/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/appOptions/appOptions.css';
import '../../constants/DefaultStyle.css';
import '../options/hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../constants/Data';

import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIEDIT from '../../classes/global/APIEDIT';

import DatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import UserLeaveBalanceModal from './userLeaveBalanceModal';
import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';

class EmployeeNoteModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.modalMode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.lastDeleted = null;
    this.lastVal = '';
    
    this.state = {

        loading: true,

        title: '',
        text: '',
        date: new Date(),
        notification: '0',
        type: 'appointment',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    if (this.modalMode === 1 && Constants.isEmpty(this.modalData) === false) {

        this.setDate1(Constants.stringToDate(this.modalData.n_datum));

        this.setState({

            title: this.modalData.n_titel,
            text: this.modalData.n_tekst,
            notification: this.modalData.n_melding,
            type: Constants.isEmpty(this.modalData.n_melding) === true ? 'note' : 'appointment',

        });

    }

    this.setState({ loading: false, }, () => {

        if (this.modalMode === 1 && Constants.isEmpty(this.modalData) === false) {
            if (Constants.isEmpty(this.modalData.n_tijd) === false) {
                this.absAmount.value = this.modalData.n_tijd.substr(0, 5);
            }
        }

    });

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        date = null;
    }

    this.setState({
        date: date,
        showError: false,
    });

  }

  selectType(event) {

    this.setState({
        type: event.target.value,
        showError: false,
    });

  }

  selectNotification(event) {

    this.setState({
        notification: event.target.value,
        showError: false,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {

    if (Constants.isEmpty(this.state.title) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen titel ingevoerd`,
        });
        return false;
    }

    if (Constants.isEmpty(this.state.date) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen datum aangegeven`,
        });
        return false;
    }

    if (this.state.type === 'appointment') {

        if (this.isValidForSave(this.absAmount.value) === false) {
            this.setState({
                showError: true,
                errorText: `Je hebt geen juiste tijd ingevoerd`,
            });
            return false;
        }

    }

    return true;

  }

  async addContract() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    if (this.state.type === 'appointment') {
        await APIADD.Request(`INSERT INTO Notitie`, `VALUES (NULL, ${this.props._userID}, ${Data.data.accountData.info_id}, '${this.state.title}', '${this.state.text}', '${Constants.dateToString(this.state.date)}', ${this.state.notification}, '${this.absAmount.value.substr(0, 5)}')`);
    } else if (this.state.type === 'note') {
        await APIADD.Request(`INSERT INTO Notitie`, `VALUES (NULL, ${this.props._userID}, ${Data.data.accountData.info_id}, '${this.state.title}', '${this.state.text}', '${Constants.dateToString(new Date())}', NULL, NULL)`);
    }

    this.reloadScreen();
    this.closeModal();

  }

  async editContract() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    if (this.state.type === 'appointment') {
        await APIEDIT.Request(`UPDATE Notitie`, `SET n_titel = '${this.state.title}', n_tekst = '${this.state.text}', n_datum = '${Constants.dateToString(this.state.date)}', n_melding = ${this.state.notification}, n_tijd = '${this.absAmount.value.substr(0, 5)}'`, `WHERE n_id = ${this.modalData.n_id}`);
    } else if (this.state.type === 'note') {
        await APIEDIT.Request(`UPDATE Notitie`, `SET n_titel = '${this.state.title}', n_tekst = '${this.state.text}', n_datum = '${Constants.dateToString(new Date())}'`, `WHERE n_id = ${this.modalData.n_id}`);
    }

    this.reloadScreen();
    this.closeModal();

  }

  closeContractModal() {

    //this.reloadScreen();
    this.closeModal();

  }

  onChangeTime(e, i2, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.absAmount.value;
    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times = e.target.value;
      // this.setState({ absAmount: times });
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   const pos = e.target.selectionStart;
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
    //   times[i2].value = e.target.value;
    //   e.target.selectionStart = pos;
    //   e.target.selectionEnd = pos;
    //   return false;
    // }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (this.lastDeleted === ':') {
          val = val.slice(0, -1);
        }

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        // if (val.length === 2 && this.lastVal.length === 3) {
        //   val = val.slice(0, 1);
        // }

        // if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
        //   val = val + ' - ';
        // }

        // if (val.length > 7 && val.includes(' - ') === false) {
        //   val = val.slice(0, 5);
        //   val = val + ' - ';
        // }

        // if (val.length === 7 && (this.lastVal.length === 8)) {
        //   val = val.slice(0, -3);
        // }

        // if (this.isValidForSave(val2)) {

        //   if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
        //     val = val + ':';
        //   }

        //   if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
        //     val = val.slice(0, -1);
        //   }

        // }

        if (val.length > 7) {
          e.target.value = val;
          times = e.target.value;
          // this.setState({ absAmount: times });
          //this.setState({ timesStandard: times });
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times = e.target.value;
        // this.setState({ absAmount: times });
        // this.setState({ timesStandard: times });

    } else {
      e.target.value = this.lastVal;
      times = e.target.value;
      // this.setState({ absAmount: times });
      // this.setState({ timesStandard: times });
    }

  }

  isValidForSave(val) {

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    // if (val.length >= 0 && val.length <= 10) {
    //   val = val.substr(0, 5);
    // } else if (val.length >= 9) {
    //   val = val.substr(8, 13);
    // }

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs
      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 16) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  render() {

    return (
        <div id="employeeNoteModal" className="modal" onClick={(e) => e.target === document.getElementById("zemployeeNoteModal") ? this.closeContractModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeContractModal() : null}>&times;</span>
            {this.modalMode === 0 ?
                <p>Afspraak of notitie toevoegen</p>
            :
            this.modalMode === 1 && this.state.type === 'note' ?
                <p>Notitie wijzigen</p>
            :
                <p>Afspraak wijzigen</p>
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    {this.modalMode !== 1 ?
                        <div className='modal-row'>

                            <div className='modal-rowSub1'>
                                <span><b>Type</b></span>
                            </div>

                            <div className='modal-rowSub2'>
                                <select 
                                    name="Selecteer een contract" 
                                    className={'dropDownField'}
                                    onChange={(event) => this.selectType(event)}
                                >
                                    <option value={'appointment'} selected={'appointment' === this.state.type}>Afspraak</option>
                                    <option value={'note'} selected={'note' === this.state.type}>Notitie</option>
                                </select>
                            </div>

                        </div>
                    : null}

                    <div className='modal-row' style={{marginTop: this.modalMode !== 1 ? '1vw' : 0}}>

                        <div className='modal-rowSub1'>
                            <span><b>Titel</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.title}
                            onChange={(event) => this.setState({ title: event.target.value })}
                            placeholder={'Titel'}
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Beschrijving</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Maximaal 250 tekens</span>
                        </div>

                        <div className='modal-rowSub2'>

                          <textarea 
                              className={'inputFieldBig'}
                              maxLength={250}
                              value={this.state.text}
                              onChange={(event) => this.setState({ text: event.target.value })}
                              placeholder={'Beschrijving'}
                          />

                        </div>

                    </div>

                    {this.state.type === 'appointment' ?

                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Datum</b></span>
                            </div>

                            <div className='modal-rowSub2'>
                                <DatePicker
                                    selected={this.state.date}
                                    onChange={(date) => this.setDate1(date)}
                                    dateFormat="P"
                                    className={'inputFieldTime'}
                                    placeholderText={'Selecteer een datum'}
                                    showWeekNumbers
                                    locale={dateLoc[Constants.getLang().substr(0, 2)]}
                                />
                            </div>

                        </div>

                    : null}

                    {this.state.type === 'appointment' ?

                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Tijd</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                                <input
                                    ref={(ref) => this.absAmount=ref}
                                    className={'inputField2'}
                                    defaultValue={Constants.msToTime2(0)}
                                    onChange={(event) => this.onChangeTime(event, null, true)}
                                    onKeyDown={(event) => this.handleKey(event)}
                                    //value={Constants.msToTime2(this.state.absAmount * 60 * 60 * 1000)}
                                    placeholder={'Verlofuren'}
                                    maxLength={7}
                                />

                            </div>

                        </div>

                    : null}

                    {this.state.type === 'appointment' ?

                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Meldingen sturen</b></span>
                                <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Naar wie</span>
                            </div>

                            <div className='modal-rowSub2'>
                                <select 
                                    name="Selecteer een contract" 
                                    className={'dropDownField'}
                                    onChange={(event) => this.selectNotification(event)}
                                >
                                    <option value={'0'} selected={'0' === this.state.notification}>Niemand</option>
                                    <option value={'1'} selected={'1' === this.state.notification}>Beide</option>
                                    <option value={'2'} selected={'2' === this.state.notification}>Alleen mijzelf</option>
                                    <option value={'3'} selected={'3' === this.state.notification}>Alleen medewerker</option>
                                </select>
                            </div>

                        </div>

                    : null}

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}
   
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeContractModal()}>
                    <span>Annuleren</span>
                </div>

                {this.modalMode === 1 ?
                    <div className='modal-submitButton' onClick={() => this.editContract()}>
                        <span>Wijzigen</span>
                    </div>
                :
                    <div className='modal-submitButton' onClick={() => this.addContract()}>
                        <span>Toevoegen</span>
                    </div>
                }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default EmployeeNoteModal;