/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availabilities.css'
import '../myHours/myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetAllAvailabilitiesYear from '../../../classes/APIGetAllAvailabilitiesYear';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAllUnavailabilitiesYear from '../../../classes/APIGetAllUnavailabilitiesYear';
import Colors from '../../../constants/Colors';
import ReactTooltip from 'react-tooltip';
import APIGetOwnHolidays from '../../../classes/APIGetOwnHolidays';
import APIGetBlockedDays from '../../../classes/APIGetBlockedDays';
import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import APIGetOwnUnavailability from '../../../classes/APIGetOwnUnavailability';
import APIGetOwnAvailability from '../../../classes/APIGetOwnAvailability';
import AvailabilityModal from '../../availability/availabilityModal';
import UnavailabilityModal from '../../availability/unavailabilityModal';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';
import APIGET from '../../../classes/global/APIGET';

class Availabilities extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    this.available = [];
    this.unavailable = [];
    this.holidayDays = [];
    this.blockedDays = [];

    this.teamUsers = [];

    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      year: year,

      availableYear: year,
      availableMonth: month,
      availableDaysInMonth: daysCurrentMonth,
      availableDateText: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,

      availableData: [],
      unavailableData: [],
      fastAvailableData: {},
      fastUnavailableData: {},
      people: [],
      daysInMonths: [],
      availableSelectedDate: date,

      selected: 'all',
      selectedName: '',

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showUnavailableModal: false,

      showAlert: false,

      fstandardAvailable: {},

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamUsers = await APIGetTeamUsers.Request();

    }

    await this.getBlockedDays();

    await this.getPeople();

    const availabilityStandard = await APIGET.Request(
      'SELECT *',
      'FROM StandaardBeschikbaarheid',
      null,
      null,
      null,
      null
    );

    const favailabilityStandard = Constants.convertToObjectWithArrays(availabilityStandard, 'sb_info_id');

    const available = await APIGetAllAvailabilitiesYear.Request(this.state.year);
    const unavailable = await APIGetAllUnavailabilitiesYear.Request(this.state.year);

    const fastAvailable = this.turnToFastObject(available);
    const fastUnavailable = this.turnToFastObject(unavailable);

    this.getDaysOfMonths();

    this.setState({
      //availableData: available,
      //unavailableData: unavailable,
      fastAvailableData: fastAvailable,
      fastUnavailableData: fastUnavailable,
      fstandardAvailable: favailabilityStandard,
      loading: false,
    });

  }

  turnToFastObject(available) {
    // init the object
    let fastArray = {};

    for (const person of available) {
      fastArray[person.info_id] = [];
    }

    //put new data in object for faster iteration
    for (const data of available) {
      fastArray[data.info_id].push(data);
    }

    return fastArray;
  }

  async reloadData(year = this.state.year) {

    const available = await APIGetAllAvailabilitiesYear.Request(year);
    const unavailable = await APIGetAllUnavailabilitiesYear.Request(this.state.year);

    const availabilityStandard = await APIGET.Request(
      'SELECT *',
      'FROM StandaardBeschikbaarheid',
      null,
      null,
      null,
      null
    );

    const favailabilityStandard = Constants.convertToObjectWithArrays(availabilityStandard, 'sb_info_id');

    this.getDaysOfMonths();

    this.setState({
      availableData: available,
      unavailableData: unavailable,
      fstandardAvailable: favailabilityStandard,
      loading: false,
    });

  }

  async getAvailable(month, year) {
    
    const availableDates = await APIGetOwnAvailability.Request(Data.data.accountData.account_username, month, year, this.state.selected);

    if(availableDates === 'error') {
      this.setState({ 
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.available = [];

    for(let key in availableDates) {
      let dateData = [];

      dateData = availableDates[key].beschikbaar_datum.split('-');

      this.available.push(
          {
              year: parseInt(dateData[0]),
              month: parseInt(dateData[1]),
              day: parseInt(dateData[2]),
              start: availableDates[key].beschikbaar_begin,
              end: availableDates[key].beschikbaar_eind,
              comment: availableDates[key].beschikbaar_opmerking,
              id: availableDates[key].beschikbaar_id,
          }
      );

    }

  }

  async getUnavailable(month, year) {
    
    const unavailableDates = await APIGetOwnUnavailability.Request(Data.data.accountData.account_username, month, year, this.state.selected);

    if(unavailableDates === 'error') {
      this.setState({ 
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.unavailable = [];

    for(let key in unavailableDates) {
      let dateData = [];

      dateData = unavailableDates[key].onbeschikbaar_datum.split('-');

      this.unavailable.push(
          {
              year: parseInt(dateData[0]),
              month: parseInt(dateData[1]),
              day: parseInt(dateData[2]),
              start: unavailableDates[key].onbeschikbaar_begin,
              end: unavailableDates[key].onbeschikbaar_eind,
              comment: unavailableDates[key].onbeschikbaar_opmerking,
              id: unavailableDates[key].onbeschikbaar_id,
          }
      );

    }

  }

  async getBlockedDays() {

    this.blockedDays = await APIGetBlockedDays.Request();

    if(this.blockedDays === 'error') {

        this.blockedDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

  }

  async getHolidayDays() {

    this.holidayDays = [];

    let holidayDays = await APIGetOwnHolidays.Request(this.state.selected);

    if(holidayDays === 'error') {

        holidayDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    if(holidayDays != null && holidayDays.length > 0) {

        let allHolidayDays = [];
        
        for(let key in holidayDays) { //iterate through holidays ranges from DB

            if(holidayDays[key].vakantie_geaccepteerd === '1') {

                let currentDate = new Date(holidayDays[key].vakantie_begin);
                let endDate = new Date(holidayDays[key].vakantie_eind);

                currentDate.setHours(12,0,0,0);
                endDate.setHours(12,0,0,0);

                while(currentDate <= endDate) {

                    allHolidayDays.push(new Date(currentDate));

                    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

                }

            }

        }


        this.holidayDays = allHolidayDays;

    } else {

        this.holidayDays = [];

    }

  }

  async getPeople() {

    let peopleDB = await APIGetAllAccounts.Request();

    let people = [];
    let index = 0;

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      peopleDB = Constants.getTeamAccounts(this.teamUsers, peopleDB);

    }

    for (const person of peopleDB) {

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

      if (Constants.objectArrayContains(people, userFullName) === false) {

        people.push({id: person.info_id, fullname: userFullName, info_voornaam: person.info_voornaam, info_achternaam: person.info_achternaam});
        //people.push(userFullName);

      }

      index++;

    }

    people.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    //people.sort((a, b) => (a > b) ? 1 : -1);

    this.setState({ people: people });

    //await this.selectUser(people[0].id, people[0].info_voornaam);

  }

  getDaysOfMonths() {

    let months = [];

    for (let i = 0; i < 12; i++) {
      months.push({month: i, days: Constants.getDaysInMonth(i + 1, this.state.year)});
    }

    this.setState({ daysInMonths: months });

  }

  async nextYear() {

    if(this.state.loading === true) {
        return;
    }

    let year = this.state.year + 1;

    if(this.canGoNext() === false) {
      return;
    }

    this.setState({loading: true});

    this.setState({
        year: year,
    });

    await this.reloadData(year);

    this.setState({loading: false});

  }

  async previousYear() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let year = this.state.year - 1;

    if(year === 0) {

      return;
      
    }

    this.setState({
        year: year,
    });

    await this.reloadData(year);

    this.setState({loading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      showUnavailableModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  canGoNext() {

    let year = this.state.year + 1;

    const today = new Date();
    const newDate = new Date(year, 0, 1);

    if(today < newDate) {
      return false;
    }

    return true;

  }

  getStandardAvailBars(standardAvail) {

    let toReturn = [];

    if (typeof standardAvail !== 'undefined') {

      let avail = null;

      for (let i = 0; i < 7; i++) {

        const day = i === 6 ? 0 : i + 1;
      
        for (const av of standardAvail) {

          if (parseInt(av.sb_dag) === day) {
            avail = av;
            break;
          }

        }

        if (avail === null) {

          toReturn.push(
            <div className='availabilities_monthName'>
              <span>-</span>
            </div>
          );

        } else {

          toReturn.push(
            <div className='availabilities_monthName'>
              {avail.sb_beschikbaar === '2' ?
                avail.sb_begin === '00:00:00' && avail.sb_eind === '00:00:00' ?
                  <span style={{color: Colors.color.redFilledIn, fontSize: '0.7vw'}}>Onbeschikbaar (hele dag)</span>
                :
                  <span style={{color: Colors.color.redFilledIn, fontSize: '0.7vw'}}>Onbeschikbaar ({avail.sb_begin.substr(0,5)} - {avail.sb_eind.substr(0,5)})</span>
              :
              avail.sb_beschikbaar === '1' ?
                avail.sb_begin === '00:00:00' && avail.sb_eind === '00:00:00' ?
                  <span style={{color: Colors.color.greenFilledIn, fontSize: '0.7vw'}}>Beschikbaar (hele dag)</span>
                :
                  <span style={{color: Colors.color.greenFilledIn, fontSize: '0.7vw'}}>Beschikbaar ({avail.sb_begin.substr(0,5)} - {avail.sb_eind.substr(0,5)})</span>
              :
                <span>-</span>
              }
            </div>
          );

        }

      }

    } else {
      for (let i = 0; i < 7; i++) {
        toReturn.push(
          <div className='availabilities_monthName'>
            <span>-</span>
          </div>
        );
      }
    }

    return toReturn;

  }

  getAvailabilityBars() {

    let bars = [];

    if (Data.data.appOptions.availability === 'STANDARD') {

      bars.push(

        <div className='availabilities_personRow stickyTopContent' style={{pointerEvents: 'none'}}>
          
          <div className='availabilities_nameTitle'>

            <div style={{fontWeight: 'bold'}}>Naam - Dag</div>

          </div>

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Maandag</div>
          </div>
          
          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Dinsdag</div>
          </div>

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Woensdag</div>
          </div>

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Donderdag</div>
          </div>

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Vrijdag</div>
          </div>

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Zaterdag</div>
          </div>

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>Zondag</div>
          </div>

        </div>

      );

      for (const person of this.state.people) {

        const availPerson = this.state.fstandardAvailable[person.id];

        bars.push(

          <div className='availabilities_personRow' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({pathname: '/employees', selected: person.id, selectedName: person.fullname, menu: 'availability'})}>
            
            <div className='availabilities_name'>

              <div>{person.fullname}</div>

            </div>

            {this.getStandardAvailBars(availPerson)}

          </div>

        );

      }

    } else {

      bars.push(

        <div className='availabilities_personRow stickyTopContent' style={{pointerEvents: 'none'}}>
          
          <div className='availabilities_nameTitle'>

            <div style={{fontWeight: 'bold'}}>Naam - Maand</div>

          </div>

          {this.getMonthBars(null)}

        </div>

      );

      for (const person of this.state.people) {

        bars.push(

          <div className='availabilities_personRow' style={{cursor: 'pointer'}} onClick={() => this.selectUser(person.id, person.info_voornaam)}>
            
            <div className='availabilities_name'>

              <div>{person.fullname}</div>

            </div>

            {this.getMonthBars(person)}

          </div>

        );

      }

    }

    return bars;

  }

  getMonthBars(person) {

    let calendarBars = [];

    if (person === null) {

      for (const calendar of this.state.daysInMonths) {

        calendarBars.push(

          <div className='availabilities_monthName' style={{borderBottom: '1px solid #EBEBEB'}}>
            <div style={{fontWeight: 'bold'}}>{Constants.firstUppercase(Constants.getShortMonthNameM(calendar.month))}</div>
          </div>

        );

      }
      
    } else {

      for (const calendar of this.state.daysInMonths) {

        calendarBars.push(

          <div className='availabilities_month'>
            {this.getDayBars(calendar.days, calendar.month, person)}
          </div>

        );

      }

    }

    return calendarBars;

  }

  getDayBars(days, month, person) {

    let calendarBars = [];

    for (let i = 0; i < days; i++) {

      let found = false;

      // if theres no availabiltiy given by person
      if (typeof this.state.fastAvailableData[person.id] !== 'undefined') {

        for (const availableData of this.state.fastAvailableData[person.id]) {

          const date = new Date(this.state.year, month, i + 1, 12,0,0,0);
          const availableDate = new Date(availableData.beschikbaar_datum+'T12:00:00.000Z');

          if (person.id === availableData.info_id && availableDate.getFullYear() === date.getFullYear() && availableDate.getMonth() === date.getMonth() && availableDate.getDate() === date.getDate()) {

            calendarBars.push(

                <div className='availabilities_day' style={{backgroundColor: Colors.color.greenFilledIn}}>

                </div>
      
            );

            found = true;

            break;

          }

        }

      }

      if (found === false) {

        // if theres no availabiltiy given by person
        if (typeof this.state.fastUnavailableData[person.id] !== 'undefined') {

          for (const availableData of this.state.fastUnavailableData[person.id]) {

            const date = new Date(this.state.year, month, i + 1, 12,0,0,0);
            const availableDate = new Date(availableData.onbeschikbaar_datum+'T12:00:00.000Z');
    
            if (person.id === availableData.info_id && availableDate.getFullYear() === date.getFullYear() && availableDate.getMonth() === date.getMonth() && availableDate.getDate() === date.getDate()) {
    
              calendarBars.push(
    
                <div className='availabilities_day' style={{backgroundColor: Colors.color.redFilledIn}}>
                  
                </div>
        
              );
    
              found = true;
    
              break;
    
            }
    
          }

        }

      }

      if (found === false) {

        calendarBars.push(

          <div className='availabilities_day'>
            
          </div>
  
        );

      }

    }

    return calendarBars;

  }

  async selectUser(id, name) {

    if (id === this.state.selected) {
      return;
    }

    if (Data.data.appOptions.availability === 'STANDARD') {
      this.props.history.push({pathname: '/employees', selected: id, selectedName: name, menu: 'availability'});
      return;
    }

    this.setState({ loading: true });

    setTimeout(async () => {

      this.setState({ selected: id, selectedName: name });

      if (id !== 'all') {
        await this.getHolidayDays();
        await this.reloadAvailableData(this.state.availableMonth, this.state.availableYear);
      }

      this.setState({ loading: false });
      
    }, 50);

  }

  getUsersMenu() {

    let toReturn = [];

    for (const person of this.state.people) {

      toReturn.push(

        <div className={`availabilities_menuButton ${this.state.selected === person.id ? 'activeAv' : ''}`} onClick={() => this.selectUser(person.id, person.info_voornaam)}>
          <div>{person.fullname}</div>
        </div>

      );

    }

    return toReturn;

  }

  inHolidayDays(i) {

    for(let key in this.holidayDays) {

        const date = new Date(this.holidayDays[key]);

        if(date.getDate() === i && date.getMonth() === (this.state.availableMonth - 1) && date.getFullYear() === this.state.availableYear) {

            return true;

        }

    }

    return false;

  }

  inBlockedDays(i) {

    for(let key in this.blockedDays) {

        const date = new Date(this.blockedDays[key].geblokkeerd_datum);

        if(date.getDate() === i && date.getMonth() === (this.state.availableMonth - 1)) {

            return true;

        }

    }

    return false;

  }

  getDayAvailableBars() {

    let finalArr = [];
    let columns = [];

    let filledDay = false;

    // Fill in empty space before the first day of month. So the week starts at monday.
    const firstDay = new Date(`${this.state.availableYear}-${this.state.availableMonth}-01`).getDay();

    if(firstDay > 1) {
      for(let i = firstDay; i > 1; i--) {
        columns.push(
          <div key ={`${i}-${firstDay}`} className="avail_dayBarEmpty" />
        );
      }
    }

    if(firstDay === 0) {
      for(let i = 7; i > 1; i--) {
        columns.push(
          <div key ={`${i}-${firstDay}`} className="avail_dayBarEmpty" />
        );
      }
    }

    // Iterate through all days of month and get available, unavaiable or not filled.
    for(let i = 1; i <= this.state.availableDaysInMonth; i++) {

      //AVAILABLE
      for(let key in this.available) {

        if(this.available[key].year === this.state.availableYear && this.available[key].month === this.state.availableMonth && this.available[key].day === i && this.inHolidayDays(i) === false) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.greenFilledIn,
                backgroundColor: 'white',
                cursor: 'pointer',
              }}
              onClick={() => this.state.mode === 'SELECTION' ? null : this.openAvailabilityModal(i, 2, this.available[key], true)}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.availableYear}-${this.state.availableMonth}-${i}`)} {i} {Constants.getShortMonthName(this.state.availableDateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.available[key].year, parseInt(this.available[key].month) - 1, this.available[key].day))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                {this.available[key].start === '00:00:00' && this.available[key].end === '00:00:00' ?

                  <div>Hele dag</div>

                :

                  <div>{this.available[key].start.substring(0, 5)} - {this.available[key].end.substring(0, 5)}</div>

                }
                
              </div>

            </div>
          );

          filledDay = true;
          break;

        }

      }

      //UNAVAILABLE
      for(let key in this.unavailable) {

        if(this.unavailable[key].year === this.state.availableYear && this.unavailable[key].month === this.state.availableMonth && this.unavailable[key].day === i && this.inHolidayDays(i) === false) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.redFilledIn,
                backgroundColor: 'white',
                cursor: 'pointer',
              }}
              onClick={() => this.state.mode === 'SELECTION' ? null : this.openAvailabilityModal(i, 2, this.unavailable[key], false)}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.availableYear}-${this.state.availableMonth}-${i}`)} {i} {Constants.getShortMonthName(this.state.availableDateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.unavailable[key].year, parseInt(this.unavailable[key].month) - 1, this.unavailable[key].day))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                {this.unavailable[key].start === '00:00:00' && this.unavailable[key].end === '00:00:00' ?

                  <div>Hele dag</div>

                :

                  <div>{this.unavailable[key].start.substring(0, 5)} - {this.unavailable[key].end.substring(0, 5)}</div>

                }

              </div>

            </div>
          );

          filledDay = true;
          break;

        }

      }

      //NOT FILLED //HOLIDAY //DEADLINE //BLOCKED
      if(filledDay === false) {

        if(this.inBlockedDays(i) === true) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.pastBorderColor, 
                backgroundColor: Colors.color.pastBackgroundColor, 
                cursor: 'default'
              }}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.availableYear}-${this.state.availableMonth}-${i}`)} {i} {Constants.getShortMonthName(this.state.availableDateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.state.availableYear, this.state.availableMonth - 1, i))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                <i className={'fa fa-ban'} style={{color: Colors.color.pastBorderColor}} />

              </div>

            </div>
          );

        } else if(this.inHolidayDays(i) === true) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.orangeVacation, 
                cursor: 'default', 
                backgroundColor: 'white'
              }}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.availableYear}-${this.state.availableMonth}-${i}`)} {i} {Constants.getShortMonthName(this.state.availableDateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.state.availableYear, this.state.availableMonth - 1, i))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                Vakantie

              </div>

            </div>
          );

        } else { //NOTFILLED

          columns.push(
            <div
              key={i} 
              className="avail_dayBar"
              style={{
                backgroundColor: 'white',
                cursor: 'pointer',
              }}
              onClick={() => this.openAvailabilityModal(i, 0)}
            >
              <div className={'avail_rowInDayBar'}>
                <i>{Constants.getDayName(`${this.state.availableYear}-${this.state.availableMonth}-${i}`)} {i} {Constants.getShortMonthName(this.state.availableDateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.state.availableYear, this.state.availableMonth - 1, i))}</i>
              </div>
              <div className={'avail_rowInDayBar2'}>
                
              </div>
            </div>
          );

        }

      }

      filledDay = false;

      if(columns.length === 7) {
        finalArr.push(<div className={'avail_dayBarRow'}>{columns}</div>);
        columns = [];
      }

      if(i === this.state.availableDaysInMonth) {
        if(columns.length < 7) {
          for(let i2 = columns.length; i2 < 7; i2++) {
            columns.push(
              <div key ={`${i}${i2}`} className="avail_dayBarEmpty" />
            );
          }
        }
        finalArr.push(<div className={'avail_dayBarRow'}>{columns}</div>);
      }

    }

    return finalArr;

  }

  openAvailabilityModal(day, mode, data, available) {

    if(Data.data.appOptions.availability === 'AVAILABILITY') {
      this.setState({
        showModal: true, 
        modalMode: mode, 
        modalDate: mode === 1 ? new Date(this.state.availableYear, this.state.availableMonth - 1, 1) : new Date(this.state.availableYear, this.state.availableMonth - 1, day),
        modalData: mode === 2 ? data : [],
        modalAvailable: available,
        modalMultipleDays: [],
      });
    } else if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
      this.setState({
        showUnavailableModal: true, 
        modalMode: mode, 
        modalDate: mode === 1 ? new Date(this.state.availableYear, this.state.availableMonth - 1, 1) : new Date(this.state.availableYear, this.state.availableMonth - 1, day),
        modalData: mode === 2 ? data : [],
        modalAvailable: available,
        modalMultipleDays: [],
      });
    }

  }

  async selectDateCalendar(date) {

    if(this.state.loading === true) {
      return;
  }

  this.setState({loading: true});

  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const daysCurrentMonth = Constants.getDaysInMonth(month, year);

  this.setState({
      availableMonth: month,
      availableYear: year,
      availableDaysInMonth: daysCurrentMonth,
      availableDateText: `${year}-${month < 10 ? '0' + month : month}-01`,
  });

  await this.reloadAvailableData(month, year);

  this.setState({loading: false, availableSelectedDate: date});

  }

  async nextMonth() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let month = this.state.availableMonth + 1;
    let year = this.state.availableYear;

    if(month >= 13) {

        month = 1;
        year += 1;
        
    }

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    this.setState({
        availableMonth: month,
        availableYear: year,
        availableDaysInMonth: daysCurrentMonth,
        availableDateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadAvailableData(month, year);

    this.setState({loading: false});

  }

  async previousMonth() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let month = this.state.availableMonth - 1;
    let year = this.state.availableYear;

    if(month <= 0) {

      month = 12;
      year -= 1;
      
    }

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    this.setState({
        availableMonth: month,
        availableYear: year,
        availableDaysInMonth: daysCurrentMonth,
        availableDateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadAvailableData(month, year);

    this.setState({loading: false});

  }

  async reloadAvailableData(month, year) {

    this.setState({loading: true});

    const date = new Date(year, month, 0);

    //await this.getDeadlines();

    await this.getAvailable(month, year);
    await this.getUnavailable(month, year);

    this.setState({
      selectedDate: date,
      loading: false,
    });

  }

  render() {

    return (
        <div className='availabilities'>

          <Navbar history={this.props.history} active={'availabilities'} fixed menuActive={true} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'availabilities'} parent={this} />

          {this.state.selected === 'all' ?

            <div className='planner_nav2 fixedNav2'>

              {Data.data.appOptions.availability === 'STANDARD' ?
                <div className='planner_nav2_sub1' style={{cursor: 'default'}}>
                </div> 
              :
                <div className='planner_nav2_sub1' onClick={() => this.previousYear()}>
                  <i className='fa fa-chevron-left'></i>
                </div> 
              }
              <div className='planner_nav2_sub2'>
              
              </div> 
              {Data.data.appOptions.availability === 'STANDARD' ?
                <div className='planner_nav2_sub3' style={{flex: 2}}>
                  Overzicht beschikbaarheid
                </div> 
              :
                <div className='planner_nav2_sub3' style={{flex: 2}}>
                    Overzicht beschikbaarheid - jaar {this.state.year}
                </div> 
              }
              <div className='schedule_nav2_sub4'>

              </div>
              <div className='planner_nav2_sub5' onClick={() => this.nextYear()} style={{cursor: this.canGoNext() === true ? 'pointer' : 'default'}}>
                {this.canGoNext() === true ?
                
                  <i className='fa fa-chevron-right'></i>

                :

                  null
              
                }
              </div> 

            </div>

          :

            <div className='planner_nav2 fixedNav2'>

              <div className='planner_nav2_sub1' onClick={() => this.previousMonth()}>
                <i className='fa fa-chevron-left'></i>
              </div> 
              <div className='planner_nav2_sub2'>
                <ReactDatePicker 
                  selected={this.state.availableSelectedDate}
                  onSelect={date => this.selectDateCalendar(date)}
                  customInput={<i className='fa fa-calendar-day'></i>}
                  popperPlacement="top-start"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "-40px, 0px"
                    },
                  }}
                  locale={nl}
                  dropdownMode="select"
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </div> 
              <div className='planner_nav2_sub3' style={{flex: 2}}>

                {Constants.firstUppercase(this.state.selectedName)} - {Constants.getMonthName(this.state.availableDateText).charAt(0).toUpperCase() + Constants.getMonthName(this.state.availableDateText).slice(1)} {this.state.availableYear}
                
              </div> 
              <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>
        
              </div> 
              <div className='planner_nav2_sub5' onClick={() => this.nextMonth()}>
                <i className='fa fa-chevron-right'></i>
              </div> 

            </div>

          }

          <div className='availabilities_main'>

            <div className='availabilities_menu stickyTopMenuPanel'>

              <div className='availabilities_menuTitle'>
                <div>Medewerkers</div>
              </div>

              <div className={`availabilities_menuButton ${this.state.selected === 'all' ? 'activeAv' : ''}`} onClick={() => this.selectUser('all', '')}>
                <div style={{fontWeight: 'bold'}}>Alle medewerkers</div>
              </div>
              
              {this.getUsersMenu()}

            </div>

            <div className='availabilities_content'>

              {this.state.loading === true ?
              
                          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                <div className='availabilities_container'>

                  {this.state.reloading === true ?
                            
                    <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                  :

                    null

                  }

                  {this.state.showModal === true ?
                
                    <AvailabilityModal 
                      _closeModal={this.closeModal.bind(this)}
                      _modalData={this.state.modalData}
                      _modalMode={this.state.modalMode}
                      _modalDate={this.state.modalDate}
                      _modalAvailable={this.state.modalAvailable}
                      _modalMultipleDays={this.state.modalMultipleDays}
                      _reloadScreen={this.reloadAvailableData.bind(this, this.state.availableMonth, this.state.availableYear)}
                      _userID={parseInt(this.state.selected)}
                    />

                  :

                    null
                  
                  }

                  {this.state.showUnavailableModal === true ?
                    
                    <UnavailabilityModal 
                      _closeModal={this.closeModal.bind(this)}
                      _modalData={this.state.modalData}
                      _modalMode={this.state.modalMode}
                      _modalDate={this.state.modalDate}
                      _modalAvailable={this.state.modalAvailable}
                      _modalMultipleDays={this.state.modalMultipleDays}
                      _reloadScreen={this.reloadAvailableData.bind(this, this.state.availableMonth, this.state.availableYear)}
                      _userID={parseInt(this.state.selected)}
                    />

                  :

                    null
                  
                  }

                  {this.state.showAlert === true ?
                    
                    <BetterAlert 
                      _closeAlert={this.closeAlert.bind(this)}
                      _title={this.state.alertTitle}
                      _body={this.state.alertBody}
                      _buttons={this.state.alertButtons}
                      _buttonText={this.state.alertButtonText}
                      _buttonAction={this.state.alertButtonAction}
                    />

                  :

                    null
                  
                  }

                  {this.state.selected === 'all' ?
                    
                    this.getAvailabilityBars()
                    //null
                
                  : 
                  Data.data.appOptions.availability === 'STANDARD' ?

                    <div>

                      <div style={{fontSize: '2vw', fontWeight: 'bold', marginLeft: '1vw', marginTop: '2vw', width: '100%', position: 'relative'}}>
                        Beschikbaarheid

                        <span className='employees_saveStandard2' onClick={() => this.saveAllStandardAvailability()}>Opslaan</span>

                      </div>

                      <div className='employees_content_container' style={{justifyContent: 'center', paddingLeft: '1vw', flexDirection: 'column'}}>

                        {this.showStandardAvailability()}

                      </div>

                    </div>

                  :
                  
                    <div>

                      <div className={'avail_headerRow stickyTopAvailabilityMenu'}>
                        <div className="avail_headerText">Maandag</div>
                        <div className="avail_headerText">Dinsdag</div>
                        <div className="avail_headerText">Woensdag</div>
                        <div className="avail_headerText">Donderdag</div>
                        <div className="avail_headerText">Vrijdag</div>
                        <div className="avail_headerText">Zaterdag</div>
                        <div className="avail_headerText">Zondag</div>
                      </div>

                      {this.getDayAvailableBars()}

                    </div>
                  
                  }
                  
                </div>
            
              }

            </div>

          </div>

        </div>
    );
  }
  
}

export default Availabilities;