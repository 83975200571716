/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../hoursRegistration/hoursRegistration.css'
import '../myHours/myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIEDIT from '../../../classes/global/APIEDIT';
import Colors from '../../../constants/Colors';

class PlannerArrange extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    // compwillmount

    this.teamUsers = [];
    this.fromBar = [];
    
    this.state = {

      loading: true,
      reloading: false,

      plannerArrange: [],

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    let plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam, functie_naam IS NULL DESC, pv_index`);
    const usersAll = await APIGetAllAccounts.Request();
    let users = [];
    let toAdd = [];

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
      users = Constants.getTeamAccounts(this.teamUsers, usersAll);
    } else {
      users = usersAll;
    }

    for (const arr of plannerArrange) {
      toAdd.push(arr);
    }

    if (users.length !== plannerArrange.length) {

      for (const user of users) {
        let found = false;
        loop2:
        for (const arr of plannerArrange) {
          if (user.info_id === arr.pv_info_id) {
            found = true;
            break loop2;
          }
        }
        if (found === false) {
          toAdd.push({
            pv_info_id: user.info_id,
          });
        }
      }

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promises = toAdd.map((add, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + add.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + add.pv_info_id.toString()}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promises);

      toAdd = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      // if (toAdd.length > 0) {

      //   loop1:
      //   for (const add of toAdd) {

      //     for (const arr of plannerArrange) {
      //       if (add.pv_info_id === arr.pv_info_id) {
      //         continue loop1;
      //       }
      //     }

      //     let index = toAdd.indexOf(add);
      //     await APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${index}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`);
      //   }

      //   plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY pv_index`);

      // }

    }

    if (toAdd.length > 0) {

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promises = toAdd.map((add, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + add.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + add.pv_info_id.toString()}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promises);

      // reload

      const plannerArrangeOld = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promises2 = plannerArrangeOld.map((add, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + add.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + add.pv_info_id.toString()}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promises2);

      // reload again

      plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

    }

    this.setState({
      plannerArrange: plannerArrange,
      loading: false,
    });

  }

  getUserBars() {

    let hourBars = [];
    let func = '';
    let pos = 0;

    for(const user of this.state.plannerArrange) {

      if (func !== user.functie_naam) {
        func = user.functie_naam;
        pos = parseInt(user.pv_index);

        if (user.functie_naam === null) {
          hourBars.push(
            <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '1.5vw', backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderRadius: 3, paddingLeft: 3, marginTop: 8, marginBottom: 5}}>
              <span style={{color: Constants.getContrast(Colors.color.redFilledIn), fontSize: '0.7vw'}}>Geen functie</span>
            </div>
          );
        } else {
          hourBars.push(
            <div style={{position: 'relative',display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '1.5vw', backgroundColor: Constants.hexToRgbA(`#${user.functie_kleur}`, 0.5), borderRadius: 3, paddingLeft: 3, marginTop: 8, marginBottom: 5}}>
              <span style={{color: Constants.getContrast(user.functie_kleur), fontSize: '0.7vw'}}>{user.functie_naam}</span>
            </div>
          );
        }
      }

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;

      hourBars.push(

        <div 
          key={user.info_id} 
          className={'hr_hoursBar_noHeight'} 
          style={{ width: '100%', margin: 0, paddingTop: '0.5vw', paddingBottom: '0.2vw' }}
          draggable='true' 
          onDragStart={(event) => this.onDragStart(event, user)}
          onDrop={(event) => this.onDrop(event, user)}
          onDragOver={(event) => this.onDragOver(event, user)}
          onDragLeave={(event) => this.onDragLeave(event)}
        >

          <div className={'hr_hoursBar_sub1'} style={{flex: 7, cursor: 'grab', fontSize: '0.8vw'}}>
            {userFullName}
          </div>
          <div className={'hr_hoursBar_sub1'} style={{flex: 3, cursor: 'grab', fontSize: '0.8vw'}}>
            {user.functie_naam}
          </div>
          <div className={'hr_hoursBar_sub1'} style={{fontSize: '0.8vw'}}>
            {this.state.reloading === true ? '-' : parseInt(user.pv_index) + 1 - pos}
          </div>
          <div 
            className={'hr_hoursBar_sub1'} 
            style={{flex: 1, color: '#282828', justifyContent: 'center'}}
          >
            <i className={'far fa-angle-up'} style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.sortUp(user)}></i>
            <i className={'far fa-angle-down'} style={{fontSize: '1.2vw', marginLeft: '0.8vw', cursor: 'pointer'}} onClick={() => this.sortDown(user)}></i>
          </div>

        </div>

      );

    }

    return hourBars;

  }

  async sortUp(user) {

    let usersArray = this.state.plannerArrange;
    const currentIndex = parseInt(user.pv_index);

    if (currentIndex !== 0) {

      this.setState({reloading: true});

      let user2 = usersArray[currentIndex - 1];

      if (user.functie_naam !== user2.functie_naam) {
        this.setState({reloading: false});
        return;
      }

      await APIEDIT.Request(`UPDATE PlannerVolgorde`, `SET pv_info_id = ${user.pv_info_id}`, `WHERE pv_index = ${user2.pv_index}`);
      await APIEDIT.Request(`UPDATE PlannerVolgorde`, `SET pv_info_id = ${user2.pv_info_id}`, `WHERE pv_index = ${user.pv_index}`);

      // reload

      const plannerArrangeOld = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promises = plannerArrangeOld.map((add, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + add.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + add.pv_info_id.toString()}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promises);

      const plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      this.setState({plannerArrange: plannerArrange, reloading: false});

    }

  }

  async sortDown(user) {

    let usersArray = this.state.plannerArrange;
    const currentIndex = parseInt(user.pv_index);

    if (currentIndex !== (usersArray.length - 1)) {

      this.setState({reloading: true});

      let user2 = usersArray[currentIndex + 1];

      if (user.functie_naam !== user2.functie_naam) {
        this.setState({reloading: false});
        return;
      }

      await APIEDIT.Request(`UPDATE PlannerVolgorde`, `SET pv_info_id = ${user.pv_info_id}`, `WHERE pv_index = ${user2.pv_index}`);
      await APIEDIT.Request(`UPDATE PlannerVolgorde`, `SET pv_info_id = ${user2.pv_info_id}`, `WHERE pv_index = ${user.pv_index}`);

      // reload

      const plannerArrangeOld = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promises = plannerArrangeOld.map((add, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + add.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + add.pv_info_id.toString()}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promises);

      const plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      this.setState({plannerArrange: plannerArrange, reloading: false});

    }

  }

  onDragStart(event, data) {

    if(this.state.loading) {
      event.preventDefault();
      return;
    }

    this.fromBar = data;

  }

  async onDrop(event, data) {

    if(this.state.loading) {
      return;
    }
  
    event.target.style.opacity = null;

    if (this.fromBar.pv_index !== data.pv_index) {

      if (this.fromBar.functie_naam !== data.functie_naam) {
        return;
      }

      this.setState({reloading: true});

      let usersArray = this.state.plannerArrange;

      const indexFrom = this.fromBar.pv_index;
      const indexTo = data.pv_index;

      usersArray.splice(indexFrom, 1);
      usersArray.splice(indexTo, 0, this.fromBar);

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promisesUsers = usersArray.map((usr, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + usr.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + usr.pv_info_id.toString()}, ${usr.info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promisesUsers);

      // reload

      const plannerArrangeOld = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

      const promises = plannerArrangeOld.map((add, index) => APIADD.Request(`INSERT INTO PlannerVolgorde`, `VALUES (${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? index.toString() + add.pv_info_id.toString() + Data.data.chosenTeamObj.team_id.toString() : index.toString() + add.pv_info_id.toString()}, ${add.pv_info_id}, ${index}, ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`));

      await Promise.all(promises);

      const plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index, info_voornaam, info_tussenvoegsel, info_achternaam, info_id, functie_naam, functie_kleur`, `FROM PlannerVolgorde`, `LEFT JOIN Info ON info_id = pv_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY functie_naam IS NULL ASC, functie_naam, pv_index`);

      this.setState({plannerArrange: plannerArrange, reloading: false});

    }

  }

  onDragOver(e, data) {
    e.preventDefault();
    e.target.style.opacity = 0.5;

  }

  onDragLeave(e) {
    e.preventDefault();
    e.target.style.opacity = null;
  }

  async reset() {

    this.setState({loading: true});
    await APIDELETE.Request(`DELETE FROM PlannerVolgorde`, `WHERE pv_id >= 0${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` AND pv_team_id = ${Data.data.chosenTeamObj.team_id}` : ``}`);

    await this.componentDidMount();

  }

  render() {

    return (
        <div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='myHours_container' style={{width: '100%', marginLeft: 0}}>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              <div className="planner_addButtons_container" style={{ marginLeft: 0 }}>

                <div className="planner_new_dangerButton" style={{width: '6vw', marginLeft: 0}} onClick={() => this.reset()}>
                  {/* <i className={'fal fa-plus'} /> */}
                  Resetten
                </div>

              </div>
              
              <div>
                <div className={'hr_hoursBar_title stickyTopHoursRegistration'} style={{marginTop: '1vw', width: '100%', marginLeft: 0, marginRight: 0}}>
                  <div className={'hr_hoursBar_sub1'} style={{flex: 7, padding: '0.2vw'}}>
                    <div>Naam</div>
                  </div>
                  <div className={'hr_hoursBar_sub1'} style={{flex: 3, padding: '0.2vw'}}>
                    <div>Functie</div>
                  </div>
                  <div className={'hr_hoursBar_sub1'} style={{padding: '0.2vw'}}>
                    <div>Positie</div>
                  </div>
                  <div className={'hr_hoursBar_sub1'} style={{flex: 1, justifyContent: 'center', padding: '0.2vw'}}>
                    <div>Sorteren</div>
                  </div>
                </div>

                {this.getUserBars()}

              </div>
              
            </div>
        
          }

        </div>
    );
  }
  
}

export default PlannerArrange;