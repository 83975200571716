import React from 'react';

//styles
import './account.css';
import '../registerUser/registerUser.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import BetterAlert from '../../../components/alert/alert';

class Account extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      loading1: false,
      loading2: false,

      blockUser: true,
      blockInfo: true,

      username: Data.data.accountData.account_username,

      firstName: Data.data.accountData.info_voornaam,
      insertion: Data.data.accountData.info_tussenvoegsel,
      lastName: Data.data.accountData.info_achternaam,

      birthDate: new Date(Data.data.accountData.info_geboorte),
      birthText: '',

      startDate: Data.data.accountData.info_indienst === null || Data.data.accountData.info_indienst === '0000-00-00' ? new Date() : new Date(Data.data.accountData.info_indienst),

      address: Data.data.accountData.info_adres,
      addressNumber: Data.data.accountData.info_huisnummer,
      zipCode: Data.data.accountData.info_postcode,
      city: Data.data.accountData.info_plaats !== null && Data.data.accountData.info_plaats !== 'null' ? Data.data.accountData.info_plaats : '',

      email: Data.data.accountData.info_email,
      phone: Data.data.accountData.info_telefoon,
      phone2: Data.data.accountData.info_telefoon2 !== null && Data.data.accountData.info_telefoon2 !== 'null' ? Data.data.accountData.info_telefoon2 : '',

      rights: 0,

      comment: Data.data.accountData.info_extra,

      bsn: Data.data.accountData.info_idnr === null || Data.data.accountData.info_idnr === 'null' || Data.data.accountData.info_idnr === '' ? 'n.v.t.' : Data.data.accountData.info_idnr,
      iban: Data.data.accountData.info_ibnr === null || Data.data.accountData.info_ibnr === 'null' || Data.data.accountData.info_ibnr === '' ? 'n.v.t.' : Data.data.accountData.info_ibnr,

    };

  }

  async componentDidMount() {

    this.setState({loading: false});

  }

  render() {

    return (
        <div className='account'>

          <Navbar history={this.props.history} active={'account'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Mijn gegevens
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='registerUser_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }


                <div className={'registerUser_userContainer'}>

                    <div>Gebruikersnaam:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={30}
                        value={this.state.username}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    <div  style={{marginTop: 10}}>Voornaam:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={50}
                        value={this.state.firstName}
                        disabled={this.state.blockInfo}
                        style={{textTransform: 'capitalize'}}
                    />

                    <div style={{marginTop: 10}}>Tussenvoegsel:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={50}
                        value={this.state.insertion}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Achternaam:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={50}
                        value={this.state.lastName}
                        disabled={this.state.blockInfo}
                        style={{textTransform: 'capitalize'}}
                    />

                    <div style={{marginTop: 10}}>Geboortedatum:</div>

                    <DatePicker
                        selected={this.state.birthDate}
                        dateFormat="dd-MM-yyyy"
                        className={'inputFieldTime'}
                        showWeekNumbers
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        locale={nl}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Straatnaam:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={80}
                        value={this.state.address}
                        disabled={this.state.blockInfo}
                        style={{textTransform: 'capitalize'}}
                    />

                    <div style={{marginTop: 10}}>Huisnummer:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={10}
                        value={this.state.addressNumber}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Postcode:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={20}
                        value={this.state.zipCode}
                        disabled={this.state.blockInfo}
                        style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                    />

                    <div style={{marginTop: 10}}>Plaats:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={50}
                        value={this.state.city}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>E-mail:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={50}
                        type={'email'}
                        value={this.state.email}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Telefoon:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={12}
                        type={'tel'}
                        value={this.state.phone}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Tweede telefoon:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={12}
                        type={'tel'}
                        value={this.state.phone2}
                        disabled={this.state.blockInfo}
                    />

                    {Data.data.appOptions.iban === true ?
                      <div>

                        <div style={{marginTop: 10}}>IBAN:</div>

                        <input 
                            className={'inputField2'}
                            maxLength={25}
                            value={this.state.iban}
                            disabled={this.state.blockInfo}
                        />

                      </div>
                    : null}

                    {Data.data.appOptions.bsn === true ?
                      <div>

                        <div style={{marginTop: 10}}>BSN-nummer:</div>

                        <input 
                            className={'inputField2'}
                            maxLength={15}
                            value={this.state.bsn}
                            disabled={this.state.blockInfo}
                        />

                      </div>
                    : null}

                    <div style={{marginTop: 10}}>Functie:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={12}
                        type={'tel'}
                        value={Data.data.accountData.functie_naam}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Accountrechten:</div>

                    <input 
                        className={'inputField2'}
                        maxLength={12}
                        type={'tel'}
                        value={Data.data.accountData.account_rights === '2' ? 'Administrator' : Data.data.accountData.account_rights === '1' ? 'Planner' : 'Medewerker'}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Datum in dienst:</div>

                    <DatePicker
                        selected={this.state.startDate}
                        dateFormat="dd-MM-yyyy"
                        className={'inputFieldTime'}
                        showWeekNumbers
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        locale={nl}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Aanvullende informatie: (max. 250 tekens)</div>

                    <textarea  
                        className={'inputFieldBig'}
                        maxLength={250}
                        value={this.state.comment}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 40, marginBottom: 100, textAlign: 'center', fontSize: '1vw'}}>

                        Jouw gegevens zijn alleen zichtbaar voor jezelf en roosterbeheerders.<br></br>
                        <br></br>
                        Jouw voornaam, achternaam, telefoonnummer en e-mailadres zijn zichtbaar voor alle medewerkers.

                    </div>

                </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default Account;