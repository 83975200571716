import React from 'react';
import './storeCode.css';
import '../constants/DefaultStyle.css';

import APIGetAllStoreCodes from '../classes/APIGetAllStoreCodes';

class StoreCode extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;
    
    this.state = {
      loading: true,
      stores: [],

      filter: '',

      reloading: false,

    };

  }

  async componentDidMount() {

    await this.getStores();

  }

  async getStores() {

    const stores = await APIGetAllStoreCodes.Request();

    if(stores === 'error') {

        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(stores === null || stores === []) {
        this.setState({
            loading: false,
        });
        return;
    }

    this.setState({ 
        stores: stores,
        loading: false
    });

  }

  getStoreBars() {

    let hourBars = [];

    const filteredData = [];
    const filter = this.state.filter.toLowerCase();

    if(this.state.filter !== '') {
      for(let item of this.state.stores) {
        if((item.bedrijf_naam.toLowerCase().indexOf(filter) !== -1 || item.bedrijf_code.toLowerCase().indexOf(filter) !== -1
        || item.bedrijf_adres.toLowerCase().indexOf(filter) !== -1 || item.bedrijf_postcode.toLowerCase().indexOf(filter) !== -1
        || item.bedrijf_plaats.toLowerCase().indexOf(filter) !== -1) && item.bedrijf_code !== 'demo01') {
          filteredData.push(item);
        }
      }
    }

    for(let key in filteredData) {

      const store = filteredData[key];

      hourBars.push(

        <div key={key} className={'storecode_barContainer'}>

          <div className={'storecode_barRow'} style={{flex: 2}}>
            {store.bedrijf_naam}
          </div>
          <div className={'storecode_barRow'}>
            {store.bedrijf_code}
          </div>

        </div>

      );

    }

    return hourBars;

  }

  render() {

    const storeBars = this.getStoreBars();

    return (
      <div className='myHours'>

      <div className='planner_nav2' style={{cursor: 'default'}}>

        <div className='planner_nav2_sub1' style={{cursor: 'default'}}>

        </div> 
        <div className='planner_nav2_sub2' style={{cursor: 'default'}}>

        </div> 
        <div className='storecode_nav2_sub3' style={{flex: 8}}>
            Bedrijfscode opzoeken
        </div> 
        <div className='planner_nav2_sub4' style={{cursor: 'default'}}>
            </div>
        <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
   
        </div> 

      </div>

      {this.state.loading === true ?
      
                  <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

      :

        <div className='storecode_container'>

          {this.state.reloading === true ?
                    
            <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            null

          }

              <input 
                  className={'inputField2'}
                  value={this.state.filter}
                  onChange={(event) => this.setState({filter: event.target.value})}
                  style={{marginBottom: 25, marginTop: 25}}
                  placeholder={'Zoek op bedrijfsnaam of adres'}
                  autoCapitalize={'none'}
              />

              <div className={'storecode_barContainerTop stickyTopHours'}>
                <div className={'storecode_barRowMain'} style={{flex: 2}}>
                  <div>Naam</div>
                </div>
                <div className={'storecode_barRowMain'}>
                  <div>Bedrijfscode</div>
                </div>
              </div>

              {storeBars}
          
        </div>
    
      }

    </div>
    );
  }
  
}

export default StoreCode;