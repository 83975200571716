/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './holidayRequests.css';
import '../tradeRequests/tradeRequests.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';
import '../trade/trade.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import APIGetAllHolidays from '../../../classes/APIGetAllHolidays';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import HolidayRequestsModal from './holidayRequestsModal';
import BetterAlert from '../../../components/alert/alert';
import APIGET from '../../../classes/global/APIGET';
import AddHolidayModal from './addHolidayModal';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';

class HolidayRequests extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      this.props.history.push("/");
    }

    this.teamUsers = null;

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      holidayRequests: [],

      showAddHolidayModal: false,

      showModal: false,
      modalMode: 0,
      modalData: [],

      oneDay: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      vHours: {},

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    await this.getHolidayRequests();

    const holidayHours = await APIGET.Request(
      `SELECT *`,
      `FROM VerlofUren`,
      `LEFT JOIN Vakantie ON vakantie_id = vu_vakantie_id`,
      `WHERE vakantie_info_id = ${Data.data.accountData.info_id}`,
      null,
      null
    );
    const vHoursObj = Constants.convertToObject(holidayHours, 'vakantie_id');

    this.setState({vHours: vHoursObj, loading: false});

  }

  openAddHoliday() {

    this.setState({
      showAddHolidayModal: true, 
    });

  }

  openHolidayRequestsModal(mode, data, oneDay) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
      oneDay: oneDay === true,
    });

  }

  async getHolidayRequests() {

    let holidays = await APIGetAllHolidays.Request();

    if(holidays === 'error') {

      holidays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(holidays === null) {

      holidays = [];

    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeamObj !== null) {
      if (this.teamUsers === null) {
        this.teamUsers = await APIGetTeamUsers.Request();
      }
      holidays = Constants.getTeamAccounts(this.teamUsers, holidays);
    }

    this.setState({ holidayRequests: holidays });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getHolidayRequests();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showAddHolidayModal: false,
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getHolidayRequestBarsOpen() {

    let holidays = [];

    for(let key in this.state.holidayRequests) {

        const holiday = this.state.holidayRequests[key];

        if(holiday.vakantie_geaccepteerd === '0') { //open ruildienst

            const date = Constants.stringToDate(holiday.vakantie_begin);
            const dateEnd = Constants.stringToDate(holiday.vakantie_eind);

            const sameDay = date.getTime() === dateEnd.getTime();

            let vHours = null;
            if (sameDay === true) { // verlof
              if (typeof this.state.vHours[holiday.vakantie_id] !== 'undefined') {
                vHours = this.state.vHours[holiday.vakantie_id];
                vHours.total = parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
              }
            }

            const userFullName = `${holiday.info_voornaam} ${(holiday.info_tussenvoegsel !== null && holiday.info_tussenvoegsel !== '') ? holiday.info_tussenvoegsel + " " + holiday.info_achternaam.charAt(0) : holiday.info_achternaam.charAt(0)}.`;

            holidays.push(
            <div 
                className={'trade_tradeBar'} 
                style={{borderColor: Colors.color.greyNotFilled, backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1)}}
            >   

                <div style={{backgroundColor: Colors.color.greyNotFilled, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                  <span style={{textAlign: 'center', color: Constants.getContrast(Colors.color.greyNotFilled)}}>In afwachting van jouw goedkeuring</span>
                </div>

                <div className='trade_tradeContainer'>

                  <div className={'trade_functionContainer'}>

                    <div style={{fontWeight: 'bold'}}>{userFullName}</div>

                    {sameDay === false ?
                      <div style={{marginTop: 3}}>&nbsp;</div>
                    : null}

                    <div style={{marginTop: 3}}>{holiday.vakantie_opmerking === 'null' ? '' : holiday.vakantie_opmerking}&nbsp;</div>

                    {/* <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                    <div style={{marginTop: 3,}}>{trade.fn1}</div>

                    <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_from)}</div>

                    <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v1}</div>

                    <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v2}</div> */}

                    <div className='trade_normalButton' style={{marginTop: 8}} onClick={() => this.openHolidayRequestsModal(0, holiday, sameDay)}>
                      Goedkeuren/afwijzen
                    </div>

                  </div>

                  <div className={'trade_namesContainer'}>

                    <div>{date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()}{sameDay === false ? ' t/m' : ''}</div>

                    {sameDay === false ?
                      <div style={{marginTop: 3}}>{dateEnd.getDate()} {Constants.getMonthName(dateEnd)} {dateEnd.getFullYear()}</div>
                    : null}

                    {vHours !== null ?
                      vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ?
                        <div style={{marginTop: 3}}>Hele dag (8 uren)</div>
                      :
                        <div style={{marginTop: 3}}>{vHours.vu_begin.substr(0,5)} - {vHours.vu_eind.substr(0,5)} ({vHours.total} {vHours.total === 1 ? 'uur' : 'uren'})</div>
                    : 
                      <div style={{marginTop: 3}}>&nbsp;</div>
                    }

                    {/* <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                    <div style={{marginTop: 3,}}>{trade.fn2}</div>

                    <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_to)}</div>

                    <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v2}</div>

                    <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v1}</div> */}

                    <div className='trade_denyButton' style={{marginTop: 8, backgroundColor: 'transparent', cursor: 'default', color: 'transparent'}}>
                      &nsbp;
                    </div>

                  </div>

                </div>

            </div>
            );

        }

    }

    return holidays;

  }

  getHolidayRequestBarsAccepted() {

    let holidays = [];

    for(let key in this.state.holidayRequests) {

      const holiday = this.state.holidayRequests[key];

      if(holiday.vakantie_geaccepteerd === '1') { //open ruildienst

          const date = Constants.stringToDate(holiday.vakantie_begin);
          const dateEnd = Constants.stringToDate(holiday.vakantie_eind);

          const sameDay = date.getTime() === dateEnd.getTime();

          let vHours = null;
          if (sameDay === true) { // verlof
            if (typeof this.state.vHours[holiday.vakantie_id] !== 'undefined') {
              vHours = this.state.vHours[holiday.vakantie_id];
              vHours.total = parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
            }
          }

          const userFullName = `${holiday.info_voornaam} ${(holiday.info_tussenvoegsel !== null && holiday.info_tussenvoegsel !== '') ? holiday.info_tussenvoegsel + " " + holiday.info_achternaam.charAt(0) : holiday.info_achternaam.charAt(0)}.`;

          holidays.push(
            // <div key={key}
            //     className={'tradeRequests_tradeBar'} 
            //     style={{borderColor: Colors.color.greenFilledIn}}
            //     onClick={() => this.openHolidayRequestsModal(1, holiday, sameDay)}
            // >

            //     <div className={'tradeRequests_functionContainer'} style={{flex: 2}}>

            //       <div className={'holidayRequests_subRow1'}>

            //         {date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()}{sameDay === false ? ' t/m' : ''}

            //       </div>

            //       {sameDay === false ?

            //         <div className={'holidayRequests_subRow2'}>

            //           {dateEnd.getDate()} {Constants.getMonthName(dateEnd)} {dateEnd.getFullYear()}

            //         </div>

            //       : null}

            //     </div>

            //     <div className={'tradeRequests_namesContainer'}>

            //         {userFullName}

            //     </div>

            // </div>

          <div 
              className={'trade_tradeBar'} 
              style={{borderColor: Colors.color.greenFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1)}}
          >   

              <div style={{backgroundColor: Colors.color.greenFilledIn, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                <span style={{textAlign: 'center', color: Constants.getContrast(Colors.color.greenFilledIn)}}>Goedgekeurd</span>
              </div>

              <div className='trade_tradeContainer'>

                <div className={'trade_functionContainer'}>

                  <div style={{fontWeight: 'bold'}}>{userFullName}</div>

                  {sameDay === false ?
                    <div style={{marginTop: 3}}>&nbsp;</div>
                  : null}

                  <div style={{marginTop: 3}}>{holiday.vakantie_opmerking === 'null' ? '' : holiday.vakantie_opmerking}&nbsp;</div>

                  {/* <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                  <div style={{marginTop: 3,}}>{trade.fn1}</div>

                  <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_from)}</div>

                  <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v1}</div>

                  <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v2}</div> */}

                  <div className='trade_normalButton' style={{marginTop: 8}} onClick={() => this.openHolidayRequestsModal(1, holiday, sameDay)}>
                    Wijzigen
                  </div>

                </div>

                <div className={'trade_namesContainer'}>

                  <div>{date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()}{sameDay === false ? ' t/m' : ''}</div>

                  {sameDay === false ?
                    <div style={{marginTop: 3}}>{dateEnd.getDate()} {Constants.getMonthName(dateEnd)} {dateEnd.getFullYear()}</div>
                  : null}

                  {vHours !== null ?
                    vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ?
                      <div style={{marginTop: 3}}>Hele dag (8 uren)</div>
                    :
                      <div style={{marginTop: 3}}>{vHours.vu_begin.substr(0,5)} - {vHours.vu_eind.substr(0,5)} ({vHours.total} {vHours.total === 1 ? 'uur' : 'uren'})</div>
                  : 
                    <div style={{marginTop: 3}}>&nbsp;</div>
                  }

                  {/* <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                  <div style={{marginTop: 3,}}>{trade.fn2}</div>

                  <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_to)}</div>

                  <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v2}</div>

                  <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v1}</div> */}

                   <div className='trade_denyButton' style={{marginTop: 8, backgroundColor: 'transparent', cursor: 'default', color: 'transparent'}}>
                    &nsbp;
                  </div>

                </div>

              </div>

          </div>
          );

      }

    }

    return holidays;

  }

  getHolidayRequestBarsRefused() {

    let holidays = [];

    for(let key in this.state.holidayRequests) {

      const holiday = this.state.holidayRequests[key];

      if(holiday.vakantie_geaccepteerd === '2') { //open ruildienst

          const date = Constants.stringToDate(holiday.vakantie_begin);
          const dateEnd = Constants.stringToDate(holiday.vakantie_eind);

          const sameDay = date.getTime() === dateEnd.getTime();

          let vHours = null;
          if (sameDay === true) { // verlof
            if (typeof this.state.vHours[holiday.vakantie_id] !== 'undefined') {
              vHours = this.state.vHours[holiday.vakantie_id];
              vHours.total = parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
            }
          }

          const userFullName = `${holiday.info_voornaam} ${(holiday.info_tussenvoegsel !== null && holiday.info_tussenvoegsel !== '') ? holiday.info_tussenvoegsel + " " + holiday.info_achternaam.charAt(0) : holiday.info_achternaam.charAt(0)}.`;

          holidays.push(
          <div 
              className={'trade_tradeBar'} 
              style={{borderColor: Colors.color.redFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.1)}}
          >   

              <div style={{backgroundColor: Colors.color.redFilledIn, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                <span style={{textAlign: 'center', color: Constants.getContrast(Colors.color.redFilledIn)}}>Afgewezen</span>
              </div>

              <div className='trade_tradeContainer'>

                <div className={'trade_functionContainer'}>

                  <div style={{fontWeight: 'bold'}}>{userFullName}</div>

                  {sameDay === false ?
                    <div style={{marginTop: 3}}>&nbsp;</div>
                  : null}

                  <div style={{marginTop: 3}}>{holiday.vakantie_opmerking === 'null' ? '' : holiday.vakantie_opmerking}&nbsp;</div>

                  {/* <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                  <div style={{marginTop: 3,}}>{trade.fn1}</div>

                  <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_from)}</div>

                  <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v1}</div>

                  <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v2}</div> */}

                  <div className='trade_normalButton' style={{marginTop: 8}} onClick={() => this.openHolidayRequestsModal(1, holiday, sameDay)}>
                    Wijzigen
                  </div>

                </div>

                <div className={'trade_namesContainer'}>

                  <div>{date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()}{sameDay === false ? ' t/m' : ''}</div>

                  {sameDay === false ?
                    <div style={{marginTop: 3}}>{dateEnd.getDate()} {Constants.getMonthName(dateEnd)} {dateEnd.getFullYear()}</div>
                  : null}

                  {vHours !== null ?
                    vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ?
                      <div style={{marginTop: 3}}>Hele dag (8 uren)</div>
                    :
                      <div style={{marginTop: 3}}>{vHours.vu_begin.substr(0,5)} - {vHours.vu_eind.substr(0,5)} ({vHours.total} {vHours.total === 1 ? 'uur' : 'uren'})</div>
                  : 
                    <div style={{marginTop: 3}}>&nbsp;</div>
                  }

                  {/* <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                  <div style={{marginTop: 3,}}>{trade.fn2}</div>

                  <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_to)}</div>

                  <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v2}</div>

                  <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v1}</div> */}

                  <div className='trade_denyButton' style={{marginTop: 8, backgroundColor: 'transparent', cursor: 'default', color: 'transparent'}}>
                    &nsbp;
                  </div>

                </div>

              </div>

          </div>
          );

      }

    }

    return holidays;

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    const holidayRequestBarsOpen = this.getHolidayRequestBarsOpen();
    const holidayRequestBarsAccepted = this.getHolidayRequestBarsAccepted();
    const holidayRequestBarsRefused = this.getHolidayRequestBarsRefused();

    return (
        <div className='holidayRequests'>

          <Navbar history={this.props.history} active={'holiday_requests'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'holidayRequests'} parent={this} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Verlofverzoeken
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='tradeRequests_container' style={{flexDirection: 'column'}}>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <HolidayRequestsModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _oneDay={this.state.oneDay}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAddHolidayModal === true ?
                
                <AddHolidayModal 
                  _closeModal={this.closeModal.bind(this)}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {this.state.holidayRequests.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen verlofverzoeken op dit moment</div>
                </div>

              :

                null
              
              }

              {/* <div className="planner_addButtons_container" style={{paddingLeft: '4vw', marginBottom: '1vw', marginTop: '2vw'}}>

                <div className="planner_new_addButton" onClick={() => this.openAddHoliday()}>
                  Toevoegen
                </div>

              </div> */}

              <div style={{flexDirection: 'row', display: 'flex'}}>

                <div className={'tradeRequests_dataContainer'}>

                  <div className={'tradeRequests_dataContainerTitle'}>

                      Aangevraagde verlofdagen

                      {holidayRequestBarsOpen.length > 0 ?
                      
                          <i className={'fa fa-exclamation'} style={{marginLeft: 8, color: Colors.color.redFilledIn, fontSize: '1vw'}}></i>

                      :

                          null

                      }

                  </div>

                  {holidayRequestBarsOpen}

                </div>

                <div className={'tradeRequests_dataContainer'}>

                  <div className={'tradeRequests_dataContainerTitle'}>

                      Goedgekeurde verlofdagen

                  </div>

                  {holidayRequestBarsAccepted}

                </div>

                <div className={'tradeRequests_dataContainer'}>

                  <div className={'tradeRequests_dataContainerTitle'}>

                      Afgewezen verlofdagen

                  </div>

                  {holidayRequestBarsRefused}

                </div>

              </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default HolidayRequests;