/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import APIAddFunction from '../../classes/APIAddFunction';

import Colors from '../../constants/Colors';
import APIADD from '../../classes/global/APIADD';
import APIGET from '../../classes/global/APIGET';

class TemplateModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        functionName: '',
        selectedTemplate: 0,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    if (this.state.selectedTemplate === 0) { // planning sjabloon

      const added = await this.props._saveTemplate(this.props._key + 1, this.state.functionName);

      if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

      }

    } else { // diensten sjabloon

      await APIADD.Request(`INSERT INTO DagDienstSjabloon`, `VALUES (NULL, '${this.state.functionName}')`);
      const ID = await APIGET.Request(`SELECT dagdienstsjabloon_id`, `FROM DagDienstSjabloon`, null, null, null, `ORDER BY dagdienstsjabloon_id DESC LIMIT 1`);

      if (ID.length > 0) {

        const added2 = await this.props._saveShiftTemplate(ID[0].dagdienstsjabloon_id);

        if(added2 === true) {

          this.closeModal();

          this.reloadScreen();

          return;

        }

      }

    }

  }

  checkValidations() {

    if(this.state.functionName === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen naam ingevoerd',
      });
      return false;
    }

    return true;

  }

  goBack() {

    this.props._closeModal();

  }

  selectTemplateType(event) {

    const type = parseInt(event.target.value);

    this.setState({ selectedTemplate: type });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="templateModal" className="modal" onClick={(e) => e.target === document.getElementById("ztemplateModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            
              <p>Sjabloon opslaan</p>

          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Naam sjabloon</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={100}
                            value={this.state.functionName}
                            onChange={(event) => this.setState({ functionName: event.target.value })}
                            placeholder={'Naam'}
                            disabled={this.state.loading}
                          />

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              <div className='modal-submitButton' onClick={() => this.saveFunction()}>
                <span>Toevoegen</span>
              </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default TemplateModal;