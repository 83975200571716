
import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIAddLocation {

    async Request(lat, lon, straal, name) {

        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/addLocation.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&lat=${lat}&lon=${lon}&straal=${straal}&name=${name}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === 1) {

            return true;

        } else if (sqlData === 0) {

            return false;

        } else {

            return false; //data

        }
        //return null;
    }
  
}
  
// singleton
export default (new APIAddLocation());