/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './home2.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import APIAddFunction from '../../classes/APIAddFunction';
import APIEditFunction from '../../classes/APIEditFunction';
import APIDeleteFunction from '../../classes/APIDeleteFunction';

import { SketchPicker, ChromePicker } from 'react-color';
import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';
import KodictNotifications from '../../constants/KodictNotifications';
import APIAddToNews from '../../classes/APIAddToNews';

class AddNewsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        title: '',
        text: '',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze functie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteFunction.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddToNews.Request(Data.data.accountData.account_id, this.state.title, this.state.text, Constants.getDateNowStatic().toISOString().substring(0, 10), 'news');

    if (added === true) {

        const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

        for (const user of this.props._users) {
            await KodictNotifications.sendPushNotification(user.info_id, 'news', 'Nieuws - ' + this.state.title, this.state.text);
        }

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  checkValidations() {

    if(this.state.title === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen titel ingevoerd',
      });
      return false;
    }

    if(this.state.text === '') {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen bericht ingevoerd',
        });
        return false;
      }

    return true;

  }

  goBack() {

    this.props._closeModal();

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="addNewsModal" className="modal" onClick={(e) => e.target === document.getElementById("zaddNewsModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            <p>Nieuwsbericht uitzenden</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <span style={{fontSize: '0.9vw'}}>Jouw medewerkers krijgen hier een melding van indien zij push-notificaties hebben toegestaan.</span>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Titel</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={25}
                            value={this.state.title}
                            onChange={(event) => this.setState({ title: event.target.value })}
                            placeholder={'Nieuwstitel'}
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Bericht</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <textarea 
                              className={'inputFieldBig'}
                              maxLength={5000}
                              value={this.state.text}
                              onChange={(event) => this.setState({ text: event.target.value })}
                              placeholder={'Nieuwsbericht'}
                          />

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              <div className='modal-submitButton' onClick={() => this.saveFunction()}>
                <span>Uitzenden</span>
              </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default AddNewsModal;