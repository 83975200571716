/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './appOptions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIADD from '../../../classes/global/APIADD';
import APIGET from '../../../classes/global/APIGET';
import APIDELETE from '../../../classes/global/APIDELETE';

class BonusModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,

        type: null,
        date: null,
        startTime: null,
        startText: '',
        endTime: null,
        endText: '',
        factor: 1.5,
        factor2: 2,

        wholeDay: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    if (this.modalData !== null) {

        const date = Constants.isEmpty(this.modalData.t_datum) === false ? Constants.stringToDate(this.modalData.t_datum) : null;
  
        if (date !== null) {
            this.setDate(date);
            this.setState({ type: '7' });
        } else if (Constants.isEmpty(this.modalData.t_dag) === false) {
            this.setState({ type: this.modalData.t_dag });
        } else if (Constants.isEmpty(this.modalData.t_elkedag) === false) {
            this.setState({ type: '8' });
        }

        const startTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), parseInt(this.modalData.t_begin.substring(0, 2)), parseInt(this.modalData.t_begin.substring(3, 5)), 0);
        const endTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), parseInt(this.modalData.t_eind.substring(0, 2)), parseInt(this.modalData.t_eind.substring(3, 5)), 0);

        this.setStartTime(startTime);
        this.setEndTime(endTime);

        this.setState({
            factor: this.modalData.t_factor,
        });

    }

    this.setState({ loading: false, });

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    this.setState({
        date: date,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze toeslag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteBonus.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkValidations() {

    if(Constants.isEmpty(this.state.type) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen moment geselecteerd`,
        });
        return false;
    }

    if(this.state.type === '7' && Constants.isEmpty(this.state.date) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen datum geselecteerd`,
        });
        return false;
    }

    if(Constants.isEmpty(this.state.startTime) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen begintijd ingevuld`,
        });
        return false;
    }
    if(Constants.isEmpty(this.state.endTime) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen eindtijd ingevuld`,
        });
        return false;
    }

    if(Constants.isEmpty(this.state.factor) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen uitbetaling ingevuld`,
        });
        return false;
    }

    if(Constants.isEmpty(this.state.factor2) === true) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen tijd-voor-tijd ingevuld`,
      });
      return false;
  }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveHoliday() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    if (this.state.type === '7') { // date

        await APIADD.Request(`INSERT INTO Toeslag`, `VALUES (NULL, '${Constants.dateToString(this.state.date)}', NULL, NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.factor}, ${this.state.factor2})`);

    } else if (this.state.type === '8') { // every day

        await APIADD.Request(`INSERT INTO Toeslag`, `VALUES (NULL, NULL, NULL, 1, '${this.state.startText}', '${this.state.endText}', ${this.state.factor}, ${this.state.factor2})`);

    } else { // every day

        await APIADD.Request(`INSERT INTO Toeslag`, `VALUES (NULL, NULL, ${this.state.type}, NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.factor}, ${this.state.factor2})`);

    }

    this.reloadScreen();
    this.closeModal();

  }

  async editBonus() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM Toeslag`, `WHERE t_id = ${this.modalData.t_id}`);

    if (this.state.type === '7') { // date

        await APIADD.Request(`INSERT INTO Toeslag`, `VALUES (NULL, '${Constants.dateToString(this.state.date)}', NULL, NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.factor}, ${this.state.factor2})`);

    } else if (this.state.type === '8') { // every day

        await APIADD.Request(`INSERT INTO Toeslag`, `VALUES (NULL, NULL, NULL, 1, '${this.state.startText}', '${this.state.endText}', ${this.state.factor}, ${this.state.factor2})`);

    } else { // every day

        await APIADD.Request(`INSERT INTO Toeslag`, `VALUES (NULL, NULL, ${this.state.type}, NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.factor}, ${this.state.factor2})`);

    }

    this.reloadScreen();
    this.closeModal();

  }

  async deleteBonus() {

    if (this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM Toeslag`, `WHERE t_id = ${this.modalData.t_id}`);

    this.reloadScreen();
    this.closeModal();

  }

  onChangeNumberDataFloat(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'factor') {

      let absAmount = this.state.factor;

      if (nr !== '') {
        absAmount = nr;
      } else {
        absAmount = '';
      }

      this.setState({ factor: absAmount });

    } else if (what === 'factor2') {

      let absAmount = this.state.factor2;

      if (nr !== '') {
        absAmount = nr;
      } else {
        absAmount = '';
      }

      this.setState({ factor2: absAmount });

    }

  }

  selectType(event) {

    this.setState({
        type: event.target.value,
    });

  }

  setWholeDay() {

    const startDate = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(), 0, 0, 0, 0);
    const endDate = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(), 0, 0, 0, 0);

    const startHours = startDate.getHours() < 10 ? `0${startDate.getHours()}` : startDate.getHours().toString();
    const startMins = startDate.getMinutes() < 10 ? `0${startDate.getMinutes()}` : startDate.getMinutes().toString();

    const endHours = endDate.getHours() < 10 ? `0${endDate.getHours()}` : endDate.getHours().toString();
    const endMins = endDate.getMinutes() < 10 ? `0${endDate.getMinutes()}` : endDate.getMinutes().toString();

    this.setState({
      startTime: startDate,
      startText: `${startHours}:${startMins}`,
      endTime: endDate,
      endText: `${endHours}:${endMins}`,
      wholeDay: !this.state.wholeDay,
      showError: false,
    });

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  render() {

    return (
        <div id="bonusModal" className="modal" onClick={(e) => e.target === document.getElementById("zbonusModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.modalData !== null ?
              <p>Toeslag wijzigen</p>
            :
              <p>Toeslag toevoegen</p>
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Wanneer</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <select 
                              name="Selecteer een optie" 
                              className={'dropDownField'}
                              style={{marginTop: 0}} 
                              onChange={(event) => this.selectType(event)}
                          >
                              <option disabled value={'null'} selected={null === this.state.type}>Selecteer een optie</option>
                              <option value={'7'} selected={'7' === this.state.type}>Specifieke datum</option>
                              <option disabled value={'null'} selected={false}>&nbsp;</option>
                              <option value={'8'} selected={'8' === this.state.type}>Elke dag</option>
                              <option disabled value={'null'} selected={false}>&nbsp;</option>
                              <option value={'1'} selected={'1' === this.state.type}>Maandag</option>
                              <option value={'2'} selected={'2' === this.state.type}>Dinsdag</option>
                              <option value={'3'} selected={'3' === this.state.type}>Woensdag</option>
                              <option value={'4'} selected={'4' === this.state.type}>Donderdag</option>
                              <option value={'5'} selected={'5' === this.state.type}>Vrijdag</option>
                              <option value={'6'} selected={'6' === this.state.type}>Zaterdag</option>
                              <option value={'0'} selected={'0' === this.state.type}>Zondag</option>
                          </select>

                        </div>

                    </div>

                    {this.state.type !== null ?

                        <div>

                            {this.state.type === '7' ?

                                 <div className='modal-row' style={{marginTop: '1vw'}}>

                                    <div className='modal-rowSub1'>
                                        <span><b>Datum</b></span>
                                    </div>

                                    <div className='modal-rowSub2'>

                                      <DatePicker
                                        selected={this.state.date}
                                        onChange={(date) => this.setDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        className={'inputFieldTime'}
                                        placeholderText={'Selecteer een datum'}
                                        locale={nl}
                                        showWeekNumbers
                                      />

                                    </div>

                                </div>

                            :

                                null

                            }

                            <div className='modal-row' style={{ marginTop: '2vw' }}>

                                <div className='modal-rowSub1'>
                                    <span><b>Hele dag</b></span>
                                    {/* <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Verstuur een e-mail met inloggegegevens. Indien je dit uitschakelt, kan dit later verzonden worden in Medewerkers {`>>`} -medewerker- {`>>`} Account</span> */}
                                </div>

                                <div className='modal-rowSub2'>

                                  {this.state.wholeDay === false ?

                                    <div className='smartPlanModal-checkbox' onClick={() => this.setWholeDay()}>
                                      <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                    </div>

                                    :

                                    <div className='smartPlanModal-checkbox' onClick={() => this.setWholeDay()}>
                                      <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                    </div>

                                  }

                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Van</b></span>
                                </div>

                                <div className='modal-rowSub2'>

                                   <DatePicker
                                      className={this.state.wholeDay ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                                      selected={this.state.startTime}
                                      onChange={(date) => this.setStartTime(date)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={1}
                                      timeCaption="Van"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      disabled={this.state.wholeDay === true}
                                  />

                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Tot</b></span>
                                </div>

                                <div className='modal-rowSub2'>

                                  <DatePicker
                                      className={this.state.wholeDay ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                                      selected={this.state.endTime}
                                      onChange={(date) => this.setEndTime(date)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={1}
                                      timeCaption="Tot"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      disabled={this.state.wholeDay === true}
                                  />

                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Factor (uitbetaling)</b></span>
                                </div>

                                <div className='modal-rowSub2'>

                                   <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('factor', 1, 999999999, event)}
                                    value={this.state.factor}
                                    placeholder={'Vermenigvuldigingsfactor'}
                                    type='number'
                                    step={0.01}
                                  />

                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Factor (tijd voor tijd)</b></span>
                                </div>

                                <div className='modal-rowSub2'>

                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('factor2', 1, 999999999, event)}
                                    value={this.state.factor2}
                                    placeholder={'Vermenigvuldigingsfactor'}
                                    type='number'
                                    step={0.01}
                                  />

                                </div>

                            </div>

                        </div>

                    : null}

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.modalData !== null ? 
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.modalData !== null ?      
                <div className='modal-submitButton' onClick={() => this.editBonus()}>
                  <span>Wijzigen</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveHoliday()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default BonusModal;