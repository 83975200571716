/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './tradeRequests.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';
import '../trade/trade.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import APIGetAllTrades from '../../../classes/APIGetAllTrades';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import TradeRequestsModal from './tradeRequestsModal';
import APIGetTeamPlanner from '../../../classes/APIGetTeamPlanner';

import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIDELETE from '../../../classes/global/APIDELETE';
import BetterAlert from '../../../components/alert/alert';
import KodictNotifications from '../../../constants/KodictNotifications';
import APITradeShift from '../../../classes/APITradeShift';

class TradeRequests extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.teamPlanner = [];

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      tradeRequests: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    await this.getTradeRequests();

    this.setState({loading: false});

  }

  openTradeRequestsModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  async getTradeRequests() {

    //let trades = await APIGetAllTrades.Request();
    let trades = await APIGET.Request(
        `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
        `FROM Ruilshift`,
        `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
        ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
        ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +
        ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
        ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
        ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id`,
        `WHERE (rs_geaccepteerd = 1 OR rs_geaccepteerd = 2 OR rs_geaccepteerd = 4) AND plan1.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)`,
        null,
        `ORDER BY rs_geaccepteerd, plan1.rooster_datum, plan1.rooster_begin, plan1.rooster_eind`
    );

    if(trades === 'error') {

        trades = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(trades === null) {

        trades = [];

    }

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

      for (const trade of trades) {

          const obj = await APIGetTeamPlanner.Request(trade.pd1);

          for (const teampl of obj) {
              this.teamPlanner = [...this.teamPlanner, teampl];
          }

          if (trade.pd1 !== trade.pd2) {

              const obj2 = await APIGetTeamPlanner.Request(trade.pd2);

              for (const teampl of obj2) {
                  this.teamPlanner = [...this.teamPlanner, teampl];
              }

          }

      }

      const oldTrades = trades;
      trades = [];

      for (const team of Data.data.ownTeams) {

          const arr = Constants.getTeamPlannerT2(this.teamPlanner, oldTrades, parseInt(team.team_id));

          for (const teampl of arr) {
              trades = [...trades, teampl];
          }

      }

    }

    this.setState({ tradeRequests: trades });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getTradeRequests();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getTradeRequestBarsOpen() {

    let trades = [];

    for(let key in this.state.tradeRequests) {

        const trade = this.state.tradeRequests[key];

        if(trade.rs_geaccepteerd === '1') { //open ruildienst

            const date = Constants.stringToDate(trade.pd1);
            const date2 = Constants.stringToDate(trade.pd2);

            const color = Colors.color.greyNotFilled;

            trades.push(
            <div>
                <div 
                    className={'trade_tradeBar'} 
                    style={{borderColor: color, backgroundColor: Constants.hexToRgbA(color, 0.1)}}
                >   

                    <div style={{backgroundColor: color, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                      <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van een goedkeuring</span>
                    </div>

                    <div className='trade_tradeContainer'>

                      <div className={'trade_functionContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb1.substring(0,5)} - {trade.pe1.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn1}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_from)}</div>

                        <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v1}</div>

                        <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v2}</div>

                        <div className='trade_denyButton' style={{marginTop: 8}} onClick={() => this.denyAlert(trade)}>
                          Afwijzen
                        </div>

                      </div>

                      <div style={{display: 'flex', flex: '0.2', justifyContent: 'center', alignItems: 'center', paddingBottom: 35}}>

                        <i className='fad fa-exchange' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}}/>

                      </div>

                      <div className={'trade_namesContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb2.substring(0,5)} - {trade.pe2.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn2}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_to)}</div>

                        <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v2}</div>

                        <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v1}</div>

                        <div className='trade_acceptButton' style={{marginTop: 8}} onClick={() => this.acceptAlert(trade)}>
                          Goedkeuren
                        </div>

                      </div>

                    </div>

                </div>
            </div>
            );

        }

    }

    return trades;

  }

  getTradeRequestBarsAccepted() {

    let trades = [];

    for(let key in this.state.tradeRequests) {

        const trade = this.state.tradeRequests[key];

        if(trade.rs_geaccepteerd === '2') { //open ruildienst

            const date = Constants.stringToDate(trade.pd1);
            const date2 = Constants.stringToDate(trade.pd2);

            const color = Colors.color.greenFilledIn;

            trades.push(
            <div>
                <div 
                    className={'trade_tradeBar'} 
                    style={{borderColor: color, backgroundColor: Constants.hexToRgbA(color, 0.1)}}
                >   

                    <div style={{backgroundColor: color, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                      <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Goedgekeurd</span>
                    </div>

                    <div className='trade_tradeContainer'>

                      <div className={'trade_functionContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb1.substring(0,5)} - {trade.pe1.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn1}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_from)}</div>

                        <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v1}</div>

                        <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v2}</div>

                        <div className='trade_denyButton' style={{marginTop: 8}} onClick={() => this.denyAlert(trade)}>
                          Toch afwijzen
                        </div>

                      </div>

                      <div style={{display: 'flex', flex: '0.2', justifyContent: 'center', alignItems: 'center', paddingBottom: 35}}>

                        <i className='fad fa-exchange' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}}/>

                      </div>

                      <div className={'trade_namesContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb2.substring(0,5)} - {trade.pe2.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn2}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_to)}</div>

                        <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v2}</div>

                        <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v1}</div>

                        <div className='trade_denyButton' style={{marginTop: 8, backgroundColor: 'transparent', cursor: 'default', color: 'transparent'}}>
                          &nsbp;
                        </div>

                      </div>

                    </div>

                </div>
            </div>
            );

        }

    }

    return trades;

  }

  getTradeRequestBarsRefused() {

    let trades = [];

    for(let key in this.state.tradeRequests) {

        const trade = this.state.tradeRequests[key];

        if(trade.rs_geaccepteerd === '4') { //open ruildienst

            const date = Constants.stringToDate(trade.pd1);
            const date2 = Constants.stringToDate(trade.pd2);

            const color = Colors.color.redFilledIn;

            trades.push(
            <div>
                <div 
                    className={'trade_tradeBar'} 
                    style={{borderColor: color, backgroundColor: Constants.hexToRgbA(color, 0.1)}}
                >   

                    <div style={{backgroundColor: color, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                      <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Afgewezen</span>
                    </div>

                    <div className='trade_tradeContainer'>

                      <div className={'trade_functionContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb1.substring(0,5)} - {trade.pe1.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn1}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_from)}</div>

                        <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v1}</div>

                        <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v2}</div>

                        <div className='trade_denyButton' style={{marginTop: 8, backgroundColor: 'transparent', cursor: 'default', color: 'transparent'}}>
                          &nsbp;
                        </div>

                      </div>

                      <div style={{display: 'flex', flex: '0.2', justifyContent: 'center', alignItems: 'center', paddingBottom: 35}}>

                        <i className='fad fa-exchange' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}}/>

                      </div>

                      <div className={'trade_namesContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb2.substring(0,5)} - {trade.pe2.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn2}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, trade.rs_rooster_id_to)}</div>

                        <div style={{marginTop: 3, textDecorationLine: 'line-through', color: Colors.color.redFilledIn}}>{trade.v2}</div>

                        <div style={{marginTop: 0, color: Colors.color.greenFilledIn}}>{trade.v1}</div>

                        <div className='trade_acceptButton' style={{marginTop: 8}} onClick={() => this.acceptAlert(trade)}>
                          Toch goedkeuren
                        </div>

                      </div>

                    </div>

                </div>
            </div>
            );

        }

    }

    return trades;

  }

  denyAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Afwijzen',
      alertBody: (
        <div>

          Weet je zeker dat je deze ruil wilt afwijzen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Afwijzen'],
      alertButtonAction: this.denyTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async denyTrade(trade) {

      if(this.state.loading === true) {
          return;
      }

      this.setState({loading: true});

      const deleted = await APIEDIT.Request(`UPDATE Ruilshift`, `SET rs_geaccepteerd = 4`, `WHERE rs_id = ${trade.rs_id}`);
  
      if (deleted === true) {
  
          if (trade.rs_geaccepteerd === '2') {
              await APITradeShift.Request(trade.rs_info_id_to, trade.rs_rooster_id_to);
              await APITradeShift.Request(trade.rs_info_id_from, trade.rs_rooster_id_from);
          }
  
          await this.getTradeRequests();
  
          const tradeDate = Constants.stringToDate(trade.pd1);
      }
  
      this.setState({loading: false});

  }

  acceptAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Accepteren',
      alertBody: (
        <div>

          Weet je zeker dat je deze ruil wilt goedkeuren?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Goedkeuren'],
      alertButtonAction: this.acceptTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async acceptTrade(trade) {

      if(this.state.loading === true) {
          return;
      }

      this.setState({loading: true});

      const deleted = await APIEDIT.Request(`UPDATE Ruilshift`, `SET rs_geaccepteerd = 2`, `WHERE rs_id = ${trade.rs_id}`);
  
      if (deleted === true) {
  
          await APITradeShift.Request(trade.rs_info_id_from, trade.rs_rooster_id_to);
          await APITradeShift.Request(trade.rs_info_id_to, trade.rs_rooster_id_from);
  
          await this.getTradeRequests();
  
          const tradeDate = Constants.stringToDate(trade.pd1);
          const tradeDate2 = Constants.stringToDate(trade.pd2);

      }
  
      this.setState({loading: false});

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    const tradeRequestBarsOpen = this.getTradeRequestBarsOpen();
    const tradeRequestBarsAccepted = this.getTradeRequestBarsAccepted();
    const tradeRequestBarsRefused = this.getTradeRequestBarsRefused();

    return (
        <div className='tradeRequests'>

          <Navbar history={this.props.history} active={'trade_requests'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'tradeRequests'} parent={this} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Ruilverzoeken
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='tradeRequests_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <TradeRequestsModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {this.state.tradeRequests.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen ruilverzoeken op dit moment</div>
                </div>

              :

                null
              
              }

              <div className={'tradeRequests_dataContainer'}>

                <div className={'tradeRequests_dataContainerTitle'}>

                    Openstaande ruilverzoeken

                    {tradeRequestBarsOpen.length > 0 ?
                    
                        <i className={'fa fa-exclamation'} style={{marginLeft: 8, color: Colors.color.redFilledIn, fontSize: '1vw'}}></i>

                    :

                        null

                    }

                </div>

                {tradeRequestBarsOpen}

              </div>

              <div className={'tradeRequests_dataContainer'}>

                <div className={'tradeRequests_dataContainerTitle'}>

                    Geaccepteerde ruilverzoeken

                </div>

                {tradeRequestBarsAccepted}

              </div>

              <div className={'tradeRequests_dataContainer'}>

                <div className={'tradeRequests_dataContainerTitle'}>

                    Afgewezen ruilverzoeken

                </div>

                {tradeRequestBarsRefused}

              </div>

              {/* {tradeRequestBars} */}

            </div>
        
          }

        </div>
    );
  }
  
}

export default TradeRequests;