/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './userHours';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import APIEditUserClockTime from '../../../classes/APIEditUserClockTime';
import APIDeleteUserClockTime from '../../../classes/APIDeleteUserClockTime';
import Constants from '../../../constants/Constants';

class UserHoursModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
        this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        clockInDate: null,
        clockInDateText: '',

        clockInTime: null,
        clockInTimeText: '00:00',

        clockOutDate: null,
        clockOutDateText: '',

        clockOutTime: null,
        clockOutTimeText: '00:00',

        breakTime: null,
        breakTimeText: '00:00',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    const clockInHour = parseInt(this.modalData.kloktijd_inklok_tijd.substring(0, 2));
    const clockInMin = parseInt(this.modalData.kloktijd_inklok_tijd.substring(3, 5));
    const clockOutHour = parseInt(this.modalData.kloktijd_uitklok_tijd.substring(0, 2));
    const clockOutMin = parseInt(this.modalData.kloktijd_uitklok_tijd.substring(3, 5));
    const breakHour = parseInt(this.modalData.kloktijd_pauze.substring(0, 2));
    const breakMin = parseInt(this.modalData.kloktijd_pauze.substring(3, 5));

    const clockInTime = new Date();
    const clockOutTime = new Date();
    const breakTime = new Date();
    clockInTime.setHours(clockInHour, clockInMin);
    clockOutTime.setHours(clockOutHour, clockOutMin);
    breakTime.setHours(breakHour, breakMin);

    this.setClockInTime(clockInTime);
    this.setClockOutTime(clockOutTime);
    this.setBreakTime(breakTime);

    const clockInDate = new Date(this.modalData.kloktijd_inklok_datum);
    const clockOutDate = new Date(this.modalData.kloktijd_uitklok_datum);
    // dateObject1.setDate(dateObject1.getDate() + 1);
    // dateObject2.setDate(dateObject2.getDate() + 1);

    const clockInDateText = clockInDate.toISOString().substring(0, 10);
    const clockOutDateText = clockOutDate.toISOString().substring(0, 10);

    this.setState({
        clockInDate: new Date(this.modalData.kloktijd_inklok_datum),
        clockInDateText: clockInDateText,
        clockOutDate: new Date(this.modalData.kloktijd_uitklok_datum),
        clockOutDateText: clockOutDateText,
    });

  }

  setClockInDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = new Date(date);

    const dateText = dateObject.toISOString().substring(0, 10);

    this.setState({
        clockInDate: date,
        clockInDateText: dateText,
        showError: false,
    });

  }

  setClockOutDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = new Date(date);

    const dateText = dateObject.toISOString().substring(0, 10);

    this.setState({
        clockOutDate: date,
        clockOutDateText: dateText,
        showError: false,
    });

  }

  setClockInTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const mins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        clockInTime: date,
        clockInTimeText: `${hours}:${mins}`,
        showError: false,
    });

  }

  setClockOutTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const mins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        clockOutTime: date,
        clockOutTimeText: `${hours}:${mins}`,
        showError: false,
    });

  }

  setBreakTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const mins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        breakTime: date,
        breakTimeText: `${hours}:${mins}`,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze inklok registratie wilt verwijderen? Deze actie kan niet meer ongedaan worden gemaakt',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteUserClockTime.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async editUserClockTime() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditUserClockTime.Request(
      this.state.clockInDateText,
      this.state.clockInTimeText,
      this.state.clockOutDateText,
      this.state.clockOutTimeText,
      this.state.breakTimeText,
      parseInt(this.modalData.kloktijd_id)
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteUserClockTime() {

    this.setState({loading: true});

    const added = await APIDeleteUserClockTime.Request(
      parseInt(this.modalData.kloktijd_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    const clockInDate = new Date(`${this.state.clockInDateText}T${this.state.clockInTimeText}:00.000Z`);
    const clockOutDate = new Date(`${this.state.clockOutDateText}T${this.state.clockOutTimeText}:00.000Z`);

    if(this.state.clockInDate === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen inklok datum ingevoerd',
      });
      return false;
    }
    if(this.state.clockInTime === null) {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen inklok tijd ingevoerd',
        });
        return false;
    }
    if(this.state.clockOutDate === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen uitklok datum ingevoerd',
      });
      return false;
    }
    if(this.state.clockOutTime === null) {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen uitklok tijd ingevoerd',
        });
        return false;
    }
    if(clockInDate > clockOutDate) {
        this.setState({
          showError: true,
          errorText: 'Inklok datum kan niet later zijn dan uitklok datum',
        });
        return false;
    }
    if(clockInDate.getTime() === clockOutDate.getTime()) {
        this.setState({
          showError: true,
          errorText: 'Inklok datum en tijd kunnen niet gelijk zijn aan uitklok datum en tijd',
        });
        return false;
    }

    return true;

  }

  render() {

    const userFullName = `${this.modalData.info_voornaam} ${(this.modalData.info_tussenvoegsel !== null && this.modalData.info_tussenvoegsel !== '') ? this.modalData.info_tussenvoegsel + " " + this.modalData.info_achternaam.charAt(0) : this.modalData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="userHoursModal" className="modal" onClick={(e) => e.target === document.getElementById("zuserHoursModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>            
              <p>{userFullName}</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Inklok datum:</div>

                    <DatePicker
                      selected={this.state.clockInDate}
                      onChange={(date) => this.setClockInDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className={'inputFieldTime'}
                      placeholderText={'Selecteer een datum'}
                      showWeekNumbers
                      locale={nl}
                    />

                    <div style={{marginTop: 5}}>Inklok tijd:</div>

                    <DatePicker
                        selected={this.state.clockInTime}
                        onChange={(date) => this.setClockInTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={'inputFieldTime'}
                    />

                    <div style={{marginTop: 10}}>Uitklok datum:</div>

                    <DatePicker
                    selected={this.state.clockOutDate}
                    onChange={(date) => this.setClockOutDate(date)}
                    dateFormat="dd-MM-yyyy"
                    className={'inputFieldTime'}
                    placeholderText={'Selecteer een datum'}
                    showWeekNumbers
                    locale={nl}
                    />

                    <div style={{marginTop: 5}}>Uitklok tijd:</div>

                    <DatePicker
                        selected={this.state.clockOutTime}
                        onChange={(date) => this.setClockOutTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={'inputFieldTime'}
                    />

                    <div style={{marginTop: 10}}>Pauze:</div>

                    <DatePicker
                        selected={this.state.breakTime}
                        onChange={(date) => this.setBreakTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={'inputFieldTime'}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :

                        <div>

                            <div className='submitButton' onClick={() => this.editUserClockTime()}>Opslaan</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default UserHoursModal;