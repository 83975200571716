import React from 'react';

//styles
import './registerUser.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APICheckUsernameAvailibility from '../../../classes/APICheckUsernameAvailibility';
import APIGetFunctions from '../../../classes/APIGetFunctions';
import APIRegisterAccount from '../../../classes/APIRegisterAccount';
import APIRegisterGetAccountID from '../../../classes/APIRegisterGetAccountID';
import APIRegisterAccountInfo from '../../../classes/APIRegisterAccountInfo';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import CryptoJS from 'crypto-js';

import BetterAlert from '../../../components/alert/alert';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAllRegistrationCodes from '../../../classes/APIGetAllRegistrationCodes';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';
import Constants from '../../../constants/Constants';

class RegisterUser extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountDataExtra.info2_registreren !== '1')) {
        this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      loading1: false,
      loading2: false,

      blockUser: false,
      blockInfo: true,

      username: '',
      password1: '',
      password2: '',

      showError1: false,
      errorText1: '',

      firstName: '',
      insertion: '',
      lastName: '',

      birthDate: null,
      birthText: '',
      birthplace: '',

      address: '',
      addressNumber: '',
      zipCode: '',
      city: '',

      email: '',
      email2: '',
      phone: '',
      phone2: '',

      functionID: null,
      rights: 0,

      functions: [],

      startDate: null,
      startDateText: '',

      iban: '',
      bsn: '',

      comment: '',

      showError2: false,
      errorText2: '',

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      privacyAgreed: false,

    };

    this.navBar = React.createRef();

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
        return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountDataExtra.info2_registreren !== '1')) {
        return;
    }

    await this.getFunctions();

    this.setState({loading: false});

  }

  addedAlert() {
    this.setState({
        alertTitle: 'Geregistreerd',
        alertBody: `${this.state.username} is geregistreerd!`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async getFunctions() {

    let functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(functionsData === null) {

        functionsData = [];

    }

    this.setState({functions: functionsData});

  }

  setBirthDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }
  
      const dateObject = new Date(date);

      dateObject.setDate(dateObject.getDate() + 1);
  
      const dateText = dateObject.toISOString().substring(0, 10);
  
      this.setState({
          birthDate: date,
          birthText: dateText,
          showError2: false,
      });

  }

  setStartDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }
  
      const dateObject = new Date(date);

      dateObject.setDate(dateObject.getDate() + 1);
  
      const dateText = dateObject.toISOString().substring(0, 10);
  
      this.setState({
          startDate: date,
          startDateText: dateText,
      });

  }

  async continue() {

    this.setState({loading1: true, showError1: false});

    if(await this.checkValidations1() === false) {
        this.setState({loading1: false});
        return;
    }

    this.setState({
        loading1: false,
        blockUser: true,
        blockInfo: false,
    });

    const smoothScroll = 'scrollBehavior' in document.documentElement.style;

    if(smoothScroll === true) {
        window.scrollTo({
            top: this.infoRef.offsetTop - 100,
            behavior: 'smooth',
        });
    } else {
        window.scrollTo(0, this.infoRef.offsetTop - 100);
    }

  }

  async previous() {

    this.setState({showError2: false});

    this.setState({
        blockUser: false,
        blockInfo: true,
    });

    const smoothScroll = 'scrollBehavior' in document.documentElement.style;

    if(smoothScroll === true) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    } else {
        window.scrollTo(0, 0);
    }

  }

  async allowRegistration() {
    let count = 0;

    const employeesObject = await APIGetAllAccounts.Request();

    if(employeesObject !== null) {
        count += employeesObject.length;
    }

    const registrationCodesObject = await APIGetAllRegistrationCodes.Request();

    if(registrationCodesObject !== null) {
        count += registrationCodesObject.length;
    }

    if(count >= (Data.data.maxEployees + 1)) {
        this.setState({
          alertTitle: 'Mislukt',
          alertBody: (<span>Je hebt het maximale aantal registratiecodes en medewerkers van <b>{Data.data.maxEployees}</b> bereikt</span>),
        alertButtons: 10,
        alertButtonText: ['OK'],        
          //   alertButtons: 2,
        //   alertButtonText: ['Terug', 'Upgrade'],
        //   alertButtonAction: () => this.navBar.current.openUpgradeMenu(),
          showAlert: true,
        });
        return false;
    }

    return true;
  } 

  async saveAccount() {

    this.setState({loading2: true, showError2: false});

    if(await this.checkValidations1() === false || await this.checkValidations2() === false || await this.allowRegistration() === false) {
        this.setState({loading2: false});
        return;
    }

    const accountAdded = await APIRegisterAccount.Request(
        this.state.username,
        CryptoJS.SHA512(this.state.password2).toString(),
        this.state.rights
    );

    if(accountAdded === true) {

        const accountIDObject = await APIRegisterGetAccountID.Request(this.state.username);

        const accountInfoAdded = await APIRegisterAccountInfo.Request(
            this.state.username,
            parseInt(accountIDObject[0].account_id),
            this.state.firstName,
            this.state.insertion,
            this.state.lastName,
            this.state.phone,
            this.state.email,
            this.state.functionID,
            this.state.comment,
            this.state.address,
            this.state.addressNumber,
            this.state.zipCode,
            this.state.birthText,
            this.state.privacyAgreed === true ? 1 : 0,
            this.state.startDate === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.startDateText,
            this.state.iban,
            this.state.bsn,
            this.state.city,
            this.state.phone2,
            this.state.birthplace,
        );

        if(accountInfoAdded === true) {

            await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${accountIDObject[0].account_id}, 1, 1, 1, 1, 1, 1, 1, 1)`);
            await APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${accountIDObject[0].account_id})`);

            await this.usersHaveAllColumnsDB();

            this.addedAlert();

            this.setState({
                loading1: false,
                loading2: false,

                blockUser: false,
                blockInfo: true,

                username: '',
                password1: '',
                password2: '',

                showError1: false,
                errorText1: '',

                firstName: '',
                insertion: '',
                lastName: '',

                birthDate: null,
                birthText: '',
                birthplace: '',

                address: '',
                addressNumber: '',
                zipCode: '',
                city: '',

                email: '',
                phone: '',
                phone2: '',

                functionID: null,
                rights: 0,

                comment: '',

                privacyAgreed: false,

                showError2: false,
                errorText2: '',

                startDate: null,
                startDateText: '',
                bsn: '',
                iban: '',
            });

            const smoothScroll = 'scrollBehavior' in document.documentElement.style;

            if(smoothScroll === true) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo(0, 0);
            }

            return;

        } else {

            this.setState({
                loading2: false,
                showError2: true,
                errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
            });

            return;

        }

    } else {

        this.setState({
            loading2: false,
            showError2: true,
            errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
        });

        return;

    }

  }

  async usersHaveAllColumnsDB() {

    const accounts = await APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id`, null, null, null);

    const info2 = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const info2Obj = Constants.convertToObject(info2, 'info2_info_id');

    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    const contractLeaveBalance = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalanceObj = Constants.convertToObjectWithArrays(contractLeaveBalance, 'cvb_contract_id');

    // const overhours = await APIGET.Request(`SELECT ov_id, ov_info_id`, `FROM Overuren`, null, null, null, null);
    // const overhoursObj = Constants.convertToObject(overhours, 'ov_info_id');

    let promises = [];

    // for (const acc of accounts) {
    //     if (typeof info2Obj[acc.info_id] === 'undefined') {
    //         promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${acc.info_id}, 1, 1, 1, 1, 1, 1, 1, 1)`));
    //     }
    //     if (typeof contractsObj[acc.info_id] === 'undefined') {
    //         promises.push(APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${acc.info_id})`));
    //     }
    //   // if (typeof overhoursObj[acc.info_id] === 'undefined') {
    //   //   promises.push(APIADD.Request(`INSERT INTO Overuren`, `VALUES (NULL, ${acc.info_id}, 0)`));
    //   // }
    // }

    for (const contract of contracts) {

        if (leaveBalances.length > 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {
  
          if (typeof contractLeaveBalanceObj[contract.contract_id] === 'undefined') {
  
            for (const leave of leaveBalances) {
              promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
            }
  
          } else {
  
            for (const leave of leaveBalances) {
              
              if (Constants.objectArrayContainsKey(contractLeaveBalanceObj[contract.contract_id], 'cvb_vb_id', leave.vb_id) === false) {
                promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
              }
  
            }
  
          }
  
        }
  
      }

    await Promise.all(promises);

  }

  showError1() {

    if(this.state.showError1 === true) {
      return <div className='errorText'>{this.state.errorText1}</div>;
    } else {
      return null;
    }

  }

  showError2() {

    if(this.state.showError2 === true) {
      return <div className='errorText'>{this.state.errorText2}</div>;
    } else {
      return null;
    }

  }

  async checkValidations1() {

    if(this.state.username === '') {
        this.setState({
            showError1: true,
            errorText1: 'Je hebt geen gebruikersnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9]*$/.test(this.state.username) === false) {
        this.setState({
            showError1: true,
            errorText1: 'Gebruikersnaam mag alleen letters en/of cijfers bevatten',
        });
        return false;
    }

    const userNameExists = await APICheckUsernameAvailibility.Request(this.state.username);

    if(userNameExists === true) {
        this.setState({
            showError1: true,
            errorText1: 'Deze gebruikersnaam bestaat al',
        });
        return false;
    }

    if(this.state.password1 === '') {
        this.setState({
            showError1: true,
            errorText1: 'Je hebt geen wachtwoord ingevoerd',
        });
        return false;
    }

    if(this.state.password2 === '') {
        this.setState({
            showError1: true,
            errorText1: 'Je moet het wachtwoord nog bevestigen',
        });
        return false;
    }

    if(this.state.password1 !== this.state.password2) {
        this.setState({
            showError1: true,
            errorText1: 'Wachtwoorden komen niet overeen',
        });
        return false;
    }

    return true;

  }

  async checkValidations2() {

    if(this.state.firstName === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen voornaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.firstName) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Voornaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.insertion !== '' && /^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.insertion) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Tussenvoegsel mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.lastName === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen achternaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.lastName) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Achternaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.birthDate === null || this.state.birthText === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen geboortedatum geselecteerd',
        });
        return false;
    }
    if(this.state.birthplace === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen geboorteplaats ingevoerd',
        });
        return false;
    }
    if(this.state.address === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen straatnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.address) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Straatnaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.addressNumber === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen huisnummer ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-()]*$/.test(this.state.addressNumber) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Huisnummer mag alleen letters, cijfers en -() bevatten',
        });
        return false;
    }
    if(this.state.zipCode === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen postcode ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.zipCode) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Postcode mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.city === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen plaatsnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.city) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Plaatsnaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.email === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen e-mailadres ingevoerd',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.email) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Geen geldig e-mailadres',
        });
        return false;
    }
    const emailValid = await this.checkEmailValid();
    if (emailValid === false) {
      this.setState({
          showError2: true,
          errorText2: 'Dit e-mailadres bestaat al',
      });
      return false;
    }
    if (this.state.email !== '' && this.state.email2 !== '') {
        if (this.state.email !== this.state.email2) {
            this.setState({
                showError2: true,
                errorText2: 'E-mailadressen komen niet overeen',
            });
            return false;
        }
    }
    if(this.state.phone === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen telefoonnummer ingevoerd',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phone) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Geen geldig telefoonnummer',
        });
        return false;
    }
    if(this.state.privacyAgreed === false) {
        this.setState({
            showError2: true,
            errorText2: 'Medewerker moet akkoord gaan met de privacyverklaring',
        });
        return false;
    }

    return true;

  }

    async checkEmailValid() {

        if (this.state.email !== '') {

            const data = await APIGET.Request(`SELECT info_id`, `FROM Info`, null, `WHERE info_email = '${this.state.email}'`, null, null);

            if (data.length > 0) {
                return false;
            }

        }

        return true;

    }

  getFunctionBars() {

    let functions = [];

    for(let key in this.state.functions) {

        functions.push(
            <option value={this.state.functions[key].functie_id} selected={parseInt(this.state.functions[key].functie_id) === this.state.functionID}>{this.state.functions[key].functie_naam}</option>
        );

    }

    return functions;

  }

  selectFunction(event) {

    this.setState({
        functionID: isNaN(parseInt(event.target.value)) === true ? null : parseInt(event.target.value),
        showError2: false,
    });

  }

  selectRight(event) {

    this.setState({
        rights: parseInt(event.target.value),
        showError2: false,
    });

  }

  toggleSwitch() {

    this.setState({
        privacyAgreed: !this.state.privacyAgreed,
        showError2: false,
    });

  }

  render() {

    const functions = this.getFunctionBars();

    return (
        <div className='registerUser'>

          <Navbar ref={this.navBar} history={this.props.history} active={'register_user'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Nieuwe medewerker registreren
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='registerUser_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  history={this.props.history}
                />

              :

                null
              
              }


                <div className={'registerUser_userContainer'}>

                    <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Inloggegevens</div>

                    <div>Gebruikersnaam: *</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={30}
                        value={this.state.username}
                        onChange={(event) => this.setState({ username: event.target.value, showError1: false })}
                        placeholder={'Gebruikersnaam'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    <div style={{marginTop: 10}}>Wachtwoord: *</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        type={'password'}
                        value={this.state.password1}
                        onChange={(event) => this.setState({ password1: event.target.value, showError1: false })}
                        placeholder={'Wachtwoord'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    <div style={{marginTop: 10}}>Bevestig wachtwoord: *</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        type={'password'}
                        value={this.state.password2}
                        onChange={(event) => this.setState({ password2: event.target.value, showError1: false })}
                        placeholder={'Bevestig wachtwoord'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    {this.state.blockUser === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}>Volgende</div>

                    :
                    this.state.loading1 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.continue()}>Volgende</div>

                    }

                    {this.showError1()}

                    <div style={{fontStyle: 'italic', marginTop: 10, fontSize: '1vw'}}>(*) = verplicht</div>

                </div>

                <div ref={(ref) => this.infoRef=ref} className={'registerUser_infoContainer'}>

                    {this.state.blockUser === true ?

                        <div style={{paddingLeft: '25%', paddingRight: '25%'}}>

                            <div className='submitButton' style={{marginBottom: 40}} onClick={() => this.previous()}>Terug</div>

                        </div>

                    :

                        null

                    }

                    <div className='registerUser_infoContainerRow'>

                        <div className='registerUser_infoContainer_sub' style={{paddingRight: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Persoonsgegevens</div>

                            <div>Voornaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.firstName}
                                onChange={(event) => this.setState({ firstName: event.target.value, showError2: false })}
                                placeholder={'Voornaam'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Tussenvoegsel:</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.insertion}
                                onChange={(event) => this.setState({ insertion: event.target.value, showError2: false })}
                                placeholder={'Tussenvoegsel'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Achternaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.lastName}
                                onChange={(event) => this.setState({ lastName: event.target.value, showError2: false })}
                                placeholder={'Achternaam'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Geboortedatum: *</div>

                            <DatePicker
                                selected={this.state.birthDate}
                                onChange={(date) => this.setBirthDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={this.state.blockInfo === false ? 'inputFieldTime' : 'inputFieldTimeDisabled'}
                                placeholderText={'Selecteer jouw geboortedatum'}
                                showWeekNumbers
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={nl}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Geboorteplaats: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={100}
                                value={this.state.birthplace}
                                onChange={(event) => this.setState({ birthplace: event.target.value, showError2: false })}
                                placeholder={'Geboorteplaats'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>E-mail: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                type={'email'}
                                value={this.state.email}
                                onChange={(event) => this.setState({ email: event.target.value, showError2: false })}
                                placeholder={'E-mail'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Bevestig e-mail: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                type={'email'}
                                value={this.state.email2}
                                onChange={(event) => this.setState({ email2: event.target.value, showError2: false })}
                                placeholder={'E-mail'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Telefoon: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={12}
                                type={'tel'}
                                value={this.state.phone}
                                onChange={(event) => this.setState({ phone: event.target.value, showError2: false })}
                                placeholder={'bv. 06 of 074'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Tweede telefoon:</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={12}
                                type={'tel'}
                                value={this.state.phone2}
                                onChange={(event) => this.setState({ phone2: event.target.value })}
                                placeholder={'Optioneel'}
                                disabled={this.state.blockInfo}
                            />

                            {Data.data.appOptions.iban === true ?

                                <div>

                                    <div style={{marginTop: 10}}>IBAN:</div>

                                    <input 
                                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                        maxLength={25}
                                        value={this.state.iban}
                                        onChange={(event) => this.setState({ iban: event.target.value })}
                                        placeholder={'bv. NL85 ABNA #########'}
                                        disabled={this.state.blockInfo}
                                    />

                                </div>

                            : null}

                            {Data.data.appOptions.bsn === true ?

                                <div>

                                    <div style={{marginTop: 10}}>BSN-nummer:</div>

                                    <input 
                                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                        maxLength={15}
                                        value={this.state.bsn}
                                        onChange={(event) => this.setState({ bsn: event.target.value })}
                                        placeholder={'Persoonsnummer'}
                                        disabled={this.state.blockInfo}
                                    />

                                </div>

                            : null}



                            {/* <div style={{marginTop: 10}}>Datum in dienst:</div>

                            <DatePicker
                                selected={this.state.startDate}
                                onChange={(date) => this.setStartDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={this.state.blockInfo === false ? 'inputFieldTime' : 'inputFieldTimeDisabled'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={nl}
                                disabled={this.state.blockInfo}
                            /> */}

                            <div style={{marginTop: 10}}>Aanvullende informatie: (max. 250 tekens)</div>

                            <textarea  
                                className={this.state.blockInfo === false ? 'inputFieldBig' : 'inputFieldBigDisabled'}
                                maxLength={250}
                                value={this.state.comment}
                                onChange={(event) => this.setState({ comment: event.target.value })}
                                disabled={this.state.blockInfo}
                            />

                        </div>

                        <div className='registerUser_infoContainer_sub' style={{paddingLeft: '1vw', paddingRight: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Adresgegevens</div>

                            <div>Straatnaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={80}
                                value={this.state.address}
                                onChange={(event) => this.setState({ address: event.target.value, showError2: false })}
                                placeholder={'Straatnaam'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Huisnummer: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={10}
                                value={this.state.addressNumber}
                                onChange={(event) => this.setState({ addressNumber: event.target.value, showError2: false })}
                                placeholder={'Huisnummer + toevoeging'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Postcode: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={20}
                                value={this.state.zipCode}
                                onChange={(event) => this.setState({ zipCode: event.target.value, showError2: false })}
                                placeholder={'Postcode'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                            />

                            <div style={{marginTop: 10}}>Plaatsnaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.city}
                                onChange={(event) => this.setState({ city: event.target.value, showError2: false })}
                                placeholder={'Plaatsnaam'}
                                disabled={this.state.blockInfo}
                            />

                        </div>

                        <div className='registerUser_infoContainer_sub' style={{paddingLeft: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Accountgegevens</div>

                            <div>Functie:</div>

                            <select 
                                name="Selecteer een functie" 
                                className={this.state.blockInfo === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                                disabled={this.state.blockInfo}
                                style={{marginTop: 5}} 
                                onChange={(event) => this.selectFunction(event)}
                            >
                                <option value={null} selected>Selecteer een functie</option>
                                {functions}
                            </select>

                            <div style={{marginTop: 10}}>Accountrechten: *</div>

                            <select 
                                name="Selecteer een accountrecht" 
                                className={this.state.blockInfo === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                                disabled={this.state.blockInfo}
                                style={{marginTop: 5}} 
                                onChange={(event) => this.selectRight(event)}
                            >
                                <option value={'0'} selected>Medewerker</option>
                                <option value={'1'}>Planner</option>
                                <option value={'2'}>Administrator</option>
                            </select>

                        </div>

                    </div>

                    <div style={{marginTop: 50, paddingLeft: '25%', paddingRight: '25%'}}>

                        <div style={{display: 'inline'}}>
                            Medewerker gaat akkoord met de <div style={{display: 'inline', color: '#47B7EB', cursor: 'pointer'}} onClick={() => window.open('https://tiemdo.nl/privacy.html', '_blank')}>privacyverklaring</div>
                        </div>

                        <label className="switch" style={{marginTop: 5, display: 'block'}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.privacyAgreed} 
                                onClick={() => this.toggleSwitch()}
                                disabled={this.state.blockInfo}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        {this.state.blockInfo === true ?

                            <div className='submitButtonLoading' style={{marginTop: 40}}>Registreren</div>

                        :
                        this.state.loading2 === true ?

                            <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                        :

                            <div className='submitButton' style={{marginTop: 40}} onClick={() => this.saveAccount()}>Registreren</div>

                        }

                        {this.showError2()}

                        <div style={{fontStyle: 'italic', marginTop: 10, marginBottom: 100, fontSize: '1vw'}}>(*) = verplicht</div>

                    </div>

                </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default RegisterUser;