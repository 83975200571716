import Data from '../../constants/Data';

class APIUpdateSubscriptionTrial {

    async Request(subid, quantity, trial, product) {

        let response = null;

          try {
              response = await fetch(`https://${Data.data.serverIP}/phppayments/updateSubscriptionTrial.php/`, {
                  method: 'post',
                  body: new URLSearchParams(`subid=${subid}&quantity=${quantity}&trial=${trial}&product=${product}`)
              });
          } catch(err) {
            //   console.log(err);
              return 'error';
          }

          let sqlData = null;

          try {
              sqlData = await response.json(); //or response.text() //response.json()
          } catch(err) {
              let response2 = null;
              try {
                  response2 = await fetch(`https://${Data.data.serverIP}/phppayments/updateSubscriptionTrial.php/`, {
                  method: 'post',
                  body: new URLSearchParams(`subid=${subid}&quantity=${quantity}&trial=${trial}&product=${product}`)
                  });
              } catch(err) {
                //   console.log(err);
                  return 'error';
              }
              const error = await response2.text();
            //   console.log(error);
          }


        return sqlData;


        // if (sqlData === "1") {

        //     return true;

        // } else if (sqlData === "0") {

        //     return false;

        // } else {

        //     return false; //data

        // }
        // //return null;
        
        }
  
}
  
// singleton
export default (new APIUpdateSubscriptionTrial());