/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './reports.scss';
import '../hoursRegistration/hoursRegistration.css';
import '../myHours/myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../../constants/DefaultStyle.css';
import '../../employees/employees.css';
import '../appOptions/appOptions.css';
import '../times/times.css';
import '../functions/functions.css';
import '../../finances/finances.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import { Tooltip } from 'react-tippy';
import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetSections from '../../../classes/APIGetSections';
import APIGetFunctions from '../../../classes/APIGetFunctions';

import APIGET from '../../../classes/global/APIGET';
import Colors from '../../../constants/Colors';
import Absences from '../absences/absences';
import NoteModal from './noteModal';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIADD from '../../../classes/global/APIADD';
import APIDELETE from '../../../classes/global/APIDELETE';
import HoursRegistration from '../hoursRegistration/hoursRegistration';
import APIGetAbsencetypes from '../../../classes/APIGetAbsencetypes';
import Plan from '../../planner/plan.class';
import { Prompt } from 'react-router';
import Localization from '../../../constants/Localization';
import __ from '../../../constants/__';

class Reports extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.salaryFactor = isNaN(parseFloat(Data.data.appOptions.opties_loonfactor)) === false ? parseFloat(Data.data.appOptions.opties_loonfactor) : 1;

    // compwillmount

    this.dateNow = new Date();
    this.teamUsers = [];

    this.times_initDate = this.props.location.times_date !== undefined && this.props.location.times_date !== null ? this.props.location.times_date : null;

    const nextMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 2, this.dateNow.getDate());

    const local = {

      holidays: localStorage.getItem('report_general_show_holidays') === '0' ? false : true,
      holidayRequests: localStorage.getItem('report_general_show_holidayRequests') === '0' ? false : true,
      unavailables: localStorage.getItem('report_general_show_unavailables') === '0' ? false : true,
      availables: localStorage.getItem('report_general_show_availables') === '0' ? false : true,
      notes: localStorage.getItem('report_general_show_notes') !== null ? JSON.parse(localStorage.getItem('report_general_show_notes')) : [],

      weekends: localStorage.getItem('report_general_show_weekends') === '0' ? false : true,
      freeDays: localStorage.getItem('report_general_show_freeDays') === '0' ? false : true,
      sticky: localStorage.getItem('report_general_sticky') === '0' ? false : true,

      avail_availables: localStorage.getItem('report_avail_show_availables') === '0' ? false : true,
      avail_unavailables: localStorage.getItem('report_avail_show_unavailables') === '0' ? false : true,
      avail_notfilled: localStorage.getItem('report_avail_show_notfilled') === '0' ? false : true,

      finances_period: localStorage.getItem('report_finances_period') === '0' ? 0 : 1,

    };

    // params
    let paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : 'general';
    const paramHighlight = this.props.location.highlight !== undefined && this.props.location.highlight !== null ? this.props.location.highlight : null;

    const param_general_start = this.props.location.general_start !== undefined && this.props.location.general_start !== null ? this.props.location.general_start : this.dateNow;
    const param_general_end = this.props.location.general_end !== undefined && this.props.location.general_end !== null ? this.props.location.general_end : nextMonth;
    
    if (this.props.individual === true) {
      paramMenu = this.props.individual_menu;
    }

    this.state = {

      highlight: paramHighlight,

      loading: true,
      reloading: false,

      general_show_noteModal: false,

      modalMode: 0,
      modalData: [],
      modalDate: null,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      menu: paramMenu,

      // general
      general_start: param_general_start,
      general_end: param_general_end,
      general_function: 'null',

      general_show_holidays: local.holidays,
      general_show_holidayRequests: local.holidayRequests,
      general_show_unavailables: local.unavailables,
      general_show_availables: local.availables,
      general_show_notes: local.notes,

      general_show_weekends: local.weekends,
      general_show_freeDays: local.freeDays,
      general_sticky: local.sticky,

      general_has_freedays: false,

      general_holidays: {},
      general_holidaysRequest: {},
      general_unavailables: {},
      general_notes: [],
      general_publicHolidays: [],
      general_repeats: {},

      // availability
      avail_start: new Date(new Date().getFullYear(), 0, 1),
      avail_end: new Date(new Date().getFullYear(), 11, 31),
      avail_sort: 'name ASC',

      avail_show_notfilled: local.avail_notfilled,
      avail_show_availables: local.avail_availables,
      avail_show_unavailables: local.avail_unavailables,

      avail_availability: [],

      // gains
      gains_date: this.dateNow,
      
      gains_gains: {},

      // gainsExpect
      egains_date: this.dateNow,
      
      egains_gains: {},

      // trades
      trades_start: new Date(new Date().getFullYear(), 0, 1),
      trades_end: new Date(new Date().getFullYear(), new Date().getMonth(), Constants.getDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
      trades_employee: 'all',
      trades_sort: 'ORDER BY s1.rooster_datum ASC, s1.rooster_begin ASC, s2.rooster_datum ASC, s2.rooster_begin ASC',

      trades_trades: [],

      // absences
      abs_start: new Date(new Date().getFullYear(), 0, 1),
      abs_end: new Date(new Date().getFullYear(), 11, 31),
      abs_employee: 'all',
      abs_sort: 'name ASC',

      abs_absence_types: [],
      abs_absences: [],

      // finances
      finances_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      finances_end: new Date(new Date().getFullYear(), new Date().getMonth(), Constants.getDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
      finances_month: new Date(),
      finances_employee: 'all',
      finances_sort: 'name ASC',
      finances_type: 0, // 0 list, 1 graph
      finances_period: local.finances_period, // 0 month, 1 period
      finances_bonus: true,

      finances_finances: [],
      finances_all: {},

      // overhours
      overhours_year: new Date().getFullYear().toString(),
      overhours_type: 0, // 0 total, 1 weekly
      overhours_sort: 'name ASC',

      overhours_years: [],
      overhours_overhours: {},

      hoursWorked: [],
      showWeekDetails: [],
      absAmount2: {},
      schedule2: {},
      overhours_corrections: {},
      infoExtra: {},

      // overhours corrections
      overhoursC_year: new Date().getFullYear().toString(),
      overhoursC_type: 1, // 0 all, 1 paid, 2 corrections
      overhoursC_sort: 'date DESC',

      overhoursC_years: [],
      overhoursC_corrections: {},

      // minmax contract
      minmax_start: new Date(new Date().getFullYear(), 0, 1),
      minmax_end: new Date(),
      minmax_employee: 'all',
      minmax_sort: 'name ASC',

      minmax_hours: [],

      // flex contract
      flex_start: new Date(new Date().getFullYear(), 0, 1),
      flex_end: new Date(),
      flex_employee: 'all',
      flex_sort: 'name ASC',

      flex_hours: [],


      accounts: [],
      functions: [],
      sections: [],
      bonus: [],
      contracts: {},

      changes: false,

      allHours_users: {},

    };

  }

  async componentDidUpdate() {

    if (this.props.individual === true && this.state.menu !== this.props.individual_menu) {

      this.setState({ menu: this.props.individual_menu, loading: true }, async () => {

        await this.getData(this.state.menu);
        this.setState({ loading: false });

      });

    }

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    const functions = await APIGetFunctions.Request();
    const sections = await APIGetSections.Request();
    const bonus = await APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, `ORDER BY t_factor DESC`);
    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');
    
    this.setState({ functions: functions, sections: sections, bonus: bonus, contracts: contractsObj }, async () => { //contracts: contractsObj

      await this.getData(this.state.menu);

      this.setState({ loading: false });

    });

  }

  async getData(menu) {

    this.dateNow = await Constants.getDateNow();
    await this.getAccounts();

    if (menu === 'general') {

      const publicHolidays = await APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${this.state.general_start.getFullYear()}`, null, `ORDER BY f_datum`);

      let hasFreeDays = false;

      let index = 0;
      while(new Date(this.state.general_start.getFullYear(), this.state.general_start.getMonth(), this.state.general_start.getDate() + index).getTime() <= this.state.general_end.getTime()) {
        const freeDay = Constants.getNationalHoliday(new Date(this.state.general_start.getFullYear(), this.state.general_start.getMonth(), this.state.general_start.getDate() + index), publicHolidays);
        if (freeDay !== null) {
          hasFreeDays = true;
          break;
        }
        index++;
      }

      this.setState({ general_has_freedays: hasFreeDays, general_publicHolidays: publicHolidays });

      await Promise.all([
        this.general_getHolidaysData(),
        this.general_getHolidayRequestsData(),
        this.general_getUnavailablesData(),
        this.general_getAvailablesData(),
        this.general_getNotesData(),
        this.general_getRepeatsData(),
      ]);

    } else if (menu === 'availability') {

      await Promise.all([
        this.avail_getAvailabilityData(),
      ]);

    } else if (menu === 'gains') {

      await Promise.all([
        this.gains_getGainsData(),
      ]);

    } else if (menu === 'egains') {

      await Promise.all([
        this.egains_getGainsData(),
      ]);

    } else if (menu === 'trades') {

      await Promise.all([
        this.trades_getTradesData(),
      ]);

    } else if (menu === 'absences') {

      await Promise.all([
        this.abs_getAbsenceTypesData(),
        this.abs_getAbsencesData(),
      ]);

    } else if (menu === 'finances') {

      await Promise.all([
        this.finances_getFinancesData(),
      ]);

    } else if (menu === 'overhours' || menu === 'overhours_corrections') {

      await Promise.all([
        this.overhours_getOverhoursData(),
      ]);

    } else if (menu === 'minmax') {

      await Promise.all([
        this.minmax_getMinmaxData(),
      ]);

    } else if (menu === 'flex') {

      await Promise.all([
        this.flex_getFlexData(),
      ]);

    }

  }

  async reloadScreen() {

    this.setState({ reloading: true });
  
    await this.getData(this.state.menu);

    this.setState({ reloading: false, });

  }

  async hardReloadScreen() {

    this.setState({ loading: true });
  
    await this.getData(this.state.menu);

    this.setState({ loading: false, });

  }

  async getAccounts() {

    let accounts = [];
    let where = `WHERE info_id > 0`;
    let rightJoin = this.state.general_function !== 'null' ? `LEFT JOIN Functie ON functie_id = info_functie_id` : ``;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    if (this.state.general_function !== 'null') {
      if (this.state.general_function.includes('s')) {
        where += ` AND functie_afdeling_id = ${this.state.general_function.substring(1)}`
      } else {
        where += ` AND functie_id = ${this.state.general_function.substring(1)}`
      }
    }

    accounts = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel`, 
      `FROM Info`,
      rightJoin,
      where,
      null,
      `ORDER BY info_voornaam, info_achternaam`
    );

    if (this.state.menu === 'minmax') {

      let _newAccounts = [];
      for (const acc of accounts) {
        const contract = Constants.getCurrentContract(this.state.contracts[acc.info_id], this.dateNow);
        if (contract !== null && contract.contract_type === '1') {
          _newAccounts.push(acc);
        }
        accounts = _newAccounts;
      }

    }
    
    if (this.state.menu === 'flex') {

      let _newAccounts = [];
      for (const acc of accounts) {
        const contract = Constants.getCurrentContract(this.state.contracts[acc.info_id], this.dateNow);
        if (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type)) {
          _newAccounts.push(acc);
        }
        accounts = _newAccounts;
      }

    }

    this.setState({
      accounts: accounts,
    });

  }

  async general_getHolidaysData() {

    let holidayRequests = [];
    let where = `WHERE (vakantie_begin BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}' OR vakantie_eind BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}')`;
    let rightJoin = ``;

    where += ` AND vakantie_geaccepteerd = 1`;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    if (this.props.individual === true) {
      where += ` AND vakantie_info_id = ${this.props.individual_userID}`;
    }

    holidayRequests = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, 
      `FROM Vakantie`,
      `LEFT JOIN Info ON vakantie_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      null,
      null
    );

    let general_holidays = [];

    for (const h of holidayRequests) {

      let startDate = Constants.stringToDate(h.vakantie_begin);
      let endDate = Constants.stringToDate(h.vakantie_eind);

      if (h.vakantie_begin === h.vakantie_eind) {

        general_holidays.push({
          ...h,
          date: startDate,
          dateString: Constants.dateToString(startDate),
        });

      } else {

        let index = 0;
        while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

          const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

          general_holidays.push({
            ...h,
            date: currentDate,
            dateString: Constants.dateToString(currentDate),
          });

          index++;

        }

      }

    }

    general_holidays = Constants.convertToObjectWithArrays(general_holidays, 'vakantie_info_id');

    this.setState({
      general_holidays: general_holidays,
    });

  }

  async general_getHolidayRequestsData() {

    let holidayRequests = [];
    let where = `WHERE (vakantie_begin BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}' OR vakantie_eind BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}')`;
    let rightJoin = ``;

    where += ` AND vakantie_geaccepteerd = 0`;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    if (this.props.individual === true) {
      where += ` AND vakantie_info_id = ${this.props.individual_userID}`;
    }

    holidayRequests = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, 
      `FROM Vakantie`,
      `LEFT JOIN Info ON vakantie_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      null,
      null
    );

    let general_holidaysRequest = [];

    for (const h of holidayRequests) {

      let startDate = Constants.stringToDate(h.vakantie_begin);
      let endDate = Constants.stringToDate(h.vakantie_eind);

      if (h.vakantie_begin === h.vakantie_eind) {

        general_holidaysRequest.push({
          ...h,
          date: startDate,
          dateString: Constants.dateToString(startDate),
        });

      } else {

        let index = 0;
        while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

          const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

          general_holidaysRequest.push({
            ...h,
            date: currentDate,
            dateString: Constants.dateToString(currentDate),
          });

          index++;

        }

      }

    }

    general_holidaysRequest = Constants.convertToObjectWithArrays(general_holidaysRequest, 'vakantie_info_id');

    this.setState({
      general_holidaysRequest: general_holidaysRequest,
    });

  }

  async general_getUnavailablesData() {

    let unavailables = [];

    if (Data.data.appOptions.availability === 'STANDARD') {

      let where = `WHERE sb_beschikbaar = 2`;
      let rightJoin = ``;

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
        rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
        where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      if (this.props.individual === true) {
        where += ` AND sb_info_id = ${this.props.individual_userID}`;
      }

      unavailables = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, StandaardBeschikbaarheid.*`, 
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info ON sb_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
        where,
        null,
        null
      );

      unavailables = Constants.convertToObjectWithArrays(unavailables, 'sb_info_id');

    } else {

      let where = `WHERE (onbeschikbaar_datum BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}')`;
      let rightJoin = ``;

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
        rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
        where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      unavailables = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Onbeschikbaar.*`, 
        `FROM Onbeschikbaar`,
        `LEFT JOIN Info ON onbeschikbaar_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
        where,
        null,
        null
      );

      unavailables = Constants.convertToObjectWithArrays(unavailables, 'onbeschikbaar_info_id');

    }

    this.setState({
      general_unavailables: unavailables,
    });

  }

  async general_getAvailablesData() {

    let unavailables = [];

    if (Data.data.appOptions.availability === 'STANDARD') {

      let where = `WHERE sb_beschikbaar = 1`;
      let rightJoin = ``;

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
        rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
        where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      if (this.props.individual === true) {
        where += ` AND sb_info_id = ${this.props.individual_userID}`;
      }

      unavailables = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, StandaardBeschikbaarheid.*`, 
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info ON sb_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
        where,
        null,
        null
      );

      unavailables = Constants.convertToObjectWithArrays(unavailables, 'sb_info_id');

    } else {

      let where = `WHERE (beschikbaar_datum BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}')`;
      let rightJoin = ``;

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
        rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
        where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      unavailables = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Beschikbaar.*`, 
        `FROM Beschikbaar`,
        `LEFT JOIN Info ON beschikbaar_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
        where,
        null,
        null
      );

      unavailables = Constants.convertToObjectWithArrays(unavailables, 'beschikbaar_info_id');

    }

    this.setState({
      general_availables: unavailables,
    });

  }

  async general_getNotesData() {

    let notes = [];
    //let where = `WHERE (rn_begin BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}' OR rn_eind BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}')`;

    notes = await APIGET.Request(
      `SELECT *`, 
      `FROM RapportNotitie`,
      null,
      null,
      null,
      `ORDER BY rn_naam`
    );

    let general_notes = [];

    for (const h of notes) {

      let startDate = Constants.stringToDate(h.rn_begin);
      let endDate = Constants.stringToDate(h.rn_eind);

      if (h.rn_begin === h.rn_eind) {

        general_notes.push({
          ...h,
          date: startDate,
          dateString: Constants.dateToString(startDate),
        });

      } else {

        let index = 0;
        while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

          const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

          general_notes.push({
            ...h,
            date: currentDate,
            dateString: Constants.dateToString(currentDate),
          });

          index++;

        }

      }

    }

    general_notes = Constants.convertToObjectWithArrays(general_notes, 'rn_naam');

    this.setState({
      general_notes: general_notes,
    });

  }

  async general_getRepeatsData() {

    let repeats = [];
    //let where = `WHERE (rn_begin BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}' OR rn_eind BETWEEN '${Constants.dateToString(this.state.general_start)}' AND '${Constants.dateToString(this.state.general_end)}')`;

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      repeats = await APIGET.Request(
        `SELECT *`, 
        `FROM HerBeschikbaar`,
        `LEFT JOIN TeamUsers ON teamu_info_id = hb_info_id`,
        `WHERE teamu_team_id = ${Data.data.chosenTeamObj.team_id}`,
        null,
        null
      );

    } else {

      repeats = await APIGET.Request(
        `SELECT *`, 
        `FROM HerBeschikbaar`,
        null,
        null,
        null,
        null
      );

    }

    const general_repeats = Constants.convertToObjectWithArrays(repeats, 'hb_info_id');

    this.setState({
      general_repeats: general_repeats,
    });

  }

  general_renderAll() {

    return (
      <div style={{marginTop: '2vw'}}>

        {this.general_renderAll_topBar()}

        {this.general_renderAll_users()}

      </div>
    );

  }

  general_renderAll_topBar() {

    let toRender = [];

    toRender.push(

      <div className='reports_general_topBar_mainBar'>

        <div className={'reports_general_user'}>

          <span>&nbsp;</span>

        </div>

        <div className={'reports_general_days'}>

          {this.general_renderAll_topBar_days()}

        </div>

      </div>

    );

    if (this.state.general_sticky === true) {

      toRender.push(
        <div className='stickyNote' style={{top: this.state.general_has_freedays && this.state.general_show_freeDays ? 'calc(6.5vw)' : 'calc(2.3vw + 1px)'}}>
          {this.general_renderAll_topBar_notes()}
        </div>
      );

    } else {  

      toRender.push(this.general_renderAll_topBar_notes());

    }

    return toRender;

  }

  general_renderAll_topBar_notes() {

    let toRender = [];

    for (const key in this.state.general_notes) {

      const note = this.state.general_notes[key][0];

      if (Constants.arrayContains(this.state.general_show_notes, note.rn_id) === true) {

        toRender.push(

          <div className='reports_general_mainBar'>

            <div className={'reports_general_user'}>

              <span style={{fontWeight: 'bold'}}>{note.rn_naam}</span>

            </div>

            <div className={'reports_general_days'}>

              {this.general_renderAll_topBar_note_days(this.state.general_notes[key])}

            </div>

          </div>

        );

      }

    }

    return toRender;

  }

  general_renderAll_topBar_days() {

    let toRender = [];

    toRender.push(

      <div className='reports_general_topBar_days_bar' style={{height: this.state.general_has_freedays && this.state.general_show_freeDays ? '6.5vw' : null}}>

        {this.general_renderAll_topBar_daysCol()}

      </div>

    );

    return toRender;

  }

  general_renderAll_topBar_daysCol() {

    let toRender = [];

    let startDate = this.state.general_start;
    let endDate = this.state.general_end;

    let index = 0;
    while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

      const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
      const isWeekend = (currentDate.getDay() === 1 || currentDate.getDay() === 6) && this.state.general_show_weekends === true;
      const freeDay = this.state.general_show_freeDays === true ? Constants.getNationalHoliday(currentDate, this.state.general_publicHolidays) : null;

      toRender.push(

        <div className='reports_general_topBar_days_dayCol' id={Constants.dateToString(currentDate)} style={{borderLeft: isWeekend ? '2px solid #282828' : null}}>
          <div className='reports_general_topBar_days_dayCol_rotate'>
            <span>{currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()} - {(currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)}{freeDay !== null ? <span style={{marginLeft: 4, fontSize: '0.45vw'}}>{freeDay}</span> : ''}</span>
          </div>
        </div>

      );

      index++;

    }

    return toRender;

  }

  general_renderAll_topBar_note_days(note) {

    let toRender = [];

    toRender.push(

      <div className='reports_general_days_bar'>

        {this.general_renderAll_notesCol(note)}

      </div>

    );

    return toRender;

  }
  
  general_renderAll_users() {

    let toRender = [];
    let count = 0;

    for (const acc of this.state.accounts) {

      if (this.props.individual === true && acc.info_id !== this.props.individual_userID) {
        continue;
      }

      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

      toRender.push(

        <div className={count === 0 ? 'reports_general_mainBar' : 'reports_general_mainBar2'}>

          <div className={'reports_general_user'}>

            <span>{userFullName}</span>

          </div>

          <div className={'reports_general_days'}>

            {this.general_renderAll_days(acc)}

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  general_renderAll_days(acc) {

    let toRender = [];

    const holidays = this.state.general_show_holidays === false ? undefined : this.state.general_holidays[acc.info_id];
    const holidayRequests = this.state.general_show_holidayRequests === false ? undefined : this.state.general_holidaysRequest[acc.info_id];
    const unavailables = this.state.general_show_unavailables === false ? undefined : this.state.general_unavailables[acc.info_id];
    const availables = this.state.general_show_availables === false ? undefined : this.state.general_availables[acc.info_id];
    const repeats = this.state.general_repeats[acc.info_id];

    if (typeof holidays !== 'undefined') {

      toRender.push(

        <div className='reports_general_days_bar'>

          {this.general_renderAll_holidaysCol(holidays)}

        </div>

      );

    }

    if (typeof holidayRequests !== 'undefined') {

      toRender.push(

        <div className='reports_general_days_bar'>

          {this.general_renderAll_holidayRequestsCol(holidayRequests)}

        </div>

      );

    }

    if (typeof unavailables !== 'undefined' || typeof availables !== 'undefined' || typeof repeats !== 'undefined') {

      toRender.push(

        <div className='reports_general_days_bar'>

          {this.general_renderAll_unavailablesCol(unavailables, availables, repeats)}

        </div>

      );

    }

    if (typeof holidays === 'undefined' && typeof holidayRequests === 'undefined' && typeof unavailables === 'undefined') {

      toRender.push(

        <div className='reports_general_days_bar'>

          {this.general_renderAll_daysCol()}

        </div>

      );

        
    }

    return toRender;

  }

  general_renderAll_daysCol() {

    let toRender = [];

    let startDate = this.state.general_start;
    let endDate = this.state.general_end;

    let index = 0;
    while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

      const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
      const isWeekend = (currentDate.getDay() === 1 || currentDate.getDay() === 6) && this.state.general_show_weekends === true;

      toRender.push(

        <div className='reports_general_days_dayCol'
          style={{borderLeft: isWeekend ? '2px solid #282828' : null}}
          onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
        />

      );

      index++;

    }

    return toRender;

  }

  general_renderAll_holidaysCol(holidays) {

    let toRender = [];

    let startDate = this.state.general_start;
    let endDate = this.state.general_end;

    let index = 0;
    while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

      const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
      const isWeekend = (currentDate.getDay() === 1 || currentDate.getDay() === 6) && this.state.general_show_weekends === true;

      if (Constants.objectArrayContainsKey(holidays, 'dateString', Constants.dateToString(currentDate))) {

        toRender.push(

          <div className='reports_general_days_dayCol' 
            style={{backgroundColor: Colors.color.orangeVacation, borderLeft: isWeekend ? '2px solid #282828' : null}} 
            onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
            onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
          />

        );

      } else {
        toRender.push(

          <div className='reports_general_days_dayCol' 
            style={{borderLeft: isWeekend ? '2px solid #282828' : null}}
            onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
            onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
          />

        );
      }

      index++;

    }

    return toRender;

  }

  general_renderAll_holidayRequestsCol(holidayRequests) {

    let toRender = [];

    let startDate = this.state.general_start;
    let endDate = this.state.general_end;

    let index = 0;
    while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

      const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
      const request = Constants.getArrayItem(holidayRequests, 'dateString', Constants.dateToString(currentDate));
      const isWeekend = (currentDate.getDay() === 1 || currentDate.getDay() === 6) && this.state.general_show_weekends === true;

      if (request !== null) {

        toRender.push(

          <Tooltip
            options
            html={(
              <div className='appoptions_tooltipContainer'>
                <span>{__('reports_tooltip_leave')}</span>
              </div>
            )}
            delay={500}
            position={'top'}
            arrow
            followCursor
            theme='kodict'
            className='reports_general_days_dayCol' 
            style={{backgroundColor: this.state.highlight === request.vakantie_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 1) : Constants.hexToRgbA(Colors.color.orangeVacation, 0.4), cursor: 'pointer', borderLeft: isWeekend ? '2px solid #282828' : null}} 
          >
            <div 
              style={{width: '100%', height: '100%'}}
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
              onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: request.vakantie_id })}
            />
          </Tooltip>

          // <div className='reports_general_days_dayCol' 
          //   style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.4)}} 
          //   onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
          //   onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
          // />

        );

      } else {
        toRender.push(

          <div className='reports_general_days_dayCol' 
            style={{borderLeft: isWeekend ? '2px solid #282828' : null}}
            onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
            onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
          />

        );
      }

      index++;

    }

    return toRender;

  }

  general_renderAll_unavailablesCol(unavailables, availables, repeats) {

    let toRender = [];

    let startDate = this.state.general_start;
    let endDate = this.state.general_end;

    let index = 0;
    while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

      const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
      const isWeekend = (currentDate.getDay() === 1 || currentDate.getDay() === 6) && this.state.general_show_weekends === true;

      if (Data.data.appOptions.availability === 'STANDARD') {

        let foundDayUnav = false;
        let foundDayAv = false;

        if (Constants.isEmpty(unavailables) === false) {
          for (const unav of unavailables) {
            if (parseInt(unav.sb_dag) === currentDate.getDay()) {
              foundDayUnav = true;
              break;
            }
          }
        }

        if (Constants.isEmpty(availables) === false && foundDayUnav === false) {
          for (const av of availables) {
            if (parseInt(av.sb_dag) === currentDate.getDay()) {
              foundDayAv = true;
              break;
            }
          }
        }

        if (foundDayUnav === true) {

          toRender.push(

            <div className='reports_general_days_dayCol' 
              style={{backgroundColor: Colors.color.redFilledIn, borderLeft: isWeekend ? '2px solid #282828' : null}} 
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
            />

          );

        } else if (foundDayAv === true) {

          toRender.push(

            <div className='reports_general_days_dayCol' 
              style={{backgroundColor: Colors.color.greenFilledIn, borderLeft: isWeekend ? '2px solid #282828' : null}} 
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
            />

          );

        } else {
          toRender.push(

            <div className='reports_general_days_dayCol' 
              style={{borderLeft: isWeekend ? '2px solid #282828' : null}}
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
            />

          );
        }

      } else {

        let foundDayUnav = false;
        let foundDayAv = false;

        if (Constants.isEmpty(repeats) === false) {
        
          for (const r of repeats) {

            const date1 = currentDate;
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval) {

              if (r.hb_beschikbaar === '1' && this.state.general_show_availables) {

                toRender.push(

                  <div className='reports_general_days_dayCol' 
                    style={{backgroundColor: Colors.color.greenFilledIn, borderLeft: isWeekend ? '2px solid #282828' : null}} 
                    onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
                    onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
                  />

                );

                foundDayAv = true;
                break;

              } else if (r.hb_beschikbaar === '2' && this.state.general_show_unavailables) {

                toRender.push(

                  <div className='reports_general_days_dayCol' 
                    style={{backgroundColor: Colors.color.redFilledIn, borderLeft: isWeekend ? '2px solid #282828' : null}} 
                    onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
                    onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
                  />

                );

                foundDayUnav = true;
                break;

              }

            }

          }

        }

        if (foundDayUnav === false && Constants.isEmpty(unavailables) === false && Constants.objectArrayContainsKey(unavailables, 'onbeschikbaar_datum', Constants.dateToString(currentDate))) {

          toRender.push(

            <div className='reports_general_days_dayCol' 
              style={{backgroundColor: Colors.color.redFilledIn, borderLeft: isWeekend ? '2px solid #282828' : null}} 
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
            />

          );

        } else if (foundDayAv === false && Constants.isEmpty(availables) === false && Constants.objectArrayContainsKey(availables, 'beschikbaar_datum', Constants.dateToString(currentDate))) {

          toRender.push(

            <div className='reports_general_days_dayCol' 
              style={{backgroundColor: Colors.color.greenFilledIn, borderLeft: isWeekend ? '2px solid #282828' : null}} 
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
            />

          );

        } else if (foundDayUnav === false && foundDayAv === false) {
          toRender.push(

            <div className='reports_general_days_dayCol' 
              style={{borderLeft: isWeekend ? '2px solid #282828' : null}}
              onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
            />

          );
        }

      }

      index++;

    }

    return toRender;

  }

  general_renderAll_notesCol(note) {

    let toRender = [];

    let startDate = this.state.general_start;
    let endDate = this.state.general_end;

    let index = 0;
    while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

      const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
      const isWeekend = (currentDate.getDay() === 1 || currentDate.getDay() === 6) && this.state.general_show_weekends === true;

      if (Constants.objectArrayContainsKey(note, 'dateString', Constants.dateToString(currentDate))) {

        toRender.push(

          <div className='reports_general_days_dayCol' 
            style={{backgroundColor: `#${note[0].rn_kleur}`, borderLeft: isWeekend ? '2px solid #282828' : null}} 
            onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
            onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
          />

        );

      } else {
        toRender.push(

          <div className='reports_general_days_dayCol' 
            style={{borderLeft: isWeekend ? '2px solid #282828' : null}}
            onMouseEnter={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#47B7EB'} 
            onMouseLeave={() => document.getElementById(Constants.dateToString(currentDate)).style.color = '#282828'} 
          />

        );
      }

      index++;

    }

    return toRender;

  }

  general_renderLegend() {

    let toRender = [];

    if (this.state.general_show_holidays) {
      toRender.push(
        <div className='reports_general_legendRow'>
          <div className='reports_general_legend_circle' style={{backgroundColor: Colors.color.orangeVacation}} />
          <span>{__('misc_leave')}</span>
        </div>
      );
    }

    if (this.state.general_show_holidayRequests) {
      toRender.push(
        <div className='reports_general_legendRow'>
          <div className='reports_general_legend_circle' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.4)}} />
          <span>{__('misc_leaveRequests')}</span>
        </div>
      );
    }

    if (this.state.general_show_availables) {
      toRender.push(
        <div className='reports_general_legendRow'>
          <div className='reports_general_legend_circle' style={{backgroundColor: Colors.color.greenFilledIn}} />
          <span>{__('misc_availability')}</span>
        </div>
      );
    }

    if (this.state.general_show_unavailables) {
      toRender.push(
        <div className='reports_general_legendRow'>
          <div className='reports_general_legend_circle' style={{backgroundColor: Colors.color.redFilledIn}} />
          <span>{__('misc_unavailability')}</span>
        </div>
      );
    }

    for (const key in this.state.general_notes) {
      const note = this.state.general_notes[key][0];
      toRender.push(
        <div className='reports_general_legendRow' style={{cursor: 'pointer'}} onClick={() => this.openNoteModal(note, 1)}>
          <div className='reports_general_legend_circle' style={{backgroundColor: `#${note.rn_kleur}`}} />
          <span>{note.rn_naam}</span>
          <i className='far fa-edit' />
        </div>
      );
    }

    return toRender;

  }

  general_render_note_toggleButtons() {

    let toRender = [];

    for (const key in this.state.general_notes) {

      const note = this.state.general_notes[key][0];

      toRender.push(

        Constants.arrayContains(this.state.general_show_notes, note.rn_id) === true ?
          <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_note(note)}>
            <i className='fas fa-check-circle' />
            <span>{note.rn_naam}</span>
          </div>
        :
          <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_note(note)}>
            <i className='fal fa-circle' />
            <span>{note.rn_naam}</span>
          </div>
        

      );

    }

    return toRender;

  }

  general_selectStartDate(date) {

    this.setState({ reloading: true, general_start: date }, async () => {
      await this.reloadScreen();
    });

    if (this.state.general_end.getTime() - date.getTime() > 10540800000) {
      this.general_selectEndDate(new Date(date.getFullYear(), date.getMonth() + 4, date.getDate()))
    }

  }

  general_selectEndDate(date) {

    this.setState({ reloading: true, general_end: date }, async () => {
      await this.reloadScreen();
    });

  }

  general_toggle_holidays() {
    localStorage.setItem('report_general_show_holidays', this.state.general_show_holidays === true ? '0' : '1');
    this.setState({ general_show_holidays: !this.state.general_show_holidays });
  }

  general_toggle_holidayRequests() {
    localStorage.setItem('report_general_show_holidayRequests', this.state.general_show_holidayRequests === true ? '0' : '1');
    this.setState({ general_show_holidayRequests: !this.state.general_show_holidayRequests });
  }

  general_toggle_unavailables() {
    localStorage.setItem('report_general_show_unavailables', this.state.general_show_unavailables === true ? '0' : '1');
    this.setState({ general_show_unavailables: !this.state.general_show_unavailables });
  }

  general_toggle_availables() {
    localStorage.setItem('report_general_show_availables', this.state.general_show_availables === true ? '0' : '1');
    this.setState({ general_show_availables: !this.state.general_show_availables });
  }

  general_toggle_note(note) {

    let general_show_notes = this.state.general_show_notes;

    if (Constants.arrayContains(general_show_notes, note.rn_id) === true) {
      Constants.removeFromArray(general_show_notes, note.rn_id);
    } else {
      general_show_notes.push(note.rn_id);
    }

    localStorage.setItem('report_general_show_notes', JSON.stringify(general_show_notes));
    this.setState({ general_show_notes: this.state.general_show_notes });

  }

  general_toggle_weekends() {
    localStorage.setItem('report_general_show_weekends', this.state.general_show_weekends === true ? '0' : '1');
    this.setState({ general_show_weekends: !this.state.general_show_weekends });
  }

  async general_toggle_freeDays() {
    localStorage.setItem('report_general_show_freeDays', this.state.general_show_freeDays === true ? '0' : '1');
    this.setState({ general_show_freeDays: !this.state.general_show_freeDays });
    await this.reloadScreen();
  }

  general_toggle_sticky() {
    localStorage.setItem('report_general_sticky', this.state.general_sticky === true ? '0' : '1');
    this.setState({ general_sticky: !this.state.general_sticky });
  }

  getSectionOptions() {

    let sections = [];

    for(const section of this.state.sections) {

      sections.push(
        <option value={'s' + section.afdeling_id} selected={this.state.general_function === 's' + section.afdeling_id}>{section.afdeling_naam}</option>
      );

    }

    return sections;

  }

  getFunctionOptions() {

    let sections = [];

    for(const func of this.state.functions) {

      sections.push(
        <option value={'f' + func.functie_id} selected={this.state.general_function === 'f' + func.functie_id}>{func.functie_naam}</option>
      );

    }

    return sections;

  }

  changeFunction(e) {

    if(this.state.general_function === e.target.value) {
      return;
    }

    this.setState({
      general_function: e.target.value,
    }, async() => { 
      await this.reloadScreen();
    });

  }

  async avail_getAvailabilityData() {

    let unavailables = [];
    let availables = [];
    let total = []; // maybe await apiget accounts and an order by state
    let where = `WHERE (onbeschikbaar_datum BETWEEN '${Constants.dateToString(this.state.avail_start)}' AND '${Constants.dateToString(this.state.avail_end)}')`;
    let where2 = `WHERE (beschikbaar_datum BETWEEN '${Constants.dateToString(this.state.avail_start)}' AND '${Constants.dateToString(this.state.avail_end)}')`;
    let rightJoin = ``;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
      where2 += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    unavailables = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, COUNT(onbeschikbaar_id) AS onbeschikbaar_totaal`, 
      `FROM Info`,
      `LEFT JOIN Onbeschikbaar ON onbeschikbaar_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      `GROUP BY info_id`,
      `ORDER BY info_voornaam, info_achternaam`
    );

    availables = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, COUNT(beschikbaar_id) AS beschikbaar_totaal`, 
      `FROM Info`,
      `LEFT JOIN Beschikbaar ON beschikbaar_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where2,
      `GROUP BY info_id`,
      `ORDER BY info_voornaam, info_achternaam`
    );

    for (const acc of this.state.accounts) {
      total.push(acc);
    }

    for (let data of total) {

      let found = false;

      for (let data2 of availables) {

        if (data.info_id === data2.info_id) {
          data.beschikbaar_totaal = data2.beschikbaar_totaal;
          found = true;
          break;
        }

      }

      if (found === false) {
        data.beschikbaar_totaal = '0';
      }

    }

    for (let data of total) {

      let found = false;

      for (let data2 of unavailables) {

        if (data.info_id === data2.info_id) {
          data.onbeschikbaar_totaal = data2.onbeschikbaar_totaal;
          found = true;
          break;
        }

      }

      if (found === false) {
        data.onbeschikbaar_totaal = '0';
      }

    }

    if (this.state.avail_sort === 'avail ASC') {
      total.sort((a, b) => (parseInt(a.beschikbaar_totaal) > parseInt(b.beschikbaar_totaal)) ? 1 : -1);
    } else if (this.state.avail_sort === 'avail DESC') {
      total.sort((a, b) => (parseInt(a.beschikbaar_totaal) > parseInt(b.beschikbaar_totaal)) ? -1 : 1);
    } else if (this.state.avail_sort === 'unavail ASC') {
      total.sort((a, b) => (parseInt(a.onbeschikbaar_totaal) > parseInt(b.onbeschikbaar_totaal)) ? 1 : -1);
    } else if (this.state.avail_sort === 'unavail DESC') {
      total.sort((a, b) => (parseInt(a.onbeschikbaar_totaal) > parseInt(b.onbeschikbaar_totaal)) ? -1 : 1);
    } else if (this.state.avail_sort === 'name ASC') {
      total.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    } else if (this.state.avail_sort === 'name DESC') {
      total.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
    }

    this.setState({
      avail_availability: total,
    });

  }

  avail_render() {

    let toRender = [];
    let count = 0;

    const daysTotal = Math.floor((this.state.avail_end.getTime() - this.state.avail_start.getTime()) / 1000 / 60 / 60 / 24) + 1;

    for (const data of this.state.avail_availability) {

      if (this.props.individual === true && data.info_id !== this.props.individual_userID) {
        continue;
      }

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

      const notfilled = daysTotal - (parseInt(data.beschikbaar_totaal) + parseInt(data.onbeschikbaar_totaal));

      const availPercent = Constants.round((parseInt(data.beschikbaar_totaal) / daysTotal) * 100);
      const unavailPercent = Constants.round((parseInt(data.onbeschikbaar_totaal) / daysTotal) * 100);
      const nfPercent = Constants.round((notfilled / daysTotal) * 100);

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            <span>{userFullName}</span>
          </div>

          {Data.data.appOptions.availability !== 'UNAVAILABILITY' && this.state.avail_show_availables === true ?
            <div className='hr_hoursBar_sub3'>
              <span>({availPercent}%) <b>{data.beschikbaar_totaal}</b></span>
            </div>
          : null}

          {this.state.avail_show_unavailables === true ?
            <div className='hr_hoursBar_sub3'>
              <span>({unavailPercent}%) <b>{data.onbeschikbaar_totaal}</b></span>
            </div>
          : null}

          {this.state.avail_show_notfilled === true && Data.data.appOptions.availability !== 'SCHOOL' ?
            <div className='hr_hoursBar_sub3'>
              <span>({nfPercent}%) <b>{notfilled}</b></span>
            </div>
          : null}

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  avail_toggle_availables() {
    localStorage.setItem('report_avail_show_availables', this.state.avail_show_availables === true ? '0' : '1');
    this.setState({ avail_show_availables: !this.state.avail_show_availables });
  }

  avail_toggle_unavailables() {
    localStorage.setItem('report_avail_show_unavailables', this.state.avail_show_unavailables === true ? '0' : '1');
    this.setState({ avail_show_unavailables: !this.state.avail_show_unavailables });
  }

  avail_toggle_notfilled() {
    localStorage.setItem('report_avail_show_notfilled', this.state.avail_show_notfilled === true ? '0' : '1');
    this.setState({ avail_show_notfilled: !this.state.avail_show_notfilled });
  }

  avail_changeSort(e) {

    let avail_availability = this.state.avail_availability;

    this.setState({ avail_sort: e.target.value });

    if (e.target.value === 'avail ASC') {
      avail_availability.sort((a, b) => (parseInt(a.beschikbaar_totaal) > parseInt(b.beschikbaar_totaal)) ? 1 : -1);
    } else if (e.target.value === 'avail DESC') {
      avail_availability.sort((a, b) => (parseInt(a.beschikbaar_totaal) > parseInt(b.beschikbaar_totaal)) ? -1 : 1);
    } else if (e.target.value === 'unavail ASC') {
      avail_availability.sort((a, b) => (parseInt(a.onbeschikbaar_totaal) > parseInt(b.onbeschikbaar_totaal)) ? 1 : -1);
    } else if (e.target.value === 'unavail DESC') {
      avail_availability.sort((a, b) => (parseInt(a.onbeschikbaar_totaal) > parseInt(b.onbeschikbaar_totaal)) ? -1 : 1);
    } else if (e.target.value === 'name ASC') {
      avail_availability.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    } else if (e.target.value === 'name DESC') {
      avail_availability.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
    }

    this.setState({ avail_availability: avail_availability });

  }

  avail_selectStartDate(date) {

    this.setState({ avail_start: date }, async () => {
      await this.reloadScreen();
    });

  }

  avail_selectEndDate(date) {

    this.setState({ avail_end: date }, async () => {
      await this.reloadScreen();
    });
  }

  // getWorkDays() {

  //   let days = 0;

  //   if (this.state.workWeekMonday === true) {
  //     days++;
  //   }
  //   if (this.state.workWeekTuesday === true) {
  //     days++;
  //   }
  //   if (this.state.workWeekWednesday === true) {
  //     days++;
  //   }
  //   if (this.state.workWeekThursday === true) {
  //     days++;
  //   }
  //   if (this.state.workWeekFriday === true) {
  //     days++;
  //   }
  //   if (this.state.workWeekSaturday === true) {
  //     days++;
  //   }
  //   if (this.state.workWeekSunday === true) {
  //     days++;
  //   }

  //   return days;

  // }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (typeof this.state.infoExtra[id] === 'undefined') {
      return 0;
    }

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  calcSickHours(userID, _week, year) {

    let absents = [];
    let totalAbsents = 0;

    let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 60 * 60 * 1000) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 60 * 60 * 1000) : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren) * 60 * 60 * 1000);

    // SICK HOURS
    if (Constants.isTrue(Data.data.appOptions.clock)) { // absence_n

      let foundSchedulesAbsence = [];
      let contract = null;

      if (Constants.isEmpty(this.state.schedule2[userID]) === false && Constants.isEmpty(this.state.schedule2[userID][year]) === false && Constants.isEmpty(this.state.schedule2[userID][year][_week.toString()]) === false) {

        for (const sch of this.state.schedule2[userID][year][_week.toString()]) {

          const date = Constants.stringToDate(sch.afwn_datum);
          contract = Constants.getCurrentContract(this.state.contracts[sch.info_id], date); // absence_n

          if (contract !== null) {

            // SET NEW WORKDAYHOURS LIKE CONTRACT
            if (Constants.isTrue(contract.contract_vaste_dagen)) {

              workDayHours = date.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                          : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

            } else {

              workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren) * 60 * 60 * 1000) : workDayHours;

            }

          }

          // ZIEKTE WACHTDAG
          if (Constants.isTrue(sch.afwn_ziekteWachtdag) === true) {
            workDayHours = 0;
          }

          // absence_n
          // if (Constants.isEmpty(sch.afwr_id) === false && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
          if (Constants.objectArrayContainsKey(foundSchedulesAbsence, 'afwn_datum', sch.afwn_datum) === false) {
            foundSchedulesAbsence.push(sch);
            absents.push({ date: date, ms: workDayHours, id: sch.afwn_id });
          }

        }

      }

      let _absenceHours = 0;

      for (const _absN of foundSchedulesAbsence) {

        if (Constants.isTrue(_absN.afwn_ziekteWachtdag) === true) {
          continue;
        }

        const absObject = Constants.getArrayItem(absents, 'id', _absN.afwn_id);

        let sickHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 60 * 60 * 1000) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 60 * 60 * 1000) : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren) * 60 * 60 * 1000);

        if (Constants.isEmpty(absObject) === false) {
          sickHours = absObject.ms;
        }

        if (contract.contract_type === '1' && _absenceHours < (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000)) {
          // _absenceHours += workDayHours > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : workDayHours;
          _absenceHours += sickHours;
        } else if (contract.contract_type === '2' && _absenceHours < (parseFloat(contract.contract_uren) * 60 * 60 * 1000)) {
          // _absenceHours += workDayHours > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : workDayHours;
          _absenceHours += sickHours;
        }

      }
        
      // if (Constants.isEmpty(contract) === false && contract.contract_type === '1') {
      //   _absenceHours = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : (workDayHours * foundSchedulesAbsence.length) : 0;
      // } else if (Constants.isEmpty(contract) === false && contract.contract_type === '2') {
      //   _absenceHours = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : (workDayHours * foundSchedulesAbsence.length) : 0;
      // }

      totalAbsents = _absenceHours;

      // totalAbsents = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) : 0;

    }

    return { absents: absents, totalAbsents: totalAbsents };

  }

  calcAbsAmount(userID, _week, year, totalWorkedWeek) {

    let absence = [];
    let totalAbsence = 0;
    let fullWeekHours = 0;
    const chosenWeek = parseInt(_week);

    // ABSAMOUNT
    if (Constants.isEmpty(this.state.absAmount2[userID]) === false && Constants.isEmpty(this.state.absAmount2[userID][year]) === false) {

      for (const weekKey in this.state.absAmount2[userID][year]) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        for (const abs of this.state.absAmount2[userID][year][weekKey]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          const contract = Constants.getCurrentContract(this.state.contracts[abs.info_id], absStart) === null ? Constants.getCurrentContract(this.state.contracts[abs.info_id], absEnd) : Constants.getCurrentContract(this.state.contracts[abs.info_id], absStart);

          if (Constants.isEmpty(contract) === true) {
            continue;
          }

          // SET NEW WORKDAYHOURS LIKE CONTRACT
          workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

          let fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;

          if (sameDay) {
            if (chosenWeek === parseInt(weekKey) && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
              // SET NEW WORKDAYHOURS LIKE CONTRACT
              if (Constants.isEmpty(contract) === false && Constants.isTrue(contract.contract_vaste_dagen)) {
                workDayHours = absStart.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                              : absStart.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                              : absStart.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                              : absStart.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                              : absStart.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                              : absStart.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                              : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
              }
              // NEW REAL CALC ABSENCE
              if (Constants.objectArrayContainsKey(absence, 'date', absStart) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60))) < parseFloat(contract.contract_uren)) {
                absence.push({ date: absStart, ms: workDayHours * 60 * 60 * 1000 });
                totalAbsence += workDayHours * 60 * 60 * 1000;
              }
              // absence.push({ date: absStart, ms: parseFloat(abs.vs_aantal) * 60 * 60 * 1000 });
              // totalAbsence += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
              // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            }
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
              
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[userID]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (Constants.personWorksDay(infoExtra[userID], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                }
                //  else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[userID]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (Constants.personWorksDay(infoExtra[userID], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                }
                //  else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              }

              index++;

            }

          }

        }

      }

    }

    return { absence: absence, totalAbsence: totalAbsence };

  }

  calcOverhoursN(hours, contract, hoursWorkedWeek, totalOverhoursLocal) {

    const _startDate = Constants.stringToDate(hours.uren_begindatum);

    let _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 1000 * 60 * 60) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);
    let _workDayHoursFloat = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : parseFloat(Data.data.appOptions.opties_dag_werkuren);

    let overhours = null;
    let overhoursCurrent = null;
    let overhoursCorrection = null;
    let overhoursContract = 0;
    let negative = false;
    let negativeCurrent = false;
    let contract_hours = null;
    let contract_max_hours = null;
    let foundUserHoursWeek = false;

    let realOverhoursCurrent = null;
    let realNegativeCurrent = null;

    let workedThisDay = 0;
    let workedThisDayAbsence = 0;
    // let absenceHoursThisDay = 0;

    if (Constants.isTrue(Data.data.appOptions.opties_overuren) === false) {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,
        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,
        overhoursCorrection: overhoursCorrection,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

    if (contract !== null) {

      // SET NEW WORKDAYHOURS LIKE CONTRACT
      _workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? (parseFloat(contract.contract_werkdag_uren) * 1000 * 60 * 60) : _workDayHours;
      _workDayHoursFloat = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? parseFloat(contract.contract_werkdag_uren) : _workDayHoursFloat;

      // CALC CURRENT OVERHOURS (THIS DAY)
      const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
      const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
      const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

      if (start !== null && end !== null && diff !== null) {

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        if (Constants.isTrue(contract.contract_vaste_dagen)) {

          _workDayHours = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

          _workDayHoursFloat = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);

        }

        overhoursCurrent = (diff - _workDayHours) < 0 ? (_workDayHours - diff) : (diff - _workDayHours);
        negativeCurrent = (diff - _workDayHours) < 0;

        workedThisDay = diff;
        workedThisDayAbsence = _workDayHours;

      }

      if (isNaN(overhoursCurrent) === true || overhoursCurrent === 0) {
        overhoursCurrent = null;
      }

      // get current chosen week
      const chosenWeek = Constants.getWeekNumber2(_startDate);

      // get total worktime this week
      let totalHoursWeek = 0;

      // found hours objects this week
      let foundDBHoursArray = [];
      let foundExtraHoursArray = [];

      // normals
      for (const hours2 of hoursWorkedWeek) {
        //if (hours2.info_id === hours.info_id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === hours.uren_begindatum)) {
          const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
          const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
          const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
          //if (startHours2 < _startDate || hours2.uren_begindatum === hours.uren_begindatum) { // <
            foundUserHoursWeek = true;
            foundDBHoursArray.push(hours2);
            totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2));
          //}
        //}
      }

      // SICK HOURS
      if (Constants.isTrue(Data.data.appOptions.clock)) { // absence_n

        let foundSchedulesAbsence = [];

        if (Constants.isEmpty(this.state.schedule2[hours.info_id]) === false && Constants.isEmpty(this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

          for (const sch of this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) {

            if (sch.afwn_info_id === (hours.info_id)) {

              if (Constants.objectArrayContainsKey(foundSchedulesAbsence, 'afwn_datum', sch.afwn_datum) === false) {
                foundSchedulesAbsence.push(sch);
              }

            }

          }

        }

        let _absenceHours = 0;

        for (const _absN of foundSchedulesAbsence) {

          if (Constants.isTrue(_absN.afwn_ziekteWachtdag) === true) {
            continue;
          }

          if (contract.contract_type === '1') {
            _absenceHours += _workDayHours > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : _workDayHours;
          } else if (contract.contract_type === '2') {
            _absenceHours += _workDayHours > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : _workDayHours;
          }

        }

        // if (contract.contract_type === '1') {
        //   _absenceHours = foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : (_workDayHours * foundSchedulesAbsence.length) : 0;
        // } else if (contract.contract_type === '2') {
        //   _absenceHours = foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : (_workDayHours * foundSchedulesAbsence.length) : 0;
        // }

        totalHoursWeek += _absenceHours;

        // totalHoursWeek += foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) : 0;

      }

      // ABSAMOUNT
      if (Constants.isEmpty(this.state.absAmount2[hours.info_id]) === false && Constants.isEmpty(this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

        let fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;

        for (const abs of this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          if (sameDay) {
            totalHoursWeek += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
            
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (Constants.personWorksDay(infoExtra[hours.info_id], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (Constants.personWorksDay(infoExtra[hours.info_id], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              }

              index++;

            }

            if (Object.keys(absWeek).length > 0) {
              for (let key in absWeek) {
                if (chosenWeek === parseInt(key)) {
                  totalHoursWeek += (absWeek[key] * 60 * 60 * 1000);
                }
              }
            }

          }

        }

      }

      realOverhoursCurrent = overhoursCurrent;
      realNegativeCurrent = negativeCurrent;

      // calc
      if (Constants.isTrue(contract.contract_vaste_dagen) === false) {
        
        if (contract.contract_type === '1') {

          contract_max_hours = parseFloat(contract.contract_max_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) > contract_max_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_max_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        } else if (contract.contract_type === '2') {

          contract_hours = parseFloat(contract.contract_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) >= contract_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        }

      }

    }

    if (Constants.isEmpty(contract) === true || Constants.isEmpty(contract.contract_type) === true || contract.contract_type === '0') {

      return {
        overhoursTotal: '00:00',
        isNegativeTotal: false,

        overhoursCurrent: null,
        isNegativeCurrent: false,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: 0,
        // overhours this week
      };

    } else {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,

        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

  }

  async overhours_getOverhoursData() {

    const allHours_users = await Constants.calculateAllHours_users(undefined, undefined, this.dateNow);

    this.setState({ allHours_users: allHours_users });
    
  }

  overhours_changeYear(e) {

    this.setState({ overhours_year: e.target.value });

  }

  overhours_getYearSelects() {

    let toReturn = [];

    // DECIDE YEARS TO SHOW AND ˜TO SELECT
    let years = [];

    for (const userKey in this.state.allHours_users) {
      for (const yearKey in this.state.allHours_users[userKey].totalOverhoursYearly) {
        if (Constants.arrayContains(years, parseInt(yearKey)) === false) {
          years.push(parseInt(yearKey));
        }
      }
    }

    let highest = this.dateNow.getFullYear();
    let lowest = this.dateNow.getFullYear();

    for (const y of years) {

      if (y > highest) {
        highest = y;
      }
      if (y < lowest) {
        lowest = y;
      }

    }

    for (let i = lowest; i < highest; i++) {

      if (Constants.arrayContains(years, i) === false) {
        years.push(i);
      }

    }

    years.sort((a, b) => (a > b) ? 1 : -1);

    for (const yearKey of years) {

      toReturn.push(
        <option value={yearKey.toString()} selected={this.state.overhours_year === yearKey.toString()}>{yearKey.toString()}</option>
      );

    }

    return toReturn;

  }

  overhours_changeSort(e) {
    this.setState({ overhours_sort: e.target.value });
  }

  overhours_render() {

    let toReturn = [];
    let count = 0;

    // if (Constants.isEmpty(this.state.hoursWorked) === false) {

    //   let _yearData = [];

    //   for (const user of this.state.hoursWorked) {

    //     let yearOverhoursData = {};

    //     for (const yearKey in user.data) {

    //       if (Constants.isEmpty(yearOverhoursData[yearKey])) {
    //         yearOverhoursData[yearKey] = 0;
    //       }

    //       const userOverhoursYear = user.data[yearKey];

    //       if (Constants.isEmpty(userOverhoursYear) === false) {

    //         const startWeek = userOverhoursYear[userOverhoursYear.length - 1].week;
    //         let endWeek = Constants.getWeekNumber2(new Date());

    //         if (new Date().getFullYear() > parseInt(yearKey)) {
    //           endWeek = userOverhoursYear[0].week;
    //         }

    //         // loop from start to current week
    //         for (let i = endWeek; i >= startWeek; i--) {

    //           const week = i;
    //           const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
    //           const monday = Constants.getMonday(i, yearKey);

    //           const showDetails = Constants.arrayContains(this.state.showWeekDetails, i);

    //           let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
    //           let totalHours = 0;
    //           let realTotalHours = 0;
    //           let totalWorkedHours = 0;
    //           let totalWorkedHoursAbsence = 0;
    //           let currentContract = null;
    //           let correction = 0;

    //           // CONTRACT
    //           let contract = Constants.getCurrentContract(this.state.contracts[user.user], monday);
    //           contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

    //           if (contract !== null) {
    //             currentContract = contract;
    //           } else {
    //             continue;
    //           }

    //           //
    //           //
    //           // yearData
    //           const yearData = Constants.getArrayItem(userOverhoursYear, 'week', i);

    //           // calc hours and overhours and correction
    //           if (Constants.isEmpty(yearData) === false) {

    //             // ARRAY OF DAYS
    //             const weekData = yearData.weekData;

    //             // HOURS DAY DATA
    //             for (const dayData of weekData) { // days in week array

    //               const date = Constants.stringToDate(dayData.uren_begindatum);

    //               // if (date > _now) {
    //               //   continue;
    //               // }

    //               const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

    //               daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
    //               daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

    //               totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
    //               realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
    //               totalWorkedHours += calcOverhours.workedThisDay;
    //               totalWorkedHoursAbsence += calcOverhours.workedThisDayAbsence;

    //             }

    //           }

    //           // CALC ABSENT AND ABSENCES
    //           const calcAbsAmount = this.calcAbsAmount(user.user, i, yearKey, totalWorkedHoursAbsence);
    //           const calcSickHours = this.calcSickHours(user.user, i, yearKey);

    //           let totalAbsence = calcAbsAmount.totalAbsence;
    //           let totalAbsent = calcSickHours.totalAbsents;

    //           // ABS DAY DATA
    //           for (const abs of calcAbsAmount.absence) {
    //             daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
    //           } 

    //           // SICK DAY DATA
    //           for (const sick of calcSickHours.absents) {
    //             daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
    //           } 

    //           // HAS CONTRACT
    //           if (currentContract !== null) {

    //             // show the correction incase of confusion
    //             correction += (totalHours - realTotalHours);

    //             // fix minus hours
    //             if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
    //               if (currentContract.contract_type === '2') {
    //                 const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
    //                 if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
    //                   const oldTotalHours = totalHours;
    //                   totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
    //                   correction = totalHours - oldTotalHours;
    //                 } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_hours) {
    //                   const oldTotalHours = totalHours;
    //                   totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
    //                   correction = totalHours - realTotalHours;
    //                 }
    //               } else if (currentContract.contract_type === '1') {
    //                 const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
    //                 const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
    //                 if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
    //                   const oldTotalHours = totalHours;
    //                   totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
    //                   correction = totalHours - oldTotalHours;
    //                 } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
    //                   const oldTotalHours = totalHours;
    //                   totalHours = 0;
    //                   correction = totalHours - oldTotalHours;
    //                 } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_max_hours) {
    //                   const oldTotalHours = totalHours;
    //                   totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_max_hours;
    //                   correction = totalHours - realTotalHours;
    //                 }
    //               }
    //             }

    //             // fix correction negative
    //             const correctionNegative = correction < 0;
    //             correction = Math.abs(correction);

    //             yearOverhoursData[yearKey] += totalHours;

    //           }

    //         }

    //       }

    //     }

    //     let startBalance = 0;
    //     let balanceNow = 0;
    //     let balanceEnd = 0;

    //     for (const yearKey in yearOverhoursData) {
    //       const overhoursAmount = yearOverhoursData[yearKey];
    //       if (parseInt(yearKey) <= parseInt(this.state.overhours_year)) {
    //         balanceEnd += overhoursAmount;
    //       }
    //       if (parseInt(yearKey) === parseInt(this.state.overhours_year)) {
    //         balanceNow += overhoursAmount;
    //       }
    //       if (parseInt(yearKey) < parseInt(this.state.overhours_year)) {
    //         startBalance += overhoursAmount;
    //       }
    //     }

    //     // CORRECTIONS
    //     if (Constants.isEmpty(this.state.overhours_corrections[user.user]) === false) {
    //       for (const yearKey in this.state.overhours_corrections[user.user]) {
    //         const overhoursAmount = (this.state.overhours_corrections[user.user][yearKey].total * 60 * 60 * 1000);
    //         if (parseInt(yearKey) <= parseInt(this.state.overhours_year)) {
    //           balanceEnd += overhoursAmount;
    //         }
    //         if (parseInt(yearKey) === parseInt(this.state.overhours_year)) {
    //           balanceNow += overhoursAmount;
    //         }
    //         if (parseInt(yearKey) < parseInt(this.state.overhours_year)) {
    //           startBalance += overhoursAmount;
    //         }
    //       }
    //     }

    //     _yearData.push({
    //       ...user,
    //       startBalance: startBalance,
    //       balanceNow: balanceNow,
    //       balanceEnd: balanceEnd,
    //     });

    //   }

    //   if (this.state.overhours_sort === 'name ASC') {
    //     _yearData.sort((a, b) => (a.userFullName > b.userFullName) ? 1 : -1);
    //   } else if (this.state.overhours_sort === 'name DESC') {
    //     _yearData.sort((a, b) => (a.userFullName > b.userFullName) ? -1 : 1);
    //   } else if (this.state.overhours_sort === 'hours ASC') {
    //     _yearData.sort((a, b) => (a.balanceEnd > b.balanceEnd) ? 1 : -1);
    //   } else if (this.state.overhours_sort === 'hours DESC') {
    //     _yearData.sort((a, b) => (a.balanceEnd > b.balanceEnd) ? -1 : 1);
    //   }

    //   for (const user of _yearData) {

    //     const startBalance = isNaN(user.startBalance) || Constants.msToTime2(user.startBalance) === '00:00' ? Constants.msToTime2(0) : user.startBalance < 0 ? '-' + Constants.msToTime2(Math.abs(user.startBalance)) : user.startBalance !== 0 ? '+' + Constants.msToTime2(user.startBalance) : Constants.msToTime2(0);
    //     const balanceNow = isNaN(user.balanceNow) || Constants.msToTime2(user.balanceNow) === '00:00' ? Constants.msToTime2(0) : user.balanceNow < 0 ? '-' + Constants.msToTime2(Math.abs(user.balanceNow)) : user.balanceNow !== 0 ? '+' + Constants.msToTime2(user.balanceNow) : Constants.msToTime2(0);
    //     const balanceEnd = isNaN(user.balanceEnd) || Constants.msToTime2(user.balanceEnd) === '00:00' ? Constants.msToTime2(0) : user.balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(user.balanceEnd)) : user.balanceEnd !== 0 ? '+' + Constants.msToTime2(user.balanceEnd) : Constants.msToTime2(0);

    //     if (startBalance === '00:00' && balanceNow === '00:00' && balanceEnd === '00:00') {
    //       continue;
    //     }

    //     toReturn.push(

    //       <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

    //         <div className='hr_hoursBar_sub1'>
    //           <span className='defaultLinkO' onClick={() => this.props.history.push({ pathname: '/employees', selected: user.user, selectedName: user.userFullName, menu: 'overhours' })}>{user.userFullName}</span>
    //         </div>

    //         <div className='hr_hoursBar_sub3'>
    //           <span style={{color: startBalance.includes('-') ? Colors.color.greenFilledIn : startBalance.includes('+') ? Colors.color.redFilledIn : null}}>{startBalance}</span>
    //         </div>

    //         <div className='hr_hoursBar_sub3'>
    //           <span style={{color: balanceNow.includes('-') ? Colors.color.greenFilledIn : balanceNow.includes('+') ? Colors.color.redFilledIn : null}}>{balanceNow}</span>
    //         </div>

    //         <div className='hr_hoursBar_sub3'>
    //           <span style={{color: balanceEnd.includes('-') ? Colors.color.greenFilledIn : balanceEnd.includes('+') ? Colors.color.redFilledIn : null}}><b>{balanceEnd}</b></span>
    //         </div>

    //         <div className='hr_hoursBar_sub4' style={{ cursor: 'pointer' }} onClick={() => this.props.history.push({ pathname: '/employees', selected: user.user, selectedName: user.userFullName, menu: 'overhours' })} >
    //           <i className='far fa-chevron-right' style={{marginRight: '0.5vw' }} />
    //         </div>

    //       </div>

    //     );

    //     if (count === 0) {
    //       count++;
    //     } else {
    //       count--;
    //     }

    //   }

    // }

    let overhours_array = [];

    for (const userKey in this.state.allHours_users) {
      overhours_array.push(this.state.allHours_users[userKey]);
    }

    if (this.state.overhours_sort === 'name ASC') {
      overhours_array.sort((a, b) => (a.userData.info_voornaam > b.userData.info_voornaam || (a.userData.info_voornaam === b.userData.info_voornaam && a.userData.info_achternaam > b.userData.info_achternaam)) ? 1 : -1);
    } else if (this.state.overhours_sort === 'name DESC') {
      overhours_array.sort((a, b) => (a.userData.info_voornaam > b.userData.info_voornaam || (a.userData.info_voornaam === b.userData.info_voornaam && a.userData.info_achternaam > b.userData.info_achternaam)) ? -1 : 1);
    } else if (this.state.overhours_sort === 'hours ASC') {
      overhours_array.sort((a, b) => (a.totalEndBalance_overhours > b.totalEndBalance_overhours) ? 1 : -1);
    } else if (this.state.overhours_sort === 'hours DESC') {
      overhours_array.sort((a, b) => (a.totalEndBalance_overhours > b.totalEndBalance_overhours) ? -1 : 1);
    }

    for (const allHoursData of overhours_array) {

      let overhours_lastYear = 0;
      let overhours_currentYear = 0;
      let overhours_total = 0;

      // OVERHOURS LAST YEAR
      for (const yearKey in allHoursData.totalOverhoursYearly) {

        if (parseInt(yearKey) <= (this.state.overhours_year - 1)) {
          overhours_lastYear += allHoursData.totalOverhoursYearly[yearKey];
        }

      }

      // OVERHOURS THIS YEAR
      if (Constants.isEmpty(allHoursData.totalOverhoursYearly[this.state.overhours_year]) === false) {
        overhours_currentYear += allHoursData.totalOverhoursYearly[this.state.overhours_year];
      }

      // OVERHOURS TOTAL
      if (Constants.isEmpty(allHoursData.totalOverhoursYearly) === false) {

        for (const yearKey in allHoursData.totalOverhoursYearly) {

          if (parseInt(yearKey) <= (this.state.overhours_year)) {
            overhours_total += allHoursData.totalOverhoursYearly[yearKey];
          }

        }

      }

      const userFullName = `${allHoursData.userData.info_voornaam} ${(allHoursData.userData.info_tussenvoegsel !== null && allHoursData.userData.info_tussenvoegsel !== '') ? allHoursData.userData.info_tussenvoegsel + " " + allHoursData.userData.info_achternaam : allHoursData.userData.info_achternaam}`;

      toReturn.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            <span className='defaultLinkO' onClick={() => this.props.history.push({ pathname: '/employees/' + allHoursData.userID, menu: 'balances' })}>{userFullName}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {overhours_lastYear < 0 ? <span style={{ color: Colors.color.greenFilledIn }}>-{Constants.msToTime2(Math.abs(overhours_lastYear))}</span> : overhours_lastYear === 0 ? <span>{Constants.msToTime2(Math.abs(overhours_lastYear))}</span> : <span style={{ color: Colors.color.redFilledIn }}>+{Constants.msToTime2(Math.abs(overhours_lastYear))}</span>}
          </div>

          <div className='hr_hoursBar_sub3'>
            {overhours_currentYear < 0 ? <span style={{ color: Colors.color.greenFilledIn }}>-{Constants.msToTime2(Math.abs(overhours_currentYear))}</span> : overhours_currentYear === 0 ? <span>{Constants.msToTime2(Math.abs(overhours_currentYear))}</span> : <span style={{ color: Colors.color.redFilledIn }}>+{Constants.msToTime2(Math.abs(overhours_currentYear))}</span>}
          </div>

          <div className='hr_hoursBar_sub3'>
            {overhours_total < 0 ? <b style={{ color: Colors.color.greenFilledIn }}>-{Constants.msToTime2(Math.abs(overhours_total))}</b> : overhours_total === 0 ? <b>{Constants.msToTime2(Math.abs(overhours_total))}</b> : <b style={{ color: Colors.color.redFilledIn }}>+{Constants.msToTime2(Math.abs(overhours_total))}</b>}
          </div>

          <div className='hr_hoursBar_sub4' style={{ cursor: 'pointer' }} onClick={() => this.props.history.push({ pathname: '/employees/' + allHoursData.userID, menu: 'balances' })} >
            <i className='far fa-chevron-right' style={{marginRight: '0.5vw' }} />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toReturn;

  }

  // overhours_render() {

  //   let toRender = [];
  //   let count = 0;

  //   let overhours_sort = [];
  //   for (const userKey in this.state.overhours_overhours) {
  //     if (Constants.isEmpty(this.state.overhours_overhours[userKey][this.state.overhours_year]) === false) {
  //       const userOverhoursYear = this.state.overhours_overhours[userKey][this.state.overhours_year];
  //       if (Constants.isEmpty(userOverhoursYear) === false) {
  //         overhours_sort.push(userOverhoursYear);
  //       }
  //     }
  //   }

  //   if (this.state.overhours_sort === 'name ASC') {
  //     overhours_sort.sort((a, b) => (a.name > b.name) ? 1 : -1);
  //   } else if (this.state.overhours_sort === 'name DESC') {
  //     overhours_sort.sort((a, b) => (a.name > b.name) ? -1 : 1);
  //   } else if (this.state.overhours_sort === 'hours ASC') {
  //     overhours_sort.sort((a, b) => (a.totalAT > b.totalAT) ? 1 : -1);
  //   } else if (this.state.overhours_sort === 'hours DESC') {
  //     overhours_sort.sort((a, b) => (a.totalAT > b.totalAT) ? -1 : 1);
  //   }

  //   // TOTALS
  //   if (this.state.overhours_type === 0) {

  //     for (const userOverhoursYear of overhours_sort) {
    
  //       let startBalance = 0;
  //       const prevYearOverhours = this.state.overhours_overhours[userOverhoursYear.userID][(parseInt(this.state.overhours_year) - 1).toString()];
  //       if (Constants.isEmpty(prevYearOverhours) === false) {
  //         startBalance = prevYearOverhours.totalAT;
  //       }

  //       // to MS and then to timestring
  //       startBalance = isNaN(startBalance) ? Constants.msToTime2(0) : startBalance < 0 ? '-' + Constants.msToTime2(Math.abs(startBalance) * 60 * 60 * 1000) : startBalance !== 0 ? '+' + Constants.msToTime2(startBalance * 60 * 60 * 1000) : Constants.msToTime2(0);
  //       const balanceNow = isNaN(userOverhoursYear.total) ? Constants.msToTime2(0) : userOverhoursYear.total < 0 ? '-' + Constants.msToTime2(Math.abs(userOverhoursYear.total) * 60 * 60 * 1000) : userOverhoursYear.total !== 0 ? '+' + Constants.msToTime2(userOverhoursYear.total * 60 * 60 * 1000) : Constants.msToTime2(0);
  //       const balanceEnd = isNaN(userOverhoursYear.totalAT) ? Constants.msToTime2(0) : userOverhoursYear.totalAT < 0 ? '-' + Constants.msToTime2(Math.abs(userOverhoursYear.totalAT) * 60 * 60 * 1000) : userOverhoursYear.totalAT !== 0 ? '+' + Constants.msToTime2(userOverhoursYear.totalAT * 60 * 60 * 1000) : Constants.msToTime2(0);

  //       toRender.push(

  //         <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

  //           <div className='hr_hoursBar_sub1'>
  //             <span className='defaultLinkO' onClick={() => this.props.history.push({ pathname: '/employees', selected: userOverhoursYear.userID, selectedName: userOverhoursYear.name, menu: 'overhours' })}>{userOverhoursYear.name}</span>
  //           </div>

  //           <div className='hr_hoursBar_sub3'>
  //             <span style={{color: startBalance.includes('-') ? Colors.color.greenFilledIn : startBalance.includes('+') ? Colors.color.redFilledIn : null}}>{startBalance}</span>
  //           </div>

  //           <div className='hr_hoursBar_sub3'>
  //             <span style={{color: balanceNow.includes('-') ? Colors.color.greenFilledIn : balanceNow.includes('+') ? Colors.color.redFilledIn : null}}>{balanceNow}</span>
  //           </div>

  //           <div className='hr_hoursBar_sub3'>
  //             <span style={{color: balanceEnd.includes('-') ? Colors.color.greenFilledIn : balanceEnd.includes('+') ? Colors.color.redFilledIn : null}}><b>{balanceEnd}</b></span>
  //           </div>

  //           <div className='hr_hoursBar_sub4' style={{ cursor: 'pointer' }} onClick={() => this.props.history.push({ pathname: '/employees', selected: userOverhoursYear.userID, selectedName: userOverhoursYear.name, menu: 'overhours' })} >
  //             <i className='far fa-chevron-right' style={{marginRight: '0.5vw' }} />
  //           </div>

  //         </div>

  //       );

  //       if (count === 0) {
  //         count++;
  //       } else {
  //         count--;
  //       }

  //     }

  //   // INDIVIDUAL
  //   } else {

  //     for (const userOverhoursYear of overhours_sort) {
    
  //       if (Constants.isEmpty(userOverhoursYear.sep) === false) {

  //         for (const _userOverhours of userOverhoursYear.sep) {

  //           const userFullName = `${_userOverhours.info_voornaam} ${(_userOverhours.info_tussenvoegsel !== null && _userOverhours.info_tussenvoegsel !== '') ? _userOverhours.info_tussenvoegsel + " " + _userOverhours.info_achternaam : _userOverhours.info_achternaam}`;
  //           const date = Constants.stringToDate(_userOverhours.ov_datum);
  //           const week = Constants.getWeekNumber2(date);
  //           const amount = parseFloat(_userOverhours.ov_aantal);

  //           const _amount = isNaN(amount) ? Constants.msToTime2(0) : amount < 0 ? '-' + Constants.msToTime2(Math.abs(amount) * 60 * 60 * 1000) : amount !== 0 ? '+' + Constants.msToTime2(amount * 60 * 60 * 1000) : Constants.msToTime2(0);

  //           toRender.push(

  //             <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

  //               <div className='hr_hoursBar_sub1'>
  //                 <span>{userFullName}</span>
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 <span>Week {week}</span>
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 <span style={{color: _amount.includes('-') ? Colors.color.greenFilledIn : _amount.includes('+') ? Colors.color.redFilledIn : null}}><b>{_amount}</b></span>
  //               </div>

  //             </div>

  //           );

  //           if (count === 0) {
  //             count++;
  //           } else {
  //             count--;
  //           }

  //         }

  //       }

  //     }

  //   }

  //   return toRender;

  // }

  overhoursC_changeYear(e) {

    this.setState({ overhoursC_year: e.target.value });

  }

  overhoursC_getYearSelects() {

    let toRender = [];

    for (const year of this.state.overhoursC_years) {
      toRender.push(<option value={year} selected={this.state.overhoursC_year === year}>{year}</option>);
    }

    return toRender;

  }

  overhoursC_changeSort(e) {
    this.setState({ overhoursC_sort: e.target.value });
  }

  overhoursC_render() {

    let toRender = [];
    let count = 0;

    let overhours_sort = [];
    for (const userKey in this.state.overhoursC_corrections) {
      if (Constants.isEmpty(this.state.overhoursC_corrections[userKey][this.state.overhoursC_year]) === false) {
        const userOverhoursYear = this.state.overhoursC_corrections[userKey][this.state.overhoursC_year];
        if (Constants.isEmpty(userOverhoursYear) === false && Constants.isEmpty(userOverhoursYear.sep) === false) {
          for (const correction of userOverhoursYear.sep) {
            overhours_sort.push(correction);
          }
        }
      }
    }

    if (this.state.overhoursC_sort === 'name ASC') {
      overhours_sort.sort((a, b) => ((a.v1 > b.v1) || (a.v1 === b.v1 && a.a1 > b.a1) ) ? 1 : -1);
    } else if (this.state.overhoursC_sort === 'name DESC') {
      overhours_sort.sort((a, b) => ((a.v1 > b.v1) || (a.v1 === b.v1 && a.a1 > b.a1) ) ? -1 : 1);
    } else if (this.state.overhoursC_sort === 'hours ASC') {
      overhours_sort.sort((a, b) => (a.co_aantal > b.co_aantal) ? 1 : -1);
    } else if (this.state.overhoursC_sort === 'hours DESC') {
      overhours_sort.sort((a, b) => (a.co_aantal > b.co_aantal) ? -1 : 1);
    } else if (this.state.overhoursC_sort === 'date ASC') {
      overhours_sort.sort((a, b) => (a.co_datum > b.co_datum) ? 1 : -1);
    } else if (this.state.overhoursC_sort === 'date DESC') {
      overhours_sort.sort((a, b) => (a.co_datum > b.co_datum) ? -1 : 1);
    }

    for (const correction of overhours_sort) {

      if (this.state.overhoursC_type === 1 && correction.co_betaling !== '1') {
        continue;
      } else if (this.state.overhoursC_type === 2 && correction.co_betaling !== '0') {
        continue;
      }

      const userFullName1 = `${correction.v1} ${(correction.tv1 !== null && correction.tv1 !== '') ? correction.tv1 + " " + correction.a1 : correction.a1}`;
      const userFullName2 = `${correction.v2} ${(correction.tv2 !== null && correction.tv2 !== '') ? correction.tv2 + " " + correction.a2 : correction.a2}`;

      const date = Constants.stringToDate(correction.co_datum);
      const amount = parseFloat(correction.co_aantal);

      const _amount = isNaN(amount) ? Constants.msToTime2(0) : amount < 0 ? '-' + Constants.msToTime2(Math.abs(amount) * 60 * 60 * 1000) : amount !== 0 ? '+' + Constants.msToTime2(amount * 60 * 60 * 1000) : Constants.msToTime2(0);

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            <span className='defaultLinkO' onClick={() => this.props.history.push({ pathname: '/employees', selected: correction.co_info_id, selectedName: userFullName1, menu: 'overhours' })}>{userFullName1}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{date.toLocaleDateString()}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{userFullName2}</span>
          </div>

          <div className='hr_hoursBar_sub4'>
            {Constants.isEmpty(correction.co_notitie) === false ?
              <Tooltip
                options
                html={(
                  <div className='appoptions_tooltipContainer'>
                    <span>{correction.co_notitie}</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
              >
                <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
              </Tooltip>
            : 
              <span>&nbsp;</span>
            }
          </div>

          <div className='hr_hoursBar_sub4'>
            {Constants.isTrue(correction.co_betaling) ?
              <i className='fal fa-check' style={{color: Colors.color.greenFilledIn}} />
            :
              <i className='fal fa-times' style={{color: Colors.color.redFilledIn}} />
            }
          </div>

          <div className='hr_hoursBar_sub3'>
            <span style={{color: _amount.includes('-') ? Colors.color.greenFilledIn : _amount.includes('+') ? Colors.color.redFilledIn : null}}><b>{_amount}</b></span>
          </div>

          <div className='hr_hoursBar_sub4' style={{ cursor: 'pointer' }} onClick={() => this.props.history.push({ pathname: '/employees', selected: correction.co_info_id, selectedName: userFullName1, menu: 'overhours' })} >
            <i className='far fa-chevron-right' style={{marginRight: '0.5vw' }} />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async trades_getTradesData() {

    let trades = [];
    let where = `WHERE ((s1.rooster_datum BETWEEN '${Constants.dateToString(this.state.trades_start)}' AND '${Constants.dateToString(this.state.trades_end)}') OR (s2.rooster_datum BETWEEN '${Constants.dateToString(this.state.trades_start)}' AND '${Constants.dateToString(this.state.trades_end)}')) AND rs_geaccepteerd = 2`;
    let rightJoin = ``;

    if (this.state.trades_employee !== 'null' && this.state.trades_employee !== 'all') {
      where += ` AND (rs_info_id_from = ${this.state.trades_employee} OR rs_info_id_to = ${this.state.trades_employee})`
    }

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
      rightJoin = ` LEFT JOIN TeamRooster AS tr1 ON tr1.teamr_rooster_id = i1.info_id LEFT JOIN TeamRooster AS tr2 ON tr2.teamr_rooster_id = i2.info_id`;
      where += ` AND (tr1.teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR tr2.teamr_team_id = ${Data.data.chosenTeamObj.team_id})`;
    }

    if (this.props.individual === true) {
      where += ` AND (rs_info_id_from = ${this.props.individual_userID} OR rs_info_id_to = ${this.props.individual_userID})`;
    }

    trades = await APIGET.Request(
      `SELECT i1.info_voornaam AS v1, i1.info_achternaam AS a1, i1.info_tussenvoegsel AS tv1, i2.info_voornaam AS v2, i2.info_achternaam AS a2, i2.info_tussenvoegsel AS tv2, s1.rooster_datum AS sd1, s1.rooster_begin AS sb1, s1.rooster_eind AS se1, s2.rooster_datum AS sd2, s2.rooster_begin AS sb2, s2.rooster_eind AS se2, f1.functie_naam AS fn1, f2.functie_naam AS fn2`, 
      `FROM Ruilshift`,
      `LEFT JOIN Info AS i1 ON i1.info_id = rs_info_id_from LEFT JOIN Info AS i2 ON i2.info_id = rs_info_id_to LEFT JOIN Rooster AS s1 ON s1.rooster_id = rs_rooster_id_from LEFT JOIN Rooster AS s2 ON s2.rooster_id = rs_rooster_id_to LEFT JOIN Functie AS f1 ON s1.rooster_functie_id = f1.functie_id LEFT JOIN Functie AS f2 ON s2.rooster_functie_id = f2.functie_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      null,
      this.state.trades_sort
    );

    this.setState({
      trades_trades: trades,
    });

  }

  trades_changeEmployee(e) {

    this.setState({ trades_employee: e.target.value }, async () => {
      await this.reloadScreen();
    });

  }

  trades_changeSort(e) {

    this.setState({ trades_sort: e.target.value }, async () => {
      await this.reloadScreen();
    });

  }

  trades_selectStartDate(date) {

    this.setState({ trades_start: date }, async () => {
      await this.reloadScreen();
    });

  }

  trades_selectEndDate(date) {

    this.setState({ trades_end: date }, async () => {
      await this.reloadScreen();
    });
  }

  trades_render() {

    let toRender = [];
    let count = 0;

    for (const data of this.state.trades_trades) {

      const date1 = Constants.stringToDate(data.sd1);
      const date2 = Constants.stringToDate(data.sd2);

      const userFullName1 = `${data.v1} ${(data.tv1 !== null && data.tv1 !== '') ? data.tv1 + " " + data.a1.substr(0, 1) : data.a1.substr(0, 1)}.`;
      const userFullName2 = `${data.v2} ${(data.tv2 !== null && data.tv2 !== '') ? data.tv2 + " " + data.a2.substr(0, 1) : data.a2.substr(0, 1)}.`;

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, height: '2.5vw'}}>

          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName1}</span>
            <span style={{color: Colors.color.greenFilledIn}}>{userFullName2}</span>
          </div>

          <div className='hr_hoursBar_sub1'>
            <span>{Constants.getShortDayName(date1).toLowerCase()} {date1.toLocaleDateString()} ({data.sb1.substr(0, 5)} - {data.se1.substr(0, 5)}) ({data.fn1})</span>
          </div>

          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName2}</span>
            <span style={{color: Colors.color.greenFilledIn}}>{userFullName1}</span>
          </div>

          <div className='hr_hoursBar_sub1'>
            <span>{Constants.getShortDayName(date2).toLowerCase()} {date2.toLocaleDateString()} ({data.sb2.substr(0, 5)} - {data.se2.substr(0, 5)}) ({data.fn2})</span>
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async abs_getAbsenceTypesData() {

    const absenceTypes = await APIGetAbsencetypes.Request();

    this.setState({
      abs_absence_types: absenceTypes,
    });

  }

  async abs_getAbsencesData() {

    let absences = [];
    let absencesTotal = [];
    let where = `WHERE afwn_datum BETWEEN '${Constants.dateToString(this.state.abs_start)}' AND '${Constants.dateToString(this.state.abs_end)}'`;
    let rightJoin = ``;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    if ((this.state.abs_employee === 'null' || this.state.abs_employee === 'all') && this.props.individual !== true) {

      absences = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, AfwezigheidN.*, Afwezigheid.*, COUNT(info_id) AS afw_totaal`, 
        `FROM AfwezigheidN`,
        `LEFT JOIN Info ON info_id = afwn_info_id LEFT JOIN Afwezigheid ON afw_id = afwn_afw_id${rightJoin.length > 0 ? rightJoin : ''}`,
        where,
        `GROUP BY info_id, afw_id`,
        null
      );

      for (const user of this.state.accounts) {
        absencesTotal.push(user);
      }

      for (let user of absencesTotal) {

        for (const abs of absences) {

          if (abs.info_id === user.info_id) {

            if (typeof user.absences === 'undefined' || user.absences === null) {
              user.absences = {};
            }

            user.absences = {
              ...user.absences,
              [abs.afw_naam]: abs.afw_totaal,
            };

          }

        }

      }

      if (this.state.abs_sort === 'name ASC') {
        absencesTotal.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      } else if (this.state.abs_sort === 'name DESC') {
        absencesTotal.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
      } else {

        for (const abs of this.state.abs_absence_types) {

          if (this.state.abs_sort === `${abs.afw_naam} ASC`) {
            absencesTotal.sort((a, b) => (((Constants.isEmpty(a.absences) || Constants.isEmpty(a.absences[abs.afw_naam])) ? 0 : parseInt(a.absences[abs.afw_naam])) > ((Constants.isEmpty(b.absences) || Constants.isEmpty(b.absences[abs.afw_naam])) ? 0 : parseInt(b.absences[abs.afw_naam]))) ? 1 : -1);
          } else if (this.state.abs_sort === `${abs.afw_naam} DESC`) {
            absencesTotal.sort((a, b) => (((Constants.isEmpty(a.absences) || Constants.isEmpty(a.absences[abs.afw_naam])) ? 0 : parseInt(a.absences[abs.afw_naam])) > ((Constants.isEmpty(b.absences) || Constants.isEmpty(b.absences[abs.afw_naam])) ? 0 : parseInt(b.absences[abs.afw_naam]))) ? -1 : 1);
          }

        }

      }

    } else {

      if (this.props.individual === true) {
        where += ` AND info_id = ${this.props.individual_userID}`;
      } else {
        where += ` AND info_id = ${this.state.abs_employee}`;
      }

      absencesTotal = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, AfwezigheidN.*, Afwezigheid.*`, 
        `FROM AfwezigheidN`,
        `LEFT JOIN Info ON info_id = afwn_info_id LEFT JOIN Afwezigheid ON afw_id = afwn_afw_id${rightJoin.length > 0 ? rightJoin : ''}`,
        where,
        null,
        `ORDER BY info_voornaam, info_achternaam, afwn_datum`
      );

    }

    this.setState({
      abs_absences: absencesTotal,
    });

  }

  abs_getSortTypes() {

    let toRender = [];

    for (const abs of this.state.abs_absence_types) {

      toRender.push(
        <option value={`${abs.afw_naam} ASC`} selected={this.state.abs_sort === `${abs.afw_naam} ASC`}>{abs.afw_naam} {__('misc_asc').toLowerCase()}</option>
      );

      toRender.push(
        <option value={`${abs.afw_naam} DESC`} selected={this.state.abs_sort === `${abs.afw_naam} DESC`}>{abs.afw_naam} {__('misc_desc').toLowerCase()}</option>
      );

    }

    return toRender;

  }

  abs_changeSort(e) {

    let abs_absences = this.state.abs_absences;

    this.setState({ abs_sort: e.target.value });

    if (e.target.value === 'name ASC') {
      abs_absences.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    } else if (e.target.value === 'name DESC') {
      abs_absences.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
    } else {

      for (const abs of this.state.abs_absence_types) {

        if (e.target.value === `${abs.afw_naam} ASC`) {
          abs_absences.sort((a, b) => (((Constants.isEmpty(a.absences) || Constants.isEmpty(a.absences[abs.afw_naam])) ? 0 : parseInt(a.absences[abs.afw_naam])) > ((Constants.isEmpty(b.absences) || Constants.isEmpty(b.absences[abs.afw_naam])) ? 0 : parseInt(b.absences[abs.afw_naam]))) ? 1 : -1);
        } else if (e.target.value === `${abs.afw_naam} DESC`) {
          abs_absences.sort((a, b) => (((Constants.isEmpty(a.absences) || Constants.isEmpty(a.absences[abs.afw_naam])) ? 0 : parseInt(a.absences[abs.afw_naam])) > ((Constants.isEmpty(b.absences) || Constants.isEmpty(b.absences[abs.afw_naam])) ? 0 : parseInt(b.absences[abs.afw_naam]))) ? -1 : 1);
        }

      }

    }

    this.setState({ abs_absences: abs_absences });

  }

  abs_changeEmployee(e) {

    this.setState({ abs_employee: e.target.value, loading: true }, async () => {
      await this.hardReloadScreen();
    });

  }

  abs_selectStartDate(date) {

    this.setState({ abs_start: date }, async () => {
      await this.reloadScreen();
    });

  }

  abs_selectEndDate(date) {

    this.setState({ abs_end: date }, async () => {
      await this.reloadScreen();
    });
  }

  abs_render_title() {

    let toRender = [];

    if ((this.state.abs_employee === 'null' || this.state.abs_employee === 'all') && this.props.individual !== true) {

      for (const abs of this.state.abs_absence_types) {

        toRender.push(
          <div className='hr_hoursBar_sub3'>
            {abs.afw_naam} 
          </div>
        );

      }

    } else {

      toRender.push(
        <div className='hr_hoursBar_sub2'>
          {__('misc_shift')}
        </div>
      );

      // toRender.push(
      //   <div className='hr_hoursBar_sub2'>
      //     {__('misc_job')}
      //   </div>
      // );

      toRender.push(
        <div className='hr_hoursBar_sub2'>
          {__('misc_absenceType')}
        </div>
      );

    }

    return toRender;

  }

  abs_render() {

    let toRender = [];
    let count = 0;

    for (const data of this.state.abs_absences) {

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

      if ((this.state.abs_employee === 'null' || this.state.abs_employee === 'all') && this.props.individual !== true) {

        let absRender = [];

        for (const abs of this.state.abs_absence_types) {

          if (typeof data.absences === 'undefined' || data.absences === null) {

            absRender.push(
              <div className='hr_hoursBar_sub3'>
                <span>0</span>
              </div>
            );

          } else {

            if (Constants.isEmpty(data.absences[abs.afw_naam]) === false) {

              absRender.push(
                <div className='hr_hoursBar_sub3'>
                  <span>{data.absences[abs.afw_naam]}</span>
                </div>
              );

            } else {

              absRender.push(
                <div className='hr_hoursBar_sub3'>
                  <span>0</span>
                </div>
              );

            }

          }

        }

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span className='defaultLinkO' onClick={() => this.abs_changeEmployee({ target: { value: data.info_id } })}>{userFullName}</span>
            </div>

            {absRender}

          </div>

        );

      } else {

        const date = Constants.stringToDate(data.afwn_datum);

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            {/* ({data.rooster_begin.substr(0, 5)} - {data.rooster_eind.substr(0, 5)}) */}
            <div className='hr_hoursBar_sub2'>
              <span>{Constants.getShortDayName(date).toLowerCase()} {date.toLocaleDateString()}</span>
            </div>

            {/* <div className='hr_hoursBar_sub2'>
              <span>{data.functie_naam}</span>
            </div> */}

            <div className='hr_hoursBar_sub2'>
              <span>{data.afw_naam}</span>
            </div>

          </div>

        );

      }

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async minmax_getMinmaxData() {

    let hours = [];

    let where = ``;
    let rightJoin = ``;

    // HOURS AND SALARIES
    if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === false) {

      where += `WHERE rooster_datum BETWEEN '${Constants.dateToString(this.state.minmax_start)}' AND '${Constants.dateToString(this.state.minmax_end)}' AND uit_id IS NULL AND rooster_publiceren = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
        rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
        where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      hours = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*`,
        // `COUNT(DISTINCT rooster_datum) as rooster_werkdagen, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) ) as rooster_bruto, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) - TIME_TO_SEC(rooster_pauze) ) as rooster_netto, ` +
        // `SUM( TIME_TO_SEC(rooster_pauze) ) as rooster_pauze`,
        `FROM Rooster`,
        (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
        where,
        null, // `GROUP BY info_id`, // HAVING rooster_netto > 0
        `ORDER BY info_voornaam, info_achternaam`,
      );

      let _newHours = [];
      for (const h of hours) {
        const contract = Constants.getCurrentContract(this.state.contracts[h.info_id], Constants.stringToDate(h.rooster_datum));
        if (contract !== null && contract.contract_type === '1') {
          _newHours.push(h);
        }
      }

      hours = _newHours;

      let _totalHours = [];
      for (const h of hours) {

        if (Constants.objectArrayContainsKey(_totalHours, 'info_id', h.info_id) === false) {

          const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
          const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.rooster_datum));

          _totalHours.push({
            ...h,
            total: hours,
            weeks: [week],
            weekHours: {
              [week.toString()]: hours,
            },
          });

        } else {

          let item = Constants.getArrayItem(_totalHours, 'info_id', h.info_id);

          const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
          const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.rooster_datum));

          if (Constants.arrayContains(item.weeks, week) === false) {
            item.weeks.push(week);
          }

          if (typeof item.weekHours[week.toString()] !== 'undefined') {
            item.weekHours[week.toString()] += hours;
          } else {
            item.weekHours[week.toString()] = hours;
          }

          item.total += hours;

        }

      }

      // sort weeks
      for (let h of _totalHours) {
        if (h.weeks.length > 1) {
          h.weeks.sort((a, b) => (a > b) ? 1 : -1);
        } 
      }

      hours = _totalHours;

      if ((this.state.minmax_employee !== 'null' && this.state.minmax_employee !== 'all') || this.props.individual === true) {

        if (this.props.individual === true) {
          where += ` AND info_id = ${this.props.individual_userID}`;
        } else {
          where += ` AND info_id = ${this.state.minmax_employee}`;
        }

        hours = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*`,
          `FROM Rooster`,
          (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
          where,
          null, // HAVING rooster_netto > 0
          `ORDER BY info_voornaam, info_achternaam, rooster_datum, rooster_begin, rooster_eind`,
        );

        let _totalHours = {};
        for (const h of hours) {

          if (typeof _totalHours[h.info_id] === 'undefined') {
            _totalHours[h.info_id] = {};
          }

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.rooster_datum));

          if (typeof _totalHours[h.info_id][week.toString()] === 'undefined') {

            const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
            const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()] = {
              total: hours,
              ...h,
            };

          } else {

            const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
            const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()].total += hours;

          }

        }

        hours = _totalHours;

      }

    // DAADWERKELIJK
    } else {

      where += `WHERE uren_begindatum BETWEEN '${Constants.dateToString(this.state.minmax_start)}' AND '${Constants.dateToString(this.state.minmax_end)}' AND uit_id IS NULL AND uren_goedgekeurd = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {

        if (Data.data.appOptions.hours === 'CLOCK') {
          rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
          where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
        } else {
          rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
          where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
        }

      }

      hours = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*`,
        `FROM Uren`,
        (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
        where,
        null, // `GROUP BY info_id`, // HAVING rooster_netto > 0
        `ORDER BY info_voornaam, info_achternaam`,
      );

      let _newHours = [];
      for (const h of hours) {
        const contract = Constants.getCurrentContract(this.state.contracts[h.info_id], Constants.stringToDate(h.uren_begindatum));
        if (contract !== null && contract.contract_type === '1') {
          _newHours.push(h);
        }
      }

      hours = _newHours;

      let _totalHours = [];
      for (const h of hours) {

        if (Constants.objectArrayContainsKey(_totalHours, 'info_id', h.info_id) === false) {

          const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
          const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.uren_begindatum));

          _totalHours.push({
            ...h,
            total: hours,
            weeks: [week],
            weekHours: {
              [week.toString()]: hours,
            },
          });

        } else {

          let item = Constants.getArrayItem(_totalHours, 'info_id', h.info_id);

          const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
          const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.uren_begindatum));

          if (Constants.arrayContains(item.weeks, week) === false) {
            item.weeks.push(week);
          }

          if (typeof item.weekHours[week.toString()] !== 'undefined') {
            item.weekHours[week.toString()] += hours;
          } else {
            item.weekHours[week.toString()] = hours;
          }

          item.total += hours;

        }

      }

      // sort weeks
      for (let h of _totalHours) {
        if (h.weeks.length > 1) {
          h.weeks.sort((a, b) => (a > b) ? 1 : -1);
        } 
      }

      hours = _totalHours;

      if ((this.state.minmax_employee !== 'null' && this.state.minmax_employee !== 'all') || this.props.individual === true) {

        if (this.props.individual === true) {
          where += ` AND info_id = ${this.props.individual_userID}`;
        } else {
          where += ` AND info_id = ${this.state.minmax_employee}`;
        }

        hours = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*`,
          `FROM Uren`,
          (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
          where,
          null, // HAVING rooster_netto > 0
          `ORDER BY info_voornaam, info_achternaam, uren_begindatum, uren_begin, uren_einddatum, uren_eind`,
        );

        let _totalHours = {};
        for (const h of hours) {

          if (typeof _totalHours[h.info_id] === 'undefined') {
            _totalHours[h.info_id] = {};
          }

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.uren_begindatum));

          if (typeof _totalHours[h.info_id][week.toString()] === 'undefined') {

            const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
            const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()] = {
              total: hours,
              ...h,
            };

          } else {

            const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
            const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()].total += hours;

          }

        }

        hours = _totalHours;

      }

    }
    
    this.setState({
      minmax_hours: hours,
    });

  }

  minmax_changeSort(e) {

    let minmax_hours = this.state.minmax_hours;

    this.setState({ minmax_sort: e.target.value });

    if (e.target.value === 'name ASC') {
      minmax_hours.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    } else if (e.target.value === 'name DESC') {
      minmax_hours.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
    }
    
    if ((this.state.minmax_employee === 'null' || this.state.minmax_employee === 'all') && this.props.individual !== true) {
      if (e.target.value === 'hours ASC') {
        minmax_hours.sort((a, b) => (a.total > b.total) ? 1 : -1);
      } else if (e.target.value === 'hours DESC') {
        minmax_hours.sort((a, b) => (a.total > b.total) ? -1 : 1);
      }
    } else {
      minmax_hours.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    }

    this.setState({ minmax_hours: minmax_hours });

  }

  minmax_changeEmployee(e) {

    this.setState({ minmax_employee: e.target.value, loading: true }, async () => {
      await this.hardReloadScreen();
    });

  }

  minmax_selectStartDate(date) {

    this.setState({ minmax_start: date }, async () => {
      await this.reloadScreen();
    });

  }

  minmax_selectEndDate(date) {

    this.setState({ minmax_end: date }, async () => {
      await this.reloadScreen();
    });
  }

  minmax_render_title() {

    let toRender = [];

    if ((this.state.minmax_employee === 'null' || this.state.minmax_employee === 'all') && this.props.individual !== true) {

      toRender.push(
        <div className='hr_hoursBar_sub2'>
          {__('misc_period')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_minContractHours')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_minHours')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_aboveMinHours')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_total')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_averageWeek')}
        </div>
      );

    } else {

      toRender.push(
        <div className='hr_hoursBar_sub2'>
          {__('misc_week_c')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_minContractHours')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_aboveMinHours')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_total')}
        </div>
      );

    }

    return toRender;

  }

  minmax_render() {

    let toRender = [];
    let count = 0;

    if ((this.state.minmax_employee === 'null' || this.state.minmax_employee === 'all') && this.props.individual !== true) {

      for (const data of this.state.minmax_hours) {

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
        const total = isNaN(data.total) || data.total === 0 ? Constants.msToTime2(0) : Constants.msToTime2(data.total);

        const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], this.state.minmax_start);

        let minHours = 0;
        let above = 0;
        let sum = 0;
        let minWeek = 0;

        if (contract !== null && contract.contract_type === '1') {

          const _min = parseFloat(contract.contract_uren) * 60 * 60 * 1000;

          minWeek = _min;
          
          if (data.weeks.length > 0) {

            minHours = _min * data.weeks.length;
            // above = data.total - minHours;
            sum = data.total / data.weeks.length;

            // if (above < 0) {
            //   above = 0;
            // }

            for (const key in data.weekHours) {
              const _above = (data.weekHours[key] - minWeek) < 0 ? 0 : (data.weekHours[key] - minWeek);
              above += _above;
            }

          }

        }

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span> 
            </div>

            <div className='hr_hoursBar_sub2'>
              {data.weeks.length > 1 ?
                <span>{__('misc_week_c')} {data.weeks[0]} - {data.weeks[data.weeks.length - 1]}</span> 
              :
                <span>{__('misc_week_c')} {data.weeks[0]}</span>
              }
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.msToTime2(minWeek)}</span> 
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.msToTime2(minHours)}</span> 
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.msToTime2(above)}</span> 
            </div>

            <div className='hr_hoursBar_sub3'>
              <span><b>{total}</b></span> 
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.msToTime2(sum)}</span> 
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    } else {

      let _minHours = 0;
      let _above = 0;
      let _total = 0;
      let _sum = 0;

      for (const userKey in this.state.minmax_hours) {

        for (const weekKey in this.state.minmax_hours[userKey]) {

          const data = this.state.minmax_hours[userKey][weekKey];

          const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
          const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], this.dateNow);

          let above = 0;
          let minWeek = 0;
          if (contract !== null && contract.contract_type === '1') {

            const _min = parseFloat(contract.contract_uren) * 60 * 60 * 1000;

            minWeek = _min;
            above = data.total - minWeek;

            if (above < 0) {
              above = 0;
            }

            _minHours += minWeek;
            _above += above;

          }

          _total += data.total;
          _sum += (data.total / Object.keys(this.state.minmax_hours[userKey]).length);

          toRender.push(

            <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              <div className='hr_hoursBar_sub1'>
                <span>{userFullName}</span> 
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{__('misc_week_c')} {weekKey}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{Constants.msToTime2(minWeek)}</span> 
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{Constants.msToTime2(above)}</span> 
              </div>

              <div className='hr_hoursBar_sub3'>
                <span><b>{Constants.msToTime2(data.total)}</b></span> 
              </div>

            </div>

          );

          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

      }

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, borderTop: '1px solid #EBEBEB'}}>

          <div className='hr_hoursBar_sub1'>
            <span><b>{__('misc_total')}</b></span> 
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span><b>{Constants.msToTime2(_minHours)}</b></span> 
          </div>

          <div className='hr_hoursBar_sub3'>
            <span><b>{Constants.msToTime2(_above)}</b></span> 
          </div>

          <div className='hr_hoursBar_sub3'>
            <span><b>{Constants.msToTime2(_total)}</b></span> 
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            <span><b>{__('misc_averageHoursWeek')}</b></span> 
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>&nbsp;</span> 
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>&nbsp;</span> 
          </div>

          <div className='hr_hoursBar_sub3'>
            <span><b>{Constants.msToTime2(_sum)}</b></span> 
          </div>

        </div>

      );

    }

    return toRender;

  }

  async flex_getFlexData() {

    let hours = [];

    let where = ``;
    let rightJoin = ``;

    // HOURS AND SALARIES
    if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === false) {

      where += `WHERE rooster_datum BETWEEN '${Constants.dateToString(this.state.flex_start)}' AND '${Constants.dateToString(this.state.flex_end)}' AND uit_id IS NULL AND rooster_publiceren = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
        rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
        where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      hours = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*`,
        // `COUNT(DISTINCT rooster_datum) as rooster_werkdagen, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) ) as rooster_bruto, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) - TIME_TO_SEC(rooster_pauze) ) as rooster_netto, ` +
        // `SUM( TIME_TO_SEC(rooster_pauze) ) as rooster_pauze`,
        `FROM Rooster`,
        (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
        where,
        null, // `GROUP BY info_id`, // HAVING rooster_netto > 0
        `ORDER BY info_voornaam, info_achternaam`,
      );

      let _newHours = [];
      for (const h of hours) {
        const contract = Constants.getCurrentContract(this.state.contracts[h.info_id], Constants.stringToDate(h.rooster_datum));
        if (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type)) {
          _newHours.push(h);
        }
      }

      hours = _newHours;

      let _totalHours = [];
      for (const h of hours) {

        if (Constants.objectArrayContainsKey(_totalHours, 'info_id', h.info_id) === false) {

          const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
          const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.rooster_datum));

          _totalHours.push({
            ...h,
            total: hours,
            weeks: [week],
          });

        } else {

          let item = Constants.getArrayItem(_totalHours, 'info_id', h.info_id);

          const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
          const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.rooster_datum));

          if (Constants.arrayContains(item.weeks, week) === false) {
            item.weeks.push(week);
          }

          item.total += hours;

        }

      }

      // sort weeks
      for (let h of _totalHours) {
        if (h.weeks.length > 1) {
          h.weeks.sort((a, b) => (a > b) ? 1 : -1);
        } 
      }

      hours = _totalHours;

      if ((this.state.flex_employee !== 'null' && this.state.flex_employee !== 'all') || this.props.individual === true) {

        if (this.props.individual === true) {
          where += ` AND info_id = ${this.props.individual_userID}`;
        } else {
          where += ` AND info_id = ${this.state.flex_employee}`;
        }

        hours = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*`,
          `FROM Rooster`,
          (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
          where,
          null, // HAVING rooster_netto > 0
          `ORDER BY info_voornaam, info_achternaam, rooster_datum, rooster_begin, rooster_eind`,
        );

        let _totalHours = {};
        for (const h of hours) {

          if (typeof _totalHours[h.info_id] === 'undefined') {
            _totalHours[h.info_id] = {};
          }

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.rooster_datum));

          if (typeof _totalHours[h.info_id][week.toString()] === 'undefined') {

            const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
            const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()] = {
              total: hours,
              ...h,
            };

          } else {

            const start = Constants.stringToDateTime(h.rooster_datum, h.rooster_begin.substr(0,5));
            const end = Constants.stringToDateTime(Plan.isNightShift2(h), h.rooster_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.rooster_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()].total += hours;

          }

        }

        hours = _totalHours;

      }

    // DAADWERKELIJK
    } else {

      where += `WHERE uren_begindatum BETWEEN '${Constants.dateToString(this.state.flex_start)}' AND '${Constants.dateToString(this.state.flex_end)}' AND uit_id IS NULL AND uren_goedgekeurd = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {

        if (Data.data.appOptions.hours === 'CLOCK') {
          rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
          where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
        } else {
          rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
          where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
        }

      }

      hours = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*`,
        `FROM Uren`,
        (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
        where,
        null, // `GROUP BY info_id`, // HAVING rooster_netto > 0
        `ORDER BY info_voornaam, info_achternaam`,
      );

      let _newHours = [];
      for (const h of hours) {
        const contract = Constants.getCurrentContract(this.state.contracts[h.info_id], Constants.stringToDate(h.uren_begindatum));
        if (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type)) {
          _newHours.push(h);
        }
      }

      hours = _newHours;

      let _totalHours = [];
      for (const h of hours) {

        if (Constants.objectArrayContainsKey(_totalHours, 'info_id', h.info_id) === false) {

          const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
          const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.uren_begindatum));

          _totalHours.push({
            ...h,
            total: hours,
            weeks: [week],
          });

        } else {

          let item = Constants.getArrayItem(_totalHours, 'info_id', h.info_id);

          const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
          const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
          const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
          const hours = ((end - start) - _break);

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.uren_begindatum));

          if (Constants.arrayContains(item.weeks, week) === false) {
            item.weeks.push(week);
          }

          item.total += hours;

        }

      }

      // sort weeks
      for (let h of _totalHours) {
        if (h.weeks.length > 1) {
          h.weeks.sort((a, b) => (a > b) ? 1 : -1);
        } 
      }

      hours = _totalHours;

      if ((this.state.flex_employee !== 'null' && this.state.flex_employee !== 'all') || this.props.individual === true) {

        if (this.props.individual === true) {
          where += ` AND info_id = ${this.props.individual_userID}`;
        } else {
          where += ` AND info_id = ${this.state.flex_employee}`;
        }

        hours = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*`,
          `FROM Uren`,
          (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
          where,
          null, // HAVING rooster_netto > 0
          `ORDER BY info_voornaam, info_achternaam, uren_begindatum, uren_begin, uren_einddatum, uren_eind`,
        );
        
        let _totalHours = {};
        for (const h of hours) {

          if (typeof _totalHours[h.info_id] === 'undefined') {
            _totalHours[h.info_id] = {};
          }

          const week = Constants.getWeekNumber2(Constants.stringToDate(h.uren_begindatum));

          if (typeof _totalHours[h.info_id][week.toString()] === 'undefined') {

            const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
            const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()] = {
              total: hours,
              ...h,
            };

          } else {

            const start = Constants.stringToDateTime(h.uren_begindatum, h.uren_begin.substr(0,5));
            const end = Constants.stringToDateTime(h.uren_einddatum, h.uren_eind.substr(0,5));
            const _break = Constants.timeStringToMS(h.uren_pauze.substr(0,5));
            const hours = ((end - start) - _break);

            _totalHours[h.info_id][week.toString()].total += hours;

          }

        }

        hours = _totalHours;

      }

    }
    
    this.setState({
      flex_hours: hours,
    });

  }

  flex_changeSort(e) {

    let flex_hours = this.state.flex_hours;

    this.setState({ flex_sort: e.target.value });
    
    if ((this.state.flex_employee === 'null' || this.state.flex_employee === 'all') && this.props.individual !== true) {

      if (e.target.value === 'hours ASC') {
        flex_hours.sort((a, b) => (a.total > b.total) ? 1 : -1);
      } else if (e.target.value === 'hours DESC') {
        flex_hours.sort((a, b) => (a.total > b.total) ? -1 : 1);
      } else if (e.target.value === 'name ASC') {
        flex_hours.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      } else if (e.target.value === 'name DESC') {
        flex_hours.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
      }

    }

    this.setState({ flex_hours: flex_hours });

  }

  flex_changeEmployee(e) {

    this.setState({ flex_employee: e.target.value, loading: true }, async () => {
      await this.hardReloadScreen();
    });

  }

  flex_selectStartDate(date) {

    this.setState({ flex_start: date }, async () => {
      await this.reloadScreen();
    });

  }

  flex_selectEndDate(date) {

    this.setState({ flex_end: date }, async () => {
      await this.reloadScreen();
    });
  }

  flex_render_title() {

    let toRender = [];

    if ((this.state.flex_employee === 'null' || this.state.flex_employee === 'all') && this.props.individual !== true) {

      toRender.push(
        <div className='hr_hoursBar_sub2'>
          {__('misc_period')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_total')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_averageWeek')}
        </div>
      );

    } else {

      toRender.push(
        <div className='hr_hoursBar_sub2'>
          {__('misc_week_c')}
        </div>
      );

      toRender.push(
        <div className='hr_hoursBar_sub3'>
          {__('misc_total')}
        </div>
      );

    }

    return toRender;

  }

  flex_render() {

    let toRender = [];
    let count = 0;

    if ((this.state.flex_employee === 'null' || this.state.flex_employee === 'all') && this.props.individual !== true) {

      for (const data of this.state.flex_hours) {

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
        const total = isNaN(data.total) || data.total === 0 ? Constants.msToTime2(0) : Constants.msToTime2(data.total);

        let sum = 0;
        if (data.weeks.length > 0) {
          sum = data.total / data.weeks.length;
        }

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span> 
            </div>

            <div className='hr_hoursBar_sub2'>
              {data.weeks.length > 1 ?
                <span>{__('misc_week_c')} {data.weeks[0]} - {data.weeks[data.weeks.length - 1]}</span> 
              :
                <span>{__('misc_week_c')} {data.weeks[0]}</span>
              }
            </div>

            <div className='hr_hoursBar_sub3'>
              <span><b>{total}</b></span> 
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.msToTime2(sum)}</span> 
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    } else {

      let _total = 0;
      let _sum = 0;

      for (const userKey in this.state.flex_hours) {

        for (const weekKey in this.state.flex_hours[userKey]) {

          const data = this.state.flex_hours[userKey][weekKey];
          const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

          _total += data.total;
          _sum += (data.total / Object.keys(this.state.flex_hours[userKey]).length);

          toRender.push(

            <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              <div className='hr_hoursBar_sub1'>
                <span>{userFullName}</span> 
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{__('misc_week_c')} {weekKey}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span><b>{Constants.msToTime2(data.total)}</b></span> 
              </div>

            </div>

          );
          
          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

      }

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, borderTop: '1px solid #EBEBEB'}}>

          <div className='hr_hoursBar_sub1'>
            <span><b>{__('misc_total')}</b></span> 
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span><b>{Constants.msToTime2(_total)}</b></span> 
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

      toRender.push(

        <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            <span><b>{__('misc_averageHoursWeek')}</b></span> 
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span><b>{Constants.msToTime2(_sum)}</b></span> 
          </div>

        </div>

      );

    }

    return toRender;

  }

  async finances_getFinancesData() {

    let finances = [];
    let financesTotal = [];
    let finances_all = { gains: 0, egains: 0, productivity: 0, salary: 0, hours: 0, };
    let where = ``;
    let rightJoin = ``;

    // HOURS AND SALARIES
    if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === false) {

      if (this.state.finances_period === 1) { // periodiek
        where += `WHERE rooster_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}' AND uit_id IS NULL AND rooster_publiceren = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)
      } else { // maandelijks
        where += `WHERE MONTH(rooster_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(rooster_datum) = ${this.state.finances_month.getFullYear()} AND uit_id IS NULL AND rooster_publiceren = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)
      }

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {
        rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
        where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
      }

      finances = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*`,
        // `COUNT(DISTINCT rooster_datum) as rooster_werkdagen, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) ) as rooster_bruto, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) - TIME_TO_SEC(rooster_pauze) ) as rooster_netto, ` +
        // `SUM( TIME_TO_SEC(rooster_pauze) ) as rooster_pauze`,
        `FROM Rooster`,
        (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
        where,
        null, // `GROUP BY info_id`, // HAVING rooster_netto > 0
        `ORDER BY info_voornaam, info_achternaam`,
      );

      // make it loop through all the hours and dates to check for bonusses and age price differences. so remove the SUM parts and return all the rooster_id's

      for (let user of this.state.accounts) {

        user.finances = {
          hoursPlain: 0,
          hours: 0,
          salary: 0,
          gains: 0,
        };

        financesTotal.push(user);

      }

      for (let user of financesTotal) {

        let netHours = 0;
        let netHoursPlain = 0;
        let hasContract = false;
        let salary = 0;

        const contractCheck = Constants.getCurrentContract(this.state.contracts[user.info_id], this.state.finances_month);

        if (Constants.isEmpty(contractCheck) === false && contractCheck.contract_type === '2') {

          for (const data of finances) {

            if (data.info_id === user.info_id) {

              const start = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
              const end = Constants.stringToDateTime(Plan.isNightShift2(data), data.rooster_eind);
              const brk = Constants.timeStringToMS(data.rooster_pauze) / 1000;

              const net = ((end.getTime() - start.getTime()) / 1000);

              netHours += (((this.state.finances_bonus === true ? Plan.calcBonus(data, net, this.state.bonus) : net) - brk) / 60 / 60);
              netHoursPlain += (net - brk) / 60 / 60;

            }

          }

          user.finances = {
            hoursPlain: Constants.round(netHoursPlain),
            hours: Constants.round(netHours),
            salary: Constants.isEmpty(contractCheck.contract_maandloon) === false ? isNaN(Constants.round(parseFloat(contractCheck.contract_maandloon) * this.salaryFactor)) === true ? 0 : Constants.round(parseFloat(contractCheck.contract_maandloon) * this.salaryFactor) : 0,
            gains: 0,
          };

        } else {

          for (const data of finances) {

            if (data.info_id === user.info_id) {

              if (salary === 0) {
                const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], Constants.stringToDate(data.rooster_datum));
                salary = contract !== null && contract.contract_type !== '2' ? parseFloat(contract.contract_uurloon) : 0;
              }

              const start = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
              const end = Constants.stringToDateTime(Plan.isNightShift2(data), data.rooster_eind);
              const brk = Constants.timeStringToMS(data.rooster_pauze) / 1000;

              const net = ((end.getTime() - start.getTime()) / 1000);

              netHours += (((this.state.finances_bonus === true ? Plan.calcBonus(data, net, this.state.bonus) : net) - brk) / 60 / 60);
              netHoursPlain += (net - brk) / 60 / 60;

            }

          }

          user.finances = {
            hoursPlain: Constants.round(netHoursPlain),
            hours: Constants.round(netHours),
            salary: isNaN(Constants.round(netHours * salary * this.salaryFactor)) === true ? 0 : Constants.round(netHours * salary * this.salaryFactor),
            gains: 0,
          };

        }

      }

      // ALL OTHER FINANCES
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        const gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE ${this.state.finances_period === 1 ? `(omzetd_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetd_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetd_datum) = ${this.state.finances_month.getFullYear()}`} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
        const egains = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE ${this.state.finances_period === 1 ? `(omzetv_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetv_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetv_datum) = ${this.state.finances_month.getFullYear()}`} AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);

        for (const gain of gains) {
          finances_all.gains += isNaN(parseFloat(gain.omzetd_hoeveelheid)) === false ? parseFloat(gain.omzetd_hoeveelheid) : 0;
        }

        for (const gain of egains) {
          finances_all.egains += isNaN(parseFloat(gain.omzetv_hoeveelheid)) === false ? parseFloat(gain.omzetv_hoeveelheid) : 0;
        }

        } else {

        const gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE ${this.state.finances_period === 1 ? `(omzetd_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetd_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetd_datum) = ${this.state.finances_month.getFullYear()}`}`, null, null);
        const egains = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE ${this.state.finances_period === 1 ? `(omzetv_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetv_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetv_datum) = ${this.state.finances_month.getFullYear()}`}`, null, null);

        for (const gain of gains) {
          finances_all.gains += isNaN(parseFloat(gain.omzetd_hoeveelheid)) === false ? parseFloat(gain.omzetd_hoeveelheid) : 0;
        }

        for (const gain of egains) {
          finances_all.egains += isNaN(parseFloat(gain.omzetv_hoeveelheid)) === false ? parseFloat(gain.omzetv_hoeveelheid) : 0;
        }

      }

      let totalHours = 0;
      let totalHoursPlain = 0;
      let totalSalary = 0;
      for (const user of financesTotal) {
        totalHoursPlain += user.finances.hoursPlain;
        totalHours += user.finances.hoursPlain;
        totalSalary += user.finances.salary;
      }

      finances_all.productivity = isNaN(Constants.round(finances_all.gains / totalHoursPlain)) === false ? Constants.round(finances_all.gains / totalHoursPlain) : 0;
      finances_all.salary = Constants.round(totalSalary);
      finances_all.hours = Constants.round(totalHours);

      for (let user of financesTotal) {
        user.finances.gains = isNaN(Constants.round(finances_all.productivity * user.finances.hoursPlain)) === false ? Constants.round(finances_all.productivity * user.finances.hoursPlain) : 0;
      }

      if (this.state.finances_sort === 'name ASC') {
        financesTotal.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      } else if (this.state.finances_sort === 'name DESC') {
        financesTotal.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
      } else if (this.state.finances_sort === 'hours ASC') {
        financesTotal.sort((a, b) => (a.finances.hours > b.finances.hours) ? 1 : -1);
      } else if (this.state.finances_sort === 'hours DESC') {
        financesTotal.sort((a, b) => (a.finances.hours > b.finances.hours) ? -1 : 1);
      } else if (this.state.finances_sort === 'gains ASC') {
        financesTotal.sort((a, b) => (a.finances.gains > b.finances.gains) ? 1 : -1);
      } else if (this.state.finances_sort === 'gains DESC') {
        financesTotal.sort((a, b) => (a.finances.gains > b.finances.gains) ? -1 : 1);
      } else if (this.state.finances_sort === 'salary ASC') {
        financesTotal.sort((a, b) => (a.finances.salary > b.finances.salary) ? 1 : -1);
      } else if (this.state.finances_sort === 'salary DESC') {
        financesTotal.sort((a, b) => (a.finances.salary > b.finances.salary) ? -1 : 1);
      }

      if ((this.state.finances_employee !== 'null' && this.state.finances_employee !== 'all') || this.props.individual === true) {

        if (this.props.individual === true) {
          where += ` AND info_id = ${this.props.individual_userID}`;
        } else {
          where += ` AND info_id = ${this.state.finances_employee}`;
        }

        financesTotal = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*`,
          `FROM Rooster`,
          (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
          where,
          null, // HAVING rooster_netto > 0
          `ORDER BY info_voornaam, info_achternaam, rooster_datum, rooster_begin, rooster_eind`,
        );

      }

    // DAADWERKELIJK
    } else {

      if (this.state.finances_period === 1) { // periodiek
        where += `WHERE uren_begindatum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}' AND uit_id IS NULL AND uren_goedgekeurd = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)
      } else { // maandelijks
        where += `WHERE MONTH(uren_begindatum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(uren_begindatum) = ${this.state.finances_month.getFullYear()} AND uit_id IS NULL AND uren_goedgekeurd = 1`; // AND (contract_type <> 2 OR contract_type IS NULL)
      }


      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null && this.props.individual !== true) {

        if (Data.data.appOptions.hours === 'CLOCK') {
          rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
          where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
        } else {
          rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
          where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
        }

      }

      finances = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*`,
        `FROM Uren`,
        (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
        where,
        null, // `GROUP BY info_id`, // HAVING rooster_netto > 0
        `ORDER BY info_voornaam, info_achternaam`,
      );

      // make it loop through all the hours and dates to check for bonusses and age price differences. so remove the SUM parts and return all the rooster_id's

      for (let user of this.state.accounts) {

        user.finances = {
          hoursPlain: 0,
          hours: 0,
          salary: 0,
          gains: 0,
        };

        financesTotal.push(user);

      }

      for (let user of financesTotal) {

        let netHours = 0;
        let netHoursPlain = 0;
        let hasContract = false;
        let salary = 0;

        const contractCheck = Constants.getCurrentContract(this.state.contracts[user.info_id], this.state.finances_month);

        if (Constants.isEmpty(contractCheck) === false && contractCheck.contract_type === '2') {

          for (const data of finances) {

            if (data.info_id === user.info_id) {

              const start = Constants.stringToDateTime(data.uren_begindatum, data.uren_begin);
              const end = Constants.stringToDateTime(data.uren_einddatum, data.uren_eind);
              const brk = Constants.timeStringToMS(data.uren_pauze) / 1000;

              const net = ((end.getTime() - start.getTime()) / 1000);

              netHours += (((this.state.finances_bonus === true ? Plan.calcBonusActual(data, net, this.state.bonus) : net) - brk) / 60 / 60);
              netHoursPlain += (net - brk) / 60 / 60;

            }

          }

          user.finances = {
            hoursPlain: Constants.round(netHoursPlain),
            hours: Constants.round(netHours),
            salary: Constants.isEmpty(contractCheck.contract_maandloon) === false ? isNaN(Constants.round(parseFloat(contractCheck.contract_maandloon) * this.salaryFactor)) === true ? 0 : Constants.round(parseFloat(contractCheck.contract_maandloon) * this.salaryFactor) : 0,
            gains: 0,
          };

        } else {

          for (const data of finances) {

            if (data.info_id === user.info_id) {

              if (salary === 0) {
                const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], Constants.stringToDate(data.uren_begindatum));
                salary = contract !== null && contract.contract_type !== '2' ? parseFloat(contract.contract_uurloon) : 0;
              }

              const start = Constants.stringToDateTime(data.uren_begindatum, data.uren_begin);
              const end = Constants.stringToDateTime(data.uren_einddatum, data.uren_eind);
              const brk = Constants.timeStringToMS(data.uren_pauze) / 1000;

              const net = ((end.getTime() - start.getTime()) / 1000);

              netHours += (((this.state.finances_bonus === true ? Plan.calcBonusActual(data, net, this.state.bonus) : net) - brk) / 60 / 60);
              netHoursPlain += (net - brk) / 60 / 60;

            }

          }

          user.finances = {
            hoursPlain: Constants.round(netHoursPlain),
            hours: Constants.round(netHours),
            salary: isNaN(Constants.round(netHours * salary * this.salaryFactor)) === true ? 0 : Constants.round(netHours * salary * this.salaryFactor),
            gains: 0,
          };

        }

      }

      // ALL OTHER FINANCES
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        const gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE ${this.state.finances_period === 1 ? `(omzetd_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetd_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetd_datum) = ${this.state.finances_month.getFullYear()}`} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
        const egains = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE ${this.state.finances_period === 1 ? `(omzetv_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetv_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetv_datum) = ${this.state.finances_month.getFullYear()}`} AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);

        for (const gain of gains) {
          finances_all.gains += isNaN(parseFloat(gain.omzetd_hoeveelheid)) === false ? parseFloat(gain.omzetd_hoeveelheid) : 0;
        }

        for (const gain of egains) {
          finances_all.egains += isNaN(parseFloat(gain.omzetv_hoeveelheid)) === false ? parseFloat(gain.omzetv_hoeveelheid) : 0;
        }

        } else {

        const gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE ${this.state.finances_period === 1 ? `(omzetd_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetd_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetd_datum) = ${this.state.finances_month.getFullYear()}`}`, null, null);
        const egains = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE ${this.state.finances_period === 1 ? `(omzetv_datum BETWEEN '${Constants.dateToString(this.state.finances_start)}' AND '${Constants.dateToString(this.state.finances_end)}')` : `MONTH(omzetv_datum) = ${this.state.finances_month.getMonth() + 1} AND YEAR(omzetv_datum) = ${this.state.finances_month.getFullYear()}`}`, null, null);

        for (const gain of gains) {
          finances_all.gains += isNaN(parseFloat(gain.omzetd_hoeveelheid)) === false ? parseFloat(gain.omzetd_hoeveelheid) : 0;
        }

        for (const gain of egains) {
          finances_all.egains += isNaN(parseFloat(gain.omzetv_hoeveelheid)) === false ? parseFloat(gain.omzetv_hoeveelheid) : 0;
        }

      }

      let totalHoursPlain = 0;
      let totalHours = 0;
      let totalSalary = 0;
      for (const user of financesTotal) {
        totalHoursPlain += user.finances.hoursPlain;
        totalHours += user.finances.hoursPlain;
        totalSalary += user.finances.salary;
      }

      finances_all.productivity = isNaN(Constants.round(finances_all.gains / totalHoursPlain)) === false ? Constants.round(finances_all.gains / totalHoursPlain) : 0;
      finances_all.salary = Constants.round(totalSalary);
      finances_all.hours = Constants.round(totalHours);

      for (let user of financesTotal) {
        user.finances.gains = isNaN(Constants.round(finances_all.productivity * user.finances.hoursPlain)) === false ? Constants.round(finances_all.productivity * user.finances.hoursPlain) : 0;
      }

      if (this.state.finances_sort === 'name ASC') {
        financesTotal.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      } else if (this.state.finances_sort === 'name DESC') {
        financesTotal.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
      } else if (this.state.finances_sort === 'hours ASC') {
        financesTotal.sort((a, b) => (a.finances.hours > b.finances.hours) ? 1 : -1);
      } else if (this.state.finances_sort === 'hours DESC') {
        financesTotal.sort((a, b) => (a.finances.hours > b.finances.hours) ? -1 : 1);
      } else if (this.state.finances_sort === 'gains ASC') {
        financesTotal.sort((a, b) => (a.finances.gains > b.finances.gains) ? 1 : -1);
      } else if (this.state.finances_sort === 'gains DESC') {
        financesTotal.sort((a, b) => (a.finances.gains > b.finances.gains) ? -1 : 1);
      } else if (this.state.finances_sort === 'salary ASC') {
        financesTotal.sort((a, b) => (a.finances.salary > b.finances.salary) ? 1 : -1);
      } else if (this.state.finances_sort === 'salary DESC') {
        financesTotal.sort((a, b) => (a.finances.salary > b.finances.salary) ? -1 : 1);
      }

      if ((this.state.finances_employee !== 'null' && this.state.finances_employee !== 'all') || this.props.individual === true) {

        if (this.props.individual === true) {
          where += ` AND info_id = ${this.props.individual_userID}`;
        } else {
          where += ` AND info_id = ${this.state.finances_employee}`;
        }

        financesTotal = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*`,
          `FROM Uren`,
          (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
          where,
          null, // HAVING rooster_netto > 0
          `ORDER BY info_voornaam, info_achternaam, uren_begindatum, uren_begin, uren_einddatum, uren_eind`,
        );

      }

    }
    
    this.setState({
      finances_finances: financesTotal,
      finances_all: { gains: Constants.round(finances_all.gains), egains: Constants.round(finances_all.egains), productivity: finances_all.productivity, salary: finances_all.salary, hours: finances_all.hours },
    });

  }

  finances_changeBonus(e) {

    this.setState({ finances_bonus: e.target.value === '1' ? true : false }, async () => {
      await this.reloadScreen();
    });
  }

  finances_changeSort(e) {

    let finances_finances = this.state.finances_finances;

    this.setState({ finances_sort: e.target.value });

    if (e.target.value === 'name ASC') {
      finances_finances.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? 1 : -1);
    } else if (e.target.value === 'name DESC') {
      finances_finances.sort((a, b) => (a.info_voornaam > b.info_voornaam) || ((a.info_voornaam === b.info_voornaam) && (a.info_achternaam > b.info_achternaam)) ? -1 : 1);
    } else if (e.target.value === 'hours ASC') {
      finances_finances.sort((a, b) => (a.finances.hours > b.finances.hours) ? 1 : -1);
    } else if (e.target.value === 'hours DESC') {
      finances_finances.sort((a, b) => (a.finances.hours > b.finances.hours) ? -1 : 1);
    } else if (e.target.value === 'gains ASC') {
      finances_finances.sort((a, b) => (a.finances.gains > b.finances.gains) ? 1 : -1);
    } else if (e.target.value === 'gains DESC') {
      finances_finances.sort((a, b) => (a.finances.gains > b.finances.gains) ? -1 : 1);
    } else if (e.target.value === 'salary ASC') {
      finances_finances.sort((a, b) => (a.finances.salary > b.finances.salary) ? 1 : -1);
    } else if (e.target.value === 'salary DESC') {
      finances_finances.sort((a, b) => (a.finances.salary > b.finances.salary) ? -1 : 1);
    }

    this.setState({ finances_finances: finances_finances });

  }

  finances_changePeriod(period) {

    if (this.state.finances_period === period) {
      return;
    }

    localStorage.setItem('report_finances_period', period.toString());
    this.setState({ finances_period: period, loading: true }, async () => {
      await this.hardReloadScreen();
    });

  }

  finances_changeEmployee(e) {

    this.setState({ finances_employee: e.target.value, loading: true }, async () => {
      await this.hardReloadScreen();
    });

  }

  finances_selectMonth(date) {

    this.setState({ finances_month: date }, async () => {
      await this.reloadScreen();
    });

  }

  finances_selectStartDate(date) {

    this.setState({ finances_start: date }, async () => {
      await this.reloadScreen();
    });

  }

  finances_selectEndDate(date) {

    this.setState({ finances_end: date }, async () => {
      await this.reloadScreen();
    });
  }

  finances_render_title() {

    let toRender = [];

    if ((this.state.finances_employee === 'null' || this.state.finances_employee === 'all') && this.props.individual !== true) {

      toRender.push(

        <div className='hr_hoursBar_title'>

          <div className='hr_hoursBar_sub1'>
            {__('misc_name')} 
          </div>

          <div className='hr_hoursBar_sub3'>
            {__('misc_hours_c')} 
          </div>

          <div className='hr_hoursBar_sub3'>
            {__('misc_achievedGains')} 
          </div>

          <div className='hr_hoursBar_sub3'>
            {__('misc_salary')}  
          </div>

        </div>

      );

    } else {

      toRender.push(

        <div className='hr_hoursBar_title'>

          {this.props.individual !== true ?
            <div className='hr_hoursBar_sub1'>
              {__('misc_name')}  
            </div>
          : null}

          <div className={this.props.individual !== true ? 'hr_hoursBar_sub2' : 'hr_hoursBar_sub1'}>
            {__('misc_shift')}  
          </div>

          <div className='hr_hoursBar_sub2'>
            {__('misc_job')}  
          </div>

          <div className='hr_hoursBar_sub3'>
            {__('misc_hours_c')} 
          </div>

          <div className='hr_hoursBar_sub3'>
            {__('misc_achievedGains')} 
          </div>

          <div className='hr_hoursBar_sub3'>
            {__('misc_salary')}  
          </div>

        </div>

      );

    }

    return toRender;

  }

  finances_render() {

    let toRender = [];
    let count = 0;

    // ALL
    if ((this.state.finances_employee === 'null' || this.state.finances_employee === 'all') && this.props.individual !== true) {

      for (const data of this.state.finances_finances) {

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span className='defaultLinkO' onClick={() => this.finances_changeEmployee({ target: { value: data.info_id } })}>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.round(data.finances.hoursPlain, true)}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{this.formatter.format(data.finances.gains)}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{this.formatter.format(data.finances.salary)}</span>
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

      toRender.push(

        <div>

          <div className='hr_hoursBar' style={{borderTop: '1px solid #EBEBEB', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{__('misc_totalHours')}:</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.round(this.state.finances_all.hours, true)}</span>
            </div>

          </div>

          <div className='hr_hoursBar' style={{backgroundColor: count === 0 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{__('misc_totalGains')}:</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{this.formatter.format(this.state.finances_all.gains)}</span>
            </div>

          </div>

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{__('misc_productivity')}:</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{this.formatter.format(this.state.finances_all.productivity)}</span>
            </div>

          </div>

          <div className='hr_hoursBar' style={{backgroundColor: count === 0 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <b>{__('misc_totalSalary')}:</b>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{this.formatter.format(this.state.finances_all.salary)}</b>
            </div>

          </div>

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <b>{__('misc_totalEnd')}:</b>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <b style={{color: parseFloat(Data.data.appOptions.opties_loonPercentage) !== 0 && Constants.calcPercentInt(this.state.finances_all.salary, this.state.finances_all.gains) > parseFloat(Data.data.appOptions.opties_loonPercentage) ? Colors.color.redFilledIn : null}}>{this.formatter.format(Constants.round(this.state.finances_all.gains - this.state.finances_all.salary))}</b>
            </div>

          </div>

        </div>

      );

    // INDIVIDUAL
    } else {

      // PLANNER
      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === false) {

        let totalHours = 0;
        let totalSalary = 0;
        let totalGains = 0;
        let totalSalaryMonth = 0;

        for (const data of this.state.finances_finances) {

          const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
          const date = Constants.stringToDate(data.rooster_datum);

          const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], Constants.stringToDate(data.rooster_datum));
          const netHours = Constants.round(((Constants.stringToDateTime(Plan.isNightShift2(data), data.rooster_eind) - Constants.stringToDateTime(data.rooster_datum, data.rooster_begin) - Constants.timeStringToMS(data.rooster_pauze)) / 1000 / 60 / 60));
          const salary = contract !== null && contract.contract_type !== '2' ? Constants.round(parseFloat(contract.contract_uurloon) * netHours * this.salaryFactor) : 0;
          const salaryMonth = contract !== null && contract.contract_type === '2' ? Constants.round(parseFloat(contract.contract_maandloon) * this.salaryFactor) : 0;

          totalHours += netHours;
          totalSalary += salary;
          totalGains += netHours * this.state.finances_all.productivity;
          totalSalaryMonth = salaryMonth;

          toRender.push(

            <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              {this.props.individual !== true ?
                <div className='hr_hoursBar_sub1'>
                  <span>{userFullName}</span>
                </div>
              : null}

              <div className={this.props.individual !== true ? 'hr_hoursBar_sub2' : 'hr_hoursBar_sub1'}>
                <span>{Constants.getShortDayName(date).toLowerCase()} {date.toLocaleDateString()} ({data.rooster_begin.substr(0, 5)} - {data.rooster_eind.substr(0, 5)})</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{data.functie_naam}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{Constants.round(netHours, true)}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{this.formatter.format(Constants.round(netHours * this.state.finances_all.productivity))}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{this.formatter.format(salary)}</span>
              </div>

            </div>

          );

          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

        toRender.push(
          <div className='hr_hoursBar' style={{borderTop: '1px solid #EBEBEB', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <b>{__('misc_total')}:</b>
            </div>

            {this.props.individual !== true ?
              <div className='hr_hoursBar_sub2'>
                <span>&nbsp;</span>
              </div>
            : null}

            <div className='hr_hoursBar_sub2'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{Constants.round(totalHours, true)}</b>
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{this.formatter.format(Constants.round(totalGains))}</b>
            </div>

            <div className='hr_hoursBar_sub3'>
              {totalSalaryMonth !== 0 && this.state.finances_period === 0 ?
                <b>{this.formatter.format(Constants.round(totalSalaryMonth))}</b>
              :
                <b>{this.formatter.format(Constants.round(totalSalary))}</b>
              }
            </div>

          </div>
        );

      // DAADWERKELIJK
      } else {

        let totalHours = 0;
        let totalSalary = 0;
        let totalGains = 0;
        let totalSalaryMonth = 0;

        for (const data of this.state.finances_finances) {

          const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
          const date = Constants.stringToDate(data.uren_begindatum);

          const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], Constants.stringToDate(data.uren_begindatum));
          const netHours = (Constants.stringToDateTime(data.uren_einddatum, data.uren_eind) - Constants.stringToDateTime(data.uren_begindatum, data.uren_begin) - Constants.timeStringToMS(data.uren_pauze)) / 1000 / 60 / 60;
          const salary = contract !== null && contract.contract_type !== '2' ? Constants.round(parseFloat(contract.contract_uurloon) * netHours * this.salaryFactor) : 0;
          const salaryMonth = contract !== null && contract.contract_type === '2' ? Constants.round(parseFloat(contract.contract_maandloon) * this.salaryFactor) : 0;

          totalHours += netHours;
          totalSalary += salary;
          totalGains += netHours * this.state.finances_all.productivity;
          totalSalaryMonth = salaryMonth;

          toRender.push(

            <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              {this.props.individual !== true ?
                <div className='hr_hoursBar_sub1'>
                  <span>{userFullName}</span>
                </div>
              : null}

              <div className={this.props.individual !== true ? 'hr_hoursBar_sub2' : 'hr_hoursBar_sub1'}>
                <span>{Constants.getShortDayName(date).toLowerCase()} {date.toLocaleDateString()} ({data.uren_begin.substr(0, 5)} - {data.uren_eind.substr(0, 5)})</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{data.functie_naam}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{Constants.round(netHours, true)}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{this.formatter.format(Constants.round(netHours * this.state.finances_all.productivity))}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{this.formatter.format(salary)}</span>
              </div>

            </div>

          );

          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

        toRender.push(
          <div className='hr_hoursBar' style={{borderTop: '1px solid #EBEBEB', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <b>{__('misc_total')}:</b>
            </div>

            {this.props.individual !== true ?
              <div className='hr_hoursBar_sub2'>
                <span>&nbsp;</span>
              </div>
            : null}

            <div className='hr_hoursBar_sub2'>
              <span>&nbsp;</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{Constants.round(totalHours, true)}</b>
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{this.formatter.format(Constants.round(totalGains))}</b>
            </div>

            <div className='hr_hoursBar_sub3'>
              {totalSalaryMonth !== 0 && this.state.finances_period === 0 ?
                <b>{this.formatter.format(Constants.round(totalSalaryMonth))}</b>
              :
                <b>{this.formatter.format(Constants.round(totalSalary))}</b>
              }
            </div>

          </div>
        );

      }

    }

    return toRender;

  }

  async gains_getGainsData() {

    let gains = [];
    // let gainsExpect = [];
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${this.state.gains_date.getFullYear()} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`);
      // gainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE YEAR(omzetv_datum) = ${this.state.gains_date.getFullYear()} AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetv_datum`);
    } else {
      gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${this.state.gains_date.getFullYear()}`, null, `ORDER BY omzetd_datum`);
      // gainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE YEAR(omzetv_datum) = ${this.state.gains_date.getFullYear()}`, null, `ORDER BY omzetv_datum`);
    }

    const gainsObj = Constants.convertToObject(gains, 'omzetd_datum');
    // const gainsExpectObj = Constants.convertToObject(gainsExpect, 'omzetv_datum');

    this.setState({ gains_gains: gainsObj });

  }

  async gains_saveAlert() {

    this.setState({
        alertTitle: __('alert_save_title'),
        alertBody: (
            <div>
              {__('alert_save')}
            </div>
        ),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_save')],
        alertButtonAction: this.gains_saveGains.bind(this),
        showAlert: true,
    });
  }

  async gains_saveGains() {

    this.setState({ loading: true });

    const highestID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);

    let promises = [];

    for (const key in this.state.gains_gains) {

      const gain = this.state.gains_gains[key];

      if (typeof gain.omzetd_id === 'undefined') { // is newly added gains

        if (isNaN(gain.omzetd_hoeveelheid) === true || Constants.isEmpty(gain.omzetd_hoeveelheid) === true) {
          continue;
        }

        promises.push(APIADD.Request(`INSERT INTO OmzetDagelijks`, `VALUES (NULL, '${gain.omzetd_datum}', ${gain.omzetd_hoeveelheid})`));

      } else { // is edited gains

        if (isNaN(gain.omzetd_hoeveelheid) === true || Constants.isEmpty(gain.omzetd_hoeveelheid) === true) {
          promises.push(APIDELETE.Request(`DELETE FROM OmzetDagelijks`, `WHERE omzetd_id = ${gain.omzetd_id}`));
          continue;
        }

        promises.push(APIEDIT.Request(`UPDATE OmzetDagelijks`, `SET omzetd_hoeveelheid = ${gain.omzetd_hoeveelheid}`, `WHERE omzetd_id = ${gain.omzetd_id}`));

      }

    }

    await Promise.all(promises);

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      const newHighestID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);

      if (parseInt(newHighestID[0].omzetd_id) > parseInt(highestID[0].omzetd_id)) {

        let promises2 = [];
        for (let i = parseInt(highestID[0].omzetd_id); i < parseInt(newHighestID[0].omzetd_id); i++) {
          promises2.push(APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${(i + 1)}, ${Data.data.chosenTeamObj.team_id})`));
        }

        await Promise.all(promises2);

      }

    }

    await this.reloadScreen();

    this.setState({loading: false, changes: false});

  }

  gains_selectDate(date) {

    this.setState({ gains_date: date }, async () => {
      await this.reloadScreen();
    });

  }

  gains_render() {

    let toReturn = [];

    let weekDivs = [];
    const weeks = Constants.getWeeksStartAndEndInMonth(this.state.gains_date.getMonth(), this.state.gains_date.getFullYear());

    for (const week of weeks) {

      let dates = [];

      for (let i = week.start; i <= week.end; i++) {

        const gainsID = `${this.state.gains_date.getFullYear()}-${(this.state.gains_date.getMonth() + 1) < 10 ? '0' + (this.state.gains_date.getMonth() + 1) : (this.state.gains_date.getMonth() + 1)}-${i < 10 ? '0' + i : i}`;
        const gainsDate = Constants.stringToDate(gainsID);

        const dateNow = Constants.dateToString(new Date());

        dates.push(

          <div key={i} className='finances_gainsRow'>

            <div className='finances_gainsRow_sub1'>

              <span style={{fontSize: '0.7vw', color: dateNow === Constants.dateToString(gainsDate) ? '#47B7EB' : Colors.color.pastBackgroundColor}}>{Constants.getShortDayName(gainsDate).toLowerCase()}</span>

            </div>

            <div className='finances_gainsRow_sub2'>

              <span style={{fontSize: '0.8vw', color: dateNow === Constants.dateToString(gainsDate) ? '#47B7EB' : null}}>{i} {Constants.getShortMonthNameM(this.state.gains_date.getMonth())}</span>

              <input 
                className={'finances_inputBoxTime'}
                min={0}
                max={999999999}
                value={typeof this.state.gains_gains[gainsID] === 'undefined' ? '' : this.state.gains_gains[gainsID].omzetd_hoeveelheid}
                onKeyDown={(event) => this.handleKey(event)}
                onChange={(event) => this.onChangeNumberData('gains', null, gainsID, 0, 999999999, event)}
                placeholder={__('misc_actualGains')}
                type={'number'}
                step={0.01}
              />

            </div>

          </div>

        );

      }

      weekDivs.push(

        <div key={week.week} className='finances_week'>
          
          <span style={{fontWeight: 'bold', fontSize: '1vw'}}>{__('misc_week_c')} {week.week}</span>

          <div style={{marginTop: '2%'}}>
            {dates}
          </div>

        </div>

      );

    }

    const rows = Constants.chunkArray(weekDivs, 2);

    let rowsCounts = 0;
    for (const row of rows) {

      toReturn.push(

        <div key={'weekcon' + rowsCounts} className='finances_weekContainer'>
          
          {row}

        </div>

      );

      rowsCounts++;

    }

    return toReturn;

  }

  async egains_getGainsData() {

    // let gains = [];
    let gainsExpect = [];
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      // gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${this.state.gains_date.getFullYear()} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`);
      gainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE YEAR(omzetv_datum) = ${this.state.egains_date.getFullYear()} AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetv_datum`);
    } else {
      // gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${this.state.gains_date.getFullYear()}`, null, `ORDER BY omzetd_datum`);
      gainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE YEAR(omzetv_datum) = ${this.state.egains_date.getFullYear()}`, null, `ORDER BY omzetv_datum`);
    }

    // const gainsObj = Constants.convertToObject(gains, 'omzetd_datum');
    const gainsExpectObj = Constants.convertToObject(gainsExpect, 'omzetv_datum');

    this.setState({ egains_gains: gainsExpectObj });

  }

  async egains_saveAlert() {

    this.setState({
        alertTitle: __('alert_save_title'),
        alertBody: (
            <div>
                {__('alert_save')}
            </div>
        ),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_save')],
        alertButtonAction: this.egains_saveGains.bind(this),
        showAlert: true,
    });
  }

  async egains_saveGains() {

    this.setState({ loading: true });

    const highestID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);

    let promises = [];

    for (const key in this.state.egains_gains) {

      const gain = this.state.egains_gains[key];

      if (typeof gain.omzetv_id === 'undefined') { // is newly added gains

        if (isNaN(gain.omzetv_hoeveelheid) === true || Constants.isEmpty(gain.omzetv_hoeveelheid) === true) {
          continue;
        }

        promises.push(APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${gain.omzetv_datum}', ${gain.omzetv_hoeveelheid})`));

      } else { // is edited gains

        if (isNaN(gain.omzetv_hoeveelheid) === true || Constants.isEmpty(gain.omzetv_hoeveelheid) === true) {
          promises.push(APIDELETE.Request(`DELETE FROM OmzetVerwachting`, `WHERE omzetv_id = ${gain.omzetv_id}`));
          continue;
        }

        promises.push(APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = ${gain.omzetv_hoeveelheid}`, `WHERE omzetv_id = ${gain.omzetv_id}`));

      }

    }

    await Promise.all(promises);

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      const newHighestID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);

      if (parseInt(newHighestID[0].omzetv_id) > parseInt(highestID[0].omzetv_id)) {

        let promises2 = [];
        for (let i = parseInt(highestID[0].omzetv_id); i < parseInt(newHighestID[0].omzetv_id); i++) {
          promises2.push(APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${(i + 1)}, ${Data.data.chosenTeamObj.team_id})`));
        }

        await Promise.all(promises2);

      }

    }

    await this.reloadScreen();

    this.setState({loading: false, changes: false});

  }

  egains_selectDate(date) {

    this.setState({ egains_date: date }, async () => {
      await this.reloadScreen();
    });
  }

  egains_render() {

    let toReturn = [];

    let weekDivs = [];
    const weeks = Constants.getWeeksStartAndEndInMonth(this.state.egains_date.getMonth(), this.state.egains_date.getFullYear());

    for (const week of weeks) {

      let dates = [];

      for (let i = week.start; i <= week.end; i++) {

        const gainsID = `${this.state.egains_date.getFullYear()}-${(this.state.egains_date.getMonth() + 1) < 10 ? '0' + (this.state.egains_date.getMonth() + 1) : (this.state.egains_date.getMonth() + 1)}-${i < 10 ? '0' + i : i}`;
        const gainsDate = Constants.stringToDate(gainsID);

        const dateNow = Constants.dateToString(new Date());

        dates.push(

          <div key={i} className='finances_gainsRow'>

            <div className='finances_gainsRow_sub1'>

              <span style={{fontSize: '0.7vw', color: dateNow === Constants.dateToString(gainsDate) ? '#47B7EB' : Colors.color.pastBackgroundColor}}>{Constants.getShortDayName(gainsDate).toLowerCase()}</span>

            </div>

            <div className='finances_gainsRow_sub2'>

              <span style={{fontSize: '0.8vw', color: dateNow === Constants.dateToString(gainsDate) ? '#47B7EB' : null}}>{i} {Constants.getShortMonthNameM(this.state.egains_date.getMonth())}</span>

              <input 
                className={'finances_inputBoxTime'}
                min={0}
                max={999999999}
                value={typeof this.state.egains_gains[gainsID] === 'undefined' ? '' : this.state.egains_gains[gainsID].omzetv_hoeveelheid}
                onKeyDown={(event) => this.handleKey(event)}
                onChange={(event) => this.onChangeNumberData('egains', null, gainsID, 0, 999999999, event)}
                placeholder={__('misc_expectedGains')}
                type={'number'}
                step={0.01}
              />

            </div>

          </div>

        );

      }

      weekDivs.push(

        <div key={week.week} className='finances_week'>
          
          <span style={{fontWeight: 'bold', fontSize: '1vw'}}>{__('misc_week_c')} {week.week}</span>

          <div style={{marginTop: '2%'}}>
            {dates}
          </div>

        </div>

      );

    }

    const rows = Constants.chunkArray(weekDivs, 2);

    let rowsCounts = 0;
    for (const row of rows) {

      toReturn.push(

        <div key={'weekcon' + rowsCounts} className='finances_weekContainer'>
          
          {row}

        </div>

      );

      rowsCounts++;

    }

    return toReturn;

  }

  async onChangeNumberData(change, data, key, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (change === 'gains') {

      let gains = this.state.gains_gains;

      if (typeof gains[key] === 'undefined') {
        gains[key] = {
          omzetd_datum: key,
          omzetd_hoeveelheid: '',
        };
      }

      if (nr !== '') {
        gains[key].omzetd_hoeveelheid = nr;
      } else {
        gains[key].omzetd_hoeveelheid = '';
      }

      this.setState({ gains_gains: gains, changes: true });

    } else if (change === 'egains') {

      let gains = this.state.egains_gains;

      if (typeof gains[key] === 'undefined') {
        gains[key] = {
          omzetv_datum: key,
          omzetv_hoeveelheid: '',
        };
      }

      if (nr !== '') {
        gains[key].omzetv_hoeveelheid = nr;
      } else {
        gains[key].omzetv_hoeveelheid = '';
      }

      this.setState({ egains_gains: gains, changes: true });

    }

  }

  handleKey(e) {

    if(e.keyCode === 13){

      e.preventDefault(); // Ensure it is only this code that runs

      if (this.state.menu === 'gains') {
        this.gains_saveAlert();
      } else if (this.state.menu === 'egains') {
        this.egains_saveAlert();
      }

      return false;

    }

    // if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    // } else {

    //   e.preventDefault();
    //   //e.target.value = e.target.value.toString().slice(0, -1);

    // }

  }

  selectMenu(menu) {

    if (this.state.menu === menu) {
      return;
    }

    if (this.state.changes === true) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ changes: false });
      }
    }

    this.setState({ menu: menu }, async () => {
      if (menu !== 'holidays') {
        await this.reloadScreen();
      }
    });
  }

  getEmployeeSelects() {

    let toRender = [];

    for (const acc of this.state.accounts) {
      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;
      toRender.push(<option value={acc.info_id} selected={this.state.trades_employee === acc.info_id || this.state.abs_employee === acc.info_id || this.state.finances_employee === acc.info_id || this.state.minmax_employee === acc.info_id || this.state.flex_employee === acc.info_id}>{userFullName}</option>)
    }

    return toRender;

  }

  openNoteModal(data, mode) {

    this.setState({
      modalData: data,
      modalMode: mode,
      general_show_noteModal: true,
    });

  }

  closeModal() {

    this.setState({
      general_show_noteModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  closeAlert() {

    this.setState({ showAlert: false });
    
  }

  render() {

    if (this.props.individual !== true) {

      return (

        <div className='reports'>

        {this.state.reloading === true ?       
          <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
        : null}

        <Navbar ref={this.navBar} history={this.props.history} active={'reports'} fixed loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'reports'} parent={this} />

        <Prompt
          when={this.state.changes}
          message='You have unsaved changes. Are you sure you want to leave?'
        />

        {this.state.showAlert === true ?
          <BetterAlert 
            _closeAlert={this.closeAlert.bind(this)}
            _title={this.state.alertTitle}
            _body={this.state.alertBody}
            _buttons={this.state.alertButtons}
            _buttonText={this.state.alertButtonText}
            _buttonAction={this.state.alertButtonAction}
          />
        : null}

        {this.state.general_show_noteModal === true ?
          <NoteModal 
            _closeModal={this.closeModal.bind(this)}
            _modalMode={this.state.modalMode}
            _modalData={this.state.modalData}
            _reloadScreen={this.reloadScreen.bind(this)}
            _toggleNote={this.general_toggle_note.bind(this)}
          />
        : null}

        <div className='employees_main2'>
          {/* stickyTopMenuPanel */}
          <div className='employees_menu2'>

            <div className='employees_menuTitle'>
                <div>{__('misc_overviews')}</div>
            </div>

            <div className='employees_menuSubTitle'>
              <div>{__('misc_general')}</div>
            </div>
            <div className={`employees_menuButton ${this.state.menu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('general')}>
              <div>{__('misc_generalOverview')}</div>
            </div>
            {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? 
              <div className={`employees_menuButton ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('availability')}>
                <div>{__('misc_availabilityReport')}</div>
              </div>
            : null}
            <div className={`employees_menuButton ${this.state.menu === 'trades' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('trades')}>
              <div>{__('misc_tradeReport')}</div>
            </div>

            {Constants.isTrue(Data.data.appOptions.opties_financien) ?
              <div>
                <div className='employees_menuSubTitle'>
                  <div>{__('misc_finances')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'gains' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('gains')}>
                  <div>{__('misc_actualGains')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'egains' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('egains')}>
                  <div>{__('misc_expectedGains')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'finances' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('finances')}>
                  <div>{__('misc_financesReport')}</div>
                </div>
              </div>
            : null}

            <div className='employees_menuSubTitle'>
              <div>{__('misc_absenceLeave')}</div>
            </div>
            <div className={`employees_menuButton ${this.state.menu === 'absences' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('absences')}>
              <div>{__('misc_absenceReport')}</div>
            </div>
            <div className={`employees_menuButton ${this.state.menu === 'holidays' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('holidays')}>
              <div>{__('misc_leaveReport')}</div>
            </div>

            {/* {Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) ? */}
              <div>
                <div className='employees_menuSubTitle'>
                  <div>{__('misc_timeRegistration')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'times' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('times')}>
                  <div>{__('misc_timeReport')}</div>
                </div>
              </div>
            {/* : null} */}

            {Constants.isTrue(Data.data.appOptions.opties_overuren) ?
              <div>
                <div className='employees_menuSubTitle'>
                  <div>{__('misc_plusMinus')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'overhours' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('overhours')}>
                  <div>{__('misc_plusMinusReport')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'overhours_corrections' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('overhours_corrections')}>
                  <div>{__('misc_plusHoursPaid')}</div>
                </div>
              </div>
            : null}

            {/* {Constants.isTrue(Data.data.appOptions.opties_overuren) ?
              <div className={`employees_menuButton ${this.state.menu === 'overhours' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('overhours')}>
                <div>Plus- {`&`} minuren rapportage</div>
              </div>
            : null}
            {Constants.isTrue(Data.data.appOptions.opties_overuren) ?
              <div className={`employees_menuButton ${this.state.menu === 'overhours_corrections' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('overhours_corrections')}>
                <div>Plusuren uitbetaald</div>
              </div>
            : null} */}

            {Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === true ?
              <div>
                <div className='employees_menuSubTitle'>
                  <div>{__('misc_contracts')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'flex' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('flex')}>
                  <div>{__('misc_flexReport')}</div>
                </div>
                <div className={`employees_menuButton ${this.state.menu === 'minmax' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('minmax')}>
                  <div>{__('misc_minMaxReport')}</div>
                </div>
              </div>
            : null}

          </div>

          <div className='employees_content2' style={{width: 'calc(87% - 0px)', fontSize: '0.8vw'}}>

            {this.state.loading === true ?
              
                        <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

              <div className='employees_container'>

                {this.state.menu === 'general' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_generalOverview')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      {this.state.general_show_holidays === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: 0}} onClick={() => this.general_toggle_holidays()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_leave')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: 0}} onClick={() => this.general_toggle_holidays()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_leave')}</span>
                        </div>
                      }

                      {this.state.general_show_holidayRequests === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_holidayRequests()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_leaveRequests')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_holidayRequests()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_leaveRequests')}</span>
                        </div>
                      }

                      {this.state.general_show_availables === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_availables()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_availability')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_availables()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_availability')}</span>
                        </div>
                      }

                      {this.state.general_show_unavailables === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_unavailables()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_unavailables()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      }

                      {this.general_render_note_toggleButtons()}

                    </div>

                    <span className='defaultLinkO' onClick={() => this.openNoteModal(null, 0)}>{__('reports_add_note')}</span>

                    <div className='reports_simple_toggleRow' style={{marginTop: '1vw'}}>

                      <span>{__('reports_show_weekends')}</span>

                      <label className={"switch"}>
                        <input 
                          type="checkbox" 
                          checked={this.state.general_show_weekends} 
                          onClick={() => this.general_toggle_weekends()}
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>

                    </div>

                    <div className='reports_simple_toggleRow' style={{marginTop: 4}}>

                      <span>{__('reports_show_holidays')}</span>

                      <label className={"switch"}>
                        <input 
                          type="checkbox" 
                          checked={this.state.general_show_freeDays} 
                          onClick={() => this.general_toggle_freeDays()}
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>

                    </div>

                    <div className='reports_simple_toggleRow' style={{marginTop: 4}}>

                      <span>{__('reports_pinNotes')}</span>

                      <label className={"switch"}>
                        <input 
                          type="checkbox" 
                          checked={this.state.general_sticky} 
                          onClick={() => this.general_toggle_sticky()}
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.general_start}
                          onChange={(date) => this.general_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.general_end}
                          onChange={(date) => this.general_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                          maxDate={new Date(this.state.general_start.getFullYear(), this.state.general_start.getMonth() + 4, this.state.general_start.getDate())}
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <div style={{width: '10%', marginLeft: '1.2vw'}}>
                        <select
                            name={__('select_job')}
                            className={'reports_dropdown'}
                            onChange={(event) => this.changeFunction(event)}
                        >
                            <option value={'null'} disabled>{__('misc_job')}</option>
                            <option value={'null'} selected={this.state.general_function === 'null'}>{__('select_job_all')}</option>
                            {this.getFunctionOptions()}
                            <option value={'null'} disabled>&nbsp;</option>
                            <option value={'null'} disabled>{__('misc_section')}</option>
                            {this.getSectionOptions()}
                        </select>
                      </div>

                    </div>

                    <div className='reports_general_legend'>

                      {this.general_renderLegend()}

                    </div>

                    {this.general_renderAll()}

                  </div>

                :
                this.state.menu === 'availability' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_availabilityReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      {Data.data.appOptions.availability !== 'UNAVAILABILITY' ?
                        this.state.avail_show_availables === true ?
                          <div className='reports_toggleButton_active' style={{marginLeft: 0}} onClick={() => this.avail_toggle_availables()}>
                            <i className='fas fa-check-circle' />
                            <span>{__('misc_availability')}</span>
                          </div>
                        :
                          <div className='reports_toggleButton' style={{marginLeft: 0}} onClick={() => this.avail_toggle_availables()}>
                            <i className='fal fa-circle' />
                            <span>{__('misc_availability')}</span>
                          </div>
                      : null}

                      {this.state.avail_show_unavailables === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: Data.data.appOptions.availability !== 'UNAVAILABILITY' ? '0.6vw' : 0}} onClick={() => this.avail_toggle_unavailables()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: Data.data.appOptions.availability !== 'UNAVAILABILITY' ? '0.6vw' : 0}} onClick={() => this.avail_toggle_unavailables()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      }

                      {Data.data.appOptions.availability !== 'SCHOOL' ?
                        this.state.avail_show_notfilled === true ?
                          <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.avail_toggle_notfilled()}>
                            <i className='fas fa-check-circle' />
                            <span>{__('misc_notFilled')}</span>
                          </div>
                        :
                          <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.avail_toggle_notfilled()}>
                            <i className='fal fa-circle' />
                            <span>{__('misc_notFilled')}</span>
                          </div>
                      : null}

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.avail_start}
                          onChange={(date) => this.avail_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.avail_end}
                          onChange={(date) => this.avail_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <div style={{width: '16%', marginLeft: '1.2vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'reports_dropdown'}
                            onChange={(event) => this.avail_changeSort(event)}
                        >
                            <option value={'null'} disabled>{__('select_sort')}</option>
                            <option value={'name ASC'} selected={this.state.avail_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                            <option value={'name DESC'} selected={this.state.avail_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                            <option value={'avail ASC'} selected={this.state.avail_sort === 'avail ASC'}>{__('select_sort_availability_asc')}</option>
                            <option value={'avail DESC'} selected={this.state.avail_sort === 'avail DESC'}>{__('select_sort_availability_desc')}</option>
                            <option value={'unavail ASC'} selected={this.state.avail_sort === 'unavail ASC'}>{__('select_sort_unavailability_asc')}</option>
                            <option value={'unavail DESC'} selected={this.state.avail_sort === 'unavail DESC'}>{__('select_sort_unavailability_desc')}</option>
                        </select>
                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}
                      </div>

                      {Data.data.appOptions.availability !== 'UNAVAILABILITY' && this.state.avail_show_availables === true ?
                        <div className='hr_hoursBar_sub3'>
                          {__('misc_available')}
                        </div>
                      : null}

                      {this.state.avail_show_unavailables === true ?
                        <div className='hr_hoursBar_sub3'>
                          {__('misc_unavailable')}
                        </div>
                      : null}

                      {this.state.avail_show_notfilled === true && Data.data.appOptions.availability !== 'SCHOOL' ?    
                        <div className='hr_hoursBar_sub3'>
                          {__('misc_notFilled')}
                        </div>
                      : null}

                    </div>

                    {this.avail_render()}

                  </div>

                :
                this.state.menu === 'gains' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_actualGains')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <span className='planner_publishMenu_buttonOpen' style={{marginLeft: 0}} onClick={() => this.gains_saveAlert()}>{__('button_save')}</span>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div className='planner_date_container'>

                        <ReactDatePicker 
                          selected={this.state.gains_date}
                          onSelect={date => this.gains_selectDate(date)}
                          className={'hr_inputBoxTime_big'}
                          locale={nl}
                          dropdownMode="select"
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                        />

                      </div>

                    </div>

                    <div>
                      {this.gains_render()}
                    </div>

                  </div>

                :
                this.state.menu === 'egains' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_expectedGains')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <span className='planner_publishMenu_buttonOpen' style={{marginLeft: 0}} onClick={() => this.egains_saveAlert()}>{__('button_save')}</span>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div className='planner_date_container'>

                        <ReactDatePicker 
                          selected={this.state.egains_date}
                          onSelect={date => this.egains_selectDate(date)}
                          className={'hr_inputBoxTime_big'}
                          locale={nl}
                          dropdownMode="select"
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                        />

                      </div>

                    </div>

                    <div>
                      {this.egains_render()}
                    </div>

                  </div>

                :
                this.state.menu === 'trades' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_tradeReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_employee')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.trades_changeEmployee(event)}
                        >
                          <option value={'null'} disabled>{__('misc_employee')}</option>
                          <option value={'all'} selected={this.state.trades_employee === 'all'}>{__('select_employee_all')}</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                          name={__('select_sort')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.trades_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option 
                            value={'ORDER BY s1.rooster_datum ASC, s1.rooster_begin ASC, s2.rooster_datum ASC, s2.rooster_begin ASC'} 
                            selected={this.state.trades_sort === 'ORDER BY s1.rooster_datum ASC, s1.rooster_begin ASC, s2.rooster_datum ASC, s2.rooster_begin ASC'}
                          >{__('select_sort_dateShift1_asc')}</option>
                          <option 
                            value={'ORDER BY s1.rooster_datum DESC, s1.rooster_begin DESC, s2.rooster_datum DESC, s2.rooster_begin DESC'} 
                            selected={this.state.trades_sort === 'ORDER BY s1.rooster_datum DESC, s1.rooster_begin DESC, s2.rooster_datum DESC, s2.rooster_begin DESC'}
                          >{__('select_sort_dateShift1_desc')}</option>
                          <option 
                            value={'ORDER BY s2.rooster_datum ASC, s2.rooster_begin ASC, s1.rooster_datum ASC, s1.rooster_begin ASC'} 
                            selected={this.state.trades_sort === 'ORDER BY s2.rooster_datum ASC, s2.rooster_begin ASC, s1.rooster_datum ASC, s1.rooster_begin ASC'}
                          >{__('select_sort_dateShift2_asc')}</option>
                          <option 
                            value={'ORDER BY s2.rooster_datum DESC, s2.rooster_begin DESC, s1.rooster_datum DESC, s1.rooster_begin DESC'} 
                            selected={this.state.trades_sort === 'ORDER BY s2.rooster_datum DESC, s2.rooster_begin DESC, s1.rooster_datum DESC, s1.rooster_begin DESC'}
                          >{__('select_sort_dateShift2_desc')}</option>
                        </select>
                      </div>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.trades_start}
                          onChange={(date) => this.trades_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.trades_end}
                          onChange={(date) => this.trades_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_name')} 
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_shift')} 
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_name')} 
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_shift')} 
                      </div>

                    </div>

                    {this.trades_render()}

                  </div>

                :
                this.state.menu === 'absences' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_absenceReport')} </div>

                    <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'abs' })}>{__('time_manageAbsenceTypes')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_employee')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.abs_changeEmployee(event)}
                        >
                          <option value={'null'} disabled>{__('misc_employee')}</option>
                          <option value={'all'} selected={this.state.abs_employee === 'all'}>{__('select_employee_all')}</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.abs_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'name ASC'} selected={this.state.abs_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.abs_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          {this.abs_getSortTypes()}
                        </select>
                      </div>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.abs_start}
                          onChange={(date) => this.abs_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.abs_end}
                          onChange={(date) => this.abs_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')} 
                      </div>

                      {this.abs_render_title()}

                    </div>

                    {this.abs_render()}

                  </div>

                :
                this.state.menu === 'minmax' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_minMaxReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_employee')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.minmax_changeEmployee(event)}
                        >
                          <option value={'null'} disabled>{__('misc_employee')}</option>
                          <option value={'all'} selected={this.state.minmax_employee === 'all'}>{__('select_employee_all')}</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.minmax_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'name ASC'} selected={this.state.minmax_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.minmax_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          <option value={'hours ASC'} selected={this.state.minmax_sort === 'hours ASC'}>{__('select_sort_hours_asc')}</option>
                          <option value={'hours DESC'} selected={this.state.minmax_sort === 'hours DESC'}>{__('select_sort_hours_desc')}</option>
                        </select>
                      </div>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.minmax_start}
                          onChange={(date) => this.minmax_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.minmax_end}
                          onChange={(date) => this.minmax_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')} 
                      </div>

                      {this.minmax_render_title()}

                    </div>

                    {this.minmax_render()}

                  </div>

                :
                this.state.menu === 'flex' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_flexReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_employee')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.flex_changeEmployee(event)}
                        >
                          <option value={'null'} disabled>{__('misc_employee')}</option>
                          <option value={'all'} selected={this.state.flex_employee === 'all'}>{__('select_employee_all')}</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                      {((this.state.flex_employee === 'null' || this.state.flex_employee === 'all') && this.props.individual !== true) ?
                        <div style={{width: '16%', marginLeft: '0.6vw'}}>
                          <select
                              name={__('select_sort')}
                              className={'hr_dropdown'}
                              onChange={(event) => this.flex_changeSort(event)}
                          >
                            <option value={'null'} disabled>{__('select_sort')}</option>
                            <option value={'name ASC'} selected={this.state.flex_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                            <option value={'name DESC'} selected={this.state.flex_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                            <option value={'hours ASC'} selected={this.state.flex_sort === 'hours ASC'}>{__('select_sort_hours_asc')}</option>
                            <option value={'hours DESC'} selected={this.state.flex_sort === 'hours DESC'}>{__('select_sort_hours_desc')}</option>
                          </select>
                        </div>
                      : null}

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.flex_start}
                          onChange={(date) => this.flex_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.flex_end}
                          onChange={(date) => this.flex_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')} 
                      </div>

                      {this.flex_render_title()}

                    </div>

                    {this.flex_render()}

                  </div>

                :
                this.state.menu === 'overhours' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_plusMinusReport')}</div>

                    {/* <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push('/employees')}>Beheer plus- {`&`} minuren</div> */}

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '8%', marginLeft: 0}}>
                        <select
                          name={__('select_year')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.overhours_changeYear(event)}
                        >
                          <option value={'null'} disabled>{__('misc_year_c')}</option>
                          {this.overhours_getYearSelects()}
                        </select>
                      </div>

                      {/* <div style={{width: '10%', marginLeft: '0.6vw'}}>
                        <select
                          name="Selecteer een overzicht"
                          className={'hr_dropdown'}
                          onChange={(event) => this.setState({ overhours_type: parseInt(event.target.value) })}
                        >
                          <option value={'null'} disabled>Overzicht</option>
                          <option value={'0'} selected={this.state.overhours_type === 0}>Totaal</option>
                          <option value={'1'} selected={this.state.overhours_type === 1}>Per week</option>
                        </select>
                      </div> */}

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.overhours_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'name ASC'} selected={this.state.overhours_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.overhours_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          <option value={'hours ASC'} selected={this.state.overhours_sort === 'hours ASC'}>{__('select_sort_hours_asc')}</option>
                          <option value={'hours DESC'} selected={this.state.overhours_sort === 'hours DESC'}>{__('select_sort_hours_desc')}</option>
                        </select>
                      </div>

                    </div>

                    {this.state.overhours_type === 0 ?

                      <div className='hr_hoursBar_title'>

                        <div className='hr_hoursBar_sub1'>
                          {__('misc_name')} 
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          {__('misc_balance')} {(parseInt(this.state.overhours_year) - 1)} 
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          {__('misc_balance')} {(parseInt(this.state.overhours_year))} 
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          {__('misc_currentBalance')}
                        </div>

                        <div className='hr_hoursBar_sub4'>
                          &nbsp;
                        </div>

                      </div>

                    : 
                    
                      <div className='hr_hoursBar_title'>

                        <div className='hr_hoursBar_sub1'>
                          {__('misc_name')} 
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          {__('misc_week_c')}
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          {__('misc_hours_c')}
                        </div>

                      </div>

                    }

                    {this.overhours_render()}

                  </div>

                :
                this.state.menu === 'overhours_corrections' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('report_plusMinusCorrections')}</div>

                    {/* <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push('/employees')}>Beheer plus- {`&`} minuren correcties</div> */}

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '8%', marginLeft: 0}}>
                        <select
                          name={__('select_year')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.overhoursC_changeYear(event)}
                        >
                          <option value={'null'} disabled>{__('misc_year_c')}</option>
                          {this.overhoursC_getYearSelects()}
                        </select>
                      </div>

                      <div style={{width: '10%', marginLeft: '0.6vw'}}>
                        <select
                          name={__('select_kind')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.setState({ overhoursC_type: parseInt(event.target.value) })}
                        >
                          <option value={'null'} disabled>{__('misc_correction')}</option>
                          <option value={'0'} selected={this.state.overhoursC_type === 0}>{__('misc_all')}</option>
                          <option value={'1'} selected={this.state.overhoursC_type === 1}>{__('select_kind_paid')}</option>
                          <option value={'2'} selected={this.state.overhoursC_type === 2}>{__('select_kind_correction')}</option>
                        </select>
                      </div>

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.overhoursC_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'date ASC'} selected={this.state.overhoursC_sort === 'date ASC'}>{__('select_sort_date_asc')}</option>
                          <option value={'date DESC'} selected={this.state.overhoursC_sort === 'date DESC'}>{__('select_sort_date_desc')}</option>
                          <option value={'name ASC'} selected={this.state.overhoursC_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.overhoursC_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          <option value={'hours ASC'} selected={this.state.overhoursC_sort === 'hours ASC'}>{__('select_sort_hours_asc')}</option>
                          <option value={'hours DESC'} selected={this.state.overhoursC_sort === 'hours DESC'}>{__('select_sort_hours_desc')}</option>
                        </select>
                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')} 
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_date')} 
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_by')} 
                      </div>

                      <div className='hr_hoursBar_sub4'>
                        {__('misc_comment')} 
                      </div>

                      <div className='hr_hoursBar_sub4'>
                        {__('misc_payout')} 
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        {__('misc_correction')} 
                      </div>

                      <div className='hr_hoursBar_sub4'>
                        &nbsp;
                      </div>

                    </div>

                    {this.overhoursC_render()}

                  </div>

                :
                this.state.menu === 'finances' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_financesReport')} </div>

                    {/* <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'exclusions' })}>Beheer uitsluitingen</div> */}

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div className="reports_toggleButton_pair">

                        <div className={`reports_toggleButton_pair_sub1${this.state.finances_period === 1 ? '_active' : ''}`} onClick={() => this.finances_changePeriod(1)}>
                          <span>{__('misc_periodic')}</span>
                        </div>

                        <div className={`reports_toggleButton_pair_sub2${this.state.finances_period === 0 ? '_active' : ''}`} onClick={() => this.finances_changePeriod(0)}>
                          <span>{__('misc_monthly')}</span>
                        </div>

                      </div>
                    
                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_employee')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.finances_changeEmployee(event)}
                        >
                          <option value={'null'} disabled>{__('misc_employee')}</option>
                          <option value={'all'} selected={this.state.finances_employee === 'all'}>{__('select_employee_all')}</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.finances_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'name ASC'} selected={this.state.finances_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.finances_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          <option value={'hours ASC'} selected={this.state.finances_sort === 'hours ASC'}>{__('select_sort_hours_asc')}</option>
                          <option value={'hours DESC'} selected={this.state.finances_sort === 'hours DESC'}>{__('select_sort_hours_desc')}</option>
                          <option value={'gains ASC'} selected={this.state.finances_sort === 'gains ASC'}>{__('select_sort_gains_asc')}</option>
                          <option value={'gains DESC'} selected={this.state.finances_sort === 'gains DESC'}>{__('select_sort_gains_desc')}</option>
                          <option value={'salary ASC'} selected={this.state.finances_sort === 'salary ASC'}>{__('select_sort_wage_asc')}</option>
                          <option value={'salary DESC'} selected={this.state.finances_sort === 'salary DESC'}>{__('select_sort_wage_desc')}</option>
                        </select>
                      </div>

                      {this.state.bonus.length > 0 ?
                        <div style={{width: '12%', marginLeft: '0.6vw'}}>
                          <select
                            name={__('select_option')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.finances_changeBonus(event)}
                          >
                            <option value={'null'} disabled>{__('misc_surcharges')}</option>
                            <option 
                              value={'1'} 
                              selected={this.state.finances_bonus === true}
                            >{__('select_surcharge_inclusive')}</option>
                            <option 
                              value={'0'} 
                              selected={this.state.finances_bonus === false}
                            >{__('select_surcharge_exclusive')}</option>
                          </select>
                        </div>
                      : null}

                    </div>

                    {this.state.finances_period === 1 ?

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                          <ReactDatePicker
                            selected={this.state.finances_start}
                            onChange={(date) => this.finances_selectStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={__('misc_startDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                              
                          />

                        </div>

                        <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                        <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                          <ReactDatePicker
                            selected={this.state.finances_end}
                            onChange={(date) => this.finances_selectEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={__('misc_endDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          />

                        </div>

                      </div>

                    :

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_date_container'>

                          <ReactDatePicker 
                            selected={this.state.finances_month}
                            onSelect={date => this.finances_selectMonth(date)}
                            className={'hr_inputBoxTime_big'}
                            locale={nl}
                            dropdownMode="select"
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                          />

                        </div>

                      </div>

                    }

                    {this.finances_render_title()}

                    {this.finances_render()}

                  </div>

                :
                this.state.menu === 'times' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_timeReport')}</div>

                    <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'abs' })}>{__('report_manageExclusions')}</div>

                    <HoursRegistration onlyReport={true} date={this.times_initDate} />

                  </div>

                :
                this.state.menu === 'holidays' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_leaveReport')}</div>

                    <Absences history={this.props.history} />

                  </div>

                :

                  null

                }

              </div>

            }

          </div>

        </div>

      </div>
      );

    } else {

      return (

        <div className='reports'>

          {this.state.showAlert === true ?
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />
          : null}

          {this.state.general_show_noteModal === true ?
            <NoteModal 
              _closeModal={this.closeModal.bind(this)}
              _modalMode={this.state.modalMode}
              _modalData={this.state.modalData}
              _reloadScreen={this.reloadScreen.bind(this)}
              _toggleNote={this.general_toggle_note.bind(this)}
            />
          : null}

          <div>

            {this.state.loading === true ?
              
              <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

              <div className='employees_container_individual'>

                {this.props.individual_menu === 'general' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_generalOverview')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      {this.state.general_show_holidays === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: 0}} onClick={() => this.general_toggle_holidays()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_leave')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: 0}} onClick={() => this.general_toggle_holidays()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_leave')}</span>
                        </div>
                      }

                      {this.state.general_show_holidayRequests === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_holidayRequests()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_leaveRequests')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_holidayRequests()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_leaveRequests')}</span>
                        </div>
                      }

                      {this.state.general_show_availables === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_availables()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_availability')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_availables()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_availability')}</span>
                        </div>
                      }

                      {this.state.general_show_unavailables === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_unavailables()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.general_toggle_unavailables()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      }

                      {this.general_render_note_toggleButtons()}

                    </div>

                    {/* <span className='defaultLinkO' onClick={() => this.openNoteModal(null, 0)}>Notitie toevoegen</span> */}

                    <div className='reports_simple_toggleRow' style={{marginTop: '1vw', width: '46%'}}>

                      <span>{__('reports_show_weekends')}</span>

                      <label className={"switch"}>
                        <input 
                          type="checkbox" 
                          checked={this.state.general_show_weekends} 
                          onClick={() => this.general_toggle_weekends()}
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>

                    </div>

                    <div className='reports_simple_toggleRow' style={{marginTop: 4, width: '46%'}}>

                      <span>{__('reports_show_holidays')}</span>

                      <label className={"switch"}>
                        <input 
                          type="checkbox" 
                          checked={this.state.general_show_freeDays} 
                          onClick={() => this.general_toggle_freeDays()}
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>

                    </div>

                    <div className='reports_simple_toggleRow' style={{marginTop: 4, width: '46%'}}>

                      <span>{__('reports_pinNotes')}</span>

                      <label className={"switch"}>
                        <input 
                          type="checkbox" 
                          checked={this.state.general_sticky} 
                          onClick={() => this.general_toggle_sticky()}
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.general_start}
                          onChange={(date) => this.general_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.general_end}
                          onChange={(date) => this.general_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                          maxDate={new Date(this.state.general_start.getFullYear(), this.state.general_start.getMonth() + 4, this.state.general_start.getDate())}
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                    </div>

                    <div className='reports_general_legend'>

                      {this.general_renderLegend()}

                    </div>

                    {this.general_renderAll()}

                  </div>

                :
                this.props.individual_menu === 'availability' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_availabilityReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      {Data.data.appOptions.availability !== 'UNAVAILABILITY' ?
                        this.state.avail_show_availables === true ?
                          <div className='reports_toggleButton_active' style={{marginLeft: 0}} onClick={() => this.avail_toggle_availables()}>
                            <i className='fas fa-check-circle' />
                            <span>{__('misc_availability')}</span>
                          </div>
                        :
                          <div className='reports_toggleButton' style={{marginLeft: 0}} onClick={() => this.avail_toggle_availables()}>
                            <i className='fal fa-circle' />
                            <span>{__('misc_availability')}</span>
                          </div>
                      : null}

                      {this.state.avail_show_unavailables === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: Data.data.appOptions.availability !== 'UNAVAILABILITY' ? '0.6vw' : 0}} onClick={() => this.avail_toggle_unavailables()}>
                          <i className='fas fa-check-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: Data.data.appOptions.availability !== 'UNAVAILABILITY' ? '0.6vw' : 0}} onClick={() => this.avail_toggle_unavailables()}>
                          <i className='fal fa-circle' />
                          <span>{__('misc_unavailability')}</span>
                        </div>
                      }

                      {Data.data.appOptions.availability !== 'SCHOOL' ?
                        this.state.avail_show_notfilled === true ?
                          <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.avail_toggle_notfilled()}>
                            <i className='fas fa-check-circle' />
                            <span>{__('misc_notFilled')}</span>
                          </div>
                        :
                          <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.avail_toggle_notfilled()}>
                            <i className='fal fa-circle' />
                            <span>{__('misc_notFilled')}</span>
                          </div>
                      : null}

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.avail_start}
                          onChange={(date) => this.avail_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.avail_end}
                          onChange={(date) => this.avail_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <div style={{width: '20%', marginLeft: '1.2vw'}}>
                        <select
                            name={__('select_sort')}
                            className={'reports_dropdown'}
                            onChange={(event) => this.avail_changeSort(event)}
                        >
                            <option value={'null'} disabled>{__('select_sort')}</option>
                            <option value={'name ASC'} selected={this.state.avail_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                            <option value={'name DESC'} selected={this.state.avail_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                            <option value={'avail ASC'} selected={this.state.avail_sort === 'avail ASC'}>{__('select_sort_availability_asc')}</option>
                            <option value={'avail DESC'} selected={this.state.avail_sort === 'avail DESC'}>{__('select_sort_availability_desc')}</option>
                            <option value={'unavail ASC'} selected={this.state.avail_sort === 'unavail ASC'}>{__('select_sort_unavailability_asc')}</option>
                            <option value={'unavail DESC'} selected={this.state.avail_sort === 'unavail DESC'}>{__('select_sort_unavailability_desc')}</option>
                        </select>
                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}
                      </div>

                      {Data.data.appOptions.availability !== 'UNAVAILABILITY' && this.state.avail_show_availables === true ?
                        <div className='hr_hoursBar_sub3'>
                          {__('misc_available')}
                        </div>
                      : null}

                      {this.state.avail_show_unavailables === true ?
                        <div className='hr_hoursBar_sub3'>
                          {__('misc_unavailable')}
                        </div>
                      : null}

                      {this.state.avail_show_notfilled === true && Data.data.appOptions.availability !== 'SCHOOL' ?    
                        <div className='hr_hoursBar_sub3'>
                          {__('misc_notFilled')}
                        </div>
                      : null}

                    </div>

                    {this.avail_render()}

                  </div>

                :
                this.props.individual_menu === 'trades' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_tradeReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '20%'}}>
                        <select
                          name={__('select_sort')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.trades_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option 
                            value={'ORDER BY s1.rooster_datum ASC, s1.rooster_begin ASC, s2.rooster_datum ASC, s2.rooster_begin ASC'} 
                            selected={this.state.trades_sort === 'ORDER BY s1.rooster_datum ASC, s1.rooster_begin ASC, s2.rooster_datum ASC, s2.rooster_begin ASC'}
                          >{__('select_sort_dateShift1_asc')}</option>
                          <option 
                            value={'ORDER BY s1.rooster_datum DESC, s1.rooster_begin DESC, s2.rooster_datum DESC, s2.rooster_begin DESC'} 
                            selected={this.state.trades_sort === 'ORDER BY s1.rooster_datum DESC, s1.rooster_begin DESC, s2.rooster_datum DESC, s2.rooster_begin DESC'}
                          >{__('select_sort_dateShift1_desc')}</option>
                          <option 
                            value={'ORDER BY s2.rooster_datum ASC, s2.rooster_begin ASC, s1.rooster_datum ASC, s1.rooster_begin ASC'} 
                            selected={this.state.trades_sort === 'ORDER BY s2.rooster_datum ASC, s2.rooster_begin ASC, s1.rooster_datum ASC, s1.rooster_begin ASC'}
                          >{__('select_sort_dateShift2_asc')}</option>
                          <option 
                            value={'ORDER BY s2.rooster_datum DESC, s2.rooster_begin DESC, s1.rooster_datum DESC, s1.rooster_begin DESC'} 
                            selected={this.state.trades_sort === 'ORDER BY s2.rooster_datum DESC, s2.rooster_begin DESC, s1.rooster_datum DESC, s1.rooster_begin DESC'}
                          >{__('select_sort_dateShift2_desc')}</option>
                        </select>
                      </div>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.trades_start}
                          onChange={(date) => this.trades_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.trades_end}
                          onChange={(date) => this.trades_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_name')} 
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_shift')} 
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_name')} 
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_shift')} 
                      </div>

                    </div>

                    {this.trades_render()}

                  </div>

                :
                this.props.individual_menu === 'absences' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_absenceReport')} </div>

                    {/* <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'abs' })}>Beheer afwezigheidstypes</div> */}

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '20%'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.abs_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'name ASC'} selected={this.state.abs_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.abs_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          {this.abs_getSortTypes()}
                        </select>
                      </div>

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.abs_start}
                          onChange={(date) => this.abs_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.abs_end}
                          onChange={(date) => this.abs_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')} 
                      </div>

                      {this.abs_render_title()}

                    </div>

                    {this.abs_render()}

                  </div>

                :
                this.props.individual_menu === 'finances' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_financesReport')} </div>

                    {/* <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'exclusions' })}>Beheer uitsluitingen</div> */}

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div className="reports_toggleButton_pair">

                        <div className={`reports_toggleButton_pair_sub1${this.state.finances_period === 1 ? '_active' : ''}`} onClick={() => this.finances_changePeriod(1)}>
                          <span>{__('misc_periodic')}</span>
                        </div>

                        <div className={`reports_toggleButton_pair_sub2${this.state.finances_period === 0 ? '_active' : ''}`} onClick={() => this.finances_changePeriod(0)}>
                          <span>{__('misc_monthly')}</span>
                        </div>

                      </div>
                    
                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '20%'}}>
                        <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.finances_changeSort(event)}
                        >
                          <option value={'null'} disabled>{__('select_sort')}</option>
                          <option value={'name ASC'} selected={this.state.finances_sort === 'name ASC'}>{__('select_sort_name_asc')}</option>
                          <option value={'name DESC'} selected={this.state.finances_sort === 'name DESC'}>{__('select_sort_name_desc')}</option>
                          <option value={'hours ASC'} selected={this.state.finances_sort === 'hours ASC'}>{__('select_sort_hours_asc')}</option>
                          <option value={'hours DESC'} selected={this.state.finances_sort === 'hours DESC'}>{__('select_sort_hours_desc')}</option>
                          <option value={'gains ASC'} selected={this.state.finances_sort === 'gains ASC'}>{__('select_sort_gains_asc')}</option>
                          <option value={'gains DESC'} selected={this.state.finances_sort === 'gains DESC'}>{__('select_sort_gains_desc')}</option>
                          <option value={'salary ASC'} selected={this.state.finances_sort === 'salary ASC'}>{__('select_sort_wage_asc')}</option>
                          <option value={'salary DESC'} selected={this.state.finances_sort === 'salary DESC'}>{__('select_sort_wage_desc')}</option>
                        </select>
                      </div>

                      {this.state.bonus.length > 0 ?
                        <div style={{width: '16%', marginLeft: '0.6vw'}}>
                          <select
                            name={__('select_option')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.finances_changeBonus(event)}
                          >
                            <option value={'null'} disabled>{__('misc_surcharges')}</option>
                            <option 
                              value={'1'} 
                              selected={this.state.finances_bonus === true}
                            >{__('select_surcharge_inclusive')}</option>
                            <option 
                              value={'0'} 
                              selected={this.state.finances_bonus === false}
                            >{__('select_surcharge_exclusive')}</option>
                          </select>
                        </div>
                      : null}

                    </div>

                    {this.state.finances_period === 1 ?

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                          <ReactDatePicker
                            selected={this.state.finances_start}
                            onChange={(date) => this.finances_selectStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={__('misc_startDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                              
                          />

                        </div>

                        <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                        <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                          <ReactDatePicker
                            selected={this.state.finances_end}
                            onChange={(date) => this.finances_selectEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={__('misc_endDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          />

                        </div>

                      </div>

                    :

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_date_container' style={{width: '15%'}}>

                          <ReactDatePicker 
                            selected={this.state.finances_month}
                            onSelect={date => this.finances_selectMonth(date)}
                            className={'hr_inputBoxTime_big'}
                            locale={nl}
                            dropdownMode="select"
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                          />

                        </div>

                      </div>

                    }

                    {this.finances_render_title()}

                    {this.finances_render()}

                  </div>

                :
                this.props.individual_menu === 'minmax' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_minMaxReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.minmax_start}
                          onChange={(date) => this.minmax_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.minmax_end}
                          onChange={(date) => this.minmax_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')} 
                      </div>

                      {this.minmax_render_title()}

                    </div>

                    {this.minmax_render()}

                  </div>

                :
                this.props.individual_menu === 'flex' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_flexReport')}</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.flex_start}
                          onChange={(date) => this.flex_selectStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_startDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                            
                        />

                      </div>

                      <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                      <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                        <ReactDatePicker
                          selected={this.state.flex_end}
                          onChange={(date) => this.flex_selectEndDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('misc_endDate')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                        />

                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}  
                      </div>

                      {this.flex_render_title()}

                    </div>

                    {this.flex_render()}

                  </div>

                :
                this.props.individual_menu === 'times' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginBottom: '2vw'}}>{__('misc_timeReport')}</div>

                    {/* <div className='defaultLinkO' style={{marginTop: '2vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'exclusions' })}>Beheer uitsluitingen</div> */}

                    <HoursRegistration onlyReport={true} date={this.times_initDate} history={this.props.history} location={this.props.location} individual={true} individual_userID={this.props.individual_userID} individual_menu={this.props.individual_menu} />

                  </div>

                :
                this.state.menu === 'holidays' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_leaveReport')}</div>

                    <Absences history={this.props.history} individual={true} individual_userID={this.props.individual_userID} individual_menu={this.props.individual_menu} />

                  </div>

                :

                  null

                }

              </div>

            }

          </div>

        </div>

      );
      
    }

  }
  
}

export default Reports;