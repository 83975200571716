import React from 'react';
import './admin.css';
import '../constants/DefaultStyle.css';

import Data from '../constants/Data';

import AdminModal from './adminModal';
import APIGetAllStoreCodes from '../classes/APIGetAllStoreCodes';
import APIACPLogin from '../classes/APIACPLogin';

import CryptoJS from 'crypto-js';
import Colors from '../constants/Colors';

class Admin extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.l_i_a === false) {
      this.props.history.push("/");
    }
    
    this.state = {
      loading: true,
      stores: [],

      filter: '',

      reloading: false,

    };

  }

  async componentDidMount() {

    if(Data.data.l_i_a === false) {
      this.props.history.push("/");
      return;
    }

    // if(correct !== true) {
    //   this.props.history.push("/");
    //   return;
    // }

    await this.getStores();

  }

  openHomeModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  async reload() {

    this.setState({loading: true});

    await this.getStores();

    this.setState({loading: false});

  }

  async getStores() {

    const stores = await APIGetAllStoreCodes.Request();

    if(stores === 'error') {

        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(stores === null || stores === []) {
        this.setState({
            loading: false,
        });
        return;
    }

    this.setState({ 
        stores: stores,
        loading: false
    });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getStores();

    this.setState({reloading: false});

  }

  getStoreBars() {

    let hourBars = [];

    const filteredData = [];
    const filter = this.state.filter;

    for(let item of this.state.stores) {
      if(item.bedrijf_naam.toLowerCase().indexOf(filter) !== -1 || item.bedrijf_code.toLowerCase().indexOf(filter) !== -1) {
        filteredData.push(item);
      }
    }

    for(let key in filteredData) {

      const store = filteredData[key];

      hourBars.push(

        <div key={key} className={'admin_barContainer'} onClick={() => this.openHomeModal(1, store)}>

          <div className={'admin_barRow'}>
            {store.bedrijf_naam}
          </div>
          <div className={'admin_barRow'}>
            {store.bedrijf_telefoon}
          </div>
          <div className={'admin_barRow'}>
            {store.bedrijf_email}
          </div>
          <div className={'admin_barRow'}>
            {store.bedrijf_proef_datum !== null && store.bedrijf_proef_datum !== '' ? <span style={{color: Colors.color.redFilledIn}}>{store.bedrijf_proef_datum}</span> : '-'}
          </div>
          <div className={'admin_barRow'} style={{ flex: 0.5 }}>
            {store.pakket_id === '0' ? <span style={{color: Colors.color.redFilledIn}}>{store.pakket_id}</span> : store.pakket_id}
          </div>

        </div>

      );

    }

    return hourBars;

  }

  render() {

    const storeBars = this.getStoreBars();

    return (
      <div className='myHours'>

      <div className='planner_nav2'>

        <div className='planner_nav2_sub1' onClick={() => {this.props.history.push("/"); Data.data.l_i_a = false}}>
          <i className='fa fa-chevron-left'></i>
        </div> 
        <div className='planner_nav2_sub2' onClick={() => this.reload()}>
          <i className='fas fa-sync-alt'></i>
        </div> 
        <div className='planner_nav2_sub3' style={{flex: 2}}>
            Bedrijven
        </div> 
        <div className='planner_nav2_sub4' onClick={() => this.openHomeModal(0)}>
                <i className='fa fa-plus'></i>
            </div>
        <div className='planner_nav2_sub5'>
   
        </div> 

      </div>

      {this.state.loading === true ?
      
                  <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

      :

        <div className='admin_admin_container'>

          {this.state.reloading === true ?
                    
            <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            null

          }

              {this.state.showModal === true ?
                
                <AdminModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                  history={this.props.history}
                />

              :

                null
              
              }

              <input 
                  className={'inputField2'}
                  value={this.state.filter}
                  onChange={(event) => this.setState({filter: event.target.value})}
                  style={{marginBottom: 25, marginTop: 25, width: '50%', marginLeft: '25%'}}
              />

              <div className={'admin_barContainerTop stickyTopHours'}>
                <div className={'admin_barRowMain'}>
                  <div>Bedrijfsnaam</div>
                </div>
                <div className={'admin_barRowMain'}>
                  <div>Telefoon</div>
                </div>
                <div className={'admin_barRowMain'}>
                  <div>E-mail</div>
                </div>
                <div className={'admin_barRowMain'}>
                  <div>Proef</div>
                </div>
                <div className={'admin_barRowMain'} style={{flex: 0.5}}>
                  <div>Pakket</div>
                </div>
              </div>

              {storeBars}
          
        </div>
    
      }

    </div>
    );
  }
  
}

export default Admin;