/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../functions/functions.css'
import '../../../constants/DefaultStyle.css';
import './shifts.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
//import APIGetAllAvailabilities from '../../classes/APIGetAllAvailabilities';
//import APIGetAllUnavailabilities from '../../classes/APIGetAllUnavailabilities';
//import APIGetAllNotFilled from '../../classes/APIGetAllNotFilled';
import APIGetDefaultShifts from '../../../classes/APIGetDefaultShifts';
import APIGetFunctions from '../../../classes/APIGetFunctions';
import APIGetDefaultDayShift from '../../../classes/APIGetDefaultDayShifts';
import Constants from '../../../constants/Constants';
import Colors from '../../../constants/Colors';

import { Tooltip } from 'react-tippy';

import 'react-datepicker/dist/react-datepicker.css';
import ShiftsModal from './shiftsModal';
import APIGetDayShiftTemplates from '../../../classes/APIGetDayShiftTemplates';
import ShiftTempModal from './shiftTempModal';
import APIAddDefaultDayShift from '../../../classes/APIAddDefaultDayShift';
import APIEditDefaultDayShift from '../../../classes/APIEditDefaultDayShift';
import APIDeleteDefaultDayShift from '../../../classes/APIDeleteDefaultDayShift';
import BetterAlert from '../../../components/alert/alert';
import APIGetParticular from '../../../classes/APIGetParticular';
import APIGetSkills from '../../../classes/APIGetSkills';
import APIGetAllSkillDayShifts from '../../../classes/APIGetAllSkillDayShifts';
import APIDeleteSkillDayShift from '../../../classes/APIDeleteSkillDayShift';
import APIAddSkillDayShift from '../../../classes/APIAddSkillDayShift';
import APIGetAllPartDayShifts from '../../../classes/APIGetAllPartDayShifts';
import APIDeletePartDayShift from '../../../classes/APIDeletePartDayShift';
import APIAddPartDayShift from '../../../classes/APIAddPartDayShift';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';
import planClass from '../../planner/plan.class';

class Shifts extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    this.plannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    this.functionsData = [];
    this.timesData = [];

    this.lastDeleted = null;
    this.lastVal = '';
    this.timeRef = {};
    this.planGroupRef = {};

    this.fromPlanner = [];
    this.toPlanner = [];

    this.functionsArrange = [];

    this.isPlanning = false;
    
    this.state = {

      loading: true,
      reloading: false,

      showModal: false,
      showTempModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      shiftTemplates: [],
      shiftTemp: null,
      shiftTempName: 'Selecteer een sjabloon',
      shiftTempObj: null,

      showModeMenu: false,

      collapsedFunctions: [],

      showPlanMenu: {},
      showPlanMenuEdit: {},
      showPlanMenuSpecial: {},
      showPlanMenuComp: {},
      showPlanMenuBreaks: {},
      planInputs: {},

      selectedBar: '',

      editPlan: false,

      particularities: [],
      skills: [],

      partSchedules: [],
      skillSchedules: [],

      breaks: [],

      showSettingsMenu: false,

      copyShifts: false,

    };

    //componentWillMount here:

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    this.functionsArrange = await APIGET.Request(`SELECT fv_functie_id, fv_index`, `FROM FunctieVolgorde`, null, null, null, `ORDER BY fv_index`);

    await this.getTimes();
    await this.getFunctions();

    const shiftTemplates = await APIGetDayShiftTemplates.Request();
    let newShiftTemplates = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const temp of shiftTemplates) {
        if (temp.dagdienstsjabloon_team_id === Data.data.chosenTeamObj.team_id) {
          newShiftTemplates.push(temp);
        }
      }
    } else {
      newShiftTemplates = shiftTemplates;
    }

    const chosenTemp = newShiftTemplates.length > 0 ? newShiftTemplates[0] : null;

    await this.getPlannerData(chosenTemp !== null ? parseInt(chosenTemp.dagdienstsjabloon_id) : null);

    // skills, particu, absencetypes
    const skills = await APIGetSkills.Request();
    const particularities = await APIGetParticular.Request();

    // GET PARTICULARITIES FROM SCHEDULES
    const partSchedules = await APIGetAllPartDayShifts.Request();
    const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');

    // GET SKILLS FROM SCHEDULES
    const skillSchedules = await APIGetAllSkillDayShifts.Request();
    const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');

    // BREAKS
    const breaks = await APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`);

    this.setState({
      shiftTemplates: newShiftTemplates,
      shiftTemp: chosenTemp !== null ? parseInt(chosenTemp.dagdienstsjabloon_id) : null,
      shiftTempName: chosenTemp !== null ? chosenTemp.dagdienstsjabloon_naam : 'Selecteer een sjabloon',
      shiftTempObj: chosenTemp,

      skills: skills,
      particularities: particularities,

      skillSchedules: skillSchedulesObj,
      partSchedules: partSchedulesObj,

      breaks: breaks,

      loading: false,
    });

  }

  getShiftTempName(tempID) {

    for (const temp of this.state.shiftTemplates) {
      if (parseInt(temp.dagdienstsjabloon_id) === tempID) {
        return temp.dagdienstsjabloon_naam;
      }
    }

    return 'Standaard';

  }

  getShiftTempObject(tempID) {

    for (const temp of this.state.shiftTemplates) {
      if (parseInt(temp.dagdienstsjabloon_id) === tempID) {
        return temp;
      }
    }

    return null;

  }

  async getFunctions() {

    const functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.functionsData = functionsData;

    // new sort
    if (this.functionsArrange.length > 0) {

      for (const arr of this.functionsArrange) {

        for (const func of this.functionsData) {

          if (func.functie_id === arr.fv_functie_id && Constants.objectArrayContainsKey(this.functionsData, 'functie_id', arr.fv_functie_id)) {
            let index = this.functionsData.indexOf(func);
            this.functionsData.splice(index, 1);
            this.functionsData.splice(parseInt(arr.fv_index), 0, func);
          }

        }

      }

    }

  }

  async getTimes() {

    const timesData = await APIGetDefaultShifts.Request();

    if(timesData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(timesData === null) {
      this.timesData = [];
    }

    this.timesData = timesData;

  }

  async getPlannerData(temp = 1) {

    if (temp === null) {

      this.plannerData.mon = [];
      this.plannerData.tue = [];
      this.plannerData.wed = [];
      this.plannerData.thu = [];
      this.plannerData.fri = [];
      this.plannerData.sat = [];
      this.plannerData.sun = [];
      //this.setState({ loading: false });
      return;

    }

    this.plannerData.mon = [];
    this.plannerData.tue = [];
    this.plannerData.wed = [];
    this.plannerData.thu = [];
    this.plannerData.fri = [];
    this.plannerData.sat = [];
    this.plannerData.sun = [];

    const shifts = await APIGetDefaultDayShift.Request(temp);

    if(shifts === 'error') {

      this.plannerData = [];
      
      this.setState({
          errorScreen: true,
          loading: false,
      });
      return;
    }

    for(let key in shifts) {

      if(shifts[key].dagdienst_dag === '0') {
        this.plannerData.sun.push(shifts[key]);
        continue;
      }
      if(shifts[key].dagdienst_dag === '1') {
        this.plannerData.mon.push(shifts[key]);
        continue;
      }
      if(shifts[key].dagdienst_dag === '2') {
        this.plannerData.tue.push(shifts[key]);
        continue;
      }
      if(shifts[key].dagdienst_dag === '3') {
        this.plannerData.wed.push(shifts[key]);
        continue;
      }
      if(shifts[key].dagdienst_dag === '4') {
        this.plannerData.thu.push(shifts[key]);
        continue;
      }
      if(shifts[key].dagdienst_dag === '5') {
        this.plannerData.fri.push(shifts[key]);
        continue;
      }
      if(shifts[key].dagdienst_dag === '6') {
        this.plannerData.sat.push(shifts[key]);
        continue;
      }

    }

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        planInputs[plan.dagdienst_id] = `${plan.dagdienst_begin.substr(0, 5)} - ${plan.dagdienst_eind.substr(0, 5)}`;        
        showPlanMenu[plan.dagdienst_id] = false;
        showPlanMenuEdit[plan.dagdienst_id] = false;
        showPlanMenuSpecial[plan.dagdienst_id] = false;
        showPlanMenuComp[plan.dagdienst_id] = false;
        showPlanMenuBreaks[plan.dagdienst_id] = false;
      }
    }

    this.setState({
      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,

      plannerDataState: this.plannerData,
    });

  }

  openShiftsModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: [],
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      showTempModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  async duplicateTemplate(shiftTemp) {

    this.setState({loading: true});

    this.closeModal();

    for (const plan of this.plannerData.mon) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 1, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.tue) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 2, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.wed) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 3, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.thu) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 4, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.fri) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 5, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.sat) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 6, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.sun) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 0, '${plan.dagdienst_begin}', '${plan.dagdienst_eind}', ${plan.functie_id}, '${plan.dagdienst_pauze}', ${shiftTemp})`);
    }

    await this.reloadScreen();

    await this.selectTemplate({target: { value: shiftTemp }});

    this.setState({loading: false});

  }

  async selectTemplate(event) {

    const id = event.target.value;

    if (this.state.shiftTemp === parseInt(id)) {
      return;
    }

    this.setState({loading: true, shiftTempObj: this.getShiftTempObject(parseInt(id)), shiftTemp: parseInt(id), shiftTempName: this.getShiftTempName(parseInt(id)) }, async() => {

      await this.reloadScreen();

      this.setState({loading: false});

    });

  }

  async reloadScreen(fromAddTemp = false) {

    this.setState({ loading: true });

    await this.getFunctions();

    const shiftTemplates = await APIGetDayShiftTemplates.Request();
    let newShiftTemplates = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const temp of shiftTemplates) {
        if (temp.dagdienstsjabloon_team_id === Data.data.chosenTeamObj.team_id) {
          newShiftTemplates.push(temp);
        }
      }
    } else {
      newShiftTemplates = shiftTemplates;
    }

    const chosenTemp = newShiftTemplates.length > 0 ? fromAddTemp === 'ADD' ? newShiftTemplates[newShiftTemplates.length - 1] : newShiftTemplates[0] : null;

    if ((fromAddTemp === 'DEL' || fromAddTemp === 'ADD') && chosenTemp !== null) {

      await this.getPlannerData(parseInt(chosenTemp.dagdienstsjabloon_id));

      this.fromPlannerDay = -1;
      this.fromPlanner = [];
      this.toPlanner = [];
      this.isPlanning = false;

      this.setState({ shiftTemplates: newShiftTemplates, shiftTempObj: chosenTemp, shiftTemp: parseInt(chosenTemp.dagdienstsjabloon_id), shiftTempName: chosenTemp.dagdienstsjabloon_naam, loading: false, reloading: false, selectedBar: '', });

    } else if (fromAddTemp === 'DEL' || fromAddTemp === 'ADD') {

      await this.getPlannerData(null);

      this.fromPlannerDay = -1;
      this.fromPlanner = [];
      this.toPlanner = [];
      this.isPlanning = false;

      this.setState({ shiftTemplates: newShiftTemplates, shiftTempObj: null, shiftTemp: null, shiftTempName: 'Selecteer een sjabloon', loading: false, selectedBar: '', });


    } else {

      await this.getPlannerData(this.state.shiftTemp);

      this.fromPlannerDay = -1;
      this.fromPlanner = [];
      this.toPlanner = [];
      this.isPlanning = false;

      this.setState({ shiftTemplates: newShiftTemplates, loading: false, selectedBar: '', });

    }

  }

  async onClickHeader(i, functionID) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    let day = i === 6 ? 0 : i + 1;

    // if(this.fromPlannerDay !== day) {
    //   this.fromPlanner = [];
    //   this.setState({ selectedBar: '' });
    // }

    // if(this.fromPlanner.length === 0 || this.state.selectedBar === '') {
    //   this.fromPlanner = [];
    //   this.setState({ selectedBar: '' });
    //   Constants.defaultAlert(this, 'Melding', 'Selecteer of versleep eerst een dienst');
    //   return;
    // }

    this.setState({ selectedBar: '', editPlan: false });
    this.lastEdited = '';

    if (functionID !== null && functionID >= 0) { //HEADER

      if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) { // from planned shift

        if(this.fromPlanner.functie_id === functionID && this.fromPlannerDay === day) {
          this.fromPlanner = [];
          this.setState({ selectedBar: '' });
          return;
        }

        this.isPlanning = true;

        if (this.state.copyShifts !== true) {
          await APIDeleteDefaultDayShift.Request(this.fromPlanner.dagdienst_id);
        }

        const added = await APIAddDefaultDayShift.Request(
          day,
          this.fromPlanner.dagdienst_begin,
          this.fromPlanner.dagdienst_eind,
          functionID,
          this.fromPlanner.dagdienst_pauze,
          this.state.shiftTemp
        );

        if(added !== null) {

          // copy bijzonderheden
          const partDayShift = this.state.partSchedules[this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIDeletePartDayShift.Request(this.fromPlanner.dagdienst_id);
            await APIAddPartDayShift.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartDayShifts.Request();
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }

          // copy skills
          const skillsDayShift = this.state.skillSchedules[this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIDeleteSkillDayShift.Request(skill.comp_id, this.fromPlanner.dagdienst_id);
              await APIAddSkillDayShift.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillDayShifts.Request();
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          this.reloadScreen();
        } else {
          // console.log('error');
        }

      } else { // from av, not fileled, unav

        this.isPlanning = true;
  
        const added = await APIAddDefaultDayShift.Request(
          day,
          '00:00',
          '00:00',
          functionID,
          '00:00',
          this.state.shiftTemp,
        );
  
        if(added !== null) {
          this.reloadScreen();
        } else {
          // console.log('error');
        }
  
      }

    }

    // if(this.state.mode === 'MODAL') {

    //   this.setState({
    //     modalData: functionID,
    //     modalMode: 2,
    //     showModal: true,
    //     modalDate: new Date(date),
    //     reloadScreenMode: 0, //1
    //   });

    // }

    // if(this.state.mode === 'CLICK') {

    // this.setState({
    //   modalData: this.fromPlanner,
    //   modalMode: 3,
    //   showModal: true,
    //   modalDate: new Date(date),
    //   reloadScreenMode: 0, //1
    //   modalFunctionID: functionID,
    // });

   // }

  }

  async onClickUserShift(i, editUserObject) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;

    this.setState({editPlan: false});

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') {

      if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && typeof editUserObject.dagdienst_id !== 'undefined') { // switch shifts

        // if (this.fromPlanner.dagdienst_dag === editUserObject.dagdienst_dag && this.fromPlanner.functie_id === editUserObject.functie_id) {
        //   this.setState({ selectedBar: '' });
        //   this.fromPlanner = [];
        //   return;
        // }

        if (this.fromPlanner.dagdienst_dag === editUserObject.dagdienst_dag && this.fromPlanner.dagdienst_begin === editUserObject.dagdienst_begin && this.fromPlanner.dagdienst_eind === editUserObject.dagdienst_eind && this.fromPlanner.functie_id === editUserObject.functie_id) {
          this.setState({ selectedBar: '' });
          this.fromPlanner = [];
          return;
        }

        this.setState({ selectedBar: '' });
        this.isPlanning = true;

        // to
        const added = await APIEditDefaultDayShift.Request(
          editUserObject.dagdienst_dag,
          this.fromPlanner.dagdienst_begin,
          this.fromPlanner.dagdienst_eind,
          editUserObject.functie_id,
          editUserObject.dagdienst_id,
          this.fromPlanner.dagdienst_pauze
        );

        // from
        const added2 = await APIEditDefaultDayShift.Request(
          this.fromPlanner.dagdienst_dag,
          editUserObject.dagdienst_begin,
          editUserObject.dagdienst_eind,
          this.fromPlanner.functie_id,
          this.fromPlanner.dagdienst_id,
          editUserObject.dagdienst_pauze
        );

        if(added === true && added2 === true) {

          if (this.fromPlanner.functie_id !== editUserObject.functie_id) {

            // copy bijzonderheden
            const partDayShift = this.state.partSchedules[this.fromPlanner.dagdienst_id];
            const partDayShift2 = this.state.partSchedules[editUserObject.dagdienst_id];
            if (typeof partDayShift !== 'undefined') {
              await APIDeletePartDayShift.Request(this.fromPlanner.dagdienst_id);
            }
            if (typeof partDayShift2 !== 'undefined') {
              await APIDeletePartDayShift.Request(editUserObject.dagdienst_id);
            }
            if (typeof partDayShift !== 'undefined') {
              await APIAddPartDayShift.Request(partDayShift.bijz_id, editUserObject.dagdienst_id);
            }
            if (typeof partDayShift2 !== 'undefined') {
              await APIAddPartDayShift.Request(partDayShift2.bijz_id, this.fromPlanner.dagdienst_id);
            }
            if (typeof partDayShift !== 'undefined' || typeof partDayShift2 !== 'undefined') {
              const partSchedules = await APIGetAllPartDayShifts.Request();
              const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');
              this.setState({ partSchedules: partSchedulesObj }); 
            }

            // copy skills
            const skillsDayShift = this.state.skillSchedules[this.fromPlanner.dagdienst_id];
            const skillsDayShift2 = this.state.skillSchedules[editUserObject.dagdienst_id];
            if (typeof skillsDayShift !== 'undefined') {
              for (const skill of skillsDayShift) {
                await APIDeleteSkillDayShift.Request(skill.comp_id, this.fromPlanner.dagdienst_id);
              }
            }
            if (typeof skillsDayShift2 !== 'undefined') {
              for (const skill of skillsDayShift2) {
                await APIDeleteSkillDayShift.Request(skill.comp_id, editUserObject.dagdienst_id);
              }
            }
            if (typeof skillsDayShift !== 'undefined') {
              for (const skill of skillsDayShift) {
                await APIAddSkillDayShift.Request(skill.comp_id, editUserObject.dagdienst_id);
              }
            }
            if (typeof skillsDayShift2 !== 'undefined') {
              for (const skill of skillsDayShift2) {
                await APIAddSkillDayShift.Request(skill.comp_id, this.fromPlanner.dagdienst_id);
              }
            }
            if (typeof skillsDayShift !== 'undefined' || typeof skillsDayShift2 !== 'undefined') {
              const skillSchedules = await APIGetAllSkillDayShifts.Request();
              const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');
              this.setState({ skillSchedules: skillSchedulesObj }); 
            }

          }

          await this.reloadScreen();
          
        } else {
          // console.log('error');
        }

      }

    } else {

      if(this.state.selectedBar === 'r' + editUserObject.dagdienst_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;
  
      } else {
  
        this.setState({
          selectedBar: 'r' + editUserObject.dagdienst_id + i,
        });
    
        this.fromPlanner = editUserObject;
        this.fromPlannerDay = day;
  
      }

    }

  }

  async onDrop(event, i, editUserObject, functionID) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    functionID = parseInt(functionID);

    event.currentTarget.style.opacity = null;
    event.target.style.opacity = null;

    let day = i === 6 ? 0 : i + 1;

    this.setState({editPlan: false});

    if(this.fromPlanner.length !== 0) {

      if(editUserObject === null && functionID >= 0 && functionID !== null) { //HEADER

        if(this.fromPlanner.functie_id === functionID && this.fromPlannerDay === day) {
          this.fromPlanner = [];
          return;
        }

        this.isPlanning = true;

        if (this.state.copyShifts !== true) {
          await APIDeleteDefaultDayShift.Request(this.fromPlanner.dagdienst_id);
        }

        const added = await APIAddDefaultDayShift.Request(
          day,
          this.fromPlanner.dagdienst_begin,
          this.fromPlanner.dagdienst_eind,
          functionID,
          this.fromPlanner.dagdienst_pauze,
          this.state.shiftTemp
        );

        if(added !== null) {

          // copy bijzonderheden
          const partDayShift = this.state.partSchedules[this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIDeletePartDayShift.Request(this.fromPlanner.dagdienst_id);
            await APIAddPartDayShift.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartDayShifts.Request();
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }

          // copy skills
          const skillsDayShift = this.state.skillSchedules[this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIDeleteSkillDayShift.Request(skill.comp_id, this.fromPlanner.dagdienst_id);
              await APIAddSkillDayShift.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillDayShifts.Request();
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          this.reloadScreen();
        } else {
          // console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && typeof editUserObject.dagdienst_id !== 'undefined') { // switch shifts

        if (this.fromPlanner.dagdienst_dag === editUserObject.dagdienst_dag && this.fromPlanner.dagdienst_begin === editUserObject.dagdienst_begin && this.fromPlanner.dagdienst_eind === editUserObject.dagdienst_eind && this.fromPlanner.functie_id === editUserObject.functie_id) {
          this.setState({ selectedBar: '' });
          this.fromPlanner = [];
          return;
        }

        this.setState({ selectedBar: '' });
        this.isPlanning = true;

        // to
        const added = await APIEditDefaultDayShift.Request(
          editUserObject.dagdienst_dag,
          this.fromPlanner.dagdienst_begin,
          this.fromPlanner.dagdienst_eind,
          editUserObject.functie_id,
          editUserObject.dagdienst_id,
          this.fromPlanner.dagdienst_pauze
        );

        // from
        const added2 = await APIEditDefaultDayShift.Request(
          this.fromPlanner.dagdienst_dag,
          editUserObject.dagdienst_begin,
          editUserObject.dagdienst_eind,
          this.fromPlanner.functie_id,
          this.fromPlanner.dagdienst_id,
          editUserObject.dagdienst_pauze
        );

        if(added === true && added2 === true) {

          if (this.fromPlanner.functie_id !== editUserObject.functie_id) {

            // copy bijzonderheden
            const partDayShift = this.state.partSchedules[this.fromPlanner.dagdienst_id];
            const partDayShift2 = this.state.partSchedules[editUserObject.dagdienst_id];
            if (typeof partDayShift !== 'undefined') {
              await APIDeletePartDayShift.Request(this.fromPlanner.dagdienst_id);
            }
            if (typeof partDayShift2 !== 'undefined') {
              await APIDeletePartDayShift.Request(editUserObject.dagdienst_id);
            }
            if (typeof partDayShift !== 'undefined') {
              await APIAddPartDayShift.Request(partDayShift.bijz_id, editUserObject.dagdienst_id);
            }
            if (typeof partDayShift2 !== 'undefined') {
              await APIAddPartDayShift.Request(partDayShift2.bijz_id, this.fromPlanner.dagdienst_id);
            }
            if (typeof partDayShift !== 'undefined' || typeof partDayShift2 !== 'undefined') {
              const partSchedules = await APIGetAllPartDayShifts.Request();
              const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');
              this.setState({ partSchedules: partSchedulesObj }); 
            }

            // copy skills
            const skillsDayShift = this.state.skillSchedules[this.fromPlanner.dagdienst_id];
            const skillsDayShift2 = this.state.skillSchedules[editUserObject.dagdienst_id];
            if (typeof skillsDayShift !== 'undefined') {
              for (const skill of skillsDayShift) {
                await APIDeleteSkillDayShift.Request(skill.comp_id, this.fromPlanner.dagdienst_id);
              }
            }
            if (typeof skillsDayShift2 !== 'undefined') {
              for (const skill of skillsDayShift2) {
                await APIDeleteSkillDayShift.Request(skill.comp_id, editUserObject.dagdienst_id);
              }
            }
            if (typeof skillsDayShift !== 'undefined') {
              for (const skill of skillsDayShift) {
                await APIAddSkillDayShift.Request(skill.comp_id, editUserObject.dagdienst_id);
              }
            }
            if (typeof skillsDayShift2 !== 'undefined') {
              for (const skill of skillsDayShift2) {
                await APIAddSkillDayShift.Request(skill.comp_id, this.fromPlanner.dagdienst_id);
              }
            }
            if (typeof skillsDayShift !== 'undefined' || typeof skillsDayShift2 !== 'undefined') {
              const skillSchedules = await APIGetAllSkillDayShifts.Request();
              const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');
              this.setState({ skillSchedules: skillSchedulesObj }); 
            }

          }

          await this.reloadScreen();
          
        } else {
          // console.log('error');
        }

      }

    } else {

      if (Constants.isEmpty(editUserObject)) {
        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;
        return;
      }

      if(this.state.selectedBar === 'r' + editUserObject.dagdienst_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;
  
      } else {
  
        this.setState({
          selectedBar: 'r' + editUserObject.dagdienst_id + i,
        });
    
        this.fromPlanner = editUserObject;
        this.fromPlannerDay = day;
  
      }

    }

  }

  onDragStartUserShift(event, day, data, planKey) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    if (this.timeRef[data.dagdienst_id] === document.activeElement) {
      event.preventDefault();
      return false;
    }

    if (this.planGroupRef[planKey] === document.activeElement) {
      event.preventDefault();
      return false;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    day = day === 6 ? 0 : day + 1;

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragOverColumn(e, i) {
    e.preventDefault();
    e.target.style.opacity = 0.5;
  }

  onDragLeaveColumn(e) {
    e.preventDefault();
    e.target.style.opacity = null;
  }

  onDragOver(e, i) {
    e.preventDefault();
    e.currentTarget.style.opacity = 0.5;
  }

  onDragLeave(e) {
    e.preventDefault();
    e.currentTarget.style.opacity = null;
  }

  async saveTimeDirectly(e, planData, table, planGroup) {

    if (this.timeRef[planData.dagdienst_id].value === `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}`) { //this.state.planInputs[planData.rooster_id]
      //this.setState({editPlan: false});
      return;
    }

    let planInputs = this.state.planInputs;

    // declare start and end
    const start = this.timeRef[planData.dagdienst_id].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
    const end = this.timeRef[planData.dagdienst_id].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

    const startDate = Constants.getDateTZ('2020-01-01', start);
    const endDate = Constants.getDateTZ('2020-01-01', end);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

      // set initial value
      planInputs[planData.dagdienst_id] = `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}`;
      this.timeRef[planData.dagdienst_id].value = `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}`;
      this.setState({ planInputs: planInputs }); //editplan

      // remove focus
      //this.timeRef[planData.rooster_id].blur();

      // reset lastVal
      this.lastVal = '';

      alert("Je hebt geen correcte begin- of eindtijd ingevuld");
      return false;
    }

    // if (startDate.getTime() > endDate.getTime()) {

    //   // set initial value
    //   planInputs[planData.dagdienst_id] = `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}`;
    //   this.timeRef[planData.dagdienst_id].value = `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}`;
    //   this.setState({ planInputs: planInputs }); //editplan

    //   // reset lastVal
    //   this.lastVal = '';

    //   alert("Begintijd kan niet later zijn dan eindtijd");
    //   return false;
    // }
    
    if (typeof table === 'undefined') {
      this.setState({editPlan: false});
    }

    planInputs[planData.dagdienst_id] = this.timeRef[planData.dagdienst_id].value;

    // remove focus
    this.timeRef[planData.dagdienst_id].blur();

    // reset lastVal
    this.lastVal = '';

    // reset timeRef
    this.timeRef = {};
    this.planGroupRef = {};

    // change last edited color
    //this.lastEdited = planData.rooster_id + day;
    this.lastEdited = '';
    //this.setState({ lastEdited: this.lastEdited });
    this.setState({ lastEdited: this.lastEdited, planInputs: planInputs }); //editplan

    let _break = planData.dagdienst_pauze;
    if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(planData.dagdienst_dag) === false) {

      const obj = {
        rooster_datum: '2020-01-01',
        rooster_begin: start,
        rooster_eind: end,
      };

      _break = await this.getBreakRule('2020-01-01', start, '2020-01-01', end, obj);
      _break = _break.str.substr(0, 5);
        
    }

    let promises = [];

    for (const _plan of planGroup.shifts) {
      promises.push(
        APIEditDefaultDayShift.Request(
          planData.dagdienst_dag,
          start,
          end,
          planData.functie_id,
          _plan.dagdienst_id,
          _break
        )
      );
    }

    // save in planner
    await Promise.all(promises);

    // reload specific date
    await this.reloadScreen();

    if (table === true) {
      this.setState({editPlan: false});
    }

  }

  async savePlanGroup(e, planGroup, planKey) {

    if (this.planGroupRef[planKey].value === `${planGroup.count.toString()}` || this.state.loading || this.state.reloading || this.isPlanning) { //this.state.planInputs[planData.rooster_id]
      return;
    }

    const amount = parseInt(this.planGroupRef[planKey].value);

    if (isNaN(amount) || this.planGroupRef[planKey].value === '') {
      this.planGroupRef[planKey].value = planGroup.count.toString();
      return;
    }

    this.setState({ loading: true });

    let promises = [];

    if (amount === 0) {

      for (const plan of planGroup.shifts) {
        promises.push(APIDeleteDefaultDayShift.Request(plan.dagdienst_id));
      }

    } else if (amount < planGroup.count) {

      const deleteAmount = (planGroup.count - amount);
      let deleted = 0;

      for (const plan of planGroup.shifts) {
        if (deleted === deleteAmount) {
          break;
        }
        promises.push(APIDeleteDefaultDayShift.Request(plan.dagdienst_id));
        deleted++;
      }

    } else if (amount > planGroup.count) {

      const addAmount = (amount - planGroup.count);
      let added = 0;

      for (let i = 0; i < addAmount; i++) {
        if (added === addAmount) {
          break;
        }
        promises.push(APIAddDefaultDayShift.Request(planGroup.shifts[0].dagdienst_dag, planGroup.shifts[0].dagdienst_begin, planGroup.shifts[0].dagdienst_eind, planGroup.shifts[0].functie_id, planGroup.shifts[0].dagdienst_pauze, this.state.shiftTemp));
        added++;
      }

    }

    await Promise.all(promises);

    this.planGroupRef = {};
    this.timeRef = {};

    await this.reloadScreen();

  }

  async getBreakRule(startDate, startTime, endDate, endTime, schedule = null) {

    const start = Constants.stringToDateTime(startDate, startTime);
    const end = Constants.stringToDateTime(schedule !== null ? planClass.isNightShift2(schedule) : endDate, endTime);

    const totalMS = end.getTime() - start.getTime();
    const totalHours = totalMS / 1000 / 60 / 60;

    const breakRules = await APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`);

    let brk = 0;
    for (const br of breakRules) {

        if (totalHours >= parseFloat(br.pr_uren)) {
          brk = parseInt(br.pr_pauze);
        }

    }

    if (brk > 0) {
        brk = brk * 60 * 1000;
    }

    return {
        ms: brk,
        str: Constants.msToTime(brk),
    };

}

  handleKey(e, planData) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.timeRef[planData.dagdienst_id].blur();

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  handleKeyPlanGroup(e, planKey) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.planGroupRef[planKey].blur();

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 8) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  onChangeTime(e, planData, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }

    let val = e.target.value.toString();
    // if (val == this.timeRef[planData.rooster_id].value) {
    //     return;
    // } 

    // if(val.charAt(0) !== '' && val.charAt(0) !== '0' && val.charAt(0) !== '1' && val.charAt(0) !== '2') {
    //   console.log('here10')
    //   e.target.value = this.lastVal;
    //   return;
    // }
    // if (val.length == 4) {
    //   if(val.charAt(3) !== '0' && val.charAt(3) !== '1' && val.charAt(3) !== '2' && val.charAt(3) !== '3' && val.charAt(3) !== '4' && val.charAt(3) !== '5') {
    //     console.log('here11')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // if (val.length > 4) {
    //   if(parseInt(val.substr(3, 4)) < 0 && parseInt(val.substr(3, 4)) > 59) {
    //     console.log('here12')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // // ERROR IN HERE (this.lastVal)
    // if (val.length > 8) {
    //   if(val.charAt(8) !== '0' && val.charAt(8) !== '1' && val.charAt(8) !== '2') {
    //     console.log('here13')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // if (val.length > 11) {
      
    //   if(val.charAt(11) !== '0' && val.charAt(11) !== '1' && val.charAt(11) !== '2' && val.charAt(11) !== '3' && val.charAt(11) !== '4' && val.charAt(11) !== '5') {
    //     console.log('here14')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    //   if(parseInt(val.substr(11, 12)) < 0 && parseInt(val.substr(11, 12)) > 59) {
    //     console.log('here15')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // ERROR IN HERE

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}`.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}` : this.lastVal); //e.target.defaultvalue
    } 
    
    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      return false;
    }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.dagdienst_begin.substring(0,5)} - ${planData.dagdienst_eind.substring(0,5)}` : this.lastVal;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false) {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11) {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;

    } else {
      // val = val.slice(0, -1);
      // e.target.value = val;
    }

  }

  onClickPlanTime(data) {

    this.setState({editPlan: true},
      () => {
        this.timeRef[data.dagdienst_id].focus();
      }
    );

  }

  getPlannerTouchBoxTable(tempPlannerData, i, mgtop, planKey) {

    const planData = tempPlannerData;

    // check particularities
    let partColor = null;
    let partAfk = null;
    let partID = null;
    const partSchedule = this.state.partSchedules[planData.shifts[0].dagdienst_id];
    if (typeof partSchedule !== 'undefined') {
      partColor = partSchedule.bijz_kleur;
      partAfk = partSchedule.bijz_afkorting;
      partID = partSchedule.bijz_id;
    }

    // check comp
    let skills = null;
    const skillSchedule = this.state.skillSchedules[planData.shifts[0].dagdienst_id];
    if (typeof skillSchedule !== 'undefined') {
      skills = skillSchedule;
    }

    let functionColor = planData.shifts[0].functie_kleur !== null ? planData.shifts[0].functie_kleur : 'EBEBEB';
    let functionName = planData.shifts[0].functie_naam !== null ? planData.shifts[0].functie_naam : 'Functie onbekend';

    return (

      <div key={planData.shifts[0].dagdienst_id} className='plannerN_shiftOuter' style={{width: '96%', marginLeft: '2%', height: '1.7vw', marginTop: mgtop ? '1.5%' : null}}>

        <div 
          className={this.state.lastEdited === planData.shifts[0].dagdienst_id + i || this.state.userHoursScheduleID === parseInt(planData.shifts[0].dagdienst_id) ? `plannerN_shiftContainer lastEdited ${this.state.selectedBar === 'r' + planData.shifts[0].dagdienst_id + i ? "blink" : ""}` : `plannerN_shiftContainer ${this.state.selectedBar === 'r' + planData.shifts[0].dagdienst_id + i ? "blink" : ""}`} 
          style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.20)}}
          draggable={this.state.showPlanMenu[planData.shifts[0].dagdienst_id] ? 'false' : 'true'}
          onDragStart={(event) => this.onDragStartUserShift(event, i, planData.shifts[0], planKey)}
          onDrop={(event) => this.onDrop(event, i, planData.shifts[0])}
          onDragOver={(event) => this.onDragOver(event, i)}
          onDragLeave={(event) => this.onDragLeave(event)}
          // onClick={(event) => this.onClickUserShift(i, planData, event)}
        >

          <div className='plannerN_shiftColumn1' style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.5)}}>

            {/* <span style={{color: Constants.getContrastPlan(`#${functionColor}`)}}>{functionName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span> */}
            {/* <span style={{color: Constants.getContrastPlan(`#${functionColor}`)}}>{planData.count}</span> */}
            <input
              ref={(ref) => this.planGroupRef[planKey]=ref}
              className='plannerN_inputBoxAmount planTimeAsset'
              style={{color: '#282828'}}
              maxLength={2}
              defaultValue={planData.count}
              onKeyDown={(event) => this.handleKeyPlanGroup(event, planKey)}
              onBlur={(e) => this.savePlanGroup(e, planData, planKey)}
              onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
            />

          </div>

          <div className='plannerN_shiftColumn2'>

            {/* <div className='plannerN_shiftRow'>

              <span className='plannerN_nameText'>{functionName}</span>

            </div> */}

            <div className='plannerN_shiftRow'>

              {this.state.editPlan === true ?
                <input
                  ref={(ref) => this.timeRef[planData.shifts[0].dagdienst_id]=ref}
                  className='plannerN_inputBoxTime_shifts planTimeAsset'
                  defaultValue={this.state.planInputs[planData.shifts[0].dagdienst_id]}
                  onKeyDown={(event) => this.handleKey(event, planData.shifts[0])}
                  onBlur={(e) => this.saveTimeDirectly(e, planData.shifts[0], false, planData)}
                  onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
                  onChange={(event) => this.onChangeTime(event, planData.shifts[0])}
                  disabled={this.state.selectedBar === 'r' + planData.shifts[0].dagdienst_id + i}
                  type={'text'}
                  style={{color: '#04163b'}}
                />
              :
                <div className='plannerN_inputBoxTime_shifts planTimeAsset' onClick={() => this.onClickPlanTime(planData.shifts[0], i, true)}>
                  <span className='planTimeAsset' style={{color: '#04163b'}}>{this.state.planInputs[planData.shifts[0].dagdienst_id]}</span>
                </div>
              }

              {planData.shifts[0].dagdienst_pauze !== '00:00:00' ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-coffee'></i>
                      <span style={{marginLeft: '0.3vw'}}>{planData.shifts[0].dagdienst_pauze.substr(0,5)} pauze</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginLeft: 4}}
                >
                  <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                </Tooltip>
              : null}

              {skills !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-graduation-cap'></i>
                      <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginLeft: 4}}
                >
                  <i className='fal fa-graduation-cap' style={{fontSize: '0.6vw'}} />
                </Tooltip>
              : null}

              {partAfk !== null ?
                  <span style={{color: `#${partColor}`, fontSize: '0.6vw', marginLeft: 4}}>{partAfk}</span>
              : 
              null}

            </div>

          </div>

          <div className='plannerN_shiftColumn3'>

            <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[planData.shifts[0].dagdienst_id] ? this.closePlanMenu(planData.shifts[0].dagdienst_id) : this.openPlanMenu(planData.shifts[0].dagdienst_id)} />

          </div>

        </div>

        {this.state.showPlanMenu[planData.shifts[0].dagdienst_id] === true ?

          this.renderPlanMenu(planData.shifts[0], i === 5 || i === 6, partID, skills, planData)

        : null}

      </div>

    );

  }

  getPlannerBars() {

    let plannerDataDay = null;

    let plannerBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < (this.plannerData.mon.length)) {
      maxNotFilled = this.plannerData.mon.length;
    }
    if(maxNotFilled < (this.plannerData.tue.length)) {
      maxNotFilled = this.plannerData.tue.length;
    }
    if(maxNotFilled < (this.plannerData.wed.length)) {
      maxNotFilled = this.plannerData.wed.length;
    }
    if(maxNotFilled < (this.plannerData.thu.length)) {
      maxNotFilled = this.plannerData.thu.length;
    }
    if(maxNotFilled < (this.plannerData.fri.length)) {
      maxNotFilled = this.plannerData.fri.length;
    }
    if(maxNotFilled < (this.plannerData.sat.length)) {
      maxNotFilled = this.plannerData.sat.length;
    }
    if(maxNotFilled < (this.plannerData.sun.length)) {
      maxNotFilled = this.plannerData.sun.length;
    }

    let maxBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

      for(let key in this.functionsData) {
      let count = 0;

      //monday
      for(let key2 in this.plannerData.mon) {
        if(this.functionsData[key].functie_id === this.plannerData.mon[key2].functie_id) {
          count++;
        }
      }
      maxBars.mon.push({ functionID: this.functionsData[key].functie_id, max: count });

      //tuesday
      count = 0;
      for(let key2 in this.plannerData.tue) {
        if(this.functionsData[key].functie_id === this.plannerData.tue[key2].functie_id) {
          count++;
        }
      }
      maxBars.tue.push({ functionID: this.functionsData[key].functie_id, max: count });

      //wednesday
      count = 0;
      for(let key2 in this.plannerData.wed) {
        if(this.functionsData[key].functie_id === this.plannerData.wed[key2].functie_id) {
          count++;
        }
      }
      maxBars.wed.push({ functionID: this.functionsData[key].functie_id, max: count });

      //thursday
      count = 0;
      for(let key2 in this.plannerData.thu) {
        if(this.functionsData[key].functie_id === this.plannerData.thu[key2].functie_id) {
          count++;
        }
      }
      maxBars.thu.push({ functionID: this.functionsData[key].functie_id, max: count });

      //friday
      count = 0;
      for(let key2 in this.plannerData.fri) {
        if(this.functionsData[key].functie_id === this.plannerData.fri[key2].functie_id) {
          count++;
        }
      }
      maxBars.fri.push({ functionID: this.functionsData[key].functie_id, max: count });

      //saturday
      count = 0;
      for(let key2 in this.plannerData.sat) {
        if(this.functionsData[key].functie_id === this.plannerData.sat[key2].functie_id) {
          count++;
        }
      }
      maxBars.sat.push({ functionID: this.functionsData[key].functie_id, max: count });

      //sunday
      count = 0;
      for(let key2 in this.plannerData.sun) {
        if(this.functionsData[key].functie_id === this.plannerData.sun[key2].functie_id) {
          count++;
        }
      }
      maxBars.sun.push({ functionID: this.functionsData[key].functie_id, max: count });
    }

    let maxBars2 = [];

    for(let key in this.functionsData) {
      let max = 0;

      for(let key2 in maxBars) {
        let tempMaxBars = maxBars[key2];
        for(let key3 in tempMaxBars) {
          if(this.functionsData[key].functie_id === tempMaxBars[key3].functionID) {
            if(max < tempMaxBars[key3].max) {
              max = tempMaxBars[key3].max;
            }
          }
        }
      }
      maxBars2.push({ 
        functionID: this.functionsData[key].functie_id, 
        max: max, 
        functionColor: this.functionsData[key].functie_kleur,
        functionName: this.functionsData[key].functie_naam,
        index: this.functionsData.indexOf(this.functionsData[key]),
      });
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {

        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.mon;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.mon.push(
              <div className={'planner_sortIconContainer'}>
                {this.state.sortFunctions === true ?
                  <div>
                    <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(maxBars2[key2].index)} />
                    <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(maxBars2[key2].index)} />
                  </div>
                : null}
                <div 
                  className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                  onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                >
                  <span style={{color: Constants.getContrast(maxBars2[key2].functionColor)}}>{maxBars2[key2].functionName}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;  
              while(j < maxBars2[key2].max) {
                plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.mon.push(
                    <div className={'planner_sortIconContainer'}>
                      {this.state.sortFunctions === true ?
                        <div>
                          <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(maxBars2[key2].index)} />
                          <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(maxBars2[key2].index)} />
                        </div>
                      : null}
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      >
                        <span style={{color: Constants.getContrast(maxBars2[key2].functionColor)}}>{maxBars2[key2].functionName}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    </div>
                  ); //MAXBARS.functionname added and rounded corners
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.mon.push(
              <div className={'planner_sortIconContainer'}>
                {this.state.sortFunctions === true ?
                  <div>
                    <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(tempPlannerData[key].index)} />
                    <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(tempPlannerData[key].index)} />
                  </div>
                : null}
                <div 
                  className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
                  onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                >
                  <span style={{color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}>{tempPlannerData[key].functie_naam}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.mon.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 0, plannerBars.mon.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.mon.push(
                    <div className={'planner_sortIconContainer'}>
                      {this.state.sortFunctions === true ?
                        <div>
                          <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(maxBars2[key2].index)} />
                          <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(maxBars2[key2].index)} />
                        </div>
                      : null}
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      > 
                        <span style={{color: Constants.getContrast(maxBars2[key2].functionColor)}}>{maxBars2[key2].functionName}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i> 
                      </div>
                    </div>
                  ); //MAXBARS2[Key2].functionname added and rounded borders
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 1) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.tue;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.tue.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.tue.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                    >
                        <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    );
                    if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                      let j = 0;
                      while(j < maxBars2[key2].max) {
                        plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                        j++;
                      }
                    }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.tue.push(
              <div 
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)} 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.tue.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 1, plannerBars.tue.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.tue.push(
                    <div 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 2) {

        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.wed;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.wed.push(
              <div 
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.wed.push(
                    <div 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.wed.push(
              <div 
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)} 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.wed.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 2, plannerBars.wed.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.wed.push(
                    <div 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 3) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.thu;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.thu.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}} 
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.thu.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}} 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.thu.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}} 
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.thu.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 3, plannerBars.thu.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.thu.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 4) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.fri;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.fri.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.fri.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.fri.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.fri.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 4, plannerBars.fri.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.fri.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 5) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.sat;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.sat.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sat.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.sat.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.sat.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 5, plannerBars.sat.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sat.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 6) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        plannerDataDay = this.plannerData.sun;

        for(let key in this.functionsData) {

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.sun.push(
              <div className={'planner_collapseIconContainer'}>
                <div className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                  onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                >
                  <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sun.push(
                    <div className={'planner_collapseIconContainer'}>
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                      >
                        <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.sun.push(
              <div className={'planner_collapseIconContainer'}>
                <div 
                  className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                  onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
                >
                  <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            plannerBars.sun.push(this.getPlannerTouchBoxTable(tempPlannerData[key], 6, plannerBars.sun.length > 0));

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sun.push(
                    <div className={'planner_collapseIconContainer'}>
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                      >
                        <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }

      // let j = dayShiftDataDay.length + plannerDataDay.length;
      // while(j < maxNotFilled) {
      //   plannerDataBars.push(<div className='plannerN_touchBoxEmpty' style={{width: '96%', marginLeft: '2%', height: '1.7vw'}} />);
      //   j++;
      // }

    }

    return plannerBars;

  }

  renderPlanMenu(data, left, partID, skills, planGroup) {

    return (

      <div draggable='false' className={`planner_planMenu${left ? '_left' : ''}`} style={{width: '120%', right: left ? null : '-114%'}}>

        {/* <div className='planner_planMenu_triangle' /> */}

        {/* <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuEdit[data.dagdienst_id] === true ? 
              this.closePlanMenuEdit(data.dagdienst_id) 
            :
              this.openPlanMenuEdit(data.dagdienst_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-edit planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Aanpassen
            </div>
            <div className='planner_planMenu_row_sub3' style={this.state.showPlanMenuEdit[data.dagdienst_id] === true ? {color: '#47B7EB'} : null}>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>

          {this.state.showPlanMenuEdit[data.dagdienst_id] === true ?

            <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickTimeEdit(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Tijd
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickEditAll(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Functie / pauze / dag
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>
              
            </div>

          : null}

        </div> */}

        <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuBreaks[data.dagdienst_id] === true ? 
              this.closeBreaksMenuComp(data.dagdienst_id) 
            :
              this.openBreaksMenuComp(data.dagdienst_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-coffee planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Pauzes
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className={`fa${data.dagdienst_pauze !== '00:00:00' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuBreaks[data.dagdienst_id] === true ? {color: '#47B7EB'} : null}/>
            </div>
          </div>

          {this.state.showPlanMenuBreaks[data.dagdienst_id] === true ?

            this.renderBreaksMenu(data, left, planGroup)

          : null}

        </div>

        <div className='planner_planMenu_row' onClick={() => this.duplicateShift(data)}>
          <div className='planner_planMenu_row_sub1'>
            <i className='fal fa-clone planMenuAsset' />
          </div>
          <div className='planner_planMenu_row_sub2'>
            Dupliceren
          </div>
          <div className='planner_planMenu_row_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

        {Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuSpecial[data.dagdienst_id] === true ? 
                this.closePlanMenuSpecial(data.dagdienst_id) 
              :
                this.openPlanMenuSpecial(data.dagdienst_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-briefcase planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Bijzonderheden
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${partID !== null ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuSpecial[data.dagdienst_id] === true ? {color: '#47B7EB'} : null}/>
              </div>
            </div>

            {this.state.showPlanMenuSpecial[data.dagdienst_id] === true ?

              this.renderSpecialMenu(data, left, partID, planGroup)

            : null}

          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuComp[data.dagdienst_id] === true ? 
                this.closePlanMenuComp(data.dagdienst_id) 
              :
                this.openPlanMenuComp(data.dagdienst_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-graduation-cap planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Competenties
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${skills !== null && typeof skills !== 'undefined' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuComp[data.dagdienst_id] === true ? {color: '#47B7EB'} : null} />
              </div>
            </div>
            
            {this.state.showPlanMenuComp[data.dagdienst_id] === true ?

              this.renderMenuSkills(data, left, skills, planGroup)

            : null}

          </div>
        : null}

        <div className='planner_planMenu_deleteRow' onClick={() => this.deleteUserPlannerAlert(data)}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-trash-alt planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Verwijderen
          </div>
          <div className='planner_planMenu_deleteRow_sub3 planMenuAsset'>
            &nbsp;
          </div>
        </div>

      </div>

    );
    
  }

  async changeBreakSchedule(data, brk, add, planGroup) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    let promises = [];

    if(add === true) {
      for (const _plan of planGroup.shifts) {
        promises.push(
          APIEditDefaultDayShift.Request(
            data.dagdienst_dag,
            data.dagdienst_begin,
            data.dagdienst_eind,
            data.functie_id,
            _plan.dagdienst_id,
            brk.brk_tijd
          )
        );
      }
    } else {
      for (const _plan of planGroup.shifts) {
        promises.push(
          APIEditDefaultDayShift.Request(
            data.dagdienst_dag,
            data.dagdienst_begin,
            data.dagdienst_eind,
            data.functie_id,
            _plan.dagdienst_id,
            '00:00:00'
          )
        );
      }
    }

    await Promise.all(promises);

    await this.reloadScreen();

  }

  renderBreaksMenu(data, left, planGroup) { // data = plannerid data

    let particularities = [];

    for (const part of this.state.breaks) {

      if (part.brk_tijd === data.dagdienst_pauze) {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeBreakSchedule(data, null, false, planGroup)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{part.brk_tijd.substr(0,5)}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeBreakSchedule(data, part, true, planGroup)}>
            <div className='planner_planMenuSub_row_sub1'>
              {part.brk_tijd.substr(0,5)}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props._selectMenu('breaks')} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer pauzecodes
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        {data.dagdienst_pauze !== '00:00:00' ?
          <div className='planner_planMenuSubInfo_row' onClick={() => this.props._selectMenu('breaks')} style={{marginBottom: '4%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Huidige pauze: {data.dagdienst_pauze.substr(0,5)}
            </div>
          </div>
          : null}

        <div className='divider' />

        {particularities}
      </div>
    );

  }

  async changeParticularitySchedule(bid, rid, add, planGroup) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    this.setState({ reloading: true });

    if(add === true) {
      for (const _plan of planGroup.shifts) {
        await APIDeletePartDayShift.Request(_plan.dagdienst_id);
        await APIAddPartDayShift.Request(bid, _plan.dagdienst_id);
      }
    } else {
      for (const _plan of planGroup.shifts) {
        await APIDeletePartDayShift.Request(_plan.dagdienst_id);
      }
    }

    const partSchedules = await APIGetAllPartDayShifts.Request();
    const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');

    this.setState({ partSchedules: partSchedulesObj, reloading: false, }); 

  }

  renderSpecialMenu(data, left, partID, planGroup) { // data = plannerid data

    let particularities = [];

    for (const part of this.state.particularities) {

      if (part.bijz_id === partID) {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeParticularitySchedule(part.bijz_id, data.dagdienst_id, false, planGroup)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{part.bijz_naam}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeParticularitySchedule(part.bijz_id, data.dagdienst_id, true, planGroup)}>
            <div className='planner_planMenuSub_row_sub1'>
              {part.bijz_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'part' })}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer bijzonderheidstypes
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' />

        {particularities}
      </div>
    );

  }

  async changeSkillsSchedule(cid, rid, add, planGroup) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    this.setState({ reloading: true });

    if(add === true) {
      for (const _plan of planGroup.shifts) {
        await APIDeleteSkillDayShift.Request(cid, _plan.dagdienst_id);
        await APIAddSkillDayShift.Request(cid, _plan.dagdienst_id);
      }
    } else {
      for (const _plan of planGroup.shifts) {
        await APIDeleteSkillDayShift.Request(cid, _plan.dagdienst_id);
      }
    }

    const skillSchedules = await APIGetAllSkillDayShifts.Request();
    const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');
    
    // const shifts = await APIGetDefaultDayShift.Request(this.state.shiftTemp);

    // this.plannerData.mon = [];
    // this.plannerData.tue = [];
    // this.plannerData.wed = [];
    // this.plannerData.thu = [];
    // this.plannerData.fri = [];
    // this.plannerData.sat = [];
    // this.plannerData.sun = [];

    // for(let key in shifts) {

    //   if(shifts[key].dagdienst_dag === '0') {
    //     this.plannerData.sun.push(shifts[key]);
    //     continue;
    //   }
    //   if(shifts[key].dagdienst_dag === '1') {
    //     this.plannerData.mon.push(shifts[key]);
    //     continue;
    //   }
    //   if(shifts[key].dagdienst_dag === '2') {
    //     this.plannerData.tue.push(shifts[key]);
    //     continue;
    //   }
    //   if(shifts[key].dagdienst_dag === '3') {
    //     this.plannerData.wed.push(shifts[key]);
    //     continue;
    //   }
    //   if(shifts[key].dagdienst_dag === '4') {
    //     this.plannerData.thu.push(shifts[key]);
    //     continue;
    //   }
    //   if(shifts[key].dagdienst_dag === '5') {
    //     this.plannerData.fri.push(shifts[key]);
    //     continue;
    //   }
    //   if(shifts[key].dagdienst_dag === '6') {
    //     this.plannerData.sat.push(shifts[key]);
    //     continue;
    //   }

    // }

    this.setState({ skillSchedules: skillSchedulesObj, reloading: false }); 

  }

  renderMenuSkills(data, left, skills, planGroup) { // data = plannerid data

    let toRender = [];

    for (const type of this.state.skills) {

      if (skills !== null && Constants.objectArrayContainsKey(skills, 'comp_naam', type.comp_naam)) {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeSkillsSchedule(type.comp_id, data.dagdienst_id, false, planGroup)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{type.comp_naam}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeSkillsSchedule(type.comp_id, data.dagdienst_id, true, planGroup)}>
            <div className='planner_planMenuSub_row_sub1'>
              {type.comp_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'skills' })}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer competenties
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' /> 

        {toRender}
      </div>
    );

  }

  async duplicateShift(data) {

    if (this.state.loading || this.state.reloading) {
      return;
    }

    this.closeAllPlanMenus();

    const added = await APIAddDefaultDayShift.Request(
      data.dagdienst_dag,
      data.dagdienst_begin,
      data.dagdienst_eind,
      data.functie_id,
      data.dagdienst_pauze,
      this.state.shiftTemp
    );

    if(added !== null) {
      this.setState({ editPlan: false });

      // copy bijzonderheden
      const partDayShift = this.state.partSchedules[data.dagdienst_id];
      if (typeof partDayShift !== 'undefined') {
        await APIAddPartDayShift.Request(partDayShift.bijz_id, added);
        const partSchedules = await APIGetAllPartDayShifts.Request();
        const partSchedulesObj = Constants.convertToObject(partSchedules, 'dagdienst_id');
        this.setState({ partSchedules: partSchedulesObj }); 
      }

      // copy skills
      const skillsDayShift = this.state.skillSchedules[data.dagdienst_id];
      if (typeof skillsDayShift !== 'undefined') {
        for (const skill of skillsDayShift) {
          await APIAddSkillDayShift.Request(skill.comp_id, added);
        }
        const skillSchedules = await APIGetAllSkillDayShifts.Request();
        const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'dagdienst_id');
        this.setState({ skillSchedules: skillSchedulesObj }); 
      }

      this.reloadScreen();
    } else {
      // console.log('error');
    }

  }

  deleteUserPlannerAlert(data) {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.closeAllPlanMenus();

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je deze standaard dienst wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteUserPlannerDirectly.bind(this, data),
      showAlert: true,
    });

  }

  async deleteUserPlannerDirectly(data) {

    await APIDeleteDefaultDayShift.Request(data.dagdienst_id);

    this.reloadScreen();

  }

  closeAllPlanMenus() {

    this.closeAllPlanMenuSubs();

    // add listener
    //window.removeEventListener("click", this.clickOutsideMenu);

    let showPlanMenu = this.state.showPlanMenu;

    for (const key in showPlanMenu) {
      showPlanMenu[key] = false;
    }

    this.setState({showPlanMenu: showPlanMenu, showPlanMenuOuter: false});

  }

  closeAllPlanMenuSubs() {

    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        showPlanMenuEdit[plan.dagdienst_id] = false;
        showPlanMenuSpecial[plan.dagdienst_id] = false;
        showPlanMenuComp[plan.dagdienst_id] = false;
        showPlanMenuBreaks[plan.dagdienst_id] = false;
      }
    }
    
    this.setState({
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  closePlanMenuEdit(rid) {
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    showPlanMenuEdit[rid] = false;
    this.setState({ showPlanMenuEdit: showPlanMenuEdit });
  }

  openPlanMenuEdit(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    showPlanMenuEdit[rid] = true;
    this.setState({ showPlanMenuEdit: showPlanMenuEdit });
  }

  closePlanMenuSpecial(rid) {
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    showPlanMenuSpecial[rid] = false;
    this.setState({ showPlanMenuSpecial: showPlanMenuSpecial });
  }

  openPlanMenuSpecial(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    showPlanMenuSpecial[rid] = true;
    this.setState({ showPlanMenuSpecial: showPlanMenuSpecial });
  }

  closePlanMenuComp(rid) {
    let showPlanMenuComp = this.state.showPlanMenuComp;
    showPlanMenuComp[rid] = false;
    this.setState({ showPlanMenuComp: showPlanMenuComp });
  }

  openPlanMenuComp(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuComp = this.state.showPlanMenuComp;
    showPlanMenuComp[rid] = true;
    this.setState({ showPlanMenuComp: showPlanMenuComp });
  }

  closeBreaksMenuComp(rid) {
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;
    showPlanMenuBreaks[rid] = false;
    this.setState({ showPlanMenuBreaks: showPlanMenuBreaks });
  }

  openBreaksMenuComp(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;
    showPlanMenuBreaks[rid] = true;
    this.setState({ showPlanMenuBreaks: showPlanMenuBreaks });
  }

  closePlanMenu(rid) {

    this.closeAllPlanMenuSubs();

    let showPlanMenu = this.state.showPlanMenu;

    showPlanMenu[rid] = false;

    this.setState({ showPlanMenu: showPlanMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  openPlanMenu(rid) {

    this.closeAllPlanMenus();

    this.fromPlanner = [];
    this.setState({ selectedBar: '' });

    // add listener
    //window.addEventListener("click", this.clickOutsideMenu);

    let showPlanMenu = this.state.showPlanMenu;

    showPlanMenu[rid] = true;

    this.setState({ showPlanMenu: showPlanMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  onClickTimeEdit(data) {

    this.closeAllPlanMenus();

    Constants.defaultAlert(
      this,
      'Melding',
      'Je kunt begin- en eindtijden snel wijzigen door erop te klikken en een nieuwe tijd in te voeren'
    );

    if(this.state.editPlan === false) {

      this.setState({editPlan: true},
        () => {
          this.timeRef[data.dagdienst_id].focus();
        }
      );

    } else {

      this.timeRef[data.dagdienst_id].focus();

    }

  }

  onClickEditAll(data) {

    this.closeAllPlanMenus();

    this.openShiftsModal(1, data);

  }

  openModeMenu() {

    this.setState({
      showModeMenu: !this.state.showModeMenu,
    });

  }

  // getShiftTemplates() {

  //   let toReturn = [];

  //   for (const temp of this.state.shiftTemplates) {

  //     toReturn.push(
  //       <div className='planner_optionButtonShifts_menu_sub' key={temp.dagdienstsjabloon_id}>
  //         <div className='planner_optionButtonShifts_menu_sub_flex1' onClick={() => this.selectTemplate(temp.dagdienstsjabloon_id)}>
  //           <i className='fal fa-copy' style={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id) ? {color: Colors.color.greenFilledIn} : null}></i>
  //         </div>
  //         <div className='planner_optionButtonShifts_menu_sub_flex2' onClick={() => this.selectTemplate(temp.dagdienstsjabloon_id)}>
  //           <span style={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id) ? {color: Colors.color.greenFilledIn} : null}>{temp.dagdienstsjabloon_naam}</span>
  //         </div>
  //         <div className='planner_optionButtonShifts_menu_sub_flex1_1' onClick={() => this.openShiftTempModal(1, temp)}>
  //           <i className='fal fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}}></i>
  //         </div>
  //       </div>
  //     )

  //   }

  //   return toReturn;

  // }

  openShiftTempModal(mode, data) {

    this.setState({
      showSettingsMenu: false,
      showTempModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  closeAllPlanMenusOutside(e) {

    if(e.target.className.includes('planner_planMenu') === false && e.target.className.includes('planner_arrowContainer') === false
      && e.target.className.includes('fas fa-caret-down') === false && e.target.className.includes('planMenuAsset') === false
      && e.target.className.includes('planner_table_nameContainer2_name_sub2') === false && e.target.className.includes('planner_userMenu') === false) {
      this.closeAllPlanMenus();
    }

    if(e.target.className.includes('settings_Asset2') === false && e.target.className.includes('planner_settingsButton') === false
      && e.target.className.includes('alertModal') === false) {
      this.setState({
        showSettingsMenu: false,
      });
    }

  }

  getShiftTemplates() {

    let toReturn = [];

    for (const temp of this.state.shiftTemplates) {

      toReturn.push(
        <option key={'shiftTemp' + temp.dagdienstsjabloon_id} value={temp.dagdienstsjabloon_id} selected={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id)}>{temp.dagdienstsjabloon_naam}</option>
        // <div key={temp.dagdienstsjabloon_id} className='planner_settingsMenu_row settings_Asset2' onClick={() => this.selectTemplate(temp.dagdienstsjabloon_id)}>
        //   <div className='planner_settingsMenu_row_sub1 settings_Asset2'>
        //     <i className='fal fa-copy settings_Asset2' style={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id) ? {color: '#47B7EB'} : null} />
        //   </div>
        //   <div className='planner_settingsMenu_row_sub2 settings_Asset2' style={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id) ? {color: '#47B7EB'} : null}>
        //     {temp.dagdienstsjabloon_naam}
        //   </div>
        //   <div className='planner_settingsMenu_row_sub3 settings_Asset2'>
        //     {this.state.shiftTemp !== parseInt(temp.dagdienstsjabloon_id) ?
        //       <i className='fal fa-arrow-right settings_Asset2' />
        //     : null}
        //   </div>
        // </div>
      )

    }

    return toReturn;

  }

  renderListPlanner() {

    let toRender = [];

    for (const func of this.functionsData) {

      // let functionPlannerCount = {};

      // for (const dayKey in this.plannerData) {
      //   for (const plan of this.plannerData[dayKey]) {
      //     if (plan.functie_id === func.functie_id) { //found a plan with same function
      //       if (typeof functionPlannerCount[func.functie_id] === 'undefined') {
      //         functionPlannerCount[func.functie_id] = 0;
      //       }
      //       functionPlannerCount[func.functie_id] += 1;
      //     }
      //   }
      // }

      // if (typeof functionPlannerCount[func.functie_id] !== 'undefined' && functionPlannerCount[func.functie_id] > 0) {
        toRender.push(
          <div className='plannerN_function_row'>
            {this.renderDaysInFunction(func)}
          </div>
        );
      // }

    }

    return toRender;

  }

  renderDaysInFunction(func) {

    let toRender = [];

    for (let i = 0; i < 7; i++) { // days

      const funcColor = `#${func.functie_kleur}`;
      const funcName = `${func.functie_naam}`;

      toRender.push(

        <div 
          className='plannerN_function_day_column' 
          onDragOver={(event) => this.onDragOverColumn(event, i)}
          onDragLeave={(event) => this.onDragLeaveColumn(event)}
          //onDragExit={(event) => this.onDragLeaveColumn(event)}
          //onDragEnd={(event) => this.onDragLeaveColumn(event)}
          onDrop={(event) => this.onDrop(event, i, null, func.functie_id)}
        >

          <div 
            className='planner_headerText_list' 
            onDragEnter={(event) => this.onDragOver(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onDragExit={(event) => this.onDragLeave(event)}
            onDragEnd={(event) => this.onDragLeave(event)}
            onDrop={(event) => this.onDrop(event, i, null, func.functie_id)}
            onClick={() => this.onClickHeader(i, func.functie_id)}
            style={{backgroundColor: Constants.hexToRgbA(funcColor, 0.5), borderTopLeftRadius: i === 0 ? '0.5vw' : 0, borderBottomLeftRadius: i === 0 ? '0.5vw' : 0, borderTopRightRadius: i === 6 ? '0.5vw' : 0, borderBottomRightRadius: i === 6 ? '0.5vw' : 0,}}
          >

            {i === 0 ?
              <span style={{marginLeft: '0.5vw'}}>{funcName}</span>
            :
              <span>&nbsp;</span>
            }

            <i className='fa fa-plus planner_headerText_list-icon' />
            <i className='fa fa-plus planner_headerText_list-iconSmall' />

          </div>

          {this.renderPlannerInDay(i, func)}

        </div>

      );

    }

    return toRender;

  }

  renderPlannerInDay(i, func) {

    let toRender = [];
    const dayKey = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : i === 6 ? 'sun' : 'mon';

    let _newPlannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    for (const plan of this.plannerData[dayKey]) {
      let skillsString = ``;
      const skillDayShifts = this.state.skillSchedules[plan.dagdienst_id];
      if (typeof skillDayShifts !== 'undefined') {
        for (const skill of skillDayShifts) {
          skillsString += `${skill.comp_id}`;
        }
      } else {
        skillsString = `null`;
      }
      const partDayShift = typeof this.state.partSchedules[plan.dagdienst_id] !== 'undefined' ? this.state.partSchedules[plan.dagdienst_id] : 'null';
      const planKey = `${plan.dagdienst_dag}${plan.dagdienst_begin}${plan.dagdienst_eind}${plan.dagdienst_pauze}${plan.functie_id}${partDayShift.bijz_id}${skillsString}`;
      if (typeof _newPlannerData[dayKey][planKey] === 'undefined') {
        _newPlannerData[dayKey][planKey] = {
          count: 1,
          fid: plan.functie_id,
          shifts: [plan],
        };
      } else {
        _newPlannerData[dayKey][planKey].count += 1;
        _newPlannerData[dayKey][planKey].shifts.push(plan);
      }
    }

    for (const planKey in _newPlannerData[dayKey]) { // planner

      const planGroup = _newPlannerData[dayKey][planKey];

      if (planGroup.fid !== func.functie_id) {
        continue;
      }

      toRender.push(this.getPlannerTouchBoxTable(planGroup, i, toRender.length > 0, planKey));

    }

    return toRender;

  }

  render() {

    //const plannerBars = this.getPlannerBars();

    //const top = (this.state.shiftTemplates.length * 2.1) + 2.1 + 2.1;

    return (
        <div className='shifts' onClick={(e) => this.closeAllPlanMenusOutside(e)}>

          {this.state.showAlert === true ?
                
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null
              
          }

          {this.state.showModal === true ?
                
            <ShiftsModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _modalTimes={this.timesData}
              _modalFunctions={this.functionsData}
              _shiftTemp={this.state.shiftTemp}
              _reloadScreen={this.reloadScreen.bind(this)}
            />

          :

            null
              
          }

          {this.state.showTempModal === true ?
                
            <ShiftTempModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _duplicateTemplate={this.duplicateTemplate.bind(this)}
              _reloadScreen={this.reloadScreen.bind(this)}
            />

          :

            null
              
          }

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

          <div className={`${this.state.reloading === true ? 'planner_reloading' : ''}`} style={{width: '100%', marginLeft: 0}}>

            {this.state.reloading === true ?
                      
              // lds-dual-ring 
              <div className='loader2' /> 

            :

              null

            }

              {/* {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              } */}

              <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                {this.state.shiftTemp !== null ?
                  <div className="planner_new_addButton" style={{marginLeft: 0, width: '10vw'}} onClick={() => this.openShiftTempModal(1, this.state.shiftTempObj)}>
                    {/* <i className={'fal fa-plus'} /> */}
                    Sjabloon bewerken
                  </div>
                : null}

                <div className="planner_new_addButton" style={{marginLeft: '0.6vw', width: '10vw'}} onClick={() => this.openShiftTempModal(0)}>
                  {/* <i className={'fal fa-plus'} /> onClick={() => this.openShiftsModal(0)} */} 
                  Sjabloon toevoegen
                </div>

                {this.state.shiftTemp !== null ?
                  <div className="planner_new_addButton" style={{marginLeft: '0.6vw', width: '10vw'}} onClick={() => this.openShiftTempModal(2)}>
                    {/* <i className={'fal fa-plus'} /> */}
                    Sjabloon dupliceren
                  </div>
                : null}

              </div>

              <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                <div style={{width: '12%'}}>
                  <select
                      name="Selecteer een sjabloon"
                      className={'planner_dropdown'}
                      onChange={(event) => this.selectTemplate(event)}
                  >
                      <option value={'null'} disabled>Standaard diensten</option>
                      {/* <option value={'TABLE'} selected={this.state.display === 'TABLE'}>Simpel</option>
                      <option value={'XCL'} selected={this.state.display === 'XCL'}>Tabel</option>
                      {Data.data.appOptions.availability !== 'SCHOOL' ?
                        <option value={'LIST'} selected={this.state.display === 'LIST'}>Lijst</option>
                      : null} */}
                      {this.getShiftTemplates()}
                  </select>
                </div>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <span>Diensten kopiëren of verplaatsen</span>
                    </div>
                  )}
                  position={'top'}
                  theme='kodict'
                  delay={300}
                  hideDelay={0}
                >
                  {this.state.copyShifts === true ?
                    <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.setState({ copyShifts: false })}>
                      <i className='fas fa-check-circle' />
                      <span><i className={'far fa-copy'} /></span>
                    </div>
                  :
                    <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.setState({ copyShifts: true })}>
                      <i className='fal fa-circle' />
                      <span><i className={'fal fa-copy'} /></span>
                    </div>
                  }
                </Tooltip>

              </div>

              {this.state.shiftTemp !== null ?

                <div className='plannerN_functions_container'>

                  {this.renderListPlanner()}

                </div>

                // <div className='planner_listDisplay'>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Maandag</div>

                //     <div>{plannerBars.mon}</div>

                //   </div>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Dinsdag</div>

                //     <div>{plannerBars.tue}</div>

                //   </div>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Woensdag</div>

                //     <div>{plannerBars.wed}</div>

                //   </div>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Donderdag</div>

                //     <div>{plannerBars.thu}</div>

                //   </div>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Vrijdag</div>

                //     <div>{plannerBars.fri}</div>

                //   </div>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Zaterdag</div>

                //     <div>{plannerBars.sat}</div>

                //   </div>

                //   <div className='planner_dayContainer'>

                //     <div className='planner_dateText stickyTopShifts' style={{width: '103%', backgroundColor: 'white'}}>Zondag</div>

                //     <div>{plannerBars.sun}</div>

                //   </div>

                // </div>

              : null}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Shifts;