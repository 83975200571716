import React from "react";
import ReactExport from "react-data-export";

import '../../pages/schedule/schedule.scss';
import '../../pages/planner/planner.scss';
import Constants from "../../constants/Constants";
import __ from "../../constants/__";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let allDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let monDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let tueDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let wedDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let thuDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let friDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let satDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

let sunDataSet = [
    {
        columns: [
            {title: __('misc_name'), width: {wch: 25}},
            {title: __('misc_date'), width: {wch: 12}},
            {title: __('misc_startTime'), width: {wch: 10}},
            {title: __('misc_endTime'), width: {wch: 10}},
            {title: __('misc_break'), width: {wch: 10}},
            {title: __('misc_job'), width: {wch: 25}},
        ],
        data: [],
    },
];

export default class ScheduleDownloadJob extends React.Component {

    render() {

        allDataSet[0].data = [];
        monDataSet[0].data = [];
        tueDataSet[0].data = [];
        wedDataSet[0].data = [];
        thuDataSet[0].data = [];
        friDataSet[0].data = [];
        satDataSet[0].data = [];
        sunDataSet[0].data = [];

        let monday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].mon.plans) {
                monday.push(plan);
            }
        }

        monday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_monday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of monday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            monDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        let tuesday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].tue.plans) {
                tuesday.push(plan);
            }
        }

        tuesday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_tuesday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of tuesday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            tueDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        let wednesday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].wed.plans) {
                wednesday.push(plan);
            }
        }

        wednesday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_wednesday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of wednesday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            wedDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        let thursday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].thu.plans) {
                thursday.push(plan);
            }
        }

        thursday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_thursday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of thursday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            thuDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        let friday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].fri.plans) {
                friday.push(plan);
            }
        }

        friday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_friday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of friday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            friDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        let saturday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].sat.plans) {
                saturday.push(plan);
            }
        }

        saturday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_saturday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of saturday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            satDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        let sunday = [];

        for (const accKey in this.props.dataSet) {
            for (const plan of this.props.dataSet[accKey].sun.plans) {
                sunday.push(plan);
            }
        }

        sunday.sort((a, b) => (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.rooster_begin > b.rooster_begin) || (a.functie_naam === b.functie_naam && a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind)) ? 1 : -1);

        allDataSet[0].data.push([
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);
        allDataSet[0].data.push([
            {value: __('misc_sunday'), style: {font: {bold: true}}},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]);

        for(const data of sunday) {
            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            sunDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
            allDataSet[0].data.push([
                {value: userFullName},
                {value: data.rooster_datum},
                {value: data.rooster_begin.substring(0,5)},
                {value: data.rooster_eind.substring(0,5)},
                {value: data.rooster_pauze.substring(0,5)},
                {value: data.functie_naam, style: {font: {color: {rgb: Constants.getContrastHex(data.functie_kleur)}}, fill: {patternType: "solid", fgColor: {rgb: data.functie_kleur}}}},
            ]);
        }

        return (
            <ExcelFile
                element={
                    <div className='plannerZ_menuRowButton settings_Asset2' onClick={this.props.closeMenu}>
                      <div className='plannerZ_menuRowButton_sub1 settings_Asset2'>
                        <p><i className='far fa-sort-shapes-down settings_Asset2' aria-hidden={true} /></p>
                      </div>
                      <div className='plannerZ_menuRowButton_sub2 settings_Asset2'>
                        <span className='settings_Asset2'>{__('planner_web_menu_sortJob')}</span>
                      </div>
                      <div className='plannerZ_menuRowButton_sub3 settings_Asset2'>
                        <p><i className='far fa-chevron-right settings_Asset2' aria-hidden={true} /></p>
                      </div>
                    </div>

                }
                filename={`${__('misc_schedule')} - ${__('misc_week').toLowerCase()} ` + this.props.week + ` ` + this.props.year}
            >
                <ExcelSheet dataSet={allDataSet} name={__('misc_wholeWeek')}/>
                <ExcelSheet dataSet={monDataSet} name={__('misc_monday')}/>
                <ExcelSheet dataSet={tueDataSet} name={__('misc_tuesday')}/>
                <ExcelSheet dataSet={wedDataSet} name={__('misc_wednesday')}/>
                <ExcelSheet dataSet={thuDataSet} name={__('misc_thuday')}/>
                <ExcelSheet dataSet={friDataSet} name={__('misc_friday')}/>
                <ExcelSheet dataSet={satDataSet} name={__('misc_saturday')}/>
                <ExcelSheet dataSet={sunDataSet} name={__('misc_sunday')}/>
            </ExcelFile>
        );
    }
}