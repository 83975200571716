/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './holidayRequests.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import APIEditHoliday from '../../../classes/APIEditHoliday';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import KodictNotifications from '../../../constants/KodictNotifications';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIAddHoliday from '../../../classes/APIAddHoliday';
import APIADD from '../../../classes/global/APIADD';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';
import APIGetAllContracts from '../../../classes/APIGetAllContracts';
import APIGET from '../../../classes/global/APIGET';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIDELETE from '../../../classes/global/APIDELETE';

class AddHolidayModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.lastDeleted = null;
    this.lastVal = '';
    
    this.state = {

        loading: true,

        personID: this.props._individual !== true ? '' : this.props._individual_userID,

        selectedHoliday: '0',
        selectedType: null,

        startDate: null,
        startText: '',

        endDate: null,
        endText: '',

        comment: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

        oneDay: true,

        accounts: [],
        contracts: {},
        infoExtra: {},

        absAmount: 0,

        workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
        workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
        workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
        workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
        workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
        workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
        workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

        leaveTypes: [],

    };

  }

  async componentDidMount() {

    let [
      infoExtra,
      accounts,
      contracts,
      leaveTypes,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, `ORDER BY vt_naam`),
    ]);

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamUsers = await APIGetTeamUsers.Request();
      accounts = Constants.getTeamAccounts(this.teamUsers, accounts);

    }

    let selectedType = null;

    if (leaveTypes.length > 0) {
      selectedType = leaveTypes[0].vt_id;
    }

    if (this.modalData !== null) {

      if (this.modalData.vakantie_geaccepteerd === '1') {

        const abs = await APIGET.Request(`SELECT vs_aantal`, `FROM VerlofSaldo`, null, `WHERE vs_vakantie_id = ${this.modalData.vakantie_id}`, null, null);

        if (Constants.isEmpty(this.absAmount) === false) {
          this.absAmount.value = abs.length > 0 ? Constants.msToTime2(parseFloat(abs[0].vs_aantal) * 60 * 60 * 1000) : Constants.msToTime2(0);
        }

        this.setState({ absAmount: abs.length > 0 ? parseFloat(abs[0].vs_aantal) : 0 });

      }

      this.setDate1(Constants.stringToDate(this.modalData.vakantie_begin));
      this.setDate2(Constants.stringToDate(this.modalData.vakantie_eind));

      selectedType = Constants.isEmpty(this.modalData.vakantie_vt_id) === false ? this.modalData.vakantie_vt_id : null;

      this.setState({
        personID: this.modalData.info_id,
        selectedHoliday: this.modalData.vakantie_begin === this.modalData.vakantie_eind ? '0' : '1',
        oneDay: this.modalData.vakantie_begin === this.modalData.vakantie_eind,
        comment: this.modalData.vakantie_opmerking,
      });

    }

    this.setState({ accounts: accounts, contracts: contractsObj, infoExtra: infoExtraObj, leaveTypes: leaveTypes, selectedType: selectedType, loading: false, });

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateText = Constants.dateToString(date);

    this.setState({
        startDate: date,
        startText: dateText,
        showError: false,
    }, () => {

      if (this.state.oneDay === true) {

        this.setDate2(date);

      } else {

        if (Constants.isEmpty(this.state.personID) === false && Constants.isEmpty(this.state.endDate) === false) {

          const startDate = this.state.startDate;
          const endDate = this.state.endDate;

          let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

          const userHasContract = Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) !== null || Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.endDate) !== null;
          const contract = Constants.getCurrentContract(this.state.contracts[this.state.personID], startDate) !== null ? Constants.getCurrentContract(this.state.contracts[this.state.personID], startDate) : Constants.getCurrentContract(this.state.contracts[this.state.personID], endDate);

          // SET NEW WORKDAYHOURS LIKE CONTRACT
          workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;
      
          let fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;

          // SET NEW FULLWEEK LIKE CONTRACT
          if (Constants.isTrue(contract.contract_vaste_dagen)) {
            fullWeekHours = ( (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0) + (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0) + (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0) + (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0) + (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0) + (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0) + (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0) );
          }

          if (this.state.oneDay === true) { // verlof

            if (this.userWorksDay() === true) {

              if (Constants.isEmpty(this.absAmount) === false) {
                this.absAmount.value = Constants.msToTime2(workDayHours * 60 * 60 * 1000);
              }

              this.setState({ absAmount: workDayHours });

            } else {

              if (Constants.isEmpty(this.absAmount) === false) {
                this.absAmount.value = Constants.msToTime2(0);
              }

              this.setState({ absAmount: 0 });

            }

          } else {

            const infoExtra = this.state.infoExtra;

            let week = Constants.getWeekNumber2(startDate);

            let absAmount = 0;

            let absWeek = {};

            let index = 0;
            while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

              const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
              
              if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at startdate, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;

                    // SET NEW FULLWEEK LIKE CONTRACT
                    if (Constants.isTrue(contract.contract_vaste_dagen)) {
                      fullWeekHours = ( (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0) + (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0) + (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0) + (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0) + (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0) + (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0) + (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0) );
                    }
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // SET NEW WORKDAYHOURS LIKE CONTRACT
                  if (Constants.isTrue(contract.contract_vaste_dagen)) {
                    workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                  : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                  : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                  : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                  : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                  : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                  : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[this.state.personID]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[this.state.personID].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[this.state.personID].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[this.state.personID].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[this.state.personID].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[this.state.personID].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[this.state.personID].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[this.state.personID].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
                
              } else if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at startdate, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[this.state.personID]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[this.state.personID].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[this.state.personID].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[this.state.personID].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[this.state.personID].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[this.state.personID].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[this.state.personID].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[this.state.personID].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        //vHours.total += workDayHours;
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      //vHours.total += workDayHours;
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  //vHours.total += workDayHours;
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  //vHours.total = parseFloat(contract.contract_min);
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              }

              index++;

            }

            fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;

            if (Object.keys(absWeek).length > 0) {
              for (let key in absWeek) {
                absAmount += absWeek[key];
              }
            }

            if (Constants.isEmpty(this.absAmount) === false) {
              this.absAmount.value = Constants.msToTime2(absAmount * 60 * 60 * 1000);
            }

            this.setState({ absAmount: absAmount });

          }

        }

      }

    });

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateText = Constants.dateToString(date);

    this.setState({
        endDate: date,
        endText: dateText,
        showError: false,
    });

    if (Constants.isEmpty(this.state.personID) === false) {

      const startDate = this.state.startDate;
      const endDate = date;

      let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

      const userHasContract = Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) !== null || Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.endDate) !== null;
      const contract = Constants.getCurrentContract(this.state.contracts[this.state.personID], startDate) !== null ? Constants.getCurrentContract(this.state.contracts[this.state.personID], startDate) : Constants.getCurrentContract(this.state.contracts[this.state.personID], endDate);

      // SET NEW WORKDAYHOURS LIKE CONTRACT
      workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;
      
      let fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;

      // SET NEW FULLWEEK LIKE CONTRACT
      if (Constants.isTrue(contract.contract_vaste_dagen)) {
        fullWeekHours = ( (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0) + (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0) + (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0) + (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0) + (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0) + (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0) + (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0) );
      }

      if (this.state.oneDay === true) { // verlof

        if (this.userWorksDay() === true) {

          if (Constants.isEmpty(this.absAmount) === false) {
            this.absAmount.value = Constants.msToTime2(workDayHours * 60 * 60 * 1000);
          }

          this.setState({ absAmount: workDayHours });

        } else {

          if (Constants.isEmpty(this.absAmount) === false) {
            this.absAmount.value = Constants.msToTime2(0);
          }

          this.setState({ absAmount: 0 });

        }

      } else {

        const infoExtra = this.state.infoExtra;

        let week = Constants.getWeekNumber2(startDate);

        let absAmount = 0;

        let absWeek = {};

        let index = 0;
        while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

          const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
              
          if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {
              
            if (Constants.hasGeneralWorkDays() === true) {

              // get week at startdate, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

              if (week !== Constants.getWeekNumber2(currentDate)) {
                week = Constants.getWeekNumber2(currentDate);
                fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;

                // SET NEW FULLWEEK LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  fullWeekHours = ( (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0) + (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0) + (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0) + (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0) + (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0) + (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0) + (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0) );
                }
              }

              if (typeof absWeek[week] === 'undefined') {
                absWeek[week] = 0;
              }

              // SET NEW WORKDAYHOURS LIKE CONTRACT
              if (Constants.isTrue(contract.contract_vaste_dagen)) {
                workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                              : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                              : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                              : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                              : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                              : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                              : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
              }

              fullWeekHours -= workDayHours;

              if (fullWeekHours < parseFloat(contract.contract_uren)) {

                if (Constants.personHasWorkDays(infoExtra[this.state.personID]) === true) {

                  // person has working days, check work days personal and general
                  if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[this.state.personID].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[this.state.personID].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[this.state.personID].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[this.state.personID].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[this.state.personID].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[this.state.personID].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[this.state.personID].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                    absWeek[week] += workDayHours;
                  }

                } else { // just count the days
                  absWeek[week] += workDayHours;
                }

              }

            } else { // just count all days
              absWeek[week] += workDayHours;
            }

            if (absWeek[week] > parseFloat(contract.contract_uren)) {
              absWeek[week] = parseFloat(contract.contract_uren);
            }
                
          } else if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
            if (Constants.hasGeneralWorkDays() === true) {

              // get week at startdate, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

              if (week !== Constants.getWeekNumber2(currentDate)) {
                week = Constants.getWeekNumber2(currentDate);
                fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;
              }

              if (typeof absWeek[week] === 'undefined') {
                absWeek[week] = 0;
              }

              fullWeekHours -= workDayHours;

              if (fullWeekHours < parseFloat(contract.contract_uren)) {

                if (Constants.personHasWorkDays(infoExtra[this.state.personID]) === true) {

                  // person has working days, check work days personal and general
                  if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[this.state.personID].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[this.state.personID].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[this.state.personID].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[this.state.personID].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[this.state.personID].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[this.state.personID].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[this.state.personID].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                    //vHours.total += workDayHours;
                    absWeek[week] += workDayHours;
                  }

                } else { // just count the days
                  //vHours.total += workDayHours;
                  absWeek[week] += workDayHours;
                }

              }

            } else { // just count all days
              //vHours.total += workDayHours;
              absWeek[week] += workDayHours;
            }

            if (absWeek[week] > parseFloat(contract.contract_uren)) {
              //vHours.total = parseFloat(contract.contract_min);
              absWeek[week] = parseFloat(contract.contract_uren);
            }
              
          }

          index++;

        }

        fullWeekHours = this.getWorkDaysPersonWeek(this.state.personID) * workDayHours;

        if (Object.keys(absWeek).length > 0) {
          for (let key in absWeek) {
            absAmount += absWeek[key];
          }
        }

        if (Constants.isEmpty(this.absAmount) === false) {
          this.absAmount.value = Constants.msToTime2(absAmount * 60 * 60 * 1000);
        }

        this.setState({ absAmount: absAmount });

      }

    }

  }

  getWorkDays() {

    let days = 0;

    if (this.state.workWeekMonday === true) {
      days++;
    }
    if (this.state.workWeekTuesday === true) {
      days++;
    }
    if (this.state.workWeekWednesday === true) {
      days++;
    }
    if (this.state.workWeekThursday === true) {
      days++;
    }
    if (this.state.workWeekFriday === true) {
      days++;
    }
    if (this.state.workWeekSaturday === true) {
      days++;
    }
    if (this.state.workWeekSunday === true) {
      days++;
    }

    return days;

  }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze verlofaanvraag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteHoliday.bind(this),
        showAlert: true,
    });

  }

  editAlert(accepted) {

    this.setState({
        alertTitle: accepted === true ? 'Goedkeuren' : 'Afwijzen',
        alertBody: `Weet je zeker dat je deze verlofaanvraag wilt ${accepted === true ? 'goedkeuren' : 'afwijzen'}?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', accepted === true ? 'Goedkeuren' : 'Afwijzen'],
        alertButtonAction: this.editHoliday.bind(this, accepted),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkValidations() {

    if(Constants.isEmpty(this.state.personID) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen medewerker geselecteerd`,
        });
        return false;
      }

    if(this.state.startDate === null) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen ${this.state.oneDay === true ? 'datum' : 'begindatum'} ingevoerd`,
      });
      return false;
    }
    if(this.state.endDate === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen einddatum ingevoerd',
      });
      return false;
    }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  getLeaveTypes() {

    let functions = [];

    for(const leave of this.state.leaveTypes) {

        functions.push(
            <option value={leave.vt_id} selected={leave.vt_id === this.state.selectedType}>{leave.vt_naam}</option>
        );

    }

    return functions;

  }

  selectLeaveType(event) {

    this.setState({
        selectedType: event.target.value,
        showError: false,
    });

  }

  getPersons() {

    let functions = [];

    for(const person of this.state.accounts) {

        const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

        functions.push(
            <option value={person.info_id} selected={person.info_id === this.state.personID}>{userFullName}</option>
        );

    }

    return functions;

  }

  selectPerson(event) {

    this.setState({
        personID: event.target.value,
        showError: false,
    });

  }

  selectHoliday(event) {

    this.setState({
        selectedHoliday: event.target.value,
        oneDay: event.target.value === '0',
    });

  }

  async saveHoliday() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_info_id = NULL, rooster_publiceren = 3`, `WHERE rooster_info_id = ${this.state.personID} AND rooster_datum BETWEEN '${this.state.startText}' AND '${this.state.endText}'`);

    if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {
      await APIADD.Request(`INSERT INTO Vakantie`, `VALUES (NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.personID}, '${this.state.comment}', 1, ${this.state.selectedType === null ? 'NULL' : this.state.selectedType})`);
    } else {
      await APIADD.Request(`INSERT INTO Vakantie`, `VALUES (NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.personID}, '${this.state.comment}', 1, NULL)`);
    }

    const holidayID = await APIGET.Request(`SELECT vakantie_id`, `FROM Vakantie`, null,null,null, `ORDER BY vakantie_id DESC LIMIT 1`);

    if (this.state.startDate.getTime() === this.state.endDate.getTime()) {
      await APIADD.Request(`INSERT INTO VerlofUren`, `VALUES (NULL, ${holidayID[0].vakantie_id}, '00:00:00', '00:00:00')`);
    }

    const contract = Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) !== null ? Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) : Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.endDate);

    // if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && Constants.isTrue(contract.contract_opbouw_verlof)) {

    //   //let absenceHours = parseFloat(this.state.absAmount);

    //   const absenceHours2 = this.absAmount.value;
    //   let absenceHours = Constants.timeStringToFloat(absenceHours2, false);
    //   absenceHours = isNaN(absenceHours) ? parseFloat(absenceHours2) : absenceHours;
    //   absenceHours = isNaN(absenceHours) ? 0 : absenceHours;

    //   //  && absenceHours > 0
    //   if (isNaN(absenceHours) === false) {

    //     if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && Constants.isTrue(contract.contract_opbouw_verlof)) {
    //       await APIADD.Request(`INSERT INTO VerlofSaldo`, `VALUES (NULL, ${this.state.personID}, ${holidayID[0].vakantie_id}, ${absenceHours}, ${this.state.selectedType === null ? 'NULL' : this.state.selectedType})`);
    //     } else {
    //       await APIADD.Request(`INSERT INTO VerlofSaldo`, `VALUES (NULL, ${this.state.personID}, ${holidayID[0].vakantie_id}, ${absenceHours}, NULL)`);
    //     }

    //   }

    // }

    this.reloadScreen();
    this.closeModal();

  }

  async saveHoliday2() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    if (this.modalData.vakantie_geaccepteerd === '1') {
      await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_info_id = NULL, rooster_publiceren = 3`, `WHERE rooster_info_id = ${this.state.personID} AND rooster_datum BETWEEN '${this.state.startText}' AND '${this.state.endText}'`);
    }

    if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {
      await APIEDIT.Request(`UPDATE Vakantie`, `SET vakantie_begin = '${this.state.startText}', vakantie_eind = '${this.state.endText}', vakantie_opmerking = '${this.state.comment}', vakantie_vt_id = ${this.state.selectedType === null ? 'NULL' : this.state.selectedType}`, `WHERE vakantie_id = ${this.modalData.vakantie_id}`);
    } else {
      await APIEDIT.Request(`UPDATE Vakantie`, `SET vakantie_begin = '${this.state.startText}', vakantie_eind = '${this.state.endText}', vakantie_opmerking = '${this.state.comment}', vakantie_vt_id = NULL`, `WHERE vakantie_id = ${this.modalData.vakantie_id}`);
    }

    // if (this.state.startDate.getTime() === this.state.endDate.getTime()) {
    //   await APIADD.Request(`INSERT INTO VerlofUren`, `VALUES (NULL, ${holidayID[0].vakantie_id}, '00:00:00', '00:00:00')`);
    // }

    const contract = Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) !== null ? Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) : Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.endDate);

    if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && Constants.isTrue(contract.contract_opbouw_verlof) && this.modalData.vakantie_geaccepteerd === '1') {

      await APIDELETE.Request(`DELETE FROM VerlofSaldo`, `WHERE vs_vakantie_id = ${this.modalData.vakantie_id}`);

      //let absenceHours = parseFloat(this.state.absAmount);

      const absenceHours2 = this.absAmount.value;
      let absenceHours = Constants.timeStringToFloat(absenceHours2, false);
      absenceHours = isNaN(absenceHours) ? parseFloat(absenceHours2) : absenceHours;
      absenceHours = isNaN(absenceHours) ? 0 : absenceHours;
      
      // && absenceHours > 0
      if (isNaN(absenceHours) === false) {

        if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && Constants.isTrue(contract.contract_opbouw_verlof)) {
          await APIADD.Request(`INSERT INTO VerlofSaldo`, `VALUES (NULL, ${this.state.personID}, ${this.modalData.vakantie_id}, ${absenceHours}, ${this.state.selectedType === null ? 'NULL' : this.state.selectedType})`);
        } else {
          await APIADD.Request(`INSERT INTO VerlofSaldo`, `VALUES (NULL, ${this.state.personID}, ${this.modalData.vakantie_id}, ${absenceHours}, NULL)`);
        }

      }

    }

    this.reloadScreen();
    this.closeModal();

  }

  userWorksDay() {

    if (typeof this.state.infoExtra[this.state.personID] !== 'undefined') {

      const infoExtra = this.state.infoExtra[this.state.personID];

      if (this.state.startDate.getDay() === 1 && infoExtra.info2_werkdag_maandag !== '1') {
        return false;
      } else if (this.state.startDate.getDay() === 2 && infoExtra.info2_werkdag_dinsdag !== '1') {
        return false;
      } else if (this.state.startDate.getDay() === 3 && infoExtra.info2_werkdag_woensdag !== '1') {
        return false;
      } else if (this.state.startDate.getDay() === 4 && infoExtra.info2_werkdag_donderdag !== '1') {
        return false;
      } else if (this.state.startDate.getDay() === 5 && infoExtra.info2_werkdag_vrijdag !== '1') {
        return false;
      } else if (this.state.startDate.getDay() === 6 && infoExtra.info2_werkdag_zaterdag !== '1') {
        return false;
      } else if (this.state.startDate.getDay() === 0 && infoExtra.info2_werkdag_zondag !== '1') {
        return false;
      }

    }

    return true;

  }

  onChangeNumberDataFloat(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'overhours') {

      let absAmount = this.state.absAmount;

      if (nr !== '') {
        absAmount = nr;
      } else {
        absAmount = '';
      }

      if (Constants.isEmpty(this.absAmount) === false) {
        this.absAmount.value = Constants.msToTime2(absAmount * 60 * 60 * 1000);
      }
      
      this.setState({ absAmount: absAmount });

    }

  }

  onChangeTime(e, i2, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.absAmount.value;
    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times = e.target.value;
      // this.setState({ absAmount: times });
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   const pos = e.target.selectionStart;
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
    //   times[i2].value = e.target.value;
    //   e.target.selectionStart = pos;
    //   e.target.selectionEnd = pos;
    //   return false;
    // }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (this.lastDeleted === ':') {
          val = val.slice(0, -1);
        }

        // if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
        //   val = val + ':';
        // }

        // if (val.length === 2 && this.lastVal.length === 3) {
        //   val = val.slice(0, 1);
        // }

        // if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
        //   val = val + ' - ';
        // }

        // if (val.length > 7 && val.includes(' - ') === false) {
        //   val = val.slice(0, 5);
        //   val = val + ' - ';
        // }

        // if (val.length === 7 && (this.lastVal.length === 8)) {
        //   val = val.slice(0, -3);
        // }

        // if (this.isValidForSave(val2)) {

        //   if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
        //     val = val + ':';
        //   }

        //   if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
        //     val = val.slice(0, -1);
        //   }

        // }

        if (val.length > 7) {
          e.target.value = val;
          times = e.target.value;
          // this.setState({ absAmount: times });
          //this.setState({ timesStandard: times });
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times = e.target.value;
        // this.setState({ absAmount: times });
        // this.setState({ timesStandard: times });

    } else {
      e.target.value = this.lastVal;
      times = e.target.value;
      // this.setState({ absAmount: times });
      // this.setState({ timesStandard: times });
    }

  }

  isValidForSave(val) {

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    // if (val.length >= 0 && val.length <= 10) {
    //   val = val.substr(0, 5);
    // } else if (val.length >= 9) {
    //   val = val.substr(8, 13);
    // }

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs
      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 16 || e.keyCode === 186) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  render() {

    const contract = Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate);

    return (
        <div id="holidayRequestsModal" className="modal" onClick={(e) => e.target === document.getElementById("zholidayRequestsModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.modalData !== null ?
              <p>Verlof wijzigen</p>
            :
              <p>Verlof toevoegen</p>
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                  {this.modalData === null ?

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Soort verlof</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <select 
                              name="Selecteer een soort" 
                              className={'dropDownField'}
                              style={{marginTop: 0}} 
                              onChange={(event) => this.selectHoliday(event)}
                          >
                              <option value={'0'} selected={'0' === this.state.selectedHoliday}>Een vrije dag</option>
                              <option value={'1'} selected={'1' === this.state.selectedHoliday}>Vakantie</option>
                          </select>

                        </div>

                    </div>

                  : null}

                    {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?

                      <div className='modal-row' style={{marginTop: this.modalData === null ? '1vw' : null}}>

                          <div className='modal-rowSub1'>
                              <span><b>Verloftype</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                          <select 
                                name="Selecteer een type" 
                                className={'dropDownField'}
                                style={{marginTop: 0}} 
                                onChange={(event) => this.selectLeaveType(event)}
                                //disabled={this.modalData !== null}
                            >
                                <option value="" selected disabled hidden>Selecteer een type</option>
                                {this.getLeaveTypes()}
                            </select>

                          </div>

                      </div>

                    : null}

                    {this.props._individual !== true ?
                      <div className='modal-row' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1'>
                              <span><b>Medewerker</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <select 
                                name="Selecteer een medewerker" 
                                className={'dropDownField'}
                                style={{marginTop: 0}} 
                                onChange={(event) => this.selectPerson(event)}
                                disabled={this.modalData !== null}
                            >
                                <option value="" selected disabled hidden>Selecteer een medewerker</option>
                                {this.getPersons()}
                            </select>

                          </div>

                      </div>
                    : null}

                    {Constants.isEmpty(this.state.personID) === false ?

                      <div>

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>{this.state.oneDay === true ? 'Datum' : 'Begindatum'}</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                               <DatePicker
                                selected={this.state.startDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={(date) => this.setDate1(date)}
                                dateFormat="dd-MM-yyyy"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                locale={nl}
                                showWeekNumbers
                                selectsStart
                              />

                            </div>

                        </div>

                        {this.state.oneDay !== true ?
                          <div className='modal-row' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1'>
                                  <span><b>Einddatum</b></span>
                              </div>

                              <div className='modal-rowSub2'>

                                 <DatePicker
                                  selected={this.state.endDate}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  minDate={this.state.startDate}
                                  onChange={(date) => this.setDate2(date)}
                                  dateFormat="dd-MM-yyyy"
                                  className={'inputFieldTime'}
                                  placeholderText={'Selecteer een datum'}
                                  locale={nl}
                                  showWeekNumbers
                                  selectsEnd
                                />

                              </div>

                          </div>
                        : null}

                        {contract !== null && this.state.oneDay === false && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && (Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.startDate) !== null || Constants.getCurrentContract(this.state.contracts[this.state.personID], this.state.endDate) !== null) && Constants.isTrue(contract.contract_opbouw_verlof) && (this.modalData === null || (this.modalData !== null && this.modalData.vakantie_geaccepteerd === '1')) ?

                          <div className='modal-row' style={{marginTop: '2vw'}}>

                              <div className='modal-rowSub1'>
                                <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Op te nemen verlofuren</span>
                              </div>

                              <div className='modal-rowSub2'>

                                {this.state.oneDay === true ?
                                  <input
                                    ref={(ref) => this.absAmount=ref}
                                    className={'inputField2'}
                                    defaultValue={Constants.msToTime2(0)}
                                    onChange={(event) => this.onChangeTime(event, null, true)}
                                    onKeyDown={(event) => this.handleKey(event)}
                                    //value={Constants.msToTime2(this.state.absAmount * 60 * 60 * 1000)}
                                    placeholder={'Verlofuren'}
                                    maxLength={7}
                                  />
                                :
                                  <input
                                    ref={(ref) => this.absAmount=ref}
                                    className={'inputField2_absence'}
                                    disabled
                                    value={'≈ ' + (isNaN(this.state.absAmount) || this.state.absAmount === 0 ? Constants.msToTime2(0) : Constants.msToTime2(this.state.absAmount * 60 * 60 * 1000))}
                                  />
                                }

                                {/* <input
                                  className={'inputField2'}
                                  onChange={(event) => this.onChangeNumberDataFloat('overhours', 0, 999999999, event)}
                                  value={this.state.absAmount}
                                  placeholder={'Verlofuren'}
                                  type='number'
                                  step={0.01}
                                /> */}

                              </div>

                          </div>

                        : null}

                        <div className='modal-row2' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1_padding'>
                                <span><b>Opmerking</b></span>
                                <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Maximaal 250 tekens</span>
                            </div>

                            <div className='modal-rowSub2'>

                              <textarea  
                                className={'inputFieldBig'}
                                maxLength={250}
                                value={this.state.comment}
                                onChange={(event) => this.setState({ comment: event.target.value })}
                              />

                            </div>

                        </div>

                      </div>

                    : null}

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.modalData !== null ?      
                <div className='modal-submitButton' onClick={() => this.saveHoliday2()}>
                  <span>Wijzigen</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveHoliday()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default AddHolidayModal;