import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIAddOrganization {

    async Request(storecode, server, passAdmin) {
        
        const hash = CryptoJS.MD5(storecode + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://server-t01.tiemdo.com/phpglobalcalls/addOrganizationSQL.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${storecode}&passAdmin=${passAdmin}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()//
        } catch(err) {
        }

        if (sqlData === "1") {
            return true;

        } else if (sqlData === "0") {
            return false;

        } else {
            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIAddOrganization());