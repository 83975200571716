/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availabilitySchool.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';

import APIAddUnavailability from '../../classes/APIAddUnavailability';
import APIEditUnavailabilitySchool from '../../classes/APIEditUnavailabilitySchool';
import APIDeleteUnavailability from '../../classes/APIDeleteUnavailability';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';

class UnavailableSchoolModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    this.deadlines = this.props._deadlines;
    this.blockedDates = this.props._blockedDates;
    this.schoolHolidays = this.props._schoolHolidays;
    
    this.state = {

        loading: false,

        date: null,
        dateText: '',

        startTime: null,
        startText: '00:00',
        endTime: null,
        endText: '00:00',

        wholeDay: false,
        wholeDayDisabled: false,
        selectTimeDisabled: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        timeNow: new Date(),

    };

  }

  async componentDidMount() {

    this.setState({
        timeNow: await Constants.getDateNow(),
    });

    if(this.mode === 0) { //ADDING AVAILABILITY

        const startTime = new Date();
        startTime.setHours(6, 0);
    
        const endTime = new Date();
        endTime.setHours(6, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        //this.setState({ date: this.props._modalDate });
        
    }

    if(this.mode === 1) { //EDITING

        const startTime = new Date(2020, 1, 1, parseInt(this.modalData.start.substring(0, 2)), parseInt(this.modalData.start.substring(3, 5)), 0);
        const endTime = new Date(2020, 1, 1, parseInt(this.modalData.end.substring(0, 2)), parseInt(this.modalData.end.substring(3, 5)), 0);
  
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        const dateObject1 = new Date(this.modalData.year, this.modalData.month - 1, this.modalData.day);

        dateObject1.setDate(dateObject1.getDate() + 1);

        const dateText1 = dateObject1.toISOString().substring(0, 10);

        this.setState({
            date: new Date(this.modalData.year, this.modalData.month - 1, this.modalData.day),
            dateText: dateText1,
            wholeDay: this.modalData.start === '00:00:00' && this.modalData.end === '00:00:00',
            wholeDayDisabled: false,
            selectTimeDisabled: this.modalData.start === '00:00:00' && this.modalData.end === '00:00:00',
        });

    }

  }

  inSchoolHoliday(date) {

    const dateObject = date;

    for(let key in this.schoolHolidays) {

        const startDate = Constants.stringToDate(this.schoolHolidays[key].schoolvakantie_begin);
        const endDate = Constants.stringToDate(this.schoolHolidays[key].schoolvakantie_eind);

        if((dateObject.getDay() === startDate.getDay() && dateObject.getMonth() === startDate.getMonth() && dateObject.getFullYear() === startDate.getFullYear())
        || (dateObject.getDay() === endDate.getDay() && dateObject.getMonth() === endDate.getMonth() && dateObject.getFullYear() === endDate.getFullYear())) {
          return true;
        }
        if(dateObject.getTime() >= startDate.getTime() && dateObject.getTime() <= endDate.getTime()) {
            return true;
        }

    }

    return false;

}

  toggleSwitch() {

    const startTime = new Date();
    const endTime = new Date();

    if(this.state.wholeDay === true) {
      startTime.setHours(6, 0);
      endTime.setHours(6, 0);
    } else if(this.state.wholeDay === false) {
      startTime.setHours(0, 0);
      endTime.setHours(0, 0);
    }

    this.setStartTime(startTime);
    this.setEndTime(endTime);

    this.setState({wholeDay: !this.state.wholeDay, selectTimeDisabled: !this.state.selectTimeDisabled});

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    const inSchoolHoliday = this.inSchoolHoliday(date);

    this.setState({
        date: date,
        dateText: dateText,
        showError: false,
    });

    const startTime = new Date();
    const endTime = new Date();

    if(inSchoolHoliday === false && dateObject.getDay() !== 0 && dateObject.getDay() !== 1) {
      startTime.setHours(0, 0);
      endTime.setHours(0, 0);
    } else {
      startTime.setHours(6, 0);
      endTime.setHours(6, 0);
    }

    this.setStartTime(startTime);
    this.setEndTime(endTime);

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async saveAvailability() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    const date = this.state.date;

    const added = await APIAddUnavailability.Request(
          Data.data.accountData.account_username,
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate(),
          this.state.startText,
          this.state.endText,
          '',
        );

    if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

    } else {
        // console.log('error');
    }

    this.setState({loading: false});

  }

  async editAvailability() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    const added = await APIEditUnavailabilitySchool.Request(
        this.state.dateText,
        this.state.startText,
        this.state.endText,
        null,
        this.modalData.id,
      );

    if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

    } else {
        // console.log('error');
    }

    this.setState({loading: false});

  }

  async deleteAvailability() {

    this.setState({loading: false, showError: false});

    const deleted = await APIDeleteUnavailability.Request(this.modalData.id);

    if(deleted === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {
        // console.log('error');
    }

    this.setState({loading: false});

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je jouw afwezigheid wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteAvailability.bind(this),
        showAlert: true,
    });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {

    if(this.state.date === null) {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen datum ingevoerd',
        });
        return false;
      }

      if(this.state.date < new Date()) {
        this.setState({
          showError: true,
          errorText: 'Datum kan niet eerder zijn dan vandaag',
        });
        return false;
      }

    if(this.state.startTime.getTime() === this.state.endTime.getTime()) {
      if(this.state.startText !== '00:00' && this.state.endText !== '00:00') {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet gelijk zijn aan eindtijd',
        });
        return false;
      }
    }
    if(this.state.startTime.getTime() > this.state.endTime.getTime()) {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet later zijn dan eindtijd',
        });
        return false;
    }

    return true;

  }

  render() {

    let markedDates = [];

    const today = this.state.timeNow;

    for(let key in this.deadlines) {

        if(this.deadlines[key].deadline_aanpassen === '1') {
            continue; //skip
        }

        const startDate = Constants.stringToDate(this.deadlines[key].deadline_begin);
        const endDate = Constants.stringToDate(this.deadlines[key].deadline_eind);
        const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadline_datum, this.deadlines[key].deadline_tijd); //NEEDEDDDD TO COMPARE TIMES

        let currentDate = startDate;

        if(today.getTime() > deadlineDate.getTime()) { //past

            let index = 0;
            while (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index).getTime() <= endDate.getTime()) {
                markedDates.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index));
                index++;
            }

        }

    }

    for (let key in this.blockedDates) {

        const blockedDate = Constants.stringToDate(this.blockedDates[key].geblokkeerd_datum);

        markedDates.push(new Date(new Date().getFullYear(), blockedDate.getMonth(), blockedDate.getDate()));

    }

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="unavailableSchoolModal" className="modal" onClick={(e) => e.target === document.getElementById("zunavailableSchoolModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Afwezigheid toevoegen</p>

            :

              <p>Afwezigheid wijzigen</p>
            
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Datum:</div>

                    <DatePicker
                        selected={this.state.date}
                        onChange={(date) => this.setDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className={'inputFieldTime'}
                        placeholderText={'Selecteer een datum'}
                        showWeekNumbers
                        locale={nl}
                        excludeDates={markedDates}
                        minDate={this.state.timeNow}
                    />

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10,}}>

                    Hele dag

                    <label className="switch" style={{marginLeft: 25}}>
                        <input 
                        type="checkbox" 
                        checked={this.state.wholeDay} 
                        onClick={() => this.toggleSwitch()}
                        readOnly
                        disabled={this.state.wholeDayDisabled}
                        />
                        <span className="slider round"></span>
                    </label>

                    </div>

                    <div style={{marginTop: 10}}>Van:</div>

                    <DatePicker
                        selected={this.state.startTime}
                        onChange={(date) => this.setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={this.state.selectTimeDisabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                        disabled={this.state.selectTimeDisabled}
                    />

                    <div style={{marginTop: 5}}>Tot:</div>

                    <DatePicker
                        selected={this.state.endTime}
                        onChange={(date) => this.setEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Tot"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={this.state.selectTimeDisabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                        disabled={this.state.selectTimeDisabled}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editAvailability()}>Wijzigen</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveAvailability()}>Opslaan</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default UnavailableSchoolModal;