/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availability.css';
import '../planner/planner.scss';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';
import Colors from '../../constants/Colors';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import APIGetOwnAvailability from '../../classes/APIGetOwnAvailability';
import APIGetOwnUnavailability from '../../classes/APIGetOwnUnavailability';
import APIGetOwnHolidays from '../../classes/APIGetOwnHolidays';
import APIGetBlockedDays from '../../classes/APIGetBlockedDays';
import APIGetDeadlines from '../../classes/APIGetDeadlines';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import AvailabilityModal from './availabilityModal';
import UnavailabilityModal from './unavailabilityModal';
import BetterModal from '../../components/modal/modal';

class Availability extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.appOptions.availability !== 'AVAILABILITY' && Data.data.appOptions.availability !== 'UNAVAILABILITY')) {
      this.props.history.push("/");
    }

    this.available = [];
    this.unavailable = [];
    this.holidayDays = [];
    this.blockedDays = [];
    this.deadlines = [];

    // compwillmount

    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);
    
    this.state = {

      loading: true,
      reloading: false,

      day: day,
      month: month,
      year: year,
      daysInMonth: daysCurrentMonth,
      dateText: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,

      selectedDate: date,

      disabledPast: false,

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showUnavailableModal: false,

      showAlert: false,

      mode: 'MODAL',

      selectedDays: [],

      timeNow: new Date(),

      showModeMenu: false,

      showQuestionModal: false,
      tutorialModal: false,

    };

    this.navBar = React.createRef();

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.appOptions.availability !== 'AVAILABILITY' && Data.data.appOptions.availability !== 'UNAVAILABILITY')) {
      return;
    }

    this.setState({
        disabledPast: await this.disabledPast(),
        timeNow: await Constants.getDateNow(),
    });

    await this.getDeadlines();

    await this.getAvailable(this.state.month, this.state.year);
    await this.getUnavailable(this.state.month, this.state.year);

    await this.getHolidayDays();
    await this.getBlockedDays();

    if(Data.data.inTutorial === true) {

      this.setState({ tutorialModal: true });

      this.openQuestionModal();

    }

    this.setState({loading: false});

  }

  async getAvailable(month, year) {
    
    const availableDates = await APIGetOwnAvailability.Request(Data.data.accountData.account_username, month, year);

    if(availableDates === 'error') {
      this.setState({ 
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.available = [];

    for(let key in availableDates) {
      let dateData = [];

      dateData = availableDates[key].beschikbaar_datum.split('-');

      this.available.push(
          {
              year: parseInt(dateData[0]),
              month: parseInt(dateData[1]),
              day: parseInt(dateData[2]),
              start: availableDates[key].beschikbaar_begin,
              end: availableDates[key].beschikbaar_eind,
              comment: availableDates[key].beschikbaar_opmerking,
              id: availableDates[key].beschikbaar_id,
          }
      );

    }

  }

  async getUnavailable(month, year) {
    
    const unavailableDates = await APIGetOwnUnavailability.Request(Data.data.accountData.account_username, month, year);

    if(unavailableDates === 'error') {
      this.setState({ 
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.unavailable = [];

    for(let key in unavailableDates) {
      let dateData = [];

      dateData = unavailableDates[key].onbeschikbaar_datum.split('-');

      this.unavailable.push(
          {
              year: parseInt(dateData[0]),
              month: parseInt(dateData[1]),
              day: parseInt(dateData[2]),
              start: unavailableDates[key].onbeschikbaar_begin,
              end: unavailableDates[key].onbeschikbaar_eind,
              comment: unavailableDates[key].onbeschikbaar_opmerking,
              id: unavailableDates[key].onbeschikbaar_id,
          }
      );

    }

  }

  async getBlockedDays() {

    this.setState({ loading: true });

    this.blockedDays = await APIGetBlockedDays.Request();

    if(this.blockedDays === 'error') {

        this.blockedDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

  }

  async getHolidayDays() {

    let holidayDays = await APIGetOwnHolidays.Request();

    if(holidayDays === 'error') {

        holidayDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    if(holidayDays != null && holidayDays.length > 0) {

        let allHolidayDays = [];
        
        for(let key in holidayDays) { //iterate through holidays ranges from DB

            if(holidayDays[key].vakantie_geaccepteerd === '1') {

                let currentDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
                let endDate = Constants.stringToDate(holidayDays[key].vakantie_eind);

                let index = 0;
                while(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index).getTime() <= endDate.getTime()) {

                    allHolidayDays.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index));

                    index++;

                }

            }

        }


        this.holidayDays = allHolidayDays;

    } else {

        this.holidayDays = [];

    }

  }

  async getDeadlines() {

    this.deadlines = await APIGetDeadlines.Request();

    if(this.deadlines === 'error') {
        this.deadlines = [];
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async disabledPast() {

    const now = await Constants.getDateNow(); //new Date()

    if(parseInt(this.state.month) <= now.getMonth() && (parseInt(this.state.year) === now.getFullYear())) {
        return true;
    }
    if(parseInt(this.state.year) < now.getFullYear()) {
        return true;
    }

    return false;

  }

  inHolidayDays(i) {

    for(let key in this.holidayDays) {

        const date = Constants.stringToDate(this.holidayDays[key]);

        if(date.getDate() === i && date.getMonth() === (this.state.month - 1) && date.getFullYear() === this.state.year) {

            return true;

        }

    }

    return false;

  }

  inBlockedDays(i) {

    for(let key in this.blockedDays) {

        const date = Constants.stringToDate(this.blockedDays[key].geblokkeerd_datum);

        if(date.getDate() === i && date.getMonth() === (this.state.month - 1)) {

            return true;

        }

    }

    return false;

  }

  inDeadlinePeriod(i) {

    const today = this.state.timeNow;

    // for(let key in this.deadlines) {

    //     if(this.deadlines[key].deadline_aanpassen === '1') {
    //         continue; //skip
    //     }

    //     const startDate = new Date(this.deadlines[key].deadline_begin);
    //     const endDate = new Date(this.deadlines[key].deadline_eind);
    //     const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadline_datum, this.deadlines[key].deadline_tijd); //NEEDEDDDD TO COMPARE TIMES

    //     const today = this.state.timeNow;

    //     if(((this.state.month - 1) === endDate.getMonth() || (this.state.month - 1) === startDate.getMonth()) && (this.state.year === endDate.getFullYear() || this.state.year === startDate.getFullYear())) {

    //         if(today.getTime() > deadlineDate.getTime()) { //past

    //             if((this.state.month - 1) === startDate.getMonth() && this.state.year === startDate.getFullYear()) {
    //                 if(i >= startDate.getDate()) {
    //                     return true;
    //                 }
    //             }
    //             if((this.state.month - 1) === endDate.getMonth() && this.state.year === endDate.getFullYear()) {
    //                 if(i <= endDate.getDate()) {
    //                     return true;
    //                 }
    //             }

    //         }

    //     }

    // }

    for(let key in this.deadlines) {

      if(this.deadlines[key].deadline_aanpassen === '1') {
          continue; //skip
      }

      const startDate = Constants.stringToDate(this.deadlines[key].deadline_begin);
      const endDate = Constants.stringToDate(this.deadlines[key].deadline_eind);
      const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadline_datum, this.deadlines[key].deadline_tijd);
      const dayDateObject = Constants.stringToDate(`${this.state.year}-${this.state.month < 10 ? '0' + this.state.month : this.state.month}-${i < 10 ? '0' + i : i}`);

      if(((this.state.month - 1) === endDate.getMonth() || (this.state.month - 1) === startDate.getMonth()) && (this.state.year === endDate.getFullYear() || this.state.year === startDate.getFullYear())) {

          if(today.valueOf() > deadlineDate.valueOf()) { //past

              if((this.state.month - 1) === startDate.getMonth() && this.state.year === startDate.getFullYear() || (this.state.month - 1) === endDate.getMonth() && this.state.year === endDate.getFullYear()) {
                  if(dayDateObject.getTime() >= startDate.getTime() && dayDateObject.getTime() <= endDate.getTime()) {
                      return true;
                  }
              }

          }

      }

    }

    return false;

  }

  async selectDateCalendar(date) {

    if(this.state.loading === true) {
      return;
  }

  this.setState({loading: true});

  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const daysCurrentMonth = Constants.getDaysInMonth(month, year);

  this.setState({
      month: month,
      year: year,
      daysInMonth: daysCurrentMonth,
      dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
  });

  await this.reloadData(month, year);

  this.setState({loading: false, selectedDate: date});

  }

  async nextMonth() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let month = this.state.month + 1;
    let year = this.state.year;

    if(month >= 13) {

        month = 1;
        year += 1;
        
    }

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    this.setState({
        month: month,
        year: year,
        daysInMonth: daysCurrentMonth,
        dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadData(month, year);

    this.setState({loading: false});

  }

  async previousMonth() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let month = this.state.month - 1;
    let year = this.state.year;

    if(month <= 0) {

      month = 12;
      year -= 1;
      
    }

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    this.setState({
        month: month,
        year: year,
        daysInMonth: daysCurrentMonth,
        dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadData(month, year);

    this.setState({loading: false});

  }

  async reloadData(month, year) {

    const date = new Date(year, month, 0);

    await this.getDeadlines();

    await this.getAvailable(month, year);
    await this.getUnavailable(month, year);

    this.setState({
      timeNow: await Constants.getDateNow(),
      disabledPast: this.disabledPast(),
      selectedDate: date,
      selectedDays: [],
    });

  }

  openAvailabilityModal(day, mode, data, available) {

    if(this.inDeadlinePeriod(day) === true || this.state.disabledPast === true) {
      return;
    }

    if(Data.data.appOptions.availability === 'AVAILABILITY') {
      this.setState({
        showModal: true, 
        modalMode: mode, 
        modalDate: mode === 1 ? new Date(this.state.year, this.state.month - 1, 1) : new Date(this.state.year, this.state.month - 1, day),
        modalData: mode === 2 ? data : [],
        modalAvailable: available,
        modalMultipleDays: mode === 1 ? this.state.selectedDays.sort(Constants.sortNumber) : [],
      });
    } else if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
      this.setState({
        showUnavailableModal: true, 
        modalMode: mode, 
        modalDate: mode === 1 ? new Date(this.state.year, this.state.month - 1, 1) : new Date(this.state.year, this.state.month - 1, day),
        modalData: mode === 2 ? data : [],
        modalAvailable: available,
        modalMultipleDays: mode === 1 ? this.state.selectedDays.sort(Constants.sortNumber) : [],
      });
    }

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.reloadData(this.state.month, this.state.year);

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      showUnavailableModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  deadlineText() {

    let deadlineText = [];

    for(let key in this.deadlines) {

        const startDate = Constants.stringToDate(this.deadlines[key].deadline_begin);
        const endDate = Constants.stringToDate(this.deadlines[key].deadline_eind);
        const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadline_datum, this.deadlines[key].deadline_tijd); //NEEDEDDDD TO COMPARE TIMES converttoUTC if on other server than netherlands

        const startDay = startDate.getDate();
        //const startMonth = startDate.getMonth(); //jan = 0
        const startYear = startDate.getFullYear();

        const endDay = endDate.getDate();
        //const endMonth = endDate.getMonth(); //jan = 0
        const endYear = endDate.getFullYear();

        const deadlineDay = deadlineDate.getUTCDate();
        const deadlineMonth = deadlineDate.getUTCMonth(); //jan = 0
        const deadlineYear = deadlineDate.getUTCFullYear();

        const today = this.state.timeNow;

        if(((this.state.month - 1) === endDate.getMonth() && this.state.year === endDate.getFullYear()) || ((this.state.month - 1) === startDate.getMonth() && this.state.year === startDate.getFullYear())) {
            
            if(today < deadlineDate && today.getDate() === deadlineDay && today.getMonth() === deadlineMonth && today.getFullYear() === deadlineYear) {

                deadlineText.push(
                  <div className={'avail_deadlineContainer'} style={{borderColor: Colors.color.redFilledIn}}>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            } else if(today.getTime() > deadlineDate.getTime()) {

                deadlineText.push(
                  <div className={'avail_deadlineContainer'} style={{borderColor: Colors.color.pastBorderColor, backgroundColor: Colors.color.pastBackgroundColor}}>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            } else if((deadlineDay - today.getDate()) <= 7 && deadlineMonth === today.getMonth() && deadlineYear === today.getFullYear()) {

                deadlineText.push(
                  <div className={'avail_deadlineContainer'} style={{borderColor: Colors.color.orangeVacation}}>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            } else {

                deadlineText.push(
                  <div className={'avail_deadlineContainer'}>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'avail_deadlineRow'}>

                      <div className={'avail_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'avail_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            }

        }

    }

    return deadlineText;

  }

  getDayBars() {

    let finalArr = [];
    let columns = [];

    let filledDay = false;

    // Fill in empty space before the first day of month. So the week starts at monday.
    const firstDay = Constants.stringToDate(`${this.state.year}-${this.state.month}-01`).getDay();

    if(firstDay > 1) {
      for(let i = firstDay; i > 1; i--) {
        columns.push(
          <div key ={`${i}-${firstDay}`} className="avail_dayBarEmpty" />
        );
      }
    }

    if(firstDay === 0) {
      for(let i = 7; i > 1; i--) {
        columns.push(
          <div key ={`${i}-${firstDay}`} className="avail_dayBarEmpty" />
        );
      }
    }

    // Iterate through all days of month and get available, unavaiable or not filled.
    for(let i = 1; i <= this.state.daysInMonth; i++) {

      //AVAILABLE
      for(let key in this.available) {

        if(this.available[key].year === this.state.year && this.available[key].month === this.state.month && this.available[key].day === i && this.inHolidayDays(i) === false) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.greenFilledIn,
                backgroundColor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? Colors.color.pastBackgroundColor : 'white',
                cursor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? 'default' : 'pointer',
              }}
              onClick={() => this.state.mode === 'SELECTION' ? null : this.openAvailabilityModal(i, 2, this.available[key], true)}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.year}-${this.state.month}-${i}`)} {i} {Constants.getShortMonthName(this.state.dateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.available[key].year, parseInt(this.available[key].month) - 1, this.available[key].day))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                {this.available[key].start === '00:00:00' && this.available[key].end === '00:00:00' ?

                  <div>Hele dag</div>

                :

                  <div>{this.available[key].start.substring(0, 5)} - {this.available[key].end.substring(0, 5)}</div>

                }
                
              </div>

            </div>
          );

          filledDay = true;
          break;

        }

      }

      //UNAVAILABLE
      for(let key in this.unavailable) {

        if(this.unavailable[key].year === this.state.year && this.unavailable[key].month === this.state.month && this.unavailable[key].day === i && this.inHolidayDays(i) === false) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.redFilledIn,
                backgroundColor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? Colors.color.pastBackgroundColor : 'white',
                cursor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? 'default' : 'pointer',
              }}
              onClick={() => this.state.mode === 'SELECTION' ? null : this.openAvailabilityModal(i, 2, this.unavailable[key], false)}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.year}-${this.state.month}-${i}`)} {i} {Constants.getShortMonthName(this.state.dateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.unavailable[key].year, parseInt(this.unavailable[key].month) - 1, this.unavailable[key].day))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                {this.unavailable[key].start === '00:00:00' && this.unavailable[key].end === '00:00:00' ?

                  <div>Hele dag</div>

                :

                  <div>{this.unavailable[key].start.substring(0, 5)} - {this.unavailable[key].end.substring(0, 5)}</div>

                }

              </div>

            </div>
          );

          filledDay = true;
          break;

        }

      }

      //NOT FILLED //HOLIDAY //DEADLINE //BLOCKED
      if(filledDay === false) {

        if(this.inBlockedDays(i) === true) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.pastBorderColor, 
                backgroundColor: Colors.color.pastBackgroundColor, 
                cursor: 'default'
              }}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.year}-${this.state.month}-${i}`)} {i} {Constants.getShortMonthName(this.state.dateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.state.year, this.state.month - 1, i))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                <i className={'fa fa-ban'} style={{color: Colors.color.pastBorderColor}} />

              </div>

            </div>
          );

        } else if(this.inHolidayDays(i) === true) {

          columns.push(
            <div 
              key={i} 
              className="avail_dayBar" 
              style={{
                borderColor: Colors.color.orangeVacation, 
                cursor: 'default', 
                backgroundColor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? Colors.color.pastBackgroundColor : 'white'
              }}
            >

              <div className={'avail_rowInDayBar'}>

                <i>{Constants.getDayName(`${this.state.year}-${this.state.month}-${i}`)} {i} {Constants.getShortMonthName(this.state.dateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.state.year, this.state.month - 1, i))}</i>

              </div>

              <div className={'avail_rowInDayBar2'}>

                Vakantie

              </div>

            </div>
          );

        } else { //NOTFILLED

          columns.push(
            <div
              key={i} 
              className="avail_dayBar"
              style={{
                backgroundColor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? Colors.color.pastBackgroundColor : this.inSelectedDays(i) === true ? Colors.color.selectedDayColor : 'white',
                cursor: this.state.disabledPast === true || this.inDeadlinePeriod(i) === true ? 'default' : 'pointer',
              }}
              onClick={() => this.state.mode === 'MODAL' ? this.openAvailabilityModal(i, 0) : this.state.mode === 'SELECTION' ? this.daySelectingPressDay(i) : null}
            >
              <div className={'avail_rowInDayBar'}>
                <i>{Constants.getDayName(`${this.state.year}-${this.state.month}-${i}`)} {i} {Constants.getShortMonthName(this.state.dateText)}</i>
                <i style={{fontSize: '0.8vw'}}>{Constants.getNationalHoliday(new Date(this.state.year, this.state.month - 1, i))}</i>
              </div>
              <div className={'avail_rowInDayBar2'}>
                
              </div>
            </div>
          );

        }

      }

      filledDay = false;

      if(columns.length === 7) {
        finalArr.push(<div className={'avail_dayBarRow'}>{columns}</div>);
        columns = [];
      }

      if(i === this.state.daysInMonth) {
        if(columns.length < 7) {
          for(let i2 = columns.length; i2 < 7; i2++) {
            columns.push(
              <div key ={`${i}${i2}`} className="avail_dayBarEmpty" />
            );
          }
        }
        finalArr.push(<div className={'avail_dayBarRow'}>{columns}</div>);
      }

    }

    return finalArr;

  }

  changeMode(mode) {

    if(this.state.mode === mode) {
      return;
    }

    this.setState({ mode: mode, selectedDays: [], showModeMenu: false });

  }

  daySelectingPressDay(day) {

    if(this.inDeadlinePeriod(day) === true || this.state.disabledPast === true) {
      return;
    }

    if(Constants.arrayContains(this.state.selectedDays, day)) {

        this.deselectDay(day);

    } else {

        this.selectDay(day);

    }

  }

  selectDay(day) {

      this.state.selectedDays.push(day); // be sure to clear this when adding availability

      this.setState({ daysSelected: this.state.selectedDays });

  }

  deselectDay(day) {

      Constants.removeFromArray(this.state.selectedDays, day);

      this.setState({ selectedDays: this.state.selectedDays });

  }

  inSelectedDays(day) {

    return Constants.arrayContains(this.state.selectedDays, day);

  }

  removeAllDays() {

    this.setState({ selectedDays: [] });

  }

  openModeMenu() {

    this.setState({
      showModeMenu: !this.state.showModeMenu,
    });

  }

  openQuestionModal() {

    this.setState({ showQuestionModal: true });

  }

  closeQuestionModal() {

    this.setState({ showQuestionModal: false });

  }

  render() {

    const dayBars = this.getDayBars();
    const deadlineBar = this.deadlineText();

    return (
        <div className='availability'>

          <Navbar ref={this.navBar} history={this.props.history} active={'availability'} loading={this.state.loading} reloading={this.state.reloading} />

          {this.state.showQuestionModal === true ?
                
            <BetterModal
              _closeModal={this.closeQuestionModal.bind(this)}
              
              _header={this.state.tutorialModal === true ? 'Web-uitleg' : 'Informatie'}
              _general={{
                  header: 'Beschikbaarheid',
                  content: (
                    <div>Op deze pagina kun je jouw beschikbaarheid aangeven. Om van maand te veranderen kun je de pijlen bovenin gebruiken.<br/><br/>Om jouw beschikbaarheid toe te voegen, klik je op een gewenste dag.</div>
                  ),
              }}
              _alineas={[
                {
                    header: 'Wijzigen/verwijderen',
                    content: (
                        <div>Klik op een dag waarin je jouw beschikbaarheid hebt aangegeven. Deze is groen wanneer je beschikbaar bent en rood wanneer je onbeschikbaar bent.</div>
                    ),
                },
                {
                    header: 'Meerdere selecteren',
                    content: (
                        <div>Druk op de modus-knop links onderin om meerdere dagen te kunnen selecteren. Je kunt jouw beschikbaarheid op alle geselecteerde dagen tegelijk toepassen.</div>
                    ),
                },
                {
                    header: 'Deadlines',
                    content: (
                        <div>Jouw werkgever kan ervoor kiezen om een deadline toe te voegen voor wanneer je jouw beschikbaarheid moet hebben aangegeven. Na de deadline kan jouw werkgever ervoor kiezen om beschikbaarheid voor de gekozen periode op slot te zetten.</div>
                    ),
                },
                {
                    header: 'Kalender',
                    content: (
                        <div>Bovenin zie je een kalender. Wanneer je erop klikt, kun je naar een specifieke datum navigeren.</div>
                    ),
                },
            ]}
              _tutorialModal={this.state.tutorialModal}
              _history={this.props.history}
              _navBar={this.navBar.current}
            />

          :

            null
          
          }

          <div className='planner_nav2'>

            <div className='planner_nav2_sub1' onClick={() => this.previousMonth()}>
              <i className='fa fa-chevron-left'></i>
            </div> 
            <div className='planner_nav2_sub2'>
              <DatePicker 
                selected={this.state.selectedDate}
                onSelect={date => this.selectDateCalendar(date)}
                customInput={<i className='fa fa-calendar-day'></i>}
                popperPlacement="top-start"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "-40px, 0px"
                  },
                }}
                locale={nl}
                dropdownMode="select"
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div> 
            <div className='planner_nav2_sub3' style={{flex: 2}}>
              {this.state.mode === 'SELECTION' && this.state.selectedDays.length > 0 ?
                
                <i className='far fa-times-circle' style={{position: 'absolute', left: 0, cursor: 'pointer'}} onClick={() => this.removeAllDays()} />

              :

                null
            
              }
              {this.state.mode === 'SELECTION' && this.state.selectedDays.length > 0 ?
              
                `${this.state.selectedDays.length} geselecteerd`

              :

                `${Constants.getMonthName(this.state.dateText).charAt(0).toUpperCase() + Constants.getMonthName(this.state.dateText).slice(1)} ${this.state.year}`
              
              }
              {this.state.mode === 'SELECTION' && this.state.selectedDays.length > 0 ?
                
                <i className='far fa-check-circle' style={{position: 'absolute', right: 0, cursor: 'pointer'}} onClick={() => this.openAvailabilityModal(0, 1)} />

              :

                null
            
              }
            </div> 
            <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>
      
            </div> 
            <div className='planner_nav2_sub5' onClick={() => this.nextMonth()}>
              <i className='fa fa-chevron-right'></i>
            </div> 

          </div>

          <div className='avail_optionsBar'>

            <div className='planner_optionButton3' onClick={() => this.openModeMenu()}>
              {this.state.mode === 'SELECTION' ?
                <i className={'fas fa-mouse-pointer'} />          
              :
                <i className={'fas fa-external-link-alt'} />
              }
              {this.state.mode === 'SELECTION' ?
                <span>Selectie</span>
              :
                <span>Pop-up</span>
              }
            </div>
            
            <div className={`planner_optionButton3_menu ${this.state.showModeMenu === true ? 'modeMenuActive' : ''}`}>
              <div className='planner_optionButton3_menu_title'>
                <div className='planner_optionButton3_menu_title_flex'>
                  <span>Selecteer modus</span>
                </div>
              </div>
              <div className='planner_optionButton3_menu_sub' onClick={() => this.changeMode('MODAL')}>
                <div className='planner_optionButton3_menu_sub_flex1'>
                  <i className='fa fa-external-link-alt' style={this.state.mode === 'MODAL' ? {color: Colors.color.greenFilledIn} : null}></i>
                </div>
                <div className='planner_optionButton3_menu_sub_flex2'>
                  <span style={this.state.mode === 'MODAL' ? {color: Colors.color.greenFilledIn} : null}>Pop-up</span>
                </div>
              </div>
              <div className='planner_optionButton3_menu_sub' onClick={() => this.changeMode('SELECTION')}>
                <div className='planner_optionButton3_menu_sub_flex1'>
                  <i className='fa fa-mouse-pointer' style={this.state.mode === 'SELECTION' ? {color: Colors.color.greenFilledIn} : null}></i>
                </div>
                <div className='planner_optionButton3_menu_sub_flex2'>
                  <span style={this.state.mode === 'SELECTION' ? {color: Colors.color.greenFilledIn} : null}>Selectie</span>
                </div>
              </div>
            </div>

            {/* <div className='planner_questionButton' onClick={this.openQuestionModal.bind(this)}>
              <i className={'far fa-question'} />
              Hulp
            </div> */}

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='avail_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <AvailabilityModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _modalDate={this.state.modalDate}
                  _modalAvailable={this.state.modalAvailable}
                  _modalMultipleDays={this.state.modalMultipleDays}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showUnavailableModal === true ?
                
                <UnavailabilityModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _modalDate={this.state.modalDate}
                  _modalAvailable={this.state.modalAvailable}
                  _modalMultipleDays={this.state.modalMultipleDays}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {deadlineBar}

              <div className={'avail_headerRow stickyTopAvailability'}>
                <div className="avail_headerText">Maandag</div>
                <div className="avail_headerText">Dinsdag</div>
                <div className="avail_headerText">Woensdag</div>
                <div className="avail_headerText">Donderdag</div>
                <div className="avail_headerText">Vrijdag</div>
                <div className="avail_headerText">Zaterdag</div>
                <div className="avail_headerText">Zondag</div>
              </div>

              {dayBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Availability;