/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/appOptions/appOptions.css';
import '../../constants/DefaultStyle.css';
import '../options/hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../constants/Data';

import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIEDIT from '../../classes/global/APIEDIT';

import DatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import UserLeaveBalanceModal from './userLeaveBalanceModal';
import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';

class ContractModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.modalMode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this._contractLeaveBalances = this.props._contractLeaveBalances;

    this._startDate = Constants.isEmpty(this.props._startDate) ? null : this.props._startDate;
    
    this.state = {

        loading: true,

        type: '0',
        start: null,
        end: null,
        hours: null,
        maxHours: null,
        salary: null,
        salaryMonth: null,

        salaryModel: null,

        workDayHours: '', // Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? '8.00' : Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren,
        workDayHoursDays: '',
        defaultWorkdays: false,
        monday: '0.00',
        tuesday: '0.00',
        wednesday: '0.00',
        thursday: '0.00',
        friday: '0.00',
        saturday: '0.00',
        sunday: '0.00',

        leaveBalance: true,

        userLeaveBalances: [],

        showUserLeaveBalanceModal: false,
        modalMode: 0,
        modalData: [],
        modalDate: null,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    if (this.modalMode === 1 && this.modalData !== null) {

        // set the leave balances for this contract
        const userLeaveBalances = this._contractLeaveBalances[this.modalData.contract_id];

        if (typeof userLeaveBalances !== 'undefined') {
            this.setState({ userLeaveBalances: userLeaveBalances });
        } else {
            this.setState({ userLeaveBalances: [] });
        }

        if (Constants.isEmpty(this.modalData.contract_begin) === false) {
            this.setDate1(Constants.stringToDate(this.modalData.contract_begin));
        }
        if (Constants.isEmpty(this.modalData.contract_eind) === false) {
            this.setDate2(Constants.stringToDate(this.modalData.contract_eind));
        }

        this.setState({

            type: Constants.isEmpty(this.modalData.contract_type) ? '0' : this.modalData.contract_type,
            hours: this.modalData.contract_uren,
            maxHours: this.modalData.contract_max_uren,
            salary: Constants.isEmpty(this.modalData.contract_uurloon) ? 0 : this.modalData.contract_uurloon,
            salaryMonth: this.modalData.contract_maandloon,

            salaryModel: Constants.isEmpty(this.props._infoExtra) === false && Constants.isEmpty(this.props._infoExtra.info2_ut_id) === false ? this.props._infoExtra.info2_ut_id : null,

            leaveBalance: this.modalData.contract_opbouw_verlof === '1',

            workDayHours: Constants.isEmpty(this.modalData.contract_werkdag_uren) === false ? this.modalData.contract_werkdag_uren : Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? '8.00' : Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren,
            defaultWorkdays: this.modalData.contract_vaste_dagen === '1',
            monday: this.modalData.contract_maandag,
            tuesday: this.modalData.contract_dinsdag,
            wednesday: this.modalData.contract_woensdag,
            thursday: this.modalData.contract_donderdag,
            friday: this.modalData.contract_vrijdag,
            saturday: this.modalData.contract_zaterdag,
            sunday: this.modalData.contract_zondag,

          }, async () => {

            // SET HOUR WAGE
            if (this.state.salaryModel !== null && Constants.isEmpty(this.modalData.contract_uurloon)) {

              const dateNow = await Constants.getDateNow();
              const age = Constants.getAge(dateNow, Constants.stringToDate(this.props._userData.info_geboorte));
              const salaries = this.props._salaries[this.state.salaryModel];

              if (salaries.length !== 0) {

                  let newSalary = 0;

                  if ((age) <= parseInt(salaries[0].uurloon_leeftijd)) {
                      newSalary = parseFloat(salaries[0].uurloon_hoeveelheid);
                  } else if ((age) >= parseInt(salaries[salaries.length - 1].uurloon_leeftijd)) {
                      newSalary = parseFloat(salaries[salaries.length - 1].uurloon_hoeveelheid);
                  } else {
                      for (const generalSalary of salaries) {
                          if ((age) === parseInt(generalSalary.uurloon_leeftijd)) {
                              newSalary = parseFloat(generalSalary.uurloon_hoeveelheid);
                              break;
                          }
                      }
                  }

                  this.onChangeNumberDataFloat('salary', 0, 999999999, { target: { value: newSalary } });

              }

          }

          });

    } else if (this.modalMode === 2 && this.modalData !== null) {

        // set the leave balances for this contract
        const userLeaveBalances = this._contractLeaveBalances[this.modalData.contract_id];

        if (typeof userLeaveBalances !== 'undefined') {
            this.setState({ userLeaveBalances: userLeaveBalances });
        } else {
            this.setState({ userLeaveBalances: [] });
        }

        if (Constants.isEmpty(this.modalData.contract_begin) === false) {
            this.setDate1(Constants.stringToDate(this.modalData.contract_begin));
        }
        if (Constants.isEmpty(this.modalData.contract_eind) === false) {
            this.setDate2(Constants.stringToDate(this.modalData.contract_eind));
        }

        this.setState({

            type: this.modalData.contract_type,
            hours: this.modalData.contract_uren,
            maxHours: this.modalData.contract_max_uren,
            salary: this.modalData.contract_uurloon,
            salaryMonth: this.modalData.contract_maandloon,

            salaryModel: Constants.isEmpty(this.props._infoExtra) === false && Constants.isEmpty(this.props._infoExtra.info2_ut_id) === false ? this.props._infoExtra.info2_ut_id : null,

            leaveBalance: this.modalData.contract_opbouw_verlof === '1',

            workDayHours: Constants.isEmpty(this.modalData.contract_werkdag_uren) === false ? this.modalData.contract_werkdag_uren : Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? '8.00' : Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren,
            defaultWorkdays: this.modalData.contract_vaste_dagen === '1',
            monday: this.modalData.contract_maandag,
            tuesday: this.modalData.contract_dinsdag,
            wednesday: this.modalData.contract_woensdag,
            thursday: this.modalData.contract_donderdag,
            friday: this.modalData.contract_vrijdag,
            saturday: this.modalData.contract_zaterdag,
            sunday: this.modalData.contract_zondag,

        });

    } else if (this.modalMode === 3 && this.modalData !== null) { // renew

        // set the leave balances for this contract
        const userLeaveBalances = this._contractLeaveBalances[this.modalData.contract_id];

        if (typeof userLeaveBalances !== 'undefined') {
            this.setState({ userLeaveBalances: userLeaveBalances });
        } else {
            this.setState({ userLeaveBalances: [] });
        }

        if (Constants.isEmpty(this.modalData.contract_begin) === false) {
            this.setDate1(Constants.stringToDate(this.modalData.contract_begin));
        }
        if (Constants.isEmpty(this.modalData.contract_eind) === false) {
            this.setDate2(Constants.stringToDate(this.modalData.contract_eind));
        }

        this.setState({
          
            type: this.modalData.contract_type,
            hours: this.modalData.contract_uren,
            maxHours: this.modalData.contract_max_uren,
            salary: this.modalData.contract_uurloon,
            salaryMonth: this.modalData.contract_maandloon,

            salaryModel: Constants.isEmpty(this.props._infoExtra) === false && Constants.isEmpty(this.props._infoExtra.info2_ut_id) === false ? this.props._infoExtra.info2_ut_id : null,

            leaveBalance: this.modalData.contract_opbouw_verlof === '1',

            workDayHours: Constants.isEmpty(this.modalData.contract_werkdag_uren) === false ? this.modalData.contract_werkdag_uren : Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? '8.00' : Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren,
            defaultWorkdays: this.modalData.contract_vaste_dagen === '1',
            monday: this.modalData.contract_maandag,
            tuesday: this.modalData.contract_dinsdag,
            wednesday: this.modalData.contract_woensdag,
            thursday: this.modalData.contract_donderdag,
            friday: this.modalData.contract_vrijdag,
            saturday: this.modalData.contract_zaterdag,
            sunday: this.modalData.contract_zondag,

        });

      } else {

        let userLeaveBalances = [];

        if (this.props._leaveBalances.length > 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

            for (const leave of this.props._leaveBalances) {
            
                userLeaveBalances.push({
                    cvb_id: -1,
                    cvb_contract_id: -1,
                    cvb_vb_id: leave.vb_id,
                    cvb_vb_factor: leave.vb_factor,
                    vb_naam: leave.vb_naam,
                    vb_factor: leave.vb_factor,
                    vb_id: leave.vb_id,
                });

            }

        }

        this.setState({ userLeaveBalances: userLeaveBalances });

    }

    this.setState({ loading: false, });

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        date = null;
    }

    this.setState({
        start: date,
        showError: false,
    });

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        date = null;
    }

    this.setState({
        end: date,  
        showError: false,
    });

  }

  selectType(event) {

    this.setState({
        type: event.target.value,
        showError: false,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {

    if (Constants.isEmpty(this.state.start) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen begindatum aangegeven`,
        });
        return false;
    }

    if (Constants.isEmpty(this.state.start) === false && Constants.isEmpty(this.state.end) === false) {

      if (this.state.end <= this.state.start) {
        this.setState({
          showError: true,
          errorText: `Einddatum kan niet eerder zijn dan of gelijk zijn aan de begindatum`,
        });
        return false;
      }

    }

    if (Constants.isEmpty(this.props._userContracts) === false) {

      for (const contract of this.props._userContracts) {

        if (Constants.isEmpty(this.modalData) === false && this.modalMode !== 2 && this.modalData.contract_id === contract.contract_id) {
          continue;
        }

        if (Constants.isEmpty(contract.contract_eind) === true && Constants.isEmpty(contract.contract_begin) === true) {
          this.setState({
            showError: true,
            errorText: `Er bestaat al een contract in de aangegeven periode`,
          });
          return false;
        }

        // this start and end is filled
        if (Constants.isEmpty(this.state.start) === false && Constants.isEmpty(this.state.end) === false) {

          if (Constants.isEmpty(contract.contract_eind) === true && Constants.isEmpty(contract.contract_begin) === false && this.state.start >= Constants.stringToDate(contract.contract_begin)) {
            this.setState({
              showError: true,
              errorText: `Er bestaat al een contract in de aangegeven periode`,
            });
            return false;
          }

          if (Constants.isEmpty(contract.contract_eind) === false && Constants.isEmpty(contract.contract_begin) === true && this.state.start <= Constants.stringToDate(contract.contract_eind)) {
            this.setState({
              showError: true,
              errorText: `Er bestaat al een contract in de aangegeven periode`,
            });
            return false;
          }

          if (Constants.isEmpty(contract.contract_eind) === false && Constants.isEmpty(contract.contract_begin) === false) {

            if (this.state.start <= Constants.stringToDate(contract.contract_begin) && this.state.end >= Constants.stringToDate(contract.contract_begin)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

            if (this.state.start <= Constants.stringToDate(contract.contract_eind) && this.state.end >= Constants.stringToDate(contract.contract_eind)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

            if (this.state.start >= Constants.stringToDate(contract.contract_begin) && this.state.end <= Constants.stringToDate(contract.contract_eind)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

            if (this.state.start <= Constants.stringToDate(contract.contract_eind) && this.state.end >= Constants.stringToDate(contract.contract_eind)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

          }

        } else if (Constants.isEmpty(this.state.start) === false && Constants.isEmpty(this.state.end) === true) {

          if (Constants.isEmpty(contract.contract_eind) === true && Constants.isEmpty(contract.contract_begin) === false) {
            this.setState({
              showError: true,
              errorText: `Er bestaat al een contract in de aangegeven periode`,
            });
            return false;
          }

          if (Constants.isEmpty(contract.contract_eind) === false && Constants.isEmpty(contract.contract_begin) === true && this.state.start <= Constants.stringToDate(contract.contract_eind)) {
            this.setState({
              showError: true,
              errorText: `Er bestaat al een contract in de aangegeven periode`,
            });
            return false;
          }

          if (Constants.isEmpty(contract.contract_eind) === false && Constants.isEmpty(contract.contract_begin) === false) {

            if (this.state.start <= Constants.stringToDate(contract.contract_begin)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

            if (this.state.start <= Constants.stringToDate(contract.contract_eind)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

            if (this.state.start >= Constants.stringToDate(contract.contract_begin) && this.state.start <= Constants.stringToDate(contract.contract_eind)) {
              this.setState({
                showError: true,
                errorText: `Er bestaat al een contract in de aangegeven periode`,
              });
              return false;
            }

          }

        }

      }

    }

    if (this.state.type === '1') {

      if (Constants.isEmpty(this.state.hours)) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen minimaal aantal uren aangegeven`,
        });
        return false;
      }

      if (Constants.isEmpty(this.state.maxHours)) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen maximaal aantal uren aangegeven`,
        });
        return false;
      }

      if (Constants.isEmpty(this.state.workDayHours)) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen aantal werkdagen aangegeven`,
        });
        return false;
      }

    }

    if (this.state.type === '2') {

      if (Constants.isEmpty(this.state.hours) && this.state.defaultWorkdays === false ) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen aantal contracturen aangegeven`,
        });
        return false;
      }

      if (this.state.defaultWorkdays === false) {

        if (Constants.isEmpty(this.state.workDayHours)) {
          this.setState({
            showError: true,
            errorText: `Je hebt geen aantal werkdagen aangegeven`,
          });
          return false;
        }

      } else {

        if ((Constants.isEmpty(this.state.monday) || parseFloat(this.state.monday) === 0) && (Constants.isEmpty(this.state.tuesday) || parseFloat(this.state.tuesday) === 0) && (Constants.isEmpty(this.state.wednesday) || parseFloat(this.state.wednesday) === 0) && (Constants.isEmpty(this.state.thursday) || parseFloat(this.state.thursday) === 0) && (Constants.isEmpty(this.state.friday) || parseFloat(this.state.friday) === 0) && (Constants.isEmpty(this.state.saturday) || parseFloat(this.state.saturday) === 0) && (Constants.isEmpty(this.state.sunday) || parseFloat(this.state.sunday) === 0)) {
          this.setState({
            showError: true,
            errorText: `Je hebt geen arbeidsduur aangegeven op de werkdagen`,
          });
          return false;
        }

      }

    }

    return true;

  }

  async addContract() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    const start = Constants.isEmpty(this.state.start) === false ? `'${Constants.dateToString(this.state.start)}'` : 'NULL';
    const end = Constants.isEmpty(this.state.end) === false ? `'${Constants.dateToString(this.state.end)}'` : 'NULL';
    const type = Constants.isEmpty(this.state.type) === false ? this.state.type : 'NULL';
    let hours = Constants.isEmpty(this.state.hours) === false ? isNaN(parseFloat(this.state.hours)) === false ? parseFloat(this.state.hours) : 'NULL' : 'NULL';
    const maxHours = Constants.isEmpty(this.state.maxHours) === false && this.state.type === '1' ? isNaN(parseFloat(this.state.maxHours)) === false ? parseFloat(this.state.maxHours) : 'NULL' : 'NULL';
    const salary = Constants.isEmpty(this.state.salary) === false && this.state.type !== '2' ? isNaN(parseFloat(this.state.salary)) === false ? parseFloat(this.state.salary) : 'NULL' : 'NULL';
    const salaryMonth = Constants.isEmpty(this.state.salaryMonth) === false && this.state.type === '2' ? isNaN(parseFloat(this.state.salaryMonth)) === false ? parseFloat(this.state.salaryMonth) : 'NULL' : 'NULL';
    const accrual = this.state.leaveBalance === true ? '1' : '0';

    const workDayHours = Constants.isEmpty(this.state.workDayHours) === false ? isNaN(parseFloat(this.state.workDayHours)) === false ? parseFloat(this.state.workDayHours) : 'NULL' : 'NULL';
    const defaultWorkdays = this.state.defaultWorkdays === true ? '1' : '0';
    const monday = Constants.isEmpty(this.state.monday) === false ? isNaN(parseFloat(this.state.monday)) === false ? parseFloat(this.state.monday) : 'NULL' : 'NULL';
    const tuesday = Constants.isEmpty(this.state.tuesday) === false ? isNaN(parseFloat(this.state.tuesday)) === false ? parseFloat(this.state.tuesday) : 'NULL' : 'NULL';
    const wednesday = Constants.isEmpty(this.state.wednesday) === false ? isNaN(parseFloat(this.state.wednesday)) === false ? parseFloat(this.state.wednesday) : 'NULL' : 'NULL';
    const thursday = Constants.isEmpty(this.state.thursday) === false ? isNaN(parseFloat(this.state.thursday)) === false ? parseFloat(this.state.thursday) : 'NULL' : 'NULL';
    const friday = Constants.isEmpty(this.state.friday) === false ? isNaN(parseFloat(this.state.friday)) === false ? parseFloat(this.state.friday) : 'NULL' : 'NULL';
    const saturday = Constants.isEmpty(this.state.saturday) === false ? isNaN(parseFloat(this.state.saturday)) === false ? parseFloat(this.state.saturday) : 'NULL' : 'NULL';
    const sunday = Constants.isEmpty(this.state.sunday) === false ? isNaN(parseFloat(this.state.sunday)) === false ? parseFloat(this.state.sunday) : 'NULL' : 'NULL';

    if (this.state.defaultWorkdays === true) {
      hours = (Constants.isEmpty(this.state.monday) === false ? isNaN(parseFloat(this.state.monday)) === false && parseFloat(this.state.monday) : 0)
            + (Constants.isEmpty(this.state.tuesday) === false ? isNaN(parseFloat(this.state.tuesday)) === false && parseFloat(this.state.tuesday) : 0)
            + (Constants.isEmpty(this.state.wednesday) === false ? isNaN(parseFloat(this.state.wednesday)) === false && parseFloat(this.state.wednesday) : 0)
            + (Constants.isEmpty(this.state.thursday) === false ? isNaN(parseFloat(this.state.thursday)) === false && parseFloat(this.state.thursday) : 0)
            + (Constants.isEmpty(this.state.friday) === false ? isNaN(parseFloat(this.state.friday)) === false && parseFloat(this.state.friday) : 0)
            + (Constants.isEmpty(this.state.saturday) === false ? isNaN(parseFloat(this.state.saturday)) === false && parseFloat(this.state.saturday) : 0)
            + (Constants.isEmpty(this.state.sunday) === false ? isNaN(parseFloat(this.state.sunday)) === false && parseFloat(this.state.sunday) : 0);
    }

    // add the contract
    await APIADD.Request(`INSERT INTO ContractN`, `VALUES (NULL, ${this.props._userData.info_id}, ${start}, ${end}, ${type}, ${hours}, ${maxHours}, ${salary}, ${salaryMonth}, ${accrual}, ${workDayHours}, ${defaultWorkdays}, ${monday}, ${tuesday}, ${wednesday}, ${thursday}, ${friday}, ${saturday}, ${sunday})`);
    let contractID = await APIGET.Request(`SELECT contract_id`, `FROM ContractN`, null, `WHERE contract_info_id = ${this.props._userData.info_id}`, null, `ORDER BY contract_id DESC LIMIT 1`);
    contractID = contractID[0].contract_id;

    // add the contract custom leave factor values
    let promises = [];
    for (const leave of this.state.userLeaveBalances) {
        promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contractID}, ${leave.vb_id}, ${leave.cvb_vb_factor})`));
    }
    await Promise.all(promises);

    // check salarymodel
    if (this.state.salaryModel !== null) {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = ${this.state.salaryModel}`, `WHERE info2_info_id = ${this.props._userData.info_id}`);
    } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = NULL`, `WHERE info2_info_id = ${this.props._userData.info_id}`);
    }

    //await APIEDIT.Request(`UPDATE InfoVerlofBalans`, `SET ivb_vb_factor = ${this.state.factor}`, `WHERE ivb_id = ${this.modalData.ivb_id}`);

    this.reloadScreen();
    this.closeModal();

  }

  async editContract() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    const start = Constants.isEmpty(this.state.start) === false ? `'${Constants.dateToString(this.state.start)}'` : 'NULL';
    const end = Constants.isEmpty(this.state.end) === false ? `'${Constants.dateToString(this.state.end)}'` : 'NULL';
    const type = Constants.isEmpty(this.state.type) === false ? this.state.type : 'NULL';
    let hours = Constants.isEmpty(this.state.hours) === false ? isNaN(parseFloat(this.state.hours)) === false ? parseFloat(this.state.hours) : 'NULL' : 'NULL';
    const maxHours = Constants.isEmpty(this.state.maxHours) === false && this.state.type === '1' ? isNaN(parseFloat(this.state.maxHours)) === false ? parseFloat(this.state.maxHours) : 'NULL' : 'NULL';
    const salary = Constants.isEmpty(this.state.salary) === false && this.state.type !== '2' ? isNaN(parseFloat(this.state.salary)) === false ? parseFloat(this.state.salary) : 'NULL' : 'NULL';
    const salaryMonth = Constants.isEmpty(this.state.salaryMonth) === false && this.state.type === '2' ? isNaN(parseFloat(this.state.salaryMonth)) === false ? parseFloat(this.state.salaryMonth) : 'NULL' : 'NULL';
    const accrual = this.state.leaveBalance === true ? '1' : '0';

    const workDayHours = Constants.isEmpty(this.state.workDayHours) === false ? isNaN(parseFloat(this.state.workDayHours)) === false ? parseFloat(this.state.workDayHours) : 'NULL' : 'NULL';
    const defaultWorkdays = this.state.defaultWorkdays === true ? '1' : '0';
    const monday = Constants.isEmpty(this.state.monday) === false ? isNaN(parseFloat(this.state.monday)) === false ? parseFloat(this.state.monday) : 'NULL' : 'NULL';
    const tuesday = Constants.isEmpty(this.state.tuesday) === false ? isNaN(parseFloat(this.state.tuesday)) === false ? parseFloat(this.state.tuesday) : 'NULL' : 'NULL';
    const wednesday = Constants.isEmpty(this.state.wednesday) === false ? isNaN(parseFloat(this.state.wednesday)) === false ? parseFloat(this.state.wednesday) : 'NULL' : 'NULL';
    const thursday = Constants.isEmpty(this.state.thursday) === false ? isNaN(parseFloat(this.state.thursday)) === false ? parseFloat(this.state.thursday) : 'NULL' : 'NULL';
    const friday = Constants.isEmpty(this.state.friday) === false ? isNaN(parseFloat(this.state.friday)) === false ? parseFloat(this.state.friday) : 'NULL' : 'NULL';
    const saturday = Constants.isEmpty(this.state.saturday) === false ? isNaN(parseFloat(this.state.saturday)) === false ? parseFloat(this.state.saturday) : 'NULL' : 'NULL';
    const sunday = Constants.isEmpty(this.state.sunday) === false ? isNaN(parseFloat(this.state.sunday)) === false ? parseFloat(this.state.sunday) : 'NULL' : 'NULL';

    if (this.state.defaultWorkdays === true) {
      hours = (Constants.isEmpty(this.state.monday) === false ? isNaN(parseFloat(this.state.monday)) === false && parseFloat(this.state.monday) : 0)
            + (Constants.isEmpty(this.state.tuesday) === false ? isNaN(parseFloat(this.state.tuesday)) === false && parseFloat(this.state.tuesday) : 0)
            + (Constants.isEmpty(this.state.wednesday) === false ? isNaN(parseFloat(this.state.wednesday)) === false && parseFloat(this.state.wednesday) : 0)
            + (Constants.isEmpty(this.state.thursday) === false ? isNaN(parseFloat(this.state.thursday)) === false && parseFloat(this.state.thursday) : 0)
            + (Constants.isEmpty(this.state.friday) === false ? isNaN(parseFloat(this.state.friday)) === false && parseFloat(this.state.friday) : 0)
            + (Constants.isEmpty(this.state.saturday) === false ? isNaN(parseFloat(this.state.saturday)) === false && parseFloat(this.state.saturday) : 0)
            + (Constants.isEmpty(this.state.sunday) === false ? isNaN(parseFloat(this.state.sunday)) === false && parseFloat(this.state.sunday) : 0);
    }

    // add the contract
    await APIEDIT.Request(`UPDATE ContractN`, `SET contract_begin = ${start}, contract_eind = ${end}, contract_type = ${type}, contract_uren = ${hours}, contract_max_uren = ${maxHours}, contract_uurloon = ${salary}, contract_maandloon = ${salaryMonth}, contract_opbouw_verlof = ${accrual}, contract_werkdag_uren = ${workDayHours}, contract_vaste_dagen = ${defaultWorkdays}, contract_maandag = ${monday}, contract_dinsdag = ${tuesday}, contract_woensdag = ${wednesday}, contract_donderdag = ${thursday}, contract_vrijdag = ${friday}, contract_zaterdag = ${saturday}, contract_zondag = ${sunday}`, `WHERE contract_id = ${this.modalData.contract_id}`);

    // add the contract custom leave factor values
    let promises = [];
    for (const leave of this.state.userLeaveBalances) {
        promises.push(APIEDIT.Request(`UPDATE ContractVerlofBalans`, `SET cvb_vb_factor = ${leave.cvb_vb_factor}`, `WHERE cvb_id = ${leave.cvb_id}`));
    }
    await Promise.all(promises);

    // check salarymodel
    if (this.state.salaryModel !== null) {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = ${this.state.salaryModel}`, `WHERE info2_info_id = ${this.props._userData.info_id}`);
    } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = NULL`, `WHERE info2_info_id = ${this.props._userData.info_id}`);
    }

    //await APIEDIT.Request(`UPDATE InfoVerlofBalans`, `SET ivb_vb_factor = ${this.state.factor}`, `WHERE ivb_id = ${this.modalData.ivb_id}`);

    this.reloadScreen();
    this.closeModal();

  }

  onChangeNumberDataFloat(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'hours') {

      let absAmount = this.state.hours;
      let hoursDay = '';

      if (nr !== '') {
        absAmount = parseFloat(nr);
      } else {
        absAmount = '';
      }

      // set workday hours
      if (Constants.isEmpty(this.state.workDayHoursDays) === false) {

        const _days = parseInt(this.state.workDayHoursDays);

        if (isNaN(_days) === false) {
          hoursDay = Constants.round(absAmount / _days);
        }

      }

      this.setState({ hours: absAmount, workDayHours: hoursDay });

    } else if (what === 'maxHours') {

        let absAmount = this.state.maxHours;
  
        if (nr !== '') {
          absAmount = parseFloat(nr);
        } else {
          absAmount = '';
        }
  
        this.setState({ maxHours: absAmount });
  
    } else if (what === 'salaryMonth') {

        let absAmount = this.state.salaryMonth;
  
        if (nr !== '') {
          absAmount = parseFloat(nr);
        } else {
          absAmount = '';
        }
  
        this.setState({ salaryMonth: absAmount });
  
    } else if (what === 'salary') {

        let absAmount = this.state.salary;
  
        if (nr !== '') {
          absAmount = parseFloat(nr);
        } else {
          absAmount = '';
        }
  
        this.setState({ salary: absAmount });
  
    // CONTRACT HOURS WORKDAY 
    } else if (what === 'monday') {
      let day = this.state.monday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ monday: day });
    } else if (what === 'tuesday') {
      let day = this.state.tuesday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ tuesday: day });
    } else if (what === 'wednesday') {
      let day = this.state.wednesday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ wednesday: day });
    } else if (what === 'thursday') {
      let day = this.state.thursday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ thursday: day });
    } else if (what === 'friday') {
      let day = this.state.friday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ friday: day });
    } else if (what === 'saturday') {
      let day = this.state.saturday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ saturday: day });
    } else if (what === 'sunday') {
      let day = this.state.sunday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ sunday: day });
    } else if (what === 'workDayHours') {
      let day = this.state.workDayHours;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ workDayHours: day });
    } else if (what === 'workDayHoursDays') {

      const _days = parseInt(nr);

      if (isNaN(_days) === false) {

        const amount = Constants.round(this.state.hours / _days);

        this.setState({ workDayHoursDays: _days, workDayHours: amount });

      } else {
        this.setState({ workDayHours: '', workDayHoursDays: '' });
      }

    }

  }

  onChangeNumberDataInt(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'days') {

      let absAmount = this.state.days;

      if (nr !== '') {

        absAmount = nr;

        const days = parseInt(absAmount);
        const contractHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === false && parseFloat(Data.data.appOptions.opties_dag_werkuren) !== 0 && isNaN(parseFloat(Data.data.appOptions.opties_dag_werkuren)) === false ? parseFloat(Data.data.appOptions.opties_dag_werkuren) : 8;
        const fte = Constants.isEmpty(Data.data.appOptions.opties_fteUren) === false && parseFloat(Data.data.appOptions.opties_fteUren) !== 0 && isNaN(parseFloat(Data.data.appOptions.opties_fteUren)) === false ? parseFloat(Data.data.appOptions.opties_fteUren) : 40;

        const maxHoursYear = fte * 52;
        const vacationMaxYear = contractHours * days;

        this.onChangeNumberDataFloat('factor', 0, 1, { target: { value: vacationMaxYear / maxHoursYear } });

      } else {
        absAmount = '';
      }

      this.setState({ days: absAmount });

    }

  }

  async selectSalaryModel(e) {

    this.setState({
        salaryModel: e.target.value === 'null' ? null : e.target.value,
    }, async () => {

        if (this.state.salaryModel !== null) {

            const dateNow = await Constants.getDateNow();
            const age = Constants.getAge(dateNow, Constants.stringToDate(this.props._userData.info_geboorte));
            const salaries = this.props._salaries[this.state.salaryModel];

            if (salaries.length !== 0) {

                let newSalary = 0;

                if ((age) <= parseInt(salaries[0].uurloon_leeftijd)) {
                    newSalary = parseFloat(salaries[0].uurloon_hoeveelheid);
                } else if ((age) >= parseInt(salaries[salaries.length - 1].uurloon_leeftijd)) {
                    newSalary = parseFloat(salaries[salaries.length - 1].uurloon_hoeveelheid);
                } else {
                    for (const generalSalary of salaries) {
                        if ((age) === parseInt(generalSalary.uurloon_leeftijd)) {
                            newSalary = parseFloat(generalSalary.uurloon_hoeveelheid);
                            break;
                        }
                    }
                }

                this.onChangeNumberDataFloat('salary', 0, 999999999, { target: { value: newSalary } });

            }

        } else {

            //this.onChangeNumberDataFloat('salary', 0, 999999999, { target: { value: 0 } });

        }

    });

  }

  getSalaryModelBars() {

    let models = [];

    for(const model of this.props._salaryModels) {

        models.push(
            <option value={model.ut_id} selected={model.ut_id === this.state.salaryModel}>{model.ut_naam}</option>
        );

    }

    return models;

  }

  async reloadUserLeaveNewContract(leave) {

    let userLeaveBalances = this.state.userLeaveBalances;

    let leaveBalance = Constants.getArrayItem(userLeaveBalances, 'cvb_vb_id', leave.cvb_vb_id);
    
    if (Constants.isEmpty(leaveBalance) === false) {
        leaveBalance.cvb_vb_factor = leave.cvb_vb_factor;
    }

    this.setState({ userLeaveBalances: userLeaveBalances });

  }

  async reloadUserLeave() {

    this.setState({ loading: true });

    const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    const userLeaveBalances = contractLeaveBalancesObj[this.modalData.contract_id];

    if (typeof userLeaveBalances !== 'undefined') {
        this.setState({ userLeaveBalances: userLeaveBalances });
    } else {
        this.setState({ userLeaveBalances: [] });
    }

    this._contractLeaveBalances = contractLeaveBalancesObj;
    this.setState({ loading: false });

    // this.setState({ reloading: true });

    // const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    // const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    // this.setState({ contractLeaveBalances: contractLeaveBalancesObj },
    //   async () => {

    //     await this.reloadScreen();
    //     this.setState({reloading: false});

    //   }
    // );

  }

  closeUserLeaveBalanceModal() {

    this.setState({
      showUserLeaveBalanceModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  openUserLeaveBalanceModal(leave) {

    this.setState({
      showUserLeaveBalanceModal: true,
      modalData: leave,
      modalMode: 1,
    });

  }

  renderUserLeaveBalances() {

    let toRender = [];
    let count = 0;
    let balances = this.state.userLeaveBalances;

    for (const leave of balances) {

      toRender.push(

        <div key={leave.cvb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '100%', cursor: 'pointer', fontSize: '0.8vw'}} onClick={() => this.openUserLeaveBalanceModal(leave)}>

          <div className='hr_hoursBar_sub1'>
            <span>{leave.vb_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{Constants.digitsToLocale(parseFloat(leave.cvb_vb_factor), 9)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  closeContractModal() {

    this.reloadScreen();
    this.closeModal();

  }

  render() {

    let totalHours = 0;

    if (isNaN(parseFloat(this.state.monday)) === false) {
      totalHours += parseFloat(this.state.monday);
    }
    if (isNaN(parseFloat(this.state.tuesday)) === false) {
      totalHours += parseFloat(this.state.tuesday);
    }
    if (isNaN(parseFloat(this.state.wednesday)) === false) {
      totalHours += parseFloat(this.state.wednesday);
    }
    if (isNaN(parseFloat(this.state.thursday)) === false) {
      totalHours += parseFloat(this.state.thursday);
    }
    if (isNaN(parseFloat(this.state.friday)) === false) {
      totalHours += parseFloat(this.state.friday);
    }
    if (isNaN(parseFloat(this.state.saturday)) === false) {
      totalHours += parseFloat(this.state.saturday);
    }
    if (isNaN(parseFloat(this.state.sunday)) === false) {
      totalHours += parseFloat(this.state.sunday);
    }

    return (
        <div id="contractModal" className="modal" onClick={(e) => e.target === document.getElementById("zcontractModal") ? this.closeContractModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeContractModal() : null}>&times;</span>
            {this.modalMode === 0 ?
                <p>Contract toevoegen</p>
            :
            this.modalMode === 1 ?
                <p>Contract wijzigen</p>
            :
            this.modalMode === 3 ?
                <p>Contract vernieuwen</p>
            :
                <p>Contract kopiëren</p>
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showUserLeaveBalanceModal === true ?

                    <UserLeaveBalanceModal
                      _closeModal={this.closeUserLeaveBalanceModal.bind(this)}
                      _modalData={this.state.modalData}
                      _leaveBalances={this.props._leaveBalances}
                      _reloadScreen={this.reloadUserLeave.bind(this)}
                      _reloadScreen2={this.reloadUserLeaveNewContract.bind(this)}
                    />

                  : null}

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Begindatum</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <DatePicker
                                selected={this.state.start}
                                onChange={(date) => this.setDate1(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                locale={dateLoc[Constants.getLang().substr(0, 2)]}
                            />
                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Einddatum</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <DatePicker
                                selected={this.state.end}
                                minDate={this.state.start}
                                onChange={(date) => this.setDate2(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                locale={dateLoc[Constants.getLang().substr(0, 2)]}
                            />
                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Contract</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <select 
                                name="Selecteer een contract" 
                                className={'dropDownField'}
                                onChange={(event) => this.selectType(event)}
                            >
                                <option value={'0'} selected={'0' === this.state.type}>Flex contract (oproep, nuluren, flex)</option>
                                <option value={'1'} selected={'1' === this.state.type}>Min-max contract</option>
                                <option value={'2'} selected={'2' === this.state.type}>Vaste uren contract</option>
                            </select>
                        </div>

                    </div>

                    {this.state.type === '1' ?

                        <div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Minimale uren</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <input
                                        className={'inputField2'}
                                        onChange={(event) => this.onChangeNumberDataFloat('hours', 0, 168, event)}
                                        value={this.state.hours}
                                        placeholder={'Minimale contracturen'}
                                        type='number'
                                        step={0.01}
                                    />
                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Maximale uren</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <input
                                        className={'inputField2'}
                                        onChange={(event) => this.onChangeNumberDataFloat('maxHours', 0, 168, event)}
                                        value={this.state.maxHours}
                                        placeholder={'Maximale contracturen'}
                                        type='number'
                                        step={0.01}
                                    />
                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Aantal werkdagen</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={Constants.isEmpty(this.state.hours) === false ? 'inputField2' : 'inputField2Disabled'}
                                  onChange={(event) => this.onChangeNumberDataFloat('workDayHoursDays', 1, 7, event)}
                                  value={this.state.workDayHoursDays}
                                  placeholder={'Werkdagen'}
                                  type='number'
                                  step={1}
                                  disabled={Constants.isEmpty(this.state.hours)}
                                />
                              </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Arbeidsduur per werkdag</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={'inputField2Disabled'}
                                  onChange={(event) => this.onChangeNumberDataFloat('workDayHours', 0, 24, event)}
                                  value={this.state.workDayHours}
                                  placeholder={'Arbeidsduur'}
                                  type='number'
                                  step={0.01}
                                  disabled
                                />
                              </div>

                            </div>

                        </div>

                    :
                    this.state.type === '2' ?

                      <div>

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                              <span><b>Vaste werkdagen</b></span>
                            </div>

                            <div className='modal-rowSub2'>
                              <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={this.state.defaultWorkdays}
                                    onClick={() => this.setState({ defaultWorkdays: !this.state.defaultWorkdays })}
                                    readOnly
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>

                        </div>

                        {this.state.defaultWorkdays === true ?

                          <div className='modal-row2' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                              <span><b>Werkdagen en arbeidsduur</b></span>
                              <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Arbeidsduur per werkdag</span>
                            </div>

                            <div className='modal-rowSub2'>
                              <div className='contract_workdays_row'>
                                <div className='contract_workdays_row_sub'>
                                  <span>ma</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('monday', 0, 24, event)}
                                    value={this.state.monday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>di</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('tuesday', 0, 24, event)}
                                    value={this.state.tuesday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>wo</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('wednesday', 0, 24, event)}
                                    value={this.state.wednesday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>do</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('thursday', 0, 24, event)}
                                    value={this.state.thursday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>vr</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('friday', 0, 24, event)}
                                    value={this.state.friday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>za</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('saturday', 0, 24, event)}
                                    value={this.state.saturday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>zo</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('sunday', 0, 24, event)}
                                    value={this.state.sunday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                              </div>
                              <span style={{marginTop: '0.2vw', fontSize: '0.8vw'}}><b>Contracturen</b>: {Constants.msToTime2(totalHours * 1000 * 60 * 60)}</span>
                            </div>

                          </div>
                      
                        : 

                          <div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Contracturen</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={'inputField2'}
                                  onChange={(event) => this.onChangeNumberDataFloat('hours', 0, 168, event)}
                                  value={this.state.hours}
                                  placeholder={'Contracturen'}
                                  type='number'
                                  step={0.01}
                                />
                              </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Aantal werkdagen</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={Constants.isEmpty(this.state.hours) === false ? 'inputField2' : 'inputField2Disabled'}
                                  onChange={(event) => this.onChangeNumberDataFloat('workDayHoursDays', 1, 7, event)}
                                  value={this.state.workDayHoursDays}
                                  placeholder={'Werkdagen'}
                                  type='number'
                                  step={1}
                                  disabled={Constants.isEmpty(this.state.hours)}
                                />
                              </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Arbeidsduur per werkdag</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={'inputField2Disabled'}
                                  onChange={(event) => this.onChangeNumberDataFloat('workDayHours', 0, 24, event)}
                                  value={this.state.workDayHours}
                                  placeholder={'Arbeidsduur'}
                                  type='number'
                                  step={0.01}
                                  disabled
                                />
                              </div>

                            </div>

                          </div>
                      
                        }

                      </div>

                        // <div className='modal-row' style={{marginTop: '2vw'}}>

                        //     <div className='modal-rowSub1'>
                        //         <span><b>Contracturen</b></span>
                        //     </div>

                        //     <div className='modal-rowSub2'>
                        //         <input
                        //             className={'inputField2'}
                        //             onChange={(event) => this.onChangeNumberDataFloat('hours', 0, 168, event)}
                        //             value={this.state.hours}
                        //             placeholder={'Contracturen'}
                        //             type='number'
                        //             step={0.01}
                        //         />
                        //     </div>

                        // </div>

                    : null}

                    {this.state.type === '2' ?

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Maandloon</b></span>
                            </div>

                            <div className='modal-rowSub2'>
                                <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('salaryMonth', 0, 999999999, event)}
                                    value={this.state.salaryMonth}
                                    placeholder={'Maandloon'}
                                    type='number'
                                    step={0.01}
                                />
                            </div>

                        </div>

                    :

                        <div>

                            <div className='modal-row2' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1_padding'>
                                    <span><b>Loonmodel</b></span>
                                </div>

                                <div className='modal-rowSub2'>

                                    <select
                                        name="Selecteer een loonmodel"
                                        className={'dropDownField'}
                                        style={{width: '100%', fontSize: '0.8vw'}}
                                        onChange={(event) => this.selectSalaryModel(event)}
                                    >
                                        <option value={'null'} selected={this.state.salaryModel === null}>Geen</option>
                                        {this.getSalaryModelBars()}
                                    </select>

                                    <span className='defaultLinkO' style={{marginTop: 2}} onClick={() => this.props.history.push({pathname: '/app_options', menu: 'general', subMenu: 'salaries'})}>Beheer loonmodellen</span>

                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Uurloon</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <input
                                        className={this.state.salaryModel !== null ? 'inputField2Disabled' : 'inputField2'}
                                        onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event)}
                                        value={this.state.salary}
                                        placeholder={'Uurloon'}
                                        type='number'
                                        step={0.01}
                                        disabled={this.state.salaryModel !== null}
                                    />
                                </div>

                            </div>

                        </div>

                    }

                    {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?

                        <div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Verlof opbouwen</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={this.state.leaveBalance}
                                            onClick={() => this.setState({ leaveBalance: !this.state.leaveBalance })}
                                            readOnly
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>

                            {this.state.leaveBalance === true ?

                                <div className='modal-row2' style={{marginTop: '1vw'}}>

                                    <div className='modal-rowSub1'>
                                        <span><b>Verlofdagen</b></span>
                                    </div>

                                    <div className='modal-rowSub2'>

                                        <div className='hr_hoursBar' style={{width: '100%', fontSize: '0.8vw', fontWeight: 'bold'}}>

                                          <div className='hr_hoursBar_sub1'>
                                            <span>Saldo</span>
                                          </div>

                                          <div className='hr_hoursBar_sub3'>
                                            <span>Opbouw per uur</span>
                                          </div>

                                          <div className='hr_hoursBar_sub3'>
                                            &nbsp;
                                          </div>

                                        </div>
                                        
                                        {this.renderUserLeaveBalances()}

                                    </div>

                                </div>

                            : null}

                        </div>

                    : null}

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}
   
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeContractModal()}>
                    <span>Annuleren</span>
                </div>

                {this.modalMode === 1 ?
                    <div className='modal-submitButton' onClick={() => this.editContract()}>
                        <span>Wijzigen</span>
                    </div>
                :
                this.modalMode === 3 ?
                    <div className='modal-submitButton' onClick={() => this.addContract()}>
                        <span>Vernieuwen</span>
                    </div>
                :
                    <div className='modal-submitButton' onClick={() => this.addContract()}>
                        <span>Toevoegen</span>
                    </div>
                }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default ContractModal;