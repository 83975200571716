/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './requests.css'
import '../myHours/myHours.css';
import '../../../components/smartPlanModal/smartPlanModal.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../employees/employees.css';
import '../hoursRegistration/hoursRegistration.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';

import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetOwnHolidays from '../../../classes/APIGetOwnHolidays';
import APIGetAllHolidaysYear from '../../../classes/APIGetAllHolidaysYear';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import Colors from '../../../constants/Colors';

import { Chart } from "react-google-charts";

import { Tooltip } from 'react-tippy';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';
import APIGET from '../../../classes/global/APIGET';
import APIGetAllContracts from '../../../classes/APIGetAllContracts';
import AddHolidayModal from '../holidayRequests/addHolidayModal';
import APIEditHoliday from '../../../classes/APIEditHoliday';
import KodictNotifications from '../../../constants/KodictNotifications';
import APIADD from '../../../classes/global/APIADD';
import APIDELETE from '../../../classes/global/APIDELETE';
import APITradeShift from '../../../classes/APITradeShift';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIGetAllSkillSchedules from '../../../classes/APIGetAllSkillSchedules';
import APIGetAllPartSchedules from '../../../classes/APIGetAllPartSchedules';
import APIGetAllPartDayShifts from '../../../classes/APIGetAllPartDayShifts';
import APIGetAllSkillsUsers from '../../../classes/APIGetAllSkillsUsers';
import APIGetAllSkillDayShifts from '../../../classes/APIGetAllSkillDayShifts';
import APIDeleteFromAcceptedShift from '../../../classes/APIDeleteFromAcceptedShift';
import APIDeleteFromAvailableShift from '../../../classes/APIDeleteFromAvailableShift';
import APIAddPartSchedule from '../../../classes/APIAddPartSchedule';
import APIAddSkillSchedule from '../../../classes/APIAddSkillSchedule';
import planClass from '../../planner/plan.class';
import Localization from '../../../constants/Localization';
import __ from '../../../constants/__';
import ___ from '../../../constants/___';

class Requests extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    const paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : 'trades';
    const paramHighlight = this.props.location.highlight !== undefined && this.props.location.highlight !== null ? this.props.location.highlight : null;

    this.teamUsers = [];

    this.hoursRef = {};

    this.lastDeleted = null;
    this.lastVal = '';

    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    this.timeNow = new Date();
    this.absAmount = {};

    // compwillmount
    
    this.state = {

      highlight: paramHighlight,

      loading: true,
      reloading: false,

      timeNow: new Date(),

      year: year,

      accounts: [],
      holidays: {},
      totalDays: {},
      totalHours: {},
      contracts: {},

      selectedMonth: null,
      selectedWeek: null,

      menu: paramMenu,

      freeHours: {},

      chartData: [],
      chartLength: 0,

      showModal: false,
      modalMode: 0,
      modalData: null,
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAddHolidayModal: false,

      showAlert: false,

      workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
      workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
      workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
      workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
      workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
      workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
      workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

      infoExtra: {},

      minYear: year,
      maxYear: year,

      reqStart: date,
      reqEnd:  new Date(date.getFullYear(), date.getMonth(), date.getDate() + 365),
      reqAccepted: '0', // all, 0, 1, 2, 3 = accepted and declined
      reqHolidays: 'all', // all, holidays, freehours
      reqEmployee: 'all',
      reqSort: `ORDER BY vakantie_geaccepteerd, vakantie_begin`,

      reqTradeStart: date,
      reqTradeEnd: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 365),
      reqTradeAccepted: '1', // all, 0, 1, 2, 3 = accepted and declined
      reqTradeEmployee: 'all',
      reqTradeSort: `ORDER BY rs_geaccepteerd, s1.rooster_datum, s2.rooster_datum`,

      holidayRequests: [],
      tradeRequests: [],
      availableShiftRequests: {},
      availabilityRequests: {},

      standardAvailabilities: {},

      absAmount: {},
      absAmountReport: {},

      skillsUser: {},
      skillsSchedule: {},
      skillsDayShifts: {},
      partSchedules: {},
      partDayShifts: {},

      showUsers: {},

      leaveBalances: [],
      leaveTypeBalances: {},
      contractLeaveBalances: {},
      leaveCorrections: {},

      userLeaveBalances: {},

      allUsersHours: {},

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    this.setState({ selectedMonth: null, selectedWeek: null, loading: true});

    //infoextra here

    const timeNow = await Constants.getDateNow();
    this.timeNow = timeNow;

    let [
      infoExtra,
      accounts,
      freeHours,
      contracts,
      absAmount,
      absAmountReport,
      partDayShifts,
      skillsUser,
      skillsDayShifts,
      contractLeaveBalances,
      leaveTypeBalances,
      leaveBalances,
      leaveCorrections,
      allUsersHours
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM VerlofUren`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null),
      APIGetAllPartDayShifts.Request(),
      APIGetAllSkillsUsers.Request(),
      APIGetAllSkillDayShifts.Request(),
      APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM CorrectieVerlof`, null, null, null, `ORDER BY cv_datum DESC`),
      Constants.calculateAllHours_users(undefined, undefined, this.timeNow),
    ]);

    const partDayShiftsObj = Constants.convertToObject(partDayShifts, 'dagdienst_id');
    const skillsUserObj = Constants.convertToObjectWithArrays(skillsUser, 'info_id');
    const skillsDayShiftsObj = Constants.convertToObjectWithArrays(skillsDayShifts, 'dagdienst_id');

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    const freeHoursObj = Constants.convertToObject(freeHours, 'vu_vakantie_id');
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');
    const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');
    const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vb_id');
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');
    const leaveCorrectionsObj = Constants.convertToObjectWithArrays(leaveCorrections, 'cv_info_id');

    this.absAmount = absAmountObj;

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamUsers = await APIGetTeamUsers.Request();
      accounts = Constants.getTeamAccounts(this.teamUsers, accounts);

    }

    this.setState({
      accounts: accounts,
      contracts: contractsObj,
      absAmount: absAmountObj,
      absAmountReport: absAmountReportObj,
      freeHours: freeHoursObj,
      infoExtra: infoExtraObj,

      partDayShifts: partDayShiftsObj,
      skillsUser: skillsUserObj,
      skillsDayShifts: skillsDayShiftsObj,

      timeNow: timeNow,

      infoExtra: infoExtraObj,

      contractLeaveBalances: contractLeaveBalancesObj,
      leaveTypeBalances: leaveTypeBalancesObj,
      leaveBalances: leaveBalances,
      leaveCorrections: leaveCorrectionsObj,

      allUsersHours: allUsersHours,

    }, async () => {

      await this.getHolidayRequestsData();
      await this.getTradeRequestsData();
      await this.getAvailableRequestsData();
      await this.getAvailabilityRequestsData();

      this.setState({ loading: false, });

    });

  }

  async getHolidayRequestsData() {

    let holidayRequests = [];
    let where = `WHERE (vakantie_begin BETWEEN '${Constants.dateToString(this.state.reqStart)}' AND '${Constants.dateToString(this.state.reqEnd)}' OR vakantie_eind BETWEEN '${Constants.dateToString(this.state.reqStart)}' AND '${Constants.dateToString(this.state.reqEnd)}')`;
    let rightJoin = ``;

    if (this.state.reqAccepted !== 'all' && this.state.reqAccepted !== '3') {
      where += ` AND vakantie_geaccepteerd = ${this.state.reqAccepted}`;
    } else if (this.state.reqAccepted === '3') {
      where += ` AND (vakantie_geaccepteerd = 1 OR vakantie_geaccepteerd = 2)`;
    }

    if (this.state.reqEmployee !== 'all') {
      where += ` AND vakantie_info_id = ${this.state.reqEmployee}`;
    }

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    holidayRequests = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, 
      `FROM Vakantie`,
      `LEFT JOIN Info ON vakantie_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      null,
      this.state.reqSort
    );

    // const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    // const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    // this.absAmount = absAmountObj;

    // const absAmountReport = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    // const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    // const allHolidays = await APIGET.Request(`SELECT *`, `FROM Vakantie`, null, `WHERE vakantie_geaccepteerd = 1`, null, null);
    // const allHolidaysObj = Constants.convertToObjectWithArrays(allHolidays, 'vakantie_info_id');

    // let userLeaveBalances = {};
    // for (const acc of this.state.accounts) {

    //   if (typeof userLeaveBalances[acc.info_id] === 'undefined') {
    //     userLeaveBalances[acc.info_id] = null;
    //   }

    //   userLeaveBalances[acc.info_id] = await Constants.getLeaveBalance(this.state.contracts[acc.info_id], this.timeNow, acc.info_id, this.state.leaveBalances, this.state.leaveTypeBalances, this.state.contractLeaveBalances, allHolidaysObj, this.state.leaveCorrections, false, this.state.infoExtra);

    // }


    this.setState({
      holidayRequests: holidayRequests,
      // absAmount: absAmountObj,
      // absAmountReport: absAmountReportObj,

      // userLeaveBalances: userLeaveBalances,
    });

  }

  async getTradeRequestsData() {

    let tradeRequests = [];
    let where = `WHERE (s1.rooster_datum BETWEEN '${Constants.dateToString(this.state.reqTradeStart)}' AND '${Constants.dateToString(this.state.reqTradeEnd)}' OR s2.rooster_datum BETWEEN '${Constants.dateToString(this.state.reqTradeStart)}' AND '${Constants.dateToString(this.state.reqTradeEnd)}')`;
    let rightJoin = ``;

    if (this.state.reqTradeAccepted !== 'all' && this.state.reqTradeAccepted !== '5') {
      where += ` AND rs_geaccepteerd = ${this.state.reqTradeAccepted}`;
    } else if (this.state.reqTradeAccepted === '5') {
      where += ` AND (rs_geaccepteerd = 2 OR rs_geaccepteerd = 4)`;
    }

    if (this.state.reqTradeEmployee !== 'all') {
      where += ` AND (rs_info_id_to = ${this.state.reqTradeEmployee} OR rs_info_id_from = ${this.state.reqTradeEmployee})`;
    }

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = s1.rooster_id`;
      where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    tradeRequests = await APIGET.Request(
      `SELECT i1.info_id AS id1, i1.info_voornaam AS v1, i1.info_achternaam AS a1, i1.info_tussenvoegsel AS tv1, i2.info_id AS id2, i2.info_voornaam AS v2, i2.info_achternaam AS a2, i2.info_tussenvoegsel AS tv2, s1.rooster_id AS sid1, s1.rooster_datum AS sd1, s1.rooster_begin AS sb1, s1.rooster_eind AS se1, s2.rooster_id AS sid2, s2.rooster_datum AS sd2, s2.rooster_begin AS sb2, s2.rooster_eind AS se2, f1.functie_naam AS fn1, f2.functie_naam AS fn2, Ruilshift.*`, 
      `FROM Ruilshift`,
      `LEFT JOIN Info AS i1 ON rs_info_id_from = i1.info_id LEFT JOIN Info AS i2 ON rs_info_id_to = i2.info_id LEFT JOIN Rooster AS s1 ON rs_rooster_id_from = s1.rooster_id LEFT JOIN Rooster AS s2 ON rs_rooster_id_to = s2.rooster_id LEFT JOIN Functie AS f1 ON s1.rooster_functie_id = f1.functie_id LEFT JOIN Functie AS f2 ON s2.rooster_functie_id = f2.functie_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      null,
      this.state.reqTradeSort
    );

    this.setState({
      tradeRequests: tradeRequests,
    });

  }

  async getAvailableRequestsData() {

    let accepted = [];
    let acceptedPlanner = [];
    let wherePlanner = `WHERE shb_datum >= '${Constants.dateToString(this.state.timeNow)}'`;
    let whereUser = `WHERE rooster_datum >= '${Constants.dateToString(this.state.timeNow)}'`;
    let rightJoinUser = ``;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoinUser = ` LEFT JOIN TeamRooster ON teamr_rooster_id = bdienst_rooster_id`;
      whereUser += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
      wherePlanner += ` AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    // PLANNER
    acceptedPlanner = await APIGET.Request(
        `SELECT SBGeaccepteerd.*, ShiftBeschikbaar.*, DagDienst.*, f1.functie_naam AS fn1, f1.functie_id AS fid1, f2.functie_naam AS fn2, info_id, info_voornaam, info_achternaam, info_tussenvoegsel`,
        `FROM SBGeaccepteerd`,
        `LEFT JOIN ShiftBeschikbaar ON sbg_shb_id = shb_id LEFT JOIN DagDienst ON shb_dagdienst_id = dagdienst_id LEFT JOIN Functie AS f1 ON dagdienst_functie_id = f1.functie_id LEFT JOIN Info ON sbg_info_id = info_id LEFT JOIN Functie AS f2 ON info_functie_id = f2.functie_id`,
        wherePlanner,
        null,
        //this.state.reqSort,
        `ORDER BY shb_team_id, shb_datum, dagdienst_begin, dagdienst_eind, sbg_id`
    );

    // USER
    accepted = await APIGET.Request(
        `SELECT BDienstGeaccepteerd.*, BeschikbareDienst.*, Rooster.*, f1.functie_naam AS fn1, f1.functie_id AS fid1, f2.functie_naam AS fn2, i1.info_id AS id1, i1.info_voornaam AS v1, i1.info_achternaam AS a1, i1.info_tussenvoegsel AS tv1, i2.info_id AS id2, i2.info_voornaam AS v2, i2.info_achternaam AS a2, i2.info_tussenvoegsel AS tv2`,
        `FROM BDienstGeaccepteerd`,
        `LEFT JOIN BeschikbareDienst ON bdg_bdienst_id = bdienst_id LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id LEFT JOIN Functie AS f1 ON rooster_functie_id = f1.functie_id LEFT JOIN Info AS i1 ON bdienst_info_id = i1.info_id LEFT JOIN Info AS i2 ON bdg_info_id = i2.info_id LEFT JOIN Functie AS f2 ON i2.info_functie_id = f2.functie_id${rightJoinUser.length > 0 ? rightJoinUser : ''}`,
        whereUser,
        null,
        //this.state.reqSort,
        `ORDER BY rooster_datum, rooster_begin, rooster_eind, bdg_id`
    );

    let availableShiftRequests = {};
    let showUsers = this.state.showUsers;

    // availables planner
    for (const sbg of acceptedPlanner) {

      if (typeof showUsers['planner' + sbg.shb_id] === 'undefined') {
        showUsers['planner' + sbg.shb_id] = false;
      }

      if (typeof availableShiftRequests['planner' + sbg.shb_id] === 'undefined') {
        availableShiftRequests['planner' + sbg.shb_id] = [];
      }

      availableShiftRequests['planner' + sbg.shb_id].push(sbg);

    }

     // availables user
     for (const bdg of accepted) {

      if (typeof showUsers['user' + bdg.bdienst_id] === 'undefined') {
        showUsers['user' + bdg.bdienst_id] = false;
      }

      if (typeof availableShiftRequests['user' + bdg.bdienst_id] === 'undefined') {
        availableShiftRequests['user' + bdg.bdienst_id] = [];
      }

      availableShiftRequests['user' + bdg.bdienst_id].push(bdg);

    }

    const skillsSchedule = await APIGetAllSkillSchedules.Request(Constants.dateToString(this.state.timeNow), Constants.dateToString(new Date(this.state.timeNow.getFullYear() + 1, this.state.timeNow.getMonth(), this.state.timeNow.getDate())));
    const skillSchedulesObj = Constants.convertToObjectWithArrays(skillsSchedule, 'rooster_id');

    const partSchedules = await APIGetAllPartSchedules.Request(Constants.dateToString(this.state.timeNow), Constants.dateToString(new Date(this.state.timeNow.getFullYear() + 1, this.state.timeNow.getMonth(), this.state.timeNow.getDate())));
    const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

    this.setState({
      availableShiftRequests: availableShiftRequests,

      skillsSchedule: skillSchedulesObj,
      partSchedules: partSchedulesObj,

      showUsers: showUsers,
    });

  }

  async getAvailabilityRequestsData() {

    let rightJoin = ``;
    let where = ``;

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where = `WHERE teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    const availabilityRequests = await APIGET.Request(
      `SELECT StandaardBeschikbaarheidWijziging.*, info_voornaam, info_id, info_achternaam, info_tussenvoegsel`, 
      `FROM StandaardBeschikbaarheidWijziging`,
      `LEFT JOIN Info ON sbw_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where.length > 0 ? where : null,
      null,
      `ORDER BY info_voornaam, info_achternaam, sbw_dag`
    );

    const availabilityRequestsObj = Constants.convertToObjectWithArrays(availabilityRequests, 'info_id');

    const standardAvailabilities = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, null, null, null);
    const standardAvailabilitiesObj = Constants.convertToObjectWithArrays(standardAvailabilities, 'sb_info_id');

    let showUsers = this.state.showUsers;

    for (const av of availabilityRequests) {
      if (typeof showUsers['av' + av.sbw_info_id] === 'undefined') {
        showUsers['av' + av.sbw_info_id] = false;
      }
    }

    this.setState({
      availabilityRequests: availabilityRequestsObj,
      standardAvailabilities: standardAvailabilitiesObj,

      showUsers: showUsers,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  closeModal() {

    this.setState({ 
      showAddHolidayModal: false,
      modalData: null,
    });

  }

  async reloadScreen() {

    this.setState({loading: true});

    await this.getHolidayRequestsData();

    this.setState({loading: false});

  }

  selectMenu(menu) {

    if (this.state.menu === menu || this.state.loading === true) {
      return;
    }

    this.setState({ menu: menu });

  }

  getWorkDays() {

    let days = 0;

    if (this.state.workWeekMonday === true) {
      days++;
    }
    if (this.state.workWeekTuesday === true) {
      days++;
    }
    if (this.state.workWeekWednesday === true) {
      days++;
    }
    if (this.state.workWeekThursday === true) {
      days++;
    }
    if (this.state.workWeekFriday === true) {
      days++;
    }
    if (this.state.workWeekSaturday === true) {
      days++;
    }
    if (this.state.workWeekSunday === true) {
      days++;
    }

    return days;

  }

  onChangeNumberDataFloat(what, min, max, event, data) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'overhours') {

      if (nr !== '') {
        this.hoursRef[data.vakantie_id].value = nr;
      } else {
        this.hoursRef[data.vakantie_id].value = '';
      }

    }

  }

  onChangeTime(e, i2, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.hoursRef;
    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times[i2].value = e.target.value;
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   const pos = e.target.selectionStart;
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
    //   times[i2].value = e.target.value;
    //   e.target.selectionStart = pos;
    //   e.target.selectionEnd = pos;
    //   return false;
    // }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (this.lastDeleted === ':') {
          val = val.slice(0, -1);
        }

        // if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
        //   val = val + ':';
        // }

        // if (val.length === 2 && this.lastVal.length === 3) {
        //   val = val.slice(0, 1);
        // }

        // if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
        //   val = val + ' - ';
        // }

        // if (val.length > 7 && val.includes(' - ') === false) {
        //   val = val.slice(0, 5);
        //   val = val + ' - ';
        // }

        // if (val.length === 7 && (this.lastVal.length === 8)) {
        //   val = val.slice(0, -3);
        // }

        // if (this.isValidForSave(val2)) {

        //   if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
        //     val = val + ':';
        //   }

        //   if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
        //     val = val.slice(0, -1);
        //   }

        // }

        if (val.length > 7) {
          e.target.value = val;
          times[i2].value = e.target.value;
          //this.setState({ timesStandard: times });
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times[i2].value = e.target.value;
        // this.setState({ timesStandard: times });

    } else {
      e.target.value = this.lastVal;
      times[i2].value = e.target.value;
      // this.setState({ timesStandard: times });
    }

  }

  isValidForSave(val) {

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    // if (val.length >= 0 && val.length <= 10) {
    //   val = val.substr(0, 5);
    // } else if (val.length >= 9) {
    //   val = val.substr(8, 13);
    // }

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs
      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 16 || e.keyCode === 186) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  getOpenBusinessDaysWeek() {

    let count = 0;

    if (this.state.workWeekMonday === true) {
      count++;
    }
    if (this.state.workWeekTuesday === true) {
      count++;
    }
    if (this.state.workWeekWednesday === true) {
      count++;
    }
    if (this.state.workWeekThursday === true) {
      count++;
    }
    if (this.state.workWeekFriday === true) {
      count++;
    }
    if (this.state.workWeekSaturday === true) {
      count++;
    }
    if (this.state.workWeekSunday === true) {
      count++;
    }

    return count;

  }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (typeof this.state.infoExtra[id] === 'undefined') {
      return 0;
    }

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  userWorksDay(data) {

    const date = Constants.stringToDate(data.vakantie_begin);

    if (typeof this.state.infoExtra[data.info_id] !== 'undefined') {

      const infoExtra = this.state.infoExtra[data.info_id];

      if (date.getDay() === 1 && infoExtra.info2_werkdag_maandag !== '1') {
        return false;
      } else if (date.getDay() === 2 && infoExtra.info2_werkdag_dinsdag !== '1') {
        return false;
      } else if (date.getDay() === 3 && infoExtra.info2_werkdag_woensdag !== '1') {
        return false;
      } else if (date.getDay() === 4 && infoExtra.info2_werkdag_donderdag !== '1') {
        return false;
      } else if (date.getDay() === 5 && infoExtra.info2_werkdag_vrijdag !== '1') {
        return false;
      } else if (date.getDay() === 6 && infoExtra.info2_werkdag_zaterdag !== '1') {
        return false;
      } else if (date.getDay() === 0 && infoExtra.info2_werkdag_zondag !== '1') {
        return false;
      }

    }

    return true;

  }

  getHolidayRequestBars() {

    let toRender = {
      open: [],
      accepted: [],
      declined: [],
    };

    let absWeek = {}; // { user: { week: 0 } }
    let absWeek2 = {}; // { user: { week: 0 } }

    let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));
    
    for (const data of this.state.holidayRequests) {

      const startDate = Constants.stringToDate(data.vakantie_begin);
      const endDate = Constants.stringToDate(data.vakantie_eind);
      const holidayDay = startDate.getDay();

      const sameDay = startDate.getTime() === endDate.getTime();

      const contract = Constants.getCurrentContract(this.state.contracts[data.info_id], startDate) === null ? Constants.getCurrentContract(this.state.contracts[data.info_id], endDate) : Constants.getCurrentContract(this.state.contracts[data.info_id], startDate);

      let reduceAmount = 0;

      // CALC EXPECTED REDUCE AMOUNT
      if (Constants.isEmpty(contract) === false && contract.contract_type !== '0') {

        const contractHours = parseFloat(contract.contract_uren);

        let workdays = 0;

        // fixed days
        if (Constants.isEmpty(contract) === false && Constants.isTrue(contract.contract_vaste_dagen)) {
          if (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false) {    workdays += 1;  }
          if (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false) {    workdays += 1;  }
          if (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false) {    workdays += 1;  }
          if (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false) {    workdays += 1;  }
          if (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false) {    workdays += 1;  }
          if (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false) {    workdays += 1;  }
          if (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false) {    workdays += 1;  }
        // no fixed days
        } else {
          const contract_workday_hours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : 8;
          workdays = Math.ceil(contractHours / contract_workday_hours);
        }

        if (workdays !== 0) {

          let currentWeek = Constants.getWeekNumber2(startDate);
          let daysUsed = 0;
          let workDayHours = 8;

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

            const year = currentDate.getFullYear();
            const week = Constants.getWeekNumber2(currentDate);
            const day = currentDate.getDay();
            const dayKey = day === 0 ? '7' : day.toString();

            if (currentWeek !== week) {
              currentWeek = week;
              daysUsed = 0;
            }

            const currentContract = Constants.getCurrentContract(this.state.contracts[data.info_id], currentDate);

            // if contract is not there, move on
            if (Constants.isEmpty(currentContract) === true) {
              index++;
              continue;
            }

            // set workDayHours
            // NO FIXED DAYS
            workDayHours = Constants.isEmpty(currentContract) === false && Constants.isEmpty(currentContract.contract_werkdag_uren) === false && currentContract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(currentContract.contract_werkdag_uren)) : workDayHours;

            // FIXED DAYS
            if (Constants.isEmpty(contract) === false && Constants.isTrue(currentContract.contract_vaste_dagen)) {
                workDayHours = day === 1 ? (Constants.isEmpty(currentContract.contract_maandag) === false && isNaN(parseFloat(currentContract.contract_maandag)) === false ? parseFloat(currentContract.contract_maandag) : 0)
                                : day === 2 ? (Constants.isEmpty(currentContract.contract_dinsdag) === false && isNaN(parseFloat(currentContract.contract_dinsdag)) === false ? parseFloat(currentContract.contract_dinsdag) : 0)
                                : day === 3 ? (Constants.isEmpty(currentContract.contract_woensdag) === false && isNaN(parseFloat(currentContract.contract_woensdag)) === false ? parseFloat(currentContract.contract_woensdag) : 0)
                                : day === 4 ? (Constants.isEmpty(currentContract.contract_donderdag) === false && isNaN(parseFloat(currentContract.contract_donderdag)) === false ? parseFloat(currentContract.contract_donderdag) : 0)
                                : day === 5 ? (Constants.isEmpty(currentContract.contract_vrijdag) === false && isNaN(parseFloat(currentContract.contract_vrijdag)) === false ? parseFloat(currentContract.contract_vrijdag) : 0)
                                : day === 6 ? (Constants.isEmpty(currentContract.contract_zaterdag) === false && isNaN(parseFloat(currentContract.contract_zaterdag)) === false ? parseFloat(currentContract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(currentContract.contract_zondag) === false && isNaN(parseFloat(currentContract.contract_zondag)) === false ? parseFloat(currentContract.contract_zondag) : 0);
            }

            if (daysUsed < workdays) {
              reduceAmount += (workDayHours * 1000 * 60 * 60);
            }

            daysUsed += 1;
            index++;

          }

        }

      }

      // BALANCE
      let leaveBalance = 0;
      
      const userLeaveBalance = this.state.allUsersHours[data.info_id].userLeaveBalances[0];

      if (Constants.isEmpty(userLeaveBalance) === false) {

        for (const yearKey in userLeaveBalance.total) {
          leaveBalance += userLeaveBalance.total[yearKey];
        }

      }

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

      if (data.vakantie_geaccepteerd === '0') { // open

        toRender.open.push(

          <div className='hr_timeRow' style={{backgroundColor: this.state.highlight === data.vakantie_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub1'>

              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.toLocaleDateString()}{sameDay === false ? ' - ' : ''}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.toLocaleDateString()}</span> : ''}</span>

              <Tooltip
                options
                html={(
                  <div className='appoptions_tooltipContainer'>
                    <span>{__('requests_tooltip_generalOverview')}</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginLeft: '0.6vw'}}
              >
                <i className='far fa-user-chart' style={{cursor: 'pointer', fontSize: '0.6vw'}} onClick={() => this.props.history.push({ pathname: `/reports`, menu: 'general', general_start: new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1), general_end: new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1), highlight: data.vakantie_id })} />
              </Tooltip>

              <Tooltip
                options
                html={(
                  <div className='appoptions_tooltipContainer'>
                    <span>{__('requests_tooltip_planner')}</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginLeft: '0.6vw'}}
              >
                <i className='far fa-calendar-day' style={{cursor: 'pointer', fontSize: '0.6vw'}} onClick={() => this.props.history.push({ pathname: `/planner`, selectedDate: startDate, highlight: data.info_id })} />
              </Tooltip>

            </div>

            {/* opmerking */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {Constants.isEmpty(data.vakantie_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{data.vakantie_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>
              :
                  <span>&nbsp;</span>
              }
            </div>

            {/* contracturen */}
            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
                {contract !== null && contract.contract_type === '1' ?
                  <i>{contract.contract_uren !== null ? Constants.msToTime2(Constants.round(parseFloat(contract.contract_uren)) * 1000 * 60 * 60) : ''}</i>
                :
                contract !== null && contract.contract_type === '2' ?
                  <i>{contract.contract_uren !== null ? Constants.msToTime2(Constants.round(parseFloat(contract.contract_uren)) * 1000 * 60 * 60) : ''}</i>
                : null}
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3'>
                {reduceAmount !== 0 ?
                  <input 
                    ref={(ref) => this.hoursRef[data.vakantie_id]=ref} 
                    disabled 
                    className={'hr_inputBoxTime_small_absence'}
                    value={'≈ ' + (Constants.msToTime2(reduceAmount))}
                  />
                :
                  <span></span>
                }
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3'>
                <b>{leaveBalance !== 0 ? `${leaveBalance < 0 ? '-' : ''}${Constants.msToTime2(leaveBalance)}` : ''}</b>
              </div>
            : null}

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.editHolidayAlert(contract, data, true)}>
                <i className='far fa-check' />
              </div>

              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.editHolidayAlert(contract, data, false)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='far fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1, cursor: 'pointer'}} onClick={() => this.setState({showAddHolidayModal: true, modalData: data})} />
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteHolidayAlert(data)} />
            </div>

          </div>

        );

      } else if (data.vakantie_geaccepteerd === '1') { // accepted

        toRender.accepted.push(

          <div className='hr_timeRow'>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.toLocaleDateString()}{sameDay === false ? ' - ' : ''}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.toLocaleDateString()}</span> : ''}</span>
            </div>

            {/* opmerking */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {Constants.isEmpty(data.vakantie_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{data.vakantie_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>
              :
                  <span>&nbsp;</span>
              }
            </div>

            {/* contracturen */}
            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
                {contract !== null && contract.contract_type === '1' ?
                  <i>{contract.contract_uren !== null ? Constants.msToTime2(Constants.round(parseFloat(contract.contract_uren)) * 1000 * 60 * 60) : ''}</i>
                :
                contract !== null && contract.contract_type === '2' ?
                  <i>{contract.contract_uren !== null ? Constants.msToTime2(Constants.round(parseFloat(contract.contract_uren)) * 1000 * 60 * 60) : ''}</i>
                : null}
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3'>
                {reduceAmount !== 0 ?
                  <input 
                    ref={(ref) => this.hoursRef[data.vakantie_id]=ref} 
                    disabled 
                    className={'hr_inputBoxTime_small_absence'}
                    value={'≈ ' + (Constants.msToTime2(reduceAmount))}
                  />
                :
                  <span></span>
                }
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3'>
                <b>{leaveBalance !== 0 ? `${leaveBalance < 0 ? '-' : ''}${Constants.msToTime2(leaveBalance)}` : ''}</b>
              </div>
            : null}

            <div className='hr_hoursBar_sub3'>

              <div className='hr_declineButton' onClick={() => this.editHolidayAlert(contract, data, false)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='far fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1, cursor: 'pointer'}} onClick={() => this.setState({showAddHolidayModal: true, modalData: data})} />
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteHolidayAlert(data)} />
            </div>

          </div>

        );

      } else if (data.vakantie_geaccepteerd === '2') { // declined

        toRender.declined.push(

          <div className='hr_timeRow'>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.toLocaleDateString()}{sameDay === false ? ' - ' : ''}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.toLocaleDateString()}</span> : ''}</span>
            </div>

            {/* opmerking */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {Constants.isEmpty(data.vakantie_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{data.vakantie_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>
              :
                  <span>&nbsp;</span>
              }
            </div>

            {/* contracturen */}
            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
                {contract !== null && contract.contract_type === '1' ?
                  <i>{contract.contract_uren !== null ? Constants.msToTime2(Constants.round(parseFloat(contract.contract_uren)) * 1000 * 60 * 60) : ''}</i>
                :
                contract !== null && contract.contract_type === '2' ?
                  <i>{contract.contract_uren !== null ? Constants.msToTime2(Constants.round(parseFloat(contract.contract_uren)) * 1000 * 60 * 60) : ''}</i>
                : null}
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3'>
                {reduceAmount !== 0 ?
                  <input 
                    ref={(ref) => this.hoursRef[data.vakantie_id]=ref} 
                    disabled 
                    className={'hr_inputBoxTime_small_absence'}
                    value={'≈ ' + (Constants.msToTime2(reduceAmount))}
                  />
                :
                  <span></span>
                }
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className='hr_hoursBar_sub3'>
                <b>{leaveBalance !== 0 ? `${leaveBalance < 0 ? '-' : ''}${Constants.msToTime2(leaveBalance)}` : ''}</b>
              </div>
            : null}

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.editHolidayAlert(contract, data, true)}>
                <i className='far fa-check' />
              </div>

              <div className='hr_invisibleButton' style={{marginLeft: '6%'}}>
                &nbsp;
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='far fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1, cursor: 'pointer'}} onClick={() => this.setState({showAddHolidayModal: true, modalData: data})} />
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteHolidayAlert(data)} />
            </div>

          </div>

        );

      }

    }

    return toRender;

  }

  editHolidayAlert(contract, data, accepted) {

    this.setState({
        alertTitle: accepted === true ? __('alert_approve_title') : __('alert_deny_title'),
        alertBody: accepted === true ? __('alert_approve') : __('alert_deny'),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), accepted === true ? __('button_approve') : __('button_deny')],
        alertButtonAction: this.editHoliday.bind(this, contract, data, accepted),
        showAlert: true,
    });

  }

  async editHoliday(contract, data, accepted) {

    this.setState({reloading: true});

    // if(this.checkValidations() === false) {
    //   this.setState({loading: false});
    //   return;
    // }

    const startDate = Constants.stringToDate(data.vakantie_begin);
    const endDate = Constants.stringToDate(data.vakantie_eind);

    const sameDay = startDate.getTime() === endDate.getTime();

    if (accepted === true) {
      await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_info_id = NULL, rooster_publiceren = 3`, `WHERE rooster_info_id = ${data.info_id} AND rooster_datum BETWEEN '${data.vakantie_begin}' AND '${data.vakantie_eind}'`);
    }

    const added = await APIEditHoliday.Request(
      data.vakantie_begin,
      data.vakantie_eind,
      data.vakantie_opmerking,
      accepted === true ? 1 : 2,
      data.vakantie_id
    );

    if(added === true) {

      await KodictNotifications.sendPushNotification(
        data.info_id,
        'holiday',
        __('noti_holiday_title'),
        Localization.t.formatString(accepted ? __('noti_holidayRequest_approved') : __('noti_holidayRequest_denied'), `${startDate.toLocaleDateString()}${sameDay === true ? `` : ` - ${endDate.toLocaleDateString()}`}`),
      );

      if (accepted === true && contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && Constants.isTrue(contract.contract_opbouw_verlof)) {

        //const absenceHours = parseFloat(this.hoursRef[data.vakantie_id].value);

        if (Constants.isEmpty(this.hoursRef[data.vakantie_id]) === false) {

          const absenceHours2 = this.hoursRef[data.vakantie_id].value;
          let absenceHours = Constants.timeStringToFloat(absenceHours2, false);
          absenceHours = isNaN(absenceHours) ? parseFloat(absenceHours2) : absenceHours;
          absenceHours = isNaN(absenceHours) ? 0 : absenceHours;

          //  && absenceHours > 0
          if (isNaN(absenceHours) === false) {
            await APIADD.Request(`INSERT INTO VerlofSaldo`, `VALUES (NULL, ${data.info_id}, ${data.vakantie_id}, ${absenceHours}, ${Constants.isEmpty(data.vakantie_vt_id) === false ? data.vakantie_vt_id : 'NULL'})`);
          }

        }

      } else if (data.vakantie_geaccepteerd === '1') {
        await APIDELETE.Request(`DELETE FROM VerlofSaldo`, `WHERE vs_vakantie_id = ${data.vakantie_id}`);
      }

      this.setState({loading: true});

      await this.getHolidayRequestsData();

    } else {

      // console.log('error');

      this.setState({loading: false, reloading: false});

    }

    this.setState({loading: false, reloading: false});

  }

  deleteHolidayAlert(data) {

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: __('alert_delete'),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_delete')],
        alertButtonAction: this.deleteHoliday.bind(this, data),
        showAlert: true,
    });

  }

  async deleteHoliday(data) {

    this.setState({reloading: true});

    // if(this.checkValidations() === false) {
    //   this.setState({loading: false});
    //   return;
    // }

    const startDate = Constants.stringToDate(data.vakantie_begin);
    const endDate = Constants.stringToDate(data.vakantie_eind);

    const sameDay = startDate.getTime() === endDate.getTime();

    const added = await APIDELETE.Request(`DELETE FROM Vakantie`, `WHERE vakantie_id = ${data.vakantie_id}`);

    if(added === true) {

      await KodictNotifications.sendPushNotification(
        data.info_id,
        'holiday',
        __('noti_holiday_title'),
        Localization.t.formatString(__('noti_holidayRequest_deleted'), `${startDate.toLocaleDateString()}${sameDay === true ? `` : ` - ${endDate.toLocaleDateString()}`}`),
      );

      await this.getHolidayRequestsData();

    } else {

      this.setState({reloading: false});

    }

    this.setState({reloading: false});

  }

  getTradeRequestBars() {

    let toRender = {
      open: [],
      accepted: [],
      declined: [],
    };
    
    for (const data of this.state.tradeRequests) {

      const date1 = Constants.stringToDate(data.sd1);
      const date2 = Constants.stringToDate(data.sd2);

      const userFullName1 = `${data.v1} ${(data.tv1 !== null && data.tv1 !== '') ? data.tv1 + " " + data.a1.substr(0, 1) : data.a1.substr(0, 1)}.`;
      const userFullName2 = `${data.v2} ${(data.tv2 !== null && data.tv2 !== '') ? data.tv2 + " " + data.a2.substr(0, 1) : data.a2.substr(0, 1)}.`;

      if (data.rs_geaccepteerd === '1') { // open

        toRender.open.push(

          <div className='hr_timeRow' style={{height: '2.5vw', backgroundColor: this.state.highlight === data.rs_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>

            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName1}</span>
              <span style={{color: Colors.color.greenFilledIn}}>{userFullName2}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date1).toLowerCase()} {date1.toLocaleDateString()} ({data.sb1.substr(0, 5)} - {data.se1.substr(0, 5)}) ({data.fn1})</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName2}</span>
              <span style={{color: Colors.color.greenFilledIn}}>{userFullName1}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date2).toLowerCase()} {date2.toLocaleDateString()} ({data.sb2.substr(0, 5)} - {data.se2.substr(0, 5)}) ({data.fn2})</span>
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.acceptTradeAlert(data)}>
                <i className='far fa-check' />
              </div>

              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyTradeAlert(data)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteTradeAlert(data)} />
            </div>

          </div>

        );

      } else if (data.rs_geaccepteerd === '2') { // accepted

        toRender.accepted.push(

          <div className='hr_timeRow' style={{height: '2.5vw', backgroundColor: this.state.highlight === data.rs_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>

            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName1}</span>
              <span style={{color: Colors.color.greenFilledIn}}>{userFullName2}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date1).toLowerCase()} {date1.toLocaleDateString()} ({data.sb1.substr(0, 5)} - {data.se1.substr(0, 5)}) ({data.fn1})</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName2}</span>
              <span style={{color: Colors.color.greenFilledIn}}>{userFullName1}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date2).toLowerCase()} {date2.toLocaleDateString()} ({data.sb2.substr(0, 5)} - {data.se2.substr(0, 5)}) ({data.fn2})</span>
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyTradeAlert(data)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteTradeAlert(data)} />
            </div>

          </div>

        );

      } else if (data.rs_geaccepteerd === '4') { // declined

        toRender.declined.push(

          <div className='hr_timeRow' style={{height: '2.5vw', backgroundColor: this.state.highlight === data.rs_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>

            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName1}</span>
              <span style={{color: Colors.color.greenFilledIn}}>{userFullName2}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date1).toLowerCase()} {date1.toLocaleDateString()} ({data.sb1.substr(0, 5)} - {data.se1.substr(0, 5)}) ({data.fn1})</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{textDecoration: 'line-through', color: Colors.color.redFilledIn}}>{userFullName2}</span>
              <span style={{color: Colors.color.greenFilledIn}}>{userFullName1}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date2).toLowerCase()} {date2.toLocaleDateString()} ({data.sb2.substr(0, 5)} - {data.se2.substr(0, 5)}) ({data.fn2})</span>
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.acceptTradeAlert(data)}>
                <i className='far fa-check' />
              </div>

              <div className='hr_invisibleButton' style={{marginLeft: '6%'}}>
                 &nbsp;
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteTradeAlert(data)} />
            </div>

          </div>

        );

      }

    }

    return toRender;

  }

  denyTradeAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: __('alert_deny_title'),
      alertBody: (
        <div>

          {__('alert_deny')}

        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_deny')],
      alertButtonAction: this.denyTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async denyTrade(trade) {

      if(this.state.loading === true) {
          return;
      }

      this.setState({loading: true});

      const deleted = await APIEDIT.Request(`UPDATE Ruilshift`, `SET rs_geaccepteerd = 4`, `WHERE rs_id = ${trade.rs_id}`);
  
      if (deleted === true) {
  
          if (trade.rs_geaccepteerd === '2') {
              await APITradeShift.Request(trade.rs_info_id_to, trade.rs_rooster_id_to);
              await APITradeShift.Request(trade.rs_info_id_from, trade.rs_rooster_id_from);
          }
  
          await this.getTradeRequestsData();
    
          await KodictNotifications.sendPushNotification(trade.rs_info_id_from, 'trade', __('noti_trade_title'), Localization.t.formatString(__('noti_tradeRequest_staff_denied'), Data.data.accountData.info_voornaam, trade.v2));
          await KodictNotifications.sendPushNotification(trade.rs_info_id_to, 'trade', __('noti_trade_title'), Localization.t.formatString(__('noti_tradeRequest_staff_denied'), Data.data.accountData.info_voornaam, trade.v1));
  
      }
  
      this.setState({loading: false});

  }

  acceptTradeAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: __('alert_approve_title'),
      alertBody: (
        <div>

          {__('alert_approve')}

        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_approve')],
      alertButtonAction: this.acceptTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async acceptTrade(trade) {

      if(this.state.loading === true) {
          return;
      }

      this.setState({loading: true});

      const deleted = await APIEDIT.Request(`UPDATE Ruilshift`, `SET rs_geaccepteerd = 2`, `WHERE rs_id = ${trade.rs_id}`);
  
      if (deleted === true) {
  
          await APITradeShift.Request(trade.rs_info_id_from, trade.rs_rooster_id_to);
          await APITradeShift.Request(trade.rs_info_id_to, trade.rs_rooster_id_from);
  
          await this.getTradeRequestsData();
  
          const tradeDate = Constants.stringToDate(trade.sd1);
          const tradeDate2 = Constants.stringToDate(trade.sd2);

          await KodictNotifications.sendPushNotification(trade.rs_info_id_from, 'trade', __('noti_trade_title'), Localization.t.formatString(__('noti_tradeRequest_staff_approved'), Data.data.accountData.info_voornaam, trade.v2, `${Constants.getShortDayName(tradeDate2).toLowerCase()} ${tradeDate2.toLocaleDateString()}`));
          await KodictNotifications.sendPushNotification(trade.rs_info_id_to, 'trade', __('noti_trade_title'), Localization.t.formatString(__('noti_tradeRequest_staff_approved'), Data.data.accountData.info_voornaam, trade.v1, `${Constants.getShortDayName(tradeDate).toLowerCase()} ${tradeDate.toLocaleDateString()}`));
  
      }
  
      this.setState({loading: false});

  }

  deleteTradeAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: (
        <div>

          {__('alert_delete')}

        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_delete')],
      alertButtonAction: this.deleteTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async deleteTrade(trade) {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    const deleted = await APIDELETE.Request(`DELETE FROM Ruilshift`, `WHERE rs_id = ${trade.rs_id}`);

    if (deleted === true) {

      if (trade.rs_geaccepteerd === '2') {

        await APITradeShift.Request(trade.rs_info_id_to, trade.rs_rooster_id_to);
        await APITradeShift.Request(trade.rs_info_id_from, trade.rs_rooster_id_from);
    
        await KodictNotifications.sendPushNotification(trade.rs_info_id_from, 'trade', __('noti_trade_title'), Localization.t.formatString(__('noti_tradeRequest_staff_deleted'), Data.data.accountData.info_voornaam, trade.v2));
        await KodictNotifications.sendPushNotification(trade.rs_info_id_to, 'trade', __('noti_trade_title'), Localization.t.formatString(__('noti_tradeRequest_staff_deleted'), Data.data.accountData.info_voornaam, trade.v1));

      }

      await this.getTradeRequestsData();

        // const tradeDate = Constants.stringToDate(trade.sd1);
        // const tradeDate2 = Constants.stringToDate(trade.sd2);

        // await KodictNotifications.sendPushNotification(trade.rs_info_id_from, 'trade', 'Ruilen', `${Data.data.accountData.info_voornaam} heeft het ruilverzoek van jou en ${trade.v2} goedgekeurd. Jouw nieuwe dienst is ${Constants.getShortDayName(tradeDate2).toLowerCase()} ${tradeDate2.getDate()} ${Constants.getShortMonthName(tradeDate2)}`);
        // await KodictNotifications.sendPushNotification(trade.rs_info_id_to, 'trade', 'Ruilen', `${Data.data.accountData.info_voornaam} heeft het ruilverzoek van jou en ${trade.v1} goedgekeurd. Jouw nieuwe dienst is ${Constants.getShortDayName(tradeDate).toLowerCase()} ${tradeDate.getDate()} ${Constants.getShortMonthName(tradeDate)}`);

    }

    this.setState({loading: false});

  }

  getAvailableShiftBars() {

    let toRender = {
      planner: [],
      users: [],
    };
    
    for (const key in this.state.availableShiftRequests) {

      // PLANNER
      if (key.toString().includes('planner')) {

        const data = this.state.availableShiftRequests[key][0];

        let skills = null;
        if (typeof this.state.skillsDayShifts[data.dagdienst_id] !== 'undefined') {
          skills = this.state.skillsDayShifts[data.dagdienst_id]; // = array
        }

        // const userFullName = `${data.v1} ${(data.tv1 !== null && data.tv1 !== '') ? data.tv1 + " " + data.a1.substr(0, 1) : data.a1.substr(0, 1)}.`;

        const date = Constants.stringToDate(data.shb_datum);

        toRender.planner.push(

          <div className='hr_timeRow_availableshift' style={{backgroundColor: this.state.highlight === data.shb_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>
  
            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date).toLowerCase()} {date.toLocaleDateString()} ({data.dagdienst_begin.substr(0, 5)} - {data.dagdienst_eind.substr(0, 5)}) ({data.fn1})</span>
            </div>
  
            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
              {this.getSkillsText(skills)}
            </div>
  
            <div className='hr_hoursBar_sub2'>
              &nbsp;
            </div>
  
            <div className='hr_hoursBar_sub2'>
              <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.setShowUsers(key)}>
                {`${this.state.availableShiftRequests[key].length} ${this.state.availableShiftRequests[key].length > 1 ? __('misc_employees').toLowerCase() : __('misc_employee').toLowerCase()}`}
              </div>
            </div>
  
            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
              {/* Functie */}
              &nbsp;
            </div>
  
            <div className='hr_hoursBar_sub2'>
              {/* Competenties  */}
              &nbsp;
            </div>
  
            <div className='hr_hoursBar_sub3'>

              &nbsp;

              {/* <div className='hr_acceptButton' onClick={() => this.acceptTradeAlert(data)}>
                <i className='far fa-check' />
              </div>
  
              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyTradeAlert(data)}>
                <i className='far fa-times' />
              </div> */}

            </div>
  
            <div className='hr_hoursBar_sub4' style={{alignItems: 'center'}}>
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteAvailableAlert(data, key.toString())} />
            </div>
  
          </div>
  
        );

        // push employees
        if (typeof this.state.showUsers[key] !== 'undefined' && this.state.showUsers[key] === true) {
          toRender.planner.push(this.renderAcceptedUsersPlanner(this.state.availableShiftRequests[key]));
        }

      // USER
      } else {

        const data = this.state.availableShiftRequests[key][0];

        let skills = null;
        if (typeof this.state.skillsSchedule[data.rooster_id] !== 'undefined') {
          skills = this.state.skillsSchedule[data.rooster_id]; // = array
        }

        let userFullName = 'Open dienst';
        if (Constants.isEmpty(data.id1) === false) {
          userFullName = `${data.v1} ${(data.tv1 !== null && data.tv1 !== '') ? data.tv1 + " " + data.a1.substr(0, 1) : data.a1.substr(0, 1)}.`;
        }

        const date = Constants.stringToDate(data.rooster_datum);

        toRender.users.push(

          <div className='hr_timeRow_availableshift' style={{backgroundColor: this.state.highlight === data.bdienst_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>
  
            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(date).toLowerCase()} {date.toLocaleDateString()} ({data.rooster_begin.substr(0, 5)} - {data.rooster_eind.substr(0, 5)}) ({data.fn1})</span>
            </div>
  
            <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
              {this.getSkillsText(skills)}
            </div>
  
            <div className='hr_hoursBar_sub2'>
              <span>{userFullName}</span>
            </div>
  
            <div className='hr_hoursBar_sub2'>
              <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.setShowUsers(key)}>
                {`${this.state.availableShiftRequests[key].length} ${this.state.availableShiftRequests[key].length > 1 ? __('misc_employees').toLowerCase() : __('misc_employee').toLowerCase()}`}
              </div>
            </div>
  
            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
              {/* Functie */}
              &nbsp;
            </div>
  
            <div className='hr_hoursBar_sub2'>
              {/* Competenties  */}
              &nbsp;
            </div>
  
            <div className='hr_hoursBar_sub3'>

              &nbsp;

              {/* <div className='hr_acceptButton' onClick={() => this.acceptTradeAlert(data)}>
                <i className='far fa-check' />
              </div>
  
              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyTradeAlert(data)}>
                <i className='far fa-times' />
              </div> */}

            </div>
  
            <div className='hr_hoursBar_sub4' style={{alignItems: 'center'}}>
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteAvailableAlert(data, key.toString())} />
            </div>
  
          </div>
  
        );

        // push employees
        if (typeof this.state.showUsers[key] !== 'undefined' && this.state.showUsers[key] === true) {
          toRender.users.push(this.renderAcceptedUsers(this.state.availableShiftRequests[key]));
        }

      }
    
    }

    return toRender;

  }

  setShowUsers(key) {

    let showUsers = this.state.showUsers;

    if (typeof showUsers[key] !== 'undefined') {
      showUsers[key] = !this.state.showUsers[key];
      this.setState({ showUsers: showUsers });
    }

  }

  checkDifferencesSalary(data, user) {

    const main = data[0];

    let price = 0;
    let hours = 0;
    let contract = null;
    let userMainContract = null;

    if (typeof main !== 'undefined') {

      if (typeof data[0].dagdienst_id !== 'undefined') { // shift beschikbaar (dagdienst)

        const contract1 = Constants.getCurrentContract(this.state.contracts[user.info_id], Constants.stringToDate(data[0].shb_datum));
        let salaryUser = 0;

        if (contract1 === null) {
          return null;
        }

        contract = contract1;

        const rData = {
          rooster_datum: data[0].shb_datum,
          rooster_begin: data[0].dagdienst_begin,
          rooster_eind: data[0].dagdienst_eind,
        };

        const startDate = Constants.stringToDateTime(data[0].shb_datum, data[0].dagdienst_begin);
        const endDate = Constants.stringToDateTime(planClass.isNightShift2(rData), data[0].dagdienst_eind);
        const _break = Constants.timeStringToMS(data[0].dagdienst_pauze);

        hours = ((endDate.getTime() - startDate.getTime()) - _break) / 1000 / 60 / 60;

        if (contract.contract_type !== '2' && Constants.isEmpty(contract1.contract_uurloon) === false) {

          salaryUser = parseFloat(contract.contract_bruto_uurloon) * hours;

          price = Constants.round(price + salaryUser);

        }

      } else { // bdienst // planner

        //const contract1 = this.state.contracts[main.bdienst_info_id];
        const contract1 = Constants.getCurrentContract(this.state.contracts[main.bdienst_info_id], Constants.stringToDate(main.rooster_datum));
        //const contract2 = this.state.contracts[user.id2];
        const contract2 = Constants.getCurrentContract(this.state.contracts[user.id2], Constants.stringToDate(main.rooster_datum));
        let salaryUser1 = 0;
        let salaryUser2 = 0;

        if (contract1 === null && contract2 === null) {
          return null;
        }

        // check contracts and set them
        if (contract1 !== null) {
          userMainContract = contract1;
        }
        if (contract2 !== null) {
          contract = contract2;
        }

        const startDate = Constants.stringToDateTime(main.rooster_datum, main.rooster_begin);
        const endDate = Constants.stringToDateTime(planClass.isNightShift2(main), main.rooster_eind);
        const _break = Constants.timeStringToMS(main.rooster_pauze);

        hours = ((endDate.getTime() - startDate.getTime()) - _break) / 1000 / 60 / 60;

        // check main contract and set salary
        if (userMainContract !== null) {
          if (userMainContract.contract_type !== '2' && Constants.isEmpty(userMainContract.contract_uurloon) === false) {
            salaryUser1 = parseFloat(userMainContract.contract_uurloon) * hours;
          }
        }

        if (contract !== null) {

          if (contract.contract_type !== '2' && Constants.isEmpty(contract.contract_uurloon) === false) {

            salaryUser2 = parseFloat(contract.contract_uurloon) * hours;

            price = Constants.round(salaryUser2 - salaryUser1);

          }
  
        }

      }

    }

    return Constants.isEmpty(contract) === false && contract.contract_type === '2' ? <span style={{color: Colors.color.redFilledIn}}>{`+ ${Constants.round(hours, true)} uren`}</span> : price > 0 ? <span style={{color: Colors.color.redFilledIn}}>{`+ ${this.formatter.format(price)}`}</span> : price < 0 ? <span style={{color: Colors.color.greenFilledIn}}>{`- ${this.formatter.format(Math.abs(price))}`}</span> : null;

  }

  renderAcceptedUsersPlanner(data) {

    let toRender = [];

    for (const user of data) {

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam.substr(0, 1) : user.info_achternaam.substr(0, 1)}.`;

      let skills = null;
      if (typeof this.state.skillsUser[user.info_id] !== 'undefined') {
        skills = this.state.skillsUser[user.info_id]; // = array
      }

      toRender.push(

        <div className='hr_timeRow_availableshift'>
    
          <div className='hr_hoursBar_sub1'>
            &nbsp;
          </div>
    
          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            &nbsp;
          </div>
    
          <div className='hr_hoursBar_sub2'>
            &nbsp;
          </div>
    
          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'stretch'}}>
            <b>{userFullName}</b>
            {Constants.isTrue(Data.data.appOptions.opties_financien) ? this.checkDifferencesSalary(data, user) : null}
          </div>
    
          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
            <span>{user.fn2}</span>
          </div>
    
          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            {this.getSkillsText(skills)}
          </div>
    
          <div className='hr_hoursBar_sub3'>

            <div className='hr_acceptButton' onClick={() => this.acceptUserAlert(data[0], user, true)}>
              <i className='far fa-check' />
            </div>
    
            <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyUserAlert(data[0], user, true)}>
              <i className='far fa-times' />
            </div>

          </div>
    
          <div className='hr_hoursBar_sub4' style={{alignItems: 'center'}}>
            &nbsp;
            {/* <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteTradeAlert(data)} /> */}
          </div>
    
        </div>

      );

    }

    return toRender;

  }

  renderAcceptedUsers(data) {

    let toRender = [];

    for (const user of data) {

      const userFullName = `${user.v2} ${(user.tv2 !== null && user.tv2 !== '') ? user.tv2 + " " + user.a2.substr(0, 1) : user.a2.substr(0, 1)}.`;

      let skills = null;
      if (typeof this.state.skillsUser[user.id2] !== 'undefined') {
        skills = this.state.skillsUser[user.id2]; // = array
      }

      toRender.push(

        <div className='hr_timeRow_availableshift'>
    
          <div className='hr_hoursBar_sub1'>
            &nbsp;
          </div>
    
          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            &nbsp;
          </div>
    
          <div className='hr_hoursBar_sub2'>
            &nbsp;
          </div>
    
          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', alignItems: 'stretch'}}>
            <b>{userFullName}</b>
            {Constants.isTrue(Data.data.appOptions.opties_financien) ? this.checkDifferencesSalary(data, user) : null}
          </div>
    
          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
            {user.fn2}
          </div>
    
          <div className='hr_hoursBar_sub2' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            {this.getSkillsText(skills)}
          </div>
    
          <div className='hr_hoursBar_sub3'>

            <div className='hr_acceptButton' onClick={() => this.acceptUserAlert(data[0], user, false)}>
              <i className='far fa-check' />
            </div>
    
            <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyUserAlert(data[0], user, false)}>
              <i className='far fa-times' />
            </div>

          </div>
    
          <div className='hr_hoursBar_sub4' style={{alignItems: 'center'}}>
            &nbsp;
            {/* <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteTradeAlert(data)} /> */}
          </div>
    
        </div>

      );

    }

    return toRender;

  }

  getSkillsText(skills) {

    if (skills === null) {
      return (<span>&nbsp;</span>);
    }

    let toRender = [];

    for (const skill of skills) {

      toRender.push(
        <span>• {skill.comp_naam}</span>
      );

    }

    return toRender;

  }

  acceptUserAlert(shift, user, planner) {

    this.setState({
      alertTitle: __('alert_approve_title'),
      alertBody: __('alert_approve2'),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_approve')],
      alertButtonAction: planner === true ? this.acceptUserPlanner.bind(this, user) : this.acceptUser.bind(this, shift, user),
      showAlert: true,
    });

  }

  denyUserAlert(shift, user, planner) {

      this.setState({
        alertTitle: __('alert_deny_title'),
        alertBody: __('alert_deny2'),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_deny')],
        alertButtonAction: planner === true ? this.denyUserPlanner.bind(this, user) : this.denyUser.bind(this, shift, user),
        showAlert: true,
      });
    
  }

  async acceptUser(shift, user) {

    this.setState({loading: true});

    const traded = await APITradeShift.Request(user.id2, shift.rooster_id);

    if (traded === true) {

        // await APIDeleteFromAvailableShift.Request(shift.bdienst_id);

        await APIDELETE.Request(`DELETE FROM BeschikbareDienst`, `WHERE bdienst_id = ${shift.bdienst_id}`);

        if (Constants.isEmpty(shift.id1) === true) {
          await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_publiceren = 1`, `WHERE rooster_id = ${shift.rooster_id}`);
        }

        const dateTrade = Constants.stringToDate(shift.rooster_datum);

        await APIDELETE.Request(`DELETE ${'`bdg`'} FROM BDienstGeaccepteerd ${'`bdg`'} LEFT JOIN BeschikbareDienst ON bdg_bdienst_id = bdienst_id LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE bdg_info_id = ${user.id2} AND rooster_datum = '${shift.rooster_datum}'`);
        await APIDELETE.Request(`DELETE ${'`sbg`'} FROM SBGeaccepteerd ${'`sbg`'} LEFT JOIN ShiftBeschikbaar ON sbg_shb_id = shb_id`, `WHERE sbg_info_id = ${user.id2} AND shb_datum = '${shift.rooster_datum}'`);

        await KodictNotifications.sendPushNotification(
            user.id2,
            'trade',
            __('noti_takeover_title'),
            Localization.t.formatString(__('noti_takeover_staff'), `${dateTrade.toLocaleDateString()} ${shift.rooster_begin.substr(0,5)} - ${shift.rooster_eind.substr(0,5)}`),
        );

        if (Constants.isEmpty(shift.id1) === false) {

          await KodictNotifications.sendPushNotification(
            shift.id1,
            'trade',
            __('noti_substitution_title'),
            Localization.t.formatString(__('noti_substitution_staff'), `${dateTrade.toLocaleDateString()} ${shift.rooster_begin.substr(0,5)} - ${shift.rooster_eind.substr(0,5)}`),
        );

        }

    }
    
    await this.getAvailableRequestsData();

    this.setState({loading: false});

  }

  async denyUser(shift, user) {

      this.setState({loading: true});

      await APIDeleteFromAcceptedShift.Request(user.id2, shift.bdienst_id);
      
      await this.getAvailableRequestsData();

      this.setState({loading: false});

  }

  async acceptUserPlanner(user) {

      this.setState({loading: true});

      const date = Constants.stringToDate(user.shb_datum);
      const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${user.shb_datum}', '${user.dagdienst_begin}', '${user.dagdienst_eind}', ${user.info_id}, ${user.fid1}, 1, '${user.dagdienst_pauze}', 0)`);

      if (added === true) {

          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

          await APIDELETE.Request(`DELETE ${'`sbg`'} FROM SBGeaccepteerd ${'`sbg`'} LEFT JOIN ShiftBeschikbaar ON sbg_shb_id = shb_id`, `WHERE sbg_info_id = ${user.info_id} AND shb_datum = '${user.shb_datum}'`);
          await APIDELETE.Request(`DELETE ${'`bdg`'} FROM BDienstGeaccepteerd ${'`bdg`'} LEFT JOIN BeschikbareDienst ON bdg_bdienst_id = bdienst_id LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE bdg_info_id = ${user.info_id} AND rooster_datum = '${user.shb_datum}'`);

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts[user.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, rid[0].rooster_id);
          }
          const skillsDayShift = this.state.skillsDayShifts[user.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, rid[0].rooster_id);
            }
          }

          await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${user.dagdienst_id} AND shb_datum = '${user.shb_datum}'`);

          //await APIAddUsedDayShift.Request(user.shb_datum, user.dagdienst_begin, user.dagdienst_eind, rid[0].rooster_id, user.fid1);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${user.shb_datum}', ${user.dagdienst_id}, ${rid[0].rooster_id})`);

          if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
              await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${user.shb_team_id}, ${rid[0].rooster_id})`)
          }

          await KodictNotifications.sendPushNotification(
              user.info_id,
              'trade',
              __('noti_availableShift_title'),
              Localization.t.formatString(__('noti_takeover_staff'), `${date.toLocaleDateString()} ${user.dagdienst_begin.substr(0,5)} - ${user.dagdienst_eind.substr(0,5)}`),
          );

      }
        
      await this.getAvailableRequestsData();

      this.setState({loading: false});

  }

  async denyUserPlanner(user) {

      this.setState({loading: true});

      await APIDELETE.Request(`DELETE FROM SBGeaccepteerd`, `WHERE sbg_info_id = ${user.info_id} AND sbg_shb_id = ${user.shb_id}`);
        
      await this.getAvailableRequestsData();

      this.setState({loading: false});

  }

  deleteAvailableAlert(data, key) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: (
        <div>

          {__('alert_delete')}

        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_delete')],
      alertButtonAction: this.deleteAvailableRequest.bind(this, data, key),
      showAlert: true,
    });

  }

  async deleteAvailableRequest(data, key) {

    if(this.state.loading === true) {
      return;
    }

    this.setState({loading: true});

    if (key.includes('planner')) {
      await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_id = ${data.shb_id}`);
    } else {
      await APIDELETE.Request(`DELETE FROM BeschikbareDienst`, `WHERE bdienst_id = ${data.bdienst_id}`);
    }

    await this.getAvailableRequestsData();

    this.setState({loading: false});

  }

  getAvailabilityRequestBars() {

    let toRender = [];
    
    for (const key in this.state.availabilityRequests) {

      const data = this.state.availabilityRequests[key][0];

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

      toRender.push(

        <div className='hr_timeRow'>

          <div className='hr_hoursBar_sub1'>
            <span>{userFullName}</span> 
          </div>

          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
            <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.setShowUsers('av' + data.sbw_info_id)}>
              {`${this.state.availabilityRequests[key].length} ${this.state.availabilityRequests[key].length > 1 ? __('misc_changes').toLowerCase() : __('misc_change').toLowerCase()}`}
            </div>
          </div>

          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
            {/* Van */}
            &nbsp;
          </div>

          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
            {/* Naar */}
            &nbsp;
          </div>

          <div className='hr_hoursBar_sub3'>
            {/* Goedkeuren */}
            &nbsp;
          </div>

          <div className='hr_hoursBar_sub4'>
            <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteUserAlertAvailability(data)} />
          </div>

        </div>

      );

      // push employees
      if (typeof this.state.showUsers['av' + data.sbw_info_id] !== 'undefined' && this.state.showUsers['av' + data.sbw_info_id] === true) {
        toRender.push(this.renderChangesAvailability(this.state.availabilityRequests[key]));
      }
    
    }

    return toRender;

  }

  renderChangesAvailability(data) {

    let toRender = [];

    for (const change of data) {

      let changeFrom = null;

      if (typeof this.state.standardAvailabilities[change.sbw_info_id] !== 'undefined') {
        for (const sa of this.state.standardAvailabilities[change.sbw_info_id]) {
          if (sa.sb_dag === change.sbw_dag) {
            changeFrom = sa;
          }
        }
      }

      const dayName = change.sbw_dag === '1' ? __('misc_monday') : change.sbw_dag === '2' ? __('misc_tuesday') : change.sbw_dag === '3' ? __('misc_wednesday') : change.sbw_dag === '4' ? __('misc_thursday') : change.sbw_dag === '5' ? __('misc_friday') : change.sbw_dag === '6' ? __('misc_saturday') : __('misc_sunday');

      if (changeFrom === null) {

        changeFrom = {
          sb_id: null,
          sb_dag: change.sbw_dag,
          sb_beschikbaar: '1',
          sb_begin: '00:00:00',
          sb_eind: '00:00:00',
          sb_info_id: change.sbw_info_id,
          sb_opmerking: '',
        };

      }

      if (changeFrom !== null) {

        toRender.push(

          <div className='hr_timeRow'>

            <div className='hr_hoursBar_sub1'>
              &nbsp;
            </div>

            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
              <b>{dayName}</b>
            </div>

            <div className='hr_hoursBar_sub3' style={{justifyContent: 'space-between'}}>
            
              {changeFrom.sb_begin === '00:00:00' && changeFrom.sb_eind === '00:00:00' ?
                <span style={{color: changeFrom.sb_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{__('misc_wholeDay')}</span>
              :
                <span style={{color: changeFrom.sb_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{changeFrom.sb_begin.substr(0, 5)} - {changeFrom.sb_eind.substr(0, 5)}</span>
              }

              <i className='fal fa-long-arrow-right' style={{color: '#47B7EB', fontSize: '1vw', marginTop: '1%', marginRight: '20%'}} />

            </div>

            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
             
              {change.sbw_begin === '00:00:00' && change.sbw_eind === '00:00:00' ?
                <span style={{color: change.sbw_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{__('misc_wholeDay')}</span>
              :
                <span style={{color: change.sbw_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{change.sbw_begin.substr(0, 5)} - {change.sbw_eind.substr(0, 5)}</span>
              }

            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.acceptUserAlertAvailability(change)}>
                <i className='far fa-check' />
              </div>
      
              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.denyUserAlertAvailability(change)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              &nbsp;
            </div>
      
          </div>

        );

      }

    }

    return toRender;

  }

  acceptUserAlertAvailability(change) {

    this.setState({
      alertTitle: __('alert_approve_title'),
      alertBody: __('alert_approve2'),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_approve')],
      alertButtonAction: this.acceptUserAvailability.bind(this, change),
      showAlert: true,
    });

  }

  denyUserAlertAvailability(change) {

      this.setState({
        alertTitle: __('alert_deny_title'),
        alertBody: __('alert_deny2'),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_deny')],
        alertButtonAction: this.denyUserAvailability.bind(this, change),
        showAlert: true,
      });
    
  }

  async acceptUserAvailability(change) {

    this.setState({loading: true});

    // const updated = await APIEDIT.Request(`UPDATE StandaardBeschikbaarheid`, `SET sb_beschikbaar = ${change.sbw_beschikbaar}, sb_begin = '${change.sbw_begin}', sb_eind = '${change.sbw_eind}'`, `WHERE sb_dag = ${change.sbw_dag} AND sb_info_id = ${change.sbw_info_id}`);

    await APIDELETE.Request(`DELETE FROM StandaardBeschikbaarheid`, `WHERE sb_dag = ${change.sbw_dag} AND sb_info_id = ${change.sbw_info_id}`);
    await APIDELETE.Request(`DELETE FROM StandaardBeschikbaarheidWijziging`, `WHERE sbw_id = ${change.sbw_id}`);
    await APIADD.Request(`INSERT INTO StandaardBeschikbaarheid`, `VALUES (NULL, ${change.sbw_dag}, ${change.sbw_beschikbaar}, '${change.sbw_begin}', '${change.sbw_eind}', ${change.sbw_info_id}, '${change.sbw_opmerking}')`);
    await KodictNotifications.sendPushNotification(change.sbw_info_id, 'news', 'Beschikbaarheid', `Jouw beschikbaarheid wijziging voor ${change.sbw_dag === '1' ? 'Maandag' : change.sbw_dag === '2' ? 'Dinsdag' : change.sbw_dag === '3' ? 'Woensdag' : change.sbw_dag === '4' ? 'Donderdag' : change.sbw_dag === '5' ? 'Vrijdag' : change.sbw_dag === '6' ? 'Zaterdag' : 'Zondag'} is goedgekeurd`);
    
    await this.getAvailabilityRequestsData();

    this.setState({loading: false});

  }

  async denyUserAvailability(change) {

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM StandaardBeschikbaarheidWijziging`, `WHERE sbw_id = ${change.sbw_id}`);
    await KodictNotifications.sendPushNotification(change.sbw_info_id, 'news', 'Beschikbaarheid', `Jouw beschikbaarheid wijziging voor ${change.sbw_dag === '1' ? 'Maandag' : change.sbw_dag === '2' ? 'Dinsdag' : change.sbw_dag === '3' ? 'Woensdag' : change.sbw_dag === '4' ? 'Donderdag' : change.sbw_dag === '5' ? 'Vrijdag' : change.sbw_dag === '6' ? 'Zaterdag' : 'Zondag'} is afgewezen`);
    
    await this.getAvailabilityRequestsData();

    this.setState({loading: false});

  }

  deleteUserAlertAvailability(change) {

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: __('alert_delete'),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_delete')],
      alertButtonAction: this.deleteUserAvailability.bind(this, change),
      showAlert: true,
    });

  }

  async deleteUserAvailability(change) {

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM StandaardBeschikbaarheidWijziging`, `WHERE sbw_info_id = ${change.sbw_info_id}`);
    await KodictNotifications.sendPushNotification(change.sbw_info_id, 'news', 'Beschikbaarheid', `Al jouw beschikbaarheid wijzigingen zijn verwijderd`);
    
    await this.getAvailabilityRequestsData();

    this.setState({loading: false});

  }

  getEmployeeSelects() {

    let toRender = [];

    for (const acc of this.state.accounts) {
      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;
      toRender.push(<option value={acc.info_id} selected={this.state.reqEmployee === acc.info_id}>{userFullName}</option>)
    }

    return toRender;

  }

  // HOLIDAYS
  changeReportEmployee(e) {

    this.setState({ reqEmployee: e.target.value });

  }

  changeReportAccepted(e) {

    this.setState({ reqAccepted: e.target.value });

  }

  changeReportSort(e) {

    this.setState({ reqSort: e.target.value });

  }

  async selectReportStartDate(date) {

    this.setState({ reqStart: date });

  }

  async selectReportEndDate(date) {

    this.setState({ reqEnd: date });

  }

  async changeDisplay() {

    this.setState({ loading: true });

    await this.getHolidayRequestsData();

    this.setState({ loading: false });

  }

  // TRADES

  changeReportEmployeeTrade(e) {

    this.setState({ reqTradeEmployee: e.target.value });

  }

  changeReportAcceptedTrade(e) {

    this.setState({ reqTradeAccepted: e.target.value });

  }

  changeReportSortTrade(e) {

    this.setState({ reqTradeSort: e.target.value });

  }

  async selectReportStartDateTrade(date) {

    this.setState({ reqTradeStart: date });

  }

  async selectReportEndDateTrade(date) {

    this.setState({ reqTradeEnd: date });

  }

  async changeDisplayTrade() {

    this.setState({ loading: true });

    await this.getTradeRequestsData();

    this.setState({ loading: false });

  }

  render() {

    let holidayRequestBars = {open: [], accepted: [], declined: []};
    let tradeRequestBars = {open: [], accepted: [], declined: []};
    let availableShiftBars = {users: [], planner: []};
    let availabilityBars = [];

    let workDayHours = 8;

    if (this.state.loading === false) {

      holidayRequestBars = this.getHolidayRequestBars();
      tradeRequestBars = this.getTradeRequestBars();
      availableShiftBars = this.getAvailableShiftBars();
      availabilityBars = this.getAvailabilityRequestBars();

      workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

    }

    return (
        <div className='requests'>

          {this.state.reloading === true ?       
            <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
          : null}

          <Navbar history={this.props.history} active={'requests'} fixed loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'requests'} parent={this} />

          {this.state.showAlert === true ?
                    
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null
                  
          }

          {this.state.showAddHolidayModal === true ?
                
            <AddHolidayModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _reloadScreen={this.reloadScreen.bind(this)}
            />

          :

            null
              
          }

          {/* <div className='planner_nav2_fixed fixedNav2'>

            <div className='planner_nav2_sub1' onClick={() => this.previousYear()}>
              <i className='fa fa-chevron-left'></i>
            </div> 
            <div className='planner_nav2_sub2'>
            
            </div> 
            <div className='planner_nav2_sub3' style={{flex: 2}}>
              Verlof
            </div> 
            <div className='schedule_nav2_sub4'>

            </div>
            <div className='planner_nav2_sub5' onClick={() => this.nextYear()} style={{cursor: this.canGoNext() === true ? 'pointer' : 'default'}}>
              {this.canGoNext() === true ?
              
                <i className='fa fa-chevron-right'></i>

              :

                null
            
              }
            </div> 

          </div> */}

          <div className='employees_main2'>
            {/* stickyTopMenuPanel */}
            <div className='employees_menu2'>

              <div className='employees_menuTitle'>
                <div>{__('misc_menu')}</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'trades' ? 'activeAv' : ''}`} style={{justifyContent: 'space-between'}} onClick={() => this.selectMenu('trades')}>
                <div>{__('misc_tradeRequests')}</div>
                {tradeRequestBars.open.length > 0 ?
                  <div className={'requests_notification'}>
                    {tradeRequestBars.open.length}
                  </div>
                : null}
              </div>

              {/* {Data.data.accountData.account_rights === '2' ? */}
                <div className={`employees_menuButton ${this.state.menu === 'absences' ? 'activeAv' : ''}`} style={{justifyContent: 'space-between'}} onClick={() => this.selectMenu('absences')}>
                  <div>{__('misc_leaveRequests')}</div>
                  {holidayRequestBars.open.length > 0 ?
                    <div className={'requests_notification'}>
                      {holidayRequestBars.open.length}
                    </div>
                  : null}
                </div>
              {/* : null} */}

              <div className={`employees_menuButton ${this.state.menu === 'shifts' ? 'activeAv' : ''}`} style={{justifyContent: 'space-between'}} onClick={() => this.selectMenu('shifts')}>
                <div>{__('misc_takeoverRequests')}</div>
                {Object.keys(this.state.availableShiftRequests).length > 0 ?
                  <div className={'requests_notification'}>
                    {Object.keys(this.state.availableShiftRequests).length}
                  </div>
                : null}
              </div>

              {Data.data.appOptions.availability === 'STANDARD' ?
                <div className={`employees_menuButton ${this.state.menu === 'availabilities' ? 'activeAv' : ''}`} style={{justifyContent: 'space-between'}} onClick={() => this.selectMenu('availabilities')}>
                  <div>{__('misc_availabilityRequests')}</div>
                  {Object.keys(this.state.availabilityRequests).length > 0 ?
                    <div className={'requests_notification'}>
                      {Object.keys(this.state.availabilityRequests).length}
                    </div>
                  : null}
                </div>
              : null}

            </div>

            {/* <div className='employees_content' style={this.state.selectedMonth === null ? {width: '85%'} : null}> */}
            <div className='employees_content2' style={{width: '87%'}}>

              {this.state.loading === true ?
              
                          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                <div className='employees_container'>

                  {this.state.menu === 'absences' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_leaveRequests')}</div>

                      <div className="employees_addButtons_container" style={{marginTop: '2vw'}}>

                        <div className="planner_new_addButton2" style={{marginLeft: 0}} onClick={() => this.setState({showAddHolidayModal: true})}>{__('button_add')}</div>

                      </div>

                      {/* {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?

                        <div>

                          <div className='planner_infoRow' style={{marginLeft: 0, marginTop: '2vw'}}>
                            <div className='planner_infoRow_col1' style={{width: '100%'}}>
                              <span>{__('requests_workingHours')}: <b>{workDayHours}</b></span>
                            </div>
                          </div>

                          <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.props.history.push({pathname: '/app_options', menu: 'general', subMenu: 'general'})}>
                            {__('requests_manage_workingDays')}
                          </div>

                        </div>

                      : null} */}

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>


                        <div style={{ width: '10vw' }}>
                          <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>{___.misc_employee}</div>
                          <select
                            name={__('select_employee')}
                            className={'plannerZ_input_select'}
                            onChange={(event) => this.changeReportEmployee(event)}
                          >
                            <option value={'null'} disabled>{__('misc_employee')}</option>
                            <option value={'all'} selected={this.state.reqEmployee === 'all'}>{__('select_employee_all')}</option>
                            <option value={'null'} disabled>&nbsp;</option>
                            {this.getEmployeeSelects()}
                          </select>
                        </div>

                        <div style={{ width: '10vw', marginLeft: '0.6vw' }}>
                          <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>{___.misc_status}</div>
                          <select
                            name={__('select_status')}
                            className={'plannerZ_input_select'}
                            onChange={(event) => this.changeReportAccepted(event)}
                          >
                            <option value={'null'} disabled>{__('misc_leaveRequests')}</option>
                            <option value={'all'} selected={this.state.reqAccepted === 'all'}>{__('misc_all')}</option>
                            <option value={'0'} selected={this.state.reqAccepted === '0'}>{__('select_open')}</option>
                            <option value={'1'} selected={this.state.reqAccepted === '1'}>{__('select_approved')}</option>
                            <option value={'2'} selected={this.state.reqAccepted === '2'}>{__('select_denied')}</option>
                            <option value={'3'} selected={this.state.reqAccepted === '3'}>{__('select_approvedDenied')}</option>
                          </select>
                        </div>

                        <div style={{ width: '10vw', marginLeft: '0.6vw' }}>
                          <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>{___.select_sort}</div>
                          <select
                            name={__('select_sort')}
                            className={'plannerZ_input_select'}
                            onChange={(event) => this.changeReportSort(event)}
                          >
                            <option value={'null'} disabled>{__('select_sort')}</option>
                            <option value={'ORDER BY vakantie_geaccepteerd, vakantie_begin'} selected={this.state.reqSort === 'ORDER BY vakantie_geaccepteerd, vakantie_begin'}>{__('select_sort_date_asc')}</option>
                            <option value={'ORDER BY vakantie_geaccepteerd, vakantie_begin DESC'} selected={this.state.reqSort === 'ORDER BY vakantie_geaccepteerd, vakantie_begin DESC'}>{__('select_sort_date_desc')}</option>
                            <option value={'ORDER BY vakantie_geaccepteerd, info_voornaam, info_achternaam, vakantie_begin'} selected={this.state.reqSort === 'ORDER BY vakantie_geaccepteerd, info_voornaam, info_achternaam, vakantie_begin'}>{__('select_sort_name_asc')}</option>
                            <option value={'ORDER BY vakantie_geaccepteerd, info_voornaam DESC, info_achternaam DESC, vakantie_begin'} selected={this.state.reqSort === 'ORDER BY vakantie_geaccepteerd, info_voornaam DESC, info_achternaam DESC, vakantie_begin'}>{__('select_sort_name_desc')}</option>
                          </select>
                        </div>

                        <div style={{ marginLeft: '0.6vw' }}>
                          <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>&nbsp;</div>
                          <div className='planner_new_availableButton2' onClick={() => this.changeDisplay()}>{__('button_show')}</div>
                        </div>

                      </div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                        <div style={{ width: '10vw' }}>
                          <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>{___.misc_date}</div>
                          <ReactDatePicker
                            selected={this.state.reqStart}
                            onChange={(date) => this.selectReportStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'plannerZ_input_date'}
                            placeholderText={__('misc_startDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          />
                        </div>

                        <div style={{ width: '10vw', marginLeft: '0.6vw' }}>
                          <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>&nbsp;</div>
                          <ReactDatePicker
                            selected={this.state.reqEnd}
                            onChange={(date) => this.selectReportEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'plannerZ_input_date'}
                            placeholderText={__('misc_endDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          />
                        </div>

                      </div>

                      <div className='hr_hoursContainer'>

                        <div className='hr_hoursBar_title'>

                          <div className='hr_hoursBar_sub1'>
                            {__('misc_name')}
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            {__('misc_period')}
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            {__('misc_comment')}
                          </div>

                          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                            <div className='hr_hoursBar_sub3'>
                              {__('misc_minContractHours')}
                            </div>
                          : null}

                          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                            <div className='hr_hoursBar_sub3'>
                              {__('misc_leaveHoursToTake')}
                            </div>
                          : null}

                          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                            <div className='hr_hoursBar_sub3'>
                              {__('misc_balance')}
                            </div>
                          : null}

                          <div className='hr_hoursBar_sub3'>
                            {__('misc_approve')}
                          </div>

                          <div className='hr_hoursBar_sub4'>
                            {__('misc_actions')}
                          </div>

                        </div>

                        {holidayRequestBars.open.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1vw', marginBottom: '0.5vw'}}>{__('misc_openRequests')}</div>

                            {holidayRequestBars.open}

                          </div>

                        : null}

                        {holidayRequestBars.accepted.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1vw', marginBottom: '0.5vw'}}>{__('misc_approvedRequests')}</div>

                            {holidayRequestBars.accepted}

                          </div>

                        : null}

                        {holidayRequestBars.declined.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1vw', marginBottom: '0.5vw'}}>{__('misc_deniedRequests')}</div>

                            {holidayRequestBars.declined}

                          </div>

                        : null}

                      </div>

                    </div>

                  :
                  this.state.menu === 'trades' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_tradeRequests')}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                        <div style={{width: '14%'}}>
                          <select
                            name={__('select_employee')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.changeReportEmployeeTrade(event)}
                          >
                            <option value={'null'} disabled>{__('misc_employee')}</option>
                            <option value={'all'} selected={this.state.reqTradeEmployee === 'all'}>{__('select_employee_all')}</option>
                            <option value={'null'} disabled>&nbsp;</option>
                            {this.getEmployeeSelects()}
                          </select>
                        </div>

                        <div style={{width: '12%', marginLeft: '0.6vw'}}>
                          <select
                            name={__('select_status')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.changeReportAcceptedTrade(event)}
                          >
                            <option value={'null'} disabled>{__('misc_tradeRequests')}</option>
                            <option value={'all'} selected={this.state.reqTradeAccepted === 'all'}>{__('misc_all')}</option>
                            <option value={'1'} selected={this.state.reqTradeAccepted === '1'}>{__('select_open')}</option>
                            <option value={'2'} selected={this.state.reqTradeAccepted === '2'}>{__('select_approved')}</option>
                            <option value={'4'} selected={this.state.reqTradeAccepted === '4'}>{__('select_denied')}</option>
                            <option value={'5'} selected={this.state.reqTradeAccepted === '5'}>{__('select_approvedDenied')}</option>
                          </select>
                        </div>

                        <div style={{width: '12%', marginLeft: '0.6vw'}}>
                          <select
                            name={__('select_sort')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.changeReportSortTrade(event)}
                          >
                            <option value={'null'} disabled>{__('select_sort')}</option>
                            <option value={'ORDER BY rs_geaccepteerd, s1.rooster_datum, s2.rooster_datum'} selected={this.state.reqTradeSort === 'ORDER BY rs_geaccepteerd, s1.rooster_datum, s2.rooster_datum'}>{__('select_sort_date_asc')}</option>
                            <option value={'ORDER BY rs_geaccepteerd, s1.rooster_datum DESC, s2.rooster_datum DESC'} selected={this.state.reqTradeSort === 'ORDER BY rs_geaccepteerd, s1.rooster_datum DESC, s2.rooster_datum DESC'}>{__('select_sort_date_desc')}</option>
                            <option value={'ORDER BY rs_geaccepteerd, i1.info_voornaam, i1.info_achternaam, s1.rooster_datum, s2.rooster_datum'} selected={this.state.reqTradeSort === 'ORDER BY rs_geaccepteerd, i1.info_voornaam, i1.info_achternaam, s1.rooster_datum, s2.rooster_datum'}>{__('select_sort_name_asc')}</option>
                            <option value={'ORDER BY rs_geaccepteerd, i1.info_voornaam DESC, i1.info_achternaam DESC, s1.rooster_datum, s2.rooster_datum'} selected={this.state.reqTradeSort === 'ORDER BY rs_geaccepteerd, i1.info_voornaam DESC, i1.info_achternaam DESC, s1.rooster_datum, s2.rooster_datum'}>{__('select_sort_name_desc')}</option>
                          </select>
                        </div>

                        <div className='planner_new_availableButton2' style={{marginLeft: '1vw'}} onClick={() => this.changeDisplayTrade()}>{__('button_show')}</div>

                      </div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                        <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                          <ReactDatePicker
                            selected={this.state.reqTradeStart}
                            onChange={(date) => this.selectReportStartDateTrade(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={__('misc_startDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          
                          />

                          {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                        </div>

                        <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>

                        <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                          <ReactDatePicker
                            selected={this.state.reqTradeEnd}
                            onChange={(date) => this.selectReportEndDateTrade(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={__('misc_endDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          />

                          {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                        </div>

                      </div>

                      <div className='hr_hoursContainer'>

                        <div className='hr_hoursBar_title'>

                          <div className='hr_hoursBar_sub2'>
                            {__('misc_name')}
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            {__('misc_shift')}
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            {__('misc_name')}
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            {__('misc_shift')}
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            {__('misc_approve')}
                          </div>

                          <div className='hr_hoursBar_sub4'>
                            {__('misc_actions')}
                          </div>

                        </div>

                        {tradeRequestBars.open.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1%'}}>{__('misc_openRequests')}</div>

                            {tradeRequestBars.open}

                          </div>

                        : null}

                        {tradeRequestBars.accepted.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1%'}}>{__('misc_approvedRequests')}</div>

                            {tradeRequestBars.accepted}

                          </div>

                        : null}

                        {tradeRequestBars.declined.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1%'}}>{__('misc_deniedRequests')}</div>

                            {tradeRequestBars.declined}

                          </div>

                        : null}

                      </div>

                    </div>

                  :
                  this.state.menu === 'shifts' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_takeoverRequests')}</div>

                      <div className='hr_hoursContainer' style={{marginTop: '2vw'}}>

                        <div className='hr_hoursBar_title'>

                          <div className='hr_hoursBar_sub1'>
                            {__('misc_shift')} 
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            {__('misc_skills')} 
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            {__('misc_by2')} 
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            {__('misc_responded')} 
                          </div>

                          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                            {__('misc_job')} 
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            {__('misc_skills')}  
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            {__('misc_approve')} 
                          </div>

                          <div className='hr_hoursBar_sub4'>
                            {__('misc_actions')} 
                          </div>

                        </div>

                        {availableShiftBars.users.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1%'}}>{__('requests_availableShifts_employees')}</div>

                            {availableShiftBars.users}

                          </div>

                        : null}

                        {availableShiftBars.planner.length > 0 ?

                          <div>

                            <div className='hr_title' style={{marginTop: '1%'}}>{__('requests_availableShifts_planner')}</div>

                            {availableShiftBars.planner}

                          </div>

                        : null}

                      </div>

                    </div>

                  :
                  this.state.menu === 'availabilities' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_availabilityRequests')}</div>

                      <div className='hr_hoursContainer' style={{marginTop: '2vw'}}>

                        <div className='hr_hoursBar_title'>

                          <div className='hr_hoursBar_sub1'>
                            {__('misc_name')} 
                          </div>

                          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                            {__('misc_day_c')} 
                          </div>

                          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                            {__('misc_from')} 
                          </div>

                          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                            {__('misc_to')} 
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            {__('misc_approve')} 
                          </div>

                          <div className='hr_hoursBar_sub4'>
                            {__('misc_actions')} 
                          </div>

                        </div>

                        {availabilityBars}

                      </div>

                    </div>

                  :

                    null

                  }
                  
                </div>
            
              }

            </div>

          </div>

        </div>
    );
  }
  
}

export default Requests;