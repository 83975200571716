import React from 'react';

import moment from 'moment-timezone';
import Constants from './Constants';
import APIAddLogger from '../classes/APIAddLogger';
import Colors from './Colors';
import APIADD from '../classes/global/APIADD';
import Data from './Data';

class Logger {

    /** PLANNER */

    async addPlannerDirectly(dataFrom, infoData, start, end) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = Constants.stringToDate(dataFrom.rooster_datum).toLocaleDateString();

        const userFullName1 = `${infoData.info_voornaam} ${(infoData.info_tussenvoegsel !== null && infoData.info_tussenvoegsel !== '') ? infoData.info_tussenvoegsel + " " + infoData.info_achternaam : infoData.info_achternaam}`;

        const s1 = `${plannerDate} ${start.substring(0, 5)} - ${end.substring(0, 5)}, ${userFullName1}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_ADD, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataFrom.rooster_datum} ${start.substring(0, 5)}`);
    }

    async editPlannerDirectly(dataFrom, start, end) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = Constants.stringToDate(dataFrom.rooster_datum).toLocaleDateString();

        const userFullName1 = `${dataFrom.info_voornaam} ${(dataFrom.info_tussenvoegsel !== null && dataFrom.info_tussenvoegsel !== '') ? dataFrom.info_tussenvoegsel + " " + dataFrom.info_achternaam : dataFrom.info_achternaam}`;

        const s1 = `${plannerDate} ${dataFrom.rooster_begin.substring(0, 5)} - ${dataFrom.rooster_eind.substring(0, 5)}, ${userFullName1}, ${dataFrom.functie_naam}`;
        const s2 = `${plannerDate} ${start.substring(0, 5)} - ${end.substring(0, 5)}, ${userFullName1}, ${dataFrom.functie_naam}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_EDIT, s1, s2, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataFrom.rooster_datum} ${dataFrom.rooster_begin}`);
    }

    async editPlannerClick(dataFrom, dataTo) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = Constants.stringToDate(dataFrom.rooster_datum).toLocaleDateString();

        const userFullName1 = `${dataFrom.info_voornaam} ${(dataFrom.info_tussenvoegsel !== null && dataFrom.info_tussenvoegsel !== '') ? dataFrom.info_tussenvoegsel + " " + dataFrom.info_achternaam : dataFrom.info_achternaam}`;
        const userFullName2 = `${dataTo.info_voornaam} ${(dataTo.info_tussenvoegsel !== null && dataTo.info_tussenvoegsel !== '') ? dataTo.info_tussenvoegsel + " " + dataTo.info_achternaam : dataTo.info_achternaam}`;

        const s1 = `${plannerDate} ${dataFrom.rooster_begin.substring(0, 5)} - ${dataFrom.rooster_eind.substring(0, 5)}, ${userFullName1}, ${dataFrom.functie_naam}`;
        const s2 = `${plannerDate} ${dataFrom.rooster_begin.substring(0, 5)} - ${dataFrom.rooster_eind.substring(0, 5)}, ${userFullName2}, ${dataFrom.functie_naam}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_EDIT, s1, s2, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataFrom.rooster_datum} ${dataFrom.rooster_begin}`);
    }

    async editPlannerModal(dataFrom, dataTo) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = Constants.stringToDate(dataFrom.rooster_datum).toLocaleDateString();

        const userFullName1 = `${dataFrom.info_voornaam} ${(dataFrom.info_tussenvoegsel !== null && dataFrom.info_tussenvoegsel !== '') ? dataFrom.info_tussenvoegsel + " " + dataFrom.info_achternaam : dataFrom.info_achternaam}`;
        const userFullName2 = dataTo.nameUser.replace('.', '');

        const oldFID = parseInt(dataFrom.functie_id);
        const newFID = parseInt(dataTo.functionID);

        const s1 = `${plannerDate} ${dataFrom.rooster_begin.substring(0, 5)} - ${dataFrom.rooster_eind.substring(0, 5)}, ${userFullName1}, ${dataFrom.functie_naam}`;
        const s2 = `${plannerDate} ${dataTo.startText} - ${dataTo.endText}, ${userFullName2}, ${oldFID !== newFID ? dataTo.functionName : dataFrom.functie_naam}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_EDIT, s1, s2, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataFrom.rooster_datum} ${dataFrom.rooster_begin}`);
    }

    async addPlannerClick(dataFrom, dataTo) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = Constants.stringToDate(dataTo.rooster_datum).toLocaleDateString();

        const userFullName1 = `${dataFrom.info_voornaam} ${(dataFrom.info_tussenvoegsel !== null && dataFrom.info_tussenvoegsel !== '') ? dataFrom.info_tussenvoegsel + " " + dataFrom.info_achternaam : dataFrom.info_achternaam}`;

        const s1 = `${plannerDate} ${dataTo.rooster_begin.substring(0, 5)} - ${dataTo.rooster_eind.substring(0, 5)}, ${userFullName1}, ${dataTo.functie_naam}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_ADD, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataTo.rooster_datum} ${dataTo.rooster_begin}`);
    }

    async addPlannerModal(dataFrom) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = dataFrom.dateText;

        const userFullName1 = dataFrom.nameUser.replace('.', '');

        const s1 = `${plannerDate} ${dataFrom.startText} - ${dataFrom.endText}, ${userFullName1}, ${dataFrom.functionName}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_ADD, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataFrom.dateText} ${dataFrom.startText}`);
    }

    async deletePlanner(dataFrom) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = Constants.stringToDate(dataFrom.rooster_datum).toLocaleDateString();

        const userFullName1 = `${dataFrom.info_voornaam} ${(dataFrom.info_tussenvoegsel !== null && dataFrom.info_tussenvoegsel !== '') ? dataFrom.info_tussenvoegsel + " " + dataFrom.info_achternaam : dataFrom.info_achternaam}`;

        const s1 = `${plannerDate} ${dataFrom.rooster_begin.substring(0, 5)} - ${dataFrom.rooster_eind.substring(0, 5)}, ${userFullName1}, ${dataFrom.functie_naam}`;

        APIAddLogger.Request(Constants.LOGGER_PLANNER, Constants.LOGGER_DEL, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '), `${dataFrom.rooster_datum} ${dataFrom.rooster_begin}`);
    }

    async deleteWholePlanner(info) {

        const dateNow = Constants.getDateNowStatic();

        const s1 = `${info}`;

        APIADD.Request(`INSERT INTO Logboek`, `VALUES (NULL, ${Data.data.accountData.info_id}, '${Constants.LOGGER_PLANNER}', '${Constants.LOGGER_DEL}', '${s1}', NULL, NULL, '${dateNow.toJSON().replace('T', ' ').replace('Z', ' ')}', NULL)`);

    }

    /** AVAILABILITY */

    async addAvailability(dataFrom, date) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = date.toLocaleDateString();

        const s1 = `${plannerDate} ${dataFrom.startText} - ${dataFrom.endText}`;

        APIAddLogger.Request(Constants.LOGGER_AVAILABILITY, Constants.LOGGER_ADD, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '));
    }

    async editAvailability(dataFrom, dataTo, date, unavail) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = date.toLocaleDateString();

        let s1 = `${plannerDate} ${dataFrom.start.substring(0,5)} - ${dataFrom.end.substring(0,5)}`;
        let s2 = `${plannerDate} ${dataTo.startText} - ${dataTo.endText}`;

        if (unavail === true) {
            s1 = `beschikbaar ${plannerDate} ${dataFrom.start.substring(0,5)} - ${dataFrom.end.substring(0,5)}`;
            s2 = `onbeschikbaar ${plannerDate} ${dataTo.startText} - ${dataTo.endText}`;
        }

        APIAddLogger.Request(Constants.LOGGER_AVAILABILITY, Constants.LOGGER_EDIT, s1, s2, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '));
    }

    async deleteAvailability(dataFrom, date) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = date.toLocaleDateString();

        const s1 = `${plannerDate} ${dataFrom.startText} - ${dataFrom.endText}`;

        APIAddLogger.Request(Constants.LOGGER_AVAILABILITY, Constants.LOGGER_DEL, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '));
    }

    /** UNAVAILABILITY */

    async addUnavailability(dataFrom, date) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = date.toLocaleDateString();

        const s1 = `${plannerDate} ${dataFrom.startText} - ${dataFrom.endText}`;

        APIAddLogger.Request(Constants.LOGGER_UNAVAILABILITY, Constants.LOGGER_ADD, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '));
    }

    async editUnavailability(dataFrom, dataTo, date, avail) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = date.toLocaleDateString();

        let s1 = `${plannerDate} ${dataFrom.start.substring(0,5)} - ${dataFrom.end.substring(0,5)}`;
        let s2 = `${plannerDate} ${dataTo.startText} - ${dataTo.endText}`;

        if (avail === true) {
            s1 = `onbeschikbaar ${plannerDate} ${dataFrom.start.substring(0,5)}-${dataFrom.end.substring(0,5)}`;
            s2 = `beschikbaar ${plannerDate} ${dataTo.startText}-${dataTo.endText}`;
        }

        APIAddLogger.Request(Constants.LOGGER_UNAVAILABILITY, Constants.LOGGER_EDIT, s1, s2, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '));
    }

    async deleteUnavailability(dataFrom, date) {

        const dateNow = Constants.getDateNowStatic();

        const plannerDate = date.toLocaleDateString();

        const s1 = `${plannerDate} ${dataFrom.startText} - ${dataFrom.endText}`;

        APIAddLogger.Request(Constants.LOGGER_UNAVAILABILITY, Constants.LOGGER_DEL, s1, null, null, dateNow.toJSON().replace('T', ' ').replace('Z', ' '));
    }

    /** PUBLISH */

    async published(info) {

        const dateNow = Constants.getDateNowStatic();

        const s1 = `${info}`;

        APIADD.Request(`INSERT INTO Logboek`, `VALUES (NULL, ${Data.data.accountData.info_id}, '${Constants.LOGGER_PLANNER}', '${Constants.LOGGER_PUBL}', '${s1}', NULL, NULL, '${dateNow.toJSON().replace('T', ' ').replace('Z', ' ')}', NULL)`);

    }

    async unpublished(info) {

        const dateNow = Constants.getDateNowStatic();

        const s1 = `${info}`;

        APIADD.Request(`INSERT INTO Logboek`, `VALUES (NULL, ${Data.data.accountData.info_id}, '${Constants.LOGGER_PLANNER}', '${Constants.LOGGER_UNPUBL}', '${s1}', NULL, NULL, '${dateNow.toJSON().replace('T', ' ').replace('Z', ' ')}', NULL)`);

    }

    getLogString(s1, s2, change) {

        if (change === Constants.LOGGER_ADD) {
            s1 = s1.replace('00:00 - 00:00', 'hele dag');
            return (<span><b style={{color: Colors.color.greenFilledIn}}>Toegevoegd:</b> {s1}</span>);
        }

        if (change === Constants.LOGGER_EDIT) {
            s1 = s1.replace('00:00 - 00:00', 'hele dag');
            s2 = s2.replace('00:00 - 00:00', 'hele dag');
            return (<div><b style={{color: Colors.color.orangeVacation}}>Gewijzigd:</b><br /><b>van</b> {s1}<br /><b>naar</b> {s2}</div>);
        }

        if (change === Constants.LOGGER_DEL) {
            s1 = s1.replace('00:00 - 00:00', 'hele dag');
            return (<span><b style={{color: Colors.color.redFilledIn}}>Verwijderd:</b> {s1}</span>);
        }

        if (change === Constants.LOGGER_PUBL) {
            return (<span><b style={{color: Colors.color.greenFilledIn}}>Gepubliceerd:</b> {s1}</span>);
        }

        if (change === Constants.LOGGER_UNPUBL) {
            return (<span><b style={{color: Colors.color.redFilledIn}}>Publiceren ongedaan gemaakt:</b> {s1}</span>);
        }

    }

    getLogType(type) {

        let info = '';

        if (type === Constants.LOGGER_PLANNER) {
            info = 'Planner';
        } else if (type === Constants.LOGGER_AVAILABILITY) {
            info = 'Beschikbaarheid';
        } else if (type === Constants.LOGGER_UNAVAILABILITY) {
            info = 'Onbeschikbaarheid';
        }

        return info;

    }

}

// singleton
export default (new Logger());