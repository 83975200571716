/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './signContractModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';
import '../../pages/options/reports/reports.scss';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import Colors from '../../constants/Colors';

import XLSX from 'xlsx';
import APIADD from '../../classes/global/APIADD';

import CryptoJS from 'crypto-js';
import APIGET from '../../classes/global/APIGET';
import APIDELETE from '../../classes/global/APIDELETE';
import APISendNewAccountMail from '../../classes/APISendNewAccountMail';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGetAllRegistrationCodes from '../../classes/APIGetAllRegistrationCodes';
import APIAddToTeam from '../../classes/APIAddToTeam';
import APICheckUsernameAvailibility from '../../classes/APICheckUsernameAvailibility';

import SignatureCanvas from 'react-signature-canvas'


class SignContractModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,
        reloading: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    this.setState({loading: false});

  }

  goBack() {

    this.props._closeModal();

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async signContract() {

    if (this.state.loading || this.state.reloading) {
      return;
    }

    if (this.sigCanvas.isEmpty()) {
      this.setState({ showError: true, errorText: 'Je hebt nog geen handtekening gegeven' });
      return;
    }

    const base64Sign = this.sigCanvas.toDataURL();

    this.reloadScreen(base64Sign); 
    this.closeModal();

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="signContractModal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.goBack() : null}>

        <div className="signContractModal-content" style={{minHeight: this.state.loading === true ? '15vw' : null}}>

          <div className="signContractModal-header">
            <p>Contract ondertekenen</p>
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="signContractModal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  history={this.props.history}
                />

              :

                null
          
              }

              <div className='signContractModal_content' style={{width: '100%'}}>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                  </div>

                :

                  <div className='signContractModal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='loader3'>
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                      </div>

                    :

                      null

                    }

                    <div style={{ display: 'flex', flexDirection: 'column', height: '20vw' }}>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Contract ondertekenen</div>

                      <SignatureCanvas 
                        ref={(ref) => { this.sigCanvas = ref }}
                        penColor='black'
                        throttle={16}
                        // minWidth={5}
                        // maxWidth={10}
                        // minDistance={20}
                        canvasProps={{ className: 'signContractModal_canvas' }}
                      />

                    </div>

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {this.state.loading === true || this.state.reloading === true ?

            <div className='signContractModal-bottom'>

              <div className='signContractModal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='signContractModal-bottom'>

              {this.showError()}

              <div className='modal-deleteButton' style={{marginRight: '1.2vw'}} onClick={() => this.sigCanvas.clear()}>
                <span>Uitwissen</span>
              </div>

              <div className='modal-normalButton' onClick={() => this.signContract()}>
                <span>Ondertekenen</span>
              </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default SignContractModal;