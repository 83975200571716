/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../pages/options/userHours/userHours.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/planner/planner.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/employees/employees.css';

import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import NulUren from './nuluren';
import Data from '../../constants/Data';

class ViewContract extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    if(this.props.location._userData === null || this.props.location._userData === undefined) {
      this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    if(this.props.location._userData === null || this.props.location._userData === undefined) {
        return;
    }

    this.setState({loading: false});

  }

  render() {

    let userFullName = '';
    const account = this.props.location._userData;

    if (typeof account !== 'undefined') {

        userFullName = `${account.info_voornaam} ${(account.info_tussenvoegsel !== null && account.info_tussenvoegsel !== '') ? account.info_tussenvoegsel + " " + account.info_achternaam : account.info_achternaam}`;

    }

    return (
        <div className='userHours'>

          <div className='planner_nav2'>

            <div 
              className='planner_nav2_sub1' 
              onClick={() => this.props.history.goBack()}
            >
              <i className='fa fa-chevron-left'></i>
            </div> 
            <div className='planner_nav2_sub2'>

            </div> 
            <div className='planner_nav2_sub3' style={{flex: 3}}>
              Contract genereren
            </div> 
            <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>
              
            </div> 
            <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
         
            </div> 

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='myHours_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

                <div className='employees_content_header_row' style={{marginBottom: '1vw'}}>

                    <div className='employees_content_header_1'>

                       {null}

                    </div>

                    <div className='employees_content_header_2'>

                        <PDFDownloadLink document={<NulUren _userData={this.props.location._userData} />} fileName={`Arbeidsovereenkomst ${userFullName}.pdf`} style={{textDecoration: 'none'}}>
                            {({ blob, url, loading, error }) => (loading ? <span className='planner_publishMenu_button' style={{textDecoration: 'none'}}>Moment geduld aub...</span> : <span className='planner_publishMenu_button' style={{textDecoration: 'none'}}>Contract downloaden</span>)}
                        </PDFDownloadLink>

                    </div>

                </div>

                <PDFViewer style={{height: '80vh'}}>
                    <NulUren _userData={this.props.location._userData} _functions={this.props.location._functions} />
                </PDFViewer>
              
            </div>
        
          }

        </div>
    );
  }
  
}

export default ViewContract;