import React from 'react';

//styles
import './employees.css'
import '../options/myHours/myHours.css';
import '../availability/availability.css';
import '../../components/smartPlanModal/smartPlanModal.css';
import '../planner/planner.scss';
import '../schedule/schedule.scss';
import '../../constants/DefaultStyle.css';
import '../options/accounts/accounts.css';
import '../options/hoursRegistration/hoursRegistration.css';
import '../options/appOptions/appOptions.css';

import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

class EmployeeDetails2 extends React.Component {

    constructor(props) {

        super(props);
    
        this.props = props;
    
        if(Data.data.loggedIn === false) {
          this.props.history.push("/");
        } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
          this.props.history.push("/");
        }
    
        const { employeeid } = this.props.match.params;
    
        // from params pathname
        this.paramSelected = this.props.location.selected !== undefined && this.props.location.selected !== null ? this.props.location.selected : Constants.isEmpty(employeeid) === false ? employeeid : null;
        this.paramSelectedName = this.props.location.selectedName !== undefined && this.props.location.selectedName !== null ? this.props.location.selectedName : null;
        this.paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : null;

        this.state = {

            loading: true,
            reloading: false,

        };

    }

    async componentDidMount() {

        if(Data.data.loggedIn === false) {
          return;
        } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
          return;
        }

    }

}

export default EmployeeDetails2;