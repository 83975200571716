/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './activeUsers.css';
import '../options/myHours/myHours.css'
import '../availability/availability.css';
import '../planner/planner.scss';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIGetAllActiveUsersClock from '../../classes/APIGetAllActiveUsersClock';
import APIGetUserTotalBreakClock from '../../classes/APIGetUserTotalBreakClock';
import APIGetAllUserTotalBreakClock from '../../classes/APIGetAllUserTotalBreakClock';
import APIGetSchedule from '../../classes/APIGetSchedule';
import APIGetAllUserActiveBreakClock from '../../classes/APIGetAllUserActiveBreakClock';
import APIGetScheduleClock from '../../classes/APIGetScheduleClock';
import APIGetAllUsersClockedToday from '../../classes/APIGetAllUsersClockedToday';
import APIAddToTotalBreakClock from '../../classes/APIAddToTotalBreakClock';
import APIAddToActiveBreakClock from '../../classes/APIAddToActiveBreakClock';
import APIAddActiveUserClock from '../../classes/APIAddActiveUserClock';
import APIAddUserToClockRegister from '../../classes/APIAddUserToClockRegister';
import Colors from '../../constants/Colors';
import APIGetActiveUserClock from '../../classes/APIGetActiveUserClock';
import APIGetUserActiveBreakClock from '../../classes/APIGetUserActiveBreakClock';
import APIGetOptions from '../../classes/APIGetOptions';
import ActiveUsersModal from './activeUsersModal';
import APIDeleteUserActiveClock from '../../classes/APIDeleteUserActiveClock';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import KodictNotifications from '../../constants/KodictNotifications';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';
import APIGET from '../../classes/global/APIGET';

class ActiveUsers extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.teamUsers = [];
    this.teamPlanner = [];

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      activeUsers: [],
      activeBreaks: [],
      allTotalBreaks: [], //if brk = clock
      schedule: [], //if brk = planner
      scheduleObj: {}, //if brk = planner
      scheduleClock: [],
      scheduleClockObj: {},
      usersClockedToday: [],

      showModal: false,
      modalData: [],

      showAlert: false,

      editMode: false,

      alertTitle: '',
      alertBody: ``,
      alertButtons: 1,
      alertButtonText: [],
      alertButtonAction: null,

      userTotalTime: [],
      userClockBreak: [],
      activeBreakTimes: [],

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    this.interval = setInterval(async () => {

      await this.refresh();

    }, 10000);

    this.setState({loading: true});

    // const options = await APIGetOptions.Request();
    // Data.data.appOptions = {
    //     id: parseInt(options[0].opties_id),
    //     clock: options[0].opties_inklok === '1' ? true : false,
    //     brk: options[0].opties_pauze === '0' ? 'PLANNER' : 'CLOCK',
    //     hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
    //     availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : 'NONE'
    // };

    if(Data.data.appOptions.clock === false) { //FALSE
      clearInterval(this.interval);
      this.props.history.push("/");
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
      this.teamPlanner = await APIGetTeamPlanner.Request(Constants.dateToString(new Date()));
    }

    await this.getActiveUsers();
    await this.getActiveBreaks();

    if(Data.data.appOptions.brk === 'CLOCK') { //CLOCK
      await this.getAllTotalBreaks();
    } else {
      await this.getSchedule();
    }

    await this.getScheduleClock();
    await this.getUsersClockedToday();

    await this.getActiveBreakTime();
    await this.getUserClockBreak();
    await this.getUserTotalTime();

    this.setState({loading: false});

  }

  componentWillUnmount() {
    clearInterval(this.interval);

  }

  async getActiveUsers() {
    
    let activeUsersData = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      activeUsersData = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, KlokActieveGebruiker.*, Locatie.*`, `FROM KlokActieveGebruiker`, `LEFT JOIN Info ON klokactievegebruiker_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id LEFT JOIN Locatie ON klokactievegebruiker_locatie_id = locatie_id RIGHT JOIN TeamKAG ON teamkag_klokactievegebruiker_id = klokactievegebruiker_id`, `WHERE teamkag_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY klokactievegebruiker_inklok_datum, klokactievegebruiker_inklok_tijd, info_voornaam, info_achternaam`);
    } else {
      activeUsersData = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, KlokActieveGebruiker.*, Locatie.*`, `FROM KlokActieveGebruiker`, `LEFT JOIN Info ON klokactievegebruiker_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id LEFT JOIN Locatie ON klokactievegebruiker_locatie_id = locatie_id`, null, null, `ORDER BY klokactievegebruiker_inklok_datum, klokactievegebruiker_inklok_tijd, info_voornaam, info_achternaam`);
    }

    if(activeUsersData === null) {
      this.setState({
        activeUsers: [],
        loading: false,
      });
      return;
    }

    if(activeUsersData === 'error') {
      this.setState({
        activeUsers: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({activeUsers: activeUsersData});


  }

  async getActiveBreaks() {

    let activeBreaks = await APIGetAllUserActiveBreakClock.Request();

    if(activeBreaks === null) {
      this.setState({
        activeBreaks: [],
        loading: false,
      });
      return;
    }

    if(activeBreaks === 'error') {
      this.setState({
          activeBreaks: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      activeBreaks = Constants.getTeamAccounts(this.teamUsers, activeBreaks);

    }

    this.setState({activeBreaks: activeBreaks});

  }

  async getAllTotalBreaks() {
    
    let totalBreaks = await APIGetAllUserTotalBreakClock.Request();

    if(totalBreaks === null) {
      this.setState({
        allTotalBreaks: [],
        loading: false,
      });
      return;
    }

    if(totalBreaks === 'error') {
      this.setState({
          allTotalBreaks: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      totalBreaks = Constants.getTeamAccounts(this.teamUsers, totalBreaks);

    }

    this.setState({allTotalBreaks: totalBreaks});

  }

  async getSchedule() {

    const today = await Constants.getDateNow(); //new Date();

    let scheduleObject = await APIGetSchedule.Request(`${today.getUTCFullYear()}-${today.getUTCMonth() + 1}-${today.getUTCDate()}`);

    if(scheduleObject === null) {
      this.setState({
        schedule: [],
        loading: false,
      });
      return;
    }

    if(scheduleObject === 'error') {
      this.setState({
          schedule: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      scheduleObject = Constants.getTeamPlanner(this.teamPlanner, scheduleObject);

    }

    this.setState({schedule: scheduleObject});

  }

  async getScheduleClock() {

    const today = await Constants.getDateNow(); //new Date();

    let scheduleObject = await APIGetScheduleClock.Request(`${today.getUTCFullYear()}-${today.getUTCMonth() + 1}-${today.getUTCDate()}`);
    
    if(scheduleObject === null) {
      this.setState({
        scheduleClock: [],
        loading: false,
      });
      return;
    }

    if(scheduleObject === 'error') {
      this.setState({
          scheduleClock: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      scheduleObject = Constants.getTeamPlanner(this.teamPlanner, scheduleObject);

    }

    this.setState({scheduleClock: scheduleObject});

  }

  async getUsersClockedToday() {

    const today = await Constants.getDateNow(); //new Date();
    
    let usersClockedToday = await APIGetAllUsersClockedToday.Request(`${today.getUTCFullYear()}-${today.getUTCMonth() + 1}-${today.getUTCDate()}`);

    if(usersClockedToday === null) {
      this.setState({
        usersClockedToday: [],
        loading: false,
      });
      return;
    }

    if(usersClockedToday === 'error') {
      this.setState({
          usersClockedToday: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      usersClockedToday = Constants.getTeamAccounts(this.teamUsers, usersClockedToday);

    }

    this.setState({usersClockedToday: usersClockedToday});

  }

  async refreshInterval(brk) {

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
      this.teamPlanner = await APIGetTeamPlanner.Request(Constants.dateToString(new Date()));
    }

    await this.getActiveUsers();
    await this.getActiveBreaks();

    if(brk === '1') { //CLOCK
      await this.getAllTotalBreaks();
    } else {
      await this.getSchedule();
    }

    await this.getScheduleClock();
    await this.getUsersClockedToday();

    await this.getActiveBreakTime();
    await this.getUserClockBreak();
    await this.getUserTotalTime();

  }

  async refresh() {

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
      this.teamPlanner = await APIGetTeamPlanner.Request(Constants.dateToString(new Date()));
    }

    await this.getActiveUsers();
    await this.getActiveBreaks();

    if(Data.data.appOptions.brk === 'CLOCK') {//change
      await this.getAllTotalBreaks();
    } else {
      await this.getSchedule();
    }

    await this.getScheduleClock();
    await this.getUsersClockedToday();

    await this.getActiveBreakTime();
    await this.getUserClockBreak();
    await this.getUserTotalTime();

  }

  async reloadData() {

    //await this.getPlannerData(month, year);

  }

  cancelClockInAlert(id, username) {

    this.setState({
        alertTitle: 'Annuleren',
        alertBody: `Wil je het inklokken van ${username} annuleren?`,
        alertButtons: 2,
        alertButtonText: ['Terug', 'Annuleren'],
        alertButtonAction: this.cancelClockIn.bind(this, id),
        showAlert: true,
    });

  }

  async cancelClockIn(id) {
    await APIDeleteUserActiveClock.Request(id);
    this.refresh();
  }

  clockInAlert(id, username) {

    this.setState({
        alertTitle: 'Inklokken',
        alertBody: `Wil je ${username} inklokken?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Inklokken'],
        alertButtonAction: this.clockInUser.bind(this, id),
        showAlert: true,
    });

  }

  clockOutAlert(id, username, startDate, startTime) {

    this.setState({
        alertTitle: 'Uitklokken',
        alertBody: `Wil je ${username} uitklokken?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Uitklokken'],
        alertButtonAction: this.clockOutUser.bind(this, id, startDate, startTime),
        showAlert: true,
    });

  }

  openActiveUsersModal(data) {

    this.setState({
      showModal: true, 
      modalData: data,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  getUserPlannerBreak(id) {

    let breakTime = '00:00';

    for(let key in this.state.schedule) {

      const schedule = this.state.schedule[key];

      if(schedule.info_id === id) {

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }

  getUserClockBreakFromState(id) {

    for(let key in this.state.userClockBreak) {

      if(this.state.userClockBreak[key].id === id) {
        return this.state.userClockBreak[key].time;
      }

    }

    return '00:00:00';

  }

  async getUserClockBreak() {

    let userClockBreak = [];

    for(let key2 in this.state.activeUsers) {

      let totalBreakTime = 0;

      for(let key in this.state.allTotalBreaks) {

        const userBreak = this.state.allTotalBreaks[key];

        if(userBreak.info_id === this.state.activeUsers[key2].info_id) {

          const breakHours = parseInt(userBreak.klokpauze_tijd.substring(0, 2));
          const breakMins = parseInt(userBreak.klokpauze_tijd.substring(3, 5)) / 60;
          const breakSecs = parseInt(userBreak.klokpauze_tijd.substring(6, 8)) / 60 / 60;

          totalBreakTime += ((breakHours + breakMins + breakSecs) * 60 * 60 * 1000);

        }

      }

      if(totalBreakTime !== 0) {
        userClockBreak.push({
          id: this.state.activeUsers[key2].info_id, 
          time: Constants.msToTime(totalBreakTime + await this.getActiveBreakTimeMS(this.state.activeUsers[key2].info_id)).substring(0, 8),
        });
      }

    }

    this.setState({userClockBreak: userClockBreak});

    //return Constants.msToTime(totalBreakTime + await this.getActiveBreakTimeMS(id)).substring(0, 5);

  }

  async getUserClockBreakMS(id) {

    let totalBreakTime = 0;

    for(let key in this.state.allTotalBreaks) {

      const userBreak = this.state.allTotalBreaks[key];

      if(userBreak.info_id === id) {

        const breakHours = parseInt(userBreak.klokpauze_tijd.substring(0, 2));
        const breakMins = parseInt(userBreak.klokpauze_tijd.substring(3, 5)) / 60;
        const breakSecs = parseInt(userBreak.klokpauze_tijd.substring(6, 8)) / 60 / 60;

        totalBreakTime += ((breakHours + breakMins + breakSecs) * 60 * 60 * 1000);

      }

    }

    return totalBreakTime + await this.getActiveBreakTimeMS(id);

  }

  getUserTotalBreaks(id) {

    let count = 0;

    for(let key in this.state.allTotalBreaks) {

      if(this.state.allTotalBreaks[key].info_id === id) {

        count++;

      }

    }

    for(let key in this.state.activeBreaks) {

      if(this.state.activeBreaks[key].info_id === id) {

        count++;

      }

    }

    return count;

  }

  async getUserTotalTime() {

    let totalTime = 0;

    let userTotalTime = [];

    for(let key in this.state.activeUsers) {

      const user = this.state.activeUsers[key];

      const startDate = Constants.getDateTZ(user.klokactievegebruiker_inklok_datum, user.klokactievegebruiker_inklok_tijd);
      const now = await Constants.getDateNow(); //new Date();

      totalTime = now - startDate < 0 ? 0 : now - startDate;

      userTotalTime.push({
        id: user.info_id, 
        ms: totalTime,
        time: Constants.msToTime(totalTime - await this.getUserClockBreakMS(user.info_id)).substring(0, 8),
      });

    }

    this.setState({userTotalTime: userTotalTime});

    //return Constants.msToTime(totalTime - this.getUserClockBreakMS(id)).substring(0, 5);

  }

  getUserTotalTimeFromState(id) {

    for(let key in this.state.userTotalTime) {

      if(this.state.userTotalTime[key].id === id) {
        return this.state.userTotalTime[key].time;
      }

    }

    return '00:00:00';

  }

  getUserTotalTimeMSFromState(id) {

    for(let key in this.state.userTotalTime) {

      if(this.state.userTotalTime[key].id === id) {
        return this.state.userTotalTime[key].ms;
      }

    }

    return '00:00:00';

  }

  userInActiveBreaks(id) {

    for(let key in this.state.activeBreaks) {
      if(this.state.activeBreaks[key].info_id === id) {
        return true;
      }
    }

    return false;
    
  }

  getActiveUserBars() {

    let bars = [];

    for(let key in this.state.activeUsers) {

      const user = this.state.activeUsers[key];
      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam.charAt(0) : user.info_achternaam.charAt(0)}.`;
      const date = Constants.stringToDate(user.klokactievegebruiker_inklok_datum);

      bars.push(

        <div key={key} className={'activeUsers_activeBar'}>

          {this.state.editMode === true ?
            <div 
              className={'activeUsers_activeBarSub'} 
              style={{justifyContent: 'center', alignItems: 'center', fontSize: '1vw', flex: 0.03, color: Colors.color.redFilledIn, cursor: 'pointer'}}
              onClick={() => this.cancelClockInAlert(user.info_id, userFullName)}
            >
              <i className={'fas fa-minus'} />
            </div>
          :
            null        
          }

          <div className={'activeUsers_activeBarSub'}>

            {userFullName}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 0.75}}>

            {date.getDate()} {Constants.getShortMonthName(date)} {user.klokactievegebruiker_inklok_tijd.substring(0, 8)}

          </div>

          <div className={'activeUsers_activeBarSub'}>

            {user.locatie_naam === null ? '(Via web)' : user.locatie_naam}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 0.5}}>

            {this.getUserTotalBreaks(user.info_id)}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 0.5}}>

            {Data.data.appOptions.brk === 'PLANNER' ? //change
            
              this.getUserPlannerBreak(user.info_id)

            :

              this.getUserClockBreakFromState(user.info_id)
          
            }

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 0.5}}>

            {this.getUserTotalTimeFromState(user.info_id)}

          </div>

          {Data.data.appOptions.brk === 'CLOCK' ?
          
            <div 
              className={'activeUsers_activeBarSub'} 
              style={{justifyContent: 'center', fontSize: '1.5vw', flex: 0.4, color: Colors.color.orangeVacation}}
            >

              {this.userInActiveBreaks(user.info_id) === true ?
                
                null
              
              :

                <i className={'far fa-pause-circle'} style={{cursor: 'pointer'}} onClick={() => this.clockInBreak(user.info_id)}/>
            
              }

            </div>

          :

            null
        
          }

          <div 
            className={'activeUsers_activeBarSub'} 
            style={{justifyContent: 'center', fontSize: '1.5vw', flex: 0.4, color: Colors.color.redFilledIn, borderRightWidth: 0}}
          >

            <i className={'far fa-stop-circle'} style={{cursor: 'pointer'}} onClick={() => this.clockOutAlert(user.info_id, userFullName, user.klokactievegebruiker_inklok_datum, user.klokactievegebruiker_inklok_tijd)}/>

          </div>

        </div>

      );

    }

    return bars;

  }

  async getActiveUserCheck(id) {

    const activeObject = await APIGetActiveUserClock.Request(parseInt(id));

    if(activeObject === 'error') {
        return false;
    }

    if(activeObject === null) {
        return false;
    }

    if(activeObject.length > 0) {
        return true;
    }

    return false;

  }

  async getActiveBreakCheck(id) {

    const activeObject = await APIGetUserActiveBreakClock.Request(parseInt(id));

    if(activeObject === 'error') {
        return false;
    }

    if(activeObject === null) {
        return false;
    }

    if(activeObject.length > 0) {
        return true;
    }

    return false;

  }

  getActiveBreakTimeFromState(id) {

    for(let key in this.state.activeBreakTimes) {

      if(this.state.activeBreakTimes[key].id === id) {
        return this.state.activeBreakTimes[key].time;
      }

    }

    return '00:00:00';

  }

  async getActiveBreakTime() {

    let activeBreakTimes = [];

    for(let key in this.state.activeBreaks) {

      const user = this.state.activeBreaks[key];

      const startBreak = Constants.getDateTZ(user.klokactievepauze_begin_datum, user.klokactievepauze_begin_tijd);
      const now = await Constants.getDateNow(); //new Date();

      activeBreakTimes.push({
        id: user.info_id,
        time: Constants.msToTime(now - startBreak).substring(0, 8),
      });

    }

    this.setState({activeBreakTimes: activeBreakTimes});

  }

  async getActiveBreakTimeMS(id) {

    for(let key in this.state.activeBreaks) {

      const user = this.state.activeBreaks[key];

      if(user.info_id === id) {

        const startBreak = Constants.getDateTZ(user.klokactievepauze_begin_datum, user.klokactievepauze_begin_tijd);
        const now = await Constants.getDateNow(); //new Date();

        return now - startBreak;

      }

    }

    return 0;

  }

  getScheduleBars() {

    let bars = [];

    for(let key in this.state.scheduleClock) {

      const user = this.state.scheduleClock[key];
      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam.charAt(0) : user.info_achternaam.charAt(0)}.`;
      //const date = new Date(user.rooster_datum);

      bars.push(

        <div key={key} className={'activeUsers_activeBar'}>

          <div className={'activeUsers_activeBarSub'} style={{fontSize: '0.8vw'}}>

            {userFullName}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 1.5, fontSize: '0.8vw'}}>

            {user.functie_naam}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{fontSize: '0.8vw'}}>

            {user.rooster_begin.substring(0, 5)} - {user.rooster_eind.substring(0, 5)}

          </div>

          <div 
            className={'activeUsers_activeBarSub'} 
            style={{justifyContent: 'center', fontSize: '1.2vw', flex: 0.5, color: Colors.color.greenFilledIn, borderRightWidth: 0}}
          >

            <i className={'far fa-play-circle'} style={{cursor: 'pointer'}} onClick={() => this.clockInAlert(user.info_id, userFullName)}/>

          </div>

        </div>

      );

    }

    return bars;

  }

  getActiveBreakBars() {

    let bars = [];

    for(let key in this.state.activeBreaks) {

      const user = this.state.activeBreaks[key];
      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam.charAt(0) : user.info_achternaam.charAt(0)}.`;
      const date = Constants.stringToDate(user.klokactievepauze_begin_datum);

      bars.push(

        <div key={key} className={'activeUsers_activeBar'}>

          <div className={'activeUsers_activeBarSub'} >

            {userFullName}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 2}}>

            {date.getDate()} {Constants.getShortMonthName(date)} {user.klokactievepauze_begin_tijd.substring(0, 8)}

          </div>

          <div className={'activeUsers_activeBarSub'}>

            {this.getActiveBreakTimeFromState(user.info_id)}

          </div>

          <div 
            className={'activeUsers_activeBarSub'} 
            style={{justifyContent: 'center', fontSize: '1.2vw', flex: 0.5, color: Colors.color.orangeVacation, borderRightWidth: 0}}
          >

            <i className={'far fa-play-circle'} style={{cursor: 'pointer'}} onClick={() => this.clockOutBreak(user.info_id, user.klokactievepauze_begin_datum, user.klokactievepauze_begin_tijd)}/>

          </div>

        </div>

      );

    }

    return bars;

  }

  getUsersClockedTodayBars() {

    let bars = [];

    for(let key in this.state.usersClockedToday) {

      const user = this.state.usersClockedToday[key];
      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam.charAt(0) : user.info_achternaam.charAt(0)}.`;
      const date = Constants.stringToDate(user.kloktijd_inklok_datum);
      const date2 = Constants.stringToDate(user.kloktijd_uitklok_datum);

      bars.push(

        <div key={key} className={'activeUsers_activeBar'}>

          <div className={'activeUsers_activeBarSub'} style={{flex: 1, fontSize: '0.8vw'}}>

            {userFullName}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 1, fontSize: '0.8vw'}}>

            {date.getDate()} {Constants.getShortMonthName(date)} {user.kloktijd_inklok_tijd.substring(0, 5)}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 1, fontSize: '0.8vw'}}>

            {date2.getDate()} {Constants.getShortMonthName(date2)} {user.kloktijd_uitklok_tijd.substring(0, 5)}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{flex: 1, fontSize: '0.8vw', overflow: 'hidden', whiteSpace: 'nowrap'}}>

            {user.locatie_naam === null ? '(Via web)' : user.locatie_naam}

          </div>

          <div className={'activeUsers_activeBarSub'} style={{justifyContent: 'center', fontSize: '0.8vw', borderRightWidth: 0, flex: 0.25}}>

            <i className={'fal fa-pen'} style={{cursor: 'pointer'}} onClick={() => this.openActiveUsersModal(user)}/>

          </div>

        </div>

      );

    }

    return bars;

  }

  async clockInUser(id) {

    if(await this.getActiveUserCheck(id) === true) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al ingeklokt');
      return;
    }

    let today = await Constants.getDateNow(); //new Date();

    if (parseInt(Data.data.appOptions.opties_klokAfronden) > 0) {
      today = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_klokAfronden), today);
    }

    const hours = today.getUTCHours();
    const minutes = today.getUTCMinutes();
    const seconds = today.getUTCSeconds();
    const dateString = `${today.getUTCFullYear()}-${today.getUTCMonth() + 1 < 10 ? '0' + (today.getUTCMonth() + 1) : (today.getUTCMonth() + 1)}-${today.getUTCDate()}`;

    const clockedIn = await APIAddActiveUserClock.Request(id, dateString, `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`, null);

    await KodictNotifications.sendPushNotification(id, 'news', 'Inkloksysteem', 'Je bent ingeklokt');

    if(clockedIn === true) {

      await this.refresh();

    }

  }

  async clockOutUser(id, startDate, startTime) {

    if(await this.getActiveUserCheck(id) === false) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al uitgeklokt');
      return;
    }

    let totalBreakTime = 0;
    let totalBreakTimeString = '';

    if(Data.data.appOptions.brk === 'CLOCK') {

        const breakObject = await APIGetUserTotalBreakClock.Request(id);

        for(let key in breakObject) {

            const breakHours = parseInt(breakObject[key].klokpauze_tijd.substring(0, 2));
            const breakMins = parseInt(breakObject[key].klokpauze_tijd.substring(3, 5)) / 60;
            const breakSecs = parseInt(breakObject[key].klokpauze_tijd.substring(6, 8)) / 60 / 60;

            totalBreakTime += ((breakHours + breakMins + breakSecs) * 60 * 60 * 1000);

        }

        totalBreakTimeString = Constants.msToTime(totalBreakTime).substring(0, 8);

    } else {

        totalBreakTimeString = this.getUserPlannerBreak(id);

    }

    let today = await Constants.getDateNow(); //new Date();

    if (parseInt(Data.data.appOptions.opties_klokAfronden) > 0) {
      today = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_klokAfronden), today);
    }

    const hours = today.getUTCHours();
    const minutes = today.getUTCMinutes();
    const seconds = today.getUTCSeconds();
    const dateString = `${today.getUTCFullYear()}-${today.getUTCMonth() + 1 < 10 ? '0' + (today.getUTCMonth() + 1) : (today.getUTCMonth() + 1)}-${today.getUTCDate()}`;

    const startDateObject = Constants.getDateTZ(startDate, startTime);//

    if(Data.data.appOptions.brk === 'CLOCK') {

      if(today - startDateObject - totalBreakTime < 60000) {
        await APIDeleteUserActiveClock.Request(id);
        this.refresh();
        return;
      }

    } else {

      const totalPlannerBreakObject = await Constants.getDateNow(); //new Date();
      totalPlannerBreakObject.setUTCHours(parseInt(totalBreakTimeString.substring(0,2)), parseInt(totalBreakTimeString.substring(3,5)), 0, 0);
      const plannerbreakHours = totalPlannerBreakObject.getUTCHours() * 60 * 60 * 1000;
      const plannerbreakMins = totalPlannerBreakObject.getUTCMinutes() * 60 * 1000;

      if(today - startDateObject - (plannerbreakHours + plannerbreakMins) < 60000) {
        await APIDeleteUserActiveClock.Request(id);
        this.refresh();
        return;
      }

    }

    const clockedOut = await APIAddUserToClockRegister.Request(id, startDate, startTime, dateString, `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`, totalBreakTimeString, null);

    await KodictNotifications.sendPushNotification(id, 'news', 'Inkloksysteem', 'Je bent uitgeklokt');

    if(clockedOut === true) {

      await this.refresh();

    }

  }

  async clockInBreak(id) {

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    if(options[0].opties_pauze === '0') {
      clearInterval(this.interval);
      this.props.history.push("/");
      return;
    }

    if(await this.getActiveBreakCheck(id) === true) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al met pauze gegaan');
      return;
    }

    if(this.getUserTotalTimeMSFromState(id) === 0 || this.getUserTotalTimeMSFromState(id) < 0) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker kan nog niet met pauze gaan');
      return;
    }

    let today = await Constants.getDateNow(); //new Date();

    if (parseInt(Data.data.appOptions.opties_pauzeAfronden) > 0) {
      today = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_pauzeAfronden), today);
    }

    const hours = today.getUTCHours();
    const minutes = today.getUTCMinutes();
    const seconds = today.getUTCSeconds();
    const dateString = `${today.getUTCFullYear()}-${today.getUTCMonth() + 1 < 10 ? '0' + (today.getUTCMonth() + 1) : (today.getUTCMonth() + 1)}-${today.getUTCDate()}`;

    const clockedIn = await APIAddToActiveBreakClock.Request(id, dateString, `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`);

    if(clockedIn === true) {

      await this.refresh();

    }

  }

  async clockOutBreak(id, startDate, startTime) {

    if(await this.getActiveBreakCheck(id) === false) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al terug van zijn/haar pauze');
      return;
    }

    const startBreak = Constants.getDateTZ(startDate, startTime);
    let endBreak = await Constants.getDateNow(); //new Date();

    if (parseInt(Data.data.appOptions.opties_pauzeAfronden) > 0) {
      endBreak = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_pauzeAfronden), endBreak);
    }

    const clockedOut = await APIAddToTotalBreakClock.Request(parseInt(id), Constants.msToTime(endBreak - startBreak).substring(0, 8));

    if(clockedOut === true) {

      await this.refresh();

    }

  }

  render() {

    const activeUserBars = this.getActiveUserBars();
    const activeBreaksBars = this.getActiveBreakBars();
    const scheduleBars = this.getScheduleBars();
    const usersClockedTodayBars = this.getUsersClockedTodayBars();

    return (
        <div className='activeUsers'>

          <Navbar history={this.props.history} active={'active_users'} menuActive={false} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'activeUsers'} parent={this} />

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='activeUsers_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <ActiveUsersModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _reloadScreen={this.refresh.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              <div className='activeUsers_statsContainer'>

                <div className='activeUsers_statContainer'>

                  <span style={{fontSize: '1.2vw', textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>Vandaag op de planning</span>

                  <div className='activeUsers_statHeader'>
                    <div className='activeUsers_statHeaderSub'>
                      Naam
                    </div>
                    <div className='activeUsers_statHeaderSub' style={{flex: 1.5}}>
                      Functie
                    </div>
                    <div className='activeUsers_statHeaderSub'>
                      Werktijd
                    </div>
                    <div className='activeUsers_statHeaderSub' style={{flex: 0.5, justifyContent: 'center'}}>
                      Start
                    </div>
                  </div>

                  {scheduleBars}

                </div>

                {Data.data.appOptions.brk === 'CLOCK' ?
                  <div className='activeUsers_statContainer' style={{marginLeft: '1%'}}>

                    <span style={{fontSize: '1.2vw', textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>Actieve pauzes</span>

                    <div className='activeUsers_statHeader'>
                      <div className='activeUsers_statHeaderSub'>
                        Naam
                      </div>
                      <div className='activeUsers_statHeaderSub' style={{flex: 2}}>
                        Begintijd pauze
                      </div>
                      <div className='activeUsers_statHeaderSub'>
                        Tijd
                      </div>
                      <div className='activeUsers_statHeaderSub' style={{flex: 0.5, justifyContent: 'center',}}>
                        Stop
                      </div>
                    </div>

                    {activeBreaksBars}

                  </div>
                :
                  null
                }

                <div className='activeUsers_statContainer' style={{marginLeft: '1%'}}>

                  <span style={{fontSize: '1.2vw', textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>Vandaag gewerkt</span>

                  <div className='activeUsers_statHeader'>
                    <div className='activeUsers_statHeaderSub' style={{flex: 1}}>
                      Naam
                    </div>
                    <div className='activeUsers_statHeaderSub' style={{flex: 1}}>
                      Ingeklokt
                    </div>
                    <div className='activeUsers_statHeaderSub' style={{flex: 1}}>
                      Uitgeklokt
                    </div>
                    <div className='activeUsers_statHeaderSub' style={{flex: 1}}>
                      Locatie
                    </div>
                    <div className='activeUsers_statHeaderSub' style={{flex: 0.25}}>
                      
                    </div>
                  </div>

                  {usersClockedTodayBars}

                </div>

              </div>

              {/* <div className='activeUsers_activeContainer' style={{position: 'relative', marginTop: 25, justifyContent: 'center', alignItems: 'center', borderWidth: 0, fontSize: '1.2vw', fontWeight: '500', backgroundColor: '#FBFCFD'}}>
                  
                  <div style={{position: 'absolute', left: 10, fontSize: '0.8vw'}}>
                    <i className={'fas fa-pen'} style={{cursor: 'pointer'}} onClick={() => this.setState({editMode: !this.state.editMode})} />
                  </div>
                  
                  Actieve medewerkers

              </div> */}

              <div className='activeUsers_activeContainer' style={{marginTop: 40}}>

              <span style={{fontSize: '1.2vw', textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>
                <div style={{position: 'absolute', left: 10, fontSize: '0.8vw'}}>
                  <i className={'fal fa-pen'} style={{cursor: 'pointer', color: this.state.editMode ? '#47B7EB' : '#282828'}} onClick={() => this.setState({editMode: !this.state.editMode})} />
                </div>

                Ingeklokte medewerkers
              </span>

                <div className='activeUsers_activeHeader'>
                  <div className='activeUsers_activeHeaderSub'>
                    Naam
                  </div>
                  <div className='activeUsers_activeHeaderSub' style={{flex: 0.75}}>
                    Ingeklokt
                  </div>
                  <div className='activeUsers_activeHeaderSub'>
                    Locatie
                  </div>
                  <div className='activeUsers_activeHeaderSub' style={{flex: 0.5}}>
                    Aantal pauzes
                  </div>
                  <div className='activeUsers_activeHeaderSub' style={{flex: 0.5}}>
                    Totale pauze
                  </div>
                  <div className='activeUsers_activeHeaderSub' style={{flex: 0.5}}>
                    Werktijd
                  </div>
                  {Data.data.appOptions.brk === 'CLOCK' ?
                    <div className='activeUsers_activeHeaderSub' style={{flex: 0.4, justifyContent: 'center'}}>
                      Pauzeren
                    </div>
                  :
                    null
                  }
                  <div className='activeUsers_activeHeaderSub' style={{borderRightWidth: 0, flex: 0.4, justifyContent: 'center'}}>
                    Uitklokken
                  </div>
                </div>

                {activeUserBars}

              </div>
              
            </div>
        
          }

        </div>
    );
  }
  
}

export default ActiveUsers;