import React from "react";
import ReactExport from "react-data-export";
import Data from "../../constants/Data";

import '../../pages/schedule/schedule.scss';
import '../../pages/planner/planner.scss';
import Constants from "../../constants/Constants";
import planClass from "../../pages/planner/plan.class";
import __ from "../../constants/__";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let users = [
    {
        columns: [
            { title: "Personeelsnummer", width: { wch: 20 }, style: { font: { bold: true } } },
            { title: "Naam", width: { wch: 50 }, style: { font: { bold: true } } },
            { title: 'Werkdagen', width: { wch: 10 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
            { title: 'Totaal bruto', width: { wch: 15 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
            { title: 'Totaal netto', width: { wch: 15 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
        ],
        data: [],
    },
];

let usersDetailed = [
    {
        columns: [
            { title: "Personeelsnummer", width: { wch: 20 }, style: { font: { bold: true } } },
            { title: "Naam", width: { wch: 50 }, style: { font: { bold: true } } },
            { title: "Functie", width: { wch: 50 }, style: { font: { bold: true } } },
            { title: 'Begin', width: { wch: 25 }, style: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { title: 'Eind', width: { wch: 25 }, style: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { title: 'Pauze', width: { wch: 10 }, style: { alignment: { horizontal: 'left', vertical: 'center' }, font: { bold: true } } },
            { title: 'Uren', width: { wch: 15 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
        ],
        data: [],
    },
];

export default class HoursDownload extends React.Component {

    size(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    // timeStringToFloat(time) {
    //     const hoursMinutes = time.split(/[.:]/);
    //     const hours = parseInt(hoursMinutes[0], 10);
    //     const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //     const hoursMins = hours + minutes / 60;
    //     return Math.round((hoursMins + Number.EPSILON) * 100) / 100;
    // }

    render() {

        users[0].data = [];
        usersDetailed[0].data = [];

        const usersData = this.props.dataSet;      
        const contracts = this.props.contracts;      

        let bonus = [];
        for (const b of this.props.bonus) {
            if (Constants.arrayContains(bonus, `${parseFloat(b.t_factor) * 100}`) === false) {
                bonus.push(`${parseFloat(b.t_factor) * 100}`);
            }
        }

        if (this.props.incBonus === true) {
            users[0].columns.push(
                { title: '', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                { title: '', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                // { title: 'Uren', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                // { title: '', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                { title: "Waarvan toeslaguren", width: { wch: 20 }, style: { font: { bold: true } } }
            );
            usersDetailed[0].columns.push(
                { title: '', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                { title: '', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                // { title: 'Uren', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                // { title: '', width: { wch: 11 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                { title: "Waarvan toeslaguren", width: { wch: 20 }, style: { font: { bold: true } } }
            );
        }

        for (const b of bonus) {
            users[0].columns.push({ title: `${b}%`, width: { wch: 10 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } });
            usersDetailed[0].columns.push({ title: `${b}%`, width: { wch: 10 }, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } });
        }

        let totalDays = 0;
        let totalHours = 0;

        for(const data of usersData) {

            const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

            if (this.props.detailed === true) {

                if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {

                    if (typeof data.uren_begin === 'undefined') {
                        continue;
                    }

                    let bonusObj = {};
                    for (const b of this.props.bonus) {
                        const key = `${parseFloat(b.t_factor) * 100}`;
                        if (typeof bonusObj[key] === 'undefined') {
                            bonusObj[key] = 0;
                        }
                    }

                    let bonusData = {
                        ...data,
                        uren_bonus: bonusObj,
                    };

                    const contract = Constants.getCurrentContract(contracts[data.info_id], Constants.stringToDate(data.uren_begindatum));

                    const startDate = Constants.stringToDate(data.uren_begindatum);
                    const endDate = Constants.stringToDate(data.uren_einddatum);

                    const start = Constants.stringToDateTime(data.uren_begindatum, data.uren_begin);
                    const end = Constants.stringToDateTime(data.uren_einddatum, data.uren_eind);
                    const _break = Constants.timeStringToMS(data.uren_pauze);
                    const hours = (((end - start) - _break) / 1000 / 60 / 60);

                    // const bonusHours = Constants.isEmpty(contract) === false && contract.contract_type === '2' ? 0 : planClass.calcBonusActual2(bonusData, hours * 60 * 60, bonusData.uren_bonus, this.props.bonus);

                    let bonusValues = [];
                    let bonusHours2 = 0;
                    if (this.props.incBonus === true) {
                        for (const key of bonus) {
                            for (const key2 in bonusData.uren_bonus) {
                                if (key === key2) {
                                    if (bonusData.uren_bonus[key2] === 0) {
                                        bonusValues.push({ value: '', style: { alignment: { horizontal: 'right', vertical: 'center' } } });
                                    } else {
                                        bonusHours2 += Constants.round(parseFloat(bonusData.uren_bonus[key2]) / 60 / 60, false, 2);
                                        bonusValues.push({ value: Constants.round(parseFloat(bonusData.uren_bonus[key2]) / 60 / 60, true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' } } });
                                    }
                                }
                            }
                        }
                    }

                    let incBonus = [];
                    if (this.props.incBonus === true) {
                        incBonus.push(    
                            { value: '' },
                            { value: '' },
                            // { value: Constants.round(normalHours, true), style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                            // { value: '' },
                            { value: '' }
                        );
                    }

                    usersDetailed[0].data.push([
                        { value: Constants.isEmpty(data.info2_personeelsnummer) ? '' : data.info2_personeelsnummer },
                        { value: userFullName },
                        { value: data.functie_naam },
                        { value: `${startDate.toLocaleDateString()} ${data.uren_begin.substr(0, 5)}`, style: { alignment: { horizontal: 'left', vertical: 'center' } } },
                        { value: `${endDate.toLocaleDateString()} ${data.uren_eind.substr(0, 5)}`, style: { alignment: { horizontal: 'left', vertical: 'center' } } },
                        { value: `${Constants.round(_break / 1000 / 60 / 60, true, 2)}`, style: { alignment: { horizontal: 'left', vertical: 'center' } } },
                        // { value: `${Constants.round(hours, true, 2)}`, style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                        { value: `${Constants.round(hours + bonusHours2, true, 2)}`, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                        //{ value: `${Constants.round(bonusHours, true, 2)}`, style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                        ...incBonus,
                        ...bonusValues,
                    ]);

                } else {

                    if (typeof data.rooster_begin === 'undefined') {
                        continue;
                    }

                    const planDate = Constants.stringToDate(data.rooster_datum);

                    const start = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
                    const end = Constants.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                    const _break = Constants.timeStringToMS(data.rooster_pauze);
                    const hours = (((end - start) - _break) / 1000 / 60 / 60);

                    usersDetailed[0].data.push([
                        { value: Constants.isEmpty(data.info2_personeelsnummer) ? '' : data.info2_personeelsnummer },
                        { value: userFullName },
                        { value: data.functie_naam },
                        { value: `${planDate.toLocaleDateString()} ${data.rooster_begin.substr(0, 5)}`, style: { alignment: { horizontal: 'left', vertical: 'center' } } },
                        { value: `${planDate.toLocaleDateString()} ${data.rooster_eind.substr(0, 5)}`, style: { alignment: { horizontal: 'left', vertical: 'center' } } },
                        // { value: `${Constants.round(_break / 1000 / 60 / 60, true, 2)}`, style: { alignment: { horizontal: 'left', vertical: 'center' } } },
                        { value: `${Constants.round(hours, true, 2)}`, style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                    ]);

                }

            } else {

                if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {

                    if (typeof data.uren_netto === 'undefined') {
                        continue;
                    }

                    let bonusValues = [];
                    let bonusHours = 0;
                    if (this.props.incBonus === true) {
                        for (const key of bonus) {
                            for (const key2 in data.uren_bonus) {
                                if (key === key2) {
                                    if (data.uren_bonus[key2] === 0) {
                                        bonusValues.push({ value: '', style: { alignment: { horizontal: 'right', vertical: 'center' } } });
                                    } else {
                                        bonusHours += Constants.round(parseFloat(data.uren_bonus[key2]) / 60 / 60, false, 2);
                                        bonusValues.push({ value: Constants.round(parseFloat(data.uren_bonus[key2]) / 60 / 60, true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' } } });
                                    }
                                }
                            }
                        }
                    }

                    const normalHours = Math.abs(Constants.round(parseFloat(data.uren_netto) / 60 / 60, false, 2) - bonusHours);

                    let incBonus = [];
                    if (this.props.incBonus === true) {
                        incBonus.push(    
                            { value: '' },
                            { value: '' },
                            // { value: Constants.round(normalHours, true), style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                            // { value: '' },
                            { value: '' }
                        );
                    }

                    users[0].data.push([
                        { value: Constants.isEmpty(data.info2_personeelsnummer) ? '' : data.info2_personeelsnummer },
                        { value: userFullName },
                        { value: data.uren_werkdagen, style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                        { value: Constants.round(parseFloat((data.uren_netto) / 60 / 60), true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                        { value: Constants.round(parseFloat((data.uren_netto) / 60 / 60) + bonusHours, true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                        ...incBonus,
                        ...bonusValues,
                    ]);

                    totalDays += parseInt(data.uren_werkdagen);
                    totalHours += Constants.round(parseFloat((data.uren_netto) / 60 / 60) + bonusHours, false, 2);

                } else {

                    if (typeof data.rooster_netto === 'undefined') {
                        continue;
                    }

                    totalDays += parseInt(data.rooster_werkdagen);
                    totalHours += Constants.round(parseFloat(data.rooster_netto) / 60 / 60, false, 2);

                    let bonusValues = [];
                    let bonusHours = 0;
                    if (this.props.incBonus === true) {
                        for (const key of bonus) {
                            for (const key2 in data.rooster_bonus) {
                                if (key === key2) {
                                    if (data.rooster_bonus[key2] === 0) {
                                        bonusValues.push({ value: '', style: { alignment: { horizontal: 'right', vertical: 'center' } } });
                                    } else {
                                        bonusHours += Constants.round(parseFloat(data.rooster_bonus[key2]) / 60 / 60, false, 2);
                                        bonusValues.push({ value: Constants.round(parseFloat(data.rooster_bonus[key2]) / 60 / 60, true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' } } });
                                    }
                                }
                            }
                        }
                    }

                    const normalHours = Math.abs(Constants.round(parseFloat(data.rooster_netto) / 60 / 60) - bonusHours, false, 2);

                    let incBonus = [];
                    if (this.props.incBonus === true) {
                        incBonus.push(    
                            { value: '' },
                            { value: '' },
                            // { value: Constants.round(normalHours, true), style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                            // { value: '' },
                            { value: '' }
                        );
                    }

                    users[0].data.push([
                        { value: Constants.isEmpty(data.info2_personeelsnummer) ? '' : data.info2_personeelsnummer },
                        { value: userFullName },
                        { value: data.rooster_werkdagen, style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                        { value: Constants.round(parseFloat((data.rooster_netto) / 60 / 60) + bonusHours, true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                        ...incBonus,
                        ...bonusValues,
                    ]);

                    totalDays += parseInt(data.rooster_werkdagen);
                    totalHours += Constants.round(parseFloat((data.rooster_netto) / 60 / 60) + bonusHours, false, 2);

                }

            }

        }

        if (this.props.detailed !== true) {

            users[0].data.push([
                { value: '' },
                { value: '', style: { alignment: { horizontal: 'right', vertical: 'center' } } },
                { value: '', style: { alignment: { horizontal: 'right', vertical: 'center' } } },
            ]);

            users[0].data.push([
                { value: 'Totaal:', style: { font: { bold: true } } },
                { value: '' },
                { value: totalDays, style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
                { value: Constants.round(totalHours, true, 2), style: { alignment: { horizontal: 'right', vertical: 'center' }, font: { bold: true } } },
            ]);

        }

        //month[0].data.sort((a, b) => (a[0].value > b[0].value || (a[0].value === b[0].value && a[2].value > b[2].value)) ? 1 : -1);

        return (
            <ExcelFile
                element={
                    <div className="planner_new_addButton2" style={{position: 'absolute', right: 0, top: 0}}>
                        {/* <i className={'fal fa-file-excel'} /> */}
                        {__('button_export')}
                    </div>
                }
                filename={`Urenrapportage ${this.props.end !== null ? `periode ${this.props.start.getDate() < 10 ? '0' + this.props.start.getDate() : this.props.start.getDate()}-${(this.props.start.getMonth() + 1) < 10 ? '0' + (this.props.start.getMonth() + 1) : (this.props.start.getMonth() + 1)}-${this.props.start.getFullYear()} - ${this.props.end.getDate() < 10 ? '0' + this.props.end.getDate() : this.props.end.getDate()}-${(this.props.end.getMonth() + 1) < 10 ? '0' + (this.props.end.getMonth() + 1) : (this.props.end.getMonth() + 1)}-${this.props.end.getFullYear()}` : `dag ${this.props.start.getDate() < 10 ? '0' + this.props.start.getDate() : this.props.start.getDate()}-${(this.props.start.getMonth() + 1) < 10 ? '0' + (this.props.start.getMonth() + 1) : (this.props.start.getMonth() + 1)}-${this.props.start.getFullYear()}`}`}
            >
                <ExcelSheet dataSet={this.props.detailed === true ? usersDetailed : users} name={`Urenrapportage ${this.props.end !== null ? `periode ${this.props.start.getDate() < 10 ? '0' + this.props.start.getDate() : this.props.start.getDate()}-${(this.props.start.getMonth() + 1) < 10 ? '0' + (this.props.start.getMonth() + 1) : (this.props.start.getMonth() + 1)}-${this.props.start.getFullYear()} - ${this.props.end.getDate() < 10 ? '0' + this.props.end.getDate() : this.props.end.getDate()}-${(this.props.end.getMonth() + 1) < 10 ? '0' + (this.props.end.getMonth() + 1) : (this.props.end.getMonth() + 1)}-${this.props.end.getFullYear()}` : `dag ${this.props.start.getDate() < 10 ? '0' + this.props.start.getDate() : this.props.start.getDate()}-${(this.props.start.getMonth() + 1) < 10 ? '0' + (this.props.start.getMonth() + 1) : (this.props.start.getMonth() + 1)}-${this.props.start.getFullYear()}`}`} />

            </ExcelFile>
        );
    }
}