/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './appOptions.css';
import '../../../constants/DefaultStyle.css';
import '../../../pages/options/hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../../constants/Data';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIADD from '../../../classes/global/APIADD';
import APIGET from '../../../classes/global/APIGET';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIEDIT from '../../../classes/global/APIEDIT';

class LeaveTypeModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,

        name: '',
        balances: [],

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    if (this.modalData !== null) {

        if (typeof this.props._leaveTypeBalances[this.modalData.vt_id] !== 'undefined') {
            this.setState({ balances: this.props._leaveTypeBalances[this.modalData.vt_id] });
        }

        this.setState({ name: this.modalData.vt_naam });

    }

    this.setState({ loading: false, });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je dit type wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteBonus.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkValidations() {

    if(Constants.isEmpty(this.state.name) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen naam ingevuld`,
        });
        return false;
    }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveHoliday() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    await APIADD.Request(`INSERT INTO VerlofType`, `VALUES (NULL, '${this.state.name}')`);
    const id = await APIGET.Request(`SELECT vt_id`, `FROM VerlofType`, null, null, null, `ORDER BY vt_id DESC LIMIT 1`);

    if (this.state.balances.length > 0) {
        let promises = [];
        for (const leave of this.state.balances) {
            promises.push(APIADD.Request(`INSERT INTO VerlofTypeBalans`, `VALUES (NULL, ${leave.vb_id}, ${id[0].vt_id})`));
        }
        await Promise.all(promises);
    }

    this.reloadScreen();
    this.closeModal();

  }

  async editBonus() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM VerlofType`, `WHERE vt_id = ${this.modalData.vt_id}`);

    await APIADD.Request(`INSERT INTO VerlofType`, `VALUES (NULL, '${this.state.name}')`);
    const id = await APIGET.Request(`SELECT vt_id`, `FROM VerlofType`, null, null, null, `ORDER BY vt_id DESC LIMIT 1`);

    if (this.state.balances.length > 0) {
        let promises = [];
        for (const leave of this.state.balances) {
            promises.push(APIADD.Request(`INSERT INTO VerlofTypeBalans`, `VALUES (NULL, ${leave.vb_id}, ${id[0].vt_id})`));
        }
        await Promise.all(promises);
    }

    this.reloadScreen();
    this.closeModal();

  }

  async deleteBonus() {

    if (this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM VerlofType`, `WHERE vt_id = ${this.modalData.vt_id}`);

    this.reloadScreen();
    this.closeModal();

  }

  changeLeaveBalance(leave) {

    let balances = this.state.balances;

    if (balances.length > 0) {

        const item = Constants.getArrayItem(balances, 'vb_id', leave.vb_id);

        if (item !== null) {
            Constants.removeFromArray2(balances, 'vb_id', item.vb_id);
        } else {
            balances.push(leave);
        }   

    } else {
        balances.push(leave);
    }

    this.setState({ balances: balances });

  }

  getLeaveBalances() {

    let toRender = [];
    let count = 0;

    for (const part of this.props._leaveBalances) {

      const checked = Constants.objectArrayContainsKey(this.state.balances, 'vb_id', part.vb_id);

      toRender.push(

        <div key={part.vb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '100%', cursor: 'pointer'}} onClick={() => this.changeLeaveBalance(part)}>

          <div className='hr_hoursBar_sub1'>

            {checked === true ?
              <i className='fas fa-check-square' style={{fontSize: '0.8vw', marginRight: '0.6vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
            :
              <i className='fal fa-square' style={{fontSize: '0.8vw', marginRight: '0.6vw'}} />
            }

            <span>{part.vb_naam}</span>

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  render() {

    return (
        <div id="leaveBalanceModal" className="modal" onClick={(e) => e.target === document.getElementById("zleaveBalanceModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.modalData !== null ?
              <p>Verloftype wijzigen</p>
            :
              <p>Verloftype toevoegen</p>
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Type verlof</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                            placeholder={'Bijv. "Vrij" / "Zwangerschapsverlof" / "Onbetaald verlof"'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                          <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Aftrekken van saldo's</span>
                        </div>

                        <div className='modal-rowSub2'>

                          {this.getLeaveBalances()}

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.modalData !== null ? 
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.modalData !== null ?      
                <div className='modal-submitButton' onClick={() => this.editBonus()}>
                  <span>Wijzigen</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveHoliday()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default LeaveTypeModal;