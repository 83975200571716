


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIGetOwnTrades {

    async Request() {

        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/getOwnTrades.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&id=${Data.data.accountData.account_id}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
            return null;
        }


        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return null;

        } else {

            return null; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIGetOwnTrades());