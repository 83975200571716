/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/functions/functions.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import { SketchPicker, ChromePicker } from 'react-color';
import BetterAlert from '../../components/alert/alert';
import APIADD from '../../classes/global/APIADD';
import APIEDIT from '../../classes/global/APIEDIT';
import APIDELETE from '../../classes/global/APIDELETE';

class DayNoteModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.date = this.props._modalDate;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        functionName: '',
        selectedColor: '#282828',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        selectedDays: [],

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING FUNCTION


        
    }

    if(this.mode === 1) { //EDITING FUNCTION

      this.setState({
        functionName: this.modalData.dn_notitie,
        selectedColor: '#' + this.modalData.dn_kleur,
      });

    }

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze notitie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteFunction.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    let added = false;

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      added = await APIADD.Request(`INSERT INTO DagNotitie`, `VALUES (NULL, '${this.date}', '${this.state.selectedColor.substr(1,7)}', '${this.state.functionName}', ${Data.data.chosenTeamObj.team_id})`);
    } else {
      added = await APIADD.Request(`INSERT INTO DagNotitie`, `VALUES (NULL, '${this.date}', '${this.state.selectedColor.substr(1,7)}', '${this.state.functionName}', NULL)`);
    }

    const dateObj = Constants.stringToDate(this.date);
    const week = Constants.getWeekNumber2(dateObj);
    const year = dateObj.getFullYear();

    for (const day of this.state.selectedDays) {
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIADD.Request(`INSERT INTO DagNotitie`, `VALUES (NULL, '${Constants.dateToString(Constants.getDateWeekday(day.id, week, year))}', '${this.state.selectedColor.substr(1,7)}', '${this.state.functionName}', ${Data.data.chosenTeamObj.team_id})`);
      } else {
        await APIADD.Request(`INSERT INTO DagNotitie`, `VALUES (NULL, '${Constants.dateToString(Constants.getDateWeekday(day.id, week, year))}', '${this.state.selectedColor.substr(1,7)}', '${this.state.functionName}', NULL)`);
      }
    }

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  async editFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEDIT.Request(`UPDATE DagNotitie`, `SET dn_kleur = '${this.state.selectedColor.substr(1,7)}', dn_notitie = '${this.state.functionName}'`, `WHERE dn_id = ${this.modalData.dn_id}`);

    const dateObj = Constants.stringToDate(this.modalData.dn_datum);
    const week = Constants.getWeekNumber2(dateObj);
    const year = dateObj.getFullYear();

    for (const day of this.state.selectedDays) {
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIEDIT.Request(`UPDATE DagNotitie`, `SET dn_kleur = '${this.state.selectedColor.substr(1,7)}', dn_notitie = '${this.state.functionName}'`, `WHERE dn_datum = '${Constants.dateToString(Constants.getDateWeekday(day.id, week, year))}' AND dn_team_id = ${Data.data.chosenTeamObj.team_id}`);
      } else {
        await APIEDIT.Request(`UPDATE DagNotitie`, `SET dn_kleur = '${this.state.selectedColor.substr(1,7)}', dn_notitie = '${this.state.functionName}'`, `WHERE dn_datum = '${Constants.dateToString(Constants.getDateWeekday(day.id, week, year))}'`);
      }
    }

    // const added = await APIEditFunction.Request(
    //   this.state.functionName, 
    //   this.state.selectedColor.substring(1, 7),
    //   parseInt(this.modalData.functie_id),
    // );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  async deleteFunction() {

    this.setState({loading: true});

    const added = await APIDELETE.Request(`DELETE FROM DagNotitie`, `WHERE dn_id = ${this.modalData.dn_id}`);

    const dateObj = Constants.stringToDate(this.modalData.dn_datum);
    const week = Constants.getWeekNumber2(dateObj);
    const year = dateObj.getFullYear();

    for (const day of this.state.selectedDays) {
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIDELETE.Request(`DELETE FROM DagNotitie`, `WHERE dn_datum = '${Constants.dateToString(Constants.getDateWeekday(day.id, week, year))}' AND dn_team_id = ${Data.data.chosenTeamObj.team_id}`);
      } else {
        await APIDELETE.Request(`DELETE FROM DagNotitie`, `WHERE dn_datum = '${Constants.dateToString(Constants.getDateWeekday(day.id, week, year))}'`);
      }
    }

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  checkValidations() {

    if(this.state.functionName === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen notitie ingevoerd',
      });
      return false;
    }

    return true;

  }

  goBack() {

    this.props._closeModal();

  }

  getDaysBars() {

    let people = [];

    const days = [
        { name: 'Maandag', id: 1 },
        { name: 'Dinsdag', id: 2 },
        { name: 'Woensdag', id: 3 },
        { name: 'Donderdag', id: 4 },
        { name: 'Vrijdag', id: 5 },
        { name: 'Zaterdag', id: 6 },
        { name: 'Zondag', id: 0 },
    ];
    
    for(const day of days) {

        if (Constants.stringToDate(this.date).getDay() === day.id) {

          people.push(
            <div className='reports_toggleButton_disabled' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}}>
              <i className='far fa-times-circle' />
              <span>{day.name}</span>
            </div>
          );

        } else {
        
          people.push(
    
            Constants.objectArrayContainsKey(this.state.selectedDays, 'id', day.id) === true ?
  
              <div className='reports_toggleButton_active' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}} onClick={() => this.selectingDay(day)}>
                <i className='fas fa-check-circle' />
                <span>{day.name}</span>
              </div>
  
            :
  
              <div className='reports_toggleButton' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}} onClick={() => this.selectingDay(day)}>
                <i className='fal fa-circle' />
                <span>{day.name}</span>
              </div>
  
        );

        }
    
    }
    
    return people;
    
  }

  selectingDay(day) {

    this.setState({showError: false});
    
    if(Constants.objectArrayContainsKey(this.state.selectedDays, 'id', day.id)) {
    
        this.deselectDay(day);
    
    } else {
    
        this.selectDay(day);
    
    }
    
  }

  selectDay(day) {

    let selectedFunctions = this.state.selectedDays;
    
    selectedFunctions.push(day); // be sure to clear this when adding availability
    
    this.setState({ selectedDays: selectedFunctions });
    
  }
    
  deselectDay(day) {
    
      let selectedFunctions = this.state.selectedDays;
    
      Constants.removeFromArray2(selectedFunctions, 'id', day.id);
    
      this.setState({ selectedDays: selectedFunctions });
    
  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="dayNoteModal" className="modal" onClick={(e) => e.target === document.getElementById("zdayNoteModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Notitie toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Notitie wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                      <div className='modal-rowSub1_padding'>
                        <span><b>Notitie</b></span>
                      </div>

                      <div className='modal-rowSub2'>

                        <textarea 
                          className={'inputFieldBig'}
                          maxLength={50}
                          value={this.state.functionName}
                          onChange={(event) => this.setState({ functionName: event.target.value })}
                          placeholder={'Notitie'}
                          style={{ minHeight: '3.2vw' }}
                        />

                      </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Tekstkleur</b></span>
                            <div style={{marginTop: 10, width: '4vw', height: '2vw', borderRadius: 5, backgroundColor: this.state.selectedColor}}></div>
                        </div>

                        <div className='modal-rowSub2'>

                          <ChromePicker 
                            //presetColors={[]}
                            width={'100%' - 32}
                            color={this.state.selectedColor}
                            onChange={(color) => this.setState({selectedColor: color.hex})}
                            disableAlpha={true}
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                          <span><b>Dagen</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <div style={{display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {this.getDaysBars()}
                          </div>

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editFunction()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveFunction()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default DayNoteModal;