import React from 'react';
import './home2.css';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';

import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import APIGetOwnSchedule from '../../classes/APIGetOwnSchedule';
import Colors from '../../constants/Colors';
import HomeModal from '../home/homeModal';
import Constants from '../../constants/Constants';
import BetterModal from '../../components/modal/modal';
import BetterAlert from '../../components/alert/alert';
import MissingModal from '../../components/missingModal/missingModal';
import APIGetTeamsPlannerOwn from '../../classes/APIGetTeamsPlannerOwn';
import APIGetAllNews from '../../classes/APIGetAllNews';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGetAllTrades from '../../classes/APIGetAllTrades';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';
import APIGetHolidayCount from '../../classes/APIGetHolidayCount';
import BetterBigModal from '../../components/defaultBigModal/defaultBigModal';
import Home from '../home/home';
import News from './News/news';
import AddNewsModal from './addNewsModal';
import APIGetAvailableShiftsAdmin from '../../classes/APIGetAvailableShiftsAdmin';
import APIGET from '../../classes/global/APIGET';
import APIDELETE from '../../classes/global/APIDELETE';
import APIGETLOGIN from '../../classes/login/APIGETLOGIN';
import APIADD from '../../classes/global/APIADD';
import APIGetAllContracts from '../../classes/APIGetAllContracts';
import APIEDIT from '../../classes/global/APIEDIT';
import StartupModal from '../../components/startupModal/startupModal';
import TutorialModal from '../../components/tutorialModal/tutorialModal';
import APIGetDeadlines from '../../classes/APIGetDeadlines';

import GridLayout from 'react-grid-layout';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

import { Prompt } from 'react-router';
import APIUploadFile from '../../classes/APIUploadFile';
import APIDelFile from '../../classes/APIDelFile';
import Localization from '../../constants/Localization';
import __ from '../../constants/__';
import APIUpdateQuantity from '../../classes/payment/APIUpdateQuantity';

import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import ___ from '../../constants/___';

class Home2 extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    Data.data.canUseNav = false;
    
    this.ownScheduleObject = [];

    this.hours = [];

    let layout = [
      {i: 'a', x: 2, y: 1, w: 1, h: 1}, // news
      {i: 'b', x: 0, y: 1, w: 1, h: 1}, // actions
      {i: 'c', x: 0, y: 0, w: 1, h: 1}, // Tiemdo news

      {i: 'd', x: 1, y: 1, w: 1, h: 1}, // events
      {i: 'e', x: 1, y: 0, w: 2, h: 1}, // requests

      //{i: 'f', x: 0, y: 2, w: 2, h: 1},
    ];

    if (localStorage.getItem('@homeLayout3') !== null) {
      layout = JSON.parse(localStorage.getItem('@homeLayout3'));
    }

    this.timeNow = new Date();
    
    this.state = {
        loading: true,
        reloading: false,
        ownScheduleState: [],
        errorScreen: false,
        noShifts: false,

        hours: [],

        showModal: false,
        modalMode: 0,
        modalData: [],

        showQuestionModal: false,
        tutorialModal: false,
        missingModalVisible: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,
        alertButtonAction2: null,

        timeNow: new Date(),

        news: [],

        allUsers: [],

        holidaysCount: 0,
        tradeRequestsCount: 0,
        otherTradesCount: 0,
        replaceCount: 0,
        availabilityRequestsCount: 0,
        totalCount: 0,

        showBigModal: false,
        content: null,
        header: '',

        showAddNewsModal: false,

        file: null,

        showStartupModal: false,
        showStartupAlert: false,

        showTutorialModal: false,
        showTutorialAlert: false,

        width: Data.data.appOptions.opties_versie !== '0' ? ((window.innerWidth / 100) * 87) - 1 : window.innerWidth,
        isDragging: false,
        layout: layout,

        upcoming_events: [],
        all_requests: [],

        menu: 'home',

        editorValue: {},
        changesEditor: false,
        editorLength: 0,
        editorOn: false,

        documents: [],
        file: null,
        uploadError: '',

        tiemdoNews: [],
        updatesActive: false,
        updatesMenu: null,
        newsArticle: '',
        newsObject: null,
        searchText_updates: '',

    };

    //componentWillMount here

    this.compWillMount();

    this.handleChangeEditor = this.handleChangeEditor.bind(this);

  }

  //remove listener on page exit
  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions.bind(this)); 
  }

  //actually set the state to the window dimensions
  getDimensions() {
    this.setState({ width: ((window.innerWidth / 100) * 87) - 1 });
  }

  compWillMount() {

  }

  async componentDidMount() {

    //add dimensions listener for window resizing
    window.addEventListener('resize', this.getDimensions.bind(this)); 

    // ***************** ADD TEAMS **********************
    // // which db
    // const dbTeams = `666`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // console.log(teams);

    // // set team
    // const _dbTeam = 3;

    // // then uncomment this
    // const rooster = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, dbTeams);
    // for (const r of rooster) {
    //   await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${_dbTeam}, ${r.rooster_id})`, dbTeams);
    // }
    // const employees = await APIGET.Request(`SELECT *`, `FROM Info`, null, null, null, null, dbTeams);
    // for (const r of employees) {
    //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${_dbTeam}, ${r.info_id})`, dbTeams);
    // }
    // const omzet = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, null, null, null, dbTeams);
    // for (const o of omzet) {
    //   await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${o.omzetd_id}, ${_dbTeam})`, dbTeams);
    // }
    // const omzetV = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, null, null, null, dbTeams);
    // for (const o of omzetV) {
    //   await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${o.omzetv_id}, ${_dbTeam})`, dbTeams);
    // }
    // const shiftTemplates = await APIGET.Request(`SELECT *`, `FROM DagDienstSjabloon`, null, null, null, null, dbTeams);
    // for (const s of shiftTemplates) {
    //   await APIEDIT.Request(`UPDATE DagDienstSjabloon`, `SET dagdienstsjabloon_team_id = ${_dbTeam}`, `WHERE dagdienstsjabloon_id >= 0`, dbTeams);
    // }
    // console.log('done');
    // ***************** ADD TEAMS **********************

    // ***************** COMBINING COMPANY INTO TEAMS **********************
    // //which db
    // // TO -----
    // const dbTeams = `666`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // const users = await APIGET.Request(`SELECT *`, `FROM Info`, null, null, null, null, dbTeams);
    // const users_obj = Constants.convertToObject(users, 'info_id');
    // console.log(teams);
    // console.log(users_obj);

    // // then uncomment this
    // // which db to combine from
    // // FROM ------
    // const dbTeams2 = `bambino`;
    // const addFrom = 1000000;
    // const whichTeam = 4;
    // const prefix = `_bak`;

    // const accountsC = await APIGET.Request(`SELECT *`, `FROM Account`, null, null, null, null, dbTeams2);
    // const infoC = await APIGET.Request(`SELECT *`, `FROM Info`, null, null, null, null, dbTeams2);
    // const infoExtraC = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null, dbTeams2);
    // const contractsC = await APIGET.Request(`SELECT *`, `FROM ContractN`, null, null, null, null, dbTeams2);
    // const roosterC = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, dbTeams2);
    // const beschikbaarC = await APIGET.Request(`SELECT *`, `FROM Beschikbaar`, null, null, null, null, dbTeams2);
    // const onbeschikbaarC = await APIGET.Request(`SELECT *`, `FROM Onbeschikbaar`, null, null, null, null, dbTeams2);
    // const standaardBeschikbaarheidC = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, null, null, null, dbTeams2);
    // const schoolroosterC = await APIGET.Request(`SELECT *`, `FROM Schoolrooster`, null, null, null, null, dbTeams2);
    // const schoolvakantieC = await APIGET.Request(`SELECT *`, `FROM Schoolvakantie`, null, null, null, null, dbTeams2);
    // const vakantieC = await APIGET.Request(`SELECT *`, `FROM Vakantie`, null, null, null, null, dbTeams2);

    // for (const a of accountsC) {

    //   if (Constants.isEmpty(users_obj[(parseInt(a.account_id) + addFrom)]) === false) {
    //     continue;
    //   }

    //   const userID = parseInt(a.account_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO Account`, `VALUES (${userID}, '${a.account_username}${prefix}', '${a.account_password}', ${a.account_rights})`, dbTeams);
    // }
    // for (const a of infoC) {
    //   const userID = parseInt(a.info_id) + addFrom;

    //   if (Constants.isEmpty(users_obj[userID]) === false) {
    //     continue;
    //   }

    //   const firstName = Constants.isEmpty(a.info_voornaam) ? `''` : `'${a.info_voornaam}'`;
    //   const insertion = Constants.isEmpty(a.info_tussenvoegsel) ? 'NULL' : `'${a.info_tussenvoegsel}'`;
    //   const lastName = Constants.isEmpty(a.info_achternaam) ? `''` : `'${a.info_achternaam}'`;
    //   const phone = Constants.isEmpty(a.info_telefoon) ? `''` : `'${a.info_telefoon}'`;
    //   const email = Constants.isEmpty(a.info_email) ? `''` : `'${a.info_email}'`;
    //   const comment = Constants.isEmpty(a.info_extra) ? 'NULL' : `'${a.info_extra}'`;
    //   const address = Constants.isEmpty(a.info_adres) ? `''` : `'${a.info_adres}'`;
    //   const house = Constants.isEmpty(a.info_huisnummer) ? `''` : `'${a.info_huisnummer}'`;
    //   const zip = Constants.isEmpty(a.info_postcode) ? `''` : `'${a.info_postcode}'`;
    //   const birth = Constants.isEmpty(a.info_geboorte) ? '2000-01-01' : `'${a.info_geboorte}'`;
    //   const startdate = Constants.isEmpty(a.info_indienst) ? 'NULL' : `'${a.info_indienst}'`;
    //   const iban = Constants.isEmpty(a.info_ibnr) ? 'NULL' : `'${a.info_ibnr}'`;
    //   const idnr = Constants.isEmpty(a.info_idnr) ? 'NULL' : `'${a.info_idnr}'`;
    //   const city = Constants.isEmpty(a.info_plaats) ? 'NULL' : `'${a.info_plaats}'`;
    //   const phone2 = Constants.isEmpty(a.info_telefoon2) ? 'NULL' : `'${a.info_telefoon2}'`;
    //   const birthplace = Constants.isEmpty(a.info_geboorteplaats) ? 'NULL' : `'${a.info_geboorteplaats}'`;
    //   await APIADD.Request(`INSERT INTO Info`, `VALUES (${userID}, ${firstName}, ${insertion}, ${lastName}, ${phone}, ${email}, NULL, ${comment}, ${address}, ${house}, ${zip}, ${birth}, 1, ${startdate}, ${iban}, ${idnr}, ${city}, ${phone2}, ${birthplace})`, dbTeams);
    //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${whichTeam}, ${userID})`, dbTeams);
    // }
    // for (const a of infoExtraC) {
    //   const userID = parseInt(a.info2_info_id) + addFrom;

    //   if (Constants.isEmpty(users_obj[userID]) === false) {
    //     continue;
    //   }

    //   await APIADD.Request(`INSERT INTO InfoExtra`, `VALUES (NULL, ${userID}, ${a.info2_max_dagen}, '${a.info2_max_eind}', ${a.info2_opeenvolgend}, ${a.info2_registreren}, ${a.info2_werkdag_maandag}, ${a.info2_werkdag_dinsdag}, ${a.info2_werkdag_woensdag}, ${a.info2_werkdag_donderdag}, ${a.info2_werkdag_vrijdag}, ${a.info2_werkdag_zaterdag}, ${a.info2_werkdag_zondag}, NULL, ${a.info2_werkweek}, NULL, NULL, ${a.info2_personeelsnummer})`, dbTeams);
    // }
    // for (const a of contractsC) {
    //   const userID = parseInt(a.contract_info_id) + addFrom;

    //   if (Constants.isEmpty(users_obj[userID]) === false) {
    //     continue;
    //   }

    //   const contractStart = Constants.isEmpty(a.contract_begin) ? 'NULL' : `'${a.contract_begin}'`;
    //   const contractEnd = Constants.isEmpty(a.contract_eind) ? 'NULL' : `'${a.contract_eind}'`;
    //   await APIADD.Request(`INSERT INTO ContractN`, `VALUES (NULL, ${userID}, ${contractStart}, ${contractEnd}, ${a.contract_type}, ${a.contract_uren}, ${a.contract_max_uren}, ${a.contract_uurloon}, ${a.contract_maandloon}, ${a.contract_opbouw_verlof}, ${a.contract_werkdag_uren}, ${a.contract_vaste_dagen}, ${a.contract_maandag}, ${a.contract_dinsdag}, ${a.contract_woensdag}, ${a.contract_donderdag}, ${a.contract_vrijdag}, ${a.contract_zaterdag}, ${a.contract_zondag})`, dbTeams);
    // }
    // // for (const a of roosterC) {
    // //   const userID = parseInt(a.rooster_info_id) + addFrom;
    // //   await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${a.rooster_datum}', '${a.rooster_begin}', '${a.rooster_eind}', ${a.rooster_info_id}, '${a.info_email}', NULL, '${a.info_extra}', '${a.info_adres}', '${a.info_huisnummer}', '${a.info_postcode}', '${a.info_geboorte}', 1, '${a.info_indienst}', '${a.info_ibnr}', '${a.info_idnr}', '${a.info_plaats}', '${a.info_telefoon2}', '${a.info_geboorteplaats}')`, dbTeams);
    // //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${whichTeam}, ${userID})`);
    // // }
    // for (const a of beschikbaarC) {
    //   const userID = parseInt(a.beschikbaar_info_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO Beschikbaar`, `VALUES (NULL, '${a.beschikbaar_datum}', '${a.beschikbaar_begin}', '${a.beschikbaar_eind}', ${userID}, '${a.beschikbaar_opmerking}')`, dbTeams);
    // }
    // for (const a of onbeschikbaarC) {
    //   const userID = parseInt(a.onbeschikbaar_info_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO Onbeschikbaar`, `VALUES (NULL, '${a.onbeschikbaar_datum}', '${a.onbeschikbaar_begin}', '${a.onbeschikbaar_eind}', ${userID}, '${a.onbeschikbaar_opmerking}')`, dbTeams);
    // }

    // console.log('done');
    // ***************** COMBINING DB TEAMS **********************

    // ***************** FROM ONE TEAM TO ANOTHER (NEW) DB **********************
    // // // // // // // // const _delSchedule = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, 'nypsgravenzande');
    // // // // // // // // for (const del of _delSchedule) {
    // // // // // // // //   await APIDELETE.Request(`DELETE FROM Rooster`, `WHERE rooster_id = ${del.rooster_id}`, 'nypsgravenzande');
    // // // // // // // // }
    // // which db
    // const dbTeams = `nyphengelo`;
    // const toDB = `nyphengelooz`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // console.log(teams);

    // // then uncomment this all
    // // set teamID
    // const _dbTeamID = 2;

    // // schedule to transfer
    // let _newDBSchedule = [];

    // // specific acc ids to transfer
    // let _usersToCopy = ['122', '111', '175', '180'];

    // // get teamusers from team id
    // const _dbAccount = await APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id LEFT JOIN TeamUsers ON teamu_info_id = info_id`, `WHERE teamu_team_id = ${_dbTeamID}`, null, null, dbTeams);
    // const _dbInfo = await APIGET.Request(`SELECT *`, `FROM Info`, `LEFT JOIN TeamUsers ON teamu_info_id = info_id`, `WHERE teamu_team_id = ${_dbTeamID}`, null, null, dbTeams);
    // const _dbFunction = await APIGET.Request(`SELECT *`, `FROM Functie`, null, null, null, null, dbTeams);
    // const _dbContract = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN TeamUsers ON teamu_info_id = contract_info_id`, `WHERE teamu_team_id = ${_dbTeamID}`, null, null, dbTeams);
    // const _dbInfoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, `LEFT JOIN TeamUsers ON teamu_info_id = info2_info_id`, `WHERE teamu_team_id = ${_dbTeamID}`, null, null, dbTeams);
    // const _dbAvail = await APIGET.Request(`SELECT *`, `FROM Beschikbaar`, `LEFT JOIN TeamUsers ON teamu_info_id = beschikbaar_info_id`, `WHERE teamu_team_id = ${_dbTeamID}`, null, null, dbTeams);
    // const _dbUnavail = await APIGET.Request(`SELECT *`, `FROM Onbeschikbaar`, `LEFT JOIN TeamUsers ON teamu_info_id = onbeschikbaar_info_id`, `WHERE teamu_team_id = ${_dbTeamID}`, null, null, dbTeams);

    // for (const a of _dbFunction) {
    //   await APIADD.Request(`INSERT INTO Functie`, `VALUES (${a.functie_id}, '${a.functie_naam}', '${a.functie_kleur}', NULL)`, toDB);
    // }
    // for (const a of _dbAccount) {
    //   if (Constants.isEmpty(_usersToCopy) === false && Constants.arrayContains(_usersToCopy, a.account_id) === false) {
    //     continue;
    //   }
    //   const userID = parseInt(a.account_id);
    //   await APIADD.Request(`INSERT INTO Account`, `VALUES (${userID}, '${a.account_username}', '${a.account_password}', ${a.account_rights})`, toDB);
    // }
    // for (const a of _dbInfo) {
    //   if (Constants.isEmpty(_usersToCopy) === false && Constants.arrayContains(_usersToCopy, a.info_id) === false) {
    //     continue;
    //   }
    //   const userID = parseInt(a.info_id);
    //   const firstName = Constants.isEmpty(a.info_voornaam) ? 'NULL' : `'${a.info_voornaam}'`;
    //   const insertion = Constants.isEmpty(a.info_tussenvoegsel) ? 'NULL' : `'${a.info_tussenvoegsel}'`;
    //   const lastName = Constants.isEmpty(a.info_achternaam) ? 'NULL' : `'${a.info_achternaam}'`;
    //   const phone = Constants.isEmpty(a.info_telefoon) ? 'NULL' : `'${a.info_telefoon}'`;
    //   const email = Constants.isEmpty(a.info_email) ? 'NULL' : `'${a.info_email}'`;
    //   const comment = Constants.isEmpty(a.info_extra) ? 'NULL' : `'${a.info_extra}'`;
    //   const address = Constants.isEmpty(a.info_adres) ? 'NULL' : `'${a.info_adres}'`;
    //   const house = Constants.isEmpty(a.info_huisnummer) ? 'NULL' : `'${a.info_huisnummer}'`;
    //   const zip = Constants.isEmpty(a.info_postcode) ? 'NULL' : `'${a.info_postcode}'`;
    //   const birth = Constants.isEmpty(a.info_geboorte) ? 'NULL' : `'${a.info_geboorte}'`;
    //   const startdate = Constants.isEmpty(a.info_indienst) ? 'NULL' : `'${a.info_indienst}'`;
    //   const iban = Constants.isEmpty(a.info_ibnr) ? 'NULL' : `'${a.info_ibnr}'`;
    //   const idnr = Constants.isEmpty(a.info_idnr) ? 'NULL' : `'${a.info_idnr}'`;
    //   const city = Constants.isEmpty(a.info_plaats) ? 'NULL' : `'${a.info_plaats}'`;
    //   const phone2 = Constants.isEmpty(a.info_telefoon2) ? 'NULL' : `'${a.info_telefoon2}'`;
    //   const birthplace = Constants.isEmpty(a.info_geboorteplaats) ? 'NULL' : `'${a.info_geboorteplaats}'`;
    //   const fid = Constants.isEmpty(a.info_functie_id) ? 'NULL' : `${a.info_functie_id}`;
    //   const worked = await APIADD.Request(`INSERT INTO Info`, `VALUES (${userID}, ${firstName}, ${insertion}, ${lastName}, ${phone}, ${email}, ${fid}, ${comment}, ${address}, ${house}, ${zip}, ${birth}, 1, ${startdate}, ${iban}, ${idnr}, ${city}, ${phone2}, ${birthplace})`, toDB);
      
    //   console.log(`${firstName} ${lastName}: ` + worked);

    //   // get schedules
    //   const _userSchedules = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_info_id = ${userID} AND teamr_team_id = ${_dbTeamID}`, null, null, dbTeams);
    //   for (const sch of _userSchedules) {
    //     _newDBSchedule.push(sch);
    //   }
    // }
    // for (const a of _dbInfoExtra) {
    //   if (Constants.isEmpty(_usersToCopy) === false && Constants.arrayContains(_usersToCopy, a.info2_info_id) === false) {
    //     continue;
    //   }
    //   const userID = parseInt(a.info2_info_id);
    //   const employeeNumber = Constants.isEmpty(a.info2_personeelsnummer) ? 'NULL' : `'${a.info2_personeelsnummer}'`;
    //   const maxEnd = Constants.isEmpty(a.info2_max_eind) ? 'NULL' : `'${a.info2_max_eind}'`;
    //   await APIADD.Request(`INSERT INTO InfoExtra`, `VALUES (NULL, ${userID}, ${a.info2_max_dagen}, ${maxEnd}, ${a.info2_opeenvolgend}, ${a.info2_registreren}, ${a.info2_werkdag_maandag}, ${a.info2_werkdag_dinsdag}, ${a.info2_werkdag_woensdag}, ${a.info2_werkdag_donderdag}, ${a.info2_werkdag_vrijdag}, ${a.info2_werkdag_zaterdag}, ${a.info2_werkdag_zondag}, NULL, ${a.info2_werkweek}, NULL, NULL, ${employeeNumber}, NULL, NULL)`, toDB);
    // }
    // for (const a of _dbContract) {
    //   if (Constants.isEmpty(_usersToCopy) === false && Constants.arrayContains(_usersToCopy, a.contract_info_id) === false) {
    //     continue;
    //   }
    //   const userID = parseInt(a.contract_info_id);
    //   const contractStart = Constants.isEmpty(a.contract_begin) ? 'NULL' : `'${a.contract_begin}'`;
    //   const contractEnd = Constants.isEmpty(a.contract_eind) ? 'NULL' : `'${a.contract_eind}'`;
    //   await APIADD.Request(`INSERT INTO ContractN`, `VALUES (NULL, ${userID}, ${contractStart}, ${contractEnd}, ${a.contract_type}, ${a.contract_uren}, ${a.contract_max_uren}, ${a.contract_uurloon}, ${a.contract_maandloon}, ${a.contract_opbouw_verlof}, ${a.contract_werkdag_uren}, ${a.contract_vaste_dagen}, ${a.contract_maandag}, ${a.contract_dinsdag}, ${a.contract_woensdag}, ${a.contract_donderdag}, ${a.contract_vrijdag}, ${a.contract_zaterdag}, ${a.contract_zondag})`, toDB);
    // }
    // for (const a of _dbAvail) {
    //   if (Constants.isEmpty(_usersToCopy) === false && Constants.arrayContains(_usersToCopy, a.beschikbaar_info_id) === false) {
    //     continue;
    //   }
    //   const userID = parseInt(a.beschikbaar_info_id);
    //   await APIADD.Request(`INSERT INTO Beschikbaar`, `VALUES (${a.beschikbaar_id}, '${a.beschikbaar_datum}', '${a.beschikbaar_begin}', '${a.beschikbaar_eind}', ${userID}, '')`, toDB);
    // }
    // for (const a of _dbUnavail) {
    //   if (Constants.isEmpty(_usersToCopy) === false && Constants.arrayContains(_usersToCopy, a.onbeschikbaar_info_id) === false) {
    //     continue;
    //   }
    //   const userID = parseInt(a.onbeschikbaar_info_id);
    //   await APIADD.Request(`INSERT INTO Onbeschikbaar`, `VALUES (${a.onbeschikbaar_id}, '${a.onbeschikbaar_datum}', '${a.onbeschikbaar_begin}', '${a.onbeschikbaar_eind}', ${userID}, '')`, toDB);
    // }
    // for (const a of _newDBSchedule) {
    //   await APIADD.Request(`INSERT INTO Rooster`, `VALUES (${a.rooster_id}, '${a.rooster_datum}', '${a.rooster_begin}', '${a.rooster_eind}', ${a.rooster_info_id}, ${a.rooster_functie_id}, ${a.rooster_publiceren}, '${a.rooster_pauze}', ${a.rooster_tijd_incorrect})`, toDB);
    // }
    // console.log('done');
    // ***************** ADD TEAMS **********************

    if(Data.data.loggedIn === false) {
      return;
    }

    const [
      timeNow,
      timeNow2,
      replaceCount2,
      availabilityRequests,
      _teamPlanner,
      news,
      allUsers,
      //_ownSchedule,
      tutorialData2,
      contracts,

      notes,
      deadlines,
      publicHolidays,

      availableShifts,
      availableShiftsPlanner,
      holidayRequests2,

      companyInfo,

      docs,

      afwRooster,

      mainNews,

    ] = await Promise.all([
      Constants.getDateNow(),
      Constants.getDateNow2(),
      this.getReplaceCount(),
      (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) ? APIGET.Request(`SELECT sbw_id`, `FROM StandaardBeschikbaarheidWijziging`, `LEFT JOIN TeamUsers ON teamu_info_id = sbw_info_id`, `WHERE teamu_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT sbw_id`, `FROM StandaardBeschikbaarheidWijziging`, null, null, null, null),
      APIGetTeamsPlannerOwn.Request(Data.data.accountData.info_id),
      APIGetAllNews.Request(),
      APIGetAllAccounts.Request(),
      //this.getOwnSchedule(),
      APIGET.Request(`SELECT *`, `FROM Tutorial`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),

      APIGET.Request(`SELECT Notitie.*, i1.info_id AS id1, i1.info_voornaam AS v1, i1.info_tussenvoegsel AS tv1, i1.info_achternaam AS a1, i2.info_voornaam AS v2, i2.info_tussenvoegsel AS tv2, i2.info_achternaam AS a2`, `FROM Notitie`, `LEFT JOIN Info AS i1 ON n_info_id = i1.info_id LEFT JOIN Info AS i2 ON n_info_id_door = i2.info_id`, `WHERE n_datum >= (NOW() - INTERVAL 2 DAY) AND n_melding IS NOT NULL`, null, `ORDER BY n_datum`),
      APIGetDeadlines.Request(),
      APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE f_datum >= (NOW() - INTERVAL 2 DAY) AND f_id > 0`, null, `ORDER BY f_datum`),

      APIGetAvailableShiftsAdmin.Request(),
      APIGET.Request(`SELECT sbg_shb_id, shb_team_id`, `FROM SBGeaccepteerd`, `LEFT JOIN ShiftBeschikbaar ON shb_id = sbg_shb_id`, `WHERE shb_datum >= '${Constants.dateToString(new Date())}'`, null, null),
      (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) ? APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, `FROM Vakantie`, `LEFT JOIN Info ON vakantie_info_id = info_id LEFT JOIN TeamUsers ON teamu_info_id = vakantie_info_id`, `WHERE vakantie_begin >= (NOW() - INTERVAL 2 DAY) AND vakantie_geaccepteerd = 0 AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY vakantie_begin`) : APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, `FROM Vakantie`, `LEFT JOIN Info ON vakantie_info_id = info_id`, `WHERE vakantie_begin >= (NOW() - INTERVAL 2 DAY) AND vakantie_geaccepteerd = 0`, null, `ORDER BY vakantie_begin`),

      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM BedrijfInformatie`, null, `WHERE bi_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM BedrijfInformatie`, null, null, null, null),

      (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) ? APIGET.Request(`SELECT *`, `FROM BedrijfDocument`, null, `WHERE bd_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY bd_naam`) : APIGET.Request(`SELECT *`, `FROM BedrijfDocument`, null, null, null, `ORDER BY bd_naam`),

      APIGET.Request(`SELECT *`, `FROM AfwRooster`, `LEFT JOIN Rooster ON rooster_id = afwr_rooster_id`, null, null, null),

      APIGETLOGIN.Request(`SELECT *`, `FROM Nieuws`, null, null, null, `ORDER BY nieuws_pinned DESC, nieuws_id DESC`),

    ]);

    // CHECK AfwRooster OLD info and put into NEW info
    // let promisesAfw = [];
    // for (const _afw of afwRooster) {
    //   if (Constants.isEmpty(_afw.rooster_info_id) === false) {
    //     promisesAfw.push(APIADD.Request(`INSERT INTO AfwezigheidN`, `VALUES (NULL, '${_afw.rooster_datum}', ${_afw.rooster_info_id}, ${_afw.afwr_afw_id}, 0)`));
    //   }
    //   promisesAfw.push(APIDELETE.Request(`DELETE FROM AfwRooster`, `WHERE afwr_id = ${_afw.afwr_id}`));
    // }
    // await Promise.all(promisesAfw);
    

    this.timeNow = timeNow2;
    this.teamPlanner = _teamPlanner;

    await this.getOwnSchedule();

    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    const fromNow = new Date(timeNow2.getTime() + 7889400000);

    await this.checkEqualSalaries();
    await this.checkWorkDays();

    let tradeRequestsCount = 0;
    let holidaysCount = 0;
    let replaceCount = replaceCount2;
    const availabilityRequestsCount = availabilityRequests.length;

    let all_requests = [];

    if(Data.data.accountData.account_rights === '1' || Data.data.accountData.account_rights === '2') {

        let join = '';
        let where = '';
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          join += ' LEFT JOIN TeamRooster AS t1 ON t1.teamr_rooster_id = plan1.rooster_id LEFT JOIN TeamRooster AS t2 ON t2.teamr_rooster_id = plan2.rooster_id';
          where += ` AND (t1.teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR t2.teamr_team_id = ${Data.data.chosenTeamObj.team_id})`;
        }

        //let tradeRequests = await APIGetAllTrades.Request();
        let tradeRequests = await APIGET.Request(
          `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
          `FROM Ruilshift`,
          `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
          ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
          ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +
          ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
          ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
          ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id` + join,
          `WHERE (rs_geaccepteerd = 1 OR rs_geaccepteerd = 2 OR rs_geaccepteerd = 4) AND plan1.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)` + where,
          null,
          `ORDER BY rs_geaccepteerd, plan1.rooster_datum, plan1.rooster_begin, plan1.rooster_eind`
        );

        let indexTrades = 0;
        if(tradeRequests !== null && tradeRequests !== 'error') {

            if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

              this.teamPlanner2 = [];
  
              for (const trade of tradeRequests) {
  
                  const obj = await APIGetTeamPlanner.Request(trade.pd1);
  
                  for (const teampl of obj) {
                      this.teamPlanner2 = [...this.teamPlanner2, teampl];
                  }

                  if (trade.pd1 !== trade.pd2) {

                    const obj2 = await APIGetTeamPlanner.Request(trade.pd2);

                    for (const teampl of obj2) {
                        this.teamPlanner2 = [...this.teamPlanner2, teampl];
                    }

                  }
  
              }
  
              const oldTrades = tradeRequests;
              tradeRequests = [];
  
              for (const team of Data.data.ownTeams) {
  
                  const arr = Constants.getTeamPlannerT2(this.teamPlanner2, oldTrades, parseInt(team.team_id));
  
                  for (const teampl of arr) {
                    tradeRequests = [...tradeRequests, teampl];
                  }
  
              }
  
            }

            for (const trade of tradeRequests) {

                if(trade.rs_geaccepteerd === '1') {
                    indexTrades++;
                }

            }

            tradeRequestsCount = indexTrades;
            this.setState({tradeRequestsCount: indexTrades});

        }

        const tradeRequests2 = await APIGET.Request(
          `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
          `FROM Ruilshift`,
          `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
          ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
          ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +  
          ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
          ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
          ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id` + join,
          `WHERE (rs_geaccepteerd = 1) AND plan1.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)` + where,
          null,
          `ORDER BY rs_geaccepteerd, plan1.rooster_datum, plan1.rooster_begin, plan1.rooster_eind`
        );

      for (const tr of tradeRequests2) {

        const _date1 = Constants.stringToDate(tr.pd1);
        const _date2 = Constants.stringToDate(tr.pd2);

        if (tr.pd1 === Constants.dateToString(timeNow2) || (_date1 >= timeNow2 && _date1 <= fromNow)) {
          all_requests.push({
            ...tr,
            type: 'trade',
            icon: (<i className='far fa-handshake' />),
            title: (<span><b>{Localization.t.home_overview_tradeRequest}</b></span>),
            text: (<span><b>{tr.v1}</b> ({_date1.toLocaleDateString()} {tr.pb1.substr(0, 5)} - {tr.pe1.substr(0, 5)}, {tr.fn1}) <i className='far fa-exchange' style={{marginLeft: '1%', marginRight: '1%'}}/> <b>{tr.v2}</b> ({_date2.toLocaleDateString()} {tr.pb2.substr(0, 5)} - {tr.pe2.substr(0, 5)}, {tr.fn2})</span>),
            date: _date1,
          });
        }

      }

      for (const bd of availableShifts) {

        const date = Constants.stringToDate(bd.rooster_datum);
        let userFullName = Localization.t.home_overview_openShift;
        if (Constants.isEmpty(bd.info_id) === false) {
          userFullName = `${bd.info_voornaam} ${(bd.info_tussenvoegsel !== null && bd.info_tussenvoegsel !== '') ? bd.info_tussenvoegsel + " " + bd.info_achternaam : bd.info_achternaam}`; //.charAt(0)
        }

        if (bd.rooster_datum === Constants.dateToString(timeNow2) || (date >= timeNow2 && date <= fromNow)) {
          all_requests.push({
            ...bd,
            type: 'takeover',
            icon: (<i className='far fa-praying-hands' />),
            title: (<span><b>{Localization.t.home_overview_shiftTransfer_title}</b></span>),
            text: <span>{Localization.t.formatString(Localization.t.home_overview_shiftTransfer, <b>{userFullName}</b>, date.toLocaleDateString(), bd.rooster_begin.substr(0, 5), bd.rooster_eind.substr(0, 5), bd.functie_naam)}</span>,
            date: date,
          });
        }

      }

    }

    // const holidayRequests = await APIGetHolidayCount.Request();

    if(Constants.isEmpty(holidayRequests2) === false) {

        holidaysCount = holidayRequests2.length;
        this.setState({holidaysCount: holidaysCount});

    }

    for (const h of holidayRequests2) {

      const _date = Constants.stringToDate(h.vakantie_begin);
      const _date2 = Constants.stringToDate(h.vakantie_eind);
      const userFullName = `${h.info_voornaam} ${(h.info_tussenvoegsel !== null && h.info_tussenvoegsel !== '') ? h.info_tussenvoegsel + " " + h.info_achternaam : h.info_achternaam}`; //.charAt(0)

      if (h.vakantie_begin === Constants.dateToString(timeNow2) || (_date >= timeNow2 && _date <= fromNow)) {
        all_requests.push({
          ...h,
          type: 'holiday',
          icon: (<i className='far fa-island-tropical' />),
          title: (<span><b>{Localization.t.home_overview_leaveRequest_title}</b></span>),
          text: h.vakantie_begin === h.vakantie_eind ? (<span>{Localization.t.formatString(Localization.t.home_overview_leaveRequest_oneDay, <b>{userFullName}</b>, _date.toLocaleDateString())}</span>) : (<span>{Localization.t.formatString(Localization.t.home_overview_leaveRequest, <b>{userFullName}</b>, _date.toLocaleDateString(), _date2.toLocaleDateString())}</span>),
          date: _date,
        });
      }

    }

    if(Data.data.accountData.account_rights === '2') {

      for (const user of allUsers) {
        const contract = Constants.getCurrentContract(contractsObj[user.info_id], timeNow2);
        if (contract !== null && Constants.isEmpty(contract.contract_eind) === false) {

          let _contract_date_check = Constants.stringToDate(contract.contract_eind);
          _contract_date_check = _contract_date_check.setDate(_contract_date_check.getDate() + 7); // better check for contract higher than current end date
          const contractAfter = Constants.getCurrentContract(contractsObj[user.info_id], new Date(_contract_date_check));
          if (contractAfter !== null) {
            continue;
          }

          const contract_end = Constants.stringToDate(contract.contract_eind);
          const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`; //.charAt(0)
          if (contract.contract_eind === Constants.dateToString(timeNow2) || (contract_end >= timeNow2 && contract_end <= fromNow)) {
            let _date = new Date(contract_end.getFullYear(), contract_end.getMonth(), contract_end.getDate());
            _date = _date.setMonth(_date.getMonth() - 2);
            all_requests.push({
              ...contract,
              type: 'contract',
              icon: (<i className='far fa-file-exclamation' />),
              title: (<span><b>{Localization.t.home_overview_contract_title}</b></span>),
              text: (<span>{Localization.t.formatString(Localization.t.home_overview_contract, <b>{userFullName}</b>, contract_end.toLocaleDateString())}</span>),
              date: _date,
            });
          }

        }
      }

    }

    all_requests.sort((a, b) => (a.date > b.date) ? 1 : -1);

    const totalCount = tradeRequestsCount + holidaysCount + replaceCount + availabilityRequestsCount;

    this.getHours();

    await this.usersHaveAllColumnsDB();

    const tutorialData = tutorialData2[0];
    if (Constants.isEmpty(tutorialData) === false && tutorialData.tut_startup !== '1') {

      if (allUsers.length > 1) {
        this.startupAlert2();
      } else {
        this.startupAlert();
      }
      this.setState({ loading: false });
      return;

    } 
    // else if(tutorialData.tut_tutorial !== '1') {

    //   // if (allUsers.length > 1) {
    //   //   this.startupAlert2();
    //   // } else {
    //   //   this.startupAlert();
    //   // }
    //   this.tutorialAlert();
    //   this.setState({ loading: false });
    //   return;

    // }

    // UPCOMING EVENTS
    let upcoming_events = [];

    for (const user of allUsers) {
      if (Constants.isEmpty(user.info_geboorte) === false) {
        let _date = Constants.stringToDate(user.info_geboorte);
        const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`; //.charAt(0)
        if (Constants.daysUntill(_date, timeNow2) >= 0 && Constants.daysUntill(_date, timeNow2) <= 91) { // 3months
          upcoming_events.push({
            ...user,
            icon: (<i className='far fa-birthday-cake' style={{color: '#E362EE'}} />),
            title: (<span>{Localization.t.formatString(Localization.t.home_overview_birthday, <b>{Localization.t.home_overview_birthday_title}</b>, <span className='defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: user.info_id, selectedName: userFullName, menu: 'account'})}>{userFullName}</span>)}</span>),
            date: new Date(_date),
            dateString: user.info_geboorte,
            timeString: null,
          });
        }
      }
    }

    for (const note of notes) {
      const _date = Constants.stringToDateTime(note.n_datum, note.n_tijd);
      const userFullName = `${note.v1} ${(note.tv1 !== null && note.tv1 !== '') ? note.tv1 + " " + note.a1 : note.a1}`; //.charAt(0)
      const userFullName2 = `${note.v2} ${(note.tv2 !== null && note.tv2 !== '') ? note.tv2 + " " + note.a2 : note.a2}`; //.charAt(0)
      if (_date >= timeNow2 && _date <= fromNow) {
        upcoming_events.push({
          ...note,
          icon: (<i className='far fa-calendar-star' style={{color: Colors.color.orangeVacation}} />),
          title: (<span><b>{note.n_titel}</b> - <span className='defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: note.id1, selectedName: userFullName, menu: 'notes'})}>{userFullName}</span></span>),
          date: _date,
          dateString: note.n_datum,
          timeString: note.n_tijd.substr(0, 5),
          name2: userFullName2,
        });
      }
    }

    // console.log(Constants.dateToStringUTC(timeNow2))

    for (const ph of publicHolidays) {
      const _date = Constants.stringToDate(ph.f_datum);
      if (ph.f_datum === Constants.dateToString(timeNow2) || (_date >= timeNow2 && _date <= fromNow)) {
        upcoming_events.push({
          ...ph,
          icon: (<i className='far fa-turkey' style={{color: '#022F40'}} />),
          title: (<span><b>{ph.f_naam}</b></span>),
          date: _date,
          dateString: ph.f_datum,
          timeString: null,
        });
      }
    }

    if (Constants.isEmpty(deadlines) === false) {
      for (const dl of deadlines) {
        const _date = Constants.stringToDateTime(dl.deadline_datum, dl.deadline_tijd);
        const _periodStart = Constants.stringToDate(dl.deadline_begin);
        const _periodEnd = Constants.stringToDate(dl.deadline_eind);
        if (_date >= timeNow2 && _date <= fromNow) {
          upcoming_events.push({
            ...dl,
            icon: (<i className='far fa-stopwatch' style={{color: Colors.color.redFilledIn}} />),
            title: (<span>{Localization.t.formatString(Localization.t.home_overview_deadline, <b>{Localization.t.home_overview_deadline_title}</b>, _periodStart.toLocaleDateString(), _periodEnd.toLocaleDateString())}</span>),
            date: _date,
            dateString: dl.deadline_datum,
            timeString: dl.deadline_tijd.substr(0, 5),
          });
        }
      }
    }

    upcoming_events.sort((a, b) => (a.date > b.date) ? 1 : -1);

    Data.data.canUseNav = true;

    // EDITOR VALUE
    let _companyInfo = {};
    if (companyInfo.length > 0) {
      _companyInfo = JSON.parse(companyInfo[0].bi_tekst);
    }

    this.setState({ 
      timeNow: timeNow,
      totalCount: totalCount, 
      availabilityRequestsCount: availabilityRequestsCount, 
      news: news, 
      allUsers: allUsers, 
      upcoming_events: upcoming_events,
      all_requests: all_requests,
      editorValue: _companyInfo,
      documents: docs,
      tiemdoNews: mainNews,
      loading: false
    });

  }

  startupAlert() {

    // if(this.state.loading === true || this.state.reloading === true) {
    //   return;
    // }

    this.setState({
      alertTitle: Localization.t.alert_welcome_title,
      alertBody: (
        <div>

          <span>{Localization.t.alert_welcome} <b>Tiemdo</b>!</span><br /><br />
          <span>{Localization.t.alert_welcome_sub}</span>

        </div>
      ),
      alertButtons: 1,
      alertButtonText: [__('button_start')],
      showStartupAlert: true,
    });

  }

  startupAlert2() {

    // if(this.state.loading === true || this.state.reloading === true) {
    //   return;
    // }

    this.setState({
      alertTitle: Localization.t.alert_welcome_title,
      alertBody: (
        <div>

          <span>{Localization.t.alert_welcome} <b>Tiemdo</b>!</span><br /><br />
          <span>{Localization.t.alert_welcomeSettings}</span><br /><br />
          <span>{Localization.t.alert_welcomeSettings_sub}</span>

        </div>
      ),
      alertButtons: 2,
      alertButtonText: [Localization.t.button_cancel, Localization.t.button_setUp],
      alertButtonAction: this.startupConfig.bind(this),
      showStartupAlert: true,
    });

  }

  startupConfig() {

    this.setState({ showStartupModal: true });

  }

  tutorialAlert() {

    // if(this.state.loading === true || this.state.reloading === true) {
    //   return;
    // }

    this.setState({
      alertTitle: 'Uitleg',
      alertBody: (
        <div>

          <span>Welkom bij <b>Tiemdo</b>!</span><br /><br />
          <span>Er zal een venster worden geopend waarin je een korte en snelle uitleg krijgt over het registreren van medewerkers en de planner.</span>

        </div>
      ),
      alertButtons: 1,
      alertButtonText: ['Starten'],
      showTutorialAlert: true,
    });

  }

  startTutorial() {

    this.setState({ showTutorialModal: true });

  }

  async usersHaveAllColumnsDB() {

    const datePreviousWeek = this.timeNow; // new Date(new Date().setDate(new Date().getDate() - 7))
    const mondayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 1));
    const sundayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 0));
    let employees = 0;
    let employeesSchedule = 0;

    const [
      accounts,
      info2,
      contracts,
      leaveBalances,
      contractLeaveBalance,
      log,
      plannedShifts_fallback,
      lastLog,
      hostedPages,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, null, `GROUP BY info_id`, null),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM Log`, null, `WHERE log_datum = '${Constants.dateToString(this.timeNow)}'`, null, null),
      APIGET.Request(`SELECT rooster_id, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${mondayPreviousWeek}' AND '${sundayPreviousWeek}'`, `GROUP BY rooster_info_id`, null),
      APIGET.Request(`SELECT *`, `FROM Log`, null,null,null, `ORDER BY log_id DESC LIMIT 1`),
      APIGET.Request(`SELECT *`, `FROM HostedPage`, null, null, null, null),
    ]);

    // LOG AND SUBSCRIPTION
    for (const acc of accounts) {
      if (acc.info2_status === '2' || Data.data.accountData.info_id === acc.info_id) {
        continue;
      }
      employees += 1;
    }

    for (const acc of plannedShifts_fallback) {
      if (Data.data.accountData.info_id === acc.rooster_info_id) {
        continue;
      }
      employeesSchedule += 1;
    }

    const total = (employeesSchedule > employees ? employeesSchedule : employees);

    // LOG
    if (Constants.isEmpty(log) === true || log.length === 0) {
      APIADD.Request(`INSERT INTO Log`, `VALUES (NULL, '${Constants.dateToString(this.timeNow)}', ${employees}, ${accounts.length - 1}, ${employeesSchedule})`);
    }

    // SUBSCRIPTION
    const hostedPage = Constants.isEmpty(hostedPages) === false && hostedPages.length > 0 ? hostedPages[0] : null;

    if (Constants.isEmpty(hostedPage) === false && Constants.isEmpty(lastLog) === false && lastLog.length > 0) {

      const lastActive = parseInt(lastLog[0].log_actieveMedewerkers);
      const lastScheduled = parseInt(lastLog[0].log_roosterMedewerkers);

      const lastTotal = (lastScheduled > lastActive ? lastScheduled : lastActive);

      if (total !== lastTotal) {
        const product = Data.data.appOptions.opties_versie === '2' ? Constants.isEmpty(Data.data.trial) === false ? "TIMETRACKING_TRIAL-EUR-MONTHLY" : "TIMETRACKING-EUR-MONTHLY" : Constants.isEmpty(Data.data.trial) === false ? "SCHEDULING_TRIAL-EUR-MONTHLY" : "SCHEDULING-EUR-MONTHLY";
        APIUpdateQuantity.Request(hostedPage.hp_subscription_id, total, product);
      }

    }

    // const accounts = await APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id`, null, null, null);

    // const info2 = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);

    // const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);

    // const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    // const contractLeaveBalance = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    
    const info2Obj = Constants.convertToObject(info2, 'info2_info_id');
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');
    const contractLeaveBalanceObj = Constants.convertToObjectWithArrays(contractLeaveBalance, 'cvb_contract_id');

    // const overhours = await APIGET.Request(`SELECT ov_id, ov_info_id`, `FROM Overuren`, null, null, null, null);
    // const overhoursObj = Constants.convertToObject(overhours, 'ov_info_id');

    let promises = [];

    for (const acc of accounts) {

      try {
        const newFirst = Constants.isEmpty(acc.info_voornaam) === false ? decodeURIComponent(escape(acc.info_voornaam)) : '';
        const newTV = Constants.isEmpty(acc.info_tussenvoegsel) === false ? decodeURIComponent(escape(acc.info_tussenvoegsel)) : '';
        const newLast = Constants.isEmpty(acc.info_achternaam) === false ? decodeURIComponent(escape(acc.info_achternaam)) : '';
        // promises.push(APIEDIT.Request(`UPDATE Info`, `SET info_voornaam = '${newFirst}', info_tussenvoegsel = '${newTV}', info_achternaam = '${newLast}'`, `WHERE info_id = ${acc.info_id}`));
      } catch (error) {

        // try {
        //   const newFirst = Constants.isEmpty(acc.info_voornaam) === false ? textDecoder.decode(textEncoder.encode(acc.info_voornaam)) : '';
        //   const newTV = Constants.isEmpty(acc.info_tussenvoegsel) === false ? textDecoder.decode(textEncoder.encode(acc.info_voornaam)) : '';
        //   const newLast = Constants.isEmpty(acc.info_achternaam) === false ? textDecoder.decode(textEncoder.encode(acc.info_voornaam)) : '';
        //   //promises.push(APIEDIT.Request(`UPDATE Info`, `SET info_voornaam = '${newFirst}', info_tussenvoegsel = '${newTV}', info_achternaam = '${newLast}'`, `WHERE info_id = ${acc.info_id}`));
        // } catch (error) { }

      }

      // try {
      //   const newAddress = Constants.isEmpty(acc.info_adres) === false ? textDecoder.decode(textEncoder.encode(acc.info_adres)) : '';
      //   const newCity = Constants.isEmpty(acc.info_plaats) === false ? textDecoder.decode(textEncoder.encode(acc.info_plaats)) : '';
      //   const newBornCity = Constants.isEmpty(acc.info_geboorteplaats) === false ? textDecoder.decode(textEncoder.encode(acc.info_geboorteplaats)) : '';
      //   console.log(newAddress, newCity, newBornCity)

      //   promises.push(APIEDIT.Request(`UPDATE Info`, `SET info_voornaam = '${newFirst}', info_tussenvoegsel = '${newTV}', info_achternaam = '${newLast}'`, `WHERE info_id = ${acc.info_id}`));
      // } catch (error) { }

      // if (typeof info2Obj[acc.info_id] === 'undefined') {
      //   promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${acc.info_id}, 1, 1, 1, 1, 1, 1, 1, 1)`));
      // }
      // if (typeof contractsObj[acc.info_id] === 'undefined') {
      //   promises.push(APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${acc.info_id})`));
      // }
      // if (typeof overhoursObj[acc.info_id] === 'undefined') {
      //   promises.push(APIADD.Request(`INSERT INTO Overuren`, `VALUES (NULL, ${acc.info_id}, 0)`));
      // }
    }

    for (const contract of contracts) {

      if (leaveBalances.length > 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

        if (typeof contractLeaveBalanceObj[contract.contract_id] === 'undefined') {

          for (const leave of leaveBalances) {
            promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
          }

        } else {

          for (const leave of leaveBalances) {

            if (Constants.objectArrayContainsKey(contractLeaveBalanceObj[contract.contract_id], 'cvb_vb_id', leave.vb_id) === false) {
              promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
            }

          }

        }

      }

    }

    await Promise.all(promises);

  }

  async checkWorkDays() {

    const infoExtras = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);

    let promises = [];

    for (const infoExtra of infoExtras) {

      if (infoExtra.info2_werkweek === '1' || Constants.isEmpty(infoExtra.info2_werkweek) === true || infoExtra.info2_werkdag_maandag === null || Constants.isEmpty(infoExtra.info2_werkdag_maandag) === true) {

        if (infoExtra.info2_werkdag_maandag !== Data.data.appOptions.opties_verlof_maandag
          || infoExtra.info2_werkdag_dinsdag !== Data.data.appOptions.opties_verlof_dinsdag
          || infoExtra.info2_werkdag_woensdag !== Data.data.appOptions.opties_verlof_woensdag
          || infoExtra.info2_werkdag_donderdag !== Data.data.appOptions.opties_verlof_donderdag
          || infoExtra.info2_werkdag_vrijdag !== Data.data.appOptions.opties_verlof_vrijdag
          || infoExtra.info2_werkdag_zaterdag !== Data.data.appOptions.opties_verlof_zaterdag
          || infoExtra.info2_werkdag_zondag !== Data.data.appOptions.opties_verlof_zondag) {

            promises.push(APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${Data.data.appOptions.opties_verlof_maandag}, info2_werkdag_dinsdag = ${Data.data.appOptions.opties_verlof_dinsdag}, info2_werkdag_woensdag = ${Data.data.appOptions.opties_verlof_woensdag}, info2_werkdag_donderdag = ${Data.data.appOptions.opties_verlof_donderdag}, info2_werkdag_vrijdag = ${Data.data.appOptions.opties_verlof_vrijdag}, info2_werkdag_zaterdag = ${Data.data.appOptions.opties_verlof_zaterdag}, info2_werkdag_zondag = ${Data.data.appOptions.opties_verlof_zondag}`, `WHERE info2_info_id = ${infoExtra.info2_info_id}`));

        }

      }

    }

    await Promise.all(promises);

  }

  async checkEqualSalaries() {

    const generalSalaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    let newSalariesObj = Constants.convertToObjectWithArrays(generalSalaries, 'uurloon_ut_id');

    if (generalSalaries.length > 0) {

      let promisesSalary = [];

      const [
        contracts,
        accounts,
        infoExtra,
        salaryModels,
        dateNow,
      ] = await Promise.all([
        APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
        APIGetAllAccounts.Request(),
        APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
        APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null),
        Constants.getDateNow(),
      ]);

      // add model array which are not added from db
      for (const sm of salaryModels) {

        let found = false;

        for (let key in newSalariesObj) {
          for (const s of newSalariesObj[key]) {
            if (s.uurloon_ut_id === sm.ut_id) {
              found = true;
            }
          }
        }

        if (found === false) {
          newSalariesObj[sm.ut_id] = [];
        }

      }

      const dateBack = new Date(dateNow.getTime() - 2629800000);

      const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
      const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

      for (const acc of accounts) {

        const contract = Constants.getCurrentContract(contractsObj[acc.info_id], dateNow);

        if (Constants.isEmpty(acc) === false && Constants.isEmpty(acc.info_geboorte) === false && contract !== null && contract.contract_type !== '2') {

          //let hasSalaryFromTable = false;
          const hasSalaryFromTable = typeof infoExtraObj[acc.info_id] !== 'undefined' && Constants.isEmpty(infoExtraObj[acc.info_id].info2_ut_id) === false;

          const age = Constants.getAge(dateNow, Constants.stringToDate(acc.info_geboorte));

          // if ((age - 1) <= parseInt(generalSalaries[0].uurloon_leeftijd)) {
          //   if (parseFloat(contractsObj[acc.info_id].contract_bruto_uurloon) === parseFloat(generalSalaries[0].uurloon_hoeveelheid)) {
          //     hasSalaryFromTable = true;
          //   }
          // } else if ((age - 1) >= parseInt(generalSalaries[generalSalaries.length - 1].uurloon_leeftijd)) {
          //   if (parseFloat(contractsObj[acc.info_id].contract_bruto_uurloon) === parseFloat(generalSalaries[generalSalaries.length - 1].uurloon_hoeveelheid)) {
          //     hasSalaryFromTable = true;
          //   }
          // } else {
          //   for (const generalSalary of generalSalaries) {
          //     if ((age - 1) === parseInt(generalSalary.uurloon_leeftijd)) {
          //       if (parseFloat(contractsObj[acc.info_id].contract_bruto_uurloon) === parseFloat(generalSalary.uurloon_hoeveelheid)) {
          //         hasSalaryFromTable = true;
          //         break;
          //       }
          //     }
          //   }
          // }

          if (hasSalaryFromTable === true) {

            const salaries = newSalariesObj[infoExtraObj[acc.info_id].info2_ut_id];

            if (salaries.length === 0) {
              continue;
            }

            const birthDate = new Date(Constants.stringToDate(acc.info_geboorte).setFullYear(dateNow.getFullYear()));

            // check if birthday is within today and month back.
            if (birthDate.getTime() >= dateBack.getTime() && birthDate.getTime() <= dateNow.getTime()) {

              let newSalary = 0;

              if ((age) <= parseInt(salaries[0].uurloon_leeftijd)) {
                newSalary = parseFloat(salaries[0].uurloon_hoeveelheid);
              } else if ((age) >= parseInt(salaries[salaries.length - 1].uurloon_leeftijd)) {
                newSalary = parseFloat(salaries[salaries.length - 1].uurloon_hoeveelheid);
              } else {
                for (const generalSalary of salaries) {
                  if ((age) === parseInt(generalSalary.uurloon_leeftijd)) {
                    newSalary = parseFloat(generalSalary.uurloon_hoeveelheid);
                    break;
                  }
                }
              }

              // set new salary
              promisesSalary.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${newSalary}`, `WHERE contract_id = ${contract.contract_id}`));

            }

          }

        }

      }

      await Promise.all(promisesSalary);

    }

  }

  async reloadRequests() {

    this.setState({loading: true});

   await this.componentDidMount();

  }

  async getReplaceCount() {

    let count = await APIGetAvailableShiftsAdmin.Request();

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

        this.teamPlanner3 = [];

        for (const trade of count) {

            const obj = await APIGetTeamPlanner.Request(trade.rooster_datum);

            for (const teampl of obj) {
                this.teamPlanner3 = [...this.teamPlanner3, teampl];
            }

        }

        const oldTrades = count;
        count = [];

        for (const team of Data.data.ownTeams) {

            const arr = Constants.getTeamPlannerT(this.teamPlanner3, oldTrades, parseInt(team.team_id));

            for (const teampl of arr) {
                count = [...count, teampl];
            }

        }

    }

    const availablesPlanner = await APIGET.Request(`SELECT sbg_shb_id, shb_team_id`, `FROM SBGeaccepteerd`, `LEFT JOIN ShiftBeschikbaar ON shb_id = sbg_shb_id`, `WHERE shb_datum >= '${Constants.dateToString(this.state.timeNow)}'`, null, null);
    const availablesPlannerObj = Constants.convertToObjectWithArrays(availablesPlanner, 'sbg_shb_id');
    let count2 = 0;

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
      for (const team of Data.data.ownTeams) {
        for (const key in availablesPlannerObj) {
          if (availablesPlannerObj[key][0].shb_team_id === team.team_id) {
            count2++;
          }
        }
      }
    } else {
      count2 = Object.keys(availablesPlannerObj).length;
    }

    this.setState({replaceCount: count.length + count2});

    return count.length + count2;

  }

  // startTutorial() {

  //   Data.data.inTutorial = true;
  //   Data.data.tutorialStep = 1;

  //   this.setState({ tutorialModal: true });

  //   this.openQuestionModal();

  // }

  // cancelTutorial() {

  //     localStorage.setItem('@doneTutorial', '1');

  //     this.setState({ loading: true });

  //     this.componentDidMount();

  // }

  openHomeModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  async getOwnSchedule() {

    this.ownScheduleObject = await APIGetOwnSchedule.Request();

    if(this.ownScheduleObject === 'error') {
        
        this.ownScheduleObject = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(this.ownScheduleObject === null || this.ownScheduleObject === []) {
        this.ownScheduleObject = [];
        this.setState({
            noShifts: true,
        });
        return;
    }

    let ownSchedules = [];

    if (this.ownScheduleObject !== null) {
        for (const key in this.ownScheduleObject) {
            const shift = this.ownScheduleObject[key];
            if(new Date(shift.rooster_datum + 'T23:59:59') < new Date()) {
                continue;
            }
            ownSchedules.push(shift);
        }
    }

    this.setState({ 
      ownScheduleState: ownSchedules,
    });


  }

  getHours() {

      let month = null;

      for(let key in this.ownScheduleObject) {

          const startTime = Constants.getDateTZ(this.ownScheduleObject[key].rooster_datum, this.ownScheduleObject[key].rooster_begin).getTime();
          const endTime = Constants.getDateTZ(this.ownScheduleObject[key].rooster_datum, this.ownScheduleObject[key].rooster_eind).getTime();
          const breakHours = Constants.getHoursTZ(this.ownScheduleObject[key].rooster_datum, this.ownScheduleObject[key].rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(this.ownScheduleObject[key].rooster_datum, this.ownScheduleObject[key].rooster_pauze) / 60;

          //const hourDiff = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_eind}`) - new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_begin}`);

          //const hourDiff = ((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes;

          const hourDiff = Math.round(((((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes) + Number.EPSILON) * 100) / 100;

          if(month !== Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)) {
              month = Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum);
              //this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] = 0 + (hourDiff / 60 / 60 / 1000);
              this.hours[Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)] = 0 + hourDiff;
              continue;
          }

          //this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] = this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] + (hourDiff / 60 / 60 / 1000);
          this.hours[Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)] = this.hours[Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)] + hourDiff;

      }

      this.setState({
          hours: this.hours
      });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    const timeNow = await Constants.getDateNow();
    this.setState({timeNow: timeNow});

    let otherTradesCount = 0;
    let tradeRequestsCount = 0;
    let holidaysCount = 0;

    // const otherTrades = await APIGetOtherTrades.Request();

    // let indexOtherTrades = 0;
    // if(otherTrades !== null && otherTrades !== 'error') {
        
    //   for (const trade of otherTrades) {

    //     if(trade.ruildienst_geaccepteerd === '0') {
    //       indexOtherTrades++;
    //     }

    //   }

    //   otherTradesCount = indexOtherTrades;
    //   this.setState({otherTradesCount: indexOtherTrades});
    // }

    if(Data.data.accountData.account_rights === '1' || Data.data.accountData.account_rights === '2') {

        let tradeRequests = await APIGetAllTrades.Request();

        let indexTrades = 0;
        if(tradeRequests !== null && tradeRequests !== 'error') {

            if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

              this.teamPlanner4 = [];
  
              for (const trade of tradeRequests) {
  
                  const obj = await APIGetTeamPlanner.Request(trade.rooster_datum);
  
                  for (const teampl of obj) {
                      this.teamPlanner4 = [...this.teamPlanner4, teampl];
                  }
  
              }
  
              const oldTrades = tradeRequests;
              tradeRequests = [];
  
              for (const team of Data.data.ownTeams) {
  
                  const arr = Constants.getTeamPlannerT(this.teamPlanner4, oldTrades, parseInt(team.team_id));
  
                  for (const teampl of arr) {
                    tradeRequests = [...tradeRequests, teampl];
                  }
  
              }
  
            }

            for (const trade of tradeRequests) {

                if(trade.ruildienst_geaccepteerd === '1') {
                    indexTrades++;
                }

            }

            tradeRequestsCount = indexTrades;
            this.setState({tradeRequestsCount: indexTrades});

        }

    }

    if(Data.data.accountData.account_rights === '2') {

        const holidayRequests = await APIGetHolidayCount.Request();

        if(holidayRequests !== null && holidayRequests !== 'error') {

            holidaysCount = parseInt(holidayRequests);
            this.setState({holidaysCount: holidaysCount});

        }

    }

    const totalCount = tradeRequestsCount + holidaysCount;

    this.setState({ totalCount: totalCount });

    this.teamPlanner = await APIGetTeamsPlannerOwn.Request(Data.data.accountData.info_id);

    const news = await APIGetAllNews.Request();
    const allUsers = await APIGetAllAccounts.Request();

    this.setState({ news: news, allUsers: allUsers });

    await this.getOwnSchedule();
    this.getHours();

    this.setState({reloading: false});

  }

  getDayName(date) {

      const dayNumber = Constants.stringToDate(date).getDay();

      const weekday = new Array(7);
      weekday[0] = Localization.t.misc_sunday;
      weekday[1] = Localization.t.misc_monday;
      weekday[2] = Localization.t.misc_tuesday;
      weekday[3] = Localization.t.misc_wednesday;
      weekday[4] = Localization.t.misc_thursday;
      weekday[5] = Localization.t.misc_friday;
      weekday[6] = Localization.t.misc_saturday;

      return weekday[dayNumber];

  }

  getShortDayName(date) {

      const dayNumber = Constants.stringToDate(date).getDay();

      const weekday = new Array(7);
      weekday[0] = `${Localization.t.misc_sunday_short}.`;
      weekday[1] = `${Localization.t.misc_monday_short}.`;
      weekday[2] = `${Localization.t.misc_tuesday_short}.`;
      weekday[3] = `${Localization.t.misc_wednesday_short}.`;
      weekday[4] = `${Localization.t.misc_thursday_short}.`;
      weekday[5] = `${Localization.t.misc_friday_short}.`;
      weekday[6] = `${Localization.t.misc_saturday_short}.`;

      return weekday[dayNumber];

  }

  getMonthName(date) {

      const month = Constants.stringToDate(date).getMonth();

      const monthNames = [Localization.t.misc_january, Localization.t.misc_february, Localization.t.misc_march,
        Localization.t.misc_april, Localization.t.misc_may, Localization.t.misc_june,
        Localization.t.misc_july, Localization.t.misc_august, Localization.t.misc_september,
        Localization.t.misc_october, Localization.t.misc_november, Localization.t.misc_december
      ];

      return monthNames[month];

  }

  getShortMonthName(date) {

      const month = Constants.stringToDate(date).getMonth();

      const monthNames = [Localization.t.misc_january_short, Localization.t.misc_february_short, Localization.t.misc_march_short,
        Localization.t.misc_april_short, Localization.t.misc_may_short, Localization.t.misc_june_short,
        Localization.t.misc_july_short, Localization.t.misc_august_short, Localization.t.misc_september_short,
        Localization.t.misc_october_short, Localization.t.misc_november_short, Localization.t.misc_december_short
      ];

      return monthNames[month];

  }

  getTiemdoNewsBars() {

    let toRender = [];

    const tiemdoNews = this.state.tiemdoNews;

    for (const news of tiemdoNews) {

      const title = news.nieuws_titel;
      const deltaOps = JSON.parse(news.nieuws_tekst).ops;

      const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
      const parser = new DOMParser();

      const htmlString = converter.convert();
      //const html = parser.parseFromString(htmlString, "text/html");

      toRender.push(

        // style={{ backgroundColor: Constants.isTrue(news.nieuws_pinned) ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.2) : null }} 
        <div className='home2_button_new2' onClick={() => this.openUpdatesMenu(news)}>

          <div>
            {Constants.isTrue(news.nieuws_pinned) ?
              <div style={{fontSize: '0.8vw', fontWeight: 600 }}><i className='far fa-thumbtack' style={{ transform: "rotate(45deg)", color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /> {title}</div>
            :
              <div style={{fontSize: '0.8vw', fontWeight: 600 }}>{title}</div>
            }
            <div style={{ color: Colors.color.muted, fontSize: '0.6vw' }}>{Constants.stringToDate(news.nieuws_datum).toLocaleDateString()}</div>
          </div>
          <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />

          {/* <div dangerouslySetInnerHTML={{ __html: htmlString }} /> */}

        </div>

      );

    }

    return toRender;

  }

  selectUpdateMenu(news) {

    const deltaOps = JSON.parse(news.nieuws_tekst).ops;

    const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
    const parser = new DOMParser();

    const htmlString = converter.convert();

    this.searchInput_updates.value = '';

    this.setState({
      updatesMenu: news.nieuws_id,
      newsArticle: htmlString,
      newsObject: news,
    });

  }

  openUpdatesMenu(news) {

    const deltaOps = JSON.parse(news.nieuws_tekst).ops;

    const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
    const parser = new DOMParser();

    const htmlString = converter.convert();

    this.setState({
      updatesActive: true,
      updatesMenu: news.nieuws_id,
      newsArticle: htmlString,
      newsObject: news,
    });

  }

  listAllUpdates() {

    let toRender = [];

    for (const news of this.state.tiemdoNews) {

      if (Constants.isEmpty(this.state.searchText_updates) === true || (news.nieuws_titel.toLowerCase().includes(this.state.searchText_updates.toLowerCase()) || news.nieuws_tekst.toLowerCase().includes(this.state.searchText_updates.toLowerCase()))) {

        toRender.push(

          <div className={`employees_menuButton ${this.state.updatesMenu === news.nieuws_id ? 'activeAv' : ''}`} onClick={() => this.selectUpdateMenu(news)}>
            <div>{news.nieuws_titel}</div> 
          </div>

        );

      }

    }

    return toRender;

  }

  onChangeSearchText_updates(event) {

    const value = event.target.value;

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(async () => {
      this.setState({ searchText_updates: value });
    }, 200);

  }

  getOwnScheduleBars() {

    let ownScheduleList = [];

    let month = null;
    let showMonth = false;

    for(let key in this.ownScheduleObject) {

        const monthNameCapitalized = this.getMonthName(this.ownScheduleObject[key].rooster_datum).charAt(0).toUpperCase() + this.getMonthName(this.ownScheduleObject[key].rooster_datum).substring(1);

        if(month !== monthNameCapitalized) {
            month = monthNameCapitalized;
            showMonth = true;
        } else {
            showMonth = false;
        }

        if (ownScheduleList.length === 10) {
          break;
        }

        if(new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date()) {
            continue;
        }

        const date = Constants.stringToDate(this.ownScheduleObject[key].rooster_datum);

        let functionColor = this.ownScheduleObject[key].functie_kleur !== null ? this.ownScheduleObject[key].functie_kleur : 'EBEBEB';
        let functionName = this.ownScheduleObject[key].functie_naam !== null ? this.ownScheduleObject[key].functie_naam : 'Functie onbekend';

        ownScheduleList.push(

            <div key={key} className='home2_touchBoxBase' style={{marginTop: '1vw'}}>

                <div style={{cursor: new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ? 'default' : 'pointer'}} onClick={() => new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ? null : this.openHomeModal(0, this.ownScheduleObject[key])}>

                  <div className='home2_touchContainer'
                    style={new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ?
                              { 
                                borderBottomColor: Colors.color.pastBorderColor,
                                borderLeftColor: Colors.color.pastBorderColor,
                                backgroundColor: Colors.color.pastBackgroundColor,
                              }
                            :
                              { 
                                borderBottomColor: `#${functionColor}`,
                                borderLeftColor: `#${functionColor}`,
                                backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.2),
                              }
                          } 
                  >

                    <i className='far fa-chevron-right' style={{fontSize: '0.9vw', display: 'flex', position: 'absolute', right: '1vw', alignSelf: 'center', zIndex: 1}} />

                    <div className='home2_initContainer' style={{backgroundColor: new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ? Colors.color.pastBorderColor : Constants.hexToRgbA(`#${functionColor}`, 0.5)}}>

                      {new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ?
                        <i className='far fa-ban' />
                      : 
                        <span style={{color: Constants.getContrastPlan(`#${functionColor}`)}}>{functionName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                      }
                      
                    </div>

                    <div className='home2_infoContainer'>

                      <div className='home2_infoRow'>
                        <span><b>{Constants.firstUppercase(Constants.getDayName(date))}</b></span>
                        <span style={{marginLeft: '0.4vw'}}>{date.getDate()} {Constants.getShortMonthName(date).toLowerCase()}</span>
                      </div>

                      <div className='home2_infoRow' style={{marginTop: '0.2vw'}}>
                        <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                          <span style={{color: `#${functionColor}`}}><i className='far fa-clock' /></span>
                        </div>
                        <div style={{display: 'flex', flex: 10}}>
                          <span>{this.ownScheduleObject[key].rooster_begin.substring(0,5)} - {this.ownScheduleObject[key].rooster_eind.substring(0,5)}</span>
                        </div>
                      </div>

                      {this.ownScheduleObject[key].rooster_pauze !== '00:00:00' ?
                        <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                          <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                            <span style={{color: `#${functionColor}`}}><i className='far fa-coffee' /></span>
                          </div>
                          <div style={{display: 'flex', flex: 10}}>
                            <span>{this.ownScheduleObject[key].rooster_pauze.substring(0,5)}</span>
                          </div>
                        </div>
                      : null}

                      <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                        <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                          <span style={{color: `#${functionColor}`}}><i className='far fa-briefcase' /></span>
                        </div>
                        <div style={{display: 'flex', flex: 10}}>
                          <span>{functionName}</span>
                        </div>
                      </div>

                      {Data.data.teams.length > 1 && Data.data.ownTeams.length > 0 ?
                        <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                          <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                            <span style={{color: `#${functionColor}`}}><i className='far fa-map-marker-alt' /></span>
                          </div>
                          <div style={{display: 'flex', flex: 10}}>
                            <span>{Constants.getTeamNameFromScheduleID(this.teamPlanner, this.ownScheduleObject[key].rooster_id)}</span>
                          </div>
                        </div>
                      : null}

                      {this.ownScheduleObject[key].bijz_id !== null ?
                        <div className='home2_infoRow3' style={{marginTop: '0.2vw'}}>
                          <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                            <span style={{color: `#${functionColor}`}}><i className='far fa-sticky-note' /></span>
                          </div>
                          <div style={{display: 'flex', flex: 10}}>
                            <span style={{color: `#${this.ownScheduleObject[key].bijz_kleur}`}}>{this.ownScheduleObject[key].bijz_naam}</span>
                          </div>
                        </div>
                      : null}

                    </div>

                  </div>

                </div>

            </div>

        );

    }

    return ownScheduleList;

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  openQuestionModal() {

    this.setState({ showQuestionModal: true });

  }

  closeQuestionModal() {

    this.setState({ showQuestionModal: false });

  }

  openMissingModal() {
    this.setState({ missingModalVisible: true });
  }

  closeMissingModal() {
      this.setState({ missingModalVisible: false });
  }

  openAddNewsModal() {
    this.setState({ showAddNewsModal: true });
  }

  closeAddNewsModal() {
      this.setState({ showAddNewsModal: false });
  }

  openBigModal(content) {

    if (content === 'home') {
      this.setState({ 
        showBigModal: true,
        content: <Home />,
        header: Localization.t.home_ownShifts,
      });
    } else if (content === 'news') {
      this.setState({ 
        showBigModal: true,
        content: <News />,
        header: Localization.t.home_newsOverview,
      });
    }

  }

  closeBigModal() {
      this.setState({ showBigModal: false });
  }

  async deleteNewsArticle(article) {

    await APIDELETE.Request(`DELETE FROM Nieuws`, `WHERE nieuws_id = ${article.nieuws_id}`);

    const news = await APIGetAllNews.Request();

    this.setState({ news: news });

  }

  getNewsArticles() {

    let toReturn = [];

    for (const article of this.state.news) {

        if (toReturn.length === 10) {
            break;
        }

        const date = Constants.stringToDate(article.nieuws_datum);
        const userFullName = `${article.info_voornaam} ${(article.info_tussenvoegsel !== null && article.info_tussenvoegsel !== '') ? article.info_tussenvoegsel + " " + article.info_achternaam.charAt(0) : article.info_achternaam.charAt(0)}.`; //.charAt(0)

        toReturn.push(

            <div style={{marginTop: toReturn.length > 0 ? '2vw' : '1vw', width: '100%', position: 'relative'}}>
                <div style={{fontSize: '1vw', fontWeight: 'bold'}}>{Constants.firstUppercase(article.nieuws_titel)}</div>
                <div style={{fontSize: '0.7vw', color: Colors.color.pastBorderColor}}>{Constants.getDayName(article.nieuws_datum)} {date.getDate()} {Constants.getMonthName(article.nieuws_datum)} {date.getFullYear()} {Localization.t.misc_by} {userFullName}</div>
                <div style={{fontSize: '0.8vw', width: '90%'}}>{Constants.firstUppercase(article.nieuws_tekst)}</div>
            
                <i className='fas fa-minus-circle home2_news_delete' onClick={() => this.deleteNewsArticle(article)} />
            </div>

        );

    }

    return toReturn;

  }

  closeStartupModal() {

    this.setState({ showStartupModal: false }, () => {

      this.componentDidMount();

    });

  }

  closeStartupAlert() {

    this.setState({ showStartupAlert: false }, async () => {

      if (this.state.allUsers.length > 1) {

        await APIEDIT.Request(`UPDATE Tutorial`, `SET tut_startup = 1`, `WHERE tut_id = 1`);

        await this.componentDidMount();

      } else {

        this.startupConfig();

      }

    });

  }

  closeTutorialModal() {

    this.setState({ showTutorialModal: false }, () => {

      this.componentDidMount();

    });

  }

  closeTutorialAlert() {

    this.setState({ showTutorialAlert: false }, async () => {

        this.startTutorial();

    });

  }

  renderEvents() {

    let toReturn = [];

    for (const ue of this.state.upcoming_events) {

      toReturn.push(

        <div key={`${toReturn.length}`} className='home2_eventContainer'>

          <div className='home2_eventContainer_sub1'>
            {ue.icon}
          </div>

          <div className='home2_eventContainer_sub2'>
            <span>{ue.title}</span>
            {Constants.isEmpty(ue.timeString) === false && Constants.isEmpty(ue.name2) === false ?
              <span className='home2_event_date'>{ue.date.toLocaleDateString()} {Localization.t.misc_at} {ue.timeString} {Localization.t.misc_by} {ue.name2}</span>
            :
            Constants.isEmpty(ue.timeString) === false ?
              <span className='home2_event_date'>{ue.date.toLocaleDateString()} {Localization.t.misc_at} {ue.timeString}</span>
            :
              <span className='home2_event_date'>{ue.date.toLocaleDateString()}</span>
            }
          </div>

        </div>

      );

    }

    return toReturn;

  }

  renderAllRequests() {

    let toReturn = [];

    for (const ue of this.state.all_requests) {

      const userFullName = `${ue.info_voornaam} ${(ue.info_tussenvoegsel !== null && ue.info_tussenvoegsel !== '') ? ue.info_tussenvoegsel + " " + ue.info_achternaam : ue.info_achternaam}`; //.charAt(0)

      if (ue.type === 'contract' && Data.data.appOptions.opties_versie === '0') {
        continue;
      }

      toReturn.push(

        <div key={`${toReturn.length}`} className='home2_requestsContainer'>

          <div className='home2_requestsContainer_sub1'>
            {ue.icon}
          </div>

          <div className='home2_requestsContainer_sub2'>
            {ue.title}
          </div>

          <div className='home2_requestsContainer_sub3'>
            {ue.text}
          </div>

          {ue.type === 'contract' ?
            <div className='home2_requestsContainer_sub4' onClick={() => this.props.history.push({pathname: '/employees', selected: ue.info_id, selectedName: userFullName, menu: 'contract'})}>
              <i className='fal fa-angle-right' style={{fontSize: '1.5vw'}} />
            </div>
          :
          ue.type === 'trade' ?
            <div className='home2_requestsContainer_sub4' onClick={() => this.props.history.push({pathname: '/requests', highlight: ue.rs_id, menu: 'trades'})}>
              <i className='fal fa-angle-right' style={{fontSize: '1.5vw'}} />
            </div>
          :
          ue.type === 'takeover' ?
            <div className='home2_requestsContainer_sub4' onClick={() => this.props.history.push({pathname: '/requests', highlight: ue.bdienst_id, menu: 'shifts'})}>
              <i className='fal fa-angle-right' style={{fontSize: '1.5vw'}} />
            </div>
          :
          ue.type === 'holiday' ?
            <div className='home2_requestsContainer_sub4' onClick={() => this.props.history.push({pathname: '/requests', highlight: ue.vakantie_id, menu: 'absences'})}>
              <i className='fal fa-angle-right' style={{fontSize: '1.5vw'}} />
            </div>
          : null}

        </div>

      );

    }

    return toReturn;

  }

  selectMenu(menu) {

    if (this.state.menu === menu) {
      return;
    }

    if (this.state.changesEditor === true) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ changesEditor: false });
      }
    }

    this.setState({ menu: menu, uploadError: '', file: null });

  }

  handleChangeEditor(html, delta, who, editor) {

    this.setState({ editorValue: editor.getContents(), editorLength: editor.getLength(), changesEditor: true });

  }

  saveEditorAlert() {

    this.setState({
        alertTitle: Localization.t.alert_save_title,
        alertBody: (
            <div>
                {Localization.t.home_editor_save}
            </div>
        ),
        alertButtons: 2,
        alertButtonText: [Localization.t.button_cancel, Localization.t.button_save],
        alertButtonAction: this.saveEditor.bind(this),
        showAlert: true,
    });

  }

  async saveEditor() {

    this.setState({ reloading: true });

    let hasInfo = [];
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      hasInfo = await APIGET.Request(`SELECT *`, `FROM BedrijfInformatie`, null, `WHERE bi_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      hasInfo = await APIGET.Request(`SELECT *`, `FROM BedrijfInformatie`, null, null, null, null);
    }

    if (this.state.editorLength < 2) {

      if (hasInfo.length > 0) {
        await APIDELETE.Request(`DELETE FROM BedrijfInformatie`, `WHERE bi_id > 0`);
      }

    } else {

      if (hasInfo.length > 0) {
        await APIEDIT.Request(`UPDATE BedrijfInformatie`, `SET bi_tekst = '${Constants.addSlashes(JSON.stringify(this.state.editorValue))}'`, `WHERE bi_id = ${hasInfo[0].bi_id}`);
      } else {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          await APIADD.Request(`INSERT INTO BedrijfInformatie`, `VALUES (NULL, '${Constants.addSlashes(JSON.stringify(this.state.editorValue))}', ${Data.data.chosenTeamObj.team_id})`);
        } else {
          await APIADD.Request(`INSERT INTO BedrijfInformatie`, `VALUES (NULL, '${Constants.addSlashes(JSON.stringify(this.state.editorValue))}', NULL)`);
        }
      }

    }

    this.setState({ reloading: false, changesEditor: false });

  }

  checkFileValidations() {

    if (this.state.file === null) {
      this.setState({ uploadError: Localization.t.error_file_empty });
      return false;
    }

    if (this.state.file.size > 25000000) {
      this.setState({ uploadError: Localization.t.error_file_max });
      return false;
    }

    return true;

  }

  async uploadFile() {

    this.setState({uploadError: ''});

    if (this.checkFileValidations() === false) {
      return;
    }

    this.setState({ reloading: true });

    const upload = await APIUploadFile.Request(this.state.file);

    if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

      let uploaded = false;
      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
        uploaded = await APIADD.Request(`INSERT INTO BedrijfDocument`, `VALUES (NULL, '${this.state.file.name}', '${upload.loc}', ${Data.data.chosenTeamObj.team_id})`);
      } else {
        uploaded = await APIADD.Request(`INSERT INTO BedrijfDocument`, `VALUES (NULL, '${this.state.file.name}', '${upload.loc}', NULL)`);
      }

      if (uploaded === true) {

        this.setState({file: null});
        this.uploadInput.value = null;

        let docs = [];
        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
          docs = await APIGET.Request(`SELECT *`, `FROM BedrijfDocument`, null, `WHERE bd_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY bd_naam`);
        } else {
          docs = await APIGET.Request(`SELECT *`, `FROM BedrijfDocument`, null, null, null, `ORDER BY bd_naam`);
        }

        this.setState({ documents: docs });

      }

    }

    this.setState({ reloading: false });

  }

  async uploadFileLogo() {

    this.setState({uploadError: ''});

    if (this.checkFileValidations() === false) {
      return;
    }

    this.setState({ reloading: true });

    const upload = await APIUploadFile.Request(this.state.file);

    if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

      const uploaded = await APIADD.Request(`INSERT INTO BedrijfsLogo`, `VALUES (NULL, '${upload.loc}')`);

      if (uploaded === true) {

        if (Data.data.storeLogo !== null) {
          await APIDelFile.Request(Data.data.storeLogo);
          await APIDELETE.Request(`DELETE FROM BedrijfsLogo`, `WHERE bl_loc = '${Data.data.storeLogo}'`);
        }

        this.setState({file: null});
        this.uploadInput.value = null;

        const companyLogo = await APIGET.Request(`SELECT *`, `FROM BedrijfsLogo`, null, null, null, null);

        if (companyLogo.length > 0) {
          Data.data.storeLogo = `${companyLogo[0].bl_loc}`;
        }

      }

    }

    this.setState({ reloading: false });

  }

  getDocumentBars() {

    let toReturn = [];

    for (const doc of this.state.documents) {

      toReturn.push(

        <div key={doc.bd_id} className='employees_doc_row'>

          <div className='employees_doc_col'>
            <i className='fal fa-file employees_doc_fileIcon' />
            <a className='defaultLink' style={{fontSize: '0.8vw'}} href={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${doc.bd_loc}`} target='_blank' download={`${doc.bd_naam}`}>{doc.bd_naam}</a>
          </div>

          <div className='employees_doc_col'>
            <span className='planner_publishMenu_button' onClick={() => window.open(`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${doc.bd_loc}`, '_blank')}>Openen</span>
            <span className='planner_publishMenu_deButton' style={{marginLeft: '5%'}} onClick={() => this.deleteAlertFile(doc)}>Verwijderen</span>
          </div>

        </div>

      );

    }

    return toReturn;

  }

  deleteAlertFile(doc) {

    this.setState({
        alertTitle: Localization.t.alert_delete_title,
        alertBody: Localization.t.home_file_delete,
        alertButtons: 2,
        alertButtonAction: this.deleteFile.bind(this, doc),
        alertButtonText: [Localization.t.button_cancel, Localization.t.button_delete],
        showAlert: true,
    });
  }

  async deleteFile(doc) {

    await APIDelFile.Request(doc.bd_loc);

    await APIDELETE.Request(`DELETE FROM BedrijfDocument`, `WHERE bd_id = ${doc.bd_id}`);

    let docs = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      docs = await APIGET.Request(`SELECT *`, `FROM BedrijfDocument`, null, `WHERE bd_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY bd_naam`);
    } else {
      docs = await APIGET.Request(`SELECT *`, `FROM BedrijfDocument`, null, null, null, `ORDER BY bd_naam`);
    }

    this.setState({ documents: docs });

  }

  deleteLogoAlert() {

    this.setState({
        alertTitle: Localization.t.alert_delete_title,
        alertBody: Localization.t.home_logo_delete,
        alertButtons: 2,
        alertButtonAction: this.deleteLogo.bind(this),
        alertButtonText: [Localization.t.button_cancel, Localization.t.button_delete],
        showAlert: true,
    });
  }

  async deleteLogo() {

    await APIDelFile.Request(Data.data.storeLogo);

    await APIDELETE.Request(`DELETE FROM BedrijfsLogo`, `WHERE bl_loc = '${Data.data.storeLogo}'`);

    Data.data.storeLogo = null;

    this.forceUpdate();

  }

  render() {

    let editorModules  = {
      toolbar: [
        [{ 'color': [] }, { 'background': [] }],
        [{ 'size': [] }],
        [{ 'header': [] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'align': [] }],
        ['link'],
        [{ 'direction': 'rtl' }], 
        ['clean']
      ],
    };

    if (this.state.editorOn === false) {
      editorModules  = {
        toolbar: false,
      };
    }

    return (

      <div>

        {this.state.reloading === true ?       
          <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
        : null}

        <Prompt
          when={this.state.changesEditor}
          message='You have unsaved changes. Are you sure you want to leave?'
        />

      {this.state.showStartupAlert === true ?
                
        <BetterAlert
          _closeAlert={this.closeStartupAlert.bind(this)}
          _title={this.state.alertTitle}
          _body={this.state.alertBody}
          _buttons={this.state.alertButtons}
          _buttonText={this.state.alertButtonText}
          _buttonAction={this.state.alertButtonAction}
          _buttonAction2={this.state.alertButtonAction2}
        />

      : null}

      {this.state.showStartupModal ?
        <StartupModal history={this.props.history} _closeModal={this.closeStartupModal.bind(this)} />
      : null}

      {this.state.showTutorialAlert === true ?
                
        <BetterAlert
          _closeAlert={this.closeTutorialAlert.bind(this)}
          _title={this.state.alertTitle}
          _body={this.state.alertBody}
          _buttons={this.state.alertButtons}
          _buttonText={this.state.alertButtonText}
          _buttonAction={this.state.alertButtonAction}
          _buttonAction2={this.state.alertButtonAction2}
        />

      : null}

      {this.state.showTutorialModal ?
        <TutorialModal history={this.props.history} _closeModal={this.closeTutorialModal.bind(this)} />
      : null}

        {/* <Prompt
          when={true}
          message='You have unsaved changes, are you sure you want to leave?'
        /> */}

      <Navbar ref={this.navBar} history={this.props.history} active={'home'} fixed loading={this.state.loading} reloading={this.state.reloading} parent={this} />

      <div className={`home2_updatesBG ${this.state.updatesActive === true ? 'active' : ''}`} id="menuBackgroundUpdates" onClick={(e) => e.target === document.getElementById("menuBackgroundUpdates") ? this.setState({updatesActive: false}) : null}>

        <div id={'home2_updates'} className={`${this.state.updatesActive === true ? 'active' : ''}`}>

          <div className='employees_menu2' style={{ height: 'calc(100vh - 2vw - 2vh)', width: '25.5%' }}>

            <div className='employees_menuTitle'>
              <div>Tiemdo nieuws</div>
            </div>

            <div style={{ paddingLeft: '6%', paddingRight: '6%', marginBottom: '1vw' }}>
              <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>Zoeken</div>
              <input
                ref={(ref) => this.searchInput_updates=ref}
                className={'inputField2'}
                maxLength={100}
                onChange={(event) => this.onChangeSearchText_updates(event)}
                placeholder={'Zoeken'}
              />
            </div>

            {this.listAllUpdates()}

          </div>

          <div className='employees_content2' style={{ height: 'calc(100vh - 1vw - 1px)', width: '74%' }}>

              <div className='employees_container2' style={{ paddingRight: 'calc(2.5% + 1vw)', paddingLeft: 'calc(2.5% + 1vw)', width: 'calc(90% - 2vw)' }}>

                {/* <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '1vw'}}>Tiemdo nieuws</div> */}

                {/* <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw'}}>

                  <div>
                    <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.1vw' }}>Zoeken</div>
                    <input
                      ref={(ref) => this.searchInput_updates=ref}
                      className={'inputField2'}
                      maxLength={100}
                      onChange={(event) => this.onChangeSearchText_updates(event)}
                      placeholder={'Zoeken'}
                    />
                  </div>

                </div> */}

                {Constants.isEmpty(this.state.updatesMenu) === false && Constants.isEmpty(this.state.newsArticle) === false ?

                  <div style={{ }}>

                    <b style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1, fontSize: '1.2vw' }}>{this.state.newsObject.nieuws_titel}</b>
                    <div style={{ color: Colors.color.muted, fontSize: '0.7vw' }}>{Constants.stringToDate(this.state.newsObject.nieuws_datum).toLocaleDateString()}</div>

                    <div dangerouslySetInnerHTML={{ __html: this.state.newsArticle }} />

                  </div>

                : null}

              </div>

          </div>

        </div>

      </div>

      <div className='employees_main2'>
        {/* stickyTopMenuPanel */}
        {/* {Data.data.appOptions.opties_versie === '2' ? */}
          <div className='employees_menu2'>

              <div className='employees_menuTitle'>
                <div>{Localization.t.navbar_home}</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'home' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('home')}>
                <div>{Localization.t.home_menu_frontPage}</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'info' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('info')}>
                <div>{Localization.t.home_menu_companyInfo}</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'documents' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('documents')}>
                <div>{Localization.t.home_menu_companyFiles}</div>
              </div>

              {Data.data.accountData.account_rights === '2' ?
                <div className={`employees_menuButton ${this.state.menu === 'logo' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('logo')}>
                  <div>{Localization.t.home_menu_companyLogo}</div>
                </div>
              : null}

          </div>
        {/* : null} */}

        <div className='employees_content2' style={{width: Data.data.appOptions.opties_versie !== '0' ? 'calc(87% - 0px)' : '100%', fontSize: '0.8vw'}}>

          {this.state.loading === true ?
            
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='home2_container_new'>

              <div>
                {this.ttthtml}
              </div>

               {this.state.missingModalVisible === true ?
                
                <MissingModal 
                  _closeModal={this.closeMissingModal.bind(this)}
                  _history={this.props.history}
                  _parent={this}
                />

              :

                null
              
              }

              {this.state.showBigModal === true ?
                
                <BetterBigModal 
                  _closeModal={this.closeBigModal.bind(this)}
                  _history={this.props.history}
                  _content={this.state.content}
                  _header={this.state.header}
                  _parent={this}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              {this.state.showModal === true ?
                
                <HomeModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                  history={this.props.history}
                />

              :

                null
              
              }

              {this.state.showAddNewsModal === true ?
                
                <AddNewsModal 
                  _closeModal={this.closeAddNewsModal.bind(this)}
                  _users={this.state.allUsers}
                  _reloadScreen={this.reloadScreen.bind(this)}
                  history={this.props.history}
                />

              :

                null
              
              }

              {this.state.menu === 'home' ?

              <GridLayout 
                compactType={'vertical'}
                // verticalCompact={true}
                // isLeftShift={true}
                layout={this.state.layout} 
                cols={3} 
                rowHeight={425} 
                containerPadding={[65, 30]} 
                margin={[30, 30]}
                width={this.state.width} 
                style={{position: 'relative', cursor: this.state.isDragging ? 'grabbing' : 'default'}}
                isBounded={true}
                onDragStop={(layout) => { this.setState({ isDragging: false, layout: layout }); localStorage.setItem('@homeLayout3', JSON.stringify(this.state.layout)); }}
                onDrag={() => this.setState({ isDragging: true })}
                draggableHandle='.home2_dragIcon'
              >

                <div key="a" className='home2_contentBox'>

                  <i className='fas fa-ellipsis-v home2_dragIcon' />

                  <div className='home2_headerContainer'>

                    <div className={'home2_contentHeader'}>{Localization.t.home_latestNews}</div>

                    {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                      <div className='home2_button_new' onClick={() => this.openAddNewsModal()}>
                        <span style={{fontSize: '1vw'}}>{Localization.t.home_latestNews_send}</span>
                        <i style={{fontSize: '1vw'}} className='fal fa-bullhorn' />
                      </div>
                    : null}

                    <div className='home2_button_new' onClick={() => this.openBigModal('news')}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.misc_showAll}</span>
                      <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
                    </div>

                  </div>

                  <div className='home2_contentContainer'>

                    {this.getNewsArticles()}

                  </div>

                </div>
                
                <div key='b' className='home2_contentBox' style={{ overflowY: 'auto', overflowX: 'hidden' }}>

                  <i className='fas fa-ellipsis-v home2_dragIcon' />

                  <div className={'home2_contentHeader'} style={{paddingTop: '2vw', marginLeft: 35}}>{Localization.t.home_actions}</div>

                  <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '0.5vw', marginLeft: 35}}>{Localization.t.home_actions_text}: {this.state.totalCount}</div>

                  {Data.data.appOptions.availability === 'STANDARD' && (Data.data.accountData.account_rights === '2') ?
                    <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'availabilities' })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_actions_availability}: <b>{this.state.availabilityRequestsCount}</b></span>
                      <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
                    </div>
                  : null}

                  {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                    <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'trades' })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_actions_trades}: <b>{this.state.tradeRequestsCount}</b></span>
                      <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
                    </div>
                  : null}

                  {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                    <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences' })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_actions_holidays}: <b>{this.state.holidaysCount}</b></span>
                      <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
                    </div>
                  : null}

                  {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                    <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'shifts' })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_actions_shifts}: <b>{this.state.replaceCount}</b></span>
                      <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
                    </div>
                  : null}

                  <div style={{fontSize: '1vw', fontWeight: 'bold', paddingTop: '1.5vw', marginLeft: 35}}>{Localization.t.home_shortcuts}</div>

                  {Data.data.accountData.account_rights === '2' ?
                    <div className='home2_buttonText2' onClick={() => this.props.history.push('/app_options')}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_shortcuts_settings}</span>
                      <i style={{fontSize: '1vw'}} className='fal fa-cogs' />
                    </div>
                  : null}

                  {Data.data.accountData.account_rights === '2' && Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) && Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) ?
                    <div className='home2_buttonText2' onClick={() => this.props.history.push('/hour_registration')}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_shortcuts_time}</span>
                      <i style={{fontSize: '1vw'}} className='fal fa-user-clock' />
                    </div>
                  : null}

                  {Data.data.accountData.account_rights === '2' && Constants.isTrue(Data.data.appOptions.opties_financien) ?
                    <div className='home2_buttonText2' onClick={() => this.props.history.push({ pathname: `/reports`, menu: 'gains' })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_shortcuts_gains}</span>
                      <i style={{fontSize: '1vw'}} className='fal fa-hand-holding-usd' />
                    </div>
                  : null}

                  {(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') && Data.data.appOptions.availability !== 'STANDARD' ?
                    <div className='home2_buttonText2' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'deadlines' })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_shortcuts_deadlines}</span>
                      <i style={{fontSize: '1vw'}} className='fal fa-stopwatch' />
                    </div>
                  : null}

                  {Data.data.accountData.account_rights === '2' && Data.data.appOptions.opties_versie !== '0' ?
                    <div className='home2_buttonText2' onClick={() => this.props.history.push({ pathname: `/reports`, menu: 'general', general_start: this.state.timeNow, general_end: new Date(this.state.timeNow.getFullYear(), this.state.timeNow.getMonth() + 3, this.state.timeNow.getDate()) })}>
                      <span style={{fontSize: '1vw'}}>{Localization.t.home_shortcuts_leave}</span>
                      <i style={{fontSize: '1vw'}} className='fal fa-user-chart' />
                    </div>
                  : null}

                  <div style={{ height: '2vw' }} />

                </div>

                <div key='c' className='home2_contentBox'>

                  <i className='fas fa-ellipsis-v home2_dragIcon' />

                  <div className='home2_headerContainer3'>

                    <div className={'home2_contentHeader'}>Tiemdo nieuws</div>

                  </div>

                  <div className='home2_contentContainer3'>

                    {this.getTiemdoNewsBars()}

                  </div>

                </div>





                <div key='d' className='home2_contentBox'>

                  <i className='fas fa-ellipsis-v home2_dragIcon' />

                  <div className={'home2_headerContainer3'}>{Localization.t.home_events}</div>

                  <div className='home2_contentContainer3'>

                    {this.renderEvents()}

                  </div>

                </div>

                <div key='e' className='home2_contentBox'>

                  <i className='fas fa-ellipsis-v home2_dragIcon' />

                  <div className={'home2_headerContainer3'}>{Localization.t.home_requestsContracts}</div>

                  <div className='home2_contentContainer3'>

                    {this.renderAllRequests()}

                  </div>

                </div>

              </GridLayout>

              :
              this.state.menu === 'info' ?

                <div style={{ width: 'calc(100% - 130px)', marginLeft: 65, marginTop: 30, }}>

                  <div className='home2_contentBox_info'>

                    <div className='home2_headerContainer_info'>

                      <div className={'home2_contentHeader'}>{Localization.t.home_companyInfo_title}</div>

                      {Data.data.accountData.account_rights === '2' ?
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>

                          {this.state.editorOn === true ?
                            <div className='reports_toggleButton_active' style={{marginLeft: 0}} onClick={() => this.setState({ editorOn: !this.state.editorOn })}>
                              <i className='fas fa-check-circle' />
                              <span>{Localization.t.home_companyInfo_edit}</span>
                            </div>
                          :
                            <div className='reports_toggleButton' style={{marginLeft: 0}} onClick={() => this.setState({ editorOn: !this.state.editorOn })}>
                              <i className='fal fa-circle' />
                              <span>{Localization.t.home_companyInfo_edit}</span>
                            </div>
                          }

                          {this.state.editorOn === true ?
                            <span className='planner_publishMenu_buttonOpen' style={{ marginLeft: '0.6vw' }} onClick={() => this.saveEditorAlert()}>{Localization.t.button_save}</span>
                          : null}

                        </div>
                      : null}

                    </div>

                    <div className='home2_contentContainer_info'>

                      <ReactQuill 
                        defaultValue={this.state.editorValue}
                        onChange={this.handleChangeEditor} 
                        modules={editorModules}
                        readOnly={!this.state.editorOn}
                      />

                    </div>

                  </div>

                </div>

              :
              this.state.menu === 'documents' ?

                <div style={{ width: 'calc(100% - 130px)', marginLeft: 65, marginTop: 30, }}>

                  <div className='home2_contentBox_info'>

                    <div className='home2_headerContainer_info'>

                      <div className={'home2_contentHeader'}>{Localization.t.home_documents_title}</div>

                      {Data.data.accountData.account_rights === '2' ?
                        <div className='employees_doc_uploadRow' style={{marginLeft: 0, marginTop: '0.5vw'}}>

                          <input ref={(ref) => this.uploadInput=ref} type="file" onChange={(event) => this.setState({file: event.target.files[0]})} accept={'.xls,.xlsx,.doc,.docx,.pdf,.jpg,.png'} />

                          {this.state.file !== null ?
                            <span className='planner_publishMenu_button' onClick={() => this.uploadFile()}>{Localization.t.button_upload}</span>
                          : null}
                          {this.state.file !== null ?
                            <span className='planner_publishMenu_deButton' style={{marginLeft: '1vw'}} onClick={() => { this.uploadInput.value = null; this.setState({file: null, uploadError: ''}); }}>{Localization.t.button_delete}</span>
                          : null}

                        </div>
                      : null}

                      {this.state.uploadError.length > 0 ?
                        <span className='errorText'>{this.state.uploadError}</span>
                      : null}

                    </div>

                    <div className='home2_contentContainer_info'>

                      {this.getDocumentBars()}

                    </div>

                  </div>

                </div>

              :
              this.state.menu === 'logo' ?

                <div style={{ width: 'calc(100% - 130px)', marginLeft: 65, marginTop: 30, }}>

                  <div className='home2_contentBox_info'>

                    <div className='home2_headerContainer_info'>

                      <div className={'home2_contentHeader'}>{Localization.t.home_menu_companyLogo}</div>

                      <div className='employees_doc_uploadRow' style={{marginLeft: 0, marginTop: '0.5vw'}}>

                        <input ref={(ref) => this.uploadInput=ref} type="file" onChange={(event) => this.setState({file: event.target.files[0]})} accept={'.jpg,.png,.jpeg'} />

                        {this.state.file !== null ?
                          <span className='planner_publishMenu_button' onClick={() => this.uploadFileLogo()}>{Localization.t.button_upload}</span>
                        : null}
                        {this.state.file !== null ?
                          <span className='planner_publishMenu_deButton' style={{marginLeft: '1vw'}} onClick={() => { this.uploadInput.value = null; this.setState({file: null, uploadError: ''}); }}>{Localization.t.button_delete}</span>
                        : null}

                      </div>

                      {this.state.uploadError.length > 0 ?
                        <span className='errorText'>{this.state.uploadError}</span>
                      : null}

                    </div>

                    <div className='home2_contentContainer_info'>

                      {Data.data.storeLogo !== null ?
                        <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${Data.data.storeLogo}`} className='home_logo' />
                      : null}

                      {Data.data.storeLogo !== null ?
                        <div style={{ width: '100%', display: 'flex', marginTop: '1vw' }}>
                          <span className='planner_publishMenu_deButton' onClick={() => this.deleteLogoAlert()}>{Localization.t.button_delete}</span>
                        </div>
                      : null}

                    </div>

                  </div>

                </div>

              :

                null

              }

            </div>

          }

        </div>

      </div>

    </div>
    );
  }

  // renderOLD() {

  //   const ownScheduleBars = this.getOwnScheduleBars();

  //   const editorConfiguration = {
  //     toolbar: [
  //       'heading',
  //       '|',
  //       'bold',
  //       'italic',
  //       'link',
  //       'bulletedList',
  //       'numberedList',
  //       '|',
  //       'outdent',
  //       'indent',
  //       'alignment',
  //       '|',
  //       'blockQuote',
  //       'insertTable',
  //       'undo',
  //       'redo',
  //       'fontColor',
  //       'fontBackgroundColor',
  //       'fontSize',
  //       'highlight',
  //       'horizontalLine'
  //     ],
  //     //language: 'en',
  //     table: {
  //       contentToolbar: [
  //         'tableColumn',
  //         'tableRow',
  //         'mergeTableCells'
  //       ]
  //     },
  //   };

  //   return (
  //     <div>

  //       {this.state.showStartupAlert === true ?
                
  //         <BetterAlert
  //           _closeAlert={this.closeStartupAlert.bind(this)}
  //           _title={this.state.alertTitle}
  //           _body={this.state.alertBody}
  //           _buttons={this.state.alertButtons}
  //           _buttonText={this.state.alertButtonText}
  //           _buttonAction={this.state.alertButtonAction}
  //           _buttonAction2={this.state.alertButtonAction2}
  //         />

  //       : null}

  //       {this.state.showStartupModal ?
  //         <StartupModal history={this.props.history} _closeModal={this.closeStartupModal.bind(this)} />
  //       : null}

  //       {this.state.showTutorialAlert === true ?
                
  //         <BetterAlert
  //           _closeAlert={this.closeTutorialAlert.bind(this)}
  //           _title={this.state.alertTitle}
  //           _body={this.state.alertBody}
  //           _buttons={this.state.alertButtons}
  //           _buttonText={this.state.alertButtonText}
  //           _buttonAction={this.state.alertButtonAction}
  //           _buttonAction2={this.state.alertButtonAction2}
  //         />

  //       : null}

  //       {this.state.showTutorialModal ?
  //         <TutorialModal history={this.props.history} _closeModal={this.closeTutorialModal.bind(this)} />
  //       : null}

  //       <div className='home2' style={{pointerEvents: this.state.showStartupModal ? null : null}}>

  //         {/* <Prompt
  //           when={true}
  //           message='You have unsaved changes, are you sure you want to leave?'
  //         /> */}

  //         <Navbar history={this.props.history} active={'home'} />

  //         <div className='home2_optionsBar'>

  //           {/* <div className='planner_questionButton' onClick={this.openQuestionModal.bind(this)}>
  //             <i className={'far fa-question'} />
  //             Hulp
  //           </div> */}

  //         </div>

  //         {this.state.loading === true ?
          
  //                     <div className='loader'>
          //   <div className="spinner">
          //       <div className="double-bounce1"></div>
          //       <div className="double-bounce2"></div>
          //   </div>
          // </div>

  //         :

  //           <div className='home2_container'>

  //             {this.state.reloading === true ?
                        
  //               <div className='loader2'>
          //   <div className="spinner">
          //       <div className="double-bounce1"></div>
          //       <div className="double-bounce2"></div>
          //   </div>
          // </div>

  //             :

  //               null

  //             }

  //             {this.state.showQuestionModal === true ?
                
  //               <BetterModal
  //                 _closeModal={this.closeQuestionModal.bind(this)}
                  
  //                 _header={this.state.tutorialModal === true ? 'Web-uitleg' : 'Informatie'}
  //                 _general={{
  //                     header: 'Mijn diensten',
  //                     content: (
  //                       <div>'Mijn diensten' is een handige plek waar je al jouw eigen diensten met de daarbij gewerkte uren terug kunt vinden van de afgelopen 3 maanden.</div>
  //                     ),
  //                 }}
  //                 _alineas={[
  //                     {
  //                       header: 'Ruilen',
  //                       content: (
  //                         <div>Om een dienst met iemand te ruilen klik je eerst op een dienst. Vervolgens klik je op 'Ruilen' waarna je beschikbare collega's kunt selecteren. Tot slot klik je op 'Dienst aanbieden'.<br/><br/>Je kunt een overzicht van jouw ruilverzoeken terug vinden in het menu onder 'Mijn account'.</div>
  //                       ),
  //                     }
  //                 ]}
  //                 _tutorialModal={this.state.tutorialModal}
  //                 _history={this.props.history}
  //               />

  //             :

  //               null
              
  //             }

  //             {this.state.missingModalVisible === true ?
                
  //               <MissingModal 
  //                 _closeModal={this.closeMissingModal.bind(this)}
  //                 _history={this.props.history}
  //                 _parent={this}
  //               />

  //             :

  //               null
              
  //             }

  //             {this.state.showBigModal === true ?
                
  //               <BetterBigModal 
  //                 _closeModal={this.closeBigModal.bind(this)}
  //                 _history={this.props.history}
  //                 _content={this.state.content}
  //                 _header={this.state.header}
  //                 _parent={this}
  //               />

  //             :

  //               null
              
  //             }

  //             {this.state.showAlert === true ?
                
  //               <BetterAlert
  //                 _closeAlert={this.closeAlert.bind(this)}
  //                 _title={this.state.alertTitle}
  //                 _body={this.state.alertBody}
  //                 _buttons={this.state.alertButtons}
  //                 _buttonText={this.state.alertButtonText}
  //                 _buttonAction={this.state.alertButtonAction}
  //                 _buttonAction2={this.state.alertButtonAction2}
  //               />

  //             :

  //               null
              
  //             }

  //             {this.state.showModal === true ?
                
  //               <HomeModal 
  //                 _closeModal={this.closeModal.bind(this)}
  //                 _modalData={this.state.modalData}
  //                 _modalMode={this.state.modalMode}
  //                 _reloadScreen={this.reloadScreen.bind(this)}
  //                 history={this.props.history}
  //               />

  //             :

  //               null
              
  //             }

  //             {this.state.showAddNewsModal === true ?
                
  //               <AddNewsModal 
  //                 _closeModal={this.closeAddNewsModal.bind(this)}
  //                 _users={this.state.allUsers}
  //                 _reloadScreen={this.reloadScreen.bind(this)}
  //                 history={this.props.history}
  //               />

  //             :

  //               null
              
  //             }

  //             <GridLayout 
  //               compactType={'vertical'}
  //               // verticalCompact={true}
  //               // isLeftShift={true}
  //               layout={this.state.layout} 
  //               cols={3} 
  //               rowHeight={425} 
  //               containerPadding={[65, 30]} 
  //               margin={[30, 30]}
  //               width={this.state.width} 
  //               style={{position: 'relative', cursor: this.state.isDragging ? 'grabbing' : 'default'}}
  //               isBounded={true}
  //               onDragStop={(layout) => { this.setState({ isDragging: false, layout: layout }); localStorage.setItem('@homeLayout3', JSON.stringify(this.state.layout)); }}
  //               onDrag={() => this.setState({ isDragging: true })}
  //               draggableHandle='.home2_dragIcon'
  //             >

  //               <div key="a" className='home2_contentBox'>

  //                 <i className='fas fa-ellipsis-v home2_dragIcon' />

  //                 <div className='home2_headerContainer'>

  //                   <div className={'home2_contentHeader'}>Laatste nieuws</div>

  //                   {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
  //                     <div className='home2_button_new' onClick={() => this.openAddNewsModal()}>
  //                       <span style={{fontSize: '1vw'}}>Nieuws uitzenden</span>
  //                       <i style={{fontSize: '1vw'}} className='fal fa-bullhorn' />
  //                     </div>
  //                   : null}

  //                   <div className='home2_button_new' onClick={() => this.openBigModal('news')}>
  //                     <span style={{fontSize: '1vw'}}>Alles weergeven</span>
  //                     <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
  //                   </div>

  //                 </div>

  //                 <div className='home2_contentContainer'>

  //                   {this.getNewsArticles()}

  //                 </div>

  //               </div>
                
  //               <div key='b' className='home2_contentBox'>

  //                 <i className='fas fa-ellipsis-v home2_dragIcon' />

  //                 <div className={'home2_contentHeader'} style={{paddingTop: '2vw', marginLeft: 35}}>Acties</div>

  //                 <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '0.5vw', marginLeft: 35}}>Je hebt {this.state.totalCount} {this.state.totalCount === 1 ? 'openstaande actie' : 'openstaande acties'}</div>

  //                 {Data.data.appOptions.availability === 'STANDARD' && (Data.data.accountData.account_rights === '2') ?
  //                   <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'availabilities' })}>
  //                     <span style={{fontSize: '1vw'}}>{this.state.availabilityRequestsCount > 0 ? <b>{this.state.availabilityRequestsCount}</b> : this.state.availabilityRequestsCount} openstaande {this.state.availabilityRequestsCount === 1 ? 'beschikbaarheid wijziging' : 'beschikbaarheid wijzigingen'}</span>
  //                     <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
  //                   </div>
  //                 : null}

  //                 {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
  //                   <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'trades' })}>
  //                     <span style={{fontSize: '1vw'}}>{this.state.tradeRequestsCount > 0 ? <b>{this.state.tradeRequestsCount}</b> : this.state.tradeRequestsCount} {this.state.tradeRequestsCount === 1 ? 'openstaand ruilverzoek' : 'openstaande ruilverzoeken'}</span>
  //                     <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
  //                   </div>
  //                 : null}

  //                 {Data.data.accountData.account_rights === '2' ?
  //                   <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences' })}>
  //                     <span style={{fontSize: '1vw'}}>{this.state.holidaysCount > 0 ? <b>{this.state.holidaysCount}</b> : this.state.holidaysCount} {this.state.holidaysCount === 1 ? 'openstaand verlofverzoek' : 'openstaande verlofverzoeken'}</span>
  //                     <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
  //                   </div>
  //                 : null}

  //                 {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
  //                   <div className='home2_buttonText' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'shifts' })}>
  //                     <span style={{fontSize: '1vw'}}>{this.state.replaceCount > 0 ? <b>{this.state.replaceCount}</b> : this.state.replaceCount} openstaande {this.state.replaceCount === 1 ? 'dienstovername' : 'dienstovernames'}</span>
  //                     <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
  //                   </div>
  //                 : null}

  //                 <div style={{fontSize: '1vw', fontWeight: 'bold', paddingTop: '1.5vw', marginLeft: 35}}>Snelkoppelingen</div>

  //                 {Data.data.accountData.account_rights === '2' ?
  //                   <div className='home2_buttonText2' onClick={() => this.props.history.push('/app_options')}>
  //                     <span style={{fontSize: '1vw'}}>Jouw platform configureren</span>
  //                     <i style={{fontSize: '1vw'}} className='fal fa-cogs' />
  //                   </div>
  //                 : null}

  //                 {Data.data.accountData.account_rights === '2' && Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) && Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) ?
  //                   <div className='home2_buttonText2' onClick={() => this.props.history.push('/hour_registration')}>
  //                     <span style={{fontSize: '1vw'}}>Tijdregistratie nakijken</span>
  //                     <i style={{fontSize: '1vw'}} className='fal fa-user-clock' />
  //                   </div>
  //                 : null}

  //                 {Data.data.accountData.account_rights === '2' && Constants.isTrue(Data.data.appOptions.opties_financien) ?
  //                   <div className='home2_buttonText2' onClick={() => this.props.history.push({ pathname: `/reports`, menu: 'gains' })}>
  //                     <span style={{fontSize: '1vw'}}>Omzet invullen</span>
  //                     <i style={{fontSize: '1vw'}} className='fal fa-hand-holding-usd' />
  //                   </div>
  //                 : null}

  //                 {(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') && Data.data.appOptions.availability !== 'STANDARD' ?
  //                   <div className='home2_buttonText2' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'deadlines' })}>
  //                     <span style={{fontSize: '1vw'}}>Deadlines beheren</span>
  //                     <i style={{fontSize: '1vw'}} className='fal fa-stopwatch' />
  //                   </div>
  //                 : null}

  //                 {Data.data.accountData.account_rights === '2' && Data.data.appOptions.opties_versie !== '0' ?
  //                   <div className='home2_buttonText2' onClick={() => this.props.history.push({ pathname: `/reports`, menu: 'general', general_start: this.state.timeNow, general_end: new Date(this.state.timeNow.getFullYear(), this.state.timeNow.getMonth() + 3, this.state.timeNow.getDate()) })}>
  //                     <span style={{fontSize: '1vw'}}>Vakantieoverzicht medewerkers</span>
  //                     <i style={{fontSize: '1vw'}} className='fal fa-user-chart' />
  //                   </div>
  //                 : null}

  //               </div>

  //               <div key='c' className='home2_contentBox'>

  //                 <i className='fas fa-ellipsis-v home2_dragIcon' />

  //                 <div className='home2_headerContainer2'>

  //                   <div className={'home2_contentHeader'}>Mijn diensten</div>

  //                   <div className='home2_button_new' onClick={() => this.openBigModal('home')}>
  //                     <span style={{fontSize: '1vw'}}>Alles weergeven</span>
  //                     <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />
  //                   </div>

  //                 </div>

  //                 <div className='home2_contentContainer2'>

  //                   {ownScheduleBars}

  //                 </div>

  //               </div>





  //               <div key='d' className='home2_contentBox'>

  //                 <i className='fas fa-ellipsis-v home2_dragIcon' />

  //                 <div className={'home2_headerContainer3'}>Gebeurtenissen</div>

  //                 <div className='home2_contentContainer3'>

  //                   {this.renderEvents()}

  //                 </div>

  //               </div>

  //               <div key='e' className='home2_contentBox'>

  //                 <i className='fas fa-ellipsis-v home2_dragIcon' />

  //                 <div className={'home2_headerContainer3'}>Verzoeken {`&`} contracten</div>

  //                 <div className='home2_contentContainer3'>

  //                   {this.renderAllRequests()}

  //                 </div>

  //               </div>





  //               <div key='f' className='home2_contentBox'>

  //                 <i className='fas fa-ellipsis-v home2_dragIcon' />

  //                 <div className={'home2_headerContainer3'}>Informatie</div>

  //                 <div className='home2_contentContainer4'>

  //                 </div>

  //               </div>

  //             </GridLayout>

  //           </div>
        
  //         }

  //       </div>

  //     </div>
  //   );
  // }
  
}

export default Home2;