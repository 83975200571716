/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './startupModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';
import '../../pages/options/reports/reports.scss';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import APIGetLocations from '../../classes/APIGetLocations';
import APIGetOptions from '../../classes/APIGetOptions';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGET from '../../classes/global/APIGET';
import APIGetParticular from '../../classes/APIGetParticular';
import APIEditOptions from '../../classes/APIEditOptions';
import APIEDIT from '../../classes/global/APIEDIT';
import APIEditOptions2 from '../../classes/APIEditOptions2';
import APIEDITLOGIN from '../../classes/login/APIEDITLOGIN';
import APIDELETE from '../../classes/global/APIDELETE';
import APIADD from '../../classes/global/APIADD';
import APIGetAllContracts from '../../classes/APIGetAllContracts';
import Colors from '../../constants/Colors';
import APIGetSkills from '../../classes/APIGetSkills';
import APIGetAbsencetypes from '../../classes/APIGetAbsencetypes';
import SkillsModal from '../../pages/options/skills/skillsModal';
import AbsencetypesModal from '../../pages/options/absencetypes/absencetypesModal';
import ParticularModal from '../../pages/options/particular/particularModal';
import BonusModal from '../../pages/options/appOptions/bonusModal';
import LocationsModal from '../../pages/options/appOptions/locationsModal';
import { Tooltip } from 'react-tippy';
import APIGetSections from '../../classes/APIGetSections';
import APIGetFunctions from '../../classes/APIGetFunctions';
import FunctionsModal from '../../pages/options/functions/functionsModal';
import BreaksModal from '../../pages/options/breaks/breaksModal';
import SectionModal from '../../pages/options/sections/sectionsModal';
import Shifts from '../../pages/options/shifts/shifts';

import ReactDatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import APIGetAccountInfo from '../../classes/APIGetAccountInfo';
import APIEditAccountInfo from '../../classes/APIEditAccountInfo';
import APIEditAccount from '../../classes/APIEditAccount';

import CryptoJS from 'crypto-js';
import LeaveBalanceModal from '../../pages/options/appOptions/leaveBalanceModal';
import LeaveTypeModal from '../../pages/options/appOptions/leaveTypeModal';
import Localization from '../../constants/Localization';
import __ from '../../constants/__';
import ___ from '../../constants/___';
import RegistrationUserModal from '../registrationUserModal/registrationUserModal';


class StartupModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.wentBack = false;
    this.initUserData = Data.data.accountData;

    this.timeRef = {};
    this.lastDeleted = null;
    this.lastVal = '';

    this.maxFuncID = 900005;
    this.industries = {
      ['RESTAURANT']: [ // industries.RESTAURANT
        { // industries.RESTAURANT[0]
          functie_id: 900000,
          functie_naam: ___.startup_job_washingUp,
          functie_kleur: 'EF44B3',
          functie_afdeling_id: 'NULL',
        },
        { // industries.RESTAURANT[1]
          functie_id: 900001,
          functie_naam: ___.startup_job_service,
          functie_kleur: 'F29A23',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900002,
          functie_naam: ___.startup_job_bar,
          functie_kleur: 'FDEE35',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900003,
          functie_naam: ___.startup_job_cook,
          functie_kleur: '47B7EB',
          functie_afdeling_id: 'NULL',
        },
        // {
        //   functie_id: 900004,
        //   functie_naam: ___.startup_job_drinks,
        //   functie_kleur: '3BF2C6',
        //   functie_afdeling_id: 'NULL',
        // },
        // {
        //   functie_id: 900005,
        //   functie_naam: ___.startup_job_food,
        //   functie_kleur: '83F23B',
        //   functie_afdeling_id: 'NULL',
        // },
      ],
      ['BAR']: [ // industries.BAR
        { // industries.BAR[0]
          functie_id: 900000,
          functie_naam: ___.startup_job_kitchen,
          functie_kleur: 'EF44B3',
          functie_afdeling_id: 'NULL',
        },
        { // industries.BAR[1]
          functie_id: 900001,
          functie_naam: ___.startup_job_service,
          functie_kleur: 'F29A23',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900002,
          functie_naam: ___.startup_job_bar,
          functie_kleur: 'FDEE35',
          functie_afdeling_id: 'NULL',
        },
      ],
      ['HOTEL']: [
        {
          functie_id: 900000,
          functie_naam: ___.startup_job_washingUp,
          functie_kleur: 'EF44B3',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900001,
          functie_naam: ___.startup_job_reception,
          functie_kleur: 'F29A23',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900002,
          functie_naam: ___.startup_job_cook,
          functie_kleur: 'FDEE35',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900003,
          functie_naam: ___.startup_job_service,
          functie_kleur: '47B7EB',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900004,
          functie_naam: ___.startup_job_maintenance,
          functie_kleur: '3BF2C6',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900005,
          functie_naam: ___.startup_job_housekeeping,
          functie_kleur: '83F23B',
          functie_afdeling_id: 'NULL',
        },
      ],
      ['DELIVERY']: [
        {
          functie_id: 900000,
          functie_naam: ___.startup_job_cash,
          functie_kleur: 'EF44B3',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900001,
          functie_naam: ___.startup_job_kitchen,
          functie_kleur: 'F29A23',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900002,
          functie_naam: ___.startup_job_car,
          functie_kleur: 'FDEE35',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900003,
          functie_naam: ___.startup_job_bicycle,
          functie_kleur: '47B7EB',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900004,
          functie_naam: ___.startup_job_scooter,
          functie_kleur: '3BF2C6',
          functie_afdeling_id: 'NULL',
        },
        {
          functie_id: 900005,
          functie_naam: ___.startup_job_washingUp,
          functie_kleur: '83F23B',
          functie_afdeling_id: 'NULL',
        },
      ],
    };
    
    this.state = {

        loading: true,
        reloading: false,

        steps: [],
        curStep: 'company',

        menu: 'company',
        subMenu: 'general',
        selectedTeam: null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        // app options
        clockSystem: Data.data.appOptions.clock,
        breakOption: Data.data.appOptions.brk,
        hoursRegister: Data.data.appOptions.hours,
        availability: Data.data.appOptions.availability,
        open: Data.data.appOptions.open,
        iban: Data.data.appOptions.iban,
        bsn: Data.data.appOptions.bsn,
  
        opties_smartplan: Data.data.appOptions.opties_smartplan === '1',
        opties_financien: Data.data.appOptions.opties_financien === '1',
        opties_urenregistratie2: Data.data.appOptions.opties_urenregistratie2 === '1',
        opties_klokPlanning: Data.data.appOptions.opties_klokPlanning === '1',
        opties_klokAfronden: Data.data.appOptions.opties_klokAfronden,
        opties_pauzeAfronden: Data.data.appOptions.opties_pauzeAfronden,
        opties_urenEigen: Data.data.appOptions.opties_urenEigen === '1',
        opties_onbpd: Data.data.appOptions.opties_onbpd,
        opties_onbpw: Data.data.appOptions.opties_onbpw,
        opties_ruilenComp: Data.data.appOptions.opties_ruilenComp === '1',
        opties_ruilenGeenFunctie: Data.data.appOptions.opties_ruilenGeenFunctie === '1',
        opties_verlofDeadline: Data.data.appOptions.opties_verlofDeadline,
        opties_ruilenFunctie: Data.data.appOptions.opties_ruilenFunctie === '1',
        opties_planBeschikbaar: Data.data.appOptions.opties_planBeschikbaar === '1',
        opties_planFunctieSort: Data.data.appOptions.opties_planFunctieSort === '1',
        opties_contractVerlopen: Data.data.appOptions.opties_contractVerlopen === '1',
        opties_klokMobiel: Data.data.appOptions.opties_klokMobiel === '1',
        opties_fteUren: Data.data.appOptions.opties_fteUren,
        opties_loonfactor: Data.data.appOptions.opties_loonfactor,
        opties_BTW: Data.data.appOptions.opties_BTW,
        opties_omzetBTW: Data.data.appOptions.opties_omzetBTW === '1',
        opties_loonPercentage: Data.data.appOptions.opties_loonPercentage,
        opties_chat: Data.data.appOptions.opties_chat === '1',
        opties_ruilen_vantevoren: Data.data.appOptions.opties_ruilen_vantevoren,
        opties_feestdagen: Data.data.appOptions.opties_feestdagen === '1',
        opties_eindtijd_weergeven: Data.data.appOptions.opties_eindtijd_weergeven === '1',
        opties_opendienst_beschikbaar: Data.data.appOptions.opties_opendienst_beschikbaar === '1',
        opties_infoplanner_verborgen: Data.data.appOptions.opties_infoplanner_verborgen === '1',
        opties_uren_daadwerkelijk: Data.data.appOptions.opties_uren_daadwerkelijk === '1',
        opties_overuren: Data.data.appOptions.opties_overuren === '1',
        opties_verlof_limiet: Data.data.appOptions.opties_verlof_limiet,
        opties_planner_drukte: Data.data.appOptions.opties_planner_drukte === '1',
        opties_planner_loonkosten: Data.data.appOptions.opties_planner_loonkosten === '1',
        opties_planner_vorigOmzet: Data.data.appOptions.opties_planner_vorigOmzet === '1',
        opties_planner_verwachteOmzet: Data.data.appOptions.opties_planner_verwachteOmzet === '1',
        opties_planner_omzet: Data.data.appOptions.opties_planner_omzet === '1',
        opties_planner_productiviteit: Data.data.appOptions.opties_planner_productiviteit === '1',
        opties_planner_berekeningen: Data.data.appOptions.opties_planner_berekeningen === '1',
        opties_planner_extraDiensten: Data.data.appOptions.opties_planner_extraDiensten === '1',
        opties_planner_contracturen: Data.data.appOptions.opties_planner_contracturen === '1',
        opties_onbpm: Data.data.appOptions.opties_onbpm,
        opties_verlofsaldo: Data.data.appOptions.opties_verlofsaldo === '1',
        opties_versie: Data.data.appOptions.opties_versie,
  
        hoursRegisterDisabled: !Data.data.appOptions.clock,
  
        showModal: false,
        modalMode: 0,
        modalData: [],
  
        showPartModal: false,
        showAbsModal: false,
        showSkillsModal: false,
        showBonusModal: false,
        showLeaveBalanceModal: false,
        showLeaveTypeModal: false,
  
        showAlert: false,
  
        hasLocation: true,
  
        missingModalVisible: false,

        newOptions: [],
  
        workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
        workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
        workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
        workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
        workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
        workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
        workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',
  
        dailyWorkHours: Data.data.appOptions.opties_dag_werkuren,
  
        accounts: [],
        infoExtra: {},
  
        salaries: {},
        salariesArray: [],
        emptySalaries: [],
  
        newSalaries: {},
        salaryModels: [],
        selectedSalaryModel: null,
        newSalaryModel: '',
  
        chosenTeam: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeam : null,
        chosenTeamObj: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj : 
        {
          bedrijf_naam: Data.data.storeName,
          bedrijf_adres: Data.data.storeAddress,
          bedrijf_postcode: Data.data.storeZip,
          bedrijf_plaats: Data.data.storeCity,
          bedrijf_telefoon: Data.data.storePhone,
          bedrijf_land: Data.data.storeCountry,
          bedrijf_email: Data.data.storeMail,
          bedrijf_kvk: Data.data.storeKvK,
        },
  
        breakRules: [],
        emptyBreakRules: [],
  
        holidayPeriods: [],
        emptyHolidayPeriods: [],
  
        particularities: [],
        absenceTypes: [],
        skills: [],
  
        exclusions: [],
  
        bonus: [],

        leaveBalances: [],
        leaveTypes: [],
        leaveTypeBalances: {},

        // my company
        showSectionsModal: false,
        showFunctionsModal: false,
        showShiftsModal: false,
        showBreaksModal: false,
        showDeadlinesModal: false,
        showBlockedModal: false,

        sections: [],
        functions: [],
        breaks: [],
        deadlines: [],
        blocked: [],

        password1: '',
        password2: '',

        userData: Data.data.accountData,

        defaultWorkdays: {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        },

        chosenJob: null,

        selectedIndustry: 'NULL',

        employeeBars: 2,
        showRegistrationUserModal: false,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    const location = await APIGetLocations.Request();

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    const accounts = await APIGetAllAccounts.Request();

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);

    const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    const leaveTypes = await APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, `ORDER BY vt_naam`);
    const leaveTypeBalances = await APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, null);
    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vt_id');

    let _defaultWorkdays = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      0: '',
    };

    const defaultWorkdays = await APIGET.Request(`SELECT *`, `FROM Openingstijden`, null, null, null, null);

    for (const _wd of defaultWorkdays) {
      _defaultWorkdays[parseInt(_wd.ot_dag)] = `${_wd.ot_begin.substr(0,5)} - ${_wd.ot_eind.substr(0,5)}`;
    }

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    const sections = await APIGetSections.Request();
    const functions = await APIGetFunctions.Request();
    const breaks = await APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`);

    this.setState({
      clockSystem: options[0].opties_inklok === '1' ? true : false,
      breakOption: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
      hoursRegister: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
      availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
      open: options[0].opties_opendienst === '1' ? true : false,
      iban: options[0].opties_iban === '1' ? true : false,
      bsn: options[0].opties_bsn === '1' ? true : false,

      opties_smartplan: options[0].opties_smartplan === '1',
      opties_financien: options[0].opties_financien === '1',
      opties_urenregistratie2: options[0].opties_urenregistratie2 === '1',
      opties_klokPlanning: options[0].opties_klokPlanning === '1',
      opties_klokAfronden: options[0].opties_klokAfronden,
      opties_pauzeAfronden: options[0].opties_pauzeAfronden,
      opties_urenEigen: options[0].opties_urenEigen === '1',
      opties_onbpd: options[0].opties_onbpd,
      opties_onbpw: options[0].opties_onbpw,
      opties_ruilenComp: options[0].opties_ruilenComp === '1',
      opties_ruilenGeenFunctie: options[0].opties_ruilenGeenFunctie === '1',
      opties_verlofDeadline: options[0].opties_verlofDeadline,
      opties_ruilenFunctie: options[0].opties_ruilenFunctie === '1',
      opties_planBeschikbaar: options[0].opties_planBeschikbaar === '1',
      opties_planFunctieSort: options[0].opties_planFunctieSort === '1',
      opties_contractVerlopen: options[0].opties_contractVerlopen === '1',
      opties_klokMobiel: options[0].opties_klokMobiel === '1',
      opties_fteUren: options[0].opties_fteUren,
      opties_loonfactor: options[0].opties_loonfactor,
      opties_BTW: options[0].opties_BTW,
      opties_omzetBTW: options[0].opties_omzetBTW === '1',
      opties_loonPercentage: options[0].opties_loonPercentage,
      opties_chat: options[0].opties_chat === '1',
      opties_ruilen_vantevoren: options[0].opties_ruilen_vantevoren,
      opties_feestdagen: options[0].opties_feestdagen === '1',
      opties_eindtijd_weergeven: options[0].opties_eindtijd_weergeven === '1',
      opties_opendienst_beschikbaar: options[0].opties_opendienst_beschikbaar === '1',
      opties_infoplanner_verborgen: options[0].opties_infoplanner_verborgen === '1',
      opties_uren_daadwerkelijk: options[0].opties_uren_daadwerkelijk === '1',
      opties_overuren: options[0].opties_overuren === '1',
      opties_verlof_limiet: options[0].opties_verlof_limiet,
      opties_verlofsaldo: options[0].opties_verlofsaldo === '1',
      opties_versie: options[0].opties_versie,

      hasLocation: location !== null,

      accounts: accounts,
      infoExtra: infoExtraObj,

      salaries: salariesObj, 
      salariesArray: salaries,

      salaryModels: salaryModels,
      selectedSalaryModel: salaryModels.length > 0 ? salaryModels[0].ut_id : null,
      newSalaries: newSalariesObj,

      sections: sections,
      functions: functions,
      breaks: breaks,

      leaveBalances: leaveBalances,
      leaveTypes: leaveTypes,
      leaveTypeBalances: leaveTypeBalancesObj,

      defaultWorkdays: _defaultWorkdays,

    }, () => {

      this.calcSteps();
      this.forceUpdate();

    });

    this.setState({loading: false});

  }

  calcSteps() {

    let steps = {};
    
    // steps['general'] = typeof this.state.steps.general !== 'undefined' ? this.state.steps.general : false;
    // steps['days'] = typeof this.state.steps.days !== 'undefined' ? this.state.steps.days : false;
    // if (Constants.isTrue(Data.data.appOptions.opties_financien)) {
    //   steps['salaries'] = typeof this.state.steps.salaries !== 'undefined' ? this.state.steps.salaries : false;
    // }
    // steps['teams'] = typeof this.state.steps.teams !== 'undefined' ? this.state.steps.teams : false;
    // steps['availability'] = typeof this.state.steps.availability !== 'undefined' ? this.state.steps.availability : false;
    // steps['planner'] = typeof this.state.steps.planner !== 'undefined' ? this.state.steps.planner : false;

    // if (Constants.isTrue(Data.data.appOptions.opties_urenregistratie2)) {
    //   steps['hours'] = typeof this.state.steps.hours !== 'undefined' ? this.state.steps.hours : false;
    // }

    // if (Constants.isTrue(Data.data.appOptions.clock)) {
    //   steps['clock'] = typeof this.state.steps.clock !== 'undefined' ? this.state.steps.clock : false;
    // }

    // if (Constants.isTrue(Data.data.appOptions.opties_financien)) {
    //   steps['finances'] = typeof this.state.steps.finances !== 'undefined' ? this.state.steps.finances : false;
    // }

    // steps['trade'] = typeof this.state.steps.trade !== 'undefined' ? this.state.steps.trade : false;
    // steps['absence'] = typeof this.state.steps.absence !== 'undefined' ? this.state.steps.absence : false;

    // if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {
    //   steps['leavebalances'] = typeof this.state.steps.leavebalances !== 'undefined' ? this.state.steps.leavebalances : false;
    //   steps['leavetypes'] = typeof this.state.steps.leavetypes !== 'undefined' ? this.state.steps.leavetypes : false;
    // }

    // steps['register'] = typeof this.state.steps.register !== 'undefined' ? this.state.steps.register : false;

    // my company
    steps['company'] = typeof this.state.steps.company !== 'undefined' ? this.state.steps.company : false;
    steps['data'] = typeof this.state.steps.data !== 'undefined' ? this.state.steps.data : false;
    steps['functions'] = typeof this.state.steps.functions !== 'undefined' ? this.state.steps.functions : false;
    steps['employees'] = typeof this.state.steps.employees !== 'undefined' ? this.state.steps.employees : false;
    // steps['sections'] = typeof this.state.steps.sections !== 'undefined' ? this.state.steps.sections : false;
    // steps['_breaks'] = typeof this.state.steps._breaks !== 'undefined' ? this.state.steps._breaks : false;
    // steps['shifts'] = typeof this.state.steps.shifts !== 'undefined' ? this.state.steps.shifts : false;

    // my account
    // steps['password'] = typeof this.state.steps.password !== 'undefined' ? this.state.steps.password : false;
    // steps['job'] = typeof this.state.steps.job !== 'undefined' ? this.state.steps.job : false;
    steps['done'] = typeof this.state.steps.done !== 'undefined' ? this.state.steps.done : false;

    this.setState({ steps: steps });

  }

  async nextStep() {

    if (await this.checkValidations() === false) {
      return;
    }

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex += 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = true;

    if (curStep === 'general' || (curStep === 'days' && Data.data.appOptions.opties_versie !== '0')) {
      this.selectSubMenu(nextKey);
    } else {
      this.selectMenu(nextKey);
    }

    this.setState({ steps: steps, curStep: nextKey });

  }

  prevStep() {

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    if (curStep === 'teams' || (curStep === 'salaries' && Data.data.appOptions.opties_versie !== '0') || curStep === 'days') {
      this.selectMenu('general');
      this.selectSubMenu(nextKey);
    } else {
      this.selectMenu(nextKey);
    }

    this.setState({ steps: steps, curStep: nextKey });

  }

  async reloadScreen2() {

    this.setState({loading: true, showError: false });

    const location = await APIGetLocations.Request();

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    this.setState({
      clockSystem: options[0].opties_inklok === '1' ? true : false,
      breakOption: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
      hoursRegister: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
      availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
      open: options[0].opties_opendienst === '1' ? true : false,
      iban: options[0].opties_iban === '1' ? true : false,
      bsn: options[0].opties_bsn === '1' ? true : false,
      hasLocation: location !== null,
    });

    this.setState({loading: false});

  }

  toggleSwitchClockFALSE() {

    this.setState({ 
        clockSystem: false,
        breakOption: 'PLANNER',
        hoursRegister: 'PLANNER',
        hoursRegisterDisabled: true,
    }, 
      () => this.editOptions(false)
    );

}

    toggleSwitchClock() {

          this.setState({ 
              clockSystem: !this.state.clockSystem,
              breakOption: this.state.breakOption === 'CLOCK' ? 'PLANNER' : this.state.breakOption,
              hoursRegister: this.state.clockSystem === true ? 'PLANNER' : 'CLOCK',
              hoursRegisterDisabled: this.state.clockSystem,
          }, () => {

            this.editNewOptions('opties_uren_daadwerkelijk', this.state.clockSystem);
            this.editOptions(false);

          });

    }

    toggleSwitchBreakSchedule() {

            this.setState({ 
                breakOption: this.state.breakOption === 'PLANNER' ? 'PLANNER' : 'PLANNER',
            }, 
              () => this.editOptions(false)
            );

    }

    toggleSwitchBreakClock() {

            this.setState({ 
                breakOption: this.state.breakOption === 'CLOCK' ? 'PLANNER' : 'CLOCK',
            }, 
              () => this.editOptions(false)
            );

    }

    toggleSwitchBreakScheduleCalc() {

            this.setState({ 
                breakOption: this.state.breakOption === 'CALC' ? 'PLANNER' : 'CALC',
            }, 
              () => this.editOptions(false)
            );

    }

    toggleSwitchHours() {

            this.setState({ 
                hoursRegister: this.state.hoursRegister === 'PLANNER' ? 'CLOCK' : 'PLANNER',
                breakOption: this.state.hoursRegister === 'CLOCK' && this.state.breakOption === 'CLOCK' ? 'PLANNER' : this.state.breakOption,
            }, () => {
              this.editOptions(false);
              this.editNewOptions('opties_uren_daadwerkelijk', this.state.hoursRegister === 'CLOCK' ? true : this.state.opties_uren_daadwerkelijk);
            });

    }

    toggleSwitchAvailability() {

            this.setState({ 
                availability: this.state.availability === 'AVAILABILITY' ? 'NONE' : 'AVAILABILITY',
            }, 
              () => this.editOptions(false)
            );

    }

    toggleSwitchSchool() {

        this.setState({ 
            availability: this.state.availability === 'SCHOOL' ? 'NONE' : 'SCHOOL',
        }, 
          () => this.editOptions(false)
        );

    }

    toggleSwitchStandard() {

      this.setState({ 
          availability: this.state.availability === 'STANDARD' ? 'NONE' : 'STANDARD',
      }, 
        () => this.editOptions(false)
      );

  }

    toggleSwitchUnavailability() {

      this.setState({ 
          availability: this.state.availability === 'UNAVAILABILITY' ? 'NONE' : 'UNAVAILABILITY',
      }, 
        () => this.editOptions(false)
      );

    }

    toggleSwitchOpen() {

      this.setState({ 
          open: !this.state.open,
      }, 
        () => this.editOptions(false)
      );

    }

    toggleSwitchIban() {

      this.setState({ 
          iban: !this.state.iban,
      }, 
        () => this.editOptions(false)
      );

    }

    toggleSwitchBsn() {

      this.setState({ 
          bsn: !this.state.bsn,
      }, 
        () => this.editOptions(false)
      );

    }

  toggleSwitchAvailabilityOff() {

          this.setState({ 
              availability: this.state.availability === 'NONE' ? 'AVAILABILITY' : 'NONE',
          }, 
            () => this.editOptions(false)
          );

  }

  async editOptions(reset) {

    // if((this.state.availability !== Data.data.appOptions.availability) && reset === false) {
    //   this.deleteAlert();
    //   return;
    // }

    this.setState({buttonLoading: true});

    const added = await APIEditOptions.Request(
      this.state.clockSystem === true ? 1 : 0,
      this.state.breakOption === 'PLANNER' ? 0 : this.state.breakOption === 'CLOCK' && this.state.clockSystem === true ? 1 : this.state.breakOption === 'CALC' ? 2 : 0,
      this.state.hoursRegister === 'PLANNER' ? 0 : 1,
      this.state.availability === 'AVAILABILITY' ? 0 : this.state.availability === 'SCHOOL' ? 1 : this.state.availability === 'UNAVAILABILITY' ? 2 : this.state.availability === 'STANDARD' ? 3 : 4,
      this.state.open === true ? 1 : 0,
      this.state.iban === true ? 1 : 0,
      this.state.bsn === true ? 1 : 0,
      Data.data.appOptions.id
    );

    // if(reset === true) {
    //   await APIDeleteAvailabilities.Request();
    // }

    this.setState({buttonLoading: false});

    if(added === true) {

      // Constants.defaultAlert(this, 'Melding', 'Opties zijn gewijzigd');

      // setTimeout(() => {
      //   this.props.history.push("/");
      // }, 1000);

      // return;

      Data.data.appOptions = {
        ...Data.data.appOptions,
        id: Data.data.appOptions.id,
        clock: this.state.clockSystem,
        brk: this.state.breakOption,
        hours: this.state.hoursRegister,
        availability: this.state.availability,
        open: this.state.open,
        iban: this.state.iban,
        bsn: this.state.bsn,
      };

    } else {

      

      this.setState({loading: false});

    }

    this.calcSteps();

  }

  openLocationsModal() {

    this.setState({
      modalData: null,
      modalMode: 0,
      showModal: true,
    });

  }

   async editNewOptions(set, value, bool = true) {

     await APIEditOptions2.Request(set, bool ? value === true ? '1' : '0' : value, Data.data.appOptions.id);

     Data.data.appOptions = {
      ...Data.data.appOptions,
      [set]: bool ? value === true ? '1' : '0' : value,
    };

    this.setState({
      [set]: value,
    }, async () => {

      if (set === 'opties_versie' && value === '0') {

        await Promise.all([
          this.editNewOptions('opties_urenregistratie2', false),
          this.editNewOptions('opties_uren_daadwerkelijk', false),
          this.editNewOptions('opties_verlofsaldo', false),
          this.editNewOptions('opties_financien', false),
          this.toggleSwitchClockFALSE(),
          this.editNewOptions('opties_planner_loonkosten', false),
          this.editNewOptions('opties_planner_vorigOmzet', false),
          this.editNewOptions('opties_planner_verwachteOmzet', false),
          this.editNewOptions('opties_planner_omzet', false),
          this.editNewOptions('opties_planner_productiviteit', false),
          this.editNewOptions('opties_planner_berekeningen', false),
          this.editNewOptions('opties_overuren', false),
        ]);

      }

      if (set === 'opties_versie' && value === '1') {

        await Promise.all([
          //this.editNewOptions('opties_urenregistratie2', false),
          //this.editNewOptions('opties_uren_daadwerkelijk', false),
          this.editNewOptions('opties_verlofsaldo', false),
          this.editNewOptions('opties_financien', false),
          this.toggleSwitchClockFALSE(),
          this.editNewOptions('opties_planner_loonkosten', false),
          this.editNewOptions('opties_planner_vorigOmzet', false),
          this.editNewOptions('opties_planner_verwachteOmzet', false),
          this.editNewOptions('opties_planner_omzet', false),
          this.editNewOptions('opties_planner_productiviteit', false),
          this.editNewOptions('opties_planner_berekeningen', false),
          this.editNewOptions('opties_overuren', false),
        ]);

      }

      if (set === 'opties_urenregistratie2' && value === false) {

        await Promise.all([
          this.editNewOptions('opties_financien', false),
          this.toggleSwitchClockFALSE(),
        ]);

      }

      if (set === 'opties_financien' && value === false) {

        await Promise.all([
          this.editNewOptions('opties_planner_loonkosten', false),
          this.editNewOptions('opties_planner_vorigOmzet', false),
          this.editNewOptions('opties_planner_verwachteOmzet', false),
          this.editNewOptions('opties_planner_omzet', false),
          this.editNewOptions('opties_planner_productiviteit', false),
          this.editNewOptions('opties_planner_berekeningen', false),
        ]);

      }

      if (set === 'opties_uren_daadwerkelijk' && value === false) {

        await Promise.all([
          this.editNewOptions('opties_overuren', false),
        ]);

      }

      this.calcSteps();

    });

  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 10) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  onChangeTime(e, i2, fromOther, option) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.state.timesStandard;

    if (option === 'availability') {
      times = this.state.timesStandard;
    } else if (option === 'default_workdays') {
      times = this.state.defaultWorkdays;
    }

    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times });
      } else if (option === 'default_workdays') {
        this.setState({ defaultWorkdays: times });
      }

      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
          APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
        } else {
          APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
        }

        this.setState({ defaultWorkdays: times });

      }

      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          times[i2] = e.target.value;

          if (option === 'availability') {
            this.setState({ timesStandard: times, specialChanges: true });
          } else if (option === 'default_workdays') {

            const start = e.target.value.substr(0, 5);
            const end = e.target.value.substr(8, 13);

            // check if start and end are correct
            if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
              APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
            } else {
              APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
            }

            this.setState({ defaultWorkdays: times });

          }

          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times, specialChanges: true });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
          APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
        } else {
          APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
        }

        this.setState({ defaultWorkdays: times });

      }

    } else {
      e.target.value = '';
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times, specialChanges: true });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
          APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
        } else {
          APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
        }

        this.setState({ defaultWorkdays: times });

      }

    }

  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async onChangeNumberData(data, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (data === 'day') {
      if (nr === '') {
        this.editNewOptions('opties_onbpd', '0', false);
      } else {
        this.editNewOptions('opties_onbpd', parseInt(nr).toString(), false);
      }
    } else if (data === 'week') {
      if (nr === '') {
        this.editNewOptions('opties_onbpw', '0', false);
      } else {
        this.editNewOptions('opties_onbpw', parseInt(nr).toString(), false);
      }
    } else if (data === 'month') {
      if (nr === '') {
        this.editNewOptions('opties_onbpm', '0', false);
      } else {
        this.editNewOptions('opties_onbpm', parseInt(nr).toString(), false);
      }
    } else if (data === 'vacation') {
      if (nr === '') {
        this.editNewOptions('opties_verlofDeadline', '0', false);
      } else {
        this.editNewOptions('opties_verlofDeadline', parseInt(nr).toString(), false);
      }
    } else if (data === 'vacationLimit') {
      if (nr === '') {
        this.editNewOptions('opties_verlof_limiet', '0', false);
      } else {
        this.editNewOptions('opties_verlof_limiet', parseInt(nr).toString(), false);
      }
    } else if (data === 'tradeDays') {
      if (nr === '') {
        this.editNewOptions('opties_ruilen_vantevoren', '0', false);
      } else {
        this.editNewOptions('opties_ruilen_vantevoren', parseInt(nr).toString(), false);
      }
    } else if (data === 'roundClock') {
      if (nr === '') {
        this.editNewOptions('opties_klokAfronden', '0', false);
      } else {
        this.editNewOptions('opties_klokAfronden', parseInt(nr).toString(), false);
      }
    } else if (data === 'roundBreaks') {
      if (nr === '') {
        this.editNewOptions('opties_pauzeAfronden', '0', false);
      } else {
        this.editNewOptions('opties_pauzeAfronden', parseInt(nr).toString(), false);
      }
    } else if (data === 'dailyWorkHours') {
      if (nr === '') {
        this.setState({dailyWorkHours: '0'});
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_dag_werkuren = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_dag_werkuren = '0';
      } else {
        this.setState({dailyWorkHours: Constants.round(parseFloat(nr)).toString()});
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_dag_werkuren = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_dag_werkuren = Constants.round(parseFloat(nr)).toString();
      }
    } else if (data === 'fte') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_fteUren = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_fteUren = '0';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_fteUren = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_fteUren = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_fteUren: nr});
    } else if (data === 'btw') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_BTW = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_BTW = '0';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_BTW = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_BTW = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_BTW: nr});
    } else if (data === 'salaryFactor') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonfactor = 1`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonfactor = '1';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonfactor = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonfactor = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_loonfactor: nr});
    } else if (data === 'salaryPercent') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonPercentage = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonPercentage = '0';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonPercentage = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonPercentage = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_loonPercentage: nr});
    }
  }

  onChangeNumberDataFloat(data, min, max, event, key, filled) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (data === 'salary') {

      if (filled === true) {

        let salaries = this.state.newSalaries;

        if (nr !== '') {
          salaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid = nr;
        } else {
          salaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid = '';
        }

        this.setState({ newSalaries: salaries });

      } else { // key = index of array here

        let salaries = this.state.emptySalaries;

        if (nr !== '') {
          salaries[key].amount = nr;
        } else {
          salaries[key].amount = '';
        }

        this.setState({ emptySalaries: salaries });

      }

    } else if (data === 'breakHours') {

      if (filled === true) {

        let breakRules = this.state.breakRules;

        if (nr !== '') {
          breakRules[key].pr_uren = nr;
        } else {
          breakRules[key].pr_uren = '';
        }

        this.setState({ breakRules: breakRules });

      } else { // key = index of array here

        let breakRules = this.state.emptyBreakRules;

        if (nr !== '') {
          breakRules[key].pr_uren = nr;
        } else {
          breakRules[key].pr_uren = '';
        }

        this.setState({ emptyBreakRules: breakRules });

      }

    }

  }

  onChangeNumberDataInt(data, min, max, event, key, filled) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (data === 'age') {

      if (filled === true) {

        let salaries = this.state.newSalaries;

        if (nr !== '') {
          salaries[this.state.selectedSalaryModel][key].uurloon_leeftijd = parseInt(nr).toString();
        } else {
          salaries[this.state.selectedSalaryModel][key].uurloon_leeftijd = '';
        }

        this.setState({ newSalaries: salaries });

      } else { // key = index of array here

        let salaries = this.state.emptySalaries;

        if (nr !== '') {
          salaries[key].age = parseInt(nr).toString();
        } else {
          salaries[key].age = '';
        }

        this.setState({ emptySalaries: salaries });

      }

    } else if (data === 'breakMinutes') {

      if (filled === true) {

        let breakRules = this.state.breakRules;

        if (nr !== '') {
          breakRules[key].pr_pauze = parseInt(nr).toString();
        } else {
          breakRules[key].pr_pauze = '';
        }

        this.setState({ breakRules: breakRules });

      } else { // key = index of array here

        let breakRules = this.state.emptyBreakRules;

        if (nr !== '') {
          breakRules[key].pr_pauze = parseInt(nr).toString();
        } else {
          breakRules[key].pr_pauze = '';
        }

        this.setState({ emptyBreakRules: breakRules });

      }

    }

  }

  onChangeTextData(data, event) {

    if (data === 'username') {
        this.setState({ userData: { ...this.state.userData, account_username: event.target.value }, showError: false, });
    } else if (data === 'firstname') {
        this.setState({ userData: { ...this.state.userData, info_voornaam: event.target.value }, showError: false, });
    } else if (data === 'insertion') {
        this.setState({ userData: { ...this.state.userData, info_tussenvoegsel: event.target.value }, showError: false, });
    } else if (data === 'lastname') {
        this.setState({ userData: { ...this.state.userData, info_achternaam: event.target.value }, showError: false, });
    } else if (data === 'iban') {
        this.setState({ userData: { ...this.state.userData, iban: event.target.value }, showError: false, });
    } else if (data === 'bsn') {
        this.setState({ userData: { ...this.state.userData, bsn: event.target.value }, showError: false, });
    } else if (data === 'email') {
        this.setState({ userData: { ...this.state.userData, info_email: event.target.value }, showError: false, });
    } else if (data === 'phone') {
        this.setState({ userData: { ...this.state.userData, info_telefoon: event.target.value }, showError: false, });
    } else if (data === 'phone2') {
        this.setState({ userData: { ...this.state.userData, info_telefoon2: event.target.value }, showError: false, });
    } else if (data === 'address') {
        this.setState({ userData: { ...this.state.userData, info_adres: event.target.value }, showError: false, });
    } else if (data === 'house') {
        this.setState({ userData: { ...this.state.userData, info_huisnummer: event.target.value }, showError: false, });
    } else if (data === 'zip') {
        this.setState({ userData: { ...this.state.userData, info_postcode: event.target.value }, showError: false, });
    } else if (data === 'city') {
        this.setState({ userData: { ...this.state.userData, info_plaats: event.target.value }, showError: false, });
    } else if (data === 'birthplace') {
        this.setState({ userData: { ...this.state.userData, info_geboorteplaats: event.target.value }, showError: false, });
    } else if (data === 'extra') {
        this.setState({ userData: { ...this.state.userData, info_extra: event.target.value }, showError: false, });
    }

  }

  onChangeText(data, event) {

    if (data === 'pw1') {
        this.setState({ password1: event.target.value, showError: false, });
    } else if (data === 'pw2') {
        this.setState({ password2: event.target.value, showError: false, });
    }

  }

  setBirthDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

      const dateObject = date;

      const dateText = Constants.dateToString(date);

      this.setState({
          userData: { ...this.state.userData, info_geboorte: dateText },
          showError: false,
      });

  }

  setStartDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
    }

      const dateObject = date;

      const dateText = Constants.dateToString(date);

      this.setState({
          userData: { ...this.state.userData, info_indienst: dateText },
          showError: false,
      });

  }

  selectMenu(menu) {

    let subMenu = null;

    if (menu === 'general') {
      subMenu = 'general';
    }

    this.setState({
      menu: menu,
      subMenu: subMenu,
    });

  }

  selectMenuManual(menu) {

    let subMenu = null;

    if (menu === 'general') {
      subMenu = 'general';
    }

    if (this.wentBack === true) {
      this.setState({
        menu: menu,
        subMenu: subMenu,
      });
      return;
    }

    this.wentBack = true;

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    this.setState({
      menu: menu,
      subMenu: subMenu,
      curStep: nextKey,
      steps: steps,
    });

  }

  selectSubMenu(menu) {

    this.setState({
      subMenu: menu,
    });

  }

  selectSubMenuManual(menu) {

    if (this.wentBack === true) {
      this.setState({
        subMenu: menu,
      });
      return;
    }

    this.wentBack = true;

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    this.setState({
      subMenu: menu,
      curStep: nextKey,
      steps: steps,
    });

  }

  async setWorkDay(day) {

    if (day === 'mon') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_maandag = ${this.state.workWeekMonday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_maandag = this.state.workWeekMonday === false ? '1' : '0';
      this.setState({ workWeekMonday: !this.state.workWeekMonday});
    } else if (day === 'tue') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_dinsdag = ${this.state.workWeekTuesday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_dinsdag = this.state.workWeekTuesday === false ? '1' : '0';
      this.setState({ workWeekTuesday: !this.state.workWeekTuesday});
    } else if (day === 'wed') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_woensdag = ${this.state.workWeekWednesday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_woensdag = this.state.workWeekWednesday === false ? '1' : '0';
      this.setState({ workWeekWednesday: !this.state.workWeekWednesday});
    } else if (day === 'thu') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_donderdag = ${this.state.workWeekThursday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_donderdag = this.state.workWeekThursday === false ? '1' : '0';
      this.setState({ workWeekThursday: !this.state.workWeekThursday});
    } else if (day === 'fri') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_vrijdag = ${this.state.workWeekFriday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_vrijdag = this.state.workWeekFriday === false ? '1' : '0';
      this.setState({ workWeekFriday: !this.state.workWeekFriday});
    } else if (day === 'sat') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_zaterdag = ${this.state.workWeekSaturday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_zaterdag = this.state.workWeekSaturday === false ? '1' : '0';
      this.setState({ workWeekSaturday: !this.state.workWeekSaturday});
    } else if (day === 'sun') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_zondag = ${this.state.workWeekSunday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_zondag = this.state.workWeekSunday === false ? '1' : '0';
      this.setState({ workWeekSunday: !this.state.workWeekSunday});
    }

  }

  async setEveryoneToWorkDays() {

    this.setState({
      reloading: true,
    });

    let promises = [];

    for (const acc of this.state.accounts) {

      if (typeof this.state.infoExtra[acc.info_id] === 'undefined') {
        promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag, info2_werkweek)`, `VALUES (NULL, ${acc.info_id}, ${this.state.workWeekMonday === true ? '1' : '0'}, ${this.state.workWeekTuesday === true ? '1' : '0'}, ${this.state.workWeekWednesday === true ? '1' : '0'}, ${this.state.workWeekThursday === true ? '1' : '0'}, ${this.state.workWeekFriday === true ? '1' : '0'}, ${this.state.workWeekSaturday === true ? '1' : '0'}, ${this.state.workWeekSunday === true ? '1' : '0'}, 1)`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${this.state.workWeekMonday === true ? '1' : '0'}, info2_werkdag_dinsdag = ${this.state.workWeekTuesday === true ? '1' : '0'}, info2_werkdag_woensdag = ${this.state.workWeekWednesday === true ? '1' : '0'}, info2_werkdag_donderdag = ${this.state.workWeekThursday === true ? '1' : '0'}, info2_werkdag_vrijdag = ${this.state.workWeekFriday === true ? '1' : '0'}, info2_werkdag_zaterdag = ${this.state.workWeekSaturday === true ? '1' : '0'}, info2_werkdag_zondag = ${this.state.workWeekSunday === true ? '1' : '0'}, info2_werkweek = 1`, `WHERE info2_info_id = ${acc.info_id}`));
      }

    }

    await Promise.all(promises);

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({
      infoExtra: infoExtraObj,
      reloading: false,
    });

  }

  renderSalaries() {

    let toRender = [];

    if (this.state.newSalaries[this.state.selectedSalaryModel].length > 0) {

      toRender.push(

        <div className='salaries_salaryContainer' style={{marginLeft: '1%', marginBottom: '0.3vw', marginTop: '1vw'}}>

          <span style={{fontsize: '0.8vw'}}>Leeftijd</span>

          <span style={{fontsize: '0.8vw'}}>Uurloon</span>

        </div>

      );

    }

    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {

      const s = this.state.newSalaries[this.state.selectedSalaryModel][key];

      toRender.push(

        <div key={s.uurloon_id} className='salaries_salaryContainer' style={{marginLeft: '1%'}}>

          <input 
            className={'inputField2'}
            min={1}
            max={200}
            value={s.uurloon_leeftijd}
            onChange={(event) => this.onChangeNumberDataInt('age', 1, 200, event, key, true)}
            placeholder={'Leeftijd'}
            type={'number'}
            step={1}
            style={{marginTop: 0, width: '15%', fontsize: '0.8vw'}}
          />

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

            <i className='salaries_delButton fas fa-minus-circle' style={{marginRight: '1vw', fontSize: '0.8vw'}} onClick={() => this.deleteAlertSalary(s)} />

            <input 
              className={'inputField2'}
              min={0}
              max={999999999}
              value={s.uurloon_hoeveelheid}
              onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event, key, true)}
              placeholder={'Uurloon'}
              type={'number'}
              step={0.01}
              style={{marginTop: 0, width: '100%', fontsize: '0.8vw'}}
            />
          
          </div>

        </div>

      );

    }

    return toRender;

  }

  renderEmptySalaries() {

    let toRender = [];

    if (this.state.emptySalaries.length > 0) {

      toRender.push(

        <div className='salaries_salaryContainer' style={{marginBottom: '0.3vw', marginTop: '1vw'}}>

          <span style={{fontsize: '0.8vw'}}>Leeftijd</span>

          <span style={{fontsize: '0.8vw'}}>Uurloon</span>

        </div>

      );

    }

    for (let index in this.state.emptySalaries) {

      const e = this.state.emptySalaries[index];

      toRender.push(

        <div key={index + 'e'} className='salaries_salaryContainer'>

          <input 
            className={'inputField2'}
            min={1}
            max={200}
            value={e.age}
            onChange={(event) => this.onChangeNumberDataInt('age', 1, 200, event, index, false)}
            placeholder={'Leeftijd'}
            type={'number'}
            step={1}
            style={{marginTop: 0, width: '15%', fontsize: '0.8vw'}}
          />

          <input 
            className={'inputField2'}
            min={0}
            max={999999999}
            value={e.amount}
            onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event, index, false)}
            placeholder={'Uurloon'}
            type={'number'}
            step={0.01}
            style={{marginTop: 0, width: '35%', fontsize: '0.8vw'}}
          />

        </div>

      );

    }

    return toRender;

  }

  addRow() {

    let emptySalaries = this.state.emptySalaries;
    emptySalaries.push({age: '', amount: ''});

    this.setState({ emptySalaries: emptySalaries });

  }

  async saveSalaries() {

    if (this.checkValidationsSalary() === false) {
      return;
    }

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {
      if ((isNaN(parseInt(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)}, ${this.state.selectedSalaryModel})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], newSalaries: newSalariesObj,  reloading: false });

  }

  async applySalary() {

    if (this.checkValidationsSalary() === false) {
      return;
    }

    // SAVE THE CURRENT VALUES

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {
      if ((isNaN(parseInt(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)}, ${this.state.selectedSalaryModel})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    // DONE RELOADING AND SAVING

    const [
      accounts, 
      contracts, 
      infoExtra,
      dateNow,
    ] = await Promise.all(
    [
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      Constants.getDateNow(),
    ]);

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    let contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    // promises
    let promisesSalary = [];

    for (const acc of accounts) {

      if (typeof infoExtraObj[acc.info_id] === 'undefined' || infoExtraObj[acc.info_id].info2_ut_id === this.state.selectedSalaryModel) {

        const age = Constants.getAge(dateNow, Constants.stringToDate(acc.info_geboorte));
        let salary = 0;
        let foundSalary = false;
        for (const s of newSalariesObj[this.state.selectedSalaryModel]) {
          if (parseInt(s.uurloon_leeftijd) === age) {
            salary = parseFloat(s.uurloon_hoeveelheid);
            foundSalary = true;
            break;
          }
        }

        if (foundSalary === false) {
          if (age < parseInt(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_leeftijd)) {
            salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_hoeveelheid);
          } else if (age > parseInt(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd)) {
            salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid);
          }
        }

        const contract = Constants.getCurrentContract(contractsObj[acc.info_id], dateNow);

        if (contract === null) {
          //promisesSalary.push(APIADD.Request(`INSERT INTO ContractN (contract_info_id, contract_uurloon)`, `VALUES (${acc.info_id}, ${salary})`));
        } else {
          promisesSalary.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${salary}`, `WHERE contract_id = ${contract.contract_id}`));
        }

        if (typeof infoExtraObj[acc.info_id] === 'undefined') {
          promisesSalary.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_ut_id)`, `VALUES (NULL, ${acc.info_id}, ${this.state.selectedSalaryModel})`));
        }

      } else {
        continue;
      }

    }

    await Promise.all(promisesSalary);

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], newSalaries: newSalariesObj, reloading: false });

  }

  async resetSalary() {

    if (this.checkValidationsSalary() === false) {
      return;
    }

    // SAVE THE CURRENT VALUES

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {
      if ((isNaN(parseInt(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)}, ${this.state.selectedSalaryModel})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    // DONE RELOADING AND SAVING

    const [
      accounts, 
      contracts, 
      infoExtra,
      dateNow,
    ] = await Promise.all(
    [
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      Constants.getDateNow(),
    ]);

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    let contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    // promises
    let promisesSalary = [];

    for (const acc of accounts) {

      const age = Constants.getAge(dateNow, Constants.stringToDate(acc.info_geboorte));
      let salary = 0;
      let foundSalary = false;
      for (const s of newSalariesObj[this.state.selectedSalaryModel]) {
        if (parseInt(s.uurloon_leeftijd) === age) {
          salary = parseFloat(s.uurloon_hoeveelheid);
          foundSalary = true;
          break;
        }
      }

      if (foundSalary === false) {
        if (age < parseInt(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_leeftijd)) {
          salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_hoeveelheid);
        } else if (age > parseInt(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd)) {
          salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid);
        }
      }

      const contract = Constants.getCurrentContract(contractsObj[acc.info_id], dateNow);

      if (contract === null) {
        //promisesSalary.push(APIADD.Request(`INSERT INTO ContractN (contract_info_id, contract_uurloon)`, `VALUES (${acc.info_id}, ${salary})`));
      } else {
        promisesSalary.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${salary}`, `WHERE contract_id = ${contract.contract_id}`));
      }

      if (typeof infoExtraObj[acc.info_id] === 'undefined') {
        promisesSalary.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_ut_id)`, `VALUES (NULL, ${acc.info_id}, ${this.state.selectedSalaryModel})`));
      } else {
        promisesSalary.push(APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = ${this.state.selectedSalaryModel}`, `WHERE info2_info_id = ${acc.info_id}`));
      }

    }

    await Promise.all(promisesSalary);

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], newSalaries: newSalariesObj, reloading: false });

  }

  async deleteSalary(data) {

    this.setState({reloading: true});

    await APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${data.uurloon_id}`);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({salaries: salariesObj, salariesArray: salaries, newSalaries: newSalariesObj, reloading: false, showError: false });

  }

  checkValidationsSalary() {

    let newSalaries2 = [];
    let newEmptySalaries = [];

    // first check salaries
    for (const s of this.state.newSalaries[this.state.selectedSalaryModel]) {

      if (Constants.objectArrayContainsKey(newSalaries2, 'uurloon_leeftijd', s.uurloon_leeftijd) === false) {
        newSalaries2.push(s);
      } else {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${s.uurloon_leeftijd} jaar is al toegevoegd`
        });
        return false;
      }

    }

    // then check emptysalaries duplications
    for (let key in this.state.emptySalaries) {

      if (Constants.objectArrayContainsKey(newEmptySalaries, 'age', this.state.emptySalaries[key].age) === false) {
        newEmptySalaries.push(this.state.emptySalaries[key]);
      } else {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.emptySalaries[key].age} jaar kan niet 2x toegevoegd worden`
        });
        return false;
      }

    }

    // lastly check salaries and empty
    for (let key in this.state.emptySalaries) {

      if (Constants.objectArrayContainsKey(newSalaries2, 'uurloon_leeftijd', this.state.emptySalaries[key].age) === true) {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.emptySalaries[key].age} jaar bestaat al`
        });
        return false;
      }

    }

    return true;

  }

  getSalaryModelBars() {

    let models = [];

    for(const model of this.state.salaryModels) {

        models.push(
            <option value={model.ut_id} selected={model.ut_id === this.state.selectedSalaryModel}>{model.ut_naam}</option>
        );

    }

    return models;

  }

  selectSalaryModel(e) {

    this.setState({
      selectedSalaryModel: e.target.value === 'null' ? null : e.target.value,
      showError: false,
    });

  }

  async saveSalaryModel() {

    if (this.state.newSalaryModel === '') {
      this.setState({
        errorText: 'Je hebt geen naam ingevoerd',
        showError: true,
      });
      return;
    }

    this.setState({ loading: true });

    await APIADD.Request(`INSERT INTO UurloonTabel`, `VALUES (NULL, '${this.state.newSalaryModel}')`);

    const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);
    const selectedModel = salaryModels.length > 0 ? salaryModels[salaryModels.length - 1].ut_id : null;

    let newSalariesObj = this.state.newSalaries;

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({
      salaryModels: salaryModels,
      selectedSalaryModel: selectedModel,
      newSalaries: newSalariesObj,
      loading: false
    });

  }

  applyWorkWeekAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je deze werkdagen wilt toepassen op alle medewerkers? Hiermee worden de huidige werkdagen van medewerkers overschreven door de werkdagen die hier zijn aangegeven

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.setEveryoneToWorkDays.bind(this),
      showAlert: true,
    });

  }

  applySalariesAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je de toegevoegde uurlonen wilt toepassen op medewerkers met dit loonmodel? Hiermee worden de huidige uurlonen van medewerkers overschreven door de bedragen in het loonmodel

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.applySalary.bind(this),
      showAlert: true,
    });

  }

  resetSalariesAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je dit loonmodel wilt toepassen op alle medewerkers? Hiermee worden de huidige uurlonen van medewerkers overschreven door de bedragen in het loonmodel

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.resetSalary.bind(this),
      showAlert: true,
    });

  }

  deleteAlertSalary(data) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je het uurloon van {data.uurloon_leeftijd} jaar wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteSalary.bind(this, data),
      showAlert: true,
    });

  }

  deleteSalaryModelAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je dit loonmodel wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteSalaryModel.bind(this),
      showAlert: true,
    });

  }

  async deleteSalaryModel() {

    this.setState({ loading: true });

    await APIDELETE.Request(`DELETE FROM UurloonTabel`, `WHERE ut_id = ${this.state.selectedSalaryModel}`);

    const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);
    const selectedModel = salaryModels.length > 0 ? salaryModels[0].ut_id : null;

    let newSalariesObj = this.state.newSalaries;

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({
      salaryModels: salaryModels,
      selectedSalaryModel: selectedModel,
      newSalaries: newSalariesObj,
      loading: false
    });

  }

  getTeamButtonsMenu() {

    let toReturn = [];

    for (const team of Data.data.teams) {

      toReturn.push(

        <div key={team.team_id} className={`employees_menuButton ${this.state.chosenTeam === parseInt(team.team_id) ? 'activeAv' : ''}`} onClick={() => this.selectTeam(team)}>
          <div>{team.team_naam}</div>
        </div>

      );

    }

    return toReturn;

  }

  selectTeam(team) {

    this.setState({
      chosenTeam: parseInt(team.team_id),
      chosenTeamObj: team,
    });

  }

  async changeTeamData(e, key) {

    let chosenTeamObj = this.state.chosenTeamObj;

    chosenTeamObj[key] = e.target.value;
    Data.data.chosenTeamObj[key] = e.target.value;

    for (let team of Data.data.teams) {
      if (parseInt(team.team_id) === this.state.chosenTeam) {
        team[key] = e.target.value;
      }
    }

    for (let team of Data.data.ownTeams) {
      if (parseInt(team.team_id) === this.state.chosenTeam) {
        team[key] = e.target.value;
      }
    }

    APIEDIT.Request(`UPDATE Team`, `SET ${key} = '${e.target.value}'`, `WHERE team_id = ${this.state.chosenTeam}`);

    this.setState({ chosenTeamObj: chosenTeamObj });

  }

  async changeCompanyData(e, key) {
    
    let chosenTeamObj = this.state.chosenTeamObj;
    chosenTeamObj[key] = e.target.value;

    if (key === 'bedrijf_naam') {
      Data.data.storeName = e.target.value;
    } else if (key === 'bedrijf_adres') {
      Data.data.storeAddress = e.target.value;
    } else if (key === 'bedrijf_postcode') {
      Data.data.storeZip = e.target.value;
    } else if (key === 'bedrijf_plaats') {
      Data.data.storeCity = e.target.value;
    } else if (key === 'bedrijf_telefoon') {
      Data.data.storePhone = e.target.value;
    } else if (key === 'bedrijf_email') {
      Data.data.storeMail = e.target.value;
    } else if (key === 'bedrijf_kvk') {
      Data.data.storeKvK = e.target.value;
    } else if (key === 'bedrijf_land') {
      Data.data.storeCountry = e.target.value;
    }

    APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET ${key} = '${e.target.value}'`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

    this.setState({ chosenTeamObj: chosenTeamObj });

  }

  selectDate(date, array, key, key2) {

    let arr = this.state[array];

    if (typeof arr[key] !== 'undefined') {
      arr[key][key2] = Constants.dateToString(date);
    }

    this.setState({ [array]: arr });

  }

  openBonusModal(data) {

    this.setState({
      showBonusModal: true, 
      modalData: data,
    });

  }

  openPartModal(mode, data) {

    this.setState({
      showPartModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  openAbsModal(mode, data) {

    this.setState({
      showAbsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  openSkillsModal(mode, data) {

    this.setState({
      showSkillsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  openLeaveBalanceModal(mode, data) {

    this.setState({
      showLeaveBalanceModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : null,
    });

  }

  openLeaveTypeModal(mode, data) {

    this.setState({
      showLeaveTypeModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : null,
    });

  }

  async reloadBonus() {

    this.setState({reloading: true});

    const bonus = await APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, `ORDER BY t_factor DESC`);

    this.setState({
      reloading: false, 
      bonus: bonus
    });

  }

  async reloadPart() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetParticular.Request();

    this.setState({
      reloading: false, 
      particularities: bijzonderheden
    });

  }

  async reloadAbs() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetAbsencetypes.Request();

    this.setState({
      reloading: false, 
      absenceTypes: bijzonderheden
    });

  }

  async reloadSkills() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetSkills.Request();

    this.setState({
      reloading: false, 
      skills: bijzonderheden
    });

  }

  getBonusBars() {

    let toRender = [];
    let count = 0;

    for (const bonus of this.state.bonus) {

      const date = Constants.isEmpty(bonus.t_datum) === false ? Constants.stringToDate(bonus.t_datum) : null;
      const bonusFactor = Constants.round(parseFloat(bonus.t_factor) * 100);

      let bonusTitle = ``;
      if (date !== null) {
        bonusTitle = `${date.getDate()} ${Constants.getMonthName(date)}`;
      } else if (Constants.isEmpty(bonus.t_dag) === false) {
        if (bonus.t_dag === '1') {
          bonusTitle = `Maandag`;
        } else if (bonus.t_dag === '2') {
          bonusTitle = `Dinsdag`;
        } else if (bonus.t_dag === '3') {
          bonusTitle = `Woensdag`;
        } else if (bonus.t_dag === '4') {
          bonusTitle = `Donderdag`;
        } else if (bonus.t_dag === '5') {
          bonusTitle = `Vrijdag`;
        } else if (bonus.t_dag === '6') {
          bonusTitle = `Zaterdag`;
        } else if (bonus.t_dag === '0') {
          bonusTitle = `Zondag`;
        }
      } else if (Constants.isEmpty(bonus.t_elkedag) === false) {
        if (bonus.t_elkedag === '1') {
          bonusTitle = `Elke dag`;
        }
      }

      toRender.push(

        <div key={bonus.t_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.openBonusModal(bonus)}>

          <div className='hr_hoursBar_sub1'>
            <span>{bonusTitle}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{bonus.t_begin.substr(0, 5)} - {bonus.t_eind.substr(0, 5)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{bonusFactor}%</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getPartBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.particularities) {

      toRender.push(

        <div key={part.bijz_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.openPartModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.bijz_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3' style={{color: `#${part.bijz_kleur}`}}>
            {part.bijz_afkorting}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getAbsBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.absenceTypes) {

      toRender.push(

        <div key={part.afw_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.openAbsModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.afw_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {part.afw_afkorting}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getSkillsBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.skills) {

      toRender.push(

        <div key={part.comp_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.openSkillsModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.comp_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {part.comp_afkorting}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async changeExclusion(checked, id) {

    if (checked === true) { // delete
      await APIDELETE.Request(`DELETE FROM Uitzondering`, `WHERE uit_afw_id = ${id}`);
    } else {
      await APIADD.Request(`INSERT INTO Uitzondering`, `VALUES (NULL, ${id})`);
    }

    const exclusions = await APIGET.Request(`SELECT *`, `FROM Uitzondering`, null, null, null, null);
    this.setState({ exclusions: exclusions });

  }

  getExclusionBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.absenceTypes) {

      const checked = Constants.objectArrayContainsKey(this.state.exclusions, 'uit_afw_id', part.afw_id);

      toRender.push(

        <div key={part.afw_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.changeExclusion(checked, part.afw_id)}>

          <div className='hr_hoursBar_sub1'>

            {checked === true ?
              <i className='fas fa-check-square' style={{fontSize: '0.8vw', marginRight: '0.6vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
            :
              <i className='fal fa-square' style={{fontSize: '0.8vw', marginRight: '0.6vw'}} />
            }

            <span>{part.afw_naam}</span>

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getSectionBars() {

    let toRender = [];
    let count = 0;

    for (const afd of this.state.sections) {

      toRender.push(

        <div key={`section${afd.afdeling_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => this.showSectionsModal(1, afd)}>

          <div className='hr_hoursBar_sub1'>
            <span>{afd.afdeling_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getFunctionBars() {

    let toRender = [];
    let count = 0;

    for (const func of this.state.functions) {

      if (parseInt(func.functie_id) >= 900000 && parseInt(func.functie_id) <= this.maxFuncID) {
        continue;
      }

      toRender.push(

        <div key={`function${func.functie_id}`} className='hr_hoursBar' style={{backgroundColor: Constants.hexToRgbA(`#${func.functie_kleur}`, 0.15), cursor: 'pointer'}}>

          <div className='hr_hoursBar_sub1' onClick={() => this.showFunctionsModal(1, func)}>
            <span>{func.functie_naam}</span>
          </div>

          <div className='hr_hoursBar_sub4' style={{ flex: 0.1 }} onClick={() => this.showFunctionsModal(1, func)}>
            <i className='far fa-edit' />
          </div>

          <div className='hr_hoursBar_sub4' style={{ flex: 0.1 }}>
            <i className='fas fa-minus-circle' style={{ color: Colors.color.redFilledIn }} onClick={() => this.deleteFunctionAlert(func)} />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getFunctionAutoBars() {

    let toRender = [];
    let count = 0;

    for (const func of this.state.functions) {

      if (parseInt(func.functie_id) < 900000 || parseInt(func.functie_id) > this.maxFuncID) {
        continue;
      }

      toRender.push(

        <div key={`function${func.functie_id}`} className='hr_hoursBar' style={{backgroundColor: Constants.hexToRgbA(`#${func.functie_kleur}`, 0.15), cursor: 'pointer', marginTop: count === 0 ? '0.5vw' : 0}}>

          <div className='hr_hoursBar_sub1' onClick={() => this.showFunctionsModal(1, func)}>
            <span>{func.functie_naam}</span>
          </div>

          <div className='hr_hoursBar_sub4' style={{ flex: 0.1 }} onClick={() => this.showFunctionsModal(1, func)}>
            <i className='far fa-edit' />
          </div>

          <div className='hr_hoursBar_sub4' style={{ flex: 0.1 }}>
            <i className='fas fa-minus-circle' style={{ color: Colors.color.redFilledIn }} onClick={() => this.deleteFunctionAlert(func)} />
          </div>

        </div>

      );

      count++;

    }

    return toRender;

  }

  getProfileBars() {

    let toRender = [];
    let count = 0;

    for (const key in this.industries) {

      let name = key;

      if (key === 'RESTAURANT') {
        name = ___.startup_profile_restaurant;
      } else if (key === 'BAR') {
        name = ___.startup_profile_bar;
      } else if (key === 'HOTEL') {
        name = ___.startup_profile_hotel;
      } else if (key === 'DELIVERY') {
        name = ___.startup_profile_delivery;
      }

      toRender.push(

        <div key={`profile${key}`} className='hr_hoursBar' style={{cursor: 'pointer', marginTop: count === 0 ? '0.5vw' : 0}} onClick={() => this.selectIndustry(key)}>

          {this.state.selectedIndustry !== key ?
            <div className='hr_hoursBar_sub5' style={{ flex: 0.1 }}>
              <i className='fal fa-circle' />
            </div>
          :
            <div className='hr_hoursBar_sub5' style={{ flex: 0.1 }}>
              <i className='fas fa-circle-check' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} />
            </div>
          }

          <div className='hr_hoursBar_sub1'>
            <span>{name}</span>
          </div>

        </div>

      );

      count++;

    }

    return toRender;

  }

  async selectIndustry(key) {

    this.setState({ reloading: true });

    await APIDELETE.Request(`DELETE FROM Functie`, `WHERE functie_id BETWEEN 900000 AND ${this.maxFuncID}`);

    if (this.state.selectedIndustry !== key) {
      
      let promisesADD = [];

      for (const job of this.industries[key]) {
        promisesADD.push(APIADD.Request(`INSERT INTO Functie`, `VALUES (${job.functie_id}, '${job.functie_naam}', '${job.functie_kleur}', ${job.functie_afdeling_id})`));
      }

      await Promise.all(promisesADD);

    }

    const jobs = await APIGetFunctions.Request();

    this.setState({ reloading: false, functions: jobs, selectedIndustry: this.state.selectedIndustry !== key ? key : 'NULL' });

  }

  getEmployeeAddBars() {

    let toRender = [];
    let skip = 0;
    let filled = 0;

    for (let i = 0; i < this.state.employeeBars; i++) {

      let acc = this.state.accounts[i + skip];

      if (Constants.isEmpty(acc) === true) {
        acc = null;
      }

      if (acc !== null && acc.info_id === Data.data.accountData.info_id) {
        acc = this.state.accounts[i + skip + 1];
        skip += 1;
      }

      if (Constants.isEmpty(acc) === true) {
        acc = null;
      }

      if (acc !== null) {

        filled++;

        const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

        toRender.push(

          <div key={`employees${i}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: i === 0 ? '2vw' : '0.3vw', color: Colors.color.ORIGINAL_MAINCOLOR_1 }}>

            <div style={{ display: 'flex', flex: 0.3 }}>
              <i className='far fa-user-check' />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 10 }}>
              <span>{userFullName}</span>
              {/* <p style={{ marginBlock: 0, marginLeft: '1vw' }}><i className='far fa-angle-right' /></p> */}
            </div>

          </div>

        );

      } else {

        toRender.push(

          <div key={`employees${i}`} className={filled > 1 ? 'defaultLinkR' : 'defaultLinkRB'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: i === 0 ? '1vw' : '0.3vw' }} onClick={() => this.setState({ showRegistrationUserModal: true })}>

            <div style={{ display: 'flex', flex: 0.3 }}>
              <i className='far fa-user-plus' />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 10 }}>
              <span>{___.startup_addEmployee_link}</span>
              <p style={{ marginBlock: 0, marginLeft: '1vw' }}><i className='far fa-angle-right' /></p>
            </div>

          </div>

        );

      }

    }

    return toRender;

  }



  getBreakBars() {

    let toRender = [];
    let count = 0;

    for (const brk of this.state.breaks) {

      toRender.push(

        <div key={`break${brk.brk_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => this.showBreaksModal(1, brk)}>

          <div className='hr_hoursBar_sub1'>
            <span>{brk.brk_tijd.substr(0, 5)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async reloadScreenCompany() {

    this.setState({ reloading: true, showError: false });

    const sections = await APIGetSections.Request();
    const functions = await APIGetFunctions.Request();
    const breaks = await APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`);
  
    this.setState({
      sections: sections,
      functions: functions,
      breaks: breaks,

      reloading: false,
    });

  }

  async reloadLeave() {

    this.setState({reloading: true});

    const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    const leaveTypes = await APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, `ORDER BY vt_naam`);
    const leaveTypeBalances = await APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, null);
    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vt_id');

    // re-check contracts
    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractLeaveBalance = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalanceObj = Constants.convertToObjectWithArrays(contractLeaveBalance, 'cvb_contract_id');

    let promises = [];
    for (const contract of contracts) {

      if (leaveBalances.length > 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

        if (typeof contractLeaveBalanceObj[contract.contract_id] === 'undefined') {

          for (const leave of leaveBalances) {
            promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
          }

        } else {

          for (const leave of leaveBalances) {
            
            if (Constants.objectArrayContainsKey(contractLeaveBalanceObj[contract.contract_id], 'cvb_vb_id', leave.vb_id) === false) {
              promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
            }

          }

        }

      }

    }

    await Promise.all(promises);

    this.setState({
      reloading: false, 
      leaveBalances: leaveBalances,
      leaveTypes: leaveTypes,
      leaveTypeBalances: leaveTypeBalancesObj,
    });

  }
  
  showBreaksModal(mode, data) {

    this.setState({
      showBreaksModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showSectionsModal(mode, data) {

    this.setState({
      showSectionsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showFunctionsModal(mode, data) {

    this.setState({
      showFunctionsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: Localization.t.alert_delete_title,
        alertBody: Localization.t.alert_delete,
        alertButtons: 2,
        alertButtonText: [Localization.t.button_cancel, Localization.t.button_delete],
        alertButtonAction: this.deleteTimes.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async checkEmailValid() {

      if (this.state.userData.info_email !== '') {

          if (this.initUserData.info_email === this.state.userData.info_email) {
            return true;
          }

          const data = await APIGET.Request(`SELECT info_id`, `FROM Info`, null, `WHERE info_email = '${this.state.userData.info_email}'`, null, null);

          if (data.length > 0) {
              return false;
          }

      }

      return true;

  }

  async checkValidations() {

    if (this.state.menu === 'functions') {

      if(this.state.functions.length < 2) {
        this.setState({
          showError: true,
          errorText: Localization.t.error_notAdded_functions,
        });
        return false;
      }

    } else if (this.state.menu === 'employees') {

      if((this.state.accounts.length - 1) < 2) {
        this.setState({
          showError: true,
          errorText: Localization.t.error_notAdded_employees,
        });
        return false;
      }

    } else if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

      if (this.state.menu === 'leavebalances') {

        if(this.state.leaveBalances.length === 0) {
          this.setState({
            showError: true,
            errorText: Localization.t.error_notAdded_leaveBalances,
          });
          return false;
        }

      } else if (this.state.menu === 'leavetypes') {

        if(this.state.leaveTypes.length === 0) {
          this.setState({
            showError: true,
            errorText: Localization.t.error_notAdded_leaveTypes,
          });
          return false;
        }

      }

    } else if (this.state.menu === 'password') {

      if(this.state.password1 === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notAdded_password,
          });
          return false;
      }

      if(this.state.password1 !== '' && this.state.password2 === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notAdded_passwordConfirm,
          });
          return false;
      }

      if((this.state.password1 !== '' && this.state.password2 !== '') && this.state.password1 !== this.state.password2) {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notSame_password,
          });
          return false;
      }

    } if (this.state.menu === 'data') {

      if(this.state.userData.info_voornaam === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notFilled_firstName,
          });
          return false;
      }
      if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_voornaam) === false) {
          this.setState({
              showError: true,
              errorText: Localization.t.error_invalid_firstName,
          });
          return false;
      }
      if(this.state.userData.info_tussenvoegsel !== '' && /^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_tussenvoegsel) === false) {
          this.setState({
              showError: true,
              errorText: Localization.t.error_invalid_insertion,
          });
          return false;
      }
      if(this.state.userData.info_achternaam === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notFilled_lastName,
          });
          return false;
      }
      if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_achternaam) === false) {
          this.setState({
              showError: true,
              errorText: Localization.t.error_invalid_lastName,
          });
          return false;
      }
      // if(this.state.userData.info_geboorte === null || this.state.userData.info_geboorte === '') {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_notFilled_birth,
      //     });
      //     return false;
      // }
      // if(this.state.userData.info_geboorteplaats === null || this.state.userData.info_geboorteplaats === '') {
      //   this.setState({
      //       showError: true,
      //       errorText: Localization.t.error_notFilled_birthPlace,
      //   });
      //   return false;
      // }
      if(this.state.userData.info_email === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notFilled_email,
          });
          return false;
      }
      const emailValid = await this.checkEmailValid();
      if (emailValid === false) {
        this.setState({
            showError: true,
            errorText: Localization.t.error_duplicate_email,
        });
        return false;
      }
      // eslint-disable-next-line no-useless-escape
      if(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.userData.info_email) === false) {
          this.setState({
              showError: true,
              errorText: Localization.t.error_invalid_email,
          });
          return false;
      }
      // if(this.state.userData.info_telefoon === '') {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_notFilled_phone,
      //     });
      //     return false;
      // }
      // // eslint-disable-next-line no-useless-escape
      // if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.userData.info_telefoon) === false) {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_invalid_phone,
      //     });
      //     return false;
      // }
      // // eslint-disable-next-line no-useless-escape
      // if((this.state.userData.info_telefoon2 !== '' && this.state.userData.info_telefoon2 !== null) && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.userData.info_telefoon2) === false) {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_invalid_phone2,
      //     });
      //     return false;
      // }

      // if(this.state.userData.info_adres === '') {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_notFilled_address,
      //     });
      //     return false;
      // }
      // if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_adres) === false) {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_invalid_address,
      //     });
      //     return false;
      // }
      // if(this.state.userData.info_huisnummer === '') {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_notFilled_addressHouse,
      //     });
      //     return false;
      // }
      // if(/^[a-zA-Z0-9\u00C0-\u017F\s-()]*$/.test(this.state.userData.info_huisnummer) === false) {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_invalid_addressHouse,
      //     });
      //     return false;
      // }
      // if(this.state.userData.info_postcode === '') {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_notFilled_zip,
      //     });
      //     return false;
      // }
      // if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_postcode) === false) {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_invalid_zip,
      //     });
      //     return false;
      // }
      // if(this.state.userData.info_plaats === '') {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_notFilled_place,
      //     });
      //     return false;
      // }
      // if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_plaats) === false) {
      //     this.setState({
      //         showError: true,
      //         errorText: Localization.t.error_invalid_place,
      //     });
      //     return false;
      // }

      if(this.state.password1 === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notAdded_password,
          });
          return false;
      }

      if(this.state.password1 !== '' && this.state.password2 === '') {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notAdded_passwordConfirm,
          });
          return false;
      }

      if((this.state.password1 !== '' && this.state.password2 !== '') && this.state.password1 !== this.state.password2) {
          this.setState({
              showError: true,
              errorText: Localization.t.error_notSame_password,
          });
          return false;
      }

    }

    return true;

  }

  async finishStartup() {

    this.setState({ loading: true });

    let skipAccount = false;
    let accountAdded = false;

    if(this.initUserData.account_username === this.state.userData.account_username
        && parseInt(this.initUserData.account_rights) === this.state.rights
        && this.state.password2 === '') {

            skipAccount = true;

    } else {

        if(this.state.password2 === '') {
            accountAdded = await APIEditAccount.Request(
                this.initUserData.account_username,
                null,
                parseInt(this.initUserData.account_rights),
                parseInt(this.initUserData.account_id)
            );
        } else {
            accountAdded = await APIEditAccount.Request(
                this.initUserData.account_username,
                CryptoJS.SHA512(this.state.password2).toString(),
                parseInt(this.initUserData.account_rights),
                parseInt(this.initUserData.account_id)
            );
        }

    }

    if(accountAdded === true || skipAccount === true) {

        let accountInfoAdded = await APIEditAccountInfo.Request(
            this.initUserData.account_username === this.state.userData.account_username ? this.initUserData.account_username : this.state.userData.account_username,
            parseInt(this.initUserData.account_id),
            this.initUserData.info_voornaam === this.state.userData.info_voornaam ? this.initUserData.info_voornaam : this.state.userData.info_voornaam,
            this.initUserData.info_tussenvoegsel === this.state.userData.info_tussenvoegsel ? this.initUserData.info_tussenvoegsel : this.state.userData.info_tussenvoegsel,
            this.initUserData.info_achternaam === this.state.userData.info_achternaam ? this.initUserData.info_achternaam : this.state.userData.info_achternaam,
            this.initUserData.info_telefoon === this.state.userData.info_telefoon ? this.initUserData.info_telefoon : this.state.userData.info_telefoon,
            this.initUserData.info_email === this.state.userData.info_email ? this.initUserData.info_email : this.state.userData.info_email,
            this.initUserData.functie_id === this.state.userData.functie_id ? this.initUserData.functie_id : this.state.userData.functie_id,
            this.initUserData.info_extra,
            this.initUserData.info_adres === this.state.userData.info_adres ? this.initUserData.info_adres : this.state.userData.info_adres,
            this.initUserData.info_huisnummer === this.state.userData.info_huisnummer ? this.initUserData.info_huisnummer : this.state.userData.info_huisnummer,
            this.initUserData.info_postcode === this.state.userData.info_postcode ? this.initUserData.info_postcode : this.state.userData.info_postcode,
            this.initUserData.info_geboorte === this.state.userData.info_geboorte ? this.initUserData.info_geboorte : this.state.userData.info_geboorte,
            this.initUserData.info_indienst === this.state.userData.info_indienst ? this.initUserData.info_indienst : this.initUserData.info_indienst === '0000-00-00' || this.initUserData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.userData.info_indienst,
            this.initUserData.info_ibnr,
            this.initUserData.info_idnr,
            this.initUserData.info_plaats === this.state.userData.info_plaats ? this.initUserData.info_plaats : this.state.userData.info_plaats,
            this.initUserData.info_telefoon2 === this.state.userData.info_telefoon2 ? this.initUserData.info_telefoon2 : this.state.userData.info_telefoon2,
            this.initUserData.info_geboorteplaats === this.state.userData.info_geboorteplaats ? this.initUserData.info_geboorteplaats : this.state.userData.info_geboorteplaats,
        );

        if(accountInfoAdded === true) {

            //CHange local account data
            if(Data.data.accountData.account_id === this.initUserData.account_id) {

              Data.data.accountData = await APIGetAccountInfo.Request(this.initUserData.account_id);

              localStorage.setItem('@username', Data.data.accountData.account_username);
              localStorage.setItem('@password', Data.data.accountData.account_password);

            }

            await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${Data.data.accountData.info_id}, 1, 1, 1, 1, 1, 1, 1, 1)`);
            await APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${Data.data.accountData.info_id})`);

            await APIEDIT.Request(`UPDATE Tutorial`, `SET tut_startup = 1`, `WHERE tut_id = 1`);

            this.props.history.push({ pathname: `/planner`, fromTut: true });
            Data.data.canUseNav = true;

            return;

        } else {

            // console.log('error');

            this.setState({loading: false});

        }

    } else {

        // console.log('error');

        this.setState({loading: false});

    }

    this.setState({ loading: false });

  }

  goBack() {

    this.props._closeModal();

  }

  closeModal2() {

    this.setState({
      showModal: false,
      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      showLeaveBalanceModal: false,
      showLeaveTypeModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  async closeRegistrationModal() {

    this.setState({ reloading: true });

    // reload users
    const accounts = await APIGetAllAccounts.Request();

    this.setState({
      accounts: accounts,
      showRegistrationUserModal: false,
      modalData: [],
      modalMode: 0,
      reloading: false,
    });
    
  }

  hasDoneStep(key) {

    const done = this.state.steps[key];

    if (typeof done === 'undefined') {
      if (key === 'functions' || this.state.menu === key || this.state.subMenu === key) {
        return '';
      }
      return '_disabled';
    }

    // if (this.state.menu === 'general' && key === 'general') {
    //     return '';
    // }

    if (this.state.menu === 'functions' && this.state.subMenu === key) {
      return '';
    }

    if (this.state.menu === key && this.state.menu !== 'functions') {
      return '';
    }

    if (this.state.menu === key || this.state.subMenu === key) {
      if (done) {
        return '_done';
      } else {
        return '';
      }
    }

    if (done) {
      return '_done';
    }

    return '_disabled';

  }

  getLeaveBalancesBars() {

    let toRender = [];
    let count = 0;

    for (const leave of this.state.leaveBalances) {

      toRender.push(

        <div key={leave.vb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.openLeaveBalanceModal(1, leave)}>

          <div className='hr_hoursBar_sub1'>
            <span>{leave.vb_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {Constants.digitsToLocale(parseFloat(leave.vb_factor), 9)}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getLeaveTypesBars() {

    let toRender = [];
    let count = 0;

    for (const leave of this.state.leaveTypes) {

      let leaveTypeBalances = null;
      if (typeof this.state.leaveTypeBalances[leave.vt_id] !== 'undefined') {
        leaveTypeBalances = this.state.leaveTypeBalances[leave.vt_id].length;
      }

      toRender.push(

        <div key={leave.vb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '50%', cursor: 'pointer'}} onClick={() => this.openLeaveTypeModal(1, leave)}>

          <div className='hr_hoursBar_sub1'>
            <span>{leave.vt_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {leaveTypeBalances !== null ?
              <span>{leaveTypeBalances}</span>
            : <span>&nbsp;</span>}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  deleteFunctionAlert(job) {

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: __('alert_delete'),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_delete')],
        alertButtonAction: this.deleteFunction.bind(this, job),
        showAlert: true,
    });

  }

  async deleteFunction(job) {

    this.setState({ reloading: true });

    await APIDELETE.Request(`DELETE FROM Functie`, `WHERE functie_id = ${job.functie_id}`);
    const jobs = await APIGetFunctions.Request();

    this.setState({ reloading: false, functions: jobs });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="tutmodal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.goBack() : null}>

        <div className="tutmodal-content" style={{minHeight: this.state.loading === true ? '15vw' : null}}>

          <div className="tutmodal-header">
              <p>{Localization.t.tutmodal_title}</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="tutmodal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showModal === true ?
            
                <LocationsModal
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen2.bind(this)}
                />

              : null}

              {this.state.showRegistrationUserModal ?
                <RegistrationUserModal 
                  _liveSwitch={this.props.liveSwitch}
                  _parent={this.props.parent}
                  history={this.props.history} 
                  _closeModal={this.closeRegistrationModal.bind(this)} 
                />
              : null}

              {this.state.showBonusModal === true ?
            
                <BonusModal
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _reloadScreen={this.reloadBonus.bind(this)}
                />

              : null}

              {this.state.showPartModal === true ?
                
                <ParticularModal
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadPart.bind(this)}
                />

              : null}

              {this.state.showAbsModal === true ?
                
                <AbsencetypesModal 
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadAbs.bind(this)}
                />

              : null}

              {this.state.showSkillsModal === true ?
                
                <SkillsModal 
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadSkills.bind(this)}
                />

              : null}

              {this.state.showSectionsModal === true ?
                
                <SectionModal
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _functions={this.state.functions}
                  _reloadScreen={this.reloadScreenCompany.bind(this)}
                />

              : null}

              {this.state.showFunctionsModal === true ?
                
                <FunctionsModal 
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreenCompany.bind(this)}
                />

              : null}

               {this.state.showBreaksModal === true ?
                
                <BreaksModal 
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreenCompany.bind(this)}
                />

              : null}

              {this.state.showLeaveBalanceModal === true ?
                
                <LeaveBalanceModal 
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadLeave.bind(this)}
                />

              : null}

              {this.state.showLeaveTypeModal === true ?
                
                <LeaveTypeModal 
                  _closeModal={this.closeModal2.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadLeave.bind(this)}
                  _leaveTypeBalances={this.state.leaveTypeBalances}
                  _leaveBalances={this.state.leaveBalances}
                  />

              : null}

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              : null}

              <div className='tutmodal_menu'>

                {/* <div className='employees_menuSubTitle'>
                  <div>Configuratie</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('general')} ${this.state.menu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('general')}>
                  <div>Algemeen</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('teams')} ${this.state.menu === 'teams' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('teams')}>
                  <div>Vestigingen</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('availability')} ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('availability')}>
                  <div>Beschikbaarheid</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('planner')} ${this.state.menu === 'planner' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('planner')}>
                  <div>Planner</div>
                </div>

                {this.state.opties_urenregistratie2 === true ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('hours')} ${this.state.menu === 'hours' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('hours')}>
                    <div>Tijdregistratie</div>
                  </div>
                : null}

                {this.state.clockSystem === true ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('clock')} ${this.state.menu === 'clock' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('clock')}>
                    <div>Inkloksysteem</div>
                  </div>
                : null}

                {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('finances')} ${this.state.menu === 'finances' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('finances')}>
                    <div>Financiën</div>
                  </div>
                : null}

                <div className={`tutmodal_menuButton${this.hasDoneStep('trade')} ${this.state.menu === 'trade' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('trade')}>
                  <div>{'Ruilen & vervanging'}</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('absence')} ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('absence')}>
                  <div>Verlof</div>
                </div>

                {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('leavebalances')} ${this.state.menu === 'leavebalances' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('leavebalances')}>
                    <div>Verlofsaldo's</div>
                  </div>
                : null}

                {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('leavetypes')} ${this.state.menu === 'leavetypes' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('leavetypes')}>
                    <div>Verloftypes</div>
                  </div>
                : null}

                <div className={`tutmodal_menuButton${this.hasDoneStep('register')} ${this.state.menu === 'register' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('register')}>
                  <div>Registreren</div>
                </div> */}

                {/* <div className='employees_menuSubTitle'>
                  <div>{Localization.t.tutmodal_subTitle_myCompany}</div>
                </div> */}

                <div className={`tutmodal_menuButton${this.hasDoneStep('company')} ${this.state.menu === 'company' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('company')}>
                  <div>{Localization.t.home_menu_companyInfo}</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('data')} ${this.state.menu === 'data' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('data')}>
                  <div>{Localization.t.tutmodal_subTitle_myAccount}</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('functions')} ${this.state.menu === 'functions' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('functions')}>
                  <div>{Localization.t.tutmodal_menu_jobs}</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('employees')} ${this.state.menu === 'employees' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('employees')}>
                  <div>{Localization.t.misc_employees}</div>
                </div>
                
                {/* <div className={`tutmodal_menuButton${this.hasDoneStep('sections')} ${this.state.menu === 'sections' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('sections')}>
                  <div>{Localization.t.tutmodal_menu_sections}</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('_breaks')} ${this.state.menu === '_breaks' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('_breaks')}>
                  <div>{Localization.t.tutmodal_menu_breaks}</div>
                </div> */}

                {/* <div className={`tutmodal_menuButton${this.hasDoneStep('shifts')} ${this.state.menu === 'shifts' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('shifts')}>
                  <div>Standaard diensten</div>
                </div> */}

                {/* <div className='employees_menuSubTitle'>
                  <div>{Localization.t.tutmodal_subTitle_myAccount}</div>
                </div> */}

                {/* <div className={`tutmodal_menuButton${this.hasDoneStep('password')} ${this.state.menu === 'password' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('password')}>
                  <div>{Localization.t.tutmodal_menu_password}</div>
                </div> */}

          

                {/* <div className={`tutmodal_menuButton${this.hasDoneStep('data')} ${this.state.menu === 'data' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('data')}>
                  <div>Functie</div>
                </div> */}

              </div>

              {this.state.menu === 'general' ?

                <div className='tutmodal_menu'>

                  <div className='employees_menuTitle'>
                    <div>Menu</div>
                  </div>

                  <div className={`tutmodal_menuButton${this.hasDoneStep('general') ? '_done' : ''} ${this.state.subMenu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenuManual('general')}>
                    <div>Algemeen</div>
                  </div>

                  <div className={`tutmodal_menuButton${this.hasDoneStep('days')} ${this.state.subMenu === 'days' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenuManual('days')}>
                    <div>Werkdagen {'&'} openingstijden</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                    <div className={`tutmodal_menuButton${this.hasDoneStep('salaries')} ${this.state.subMenu === 'salaries' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenuManual('salaries')}>
                      <div>Uurlonen</div>
                    </div>
                  : null}

                </div>

              : null}

              {this.state.menu === 'teams' ?

                Data.data.teams.length > 1 ?

                  <div className='tutmodal_menu'>

                    <div className='employees_menuTitle'>
                      <div>Teams</div>
                    </div>

                    {this.getTeamButtonsMenu()}

                  </div>

                : 

                  <div className='tutmodal_menu'>

                    <div className='employees_menuTitle'>
                      <div>Teams</div>
                    </div>

                    <div className={`employees_menuButton activeAv`}>
                      <div>{Data.data.storeName}</div>
                    </div>

                  </div>

              : null}

              <div className='tutmodal_content' style={this.state.menu !== 'general' && this.state.menu !== 'teams' ? {width: 'calc(87% - 0px)'} : null}>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                :

                  <div className='tutmodal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='loader3'>
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                      </div>

                    :

                      null

                    }

                    {this.state.menu === 'general' ?

                      this.state.subMenu === 'general' ?

                        <div>

                          <div className='appoptions_submenu_title'>Algemeen</div>

                          {/* <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Jouw pakket</div>

                          <div className='appoptions_submenu_rowContainer'>

                            {Data.data.pakketID === 99 ?
                              <span style={{fontsize: '0.8vw'}}>Maximaal</span>
                            :
                            Data.data.pakketID === 0 ?
                              <span style={{fontsize: '0.8vw'}}>Proefversie</span>
                            :
                              <span style={{fontsize: '0.8vw'}}>{Data.data.maxEployees - 9} - {Data.data.maxEployees} medewerkers</span>
                            }

                          </div> */}

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Versie</div>

                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Versie</span>

                            {Data.data.appOptions.opties_versie === '0' ?
                              <span><b>Starter</b></span>
                            : Data.data.appOptions.opties_versie === '1' ?
                              <span><b>Regular</b></span>
                            : 
                              <span><b>Enterprise</b></span>
                            }

                            {/* <div className="reports_toggleButton_pair">

                              <div className={`reports_toggleButton_pair_sub1${this.state.opties_versie === '0' ? '_active' : ''}`} onClick={() => this.editNewOptions('opties_versie', '0', false)}>
                                <span>Simpel</span>
                              </div>

                              <div className={`reports_toggleButton_pair_sub3${this.state.opties_versie === '1' ? '_active' : ''}`} onClick={() => this.editNewOptions('opties_versie', '1', false)} style={this.state.opties_versie === '2' ? {borderRight: 0} : this.state.opties_versie === '0' ? {borderLeft: 0} : {}}>
                                <span>Uitgebreid</span>
                              </div>

                              <div className={`reports_toggleButton_pair_sub2${this.state.opties_versie === '2' ? '_active' : ''}`} onClick={() => this.editNewOptions('opties_versie', '2', false)}>
                                <span>Volledig</span>
                              </div>

                            </div> */}

                            {/* <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <b>Simpele versie</b><br />
                                  <span>De simpele versie is een compacte omgeving en bevat alleen de tools om jouw medewerkers in te roosteren.</span><br /><br />
                                  <b>Volledige versie</b><br />
                                  <span>De volledige versie bevat alle beschikbare tools.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip> */}

                          </div>

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Algemeen</div>

                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Smart Plan toestaan</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_smartplan} 
                                    onClick={() => this.editNewOptions('opties_smartplan', !this.state.opties_smartplan)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>De Smart Plan optie tonen in de planner. Hiermee worden jouw medewerkers snel ingepland op basis van de door hun aangegeven beschikbaarheid.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                          {/* {Data.data.appOptions.opties_versie !== '0' ?
                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Tijdregistratie weergeven</span>

                              <label className={"switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_urenregistratie2} 
                                      onClick={() => this.editNewOptions('opties_urenregistratie2', !this.state.opties_urenregistratie2)}
                                      readOnly
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Uren bijhouden volgens planner of inkloksysteem.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          : null}

                          {Data.data.appOptions.opties_versie === '2' ?
                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Financiën weergeven</span>

                              <label className={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false ? 'switch_disabled' : "switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_financien} 
                                      onClick={() => this.editNewOptions('opties_financien', !this.state.opties_financien)}
                                      readOnly
                                      disabled={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false}
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Uitgebreid financiën bijhouden van medewerkers. De financiën rapportage is terug te zien onder het tabje 'Overzichten'.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          : null} */}

                          {Data.data.appOptions.opties_versie === '2' ?
                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Inkloksysteem</span>

                              <label className={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false ? 'switch_disabled' : "switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.clockSystem} 
                                      onClick={() => this.toggleSwitchClock()}
                                      readOnly
                                      disabled={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false}
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Gebruik maken van het inkloksysteem.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          : null}

                          {Data.data.appOptions.opties_versie !== '0' ?
                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Chatten toestaan</span>

                              <label className={"switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_chat} 
                                      onClick={() => this.editNewOptions('opties_chat', !this.state.opties_chat)}
                                      readOnly
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>De chat-functie toestaan. Wanneer dit is toegestaan, kan er onderling gechat worden via de Tiemdo app.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          : null}

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Algemeen medewerkers</div>

                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Feestdagen weergeven</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_feestdagen} 
                                    onClick={() => this.editNewOptions('opties_feestdagen', !this.state.opties_feestdagen)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Feestdagen weergeven voor medewerkers in de Tiemdo app.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Eindtijd weergeven</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_eindtijd_weergeven} 
                                    onClick={() => this.editNewOptions('opties_eindtijd_weergeven', !this.state.opties_eindtijd_weergeven)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Eindtijd van diensten weergeven voor medewerkers in de Tiemdo app.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                        </div>

                      :
                      this.state.subMenu === 'days' ?
                      
                        <div>

                          <div className='appoptions_submenu_title'>Werkdagen {`&`} openingstijden</div>

                          {Data.data.appOptions.opties_versie !== '0' ?
                            <div className='appoptions_submenu_rowContainer' sty>

                              <span style={{fontsize: '0.8vw'}}>Wekelijkse FTE-uren</span>

                              <input 
                                className={'inputField2Shadow'}
                                min={0}
                                max={999999999}
                                value={this.state.opties_fteUren}
                                onChange={(event) => this.onChangeNumberData('fte', 0, 999999999, event)}
                                type={'number'}
                                step={0.01}
                                style={{width: '15%'}}
                                />

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Geef hier het Fulltime-Equivalent aan. Dit zijn de uren die een full-time medewerker moet werken per week. (0 = geen)</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          : null}

                          {Data.data.appOptions.opties_versie !== '0' ?
                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Arbeidsduur werkdag</span>

                              <input 
                                className={'inputField2Shadow'}
                                onChange={(event) => this.onChangeNumberData('dailyWorkHours', 0, 24, event)}
                                // onKeyDown={(event) => this.handleKey(event, 'maxDays')}
                                value={this.state.dailyWorkHours}
                                placeholder={'8'}
                                maxLength={5}
                                type={'number'}
                                style={{width: '15%'}}

                              />

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Geef hier het fulltime aantal contracturen per dag aan.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          : null}

                          <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw', paddingLeft: '1%'}}>

                            Openingstijden

                            {/* <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>De geselecteerde werkdagen zullen weergegeven worden in de planner en meegenomen worden in de berekening van verlofuren.</span><br /><br />
                                  <span>Werkdagen kunnen ook per medewerker ingesteld worden bij {`Medewerkers -> Naam -> Werkdagen`}.</span>
                                </div>
                              )}
                              style={{marginLeft: '2%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip> */}
                          </div>

                          {/* <div className='salaries_buttonContainer_apply' style={{paddingLeft: '1%', marginTop: '1%', marginBottom: 0, width: '45%'}}>

                            <span style={{fontsize: '0.8vw'}}>De werkdagen toepassen op alle medewerkers</span>

                            <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.applyWorkWeekAlert()}>Toepassen</span>

                          </div> */}

                          <div>

                            <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw', marginLeft: '1vw'}}>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekMonday === false ? 0.5 : 1}}>Maandag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw1']=ref}
                                    className={this.state.workWeekMonday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 1, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 1, 'default_workdays')}
                                    value={this.state.defaultWorkdays[1]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekMonday === false}
                                  />

                                </div>

                              </div>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekTuesday === false ? 0.5 : 1}}>Dinsdag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw2']=ref}
                                    className={this.state.workWeekTuesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 2, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 2, 'default_workdays')}
                                    value={this.state.defaultWorkdays[2]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekTuesday === false}
                                  />

                                </div>

                              </div>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekWednesday === false ? 0.5 : 1}}>Woensdag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw3']=ref}
                                    className={this.state.workWeekWednesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 3, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 3, 'default_workdays')}
                                    value={this.state.defaultWorkdays[3]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekWednesday === false}
                                  />

                                </div>

                              </div>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekThursday === false ? 0.5 : 1}}>Donderdag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw4']=ref}
                                    className={this.state.workWeekThursday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 4, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 4, 'default_workdays')}
                                    value={this.state.defaultWorkdays[4]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekThursday === false}
                                  />

                                </div>

                              </div>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekFriday === false ? 0.5 : 1}}>Vrijdag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw5']=ref}
                                    className={this.state.workWeekFriday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 5, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 5, 'default_workdays')}
                                    value={this.state.defaultWorkdays[5]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekFriday === false}
                                  />

                                </div>

                              </div>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekSaturday === false ? 0.5 : 1}}>Zaterdag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw6']=ref}
                                    className={this.state.workWeekSaturday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 6, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 6, 'default_workdays')}
                                    value={this.state.defaultWorkdays[6]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekSaturday === false}
                                  />

                                </div>

                              </div>

                              <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                  <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekSunday === false ? 0.5 : 1}}>Zondag</span>

                                </div>

                                <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                  <input
                                    ref={(ref) => this.timeRef['dw0']=ref}
                                    className={this.state.workWeekSunday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                    onChange={(event) => this.onChangeTime(event, 0, true, 'default_workdays')}
                                    onKeyDown={(event) => this.handleKey(event, 0, 'default_workdays')}
                                    value={this.state.defaultWorkdays[0]}
                                    placeholder={'Begin- en eindtijd'}
                                    style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                    disabled={this.state.workWeekSunday === false}
                                  />

                                </div>

                              </div>

                            </div>

                          </div>

                        </div>

                      :
                      this.state.subMenu === 'salaries' ?

                        <div style={{width: '55%'}}>

                          <div className='appoptions_submenu_title'>Uurlonen</div>

                          {this.state.showError === true ?
                            <div style={{marginLeft: '1%', fontSize: '0.8vw', color: Colors.color.redFilledIn, marginBottom: '2%'}}>{this.state.errorText}</div>
                          : null}

                          {this.state.selectedSalaryModel !== null ?
                            <div className='salaries_buttonContainer'>

                              <span className='planner_publishMenu_deButton' onClick={() => this.deleteSalaryModelAlert()}>Verwijderen</span>

                              <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveSalaries()}>Opslaan</span>

                            </div>
                          : null}

                          <div className='appoptions_salary_selectionContainer' style={{marginBottom: '2vw'}}>

                            <span style={{fontsize: '0.8vw'}}>Loonmodel</span>

                            <select 
                                name="Selecteer een loonmodel" 
                                className={'dropDownField'}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                                onChange={(event) => this.selectSalaryModel(event)}
                            >
                                <option value={'null'} selected={this.state.selectedSalaryModel === null}>- Nieuw loonmodel toevoegen -</option>
                                {this.getSalaryModelBars()}
                            </select>

                          </div>

                          {this.state.selectedSalaryModel === null ?
                            <div className='salaries_buttonContainer'>

                              <span className='planner_publishMenu_buttonOpen' style={{marginLeft: 0}} onClick={() => this.saveSalaryModel()}>Toevoegen</span>

                            </div>
                          : null}

                          {/* {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length > 0 ?

                            <div className='salaries_buttonContainer_apply' style={{marginTop: '4%'}}>

                              <span style={{marginLeft: '1%', fontsize: '0.8vw'}}>Alle medewerkers resetten naar dit loonmodel</span>

                              <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.resetSalariesAlert()}>Resetten</span>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Dit loonmodel zal toegepast worden op alle medewerkers</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-6%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>

                          : null} */}

                          {/* {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length > 0 ?

                            <div className='salaries_buttonContainer_apply' style={{marginTop: '4%', marginBottom: '2vw'}}>

                              <span style={{marginLeft: '1%', fontsize: '0.8vw'}}>De uurlonen toepassen op medewerkers met dit loonmodel</span>

                              <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.applySalariesAlert()}>Toepassen</span>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>De onderstaande uurlonen zullen worden toegepast op alle medewerkers die dit loonmodel gebruiken</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-6%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>

                          : null} */}

                          {this.state.selectedSalaryModel !== null ?
                            this.state.newSalaries[this.state.selectedSalaryModel].length > 0 ?
                              <span style={{marginLeft: '1%', fontSize: '1vw', fontWeight: 'bold'}}>Toegevoegde uurlonen</span>
                            : 
                              <div style={{marginLeft: '1%', fontsize: '0.8vw', marginTop: '2vw'}}>Je hebt nog geen uurlonen toegevoegd</div>
                          : null}

                          {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length > 1 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1%'}}>
                              <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.newSalaries[this.state.selectedSalaryModel][0].uurloon_leeftijd}</b> jaar of jonger krijgen een uurloon van {this.formatter.format(this.state.newSalaries[this.state.selectedSalaryModel][0].uurloon_hoeveelheid)}</div>
                              <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd}</b> jaar of ouder krijgen een uurloon van {this.formatter.format(this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid)}</div>
                            </div>
                          : null}

                          {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length === 1 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1%'}}>
                              <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd}</b> jaar en jonger of ouder krijgen een uurloon van {this.formatter.format(this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid)}</div>
                            </div>
                          : null}

                          {this.state.selectedSalaryModel !== null ?

                            this.renderSalaries()

                          : 

                             <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>Naam</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={50}
                                  value={this.state.newSalaryModel}
                                  onChange={(event) => this.setState({ newSalaryModel: event.target.value, showError: false })}
                                  placeholder={'Naam loonmodel'}
                                  style={{width: 'calc(60% - 34px)', fontsize: '0.8vw'}} 
                              />

                            </div>
                          
                          }

                          {this.state.selectedSalaryModel !== null ?
                            <div style={{marginLeft: '1%'}}>

                              <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>Nieuw uurloon toevoegen</div>

                              <div className='salaries_buttonContainer_add'>

                                <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.addRow()}>Toevoegen</span>

                              </div>

                              {this.renderEmptySalaries()}

                            </div>
                          : null}

                        </div>

                      :

                        null

                    :
                    this.state.menu === 'company' ?

                      Data.data.teams.length > 1 ?

                        <div style={{width: '45%'}}>
                        
                          <div className='appoptions_submenu_title'>{this.state.chosenTeamObj.team_naam}</div>

                          <div className='appoptions_salary_selectionContainer'>

                            <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyName2')}</span>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.chosenTeamObj.team_naam}
                                onChange={(event) => this.changeTeamData(event, 'team_naam')}
                                placeholder={__('employees_contractTag_companyName2')}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                            />

                          </div>

                          <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                            <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyAddress2')}</span>

                            <input 
                                className={'inputField2'}
                                maxLength={100}
                                value={this.state.chosenTeamObj.team_adres}
                                onChange={(event) => this.changeTeamData(event, 'team_adres')}
                                placeholder={__('employees_contractTag_companyAddress2')}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                            />

                          </div>

                          <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                            <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyPostalCode2')}</span>

                            <input 
                                className={'inputField2'}
                                maxLength={20}
                                value={this.state.chosenTeamObj.team_postcode}
                                onChange={(event) => this.changeTeamData(event, 'team_postcode')}
                                placeholder={__('employees_contractTag_companyPostalCode2')}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                            />

                          </div>

                          <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                            <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyCity2')}</span>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.chosenTeamObj.team_plaats}
                                onChange={(event) => this.changeTeamData(event, 'team_plaats')}
                                placeholder={__('employees_contractTag_companyCity2')}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                            />

                          </div>

                          <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                            <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyPhone2')}</span>

                            <input 
                                className={'inputField2'}
                                maxLength={20}
                                value={this.state.chosenTeamObj.team_telefoon}
                                onChange={(event) => this.changeTeamData(event, 'team_telefoon')}
                                placeholder={__('employees_contractTag_companyPhone2')}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                            />

                          </div>

                          <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                            <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyNumber2')}</span>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.chosenTeamObj.team_kvk}
                                onChange={(event) => this.changeTeamData(event, 'team_kvk')}
                                placeholder={__('employees_contractTag_companyNumber2')}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                            />

                          </div>

                        </div>

                      :

                        <div>
                          
                          <div className='appoptions_submenu_title'>{Data.data.storeName}</div>

                          <div style={{ marginTop: '0.5vw', marginLeft: '0.5%' }}>
                            <span>{___.startup_tut_company}</span>
                          </div>

                          <div style={{width: '45%', marginTop: '2vw'}}>

                            <div className='appoptions_salary_selectionContainer'>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyName2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={50}
                                  value={Data.data.storeName}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_naam')}
                                  placeholder={__('employees_contractTag_companyName2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyAddress2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={100}
                                  value={Data.data.storeAddress}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_adres')}
                                  placeholder={__('employees_contractTag_companyAddress2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyPostalCode2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={20}
                                  value={Data.data.storeZip}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_postcode')}
                                  placeholder={__('employees_contractTag_companyPostalCode2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyCity2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={50}
                                  value={Data.data.storeCity}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_plaats')}
                                  placeholder={__('employees_contractTag_companyCity2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyCountry2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={20}
                                  value={Data.data.storeCountry}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_land')}
                                  placeholder={__('employees_contractTag_companyCountry2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyPhone2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={20}
                                  value={Data.data.storePhone}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_telefoon')}
                                  placeholder={__('employees_contractTag_companyPhone2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyEmail2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={100}
                                  value={Data.data.storeMail}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_email')}
                                  placeholder={__('employees_contractTag_companyEmail2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div>

                            {/* <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                              <span style={{fontsize: '0.8vw'}}>{__('employees_contractTag_companyNumber2')}</span>

                              <input 
                                  className={'inputField2'}
                                  maxLength={50}
                                  value={Data.data.storeKvK}
                                  onChange={(event) => this.changeCompanyData(event, 'bedrijf_kvk')}
                                  placeholder={__('employees_contractTag_companyNumber2')}
                                  style={{width: '60%', fontsize: '0.8vw'}} 
                              />

                            </div> */}

                          </div>

                        </div>

                    :
                    this.state.menu === 'availability' ?

                      <div>

                        <div className='appoptions_submenu_title'>Beschikbaarheid</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Dagelijkse beschikbaarheid</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.availability === 'AVAILABILITY'} 
                                onClick={() => this.toggleSwitchAvailability()}
                                readOnly
                            />
                            <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers toestaan om zelf de keuze te laten maken wanneer zij beschikbaar of onbeschikbaar zijn per dag.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Standaard beschikbaarheid</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.availability === 'STANDARD'} 
                                  onClick={() => this.toggleSwitchStandard()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Standaard beschikbaarheid voor medewerkers van maandag t/m zondag.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Schoolrooster</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.availability === 'SCHOOL'} 
                                  onClick={() => this.toggleSwitchSchool()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers toestaan om hun schoolrooster en onbeschikbare dagen aan te geven.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Alleen onbeschikbaarheid</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.availability === 'UNAVAILABILITY'} 
                                  onClick={() => this.toggleSwitchUnavailability()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers toestaan om alleen onbeschikbare dagen aan te geven.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Uitgeschakeld</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.availability === 'NONE'} 
                                  onClick={() => this.toggleSwitchAvailabilityOff()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers hoeven geen beschikbaarheid aan te geven.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Extra instellingen</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Limiet onbeschikbare medewerkers per dag</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_onbpd}
                            onChange={(event) => this.onChangeNumberData('day', 0, 999999999, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Wanneer op één dag deze limiet is bereikt, kan deze dag niet meer als onbeschikbaar worden aangegeven.</span><br/>
                                <span>Bijvoorbeeld 2 = op één dag maximaal 2 medewerkers onbeschikbaar (0 = geen limiet)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {/* ONBPM EN ONBPW zijn omgedraaidd!!!!!!!!! */}

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Limiet onbeschikbaarheid per persoon per week</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_onbpm}
                            onChange={(event) => this.onChangeNumberData('month', 0, 7, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Limiet voor het aangeven van aantal onbeschikbare dagen per persoon per week.</span><br/>
                                <span>Bijvoorbeeld 2 = medewerker mag maar 2 onbeschikbare dagen per week aangeven (0 = geen wekelijks limiet)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Limiet onbeschikbaarheid per persoon per maand</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_onbpw}
                            onChange={(event) => this.onChangeNumberData('week', 0, 999999999, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Limiet voor het aangeven van aantal onbeschikbare dagen per persoon per maand.</span><br/>
                                <span>Bijvoorbeeld 2 = medewerker mag maar 2 onbeschikbare dagen per maand aangeven (0 = geen maandelijks limiet)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                    :
                    this.state.menu === 'clock' ?

                      <div>

                        <div className='appoptions_submenu_title'>Inkloksysteem</div>

                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Inklokken via mobiel toestaan</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_klokMobiel} 
                                    onClick={() => this.editNewOptions('opties_klokMobiel', !this.state.opties_klokMobiel)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Toestaan om medewerkers in te laten klokken in de Tiemdo app.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                          {this.state.opties_klokMobiel === true ?

                            this.state.hasLocation === true ?

                              <div className='appoptions_submenu_rowContainer2'>

                                <span style={{fontsize: '0.8vw'}}>Locatie(s) ingesteld</span>

                                <div className='appOptions_button' onClick={this.openLocationsModal.bind(this)}>
                                  Mijn locaties
                                </div>

                                <Tooltip
                                  options
                                  html={(
                                    <div className='appoptions_tooltipContainer'>
                                      <span>Wanneer er locaties zijn ingesteld, kunnen medewerkers op deze locaties inklokken via hun mobiel.</span>
                                    </div>
                                  )}
                                  style={{position: 'absolute', right: '-5%'}}
                                  position={'top'}
                                  arrow
                                  followCursor
                                  theme='kodict'
                                >
                                  <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                                </Tooltip>

                              </div>

                            :

                              <div className='appoptions_submenu_rowContainer2'>

                                <span style={{fontsize: '0.8vw', color: Colors.color.redFilledIn, fontWeight: 'bold'}}>Geen locatie ingesteld</span>

                                <div className='appOptions_button' onClick={this.openLocationsModal.bind(this)}>
                                  Instellen
                                </div>

                                <Tooltip
                                  options
                                  html={(
                                    <div className='appoptions_tooltipContainer'>
                                      <span>Wanneer er locaties zijn ingesteld, kunnen medewerkers op deze locaties inklokken via hun mobiel.</span>
                                    </div>
                                  )}
                                  style={{position: 'absolute', right: '-5%'}}
                                  position={'top'}
                                  arrow
                                  followCursor
                                  theme='kodict'
                                >
                                  <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                                </Tooltip>

                              </div>

                          : null}

                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Alleen ingeplande medewerkers</span>

                            <label className={this.state.opties_klokMobiel === false ? 'switch_disabled' : "switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_klokPlanning} 
                                    onClick={() => this.editNewOptions('opties_klokPlanning', !this.state.opties_klokPlanning)}
                                    readOnly
                                    disabled={this.state.opties_klokMobiel === false}
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Alleen ingeplande medewerkers mogen inklokken via hun mobiel. Vanaf het inklokscherm is het altijd mogelijk om in te klokken.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Afronden</div>

                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>In- en uitklokken afronden</span>

                            <input 
                              className={'inputField2Shadow'}
                              min={0}
                              value={this.state.opties_klokAfronden}
                              onChange={(event) => this.onChangeNumberData('roundClock', 0, 60, event)}
                              type={'number'}
                              step={1}
                              style={{width: '10%'}}
                            />

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Inklok- en uitkloktijden afronden op x minuten. Bijv. afronden op 5 minuten maakt 12:28 = 12:30. (0 = niet afronden)</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Pauzes afronden</span>

                            <input 
                              className={'inputField2Shadow'}
                              min={0}
                              value={this.state.opties_pauzeAfronden}
                              onChange={(event) => this.onChangeNumberData('roundBreaks', 0, 60, event)}
                              type={'number'}
                              step={1}
                              style={{width: '10%'}}
                            />

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Pauzes afronden op x minuten. Bijv. afronden op 5 minuten maakt 12:28 = 12:30. (0 = niet afronden)</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                        </div>

                    :
                    this.state.menu === 'bonus' ?

                      <div>

                        <div className='appoptions_submenu_title'>

                          <span>Toeslagen</span>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Dit zijn de factoren waarmee de uren en financiën vermenigvuldigd worden. Dit kan toegepast worden op bijvoorbeeld feestdagen.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '2vw', marginBottom: '1%', marginLeft: '0.5%'}}>
                          <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openBonusModal(null)}>Toevoegen</span>
                        </div>

                        {this.getBonusBars()}

                      </div>

                    :
                    this.state.menu === 'finances' ?

                      <div>

                        <div className='appoptions_submenu_title'>Financiën</div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Factoren</div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Wekelijkse FTE-uren</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            max={999999999}
                            value={this.state.opties_fteUren}
                            onChange={(event) => this.onChangeNumberData('fte', 0, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Geef hier het Fulltime-Equivalent aan. Dit zijn de uren die een full-time medewerker moet werken per week. (0 = geen)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>BTW (%)</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            max={999999999}
                            value={this.state.opties_BTW}
                            onChange={(event) => this.onChangeNumberData('btw', 0, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Geef hier het BTW percentage aan.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Omzet inclusief BTW</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_omzetBTW} 
                                  onClick={() => this.editNewOptions('opties_omzetBTW', !this.state.opties_omzetBTW)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Is jouw aangegeven omzet inclusief of exclusief BTW?</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Loonfactor</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={1}
                            max={999999999}
                            value={this.state.opties_loonfactor}
                            onChange={(event) => this.onChangeNumberData('salaryFactor', 1, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>De loonkosten worden met deze factor vermenigvuldigd. Deze vermenigvuldiging kan worden gebruikt om diverse extra kosten mee te kunnen rekenen (zoals kosten die naast de directe loonkosten gemaakt worden).</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Loonpercentage van (verwachte) omzet</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            max={999999999}
                            value={this.state.opties_loonPercentage}
                            onChange={(event) => this.onChangeNumberData('salaryPercent', 0, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Percentage loonkosten in verhouding tot omzet. Wanneer de verwachte loonkosten boven dit percentage komt, wordt dit rood aangegeven in de planner. (0 = uitgeschakeld)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                    :
                    this.state.menu === 'planner' ?

                      <div>

                        <div className='appoptions_submenu_title'>Planner</div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Weergave</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Beschikbaarheid verbergen</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_infoplanner_verborgen} 
                                  onClick={() => this.editNewOptions('opties_infoplanner_verborgen', !this.state.opties_infoplanner_verborgen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Beschikbaarheid op de planner verborgen houden. Beschikbaarheid kan alsnog worden gezien wanneer er op de invoerbalk geklikt wordt.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Verwachte drukte</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planner_drukte} 
                                  onClick={() => this.editNewOptions('opties_planner_drukte', !this.state.opties_planner_drukte)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Laat de verwachte drukte zien. De drukte wordt berekend met behulp van de afgelopen 3 weken.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {/* <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Extra open diensten</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planner_extraDiensten} 
                                  onClick={() => this.editNewOptions('opties_planner_extraDiensten', !this.state.opties_planner_extraDiensten)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Extra open diensten weergeven. Dit zijn diensten die extra zijn gemaakt of die (nog) niet gekoppeld zijn aan een medewerker.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div> */}

                        {/* <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Contracturen</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planner_contracturen} 
                                  onClick={() => this.editNewOptions('opties_planner_contracturen', !this.state.opties_planner_contracturen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Contracturen en overuren weergeven achter de naam van een medewerker.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div> */}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>{`Financiële berekeningen`}</div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Loonkosten</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_loonkosten} 
                                    onClick={() => this.editNewOptions('opties_planner_loonkosten', !this.state.opties_planner_loonkosten)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Loonkosten weergeven van medewerkers met een uurloon (oproepkracht, nul-uren, min/max enzovoort).</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                         {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Omzet vorig jaar</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_vorigOmzet} 
                                    onClick={() => this.editNewOptions('opties_planner_vorigOmzet', !this.state.opties_planner_vorigOmzet)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Omzet van het jaar ervoor weergeven.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Verwachte omzet</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_verwachteOmzet} 
                                    onClick={() => this.editNewOptions('opties_planner_verwachteOmzet', !this.state.opties_planner_verwachteOmzet)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Verwachte omzet kunnen invullen per dag.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Werkelijke omzet</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_omzet} 
                                    onClick={() => this.editNewOptions('opties_planner_omzet', !this.state.opties_planner_omzet)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Werkelijke omzet kunnen invullen per dag.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Productiviteit</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_productiviteit} 
                                    onClick={() => this.editNewOptions('opties_planner_productiviteit', !this.state.opties_planner_productiviteit)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Productiviteit per dag berekenen met behulp van geplande uren en omzet. Als er nog geen werkelijke omzet is gemaakt, wordt de berekening gemaakt met behulp van verwachte omzet of omzet van het jaar ervoor.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Berekening weergeven</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_berekeningen} 
                                    onClick={() => this.editNewOptions('opties_planner_berekeningen', !this.state.opties_planner_berekeningen)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>De totale berekening weergeven.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div style={{marginTop: '1vw'}}>
                            <div className='appoptions_submenu_subtitle'>Open diensten</div>

                            <div className='appoptions_submenu_rowContainer2'>

                              <span style={{fontsize: '0.8vw'}}>Open diensten gelijk beschikbaar zetten</span>

                              <label className={"switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_opendienst_beschikbaar} 
                                      onClick={() => this.editNewOptions('opties_opendienst_beschikbaar', !this.state.opties_opendienst_beschikbaar)}
                                      readOnly
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Wanneer een open dienst wordt aangemaakt, zal deze gelijk beschikbaar worden gezet.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div style={{marginTop: '1vw'}}>
                            <div className='appoptions_submenu_subtitle'>Contract</div>

                            <div className='appoptions_submenu_rowContainer2'>

                              <span style={{fontsize: '0.8vw'}}>Contract verlopen</span>

                              <label className={"switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_contractVerlopen} 
                                      onClick={() => this.editNewOptions('opties_contractVerlopen', !this.state.opties_contractVerlopen)}
                                      readOnly
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Wanneer een contract is verlopen van een medewerker houdt de planner hier rekening mee en kan deze medewerker niet meer worden ingepland.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          </div>
                        : null}

                      </div>

                    :
                    this.state.menu === 'trade' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Ruilen & overnemen`}</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Ruilen/overnemen dezelfde functie</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_ruilenFunctie} 
                                  onClick={() => this.editNewOptions('opties_ruilenFunctie', !this.state.opties_ruilenFunctie)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers mogen alleen diensten ruilen die dezelfde functie vereisen als hun eigen functie. Wanneer dit is uitgeschakeld, kan elke dienst geruild worden ongeacht de functie.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Rekening houden met competenties</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_ruilenComp} 
                                  onClick={() => this.editNewOptions('opties_ruilenComp', !this.state.opties_ruilenComp)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers mogen alleen met elkaar ruilen of andermans diensten overnemen wanneer zij dezelfde competenties hebben.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Overnemen zonder functie</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_ruilenGeenFunctie} 
                                  onClick={() => this.editNewOptions('opties_ruilenGeenFunctie', !this.state.opties_ruilenGeenFunctie)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers - die geen functie toegewezen hebben gekregen - toestaan om diensten overnemen.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Dagen van te voren</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_ruilen_vantevoren}
                            onChange={(event) => this.onChangeNumberData('tradeDays', 0, 999999999, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Aantal dagen van te voren dat geruild mag worden door medewerkers. (0 = op de dag zelf nog)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                    :
                    this.state.menu === 'hours' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Tijdregistratie`}</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Medewerkers mogen eigen uren inzien</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_urenEigen} 
                                  onClick={() => this.editNewOptions('opties_urenEigen', !this.state.opties_urenEigen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers toestaan om eigen gewerkte uren in te zien.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {/* <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Uren bijhouden</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Tijdregistratie volgens rooster</span>

                          <label className={this.state.hoursRegisterDisabled ? "switch_disabled" : "switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.hoursRegister === 'PLANNER'} 
                                  onClick={() => this.toggleSwitchHours()}
                                  readOnly
                                  disabled={this.state.hoursRegisterDisabled}
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Tijdregistratie wordt bijgehouden door het rooster.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Tijdregistratie volgens inkloksysteem</span>

                          <label className={this.state.hoursRegisterDisabled ? "switch_disabled" : "switch"}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.hoursRegister === 'CLOCK'} 
                                  onClick={() => this.toggleSwitchHours()}
                                  readOnly
                                  disabled={this.state.hoursRegisterDisabled}
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Tijdregistratie wordt bijgehouden door het inkloksysteem.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div> */}

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Rapportage</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Rooster</span>

                          <label className={this.state.hoursRegister === 'CLOCK' ? "switch_disabled" : "switch"}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_uren_daadwerkelijk === false} 
                                  onClick={() => this.editNewOptions('opties_uren_daadwerkelijk', !this.state.opties_uren_daadwerkelijk)}
                                  readOnly
                                  disabled={this.state.hoursRegister === 'CLOCK'}
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Rapportage weergeven van de uren die door de planning zijn berekend.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Daadwerkelijk gewerkte uren</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_uren_daadwerkelijk === true} 
                                  onClick={() => this.editNewOptions('opties_uren_daadwerkelijk', !this.state.opties_uren_daadwerkelijk)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Rapportage weergeven van de gewerkte uren die  door jou zijn goedgekeurd. Tijdregistratie kun je goedkeuren bij het tabje 'Tijdregistratie'.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Pauze</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Pauzes via rooster</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.breakOption === 'PLANNER'} 
                                  onClick={() => this.toggleSwitchBreakSchedule()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Pauzes berekenen via het rooster.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span style={{fontsize: '0.8vw'}}>Pauzes via gewerkte uren</span>
                            {/* {this.state.breakOption === 'CALC' ?
                              <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.selectMenu('breaks')}>
                                Pauzeregels aanpassen
                              </div>
                            : null} */}
                          </div>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.breakOption === 'CALC'}
                                  onClick={() => this.toggleSwitchBreakScheduleCalc()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Pauzes berekenen via het totaal aantal gewerkte uren. Deze berekeningen kun je zelf aanpassen.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Pauzes via inkloksysteem</span>

                          <label className={this.state.clockSystem === false || this.state.hoursRegister === 'PLANNER' ? "switch_disabled" : "switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.breakOption === 'CLOCK'}
                                  onClick={() => this.toggleSwitchBreakClock()}
                                  readOnly
                                  disabled={this.state.clockSystem === false || this.state.hoursRegister === 'PLANNER'}
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Pauzes berekenen via het inkloksysteem. Medewerkers mogen dan zelf hun pauzes aangeven via het inkloksysteem.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div style={{marginTop: '1vw'}}>
                            <div className='appoptions_submenu_subtitle'>Overuren</div>

                            <div className='appoptions_submenu_rowContainer2'>

                              <span style={{fontsize: '0.8vw'}}>Overuren bijhouden</span>

                              <label className={this.state.opties_uren_daadwerkelijk === false ? 'switch_disabled' : "switch"}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_overuren} 
                                      onClick={() => this.editNewOptions('opties_overuren', !this.state.opties_overuren)}
                                      readOnly
                                      disabled={this.state.opties_uren_daadwerkelijk === false}
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Overuren worden middels de planning en de daadwerkelijke uren berekend. Overuren kun je bekijken en wijzigen bij het tabje 'Overzichten' of bij de medewerkersinstellingen.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          </div>
                        : null}

                      </div>

                    :
                    this.state.menu === 'breaks' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Pauzeregels`}</div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '1vw', marginBottom: '1%', marginLeft: '0.5%'}}>
                          <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.addBreakRuleRow()}>Toevoegen</span>
                          <span className='planner_publishMenu_buttonOpen' style={{width: '4vw'}} onClick={() => this.saveBreakRules()}>Opslaan</span>
                        </div>

                        {this.getBreakRulesBars()}

                        {this.getEmptyBreakRulesBars()}

                      </div>

                    :
                    this.state.menu === 'absence' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Verlof`}</div>

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Verlofsaldo bijhouden</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_verlofsaldo} 
                                    onClick={() => this.editNewOptions('opties_verlofsaldo', !this.state.opties_verlofsaldo)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Verlofsaldo bijhouden bij medewerkers die een vaste uren contract hebben of een min-max contract. Bij deze medewerkers kan een verlofsaldo worden opgebouwd of een vast aantal dagen toegewezen worden.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Dagen van te voren</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_verlofDeadline}
                            onChange={(event) => this.onChangeNumberData('vacation', 0, 999999999, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Aantal dagen van te voren dat verlof kan worden aangevraagd door medewerkers. (0 = op de dag zelf nog)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Limiet dagen achtereenvolgend</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_verlof_limiet}
                            onChange={(event) => this.onChangeNumberData('vacationLimit', 0, 999999999, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Aantal dagen achtereenvolgend dat een medewerker in één verzoek vrij kan vragen. (0 = geen limiet)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                    :
                    this.state.menu === 'leavebalances' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Verlofsaldo's`}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openLeaveBalanceModal(0)}>Toevoegen</div>

                        </div>

                        <div className='hr_hoursBar' style={{width: '50%'}}>

                          <div className='hr_hoursBar_sub1'>
                            <b>Saldo</b>
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            <b>Opbouw per uur</b>
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            &nbsp;
                          </div>

                        </div>

                        {this.getLeaveBalancesBars()}

                      </div>

                    :
                    this.state.menu === 'leavetypes' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Verloftypes`}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openLeaveTypeModal(0)}>Toevoegen</div>

                        </div>

                        <div className='hr_hoursBar' style={{width: '50%'}}>

                          <div className='hr_hoursBar_sub1'>
                            <b>Type</b>
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            <b>Saldo's</b>
                          </div>

                          <div className='hr_hoursBar_sub3'>
                            &nbsp;
                          </div>

                        </div>

                        {this.getLeaveTypesBars()}

                      </div>

                    :
                    this.state.menu === 'register' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Registratie`}</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>IBAN</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.iban} 
                                  onClick={() => this.toggleSwitchIban()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers moeten hun IBAN invullen tijdens het registreren.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>BSN-nummer</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.bsn} 
                                  onClick={() => this.toggleSwitchBsn()}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers moeten hun BSN-nummer invullen tijdens het registreren.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                    :
                    this.state.menu === 'part' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Bijzonderheden`}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openPartModal(0)}>Toevoegen</div>

                        </div>

                        {this.getPartBars()}

                      </div>

                    :
                    this.state.menu === 'abs' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Afwezigheden`}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openAbsModal(0)}>Toevoegen</div>

                        </div>

                        {this.getAbsBars()}

                      </div>

                    :
                    this.state.menu === 'skills' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Competenties`}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openSkillsModal(0)}>Toevoegen</div>

                        </div>

                        {this.getSkillsBars()}

                      </div>

                    :
                    this.state.menu === 'exclusions' ?

                      <div>

                        <div className='appoptions_submenu_title'>

                          <span>Uitsluitingen</span>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Deze afwezigheidstypes worden uitgesloten tijdens de financiële berekeningen en tijdregistraties.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '0vw', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>

                          <span className='defaultLinkO' onClick={() => this.selectMenu('abs')}>Beheer afwezigheidstypes</span>

                        </div>

                        {this.getExclusionBars()}

                      </div>

                    :
                    this.state.menu === 'sections' ?

                    <div style={{width: '50%'}}>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Localization.t.tutmodal_menu_sections}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.showSectionsModal(0)}>{Localization.t.button_add}</div>

                      </div>

                      {this.getSectionBars()}

                    </div>

                  :
                  this.state.menu === 'functions' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Localization.t.tutmodal_menu_jobs}</div>

                      <div style={{ marginTop: '0.5vw' }}>
                        <div>{___.startup_tut_jobs}</div>
                        <div><b>{___.startup_tut_caution}</b>{___.startup_tut_jobs2}</div>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div style={{width: '50%'}}>

                          {this.state.selectedIndustry !== 'NULL' ?

                            <div>

                              <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{___.startup_title_jobsAuto}</div>

                              {this.getFunctionAutoBars()}

                            </div>

                          : null}

                          <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{___.startup_title_jobs}</div>

                          <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                            <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.showFunctionsModal(0)}>{Localization.t.button_add}</div>

                          </div>

                          {this.getFunctionBars()}

                        </div>

                        <div style={{width: '40%', marginLeft: '10%'}}>

                          <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{___.startup_title_profiles}</div>

                          {this.getProfileBars()}

                        </div>

                      </div>

                    </div>

                  :
                  this.state.menu === 'employees' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Localization.t.misc_employees}</div>

                      <div style={{ marginTop: '0.5vw' }}>
                        <div>{___.startup_tut_employees}</div>
                        <div><b>{___.startup_tut_caution}</b>{___.startup_tut_employees2}</div>
                      </div>

                      {this.getEmployeeAddBars()}

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.setState({ employeeBars: this.state.employeeBars + 1 })}>{Localization.t.button_add}</div>

                      </div>

                    </div>

                  :
                  this.state.menu === '_breaks' ?

                    <div style={{width: '50%'}}>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Localization.t.tutmodal_menu_breaks}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.showBreaksModal(0)}>{Localization.t.button_add}</div>

                      </div>

                      {this.getBreakBars()}

                    </div>

                  :
                  this.state.menu === 'shifts' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Standaard diensten</div>

                      <Shifts history={this.props.history} />

                    </div>

                  :
                  this.state.menu === 'password' ?

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Localization.t.tutmodal_page_password_title}</div>

                      <div className='employees_content_container' style={{marginTop: '1vw'}}>

                        <div className='employees_content_container_sub' style={{paddingLeft: 0}}>

                          <div style={{marginTop: 10, marginBottom: 25}} className='accounts_modal_passContainer'>

                              <div>{Localization.t.misc_data_password}:</div>

                              <input
                                  className={'inputField2'}
                                  type={'password'}
                                  value={this.state.password1}
                                  onChange={(event) => this.onChangeText('pw1', event)}
                                  placeholder={Localization.t.misc_data_password}
                                  autoComplete={'new-password'}
                              />

                              <div style={{marginTop: 10}}>{Localization.t.misc_data_passwordConfirm}:</div>

                              <input
                                  className={'inputField2'}
                                  type={'password'}
                                  value={this.state.password2}
                                  onChange={(event) => this.onChangeText('pw2', event)}
                                  placeholder={Localization.t.misc_data_passwordConfirm}
                                  autoComplete={'new-password'}
                              />

                          </div>

                        </div>

                        <div className='employees_content_container_sub'>

                          &nbsp;

                        </div>

                      </div>

                    </div>

                  :
                  this.state.menu === 'data' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{___.select_rights_admin}</div>

                    <div style={{ marginTop: '0.5vw' }}>
                      <span>{___.startup_tut_data1}</span>
                    </div>

                    <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{___.misc_data_title}</div>

                    <div style={{ width: '45%' }}>

                      <div className='modal-row' style={{ marginTop: '0.5vw' }}>

                          <div className='modal-rowSub1_start'>
                              <span><b>{__('misc_data_firstName')} *</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.userData.info_voornaam}
                              onChange={(event) => this.onChangeTextData('firstname', event)}
                              placeholder={__('misc_data_firstName')}
                              style={{ textTransform: 'capitalize' }}
                            />

                          </div>

                      </div>

                      <div className='modal-row' style={{ marginTop: '1vw' }}>

                          <div className='modal-rowSub1_start'>
                              <span><b>{__('misc_data_insertion')}</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.userData.info_tussenvoegsel}
                              onChange={(event) => this.onChangeTextData('insertion', event)}
                              placeholder={__('misc_data_insertion')}
                            />

                          </div>

                      </div>

                      <div className='modal-row' style={{ marginTop: '1vw' }}>

                          <div className='modal-rowSub1_start'>
                              <span><b>{__('misc_data_lastName')} *</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.userData.info_achternaam}
                              onChange={(event) => this.onChangeTextData('lastname', event)}
                              placeholder={__('misc_data_lastName')}
                              style={{ textTransform: 'capitalize' }}
                            />

                          </div>

                      </div>

                      <div className='modal-row' style={{ marginTop: '1vw' }}>

                          <div className='modal-rowSub1_start'>
                              <span><b>{__('misc_data_email')} *</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <input 
                              onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} onCut={(e) => { e.preventDefault(); return false; }}
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.userData.info_email}
                              onChange={(event) => this.onChangeTextData('email', event)}
                              placeholder={__('misc_data_email')}
                            />

                          </div>

                      </div>

                    </div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '2vw'}}>{Localization.t.tutmodal_page_password_title}</div>

                    <div style={{ marginTop: '0.5vw' }}>
                      <span>{___.startup_tut_data2}</span>
                    </div>

                    <div style={{ width: '45%' }}>

                      <div className='modal-row' style={{ marginTop: '2vw' }}>

                          <div className='modal-rowSub1_start'>
                              <span><b>{Localization.t.misc_data_password} *</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <input 
                              className={'inputField2'}
                              type="password"
                              value={this.state.password1}
                              onChange={(event) => this.onChangeText('pw1', event)}
                              placeholder={Localization.t.misc_data_password}
                              autoComplete={'new-password'}
                              />

                          </div>

                      </div>

                      <div className='modal-row' style={{ marginTop: '1vw' }}>

                          <div className='modal-rowSub1_start'>
                              <span><b>{Localization.t.misc_data_passwordConfirm} *</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <input 
                              className={'inputField2'}
                              type="password"
                              value={this.state.password2}
                              onChange={(event) => this.onChangeText('pw2', event)}
                              placeholder={Localization.t.misc_data_passwordConfirm}
                              autoComplete={'new-password'}
                              />

                          </div>

                      </div>

                    </div>

                    {/* <div className='employees_content_container' style={{marginTop: '0.5vw'}}>

                      <div className='employees_content_container_sub' style={{paddingLeft: 0}}>

                        <div>{__('misc_data_firstName')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.userData.info_voornaam}
                            onChange={(event) => this.onChangeTextData('firstname', event)}
                            placeholder={__('misc_data_firstName')}
                            style={{textTransform: 'capitalize'}}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_insertion')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.userData.info_tussenvoegsel}
                            onChange={(event) => this.onChangeTextData('insertion', event)}
                            placeholder={__('misc_data_insertion')}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_lastName')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.userData.info_achternaam}
                            onChange={(event) => this.onChangeTextData('lastname', event)}
                            placeholder={__('misc_data_lastName')}
                            style={{textTransform: 'capitalize'}}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_birth')}:</div>

                        <ReactDatePicker
                            selected={Constants.stringToDate(this.state.userData.info_geboorte)}
                            onChange={(date) => this.setBirthDate(date)}
                            className={'inputFieldDate'}
                            placeholderText={__('misc_data_birth')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            dateFormat="P"
                            locale={dateLoc[Constants.getLang().substr(0, 2)]}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_birthPlace')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={100}
                            value={this.state.userData.info_geboorteplaats}
                            onChange={(event) => this.onChangeTextData('birthplace', event)}
                            placeholder={__('misc_data_birthPlace')}
                        />

                      </div>

                      <div className='employees_content_container_sub'>

                        <div>{__('misc_data_email')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={50}
                            type={'email'}
                            value={this.state.userData.info_email}
                            onChange={(event) => this.onChangeTextData('email', event)}
                            placeholder={__('misc_data_email')}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_phone')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={12}
                            type={'tel'}
                            value={this.state.userData.info_telefoon}
                            onChange={(event) => this.onChangeTextData('phone', event)}
                            placeholder={__('misc_data_phone')}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_phone2')}:</div>

                        <input
                            className={'inputField2'}
                            maxLength={12}
                            type={'tel'}
                            value={this.state.userData.info_telefoon2}
                            onChange={(event) => this.onChangeTextData('phone2', event)}
                            placeholder={__('misc_data_phone2')}
                        />

                        <div style={{marginTop: 10}}>{__('misc_data_startDate')}:</div>

                        <ReactDatePicker
                            selected={Constants.isEmpty(this.state.userData.info_indienst) ? null : Constants.stringToDate(this.state.userData.info_indienst)}
                            onChange={(date) => this.setStartDate(date)}
                            className={'inputFieldDate'}
                            placeholderText={__('misc_data_startDate')}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            dateFormat="P"
                            locale={dateLoc[Constants.getLang().substr(0, 2)]}
                        />

                      </div>

                      </div>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>{__('misc_data_address_title')}</div>

                      <div className='employees_content_container' style={{marginTop: '0.5vw'}}>

                        <div className='employees_content_container_sub' style={{paddingLeft: 0}}>

                          <div>{__('misc_data_address')}:</div>

                          <input
                              className={'inputField2'}
                              maxLength={80}
                              value={this.state.userData.info_adres}
                              onChange={(event) => this.onChangeTextData('address', event)}
                              placeholder={__('misc_data_address')}
                              style={{textTransform: 'capitalize'}}
                          />

                          <div style={{marginTop: 10}}>{__('misc_data_zip')}:</div>

                          <input
                              className={'inputField2'}
                              maxLength={20}
                              value={this.state.userData.info_postcode}
                              onChange={(event) => this.onChangeTextData('zip', event)}
                              placeholder={__('misc_data_zip')}
                              style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                          />

                        </div>

                        <div className='employees_content_container_sub'>

                          <div>{__('misc_data_addressHouse')}:</div>

                          <input
                              className={'inputField2'}
                              maxLength={10}
                              value={this.state.userData.info_huisnummer}
                              onChange={(event) => this.onChangeTextData('house', event)}
                              placeholder={__('misc_data_addressHouse')}
                          />

                          <div style={{marginTop: 10}}>{__('misc_data_place')}:</div>

                          <input
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.userData.info_plaats}
                              onChange={(event) => this.onChangeTextData('city', event)}
                              placeholder={__('misc_data_place')}
                          />

                        </div>

                      </div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '1vw'}}>{Localization.t.tutmodal_page_password_title}</div>

                      <div style={{ marginTop: '0.5vw' }}>
                        <span>{___.startup_tut_data2}</span>
                      </div>

                      <div className='employees_content_container' style={{marginTop: '2vw'}}>

                        <div className='employees_content_container_sub' style={{paddingLeft: 0}}>

                          <div style={{marginTop: 0, marginBottom: 0}} className='accounts_modal_passContainer'>

                              <div>{Localization.t.misc_data_password}:</div>

                              <input
                                  className={'inputField2'}
                                  type={'password'}
                                  value={this.state.password1}
                                  onChange={(event) => this.onChangeText('pw1', event)}
                                  placeholder={Localization.t.misc_data_password}
                                  autoComplete={'new-password'}
                              />

                              <div style={{marginTop: 10}}>{Localization.t.misc_data_passwordConfirm}:</div>

                              <input
                                  className={'inputField2'}
                                  type={'password'}
                                  value={this.state.password2}
                                  onChange={(event) => this.onChangeText('pw2', event)}
                                  placeholder={Localization.t.misc_data_passwordConfirm}
                                  autoComplete={'new-password'}
                              />

                          </div>

                        </div>

                        <div className='employees_content_container_sub'>

                          &nbsp;

                        </div>

                      </div> */}

                    </div>

                    :
                    this.state.curStep === 'done' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginBottom: '2vw'}}>{__('tutmodal_page_config_title')}</div>

                        <span>{Localization.t.formatString(__('tutmodal_page_config_text'), <b>{__('tutmodal_page_config_finished')}</b>)}</span>

                      </div>

                    :

                      null

                    }

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {this.state.loading === true ?

            <div className='tutmodal-bottom'>

              <div className='tutmodal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='tutmodal-bottom'>

              {this.showError()}

              {this.state.curStep !== 'company' ?
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.prevStep()}>
                  <span>{__('button_back')}</span>
                </div>
              : null}

              {this.state.menu === 'done' ?
                <div className='modal-normalButton' onClick={() => this.finishStartup()}>
                  <span>{__('button_finish')}</span>
                </div>
              :
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>{__('button_next')}</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default StartupModal;