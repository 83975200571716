/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../hoursRegistration/hoursRegistration.css';
import './whatsNew.css';
import '../myHours/myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';

class WhatsNew extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.whatsNewObject = {
      '5.3.0': [
        {
          title: 'Instelbare factoren',
          sub: ``,
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Nieuwe instellingen medewerker',
          sub: ``,
          action: () => this.props.history.push('/employees'),
        },
        {
          title: 'Nieuwe configuratiescherm',
          sub: ``,
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Nieuwe opties',
          sub: ``,
          action: () => this.props.history.push('/app_options'),
        },
      ],
      '5.2.7': [
        {
          title: 'Documenten uploaden bij medewerkers',
          sub: `Documenten kunnen nu gekoppeld worden aan medewerkers`,
          action: () => this.props.history.push('/employees'),
        },
      ],
      '5.2.6': [
        {
          title: 'Nieuwe instellingen planner',
          sub: `Verwachte drukte en omzet van vorig jaar is toegevoegd`,
          action: () => this.props.history.push('/planner'),
        },
      ],
      '5.2.5': [
        {
          title: 'Omzet verbeteringen',
          sub: `Omzet is nu gekoppeld aan de planner en andersom.`,
          action: () => this.props.history.push({pathname: '/finances', refer: 'gains'}),
        },
      ],
      '5.2.4': [
        {
          title: 'Uurloontabel',
          sub: `Uurlonen kunnen gekoppeld worden aan leeftijden.`,
          action: () => this.props.history.push('/salaries'),
        },
      ],
      '5.2.1': [
        {
          title: 'Extra open diensten',
          sub: `Extra open diensten kunnen worden toegevoegd via de planner in de simpele en tabel weergave.`,
          action: () => this.props.history.push('/planner'),
        },
      ],
      '5.2.0': [
        {
          title: 'Nieuwe weergave planner',
          sub: `Klik op de knop 'weergave' om tussen de nieuwe weergaven te rouleren.`,
          action: () => this.props.history.push('/planner'),
        },
      ],
      '5.1.0': [
        {
          title: 'Verwachte financiën',
          sub: `Verwachte financiën en de werkelijke financiën zijn nu gescheiden van elkaar voor een betere overzicht.`,
          action: () => this.props.history.push('/finances'),
        },
        {
          title: 'Verbeterde urenregistratie',
          sub: `Urenregistratie is nu in te zien in een jaarlijks, maandelijks en wekelijks overzicht.`,
          action: () => this.props.history.push('/hour_registration'),
        },
        {
          title: 'Verbeterde verlofoverzicht',
          sub: `Verlofoverzicht heeft nu een betere overzicht en berekent juist de aantal verlofdagen en verlofuren.`,
          action: () => this.props.history.push('/absences'),
        },
      ],
      '5.0.0': [
        {
          title: 'Nieuwe planner opties',
          sub: `Open diensten zijn nu te sorteren onder functies.`,
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Beschikbare diensten',
          sub: `Diensten zijn nu beschikbaar te zetten via dienstopties in de Planner en via de knop 'Beschikbare diensten'. Deze diensten kunnen door medewerkers worden geaccepteerd. Wanneer je een medewerker goedkeurt voor een dienst, wordt deze medewerker automatisch ingeroosterd.`,
          action: () => this.props.history.push('/planner'),
        },
        {
          title: 'Einddatum contract verbeterd',
          sub: `Contracten hebben een betere functie gekregen waardoor een medewerker wiens contract verlopen is, niet meer ingepland kan worden. Deze optie kun je aanpassen binnen jouw configuratie onder het kopje 'Planner'.`,
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Medewerkers handmatig sorteren',
          sub: `In de Planner kunnen medewerkers nu handmatig worden gesorteerd voor de 'Standaard weergave'. Deze vind je onder de instellingen van de Planner.`,
          action: () => this.props.history.push('/planner'),
        },
        {
          title: 'Medewerkers exporteren',
          sub: ``,
          action: () => this.props.history.push('/employees'),
        },
      ],
      '4.9.9': [
        {
          title: 'Dagnotities',
          sub: 'Notities aan dagen kunnen koppelen',
          action: () => this.props.history.push('/planner'),
        },
        {
          title: 'Secundaire functies medewerker',
          sub: 'Meerdere functies aan een medewerker kunnen koppelen',
          action: () => this.props.history.push({pathname: '/employees', selected: Data.data.accountData.info_id, selectedName: Data.data.accountData.info_voornaam, menu: 'function'}),
        },
        {
          title: 'Nieuwe configuratieopties',
          sub: '',
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Afronden in-, uitklokken en pauzes',
          sub: '',
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Vaste beschikbaarheid maandag t/m zondag',
          sub: '',
          action: () => this.props.history.push('/app_options'),
        },
        {
          title: 'Instellingen per medewerker',
          sub: '',
          action: () => this.props.history.push({pathname: '/employees', selected: Data.data.accountData.info_id, selectedName: Data.data.accountData.info_voornaam, menu: 'settings'}),
        },
        {
          title: 'Competenties',
          sub: 'Vaardigheden kunnen koppelen aan medewerkers',
          action: () => this.props.history.push({pathname: '/employees', selected: Data.data.accountData.info_id, selectedName: Data.data.accountData.info_voornaam, menu: 'skills'}),
        },
        {
          title: 'Afwezigheid',
          sub: 'Afwezigheden kunnen koppelen aan medewerkers. Deze kunnen toegepast worden via de planner.',
          action: () => this.props.history.push('/absencetypes'),
        },
        {
          title: 'Bijzonderheid',
          sub: 'Bijzonderheden kunnen koppelen aan diensten. Deze kunnen toegepast worden via de planner en/of dienstenoverzicht.',
          action: () => this.props.history.push('/particularities'),
        },
      ],
    };

    // compwillmount
    
    this.state = {

      loading: false,
      reloading: false,

    };

  }

  async componentDidMount() {

  }

  getWhatsNewBars() {

    let toReturn = [];

    for (const key in this.whatsNewObject) {

      const version = this.whatsNewObject[key];

      toReturn.push(<span style={{fontWeight: 'bold', fontSize: '1.5vw', marginTop: '2vw'}}>{key}</span>);

      for (const feat of version) {

        toReturn.push(
          <div onClick={feat.action} className='whatsnew_feature' style={{marginTop: '0.5vw', display: 'flex', flexDirection: 'column'}}>
            <span className='whatsnew_feature_title' style={{fontSize: '1.1vw', fontWeight: 'bold'}}>• {feat.title}</span>
            {feat.sub !== '' ?
            <span style={{marginLeft: '1vw', fontSize: '0.8vw'}}>- {feat.sub}</span>
            : null}
          </div>

        );

      }

    }

    return toReturn;

  }

  render() {

    return (
        <div className='hoursRegistration'>

          <Navbar menuActive={true} history={this.props.history} active={'whats_new'} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='planner_nav2_sub1' style={{cursor: 'default'}}>
              
            </div> 
            <div className='planner_nav2_sub2'>
              
            </div> 
            <div className='planner_nav2_sub3' style={{flex: 2}}>
                Wat is er nieuw?
            </div> 
            <div className='schedule_nav2_sub4'>

            </div>
            <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
              
            </div> 

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='myHours_container' style={{width: '50%', marginLeft: '25%'}}>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {this.getWhatsNewBars()}
              
            </div>
        
          }

        </div>
    );
  }
  
}

export default WhatsNew;