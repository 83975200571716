/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../constants/DefaultStyle.css';
import '../options/hoursRegistration/hoursRegistration.css';
import '../options/reports/reports.scss';

//js components & classes
import Data from '../../constants/Data';

import DatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIADD from '../../classes/global/APIADD';
import APIGET from '../../classes/global/APIGET';
import APIEDIT from '../../classes/global/APIEDIT';

class CorrectionModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.lastDeleted = null;
    this.lastVal = '';
    
    this.state = {

        loading: true,

        type: 'correct', // correct, payout, transfer
        balance: null,
        date: new Date(),
        dateText: Constants.dateToString(new Date()),
        deduct: true,
        note: '',

        conversion: 'leave',
        conversion_vb: null,

        absAmount: 0,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

        leaveBalances: [],

    };

  }

  async componentDidMount() {

    const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);

    let leave = null;
    if (leaveBalances.length > 0) {
        leave = leaveBalances[0].vb_id;
    }

    this.setState({ leaveBalances: leaveBalances, balance: leave, conversion_vb: leave, loading: false });

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateText = Constants.dateToString(date);

    this.setState({
        date: date,
        dateText: dateText,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze verlofaanvraag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteHoliday.bind(this),
        showAlert: true,
    });

  }

  editAlert(accepted) {

    this.setState({
        alertTitle: accepted === true ? 'Goedkeuren' : 'Afwijzen',
        alertBody: `Weet je zeker dat je deze verlofaanvraag wilt ${accepted === true ? 'goedkeuren' : 'afwijzen'}?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', accepted === true ? 'Goedkeuren' : 'Afwijzen'],
        alertButtonAction: this.editHoliday.bind(this, accepted),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkValidations() {

    const correction2 = this.absAmount.value;
    let correction = Constants.timeStringToFloat(correction2);
    correction = isNaN(correction) ? parseFloat(correction2) : correction;
    correction = isNaN(correction) ? 0 : correction;

    if (this.mode === 1) { // LEAVE

        if (Constants.isEmpty(this.state.balance)) {
            this.setState({
                showError: true,
                errorText: `Je hebt geen saldo geselecteerd`,
            });
            return false;
        }

    }

    if (this.state.type === 'transfer') {

        if (Constants.isEmpty(this.state.conversion)) {
            this.setState({
                showError: true,
                errorText: `Je hebt geen overdracht geselecteerd`,
            });
            return false;
        }

        if (this.state.conversion === 'leave') {

            if (Constants.isEmpty(this.state.conversion_vb)) {
                this.setState({
                    showError: true,
                    errorText: `Je hebt geen saldo geselecteerd`,
                });
                return false;
            }

            if (this.state.balance === this.state.conversion_vb && this.mode === 1) {
                this.setState({
                    showError: true,
                    errorText: `Je kunt niet naar hetzelfde saldo overdragen`,
                });
                return false;
            }

        }

    }

    if (correction === 0 || isNaN(correction) || Constants.isEmpty(correction)) {
        this.setState({
            showError: true,
            errorText: `Je hebt geen geldig aantal uren ingevoerd`,
        });
        return false;
    }

    if(Constants.isEmpty(this.state.date) === true) {
        this.setState({
            showError: true,
            errorText: `Je hebt geen datum ingevoerd`,
        });
        return false;
    }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  getLeaveBalances() {

    let functions = [];

    for(const leave of this.state.leaveBalances) {

        functions.push(
            <option value={leave.vb_id} selected={leave.vb_id === this.state.balance}>{leave.vb_naam}</option>
        );

    }

    return functions;

  }

  selectLeaveBalance(event) {

    this.setState({
        balance: event.target.value,
        showError: false,
    });

  }

  getLeaveBalances2() {

    let functions = [];

    for(const leave of this.state.leaveBalances) {

        functions.push(
            <option value={leave.vb_id} selected={leave.vb_id === this.state.conversion_vb}>{leave.vb_naam}</option>
        );

    }

    return functions;

  }

  selectLeaveBalance2(event) {

    this.setState({
        conversion_vb: event.target.value,
        showError: false,
    });

  }

  async saveHoliday() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    const correction2 = this.absAmount.value;
    let correction = (Constants.timeStringToFloat(correction2));
    correction = isNaN(correction) ? parseFloat(correction2) : correction;
    correction = isNaN(correction) ? 0 : correction;

    const note = Constants.isEmpty(this.state.note) ? 'NULL' : `'${this.state.note}'`;

    if (this.state.type === 'correct') { // CORRECTION

        if (this.mode === 0) { // OVERHOURS
            await APIADD.Request(`INSERT INTO CorrectieOveruren`, `VALUES (NULL, ${this.props._userID}, ${this.state.deduct ? -correction : correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, ${note}, 0)`);
        } else if (this.mode === 1) { // LEAVE
            await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.balance}, ${this.props._userID}, ${this.state.deduct ? -correction : correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, NULL, ${note}, 0)`);
        } else if (this.mode === 2) { // T4T
            await APIADD.Request(`INSERT INTO CorrectieT4T`, `VALUES (NULL, ${this.props._userID}, ${this.state.deduct ? -correction : correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, ${note})`);
        }
        

    } else if (this.state.type === 'payout') { // PAYOUT

        if (this.mode === 0) { // OVERHOURS
            await APIADD.Request(`INSERT INTO CorrectieOveruren`, `VALUES (NULL, ${this.props._userID}, ${this.state.deduct ? -correction : correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 1, NULL, ${note}, 0)`);
        } else if (this.mode === 1) { // LEAVE
            await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.balance}, ${this.props._userID}, ${this.state.deduct ? -correction : correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 1, NULL, NULL, ${note}, 0)`);
        } else if (this.mode === 2) { // T4T
            await APIADD.Request(`INSERT INTO CorrectieT4T`, `VALUES (NULL, ${this.props._userID}, ${this.state.deduct ? -correction : correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 1, ${note})`);
        }

    } else if (this.state.type === 'transfer') { // PAYOUT

        if (this.mode === 0) { // OVERHOURS

            // add the minus to DB
            await APIADD.Request(`INSERT INTO CorrectieOveruren`, `VALUES (NULL, ${this.props._userID}, ${-correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, ${note}, 0)`);

            // get the ID
            const coID_data = await APIGET.Request(`SELECT co_id`, `FROM CorrectieOveruren`, null, `WHERE co_info_id = ${this.props._userID}`, null, `ORDER BY co_id DESC LIMIT 1`);
            const coID = coID_data.length > 0 ? coID_data[0].co_id : null;

            if (coID !== null) {

                // add the plus to DB
                await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.conversion_vb}, ${this.props._userID}, ${correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, ${coID}, NULL, ${note}, 0)`);

                // get the ID
                const cvID_data = await APIGET.Request(`SELECT cv_id`, `FROM CorrectieVerlof`, null, `WHERE cv_info_id = ${this.props._userID}`, null, `ORDER BY cv_id DESC LIMIT 1`);
                const cvID = cvID_data.length > 0 ? cvID_data[0].cv_id : null;

                if (cvID !== null) {

                    // finally edit the first ADD
                    await APIEDIT.Request(`UPDATE CorrectieOveruren`, `SET co_cv_id = ${cvID}`, `WHERE co_id = ${coID}`);

                }

            }
        
        } else if (this.mode === 1) { // LEAVE

            // add the minus to DB
            await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.balance}, ${this.props._userID}, ${-correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, NULL, ${note}, 0)`);

            // get the ID
            const cvID_data = await APIGET.Request(`SELECT cv_id`, `FROM CorrectieVerlof`, null, `WHERE cv_info_id = ${this.props._userID}`, null, `ORDER BY cv_id DESC LIMIT 1`);
            const cvID = cvID_data.length > 0 ? cvID_data[0].cv_id : null;

            if (cvID !== null) {

                if (this.state.conversion === 'overhours') {

                    // add the plus to DB
                    await APIADD.Request(`INSERT INTO CorrectieOveruren`, `VALUES (NULL, ${this.props._userID}, ${correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, ${cvID}, ${note}, 0)`);

                    // get the ID
                    const coID_data = await APIGET.Request(`SELECT co_id`, `FROM CorrectieOveruren`, null, `WHERE co_info_id = ${this.props._userID}`, null, `ORDER BY co_id DESC LIMIT 1`);
                    const coID = coID_data.length > 0 ? coID_data[0].co_id : null;

                    if (coID !== null) {

                        // finally edit the first ADD
                        await APIEDIT.Request(`UPDATE CorrectieVerlof`, `SET cv_co_id = ${coID}`, `WHERE cv_id = ${cvID}`);

                    }

                } else { // to other balance

                    // add the plus to DB
                    await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.conversion_vb}, ${this.props._userID}, ${correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, NULL, ${note}, 0)`);

                    // get the ID
                    const cvID_data2 = await APIGET.Request(`SELECT cv_id`, `FROM CorrectieVerlof`, null, `WHERE cv_info_id = ${this.props._userID}`, null, `ORDER BY cv_id DESC LIMIT 1`);
                    const cvID2 = cvID_data2.length > 0 ? cvID_data2[0].cv_id : null;

                    if (cvID2 !== null) {

                        // finally edit the first ADD
                        await APIEDIT.Request(`UPDATE CorrectieVerlof`, `SET cv_cv_id = ${cvID2}`, `WHERE cv_id = ${cvID}`);

                    }

                }

                // // add the plus to DB
                // await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.conversion_vb}, ${this.props._userID}, ${correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, ${coID})`);

                // // get the ID
                // const cvID_data = await APIGET.Request(`SELECT cv_id`, `FROM CorrectieVerlof`, null, `WHERE cv_info_id = ${this.props._userID}`, null, `ORDER BY cv_id DESC LIMIT 1`);
                // const cvID = cvID_data.length > 0 ? cvID_data[0].cv_id : null;

                // if (cvID !== null) {

                //     // finally edit the first ADD
                //     await APIEDIT.Request(`UPDATE CorrectieOveruren`, `SET co_cv_id = ${cvID}`, `WHERE co_id = ${coID}`);

                // }

            }

        } else if (this.mode === 2) { // T4T

            if (this.state.conversion === 'overhours') {
                await APIADD.Request(`INSERT INTO CorrectieOveruren`, `VALUES (NULL, ${this.props._userID}, ${correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, ${note}, 1)`);
            } else if (this.state.conversion === 'leave') {
                await APIADD.Request(`INSERT INTO CorrectieVerlof`, `VALUES (NULL, ${this.state.conversion_vb}, ${this.props._userID}, ${correction}, ${Data.data.accountData.info_id}, '${this.state.dateText}', 0, NULL, NULL, ${note}, 1)`);
            }

        }

    }

    this.reloadScreen();
    this.closeModal();

  }

//   async saveHoliday2() {

//     if (this.state.loading === true || this.checkValidations() === false) {
//         return;
//     }

//     this.setState({loading: true});

//     if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {
//       await APIEDIT.Request(`UPDATE Vakantie`, `SET vakantie_begin = '${this.state.startText}', vakantie_eind = '${this.state.endText}', vakantie_opmerking = '${this.state.comment}', vakantie_vt_id = ${this.state.selectedType === null ? 'NULL' : this.state.selectedType}`, `WHERE vakantie_id = ${this.modalData.vakantie_id}`);
//     } else {
//       await APIEDIT.Request(`UPDATE Vakantie`, `SET vakantie_begin = '${this.state.startText}', vakantie_eind = '${this.state.endText}', vakantie_opmerking = '${this.state.comment}', vakantie_vt_id = NULL`, `WHERE vakantie_id = ${this.modalData.vakantie_id}`);
//     }

//     this.reloadScreen();
//     this.closeModal();

//   }

  onChangeNumberDataFloat(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'overhours') {

      let absAmount = this.state.absAmount;

      if (nr !== '') {
        absAmount = nr;
      } else {
        absAmount = '';
      }

      this.absAmount.value = Constants.msToTime2(absAmount * 60 * 60 * 1000);
      this.setState({ absAmount: absAmount });

    }

  }

  onChangeTime(e, i2, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.absAmount.value;
    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times = e.target.value;
      // this.setState({ absAmount: times });
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   const pos = e.target.selectionStart;
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
    //   times[i2].value = e.target.value;
    //   e.target.selectionStart = pos;
    //   e.target.selectionEnd = pos;
    //   return false;
    // }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (this.lastDeleted === ':') {
          val = val.slice(0, -1);
        }

        // if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
        //   val = val + ':';
        // }

        // if (val.length === 2 && this.lastVal.length === 3) {
        //   val = val.slice(0, 1);
        // }

        // if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
        //   val = val + ' - ';
        // }

        // if (val.length > 7 && val.includes(' - ') === false) {
        //   val = val.slice(0, 5);
        //   val = val + ' - ';
        // }

        // if (val.length === 7 && (this.lastVal.length === 8)) {
        //   val = val.slice(0, -3);
        // }

        // if (this.isValidForSave(val2)) {

        //   if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
        //     val = val + ':';
        //   }

        //   if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
        //     val = val.slice(0, -1);
        //   }

        // }

        if (val.length > 7) {
          e.target.value = val;
          times = e.target.value;
          // this.setState({ absAmount: times });
          //this.setState({ timesStandard: times });
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times = e.target.value;
        // this.setState({ absAmount: times });
        // this.setState({ timesStandard: times });

    } else {
      e.target.value = this.lastVal;
      times = e.target.value;
      // this.setState({ absAmount: times });
      // this.setState({ timesStandard: times });
    }

  }

  isValidForSave(val) {

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    // if (val.length >= 0 && val.length <= 10) {
    //   val = val.substr(0, 5);
    // } else if (val.length >= 9) {
    //   val = val.substr(8, 13);
    // }

    const regexp = /^\d{0,4}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 1000;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours <= 1000) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 1000)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs
      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 16 || e.keyCode === 186) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  changeDeduction(bool) {

    this.setState({ deduct: bool });

  }

  render() {

    return (
        <div id="correctionModal" className="modal" onClick={(e) => e.target === document.getElementById("zcorrectionModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            <p>Correctie toevoegen</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    {/* <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Soort verlof</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <select 
                              name="Selecteer een soort" 
                              className={'dropDownField'}
                              style={{marginTop: 0}} 
                              onChange={(event) => this.selectHoliday(event)}
                          >
                              <option value={'0'} selected={'0' === this.state.selectedHoliday}>Een vrije dag</option>
                              <option value={'1'} selected={'1' === this.state.selectedHoliday}>Vakantie</option>
                          </select>

                        </div>

                    </div> */}

                    {/* <div className='modal-row' style={{marginTop: this.modalData === null ? '1vw' : null}}>

                        <div className='modal-rowSub1'>
                            <span><b>Verloftype</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                        <select 
                            name="Selecteer een type" 
                            className={'dropDownField'}
                            style={{marginTop: 0}} 
                            onChange={(event) => this.selectLeaveType(event)}
                            //disabled={this.modalData !== null}
                        >
                            <option value="" selected disabled hidden>Selecteer een type</option>
                            {this.getLeaveTypes()}
                        </select>

                        </div>

                    </div> */}

                    {/* <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Medewerker</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <select 
                              name="Selecteer een medewerker" 
                              className={'dropDownField'}
                              style={{marginTop: 0}} 
                              onChange={(event) => this.selectPerson(event)}
                              disabled={this.modalData !== null}
                          >
                              <option value="" selected disabled hidden>Selecteer een medewerker</option>
                              {this.getPersons()}
                          </select>

                        </div>

                    </div> */}

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Type</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                            <select 
                                name="Selecteer een optie" 
                                className={'dropDownField'}
                                style={{marginTop: 0}} 
                                onChange={(event) => this.setState({ type: event.target.value })}
                            >
                                <option value={'correct'} selected={this.state.type === 'correct'}>Correctie</option>
                                <option value={'payout'} selected={this.state.type === 'payout'}>Uitbetaling</option>
                                <option value={'transfer'} selected={this.state.type === 'transfer'}>Overdracht</option>
                            </select>

                        </div>

                    </div>

                    {this.state.type === 'transfer' && (this.mode === 1 || this.mode === 2) ?

                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Conversie naar</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                                <select 
                                    name="Selecteer een optie" 
                                    className={'dropDownField'}
                                    style={{marginTop: 0}} 
                                    onChange={(event) => this.setState({ conversion: event.target.value })}
                                >
                                    <option value={'leave'} selected={this.state.conversion === 'leave'}>Verlofsaldo</option>
                                    <option value={'overhours'} selected={this.state.conversion === 'overhours'}>Plus- {`&`} minsaldo</option>
                                </select>

                            </div>

                        </div>

                    : null}

                    {this.state.type === 'correct' ?

                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span style={{fontStyle: 'italic', fontSize: '0.7vw'}}>Saldo verhogen of verminderen</span>
                            </div>

                            <div className='modal-rowSub2' style={{alignItems: 'flex-start'}}>
                                <div className="reports_toggleButton_pair">

                                <div className={`reports_toggleButton_pair_sub1${this.state.deduct === false ? '_active' : ''}`} onClick={() => this.changeDeduction(false)}>
                                    <span>Verhogen</span>
                                </div>

                                <div className={`reports_toggleButton_pair_sub2${this.state.deduct === true ? '_active' : ''}`} onClick={() => this.changeDeduction(true)}>
                                    <span>Verminderen</span>
                                </div>

                                </div>
                            </div>

                        </div>

                    : null}

                    {this.mode === 1 ?
                    
                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>{this.state.type === 'transfer' && this.state.conversion === 'leave' ? 'Van saldo' : 'Saldo'}</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                            <select 
                                name="Selecteer een saldo" 
                                className={'dropDownField'}
                                style={{marginTop: 0}} 
                                onChange={(event) => this.selectLeaveBalance(event)}
                            >
                                <option value="" selected disabled hidden>Selecteer een saldo</option>
                                {this.getLeaveBalances()}
                            </select>

                            </div>

                        </div>

                    : null}

                    {this.state.type === 'transfer' && this.state.conversion === 'leave' ?

                        <div className='modal-row' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Naar saldo</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                            <select 
                                name="Selecteer een saldo" 
                                className={'dropDownField'}
                                style={{marginTop: 0}} 
                                onChange={(event) => this.selectLeaveBalance2(event)}
                            >
                                <option value="" selected disabled hidden>Selecteer een saldo</option>
                                {this.getLeaveBalances2()}
                            </select>

                            </div>

                        </div>

                    : null}

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Aantal uren</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                            <input
                                ref={(ref) => this.absAmount=ref}
                                className={'inputField2'}
                                defaultValue={Constants.msToTime2(0)}
                                onChange={(event) => this.onChangeTime(event, null, true)}
                                onKeyDown={(event) => this.handleKey(event)}
                                //value={Constants.msToTime2(this.state.absAmount * 60 * 60 * 1000)}
                                placeholder={'Uren'}
                                maxLength={7}
                            />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Datum</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                            <DatePicker
                                selected={this.state.date}
                                onChange={(date) => this.setDate(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                locale={dateLoc[Constants.getLang().substr(0, 2)]}
                                showWeekNumbers
                            />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Opmerking</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Maximaal 250 tekens</span>
                        </div>

                        <div className='modal-rowSub2'>

                          <textarea 
                              className={'inputFieldBig'}
                              maxLength={250}
                              value={this.state.note}
                              onChange={(event) => this.setState({ note: event.target.value })}
                              placeholder={'Opmerking'}
                          />

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

                {this.showError()}

                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                    <span>Annuleren</span>
                </div>

                <div className='modal-submitButton' onClick={() => this.saveHoliday()}>
                    <span>Toevoegen</span>
                </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default CorrectionModal;