import React from 'react';

//styles
import './password.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APILogin from '../../../classes/APILogin';
import APIEditPassword from '../../../classes/APIEditPassword';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import CryptoJS from 'crypto-js';

import BetterAlert from '../../../components/alert/alert';

class Password extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      loading2: false,

      password: '',
      password1: '',
      password2: '',

      showError: false,
      errorText: '',

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    this.setState({loading: false});

  }

  changedAlert() {
    this.setState({
        alertTitle: 'Gewijzigd',
        alertBody: `Wachtwoord is gewijzigd naar: ${this.state.password2}`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  
  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async changePass() {

    this.setState({loading2: true});

    if(await this.checkValidations() === false) {
        this.setState({loading2: false});
        return;
    }

    const changed = await APIEditPassword.Request(CryptoJS.SHA512(this.state.password2).toString());

    if(changed === true) {

        if (this.props.location.fromTut === true) {

          localStorage.setItem('@tutPassChanged', '1');

          this.props.history.push('/home');

          this.props.location.reloadTutScreen();

          return;

        }

        this.changedAlert();

        this.setState({
            password: '',
            password1: '',
            password2: '',
            loading2: false,
        });

    } else {

        // console.log('error');

        this.setState({
            loading2: false,
        });

    }

  }

  async checkValidations() {

    if(this.state.password === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen wachtwoord ingevoerd',
        });
        return false;
    }

    const loggedIn = await APILogin.Request(Data.data.accountData.account_username, CryptoJS.SHA512(this.state.password).toString());

    if(loggedIn === false) {
        this.setState({
            showError: true,
            errorText: 'Wachtwoord is onjuist',
        });
        return false;
    }

    if(this.state.password1 === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen nieuw wachtwoord ingevoerd',
        });
        return false;
    }

    if(this.state.password2 === '') {
        this.setState({
            showError: true,
            errorText: 'Je moet het nieuwe wachtwoord nog bevestigen',
        });
        return false;
    }

    if(this.state.password1 !== this.state.password2) {
        this.setState({
            showError: true,
            errorText: 'Wachtwoorden komen niet overeen',
        });
        return false;
    }

    return true;

  }

  render() {

    return (
        <div className='password'>

          <Navbar history={this.props.history} active={'password'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Wachtwoord wijzigen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='registerUser_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }


                <div className={'registerUser_userContainer'}>

                    <div>Huidig wachtwoord:</div>

                    <input 
                        className={'inputField2'}
                        type={'password'}
                        value={this.state.password}
                        onChange={(event) => this.setState({ password: event.target.value, showError: false })}
                        placeholder={'Huidig wachtwoord'}
                        autoComplete={'new-password'}
                    />

                    <div style={{marginTop: 10}}>Wachtwoord:</div>

                    <input 
                        className={'inputField2'}
                        type={'password'}
                        value={this.state.password1}
                        onChange={(event) => this.setState({ password1: event.target.value, showError: false })}
                        placeholder={'Wachtwoord'}
                        autoComplete={'new-password'}
                    />

                    <div style={{marginTop: 10}}>Bevestig wachtwoord:</div>

                    <input 
                        className={'inputField2'}
                        type={'password'}
                        value={this.state.password2}
                        onChange={(event) => this.setState({ password2: event.target.value, showError: false })}
                        placeholder={'Bevestig wachtwoord'}
                        autoComplete={'new-password'}
                    />

                    {this.state.loading2 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.changePass()}>Wijzigen</div>

                    }

                    {this.showError()}

                </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default Password;