/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './clockLateModal.css';
import '../../constants/DefaultStyle.css';

class ClockLateModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;
    
    this.state = {

      selected: null,

    };

    //componentWillMount here:

  }

  componentDidMount() {

  }

  render() {

    return (
        <div id="clockLateModal" className="clockLateModal" onClick={(e) => e.target === document.getElementById("clockLateModal") ? this.props._closeModal(this.state.selected) : null}>

        <div className="alertModal-content">

          <div className="alertModal-header">
            <span className="alertModal-close" onClick={() => this.props._closeModal(null)}>&times;</span>
            <p>Reden</p>
          </div>

          <div className="alertModal-body">

            {this.state.selected === null ?
              <div className='reports_toggleButton_active' style={{marginLeft: 0, justifyContent: 'flex-start', borderRadius: '0.5vw', padding: '1vw 1.1vw'}}>
                <i className='fas fa-check-circle' />
                <span>Geen van onderstaande</span>
              </div>
            :
              <div className='reports_toggleButton' style={{marginLeft: 0, justifyContent: 'flex-start', borderRadius: '0.5vw', padding: '1vw 1.1vw'}} onClick={() => this.setState({ selected: null })}>
                <i className='fal fa-circle' />
                <span>Geen van onderstaande</span>
              </div>
            }

            {this.state.selected === 1 ?
              <div className='reports_toggleButton_active' style={{marginLeft: 0, justifyContent: 'flex-start', borderRadius: '0.5vw', padding: '1vw 1.1vw', marginTop: '0.5vw'}}>
                <i className='fas fa-check-circle' />
                <span>Te laat</span>
              </div>
            :
              <div className='reports_toggleButton' style={{marginLeft: 0, justifyContent: 'flex-start', borderRadius: '0.5vw', padding: '1vw 1.1vw', marginTop: '0.5vw'}} onClick={() => this.setState({ selected: 1 })}>
                <i className='fal fa-circle' />
                <span>Te laat</span>
              </div>
            }

            {this.state.selected === 2 ?
              <div className='reports_toggleButton_active' style={{marginLeft: 0, justifyContent: 'flex-start', borderRadius: '0.5vw', padding: '1vw 1.1vw', marginTop: '0.5vw'}}>
                <i className='fas fa-check-circle' />
                <span>Vergeten in te klokken</span>
              </div>
            :
              <div className='reports_toggleButton' style={{marginLeft: 0, justifyContent: 'flex-start', borderRadius: '0.5vw', padding: '1vw 1.1vw', marginTop: '0.5vw'}} onClick={() => this.setState({ selected: 2 })}>
                <i className='fal fa-circle' />
                <span>Vergeten in te klokken</span>
              </div>
            }

          </div>

          <div className='alertModal-bottom'>
            <div className='modal-normalButton' onClick={() => this.props._closeModal(this.state.selected)}>
              <span>Doorgaan</span>
            </div>
          </div>

        </div>

      </div>
    );
  }
  
}

export default ClockLateModal;