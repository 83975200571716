/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/functions/functions.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../../components/alert/alert';
import APIADD from '../../classes/global/APIADD';
import APIEDIT from '../../classes/global/APIEDIT';
import APIDELETE from '../../classes/global/APIDELETE';

import DatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import APIGET from '../../classes/global/APIGET';

class RepeatShiftModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.date = this.props._modalDate;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        interval: 1,
        type: 'week',
        endDate: null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if (this.mode === 1) {

        if (Constants.isEmpty(this.modalData.hr_einddatum) === false) {
            this.setDate(Constants.stringToDate(this.modalData.hr_einddatum));
        }

        this.setState({
            interval: this.modalData.hr_interval,
            type: this.modalData.hr_type,
        });

    }

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze herhaling wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteFunction.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const endDate = Constants.isEmpty(this.state.endDate) ? `NULL` : `'${Constants.dateToString(this.state.endDate)}'`;
    const _break = Constants.isEmpty(this.modalData.rooster_pauze) ? `'00:00:00'` : `'${this.modalData.rooster_pauze}'`;

    let added = false;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        added = await APIADD.Request(`INSERT INTO HerRooster`, `VALUES (NULL, ${this.modalData.info_id}, '${this.modalData.rooster_datum}', ${endDate}, '${this.modalData.rooster_begin}', '${this.modalData.rooster_eind}', ${_break}, ${this.modalData.functie_id}, ${this.state.interval}, '${this.state.type}', ${Data.data.chosenTeamObj.team_id})`);
    } else {
        added = await APIADD.Request(`INSERT INTO HerRooster`, `VALUES (NULL, ${this.modalData.info_id}, '${this.modalData.rooster_datum}', ${endDate}, '${this.modalData.rooster_begin}', '${this.modalData.rooster_eind}', ${_break}, ${this.modalData.functie_id}, ${this.state.interval}, '${this.state.type}', NULL)`);
    }

    if(added === true) {

        const addedID = await APIGET.Request(`SELECT hr_id`, `FROM HerRooster`, null, null, null, `ORDER BY hr_id DESC LIMIT 1`);

        if (addedID.length > 0) {

            const _addedID = addedID[0].hr_id;

            if (typeof this.props._skillsShifts[this.modalData.rooster_id] !== 'undefined') {
                for (const skill of this.props._skillsShifts[this.modalData.rooster_id]) {
                    await APIADD.Request(`INSERT INTO CompHerRooster`, `VALUES (NULL, ${skill.comp_id}, ${_addedID})`);
                }
            }

            if (typeof this.props._partShifts[this.modalData.rooster_id] !== 'undefined') {
                await APIADD.Request(`INSERT INTO BijzHerRooster`, `VALUES (NULL, ${this.props._partShifts[this.modalData.rooster_id].bijz_id}, ${_addedID})`);
            }

        }

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  async editFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const endDate = Constants.isEmpty(this.state.endDate) ? `NULL` : `'${Constants.dateToString(this.state.endDate)}'`;

    const added = await APIEDIT.Request(`UPDATE HerRooster`, `SET hr_interval = ${this.state.interval}, hr_type = '${this.state.type}', hr_einddatum = ${endDate}`, `WHERE hr_id = ${this.modalData.hr_id}`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  async deleteFunction() {

    this.setState({loading: true});

    const added = await APIDELETE.Request(`DELETE FROM HerRooster`, `WHERE hr_id = ${this.modalData.hr_id}`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  checkValidations() {

    if (Constants.isEmpty(this.state.interval) === true || isNaN(parseInt(this.state.interval)) === true || parseInt(this.state.interval) <= 0) {
        this.setState({
        showError: true,
        errorText: 'Je hebt geen correcte interval ingevoerd',
      });
      return false;
    }

    return true;

  }

  goBack() {

    this.props._closeModal();

  }

  selectType(e) {
    this.setState({ type: e.target.value });
  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        date = null;
    }

    this.setState({
        endDate: date,
    });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="repeatShiftModal" className="modal" onClick={(e) => e.target === document.getElementById("zrepeatShiftModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            <p>Herhalende dienst</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Interval</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={9}
                            value={this.state.interval}
                            onChange={(event) => this.setState({ interval: event.target.value, showError: false, })}
                            placeholder={'Herhaling'}
                            type='number'
                            step={1}
                            min={0}
                            max={9999}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Type</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <select 
                                name="Selecteer een type" 
                                className={'dropDownField'}
                                onChange={(event) => this.selectType(event)}
                            >
                                <option value={'day'} selected={'day' === this.state.type}>Dagen</option>
                                <option value={'week'} selected={'week' === this.state.type}>Weken</option>
                                <option value={'month'} selected={'month' === this.state.type}>Maanden</option>
                                <option value={'year'} selected={'year' === this.state.type}>Jaren</option>
                            </select>
                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '2vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Einddatum</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Optioneel</span>
                        </div>

                        <div className='modal-rowSub2'>
                            <DatePicker
                                selected={this.state.endDate}
                                //minDate={this.state.start}
                                onChange={(date) => this.setDate(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                locale={dateLoc[Constants.getLang().substr(0, 2)]}
                            />
                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editFunction()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveFunction()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default RepeatShiftModal;