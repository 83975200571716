


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APILogin {

    async Request(username, password) {
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/login.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&pass=${password}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }


        if (sqlData !== null) {

            Data.data.accountData = sqlData; //LOOOOOL it works , { ...sqlData, account_username: username}

            if(Data.data.accountData.info_tussenvoegsel === '') {
                Data.data.accountData.info_tussenvoegsel = null;
            }

            return true;

        } else if (sqlData === null) {

            return false;

        } else {

            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APILogin());