/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './clockAlert.css';
import '../../constants/DefaultStyle.css';
import Colors from '../../constants/Colors';

class ClockAlert extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;
    
    this.state = {

    };

    //componentWillMount here:

  }

  componentDidMount() {

  }

  render() {

    return (
        <div id="clockalertModal" className="clockalertModal" onClick={() => this.props._closeAlert()} /*onClick={(e) => e.target === document.getElementById("clockalertModal") ? this.props._closeAlert() : null}*/>

        <div className="clockalertModal-content" style={{backgroundColor: this.props._clockedIn === true ? Colors.color.greenFilledIn : Colors.color.redFilledIn, borderColor: this.props._clockedIn === true ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>

          <div className='clockalertModal_sub1'>

            <i className='fas fa-check' style={{fontSize: '4vw'}} />

          </div>

          <div className='clockalertModal_sub2'>

            {this.props._body}

          </div>

        </div>

      </div>
    );
  }
  
}

export default ClockAlert;