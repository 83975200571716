/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './history.scss'
import '../options/myHours/myHours.css';
import '../availability/availability.css';
import '../planner/planner.scss';
import '../schedule/schedule.scss';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';
import '../options/hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import APIGetLogger from '../../classes/APIGetLogger';
import Logger from '../../constants/Logger';
import Constants from '../../constants/Constants';
import APIGET from '../../classes/global/APIGET';
import ReactPaginate from 'react-paginate';
import Pagination from '../../components/pagination/pagination';

class History extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.maxPage = 250;

    this.delayTimer = null;

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      logs: [],
      filteredLogs: [],

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

      order: 'logboek_date DESC',
      name: '',

      type_availability: true,
      type_unavailability: true,
      type_planner: true,

      page: 1,
      pages: 1,

    };

  }

  // isBottom(el) {
  //   return el.getBoundingClientRect().bottom <= (window.innerHeight + 1);
  // }
  
  // componentWillUnmount() {
  //   document.removeEventListener('scroll', this.trackScrolling);
  // }
  
  // trackScrolling = () => {
  //   const wrappedElement = document.getElementById('historyID');
  //   console.log(wrappedElement.getBoundingClientRect().bottom);
  //   if (this.isBottom(wrappedElement)) {
  //     console.log('historyID bottom reached');
  //   }
  // };

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    let where = ``;
    let join = ``;

    // TEAMS
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      join += ` LEFT JOIN TeamUsers ON teamu_info_id = logboek_info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    const logs = await APIGET.Request(`SELECT *`, `FROM Logboek`, `LEFT JOIN Info ON info_id = logboek_info_id` + join, `WHERE logboek_id > 0` + where, null, `ORDER BY ${this.state.order} LIMIT ${this.maxPage} OFFSET ${(this.state.page * this.maxPage) - this.maxPage}`);

    const total = await APIGET.Request(`SELECT COUNT(*) AS logboek_totaal`, `FROM Logboek`, `LEFT JOIN Info ON info_id = logboek_info_id` + join, `WHERE logboek_id > 0` + where, null, null);
    let pages = isNaN(parseInt(total[0].logboek_totaal) / this.maxPage) ? 1 : (parseInt(total[0].logboek_totaal) / this.maxPage);
    pages = Math.ceil(pages);

    if (pages === 0) {
      pages = 1;
    }

    this.setState({
        pages: pages,
        logs: logs,
        filteredLogs: logs,
        loading: false,
    });

    // document.addEventListener('scroll', this.trackScrolling);

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  getLogs() {

    let toRender = [];
    let count = 0;

    for(const log of this.state.logs) {

      if (log.info_achternaam === null || log.info_voornaam === null) {
        continue;
      }

      const userFullName = `${log.info_voornaam} ${(log.info_tussenvoegsel !== null && log.info_tussenvoegsel !== '') ? log.info_tussenvoegsel + " " + log.info_achternaam : log.info_achternaam}`;

      toRender.push(

        <div key={log.logboek_id} className='hr_hoursBar_noHeight' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className={'hr_hoursBar_sub5'}>
            <b>{`${log.logboek_date.substring(8, 10)}-${log.logboek_date.substring(5, 7)}-${log.logboek_date.substring(0, 4)} ${log.logboek_date.substring(11, 16)}`}</b>
          </div>
          <div className={'hr_hoursBar_sub2'}>
            {userFullName}
          </div>
          <div className={'hr_hoursBar_sub5'}>
            {Logger.getLogType(log.logboek_type)}
          </div>
          <div className={'hr_hoursBar_sub1'}>
            {Logger.getLogString(log.logboek_changeFrom, log.logboek_changeTo, log.logboek_change)}
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async selectOrder(event) {

    this.setState({
      order: event.target.value,
    }, () => {
      this.reload();
    });

  }

  doSearch() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.reload();
    }, 400);
  }

  setName(event) {

    this.setState({
      name: event.target.value,
    }, () => {
      this.doSearch();
    });

  }

  toggleButton_type(type) {

    if (this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({ [`${type}`]: !this.state[type] }, () => {
      this.reload();
    });

  }

  onClickPage(page) {

    if (this.state.page === page || this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({ page: page }, () => {
      this.reload();
    });

  }

  async reload() {

    this.setState({ reloading: true });

    let where = ``;
    let join = ``;

    // TEAMS
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      join += ` LEFT JOIN TeamUsers ON teamu_info_id = logboek_info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    // TYPES
    if (this.state.type_availability === false) {
      where += ` AND logboek_type != 'AVAILABILITY'`;
    }
    if (this.state.type_unavailability === false) {
      where += ` AND logboek_type != 'UNAVAILABILITY'`;
    }
    if (this.state.type_planner === false) {
      where += ` AND logboek_type != 'PLANNER'`;
    }

    // NAME
    if (Constants.isEmpty(this.state.name) === false) {
      where += ` AND (info_voornaam LIKE '%${this.state.name}%' OR info_achternaam LIKE '%${this.state.name}%' OR info_tussenvoegsel LIKE '%${this.state.name}%' OR logboek_changeFrom LIKE '%${this.state.name}%')`;
    }

    const logs = await APIGET.Request(`SELECT *`, `FROM Logboek`, `LEFT JOIN Info ON info_id = logboek_info_id` + join, `WHERE logboek_id > 0` + where, null, `ORDER BY ${this.state.order} LIMIT ${this.maxPage} OFFSET ${(this.state.page * this.maxPage) - this.maxPage}`);

    const total = await APIGET.Request(`SELECT COUNT(*) AS logboek_totaal`, `FROM Logboek`, `LEFT JOIN Info ON info_id = logboek_info_id` + join, `WHERE logboek_id > 0` + where, null, null);
    let pages = isNaN(parseInt(total[0].logboek_totaal) / this.maxPage) ? 1 : (parseInt(total[0].logboek_totaal) / this.maxPage);
    pages = Math.ceil(pages);

    if (pages === 0) {
      pages = 1;
    }

    this.setState({ reloading: false, logs: logs, pages: pages });

  }

  render() {

    return (
        <div className='history' id='historyID'>

          <Navbar history={this.props.history} fixed active={'history'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          {this.state.reloading === true ?
                        
            <div className='loader2'>
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>

          : null}

          <div className='employees_main2'>

            <div className='employees_content2' style={ {width: '100%' }}>

              {this.state.loading === true ?

                <div className='loader'>
                  <div className="spinner">
                      <div className="double-bounce1"></div>
                      <div className="double-bounce2"></div>
                  </div>
                </div>

              :

                <div className='employees_container'>

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Logboek</div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2vw' }}>
                      
                      {this.state.type_planner === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: 0}} onClick={() => this.toggleButton_type('type_planner')}>
                          <i className='fas fa-check-circle' />
                          <span>Planner</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: 0}} onClick={() => this.toggleButton_type('type_planner')}>
                          <i className='fal fa-circle' />
                          <span>Planner</span>
                        </div>
                      }

                      {this.state.type_availability === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.toggleButton_type('type_availability')}>
                          <i className='fas fa-check-circle' />
                          <span>Beschikbaarheid</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.toggleButton_type('type_availability')}>
                          <i className='fal fa-circle' />
                          <span>Beschikbaarheid</span>
                        </div>
                      }

                      {this.state.type_unavailability === true ?
                        <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.toggleButton_type('type_unavailability')}>
                          <i className='fas fa-check-circle' />
                          <span>Onbeschikbaarheid</span>
                        </div>
                      :
                        <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.toggleButton_type('type_unavailability')}>
                          <i className='fal fa-circle' />
                          <span>Onbeschikbaarheid</span>
                        </div>
                      }

                    </div>

                    <div className='modal-row3_start' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_start'>
                            <span><b>Naam</b></span>
                        </div>

                        <div className='modal-rowSub2_start'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.name}
                            onChange={(event) => this.setName(event)}
                            placeholder={'Naam :'}
                          />

                        </div>

                    </div>

                    <div className='modal-row3_start' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_start'>
                            <span><b>Sorteren</b></span>
                        </div>

                        <div className='modal-rowSub2_start'>

                          <select 
                              name="Selecteer een functie" 
                              className={'dropDownField'}
                              onChange={(event) => this.selectOrder(event)}
                          >
                              <option value="logboek_date DESC" selected={this.state.selected === 'logboek_date DESC'}>Datum aflopend</option>
                              <option value="logboek_date" selected={this.state.selected === 'logboek_date'}>Datum oplopend</option>
                              <option value="info_voornaam DESC, info_achternaam DESC, logboek_date DESC" selected={this.state.selected === 'info_voornaam DESC, info_achternaam DESC, logboek_date DESC'}>Naam aflopend</option>
                              <option value="info_voornaam, info_achternaam, logboek_date DESC" selected={this.state.selected === 'info_voornaam, info_achternaam, logboek_date DESC'}>Naam oplopend</option>
                              <option value="logboek_type DESC, logboek_date DESC" selected={this.state.selected === 'logboek_type DESC, logboek_date DESC'}>Type aflopend</option>
                              <option value="logboek_type, logboek_date DESC" selected={this.state.selected === 'logboek_type, logboek_date DESC'}>Type oplopend</option>
                              <option value="logboek_change DESC, logboek_date DESC" selected={this.state.selected === 'logboek_change DESC, logboek_date DESC'}>Details aflopend</option>
                              <option value="logboek_change, logboek_date DESC" selected={this.state.selected === 'logboek_change, logboek_date DESC'}>Details oplopend</option>
                          </select>

                        </div>

                    </div>

                    <div className='hr_hoursBar_title' style={{ marginTop: '2vw' }}>

                      <div className='hr_hoursBar_sub5'>
                        Datum 
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        Naam
                      </div>

                      <div className='hr_hoursBar_sub5'>
                        Type
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        Details
                      </div>

                    </div>

                    {this.getLogs()}

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2vw' }}>
                      <Pagination
                        pages={this.state.pages}
                        
                        pageFunction={this.onClickPage.bind(this)}
                        mainLoading={this.state.reloading || this.state.loading}
                      />
                    </div>

                  </div>

                </div>

              }

            </div>

          </div>

          {/* {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='myHours_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

                <div className='history_filterContainer'>

                    <span style={{fontSize: '1vw'}}>Toon</span>

                    <div className={`history_filterButton ${this.state.type === 'ALL' ? 'factive' : ''}`}
                        onClick={() => this.selectType('ALL')}
                    >
                        <span>Alles</span>
                    </div>

                    <div className={`history_filterButton ${this.state.type === Constants.LOGGER_PLANNER ? 'factive' : ''}`}
                        onClick={() => this.selectType(Constants.LOGGER_PLANNER)}
                    >
                        <span>Planner</span>
                    </div>

                    <div className={`history_filterButton ${this.state.type === Constants.LOGGER_AVAILABILITY ? 'factive' : ''}`}
                        onClick={() => this.selectType(Constants.LOGGER_AVAILABILITY)}
                        >
                        <span>Beschikbaarheid</span>
                    </div>

                    <div className={`history_filterButton ${this.state.type === Constants.LOGGER_UNAVAILABILITY ? 'factive' : ''}`}
                        onClick={() => this.selectType(Constants.LOGGER_UNAVAILABILITY)}
                        >
                        <span>Onbeschikbaarheid</span>
                    </div>

                </div>

                <div className='history_filterContainer' style={{marginTop: '1vw'}}>

                    <span style={{fontSize: '1vw'}}>Sorteer op</span>

                    <select 
                        name="Selecteer een functie" 
                        className={'dropDownField'}
                        style={{width: '25%', fontSize: '1vw', height: '2.2vw', marginLeft: '1vw'}} 
                        onChange={(event) => this.selectOrder(event)}
                    >
                        <option value="logboek_date DESC" selected={this.state.selected === 'logboek_date DESC'}>Datum aflopend</option>
                        <option value="logboek_date" selected={this.state.selected === 'logboek_date'}>Datum oplopend</option>
                        <option value="info_voornaam DESC, info_achternaam DESC, logboek_date DESC" selected={this.state.selected === 'info_voornaam DESC, info_achternaam DESC, logboek_date DESC'}>Naam aflopend</option>
                        <option value="info_voornaam, info_achternaam, logboek_date DESC" selected={this.state.selected === 'info_voornaam, info_achternaam, logboek_date DESC'}>Naam oplopend</option>
                        <option value="logboek_type DESC, logboek_date DESC" selected={this.state.selected === 'logboek_type DESC, logboek_date DESC'}>Type aflopend</option>
                        <option value="logboek_type, logboek_date DESC" selected={this.state.selected === 'logboek_type, logboek_date DESC'}>Type oplopend</option>
                        <option value="logboek_change DESC, logboek_date DESC" selected={this.state.selected === 'logboek_change DESC, logboek_date DESC'}>Details aflopend</option>
                        <option value="logboek_change, logboek_date DESC" selected={this.state.selected === 'logboek_change, logboek_date DESC'}>Details oplopend</option>
                    </select>

                </div>

                <div className='history_filterContainer'>

                    <span style={{fontSize: '1vw'}}>Zoeken op naam</span>

                    <input 
                        ref={(ref) => this.inputRef=ref}
                      className={'inputField2'}
                      maxLength={100}
                      style={{width: '25%', fontSize: '1vw', height: '2.2vw', marginLeft: '1vw'}} 
                      onChange={(event) => this.setName(event)}
                    />

                </div>

                <div>

                    <div className={'history_barContainerMain stickyTopHoursRegistration'}>
                        
                        <div className={'myHours_barRowMain'} style={{flex: 1.5}}>
                            <div>Wanneer</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                            <div>Wie</div>
                        </div>
                        <div className={'myHours_barRowMain'} style={{flex: 1.5}}>
                            <div>Type</div>
                        </div>
                        <div className={'myHours_barRowMain'} style={{flex: 6}}>
                            <div>Details</div>
                        </div>

                    </div>

                    {this.getLogs()}

                </div>
              
            </div>
        
          } */}

        </div>
    );
  }
  
}

export default History;