


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIAddDeadline {

    async Request(start, end, deadline, time, edit) {

        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        edit = edit === true ? 1 : 0;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/addDeadline.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&start=${start}&end=${end}&deadline=${deadline}&time=${time}&edit=${edit}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === "1") {
            return true;

        } else if (sqlData === "0") {
            return false;

        } else {
            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIAddDeadline());