/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../planner/planner.scss';
import '../home2/home2.css';
import '../../constants/DefaultStyle.css';
import '../../components/smartPlanModal/smartPlanModal.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../../components/alert/alert';
import APIGetAvailableShifts from '../../classes/APIGetAvailableShifts';
import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';
import APIDELETE from '../../classes/global/APIDELETE';
import Colors from '../../constants/Colors';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import KodictNotifications from '../../constants/KodictNotifications';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';

class AvailableShiftsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    this.monday = Constants.getMonday(this.props._week, this.props._year);
    this.sunday = Constants.getSunday(this.props._week, this.props._year);

    this.state = {

        loading: true,

        userAvailableShifts: [],
        plannerAvailableShifts: [],

        total: 0,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        display: 'PUBLISH',
        detailsWeek: 0,
        detailsYear: 0,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

    this.teamPlanner = await APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`, null, null);

    let userAvailableShifts = await APIGetAvailableShifts.Request();

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      userAvailableShifts = Constants.getTeamPlanner(this.teamPlanner, userAvailableShifts);
    }

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`, null, null);
    }
    
    this.setState({userAvailableShifts: userAvailableShifts, plannerAvailableShifts: plannerAvailableShifts, loading: false});

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async sendPushNotification() {

    const people = await APIGetAllAccounts.Request();

    let peopleToNotify = [];

    for (const person of people) {

        //await KodictNotifications.sendPushNotification(person.info_id, 'trade', 'Beschikbare diensten', 'Er zijn nieuwe diensten beschikbaar. Kun jij werken?');
        peopleToNotify.push(person.info_id);

    }

    await KodictNotifications.sendPushNotificationBulk(
        peopleToNotify,
        'trade',
        'Beschikbare diensten',
        'Er zijn nieuwe diensten beschikbaar. Kun jij werken?'
    );

  }

  async removeUserAvailableShift(plan) {

    await APIDELETE.Request(`DELETE FROM BeschikbareDienst`, `WHERE bdienst_rooster_id = ${plan.rooster_id}`);

    const userAvailableShifts = await APIGetAvailableShifts.Request();

    this.setState({userAvailableShifts: userAvailableShifts});

  }

getUserAvailableShifts() {

    const toRender = [];
    let index = 0;

    if (this.state.userAvailableShifts.length === 0) {
        return (<div style={{marginTop: '1vw'}}>Geen beschikbare diensten</div>);
    }

    for (const available of this.state.userAvailableShifts) {

        const date = Constants.stringToDate(available.rooster_datum);

        let functionColor = available.functie_kleur !== null ? available.functie_kleur : 'EBEBEB';
        let functionName = available.functie_naam !== null ? available.functie_naam : 'Functie onbekend';

        toRender.push(

            <div key={available.rooster_id} className='planner_userAvailable_container' style={index === 0 ? {marginTop: '1vw'} : null}>

                <div className='planner_dePublish_container_sub1'>

                    <div key={available.rooster_id} className='home2_touchBoxBase'>

                        <div style={{cursor: 'default'}}>

                          <div className='home2_touchContainer'
                            style={{ 
                                        borderBottomColor: `#${available.functie_kleur}`,
                                        borderLeftColor: `#${available.functie_kleur}`,
                                        backgroundColor: Constants.hexToRgbA(`#${available.functie_kleur}`, 0.2),
                                    }} 
                          >

                            <div className='home2_initContainer' style={{backgroundColor: Constants.hexToRgbA(`#${available.functie_kleur}`, 0.5)}}>

                                <span style={{color: Constants.getContrastPlan(`#${available.functie_kleur}`)}}>{available.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                      
                            </div>

                            <div className='home2_infoContainer'>

                              <div className='home2_infoRow'>
                                <span><b>{Constants.firstUppercase(Constants.getDayName(date))}</b></span>
                                <span style={{marginLeft: '0.4vw'}}>{date.getDate()} {Constants.getShortMonthName(date).toLowerCase()}</span>
                              </div>

                              <div className='home2_infoRow' style={{marginTop: '0.2vw'}}>
                                <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                  <span style={{color: `#${functionColor}`}}><i className='far fa-clock' /></span>
                                </div>
                                <div style={{display: 'flex', flex: 15}}>
                                  <span>{available.rooster_begin.substring(0,5)} - {available.rooster_eind.substring(0,5)}</span>
                                </div>
                              </div>

                              {/* {available.rooster_pauze !== '00:00:00' ?
                                <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                                  <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                    <span style={{color: `#${functionColor}`}}><i className='far fa-coffee' /></span>
                                  </div>
                                  <div style={{display: 'flex', flex: 15}}>
                                    <span>{available.rooster_pauze.substring(0,5)}</span>
                                  </div>
                                </div>
                              : null} */}

                              <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                                <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                  <span style={{color: `#${functionColor}`}}><i className='far fa-briefcase' /></span>
                                </div>
                                <div style={{display: 'flex', flex: 15}}>
                                  <span>{functionName}</span>
                                </div>
                              </div>

                              {/* {available.bijz_id !== null ?
                                <div className='home2_infoRow3' style={{marginTop: '0.2vw'}}>
                                  <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                    <span style={{color: `#${functionColor}`}}><i className='far fa-sticky-note' /></span>
                                  </div>
                                  <div style={{display: 'flex', flex: 15}}>
                                    <span style={{color: `#${available.bijz_kleur}`}}>{available.bijz_naam}</span>
                                  </div>
                                </div>
                              : null} */}

                            </div>

                          </div>

                        </div>

                    </div>
                                        
                    {/* <div className='home2_touchBoxBase' style={{marginLeft: 0, width: '100%'}}>

                        <div className='home2_touchBoxText' style={{fontStyle: 'italic', marginBottom: '2%'}}>

                            {Constants.getDayName(available.rooster_datum)} {new Date(available.rooster_datum).getDate()} {Constants.getMonthName(available.rooster_datum)} {new Date(available.rooster_datum).getFullYear()}

                        </div>

                        <div>

                        <div className='home2_touchContainer' style={{ backgroundColor: Constants.hexToRgbA(`#${available.functie_kleur}`, 0.2)}}>

                            <div className='home2_initContainer' style={{backgroundColor: Constants.hexToRgbA(`#${available.functie_kleur}`, 0.5)}}>

                                <span style={{color: Constants.getContrastPlan(`#${available.functie_kleur}`)}}>{available.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                        
                            </div>

                            <div className='home2_timeContainer'>

                                {available.rooster_begin.substring(0,5)} - {available.rooster_eind.substring(0,5)}

                            </div>

                            <div className='home2_functionContainer'>

                            {Data.data.teams.length > 1 && Data.data.ownTeams.length > 0 ?

                                <div style={{fontSize: '0.8vw', marginBottom: '1%'}}>{Constants.getTeamNameFromScheduleID(this.props._teamPlanner, available.rooster_id)}</div>

                            : null}

                            <div>{available.functie_naam}</div>

                            </div>

                            <div className='home2_afkContainer'>

                                &nbsp;

                            </div>

                        </div>

                        </div>

                    </div> */}

                </div>

                <div className='planner_dePublish_container_sub2'>
                    <span className='modal-deleteButton' onClick={() => this.removeUserAvailableShift(available)}>
                        Ongedaan maken
                    </span>
                </div>

            </div>

        );

        index++;
        
    }

    return toRender;

  }

  async putOpenShiftOnAvailable(shift) {

    let date = Constants.getMonday(this.props._week, this.props._year); //monday

    if (shift.dagdienst_dag === '2') { //tuesday
        date = Constants.getTuesday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '3') {
        date = Constants.getWednesday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '4') {
        date = Constants.getThursday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '5') {
        date = Constants.getFriday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '6') {
        date = Constants.getSaturday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '0') {
        date = Constants.getSunday(this.props._week, this.props._year);
    }

    await APIADD.Request(`INSERT INTO ShiftBeschikbaar`, `VALUES (NULL, ${shift.dagdienst_id}, '${Constants.toISOString(date)}', ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`);

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`, null, null);
    }

    this.setState({plannerAvailableShifts: plannerAvailableShifts});

  }

  async putOpenShiftOnUnavailable(shift) {

    let date = Constants.getMonday(this.props._week, this.props._year); //monday

    if (shift.dagdienst_dag === '2') { //tuesday
        date = Constants.getTuesday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '3') {
        date = Constants.getWednesday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '4') {
        date = Constants.getThursday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '5') {
        date = Constants.getFriday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '6') {
        date = Constants.getSaturday(this.props._week, this.props._year);
    } else if (shift.dagdienst_dag === '0') {
        date = Constants.getSunday(this.props._week, this.props._year);
    }

    await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id} AND shb_datum = '${Constants.toISOString(date)}'`);

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`, null, null);
    }

    this.setState({plannerAvailableShifts: plannerAvailableShifts});

  }

  async putAllOpenShiftsOnAvailable() {

    this.setState({loading: true});

    for (const key in this.props._dayShifts) {

        for (const shift of this.props._dayShifts[key]) {

            if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === true || typeof this.props._partDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                continue;
            }

            let date = Constants.getMonday(this.props._week, this.props._year); //monday

            if (shift.dagdienst_dag === '2') { //tuesday
                date = Constants.getTuesday(this.props._week, this.props._year);
            } else if (shift.dagdienst_dag === '3') {
                date = Constants.getWednesday(this.props._week, this.props._year);
            } else if (shift.dagdienst_dag === '4') {
                date = Constants.getThursday(this.props._week, this.props._year);
            } else if (shift.dagdienst_dag === '5') {
                date = Constants.getFriday(this.props._week, this.props._year);
            } else if (shift.dagdienst_dag === '6') {
                date = Constants.getSaturday(this.props._week, this.props._year);
            } else if (shift.dagdienst_dag === '0') {
                date = Constants.getSunday(this.props._week, this.props._year);
            }

            await APIADD.Request(`INSERT INTO ShiftBeschikbaar`, `VALUES (NULL, ${shift.dagdienst_id}, '${Constants.toISOString(date)}', ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`);

        }

    }

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`, null, null);
    }

    this.setState({plannerAvailableShifts: plannerAvailableShifts, loading: false});

  }

  async removeAllOpenShiftsAvailable() {

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`);

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.toISOString(this.monday)}' AND '${Constants.toISOString(this.sunday)}'`, null, null);
    }
    
    this.setState({plannerAvailableShifts: plannerAvailableShifts, loading: false});

  }

  getPlannerAvailableShifts() {

    const toRender = [];
    let index = 0;

    for (const key in this.props._dayShifts) {

        const day = this.props._dayShifts[key];
        const date = key === 'mon' ? Constants.getMonday(this.props._week, this.props._year) :
                    key === 'tue' ? Constants.getTuesday(this.props._week, this.props._year) :
                    key === 'wed' ? Constants.getWednesday(this.props._week, this.props._year) :
                    key === 'thu' ? Constants.getThursday(this.props._week, this.props._year) :
                    key === 'fri' ? Constants.getFriday(this.props._week, this.props._year) :
                    key === 'sat' ? Constants.getSaturday(this.props._week, this.props._year) :
                    key === 'sun' ? Constants.getSunday(this.props._week, this.props._year) :
                    Constants.getMonday(this.props._week, this.props._year);

        // if (day.length > 0) {            
        //     toRender.push(
        //         <div style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1vw'}}>
        //             {/* {key === 'mon' ? `Maandag` :
        //                 key === 'tue' ? `Dinsdag` :
        //                 key === 'wed' ? `Woensdag` :
        //                 key === 'thu' ? `Donderdag` :
        //                 key === 'fri' ? `Vrijdag` :
        //                 key === 'sat' ? `Zaterdag` :
        //                 key === 'sun' ? `Zondag` :
        //                 'Maandag'
        //             }  */}
        //             {Constants.getDayName(date)} {date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()}
        //         </div>
        //     );
        // }

        for (const available of day) {

            let shiftIsAvailable = false;
            let shiftHasParticularity = false;
            let bijz = null;

            if (Constants.objectArrayContains(this.state.plannerAvailableShifts, available.dagdienst_id) === true) {
                shiftIsAvailable = true;
            }

            if (typeof this.props._partDayShifts['d' + available.dagdienst_id] !== 'undefined') {
                bijz = this.props._partDayShifts['d' + available.dagdienst_id];
                shiftHasParticularity = true;
                //shiftIsAvailable = true;
            }

            let functionColor = available.functie_kleur !== null ? available.functie_kleur : 'EBEBEB';
            let functionName = available.functie_naam !== null ? available.functie_naam : 'Functie onbekend';

            toRender.push(

                <div key={available.dagdienst_id} className='planner_userAvailable_container' style={{marginTop: '1vw'}} >

                    <div className='planner_dePublish_container_sub1'>

                        <div key={'d' + available.dagdienst_id} className='home2_touchBoxBase' style={{opacity: shiftIsAvailable ? 1 : 0.5}}>

                            <div style={{cursor: 'default'}}>

                              <div className='home2_touchContainer'
                                style={{ 
                                            borderBottomColor: `#${available.functie_kleur}`,
                                            borderLeftColor: `#${available.functie_kleur}`,
                                            backgroundColor: Constants.hexToRgbA(`#${available.functie_kleur}`, 0.2),
                                        }} 
                              >

                                <div className='home2_initContainer' style={{backgroundColor: Constants.hexToRgbA(`#${available.functie_kleur}`, 0.5)}}>

                                    <span style={{color: Constants.getContrastPlan(`#${available.functie_kleur}`)}}>{available.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                      
                                </div>

                                <div className='home2_infoContainer'>

                                  <div className='home2_infoRow'>
                                    <span><b>{Constants.firstUppercase(Constants.getDayName(date))}</b></span>
                                    <span style={{marginLeft: '0.4vw'}}>{date.getDate()} {Constants.getShortMonthName(date).toLowerCase()}</span>
                                  </div>

                                  <div className='home2_infoRow' style={{marginTop: '0.2vw'}}>
                                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                      <span style={{color: `#${functionColor}`}}><i className='far fa-clock' /></span>
                                    </div>
                                    <div style={{display: 'flex', flex: 15}}>
                                      <span>{available.dagdienst_begin.substring(0,5)} - {available.dagdienst_eind.substring(0,5)}</span>
                                    </div>
                                  </div>

                                  {/* {available.rooster_pauze !== '00:00:00' ?
                                    <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                                      <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                        <span style={{color: `#${functionColor}`}}><i className='far fa-coffee' /></span>
                                      </div>
                                      <div style={{display: 'flex', flex: 15}}>
                                        <span>{available.rooster_pauze.substring(0,5)}</span>
                                      </div>
                                    </div>
                                  : null} */}

                                  <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                      <span style={{color: `#${functionColor}`}}><i className='far fa-briefcase' /></span>
                                    </div>
                                    <div style={{display: 'flex', flex: 15}}>
                                      <span>{functionName}</span>
                                    </div>
                                  </div>

                                  {shiftHasParticularity ?
                                    <div className='home2_infoRow3' style={{marginTop: '0.2vw'}}>
                                      <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                                        <span style={{color: `#${functionColor}`}}><i className='far fa-sticky-note' /></span>
                                      </div>
                                      <div style={{display: 'flex', flex: 15}}>
                                        <span style={{color: `#${bijz.bijz_kleur}`}}>{bijz.bijz_naam}</span>
                                      </div>
                                    </div>
                                  : null}

                                </div>

                              </div>

                            </div>

                        </div>
                                            
                        {/* <div className='home2_touchBoxBase' style={{marginLeft: 0, width: '100%', marginBottom: 0}}>

                            <div>
                                                                                                                                            
                            <div className='home2_touchContainer' style={{ opacity: shiftIsAvailable ? 1 : 0.6, backgroundColor: shiftHasParticularity ? Colors.color.pastBackgroundColor : Constants.hexToRgbA(`#${available.functie_kleur}`, 0.2)}}>

                                <div className='home2_initContainer' style={{backgroundColor: shiftHasParticularity ? Colors.color.pastBorderColor : Constants.hexToRgbA(`#${available.functie_kleur}`, 0.5)}}>

                                    <span style={{color: shiftHasParticularity ? '#282828' : Constants.getContrastPlan(`#${available.functie_kleur}`)}}>{available.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                            
                                </div>

                                <div className='home2_timeContainer'>

                                    {available.dagdienst_begin.substring(0,5)} - {available.dagdienst_eind.substring(0,5)}

                                </div>

                                <div className='home2_functionContainer'>

                                    <div>{available.functie_naam}</div>

                                </div>

                                {bijz !== null ?

                                <div className='home2_afkContainer' style={{color: `#${bijz.bijz_kleur}`}}>

                                    {bijz.bijz_afkorting}

                                </div>

                                : 
                            
                                <div className='home2_afkContainer'>

                                    &nbsp;

                                </div>

                                }

                            </div>

                            </div>

                        </div> */}

                    </div>

                    {shiftIsAvailable === true ?

                        // shiftHasParticularity === true ?
                        
                        //     <div className='planner_dePublish_container_sub2'>
                        //         <span className='planner_publishMenu_deButton' style={{backgroundColor: Colors.color.pastBackgroundColor, cursor: 'default', color: '#282828'}}>
                        //             Niet van toepassing
                        //         </span>
                        //     </div>

                        // :

                            <div className='planner_dePublish_container_sub2'>
                                <span className='modal-deleteButton' style={{marginRight: 0}} onClick={() => this.putOpenShiftOnUnavailable(available)}>
                                    Ongedaan maken
                                </span>
                            </div>

                    :

                        <div className='planner_dePublish_container_sub2'>
                            <span className='modal-submitButton2' onClick={() => this.putOpenShiftOnAvailable(available)}>
                                Beschikbaar maken
                            </span>
                        </div>
                
                    }

                </div>

            );

            index++;

        }
        
    }

    return toRender;

  }

  render() {

    return (
        <div id="publishModal" className="modal" onClick={(e) => e.target === document.getElementById("zpublishModal") ? this.props._closeModal() : null}>

        {this.state.showAlert === true ?
                        
            <BetterAlert
                _closeAlert={this.closeAlert.bind(this)}
                _title={this.state.alertTitle}
                _body={this.state.alertBody}
                _buttons={this.state.alertButtons}
                _buttonText={this.state.alertButtonText}
                _buttonAction={this.state.alertButtonAction}
            />

        :

            null
                    
        }

        <div className="tutmodal2-content" style={{width: '50%'}}>
            {this.state.display === 'HOURS' ?
                <div className="tutmodal2-header" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <i className='far fa-chevron-left' style={{cursor: 'pointer', marginRight: '10%'}} onClick={() => this.setState({display: 'PUBLISH'})} />
                    <p>Details week {this.state.detailsWeek}, {this.state.detailsYear}</p>
                </div>
            :
                <div className="tutmodal2-header">
                    <span className="tutmodal2-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
                    <p>Beschikbare diensten{Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? ` - ${Data.data.chosenTeamObj.team_naam}` : null} - week {this.props._week}, {this.props._year}</p>
                </div>
            }

                <div className="tutmodal2-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                    {this.state.loading === true ?

                        <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                    :

                        <div className='tutmodal2_content' style={{width: '100%'}}>

                            <div className='tutmodal2_container'>

                                {this.state.display === 'HOURS' ?

                                    <div className='plan_modal-form'>

                                        {/* <div className='planner_publish_row' style={{padding: 0}}>

                                            <div className='planner_publish_row_sub1' style={{fontWeight: 'bold'}}>
                                                <span>Naam</span>
                                            </div>

                                            <div className='planner_publish_row_sub2' style={{fontStyle: 'normal'}}>
                                                <span>Ingeplande uren</span>
                                            </div>

                                        </div>

                                        <div className='divider' />

                                        {this.getUserHours()} */}

                                    </div>

                                :

                                    <div className='plan_modal-form' style={{paddingBottom: '5vw'}}>

                                        <span style={{fontWeight: 'bold', fontSize: '1vw'}}>Door medewerkers</span>
                                        
                                        {this.getUserAvailableShifts()}

                                        <div className='divider' style={{marginTop: '2vw', marginBottom: '2vw'}} />

                                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>

                                            <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                                                <span style={{fontWeight: 'bold', fontSize: '1vw'}}>Standaard diensten planner</span>
                                            </div>

                                            <div style={{display: 'flex', flexDirection: 'row', flex: 2, justifyContent: 'flex-end', paddingRight: '1vw'}}>
                                                <span className='modal-submitButton' onClick={() => this.putAllOpenShiftsOnAvailable()}>
                                                    Alles beschikbaar maken
                                                </span>
                                                <span className='modal-denyButton' style={{marginLeft: '1vw', marginRight: 0}} onClick={() => this.removeAllOpenShiftsAvailable()}>
                                                    Alles ongedaan maken
                                                </span>
                                            </div>

                                        </div>

                                        {this.getPlannerAvailableShifts()}

                                    </div>

                                }

                            </div>
                        
                        </div>

                    }

                    {this.state.loading === true || this.state.reloading === true ?

                        <div className='tutmodal2-bottom'>

                            <div className='tutmodal2-loadingButtonText'>
                                <div className="lds-dual-ring-button" />
                            </div>

                        </div>

                    :

                        <div className='tutmodal2-bottom'>

                            {/* {this.showError()} */}

                            {/* {this.state.total > 1 ?
                                <div style={{position: 'absolute', left: '2vw'}}>
                                    <span>Totaal: </span>
                                    <span><b>{this.state.total}</b> wijzigingen</span>
                                </div>
                            : null} */}

                            {/* {this.state.mainDepublished === false ? */}
                                {/* <div className='modal-denyButton' onClick={this.dePublishPlanner.bind(this, this.props._week, this.props._year)}>
                                    <span>Week {this.props._week} depubliceren</span>
                                </div> */}
                            {/* : null} */}

                            <div className='modal-normalButton' onClick={() => this.sendPushNotification()}>
                                <span>Verstuur melding/herinnering</span>
                            </div>

                        </div>

                    }

                </div>

        </div>

      </div>
    );
  }
  
}

export default AvailableShiftsModal;