import React from 'react';

//styles
import './registrationCode.css';
import '../registerUser/registerUser.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APICheckUsernameAvailibility from '../../../classes/APICheckUsernameAvailibility';
import APIGetFunctions from '../../../classes/APIGetFunctions';
import APIRegisterAddRegistrationCode from '../../../classes/APIRegisterAddRegistrationCode';
import APIRegisterDeleteCode from '../../../classes/APIRegisterDeleteCode';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import QRCode from 'qrcode.react';

import BetterAlert from '../../../components/alert/alert';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAllRegistrationCodes from '../../../classes/APIGetAllRegistrationCodes';

class RegistrationCode extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountDataExtra.info2_registreren !== '1')) {
        this.props.history.push("/");
    }

    // compwillmount

    this.code = this.makeCode(4);
    
    this.state = {

        loading: true,
        loading1: false,

        blockUser: false,

        showShare: false,

        username: '',

        email: '',
        phone: '',

        functionID: null,
        rights: 0,

        startDate: null,
        startDateText: '',

        showError1: false,
        errorText1: '',

        functions: [],

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        deletingCode: false,

    };

    this.navBar = React.createRef();

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountDataExtra.info2_registreren !== '1')) {
      return;
    }

    await this.getFunctions();

    this.setState({loading: false});

  }

    makeCode(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

  addedAlert() {
    this.setState({
        alertTitle: 'Gelukt',
        alertBody: `Registratiecode is aangemaakt!`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async getFunctions() {

    let functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(functionsData === null) {

        functionsData = [];

    }

    this.setState({functions: functionsData});

  }

  async allowRegistration() {
    let count = 0;

    const employeesObject = await APIGetAllAccounts.Request();

    if(employeesObject !== null) {
        count += employeesObject.length;
    }

    const registrationCodesObject = await APIGetAllRegistrationCodes.Request();

    if(registrationCodesObject !== null) {
        count += registrationCodesObject.length;
    }

    if(count >= (Data.data.maxEployees + 1)) {
        this.setState({
          alertTitle: 'Mislukt',
          alertBody: (<span>Je hebt het maximale aantal registratiecodes en medewerkers van <b>{Data.data.maxEployees}</b> bereikt</span>),
          alertButtons: 10,
          alertButtonText: ['OK'],          
          // alertButtons: 2,
          // alertButtonText: ['Terug', 'Upgrade'],
          // alertButtonAction: () => this.navBar.current.openUpgradeMenu(),
          showAlert: true,
        });
        return false;
    }

    return true;
  } 

  setStartDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }
  
      const dateObject = new Date(date);

      dateObject.setDate(dateObject.getDate() + 1);
  
      const dateText = dateObject.toISOString().substring(0, 10);
  
      this.setState({
          startDate: date,
          startDateText: dateText,
      });

  }

  async continue() {

    this.setState({loading1: true, showError1: false});

    if(await this.checkValidations1() === false || await this.allowRegistration() === false) {
        this.setState({loading1: false});
        return;
    }

    const added = await APIRegisterAddRegistrationCode.Request(
        this.code,
        this.state.email === '' ? null : this.state.email,
        this.state.phone === '' ? null : this.state.phone,
        this.state.rights,
        this.state.username === '' ? null : this.state.username,
        this.state.functionID,
        this.state.startDate === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.startDateText,
    );

    if(added === true) {

        this.addedAlert();

        this.setState({
            loading1: false,
            blockUser: true,
            showShare: true,
        });
    
        const smoothScroll = 'scrollBehavior' in document.documentElement.style;

        if(smoothScroll === true) {
            window.scrollTo({
                top: this.infoRef.offsetTop - 80,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo(0, this.infoRef.offsetTop - 80);
        }

    } else {

        this.setState({
            loading1: false,
            showError1: true,
            errorText1: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
        });

        return;

    }

  }

//   async saveAccount() {

//     this.setState({loading2: true, showError2: false});

//     if(await this.checkValidations1() === false || this.checkValidations2() === false) {
//         this.setState({loading2: false});
//         return;
//     }

//     const accountAdded = await APIRegisterAccount.Request(
//         this.state.username,
//         CryptoJS.SHA512(this.state.password2).toString(),
//         this.state.rights
//     );

//     if(accountAdded === true) {

//         const accountIDObject = await APIRegisterGetAccountID.Request(this.state.username);

//         const accountInfoAdded = await APIRegisterAccountInfo.Request(
//             this.state.username,
//             parseInt(accountIDObject[0].account_id),
//             this.state.firstName,
//             this.state.insertion,
//             this.state.lastName,
//             this.state.phone,
//             this.state.email,
//             this.state.functionID,
//             this.state.comment,
//             this.state.address,
//             this.state.addressNumber,
//             this.state.zipCode,
//             this.state.birthText
//         );

//         if(accountInfoAdded === true) {

//             this.addedAlert();

//             this.setState({
//                 loading1: false,

//                 blockUser: false,

//                 username: '',

//                 email: '',
//                 phone: '',

//                 functionID: null,
//                 rights: 0,

//                 showError1: false,
//                 errorText1: '',
//             });

//             window.scrollTo({
//                 top: 0,
//                 behavior: 'smooth',
//             });

//             return;

//         } else {

//             this.setState({
//                 loading2: false,
//                 showError2: true,
//                 errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
//             });

//             return;

//         }

//     } else {

//         this.setState({
//             loading2: false,
//             showError2: true,
//             errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
//         });

//         return;

//     }

//   }

  showError1() {

    if(this.state.showError1 === true) {
      return <div className='errorText'>{this.state.errorText1}</div>;
    } else {
      return null;
    }

  }

  async checkValidations1() {

    if(this.state.username !== '' && /^[a-zA-Z0-9]*$/.test(this.state.username) === false) {
        this.setState({
            showError1: true,
            errorText1: 'Gebruikersnaam mag alleen letters en/of cijfers bevatten',
        });
        return false;
    }

    if(this.state.username !== '' && /^[a-zA-Z0-9]*$/.test(this.state.username) === true) {
        const userNameExists = await APICheckUsernameAvailibility.Request(this.state.username);

        if(userNameExists === true) {
            this.setState({
                showError1: true,
                errorText1: 'Deze gebruikersnaam bestaat al',
            });
            return false;
        }
    }

    // eslint-disable-next-line no-useless-escape
    if(this.state.email !== '' && /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.email) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Geen geldig e-mailadres',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(this.state.phone !== '' && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phone) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Geen geldig telefoonnummer',
        });
        return false;
    }

    return true;

  }

  getFunctionBars() {

    let functions = [];

    for(let key in this.state.functions) {

        functions.push(
            <option value={this.state.functions[key].functie_id} selected={parseInt(this.state.functions[key].functie_id) === this.state.functionID}>{this.state.functions[key].functie_naam}</option>
        );

    }

    return functions;

  }

  selectFunction(event) {

    this.setState({
        functionID: isNaN(parseInt(event.target.value)) === true ? null : parseInt(event.target.value),
        showError2: false,
    });

  }

  selectRight(event) {

    this.setState({
        rights: parseInt(event.target.value),
        showError2: false,
    });

  }

  registrationCodeString(code) {

    let count = 0;

    let extraString = ' ';

    if(code[0] === 'I' || code[0] === 'l') {
        count++;
    }
    if(code[1] === 'I' || code[1] === 'l') {
        count++;
    }
    if(code[2] === 'I' || code[2] === 'l') {
        count++;
    }
    if(code[3] === 'I' || code[3] === 'l') {
        count++;
    }

    if(count > 0) {

        extraString += '(';

        for(let i = 0; i < code.length; i++) {

            const comma = (count - i) > 1;

            if(code[i] === 'I') {
                extraString += comma ? 'hoofdletter i, ' : 'hoofdletter i';
            }
            if(code[i] === 'l') {
                extraString += comma ? 'kleine letter L, ' : 'kleine letter L';
            }
        }

        extraString += ')';

    }

    return code + extraString;

}

  async goStepBack() {

    if(this.state.deletingCode === true) {
      return;
    }

    this.setState({deletingCode: true});

    const deleted = await APIRegisterDeleteCode.Request(this.code);

    if(deleted === true) {

      const smoothScroll = 'scrollBehavior' in document.documentElement.style;

      if(smoothScroll === true) {
          window.scrollTo({
              top: 0,
              behavior: 'smooth',
          });
      } else {
          window.scrollTo(0, 0);
      }

      setTimeout(() => {
        this.setState({showShare: false, blockUser: false});
      }, 1000);

    }

    this.setState({deletingCode: false});

  }

  render() {

    const functions = this.getFunctionBars();

    return (
        <div className='registrationCode'>

          <Navbar ref={this.navBar} history={this.props.history} active={'registration_code'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              4-cijferige registratiecode versturen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='registerUser_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  history={this.props.history}
                />

              :

                null
              
              }


                <div className={'registerUser_userContainer'} style={{height: '1000px'}}>

                    <div style={{fontSize: '1vw', marginBottom: 20}}>Hieronder kan een registratiecode worden aangemaakt die verstuurd kan worden via e-mail of via de mobiel met vele andere mogelijkheden. Medewerkers kunnen aan de hand van de registratiecode zelf registreren.</div>

                    <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Inloggegevens</div>

                    <div>Accountrechten: *</div>

                    <select 
                        name="Selecteer een accountrecht" 
                        className={this.state.blockUser === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                        disabled={this.state.blockUser}
                        style={{marginTop: 5}} 
                        onChange={(event) => this.selectRight(event)}
                    >
                        <option value={'0'} selected>Medewerker</option>
                        <option value={'1'}>Planner</option>
                        <option value={'2'}>Administrator</option>
                    </select>

                    <div style={{marginTop: 10}}>Functie: (optioneel)</div>

                    <select 
                        name="Selecteer een functie" 
                        className={this.state.blockUser === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                        disabled={this.state.blockUser}
                        style={{marginTop: 5}} 
                        onChange={(event) => this.selectFunction(event)}
                    >
                        <option value={null} selected>Selecteer een functie</option>
                        {functions}
                    </select>

                    <div style={{marginTop: 10}}>Gebruikersnaam: (optioneel)</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={30}
                        value={this.state.username}
                        onChange={(event) => this.setState({ username: event.target.value, showError1: false })}
                        placeholder={'Gebruikersnaam'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    <div style={{marginTop: 10}}>E-mail: (optioneel)</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={50}
                        type={'email'}
                        value={this.state.email}
                        onChange={(event) => this.setState({ email: event.target.value })}
                        placeholder={'E-mail'}
                        disabled={this.state.blockUser}
                    />

                    <div style={{marginTop: 10}}>Telefoon: (optioneel)</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={12}
                        type={'tel'}
                        value={this.state.phone}
                        onChange={(event) => this.setState({ phone: event.target.value })}
                        placeholder={'bv. 06 of 074'}
                        disabled={this.state.blockUser}
                    />

                    {this.state.blockUser === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}>Volgende</div>

                    :
                    this.state.loading1 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.continue()}>Volgende</div>

                    }

                    {this.showError1()}

                    <div style={{fontStyle: 'italic', marginTop: 10, marginBottom: 25, fontSize: '1vw'}}>(*) = verplicht</div>

                </div>

                {this.state.showShare === true ?
                
                    <div ref={(ref) => this.infoRef=ref} className={'registerUser_infoContainer'} style={{height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: 100}}>

                        <div className='submitButton' style={{marginBottom: 40}} onClick={() => this.goStepBack()}>Terug</div>

                        <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 10, alignSelf: 'flex-start'}}>De 4-cijferige registratiecode versturen</div>

                        <span style={{marginBottom: 25}}>Hieronder heb je twee mogelijkheden om de registratiecode te delen. Optie één is via een e-mail en optie twee is via de Tiemdo app.</span>

                        <div style={{alignSelf: 'flex-start'}}><b>De 4-cijferige registratiecode delen via e-mail:</b></div>

                        <div className='submitButton' style={{marginTop: 10}} onClick={() => window.open(`mailto:${this.state.email}?body=${`Ik wil graag dat je je registreert in de Tiemdo app. Gebruik "${Data.data.storeCode}" als bedrijfscode.%0A%0AJouw registratiecode: (hoofdletter gevoelig)%0A%0A${this.registrationCodeString(this.code)}%0A%0AHeb je de Tiemdo app nog niet? Download het dan via:%0A%0Awww.tiemdo.nl/download`}`)}>Verstuur e-mail</div>

                        <div style={{alignSelf: 'flex-start', marginTop: 40}}><b>De 4-cijferige registratiecode delen via een mobiel:</b></div>

                        <div style={{alignSelf: 'flex-start', marginTop: 5, marginBottom: 15, fontSize: '1vw'}}>Met deze QR-code kun je de registratiecode delen via een mobiel. Wanneer je deze QR-code scant, krijg je de mogelijkheid om de registratiecode te versturen via bijvoorbeeld WhatsApp of SMS. Aan de hand van deze 4-cijferige code kan een nieuwe medewerker zich registreren.<br /><br />Open de Tiemdo app. Navigeer naar menu en vervolgens naar 'Medewerkers registreren'. Druk op 'QR-code scannen' en scan de onderstaande QR-code.</div>

                        <QRCode 
                            value={`${this.code}`}
                            size={256}
                        />

                    </div>
                
                :

                    null
                
                }

            </div>
        
          }

        </div>
    );
  }
  
}

export default RegistrationCode;