/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './hoursRegistration.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIADD from '../../../classes/global/APIADD';

class OverhoursModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.data = this.props._modalData;
    this.closeModal = this.props._closeModal;
    
    this.state = {

        loading: false,

        startDate: null,
        startText: '',

        overhours: this.data.data.ov_aantal,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = dateObject.toISOString().substring(0, 10);

    this.setState({
        startDate: date,
        startText: dateText,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze verlofaanvraag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteHoliday.bind(this),
        showAlert: true,
    });

  }

  editAlert(accepted) {

    this.setState({
        alertTitle: accepted === true ? 'Goedkeuren' : 'Afwijzen',
        alertBody: `Weet je zeker dat je deze verlofaanvraag wilt ${accepted === true ? 'goedkeuren' : 'afwijzen'}?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', accepted === true ? 'Goedkeuren' : 'Afwijzen'],
        alertButtonAction: this.editHoliday.bind(this, accepted),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkValidations() {

    if(Constants.isEmpty(this.state.personID) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen medewerker geselecteerd`,
        });
        return false;
      }

    if(this.state.startDate === null) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen ${this.state.oneDay === true ? 'datum' : 'begindatum'} ingevoerd`,
      });
      return false;
    }
    if(this.state.endDate === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen einddatum ingevoerd',
      });
      return false;
    }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveHoliday() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    await APIADD.Request(`INSERT INTO Vakantie`, `VALUES (NULL, '${this.state.startText}', '${this.state.endText}', ${this.state.personID}, '${this.state.comment}', 1)`);

    this.reloadScreen();
    this.closeModal();

  }

  render() {

    return (
        <div id="overhoursModal" className="modal" onClick={(e) => e.target === document.getElementById("zoverhoursModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            <p>{this.data.absence === true ? 'Overuren omzetten' : 'Overuren uitbetalen'}</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Aantal:</div>

                    <input
                      className={'inputField2'}
                      onChange={(event) => this.onChangeNumberDataFloat('overhours', -999999999, 999999999, event)}
                      value={this.state.overhours}
                      placeholder={'Overuren'}
                      type='number'
                      step={0.01}
                    />

                    {this.data.absence === false ?
                        <div style={{marginTop: 5}}>Wanneer:</div>
                    : null}

                    {this.data.absence === false ?
                        <DatePicker
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(date) => this.setDate1(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'inputFieldTime'}
                            placeholderText={'Selecteer een datum'}
                            locale={nl}
                            showWeekNumbers
                            selectsStart
                        />
                    : null}

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    
                        <div className='submitButton' onClick={() => this.saveHoliday()}>Omzetten</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default OverhoursModal;