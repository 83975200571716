import moment from 'moment-timezone';
import Data from './Data';

import GlobalAPIGetTime from '../classes/GlobalAPIGetTime';
import timesModal from '../pages/options/times/timesModal';
import APIGET from '../classes/global/APIGET';
import planClass from '../pages/planner/plan.class';

import React from 'react';
import __ from './__';
import {toHexStringOfMinLength} from 'pdf-lib';

class Constants {

    // types LOGGER
    LOGGER_PLANNER = 'PLANNER';
    LOGGER_AVAILABILITY = 'AVAILABILITY';
    LOGGER_UNAVAILABILITY = 'UNAVAILABILITY';

    // changes LOGGER
    LOGGER_ADD = 'ADD';
    LOGGER_EDIT = 'EDIT';
    LOGGER_DEL = 'DEL';
    LOGGER_PUBL = 'PUBL';
    LOGGER_UNPUBL = 'UNPUBL';

    cancelTutorial() {

        Data.data.inTutorial = false;
        Data.data.tutorialStep = 0;

        localStorage.setItem('@doneTutorial', '1');

    }

    arrayEquals(array, array2) {
        // if the other array is a falsy value, return
        if (!array2) {
            return false;
        }

        // compare lengths - can save a lot of time 
        if (array.length != array2.length) {
            return false;
        }

        for (var i = 0, l = array.length; i < l; i++) {
            // Check if we have nested arrays
            if (array[i] instanceof Array && array2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!this.arrayEquals(array[i], array2[i])) {
                    return false;
                }
            } else if (array[i] != array2[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    }

    arrayContains(array, item) {

        let i;

        for (i = 0; i < array.length; i++) {

            if (array[i] === item) {

                return true;

            }

        }

        return false;
    }

    objectArrayContains(array, item) {

        if (typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            for (let key in array[i]) {

                if (array[i][key] === item) {
                    return true;
                }

            }

        }

        return false;
    }

    objectArrayContainsKey(array, key, item) {

        if (typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if (typeof array[i] === 'undefined') {
                return false;
            }

            if (array[i][key] === item) {
                return true;
            }

        }

        return false;
    }

    objectArrayContainsKey2(array, key, item, key2, item2) {

        if (typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if (typeof array[i] === 'undefined') {
                return false;
            }

            if (array[i][key] === item && array[i][key2] === item2) {
                return true;
            }

        }

        return false;
    }

    getArrayItem(array, key, item) {

        if (typeof array === 'undefined') {
            return null;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if (typeof array[i] === 'undefined') {
                return null;
            }

            if (array[i][key] === item) {
                return array[i];
            }

        }

        return null;
    }

    getArrayItem2(array, key, item, key2, item2) {

        if (typeof array === 'undefined') {
            return null;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if (typeof array[i] === 'undefined') {
                return null;
            }

            if (array[i][key] === item && array[i][key2] === item2) {
                return array[i];
            }

        }

        return null;
    }

    defaultAlert(parent, title, body) {

        parent.setState({
            alertTitle: title,
            alertBody: body,
            alertButtons: 1,
            alertButtonText: ['OK'],
            alertButtonAction: null,
            showAlert: true,
        });

    }

    removeFromArray(array, item) {

        let index = array.indexOf(item);

        if (index > -1) {

            array.splice(index, 1);

        }

    }

    removeFromArray2(array, key, item) {

        if (typeof array === 'undefined') {
            return;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if (typeof array[i] === 'undefined') {
                return;
            }

            if (array[i][key] === item) {

                const index = array.indexOf(array[i]);

                if (index > -1) {
                    array.splice(index, 1);
                }

            }

        }

    }

    sortNumber(a, b) {

        return a - b;

    }

    // async getDateNow() {
    //     const string = await GlobalAPIGetTime.Request();
    //     if (typeof string !== 'string') {
    //         return new Date();
    //     }
    //     const splitted = string.split('T');
    //     return this.stringToDateTime(splitted[0], splitted[1]);
    // }
    async getDateNow(timezone = Data.data.storeTimezone) {
        return moment(await GlobalAPIGetTime.Request(timezone)).utc(Data.data.utcOffset).toDate();
    }

    async getDateNow2(timezone = Data.data.storeTimezone) {
        const now = await GlobalAPIGetTime.Request(timezone);
        const splitted = now.split('T');
        return this.stringToDateTime(splitted[0], splitted[1].substr(0, 8));
    }

    getDateNowStatic() {
        return moment().utc(Data.data.utcOffset).toDate();
    }

    getDate(date, time) {
        return moment(`${date}T${time}`).toDate();
    }

    getDateTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).toDate();
    }

    getDateTZD(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).toDate();
    }

    getDateTZMS(ms) {
        return moment(ms).utc(Data.data.utcOffset).toDate();
    }

    getMonthTZ(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).month();
        //return moment(`${date}`).tz('Europe/Amsterdam').month();
    }

    getHoursTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).hours();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').hours();
    }

    getMinutesTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).minutes();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').minutes();
    }

    getSecondsTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).seconds();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').seconds();
    }

    getHoursTZD(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).hours();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').hours();
    }

    getMinutesTZD(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).minutes();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').minutes();
    }

    getSpecificDate(dateString) {
        return new Date(Date.parse(dateString));
    }

    formatDate(date) {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    stringToDate(dateString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        const [yyyy, mm, dd] = dateString.split("-");
        return new Date(yyyy, parseInt(mm) - 1, dd);
    };

    stringToDateTime(dateString, timeString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        if (typeof timeString !== 'string') {
            return new Date();
        }
        const [yyyy, mm, dd] = dateString.split("-");
        const [hh, MM, ss = 0] = timeString.split(":");
        return new Date(yyyy, parseInt(mm) - 1, dd, hh, MM, ss, 0);
    };

    dateToString(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    dateToStringUTC(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let month = '' + (date.getUTCMonth() + 1);
        let day = '' + date.getUTCDate();
        let year = date.getUTCFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    getAge(now, birthday) { // birthday is a date
        const ageDifMs = now.getTime() - birthday.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getDayName(date, lower = false) {

        const dayNumber = new Date(date).getDay();

        const weekday = new Array(7);
        weekday[0] = __('misc_sunday');
        weekday[1] = __('misc_monday');
        weekday[2] = __('misc_tuesday');
        weekday[3] = __('misc_wednesday');
        weekday[4] = __('misc_thursday');
        weekday[5] = __('misc_friday');
        weekday[6] = __('misc_saturday');

        if (lower) {
            return weekday[dayNumber].toLowerCase();
        }

        return weekday[dayNumber];

    }

    getShortDayName(date, lower = false) {

        const dayNumber = new Date(date).getDay();

        const weekday = new Array(7);
        weekday[0] = __('misc_sunday_short');
        weekday[1] = __('misc_monday_short');
        weekday[2] = __('misc_tuesday_short');
        weekday[3] = __('misc_wednesday_short');
        weekday[4] = __('misc_thursday_short');
        weekday[5] = __('misc_friday_short');
        weekday[6] = __('misc_saturday_short');

        if (lower) {
            return weekday[dayNumber].toLowerCase();
        }

        return weekday[dayNumber];

    }

    getMonthName(date, lower = false) {

        const month = new Date(date).getMonth();

        const monthNames = [__('misc_january'), __('misc_february'), __('misc_march'),
            __('misc_april'), __('misc_may'), __('misc_june'), __('misc_july'),
            __('misc_august'), __('misc_september'), __('misc_october'), __('misc_november'),
            __('misc_december')
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getMonthNameM(month, lower = false) {

        const monthNames = [__('misc_january'), __('misc_february'), __('misc_march'),
            __('misc_april'), __('misc_may'), __('misc_june'), __('misc_july'),
            __('misc_august'), __('misc_september'), __('misc_october'), __('misc_november'),
            __('misc_december')
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getShortMonthNameM(month, lower = false) {

        const monthNames = [__('misc_january_short'), __('misc_february_short'), __('misc_march_short'),
            __('misc_april_short'), __('misc_may_short'), __('misc_june_short'), __('misc_july_short'),
            __('misc_august_short'), __('misc_september_short'), __('misc_october_short'), __('misc_november_short'),
            __('misc_december_short')
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getShortMonthName(date, lower = false) {

        const month = new Date(date).getMonth();

        const monthNames = [__('misc_january_short'), __('misc_february_short'), __('misc_march_short'),
            __('misc_april_short'), __('misc_may_short'), __('misc_june_short'), __('misc_july_short'),
            __('misc_august_short'), __('misc_september_short'), __('misc_october_short'), __('misc_november_short'),
            __('misc_december_short')
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    getDaysInMonth2(date) {

        const date2 = new Date(date);
        date2.setDate(0);

        return date2.getDate();

    }

    convertDateToUTC(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    roundUp(num, X) {
        return +(Math.round(num + "e+" + X) + "e-" + X);
    }

    hourAmPm(dateInput) {

        let d = new Date(dateInput);
        const ampm = (d.getHours() >= 12) ? "PM" : "AM";
        const hours = (d.getHours() >= 12) ? d.getHours() - 12 : d.getHours();

        return (hours < 10 ? '0' + hours : hours) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) + ' ' + ampm;

    }

    // msToTime2(ms) {
    //     const milliseconds = ms % 1000
    //     const seconds = Math.floor((ms / 1000) % 60)
    //     const minutes = Math.floor((ms / (60 * 1000)) % 60)
    //     const hours = Math.floor((ms / (3600 * 1000)) % 3600)
    //     return `${hours < 10 ? '0' + hours.toString() : hours.toString()}:${minutes < 10 ? '0' + minutes.toString() : minutes.toString()}:${
    //       seconds < 10 ? '0' + seconds.toString() : seconds.toString()
    //     }.${milliseconds}`
    // }

    msToTime(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }

        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
    }

    msToTime2(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }

        let hours = parseInt(s / 1000 / 60 / 60);
        let minutes = this.roundUp(((s / 1000 / 60 / 60) - hours), 2);
        minutes = this.roundUp(((minutes / 100) * 60), 2);
        minutes = minutes === 0 ? parseInt(minutes * 100) : parseInt((minutes + Number.EPSILON) * 100);

        // if (hours < 0) {
        //     hours = 0;
        // }

        // if (minutes < 0) {
        //     minutes = 0;
        // }

        if (s > -60000 && s < 60000) {
            hours = 0;
            minutes = 0;
        }

        if (minutes === 60) {
            hours += 1;
            minutes = 0;
        }

        if (isNaN(hours)) {
            hours = 0;
        }
        if (isNaN(minutes)) {
            minutes = 0;
        }

        // var ms = s % 1000;
        // s = (s - ms) / 1000;
        // var secs = s % 60;
        // s = (s - secs) / 60;
        // var mins = s % 60;
        // var hrs = (s - mins) / 60;

        return (hours < 10 ? pad(hours) : hours) + ':' + pad(minutes);
    }

    msToTimeClock(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }

        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return (hrs < 10 ? pad(hrs) : hrs) + ':' + pad(mins) + ':' + pad(secs);
    }

    timeStringToFloat(time, r = true) {
        if (time === null) {
            return NaN;
        }
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0]);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
        return r ? Math.round((hoursMins + Number.EPSILON) * 100) / 100 : hoursMins;
    }

    timeStringToMS(time) {
        if (time === null) {
            return NaN;
        }
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0]);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
        return hoursMins * 1000 * 60 * 60;
    }

    firstUppercase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    isTablet() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return isTablet;
    }

    round(num, fixed = false, digits = 2) {
        if (fixed === true) {
            return (Math.round((num + Number.EPSILON) * 100) / 100).toLocaleString(this.getLang(), {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits
            });
        } else {
            return Math.round((num + Number.EPSILON) * 100) / 100;
        }
    }

    digitsToLocale(num, digits = 2) {
        return num.toLocaleString(this.getLang(), {minimumFractionDigits: digits, maximumFractionDigits: digits});
    }

    roundBig(value, decimals) {
        return Number(Math.round(value + Number.EPSILON + 'e' + decimals) + 'e-' + decimals);
    }

    generateString(length = 6) {
        var result = '';
        var characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getLang() {
        try {
            return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
        } catch (error) {
            return 'en';
        }
    }

    getContrast(hexcolor) {

        if (hexcolor === null) {
            return 'black';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0, 2), 16);
        const g = parseInt(hexcolor.substr(2, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);

        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return (yiq >= 140) ? 'black' : 'white';

    };

    getContrastPlan(hexcolor) {

        if (hexcolor === null) {
            return 'black';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0, 2), 16);
        const g = parseInt(hexcolor.substr(2, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);

        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return (yiq >= 50) ? '#282828' : 'white';

    };

    getContrastHex(hexcolor) {

        if (hexcolor === null) {
            return '000000';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0, 2), 16);
        const g = parseInt(hexcolor.substr(2, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);

        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return (yiq >= 140) ? '000000' : 'FFFFFF';

    };

    hexToRgbA(hex, opacity) {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
        }
        throw new Error('Bad Hex');
    }

    convertToObject(array, key) {

        let obj = {};

        for (const i in array) {

            obj[array[i][key]] = array[i];

        }

        return obj;

    }

    convertToObjectPrefix(array, key, prefix) {

        let obj = {};

        for (const i in array) {

            obj[prefix + array[i][key]] = array[i];

        }

        return obj;

    }

    convertToObjectWithArrays(array, key) {

        let obj = {};

        for (const i in array) {

            if (typeof obj[array[i][key]] === 'undefined') {
                obj[array[i][key]] = [];
            }

            obj[array[i][key]].push(array[i]);

        }

        return obj;

    }

    convertToObjectWithArraysPrefix(array, key, prefix) {

        let obj = {};

        for (const i in array) {

            if (typeof obj[prefix + array[i][key]] === 'undefined') {
                obj[prefix + array[i][key]] = [];
            }

            obj[prefix + array[i][key]].push(array[i]);

        }

        return obj;

    }

    getTeamDataChosen() {

        for (const team of Data.data.teams) {

            if (parseInt(team.team_id) === Data.data.chosenTeam) {
                return {
                    name: team.team_naam,
                    phone: team.team_telefoon
                };
            }

        }

    }

    getTeamNameFromTeamID(teamID) {

        for (const team of Data.data.teams) {

            if (team.team_id === teamID) {
                return team.team_naam;
            }

        }

        return '';

    }

    getUserIsInTeam(teamUsers, userID, teamID) {

        let found = false;

        for (const user of teamUsers) {

            if (user.teamu_info_id === userID && teamID === user.teamu_team_id) {
                found = true;
                break;
            }

        }

        return found;

    }

    getTeamIDFromUserID(users, userID) { // needs rework to array (user can have more teams)

        let teamID = null;

        for (const teamUser of users) {

            if (teamUser.teamu_info_id === userID) {

                teamID = teamUser.teamu_team_id;
                break;

            }

        }

        return teamID;//

    }

    getTeamNameFromUserID(users, userID) {

        let teamID = [];

        for (const schedule of users) {

            if (schedule.teamu_info_id === userID) {

                teamID.push(schedule.teamu_team_id);

            }

        }

        let names = [];

        if (teamID !== null && teamID.length > 0) {

            for (const team of Data.data.teams) {

                for (const id of teamID) {

                    if (team.team_id === id) {
                        names.push(team.team_naam);
                    }

                }

            }

        }

        if (names.length > 0) {
            return names;
        } else {
            return '';
        }

    }

    getTeamNameFromScheduleID(schedules, scheduleID) {

        let teamID = null;

        for (const schedule of schedules) {

            if (schedule.teamr_rooster_id === scheduleID) {

                teamID = schedule.teamr_team_id;
                break;

            }

        }

        if (teamID !== null) {

            for (const team of Data.data.teams) {

                if (team.team_id === teamID) {
                    return team.team_naam;
                }

            }

        }

        return '';

    }

    getTeamIDFromScheduleID(schedules, scheduleID) {

        for (const schedule of schedules) {

            if (schedule.teamr_rooster_id === scheduleID) {

                return schedule.teamr_team_id;

            }

        }

        return null;

    }

    getTeamAccounts(teamUsers, allAccounts) {

        if (allAccounts === null) {
            return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamUsers) {
            if (parseInt(teamAcc.teamu_team_id) === Data.data.chosenTeam) {
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allAccounts) {

            for (const teamAcc of teamAccounts) {

                if (acc.info_id === teamAcc.teamu_info_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

    }

    getTeamAccountsContract(teamUsers, allAccounts) {

        if (allAccounts === null) {
            return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamUsers) {
            if (parseInt(teamAcc.teamu_team_id) === Data.data.chosenTeam) {
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allAccounts) {

            for (const teamAcc of teamAccounts) {

                if (acc.contract_info_id === teamAcc.teamu_info_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

    }

    getTeamAccountsT(teamUsers, allAccounts, team) {

        if (allAccounts === null) {
            return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamUsers) {
            if (parseInt(teamAcc.teamu_team_id) === team) {
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allAccounts) {

            for (const teamAcc of teamAccounts) {

                if (acc.account_id === teamAcc.teamu_info_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

    }

    getTeamPlanner(teamPlanning, allPlannings) {

        if (allPlannings === null) {
            return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamPlanning) {
            if (parseInt(teamAcc.teamr_team_id) === Data.data.chosenTeam) {
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allPlannings) {

            for (const teamAcc of teamAccounts) {

                if (acc.rooster_id === teamAcc.teamr_rooster_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

    }

    getTeamPlannerT(teamPlanning, allPlannings, team) {

        if (allPlannings === null) {
            return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamPlanning) {
            if (parseInt(teamAcc.teamr_team_id) === team) {
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allPlannings) {

            for (const teamAcc of teamAccounts) {

                if (acc.rooster_id === teamAcc.teamr_rooster_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

    }

    getTeamPlannerT2(teamPlanning, allPlannings, team) {

        if (allPlannings === null) {
            return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamPlanning) {
            if (parseInt(teamAcc.teamr_team_id) === team) {
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allPlannings) {

            for (const teamAcc of teamAccounts) {

                if (acc.rs_rooster_id_to === teamAcc.teamr_rooster_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

    }

    getMissingPeopleTeam(people, teamUsers) {

        let missing = [];

        for (const user of people) {

            let found = false;

            for (const teamUser of teamUsers) {

                if (user.info_id === teamUser.teamu_info_id) {
                    found = true;
                    break;
                }

            }

            if (found === false) {
                missing.push(user);
            }

        }

        return missing;

    }

    getMissingJobPeople(people) {

        let missing = [];

        for (const user of people) {

            if (user.account_rights === '2') {
                continue;
            }

            if (this.isEmpty(user.functie_id)) {
                missing.push(user);
            }

        }

        return missing;

    }

    charCount(string, word) {
        var length = typeof string === "string" && typeof word === "string" && word.length,
            loop = length,
            index = 0,
            count = 0;

        while (loop) {
            index = string.indexOf(word, index);
            if (index !== -1) {
                count += 1;
                index += length;
            } else {
                loop = false;
            }
        }

        return count;
    }

    stringDiffer(a, b) {
        var i = 0;
        var j = 0;
        var result = "";

        while (j < b.length) {
            if (a[i] != b[j] || i == a.length)
                result += b[j];
            else
                i++;
            j++;
        }
        return result.toString();
    }

    isTrue(data) {

        return data === true || data === '1';

    }

    isEmpty(data) {
        return typeof data === 'undefined' || data === undefined || data === null || data === '' || data === 'NULL' || data === 'null' || data === 'undefined' || data === '0000-00-00' || (typeof data === 'string' && data === 'error') || (Array.isArray(data) === true && data.length === 0);
    }

    personWorksDay(data, day) {
        if (typeof data === 'undefined') {
            return false;
        }

        return day === 0 && data.info2_werkdag_zondag === '1'
            || day === 1 && data.info2_werkdag_maandag === '1'
            || day === 2 && data.info2_werkdag_dinsdag === '1'
            || day === 3 && data.info2_werkdag_woensdag === '1'
            || day === 4 && data.info2_werkdag_donderdag === '1'
            || day === 5 && data.info2_werkdag_vrijdag === '1'
            || day === 6 && data.info2_werkdag_zaterdag === '1';
    }

    personHasWorkDays(data) {
        if (typeof data === 'undefined') {
            return false;
        } else if (this.isEmpty(data.info2_werkdag_maandag) === true && this.isEmpty(data.info2_werkdag_dinsdag) === true && this.isEmpty(data.info2_werkdag_woensdag) === true && this.isEmpty(data.info2_werkdag_donderdag) === true && this.isEmpty(data.info2_werkdag_vrijdag) === true && this.isEmpty(data.info2_werkdag_zaterdag) === true && this.isEmpty(data.info2_werkdag_zondag) === true) {
            return false;
        } else if (data.info2_werkdag_maandag === '0' && data.info2_werkdag_dinsdag === '0' && data.info2_werkdag_woensdag === '0' && data.info2_werkdag_donderdag === '0' && data.info2_werkdag_vrijdag === '0' && data.info2_werkdag_zaterdag === '0' && data.info2_werkdag_zondag === '0') {
            return false;
        } else {
            return true;
        }
    }

    hasGeneralWorkDays() {
        const data = Data.data.appOptions;
        if (typeof data === 'undefined') {
            return false;
        } else if (data.opties_verlof_maandag === '0' && data.opties_verlof_dinsdag === '0' && data.opties_verlof_woensdag === '0' && data.opties_verlof_donderdag === '0' && data.opties_verlof_vrijdag === '0' && data.opties_verlof_zaterdag === '0' && data.opties_verlof_zondag === '0') {
            return false;
        } else {
            return true;
        }
    }

    getRoundedDate(minutes, d = this.getDateNowStatic()) {

        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

        return roundedDate;

    }

    endFirstWeek(firstDate, firstDay) {
        if (!firstDay) {
            return 7 - firstDate.getDay();
        }
        if (firstDate.getDay() < firstDay) {
            return firstDay - firstDate.getDay();
        } else {
            return 7 - firstDate.getDay() + firstDay;
        }
    }

    getWeeksStartAndEndInMonth(month, year) {
        let weeks = [],
            firstDate = new Date(year, month, 1),
            lastDate = new Date(year, month + 1, 0),
            numDays = this.getDaysInMonth(month + 1, year);

        let start = 1;
        let end = this.endFirstWeek(firstDate, 2);
        while (start <= numDays) {
            if (start === 1 && end === 1) {
                start = end + 1;
                end = end + 7;
                end = start === 1 && end === 8 ? 1 : end;
                if (end > numDays) {
                    end = numDays;
                }
                continue;
            }
            if (start - 1 !== end) {
                const week = this.getWeekNumber2(new Date(year, month, start));
                weeks.push({
                    week: week,
                    month: month,
                    year: year,
                    year1: this.getBeginOfWeek(new Date(year, month, start)).getFullYear(),
                    start: start === 1 ? start : start - 1,
                    end: end === this.getDaysInMonth(month + 1, year) ? end : end - 1
                });
            }
            start = end + 1;
            end = end + 7;
            end = start === 1 && end === 8 ? 1 : end;
            if (end > numDays) {
                end = numDays;
            }
        }
        return weeks;
    }

    getBeginOfWeek(date = new Date(), startOfWeek = 1) {
        const dayDate = date.getDay() === 0 ? 7 : date.getDay();
        const dayStartOfWeek = startOfWeek === 0 ? 7 : startOfWeek;
        const result = new Date(date);
        while (result.getDay() !== startOfWeek) {
            if (dayDate > dayStartOfWeek) {
                result.setDate(result.getDate() - 1);
            } else {
                result.setDate(result.getDate() + 1);
            }
        }
        return result;
    }

    getWeekNumber() {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    getWeekNumber2(today) {
        let date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    getWeekYear() {
        let date = new Date();
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
    }

    getWeekYear2(today) {
        let date = today;
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
    }

    weeksInYear(year) {
        let month = 11, day = 31, week = 0;

        // Find week that 31 Dec is in. If is first week, reduce date until
        // get previous week.
        do {
            const d = new Date(year, month, day--);
            week = this.alt(d)[1];
        } while (week === 1);

        return week;
    }

    alt(d) {
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        var yearStart = new Date(d.getFullYear(), 0, 1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        // Return array of year and week number
        return [d.getFullYear(), weekNo];
    }

    //   getMonday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').startOf('isoWeek').utc(Data.data.utcOffset).toDate();
    //   }

    //   getTuesday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 1);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(2).utc(Data.data.utcOffset).toDate();
    //   }

    //   getWednesday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 2);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(3).utc(Data.data.utcOffset).toDate();
    //   }

    //   getThursday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 3);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(4).utc(Data.data.utcOffset).toDate();
    //   }

    //   getFriday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 4);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(5).utc(Data.data.utcOffset).toDate();
    //   }

    //   getSaturday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 5);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(6).utc(Data.data.utcOffset).toDate();
    //   }

    //   getSunday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 6);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').endOf('isoWeek').utc(Data.data.utcOffset).toDate();
    //   }

    getMonday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        return ISOweekStart;
    }

    getTuesday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 1);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 1);
        return ISOweekStart;
    }

    getWednesday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 2);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 2);
        return ISOweekStart;
    }

    getThursday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 3);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 3);
        return ISOweekStart;
    }

    getFriday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 4);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 4);
        return ISOweekStart;
    }

    getSaturday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 5);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 5);
        return ISOweekStart;
    }

    getSunday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 6);
        return ISOweekStart;
    }

    getDateWeekday(day, w, y) {

        let weekDate = new Date();

        if (day === 1) {
            weekDate = this.getMonday(w, y);
        } else if (day === 2) {
            weekDate = this.getTuesday(w, y);
        } else if (day === 3) {
            weekDate = this.getWednesday(w, y);
        } else if (day === 4) {
            weekDate = this.getThursday(w, y);
        } else if (day === 5) {
            weekDate = this.getFriday(w, y);
        } else if (day === 6) {
            weekDate = this.getSaturday(w, y);
        } else if (day === 0) {
            weekDate = this.getSunday(w, y);
        }

        return weekDate;

    }

    dateInWeek(date, w, y) {
        const dateObj = this.stringToDate(date);

        const monday = this.getMonday(w, y);
        const tuesday = this.getTuesday(w, y);
        const wednesday = this.getWednesday(w, y);
        const thursday = this.getThursday(w, y);
        const friday = this.getFriday(w, y);
        const saturday = this.getSaturday(w, y);
        const sunday = this.getSunday(w, y);

        if ((dateObj.getDate() === monday.getDate() && dateObj.getMonth() === monday.getMonth() && dateObj.getFullYear() === monday.getFullYear())
            || (dateObj.getDate() === tuesday.getDate() && dateObj.getMonth() === tuesday.getMonth() && dateObj.getFullYear() === tuesday.getFullYear())
            || (dateObj.getDate() === wednesday.getDate() && dateObj.getMonth() === wednesday.getMonth() && dateObj.getFullYear() === wednesday.getFullYear())
            || (dateObj.getDate() === thursday.getDate() && dateObj.getMonth() === thursday.getMonth() && dateObj.getFullYear() === thursday.getFullYear())
            || (dateObj.getDate() === friday.getDate() && dateObj.getMonth() === friday.getMonth() && dateObj.getFullYear() === friday.getFullYear())
            || (dateObj.getDate() === saturday.getDate() && dateObj.getMonth() === saturday.getMonth() && dateObj.getFullYear() === saturday.getFullYear())
            || (dateObj.getDate() === sunday.getDate() && dateObj.getMonth() === sunday.getMonth() && dateObj.getFullYear() === sunday.getFullYear())) {
            return true;
        }

        return false;
    }

    toISOString(date) {

        if (date === null || date === undefined || typeof date === 'undefined') {
            return null;
        }

        return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;

    }

    getDaysInYear(year) {

        return this.isLeapYear(year) ? 366 : 365;

    }

    isLeapYear(year) {
        return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    }

    isValidDateString(dateString) {

        return typeof dateString !== 'undefined' && dateString !== null && dateString !== 'null' && dateString !== 'NULL' && dateString !== '' && dateString !== '0000-00-00';

    }

    chunkArray(arr, size, out) {
        out = out || [];
        if (!arr.length) return out;
        out.push(arr.slice(0, size));
        return this.chunkArray(arr.slice(size), size, out);
    }

    checkDuplicatesArray(arr, key, remove) {

        let hasDupes = false;

        for (let i = 0; i < arr.length - 1; i++) {

            for (let j = i + 1; j < arr.length; j++) {

                if (arr[i][key] == arr[j][key]) {

                    if (remove === true) {
                        this.removeFromArray(arr, arr[i]);
                    }

                    hasDupes = true;

                }

            }

        }

        return hasDupes;

    }

    daysUntill(date, today = new Date()) {

        //Set current year or the next year if you already had birthday this year
        date.setFullYear(today.getFullYear());
        if (today > date) {
            date.setFullYear(today.getFullYear() + 1);
        }

        //Calculate difference between days
        return Math.floor((date - today) / (1000 * 60 * 60 * 24));

    }

    sortArray(a, b, key, DESC) {
        return (a[key] > b[key]) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray2(a, b, key, key2, DESC) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && (a[key2] > b[key2])) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray3(a, b, key, key2, key3, DESC) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray3nameASC(a, b, key, key2, key3) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1;
    }

    sortArray3nameDESC(a, b, key, key2, key3) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] < b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? -1 : 1;
    }

    calcPercentInt(first, second) {

        return this.round((first / second) * 100) === Infinity ? 0 : this.round((first / second) * 100);

    }

    addSlashes(string) {
        return string.replace(/\\/g, '\\\\').replace(/\u0008/g, '\\b').replace(/\t/g, '\\t').replace(/\n/g, '\\n').replace(/\f/g, '\\f').replace(/\r/g, '\\r').replace(/'/g, '\\\'').replace(/"/g, '\\"');
    }

    async urltoFile(url, filename, mimeType) {
        return (await fetch(url)
                .then(function (res) {
                    return res.arrayBuffer();
                })
                .then(function (buf) {
                    return new File([buf], filename, {type: mimeType});
                })
        );
    }

    async blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    getUserPlannerBreak(id, date, schedules) {

        let breakTime = '00:00';

        for (const schedule of schedules) {

            if (schedule.info_id === id && schedule.rooster_datum === date) {

                if (breakTime !== '00:00') {

                    const oldBreakHours = this.getHoursTZ(schedule.rooster_datum, breakTime);
                    const oldBreakMinutes = this.getMinutesTZ(schedule.rooster_datum, breakTime);
                    const breakHours = this.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
                    const breakMinutes = this.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

                    const totalBreakHours = oldBreakHours + breakHours;
                    const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

                    const newBreakDateAndTime = this.getDateTZ(schedule.rooster_datum, breakTime);
                    newBreakDateAndTime.setTime((totalBreakHours * 60 * 60 * 1000) + (totalBreakMinutes * 60 * 1000));

                    const newBreakHours = newBreakDateAndTime.getUTCHours();
                    const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

                    breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

                } else {

                    breakTime = schedule.rooster_pauze.substring(0, 5);

                }

            }

        }

        return breakTime;

    }

    getBreakRule(startDate, startTime, endDate, endTime, schedule = null, breakRules) {

        const start = this.stringToDateTime(startDate, startTime);
        const end = this.stringToDateTime(schedule !== null ? planClass.isNightShift2(schedule) : endDate, endTime);

        const totalMS = end.getTime() - start.getTime();
        const totalHours = totalMS / 1000 / 60 / 60;

        let brk = 0;
        for (const br of breakRules) {

            if (totalHours >= parseFloat(br.pr_uren)) {
                brk = parseInt(br.pr_pauze);
            }

        }

        if (brk > 0) {
            brk = brk * 60 * 1000;
        }

        return {
            ms: brk,
            str: this.msToTime(brk),
        };

    }

    weeksBetween(d1, d2) {
        return Math.floor(Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000)));
    }

    getCurrentContract(contracts, dateNow = new Date()) {
        if (this.isEmpty(contracts)) {
            return null;
        }

        if (typeof dateNow === 'string') {
            dateNow = this.stringToDate(dateNow);
        }

        dateNow = dateNow || new Date();

        for (const c of contracts) {
            const contractStart = this.isEmpty(c.contract_begin) ? null : this.stringToDate(c.contract_begin);
            const contractEnd = this.isEmpty(c.contract_eind) ? null : this.stringToDate(c.contract_eind);

            if (!contractStart && !contractEnd) {
                return c;
            }

            if (contractStart && !contractEnd && contractStart.getTime() <= dateNow.getTime()) {
                return c;
            }

            if (!contractStart && contractEnd && contractEnd.getTime() >= dateNow.getTime()) {
                return c;
            }

            if (contractStart && contractEnd &&
                contractStart.getTime() <= dateNow.getTime() &&
                contractEnd.getTime() >= dateNow.getTime()) {
                return c;
            }
        }

        return null;
    }

    getCurrentContractWeek(contracts, week, year) {

        let contract = null;

        if (this.isEmpty(contracts)) {
            return null;
        }

        for (const c of contracts) {

            if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === true) {

                contract = c;
                break;

            } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === true) {

                const contractStart = this.stringToDate(c.contract_begin);

                if (contractStart.getFullYear() < year || (this.getWeekNumber2(contractStart) <= week && contractStart.getFullYear() === year)) {
                    contract = c;
                    break;
                }

            } else if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === false) {

                const contractEnd = this.stringToDate(c.contract_eind);

                if (contractEnd.getFullYear() > year || (this.getWeekNumber2(contractEnd) >= week && contractEnd.getFullYear() === year)) {
                    contract = c;
                    break;
                }

            } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === false) {

                const contractStart = this.stringToDate(c.contract_begin);
                const contractEnd = this.stringToDate(c.contract_eind);

                if ((contractStart.getFullYear() !== year && contractEnd.getFullYear() !== year && year > contractStart.getFullYear() && year < contractEnd.getFullYear())
                    || ((contractStart.getFullYear() + this.getWeekNumber2(contractStart)) <= (year + week) && (contractEnd.getFullYear() + this.getWeekNumber2(contractEnd)) >= (year + week))) {
                    contract = c;
                    break;
                }

            }

        }

        return contract;

    }

    getAnyContract_type(contracts, type) {

        if (type === '0') {

            if (this.isEmpty(contracts)) {
                return true;
            }

        } else {

            if (this.isEmpty(contracts)) {
                return false;
            }

        }

        for (const c of contracts) {

            if (type === '0') {

                if (c.contract_type === type || this.isEmpty(c.contract_type)) {
                    return true;
                }

            } else {

                if (c.contract_type === type) {
                    return true;
                }

            }

        }

        return false;

    }

    getAnyContract_leaveBalance(contracts) {

        if (this.isEmpty(contracts)) {
            return false;
        }

        for (const c of contracts) {

            if (c.contract_opbouw_verlof === '1') {
                return true;
            }

        }

        return false;

    }

    // getContractPeriod(contracts, start, end) {

    //     let contract = null;

    //     if (typeof start === 'string') {
    //         start = this.stringToDate(start);
    //     }
    //     if (typeof end === 'string') {
    //         end = this.stringToDate(end);
    //     }

    //     for (const c of contracts) {

    //         if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === true) {

    //             contract = c;
    //             break;

    //         } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === true) {

    //             const contractStart = this.stringToDate(c.contract_begin);

    //             if (contractStart.getTime() <= date.getTime()) {
    //                 contract = c;
    //                 break;
    //             }

    //         } else if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === false) {

    //             const contractEnd = this.stringToDate(c.contract_eind);

    //             if (contractEnd.getTime() >= date.getTime()) {
    //                 contract = c;
    //                 break;
    //             }

    //         } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === false) {

    //             const contractStart = this.stringToDate(c.contract_begin);
    //             const contractEnd = this.stringToDate(c.contract_eind);

    //             if (contractStart.getTime() <= date.getTime() && contractEnd.getTime() >= date.getTime()) {
    //                 contract = c;
    //                 break;
    //             }

    //         }

    //     }

    //     return contract;

    // }

    getWorkDaysPersonWeek(id, infoExtra) {

        let count = 0;

        if (typeof infoExtra[id] === 'undefined') {
            return 0;
        }

        if (infoExtra[id].info2_werkdag_maandag === '1') {
            count++;
        }
        if (infoExtra[id].info2_werkdag_dinsdag === '1') {
            count++;
        }
        if (infoExtra[id].info2_werkdag_woensdag === '1') {
            count++;
        }
        if (infoExtra[id].info2_werkdag_donderdag === '1') {
            count++;
        }
        if (infoExtra[id].info2_werkdag_vrijdag === '1') {
            count++;
        }
        if (infoExtra[id].info2_werkdag_zaterdag === '1') {
            count++;
        }
        if (infoExtra[id].info2_werkdag_zondag === '1') {
            count++;
        }

        return count;

    }

    calcAbsAmountHoliday(_week, year, totalWorkedWeek, absAmount2, contracts, _infoExtra) {

        let absence = [];
        let totalAbsence = 0;
        let fullWeekHours = 0;
        let absWeek = {};
        const chosenWeek = parseInt(_week);

        // ABSAMOUNT
        // if (this.isEmpty(absAmount2[year]) === false && this.isEmpty(absAmount2[year][chosenWeek.toString()]) === false) {
        if (this.isEmpty(absAmount2[year]) === false) {

            for (const weekKey in absAmount2[year]) {

                let workDayHours = this.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : this.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

                for (const abs of absAmount2[year][weekKey]) {

                    const absStart = this.stringToDate(abs.vakantie_begin);
                    const absEnd = this.stringToDate(abs.vakantie_eind);
                    const sameDay = abs.vakantie_begin === abs.vakantie_eind;

                    const contract = this.getCurrentContract(contracts, absStart) === null ? this.getCurrentContract(contracts, absEnd) : this.getCurrentContract(contracts, absStart);

                    if (this.isEmpty(contract) === true) {
                        continue;
                    }

                    if (typeof absWeek[weekKey] === 'undefined') {
                        absWeek[weekKey] = 0;
                    }

                    // SET NEW WORKDAYHOURS LIKE CONTRACT
                    workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                    let fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id, _infoExtra) * workDayHours;

                    if (sameDay) {
                        if (chosenWeek === parseInt(weekKey)) {
                            // SET NEW WORKDAYHOURS LIKE CONTRACT
                            if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                                workDayHours = absStart.getDay() === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                    : absStart.getDay() === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                        : absStart.getDay() === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                            : absStart.getDay() === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                                : absStart.getDay() === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                                    : absStart.getDay() === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                        : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                            }
                            // NEW REAL CALC ABSENCE
                            // if (this.objectArrayContainsKey(absence, 'date', absStart) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60))) < parseFloat(contract.contract_uren)) {
                            //     absence.push({ date: absStart, ms: workDayHours * 60 * 60 * 1000 });
                            //     totalAbsence += workDayHours * 60 * 60 * 1000;
                            // }
                            if (contract.contract_type === '2') {

                                if (this.objectArrayContainsKey(absence, 'date', absStart) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[weekKey]) < parseFloat(contract.contract_uren)) {
                                    absWeek[weekKey] += workDayHours;
                                    absence.push({date: absStart, ms: workDayHours * 60 * 60 * 1000});
                                    totalAbsence += workDayHours * 60 * 60 * 1000;
                                }

                                if (absWeek[weekKey] > parseFloat(contract.contract_uren)) {
                                    absWeek[weekKey] = parseFloat(contract.contract_uren);
                                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                                }

                                if (this.round((totalWorkedWeek / 1000 / 60 / 60)) < parseFloat(contract.contract_uren) && (absWeek[weekKey] + this.round((totalWorkedWeek / 1000 / 60 / 60))) > parseFloat(contract.contract_uren)) {
                                    absWeek[weekKey] = parseFloat(contract.contract_uren) - this.round((totalWorkedWeek / 1000 / 60 / 60));
                                    totalAbsence = (parseFloat(contract.contract_uren) * 1000 * 60 * 60) - totalWorkedWeek;
                                }

                            } else if (contract.contract_type === '1') {

                                if (this.objectArrayContainsKey(absence, 'date', absStart) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[weekKey]) < parseFloat(contract.contract_max_uren)) {
                                    absWeek[weekKey] += workDayHours;
                                    absence.push({date: absStart, ms: workDayHours * 60 * 60 * 1000});
                                    totalAbsence += workDayHours * 60 * 60 * 1000;
                                }

                                if (absWeek[weekKey] > parseFloat(contract.contract_max_uren)) {
                                    absWeek[weekKey] = parseFloat(contract.contract_max_uren);
                                    totalAbsence = parseFloat(contract.contract_max_uren) * 1000 * 60 * 60;
                                }

                                if (this.round((totalWorkedWeek / 1000 / 60 / 60)) < parseFloat(contract.contract_max_uren) && (absWeek[weekKey] + this.round((totalWorkedWeek / 1000 / 60 / 60))) > parseFloat(contract.contract_max_uren)) {
                                    absWeek[weekKey] = parseFloat(contract.contract_max_uren) - this.round((totalWorkedWeek / 1000 / 60 / 60));
                                    totalAbsence = (parseFloat(contract.contract_max_uren) * 1000 * 60 * 60) - totalWorkedWeek;
                                }

                            }
                            //   absence.push({ date: absStart, ms: parseFloat(abs.vs_aantal) * 60 * 60 * 1000 });
                            //   totalAbsence += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
                            // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
                        }
                    } else {

                        const infoExtra = _infoExtra;
                        let week = this.getWeekNumber2(absStart);

                        let index = 0;
                        while (new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

                            const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);

                            if (contract !== null && this.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && this.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                                // SET NEW WORKDAYHOURS LIKE CONTRACT
                                if (this.isTrue(contract.contract_vaste_dagen)) {
                                    workDayHours = currentDate.getDay() === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                        : currentDate.getDay() === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                            : currentDate.getDay() === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                                : currentDate.getDay() === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                                    : currentDate.getDay() === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                                        : currentDate.getDay() === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                            : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                                }

                                // if (this.dateToString(currentDate) === hours.uren_begindatum) {
                                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                                // }

                                if (this.hasGeneralWorkDays() === true) {

                                    // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                                    if (week !== this.getWeekNumber2(currentDate)) {
                                        week = this.getWeekNumber2(currentDate);
                                        fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id, _infoExtra) * workDayHours;
                                    }

                                    if (typeof absWeek[week] === 'undefined') {
                                        absWeek[week] = 0;
                                    }

                                    // fullWeekHours -= workDayHours;

                                    // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                                    //   if (this.personHasWorkDays(infoExtra[abs.info_id]) === true) {

                                    //     // person has working days, check work days personal and general
                                    //     if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[abs.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[abs.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[abs.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[abs.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[abs.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[abs.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[abs.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                    //       absWeek[week] += workDayHours;
                                    //       if (week === chosenWeek) {
                                    //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                    //         totalAbsence += workDayHours * 60 * 60 * 1000;
                                    //       }
                                    //     }

                                    //   } else { // just count the days
                                    //     absWeek[week] += workDayHours;
                                    //     if (week === chosenWeek) {
                                    //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                    //       totalAbsence += workDayHours * 60 * 60 * 1000;
                                    //     }
                                    //   }

                                    // }

                                }
                                //  else { // just count all days
                                //   absWeek[week] += workDayHours;
                                //   if (week === chosenWeek) {
                                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                                //   }
                                // }

                                // NEW REAL CALC ABSENCE
                                // if (absWeek[week] < parseFloat(contract.contract_uren) && this.objectArrayContainsKey(absence, 'date', currentDate) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                                //   absWeek[week] += workDayHours;
                                //   if (week === chosenWeek) {
                                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                                //   }
                                // }

                                // if (absWeek[week] > parseFloat(contract.contract_uren)) {
                                //   absWeek[week] = parseFloat(contract.contract_uren);
                                //   if (week === chosenWeek) {
                                //     totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                                //   }
                                // }

                                if (absWeek[week] < parseFloat(contract.contract_uren) && this.objectArrayContainsKey(absence, 'date', currentDate) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                                    absWeek[week] += workDayHours;
                                    if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < this.getWeekNumber())) {
                                        absence.push({date: currentDate, ms: workDayHours * 60 * 60 * 1000});
                                        totalAbsence += workDayHours * 60 * 60 * 1000;
                                    }
                                }

                                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                                    absWeek[week] = parseFloat(contract.contract_uren);
                                    if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < this.getWeekNumber())) {
                                        totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                                    }
                                }

                                if (this.round((totalWorkedWeek / 1000 / 60 / 60)) < parseFloat(contract.contract_uren) && (absWeek[week] + this.round((totalWorkedWeek / 1000 / 60 / 60))) > parseFloat(contract.contract_uren)) {
                                    absWeek[week] = parseFloat(contract.contract_uren) - this.round((totalWorkedWeek / 1000 / 60 / 60));
                                    if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < this.getWeekNumber())) {
                                        totalAbsence = (parseFloat(contract.contract_uren) * 1000 * 60 * 60) - totalWorkedWeek;
                                    }
                                }

                            } else if (contract !== null && this.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && this.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {

                                if (this.hasGeneralWorkDays() === true) {

                                    // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                                    if (week !== this.getWeekNumber2(currentDate)) {
                                        week = this.getWeekNumber2(currentDate);
                                        fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id, _infoExtra) * workDayHours;
                                    }

                                    if (typeof absWeek[week] === 'undefined') {
                                        absWeek[week] = 0;
                                    }

                                    // fullWeekHours -= workDayHours;

                                    // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                                    //   if (this.personHasWorkDays(infoExtra[abs.info_id]) === true) {

                                    //     // person has working days, check work days personal and general
                                    //     if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[abs.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[abs.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[abs.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[abs.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[abs.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[abs.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[abs.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                    //       absWeek[week] += workDayHours;
                                    //       if (week === chosenWeek) {
                                    //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                    //         totalAbsence += workDayHours * 60 * 60 * 1000;
                                    //       }
                                    //     }

                                    //   } else { // just count the days
                                    //     absWeek[week] += workDayHours;
                                    //     if (week === chosenWeek) {
                                    //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                    //       totalAbsence += workDayHours * 60 * 60 * 1000;
                                    //     }
                                    //   }

                                    // }

                                }
                                // else { // just count all days
                                //   absWeek[week] += workDayHours;
                                //   if (week === chosenWeek) {
                                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                                //   }
                                // }

                                // NEW REAL CALC ABSENCE
                                // if (absWeek[week] < parseFloat(contract.contract_max_uren) && this.objectArrayContainsKey(absence, 'date', currentDate) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                                //   absWeek[week] += workDayHours;
                                //   if (week === chosenWeek) {
                                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                                //   }
                                // }

                                // if (absWeek[week] > parseFloat(contract.contract_uren)) {
                                //   absWeek[week] = parseFloat(contract.contract_uren);
                                //   if (week === chosenWeek) {
                                //     totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                                //   }
                                // }
                                if (absWeek[week] < parseFloat(contract.contract_max_uren) && this.objectArrayContainsKey(absence, 'date', currentDate) === false && (this.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_max_uren)) {
                                    absWeek[week] += workDayHours;
                                    if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < this.getWeekNumber())) {
                                        absence.push({date: currentDate, ms: workDayHours * 60 * 60 * 1000});
                                        totalAbsence += workDayHours * 60 * 60 * 1000;
                                    }
                                }

                                if (absWeek[week] > parseFloat(contract.contract_max_uren)) {
                                    absWeek[week] = parseFloat(contract.contract_max_uren);
                                    if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < this.getWeekNumber())) {
                                        totalAbsence = parseFloat(contract.contract_max_uren) * 1000 * 60 * 60;
                                    }
                                }

                                if (this.round((totalWorkedWeek / 1000 / 60 / 60)) < parseFloat(contract.contract_max_uren) && (absWeek[week] + this.round((totalWorkedWeek / 1000 / 60 / 60))) > parseFloat(contract.contract_max_uren)) {
                                    absWeek[week] = parseFloat(contract.contract_max_uren) - this.round((totalWorkedWeek / 1000 / 60 / 60));
                                    if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < this.getWeekNumber())) {
                                        totalAbsence = (parseFloat(contract.contract_max_uren) * 1000 * 60 * 60) - totalWorkedWeek;
                                    }
                                }

                            }

                            index++;

                        }

                    }

                }

            }

        }

        return {absence: absence, totalAbsence: totalAbsence};

    }

    async getLeaveBalance(contracts, dateNow, userID, leaveBalances, leaveTypeBalances, contractLeaveBalances, absAmount, leaveCorrectionsData, correctionIndividual = false, infoExtra) {

        let totalLeaveBalances = {};
        totalLeaveBalances[new Date().getFullYear().toString()] = [];

        //iterate

        if (this.isEmpty(contracts)) {
            return null;
        }

        let newHours = {};
        let absAmountObj = {};

        let [
            hours,
            _absAmount2,
            clocked,
            schedules,
            breakRules,
        ] = await Promise.all([
            APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, `WHERE uren_info_id = ${userID}`, null, null),
            //APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id LEFT JOIN Info ON vs_info_id = info_id`, `WHERE vs_info_id = ${userID}`, null, null),
            APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN VerlofSaldo ON vakantie_id = vs_vakantie_id LEFT JOIN Info ON vakantie_info_id = info_id`, `WHERE vakantie_info_id = ${userID} AND vakantie_geaccepteerd = 1`, null, null),
            APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id LEFT JOIN Uren ON uren_kloktijd_id = kloktijd_id`, `WHERE kloktijd_info_id = ${userID} AND uren_id IS NULL`, null, null),
            APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Uren ON uren_rooster_id = rooster_id`, `WHERE rooster_info_id = ${userID} AND rooster_publiceren = 1 AND uren_id IS NULL`, null, null),
            APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),
        ]);

        // CLOCKED DATA INTO UREN DB
        if (Data.data.appOptions.hours === 'CLOCK') {
            for (const data of clocked) {

                let _break = data.kloktijd_pauze.substr(0, 8);
                if (Data.data.appOptions.brk === 'PLANNER') {
                    _break = this.getUserPlannerBreak(data.info_id, data.kloktijd_inklok_datum, schedules);
                } else if (Data.data.appOptions.brk === 'CALC') {
                    _break = this.getBreakRule(data.kloktijd_inklok_datum, data.kloktijd_inklok_tijd, data.kloktijd_uitklok_datum, data.kloktijd_uitklok_tijd, null, breakRules).str.substr(0, 5);
                }

                hours.push({
                    ...data,
                    uren_id: null,
                    uren_begindatum: data.kloktijd_inklok_datum,
                    uren_einddatum: data.kloktijd_uitklok_datum,
                    uren_begin: data.kloktijd_inklok_tijd,
                    uren_eind: data.kloktijd_uitklok_tijd,
                    uren_pauze: _break,
                });

            }
        } else if (Data.data.appOptions.hours === 'PLANNER') {
            for (const data of schedules) {

                let _break = data.rooster_pauze;
                if (Data.data.appOptions.brk === 'CALC') {
                    _break = this.getBreakRule(data.rooster_datum, data.rooster_begin, data.rooster_datum, data.rooster_eind, data, breakRules).str.substr(0, 5);
                }

                hours.push({
                    ...data,
                    uren_id: null,
                    uren_begindatum: data.rooster_datum,
                    uren_einddatum: planClass.isNightShift2(data),
                    uren_begin: data.rooster_begin,
                    uren_eind: data.rooster_eind,
                    uren_pauze: _break,
                });

            }
        }

        // HOURS (UREN DB)
        for (const data of hours) {

            const hourDate = this.stringToDate(data.uren_begindatum);
            const week = this.getWeekNumber2(hourDate);
            const year = hourDate.getFullYear();
            const yearKey = year.toString();

            // CREATE YEAR OBJECT
            if (this.isEmpty(newHours[yearKey])) {
                newHours[yearKey] = [];
            }

            // CREATE WEEK ITEM
            if (this.objectArrayContainsKey(newHours[yearKey], 'week', week) === false) {
                newHours[yearKey].push({
                    week: week,
                    weekData: [],
                });
            }

            const weekItem = this.getArrayItem(newHours[yearKey], 'week', week);

            if (weekItem !== null) {
                weekItem.weekData.push(data);
            }

        }

        // ABS
        for (const abs of _absAmount2) {

            const date = this.stringToDate(abs.vakantie_begin);
            const week = this.getWeekNumber2(date);
            const weekKey = week.toString();
            const year = date.getFullYear();
            const yearKey = year.toString();

            if (this.isEmpty(absAmountObj[yearKey])) {
                absAmountObj[yearKey] = {};
            }
            if (this.isEmpty(absAmountObj[yearKey][weekKey])) {
                absAmountObj[yearKey][weekKey] = [];
            }

            absAmountObj[yearKey][weekKey].push(abs);

        }

        for (const contract of contracts) {

            if (contract.contract_type === '0') { // flex

                const currentYear = dateNow.getFullYear();

                const contractStart = this.isEmpty(contract.contract_begin) === false ? this.stringToDate(contract.contract_begin) : this.stringToDate(`${currentYear}-01-01`);
                const contractEnd = this.isEmpty(contract.contract_eind) === false ? this.stringToDate(contract.contract_eind) : this.stringToDate(`${currentYear}-12-31`);

                // calc hours
                let weekStart = 0;
                let weekEnd = 0;

                let weeks = 0;
                let hours = 0;
                let totalHoursYear = 0;

                if (contractStart.getFullYear() === contractEnd.getFullYear()) {

                    const yearKey = contractStart.getFullYear().toString();

                    if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                        totalLeaveBalances[yearKey] = [];
                    }

                    if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                            if (Data.data.appOptions.hours === 'CLOCK') {
                                rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            } else {
                                rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            }

                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Uren`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                            const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                            const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYear += (net - brk) / 60 / 60;

                        }

                    } else { // PLANNING

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                            rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                            where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Rooster`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE rooster_datum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                            const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                            const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYear += (net - brk) / 60 / 60;

                        }

                    }

                    let balances = [];

                    for (const balance of leaveBalances) {

                        let b = {
                            substract: 0,
                            balance: 0,
                            total: 0,
                            correction: 0,
                            worked: totalHoursYear,
                            ...balance
                        };

                        const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);

                        if (factor === null) {
                            b.balance = 0;
                        } else {
                            b.balance = (totalHoursYear * (parseFloat(factor.cvb_vb_factor) * 1000 * 60 * 60));
                        }

                        balances.push(b);

                    }

                    // return total balances
                    if (totalLeaveBalances[yearKey].length > 0) {

                        for (const blnc of balances) {

                            let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                            if (userBalance !== null) {

                                userBalance.substract += blnc.substract;
                                userBalance.balance += blnc.balance;
                                userBalance.total += blnc.total;
                                userBalance.worked += blnc.worked;

                            }

                        }

                    } else {

                        totalLeaveBalances[yearKey] = balances;

                    }

                } else {

                    for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                        totalHoursYear = 0;

                        const yearKey = i.toString();

                        if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                            totalLeaveBalances[yearKey] = [];
                        }

                        let contractHoursStart = contractStart;
                        let contractHoursEnd = contractEnd;

                        if (i === contractStart.getFullYear()) {

                            contractHoursStart = contractStart;
                            contractHoursEnd = new Date(i, 11, 31);

                        } else if (i === contractEnd.getFullYear()) {

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = contractEnd;

                        } else { // there is a whole year between start and end date contract

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = new Date(i, 11, 31);

                        }

                        if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                                if (Data.data.appOptions.hours === 'CLOCK') {
                                    rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                    where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                } else {
                                    rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                    where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                }

                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Uren`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                                const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                                const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYear += (net - brk) / 60 / 60;

                            }

                        } else { // PLANNING

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                                rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Rooster`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE rooster_datum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                                const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                                const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYear += (net - brk) / 60 / 60;

                            }

                        }

                        let balances = [];

                        for (const balance of leaveBalances) {

                            let b = {
                                substract: 0,
                                balance: 0,
                                total: 0,
                                correction: 0,
                                worked: totalHoursYear,
                                ...balance
                            };

                            const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);

                            if (factor === null) {
                                b.balance = 0;
                            } else {
                                b.balance = (totalHoursYear * (parseFloat(factor.cvb_vb_factor) * 1000 * 60 * 60));
                            }

                            balances.push(b);

                        }

                        // return total balances
                        if (totalLeaveBalances[yearKey].length > 0) {

                            for (const blnc of balances) {

                                let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                                if (userBalance !== null) {

                                    userBalance.substract += blnc.substract;
                                    userBalance.balance += blnc.balance;
                                    userBalance.total += blnc.total;
                                    userBalance.worked += blnc.worked;

                                }

                            }

                        } else {

                            totalLeaveBalances[yearKey] = balances;

                        }

                    }

                }

                // // finish vacationdays total balance
                // for (const balance of balances) {
                //     vacationSaldo += balance.total;
                // }

            } else if (contract.contract_type === '1') { // min-max

                const currentYear = dateNow.getFullYear();

                const contractStart = this.isEmpty(contract.contract_begin) === false ? this.stringToDate(contract.contract_begin) : this.stringToDate(`${currentYear}-01-01`);
                const contractEnd = this.isEmpty(contract.contract_eind) === false ? this.stringToDate(contract.contract_eind) : this.stringToDate(`${currentYear}-12-31`);

                // calc hours
                let weekStart = 0;
                let weekEnd = 0;

                let weeks = 0;
                let hours = 0;
                let hoursContract = 0;
                let totalHoursYear = 0;
                let totalHoursYearWorked = 0;
                let totalHoursYearContract = 0;

                if (contractStart.getFullYear() === contractEnd.getFullYear()) {

                    const yearKey = contractStart.getFullYear().toString();

                    if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                        totalLeaveBalances[yearKey] = [];
                    }

                    if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                            if (Data.data.appOptions.hours === 'CLOCK') {
                                rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            } else {
                                rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            }

                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Uren`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                            const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                            const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYearWorked += (net - brk) / 60 / 60;

                        }

                    } else { // PLANNING

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                            rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                            where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Rooster`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE rooster_datum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                            const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                            const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYearWorked += (net - brk) / 60 / 60;

                        }

                    }

                    // weekStart = this.getWeekNumber2(contractStart) >= 52 ? 0 : this.getWeekNumber2(contractStart);
                    // weekEnd = this.getWeekNumber2(contractEnd) <= 1 ? 52 : this.getWeekNumber2(contractEnd);

                    // weeks = weekEnd - weekStart;
                    weeks = this.weeksBetween(contractStart, contractEnd);
                    hoursContract = parseFloat(contract.contract_uren);
                    totalHoursYearContract = hoursContract * weeks;

                    if (totalHoursYearWorked < totalHoursYearContract) {
                        totalHoursYear = totalHoursYearContract;
                    } else {
                        totalHoursYear = totalHoursYearWorked;
                    }

                    let balances = [];

                    for (const balance of leaveBalances) {

                        let b = {
                            substract: 0,
                            balance: 0,
                            total: 0,
                            correction: 0,
                            worked: totalHoursYear,
                            ...balance
                        };

                        const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);

                        if (factor === null) {
                            b.balance = 0;
                        } else {
                            b.balance = (totalHoursYear * (parseFloat(factor.cvb_vb_factor) * 1000 * 60 * 60));
                        }

                        balances.push(b);

                    }

                    // return total balances
                    if (totalLeaveBalances[yearKey].length > 0) {

                        for (const blnc of balances) {

                            let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                            if (userBalance !== null) {

                                userBalance.substract += blnc.substract;
                                userBalance.balance += blnc.balance;
                                userBalance.total += blnc.total;
                                userBalance.worked += blnc.worked;

                            }

                        }

                    } else {

                        totalLeaveBalances[yearKey] = balances;

                    }

                } else {

                    for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                        totalHoursYear = 0;
                        totalHoursYearWorked = 0;
                        totalHoursYearContract = 0;

                        const yearKey = i.toString();

                        if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                            totalLeaveBalances[yearKey] = [];
                        }

                        let contractHoursStart = contractStart;
                        let contractHoursEnd = contractEnd;

                        if (i === contractStart.getFullYear()) {

                            contractHoursStart = contractStart;
                            contractHoursEnd = new Date(i, 11, 31);

                        } else if (i === contractEnd.getFullYear()) {

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = contractEnd;

                        } else { // there is a whole year between start and end date contract

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = new Date(i, 11, 31);

                        }

                        if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                                if (Data.data.appOptions.hours === 'CLOCK') {
                                    rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                    where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                } else {
                                    rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                    where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                }

                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Uren`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                                const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                                const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYearWorked += (net - brk) / 60 / 60;

                            }

                        } else { // PLANNING

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                                rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Rooster`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE rooster_datum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                                const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                                const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYearWorked += (net - brk) / 60 / 60;

                            }

                        }

                        if (i === contractStart.getFullYear()) {

                            weekStart = (this.getWeekNumber2(contractStart) - 1);
                            weekEnd = 52;

                        } else if (i === contractEnd.getFullYear()) {

                            weekStart = 0;
                            weekEnd = this.getWeekNumber2(contractEnd);

                        } else { // there is a whole year between start and end date contract

                            weekStart = 0;
                            weekEnd = 52;

                        }

                        weeks = weekEnd - weekStart;
                        hoursContract = parseFloat(contract.contract_uren);
                        totalHoursYearContract = hoursContract * weeks;

                        if (totalHoursYearWorked < totalHoursYearContract) {
                            totalHoursYear = totalHoursYearContract;
                        } else {
                            totalHoursYear = totalHoursYearWorked;
                        }

                        let balances = [];

                        for (const balance of leaveBalances) {

                            let b = {
                                substract: 0,
                                balance: 0,
                                total: 0,
                                correction: 0,
                                worked: totalHoursYear,
                                ...balance
                            };

                            const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);

                            if (factor === null) {
                                b.balance = 0;
                            } else {
                                b.balance = (totalHoursYear * (parseFloat(factor.cvb_vb_factor) * 1000 * 60 * 60));
                            }

                            balances.push(b);

                        }

                        // return total balances
                        if (totalLeaveBalances[yearKey].length > 0) {

                            for (const blnc of balances) {

                                let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                                if (userBalance !== null) {

                                    userBalance.substract += blnc.substract;
                                    userBalance.balance += blnc.balance;
                                    userBalance.total += blnc.total;
                                    userBalance.worked += blnc.worked;

                                }

                            }

                        } else {

                            totalLeaveBalances[yearKey] = balances;

                        }

                    }

                }

                // // finish vacationdays total balance
                // for (const balance of balances) {
                //     vacationSaldo += balance.total;
                // }

            } else if (contract.contract_type === '2') { // vast

                const currentYear = dateNow.getFullYear();

                const contractStart = this.isEmpty(contract.contract_begin) === false ? this.stringToDate(contract.contract_begin) : this.stringToDate(`${currentYear}-01-01`);
                const contractEnd = this.isEmpty(contract.contract_eind) === false ? this.stringToDate(contract.contract_eind) : this.stringToDate(`${currentYear}-12-31`);

                // calc hours
                let weekStart = 0;
                let weekEnd = 0;

                let weeks = 0;
                let hours = 0;
                let totalHoursYear = 0;

                if (contractStart.getFullYear() === contractEnd.getFullYear()) {

                    const yearKey = contractStart.getFullYear().toString();

                    if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                        totalLeaveBalances[yearKey] = [];
                    }

                    // weekStart = this.getWeekNumber2(contractStart) >= 52 ? 1 : this.getWeekNumber2(contractStart);
                    // weekEnd = this.getWeekNumber2(contractEnd) <= 1 ? 53 : this.getWeekNumber2(contractEnd);

                    // weeks = weekEnd - weekStart;
                    weeks = this.weeksBetween(contractStart, contractEnd);
                    hours = parseFloat(contract.contract_uren);
                    totalHoursYear = hours * weeks;

                    let balances = [];

                    for (const balance of leaveBalances) {

                        let b = {
                            substract: 0,
                            balance: 0,
                            total: 0,
                            correction: 0,
                            worked: totalHoursYear,
                            ...balance
                        };

                        const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);

                        if (factor === null) {
                            b.balance = 0;
                        } else {
                            b.balance = (totalHoursYear * (parseFloat(factor.cvb_vb_factor) * 1000 * 60 * 60));
                        }

                        balances.push(b);

                    }

                    // return total balances
                    if (totalLeaveBalances[yearKey].length > 0) {

                        for (const blnc of balances) {

                            let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                            if (userBalance !== null) {

                                userBalance.substract += blnc.substract;
                                userBalance.balance += blnc.balance;
                                userBalance.total += blnc.total;
                                userBalance.worked += blnc.worked;

                            }

                        }

                    } else {

                        totalLeaveBalances[yearKey] = balances;

                    }

                } else {

                    for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                        const yearKey = i.toString();

                        if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                            totalLeaveBalances[yearKey] = [];
                        }

                        if (i === contractStart.getFullYear()) {

                            weekStart = (this.getWeekNumber2(contractStart) - 1);
                            weekEnd = 52;

                        } else if (i === contractEnd.getFullYear()) {

                            weekStart = 0;
                            weekEnd = this.getWeekNumber2(contractEnd);

                        } else { // there is a whole year between start and end date contract

                            weekStart = 0;
                            weekEnd = 52;

                        }

                        weeks = weekEnd - weekStart;
                        hours = parseFloat(contract.contract_uren);
                        totalHoursYear = hours * weeks;

                        let balances = [];

                        for (const balance of leaveBalances) {

                            let b = {
                                substract: 0,
                                balance: 0,
                                total: 0,
                                correction: 0,
                                worked: totalHoursYear,
                                ...balance
                            };

                            const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);

                            if (factor === null) {
                                b.balance = 0;
                            } else {
                                b.balance = (totalHoursYear * (parseFloat(factor.cvb_vb_factor) * 1000 * 60 * 60));
                            }

                            balances.push(b);

                        }

                        // return total balances
                        if (totalLeaveBalances[yearKey].length > 0) {

                            for (const blnc of balances) {

                                let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                                if (userBalance !== null) {

                                    userBalance.substract += blnc.substract;
                                    userBalance.balance += blnc.balance;
                                    userBalance.total += blnc.total;
                                    userBalance.worked += blnc.worked;

                                }

                            }

                        } else {

                            totalLeaveBalances[yearKey] = balances;

                        }

                    }

                }

                // // finish vacationdays total balance
                // for (const balance of balances) {
                //     vacationSaldo += balance.total;
                // }

            }

        }

        let absAmountsUsed = [];
        let correctionsUsed = [];
        let startingTotalYear = {};
        let substractYear = {};
        let correctionYear = {};
        for (const yearKey in totalLeaveBalances) {

            let iteratedHolidays = [];

            for (let balance of totalLeaveBalances[yearKey]) {

                const typeBalances = leaveTypeBalances[balance.vb_id];

                // get substraction // VerlofSaldo
                if (typeof typeBalances !== 'undefined') {

                    // Absamount = Vakantie TABLE now
                    if (typeof absAmount[userID] !== 'undefined') {

                        const currentWeek = this.getWeekNumber2(new Date());

                        for (const holiday of absAmount[userID]) {

                            const holidayYear = this.stringToDate(holiday.vakantie_eind).getFullYear();
                            const startWeek = this.getWeekNumber2(this.stringToDate(holiday.vakantie_begin));
                            const endWeek = this.getWeekNumber2(this.stringToDate(holiday.vakantie_eind));

                            // if is current week, skip
                            if (holidayYear !== parseInt(yearKey)) {
                                continue;
                            }

                            // if is current week, skip
                            if (startWeek >= currentWeek && holidayYear === new Date().getFullYear()) {
                                continue;
                            }

                            // Leavetype has vakantie_vt_type id
                            if (this.arrayContains(iteratedHolidays, holiday.vakantie_id) === true || (this.isEmpty(holiday.vakantie_vt_id) === false && this.objectArrayContainsKey(typeBalances, 'vtb_vt_id', holiday.vakantie_vt_id) === false)) {
                                continue;
                            }

                            iteratedHolidays.push(holiday.vakantie_id);

                            for (let i = startWeek; i <= endWeek; i++) {

                                let totalWorkedHours = 0;

                                const monday = this.getMonday(i, parseInt(yearKey));
                                const contract = this.getCurrentContract(contracts, monday) === null ? null : this.getCurrentContract(contracts, monday);

                                if (contract !== null && this.isEmpty(newHours[yearKey]) === false) {

                                    let workDayHours = this.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : this.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

                                    // SET NEW WORKDAYHOURS LIKE CONTRACT GEEN VASTE DAGEN
                                    workDayHours = this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                                    const yearData = this.getArrayItem(newHours[yearKey], 'week', i);

                                    if (this.isEmpty(yearData) === false) {

                                        const weekData = yearData.weekData;

                                        for (const dayData of weekData) { // days in week array

                                            const start = this.stringToDateTime(dayData.uren_begindatum, dayData.uren_begin);
                                            const end = this.stringToDateTime(dayData.uren_einddatum, dayData.uren_eind);
                                            const diff = end.getTime() - start.getTime() - this.timeStringToMS(dayData.uren_pauze);

                                            // SET NEW WORKDAYHOURS LIKE CONTRACT
                                            if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                                                workDayHours = start.getDay() === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                                    : start.getDay() === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                                        : start.getDay() === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                                            : start.getDay() === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                                                : start.getDay() === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                                                    : start.getDay() === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                                        : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                                            }

                                            totalWorkedHours += diff; //(workDayHours * 1000 * 60 * 60);

                                        }

                                    }

                                }

                                const calcAbsAmount = this.calcAbsAmountHoliday(i, yearKey, totalWorkedHours, absAmountObj, contracts, infoExtra);
                                balance.substract += calcAbsAmount.totalAbsence;

                            }

                        }

                    }

                    // if (typeof absAmount[userID] !== 'undefined') {

                    //     for (const abs of absAmount[userID]) {

                    //         if (this.stringToDate(abs.vakantie_begin).getFullYear() === parseInt(yearKey)) {

                    //             // this type of VerlofSaldo can substract from given VerlofBalans?
                    //             if (this.objectArrayContainsKey(typeBalances, 'vtb_vt_id', abs.vs_vt_id) === true && this.arrayContains(absAmountsUsed, abs.vs_id) === false) {
                    //                 if (this.getCurrentContract(contracts, this.stringToDate(abs.vakantie_begin)) !== null || this.getCurrentContract(contracts, this.stringToDate(abs.vakantie_eind)) !== null) {
                    //                     balance.substract += parseFloat(abs.vs_aantal);
                    //                     absAmountsUsed.push(abs.vs_id);
                    //                 }
                    //             }

                    //         }

                    //     }

                    // }

                    if (balance.substract !== 0) {

                        if (typeof startingTotalYear[balance.vb_id] === 'undefined') {
                            startingTotalYear[balance.vb_id] = 0;
                        }
                        if (typeof substractYear[balance.vb_id] === 'undefined') {
                            substractYear[balance.vb_id] = 0;
                        }

                        balance.total = (balance.balance - balance.substract);
                        balance.total2 = (balance.balance - balance.substract) + startingTotalYear[balance.vb_id];
                        startingTotalYear[balance.vb_id] = balance.total2;
                        balance.substract2 = balance.substract + substractYear[balance.vb_id];
                        substractYear[balance.vb_id] = balance.substract2;

                    } else {

                        if (typeof startingTotalYear[balance.vb_id] === 'undefined') {
                            startingTotalYear[balance.vb_id] = 0;
                        }
                        if (typeof substractYear[balance.vb_id] === 'undefined') {
                            substractYear[balance.vb_id] = 0;
                        }

                        balance.total = balance.balance;
                        balance.total2 = balance.balance + startingTotalYear[balance.vb_id];
                        startingTotalYear[balance.vb_id] = balance.total2;
                        balance.substract2 = balance.substract + substractYear[balance.vb_id];
                        substractYear[balance.vb_id] = balance.substract2;

                    }

                }

                // FIX TOTAL2
                if (typeof balance.total2 === 'undefined') {

                    if (typeof startingTotalYear[balance.vb_id] === 'undefined') {
                        startingTotalYear[balance.vb_id] = 0;
                    }

                    balance.total2 = balance.balance + startingTotalYear[balance.vb_id];
                    startingTotalYear[balance.vb_id] = balance.total2;

                }
                if (balance.total === 0) {
                    balance.total = balance.balance;
                }

                // CORRECTIONS
                if (correctionIndividual === true) {

                    if (this.isEmpty(leaveCorrectionsData) === false) {

                        for (const correction of leaveCorrectionsData) {

                            const date = this.stringToDate(correction.cv_datum);
                            const year = date.getFullYear();

                            if (year === parseInt(yearKey) && balance.vb_id === correction.cv_vb_id) {
                                balance.correction += parseFloat(correction.cv_aantal) * 1000 * 60 * 60;
                            }

                        }

                        if (balance.correction !== 0) {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.total = (balance.total + balance.correction);
                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            balance.total2 = (balance.total2 + balance.correction2);
                            correctionYear[balance.vb_id] = balance.correction2;

                        } else {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            balance.total2 = (balance.total2 + balance.correction2);
                            correctionYear[balance.vb_id] = balance.correction2;

                        }

                    }

                } else {

                    if (this.isEmpty(leaveCorrectionsData) === false && this.isEmpty(leaveCorrectionsData[userID]) === false) {

                        for (const correction of leaveCorrectionsData[userID]) {

                            const date = this.stringToDate(correction.cv_datum);
                            const year = date.getFullYear();

                            if (year === parseInt(yearKey) && balance.vb_id === correction.cv_vb_id) {
                                balance.correction += parseFloat(correction.cv_aantal) * 1000 * 60 * 60;
                            }

                        }

                        if (balance.correction !== 0) {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.total = (balance.total + balance.correction);
                            balance.total2 = (balance.total2 + balance.correction);
                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            correctionYear[balance.vb_id] = balance.correction2;

                        } else {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            balance.total2 = (balance.total2 + balance.correction2);
                            correctionYear[balance.vb_id] = balance.correction2;

                        }

                    }

                }

            }

        }

        let totals = [];
        let total_totalLeaveBalances = {};

        // make sure theres a new fresh list for total, so it wont interrupt the seperate balances list
        for (const yearKey in totalLeaveBalances) {

            if (typeof total_totalLeaveBalances[yearKey] === 'undefined') {
                total_totalLeaveBalances[yearKey] = [];
            }

            for (const balance of totalLeaveBalances[yearKey]) {

                total_totalLeaveBalances[yearKey].push({
                    balance: balance.balance,
                    substract: balance.substract,
                    total: balance.total,
                    vb_factor: balance.vb_factor,
                    vb_id: balance.vb_id,
                    vb_naam: balance.vb_naam,
                });

            }

        }

        // create totals list
        for (const yearKey in total_totalLeaveBalances) {

            for (const balance of total_totalLeaveBalances[yearKey]) {

                let userBalance = this.getArrayItem(totals, 'vb_id', balance.vb_id);

                if (userBalance !== null) {

                    userBalance.substract += balance.substract;
                    userBalance.balance += balance.balance;
                    userBalance.total += balance.total;

                } else {

                    totals.push(balance);

                }

            }

        }

        return {
            sep: totalLeaveBalances,
            totals: totals,
        };

    }

    async calculateAllHours(userID, paramWeek = this.getWeekNumber2(new Date()), paramYear = new Date().getFullYear(), now = new Date()) {

        let workDayHours = this.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : this.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        const [
            hoursWorked,
            holidays,
            absents,
            contracts,
            overhoursCorrectionsData,
            time4timeCorrectionsData_overhours,
            time4timeCorrectionsData_holiday,
            time4timeCorrectionsDataDB,
            holidayCorrectionsData,
            contractLeaveBalances,
            leaveBalances,
            leaveTypeBalance,
            surcharges,
        ] = await Promise.all([
            APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, `WHERE uren_info_id = ${userID}`, null, null),
            APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN VerlofSaldo ON vakantie_id = vs_vakantie_id LEFT JOIN Info ON vakantie_info_id = info_id`, `WHERE vakantie_info_id = ${userID} AND vakantie_geaccepteerd = 1`, null, null),
            APIGET.Request(`SELECT *`, `FROM AfwezigheidN`, `LEFT JOIN Afwezigheid ON afwn_afw_id = afw_id LEFT JOIN Info ON afwn_info_id = info_id LEFT JOIN Uitzondering ON uit_afw_id = afwn_afw_id`, `WHERE afwn_info_id = ${userID} AND uit_id IS NOT NULL`, null, null), // absence_n
            APIGET.Request(`SELECT *`, `FROM ContractN`, null, `WHERE contract_info_id = ${userID}`, null, null),
            APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, `WHERE co_info_id = ${userID}`, null, `ORDER BY co_datum DESC, co_id DESC`),
            APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, `WHERE co_info_id = ${userID} AND co_tvt = 1`, null, `ORDER BY co_datum DESC, co_id DESC`),
            APIGET.Request(`SELECT CorrectieVerlof.*, VerlofBalans.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieVerlof`, `LEFT JOIN VerlofBalans ON cv_vb_id = vb_id LEFT JOIN Info ON cv_info_id_door = info_id`, `WHERE cv_info_id = ${userID} AND cv_tvt = 1`, null, `ORDER BY cv_datum DESC, cv_id DESC`),
            APIGET.Request(`SELECT CorrectieT4T.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieT4T`, `LEFT JOIN Info ON ct_info_id_door = info_id`, `WHERE ct_info_id = ${userID}`, null, `ORDER BY ct_datum DESC, ct_id DESC`),
            APIGET.Request(`SELECT CorrectieVerlof.*, VerlofBalans.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieVerlof`, `LEFT JOIN VerlofBalans ON cv_vb_id = vb_id LEFT JOIN Info ON cv_info_id_door = info_id`, `WHERE cv_info_id = ${userID}`, null, `ORDER BY cv_datum DESC, cv_id DESC`),
            APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, null, null, null, null),
            APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
            APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vb_id = vtb_vb_id`, null, null, `ORDER BY vb_factor DESC`),
            APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, null),
        ]);

        const contractLeaveBalancesObj = this.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');
        const leaveTypeBalanceObj = this.convertToObjectWithArrays(leaveTypeBalance, 'vtb_vt_id');

        // all the information seperated in years | yearData[year][week][day].variable | yearData[2022][45][1].hoursWorked
        let yearData = {};

        // ********* TOTALS *********
        let totalHoursWorked = {};
        let totalHoursAbsent = {};
        let totalHoursHoliday = {};
        let totalOverhours = {}; // totalOverhours[year]: 0
        let totalT4T = {};
        let totalHolidayBalance = {};
        let holidayReduces = {};
        let totalT4TYearly = {};

        // for (const c of contracts) {

        //     if (this.isEmpty(c.contract_begin) === true) {
        //         continue;
        //     }

        //     const startDate = this.stringToDate(c.contract_begin);
        //     const year = startDate.getFullYear();
        //     const week = this.getWeekNumber2(startDate);

        //     const weeks = this.getWeeksStartAndEndInMonth(startDate.getMonth(), year);

        //     // first get weeks of date between first year and second year EG: jan 1 2022 is week 52
        //     for (const w of weeks) {

        //         let _year = w.year;
        //         if (w.year1 !== w.year) {
        //             _year = w.year1;
        //         }

        //         if (this.getMonday(w.week, _year).getTime() < startDate.getTime()) {
        //             continue;
        //         }

        //         if (this.isEmpty(yearData[_year]) === true) {
        //             yearData[_year] = {};
        //         }

        //         if (this.isEmpty(yearData[_year][w.week]) === true) {
        //             yearData[_year][w.week] = {
        //                 7: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSunday(w.week, _year) }, // sunday
        //                 1: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getMonday(w.week, _year) }, // monday
        //                 2: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getTuesday(w.week, _year) }, // tuesday
        //                 3: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getWednesday(w.week, _year) }, // wednesday
        //                 4: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getThursday(w.week, _year) }, // thursday
        //                 5: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getFriday(w.week, _year) }, // friday
        //                 6: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSaturday(w.week, _year) }, // saturday
        //             };
        //         }

        //     }

        //     // continue with just contract date stuff
        //     if (this.isEmpty(yearData[year]) === true) {
        //         yearData[year] = {};
        //     }

        //     if (this.isEmpty(yearData[year][week]) === true) {
        //         yearData[year][week] = {
        //             7: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSunday(week, year) }, // sunday
        //             1: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getMonday(week, year) }, // monday
        //             2: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getTuesday(week, year) }, // tuesday
        //             3: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getWednesday(week, year) }, // wednesday
        //             4: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getThursday(week, year) }, // thursday
        //             5: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getFriday(week, year) }, // friday
        //             6: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSaturday(week, year) }, // saturday
        //         };
        //     }

        // }

        if (this.isEmpty(hoursWorked) === false) {

            // HOURS WORKED
            for (const hour of hoursWorked) {

                const date = this.stringToDate(hour.uren_begindatum);
                const year = date.getFullYear();
                const week = this.getWeekNumber2(date);
                const day = date.getDay();
                const dayKey = day === 0 ? '7' : day.toString();

                const contract = this.getCurrentContract(contracts, date);

                // if contract is not there, move on
                if (this.isEmpty(contract) === true) {
                    continue;
                }

                // set workDayHours
                // NO FIXED DAYS
                workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                // FIXED DAYS
                if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                    workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                        : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                            : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                    : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                        : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                            : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                if (this.isEmpty(yearData[year]) === true) {
                    yearData[year] = {};
                }

                if (this.isEmpty(yearData[year][week]) === true) {
                    yearData[year][week] = {
                        7: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getSunday(week, year)
                        }, // sunday
                        1: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getMonday(week, year)
                        }, // monday
                        2: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getTuesday(week, year)
                        }, // tuesday
                        3: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getWednesday(week, year)
                        }, // wednesday
                        4: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getThursday(week, year)
                        }, // thursday
                        5: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getFriday(week, year)
                        }, // friday
                        6: {
                            hoursWorked: 0,
                            absent: 0,
                            holiday: 0,
                            overhours: 0,
                            t4t: 0,
                            holidayData: null,
                            date: this.getSaturday(week, year)
                        }, // saturday
                    };
                }

                const startDate = this.stringToDateTime(hour.uren_begindatum, hour.uren_begin);
                const endDate = this.stringToDateTime(hour.uren_einddatum, hour.uren_eind);
                const breakTime = this.timeStringToMS(hour.uren_pauze);

                const workedDay = (endDate.getTime() - startDate.getTime()) - breakTime;
                const overhours = (workedDay - (workDayHours * 1000 * 60 * 60));

                yearData[year][week][dayKey].hoursWorked = workedDay;
                yearData[year][week][dayKey].overhours = overhours;

                // TIME 4 TIME
                const workedDay_bonus = planClass.calcBonus_t4t(hour, (workedDay / 1000), surcharges, true);
                const bonus_hours = ((workedDay_bonus - (workedDay / 1000)) * 1000);

                yearData[year][week][dayKey].t4t = bonus_hours;

            }

        }

        // ABSENTS
        for (const absent of absents) {

            const date = this.stringToDate(absent.afwn_datum);
            const year = date.getFullYear();
            const week = this.getWeekNumber2(date);
            const day = date.getDay();
            const dayKey = day === 0 ? '7' : day.toString();

            const contract = this.getCurrentContract(contracts, date);

            // if contract is not there, move on
            if (this.isEmpty(contract) === true) {
                continue;
            }

            // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
            if (this.isEmpty(yearData[year]) === true) {
                yearData[year] = {};
            }

            if (this.isEmpty(yearData[year][week]) === true) {
                yearData[year][week] = {
                    7: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getSunday(week, year)
                    }, // sunday
                    1: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getMonday(week, year)
                    }, // monday
                    2: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getTuesday(week, year)
                    }, // tuesday
                    3: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getWednesday(week, year)
                    }, // wednesday
                    4: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getThursday(week, year)
                    }, // thursday
                    5: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getFriday(week, year)
                    }, // friday
                    6: {
                        hoursWorked: 0,
                        absent: 0,
                        holiday: 0,
                        overhours: 0,
                        t4t: 0,
                        holidayData: null,
                        date: this.getSaturday(week, year)
                    }, // saturday
                };
            }

            if (this.isTrue(absent.afwn_ziekteWachtdag) === true) {
                yearData[year][week][dayKey].absent = -1;
                continue;
            }

            // set workDayHours
            // NO FIXED DAYS
            workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

            // FIXED DAYS
            if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                    : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                        : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                            : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                    : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                        : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
            }

            yearData[year][week][dayKey].absent = (workDayHours * 1000 * 60 * 60);

        }

        // HOLIDAYS
        loopHoliday:
            for (const holiday of holidays) {

                const startDate = this.stringToDate(holiday.vakantie_begin);
                const endDate = this.stringToDate(holiday.vakantie_eind);

                const sameDay = holiday.vakantie_begin === holiday.vakantie_eind;

                if (sameDay === true) {

                    const year = startDate.getFullYear();
                    const week = this.getWeekNumber2(startDate);
                    const day = startDate.getDay();
                    const dayKey = day === 0 ? '7' : day.toString();

                    const contract = this.getCurrentContract(contracts, startDate);

                    // if contract is not there, move on
                    if (this.isEmpty(contract) === true) {
                        continue;
                    }

                    // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                    if (this.isEmpty(yearData[year]) === true) {
                        yearData[year] = {};
                    }

                    if (this.isEmpty(yearData[year][week]) === true) {
                        yearData[year][week] = {
                            7: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSunday(week, year)
                            }, // sunday
                            1: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getMonday(week, year)
                            }, // monday
                            2: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getTuesday(week, year)
                            }, // tuesday
                            3: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getWednesday(week, year)
                            }, // wednesday
                            4: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getThursday(week, year)
                            }, // thursday
                            5: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getFriday(week, year)
                            }, // friday
                            6: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSaturday(week, year)
                            }, // saturday
                        };
                    }

                    // set workDayHours
                    // NO FIXED DAYS
                    workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                    // FIXED DAYS
                    if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                        workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                            : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                    : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                        : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                            : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                    }

                    yearData[year][week][dayKey].holiday = (workDayHours * 1000 * 60 * 60);
                    yearData[year][week][dayKey].holidayData = holiday;

                } else {

                    let index = 0;
                    while (new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

                        const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

                        const year = currentDate.getFullYear();
                        const week = this.getWeekNumber2(currentDate);
                        const day = currentDate.getDay();
                        const dayKey = day === 0 ? '7' : day.toString();

                        const contract = this.getCurrentContract(contracts, currentDate);

                        // if contract is not there, move on
                        if (this.isEmpty(contract) === true) {
                            continue loopHoliday;
                        }

                        // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                        if (this.isEmpty(yearData[year]) === true) {
                            yearData[year] = {};
                        }

                        if (this.isEmpty(yearData[year][week]) === true) {
                            yearData[year][week] = {
                                7: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getSunday(week, year)
                                }, // sunday
                                1: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getMonday(week, year)
                                }, // monday
                                2: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getTuesday(week, year)
                                }, // tuesday
                                3: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getWednesday(week, year)
                                }, // wednesday
                                4: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getThursday(week, year)
                                }, // thursday
                                5: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getFriday(week, year)
                                }, // friday
                                6: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getSaturday(week, year)
                                }, // saturday
                            };
                        }

                        // set workDayHours
                        // NO FIXED DAYS
                        workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                        // FIXED DAYS
                        if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                            workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                    : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                        : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                            : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                                : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                    : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                        }

                        yearData[year][week][dayKey].holiday = (workDayHours * 1000 * 60 * 60);
                        yearData[year][week][dayKey].holidayData = holiday;
                        index++;

                    }

                }

            }

        // ********* ADD ALL WEEKS UNTIL NOW *********
        if (this.isEmpty(yearData) === false) {

            const weekNow = paramWeek;
            const yearNow = paramYear;

            let endWeek = weekNow;

            for (const yearKey in yearData) {

                const startWeek = Object.keys(yearData[yearKey])[0];
                const currentYear = parseInt(yearKey);

                if (yearNow > currentYear) {
                    endWeek = this.weeksInYear(currentYear);
                }

                for (let i = startWeek; i <= endWeek; i++) {

                    // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                    if (this.isEmpty(yearData[yearKey]) === true) {
                        yearData[yearKey] = {};
                    }

                    if (this.isEmpty(yearData[yearKey][i]) === true) {
                        yearData[yearKey][i] = {
                            7: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSunday(i, currentYear)
                            }, // sunday
                            1: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getMonday(i, currentYear)
                            }, // monday
                            2: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getTuesday(i, currentYear)
                            }, // tuesday
                            3: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getWednesday(i, currentYear)
                            }, // wednesday
                            4: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getThursday(i, currentYear)
                            }, // thursday
                            5: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getFriday(i, currentYear)
                            }, // friday
                            6: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSaturday(i, currentYear)
                            }, // saturday
                        };
                    }

                }

            }

        }

        // ********* CALCULATIONS *********
        // just to be sure
        workDayHours = this.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : this.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        for (const yearKey in yearData) {

            // check for empties
            if (this.isEmpty(totalHoursWorked[yearKey]) === true) {
                totalHoursWorked[yearKey] = {};
            }
            if (this.isEmpty(totalHoursAbsent[yearKey]) === true) {
                totalHoursAbsent[yearKey] = {};
            }
            if (this.isEmpty(totalHoursHoliday[yearKey]) === true) {
                totalHoursHoliday[yearKey] = {};
            }
            if (this.isEmpty(totalOverhours[yearKey]) === true) {
                totalOverhours[yearKey] = {};
            }
            if (this.isEmpty(totalT4T[yearKey]) === true) {
                totalT4T[yearKey] = {};
            }
            if (this.isEmpty(holidayReduces[yearKey]) === true) {
                holidayReduces[yearKey] = {};
            }
            if (this.isEmpty(totalT4TYearly[yearKey]) === true) {
                totalT4TYearly[yearKey] = 0;
            }

            const year = parseInt(yearKey);

            for (const weekKey in yearData[yearKey]) {

                const week = parseInt(weekKey);
                const monday = this.getMonday(week, year);

                let hoursWorked_week = 0;
                let hoursAbsent_week = 0;
                let hoursHoliday_week = 0;
                let weeklyHours = 0;
                let daysUsed = 0;

                if (this.getWeekNumber2(now) < week && now.getFullYear() === year) {
                    break;
                }

                // check for empties
                if (this.isEmpty(totalHoursWorked[yearKey][weekKey]) === true) {
                    totalHoursWorked[yearKey][weekKey] = 0;
                }
                if (this.isEmpty(totalHoursAbsent[yearKey][weekKey]) === true) {
                    totalHoursAbsent[yearKey][weekKey] = 0;
                }
                if (this.isEmpty(totalHoursHoliday[yearKey][weekKey]) === true) {
                    totalHoursHoliday[yearKey][weekKey] = 0;
                }
                if (this.isEmpty(totalOverhours[yearKey][weekKey]) === true) {
                    totalOverhours[yearKey][weekKey] = 0;
                }
                if (this.isEmpty(totalT4T[yearKey][weekKey]) === true) {
                    totalT4T[yearKey][weekKey] = 0;
                }

                // CONTRACT
                let contract = this.getCurrentContract(contracts, monday);
                contract = contract !== null && contract.contract_type !== '0' && this.isEmpty(contract.contract_type) === false ? contract : null;

                if (this.isEmpty(contract) === true) {
                    continue;
                }

                const workdays = Math.round(parseFloat(contract.contract_uren) / workDayHours);

                // HOURS WORKED
                for (const dayKey in yearData[yearKey][weekKey]) {
                    hoursWorked_week += yearData[yearKey][weekKey][dayKey].hoursWorked;
                    weeklyHours += yearData[yearKey][weekKey][dayKey].hoursWorked;
                    totalHoursWorked[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].hoursWorked;

                    // TIME 4 TIME
                    totalT4T[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].t4t;
                    totalT4TYearly[yearKey] += yearData[yearKey][weekKey][dayKey].t4t;

                    if (yearData[yearKey][weekKey][dayKey].hoursWorked !== 0) {
                        daysUsed++;
                    }

                }

                // ABSENTS
                for (const dayKey in yearData[yearKey][weekKey]) {

                    if (daysUsed >= workdays) {

                        if (yearData[yearKey][weekKey][dayKey].absent !== 0 && yearData[yearKey][weekKey][dayKey].absent !== -1) {
                            yearData[yearKey][weekKey][dayKey].absent = -2;
                        }

                    } else {

                        // ziekte wachtdag
                        if (yearData[yearKey][weekKey][dayKey].absent === -1) {
                            continue;
                        }

                        hoursAbsent_week += yearData[yearKey][weekKey][dayKey].absent;
                        weeklyHours += yearData[yearKey][weekKey][dayKey].absent;
                        totalHoursAbsent[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].absent;

                        if (yearData[yearKey][weekKey][dayKey].absent !== 0) {
                            daysUsed++;
                        }

                    }

                }

                // HOLIDAYS
                for (const dayKey in yearData[yearKey][weekKey]) {

                    if (daysUsed >= workdays) {

                        // break;
                        if (yearData[yearKey][weekKey][dayKey].holiday !== 0) {
                            yearData[yearKey][weekKey][dayKey].holiday = -1;
                        }

                    } else {

                        // HOLIDAY DATA GRADED
                        if (this.isEmpty(yearData[yearKey][weekKey][dayKey].holidayData) === false) {

                            const _holidayData = yearData[yearKey][weekKey][dayKey].holidayData;

                            if (this.isEmpty(holidayReduces[yearKey][_holidayData.vakantie_begin + _holidayData.vakantie_id]) === true) {
                                holidayReduces[yearKey][_holidayData.vakantie_begin + _holidayData.vakantie_id] = {
                                    data: _holidayData,
                                    amount: 0
                                };
                            }

                            holidayReduces[yearKey][_holidayData.vakantie_begin + _holidayData.vakantie_id].amount += yearData[yearKey][weekKey][dayKey].holiday;

                        }

                        hoursHoliday_week += yearData[yearKey][weekKey][dayKey].holiday;
                        weeklyHours += yearData[yearKey][weekKey][dayKey].holiday;
                        totalHoursHoliday[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].holiday;

                        if (yearData[yearKey][weekKey][dayKey].holiday !== 0) {
                            daysUsed++;
                        }

                        // LEAVE BALANCE TOTALS
                        const holidayData = yearData[yearKey][weekKey][dayKey].holidayData;

                        if (this.isEmpty(holidayData) === false) {

                            // IS CONNECTED TO LEAVE TYPE
                            if (this.isEmpty(holidayData.vakantie_vt_id) === false) {

                                const selectedLeaveBalance = leaveTypeBalanceObj[holidayData.vakantie_vt_id][0].vtb_vb_id;

                                if (this.isEmpty(totalHolidayBalance[selectedLeaveBalance]) === true) {
                                    totalHolidayBalance[selectedLeaveBalance] = {};
                                }

                                if (this.isEmpty(totalHolidayBalance[selectedLeaveBalance][yearKey]) === true) {
                                    totalHolidayBalance[selectedLeaveBalance][yearKey] = 0;
                                }

                                totalHolidayBalance[selectedLeaveBalance][yearKey] += yearData[yearKey][weekKey][dayKey].holiday;

                            } else {

                                if (this.isEmpty(totalHolidayBalance[leaveBalances[0].vb_id]) === true) {
                                    totalHolidayBalance[leaveBalances[0].vb_id] = {};
                                }

                                if (this.isEmpty(totalHolidayBalance[leaveBalances[0].vb_id][yearKey]) === true) {
                                    totalHolidayBalance[leaveBalances[0].vb_id][yearKey] = 0;
                                }

                                totalHolidayBalance[leaveBalances[0].vb_id][yearKey] += yearData[yearKey][weekKey][dayKey].holiday;

                            }

                        }

                    }

                }

                // OVERHOURS
                //const isNegative = weeklyHours < (parseFloat(contract.contract_uren) * 1000 * 60 * 60);
                let overhours = 0;

                if (contract.contract_type === '2') {

                    overhours = weeklyHours - (parseFloat(contract.contract_uren) * 1000 * 60 * 60);

                } else if (contract.contract_type === '1') {

                    if (weeklyHours < (parseFloat(contract.contract_uren) * 1000 * 60 * 60)) {
                        overhours = weeklyHours - (parseFloat(contract.contract_uren) * 1000 * 60 * 60);
                    } else if (weeklyHours > (parseFloat(contract.contract_max_uren) * 1000 * 60 * 60)) {
                        overhours = weeklyHours - (parseFloat(contract.contract_max_uren) * 1000 * 60 * 60);
                    }

                }

                if (now.getFullYear() > year || (this.getWeekNumber2(now) > week && now.getFullYear() === year)) {
                    totalOverhours[yearKey][weekKey] = overhours;
                }

            }

        }

        // ********* OVERHOURS YEARLY OBJECT *********
        let totalOverhoursYearly = {};

        for (const yearKey in totalOverhours) {

            if (this.isEmpty(totalOverhoursYearly[yearKey]) === true) {
                totalOverhoursYearly[yearKey] = 0;
            }

            for (const weekKey in totalOverhours[yearKey]) {
                totalOverhoursYearly[yearKey] += totalOverhours[yearKey][weekKey];
            }

        }

        // ********* LEAVE BALANCES *********
        let totalContractHoursYear = {};
        let userLeaveBalances = [];

        // CALCULATE CONTRACT HOURS WHOLE YEAR
        for (const c of contracts) {

            if (this.isTrue(c.contract_opbouw_verlof) === false) {
                continue;
            }

            const contractStart = this.isEmpty(c.contract_begin) === false ? this.stringToDate(c.contract_begin) : null;
            const contractEnd = this.isEmpty(c.contract_eind) === false ? this.stringToDate(c.contract_eind) : null;

            if (this.isEmpty(contractStart) === true) {
                continue;
            }

            if (this.isEmpty(totalContractHoursYear[contractStart.getFullYear()]) === true) {
                totalContractHoursYear[contractStart.getFullYear()] = 0;
            }

            // START CALCULATING LEAVE BALANCES
            if (this.isEmpty(contractEnd) === true) {

                const currentYear = now.getFullYear();

                for (let i = contractStart.getFullYear(); i <= currentYear; i++) {

                    if (i > currentYear) {
                        break;
                    }

                    if (this.isEmpty(totalContractHoursYear[i]) === true) {
                        totalContractHoursYear[i] = 0;
                    }

                    let _contractStart = this.stringToDate(`${i}-01-01`);

                    if (i === contractStart.getFullYear()) {
                        _contractStart = this.stringToDate(this.dateToString(contractStart));
                    }

                    const _contractEnd = this.stringToDate(`${i}-12-31`);
                    const weeks = this.weeksBetween(_contractStart, _contractEnd);

                    // VAST
                    if (c.contract_type === '2') {

                        totalContractHoursYear[i] += weeks * (parseFloat(c.contract_uren) * 60 * 60 * 1000);

                        // MIN-MAX OF FLEX
                    } else {

                        for (const weekKey in yearData[i]) {

                            // start contract week higher than worked week
                            if (this.getWeekNumber2(_contractStart) > parseInt(weekKey)) {
                                continue;
                            }

                            for (const dayKey in yearData[i][weekKey]) {

                                // start contract higher than worked date
                                if (_contractStart.getTime() > yearData[i][weekKey][dayKey].date.getTime()) {
                                    continue;
                                }

                                totalContractHoursYear[i] += (yearData[i][weekKey][dayKey].hoursWorked);

                            }

                        }

                    }

                }

            } else {

                const currentYear = now.getFullYear();

                for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                    if (i > contractEnd.getFullYear() || i > currentYear) {
                        break;
                    }

                    if (this.isEmpty(totalContractHoursYear[i]) === true) {
                        totalContractHoursYear[i] = 0;
                    }

                    let _contractStart = this.stringToDate(`${i}-01-01`);
                    let _contractEnd = this.stringToDate(`${i}-12-31`);

                    if (i === contractStart.getFullYear()) {
                        _contractStart = this.stringToDate(this.dateToString(contractStart));
                    }

                    if (i === contractEnd.getFullYear()) {
                        _contractEnd = this.stringToDate(this.dateToString(contractEnd));
                    }

                    const weeks = this.weeksBetween(_contractStart, _contractEnd);

                    // VAST
                    if (c.contract_type === '2') {

                        totalContractHoursYear[i] += weeks * (parseFloat(c.contract_uren) * 60 * 60 * 1000);

                        // MIN-MAX OF FLEX
                    } else {

                        for (const weekKey in yearData[i]) {

                            // start contract week higher than worked week
                            if (this.getWeekNumber2(_contractStart) > parseInt(weekKey) || this.getWeekNumber2(_contractEnd) < parseInt(weekKey)) {
                                continue;
                            }

                            for (const dayKey in yearData[i][weekKey]) {

                                // start contract higher than worked date
                                if (_contractStart.getTime() > yearData[i][weekKey][dayKey].date.getTime() || _contractEnd.getTime() < yearData[i][weekKey][dayKey].date.getTime()) {
                                    continue;
                                }

                                totalContractHoursYear[i] += (yearData[i][weekKey][dayKey].hoursWorked);

                            }

                        }

                    }

                }

            }

        }

        // create leave balances user
        for (const leaveBalance of leaveBalances) {

            let _balance = {};
            let _reduce = {};
            let _total = {};

            for (const yearKey in totalContractHoursYear) {

                if (this.isEmpty(_balance[yearKey]) === true) {
                    _balance[yearKey] = 0;
                }
                if (this.isEmpty(_reduce[yearKey]) === true) {
                    _reduce[yearKey] = 0;
                }
                if (this.isEmpty(_total[yearKey]) === true) {
                    _total[yearKey] = 0;
                }

                _balance[yearKey] += (totalContractHoursYear[yearKey] * parseFloat(leaveBalance.vb_factor));

                if (this.isEmpty(totalHolidayBalance) === false && this.isEmpty(totalHolidayBalance[leaveBalance.vb_id]) === false && this.isEmpty(totalHolidayBalance[leaveBalance.vb_id][yearKey]) === false) {
                    _reduce[yearKey] += totalHolidayBalance[leaveBalance.vb_id][yearKey];
                }

                _total[yearKey] = _balance[yearKey] - _reduce[yearKey];

            }

            userLeaveBalances.push({
                ...leaveBalance,
                balance: _balance,
                reduce: _reduce,
                total: _total,
            });

        }

        // ********* CORRECTIONS *********
        let totalOverhoursCorrection = {};
        let totalHolidayCorrection = {};
        let totalT4TCorrection = {};

        // TIME 4 TIME // FUSE ALL 3 DATAS HERE
        let time4timeCorrections_data = [];
        let time4timeCorrectionsYearly = {};

        // T4T OVERHOURS
        for (const t of time4timeCorrectionsData_overhours) {

            time4timeCorrections_data.push({
                ...t,
                amount: parseFloat(t.co_aantal) > 0 ? -parseFloat(t.co_aantal) : parseFloat(t.co_aantal),
                by: t.co_info_id_door,
                dateString: t.co_datum,
                payout: t.co_betaling,
                note: t.co_notitie,
                date: this.stringToDate(t.co_datum),
                dateMS: this.stringToDate(t.co_datum).getTime(),
            });

        }

        // T4T HOLIDAYS
        for (const t of time4timeCorrectionsData_holiday) {

            time4timeCorrections_data.push({
                ...t,
                amount: parseFloat(t.cv_aantal) > 0 ? -parseFloat(t.cv_aantal) : parseFloat(t.cv_aantal),
                by: t.cv_info_id_door,
                dateString: t.cv_datum,
                payout: t.cv_betaling,
                note: t.cv_notitie,
                date: this.stringToDate(t.cv_datum),
                dateMS: this.stringToDate(t.cv_datum).getTime(),
            });

        }

        // T4T DB
        for (const t of time4timeCorrectionsDataDB) {

            time4timeCorrections_data.push({
                ...t,
                amount: parseFloat(t.ct_aantal),
                by: t.ct_info_id_door,
                dateString: t.ct_datum,
                payout: t.ct_betaling,
                note: t.ct_notitie,
                date: this.stringToDate(t.ct_datum),
                dateMS: this.stringToDate(t.ct_datum).getTime(),
            });

        }

        // SORT BY DATE
        time4timeCorrections_data.sort((a, b) => (a.dateMS > b.dateMS) ? 1 : -1);

        // TO YEARLY OBJECT
        for (const t of time4timeCorrections_data) {

            const t4tYear = t.date.getFullYear();

            if (this.isEmpty(time4timeCorrectionsYearly[t4tYear]) === true) {
                time4timeCorrectionsYearly[t4tYear] = 0;
            }

            time4timeCorrectionsYearly[t4tYear] += (t.amount * 1000 * 60 * 60);

        }

        // TIME 4 TIME
        for (const yearKey in time4timeCorrectionsYearly) {

            if (this.isEmpty(totalT4TYearly[yearKey]) === true) {
                totalT4TYearly[yearKey] = 0;
            }

            totalT4TYearly[yearKey] += time4timeCorrectionsYearly[yearKey];

        }

        // OVERHOURS
        for (const overhourCorrection of overhoursCorrectionsData) {

            const correctionDate = this.stringToDate(overhourCorrection.co_datum);
            const year = correctionDate.getFullYear();
            // const week = this.getWeekNumber2(correctionDate);

            // if (this.isEmpty(totalOverhoursCorrection[year]) === true) {
            //     totalOverhoursCorrection[year] = {};
            // }
            // if (this.isEmpty(totalOverhoursCorrection[year][week]) === true) {
            //     totalOverhoursCorrection[year][week] = 0;
            // }

            // totalOverhoursCorrection[year][week] += parseFloat(overhourCorrection.co_aantal);

            if (this.isEmpty(totalOverhoursYearly[year]) === true) {
                totalOverhoursYearly[year] = 0;
            }

            totalOverhoursYearly[year] += (parseFloat(overhourCorrection.co_aantal) * 1000 * 60 * 60);

        }

        // HOLIDAYS
        for (const holidayCorrection of holidayCorrectionsData) {

            const correctionDate = this.stringToDate(holidayCorrection.cv_datum);
            const year = correctionDate.getFullYear();
            //const week = this.getWeekNumber2(correctionDate);

            // if (this.isEmpty(totalHolidayCorrection[year]) === true) {
            //     totalHolidayCorrection[year] = {};
            // }
            // if (this.isEmpty(totalHolidayCorrection[year][week]) === true) {
            //     totalHolidayCorrection[year][week] = 0;
            // }

            // totalHolidayCorrection[year][week] += parseFloat(holidayCorrection.cv_aantal);

            let userLeaveBalance = this.getArrayItem(userLeaveBalances, 'vb_id', holidayCorrection.cv_vb_id);

            if (this.isEmpty(userLeaveBalance) === false) {

                if (this.isEmpty(userLeaveBalance.total[year]) === true) {
                    userLeaveBalance.total[year] = 0;
                }

                userLeaveBalance.total[year] += (parseFloat(holidayCorrection.cv_aantal) * 1000 * 60 * 60);

            }

        }

        return {

            // userID
            userID: userID,

            // contracts
            contracts: contracts,

            // all year data
            yearData: yearData,

            // all data total separated in weeks
            totalHoursWorked: totalHoursWorked,
            totalHoursAbsent: totalHoursAbsent,
            totalHoursHoliday: totalHoursHoliday,
            totalOverhours: totalOverhours,
            totalT4T: totalT4T,

            // holiday requests graded
            holidayReduces: holidayReduces,

            // all data total separated in years
            totalOverhoursYearly: totalOverhoursYearly,
            totalT4TYearly: totalT4TYearly,

            // corrections objects
            overhoursCorrections: overhoursCorrectionsData,
            holidaysCorrections: holidayCorrectionsData,
            time4timeCorrections: time4timeCorrections_data,

            // contract leave balances
            leaveBalances: leaveBalances,
            contractLeaveBalances: contractLeaveBalancesObj,

            // user leave balances
            userLeaveBalances: userLeaveBalances,

        };

    }

    async calculateAllHours_users(paramWeek = this.getWeekNumber2(new Date()), paramYear = new Date().getFullYear(), now = new Date()) {

        let allUsersHours = {};

        let workDayHours = this.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : this.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        const [
            accounts,
            hoursWorked,
            clocked,
            holidays,
            absents,
            contracts,
            overhoursCorrectionsData,
            time4timeCorrectionsData_overhours,
            time4timeCorrectionsData_holiday,
            time4timeCorrectionsDataDB,
            holidayCorrectionsData,
            contractLeaveBalances,
            leaveBalances,
            leaveTypeBalance,
            surcharges,
            schedules,
            breakRules,
        ] = await Promise.all([
            APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id`, null, null, null),
            APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, null, null, `ORDER BY uren_begindatum ASC`),
            APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id LEFT JOIN Uren ON uren_kloktijd_id = kloktijd_id`, `WHERE uren_id IS NULL`, null, `ORDER BY kloktijd_inklok_datum ASC`),
            APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN VerlofSaldo ON vakantie_id = vs_vakantie_id LEFT JOIN Info ON vakantie_info_id = info_id`, `WHERE vakantie_geaccepteerd = 1`, null, null),
            APIGET.Request(`SELECT *`, `FROM AfwezigheidN`, `LEFT JOIN Afwezigheid ON afwn_afw_id = afw_id LEFT JOIN Info ON afwn_info_id = info_id LEFT JOIN Uitzondering ON uit_afw_id = afwn_afw_id`, `WHERE uit_id IS NOT NULL`, null, null), // absence_n
            APIGET.Request(`SELECT *`, `FROM ContractN`, null, null, null, null),
            APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, null, null, `ORDER BY co_datum DESC, co_id DESC`),
            APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, `WHERE co_tvt = 1`, null, `ORDER BY co_datum DESC, co_id DESC`),
            APIGET.Request(`SELECT CorrectieVerlof.*, VerlofBalans.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieVerlof`, `LEFT JOIN VerlofBalans ON cv_vb_id = vb_id LEFT JOIN Info ON cv_info_id_door = info_id`, `WHERE cv_tvt = 1`, null, `ORDER BY cv_datum DESC, cv_id DESC`),
            APIGET.Request(`SELECT CorrectieT4T.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieT4T`, `LEFT JOIN Info ON ct_info_id_door = info_id`, null, null, `ORDER BY ct_datum DESC, ct_id DESC`),
            APIGET.Request(`SELECT CorrectieVerlof.*, VerlofBalans.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieVerlof`, `LEFT JOIN VerlofBalans ON cv_vb_id = vb_id LEFT JOIN Info ON cv_info_id_door = info_id`, null, null, `ORDER BY cv_datum DESC, cv_id DESC`),
            APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, null, null, null, null),
            APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
            APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vb_id = vtb_vb_id`, null, null, `ORDER BY vb_factor DESC`),
            APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, null),
            APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Uren ON uren_rooster_id = rooster_id`, `WHERE rooster_publiceren = 1 AND uren_id IS NULL`, null, null),
            APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),
        ]);

        let newHoursWorked = [];

        // combine clocked and hours
        for (const hour of hoursWorked) {
            newHoursWorked.push(hour);
        }
        for (const clock of clocked) {

            let _break = clock.kloktijd_pauze.substr(0, 8);
            if (Data.data.appOptions.brk === 'PLANNER') {
                _break = this.getUserPlannerBreak(clock.info_id, clock.kloktijd_inklok_datum, schedules);
            } else if (Data.data.appOptions.brk === 'CALC') {
                _break = this.getBreakRule(clock.kloktijd_inklok_datum, clock.kloktijd_inklok_tijd, clock.kloktijd_uitklok_datum, clock.kloktijd_uitklok_tijd, null, breakRules).str.substr(0, 5);
            }

            newHoursWorked.push({
                ...clock,
                uren_id: null,
                uren_info_id: clock.info_id,
                uren_begindatum: clock.kloktijd_inklok_datum,
                uren_einddatum: clock.kloktijd_uitklok_datum,
                uren_begin: clock.kloktijd_inklok_tijd,
                uren_eind: clock.kloktijd_uitklok_tijd,
                uren_pauze: _break,
            });

        }

        // attach arrays to users
        const hoursWorked_obj = this.convertToObjectWithArrays(newHoursWorked, 'uren_info_id');
        const holidays_obj = this.convertToObjectWithArrays(holidays, 'vakantie_info_id');
        const absents_obj = this.convertToObjectWithArrays(absents, 'afwn_info_id');
        const contracts_obj = this.convertToObjectWithArrays(contracts, 'contract_info_id');
        const overhoursCorrectionsData_obj = this.convertToObjectWithArrays(overhoursCorrectionsData, 'co_info_id');
        const time4timeCorrectionsData_overhours_obj = this.convertToObjectWithArrays(time4timeCorrectionsData_overhours, 'co_info_id');
        const time4timeCorrectionsData_holiday_obj = this.convertToObjectWithArrays(time4timeCorrectionsData_holiday, 'cv_info_id');
        const time4timeCorrectionsDataDB_obj = this.convertToObjectWithArrays(time4timeCorrectionsDataDB, 'ct_info_id');
        const holidayCorrectionsData_obj = this.convertToObjectWithArrays(holidayCorrectionsData, 'cv_info_id');

        const contractLeaveBalancesObj = this.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');
        const leaveTypeBalanceObj = this.convertToObjectWithArrays(leaveTypeBalance, 'vtb_vt_id');

        // all account objects
        for (const a of accounts) {

            if (this.isEmpty(allUsersHours[a.info_id]) === true) {

                allUsersHours[a.info_id] = {

                    // userID
                    userID: a.info_id,

                    // user data
                    userData: a,

                    // contracts
                    contracts: [],

                    // all year data
                    yearData: {},

                    // all data total separated in weeks
                    totalHoursWorked: {},
                    totalHoursAbsent: {},
                    totalHoursHoliday: {},
                    totalOverhours: {},
                    totalT4T: {},

                    // holiday requests graded
                    holidayReduces: {},

                    // all data total separated in years
                    totalOverhoursYearly: {},
                    totalT4TYearly: {},

                    // corrections objects
                    overhoursCorrections: [],
                    holidaysCorrections: [],
                    time4timeCorrections: [],

                    // contract leave balances
                    leaveBalances: leaveBalances,
                    contractLeaveBalances: contractLeaveBalancesObj,

                    // user leave balances
                    userLeaveBalances: [],

                };

            }

        }

        for (const accountKey in allUsersHours) {

            let firstYear = now.getFullYear();

            // all the information seperated in years | yearData[year][week][day].variable | yearData[2022][45][1].hoursWorked
            let yearData = {};

            // ********* TOTALS *********
            let totalHoursWorked = {};
            let totalHoursAbsent = {};
            let totalHoursHoliday = {};
            let totalOverhours = {}; // totalOverhours[year]: 0
            let totalT4T = {};
            let totalHolidayBalance = {};
            let holidayReduces = {};
            let totalT4TYearly = {};

            // ADDS ALL CONTRACT YEARS
            // for (const c of contracts) {

            //     if (this.isEmpty(c.contract_begin) === true) {
            //         continue;
            //     }

            //     const startDate = this.stringToDate(c.contract_begin);
            //     const year = startDate.getFullYear();
            //     const week = this.getWeekNumber2(startDate);

            //     const weeks = this.getWeeksStartAndEndInMonth(startDate.getMonth(), year);

            //     // first get weeks of date between first year and second year EG: jan 1 2022 is week 52
            //     for (const w of weeks) {

            //         let _year = w.year;
            //         if (w.year1 !== w.year) {
            //             _year = w.year1;
            //         }

            //         if (this.getMonday(w.week, _year).getTime() < startDate.getTime()) {
            //             continue;
            //         }

            //         if (this.isEmpty(yearData[_year]) === true) {
            //             yearData[_year] = {};
            //         }

            //         if (this.isEmpty(yearData[_year][w.week]) === true) {
            //             yearData[_year][w.week] = {
            //                 7: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSunday(w.week, _year) }, // sunday
            //                 1: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getMonday(w.week, _year) }, // monday
            //                 2: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getTuesday(w.week, _year) }, // tuesday
            //                 3: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getWednesday(w.week, _year) }, // wednesday
            //                 4: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getThursday(w.week, _year) }, // thursday
            //                 5: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getFriday(w.week, _year) }, // friday
            //                 6: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSaturday(w.week, _year) }, // saturday
            //             };
            //         }

            //     }

            //     // continue with just contract date stuff
            //     if (this.isEmpty(yearData[year]) === true) {
            //         yearData[year] = {};
            //     }

            //     if (this.isEmpty(yearData[year][week]) === true) {
            //         yearData[year][week] = {
            //             7: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSunday(week, year) }, // sunday
            //             1: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getMonday(week, year) }, // monday
            //             2: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getTuesday(week, year) }, // tuesday
            //             3: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getWednesday(week, year) }, // wednesday
            //             4: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getThursday(week, year) }, // thursday
            //             5: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getFriday(week, year) }, // friday
            //             6: { hoursWorked: 0, absent: 0, holiday: 0, overhours: 0, t4t: 0, holidayData: null, date: this.getSaturday(week, year) }, // saturday
            //         };
            //     }

            // }

            // FIRST DAY WORKED
            let first_working_date = null;

            // GET OUT OF ORDER DATE
            let out_of_order_date = null;

            if (this.isEmpty(contracts_obj[accountKey]) === false) {

                let latestDate = null;

                for (const c of contracts_obj[accountKey]) {

                    if (this.isEmpty(c.contract_eind) === true) {
                        latestDate = null;
                        out_of_order_date = null;
                        break;
                    }

                    const endDate = this.stringToDate(c.contract_eind);

                    if (latestDate === null || (latestDate !== null && latestDate.getTime() < endDate.getTime())) {
                        latestDate = endDate;
                    }

                }

                out_of_order_date = latestDate;

            }

            // HOURS WORKED
            if (this.isEmpty(hoursWorked_obj[accountKey]) === false) {

                // set first year worked
                if (hoursWorked_obj[accountKey].length > 0) {
                    firstYear = this.stringToDate(hoursWorked_obj[accountKey][0].uren_begindatum).getFullYear();
                }

                // HOURS WORKED
                for (const hour of hoursWorked_obj[accountKey]) {

                    const date = this.stringToDate(hour.uren_begindatum);
                    const year = this.getBeginOfWeek(date).getFullYear();
                    const week = this.getWeekNumber2(date);
                    const day = date.getDay();
                    const dayKey = day === 0 ? '7' : day.toString();

                    const contract = this.getCurrentContract(contracts_obj[accountKey], date);

                    // first working date
                    if (first_working_date === null) {
                        first_working_date = date;
                    }

                    // if contract is not there, move on
                    if (this.isEmpty(contract) === true) {
                        continue;
                    }

                    // set workDayHours
                    // NO FIXED DAYS
                    workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                    // FIXED DAYS
                    if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                        workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                            : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                    : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                        : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                            : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                    }

                    // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                    if (this.isEmpty(yearData[year]) === true) {
                        yearData[year] = {};
                    }

                    if (this.isEmpty(yearData[year][week]) === true) {
                        yearData[year][week] = {
                            7: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSunday(week, year)
                            }, // sunday
                            1: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getMonday(week, year)
                            }, // monday
                            2: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getTuesday(week, year)
                            }, // tuesday
                            3: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getWednesday(week, year)
                            }, // wednesday
                            4: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getThursday(week, year)
                            }, // thursday
                            5: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getFriday(week, year)
                            }, // friday
                            6: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSaturday(week, year)
                            }, // saturday
                        };
                    }

                    const startDate = this.stringToDateTime(hour.uren_begindatum, hour.uren_begin);
                    const endDate = this.stringToDateTime(hour.uren_einddatum, hour.uren_eind);
                    const breakTime = this.timeStringToMS(hour.uren_pauze);

                    const workedDay = (endDate.getTime() - startDate.getTime()) - breakTime;
                    const overhours = (workedDay - (workDayHours * 1000 * 60 * 60));

                    yearData[year][week][dayKey].hoursWorked += workedDay;
                    yearData[year][week][dayKey].overhours += overhours;

                    // TIME 4 TIME
                    const workedDay_bonus = planClass.calcBonus_t4t(hour, (workedDay / 1000), surcharges, true);
                    yearData[year][week][dayKey].t4t += workedDay_bonus * 1000;

                }

            }

            // ABSENTS
            if (this.isEmpty(absents_obj[accountKey]) === false) {

                for (const absent of absents_obj[accountKey]) {

                    const date = this.stringToDate(absent.afwn_datum);
                    const year = date.getFullYear();
                    const week = this.getWeekNumber2(date);
                    const day = date.getDay();
                    const dayKey = day === 0 ? '7' : day.toString();

                    const contract = this.getCurrentContract(contracts_obj[accountKey], date);

                    // if contract is not there, move on
                    if (this.isEmpty(contract) === true) {
                        continue;
                    }

                    // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                    // if (this.isEmpty(yearData[year]) === true || this.isEmpty(yearData[year][week]) === true) {
                    //     continue;
                    // }
                    if (this.isEmpty(yearData[year]) === true) {
                        yearData[year] = {};
                    }

                    if (this.isEmpty(yearData[year][week]) === true) {
                        yearData[year][week] = {
                            7: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSunday(week, year)
                            }, // sunday
                            1: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getMonday(week, year)
                            }, // monday
                            2: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getTuesday(week, year)
                            }, // tuesday
                            3: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getWednesday(week, year)
                            }, // wednesday
                            4: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getThursday(week, year)
                            }, // thursday
                            5: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getFriday(week, year)
                            }, // friday
                            6: {
                                hoursWorked: 0,
                                absent: 0,
                                holiday: 0,
                                overhours: 0,
                                t4t: 0,
                                holidayData: null,
                                date: this.getSaturday(week, year)
                            }, // saturday
                        };
                    }

                    if (this.isTrue(absent.afwn_ziekteWachtdag) === true) {
                        yearData[year][week][dayKey].absent = -1;
                        continue;
                    }

                    // set workDayHours
                    // NO FIXED DAYS
                    workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

                    // FIXED DAYS
                    if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                        workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                            : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                    : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                        : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                            : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                    }

                    yearData[year][week][dayKey].absent = (workDayHours * 1000 * 60 * 60);

                }

            }

            // HOLIDAYS
            if (this.isEmpty(holidays_obj[accountKey]) === false) {

                loopHoliday:
                    for (const holiday of holidays_obj[accountKey]) {

                        const startDate = this.stringToDate(holiday.vakantie_begin);
                        const endDate = this.stringToDate(holiday.vakantie_eind);
                        const sameDay = holiday.vakantie_begin === holiday.vakantie_eind;

                        if (sameDay === true) {

                            const year = startDate.getFullYear();
                            const week = this.getWeekNumber2(startDate);
                            const day = startDate.getDay();
                            const dayKey = day === 0 ? '7' : day.toString();

                            const contract = this.getCurrentContract(contracts_obj[accountKey], startDate);

                            // if contract is not there, move on
                            if (this.isEmpty(contract) === true) {
                                continue;
                            }

                            // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                            // if (this.isEmpty(yearData[year]) === true || this.isEmpty(yearData[year][week]) === true) {
                            //     continue;
                            // }
                            if (this.isEmpty(yearData[year]) === true) {
                                yearData[year] = {};
                            }

                            if (this.isEmpty(yearData[year][week]) === true) {
                                yearData[year][week] = {
                                    7: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getSunday(week, year)
                                    }, // sunday
                                    1: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getMonday(week, year)
                                    }, // monday
                                    2: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getTuesday(week, year)
                                    }, // tuesday
                                    3: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getWednesday(week, year)
                                    }, // wednesday
                                    4: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getThursday(week, year)
                                    }, // thursday
                                    5: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getFriday(week, year)
                                    }, // friday
                                    6: {
                                        hoursWorked: 0,
                                        absent: 0,
                                        holiday: 0,
                                        overhours: 0,
                                        t4t: 0,
                                        holidayData: null,
                                        date: this.getSaturday(week, year)
                                    }, // saturday
                                };
                            }

                            // set workDayHours
                            // NO FIXED DAYS
                            if (!this.isEmpty(contract) &&
                                !this.isEmpty(contract.contract_uren) &&
                                !this.isEmpty(contract.contract_werkdag_uren) &&
                                contract.contract_werkdag_uren !== '0') {

                                const contractUren = parseFloat(contract.contract_uren);
                                const contractWerkdagUren = parseFloat(contract.contract_werkdag_uren);
                                const contractDagen = Math.round(contractUren / contractWerkdagUren);
                                workDayHours = contractUren / contractDagen;
                            }

                            // FIXED DAYS
                            if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                                workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                    : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                        : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                            : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                                : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                                    : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                        : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                            }


                            yearData[year][week][dayKey].holiday = (workDayHours * 1000 * 60 * 60);
                            yearData[year][week][dayKey].holidayData = holiday;

                        } else {

                            let index = 0;
                            while (new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

                                const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

                                const year = currentDate.getFullYear();
                                const week = this.getWeekNumber2(currentDate);
                                const day = currentDate.getDay();
                                const dayKey = day === 0 ? '7' : day.toString();

                                const contract = this.getCurrentContract(contracts_obj[accountKey], currentDate);

                                // if contract is not there, move on
                                if (this.isEmpty(contract) === true) {
                                    continue loopHoliday;
                                }

                                // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                                // if (this.isEmpty(yearData[year]) === true || this.isEmpty(yearData[year][week]) === true) {
                                //     continue loopHoliday;
                                // }
                                if (this.isEmpty(yearData[year]) === true) {
                                    yearData[year] = {};
                                }

                                if (this.isEmpty(yearData[year][week]) === true) {
                                    yearData[year][week] = {
                                        7: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getSunday(week, year)
                                        }, // sunday
                                        1: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getMonday(week, year)
                                        }, // monday
                                        2: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getTuesday(week, year)
                                        }, // tuesday
                                        3: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getWednesday(week, year)
                                        }, // wednesday
                                        4: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getThursday(week, year)
                                        }, // thursday
                                        5: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getFriday(week, year)
                                        }, // friday
                                        6: {
                                            hoursWorked: 0,
                                            absent: 0,
                                            holiday: 0,
                                            overhours: 0,
                                            t4t: 0,
                                            holidayData: null,
                                            date: this.getSaturday(week, year)
                                        }, // saturday
                                    };
                                }

                                // set workDayHours
                                // NO FIXED DAYS
                                if (!this.isEmpty(contract) &&
                                    !this.isEmpty(contract.contract_uren) &&
                                    !this.isEmpty(contract.contract_werkdag_uren) &&
                                    contract.contract_werkdag_uren !== '0') {

                                    const contractUren = parseFloat(contract.contract_uren);
                                    const contractWerkdagUren = parseFloat(contract.contract_werkdag_uren);
                                    const contractDagen = Math.round(contractUren / contractWerkdagUren);
                                    workDayHours = contractUren / contractDagen;
                                }
                                // FIXED DAYS
                                if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                                    workDayHours = day === 1 ? (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                        : day === 2 ? (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                            : day === 3 ? (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                                : day === 4 ? (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                                    : day === 5 ? (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                                        : day === 6 ? (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                                            : (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                                }

                                yearData[year][week][dayKey].holiday = (workDayHours * 1000 * 60 * 60);
                                yearData[year][week][dayKey].holidayData = holiday;
                                index++;

                            }

                        }

                    }

            }

            // ********* ADD ALL WEEKS UNTIL NOW *********
            if (this.isEmpty(yearData) === false) {

                const weekNow = paramWeek;
                const yearNow = paramYear;

                let endWeek = weekNow;

                for (const yearKey in yearData) {

                    endWeek = weekNow;

                    const startWeek = Object.keys(yearData[yearKey])[0];
                    const currentYear = parseInt(yearKey);

                    if (yearNow > currentYear) {
                        endWeek = this.weeksInYear(currentYear);
                    }

                    for (let i = startWeek; i <= endWeek; i++) {

                        // create weeks in yearData and create days in weeks | 0 = sunday, 6 = saturday
                        if (this.isEmpty(yearData[yearKey]) === true) {
                            yearData[yearKey] = {};
                        }

                        if (this.isEmpty(yearData[yearKey][i]) === true) {
                            yearData[yearKey][i] = {
                                7: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getSunday(i, currentYear)
                                }, // sunday
                                1: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getMonday(i, currentYear)
                                }, // monday
                                2: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getTuesday(i, currentYear)
                                }, // tuesday
                                3: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getWednesday(i, currentYear)
                                }, // wednesday
                                4: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getThursday(i, currentYear)
                                }, // thursday
                                5: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getFriday(i, currentYear)
                                }, // friday
                                6: {
                                    hoursWorked: 0,
                                    absent: 0,
                                    holiday: 0,
                                    overhours: 0,
                                    t4t: 0,
                                    holidayData: null,
                                    date: this.getSaturday(i, currentYear)
                                }, // saturday
                            };
                        }

                    }

                }

            }

            // ********* CALCULATIONS *********
            // just to be sure
            workDayHours = this.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : this.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

            for (const yearKey in yearData) {

                // check for empties
                if (this.isEmpty(totalHoursWorked[yearKey]) === true) {
                    totalHoursWorked[yearKey] = {};
                }
                if (this.isEmpty(totalHoursAbsent[yearKey]) === true) {
                    totalHoursAbsent[yearKey] = {};
                }
                if (this.isEmpty(totalHoursHoliday[yearKey]) === true) {
                    totalHoursHoliday[yearKey] = {};
                }
                if (this.isEmpty(totalOverhours[yearKey]) === true) {
                    totalOverhours[yearKey] = {};
                }
                if (this.isEmpty(totalT4T[yearKey]) === true) {
                    totalT4T[yearKey] = {};
                }
                if (this.isEmpty(holidayReduces[yearKey]) === true) {
                    holidayReduces[yearKey] = {};
                }
                if (this.isEmpty(totalT4TYearly[yearKey]) === true) {
                    totalT4TYearly[yearKey] = 0;
                }

                const year = parseInt(yearKey);

                for (const weekKey in yearData[yearKey]) {

                    const week = parseInt(weekKey);
                    const monday = this.getMonday(week, year);

                    let hoursWorked_week = 0;
                    let hoursAbsent_week = 0;
                    let hoursHoliday_week = 0;
                    let weeklyHours = 0;
                    let daysUsed = 0;

                    // if (this.getWeekNumber2(now) < week && now.getFullYear() === year) {
                    //     break;
                    // }

                    // check for empties
                    if (this.isEmpty(totalHoursWorked[yearKey][weekKey]) === true) {
                        totalHoursWorked[yearKey][weekKey] = 0;
                    }
                    if (this.isEmpty(totalHoursAbsent[yearKey][weekKey]) === true) {
                        totalHoursAbsent[yearKey][weekKey] = 0;
                    }
                    if (this.isEmpty(totalHoursHoliday[yearKey][weekKey]) === true) {
                        totalHoursHoliday[yearKey][weekKey] = 0;
                    }
                    if (this.isEmpty(totalOverhours[yearKey][weekKey]) === true) {
                        totalOverhours[yearKey][weekKey] = 0;
                    }
                    if (this.isEmpty(totalT4T[yearKey][weekKey]) === true) {
                        totalT4T[yearKey][weekKey] = 0;
                    }

                    // CONTRACT
                    let contract = this.getCurrentContract(contracts_obj[accountKey], monday);
                    contract = contract !== null && contract.contract_type !== '0' && this.isEmpty(contract.contract_type) === false ? contract : null;

                    if (this.isEmpty(contract) === true) {
                        continue;
                    }

                    let workdays = 0;

                    // fixed days
                    if (this.isEmpty(contract) === false && this.isTrue(contract.contract_vaste_dagen)) {
                        if (this.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false) {
                            workdays += 1;
                        }
                        if (this.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false) {
                            workdays += 1;
                        }
                        if (this.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false) {
                            workdays += 1;
                        }
                        if (this.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false) {
                            workdays += 1;
                        }
                        if (this.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false) {
                            workdays += 1;
                        }
                        if (this.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false) {
                            workdays += 1;
                        }
                        if (this.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false) {
                            workdays += 1;
                        }
                        // no fixed days
                    } else {
                        workDayHours = this.isEmpty(contract) === false && this.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? this.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;
                        workdays = Math.round(parseFloat(contract.contract_uren) / workDayHours);
                    }

                    // HOURS WORKED
                    for (const dayKey in yearData[yearKey][weekKey]) {
                        hoursWorked_week += yearData[yearKey][weekKey][dayKey].hoursWorked;
                        weeklyHours += yearData[yearKey][weekKey][dayKey].hoursWorked;
                        totalHoursWorked[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].hoursWorked;

                        // TIME 4 TIME
                        totalT4T[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].t4t;
                        totalT4TYearly[yearKey] += yearData[yearKey][weekKey][dayKey].t4t;

                        if (yearData[yearKey][weekKey][dayKey].hoursWorked !== 0) {
                            daysUsed++;
                        }

                    }

                    // ABSENTS
                    for (const dayKey in yearData[yearKey][weekKey]) {

                        if (daysUsed >= workdays) {

                            if (yearData[yearKey][weekKey][dayKey].absent !== 0 && yearData[yearKey][weekKey][dayKey].absent !== -1) {
                                yearData[yearKey][weekKey][dayKey].absent = -2;
                            }

                        } else {

                            // ziekte wachtdag
                            if (yearData[yearKey][weekKey][dayKey].absent === -1) {
                                continue;
                            }

                            hoursAbsent_week += yearData[yearKey][weekKey][dayKey].absent;
                            weeklyHours += yearData[yearKey][weekKey][dayKey].absent;
                            totalHoursAbsent[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].absent;

                            if (yearData[yearKey][weekKey][dayKey].absent !== 0) {
                                daysUsed++;
                            }

                        }

                    }

                    // HOLIDAYS
                    for (const dayKey in yearData[yearKey][weekKey]) {

                        if (daysUsed >= workdays) {

                            // break;
                            if (yearData[yearKey][weekKey][dayKey].holiday !== 0) {
                                yearData[yearKey][weekKey][dayKey].holiday = -1;
                            }

                        } else {

                            // HOLIDAY DATA GRADED
                            if (this.isEmpty(yearData[yearKey][weekKey][dayKey].holidayData) === false) {

                                const _holidayData = yearData[yearKey][weekKey][dayKey].holidayData;

                                if (this.isEmpty(holidayReduces[yearKey][_holidayData.vakantie_begin + _holidayData.vakantie_id]) === true) {
                                    holidayReduces[yearKey][_holidayData.vakantie_begin + _holidayData.vakantie_id] = {
                                        data: _holidayData,
                                        amount: 0
                                    };
                                }

                                holidayReduces[yearKey][_holidayData.vakantie_begin + _holidayData.vakantie_id].amount += yearData[yearKey][weekKey][dayKey].holiday;

                            }

                            hoursHoliday_week += yearData[yearKey][weekKey][dayKey].holiday;
                            weeklyHours += yearData[yearKey][weekKey][dayKey].holiday;
                            totalHoursHoliday[yearKey][weekKey] += yearData[yearKey][weekKey][dayKey].holiday;

                            if (yearData[yearKey][weekKey][dayKey].holiday !== 0) {
                                daysUsed++;
                            }

                            // LEAVE BALANCE TOTALS
                            const holidayData = yearData[yearKey][weekKey][dayKey].holidayData;

                            if (this.isEmpty(holidayData) === false && leaveBalances.length > 0 && leaveTypeBalance.length > 0) {

                                // IS CONNECTED TO LEAVE TYPE
                                if (this.isEmpty(holidayData.vakantie_vt_id) === false) {

                                    const selectedLeaveBalance = leaveTypeBalanceObj[holidayData.vakantie_vt_id][0].vtb_vb_id;

                                    if (this.isEmpty(totalHolidayBalance[selectedLeaveBalance]) === true) {
                                        totalHolidayBalance[selectedLeaveBalance] = {};
                                    }

                                    if (this.isEmpty(totalHolidayBalance[selectedLeaveBalance][yearKey]) === true) {
                                        totalHolidayBalance[selectedLeaveBalance][yearKey] = 0;
                                    }

                                    totalHolidayBalance[selectedLeaveBalance][yearKey] += yearData[yearKey][weekKey][dayKey].holiday;

                                } else {

                                    if (this.isEmpty(totalHolidayBalance[leaveBalances[0].vb_id]) === true) {
                                        totalHolidayBalance[leaveBalances[0].vb_id] = {};
                                    }

                                    if (this.isEmpty(totalHolidayBalance[leaveBalances[0].vb_id][yearKey]) === true) {
                                        totalHolidayBalance[leaveBalances[0].vb_id][yearKey] = 0;
                                    }

                                    totalHolidayBalance[leaveBalances[0].vb_id][yearKey] += yearData[yearKey][weekKey][dayKey].holiday;

                                }

                            }

                        }

                    }

                    // OVERHOURS
                    //const isNegative = weeklyHours < (parseFloat(contract.contract_uren) * 1000 * 60 * 60);
                    let overhours = 0;

                    // BE SURE EMPLOYEE IS IN EMPLOYMENT IN CURRENT WEEK AND FIRST WORKING DAY IS LOWER THAN WEEK
                    if ((this.isEmpty(out_of_order_date) === true
                            || (parseInt(yearKey) !== out_of_order_date.getFullYear()) || (parseInt(yearKey) === out_of_order_date.getFullYear() && parseInt(weekKey) !== this.getWeekNumber2(out_of_order_date)))
                        && (this.isEmpty(first_working_date) === false && (parseInt(yearKey) > this.getBeginOfWeek(first_working_date).getFullYear() || (parseInt(yearKey) === this.getBeginOfWeek(first_working_date).getFullYear() && parseInt(weekKey) >= this.getWeekNumber2(first_working_date))))) {

                        if (contract.contract_type === '2') {

                            overhours = weeklyHours - (parseFloat(contract.contract_uren) * 1000 * 60 * 60);

                        } else if (contract.contract_type === '1') {

                            if (weeklyHours < (parseFloat(contract.contract_uren) * 1000 * 60 * 60)) {
                                overhours = weeklyHours - (parseFloat(contract.contract_uren) * 1000 * 60 * 60);
                            } else if (weeklyHours > (parseFloat(contract.contract_max_uren) * 1000 * 60 * 60)) {
                                overhours = weeklyHours - (parseFloat(contract.contract_max_uren) * 1000 * 60 * 60);
                            }

                        }

                    }

                    if (now.getFullYear() > year || (this.getWeekNumber2(now) > week && now.getFullYear() === year)) {
                        totalOverhours[yearKey][weekKey] = overhours;
                    }

                }

            }

            // ********* OVERHOURS YEARLY OBJECT *********
            let totalOverhoursYearly = {};

            for (const yearKey in totalOverhours) {

                if (this.isEmpty(totalOverhoursYearly[yearKey]) === true) {
                    totalOverhoursYearly[yearKey] = 0;
                }

                for (const weekKey in totalOverhours[yearKey]) {
                    totalOverhoursYearly[yearKey] += totalOverhours[yearKey][weekKey];
                }

            }

            // ********* LEAVE BALANCES *********
            let totalContractHoursYear = {};
            let userLeaveBalances = [];

            // CALCULATE CONTRACT HOURS WHOLE YEAR
            if (this.isEmpty(contracts_obj[accountKey]) === false) {
                for (const c of contracts_obj[accountKey]) {

                    if (this.isTrue(c.contract_opbouw_verlof) === false) {
                        continue;
                    }

                    const contractStart = this.isEmpty(c.contract_begin) === false ? this.stringToDate(c.contract_begin) : null;
                    const contractEnd = this.isEmpty(c.contract_eind) === false ? this.stringToDate(c.contract_eind) : null;

                    if (this.isEmpty(contractStart) === true) {
                        continue;
                    }

                    if (this.isEmpty(totalContractHoursYear[contractStart.getFullYear()]) === true) {
                        totalContractHoursYear[contractStart.getFullYear()] = 0;
                    }

                    // START CALCULATING LEAVE BALANCES
                    if (this.isEmpty(contractEnd) === true) {

                        const currentYear = now.getFullYear();
                        const startYear = contractStart.getFullYear() > firstYear ? contractStart.getFullYear() : firstYear;

                        for (let i = startYear; i <= currentYear; i++) {

                            if (i > currentYear) {
                                break;
                            }

                            if (this.isEmpty(totalContractHoursYear[i]) === true) {
                                totalContractHoursYear[i] = 0;
                            }

                            let _contractStart = this.stringToDate(`${i}-01-01`);

                            if (i === contractStart.getFullYear()) {
                                _contractStart = this.stringToDate(this.dateToString(contractStart));
                            }

                            const _contractEnd = this.stringToDate(`${i}-12-31`);
                            const weeks = this.weeksBetween(_contractStart, _contractEnd);

                            // VAST
                            if (c.contract_type === '2') {

                                totalContractHoursYear[i] += weeks * (parseFloat(c.contract_uren) * 60 * 60 * 1000);

                                // MIN-MAX OF FLEX
                            } else {

                                for (const weekKey in yearData[i]) {

                                    // start contract week higher than worked week
                                    if (this.getWeekNumber2(_contractStart) > parseInt(weekKey)) {
                                        continue;
                                    }

                                    for (const dayKey in yearData[i][weekKey]) {

                                        // start contract higher than worked date
                                        if (_contractStart.getTime() > yearData[i][weekKey][dayKey].date.getTime()) {
                                            continue;
                                        }

                                        totalContractHoursYear[i] += (yearData[i][weekKey][dayKey].hoursWorked);

                                    }

                                }

                            }

                        }

                    } else {

                        const currentYear = now.getFullYear();
                        const startYear = contractStart.getFullYear() > firstYear ? contractStart.getFullYear() : firstYear;

                        for (let i = startYear; i <= contractEnd.getFullYear(); i++) {

                            if (i > contractEnd.getFullYear() || i > currentYear) {
                                break;
                            }

                            if (this.isEmpty(totalContractHoursYear[i]) === true) {
                                totalContractHoursYear[i] = 0;
                            }

                            let _contractStart = this.stringToDate(`${i}-01-01`);
                            let _contractEnd = this.stringToDate(`${i}-12-31`);

                            if (i === contractStart.getFullYear()) {
                                _contractStart = this.stringToDate(this.dateToString(contractStart));
                            }

                            if (i === contractEnd.getFullYear()) {
                                _contractEnd = this.stringToDate(this.dateToString(contractEnd));
                            }

                            const weeks = this.weeksBetween(_contractStart, _contractEnd);

                            // VAST
                            if (c.contract_type === '2') {

                                totalContractHoursYear[i] += weeks * (parseFloat(c.contract_uren) * 60 * 60 * 1000);

                                // MIN-MAX OF FLEX
                            } else {

                                for (const weekKey in yearData[i]) {

                                    // start contract week higher than worked week
                                    if (this.getWeekNumber2(_contractStart) > parseInt(weekKey) || this.getWeekNumber2(_contractEnd) < parseInt(weekKey)) {
                                        continue;
                                    }

                                    for (const dayKey in yearData[i][weekKey]) {

                                        // start contract higher than worked date
                                        if (_contractStart.getTime() > yearData[i][weekKey][dayKey].date.getTime() || _contractEnd.getTime() < yearData[i][weekKey][dayKey].date.getTime()) {
                                            continue;
                                        }

                                        totalContractHoursYear[i] += (yearData[i][weekKey][dayKey].hoursWorked);

                                    }

                                }

                            }

                        }

                    }

                }

            }

            // create leave balances user
            for (const leaveBalance of leaveBalances) {

                let _balance = {};
                let _reduce = {};
                let _total = {};

                for (const yearKey in totalContractHoursYear) {

                    if (this.isEmpty(_balance[yearKey]) === true) {
                        _balance[yearKey] = 0;
                    }
                    if (this.isEmpty(_reduce[yearKey]) === true) {
                        _reduce[yearKey] = 0;
                    }
                    if (this.isEmpty(_total[yearKey]) === true) {
                        _total[yearKey] = 0;
                    }

                    _balance[yearKey] += (totalContractHoursYear[yearKey] * parseFloat(leaveBalance.vb_factor));

                    if (this.isEmpty(totalHolidayBalance) === false && this.isEmpty(totalHolidayBalance[leaveBalance.vb_id]) === false && this.isEmpty(totalHolidayBalance[leaveBalance.vb_id][yearKey]) === false) {
                        _reduce[yearKey] += totalHolidayBalance[leaveBalance.vb_id][yearKey];
                    }

                    _total[yearKey] = _balance[yearKey] - _reduce[yearKey];

                }

                userLeaveBalances.push({
                    ...leaveBalance,
                    balance: _balance,
                    reduce: _reduce,
                    total: _total,
                });

            }

            // ********* CORRECTIONS *********
            let totalOverhoursCorrection = {};
            let totalHolidayCorrection = {};
            let totalT4TCorrection = {};

            // TIME 4 TIME // FUSE ALL 3 DATAS HERE
            let time4timeCorrections_data = [];
            let time4timeCorrectionsYearly = {};

            // T4T OVERHOURS
            if (this.isEmpty(time4timeCorrectionsData_overhours_obj[accountKey]) === false) {

                for (const t of time4timeCorrectionsData_overhours_obj[accountKey]) {

                    time4timeCorrections_data.push({
                        ...t,
                        amount: parseFloat(t.co_aantal) > 0 ? -parseFloat(t.co_aantal) : parseFloat(t.co_aantal),
                        by: t.co_info_id_door,
                        dateString: t.co_datum,
                        payout: t.co_betaling,
                        note: t.co_notitie,
                        date: this.stringToDate(t.co_datum),
                        dateMS: this.stringToDate(t.co_datum).getTime(),
                    });

                }

            }

            // T4T HOLIDAYS
            if (this.isEmpty(time4timeCorrectionsData_holiday_obj[accountKey]) === false) {

                for (const t of time4timeCorrectionsData_holiday_obj[accountKey]) {

                    time4timeCorrections_data.push({
                        ...t,
                        amount: parseFloat(t.cv_aantal) > 0 ? -parseFloat(t.cv_aantal) : parseFloat(t.cv_aantal),
                        by: t.cv_info_id_door,
                        dateString: t.cv_datum,
                        payout: t.cv_betaling,
                        note: t.cv_notitie,
                        date: this.stringToDate(t.cv_datum),
                        dateMS: this.stringToDate(t.cv_datum).getTime(),
                    });

                }

            }

            // T4T DB
            if (this.isEmpty(time4timeCorrectionsDataDB_obj[accountKey]) === false) {

                for (const t of time4timeCorrectionsDataDB_obj[accountKey]) {

                    time4timeCorrections_data.push({
                        ...t,
                        amount: parseFloat(t.ct_aantal),
                        by: t.ct_info_id_door,
                        dateString: t.ct_datum,
                        payout: t.ct_betaling,
                        note: t.ct_notitie,
                        date: this.stringToDate(t.ct_datum),
                        dateMS: this.stringToDate(t.ct_datum).getTime(),
                    });

                }

            }

            // SORT BY DATE
            time4timeCorrections_data.sort((a, b) => (a.dateMS > b.dateMS) ? 1 : -1);

            // TO YEARLY OBJECT
            for (const t of time4timeCorrections_data) {

                const t4tYear = t.date.getFullYear();

                if (this.isEmpty(time4timeCorrectionsYearly[t4tYear]) === true) {
                    time4timeCorrectionsYearly[t4tYear] = 0;
                }

                time4timeCorrectionsYearly[t4tYear] += (t.amount * 1000 * 60 * 60);

            }

            // TIME 4 TIME
            for (const yearKey in time4timeCorrectionsYearly) {

                if (this.isEmpty(totalT4TYearly[yearKey]) === true) {
                    totalT4TYearly[yearKey] = 0;
                }

                totalT4TYearly[yearKey] += time4timeCorrectionsYearly[yearKey];

            }

            // OVERHOURS
            if (this.isEmpty(overhoursCorrectionsData_obj[accountKey]) === false) {

                for (const overhourCorrection of overhoursCorrectionsData_obj[accountKey]) {

                    const correctionDate = this.stringToDate(overhourCorrection.co_datum);
                    const year = correctionDate.getFullYear();
                    // const week = this.getWeekNumber2(correctionDate);

                    // if (this.isEmpty(totalOverhoursCorrection[year]) === true) {
                    //     totalOverhoursCorrection[year] = {};
                    // }
                    // if (this.isEmpty(totalOverhoursCorrection[year][week]) === true) {
                    //     totalOverhoursCorrection[year][week] = 0;
                    // }

                    // totalOverhoursCorrection[year][week] += parseFloat(overhourCorrection.co_aantal);

                    if (this.isEmpty(totalOverhoursYearly[year]) === true) {
                        totalOverhoursYearly[year] = 0;
                    }

                    totalOverhoursYearly[year] += (parseFloat(overhourCorrection.co_aantal) * 1000 * 60 * 60);

                }

            }

            // HOLIDAYS
            if (this.isEmpty(holidayCorrectionsData_obj[accountKey]) === false) {

                for (const holidayCorrection of holidayCorrectionsData_obj[accountKey]) {

                    const correctionDate = this.stringToDate(holidayCorrection.cv_datum);
                    const year = correctionDate.getFullYear();

                    let userLeaveBalance = this.getArrayItem(userLeaveBalances, 'vb_id', holidayCorrection.cv_vb_id);

                    if (this.isEmpty(userLeaveBalance) === false) {

                        if (this.isEmpty(userLeaveBalance.total[year]) === true) {
                            userLeaveBalance.total[year] = 0;
                        }

                        if (this.isEmpty(userLeaveBalance.reduce[year]) === true) {
                            userLeaveBalance.reduce[year] = 0;
                        }

                        userLeaveBalance.total[year] += (parseFloat(holidayCorrection.cv_aantal) * 1000 * 60 * 60);
                        userLeaveBalance.reduce[year] += (parseFloat(holidayCorrection.cv_aantal) * 1000 * 60 * 60);

                    }

                }

            }

            // TOTAL OVERHOURS END BALANCE
            let totalEndBalance_overhours = 0;

            if (this.isEmpty(totalOverhoursYearly) === false) {
                for (const yearKey in totalOverhoursYearly) {
                    if (parseInt(yearKey) <= (paramYear)) {
                        totalEndBalance_overhours += totalOverhoursYearly[yearKey];
                    }
                }
            }

            allUsersHours[accountKey] = {

                // userID
                userID: accountKey,

                // user data
                userData: allUsersHours[accountKey].userData,

                // contracts
                contracts: this.isEmpty(contracts_obj[accountKey]) === false ? contracts_obj[accountKey] : [],

                // all year data
                yearData: yearData,

                // all data total separated in weeks
                totalHoursWorked: totalHoursWorked,
                totalHoursAbsent: totalHoursAbsent,
                totalHoursHoliday: totalHoursHoliday,
                totalOverhours: totalOverhours,
                totalT4T: totalT4T,

                // holiday requests graded
                holidayReduces: holidayReduces,

                // all data total separated in years
                totalOverhoursYearly: totalOverhoursYearly,
                totalT4TYearly: totalT4TYearly,

                // corrections objects
                overhoursCorrections: overhoursCorrectionsData_obj[accountKey],
                holidaysCorrections: holidayCorrectionsData_obj[accountKey],
                time4timeCorrections: time4timeCorrections_data,

                // contract leave balances
                leaveBalances: leaveBalances,
                contractLeaveBalances: contractLeaveBalancesObj,

                // user leave balances
                userLeaveBalances: userLeaveBalances,

                // end balances
                totalEndBalance_overhours: totalEndBalance_overhours,

            };

        }

        return allUsersHours;

    }

    getFieldNameValue(fieldName, user, contract) {

        let toReturn = ``;

        // EMPLOYEE
        if (fieldName === 'first_name') {
            toReturn = `${user.info_voornaam}`;
        } else if (fieldName === 'last_name') {
            toReturn = `${user.info_achternaam}`;
        } else if (fieldName === 'middle_name') {
            toReturn = `${user.info_tussenvoegsel}`;
        } else if (fieldName === 'initials') {
            toReturn = `${user.info_voornaam.charAt(0)}`;
        } else if (fieldName === 'birth_date') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${birthdate.toLocaleDateString()}`;
            }
        } else if (fieldName === 'birth_place') {
            toReturn = `${user.info_geboorteplaats}`;
        } else if (fieldName === 'phone') {
            toReturn = `${user.info_telefoon}`;
        } else if (fieldName === 'email') {
            toReturn = `${user.info_email}`;
        } else if (fieldName === 'job') {
            toReturn = `${user.functie_naam}`;
        } else if (fieldName === 'address') {
            toReturn = `${user.info_adres} ${user.info_huisnummer}`;
        } else if (fieldName === 'postal_code') {
            toReturn = `${user.info_postcode}`;
        } else if (fieldName === 'city') {
            toReturn = `${user.info_plaats}`;
        } else if (fieldName === 'contract_start_date') {
            if (contract !== null && this.isEmpty(contract.contract_begin) === false) {
                const startdate = this.stringToDate(contract.contract_begin);
                toReturn = `${startdate.toLocaleDateString()}`;
            } else {
                toReturn = `${new Date().toLocaleDateString()}`;
            }
        } else if (fieldName === 'contract_end_date') {
            if (contract !== null && this.isEmpty(contract.contract_eind) === false) {
                const enddate = this.stringToDate(contract.contract_eind);
                toReturn = `${enddate.toLocaleDateString()}`;
            }
        } else if (fieldName === 'citizen_number') {
            toReturn = `${user.bsn}`;
        } else if (fieldName === 'bank_number') {
            toReturn = `${user.iban}`;
        } else if (fieldName === 'contract_hours' || fieldName === 'contract_min_hours') {
            if (contract !== null && this.isEmpty(contract.contract_uren) === false) {
                toReturn = `${contract.contract_uren}`;
            }
        } else if (fieldName === 'contract_max_hours') {
            if (contract !== null && this.isEmpty(contract.contract_max_uren) === false) {
                toReturn = `${contract.contract_max_uren}`;
            }
        } else if (fieldName === 'contract_hourly_salary' || fieldName === 'contract_hourly_wage') {
            if (contract !== null && this.isEmpty(contract.contract_uurloon) === false) {
                toReturn = `${contract.contract_uurloon}`;
            }
        } else if (fieldName === 'contract_monthly_salary' || fieldName === 'contract_monthly_wage') {
            if (contract !== null && this.isEmpty(contract.contract_maandloon) === false) {
                toReturn = `${contract.contract_maandloon}`;
            }
            // } else if (fieldName === 'last_initials') {
            //     toReturn = `${user.info_achternaam} ${user.info_voornaam.charAt(0)}`;
        } else if (fieldName === 'birth_date_day') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${birthdate.getDate() < 10 ? '0' + birthdate.getDate() : birthdate.getDate()}`;
            }
        } else if (fieldName === 'birth_date_month') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${(birthdate.getMonth() + 1) < 10 ? '0' + (birthdate.getMonth() + 1) : (birthdate.getMonth() + 1)}`;
            }
        } else if (fieldName === 'birth_date_year') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${birthdate.getFullYear()}`;
            }
        } else if (fieldName === 'full_name') {
            const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
            toReturn = `${userFullName}`;

            // manager
        } else if (fieldName === 'manager_full_name') {
            const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`;
            toReturn = `${userFullName}`;
        } else if (fieldName === 'manager_job') {
            toReturn = `${Data.data.accountData.functie_naam}`;
        } else if (fieldName === 'manager_first_name') {
            toReturn = `${Data.data.accountData.info_voornaam}`;
        } else if (fieldName === 'manager_last_name') {
            toReturn = `${Data.data.accountData.info_achternaam}`;
        } else if (fieldName === 'manager_middle_name') {
            toReturn = `${Data.data.accountData.info_tussenvoegsel}`;
        } else if (fieldName === 'manager_initials') {
            toReturn = `${Data.data.accountData.info_voornaam.charAt(0)}`;
        } else if (fieldName === 'manager_birth_date') {
            if (this.isEmpty(Data.data.accountData.info_geboorte) === false) {
                const birthdate = this.stringToDate(Data.data.accountData.info_geboorte);
                toReturn = `${birthdate.toLocaleDateString()}`;
            }
        } else if (fieldName === 'manager_birth_place') {
            toReturn = `${Data.data.accountData.info_geboorteplaats}`;
        } else if (fieldName === 'manager_phone') {
            toReturn = `${Data.data.accountData.info_telefoon}`;
        } else if (fieldName === 'manager_email') {
            toReturn = `${Data.data.accountData.info_email}`;
        } else if (fieldName === 'manager_address') {
            toReturn = `${Data.data.accountData.info_adres} ${Data.data.accountData.info_huisnummer}`;
        } else if (fieldName === 'manager_postal_code') {
            toReturn = `${Data.data.accountData.info_postcode}`;
        } else if (fieldName === 'manager_city') {
            toReturn = `${Data.data.accountData.info_plaats}`;
        }

        // COMPANY
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (fieldName === 'company_name') {
                toReturn = `${Data.data.chosenTeamObj.team_naam}`;
            } else if (fieldName === 'company_address') {
                toReturn = `${Data.data.chosenTeamObj.team_adres}`;
            } else if (fieldName === 'company_postal_code') {
                toReturn = `${Data.data.chosenTeamObj.team_postcode}`;
            } else if (fieldName === 'company_city') {
                toReturn = `${Data.data.chosenTeamObj.team_plaats}`;
            } else if (fieldName === 'company_phone') {
                toReturn = `${Data.data.chosenTeamObj.team_telefoon}`;
            } else if (fieldName === 'company_number') {
                toReturn = `${Data.data.chosenTeamObj.team_kvk}`;
            }
        } else {
            if (fieldName === 'company_name') {
                toReturn = `${Data.data.storeName}`;
            } else if (fieldName === 'company_address') {
                toReturn = `${Data.data.storeAddress}`;
            } else if (fieldName === 'company_postal_code') {
                toReturn = `${Data.data.storeZip}`;
            } else if (fieldName === 'company_city') {
                toReturn = `${Data.data.storeCity}`;
            } else if (fieldName === 'company_phone') {
                toReturn = `${Data.data.storePhone}`;
            } else if (fieldName === 'company_number') {
                toReturn = `${Data.data.storeKvK}`;
            } else if (fieldName === 'company_email') {
                toReturn = `${Data.data.storeMail}`;
            } else if (fieldName === 'company_country') {
                toReturn = `${Data.data.storeCountry}`;
            }
        }

        if (this.isEmpty(toReturn)) {
            toReturn = '';
        }

        return toReturn;

    }

    getNationalHoliday(date, publicHolidays) {

        if (this.isTrue(Data.data.appOptions.opties_feestdagen) === false && Data.data.accountData.account_rights === '0') {
            return null;
        }

        const dateObject = this.stringToDate(date);

        // NON DUTCH
        if ((this.isEmpty(publicHolidays) === false && publicHolidays.length > 0)) {

            if ((this.isEmpty(publicHolidays) === true || publicHolidays.length === 0)) {
                return null;
            }

            for (const ph of publicHolidays) {
                if (this.dateToString(date) === ph.f_datum) {
                    return ph.f_naam;
                }
            }

            // DUTCH
        } else {

            if (dateObject.getDate() === 1 && dateObject.getMonth() === 0) {
                return <span>Nieuwjaar</span>;
            }
            if (dateObject.getDate() === 14 && dateObject.getMonth() === 1) {
                return <span>Valentijn</span>;
            }
            if (dateObject.getDate() === 15 && dateObject.getMonth() === 3) { //change
                return <span>Goede vrijdag</span>;
            }
            if (dateObject.getDate() === 17 && dateObject.getMonth() === 3) { //change
                return <span>1e Paasdag</span>;
            }
            if (dateObject.getDate() === 18 && dateObject.getMonth() === 3) { //change
                return <span>2e Paasdag</span>;
            }
            if (dateObject.getDate() === 27 && dateObject.getMonth() === 3) {
                return <span>Koningsdag</span>;
            }
            if (dateObject.getDate() === 4 && dateObject.getMonth() === 4) {
                return <span>Dodenherdenking</span>;
            }
            if (dateObject.getDate() === 5 && dateObject.getMonth() === 4) {
                return <span>Bevrijdingsdag</span>;
            }
            if (dateObject.getDate() === 8 && dateObject.getMonth() === 4) { //change
                return <span>Moederdag</span>;
            }
            if (dateObject.getDate() === 26 && dateObject.getMonth() === 4) { //change
                return <span>Hemelvaart</span>;
            }
            if (dateObject.getDate() === 5 && dateObject.getMonth() === 5) { //change
                return <span>1e Pinksterdag</span>;
            }
            if (dateObject.getDate() === 6 && dateObject.getMonth() === 5) { //change
                return <span>2e Pinksterdag</span>;
            }
            if (dateObject.getDate() === 19 && dateObject.getMonth() === 5) { //change
                return <span>Vaderdag</span>;
            }
            if (dateObject.getDate() === 20 && dateObject.getMonth() === 8) { //change
                return <span>Prinsjesdag</span>;
            }
            if (dateObject.getDate() === 31 && dateObject.getMonth() === 9) {
                return <span>Halloween</span>;
            }
            if (dateObject.getDate() === 11 && dateObject.getMonth() === 10) {
                return <span>Sint Maarten</span>;
            }
            if (dateObject.getDate() === 5 && dateObject.getMonth() === 11) {
                return <span>Sinterklaas</span>;
            }
            if (dateObject.getDate() === 24 && dateObject.getMonth() === 11) {
                return <span>Kerstavond</span>;
            }
            if (dateObject.getDate() === 25 && dateObject.getMonth() === 11) {
                return <span>1e Kerstdag</span>;
            }
            if (dateObject.getDate() === 26 && dateObject.getMonth() === 11) {
                return <span>2e Kerstdag</span>;
            }
            if (dateObject.getDate() === 31 && dateObject.getMonth() === 11) {
                return <span>Oudjaar</span>;
            }

        }

        return null;

    }

    swapInArray(arr, from, to) {
        arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
    }

}

// singleton
export default (new Constants());