/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../planner/planner.scss';
import '../../../constants/DefaultStyle.css';
import "./appOptions.css";

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import BetterAlert from '../../../components/alert/alert';
import APIGetLocations from '../../../classes/APIGetLocations';

import Geocode from "react-geocode";
import Colors from '../../../constants/Colors';
import AddLocationModal from './addLocationModal';
import APIDeleteLocation from '../../../classes/APIDeleteLocation';

class LocationsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    this.state = {

        loading: true,

        locations: [],
        addresses: [],

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showModal: false,
        modalMode: 0,
        modalData: [],

    };

    //componentWillMount here:

  }

  async componentDidMount() {

    await this.loadLocations();

  }

  async loadLocations() {

    this.setState({
        loading: true,
    });

    let locs = await APIGetLocations.Request();

    if (locs === null) {
        locs = [];
    }

    let addresses = [];

    for (const loc of locs) {
        const geo = await Geocode.fromLatLng(loc.locatie_lat, loc.locatie_long);
        addresses.push(
            {
                id: loc.locatie_id,
                name: loc.locatie_naam,
                radius: loc.locatie_straal,
                address: geo.results[0].address_components[1].long_name,
                city: geo.results[0].address_components[2].long_name,
            }
        );
    }

    this.setState({
        locations: locs,
        addresses: addresses,
        loading: false,
    });

  }

  getLocationBars() {

    const toRender = [];

    for (const loc of this.state.addresses) {

        toRender.push(

            <div className="locations_row" key={loc.id}>

                <div className="locations_row_sub">
                    <div className="locations_row_sub_left" style={{flex: 5, fontSize: "1.5vw", fontWeight: "bold"}}>
                        {loc.name}
                    </div>
                    <div onClick={this.deleteAlert.bind(this, loc.id)} className="locations_row_sub_right" style={{flex: 1, fontSize: "1.5vw", color: Colors.color.redFilledIn, cursor: "pointer", justifyContent: "flex-end"}}>
                        <i className="fa fa-trash" />
                    </div>
                </div>

                <div className="locations_row_sub">
                    <div className="locations_row_sub_left">
                        Adres
                    </div>
                    <div className="locations_row_sub_right">
                        ≈ {loc.address}, {loc.city}
                    </div>
                </div>

                <div className="locations_row_sub">
                    <div className="locations_row_sub_left">
                        Straal
                    </div>
                    <div className="locations_row_sub_right">
                        {loc.radius} meter
                    </div>
                </div>

            </div>

        );
        
    }

    return toRender;

  }

  openAddLocationModal() {

    this.setState({
      modalData: null,
      modalMode: 0,
      showModal: true,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {
      this.setState({
        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,
      });
  }

  deleteAlert(idl) {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze inkloklocatie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteLocation.bind(this, idl),
        showAlert: true,
    });

  }

  async deleteLocation(idl) {

    this.setState({loading: true});

    const added = await APIDeleteLocation.Request(
      parseInt(idl),
    );

    if(added === true) {

      this.closeAlert();

      this.loadLocations();

    }

  }

  closeAndReload() {
    this.props._closeModal();
    this.props._reloadScreen();
  }

  render() {

    return (
        <div id="locationsModal" className="modal" onClick={(e) => e.target === document.getElementById("zlocationsModal") ? this.closeAndReload() : null}>

        <div className="modal-content" style={{width: '50%'}}>
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeAndReload() : null}>&times;</span>
            <p>Inkloklocaties</p>
          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

                <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                    {this.state.showModal === true ?
                
                        <AddLocationModal
                        _closeModal={this.closeModal.bind(this)}
                        _modalData={this.state.modalData}
                        _modalMode={this.state.modalMode}
                        _reloadScreen={this.loadLocations.bind(this)}
                        />

                    : null}

                    {this.state.showAlert === true ?
                        
                        <BetterAlert
                            _closeAlert={this.closeAlert.bind(this)}
                            _title={this.state.alertTitle}
                            _body={this.state.alertBody}
                            _buttons={this.state.alertButtons}
                            _buttonText={this.state.alertButtonText}
                            _buttonAction={this.state.alertButtonAction}
                        />

                    :

                        null
                    
                    }

                    <div className='plan_modal-form' style={{paddingBottom: '4vw'}}>

                        {this.getLocationBars()}

                    </div>

                </div>

            }

            <div className='locations_add_button' onClick={this.openAddLocationModal.bind(this)}>
                Locatie toevoegen
            </div>

        </div>

      </div>
    );
  }
  
}

export default LocationsModal;