/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import { ChromePicker } from 'react-color';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import APIAddParticular from '../../../classes/APIAddParticular';
import APIEditParticular from '../../../classes/APIEditParticular';
import APIDeleteParticular from '../../../classes/APIDeleteParticular';
import APIADD from '../../../classes/global/APIADD';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIGET from '../../../classes/global/APIGET';

class NoteModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        name: '',
        color: '#000000',
        start: null,
        end: null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDINg
        
    }

    if(this.mode === 1) { //EDITING

      this.setState({
        name: this.modalData.rn_naam,
        color: `#${this.modalData.rn_kleur}`,
        start: Constants.stringToDate(this.modalData.rn_begin),
        end: Constants.stringToDate(this.modalData.rn_eind)
      });

    }

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze notitie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteTimes.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIADD.Request(`INSERT INTO RapportNotitie`, `VALUES (NULL, '${this.state.name}', '${this.state.color.substring(1)}', '${Constants.dateToString(this.state.start)}', '${Constants.dateToString(this.state.end)}')`);

    if(added === true) {

      const addedID = await APIGET.Request(`SELECT *`, `FROM RapportNotitie`, null, null, null, `ORDER BY rn_id DESC LIMIT 1`);

      this.closeModal();

      await this.reloadScreen();

      if (addedID.length > 0) {
        this.props._toggleNote(addedID[0]);
      }

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEDIT.Request(`UPDATE RapportNotitie`, `SET rn_naam = '${this.state.name}', rn_kleur = '${this.state.color.substring(1)}', rn_begin = '${Constants.dateToString(this.state.start)}',  rn_eind = '${Constants.dateToString(this.state.end)}'`, `WHERE rn_id = ${this.modalData.rn_id}`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteTimes() {

    this.setState({loading: true});

    const added = await APIDELETE.Request(`DELETE FROM RapportNotitie`, `WHERE rn_id = ${this.modalData.rn_id}`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.name === '') {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen naam ingevoerd',
        });
        return false;
      }

      if(this.state.start === null) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen begindatum ingevoerd`,
        });
        return false;
      }
      if(this.state.start === null) {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen einddatum ingevoerd',
        });
        return false;
      }

    if (this.state.end < this.state.start) {
        this.setState({
            showError: true,
            errorText: 'Einddatum kan niet eerder zijn dan de begindatum',
          });
          return false;
    }

    return true;

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    this.setState({
        start: date,
        showError: false,
    });

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    this.setState({
        end: date,
        showError: false,
    });

  }

  goBack() {

    this.props._closeModal();

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="noteModal" className="modal" onClick={(e) => e.target === document.getElementById("znoteModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Notitie toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Notitie wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Notitie titel</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                            placeholder={'Naam'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Begindatum</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <DatePicker
                            selected={this.state.start}
                            onChange={(date) => this.setDate1(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'inputFieldTime'}
                            placeholderText={'Selecteer een datum'}
                            locale={nl}
                            showWeekNumbers
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Einddatum</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <DatePicker
                              selected={this.state.end}
                              minDate={this.state.start}
                              onChange={(date) => this.setDate2(date)}
                              dateFormat="dd-MM-yyyy"
                              className={'inputFieldTime'}
                              placeholderText={'Selecteer een datum'}
                              locale={nl}
                              showWeekNumbers
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Kleur</b></span>
                            <div style={{width: '4vw', height: '2vw', marginTop: 10, borderRadius: 5, backgroundColor: this.state.color}}></div>
                        </div>

                        <div className='modal-rowSub2'>

                          <ChromePicker 
                            //presetColors={[]}
                            width={'100%' - 32}
                            color={this.state.color}
                            onChange={(color) => this.setState({color: color.hex})}
                            disableAlpha={true}
                          />

                        </div>

                    </div>

                  <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editTimes()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveTimes()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default NoteModal;