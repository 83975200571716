import React from 'react';

//styles
import './resetPassword.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

import 'react-datepicker/dist/react-datepicker.css';

import BetterAlert from '../../../components/alert/alert';
import APIResetPasswordCheckUsername from '../../../classes/APIResetPasswordCheckUsername';
import APIResetPasswordMail from '../../../classes/APIResetPasswordMail';
import Data from '../../../constants/Data';
import APICheckStoreCode from '../../../classes/APICheckStoreCode';

class ResetPassword extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    // compwillmount
    
    this.state = {

      loading: true,
      loading2: false,

      username: '',
      storeCode: '',

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

    };

  }

  async componentDidMount() {

    this.setState({loading: false});

  }

  changedAlert(email) {
    this.setState({
        alertTitle: 'Gelukt',
        alertBody: (<span>Er is een e-mail verzonden naar <b>{email}</b> om jouw wachtwoord te herstellen</span>),
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    Data.data.serverIP = '';

    if(Data.data.loggedIn === true) {
      Data.data.accountData = [];
      Data.data.loggedIn = false;

      localStorage.setItem('@storecode', '');
      localStorage.setItem('@username', '');
      localStorage.setItem('@password', '');
    }

    this.props.history.push("/");

  }

  
  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async changePass() {

    this.setState({loading2: true});

    const codeValid = await APICheckStoreCode.Request(this.state.storeCode);

    if(codeValid === null) {
      this.setState({
        showError: true, 
        errorText: 'Bedrijfscode is onjuist',
        loading2: false,
      });
      Data.data.serverIP = '';
      return;
    }

    Data.data.serverIP = codeValid.server_ip;

    const accountID = await APIResetPasswordCheckUsername.Request(this.state.username, this.state.storeCode, codeValid.server_ip);

    if(accountID === null) {
        this.setState({
          showError: true, 
          errorText: 'Gebruikersnaam niet gevonden',
          loading2: false,
        });
        Data.data.serverIP = '';
        return;
    }

    const requested = await APIResetPasswordMail.Request(accountID.info_email, parseInt(accountID.account_id), this.state.storeCode);

    if(requested === true) {

        this.changedAlert(accountID.info_email);

    } else {

        // console.log('error');

        this.setState({
            loading2: false,
        });

    }

  }

  render() {

    return (
        <div className='resetPassword'>

          <div className='planner_nav2'>

            <div className='functions_nav2_sub2'>
            </div>
            <div className='resetPass_nav2_sub3'>
              Wachtwoord vergeten
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='resetPass_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }


                <div className={'resetPass_userContainer'}>

                    <input 
                        className={'inputField2'}
                        value={this.state.storeCode}
                        onChange={(event) => this.setState({ storeCode: event.target.value, showError: false })}
                        placeholder={'Bedrijfscode'}
                        autoCapitalize={'none'}
                    />

                    <input 
                        style={{marginTop: 10}}
                        className={'inputField2'}
                        value={this.state.username}
                        onChange={(event) => this.setState({ username: event.target.value, showError: false })}
                        placeholder={'Gebruikersnaam'}
                        autoComplete={'new-password'}
                        autoCapitalize={'none'}
                    />

                    {this.state.loading2 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.changePass()}>Wachtwoord herstellen</div>

                    }

                    {this.showError()}

                </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default ResetPassword;