/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availabilitySchool.css';
import '../availability/availability.css';
import '../planner/planner.scss';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';
import Colors from '../../constants/Colors';
import APIGetOwnSchoolSchedule from '../../classes/APIGetOwnSchoolSchedule';
import APIGetOwnUnavailabilitySchool from '../../classes/APIGetOwnUnavailabilitySchool';
import APIGetOwnHolidays from '../../classes/APIGetOwnHolidays';
import APIGetBlockedDays from '../../classes/APIGetBlockedDays';
import APIGetDeadlines from '../../classes/APIGetDeadlines';
import APIGetOwnSchoolHolidays from '../../classes/APIGetOwnSchoolHolidays';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import AvailabilitySchoolModal from './availabilitySchoolModal';
import SchoolHolidayModal from './schoolHolidayModal';
import UnavailableSchoolModal from './unavailableSchoolModal';
import BetterModal from '../../components/modal/modal';

class AvailabilitySchool extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && Data.data.appOptions.availability !== 'SCHOOL') {
      this.props.history.push("/");
    }

    this.available = [];
    this.unavailable = [];
    this.holidayDays = [];
    this.blockedDays = [];
    this.deadlines = [];

    // compwillmount

    const date = new Date();
    const month = date.getMonth() + 2;
    const year = date.getFullYear();
    const day = date.getDate();

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);
    
    this.state = {

      loading: true,
      reloading: false,

      day: day,
      month: month,
      year: year,
      daysInMonth: daysCurrentMonth,
      dateText: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,

      selectedDate: date,

      showModal: false, 
      modalMode: 0, 
      modalDay: '',
      modalDayNumber: 0,
      modalData: [],

      showModalHoliday: false, 
      modalModeHoliday: 0, 
      modalHoliday: '',
      modalHolidayNumber: 0,
      modalDataHoliday: [],

      showModalUnavail: false, 
      modalModeUnavail: 0, 
      modalDataUnavail: [],

      showAlert: false,

      schoolHolidays: [],

      timeNow: new Date(),

      showQuestionModal: false,
      tutorialModal: false,

    };

    this.navBar = React.createRef();

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.appOptions.availability !== 'SCHOOL') {
      return;
    }

    this.setState({
        timeNow: await Constants.getDateNow(),
    });

    await this.getDeadlines();

    await this.getAvailable();
    await this.getUnavailable();
    await this.getSchoolHolidays()
    await this.getHolidayDays();
    await this.getBlockedDays();

    if(Data.data.inTutorial === true) {

      this.setState({ tutorialModal: true });

      this.openQuestionModal();

    }

    this.setState({loading: false});

  }

  async getAvailable() {
    
    const availableDates = await APIGetOwnSchoolSchedule.Request(Data.data.accountData.account_username);

    if(availableDates === 'error') {
      this.setState({ 
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.available = [];

    for(let key in availableDates) {

      this.available.push(availableDates[key]);

    }

  }

  async getUnavailable() {
    
    const unavailableDates = await APIGetOwnUnavailabilitySchool.Request(Data.data.accountData.account_username);

    if(unavailableDates === 'error') {
      this.setState({ 
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.unavailable = [];

    for(let key in unavailableDates) {
      let dateData = [];

      dateData = unavailableDates[key].onbeschikbaar_datum.split('-');

      this.unavailable.push(
          {
              year: parseInt(dateData[0]),
              month: parseInt(dateData[1]),
              day: parseInt(dateData[2]),
              start: unavailableDates[key].onbeschikbaar_begin,
              end: unavailableDates[key].onbeschikbaar_eind,
              comment: unavailableDates[key].onbeschikbaar_opmerking,
              id: unavailableDates[key].onbeschikbaar_id,
          }
      );

    }

  }

  async getSchoolHolidays() {

    const schoolHolidays = await APIGetOwnSchoolHolidays.Request(Data.data.accountData.account_username);

    if(schoolHolidays === null) {
        this.setState({ 
          schoolHolidays: [],
        });
        return;
    }

    if(schoolHolidays === 'error') {
        this.setState({ 
            schoolHolidays: [],
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.setState({schoolHolidays: schoolHolidays});

}

  async getBlockedDays() {

    this.setState({ loading: true });

    this.blockedDays = await APIGetBlockedDays.Request();

    if(this.blockedDays === 'error') {

        this.blockedDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

  }

  async getHolidayDays() {

    let holidayDays = await APIGetOwnHolidays.Request();

    if(holidayDays === 'error') {

        holidayDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    if(holidayDays != null && holidayDays.length > 0) {

        let allHolidayDays = [];
        
        for(let key in holidayDays) { //iterate through holidays ranges from DB

            if(holidayDays[key].vakantie_geaccepteerd === '1') {

                let currentDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
                let endDate = Constants.stringToDate(holidayDays[key].vakantie_eind);

                let index = 0;
                while(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index).getTime() <= endDate.getTime()) {

                    allHolidayDays.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index));

                    index++;

                }

            }

        }


        this.holidayDays = allHolidayDays;

    } else {

        this.holidayDays = [];

    }

  }

  async getDeadlines() {

    this.deadlines = await APIGetDeadlines.Request();

    if(this.deadlines === 'error') {
        this.deadlines = [];
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  inHolidayDays(i) {

    for(let key in this.holidayDays) {

        const date = Constants.stringToDate(this.holidayDays[key]);

        if(date.getDate() === i && date.getMonth() === (this.state.month - 1) && date.getFullYear() === this.state.year) {

            return true;

        }

    }

    return false;

  }

  inBlockedDays(i) {

    for(let key in this.blockedDays) {

        const date = Constants.stringToDate(this.blockedDays[key].geblokkeerd_datum);

        if(date.getDate() === i && date.getMonth() === (this.state.month - 1)) {

            return true;

        }

    }

    return false;

  }

  inDeadlinePeriod(i) {

    const today = this.state.timeNow;

    for(let key in this.deadlines) {

      if(this.deadlines[key].deadline_aanpassen === '1') {
          continue; //skip
      }

      const startDate = Constants.stringToDate(this.deadlines[key].deadline_begin);
      const endDate = Constants.stringToDate(this.deadlines[key].deadline_eind);
      const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadline_datum, this.deadlines[key].deadline_tijd);
      const dayDateObject = Constants.stringToDate(`${this.state.year}-${this.state.month < 10 ? '0' + this.state.month : this.state.month}-${i < 10 ? '0' + i : i}`);

      if(((this.state.month - 1) === endDate.getMonth() || (this.state.month - 1) === startDate.getMonth()) && (this.state.year === endDate.getFullYear() || this.state.year === startDate.getFullYear())) {

          if(today.valueOf() > deadlineDate.valueOf()) { //past

              if(((this.state.month - 1) === startDate.getMonth() && this.state.year === startDate.getFullYear()) || ((this.state.month - 1) === endDate.getMonth() && this.state.year === endDate.getFullYear())) {
                  if(dayDateObject.getTime() >= startDate.getTime() && dayDateObject.getTime() <= endDate.getTime()) {
                      return true;
                  }
              }

          }

      }

    }

    return false;

  }

  async reloadData() {

    this.setState({
        timeNow: await Constants.getDateNow(),
    });

    await this.getDeadlines();

    await this.getSchoolHolidays()
    await this.getAvailable();
    await this.getUnavailable();

  }

  openAvailabilityModal(mode, data, dayNumber, day) {

    this.setState({
      showModal: true, 
      modalMode: mode, 
      modalDay: day,
      modalDayNumber: dayNumber,
      modalData: data,
    });

  }

  openSchoolHolidayModal(mode, data, holidayNumber, holiday) {

    this.setState({
      showModalHoliday: true, 
      modalModeHoliday: mode, 
      modalHoliday: holiday,
      modalHolidayNumber: holidayNumber,
      modalDataHoliday: data,
    });

  }

  openUnavailabilityModal(mode, data) {

    this.setState({
      showModalUnavail: true, 
      modalModeUnavail: mode, 
      modalDataUnavail: data,
    });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.reloadData();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeModalHoliday() {

    this.setState({ 
      showModalHoliday: false,
      modalDataHoliday: [],
      modalModeHoliday: 0,
    });

  }

  closeModalUnavail() {

    this.setState({ 
      showModalUnavail: false,
      modalDataUnavail: [],
      modalModeUnavail: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  deadlineText() {

    let deadlineText = [];

    for(let key in this.deadlines) {

        const startDate = Constants.stringToDate(this.deadlines[key].deadline_begin);
        const endDate = Constants.stringToDate(this.deadlines[key].deadline_eind);
        const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadline_datum, this.deadlines[key].deadline_tijd); //NEEDEDDDD TO COMPARE TIMES converttoUTC if on other server than netherlands
      
        const startDay = startDate.getDate();
        //const startMonth = startDate.getMonth(); //jan = 0
        const startYear = startDate.getFullYear();

        const endDay = endDate.getDate();
        //const endMonth = endDate.getMonth(); //jan = 0
        const endYear = endDate.getFullYear();

        const deadlineDay = deadlineDate.getUTCDate();
        const deadlineMonth = deadlineDate.getUTCMonth(); //jan = 0
        const deadlineYear = deadlineDate.getUTCFullYear();

        const today = this.state.timeNow;

        if(((this.state.month - 1) === endDate.getMonth() && this.state.year === endDate.getFullYear()) || ((this.state.month - 1) === startDate.getMonth() && this.state.year === startDate.getFullYear())) {
            
            if(today < deadlineDate && today.getDate() === deadlineDay && today.getMonth() === deadlineMonth && today.getFullYear() === deadlineYear) {

                deadlineText.push(
                  <div className={'availSchool_deadlineContainer'} style={{borderColor: Colors.color.redFilledIn}}>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            } else if(today.getTime() > deadlineDate.getTime()) {

                deadlineText.push(
                  <div className={'availSchool_deadlineContainer'} style={{borderColor: Colors.color.pastBorderColor, backgroundColor: Colors.color.pastBackgroundColor}}>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            } else if((deadlineDay - today.getDate()) <= 7 && deadlineMonth === today.getMonth() && deadlineYear === today.getFullYear()) {

                deadlineText.push(
                  <div className={'availSchool_deadlineContainer'} style={{borderColor: Colors.color.orangeVacation}}>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            } else {

                deadlineText.push(
                  <div className={'availSchool_deadlineContainer'}>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{paddingBottom: 2}}>

                        Deadline:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{paddingBottom: 2}}>

                        <i>{deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.deadlines[key].deadline_tijd.substring(0,5)}</i>

                      </div>

                    </div>

                    <div className={'availSchool_deadlineRow'}>

                      <div className={'availSchool_deadlineRowSub1'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        Periode:

                      </div>

                      <div className={'availSchool_deadlineRowSub2'} style={{alignItems: 'flex-start', paddingTop: 2}}>

                        <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                      </div>

                    </div>

                  </div>
                );

            }

        }

    }

    return deadlineText;

  }

  schoolHolidayBar(key, filled, schoolHoliday, holiday, holidayNumber) {

    const startDate = filled === true ? Constants.stringToDate(schoolHoliday.schoolvakantie_begin) : null;
    const endDate = filled === true ? Constants.stringToDate(schoolHoliday.schoolvakantie_eind) : null;

    return(

      <div 
        key={key} 
        className="availSchool_dayBar" 
        style={{
          borderColor: Colors.color.greyNotFilled,
          backgroundColor: 'white',
        }}
        onClick={() => filled === true ? this.openSchoolHolidayModal(1, schoolHoliday, holidayNumber, holiday) : this.openSchoolHolidayModal(0, schoolHoliday, holidayNumber, holiday)}
      >

        <div className={'availSchool_rowInDayBar'}>

          <i style={{color: filled === true ? 'black' : Colors.color.redFilledIn}}>{holiday}</i>

        </div>

        <div className={'availSchool_rowInDayBar2'}>

          {filled === true ?

            <div>{startDate.getDate()} {Constants.getShortMonthName(startDate)} t/m {endDate.getDate()} {Constants.getShortMonthName(endDate)}</div>

          :

            <div style={{color: Colors.color.redFilledIn}}>-</div>

          }

        </div>

      </div>

    );

  }

  getSchoolHolidayBars() {

    let bars = {
        voorjaars: [],
        mei: [],
        zomer: [],
        herfst: [],
        kerst: [],
    };

    for(let key in this.state.schoolHolidays) {

        let schoolHoliday = this.state.schoolHolidays[key];

        if(schoolHoliday.schoolvakantie_vakantie === '1') {
            bars.voorjaars.push(this.schoolHolidayBar(key, true, schoolHoliday, 'Voorjaarsvakantie', 1));
        }
        if(schoolHoliday.schoolvakantie_vakantie === '2') {
            bars.mei.push(this.schoolHolidayBar(key, true, schoolHoliday, 'Meivakantie', 2));
        }
        if(schoolHoliday.schoolvakantie_vakantie === '3') {
            bars.zomer.push(this.schoolHolidayBar(key, true, schoolHoliday, 'Zomervakantie', 3));
        }
        if(schoolHoliday.schoolvakantie_vakantie === '4') {
            bars.herfst.push(this.schoolHolidayBar(key, true, schoolHoliday, 'Herfstvakantie', 4));
        }
        if(schoolHoliday.schoolvakantie_vakantie === '5') {
            bars.kerst.push(this.schoolHolidayBar(key, true, schoolHoliday, 'Kerstvakantie', 5));
        }

    }

    if(bars.voorjaars.length === 0) {
        bars.voorjaars.push(this.schoolHolidayBar(1, false, null, 'Voorjaarsvakantie', 1));
    }
    if(bars.mei.length === 0) {
        bars.mei.push(this.schoolHolidayBar(2, false, null, 'Meivakantie', 2));
    }
    if(bars.zomer.length === 0) {
        bars.zomer.push(this.schoolHolidayBar(3, false, null, 'Zomervakantie', 3));
    }
    if(bars.herfst.length === 0) {
        bars.herfst.push(this.schoolHolidayBar(4, false, null, 'Herfstvakantie', 4));
    }
    if(bars.kerst.length === 0) {
        bars.kerst.push(this.schoolHolidayBar(5, false, null, 'Kerstvakantie', 5));
    }

    return bars;

}

schoolDayBar(key, filled, schoolDay, day, dayNumber) {

    return (
      <div 
        key={key} 
        className="availSchool_dayBar" 
        style={{
          borderColor: Colors.color.greyNotFilled,
          backgroundColor: 'white',
        }}
        onClick={() => filled === true ? this.openAvailabilityModal(1, schoolDay, dayNumber, day) : this.openAvailabilityModal(0, null, dayNumber, day)}
      >

        <div className={'availSchool_rowInDayBar'}>

          <i style={{color: filled === true ? 'black' : Colors.color.redFilledIn}}>{day}</i>

        </div>

        <div className={'availSchool_rowInDayBar2'}>

          {filled === true ?

            <div>{schoolDay.schoolrooster_begin.substring(0, 5)} - {schoolDay.schoolrooster_eind.substring(0, 5)}</div>

          :

            <div style={{color: Colors.color.redFilledIn}}>-</div>

          }

        </div>

      </div>
    );
}

getSchoolScheduleBars() {

    let bars = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
    };

    for(let key in this.available) {

        let schoolDay = this.available[key];

        if(schoolDay.schoolrooster_dag === '1') {
            bars.mon.push(this.schoolDayBar(key, true, schoolDay, 'Maandag', 1));
        }
        if(schoolDay.schoolrooster_dag === '2') {
            bars.tue.push(this.schoolDayBar(key, true, schoolDay, 'Dinsdag', 2));
        }
        if(schoolDay.schoolrooster_dag === '3') {
            bars.wed.push(this.schoolDayBar(key, true, schoolDay, 'Woensdag', 3));
        }
        if(schoolDay.schoolrooster_dag === '4') {
            bars.thu.push(this.schoolDayBar(key, true, schoolDay, 'Donderdag', 4));
        }
        if(schoolDay.schoolrooster_dag === '5') {
            bars.fri.push(this.schoolDayBar(key, true, schoolDay, 'Vrijdag', 5));
        }

    }

    if(bars.mon.length === 0) {
        bars.mon.push(this.schoolDayBar(1, false, null, 'Maandag', 1));
    }
    if(bars.tue.length === 0) {
        bars.tue.push(this.schoolDayBar(2, false, null, 'Dinsdag', 2));
    }
    if(bars.wed.length === 0) {
        bars.wed.push(this.schoolDayBar(3, false, null, 'Woensdag', 3));
    }
    if(bars.thu.length === 0) {
        bars.thu.push(this.schoolDayBar(4, false, null, 'Donderdag', 4));
    }
    if(bars.fri.length === 0) {
        bars.fri.push(this.schoolDayBar(5, false, null, 'Vrijdag', 5));
    }

    return bars;

}

  getUnavailabilityBars() {

    let bars = [];

    for (let key in this.unavailable) {

        bars.push(
          <div onClick={() => this.openUnavailabilityModal(1, this.unavailable[key])}>
            <div className='availSchool_whiteBar'
            />

            <div className='availSchool_touchContainer'
              style={
                        { 
                          borderBottomColor: Colors.color.redFilledIn,
                          borderLeftColor: Colors.color.redFilledIn,
                          backgroundColor: 'white',
                        }
                    } 
            >

              <div className='availSchool_timeContainer'>

              {this.unavailable[key].day} {Constants.getShortMonthName(`${this.unavailable[key].year}-${this.unavailable[key].month < 10 ? '0' + this.unavailable[key].month : this.unavailable[key].month}-${this.unavailable[key].day < 10 ? '0' + this.unavailable[key].day : this.unavailable[key].day}`)} {this.unavailable[key].year}

              </div>

              <div className='availSchool_functionContainer'>

                {this.unavailable[key].start === '00:00:00' && this.unavailable[key].end === '00:00:00' ?
                                
                    <div>Hele dag</div>

                :

                    <div>{this.unavailable[key].start.substring(0, 5)} - {this.unavailable[key].end.substring(0, 5)}</div>
            
                }

              </div>

            </div>
          </div>
        );

    }

    return bars;
  }

  openQuestionModal() {

    this.setState({ showQuestionModal: true });

  }

  closeQuestionModal() {

    this.setState({ showQuestionModal: false });

  }

  render() {

    const schoolScheduleBars = this.getSchoolScheduleBars();
    const schoolHolidayBars = this.getSchoolHolidayBars();
    const deadlineBar = this.deadlineText();
    const unavailableBars = this.getUnavailabilityBars();

    return (
        <div className='availability'>

          <Navbar ref={this.navBar} history={this.props.history} active={'availability'} loading={this.state.loading} reloading={this.state.reloading} />

          {this.state.showQuestionModal === true ?
                
            <BetterModal 
              _closeModal={this.closeQuestionModal.bind(this)}
              
              _header={'Informatie'}
              _general={{
                header: 'Beschikbaarheid',
                content: (
                    <div>Op deze pagina kun je jouw schoolrooster en jouw schoolvakanties toevoegen. Ook kun je jouw onbeschikbaarheid toevoegen door op het "plus"-knopje te klikken.</div>
                ),
              }}
              _alineas={[
                  {
                      header: 'Wijzigen/verwijderen',
                      content: (
                          <div>Om jouw schoolrooster te wijzigen/verwijderen, klik je op een dag waarin je jouw schooltijden hebt aangegeven. Voor schoolvakanties klik je op één van de schoolvakanties die je hebt aangegeven.<br/><br/>Om jouw onbeschikbaarheid te wijzigen/verwijderen, druk je op een onbeschikbare dag.</div>
                      ),
                  },
                  {
                      header: 'Deadlines',
                      content: (
                          <div>Jouw werkgever kan ervoor kiezen om een deadline toe te voegen voor wanneer je jouw beschikbaarheid moet hebben aangegeven. Na de deadline kan jouw werkgever ervoor kiezen om beschikbaarheid voor de gekozen periode op slot te zetten.</div>
                      ),
                  },
              ]}
              _tutorialModal={this.state.tutorialModal}
              _history={this.props.history}
              _navBar={this.navBar.current}
            />

          :

            null
          
          }

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Beschikbaarheid
            </div> 
            <div className='functions_nav2_sub4' onClick={() => this.openUnavailabilityModal(0, null)}>
                <i className='fa fa-plus'></i>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          <div className='availSchool_optionsBar'>

            {/* <div className='planner_questionButton' onClick={this.openQuestionModal.bind(this)}>
              <i className={'far fa-question'} />
              Hulp
            </div> */}

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='availSchool_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <AvailabilitySchoolModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _modalDay={this.state.modalDay}
                  _modalDayNumber={this.state.modalDayNumber}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showModalHoliday === true ?
                
                <SchoolHolidayModal 
                  _closeModal={this.closeModalHoliday.bind(this)}
                  _modalData={this.state.modalDataHoliday}
                  _modalMode={this.state.modalModeHoliday}
                  _modalHoliday={this.state.modalHoliday}
                  _modalHolidayNumber={this.state.modalHolidayNumber}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showModalUnavail === true ?
                
                <UnavailableSchoolModal 
                  _closeModal={this.closeModalUnavail.bind(this)}
                  _modalData={this.state.modalDataUnavail}
                  _modalMode={this.state.modalModeUnavail}
                  _deadlines={this.deadlines}
                  _blockedDates={this.blockedDays}
                  _schoolHolidays={this.state.schoolHolidays}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {deadlineBar}

              <div className={'availSchool_headerRow'} style={{marginTop: 20}}>
                <div className="availSchool_headerText">School</div>
              </div>

              <div className={'availSchool_dayBarRow'}>
                {schoolScheduleBars.mon}
                {schoolScheduleBars.tue}
                {schoolScheduleBars.wed}
                {schoolScheduleBars.thu}
                {schoolScheduleBars.fri}
              </div>

              <div className={'availSchool_headerRow'} style={{marginTop: 50}}>
                <div className="availSchool_headerText">Schoolvakanties</div>
              </div>

              <div className={'availSchool_dayBarRow'}>
                {schoolHolidayBars.voorjaars}
                {schoolHolidayBars.mei}
                {schoolHolidayBars.zomer}
                {schoolHolidayBars.herfst}
                {schoolHolidayBars.kerst}
              </div>

              <div className={'availSchool_headerRow'} style={{marginTop: 50}}>
                <div className="availSchool_headerText">Onbeschikbaar</div>
              </div>

              {unavailableBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default AvailabilitySchool;