/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './finances.css'
import '../options/myHours/myHours.css';
import '../availability/availability.css';
import '../planner/planner.scss';
import '../schedule/schedule.scss';
import '../../constants/DefaultStyle.css';
import '../options/accounts/accounts.css';
import '../employees/employees.css';
import '../options/hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';

import { Chart } from "react-google-charts";
import APIGetAllHoursMinClock from '../../classes/APIGetAllHoursMinClock';
import APIGetAllHoursMin from '../../classes/APIGetAllHoursMin';
import APIGetSchedule from '../../classes/APIGetSchedule';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGetAllContracts from '../../classes/APIGetAllContracts';
import Colors from '../../constants/Colors';
import APIEditContractSalary from '../../classes/APIEditContractSalary';
import APIAddContractSalary from '../../classes/APIAddContractSalary';
import APIGetGains from '../../classes/APIGetGains';
import APIAddGains from '../../classes/APIAddGains';
import APIEditGains from '../../classes/APIEditGains';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGET from '../../classes/global/APIGET';
import APIEDIT from '../../classes/global/APIEDIT';
import APIADD from '../../classes/global/APIADD';
import APIDELETE from '../../classes/global/APIDELETE';

class Finances extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    this.navBar = React.createRef();

    const date = this.props.location.date !== undefined && this.props.location.date !== null ? this.props.location.date : new Date();
    const refer = this.props.location.refer !== undefined && this.props.location.date !== null ? this.props.location.refer : null;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.salaryFactor = parseFloat(Data.data.appOptions.opties_loonfactor);

    this.toPlanData = {};

    this.teamUsers = [];

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      accounts: [],
      accountsObj: {},
      contracts: {},
      plannerData: {},
      plannerDataMonth: {},
      clockData: [],
      yearlyData: [],
      monthlyData: {},
      gains: [],
      gainsNew: {},
      gainsExpectNew: {},

      editedContracts: {},
      editedGains: {},
      editedGainsExpect: {},

      day: day,
      month: refer === 'gains' ? new Date().getMonth() : 12,
      year: year,
      dateText: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,

      menu: refer !== null ? refer : 'salaries',
      extraMenu: 'add',

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      now: new Date(),

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
    }

    for (let i = 1; i <= 12; i++) {
      if (Data.data.appOptions.hours === 'CLOCK') {
        this.toPlanData[i] = await this.getAllHoursClock(i, this.state.year);
      } else {
        this.toPlanData[i] = await this.getAllHoursMin(i, this.state.year);
      }
    }

    await this.getAccounts();
    await this.getContracts();
    await this.getGains();
    await this.getYearHours();

    this.setState({
      now: await Constants.getDateNow(),
      loading: false,
    });

  }

  async reloadData() {

    this.setState({ 
      loading: true,
      accounts: [],
      contracts: {},
      plannerData: {},
      plannerDataMonth: {},
      clockData: [],
      yearlyData: [],
      monthlyData: {},
      editedContracts: {},
      editedGains: {},
      gains: [],
      now: await Constants.getDateNow(),
    });

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
    }

    for (let i = 1; i <= 12; i++) {
      if (Data.data.appOptions.hours === 'CLOCK') {
        this.toPlanData[i] = await this.getAllHoursClock(i, this.state.year);
      } else {
        this.toPlanData[i] = await this.getAllHoursMin(i, this.state.year);
      }
    }

    await this.getAccounts();
    await this.getContracts();
    await this.getGains();
    await this.getYearHours();

    this.setState({ loading: false });

  }

  async getYearHours() {

    let i = 1;
    let plannerData = this.state.plannerData;
    let plannerDataMonth = this.state.plannerDataMonth;
    const now = this.state.now;

    //const generalSalaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);

    // iterate 12 months 1 = jan 12 = dec
    while (i <= 12) {

      let planData = this.toPlanData[i];

      for (let plan of planData) {

        if (Constants.isEmpty(plan.info_id) === true) {
          continue;
        }

        if (Data.data.appOptions.hours === 'CLOCK') {
          plan = {
            ...plan,
            rooster_bruto_uren: plan.kloktijd_bruto_uren,
            rooster_pauze_totaal: Data.data.appOptions.brk === 'PLANNER' ? Constants.msToTime(plan.kloktijd_pauze_totaal*60*60*1000) : plan.kloktijd_pauze_totaal,
            rooster_werkdagen: plan.kloktijd_werkdagen,
            rooster_netto_uren: Data.data.appOptions.brk === 'PLANNER' ? Constants.msToTime(plan.kloktijd_netto_uren*60*60*1000) : plan.kloktijd_netto_uren,
          };
        }

        const hasSalary = typeof this.state.contracts[plan.info_id] !== 'undefined'
                          && this.state.contracts[plan.info_id].contract_bruto_uurloon !== null 
                          && this.state.contracts[plan.info_id].contract_bruto_uurloon !== '';

        let salary = hasSalary === true ? parseFloat(this.state.contracts[plan.info_id].contract_bruto_uurloon) : 0;

        // check birth month and then define birthday and age with state.year
        // if (typeof this.state.accountsObj[plan.info_id] !== 'undefined') {

        //   const userInfo = this.state.accountsObj[plan.info_id];
        //   const birthDate = Constants.stringToDate(userInfo.info_geboorte);
        //   const age = Constants.getAge(now, birthDate);
        //   const planDate = Constants.stringToDate(plan.rooster_datum);
        //   const ageAtTime = Constants.getAge(new Date(this.state.year, planDate.getMonth(), planDate.getDate()), birthDate);

        //   let hasSalaryFromTable = false;

        //   if ((age) <= parseInt(generalSalaries[0].uurloon_leeftijd)) {
        //     if (salary === parseFloat(generalSalaries[0].uurloon_hoeveelheid)) {
        //       hasSalaryFromTable = true;
        //     }
        //   } else if ((age) >= parseInt(generalSalaries[generalSalaries.length - 1].uurloon_leeftijd)) {
        //     if (salary === parseFloat(generalSalaries[generalSalaries.length - 1].uurloon_hoeveelheid)) {
        //       hasSalaryFromTable = true;
        //     }
        //   } else {
        //     for (const generalSalary of generalSalaries) {
        //       if ((age) === parseInt(generalSalary.uurloon_leeftijd)) {
        //         if (salary === parseFloat(generalSalary.uurloon_hoeveelheid)) {
        //           hasSalaryFromTable = true;
        //           break;
        //         }
        //       }
        //     }
        //   }

        //   if (hasSalaryFromTable === true) {

        //     if ((ageAtTime) <= parseInt(generalSalaries[0].uurloon_leeftijd)) {
        //       salary = parseFloat(generalSalaries[0].uurloon_hoeveelheid);
        //     } else if ((ageAtTime) >= parseInt(generalSalaries[generalSalaries.length - 1].uurloon_leeftijd)) {
        //       salary = parseFloat(generalSalaries[generalSalaries.length - 1].uurloon_hoeveelheid);
        //     } else {
        //       for (const generalSalary of generalSalaries) {
        //         if ((ageAtTime) === parseInt(generalSalary.uurloon_leeftijd)) {
        //           salary = parseFloat(generalSalary.uurloon_hoeveelheid);
        //           break;
        //         }
        //       }
        //     }

        //   }

        // }

        if ((((i - 1) < now.getMonth() && this.state.year === now.getFullYear()) || this.state.year < now.getFullYear()) || this.state.menu === 'expect') {

          // user has no data
          if (plannerData[plan.info_id] === 0) {

            plannerData[plan.info_id] = {

              id: plan.info_id,
              first: plan.info_voornaam,
              ins: plan.info_tussenvoegsel,
              last: plan.info_achternaam,

              // this is going to be resetted for monthly data
              bruto: Constants.timeStringToFloat(plan.rooster_bruto_uren),
              netto: Constants.timeStringToFloat(plan.rooster_netto_uren),
              days: parseInt(plan.rooster_werkdagen),
              break: Constants.timeStringToFloat(plan.rooster_pauze_totaal),
              nettoLoon: hasSalary === true ? (Constants.timeStringToFloat(plan.rooster_netto_uren) * salary * this.salaryFactor) : 0, //1.08 vacation

              // year is saved to next cycle
              brutoYear: Constants.timeStringToFloat(plan.rooster_bruto_uren),
              nettoYear: Constants.timeStringToFloat(plan.rooster_netto_uren),
              daysYear: parseInt(plan.rooster_werkdagen),
              breakYear: Constants.timeStringToFloat(plan.rooster_pauze_totaal),
              nettoLoonYear: hasSalary === true ? (Constants.timeStringToFloat(plan.rooster_netto_uren) * salary * this.salaryFactor) : 0,

            };

          // user has data
          } else {

            plannerData[plan.info_id].bruto += Constants.timeStringToFloat(plan.rooster_bruto_uren);
            plannerData[plan.info_id].netto += Constants.timeStringToFloat(plan.rooster_netto_uren);
            plannerData[plan.info_id].break += Constants.timeStringToFloat(plan.rooster_pauze_totaal);
            plannerData[plan.info_id].days += parseInt(plan.rooster_werkdagen);
            plannerData[plan.info_id].nettoLoon += hasSalary === true ? (Constants.timeStringToFloat(plan.rooster_netto_uren) * salary * this.salaryFactor) : 0;

            plannerData[plan.info_id].brutoYear += Constants.timeStringToFloat(plan.rooster_bruto_uren);
            plannerData[plan.info_id].nettoYear += Constants.timeStringToFloat(plan.rooster_netto_uren);
            plannerData[plan.info_id].breakYear += Constants.timeStringToFloat(plan.rooster_pauze_totaal);
            plannerData[plan.info_id].daysYear += parseInt(plan.rooster_werkdagen);
            plannerData[plan.info_id].nettoLoonYear += hasSalary === true ? (Constants.timeStringToFloat(plan.rooster_netto_uren) * salary * this.salaryFactor) : 0;

          }

        }

        // set monthly data
        if (plannerDataMonth[i - 1][plan.info_id] === 0) {

          plannerDataMonth[i - 1][plan.info_id] = {

            id: plan.info_id,
            first: plan.info_voornaam,
            ins: plan.info_tussenvoegsel,
            last: plan.info_achternaam,
            bruto: Constants.timeStringToFloat(plan.rooster_bruto_uren),
            netto: Constants.timeStringToFloat(plan.rooster_netto_uren),
            days: parseInt(plan.rooster_werkdagen),
            break: Constants.timeStringToFloat(plan.rooster_pauze_totaal),
            nettoLoon: hasSalary === true ? (Constants.timeStringToFloat(plan.rooster_netto_uren) * salary * this.salaryFactor) : 0,

          };

        } else {

          plannerDataMonth[i - 1][plan.info_id].bruto += Constants.timeStringToFloat(plan.rooster_bruto_uren);
          plannerDataMonth[i - 1][plan.info_id].netto += Constants.timeStringToFloat(plan.rooster_netto_uren);
          plannerDataMonth[i - 1][plan.info_id].break += Constants.timeStringToFloat(plan.rooster_pauze_totaal);
          plannerDataMonth[i - 1][plan.info_id].days += parseInt(plan.rooster_werkdagen);
          plannerDataMonth[i - 1][plan.info_id].nettoLoon += hasSalary === true ? (Constants.timeStringToFloat(plan.rooster_netto_uren) * salary * this.salaryFactor): 0;

        }

      }

      // ** MONTHLY DATA **

      let monthlyData = {
        omzet: 0,
        loon: 0,
        winst: 0,
      };

      for (const acc of this.state.accounts) {

        monthlyData.loon += typeof plannerData[acc.info_id].nettoLoon !== 'undefined' ? plannerData[acc.info_id].nettoLoon : 0;

        if (typeof plannerData[acc.info_id].bruto !== 'undefined') {

          plannerData[acc.info_id].bruto = 0;
          plannerData[acc.info_id].netto = 0;
          plannerData[acc.info_id].break = 0;
          plannerData[acc.info_id].days = 0;
          plannerData[acc.info_id].nettoLoon = 0;

        }

      }

      // calc gains/omzet
      for (const key in this.state.gainsNew) {
        const gain = this.state.gainsNew[key];
        if (parseInt(gain.omzetd_datum.substr(5, 2)) === i) {
          monthlyData.omzet += parseFloat(gain.omzetd_hoeveelheid);
        }
      }

      monthlyData.winst = (monthlyData.omzet - monthlyData.loon);

      let monthlyDataState = this.state.monthlyData;

      monthlyDataState[i] = {
        ...monthlyData,
      };

      // ** YEARLY DATA **

      let yearlyData = this.state.yearlyData;

      const hasOnlyNull = monthlyData.omzet === 0 && monthlyData.loon === 0 && monthlyData.winst === 0;

      if (hasOnlyNull === true || (now.getMonth() <= (i - 1) && this.state.year === now.getFullYear() && this.state.menu !== 'expect')) {
        yearlyData.push([Constants.firstUppercase(Constants.getShortMonthNameM(i - 1)), 0, 0]); //, 0
      } else {
        yearlyData.push([Constants.firstUppercase(Constants.getShortMonthNameM(i - 1)), monthlyData.omzet, Math.round((monthlyData.loon + Number.EPSILON) * 100) / 100]); //, monthlyData.winst
      }

      this.setState({
        monthlyData: monthlyDataState,
        yearlyData: yearlyData,
      });

      // cycle up
      i++;

    }

  }

  async getGains() {
    
    // const gainsData = await APIGetGains.Request(this.state.year);

    // if(gainsData === null) {
    //   // this.setState({
    //   //   plannerData: [],
    //   //   loading: false,
    //   // });
    //   return [];
    // }

    // if(gainsData === 'error') {
    //   this.setState({
    //       errorScreen: true,
    //       loading: false,
    //   });
    //   return [];
    // }

    // this.setState({gains: gainsData});

    let gains = [];
    let gainsExpect = [];
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${this.state.year} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`);
      gainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE YEAR(omzetv_datum) = ${this.state.year} AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetv_datum`);
    } else {
      gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${this.state.year}`, null, `ORDER BY omzetd_datum`);
      gainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE YEAR(omzetv_datum) = ${this.state.year}`, null, `ORDER BY omzetv_datum`);
    }

    const gainsObj = Constants.convertToObject(gains, 'omzetd_datum');
    const gainsExpectObj = Constants.convertToObject(gainsExpect, 'omzetv_datum');

    this.setState({ gainsNew: gainsObj, gainsExpectNew: gainsExpectObj });

  }

  async getAllHoursMin(month, year) {
    
    let plannerData = await APIGetAllHoursMin.Request(month, year);

    if(plannerData === null) {
      // this.setState({
      //   plannerData: [],
      //   loading: false,
      // });
      return [];
    }

    if(plannerData === 'error') {
      this.setState({
          errorScreen: true,
          loading: false,
      });
      return [];
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      plannerData = Constants.getTeamAccounts(this.teamUsers, plannerData);
    }

    //this.setState({plannerData: plannerData});

    return plannerData;

  }

  async getAccounts() {

    let accounts = await APIGetAllAccounts.Request();

    if(accounts === 'error') {

        accounts = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(accounts === null) {

        accounts = [];

    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      accounts = Constants.getTeamAccounts(this.teamUsers, accounts);
    }

    let accounts2 = {};
    let plannerDataMonth = {0: {}, 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {}, 10: {}, 11: {}};

    for (const acc of accounts) {
      accounts2[acc.info_id] = 0;

      for (const key in plannerDataMonth) {
        plannerDataMonth[key][acc.info_id] = 0;
      }
    }

    const accountsObj = Constants.convertToObject(accounts, 'info_id');

    this.setState({ plannerData: accounts2, accounts: accounts, plannerDataMonth: plannerDataMonth, accountsObj: accountsObj });

  }

  async getContracts() {

    let accounts = await APIGetAllContracts.Request();

    if(accounts === 'error') {

        accounts = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(accounts === null) {

        accounts = [];

    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      accounts = Constants.getTeamAccountsContract(this.teamUsers, accounts);
    }

    let accounts2 = {};

    for (const acc of accounts) {
      accounts2[acc.contract_info_id] = acc;
    }

    this.setState({ contracts: accounts2 });

  }

  async getAllHoursClock(month, year) {

    if(Data.data.appOptions.brk === 'PLANNER') { //CLOCK
      await this.getSchedule(month, year);
    }
    
    let plannerData = await APIGetAllHoursMinClock.Request(month, year);

    if(plannerData === null) {
      // this.setState({
      //   clockData: [],
      //   loading: false,
      // });
      return [];
    }

    if(plannerData === 'error') {
      this.setState({
          clockData: [],
          errorScreen: true,
          loading: false,
      });
      return [];
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      plannerData = Constants.getTeamAccounts(this.teamUsers, plannerData);
    }

    if(Data.data.appOptions.brk === 'PLANNER') {
      for(const clockData of plannerData) {
          clockData.kloktijd_pauze_totaal = Constants.timeStringToFloat(this.getUserPlannerBreak(clockData.info_id).substring(0, 5));
          clockData.kloktijd_netto_uren = Constants.timeStringToFloat(clockData.kloktijd_bruto_uren.substring(0, 5)) - Constants.timeStringToFloat(this.getUserPlannerBreak(clockData.info_id).substring(0, 5));
      }
    }

    return plannerData;

  }

  async getSchedule(month, year) {

    const today = this.state.now; //new Date();

    let scheduleObject = [];

    let s = null;
    if(typeof month === 'undefined' || month === null) {
      //s = await APIGetSchedule.Request(`${today.getFullYear()}-${today.getMonth() + 1}-${i}`);
      s = await APIGET.Request(`SELECT rooster_id, rooster_datum, rooster_begin, rooster_eind, rooster_publiceren, rooster_pauze, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_naam, functie_kleur, functie_id`,
      `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Functie ON rooster_functie_id = functie_id`, `WHERE MONTH(rooster_datum) = ${today.getMonth() + 1} AND YEAR(rooster_datum) = ${today.getFullYear()} AND rooster_publiceren = 1`,
      null, `ORDER BY rooster_begin, rooster_eind`);
    } else {
      //s = await APIGetSchedule.Request(`${year}-${month}-${i}`);
      s = await APIGET.Request(`SELECT rooster_id, rooster_datum, rooster_begin, rooster_eind, rooster_publiceren, rooster_pauze, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_naam, functie_kleur, functie_id`,
      `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Functie ON rooster_functie_id = functie_id`, `WHERE MONTH(rooster_datum) = ${month} AND YEAR(rooster_datum) = ${year} AND rooster_publiceren = 1`,
      null, `ORDER BY rooster_begin, rooster_eind`);
    }
    if(s !== null) {
      for(const _s of s) {
        scheduleObject.push({..._s});
      }
    }

    if(scheduleObject === null) {
      this.setState({
        schedule: [],
        loading: false,
      });
      return;
    }

    if(scheduleObject === 'error') {
      this.setState({
          schedule: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({schedule: scheduleObject});

  }

  getUserPlannerBreak(id) {

    let breakTime = '00:00';

    for(let key in this.state.schedule) {

      const schedule = this.state.schedule[key];

      const today = this.state.now;
      const scheduleDate = Constants.getDateTZ(schedule.rooster_datum, schedule.rooster_eind);

      if(schedule.info_id === id && today.getTime() >= scheduleDate.getTime()) {

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();
          // const newBreakHours = Constants.getHoursTZD(newBreakDateAndTime);
          // const newBreakMinutes = Constants.getMinutesTZD(newBreakDateAndTime);

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }
  
  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  accountBars() {

    let toReturn = [];

    let sortable = [];
    for (const key in this.state.plannerData) {

        if (this.state.plannerData[key] === 0) {

          for (const acc of this.state.accounts) {

            if (acc.info_id === key) {

              sortable.push({

                id: acc.info_id,
                first: acc.info_voornaam,
                ins: acc.info_tussenvoegsel,
                last: acc.info_achternaam,
                bruto: 0,
                netto: 0,
                days: 0,
                break: 0,
                nettoLoon: 0,
                brutoYear: 0,
                nettoYear: 0,
                daysYear: 0,
                breakYear: 0,
                nettoLoonYear: 0,
    
              });

            }

          }

        } else {
          sortable.push(this.state.plannerData[key]);
        }
    }

    sortable.sort((a, b) => (a.nettoLoonYear > b.nettoLoonYear || (a.nettoLoonYear === b.nettoLoonYear && a.first < b.first) || (a.nettoLoonYear === b.nettoLoonYear && a.first === b.first && a.last < b.last)) ? -1 : 1);

    for(let key in sortable) {

      let plannerData = sortable[key];

      const userFullName = `${plannerData.first} ${(plannerData.ins !== null && plannerData.ins !== '') ? plannerData.ins + " " + plannerData.last : plannerData.last}`;

      const hasSalary = typeof this.state.contracts[plannerData.id] !== 'undefined'
                          && this.state.contracts[plannerData.id].contract_bruto_uurloon !== null 
                          && this.state.contracts[plannerData.id].contract_bruto_uurloon !== '';

      toReturn.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'} style={{flex: 2}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow'}>
            {Math.round((plannerData.nettoYear + Number.EPSILON) * 100) / 100}
          </div>
          <div className={'myHours_barRow'}>
            {Math.round((plannerData.breakYear + Number.EPSILON) * 100) / 100}
          </div>
          <div className={'myHours_barRow'}>
            {hasSalary === true ?
              <span style={{fontWeight: 'bold'}}>{this.formatter.format(Math.round((plannerData.nettoLoonYear + Number.EPSILON) * 100) / 100)}</span>
            :
              <span className='defaultLink' onClick={() => this.props.history.push({pathname: '/salaries', menu: 'salary'})}>Uurloon toevoegen</span>
            }
          </div>

        </div>

      );

    }

    return toReturn;

  }

  accountBarsMonth() {

    let toReturn = [];

    let sortable = [];
    for (const key in this.state.plannerDataMonth[this.state.month]) {

        if (this.state.plannerDataMonth[this.state.month][key] === 0) {

          for (const acc of this.state.accounts) {

            if (acc.info_id === key) {

              sortable.push({

                id: acc.info_id,
                first: acc.info_voornaam,
                ins: acc.info_tussenvoegsel,
                last: acc.info_achternaam,
                bruto: 0,
                netto: 0,
                days: 0,
                break: 0,
                nettoLoon: 0,
                brutoYear: 0,
                nettoYear: 0,
                daysYear: 0,
                breakYear: 0,
                nettoLoonYear: 0,
    
              });

            }

          }

        } else {
          sortable.push(this.state.plannerDataMonth[this.state.month][key]);
        }
    }

    sortable.sort((a, b) => (a.nettoLoon > b.nettoLoon|| (a.nettoLoon === b.nettoLoon && a.first < b.first) || (a.nettoLoon === b.nettoLoon && a.first === b.first && a.last < b.last)) ? -1 : 1);

    for(let key in sortable) {

      let plannerData = sortable[key];

      const userFullName = `${plannerData.first} ${(plannerData.ins !== null && plannerData.ins !== '') ? plannerData.ins + " " + plannerData.last : plannerData.last}`;

      const hasSalary = typeof this.state.contracts[plannerData.id] !== 'undefined'
                          && this.state.contracts[plannerData.id].contract_bruto_uurloon !== null 
                          && this.state.contracts[plannerData.id].contract_bruto_uurloon !== '';

      toReturn.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'} style={{flex: 2}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow'}>
            {Math.round((plannerData.netto + Number.EPSILON) * 100) / 100}
          </div>
          <div className={'myHours_barRow'}>
            {Math.round((plannerData.break + Number.EPSILON) * 100) / 100}
          </div>
          <div className={'myHours_barRow'}>
            {hasSalary === true ?
              <span style={{fontWeight: 'bold'}}>{this.formatter.format(Math.round((plannerData.nettoLoon + Number.EPSILON) * 100) / 100)}</span>
            :
              <span className='defaultLink' onClick={() => this.props.history.push({pathname: '/salaries', menu: 'salary'})}>Uurloon toevoegen</span>
            }
          </div>

        </div>

      );

    }

    return toReturn;

  }

  accountBarsSalary() {

    let toReturn = [];

    for (const acc of this.state.accounts) {

      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

      const hasSalary = typeof this.state.contracts[acc.info_id] !== 'undefined'
                          && this.state.contracts[acc.info_id].contract_bruto_uurloon !== null 
                          && this.state.contracts[acc.info_id].contract_bruto_uurloon !== '';

      const salary = hasSalary === true ? parseFloat(this.state.contracts[acc.info_id].contract_bruto_uurloon) : 0;

      toReturn.push(

        <div key={acc.info_id} className={'myHours_barContainer'}>

          <div className={'myHours_barRow2'} style={{flex: 3}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow2'}>
            {/* {hasSalary === true ?
              <span style={{fontWeight: 'bold'}}>{this.formatter.format(Math.round((salary + Number.EPSILON) * 100) / 100)}</span>
            :
              <span className='defaultLink'>Uurloon toevoegen</span>
            } */}
            <input 
              className={'inputField2'}
              min={1}
              max={1000}
              value={typeof this.state.editedContracts[acc.info_id] !== 'undefined' ? this.state.editedContracts[acc.info_id] : hasSalary === true ? salary : ''}
              onChange={(event) => this.onChangeNumberData('salary', 1, 1000, event, acc.info_id)}
              placeholder={'Uurloon'}
              type={'number'}
              step={0.01}
              style={{marginTop: 0}}
            />
          </div>

        </div>

      );
      
    }

    return toReturn;

  }

  onChangeNumberDataFloat(data, min, max, event, key) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (data === 'gains') {

      let gains = this.state.gainsNew;

      if (typeof gains[key] === 'undefined') {
        gains[key] = {
          omzetd_datum: key,
          omzetd_hoeveelheid: '',
        };
      }

      if (nr !== '') {
        gains[key].omzetd_hoeveelheid = nr;
      } else {
        gains[key].omzetd_hoeveelheid = '';
      }

      this.setState({ gainsNew: gains });

    } else if (data === 'gainsExpect') {

      let gains = this.state.gainsExpectNew;

      if (typeof gains[key] === 'undefined') {
        gains[key] = {
          omzetv_datum: key,
          omzetv_hoeveelheid: '',
        };
      }

      if (nr !== '') {
        gains[key].omzetv_hoeveelheid = nr;
      } else {
        gains[key].omzetv_hoeveelheid = '';
      }

      this.setState({ gainsExpectNew: gains });

    }

  }

  GainsBars() {

    let toReturn = [];

    let weekDivs = [];
    const weeks = Constants.getWeeksStartAndEndInMonth(this.state.month, this.state.year);

    for (const week of weeks) {

      let dates = [];

      for (let i = week.start; i <= week.end; i++) {

        const gainsID = `${this.state.year}-${(this.state.month + 1) < 10 ? '0' + (this.state.month + 1) : (this.state.month + 1)}-${i < 10 ? '0' + i : i}`;
        const gainsDate = Constants.stringToDate(gainsID);

        dates.push(

          <div key={i} className='finances_gainsRow'>

            <div className='finances_gainsRow_sub1'>

              <span style={{fontSize: '0.8vw', color: Colors.color.pastBackgroundColor}}>{Constants.getShortDayName(gainsDate).toLowerCase()}</span>

            </div>

            <div className='finances_gainsRow_sub2'>

              <span style={{fontSize: '1vw'}}>{i} {Constants.getShortMonthNameM(this.state.month)}</span>

              <input 
                className={'finances_inputBoxTime'}
                min={0}
                max={999999999}
                value={typeof this.state.gainsNew[gainsID] === 'undefined' ? '' : this.state.gainsNew[gainsID].omzetd_hoeveelheid}
                onChange={(event) => this.onChangeNumberDataFloat('gains', 0, 999999999, event, gainsID)}
                placeholder={'Omzet'}
                type={'number'}
                step={0.01}
              />

            </div>

          </div>

        );

      }

      weekDivs.push(

        <div key={week.week} className='finances_week'>
          
          <span style={{fontWeight: 'bold', fontSize: '1vw'}}>Week {week.week}</span>

          <div style={{marginTop: '2%'}}>
            {dates}
          </div>

        </div>

      );

    }

    const rows = Constants.chunkArray(weekDivs, 2);

    let rowsCounts = 0;
    for (const row of rows) {

      toReturn.push(

        <div key={'weekcon' + rowsCounts} className='finances_weekContainer'>
          
          {row}

        </div>

      );

      rowsCounts++;

    }

    return toReturn;

  }

  GainsExpectBars() {

    let toReturn = [];

    let weekDivs = [];
    const weeks = Constants.getWeeksStartAndEndInMonth(this.state.month, this.state.year);

    for (const week of weeks) {

      let dates = [];

      for (let i = week.start; i <= week.end; i++) {

        const gainsID = `${this.state.year}-${(this.state.month + 1) < 10 ? '0' + (this.state.month + 1) : (this.state.month + 1)}-${i < 10 ? '0' + i : i}`;
        const gainsDate = Constants.stringToDate(gainsID);

        dates.push(

          <div key={i} className='finances_gainsRow'>

            <div className='finances_gainsRow_sub1'>

              <span style={{fontSize: '0.8vw', color: Colors.color.pastBackgroundColor}}>{Constants.getShortDayName(gainsDate).toLowerCase()}</span>

            </div>

            <div className='finances_gainsRow_sub2'>

              <span style={{fontSize: '1vw'}}>{i} {Constants.getShortMonthNameM(this.state.month)}</span>

              <input 
                className={'finances_inputBoxTime'}
                min={0}
                max={999999999}
                value={typeof this.state.gainsExpectNew[gainsID] === 'undefined' ? '' : this.state.gainsExpectNew[gainsID].omzetv_hoeveelheid}
                onChange={(event) => this.onChangeNumberDataFloat('gainsExpect', 0, 999999999, event, gainsID)}
                placeholder={'Verwachte omzet'}
                type={'number'}
                step={0.01}
              />

            </div>

          </div>

        );

      }

      weekDivs.push(

        <div key={week.week} className='finances_week'>
          
          <span style={{fontWeight: 'bold', fontSize: '1vw'}}>Week {week.week}</span>

          <div style={{marginTop: '2%'}}>
            {dates}
          </div>

        </div>

      );

    }

    const rows = Constants.chunkArray(weekDivs, 2);

    let rowsCounts = 0;
    for (const row of rows) {

      toReturn.push(

        <div key={'weekcon' + rowsCounts} className='finances_weekContainer'>
          
          {row}

        </div>

      );

      rowsCounts++;

    }

    return toReturn;

  }

  getTotalGainsYear() {

    let amount = 0;

    // for (const gain of this.state.gains) {

    //     if (parseInt(gain.omzet_id.substr(0, 2)) >= (this.state.now.getMonth() + 1)) {
    //       continue;
    //     }

    //     amount += parseFloat(gain.omzet_hoeveelheid);

    // }

    for (const key in this.state.gainsNew) {

      const gain = this.state.gainsNew[key];

      if (this.state.year === this.state.now.getFullYear() && parseInt(gain.omzetd_datum.substr(5, 2)) >= (this.state.now.getMonth() + 1) && parseInt(gain.omzetd_datum.substr(8, 2)) > this.state.now.getDate()) {
        continue;
      }

      amount += parseFloat(gain.omzetd_hoeveelheid);

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalGainsYearExpect() {

    let amount = 0;

    for (const key in this.state.gainsNew) {

      const gain = this.state.gainsNew[key];

      amount += parseFloat(gain.omzetd_hoeveelheid);

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalWinsYear() {

    let amount = 0;

    for (const gain of this.state.gains) {

        amount += parseFloat(gain.omzet_hoeveelheid);

    }

    for (const key in this.state.plannerData) {

      const data = this.state.plannerData[key];

      if (data !== 0) {

        amount -= data.nettoLoonYear;

      }

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalSalaryYear() {

    let amount = 0;

    for (const key in this.state.plannerData) {

      const data = this.state.plannerData[key];

      if (data !== 0) {

        amount += data.nettoLoonYear;

      }

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalSalaryMonth() {

    let amount = 0;

    for (const key in this.state.plannerDataMonth[this.state.month]) {

      const data = this.state.plannerDataMonth[this.state.month][key];

      if (data !== 0) {

        amount += data.nettoLoon;

      }

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalGainsMonth() {

    let amount = 0;

    // for (const gain of this.state.gains) {

    //   if (parseInt(gain.omzet_id.substring(0, 2)) === this.state.month + 1) {

    //     amount = parseFloat(gain.omzet_hoeveelheid);

    //   }

    // }

    for (const key in this.state.gainsNew) {

      const gain = this.state.gainsNew[key];

      if (parseInt(gain.omzetd_datum.substr(5, 2)) === (this.state.month + 1)) {
        amount += isNaN(parseFloat(gain.omzetd_hoeveelheid)) === true ? 0 : parseFloat(gain.omzetd_hoeveelheid);
      }

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalGainsExpectMonth() {

    let amount = 0;

    // for (const gain of this.state.gains) {

    //   if (parseInt(gain.omzet_id.substring(0, 2)) === this.state.month + 1) {

    //     amount = parseFloat(gain.omzet_hoeveelheid);

    //   }

    // }

    for (const key in this.state.gainsExpectNew) {

      const gain = this.state.gainsExpectNew[key];

      if (parseInt(gain.omzetv_datum.substr(5, 2)) === (this.state.month + 1)) {
        amount += isNaN(parseFloat(gain.omzetv_hoeveelheid)) === true ? 0 : parseFloat(gain.omzetv_hoeveelheid);
      }

    }

    return this.formatter.format(Constants.round(amount));

  }

  getTotalWinsMonth() {

    let amount = 0;

    for (const gain of this.state.gains) {

      if (parseInt(gain.omzet_id.substring(0, 2)) === this.state.month + 1) {

        amount = parseFloat(gain.omzet_hoeveelheid);

      }

    }

    for (const key in this.state.plannerDataMonth[this.state.month]) {

      const data = this.state.plannerDataMonth[this.state.month][key];

      if (data !== 0) {

        amount -= data.nettoLoon;

      }

    }

    return this.formatter.format(Constants.round(amount));

  }

  goToYear(next) {

    if (this.state.loading === true) {
      return;
    }

    let year = next === true ? this.state.year + 1 : this.state.year - 1;

    this.setState({
      year: year,
      loading: true,
    }, () => {
      this.reloadData();
    });

  }

  selectMonth(month) {

    if (this.state.month === 12) {
      const myDiv = document.getElementById('financesContentScroll');
      myDiv.scrollTop = 0;
    }

    if (month === 12) {
      const myDiv = document.getElementById('financesContentScroll');
      myDiv.scrollTop = 0;
    }

    this.setState({
      month: month,
    });

  }

  selectMenu(menu) {

    if (this.state.loading === true || this.state.menu === menu) {
      return;
    }

    if (menu === 'salaries' || menu === 'expect') {

      this.setState({
        menu: menu,
        month: 12,
      }, async () => {
        this.setState({ loading: true });
        await this.reloadData();
      });

    } else if (menu === 'gains' || menu === 'gainsExpect') {

      this.setState({
        menu: menu,
        month: this.state.now.getMonth(),
      });

    } else {

      this.setState({
        menu: menu,
        month: 12,
      });

    }

    const myDiv = document.getElementById('financesContentScroll');
    myDiv.scrollTop = 0;

  }

  selectExtraMenu(menu) {

    if (this.state.loading === true || this.state.menu === menu) {
      return;
    }

    this.setState({
      extraMenu: menu,
    });

    const myDiv = document.getElementById('financesContentScroll');
    myDiv.scrollTop = 0;

  }

  onChangeNumberData(data, min, max, event, id) {

    let formatted = event.target.value;

    let nr = formatted;

    // let nr = 0;

    // if (formatted.includes('.0') || formatted.includes(',0')) {
    //   nr = parseFloat(formatted) + .0;
    // } else {
    //   nr = parseFloat(formatted);
    // }

    // if (isNaN(nr) === true) {
    //     nr = formatted.replace('.', ',');
    // }

    // if (isNaN(nr) === true) {
    //     nr = formatted.replace(',', '.');
    // }

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (data === 'salary') {

      let editedContracts = this.state.editedContracts;

      editedContracts[id] = nr.toString();

      this.setState({ editedContracts: editedContracts, });

    } else if (data === 'gains') {

      let editedGains = this.state.editedGains;

      editedGains[id] = nr.toString();

      this.setState({ editedGains: editedGains, });

    }

  }

  async saveSalaryAlert() {

    this.setState({
        alertTitle: 'Wijzigen',
        alertBody: (
            <div>
                Weet je zeker dat je deze bruto uurlonen wilt opslaan?
            </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Opslaan'],
        alertButtonAction: this.editContract.bind(this),
        showAlert: true,
    });
  }

  async editContract() {

    this.setState({ loading: true });

    for (const key in this.state.editedContracts) {

      let salary = parseFloat(this.state.editedContracts[key]);

      if (isNaN(salary) === true || this.state.editedContracts[key] === '') {
        salary = null;
      }

      const hasContract = typeof this.state.contracts[key] !== 'undefined';

      let done = false;

      if (hasContract === true) {

        done = await APIEditContractSalary.Request(key, salary);

      } else {

        done = await APIAddContractSalary.Request(key, salary);

      }

      if (done === false) {
        this.setState({loading: false});
        break;
      }

    }

    await this.reloadData();

    this.setState({loading: false});

  }

  async saveGainsAlert() {

    this.setState({
        alertTitle: 'Wijzigen',
        alertBody: (
            <div>
                Weet je zeker dat je de omzet van {this.state.year} wilt opslaan?
            </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Opslaan'],
        alertButtonAction: this.editGains.bind(this),
        showAlert: true,
    });
  }

  async saveGainsExpectAlert() {

    this.setState({
        alertTitle: 'Wijzigen',
        alertBody: (
            <div>
                Weet je zeker dat je de verwachte omzet van {this.state.year} wilt opslaan?
            </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Opslaan'],
        alertButtonAction: this.editGainsExpect.bind(this),
        showAlert: true,
    });
  }

  async editGains() {

    this.setState({ loading: true });

    // for (const key in this.state.editedGains) {

    //   let salary = parseFloat(this.state.editedGains[key]);

    //   if (isNaN(salary) === true || this.state.editedGains[key] === '') {
    //     continue;
    //   }

    //   let hasGains = false;

    //   for (const gain of this.state.gains) {
    //     if (gain.omzet_id === key) {
    //       hasGains = true;
    //     }
    //   }

    //   let done = false;

    //   if (hasGains === true) {

    //     done = await APIEditGains.Request(key, salary);

    //   } else {

    //     done = await APIAddGains.Request(key, this.state.year, salary);

    //   }

    //   if (done === false) {
    //     this.setState({loading: false});
    //     break;
    //   }

    // }

    const highestID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);

    let promises = [];

    for (const key in this.state.gainsNew) {

      const gain = this.state.gainsNew[key];

      if (typeof gain.omzetd_id === 'undefined') { // is newly added gains

        if (isNaN(gain.omzetd_hoeveelheid) === true || Constants.isEmpty(gain.omzetd_hoeveelheid) === true) {
          continue;
        }

        promises.push(APIADD.Request(`INSERT INTO OmzetDagelijks`, `VALUES (NULL, '${gain.omzetd_datum}', ${gain.omzetd_hoeveelheid})`));

      } else { // is edited gains

        if (isNaN(gain.omzetd_hoeveelheid) === true || Constants.isEmpty(gain.omzetd_hoeveelheid) === true) {
          promises.push(APIDELETE.Request(`DELETE FROM OmzetDagelijks`, `WHERE omzetd_id = ${gain.omzetd_id}`));
          continue;
        }

        promises.push(APIEDIT.Request(`UPDATE OmzetDagelijks`, `SET omzetd_hoeveelheid = ${gain.omzetd_hoeveelheid}`, `WHERE omzetd_id = ${gain.omzetd_id}`));

      }

    }

    await Promise.all(promises);

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      const newHighestID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);

      if (parseInt(newHighestID[0].omzetd_id) > parseInt(highestID[0].omzetd_id)) {

        let promises2 = [];
        for (let i = parseInt(highestID[0].omzetd_id); i < parseInt(newHighestID[0].omzetd_id); i++) {
          promises2.push(APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${(i + 1)}, ${Data.data.chosenTeamObj.team_id})`));
        }

        await Promise.all(promises2);

      }

    }

    await this.reloadData();

    this.setState({loading: false});

  }

  async editGainsExpect() {

    this.setState({ loading: true });

    const highestID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);

    let promises = [];

    for (const key in this.state.gainsExpectNew) {

      const gain = this.state.gainsExpectNew[key];

      if (typeof gain.omzetv_id === 'undefined') { // is newly added gains

        if (isNaN(gain.omzetv_hoeveelheid) === true || Constants.isEmpty(gain.omzetv_hoeveelheid) === true) {
          continue;
        }

        promises.push(APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${gain.omzetv_datum}', ${gain.omzetv_hoeveelheid})`));

      } else { // is edited gains

        if (isNaN(gain.omzetv_hoeveelheid) === true || Constants.isEmpty(gain.omzetv_hoeveelheid) === true) {
          promises.push(APIDELETE.Request(`DELETE FROM OmzetVerwachting`, `WHERE omzetv_id = ${gain.omzetv_id}`));
          continue;
        }

        promises.push(APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = ${gain.omzetv_hoeveelheid}`, `WHERE omzetv_id = ${gain.omzetv_id}`));

      }

    }

    await Promise.all(promises);

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      const newHighestID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);

      if (parseInt(newHighestID[0].omzetv_id) > parseInt(highestID[0].omzetv_id)) {

        let promises2 = [];
        for (let i = parseInt(highestID[0].omzetv_id); i < parseInt(newHighestID[0].omzetv_id); i++) {
          promises2.push(APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${(i + 1)}, ${Data.data.chosenTeamObj.team_id})`));
        }

        await Promise.all(promises2);

      }

    }

    await this.reloadData();

    this.setState({loading: false});

  }

  render() {

    const wins = this.getTotalWinsYear();
    const wins2 = this.getTotalWinsMonth();

    return (
        <div className='finances'>

          <Navbar ref={this.navBar} history={this.props.history} active={'finances'} fixed loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'finances'} parent={this}/>

          {this.state.showAlert === true ?
                    
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null
                  
          }

          <div className='planner_nav2_fixed fixedNav2'>

            {this.state.menu === 'salaries' || this.state.menu === 'gains' || this.state.menu === 'expect' ?
              <div className='planner_nav2_sub1' onClick={() => this.goToYear(false)}>
                <i className='fa fa-chevron-left'></i>
              </div> 
            :
              <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
                
              </div> 
            }
            <div className='planner_nav2_sub2'>

            </div> 
            <div className='planner_nav2_sub3' style={{flex: 8}}>

              {this.state.menu === 'salaries' ?

                `Financiën - ${this.state.year}`

              :
              this.state.menu === 'expect' ?

                `Verwachte financiën - ${this.state.year}`

              :
              this.state.menu === 'gainsExpect' ?

                `Verwachte omzet - ${this.state.year}`

              :
              this.state.menu === 'salary' ?

                `Uurlonen aanpassen`

              :
              this.state.menu === 'gains' ?

                `Omzet - ${this.state.year}`

              :
              this.state.menu === 'extra' ?

                `Toeslagen`
            
              : null}
              
            </div> 
            <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>
      
            </div> 
            {this.state.menu === 'salaries' || this.state.menu === 'gains' || this.state.menu === 'expect' ?
              <div className='planner_nav2_sub5' onClick={() => this.goToYear(true)}>
                <i className='fa fa-chevron-right'></i>
              </div> 
            :
              <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
                
              </div> 
            }

          </div>

          <div className='finances_main'>
            {/* stickyTopMenuPanel */}
            
            <div className='finances_menu'>

              <div className='finances_menuTitle'>
                <div>Menu</div>
              </div>
              
              <div className={`finances_menuButton ${this.state.menu === 'salaries' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('salaries')}>
                <div>Loonkosten</div>
              </div>

              <div className={`finances_menuButton ${this.state.menu === 'gains' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('gains')}>
                <div>Omzet</div>
              </div>

              <div className={`finances_menuButton ${this.state.menu === 'expect' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('expect')}>
                <div>Verwachte loonkosten</div>
              </div>

              <div className={`finances_menuButton ${this.state.menu === 'gainsExpect' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('gainsExpect')}>
                <div>Verwachte omzet</div>
              </div>

              {/* <div className={`finances_menuButton ${this.state.menu === 'extra' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('extra')}>
                <div>Toeslagen</div>
              </div> */}

              {/* <div className={`finances_menuButton ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('absence')}>
                <div>Verlof</div>
              </div>

              <div className={`finances_menuButton ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('availability')}>
                <div>Beschikbaarheid</div>
              </div> */}

            </div>

            {this.state.menu === 'expect' ?

              <div className='finances_menu'>

                <div className='finances_menuTitle'>
                  <div>Overzicht {this.state.year}</div>
                </div>
                
                <div className={`finances_menuButton ${this.state.month === 12 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(12)}>
                  <div style={{fontWeight: 'bold'}}>Jaarlijks</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 0 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(0)}>
                  <div>Januari</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 1 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(1)}>
                  <div>Februari</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 2 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(2)}>
                  <div>Maart</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 3 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(3)}>
                  <div>April</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 4 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(4)}>
                  <div>Mei</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 5 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(5)}>
                  <div>Juni</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 6 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(6)}>
                  <div>Juli</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 7 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(7)}>
                  <div>Augustus</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 8 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(8)}>
                  <div>September</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 9 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(9)}>
                  <div>Oktober</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 10 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(10)}>
                  <div>November</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 11 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(11)}>
                  <div>December</div>
                </div>

              </div>

            :
            this.state.menu === 'salaries' ?
            
              <div className='finances_menu'>

                <div className='finances_menuTitle'>
                  <div>Overzicht {this.state.year}</div>
                </div>
                  
                <div className={`finances_menuButton ${this.state.month === 12 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(12)}>
                  <div style={{fontWeight: 'bold'}}>Jaarlijks</div>
                </div>

                {(0 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                  <div className={`finances_menuButton ${this.state.month === 0 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(0)}>
                    <div>Januari</div>
                  </div>
                : null}

                {(1 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 1 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(1)}>
                  <div>Februari</div>
                </div>
                : null}

                {(2 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 2 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(2)}>
                  <div>Maart</div>
                </div>
                : null}

                {(3 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 3 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(3)}>
                  <div>April</div>
                </div>
                : null}

                {(4 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 4 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(4)}>
                  <div>Mei</div>
                </div>
                : null}

                {(5 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 5 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(5)}>
                  <div>Juni</div>
                </div>
                : null}

                {(6 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 6 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(6)}>
                  <div>Juli</div>
                </div>
                : null}

                {(7 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 7 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(7)}>
                  <div>Augustus</div>
                </div>
                : null}

                {(8 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 8 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(8)}>
                  <div>September</div>
                </div>
                : null}

                {(9 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 9 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(9)}>
                  <div>Oktober</div>
                </div>
                : null}

                {(10 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 10 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(10)}>
                  <div>November</div>
                </div>
                : null}

                {(11 < this.state.now.getMonth() && this.state.year === this.state.now.getFullYear()) || this.state.year < this.state.now.getFullYear() ?
                <div className={`finances_menuButton ${this.state.month === 11 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(11)}>
                  <div>December</div>
                </div>
                : null}

              </div>

            :
            this.state.menu === 'gains' || this.state.menu === 'gainsExpect' ?
            
              <div className='finances_menu'>

                <div className='finances_menuTitle'>
                  <div>Overzicht {this.state.year}</div>
                </div>
                  
                <div className={`finances_menuButton ${this.state.month === 0 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(0)}>
                  <div>Januari</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 1 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(1)}>
                  <div>Februari</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 2 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(2)}>
                  <div>Maart</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 3 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(3)}>
                  <div>April</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 4 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(4)}>
                  <div>Mei</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 5 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(5)}>
                  <div>Juni</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 6 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(6)}>
                  <div>Juli</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 7 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(7)}>
                  <div>Augustus</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 8 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(8)}>
                  <div>September</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 9 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(9)}>
                  <div>Oktober</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 10 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(10)}>
                  <div>November</div>
                </div>

                <div className={`finances_menuButton ${this.state.month === 11 ? 'activeAv' : ''}`} onClick={() => this.selectMonth(11)}>
                  <div>December</div>
                </div>

              </div>
            
            : null}

            {this.state.menu === 'extra' ?

              <div className='finances_menu'>

                <div className='finances_menuTitle'>
                  <div>Toeslagen</div>
                </div>
                
                <div className={`finances_menuButton ${this.state.extraMenu === 'add' ? 'activeAv' : ''}`} onClick={() => this.selectExtraMenu('add')}>
                  <div style={{fontWeight: 'bold'}}>Toevoegen</div>
                  <i className={'far fa-money-check-edit-alt'} style={{fontSize: '1vw', color: '#282828'}}/>
                </div>

              </div>

            : null}

            <div id='financesContentScroll' className='finances_content' style={this.state.menu === 'salaries' || this.state.menu === 'expect' || this.state.menu === 'gainsExpect' || this.state.menu === 'gains' ? {width: '70%'} : null}>

              {this.state.loading === true ?
              
                          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                <div className='finances_container'>

                  {this.state.reloading === true ?
                            
                    <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                  :

                    null

                  }

                  {this.state.menu === 'salaries' ?

                    this.state.month === 12 ?

                      <div>

                        <div className='finances_costs_container'>

                          <span className='finances_costs_title'>Jaaroverzicht {this.state.year}</span>

                          <span className='finances_costs_sub'><i>Totale omzet: {this.getTotalGainsYear()}</i></span>

                          <span className='finances_costs_sub'><i>Totale bruto loonkosten: {this.getTotalSalaryYear()}</i></span>

                          {/* <span className='finances_costs_sub'><i>Totale {wins.includes('-') === true ? 'verlies' : 'winst'}: {wins}</i></span> */}

                        </div>

                        <Chart
                          width={'100%'}
                          height={500}
                          chartLanguage={Constants.getLang()}
                          chartType="AreaChart"
                          data={[
                            ['Periode', 'Omzet', 'Loonkosten'],
                            ...this.state.yearlyData
                          ]}
                          options={{
                            backgroundColor: 'transparent',
                            hAxis: { title: 'Periode', titleTextStyle: { color: '#333' } },
                            vAxis: { title: 'Euro €', },
                            chartArea: { width: '65%', height: '80%' },
                            // lineWidth: 25
                          }}
                        />

                        <div className={'hoursRegistration_barContainerMain stickyTopHoursRegistration2'} style={{marginTop: '2vw'}}>
                          <div className={'myHours_barRowMain'} style={{flex: 2}}>
                            <div>Naam</div>
                          </div>
                          <div className={'myHours_barRowMain'}>
                            <div>Uren</div>
                          </div>
                          <div className={'myHours_barRowMain'}>
                            <div>Pauze</div>
                          </div>
                          <div className={'myHours_barRowMain'}>
                            <div>Bruto loon</div>
                          </div>
                        </div>

                        <div className='finances_yearlyData'>

                          {this.accountBars()}

                        </div>

                      </div>

                    :

                    <div>

                      <div className='finances_costs_container'>

                        <span className='finances_costs_title'>Maandoverzicht {Constants.getMonthNameM(this.state.month)} {this.state.year}</span>

                        <span className='finances_costs_sub'><i>Omzet: {this.getTotalGainsMonth()}</i></span>

                        <span className='finances_costs_sub'><i>Bruto loonkosten: {this.getTotalSalaryMonth()}</i></span>

                        {/* <span className='finances_costs_sub'><i>{wins2.includes('-') === true ? 'Verlies' : 'Winst'}: {wins2}</i></span> */}

                      </div>

                      <div className={'hoursRegistration_barContainerMain stickyTopHoursRegistration2'} style={{marginTop: '2vw'}}>
                        <div className={'myHours_barRowMain'} style={{flex: 2}}>
                          <div>Naam</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Uren</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Pauze</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Bruto loon</div>
                        </div>
                      </div>

                      <div className='finances_yearlyData'>

                        {this.accountBarsMonth()}

                      </div>

                    </div>

                  :

                  this.state.menu === 'expect' ?

                    this.state.month === 12 ?

                      <div>

                        <div className='finances_costs_container'>

                          <span className='finances_costs_title'>Jaaroverzicht {this.state.year}</span>

                          <span className='finances_costs_sub'><i>Totale omzet: {this.getTotalGainsYearExpect()}</i></span>

                          <span className='finances_costs_sub'><i>Totale bruto loonkosten: {this.getTotalSalaryYear()}</i></span>

                          {/* <span className='finances_costs_sub'><i>Totale {wins.includes('-') === true ? 'verlies' : 'winst'}: {wins}</i></span> */}

                        </div>

                        <Chart
                          width={'100%'}
                          height={500}
                          chartLanguage={Constants.getLang()}
                          chartType="AreaChart"
                          data={[
                            ['Periode', 'Omzet', 'Loonkosten'],
                            ...this.state.yearlyData
                          ]}
                          options={{
                            backgroundColor: 'transparent',
                            hAxis: { title: 'Periode', titleTextStyle: { color: '#333' } },
                            vAxis: { title: 'Euro €', },
                            chartArea: { width: '65%', height: '80%' },
                            // lineWidth: 25
                          }}
                        />

                        <div className={'hoursRegistration_barContainerMain stickyTopHoursRegistration2'} style={{marginTop: '2vw'}}>
                          <div className={'myHours_barRowMain'} style={{flex: 2}}>
                            <div>Naam</div>
                          </div>
                          <div className={'myHours_barRowMain'}>
                            <div>Uren</div>
                          </div>
                          <div className={'myHours_barRowMain'}>
                            <div>Pauze</div>
                          </div>
                          <div className={'myHours_barRowMain'}>
                            <div>Bruto loon</div>
                          </div>
                        </div>

                        <div className='finances_yearlyData'>

                          {this.accountBars()}

                        </div>

                      </div>

                    :

                    <div>

                      <div className='finances_costs_container'>

                        <span className='finances_costs_title'>Maandoverzicht {Constants.getMonthNameM(this.state.month)} {this.state.year}</span>

                        <span className='finances_costs_sub'><i>Omzet: {this.getTotalGainsMonth()}</i></span>

                        <span className='finances_costs_sub'><i>Bruto loonkosten: {this.getTotalSalaryMonth()}</i></span>

                        {/* <span className='finances_costs_sub'><i>{wins2.includes('-') === true ? 'Verlies' : 'Winst'}: {wins2}</i></span> */}

                      </div>

                      <div className={'hoursRegistration_barContainerMain stickyTopHoursRegistration2'} style={{marginTop: '2vw'}}>
                        <div className={'myHours_barRowMain'} style={{flex: 2}}>
                          <div>Naam</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Uren</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Pauze</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Bruto loon</div>
                        </div>
                      </div>

                      <div className='finances_yearlyData'>

                        {this.accountBarsMonth()}

                      </div>

                    </div>

                  : 

                  this.state.menu === 'salary' ?

                    <div>

                      <div className={'employees_content_header stickyTopMenuContent2'} style={{backgroundColor: Colors.color.backgroundColor}}>

                        <div className='employees_content_header_row'>

                            <div className='employees_content_header_1'>
                            
                              {null}

                            </div>

                            <div className='employees_content_header_2'>

                                <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveSalaryAlert()}>Opslaan</span>

                            </div>

                        </div>

                        {/* {this.showError()} */}

                      </div>

                      <div className={'hoursRegistration_barContainerMain stickyTopHoursRegistration3'}>
                        <div className={'myHours_barRowMain'} style={{flex: 3}}>
                          <div>Naam</div>
                        </div>
                        <div className={'myHours_barRowMain'}>
                          <div>Bruto uurloon</div>
                        </div>
                      </div>

                      {this.accountBarsSalary()}

                    </div>

                  :

                  this.state.menu === 'gainsExpect' ? 

                    <div>

                      <div className={'employees_content_header'} style={{backgroundColor: Colors.color.backgroundColor}}>

                        <div className='employees_content_header_row'>

                            <div className='employees_content_header_1'>
                            
                              {null}

                            </div>

                            <div className='employees_content_header_2'>

                              <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveGainsExpectAlert()}>Opslaan</span>

                            </div>

                        </div>

                        {/* {this.showError()} */}

                      </div>

                      <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Constants.firstUppercase(Constants.getMonthNameM(this.state.month))}</span>

                      <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginTop: '0.5vw'}}>{this.getTotalGainsExpectMonth()}</div>

                      {this.GainsExpectBars()}

                    </div>

                  :

                  this.state.menu === 'gains' ? 

                    <div>

                      <div className={'employees_content_header'} style={{backgroundColor: Colors.color.backgroundColor}}>

                        <div className='employees_content_header_row'>

                            <div className='employees_content_header_1'>
                              
                              {null}

                            </div>

                            <div className='employees_content_header_2'>

                              <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveGainsAlert()}>Opslaan</span>

                            </div>

                        </div>

                        {/* {this.showError()} */}

                      </div>

                      <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{Constants.firstUppercase(Constants.getMonthNameM(this.state.month))}</span>

                      <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginTop: '0.5vw'}}>{this.getTotalGainsMonth()}</div>

                      {this.GainsBars()}

                    </div>

                  :

                  this.state.menu === 'extra' ? 

                    <div>

                      Binnenkort

                    </div>

                  :

                    null

                  }
                  
                </div>
            
              }

            </div>

          </div>

        </div>
    );
  }
  
}

export default Finances;