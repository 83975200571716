/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../planner/planner.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../components/startupModal/startupModal.scss';
import '../../pages/planner/planner.scss';
import '../../components/smartPlanModal/smartPlanModal.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../../components/alert/alert';
import APIGetAllUnpublished from '../../classes/APIGetAllUnpublished';
import KodictNotifications from '../../constants/KodictNotifications';
import Logger from '../../constants/Logger';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGetSections from '../../classes/APIGetSections';
import APIGET from '../../classes/global/APIGET';
import APIEDIT from '../../classes/global/APIEDIT';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import planClass from './plan.class';
import APIADD from '../../classes/global/APIADD';
import APIAddSkillSchedule from '../../classes/APIAddSkillSchedule';
import APIAddPartSchedule from '../../classes/APIAddPartSchedule';
import Colors from '../../constants/Colors';

class PublishModalTryout extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    this.allPlannerData = {};
    this.unpublishedRepeatingShifts = {};

    this.state = {

        loading: true,
        reloading: false,

        accounts: [],

        unpublished: [],
        total: 0,

        publishedWeeks: [],

        mainDepublished: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        display: 'PUBLISH',
        detailsWeek: 0,
        detailsYear: 0,

        functions: [],
        sections: [],

        selectedFunction: null,
        selectedSection: null,

        selectedDays: [],

        unpublishedRepeatsThisWeek: [],

        week: 1,
        year: 2000,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

    let selectedDays = this.state.selectedDays;

    if (Constants.isTrue(localStorage.getItem('@publishMonday')) || Constants.isEmpty(localStorage.getItem('@publishMonday'))) {
        selectedDays.push({ name: 'Maandag', id: 1 });
    }
    if (Constants.isTrue(localStorage.getItem('@publishTuesday')) || Constants.isEmpty(localStorage.getItem('@publishTuesday'))) {
        selectedDays.push({ name: 'Dinsdag', id: 2 });
    }
    if (Constants.isTrue(localStorage.getItem('@publishWednesday')) || Constants.isEmpty(localStorage.getItem('@publishWednesday'))) {
        selectedDays.push({ name: 'Woensdag', id: 3 });
    }
    if (Constants.isTrue(localStorage.getItem('@publishThursday')) || Constants.isEmpty(localStorage.getItem('@publishThursday'))) {
        selectedDays.push({ name: 'Donderdag', id: 4 });
    }
    if (Constants.isTrue(localStorage.getItem('@publishFriday')) || Constants.isEmpty(localStorage.getItem('@publishFriday'))) {
        selectedDays.push({ name: 'Vrijdag', id: 5 });
    }
    if (Constants.isTrue(localStorage.getItem('@publishSaturday')) || Constants.isEmpty(localStorage.getItem('@publishSaturday'))) {
        selectedDays.push({ name: 'Zaterdag', id: 6 });
    }
    if (Constants.isTrue(localStorage.getItem('@publishSunday')) || Constants.isEmpty(localStorage.getItem('@publishSunday'))) {
        selectedDays.push({ name: 'Zondag', id: 0 });
    }

    const functions = await APIGetFunctions.Request();
    const sections = await APIGetSections.Request();

    let accounts = await APIGetAllAccounts.Request();

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.teamUsers = await APIGetTeamUsers.Request();
        accounts = Constants.getTeamAccounts(this.teamUsers, accounts);
    }

    await this.getUnpublishedPlanner();

    // REPEATING
    let unpublishedRepeatsWeek = [];
    for (const userKey in this.props._repeatingShifts) {
        for (const repeatShift of this.props._repeatingShifts[userKey]) {
            const _unpublished = await this.getUnpublishedRepeatShiftsWeek(repeatShift, this.props._week, this.props._year);
            for (const u of _unpublished) {
                unpublishedRepeatsWeek.push(u);
            }
        }
    }

    this.setState({ functions: functions, sections: sections, selectedDays: selectedDays, accounts: accounts, unpublishedRepeatsThisWeek: unpublishedRepeatsWeek, loading: false});


  }

  async getUnpublishedPlanner() {

    let unpublished = [];

    const _localScheduleData = JSON.parse(localStorage.getItem('@Rooster'));

    for (const _localPlan of _localScheduleData) {
        if (_localPlan.rooster_publiceren === '0') {
            unpublished.push(_localPlan);
        }
    }

    let newUnpublished = {};
    let count = 0;

    for (const plan of unpublished) {

        if (Constants.isEmpty(this.state.selectedFunction) === false && this.state.selectedFunction !== `f${plan.rooster_functie_id}`) {
            continue;
        } else if (Constants.isEmpty(this.state.selectedSection) === false && this.state.selectedSection !== `a${plan.functie_afdeling_id}`) {
            continue;
        }

        const week = this.getWeekNumber2(`${plan.rooster_datum}T${plan.rooster_begin}`);
        const start = Constants.timeStringToFloat(plan.rooster_begin);
        const end = Constants.timeStringToFloat(plan.rooster_eind);
        const _break = Constants.timeStringToFloat(plan.rooster_pauze);

        if(typeof newUnpublished[week] === 'undefined') {
            
            newUnpublished[week] = [{id: plan.rooster_id, date: plan.rooster_datum, hours: isNaN(Constants.round(end - start - _break)) ? 0 : Constants.round(end - start - _break), data: plan}];

        } else {

            newUnpublished[week].push({id: plan.rooster_id, date: plan.rooster_datum, hours: isNaN(Constants.round(end - start - _break)) ? 0 : Constants.round(end - start - _break), data: plan});

        }

        count++;

    }

    this.setState({
        unpublished: newUnpublished,
        total: count,
    });

  }

  async getUnpublishedPlanner2() {

    let unpublished = [];

    const _localScheduleData = JSON.parse(localStorage.getItem('@Rooster'));

    for (const _localPlan of _localScheduleData) {
        if (_localPlan.rooster_publiceren === '0') {
            unpublished.push(_localPlan);
        }
    }

    let count = 0;
    for (const plan of unpublished) {
        if (Constants.isEmpty(this.state.selectedFunction) === false && this.state.selectedFunction !== `f${plan.rooster_functie_id}`) {
            continue;
        } else if (Constants.isEmpty(this.state.selectedSection) === false && this.state.selectedSection !== `a${plan.functie_afdeling_id}`) {
            continue;
        }
        count++;
    }

    this.setState({
        total: count,
    });

  }

  async reloadWeek(_week, _year) {

    let unpublished = [];

    const _localScheduleData = JSON.parse(localStorage.getItem('@Rooster'));

    for (const _localPlan of _localScheduleData) {
        if (_localPlan.rooster_publiceren === '0') {
            unpublished.push(_localPlan);
        }
    }

    let newUnpublished = this.state.unpublished;

    let reloaded = false;

    let count = 0;

    for (const plan of unpublished) {

        if (Constants.isEmpty(this.state.selectedFunction) === false && this.state.selectedFunction !== `f${plan.rooster_functie_id}`) {
            continue;
        } else if (Constants.isEmpty(this.state.selectedSection) === false && this.state.selectedSection !== `a${plan.functie_afdeling_id}`) {
            continue;
        }

        const week = this.getWeekNumber2(`${plan.rooster_datum}T${plan.rooster_begin}`);
        const start = Constants.timeStringToFloat(plan.rooster_begin);
        const end = Constants.timeStringToFloat(plan.rooster_eind);
        const _break = Constants.timeStringToFloat(plan.rooster_pauze);

        if (week === _week) {

            if(typeof newUnpublished[week] === 'undefined') {
            
                newUnpublished[week] = [{id: plan.rooster_id, date: plan.rooster_datum, hours: isNaN(Constants.round(end - start - _break)) ? 0 : Constants.round(end - start - _break), data: plan}];

                reloaded = true;
    
            } else {

                if (reloaded === false) {
                    newUnpublished[week] = [];
                    reloaded = true;
                }
    
                newUnpublished[week].push({id: plan.rooster_id, date: plan.rooster_datum, hours: isNaN(Constants.round(end - start - _break)) ? 0 : Constants.round(end - start - _break), data: plan});
    
            }

        }

        count++;

    }

    // REPEATING
    let unpublishedRepeatsWeek = [];
    for (const userKey in this.props._repeatingShifts) {
        for (const repeatShift of this.props._repeatingShifts[userKey]) {
            const _unpublished = await this.getUnpublishedRepeatShiftsWeek(repeatShift, this.props._week, this.props._year);
            for (const u of _unpublished) {
                unpublishedRepeatsWeek.push(u);
            }
        }
    }

    this.setState({
        loading: false,
        reloading: false,
        unpublished: newUnpublished,
        total: count,
        unpublishedRepeatsThisWeek: unpublishedRepeatsWeek,
    });

  }

  getWeekNumber2(today) {
    let date = new Date(today);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getWeekYear2(today) {
    let date = new Date(today);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
  }

  dateInWeek(date, w, y) {
      const dateObj = new Date(date);

      const monday = this.getMonday(w, y);
      const tuesday = this.getTuesday(w, y);
      const wednesday = this.getWednesday(w, y);
      const thursday = this.getThursday(w, y);
      const friday = this.getFriday(w, y);
      const saturday = this.getSaturday(w, y);
      const sunday = this.getSunday(w, y);

      if ((dateObj.getDate() === monday.getDate() && dateObj.getMonth() === monday.getMonth() && dateObj.getFullYear() === monday.getFullYear())
        || (dateObj.getDate() === tuesday.getDate() && dateObj.getMonth() === tuesday.getMonth() && dateObj.getFullYear() === tuesday.getFullYear())
        || (dateObj.getDate() === wednesday.getDate() && dateObj.getMonth() === wednesday.getMonth() && dateObj.getFullYear() === wednesday.getFullYear())
        || (dateObj.getDate() === thursday.getDate() && dateObj.getMonth() === thursday.getMonth() && dateObj.getFullYear() === thursday.getFullYear())
        || (dateObj.getDate() === friday.getDate() && dateObj.getMonth() === friday.getMonth() && dateObj.getFullYear() === friday.getFullYear())
        || (dateObj.getDate() === saturday.getDate() && dateObj.getMonth() === saturday.getMonth() && dateObj.getFullYear() === saturday.getFullYear())
        || (dateObj.getDate() === sunday.getDate() && dateObj.getMonth() === sunday.getMonth() && dateObj.getFullYear() === sunday.getFullYear())) {
            return true;
    }

    return false;
  }

  getMonday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
  }

  getTuesday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 1);
    return ISOweekStart;
  }

  getWednesday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 2);
    return ISOweekStart;
  }

  getThursday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 3);
    return ISOweekStart;
  }

  getFriday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 4);
    return ISOweekStart;
  }

  getSaturday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 5);
    return ISOweekStart;
  }

  getSunday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 6);
    return ISOweekStart;
  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  getWeekBars() {

    const toRender = [];
    let count = 0;

    for (const week in this.state.unpublished) {

        const plan = this.state.unpublished[week];

        let year = 0;
        
        for (const p of plan) {
            const date = Constants.stringToDate(p.date)

            year = date.getFullYear();
            
            if (date.getDay() !== 1) {
                const days = date.getDay() === 0 ? 6 : date.getDay() - 1;

                date.setDate(date.getDate() - days);

                year = date.getFullYear();
            }
        }

        toRender.push(

            Constants.arrayContains(this.state.publishedWeeks, parseInt(week) + year) === true ?

                <div className='planner_publish_row' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

                    <div className='planner_publish_row_sub1'>
                        <span className='defaultLinkO' onClick={() => this.props._goTo(parseInt(week), year)}><b>Week {week}</b></span>
                    </div>

                    <div className='planner_publish_row_sub2'>
                        <span>Gepubliceerd</span>
                    </div>
                    
                    <div className='planner_publish_row_sub3'>
                        <span style={{marginRight: 0}} className='modal-deleteButton' onClick={this.dePublishPlanner.bind(this, parseInt(week), year)}>Depubliceren</span>
                    </div>

                </div>

            :

                <div className='planner_publish_row' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

                    <div className='planner_publish_row_sub1'>
                        <span className='defaultLinkO' onClick={() => this.props._goTo(parseInt(week), year)}><b>Week {week}</b></span>
                    </div>

                    <div className='planner_publish_row_sub2'>
                        <span><b>{plan.length}</b> {plan.length > 1 ? 'wijzigingen' : 'wijziging'}</span>
                    </div>
                
                    <div className='planner_publish_row_sub3'>
                        <span className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.switchToHours(week, year)}>Urenoverzicht</span>
                        <span className='modal-submitButton2' onClick={this.publishWeek.bind(this, parseInt(week), year)}>Publiceren</span>
                    </div>

                </div>

        );

        if (count === 0) {
            count++;
          } else {
            count--;
          }
        
    }

    return toRender;

  }

  getWeekBarsRepeatingShifts() {

    const toRender = [];
    let count = 0;

    for (const week in this.state.unpublished) {

        const plan = this.state.unpublished[week];

        let year = 0;
        
        for (const p of plan) {
            const date = Constants.stringToDate(p.date)

            year = date.getFullYear();
            
            if (date.getDay() !== 1) {
                const days = date.getDay() === 0 ? 6 : date.getDay() - 1;

                date.setDate(date.getDate() - days);

                year = date.getFullYear();
            }
        }

        toRender.push(

            Constants.arrayContains(this.state.publishedWeeks, parseInt(week) + year) === true ?

                <div className='planner_publish_row' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

                    <div className='planner_publish_row_sub1'>
                        <span className='defaultLinkO' onClick={() => this.props._goTo(parseInt(week), year)}><b>Week {week}</b></span>
                    </div>

                    <div className='planner_publish_row_sub2'>
                        <span>Gepubliceerd</span>
                    </div>
                    
                    <div className='planner_publish_row_sub3'>
                        <span style={{marginRight: 0}} className='modal-deleteButton' onClick={this.dePublishPlanner.bind(this, parseInt(week), year)}>Depubliceren</span>
                    </div>

                </div>

            :

                <div className='planner_publish_row' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

                    <div className='planner_publish_row_sub1'>
                        <span className='defaultLinkO' onClick={() => this.props._goTo(parseInt(week), year)}><b>Week {week}</b></span>
                    </div>

                    <div className='planner_publish_row_sub2'>
                        <span><b>{plan.length}</b> {plan.length > 1 ? 'wijzigingen' : 'wijziging'}</span>
                    </div>
                
                    <div className='planner_publish_row_sub3'>
                        <span className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.setState({display: 'HOURS', detailsWeek: week, detailsYear: year})}>Urenoverzicht</span>
                        <span className='modal-submitButton2' onClick={this.publishWeek.bind(this, parseInt(week), year)}>Publiceren</span>
                    </div>

                </div>

        );

        if (count === 0) {
            count++;
          } else {
            count--;
          }
        
    }

    return toRender;

  }

  dePublishPlanner(week, year) {

    if(this.state.loading === true || this.state.reloading === true) {
    return;
    }

    this.setState({
        alertTitle: 'Planner publiceren',
        alertBody: (
        <div>

            Weet je zeker dat je week <b>{week}</b> wilt depubliceren?<br /><br />
            De planning zal onzichtbaar worden voor medewerkers.

        </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Depubliceren'],
        alertButtonAction: this.dePublishWeek.bind(this, week, year),
        showAlert: true,
    });

}

async dePublishWeek(week, year) {

    this.setState({reloading: true});

    const monday = Constants.getMonday(week, year);
    const tuesday = Constants.getTuesday(week, year);
    const wednesday = Constants.getWednesday(week, year);
    const thursday = Constants.getThursday(week, year);
    const friday = Constants.getFriday(week, year);
    const saturday = Constants.getSaturday(week, year);
    const sunday = Constants.getSunday(week, year);

    let published = false;

    let _localScheduleData = JSON.parse(localStorage.getItem('@Rooster'));

    // selected all days
    if (this.state.selectedDays.length === 7) {

        let where = ``;
        if (Data.data.chosenTeam !== null && Data.data.teams.length > 0) {
            where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
        }

        if (this.state.selectedFunction !== null) {
            where += ` AND rooster_functie_id = ${this.state.selectedFunction.substr(1)}`;
        } else if (this.state.selectedSection !== null) {
            where += ` AND functie_afdeling_id = ${this.state.selectedSection.substr(1)}`;
        }

        for (let _localPlan of _localScheduleData) {
            if (_localPlan.rooster_publiceren === '1') {
                if (this.state.selectedFunction !== null) {
                    if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                        _localPlan.rooster_publiceren = '0';
                    }
                } else if (this.state.selectedSection !== null) {
                    if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                        _localPlan.rooster_publiceren = '0';
                    }
                } else {
                    _localPlan.rooster_publiceren = '0';
                }
            }
        }

        localStorage.setItem('@Rooster', JSON.stringify(_localScheduleData));

        published = true;

        //published = await APIDePublishPlannerDates.Request(Constants.dateToString(monday), Constants.dateToString(sunday), Data.data.chosenTeam);
            
    // selected few days
    } else {

        let where = ``;
        if (Data.data.chosenTeam !== null && Data.data.teams.length > 0) {
            where = ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
        }

        if (this.state.selectedFunction !== null) {
            where += ` AND rooster_functie_id = ${this.state.selectedFunction.substr(1)}`;
        } else if (this.state.selectedSection !== null) {
            where += ` AND functie_afdeling_id = ${this.state.selectedSection.substr(1)}`;
        }

        let promises = [];

        for (const day of this.state.selectedDays) {

            if (day.id === 0) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(sunday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            } else if (day.id === 1) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(monday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            } else if (day.id === 2) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(tuesday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            } else if (day.id === 3) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(wednesday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            } else if (day.id === 4) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(thursday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            } else if (day.id === 5) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(friday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            } else if (day.id === 6) {

                for (let _localPlan of _localScheduleData) {
                    if (_localPlan.rooster_publiceren === '1' && Constants.dateToString(saturday) === _localPlan.rooster_datum) {
                        if (this.state.selectedFunction !== null) {
                            if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else if (this.state.selectedSection !== null) {
                            if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                _localPlan.rooster_publiceren = '0';
                            }
                        } else {
                            _localPlan.rooster_publiceren = '0';
                        }
                    }
                }

            }

            localStorage.setItem('@Rooster', JSON.stringify(_localScheduleData));

        }

        await Promise.all(promises);

        published = true;

    }

    if(published === true) {

        Logger.unpublished(`week ${week}, ${year}`);

        // we dont need reload the whole screen if it isnt the current selected week
        if (week === this.props._week && year === this.props._year) {
            await this.props._reloadScreen();
        }

        const publishedWeeks = this.state.publishedWeeks;

        if(Constants.arrayContains(publishedWeeks, week + year) === true) {
            Constants.removeFromArray(publishedWeeks, week + year);
            this.setState({ publishedWeeks: publishedWeeks });
        }

        if (week + year === this.props._week + this.props._year) {
            this.setState({ mainDepublished: true });
        }

        await this.getUnpublishedPlanner2();
        await this.reloadWeek(week, year);

    }

    this.setState({reloading: false});

}

    publishPlanner() {

        if(this.state.loading === true || this.state.reloading === true) {
            return;
        }

        if(this.state.total > 0) {

            this.setState({
                alertTitle: 'Planner publiceren',
                alertBody: (
                <div>

                    Weet je zeker dat je de hele planning wilt publiceren?<br /><br />
                    De planning zal voor alle medewerkers zichtbaar worden

                </div>
                ),
                alertButtons: 2,
                alertButtonText: ['Annuleren', 'Publiceren'],
                alertButtonAction: this.publish.bind(this),
                showAlert: true,
            });

        }

    }

    async publish() {

        this.setState({reloading: true});

        const monday = Constants.getMonday(this.props._week, this.props._year);
        const tuesday = Constants.getTuesday(this.props._week, this.props._year);
        const wednesday = Constants.getWednesday(this.props._week, this.props._year);
        const thursday = Constants.getThursday(this.props._week, this.props._year);
        const friday = Constants.getFriday(this.props._week, this.props._year);
        const saturday = Constants.getSaturday(this.props._week, this.props._year);
        const sunday = Constants.getSunday(this.props._week, this.props._year);

        let peopleToNotify = [];
        let published = false;

        let unpublished = [];

        let _localScheduleData = JSON.parse(localStorage.getItem('@Rooster'));

        for (const _localPlan of _localScheduleData) {
            if (_localPlan.rooster_publiceren === '0') {
                unpublished.push(_localPlan);
            }
        }

        // selected all days
        if (this.state.selectedDays.length === 7) {

            if(unpublished !== null) {
                for(const user of unpublished) {
                    if(Constants.arrayContains(peopleToNotify, user.rooster_info_id) === false && this.selectedFunctionPublish(user)) {
                        peopleToNotify.push(user.rooster_info_id);
                    }
                }
            }

            let where = ``;
            if (Data.data.chosenTeam !== null && Data.data.teams.length > 0) {
                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
            }

            if (this.state.selectedFunction !== null) {
                where += ` AND rooster_functie_id = ${this.state.selectedFunction.substr(1)}`;
            } else if (this.state.selectedSection !== null) {
                where += ` AND functie_afdeling_id = ${this.state.selectedSection.substr(1)}`;
            }

            for (let _localPlan of _localScheduleData) {
                if (_localPlan.rooster_publiceren === '0') {
                    if (this.state.selectedFunction !== null) {
                        if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                            _localPlan.rooster_publiceren = '1';
                        }
                    } else if (this.state.selectedSection !== null) {
                        if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                            _localPlan.rooster_publiceren = '1';
                        }
                    } else {
                        _localPlan.rooster_publiceren = '1';
                    }
                }
            }

            localStorage.setItem('@Rooster', JSON.stringify(_localScheduleData));

            published = true;

            // published = await APIEDIT.Request(`UPDATE Rooster LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN Functie ON rooster_functie_id = functie_id`, `SET rooster_publiceren = 1`, `WHERE rooster_publiceren = 0` + where);

        } else {

            if(unpublished !== null) {
                for(const user of unpublished) {
                    if(Constants.arrayContains(peopleToNotify, user.rooster_info_id) === false && this.inSelectedDays(user) && this.selectedFunctionPublish(user)) {
                        peopleToNotify.push(user.rooster_info_id);
                    }
                }
            }

            let where = ``;
            if (Data.data.chosenTeam !== null && Data.data.teams.length > 0) {
                where = ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
            }

            if (this.state.selectedFunction !== null) {
                where += ` AND rooster_functie_id = ${this.state.selectedFunction.substr(1)}`;
            } else if (this.state.selectedSection !== null) {
                where += ` AND functie_afdeling_id = ${this.state.selectedSection.substr(1)}`;
            }

            let promises = [];

            for (const day of this.state.selectedDays) {

                if (day.id === 0) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(sunday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 1) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(monday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 2) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(tuesday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 3) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(wednesday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 4) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(thursday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 5) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(friday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 6) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(saturday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                }

                localStorage.setItem('@Rooster', JSON.stringify(_localScheduleData));

            }

            await Promise.all(promises);

            published = true;

        }

        // REPEATING
        for (const week in this.state.unpublished) {
            let year = null;
            for (const plan of this.state.unpublished[week]) {
                const _date = Constants.stringToDate(plan.date);
                if (_date.getDay() === 1) {
                    year = _date.getFullYear();
                    break;
                } else {
                    year = _date.getFullYear();
                }
            }
            if (year !== null) {
                for (const userKey in this.props._repeatingShifts) {
                    for (const repeatShift of this.props._repeatingShifts[userKey]) {
                        await this.canPlanRepeatShift(repeatShift, parseInt(week), year);
                    }
                }
            }
        }

        // REPEATING
        for (const userKey in this.props._repeatingShifts) {
            for (const repeatShift of this.props._repeatingShifts[userKey]) {
                await this.canPlanRepeatShift(repeatShift, this.props._week, this.props._year);
            }
        }

        if(published === true) {

            await KodictNotifications.sendPushNotificationBulk(
                peopleToNotify,
                'planner',
                'Planner',
                'Jouw nieuwe rooster is geüpload. Tik hier om jouw roostermeldingen te updaten'
            );

            peopleToNotify = [];

            Logger.published('gehele planner');

            await this.props._reloadScreen();

            // has selected function, stay on screen
            if (this.state.selectedFunction !== null || this.state.selectedSection !== null) {

                await this.getUnpublishedPlanner();

            } else {

                this.props._closeModal();

            }


        } 
        
        this.setState({reloading: false});

    }

    async publishWeek(week, year) {

        this.setState({reloading: true});

        const monday = Constants.getMonday(week, year);
        const tuesday = Constants.getTuesday(week, year);
        const wednesday = Constants.getWednesday(week, year);
        const thursday = Constants.getThursday(week, year);
        const friday = Constants.getFriday(week, year);
        const saturday = Constants.getSaturday(week, year);
        const sunday = Constants.getSunday(week, year);

        let peopleToNotify = [];
        let published = false;

        let unpublished = [];

        let _localScheduleData = JSON.parse(localStorage.getItem('@Rooster'));

        for (const _localPlan of _localScheduleData) {
            if (_localPlan.rooster_publiceren === '0') {
                unpublished.push(_localPlan);
            }
        }

        // selected all days
        if (this.state.selectedDays.length === 7) {

            if(unpublished !== null) {
                for(const user of unpublished) {
                    if(Constants.arrayContains(peopleToNotify, user.rooster_info_id) === false && Constants.dateInWeek(user.rooster_datum, week, year) && this.selectedFunctionPublish(user)) {
                        peopleToNotify.push(user.rooster_info_id);
                    }
                }
            }

            //published = await APIPublishPlannerDates.Request(Constants.dateToString(monday), Constants.dateToString(sunday), Data.data.chosenTeam);

            let where = ``;
            if (Data.data.chosenTeam !== null && Data.data.teams.length > 0) {
                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
            }

            if (this.state.selectedFunction !== null) {
                where += ` AND rooster_functie_id = ${this.state.selectedFunction.substr(1)}`;
            } else if (this.state.selectedSection !== null) {
                where += ` AND functie_afdeling_id = ${this.state.selectedSection.substr(1)}`;
            }

            for (let _localPlan of _localScheduleData) {
                if (_localPlan.rooster_publiceren === '0') {
                    if (this.state.selectedFunction !== null) {
                        if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                            _localPlan.rooster_publiceren = '1';
                        }
                    } else if (this.state.selectedSection !== null) {
                        if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                            _localPlan.rooster_publiceren = '1';
                        }
                    } else {
                        _localPlan.rooster_publiceren = '1';
                    }
                }
            }
    
            localStorage.setItem('@Rooster', JSON.stringify(_localScheduleData));
    
            published = true;            

        // selected few days
        } else {

            if(unpublished !== null) {
                for(const user of unpublished) {
                    if(Constants.arrayContains(peopleToNotify, user.rooster_info_id) === false && Constants.dateInWeek(user.rooster_datum, week, year) && this.inSelectedDays(user) && this.selectedFunctionPublish(user)) {
                        peopleToNotify.push(user.rooster_info_id);
                    }
                }
            }

            let where = ``;
            if (Data.data.chosenTeam !== null && Data.data.teams.length > 0) {
                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
            }

            if (this.state.selectedFunction !== null) {
                where += ` AND rooster_functie_id = ${this.state.selectedFunction.substr(1)}`;
            } else if (this.state.selectedSection !== null) {
                where += ` AND functie_afdeling_id = ${this.state.selectedSection.substr(1)}`;
            }

            let promises = [];

            for (const day of this.state.selectedDays) {

                if (day.id === 0) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(sunday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 1) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(monday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 2) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(tuesday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 3) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(wednesday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 4) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(thursday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 5) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(friday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                } else if (day.id === 6) {

                    for (let _localPlan of _localScheduleData) {
                        if (_localPlan.rooster_publiceren === '0' && Constants.dateToString(saturday) === _localPlan.rooster_datum) {
                            if (this.state.selectedFunction !== null) {
                                if (_localPlan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else if (this.state.selectedSection !== null) {
                                if (_localPlan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                                    _localPlan.rooster_publiceren = '1';
                                }
                            } else {
                                _localPlan.rooster_publiceren = '1';
                            }
                        }
                    }

                }

                localStorage.setItem('@Rooster', JSON.stringify(_localScheduleData));

            }

            await Promise.all(promises);

            published = true;

        }

        // REPEATING
        for (const userKey in this.props._repeatingShifts) {
            for (const repeatShift of this.props._repeatingShifts[userKey]) {
                await this.canPlanRepeatShift(repeatShift, week, year);
            }
        }

        if(published === true) {

            await KodictNotifications.sendPushNotificationBulk(
                peopleToNotify,
                'planner',
                'Planner',
                'Jouw nieuwe rooster is geüpload. Tik hier om jouw roostermeldingen te updaten'
            );

            peopleToNotify = [];

            Logger.published(`week ${week}, ${year}`);

            // we dont need reload the whole screen if it isnt the current selected week
            if (week === this.props._week && year === this.props._year) {
                await this.props._reloadScreen();
            }

            let publishedWeeks = this.state.publishedWeeks;

            // do we need to show the depublish button?
            const unpublishedAmount = await APIGET.Request(`SELECT COUNT(rooster_id) AS rooster_aantal`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}' AND rooster_publiceren = 0`, null, null);
            if (unpublishedAmount.length > 0) {
                const _amount = parseInt(unpublishedAmount[0].rooster_aantal);
                if (isNaN(_amount) === true || _amount === 0) {
                    publishedWeeks.push(week + year);
                }
            }     
            
            await this.getUnpublishedPlanner2();
            await this.reloadWeek(week, year);

            this.setState({ 
                publishedWeeks: publishedWeeks,
                //total: this.state.total - this.state.unpublished[week].length,
            });

        }

        this.setState({reloading: false});

    }

    async publishWeekRepeatShift(week, year) {

        this.setState({reloading: true});

        // const monday = Constants.getMonday(week, year);
        // const tuesday = Constants.getTuesday(week, year);
        // const wednesday = Constants.getWednesday(week, year);
        // const thursday = Constants.getThursday(week, year);
        // const friday = Constants.getFriday(week, year);
        // const saturday = Constants.getSaturday(week, year);
        // const sunday = Constants.getSunday(week, year);

        let peopleToNotify = [];

        // REPEATING
        for (const userKey in this.props._repeatingShifts) {
            for (const repeatShift of this.props._repeatingShifts[userKey]) {
                await this.canPlanRepeatShift(repeatShift, week, year);
            }
        }

        // if(published === true) {

            // await KodictNotifications.sendPushNotificationBulk(
            //     peopleToNotify,
            //     'planner',
            //     'Planner',
            //     'Jouw nieuwe rooster is geüpload. Tik hier om jouw roostermeldingen te updaten'
            // );

            // peopleToNotify = [];

            // Logger.published(`week ${week}, ${year}`);

            // we dont need reload the whole screen if it isnt the current selected week
            if (week === this.props._week && year === this.props._year) {
                await this.props._reloadScreen();
            }

            // let publishedWeeks = this.state.publishedWeeks;

            // // do we need to show the depublish button?
            // const unpublishedAmount = await APIGET.Request(`SELECT COUNT(rooster_id) AS rooster_aantal`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}' AND rooster_publiceren = 0`, null, null);
            // if (unpublishedAmount.length > 0) {
            //     const _amount = parseInt(unpublishedAmount[0].rooster_aantal);
            //     if (isNaN(_amount) === true || _amount === 0) {
            //         publishedWeeks.push(week + year);
            //     }
            // }     
            
            // await this.getUnpublishedPlanner2();
            await this.reloadWeek(week, year);

            // this.setState({ 
            //     publishedWeeks: publishedWeeks,
            //     //total: this.state.total - this.state.unpublished[week].length,
            // });

        // }

        this.setState({reloading: false});

    }

    async canPlanRepeatShift(repeatShift, week, year) {

        if (Constants.isEmpty(repeatShift.hr_team_id) === false && Data.data.teams.length > 1 && Data.data.chosenTeam !== null && Data.data.chosenTeamObj.team_id !== repeatShift.hr_team_id) {
            return;
        }

        for (let i = 0; i < 7; i++) {

            if (Constants.objectArrayContainsKey(this.state.selectedDays, 'id', i) === false) {
                continue;
            }

            const monday = Constants.getMonday(week, year);
            const tuesday = Constants.getTuesday(week, year);
            const wednesday = Constants.getWednesday(week, year);
            const thursday = Constants.getThursday(week, year);
            const friday = Constants.getFriday(week, year);
            const saturday = Constants.getSaturday(week, year);
            const sunday = Constants.getSunday(week, year);

            // const array = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;

            let isWorkDay = true;
            if (Constants.isEmpty(this.props._infoExtra[repeatShift.hr_info_id]) === false) {
                isWorkDay = i === 1 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_maandag !== '0' : i === 2 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_dinsdag !== '0' : i === 3 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_woensdag !== '0' : i === 4 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_donderdag !== '0' : i === 5 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_vrijdag !== '0' : i === 6 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_zaterdag !== '0' : this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_zondag !== '0';
            }

            const date1 = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;
            const date2 = Constants.stringToDate(repeatShift.hr_startdatum);
            const date3 = Constants.isEmpty(repeatShift.hr_einddatum) === false ? Constants.stringToDate(repeatShift.hr_einddatum) : null;
            const interval = parseInt(repeatShift.hr_interval);
            const calc1 = repeatShift.hr_type === 'day' ? date1.getDate() : repeatShift.hr_type === 'week' ? Constants.getWeekNumber2(date1) : repeatShift.hr_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = repeatShift.hr_type === 'day' ? date2.getDate() : repeatShift.hr_type === 'week' ? Constants.getWeekNumber2(date2) : repeatShift.hr_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = repeatShift.hr_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : repeatShift.hr_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : repeatShift.hr_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
                    
            if (isInInterval && isWorkDay && date1 >= date2 && ((date3 !== null && date1 <= date3) || date3 === null)) {

                const existsInDB = await APIGET.Request(
                    `SELECT info_id`,
                    `FROM Info`,
                    `LEFT JOIN ContractN` +
                    ` ON contract_info_id = info_id`,
                    `WHERE (contract_eind IS NULL OR contract_eind >= '${Constants.dateToString(date1)}') AND info_id = ${repeatShift.hr_info_id}` +
                    ` AND NOT EXISTS (SELECT 1 FROM Rooster WHERE rooster_info_id = info_id AND '${Constants.dateToString(date1)}' = rooster_datum)` +
                    ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${Constants.dateToString(date1)}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
                    null,
                    null
                );

                if (existsInDB.length > 0) {

                    let _break = '00:00:00';
                    if (Constants.isEmpty(repeatShift.hr_pauze) === false) {
                    _break = `${repeatShift.hr_pauze.substr(0,5)}`;
                    }

                    const toPlanner = {
                        hr_team_id: repeatShift.hr_team_id,
                        rooster_datum: Constants.dateToString(date1),
                        rooster_begin: repeatShift.hr_begin.substr(0, 5),
                        rooster_eind: repeatShift.hr_eind.substr(0, 5),
                        rooster_pauze: _break,
                        functie_id: repeatShift.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
                        functie_naam: repeatShift.functie_naam,
                    }

                    const added = await this.props._planUser(Constants.dateToString(date1), { info_id: repeatShift.hr_info_id }, toPlanner);

                    if (Constants.isEmpty(this.props._skillsRepeatShifts[repeatShift.hr_id]) === false) {
                        for (const skill of this.props._skillsRepeatShifts[repeatShift.hr_id]) {
                            await APIAddSkillSchedule.Request(skill.comp_id, added);
                        }
                    }

                    if (Constants.isEmpty(this.props._partRepeatShifts[repeatShift.hr_id]) === false) {
                        await APIAddPartSchedule.Request(this.props._partRepeatShifts[repeatShift.hr_id].bijz_id, added);
                    }

                }

            }

        }

    }

    async getUnpublishedRepeatShiftsWeek(repeatShift, week, year) {

        let unpublished = [];

        for (let i = 0; i < 7; i++) {

            if (Constants.objectArrayContainsKey(this.state.selectedDays, 'id', i) === false) {
                continue;
            }

            const monday = Constants.getMonday(week, year);
            const tuesday = Constants.getTuesday(week, year);
            const wednesday = Constants.getWednesday(week, year);
            const thursday = Constants.getThursday(week, year);
            const friday = Constants.getFriday(week, year);
            const saturday = Constants.getSaturday(week, year);
            const sunday = Constants.getSunday(week, year);

            // const array = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;

            let isWorkDay = true;
            if (Constants.isEmpty(this.props._infoExtra[repeatShift.hr_info_id]) === false) {
                isWorkDay = i === 1 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_maandag !== '0' : i === 2 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_dinsdag !== '0' : i === 3 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_woensdag !== '0' : i === 4 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_donderdag !== '0' : i === 5 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_vrijdag !== '0' : i === 6 ? this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_zaterdag !== '0' : this.props._infoExtra[repeatShift.hr_info_id].info2_werkdag_zondag !== '0';
            }

            const date1 = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;
            const date2 = Constants.stringToDate(repeatShift.hr_startdatum);
            const date3 = Constants.isEmpty(repeatShift.hr_einddatum) === false ? Constants.stringToDate(repeatShift.hr_einddatum) : null;
            const interval = parseInt(repeatShift.hr_interval);
            const calc1 = repeatShift.hr_type === 'day' ? date1.getDate() : repeatShift.hr_type === 'week' ? Constants.getWeekNumber2(date1) : repeatShift.hr_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = repeatShift.hr_type === 'day' ? date2.getDate() : repeatShift.hr_type === 'week' ? Constants.getWeekNumber2(date2) : repeatShift.hr_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = repeatShift.hr_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : repeatShift.hr_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : repeatShift.hr_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
                    
            if (isInInterval && isWorkDay && date1 >= date2 && ((date3 !== null && date1 <= date3) || date3 === null)) {

                const existsInDB = await APIGET.Request(
                    `SELECT info_id`,
                    `FROM Info`,
                    `LEFT JOIN ContractN` +
                    ` ON contract_info_id = info_id`,
                    `WHERE (contract_eind IS NULL OR contract_eind >= '${Constants.dateToString(date1)}') AND info_id = ${repeatShift.hr_info_id}` +
                    ` AND NOT EXISTS (SELECT 1 FROM Rooster WHERE rooster_info_id = info_id AND '${Constants.dateToString(date1)}' = rooster_datum)` +
                    ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${Constants.dateToString(date1)}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
                    null,
                    null
                );

                if (existsInDB.length > 0) {

                    unpublished.push({
                        ...repeatShift,
                        date: date1,
                      });

                }

            }

        }

        return unpublished;

    }

    selectedFunctionPublish(plan) {

        if (this.state.selectedFunction === null && this.state.selectedSection === null) {
            return true;
        } 

        if (this.state.selectedFunction !== null) {
            if (plan.rooster_functie_id === this.state.selectedFunction.substr(1)) {
                return true;
            }
        } else if (this.state.selectedSection !== null) {
            if (plan.functie_afdeling_id === this.state.selectedSection.substr(1)) {
                return true;
            }
        }

        return false;

    }

    inSelectedDays(plan) {

        const planDay = Constants.stringToDate(plan.rooster_datum).getDay();

        let found = false;
        for (const day of this.state.selectedDays) {
            if (day.id === planDay) {
                found = true;
                break;
            }
        }

        return found;

    }

    async switchToHours(week, year) {

        await this.loadWeekHours(week, year);

        this.setState({display: 'HOURS', detailsWeek: week, detailsYear: year});

    }
    
    async loadWeekHours(week, year) {

        this.setState({ loading: true });

        const monday = Constants.getMonday(week, year);
        const sunday = Constants.getSunday(week, year);

        const plannerData = JSON.parse(localStorage.getItem('@Rooster'));
        const repeatingShifts = [];

        let unpublishedRepeatsWeek = [];
        for (const r of repeatingShifts) {
          if (Constants.isEmpty(r.hr_team_id) === false && Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (r.hr_team_id === Data.data.chosenTeamObj.team_id) {
              const _unpublishedRepeatsWeek = await this.getUnpublishedRepeatShiftsWeek(r, week, year);
              for (const u of _unpublishedRepeatsWeek) {
                unpublishedRepeatsWeek.push(u);
              }
            }
          } else {
            const _unpublishedRepeatsWeek = await this.getUnpublishedRepeatShiftsWeek(r, week, year);
            for (const u of _unpublishedRepeatsWeek) {
              unpublishedRepeatsWeek.push(u);
            }
          }
        }

        this.allPlannerData = Constants.convertToObjectWithArrays(plannerData, 'rooster_info_id');
        this.unpublishedRepeatingShifts = Constants.convertToObjectWithArrays(unpublishedRepeatsWeek, 'hr_info_id');

        this.setState({ loading: false, week: week, year: year });

    }

    getUserHoursWeek(userID) {

        let hours = 0.00;

        if (Constants.isEmpty(this.allPlannerData[userID]) === false) {

            for (const data of this.allPlannerData[userID]) {
                // if (typeof this.state.absenceSchedules[data.rooster_id] !== 'undefined' && Constants.objectArrayContainsKey(this.state.exclusions, 'uit_afw_id', this.state.absenceSchedules[data.rooster_id].afw_id)) {
                //   continue;
                // }
                const startTime = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
                const endTime = Constants.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                const breakTime = Constants.timeStringToMS(data.rooster_pauze);
                hours += (endTime.getTime() - startTime.getTime()) - breakTime;
            }

        }

        if (Constants.isEmpty(this.unpublishedRepeatingShifts[userID]) === false) {

          for (const r of this.unpublishedRepeatingShifts[userID]) {

            let _break = `00:00:00`;
            if (Constants.isEmpty(r.hr_pauze) === false) {
              _break = `${r.hr_pauze}`;
            }

            const data = {
                hr_team_id: r.hr_team_id,
                info_id: r.hr_info_id,
                rooster_datum: Constants.dateToString(r.date),
                rooster_begin: r.hr_begin,
                rooster_eind: r.hr_eind,
                rooster_pauze: _break,
                functie_id: r.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
                functie_naam: r.functie_naam,
            }

            const startTime = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
            const endTime = Constants.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
            const breakTime = Constants.timeStringToMS(data.rooster_pauze);
            hours += (endTime.getTime() - startTime.getTime()) - breakTime;

          }
      
        }

        return hours;

    }

    getUserHours() {

        let toRender = [];
        let count = 0;

        let users = [];

        const monday = Constants.getMonday(this.state.week, this.state.year);

        for (const data of this.state.accounts) {

            // inactive
            if (data.info2_status === '2') {
                continue;
            }

            if (Constants.objectArrayContainsKey(users, 'id', data.info_id) === false) {

                const hoursWeek = this.getUserHoursWeek(data.info_id);
                const hoursWeek2 = Constants.round(hoursWeek / 1000 / 60 / 60);
                const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;


                let contractMinHours = null;
                let contractHours = null;

                const contract = Constants.getCurrentContract(this.props._contracts[data.info_id], Constants.stringToDate(monday));
                if (contract !== null) {
                    if (contract.contract_type === '1') { // minmax
                        const minHours = parseFloat(contract.contract_uren);
                        const hours = parseFloat(contract.contract_max_uren);
                        if (isNaN(hours) === false && hours > 0 && isNaN(minHours) === false && minHours > 0) {
                          contractMinHours = minHours;
                          contractHours = hours;
                        }
                    } else if (contract.contract_type === '2') { // vaste uren
                        const hours = parseFloat(contract.contract_uren);
                        if (isNaN(hours) === false && hours > 0) {
                          contractHours = hours;
                        }
                    }
                }

                users.push({
                    id: data.info_id,
                    hours: hoursWeek2,
                    ms: hoursWeek,
                    name: userFullName,
                    contractMinHours: contractMinHours,
                    contractHours: contractHours,
                });

            }
        }

        users.sort((a, b) => (a.hours > b.hours || (a.hours === b.hours && a.info_voornaam > b.info_voornaam) || (a.hours === b.hours && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? -1 : 1);

        for (let key in users) {

            const user = users[key];

            toRender.push(
                <div className='hr_hoursBar' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

                    <div className='hr_hoursBar_sub1'>
                        <b>{user.name}</b>
                    </div>

                    {user.contractHours !== null && user.contractMinHours !== null ?
                      <div className='hr_hoursBar_sub3'>
                        <span style={{color: user.hours > user.contractHours ? Colors.color.redFilledIn : null}}>{Constants.msToTime2(user.contractMinHours * 60 * 60 * 1000)}|{Constants.msToTime2(user.contractHours * 60 * 60 * 1000)}</span>
                      </div>
                    :
                    user.contractHours !== null ?
                      <div className='hr_hoursBar_sub3'>
                        <span style={{color: user.hours > user.contractHours ? Colors.color.redFilledIn : null}}>{Constants.msToTime2(user.contractHours * 60 * 60 * 1000)}</span>
                      </div>
                    : 
                        <div className='hr_hoursBar_sub3'>
                            &nbsp;
                        </div>
                    }

                    <div className='hr_hoursBar_sub3'>
                    {Constants.msToTime2(user.ms)}
                    </div>

                </div>
            );

            if (count === 0) {
                count++;
              } else {
                count--;
              }
                
        }

        return toRender;

    }

    getFunctionBars() {

        let toRender = [];

            for (const func of this.state.functions) {

                toRender.push(
                    <option value={'f' + func.functie_id} selected={this.state.selectedFunction === 'f' + func.functie_id}>{func.functie_naam}</option>
                );

            }

            toRender.push(<option value={'null'} disabled></option>);
            toRender.push(<option value={'null'} disabled>Afdelingen</option>);

            for (const section of this.state.sections) {

                toRender.push(
                    <option value={'a' + section.afdeling_id} selected={this.state.selectedSection === 'a' + section.afdeling_id}>{section.afdeling_naam}</option>
                );
            
            }

        return toRender;

    }

    changeFunction(e) {

        if (e.target.value.startsWith('f')) {
            this.setState({ selectedFunction: e.target.value, selectedSection: null }, () => {
                this.setState({ loading: true });
                this.componentDidMount();
            });
        } else if (e.target.value.startsWith('a')) {
            this.setState({ selectedSection: e.target.value, selectedFunction: null }, () => {
                this.setState({ loading: true });
                this.componentDidMount();
            });
        } else {
            this.setState({ selectedSection: null, selectedFunction: null }, () => {
                this.setState({ loading: true });
                this.componentDidMount();
            });
        }

    } 

    getDaysBars() {

        let people = [];

        const days = [
            { name: 'Maandag', id: 1 },
            { name: 'Dinsdag', id: 2 },
            { name: 'Woensdag', id: 3 },
            { name: 'Donderdag', id: 4 },
            { name: 'Vrijdag', id: 5 },
            { name: 'Zaterdag', id: 6 },
            { name: 'Zondag', id: 0 },
        ];
    
        for(const day of days) {
        
            people.push(
    
                Constants.objectArrayContainsKey(this.state.selectedDays, 'id', day.id) === true ?
    
                  <div className='reports_toggleButton_active' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}} onClick={() => this.selectingDay(day)}>
                    <i className='fas fa-check-circle' />
                    <span>{day.name}</span>
                  </div>
    
                :
    
                  <div className='reports_toggleButton' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}} onClick={() => this.selectingDay(day)}>
                    <i className='fal fa-circle' />
                    <span>{day.name}</span>
                  </div>
    
            );
    
        }
    
        return people;
    
      }

      selectingDay(day) {

        this.setState({showError: false});
    
        if(Constants.objectArrayContainsKey(this.state.selectedDays, 'id', day.id)) {
    
            this.deselectDay(day);
    
        } else {
    
            this.selectDay(day);
    
        }
    
      }

      selectDay(day) {

        let selectedFunctions = this.state.selectedDays;
    
        selectedFunctions.push(day); // be sure to clear this when adding availability

        if (day.id === 0) {
            localStorage.setItem('@publishSunday', '1');
          } else if (day.id === 1) {
            localStorage.setItem('@publishMonday', '1');
          } else if (day.id === 2) {
            localStorage.setItem('@publishTuesday', '1');
          } else if (day.id === 3) {
            localStorage.setItem('@publishWednesday', '1');
          } else if (day.id === 4) {
            localStorage.setItem('@publishThursday', '1');
          } else if (day.id === 5) {
            localStorage.setItem('@publishFriday', '1');
          } else if (day.id === 6) {
            localStorage.setItem('@publishSaturday', '1');
          }
    
        this.setState({ selectedDays: selectedFunctions });
    
      }
    
      deselectDay(day) {
    
          let selectedFunctions = this.state.selectedDays;
    
          Constants.removeFromArray2(selectedFunctions, 'id', day.id);

          if (day.id === 0) {
            localStorage.setItem('@publishSunday', '0');
          } else if (day.id === 1) {
            localStorage.setItem('@publishMonday', '0');
          } else if (day.id === 2) {
            localStorage.setItem('@publishTuesday', '0');
          } else if (day.id === 3) {
            localStorage.setItem('@publishWednesday', '0');
          } else if (day.id === 4) {
            localStorage.setItem('@publishThursday', '0');
          } else if (day.id === 5) {
            localStorage.setItem('@publishFriday', '0');
          } else if (day.id === 6) {
            localStorage.setItem('@publishSaturday', '0');
          }
    
          this.setState({ selectedDays: selectedFunctions });
    
      }

  render() {

    return (
        <div id="publishModal" className="modal" onClick={(e) => e.target === document.getElementById("zpublishModal") ? this.props._closeModal() : null}>

        {this.state.showAlert === true ?
                        
            <BetterAlert
                _closeAlert={this.closeAlert.bind(this)}
                _title={this.state.alertTitle}
                _body={this.state.alertBody}
                _buttons={this.state.alertButtons}
                _buttonText={this.state.alertButtonText}
                _buttonAction={this.state.alertButtonAction}
            />

        : null}

        <div className="tutmodal2-content" style={{width: '50vw'}}>
            {this.state.display === 'HOURS' ?
                <div className="tutmodal2-header" style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => this.setState({display: 'PUBLISH'})}>
                    <i className='far fa-chevron-left' style={{marginRight: '1.2vw', marginTop: '0.2vw'}} />
                    <p>Details week {this.state.detailsWeek}, {this.state.detailsYear}</p>
                </div>
            :
                <div className="tutmodal2-header">
                    <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
                    <p>Rooster publiceren - week {this.props._week}, {this.props._year}</p>
                </div>
            }

            <div className="tutmodal2-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0, marginBottom: this.state.display === 'HOURS' ? 0 : null}}>

                {this.state.loading === true ?

                    <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                :

                    <div className='tutmodal2_content' style={{width: '100%'}}>

                        <div className='tutmodal2_container'>

                            {this.state.reloading === true ?
                            
                                <div className='loader3'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                            : null}

                            {this.state.display === 'HOURS' ?

                                <div>

                                    <div className='hr_hoursBar_title'>

                                      <div className='hr_hoursBar_sub1'>
                                        <b>Naam</b>
                                      </div>

                                      <div className='hr_hoursBar_sub3'>
                                        <b>Contract</b>
                                      </div>

                                      <div className='hr_hoursBar_sub3'>
                                        <b>Ingeplande uren</b>
                                      </div>

                                    </div>

                                    {this.getUserHours()}

                                </div>

                            :

                                <div>

                                    <div className='planner_addButtons_container' style={{marginTop: 0, marginLeft: 0}}>

                                        {/* marginLeft: '0.6vw' */}

                                        <div style={{width: '30%'}}>
                                          <select
                                              name="Selecteer een afdeling"
                                              className={'planner_dropdown'}
                                              onChange={(event) => this.changeFunction(event)}
                                          >
                                              <option value={'null'} disabled>Functies</option>
                                              <option value={'null'} selected={this.state.selectedFunction === null}>Alle functies</option>
                                              <option value={'null'} disabled></option>
                                              {this.getFunctionBars()}
                                          </select>
                                        </div>

                                    </div>

                                    <div style={{fontSize: '1vw', marginBottom: '0.1vw', marginTop: '2vw'}}><b>Dagen</b></div>

                                    <div style={{display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap'}}>
                                        {this.getDaysBars()}
                                    </div>

                                    <div style={{fontSize: '1vw', marginBottom: '0.5vw', marginTop: '2vw'}}><b>Wijzigingen</b></div>

                                    {this.getWeekBars()}

                                    {this.state.unpublishedRepeatsThisWeek.length > 0 ?
                                        <div style={{fontSize: '1vw', marginBottom: '0.5vw', marginTop: '2vw'}}><b>Herhalende diensten</b></div>
                                    : null}

                                    {this.state.unpublishedRepeatsThisWeek.length > 0 ?
                                        <span className='defaultLinkO' onClick={() => this.publishWeekRepeatShift(this.props._week, this.props._year)}>Niet-gepubliceerde herhalende diensten <b>week {this.props._week}</b> publiceren</span>
                                    : null}

                                </div>

                            }

                        </div>

                    </div>

                }

            </div>

            {this.state.display === 'PUBLISH' ?

                this.state.loading === true || this.state.reloading === true ?

                    <div className='tutmodal2-bottom'>

                    <div className='tutmodal2-loadingButtonText'>
                        <div className="lds-dual-ring-button" />
                    </div>

                    </div>

                :

                    <div className='tutmodal2-bottom'>

                        {/* {this.showError()} */}

                        {this.state.total > 1 ?
                            <div style={{position: 'absolute', left: '2vw'}}>
                                <span>Totaal: </span>
                                <span><b>{this.state.total}</b> wijzigingen</span>
                            </div>
                        : null}

                        {/* {this.state.mainDepublished === false ? */}
                            <div className='modal-denyButton' onClick={this.dePublishPlanner.bind(this, this.props._week, this.props._year)}>
                                <span>Week {this.props._week} depubliceren</span>
                            </div>
                        {/* : null} */}

                        <div className='modal-submitButton' onClick={this.publishPlanner.bind(this)}>
                            <span>Alles publiceren</span>
                        </div>

                    </div>

            : null}

        </div>

      </div>
    );
  }
  
}

export default PublishModalTryout;