/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './holidays.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import APIAddHoliday from '../../../classes/APIAddHoliday';
import APIEditHoliday from '../../../classes/APIEditHoliday';
import APIDeleteHoliday from '../../../classes/APIDeleteHoliday';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import KodictNotifications from '../../../constants/KodictNotifications';
import { Link } from 'react-router-dom';
import APIADD from '../../../classes/global/APIADD';
import APIGET from '../../../classes/global/APIGET';
import APIEDIT from '../../../classes/global/APIEDIT';

class HolidaysModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.deadlines = this.props._deadlines;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        holidayDate1: null,
        holidayDateText1: '',

        holidayDate2: null,
        holidayDateText2: '',

        comment: '',

        disabled: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        timeNow: new Date(),

        startTime: null,
        startText: '00:00',
        endTime: null,
        endText: '00:00',
  
        wholeDay: false,
  
        wholeDayBlocked: false,
        selectTimeDisabled: false,

    };

    this._oneDay = this.props._oneDay;

  }

  async componentDidMount() {

    this.setState({
      timeNow: await Constants.getDateNow(),
    });

    if(this.mode === 0) { //ADDING

      const startTime = new Date();
      startTime.setHours(6, 0);
    
      const endTime = new Date();
      endTime.setHours(6, 0);
    
      this.setStartTime(startTime);
      this.setEndTime(endTime);

    }

    if(this.mode === 1) { //EDITING

      const dateObject1 = Constants.stringToDate(this.modalData.vakantie_begin);
      const dateObject2 = Constants.stringToDate(this.modalData.vakantie_eind);

      const dateText1 = Constants.dateToString(dateObject1);
      const dateText2 = Constants.dateToString(dateObject2);

      if (this.modalData.hours !== null) {

        if (this.modalData.hours.vu_begin === '00:00:00' && this.modalData.hours.vu_eind === '00:00:00') {

          const startTime = new Date();
          startTime.setHours(0, 0);
    
          const endTime = new Date();
          endTime.setHours(0, 0);

          this.setStartTime(startTime);
          this.setEndTime(endTime);

          this.setState({selectTimeDisabled: true, wholeDay: true});

        } else {

          const startTime = new Date(dateObject1.getFullYear(), dateObject1.getMonth(), dateObject1.getDate(), parseInt(this.modalData.hours.vu_begin.substring(0, 2)), parseInt(this.modalData.hours.vu_begin.substring(3, 5)), 0);
          const endTime = new Date(dateObject1.getFullYear(), dateObject1.getMonth(), dateObject1.getDate(), parseInt(this.modalData.hours.vu_eind.substring(0, 2)), parseInt(this.modalData.hours.vu_eind.substring(3, 5)), 0);
    
          this.setStartTime(startTime);
          this.setEndTime(endTime);

        }

      } else {

        const startTime = new Date();
        startTime.setHours(6, 0);
    
        const endTime = new Date();
        endTime.setHours(6, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

      }

      this.setState({
        holidayDate1: Constants.stringToDate(this.modalData.vakantie_begin),
        holidayDateText1: dateText1,
        holidayDate2: Constants.stringToDate(this.modalData.vakantie_eind),
        holidayDateText2: dateText2,
        disabled: this.modalData.vakantie_geaccepteerd !== '0',
      });

    }

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        holidayDate1: date,
        holidayDateText1: dateText,
        showError: false,
    });

    if (this._oneDay === true) {
      this.setDate2(date);
    }

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        holidayDate2: date,
        holidayDateText2: dateText,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je jouw verlofaanvraag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteHoliday.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveHoliday() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddHoliday.Request(
      this.state.holidayDateText1,
      this.state.holidayDateText2,
      this.state.comment,
    );

    if(added === true) {

      // if verlof, add time
      if (this._oneDay === true) {
        const vakantieID = await APIGET.Request(`SELECT vakantie_id`, `FROM Vakantie`, null, null, null, `ORDER BY vakantie_id DESC LIMIT 1`);
        if (vakantieID.length > 0) {
          await APIADD.Request(`INSERT INTO VerlofUren`, `VALUES (NULL, ${vakantieID[0].vakantie_id}, '${this.state.startText}:00', '${this.state.endText}:00')`);
        }
      }

      const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

      const msg = `${this._oneDay === true ? `heeft verlof aangevraagd voor ${this.state.holidayDate1.toLocaleDateString()} ${this.state.startText === '00:00' && this.state.endText === '00:00' ? '(hele dag, 8 uren)' : `(${this.state.startText} - ${this.state.endText})`}` : `heeft een nieuw vakantieverzoek aangevraagd van ${this.state.holidayDate1.toLocaleDateString()} t/m ${this.state.holidayDate2.toLocaleDateString()}`}`

      await KodictNotifications.sendPushNotificationAdmin(
          'holiday',
          'Verlof',
          `${userFullName} ${msg}`
      );

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editHoliday(accepted) {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditHoliday.Request(
        this.state.holidayDateText1,
        this.state.holidayDateText2,
        this.state.comment,
        0,
        parseInt(this.modalData.vakantie_id)
    );

    if(added === true) {

      // if verlof, add time
      if (this._oneDay === true) {
        await APIEDIT.Request(`UPDATE VerlofUren`, `SET vu_begin = '${this.state.startText}', vu_eind = '${this.state.endText}'`, `WHERE vu_vakantie_id = ${this.modalData.vakantie_id}`);
      }

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteHoliday() {

    this.setState({loading: true});

    const added = await APIDeleteHoliday.Request(
        parseInt(this.modalData.vakantie_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if (parseInt(Data.data.appOptions.opties_verlofDeadline) > 0) {
      const daysMS = parseInt(Data.data.appOptions.opties_verlofDeadline) * 86400000;
      if (this.state.holidayDate1 !== null && (this.state.holidayDate1.getTime() - this.state.timeNow.getTime() < daysMS)) {
        this.setState({
          showError: true,
          errorText: `Je bent te laat met het aanvragen van verlof. Verlof aanvragen kan ${Data.data.appOptions.opties_verlofDeadline} dagen voor de begindatum van jouw verlof/vakantie`,
        });
        return false;
      }
    }

    if(this.state.holidayDate1 === null) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen ${this._oneDay === true ? 'datum' : 'begindatum'} ingevoerd`,
      });
      return false;
    }
    if(this.state.holidayDate2 === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen einddatum ingevoerd',
      });
      return false;
    }
    if((this.state.startTime.getTime() === this.state.endTime.getTime()) && this._oneDay === true) {
      if(this.state.startText !== '00:00' && this.state.endText !== '00:00') {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet gelijk zijn aan eindtijd',
        });
        return false;
      }
    }
    if((this.state.startTime.getTime() > this.state.endTime.getTime()) && this._oneDay === true) {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet later zijn dan eindtijd',
        });
        return false;
    }

    return true;

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  toggleSwitch() {

    const startTime = new Date();
    const endTime = new Date();

    if(this.state.wholeDay === true) {
      startTime.setHours(6, 0);
      endTime.setHours(6, 0);
    } else if(this.state.wholeDay === false) {
      startTime.setHours(0, 0);
      endTime.setHours(0, 0);
    }

    this.setStartTime(startTime);
    this.setEndTime(endTime);

    this.setState({wholeDay: !this.state.wholeDay, selectTimeDisabled: !this.state.selectTimeDisabled});

  }

  render() {

    let markedDates = [];

    const today = this.state.timeNow;

    // for(let key in this.deadlines) {

    //     if(this.deadlines[key].deadlineverlof_aanpassen === '1') {
    //         continue; //skip
    //     }

    //     const startDate = new Date(this.deadlines[key].deadlineverlof_begin);
    //     const endDate = new Date(this.deadlines[key].deadlineverlof_eind);
    //     const deadlineDate = Constants.getDateTZ(this.deadlines[key].deadlineverlof_datum, this.deadlines[key].deadlineverlof_tijd); //NEEDEDDDD TO COMPARE TIMES

    //     let currentDate = startDate;

    //     currentDate.setHours(12,0,0,0);
    //     endDate.setHours(12,0,0,0);

    //     if(today.getTime() > deadlineDate.getTime()) { //past

    //         while (currentDate <= endDate) {
    //             markedDates.push(new Date(currentDate));

    //             currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    //         }

    //     }

    // }

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="holidaysModal" className="modal" onClick={(e) => e.target === document.getElementById("zholidaysModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>{this._oneDay === true ? 'Verlof' : 'Vakantie'} aanvragen</p>
            
            :
            this.mode === 1 ?

              <p>{this._oneDay === true ? 'Verlof' : 'Vakantie'} aanpassen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    {/* <Link to='/all_deadlines' className='defaultLink'><i className='far fa-info-circle' /> Bekijk hier alle deadlines</Link> */}

                    <div style={{marginTop: 10}}>{this._oneDay === true ? 'Datum' : 'Begindatum'}:</div>

                    <DatePicker
                      selected={this.state.holidayDate1}
                      startDate={this.state.holidayDate1}
                      endDate={this.state.holidayDate2}
                      onChange={(date) => this.setDate1(date)}
                      dateFormat="dd-MM-yyyy"
                      className={this.state.disabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                      placeholderText={'Selecteer een datum'}
                      locale={nl}
                      showWeekNumbers
                      selectsStart={this._oneDay !== true}
                      disabled={this.state.disabled}
                      excludeDates={markedDates}
                      minDate={this.state.timeNow}
                    />

                    {this._oneDay !== true ?
                      <div>
                        <div style={{marginTop: 5}}>Einddatum:</div>

                        <DatePicker
                          selected={this.state.holidayDate2}
                          startDate={this.state.holidayDate1}
                          endDate={this.state.holidayDate2}
                          minDate={this.state.holidayDate1}
                          onChange={(date) => this.setDate2(date)}
                          dateFormat="dd-MM-yyyy"
                          className={this.state.disabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                          placeholderText={'Selecteer een datum'}
                          locale={nl}
                          showWeekNumbers
                          selectsEnd
                          disabled={this.state.disabled}
                          excludeDates={markedDates}
                        />
                      </div>
                    : 
                      <div>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 10,}}>

                            Hele dag

                            <label className="switch" style={{marginLeft: 25}}>
                                <input 
                                  type="checkbox" 
                                  checked={this.state.wholeDay} 
                                  onClick={() => this.toggleSwitch()}
                                  disabled={this.state.wholeDayBlocked}
                                  readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                        </div>

                        <div style={{marginTop: 10}}>Van:</div>

                        <DatePicker
                            selected={this.state.startTime}
                            onChange={(date) => this.setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Van"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className={this.state.selectTimeDisabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                            disabled={this.state.selectTimeDisabled}
                        />

                        <div style={{marginTop: 5}}>Tot:</div>

                        <DatePicker
                            selected={this.state.endTime}
                            onChange={(date) => this.setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Tot"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className={this.state.selectTimeDisabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                            disabled={this.state.selectTimeDisabled}
                        />
                      </div>
                    }

                    <div style={{marginTop: 10}}>Opmerking: (max. 250 tekens)</div>

                    <textarea  
                        className={this.state.disabled === true ? 'inputFieldBigDisabled' : 'inputFieldBig'}
                        maxLength={250}
                        value={this.state.comment}
                        onChange={(event) => this.setState({ comment: event.target.value })}
                        disabled={this.state.disabled}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?
                        this.modalData.vakantie_geaccepteerd === '0' ? 

                            <div>

                                <div className='submitButton' onClick={() => this.editHoliday()}>Opslaan</div>

                                <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                            </div>

                        :

                            null

                    :

                        <div className='submitButton' onClick={() => this.saveHoliday()}>Aanvragen</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default HolidaysModal;