/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './standardAvailabilityRequests.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import BetterAlert from '../../../components/alert/alert';
import KodictNotifications from '../../../constants/KodictNotifications';
import APITradeShift from '../../../classes/APITradeShift';
import APIDeleteFromAcceptedShift from '../../../classes/APIDeleteFromAcceptedShift';
import APIDeleteFromAvailableShift from '../../../classes/APIDeleteFromAvailableShift';
import APIGET from '../../../classes/global/APIGET';
import APIEditAbsencetype from '../../../classes/APIEditAbsencetype';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIDELETE from '../../../classes/global/APIDELETE';

class StandardAvailabilityRequests extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.teamPlanner = [];

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      availabilityChanges: {},
      standardAvailability: {},

      showModal: false,
      modalMode: 0,
      modalData: [],

      selected: null,
      selectedObject: null,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,
      alertButtonAction2: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    const availabilityChanges = await APIGET.Request(`SELECT StandaardBeschikbaarheidWijziging.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM StandaardBeschikbaarheidWijziging`, `LEFT JOIN Info ON info_id = sbw_info_id`, null, null, `ORDER BY info_voornaam, info_achternaam, sbw_dag`);
    const availabilityChangesObj = Constants.convertToObjectWithArrays(availabilityChanges, 'sbw_info_id');

    const standardAvailability = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, null, null, null);
    const standardAvailabilityObj = Constants.convertToObjectWithArrays(standardAvailability, 'sb_info_id');

    //this.teamPlanner = [];

    // if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

    //     for (const trade of availableShifts) {

    //         const obj = await APIGetTeamPlanner.Request(trade.rooster_datum);

    //         for (const teampl of obj) {
    //             this.teamPlanner = [...this.teamPlanner, teampl];
    //         }

    //     }

    //     const oldTrades = availableShifts;
    //     availableShifts = [];

    //     for (const team of Data.data.ownTeams) {

    //         const arr = Constants.getTeamPlannerT(this.teamPlanner, oldTrades, parseInt(team.team_id));

    //         for (const teampl of arr) {
    //             availableShifts = [...availableShifts, teampl];
    //         }

    //     }

    // }

    this.setState({
        loading: false,
        availabilityChanges: availabilityChangesObj,
        standardAvailability: standardAvailabilityObj,
    });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    const availabilityChanges = await APIGET.Request(`SELECT StandaardBeschikbaarheidWijziging.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM StandaardBeschikbaarheidWijziging`, `LEFT JOIN Info ON info_id = sbw_info_id`, null, null, `ORDER BY info_voornaam, info_achternaam, sbw_dag`);
    const availabilityChangesObj = Constants.convertToObjectWithArrays(availabilityChanges, 'sbw_info_id');

    const standardAvailability = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, null, null, null);
    const standardAvailabilityObj = Constants.convertToObjectWithArrays(standardAvailability, 'sb_info_id');

    this.reloadShift(availabilityChanges, availabilityChangesObj);

    this.setState({
      reloading: false,
      availabilityChanges: availabilityChangesObj,
      standardAvailability: standardAvailabilityObj,
    });

  }

  reloadShift(changes, changesObject) {

    let found = false;

    for (const change of changes) {

      if (this.state.selected === change.sbw_info_id) {
        found = true;
        this.setState({ selectedObject: changesObject[change.sbw_info_id] });
      }

    }

     if (found === false) {
       this.setState({
         selected: null,
         selectedObject: null,
       });
     }

  }

  selectUser(user) {

    const userInfo = user[0];

    if (userInfo.sbw_info_id === this.state.selected) {

      this.setState({
        selected: null,
        selectedObject: null,
      });

    } else {

      this.setState({
        selected: userInfo.sbw_info_id,
        selectedObject: user,
      });

    }

  }

  getAvailableChanges() {

    let trades = [];

    for(const key in this.state.availabilityChanges) {

      const shObject = this.state.availabilityChanges[key];
      const sh = this.state.availabilityChanges[key][0];

      const userFullName = `${sh.info_voornaam} ${(sh.info_tussenvoegsel !== null && sh.info_tussenvoegsel !== '') ? sh.info_tussenvoegsel + " " + sh.info_achternaam : sh.info_achternaam}`;

      trades.push(

        <div key={sh.sbw_id}>

          <div className='availabilityRequests_avContainer' style={this.state.selected === sh.sbw_info_id ? {border: '1px solid #47B7EB'} : null} onClick={() => this.selectUser(shObject)}>

            <div className='availabilityRequests_avContainer_row'>

              {/* <div className='availabilityRequests_avContainer_sub1'>

                <span>Tijd:</span>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <span style={{marginTop: 1}}>Team:</span>

                : null}

                <span style={{marginTop: 1}}>Functie:</span>

                <span style={{marginTop: 4}}>Naam:</span>

                {this.getSkillsSchedule(sh.rooster_id) !== null ?
                  <span style={{marginTop: 4}}>Competenties:</span>
                : null}

              </div> */}

              <div className='availabilityRequests_avContainer_sub2'>

                {/* <span style={{fontWeight: 'bold'}}>{sh.rooster_begin.substr(0,5)} - {sh.rooster_eind.substr(0,5)}</span>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <span style={{marginTop: 1}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, sh.rooster_id)}</span>

                : null}

                <span style={{marginTop: 1}}>{sh.functie_naam}</span> */}

                <span style={{marginTop: 0}}>{userFullName}</span>

                {/* {this.getSkillsSchedule(sh.rooster_id) !== null ?
                  <div style={{marginTop: 4}}>{this.getSkillsSchedule(sh.rooster_id)}</div>
                : null} */}

              </div>

            </div>

          </div>

        </div>
      );

    }

    return trades;

  }

  getAvailableChangesUser(data) {

    let trades = [];

    for(const change of data) {

      let changeFrom = null;

      if (typeof this.state.standardAvailability[change.sbw_info_id] !== 'undefined') {
        for (const sa of this.state.standardAvailability[change.sbw_info_id]) {
          if (sa.sb_dag === change.sbw_dag) {
            changeFrom = sa;
          }
        }
      }

      if (changeFrom !== null) {

        trades.push(

          <div key={change.sbw_info_id + change.sbw_dag}>

            <div className='availabilityRequests_avContainer2'>

              <span style={{marginLeft: '2%', fontWeight: 'bold'}}>{change.sbw_dag === '1' ? 'Maandag' : change.sbw_dag === '2' ? 'Dinsdag' : change.sbw_dag === '3' ? 'Woensdag' : change.sbw_dag === '4' ? 'Donderdag' : change.sbw_dag === '5' ? 'Vrijdag' : change.sbw_dag === '6' ? 'Zaterdag' : 'Zondag'}</span>

              <div className='availabilityRequests_avContainer_row'>

                <div className='availabilityRequests_changes_sub1'>

                  {changeFrom.sb_begin === '00:00:00' && changeFrom.sb_eind === '00:00:00' ?
                    <span style={{color: changeFrom.sb_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>Hele dag</span>
                  :
                    <span style={{color: changeFrom.sb_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{changeFrom.sb_begin.substr(0, 5)} - {changeFrom.sb_eind.substr(0, 5)}</span>
                  }

                  <i className='fal fa-long-arrow-right' style={{color: '#47B7EB', marginLeft: '4%', fontSize: '1vw', marginTop: '1%'}} />

                  {change.sbw_begin === '00:00:00' && change.sbw_eind === '00:00:00' ?
                    <span style={{color: change.sbw_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn, marginLeft: '4%'}}>Hele dag</span>
                  :
                    <span style={{color: change.sbw_beschikbaar === '1' ? Colors.color.greenFilledIn : Colors.color.redFilledIn, marginLeft: '4%'}}>{change.sbw_begin.substr(0, 5)} - {change.sbw_eind.substr(0, 5)}</span>
                  }

                </div>

                <div className='availabilityRequests_changes_sub2'>

                  <span className='availabilityRequests_button_green' onClick={() => this.acceptUserAlert(change)}>Goedkeuren</span>
                  <span className='availabilityRequests_button_red' onClick={() => this.denyUserAlert(change)}>Afwijzen</span>

                </div>

              </div>

            </div>

          </div>
        );

      }

    }

    return trades;

  }

  acceptUserAlert(change) {

    this.setState({
      alertTitle: 'Goedkeuren',
      alertBody: `Wil je deze wijziging goedkeuren?`,
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Goedkeuren'],
      alertButtonAction: this.acceptUser.bind(this, change),
      showAlert: true,
    });

  }

  denyUserAlert(change) {

      this.setState({
        alertTitle: 'Afwijzen',
        alertBody: `Wil je deze wijziging afwijzen?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Afwijzen'],
        alertButtonAction: this.denyUser.bind(this, change),
        showAlert: true,
      });
    
  }

  async acceptUser(change) {

    this.setState({loading: true});

    const updated = await APIEDIT.Request(`UPDATE StandaardBeschikbaarheid`, `SET sb_beschikbaar = ${change.sbw_beschikbaar}, sb_begin = '${change.sbw_begin}', sb_eind = '${change.sbw_eind}'`, `WHERE sb_dag = ${change.sbw_dag} AND sb_info_id = ${change.sbw_info_id}`);

    if (updated === true) {
      await APIDELETE.Request(`DELETE FROM StandaardBeschikbaarheidWijziging`, `WHERE sbw_id = ${change.sbw_id}`);
      await KodictNotifications.sendPushNotification(change.sbw_info_id, 'news', 'Beschikbaarheid', `Jouw beschikbaarheid wijziging voor ${change.sbw_dag === '1' ? 'Maandag' : change.sbw_dag === '2' ? 'Dinsdag' : change.sbw_dag === '3' ? 'Woensdag' : change.sbw_dag === '4' ? 'Donderdag' : change.sbw_dag === '5' ? 'Vrijdag' : change.sbw_dag === '6' ? 'Zaterdag' : 'Zondag'} is goedgekeurd`);
    }
    
    await this.reloadScreen();

    this.setState({loading: false});

  }

  async denyUser(change) {

    this.setState({loading: true});

    await APIDELETE.Request(`DELETE FROM StandaardBeschikbaarheidWijziging`, `WHERE sbw_id = ${change.sbw_id}`);
    await KodictNotifications.sendPushNotification(change.sbw_info_id, 'news', 'Beschikbaarheid', `Jouw beschikbaarheid wijziging voor ${change.sbw_dag === '1' ? 'Maandag' : change.sbw_dag === '2' ? 'Dinsdag' : change.sbw_dag === '3' ? 'Woensdag' : change.sbw_dag === '4' ? 'Donderdag' : change.sbw_dag === '5' ? 'Vrijdag' : change.sbw_dag === '6' ? 'Zaterdag' : 'Zondag'} is afgewezen`);
    
    await this.reloadScreen();

    this.setState({loading: false});

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    const availabilityChanges = this.getAvailableChanges();

    return (
        <div className='availableShiftsAdmin'>

          <Navbar history={this.props.history} active={'standard_availability_requests'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} parent={this} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Beschikbaarheid wijzigingen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='availabilityRequests_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {Object.keys(this.state.availabilityChanges).length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen wijzigingen in beschikbaarheid ingediend</div>
                </div>

              :

                null
              
              }

              <div className={'availabilityRequests_dataContainer'}>

                <div className={'availabilityRequests_dataContainerTitle'}>

                    Medewerker

                    {Object.keys(this.state.availabilityChanges).length > 0 ?
                    
                        <i className={'fa fa-exclamation'} style={{marginLeft: 8, color: Colors.color.redFilledIn, fontSize: '1vw'}}></i>

                    :

                        null

                    }

                </div>

                {availabilityChanges}

              </div>

              <div className={'availabilityRequests_dataContainer'}>

                <div className={'availabilityRequests_dataContainerTitle'}>

                    Beschikbaarheid wijzigingen

                </div>

                {this.state.selected === null ?

                  this.state.selectedObject === null ?

                    <span style={{justifySelf: 'flex-start', textAlign: 'center', fontSize: '1.5vw'}}>Selecteer een medewerker</span>

                  : null

                :
                  
                    this.getAvailableChangesUser(this.state.selectedObject)  

                }

              </div>

              {/* {tradeRequestBars} */}

            </div>
        
          }

        </div>
    );
  }
  
}

export default StandardAvailabilityRequests;