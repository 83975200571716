/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './admin.css';
import '../constants/DefaultStyle.css';

//js components & classes
import Data from '../constants/Data';
import Constants from '../constants/Constants';

import DatePicker from 'react-datepicker';
import BetterAlert from '../components/alert/alert';
import APIAddStoreCode from '../classes/APIAddStoreCode';
import APIAddOrganization from '../classes/APIAddOrganization';
import APIEditStoreCode from '../classes/APIEditStoreCode';
import Colors from '../constants/Colors';
import APISendWelcomeMail from '../classes/APISendWelcomeMail';

import CryptoJS from 'crypto-js';

class AdminModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.l_i_a === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        name: '',
        storecode: '',
        address: '',
        zip: '',
        city: '',
        phone: '',
        email: '',
        owner: '',
        sid: null,
        status: null,
        trial: '',
        pid: null,
        kvk: '',

        showModal2: false,
        modalMode2: 0,
        modalData2: [],

    };

  }

  async componentDidMount() {

    if(this.mode === 1) {
      this.setState({
        name: this.modalData.bedrijf_naam,
        storecode: this.modalData.bedrijf_code,
        address: this.modalData.bedrijf_adres,
        zip: this.modalData.bedrijf_postcode,
        city: this.modalData.bedrijf_plaats,
        phone: this.modalData.bedrijf_telefoon,
        email: this.modalData.bedrijf_email,
        owner: this.modalData.bedrijf_eigenaar,
        sid: parseInt(this.modalData.bedrijf_server_id),
        status: parseInt(this.modalData.bedrijf_status),
        trial: this.modalData.bedrijf_proef_datum === null ? '' : this.modalData.bedrijf_proef_datum,
        pid: parseInt(this.modalData.pakket_id),
        kvk: this.modalData.bedrijf_kvk,
      });
    }

  }

  async saveOrganization() {

    this.setState({loading: true});

    const added1 = await APIAddStoreCode.Request(
      this.state.name,
      this.state.storecode,
      this.state.address,
      this.state.zip,
      this.state.city,
      this.state.phone,
      this.state.email,
      this.state.owner,
      this.state.sid,
      this.state.status,
      this.state.trial,
      this.state.pid,
      this.state.kvk,
    );

    if(added1 === true) {

      const generatedPass = Constants.generateString();

      const passAdmin = CryptoJS.SHA512(generatedPass).toString();

      const added2 = await APIAddOrganization.Request(this.state.storecode, this.state.sid, passAdmin);

      if(added2 === true) {

        await APISendWelcomeMail.Request(this.state.email, generatedPass, this.state.storecode);

        this.reloadScreen();
        this.closeModal();
      }
    } else {
      this.setState({loading: false});
    }

  }

  async editOrganization() {

    this.setState({loading: true});

    const added1 = await APIEditStoreCode.Request(
      this.state.name,
      this.state.storecode,
      this.state.address,
      this.state.zip,
      this.state.city,
      this.state.phone,
      this.state.email,
      this.state.owner,
      this.state.sid,
      this.state.status,
      this.state.trial,
      this.state.pid,
      this.state.kvk,
      this.modalData.bedrijf_id
    );

    if(added1 === true) {
        this.reloadScreen();
        this.closeModal();
    } else {
      this.setState({loading: false});
    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="adminModal" className="modal" onClick={(e) => e.target === document.getElementById("zadminModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.mode === 1 ?
              <p>Bedrijf wijzigen</p>
            :
              <p>Bedrijf toevoegen</p>
            }
            
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Naam bedrijf:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.name}
                        onChange={(event) => this.setState({name: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>Code:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.storecode}
                        onChange={(event) => this.setState({storecode: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>Adres:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.address}
                        onChange={(event) => this.setState({address: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>Postcode:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.zip}
                        onChange={(event) => this.setState({zip: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>Plaats:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.city}
                        onChange={(event) => this.setState({city: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>Telefoon:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.phone}
                        onChange={(event) => this.setState({phone: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>E-mail:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.email}
                        onChange={(event) => this.setState({email: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>Eigenaar:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.owner}
                        onChange={(event) => this.setState({owner: event.target.value})}
                    />

                    <div style={{marginTop: 10}}>KvK:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.kvk}
                        onChange={(event) => this.setState({kvk: event.target.value})}
                        placeholder={'kvk-nummer'}
                    />

                    <div style={{marginTop: 10}}>SID:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.sid}
                        onChange={(event) => this.setState({sid: event.target.value})}
                        placeholder={'1'}
                    />

                    <div style={{marginTop: 10}}>Status:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.status}
                        onChange={(event) => this.setState({status: event.target.value})}
                        placeholder={'0: geblokkeerd | 1: actief'}
                    />

                    <div style={{marginTop: 10}}>Proef:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.trial}
                        onChange={(event) => this.setState({trial: event.target.value})}
                        placeholder={'2019-12-31 | leeg voor geen'}
                    />

                    <div style={{marginTop: 10}}>PID:</div>

                    <input 
                        className={'inputField2'}
                        value={this.state.pid}
                        onChange={(event) => this.setState({pid: event.target.value})}
                        placeholder={'0: proef | 1: pakket 1 etc...'}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                      <div className='submitButton' onClick={() => this.editOrganization()} style={{color: Colors.color.redFilledIn}}>Opslaan</div>
                    :

                      <div className='submitButton' onClick={() => this.saveOrganization()}>Opslaan</div>

                    }

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default AdminModal;