/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './tradeRequests.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddDefaultShift from '../../../classes/APIAddDefaultShift';
import APITradeShift from '../../../classes/APITradeShift';
import APIAcceptTradeDef from '../../../classes/APIAcceptTradeDef';

import DatePicker from 'react-datepicker';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import KodictNotifications from '../../../constants/KodictNotifications';

class TradeRequestsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    if(this.modalData.f2 === undefined || this.modalData.f2 === null) {
      this.modalData.f2 = 'Geen standaard functie';
    }
    
    this.state = {

        loading: false,

        date: Constants.stringToDate(this.modalData.rooster_datum),

        startText: this.modalData.rooster_begin.substring(0, 5),

        endText: this.modalData.rooster_eind.substring(0, 5),

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze standaard tijden wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteTimes.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async saveTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddDefaultShift.Request(
      this.state.startText,
      this.state.endText,
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async denyTrade() {

    this.setState({loading: true});

    const denied = await APIAcceptTradeDef.Request(this.modalData.ruildienst_id, 0);

    if(denied === true) {

        const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

        const dateTrade = Constants.stringToDate(this.modalData.rooster_datum)

        this.closeModal();

        this.reloadScreen();
  
        return;

    } else {

        // console.log('error');
  
        this.setState({loading: false});
  
    }
    
}

async acceptTrade() {

    this.setState({loading: true});

    const accepted = await APIAcceptTradeDef.Request(this.modalData.ruildienst_id, 1);

    if(accepted === true) {

        const traded = await APITradeShift.Request(this.modalData.ruildienst_info_id_other, this.modalData.ruildienst_rooster_id);

        if(traded === true) {

            const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

            const dateTrade = Constants.stringToDate(this.modalData.rooster_datum);

            this.closeModal();

            this.reloadScreen();
      
            return;

        } else {

            // console.log('error');
      
            this.setState({loading: false});
      
        }

    } else {

        // console.log('error');
  
        this.setState({loading: false});
  
    }
    
  }


  async denyTrade2() {

    this.setState({loading: true});

    const denied = await APIAcceptTradeDef.Request(this.modalData.ruildienst_id, 0);

    if(denied === true) {

      const traded = await APITradeShift.Request(this.modalData.ruildienst_info_id, this.modalData.ruildienst_rooster_id);

      if(traded === true) {

          const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

          const dateTrade = Constants.stringToDate(this.modalData.rooster_datum)

          this.closeModal();

          this.reloadScreen();
    
          return;

      } else {

          // console.log('error');
    
          this.setState({loading: false});
    
      }

    } else {

        // console.log('error');

        this.setState({loading: false});

    }
    
  }

  async acceptTrade2() {

    this.setState({loading: true});

    const accepted = await APIAcceptTradeDef.Request(this.modalData.ruildienst_id, 1);

    if(accepted === true) {

        const traded = await APITradeShift.Request(this.modalData.ruildienst_info_id_other, this.modalData.ruildienst_rooster_id);

        if(traded === true) {

            const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

            const dateTrade = Constants.stringToDate(this.modalData.rooster_datum)

            this.closeModal();

            this.reloadScreen();
      
            return;

        } else {

            // console.log('error');
      
            this.setState({loading: false});
      
        }

    } else {

        // console.log('error');
  
        this.setState({loading: false});
  
    }
    
  }

  render() {

    const userFullName = `${this.modalData.v1} ${(this.modalData.tv1 !== null && this.modalData.tv1 !== '') ? this.modalData.tv1 + " " + this.modalData.a1.charAt(0) : this.modalData.a1.charAt(0)}.`;
    const userFullName2 = `${this.modalData.v2} ${(this.modalData.tv2 !== null && this.modalData.tv2 !== '') ? this.modalData.tv2 + " " + this.modalData.a2.charAt(0) : this.modalData.a2.charAt(0)}.`;

    return (
        <div id="tradeRequestsModal" className="modal" onClick={(e) => e.target === document.getElementById("ztradeRequestsModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            <p>{Constants.getDayName(this.state.date)} {this.state.date.getDate()} {Constants.getMonthName(this.state.date)} {this.state.date.getFullYear()}</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Wie:</div>

                    <input className={'inputField2'} disabled value={userFullName} style={{borderColor: Colors.color.redFilledIn, color: 'black'}} />

                    <div style={{marginTop: 5}}>Ruilen met:</div>

                    <input className={'inputField2'} disabled value={userFullName2 + `  (${this.modalData.f2})`} style={{borderColor: Colors.color.greenFilledIn, color: 'black'}} />

                    <div style={{marginTop: 5}}>Van:</div>

                    <input className={'inputField2'} disabled value={this.state.startText} style={{color: 'black'}} />

                    <div style={{marginTop: 5}}>Tot:</div>

                    <input className={'inputField2'} disabled value={this.state.endText} style={{color: 'black'}} />

                    <div style={{marginTop: 5}}>Als:</div>

                    <input className={'inputField2'} disabled value={this.modalData.f1} style={{color: 'black'}} />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 0 ?

                        <div>

                            <div className='submitButton' onClick={() => this.acceptTrade()}>Accepteren</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.denyTrade()}>Afwijzen</div>

                        </div>

                    :
                    this.mode === 1 ?

                      this.modalData.ruildienst_geaccepteerd === '2' ?

                        <div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.denyTrade2()}>Afwijzen</div>

                        </div>

                      :
                      this.modalData.ruildienst_geaccepteerd === '3' ?

                        <div>

                          <div className='submitButton' onClick={() => this.acceptTrade2()}>Accepteren</div>

                        </div>

                      :

                        null

                    :

                      null

                    }

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default TradeRequestsModal;