import React from 'react';

import '../../constants/DefaultStyle.css';
import './signContract.css';

import SignatureCanvas from 'react-signature-canvas'
import APIGET from '../../classes/global/APIGET';
import Constants from '../../constants/Constants';

import { PDFDocument } from 'pdf-lib';
import APIUploadFile from '../../classes/APIUploadFile';
import Data from '../../constants/Data';
import APIEDIT from '../../classes/global/APIEDIT';
import APIDelFile from '../../classes/APIDelFile';

import Docxtemplater from "docxtemplater";
import InspectModule from "docxtemplater/js/inspect-module";
// import ImageModule from "docxtemplater-image-module";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

class SignContract extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    //const { storecode, serverip } = useParams();
    const { storecode, serverip, userID, contractFlatID, contractID } = this.props.match.params;

    this.storecode = storecode;
    this.serverip = serverip;
    this.userID = userID;
    this.contractFlatID = contractFlatID;
    this.contractID = contractID;

    Data.data.accountData = {};
    Data.data.accountData.account_username = 'signwebtiemdo';
    Data.data.serverIP = this.serverip;
    Data.data.storeCode = this.storecode;

    this.docFlat = null;
    this.doc = null;
    
    this.state = {

      loading: true,

      docs: [],
      width: window.innerWidth,

      signed: false,

    };

  }

  async componentDidMount() {

    //add dimensions listener for window resizing
    window.addEventListener('resize', this.getDimensions.bind(this)); 

    const docs = await APIGET.webView(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.userID}`, null, null, this.storecode, this.serverip);

    this.docFlat = Constants.getArrayItem(docs, 'd_id', this.contractFlatID);
    this.doc = Constants.getArrayItem(docs, 'd_id', this.contractID);

    this.setState({ 
      docs: docs,
      loading: false,
    });

  }

  //remove listener on page exit
  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions.bind(this)); 
  }

  //actually set the state to the window dimensions
  getDimensions() {
    this.setState({ width: window.innerWidth });
  }

  async reloadContractSignDocuments(base64Sign) {

    this.setState({ loading: true });

    const doc = this.doc;

    const formUrl = `https://${this.serverip}/u/${this.storecode}/${doc.d_loc}`;
   
    PizZipUtils.getBinaryContent(formUrl, async (error2, content2) => {

      if (error2) {
          throw error2;
      }
  
      const imageOpts2 = {
        centered: false,
        getImage: function (tagValue2, tagName2) {
          return new Promise(function (resolve, reject) {
            PizZipUtils.getBinaryContent(tagValue2, function (error2, content3) {
              if (error2) {
                return reject(error2);
              }
              return resolve(content3);
            });
          });
        },
        getSize: function (img2, tagValue2, tagName2) {
          // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
          return [186, 75];
        },
      };
  
      const zip2 = new PizZip(content2);
      const doc2 = new Docxtemplater(zip2, {
          paragraphLoop: true,
          linebreaks: true,
          // modules: [new ImageModule(imageOpts2)],
      });

      let tags = {};

      tags['sign_employee'] = base64Sign;
    
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      await doc2.renderAsync(tags);
  
      const out2 = doc2.getZip().generate({
          type: "base64",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

          
      // decode to file object
      const newFile = await Constants.urltoFile('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + out2, `${doc.d_naam}`,'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

      // UPLOAD NEW FILE
      const upload = await APIUploadFile.Request(newFile);

      if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

        const uploaded = await APIEDIT.Request(`UPDATE Document`, `SET d_loc = '${upload.loc}', d_getekend = 1, d_datum = '${Constants.dateToString(new Date())}'`, `WHERE d_id = ${doc.d_id}`);

        // DELETE OLD FILES
        await APIDelFile.Request(doc.d_loc);

        this.setState({ loading: false, signed: true });

      }

    });

  }

  // async reloadContractSignDocuments_notFlat(flatID, doc, base64Sign) {

  //   const formUrl = `https://${this.serverip}/u/${this.storecode}/${doc.d_loc}`;

  //   PizZipUtils.getBinaryContent('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + out, async (error2, content2) => {

  //     if (error2) {
  //         throw error2;
  //     }
  
  //     const imageOpts2 = {
  //       centered: false,
  //       getImage: function (tagValue2, tagName2) {
  //         return new Promise(function (resolve, reject) {
  //           PizZipUtils.getBinaryContent(tagValue2, function (error2, content3) {
  //             if (error2) {
  //               return reject(error2);
  //             }
  //             return resolve(content3);
  //           });
  //         });
  //       },
  //       getSize: function (img2, tagValue2, tagName2) {
  //         // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
  //         return [186, 75];
  //       },
  //     };
  
  //     const zip2 = new PizZip(content2);
  //     const doc2 = new Docxtemplater(zip2, {
  //         paragraphLoop: true,
  //         linebreaks: true,
  //         modules: [new ImageModule(imageOpts2)],
  //     });

  //     tags['sign_manager'] = this.state.signBase64;
  //     tags['sign_employee'] = '{%sign_employee}';
    
  //     // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
  //     await doc2.renderAsync(tags);
  
  //     const out2 = doc2.getZip().generate({
  //         type: "base64",
  //         mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //     });

          
  //     // decode to file object
  //     const newFile = await Constants.urltoFile('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + out2, `${doc.d_naam}`,'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

  //     // UPLOAD NEW FILE
  //     const upload = await APIUploadFile.Request(newFile);

  //     if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

  //       const uploaded = await APIEDIT.Request(`UPDATE Document`, `SET d_loc = '${upload.loc}', d_getekend = 1, d_datum = '${Constants.dateToString(new Date())}', d_flat_id = ${flatID}`, `WHERE d_id = ${doc.d_id}`);

  //     }

  //   });

  // }

  async signContract() {

    if (this.state.loading) {
      return;
    }

    if (this.sigCanvas.isEmpty()) {
      return;
    }

    const base64Sign = this.sigCanvas.toDataURL();

    await this.reloadContractSignDocuments(base64Sign);

  }

  render() {

    return (
      <div className='signContract_container'>

        {this.state.loading ?

          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

        :
        this.state.signed === true ?

          <div className='signContract_subContainer'>

            <span style={{ fontWeight: 600, fontSize: 18 }}>Contract is ondertekend</span><br />
            <span style={{ fontWeight: 600, fontSize: 18 }}>Ga terug naar de app</span>

          </div>

        :
        this.state.width < 530 ?

          <div className='signContract_subContainer'>

            <span style={{ fontWeight: 600, fontSize: 18 }}>Kantel jouw apparaat</span><br />
            <i className='far fa-sync-alt' style={{ fontSize: 18 }} />

          </div>

        :
          
          <div className='signContract_subContainer'>

            <span style={{ fontWeight: 600, fontSize: 20 }}>Teken hieronder:</span>

            <SignatureCanvas 
              ref={(ref) => { this.sigCanvas = ref }}
              penColor='black'
              throttle={16}
              // minWidth={5}
              // maxWidth={10}
              // minDistance={20}
              canvasProps={{ className: 'signContract_canvas' }}
            />

            <div className='signContract_buttonsContainer'>

              <div className='signContract_signButton' onClick={() => this.signContract()}>
                <span>Ondertekenen</span>
              </div>

              <div className='signContract_clearButton' onClick={() => this.sigCanvas.clear()}>
                <span>Uitwissen</span>
              </div>

            </div>

          </div>

        }
      </div>        
    );
  }
  
}

export default SignContract;