import React from "react";
import ReactExport from "react-data-export";
import Data from "../../constants/Data";

import '../../pages/schedule/schedule.scss';
import '../../pages/planner/planner.scss';
import Constants from "../../constants/Constants";
import __ from "../../constants/__";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let users = [
    {
        columns: [
            {title: "First_name", width: {wch: 15}},
            {title: "Middle_name", width: {wch: 10}},
            {title: "Last_name", width: {wch: 25}},
            {title: "Birth_date (2000-12-31)", width: {wch: 20}},
            {title: "Birth_place", width: {wch: 20}},
            {title: "Email", width: {wch: 25}},
            {title: "Phone", width: {wch: 20}},
            {title: "Address", width: {wch: 25}},
            {title: "House_number", width: {wch: 10}},
            {title: "Postal_code", width: {wch: 10}},
            {title: "City", width: {wch: 20}},
            {title: "Start_date", width: {wch: 20}},
            {title: "Bank_nr", width: {wch: 20}},
            {title: "Person_nr (identification)", width: {wch: 20}},
        ],
        data: [],
    },
];

let users_all = [
    {
        columns: [
            {title: "First_name", width: {wch: 15}},
            {title: "Middle_name", width: {wch: 10}},
            {title: "Last_name", width: {wch: 25}},
            {title: "Birth_date (2000-12-31)", width: {wch: 20}},
            {title: "Birth_place", width: {wch: 20}},
            {title: "Email", width: {wch: 25}},
            {title: "Phone", width: {wch: 20}},
            {title: "Address", width: {wch: 25}},
            {title: "House_number", width: {wch: 10}},
            {title: "Postal_code", width: {wch: 10}},
            {title: "City", width: {wch: 20}},
            {title: "Start_date", width: {wch: 20}},
            {title: "Bank_nr", width: {wch: 20}},
            {title: "Person_nr (identification)", width: {wch: 20}},
        ],
        data: [],
    },
];

export default class EmployeesDownloadName extends React.Component {

    size(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    // timeStringToFloat(time) {
    //     const hoursMinutes = time.split(/[.:]/);
    //     const hours = parseInt(hoursMinutes[0], 10);
    //     const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //     const hoursMins = hours + minutes / 60;
    //     return Math.round((hoursMins + Number.EPSILON) * 100) / 100;
    // }

    render() {

        users[0].data = [];
        users_all[0].data = [];

        const usersData = this.props.dataSet;      
        const usersData_all = this.props.dataSet2;      

        for(const data of usersData) {
            //const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            users[0].data.push([
                { value: Constants.isEmpty(data.info_voornaam) ? '' : data.info_voornaam },
                { value: Constants.isEmpty(data.info_tussenvoegsel) ? '' : data.info_tussenvoegsel },
                { value: Constants.isEmpty(data.info_achternaam) ? '' : data.info_achternaam },
                { value: Constants.isEmpty(data.info_geboorte) ? '' : data.info_geboorte, style: { numFmt: "yyyy-mm-dd" } },
                { value: Constants.isEmpty(data.info_geboorteplaats) ? '' : data.info_geboorteplaats },
                { value: Constants.isEmpty(data.info_email) ? '' : data.info_email },
                { value: Constants.isEmpty(data.info_telefoon) ? '' : data.info_telefoon },
                { value: Constants.isEmpty(data.info_adres) ? '' : data.info_adres },
                { value: Constants.isEmpty(data.info_huisnummer) ? '' : data.info_huisnummer },
                { value: Constants.isEmpty(data.info_postcode) ? '' : data.info_postcode },
                { value: Constants.isEmpty(data.info_plaats) ? '' : data.info_plaats },
                { value: Constants.isEmpty(data.info_indienst) ? '' : data.info_indienst, style: { numFmt: "yyyy-mm-dd" } },
                { value: Constants.isEmpty(data.info_ibnr) ? '' : data.info_ibnr },
                { value: Constants.isEmpty(data.info_idnr) ? '' : data.info_idnr },
            ]);
        }

        for(const data of usersData_all) {
            //const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
            users_all[0].data.push([
                { value: Constants.isEmpty(data.info_voornaam) ? '' : data.info_voornaam },
                { value: Constants.isEmpty(data.info_tussenvoegsel) ? '' : data.info_tussenvoegsel },
                { value: Constants.isEmpty(data.info_achternaam) ? '' : data.info_achternaam },
                { value: Constants.isEmpty(data.info_geboorte) ? '' : data.info_geboorte, style: { numFmt: "yyyy-mm-dd" } },
                { value: Constants.isEmpty(data.info_geboorteplaats) ? '' : data.info_geboorteplaats },
                { value: Constants.isEmpty(data.info_email) ? '' : data.info_email },
                { value: Constants.isEmpty(data.info_telefoon) ? '' : data.info_telefoon },
                { value: Constants.isEmpty(data.info_adres) ? '' : data.info_adres },
                { value: Constants.isEmpty(data.info_huisnummer) ? '' : data.info_huisnummer },
                { value: Constants.isEmpty(data.info_postcode) ? '' : data.info_postcode },
                { value: Constants.isEmpty(data.info_plaats) ? '' : data.info_plaats },
                { value: Constants.isEmpty(data.info_indienst) ? '' : data.info_indienst, style: { numFmt: "yyyy-mm-dd" } },
                { value: Constants.isEmpty(data.info_ibnr) ? '' : data.info_ibnr },
                { value: Constants.isEmpty(data.info_idnr) ? '' : data.info_idnr },
            ]);
        }

        //month[0].data.sort((a, b) => (a[0].value > b[0].value || (a[0].value === b[0].value && a[2].value > b[2].value)) ? 1 : -1);

        return (
            <ExcelFile
                element={
                    <div className="planner_new_addButton2">
                        {/* <i className={'fal fa-file-excel'} /> */}
                        {__('employees_export')}
                    </div>
                }
                filename={'Medewerkers'}
            >
                <ExcelSheet dataSet={users} name={'Actieve medewerkers'} />
                <ExcelSheet dataSet={users_all} name={'Alle medewerkers'} />

            </ExcelFile>
        );
    }
}