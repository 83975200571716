/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddBlockedDay from '../../../classes/APIAddBlockedDay';
import APIEditBlockedDay from '../../../classes/APIEditBlockedDay';
import APIDeleteBlockedDay from '../../../classes/APIDeleteBlockedDay';

import DatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import APIADD from '../../../classes/global/APIADD';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIDELETE from '../../../classes/global/APIDELETE';

class PublicHolidayModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        name: this.mode === 1 ? this.modalData.f_naam : '',
        date: this.mode === 1 ? Constants.stringToDate(this.modalData.f_datum) : null,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING
        
    }

    if(this.mode === 1) { //EDITING

    }

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    this.setState({
        date: date,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze feestdag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteBlocked.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveBlocked() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIADD.Request(`INSERT INTO Feestdag`, `VALUES (NULL, '${this.state.name}', '${Constants.dateToString(this.state.date)}')`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editBlocked() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEDIT.Request(`UPDATE Feestdag`, `SET f_naam = '${this.state.name}', f_datum = '${Constants.dateToString(this.state.date)}'`, `WHERE f_id = ${this.modalData.f_id}`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteBlocked() {

    this.setState({loading: true});

    const added = await APIDELETE.Request(`DELETE FROM Feestdag`, `WHERE f_id = ${this.modalData.f_id}`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(Constants.isEmpty(this.state.name)) {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen naam ingevoerd',
        });
        return false;
      }

    if(Constants.isEmpty(this.state.date)) {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen datum geselecteerd',
        });
        return false;
      }

    return true;

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="blockedModal" className="modal" onClick={(e) => e.target === document.getElementById("zblockedModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Feestdag toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Feestdag wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Naam</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                            placeholder={'Naam'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Datum</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                            <DatePicker
                                selected={this.state.date}
                                onChange={(date) => this.setDate(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                locale={dateLoc[Constants.getLang().substr(0, 2)]}
                            />

                        </div>

                    </div>

                  <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editBlocked()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveBlocked()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default PublicHolidayModal;