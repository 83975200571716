/* eslint-disable no-loop-func */
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

class NulUren extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
        style: 'currency',
        currency: Data.data.storeCurrency,
      });
    
    this.tijdelijk = this.props._userData.contract_vast === '0';
    this.vacationMonthly = this.props._userData.contract_vakantiegeld_wanneer1 === '0';
    this.meiJuni = this.props._userData.contract_vakantiegeld_wanneer2;
    this.onkosten = this.props._userData.contract_vergoeding !== null && this.props._userData.contract_vergoeding !== '' ? this.props._userData.contract_vergoeding : '';
    this.cao = this.props._userData.contract_cao2 !== null && this.props._userData.contract_cao2 !== '' ? this.props._userData.contract_cao2 : '';
    this.pensioen = this.props._userData.contract_pensioen !== null && this.props._userData.contract_pensioen !== '' ? this.props._userData.contract_pensioen : '';
    this.proef = this.props._userData.contract_proef !== null && this.props._userData.contract_proef !== '' ? this.props._userData.contract_proef : '';
    this.bovenwettelijk = this.props._userData.contract_vakantie_bovenwettelijk !== null && this.props._userData.contract_vakantie_bovenwettelijk !== '' ? parseInt(this.props._userData.contract_vakantie_bovenwettelijk) : 0;
    this.brutoLoon = this.formatter.format(parseFloat(this.props._userData.contract_bruto_uurloon));

    this.voorletter = this.props._userData.info_voornaam.substring(0, 1);
    this.tussen = this.props._userData.info_tussenvoegsel !== null && this.props._userData.info_tussenvoegsel !== '' ? this.props._userData.info_tussenvoegsel : '';
    this.achternaam = this.props._userData.info_achternaam;

    this.voorletter2 = Data.data.accountData.info_voornaam.substring(0, 1);
    this.tussen2 = Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '' ? Data.data.accountData.info_tussenvoegsel : '';
    this.achternaam2 = Data.data.accountData.info_achternaam;
    
    this.state = {};

    //componentWillMount here:

  }

  getFunctionName(functionID) {

    for(let key in this.props._functions) {

        const _function = this.props._functions[key];

        if(parseInt(_function.functie_id) === parseInt(functionID)) {
            return _function.functie_naam;
        }

    }

    return null;

  } 

  render() {

    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#FBFCFD',
            fontSize: 10,
            paddingTop: 25,
            paddingBottom: 25,
            paddingLeft: 50,
            paddingRight: 50,
        },
        titleContainer: {
            width: '100%',
        },
        title: {
            fontSize: 16,
            fontFamily: 'Helvetica-Bold',
            textAlign: 'center',
        },
        bold: {
            fontFamily: 'Helvetica-Bold',
        },
        marginLeft20: {
            marginLeft: 20,
        },
        marginLeft40: {
            marginLeft: 40,
        },
        marginTop20: {
            marginTop: 20,
        },
        marginTop40: {
            marginTop: 40,
        },
        marginTop100: {
            marginTop: 100,
        },
        text: {
            marginTop: 5,
        },
        section: {
            marginTop: 20,
        },
        liMain: {
            flexDirection: 'row',
            width: '100%',
            marginTop: 5,
        },
        li1: {
            width: '3%',
        },
        li2: {
            width: '97%',
        },
    });

    return (

        <Document>

            <Page size="A4" style={styles.page}>

                <View style={styles.titleContainer}>

                    <Text style={styles.title}>NUL UREN CONTRACT</Text>

                </View>

                <View style={styles.section}>

                    <Text style={styles.bold}>Partijen:</Text>

                    <View style={styles.marginLeft40}>

                        <Text style={styles.text}>
                            {Data.data.storeName}, gevestigd aan de {Data.data.storeAddress}, {Data.data.storeZip} {Data.data.storeCity}, KvK-nummer {Data.data.storeKvK}, vertegenwoordigd door {this.voorletter2} {this.tussen2 !== '' ? `${this.tussen2} ${this.achternaam2}` : this.achternaam2}, hierna te noemen: '<Text style={styles.bold}>werkgever</Text>';
                        </Text>

                        <Text style={styles.text}>en</Text>

                        <Text style={styles.text}>
                            {this.voorletter} {this.tussen !== '' ? `${this.tussen} ${this.achternaam}` : this.achternaam} wonende aan de {this.props._userData.info_adres} {this.props._userData.info_huisnummer}, {this.props._userData.info_postcode} {this.props._userData.info_plaats}, geboren op {moment(this.props._userData.info_geboorte).format('D-MM-YYYY')} te {this.props._userData.info_geboorteplaats}, hierna te noemen: '<Text style={styles.bold}>werknemer</Text>';
                        </Text>

                    </View>

                </View>

                <View style={styles.section}>

                    <Text style={styles.bold}>Overwegen het volgende:</Text>

                    <View style={[styles.marginLeft40, styles.marginTop20]}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>-&nbsp;</Text>
                            <Text style={styles.li2}>dat zich in het bedrijf van werkgever piekperioden kunnen voordoen, waarvoor werkgever een tijdelijke uitbreiding van zijn personeelsbestand nodig heeft</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>-&nbsp;</Text>
                            <Text style={styles.li2}>dat werkgever slechts van de arbeid van werknemer gebruik wenst te maken, indien en voor zover er sprake is van een piek in de werkzaamheden</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>-&nbsp;</Text>
                            <Text style={styles.li2}>dat werkgever en werknemer het erover eens zijn dat slechts loon betaald behoeft te worden indien en voor zover werknemer daadwerkelijk arbeid voor werkgever verricht</Text>
                        </View>

                    </View>

                </View>

                <View style={styles.section}>

                    <Text style={styles.bold}>Zijn overeengekomen:</Text>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel 1 - Datum indiensttreding en functie</Text>

                    <View style={styles.marginLeft20}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>1.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer treedt bij werkgever in dienst in de functie van {this.getFunctionName(this.props._userData.functie_id)}. De bij deze functie behorende werkzaamheden bestaan uit: {this.props._userData.contract_werkzaamheden}. Daarnaast bestaan de werkzaamheden uit alle werkzaamheden die redelijkerwijs van hem verlangd kunnen worden.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>2.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer zal op verzoek van werkgever werkzaamheden verrichten voor ondernemingen die gelieerd zijn aan de onderneming van werkgever.</Text>
                        </View>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel 2 - Duur van de overeenkomst</Text>

                    {this.tijdelijk === true ?

                        <View style={styles.marginLeft20}>

                            <View style={styles.liMain}>
                                <Text style={styles.li1}>1.&nbsp;</Text>
                                <Text style={styles.li2}>Deze arbeidsovereenkomst is aangegaan voor bepaalde tijd en vangt aan op {moment(this.props._userData.info_indienst).format('D-MM-YYYY')} en eindigt op {moment(this.props._userData.contract_eind).format('D-MM-YYYY')}.</Text>
                            </View>
                            <View style={styles.liMain}>
                                <Text style={styles.li1}>2.&nbsp;</Text>
                                <Text style={styles.li2}>Werkgever zegt werknemer bij voorbaat aan dat deze arbeidsovereenkomst voor bepaalde tijd - nadat die is geëindigd van rechtswege - niet zal worden voortgezet. Hiermee wordt voldaan aan de aanzegverplichting die werkgever heeft op grond van artikel 7:668 BW. De werknemer erkent door ondertekening van deze arbeidsovereenkomst dat werkgever aan zijn aanzegverplichting heeft voldaan. De werknemer kan geen aanspraak maken op de boete als bedoeld in artikel 7:668 lid 3 BW.</Text>
                            </View>

                        </View>

                    :

                        <View style={styles.marginLeft20}>

                            <View style={styles.liMain}>
                                <Text style={styles.li1}>1.&nbsp;</Text>
                                <Text style={styles.li2}>Deze arbeidsovereenkomst is aangegaan voor onbepaalde tijd en vangt aan op {moment(this.props._userData.info_indienst).format('D-MM-YYYY')}.</Text>
                            </View>
                            <View style={styles.liMain}>
                                <Text style={styles.li1}>2.&nbsp;</Text>
                                <Text style={styles.li2}>Partijen kunnen deze arbeidsovereenkomst schriftelijk opzeggen tegen het einde van de kalendermaand met inachtneming van de {this.cao === '' ? 'wettelijke opzegtermijn' : 'opzegtermijn zoals vastgelegd in de cao'}.</Text>
                            </View>

                        </View>
                    
                    }

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel 3 - Proeftijd</Text>

                    {this.proef !== '' ?
                    
                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>Er geldt een proeftijd van {this.proef}, te rekenen vanaf het moment van indiensttreding. Tijdens de proeftijd kan de arbeidsovereenkomst per direct, zonder opgave van redenen worden beëindigd door werkgever of werknemer.</Text>
                            
                        </View>

                    :

                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>Werkgever en werknemer spreken geen proeftijd met elkaar af.</Text>
                            
                        </View>
                
                    }

                    

                    {this.tijdelijk === true ?

                        <View>
                        
                            <Text style={[styles.bold, styles.marginTop20]}>Artikel 4 - Tussentijdse opzegging arbeidsovereenkomst</Text>

                            <View style={styles.marginLeft20}>

                                <View style={styles.liMain}>
                                    <Text style={styles.li1}>1.&nbsp;</Text>
                                    <Text style={styles.li2}>Werkgever en werknemer kunnen de arbeidsovereenkomst tussentijds opzeggen met inachtneming van de {this.cao === '' ? 'wettelijke opzegtermijn' : 'opzegtermijn zoals vastgelegd in de cao'}. De opzegging geschiedt tegen het einde van de maand.</Text>
                                </View>
                                <View style={styles.liMain}>
                                    <Text style={styles.li1}>2.&nbsp;</Text>
                                    <Text style={styles.li2}>Als werkgever tussentijds opzegt, dan moet hij beschikken over toestemming van het UWV (een ontslagvergunning).</Text>
                                </View>

                            </View>

                        </View>
                
                    : null}

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '5' : '4'} - Verplichting tot werken</Text>

                    <View style={styles.marginLeft20}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>1.&nbsp;</Text>
                            <Text style={styles.li2}>De werkgever is verplicht de werknemer voor werkzaamheden op te roepen met inachtneming van de termijn van vier dagen als bedoeld in artikel 7:628a lid 2 van het Burgerlijk Wetboek.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>2.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer is verplicht aan een oproep van werkgever gehoor te geven.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>3.&nbsp;</Text>
                            <Text style={styles.li2}>Bij een overeenkomst van minder dan 15 uur per week geldt voor iedere periode dat als de werknemer voor minder dan 3 uur per week is opgeroepen hij aanspraak kan maken op betaling van 3 uur, op basis van artikel 7:628a BW.</Text>
                        </View>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '6' : '5'} - CAO</Text>

                    {this.cao === '' ?

                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>Op de arbeidsovereenkomst is geen collectieve arbeidsovereenkomst (cao) van toepassing.</Text>

                        </View>

                    :

                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>Op de arbeidsovereenkomst is collectieve arbeidsovereenkomst (cao) {this.cao} van toepassing. Indien de bepalingen in de cao op enig moment gunstiger zijn dan de afspraken uit deze arbeidsovereenkomst, dan prevaleren de bepalingen van de cao. De werknemer ontvangt bij indiensttreding een exemplaar van de cao.</Text>

                        </View>

                    }

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '7' : '6'} - Plaats uitvoering werkzaamheden</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>De werknemer verricht de werkzaamheden in {this.props._userData.contract_waar}</Text>
                        
                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '8' : '7'} - Salaris en vakantietoeslag</Text>

                    <View style={styles.marginLeft20}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>1.&nbsp;</Text>
                            <Text style={styles.li2}>Het loon van werknemer bedraagt {this.brutoLoon} bruto per uur. Werknemer heeft daarnaast recht op vakantietoeslag van 8% over elk gewerkt uur over de periode {this.vacationMonthly === true ? 'vanaf 1 mei van het voorgaande jaar tot en met 30 april ' : this.meiJuni === '0' ? 'vanaf 1 mei van het voorgaande jaar tot en met 30 april ' : this.meiJuni === '1' ? 'vanaf 1 juni van het voorgaande jaar tot en met 31 mei ' : ''}van het desbetreffende jaar een pro rata gedeelte daarvan als de arbeidsovereenkomst niet de gehele genoemde periode heeft geduurd.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>2.&nbsp;</Text>
                            {this.vacationMonthly === true ?
                                <Text style={styles.li2}>De vakantietoeslag wordt uitbetaald naar verhouding per maand. De werkgever verstrekt per loonbetalingsperiode een loonspecificatie (loonstrook).</Text>
                            :
                                <Text style={styles.li2}>De vakantietoeslag wordt uitbetaald in de maand {this.meiJuni === '0' ? 'mei' : this.meiJuni === '1' ? 'juni' : this.props._userData.contract_vakantiegeld_wanneer3}. De werkgever verstrekt per loonbetalingsperiode een loonspecificatie (loonstrook).</Text>
                            }
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>3.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer heeft in de eerste 6 maanden van de overeenkomst uitsluitend recht op loon over de tijd dat daadwerkelijk arbeid is verricht, ook als na een aanvaarde oproep de werknemer de overeengekomen arbeid niet heeft kunnen verrichten wegens een oorzaak die voor rekening van de werkgever behoort te komen. Hierna komt het niet verrichten van arbeid door de werknemer na een oproep hiertoe wegens een oorzaak die voor rekening van de werkgever behoort te komen, voor rekening van de werkgever en heeft werknemer recht op de afgesproken beloning.</Text>
                        </View>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '9' : '8'} - Vakantiedagen</Text>

                    <View style={styles.marginLeft20}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>1.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer heeft - met behoud van salaris - per kalenderjaar recht op vakantie conform de daarvoor geldende regels van werkgever, naar rato van het aantal gewerkte dagen/uren. Uitgangspunt daarbij is een vakantie van 20 wettelijke vakantiedagen {this.bovenwettelijk > 0 ? `en ${this.bovenwettelijk} bovenwettelijke vakantiedag(en) ` : ''}op jaarbasis bij een fulltime dienstverband.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>2.&nbsp;</Text>
                            <Text style={styles.li2}>De wettelijke vakantiedagen, 20 dagen op basis van een voltijd dienstverband, mogen worden meegenomen naar het nieuwe jaar en hebben een vervaltermijn van 6 maanden na het opbouwjaar.{this.bovenwettelijk > 0 ? ` De bovenwettelijke dagen, ${this.bovenwettelijk} dag(en) op basis van een voltijd dienstverband, hebben een verjaringstermijn van 5 jaar na het opbouwjaar.` : ''}</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>3.&nbsp;</Text>
                            <Text style={styles.li2}>Het is de bedoeling dat alle voor een jaar toegekende dagen in het betreffende jaar worden opgenomen.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>4.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer dient bij het opnemen van vakantie de vakantieperiode binnen zijn team af te stemmen.</Text>
                        </View>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '10' : '9'} - Ziekte en arbeidsongeschiktheid</Text>

                    <View style={styles.marginLeft20}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>1.&nbsp;</Text>
                            <Text style={styles.li2}>Indien werknemer als gevolg van ziekte niet in staat is de overeengekomen werkzaamheden te verrichten, is hij, ook tijdens vakantie, verplicht dit zo snel mogelijk maar in ieder geval voor de afgesproken begintijden bij werkgever te melden, tenzij dat door overmacht niet mogelijk is.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>2.&nbsp;</Text>
                            <Text style={styles.li2}>Indien en voor zover er tijdens ziekte enig recht op loon bestaat, heeft werknemer tijdens de looptijd van de arbeidsovereenkomst recht op doorbetaling, minimaal 70% van het bij de wet bepaalde deel van zijn brutoloon over de perioden waarover werkgever op grond van de wet tot loondoorbetaling is gehouden.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>3.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer verklaart op de hoogte te zijn van en in te stemmen met de door werkgever vastgestelde voorschriften in verband met ziekmelding en controle.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>4.&nbsp;</Text>
                            <Text style={styles.li2}>De werknemer heeft gedurende de eerste twee dagen van een ziekteperiode geen recht op loon. Wordt de werknemer na het einde van een ziekteperiode binnen vier weken nogmaals ziek, dan behoudt hij op dat moment wel zijn recht op loon.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>5.&nbsp;</Text>
                            <Text style={styles.li2}>Werknemer heeft geen aanspraak op de in dit artikel vermelde loondoorbetaling indien een van de situaties zoals omschreven in artikel 7:629 lid 3 BW van toepassing is.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>6.&nbsp;</Text>
                            <Text style={styles.li2}>Werkgever is bevoegd de in dit artikel vermelde loondoorbetaling op te schorten voor de periode dat werknemer zich niet houdt aan door werkgever schriftelijk gegeven redelijke voorschriften over het verstrekken van inlichtingen die werkgever nodig heeft om het recht op loon vast te stellen.</Text>
                        </View>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '11' : '10'} - Onkostenvergoeding</Text>

                    {this.onkosten !== '' ?
                    
                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>De werknemer heeft recht op een onkostenvergoeding. Deze vergoeding geldt als tegemoetkoming in:</Text>

                            <View style={styles.liMain}>
                                <Text style={styles.li1}>-&nbsp;</Text>
                                <Text style={styles.li2}>{this.onkosten}</Text>
                            </View>

                        </View>

                    :

                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>De werknemer heeft geen recht op een onkostenvergoeding.</Text>

                        </View>
                
                    }

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '12' : '11'} - Pensioen</Text>

                    {this.pensioen === '' ?

                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>Er is geen verplichte pensioenregeling.</Text>

                        </View>

                    :

                        <View style={styles.marginLeft20}>

                            <Text style={styles.text}>Werknemer valt onder de verplichte pensioenregeling van {this.pensioen}. Werkgever informeert werknemer over de inhoud en de toetredingsvoorwaarden van het pensioen.</Text>

                        </View>

                    }

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '13' : '12'} - Geheimhoudingsplicht</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>De werknemer is verplicht tot geheimhouding van alle gegevens over het bedrijf, de bedrijfsvoering en klanten van de werkgever waarvan hij weet of redelijkerwijze kan vermoeden dat deze vertrouwelijk zijn. Deze verplichting geldt ook na beëindiging van deze arbeidsovereenkomst. Werknemer is tevens verplicht om alle documenten of andere gegevens die hij heeft verkregen in het kader van zijn werkzaamheden na afloop van de arbeidsovereenkomst aan werkgever over te dragen.</Text>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '14' : '13'} - Boetebeding</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>Indien de werknemer in strijd met voornoemde geheimhoudingsverplichting handelt, zal de werknemer in afwijking van artikel 7:650 leden 3, 4 en 5 BW aan de werkgever, zonder dat enige ingebrekestelling is vereist, voor iedere overtreding een boete verbeuren ten bedrage van € 25 en € 5 voor iedere dag dat de overtreding voortduurt. Indien het loon van werknemer gelijk is aan het minimumloon zal de boete ten goede komen van een door werkgever nader te bepalen goed doel. Indien het loon van werknemer hoger is dan het minimum dagloon, komt de boete toe aan de werkgever. In plaats daarvan is de werkgever gerechtigd om volledige schadevergoeding te vorderen.</Text>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '15' : '14'} - Intellectuele eigendom</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>Aan werkgever komt in binnen- en buitenland het intellectuele eigendomsrecht toe voortvloeiende uit de werkzaamheden van de werknemer tijdens de arbeidsovereenkomst en gedurende een jaar na afloop daarvan. Werknemer zal desgevraagd alle mededelingen doen en formaliteiten verrichten die noodzakelijk zijn om werkgever in staat te stellen de rechten op zijn naam te verkrijgen, dit laatste zo nodig na aanvankelijke aanvraag op naam van werknemer, door overdracht op naam van werkgever.</Text>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '16' : '15'} - Arbeids- en bedrijfsregels</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>Werknemer verklaart op de hoogte te zijn van en in te stemmen met de bij werkgever geldende arbeids- en bedrijfsregels en een exemplaar van deze arbeids- en bedrijfsregels te hebben ontvangen.</Text>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '17' : '16'} - Bedrijfskleding</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>Indien dit voor de functie noodzakelijk is, geeft de werkgever de werknemer bedrijfskleding in bruikleen voor de tijd dat het dienstverband voortduurt. Werknemer verplicht zich deze bedrijfskleding met zorg te behandelen en alleen te dragen bij de uitoefening van zijn werkzaamheden bij {Data.data.storeName}.</Text>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '18' : '17'} - Wijzigingsbeding</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>Werkgever behoudt zich het recht voor om eenzijdig de arbeidsvoorwaarden te ­wijzigen, met inachtneming van hetgeen bepaald is in artikel 7:613 BW.</Text>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '19' : '18'} - Toepasselijk recht en bevoegde rechter</Text>

                    <View style={styles.marginLeft20}>

                        <View style={styles.liMain}>
                            <Text style={styles.li1}>1.&nbsp;</Text>
                            <Text style={styles.li2}>Op deze overeenkomst is Nederlands recht van toepassing.</Text>
                        </View>
                        <View style={styles.liMain}>
                            <Text style={styles.li1}>2.&nbsp;</Text>
                            <Text style={styles.li2}>Bij eventuele geschillen is de rechtbank in het arrondissement waar de arbeid normaal verricht wordt bevoegd.</Text>
                        </View>

                    </View>

                    <Text style={[styles.bold, styles.marginTop20]}>Artikel {this.tijdelijk === true ? '20' : '19'} - Ontvangst exemplaar arbeidscontract</Text>

                    <View style={styles.marginLeft20}>

                        <Text style={styles.text}>Werknemer verklaart een getekend exemplaar van dit contract te hebben ont­vangen.</Text>

                    </View>

                </View>

            </Page>

            <Page size="A4" style={styles.page}>

            <View style={styles.section}>

                <Text style={[styles.bold, styles.marginTop20]}>Aldus getekend:</Text>

                <Text style={[styles.bold, styles.marginTop100]}>{this.voorletter} {this.tussen !== '' ? `${this.tussen} ${this.achternaam}` : this.achternaam}</Text>

                <Text style={[styles.bold, styles.marginTop100]}>{this.voorletter2} {this.tussen2 !== '' ? `${this.tussen2} ${this.achternaam2}` : this.achternaam2}</Text>

            </View>

            </Page>

        </Document>
                
    );
  }
  
}

export default NulUren;