/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './salaries.css';
import '../times/times.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';
import '../../employees/employees.css';
import '../hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import APIGET from '../../../classes/global/APIGET';
import Constants from '../../../constants/Constants';
import Colors from '../../../constants/Colors';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIADD from '../../../classes/global/APIADD';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAllContracts from '../../../classes/APIGetAllContracts';
import GlobalAPIGetTime from '../../../classes/GlobalAPIGetTime';

class Salaries extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    // from params pathname
    this.paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : null;

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      menu: this.paramMenu !== null ? this.paramMenu : 'table',

      salaries: {},
      salariesArray: [],
      emptySalaries: [],

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      contracts: {},
      accounts: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

      errorText: '',
      showError: false,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    const accounts = await APIGetAllAccounts.Request();
    const contracts = await APIGetAllContracts.Request();
    const contractsObj = Constants.convertToObject(contracts, 'contract_info_id');

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');

    this.setState({salaries: salariesObj, salariesArray: salaries, accounts: accounts, contracts: contractsObj, loading: false});

  }

  deleteAlert(data) {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je het uurloon van {data.uurloon_leeftijd} jaar wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteSalary.bind(this, data),
      showAlert: true,
    });

  }

  async deleteSalary(data) {

    this.setState({reloading: true});

    await APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${data.uurloon_id}`);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');

    this.setState({salaries: salariesObj, salariesArray: salaries, reloading: false, showError: false });

  }

  applyAlert() {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je de toegevoegde uurlonen wilt toepassen op alle medewerkers? Hiermee worden de huidige uurlonen van medewerkers overschreven door de bedragen in de uurloontabel.<br /><br />

          Indien je het uurloon van een medewerker zelf wijzigt, wordt dit niet meer bijgehouden door de uurloontabel

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.applySalary.bind(this),
      showAlert: true,
    });

  }

  async applySalary() {

    if (this.checkValidations() === false) {
      return;
    }

    // SAVE THE CURRENT VALUES

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.salaries) {
      if ((isNaN(parseInt(this.state.salaries[key].uurloon_leeftijd)) || this.state.salaries[key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.salaries[key].uurloon_hoeveelheid)) || this.state.salaries[key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.salaries[key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.salaries[key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');

    // DONE RELOADING AND SAVING

    const [
      accounts, 
      contracts, 
      dateNow,
    ] = await Promise.all(
    [
      APIGetAllAccounts.Request(),
      APIGetAllContracts.Request(),
      Constants.getDateNow(),
    ]);

    let contractsObj = Constants.convertToObject(contracts, 'contract_info_id');

    // promises
    let promisesSalary = [];

    for (const acc of accounts) {

      const age = Constants.getAge(dateNow, Constants.stringToDate(acc.info_geboorte));
      let salary = 0;
      let foundSalary = false;
      for (const s of salaries) {
        if (parseInt(s.uurloon_leeftijd) === age) {
          salary = parseFloat(s.uurloon_hoeveelheid);
          foundSalary = true;
          break;
        }
      }

      if (foundSalary === false) {
        if (age < parseInt(salaries[0].uurloon_leeftijd)) {
          salary = parseFloat(salaries[0].uurloon_hoeveelheid);
        } else if (age > parseInt(salaries[salaries.length - 1].uurloon_leeftijd)) {
          salary = parseFloat(salaries[salaries.length - 1].uurloon_hoeveelheid);
        }
      }

      if (typeof contractsObj[acc.info_id] === 'undefined') {
        promisesSalary.push(APIADD.Request(`INSERT INTO Contract (contract_info_id, contract_bruto_uurloon)`, `VALUES (${acc.info_id}, ${salary})`));
      } else {
        promisesSalary.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${salary}`, `WHERE contract_id = ${acc.info_id}`));
      }

    }

    await Promise.all(promisesSalary);

    const contracts2 = await APIGetAllContracts.Request();
    const contractsObj2 = Constants.convertToObject(contracts2, 'contract_info_id');

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], contracts: contractsObj2, reloading: false });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  onChangeNumberDataFloat(data, min, max, event, key, filled) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (data === 'salary') {

      if (filled === true) {

        let salaries = this.state.salaries;

        if (nr !== '') {
          salaries[key].uurloon_hoeveelheid = nr;
        } else {
          salaries[key].uurloon_hoeveelheid = '';
        }

        this.setState({ salaries: salaries });

      } else { // key = index of array here

        let salaries = this.state.emptySalaries;

        if (nr !== '') {
          salaries[key].amount = nr;
        } else {
          salaries[key].amount = '';
        }

        this.setState({ emptySalaries: salaries });

      }

    } else if (data === 'salaryUser') {

      let contracts = this.state.contracts;

      const c = contracts[key];

      if (typeof c === 'undefined') {

        const c2 = contracts[key] = {};

        if (nr !== '') {
          c2.contract_bruto_uurloon = nr;
        } else {
          c2.contract_bruto_uurloon = '';
        }

        this.setState({ contracts: contracts });

      } else {

        if (nr !== '') {
          c.contract_bruto_uurloon = nr;
        } else {
          c.contract_bruto_uurloon = '';
        }

        this.setState({ contracts: contracts });

      }

    }

  }

  onChangeNumberDataInt(data, min, max, event, key, filled) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (data === 'age') {

      if (filled === true) {

        let salaries = this.state.salaries;

        if (nr !== '') {
          salaries[key].uurloon_leeftijd = parseInt(nr).toString();
        } else {
          salaries[key].uurloon_leeftijd = '';
        }

        this.setState({ salaries: salaries });

      } else { // key = index of array here

        let salaries = this.state.emptySalaries;

        if (nr !== '') {
          salaries[key].age = parseInt(nr).toString();
        } else {
          salaries[key].age = '';
        }

        this.setState({ emptySalaries: salaries });

      }

    }

  }

  renderSalaries() {

    let toRender = [];

    if (Object.keys(this.state.salaries).length > 0) {

      toRender.push(

        <div className='salaries_salaryContainer' style={{marginBottom: '0.3vw', marginTop: '1vw'}}>

          <span style={{fontSize: '1vw'}}>Leeftijd</span>

          <span style={{fontSize: '1vw'}}>Uurloon</span>

        </div>

      );

    }

    for (const key in this.state.salaries) {

      const s = this.state.salaries[key];

      toRender.push(

        <div key={key} className='salaries_salaryContainer'>

          <input 
            className={'inputField2'}
            min={1}
            max={200}
            value={s.uurloon_leeftijd}
            onChange={(event) => this.onChangeNumberDataInt('age', 1, 200, event, key, true)}
            placeholder={'Leeftijd'}
            type={'number'}
            step={1}
            style={{marginTop: 0, width: '15%'}}
          />

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

            <i className='salaries_delButton fal fa-trash-alt' style={{marginRight: '1vw', fontSize: '1vw'}} onClick={() => this.deleteAlert(s)} />

            <input 
              className={'inputField2'}
              min={0}
              max={999999999}
              value={s.uurloon_hoeveelheid}
              onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event, key, true)}
              placeholder={'Uurloon'}
              type={'number'}
              step={0.01}
              style={{marginTop: 0, width: '100%'}}
            />
           
          </div>

        </div>

      );

    }

    return toRender;

  }

  renderEmptySalaries() {

    let toRender = [];

    if (this.state.emptySalaries.length > 0) {

      toRender.push(

        <div className='salaries_salaryContainer' style={{marginBottom: '0.3vw', marginTop: '1vw'}}>

          <span style={{fontSize: '1vw'}}>Leeftijd</span>

          <span style={{fontSize: '1vw'}}>Uurloon</span>

        </div>

      );

    }

    for (let index in this.state.emptySalaries) {

      const e = this.state.emptySalaries[index];

      toRender.push(

        <div key={index + 'e'} className='salaries_salaryContainer'>

          <input 
            className={'inputField2'}
            min={1}
            max={200}
            value={e.age}
            onChange={(event) => this.onChangeNumberDataInt('age', 1, 200, event, index, false)}
            placeholder={'Leeftijd'}
            type={'number'}
            step={1}
            style={{marginTop: 0, width: '15%'}}
          />

          <input 
            className={'inputField2'}
            min={0}
            max={999999999}
            value={e.amount}
            onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event, index, false)}
            placeholder={'Uurloon'}
            type={'number'}
            step={0.01}
            style={{marginTop: 0, width: '35%'}}
          />

        </div>

      );

    }

    return toRender;

  }

  addRow() {

    let emptySalaries = this.state.emptySalaries;
    emptySalaries.push({age: '', amount: ''});

    this.setState({ emptySalaries: emptySalaries });

  }

  checkValidations() {

    let newSalaries = [];
    let newEmptySalaries = [];

    // first check salaries
    for (let key in this.state.salaries) {

      if (Constants.objectArrayContainsKey(newSalaries, 'uurloon_leeftijd', this.state.salaries[key].uurloon_leeftijd) === false) {
        newSalaries.push(this.state.salaries[key]);
      } else {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.salaries[key].uurloon_leeftijd} jaar is al toegevoegd`
        });
        return false;
      }

    }

    // then check emptysalaries duplications
    for (let key in this.state.emptySalaries) {

      if (Constants.objectArrayContainsKey(newEmptySalaries, 'age', this.state.emptySalaries[key].age) === false) {
        newEmptySalaries.push(this.state.emptySalaries[key]);
      } else {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.emptySalaries[key].age} jaar kan niet 2x toegevoegd worden`
        });
        return false;
      }

    }

    // lastly check salaries and empty
    for (let key in this.state.emptySalaries) {

      if (Constants.objectArrayContainsKey(newSalaries, 'uurloon_leeftijd', this.state.emptySalaries[key].age) === true) {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.emptySalaries[key].age} jaar bestaat al`
        });
        return false;
      }

    }

    return true;

  }

  async saveSalaries() {

    if (this.checkValidations() === false) {
      return;
    }

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.salaries) {
      if ((isNaN(parseInt(this.state.salaries[key].uurloon_leeftijd)) || this.state.salaries[key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.salaries[key].uurloon_hoeveelheid)) || this.state.salaries[key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.salaries[key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.salaries[key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], reloading: false });

  }

  chooseMenu(menu) {

    if (menu === this.state.menu || this.state.reloading === true || this.state.loading === true) {
      return;
    }

    this.setState({
      menu: menu,
    });

  }

  accountBarsSalary() {

    let toReturn = [];

    for (const acc of this.state.accounts) {

      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

      const hasSalary = typeof this.state.contracts[acc.info_id] !== 'undefined'
                          && this.state.contracts[acc.info_id].contract_bruto_uurloon !== null 
                          && this.state.contracts[acc.info_id].contract_bruto_uurloon !== '';

      const salary = hasSalary === true ? parseFloat(this.state.contracts[acc.info_id].contract_bruto_uurloon) : '';

      toReturn.push(

        <div key={acc.info_id} className={'myHours_barContainer'}>

          <div className={'myHours_barRow2'} style={{flex: 3}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow2'}>
            {/* {hasSalary === true ?
              <span style={{fontWeight: 'bold'}}>{this.formatter.format(Math.round((salary + Number.EPSILON) * 100) / 100)}</span>
            :
              <span className='defaultLink'>Uurloon toevoegen</span>
            } */}
            <input 
              className={'inputField2'}
              min={0}
              max={999999999}
              value={typeof this.state.contracts[acc.info_id] !== 'undefined' ? salary : hasSalary === true ? salary : ''}
              onChange={(event) => this.onChangeNumberDataFloat('salaryUser', 0, 999999999, event, acc.info_id)}
              placeholder={'Uurloon'}
              type={'number'}
              step={0.01}
              style={{marginTop: 0}}
            />
          </div>

        </div>

      );
      
    }

    return toReturn;

  }

  async saveAccountSalaries() {

    this.setState({ reloading: true });

    const savedContracts = await APIGetAllContracts.Request();
    const savedContractsObj = Constants.convertToObject(savedContracts, 'contract_info_id');

    let promises = [];

    for (const key in this.state.contracts) {

      const c = this.state.contracts[key];

      if (typeof savedContractsObj[key] === 'undefined') {
        if (isNaN(parseFloat(c.contract_bruto_uurloon)) || c.contract_bruto_uurloon === '') {
          continue;
        } else {
          promises.push(APIADD.Request(`INSERT INTO Contract (contract_info_id, contract_bruto_uurloon)`, `VALUES (${key}, ${parseFloat(c.contract_bruto_uurloon)})`));
        }
      } else {
        if (isNaN(parseFloat(c.contract_bruto_uurloon)) || c.contract_bruto_uurloon === '') {
          promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_bruto_uurloon = NULL`, `WHERE contract_info_id = ${c.contract_info_id}`));
        } else {
          promises.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${parseFloat(c.contract_bruto_uurloon)}`, `WHERE contract_id = ${c.contract_info_id}`));
          
        }
      }

    }

    await Promise.all(promises);

    const savedContracts2 = await APIGetAllContracts.Request();
    const savedContractsObj2 = Constants.convertToObject(savedContracts2, 'contract_info_id');

    this.setState({ contracts: savedContractsObj2, reloading: false });
    
  }

  render() {

    return (
        <div className='salaries'>

          <Navbar history={this.props.history} active={'salaries'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          {this.state.showAlert === true ?
                
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null
              
          }

          <div className='planner_nav2_fixed fixedNav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              {this.state.menu === 'table' ?
                'Uurloontabel'
              :
                'Uurlonen medewerkers'
              }
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
                {/* <i className='fa fa-plus'></i> */}
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          <div className='finances_main'>
            
            <div className='finances_menu'>

              <div className='finances_menuTitle'>
                <div>Menu</div>
              </div>
                
              <div className={`finances_menuButton ${this.state.menu === 'table' ? 'activeAv' : ''}`} onClick={() => this.chooseMenu('table')}>
                <div>Tabel</div>
              </div>

              <div className={`finances_menuButton ${this.state.menu === 'salary' ? 'activeAv' : ''}`} onClick={() => this.chooseMenu('salary')}>
                <div>Uurlonen</div>
              </div>

            </div>

            <div id='financesContentScroll' className='finances_content' style={{paddingLeft: 0, paddingRight: 0, width: '90%'}}>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='salaries_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.menu === 'table' ?

                <div>

                  <div className='salaries_buttonContainer'>

                    <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveSalaries()}>Opslaan</span>

                  </div>

                  {this.state.salariesArray.length > 0 ?

                    <div className='salaries_buttonContainer_apply'>

                      <span style={{fontSize: '1vw'}}>De uurlonen toepassen op alle medewerkers</span>

                      <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.applyAlert()}>Toepassen</span>

                    </div>

                  : null}

                  {this.state.showError === true ?
                    <span style={{fontSize: '0.8vw', color: Colors.color.redFilledIn, marginBottom: '1vw'}}>{this.state.errorText}</span>
                  : null}

                  {this.state.salariesArray.length > 0 ?
                    <span style={{fontSize: '1.2vw', fontWeight: 'bold'}}>Toegevoegde uurlonen</span>
                  : 
                    <span style={{fontSize: '1vw'}}>Je hebt nog geen standaard uurlonen toegevoegd</span>
                  }

                  {this.state.salariesArray.length > 1 ?
                    <div style={{marginTop: '0.5vw'}}>
                      <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.salariesArray[0].uurloon_leeftijd}</b> jaar of jonger krijgen een uurloon van {this.formatter.format(this.state.salariesArray[0].uurloon_hoeveelheid)}</div>
                      <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.salariesArray[this.state.salariesArray.length - 1].uurloon_leeftijd}</b> jaar of ouder krijgen een uurloon van {this.formatter.format(this.state.salariesArray[this.state.salariesArray.length - 1].uurloon_hoeveelheid)}</div>
                    </div>
                  : null}

                  {this.state.salariesArray.length === 1 ?
                    <div style={{marginTop: '0.5vw'}}>
                      <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.salariesArray[this.state.salariesArray.length - 1].uurloon_leeftijd}</b> jaar en jonger of ouder krijgen een uurloon van {this.formatter.format(this.state.salariesArray[this.state.salariesArray.length - 1].uurloon_hoeveelheid)}</div>
                    </div>
                  : null}

                  {this.renderSalaries()}

                  <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginTop: '2vw'}}>Nieuw uurloon toevoegen</div>

                  <div className='salaries_buttonContainer_add'>

                    <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.addRow()}>Toevoegen</span>

                  </div>

                  {this.renderEmptySalaries()}

                </div>

              : 
              this.state.menu === 'salary' ?

                <div>

                  <div className={'employees_content_header stickyTopMenuContent2'} style={{backgroundColor: Colors.color.backgroundColor}}>

                    <div className='employees_content_header_row'>

                        <div className='employees_content_header_1'>
                              
                          {null}

                        </div>

                        <div className='employees_content_header_2'>

                            <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveAccountSalaries()}>Opslaan</span>

                        </div>

                    </div>

                    {/* {this.showError()} */}

                  </div>

                  <div className={'hoursRegistration_barContainerMain stickyTopHoursRegistration3'}>
                    <div className={'myHours_barRowMain'} style={{flex: 3}}>
                      <div>Naam</div>
                    </div>
                    <div className={'myHours_barRowMain'}>
                      <div>Bruto uurloon</div>
                    </div>
                  </div>

                  {this.accountBarsSalary()}

                </div>

              :
              
                null
              
              }

            </div>
        
          }

          </div>

          </div>

        </div>
    );
  }
  
}

export default Salaries;