/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './deadlines.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import APIGetDeadlines from '../../../classes/APIGetDeadlines';

import 'react-datepicker/dist/react-datepicker.css';
import Constants from '../../../constants/Constants';
import DeadlinesModal from './deadlinesModal';
import APIGetDeadlinesAbsence from '../../../classes/APIGetDeadlinesAbsence';

class DeadlinesView extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      deadlines: [],
      deadlinesAbsence: [],

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalType: '',

      timeNow: new Date(),

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    this.setState({
        timeNow: await Constants.getDateNow(),
    });

    await this.getDeadlines();
    //await this.getDeadlinesAbsence();

    this.setState({loading: false});

  }

  openDeadlinesModal(mode, data, type) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
      modalType: type,
    });

  }

  async getDeadlines() {

    let deadlines = await APIGetDeadlines.Request();

    if(deadlines === 'error') {

        deadlines = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(deadlines === null) {

      deadlines = [];

    }

    this.setState({ deadlines: deadlines });

  }

  async getDeadlinesAbsence() {

    let deadlines = await APIGetDeadlinesAbsence.Request();

    if(deadlines === 'error') {

        deadlines = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    this.setState({ deadlinesAbsence: deadlines });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    this.setState({
        timeNow: await Constants.getDateNow(),
    });

    await this.getDeadlines();
    //await this.getDeadlinesAbsence();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getDeadlineBars() {

    let deadlineBars = [];

    for(let key in this.state.deadlines) {

      const startDate = Constants.stringToDate(this.state.deadlines[key].deadline_begin);
      const endDate = Constants.stringToDate(this.state.deadlines[key].deadline_eind);
      const deadlineDate = Constants.getDateTZ(this.state.deadlines[key].deadline_datum, this.state.deadlines[key].deadline_tijd); //NEEDEDDDD TO COMPARE TIMES converttoUTC if on other server than netherlands

      const startDay = startDate.getDate();
      //const startMonth = startDate.getMonth(); //jan = 0
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      //const endMonth = endDate.getMonth(); //jan = 0
      const endYear = endDate.getFullYear();

      const deadlineDay = deadlineDate.getUTCDate();
      const deadlineMonth = deadlineDate.getUTCMonth(); //jan = 0
      const deadlineYear = deadlineDate.getUTCFullYear();

      const today = this.state.timeNow;

      deadlineBars.push(
        <div className={'deadlines_deadlineRow2'}>

          Deadline: {deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.state.deadlines[key].deadline_tijd.substring(0,5)}

        </div>
      );
          
      if(today < deadlineDate && today.getUTCDate() === deadlineDay && today.getUTCMonth() === deadlineMonth && today.getUTCFullYear() === deadlineYear) {

        deadlineBars.push(
            <div className={'deadlines_deadlineContainer'} style={{borderColor: Colors.color.redFilledIn, cursor: 'default'}} onClick={() => this.openDeadlinesModal(1, this.state.deadlines[key])}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      } else if(today.getTime() > deadlineDate.getTime()) {

        deadlineBars.push(
            <div className={'deadlines_deadlineContainer'} style={{borderColor: Colors.color.pastBorderColor, backgroundColor: Colors.color.pastBackgroundColor, cursor: 'default'}} onClick={() => this.openDeadlinesModal(1, this.state.deadlines[key])}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      } else if((deadlineDay - today.getUTCDate()) <= 7 && deadlineMonth === today.getUTCMonth() && deadlineYear === today.getUTCFullYear()) {

        deadlineBars.push(
            <div className={'deadlines_deadlineContainer'} style={{borderColor: Colors.color.orangeVacation, cursor: 'default'}} onClick={() => this.openDeadlinesModal(1, this.state.deadlines[key])}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      } else {

        deadlineBars.push(
            <div style={{cursor: 'default'}} className={'deadlines_deadlineContainer'} onClick={() => this.openDeadlinesModal(1, this.state.deadlines[key])}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      }

    }

    return deadlineBars;

  }

  getDeadlineBarsAbsence() {

    let deadlineBars = [];

    for(let key in this.state.deadlinesAbsence) {

      const startDate = Constants.stringToDate(this.state.deadlinesAbsence[key].deadlineverlof_begin);
      const endDate = Constants.stringToDate(this.state.deadlinesAbsence[key].deadlineverlof_eind);
      const deadlineDate = Constants.getDateTZ(this.state.deadlinesAbsence[key].deadlineverlof_datum, this.state.deadlinesAbsence[key].deadlineverlof_tijd); //NEEDEDDDD TO COMPARE TIMES converttoUTC if on other server than netherlands

      const startDay = startDate.getDate();
      //const startMonth = startDate.getMonth(); //jan = 0
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      //const endMonth = endDate.getMonth(); //jan = 0
      const endYear = endDate.getFullYear();

      const deadlineDay = deadlineDate.getUTCDate();
      const deadlineMonth = deadlineDate.getUTCMonth(); //jan = 0
      const deadlineYear = deadlineDate.getUTCFullYear();

      const today = this.state.timeNow;

      deadlineBars.push(
        <div className={'deadlines_deadlineRow2'}>

          Deadline: {deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} om {this.state.deadlinesAbsence[key].deadlineverlof_tijd.substring(0,5)}

        </div>
      );
          
      if(today < deadlineDate && today.getDate() === deadlineDay && today.getMonth() === deadlineMonth && today.getFullYear() === deadlineYear) {

        deadlineBars.push(
            <div className={'deadlines_deadlineContainer'} style={{borderColor: Colors.color.redFilledIn, cursor: 'default'}}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      } else if(today.getTime() > deadlineDate.getTime()) {

        deadlineBars.push(
            <div className={'deadlines_deadlineContainer'} style={{borderColor: Colors.color.pastBorderColor, backgroundColor: Colors.color.pastBackgroundColor, cursor: 'default'}}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      } else if((deadlineDay - today.getDate()) <= 7 && deadlineMonth === today.getMonth() && deadlineYear === today.getFullYear()) {

        deadlineBars.push(
            <div className={'deadlines_deadlineContainer'} style={{borderColor: Colors.color.orangeVacation, cursor: 'default'}}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      } else {

        deadlineBars.push(
            <div style={{cursor: 'default'}} className={'deadlines_deadlineContainer'}>

              <div className={'deadlines_deadlineRow'}>

                <div className={'deadlines_deadlineRowSub1'}>

                  Periode:

                </div>

                <div className={'deadlines_deadlineRowSub2'}>

                  <i>{startDay} {Constants.getMonthName(startDate)} {startYear} t/m {endDay} {Constants.getMonthName(endDate)} {endYear}</i>

                </div>

              </div>

            </div>
          );

      }

    }

    return deadlineBars;

  }

  render() {

    const deadlineBars = this.getDeadlineBars();
    const deadlineBarsAbsence = this.getDeadlineBarsAbsence();

    return (
        <div className='deadlines'>

          <Navbar history={this.props.history} active={'all_deadlines'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Alle deadlines
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
                
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='deadlines_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {/* {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              } */}

              <div className='deadlines_subContainer'>

                <div className='deadlines_dataContainerTitle'>
                  <span>Beschikbaarheid opgeven</span>
                </div>

                {this.state.deadlines.length === 0 ?
                  
                  <div className={'textInMiddle'}>
                      <div>Geen deadlines</div>
                  </div>

                :

                  deadlineBars
            
                }

              </div>

              {/* <div className='deadlines_subContainer' style={{marginLeft: '2.5%'}}>

                <div className='deadlines_dataContainerTitle'>
                  <span>Verlof aanvragen</span>
                </div>

                {this.state.deadlinesAbsence.length === 0 ?
                  
                  <div className={'textInMiddle'}>
                      <div>Geen deadlines</div>
                  </div>

                :

                  deadlineBarsAbsence
            
                }

              </div> */}

            </div>
        
          }

        </div>
    );
  }
  
}

export default DeadlinesView;