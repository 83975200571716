/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availableShiftsAdmin.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import APIGetAllTrades from '../../../classes/APIGetAllTrades';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetTeamPlanner from '../../../classes/APIGetTeamPlanner';
import BetterAlert from '../../../components/alert/alert';
import APIGetAllSkillSchedules from '../../../classes/APIGetAllSkillSchedules';
import APIGetAllSkillsUsers from '../../../classes/APIGetAllSkillsUsers';
import APIEditAvailableShift from '../../../classes/APIEditAvailableShift';
import APIGetAvailableShiftsAdmin from '../../../classes/APIGetAvailableShiftsAdmin';
import APIGetAcceptedShifts from '../../../classes/APIGetAcceptedShifts';
import KodictNotifications from '../../../constants/KodictNotifications';
import APITradeShift from '../../../classes/APITradeShift';
import APIDeleteFromAcceptedShift from '../../../classes/APIDeleteFromAcceptedShift';
import APIDeleteFromAvailableShift from '../../../classes/APIDeleteFromAvailableShift';
import APIGET from '../../../classes/global/APIGET';
import APIGetAllSkillDayShifts from '../../../classes/APIGetAllSkillDayShifts';
import APIADD from '../../../classes/global/APIADD';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIAddUsedDayShift from '../../../classes/APIAddUsedDayShift';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIGetAllPartSchedules from '../../../classes/APIGetAllPartSchedules';
import APIGetAllPartDayShifts from '../../../classes/APIGetAllPartDayShifts';

class AvailableShiftsAdmin extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.teamPlanner = [];

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      trades: [],
      accepted: [],
      acceptedPlanner: {},
      skillsUser: {},
      skillsSchedule: {},
      skillsDayShifts: {},
      partSchedules: {},
      partDayShifts: {},

      showModal: false,
      modalMode: 0,
      modalData: [],

      selected: null,
      selectedObject: null,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,
      alertButtonAction2: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    const accepted = await APIGetAcceptedShifts.Request();
    const acceptedPlannerObj = await APIGET.Request(
        `SELECT SBGeaccepteerd.*, ShiftBeschikbaar.*, DagDienst.*, f1.functie_naam AS fn1, f1.functie_id AS fid1, f2.functie_naam AS fn2, info_id, info_voornaam, info_achternaam, info_tussenvoegsel`,
        `FROM SBGeaccepteerd`,
        `LEFT JOIN ShiftBeschikbaar ON sbg_shb_id = shb_id LEFT JOIN DagDienst ON shb_dagdienst_id = dagdienst_id LEFT JOIN Functie AS f1 ON dagdienst_functie_id = f1.functie_id LEFT JOIN Info ON sbg_info_id = info_id LEFT JOIN Functie AS f2 ON info_functie_id = f2.functie_id`,
        null,
        null,
        `ORDER BY shb_team_id, shb_datum, dagdienst_begin, dagdienst_eind, sbg_id`
    );
    const acceptedPlanner = Constants.convertToObjectWithArrays(acceptedPlannerObj, 'dagdienst_id');

    this.teamPlanner = [];

    let availableShifts = await APIGetAvailableShiftsAdmin.Request();

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

        for (const trade of availableShifts) {

            const obj = await APIGetTeamPlanner.Request(trade.rooster_datum);

            for (const teampl of obj) {
                this.teamPlanner = [...this.teamPlanner, teampl];
            }

        }

        const oldTrades = availableShifts;
        availableShifts = [];

        for (const team of Data.data.ownTeams) {

            const arr = Constants.getTeamPlannerT(this.teamPlanner, oldTrades, parseInt(team.team_id));

            for (const teampl of arr) {
                availableShifts = [...availableShifts, teampl];
            }

        }

    }

    let lowestDate = null;
    let highestDate = null;

    for (const av of availableShifts) {

        const date = Constants.stringToDate(av.rooster_datum);

        if (lowestDate === null && highestDate === null) {
            lowestDate = date;
            highestDate = date;
        } else if (date.getTime() < lowestDate) {
            lowestDate = date;
        } else if (date.getTime() > highestDate) {
            highestDate = date;
        }

    }

    if (lowestDate !== null && highestDate !== null) {
        const skillsSchedule = await APIGetAllSkillSchedules.Request(Constants.dateToString(lowestDate), Constants.dateToString(highestDate));
        const skillSchedulesObj = Constants.convertToObjectWithArrays(skillsSchedule, 'rooster_id');

        const partSchedules = await APIGetAllPartSchedules.Request(Constants.dateToString(lowestDate), Constants.dateToString(highestDate));
        const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

        const partDayShifts = await APIGetAllPartDayShifts.Request();
        const partDayShiftsObj = Constants.convertToObject(partDayShifts, 'dagdienst_id');
        this.setState({
            skillsSchedule: skillSchedulesObj,
            partSchedules: partSchedulesObj,
            partDayShifts: partDayShiftsObj,
        });
    }

    const skillsUser = await APIGetAllSkillsUsers.Request();
    const skillsUserObj = Constants.convertToObjectWithArrays(skillsUser, 'info_id');

    const skillsDayShifts = await APIGetAllSkillDayShifts.Request();
    const skillsDayShiftsObj = Constants.convertToObjectWithArrays(skillsDayShifts, 'dagdienst_id');

    let newAvailableShifts = [];

    for (const shift of availableShifts) {

        let shift2 = {...shift, accepted: []};

        for (const acc of accepted) {
            if (acc.bdienst_id === shift.bdienst_id) {
                shift2.accepted.push(acc);
            }
        }

        if (shift2.accepted.length === 0) {
            await APIEditAvailableShift.Request(0, shift.bdienst_id);
        } else {
            newAvailableShifts.push(shift2);
        }

    }

    this.setState({
        loading: false,
        trades: newAvailableShifts,
        accepted: accepted,
        skillsUser: skillsUserObj,
        acceptedPlanner: acceptedPlanner,
        skillsDayShifts: skillsDayShiftsObj,
    });

    this.setState({loading: false});

  }

  async reloadScreen() {

    this.setState({reloading: true});

    const accepted = await APIGetAcceptedShifts.Request();
    const acceptedPlannerObj = await APIGET.Request(
        `SELECT SBGeaccepteerd.*, ShiftBeschikbaar.*, DagDienst.*, f1.functie_naam AS fn1, f1.functie_id AS fid1, f2.functie_naam AS fn2, info_id, info_voornaam, info_achternaam, info_tussenvoegsel`,
        `FROM SBGeaccepteerd`,
        `LEFT JOIN ShiftBeschikbaar ON sbg_shb_id = shb_id LEFT JOIN DagDienst ON shb_dagdienst_id = dagdienst_id LEFT JOIN Functie AS f1 ON dagdienst_functie_id = f1.functie_id LEFT JOIN Info ON sbg_info_id = info_id LEFT JOIN Functie AS f2 ON info_functie_id = f2.functie_id`,
        null,
        null,
        `ORDER BY shb_team_id, shb_datum, dagdienst_begin, dagdienst_eind, sbg_id`
    );
    const acceptedPlanner = Constants.convertToObjectWithArrays(acceptedPlannerObj, 'dagdienst_id');

    this.teamPlanner = [];

    let availableShifts = await APIGetAvailableShiftsAdmin.Request();

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

        for (const trade of availableShifts) {

            const obj = await APIGetTeamPlanner.Request(trade.rooster_datum);

            for (const teampl of obj) {
                this.teamPlanner = [...this.teamPlanner, teampl];
            }

        }

        const oldTrades = availableShifts;
        availableShifts = [];

        for (const team of Data.data.ownTeams) {

            const arr = Constants.getTeamPlannerT(this.teamPlanner, oldTrades, parseInt(team.team_id));

            for (const teampl of arr) {
                availableShifts = [...availableShifts, teampl];
            }

        }

    }

    // let lowestDate = null;
    // let highestDate = null;

    // for (const av of availableShifts) {

    //     const date = Constants.stringToDate(av.rooster_datum);

    //     if (lowestDate === null && highestDate === null) {
    //         lowestDate = date;
    //         highestDate = date;
    //     } else if (date.getTime() < lowestDate) {
    //         lowestDate = date;
    //     } else if (date.getTime() > highestDate) {
    //         highestDate = date;
    //     }

    // }

    // if (lowestDate !== null && highestDate !== null) {
    //     const skillsSchedule = await APIGetAllSkillSchedules.Request(lowestDate.toISOString().substr(0, 10), highestDate.toISOString().substr(0, 10));
    //     const skillSchedulesObj = Constants.convertToObjectWithArrays(skillsSchedule, 'rooster_id');
    //     this.setState({
    //         skillsSchedule: skillSchedulesObj,
    //     });
    // }

    // const skillsUser = await APIGetAllSkillsUsers.Request();
    // const skillsUserObj = Constants.convertToObjectWithArrays(skillsUser, 'info_id');

    // const skillsDayShifts = await APIGetAllSkillDayShifts.Request();
    // const skillsDayShiftsObj = Constants.convertToObjectWithArrays(skillsDayShifts, 'dagdienst_id');

    let newAvailableShifts = [];

    for (const shift of availableShifts) {

        let shift2 = {...shift, accepted: []};

        for (const acc of accepted) {
            if (acc.bdienst_id === shift.bdienst_id) {
                shift2.accepted.push(acc);
            }
        }

        if (shift2.accepted.length === 0) {
            await APIEditAvailableShift.Request(0, shift.bdienst_id);
        } else {
            newAvailableShifts.push(shift2);
        }

    }

    this.setState({
        trades: newAvailableShifts,
        accepted: accepted,
        //skillsUser: skillsUserObj,
        acceptedPlanner: acceptedPlanner,
        //skillsDayShifts: skillsDayShiftsObj,
    });

    this.reloadShift(newAvailableShifts);

    this.setState({reloading: false});

  }

  reloadShift(shifts) {

    let found = false;

    for (const shift of shifts) {

      if (this.state.selected === shift.bdienst_id) {
        found = true;
        this.setState({ selectedObject: shift });
      }

    }

     if (found === false) {
       this.setState({
         selected: null,
         selectedObject: null,
       });
     }

  }

  selectShift(shift, planner) {

    if (planner === true) {

      if ('planner' + shift[0].shb_id === this.state.selected) {

        this.setState({
          selected: null,
          selectedObject: null,
        });

      } else {

        this.setState({
          selected: 'planner' + shift[0].shb_id,
          selectedObject: shift,
        });

      }

    } else {

      if (shift.bdienst_id === this.state.selected) {

        this.setState({
          selected: null,
          selectedObject: null,
        });

      } else {

        this.setState({
          selected: shift.bdienst_id,
          selectedObject: shift,
        });

      }

    }

  }

  getAvailableShifts() {

    let trades = [];

    for(const sh of this.state.trades) {

      const date = Constants.stringToDate(sh.rooster_datum);

      let userFullName = 'Lege dienst';
      if (Constants.isEmpty(sh.info_id) === false) {
        userFullName = `${sh.info_voornaam} ${(sh.info_tussenvoegsel !== null && sh.info_tussenvoegsel !== '') ? sh.info_tussenvoegsel + " " + sh.info_achternaam.charAt(0) : sh.info_achternaam.charAt(0)}.`;
      }

      trades.push(

        <div key={sh.bdienst_id}>

          <div className='availableShiftsAdmin_avContainer' style={this.state.selected === sh.bdienst_id ? {border: '1px solid #47B7EB'} : null} onClick={() => this.selectShift(sh)}>

            <span style={{marginLeft: '2%', fontWeight: 'bold', marginBottom: '2%'}}>{date.getDate()} {Constants.getMonthNameM(date.getMonth())} {date.getFullYear()}</span>

            <div className='availableShiftsAdmin_avContainer_row'>

              <div className='availableShiftsAdmin_avContainer_sub1'>

                <span>Tijd:</span>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <span style={{marginTop: 1}}>Team:</span>

                : null}

                <span style={{marginTop: 1}}>Functie:</span>

                <span style={{marginTop: 4}}>Naam:</span>

                {typeof this.state.partSchedules[sh.rooster_id] !== 'undefined' ?
                  <span style={{marginTop: 4}}>Bijzonderheden:</span>
                : null}

                {this.getSkillsSchedule(sh.rooster_id) !== null ?
                  <span style={{marginTop: 4}}>Competenties:</span>
                : null}

              </div>

              <div className='availableShiftsAdmin_avContainer_sub2'>

                <span style={{fontWeight: 'bold'}}>{sh.rooster_begin.substr(0,5)} - {sh.rooster_eind.substr(0,5)}</span>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <span style={{marginTop: 1}}>{Constants.getTeamNameFromScheduleID(this.teamPlanner, sh.rooster_id)}</span>

                : null}

                <span style={{marginTop: 1}}>{sh.functie_naam}</span>

                <span style={{marginTop: 4}}>{userFullName}</span>

                {typeof this.state.partSchedules[sh.rooster_id] !== 'undefined' ?
                  <span style={{marginTop: 4, color: `#${this.state.partSchedules[sh.rooster_id].bijz_kleur}`}}>{this.state.partSchedules[sh.rooster_id].bijz_naam}</span>
                : null}

                {this.getSkillsSchedule(sh.rooster_id) !== null ?
                  <div style={{marginTop: 4}}>{this.getSkillsSchedule(sh.rooster_id)}</div>
                : null}

              </div>

            </div>

          </div>

        </div>
      );

    }

    return trades;

  }

  getAvailableShiftsPlanner() {

    let trades = [];

    for(const key in this.state.acceptedPlanner) {

      const sh = this.state.acceptedPlanner[key][0];

      const date = Constants.stringToDate(sh.shb_datum);
      //const userFullName = `${sh.info_voornaam} ${(sh.info_tussenvoegsel !== null && sh.info_tussenvoegsel !== '') ? sh.info_tussenvoegsel + " " + sh.info_achternaam.charAt(0) : sh.info_achternaam.charAt(0)}.`;

      trades.push(

        <div key={sh.shb_id}>

          <div className='availableShiftsAdmin_avContainer' style={this.state.selected === 'planner' + sh.shb_id ? {border: '1px solid #47B7EB'} : null} onClick={() => this.selectShift(this.state.acceptedPlanner[key], true)}>

            <span style={{marginLeft: '2%', fontWeight: 'bold', marginBottom: '2%'}}>{date.getDate()} {Constants.getMonthNameM(date.getMonth())} {date.getFullYear()}</span>

            <div className='availableShiftsAdmin_avContainer_row'>

              <div className='availableShiftsAdmin_avContainer_sub1'>

                <span>Tijd:</span>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <span style={{marginTop: 1}}>Team:</span>

                : null}

                <span style={{marginTop: 1}}>Functie:</span>

                {typeof this.state.partDayShifts[sh.dagdienst_id] !== 'undefined' ?
                  <span style={{marginTop: 1}}>Bijzonderheden:</span>
                : null}

                {this.getSkillsDayShifts(sh.dagdienst_id) !== null ?
                  <span style={{marginTop: 4}}>Competenties:</span>
                : null}

              </div>

              <div className='availableShiftsAdmin_avContainer_sub2'>

                <span style={{fontWeight: 'bold'}}>{sh.dagdienst_begin.substr(0,5)} - {sh.dagdienst_eind.substr(0,5)}</span>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <span style={{marginTop: 1}}>{Constants.getTeamNameFromTeamID(sh.shb_team_id)}</span>

                : null}

                <span style={{marginTop: 1}}>{sh.fn1}</span>

                {typeof this.state.partDayShifts[sh.dagdienst_id] !== 'undefined' ?
                  <span style={{marginTop: 1, color: `#${this.state.partDayShifts[sh.dagdienst_id].bijz_kleur}`}}>{this.state.partDayShifts[sh.dagdienst_id].bijz_naam}</span>
                : null}

                {this.getSkillsDayShifts(sh.dagdienst_id) !== null ?
                  <div style={{marginTop: 4}}>{this.getSkillsDayShifts(sh.dagdienst_id)}</div>
                : null}

              </div>

            </div>

          </div>

        </div>
      );

    }

    return trades;

  }

  getAcceptedUsers(shift) {

    let trades = [];

    for(const user of shift.accepted) {

      const userFullName = `${user.v1} ${(user.tv1 !== null && user.tv1 !== '') ? user.tv1 + " " + user.a1.charAt(0) : user.a1.charAt(0)}.`;

      trades.push(

        <div key={user.id1}>

          <div className='availableShiftsAdmin_avContainer2'>

            <div className='availableShiftsAdmin_avContainer_row'>

              <div className='availableShiftsAdmin_avContainer_sub1'>

                <span style={{marginTop: 4}}>Naam:</span>

                <span style={{marginTop: 4}}>Functie:</span>

                {this.getSkillsUser(user.id1) !== null ?
                    <span style={{marginTop: 4}}>Competenties:</span>
                : null}

              </div>

              <div className='availableShiftsAdmin_avContainer_sub2'>

                <span style={{marginTop: 4, fontWeight: 'bold'}}>{userFullName}</span>

                <span style={{marginTop: 4}}>{shift.functie_naam}</span>

                {this.getSkillsUser(user.id1) !== null ?
                    <span style={{marginTop: 4}}>{this.getSkillsUser(user.id1)}</span>
                : null}

              </div>

            </div>

            <div style={{marginLeft: '2%', marginTop: '2%', width: '96%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <span className='availableShiftsAdmin_button_green' onClick={() => this.acceptUserAlert(shift, user)}>Goedkeuren</span>
              <span className='availableShiftsAdmin_button_red' onClick={() => this.denyUserAlert(shift, user)}>Afwijzen</span>
            </div>

          </div>

        </div>
      );

    }

    return trades;

  }

  getAcceptedUsersPlanner(shift) {

    let trades = [];

    for(const user of shift) {

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam.charAt(0) : user.info_achternaam.charAt(0)}.`;

      trades.push(

        <div key={user.dagdienst_id + user.info_id}>

          <div className='availableShiftsAdmin_avContainer2'>

            <div className='availableShiftsAdmin_avContainer_row'>

              <div className='availableShiftsAdmin_avContainer_sub1'>

                <span style={{marginTop: 4}}>Naam:</span>

                <span style={{marginTop: 4}}>Functie:</span>

                {this.getSkillsUser(user.info_id) !== null ?
                    <span style={{marginTop: 4}}>Competenties:</span>
                : null}

              </div>

              <div className='availableShiftsAdmin_avContainer_sub2'>

                <span style={{marginTop: 4, fontWeight: 'bold'}}>{userFullName}</span>

                <span style={{marginTop: 4}}>{user.fn2}</span>

                {this.getSkillsUser(user.info_id) !== null ?
                    <span style={{marginTop: 4}}>{this.getSkillsUser(user.info_id)}</span>
                : null}

              </div>

            </div>

            <div style={{marginLeft: '2%', marginTop: '2%', width: '96%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <span className='availableShiftsAdmin_button_green' onClick={() => this.acceptUserAlert(shift, user, true)}>Goedkeuren</span>
              <span className='availableShiftsAdmin_button_red' onClick={() => this.denyUserAlert(shift, user, true)}>Afwijzen</span>
            </div>

          </div>

        </div>
      );

    }

    return trades;

  }

  acceptUserAlert(shift, user, planner) {

    this.setState({
      alertTitle: 'Goedkeuren',
      alertBody: `Wil je ${planner === true ? user.info_voornaam : user.v1} deze dienst laten overnemen?`,
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Goedkeuren'],
      alertButtonAction: planner === true ? this.acceptUserPlanner.bind(this, shift, user) : this.acceptUser.bind(this, shift, user),
      showAlert: true,
    });

  }

  denyUserAlert(shift, user, planner) {

      this.setState({
        alertTitle: 'Afwijzen',
        alertBody: `Wil je ${planner === true ? user.info_voornaam : user.v1} afwijzen voor het overnemen van deze dienst?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Afwijzen'],
        alertButtonAction: planner === true ? this.denyUserPlanner.bind(this, shift, user) : this.denyUser.bind(this, shift, user),
        showAlert: true,
      });
    
  }

  async acceptUser(shift, user) {

    this.setState({loading: true});

    const traded = await APITradeShift.Request(user.id1, shift.rooster_id);

    if (traded === true) {

        await APIDeleteFromAvailableShift.Request(shift.bdienst_id);

        if (Constants.isEmpty(shift.info_id) === true) {
          await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_publiceren = 1`, `WHERE rooster_id = ${shift.rooster_id}`);
        }

        const dateTrade = Constants.stringToDate(shift.rooster_datum);

        await KodictNotifications.sendPushNotification(
            user.id1,
            'trade',
            'Dienstovername',
            `Je hebt de dienst ${dateTrade.toLocaleDateString()} ${shift.rooster_begin.substr(0,5)} - ${shift.rooster_eind.substr(0,5)} overgenomen`
        );

        await KodictNotifications.sendPushNotification(
            shift.info_id,
            'trade',
            'Vervanging',
            `Jouw dienst van ${dateTrade.toLocaleDateString()} ${shift.rooster_begin.substr(0,5)} - ${shift.rooster_eind.substr(0,5)} is overgenomen`
        );

    }
    
    await this.reloadScreen();

    this.setState({loading: false});

  }

  async denyUser(shift, user) {

      this.setState({loading: true});

      await APIDeleteFromAcceptedShift.Request(user.id1, shift.bdienst_id);
      
      await this.reloadScreen();

      this.setState({loading: false});

  }

  async acceptUserPlanner(shift, user) {

      this.setState({loading: true});

      const date = Constants.stringToDate(user.shb_datum);
      const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${user.shb_datum}', '${user.dagdienst_begin}', '${user.dagdienst_eind}', ${user.info_id}, ${user.fid1}, 1, '${user.dagdienst_pauze}', 0)`);

      if (added === true) {

          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

          await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${user.dagdienst_id} AND shb_datum = '${user.shb_datum}'`);

          //await APIAddUsedDayShift.Request(user.shb_datum, user.dagdienst_begin, user.dagdienst_eind, rid[0].rooster_id, user.fid1);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${user.shb_datum}', ${user.dagdienst_id}, ${rid[0].rooster_id})`);

          if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
              await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${user.shb_team_id}, ${rid[0].rooster_id})`)
          }

          await KodictNotifications.sendPushNotification(
              user.info_id,
              'trade',
              'Beschikbare dienst',
              `Je hebt de dienst ${date.toLocaleDateString()} ${user.dagdienst_begin.substr(0,5)} - ${user.dagdienst_eind.substr(0,5)} overgenomen`
          );

      }
        
      await this.reloadScreen();

      this.setState({loading: false});

  }

  async denyUserPlanner(shift, user) {

      this.setState({loading: true});

      await APIDELETE.Request(`DELETE FROM SBGeaccepteerd`, `WHERE sbg_info_id = ${user.info_id} AND sbg_shb_id = ${user.shb_id}`);
        
      await this.reloadScreen();

      this.setState({loading: false});

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  getSkillsSchedule(rid) {

    const skills = this.state.skillsSchedule[rid];

    if(typeof skills !== 'undefined') {
        let toReturn = [];
        let index = 1;
        for (const skill of skills) {
            toReturn.push(<span>- {skill.comp_naam}</span>);
            if(index !== skills.length) {
                toReturn.push(<br />);
            }
            index++;
        }
        return toReturn;
    } else {
        return null;
    }

  }

  getSkillsDayShifts(did) {

      const skills = this.state.skillsDayShifts[did];

      if(typeof skills !== 'undefined') {
          let toReturn = ``;
          let index = 1;
          for (const skill of skills) {
              toReturn.push(<span>- {skill.comp_naam}</span>);
              if(index !== skills.length) {
                toReturn.push(<br />);
              }
              index++;
          }
          return toReturn;
      } else {
          return null;
      }

  }

  getSkillsUser(id) {

      const skills = this.state.skillsUser[id];

      if(typeof skills !== 'undefined') {
          let toReturn = [];
          let index = 1;
          for (const skill of skills) {
              toReturn.push(<span>- {skill.comp_naam}</span>);
              if(index !== skills.length) {
                toReturn.push(<br />);
              }
              index++;
          }
          return toReturn;
      } else {
          return null;
      }

  }

  render() {

    const availableShifts = this.getAvailableShifts();
    const availableShiftsPlanner = this.getAvailableShiftsPlanner();

    return (
        <div className='availableShiftsAdmin'>

          <Navbar history={this.props.history} active={'available_shifts_requests'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'availableShifts'} parent={this} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Dienstovernames
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='availableShiftsAdmin_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {availableShifts.length === 0 && availableShiftsPlanner.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen dienstovernames op dit moment</div>
                </div>

              :

                null
              
              }

              <div className={'availableShiftsAdmin_dataContainer'}>

                <div className={'availableShiftsAdmin_dataContainerTitle'}>

                    Openstaande dienstovernames

                    {availableShifts.length > 0 ?
                    
                        <i className={'fa fa-exclamation'} style={{marginLeft: 8, color: Colors.color.redFilledIn, fontSize: '1vw'}}></i>

                    :

                        null

                    }

                </div>

                <span style={{fontWeight: 'bold', fontSize: '1.2vw', marginBottom: '0.5vw'}}>Door medewerkers</span>

                {availableShifts}

                <span style={{fontWeight: 'bold', fontSize: '1.2vw', marginBottom: '0.5vw', marginTop: '2vw'}}>Open diensten</span>

                {availableShiftsPlanner}

              </div>

              <div className={'availableShiftsAdmin_dataContainer'}>

                <div className={'availableShiftsAdmin_dataContainerTitle'}>

                    Medewerkers geaccepteerd

                </div>

                {this.state.selected === null ?

                  this.state.trades.length > 0 ?

                    <span style={{justifySelf: 'flex-start', textAlign: 'center', fontSize: '1.5vw'}}>Selecteer een beschikbare dienst</span>

                  : null

                :
                this.state.selected.includes('planner') === true ?

                    this.getAcceptedUsersPlanner(this.state.selectedObject)

                  :
                  
                    this.getAcceptedUsers(this.state.selectedObject)  

                }

              </div>

              {/* {tradeRequestBars} */}

            </div>
        
          }

        </div>
    );
  }
  
}

export default AvailableShiftsAdmin;