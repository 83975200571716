/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './holidayRequests.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import APIEditHoliday from '../../../classes/APIEditHoliday';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import KodictNotifications from '../../../constants/KodictNotifications';

class HolidayRequestsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    this._oneDay = this.props._oneDay;
    
    this.state = {

        loading: false,

        startDate: Constants.stringToDate(this.modalData.vakantie_begin),
        startText: this.modalData.vakantie_begin,

        endDate: Constants.stringToDate(this.modalData.vakantie_eind),
        endText: this.modalData.vakantie_eind,

        comment: this.modalData.vakantie_opmerking,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  componentDidMount() {

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        startDate: date,
        startText: dateText,
        showError: false,
    });

    if (this._oneDay === true) {
      this.setDate2(date);
    }

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        endDate: date,
        endText: dateText,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze verlofaanvraag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteHoliday.bind(this),
        showAlert: true,
    });

  }

  editAlert(accepted) {

    this.setState({
        alertTitle: accepted === true ? 'Goedkeuren' : 'Afwijzen',
        alertBody: `Weet je zeker dat je deze verlofaanvraag wilt ${accepted === true ? 'goedkeuren' : 'afwijzen'}?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', accepted === true ? 'Goedkeuren' : 'Afwijzen'],
        alertButtonAction: this.editHoliday.bind(this, accepted),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async editHoliday(accepted) {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditHoliday.Request(
      this.state.startText,
      this.state.endText,
      this.state.comment,
      accepted === true ? 1 : 2,
      this.modalData.vakantie_id
    );

    if(added === true) {

      await KodictNotifications.sendPushNotification(
        this.modalData.info_id,
        'holiday',
        'Vakantie',
        `Jouw ${this._oneDay === true ? 'verlofaanvraag voor' : 'vakantieverzoek van'} ${this.state.startDate.toLocaleDateString()}${this._oneDay === true ? `` : ` t/m ${this.state.endDate.toLocaleDateString()}`} is ${accepted === true ? 'goedgekeurd' : 'geweigerd'}`
      );

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editHolidayData() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditHoliday.Request(
      this.state.startText,
      this.state.endText,
      this.state.comment,
      parseInt(this.modalData.vakantie_geaccepteerd),
      this.modalData.vakantie_id
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.startDate === null) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen ${this._oneDay === true ? 'datum' : 'begindatum'} ingevoerd`,
      });
      return false;
    }
    if(this.state.endDate === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen einddatum ingevoerd',
      });
      return false;
    }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  render() {

    const userFullName = `${this.modalData.info_voornaam} ${(this.modalData.info_tussenvoegsel !== null && this.modalData.info_tussenvoegsel !== '') ? this.modalData.info_tussenvoegsel + " " + this.modalData.info_achternaam.charAt(0) : this.modalData.info_achternaam.charAt(0)}.`;
    //const userFullName2 = `${this.modalData.v2} ${(this.modalData.tv2 !== null && this.modalData.tv2 !== '') ? this.modalData.tv2 + " " + this.modalData.a2.charAt(0) : this.modalData.a2.charAt(0)}.`;

    return (
        <div id="holidayRequestsModal" className="modal" onClick={(e) => e.target === document.getElementById("zholidayRequestsModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            <p>Verlofaanvraag keuren</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Wie:</div>

                    <input className={'inputField2'} disabled value={userFullName} style={{color: 'black'}} />

                    <div style={{marginTop: 5}}>{this._oneDay === true ? 'Datum' : 'Begindatum'}:</div>

                    <DatePicker
                      selected={this.state.startDate}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={(date) => this.setDate1(date)}
                      dateFormat="dd-MM-yyyy"
                      className={'inputFieldTime'}
                      placeholderText={'Selecteer een datum'}
                      locale={nl}
                      showWeekNumbers
                      selectsStart
                    />

                    {this._oneDay !== true ?
                      <div>
                        <div style={{marginTop: 5}}>Einddatum:</div>

                        <DatePicker
                          selected={this.state.endDate}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          minDate={this.state.startDate}
                          onChange={(date) => this.setDate2(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'inputFieldTime'}
                          placeholderText={'Selecteer een datum'}
                          locale={nl}
                          showWeekNumbers
                          selectsEnd
                        />
                      </div>
                    : null}

                    <div style={{marginTop: 5}}>Opmerking: (max. 250 tekens)</div>

                    <textarea  
                      className={'inputFieldBig'}
                      maxLength={250}
                      value={this.state.comment}
                      onChange={(event) => this.setState({ comment: event.target.value })}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 0 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editHolidayData()}>Wijzigen</div>

                            <div className='submitButton' style={{marginTop: 10}} onClick={() => this.editAlert(true)}>Goedkeuren</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.editAlert(false)}>Afwijzen</div>

                        </div>

                    :
                    this.mode === 1 ?

                      this.modalData.vakantie_geaccepteerd === '1' ?

                        <div>

                            <div className='submitButton' onClick={() => this.editHolidayData()}>Wijzigen</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.editAlert(false)}>Afwijzen</div>

                        </div>

                      :
                      this.modalData.vakantie_geaccepteerd === '2' ?

                        <div>

                          <div className='submitButton' onClick={() => this.editHolidayData()}>Wijzigen</div>

                          <div className='submitButton' style={{marginTop: 10}} onClick={() => this.editAlert(true)}>Goedkeuren</div>

                        </div>

                      :

                        null

                    :

                      null

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default HolidayRequestsModal;