/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './sections.css';
import '../times/times.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APIGetSections from '../../../classes/APIGetSections';
import APIGetFunctions from '../../../classes/APIGetFunctions';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import TimesModal from './sectionsModal';

class Sections extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      times: [],
      functions: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    await this.getSections();
    await this.getFunctions();

    this.setState({loading: false});

  }

  openTimesModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  async getSections() {

    let times = await APIGetSections.Request();

    if(times === 'error') {

        times = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(times === null) {

        times = [];

    }

    this.setState({ times: times });

  }

  async getFunctions() {

    let times = await APIGetFunctions.Request();

    if(times === 'error') {

        times = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(times === null) {

        times = [];

    }

    this.setState({ functions: times });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getSections();
    await this.getFunctions();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getTimeBars() {

    let times = [];

    for(let key in this.state.times) {

        times.push(
            <div key={key} className={'times_timeContainer'} onClick={() => this.openTimesModal(1, this.state.times[key])}>

              {this.state.times[key].afdeling_naam}

            </div>
        );

    }

    return times;

  }

  render() {

    const timeBars = this.getTimeBars();

    return (
        <div className='sections'>

          <Navbar history={this.props.history} active={'sections'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Afdelingen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
                {/* <i className='fa fa-plus'></i> */}
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='times_timesMainContainer'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <TimesModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _functions={this.state.functions}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {/* {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              } */}

              <div className="planner_addButtons_container" style={{marginLeft: 0, marginBottom: '2vw'}}>

                <div className="planner_new_addButton" onClick={() => this.openTimesModal(0)}>
                  {/* <i className={'fal fa-plus'} /> */}
                  Toevoegen
                </div>

              </div>

              {this.state.times.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen afdelingen aangemaakt</div>
                </div>

              :

                null
              
              }

              {timeBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Sections;