import React from 'react';
import './pagination.scss';
import '../../constants/DefaultStyle.css';

class Pagination extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    // props
    // this.pages = this.props.pages;
    this.pageFunction = this.props.pageFunction;
    
    this.state = {

        page: 1,
        // pages: this.pages,

    };

  }

//   componentDidUpdate(updatedState, currentState) {

//     if (currentState.pages !== updatedState.pages) {
//         this.setState({ pages: updatedState.pages });
//     }

//   }

  selectPage(page) {
  
    if (this.props.mainLoading === true) {
        return;
    }

    this.setState({ page: page });
    this.pageFunction(page);

  }

  nextPage() {

    if (this.props.mainLoading === true) {
        return;
    }

    if ((this.state.page + 1) > this.pages) {
        return;
    }

    this.setState({ page: this.state.page + 1 });
    this.pageFunction(this.state.page + 1);

  }

  prevPage() {

    if (this.props.mainLoading === true) {
        return;
    }

    if ((this.state.page - 1) < 1) {
        return;
    }

    this.setState({ page: this.state.page - 1 });
    this.pageFunction(this.state.page - 1);

  }

  renderPages() {

    let toRender = [];
    let numbered = 0;

    let start = this.state.page - 2;
    let end = this.state.page + 2;

    if (start < 1) {
        start = 1;
        end = 5 > this.props.pages ? this.props.pages : 5;
    }

    if (end > this.props.pages) {
        end = this.props.pages;
        start = (this.props.pages - 4) < 1 ? 1 : (this.props.pages - 4);
    }

    const shouldShowLeftDots = this.state.page > 2;
    const shouldShowRightDots = this.state.page < this.props.pages - 2;

    if (shouldShowLeftDots) {
        if (this.state.page > 3) {
            toRender.push(
                <div className='pagination_page' onClick={() => this.selectPage(1)}>
                    <span>1</span>
                </div>
            );
        }
        if (this.state.page > 4) {
            toRender.push(
                <div className='pagination_page' onClick={() => this.selectPage(2)}>
                    <span>2</span>
                </div>
            );
        }
        if (this.state.page > 5) {
            toRender.push(
                <div className='pagination_page_break'>
                    <span>...</span>
                </div>
            );
        }
    }

    for (let i = start; i <= this.props.pages; i++) {

        // SHOW FIRST 5 PAGES
        if (numbered === 5) {
            break;
        }

        toRender.push(
            <div className={`pagination_page${this.state.page === i ? '_active' : ''}`} onClick={() => this.selectPage(i)}>
                <span>{i}</span>
            </div>
        );

        numbered++;

    }

    if (shouldShowRightDots) {
        if ((this.state.page < (this.props.pages - 4)) && this.props.pages > 7) {
            toRender.push(
                <div className='pagination_page_break'>
                    <span>...</span>
                </div>
            );
        }
        if ((this.state.page < (this.props.pages - 3)) && this.props.pages > 6) {
            toRender.push(
                <div className='pagination_page' onClick={() => this.selectPage(this.props.pages - 1)}>
                    <span>{this.props.pages - 1}</span>
                </div>
            );
        }
        if ((this.state.page < (this.props.pages - 2)) && this.props.pages > 5) {
            toRender.push(
                <div className='pagination_page' onClick={() => this.selectPage(this.props.pages)}>
                    <span>{this.props.pages}</span>
                </div>
            );
        }
    }

    return toRender;

  }

  render() {

    return (
        <div className='pagination_container'>

            {this.state.page !== 1 ?
                <div className='pagination_page_left' onClick={() => this.prevPage()}> 
                    <i className='far fa-chevron-left' />
                </div>
            : null}

            {this.renderPages()}

            {this.state.page !== this.props.pages ?
                <div className='pagination_page_right' onClick={() => this.nextPage()}> 
                    <i className='far fa-chevron-right' />
                </div>
            : null}

        </div>
    );
  }
  
}

export default Pagination;