/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './clockSystem.css';
import '../options/myHours/myHours.css';
import '../availability/availability.css';
import '../planner/planner.scss';
import '../schedule/schedule.scss';
import '../../constants/DefaultStyle.css';
import '../options/accounts/accounts.css';
import '../employees/employees.css';
import '../options/hoursRegistration/hoursRegistration.css';
import '../login/login.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';

import Colors from '../../constants/Colors';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGetAllUsersClockedToday from '../../classes/APIGetAllUsersClockedToday';
import APIGetSchedule from '../../classes/APIGetSchedule';
import APIGetAllUserTotalBreakClock from '../../classes/APIGetAllUserTotalBreakClock';
import APIGetAllUserActiveBreakClock from '../../classes/APIGetAllUserActiveBreakClock';
import APIGetAllActiveUsersClock from '../../classes/APIGetAllActiveUsersClock';
import APIGetUserActiveBreakClock from '../../classes/APIGetUserActiveBreakClock';
import APIGetActiveUserClock from '../../classes/APIGetActiveUserClock';
import APIGET from '../../classes/global/APIGET';
import APIAddActiveUserClock from '../../classes/APIAddActiveUserClock';
import KodictNotifications from '../../constants/KodictNotifications';
import APIDeleteUserActiveClock from '../../classes/APIDeleteUserActiveClock';
import APIAddUserToClockRegister from '../../classes/APIAddUserToClockRegister';
import APIGetUserTotalBreakClock from '../../classes/APIGetUserTotalBreakClock';
import APIAddToActiveBreakClock from '../../classes/APIAddToActiveBreakClock';
import APIAddToTotalBreakClock from '../../classes/APIAddToTotalBreakClock';
import APIGetOptions from '../../classes/APIGetOptions';
import ClockAdminModal from './clockAdminModal';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIADD from '../../classes/global/APIADD';
import Plan from '../planner/plan.class';
import ClockAlert from '../../components/clockAlert/clockAlert';
import APIGetAbsencetypes from '../../classes/APIGetAbsencetypes';
import APIDELETE from '../../classes/global/APIDELETE';
import ClockLateModal from '../../components/clockLateModal/clockLateModal';

import IdleTimer from 'react-idle-timer';


class ClockSystem extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    this.mounted = false;

    this.teamUsers = [];
    this.teamPlanner = [];

    this.idleTimer = null

    this.now = new Date();

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      showClockAlert: false,
      clockBody: '',
      clockInAlert: true,

      showClockLateModal: false,

      pin: '',
      pinCorrect: false,
      isClocked: false,
      isToday: false,
      times: [],
      pinData: null,

      menu: 'clock',

      errorText: '',

      accounts: [],

      activeUsers: [],
      activeBreaks: [],
      allTotalBreaks: [], //if brk = clock
      schedule: [], //if brk = planner
      usersClockedToday: [],

      userTotalTime: [],
      userClockBreak: [],
      activeBreakTimes: [],

      abs_clockIn: null,

      now: new Date(),

    };

    this.handleUnloadFunction = this.handleUnload.bind(this);
    this.handleBackFunction = this.handleBack.bind(this);
    this.handleCheckTimerFunction = this.handleCheckTimer.bind(this);
    this.handleCheckTimerFunctionFalse = this.handleCheckTimer.bind(this, false);
    this.handleOnIdle = this.refresh.bind(this);

  }

  handleUnload(e) {

    const confirmationMessage = 'Weet je zeker dat je deze pagina wilt verlaten?';

    e.returnValue = confirmationMessage;
    return confirmationMessage;

  }

  handleBack() {

    this.props.history.go(1);

  }

  handleCheckTimer(createInterval = true) {

    if (createInterval === false) {
      return;
    }

    if (Constants.isEmpty(this.interval) === true || Constants.isEmpty(this.interval2) === true) {
      this.componentDidMount();
    }

  }

  componentWillUnmount() {

    this.mounted = false;

    window.removeEventListener('beforeunload', this.handleUnloadFunction);
    window.removeEventListener('popstate', this.handleBackFunction);

    document.removeEventListener('visibilitychange', this.handleCheckTimerFunctionFalse);
    document.removeEventListener('blur', this.handleCheckTimerFunctionFalse);
    window.removeEventListener('blur', this.handleCheckTimerFunctionFalse);
    window.removeEventListener('focus', this.handleCheckTimerFunctionFalse);
    document.removeEventListener('focus', this.handleCheckTimerFunctionFalse);

    clearTimeout(this.interval);
    clearInterval(this.interval);
    clearTimeout(this.interval2);
    clearInterval(this.interval2);

    clearTimeout(this.clockAlertTimeout);

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    if (window.navigator.onLine !== true) {
      this.componentDidMount();
      return;
    }

    if(Data.data.appOptions.clock === false) { //FALSE
      clearInterval(this.interval);
      clearInterval(this.interval2);
      this.props.history.push("/");
      return;
    }

    window.addEventListener('beforeunload', this.handleUnloadFunction);
    window.addEventListener('popstate', this.handleBackFunction);

    this.now = await Constants.getDateNow2();

    let accounts = await APIGetAllAccounts.Request();

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
      this.teamPlanner = await APIGetTeamPlanner.Request(Constants.dateToString(this.now));
      accounts = Constants.getTeamAccounts(this.teamUsers, accounts);
    }

    const abs_clockIn = await APIGET.Request(`SELECT *`, `FROM Afwezigheid`, null, `WHERE afw_inklok IS NOT NULL`, null, null);

    let promises = [];

    promises.push(this.getActiveUsers());
    promises.push(this.getActiveBreaks());

    if(Data.data.appOptions.brk === 'CLOCK') { //CLOCK
      promises.push(this.getAllTotalBreaks());
    }

    promises.push(this.getSchedule());

    promises.push(this.getUsersClockedToday());

    await Promise.all(promises);

    setTimeout(async () => {

      await this.getActiveBreakTime();
      await this.getUserClockBreak();
      await this.getUserTotalTime();
      
    }, 1);

    this.setState({
      now: this.now,
      //now: await Constants.getDateNow2(),
      accounts: accounts,
      abs_clockIn: abs_clockIn.length > 0 ? abs_clockIn[0].afw_id : null,
      loading: false,
    });

    this.mounted = true;

    await this.setIntervalAsync(await this.refresh.bind(this), 5000);
    await this.setIntervalAsync2(await this.getEndTime.bind(this), 1800000);

    document.addEventListener('visibilitychange', this.handleCheckTimerFunction);
    document.addEventListener('blur', this.handleCheckTimerFunction);
    window.addEventListener('blur', this.handleCheckTimerFunction);
    window.addEventListener('focus', this.handleCheckTimerFunction);
    document.addEventListener('focus', this.handleCheckTimerFunction);

  }

  async setIntervalAsync(fn, ms) {
    await fn();
    if (this.mounted === true) {
        this.interval = setTimeout(() => this.setIntervalAsync(fn, ms), ms);
    }
  };

  async setIntervalAsync2(fn, ms) {
    await fn();
    if (this.mounted === true) {
        this.interval2 = setTimeout(() => this.setIntervalAsync2(fn, ms), ms);
    }
  };

  async refresh() {

    this.idleTimer = null;

    if (this.mounted === true) {

      if (window.navigator.onLine !== true) {
          return;
      }

      this.now = await Constants.getDateNow2();

      let accounts = await APIGetAllAccounts.Request();

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.teamUsers = await APIGetTeamUsers.Request();
        this.teamPlanner = await APIGetTeamPlanner.Request(Constants.dateToString(this.now));
        accounts = Constants.getTeamAccounts(this.teamUsers, accounts);
      }

      const abs_clockIn = await APIGET.Request(`SELECT *`, `FROM Afwezigheid`, null, `WHERE afw_inklok IS NOT NULL`, null, null);

      let promises = [];

      promises.push(this.getActiveUsers());
      promises.push(this.getActiveBreaks());

      if(Data.data.appOptions.brk === 'CLOCK') { //CLOCK
        promises.push(this.getAllTotalBreaks());
      }

      promises.push(this.getSchedule());

      promises.push(this.getUsersClockedToday());

      await Promise.all(promises);

      setTimeout(async () => {

        await this.getActiveBreakTime();
        await this.getUserClockBreak();
        await this.getUserTotalTime();

        this.setState({
          now: this.now,
          accounts: accounts,
          abs_clockIn: abs_clockIn.length > 0 ? abs_clockIn[0].afw_id : null,
        });
        
      }, 1);

    }

  }

  async getActiveUsers() {
    
    // let activeUsersData = await APIGetAllActiveUsersClock.Request();
    let activeUsersData = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      activeUsersData = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, KlokActieveGebruiker.*, Locatie.*, info2_profielfoto`, `FROM KlokActieveGebruiker`, `LEFT JOIN Info ON klokactievegebruiker_info_id = info_id LEFT JOIN InfoExtra ON info_id = info2_info_id LEFT JOIN Functie ON info_functie_id = functie_id LEFT JOIN Locatie ON klokactievegebruiker_locatie_id = locatie_id RIGHT JOIN TeamKAG ON teamkag_klokactievegebruiker_id = klokactievegebruiker_id`, `WHERE teamkag_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY klokactievegebruiker_inklok_datum, klokactievegebruiker_inklok_tijd, info_voornaam, info_achternaam`);
    } else {
      activeUsersData = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, KlokActieveGebruiker.*, Locatie.*, info2_profielfoto`, `FROM KlokActieveGebruiker`, `LEFT JOIN Info ON klokactievegebruiker_info_id = info_id LEFT JOIN InfoExtra ON info_id = info2_info_id LEFT JOIN Functie ON info_functie_id = functie_id LEFT JOIN Locatie ON klokactievegebruiker_locatie_id = locatie_id`, null, null, `ORDER BY klokactievegebruiker_inklok_datum, klokactievegebruiker_inklok_tijd, info_voornaam, info_achternaam`);
    }
    
    if(activeUsersData === null) {
      this.setState({
        activeUsers: [],
        loading: false,
      });
      return;
    }

    if(activeUsersData === 'error') {
      this.setState({
        activeUsers: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({activeUsers: activeUsersData});


  }

  async getActiveBreaks() {

    let activeBreaks = await APIGetAllUserActiveBreakClock.Request();

    if(activeBreaks === null) {
      this.setState({
        activeBreaks: [],
        loading: false,
      });
      return;
    }

    if(activeBreaks === 'error') {
      this.setState({
          activeBreaks: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      activeBreaks = Constants.getTeamAccounts(this.teamUsers, activeBreaks);

    }

    this.setState({activeBreaks: activeBreaks});

  }

  async getAllTotalBreaks() {
    
    let totalBreaks = await APIGetAllUserTotalBreakClock.Request();

    if(totalBreaks === null) {
      this.setState({
        allTotalBreaks: [],
        loading: false,
      });
      return;
    }

    if(totalBreaks === 'error') {
      this.setState({
          allTotalBreaks: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      totalBreaks = Constants.getTeamAccounts(this.teamUsers, totalBreaks);

    }

    this.setState({allTotalBreaks: totalBreaks});

  }

  async getEndTime() {

    // check end time and send notification if still clocked in
    const scheduleObj = Constants.convertToObjectWithArrays(this.state.schedule, 'info_id');

    for (const userKey in scheduleObj) {

      let endTime = 0;

      if (scheduleObj[userKey].length > 1) { // more shifts
        for (const sch of scheduleObj[userKey]) {
          if (Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind).getTime() > endTime) {
            endTime = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);
          }
        }
      } else {
        endTime = Constants.stringToDateTime(Plan.isNightShift2(scheduleObj[userKey][0]), scheduleObj[userKey][0].rooster_eind);
      }

      if (Constants.objectArrayContainsKey(this.state.activeUsers, 'info_id', scheduleObj[userKey][0].info_id)) {
        if (this.now > endTime) {
          await KodictNotifications.sendPushNotification(scheduleObj[userKey][0].info_id, 'news', 'Inkloksysteem', 'Vergeet je niet om uit te klokken? Ben je dit wel vergeten, neem dan contact op met jouw leidinggevende');
        }
      }

    }

  }

  async getSchedule() {

    const today = this.now; //new Date();

    let scheduleObject = await APIGetSchedule.Request(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);

    if(scheduleObject === null) {
      this.setState({
        schedule: [],
        loading: false,
      });
      return;
    }

    if(scheduleObject === 'error') {
      this.setState({
          schedule: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      scheduleObject = Constants.getTeamPlanner(this.teamPlanner, scheduleObject);
    }

    // check end time and send notification if still clocked in
    const scheduleObj = Constants.convertToObjectWithArrays(scheduleObject, 'info_id');

    for (const userKey in scheduleObj) {

      let endTime = 0;

      if (scheduleObj[userKey].length > 1) { // more shifts
        for (const sch of scheduleObj[userKey]) {
          if (Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind).getTime() > endTime) {
            endTime = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);
          }
        }
      } else {
        endTime = Constants.stringToDateTime(Plan.isNightShift2(scheduleObj[userKey][0]), scheduleObj[userKey][0].rooster_eind);
      }

      if (Constants.objectArrayContainsKey(this.state.activeUsers, 'info_id', scheduleObj[userKey][0].info_id)) {
        if (this.now > endTime) {
          //KodictNotifications.sendPushNotification(scheduleObj[userKey][0].info_id, 'news', 'Inkloksysteem', 'Houd er rekening mee dat je nog steeds bent ingeklokt');
        }
      }

    }

    // this.getUserTotalTimeFromState(user.info_id)

    this.setState({schedule: scheduleObject});

  }

  async getUsersClockedToday() {

    const today = this.now; //new Date();
    
    let usersClockedToday = await APIGetAllUsersClockedToday.Request(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);

    if(usersClockedToday === null) {
      this.setState({
        usersClockedToday: [],
        loading: false,
      });
      return;
    }

    if(usersClockedToday === 'error') {
      this.setState({
          usersClockedToday: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      usersClockedToday = Constants.getTeamAccounts(this.teamUsers, usersClockedToday);

    }

    this.setState({usersClockedToday: usersClockedToday});

  }

  async getActiveUserCheck(id) {

    const activeObject = await APIGetActiveUserClock.Request(parseInt(id));

    if(activeObject === 'error') {
        return false;
    }

    if(activeObject === null) {
        return false;
    }

    if(activeObject.length > 0) {
        return true;
    }

    return false;

  }

  async getActiveBreakCheck(id) {

    const activeObject = await APIGetUserActiveBreakClock.Request(parseInt(id));

    if(activeObject === 'error') {
        return false;
    }

    if(activeObject === null) {
        return false;
    }

    if(activeObject.length > 0) {
        return true;
    }

    return false;

  }

  getActiveBreakTimeFromState(id) {

    for(let key in this.state.activeBreakTimes) {

      if(this.state.activeBreakTimes[key].id === id) {
        return this.state.activeBreakTimes[key].time;
      }

    }

    return '00:00:00';

  }

  async getActiveBreakTime() {

    let activeBreakTimes = [];

    for(let key in this.state.activeBreaks) {

      const user = this.state.activeBreaks[key];

      const startBreak = Constants.stringToDateTime(user.klokactievepauze_begin_datum, user.klokactievepauze_begin_tijd);
      const now = await Constants.getDateNow2(); //new Date();

      activeBreakTimes.push({
        id: user.info_id,
        time: Constants.msToTimeClock(now - startBreak),
      });

    }

    this.setState({activeBreakTimes: activeBreakTimes});

  }

  async getActiveBreakTimeMS(id) {

    for(let key in this.state.activeBreaks) {

      const user = this.state.activeBreaks[key];

      if(user.info_id === id) {

        const startBreak = Constants.stringToDateTime(user.klokactievepauze_begin_datum, user.klokactievepauze_begin_tijd);
        const now = await Constants.getDateNow2(); //new Date();

        return now - startBreak;

      }

    }

    return 0;

  }

  getUserPlannerBreak(id) {

    let breakTime = '00:00';

    for(let key in this.state.schedule) {

      const schedule = this.state.schedule[key];

      if(schedule.info_id === id) {

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.stringToDateTime(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }

  getUserPlannerJob(id) {

    let job = '';

    for(const plan of this.state.schedule) {

      if(plan.info_id === id) {

        if (Constants.isEmpty(plan.functie_naam) === false) {

          if (job === '') {
            job = plan.functie_naam;
          } else if (job.includes(plan.functie_naam) === false) {
            job += `, ${plan.functie_naam}`;
          }

        }

      }

    }

    return job;

  }

  getUserClockBreakFromState(id) {

    for(let key in this.state.userClockBreak) {

      if(this.state.userClockBreak[key].id === id) {
        return this.state.userClockBreak[key].time;
      }

    }

    return '00:00:00';

  }

  async getUserClockBreak() {

    let userClockBreak = [];

    for(let key2 in this.state.activeUsers) {

      let totalBreakTime = 0;

      for(let key in this.state.allTotalBreaks) {

        const userBreak = this.state.allTotalBreaks[key];

        if(userBreak.info_id === this.state.activeUsers[key2].info_id) {

          const breakHours = parseInt(userBreak.klokpauze_tijd.substring(0, 2));
          const breakMins = parseInt(userBreak.klokpauze_tijd.substring(3, 5)) / 60;
          const breakSecs = parseInt(userBreak.klokpauze_tijd.substring(6, 8)) / 60 / 60;

          totalBreakTime += ((breakHours + breakMins + breakSecs) * 60 * 60 * 1000);

        }

      }

      userClockBreak.push({
        id: this.state.activeUsers[key2].info_id, 
        time: Constants.msToTimeClock(totalBreakTime + await this.getActiveBreakTimeMS(this.state.activeUsers[key2].info_id)),
      });

    }

    this.setState({userClockBreak: userClockBreak});

    //return Constants.msToTime(totalBreakTime + await this.getActiveBreakTimeMS(id)).substring(0, 5);

  }

  async getUserClockBreakMS(id) {

    let totalBreakTime = 0;

    for(let key in this.state.allTotalBreaks) {

      const userBreak = this.state.allTotalBreaks[key];

      if(userBreak.info_id === id) {

        const breakHours = parseInt(userBreak.klokpauze_tijd.substring(0, 2));
        const breakMins = parseInt(userBreak.klokpauze_tijd.substring(3, 5)) / 60;
        const breakSecs = parseInt(userBreak.klokpauze_tijd.substring(6, 8)) / 60 / 60;

        totalBreakTime += ((breakHours + breakMins + breakSecs) * 60 * 60 * 1000);

      }

    }

    return totalBreakTime + await this.getActiveBreakTimeMS(id);

  }

  getUserTotalBreaks(id) {

    let count = 0;

    for(let key in this.state.allTotalBreaks) {

      if(this.state.allTotalBreaks[key].info_id === id) {

        count++;

      }

    }

    for(let key in this.state.activeBreaks) {

      if(this.state.activeBreaks[key].info_id === id) {

        count++;

      }

    }

    return count;

  }

  async getUserTotalTime() {

    let totalTime = 0;

    let userTotalTime = [];

    for(let key in this.state.activeUsers) {

      const user = this.state.activeUsers[key];

      const startDate = Constants.stringToDateTime(user.klokactievegebruiker_inklok_datum, user.klokactievegebruiker_inklok_tijd);
      const now = await Constants.getDateNow2(); //new Date();

      totalTime = now - startDate < 0 ? 0 : now - startDate;

      userTotalTime.push({
        id: user.info_id, 
        ms: totalTime,
        time: Constants.msToTimeClock(totalTime - await this.getUserClockBreakMS(user.info_id)),
      });

    }

    this.setState({userTotalTime: userTotalTime});

    //return Constants.msToTime(totalTime - this.getUserClockBreakMS(id)).substring(0, 5);

  }

  getUserTotalTimeFromState(id) {

    for(let key in this.state.userTotalTime) {

      if(this.state.userTotalTime[key].id === id) {
        return this.state.userTotalTime[key].time;
      }

    }

    return '00:00:00';

  }

  getUserTotalTimeMSFromState(id) {

    for(let key in this.state.userTotalTime) {

      if(this.state.userTotalTime[key].id === id) {
        return this.state.userTotalTime[key].ms;
      }

    }

    return '00:00:00';

  }

  userInActiveBreaks(id) {

    for(let key in this.state.activeBreaks) {
      if(this.state.activeBreaks[key].info_id === id) {
        return true;
      }
    }

    return false;
    
  }

  async pressButton(number) {

    this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });

    let pin = this.state.pin;

    if (number === 'del' || number === 'Backspace' || number === 'Delete') {
      if (pin.length > 0) {
        pin = pin.slice(0, -1);
        this.setState({ pin: pin });
      }
      return;
    }

    if (number === 'cancel' || number === 'Escape') {
      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, pin: ''});
      return;
    }

    if (pin.length === 4) {
      return;
    }

    pin += number;

    pin.toString();

    if (pin.length === 4) {

      this.setState({ pin: pin, reloading: true });

      await this.checkPinCode(pin);

      this.setState({ pin: '', reloading: false });

    } else {

      this.setState({ pin: pin });

    }

  }

  async checkPinCode(pin) {

    if (window.navigator.onLine !== true) {
      await this.checkPinCode(pin);
      return;
    }

    const pincodeData = await APIGET.Request(`SELECT account_rights, info_id, info_voornaam, info_achternaam, info_tussenvoegsel, KlokActieveGebruiker.*, KlokActievePauze.*`, `FROM InfoExtra`, `LEFT JOIN Info ON info2_info_id = info_id LEFT JOIN KlokActieveGebruiker ON klokactievegebruiker_info_id = info_id LEFT JOIN KlokActievePauze ON klokactievepauze_info_id = info_id LEFT JOIN Account ON account_id = info_id`, `WHERE info2_klokcode = ${pin}`, null, null);

    if (pincodeData.length === 0) {
      this.setState({ errorText: 'Je hebt een onjuiste inklokcode ingevoerd' });
      return false;
    }

    const pinData = pincodeData[0];

    this.now = await Constants.getDateNow2();

    const isClocked = await this.getActiveUserCheck(pinData.info_id);

    this.setState({ 
      pinCorrect: true,
      isToday: this.checkToday(pinData.info_id),
      isClocked: isClocked,
      pinData: pinData,
      now: this.now,
    });

    return true;

  } 

  checkToday(userID) {

    let found = false;
    let times = [];

    for (const plan of this.state.schedule) {

      if (plan.info_id === userID) {

        found = true;

        times.push({
          startTime: plan.rooster_begin.substring(0, 5),
          endTime: plan.rooster_eind.substring(0, 5),
          id: plan.rooster_id,
        });

      }

    }

    this.setState({ times: times });

    return found;

  }

  getActiveUserBars() {

    let toReturn = [];
    let count = 0;

    for (const user of this.state.activeUsers) {

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
      const date = Constants.stringToDate(user.klokactievegebruiker_inklok_datum);
      const jobs = this.getUserPlannerJob(user.info_id);

      toReturn.push(

        <div className='cs_activeUser_bar' style={{backgroundColor: count === 1 ? 'transparent' : null}}>

          <div className='cs_activeUser_sub3'>
            {Constants.isEmpty(user.info2_profielfoto) === false ?
              <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${user.info2_profielfoto}`} style={{ width: '3vw', height: '3vw', borderRadius: '3vw' }} />
            :
              <div className='profilePic' style={{ width: '3vw', height: '3vw', borderRadius: '3vw', backgroundColor: '#EBEBEB' }}>
                <i className='far fa-user' style={{ color: '#282828', fontSize: '1.2vw' }} />
              </div>
            }
          </div>

          <div className='cs_activeUser_sub1'>
            <span>{userFullName}</span>
            {Constants.isEmpty(jobs) === false ?
              <span style={{fontSize: '0.8vw', fontWeight: '500'}}>{jobs}</span>
            : null}
          </div>

          <div className='cs_activeUser_sub2'>
            {date.getDate()} {Constants.getShortMonthName(date)} {user.klokactievegebruiker_inklok_tijd.substring(0, 8)}
          </div>

          {Data.data.appOptions.brk === 'CLOCK' ?
            <div className='cs_activeUser_sub2'>
              {this.getUserTotalBreaks(user.info_id)}
            </div>
          : null}

          {Data.data.appOptions.brk === 'PLANNER' ? //change
            <div className='cs_activeUser_sub2'>
              {this.getUserPlannerBreak(user.info_id)}
            </div>
          :
          Data.data.appOptions.brk === 'CLOCK' ?
             <div className='cs_activeUser_sub2'>
              {this.getUserClockBreakFromState(user.info_id)}
            </div>
          :
            null
          }

          <div className='cs_activeUser_sub2' style={{fontWeight: 'bold'}}>
            <span style={{color: this.userInActiveBreaks(user.info_id) === true ? Colors.color.orangeVacation : null}}>{this.getUserTotalTimeFromState(user.info_id)}</span>
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toReturn;

  }

  getScheduleBars() {

    let toReturn = [];
    let count = 0;

    for (const user of this.state.schedule) {

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;

      let userActive = false;
      let userAlreadyClocked = false;
      let clockedOutTime = '';

      for (const active of this.state.activeUsers) {
        if (active.info_id === user.info_id) {
          userActive = true;
          break;
        }
      }

      if (userActive === false) {
        for (const clocked of this.state.usersClockedToday) {
          if (clocked.info_id === user.info_id) {
            const date = Constants.stringToDate(clocked.kloktijd_uitklok_datum);
            userAlreadyClocked = true;
            clockedOutTime = `${date.getDate()} ${Constants.getShortMonthName(date)} ${clocked.kloktijd_uitklok_tijd.substring(0, 8)}`
            break;
          }
        }
      }

      toReturn.push(

        <div className='cs_activeUser_bar' style={{backgroundColor: count === 1 ? 'transparent' : null}}>

          <div className='cs_activeUser_sub1'>
            <span>{userFullName}</span>
            {Constants.isEmpty(user.functie_naam) === false ?
              <span style={{fontSize: '0.7vw', fontWeight: '500'}}>{user.functie_naam}</span>
            : null}
          </div>

          <div className='cs_activeUser_sub2' style={{flex: 0.5}}>
            {user.rooster_begin.substr(0, 5)}
          </div>

          {Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) === true ?
            <div className='cs_activeUser_sub2' style={{flex: 0.5}}>
              {user.rooster_eind.substr(0, 5)}
            </div>
          : null}

          <div className='cs_activeUser_sub2' style={{fontWeight: 'bold'}}>
            {userActive === true ?
              <span style={{color: Colors.color.greenFilledIn}}>Ingeklokt</span>
            :
            userActive === false && userAlreadyClocked === true ?
              <span style={{color: Colors.color.redFilledIn}}>Uitgeklokt op {clockedOutTime}</span>
            :
              <span style={{color: Colors.color.redFilledIn}}>Nog niet ingeklokt</span>
            }
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toReturn;

  }

  async clockInUser(id, special = false, manager = false, reason) {

    if (manager === true) {

      if (Constants.isEmpty(id) === true) {
        Constants.defaultAlert(this, 'Fout', 'Selecteer eerst een medewerker');
        return;
      }

    }

    if(await this.getActiveUserCheck(id) === true) {
      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al ingeklokt');
      return;
    }

    if (manager === true) {
      this.closeModal();
    }

    let today = await Constants.getDateNow2(); //new Date();

    if (parseInt(Data.data.appOptions.opties_klokAfronden) > 0) {
      today = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_klokAfronden), today);
    }

    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let dateString = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${today.getDate()}`;

    if (this.state.isToday === true) {

      const startTimePlanner = Constants.stringToDateTime(Constants.dateToStringUTC(today), `${this.state.times[0].startTime}:00`);
      const startTimePlannerCalc = Constants.stringToDateTime(Constants.dateToStringUTC(today), `${this.state.times[0].startTime}:00`);

      if (today < startTimePlannerCalc && special === false) { // may not start earlier
        today = startTimePlanner;
        hours = today.getHours();
        minutes = today.getMinutes();
        seconds = today.getSeconds();
        dateString = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${today.getDate()}`;
      }

    }

    // LATE CLOCKIN REASON
    if (typeof reason === 'undefined' && special === false && manager === false) {
      const _clockInDate = Constants.stringToDateTime(dateString, `${hours}:${minutes < 10 ? '0' + minutes : minutes}`);
      let tooLate = false;
      for (const time of this.state.times) {
        const _startDate = Constants.stringToDateTime(Constants.dateToStringUTC(today), `${time.startTime}`);
        if (_clockInDate > _startDate) {
          tooLate = true;
          break;
        }
      }
      if (tooLate === true) {
        this.showClockLateModal();
        return;
      }
    }

    // const clockedIn = await APIAddActiveUserClock.Request(id, dateString, `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`, null);
    const clockedIn = await APIADD.Request(`INSERT INTO KlokActieveGebruiker`, `VALUES (NULL, ${id}, '${dateString}', '${`${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`}', NULL, ${typeof reason === 'undefined' || reason === null ? 'NULL' : reason})`);

    if(clockedIn === true) {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const clockID = await APIGET.Request(`SELECT klokactievegebruiker_id`, `FROM KlokActieveGebruiker`, null,null,null, `ORDER BY klokactievegebruiker_id DESC LIMIT 1`);
        if (clockID.length > 0) {
          await APIADD.Request(`INSERT INTO TeamKAG`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${clockID[0].klokactievegebruiker_id})`);
        }
      }

      // AUTOMATIC ABSENTS
      if (Constants.isEmpty(this.state.abs_clockIn) === false) {

        const _clockInDate = Constants.stringToDateTime(dateString, `${hours}:${minutes < 10 ? '0' + minutes : minutes}`);

        for (const time of this.state.times) {
          const _startDate = Constants.stringToDateTime(Constants.dateToStringUTC(today), `${time.startTime}`);
          if (_clockInDate > _startDate) {
            await APIDELETE.Request(`DELETE FROM AfwezigheidN`, `WHERE afwn_info_id = ${id} AND afwn_datum = '${dateString}'`);
            await APIADD.Request(`INSERT INTO AfwezigheidN`, `VALUES (NULL, '${dateString}', ${id}, ${this.state.abs_clockIn}, 0)`);
            // await APIDELETE.Request(`DELETE FROM AfwRooster`, `WHERE afwr_rooster_id = ${time.id}`);
            // await APIADD.Request(`INSERT INTO AfwRooster`, `VALUES (NULL, ${this.state.abs_clockIn}, ${time.id})`);
          }
        }

      }

      this.showClockAlert(this.state.pinData, true);

      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });

      await KodictNotifications.sendPushNotification(id, 'news', 'Inkloksysteem', 'Je bent ingeklokt');

      await this.refresh();

    }

  }

  async clockOutUser(id, manager = false) {

    let startDate = null;
    let startTime = null;
    let reason = 'NULL';

    if (manager === true) {

      const accData = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, KlokActieveGebruiker.*`, `FROM Info`, `LEFT JOIN KlokActieveGebruiker ON klokactievegebruiker_info_id = info_id`, `WHERE info_id = ${id}`, null, null);

      if (accData.length === 0) {
        Constants.defaultAlert(this, 'Fout', 'Selecteer eerst een medewerker');
        return;
      }

      startDate = accData[0].klokactievegebruiker_inklok_datum;
      startTime = accData[0].klokactievegebruiker_inklok_tijd;
      reason = accData[0].klokactievegebruiker_laat_reden;

    } else {

      startDate = this.state.pinData.klokactievegebruiker_inklok_datum;
      startTime = this.state.pinData.klokactievegebruiker_inklok_tijd;
      reason = this.state.pinData.klokactievegebruiker_laat_reden;

    }

    if(await this.getActiveUserCheck(id) === false) {
      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al uitgeklokt');
      return;
    }

    if (manager === true) {
      this.closeModal();
    }

    let totalBreakTime = 0;
    let totalBreakTimeString = '';

    if(Data.data.appOptions.brk === 'CLOCK') {

        const breakObject = await APIGetUserTotalBreakClock.Request(id);

        for(let key in breakObject) {

            const breakHours = parseInt(breakObject[key].klokpauze_tijd.substring(0, 2));
            const breakMins = parseInt(breakObject[key].klokpauze_tijd.substring(3, 5)) / 60;
            const breakSecs = parseInt(breakObject[key].klokpauze_tijd.substring(6, 8)) / 60 / 60;

            totalBreakTime += ((breakHours + breakMins + breakSecs) * 60 * 60 * 1000);

        }

        totalBreakTimeString = Constants.msToTimeClock(totalBreakTime);

    } else {

        totalBreakTimeString = this.getUserPlannerBreak(id);

    }

    let today = await Constants.getDateNow2(); //new Date();

    if (parseInt(Data.data.appOptions.opties_klokAfronden) > 0) {
      today = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_klokAfronden), today);
    }

    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const dateString = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${today.getDate()}`;

    const startDateObject = Constants.stringToDateTime(startDate, startTime);//

    if(Data.data.appOptions.brk === 'CLOCK') {

      // if(today - startDateObject - totalBreakTime < 60000) {
      //   await APIDeleteUserActiveClock.Request(id);
      //   this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      //   this.refresh();
      //   return;
      // }

    } else {

      const totalPlannerBreakObject = await Constants.getDateNow2(); //new Date();
      totalPlannerBreakObject.setUTCHours(parseInt(totalBreakTimeString.substring(0,2)), parseInt(totalBreakTimeString.substring(3,5)), 0, 0);
      const plannerbreakHours = totalPlannerBreakObject.getUTCHours() * 60 * 60 * 1000;
      const plannerbreakMins = totalPlannerBreakObject.getUTCMinutes() * 60 * 1000;

      // if(today - startDateObject - (plannerbreakHours + plannerbreakMins) < 60000) {
      //   await APIDeleteUserActiveClock.Request(id);
      //   this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      //   this.refresh();
      //   return;
      // }

    }

    // const clockedOut = await APIAddUserToClockRegister.Request(id, startDate, startTime, dateString, `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`, totalBreakTimeString, null);
    const clockedOut = await APIADD.Request(`INSERT INTO Kloktijd`, `VALUES (NULL, ${id}, '${startDate}', '${startTime}', '${dateString}', '${`${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`}', '${totalBreakTimeString}', NULL, ${reason})`);

    if(clockedOut === true) {

      await APIDeleteUserActiveClock.Request(id);

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const clockID = await APIGET.Request(`SELECT kloktijd_id`, `FROM Kloktijd`, null,null,null, `ORDER BY kloktijd_id DESC LIMIT 1`);
        if (clockID.length > 0) {
          await APIADD.Request(`INSERT INTO TeamKloktijd`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${clockID[0].kloktijd_id})`);
        }
      }

      if (manager === false) {
        this.showClockAlert(this.state.pinData, false);
      }

      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });

      await KodictNotifications.sendPushNotification(id, 'news', 'Inkloksysteem', 'Je bent uitgeklokt');

      await this.refresh();

    }

  }

  async clockInBreak(id) {

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    if(options[0].opties_pauze === '0') {
      clearInterval(this.interval);
      this.props.history.push("/");
      return;
    }

    if(await this.getActiveBreakCheck(id) === true) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al met pauze gegaan');
      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      return;
    }

    if(this.getUserTotalTimeMSFromState(id) === 0 || this.getUserTotalTimeMSFromState(id) < 0) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker kan nog niet met pauze gaan');
      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      return;
    }

    let today = await Constants.getDateNow2(); //new Date();

    if (parseInt(Data.data.appOptions.opties_pauzeAfronden) > 0) {
      today = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_pauzeAfronden), today);
    }

    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const dateString = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${today.getDate()}`;

    const clockedIn = await APIAddToActiveBreakClock.Request(id, dateString, `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`);

    if(clockedIn === true) {

      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });

      await this.refresh();

    }

  }

  async clockOutBreak(id) {

    if(await this.getActiveBreakCheck(id) === false) {
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker is al terug van zijn/haar pauze');
      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });
      return;
    }

    const startDate = this.state.pinData.klokactievepauze_begin_datum;
    const startTime = this.state.pinData.klokactievepauze_begin_tijd;

    const startBreak = Constants.stringToDateTime(startDate, startTime);
    let endBreak = await Constants.getDateNow2(); //new Date();

    if (parseInt(Data.data.appOptions.opties_pauzeAfronden) > 0) {
      endBreak = Constants.getRoundedDate(parseInt(Data.data.appOptions.opties_pauzeAfronden), endBreak);
    }

    const clockedOut = await APIAddToTotalBreakClock.Request(parseInt(id), Constants.msToTimeClock(endBreak - startBreak));

    if(clockedOut === true) {

      this.setState({ errorText: '', pinCorrect: false, times: [], isToday: false, isClocked: false, pinData: null, });

      await this.refresh();

    }

  }

  clockAllUsersOutAlert() {
    this.setState({
        alertTitle: 'Waarschuwing',
        alertBody: `Weet je zeker dat je iedereen wilt uitklokken?`,
        alertButtons: 2,
        alertButtonAction: this.clockAllUsersOut.bind(this),
        alertButtonText: ['Annuleren', 'Doorgaan'],
        showAlert: true,
    });
  }

  async clockAllUsersOut() {

    let promises = [];

    for (const active of this.state.activeUsers) {
      promises.push(this.clockOutUser(active.info_id, true));
    }

    await Promise.all(promises);

    this.closeModal();

  }

  logoutAlert() {

    if (this.state.pinData === null) {
      Constants.defaultAlert(this, `Fout`, `Voer eerst jouw inklokcode in en probeer vervolgens opnieuw`);
      return;
    }

    if (this.state.pinData.account_rights === '0') {
      Constants.defaultAlert(this, `Toegang geweigerd`, `Je hebt geen recht om dit menu te openen`);
      return;
    }

    this.setState({
        alertTitle: 'Uitloggen',
        alertBody: `Weet je zeker dat je wilt uitloggen?`,
        alertButtons: 2,
        alertButtonAction: this.logout.bind(this),
        alertButtonText: ['Annuleren', 'Uitloggen'],
        showAlert: true,
    });

  }

  logout() {

    window.removeEventListener('beforeunload', this.handleUnloadFunction);
    window.removeEventListener('popstate', this.handleBackFunction);

    Data.data.accountData = [];
    Data.data.accountDataExtra = [];
    Data.data.loggedIn = false;
    Data.data.serverIP = '';
    Data.data.storeCode = '';
    Data.data.storeName = '';
    Data.data.storePhone = '';
    Data.data.storeMail = '';
    Data.data.storeCountry = '';
    Data.data.storeAddress = '';
    Data.data.storeZip = '';
    Data.data.storeCity = '';
    Data.data.storeKvK = '';
    Data.data.pakketID = 0;
    Data.data.storeTimezone = '';
    Data.data.storeCurrency = 'EUR';
    Data.data.trial = null;
    Data.data.teams = [];
    Data.data.ownTeams = [];
    Data.data.chosenTeam = null;
    Data.data.chosenTeamObj = null;

    localStorage.setItem('@storecode', '');
    localStorage.setItem('@username', '');
    localStorage.setItem('@password', '');

    this.props.history.push("/");

  }

  openAdminModal() {

    if (this.state.pinData === null) {
      Constants.defaultAlert(this, `Fout`, `Voer eerst jouw inklokcode in en probeer vervolgens opnieuw`);
      return;
    }

    if (this.state.pinData.account_rights === '0') {
      Constants.defaultAlert(this, `Toegang geweigerd`, `Je hebt geen recht om dit menu te openen`);
      return;
    }

    this.setState({
      showModal: true, 
    });

  }

  closeModal() {
    this.setState({ 
      showModal: false,
      modalData: [],
    });
  }

  closeAlert() {
    this.setState({ showAlert: false });
  }

  selectMenu(menu) {

    if (this.state.loading === true || this.state.menu === menu) {
      return;
    }

    this.setState({ menu: menu });

  }

  showClockAlert(user, clocked) {

    let greeting = 'Goedendag';

    const currentHour = new Date().getHours();

    if(currentHour >= 0 && currentHour < 12) {
        greeting = 'Goedemorgen';
    }
    if(currentHour >= 12 && currentHour < 18) {
        greeting = 'Goedemiddag';
    }
    if(currentHour >= 18 && currentHour <= 23) {
        greeting = 'Goedenavond';
    }

    const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`; //.charAt(0)

    this.setState({
        clockBody: 
          clocked ? (
            <div>
              <div>{greeting} <b>{userFullName}</b>,</div>
              <div style={{marginTop: '0.5vw'}}>Je bent succesvol <b>ingeklokt</b>!</div>

              <div style={{marginTop: '2vw', fontSize: '0.7vw'}}>Tik ergens op het scherm om deze melding snel af te sluiten</div>
            </div>
          )
        :
          (
            <div>
              <div>{greeting} <b>{userFullName}</b>,</div>
              <div style={{marginTop: '0.5vw'}}>Je bent succesvol <b>uitgeklokt</b>!</div>

              <div style={{marginTop: '2vw', fontSize: '0.7vw'}}>Tik ergens op het scherm om deze melding snel af te sluiten</div>
            </div>
          )
        ,
        clockInAlert: clocked,
        showClockAlert: true,
    });

    this.clockAlertTimeout = setTimeout(() => {
      this.closeClockAlert();
    }, 5000);

  }

  closeClockAlert() {
    this.setState({ showClockAlert: false });
    clearTimeout(this.clockAlertTimeout);
  }

  showClockLateModal() {

    this.setState({ showClockLateModal: true });

  }

  closeClockLateModal(reason) {

    this.setState({ showClockLateModal: false });
    
    this.clockInUser(this.state.pinData.info_id, false, false, reason);

  }

  handleKey(e) {

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 27) {
      const nr = parseInt(e.key);
      if (Constants.isEmpty(nr) === false && isNaN(nr) === false) {
        this.pressButton(nr.toString());
      } else if (e.key === 'Backspace' || e.key === 'Escape' || e.key === 'Delete') {
        this.pressButton(e.key);
      }
    } else {
      e.preventDefault();
    }

  }

  render() {

    let greeting = 'Goedendag';

    const currentHour = new Date().getHours();

    if(currentHour >= 0 && currentHour < 12) {
        greeting = 'Goedemorgen';
    }
    if(currentHour >= 12 && currentHour < 18) {
        greeting = 'Goedemiddag';
    }
    if(currentHour >= 18 && currentHour <= 23) {
        greeting = 'Goedenavond';
    }

    let todayString = '';

    if(this.state.isToday === true) {

        todayString = `Je staat vandaag op de planning van `;

        if(this.state.times.length === 1) {

            todayString += `${this.state.times[0].startTime} tot ${this.state.times[0].endTime}`;

        }

        if(this.state.times.length > 1) {

            todayString += `${this.state.times[0].startTime} tot ${this.state.times[0].endTime}`;

            for(let i = 1; i < this.state.times.length; i++) {
                todayString += `, ${this.state.times[i].startTime} tot ${this.state.times[i].endTime}`;
            }

        }

    } else {

        todayString = 'Je staat vandaag niet op de planning';

    }

    let userFullName = '';

    if (this.state.pinData !== null) {
      userFullName = `${this.state.pinData.info_voornaam} ${(this.state.pinData.info_tussenvoegsel !== null && this.state.pinData.info_tussenvoegsel !== '') ? this.state.pinData.info_tussenvoegsel + " " + this.state.pinData.info_achternaam : this.state.pinData.info_achternaam}`; //.charAt(0)
    }

    return (
        <div className='clockSystem' tabIndex="0" onKeyDown={(e) => this.handleKey(e)}>

          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * 15} // 1000 * 60 * 15 = 15 mins
            //onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            //onAction={this.handleOnAction}
            debounce={250}
          />

          {this.state.showModal === true ?
                
            <ClockAdminModal 
              _closeModal={this.closeModal.bind(this)}
              _reloadScreen={this.refresh.bind(this)}
              _clockInUser={this.clockInUser.bind(this)}
              _clockOutUser={this.clockOutUser.bind(this)}
              _clockAllOut={this.clockAllUsersOutAlert.bind(this)}
              _accounts={this.state.accounts}
              _accountsActive={this.state.activeUsers}
            />

          :

            null
              
          }

          {this.state.showAlert === true ?
                    
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          : null}

          {this.state.showClockAlert === true ?
                    
            <ClockAlert 
              _closeAlert={this.closeClockAlert.bind(this)}
              _body={this.state.clockBody}
              _clockedIn={this.state.clockInAlert}
            />

          : null}

          {this.state.showClockLateModal === true ?
                    
            <ClockLateModal 
              _closeModal={this.closeClockLateModal.bind(this)}
            />

          : null}

          <div className='cs_topBar'>

            <div className={`cs_top_button ${this.state.menu === 'clock' ? 'cs_active' : ''}`} onClick={() => this.selectMenu('clock')}>
              <i className='fal fa-users' />
              <span>Ingeklokte medewerkers</span>
            </div>

            <div className={`cs_top_button ${this.state.menu === 'plan' ? 'cs_active' : ''}`} onClick={() => this.selectMenu('plan')}>
              <i className='fal fa-calendar-day' />
              <span>Dagplanning</span>
            </div>

            <div className='cs_top_button' onClick={() => this.openAdminModal()}>
              <i className='fal fa-users-cog' />
              <span>Managers menu</span>
            </div>

            <div className='cs_top_button_logout' onClick={() => this.logoutAlert()}>
              <i className='fal fa-sign-out-alt ' />
              <span>Uitloggen</span>
            </div>

          </div>

          <div className='cs_panel'>

            {this.state.reloading === true ?
                        
              // lds-dual-ring 
              <div className='loader3'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

              null

            }

            <div className='cs_panel_top'>

              <div className={'login_logoContainer'} style={{width: '40%'}}>

                {Data.data.storeLogo !== null ?
                  <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${Data.data.storeLogo}`} className={'navbar_menuImg'} alt='logo' />
                : 
                  <img className={'navbar_menuImg'} src={`https://tiemdo.com/imgs/logo/logo_light.png?v=${Data.data.logo_version}`} alt='logo'/>
                }

              </div>

              {typeof Data.data.chosenTeamObj !== 'undefined' && Data.data.chosenTeamObj !== null && Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?
                <span style={{marginTop: '1%', fontWeight: 'bold', fontSize: '1vw'}}>{Data.data.chosenTeamObj.team_naam}</span>
              :
              typeof Data.data.storeName !== 'undefined' && Data.data.storeName !== null ?
                <span style={{marginTop: '1%', fontWeight: 'bold', fontSize: '1vw'}}>{Data.data.storeName}</span>
              : null}

            </div>

            <div className='cs_panel_mid'>

              <div className='cs_panel_mid_container'>

                <div className='cs_pin'>

                  <i className={`fa${this.state.pin.length > 0 ? 's' : 'l'} fa-circle`} style={{color: '#022F40'}} />

                  <i className={`fa${this.state.pin.length > 1 ? 's' : 'l'} fa-circle`} style={{color: '#022F40'}} />

                  <i className={`fa${this.state.pin.length > 2 ? 's' : 'l'} fa-circle`} style={{color: '#022F40'}} />

                  <i className={`fa${this.state.pin.length > 3 ? 's' : 'l'} fa-circle`} style={{color: '#022F40'}} />

                </div>

                <div className='cs_pinButtons_container' style={{marginTop: '1vw'}}>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB'}} onClick={() => this.pressButton('1')}>
                    1
                  </div>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB'}} onClick={() => this.pressButton('2')}>
                    2
                  </div>

                  <div className='cs_pinButton' onClick={() => this.pressButton('3')}>
                    3
                  </div>

                </div>

                <div className='cs_pinButtons_container'>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB'}} onClick={() => this.pressButton('4')}>
                    4
                  </div>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB'}} onClick={() => this.pressButton('5')}>
                    5
                  </div>

                  <div className='cs_pinButton' onClick={() => this.pressButton('6')}>
                    6
                  </div>

                </div>

                <div className='cs_pinButtons_container' style={{borderBottom: '0px solid #EBEBEB'}}>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB', borderBottom: '1px solid #EBEBEB'}} onClick={() => this.pressButton('7')}>
                    7
                  </div>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB', borderBottom: '1px solid #EBEBEB'}} onClick={() => this.pressButton('8')}>
                    8
                  </div>

                  <div className='cs_pinButton' onClick={() => this.pressButton('9')}>
                    9
                  </div>

                </div>

                <div className='cs_pinButtons_container' style={{borderBottom: '0px solid #EBEBEB'}}>

                  <div className='cs_pinButton' style={{borderRight: '1px solid #EBEBEB'}} onClick={() => this.pressButton('del')}>
                    <i className='fas fa-backspace' />
                  </div>

                  <div className='cs_pinButton' style={{borderRight: '1px solid white'}} onClick={() => this.pressButton('0')}>
                    0
                  </div>

                  {this.state.pinCorrect === true ?
                    <div className='cs_pinButton' style={{borderLeft: '1px solid #EBEBEB', borderTop: '1px solid #EBEBEB', marginTop: '-1px', marginLeft: '-1px'}} onClick={() => this.pressButton('cancel')}>
                      <i style={{color: Colors.color.redFilledIn}} className='fas fa-times-circle' />
                    </div>
                  :
                    <div className='cs_pinButton_nothing'>
                      &nbsp;
                    </div>
                  }

                </div>

                <div className='cs_pin_textContainer'>

                  {this.state.pinCorrect === true ?
                    <span>{greeting}, <b>{userFullName}</b>!</span>
                  : null}

                  {this.state.errorText !== '' ?

                    <span style={{color: Colors.color.redFilledIn}}>{this.state.errorText}</span>

                  : 
                  this.state.pinCorrect === true && this.state.isClocked === false ?

                    <span style={{color: this.state.isToday === false ? Colors.color.redFilledIn : null}}>{todayString}</span>

                  :

                    null

                  }

                </div>

              </div>

            </div>

            <div className='cs_panel_bottom'>

              {this.state.pinCorrect === true ?

                <div>

                  {this.state.isClocked === false ?

                    this.state.isToday === true ?

                      <div className='cs_clockButtons_container'>

                        <div className='cs_clockButton_green' onClick={() => this.clockInUser(this.state.pinData.info_id)}>
                          <i className='fas fa-play' style={{marginRight: '4%', color: 'white'}} />
                          Inklokken
                        </div>

                        {this.state.now < Constants.stringToDateTime(Constants.dateToStringUTC(this.state.now), this.state.times[0].startTime) ?
                          <div className='cs_clockButton_alt' onClick={() => this.clockInUser(this.state.pinData.info_id, true)}>
                            <i className='fas fa-history' style={{marginRight: '4%', color: 'white'}} />
                            Inklokken bijzonder
                          </div>
                        : null}

                      </div>

                    :

                      null

                  :

                    <div className='cs_clockButtons_container'>

                      <div className='cs_clockButton_red' onClick={() => this.clockOutUser(this.state.pinData.info_id)}>
                        <i className='fas fa-stop' style={{marginRight: '4%', color: 'white'}} />
                        Uitklokken
                      </div>

                      {Data.data.appOptions.brk === 'CLOCK' ?
                        this.userInActiveBreaks(this.state.pinData.info_id) === false ?
                          <div className='cs_clockButton_break' onClick={() => this.clockInBreak(this.state.pinData.info_id)}>
                            <i className='fas fa-play' style={{marginRight: '4%', color: 'white'}} />
                            Pauze starten
                          </div>
                        :
                          <div className='cs_clockButton_break' onClick={() => this.clockOutBreak(this.state.pinData.info_id)}>
                            <i className='fas fa-stop' style={{marginRight: '4%', color: 'white'}} />
                            Pauze beëindigen
                          </div>
                      : null}
                    
                    </div>

                  }

                    {this.state.isClocked === false ?

                      this.state.isToday === false ?

                        <div className='cs_clockButtons_container'>

                          <div className='cs_clockButton_alt' onClick={() => this.clockInUser(this.state.pinData.info_id, true)}>
                            <i className='fas fa-history' style={{marginRight: '4%', color:  'white'}} />
                            Inklokken bijzonder
                          </div>

                        </div>

                      : null

                    : null}

                    <div className='cs_space_empty_bottom' />

                </div>

              :

                null

              }

            </div>

          </div>

          <div className='cs_main'>

            {this.state.menu === 'clock' ?

              <div className='cs_main_container'>

                <div className='cs_activeUser_bar_title'>

                  <div className='cs_activeUser_sub3'>
                    &nbsp;
                  </div>

                  <div className='cs_activeUser_sub1_title'>
                    Naam
                  </div>

                  <div className='cs_activeUser_sub2_title'>
                    Inklokdatum + tijd
                  </div>

                  {Data.data.appOptions.brk === 'CLOCK' ?
                    <div className='cs_activeUser_sub2_title'>
                      Aantal pauzes
                    </div>
                  : null}

                  {Data.data.appOptions.brk === 'CLOCK' || Data.data.appOptions.brk === 'PLANNER' ?
                    <div className='cs_activeUser_sub2_title'>
                      Totale pauzetijd
                    </div>
                  : null}

                  <div className='cs_activeUser_sub2_title'>
                    Totale werktijd
                  </div>

                </div>

                {this.getActiveUserBars()}

              </div>

            :
            this.state.menu === 'plan' ?

              <div className='cs_main_container'>

                <div className='cs_activeUser_bar_title'>

                  <div className='cs_activeUser_sub1_title'>
                    Naam
                  </div>

                  <div className='cs_activeUser_sub2_title' style={{flex: 0.5}}>
                    Begintijd
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) === true ?
                    <div className='cs_activeUser_sub2_title' style={{flex: 0.5}}>
                      Eindtijd
                    </div>
                  : null}

                  <div className='cs_activeUser_sub2_title'>
                    Ingeklokt
                  </div>

                </div>

                {this.getScheduleBars()}

              </div>

            :

              null

            }

          </div>

        </div>
    );
  }
  
}

export default ClockSystem;