import React from 'react';
//import Data from "./Data";

class Colors extends React.Component {
  constructor() {

    super();

    this.tintColor = '#2f95dc';

    this.color = {
      tintColor: this.tintColor,
      tabIconDefault: '#ccc',
      tabIconSelected: this.tintColor,
      tabBar: '#fefefe',
      errorBackground: 'red',
      errorText: '#fff',
      warningBackground: '#EAEB5E',
      warningText: '#666804',
      noticeBackground: this.tintColor,
      noticeText: '#fff',

      orange: '#D87A16',
      darkOrange: '#8B5113', 

      backgroundColor: '#FBFCFD', //was F8F8.F8 // F7F8.F9 // F9FA.FB // FBFC.FD

      TEXT_COLOR: '#2B2B2B',
      ORIGINAL_LOGIN_BACKGROUNDCOLOR: '#022F40', // 143642LAST
      ORIGINAL_MAINCOLOR_1: '#47B7EB',
      ORIGINAL_MAINCOLOR_1_LOGIN: '#6D6D6D', //'rgba(21, 125, 250, 1)',
      ORIGINAL_MAINCOLOR_1_TEXTCOLOR: 'white',

    //   mainColor1: '#' + this.themes[this.chosenTheme].color1, //FROMDB
    //   mainColor1_textColor: '#' + this.themes[this.chosenTheme].color1_text, //FROMDB

    //   mainColor2: '#' + this.themes[this.chosenTheme].color2, //FROMDB
    //   mainColor2_switch: HexToRGBA.hexToRGBA('#' + this.themes[this.chosenTheme].color2, 0.5), //FROMDB

    //   mainColor3: '#' + this.themes[this.chosenTheme].color3, //FROMDB
    //   mainColor3_textColor: '#' + this.themes[this.chosenTheme].color3_text, //FROMDB

      selectedDayColor: 'rgba(230, 160, 36, 0.25)', //FROMDB

      button_red: '#E54B4B', // ED1C24 // EE6262 // E54B4BLAST
      button_green: '#38C173', // 20BF55 // 78C74D // 38C173LAST
      button_textColor: 'white',

      button_orange_hover: '#6CC5EF', // 80CEF2LAST
      button_orange_hover2: '#FFCA60',
      button_red_hover: '#EB7070', // F15E63 // EE8383LAST
      button_green_hover: '#61D192', // 69CC8A // 97D774LAST

      normalText_hover: '#464a54',

      //loaderColor: '#' + this.themes[this.chosenTheme].color1, //FROMDB

      greenFilledIn: '#259D58', // 259D58LAST
      redFilledIn: '#E54B4B',
      greyNotFilled: '#8D9EAF',
      orangeVacation: '#F5B639',
      pastBorderColor: '#9B9B9B',
      pastBackgroundColor: '#DADADA',
      otherBorder: '#9B9B9B',
      placeholderColor: '#C7C7CD',
      muted: '#8d9eaf',
    };

  }

//   async getColorsFromDB() {

//     const themesObject = await APIGetThemeColors.Request();

//     if(themesObject !== null) {

//       for(let key in themesObject) {

//         Data.data.themes.push({
//           name: themesObject[key].thema_naam,
//           color1: themesObject[key].thema_kleur1,
//           color1_text: themesObject[key].thema_kleur1_tekst,
//           color2: themesObject[key].thema_kleur2,
//           color3: themesObject[key].thema_kleur3,
//           color3_text: themesObject[key].thema_kleur3_tekst
//         });

//       }

//     }

//   }

//   async chooseTheme(themeID) {

//       Data.data.theme = themeID;
//       await AsyncStorage.setItem('@theme', `${themeID}`);

//       this.color.mainColor1 = '#' + this.themes[themeID].color1;
//       this.color.mainColor1_textColor = '#' + this.themes[themeID].color1_text;

//       this.color.mainColor2 = '#' + this.themes[themeID].color2;
//       this.color.mainColor2_switch = HexToRGBA.hexToRGBA('#' + this.themes[themeID].color2, 0.5);

//       this.color.mainColor3 = '#' + this.themes[themeID].color3;
//       this.color.mainColor3_textColor = '#' + this.themes[themeID].color3_text;

//       this.color.selectedDayColor = HexToRGBA.hexToRGBA('#' + this.themes[themeID].color2, 0.25);

//       this.color.loaderColor = '#' + this.themes[themeID].color1;

//   }


}

// singleton
export default new Colors();
