


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APISendRegistrationCodeMail {

    async Request(email, code, storeCode) {

        let response = null;

        try {
            response = await fetch(`https://tiemdo.com/php/mail/nl/registrationCodeMail.php/`, {
                method: 'post',
                body: new URLSearchParams(`email=${email}&code=${code}&storecode=${storeCode}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === "1") {
            return true;

        } else if (sqlData === "0") {
            return false;

        } else {
            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APISendRegistrationCodeMail());