/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../startupModal/startupModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import Colors from '../../constants/Colors';
import SkillsModal from '../../pages/options/skills/skillsModal';
import AbsencetypesModal from '../../pages/options/absencetypes/absencetypesModal';
import ParticularModal from '../../pages/options/particular/particularModal';
import BonusModal from '../../pages/options/appOptions/bonusModal';
import LocationsModal from '../../pages/options/appOptions/locationsModal';
import { Tooltip } from 'react-tippy';
import FunctionsModal from '../../pages/options/functions/functionsModal';
import BreaksModal from '../../pages/options/breaks/breaksModal';
import SectionModal from '../../pages/options/sections/sectionsModal';
import Shifts from '../../pages/options/shifts/shifts';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';


class TutorialModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,
        reloading: false,

        steps: [],
        curStep: 'general',

        menu: 'general',
        subMenu: 'general',
        selectedTeam: null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    this.setState({loading: false});

  }

  calcSteps() {

    let steps = {};
    
    steps['general'] = typeof this.state.steps.general !== 'undefined' ? this.state.steps.general : false;
    steps['days'] = typeof this.state.steps.days !== 'undefined' ? this.state.steps.days : false;
    steps['salaries'] = typeof this.state.steps.salaries !== 'undefined' ? this.state.steps.salaries : false;
    steps['teams'] = typeof this.state.steps.teams !== 'undefined' ? this.state.steps.teams : false;
    steps['availability'] = typeof this.state.steps.availability !== 'undefined' ? this.state.steps.availability : false;
    steps['planner'] = typeof this.state.steps.planner !== 'undefined' ? this.state.steps.planner : false;

    if (Constants.isTrue(Data.data.appOptions.opties_urenregistratie2)) {
      steps['hours'] = typeof this.state.steps.hours !== 'undefined' ? this.state.steps.hours : false;
    }

    if (Constants.isTrue(Data.data.appOptions.clock)) {
      steps['clock'] = typeof this.state.steps.clock !== 'undefined' ? this.state.steps.clock : false;
    }

    if (Constants.isTrue(Data.data.appOptions.opties_financien)) {
      steps['finances'] = typeof this.state.steps.finances !== 'undefined' ? this.state.steps.finances : false;
    }

    steps['trade'] = typeof this.state.steps.trade !== 'undefined' ? this.state.steps.trade : false;
    steps['absence'] = typeof this.state.steps.absence !== 'undefined' ? this.state.steps.absence : false;
    steps['register'] = typeof this.state.steps.register !== 'undefined' ? this.state.steps.register : false;

    // my company
    steps['functions'] = typeof this.state.steps.functions !== 'undefined' ? this.state.steps.functions : false;
    steps['sections'] = typeof this.state.steps.sections !== 'undefined' ? this.state.steps.sections : false;
    steps['_breaks'] = typeof this.state.steps._breaks !== 'undefined' ? this.state.steps._breaks : false;
    steps['shifts'] = typeof this.state.steps.shifts !== 'undefined' ? this.state.steps.shifts : false;

    // my account
    steps['password'] = typeof this.state.steps.password !== 'undefined' ? this.state.steps.password : false;
    steps['data'] = typeof this.state.steps.data !== 'undefined' ? this.state.steps.data : false;
    steps['done'] = typeof this.state.steps.done !== 'undefined' ? this.state.steps.done : false;

    this.setState({ steps: steps });

  }

  async nextStep() {

    if (await this.checkValidations() === false) {
      return;
    }

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex += 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = true;

    if (curStep === 'general' || curStep === 'days') {
      this.selectSubMenu(nextKey);
    } else {
      this.selectMenu(nextKey);
    }

    this.setState({ steps: steps, curStep: nextKey });

  }

  prevStep() {

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    if (curStep === 'teams' || curStep === 'salaries' || curStep === 'days') {
      this.selectMenu('general');
      this.selectSubMenu(nextKey);
    } else {
      this.selectMenu(nextKey);
    }

    this.setState({ steps: steps, curStep: nextKey });

  }

  goBack() {

    this.props._closeModal();

  }

  closeModal2() {

    this.setState({
      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  hasDoneStep(key) {

    const done = this.state.steps[key];

    if (typeof done === 'undefined') {
      if (key === 'general' || this.state.menu === key || this.state.subMenu === key) {
        return '';
      }
      return '_disabled';
    }

    // if (this.state.menu === 'general' && key === 'general') {
    //     return '';
    // }

    if (this.state.menu === 'general' && this.state.subMenu === key) {
      return '';
    }

    if (this.state.menu === key && this.state.menu !== 'general') {
      return '';
    }

    if (this.state.menu === key || this.state.subMenu === key) {
      if (done) {
        return '_done';
      } else {
        return '';
      }
    }

    if (done) {
      return '_done';
    }

    return '_disabled';

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="tutmodal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.goBack() : null}>

        <div className="tutmodal-content" style={{minHeight: this.state.loading === true ? '15vw' : null}}>

          <div className="tutmodal-header">
              <p>Tiemdo uitleg</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="tutmodal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
          
              }

              <div className='tutmodal_menu'>

                <div className='employees_menuSubTitle'>
                  <div>Registratie</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('general')} ${this.state.menu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('general')}>
                  <div>Zelf registreren</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('teams')} ${this.state.menu === 'teams' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('teams')}>
                  <div>Medewerker registreren</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('availability')} ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('availability')}>
                  <div>Registratiecodes</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('planner')} ${this.state.menu === 'planner' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('planner')}>
                  <div>Planner</div>
                </div>

                {this.state.opties_urenregistratie2 === true ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('hours')} ${this.state.menu === 'hours' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('hours')}>
                    <div>Tijdregistratie</div>
                  </div>
                : null}

                {this.state.clockSystem === true ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('clock')} ${this.state.menu === 'clock' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('clock')}>
                    <div>Inkloksysteem</div>
                  </div>
                : null}

                {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                  <div className={`tutmodal_menuButton${this.hasDoneStep('finances')} ${this.state.menu === 'finances' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('finances')}>
                    <div>Financiën</div>
                  </div>
                : null}

                <div className={`tutmodal_menuButton${this.hasDoneStep('trade')} ${this.state.menu === 'trade' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('trade')}>
                  <div>{'Ruilen & vervanging'}</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('absence')} ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('absence')}>
                  <div>Verlof</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('register')} ${this.state.menu === 'register' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('register')}>
                  <div>Registreren</div>
                </div>

                <div className='employees_menuSubTitle'>
                  <div>Mijn bedrijf</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('functions')} ${this.state.menu === 'functions' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('functions')}>
                  <div>Functies</div>
                </div>
                
                <div className={`tutmodal_menuButton${this.hasDoneStep('sections')} ${this.state.menu === 'sections' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('sections')}>
                  <div>Afdelingen</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('_breaks')} ${this.state.menu === '_breaks' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('_breaks')}>
                  <div>Pauzes</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('shifts')} ${this.state.menu === 'shifts' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('shifts')}>
                  <div>Standaard diensten</div>
                </div>

                <div className='employees_menuSubTitle'>
                  <div>Mijn account</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('password')} ${this.state.menu === 'password' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('password')}>
                  <div>Wachtwoord</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('data')} ${this.state.menu === 'data' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('data')}>
                  <div>Gegevens</div>
                </div>

              </div>

              <div className='tutmodal_content' style={this.state.menu !== 'general' && this.state.menu !== 'teams' ? {width: 'calc(87% - 0px)'} : null}>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                :

                  <div className='tutmodal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='loader3'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                    :

                      null

                    }

                    {this.state.menu === 'register' ?

                        <div>

                            <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Registreren</div>

                        </div>

                    :
                    this.state.curStep === 'done' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginBottom: '2vw'}}>Platform geconfigureerd</div>

                        <span>Het platform is naar wens ingesteld! Klik op <b>voltooien</b> om dit venster af te sluiten.</span>

                      </div>

                    :

                      null

                    }

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {this.state.loading === true ?

            <div className='tutmodal-bottom'>

              <div className='tutmodal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='tutmodal-bottom'>

              {this.showError()}

              {this.state.curStep !== 'general' ?
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.prevStep()}>
                  <span>Terug</span>
                </div>
              : null}

              {this.state.menu === 'done' ?
                <div className='modal-normalButton' onClick={() => this.finishStartup()}>
                  <span>Voltooien</span>
                </div>
              :
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>Volgende</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default TutorialModal;