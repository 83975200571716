/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availability.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import APIAddAvailability from '../../classes/APIAddAvailability';
import APIAddUnavailability from '../../classes/APIAddUnavailability';
import APIEditAvailability from '../../classes/APIEditAvailability';
import APIEditUnavailability from '../../classes/APIEditUnavailability';
import APIDeleteAvailability from '../../classes/APIDeleteAvailability';
import APIDeleteUnavailability from '../../classes/APIDeleteUnavailability';

import DatePicker from 'react-datepicker';
import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';
import Logger from '../../constants/Logger';

class AvailabilityModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.available = this.props._modalAvailable;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        startTime: null,
        startText: '00:00',
        endTime: null,
        endText: '00:00',

        available: null,
        wholeDay: false,

        comment: '',

        date: this.props._modalDate,
        dates: [],

        wholeDayBlocked: false,
        selectTimeDisabled: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING AVAILABILITY

        const startTime = new Date();
        startTime.setHours(6, 0);
    
        const endTime = new Date();
        endTime.setHours(6, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        //this.setState({ date: this.props._modalDate });
        
    }

    if(this.mode === 1) { //ADDING MULTIPLE AVAILABILITY

      const startTime = new Date();
      startTime.setHours(6, 0);
  
      const endTime = new Date();
      endTime.setHours(6, 0);
  
      this.setStartTime(startTime);
      this.setEndTime(endTime);

      let dates = [];

      for(let key in this.props._modalMultipleDays) {

        dates.push(new Date(this.state.date.getFullYear(), this.state.date.getMonth(), this.props._modalMultipleDays[key]));

      }

      this.setState({ dates: dates });

    }

    if(this.mode === 2) { //EDITING

      const startTime = new Date(this.modalData.year, this.modalData.month, this.modalData.day, parseInt(this.modalData.start.substring(0, 2)), parseInt(this.modalData.start.substring(3, 5)), 0);
      const endTime = new Date(this.modalData.year, this.modalData.month, this.modalData.day, parseInt(this.modalData.end.substring(0, 2)), parseInt(this.modalData.end.substring(3, 5)), 0);
  
      this.setStartTime(startTime);
      this.setEndTime(endTime);

      this.setState({
        wholeDay: this.modalData.start === '00:00:00' && this.modalData.end === '00:00:00',
        selectTimeDisabled: this.modalData.start === '00:00:00' && this.modalData.end === '00:00:00',
        wholeDayBlocked: !this.available,
        comment: this.modalData.comment,
        //date: this.props._modalDate,
        available: this.available,
      });

    }

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  selectAvailable() {

    if(this.state.available === null) {
        this.setState({ showError: false });
    }

    if(this.state.available === null) {

        this.setState({ available: true });

    } else if(this.state.available === true) {

        this.setState({ available: null });

    } else if(this.state.available === false) {

        const startTime = new Date();
        const endTime = new Date();

        if(this.state.wholeDay === false) {
            startTime.setHours(0, 0);
            endTime.setHours(0, 0);
        } else if(this.state.wholeDay === true) {
            startTime.setHours(6, 0);
            endTime.setHours(6, 0);
        }
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        this.setState({ 
            available: true, 
            wholeDayBlocked: false,
            wholeDay: false,
            selectTimeDisabled: false,
        });

    }

}

selectUnavailable() {

    if(this.state.available === null) {
        this.setState({ showError: false });
    }

    if(this.state.available === null) {

        const startTime = new Date();
        const endTime = new Date();

        startTime.setHours(0, 0);
        endTime.setHours(0, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        this.setState({ 
            available: false, 
            wholeDayBlocked: true,
            wholeDay: true,
            selectTimeDisabled: true,
        });

    } else if(this.state.available === true) {

        const startTime = new Date();
        const endTime = new Date();

        startTime.setHours(0, 0);
        endTime.setHours(0, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        this.setState({ 
            available: false, 
            wholeDayBlocked: true,
            wholeDay: true,
            selectTimeDisabled: true,
        });

    } else if(this.state.available === false) {

        const startTime = new Date();
        const endTime = new Date();

        if(this.state.wholeDay === false) {
            startTime.setHours(0, 0);
            endTime.setHours(0, 0);
        } else if(this.state.wholeDay === true) {
            startTime.setHours(6, 0);
            endTime.setHours(6, 0);
        }
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        this.setState({ 
            available: null, 
            wholeDayBlocked: false,
            wholeDay: false,
            selectTimeDisabled: false,
        });

    }

}

  toggleSwitch() {

    const startTime = new Date();
    const endTime = new Date();

    if(this.state.wholeDay === true) {
      startTime.setHours(6, 0);
      endTime.setHours(6, 0);
    } else if(this.state.wholeDay === false) {
      startTime.setHours(0, 0);
      endTime.setHours(0, 0);
    }

    this.setStartTime(startTime);
    this.setEndTime(endTime);

    this.setState({wholeDay: !this.state.wholeDay, selectTimeDisabled: !this.state.selectTimeDisabled});

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async saveAvailability() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    let added = false;

    if(this.state.available === true) {

      if(this.mode === 1) { //multiple

        for(let key in this.state.dates) {

          added = await APIAddAvailability.Request(
            Data.data.accountData.account_username,
            this.state.dates[key].getFullYear(),
            this.state.dates[key].getMonth() + 1,
            this.state.dates[key].getDate(),
            this.state.startText,
            this.state.endText,
            this.state.comment,
            this.props._userID,
          );
          
          if(added === false) {
            break;
          }

          Logger.addAvailability(this.state, this.state.dates[key]);

        }

      } else {

        added = await APIAddAvailability.Request(
          Data.data.accountData.account_username,
          this.state.date.getFullYear(),
          this.state.date.getMonth() + 1,
          this.state.date.getDate(),
          this.state.startText,
          this.state.endText,
          this.state.comment,
          this.props._userID,
        );

        Logger.addAvailability(this.state, this.state.date);

      }

    }

    if(this.state.available === false) {

      if(this.mode === 1) { //multiple

        for(let key in this.state.dates) {

          added = await APIAddUnavailability.Request(
            Data.data.accountData.account_username,
            this.state.dates[key].getFullYear(),
            this.state.dates[key].getMonth() + 1,
            this.state.dates[key].getDate(),
            this.state.startText,
            this.state.endText,
            this.state.comment,
            this.props._userID,
          );
          
          if(added === false) {
            break;
          }

          Logger.addUnavailability(this.state, this.state.dates[key]);

        }

      } else {

        added = await APIAddUnavailability.Request(
          Data.data.accountData.account_username,
          this.state.date.getFullYear(),
          this.state.date.getMonth() + 1,
          this.state.date.getDate(),
          this.state.startText,
          this.state.endText,
          this.state.comment,
          this.props._userID,
        );

        Logger.addUnavailability(this.state, this.state.date);

      }
      
    }

    if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

    } else {
        
    }

    this.setState({loading: false});

  }

  async editAvailability() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    let added = false;

    if(this.state.available === true && this.available === true) { //available

      added = await APIEditAvailability.Request(
        this.state.startText,
        this.state.endText,
        this.state.comment,
        this.modalData.id,
      );

      Logger.editAvailability(this.modalData, this.state, this.state.date);

    } else if(this.state.available === true && this.available === false) { //available and delete unavailable first

        const deleted = await APIDeleteUnavailability.Request(this.modalData.id);

        if(deleted === true) {

            added = await APIAddAvailability.Request(
                Data.data.accountData.account_username,
                this.state.date.getFullYear(),
                this.state.date.getMonth() + 1,
                this.state.date.getDate(),
                this.state.startText,
                this.state.endText,
                this.state.comment,
                this.props._userID,
            );

            Logger.editUnavailability(this.modalData, this.state, this.state.date, true);

        }

    } else if(this.state.available === false && this.available === false) { //unavailable

        added = await APIEditUnavailability.Request(
          this.state.startText,
          this.state.endText,
          this.state.comment,
          this.modalData.id,
        );

        Logger.editUnavailability(this.modalData, this.state, this.state.date);

    } else if(this.state.available === false && this.available === true) { //unavailable and delete available first

        const deleted = await APIDeleteAvailability.Request(this.modalData.id);

        if(deleted === true) {

            added = await APIAddUnavailability.Request(
              Data.data.accountData.account_username,
              this.state.date.getFullYear(),
              this.state.date.getMonth() + 1,
              this.state.date.getDate(),
              this.state.startText,
              this.state.endText,
              this.state.comment,
              this.props._userID,
            );

            Logger.editAvailability(this.modalData, this.state, this.state.date, true);

        }

    }

    if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

    } else {
        
    }

    this.setState({loading: false});

  }

  async deleteAvailability() {

    this.setState({loading: false, showError: false});

    let deleted = false;

    if(this.available === true) {
      deleted = await APIDeleteAvailability.Request(this.modalData.id);
      Logger.deleteAvailability(this.state, this.state.date);
    }
    if(this.available === false) {
      deleted = await APIDeleteUnavailability.Request(this.modalData.id);
      Logger.deleteUnavailability(this.state, this.state.date);
    }

    if(deleted === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {
        
    }

    this.setState({loading: false});

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je jouw beschikbaarheid wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteAvailability.bind(this),
        showAlert: true,
    });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {

    if(this.state.available === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen optie geselecteerd',
      });
      return false;
    }
    if(this.state.startTime.getTime() === this.state.endTime.getTime()) {
      if(this.state.startText !== '00:00' && this.state.endText !== '00:00') {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet gelijk zijn aan eindtijd',
        });
        return false;
      }
    }
    if(this.state.startTime.getTime() > this.state.endTime.getTime()) {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet later zijn dan eindtijd',
        });
        return false;
    }

    return true;

  }

  getDates() {

    let dateTexts = [];

    for(let key in this.state.dates) {

      dateTexts.push(<br key={key+'br'} />);
      dateTexts.push(<i key={key} style={{marginTop: 10}}>{Constants.getDayName(this.state.dates[key])} {this.state.dates[key].getDate()} {Constants.getMonthName(this.state.dates[key])} {this.state.dates[key].getFullYear()}</i>);

    }

    return dateTexts;

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="availabilityModal" className="modal" onClick={(e) => e.target === document.getElementById("zavailabilityModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
            {this.mode === 1 ?
            
              <p>Beschikbaarheid toevoegen</p>

            :

              <p>{Constants.getDayName(this.state.date)} {this.state.date.getDate()} {Constants.getMonthName(this.state.date)} {this.state.date.getFullYear()}</p>
            
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }
                
                <div className='plan_modal-account'>

                  {this.mode === 0 ?

                    <div>
                      Beschikbaarheid toevoegen op <br />
                      <i style={{marginTop: 10}}>{Constants.getDayName(this.state.date)} {this.state.date.getDate()} {Constants.getMonthName(this.state.date)} {this.state.date.getFullYear()}</i>
                    </div>

                  :
                  this.mode === 1 ?

                    <div>
                      Beschikbaarheid toevoegen op
                      {this.getDates()}
                    </div>

                  :
                  this.mode === 2 ?

                    <div>
                      Beschikbaarheid wijzigen op <br />
                      <i style={{marginTop: 10}}>{Constants.getDayName(this.state.date)} {this.state.date.getDate()} {Constants.getMonthName(this.state.date)} {this.state.date.getFullYear()}</i>
                    </div>

                  :

                    null
                  
                  }
                </div>

                <div className='plan_modal-form'>

                    <div>Ik ben: (kies een optie)</div>

                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5}}>

                        <div 
                            className={'submitButtonHalf'}
                            onClick={() => this.selectAvailable()}
                            style={{
                                borderColor: this.state.available === true ? Colors.color.greenFilledIn : '#EBEBEB',
                            }}
                        >
                            Beschikbaar
                        </div>

                        <div 
                            className={'submitButtonHalf'}
                            onClick={() => this.selectUnavailable()}
                            style={{
                                borderColor: this.state.available === false ? Colors.color.redFilledIn : '#EBEBEB',
                            }}
                        >
                            Onbeschikbaar
                        </div>

                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10,}}>

                        Hele dag

                        <label className="switch" style={{marginLeft: 25}}>
                            <input 
                              type="checkbox" 
                              checked={this.state.wholeDay} 
                              onClick={() => this.toggleSwitch()}
                              disabled={this.state.wholeDayBlocked}
                              readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                    </div>

                    <div style={{marginTop: 10}}>Van:</div>

                    <DatePicker
                        selected={this.state.startTime}
                        onChange={(date) => this.setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={this.state.selectTimeDisabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                        disabled={this.state.selectTimeDisabled}
                    />

                    <div style={{marginTop: 5}}>Tot:</div>

                    <DatePicker
                        selected={this.state.endTime}
                        onChange={(date) => this.setEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Tot"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={this.state.selectTimeDisabled === true ? 'inputFieldTimeDisabled' : 'inputFieldTime'}
                        disabled={this.state.selectTimeDisabled}
                    />

                    <div style={{marginTop: 5}}>Opmerking: (max. 250 tekens)</div>

                    <textarea 
                      className={'inputFieldBig'}
                      maxLength={250}
                      value={this.state.comment}
                      onChange={(event) => this.setState({ comment: event.target.value })}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 2 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editAvailability()}>Wijzigen</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveAvailability()}>Opslaan</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default AvailabilityModal;