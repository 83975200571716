/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './mycompany.scss';
import '../hoursRegistration/hoursRegistration.css';
import '../myHours/myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../../constants/DefaultStyle.css';
import '../../employees/employees.css';
import '../appOptions/appOptions.css';
import '../times/times.css';
import '../functions/functions.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import { Tooltip } from 'react-tippy';
import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetSections from '../../../classes/APIGetSections';
import APIGetFunctions from '../../../classes/APIGetFunctions';

import SectionModal from '../sections/sectionsModal';
import FunctionsModal from '../functions/functionsModal';
import APIGET from '../../../classes/global/APIGET';
import BreaksModal from '../breaks/breaksModal';
import APIGetDeadlines from '../../../classes/APIGetDeadlines';
import Colors from '../../../constants/Colors';
import DeadlinesModal from '../deadlines/deadlinesModal';
import Shifts from '../shifts/shifts';
import APIGetBlockedDays from '../../../classes/APIGetBlockedDays';
import BlockedModal from '../blocked/blockedModal';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIEDITLOGIN from '../../../classes/login/APIEDITLOGIN';

class MyCompany extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    // compwillmount

    const paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : Data.data.accountData.account_rights === '1' ? 'breaks' : 'teams';

    this.dateNow = new Date();
    
    this.state = {

      loading: true,
      reloading: false,

      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      showDeadlinesModal: false,
      showBlockedModal: false,

      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

      menu: paramMenu,

      sections: [],
      functions: [],
      breaks: [],
      deadlines: [],
      blocked: [],

      chosenTeam: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeam : null,
      chosenTeamObj: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj : 
      {
        bedrijf_naam: Data.data.storeName,
        bedrijf_adres: Data.data.storeAddress,
        bedrijf_postcode: Data.data.storeZip,
        bedrijf_plaats: Data.data.storeCity,
        bedrijf_telefoon: Data.data.storePhone,
        bedrijf_email: Data.data.storeMail,
        bedrijf_land: Data.data.storeCountry,
        bedrijf_kvk: Data.data.storeKvK,
      },

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    const sections = await APIGetSections.Request();
    const functions = await APIGetFunctions.Request();
    const breaks = await APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`);
    const deadlines = await this.getDeadlines();
    const blocked = await this.getBlockedDays();
    
    this.dateNow = await Constants.getDateNow2();

    this.setState({
      sections: sections,
      functions: functions,
      breaks: breaks,
      deadlines: deadlines,
      blocked: blocked,

      loading: false,
    });

  }

  async getDeadlines() {

    let deadlines = await APIGetDeadlines.Request();

    if(deadlines === 'error' || deadlines === null) {
      deadlines = [];
    }

    return deadlines;

  }

  async getBlockedDays() {

    let blocked = await APIGetBlockedDays.Request();

    if(blocked === 'error' || blocked === null) {
      blocked = [];
    }

    return blocked;

  }

  getSectionBars() {

    let toRender = [];
    let count = 0;

    for (const afd of this.state.sections) {

      toRender.push(

        <div key={`section${afd.afdeling_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => this.showSectionsModal(1, afd)}>

          <div className='hr_hoursBar_sub1'>
            <span>{afd.afdeling_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getFunctionBars() {

    let toRender = [];
    let count = 0;

    for (const func of this.state.functions) {

      toRender.push(

        <div key={`function${func.functie_id}`} className='hr_hoursBar' style={{backgroundColor: Constants.hexToRgbA(`#${func.functie_kleur}`, 0.15), cursor: 'pointer'}} onClick={() => this.showFunctionsModal(1, func)}>

          <div className='hr_hoursBar_sub1'>
            <span>{func.functie_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getBreakBars() {

    let toRender = [];
    let count = 0;

    for (const brk of this.state.breaks) {

      toRender.push(

        <div key={`break${brk.brk_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => this.showBreaksModal(1, brk)}>

          <div className='hr_hoursBar_sub1'>
            <span>{brk.brk_tijd.substr(0, 5)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getBlockedBars() {

    let toRender = [];
    let count = 0;

    for (const blck of this.state.blocked) {

      const date = Constants.stringToDate(blck.geblokkeerd_datum);

      toRender.push(

        <div key={`blocked${blck.geblokkeerd_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => this.showBlockedModal(1, blck)}>

          <div className='hr_hoursBar_sub1'>
            <span>{date.getDate()} {Constants.getMonthName(date)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getDeadlineBars() {

    let toRender = [];
    let count = 0;

    for (const deadline of this.state.deadlines) {

      const startDate = Constants.stringToDate(deadline.deadline_begin);
      const endDate = Constants.stringToDate(deadline.deadline_eind);
      const deadlineDate = Constants.stringToDateTime(deadline.deadline_datum, deadline.deadline_tijd); //NEEDEDDDD TO COMPARE TIMES converttoUTC if on other server than netherlands

      const startDay = startDate.getDate();
      //const startMonth = startDate.getMonth(); //jan = 0
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      //const endMonth = endDate.getMonth(); //jan = 0
      const endYear = endDate.getFullYear();

      const deadlineDay = deadlineDate.getDate();
      const deadlineMonth = deadlineDate.getMonth(); //jan = 0
      const deadlineYear = deadlineDate.getFullYear();

      const today = this.dateNow;

      if(today < deadlineDate && today.getDate() === deadlineDay && today.getMonth() === deadlineMonth && today.getFullYear() === deadlineYear) {

        toRender.push(

          <div key={`deadline${deadline.deadline_id}`} className='hr_hoursBar' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.4), cursor: 'pointer'}} onClick={() => this.showDeadlinesModal(1, deadline)}>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(deadlineDate).toLowerCase()} {deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} {deadline.deadline_tijd.substring(0,5)}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDay} {Constants.getMonthName(startDate)} {startYear} t/m {Constants.getShortDayName(endDate).toLowerCase()} {endDay} {Constants.getMonthName(endDate)} {endYear}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <i className='far fa-edit' />
            </div>

          </div>

        );

      } else if(today.getTime() > deadlineDate.getTime()) {

        toRender.push(

          <div key={`deadline${deadline.deadline_id}`} className='hr_hoursBar' style={{backgroundColor: Colors.color.pastBackgroundColor, cursor: 'pointer'}} onClick={() => this.showDeadlinesModal(1, deadline)}>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(deadlineDate).toLowerCase()} {deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} {deadline.deadline_tijd.substring(0,5)}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDay} {Constants.getMonthName(startDate)} {startYear} t/m {Constants.getShortDayName(endDate).toLowerCase()} {endDay} {Constants.getMonthName(endDate)} {endYear}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <i className='far fa-edit' />
            </div>

          </div>

        );

      } else if((deadlineDay - today.getDate()) <= 7 && deadlineMonth === today.getMonth() && deadlineYear === today.getFullYear()) {

        toRender.push(

          <div key={`deadline${deadline.deadline_id}`} className='hr_hoursBar' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.4), cursor: 'pointer'}} onClick={() => this.showDeadlinesModal(1, deadline)}>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(deadlineDate).toLowerCase()} {deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} {deadline.deadline_tijd.substring(0,5)}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDay} {Constants.getMonthName(startDate)} {startYear} t/m {Constants.getShortDayName(endDate).toLowerCase()} {endDay} {Constants.getMonthName(endDate)} {endYear}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <i className='far fa-edit' />
            </div>

          </div>

        );

      } else {

        toRender.push(

          <div key={`deadline${deadline.deadline_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => this.showDeadlinesModal(1, deadline)}>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(deadlineDate).toLowerCase()} {deadlineDay} {Constants.getMonthName(deadlineDate)} {deadlineYear} {deadline.deadline_tijd.substring(0,5)}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDay} {Constants.getMonthName(startDate)} {startYear} t/m {Constants.getShortDayName(endDate).toLowerCase()} {endDay} {Constants.getMonthName(endDate)} {endYear}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <i className='far fa-edit' />
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toRender;

  }

  getTeamButtonsMenu() {

    let toReturn = [];

    for (const team of Data.data.teams) {

      toReturn.push(

        <div key={team.team_id} className={`employees_menuButton ${this.state.chosenTeam === parseInt(team.team_id) ? 'activeAv' : ''}`} onClick={() => this.selectTeam(team)}>
          <div>{team.team_naam}</div>
        </div>

      );

    }

    return toReturn;

  }

  selectTeam(team) {

    this.setState({
      chosenTeam: parseInt(team.team_id),
      chosenTeamObj: team,
    });

  }

  async changeTeamData(e, key) {

    let chosenTeamObj = this.state.chosenTeamObj;

    chosenTeamObj[key] = e.target.value;

    if (this.state.chosenTeam === parseInt(Data.data.chosenTeamObj.team_id)) {
      Data.data.chosenTeamObj[key] = e.target.value;
    }

    for (let team of Data.data.teams) {
      if (parseInt(team.team_id) === this.state.chosenTeam) {
        team[key] = e.target.value;
      }
    }

    for (let team of Data.data.ownTeams) {
      if (parseInt(team.team_id) === this.state.chosenTeam) {
        team[key] = e.target.value;
      }
    }

    APIEDIT.Request(`UPDATE Team`, `SET ${key} = '${e.target.value}'`, `WHERE team_id = ${this.state.chosenTeam}`);

    this.setState({ chosenTeamObj: chosenTeamObj });

  }

  async changeCompanyData(e, key) {
    
    let chosenTeamObj = this.state.chosenTeamObj;
    chosenTeamObj[key] = e.target.value;

    if (key === 'bedrijf_naam') {
      Data.data.storeName = e.target.value;
    } else if (key === 'bedrijf_adres') {
      Data.data.storeAddress = e.target.value;
    } else if (key === 'bedrijf_postcode') {
      Data.data.storeZip = e.target.value;
    } else if (key === 'bedrijf_plaats') {
      Data.data.storeCity = e.target.value;
    } else if (key === 'bedrijf_telefoon') {
      Data.data.storePhone = e.target.value;
    } else if (key === 'bedrijf_email') {
      Data.data.storeMail = e.target.value;
    } else if (key === 'bedrijf_kvk') {
      Data.data.storeKvK = e.target.value;
    } else if (key === 'bedrijf_land') {
      Data.data.storeCountry = e.target.value;
    }

    APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET ${key} = '${e.target.value}'`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

    this.setState({ chosenTeamObj: chosenTeamObj });

  }

  async reloadScreen() {

    this.setState({ reloading: true });

    const sections = await APIGetSections.Request();
    const functions = await APIGetFunctions.Request();
    const breaks = await APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`);
    const deadlines = await this.getDeadlines();
    const blocked = await this.getBlockedDays();
  
    this.dateNow = await Constants.getDateNow2();

    this.setState({
      sections: sections,
      functions: functions,
      breaks: breaks,
      deadlines: deadlines,
      blocked: blocked,

      reloading: false,
    });

  }

  selectMenu(menu) {
    this.setState({ menu: menu });
  }

  showBreaksModal(mode, data) {

    this.setState({
      showBreaksModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showSectionsModal(mode, data) {

    this.setState({
      showSectionsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showFunctionsModal(mode, data) {

    this.setState({
      showFunctionsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showDeadlinesModal(mode, data) {

    this.setState({
      showDeadlinesModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showBlockedModal(mode, data) {

    this.setState({
      showBlockedModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  closeModal() {

    this.setState({
      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      showDeadlinesModal: false,
      showBlockedModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  render() {

    return (

      <div className='mycompany'>

      {this.state.reloading === true ?       
        <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
      : null}

      <Navbar ref={this.navBar} history={this.props.history} active={'manage'} fixed loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'manage'} parent={this} />

      {this.state.showAlert === true ?
                  
        <BetterAlert 
          _closeAlert={this.closeAlert.bind(this)}
          _title={this.state.alertTitle}
          _body={this.state.alertBody}
          _buttons={this.state.alertButtons}
          _buttonText={this.state.alertButtonText}
          _buttonAction={this.state.alertButtonAction}
        />

      : null}

      {this.state.showSectionsModal === true ?
                
        <SectionModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _functions={this.state.functions}
          _reloadScreen={this.reloadScreen.bind(this)}
        />

      : null}

      {this.state.showFunctionsModal === true ?
                
        <FunctionsModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _reloadScreen={this.reloadScreen.bind(this)}
        />

      : null}

       {this.state.showBreaksModal === true ?
                
        <BreaksModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _reloadScreen={this.reloadScreen.bind(this)}
        />

      : null}

      {this.state.showDeadlinesModal === true ?
                
        <DeadlinesModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _reloadScreen={this.reloadScreen.bind(this)}
        />

      : null}

      {this.state.showBlockedModal === true ?
                
        <BlockedModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _reloadScreen={this.reloadScreen.bind(this)}
        />

      : null}

      <div className='employees_main2'>
        {/* stickyTopMenuPanel */}
        <div className='employees_menu2'>

            <div className='employees_menuTitle'>
                <div>Menu</div>
            </div>

            {Data.data.accountData.account_rights === '2' ?
              <div className={`employees_menuButton ${this.state.menu === 'teams' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('teams')}>
                  <div>Vestigingen</div>
              </div>
            : null}

            {Data.data.accountData.account_rights === '2' ?
              <div className={`employees_menuButton ${this.state.menu === 'functions' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('functions')}>
                  <div>Functies</div>
              </div>
            : null}

            {Data.data.accountData.account_rights === '2' ?
              <div className={`employees_menuButton ${this.state.menu === 'sections' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('sections')}>
                  <div>Afdelingen</div>
              </div>
            : null}

            <div className={`employees_menuButton ${this.state.menu === 'breaks' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('breaks')}>
                <div>Pauzes</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'shifts' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('shifts')}>
                <div>Standaard diensten</div>
            </div>

            {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ?
              <div className='employees_menuSubTitle'>
                <div>Management</div>
              </div>
            : null}

            {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ?
              <div className={`employees_menuButton ${this.state.menu === 'deadlines' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('deadlines')}>
                <div>Deadlines</div>
              </div>
            : null}

          {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ?
            <div className={`employees_menuButton ${this.state.menu === 'blocked' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('blocked')}>
              <div>Geblokkeerde datums</div>
            </div>
          : null}

        </div>

        {this.state.menu === 'teams' ?

          Data.data.teams.length > 1 ?

            <div className='employees_menu2'>

              <div className='employees_menuTitle'>
                <div>Teams</div>
              </div>

              {this.getTeamButtonsMenu()}

            </div>

          : 

            <div className='employees_menu2'>

              <div className='employees_menuTitle'>
                <div>Teams</div>
              </div>

              <div className={`employees_menuButton activeAv`}>
                <div>{Data.data.storeName}</div>
              </div>

            </div>

        : null}

        <div className='employees_content2' style={{width: this.state.menu !== 'teams' ? 'calc(87% - 0px)' : null, fontSize: '0.8vw'}}>

          {this.state.loading === true ?
            
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='employees_container'>

              {this.state.menu === 'sections' ?

                <div style={{width: '50%'}}>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Afdelingen</div>

                  <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                    <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showSectionsModal(0)}>Toevoegen</div>

                  </div>

                  {this.getSectionBars()}

                </div>

              :
              this.state.menu === 'functions' ?

                <div style={{width: '50%'}}>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Functies</div>

                  <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                    <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showFunctionsModal(0)}>Toevoegen</div>

                  </div>

                  {this.getFunctionBars()}

                </div>

              :
              this.state.menu === 'breaks' ?

                <div style={{width: '50%'}}>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Pauzes</div>

                  <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                    <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showBreaksModal(0)}>Toevoegen</div>

                  </div>

                  {this.getBreakBars()}

                </div>

              :
              this.state.menu === 'deadlines' ?

                <div style={{width: '50%'}}>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Deadlines</div>

                  <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                    <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showDeadlinesModal(0)}>Toevoegen</div>

                  </div>

                  {this.getDeadlineBars()}

                </div>

              :
              this.state.menu === 'blocked' ?

                <div style={{width: '50%'}}>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Geblokkeerde datums</div>

                  <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                    <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showBlockedModal(0)}>Toevoegen</div>

                  </div>

                  {this.getBlockedBars()}

                </div>

              :
              this.state.menu === 'shifts' ?

                <div>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Standaard diensten</div>

                  <Shifts history={this.props.history} _selectMenu={this.selectMenu.bind(this)} />

                </div>

              :
              this.state.menu === 'teams' ?

                Data.data.teams.length > 1 ?

                  <div style={{width: '45%'}}>
                      
                    <div className='appoptions_submenu_title'>{this.state.chosenTeamObj.team_naam}</div>

                    <div className='appoptions_salary_selectionContainer'>

                      <span style={{fontsize: '0.8vw'}}>Naam</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_naam) === false ? this.state.chosenTeamObj.team_naam : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_naam')}
                          placeholder={'Naam vestiging :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Adres</span>

                      <input 
                          className={'inputField2'}
                          maxLength={100}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_adres) === false ? this.state.chosenTeamObj.team_adres : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_adres')}
                          placeholder={'Adres :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Postcode</span>

                      <input 
                          className={'inputField2'}
                          maxLength={20}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_postcode) === false ? this.state.chosenTeamObj.team_postcode : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_postcode')}
                          placeholder={'Postcode :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Plaats</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_plaats) === false ? this.state.chosenTeamObj.team_plaats : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_plaats')}
                          placeholder={'Plaats :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Land</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_land) === false ? this.state.chosenTeamObj.team_land : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_land')}
                          placeholder={'Land :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Telefoon</span>

                      <input 
                          className={'inputField2'}
                          maxLength={20}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_telefoon) === false ? this.state.chosenTeamObj.team_telefoon : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_telefoon')}
                          placeholder={'Telefoonnummer :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>KvK</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(this.state.chosenTeamObj.team_kvk) === false ? this.state.chosenTeamObj.team_kvk : ''}
                          onChange={(event) => this.changeTeamData(event, 'team_kvk')}
                          placeholder={'KvK :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                  </div>

                :

                  <div style={{width: '45%'}}>
                        
                    <div className='appoptions_submenu_title'>{Data.data.storeName}</div>

                    <div className='appoptions_salary_selectionContainer'>

                      <span style={{fontsize: '0.8vw'}}>Naam</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(Data.data.storeName) === false ? Data.data.storeName : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_naam')}
                          placeholder={'Naam vestiging :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Adres</span>

                      <input 
                          className={'inputField2'}
                          maxLength={100}
                          value={Constants.isEmpty(Data.data.storeAddress) === false ? Data.data.storeAddress : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_adres')}
                          placeholder={'Adres :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Postcode</span>

                      <input 
                          className={'inputField2'}
                          maxLength={20}
                          value={Constants.isEmpty(Data.data.storeZip) === false ? Data.data.storeZip : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_postcode')}
                          placeholder={'Postcode :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Plaats</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(Data.data.storeCity) === false ? Data.data.storeCity : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_plaats')}
                          placeholder={'Plaats :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Land</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(Data.data.storeCountry) === false ? Data.data.storeCountry : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_land')}
                          placeholder={'Land :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>Telefoon</span>

                      <input 
                          className={'inputField2'}
                          maxLength={20}
                          value={Constants.isEmpty(Data.data.storePhone) === false ? Data.data.storePhone : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_telefoon')}
                          placeholder={'Telefoonnummer :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>E-mail</span>

                      <input 
                          className={'inputField2'}
                          maxLength={100}
                          value={Constants.isEmpty(Data.data.storeMail) === false ? Data.data.storeMail : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_email')}
                          placeholder={'E-mail :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                    <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                      <span style={{fontsize: '0.8vw'}}>KvK</span>

                      <input 
                          className={'inputField2'}
                          maxLength={50}
                          value={Constants.isEmpty(Data.data.storeKvK) === false ? Data.data.storeKvK : ''}
                          onChange={(event) => this.changeCompanyData(event, 'bedrijf_kvk')}
                          placeholder={'KvK :'}
                          style={{width: '60%', fontsize: '0.8vw'}} 
                      />

                    </div>

                  </div>

              :

                null

              }

            </div>

          }

        </div>

      </div>

    </div>
    );
  }
  
}

export default MyCompany;