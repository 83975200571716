import React from 'react';
import './news.css';
import '../../../constants/DefaultStyle.css';

import Data from '../../../constants/Data';

import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';
import BetterAlert from '../../../components/alert/alert';
import APIGetAllNews from '../../../classes/APIGetAllNews';
import APIDELETE from '../../../classes/global/APIDELETE';

class News extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }
    
    this.ownScheduleObject = [];

    this.hours = [];
    
    this.state = {
        loading: true,
        ownScheduleState: [],
        errorScreen: false,
        noShifts: false,

        hours: [],

        showModal: false,
        modalMode: 0,
        modalData: [],

        showQuestionModal: false,
        tutorialModal: false,
        missingModalVisible: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,
        alertButtonAction2: null,

        news: [],

    };

    //componentWillMount here

    this.compWillMount();

  }

  compWillMount() {

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    const news = await APIGetAllNews.Request();

    this.setState({ news: news, loading: false, });

  }

  async deleteNewsArticle(article) {

    await APIDELETE.Request(`DELETE FROM Nieuws`, `WHERE nieuws_id = ${article.nieuws_id}`);

    const news = await APIGetAllNews.Request();

    this.setState({ news: news });

  }

  getOwnScheduleBars() {

    let ownScheduleList = [];

    for(const article of this.state.news) {

      const date = Constants.getDateTZD(article.nieuws_datum);
      const userFullName = `${article.info_voornaam} ${(article.info_tussenvoegsel !== null && article.info_tussenvoegsel !== '') ? article.info_tussenvoegsel + " " + article.info_achternaam.charAt(0) : article.info_achternaam.charAt(0)}.`; //.charAt(0)

      ownScheduleList.push(

        <div key={article.nieuws_id} style={{marginTop: ownScheduleList.length > 0 ? '2vw' : '2vw'}} className={'news_contentBox'}>
            <div style={{fontSize: '1vw', fontWeight: 'bold'}}>{Constants.firstUppercase(article.nieuws_titel)}</div>
            <div style={{fontSize: '0.7vw', color: Colors.color.pastBorderColor}}>{Constants.getDayName(article.nieuws_datum)} {date.getDate()} {Constants.getMonthName(article.nieuws_datum)} {date.getFullYear()} door {userFullName}</div>
            <div style={{fontSize: '0.8vw', width: '90%'}}>{Constants.firstUppercase(article.nieuws_tekst)}</div>

            <i className='fas fa-minus-circle news_delete' onClick={() => this.deleteNewsArticle(article)} />
        </div>

      );

    }

    return ownScheduleList;

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    const ownScheduleBars = this.getOwnScheduleBars();

    return (
        <div className='news'>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              {ownScheduleBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default News;