/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './defaultBigModal.css';
import '../../constants/DefaultStyle.css';
import '../smartPlanModal/smartPlanModal.css';

//js components & classes
import Colors from '../../constants/Colors';
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';
import BetterAlert from '../alert/alert';

class BetterBigModal extends React.Component {

  constructor(props) {

    super(props);

	this.props = props;
    
    this.state = {

		showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

    //componentWillMount here:

  }

  componentDidMount() {

  }

	closeAlert() {

		this.setState({ showAlert: false });

	}

  render() {

    return (
        <div id="betterBigModal" className="betterModal" onClick={(e) => e.target === document.getElementById("zbetterBigModal") ? this.props._closeModal() : null}>

			{this.state.showAlert === true ?
                
				<BetterAlert
					_closeAlert={this.closeAlert.bind(this)}
					_title={this.state.alertTitle}
					_body={this.state.alertBody}
					_buttons={this.state.alertButtons}
					_buttonText={this.state.alertButtonText}
					_buttonAction={this.state.alertButtonAction}
				/>

			:

			null
			
			}

        <div className="tutmodal2-content" style={{width: '40vw'}}>

          <div className="tutmodal2-header">

			{this.props._tutorialModal !== true ?
            	<span className="tutmodal2-close" onClick={() => this.props._closeModal()}>&times;</span>
			: null}

            <p>{this.props._header}</p>

          </div>

            <div className="tutmodal2-body_noBottom" style={{paddingBottom: this.props._tutorialModal === true ? '5vw' : 0}}>

                <div className='tutmodal2_content' style={{width: '100%'}}>

                  <div className='tutmodal2_container'>

                    {this.props._content}

                  </div>

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default BetterBigModal;