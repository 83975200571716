/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './planner.scss';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import Plan from './plan.class';
import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';
import APIGetUserEditUnavailable from '../../classes/APIGetUserEditUnavailable';
import APIGetUserEditSchool from '../../classes/APIGetUserEditSchool';
import APIGetUserEditAvailable from '../../classes/APIGetUserEditAvailable';
import APIAddUsedDayShift from '../../classes/APIAddUsedDayShift';
import APIDeleteUsedDayShift from '../../classes/APIDeleteUsedDayShift';
import KodictNotifications from '../../constants/KodictNotifications';
import Logger from '../../constants/Logger';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import APIGET from '../../classes/global/APIGET';
import APIDELETE from '../../classes/global/APIDELETE';
import APIDeleteUserPlanner from '../../classes/APIDeleteUserPlanner';
import APIADD from '../../classes/global/APIADD';

class PlanModal extends React.Component {

  constructor(props) {

    super(props);

    this.available = false;
    this.unavailable = false;
    this.notFilled = false;
    this.savailable = false;
    this.sunavailable = false;
    this.wholeDay = false;
    this.errorText = '';

    this.started = false;

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    const planData = this.props._modalData;

    if(Data.data.appOptions.availability === 'SCHOOL') {
        const data = this.getUserIDTime(planData.info_id);
        
        if(data !== null) {
            if(typeof planData.schoolrooster_begin !== 'undefined') {
                for(const d of data) {
                    if(d.available === Colors.color.redFilledIn) {
                        planData.onbeschikbaar_begin = d.start;
                        planData.onbeschikbaar_eind = d.end;
                    }
                }
            } else if (typeof planData.onbeschikbaar_begin !== 'undefined') {
                for(const d of data) {
                    if(d.available === 'black') {
                        planData.schoolrooster_begin = d.start;
                        planData.schoolrooster_eind = d.end;
                    }
                }
            }
        }
    }

    this.available = planData.onbeschikbaar_begin === undefined && planData.beschikbaar_begin !== undefined;
    this.unavailable = planData.beschikbaar_begin === undefined && planData.onbeschikbaar_begin !== undefined;
    this.notFilled = planData.beschikbaar_begin === undefined && planData.onbeschikbaar_begin === undefined && planData.sb_begin === undefined && planData.sb_eind === undefined;

    this.savailable = planData.sb_begin !== undefined && planData.sb_eind !== undefined && planData.sb_beschikbaar === '1';
    this.sunavailable = planData.sb_begin !== undefined && planData.sb_eind !== undefined && planData.sb_beschikbaar === '2';

    this.wholeDay = (planData.beschikbaar_begin === '00:00:00' && planData.beschikbaar_eind === '00:00:00')
                        || ((planData.onbeschikbaar_begin === '00:00:00' && planData.onbeschikbaar_eind === '00:00:00') || (planData.onbeschikbaar_begin === '00:00' && planData.onbeschikbaar_eind === '00:00')
                        || (planData.sb_begin === '00:00:00' && planData.sb_eind === '00:00:00'));
    
    this.state = {

        screenLoading: false,

        loading: false,

        date: null,
        dateText: '',
        day: '',
        month: '',
        year: '',

        startTime: null,
        startText: '00:00',
        endTime: null,
        endText: '00:00',

        break: false,
        breakTime: new Date().setHours(0, 0),
        breakText: '00:00',

        functionID: -1,
        functionName: '',
        userID: -1,
        nameUser: '',

        disabledInput: false,
        disabledFunction: false,
        disabledUsers: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

        incorrectStartTime: false, // redmark
        incorrectEndTime: false, // redmark

        textAvailable: '',
        textSchool: '',
        textUnavailable: '',

        ignoreAvailability: false,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

    const planData = this.props._modalData;

    const date = new Date(this.props._modalDate);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if(this.props._modalMode === 0) { //clicking available, not filled, unavailable MODAL MODE

        const startTime = new Date();
        startTime.setHours(0, 0);
        this.setStartTime(startTime);

        const endTime = new Date();
        endTime.setHours(0, 0);
        this.setEndTime(endTime);

        const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;    

        this.setState({
            date: date,
            dateText: `${day < 10 ? `0`+ day : day}-${month < 10 ? `0`+ month : month}-${year}`,
            day: day,
            month: month,
            year: year,
            nameUser: userFullName,
            functionID: parseInt(planData.functie_id),
            functionName: this.getFunctionName(parseInt(planData.functie_id)),
        });

        // if(Data.data.appOptions.availability === 'SCHOOL') {
        //     this.checkCorrectTimeSchool(startTime, endTime);
        // } else {
        //     this.checkCorrectStartTime(startTime); //redmark
        //     this.checkCorrectEndTime(endTime); //redmark
        // }
        
    }

    if(this.props._modalMode === 1) { //clicking default shift MODAL MODE

        const startHour = parseInt(planData.rooster_begin.substring(0, 2));
        const startMin = parseInt(planData.rooster_begin.substring(3, 5));
        const endHour = parseInt(planData.rooster_eind.substring(0, 2));
        const endMin = parseInt(planData.rooster_eind.substring(3, 5));

        const startTime = new Date();
        startTime.setHours(startHour);
        startTime.setMinutes(startMin);
    
        const endTime = new Date();
        endTime.setHours(endHour);
        endTime.setMinutes(endMin);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        if(planData.rooster_pauze.substring(0, 5) !== '00:00') {
            const breakHour = parseInt(planData.rooster_pauze.substring(0, 2));
            const breakMin = parseInt(planData.rooster_pauze.substring(3, 5));

            const breakTime = new Date();
            breakTime.setHours(breakHour, breakMin);

            this.setState({break: true});

            this.setBreakTime(breakTime);
        }

        this.setState({
            date: date,
            dateText: `${day < 10 ? `0`+ day : day}-${month < 10 ? `0`+ month : month}-${year}`,
            day: day,
            month: month,
            year: year,
            functionID: parseInt(planData.functie_id),
            functionName: this.getFunctionName(parseInt(planData.functie_id)),
            //disabledInput: true,
            disabledFunction: true,
        });

    }

    if(this.props._modalMode === 2) { //clicking header MODAL MODE

        const startTime = new Date();
        startTime.setHours(0, 0);
    
        const endTime = new Date();
        endTime.setHours(0, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        this.setState({
            date: date,
            dateText: `${day < 10 ? `0`+ day : day}-${month < 10 ? `0`+ month : month}-${year}`,
            day: day,
            month: month,
            year: year,
            functionID: parseInt(planData),
            functionName: this.getFunctionName(parseInt(planData)),
            disabledFunction: true,
        });

    }

    if(this.props._modalMode === 3) { //clicking header in CLICK MODE

        const startTime = new Date();
        startTime.setHours(0, 0);
    
        const endTime = new Date();
        endTime.setHours(0, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;    

        this.setState({
            date: date,
            dateText: `${day < 10 ? `0`+ day : day}-${month < 10 ? `0`+ month : month}-${year}`,
            day: day,
            month: month,
            year: year,
            functionID: parseInt(this.props._modalFunctionID),
            functionName: this.getFunctionName(parseInt(this.props._modalFunctionID)),
            nameUser: userFullName,
            disabledFunction: true,
        });

        this.checkCorrectStartTime(startTime); //redmark
        this.checkCorrectEndTime(endTime); //redmark

    }

    if(this.props._modalMode === 4) { //edit mode

        this.setState({screenLoading: true});

        this.userUnavailable = await APIGetUserEditUnavailable.Request(planData.info_id, `${year}-${month < 10 ? `0`+ month : month}-${day < 10 ? `0`+ day : day}`); // redmark
        this.userSchool = await APIGetUserEditSchool.Request(planData.info_id, date.getDay()); // redmark
        this.userAvailable = await APIGetUserEditAvailable.Request(planData.info_id, `${year}-${month < 10 ? `0`+ month : month}-${day < 10 ? `0`+ day : day}`); // redmark

        this.userStAvailable = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, `WHERE sb_info_id = ${planData.info_id} AND sb_dag = ${date.getDay()} AND sb_beschikbaar = 1`, null, null);
        this.userStUnavailable = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, `WHERE sb_info_id = ${planData.info_id} AND sb_dag = ${date.getDay()} AND sb_beschikbaar = 2`, null, null);

        this.type = Data.data.appOptions.availability === 'STANDARD' && this.userStAvailable.length > 0 ? 'savailable'
                    : Data.data.appOptions.availability === 'STANDARD' && this.userStUnavailable.length > 0 ? 'sunavailable'
                    : this.userUnavailable !== null && this.userSchool !== null && Data.data.appOptions.availability === 'SCHOOL' ? 'schoolunavailable'
                    : this.userUnavailable !== null ? 'unavailable'
                    : this.userSchool !== null && Data.data.appOptions.availability === 'SCHOOL' ? 'school'
                    : this.userAvailable !== null ? 'available' : null; // redmark

        const startHour = parseInt(planData.rooster_begin.substring(0, 2));
        const startMin = parseInt(planData.rooster_begin.substring(3, 5));
        const endHour = parseInt(planData.rooster_eind.substring(0, 2));
        const endMin = parseInt(planData.rooster_eind.substring(3, 5));

        const startTime = new Date();
        startTime.setHours(startHour);
        startTime.setMinutes(startMin);
    
        const endTime = new Date();
        endTime.setHours(endHour);
        endTime.setMinutes(endMin);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

        if(planData.rooster_pauze.substring(0, 5) !== '00:00') {
            const breakHour = parseInt(planData.rooster_pauze.substring(0, 2));
            const breakMin = parseInt(planData.rooster_pauze.substring(3, 5));

            const breakTime = new Date();
            breakTime.setHours(breakHour, breakMin);

            this.setState({break: true});

            this.setBreakTime(breakTime);
        }

        const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;    

        this.setState({
            date: date,
            dateText: `${day < 10 ? `0`+ day : day}-${month < 10 ? `0`+ month : month}-${year}`,
            day: day,
            month: month,
            year: year,
            functionID: parseInt(planData.functie_id),
            functionName: this.getFunctionName(parseInt(planData.functie_id)),
            userID: parseInt(planData.info_id),
            nameUser: userFullName,
            //disabledFunction: true,
        });

        if(Data.data.appOptions.availability === 'SCHOOL') { // redmark
            if(this.type === 'schoolunavailable') {
                this.checkCorrectTimeSchoolEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, startTime, endTime);
                // this.checkCorrectTimeSchoolEditMode('unavailable', this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, startTime, endTime);
                // if(this.state.incorrectStartTime === false && this.state.incorrectEndTime === false) {
                //     this.checkCorrectTimeSchoolEditMode('school', this.userSchool[0].schoolrooster_begin, this.userSchool[0].schoolrooster_eind, startTime, endTime);
                // }
            }
            if(this.type === 'unavailable') {
                this.checkCorrectTimeSchoolEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, startTime, endTime);
            }
            if(this.type === 'school') {
                this.checkCorrectTimeSchoolEditMode(this.type, this.userSchool[0].schoolrooster_begin, this.userSchool[0].schoolrooster_eind, startTime, endTime);
            }
        } else { // redmark
            if(this.type === 'available') {
                this.checkCorrectStartTimeEditMode(this.type, this.userAvailable[0].beschikbaar_begin, this.userAvailable[0].beschikbaar_eind, startTime);
                this.checkCorrectEndTimeEditMode(this.type, this.userAvailable[0].beschikbaar_begin, this.userAvailable[0].beschikbaar_eind, endTime);
            }
            if(this.type === 'unavailable') {
                this.checkCorrectStartTimeEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, startTime);
                this.checkCorrectEndTimeEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, endTime);
            }
            if(this.type === 'savailable') {
                this.checkCorrectStartTimeEditMode(this.type, this.userStAvailable[0].sb_begin, this.userStAvailable[0].sb_eind, startTime);
                this.checkCorrectEndTimeEditMode(this.type, this.userStAvailable[0].sb_begin, this.userStAvailable[0].sb_eind, endTime);
            }
            if(this.type === 'sunavailable') {
                this.checkCorrectStartTimeEditMode(this.type, this.userStUnavailable[0].sb_begin, this.userStUnavailable[0].sb_eind, startTime);
                this.checkCorrectEndTimeEditMode(this.type, this.userStUnavailable[0].sb_begin, this.userStUnavailable[0].sb_eind, endTime);
            }
        }

        if (this.type === 'unavailable') {
            if(this.userUnavailable[0].onbeschikbaar_begin === '00:00:00' && this.userUnavailable[0].onbeschikbaar_eind === '00:00:00') {
                this.setState({ textUnavailable: `${userFullName} is de hele dag onbeschikbaar` });
            } else {
                this.setState({ textUnavailable: `${userFullName} is onbeschikbaar van ${this.userUnavailable[0].onbeschikbaar_begin.substring(0, 5)} tot ${this.userUnavailable[0].onbeschikbaar_eind.substring(0, 5)}` });
            }
        }
        if (this.type === 'sunavailable') {
            if(this.userStUnavailable[0].sb_begin === '00:00:00' && this.userStUnavailable[0].sb_eind === '00:00:00') {
                this.setState({ textUnavailable: `${userFullName} is de hele dag onbeschikbaar` });
            } else {
                this.setState({ textUnavailable: `${userFullName} is onbeschikbaar van ${this.userStUnavailable[0].sb_begin.substring(0, 5)} tot ${this.userStUnavailable[0].sb_eind.substring(0, 5)}` });
            }
        }
        if (this.type === 'available') {
            if(Data.data.appOptions.availability !== 'SCHOOL') {
                if(this.userAvailable[0].beschikbaar_begin === '00:00:00' && this.userAvailable[0].beschikbaar_eind === '00:00:00') {
                    this.setState({ textAvailable: `${userFullName} is de hele dag beschikbaar` });
                } else {
                    this.setState({ textAvailable: `${userFullName} is beschikbaar van ${this.userAvailable[0].beschikbaar_begin.substring(0, 5)} tot ${this.userAvailable[0].beschikbaar_eind.substring(0, 5)}` });
                }
            }
        }
        if (this.type === 'savailable') {
            if(Data.data.appOptions.availability !== 'SCHOOL') {
                if(this.userStAvailable[0].sb_begin === '00:00:00' && this.userStAvailable[0].sb_eind === '00:00:00') {
                    this.setState({ textAvailable: `${userFullName} is de hele dag beschikbaar` });
                } else {
                    this.setState({ textAvailable: `${userFullName} is beschikbaar van ${this.userStAvailable[0].sb_begin.substring(0, 5)} tot ${this.userStAvailable[0].sb_eind.substring(0, 5)}` });
                }
            }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
            if (this.type === 'school') {
                this.setState({ textSchool: `${userFullName} heeft school van ${this.userSchool[0].schoolrooster_begin.substring(0, 5)} tot ${this.userSchool[0].schoolrooster_eind.substring(0, 5)}` });
            }
            if (this.type === 'schoolunavailable') {
                this.setState({ textSchool: `${userFullName} heeft school van ${this.userSchool[0].schoolrooster_begin.substring(0, 5)} tot ${this.userSchool[0].schoolrooster_eind.substring(0, 5)}` });
                if(this.userUnavailable[0].onbeschikbaar_begin === '00:00:00' && this.userUnavailable[0].onbeschikbaar_eind === '00:00:00') {
                    this.setState({ textUnavailable: `${userFullName} is de hele dag onbeschikbaar` });
                } else {
                    this.setState({ textUnavailable: `${userFullName} is onbeschikbaar van ${this.userUnavailable[0].onbeschikbaar_begin.substring(0, 5)} tot ${this.userUnavailable[0].onbeschikbaar_eind.substring(0, 5)}` });
                }
            }
        }

        this.setState({screenLoading: false});

    }

    if (this.props._modalMode === 5) {

        const startTime = new Date();
        startTime.setHours(0);
        startTime.setMinutes(0);
    
        const endTime = new Date();
        endTime.setHours(0);
        endTime.setMinutes(0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

    }

    this.started = true;

  }

  getDefaultTimes() {

    let defaultTimes = [];

    for(let key in this.props._times) {

        defaultTimes.push(
            <option value={key}>{this.props._times[key].dienst_begin.substring(0, 5)} - {this.props._times[key].dienst_eind.substring(0, 5)}</option>
        );

    }

    return defaultTimes;

  }

  selectTime(event) {

    const key = parseInt(event.target.value);

    const startHour = parseInt(this.props._times[key].dienst_begin.substring(0, 2));
    const startMin = parseInt(this.props._times[key].dienst_begin.substring(3, 5));
    const endHour = parseInt(this.props._times[key].dienst_eind.substring(0, 2));
    const endMin = parseInt(this.props._times[key].dienst_eind.substring(3, 5));

    const startTime = new Date();
    startTime.setHours(startHour);
    startTime.setMinutes(startMin);

    const endTime = new Date();
    endTime.setHours(endHour);
    endTime.setMinutes(endMin);

    this.setStartTime(startTime);
    this.setEndTime(endTime);

    if(Data.data.appOptions.availability === 'SCHOOL') { // redmark
        this.checkCorrectTimeSchool(startTime, endTime, this.state.userID);
    }

  }

  getFunctions() {

    let functions = [];

    for(let key in this.props._functions) {

        functions.push(
            <option value={this.props._functions[key].functie_id} selected={parseInt(this.props._functions[key].functie_id) === this.state.functionID}>{this.props._functions[key].functie_naam}</option>
        );

    }

    return functions;

  }

  getFunctionName(functionID) {

    for(let key in this.props._functions) {

        const _function = this.props._functions[key];

        if(parseInt(_function.functie_id) === parseInt(functionID)) {
            return _function.functie_naam;
        }

    }

    return null;

  } 

  ignoreAvailability() {

    this.setState({
        ignoreAvailability: !this.state.ignoreAvailability,
    });

    setTimeout(() => {
        if(this.props._modalMode === 4) {
            if(this.props._modalData.info_id === this.state.userID) {
                if(Data.data.appOptions.availability === 'SCHOOL') { // redmark
                    if(this.type === 'schoolunavailable') {
                        this.checkCorrectTimeSchoolEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, this.state.startTime, this.state.endTime);
                    }
                    if(this.type === 'unavailable') {
                        this.checkCorrectTimeSchoolEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, this.state.startTime, this.state.endTime);
                    }
                    if(this.type === 'school') {
                        this.checkCorrectTimeSchoolEditMode(this.type, this.userSchool[0].schoolrooster_begin, this.userSchool[0].schoolrooster_eind, this.state.startTime, this.state.endTime);
                    }
                } else { // redmark
                    if(this.type === 'available') {
                        this.checkCorrectStartTimeEditMode(this.type, this.userAvailable[0].beschikbaar_begin, this.userAvailable[0].beschikbaar_eind, this.state.startTime);
                        this.checkCorrectEndTimeEditMode(this.type, this.userAvailable[0].beschikbaar_begin, this.userAvailable[0].beschikbaar_eind, this.state.endTime);
                    }
                    if(this.type === 'unavailable') {
                        this.checkCorrectStartTimeEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, this.state.startTime);
                        this.checkCorrectEndTimeEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, this.state.endTime);
                    }
                    if(this.type === 'savailable') {
                        this.checkCorrectStartTimeEditMode(this.type, this.userStAvailable[0].sb_begin, this.userStAvailable[0].sb_eind, this.state.startTime);
                        this.checkCorrectEndTimeEditMode(this.type, this.userStAvailable[0].sb_begin, this.userStAvailable[0].sb_eind, this.state.endTime);
                    }
                    if(this.type === 'sunavailable') {
                        this.checkCorrectStartTimeEditMode(this.type, this.userStUnavailable[0].sb_begin, this.userStUnavailable[0].sb_eind, this.state.startTime);
                        this.checkCorrectEndTimeEditMode(this.type, this.userStUnavailable[0].sb_begin, this.userStUnavailable[0].sb_eind, this.state.endTime);
                    }
                }
            } else {
                if(Data.data.appOptions.availability === 'SCHOOL') {
                    this.checkCorrectTimeSchool(this.state.startTime, this.state.endTime, this.state.userID);
                } else {
                    this.checkCorrectStartTime(this.state.startTime, this.state.userID);
                    this.checkCorrectEndTime(this.state.endTime, this.state.userID);
                }
            }
        } else {
            if(Data.data.appOptions.availability === 'SCHOOL') {
                this.checkCorrectTimeSchool(this.state.startTime, this.state.endTime, this.state.userID);
            } else {
                this.checkCorrectStartTime(this.state.startTime, this.state.userID);
                this.checkCorrectEndTime(this.state.endTime, this.state.userID);
            }
        }
    }, 1);

  }

  selectFunction(event) {

    this.setState({
        functionID: parseInt(event.target.value),
        functionName: this.getFunctionName(event.target.value),
        showError: false,
    });

  }

  getUsers() {

    let users = [];

    for(let key in this.props._users) {

        users.push(
            <option 
                value={this.props._users[key].value} 
                selected={parseInt(this.props._users[key].value) === this.state.userID}
                style={{color: this.props._users[key].color}}
                disabled={this.props._users[key].color === Colors.color.orangeVacation || this.props._users[key].contractExpired === true}
            >
                    {this.props._users[key].name}&emsp;

                    {this.props._users[key].color === Colors.color.orangeVacation ?

                        'vakantie'

                    :

                    this.props._users[key].contractExpired === true ?

                        'contract verlopen'

                    :

                    this.props._users[key].inPlanner === true ?

                        'ingepland'

                    :

                    this.props._users[key].start === '00:01' && this.props._users[key].end === '00:01' ?
                                
                        `n.v.t.`

                    :

                    this.props._users[key].start === '00:02' && this.props._users[key].end === '00:02' ?
                    
                        `Schoolvakantie`

                    :

                    this.props._users[key].start === null && this.props._users[key].end === null ?
                                    
                        null

                    :

                    this.props._users[key].start === '00:00' && this.props._users[key].end === '00:00' ?

                        `${this.props._users[key].color === Colors.color.greenFilledIn ? `beschikbaar:` : `onbeschikbaar:`} hele dag`

                    :

                    this.props._users[key].start !== '00:00' && this.props._users[key].end !== '00:00' && this.props._users[key].color === Colors.color.greyNotFilled ?

                        `${this.props._users[key].start} - ${this.props._users[key].end}`
                    
                    :

                        `${Data.data.appOptions.availability === 'SCHOOL' ? this.props._users[key].color === Colors.color.redFilledIn ? 'onbeschikbaar:' : `school:` : this.props._users[key].color === Colors.color.greenFilledIn ? `beschikbaar:` : `onbeschikbaar:`} ${this.props._users[key].start} - ${this.props._users[key].end}`

                    }   
            </option>
        );

    }

    return users;

  }

  getUserIDTime(userID) { // redmark

    if(userID === null || typeof userID === 'undefined') {
        return null;
    }

    if(Data.data.appOptions.availability === 'SCHOOL') {

        let data = [];

        for(let key in this.props._users) {

            const user = this.props._users[key];
    
            if(user.value === userID || user.value === userID.toString()) {
                data.push({start: user.start, end: user.end, available: user.color, name: user.name});
            }
    
        }

        return data;

    } else {

        for(let key in this.props._users) {

            const user = this.props._users[key];
    
            if(user.value === userID || user.value === userID.toString()) {
                return {start: user.start, end: user.end, available: user.color, name: user.name};
            }
    
        }

    }

    return null;

  }

  checkCorrectTimeSchoolEditMode(type, availStart, availEnd, planStart, planEnd) { // redmark

    if(this.state.ignoreAvailability === true) {
        this.setState({ incorrectStartTime: false, incorrectEndTime: false });
        return;
    }

    if(this.type === 'schoolunavailable') {
        if(this.userUnavailable !== null) {

            if(this.userUnavailable[0].onbeschikbaar_begin === '00:00:00' && this.userUnavailable[0].onbeschikbaar_eind === '00:00:00') {
                this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                return;
            }
    
            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);
    
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();
    
            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.userUnavailable[0].onbeschikbaar_begin}`);
    
            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.userUnavailable[0].onbeschikbaar_eind}`);
    
            // set correct planner date to check
            // planStart.setDate(date.getDate());
            // planStart.setMonth(date.getMonth());
            // planStart.setFullYear(date.getFullYear());
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);
    
            // planEnd.setDate(date.getDate());
            // planEnd.setMonth(date.getMonth());
            // planEnd.setFullYear(date.getFullYear());
            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);
    
            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();
    
            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
    
        }
    
        if(this.userSchool !== null) {
    
            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);
    
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();
    
            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.userSchool[0].schoolrooster_begin}`);
    
            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.userSchool[0].schoolrooster_eind}`);
    
            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);
    
            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);
    
            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();
    
            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
    
        }
    }

    if(type === 'unavailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            this.setState({ incorrectStartTime: true, incorrectEndTime: true });
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        // set correct planner date to check
        // planStart.setDate(date.getDate());
        // planStart.setMonth(date.getMonth());
        // planStart.setFullYear(date.getFullYear());
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // planEnd.setDate(date.getDate());
        // planEnd.setMonth(date.getMonth());
        // planEnd.setFullYear(date.getFullYear());
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }
        if(planStart >= availableStart && planStart <= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: false});
            return;
        }
        if(planEnd >= availableStart && planEnd <= availableEnd) {
            this.setState({incorrectEndTime: true, incorrectStartTime: false});
            return;
        }
        if(planStart <= availableStart && planEnd >= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }

    }

    if(type === 'school') {

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        // set correct planner date to check
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }
        if(planStart >= availableStart && planStart <= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: false});
            return;
        }
        if(planEnd >= availableStart && planEnd <= availableEnd) {
            this.setState({incorrectEndTime: true, incorrectStartTime: false});
            return;
        }
        if(planStart <= availableStart && planEnd >= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }

    }

    this.setState({ incorrectStartTime: false, incorrectEndTime: false });

  }

  checkCorrectStartTimeEditMode(type, availStart, availEnd, planStart) { // redmark

    if(this.state.ignoreAvailability === true) {
        this.setState({ incorrectStartTime: false, incorrectEndTime: false });
        return;
    }

    if(type === 'unavailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            this.setState({ incorrectStartTime: true, incorrectEndTime: true });
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        let planEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.endText}`);

        // set correct planner date to check
        // planStart.setDate(date.getDate());
        // planStart.setMonth(date.getMonth());
        // planStart.setFullYear(date.getFullYear());
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // planEnd.setDate(date.getDate());
        // planEnd.setMonth(date.getMonth());
        // planEnd.setFullYear(date.getFullYear());
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }
        if(planStart >= availableStart && planStart <= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: false});
            return;
        }
        if(planEnd >= availableStart && planEnd <= availableEnd) {
            this.setState({incorrectEndTime: true, incorrectStartTime: false});
            return;
        }
        if(planStart <= availableStart && planEnd >= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }

    }

    if(type === 'sunavailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            this.setState({ incorrectStartTime: true, incorrectEndTime: true });
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        let planEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.endText}`);

        // set correct planner date to check
        // planStart.setDate(date.getDate());
        // planStart.setMonth(date.getMonth());
        // planStart.setFullYear(date.getFullYear());
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // planEnd.setDate(date.getDate());
        // planEnd.setMonth(date.getMonth());
        // planEnd.setFullYear(date.getFullYear());
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }
        if(planStart >= availableStart && planStart <= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: false});
            return;
        }
        if(planEnd >= availableStart && planEnd <= availableEnd) {
            this.setState({incorrectEndTime: true, incorrectStartTime: false});
            return;
        }
        if(planStart <= availableStart && planEnd >= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }

    }

    if(type === 'available') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            return;
        }

        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // console.log('day:', day);
        // console.log('month:', month);
        // console.log('year:', year);
        // console.log('planStart init:', planStart);

        // available start time
        let availableStart = new Date(`${this.userAvailable[0].beschikbaar_datum}T${availStart}`);

        // console.log('availableStart init:', availableStart);

        // available end time
        let availableEnd = new Date(`${this.userAvailable[0].beschikbaar_datum}T${availEnd}`);

        // console.log('availableEnd init:', availableEnd);

        // set correct planner date to check
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // console.log('planStart2:', planStart);

        //availableStart.setSeconds(0, 0);
        //availableEnd.setSeconds(0, 0);
        
        // console.log('availableStart:', Constants.getDateTZD(availableStart.toISOString()));
        // console.log('availableEnd:', Constants.getDateTZD(availableEnd.toISOString()));
        // console.log('planStart:', Constants.getDateTZD(planStart.toISOString()));

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();

        if(availableStart > planStart) {
            this.setState({incorrectStartTime: true});
            return;
        }
        if(availableEnd < planStart) {
            this.setState({incorrectStartTime: true});
            return;
        }

    }

    if(type === 'savailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            return;
        }

        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // console.log('day:', day);
        // console.log('month:', month);
        // console.log('year:', year);
        // console.log('planStart init:', planStart);

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        // console.log('availableEnd init:', availableEnd);

        // set correct planner date to check
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // console.log('planStart2:', planStart);

        //availableStart.setSeconds(0, 0);
        //availableEnd.setSeconds(0, 0);
        
        // console.log('availableStart:', Constants.getDateTZD(availableStart.toISOString()));
        // console.log('availableEnd:', Constants.getDateTZD(availableEnd.toISOString()));
        // console.log('planStart:', Constants.getDateTZD(planStart.toISOString()));

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();

        if(availableStart > planStart) {
            this.setState({incorrectStartTime: true});
            return;
        }
        if(availableEnd < planStart) {
            this.setState({incorrectStartTime: true});
            return;
        }

    }

    this.setState({ incorrectStartTime: false });

  }

  checkCorrectEndTimeEditMode(type, availStart, availEnd, planEnd) { // redmark

    if(this.state.ignoreAvailability === true) {
        this.setState({ incorrectStartTime: false, incorrectEndTime: false });
        return;
    }

    if(type === 'unavailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            this.setState({ incorrectStartTime: true, incorrectEndTime: true });
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        let planStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.startText}`);

        // set correct planner date to check
        // planStart.setDate(date.getDate());
        // planStart.setMonth(date.getMonth());
        // planStart.setFullYear(date.getFullYear());
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // planEnd.setDate(date.getDate());
        // planEnd.setMonth(date.getMonth());
        // planEnd.setFullYear(date.getFullYear());
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }
        if(planStart >= availableStart && planStart <= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: false});
            return;
        }
        if(planEnd >= availableStart && planEnd <= availableEnd) {
            this.setState({incorrectEndTime: true, incorrectStartTime: false});
            return;
        }
        if(planStart <= availableStart && planEnd >= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }

    }

    if(type === 'sunavailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            this.setState({ incorrectStartTime: true, incorrectEndTime: true });
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        let planStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.startText}`);

        // set correct planner date to check
        // planStart.setDate(date.getDate());
        // planStart.setMonth(date.getMonth());
        // planStart.setFullYear(date.getFullYear());
        planStart.setFullYear(year, month, day);
        planStart.setSeconds(0, 0);

        // planEnd.setDate(date.getDate());
        // planEnd.setMonth(date.getMonth());
        // planEnd.setFullYear(date.getFullYear());
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }
        if(planStart >= availableStart && planStart <= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: false});
            return;
        }
        if(planEnd >= availableStart && planEnd <= availableEnd) {
            this.setState({incorrectEndTime: true, incorrectStartTime: false});
            return;
        }
        if(planStart <= availableStart && planEnd >= availableEnd) {
            this.setState({incorrectStartTime: true, incorrectEndTime: true});
            return;
        }

    }

    if(type === 'available') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${this.userAvailable[0].beschikbaar_datum}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${this.userAvailable[0].beschikbaar_datum}T${availEnd}`);

        // set correct planner date to check
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if(availableEnd < planEnd) {
            this.setState({incorrectEndTime: true});
            return;
        }
        if(availableStart > planEnd) {
            this.setState({incorrectEndTime: true});
            return;
        }

    }

    if(type === 'savailable') {

        if(availStart === '00:00:00' && availEnd === '00:00:00') {
            return;
        }

        // date of planner
        const date = Constants.stringToDate(this.props._modalDate);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        // available start time
        let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availStart}`);

        // available end time
        let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${availEnd}`);

        // set correct planner date to check
        planEnd.setFullYear(year, month, day);
        planEnd.setSeconds(0, 0);

        availableStart.setSeconds(0, 0);
        availableEnd.setSeconds(0, 0);

        availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
        availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
        planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

        if(availableEnd < planEnd) {
            this.setState({incorrectEndTime: true});
            return;
        }
        if(availableStart > planEnd) {
            this.setState({incorrectEndTime: true});
            return;
        }

    }

    this.setState({ incorrectEndTime: false });

  }

  checkCorrectTimeSchool(planStart, planEnd, userID) { // redmark

    if(this.state.ignoreAvailability === true) {
        this.setState({ incorrectStartTime: false, incorrectEndTime: false });
        return;
    }

    if(this.props._modalMode === 0) { //select avail, nvt, unavail

        if(typeof this.props._modalData.onbeschikbaar_begin !== 'undefined') {

            if(this.props._modalData.onbeschikbaar_begin === '00:00:00' && this.props._modalData.onbeschikbaar_eind === '00:00:00') {
                this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.onbeschikbaar_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.onbeschikbaar_eind}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }

        }

        if(typeof this.props._modalData.schoolrooster_begin !== 'undefined') {

             // date of planner
             const date = Constants.stringToDate(this.props._modalDate);

             const day = date.getDate();
             const month = date.getMonth();
             const year = date.getFullYear();

             // available start time
             let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.schoolrooster_begin}`);
 
             // available end time
             let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.schoolrooster_eind}`);
 
             // set correct planner date to check
             planStart.setFullYear(year, month, day);
             planStart.setSeconds(0, 0);
 
             planEnd.setFullYear(year, month, day);
             planEnd.setSeconds(0, 0);

             availableStart.setSeconds(0, 0);
             availableEnd.setSeconds(0, 0);

             availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
             availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
             planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
             planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();
  
             if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                 this.setState({incorrectStartTime: true, incorrectEndTime: true});
                 return;
             }
             if(planStart >= availableStart && planStart <= availableEnd) {
                 this.setState({incorrectStartTime: true, incorrectEndTime: false});
                 return;
             }
             if(planEnd >= availableStart && planEnd <= availableEnd) {
                 this.setState({incorrectEndTime: true, incorrectStartTime: false});
                 return;
             }
             if(planStart <= availableStart && planEnd >= availableEnd) {
                 this.setState({incorrectStartTime: true, incorrectEndTime: true});
                 return;
             }

        }

    } else if(this.props._modalMode === 4 && this.type !== null) {

        if(this.type === 'schoolunavailable') {
            this.checkCorrectTimeSchoolEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, planStart, planEnd);
            // this.checkCorrectTimeSchoolEditMode('unavailable', this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, planStart, planEnd);
            // if(this.state.incorrectStartTime === false && this.state.incorrectEndTime === false) {
            //     this.checkCorrectTimeSchoolEditMode('school', this.userSchool[0].schoolrooster_begin, this.userSchool[0].schoolrooster_eind, planStart, planEnd);
            // }
            return;
        }
        if(this.type === 'unavailable') {
            this.checkCorrectTimeSchoolEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, planStart, planEnd);
            return;
        }
        if(this.type === 'school') {
            this.checkCorrectTimeSchoolEditMode(this.type, this.userSchool[0].schoolrooster_begin, this.userSchool[0].schoolrooster_eind, planStart, planEnd);
            return;
        }

        this.setState({ incorrectStartTime: false, incorrectEndTime: false });

    } else {

        const userTimes = this.getUserIDTime(userID);

        if(userTimes === null) {
            return;
        }

        for(const userTime of userTimes) {

            if(userTime.available === Colors.color.redFilledIn) {

                if(userTime.start === '00:00' && userTime.end === '00:00') {
                    this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                    return;
                }

                // date of planner
                const date = Constants.stringToDate(this.props._modalDate);

                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getFullYear();

                // available start time
                let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.start}`);

                // available end time
                let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.end}`);

                // set correct planner date to check
                planStart.setFullYear(year, month, day);
                planStart.setSeconds(0, 0);

                planEnd.setFullYear(year, month, day);
                planEnd.setSeconds(0, 0);

                availableStart.setSeconds(0, 0);
                availableEnd.setSeconds(0, 0);

                availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
                availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
                planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
                planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

                if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                    this.setState({incorrectStartTime: true, incorrectEndTime: true});
                    return;
                }
                if(planStart >= availableStart && planStart <= availableEnd) {
                    this.setState({incorrectStartTime: true, incorrectEndTime: false});
                    return;
                }
                if(planEnd >= availableStart && planEnd <= availableEnd) {
                    this.setState({incorrectEndTime: true, incorrectStartTime: false});
                    return;
                }
                if(planStart <= availableStart && planEnd >= availableEnd) {
                    this.setState({incorrectStartTime: true, incorrectEndTime: true});
                    return;
                }

            }

            if(userTime.available === 'black' && Data.data.appOptions.availability === 'SCHOOL') {
                // date of planner
                const date = Constants.stringToDate(this.props._modalDate);

                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getFullYear();

                // available start time
                let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.start}`);

                // available end time
                let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.end}`);

                // set correct planner date to check
                planStart.setFullYear(year, month, day);
                planStart.setSeconds(0, 0);

                planEnd.setFullYear(year, month, day);
                planEnd.setSeconds(0, 0);

                availableStart.setSeconds(0, 0);
                availableEnd.setSeconds(0, 0);

                availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
                availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
                planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
                planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

                if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                    this.setState({incorrectStartTime: true, incorrectEndTime: true});
                    return;
                }
                if(planStart >= availableStart && planStart <= availableEnd) {
                    this.setState({incorrectStartTime: true, incorrectEndTime: false});
                    return;
                }
                if(planEnd >= availableStart && planEnd <= availableEnd) {
                    this.setState({incorrectEndTime: true, incorrectStartTime: false});
                    return;
                }
                if(planStart <= availableStart && planEnd >= availableEnd) {
                    this.setState({incorrectStartTime: true, incorrectEndTime: true});
                    return;
                }
            }

        }

    }

    this.setState({ incorrectStartTime: false, incorrectEndTime: false });

  }

  checkCorrectStartTime(planStart, userID) { // redmark

    if(this.state.ignoreAvailability === true) {
        this.setState({ incorrectStartTime: false, incorrectEndTime: false });
        return;
    }

    if(this.props._modalMode === 0 || this.props._modalMode === 3) { //select avail, nvt, unavail

        // if unavailable
        if(typeof this.props._modalData.onbeschikbaar_begin !== 'undefined') {

            if(this.props._modalData.onbeschikbaar_begin === '00:00:00' && this.props._modalData.onbeschikbaar_eind === '00:00:00') {
                this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.onbeschikbaar_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.onbeschikbaar_eind}`);

            let planEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.endText}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }

        }

        // if sunavailable
        if(typeof this.props._modalData.sb_beschikbaar !== 'undefined' && this.props._modalData.sb_beschikbaar === '2') {

            if(this.props._modalData.sb_begin === '00:00:00' && this.props._modalData.sb_eind === '00:00:00') {
                this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_eind}`);

            let planEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.endText}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }

        }

        // if available
        if(typeof this.props._modalData.beschikbaar_begin !== 'undefined') {

            if(this.props._modalData.beschikbaar_begin === '00:00:00' && this.props._modalData.beschikbaar_eind === '00:00:00') {
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${this.props._modalData.beschikbaar_datum}T${this.props._modalData.beschikbaar_begin}`);

            // available end time
            let availableEnd = new Date(`${this.props._modalData.beschikbaar_datum}T${this.props._modalData.beschikbaar_eind}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();

            if(availableStart > planStart) {
                this.setState({incorrectStartTime: true});
                return;
            }
            if(availableEnd < planStart) {
                this.setState({incorrectStartTime: true});
                return;
            }

        }

        // if stavailable
        if(typeof this.props._modalData.sb_beschikbaar !== 'undefined' && this.props._modalData.sb_beschikbaar === '1') {

            if(this.props._modalData.sb_begin === '00:00:00' && this.props._modalData.sb_eind === '00:00:00') {
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_eind}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();

            if(availableStart > planStart) {
                this.setState({incorrectStartTime: true});
                return;
            }
            if(availableEnd < planStart) {
                this.setState({incorrectStartTime: true});
                return;
            }

        }

    } else if(this.props._modalMode === 4 && this.type !== null) {

        if(this.type === 'unavailable') {
            this.checkCorrectStartTimeEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, planStart);
            return;
        }

        if(this.type === 'sunavailable') {
            this.checkCorrectStartTimeEditMode(this.type, this.userStUnavailable[0].sb_begin, this.userStUnavailable[0].sb_eind, planStart);
            return;
        }

        if(this.type === 'available') {
            this.checkCorrectStartTimeEditMode(this.type, this.userAvailable[0].beschikbaar_begin, this.userAvailable[0].beschikbaar_eind, planStart);
            return;
        }

        if(this.type === 'savailable') {
            this.checkCorrectStartTimeEditMode(this.type, this.userStAvailable[0].sb_begin, this.userStAvailable[0].sb_eind, planStart);
            return;
        }

    } else { //selecting user id

        if(userID !== -1 && userID !== null && typeof userID !== 'undefined') {

            //get user time
            const userTime = this.getUserIDTime(userID);

            if(userTime === null) {
                return;
            }

            if(userTime.available === Colors.color.redFilledIn) {
                this.setState({incorrectStartTime: true});
                return;
            }

            if(userTime.available === Colors.color.greenFilledIn) {

                if((userTime.start === '00:00' && userTime.end === '00:00')
                    || (userTime.start === '00:01' && userTime.end === '00:01')
                    || (userTime.start === '00:02' && userTime.end === '00:02')) {
                        this.setState({incorrectStartTime: false});
                    return;
                }

                // date of planner
                const date = Constants.stringToDate(this.props._modalDate);

                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getFullYear();

                // available start time
                let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.start}`);

                // available end time
                let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.end}`);

                // set correct planner date to check
                planStart.setFullYear(year, month, day);
                planStart.setSeconds(0, 0);

                availableStart.setSeconds(0, 0);
                availableEnd.setSeconds(0, 0);

                availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
                availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
                planStart = Constants.getDateTZD(planStart.toISOString()).getTime();

                if(availableStart > planStart) {
                    this.setState({incorrectStartTime: true});
                    return;
                }
                if(availableEnd < planStart) {
                    this.setState({incorrectStartTime: true});
                    return;
                }

            }
            
        }

    }

    this.setState({ incorrectStartTime: false});

  }

  checkCorrectEndTime(planEnd, userID) { // redmark

    if(this.state.ignoreAvailability === true) {
        this.setState({ incorrectStartTime: false, incorrectEndTime: false });
        return;
    }

    if(this.props._modalMode === 0 || this.props._modalMode === 3) { //selecting avail,nvt, unavail

        // if unavailable
        if(typeof this.props._modalData.onbeschikbaar_begin !== 'undefined') {

            if(this.props._modalData.onbeschikbaar_begin === '00:00:00' && this.props._modalData.onbeschikbaar_eind === '00:00:00') {
                this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.onbeschikbaar_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.onbeschikbaar_eind}`);

            let planStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.startText}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }

        }

        // if stunavailable
        if(typeof this.props._modalData.sb_beschikbaar !== 'undefined' && this.props._modalData.sb_beschikbaar === '2') {

            if(this.props._modalData.sb_begin === '00:00:00' && this.props._modalData.sb_eind === '00:00:00') {
                this.setState({ incorrectStartTime: true, incorrectEndTime: true });
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_eind}`);

            let planStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.state.startText}`);

            // set correct planner date to check
            planStart.setFullYear(year, month, day);
            planStart.setSeconds(0, 0);

            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planStart = Constants.getDateTZD(planStart.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if((planStart >= availableStart && planStart <= availableEnd) && (planEnd >= availableStart && planEnd <= availableEnd)) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }
            if(planStart >= availableStart && planStart <= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: false});
                return;
            }
            if(planEnd >= availableStart && planEnd <= availableEnd) {
                this.setState({incorrectEndTime: true, incorrectStartTime: false});
                return;
            }
            if(planStart <= availableStart && planEnd >= availableEnd) {
                this.setState({incorrectStartTime: true, incorrectEndTime: true});
                return;
            }

        }

        // if available
        if(typeof this.props._modalData.beschikbaar_begin !== 'undefined') {

            if(this.props._modalData.beschikbaar_begin === '00:00:00' && this.props._modalData.beschikbaar_eind === '00:00:00') {
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${this.props._modalData.beschikbaar_datum}T${this.props._modalData.beschikbaar_begin}`);

            // available end time
            let availableEnd = new Date(`${this.props._modalData.beschikbaar_datum}T${this.props._modalData.beschikbaar_eind}`);

            // set correct planner date to check
            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if(availableEnd < planEnd) {
                this.setState({incorrectEndTime: true});
                return;
            }
            if(availableStart > planEnd) {
                this.setState({incorrectEndTime: true});
                return;
            }

        }

        // if stavailable
        if(typeof this.props._modalData.sb_beschikbaar !== 'undefined' && this.props._modalData.sb_beschikbaar === '1') {

            if(this.props._modalData.sb_begin === '00:00:00' && this.props._modalData.sb_eind === '00:00:00') {
                return;
            }

            // date of planner
            const date = Constants.stringToDate(this.props._modalDate);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            // available start time
            let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_begin}`);

            // available end time
            let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${this.props._modalData.sb_eind}`);

            // set correct planner date to check
            planEnd.setFullYear(year, month, day);
            planEnd.setSeconds(0, 0);

            availableStart.setSeconds(0, 0);
            availableEnd.setSeconds(0, 0);

            availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
            availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
            planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

            if(availableEnd < planEnd) {
                this.setState({incorrectEndTime: true});
                return;
            }
            if(availableStart > planEnd) {
                this.setState({incorrectEndTime: true});
                return;
            }

        }

    } else if(this.props._modalMode === 4 && this.type !== null) {

        if(this.type === 'unavailable') {
            this.checkCorrectEndTimeEditMode(this.type, this.userUnavailable[0].onbeschikbaar_begin, this.userUnavailable[0].onbeschikbaar_eind, planEnd);
            return;
        }

        if(this.type === 'sunavailable') {
            this.checkCorrectEndTimeEditMode(this.type, this.userStUnavailable[0].sb_begin, this.userStUnavailable[0].sb_eind, planEnd);
            return;
        }

        if(this.type === 'available') {
            this.checkCorrectEndTimeEditMode(this.type, this.userAvailable[0].beschikbaar_begin, this.userAvailable[0].beschikbaar_eind, planEnd);
            return;
        }

        if(this.type === 'available') {
            this.checkCorrectEndTimeEditMode(this.type, this.userStAvailable[0].sb_begin, this.userStAvailable[0].sb_eind, planEnd);
            return;
        }

    } else {

        if(userID !== -1 && userID !== null && typeof userID !== 'undefined') {

            //get user time
            const userTime = this.getUserIDTime(userID);

            if(userTime === null) {
                return;
            }

            if(userTime.available === Colors.color.redFilledIn) {
                this.setState({incorrectEndTime: true});
                return;
            }

            if(userTime.available === Colors.color.greenFilledIn) {

                if((userTime.start === '00:00' && userTime.end === '00:00')
                    || (userTime.start === '00:01' && userTime.end === '00:01')
                    || (userTime.start === '00:02' && userTime.end === '00:02')) {
                        this.setState({incorrectEndTime: false});
                    return;
                }

                // date of planner
                const date = Constants.stringToDate(this.props._modalDate);

                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getFullYear();

                // available start time
                let availableStart = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.start}:00`);

                // available end time
                let availableEnd = new Date(`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}T${userTime.end}:00`);

                // set correct planner date to check
                planEnd.setFullYear(year, month, day);
                planEnd.setSeconds(0, 0);

                availableStart.setSeconds(0, 0);
                availableEnd.setSeconds(0, 0);

                availableStart = Constants.getDateTZD(availableStart.toISOString()).getTime();
                availableEnd = Constants.getDateTZD(availableEnd.toISOString()).getTime();
                planEnd = Constants.getDateTZD(planEnd.toISOString()).getTime();

                if(availableEnd < planEnd) {
                    this.setState({incorrectEndTime: true});
                    return;
                }
                if(availableStart > planEnd) {
                    this.setState({incorrectEndTime: true});
                    return;
                }

            }
            
        }

    }

    this.setState({ incorrectEndTime: false });

  }

  selectUser(event) {

    this.type = null;

    if (this.props._modalData.info_id === event.target.value && this.props._modalMode === 4) { // redmark

        this.type = Data.data.appOptions.availability === 'STANDARD' && this.userStAvailable.length > 0 ? 'savailable'
                    : Data.data.appOptions.availability === 'STANDARD' && this.userStUnavailable.length > 0 ? 'sunavailable'
                    : this.userUnavailable !== null && this.userSchool !== null && Data.data.appOptions.availability === 'SCHOOL' ? 'schoolunavailable'
                    : this.userUnavailable !== null ? 'unavailable'
                    : this.userSchool !== null && Data.data.appOptions.availability === 'SCHOOL' ? 'school'
                    : this.userAvailable !== null ? 'available' : null; // redmark

        const userFullName = `${this.props._modalData.info_voornaam} ${(this.props._modalData.info_tussenvoegsel !== null && this.props._modalData.info_tussenvoegsel !== '') ? this.props._modalData.info_tussenvoegsel + " " + this.props._modalData.info_achternaam.charAt(0) : this.props._modalData.info_achternaam.charAt(0)}.`;    

        if (this.type === 'unavailable') {
            if(this.userUnavailable[0].onbeschikbaar_begin === '00:00:00' && this.userUnavailable[0].onbeschikbaar_eind === '00:00:00') {
                this.setState({ textUnavailable: `${userFullName} is de hele dag onbeschikbaar` });
            } else {
                this.setState({ textUnavailable: `${userFullName} is onbeschikbaar van ${this.userUnavailable[0].onbeschikbaar_begin.substring(0, 5)} tot ${this.userUnavailable[0].onbeschikbaar_eind.substring(0, 5)}` });
            }
        }
        if (this.type === 'sunavailable') {
            if(this.userStUnavailable[0].sb_begin === '00:00:00' && this.userStUnavailable[0].sb_eind === '00:00:00') {
                this.setState({ textUnavailable: `${userFullName} is de hele dag onbeschikbaar` });
            } else {
                this.setState({ textUnavailable: `${userFullName} is onbeschikbaar van ${this.userStUnavailable[0].sb_begin.substring(0, 5)} tot ${this.userStUnavailable[0].sb_eind.substring(0, 5)}` });
            }
        }
        if (this.type === 'available') {
            if(Data.data.appOptions.availability !== 'SCHOOL') {
                if(this.userAvailable[0].beschikbaar_begin === '00:00:00' && this.userAvailable[0].beschikbaar_eind === '00:00:00') {
                    this.setState({ textAvailable: `${userFullName} is de hele dag beschikbaar` });
                } else {
                    this.setState({ textAvailable: `${userFullName} is beschikbaar van ${this.userAvailable[0].beschikbaar_begin.substring(0, 5)} tot ${this.userAvailable[0].beschikbaar_eind.substring(0, 5)}` });
                }
            }
        }
        if (this.type === 'savailable') {
            if(Data.data.appOptions.availability !== 'SCHOOL') {
                if(this.userStAvailable[0].sb_begin === '00:00:00' && this.userStAvailable[0].sb_eind === '00:00:00') {
                    this.setState({ textAvailable: `${userFullName} is de hele dag beschikbaar` });
                } else {
                    this.setState({ textAvailable: `${userFullName} is beschikbaar van ${this.userStAvailable[0].sb_begin.substring(0, 5)} tot ${this.userStAvailable[0].sb_eind.substring(0, 5)}` });
                }
            }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
            if (this.type === 'school') {
                this.setState({ textSchool: `${userFullName} heeft school van ${this.userSchool[0].schoolrooster_begin.substring(0, 5)} tot ${this.userSchool[0].schoolrooster_eind.substring(0, 5)}` });
            }
            if (this.type === 'schoolunavailable') {
                this.setState({ textSchool: `${userFullName} heeft school van ${this.userSchool[0].schoolrooster_begin.substring(0, 5)} tot ${this.userSchool[0].schoolrooster_eind.substring(0, 5)}` });
                if(this.userUnavailable[0].onbeschikbaar_begin === '00:00:00' && this.userUnavailable[0].onbeschikbaar_eind === '00:00:00') {
                    this.setState({ textUnavailable: `${userFullName} is de hele dag onbeschikbaar` });
                } else {
                    this.setState({ textUnavailable: `${userFullName} is onbeschikbaar van ${this.userUnavailable[0].onbeschikbaar_begin.substring(0, 5)} tot ${this.userUnavailable[0].onbeschikbaar_eind.substring(0, 5)}` });
                }
            }
        }

    }

    this.setState({
        userID: parseInt(event.target.value),
        showError: false,
    });

    if(this.type === null) {
        this.setState({
            textUnavailable: '', // reset
            textSchool: '',
            textAvailable: '',
        });
    }

    if(Data.data.appOptions.availability === 'SCHOOL') { // redmark
        this.checkCorrectTimeSchool(this.state.startTime, this.state.endTime, event.target.value);
    } else {
        this.checkCorrectStartTime(this.state.startTime, event.target.value);
        this.checkCorrectEndTime(this.state.endTime, event.target.value);
    }

    if(this.props._modalData.info_id !== event.target.value) {

        const person = this.getUserIDTime(event.target.value);

        this.setState({
            nameUser: typeof person.length === 'undefined' ? person.name : person[0].name,
        });

        if (person.available === Colors.color.redFilledIn) {
            if(person.start === '00:00' && person.end === '00:00') {
                this.setState({ textUnavailable: `${person.name} is de hele dag onbeschikbaar` });
            } else {
                this.setState({ textUnavailable: `${person.name} is onbeschikbaar van ${person.start} tot ${person.end}` });
            }
        }
        if (person.available === Colors.color.greenFilledIn) {
            if(Data.data.appOptions.availability !== 'SCHOOL') {
                if(person.start === '00:00' && person.end === '00:00') {
                    this.setState({ textAvailable: `${person.name} is de hele dag beschikbaar` });
                } else {
                    this.setState({ textAvailable: `${person.name} is beschikbaar van ${person.start} tot ${person.end}` });
                }
            }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
            for(const data of person) {
                if(data.available === Colors.color.redFilledIn) {
                    if(data.start === '00:00' && data.end === '00:00') {
                        this.setState({ textUnavailable: `${data.name} is de hele dag onbeschikbaar` });
                    } else {
                        this.setState({ textUnavailable: `${data.name} is onbeschikbaar van ${data.start} tot ${data.end}` });
                    }
                }
                if (data.available === 'black') {
                    this.setState({ textSchool: `${data.name} heeft school van ${data.start} tot ${data.end}` });
                }
            }
        }

    }

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

    if(this.started === true) { // redmark
        if(Data.data.appOptions.availability === 'SCHOOL') {
            this.checkCorrectTimeSchool(date, this.state.endTime, this.state.userID);
        } else {
            this.checkCorrectStartTime(date, this.state.userID);
        }
    }

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

    if(this.started === true) { // redmark
        if(Data.data.appOptions.availability === 'SCHOOL') {
            this.checkCorrectTimeSchool(this.state.startTime, date, this.state.userID);
        } else {
            this.checkCorrectEndTime(date, this.state.userID);
        }
    }

  }

  setBreakTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        breakTime: date,
        breakText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  toggleSwitch() {

    this.setState({
        break: !this.state.break,
        breakTime: new Date().setHours(0, 0),
        breakText: '00:00',
        showError: false,
    });

  }

  deleteUserPlanner() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze dienst wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteUser.bind(this),
        showAlert: true,
    });

  }

  async deleteUser() {

    this.setState({loading: true});

    const deleted = await Plan.deleteUserPlanner(this.props._modalData);

    if(deleted === true) {

        Logger.deletePlanner(this.props._modalData);

        this.props._closeModal();
        this.props._reloadScreen(this.state.date);

        return;

    } else {
        // console.log('error');
        this.setState({loading: false});
    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async saveUserPlanner() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    let added = false;

    if(this.props._modalMode === 4) { // edit mode

        const toPlan = {
            rooster_eind: this.state.endText,
            info_id: this.props._modalData.info_id,
        };

        if (this.props._modalData.info_id.toString() === this.state.userID.toString()) { // EDIT same day same user

            if (Plan.checkInfoExtraEditNoDayCheck(this.props._infoExtra[this.state.userID.toString()], this.state.userID.toString(), this.props._modalData.rooster_datum, toPlan) === false) {
                this.setState({ loading: false });
                return;
            }

        } else { // ADD same day other user from av (w.e.)

            if (Plan.checkInfoExtraAdd(this.props._infoExtra[this.state.userID.toString()], this.state.userID.toString(), this.props._modalData.rooster_datum, toPlan, this.props._hoursWeek, this.props._planUsersMaxCumulative) === false) {
                this.setState({ loading: false });
                return;
            }

        }

        if(parseInt(this.props._modalData.functie_id) !== this.state.functionID) {
            await APIDeleteUsedDayShift.Request(this.props._modalData.rooster_id);
        }

        added = await Plan.editUserPlanner(
            this.state.startText,
            this.state.endText,
            this.state.functionID,
            this.state.userID,
            this.props._modalData.rooster_id,
            this.state.break === true ? this.state.breakText : '00:00',
            this.state.incorrectStartTime === true || this.state.incorrectEndTime === true ? 1 : 0,
        );

        Logger.editPlannerModal(this.props._modalData, this.state, Constants.LOGGER_EDIT);

    } else {

        const _userid = this.props._modalMode === 0 ? parseInt(this.props._modalData.info_id)
        : this.props._modalMode === 1 || this.props._modalMode === 2 || this.props._modalMode === 5 ? this.state.userID
        : this.props._modalMode === 3 ? parseInt(this.props._modalData.info_id) : this.state.userID;

        const toPlan = {
            rooster_eind: this.state.endText,
            info_id: _userid.toString(),
        };

        if (Plan.checkInfoExtraAdd(this.props._infoExtra[_userid.toString()], _userid.toString(), this.state.date.toISOString().substr(0,10), toPlan, this.props._hoursWeek, this.props._planUsersMaxCumulative) === false) {
            this.setState({ loading: false });
            return;
        }

        added = await Plan.planUserModal(
            this.state.date, 
            this.state.startText, 
            this.state.endText,
            this.state.functionID,
            this.props._modalMode === 0 ? parseInt(this.props._modalData.info_id)
            : this.props._modalMode === 1 || this.props._modalMode === 2 || this.props._modalMode === 5 ? this.state.userID
            : this.props._modalMode === 3 ? parseInt(this.props._modalData.info_id) : null,
            this.state.break === true ? this.state.breakText : '00:00',
            this.state.incorrectStartTime === true || this.state.incorrectEndTime === true ? 1 : 0,
        );

        Logger.addPlannerModal(this.state);

    }

    if(added !== null) {

        if(this.props._modalMode === 1) {
            await this.props._putOpenShiftOnUnavailable(this.props._modalData);
            //await APIAddUsedDayShift.Request(`${this.state.date.getFullYear()}-${parseInt(this.state.date.getMonth()) + 1}-${this.state.date.getDate()}`, this.props._modalData.rooster_begin, this.props._modalData.rooster_eind, added, this.props._modalData.functie_id);
            await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${this.state.date.getFullYear()}-${parseInt(this.state.date.getMonth()) + 1}-${this.state.date.getDate()}', ${this.props._modalData.dagdienst_id}, ${added})`);

            if (this.props._modalData.rooster_publiceren === '3') {
                await APIDeleteUserPlanner.Request(this.props._modalData.rooster_id);
            }
        }

        this.props._closeModal();

        this.props._reloadScreen(this.state.date);

        // if(this.props._modalMode === 0 || this.props._modalMode === 2 || this.props._modalMode === 3) {

        //     this.props._reloadScreen(this.state.date);

        // }

        // if(this.props._modalMode === 1 || this.props._modalMode === 4) {

        //     this.props._reloadScreen(this.state.date);

        // }

        return;

    } else {
        // console.log('error');
        this.setState({loading: false});
    }

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {
      
    if(this.props._modalMode === 1 || this.props._modalMode === 2 || this.props._modalMode === 5) {
        if(this.state.userID < 0) {
            this.errorText = 'Je hebt geen medewerker geselecteerd';
            this.setState({showError: true});
            return false;
        }
    }
    if(this.props._modalMode === 5) {
        if(this.state.date === null) {
            this.errorText = 'Je hebt geen datum geselecteerd';
            this.setState({showError: true});
            return false;
        }
    }
    if(this.state.startTime.getTime() === this.state.endTime.getTime()) {
        this.errorText = 'Begintijd kan niet gelijk zijn aan eindtijd';
        this.setState({showError: true});
        return false;
    }
    if(this.state.startTime.getTime() > this.state.endTime.getTime()) {
        this.errorText = 'Begintijd kan niet later zijn dan eindtijd';
        this.setState({showError: true});
        return false;
    }
    if(this.state.functionID < 0 || this.state.functionID === undefined || this.state.functionID === null || isNaN(this.state.functionID)) {
        this.errorText = 'Je hebt geen functie geselecteerd';
        this.setState({showError: true});
        return false;
    }

    return true;

  }

  selectDate(date) {

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    this.setState({
        date: date,
        dateText: `${day < 10 ? `0`+ day : day}-${month < 10 ? `0`+ month : month}-${year}`,
        day: day,
        month: month,
        year: year,
    });

  }

  render() {

    let planData = null;
    let userFullName = null;

    if(this.props._modalMode === 0 || this.props._modalMode === 3) {
        planData = this.props._modalData;
        userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;    
    }

    const defaultTimes = this.getDefaultTimes();
    const functions = this.getFunctions();
    const users = this.getUsers();

    return (
        <div id="planModal" className="modal" onClick={(e) => e.target === document.getElementById("zplanModal") ? this.props._closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>

            {this.props._modalMode !== 5 ?
                <p>{Constants.getDayName(this.state.date)} {this.state.day} {Constants.getMonthName(this.state.date)} {this.state.year}</p>
            :
                <p>Toevoegen</p>
            }
          </div>

          {this.state.screenLoading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

                <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                    {this.state.showAlert === true ?
                        
                        <BetterAlert
                            _closeAlert={this.closeAlert.bind(this)}
                            _title={this.state.alertTitle}
                            _body={this.state.alertBody}
                            _buttons={this.state.alertButtons}
                            _buttonText={this.state.alertButtonText}
                            _buttonAction={this.state.alertButtonAction}
                        />

                    :

                        null
                    
                    }

                    {this.props._modalMode === 0 || this.props._modalMode === 3 ?
                    
                        <div className='plan_modal-account'>

                            {userFullName} inroosteren op

                            <div style={{fontStyle: 'italic', marginTop: 5,}}>{Constants.getDayName(this.state.date)} {this.state.day} {Constants.getMonthName(this.state.date)} {this.state.year}</div>

                            {Data.data.appOptions.availability === 'AVAILABILITY' ?
                            
                                this.notFilled === true ?
                                
                                    <div style={{fontStyle: 'italic', marginTop: 5, textAlign: 'center'}}>
                                        Geen beschikbaarheid aangegeven voor deze dag
                                    </div>

                                :
                                this.available === true ?
                                
                                    <div style={{fontStyle: 'italic', marginTop: 5,}}>
                                        {this.wholeDay === true ?
                                        
                                            <div>Hele dag beschikbaar</div>

                                        :

                                            <div>Beschikbaar van {this.props._modalData.beschikbaar_begin.substring(0,5)} - {this.props._modalData.beschikbaar_eind.substring(0,5)}</div>

                                        }
                                    </div>

                                :
                                this.unavailable === true ?
                                
                                    <div style={{fontStyle: 'italic', marginTop: 5,}}>
                                        {this.wholeDay === true ?
                                            
                                            <div style={{color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>

                                        :

                                            <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar van {this.props._modalData.onbeschikbaar_begin.substring(0,5)} - {this.props._modalData.onbeschikbaar_eind.substring(0,5)}</div>

                                        }
                                    </div>

                                :

                                    null
                            :
                            Data.data.appOptions.availability === 'STANDARD' ?
                            
                                this.notFilled === true ?
                                
                                    <div style={{fontStyle: 'italic', marginTop: 5, textAlign: 'center'}}>
                                        Geen beschikbaarheid aangegeven voor deze dag
                                    </div>

                                :
                                this.savailable === true ?
                                
                                    <div style={{fontStyle: 'italic', marginTop: 5,}}>
                                        {this.wholeDay === true ?
                                        
                                            <div>Hele dag beschikbaar</div>

                                        :

                                            <div>Beschikbaar van {this.props._modalData.sb_begin.substring(0,5)} - {this.props._modalData.sb_eind.substring(0,5)}</div>

                                        }
                                    </div>

                                :
                                this.sunavailable === true ?
                                
                                    <div style={{fontStyle: 'italic', marginTop: 5,}}>
                                        {this.wholeDay === true ?
                                            
                                            <div style={{color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>

                                        :

                                            <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar van {this.props._modalData.sb_begin.substring(0,5)} - {this.props._modalData.sb_eind.substring(0,5)}</div>

                                        }
                                    </div>

                                :

                                    null
                            :
                            Data.data.appOptions.availability === 'SCHOOL' ?

                                this.props._modalData.schoolrooster_begin !== undefined && this.props._modalData.onbeschikbaar_begin !== undefined ?

                                    this.wholeDay === true ?

                                        <div>
                                            <div style={{marginTop: 5, textAlign: 'center', fontStyle: 'italic' }}>School van {this.props._modalData.schoolrooster_begin.substring(0, 5)} - {this.props._modalData.schoolrooster_eind.substring(0, 5)}</div>
                                            <div style={{ color: Colors.color.redFilledIn, marginTop: 5, textAlign: 'center', fontStyle: 'italic' }}>Hele dag onbeschikbaar</div>
                                        </div>

                                    :

                                        <div>
                                            <div style={{marginTop: 5, textAlign: 'center', fontStyle: 'italic' }}>School van {this.props._modalData.schoolrooster_begin.substring(0, 5)} - {this.props._modalData.schoolrooster_eind.substring(0, 5)}</div>
                                            <div style={{ color: Colors.color.redFilledIn, marginTop: 5, textAlign: 'center', fontStyle: 'italic' }}>Onbeschikbaar van {this.props._modalData.onbeschikbaar_begin.substring(0,5)} - {this.props._modalData.onbeschikbaar_eind.substring(0,5)}</div>
                                        </div>

                                :

                                this.props._modalData.schoolrooster_begin !== undefined ?

                                    <div style={{marginTop: 5, textAlign: 'center', fontStyle: 'italic'}}>School van {this.props._modalData.schoolrooster_begin.substring(0, 5)} - {this.props._modalData.schoolrooster_eind.substring(0, 5)}</div>

                                :

                                this.unavailable === true ?

                                    this.wholeDay === true ?

                                        <div style={{ color: Colors.color.redFilledIn, marginTop: 5, textAlign: 'center', fontStyle: 'italic' }}>Hele dag onbeschikbaar</div>

                                    :

                                        <div style={{ color: Colors.color.redFilledIn, marginTop: 5, textAlign: 'center', fontStyle: 'italic' }}>Onbeschikbaar van {this.props._modalData.onbeschikbaar_begin.substring(0,5)} - {this.props._modalData.onbeschikbaar_eind.substring(0,5)}</div>

                                :

                                <div style={{marginTop: 5, textAlign: 'center', fontStyle: 'italic'}}>Hele dag beschikbaar</div>

                            :
                            Data.data.appOptions.availability === 'UNAVAILABILITY' ?

                                this.unavailable === true ?
                                    
                                    <div style={{fontStyle: 'italic', marginTop: 5,}}>
                                        {this.wholeDay === true ?
                                            
                                            <div style={{color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>

                                        :

                                            <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar van {this.props._modalData.onbeschikbaar_begin.substring(0,5)} - {this.props._modalData.onbeschikbaar_eind.substring(0,5)}</div>

                                        }
                                    </div>

                                :

                                    null

                            :

                                null
                            
                            }

                            {this.available === true && this.props._modalData.beschikbaar_opmerking !== '' && this.props._modalData.beschikbaar_opmerking !== null ?
                                        
                                <div><i className={'far fa-comment'} style={{marginRight: 5, marginTop: 5}}></i>{this.props._modalData.beschikbaar_opmerking}</div>

                            : null }

                            {this.unavailable === true && this.props._modalData.onbeschikbaar_opmerking !== undefined && this.props._modalData.onbeschikbaar_opmerking !== '' && this.props._modalData.onbeschikbaar_opmerking !== null ?
                                        
                                <div><i className={'far fa-comment'} style={{marginRight: 5, marginTop: 5}}></i>{this.props._modalData.onbeschikbaar_opmerking}</div>

                            : null }

                        </div>

                    :
                    this.props._modalMode === 99 ?

                        <div className='plan_modal-account'>

                            {userFullName} inroosteren op

                            <div style={{fontStyle: 'italic', marginTop: 5,}}>{Constants.getDayName(this.state.date)} {this.state.day} {Constants.getMonthName(this.state.date)} {this.state.year}</div>

                        </div>

                    :

                        null
                
                    }

                    <div className='plan_modal-form'>

                        {this.props._modalMode === 1 || this.props._modalMode === 2 || this.props._modalMode === 4 || this.props._modalMode === 5 ?

                            <div>

                                {this.state.textUnavailable !== '' || this.state.textAvailable !== '' || this.state.textSchool !== '' ?
                                    
                                    <div style={{marginBottom: 30}}>

                                        {this.state.textAvailable !== '' ? <div style={{ textAlign: 'center', color: this.state.incorrectStartTime === true || this.state.incorrectEndTime === true ? Colors.color.redFilledIn : 'black' }}>{this.state.textAvailable}</div> : null}
                                        {this.state.textSchool !== '' ? <div style={{ textAlign: 'center', color: this.state.textUnavailable === '' && (this.state.incorrectStartTime === true || this.state.incorrectEndTime === true) ? Colors.color.redFilledIn : 'black' }}>{this.state.textSchool}</div> : null}
                                        {this.state.textUnavailable !== '' ? <div style={{ marginTop: this.state.textSchool !== '' ? 5 : 0, textAlign: 'center', color: Colors.color.redFilledIn }}>{this.state.textUnavailable}</div> : null}

                                    </div>

                                :

                                    null

                                }

                                <div>Wie:</div>

                                <select name="Selecteer een medewerker" className='dropDownField' onChange={(event) => this.selectUser(event)} style={{marginTop: 0, marginBottom: 10}}>
                                    <option value="" selected={true} disabled hidden>Selecteer een medewerker</option>
                                    {users}
                                </select>

                            </div>

                        :

                            null

                        }

                        {this.props._modalMode === 5 ?

                            <div style={{marginBottom: 10}}>

                                <div>Wanneer:</div>

                                <DatePicker
                                    selected={this.state.date}
                                    placeholderText={'Selecteer een datum'}
                                    dateFormat="dd-MM-yyyy"
                                    className={'inputFieldTime'}
                                    showWeekNumbers
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                    locale={nl}
                                    onChange={(date) => this.selectDate(date)}
                                />

                            </div>

                        :

                            null

                        }

                        <select 
                            name="Vaste tijden" 
                            className={this.state.disabledInput === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                            disabled={this.state.disabledInput}
                            onChange={(event) => this.selectTime(event)}
                        >
                            <option value="" selected={true} disabled hidden>Vaste tijden</option>
                            {defaultTimes}
                        </select>

                        <div style={{marginTop: 5}}>Van:</div>

                        <DatePicker
                            selected={this.state.startTime}
                            onChange={(date) => this.setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Van"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className={this.state.disabledInput === true ? this.state.incorrectStartTime === true ? 'inputFieldTimeDisabledIncorrect' : 'inputFieldTimeDisabled' : this.state.incorrectStartTime === true ? 'inputFieldTimeIncorrect' : 'inputFieldTime'}
                            disabled={this.state.disabledInput}
                        />

                        <div style={{marginTop: 5}}>Tot:</div>

                        <DatePicker
                            selected={this.state.endTime}
                            onChange={(date) => this.setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Tot"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className={this.state.disabledInput === true ? this.state.incorrectEndTime === true ? 'inputFieldTimeDisabledIncorrect' : 'inputFieldTimeDisabled' : this.state.incorrectEndTime === true ? 'inputFieldTimeIncorrect' : 'inputFieldTime'}
                            disabled={this.state.disabledInput}
                        />

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>

                            Negeer beschikbaarheid

                            <label className="switch" style={{marginLeft: 25}}>
                                <input 
                                type="checkbox" 
                                checked={this.state.ignoreAvailability} 
                                onClick={() => this.ignoreAvailability()}
                                readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 5}}>

                            Pauze

                            <label className="switch" style={{marginLeft: 25}}>
                                <input 
                                type="checkbox" 
                                checked={this.state.break} 
                                onClick={() => this.toggleSwitch()}
                                readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                        </div>

                        {this.state.break === true ?
                        
                            <DatePicker
                                selected={this.state.breakTime}
                                onChange={(date) => this.setBreakTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Pauze"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                minTime={new Date().setHours(0, 0)}
                                maxTime={new Date().setHours(4, 0)}
                                className={'inputFieldTime'}
                            />

                        :

                            null
                    
                        }

                        <div style={{marginTop: 10}}>Als:</div>

                        <select 
                            name="Selecteer een functie" 
                            className={this.state.disabledFunction === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                            disabled={this.state.disabledFunction}
                            style={{marginTop: 0}} 
                            onChange={(event) => this.selectFunction(event)}
                        >
                            <option value="" selected disabled hidden>Selecteer een functie</option>
                            {functions}
                        </select>

                    </div>
                
                    <div className='plan_modal-submit'>

                        {this.state.loading === true ?

                            <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                        :
                        this.props._modalMode === 4 ?

                            <div>

                                <div className='submitButton' onClick={() => this.saveUserPlanner()}>Opslaan</div>

                                <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteUserPlanner()}>Verwijderen</div>

                            </div>

                        :

                            <div className='submitButton' onClick={() => this.saveUserPlanner()}>Toevoegen</div>

                        }

                        {this.showError()}

                    </div>

                </div>

            }

        </div>

      </div>
    );
  }
  
}

export default PlanModal;