/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './employees.css'
import '../options/myHours/myHours.css';
import '../availability/availability.css';
import '../../components/smartPlanModal/smartPlanModal.css';
import '../planner/planner.scss';
import '../schedule/schedule.scss';
import '../../constants/DefaultStyle.css';
import '../options/accounts/accounts.css';
import '../options/hoursRegistration/hoursRegistration.css';
import '../options/appOptions/appOptions.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGetAdminAccountInfo from '../../classes/APIGetAdminAccountInfo';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGetAllContracts from '../../classes/APIGetAllContracts';
import Availabilities from '../options/availabilities/availabilities';
import APIEditAccount from '../../classes/APIEditAccount';
import APIEditAccountInfo from '../../classes/APIEditAccountInfo';
import CryptoJS from 'crypto-js';
import APICheckUsernameAvailibility from '../../classes/APICheckUsernameAvailibility';
import APIDeleteAccount from '../../classes/APIDeleteAccount';
import APIAddContract from '../../classes/APIAddContract';
import APIEditContract from '../../classes/APIEditContract';
import APIGetAccountInfo from '../../classes/APIGetAccountInfo';
import Colors from '../../constants/Colors';
import APIEditContractNulUren from '../../classes/APIEditContractNulUren';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGetTeamsOwn from '../../classes/APIGetTeamsOwn';
import APIDeleteFromTeam from '../../classes/APIDeleteFromTeam';
import APIAddToTeam from '../../classes/APIAddToTeam';
import APIGetSkills from '../../classes/APIGetSkills';
import APIGetAllSkillsUsers from '../../classes/APIGetAllSkillsUsers';
import APIAddSkillUser from '../../classes/APIAddSkillUser';
import APIDeleteSkillUser from '../../classes/APIDeleteSkillUser';
import APIGetAllHoursMin from '../../classes/APIGetAllHoursMin';
import { faCoins, faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';
import APIGetAllAvailabilitiesYear from '../../classes/APIGetAllAvailabilitiesYear';
import APIGetAllAvailabilitiesDay from '../../classes/APIGetAllAvailabilitiesDay';
import APIGetAllAvailabilitiesMin from '../../classes/APIGetAllAvailabilitiesMin';
import APIGetAllUnavailabilitiesMin from '../../classes/APIGetAllUnavailabilitiesMin';
import APIGetAllAbsencesUser from '../../classes/APIGetAllAbsencesUser';
import APIGetAbsencetypes from '../../classes/APIGetAbsencetypes';

import APIGET from '../../classes/global/APIGET.js';
import APIDELETE from '../../classes/global/APIDELETE.js';
import APIEDIT from '../../classes/global/APIEDIT.js';
import APIADD from '../../classes/global/APIADD.js';
import EmployeesDownloadName from '../../components/excel/employeesDownloadName';
import ContractEndModal from './contractEndModal';
import APIGetOptions from '../../classes/APIGetOptions';

import { saveAs } from 'file-saver';
import APIDelFile from '../../classes/APIDelFile';
import APIDelFileContract from '../../classes/APIDelFileContract';
import APIUploadFile from '../../classes/APIUploadFile';
import UploadEmployeesModal from '../../components/uploadEmployeesModal/uploadEmployeesModal';
import KodictNotifications from '../../constants/KodictNotifications';
import { Tooltip } from 'react-tippy';
import UserLeaveBalanceModal from './userLeaveBalanceModal';
import ContractModal from './contractModal';
import CorrectionModal from './correctionModal';
import { Prompt } from 'react-router';
import Reports from '../options/reports/reports';
import AddHolidayModal from '../options/holidayRequests/addHolidayModal';
import EmployeeNoteModal from './employeeNoteModal';
import APIUploadContract from '../../classes/APIUploadContract';

import { PDFDocument } from 'pdf-lib';
import planClass from '../planner/plan.class';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import SignContractModal from '../../components/signContractModal/signContractModal';
import __ from '../../constants/__';
import Localization from '../../constants/Localization';
import APIUpdateQuantity from '../../classes/payment/APIUpdateQuantity';

class Employees extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    const { employeeid } = this.props.match.params;

    // from params pathname
    this.paramSelected = this.props.location.selected !== undefined && this.props.location.selected !== null ? this.props.location.selected : Constants.isEmpty(employeeid) === false ? employeeid : null;
    this.paramSelectedName = this.props.location.selectedName !== undefined && this.props.location.selectedName !== null ? this.props.location.selectedName : null;
    this.paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : null;

    this.teamMenu = false;

    this.navBar = React.createRef();

    this.userData = {};

    this.teamUsers = [];

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.timeRef = {};
    this.lastDeleted = null;
    this.lastVal = '';

    this.timeNow = new Date();

    // compwillmount

    this.state = {

      loading: true,
      reloading: false,

      selected: 'all',
      selectedName: '',

      menu: 'account',

      accounts: [],
      accountsIter: {},
      secureData: [],
      secureDataIter: {},
      functions: [],
      userData: {},
      contracts: {},

      showModal: false,
      modalMode: 0,
      modalData: null,
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

      userData: {},
      hasContract: false,
      password1: '',
      password2: '',
      rights: 0,

      showPassContainer: false,

      changed: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      showError: false,
      errorText: '',

      teams: [],

      missingTeamPeople: [],
      missingJobPeople: [],

      skills: [],
      skillsUsers: [],
      employeeSkills: [],

      statYear: new Date().getFullYear(),

      allHours: { jan: {}, feb: {}, mrt: {}, apr: {}, mei: {}, jun: {}, jul: {}, aug: {}, sept: {}, okt: {}, nov: {}, dec: {} },
      allAvailabilities: { jan: {}, feb: {}, mrt: {}, apr: {}, mei: {}, jun: {}, jul: {}, aug: {}, sept: {}, okt: {}, nov: {}, dec: {} },
      allUnavailabilities: { jan: {}, feb: {}, mrt: {}, apr: {}, mei: {}, jun: {}, jul: {}, aug: {}, sept: {}, okt: {}, nov: {}, dec: {} },
      allNotFilled: { jan: {}, feb: {}, mrt: {}, apr: {}, mei: {}, jun: {}, jul: {}, aug: {}, sept: {}, okt: {}, nov: {}, dec: {} },
      userAbsences: { jan: {}, feb: {}, mrt: {}, apr: {}, mei: {}, jun: {}, jul: {}, aug: {}, sept: {}, okt: {}, nov: {}, dec: {} },

      allAbsences: [],

      defaultWorkdays: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      },
      defaultWorkdays_breaks: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      },
      defaultWorkdays_functions: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      },

      timesStandard: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',

        maxEnd: '',
      },

      wholedayStandard: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        0: false,
      },

      availableStandard: { // 0 = nvt, 1 = available, 2 = unavailable
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        0: 0,
      },

      infoExtra: {},
      maxDays: '',
      maxEnd: '',
      maxCumulative: '',
      canRegister: false,
      useSalaryModel: null,
      useWorkWeek: false,
      clockCode: '',
      leaveAccrual: false,
      employeeNumber: '',
      employeeStatus: null,

      userLeaveBalances: [],

      secondaryFunctions: [],

      showContractEndModal: false,

      workWeekMonday: false,
      workWeekTuesday: false,
      workWeekWednesday: false,
      workWeekThursday: false,
      workWeekFriday: false,
      workWeekSaturday: false,
      workWeekSunday: false,

      documents: [],
      file: null,
      uploadError: '',

      documentsContract: [],
      documentsSigned: {},
      fileContract: null,
      chosenContractTemplate: null,
      chosenContractObj: null,
      formPdfBytes: null,
      pdfForm: null,
      pdfForm_notFlat: null,
      pdfFields: [],

      salaryModels: [],
      salaries: {},

      leaveBalances: [],
      contractLeaveBalances: {},
      leaveTypeBalances: {},
      absAmount: {},

      subMenu: 'contract',

      showEmployeesModal: false,
      showUserLeaveBalanceModal: false,
      showContractModal: false,
      showCorrectionModal: false,
      showAddHolidayModal: false,
      showNotesModal: false,
      showSignContractModal: false,
      showSignContractModalDocuments: false,

      signContractDocLoc: null,

      userContracts: [],
      currentContract: null,

      leave_userLeaveBalances: {},
      leave_userRequests: [],
      leave_chosenYear: new Date().getFullYear(),
      leave_corrections: [],

      overhours_userOverhours: {},
      overhours_corrections: [],
      overhours_chosenYear: new Date().getFullYear(),

      specialChanges: false,

      notes: [],
      appointments: [],

      selectedContract: 'all',
      showInactive: true,

      activeEmployees: [],
      inactiveEmployees: [],
      //bannedEmployees: [],

      hoursWorked: {}, // year: [{}, {}] // week: int, weekData: [{}, {}]
      showWeekDetails: [],
      absAmount2: {},
      schedule2: {},

      contractSigned: false,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    this.timeNow = await Constants.getDateNow();

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      this.teamUsers = await APIGetTeamUsers.Request();
      const teamUsers = await APIGetTeamUsers.Request();
      const people = await APIGetAllAccounts.Request();
      const missingTeamPeople = Constants.getMissingPeopleTeam(people, teamUsers);
      this.setState({
        missingTeamPeople: missingTeamPeople,
      });
    }

    const [

      _accs,
      _cntrcts,
      _fnctns,

      skills,
      skillsUsers,

      _allhrs,
      _allavbl,
      _allunavbl,

      allAbsences,
      infoExtra,
      leaveBalances,
      contractLeaveBalances,
      leaveTypeBalances,
      salaryModels,
      salaries,

      docsContracts,

    ] = await Promise.all([

      this.getAccounts(),
      this.getContracts(),
      this.getFunctions(),

      APIGetSkills.Request(),
      APIGetAllSkillsUsers.Request(),

      this.getAllHours(),
      this.getAllAvailabilities(),
      this.getAllUnavailabilities(),

      
      APIGetAbsencetypes.Request(),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`),

      APIGET.Request(`SELECT *`, `FROM DocumentC`, null, null, null, `ORDER BY dc_naam`),

    ]);

    //await this.getAccounts();
    //await this.getContracts();
    //await this.getFunctions();

    //const skills = await APIGetSkills.Request();
    //const skillsUsers = await APIGetAllSkillsUsers.Request();

    const skillsUsersObj = Constants.convertToObjectWithArrays(skillsUsers, 'info_id');

    if(Data.data.accountData.account_rights === '2' && (Data.data.appOptions.bsn === true || Data.data.appOptions.iban === true)) {
      await this.getSecureData();
    }

    //await this.getAllHours();
    //await this.getAllAvailabilities();
    //await this.getAllUnavailabilities();

    //const allAbsences = await APIGetAbsencetypes.Request();

    //const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    //const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    //const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');
    //const leaveTypeBalances = await APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, `ORDER BY vb_factor DESC`);
    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vb_id');

    //const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);

    //const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    let salariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in salariesObj) {
        for (const s of salariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        salariesObj[sm.ut_id] = [];
      }

    }

    this.setState({
      loading: false,
      skills: skills,
      skillsUsers: skillsUsersObj,

      allAbsences: allAbsences,

      infoExtra: infoExtraObj,

      salaryModels: salaryModels,
      salaries: salariesObj,

      leaveBalances: leaveBalances,
      contractLeaveBalances: contractLeaveBalancesObj,
      leaveTypeBalances: leaveTypeBalancesObj,

      documentsContract: docsContracts,

    }, async () => {

      if (this.paramSelected !== null && this.paramSelectedName !== null && this.paramMenu !== null) {
        await this.selectUser(this.paramSelected, this.paramSelectedName, true);
        await this.selectMenu(this.paramMenu, true);
      }

    });

  }

  async getAllHours() {

    let allHours = this.state.allHours;

    let index = 1;
    for (const key in allHours) {
      if(index === 13) {
        break;
      }
      const hours = await APIGetAllHoursMin.Request(index, this.state.statYear);
      const hoursObj = Constants.convertToObject(hours, 'info_id');
      allHours[key] = hoursObj;
      index++;
    }

    this.setState({
      allHours: allHours,
    });

  }

  async getAllAvailabilities() {

    let allAvailabilities = this.state.allAvailabilities;

    let index = 1;
    for (const key in allAvailabilities) {
      if(index === 13) {
        break;
      }
      const av = await APIGetAllAvailabilitiesMin.Request(index, this.state.statYear);
      const avObj = Constants.convertToObject(av, 'beschikbaar_info_id');
      allAvailabilities[key] = avObj;
      index++;
    }

    this.setState({
      allAvailabilities: allAvailabilities,
    });

  }

  async getAllUnavailabilities() {

    let allAvailabilities = this.state.allUnavailabilities;

    let index = 1;
    for (const key in allAvailabilities) {
      if(index === 13) {
        break;
      }
      const av = await APIGetAllUnavailabilitiesMin.Request(index, this.state.statYear);
      const avObj = Constants.convertToObject(av, 'onbeschikbaar_info_id');
      allAvailabilities[key] = avObj;
      index++;
    }

    this.setState({
      allUnavailabilities: allAvailabilities,
    });

  }

  async getAllAbsencesUser(id) {

    let userAbsences = this.state.userAbsences;

    let index = 1;
    for (const key in userAbsences) {
      if(index === 13) {
        break;
      }
      const hours = await APIGetAllAbsencesUser.Request(index, this.state.statYear, id);
      const hoursObj = Constants.convertToObjectWithArrays(hours, 'afw_id');
      userAbsences[key] = hoursObj;
      index++;
    }

    this.setState({
      userAbsences: userAbsences,
    });

  }

  async getAccounts() {

    let accounts = await APIGetAllAccounts.Request();

    if(accounts === 'error') {

        accounts = [];

        this.setState({
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(accounts === null) {

        accounts = [];

    }

    const missingJobPeople = Constants.getMissingJobPeople(accounts);

    await this.usersHaveAllColumnsDB();

    if (this.teamMenu == true && Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamUsers = await APIGetTeamUsers.Request();

      accounts = Constants.getTeamAccounts(this.teamUsers, accounts);

    }

    let accounts2 = {};

    let active = [];
    let inactive = [];
    let banned = [];

    for (const acc of accounts) {

      if (acc.info2_status === '2') { // inactive
        inactive.push(acc);
      // } else if (acc.info2_status === '3') { // banned
      //   banned.push(acc);
      } else {
        active.push(acc);
      }

      accounts2[acc.info_id] = acc;

    }

    this.setState({ accounts: accounts, accountsIter: accounts2, activeEmployees: active, inactiveEmployees: inactive, bannedEmployees: banned, missingJobPeople: missingJobPeople, });

  }

  async usersHaveAllColumnsDB() {

    const datePreviousWeek = this.timeNow; // new Date(new Date().setDate(new Date().getDate() - 7))
    const mondayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 1));
    const sundayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 0));
    let employees = 0;
    let employeesSchedule = 0;

    const [
      accounts,
      info2,
      contracts,
      leaveBalances,
      contractLeaveBalances,
      log,
      plannedShifts_fallback,
      lastLog,
      hostedPages,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, null, `GROUP BY info_id`, null),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM Log`, null, `WHERE log_datum = '${Constants.dateToString(this.timeNow)}'`, null, null),
      APIGET.Request(`SELECT rooster_id, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${mondayPreviousWeek}' AND '${sundayPreviousWeek}'`, `GROUP BY rooster_info_id`, null),
      APIGET.Request(`SELECT *`, `FROM Log`, null,null,null, `ORDER BY log_id DESC LIMIT 1`),
      APIGET.Request(`SELECT *`, `FROM HostedPage`, null, null, null, null),
    ]);

    // LOG AND SUBSCRIPTION
    for (const acc of accounts) {
      if (acc.info2_status === '2' || Data.data.accountData.info_id === acc.info_id) {
        continue;
      }
      employees += 1;
    }

    for (const acc of plannedShifts_fallback) {
      if (Data.data.accountData.info_id === acc.rooster_info_id) {
        continue;
      }
      employeesSchedule += 1;
    }

    const total = (employeesSchedule > employees ? employeesSchedule : employees);

    // LOG
    if (Constants.isEmpty(log) === true || log.length === 0) {
      APIADD.Request(`INSERT INTO Log`, `VALUES (NULL, '${Constants.dateToString(this.timeNow)}', ${employees}, ${accounts.length - 1}, ${employeesSchedule})`);
    }

    // SUBSCRIPTION
    const hostedPage = Constants.isEmpty(hostedPages) === false && hostedPages.length > 0 ? hostedPages[0] : null;

    if (Constants.isEmpty(hostedPage) === false && Constants.isEmpty(lastLog) === false && lastLog.length > 0) {

      const lastActive = parseInt(lastLog[0].log_actieveMedewerkers);
      const lastScheduled = parseInt(lastLog[0].log_roosterMedewerkers);

      const lastTotal = (lastScheduled > lastActive ? lastScheduled : lastActive);

      if (total !== lastTotal) {
        const product = Data.data.appOptions.opties_versie === '2' ? Constants.isEmpty(Data.data.trial) === false ? "TIMETRACKING_TRIAL-EUR-MONTHLY" : "TIMETRACKING-EUR-MONTHLY" : Constants.isEmpty(Data.data.trial) === false ? "SCHEDULING_TRIAL-EUR-MONTHLY" : "SCHEDULING-EUR-MONTHLY";
        APIUpdateQuantity.Request(hostedPage.hp_subscription_id, total, product);
      }

    }

    // const accounts = await APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id`, null, null, null);

    // const info2 = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);

    // const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);

    // const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    // const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    
    const info2Obj = Constants.convertToObject(info2, 'info2_info_id');
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    // const overhours = await APIGET.Request(`SELECT ov_id, ov_info_id`, `FROM Overuren`, null, null, null, null);
    // const overhoursObj = Constants.convertToObject(overhours, 'ov_info_id');

    let promises = [];

    // for (const acc of accounts) {
    //   if (typeof info2Obj[acc.info_id] === 'undefined') {
    //     promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${acc.info_id}, 1, 1, 1, 1, 1, 1, 1, 1)`));
    //   }
    //   if (typeof contractsObj[acc.info_id] === 'undefined') {
    //     promises.push(APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${acc.info_id})`));
    //   }
    // }

    for (const contract of contracts) {

      if (leaveBalances.length > 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

        if (typeof contractLeaveBalancesObj[contract.contract_id] === 'undefined') {

          for (const leave of leaveBalances) {
            promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
          }

        } else {

          for (const leave of leaveBalances) {
            
            if (Constants.objectArrayContainsKey(contractLeaveBalancesObj[contract.contract_id], 'cvb_vb_id', leave.vb_id) === false) {
              promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
            }

          }

        }

      }

    }

    await Promise.all(promises);

  }

  async getContracts() {

    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    this.setState({ contracts: contractsObj });

  }

  async getSecureData() {

    this.setState({ secureData: [] });

    let secureData = [];
    let accounts2 = {};

    let promises = [];

    for(let key in this.state.accounts) {
      promises.push(APIGetAdminAccountInfo.Request(this.state.accounts[key].account_id));
    }

    const secureDataCall = await Promise.all(promises);

    for (const s of secureDataCall) {

      if (s !== null && s[0] !== null) {

        secureData.push({id: s[0].info_id, bsn: s[0].info_idnr, iban: s[0].info_ibnr});

        accounts2[s[0].info_id] = {bsn: s[0].info_idnr, iban: s[0].info_ibnr};

      }

    }

    this.setState({secureData: secureData, secureDataIter: accounts2});

  }

  async getFunctions() {

    let functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(functionsData === null) {

        functionsData = [];

    }

    this.setState({functions: functionsData});

  }

  getIBAN(id) {

    for(let key in this.state.secureData) {

      if(this.state.secureData[key].id === id) {

        return this.state.secureData[key].iban === '' || this.state.secureData[key].iban === null || this.state.secureData[key].iban === 'null' ? '' : this.state.secureData[key].iban;

      }

    }

    return '';

  }

  getBSN(id) {

    for(let key in this.state.secureData) {

      if(this.state.secureData[key].id === id) {

        return this.state.secureData[key].bsn === '' || this.state.secureData[key].bsn === null || this.state.secureData[key].bsn === 'null' ? '' : this.state.secureData[key].bsn;

      }

    }

    return '';

  }

  getAccountBars() {

    let accounts = [];
    let count = 0;

    let char = '';

    for(let key in this.state.accounts) {

        const account = this.state.accounts[key];

        // account is empty? (Most commnly InfoExtra problem (shows null info id))
        if (Constants.isEmpty(account.account_id) || Constants.isEmpty(account.info_geboorte)) {
          continue;
        }

        const contract = Constants.getCurrentContract(this.state.contracts[account.info_id], new Date());

        if (this.state.selectedContract === '0') {
          if (Constants.isEmpty(contract) === false && (contract.contract_type === '1' || contract.contract_type === '2')) {
            continue;
          }
        } else if (this.state.selectedContract === '1') {
          if ((Constants.isEmpty(contract) === false && contract.contract_type !== '1') || Constants.isEmpty(contract) === true) {
            continue;
          }
        } else if (this.state.selectedContract === '2') {
          if ((Constants.isEmpty(contract) === false && contract.contract_type !== '2') || Constants.isEmpty(contract) === true) {
            continue;
          }
        }

        const userFullName = `${account.info_voornaam} ${(account.info_tussenvoegsel !== null && account.info_tussenvoegsel !== '') ? account.info_tussenvoegsel + " " + account.info_achternaam : account.info_achternaam}`;

        if(Data.data.accountData.account_rights === '2') {

          let teamName = '';

          if(Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

              teamName = Constants.getTeamNameFromUserID(this.teamUsers, account.account_id);

              if(teamName !== '') {

                  if(teamName.length > 1) {

                      teamName = 'Meerdere teams';

                  }

              }

          }

          const birth = Constants.stringToDate(account.info_geboorte);

          let contractEnding = false;
          if (contract !== null && Constants.isEmpty(contract.contract_eind) === false && (Constants.stringToDate(contract.contract_eind) - new Date() <= 8294400000)) {

            let _contract_date_check = Constants.stringToDate(contract.contract_eind);
            _contract_date_check = _contract_date_check.setDate(_contract_date_check.getDate() + 7); // better check for contract higher than current end date
            const contractAfter = Constants.getCurrentContract(this.state.contracts[account.info_id], new Date(_contract_date_check));
            if (contractAfter === null) {
              contractEnding = true;
            } 

          }

          accounts.push(

            <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>

              {contractEnding ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('employees_tooltip_contract')}</span>
                    </div>
                  )}
                  style={{position: 'absolute', left: Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ? '-1.8vw' : '-0.6vw', marginBottom: 2, cursor: 'pointer'}}
                  position={'top'}
                  followCursor
                  arrow
                  theme='kodict'
                >
                  <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='far fa-file-exclamation' onClick={() => this.props.history.push({ pathname: `/employees/${account.info_id}`, menu: 'contract' })} />
                </Tooltip>
              : null}

              {Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('employees_tooltip_team')}</span>
                    </div>
                  )}
                  style={{position: 'absolute', left: '-0.8vw', marginBottom: 2, cursor: 'pointer'}}
                  position={'top'}
                  followCursor
                  arrow
                  theme='kodict'
                >
                  <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='fas fa-exclamation-triangle' onClick={() => this.props.history.push({ pathname: `/employees/${account.info_id}`, menu: 'teams' })} />
                </Tooltip>
              :
              Constants.isEmpty(account.functie_id) && account.account_rights !== '2' ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('employees_tooltip_job')}</span>
                    </div>
                  )}
                  style={{position: 'absolute', left: '-0.8vw', marginBottom: 2, cursor: 'pointer'}}
                  position={'top'}
                  followCursor
                  arrow
                  theme='kodict'
                >
                  <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='fas fa-exclamation-triangle' onClick={() => this.props.history.push({ pathname: `/employees/${account.info_id}`, menu: 'function' })} />
                </Tooltip>
              : null}

              <div className='hr_hoursBar' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null, height: '2.5vw' }}>

                <div className='hr_hoursBar_sub5' onClick={() => this.props.history.push(`/employees/${account.info_id}`)} style={{ opacity: account.info2_status === '2' ? 0.4 : 1, flex: 0.3, paddingLeft: '0.5%', justifyContent: 'flex-start', alignItems: 'center' }}>
                  {Constants.isEmpty(account.info2_profielfoto) === false ?
                    <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${account.info2_profielfoto}`} style={{ width: '1.8vw', height: '1.8vw', borderRadius: '2vw' }} />
                  :
                    <div className='profilePic' style={{ width: '1.8vw', height: '1.8vw', borderRadius: '2vw', backgroundColor: '#EBEBEB' }}>
                      <p><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.7vw' }} /></p>
                    </div>
                  }
                </div>

                <div className='hr_hoursBar_sub1' onClick={() => this.props.history.push(`/employees/${account.info_id}`)} style={{ opacity: account.info2_status === '2' ? 0.4 : 1 }}>
                  <span className='defaultLinkO'><b>{userFullName}</b></span>
                </div>

                <div className='hr_hoursBar_sub1' style={{ opacity: account.info2_status === '2' ? 0.4 : 1 }}>
                  <span>{account.info_adres} {account.info_huisnummer}, {account.info_postcode}, {account.info_plaats}</span>
                </div>

                <div className='hr_hoursBar_sub2' style={{ opacity: account.info2_status === '2' ? 0.4 : 1 }}>
                  <span>{birth.getDate() < 10 ? '0' + birth.getDate() : birth.getDate()}-{(birth.getMonth() + 1) < 10 ? '0' + (birth.getMonth() + 1) : (birth.getMonth() + 1)}-{birth.getFullYear()}</span>
                </div>

                <div className='hr_hoursBar_sub1' style={{ opacity: account.info2_status === '2' ? 0.4 : 1 }}>
                  <span>{account.info_email}</span>
                </div>

                <div className='hr_hoursBar_sub2' style={{ opacity: account.info2_status === '2' ? 0.4 : 1 }}>
                  <span>{account.info_telefoon}</span>
                </div>

                {/* <div className='hr_hoursBar_sub2'>
                  <span>{this.getBSN(account.account_id)}</span>
                </div>

                <div className='hr_hoursBar_sub2'>
                  <span>{this.getIBAN(account.account_id)}</span>
                </div> */}

                <div className='hr_hoursBar_sub2' style={{ flex: 0.8, opacity: account.info2_status === '2' ? 0.4 : 1 }}>
                  <span>{account.account_username}</span>
                </div>

                {/* <div className='hr_hoursBar_sub2'>
                  <span>{account.account_rights === '2' ? 'Administrator' : account.account_rights === '1' ? 'Planner' : 'Medewerker'}</span>
                </div> */}

                {Data.data.teams.length > 0 && Data.data.chosenTeam !== null ?

                  <div className='hr_hoursBar_sub2' style={{ opacity: account.info2_status === '2' ? 0.4 : 1 }}>

                    {teamName !== '' ?
                      <span>{teamName}</span>
                    :
                      <span style={{color: Colors.color.redFilledIn}}>{__('employees_misc_noTeam')}</span>
                    }

                  </div>

                : null}

                <div className='hr_hoursBar_sub3' style={{flex: 0.2}}>
                  {account.info2_status === '2' ?
                    <i className='far fa-user-lock' style={{ color: Colors.color.pastBorderColor }} />
                  :
                    <span>&nbsp;</span>
                  }
                </div>

                <div className='hr_hoursBar_sub3' style={{cursor: 'pointer', flex: 0.2}} onClick={() => this.props.history.push(`/employees/${account.info_id}`)}>
                  <i className='far fa-edit' />
                </div>

              </div>

            </div>
          
          );

        } else {

          let teamName = '';

          if(Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

              teamName = Constants.getTeamNameFromUserID(this.teamUsers, account.account_id);

              if(teamName !== '') {

                  if(teamName.length > 1) {

                      teamName = 'Meerdere teams';

                  }

              }

          }

          const birth = Constants.stringToDate(account.info_geboorte);

          accounts.push(

            <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              <div className='hr_hoursBar_sub2'>
                <span>{userFullName}</span>
              </div>

              <div className='hr_hoursBar_sub1'>
                <span>{account.info_adres} {account.info_huisnummer}, {account.info_postcode}, {account.info_plaats}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{birth.toLocaleDateString()}</span>
              </div>

              <div className='hr_hoursBar_sub1'>
                <span>{account.info_email}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{account.info_telefoon}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>****</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>****</span>
              </div>

              <div className='hr_hoursBar_sub2' style={{flex: 0.8}}>
                <span>{account.account_username}</span>
              </div>

              {/* <div className='hr_hoursBar_sub2'>
                <span>{account.account_rights === '2' ? 'Administrator' : account.account_rights === '1' ? 'Planner' : 'Medewerker'}</span>
              </div> */}

              {Data.data.teams.length > 0 && Data.data.chosenTeam !== null ?

                <div className='hr_hoursBar_sub2'>

                  {teamName !== '' ?
                    <span>{teamName}</span>
                  :
                    <span style={{color: Colors.color.redFilledIn}}>{__('employees_misc_noTeam')}</span>
                  }

                </div>

              : null}

            </div>
          
          );

        }

        if (count === 0) {
          count++;
        } else {
          count--;
        }

        // char = account.info_voornaam.substring(0,1).toUpperCase();

    }

    return accounts;

  }

  openContractEndModal() {

    this.setState({
      showContractEndModal: true,
      modalData: this.state.userData,
      modalMode: 0,
    });

  }

  openUserLeaveBalanceModal(leave) {

    this.setState({
      showUserLeaveBalanceModal: true,
      modalData: leave,
      modalMode: 1,
    });

  }

  closeModal() {

    this.setState({
      showModal: false,
      showUserLeaveBalanceModal: false,
      showAddHolidayModal: false,
      showContractEndModal: false,
      showNotesModal: false,
      modalData: null,
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async selectUser(id, name, force = false, menu = this.state.menu) {

    if ((this.state.loading === true || this.state.selected === id) && force === false) {
        return;
    }

    if (this.state.specialChanges === true && force === false) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ specialChanges: false });
      }
    }

    // this.props.history.push(`/employees/${id}`);
    // //return;

    this.setState({ loading: true });

    const undef = typeof this.state.accountsIter[id] === 'undefined';
    //const hasContract = typeof this.state.contracts[id] !== 'undefined';

    this.userData = {
        ...this.state.accountsIter[id],
        bsn: undef === false ? (Data.data.appOptions.bsn === true ? this.state.secureDataIter[id].bsn : '') : '',
        iban: undef === false ? (Data.data.appOptions.iban === true ? this.state.secureDataIter[id].iban : '') : '',
    };

    const today = Constants.getDateNowStatic();
    this.userData.info_indienst = this.userData.info_indienst === null || this.userData.info_indienst === '0000-00-00' ? `${today.getFullYear()}-${parseInt(today.getMonth() + 1) < 10 ? '0' + parseInt(today.getMonth() + 1) : parseInt(today.getMonth() + 1)}-${parseInt(today.getDate()) < 10 ? '0' + parseInt(today.getDate()) : parseInt(today.getDate())}` : this.userData.info_indienst;
    this.userData.info_extra = this.userData.info_extra === null ? '' : this.userData.info_extra;
    this.userData.info_plaats = this.userData.info_plaats === null || this.userData.info_plaats === 'null' ? '' : this.userData.info_plaats;
    this.userData.info_telefoon2 = this.userData.info_telefoon2 === null || this.userData.info_telefoon2 === 'null' ? '' : this.userData.info_telefoon2;
    this.userData.info_geboorteplaats = this.isEmpty(this.userData.info_geboorteplaats) ? '' : this.userData.info_geboorteplaats;
    this.userData.bsn = this.userData.bsn === null || this.userData.bsn === 'null' ? '' : this.userData.bsn;
    this.userData.iban = this.userData.iban === null || this.userData.iban === 'null' ? '' : this.userData.iban;

    for (const key in this.userData) {
      if (this.userData[key] === 'null') {
        this.userData[key] = '';
      }
    }

    if (this.isEmpty(this.userData.info_geboorte) === true) {
      this.userData.info_geboorte = Constants.getDateNowStatic();
    }

    if (id !== 'all' && id !== 'team') { //get other info

      if(Data.data.teams.length > 1 && Data.data.chosenTeam !== null) { // has team?

        const teams = await APIGetTeamsOwn.Request(id);

        this.setState({teams: teams});

      }

      // skills
      const employeeSkills = this.state.skillsUsers[id];

      if (typeof employeeSkills !== 'undefined') {
        this.setState({
          employeeSkills: employeeSkills
        });
      } else {
        this.setState({
          employeeSkills: []
        });
      }

      // contracts
      const currentContract = Constants.getCurrentContract(this.state.contracts[id], today);
      const userContracts = typeof this.state.contracts[id] === 'undefined' ? [] : this.state.contracts[id];

      // info extra
      const info2 = this.state.infoExtra[id];

      if (typeof info2 !== 'undefined') {

        let _defaultWorkdays = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        };
        let _defaultWorkdays_breaks = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        };
        let _defaultWorkdays_functions = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        };

        let _timesStandard = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',

          maxEnd: Constants.isEmpty(info2.info2_max_eind) === true ? '' : info2.info2_max_eind.substr(0,5),
        };

        this.setState({
          maxDays: info2.info2_max_dagen,
          defaultWorkdays: _defaultWorkdays,
          defaultWorkdays_breaks: _defaultWorkdays_breaks,
          defaultWorkdays_functions: _defaultWorkdays_functions,
          timesStandard: _timesStandard,
          maxCumulative: info2.info2_opeenvolgend,
          canRegister: info2.info2_registreren === '1',
          workWeekMonday: info2.info2_werkdag_maandag === '1',
          workWeekTuesday: info2.info2_werkdag_dinsdag === '1',
          workWeekWednesday: info2.info2_werkdag_woensdag === '1',
          workWeekThursday: info2.info2_werkdag_donderdag === '1',
          workWeekFriday: info2.info2_werkdag_vrijdag === '1',
          workWeekSaturday: info2.info2_werkdag_zaterdag === '1',
          workWeekSunday: info2.info2_werkdag_zondag === '1',
          useSalaryModel: info2.info2_ut_id === null ? null : info2.info2_ut_id,
          useWorkWeek: info2.info2_werkweek === '1' ? true : false,
          clockCode: info2.info2_klokcode,
          employeeNumber: info2.info2_personeelsnummer,
          employeeStatus: info2.info2_status,
        });

      } else {

        let _defaultWorkdays = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        };
        let _defaultWorkdays_breaks = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        };
        let _defaultWorkdays_functions = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',
        };

        let _timesStandard = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',

          maxEnd: '',
        };
        this.setState({
          maxDays: '',
          defaultWorkdays: _defaultWorkdays,
          defaultWorkdays_breaks: _defaultWorkdays_breaks,
          defaultWorkdays_functions: _defaultWorkdays_functions,
          timesStandard: _timesStandard,
          maxCumulative: '',
          canRegister: false,
          workWeekMonday: false,
          workWeekTuesday: false,
          workWeekWednesday: false,
          workWeekThursday: false,
          workWeekFriday: false,
          workWeekSaturday: false,
          workWeekSunday: false,
          useSalaryModel: null,
          useWorkWeek: false,
          clockCode: '',
          leaveAccrual: false,
          employeeNumber: '',
          employeeStatus: null,
          showWeekDetails: [],
        });

      }

      // standard availability
      if(Data.data.appOptions.availability === 'STANDARD') {

        let _wholedayStandard = {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          0: false,
        };
        let _availableStandard = { // 0 = nvt, 1 = available, 2 = unavailable
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          0: 0,
        };
        let _timesStandard = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          0: '',

          maxEnd: this.state.timesStandard['maxEnd'],
        };

        const availabilityUser = await APIGET.Request(
          'SELECT *',
          'FROM StandaardBeschikbaarheid',
          null,
          `WHERE sb_info_id = ${id}`,
          null,
          null
        );
        for (const av of availabilityUser) {
          _wholedayStandard[av.sb_dag] = av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00';
          _availableStandard[av.sb_dag] = parseInt(av.sb_beschikbaar);
          _timesStandard[av.sb_dag] = `${av.sb_begin.substr(0,5)} - ${av.sb_eind.substr(0,5)}`;
        }

        this.setState({ availableStandard: _availableStandard, wholedayStandard: _wholedayStandard, timesStandard: _timesStandard });

      }

      await this.getLeaveData(id);
      await this.getOverhoursData(id);

      const [
        defaultWorkDaysUser,
        _absences,
        docs,
        docsSigned,
        secondaryFunctions,
        notes,
      ] = await Promise.all([
        APIGET.Request(`SELECT *`, `FROM VasteDagen`, null, `WHERE vd_info_id = ${id}`, null, null),
        this.getAllAbsencesUser(id),
        APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${id}`, null, `ORDER BY d_naam`),
        APIGET.Request(`SELECT *`, `FROM DocumentOndertekend`, null, `WHERE do_info_id = ${id}`, null, `ORDER BY do_id`),
        APIGET.Request(`SELECT functie_naam, functie_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, `WHERE fe_info_id = ${id}`, null, null),
        APIGET.Request(`SELECT *`, `FROM Notitie`, `LEFT JOIN Info ON n_info_id_door = info_id`, `WHERE n_datum >= (NOW() - INTERVAL 3 MONTH) AND n_info_id = ${id}`, null, `ORDER BY n_datum`),
      ]);

      const docsSignedObj = Constants.convertToObjectWithArrays(docsSigned, 'do_d_id');

      let _notes = [];
      let _appointments = [];

      for (const note of notes) {

        if (Constants.isEmpty(note.n_melding)) {
          _notes.push(note);
        } else {
          _appointments.push(note);
        }

      }

      // Default workdays
      let _defaultWorkdays = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      };
      let _defaultWorkdays_breaks = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      };
      let _defaultWorkdays_functions = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      };
      // const defaultWorkDaysUser = await APIGET.Request(`SELECT *`, `FROM VasteDagen`, null, `WHERE vd_info_id = ${id}`, null, null);
      for (const _wd of defaultWorkDaysUser) {
        _defaultWorkdays[parseInt(_wd.vd_dag)] = `${_wd.vd_begin.substr(0,5)} - ${_wd.vd_eind.substr(0,5)}`;
        _defaultWorkdays_functions[parseInt(_wd.vd_dag)] = _wd.vd_functie_id;
        if (Constants.isEmpty(_wd.vd_pauze) === false) {
          _defaultWorkdays_breaks[parseInt(_wd.vd_dag)] = `${_wd.vd_pauze.substr(0,5)}`;
        }
      }

      this.setState({ defaultWorkdays: _defaultWorkdays, defaultWorkdays_breaks: _defaultWorkdays_breaks, defaultWorkdays_functions: _defaultWorkdays_functions });

      //absences
      // await this.getAllAbsencesUser(id);

      // documents
      // const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${id}`, null, `ORDER BY d_naam`);

      //functions
      // const secondaryFunctions = await APIGET.Request(`SELECT functie_naam, functie_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, `WHERE fe_info_id = ${id}`, null, null);

      this.setState({ 

        notes: _notes,
        appointments: _appointments,

        secondaryFunctions: secondaryFunctions, 
        documents: docs,
        documentsSigned: docsSignedObj,

        userContracts: userContracts,
        currentContract: currentContract,

      });

    }

    this.setState({
      selected: id,
      selectedName: name,
      userData: this.userData,
      changed: false,
      rights: undef === false ? parseInt(this.state.accountsIter[id].account_rights) : 0,
      password1: '',
      password2: '',
      showPassContainer: false,
      //hasContract: hasContract,

      fileContract: null,
      chosenContractTemplate: null,
      chosenContractObj: null,
      formPdfBytes: null,
      pdfForm: null,
      pdfFields: [],

      showError: false,
    });

    if (force !== true && menu === this.state.menu) {
      this.setState({ menu: 'account' });
    } else if (force !== true) {
      this.setState({ menu: menu });
    }

    if (id === 'all') {

        this.teamMenu = false;

        this.setState({
            menu: 'account',
        });

        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

          await this.reloadScreen();

        }

    }

    if (id === 'team') {

      this.teamMenu = true;

      this.setState({
          menu: 'account',
      });

      await this.reloadScreen();

    }

    this.setState({ loading: false, specialChanges: false, });

  }

  async selectMenu(menu, force = false) {

    if(this.state.menu === menu && force === false) {
        return;
    }

    if (this.state.specialChanges === true) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ specialChanges: false });
      }
    }

    const undef = typeof this.state.accountsIter[this.state.selected] === 'undefined';
    //const hasContract = typeof this.state.contracts[this.state.selected] !== 'undefined';

    this.userData = {
        ...this.state.accountsIter[this.state.selected],
        bsn: undef === false ? (Data.data.appOptions.bsn === true ? this.state.secureDataIter[this.state.selected].bsn : '') : '',
        iban: undef === false ? (Data.data.appOptions.iban === true ? this.state.secureDataIter[this.state.selected].iban : '') : '',
    };

    const today = Constants.getDateNowStatic();
    this.userData.info_indienst = this.userData.info_indienst === null || this.userData.info_indienst === '0000-00-00' ? `${today.getFullYear()}-${parseInt(today.getMonth() + 1) < 10 ? '0' + parseInt(today.getMonth() + 1) : parseInt(today.getMonth() + 1)}-${parseInt(today.getDate()) < 10 ? '0' + parseInt(today.getDate()) : parseInt(today.getDate())}` : this.userData.info_indienst;
    this.userData.info_extra = this.userData.info_extra === null ? '' : this.userData.info_extra;
    this.userData.info_plaats = this.userData.info_plaats === null || this.userData.info_plaats === 'null' ? '' : this.userData.info_plaats;
    this.userData.info_telefoon2 = this.userData.info_telefoon2 === null || this.userData.info_telefoon2 === 'null' ? '' : this.userData.info_telefoon2;
    this.userData.info_geboorteplaats = this.isEmpty(this.userData.info_geboorteplaats) ? '' : this.userData.info_geboorteplaats;
    this.userData.bsn = this.userData.bsn === null || this.userData.bsn === 'null' ? '' : this.userData.bsn;
    this.userData.iban = this.userData.iban === null || this.userData.iban === 'null' ? '' : this.userData.iban;

    if (menu === 'contract') {

      this.setState({loading: true});

      const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
      const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

      const currentContract = Constants.getCurrentContract(contractsObj[this.state.selected]);
      const userContracts = typeof contractsObj[this.state.selected] === 'undefined' ? [] : contractsObj[this.state.selected];

      this.setState({ contracts: contractsObj, currentContract: currentContract, userContracts: userContracts, loading: false, });

    } 
    // else if (menu === 'leavebalances') {

    //   await this.getLeaveData(this.state.selected);

    // } else if (menu === 'overhours') {

    //   await this.getOverhoursData(this.state.selected);

    // }

    // if (hasContract === true) {

    //     this.userData = {
    //         ...this.userData,
    //         ...this.state.contracts[this.state.selected],
    //     };

    // } else {

    //     this.userData = {
    //         ...this.userData,
    //         contract_info_id: this.state.selected,
    //         contract_start: null,
    //         contract_eind: null,
    //         contract_uren: '',
    //         contract_bruto_uurloon: '',
    //         contract_vakantie_dagen: '',
    //         contract_overeenkomst: '',
    //         contract_type: '',
    //         contract_vast: '',
    //         contract_werkzaamheden: '',
    //         contract_proef: '',
    //         contract_waar: '',
    //         contract_vakantiegeld_wanneer1: '',
    //         contract_vakantiegeld_wanneer2: '',
    //         contract_vakantiegeld_wanneer3: '',
    //         contract_vakantie_bovenwettelijk: '',
    //         contract_vergoeding: '',
    //         contract_cao: '',
    //         contract_pensioen: '',
    //         contract_dagen: '',
    //         contract_dagen_begin: '',
    //         contract_dagen_eind: '',
    //         contract_personeelshandboek: '',
    //         contract_cao2: '',
    //         contract_uitbetaling: '',
    //         contract_arbeidsvoorwaarden: '',
    //         contract_ziekmeldingsprotocol: '',
    //         contract_ziek_percentage1: '',
    //         contract_ziek_percentage2: '',
    //         contract_conc_rel_beding: '',
    //         contract_vog: '',
    //         contract_privacy: '',
    //         contract_vergoeding_overwerk: '',
    //         contract_bonus: '',
    //         contract_min: '',
    //         contract_max: '',
    //         contract_minmax_maand: '',
    //         contract_categorie: '',
    //         contract_vakkracht: '',
    //         contract_seizoenskracht: '',
    //         contract_vrijwilliger_vergoeding: '',
    //         contract_iban: '',
    //         contract_begeleider: '',
    //         contract_vrijwilliger_informatie: '',
    //     }

    // }

    for (const key in this.userData) {
      if (this.userData[key] === 'null') {
        this.userData[key] = '';
      }
    }

    // if (this.isEmpty(this.userData.contract_eind) === true) {
    //   this.userData.contract_eind = null;
    // }

    // if (this.isEmpty(this.userData.contract_start) === true) {
    //   this.userData.contract_start = null;
    // }

    if (this.isEmpty(this.userData.info_geboorte) === true) {
      this.userData.info_geboorte = Constants.getDateNowStatic();
    }

    this.setState({
      menu: menu,
      subMenu: menu === 'contract' ? 'contract' : menu === 'reports' ? 'general' : 'contract',
      userData: this.userData,
      changed: false,
      rights: undef === false ? parseInt(this.state.accountsIter[this.state.selected].account_rights) : 0,
      password1: '',
      password2: '',
      showPassContainer: false,
      //hasContract: hasContract,
      showError: false,
      specialChanges: false,
      showWeekDetails: [],
    });

  }

  selectSubMenu(sub) {

    this.setState({ subMenu: sub });

  }

  selectRight(event) {

    this.setState({
        rights: parseInt(event.target.value),
        showError: false,
    });

    this.onChangeSomething();

  }

  async getLeaveData(userID) {

    //this.setState({loading: true});

    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    const currentContract = Constants.getCurrentContract(contractsObj[userID]);
    const userContracts = typeof contractsObj[userID] === 'undefined' ? [] : contractsObj[userID];

    const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    const leaveCorrectionsData = await APIGET.Request(`SELECT CorrectieVerlof.*, VerlofBalans.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieVerlof`, `LEFT JOIN VerlofBalans ON cv_vb_id = vb_id LEFT JOIN Info ON cv_info_id_door = info_id`, `WHERE cv_info_id = ${userID}`, null, `ORDER BY cv_datum DESC, cv_id DESC`);

    const allHolidays = await APIGET.Request(`SELECT *`, `FROM Vakantie`, null, `WHERE vakantie_geaccepteerd = 1`, null, null);
    const allHolidaysObj = Constants.convertToObjectWithArrays(allHolidays, 'vakantie_info_id');

    // let newHours = {};
    // let absAmountObj2 = {};

    // let [
    //   hours,
    //   absAmount2,
    //   clocked,
    //   schedules,
    //   breakRules,
    // ] = await Promise.all([
    //   APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, `WHERE uren_info_id = ${userID}`, null, null),
    //   APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id LEFT JOIN Info ON vs_info_id = info_id`, `WHERE vs_info_id = ${userID}`, null, null),
    //   APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id LEFT JOIN Uren ON uren_kloktijd_id = kloktijd_id`, `WHERE kloktijd_info_id = ${userID} AND uren_id IS NULL`, null, null),
    //   APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id`, `WHERE rooster_info_id = ${userID} AND rooster_publiceren = 1`, null, null),
    //   APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),
    // ]);

    const userLeaveBalances = await Constants.getLeaveBalance(userContracts, this.timeNow, userID, this.state.leaveBalances, this.state.leaveTypeBalances, this.state.contractLeaveBalances, allHolidaysObj, leaveCorrectionsData, true, this.state.infoExtra);

    await this.leave_getRequestsData(userID);

    // CORRECTIONS
    let leaveCorrections = {};
    for (const correction of leaveCorrectionsData) {

      const date = Constants.stringToDate(correction.cv_datum);
      const year = date.getFullYear();
      const yearKey = year.toString();

      if (typeof leaveCorrections[yearKey] === 'undefined') {
        leaveCorrections[yearKey] = {
          sep: [],
        };
      }

      leaveCorrections[yearKey].sep.push(correction);

    }

    // // CLOCKED DATA INTO UREN DB
    // if (Data.data.appOptions.hours === 'CLOCK') {
    //   for (const data of clocked) {

    //     let _break = data.kloktijd_pauze.substr(0, 8);
    //     if (Data.data.appOptions.brk === 'PLANNER') {
    //       _break = Constants.getUserPlannerBreak(data.info_id, data.kloktijd_inklok_datum, schedules);
    //     } else if (Data.data.appOptions.brk === 'CALC') {
    //       _break = Constants.getBreakRule(data.kloktijd_inklok_datum, data.kloktijd_inklok_tijd, data.kloktijd_uitklok_datum, data.kloktijd_uitklok_tijd, null, breakRules).str.substr(0, 5);
    //     }

    //     hours.push({
    //       ...data,
    //       uren_id: null,
    //       uren_begindatum: data.kloktijd_inklok_datum,
    //       uren_einddatum: data.kloktijd_uitklok_datum,
    //       uren_begin: data.kloktijd_inklok_tijd,
    //       uren_eind: data.kloktijd_uitklok_tijd,
    //       uren_pauze: _break,
    //     });

    //   }
    // } else if (Data.data.appOptions.hours === 'PLANNER') {
    //   for (const data of schedules) {

    //     let _break = data.rooster_pauze;
    //     if (Data.data.appOptions.brk === 'CALC') {
    //       _break = Constants.getBreakRule(data.rooster_datum, data.rooster_begin, data.rooster_datum, data.rooster_eind, data, breakRules).str.substr(0, 5);
    //     }

    //     hours.push({
    //       ...data,
    //       uren_id: null,
    //       uren_begindatum: data.rooster_datum,
    //       uren_einddatum: planClass.isNightShift2(data),
    //       uren_begin: data.rooster_begin,
    //       uren_eind: data.rooster_eind,
    //       uren_pauze: _break,
    //     });

    //   }
    // }

    // // HOURS (UREN DB)
    // for (const data of hours) {

    //   const hourDate = Constants.stringToDate(data.uren_begindatum);
    //   const week = Constants.getWeekNumber2(hourDate);
    //   const year = hourDate.getFullYear();
    //   const yearKey = year.toString();

    //   // CREATE YEAR OBJECT
    //   if (Constants.isEmpty(newHours[yearKey])) {
    //     newHours[yearKey] = [];
    //   }

    //   // CREATE WEEK ITEM
    //   if (Constants.objectArrayContainsKey(newHours[yearKey], 'week', week) === false) {
    //     newHours[yearKey].push({
    //       week: week,
    //       weekData: [],
    //     });
    //   }

    //   const weekItem = Constants.getArrayItem(newHours[yearKey], 'week', week);

    //   if (weekItem !== null) {
    //     weekItem.weekData.push(data);
    //   }

    // }

    // // ABS
    // for (const abs of absAmount2) {

    //   const date = Constants.stringToDate(abs.vakantie_begin);
    //   const week = Constants.getWeekNumber2(date);
    //   const weekKey = week.toString();
    //   const year = date.getFullYear();
    //   const yearKey = year.toString();

    //   if (Constants.isEmpty(absAmountObj2[yearKey])) {
    //     absAmountObj2[yearKey] = {};
    //   }
    //   if (Constants.isEmpty(absAmountObj2[yearKey][weekKey])) {
    //     absAmountObj2[yearKey][weekKey] = [];
    //   }

    //   absAmountObj2[yearKey][weekKey].push(abs);

    // }

    // absAmount2: absAmountObj2, loading: false, 
    this.setState({  contracts: contractsObj, currentContract: currentContract, userContracts: userContracts, leave_userLeaveBalances: userLeaveBalances, absAmount: absAmountObj, leave_corrections: leaveCorrections  });

  }

  async getOverhoursData(userID) {

    // this.setState({ loading: true });

    let newHours = {};
    let absAmountObj = {};
    let scheduleAbsenceObj = {};

    let [
      hours,
      absAmount,
      scheduleAbsence,
      clocked,
      schedules,
      breakRules,
      overhoursCorrectionsData,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, `WHERE uren_info_id = ${userID}`, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id LEFT JOIN Info ON vs_info_id = info_id`, `WHERE vs_info_id = ${userID}`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afwr_afw_id = afw_id LEFT JOIN Uitzondering ON uit_afw_id = afw_id`, `WHERE rooster_info_id = ${userID} AND uit_id IS NOT NULL`, null, null),
      APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id LEFT JOIN Uren ON uren_kloktijd_id = kloktijd_id`, `WHERE kloktijd_info_id = ${userID} AND uren_id IS NULL`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Uren ON uren_rooster_id = rooster_id`, `WHERE rooster_info_id = ${userID} AND rooster_publiceren = 1 AND uren_id IS NULL`, null, null),
      APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),
      APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, `WHERE co_info_id = ${userID}`, null, `ORDER BY co_datum DESC, co_id DESC`),
    ]);

    // CLOCKED DATA INTO UREN DB
    if (Data.data.appOptions.hours === 'CLOCK') {
      for (const data of clocked) {

        let _break = data.kloktijd_pauze.substr(0, 8);
        if (Data.data.appOptions.brk === 'PLANNER') {
          _break = Constants.getUserPlannerBreak(data.info_id, data.kloktijd_inklok_datum, schedules);
        } else if (Data.data.appOptions.brk === 'CALC') {
          _break = Constants.getBreakRule(data.kloktijd_inklok_datum, data.kloktijd_inklok_tijd, data.kloktijd_uitklok_datum, data.kloktijd_uitklok_tijd, null, breakRules).str.substr(0, 5);
        }

        hours.push({
          ...data,
          uren_id: null,
          uren_begindatum: data.kloktijd_inklok_datum,
          uren_einddatum: data.kloktijd_uitklok_datum,
          uren_begin: data.kloktijd_inklok_tijd,
          uren_eind: data.kloktijd_uitklok_tijd,
          uren_pauze: _break,
        });

      }
    } else if (Data.data.appOptions.hours === 'PLANNER') {
      for (const data of schedules) {

        let _break = data.rooster_pauze;
        if (Data.data.appOptions.brk === 'CALC') {
          _break = Constants.getBreakRule(data.rooster_datum, data.rooster_begin, data.rooster_datum, data.rooster_eind, data, breakRules).str.substr(0, 5);
        }

        hours.push({
          ...data,
          uren_id: null,
          uren_begindatum: data.rooster_datum,
          uren_einddatum: planClass.isNightShift2(data),
          uren_begin: data.rooster_begin,
          uren_eind: data.rooster_eind,
          uren_pauze: _break,
        });

      }
    }

    // HOURS (UREN DB)
    for (const data of hours) {

      const hourDate = Constants.stringToDate(data.uren_begindatum);
      const week = Constants.getWeekNumber2(hourDate);
      const year = hourDate.getFullYear();
      const yearKey = year.toString();

      // CREATE YEAR OBJECT
      if (Constants.isEmpty(newHours[yearKey])) {
        newHours[yearKey] = [];
      }

      // CREATE WEEK ITEM
      if (Constants.objectArrayContainsKey(newHours[yearKey], 'week', week) === false) {
        newHours[yearKey].push({
          week: week,
          weekData: [],
        });
      }

      const weekItem = Constants.getArrayItem(newHours[yearKey], 'week', week);

      if (weekItem !== null) {
        weekItem.weekData.push(data);
      }

    }

    // ABS
    for (const abs of absAmount) {

      const date = Constants.stringToDate(abs.vakantie_begin);
      const week = Constants.getWeekNumber2(date);
      const weekKey = week.toString();
      const year = date.getFullYear();
      const yearKey = year.toString();

      if (Constants.isEmpty(absAmountObj[yearKey])) {
        absAmountObj[yearKey] = {};
      }
      if (Constants.isEmpty(absAmountObj[yearKey][weekKey])) {
        absAmountObj[yearKey][weekKey] = [];
      }

      absAmountObj[yearKey][weekKey].push(abs);

    }

     // SCHEDULE ABSENTS
     for (const data of scheduleAbsence) {

      const date = Constants.stringToDate(data.rooster_datum);
      const week = Constants.getWeekNumber2(date);
      const weekKey = week.toString();
      const year = date.getFullYear();
      const yearKey = year.toString();

      if (Constants.isEmpty(scheduleAbsenceObj[yearKey])) {
        scheduleAbsenceObj[yearKey] = {};
      }
      if (Constants.isEmpty(scheduleAbsenceObj[yearKey][weekKey])) {
        scheduleAbsenceObj[yearKey][weekKey] = [];
      }

      scheduleAbsenceObj[yearKey][weekKey].push(data);

    }

    // SORT DAY DATA WEEKS (UREN)
    for (const key in newHours) {
      newHours[key].sort((a, b) => (a.week > b.week) ? -1 : 1);
      for (let _weekData of newHours[key]) {
        _weekData.weekData.sort((a, b) => ((a.uren_begindatum > b.uren_begindatum) || (a.uren_begindatum === b.uren_begindatum && a.uren_begin > b.uren_begin)) ? 1 : -1);
      }
    }

    // CORRECTIONS
    let overhoursCorrections = {};
    overhoursCorrections[new Date().getFullYear().toString()] = {
      sep: [],
      total: 0,
      totalAT: 0,
    };
    for (const correction of overhoursCorrectionsData) {

      const date = Constants.stringToDate(correction.co_datum);
      const year = date.getFullYear();
      const yearKey = year.toString();

      if (typeof overhoursCorrections[yearKey] === 'undefined') {
        overhoursCorrections[yearKey] = {
          sep: [],
          total: 0,
          totalAT: 0,
        };
      }

      overhoursCorrections[yearKey].sep.push(correction);
      overhoursCorrections[yearKey].total += parseFloat(correction.co_aantal);

    }

    let yearCorrections = 0;
    for (const yearKey in overhoursCorrections) {
      overhoursCorrections[yearKey].totalAT += (parseFloat(overhoursCorrections[yearKey].total) + yearCorrections);
      yearCorrections += overhoursCorrections[yearKey].total;
    }

    // loading: false
    this.setState({ schedule2: scheduleAbsenceObj, absAmount2: absAmountObj, hoursWorked: newHours, overhours_corrections: overhoursCorrections,  });

  }

  getUsersMenu() {

    let toReturn = [];

    for (const person of this.state.accounts) {

      if (Constants.isEmpty(person.account_id) || Constants.isEmpty(person.info_geboorte)) {
        continue;
      }

      const infoExtra = this.state.infoExtra[person.info_id];

      if (this.state.showInactive === false) {
        if (Constants.isEmpty(infoExtra) === false && infoExtra.info2_status === '2') {
            continue;
        }
      }

      const contract = Constants.getCurrentContract(this.state.contracts[person.info_id], new Date());

      if (this.state.selectedContract === '0') {
        if (Constants.isEmpty(contract) === false && (contract.contract_type === '1' || contract.contract_type === '2')) {
          continue;
        }
      } else if (this.state.selectedContract === '1') {
        if ((Constants.isEmpty(contract) === false && contract.contract_type !== '1') || Constants.isEmpty(contract) === true) {
          continue;
        }
      } else if (this.state.selectedContract === '2') {
        if ((Constants.isEmpty(contract) === false && contract.contract_type !== '2') || Constants.isEmpty(contract) === true) {
          continue;
        }
      }

      const hasTeam = Constants.objectArrayContainsKey(this.state.missingTeamPeople, 'info_id', person.info_id) === false;
      const hasJob = Constants.objectArrayContainsKey(this.state.missingJobPeople, 'info_id', person.info_id) === false;

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;


        if (Constants.isEmpty(infoExtra) === false && infoExtra.info2_status === '2') {

          toReturn.push(

            <div className={`employees_menuButton_inactive ${this.state.selected === person.info_id ? 'activeAv' : ''}`} onClick={() => this.props.history.push(`/employees/${person.info_id}`)}>
              <div>{userFullName}</div>
              <i className="far fa-user-lock" style={{ color: '#DADADA', fontSize: '0.6vw' }} />
            </div>

          );

        } else {

          toReturn.push(

            <div className={`employees_menuButton ${this.state.selected === person.info_id ? 'activeAv' : ''}`} onClick={() => this.props.history.push(`/employees/${person.info_id}`)}>
              <div>{userFullName}</div>
              {hasTeam === false || hasJob === false ?
                <i className="fas fa-exclamation-triangle triangle" style={{ color: Colors.color.redFilledIn, fontSize: '0.6vw' }} />
              : null}
            </div>

          );

        }

      }

      return toReturn;

    }

  onChangeSomething() {

    setTimeout(() => {

        if (this.userData !== this.state.userData || this.state.rights !== parseInt(this.userData.account_rights)
            || this.state.password1 !== '' || this.state.password2 !== '') {
            this.setState({changed: true, specialChanges: true});
        } else {
            this.setState({changed: false, specialChanges: true});
        }

    }, 10);

  }

  undoChanges() {

    switch(this.state.menu) {

        case 'account':
            this.setState({
                password1: '',
                password2: '',
                rights: parseInt(this.userData.account_rights),
            });
            break;

        default:
            break;

    }

    this.setState({ userData: this.userData, changed: false, showError: false });

  }

  async onChangeTextData(data, event) {

    if (data === 'username') {
        this.setState({ userData: { ...this.state.userData, account_username: event.target.value }, showError: false, });
    } else if (data === 'firstname') {
        this.setState({ userData: { ...this.state.userData, info_voornaam: event.target.value }, showError: false, });
    } else if (data === 'insertion') {
        this.setState({ userData: { ...this.state.userData, info_tussenvoegsel: event.target.value }, showError: false, });
    } else if (data === 'lastname') {
        this.setState({ userData: { ...this.state.userData, info_achternaam: event.target.value }, showError: false, });
    } else if (data === 'iban') {
        this.setState({ userData: { ...this.state.userData, iban: event.target.value }, showError: false, });
    } else if (data === 'bsn') {
        this.setState({ userData: { ...this.state.userData, bsn: event.target.value }, showError: false, });
    } else if (data === 'email') {
        this.setState({ userData: { ...this.state.userData, info_email: event.target.value }, showError: false, });
    } else if (data === 'phone') {
        this.setState({ userData: { ...this.state.userData, info_telefoon: event.target.value }, showError: false, });
    } else if (data === 'phone2') {
        this.setState({ userData: { ...this.state.userData, info_telefoon2: event.target.value }, showError: false, });
    } else if (data === 'address') {
        this.setState({ userData: { ...this.state.userData, info_adres: event.target.value }, showError: false, });
    } else if (data === 'house') {
        this.setState({ userData: { ...this.state.userData, info_huisnummer: event.target.value }, showError: false, });
    } else if (data === 'zip') {
        this.setState({ userData: { ...this.state.userData, info_postcode: event.target.value }, showError: false, });
    } else if (data === 'city') {
        this.setState({ userData: { ...this.state.userData, info_plaats: event.target.value }, showError: false, });
    } else if (data === 'birthplace') {
        this.setState({ userData: { ...this.state.userData, info_geboorteplaats: event.target.value }, showError: false, });
    } else if (data === 'extra') {
        this.setState({ userData: { ...this.state.userData, info_extra: event.target.value }, showError: false, });
    } else if (data === 'employeeNumber') {
      if (event.target.value === '') {
        this.setState({employeeNumber: ''});
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_personeelsnummer = NULL`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      } else {
        this.setState({employeeNumber: event.target.value});
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_personeelsnummer = '${event.target.value}'`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      const infoExtraR = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
      const infoExtraRObj = Constants.convertToObject(infoExtraR, 'info2_info_id');
      this.setState({ infoExtra: infoExtraRObj });
    }

    if (data !== 'employeeNumber') {
      this.onChangeSomething();
    }

  }

  onChangeTemplateData(key, event) {

    let pdfFields = this.state.pdfFields;

    // if (typeof pdfFields[key] === 'undefined') {
    //   return;
    // }
    let field = Constants.getArrayItem(pdfFields, 'key', key);
    if (field === null) {
      return;
    }

    field.value = event.target.value;

    this.setState({ pdfFields: pdfFields }, () => {
      this.doUpdateTemplate();
    });

  }

  doUpdateTemplate() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.reloadContractTemplate();
    }, 800);
  }

  onChangeText(data, event) {

    if (data === 'pw1') {
        this.setState({ password1: event.target.value, showError: false, });
    } else if (data === 'pw2') {
        this.setState({ password2: event.target.value, showError: false, });
    }

    this.onChangeSomething();

  }

  onChangeNumberData(data, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    // let nr = parseFloat(formatted);

    // if (isNaN(nr) === true) {
    //     nr = formatted.replace('.', ',');
    // }

    // if (isNaN(nr) === true) {
    //     nr = formatted.replace(',', '.');
    // }

    if(parseFloat(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    // if (data === 'salary') {

    //     this.setState({ userSalary: nr.toString(), showError: false, }, async () => {
    //       //await APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${nr.toString() === '' ? 'NULL' : nr.toString()}`, `WHERE contract_id = ${this.state.currentContract.contract_id}`);
    //     });

    // } 
    // else if (data === 'hours') {
    //     this.setState({ userData: { ...this.state.userData, contract_uren: nr.toString() }, showError: false, });
    // } else if (data === 'hoursMin') {
    //   this.setState({ userData: { ...this.state.userData, contract_min: nr.toString() }, showError: false, });
    // } else if (data === 'hoursMax') {
    //   this.setState({ userData: { ...this.state.userData, contract_max: nr.toString() }, showError: false, });
    // } else if (data === 'extraVacation') {
    //   this.setState({ userData: { ...this.state.userData, contract_vakantie_bovenwettelijk: nr.toString() }, showError: false, });
    // } else if (data === 'vacation') {
    //   this.setState({ userData: { ...this.state.userData, contract_vakantie_dagen: nr.toString() }, showError: false, });
    // }

    this.onChangeSomething();

  }

  getFunctionBars() {

    let functions = [];

    for(let key in this.state.functions) {

        const func = this.state.functions[key];

        functions.push(
            <option value={func.functie_id} selected={func.functie_id === this.state.userData.functie_id}>{func.functie_naam}</option>
        );

    }

    return functions;

  }

  selectFunction(event) {

    this.setState({
        userData: { ...this.state.userData, functie_id: isNaN(parseInt(event.target.value)) === true ? null : event.target.value },
        showError: false,
    });

    this.onChangeSomething();

  }

  async selectFunction_function(event) {

    const fid = event.target.value;

    this.setState({loading: true});

    await APIEDIT.Request(`UPDATE Info`, `SET info_functie_id = ${isNaN(parseInt(fid)) === true ? null : fid}`, `WHERE info_id = ${this.state.selected}`);

    if (isNaN(parseInt(fid)) === false) {
      await APIDELETE.Request(`DELETE FROM FunctieExtra`, `WHERE fe_functie_id = ${fid} AND fe_info_id = ${this.state.selected}`);

      const secondaryFunctions = await APIGET.Request(`SELECT functie_naam, functie_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, `WHERE fe_info_id = ${this.state.userData.account_id}`, null, null);
      this.setState({secondaryFunctions: secondaryFunctions});
    }

    await this.reloadScreen();

    this.userData.functie_id = isNaN(parseInt(fid)) === true ? null : fid;

    this.setState({
      userData: { ...this.state.userData, functie_id: isNaN(parseInt(fid)) === true ? null : fid },
      showError: false,
      loading: false,
    });

  }

  setBirthDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

      const dateObject = date;

      const dateText = Constants.dateToString(date);

      this.setState({
          userData: { ...this.state.userData, info_geboorte: dateText },
          showError: false,
      });

      this.onChangeSomething();

  }

  setStartDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
    }

      const dateObject = date;

      const dateText = Constants.dateToString(date);

      this.setState({
          userData: { ...this.state.userData, info_indienst: dateText },
          showError: false,
      });

      this.onChangeSomething();

  }

  setStartDateContract(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
    }

      const dateObject = date;

      const dateText = Constants.dateToString(date);

      this.setState({
          userData: { ...this.state.userData, contract_start: dateText },
          showError: false,
      });

      this.onChangeSomething();

  }

  setEndDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

      const dateObject = date;

      const dateText = Constants.dateToString(date);

      this.setState({
          userData: { ...this.state.userData, contract_eind: dateText },
          showError: false,
      });

      this.onChangeSomething();

  }

  async editAdminAccount() {

    this.setState({loading: true});

    // ADD TO MAIN STORE ADMIN ACCOUNT

    const storecodeadmin = localStorage.getItem('@storecode');

    if(this.state.password2 === '') {
      await APIEditAccount.Request(
          this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
          null,
          parseInt(this.userData.account_rights) === this.state.rights ? parseInt(this.userData.account_rights) : this.state.rights,
          parseInt(this.userData.account_id),
          storecodeadmin
      );
    } else {
        await APIEditAccount.Request(
            this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
            CryptoJS.SHA512(this.state.password2).toString(),
            parseInt(this.userData.account_rights) === this.state.rights ? parseInt(this.userData.account_rights) : this.state.rights,
            parseInt(this.userData.account_id),
            storecodeadmin
        );
    }

    await APIEditAccountInfo.Request(
      this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
      parseInt(this.userData.account_id),
      this.userData.info_voornaam === this.state.userData.info_voornaam ? this.userData.info_voornaam : this.state.userData.info_voornaam,
      this.userData.info_tussenvoegsel === this.state.userData.info_tussenvoegsel ? this.userData.info_tussenvoegsel : this.state.userData.info_tussenvoegsel,
      this.userData.info_achternaam === this.state.userData.info_achternaam ? this.userData.info_achternaam : this.state.userData.info_achternaam,
      this.userData.info_telefoon === this.state.userData.info_telefoon ? this.userData.info_telefoon : this.state.userData.info_telefoon,
      this.userData.info_email === this.state.userData.info_email ? this.userData.info_email : this.state.userData.info_email,
      this.userData.functie_id === this.state.userData.functie_id ? this.userData.functie_id : this.state.userData.functie_id,
      this.userData.info_extra === this.state.userData.info_extra ? this.userData.info_extra : this.state.userData.info_extra,
      this.userData.info_adres === this.state.userData.info_adres ? this.userData.info_adres : this.state.userData.info_adres,
      this.userData.info_huisnummer === this.state.userData.info_huisnummer ? this.userData.info_huisnummer : this.state.userData.info_huisnummer,
      this.userData.info_postcode === this.state.userData.info_postcode ? this.userData.info_postcode : this.state.userData.info_postcode,
      this.userData.info_geboorte === this.state.userData.info_geboorte ? this.userData.info_geboorte : this.state.userData.info_geboorte,
      this.userData.info_indienst === this.state.userData.info_indienst ? this.userData.info_indienst : this.userData.info_indienst === '0000-00-00' || this.userData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.userData.info_indienst,
      this.userData.iban === this.state.userData.iban ? this.userData.iban : this.state.userData.iban,
      this.userData.bsn === this.state.userData.bsn ? this.userData.bsn : this.state.userData.bsn,
      this.userData.info_plaats === this.state.userData.info_plaats ? this.userData.info_plaats : this.state.userData.info_plaats,
      this.userData.info_telefoon2 === this.state.userData.info_telefoon2 ? this.userData.info_telefoon2 : this.state.userData.info_telefoon2,
      this.userData.info_geboorteplaats === this.state.userData.info_geboorteplaats ? this.userData.info_geboorteplaats : this.state.userData.info_geboorteplaats,
      storecodeadmin
    );

    // EDIT ADMIN ACCOUNT ON ALL TEAMS

    for(const team of Data.data.teams) {

      if(this.state.password2 === '') {
        await APIEditAccount.Request(
            this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
            null,
            parseInt(this.userData.account_rights) === this.state.rights ? parseInt(this.userData.account_rights) : this.state.rights,
            parseInt(this.userData.account_id),
            team.bedrijf_code
        );
      } else {
          await APIEditAccount.Request(
              this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
              CryptoJS.SHA512(this.state.password2).toString(),
              parseInt(this.userData.account_rights) === this.state.rights ? parseInt(this.userData.account_rights) : this.state.rights,
              parseInt(this.userData.account_id),
              team.bedrijf_code
          );
      }

      await APIEditAccountInfo.Request(
        this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
        parseInt(this.userData.account_id),
        this.userData.info_voornaam === this.state.userData.info_voornaam ? this.userData.info_voornaam : this.state.userData.info_voornaam,
        this.userData.info_tussenvoegsel === this.state.userData.info_tussenvoegsel ? this.userData.info_tussenvoegsel : this.state.userData.info_tussenvoegsel,
        this.userData.info_achternaam === this.state.userData.info_achternaam ? this.userData.info_achternaam : this.state.userData.info_achternaam,
        this.userData.info_telefoon === this.state.userData.info_telefoon ? this.userData.info_telefoon : this.state.userData.info_telefoon,
        this.userData.info_email === this.state.userData.info_email ? this.userData.info_email : this.state.userData.info_email,
        this.userData.functie_id === this.state.userData.functie_id ? this.userData.functie_id : this.state.userData.functie_id,
        this.userData.info_extra === this.state.userData.info_extra ? this.userData.info_extra : this.state.userData.info_extra,
        this.userData.info_adres === this.state.userData.info_adres ? this.userData.info_adres : this.state.userData.info_adres,
        this.userData.info_huisnummer === this.state.userData.info_huisnummer ? this.userData.info_huisnummer : this.state.userData.info_huisnummer,
        this.userData.info_postcode === this.state.userData.info_postcode ? this.userData.info_postcode : this.state.userData.info_postcode,
        this.userData.info_geboorte === this.state.userData.info_geboorte ? this.userData.info_geboorte : this.state.userData.info_geboorte,
        this.userData.info_indienst === this.state.userData.info_indienst ? this.userData.info_indienst : this.userData.info_indienst === '0000-00-00' || this.userData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.userData.info_indienst,
        this.userData.iban === this.state.userData.iban ? this.userData.iban : this.state.userData.iban,
        this.userData.bsn === this.state.userData.bsn ? this.userData.bsn : this.state.userData.bsn,
        this.userData.info_plaats === this.state.userData.info_plaats ? this.userData.info_plaats : this.state.userData.info_plaats,
        this.userData.info_telefoon2 === this.state.userData.info_telefoon2 ? this.userData.info_telefoon2 : this.state.userData.info_telefoon2,
        this.userData.info_geboorteplaats === this.state.userData.info_geboorteplaats ? this.userData.info_geboorteplaats : this.state.userData.info_geboorteplaats,
        team.bedrijf_code
      );

    }

    // if (this.hasFilledContract() === true) {

    //   if (typeof this.state.contracts[this.state.selected] === 'undefined') {

    //     await APIAddContract.Request(
    //         parseInt(this.userData.account_id),
    //         parseFloat(this.state.userData.contract_bruto_uurloon),
    //     );

    //   } else {

    //       await APIEditContract.Request(
    //           parseInt(this.userData.account_id),
    //           parseFloat(this.state.userData.contract_bruto_uurloon),
    //       );

    //   }

    //   if (this.state.userData.contract_type === '0') { //nuluren
    //     await this.saveContractNulUren();
    //   }

    // }

    //CHange local account data
    if(Data.data.accountData.account_id === this.userData.account_id) {

      Data.data.accountData = await APIGetAccountInfo.Request(this.userData.account_id, storecodeadmin);

      localStorage.setItem('@username', Data.data.accountData.account_username);
      localStorage.setItem('@password', Data.data.accountData.account_password);

    }

    this.editedAlert();

    this.reloadScreen();

  }

  async editAccount() {

    this.setState({loading: true});

    let skipAccount = false;
    let accountAdded = false;

    if(this.userData.account_username === this.state.userData.account_username
        && parseInt(this.userData.account_rights) === this.state.rights
        && this.state.password2 === '') {

            skipAccount = true;

    } else {

        if(this.state.password2 === '') {
            accountAdded = await APIEditAccount.Request(
                this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
                null,
                parseInt(this.userData.account_rights) === this.state.rights ? parseInt(this.userData.account_rights) : this.state.rights,
                parseInt(this.userData.account_id)
            );
        } else {
            accountAdded = await APIEditAccount.Request(
                this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
                CryptoJS.SHA512(this.state.password2).toString(),
                parseInt(this.userData.account_rights) === this.state.rights ? parseInt(this.userData.account_rights) : this.state.rights,
                parseInt(this.userData.account_id)
            );
        }

    }

    if(accountAdded === true || skipAccount === true) {

        let accountInfoAdded = await APIEditAccountInfo.Request(
            this.userData.account_username === this.state.userData.account_username ? this.userData.account_username : this.state.userData.account_username,
            parseInt(this.userData.account_id),
            this.userData.info_voornaam === this.state.userData.info_voornaam ? this.userData.info_voornaam : this.state.userData.info_voornaam,
            this.userData.info_tussenvoegsel === this.state.userData.info_tussenvoegsel ? this.userData.info_tussenvoegsel : this.state.userData.info_tussenvoegsel,
            this.userData.info_achternaam === this.state.userData.info_achternaam ? this.userData.info_achternaam : this.state.userData.info_achternaam,
            this.userData.info_telefoon === this.state.userData.info_telefoon ? this.userData.info_telefoon : this.state.userData.info_telefoon,
            this.userData.info_email === this.state.userData.info_email ? this.userData.info_email : this.state.userData.info_email,
            this.userData.functie_id === this.state.userData.functie_id ? this.userData.functie_id : this.state.userData.functie_id,
            this.userData.info_extra === this.state.userData.info_extra ? this.userData.info_extra : this.state.userData.info_extra,
            this.userData.info_adres === this.state.userData.info_adres ? this.userData.info_adres : this.state.userData.info_adres,
            this.userData.info_huisnummer === this.state.userData.info_huisnummer ? this.userData.info_huisnummer : this.state.userData.info_huisnummer,
            this.userData.info_postcode === this.state.userData.info_postcode ? this.userData.info_postcode : this.state.userData.info_postcode,
            this.userData.info_geboorte === this.state.userData.info_geboorte ? this.userData.info_geboorte : this.state.userData.info_geboorte,
            this.userData.info_indienst === this.state.userData.info_indienst ? this.userData.info_indienst : this.userData.info_indienst === '0000-00-00' || this.userData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.userData.info_indienst,
            this.userData.iban === this.state.userData.iban ? this.userData.iban : this.state.userData.iban,
            this.userData.bsn === this.state.userData.bsn ? this.userData.bsn : this.state.userData.bsn,
            this.userData.info_plaats === this.state.userData.info_plaats ? this.userData.info_plaats : this.state.userData.info_plaats,
            this.userData.info_telefoon2 === this.state.userData.info_telefoon2 ? this.userData.info_telefoon2 : this.state.userData.info_telefoon2,
            this.userData.info_geboorteplaats === this.state.userData.info_geboorteplaats ? this.userData.info_geboorteplaats : this.state.userData.info_geboorteplaats,
        );

        if (accountInfoAdded === true) {

          //if (this.hasFilledContract() === true) {

            // if (typeof this.state.contracts[this.state.selected] === 'undefined') {

            //   const endDate = this.state.userData.contract_eind === null || this.state.userData.contract_eind === '' ? null : `'${this.state.userData.contract_eind}'`;
            //   const salary = isNaN(parseFloat(this.state.userData.contract_bruto_uurloon)) === true ? null : parseFloat(this.state.userData.contract_bruto_uurloon);

            //   accountInfoAdded = await APIADD.Request(`INSERT INTO Contract (contract_info_id, contract_bruto_uurloon, contract_eind)`, `VALUES (${parseInt(this.userData.account_id)}, ${salary}, ${endDate})`)

            //   // accountInfoAdded = await APIAddContract.Request(
            //   //     parseInt(this.userData.account_id),
            //   //     parseFloat(this.state.userData.contract_bruto_uurloon),
            //   // );

            // } else {

            //   const endDate = this.state.userData.contract_eind === null || this.state.userData.contract_eind === '' ? null : `'${this.state.userData.contract_eind}'`;
            //   const salary = isNaN(parseFloat(this.state.userData.contract_bruto_uurloon)) === true ? null : parseFloat(this.state.userData.contract_bruto_uurloon);

            //   accountInfoAdded = await APIEDIT.Request(`UPDATE Contract`, `SET contract_bruto_uurloon = ${salary}, contract_eind = ${endDate}`, `WHERE contract_info_id = ${parseInt(this.userData.account_id)}`);

            //     // accountInfoAdded = await APIEditContract.Request(
            //     //     parseInt(this.userData.account_id),
            //     //     parseFloat(this.state.userData.contract_bruto_uurloon),
            //     // );

            // }

            // if (this.state.userData.contract_type === '0') { //nuluren
            //   await this.saveContractNulUren();
            // }

          //}

        }

        if(accountInfoAdded === true) {

            //CHange local account data
            if(Data.data.accountData.account_id === this.userData.account_id) {

              Data.data.accountData = await APIGetAccountInfo.Request(this.userData.account_id);

              localStorage.setItem('@username', Data.data.accountData.account_username);
              localStorage.setItem('@password', Data.data.accountData.account_password);

            }

            this.editedAlert();

            this.reloadScreen();

            return;

        } else {

            // console.log('error');

            this.setState({loading: false});

        }

    } else {

        // console.log('error');

        this.setState({loading: false});

    }
  }

  editedAlert() {
    this.setState({
        alertTitle: __('employees_alert_changed_title'),
        alertBody: Localization.t.formatString(__('employees_alert_changed'), <b>{this.state.userData.account_username}</b>),
        alertButtons: 1,
        alertButtonAction: null,
        alertButtonText: [__('button_ok')],
        showAlert: true,

        specialChanges: false,
    });
  }

  async saveAccAlert() {

    if (await this.checkValidations() === false) {
        return;
    }

    this.setState({
        alertTitle: __('alert_save_title'),
        alertBody: (
            <div>
                {__('alert_save')}
            </div>
        ),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_save')],
        alertButtonAction: this.editAccount.bind(this),
        showAlert: true,
    });
  }

  async reloadScreen() {

    this.setState({loading: true});

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamUsers = await APIGetTeamUsers.Request();

      const teamUsers = await APIGetTeamUsers.Request();
      const people = await APIGetAllAccounts.Request();

      const missingTeamPeople = Constants.getMissingPeopleTeam(people, teamUsers);

      this.setState({
        missingTeamPeople: missingTeamPeople,
      });

    }

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    await this.getAccounts();
    await this.getContracts();

    if(Data.data.accountData.account_rights === '2' && (Data.data.appOptions.bsn === true || Data.data.appOptions.iban === true)) {
      await this.getSecureData();
    }

    this.setState({

      infoExtra: infoExtraObj,

      leaveBalances: leaveBalances,
      contractLeaveBalances: contractLeaveBalancesObj,

    }, async () => {

      if (this.state.selectedName !== '') {
        await this.selectUser(this.state.userData.info_id, this.state.selectedName, true);
        await this.selectMenu(this.state.menu, true);
      }

      this.setState({loading: false, changed: false,});

    });

  }

  async checkValidations() {

    if (this.state.menu === 'contract') {

      const userData = this.state.userData;

      // if (this.isEmpty(userData.contract_overeenkomst)) {
      //   this.setState({
      //     showError: true,
      //     errorText: 'Je hebt het contract niet volledig ingevuld',
      //   });
      //   return false;
      // }
      // if (this.isEmpty(userData.contract_type)) {
      //   this.setState({
      //     showError: true,
      //     errorText: 'Je hebt het contract niet volledig ingevuld',
      //   });
      //   return false;
      // }

      // if (!this.isEmpty(userData.contract_overeenkomst) && !this.isEmpty(userData.contract_type)) {

      //   if (userData.contract_type === '0' && this.hasFilledContractNulUren() === false) { //NULUREN
      //     this.setState({
      //       showError: true,
      //       errorText: 'Je hebt het contract niet volledig ingevuld',
      //     });
      //     return false;
      //   }

      // }

    } else if (this.state.menu === 'account') {

        if(this.state.userData.account_username === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_username'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9]*$/.test(this.state.userData.account_username) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_username'),
            });
            return false;
        }

        if(this.userData.account_username !== this.state.userData.account_username) {

            const userNameExists = await APICheckUsernameAvailibility.Request(this.state.userData.account_username);

            if(userNameExists === true) {
                this.setState({
                    showError: true,
                    errorText: __('error_duplicate_username'),
                });
                return false;
            }

        }

        if(this.state.password1 !== '' && this.state.password2 === '') {
            this.setState({
                showError: true,
                errorText: __('error_notAdded_passwordConfirm'),
            });
            return false;
        }

        if((this.state.password1 !== '' && this.state.password2 !== '') && this.state.password1 !== this.state.password2) {
            this.setState({
                showError: true,
                errorText: __('error_notSame_password'),
            });
            return false;
        }

    } else if (this.state.menu === 'info') {

        if(this.state.userData.info_voornaam === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_firstName'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_voornaam) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_firstName'),
            });
            return false;
        }
        if(this.state.userData.info_tussenvoegsel !== '' && /^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_tussenvoegsel) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_insertion'),
            });
            return false;
        }
        if(this.state.userData.info_achternaam === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_lastName'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_achternaam) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_lastName'),
            });
            return false;
        }
        if(this.state.userData.info_geboorte === null || this.state.userData.info_geboorte === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_birth'),
            });
            return false;
        }
        if(this.state.userData.info_geboorteplaats === null || this.state.userData.info_geboorteplaats === '') {
          this.setState({
              showError: true,
              errorText: __('error_notFilled_birthPlace'),
          });
          return false;
        }
        if(this.state.userData.info_email === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_email'),
            });
            return false;
        }
        const emailValid = await this.checkEmailValid();
        if (emailValid === false) {
          this.setState({
              showError: true,
              errorText: __('error_duplicate_email'),
          });
          return false;
        }
        // eslint-disable-next-line no-useless-escape
        if(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.userData.info_email) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_email'),
            });
            return false;
        }
        if(this.state.userData.info_telefoon === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_phone'),
            });
            return false;
        }
        // eslint-disable-next-line no-useless-escape
        if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.userData.info_telefoon) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_phone'),
            });
            return false;
        }
        // eslint-disable-next-line no-useless-escape
        if((this.state.userData.info_telefoon2 !== '' && this.state.userData.info_telefoon2 !== null) && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.userData.info_telefoon2) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_phone2'),
            });
            return false;
        }

    } else if (this.state.menu === 'address') {

        if(this.state.userData.info_adres === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_address'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_adres) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_address'),
            });
            return false;
        }
        if(this.state.userData.info_huisnummer === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_addressHouse'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9\u00C0-\u017F\s-()]*$/.test(this.state.userData.info_huisnummer) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_addressHouse'),
            });
            return false;
        }
        if(this.state.userData.info_postcode === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_zip'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_postcode) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_zip'),
            });
            return false;
        }
        if(this.state.userData.info_plaats === '') {
            this.setState({
                showError: true,
                errorText: __('error_notFilled_place'),
            });
            return false;
        }
        if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.userData.info_plaats) === false) {
            this.setState({
                showError: true,
                errorText: __('error_invalid_place'),
            });
            return false;
        }

    }

    return true;

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText' style={{marginTop: 0, marginLeft: '1vw', marginBottom: '1vw'}}>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  deleteAlert() {

    const userFullName = `${this.state.userData.info_voornaam} ${(this.state.userData.info_tussenvoegsel !== null && this.state.userData.info_tussenvoegsel !== '') ? this.state.userData.info_tussenvoegsel + " " + this.state.userData.info_achternaam : this.state.userData.info_achternaam}`;

    let admins = 0;
    for (const acc of this.state.accounts) {
      if (acc.account_rights === '2') {
        admins++;
      }
    }

    if (admins <= 1 && this.state.userData.account_rights === '2') {
        this.setState({
            alertTitle: __('alert_failed_title'),
            alertBody: __('employees_alert_failed_admin'),
            alertButtons: 1,
            alertButtonText: [__('button_ok')],
            showAlert: true,
        });
        return;
    }

    if (this.state.accounts.length === 1) {
      this.setState({
          alertTitle: __('alert_failed_title'),
          alertBody: __('employees_alert_failed_delete'),
          alertButtons: 1,
          alertButtonText: [__('button_ok')],
          showAlert: true,
      });
      return;
    }

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: Localization.t.formatString(__('employees_alert_delete_employee'), <b>{userFullName}</b>),
        alertButtons: 2,
        alertButtonAction: this.deleteAccount.bind(this),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });

  }

  lockAlert(lock) {

    const userFullName = `${this.state.userData.info_voornaam} ${(this.state.userData.info_tussenvoegsel !== null && this.state.userData.info_tussenvoegsel !== '') ? this.state.userData.info_tussenvoegsel + " " + this.state.userData.info_achternaam : this.state.userData.info_achternaam}`;

    if (this.userData.account_rights === '2') {
        this.setState({
            alertTitle: __('alert_failed_title'),
            alertBody: __('employees_alert_failed_inactive'),
            alertButtons: 1,
            alertButtonText: [__('button_ok')],
            showAlert: true,
        });
        return;
    }

    if (lock === true) {

      this.setState({
          alertTitle: __('alert_edit_title'),
          alertBody: Localization.t.formatString(__('employees_alert_inactive_employee'), <b>{userFullName}</b>),
          alertButtons: 2,
          alertButtonAction: this.lockAccount.bind(this, lock),
          alertButtonText: [__('button_cancel'), __('button_inactive')],
          showAlert: true,
      });

    } else {

      this.setState({
          alertTitle: __('alert_edit_title'),
          alertBody: Localization.t.formatString(__('employees_alert_active_employee'), <b>{userFullName}</b>),
          alertButtons: 2,
          alertButtonAction: this.lockAccount.bind(this, lock),
          alertButtonText: [__('button_cancel'), __('button_active')],
          showAlert: true,
      });

    }
    
  }

  async lockAccount(lock) {

    if (lock === true) {
      await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_status = 2`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
    } else {
      await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_status = 1`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
    }

    this.setState({ employeeStatus: lock ? '2' : null });

  }

  notifyFile(doc) {

    const userFullName = `${this.state.userData.info_voornaam} ${(this.state.userData.info_tussenvoegsel !== null && this.state.userData.info_tussenvoegsel !== '') ? this.state.userData.info_tussenvoegsel + " " + this.state.userData.info_achternaam : this.state.userData.info_achternaam}`;

    this.setState({
        alertTitle: __('employees_alert_notifyUsers_title'),
        alertBody: Localization.t.formatString(__('employees_alert_documents_notify'), <b>{userFullName}</b>),
        alertButtons: 2,
        alertButtonAction: this.notifyUserFile.bind(this, doc),
        alertButtonText: [__('button_cancel'), __('button_notification')],
        showAlert: true,
    });
  }

  async notifyUserFile(doc) {

    const docName = `${doc.d_naam.substring(0, 5)}...${doc.d_naam.substr(doc.d_naam.length - 5)}`;

    await KodictNotifications.sendPushNotification(this.state.userData.info_id, 'news', 'Document ondertekenen', `Jouw leidinggevende wilt graag dat je het document ${docName} bekijkt of digitaal ondertekent. Tik op deze melding en ga in het menu naar 'Mijn documenten'`);

    this.setState({
        alertTitle: __('alert_success_title'),
        alertBody: __('employees_alert_notification'),
        alertButtons: 1,
        alertButtonAction: null,
        alertButtonText: [__('button_ok')],
        showAlert: true,
    });

  }

  deleteContractTemplateAlert() {

     this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: __('alert_delete'),
        alertButtons: 2,
        alertButtonAction: this.deleteContractTemplate.bind(this),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });

  }

  async deleteContractTemplate() {

    const doc = this.state.chosenContractObj;

    await APIDelFileContract.Request(doc.dc_loc);

    await APIDELETE.Request(`DELETE FROM DocumentC`, `WHERE dc_id = ${doc.dc_id}`);

    const docs = await APIGET.Request(`SELECT *`, `FROM DocumentC`, null, null, null, `ORDER BY dc_naam`);

    this.setState({ 
      documentsContract: docs,
      fileContract: null,
      chosenContractTemplate: null,
      chosenContractObj: null,
      formPdfBytes: null,
      pdfForm: null,
      pdfForm_notFlat: null,
      pdfFields: [],
      specialChanges: false,
      contractSigned: false,
    });

  }

  deleteAlertFile(doc, notFlattenDoc) {

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: __('alert_delete'),
        alertButtons: 2,
        alertButtonAction: this.deleteFile.bind(this, doc, notFlattenDoc),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });
  }

  async deleteFile(doc, notFlattenDoc) {

    await APIDelFile.Request(doc.d_loc);

    await APIDELETE.Request(`DELETE FROM Document`, `WHERE d_id = ${doc.d_id}`);

    if (Constants.isEmpty(notFlattenDoc) === false) {
      await APIDelFile.Request(notFlattenDoc.d_loc);
      await APIDELETE.Request(`DELETE FROM Document`, `WHERE d_id = ${notFlattenDoc.d_id}`);
    }

    const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.state.userData.info_id}`, null, `ORDER BY d_naam`);

    this.setState({ documents: docs });

  }

  deleteAlertFileSigned(mainDoc, doc) {

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: __('alert_delete'),
      alertButtons: 2,
      alertButtonAction: this.deleteFileSigned.bind(this, mainDoc, doc),
      alertButtonText: [__('button_cancel'), __('button_delete')],
      showAlert: true,
    });
  }

  async deleteFileSigned(mainDoc, doc) {

    await APIDelFile.Request(doc.do_loc);

    await APIDELETE.Request(`DELETE FROM DocumentOndertekend`, `WHERE do_id = ${doc.do_id}`);

    const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.state.userData.info_id}`, null, `ORDER BY d_naam`);
    const docsSigned = await APIGET.Request(`SELECT *`, `FROM DocumentOndertekend`, null, `WHERE do_info_id = ${this.state.userData.info_id}`, null, `ORDER BY do_id`);
    const docsSignedObj = Constants.convertToObjectWithArrays(docsSigned, 'do_d_id');

    if (typeof this.state.documentsSigned[mainDoc.d_id] === 'undefined' || this.state.documentsSigned[mainDoc.d_id].length === 1) {
      await APIEDIT.Request(`UPDATE Document`, `SET d_getekend = 0, d_datum = NULL`, `WHERE d_id = ${mainDoc.d_id}`);
    }

    this.setState({ documentsSigned: docsSignedObj, documents: docs });

  }

  async deleteAccount() {

    this.setState({loading: true});

    const added = await APIDeleteAccount.Request(
        parseInt(this.userData.account_id),
    );

    if(added === true) {

      await this.selectUser('all', '', true);

      await this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  hasChangedContract() {

    return (this.state.userData.contract_eind !== this.userData.contract_eind || this.state.userData.contract_uren !== this.userData.contract_uren
        || this.state.userData.contract_bruto_uurloon !== this.userData.contract_bruto_uurloon);

  }

  hasFilledContract() {

    return !this.isEmpty(this.state.userData.contract_bruto_uurloon);

  }

  hasFilledContractNulUren() {

    const userData = this.state.userData;

    if (this.isEmpty(userData.contract_vast)) {
      return false;
    }

    if (userData.contract_vast === '0') { // tijdelijk
      if (this.isEmpty(userData.info_indienst) && this.isEmpty(userData.contract_eind)) {
        return false;
      }
    }
    if (userData.contract_vast === '1') { // vast
      if (this.isEmpty(userData.info_indienst)) {
        return false;
      }
    }

    if (this.isEmpty(userData.functie_id) || this.isEmpty(userData.contract_werkzaamheden) || this.isEmpty(userData.contract_waar)
      || this.isEmpty(userData.contract_bruto_uurloon) || this.isEmpty(userData.info_plaats) || this.isEmpty(userData.info_geboorteplaats)) {
      return false;
    }

    if (this.isEmpty(userData.contract_vakantiegeld_wanneer1)) { // maand of jaar vakantiegeld
      return false;
    }

    if (userData.contract_vakantiegeld_wanneer1 === '1') { // jaar
      if (this.isEmpty(userData.contract_vakantiegeld_wanneer2)) { // mei, juni, anders not empty please
        return false;
      }
      if (userData.contract_vakantiegeld_wanneer2 === '2' && this.isEmpty(userData.contract_vakantiegeld_wanneer3)) { // anders - not empty please
        return false;
      }
    }

    return true;

  }

  isEmpty(data) {
    return typeof data === 'undefined' || data === undefined || data === null || data === '' || data === 'NULL' || data === 'null' || data === 'undefined' || data === '0000-00-00';
  }

  async saveContractNulUren() {

    const userData = this.state.userData;

    await APIEditContractNulUren.Request(
      parseInt(this.userData.account_id), //info id
      parseInt(userData.contract_overeenkomst), // overeenkomst (arbeid, oproep)
      userData.contract_vast === '1' ? null : userData.contract_eind,
      0, //uren
      parseFloat(userData.contract_bruto_uurloon), //uurloon
      0, //vakantiedagen
      parseInt(userData.contract_type), // type (nuluren, vast)
      parseInt(userData.contract_vast), // tijdelijk of vast
      userData.contract_werkzaamheden,
      userData.contract_proef,
      userData.contract_waar,
      parseInt(userData.contract_vakantiegeld_wanneer1), // maand of jaar
      isNaN(parseInt(userData.contract_vakantiegeld_wanneer2)) ? null : parseInt(userData.contract_vakantiegeld_wanneer2), // mei, juni, anders
      userData.contract_vakantiegeld_wanneer3, // anders maand
      isNaN(parseInt(userData.contract_vakantie_bovenwettelijk)) ? 0 : parseInt(userData.contract_vakantie_bovenwettelijk),
      userData.contract_vergoeding,
      userData.contract_cao2,
      userData.contract_pensioen,
    );

  }

  async viewContract() {

    const userData = this.state.userData;

    if (this.isEmpty(userData.contract_overeenkomst)) {
      this.setState({
        showError: true,
        errorText: 'Je hebt het contract niet volledig ingevuld',
      });
      return;
    }
    if (this.isEmpty(userData.contract_type)) {
      this.setState({
        showError: true,
        errorText: 'Je hebt het contract niet volledig ingevuld',
      });
      return;
    }

    if (!this.isEmpty(userData.contract_overeenkomst) && !this.isEmpty(userData.contract_type)) {

      if (userData.contract_type === '0' && this.hasFilledContractNulUren() === false) { //NULUREN
        this.setState({
          showError: true,
          errorText: 'Je hebt het contract niet volledig ingevuld',
        });
        return;
      }

    }

    this.setState({loading: true});

    //if (this.userData.account_id === '1' && Data.data.teams.length > 1) {
    //  await this.editAdminAccount();
    //} else {
      await this.editAccount();
    //}

    this.props.history.push({
      pathname: `/contract`,
      _userData: this.state.userData,
      _functions: this.state.functions,
    });

  }

  async selectTeam(team, add) {

    if(this.state.buttonLoading === true || this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    if(add === true) {

        await APIAddToTeam.Request(team.team_id, this.state.userData.account_id);

    } else {

        await APIDeleteFromTeam.Request(team.team_id, this.state.userData.account_id);

    }

    const teams = await APIGetTeamsOwn.Request(this.state.userData.account_id);

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      const teamUsers = await APIGetTeamUsers.Request();
      const people = await APIGetAllAccounts.Request();

      const missingTeamPeople = Constants.getMissingPeopleTeam(people, teamUsers);

      this.setState({
        missingTeamPeople: missingTeamPeople,
      });

    }

    await this.getAccounts();
    await this.getContracts();

    this.setState({teams: teams, loading: false});

  }

  showTeams() {

    let toReturn = [];

    for (const team of Data.data.teams) {

        toReturn.push(
            <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw'}}>

              <div style={{display: 'flex', flex: 7}}>
                <div>{team.team_naam}</div>
              </div>

              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={Constants.objectArrayContains(this.state.teams, team.team_naam)}
                        onClick={() => this.selectTeam(team, Constants.objectArrayContains(this.state.teams, team.team_naam) === false)}
                        readOnly
                    />
                    <span className="slider round"></span>
                </label>
              </div>
            </div>
        );

    }

    return toReturn;

  }

  async selectSkill(skill, add) {

    if(this.state.buttonLoading === true || this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    if(add === true) {

        await APIAddSkillUser.Request(skill.comp_id, this.state.userData.account_id);

    } else {

        await APIDeleteSkillUser.Request(skill.comp_id, this.state.userData.account_id);

    }

    const skillsUsers = await APIGetAllSkillsUsers.Request();
    const skillsUsersObj = Constants.convertToObjectWithArrays(skillsUsers, 'info_id');

    const employeeSkills = skillsUsersObj[this.state.userData.account_id];

    if (typeof employeeSkills !== 'undefined') {
      this.setState({
        employeeSkills: employeeSkills
      });
    } else {
      this.setState({
        employeeSkills: []
      });
    }

    this.setState({skillsUsers: skillsUsersObj, employeeSkills: employeeSkills, loading: false});

  }

  showSkills() {

    let toReturn = [];

    for (const skill of this.state.skills) {

        toReturn.push(
            <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw'}}>

              <div style={{display: 'flex', flex: 7}}>
                <div>{skill.comp_naam}</div>
              </div>

              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={Constants.objectArrayContainsKey(this.state.employeeSkills, 'comp_naam', skill.comp_naam)}
                        onClick={() => this.selectSkill(skill, Constants.objectArrayContainsKey(this.state.employeeSkills, 'comp_naam', skill.comp_naam) === false)}
                        readOnly
                    />
                    <span className="slider round"></span>
                </label>
              </div>
            </div>
        );

    }

    return toReturn;

  }

  async selectSecondaryFunction(func, add) {

    if(this.state.buttonLoading === true || this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    if(add === true) {

      await APIADD.Request(`INSERT INTO FunctieExtra`, `VALUES (NULL, ${this.state.userData.account_id}, ${func.functie_id})`);

    } else {

      await APIDELETE.Request(`DELETE FROM FunctieExtra`, `WHERE fe_functie_id = ${func.functie_id} AND fe_info_id = ${this.state.userData.account_id}`);

    }

    const secondaryFunctions = await APIGET.Request(`SELECT functie_naam, functie_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, `WHERE fe_info_id = ${this.state.userData.account_id}`, null, null);

    this.setState({secondaryFunctions: secondaryFunctions, loading: false});

  }

  showSecondaryFunctions() {

    let toReturn = [];

    for (const func of this.state.functions) {

      if (func.functie_id === this.state.userData.functie_id) {
        continue;
      }

      toReturn.push(
          <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw'}}>

            <div style={{display: 'flex', flex: 7}}>
              <div>{func.functie_naam}</div>
            </div>

            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
              <label className="switch">
                  <input
                      type="checkbox"
                      checked={Constants.objectArrayContainsKey(this.state.secondaryFunctions, 'functie_naam', func.functie_naam)}
                      onClick={() => this.selectSecondaryFunction(func, Constants.objectArrayContainsKey(this.state.secondaryFunctions, 'functie_naam', func.functie_naam) === false)}
                      readOnly
                  />
                  <span className="slider round"></span>
              </label>
            </div>
          </div>
      );

    }

    return toReturn;

  }

  async onChangeNumberData2(data, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }

    if (data === 'maxDays') {
      if (nr === '') {
        this.setState({maxDays: '0'});
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_max_dagen = 0`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      } else {
        this.setState({maxDays: parseInt(nr).toString()});
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_max_dagen = ${parseInt(nr)}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
    }
    if (data === 'maxCumulative') {
      if (nr === '') {
        this.setState({maxCumulative: '0'});
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_opeenvolgend = 0`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      } else {
        this.setState({maxCumulative: parseInt(nr).toString()});
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_opeenvolgend = ${parseInt(nr)}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
    }

    const infoExtraR = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraRObj = Constants.convertToObject(infoExtraR, 'info2_info_id');
    this.setState({ infoExtra: infoExtraRObj });

  }

  getSalaryModelBars() {

    let models = [];

    for(const model of this.state.salaryModels) {

        models.push(
            <option value={model.ut_id} selected={model.ut_id === this.state.useSalaryModel}>{model.ut_naam}</option>
        );

    }

    return models;

  }

  selectSalaryModel(e) {

    this.setState({
      useSalaryModel: e.target.value === 'null' ? null : e.target.value,
    }, async () => {

      if (this.state.useSalaryModel !== null) {

        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = ${this.state.useSalaryModel}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);

        const dateNow = await Constants.getDateNow();
        const age = Constants.getAge(dateNow, Constants.stringToDate(this.state.userData.info_geboorte));
        const salaries = this.state.salaries[this.state.useSalaryModel];

        if (salaries.length !== 0) {

          let newSalary = 0;

          if ((age) <= parseInt(salaries[0].uurloon_leeftijd)) {
            newSalary = parseFloat(salaries[0].uurloon_hoeveelheid);
          } else if ((age) >= parseInt(salaries[salaries.length - 1].uurloon_leeftijd)) {
            newSalary = parseFloat(salaries[salaries.length - 1].uurloon_hoeveelheid);
          } else {
            for (const generalSalary of salaries) {
              if ((age) === parseInt(generalSalary.uurloon_leeftijd)) {
                newSalary = parseFloat(generalSalary.uurloon_hoeveelheid);
                break;
              }
            }
          }

          const contract = Constants.getCurrentContract(this.state.contracts[this.state.userData.info_id], dateNow);
          if (contract === null) {
            //await APIADD.Request(`INSERT INTO ContractN (contract_info_id, contract_uurloon)`, `VALUES (${this.state.userData.info_id}, ${newSalary})`);
          } else {
            await APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${newSalary}`, `WHERE contract_id = ${contract.contract_id}`);
          }

          // await APIEDIT.Request(`UPDATE Contract`, `SET contract_bruto_uurloon = ${newSalary}`, `WHERE contract_info_id = ${this.state.userData.info_id}`);

        }

        const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
        const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

        this.setState({infoExtra: infoExtraObj},
          async () => {
            await this.reloadScreen();
            this.setState({reloading: false});
          });

      } else {

        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = NULL`, `WHERE info2_info_id = ${this.state.userData.info_id}`);

        const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
        const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

        this.setState({infoExtra: infoExtraObj},
          async () => {
            await this.reloadScreen();
            this.setState({reloading: false});
          });

      }

    });

  }

  showSettings() {

    return (
      <div>

        {/* <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw'}}>
          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>Gebruik algemene werkdagen</div>
          </div>

          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={this.state.useWorkWeek}
                    onClick={() => this.setState({ useWorkWeek: !this.state.useWorkWeek })}
                    readOnly
                />
                <span className="slider round"></span>
            </label>
          </div>
        </div> */}

        <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', marginLeft: '1vw'}}>
          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>{__('employees_userSettings_employeeNumber')}</div>
            <div style={{fontSize: '0.6vw'}}>{__('employees_userSettings_employeeNumber_sub')}</div>
          </div>
          <div style={{display: 'flex', flex: 3, justifyContent: 'flex-end'}}>
            <input
              className={'employees_inputBoxTemplate'}
              onChange={(event) => this.onChangeTextData('employeeNumber', event)}
              value={this.state.employeeNumber}
              placeholder={__('employees_userSettings_employeeNumber')}
              maxLength={100}
              type={'text'}
            />
          </div>
        </div>

        <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', marginLeft: '1vw'}}>

          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>{__('employees_userSettings_register')}</div>
            <div style={{fontSize: '0.6vw'}}>{__('employees_userSettings_register_sub')}</div>
          </div>

          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={this.state.canRegister}
                    onClick={() => this.editNewSettingsInfo('info2_registreren', this.state.canRegister === true ? 0 : 1)}
                    readOnly
                />
                <span className="slider round"></span>
            </label>
          </div>
        </div>

        <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', marginLeft: '1vw'}}>
          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>{__('employees_userSettings_maxDays')}</div>
            <div style={{fontSize: '0.6vw'}}>{__('employees_userSettings_maxDays_sub')}</div>
          </div>
          <div style={{display: 'flex', flex: 1.2, justifyContent: 'flex-end'}}>
            <input
              ref={(ref) => this.timeRef['maxDays']=ref}
              className={'employees_inputBoxTime'}
              onChange={(event) => this.onChangeNumberData2('maxDays', 0, 7, event)}
              onKeyDown={(event) => this.handleKey(event, 'maxDays')}
              value={this.state.maxDays}
              placeholder={'0'}
              maxLength={1}
              type={'number'}
              style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
            />
          </div>
        </div>

        <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', marginLeft: '1vw'}}>
          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>{__('employees_userSettings_endTime')}</div>
            <div style={{fontSize: '0.6vw'}}>{__('employees_userSettings_endTime_sub')}</div>
          </div>
          <div style={{display: 'flex', flex: 1.2, justifyContent: 'flex-end'}}>
            <input
              ref={(ref) => this.timeRef['maxEnd']=ref}
              className={'employees_inputBoxTime'}
              onChange={(event) => this.editNewSettingsInfo('info2_max_eind', event.target.value, event)}
              onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
              value={this.state.timesStandard['maxEnd']}
              placeholder={__('employees_userSettings_endTime')}
              maxLength={5}
              style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
            />
          </div>
        </div>

        <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%', marginLeft: '1vw'}}>
          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>{__('employees_userSettings_maxCumulative')}</div>
            <div style={{fontSize: '0.6vw'}}>{__('employees_userSettings_maxCumulative_sub')}</div>
          </div>
          <div style={{display: 'flex', flex: 1.2, justifyContent: 'flex-end'}}>
            <input
              ref={(ref) => this.timeRef['maxCumulative']=ref}
              className={'employees_inputBoxTime'}
              onChange={(event) => this.onChangeNumberData2('maxCumulative', 0, 9999, event)}
              onKeyDown={(event) => this.handleKey(event, 'maxCumulative')}
              value={this.state.maxCumulative}
              placeholder={'0'}
              maxLength={2}
              type={'number'}
              style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
            />
          </div>
        </div>

        {/* <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw'}}>
          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <div>Inklokcode</div>
          </div>
          <div style={{display: 'flex', flex: 1.2, justifyContent: 'flex-end'}}>
            <input
              className={'employees_inputBoxTime'}
              onChange={(event) => this.setState({ clockCode: event.target.value })}
              value={this.state.clockCode}
              placeholder={'0000'}
              maxLength={4}
              type={'text'}
              style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
            />
          </div>
        </div> */}

      </div>
    );

  }

  async editNewSettingsInfo(data, value, event) {

    if (data === 'info2_max_eind') {

      this.onChangeTime(event, 'maxEnd', true, 'availability');

      if (value.length === 5 && this.isValidForSave(value) === true) {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET ${data} = '${value}'`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET ${data} = NULL`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }

    } else if (data === 'info2_registreren') {

      this.setState({ canRegister: !this.state.canRegister });
      await APIEDIT.Request(`UPDATE InfoExtra`, `SET ${data} = ${value}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);

    }

    const infoExtraR = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraRObj = Constants.convertToObject(infoExtraR, 'info2_info_id');
    this.setState({ infoExtra: infoExtraRObj });

  }

  async saveExtraInfo() {

    let _timesStandard = this.state.timesStandard;

    if (_timesStandard['maxEnd'] === '') {
      _timesStandard['maxEnd'] = '00:00';
    }

    // declare start and end
    let start = _timesStandard['maxEnd'].substr(0, 5);

    // check if start and end are correct
    if (start.length !== 5 || this.isValidForSave(start) === false) {
      _timesStandard['maxEnd'] = '';
      alert(__('error_invalid_endTime'));
      this.setState({ timesStandard: _timesStandard });
      return;
    }

    let clockCode = this.state.clockCode;

    // check if start and end are correct
    // if (Constants.isEmpty(clockCode) === false) {
    //   if (/^\d+$/.test(clockCode) === false) {
    //     clockCode = '';
    //     alert(`Inklokcode mag alleen cijfers bevatten`);
    //     this.setState({ clockCode: clockCode });
        // return;
    //   }
    //   if (clockCode.length < 4) {
    //     clockCode = '';
    //     alert(`Inklokcode moet 4 cijfers bevatten`);
    //     this.setState({ clockCode: clockCode });
    //     return;
    //   }
    //   const pinData = await APIGET.Request(`SELECT info2_info_id`, `FROM InfoExtra`, null, `WHERE info2_klokcode = '${clockCode}'`, null, null);
    //   if (pinData.length > 0) {
    //     clockCode = '';
    //     alert(`Inklokcode bestaat al`);
    //     this.setState({ clockCode: clockCode });
    //     return;
    //   }
    // }

    this.setState({reloading: true});

    // corrections

    let maxDays = this.state.maxDays;
    let maxCumulative = this.state.maxCumulative;
    let canRegister = this.state.canRegister === true ? 1 : 0;

    if (maxDays === '') {
      maxDays = 0;
    }
    if (maxCumulative === '') {
      maxCumulative = 0;
    }

    await APIDELETE.Request(
      `DELETE FROM InfoExtra`,
      `WHERE info2_info_id = ${this.state.userData.info_id}`
    );

    await APIADD.Request(
      `INSERT INTO InfoExtra`,
      `VALUES (NULL, ${this.state.userData.info_id}, ${maxDays}, '${start}', ${maxCumulative}, ${canRegister}, ${this.state.workWeekMonday === true ? '1' : '0'}, ${this.state.workWeekTuesday === true ? '1' : '0'}, ${this.state.workWeekWednesday === true ? '1' : '0'}, ${this.state.workWeekThursday === true ? '1' : '0'}, ${this.state.workWeekFriday === true ? '1' : '0'}, ${this.state.workWeekSaturday === true ? '1' : '0'}, ${this.state.workWeekSunday === true ? '1' : '0'}, ${this.state.useSalaryModel === null ? 'NULL' : this.state.useSalaryModel}, ${this.state.useWorkWeek === true ? '1' : '0'}, '${clockCode}')`
    );

    // save salary
    if (this.state.useSalaryModel !== null) {

      const dateNow = await Constants.getDateNow();
      const age = Constants.getAge(dateNow, Constants.stringToDate(this.state.userData.info_geboorte));
      const salaries = this.state.salaries[this.state.useSalaryModel];

      if (salaries.length !== 0) {

        let newSalary = 0;

        if ((age) <= parseInt(salaries[0].uurloon_leeftijd)) {
          newSalary = parseFloat(salaries[0].uurloon_hoeveelheid);
        } else if ((age) >= parseInt(salaries[salaries.length - 1].uurloon_leeftijd)) {
          newSalary = parseFloat(salaries[salaries.length - 1].uurloon_hoeveelheid);
        } else {
          for (const generalSalary of salaries) {
            if ((age) === parseInt(generalSalary.uurloon_leeftijd)) {
              newSalary = parseFloat(generalSalary.uurloon_hoeveelheid);
              break;
            }
          }
        }

        //await APIEDIT.Request(`UPDATE Contract`, `SET contract_bruto_uurloon = ${newSalary}`, `WHERE contract_info_id = ${this.state.userData.info_id}`);

      }

    }

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({infoExtra: infoExtraObj},
      async () => {
        await this.reloadScreen();
        this.setState({reloading: false});
      });

  }

  async nextYear(next) {

    this.setState({loading: true});

    this.setState({
      statYear: next ? (this.state.statYear + 1) : (this.state.statYear - 1),
    }, async () => {

      await this.getAllHours();
      await this.getAllAvailabilities();
      await this.getAllUnavailabilities();

      await this.getAllAbsencesUser(this.state.selected);

      this.setState({loading: false});
    });

  }

  renderStats(key) {

    const month = key === 'jan' ? 0 : key === 'feb' ? 1 : key === 'mrt' ? 2 : key === 'apr' ? 3 : key === 'mei' ? 4 : key === 'jun' ? 5 : key === 'jul' ? 6
    : key === 'aug' ? 7 : key === 'sept' ? 8 : key === 'okt' ? 9 : key === 'nov' ? 10 : key === 'dec' ? 11 : 0;
    const dateObject = Constants.stringToDate(`${this.state.statYear}-${(month + 1) < 10 ? '0' + (month + 1) : (month + 1)}-01`);

    let allHours = this.state.allHours;

    if (typeof allHours[key][this.state.selected] === 'undefined') {
      allHours[key][this.state.selected] = {
        rooster_werkdagen: '0',
        rooster_bruto_uren: '0',
        rooster_pauze_totaal: '0',
        rooster_netto_uren: '0',
      };
    }

    const hasContract = typeof this.state.contracts[this.state.selected] !== 'undefined';
    const salary = hasContract ? parseFloat(this.state.contracts[this.state.selected].contract_bruto_uurloon) : null;

    const avDays = typeof this.state.allAvailabilities[key][this.state.selected] !== 'undefined' ? parseInt(this.state.allAvailabilities[key][this.state.selected].beschikbaar_dagen) : 0;
    const unavDays = typeof this.state.allUnavailabilities[key][this.state.selected] !== 'undefined' ? parseInt(this.state.allUnavailabilities[key][this.state.selected].onbeschikbaar_dagen) : 0;
    const nfDays = Constants.getDaysInMonth((month + 1), this.state.statYear) - avDays - unavDays;

    let absencesToRender = [];

    for (const abs of this.state.allAbsences) {

      const userAbsence = typeof this.state.userAbsences[key][abs.afw_id] !== 'undefined' ? this.state.userAbsences[key][abs.afw_id].length : 0;

      absencesToRender.push(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>{abs.afw_naam}:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {userAbsence}
          </div>
        </div>
      );
    }

    const salaryFactor = parseFloat(Data.data.appOptions.opties_loonfactor);

    return (

      <div style={{display: 'flex', flexDirection: 'column'}}>

        <b style={{fontSize: '1vw', marginTop: '0.5vw'}}>Urenregistratie</b>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Gewerkte dagen:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {allHours[key][this.state.selected].rooster_werkdagen}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Bruto uren:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_bruto_uren)}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Pauzes:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_pauze_totaal)}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Netto uren:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_netto_uren)}
          </div>
        </div>

        <b style={{fontSize: '1vw', marginTop: '0.5vw'}}>Salaris</b>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Bruto loon:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {hasContract ? this.formatter.format(Constants.round(Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_netto_uren) * salary * salaryFactor)) : 'n.v.t.'}
          </div>
        </div>

        {Data.data.appOptions.availability !== 'STANDARD' && Data.data.appOptions.availability !== 'SCHOOL' && Data.data.appOptions.availability !== 'NONE' ?
          <div style={{marginTop: '0.5vw'}}>

            <b style={{fontSize: '1vw'}}>Beschikbaarheid</b>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
              <div style={{display: 'flex', flex: 1.6}}>
                <span>Beschikbare dagen:</span>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                {avDays} ({Constants.round((avDays / Constants.getDaysInMonth((month + 1), this.state.statYear)) * 100)}%)
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
              <div style={{display: 'flex', flex: 1.6}}>
                <span>Onbeschikbare dagen:</span>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                {unavDays} ({Constants.round((unavDays / Constants.getDaysInMonth((month + 1), this.state.statYear)) * 100)}%)
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
              <div style={{display: 'flex', flex: 1.6}}>
                <span>Dagen niet ingevuld:</span>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                {nfDays} ({Constants.round((nfDays / Constants.getDaysInMonth((month + 1), this.state.statYear)) * 100)}%)
              </div>
            </div>

          </div>
        : null}

        <b style={{fontSize: '1vw', marginTop: '0.5vw'}}>Afwezigheid</b>

        {absencesToRender}

      </div>

    );

  }

  renderStatsYear() {

    let workYear = 0;
    let hoursYear = 0;
    let breakYear = 0;
    let netHoursYear = 0;

    let salaryYear = 0;
    let vacYear = 0;
    let netSalaryYear = 0;

    let avDaysYear = 0;
    let unavDaysYear = 0;
    let nfDaysYear = 0;

    const daysYear = Constants.getDaysInYear(this.state.statYear);

    const hasContract = typeof this.state.contracts[this.state.selected] !== 'undefined';

    let allHours = this.state.allHours;

    let absences = {};

    for (const key in this.state.allHours) {

      const month = key === 'jan' ? 0 : key === 'feb' ? 1 : key === 'mrt' ? 2 : key === 'apr' ? 3 : key === 'mei' ? 4 : key === 'jun' ? 5 : key === 'jul' ? 6
      : key === 'aug' ? 7 : key === 'sept' ? 8 : key === 'okt' ? 9 : key === 'nov' ? 10 : key === 'dec' ? 11 : 0;
      const dateObject = Constants.stringToDate(`${this.state.statYear}-${(month + 1) < 10 ? '0' + (month + 1) : (month + 1)}-01`);

      if (typeof allHours[key][this.state.selected] === 'undefined') {
        allHours[key][this.state.selected] = {
          rooster_werkdagen: '0',
          rooster_bruto_uren: '0',
          rooster_pauze_totaal: '0',
          rooster_netto_uren: '0',
        };
      }

      workYear += parseInt(allHours[key][this.state.selected].rooster_werkdagen);
      hoursYear += Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_bruto_uren);
      breakYear += Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_pauze_totaal);
      netHoursYear += Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_netto_uren);

      const salary = hasContract ? parseFloat(this.state.contracts[this.state.selected].contract_bruto_uurloon) : null;
      const salaryFactor = parseFloat(Data.data.appOptions.opties_loonfactor);

      salaryYear += Constants.round(Constants.timeStringToFloat(allHours[key][this.state.selected].rooster_netto_uren) * salary * salaryFactor);

      const avDays = typeof this.state.allAvailabilities[key][this.state.selected] !== 'undefined' ? parseInt(this.state.allAvailabilities[key][this.state.selected].beschikbaar_dagen) : 0;
      const unavDays = typeof this.state.allUnavailabilities[key][this.state.selected] !== 'undefined' ? parseInt(this.state.allUnavailabilities[key][this.state.selected].onbeschikbaar_dagen) : 0;
      const nfDays = Constants.getDaysInMonth((month + 1), this.state.statYear) - avDays - unavDays;

      avDaysYear += avDays;
      unavDaysYear += unavDays;
      nfDaysYear += nfDays;

      for (const abs of this.state.allAbsences) {

        const userAbsence = typeof this.state.userAbsences[key][abs.afw_id] !== 'undefined' ? this.state.userAbsences[key][abs.afw_id].length : 0;

        if (typeof absences[abs.afw_id] === 'undefined') {
          absences[abs.afw_id] = { name: abs.afw_naam, quantity: 0 };
        }

        absences[abs.afw_id].quantity += userAbsence;

      }

    }

    let absencesToRender = [];

    for (const key in absences) {

      absencesToRender.push(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>{absences[key].name}:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {absences[key].quantity}
          </div>
        </div>
      );
    }

    return (

      <div style={{display: 'flex', flexDirection: 'column'}}>

        <b style={{fontSize: '1vw', marginTop: '0.5vw'}}>Urenregistratie</b>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Gewerkte dagen:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {workYear}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Bruto uren:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {hoursYear}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Pauzes:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {breakYear}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Netto uren:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {netHoursYear}
          </div>
        </div>

        <b style={{fontSize: '1vw', marginTop: '0.5vw'}}>Salaris</b>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
          <div style={{display: 'flex', flex: 1.6}}>
            <span>Bruto loon:</span>
          </div>
          <div style={{display: 'flex', flex: 1}}>
            {hasContract ? this.formatter.format(salaryYear) : 'n.v.t.'}
          </div>
        </div>

        {Data.data.appOptions.availability !== 'STANDARD' && Data.data.appOptions.availability !== 'SCHOOL' && Data.data.appOptions.availability !== 'NONE' ?
          <div style={{marginTop: '0.5vw'}}>

            <b style={{fontSize: '1vw'}}>Beschikbaarheid</b>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
              <div style={{display: 'flex', flex: 1.6}}>
                <span>Beschikbare dagen:</span>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                {avDaysYear} ({Constants.round((avDaysYear / daysYear) * 100)}%)
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
              <div style={{display: 'flex', flex: 1.6}}>
                <span>Onbeschikbare dagen:</span>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                {unavDaysYear} ({Constants.round((unavDaysYear / daysYear) * 100)}%)
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1vw'}}>
              <div style={{display: 'flex', flex: 1.6}}>
                <span>Dagen niet ingevuld:</span>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                {nfDaysYear} ({Constants.round((nfDaysYear / daysYear) * 100)}%)
              </div>
            </div>

          </div>
        : null}

        <b style={{fontSize: '1vw', marginTop: '0.5vw'}}>Afwezigheid</b>

        {absencesToRender}

      </div>

    );

  }

  setWholeDay(i2) {

    let wholedays = this.state.wholedayStandard;
    wholedays[i2] = !this.state.wholedayStandard[i2];
    this.setState({ wholedayStandard: wholedays, specialChanges: true });

  }

  setAvailableStandard(i2, available) {

    let availables = this.state.availableStandard;
    let wholedays = this.state.wholedayStandard;

    //availables[i2] = available ? 1 : 2;

    if (available === true && availables[i2] === 1) {
      availables[i2] = 0;
      wholedays[i2] = false;
    } else if (available === false && availables[i2] === 2) {
      availables[i2] = 0;
      wholedays[i2] = false;
    } else {
      if (availables[i2] === 2) {
        wholedays[i2] = false;
      }
      availables[i2] = available ? 1 : 2;
    }

    if (availables[i2] === 2) {
      wholedays[i2] = true;
    }

    this.setState({ availableStandard: availables, wholedayStandard: wholedays, specialChanges: true });

  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 10) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  onChangeTime(e, i2, fromOther, option) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.state.timesStandard;

    if (option === 'availability') {
      times = this.state.timesStandard;
    } else if (option === 'default_workdays') {
      times = this.state.defaultWorkdays;
    }

    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times });
      } else if (option === 'default_workdays') {
        this.setState({ defaultWorkdays: times });
      }

      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        const _function = this.state.defaultWorkdays_functions[i2];

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
          APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
        } else {

          let _break = `'${this.state.defaultWorkdays_breaks[i2]}'`;
          if (this.state.defaultWorkdays_breaks[i2].length !== 5 || this.isValidForSave(this.state.defaultWorkdays_breaks[i2]) === false) {
            _break = 'NULL';
          }

          APIADD.Request(`INSERT INTO VasteDagen`, `VALUES (NULL, ${this.state.userData.info_id}, ${_function}, ${i2}, '${start}', '${end}', ${_break})`);

        }

        this.setState({ defaultWorkdays: times });

      }

      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          times[i2] = e.target.value;

          if (option === 'availability') {
            this.setState({ timesStandard: times, specialChanges: i2 === 'maxEnd' ? false : true });
          } else if (option === 'default_workdays') {

            const start = e.target.value.substr(0, 5);
            const end = e.target.value.substr(8, 13);

            const _function = this.state.defaultWorkdays_functions[i2];

            // check if start and end are correct
            if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
              APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
            } else {

              let _break = `'${this.state.defaultWorkdays_breaks[i2]}'`;
              if (this.state.defaultWorkdays_breaks[i2].length !== 5 || this.isValidForSave(this.state.defaultWorkdays_breaks[i2]) === false) {
                _break = 'NULL';
              }

              APIADD.Request(`INSERT INTO VasteDagen`, `VALUES (NULL, ${this.state.userData.info_id}, ${_function}, ${i2}, '${start}', '${end}', ${_break})`);

            }

            this.setState({ defaultWorkdays: times });

          }

          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times, specialChanges: i2 === 'maxEnd' ? false : true });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        const _function = this.state.defaultWorkdays_functions[i2];

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
          APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
        } else {

          let _break = `'${this.state.defaultWorkdays_breaks[i2]}'`;
          if (this.state.defaultWorkdays_breaks[i2].length !== 5 || this.isValidForSave(this.state.defaultWorkdays_breaks[i2]) === false) {
            _break = 'NULL';
          }

          APIADD.Request(`INSERT INTO VasteDagen`, `VALUES (NULL, ${this.state.userData.info_id}, ${_function}, ${i2}, '${start}', '${end}', ${_break})`);

        }

        this.setState({ defaultWorkdays: times });

      }

    } else {
      e.target.value = '';
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times, specialChanges: i2 === 'maxEnd' ? false : true });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        const _function = this.state.defaultWorkdays_functions[i2];

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
          APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
        } else {

          let _break = `'${this.state.defaultWorkdays_breaks[i2]}'`;
          if (this.state.defaultWorkdays_breaks[i2].length !== 5 || this.isValidForSave(this.state.defaultWorkdays_breaks[i2]) === false) {
            _break = 'NULL';
          }

          APIADD.Request(`INSERT INTO VasteDagen`, `VALUES (NULL, ${this.state.userData.info_id}, ${_function}, ${i2}, '${start}', '${end}', ${_break})`);

        }

        this.setState({ defaultWorkdays: times });

      }

    }

  }

  onChangeTimeBreak(e, i2, fromOther, option) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.state.defaultWorkdays_breaks;

    if (option === 'default_workdays') {
      times = this.state.defaultWorkdays_breaks;
    }

    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 5) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'default_workdays') {
        this.setState({ defaultWorkdays_breaks: times });
      }

      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'default_workdays') {

        const start = this.state.defaultWorkdays[i2].substr(0, 5);
        const end = this.state.defaultWorkdays[i2].substr(8, 13);
        const _function = this.state.defaultWorkdays_functions[i2];

        const _break = e.target.value;

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
          APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
        } else {
          if (_break.length !== 5 || this.isValidForSave(_break) === false) {
            APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = NULL`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
          } else {
            APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = '${_break}'`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
          }
        }

        this.setState({ defaultWorkdays_breaks: times });

      }

      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length > 5) {
          e.target.value = val;
          times[i2] = e.target.value;

          if (option === 'default_workdays') {

            const start = this.state.defaultWorkdays[i2].substr(0, 5);
            const end = this.state.defaultWorkdays[i2].substr(8, 13);
            const _function = this.state.defaultWorkdays_functions[i2];

            const _break = e.target.value;

            // check if start and end are correct
            if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
              APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
            } else {
              if (_break.length !== 5 || this.isValidForSave(_break) === false) {
                APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = NULL`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
              } else {
                APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = '${_break}'`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
              }
            }

            this.setState({ defaultWorkdays_breaks: times });

          }

          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times[i2] = e.target.value;

        if (option === 'default_workdays') {

          const start = this.state.defaultWorkdays[i2].substr(0, 5);
          const end = this.state.defaultWorkdays[i2].substr(8, 13);
          const _function = this.state.defaultWorkdays_functions[i2];

          const _break = e.target.value;

          // check if start and end are correct
          if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
            APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
          } else {
            if (_break.length !== 5 || this.isValidForSave(_break) === false) {
              APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = NULL`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
            } else {
              APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = '${_break}'`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
            }
          }
  
          this.setState({ defaultWorkdays_breaks: times });
  
        }

    } else {
      e.target.value = '';
      times[i2] = e.target.value;

      if (option === 'default_workdays') {

        const start = this.state.defaultWorkdays[i2].substr(0, 5);
        const end = this.state.defaultWorkdays[i2].substr(8, 13);
        const _function = this.state.defaultWorkdays_functions[i2];

        const _break = e.target.value;

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {
          APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
        } else {
          if (_break.length !== 5 || this.isValidForSave(_break) === false) {
            APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = NULL`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
          } else {
            APIEDIT.Request(`UPDATE VasteDagen`, `SET vd_pauze = '${_break}'`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${i2}`);
          }
        }

        this.setState({ defaultWorkdays_breaks: times });

      }

    }

  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      if (this.state.menu === 'availability') {
        this.saveStandardAvailability(i2);
      }

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async saveAllStandardAvailability(i2) {

    if (this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({ reloading: true });

    for (let i = 0; i < 7; i++) {
      await this.saveStandardAvailability(i);
    }

    this.setState({ reloading: false, specialChanges: false });

  }

  async saveStandardAvailability(i2) {

    let _wholedayStandard = this.state.wholedayStandard;
    let _availableStandard = this.state.availableStandard;
    let _timesStandard = this.state.timesStandard;

    if (_wholedayStandard[i2] === false && _timesStandard[i2] === '') {
      return;
    }

    // declare start and end
    let start = _timesStandard[i2].substr(0, 5);
    let end = _timesStandard[i2].substr(8, 13);

    if (_wholedayStandard[i2] === true) {
      start = '00:00';
      end = '00:00';
    }

    const startDate = Constants.getDateTZ('2020-01-01', start);
    const endDate = Constants.getDateTZ('2020-01-01', end);

    let dayName = '';
    if (i2 === 1) {
      dayName = __('misc_monday');
    } else if (i2 === 2) {
      dayName = __('misc_tuesday');
    } else if (i2 === 3) {
      dayName = __('misc_wednesday');
    } else if (i2 === 4) {
      dayName = __('misc_thursday');
    } else if (i2 === 5) {
      dayName = __('misc_friday');
    } else if (i2 === 6) {
      dayName = __('misc_saturday');
    } else if (i2 === 0) {
      dayName = __('misc_sunday');
    }

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
      _timesStandard[i2] = '';
      alert(Localization.t.formatString(__('employees_availability_invalid_startEndTime'), dayName.toLowerCase()));
      this.setState({ timesStandard: _timesStandard });
      return;
    }

    if (startDate.getTime() > endDate.getTime()) {
      _timesStandard[i2] = '';
      alert(Localization.t.formatString(__('employees_availability_invalid_startEndTime2'), dayName.toLowerCase()));
      this.setState({ timesStandard: _timesStandard });
      return;
    }

    await APIDELETE.Request(
      `DELETE FROM StandaardBeschikbaarheid`,
      `WHERE sb_info_id = ${this.state.userData.info_id} AND sb_dag = ${i2}`
    );

    await APIADD.Request(
      `INSERT INTO StandaardBeschikbaarheid`,
      `VALUES (NULL, ${i2}, ${_availableStandard[i2]}, '${start}', '${end}', ${this.state.userData.info_id}, '')`
    );

  }

  showStandardAvailability() {

    let toReturn = [];
    let count = 0;

    for (let i = 0; i < 7; i++) {

      const i2 = i === 6 ? 0 : i + 1;
      let dayName = '';

      if (i === 0) {
        dayName = __('misc_monday');
      } else if (i === 1) {
        dayName = __('misc_tuesday');
      } else if (i === 2) {
        dayName = __('misc_wednesday');
      } else if (i === 3) {
        dayName = __('misc_thursday');
      } else if (i === 4) {
        dayName = __('misc_friday');
      } else if (i === 5) {
        dayName = __('misc_saturday');
      } else if (i === 6) {
        dayName = __('misc_sunday');
      }

      toReturn.push(

        <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1vw', backgroundColor: count === 1 ? '#F9FBFC' :  'white'}}>

          <div style={{display: 'flex', flex: 8}}>
            <div style={{fontSize: '1vw', fontWeight: 'bold'}}>{dayName}</div>
          </div>

          <div style={{display: 'flex', flex: 6, flexDirection: 'column'}}>
            <span style={this.state.availableStandard[i2] === 1 ? {color: Colors.color.greenFilledIn} : null}>{__('misc_available')}</span>
            <span style={{marginTop: 8, color: this.state.availableStandard[i2] === 2 ? Colors.color.redFilledIn : null}}>{__('misc_unavailable')}</span>
          </div>

          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={this.state.availableStandard[i2] === 1}
                    onClick={() => this.setAvailableStandard(i2, true)}
                    readOnly
                />
                <span className="slider round"></span>
            </label>
            <label className="switch" style={{marginTop: 8}}>
                <input
                    type="checkbox"
                    checked={this.state.availableStandard[i2] === 2}
                    onClick={() => this.setAvailableStandard(i2, false)}
                    readOnly
                />
                <span className="slider round"></span>
            </label>
          </div>

          <div style={{display: 'flex', flex: 3}}>
            <span>{__('misc_wholeDay')}</span>
          </div>

          <div style={{display: 'flex', flex: 3}}>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={this.state.wholedayStandard[i2] === true}
                    onClick={() => this.setWholeDay(i2)}
                    readOnly
                    disabled={this.state.availableStandard[i2] === 2}
                />
                <span className="slider round"></span>
            </label>
          </div>

          <div style={{display: 'flex', flex: 5}}>
            <input
              ref={(ref) => this.timeRef[i2]=ref}
              className={this.state.wholedayStandard[i2] === false ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
              onChange={(event) => this.onChangeTime(event, i2, true, 'availability')}
              onKeyDown={(event) => this.handleKey(event, i2, 'availability')}
              value={this.state.timesStandard[i2]}
              placeholder={__('misc_startEndTime')}
              style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
              disabled={this.state.wholedayStandard[i2] === true}
            />
          </div>

          {/* <div style={{display: 'flex', flex: 4, justifyContent: 'flex-end'}}>
            <span className='employees_saveStandard' onClick={() => this.saveStandardAvailability(i2)}>Opslaan</span>
          </div> */}
        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toReturn;

  }

  async setWorkWeek() {

    if (this.state.useWorkWeek === false) {
      await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${Data.data.appOptions.opties_verlof_maandag}, info2_werkdag_dinsdag = ${Data.data.appOptions.opties_verlof_dinsdag}, info2_werkdag_woensdag = ${Data.data.appOptions.opties_verlof_woensdag}, info2_werkdag_donderdag = ${Data.data.appOptions.opties_verlof_donderdag}, info2_werkdag_vrijdag = ${Data.data.appOptions.opties_verlof_vrijdag}, info2_werkdag_zaterdag = ${Data.data.appOptions.opties_verlof_zaterdag}, info2_werkdag_zondag = ${Data.data.appOptions.opties_verlof_zondag}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
    }

    await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkweek = ${this.state.useWorkWeek === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({infoExtra: infoExtraObj, useWorkWeek: !this.state.useWorkWeek}, async () => {
      await this.reloadScreen();
    });

  }

  async setWorkDay(day) {

    if (day === 'mon') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_maandag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekMonday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${this.state.workWeekMonday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekMonday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 1`);
      }
      this.setState({ workWeekMonday: !this.state.workWeekMonday});
    } else if (day === 'tue') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_dinsdag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekTuesday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_dinsdag = ${this.state.workWeekTuesday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekTuesday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 2`);
      }
      this.setState({ workWeekTuesday: !this.state.workWeekTuesday});
    } else if (day === 'wed') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_woensdag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekWednesday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_woensdag = ${this.state.workWeekWednesday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekWednesday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 3`);
      }
      this.setState({ workWeekWednesday: !this.state.workWeekWednesday});
    } else if (day === 'thu') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_donderdag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekThursday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_donderdag = ${this.state.workWeekThursday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekThursday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 4`);
      }
      this.setState({ workWeekThursday: !this.state.workWeekThursday});
    } else if (day === 'fri') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_vrijdag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekFriday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_vrijdag = ${this.state.workWeekFriday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekFriday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 5`);
      }
      this.setState({ workWeekFriday: !this.state.workWeekFriday});
    } else if (day === 'sat') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_zaterdag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekSaturday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_zaterdag = ${this.state.workWeekSaturday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekSaturday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 6`);
      }
      this.setState({ workWeekSaturday: !this.state.workWeekSaturday});
    } else if (day === 'sun') {
      if (typeof this.state.infoExtra[this.state.userData.info_id] === 'undefined') {
        await APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_zondag)`, `VALUES(NULL, ${this.state.userData.info_id}, ${this.state.workWeekSunday === false ? '1' : '0'})`);
      } else {
        await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_zondag = ${this.state.workWeekSunday === false ? '1' : '0'}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      }
      if (this.state.workWeekSunday === true) {
        await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = 0`);
      }
      this.setState({ workWeekSunday: !this.state.workWeekSunday});
    }

    if (this.state.useWorkWeek === true) {
      await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkweek = 0`, `WHERE info2_info_id = ${this.state.userData.info_id}`);
      this.setState({useWorkWeek: false});
    }

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    // Default workdays
    let _defaultWorkdays = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      0: '',
    };
    let _defaultWorkdays_breaks = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      0: '',
    };
    let _defaultWorkdays_functions = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      0: '',
    };
    const defaultWorkDaysUser = await APIGET.Request(`SELECT *`, `FROM VasteDagen`, null, `WHERE vd_info_id = ${this.state.userData.info_id}`, null, null);
    for (const _wd of defaultWorkDaysUser) {
      _defaultWorkdays[parseInt(_wd.vd_dag)] = `${_wd.vd_begin.substr(0,5)} - ${_wd.vd_eind.substr(0,5)}`;
      _defaultWorkdays_functions[parseInt(_wd.vd_dag)] = _wd.vd_functie_id;
      if (Constants.isEmpty(_wd.vd_pauze) === false) {
        _defaultWorkdays_breaks[parseInt(_wd.vd_dag)] = `${_wd.vd_pauze.substr(0,5)}`;
      }
    }

    this.setState({ infoExtra: infoExtraObj, defaultWorkdays: _defaultWorkdays, defaultWorkdays_breaks: _defaultWorkdays_breaks, defaultWorkdays_functions: _defaultWorkdays_functions });

  }

  navToWorkDays() {
    this.props.history.push('/work_week');
  }

  async resetToGeneralWorkDays() {

    this.setState({loading: true});

    const options = await APIGetOptions.Request();

    if (options[0].opties_verlof_maandag === '0' && options[0].opties_verlof_dinsdag === '0' && options[0].opties_verlof_woensdag === '0' && options[0].opties_verlof_donderdag === '0' && options[0].opties_verlof_vrijdag === '0' && options[0].opties_verlof_zaterdag === '0' && options[0].opties_verlof_zondag === '0') {
      this.setState({
        alertTitle: __('alert_error_title'),
        alertBody: (
            <div>
                {__('employees_alert_generalDays')}
            </div>
        ),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_setUp')],
        alertButtonAction: this.navToWorkDays.bind(this),
        showAlert: true,

        loading: false,
      });
      return;
    }

    await APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${options[0].opties_verlof_maandag}, info2_werkdag_dinsdag = ${options[0].opties_verlof_dinsdag}, info2_werkdag_woensdag = ${options[0].opties_verlof_woensdag}, info2_werkdag_donderdag = ${options[0].opties_verlof_donderdag}, info2_werkdag_vrijdag = ${options[0].opties_verlof_vrijdag}, info2_werkdag_zaterdag = ${options[0].opties_verlof_zaterdag}, info2_werkdag_zondag = ${options[0].opties_verlof_zondag}`, `WHERE info2_info_id = ${this.state.userData.info_id}`);

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({infoExtra: infoExtraObj}, async() => {

      await this.selectUser(this.state.userData.info_id, this.state.selectedName, true);
      await this.selectMenu(this.state.menu, true);

      this.setState({loading: false});

    });

  }

  async uploadFileContract() {

    this.setState({uploadError: ''});

    if (this.checkFileValidations(true) === false) {
      return;
    }

    this.setState({ reloading: true });

    const upload = await APIUploadContract.Request(this.state.fileContract);

    if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

      const uploaded = await APIADD.Request(`INSERT INTO DocumentC`, `VALUES (NULL, '${this.state.fileContract.name}', '${upload.loc}')`);

      if (uploaded === true) {

        this.setState({fileContract: null});
        this.uploadInputContract.value = null;

        const docs = await APIGET.Request(`SELECT *`, `FROM DocumentC`, null, null, null, `ORDER BY dc_naam`);

        this.setState({ documentsContract: docs });

      }

    }

    this.setState({ reloading: false });

  }

  async signFile(doc) {

    const signed = await APIEDIT.Request(`UPDATE Document`, `SET d_getekend = ${doc.d_getekend === '0' ? 1 : 0}`, `WHERE d_id = ${doc.d_id}`);

    if (signed === true) {

      const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.state.userData.info_id}`, null, `ORDER BY d_naam`);

      this.setState({ documents: docs });

    }

  }

  checkFileValidations(contract = false) {

    if (contract === true) {

      if (this.state.fileContract === null) {
        this.setState({ uploadError: __('error_file_empty') });
        return false;
      }

      if (this.state.fileContract.size > 25000000) {
        this.setState({ uploadError: __('error_file_max') });
        return false;
      }

    } else {

      if (this.state.file === null) {
        this.setState({ uploadError: __('error_file_empty') });
        return false;
      }

      if (this.state.file.size > 25000000) {
        this.setState({ uploadError: __('error_file_max') });
        return false;
      }

    }

    return true;

  }

  async uploadFile() {

    this.setState({uploadError: ''});

    if (this.checkFileValidations() === false) {
      return;
    }

    this.setState({ reloading: true });

    const upload = await APIUploadFile.Request(this.state.file);

    if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

      const uploaded = await APIADD.Request(`INSERT INTO Document`, `VALUES (NULL, ${this.state.userData.info_id}, '${this.state.file.name.replaceAll(/[^\d\w.]+/g, '_')}', '${upload.loc}', 0, NULL, NULL, NULL, NULL, NULL)`);

      if (uploaded === true) {

        this.setState({file: null});
        this.uploadInput.value = null;

        const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.state.userData.info_id}`, null, `ORDER BY d_naam`);

        this.setState({ documents: docs });

      }

    }

    this.setState({ reloading: false });

  }

  getDocumentBars() {

    let toReturn = [];

    for (const doc of this.state.documents) {

      if (doc.d_flatten === '0') {
        continue;
      }

      const date = Constants.isEmpty(doc.d_datum) === false ? Constants.stringToDate(doc.d_datum) : null;

      let signedFiles = [];
      if (doc.d_contract === '1' && Constants.isEmpty(doc.d_getekend_eigenaar) === false && doc.d_getekend_eigenaar !== '0') { //doc is contract which eneds signing
        const _user = this.state.accountsIter[doc.d_getekend_eigenaar];
        if (Constants.isEmpty(_user) === false) {
          const userFullName = `${_user.info_voornaam} ${(_user.info_tussenvoegsel !== null && _user.info_tussenvoegsel !== '') ? _user.info_tussenvoegsel + " " + _user.info_achternaam : _user.info_achternaam}`;
          signedFiles.push(
            <div style={{ fontSize: '0.7vw', marginTop: signedFiles.length > 0 ? '0.2vw' : 0 }}>
              <span>{userFullName}</span>
            </div>
          );
        }
        if (Constants.isEmpty(doc.d_getekend) === false && doc.d_getekend === '1') {
          const userFullName = `${this.state.userData.info_voornaam} ${(this.state.userData.info_tussenvoegsel !== null && this.state.userData.info_tussenvoegsel !== '') ? this.state.userData.info_tussenvoegsel + " " + this.state.userData.info_achternaam : this.state.userData.info_achternaam}`;
          signedFiles.push(
            <div style={{ fontSize: '0.7vw', marginTop: signedFiles.length > 0 ? '0.2vw' : 0 }}>
              <span>{userFullName}</span>
              {doc.d_contract === '1' && doc.d_getekend === '1' ?
                <span style={{fontSize: '0.6vw'}}> {date.toLocaleDateString()}</span>
              : null}
            </div>
          );
        }
      }
      // if (doc.d_contract === '1' && date !== null) { //doc is contract which eneds signing
      //     const signed = this.state.documentsSigned[doc.d_id];
      //     if (typeof signed !== 'undefined') {
      //         for (const s of signed) {
      //             signedFiles.push(
      //               <div style={{ fontSize: '0.7vw', marginTop: signedFiles.length > 0 ? '0.2vw' : 0 }}>
      //                 <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, fontSize: '0.6vw', marginRight: '0.4vw', cursor: 'pointer'}} onClick={() => this.deleteAlertFileSigned(doc, s)} />
      //                 <a className='defaultLinkO' href={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${s.do_loc}`} target='_blank' download={`${s.do_naam}`}>Ondertekend document {signedFiles.length + 1}</a>
      //               </div>
      //             );
      //         }
      //     }
      // }

      const notFlattenDoc = Constants.getArrayItem(this.state.documents, 'd_flat_id', doc.d_id);

      toReturn.push(

        <div key={doc.d_id} className='employees_doc_row'>

          <div className='employees_doc_col'>
            <i className='fal fa-file employees_doc_fileIcon' />
            <a className='defaultLink' style={{fontSize: '0.8vw'}} href={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${doc.d_loc}`} target='_blank' download={`${doc.d_naam}`}>{doc.d_naam}</a>
            {/* <span className='defaultLink' style={{fontSize: '1vw'}} onClick={() => this.downloadFile(`https://${Data.data.serverIP}/u/${doc.d_loc}`, doc.d_naam)}>{doc.d_naam}</span> */}
          </div>

          {/* <div className='employees_doc_col2'>

            {doc.d_contract === '1' && doc.d_getekend === '1' ?
              <span style={{fontSize: '0.6vw'}}> {date.toLocaleDateString()}</span>
            : null}

          </div> */}

          <div className='employees_doc_col3'>

            {signedFiles.length > 0 ?
              signedFiles
            : null}

          </div>

          <div className='employees_doc_col4'>
            {/* <span className='planner_publishMenu_button' onClick={() => window.open(`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${doc.d_loc}`, '_blank')}>Openen</span> */}
            {(Constants.isEmpty(doc.d_getekend_eigenaar) || doc.d_getekend_eigenaar === '0') && doc.d_contract === '1' && notFlattenDoc !== null ?
              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer'>
                    <span>{__('employees_tooltip_contractSign')}</span>
                  </div>
                )}
                position={'top'}
                theme='kodict'
                delay={300}
                hideDelay={0}
              >
                <span><i className='far fa-file-signature employees_contract_icon' onClick={() => this.openSignContractModalDocuments(notFlattenDoc)} /></span>
              </Tooltip>
            : null}
            {/* {doc.d_getekend === '1' && doc.d_contract === '1' ?
              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer'>
                    <span>Opnieuw contract laten ondertekenen</span>
                  </div>
                )}
                position={'top'}
                theme='kodict'
                delay={300}
                hideDelay={0}
              >
                <span><i className='far fa-bell-on employees_contract_icon' style={{ marginLeft: '0.6vw' }} onClick={() => this.notifyFile(doc)} /></span>
              </Tooltip>
            : null} */}
            {doc.d_getekend !== '1' && doc.d_contract === '1' ?
              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer'>
                    <span>{__('employees_tooltip_contractSignNotify')}</span>
                  </div>
                )}
                position={'top'}
                theme='kodict'
                delay={300}
                hideDelay={0}
              >
                <span><i className='far fa-bell-on employees_contract_icon' style={{ marginLeft: '0.6vw' }} onClick={() => this.notifyFile(doc)} /></span>
              </Tooltip>
            : null}
            <span><i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '0.6vw' }} onClick={() => this.deleteAlertFile(doc, notFlattenDoc)}/></span>
            {/* <span className='planner_publishMenu_deButton' style={{marginLeft: '0.6vw'}} onClick={() => this.deleteAlertFile(doc)}>Verwijderen</span> */}
          </div>

          {/* <input type="file" onChange={(event) => this.setState({file: event.target.files[0]})} accept={'.xls,.xlsx,.doc,.docx,.pdf,.jpg,.png'} />
          <button type="submit" onClick={() => this.uploadFile()}>Upload File</button> */}

        </div>

      );

    }

    return toReturn;

  }

  async deleteStartDateContract() {

    this.setState({ userData: { ...this.state.userData, contract_start: null }, showError: false, });

    await APIEDIT.Request(`UPDATE Contract`, `SET contract_start = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`);
    await this.reloadScreen();

  }

  async deleteEndDateContract() {

    this.setState({ userData: { ...this.state.userData, contract_eind: null }, showError: false, });

    if (this.state.hasContract === true) {
      await APIEDIT.Request(`UPDATE Contract`, `SET contract_eind = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`);
      await this.reloadScreen();
    }

  }

  async saveGeneralContract() {

    let promises = [];

    if (this.state.hasContract === false) {
      await APIADD.Request(`INSERT INTO Contract (contract_id, contract_info_id)`, `VALUES (NULL, ${this.state.userData.info_id})`);
    }

    if (this.userData.contract_start !== this.state.userData.contract_start) {
      if (Constants.isEmpty(this.state.userData.contract_start) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_start = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_start = '${this.state.userData.contract_start}'`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_eind !== this.state.userData.contract_eind) {
      if (Constants.isEmpty(this.state.userData.contract_eind) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_eind = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_eind = '${this.state.userData.contract_eind}'`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_uren !== this.state.userData.contract_uren) {
      if (Constants.isEmpty(this.state.userData.contract_uren) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_uren = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_uren = ${this.state.userData.contract_uren}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_bruto_uurloon !== this.state.userData.contract_bruto_uurloon) {
      if (Constants.isEmpty(this.state.userData.contract_bruto_uurloon) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_bruto_uurloon = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_bruto_uurloon = ${this.state.userData.contract_bruto_uurloon}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_vakantie_dagen !== this.state.userData.contract_vakantie_dagen) {
      if (Constants.isEmpty(this.state.userData.contract_vakantie_dagen) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_vakantie_dagen = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_vakantie_dagen = ${this.state.userData.contract_vakantie_dagen}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_vakantie_bovenwettelijk !== this.state.userData.contract_vakantie_bovenwettelijk) {
      if (Constants.isEmpty(this.state.userData.contract_vakantie_bovenwettelijk) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_vakantie_bovenwettelijk = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_vakantie_bovenwettelijk = ${this.state.userData.contract_vakantie_bovenwettelijk}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_type !== this.state.userData.contract_type) {
      if (Constants.isEmpty(this.state.userData.contract_type) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_type = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_type = ${this.state.userData.contract_type}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_min !== this.state.userData.contract_min) {
      if (Constants.isEmpty(this.state.userData.contract_min) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_min = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_min = ${this.state.userData.contract_min}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.contract_max !== this.state.userData.contract_max) {
      if (Constants.isEmpty(this.state.userData.contract_max) === true) {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_max = NULL`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Contract`, `SET contract_max = ${this.state.userData.contract_max}`, `WHERE contract_info_id = ${this.state.userData.info_id}`));
      }
    }

    if (this.userData.functie_id !== this.state.userData.functie_id) {
      if (Constants.isEmpty(this.state.userData.functie_id) === true) {
        promises.push(APIEDIT.Request(`UPDATE Info`, `SET info_functie_id = NULL`, `WHERE info_id = ${this.state.userData.info_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Info`, `SET info_functie_id = ${this.state.userData.functie_id}`, `WHERE info_id = ${this.state.userData.info_id}`));
      }
    }

    await Promise.all(promises);

    await this.reloadScreen();

  }

  async checkEmailValid() {

      if (this.state.userData.info_email !== '') {

          if (this.userData.info_email === this.state.userData.info_email) {
            return true;
          }

          const data = await APIGET.Request(`SELECT info_id`, `FROM Info`, null, `WHERE info_email = '${this.state.userData.info_email}'`, null, null);

          if (data.length > 0) {
              return false;
          }

      }

      return true;

  }

  changeEmployee(e) {

    let account = null;
    let userFullName = '';

    if (e.target.value !== 'all' && e.target.value !== 'team') {
      account = this.state.accountsIter[e.target.value];
    }

    if (Constants.isEmpty(account) === false) {
      userFullName = `${account.info_voornaam} ${(account.info_tussenvoegsel !== null && account.info_tussenvoegsel !== '') ? account.info_tussenvoegsel + " " + account.info_achternaam : account.info_achternaam}`;
    }

    const accID = e.target.value;

    // this.selectUser(e.target.value, userFullName);
    this.props.history.push({ pathname: `/employees/${accID}` });

  }

  changeContract(e) {
    this.setState({ selectedContract: e.target.value });
  }

  changeTeam(e) {
    this.selectUser(e.target.value, '');
  }

  getEmployeeSelects() {

    let toRender = [];

    for (const account of this.state.accounts) {

      if (Constants.isEmpty(account.account_id) || Constants.isEmpty(account.info_geboorte)) {
        continue;
      }

      const contract = Constants.getCurrentContract(this.state.contracts[account.info_id], new Date());
      // console.log('hell yeah: ' + JSON.stringify(contract))
      if (this.state.selectedContract === '0') {
        if (Constants.isEmpty(contract) === false && (contract.contract_type === '1' || contract.contract_type === '2')) {
          continue;
        }
      } else if (this.state.selectedContract === '1') {
        if ((Constants.isEmpty(contract) === false && contract.contract_type !== '1') || Constants.isEmpty(contract) === true) {
          continue;
        }
      } else if (this.state.selectedContract === '2') {
        if ((Constants.isEmpty(contract) === false && contract.contract_type !== '2') || Constants.isEmpty(contract) === true) {
          continue;
        }
      }

      const userFullName = `${account.info_voornaam} ${(account.info_tussenvoegsel !== null && account.info_tussenvoegsel !== '') ? account.info_tussenvoegsel + " " + account.info_achternaam : account.info_achternaam}`;

      toRender.push(<option value={account.info_id} selected={this.state.selected === account.info_id}>{userFullName}</option>);

    }

    return toRender;

  }

  openEmployeesModal() {

    this.setState({
      showEmployeesModal: true,
      modalData: null,
      modalMode: 0,
    });

  }

  closeEmployeesModal() {

    this.setState({
      showEmployeesModal: false,
      modalData: null,
      modalMode: 0,
    });

  }

  getWorkdayFunctionSelects(day) {

    let toRender = [];

    for (const func of this.state.functions) {

      toRender.push(<option value={func.functie_id} selected={this.state.defaultWorkdays_functions[day] === func.functie_id}>{func.functie_naam}</option>);

    }

    return toRender;

  }

  async changeWorkdayFunction(e, day) {

    let _defaultWorkdays_functions = this.state.defaultWorkdays_functions;

    const start = this.state.defaultWorkdays[day].substr(0, 5);
    const end = this.state.defaultWorkdays[day].substr(8, 13);

    const _function = e.target.value;

    await APIDELETE.Request(`DELETE FROM VasteDagen`, `WHERE vd_info_id = ${this.state.userData.info_id} AND vd_dag = ${day}`);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false || Constants.isEmpty(_function) === true) {

    } else {

      let _break = `'${this.state.defaultWorkdays_breaks[day]}'`;
      if (this.state.defaultWorkdays_breaks[day].length !== 5 || this.isValidForSave(this.state.defaultWorkdays_breaks[day]) === false) {
        _break = 'NULL';
      }

      APIADD.Request(`INSERT INTO VasteDagen`, `VALUES (NULL, ${this.state.userData.info_id}, ${_function}, ${day}, '${start}', '${end}', ${_break})`);

    }

    _defaultWorkdays_functions[day] = Constants.isEmpty(_function) ? null : _function;

    this.setState({ defaultWorkdays_functions: _defaultWorkdays_functions });

  }

  renderUserLeaveBalances() {

    let toRender = [];
    let count = 0;
    let balances = this.state.userLeaveBalances;

    for (const leave of balances) {

      toRender.push(

        <div key={leave.cvb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '100%', cursor: 'pointer', fontSize: '0.8vw'}} onClick={() => this.openUserLeaveBalanceModal(leave)}>

          <div className='hr_hoursBar_sub1'>
            <span>{leave.vb_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{Constants.digitsToLocale(parseFloat(leave.cvb_vb_factor), 9)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async reloadUserLeave() {

    this.setState({ reloading: true });

    const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    this.setState({ contractLeaveBalances: contractLeaveBalancesObj },
      async () => {

        await this.reloadScreen();
        this.setState({reloading: false});

      }
    );

  }

  openContractModal(mode, data) {

    // renew
    if (mode === 3) {

      let contract = data;

      if (Constants.isEmpty(contract.contract_eind) === true) {
        this.setState({
          alertTitle: __('alert_error_title'),
          alertBody: (<div>{__('employees_alert_error_extendContract')}</div>),
          alertButtons: 1,
          alertButtonText: [__('button_ok')],
          showAlert: true,
        });
        return;
      }

      const contractEnd = Constants.stringToDate(contract.contract_eind);
      const newStart = new Date(contractEnd.setDate(contractEnd.getDate() + 1));

      contract.contract_eind = null;
      contract.contract_begin = newStart;

    }

    this.setState({
      showContractModal: true,
      modalData: Constants.isEmpty(data) === true ? null : data,
      modalMode: mode,
    });

  }

  closeContractModal() {

    this.setState({
      showContractModal: false,
      modalData: null,
      modalMode: 0,
    });

  }

  async reloadContracts() {

    this.setState({loading: true});

    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    const currentContract = Constants.getCurrentContract(contractsObj[this.state.selected]);
    const userContracts = typeof contractsObj[this.state.selected] === 'undefined' ? [] : contractsObj[this.state.selected];

    this.setState({ infoExtra: infoExtraObj, contracts: contractsObj, currentContract: currentContract, userContracts: userContracts, contractLeaveBalances: contractLeaveBalancesObj }, async() => {

      await this.getLeaveData(this.state.selected);
      await this.getOverhoursData(this.state.selected);

      this.setState({ loading: false });

    });

  }

  deleteAlertContract(contract) {

    // <b>{Constants.stringToDate(contract.contract_begin).toLocaleDateString()}</b> - <b>{Constants.stringToDate(contract.contract_eind).toLocaleDateString()}</b>

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: (<div>{__('alert_delete')}</div>),
        alertButtons: 2,
        alertButtonAction: this.deleteContract.bind(this, contract),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });

  }

  async deleteContract(contract) {

    this.setState({ loading: true });

    await APIDELETE.Request(`DELETE FROM ContractN`, `WHERE contract_id = ${contract.contract_id}`);

    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    const contractLeaveBalances = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    const currentContract = Constants.getCurrentContract(contractsObj[this.state.selected]);
    const userContracts = typeof contractsObj[this.state.selected] === 'undefined' ? [] : contractsObj[this.state.selected];

    this.setState({ infoExtra: infoExtraObj, contracts: contractsObj, currentContract: currentContract, userContracts: userContracts, contractLeaveBalances: contractLeaveBalancesObj, loading: false, });

  }

  renderContracts() {

    let toRender = [];
    let count = 0;

    for (const c of this.state.userContracts) {

      let contractName = '-';
      if (c.contract_type === '2') {
        contractName = 'Vaste uren';
      } else if (c.contract_type === '1') {
        contractName = 'Min-max';
      } else if (c.contract_type === '0') {
        contractName = 'Flex uren';
      } 

      let overtime = <i className='fal fa-times' style={{color: Colors.color.redFilledIn}} />;
      if (c.contract_type === '2') {
        overtime = <i className='fal fa-check' style={{color: Colors.color.greenFilledIn}} />;
      } else if (c.contract_type === '1') {
        overtime = <i className='fal fa-check' style={{color: Colors.color.greenFilledIn}} />;
      }

      let vacationHours = 'Opbouwen';
      if (Constants.isTrue(c.contract_opbouw_verlof) === true) {

        if (c.contract_type === '2') {
          vacationHours = 'Contract';
        } else if (c.contract_type === '1') {
          vacationHours = `Contract & opbouwen`;
        }

      } else {

        vacationHours = '-';

      }

      let contractHours = '-';
      if (c.contract_type === '2') {
        contractHours = isNaN(parseFloat(c.contract_uren)) === true ? <i className='fas fa-exclamation-triangle' style={{color: Colors.color.redFilledIn}} /> : Constants.round(parseFloat(c.contract_uren), true);
      } else if (c.contract_type === '1') {
        contractHours = isNaN(parseFloat(c.contract_uren)) === true || isNaN(parseFloat(c.contract_max_uren)) === true ? <i className='fas fa-exclamation-triangle' style={{color: Colors.color.redFilledIn}} /> : `${Constants.round(parseFloat(c.contract_uren), true)} / ${Constants.round(parseFloat(c.contract_max_uren), true)}`;
      } else if (c.contract_type === '0') {
        contractHours = Constants.round(0, true);
      }

      let contractSalary = isNaN(parseFloat(c.contract_uurloon)) === true ? <i className='fas fa-exclamation-triangle' style={{color: Colors.color.redFilledIn}} /> : this.formatter.format(Constants.round(parseFloat(c.contract_uurloon)));
      if (c.contract_type === '2') {
        contractSalary = isNaN(parseFloat(c.contract_maandloon)) === true ? <i className='fas fa-exclamation-triangle' style={{color: Colors.color.redFilledIn}} /> : this.formatter.format(Constants.round(parseFloat(c.contract_maandloon)));
      }

      toRender.push(

        <div key={c.contract_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '100%', fontSize: '0.8vw'}}>

          <div className='hr_hoursBar_sub2'>
            <span>{contractName}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{Constants.isEmpty(c.contract_begin) === false ? Constants.stringToDate(c.contract_begin).toLocaleDateString() : ''}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{Constants.isEmpty(c.contract_eind) === false ? Constants.stringToDate(c.contract_eind).toLocaleDateString() : ''}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{overtime}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{vacationHours}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{contractHours}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{contractSalary}</span>
          </div>

          <div className='hr_hoursBar_sub4'>
            <Tooltip
              options
              html={(
                <div className='plannerN_tooltipContainer'>
                  <span>{__('employees_tooltip_changeContract')}</span>
                </div>
              )}
              position={'top'}
              theme='kodict'
              delay={300}
              hideDelay={0}
            >
              <span><i className='far fa-edit employees_contract_icon' onClick={() => this.openContractModal(1, c)} /></span>
            </Tooltip>
          </div>

          <div className='hr_hoursBar_sub4' style={{flex: 0.2}}>
            <Tooltip
              options
              html={(
                <div className='plannerN_tooltipContainer'>
                  <span>{__('employees_tooltip_extendContract')}</span>
                </div>
              )}
              position={'top'}
              theme='kodict'
              delay={300}
              hideDelay={0}
            >
              <span><i className='far fa-sync employees_contract_icon' onClick={() => this.openContractModal(3, c)} /></span>
            </Tooltip>
          </div>

          {/* <div className='hr_hoursBar_sub4' style={{flex: 0.2}}>
            <Tooltip
              options
              html={(
                <div className='plannerN_tooltipContainer'>
                  <span>Contract wijzigen</span>
                </div>
              )}
              position={'top'}
              theme='kodict'
              delay={300}
              hideDelay={0}
            >
              <span><i className='far fa-copy employees_contract_icon' onClick={() => this.openContractModal(2, c)} /></span>
            </Tooltip>
          </div> */}

          {this.state.userContracts.length > 1 ?
            <div className='hr_hoursBar_sub4' style={{flex: 0.2}}>
              <span><i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer'}} onClick={() => this.deleteAlertContract(c)} /></span>
            </div>
          : null}

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async leave_getRequestsData(userID) {

    const userRequests = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, 
      `FROM Vakantie`,
      `LEFT JOIN Info ON vakantie_info_id = info_id`,
      `WHERE (YEAR(vakantie_begin) = ${this.state.leave_chosenYear} OR YEAR(vakantie_eind) = ${this.state.leave_chosenYear}) AND vakantie_info_id = ${userID}`,
      null,
      `ORDER BY vakantie_begin DESC, vakantie_eind DESC`
    );

    this.setState({ leave_userRequests: userRequests });

  }

  deleteAlertLeaveCorrection(correction) {

    // <b>{Constants.stringToDate(contract.contract_begin).toLocaleDateString()}</b> - <b>{Constants.stringToDate(contract.contract_eind).toLocaleDateString()}</b>

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: (<div>{__('alert_delete')}</div>),
        alertButtons: 2,
        alertButtonAction: this.deleteLeaveCorrection.bind(this, correction),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });

  }

  async deleteLeaveCorrection(correction) {

    this.setState({ loading: true });

    await APIDELETE.Request(`DELETE FROM CorrectieVerlof`, `WHERE cv_id = ${correction.cv_id}`);

    await this.reloadBalances();

  }

  leave_renderCorrections() {

    let toReturn = [];
    let count = 0;

    if (Constants.isEmpty(this.state.leave_corrections) === false && Constants.isEmpty(this.state.leave_corrections[this.state.leave_chosenYear.toString()]) === false) {

      const userOverhoursYear = this.state.leave_corrections[this.state.leave_chosenYear.toString()].sep;

      if (Constants.isEmpty(userOverhoursYear) === false) {

        for (const overhour of userOverhoursYear) {

          const date = Constants.stringToDate(overhour.cv_datum);

          const hours = parseFloat(overhour.cv_aantal);
          const hoursString = `${hours < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(hours) * 60 * 60 * 1000)}`;

          const userFullName = `${overhour.info_voornaam} ${(overhour.info_tussenvoegsel !== null && overhour.info_tussenvoegsel !== '') ? overhour.info_tussenvoegsel + " " + overhour.info_achternaam : overhour.info_achternaam}`;

          toReturn.push(

            <div className='hr_hoursBar' style={{width: '100%', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              <div className='hr_hoursBar_sub1'>
                <span>{overhour.vb_naam}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{date.toLocaleDateString()}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{userFullName}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                {Constants.isEmpty(overhour.cv_notitie) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{overhour.cv_notitie}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                  </Tooltip>
                : 
                  <span>&nbsp;</span>
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                {Constants.isTrue(overhour.cv_betaling) ?
                  <i className='fal fa-check' style={{color: Colors.color.greenFilledIn}} />
                :
                  <i className='fal fa-times' style={{color: Colors.color.redFilledIn}} />
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>{hoursString}</span>
              </div>

              <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.deleteAlertLeaveCorrection(overhour)}>
                <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn}} />
              </div>

            </div>

          );

          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

      }

    }

    return toReturn;

  }

  leave_renderUserRequests(open) {

    let toReturn = [];
    let count = 0;
    let count2 = 0;

    for (const data of this.state.leave_userRequests) {

      const startDate = Constants.stringToDate(data.vakantie_begin);
      const endDate = Constants.stringToDate(data.vakantie_eind);

      const sameDay = startDate.getTime() === endDate.getTime();

      let taken = 0;
      // if (Constants.isEmpty(this.state.absAmount[data.vakantie_info_id]) === false) {
      //   const item = Constants.getArrayItem(this.state.absAmount[data.vakantie_info_id], 'vs_vakantie_id', data.vakantie_id);
      //   if (Constants.isEmpty(item) === false) {
      //     taken = parseFloat((Math.ceil(item.vs_aantal * 100) / 100));
      //   }
      // }

      const startWeek = Constants.getWeekNumber2(startDate);
      const endWeek = Constants.getWeekNumber2(endDate);

      if (data.vakantie_geaccepteerd === '1') {

        for (let i = startWeek; i <= endWeek; i++) {

          let totalWorkedHours = 0;

          const monday = Constants.getMonday(i, parseInt(this.state.leave_chosenYear));
          const contract = Constants.getCurrentContract(this.state.contracts[this.state.userData.info_id], monday) === null ? null : Constants.getCurrentContract(this.state.contracts[this.state.userData.info_id], monday);

          if (contract !== null && Constants.isEmpty(this.state.hoursWorked[this.state.leave_chosenYear.toString()]) === false) {

              let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

              // SET NEW WORKDAYHOURS LIKE CONTRACT GEEN VASTE DAGEN
              workDayHours = Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

              const yearData = Constants.getArrayItem(this.state.hoursWorked[this.state.leave_chosenYear.toString()], 'week', i);

              if (Constants.isEmpty(yearData) === false) {

                  const weekData = yearData.weekData;

                  for (const dayData of weekData) { // days in week array

                      const start = Constants.stringToDateTime(dayData.uren_begindatum, dayData.uren_begin);
                      const end = Constants.stringToDateTime(dayData.uren_einddatum, dayData.uren_eind);
                      const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(dayData.uren_pauze);
    
                      // SET NEW WORKDAYHOURS LIKE CONTRACT
                      if (Constants.isEmpty(contract) === false && Constants.isTrue(contract.contract_vaste_dagen)) {
                          workDayHours = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                          : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                          : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                          : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                          : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                          : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                          : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                      }

                      totalWorkedHours += (workDayHours * 1000 * 60 * 60);
    
                  }

              }

          }

          const calcAbsAmount = this.calcAbsAmount(i, this.state.leave_chosenYear.toString(), totalWorkedHours);
          taken += calcAbsAmount.totalAbsence;

        }

      }

      if (open === true) {

        if (data.vakantie_geaccepteerd === '0') {

          toReturn.push(

            <div className='hr_hoursBar' style={{width: '100%', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

              <div className='hr_hoursBar_sub1'>
                <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.toLocaleDateString()}{sameDay === false ? ' - ' : ' '}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.toLocaleDateString()}</span> : null}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>&nbsp;</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>&nbsp;</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                {Constants.isEmpty(data.vakantie_opmerking) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{data.vakantie_opmerking}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                  </Tooltip>
                :
                    <span>&nbsp;</span>
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                &nbsp;
              </div>

              <div className='hr_hoursBar_sub3'>
                &nbsp;
              </div>

              <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: data.vakantie_id })}>
                <i className='far fa-arrow-right' />
              </div>

            </div>

          );

          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

      } else {

        if (data.vakantie_geaccepteerd !== '0') {

          toReturn.push(

            <div className='hr_hoursBar' style={{width: '100%', backgroundColor: count2 === 1 ? '#F9FBFC' : null}}>

              <div className='hr_hoursBar_sub1'>
                <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.toLocaleDateString()}{sameDay === false ? ' - ' : ' '}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.toLocaleDateString()}</span> : null}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>&nbsp;</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                <span>&nbsp;</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                {Constants.isEmpty(data.vakantie_opmerking) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{data.vakantie_opmerking}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                  </Tooltip>
                :
                    <span>&nbsp;</span>
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                {data.vakantie_geaccepteerd === '1' ?
                  <i className='far fa-check' style={{color: Colors.color.greenFilledIn}} />
                :
                  <i className='far fa-times' style={{color: Colors.color.redFilledIn}} />
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                {((Constants.getWeekNumber2(startDate) >= Constants.getWeekNumber() && startDate.getFullYear() === new Date().getFullYear()) || startDate.getFullYear() > new Date().getFullYear()) && data.vakantie_geaccepteerd === '1' ?
                  <span>{__('misc_ntb')}</span>
                :
                  <span>{Constants.msToTime2(taken)}</span>
                }
              </div>

              <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: data.vakantie_id })}>
                <i className='far fa-arrow-right' />
              </div>

            </div>

          );

          if (count2 === 0) {
            count2++;
          } else {
            count2--;
          }

        }

      }

    }

    return toReturn;

  }

  leave_selectYear(event) {

    this.setState({ leave_chosenYear: parseInt(event.target.value) }, async () => {
      await this.reloadBalances();
    });

  }

  leave_yearOptions() {

    let toReturn = [];

    if (Constants.isEmpty(this.state.leave_userLeaveBalances) === false) {

      for (const yearKey in this.state.leave_userLeaveBalances.sep) {

        toReturn.push(
          <option value={yearKey} selected={this.state.leave_chosenYear === parseInt(yearKey)}>{yearKey}</option>
        );

      }

    }

    return toReturn;

  }

  leave_renderUserLeaveBalances() {

    let toReturn = [];
    let count = 0;

    const userLeaveBalancesYear = this.state.leave_userLeaveBalances.sep[this.state.leave_chosenYear.toString()];

    if (Constants.isEmpty(this.state.leave_userLeaveBalances) === false && Constants.isEmpty(userLeaveBalancesYear) === false) {

      for (const lb of userLeaveBalancesYear) {

        // const totalLB = Constants.getArrayItem(this.state.leave_userLeaveBalances.totals, 'vb_id', lb.vb_id);

        let startBalance = 0;
        const prevYearLB = this.state.leave_userLeaveBalances.sep[(this.state.leave_chosenYear - 1).toString()];
        if (Constants.isEmpty(prevYearLB) === false) {
          const prevYearBalance = Constants.getArrayItem(prevYearLB, 'vb_id', lb.vb_id);
          startBalance = prevYearBalance.total2;
        }

        // to MS and then to timestring
        startBalance = isNaN(startBalance) ? Constants.msToTime2(0) : startBalance < 0 ? '-' + Constants.msToTime2(Math.abs(startBalance)) : Constants.msToTime2(startBalance);
        const balanceNow = isNaN(lb.balance) ? Constants.msToTime2(0) : lb.balance < 0 ? '-' + Constants.msToTime2(Math.abs(lb.balance)) : Constants.msToTime2(lb.balance);
        const substract = isNaN(lb.substract) ? Constants.msToTime2(0) : Constants.msToTime2(lb.substract);
        const total = isNaN(lb.total2) ? Constants.msToTime2(0) : lb.total2 < 0 ? '-' + Constants.msToTime2(Math.abs(lb.total2)) : Constants.msToTime2(lb.total2);

        toReturn.push(

          <div className='hr_hoursBar' style={{width: '100%', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{lb.vb_naam}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{startBalance}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{balanceNow}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{substract}</span>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span style={{color: total.includes('-') ? Colors.color.redFilledIn : null}}><b>{total}</b></span>
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toReturn;

  }

  // overhours_renderUserOverhours() {

  //   let toReturn = [];

  //   let yearOverhoursData = {};

  //   if (Constants.isEmpty(this.state.hoursWorked) === false) {

  //     for (const yearKey in this.state.hoursWorked) {

  //       if (Constants.isEmpty(yearOverhoursData[yearKey])) {
  //         yearOverhoursData[yearKey] = 0;
  //       }

  //       const userOverhoursYear = this.state.hoursWorked[yearKey];

  //       if (Constants.isEmpty(userOverhoursYear) === false) {

  //         for (const yearData of userOverhoursYear) { // weeks in year array

  //           const week = yearData.week;
  //           const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);

  //           const calcAbsAmount = this.calcAbsAmount(yearData.week, yearKey);
  //           const calcSickHours = this.calcSickHours(yearData.week, yearKey);

  //           const showDetails = Constants.arrayContains(this.state.showWeekDetails, yearData.week);

  //           let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
  //           let totalHours = 0;
  //           let realTotalHours = 0;
  //           let totalWorkedHours = 0;
  //           let currentContract = null;
  //           let totalAbsence = calcAbsAmount.totalAbsence;
  //           let totalAbsent = calcSickHours.totalAbsents;
  //           let correction = 0;

  //           // ABS DAY DATA
  //           for (const abs of calcAbsAmount.absence) {
  //             daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
  //           } 

  //           // SICK DAY DATA
  //           for (const sick of calcSickHours.absents) {
  //             daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
  //           } 

  //           // ARRAY OF DAYS
  //           const weekData = yearData.weekData;

  //           // HOURS DAY DATA
  //           for (const dayData of weekData) { // days in week array

  //             const date = Constants.stringToDate(dayData.uren_begindatum);

  //             // if (date > _now) {
  //             //   continue;
  //             // }

  //             let contract = Constants.getCurrentContract(this.state.contracts[dayData.info_id], date);
  //             contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

  //             if (contract !== null) {
  //               currentContract = contract;
  //             } else {
  //               continue;
  //             }

  //             const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

  //             daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
  //             daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

  //             totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
  //             realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
  //             totalWorkedHours += calcOverhours.workedThisDay;

  //           }

  //           if (currentContract !== null) {

  //             // show the correction incase of confusion
  //             correction += (totalHours - realTotalHours);

  //             // fix minus hours
  //             if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
  //               if (currentContract.contract_type === '2') {
  //                 const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
  //                 if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
  //                   const oldTotalHours = totalHours;
  //                   totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
  //                   correction = totalHours - oldTotalHours;
  //                 }
  //               } else if (currentContract.contract_type === '1') {
  //                 const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
  //                 const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
  //                 if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
  //                   const oldTotalHours = totalHours;
  //                   totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
  //                   correction = totalHours - oldTotalHours;
  //                 } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
  //                   const oldTotalHours = totalHours;
  //                   totalHours = 0;
  //                   correction = totalHours - oldTotalHours;
  //                 }
  //               }
  //             }

  //             // fix correction negative
  //             const correctionNegative = correction < 0;
  //             correction = Math.abs(correction);

  //             yearOverhoursData[yearKey] += totalHours;

  //           }

  //         }

  //       }

  //     }

  //   }

  //   let startBalance = 0;
  //   let balanceNow = 0;
  //   let balanceEnd = 0;

  //   for (const yearKey in yearOverhoursData) {
  //     const overhoursAmount = yearOverhoursData[yearKey];
  //     if (parseInt(yearKey) <= this.state.overhours_chosenYear) {
  //       balanceEnd += overhoursAmount;
  //     }
  //     if (parseInt(yearKey) === this.state.overhours_chosenYear) {
  //       balanceNow += overhoursAmount;
  //     }
  //     if (parseInt(yearKey) < this.state.overhours_chosenYear) {
  //       startBalance += overhoursAmount;
  //     }
  //   }

  //   // CORRECTIONS
  //   for (const yearKey in this.state.overhours_corrections) {
  //     const overhoursAmount = (this.state.overhours_corrections[yearKey].total * 60 * 60 * 1000);
  //     if (parseInt(yearKey) <= this.state.overhours_chosenYear) {
  //       balanceEnd += overhoursAmount;
  //     }
  //     if (parseInt(yearKey) === this.state.overhours_chosenYear) {
  //       balanceNow += overhoursAmount;
  //     }
  //     if (parseInt(yearKey) < this.state.overhours_chosenYear) {
  //       startBalance += overhoursAmount;
  //     }
  //   }

  //   startBalance = isNaN(startBalance) || Constants.msToTime2(startBalance) === '00:00' ? Constants.msToTime2(0) : startBalance < 0 ? '-' + Constants.msToTime2(Math.abs(startBalance)) : startBalance !== 0 ? '+' + Constants.msToTime2(startBalance) : Constants.msToTime2(0);
  //   balanceNow = isNaN(balanceNow) || Constants.msToTime2(balanceNow) === '00:00' ? Constants.msToTime2(0) : balanceNow < 0 ? '-' + Constants.msToTime2(Math.abs(balanceNow)) : balanceNow !== 0 ? '+' + Constants.msToTime2(balanceNow) : Constants.msToTime2(0);
  //   balanceEnd = isNaN(balanceEnd) || Constants.msToTime2(balanceEnd) === '00:00' ? Constants.msToTime2(0) : balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(balanceEnd)) : balanceEnd !== 0 ? '+' + Constants.msToTime2(balanceEnd) : Constants.msToTime2(0);

  //   toReturn.push(

  //     <div className='hr_hoursBar' style={{width: '100%'}}>

  //       <div className='hr_hoursBar_sub1' style={{justifyContent: 'center', fontSize: '1vw'}}>
  //         <span style={{color: startBalance.includes('-') ? Colors.color.greenFilledIn : startBalance.includes('+') ? Colors.color.redFilledIn : null}}>{startBalance}</span>
  //       </div>

  //       <div className='hr_hoursBar_sub1' style={{justifyContent: 'center', fontSize: '1vw'}}>
  //         <span style={{color: balanceNow.includes('-') ? Colors.color.greenFilledIn : balanceNow.includes('+') ? Colors.color.redFilledIn : null}}>{balanceNow}</span>
  //       </div>

  //       <div className='hr_hoursBar_sub1' style={{justifyContent: 'center', fontSize: '1vw'}}>
  //         <span style={{color: balanceEnd.includes('-') ? Colors.color.greenFilledIn : balanceEnd.includes('+') ? Colors.color.redFilledIn : null}}><b>{balanceEnd}</b></span>
  //       </div>

  //     </div>

  //   );

  //   return toReturn;

  // }

  overhours_renderUserOverhours() {

    let toReturn = [];

    let yearOverhoursData = {};

    if (Constants.isEmpty(this.state.hoursWorked) === false) {

      for (const yearKey in this.state.hoursWorked) {

        if (Constants.isEmpty(yearOverhoursData[yearKey])) {
          yearOverhoursData[yearKey] = 0;
        }

        const userOverhoursYear = this.state.hoursWorked[yearKey];

        if (Constants.isEmpty(userOverhoursYear) === false) {

          const startWeek = userOverhoursYear[userOverhoursYear.length - 1].week;
          let endWeek = Constants.getWeekNumber2(new Date());

          if (new Date().getFullYear() > parseInt(yearKey)) {
            endWeek = userOverhoursYear[0].week;
          }

          // loop from start to current week
          for (let i = endWeek; i >= startWeek; i--) {

            const week = i;
            const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
            const monday = Constants.getMonday(i, parseInt(yearKey));

            const showDetails = Constants.arrayContains(this.state.showWeekDetails, i);

            let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
            let totalHours = 0;
            let realTotalHours = 0;
            let totalWorkedHours = 0;
            let totalWorkedHoursAbsence = 0;
            let currentContract = null;
            let correction = 0;

            // CONTRACT
            let contract = Constants.getCurrentContract(this.state.contracts[this.state.userData.info_id], monday);
            contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

            if (contract !== null) {
              currentContract = contract;
            } else {
              continue;
            }

            //
            //
            // yearData
            const yearData = Constants.getArrayItem(userOverhoursYear, 'week', i);

            // calc hours and overhours and correction
            if (Constants.isEmpty(yearData) === false) {

              // ARRAY OF DAYS
              const weekData = yearData.weekData;

              // HOURS DAY DATA
              for (const dayData of weekData) { // days in week array

                const date = Constants.stringToDate(dayData.uren_begindatum);

                // if (date > _now) {
                //   continue;
                // }

                const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

                daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
                daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

                totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
                realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
                totalWorkedHours += calcOverhours.workedThisDay;
                totalWorkedHoursAbsence += calcOverhours.workedThisDayAbsence;

              }

            }

            // CALC ABSENT AND ABSENCES
            const calcAbsAmount = this.calcAbsAmount(i, yearKey, totalWorkedHoursAbsence);
            const calcSickHours = this.calcSickHours(i, yearKey);

            let totalAbsence = calcAbsAmount.totalAbsence;
            let totalAbsent = calcSickHours.totalAbsents;

            // ABS DAY DATA
            for (const abs of calcAbsAmount.absence) {
              daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
            } 

            // SICK DAY DATA
            for (const sick of calcSickHours.absents) {
              daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
            } 

            if (currentContract !== null) {

              // show the correction incase of confusion
              correction += (totalHours - realTotalHours);

              // fix minus hours
              if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
                if (currentContract.contract_type === '2') {
                  const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                  if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - realTotalHours;
                  }
                } else if (currentContract.contract_type === '1') {
                  const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                  const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
                  if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = 0;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_max_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_max_hours;
                    correction = totalHours - realTotalHours;
                  }
                }
              }

              // fix correction negative
              const correctionNegative = correction < 0;
              correction = Math.abs(correction);

              yearOverhoursData[yearKey] += totalHours;

            }

          }

        }

      }

    }

    let startBalance = 0;
    let balanceNow = 0;
    let balanceEnd = 0;

    for (const yearKey in yearOverhoursData) {
      const overhoursAmount = yearOverhoursData[yearKey];
      if (parseInt(yearKey) <= this.state.overhours_chosenYear) {
        balanceEnd += overhoursAmount;
      }
      if (parseInt(yearKey) === this.state.overhours_chosenYear) {
        balanceNow += overhoursAmount;
      }
      if (parseInt(yearKey) < this.state.overhours_chosenYear) {
        startBalance += overhoursAmount;
      }
    }

    // CORRECTIONS
    for (const yearKey in this.state.overhours_corrections) {
      const overhoursAmount = (this.state.overhours_corrections[yearKey].total * 60 * 60 * 1000);
      if (parseInt(yearKey) <= this.state.overhours_chosenYear) {
        balanceEnd += overhoursAmount;
      }
      if (parseInt(yearKey) === this.state.overhours_chosenYear) {
        balanceNow += overhoursAmount;
      }
      if (parseInt(yearKey) < this.state.overhours_chosenYear) {
        startBalance += overhoursAmount;
      }
    }

    startBalance = isNaN(startBalance) || Constants.msToTime2(startBalance) === '00:00' ? Constants.msToTime2(0) : startBalance < 0 ? '-' + Constants.msToTime2(Math.abs(startBalance)) : startBalance !== 0 ? '+' + Constants.msToTime2(startBalance) : Constants.msToTime2(0);
    balanceNow = isNaN(balanceNow) || Constants.msToTime2(balanceNow) === '00:00' ? Constants.msToTime2(0) : balanceNow < 0 ? '-' + Constants.msToTime2(Math.abs(balanceNow)) : balanceNow !== 0 ? '+' + Constants.msToTime2(balanceNow) : Constants.msToTime2(0);
    balanceEnd = isNaN(balanceEnd) || Constants.msToTime2(balanceEnd) === '00:00' ? Constants.msToTime2(0) : balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(balanceEnd)) : balanceEnd !== 0 ? '+' + Constants.msToTime2(balanceEnd) : Constants.msToTime2(0);

    toReturn.push(

      <div className='hr_hoursBar' style={{width: '100%'}}>

        <div className='hr_hoursBar_sub1' style={{justifyContent: 'center', fontSize: '1vw'}}>
          <span style={{color: startBalance.includes('-') ? Colors.color.greenFilledIn : startBalance.includes('+') ? Colors.color.redFilledIn : null}}>{startBalance}</span>
        </div>

        <div className='hr_hoursBar_sub1' style={{justifyContent: 'center', fontSize: '1vw'}}>
          <span style={{color: balanceNow.includes('-') ? Colors.color.greenFilledIn : balanceNow.includes('+') ? Colors.color.redFilledIn : null}}>{balanceNow}</span>
        </div>

        <div className='hr_hoursBar_sub1' style={{justifyContent: 'center', fontSize: '1vw'}}>
          <span style={{color: balanceEnd.includes('-') ? Colors.color.greenFilledIn : balanceEnd.includes('+') ? Colors.color.redFilledIn : null}}><b>{balanceEnd}</b></span>
        </div>

      </div>

    );

    return toReturn;

  }

  // overhours_renderUserOverhoursSep() {

  //   let toReturn = [];
  //   let count = 0;

  //   if (Constants.isEmpty(this.state.hoursWorked) === false && Constants.isEmpty(this.state.hoursWorked[this.state.overhours_chosenYear.toString()]) === false) {

  //     const userOverhoursYear = this.state.hoursWorked[this.state.overhours_chosenYear.toString()];

  //     if (Constants.isEmpty(userOverhoursYear) === false) {

  //       for (const yearData of userOverhoursYear) { // weeks in year array

  //         const week = yearData.week;
  //         const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);

  //         const calcAbsAmount = this.calcAbsAmount(yearData.week, this.state.overhours_chosenYear.toString());
  //         const calcSickHours = this.calcSickHours(yearData.week, this.state.overhours_chosenYear.toString());

  //         const showDetails = Constants.arrayContains(this.state.showWeekDetails, yearData.week);

  //         let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
  //         let totalHours = 0;
  //         let realTotalHours = 0;
  //         let totalWorkedHours = 0;
  //         let currentContract = null;
  //         let totalAbsence = calcAbsAmount.totalAbsence;
  //         let totalAbsent = calcSickHours.totalAbsents;
  //         let correction = 0;

  //         // ABS DAY DATA
  //         for (const abs of calcAbsAmount.absence) {
  //           daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
  //         } 

  //         // SICK DAY DATA
  //         for (const sick of calcSickHours.absents) {
  //           daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
  //         } 

  //         // ARRAY OF DAYS
  //         const weekData = yearData.weekData;

  //         // HOURS DAY DATA
  //         for (const dayData of weekData) { // days in week array

  //           const date = Constants.stringToDate(dayData.uren_begindatum);

  //           // if (date > _now) {
  //           //   continue;
  //           // }

  //           let contract = Constants.getCurrentContract(this.state.contracts[dayData.info_id], date);
  //           contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

  //           if (contract !== null) {
  //             currentContract = contract;
  //           } else {
  //             continue;
  //           }

  //           const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

  //           daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
  //           daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

  //           totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
  //           realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
  //           totalWorkedHours += calcOverhours.workedThisDay;

  //         }

  //         if (currentContract !== null) {

  //           // show the correction incase of confusion
  //           correction += (totalHours - realTotalHours);

  //           // fix minus hours
  //           if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
  //             if (currentContract.contract_type === '2') {
  //               const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
  //               if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
  //                 const oldTotalHours = totalHours;
  //                 totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
  //                 correction = totalHours - oldTotalHours;
  //               }
  //             } else if (currentContract.contract_type === '1') {
  //               const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
  //               const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
  //               if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
  //                 const oldTotalHours = totalHours;
  //                 totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
  //                 correction = totalHours - oldTotalHours;
  //               } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
  //                 const oldTotalHours = totalHours;
  //                 totalHours = 0;
  //                 correction = totalHours - oldTotalHours;
  //               }
  //             }
  //           }

  //           // fix correction negative
  //           const correctionNegative = correction < 0;
  //           correction = Math.abs(correction);

  //           // total hours
  //           const hoursString = `${totalHours < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(totalHours))}`;

  //           toReturn.push(

  //             <div className='hr_hoursBar' style={{width: '98%', backgroundColor: count === 1 ? '#F9FBFC' : null, paddingLeft: '1%', paddingRight: '1%'}}>

  //               <div className='hr_hoursBar_sub1' style={{cursor: 'pointer'}} onClick={() => this.toggleWeekDetailsOverhours(yearData.week)}>
  //                 {showDetails ?
  //                   <i className='fas fa-chevron-up' style={{ marginRight: '5%' }} />
  //                 :
  //                   <i className='fas fa-chevron-down' style={{ marginRight: '5%' }} />
  //                 }
  //                 <b>Week {yearData.week}</b>
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 {currentContract !== null ?
  //                   currentContract.contract_type === '1' ?
  //                     <b>{Constants.msToTime2(currentContract.contract_uren * 60 * 60 * 1000)} | {Constants.msToTime2(currentContract.contract_max_uren * 60 * 60 * 1000)}</b>
  //                   :
  //                     <b>{Constants.msToTime2(currentContract.contract_uren * 60 * 60 * 1000)}</b>
  //                 :
  //                   <span>&nbsp;</span>
  //                 }
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 {totalWorkedHours !== 0 ?
  //                   <b>{Constants.msToTime2(totalWorkedHours)}</b>
  //                 : 
  //                   <span>&nbsp;</span>
  //                 }
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 {totalAbsence !== 0 ?
  //                   <b style={{color: Colors.color.pastBorderColor}}>{Constants.msToTime2(totalAbsence)}</b>
  //                 : 
  //                   <span>&nbsp;</span>
  //                 }
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 {totalAbsent !== 0 ?
  //                   <b style={{color: Colors.color.pastBorderColor}}>{Constants.msToTime2(totalAbsent)}</b>
  //                 : 
  //                   <span>&nbsp;</span>
  //                 }
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 <span>&nbsp;</span>
  //               </div>

  //               <div className='hr_hoursBar_sub3'>
  //                 {totalHours !== 0 ?
  //                   <b style={{color: hoursString.includes('-') ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{hoursString}</b>
  //                 : 
  //                   <span>&nbsp;</span>
  //                 }
  //               </div>

  //               {/* <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getMonday(_week.week, this.state.overhours_chosenYear) })}>
  //                 <i className='far fa-arrow-right' />
  //               </div> */}

  //             </div>

  //           );

  //           if (showDetails) {
  //             toReturn.push(this.overhours_renderWeeks(daysTotal, yearData.week, currentContract, correction, correctionNegative, realTotalHours));
  //           }

  //           if (count === 0) {
  //             count++;
  //           } else {
  //             count--;
  //           }

  //         }

  //       }

  //     }

  //   }

  //   return toReturn;

  // }

  overhours_renderUserOverhoursSep() {

    let toReturn = [];
    let count = 0;

    if (Constants.isEmpty(this.state.hoursWorked) === false && Constants.isEmpty(this.state.hoursWorked[this.state.overhours_chosenYear.toString()]) === false) {

      const userOverhoursYear = this.state.hoursWorked[this.state.overhours_chosenYear.toString()];

      if (Constants.isEmpty(userOverhoursYear) === false) {

        const startWeek = userOverhoursYear[userOverhoursYear.length - 1].week;
        let endWeek = Constants.getWeekNumber2(new Date());

        if (new Date().getFullYear() > parseInt(this.state.overhours_chosenYear.toString())) {
          endWeek = userOverhoursYear[0].week;
        }

        // loop from start to current week
        for (let i = endWeek; i >= startWeek; i--) {

          const week = i;
          const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
          const monday = Constants.getMonday(i, this.state.overhours_chosenYear);

          const showDetails = Constants.arrayContains(this.state.showWeekDetails, i);

          let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
          let totalHours = 0;
          let realTotalHours = 0;
          let totalWorkedHours = 0;
          let totalWorkedHoursAbsence = 0;
          let currentContract = null;
          let correction = 0;

          // CONTRACT
          let contract = Constants.getCurrentContract(this.state.contracts[this.state.userData.info_id], monday);
          contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

          if (contract !== null) {
            currentContract = contract;
          } else {
            continue;
          }

          //
          //
          // yearData
          const yearData = Constants.getArrayItem(userOverhoursYear, 'week', i);

          // calc hours and overhours and correction
          if (Constants.isEmpty(yearData) === false) {

            // ARRAY OF DAYS
            const weekData = yearData.weekData;

            // HOURS DAY DATA
            for (const dayData of weekData) { // days in week array

              const date = Constants.stringToDate(dayData.uren_begindatum);

              // if (date > _now) {
              //   continue;
              // }

              const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

              daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
              daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

              totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
              realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
              totalWorkedHours += calcOverhours.workedThisDay;
              totalWorkedHoursAbsence += calcOverhours.workedThisDayAbsence;

            }

          }
          
          // CALC ABSENT AND ABSENCES
          const calcAbsAmount = this.calcAbsAmount(i, this.state.overhours_chosenYear.toString(), totalWorkedHoursAbsence);
          const calcSickHours = this.calcSickHours(i, this.state.overhours_chosenYear.toString());

          let totalAbsence = calcAbsAmount.totalAbsence;
          let totalAbsent = calcSickHours.totalAbsents;

          // ABS DAY DATA
          for (const abs of calcAbsAmount.absence) {
            daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
          } 

          // SICK DAY DATA
          for (const sick of calcSickHours.absents) {
            daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
          } 

          // HAS CONTRACT, GO ON
          if (currentContract !== null) {

            // show the correction incase of confusion
            correction += (totalHours - realTotalHours);

            // fix minus hours
            if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
              if (currentContract.contract_type === '2') {
                const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                  const oldTotalHours = totalHours;
                  totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                  correction = totalHours - oldTotalHours;
                } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_hours) {
                  const oldTotalHours = totalHours;
                  totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                  correction = totalHours - realTotalHours;
                }
              } else if (currentContract.contract_type === '1') {
                const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
                if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                  const oldTotalHours = totalHours;
                  totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                  correction = totalHours - oldTotalHours;
                } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
                  const oldTotalHours = totalHours;
                  totalHours = 0;
                  correction = totalHours - oldTotalHours;
                } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_max_hours) {
                  const oldTotalHours = totalHours;
                  totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_max_hours;
                  correction = totalHours - realTotalHours;
                }
              }
            }

            // fix correction negative
            const correctionNegative = correction < 0;
            correction = Math.abs(correction);

            // total hours
            const hoursString = `${totalHours < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(totalHours))}`;

            toReturn.push(

              <div className='hr_hoursBar' style={{width: '98%', backgroundColor: count === 1 ? '#F9FBFC' : null, paddingLeft: '1%', paddingRight: '1%'}}>

                <div className='hr_hoursBar_sub1' style={{cursor: 'pointer'}} onClick={() => this.toggleWeekDetailsOverhours(i)}>
                  {showDetails ?
                    <i className='fas fa-chevron-up' style={{ marginRight: '5%' }} />
                  :
                    <i className='fas fa-chevron-down' style={{ marginRight: '5%' }} />
                  }
                  <b>Week {i}</b>
                </div>

                <div className='hr_hoursBar_sub3'>
                  {currentContract !== null ?
                    currentContract.contract_type === '1' ?
                      <b>{Constants.msToTime2(currentContract.contract_uren * 60 * 60 * 1000)} | {Constants.msToTime2(currentContract.contract_max_uren * 60 * 60 * 1000)}</b>
                    :
                      <b>{Constants.msToTime2(currentContract.contract_uren * 60 * 60 * 1000)}</b>
                  :
                    <span>&nbsp;</span>
                  }
                </div>

                <div className='hr_hoursBar_sub3'>
                  {totalWorkedHours !== 0 ?
                    <b>{Constants.msToTime2(totalWorkedHours)}</b>
                  : 
                    <span>&nbsp;</span>
                  }
                </div>

                <div className='hr_hoursBar_sub3'>
                  {totalAbsence !== 0 ?
                    <b style={{color: Colors.color.pastBorderColor}}>{Constants.msToTime2(totalAbsence)}</b>
                  : 
                    <span>&nbsp;</span>
                  }
                </div>

                <div className='hr_hoursBar_sub3'>
                  {totalAbsent !== 0 ?
                    <b style={{color: Colors.color.pastBorderColor}}>{Constants.msToTime2(totalAbsent)}</b>
                  : 
                    <span>&nbsp;</span>
                  }
                </div>

                <div className='hr_hoursBar_sub3'>
                  <span>&nbsp;</span>
                </div>

                <div className='hr_hoursBar_sub3'>
                  {totalHours !== 0 ?
                    <b style={{color: hoursString.includes('-') ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{hoursString}</b>
                  : 
                    <span>&nbsp;</span>
                  }
                </div>

                {/* <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getMonday(_week.week, this.state.overhours_chosenYear) })}>
                  <i className='far fa-arrow-right' />
                </div> */}

              </div>

            );

            if (showDetails) {
              toReturn.push(this.overhours_renderWeeks(daysTotal, i, currentContract, correction, correctionNegative, realTotalHours));
            }

            if (count === 0) {
              count++;
            } else {
              count--;
            }

          }

        }

      }

    }

    return toReturn;

  }

  overhours_renderWeeks(daysTotal, week, contract, correction, correctionNegative, realTotalHours) {

    let toRender = [];

    toRender.push(

      <div className='hr_hoursBar' style={{width: '98%', backgroundColor: '#E7EBEF', cursor: 'pointer', borderBottom: '1px solid #EBEBEB', paddingLeft: '1%', paddingRight: '1%'}}>

        <div className='hr_hoursBar_sub1'>
          <span style={{marginLeft: '9%', fontWeight: 600, fontStyle: 'italic', width: '109%'}}>Totaal</span>
        </div>

        <div className='hr_hoursBar_sub3'>
          <span>&nbsp;</span>
        </div>

        <div className='hr_hoursBar_sub3'>
          <span>&nbsp;</span>
        </div>

        <div className='hr_hoursBar_sub3'>
          <span>&nbsp;</span>
        </div>

        <div className='hr_hoursBar_sub3'>
          <span>&nbsp;</span>
        </div>

        <div className='hr_hoursBar_sub3'>
          {correction !== 0 ?
            <span style={{color: correctionNegative ? Colors.color.greenFilledIn : Colors.color.redFilledIn, fontStyle: 'italic', paddingRight: 1}}>{correctionNegative ? '-' : '+'}{Constants.msToTime2(correction)}</span>
          : 
            <span>&nbsp;</span>
          }
        </div>

        <div className='hr_hoursBar_sub3'>
          {realTotalHours !== 0 ?
            <span style={{color: realTotalHours < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn, fontStyle: 'italic', paddingRight: 1}}>{`${realTotalHours < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(realTotalHours))}`}</span>
          :
            <span>&nbsp;</span>
          } 
        </div>

      </div>

    );

    for (const dayKey in daysTotal) {

      const data = daysTotal[dayKey];
      const dayName = dayKey === '1' ? 'Maandag' : dayKey === '2' ? 'Dinsdag' : dayKey === '3' ? 'Woensdag' : dayKey === '4' ? 'Donderdag' : dayKey === '5' ? 'Vrijdag' : dayKey === '6' ? 'Zaterdag' : 'Zondag';
      let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 1000 * 60 * 60) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);
      
      if (contract !== null) {

        if (Constants.isTrue(contract.contract_vaste_dagen)) {

          workDayHours = dayKey === '1' ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                        : dayKey === '2' ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                        : dayKey === '3' ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                        : dayKey === '4' ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                        : dayKey === '5' ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                        : dayKey === '6' ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

        } else {
          workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? (parseFloat(contract.contract_werkdag_uren) * 1000 * 60 * 60) : workDayHours;
        }

      }
    
      toRender.push(

        <div className='hr_hoursBar' style={{width: '98%', backgroundColor: '#F3F5F7', cursor: 'pointer', borderBottom: '1px solid #EBEBEB', paddingLeft: '1%', paddingRight: '1%'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getDateWeekday(dayKey === '7' ? 0 : parseInt(dayKey), week, this.state.overhours_chosenYear) })}>

          <div className='hr_hoursBar_sub1'>
            <span style={{marginLeft: '9%'}}>{dayName}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {data.worked !== 0 ?
              <span>{Constants.msToTime2(workDayHours)}</span>
            : 
              <span>&nbsp;</span>
            }
          </div>

          <div className='hr_hoursBar_sub3'>
            {data.worked !== 0 ?
              <span>{Constants.msToTime2(data.worked)}</span>
            : 
              <span>&nbsp;</span>
            }
          </div>

          <div className='hr_hoursBar_sub3'>
            {data.absence !== 0 ?
              <span style={{color: Colors.color.pastBorderColor}}>{Constants.msToTime2(data.absence)}</span>
            : 
              <span>&nbsp;</span>
            }
          </div>

          <div className='hr_hoursBar_sub3'>
            {data.absent !== 0 ?
              <span style={{color: Colors.color.pastBorderColor}}>{Constants.msToTime2(data.absent)}</span>
            : 
              <span>&nbsp;</span>
            }
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {data.realOverhours !== 0 ?
              <span style={{color: data.realOverhours < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${data.realOverhours < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(data.realOverhours))}`}</span>
            :
              <span>&nbsp;</span>
            } 
          </div>

          {/* <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getMonday(_week.week, this.state.overhours_chosenYear) })}>
            <i className='far fa-arrow-right' />
          </div> */}

        </div>

      );

    }

    return toRender;

  }

  toggleWeekDetailsOverhours(week) {

    let showWeekDetails = this.state.showWeekDetails;

    if (Constants.arrayContains(showWeekDetails, week)) {
      Constants.removeFromArray(showWeekDetails, week);
    } else {
      showWeekDetails.push(week);
    }

    this.setState({ showWeekDetails: showWeekDetails });

  }

  // overhours_renderUserOverhoursSep() {

  //   let toReturn = [];
  //   let count = 0;

  //   if (Constants.isEmpty(this.state.overhours_userOverhours) === false && Constants.isEmpty(this.state.overhours_userOverhours[this.state.overhours_chosenYear.toString()]) === false) {

  //     const userOverhoursYear = this.state.overhours_userOverhours[this.state.overhours_chosenYear.toString()].sep;

  //     if (Constants.isEmpty(userOverhoursYear) === false) {

  //       let weeks = [];

  //       for (const overhour of userOverhoursYear) {

  //         const date = Constants.stringToDate(overhour.ov_datum);
  //         const week = Constants.getWeekNumber2(date);

  //         if (Constants.objectArrayContainsKey(weeks, 'week', week) === false) {
  //           weeks.push({
  //             week: week,
  //             '1': [], '2': [], '3': [], '4': [], '5': [], '6': [], '0': [],
  //           });
  //         }

  //         let weekObject = Constants.getArrayItem(weeks, 'week', week);

  //         if (weekObject !== null) {
  //           weekObject[date.getDay().toString()].push(overhour);
  //         }

  //       }

  //       weeks.sort((a, b) => (a.week > b.week) ? -1 : 1);

  //       for (const _week of weeks) {

  //         let totalHours = 0;
  //         let daysTotal = { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '0': 0 };
  //         for (const key in _week) {

  //           if (key === 'week') {
  //             continue;
  //           }

  //           if (_week[key].length > 0) {
  //             for (const overhour of _week[key]) {
  //               totalHours += parseFloat(overhour.ov_aantal);
  //               daysTotal[key] += parseFloat(overhour.ov_aantal);
  //             }
  //           }

  //         }

  //         const hoursString = `${totalHours < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(totalHours) * 60 * 60 * 1000)}`;

  //         toReturn.push(

  //           <div className='hr_hoursBar' style={{width: '100%', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

  //             <div className='hr_hoursBar_sub1'>
  //               <span>Week {_week.week}</span>
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getMonday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['1'] !== 0 ?
  //                 <span style={{color: daysTotal['1'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['1'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['1']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getTuesday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['2'] !== 0 ?
  //                 <span style={{color: daysTotal['2'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['2'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['2']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getWednesday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['3'] !== 0 ?
  //                 <span style={{color: daysTotal['3'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['3'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['3']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getThursday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['4'] !== 0 ?
  //                 <span style={{color: daysTotal['4'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['4'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['4']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getFriday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['5'] !== 0 ?
  //                 <span style={{color: daysTotal['5'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['5'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['5']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getSaturday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['6'] !== 0 ?
  //                 <span style={{color: daysTotal['6'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['6'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['6']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub4' style={{cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getSunday(_week.week, this.state.overhours_chosenYear) })}>
  //               {daysTotal['0'] !== 0 ?
  //                 <span style={{color: daysTotal['0'] < 0 ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{`${daysTotal['0'] < 0 ? '-' : '+'}${Constants.msToTime2(Math.abs(daysTotal['0']) * 60 * 60 * 1000)}`}</span>
  //               :
  //                 <span>&nbsp;</span>
  //               } 
  //             </div>

  //             <div className='hr_hoursBar_sub3'>
  //               <b style={{color: hoursString.includes('-') ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{hoursString}</b>
  //             </div>

  //             {/* <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.props.history.push({ pathname: `/hour_registration`, date: Constants.getMonday(_week.week, this.state.overhours_chosenYear) })}>
  //               <i className='far fa-arrow-right' />
  //             </div> */}

  //           </div>

  //         );

  //         if (count === 0) {
  //           count++;
  //         } else {
  //           count--;
  //         }

  //       }

  //     }

  //   }

  //   return toReturn;

  // }

  overhours_renderCorrections() {

    let toReturn = [];
    let count = 0;

    if (Constants.isEmpty(this.state.overhours_corrections) === false && Constants.isEmpty(this.state.overhours_corrections[this.state.overhours_chosenYear.toString()]) === false) {

      const userOverhoursYear = this.state.overhours_corrections[this.state.overhours_chosenYear.toString()].sep;

      if (Constants.isEmpty(userOverhoursYear) === false) {

        for (const overhour of userOverhoursYear) {

          const date = Constants.stringToDate(overhour.co_datum);

          const hours = parseFloat(overhour.co_aantal);
          const hoursString = `${hours < 0 ? '-' : '+'}${Constants.msToTime2((Math.abs(hours) * 60 * 60 * 1000))}`;

          const userFullName = `${overhour.info_voornaam} ${(overhour.info_tussenvoegsel !== null && overhour.info_tussenvoegsel !== '') ? overhour.info_tussenvoegsel + " " + overhour.info_achternaam : overhour.info_achternaam}`;

          toReturn.push(

            <div className='hr_hoursBar' style={{width: '98%', backgroundColor: count === 1 ? '#F9FBFC' : null, paddingLeft: '1%', paddingRight: '1%'}}>

              <div className='hr_hoursBar_sub1'>
                <span>{date.toLocaleDateString()}</span>
              </div>

              <div className='hr_hoursBar_sub2'>
                <span>{userFullName}</span>
              </div>

              <div className='hr_hoursBar_sub3'>
                {Constants.isEmpty(overhour.co_notitie) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{overhour.co_notitie}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                  </Tooltip>
                : 
                  <span>&nbsp;</span>
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                {Constants.isTrue(overhour.co_betaling) ?
                  <i className='fal fa-check' style={{color: Colors.color.greenFilledIn}} />
                :
                  <i className='fal fa-times' style={{color: Colors.color.redFilledIn}} />
                }
              </div>

              <div className='hr_hoursBar_sub3'>
                <span style={{color: hoursString.includes('-') ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{hoursString}</span>
              </div>

              <div className='hr_hoursBar_sub4 hr_hoursBar_hover' onClick={() => this.deleteAlertCorrection(overhour)}>
                <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn}} />
              </div>

            </div>

          );

          if (count === 0) {
            count++;
          } else {
            count--;
          }

        }

      }

    }

    return toReturn;

  }

  deleteAlertCorrection(correction) {

    // <b>{Constants.stringToDate(contract.contract_begin).toLocaleDateString()}</b> - <b>{Constants.stringToDate(contract.contract_eind).toLocaleDateString()}</b>

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: (<div>{__('alert_delete')}</div>),
        alertButtons: 2,
        alertButtonAction: this.deleteCorrection.bind(this, correction),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });

  }

  async deleteCorrection(correction) {

    this.setState({ loading: true });

    await APIDELETE.Request(`DELETE FROM CorrectieOveruren`, `WHERE co_id = ${correction.co_id}`);

    await this.reloadBalances();

  }

  overhours_selectYear(event) {

    this.setState({ overhours_chosenYear: parseInt(event.target.value) });

  }

  overhours_yearOptions() {

    let toReturn = [];

    let years = [];

    if (Constants.isEmpty(this.state.hoursWorked) === false) {
      for (const yearKey in this.state.hoursWorked) {
        years.push(parseInt(yearKey));
      }
    }

    if (Constants.isEmpty(this.state.overhours_corrections) === false) {
      for (const yearKey in this.state.overhours_corrections) {
        if (Constants.arrayContains(years, parseInt(yearKey)) === false) {
          years.push(parseInt(yearKey));
        }
      }
    }

    years.sort((a, b) => (a > b) ? 1 : -1);

    for (const yearKey of years) {

      toReturn.push(
        <option value={yearKey.toString()} selected={this.state.overhours_chosenYear === yearKey}>{yearKey}</option>
      );

    }

    return toReturn;

  }

  getWorkDays() {

    let days = 0;

    if (this.state.workWeekMonday === true) {
      days++;
    }
    if (this.state.workWeekTuesday === true) {
      days++;
    }
    if (this.state.workWeekWednesday === true) {
      days++;
    }
    if (this.state.workWeekThursday === true) {
      days++;
    }
    if (this.state.workWeekFriday === true) {
      days++;
    }
    if (this.state.workWeekSaturday === true) {
      days++;
    }
    if (this.state.workWeekSunday === true) {
      days++;
    }

    return days;

  }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (typeof this.state.infoExtra[id] === 'undefined') {
      return 0;
    }

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  calcSickHours(_week, year) {

    let absents = [];
    let totalAbsents = 0;

    let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 60 * 60 * 1000) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 60 * 60 * 1000) : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren) * 60 * 60 * 1000);

    // SICK HOURS
    if (Constants.isTrue(Data.data.appOptions.clock)) {

      let foundSchedulesAbsence = [];

      if (Constants.isEmpty(this.state.schedule2[year]) === false && Constants.isEmpty(this.state.schedule2[year][_week.toString()]) === false) {

        for (const sch of this.state.schedule2[year][_week.toString()]) {

          const date = Constants.stringToDate(sch.rooster_datum);
          const contract = Constants.getCurrentContract(this.state.contracts[sch.info_id], date);

          if (contract !== null) {

            // SET NEW WORKDAYHOURS LIKE CONTRACT
            if (Constants.isTrue(contract.contract_vaste_dagen)) {

              workDayHours = date.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                          : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

            } else {

              workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren) * 60 * 60 * 1000) : workDayHours;

            }

          }

          if (Constants.isEmpty(sch.afwr_id) === false && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
            foundSchedulesAbsence.push(sch);
            absents.push({ date: date, ms: workDayHours });
          }

        }

      }

      totalAbsents = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) : 0;

    }

    return { absents: absents, totalAbsents: totalAbsents };

  }

  calcAbsAmount(_week, year, totalWorkedWeek) {

    let absence = [];
    let totalAbsence = 0;
    let fullWeekHours = 0;
    const chosenWeek = parseInt(_week);

    // ABSAMOUNT
    // if (Constants.isEmpty(this.state.absAmount2[year]) === false && Constants.isEmpty(this.state.absAmount2[year][chosenWeek.toString()]) === false) {
    if (Constants.isEmpty(this.state.absAmount2[year]) === false) {

      for (const weekKey in this.state.absAmount2[year]) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        for (const abs of this.state.absAmount2[year][weekKey]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          const contract = Constants.getCurrentContract(this.state.contracts[abs.info_id], absStart) === null ? Constants.getCurrentContract(this.state.contracts[abs.info_id], absEnd) : Constants.getCurrentContract(this.state.contracts[abs.info_id], absStart);

          if (Constants.isEmpty(contract) === true) {
            continue;
          }

          // SET NEW WORKDAYHOURS LIKE CONTRACT GEEN VASTE DAGEN
          workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

          let fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;

          if (sameDay) {
            if (chosenWeek === parseInt(weekKey) && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
              // SET NEW WORKDAYHOURS LIKE CONTRACT
              if (Constants.isEmpty(contract) === false && Constants.isTrue(contract.contract_vaste_dagen)) {
                workDayHours = absStart.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                              : absStart.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                              : absStart.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                              : absStart.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                              : absStart.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                              : absStart.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                              : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
              }
              // NEW REAL CALC ABSENCE
              if (Constants.objectArrayContainsKey(absence, 'date', absStart) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60))) < parseFloat(contract.contract_uren)) {
                absence.push({ date: absStart, ms: workDayHours * 60 * 60 * 1000 });
                totalAbsence += workDayHours * 60 * 60 * 1000;
              }
              // absence.push({ date: absStart, ms: parseFloat(abs.vs_aantal) * 60 * 60 * 1000 });
              // totalAbsence += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
              // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            }
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
              
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[abs.info_id]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[abs.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[abs.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[abs.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[abs.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[abs.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[abs.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[abs.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                }
                //  else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (absWeek[week] < parseFloat(contract.contract_uren) && Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[abs.info_id]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[abs.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[abs.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[abs.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[abs.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[abs.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[abs.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[abs.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                } 
                // else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (absWeek[week] < parseFloat(contract.contract_max_uren) && Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              }

              index++;

            }

          }

        }

      }

    }

    return { absence: absence, totalAbsence: totalAbsence };

  }

  calcOverhoursN(hours, contract, hoursWorkedWeek, totalOverhoursLocal) {

    const _startDate = Constants.stringToDate(hours.uren_begindatum);

    let _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 1000 * 60 * 60) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);
    let _workDayHoursFloat = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : parseFloat(Data.data.appOptions.opties_dag_werkuren);

    let overhours = null;
    let overhoursCurrent = null;
    let overhoursCorrection = null;
    let overhoursContract = 0;
    let negative = false;
    let negativeCurrent = false;
    let contract_hours = null;
    let contract_max_hours = null;
    let foundUserHoursWeek = false;

    let realOverhoursCurrent = null;
    let realNegativeCurrent = null;

    let workedThisDay = 0;
    let workedThisDayAbsence = 0;
    // let absenceHoursThisDay = 0;

    if (Constants.isTrue(Data.data.appOptions.opties_overuren) === false) {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,
        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,
        overhoursCorrection: overhoursCorrection,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

    if (contract !== null) {

      // SET NEW WORKDAYHOURS LIKE CONTRACT
      _workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? (parseFloat(contract.contract_werkdag_uren) * 1000 * 60 * 60) : _workDayHours;
      _workDayHoursFloat = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? parseFloat(contract.contract_werkdag_uren) : _workDayHoursFloat;

      // CALC CURRENT OVERHOURS (THIS DAY)
      const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
      const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
      const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

      if (start !== null && end !== null && diff !== null) {

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        if (Constants.isTrue(contract.contract_vaste_dagen)) {

          // 0 || _workDayHours
          _workDayHours = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

          // 0 || _workDayHoursFloat
          _workDayHoursFloat = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);

        }

        overhoursCurrent = (diff - _workDayHours) < 0 ? (_workDayHours - diff) : (diff - _workDayHours);
        negativeCurrent = (diff - _workDayHours) < 0;

        workedThisDay = diff;
        workedThisDayAbsence = _workDayHours;

      }

      if (isNaN(overhoursCurrent) === true || overhoursCurrent === 0) {
        overhoursCurrent = null;
      }

      // get current chosen week
      const chosenWeek = Constants.getWeekNumber2(_startDate);

      // get total worktime this week
      let totalHoursWeek = 0;

      // found hours objects this week
      let foundDBHoursArray = [];
      let foundExtraHoursArray = [];

      // normals
      for (const hours2 of hoursWorkedWeek) {
        //if (hours2.info_id === hours.info_id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === hours.uren_begindatum)) {
          const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
          const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
          const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
          //if (startHours2 < _startDate || hours2.uren_begindatum === hours.uren_begindatum) { // <
            foundUserHoursWeek = true;
            foundDBHoursArray.push(hours2);
            totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2));
          //}
        //}
      }

      // SICK HOURS
      if (Constants.isTrue(Data.data.appOptions.clock)) {

        let foundSchedulesAbsence = [];

        if (Constants.isEmpty(this.state.schedule2[_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.schedule2[_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

          for (const sch of this.state.schedule2[_startDate.getFullYear().toString()][chosenWeek.toString()]) {

            if (sch.rooster_info_id === (hours.info_id)) {

              if (Constants.isEmpty(sch.afwr_id) === false && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
                foundSchedulesAbsence.push(sch);
              }

            }

          }

        }

        totalHoursWeek += foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) : 0;

      }

      // ABSAMOUNT
      if (Constants.isEmpty(this.state.absAmount2[_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.absAmount2[_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

        let fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;

        for (const abs of this.state.absAmount2[_startDate.getFullYear().toString()][chosenWeek.toString()]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          if (sameDay) {
            totalHoursWeek += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
            
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[hours.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[hours.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[hours.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[hours.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[hours.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[hours.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[hours.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[hours.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[hours.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[hours.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[hours.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[hours.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[hours.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[hours.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              }

              index++;

            }

            if (Object.keys(absWeek).length > 0) {
              for (let key in absWeek) {
                if (chosenWeek === parseInt(key)) {
                  totalHoursWeek += (absWeek[key] * 60 * 60 * 1000);
                }
              }
            }

          }

        }

      }

      realOverhoursCurrent = overhoursCurrent;
      realNegativeCurrent = negativeCurrent;

      // calc
      if (Constants.isTrue(contract.contract_vaste_dagen) === false) {
        
        if (contract.contract_type === '1') {

          contract_max_hours = parseFloat(contract.contract_max_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) > contract_max_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_max_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        } else if (contract.contract_type === '2') {

          contract_hours = parseFloat(contract.contract_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) >= contract_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        }

      }

    }

    if (Constants.isEmpty(contract) === true || Constants.isEmpty(contract.contract_type) === true || contract.contract_type === '0') {

      return {
        overhoursTotal: '00:00',
        isNegativeTotal: false,

        overhoursCurrent: null,
        isNegativeCurrent: false,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: 0,
        // overhours this week
      };

    } else {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,

        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

  }

  async reloadContractSign(base64Sign) {

    this.setState({ reloading: true });

    const formPdfBytes = this.state.formPdfBytes;

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // sign bytes
    const signBytes = await fetch(base64Sign).then(res => res.arrayBuffer());

    // embed sign
    const signImage = await pdfDoc.embedPng(signBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // List all fields and set the state (editable)
    fields.forEach(field => {

      // const type = field.constructor.name;
      const name = field.getName();

      try {

        const item = Constants.getArrayItem(this.state.pdfFields, 'key', name);

        if (item !== null) {

          // set button
          if (name === 'sign_employer') {

            const _field = form.getButton(`${name}`);
            _field.setImage(signImage);

          // set text
          } else {

            // Set field in PDF
            const _field = form.getTextField(`${name}`);
            _field.setText(item.value);

          }

        }
        
      } catch (error) {
        
      }

    });

    // Flatten pdf so it stays this way
    form.flatten();

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();

    // not flatten version
    await this.reloadContractSign_notFlat(base64Sign);
    
    this.setState({ reloading: false, pdfForm: pdfBytes, contractSigned: true });

  }

  async reloadContractSign_notFlat(base64Sign) {

    const formPdfBytes = this.state.formPdfBytes;

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // sign bytes
    const signBytes = await fetch(base64Sign).then(res => res.arrayBuffer());

    // embed sign
    const signImage = await pdfDoc.embedPng(signBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // List all fields and set the state (editable)
    fields.forEach(field => {

      // const type = field.constructor.name;
      const name = field.getName();

      try {

        const item = Constants.getArrayItem(this.state.pdfFields, 'key', name);

        if (item !== null) {

          // set button
          if (name === 'sign_employer') {

            const _field = form.getButton(`${name}`);
            _field.setImage(signImage);

          // set text
          } else {

            // Set field in PDF
            const _field = form.getTextField(`${name}`);
            _field.setText(item.value);

          }

        }
        
      } catch (error) {
        
      }

    });

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();
    
    this.setState({ pdfForm_notFlat: pdfBytes });

  }

  async reloadContractSignDocuments(base64Sign) {

    this.setState({ reloading: true });

    const doc = this.state.signContractDocLoc;

    const formUrl = `https://${Data.data.serverIP}/u/${Data.data.storeCode.toLowerCase()}/${doc.d_loc}`;
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // sign bytes
    const signBytes = await fetch(base64Sign).then(res => res.arrayBuffer());

    // embed sign
    const signImage = await pdfDoc.embedPng(signBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // List all fields and set the state (editable)
    fields.forEach(field => {

      // const type = field.constructor.name;
      const name = field.getName();

      try {

        // set button
        if (name === 'sign_employer') {

          const _field = form.getButton(`${name}`);
          _field.setImage(signImage);

        }
        
      } catch (error) {
        
      }

    });

    // Flatten pdf so it stays this way
    form.flatten();

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();

    // get flatten doc
    const flattenDoc = Constants.getArrayItem(this.state.documents, 'd_id', doc.d_flat_id);

    // decode to file object
    const newFile = await Constants.urltoFile('data:application/pdf;base64,' + pdfBytes, `${flattenDoc.d_naam}`,'application/pdf');

    // UPLOAD NEW FILE
    const upload = await APIUploadFile.Request(newFile);

    if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

      const uploaded = await APIEDIT.Request(`UPDATE Document`, `SET d_loc = '${upload.loc}', d_getekend_eigenaar = ${Data.data.accountData.info_id}`, `WHERE d_id = ${flattenDoc.d_id}`);

      // not flatten version
      await this.reloadContractSignDocuments_notFlat(flattenDoc.d_id, doc, base64Sign);

      // DELETE OLD FILES
      await APIDelFile.Request(flattenDoc.d_loc);
      await APIDelFile.Request(doc.d_loc);

      // get documents
      const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.state.userData.info_id}`, null, `ORDER BY d_naam`);
    
      this.setState({ reloading: false, documents: docs, signContractDocLoc: null, });

    }

  }

  async reloadContractSignDocuments_notFlat(flatID, doc, base64Sign) {

    const formUrl = `https://${Data.data.serverIP}/u/${Data.data.storeCode.toLowerCase()}/${doc.d_loc}`;
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // sign bytes
    const signBytes = await fetch(base64Sign).then(res => res.arrayBuffer());

    // embed sign
    const signImage = await pdfDoc.embedPng(signBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // List all fields and set the state (editable)
    fields.forEach(field => {

      // const type = field.constructor.name;
      const name = field.getName();

      try {

        // set button
        if (name === 'sign_employer') {

          const _field = form.getButton(`${name}`);
          _field.setImage(signImage);

        }
        
      } catch (error) {
        
      }

    });

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();

    // decode to file object
    const newFile = await Constants.urltoFile('data:application/pdf;base64,' + pdfBytes, `${doc.d_naam}`,'application/pdf');

    // UPLOAD NEW FILE
    const upload = await APIUploadFile.Request(newFile);

    if (upload !== null && typeof upload.status !== 'undefined' && upload.status === 'success') {

      const uploaded = await APIEDIT.Request(`UPDATE Document`, `SET d_loc = '${upload.loc}', d_getekend_eigenaar = ${Data.data.accountData.info_id}, d_flat_id = ${flatID}`, `WHERE d_id = ${doc.d_id}`);

    }

  }

  openSignContractModal() {

    this.setState({
      showSignContractModal: true,
    });

  }

  closeSignContractModal() {

    this.setState({
      showSignContractModal: false,
    });

  }

  openSignContractModalDocuments(doc) {

    this.setState({
      signContractDocLoc: doc,
      showSignContractModalDocuments: true,
    });

  }

  closeSignContractModalDocuments() {

    this.setState({
      showSignContractModalDocuments: false,
    });

  }

  openNotesModal(mode, data) {

    this.setState({
      showNotesModal: true,
      modalData: Constants.isEmpty(data) === true ? null : data,
      modalMode: mode,
    });

  }

  openCorrectionModal(mode, data) {

    this.setState({
      showCorrectionModal: true,
      modalData: Constants.isEmpty(data) === true ? null : data,
      modalMode: mode,
    });

  }

  closeCorrectionModal() {

    this.setState({
      showCorrectionModal: false,
      modalData: null,
      modalMode: 0,
    });

  }

  async reloadBalances() {

    this.setState({ loading: true });

    await this.getLeaveData(this.state.userData.info_id);
    await this.getOverhoursData(this.state.userData.info_id);

    // if (this.state.menu === 'leavebalances') {

    //   const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    //   const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    //   const currentContract = Constants.getCurrentContract(contractsObj[this.state.selected]);
    //   const userContracts = typeof contractsObj[this.state.selected] === 'undefined' ? [] : contractsObj[this.state.selected];

    //   const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    //   const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    //   const leaveCorrectionsData = await APIGET.Request(`SELECT CorrectieVerlof.*, VerlofBalans.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieVerlof`, `LEFT JOIN VerlofBalans ON cv_vb_id = vb_id LEFT JOIN Info ON cv_info_id_door = info_id`, `WHERE cv_info_id = ${this.state.selected}`, null, `ORDER BY cv_datum DESC, cv_id DESC`);

    //   const allHolidays = await APIGET.Request(`SELECT *`, `FROM Vakantie`, null, `WHERE vakantie_geaccepteerd = 1`, null, null);
    //   const allHolidaysObj = Constants.convertToObjectWithArrays(allHolidays, 'vakantie_info_id');

    //   const userLeaveBalances = await Constants.getLeaveBalance(userContracts, this.timeNow, this.state.selected, this.state.leaveBalances, this.state.leaveTypeBalances, this.state.contractLeaveBalances, allHolidaysObj, leaveCorrectionsData, true, this.state.infoExtra);

    //   await this.leave_getRequestsData(this.state.selected);

    //   // CORRECTIONS
    //   let leaveCorrections = {};
    //   for (const correction of leaveCorrectionsData) {
 
    //     const date = Constants.stringToDate(correction.cv_datum);
    //     const year = date.getFullYear();
    //     const yearKey = year.toString();

    //     if (typeof leaveCorrections[yearKey] === 'undefined') {
    //       leaveCorrections[yearKey] = {
    //         sep: [],
    //       };
    //     }

    //     leaveCorrections[yearKey].sep.push(correction);
 
    //   }

    //   this.setState({ contracts: contractsObj, currentContract: currentContract, userContracts: userContracts, leave_userLeaveBalances: userLeaveBalances, absAmount: absAmountObj, leave_corrections: leaveCorrections });

    // } else if (this.state.menu === 'overhours') {

    //   const overhours = await APIGET.Request(`SELECT *`, `FROM Overuren`, null, `WHERE ov_info_id = ${this.state.selected}`, null, `ORDER BY ov_datum DESC`);
    //   const overhoursCorrectionsData = await APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, `WHERE co_info_id = ${this.state.selected}`, null, `ORDER BY co_datum DESC, co_id DESC`);

    //   // CORRECTIONS
    //   let overhoursCorrections = {};
    //   overhoursCorrections[new Date().getFullYear().toString()] = {
    //     sep: [],
    //     total: 0,
    //     totalAT: 0,
    //   };
    //   for (const correction of overhoursCorrectionsData) {

    //     const date = Constants.stringToDate(correction.co_datum);
    //     const year = date.getFullYear();
    //     const yearKey = year.toString();

    //     if (typeof overhoursCorrections[yearKey] === 'undefined') {
    //       overhoursCorrections[yearKey] = {
    //         sep: [],
    //         total: 0,
    //         totalAT: 0,
    //       };
    //     }

    //     overhoursCorrections[yearKey].sep.push(correction);
    //     overhoursCorrections[yearKey].total += parseFloat(correction.co_aantal);

    //   }

    //   let yearCorrections = 0;
    //   for (const yearKey in overhoursCorrections) {
    //     overhoursCorrections[yearKey].totalAT += (parseFloat(overhoursCorrections[yearKey].total) + yearCorrections);
    //     yearCorrections += overhoursCorrections[yearKey].total;
    //   }

    //   // OVERHOURS
    //   let userOverhours = {};
    //   userOverhours[new Date().getFullYear().toString()] = {
    //     sep: [],
    //     total: 0,
    //     totalAT: 0,
    //   };
    //   for (const overhour of overhours) {

    //     const date = Constants.stringToDate(overhour.ov_datum);
    //     const year = date.getFullYear();
    //     const yearKey = year.toString();

    //     if (typeof userOverhours[yearKey] === 'undefined') {
    //       userOverhours[yearKey] = {
    //         sep: [],
    //         total: 0,
    //         totalAT: 0,
    //       };
    //     }

    //     userOverhours[yearKey].sep.push(overhour);
    //     userOverhours[yearKey].total += parseFloat(overhour.ov_aantal);

    //   }

    //   let yearOverhours = 0;
    //   for (const yearKey in userOverhours) {
    //     userOverhours[yearKey].totalAT += (parseFloat(userOverhours[yearKey].total) + yearOverhours);
    //     userOverhours[yearKey].totalAT += (typeof overhoursCorrections[yearKey] !== 'undefined' ? overhoursCorrections[yearKey].totalAT : 0);
    //     yearOverhours += userOverhours[yearKey].total;
    //     // userOverhours[yearKey].totalAT += (typeof overhoursCorrections[yearKey] !== 'undefined' ? overhoursCorrections[yearKey].totalAT : 0);
    //   }

    //   this.setState({ overhours_userOverhours: userOverhours, overhours_corrections: overhoursCorrections, });

    // }

    this.setState({ loading: false });

  }

  deleteNoteAlert(note) {

    // <b>{Constants.stringToDate(contract.contract_begin).toLocaleDateString()}</b> - <b>{Constants.stringToDate(contract.contract_eind).toLocaleDateString()}</b>

    this.setState({
        alertTitle: __('alert_delete_title'),
        alertBody: (<div>{__('alert_delete')}</div>),
        alertButtons: 2,
        alertButtonAction: this.deleteNote.bind(this, note),
        alertButtonText: [__('button_cancel'), __('button_delete')],
        showAlert: true,
    });

  }

  async deleteNote(note) {

    this.setState({ loading: true });

    await APIDELETE.Request(`DELETE FROM Notitie`, `WHERE n_id = ${note.n_id}`);

    await this.reloadNotes();

    this.setState({ loading: false, });

  }

  renderAppointments() {

    let toRender = [];
    let count = 0;

    for (const appoint of this.state.appointments) {

      const date = Constants.stringToDate(appoint.n_datum);
      const userFullName = `${appoint.info_voornaam} ${(appoint.info_tussenvoegsel !== null && appoint.info_tussenvoegsel !== '') ? appoint.info_tussenvoegsel + " " + appoint.info_achternaam : appoint.info_achternaam}`;

      toRender.push(

        <div className='hr_hoursBar_noHeight' style={{width: '100%', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub2'>
            <span>{date.toLocaleDateString()}</span>
          </div>

          <div className='hr_hoursBar_sub5'>
            <span>{appoint.n_tijd.substr(0, 5)}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{Constants.firstUppercase(appoint.n_titel)}</span>
          </div>

          <div className='hr_hoursBar_sub1_wrap'>
            <span>{Constants.isEmpty(appoint.n_tekst) === false ? Constants.firstUppercase(appoint.n_tekst) : ''}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{userFullName}</span>
          </div>

          <div className='hr_hoursBar_sub6'>
            <span><i className='far fa-edit' style={{cursor: 'pointer'}} onClick={() => this.openNotesModal(1, appoint)} /></span>
          </div>

          <div className='hr_hoursBar_sub6'>
            <span><i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer'}} onClick={() => this.deleteNoteAlert(appoint)} /></span>
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  renderNotes() {

    let toRender = [];
    let count = 0;

    let notes = this.state.notes;
    notes.sort((a, b) => (a.n_id > b.n_id) ? 1 : -1);

    for (const appoint of notes) {

      const userFullName = `${appoint.info_voornaam} ${(appoint.info_tussenvoegsel !== null && appoint.info_tussenvoegsel !== '') ? appoint.info_tussenvoegsel + " " + appoint.info_achternaam : appoint.info_achternaam}`;

      toRender.push(

        <div className='hr_hoursBar_noHeight' style={{width: '100%', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub2'>
            <span>{Constants.firstUppercase(appoint.n_titel)}</span>
          </div>

          <div className='hr_hoursBar_sub1_wrap'>
            <span>{Constants.isEmpty(appoint.n_tekst) === false ? Constants.firstUppercase(appoint.n_tekst) : ''}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub5'>
            <span>&nbsp;</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{userFullName}</span>
          </div>

          <div className='hr_hoursBar_sub6'>
            <span><i className='far fa-edit' style={{cursor: 'pointer'}} onClick={() => this.openNotesModal(1, appoint)} /></span>
          </div>

          <div className='hr_hoursBar_sub6'>
            <span><i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer'}} onClick={() => this.deleteNoteAlert(appoint)} /></span>
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async reloadNotes() {

    this.setState({ reloading: true });

    const notes = await APIGET.Request(`SELECT *`, `FROM Notitie`, `LEFT JOIN Info ON n_info_id_door = info_id`, `WHERE n_datum >= (NOW() - INTERVAL 3 MONTH) AND n_info_id = ${this.state.userData.info_id}`, null, `ORDER BY n_datum`);

    let _notes = [];
    let _appointments = [];

    for (const note of notes) {

      if (Constants.isEmpty(note.n_melding)) {
        _notes.push(note);
      } else {
        _appointments.push(note);
      }

    }

    this.setState({         
      notes: _notes,
      appointments: _appointments, 
      reloading: false 
    });

  }

  getContractTemplates() {

    let toReturn = [];

    for (const doc of this.state.documentsContract) {

      toReturn.push(
        <option key={doc.dc_id} value={doc.dc_id} selected={this.state.chosenContractTemplate === doc.dc_id}>{doc.dc_naam}</option>
      );

    }

    return toReturn;

  }

  async changeContractTemplate(e) {

    if (Constants.isEmpty(e.target.value)) {
      return;
    }

    const val = e.target.value;

    this.setState({ reloading: true, chosenContractTemplate: e.target.value, specialChanges: true });

    const chosenContractTemplate = await APIGET.Request(`SELECT *`, `FROM DocumentC`, null, `WHERE dc_id = ${e.target.value}`, null, null);

    let _chosenContractTemplate = null;
    if (chosenContractTemplate.length > 0) {
      _chosenContractTemplate = chosenContractTemplate[0];
    }

    // contract
    const contract = Constants.getCurrentContract(this.state.userContracts, new Date());

    // Get form by url
    const formUrl = `https://${Data.data.serverIP}/u/${Data.data.storeCode.toLowerCase()}/contracts/${_chosenContractTemplate.dc_loc}`;
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // Create state for text fields
    let _pdfFields = [];

    // List all fields and set the state (editable)
    fields.forEach(field => {

      const type = field.constructor.name;
      const name = field.getName();

      try {

        // Set state for field
        if (Constants.objectArrayContainsKey(_pdfFields, 'key', name) === false) {
          _pdfFields.push({ key: name, value: Constants.getFieldNameValue(name, this.state.userData, contract) });
        }

        // Set field in PDF
        const _field = form.getTextField(`${name}`);
        _field.setText(Constants.getFieldNameValue(name, this.state.userData, contract));
        
      } catch (error) {

        //console.log(error);
        
      }

    });

    _pdfFields.sort((a, b) => (a.key > b.key) ? 1 : -1);

    // Flatten pdf so it stays this way
    form.flatten();

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();

    // // Decode to file object 
    // const newFile = await Constants.urltoFile('data:application/pdf;base64,' + pdfBytes, 'testttt.pdf','application/pdf');
    
    //await APIUploadContract.Request(newFile);

    // not flatten version
    await this.changeContractTemplate_notFlat(val);
    
    this.setState({ reloading: false, chosenContractObj: _chosenContractTemplate, formPdfBytes: formPdfBytes, pdfForm: pdfBytes, pdfFields: _pdfFields });

  }

  async changeContractTemplate_notFlat(value) {

    if (Constants.isEmpty(value)) {
      return;
    }

    const chosenContractTemplate = await APIGET.Request(`SELECT *`, `FROM DocumentC`, null, `WHERE dc_id = ${value}`, null, null);

    let _chosenContractTemplate = null;
    if (chosenContractTemplate.length > 0) {
      _chosenContractTemplate = chosenContractTemplate[0];
    }

    // contract
    const contract = Constants.getCurrentContract(this.state.userContracts, new Date());

    // Get form by url
    const formUrl = `https://${Data.data.serverIP}/u/${Data.data.storeCode.toLowerCase()}/contracts/${_chosenContractTemplate.dc_loc}`;
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // Create state for text fields
    let _pdfFields = [];

    // List all fields and set the state (editable)
    fields.forEach(field => {

      const type = field.constructor.name;
      const name = field.getName();

      try {

        // Set state for field
        if (Constants.objectArrayContainsKey(_pdfFields, 'key', name) === false) {
          _pdfFields.push({ key: name, value: Constants.getFieldNameValue(name, this.state.userData, contract) });
        }

        // Set field in PDF
        const _field = form.getTextField(`${name}`);
        _field.setText(Constants.getFieldNameValue(name, this.state.userData, contract));
        
      } catch (error) {

        //console.log(error);
        
      }

    });

    _pdfFields.sort((a, b) => (a.key > b.key) ? 1 : -1);

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();

    // // Decode to file object 
    // const newFile = await Constants.urltoFile('data:application/pdf;base64,' + pdfBytes, 'testttt.pdf','application/pdf');
    
    //await APIUploadContract.Request(newFile);
    
    this.setState({ pdfForm_notFlat: pdfBytes });

  }

  getPDFFieldBars() {

    let toRender = [];

    for (const field of this.state.pdfFields) {

      if (field.key === 'sign_employee' || field.key === 'sign_employer' || field.key === 'sign_day' || field.key === 'sign_month' || field.key === 'sign_year' || field.key === 'sign_date') {
        continue;
      }

      toRender.push(

        <div className='employees_template_field_row'>

          <div className='employees_template_field_sub1'>
            <span><b>{field.key}</b></span>
          </div>

          <div className='employees_template_field_sub2'>
            <input
              className={'employees_inputBoxTemplate'}
              maxLength={50}
              value={field.value}
              onChange={(event) => this.onChangeTemplateData(field.key, event)}
              placeholder={field.key.replace('_', ' ')}
            />
          </div>

        </div>

      );

    }

    return toRender;

  }

  async reloadContractTemplate() {

    this.setState({ reloading: true });

    const formPdfBytes = this.state.formPdfBytes;

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // List all fields and set the state (editable)
    fields.forEach(field => {

      // const type = field.constructor.name;
      const name = field.getName();

      if (name === 'sign_employee' || name === 'sign_employer' || name === 'sign_day' || name === 'sign_month' || name === 'sign_year' || name === 'sign_date') {
        return;
      }

      try {

        const item = Constants.getArrayItem(this.state.pdfFields, 'key', name);

        if (item !== null) {

          // Set field in PDF
          const _field = form.getTextField(`${name}`);
          _field.setText(item.value);

        }
        
      } catch (error) {
        
      }

    });

    // Flatten pdf so it stays this way
    form.flatten();

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();

    // not flatten version
    await this.reloadContractTemplate_notFlat();
    
    this.setState({ reloading: false, pdfForm: pdfBytes });

  }

  async reloadContractTemplate_notFlat() {

    const formPdfBytes = this.state.formPdfBytes;

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all text fields
    const fields = form.getFields();

    // List all fields and set the state (editable)
    fields.forEach(field => {

      // const type = field.constructor.name;
      const name = field.getName();

      if (name === 'sign_employee' || name === 'sign_employer' || name === 'sign_day' || name === 'sign_month' || name === 'sign_year' || name === 'sign_date') {
        return;
      }

      try {

        const item = Constants.getArrayItem(this.state.pdfFields, 'key', name);

        if (item !== null) {

          // Set field in PDF
          const _field = form.getTextField(`${name}`);
          _field.setText(item.value);

        }
        
      } catch (error) {
        
      }

    });

    // encode to Base64
    const pdfBytes = await pdfDoc.saveAsBase64();
    
    this.setState({ pdfForm_notFlat: pdfBytes });

  }

  async saveToDocuments() {

    this.setState({ reloading: true });

    // Decode to file object 
    const newFile = await Constants.urltoFile('data:application/pdf;base64,' + this.state.pdfForm, `${this.state.chosenContractObj.dc_naam}`,'application/pdf');
    const newFile_notFlat = await Constants.urltoFile('data:application/pdf;base64,' + this.state.pdfForm_notFlat, `${this.state.chosenContractObj.dc_naam}`,'application/pdf');
    
    const upload = await APIUploadFile.Request(newFile);
    const upload_notFlat = await APIUploadFile.Request(newFile_notFlat);

    if (upload !== null && typeof upload.status !== 'undefined' && upload_notFlat !== null && typeof upload_notFlat.status !== 'undefined' && upload.status === 'success' && upload_notFlat.status === 'success') {

      const uploaded = await APIADD.Request(`INSERT INTO Document`, `VALUES (NULL, ${this.state.userData.info_id}, '${this.state.chosenContractObj.dc_naam}', '${upload.loc}', NULL, NULL, 1, 1, ${this.state.contractSigned === true ? Data.data.accountData.info_id : 0}, NULL)`);
      const uploadedFlatID = await APIGET.Request(`SELECT d_id`, `FROM Document`, null, null, null, `ORDER BY d_id DESC LIMIT 1`);
      const uploaded_notFlat = await APIADD.Request(`INSERT INTO Document`, `VALUES (NULL, ${this.state.userData.info_id}, '${this.state.chosenContractObj.dc_naam}', '${upload_notFlat.loc}', NULL, NULL, 1, 0, ${this.state.contractSigned === true ? Data.data.accountData.info_id : 0}, ${uploadedFlatID[0].d_id})`);

      if (uploaded === true && uploaded_notFlat === true) {

        this.setState({ 
          fileContract: null,
          chosenContractTemplate: null,
          chosenContractObj: null,
          formPdfBytes: null,
          pdfForm: null,
          pdfForm_notFlat: null,
          pdfFields: [],
          specialChanges: false,
          contractSigned: false,
        });

        const docs = await APIGET.Request(`SELECT *`, `FROM Document`, null, `WHERE d_info_id = ${this.state.userData.info_id}`, null, `ORDER BY d_naam`);

        this.setState({ documents: docs }, () => {

          this.selectMenu('documents', true);

          this.setState({ 
            reloading: false,
          });

        });

      } else {

        this.setState({ 
          reloading: false,
        });

      }

    } else {

      this.setState({ 
        reloading: false,
      });

    }

  }

  async saveContractDocumentsAlert() {

    this.setState({
        alertTitle: __('alert_save_title'),
        alertBody: (
            <div>
                {__('alert_save')}
            </div>
        ),
        alertButtons: 2,
        alertButtonText: [__('button_cancel'), __('button_save')],
        alertButtonAction: this.saveToDocuments.bind(this),
        showAlert: true,
    });
  }

  render() {

    let exportAccs = [];
    let exportAccs_all = [];

    for (const acc of this.state.accounts) {
      let newAcc = acc;
      const secureData = this.state.secureDataIter[acc.info_id];
      if (Constants.isEmpty(secureData) === false) {
        newAcc.info_ibnr = secureData.iban;
        newAcc.info_idnr = secureData.bsn;
      }
      const infoExtra = this.state.infoExtra[acc.info_id];
      if (Constants.isEmpty(infoExtra) === false && infoExtra.info2_status === '2') {
        exportAccs_all.push(newAcc);
      } else {
        exportAccs.push(newAcc);
        exportAccs_all.push(newAcc);
      }
    }

    const functions = this.getFunctionBars();

    const holidayRequestsOpen = this.leave_renderUserRequests(true);
    const holidayRequestsReviewed = this.leave_renderUserRequests(false);

    const has_a_fixedHours = Constants.getAnyContract_type(this.state.userContracts, '2');
    const has_a_minmax = Constants.getAnyContract_type(this.state.userContracts, '1');
    const has_a_flex = Constants.getAnyContract_type(this.state.userContracts, '0');
    const has_a_leavebalance = Constants.getAnyContract_leaveBalance(this.state.userContracts);

    const hasTeam = Constants.objectArrayContainsKey(this.state.missingTeamPeople, 'info_id', this.state.selected) === false;
    const hasJob = Constants.objectArrayContainsKey(this.state.missingJobPeople, 'info_id', this.state.selected) === false;

    return (
        <div className='employees'>

          {this.state.reloading === true ?       
            <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
          : null}

          <Navbar ref={this.navBar} history={this.props.history} active={'employees'} fixed loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'employees'} parent={this} />

          <Prompt
            when={this.state.specialChanges}
            message='You have unsaved changes. Are you sure you want to leave?'
          />

           {this.state.showAlert === true ?

            <BetterAlert
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null

          }

          {this.state.showContractEndModal === true ?

            <ContractEndModal
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _hasContract={this.state.hasContract}
              _reloadScreen={this.reloadScreen.bind(this)}
            />

          : null}

           {this.state.showEmployeesModal === true ?

            <UploadEmployeesModal
              _closeModal={this.closeEmployeesModal.bind(this)}
              _modalData={this.state.modalData}
              _reloadScreen={this.reloadScreen.bind(this)}
            />

          : null}

          {this.state.showUserLeaveBalanceModal === true ?

            <UserLeaveBalanceModal
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _leaveBalances={this.state.leaveBalances}
              _reloadScreen={this.reloadUserLeave.bind(this)}
            />

          : null}

          {this.state.showCorrectionModal === true ?

            <CorrectionModal
              _closeModal={this.closeCorrectionModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _userID={this.state.userData.info_id}
              _reloadScreen={this.reloadBalances.bind(this)}
            />

          : null}

          {this.state.showContractModal === true ?

            <ContractModal
              _closeModal={this.closeContractModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}

              _userContracts={this.state.contracts[this.state.userData.info_id]}
              _infoExtra={this.state.infoExtra[this.state.userData.info_id]}
              _contractLeaveBalances={this.state.contractLeaveBalances}
              _leaveBalances={this.state.leaveBalances}
              _salaryModels={this.state.salaryModels}
              _salaries={this.state.salaries}
              _userData={this.state.userData}

              history={this.props.history}

              _reloadScreen={this.reloadContracts.bind(this)}
            />

          : null}

          {this.state.showAddHolidayModal === true ?
    
            <AddHolidayModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _reloadScreen={this.reloadBalances.bind(this)}
              _individual={true}
              _individual_userID={this.state.userData.info_id}
              />

          : null}

          {this.state.showNotesModal === true ?
    
            <EmployeeNoteModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadNotes.bind(this)}
              _userID={this.state.userData.info_id}
              />

          : null}

          {this.state.showSignContractModal === true ?
    
            <SignContractModal 
              _closeModal={this.closeSignContractModal.bind(this)}
              _reloadScreen={this.reloadContractSign.bind(this)}
            />

          : null}

          {this.state.showSignContractModalDocuments === true ?
    
            <SignContractModal
              _closeModal={this.closeSignContractModalDocuments.bind(this)}
              _reloadScreen={this.reloadContractSignDocuments.bind(this)}
            />

          : null}

          {/* {this.state.selected === 'all' || this.state.selected === 'team' ?

            <div className='planner_nav2_fixed fixedNav2'>

              <div className='planner_nav2_sub1' onClick={null} style={{cursor: 'default'}}>

              </div>
              <div className='planner_nav2_sub2'>

              </div>
              {this.state.selected === 'team' ?
                <div className='planner_nav2_sub3' style={{flex: 2}}>
                  Medewerkers ({this.state.accounts.length}/{Data.data.maxEployees})
                </div>
              :
                <div className='planner_nav2_sub3' style={{flex: 2}}>
                    Medewerkers ({this.state.accounts.length - 1}/{Data.data.maxEployees})
                </div>
              }
              <div className='schedule_nav2_sub4'>

              </div>
              <div className='planner_nav2_sub5' onClick={() => this.setState({selected: '1'})} style={{cursor: 'default'}}>

              </div>

            </div>

          :

            <div className='planner_nav2_fixed fixedNav2'>

              <div className='planner_nav2_sub1' style={{cursor: 'default'}}>
              </div>
              <div className='planner_nav2_sub2'>

              </div>
              <div className='planner_nav2_sub3' style={{flex: 8}}>

                {Constants.firstUppercase(this.state.selectedName)}

              </div>
              <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>

              </div>
              <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
              </div>

            </div>

          } */}

          <div className='employees_main2'>
            {/* stickyTopMenuPanel */}
            {this.state.selected !== 'all' && this.state.selected !== 'team' ?
              // style={{ height: 'calc(100vh - 6vw - 1px)' }}
              <div className='employees_menu2'>

                <div className='employees_menuTitle'>
                  <div>{__('employees_title')}</div>
                </div>

                <div className={`employees_menuButton ${this.state.selected === 'all' ? 'activeAv' : ''}`} onClick={() => this.selectUser('all', '', true)}>
                  <div>{__('employees_allEmployees')}</div> 
                </div>

                {Data.data.teams.length > 0 && Data.data.chosenTeam !== null ?
                  <div className={`employees_menuButton ${this.state.selected === 'team' ? 'activeAv' : ''}`} onClick={() => this.selectUser('team', '', true)}>
                    <div>{__('employees_allEmployeesTeam')}</div> 
                  </div>
                : null}

                <div style={{width: 'calc(100% - 0.8vw)', marginLeft: '0.8vw', marginBottom: '0.2vw', marginTop: '1vw'}}>
                  <select
                    name={__('select_contract')}
                    className={'hr_dropdown'}
                    onChange={(event) => this.changeContract(event)}
                  >
                    <option value={'null'} disabled>{__('select_contract')}</option>
                    <option value={'all'} selected={this.state.selectedContract === 'all'}>{__('select_contract_all')}</option>
                    <option value={'0'} selected={this.state.selectedContract === '0'}>{__('select_contract_flex')}</option>
                    <option value={'1'} selected={this.state.selectedContract === '1'}>{__('select_contract_minMax')}</option>
                    <option value={'2'} selected={this.state.selectedContract === '2'}>{__('select_contract_fixed')}</option>
                  </select>
                </div>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?
                  <div style={{width: 'calc(100% - 0.8vw)', marginLeft: '0.8vw', marginBottom: '0.5vw'}}>
                    <select
                      name={__('select_employee')}
                      className={'hr_dropdown'}
                      onChange={(event) => this.changeEmployee(event)}
                    >
                      <option value={'null'} disabled>{__('select_team')}</option>
                      <option value={'all'} selected={this.state.selected === 'all'}>{__('select_team_all')}</option>
                      <option value={'team'} selected={this.state.selected === 'team'}>{Data.data.chosenTeamObj.team_naam}</option>
                      <option value={'null'} disabled>&nbsp;</option>
                      <option value={'null'} disabled>{__('select_employee')}</option>
                      {this.getEmployeeSelects()}
                    </select>
                  </div>
                : 
                  <div style={{width: 'calc(100% - 0.8vw)', marginLeft: '0.8vw', marginBottom: '0.5vw'}}>
                    <select
                      name={__('select_employee')}
                      className={'hr_dropdown'}
                      onChange={(event) => this.changeEmployee(event)}
                    >
                      <option value={'null'} disabled>{__('select_employee')}</option>
                      <option value={'all'} selected={this.state.selected === 'all'}>{__('select_employee_all')}</option>
                      <option value={'null'} disabled>&nbsp;</option>
                      {this.getEmployeeSelects()}
                    </select>
                  </div>
                }

                {/*<div style={{width: 'calc(100% - 0.8vw)', marginLeft: '0.8vw', marginBottom: '0.5vw'}}>*/}
                {/*  <input type="checkbox" defaultChecked={isToggled} onClick={callback} />*/}
                {/*  <span />*/}
                {/*  <strong>{label}</strong>*/}
                {/*  <select*/}
                {/*      name={__('select_active_status')}*/}
                {/*      className={'hr_dropdown'}*/}
                {/*      onChange={(event) => this.changeEmployee(event)}*/}
                {/*  >*/}
                {/*    <option value={'null'} disabled>{__('select_team')}</option>*/}
                {/*    <option value={'all'} selected={this.state.selected === 'all'}>{__('select_team_all')}</option>*/}
                {/*    <option value={'team'} selected={this.state.selected === 'team'}>{Data.data.chosenTeamObj.team_naam}</option>*/}
                {/*    <option value={'null'} disabled>&nbsp;</option>*/}
                {/*    <option value={'null'} disabled>{__('select_employee')}</option>*/}
                {/*    {this.getEmployeeSelects()}*/}
                {/*  </select>*/}
                {/*</div>*/}

                {/* {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                  <div className={`employees_menuButton ${this.state.selected === 'team' ? 'activeAv' : ''}`} onClick={() => this.selectUser('team', '')}>
                    <div style={{fontWeight: 'bold'}}>Team</div>
                  </div>

                : null}

                <div className={`employees_menuButton ${this.state.selected === 'all' ? 'activeAv' : ''}`} onClick={() => this.selectUser('all', '')}>
                  <div style={{fontWeight: 'bold'}}>Alle medewerkers</div>
                </div> */}

                {this.getUsersMenu()}

              </div>
            : null}

            {this.state.selected !== 'all' && this.state.selected !== 'team' ?

              // height: 'calc(100vh - 6vw - 1px)'
              <div className='employees_menu2' style={{ zIndex: 1 }}>

                <div className='employees_menuTitle'>
                  <div>{__('misc_menu')}</div>
                </div>

                <div className='employees_menuSubTitle'>
                  <div>{__('misc_employee')}</div>
                </div>

                <div className={`employees_menuButton ${this.state.menu === 'account' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('account')}>
                  <div>{__('misc_account')}</div>
                </div>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?
                  <div className={`employees_menuButton ${this.state.menu === 'teams' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('teams')}>
                    <div>{__('misc_teams')}</div>
                    {hasTeam === false ?
                      <i className="fas fa-exclamation-triangle triangle" style={{ color: Colors.color.redFilledIn, fontSize: '0.6vw' }} />
                    : null}
                  </div>
                : null}

                {Data.data.appOptions.availability === 'STANDARD' ?
                  <div className={`employees_menuButton ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('availability')}>
                    <div>{__('misc_availability')}</div>
                  </div>
                : null}

                <div className={`employees_menuButton ${this.state.menu === 'info' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('info')}>
                  <div>{__('misc_data')}</div>
                </div>

                <div className='employees_menuSubTitle'>
                  <div>{__('misc_contract')}</div>
                </div>

                {Data.data.appOptions.opties_versie !== '0' ?
                  <div className={`employees_menuButton ${this.state.menu === 'contract' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('contract')}>
                    <div>{__('misc_contract')}</div>
                  </div>
                : null}

                {/* <div className={`employees_menuButton ${this.state.menu === 'salary' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('salary')}>
                  <div>Salaris</div>
                </div> */}

                <div className={`employees_menuButton ${this.state.menu === 'function' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('function')}>
                  <div>{__('misc_job')}</div>
                  {hasJob === false ?
                    <i className="fas fa-exclamation-triangle triangle" style={{ color: Colors.color.redFilledIn, fontSize: '0.6vw' }} />
                  : null}
                </div>

                <div className={`employees_menuButton ${this.state.menu === 'workdays' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('workdays')}>
                  <div>{__('misc_workingDays')}</div>
                </div>

                {((Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && has_a_leavebalance) || (Constants.isTrue(Data.data.appOptions.opties_overuren) && (has_a_minmax || has_a_fixedHours))) ?
                  <div className='employees_menuSubTitle'>
                    <div>{__('misc_balances')}</div>
                  </div>
                : null}

                {(Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) && (has_a_leavebalance) ?
                  <div className={`employees_menuButton ${this.state.menu === 'leavebalances' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('leavebalances')}>
                    <div>{__('misc_leaveBalance')}</div>
                  </div>
                : null}

                {(Constants.isTrue(Data.data.appOptions.opties_overuren)) && (has_a_fixedHours || has_a_minmax) ?
                  <div className={`employees_menuButton ${this.state.menu === 'overhours' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('overhours')}>
                    <div>{__('misc_plusMinus')}</div>
                  </div>
                : null}

                {Data.data.appOptions.opties_versie !== '0' ?
                  <div>
                    <div className='employees_menuSubTitle'>
                      <div>{__('misc_workingFiles')}</div>
                    </div>

                    <div className={`employees_menuButton ${this.state.menu === 'documents' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('documents')}>
                      <div>{__('misc_docs')}</div>
                    </div>

                    <div className={`employees_menuButton ${this.state.menu === 'notes' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('notes')}>
                      <div>{__('misc_notes')}</div>
                    </div>

                    <div className={`employees_menuButton ${this.state.menu === 'reports' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('reports')}>
                      <div>{__('misc_overviews')}</div>
                    </div>
                  </div>
                : null}

                <div className='employees_menuSubTitle'>
                  <div>{__('misc_settings')}</div>
                </div>

                {Data.data.appOptions.opties_versie !== '0' ?
                  <div className={`employees_menuButton ${this.state.menu === 'skills' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('skills')}>
                    <div>{__('misc_skills')}</div>
                  </div>
                : null}

                {Data.data.appOptions.opties_versie !== '0' ?
                  <div className={`employees_menuButton ${this.state.menu === 'default_workdays' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('default_workdays')}>
                    <div>{__('misc_defaultWorkingTimes')}</div>
                  </div>
                : null}

                <div className={`employees_menuButton ${this.state.menu === 'settings' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('settings')}>
                  <div>{__('misc_settings')}</div>
                </div>

                {/* <div className={`employees_menuButton ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('absence')}>
                  <div>Verlof</div>
                </div>

                <div className={`employees_menuButton ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('availability')}>
                  <div>Beschikbaarheid</div>
                </div> */}

              </div>

            : null}

            {(this.state.selected !== 'all' && this.state.selected !== 'team') && this.state.menu === 'contract' ?

              <div className='employees_menu2' style= {{ zIndex: 0 }}>

                <div className='employees_menuTitle'>
                  <div>{__('misc_contract')}</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'contract' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('contract')}>
                  <div>{__('misc_contractData')}</div>
                </div>

                {/* onClick={() => this.selectSubMenu('generate')} */}
                <div className={`employees_menuButton_disabled ${this.state.subMenu === 'generate' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('generate')}> 
                  <div>{__('misc_generate')}</div>
                  {/* {Data.data.appOptions.opties_versie !== '2' ? */}
                    <i className='far fa-clock' />
                  {/* : null} */}
                </div>

              </div>

            : null}

            {(this.state.selected !== 'all' && this.state.selected !== 'team') && this.state.menu === 'reports' ?

              <div className='employees_menu2' style= {{ zIndex: 0 }}>

                <div className='employees_menuTitle'>
                  <div>{__('misc_overviews')}</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('general')}>
                  <div>{__('misc_general')}</div>
                </div>

                {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? 
                  <div className={`employees_menuButton ${this.state.subMenu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('availability')}>
                    <div>{__('misc_availability')}</div>
                  </div>
                : null}

                <div className={`employees_menuButton ${this.state.subMenu === 'trades' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('trades')}>
                  <div>{__('misc_trade')}</div>
                </div>

                <div className='employees_menuSubTitle'>
                  <div>{__('misc_absenceLeave')}</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'absences' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('absences')}>
                  <div>{__('misc_absence')}</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'holidays' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('holidays')}>
                  <div>{__('misc_leave')}</div>
                </div>

                {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                  <div>
                    <div className='employees_menuSubTitle'>
                      <div>{__('misc_finances')}</div>
                    </div>
                    <div className={`employees_menuButton ${this.state.subMenu === 'finances' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('finances')}>
                      <div>{__('misc_finances')}</div>
                    </div>
                  </div>
                : null}

                {Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) ?
                  <div>
                    <div className='employees_menuSubTitle'>
                      <div>{__('misc_timeRegistration')}</div>
                    </div>
                    <div className={`employees_menuButton ${this.state.subMenu === 'times' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('times')}>
                      <div>{__('misc_timeRegistration')}</div>
                    </div>
                  </div>
                : null}

                {has_a_flex || has_a_minmax ?
                  <div className='employees_menuSubTitle'>
                    <div>{__('misc_contracts')}</div>
                  </div>
                : null}

                {has_a_flex ?
                  <div className={`employees_menuButton ${this.state.subMenu === 'flex' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('flex')}>
                    <div>{__('misc_flexHours')}</div>
                  </div>
                : null}

                {has_a_minmax ?
                  <div className={`employees_menuButton ${this.state.subMenu === 'minmax' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('minmax')}>
                    <div>{__('misc_minMaxHours')}</div>
                  </div>
                : null}

              </div>

            : null}

            <div className='employees_content2' style={this.state.selected === 'all' || this.state.selected === 'team' ? {width: '100%'} : this.state.menu === 'contract' || this.state.menu === 'reports' ? {width: 'calc(61% - 0px)'} : null}>

              {this.state.loading === true ?

                <div className='loader'>
                  <div className="spinner">
                      <div className="double-bounce1"></div>
                      <div className="double-bounce2"></div>
                  </div>
                </div>

              :

                <div className={this.state.selected === 'all' || this.state.selected === 'team' ? 'employees_container' : 'employees_container2'} style={this.state.menu === 'stats' ? {backgroundColor: 'transparent', paddingTop: 0} : null}>

                  {this.state.selected !== 'all' && this.state.selected !== 'team' ?
                    <Breadcrumb 
                      history={this.props.history}
                      items={
                        [
                          { name: __('misc_employees'), action: this.selectUser.bind(this, 'all', '', true), active: false },
                          { name: this.state.selectedName, path: null, active: true },
                        ]
                      }
                    />
                  : null}

                  {this.state.selected === 'all' || this.state.selected === 'team' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_employees')}</div>

                    <div style={{marginTop: '0.5vw'}}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{width: '13%'}}>
                          <span>{__('employees_active')}:</span>
                        </div>
                        <div style={{width: '87%'}}>
                          <b>{this.state.activeEmployees.length - 1}</b> {/*/{Data.data.maxEployees}*/}
                        </div>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0.2vw'}}>
                        <div style={{width: '13%'}}>
                          <span>{__('employees_inactive')}:</span>
                        </div>
                        <div style={{width: '87%'}}>
                          <b>{this.state.inactiveEmployees.length}</b> {/*/{Data.data.maxEployees}*/}
                        </div>
                      </div>
                      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0.2vw'}}>
                        <div style={{width: '13%'}}>
                          <span>Geblokkeerde medewerkers:</span>
                        </div>
                        <div style={{width: '87%'}}>
                          <b>{this.state.bannedEmployees.length}</b>
                        </div>
                      </div> */}
                    </div>

                    <div className="employees_addButtons_container" style={{marginTop: '2vw'}}>

                        <div className="planner_new_addButton2" style={{marginLeft: 0}} onClick={() => this.navBar.current.openRegistrationMenu()}>{__('employees_button_register')}</div>

                        <div className="planner_new_addButton2" style={{}} onClick={() => this.openEmployeesModal()}>{__('employees_button_import')}</div>

                        <EmployeesDownloadName dataSet={exportAccs} dataSet2={exportAccs_all} />

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                      {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?
                        <div style={{width: '14%', marginLeft: 0}}>
                          <select
                            name={__('select_team2')}
                            className={'hr_dropdown'}
                            onChange={(event) => this.changeTeam(event)}
                          >
                            <option value={'null'} disabled>{__('select_team')}</option>
                            <option value={'all'} selected={this.state.selected === 'all'}>{__('select_team_all')}</option>
                            <option value={'team'} selected={this.state.selected === 'team'}>{Data.data.chosenTeamObj.team_naam}</option>
                          </select>
                        </div>
                      : null}

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_contract')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeContract(event)}
                        >
                          <option value={'null'} disabled>{__('select_contract')}</option>
                          <option value={'all'} selected={this.state.selectedContract === 'all'}>{__('select_contract_all')}</option>
                          <option value={'0'} selected={this.state.selectedContract === '0'}>{__('select_contract_flex')}</option>
                          <option value={'1'} selected={this.state.selectedContract === '1'}>{__('select_contract_minMax')}</option>
                          <option value={'2'} selected={this.state.selectedContract === '2'}>{__('select_contract_fixed')}</option>
                        </select>
                      </div>

                      <div style={{width: '14%', marginLeft: 0}}>
                        <select
                          name={__('select_employee')}
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeEmployee(event)}
                        >
                          <option value={'null'} disabled>{__('select_employee')}</option>
                          <option value={'all'} selected={this.state.selected === 'all'}>{__('select_employee_all')}</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                    </div>

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub5' style={{ flex: 0.3, paddingLeft: '0.5%', justifyContent: 'flex-start', alignItems: 'center' }}>
                        &nbsp;
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        <span>{__('misc_name')}</span>
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        <span>{__('misc_data_address')}</span>
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        <span>{__('misc_data_birth')}</span>
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        <span>{__('misc_data_email')}</span>
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        <span>{__('misc_data_phone')}</span>
                      </div>

                      <div className='hr_hoursBar_sub2' style={{flex: 0.8}}>
                        <span>{__('misc_data_username')}</span>
                      </div>

                      {/* <div className='hr_hoursBar_sub2'>
                        <span>Rechten</span>
                      </div> */}

                      {Data.data.teams.length > 0 && Data.data.chosenTeam !== null ?

                        <div className='hr_hoursBar_sub2'>

                          <span>{__('misc_team')}</span>

                        </div>

                      : null}

                      <div className='hr_hoursBar_sub3' style={{flex: 0.2}}>
                        &nbsp;
                      </div>

                      <div className='hr_hoursBar_sub3' style={{flex: 0.2}}>
                        &nbsp;
                      </div>

                    </div>

                    {this.getAccountBars()}

                  </div>

                  :

                  this.state.menu === 'account' ?

                    <div>

                      {/* <div className={'employees_content_header'}>

                        <div className='employees_content_header_row'>

                            <div className='employees_content_header_1'>

                            {this.state.changed === true ?
                                <span className='planner_publishMenu_button' onClick={() => this.undoChanges()}>Wijzigingen ongedaan maken</span>
                            : null}

                            </div>

                            <div className='employees_content_header_2'>

                                <span className='planner_publishMenu_deButton' onClick={() => this.deleteAlert()}>Verwijderen</span> <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveAccAlert()}>Opslaan</span>

                            </div>

                        </div>

                        {this.showError()}

                      </div> */}

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw' }}>Account</div>

                      <div className="planner_addButtons_container" style={{marginLeft: '1vw', marginTop: '1vw'}}>

                        <div className='planner_new_publishButton2' style={{marginLeft: 0}} onClick={() => this.saveAccAlert()}>{__('button_save')}</div>

                      </div>

                      {this.showError()}

                      <div className='employees_content_container' style={{}}>

                        <div className='employees_content_container_sub'>

                            <div>{__('misc_data_username')}:</div>

                            <input
                                className={'inputField2'}
                                maxLength={30}
                                value={this.state.userData.account_username}
                                onChange={(event) => this.onChangeTextData('username', event)}
                                placeholder={__('misc_data_username')}
                                autoComplete={'new-password'}
                            />

                            <span style={{marginTop: 5, fontSize: '0.8vw'}} onClick={() => this.setState({showPassContainer: !this.state.showPassContainer})} className='defaultLinkO'>{__('employees_button_changePass')}</span>

                            {this.state.showPassContainer === true ?

                                <div style={{marginTop: 10, marginBottom: 25}} className='accounts_modal_passContainer'>

                                    <div>{__('misc_data_password')}: *</div>

                                    <input
                                        className={'inputField2'}
                                        type={'password'}
                                        value={this.state.password1}
                                        onChange={(event) => this.onChangeText('pw1', event)}
                                        placeholder={__('misc_data_password')}
                                        autoComplete={'new-password'}
                                    />

                                    <div style={{marginTop: 10}}>{__('misc_data_passwordConfirm')}: *</div>

                                    <input
                                        className={'inputField2'}
                                        type={'password'}
                                        value={this.state.password2}
                                        onChange={(event) => this.onChangeText('pw2', event)}
                                        placeholder={__('misc_data_passwordConfirm')}
                                        autoComplete={'new-password'}
                                    />

                                </div>

                            : null}

                            <div style={{marginTop: 10}}>{__('misc_data_accountRights')}:</div>

                            <select
                                name={__('select_rights')}
                                className={'dropDownField'}
                                style={{marginTop: 5}}
                                onChange={(event) => this.selectRight(event)}
                            >
                                <option value={'0'} selected={this.state.rights === 0}>{__('select_rights_employee')}</option>
                                <option value={'1'} selected={this.state.rights === 1}>{__('select_rights_planner')}</option>
                                <option value={'2'} selected={this.state.rights === 2}>{__('select_rights_admin')}</option>
                            </select>

                        </div>

                        <div className='employees_content_container_sub'>

                          {/* <div className='accounts_modal_passButton' onClick={() => this.setState({showPassContainer: !this.state.showPassContainer})}>Wijzig wachtwoord</div>

                           */}

                        </div>

                      </div>

                    </div>

                    :

                    this.state.menu === 'teams' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', marginTop: '1vw'}}>{__('misc_teams')}</div>

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw'}}>

                          {this.showTeams()}

                        </div>

                      </div>

                    :

                    this.state.menu === 'salary' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw'}}>{__('misc_salary')}</div>

                        <span style={{marginLeft: '1vw', fontSize: '0.8vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'general', subMenu: 'salaries' })} className='defaultLinkO'>{__('employees_button_manageWageModel')}</span>

                        <div style={{marginTop: '1vw'}}>

                          <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw'}}>
                            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                              <div>{__('misc_salaryModel')}</div>
                            </div>
                            <div style={{display: 'flex', flex: 2, justifyContent: 'flex-end'}}>
                              <select
                                  name={__('select_wageModel')}
                                  className={'dropDownField'}
                                  style={{width: '100%', fontSize: '0.8vw'}}
                                  onChange={(event) => this.selectSalaryModel(event)}
                              >
                                  <option value={'null'} selected={this.state.useSalaryModel === null}>{__('misc_none')}</option>
                                  {this.getSalaryModelBars()}
                              </select>
                            </div>
                          </div>

                          <div style={{paddingLeft: '0.5%', paddingRight: '0.5%', width: '40%', display: 'flex', flexDirection: 'column', marginLeft: '1vw'}}>

                            <div style={{marginTop: 10}}>{__('misc_brutoHour')}:</div>

                            <input
                                className={this.state.useSalaryModel !== null ? 'inputField2Disabled' : 'inputField2'}
                                min={1}
                                max={1000}
                                value={this.state.userSalary}
                                onChange={(event) => this.onChangeNumberData('salary', 0, 1000, event)}
                                placeholder={__('misc_brutoHour')}
                                type={'number'}
                                step={0.01}
                                disabled={this.state.useSalaryModel !== null}
                            />

                          </div>

                        </div>

                      </div>

                    :

                    this.state.menu === 'function' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw'}}>{__('misc_job')}</div>

                        <span style={{marginLeft: '1vw', fontSize: '0.8vw'}} onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'functions' })} className='defaultLinkO'>{__('employees_button_manageJob')}</span>

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw'}}>

                          <div style={{fontSize: '1vw', fontWeight: 'bold', marginLeft: '1vw'}}>{__('employees_primeJob')}</div>

                          <select
                              name={__('select_job')}
                              className={'dropDownField'}
                              style={{marginTop: 5, width: '40%', marginLeft: '1vw'}}
                              onChange={(event) => this.selectFunction_function(event)}
                          >
                              <option value={null} selected>{__('select_job')}</option>
                              {functions}
                          </select>

                          <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw', marginBottom: 8, marginLeft: '1vw'}}>{__('employees_secondJob')}</div>

                          {this.showSecondaryFunctions()}

                        </div>

                      </div>

                    :

                    this.state.menu === 'settings' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', width: '100%', position: 'relative'}}>
                          {__('misc_settings')}
                        </div>

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw'}}>

                          {this.showSettings()}

                        </div>

                        {Constants.isEmpty(this.state.employeeStatus) || this.state.employeeStatus === '1' ?

                          <div className='employees_delete_line' style={{width: '50%', marginLeft: '1vw'}} onClick={() => this.lockAlert(true)}>

                            <div style={{display: 'flex', flex: 10, flexDirection: 'column'}}>
                              <span><b>{__('employees_put_inactive_title')}</b></span>
                              <div style={{fontSize: '0.6vw'}}>{__('employees_put_inactive')}</div>
                            </div>

                            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                              <i className='far fa-user-lock' />
                            </div>

                          </div>

                        :

                          <div className='employees_delete_line' style={{width: '50%', marginLeft: '1vw', color: Colors.color.greenFilledIn}} onClick={() => this.lockAlert(false)}>

                            <div style={{display: 'flex', flex: 10, flexDirection: 'column'}}>
                              <span><b>{__('employees_put_active_title')}</b></span>
                              <div style={{fontSize: '0.6vw'}}>{__('employees_put_active')}</div>
                            </div>

                            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                              <i className='far fa-user-unlock' />
                            </div>

                          </div>

                        }

                        {/*Temporarily remove -- employees that are deleted should still be stored in the database*/}
                        {/*<div className='employees_delete_line' style={{width: '50%', marginLeft: '1vw'}} onClick={() => this.deleteAlert()}>*/}

                        {/*  <div style={{display: 'flex', flex: 10, flexDirection: 'column'}}>*/}
                        {/*    <span><b>{__('employees_delete_employee_title')}</b></span>*/}
                        {/*    <div style={{fontSize: '0.6vw'}}>{__('employees_delete_employee')}</div>*/}
                        {/*  </div>*/}

                        {/*  <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>*/}
                        {/*    <i className='far fa-user-slash' />*/}
                        {/*  </div>*/}

                        {/*</div>*/}

                      </div>

                    :

                    this.state.menu === 'documents' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', width: '100%', position: 'relative'}}>
                          {__('misc_docs')}
                        </div>

                        <div className='employees_doc_uploadRow'>

                          <input ref={(ref) => this.uploadInput=ref} type="file" onChange={(event) => this.setState({file: event.target.files[0]})} accept={'.xls,.xlsx,.doc,.docx,.pdf,.jpg,.png'} />

                          {this.state.file !== null ?
                            <span className='planner_publishMenu_button' onClick={() => this.uploadFile()}>{__('button_upload')}</span>
                          : null}
                          {this.state.file !== null ?
                            <span className='planner_publishMenu_deButton' style={{marginLeft: '1vw'}} onClick={() => { this.uploadInput.value = null; this.setState({file: null, uploadError: ''}); }}>{__('button_delete')}</span>
                          : null}

                        </div>

                        {this.state.uploadError.length > 0 ?
                          <span className='errorText' style={{marginLeft: '1vw'}}>{this.state.uploadError}</span>
                        : null}

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw', marginLeft: '1vw'}}>

                          <div className='employees_doc_row'>

                            <div className='employees_doc_col'>
                              <b>{__('misc_fileName')}</b>
                            </div>

                            {/* <div className='employees_doc_col2'>
                              <b></b>
                            </div> */}

                            <div className='employees_doc_col3'>
                              <b>{__('employees_docs_signedBy')}</b>
                            </div>

                            <div className='employees_doc_col4'>
                              <b>{__('misc_actions')}</b>
                            </div>

                            {/* <input type="file" onChange={(event) => this.setState({file: event.target.files[0]})} accept={'.xls,.xlsx,.doc,.docx,.pdf,.jpg,.png'} />
                            <button type="submit" onClick={() => this.uploadFile()}>Upload File</button> */}

                          </div>

                          {this.getDocumentBars()}

                        </div>

                      </div>

                    :

                    this.state.menu === 'availability' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', width: '100%', position: 'relative'}}>
                          {__('misc_availability')}
                        </div>

                        <div className="planner_addButtons_container" style={{marginLeft: '1vw', marginTop: '1vw'}}>

                          <div className='planner_new_publishButton2' style={{marginLeft: 0}} onClick={() => this.saveAllStandardAvailability()}>{__('button_save')}</div>

                        </div>

                        {this.showError()}

                        {/* <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw'}}> */}

                          {this.showStandardAvailability()}

                        {/* </div> */}

                      </div>

                    :

                    this.state.menu === 'skills' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw'}}>{__('misc_skills')}</div>

                        <span style={{marginLeft: '1vw', fontSize: '0.8vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'skills' })} className='defaultLinkO'>{__('employees_button_manageSkills')}</span>

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw'}}>

                          {this.showSkills()}

                        </div>

                      </div>

                    :

                    this.state.menu === 'workdays' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw'}}>{__('misc_workingDays')}</div>

                        {/* <span style={{marginLeft: '1vw', fontSize: '0.8vw'}} onClick={() => this.resetToGeneralWorkDays()} className='defaultLinkO'>Werkdagen terugzetten naar algemene werkdagen</span> */}

                        {/* <div className='employees_contentBox' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', marginLeft: '1vw', marginTop: '1vw'}}>
                          <div style={{display: 'flex', flex: 7, flexDirection: 'column'}}>
                            <div>Gebruik algemene werkdagen</div>
                            <div><span className='defaultLinkO' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'general', subMenu: 'days' })}>Beheer algemene werkdagen</span></div>
                          </div>

                          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={this.state.useWorkWeek}
                                    onClick={() => this.setWorkWeek()}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>
                          </div>
                        </div> */}

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw', marginLeft: '1vw'}}>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekMonday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('mon')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('mon')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('mon')}>{__('misc_monday')}</span>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekTuesday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('tue')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('tue')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('tue')}>{__('misc_tuesday')}</span>

                            </div>

                          </div>

                           <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekWednesday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('wed')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('wed')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('wed')}>{__('misc_wednesday')}</span>

                            </div>

                          </div>

                           <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekThursday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('thu')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('thu')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('thu')}>{__('misc_thursday')}</span>

                            </div>

                          </div>

                           <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekFriday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('fri')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('fri')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('fri')}>{__('misc_friday')}</span>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekSaturday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sat')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sat')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('sat')}>{__('misc_saturday')}</span>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                            <div className='smartPlanModal-settingRowSub1'>

                              {this.state.workWeekSunday === false ?

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sun')}>
                                  <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                                </div>

                                :

                                <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sun')}>
                                  <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                                </div>

                              }

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                              <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('sun')}>{__('misc_sunday')}</span>

                            </div>

                          </div>

                        </div>

                      </div>

                    :

                    this.state.menu === 'default_workdays' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', width: '14vw', position: 'relative'}}>

                          {__('misc_defaultWorkingTimes')}

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>{__('employees_fixedShifts_sub')}</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: 0}}
                            position={'top'}
                            followCursor
                            arrow
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.7vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {/* <span style={{marginLeft: '1vw', fontSize: '0.8vw'}} onClick={() => this.resetToGeneralWorkDays()} className='defaultLinkO'>Werkdagen terugzetten naar algemene werkdagen</span> */}

                        <div><span style={{marginLeft: '1vw', fontSize: '0.8vw'}} className='defaultLinkO' onClick={() => this.selectMenu('workdays')}>{__('employees_button_manageWorkingDays')}</span></div>

                        <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw', marginLeft: '1vw'}}>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekMonday === false ? 0.5 : 1}}>{__('misc_monday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw1']=ref}
                                className={this.state.workWeekMonday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 1, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 1, 'default_workdays')}
                                value={this.state.defaultWorkdays[1]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekMonday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb1']=ref}
                                className={this.state.workWeekMonday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 1, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 1, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[1]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekMonday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekMonday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 1)}
                                disabled={this.state.workWeekMonday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[1] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(1)}
                              </select>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekTuesday === false ? 0.5 : 1}}>{__('misc_tuesday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw2']=ref}
                                className={this.state.workWeekTuesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 2, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 2, 'default_workdays')}
                                value={this.state.defaultWorkdays[2]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekTuesday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb2']=ref}
                                className={this.state.workWeekTuesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 2, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 2, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[2]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekTuesday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekTuesday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 2)}
                                disabled={this.state.workWeekTuesday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[2] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(2)}
                              </select>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekWednesday === false ? 0.5 : 1}}>{__('misc_wednesday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw3']=ref}
                                className={this.state.workWeekWednesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 3, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 3, 'default_workdays')}
                                value={this.state.defaultWorkdays[3]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekWednesday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb3']=ref}
                                className={this.state.workWeekWednesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 3, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 3, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[3]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekWednesday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekWednesday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 3)}
                                disabled={this.state.workWeekWednesday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[3] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(3)}
                              </select>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekThursday === false ? 0.5 : 1}}>{__('misc_thursday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw4']=ref}
                                className={this.state.workWeekThursday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 4, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 4, 'default_workdays')}
                                value={this.state.defaultWorkdays[4]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekThursday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb4']=ref}
                                className={this.state.workWeekThursday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 4, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 4, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[4]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekThursday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekThursday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 4)}
                                disabled={this.state.workWeekThursday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[4] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(4)}
                              </select>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekFriday === false ? 0.5 : 1}}>{__('misc_friday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw5']=ref}
                                className={this.state.workWeekFriday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 5, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 5, 'default_workdays')}
                                value={this.state.defaultWorkdays[5]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekFriday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb5']=ref}
                                className={this.state.workWeekFriday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 5, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 5, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[5]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekFriday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekFriday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 5)}
                                disabled={this.state.workWeekFriday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[5] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(5)}
                              </select>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekSaturday === false ? 0.5 : 1}}>{__('misc_saturday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw6']=ref}
                                className={this.state.workWeekSaturday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 6, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 6, 'default_workdays')}
                                value={this.state.defaultWorkdays[6]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekSaturday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb6']=ref}
                                className={this.state.workWeekSaturday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 6, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 6, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[6]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekSaturday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekSaturday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 6)}
                                disabled={this.state.workWeekSaturday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[6] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(6)}
                              </select>

                            </div>

                          </div>

                          <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '60%'}}>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                              <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekSunday === false ? 0.5 : 1}}>{__('misc_sunday')}</span>

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                              <input
                                ref={(ref) => this.timeRef['dw0']=ref}
                                className={this.state.workWeekSunday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTime(event, 0, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 0, 'default_workdays')}
                                value={this.state.defaultWorkdays[0]}
                                placeholder={__('misc_startEndTime')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekSunday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 1, paddingLeft: '2%'}}>

                              <input
                                ref={(ref) => this.timeRef['dwb0']=ref}
                                className={this.state.workWeekSunday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                onChange={(event) => this.onChangeTimeBreak(event, 0, true, 'default_workdays')}
                                onKeyDown={(event) => this.handleKey(event, 0, 'default_workdays')}
                                value={this.state.defaultWorkdays_breaks[0]}
                                placeholder={__('misc_break')}
                                style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                disabled={this.state.workWeekSunday === false}
                              />

                            </div>

                            <div className='smartPlanModal-settingRowSub2' style={{flex: 3, paddingLeft: '2%'}}>

                              <select
                                name={__('select_job')}
                                className={this.state.workWeekSunday === true ? 'hr_dropdown2' : 'hr_dropdown2Disabled'}
                                onChange={(event) => this.changeWorkdayFunction(event, 0)}
                                disabled={this.state.workWeekSunday === false}
                              >
                                <option value={'null'} selected={this.state.defaultWorkdays_functions[0] === ''} disabled>{__('select_job')}</option>
                                {this.getWorkdayFunctionSelects(0)}
                              </select>

                            </div>

                          </div>

                        </div>

                      </div>

                    :

                    this.state.menu === 'stats' ?

                      <div>

                        <div className='employees_content_container' style={{marginLeft: '1vw', marginTop: '1vw', marginBottom: '2vw'}}>
                          <span style={{fontSize: '1.5vw', fontWeight: 'bold', flex: 1}}>Statistieken</span>
                          <div style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', fontSize: '1.5vw'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.nextYear(false)}>
                              <i className='far fa-chevron-left' />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '2vw', marginRight: '2vw'}}>
                              <span>{this.state.statYear}</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.nextYear(true)}>
                              <i className='far fa-chevron-right' />
                            </div>
                          </div>
                        </div>

                        <div className='employees_content_container_sub'>

                          <div className='employees_contentBoxYear'>

                            <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{this.state.statYear}</span>

                            {this.renderStatsYear()}

                          </div>

                        </div>

                        <div className='employees_content_container'>

                          <div className='employees_content_container_sub'>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Januari</span>

                              {this.renderStats('jan')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Maart</span>

                              {this.renderStats('mrt')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Mei</span>

                              {this.renderStats('mei')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Juli</span>

                              {this.renderStats('jul')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>September</span>

                              {this.renderStats('sept')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>November</span>

                              {this.renderStats('nov')}

                            </div>

                          </div>

                          <div className='employees_content_container_sub'>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Februari</span>

                              {this.renderStats('feb')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>April</span>

                              {this.renderStats('apr')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Juni</span>

                              {this.renderStats('jun')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Augustus</span>

                              {this.renderStats('aug')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Oktober</span>

                              {this.renderStats('okt')}

                            </div>

                            <div className='employees_contentBoxMonth'>

                              <span style={{fontSize: '1.5vw', fontWeight: 'bold'}}>December</span>

                              {this.renderStats('dec')}

                            </div>

                          </div>

                        </div>

                      </div>

                    :

                    this.state.menu === 'info' ?

                    <div>

                        {/* <div className={'employees_content_header'}>

                            <div className='employees_content_header_row'>

                                <div className='employees_content_header_1'>

                                {this.state.changed === true ?
                                    <span className='planner_publishMenu_button' onClick={() => this.undoChanges()}>Wijzigingen ongedaan maken</span>
                                : null}

                                </div>

                                <div className='employees_content_header_2'>

                                    <span className='planner_publishMenu_buttonOpen' onClick={() => this.saveAccAlert()}>Opslaan</span>

                                </div>

                            </div>

                            {this.showError()}

                        </div> */}

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', }}>{__('misc_data_userData')}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '1vw', marginTop: '1vw'}}>

                          <div className='planner_new_publishButton2' style={{marginLeft: 0}} onClick={() => this.saveAccAlert()}>{__('button_save')}</div>

                        </div>

                        {this.showError()}

                        <div className='employees_content_container'>

                            <div className='employees_content_container_sub'>

                                <div>{__('misc_data_firstName')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={50}
                                    value={this.state.userData.info_voornaam}
                                    onChange={(event) => this.onChangeTextData('firstname', event)}
                                    placeholder={__('misc_data_firstName')}
                                    style={{textTransform: 'capitalize'}}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_insertion')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={50}
                                    value={this.state.userData.info_tussenvoegsel}
                                    onChange={(event) => this.onChangeTextData('insertion', event)}
                                    placeholder={__('misc_data_insertion')}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_lastName')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={50}
                                    value={this.state.userData.info_achternaam}
                                    onChange={(event) => this.onChangeTextData('lastname', event)}
                                    placeholder={__('misc_data_lastName')}
                                    style={{textTransform: 'capitalize'}}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_birth')}:</div>

                                <ReactDatePicker
                                    selected={Constants.stringToDate(this.state.userData.info_geboorte)}
                                    onChange={(date) => this.setBirthDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    className={'inputFieldDate'}
                                    placeholderText={__('misc_data_birth')}
                                    showWeekNumbers
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                    locale={nl}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_birthPlace')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={100}
                                    value={this.state.userData.info_geboorteplaats}
                                    onChange={(event) => this.onChangeTextData('birthplace', event)}
                                    placeholder={__('misc_data_birthPlace')}
                                />

                                {Data.data.appOptions.iban === true && Data.data.accountData.account_rights === '2' ?

                                <div>

                                    <div style={{marginTop: 10}}>{__('misc_data_bank')}:</div>

                                    <input
                                        className={'inputField2'}
                                        maxLength={25}
                                        value={this.state.userData.iban}
                                        onChange={(event) => this.onChangeTextData('iban', event)}
                                        placeholder={__('misc_data_bank')}
                                    />

                                </div>

                                : null}

                                {Data.data.appOptions.bsn === true && Data.data.accountData.account_rights === '2' ?

                                    <div>

                                        <div style={{marginTop: 10}}>{__('misc_data_citizen')}:</div>

                                        <input
                                            className={'inputField2'}
                                            maxLength={15}
                                            value={this.state.userData.bsn}
                                            onChange={(event) => this.onChangeTextData('bsn', event)}
                                            placeholder={__('misc_data_bank')}
                                        />

                                    </div>

                                : null}

                            </div>

                            <div className='employees_content_container_sub'>

                                <div>{__('misc_data_email')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={50}
                                    type={'email'}
                                    value={this.state.userData.info_email}
                                    onChange={(event) => this.onChangeTextData('email', event)}
                                    placeholder={__('misc_data_email')}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_phone')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={12}
                                    type={'tel'}
                                    value={this.state.userData.info_telefoon}
                                    onChange={(event) => this.onChangeTextData('phone', event)}
                                    placeholder={__('misc_data_phone')}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_phone2')}:</div>

                                <input
                                    className={'inputField2'}
                                    maxLength={12}
                                    type={'tel'}
                                    value={this.state.userData.info_telefoon2}
                                    onChange={(event) => this.onChangeTextData('phone2', event)}
                                    placeholder={__('misc_data_phone2')}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_startDate')}:</div>

                                <ReactDatePicker
                                    selected={this.isEmpty(this.state.userData.info_indienst) ? null : Constants.stringToDate(this.state.userData.info_indienst)}
                                    onChange={(date) => this.setStartDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    className={'inputFieldDate'}
                                    placeholderText={__('misc_data_startDate')}
                                    showWeekNumbers
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                    locale={nl}
                                />

                                <div style={{marginTop: 10}}>{__('misc_data_extra')}:</div>

                                <textarea
                                    className={'inputFieldBig'}
                                    maxLength={250}
                                    value={this.state.userData.info_extra}
                                    onChange={(event) => this.onChangeTextData('extra', event)}
                                />

                            </div>

                        </div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginLeft: '1vw', marginBottom: '1vw'}}>{__('misc_data_addressData')}</div>

                      <div className='employees_content_container'>

                          <div className='employees_content_container_sub'>

                              <div>{__('misc_data_address')}:</div>

                              <input
                                  className={'inputField2'}
                                  maxLength={80}
                                  value={this.state.userData.info_adres}
                                  onChange={(event) => this.onChangeTextData('address', event)}
                                  placeholder={__('misc_data_address')}
                                  style={{textTransform: 'capitalize'}}
                              />

                              <div style={{marginTop: 10}}>{__('misc_data_zip')}:</div>

                              <input
                                  className={'inputField2'}
                                  maxLength={20}
                                  value={this.state.userData.info_postcode}
                                  onChange={(event) => this.onChangeTextData('zip', event)}
                                  placeholder={__('misc_data_zip')}
                                  style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                              />

                          </div>

                          <div className='employees_content_container_sub'>

                              <div>{__('misc_data_addressHouse')}:</div>

                              <input
                                  className={'inputField2'}
                                  maxLength={10}
                                  value={this.state.userData.info_huisnummer}
                                  onChange={(event) => this.onChangeTextData('house', event)}
                                  placeholder={__('misc_data_addressHouse')}
                              />

                              <div style={{marginTop: 10}}>{__('misc_data_place')}:</div>

                              <input
                                  className={'inputField2'}
                                  maxLength={50}
                                  value={this.state.userData.info_plaats}
                                  onChange={(event) => this.onChangeTextData('city', event)}
                                  placeholder={__('misc_data_place')}
                              />

                          </div>

                      </div>

                    </div>

                    :
                    this.state.menu === 'leavebalances' ?

                      <div style={{marginLeft: '1vw', width: 'calc(100% - 2vw)'}}>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_leaveBalances')}</div>

                        <span style={{fontSize: '0.8vw'}} onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'leavebalances' })} className='defaultLinkO'>{__('employees_button_manageLeaveBalances')}</span>

                        <div className="employees_addButtons_container" style={{marginTop: '2vw'}}>

                          <div className="planner_new_addButton2" style={{marginLeft: 0}} onClick={() => this.setState({showAddHolidayModal: true})}>{__('button_add')}</div>

                        </div>

                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                          <div style={{width: '10%'}}>
                            <select
                              name={__('select_job')}
                              className={'hr_dropdown'}
                              onChange={(event) => this.leave_selectYear(event)}
                            >
                              <option value={'null'} disabled>{__('misc_year_c')}</option>
                              {this.leave_yearOptions()}
                            </select>
                          </div>

                        </div>

                        <div style={{fontSize: '1vw', fontWeight: 'bold'}}>{__('misc_balances')}</div>

                        {this.leave_renderUserLeaveBalances().length > 0 ?

                          <div className='hr_hoursBar_title' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw'}}>

                            <div className='hr_hoursBar_sub1'>
                              <b>{__('misc_name')}</b>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <b>{__('misc_balance')} ({this.state.leave_chosenYear - 1})</b>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <b>{__('misc_balance')} ({this.state.leave_chosenYear})</b>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <b>{__('misc_used')}</b>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <b>{__('misc_currentBalance')}</b>
                            </div>

                          </div>

                        : null}

                        {this.leave_renderUserLeaveBalances()}

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_corrections')}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                          <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openCorrectionModal(1)}>{__('button_add')}</div>

                        </div>

                        {this.leave_renderCorrections().length > 0 ?

                          <div className='hr_hoursBar_title' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw'}}>

                            <div className='hr_hoursBar_sub1'>
                              <span>{__('misc_balance')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_date')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_by')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_comment')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_payout')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_correction')}</span>
                            </div>

                            <div className='hr_hoursBar_sub4'>
                              <span>&nbsp;</span>
                            </div>

                          </div>

                        : null}

                        {this.leave_renderCorrections()}

                        {holidayRequestsOpen.length > 0 ?

                          <div>

                            <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_openRequests')}</div>

                            <div className='hr_hoursBar_title' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw'}}>

                              <div className='hr_hoursBar_sub1'>
                                <span>{__('misc_period')}</span>
                              </div>

                              <div className='hr_hoursBar_sub2'>
                                <span>&nbsp;</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>&nbsp;</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_comment')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                &nbsp;
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                &nbsp;
                              </div>

                              <div className='hr_hoursBar_sub4'>
                                <span>&nbsp;</span>
                              </div>

                            </div>

                            {holidayRequestsOpen}

                          </div>

                        : null}

                        {holidayRequestsReviewed.length > 0 ?

                          <div>

                            <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_gradedRequests')}</div>

                            <div className='hr_hoursBar_title' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw'}}>

                              <div className='hr_hoursBar_sub1'>
                                <span>{__('misc_period')}</span>
                              </div>

                              <div className='hr_hoursBar_sub2'>
                                <span>&nbsp;</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>&nbsp;</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_comment')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_status')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_used')}</span>
                              </div>

                              <div className='hr_hoursBar_sub4'>
                                <span>&nbsp;</span>
                              </div>

                            </div>

                            {holidayRequestsReviewed}

                          </div>

                        : null}

                      </div>

                    :
                    this.state.menu === 'overhours' ?

                      <div style={{marginLeft: '1vw', width: 'calc(100% - 2vw)'}}>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_plusMinus')}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                          <div style={{width: '10%'}}>
                            <select
                              name={__('select_job')}
                              className={'hr_dropdown'}
                              onChange={(event) => this.overhours_selectYear(event)}
                            >
                              <option value={'null'} disabled>{__('misc_year_c')}</option>
                              {this.overhours_yearOptions()}
                            </select>
                          </div>

                        </div>

                        <div className='hr_hoursBar' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw', borderBottom: 0}}>

                          <div className='hr_hoursBar_sub1' style={{justifyContent: 'center'}}>
                            <b>{__('misc_balance')} ({this.state.overhours_chosenYear - 1})</b>
                          </div>

                          <div className='hr_hoursBar_sub1' style={{justifyContent: 'center'}}>
                            <b>{__('misc_balance')} ({this.state.overhours_chosenYear})</b>
                          </div>

                          <div className='hr_hoursBar_sub1' style={{justifyContent: 'center'}}>
                            <b>{__('misc_currentBalance')}</b>
                          </div>

                        </div>

                        {this.overhours_renderUserOverhours()}

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_corrections')}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                          <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openCorrectionModal(0)}>{__('button_add')}</div>

                        </div>

                        {this.overhours_renderCorrections().length > 0 ?

                          <div className='hr_hoursBar_title' style={{width: '98%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw', paddingLeft: '1%', paddingRight: '1%'}}>

                            <div className='hr_hoursBar_sub1'>
                              <span>{__('misc_date')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_by')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_comment')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_payout')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_correction')}</span>
                            </div>

                            <div className='hr_hoursBar_sub4'>
                              <span>&nbsp;</span>
                            </div>

                          </div>

                        : null}

                        {this.overhours_renderCorrections()}

                        {this.overhours_renderUserOverhoursSep().length > 0 ?

                          <div>

                            <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_accrual')}</div>

                            <div className='hr_hoursBar_title' style={{width: '98%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw', paddingLeft: '1%', paddingRight: '1%'}}>

                              <div className='hr_hoursBar_sub1'>
                                <span>{__('misc_week_c')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_contract')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_worked')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_leave')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_absence2')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_correction')}</span>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <span>{__('misc_total')}</span>
                              </div>

                            </div>

                            {this.overhours_renderUserOverhoursSep()}

                          </div>

                        : null}

                      </div>

                    :
                    this.state.menu === 'notes' ?

                      <div style={{marginLeft: '1vw', width: 'calc(100% - 2vw)'}}>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_notes')}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                          <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openNotesModal(0)}>{__('button_add')}</div>

                        </div>

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_appointments')}</div>

                        <div className='hr_hoursBar_title' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw'}}>

                          <div className='hr_hoursBar_sub2'>
                            <span>{__('misc_date')}</span>
                          </div>

                          <div className='hr_hoursBar_sub5'>
                            <span>{__('misc_time')}</span>
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            <span>{__('misc_title')}</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>{__('misc_description')}</span>
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            <span>{__('misc_by')}</span>
                          </div>

                          <div className='hr_hoursBar_sub6'>
                            <span>&nbsp;</span>
                          </div>

                          <div className='hr_hoursBar_sub6'>
                            <span>&nbsp;</span>
                          </div>

                        </div>

                        {this.renderAppointments()}

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>{__('misc_notes2')}</div>

                        <div className='hr_hoursBar_title' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold', marginTop: '0.2vw'}}>

                          <div className='hr_hoursBar_sub2'>
                            <span>{__('misc_title')}</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>{__('misc_description')}</span>
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            <span>&nbsp;</span>
                          </div>

                          <div className='hr_hoursBar_sub5'>
                            <span>&nbsp;</span>
                          </div>

                          <div className='hr_hoursBar_sub2'>
                            <span>{__('misc_by')}</span>
                          </div>

                          <div className='hr_hoursBar_sub6'>
                            <span>&nbsp;</span>
                          </div>

                          <div className='hr_hoursBar_sub6'>
                            <span>&nbsp;</span>
                          </div>

                        </div>

                        {this.renderNotes()}

                      </div>

                    :
                    this.state.menu === 'reports' ?

                      // individual_menu =  must be same as menu's in reports
                      <Reports history={this.props.history} location={this.props.location} individual={true} individual_userID={this.state.userData.info_id} individual_menu={this.state.subMenu} />

                    :
                    this.state.menu === 'contract' ?

                      this.state.subMenu === 'contract' ?

                        <div style={{marginLeft: '1vw', width: 'calc(100% - 2vw)'}}>

                          <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_contracts')}</div>

                          <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                            <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openContractModal(0)}>{__('button_add')}</div>

                          </div>

                          <div className='hr_hoursBar' style={{width: '100%', fontSize: '0.7vw', fontWeight: 'bold'}}>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_contract')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_startDate')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_endDate')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_plusMinus')}</span>
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              <span>{__('misc_leaveDays')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_hours_c')}</span>
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              <span>{__('misc_salary')}</span>
                            </div>

                            <div className='hr_hoursBar_sub4'>
                              <span>&nbsp;</span>
                            </div>

                            <div className='hr_hoursBar_sub4' style={{flex: 0.2}}>
                              <span>&nbsp;</span>
                            </div>

                            {/* <div className='hr_hoursBar_sub4' style={{flex: 0.2}}>
                              <span>&nbsp;</span>
                            </div> */}

                            {this.state.userContracts.length > 1 ?
                              <div className='hr_hoursBar_sub4' style={{flex: 0.2}}>
                                <span>&nbsp;</span>
                              </div>
                            : null}

                          </div>

                          {this.renderContracts()}

                        </div>

                      :
                      this.state.subMenu === 'generate' ?

                      <div style={{marginLeft: '1vw', width: 'calc(100% - 2vw)'}}>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Contract genereren</div>

                        <div style={{marginTop: '1vw'}}>
                          <span><b>Let op:</b> Een nieuwe template toevoegen? Stuur jouw - niet-ingevulde - arbeidsovereenkomst(en) als PDF per mail naar <b className='defaultLinkO' onClick={() => window.open('mailto:info@tiemdo.com')}>info@tiemdo.com</b>. Wij zorgen ervoor dat je zo snel mogelijk een goed werkende template van ons krijgt. Deze template kun je vervolgens hieronder uploaden.</span>
                        </div>

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>Uploaden</div>

                        <div className='employees_doc_uploadRow' style={{marginLeft: 0, marginTop: '0.5vw'}}>

                          <input ref={(ref) => this.uploadInputContract=ref} type="file" onChange={(event) => this.setState({fileContract: event.target.files[0]})} accept={'.pdf'} />

                          {this.state.fileContract !== null ?
                            <span className='planner_publishMenu_button' onClick={() => this.uploadFileContract()}>Uploaden</span>
                          : null}
                          {this.state.fileContract !== null ?
                            <span className='planner_publishMenu_deButton' style={{marginLeft: '1vw'}} onClick={() => { this.uploadInputContract.value = null; this.setState({fileContract: null, uploadError: ''}); }}>Verwijderen</span>
                          : null}

                        </div>

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>Template selecteren</div>

                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '0.5vw'}}>

                          <div style={{width: '30%', marginLeft: 0}}>
                            <select
                              name="Selecteer een template"
                              className={'hr_dropdown'}
                              onChange={(event) => this.changeContractTemplate(event)}
                            >
                              <option value={'null'} disabled selected={this.state.chosenContractTemplate === null}>Selecteer een template</option>
                              {this.getContractTemplates()}
                            </select>
                          </div>

                          {this.state.chosenContractObj !== null ?
                            <Tooltip
                              options
                              html={(
                                <div className='plannerN_tooltipContainer'>
                                  <span>Contract template verwijderen</span>
                                </div>
                              )}
                              position={'top'}
                              theme='kodict'
                              delay={300}
                              hideDelay={0}
                            >
                              <span><i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '0.6vw' }} onClick={() => this.deleteContractTemplateAlert()}/></span>
                            </Tooltip>
                          : null}

                        </div>

                        {this.state.pdfForm !== null ?

                          <div style={{marginTop: '2vw'}}>

                            <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: 0}}>

                              {/* <span className='planner_new_addButton' style={{marginLeft: 0, width: '5.5vw'}} onClick={() => this.reloadContractTemplate()}>Wijzigen</span> */}

                              <span className='planner_new_availableButton' style={{marginLeft: 0, width: '7.5vw'}} onClick={() => this.openSignContractModal()}>Ondertekenen</span>

                              <span className='planner_new_publishButton' style={{width: '5.5vw'}} onClick={() => this.saveContractDocumentsAlert()}>Opslaan</span>

                            </div>

                            <div style={{fontSize: '0.8vw', fontWeight: 'bold', marginBottom: '0.5vw', marginTop: '2vw'}}>Gevonden types</div>

                            {this.getPDFFieldBars()}

                            {/* object */}
                            <embed src={`data:application/pdf;base64,${this.state.pdfForm}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`} type="application/pdf" style={{ width: '100%', height: '80vh', marginTop: '1vw' }}>
                            </embed>

                          </div>

                        : null}

                      </div>

                      :

                        null

                  :

                    null

                  }

                </div>

              }

            </div>

          </div>

        </div>
    );
  }

}

export default Employees;