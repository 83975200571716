/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './registrationUserModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';
import '../../pages/options/reports/reports.scss';

import CryptoJS from 'crypto-js';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import APIGET from '../../classes/global/APIGET';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APICheckUsernameAvailibility from '../../classes/APICheckUsernameAvailibility';
import APIADD from '../../classes/global/APIADD';
import APIAddToTeam from '../../classes/APIAddToTeam';
import APIDELETE from '../../classes/global/APIDELETE';
import { toast } from 'react-toastify';
import APISendNewAccountMail from '../../classes/APISendNewAccountMail';

import { remove } from 'remove-accents';
import Colors from '../../constants/Colors';

class RegistrationUserModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

      loading: true,
      reloading: false,

      first: '',
      last: '',
      insertion: '',
      mail1: '',
      mail2: '',

      jobs: [],
      job: null,

      selectedTeams: [],

      rights: 0,

      errorText: '',

      sendLoginData: true,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    const jobs = await APIGetFunctions.Request();

    this.setState({ jobs: jobs, loading: false });

  }

  async checkEmailValid() {

    if (this.state.mail2 !== '') {

        const data = await APIGET.Request(`SELECT info_id`, `FROM Info`, null, `WHERE info_email = '${this.state.mail2}'`, null, null);

        if (data.length > 0) {
            return false;
        }

    }

    return true;

  }

  async checkValidations() {

    if(this.state.loading || this.state.reloading) {
      return false;
    }

    if(this.state.first === '') {
        this.setState({
            errorText: 'Je hebt geen voornaam ingevoerd',
        });
        return false;
      }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.first) === false) {
        this.setState({
            errorText: 'Voornaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
      }
    if(this.state.insertion !== '' && /^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.insertion) === false) {
        this.setState({
            errorText: 'Tussenvoegsel mag alleen letters, cijfers en - bevatten',
        });
        return false;
      }
    if(this.state.last === '') {
        this.setState({
            errorText: 'Je hebt geen achternaam ingevoerd',
        });
        return false;
      }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.last) === false) {
        this.setState({
            errorText: 'Achternaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
      }

    if(Constants.isEmpty(this.state.mail1) === false && Constants.isEmpty(this.state.mail2)) { // Constants.isEmpty(this.state.mail1) || 
      this.setState({
          errorText: 'Vul twee keer het e-mailadres in',
      });
      return false;
    }

    if(this.state.mail2 !== '' && /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.mail2) === false) {
      this.setState({
          errorText: 'Geen geldig e-mailadres',
      });
      return false;
    }

    if(this.state.mail1 !== this.state.mail2) {
      this.setState({
          errorText: 'E-mailadressen zijn niet hetzelfde',
      });
      return false;
    }

    if (Constants.isEmpty(this.state.mail1) === false && Constants.isEmpty(this.state.mail2) === false) {
      const emailValid = await this.checkEmailValid();
      if (emailValid === false) {
        this.setState({
            errorText: 'Dit e-mailadres bestaat al',
        });
        return false;
      }
    }

    if(Constants.isEmpty(this.state.job)) {
      this.setState({
          errorText: 'Je hebt geen functie geselecteerd',
      });
      return false;
    }

  }

  async registerEmployee() {

    if(this.state.loading || this.state.reloading || await this.checkValidations() === false) {
      return;
    }

    this.setState({ loading: true });

    let usernameExistsCount = 2; // so it starts with .....2 on the end like (jordyortega2)
    let _user = this.fixUsername(`${this.state.first + this.state.last}`);

    const usernameExists = await APICheckUsernameAvailibility.Request(_user);

    if (usernameExists === true) {

      _user = this.fixUsername(`${(this.state.first + this.state.last + usernameExistsCount.toString()).toLowerCase()}`);
      usernameExistsCount++;

      while(true) {

        const usernameExists2 = await APICheckUsernameAvailibility.Request(_user);

        if (usernameExists2 === false) {
          break;
        }

        _user = this.fixUsername(`${(this.state.first + this.state.last + usernameExistsCount.toString()).toLowerCase()}`);
        usernameExistsCount++;

      }

    }

    const _pass = Constants.generateString();
    const _passEncrypted = CryptoJS.SHA512(_pass).toString();

    const added1 = await APIADD.Request(`INSERT INTO Account`, `VALUES (null, '${_user}', '${_passEncrypted}', ${this.state.rights})`);

    if (added1 === false) {
      toast.error('😔 Er is iets misgegaan', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });
      this.setState({ loading: false });
      return;
    }

    const addedInfoID = (await APIGET.Request(`SELECT account_id`, `FROM Account`, null, null, null, `ORDER BY account_id DESC LIMIT 1`))[0].account_id;

    const added2 = await APIADD.Request(`INSERT INTO Info`, `VALUES (` +
      `${addedInfoID}, ` +
      `'${Constants.isEmpty(this.state.first) === false ? Constants.firstUppercase(this.state.first) : ''}', ` +
      `'${Constants.isEmpty(this.state.insertion) === false ? this.state.insertion : ''}', ` +
      `'${Constants.isEmpty(this.state.last) === false ? Constants.firstUppercase(this.state.last) : ''}', ` +
      `'', ` +
      `'${Constants.isEmpty(this.state.mail2) === false ? this.state.mail2 : ''}', ` +
      `${Constants.isEmpty(this.state.job) === false ? this.state.job : 'NULL'}, ` +
      `'', ` +
      `'', ` +
      `'', ` +
      `'', ` +
      `'2000-01-01', ` +
      `0, ` +
      `'${Constants.dateToString(new Date())}', ` +
      `'', ` +
      `'', ` +
      `'', ` +
      `'', ` +
      `'')`
    );

    if (added2 === false) {
      await APIDELETE.Request(`DELETE FROM Account`, `WHERE account_id = ${addedInfoID}`);
      toast.error('😔 Er is iets misgegaan', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });
      this.setState({ loading: false });
      return;
    }

    let promises = [];

    if (this.state.selectedTeams.length > 0) {

      for (const team of this.state.selectedTeams) {
        promises.push(APIAddToTeam.Request(team.team_id, addedInfoID));
      }

    }

    promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${addedInfoID}, 1, 1, 1, 1, 1, 1, 1, 1)`));
    promises.push(APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${addedInfoID})`));

    await Promise.all(promises);

    toast.success('😄 Medewerker is geregistreerd!', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      icon: false, 
    });

    if (this.state.sendLoginData === true && Constants.isEmpty(this.state.mail2) === false) {
      await APISendNewAccountMail.Request(this.state.mail2, _user, _pass, Data.data.storeCode);
    }

    if (this.props._liveSwitch === 'planner') {
      this.props._parent.reloadScreen2FullScreenTeam();
    } else if (this.props._liveSwitch === 'activeUsers') {
      this.props._parent.refresh();
    } else if (this.props._liveSwitch === 'finances') {
      this.props._parent.reloadData();
    } else if (this.props._liveSwitch === 'hours') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'tradeRequests') {
      this.props._parent.reloadScreen();
    } else if (this.props._liveSwitch === 'availableShifts') {
      this.props._parent.reloadScreen();
    } else if (this.props._liveSwitch === 'availabilities') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'absences') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'employees') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'plannerArrange') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'holidayRequests') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'requests') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'manage') {
      this.props._parent.componentDidMount();
    } else if (this.props._liveSwitch === 'reports') {
      this.props._parent.hardReloadScreen();
    }

    this.closeModal();

  }

  fixUsername(user) {

    let _user = '';

    // remove spaces
    _user = user.replaceAll(' ', '');

    // remove special characters
    _user.normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "");

    // last check special chars
    _user = remove(_user);

    return _user.toLowerCase();

  }

  renderJobSelects() {

    let toRender = [];

    for (const job of this.state.jobs) {

      toRender.push(
        <option value={job.functie_id} selected={this.state.job === job.functie_id}>{job.functie_naam}</option>
      );

    }

    return toRender;

  }

  selectJob(e) {

    this.setState({ job: e.target.value === 'null' ? null : e.target.value });

  }

  toggleTeamButton(team) {

    let selected = this.state.selectedTeams;

    if (Constants.objectArrayContainsKey(this.state.selectedTeams, 'team_id', team.team_id)) {
      Constants.removeFromArray2(selected, 'team_id', team.team_id);
    } else {
      selected.push(team);
    }

    this.setState({ selectedTeams: selected });

  }

  renderTeamButtons() {

    let toRender = [];

    for (const team of Data.data.teams) {

      toRender.push(

        Constants.objectArrayContainsKey(this.state.selectedTeams, 'team_id', team.team_id) ?
          <div className='reports_toggleButton_active' style={{ marginTop: '0.5vw', marginLeft: '0.6vw' }} onClick={() => this.toggleTeamButton(team)}>
            <i className='fas fa-check-circle' />
            <span>{team.team_naam}</span>
          </div>
        :
          <div className='reports_toggleButton' style={{ marginTop: '0.5vw', marginLeft: '0.6vw' }} onClick={() => this.toggleTeamButton(team)}>
            <i className='fal fa-circle' />
            <span>{team.team_naam}</span>
          </div>

      );

    }

    return toRender;

  }

  showError() {

    if(this.state.errorText !== '') {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="modal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.closeModal() : null}>

        <div className="modal-content" style={{minHeight: '15vw'}}>

          <div className="modal-header">
              <span className="modal-close" onClick={() => this.closeModal()}>&times;</span>
              <p>Medewerker registreren</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  history={this.props.history}
                />

              :

                null
          
              }

              <div className='plan_modal-form'>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                  </div>

                :

                  <div>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Accountrechten</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <select 
                              name="Selecteer een recht" 
                              className={'dropDownField'}
                              onChange={(event) => this.setState({ rights: parseInt(event.target.value) })}
                          >
                            <option value="0" selected={this.state.rights === 0}>Medewerker</option>
                            <option value="1" selected={this.state.rights === 1}>Planner</option>
                            <option value="2" selected={this.state.rights === 2}>Administrator</option>
                          </select>

                        </div>

                    </div>

                    <div className='modal-row' style={{ marginTop: '2vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>Voornaam *</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.first}
                            onChange={(event) => this.setState({ first: event.target.value, errorText: '' })}
                            placeholder={'Voornaam :'}
                            style={{ textTransform: 'capitalize' }}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{ marginTop: '1vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>Tussenvoegsel</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.insertion}
                            onChange={(event) => this.setState({ insertion: event.target.value, errorText: '' })}
                            placeholder={'Tussenvoegsel :'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{ marginTop: '1vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>Achternaam *</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.last}
                            onChange={(event) => this.setState({ last: event.target.value, errorText: '' })}
                            placeholder={'Achternaam :'}
                            style={{ textTransform: 'capitalize' }}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{ marginTop: '2vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>E-mail</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} onCut={(e) => { e.preventDefault(); return false; }}
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.mail1}
                            onChange={(event) => this.setState({ mail1: event.target.value, errorText: '' })}
                            placeholder={'E-mail :'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{ marginTop: '1vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>E-mail herhalen</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} onCut={(e) => { e.preventDefault(); return false; }}
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.mail2}
                            onChange={(event) => this.setState({ mail2: event.target.value, errorText: '' })}
                            placeholder={'Herhaal e-mail :'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{ marginTop: '2vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>Functie *</b></span>
                            {/* <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Optioneel</span> */}
                        </div>

                        <div className='modal-rowSub2'>

                          <select 
                              name="Selecteer een functie" 
                              className={'dropDownField'}
                              onChange={(event) => this.selectJob(event)}
                          >
                              <option value="null" selected={this.state.job === 'null'}>Selecteer een functie</option>
                              {this.renderJobSelects()}                       
                          </select>

                        </div>

                    </div>

                    {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                      <div className='modal-row2' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1_padding'>
                            <span><b>Teams</b></span>
                          </div>

                          <div className='modal-rowSub2'>

                            <div style={{display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap'}}>
                              {this.renderTeamButtons()}
                            </div>

                          </div>

                      </div>

                    : null}

                    <div className='modal-row' style={{ marginTop: '2vw' }}>

                        <div className='modal-rowSub1'>
                            <span><b>E-mail versturen</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Verstuur een e-mail met inloggegegevens. Indien je dit uitschakelt, kan dit later verzonden worden in Medewerkers {`>>`} -medewerker- {`>>`} Account</span>
                        </div>

                        <div className='modal-rowSub2'>

                          {this.state.sendLoginData === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setState({ sendLoginData: true })}>
                              <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                            </div>

                            :

                            <div className='smartPlanModal-checkbox' onClick={() => this.setState({ sendLoginData: false })}>
                              <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                            </div>

                          }

                        </div>

                    </div>

                  </div>

                }

                <div style={{height: '1vw', width: '100%'}} />

              </div>

          </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              <div className='modal-deleteButton' onClick={() => this.closeModal()}>
                <span>Annuleren</span>
              </div>

              <div className='modal-submitButton' onClick={() => this.registerEmployee()}>
                <span>Opslaan</span>
              </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default RegistrationUserModal;