


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APICheckCompanies {

    async Request(ids) {
        
        const hash = CryptoJS.MD5(ids + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://login-t01.tiemdo.com/phpcalls/checkCompanies.php/`, {
                method: 'post',
                body: new URLSearchParams(`ids=${ids}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {
            return sqlData;

        } else if (sqlData === null) {
            return [];

        } else {
            return []; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APICheckCompanies());