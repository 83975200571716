/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './smartPlanModal.css';
import '../startupModal/startupModal.scss';
import '../../pages/planner/planner.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';

//js components & classes
import Colors from '../../constants/Colors';
import BetterAlert from '../alert/alert';
import APIGetAllTemplates from '../../classes/APIGetAllTemplates';
import TemplateModal from './templateModal';
import Data from '../../constants/Data';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGetSections from '../../classes/APIGetSections';

class SmartPlanModal extends React.Component {

  constructor(props) {

    super(props);

	this.props = props;
    
    this.state = {

		showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
		alertButtonAction: null,
		
		loading: true,
		reloading: false,

		monday: localStorage.getItem('@smartMonday') !== null ? localStorage.getItem('@smartMonday') === '1' : true,
		tuesday: localStorage.getItem('@smartTuesday') !== null ? localStorage.getItem('@smartTuesday') === '1' : true,
		wednesday: localStorage.getItem('@smartWednesday') !== null ? localStorage.getItem('@smartWednesday') === '1' : true,
		thurday: localStorage.getItem('@smartThursday') !== null ? localStorage.getItem('@smartThursday') === '1' : true,
		friday: localStorage.getItem('@smartFriday') !== null ? localStorage.getItem('@smartFriday') === '1' : true,
		saturday: localStorage.getItem('@smartSaturday') !== null ? localStorage.getItem('@smartSaturday') === '1' : true,
		sunday: localStorage.getItem('@smartSunday') !== null ? localStorage.getItem('@smartSunday') === '1' : true,

		available: localStorage.getItem('@smartAvailable') !== null ? localStorage.getItem('@smartAvailable') === '1' : true,
		notFilled: localStorage.getItem('@smartNotFilled') !== null ? localStorage.getItem('@smartNotFilled') === '1' : false,

		noSchool: localStorage.getItem('@smartNoSchool') !== null ? localStorage.getItem('@smartNoSchool') === '1' : true,
		school: localStorage.getItem('@smartSchool') !== null ? localStorage.getItem('@smartSchool') === '1' : true,
		schoolHoliday: localStorage.getItem('@smartSchoolHoliday') !== null ? localStorage.getItem('@smartSchoolHoliday') === '1' : true,

		maxDays: localStorage.getItem('@smartMaxDays') !== null ? parseInt(localStorage.getItem('@smartMaxDays')) : 7,
		cheapest: localStorage.getItem('@smartCheapest') !== null ? localStorage.getItem('@smartCheapest') === '1' : false,

		templates: [],
		highestTempKey: 0,

		showModal: false,
		modalMode: 0,
		modalData: [],

		functions: [],
		sections: [],

		selectedFunction: null,
		selectedSection: null,

		defaultWorkDays: localStorage.getItem('@smartDefaultWorkDays') !== null ? localStorage.getItem('@smartDefaultWorkDays') === '1' : true,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

	const functions = await APIGetFunctions.Request();
	const sections = await APIGetSections.Request();

	const monday = localStorage.getItem('@smartMonday');
	const tuesday = localStorage.getItem('@smartTuesday');
	const wednesday = localStorage.getItem('@smartWednesday');
	const thurday = localStorage.getItem('@smartThursday');
	const friday = localStorage.getItem('@smartFriday');
	const saturday = localStorage.getItem('@smartSaturday');
	const sunday = localStorage.getItem('@smartSunday');
	const available = localStorage.getItem('@smartAvailable');
	const notFilled = localStorage.getItem('@smartNotFilled');
	const noSchool = localStorage.getItem('@smartNoSchool');
	const school = localStorage.getItem('@smartSchool');
	const schoolHoliday = localStorage.getItem('@smartSchoolHoliday');
	const maxDays = localStorage.getItem('@smartMaxDays');
	const cheapest = localStorage.getItem('@smartCheapest');
	const defaultWorkDays = localStorage.getItem('@smartDefaultWorkDays');

	if (monday === null) {
		localStorage.setItem('@smartMonday', '1');
	}
	if (tuesday === null) {
		localStorage.setItem('@smartTuesday', '1');
	}
	if (wednesday === null) {
		localStorage.setItem('@smartWednesday', '1');
	}
	if (thurday === null) {
		localStorage.setItem('@smartThursday', '1');
	}
	if (friday === null) {
		localStorage.setItem('@smartFriday', '1');
	}
	if (saturday === null) {
		localStorage.setItem('@smartSaturday', '1');
	}
	if (sunday === null) {
		localStorage.setItem('@smartSunday', '1');
	}
	if (available === null) {
		localStorage.setItem('@smartAvailable', '1');
	}
	if (notFilled === null) {
		localStorage.setItem('@smartNotFilled', '0');
	}
	if (noSchool === null) {
		localStorage.setItem('@smartNoSchool', '1');
	}
	if (school === null) {
		localStorage.setItem('@smartSchool', '1');
	}
	if (schoolHoliday === null) {
		localStorage.setItem('@smartSchoolHoliday', '1');
	}
	if (maxDays === null) {
		localStorage.setItem('@smartMaxDays', '7');
	}
	if (cheapest === null) {
		localStorage.setItem('@smartCheapest', '0');
	}
	if (defaultWorkDays === null) {
		localStorage.setItem('@smartDefaultWorkDays', '1');
	}

	this.setState({ functions: functions, sections: sections, loading: false});

  }

	closeAlert() {

		this.setState({ showAlert: false });

	}

	smartPlan() {

		localStorage.setItem('@smartMonday', this.state.monday === true ? '1' : '0');
		localStorage.setItem('@smartTuesday', this.state.tuesday === true ? '1' : '0');
		localStorage.setItem('@smartWednesday', this.state.wednesday === true ? '1' : '0');
		localStorage.setItem('@smartThursday', this.state.thurday === true ? '1' : '0');
		localStorage.setItem('@smartFriday', this.state.friday === true ? '1' : '0');
		localStorage.setItem('@smartSaturday', this.state.saturday === true ? '1' : '0');
		localStorage.setItem('@smartSunday', this.state.sunday === true ? '1' : '0');
		localStorage.setItem('@smartAvailable', this.state.available === true ? '1' : '0');
		localStorage.setItem('@smartNotFilled', this.state.notFilled === true ? '1' : '0');
		localStorage.setItem('@smartNoSchool', this.state.noSchool === true ? '1' : '0');
		localStorage.setItem('@smartSchool', this.state.school === true ? '1' : '0');
		localStorage.setItem('@smartSchoolHoliday', this.state.schoolHoliday === true ? '1' : '0');
		localStorage.setItem('@smartMaxDays', this.state.maxDays.toString());
		localStorage.setItem('@smartCheapest', this.state.cheapest === true ? '1' : '0');
		localStorage.setItem('@smartDefaultWorkDays', this.state.defaultWorkDays === true ? '1' : '0');

		this.props._smartPlanAlert({

			monday: this.state.monday,
			tuesday: this.state.tuesday,
			wednesday: this.state.wednesday,
			thurday: this.state.thurday,
			friday: this.state.friday,
			saturday: this.state.saturday,
			sunday: this.state.sunday,

			available: this.state.available,
			notFilled: this.state.notFilled,

			noSchool: this.state.noSchool,
			school: this.state.school,
			schoolHoliday: this.state.schoolHoliday,

			maxDays: this.state.maxDays,

			cheapest: this.state.cheapest,

			function: this.state.selectedFunction !== null ? this.state.selectedFunction.substr(1) : null,
			section: this.state.selectedSection !== null ? this.state.selectedSection.substr(1) : null,

			defaultWorkDays: this.state.defaultWorkDays,

		});

	}

	closeModal() {

		localStorage.setItem('@smartMonday', this.state.monday === true ? '1' : '0');
		localStorage.setItem('@smartTuesday', this.state.tuesday === true ? '1' : '0');
		localStorage.setItem('@smartWednesday', this.state.wednesday === true ? '1' : '0');
		localStorage.setItem('@smartThursday', this.state.thurday === true ? '1' : '0');
		localStorage.setItem('@smartFriday', this.state.friday === true ? '1' : '0');
		localStorage.setItem('@smartSaturday', this.state.saturday === true ? '1' : '0');
		localStorage.setItem('@smartSunday', this.state.sunday === true ? '1' : '0');
		localStorage.setItem('@smartAvailable', this.state.available === true ? '1' : '0');
		localStorage.setItem('@smartNotFilled', this.state.notFilled === true ? '1' : '0');
		localStorage.setItem('@smartNoSchool', this.state.noSchool === true ? '1' : '0');
		localStorage.setItem('@smartSchool', this.state.school === true ? '1' : '0');
		localStorage.setItem('@smartSchoolHoliday', this.state.schoolHoliday === true ? '1' : '0');
		localStorage.setItem('@smartMaxDays', this.state.maxDays.toString());
		localStorage.setItem('@smartCheapest', this.state.cheapest === true ? '1' : '0');
		localStorage.setItem('@smartDefaultWorkDays', this.state.defaultWorkDays === true ? '1' : '0');

		this.props._closeModal();

	}

	applyTemplate(key) {

		this.props._applyTemplate(parseInt(key));

		this.props._closeModal();

	}

	async deleteTemplate(key) {

		const deleted = await this.props._deleteTemplate(parseInt(key));

		await this.reloadScreen();

	}

	getTemplateBars() {

		let toReturn = [];
		let index = 1;

		for (const temp of this.state.templates) {

			toReturn.push(

				<div className='smartPlanModal-templateRow'>

					<div className='smartPlanModal-templateRowSub1'>
						<span>{index}. {temp.sjabloon_naam}</span>
					</div>

					<div className='smartPlanModal-templateRowSub2'>
						<span className='planner_publishMenu_deButton' onClick={() => this.deleteTempAlert(temp.sjabloon_key, temp.sjabloon_naam)}>Verwijderen</span>
						<span className='planner_publishMenu_buttonOpen' onClick={() => this.applyTempAlert(temp.sjabloon_key, temp.sjabloon_naam)}>Toepassen</span>
					</div>

				</div>

			);

			index++;

		}

		return toReturn;

	}

	async reloadScreen() {

		this.setState({loading: true});
	
		const templates = await APIGetAllTemplates.Request();
	
		this.setState({loading: false, templates: templates});
	
	  }

	  openTempModal() {

		this.setState({
		  showModal: true, 
		  modalMode: 0,
		  modalData: [],
		});
	
	  }
	
	  closeTempModal() {
	
		this.setState({ 
		  showModal: false,
		  modalData: [],
		  modalMode: 0,
		});
	
	  }

	  deleteTempAlert(key, name) {

		this.setState({
			alertTitle: 'Standaard planning verwijderen',
			alertBody: (
			  <div>
	
				Weet je zeker dat je de standaard planning "{name}" wilt verwijderen?
	
			  </div>
			),
			alertButtons: 2,
			alertButtonText: ['Annuleren', 'Verwijderen'],
			alertButtonAction: this.deleteTemplate.bind(this, key),
			showAlert: true,
		  });

	  }

	  applyTempAlert(key, name) {

		this.setState({
			alertTitle: 'Standaard planning toepassen',
			alertBody: (
			  <div>
	
				Weet je zeker dat je de standaard planning "{name}" wilt toepassen?
	
			  </div>
			),
			alertButtons: 2,
			alertButtonText: ['Annuleren', 'Toepassen'],
			alertButtonAction: this.applyTemplate.bind(this, key),
			showAlert: true,
		  });

	  }

	  onChangeMaxDays(event) {

		let days = parseInt(event.target.value);

		if (days < 1) {
			days = 1;
		}
		if (days > 7) {
			days = 7;
		}

		localStorage.setItem('@smartMaxDays', days.toString());

		this.setState({ maxDays: days });
	  }


	getFunctionBars() {

	let toRender = [];

		for (const func of this.state.functions) {

			toRender.push(
				<option value={'f' + func.functie_id} selected={this.state.selectedFunction === 'f' + func.functie_id}>{func.functie_naam}</option>
			);

		}

		toRender.push(<option value={'null'} disabled></option>);
		toRender.push(<option value={'null'} disabled>Afdelingen</option>);

		for (const section of this.state.sections) {

			toRender.push(
				<option value={'a' + section.afdeling_id} selected={this.state.selectedSection === 'a' + section.afdeling_id}>{section.afdeling_naam}</option>
			);
	
		}

	return toRender;

	}

	changeFunction(e) {

		if (e.target.value.startsWith('f')) {
			this.setState({ selectedFunction: e.target.value, selectedSection: null });
		} else if (e.target.value.startsWith('a')) {
			this.setState({ selectedSection: e.target.value, selectedFunction: null });
		} else {
			this.setState({ selectedSection: null, selectedFunction: null });
		}

	}

  render() {

    return (
        <div id="smartPlanModal" className="smartPlanModal" onClick={(e) => e.target === document.getElementById("zsmartPlanModal") ? this.closeModal() : null}>

		{this.state.showAlert === true ?
			
			<BetterAlert
				_closeAlert={this.closeAlert.bind(this)}
				_title={this.state.alertTitle}
				_body={this.state.alertBody}
				_buttons={this.state.alertButtons}
				_buttonText={this.state.alertButtonText}
				_buttonAction={this.state.alertButtonAction}
			/>

		:

		null
		
		}

		{this.state.showModal === true ?
                
			<TemplateModal 
				_closeModal={this.closeTempModal.bind(this)}
				_modalData={this.state.modalData}
				_modalMode={this.state.modalMode}
				_reloadScreen={this.reloadScreen.bind(this)}
				_saveTemplate={this.props._saveTemplate}
				_key={this.state.highestTempKey}
			/>

		:

			null
		
		}

        <div className="tutmodal2-content" style={{width: '50vw'}}>

          <div className="tutmodal2-header">

            <span className="tutmodal2-close" onClick={() => this.closeModal()}>&times;</span>

            <p>Smart plan opties - week {this.props._week}</p>

          </div>

            <div className="tutmodal2-body">

				{this.state.loading === true ?

					<div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

				:

				  	<div className='tutmodal2_content' style={{width: '100%'}}>

						<div className='tutmodal2_container'>

							{this.state.reloading === true ?
								
							<div className='loader3'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

							:

							null

							}

							<div className='planner_addButtons_container' style={{marginTop: 0, marginLeft: 0}}>

								{/* marginLeft: '0.6vw' */}

								<div style={{width: '30%'}}>
								  <select
									  name="Selecteer een afdeling"
									  className={'plannerZ_input_select'}
									  onChange={(event) => this.changeFunction(event)}
								  >
									  <option value={'null'} disabled>Functies</option>
									  <option value={'null'} selected={this.state.selectedFunction === null}>Alle functies</option>
									  <option value={'null'} disabled></option>
									  {this.getFunctionBars()}
								  </select>
								</div>

							</div>

							<span><b>Let op</b>: om Smart Plan te gebruiken moet je standaard diensten hebben ingesteld en moeten medewerkers een functie toegewezen hebben.</span>

							<div className='smartPlanModal-settings'>

								<div className='smartPlanModal-settingsColumn'>

									<span style={{fontWeight: 'bold', fontSize: '1vw'}}>Dagen</span>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.monday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({monday: !this.state.monday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

											: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({monday: !this.state.monday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({monday: !this.state.monday})}>Maandag</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.tuesday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({tuesday: !this.state.tuesday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

											: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({tuesday: !this.state.tuesday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({tuesday: !this.state.tuesday})}>Dinsdag</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.wednesday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({wednesday: !this.state.wednesday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({wednesday: !this.state.wednesday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({wednesday: !this.state.wednesday})}>Woensdag</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.thurday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({thurday: !this.state.thurday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({thurday: !this.state.thurday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({thurday: !this.state.thurday})}>Donderdag</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.friday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({friday: !this.state.friday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({friday: !this.state.friday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({friday: !this.state.friday})}>Vrijdag</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.saturday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({saturday: !this.state.saturday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({saturday: !this.state.saturday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({saturday: !this.state.saturday})}>Zaterdag</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.sunday === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({sunday: !this.state.sunday})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({sunday: !this.state.sunday})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({sunday: !this.state.sunday})}>Zondag</span>

										</div>

									</div>

								</div>

								<div className='smartPlanModal-settingsColumn'>

									{Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'STANDARD' ?

										<div>

											<span style={{fontWeight: 'bold', fontSize: '1vw'}}>Opties beschikbaarheid</span>

											<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

												<div className='smartPlanModal-settingRowSub1'>

													{this.state.available === false ?

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({available: !this.state.available})}>
															<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
														</div>

														: 

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({available: !this.state.available})}>
															<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
														</div>

													}

												</div>

												<div className='smartPlanModal-settingRowSub2'>

													<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({available: !this.state.available})}>Beschikbaar</span>

												</div>

											</div>

											<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

												<div className='smartPlanModal-settingRowSub1'>

													{this.state.notFilled === false ?

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({notFilled: !this.state.notFilled})}>
															<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
														</div>

														: 

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({notFilled: !this.state.notFilled})}>
															<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
														</div>

													}

												</div>

												<div className='smartPlanModal-settingRowSub2'>

													<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({notFilled: !this.state.notFilled})}>Niet ingevuld</span>

												</div>

											</div>
											
										</div>

									:
									Data.data.appOptions.availability === 'SCHOOL' ?

										<div>

											<span style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1vw'}}>Opties beschikbaarheid</span>

											<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

												<div className='smartPlanModal-settingRowSub1'>

													{this.state.noSchool === false ?

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({noSchool: !this.state.noSchool})}>
															<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
														</div>

														: 

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({noSchool: !this.state.noSchool})}>
															<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
														</div>

													}

												</div>

												<div className='smartPlanModal-settingRowSub2'>

													<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({noSchool: !this.state.noSchool})}>Geen school</span>

												</div>

											</div>

											<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

												<div className='smartPlanModal-settingRowSub1'>

													{this.state.school === false ?

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({school: !this.state.school})}>
															<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
														</div>

														: 

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({school: !this.state.school})}>
															<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
														</div>

													}

												</div>

												<div className='smartPlanModal-settingRowSub2'>

													<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({school: !this.state.school})}>School</span>

												</div>

											</div>

											<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

												<div className='smartPlanModal-settingRowSub1'>

													{this.state.schoolHoliday === false ?

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({schoolHoliday: !this.state.schoolHoliday})}>
															<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
														</div>

														: 

														<div className='smartPlanModal-checkbox' onClick={() => this.setState({schoolHoliday: !this.state.schoolHoliday})}>
															<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
														</div>

													}

												</div>

												<div className='smartPlanModal-settingRowSub2'>

													<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({schoolHoliday: !this.state.schoolHoliday})}>Schoolvakantie</span>

												</div>

											</div>
											
										</div>
									
									: null}

									{Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'STANDARD' || Data.data.appOptions.availability === 'SCHOOL' ?
										<span style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1.5vw'}}>Extra opties</span>
									:
										<span style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1vw'}}>Opties</span>
									}

									<div className='smartPlanModal-settingRow' style={{marginTop: '0.5vw'}}>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw'}} onClick={() => this.setState({notFilled: !this.state.notFilled})}>Maximale werkdagen p.p.</span>

										</div>

										<div className='smartPlanModal-settingRowSub1' style={{flex: 10}}>

											<input 
												className={'plannerZ_input_text'}
												min={1}
												max={7}
												value={this.state.maxDays}
												onChange={(event) => this.onChangeMaxDays(event)}
												type={'number'}
												step={1}
											/>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.cheapest === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({cheapest: !this.state.cheapest})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({cheapest: !this.state.cheapest})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({cheapest: !this.state.cheapest})}>Goedkoopste medewerkers eerst</span>

										</div>

									</div>

									<div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

										<div className='smartPlanModal-settingRowSub1'>

											{this.state.defaultWorkDays === false ?

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({defaultWorkDays: !this.state.defaultWorkDays})}>
													<i className='fal fa-square' style={{fontSize: '0.8vw'}} />
												</div>

												: 

												<div className='smartPlanModal-checkbox' onClick={() => this.setState({defaultWorkDays: !this.state.defaultWorkDays})}>
													<i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
												</div>

											}

										</div>

										<div className='smartPlanModal-settingRowSub2'>

											<span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({defaultWorkDays: !this.state.defaultWorkDays})}>Medewerkers met vaste werktijden inplannen</span>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				}

            </div>

			{this.state.loading === true ?

				<div className='tutmodal2-bottom'>

				  <div className='tutmodal2-loadingButtonText'>
					<div className="lds-dual-ring-button" />
				  </div>

				</div>

			  :

				<div className='tutmodal2-bottom'>

				  {/* {this.showError()} */}

				  {/* {this.state.curStep !== 'general' ?
					<div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.prevStep()}>
					  <span>Terug</span>
					</div>
				  : null}

				  {this.state.menu === 'done' ?
					<div className='modal-normalButton' onClick={() => this.finishStartup()}>
					  <span>Voltooien</span>
					</div>
				  : */}
					<div className='modal-normalButton' onClick={() => this.smartPlan()}>
					  <span>Smart plan</span>
					</div>
				  {/* } */}

				</div>

			  }

        </div>

      </div>
    );
  }
  
}

export default SmartPlanModal;