/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './trade.css';
import '../tradeRequests/tradeRequests.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import APIGetOwnTrades from '../../../classes/APIGetOwnTrades';
import APIGetOtherTrades from '../../../classes/APIGetOtherTrades';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import TradeModal from './tradeModal';
import APIGetTeamPlanner from '../../../classes/APIGetTeamPlanner';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIDELETE from '../../../classes/global/APIDELETE';
import BetterAlert from '../../../components/alert/alert';
import KodictNotifications from '../../../constants/KodictNotifications';


class Trade extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    this.teamPlannerOwn = [];
    this.teamPlannerOther = [];

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      ownTrades: [],
      otherTrades: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    await this.getOwnTrades();
    await this.getOtherTrades();

    this.setState({loading: false});

  }

  openTradeModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  async getOwnTrades() {

    //let trades = await APIGetOwnTrades.Request();
    let trades = await APIGET.Request(
      `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
      `FROM Ruilshift`,
      `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
      ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
      ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +
      ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
      ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
      ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id`,
      `WHERE rs_info_id_from = ${Data.data.accountData.info_id} AND plan1.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)`,
      null,
      `ORDER BY rs_geaccepteerd, plan1.rooster_datum, plan1.rooster_begin, plan1.rooster_eind`
  );

    if(trades === 'error') {

        trades = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(trades === null) {

        trades = [];

    }

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

      for (const trade of trades) {

          const obj = await APIGetTeamPlanner.Request(trade.pd1);

          for (const teampl of obj) {
              this.teamPlannerOwn = [...this.teamPlannerOwn, teampl];
          }

          if (trade.pd1 !== trade.pd2) {

            const obj2 = await APIGetTeamPlanner.Request(trade.pd2);

            for (const teampl of obj2) {
                this.teamPlannerOwn = [...this.teamPlannerOwn, teampl];
            }

          }

      }

    }

    this.setState({ ownTrades: trades });

  }

  async getOtherTrades() {

    //let trades = await APIGetOtherTrades.Request();
    let trades = await APIGET.Request(
      `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
      `FROM Ruilshift`,
      `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
      ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
      ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +
      ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
      ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
      ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id`,
      `WHERE rs_info_id_to = ${Data.data.accountData.info_id} AND plan2.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)`,
      null,
      `ORDER BY rs_geaccepteerd, plan2.rooster_datum, plan2.rooster_begin, plan2.rooster_eind`
    );

    if(trades === 'error') {

        trades = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(trades === null) {

        trades = [];

    }

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

      for (const trade of trades) {

          const obj = await APIGetTeamPlanner.Request(trade.pd2);

          for (const teampl of obj) {
              this.teamPlannerOther = [...this.teamPlannerOther, teampl];
          }

          if (trade.pd1 !== trade.pd2) {

            const obj2 = await APIGetTeamPlanner.Request(trade.pd1);
  
            for (const teampl of obj2) {
                this.teamPlannerOther = [...this.teamPlannerOther, teampl];
            }
  
          }

      }

    }

    this.setState({ otherTrades: trades });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getOwnTrades();
    await this.getOtherTrades();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getOwnTradeBars() {

    let trades = [];

    for(let key in this.state.ownTrades) {

        const trade = this.state.ownTrades[key];

        let color = Colors.color.greyNotFilled;
        let mode = 2;

        if(trade.rs_geaccepteerd === '0') {
          color = Colors.color.greyNotFilled;
        }
        if(trade.rs_geaccepteerd === '1') {
            color = Colors.color.orangeVacation;
        }
        if(trade.rs_geaccepteerd === '2') {
            color = Colors.color.greenFilledIn;
        }
        if(trade.rs_geaccepteerd === '3' || trade.rs_geaccepteerd === '4') {
            color = Colors.color.redFilledIn;
        }

        const date = Constants.stringToDate(trade.pd1);
        const date2 = Constants.stringToDate(trade.pd2);

        trades.push(
            <div>
                <div 
                    className={'trade_tradeBar'} 
                    style={{borderColor: color, backgroundColor: Constants.hexToRgbA(color, 0.1)}}
                >   

                    <div style={{backgroundColor: color, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>

                      {trade.rs_geaccepteerd === '0' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van een reactie</span>
                      : trade.rs_geaccepteerd === '1' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van een goedkeuring</span>
                      : trade.rs_geaccepteerd === '2' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Goedgekeurd door manager</span>
                      : trade.rs_geaccepteerd === '3' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Afgewezen door de ander</span>
                      : trade.rs_geaccepteerd === '4' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Afgewezen door manager</span>
                      :
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van een reactie</span>
                      }

                    </div>

                    <div className='trade_tradeContainer'>

                      <div className={'trade_functionContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb1.substring(0,5)} - {trade.pe1.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn1}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlannerOwn, trade.rs_rooster_id_from)}</div>

                        <div style={{marginTop: 3}}>{trade.v1}</div>

                        {trade.rs_geaccepteerd === '0' || trade.rs_geaccepteerd === '1' ?
                          <div className='trade_denyButton' style={{marginTop: 8}} onClick={() => this.deleteAlert(trade)}>
                            Verwijderen
                          </div>
                        : null}

                      </div>

                      <div style={{display: 'flex', flex: '0.2', justifyContent: 'center', alignItems: 'center', paddingBottom: trade.rs_geaccepteerd === '0' || trade.rs_geaccepteerd === '1' ? 35 : 0}}>

                        <i className='fad fa-exchange' style={{ '--fa-primary-color': Colors.color.redFilledIn, '--fa-secondary-color': Colors.color.greenFilledIn, '--fa-primary-opacity': 1, '--fa-secondary-opacity': 1 }}/>

                      </div>

                      <div className={'trade_namesContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb2.substring(0,5)} - {trade.pe2.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn2}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlannerOwn, trade.rs_rooster_id_to)}</div>

                        <div style={{marginTop: 3}}>{trade.v2}</div>

                        {trade.rs_geaccepteerd === '0' || trade.rs_geaccepteerd === '1' ?
                          <div className='trade_denyButton' style={{marginTop: 8, backgroundColor: 'transparent', cursor: 'default', color: 'transparent'}}>
                            &nsbp;
                          </div>
                        : null}

                      </div>

                    </div>

                </div>
            </div>
        );

    }

    return trades;

  }

  getOtherTradeBars() {

    let trades = [];

    for(let key in this.state.otherTrades) {

        const trade = this.state.otherTrades[key];

        let color = Colors.color.greyNotFilled;
        let mode = 2;

        if(trade.rs_geaccepteerd === '0') {
          color = Colors.color.greyNotFilled;
        }
        if(trade.rs_geaccepteerd === '1') {
            color = Colors.color.orangeVacation;
        }
        if(trade.rs_geaccepteerd === '2') {
            color = Colors.color.greenFilledIn;
        }
        if(trade.rs_geaccepteerd === '3' || trade.rs_geaccepteerd === '4') {
            color = Colors.color.redFilledIn;
        }

        const date = Constants.stringToDate(trade.pd1);
        const date2 = Constants.stringToDate(trade.pd2);

        let expired = false;

        trades.push(
            <div>
                <div 
                    className={'trade_tradeBar'} 
                    style={{borderColor: color, backgroundColor: Constants.hexToRgbA(color, 0.1)}}
                >   

                    <div style={{backgroundColor: color, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>

                      {trade.rs_geaccepteerd === '0' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van jouw reactie</span>
                      : trade.rs_geaccepteerd === '1' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van een goedkeuring</span>
                      : trade.rs_geaccepteerd === '2' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Goedgekeurd door manager</span>
                      : trade.rs_geaccepteerd === '3' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Afgewezen  door jou</span>
                      : trade.rs_geaccepteerd === '4' ?
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>Afgewezen door manager</span>
                      :
                          <span style={{textAlign: 'center', color: Constants.getContrast(color)}}>In afwachting van jouw reactie</span>
                      }

                    </div>

                    <div className='trade_tradeContainer'>

                      <div className={'trade_functionContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb2.substring(0,5)} - {trade.pe2.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date2.getDate()} {Constants.getMonthName(date2)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn2}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlannerOther, trade.rs_rooster_id_to)}</div>

                        <div style={{marginTop: 3}}>{trade.v2}</div>

                        {trade.rs_geaccepteerd === '0' ?
                          <div className='trade_denyButton' style={{marginTop: 8}} onClick={() => this.denyAlert(trade)}>
                            Afwijzen
                          </div>
                        : null}

                      </div>

                      <div style={{display: 'flex', flex: '0.2', justifyContent: 'center', alignItems: 'center', paddingBottom: trade.rs_geaccepteerd === '0' ? 35 : 0}}>

                        <i className='fad fa-exchange' style={{ '--fa-primary-color': Colors.color.redFilledIn, '--fa-secondary-color': Colors.color.greenFilledIn, '--fa-primary-opacity': 1, '--fa-secondary-opacity': 1 }}/>

                      </div>

                      <div className={'trade_namesContainer'}>

                        <div style={{fontWeight: 'bold'}}>{trade.pb1.substring(0,5)} - {trade.pe1.substring(0,5)}</div>

                        <div style={{marginTop: 3}}>{date.getDate()} {Constants.getMonthName(date)}</div>

                        <div style={{marginTop: 3,}}>{trade.fn1}</div>

                        <div style={{marginTop: 3,}}>{Constants.getTeamNameFromScheduleID(this.teamPlannerOther, trade.rs_rooster_id_from)}</div>

                        <div style={{marginTop: 3}}>{trade.v1}</div>

                        {trade.rs_geaccepteerd === '0' ?
                          <div className='trade_acceptButton' style={{marginTop: 8}} onClick={() => this.acceptAlert(trade)}>
                            Accepteren
                          </div>
                        : null}

                      </div>

                    </div>

                </div>
            </div>
        );

    }

    return trades;

  }

  deleteAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je deze ruil wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async deleteTrade(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
        return;
    }

    this.setState({loading: true});

    const deleted = await APIDELETE.Request(`DELETE FROM Ruilshift`, `WHERE rs_id = ${trade.rs_id}`);

    if (deleted === true) {

        await this.getOwnTrades();

    }

    this.setState({loading: false});

  }

  denyAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Afwijzen',
      alertBody: (
        <div>

          Weet je zeker dat je deze ruil wilt afwijzen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Afwijzen'],
      alertButtonAction: this.denyTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async denyTrade(trade) {

      if(this.state.loading === true) {
          return;
      }

      this.setState({loading: true});

      const deleted = await APIEDIT.Request(`UPDATE Ruilshift`, `SET rs_geaccepteerd = 3`, `WHERE rs_id = ${trade.rs_id}`);

      if (deleted === true) {

          await this.getOtherTrades();

          const tradeDate = Constants.stringToDate(trade.pd1);

          await KodictNotifications.sendPushNotification(trade.rs_info_id_from, 'trade', 'Ruilen', `${trade.v2} heeft jouw ruilverzoek van ${Constants.getShortDayName(tradeDate, true)} ${tradeDate.toLocaleDateString()} afgewezen`);

      }

      this.setState({loading: false});

  }

  acceptAlert(trade) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Accepteren',
      alertBody: (
        <div>

          Weet je zeker dat je deze ruil wilt accepteren?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Accepteren'],
      alertButtonAction: this.acceptTrade.bind(this, trade),
      showAlert: true,
    });

  }

  async acceptTrade(trade) {

      if(this.state.loading === true) {
          return;
      }

      this.setState({loading: true});

      const deleted = await APIEDIT.Request(`UPDATE Ruilshift`, `SET rs_geaccepteerd = 1`, `WHERE rs_id = ${trade.rs_id}`);

      if (deleted === true) {

          await this.getOtherTrades();

          const tradeDate = Constants.stringToDate(trade.pd1);
          const tradeDate2 = Constants.stringToDate(trade.pd2);

          await KodictNotifications.sendPushNotification(trade.rs_info_id_from, 'trade', 'Ruilen', `${trade.v2} heeft jouw ruilverzoek van ${Constants.getShortDayName(tradeDate, true)} ${tradeDate.toLocaleDateString()} geaccepteerd. Wacht af op een goedkeuring van jouw manager.`);

          await KodictNotifications.sendPushNotificationStaff('trade', 'Ruilverzoek', `${trade.v1}, ${Constants.getShortDayName(tradeDate, true)} ${tradeDate.toLocaleDateString()} ${trade.pb1.substr(0,5)} - ${trade.pe1.substr(0,5)} wilt graag ruilen met ${trade.v2}, ${Constants.getShortDayName(tradeDate2, true)} ${tradeDate2.toLocaleDateString()} ${trade.pb2.substr(0,5)} - ${trade.pe2.substr(0,5)}`)

      }

      this.setState({loading: false});

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    const ownTradeBars = this.getOwnTradeBars();
    const otherTradeBars = this.getOtherTradeBars();

    return (
        <div className='trade'>

          <Navbar history={this.props.history} active={'trade'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Ruilen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='trade_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <TradeModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {this.state.ownTrades.length === 0 && this.state.otherTrades.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen ruilverzoeken op dit moment</div>
                </div>

              :

                null
              
              }

              <div className={'trade_dataContainer'}>

                <div className={'trade_dataContainerTitle'}>

                    Mijn verzonden ruilverzoeken

                    {/* {otherTradeBars.length > 0 ?
                    
                        <i className={'fa fa-exclamation'} style={{marginLeft: 8, color: Colors.color.redFilledIn, fontSize: '1vw'}}></i>

                    :

                        null

                    } */}

                </div>

                {ownTradeBars}

              </div>

              <div className={'trade_dataContainer'}>

                <div className={'trade_dataContainerTitle'}>

                    Mijn ontvangen ruilverzoeken

                </div>

                {otherTradeBars}

              </div>

              {/* {tradeRequestBars} */}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Trade;