

import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIUploadFile {

    async Request(file) {

        const username = Data.data.accountData.account_username;

        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let formData = new FormData();
        formData.append('upfile', file);
        formData.append('user', username);
        formData.append('DB', Data.data.storeCode);
        formData.append('hash', hash);

        const response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/uploadFile.php/`, {
            method: 'POST',
            body: formData,
        });

        let sqlData = [];

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch (e) {
            // console.log(e);
        }

        return sqlData;
    }
  
}
  
// singleton
export default (new APIUploadFile());