/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './absences.css'
import '../myHours/myHours.css';
import '../../../components/smartPlanModal/smartPlanModal.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../employees/employees.css';
import '../hoursRegistration/hoursRegistration.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';

import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetOwnHolidays from '../../../classes/APIGetOwnHolidays';
import APIGetAllHolidaysYear from '../../../classes/APIGetAllHolidaysYear';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import Colors from '../../../constants/Colors';

import { Chart } from "react-google-charts";

import { Tooltip } from 'react-tippy';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';
import APIGET from '../../../classes/global/APIGET';
import APIGetAllContracts from '../../../classes/APIGetAllContracts';
import AddHolidayModal from '../holidayRequests/addHolidayModal';
import APIEditHoliday from '../../../classes/APIEditHoliday';
import KodictNotifications from '../../../constants/KodictNotifications';
import APIADD from '../../../classes/global/APIADD';
import APIDELETE from '../../../classes/global/APIDELETE';

class Absences extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    this.teamUsers = [];

    this.hoursRef = {};

    this.timeNow = new Date();
    this.absAmount = {};

    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();

    const daysCurrentMonth = Constants.getDaysInMonth(month, year);

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      timeNow: new Date(),

      year: year,

      accounts: [],
      holidays: {},
      totalDays: {},
      totalHours: {},
      contracts: {},

      selectedMonth: null,
      selectedWeek: null,

      menu: 'absences',

      freeHours: {},

      chartData: [],
      chartLength: 0,

      showModal: false,
      modalMode: 0,
      modalData: null,
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAddHolidayModal: false,

      showAlert: false,

      workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
      workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
      workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
      workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
      workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
      workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
      workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

      infoExtra: {},

      minYear: year,
      maxYear: year,

      reqStart: date,
      reqEnd: new Date(date.getFullYear(), 11, 31),
      reqAccepted: '0', // all, 0, 1, 2, 3 = accepted and declined
      reqHolidays: 'all', // all, holidays, freehours
      reqEmployee: 'all',
      reqSort: `ORDER BY vakantie_geaccepteerd, vakantie_begin`,

      holidayRequests: [],

      absAmount: {},
      absAmountReport: {},

      leaveBalances: [],
      leaveTypeBalances: {},
      contractLeaveBalances: {},
      leaveCorrections: {},

      userLeaveBalances: {},

      allHours_users: {},

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    this.setState({ selectedMonth: null, selectedWeek: null, menu: 'absences', loading: true});

    //infoextra here

    const timeNow = await Constants.getDateNow2();

    let [
      infoExtra,
      absences,
      accounts,
      freeHours,
      contracts,
      minmaxYear,
      absAmount,
      absAmountReport,
      contractLeaveBalances,
      leaveTypeBalances,
      leaveBalances,
      leaveCorrections,
      allHours_users,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGetAllHolidaysYear.Request(this.state.year),
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM VerlofUren`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT MIN(vakantie_begin) AS min_jaar, MAX(vakantie_eind) AS max_jaar`, `FROM Vakantie`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM CorrectieVerlof`, null, null, null, `ORDER BY cv_datum DESC`),
      Constants.calculateAllHours_users(undefined, undefined, timeNow),
    ]);

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    const freeHoursObj = Constants.convertToObject(freeHours, 'vu_vakantie_id');
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');
    const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');
    const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vb_id');
    const contractLeaveBalancesObj = Constants.convertToObjectWithArrays(contractLeaveBalances, 'cvb_contract_id');
    const leaveCorrectionsObj = Constants.convertToObjectWithArrays(leaveCorrections, 'cv_info_id');

    this.absAmount = absAmountObj;

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.props.individual !== true) {
      this.teamUsers = await APIGetTeamUsers.Request();
      accounts = Constants.getTeamAccounts(this.teamUsers, accounts);
    }

    let _newAccounts = [];
    if (this.props.individual === true) {

      for (const acc of accounts) {
        if (acc.info_id === this.props.individual_userID) {
          _newAccounts.push(acc);
        }
      }

      accounts = _newAccounts;

    }

    this.getDaysOfMonths();

    this.syncAccountsWithHolidays(accounts, absences, freeHoursObj, infoExtraObj, absAmountObj, contractsObj);

    this.setState({

      accounts: accounts,
      contracts: contractsObj,
      absAmount: absAmountObj,
      absAmountReport: absAmountReportObj,

      timeNow: timeNow,

      minYear: minmaxYear.length > 0 && typeof minmaxYear[0].min_jaar !== 'undefined' && minmaxYear[0].min_jaar !== null && typeof minmaxYear[0].max_jaar !== 'undefined' && minmaxYear[0].max_jaar !== null ? parseInt(Constants.stringToDate(minmaxYear[0].min_jaar).getFullYear()) : this.state.year,
      maxYear: minmaxYear.length > 0 && typeof minmaxYear[0].min_jaar !== 'undefined' && minmaxYear[0].min_jaar !== null && typeof minmaxYear[0].max_jaar !== 'undefined' && minmaxYear[0].max_jaar !== null ? parseInt(Constants.stringToDate(minmaxYear[0].max_jaar).getFullYear()) : this.state.year,

      infoExtra: infoExtraObj,

      contractLeaveBalances: contractLeaveBalancesObj,
      leaveTypeBalances: leaveTypeBalancesObj,
      leaveBalances: leaveBalances,
      leaveCorrections: leaveCorrectionsObj,

      allHours_users: allHours_users,

      loading: false,

    });

  }

  async getRequestsData() {

    let holidayRequests = [];
    let where = `WHERE (vakantie_begin BETWEEN '${Constants.dateToString(this.state.reqStart)}' AND '${Constants.dateToString(this.state.reqEnd)}' OR vakantie_eind BETWEEN '${Constants.dateToString(this.state.reqStart)}' AND '${Constants.dateToString(this.state.reqEnd)}')`;
    let rightJoin = ``;

    if (this.state.reqAccepted !== 'all' && this.state.reqAccepted !== '3') {
      where += ` AND vakantie_geaccepteerd = ${this.state.reqAccepted}`;
    } else if (this.state.reqAccepted === '3') {
      where += ` AND (vakantie_geaccepteerd = 1 OR vakantie_geaccepteerd = 2)`;
    }

    if (this.state.reqEmployee !== 'all') {
      where += ` AND vakantie_info_id = ${this.state.reqEmployee}`;
    }

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      rightJoin = ` LEFT JOIN TeamUsers ON teamu_info_id = info_id`;
      where += ` AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`;
    }

    holidayRequests = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Vakantie.*`, 
      `FROM Vakantie`,
      `LEFT JOIN Info ON vakantie_info_id = info_id${rightJoin.length > 0 ? rightJoin : ''}`,
      where,
      null,
      this.state.reqSort
    );

    // const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    // const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    // this.absAmount = absAmountObj;

    // const absAmountReport = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    // const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    // const allHolidays = await APIGET.Request(`SELECT *`, `FROM Vakantie`, null, `WHERE vakantie_geaccepteerd = 1`, null, null);
    // const allHolidaysObj = Constants.convertToObjectWithArrays(allHolidays, 'vakantie_info_id');

    // const userLeaveBalances = {};
    // for (const acc of this.state.accounts) {

    //   if (typeof userLeaveBalances[acc.info_id] === 'undefined') {
    //     userLeaveBalances[acc.info_id] = null;
    //   }

    //   userLeaveBalances[acc.info_id] = await Constants.getLeaveBalance(this.state.contracts[acc.info_id], this.timeNow, acc.info_id, this.state.leaveBalances, this.state.leaveTypeBalances, this.state.contractLeaveBalances, allHolidaysObj, this.state.leaveCorrections, false, this.state.infoExtra);

    // }

    this.setState({
      holidayRequests: holidayRequests,
      // absAmount: absAmountObj,
      // absAmountReport: absAmountReportObj,
      // userLeaveBalances: userLeaveBalances,
    });

  }

  async reloadData(year) {

    const absences = await APIGetAllHolidaysYear.Request(year);

    const freeHours = await APIGET.Request(`SELECT *`, `FROM VerlofUren`, null, null, null, null);
    const freeHoursObj = Constants.convertToObject(freeHours, 'vu_vakantie_id');

    const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    this.absAmount = absAmountObj;

    const absAmountReport = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    await this.getRequestsData();

    this.setState({ totalDays: {}, selectedMonth: null, selectedWeek: null, freeHours: freeHoursObj, absAmount: absAmountObj, absAmountReport: absAmountReportObj, },
      
      () => {

        this.syncAccountsWithHolidays(this.state.accounts, absences, freeHoursObj, this.state.infoExtra, absAmountReportObj, this.state.contracts);

      }

    );

    this.setState({
      loading: false,
    });

  }

  async reloadDataMonth(month) {

    const absences = await APIGET.Request(`SELECT Vakantie.*, info_id, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM Vakantie`, `LEFT JOIN Info ON vakantie_info_id = info_id`, `WHERE ((YEAR(vakantie_begin) = ${this.state.year} AND MONTH(vakantie_begin) = ${month}) OR (YEAR(vakantie_eind) = ${this.state.year} AND MONTH(vakantie_eind) = ${month})) AND vakantie_geaccepteerd = 1`, null, `ORDER BY info_voornaam, info_achternaam`);

    const freeHours = await APIGET.Request(`SELECT *`, `FROM VerlofUren`, null, null, null, null);
    const freeHoursObj = Constants.convertToObject(freeHours, 'vu_vakantie_id');

    const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    this.absAmount = absAmountObj;

    const absAmountReport = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    await this.getRequestsData();

    this.setState({ totalDays: {}, freeHours: freeHoursObj, absAmount: absAmountObj, absAmountReport: absAmountReportObj, },
      
      () => {

        this.syncAccountsWithHolidays(this.state.accounts, absences, freeHoursObj, this.state.infoExtra, absAmountReportObj, this.state.contracts);

      }

    );

  }

  async reloadDataDates(start, end) {

    const absences = await APIGET.Request(`SELECT Vakantie.*, info_id, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM Vakantie`, `LEFT JOIN Info ON vakantie_info_id = info_id`, `WHERE ((vakantie_begin BETWEEN '${start}' AND '${end}') OR (vakantie_eind BETWEEN '${start}' AND '${end}')) AND vakantie_geaccepteerd = 1`, null, `ORDER BY info_voornaam, info_achternaam`);

    const freeHours = await APIGET.Request(`SELECT *`, `FROM VerlofUren`, null, null, null, null);
    const freeHoursObj = Constants.convertToObject(freeHours, 'vu_vakantie_id');

    const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

    this.absAmount = absAmountObj;

    const absAmountReport = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, null, null, null);
    const absAmountReportObj = Constants.convertToObjectWithArrays(absAmountReport, 'vs_info_id');

    await this.getRequestsData();

    this.setState({ totalDays: {}, freeHours: freeHoursObj, absAmount: absAmountObj, absAmountReport: absAmountReportObj, },
      
      () => {

        this.syncAccountsWithHolidays(this.state.accounts, absences, freeHoursObj, this.state.infoExtra, absAmountReportObj, this.state.contracts);

      }

    );

  }

  syncAccountsWithHolidays(accounts, holidays, freeHours, infoExtra, absAmount, contracts) {

    let holidaysState = {};
    let totalDays = {};
    let totalHours = {};
    let chartData = [];

    const workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

    for (const acc of accounts) {
      holidaysState[acc.info_id] = [];
    }

    for (const holiday of holidays) {

      if (typeof holidaysState[holiday.info_id] === 'undefined') {
        continue;
      }

      //let currentDate = new Date(holiday.vakantie_begin);
      let startDate = Constants.stringToDate(holiday.vakantie_begin);
      let endDate = Constants.stringToDate(holiday.vakantie_eind);

      let username = '';
      for (const acc of accounts) {
        if (acc.info_id === holiday.info_id) {
          username = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;
          break;
        }
      }

      totalDays[holiday.info_id] = typeof totalDays[holiday.info_id] === 'undefined' ? 0 : totalDays[holiday.info_id];
      totalHours[holiday.info_id] = typeof totalHours[holiday.info_id] === 'undefined' ? 0 : totalHours[holiday.info_id];

      if ((Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && (Constants.getCurrentContract(contracts[holiday.info_id], Constants.stringToDate(holiday.vakantie_begin)) !== null || Constants.getCurrentContract(contracts[holiday.info_id], Constants.stringToDate(holiday.vakantie_eind)) !== null)) || Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === false) {

        if (typeof freeHours[holiday.vakantie_id] !== 'undefined') {

          holidaysState[holiday.info_id].push(startDate);

          if (Constants.hasGeneralWorkDays() === true) {

            if (Constants.personHasWorkDays(infoExtra[holiday.info_id]) === true) {

              // person has working days, check work days personal and general
              if (((startDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holiday.info_id].info2_werkdag_vrijdag === '1') || (startDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holiday.info_id].info2_werkdag_donderdag === '1') || (startDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holiday.info_id].info2_werkdag_woensdag === '1') || (startDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holiday.info_id].info2_werkdag_dinsdag === '1') || (startDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holiday.info_id].info2_werkdag_maandag === '1') || (startDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holiday.info_id].info2_werkdag_zaterdag === '1') || (startDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holiday.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                totalDays[holiday.info_id] += 1;
              }

            } else { // just count the days
              totalDays[holiday.info_id] += 1;
            }

          } else { // just count all days
            totalDays[holiday.info_id] += 1;
          }

          if (freeHours[holiday.vakantie_id].vu_begin === '00:00:00' && freeHours[holiday.vakantie_id].vu_eind === '00:00:00') {
            chartData.push([
              username, 
              '', 
              `<div style='padding: 5px 5px;'>
                <span style='font-weight: bold;'>${Constants.getShortDayName(Constants.stringToDate(holiday.vakantie_begin)).toLowerCase()} ${Constants.stringToDate(holiday.vakantie_begin).getDate()} ${Constants.getShortMonthNameM(Constants.stringToDate(holiday.vakantie_begin).getMonth())} - hele dag</span>
              </div>`, 
              Date.parse(holiday.vakantie_begin), //  + ' 00:00:00'), 
              Date.parse(holiday.vakantie_eind) //  + ' 00:00:00') + 86400000
            ]);
            // if (Constants.hasGeneralWorkDays() === true) {

            //   if (Constants.personHasWorkDays(infoExtra[holiday.info_id]) === true) {

            //     // person has working days, check work days personal and general
            //     if (((startDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holiday.info_id].info2_werkdag_vrijdag === '1') || (startDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holiday.info_id].info2_werkdag_donderdag === '1') || (startDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holiday.info_id].info2_werkdag_woensdag === '1') || (startDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holiday.info_id].info2_werkdag_dinsdag === '1') || (startDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holiday.info_id].info2_werkdag_maandag === '1') || (startDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holiday.info_id].info2_werkdag_zaterdag === '1') || (startDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holiday.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
            //       totalHours[holiday.info_id] += workDayHours;
            //     }

            //   } else { // just count the days
            //     totalHours[holiday.info_id] += workDayHours;
            //   }

            // } else { // just count all days
            //   totalHours[holiday.info_id] += workDayHours;
            // }
            // if (((startDate.getDay() === 5 && this.state.workWeekFriday === true) || (startDate.getDay() === 4 && this.state.workWeekThursday === true) || (startDate.getDay() === 3 && this.state.workWeekWednesday === true) || (startDate.getDay() === 2 && this.state.workWeekTuesday === true) || (startDate.getDay() === 1 && this.state.workWeekMonday === true) || (startDate.getDay() === 6 && this.state.workWeekSaturday === true) || (startDate.getDay() === 0 && this.state.workWeekSunday === true)) || this.getWorkDays() === 0) {
            //   totalHours[holiday.info_id] += workDayHours;
            // }
          } else {

            //const calcHours = (Constants.timeStringToFloat(freeHours[holiday.vakantie_id].vu_eind) - Constants.timeStringToFloat(freeHours[holiday.vakantie_id].vu_begin));

            chartData.push([
              username, 
              '', 
              `<div style='padding: 5px 5px;'>
                <span style='font-weight: bold;'>${Constants.getShortDayName(Constants.stringToDate(holiday.vakantie_begin)).toLowerCase()} ${Constants.stringToDate(holiday.vakantie_begin).getDate()} ${Constants.getShortMonthNameM(Constants.stringToDate(holiday.vakantie_begin).getMonth())} - ${freeHours[holiday.vakantie_id].vu_begin.substr(0, 5)} t/m ${freeHours[holiday.vakantie_id].vu_eind.substr(0, 5)}</span>
              </div>`, 
              Date.parse(holiday.vakantie_begin), //  + ' ' + freeHours[holiday.vakantie_id].vu_begin), 
              Date.parse(holiday.vakantie_eind) //  + ' ' + freeHours[holiday.vakantie_id].vu_eind)
            ]);
            // if (Constants.hasGeneralWorkDays() === true) {

            //   if (Constants.personHasWorkDays(infoExtra[holiday.info_id]) === true) {

            //     // person has working days, check work days personal and general
            //     if (((startDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holiday.info_id].info2_werkdag_vrijdag === '1') || (startDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holiday.info_id].info2_werkdag_donderdag === '1') || (startDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holiday.info_id].info2_werkdag_woensdag === '1') || (startDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holiday.info_id].info2_werkdag_dinsdag === '1') || (startDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holiday.info_id].info2_werkdag_maandag === '1') || (startDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holiday.info_id].info2_werkdag_zaterdag === '1') || (startDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holiday.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
            //       totalHours[holiday.info_id] += calcHours;
            //     }

            //   } else { // just count the days
            //     totalHours[holiday.info_id] += calcHours;
            //   }

            // } else { // just count all days
            //   totalHours[holiday.info_id] += calcHours;
            // }
            // if (((startDate.getDay() === 5 && this.state.workWeekFriday === true) || (startDate.getDay() === 4 && this.state.workWeekThursday === true) || (startDate.getDay() === 3 && this.state.workWeekWednesday === true) || (startDate.getDay() === 2 && this.state.workWeekTuesday === true) || (startDate.getDay() === 1 && this.state.workWeekMonday === true) || (startDate.getDay() === 6 && this.state.workWeekSaturday === true) || (startDate.getDay() === 0 && this.state.workWeekSunday === true)) || this.getWorkDays() === 0) {
            //   totalHours[holiday.info_id] += (Constants.timeStringToFloat(freeHours[holiday.vakantie_id].vu_eind) - Constants.timeStringToFloat(freeHours[holiday.vakantie_id].vu_begin));
            // }
          }

          //totalDays[holiday.info_id] = this.fixTotalDays(totalDays[holiday.info_id]);
          
        } else {

          holidaysState[holiday.info_id].push(startDate);
          holidaysState[holiday.info_id].push(endDate);

          let startDateString = holiday.vakantie_begin;
          let endDateString = holiday.vakantie_eind;

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

            if (this.state.selectedMonth === null) { // jaar overzicht

              if (currentDate.getTime() >= new Date(this.state.year, 0, 1, 0, 0, 0, 0) && currentDate.getTime() <= new Date(this.state.year, 11, 31, 23, 59, 59, 999)) {

                if (Constants.hasGeneralWorkDays() === true) {

                  if (Constants.personHasWorkDays(infoExtra[holiday.info_id]) === true) {

                    // person has working days, check work days personal and general
                    if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holiday.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holiday.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holiday.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holiday.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holiday.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holiday.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holiday.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                      totalDays[holiday.info_id] += 1;
                      //totalHours[holiday.info_id] += workDayHours;
                    }

                  } else { // just count the days
                    totalDays[holiday.info_id] += 1;
                    //totalHours[holiday.info_id] += workDayHours;
                  }

                } else { // just count all days
                  totalDays[holiday.info_id] += 1;
                  //totalHours[holiday.info_id] += workDayHours;
                }
                // if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true) || (currentDate.getDay() === 4 && this.state.workWeekThursday === true) || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true) || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true) || (currentDate.getDay() === 1 && this.state.workWeekMonday === true) || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true) || (currentDate.getDay() === 0 && this.state.workWeekSunday === true)) || this.getWorkDays() === 0) {
                //   totalDays[holiday.info_id] += 1;
                //   totalHours[holiday.info_id] += workDayHours;
                // }
              } else {
                if (currentDate.getTime() < new Date(this.state.year, 0, 1, 0, 0, 0, 0)) {
                  startDateString = `${this.state.year}-01-01`;
                }
                if (currentDate.getTime() > new Date(this.state.year, 11, 31, 23, 59, 59, 999)) {
                  endDateString = `${this.state.year}-12-31`;
                }
              }

            } else {

              //maand overzicht
              if (this.state.selectedWeek === null) {

                if (currentDate.getTime() >= new Date(this.state.year, 0, 1, 0, 0, 0, 0) && currentDate.getTime() <= new Date(this.state.year, 11, 31, 23, 59, 59, 999)) {
                  if (Constants.hasGeneralWorkDays() === true) {

                    if (Constants.personHasWorkDays(infoExtra[holiday.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holiday.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holiday.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holiday.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holiday.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holiday.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holiday.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holiday.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        totalDays[holiday.info_id] += 1;
                        //totalHours[holiday.info_id] += workDayHours;
                      }

                    } else { // just count the days
                      totalDays[holiday.info_id] += 1;
                      //totalHours[holiday.info_id] += workDayHours;
                    }

                  } else { // just count all days
                    totalDays[holiday.info_id] += 1;
                    //totalHours[holiday.info_id] += workDayHours;
                  }
                  // if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true) || (currentDate.getDay() === 4 && this.state.workWeekThursday === true) || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true) || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true) || (currentDate.getDay() === 1 && this.state.workWeekMonday === true) || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true) || (currentDate.getDay() === 0 && this.state.workWeekSunday === true)) || this.getWorkDays() === 0) {
                  //   totalDays[holiday.info_id] += 1;
                  //   totalHours[holiday.info_id] += workDayHours;
                  // }
                } else {
                  const maxDaysMonth = Constants.getDaysInMonth(this.state.selectedMonth + 1, this.state.year);
                  if (currentDate.getTime() < new Date(this.state.year, this.state.selectedMonth, 1, 0, 0, 0, 0)) {
                    startDateString = `${this.state.year}-${(this.state.selectedMonth + 1) < 10 ? '0' + (this.state.selectedMonth + 1) : (this.state.selectedMonth + 1)}-01`;
                  }
                  if (currentDate.getTime() > new Date(this.state.year, this.state.selectedMonth, maxDaysMonth, 23, 59, 59, 999)) {
                    endDateString = `${this.state.year}-${(this.state.selectedMonth + 1) < 10 ? '0' + (this.state.selectedMonth + 1) : (this.state.selectedMonth + 1)}-${maxDaysMonth < 10 ? '0' + maxDaysMonth : maxDaysMonth}`;
                  }
                }

              }

            }

            index++;

          }

          chartData.push([
            username, 
            '', 
            `<div style='padding: 5px 5px;'>
              <span style='font-weight: bold;'>${Constants.getShortDayName(Constants.stringToDate(startDateString)).toLowerCase()} ${Constants.stringToDate(startDateString).getDate()} ${Constants.getShortMonthNameM(Constants.stringToDate(startDateString).getMonth())} t/m ${Constants.getShortDayName(Constants.stringToDate(endDateString)).toLowerCase()} ${Constants.stringToDate(endDateString).getDate()} ${Constants.getShortMonthNameM(Constants.stringToDate(endDateString).getMonth())}</span>
            </div>`, 
            Date.parse(startDateString), // + ' 00:00:00'
            Date.parse(endDateString), //  + ' 00:00:00') + 86400000
          ]);

          // totalDays[holiday.info_id] = this.fixTotalDays(totalDays[holiday.info_id]);
          // totalHours[holiday.info_id] = totalDays[holiday.info_id] * 8;

        }

      }

    }

    let chartLength = [];

    if (chartData.length > 0) {
      for (const data of chartData) {
        let found = false;
        for (const data2 of chartLength) {
          if (data2[0] === data[0]) {
            found = true;
          }
        }
        if (found === false) {
          chartLength.push(data);
        }
      }
    }

    this.chartData = chartData;
    this.setState({ holidays: holidaysState, totalDays: totalDays, totalHours: totalHours, chartLength: chartLength.length, freeHours: freeHours, absAmount: absAmount, contracts: contracts, infoExtra: infoExtra });

  }

  fixTotalDays(vacationDays) {

    const fullWorkWeek = this.getWorkDays();
    const week = 7;

    if (fullWorkWeek === 0 || fullWorkWeek === week) { // maybe if fullweek === week, fullweek = 5 ? because full workw eek is 40 hours thus 5 days
      return vacationDays;
    }

    let daysToRemove = 0;
    let total = 0;
    let iteration = 0;
    
    while (total < vacationDays) {

      total++;

      if (total !== vacationDays) {

        if (iteration === fullWorkWeek) {
          daysToRemove += (week - fullWorkWeek);
          iteration = 0;
        }

        iteration++;

      }

    }

    return total - daysToRemove;

  }

  getDaysOfMonths() {

    let months = [];

    for (let i = 0; i < 12; i++) {
      months.push({month: i, days: Constants.getDaysInMonth(i + 1, this.state.year)});
    }

    this.daysInMonths = months;

  }

  async nextYear(year) {

    if(this.state.loading === true) {
        return;
    }

    if(this.canGoNext() === false) {
      return;
    }

    this.setState({loading: true});

    this.setState({
        year: year,
    });

    await this.reloadData(year);

    this.setState({loading: false});

  }

  async previousYear() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let year = this.state.year - 1;

    if(year === 0) {

      return;
      
    }

    this.setState({
        year: year,
    });

    await this.reloadData(year);

    this.setState({loading: false});

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  canGoNext() {

    // let year = this.state.year + 1;

    // const today = new Date();
    // const newDate = new Date(year, 0, 1);

    // if(today < newDate) {
    //   return false;
    // }

    return true;

  }

  getAvailabilityBars() {

    let bars = [];

    bars.push(

      <div className='absences_personRow stickyTopContent' style={{pointerEvents: 'none'}}>
        
        <div className='absences_nameTitle'>

          <div style={{fontWeight: 'bold'}}>Naam - Maand</div>

        </div>

        {this.getMonthBars(null)}

      </div>

    );

    for (const person of this.state.accounts) {

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

      bars.push(

        <div className='absences_personRow' style={{cursor: 'pointer'}}>
          
          <div className='absences_name'>

            <div>{userFullName}</div>

          </div>

          {this.getMonthBars(person)}

        </div>

      );

    }

    return bars;

  }

  getMonthBars(person) {

    let calendarBars = [];

    if (person === null) {

      for (const calendar of this.daysInMonths) {

        calendarBars.push(

          <div className='absences_monthName'>
            <div style={{fontWeight: 'bold'}}>{Constants.firstUppercase(Constants.getShortMonthNameM(calendar.month))}</div>
          </div>

        );

      }
      
    } else {

      for (const calendar of this.daysInMonths) {

        calendarBars.push(

          <div className='absences_month'>
            {this.getDayBars(calendar.days, calendar.month, person)}
          </div>

        );

      }

    }

    return calendarBars;

  }

  getDayBars(days, month, person) {

    let calendarBars = [];

    for (let i = 0; i < days; i++) {

      let found = false;

      // if theres no availabiltiy given by person
      if (this.state.holidays[person.info_id].length > 0) {

        for (const date of this.state.holidays[person.info_id]) {

          const dateNow = new Date(this.state.year, month, i + 1, 12,0,0,0);
  
          if (dateNow.getFullYear() === date.getFullYear() && dateNow.getMonth() === date.getMonth() && dateNow.getDate() === date.getDate()) {
  
            calendarBars.push(

              <Tooltip
                options
                html={(
                  <div className='planner_hoursTooltip'>
                    <div>Datum: {date.getDate()} {Constants.getMonthNameM(date.getMonth())}</div>
                    <div style={{marginTop: '1vw', fontWeight: 'bold'}}>Totale vakantiedagen: {this.state.totalDays[person.info_id]}</div>
                  </div>
                )}
                position={'top'}
                arrow
                distance={10}
                theme='kodict'
                className='absences_day' 
                style={{backgroundColor: Colors.color.orangeVacation}}
              >

              </Tooltip>
      
            );
  
            found = true;
  
            break;
  
          }
  
        }

      }

      if (found === false) {

        calendarBars.push(

          <div className='absences_day'>
            
          </div>
  
        );

      }

    }

    return calendarBars;

  }

  getAbsenceBarsYear() {

    let toReturn = [];
    let count = 0;

    for (const person of this.state.accounts) {

      const workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

      const userTotalDays = typeof this.state.totalDays[person.info_id] !== 'undefined' ? this.state.totalDays[person.info_id] : 0;
      const userTotalHours = typeof this.state.totalHours[person.info_id] !== 'undefined' ? this.state.totalHours[person.info_id] : 0;

      const userHasContract = typeof this.state.contracts[person.info_id] !== 'undefined';
      const contract = Constants.getCurrentContract(this.state.contracts[person.info_id]);
      let vacationDays = null;
      let vacationSaldo = null;

      let startBalance = 0;
      let balanceNow = 0;
      let substract = 0;
      let total = 0;

      const leaveData = this.state.allHours_users[person.info_id];

      const userLeaveBalance = leaveData.userLeaveBalances[0];

      if (Constants.isEmpty(userLeaveBalance) === false) {

        vacationSaldo = true;

        // LAST YEAR
        for (const yearKey in userLeaveBalance.total) {

          if (parseInt(yearKey) <= (this.state.year - 1)) {
            startBalance += userLeaveBalance.total[yearKey];
          }

        }

        // THIS YEAR
        if (Constants.isEmpty(userLeaveBalance.balance[this.state.year]) === false) {
          balanceNow += userLeaveBalance.balance[this.state.year];
        }

        // THIS YEAR SUBSTRACT
        if (Constants.isEmpty(userLeaveBalance.total[this.state.year]) === false) {
          substract += userLeaveBalance.reduce[this.state.year];
        }

        // TOTAL
        if (Constants.isEmpty(userLeaveBalance.total) === false) {

          for (const yearKey in userLeaveBalance.total) {

            if (parseInt(yearKey) <= (this.state.year)) {
              total += userLeaveBalance.total[yearKey];
            }

          }

        }

      }

      toReturn.push(

        <div key={person.info_id} className={'hr_hoursBar'} style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className={'hr_hoursBar_sub1'} style={{flex: 4}}>
            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && vacationSaldo !== null && this.props.individual !== true ?  
              <span className='defaultLinkO' onClick={() => this.props.history.push({ pathname: '/employees/' + person.info_id, menu: 'balances' })}>{userFullName}</span>
            :
              <span>{userFullName}</span>
            }
          </div>

          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
            <div className={'hr_hoursBar_sub4'}>
              <span>{vacationSaldo !== null ? startBalance < 0 ? <span style={{ color: Colors.color.redFilledIn }}>-{Constants.msToTime2(Math.abs(startBalance))}</span> : startBalance === 0 ? <span>{Constants.msToTime2(Math.abs(startBalance))}</span> : <span>{Constants.msToTime2(Math.abs(startBalance))}</span> : ''}</span>
            </div>
          : null}

          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
            <div className={'hr_hoursBar_sub4'}>
              <span>{vacationSaldo !== null ? balanceNow < 0 ? <span style={{ color: Colors.color.redFilledIn }}>-{Constants.msToTime2(Math.abs(balanceNow))}</span> : balanceNow === 0 ? <span>{Constants.msToTime2(Math.abs(balanceNow))}</span> : <span>{Constants.msToTime2(Math.abs(balanceNow))}</span> : ''}</span>
            </div>
          : null}

          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
            <div className={'hr_hoursBar_sub4'}>
              <span>{vacationSaldo !== null ? substract < 0 ? <span style={{ color: Colors.color.redFilledIn }}>-{Constants.msToTime2(Math.abs(substract))}</span> : substract === 0 ? <span>{Constants.msToTime2(Math.abs(substract))}</span> : <span>{Constants.msToTime2(Math.abs(substract))}</span> : ''}</span>
            </div>
          : null}

          <div className={Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ? 'hr_hoursBar_sub4' : 'hr_hoursBar_sub2'}>
            {userTotalDays}
          </div>

          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
            <div className={'hr_hoursBar_sub4'} style={{fontWeight: 'bold'}}>
              {vacationSaldo !== null ? total < 0 ? <b style={{ color: Colors.color.redFilledIn }}>-{Constants.msToTime2(Math.abs(total))}</b> : total === 0 ? <b>{Constants.msToTime2(Math.abs(total))}</b> : <b>{Constants.msToTime2(Math.abs(total))}</b> : ''}
            </div>
          : null}

          {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) && vacationSaldo !== null && this.props.individual !== true ?
            <div className='hr_hoursBar_sub4' style={{ cursor: 'pointer' }} onClick={() => this.props.history.push({ pathname: '/employees/' + person.info_id, menu: 'balances' })} >
              <i className='far fa-chevron-right' style={{marginRight: '0.5vw' }} />
            </div>
          : 
          this.props.individual === true || Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === false ? null
          :
            <div className='hr_hoursBar_sub4'>
              <span>&nbsp;</span>
            </div>
          }

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toReturn;

  }

  getAbsenceBars() {

    let toReturn = [];
    let count = 0;

    for (const person of this.state.accounts) {

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

      const userTotalDays = typeof this.state.totalDays[person.info_id] !== 'undefined' ? this.state.totalDays[person.info_id] : 0;
      //const userTotalHours = typeof this.state.totalHours[person.info_id] !== 'undefined' ? this.state.totalHours[person.info_id] : 0;
      let userTotalHours = 0;

      if (typeof this.state.absAmountReport[person.info_id] !== 'undefined') {

        for (const abs of this.state.absAmountReport[person.info_id]) {

          const date = Constants.stringToDate(abs.vakantie_begin);

          if (this.state.selectedMonth === date.getMonth()) {
            userTotalHours += parseFloat((Math.ceil(abs.vs_aantal * 100) / 100));
          }

        }

      }

      if (userTotalDays > 0) {

        toReturn.push(

          <div key={person.info_id} className={'hr_hoursBar'} style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className={'hr_hoursBar_sub1'}>
              {userFullName}
            </div>

            {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
              <div className={'hr_hoursBar_sub3'}>
                {Constants.msToTime2(userTotalHours * 60 * 60 * 1000)}
              </div>
            : null}

            <div className={'hr_hoursBar_sub3'}>
              {userTotalDays}
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toReturn;

  }

  async selectMonth(month) {

    if (this.state.selectedMonth === month && this.state.menu === 'absences') {
      return;
    }

    this.setState({ menu: 'absences', loading: true, chartLength: 0, selectedMonth: month, selectedWeek: null, dateText: `${this.state.year}-${(month + 1) < 10 ? '0' + (month + 1) : (month + 1)}-01` }, async() => {

      if (month !== null) {
        await this.reloadDataMonth(month + 1);
      } else {
        await this.reloadData(this.state.year);
      }

      this.setState({ loading: false });

    });

  }

  async selectWeek(data) {

    if (data !== null && this.state.selectedWeek === data.week) {
      return;
    }

    this.setState({ loading: true, chartLength: 0, selectedWeek: data === null ? null : data.week }, async() => {

      if (data !== null) {
        await this.reloadDataDates(`${this.state.year}-${(this.state.selectedMonth + 1) < 10 ? '0' + (this.state.selectedMonth + 1) : (this.state.selectedMonth + 1)}-${data.start < 10 ? '0' + data.start : data.start}`, `${this.state.year}-${(this.state.selectedMonth + 1) < 10 ? '0' + (this.state.selectedMonth + 1) : (this.state.selectedMonth + 1)}-${data.end < 10 ? '0' + data.end : data.end}`);
      } else {
        await this.reloadDataMonth(this.state.selectedMonth + 1);
      }

      this.setState({ loading: false });

    });

  }

  renderWeekNumbers() {

    let toReturn = [];

    const weeks = Constants.getWeeksStartAndEndInMonth(this.state.selectedMonth, this.state.year);

    for (const data of weeks) {

      toReturn.push(
        <div className={`employees_menuButton ${this.state.selectedWeek === data.week ? 'activeAv' : ''}`} onClick={() => this.selectWeek(data)}>
          <div>Week {data.week}</div>
        </div>
      );

    }

    return toReturn;

  }

  closeModal() {

    this.setState({ 
      showAddHolidayModal: false,
      modalData: null,
    });

  }

  async reloadScreen() {

    this.setState({loading: true});

    if (this.state.selectedMonth !== null) {
      await this.reloadDataMonth(this.state.selectedMonth);
    } else {
      await this.reloadData(this.state.year);
    }

    await this.getRequestsData();

    this.setState({loading: false});

  }

  selectMenu(menu) {

    if (this.state.menu === menu || this.state.loading === true) {
      return;
    }

    this.setState({ menu: menu });

  }

  setWorkDay(day) {

    if (day === 'mon') {
      localStorage.setItem('@absenceMonday', this.state.workWeekMonday === false ? '1' : '0');
      this.setState({ workWeekMonday: !this.state.workWeekMonday});
    } else if (day === 'tue') {
      localStorage.setItem('@absenceTuesday', this.state.workWeekTuesday === false ? '1' : '0');
      this.setState({ workWeekTuesday: !this.state.workWeekTuesday});
    } else if (day === 'wed') {
      localStorage.setItem('@absenceWednesday', this.state.workWeekWednesday === false ? '1' : '0');
      this.setState({ workWeekWednesday: !this.state.workWeekWednesday});
    } else if (day === 'thu') {
      localStorage.setItem('@absenceThursday', this.state.workWeekThursday === false ? '1' : '0');
      this.setState({ workWeekThursday: !this.state.workWeekThursday});
    } else if (day === 'fri') {
      localStorage.setItem('@absenceFriday', this.state.workWeekFriday === false ? '1' : '0');
      this.setState({ workWeekFriday: !this.state.workWeekFriday});
    } else if (day === 'sat') {
      localStorage.setItem('@absenceSaturday', this.state.workWeekSaturday === false ? '1' : '0');
      this.setState({ workWeekSaturday: !this.state.workWeekSaturday});
    } else if (day === 'sun') {
      localStorage.setItem('@absenceSunday', this.state.workWeekSunday === false ? '1' : '0');
      this.setState({ workWeekSunday: !this.state.workWeekSunday});
    }

  }

  getWorkDays() {

    let days = 0;

    if (this.state.workWeekMonday === true) {
      days++;
    }
    if (this.state.workWeekTuesday === true) {
      days++;
    }
    if (this.state.workWeekWednesday === true) {
      days++;
    }
    if (this.state.workWeekThursday === true) {
      days++;
    }
    if (this.state.workWeekFriday === true) {
      days++;
    }
    if (this.state.workWeekSaturday === true) {
      days++;
    }
    if (this.state.workWeekSunday === true) {
      days++;
    }

    return days;

  }

  getYearOptions() {

    let toReturn = [];

    for (let i = this.state.minYear; i <= this.state.maxYear; i++) {

      toReturn.push(
        <option value={i.toString()} selected={this.state.year === i}>{i.toString()}</option>
      );

    }

    return toReturn;

  }

  getMonthOptions() {

    let toReturn = [];

    for (let i = 0; i <= 11; i++) {

      toReturn.push(
        <option value={i.toString()} selected={this.state.selectedMonth === i}>{Constants.firstUppercase(Constants.getMonthNameM(i))}</option>
      );

    }

    return toReturn;

  }

  onChangeNumberDataFloat(what, min, max, event, data) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'overhours') {

      if (nr !== '') {
        this.hoursRef[data.vakantie_id].value = nr;
      } else {
        this.hoursRef[data.vakantie_id].value = '';
      }

    }

  }

  getOpenBusinessDaysWeek() {

    let count = 0;

    if (this.state.workWeekMonday === true) {
      count++;
    }
    if (this.state.workWeekTuesday === true) {
      count++;
    }
    if (this.state.workWeekWednesday === true) {
      count++;
    }
    if (this.state.workWeekThursday === true) {
      count++;
    }
    if (this.state.workWeekFriday === true) {
      count++;
    }
    if (this.state.workWeekSaturday === true) {
      count++;
    }
    if (this.state.workWeekSunday === true) {
      count++;
    }

    return count;

  }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  userWorksDay(data) {

    const date = Constants.stringToDate(data.vakantie_begin);

    if (typeof this.state.infoExtra[data.info_id] !== 'undefined') {

      const infoExtra = this.state.infoExtra[data.info_id];

      if (date.getDay() === 1 && infoExtra.info2_werkdag_maandag !== '1') {
        return false;
      } else if (date.getDay() === 2 && infoExtra.info2_werkdag_dinsdag !== '1') {
        return false;
      } else if (date.getDay() === 3 && infoExtra.info2_werkdag_woensdag !== '1') {
        return false;
      } else if (date.getDay() === 4 && infoExtra.info2_werkdag_donderdag !== '1') {
        return false;
      } else if (date.getDay() === 5 && infoExtra.info2_werkdag_vrijdag !== '1') {
        return false;
      } else if (date.getDay() === 6 && infoExtra.info2_werkdag_zaterdag !== '1') {
        return false;
      } else if (date.getDay() === 0 && infoExtra.info2_werkdag_zondag !== '1') {
        return false;
      }

    }

    return true;

  }

  getHolidayRequestBars() {

    let toRender = {
      open: [],
      accepted: [],
      declined: [],
    };

    let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));
    
    for (const data of this.state.holidayRequests) {

      const startDate = Constants.stringToDate(data.vakantie_begin);
      const endDate = Constants.stringToDate(data.vakantie_eind);

      const sameDay = startDate.getTime() === endDate.getTime();

      const userHasContract = typeof this.state.contracts[data.info_id] !== 'undefined';
      const contract = typeof this.state.contracts[data.info_id] !== 'undefined' ? this.state.contracts[data.info_id] : null;

      workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

      let fullWeekHours = this.getWorkDaysPersonWeek(data.info_id) * workDayHours;

      let vacationDays = null;
      let vacationSaldo = null;

      if (userHasContract === true) {

        if (Constants.isEmpty(contract.contract_vakantie_dagen) === false || Constants.isEmpty(contract.contract_vakantie_bovenwettelijk) === false) {
          vacationDays = 0;
          vacationDays += Constants.isEmpty(contract.contract_vakantie_dagen) === false ? parseInt(contract.contract_vakantie_dagen) : 0;
          vacationDays += Constants.isEmpty(contract.contract_vakantie_bovenwettelijk) === false ? parseInt(contract.contract_vakantie_bovenwettelijk) : 0;
        }

        if (vacationDays === 0 || contract.contract_type === '0') { // 0 = nulurencontract
          vacationDays = null;
        }

        if (vacationDays !== null && contract.contract_type !== '0') {

          vacationSaldo = vacationDays * workDayHours;

          if (typeof this.state.absAmount[data.info_id] !== 'undefined') {

            for (const abs of this.state.absAmount[data.info_id]) {
              vacationSaldo -= parseFloat(abs.vs_aantal);
            }

          }

        }

      }

      let vHours = {
        total: 0,
      };
      if (data.vakantie_geaccepteerd === '0') {

        if (sameDay === true) { // verlof

          if (typeof this.state.freeHours[data.vakantie_id] !== 'undefined') {

            vHours = this.state.freeHours[data.vakantie_id];
            vHours.total = parseFloat(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseFloat(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
            
            if (vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00') {
              vHours.total = workDayHours;
            }

          } else {
            vHours.total = workDayHours;
          }

          if (this.userWorksDay(data) === false) {
            vHours.total = 0;
          }

        } else {

          const infoExtra = this.state.infoExtra;

          let week = Constants.getWeekNumber2(startDate);

          let absWeek = {};

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            
            if ( userHasContract === true 
              && ( (contract.contract_type === '2' && Constants.isEmpty(contract.contract_uren) === false) ) ) {
            
              if (Constants.hasGeneralWorkDays() === true) {

                // get week at startdate, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                if (week !== Constants.getWeekNumber2(currentDate)) {
                  week = Constants.getWeekNumber2(currentDate);
                  fullWeekHours = this.getWorkDaysPersonWeek(data.info_id) * workDayHours;
                }

                if (typeof absWeek[week] === 'undefined') {
                  absWeek[week] = 0;
                }

                fullWeekHours -= workDayHours;

                if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  if (Constants.personHasWorkDays(infoExtra[data.info_id]) === true) {

                    // person has working days, check work days personal and general
                    if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[data.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[data.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[data.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[data.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[data.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[data.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[data.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                      //vHours.total += workDayHours;
                      absWeek[week] += workDayHours;
                    }

                  } else { // just count the days
                    //vHours.total += workDayHours;
                    absWeek[week] += workDayHours;
                  }

                }

              } else { // just count all days
                //vHours.total += workDayHours;
                absWeek[week] += workDayHours;
              }

              if (absWeek[week] > parseFloat(contract.contract_uren)) {
                //vHours.total = parseFloat(contract.contract_uren);
                absWeek[week] = parseFloat(contract.contract_uren);
              }
              
            } else if ( userHasContract === true 
              && ( (contract.contract_type === '1' && Constants.isEmpty(contract.contract_min) === false) ) ) {
            
              if (Constants.hasGeneralWorkDays() === true) {

                // get week at startdate, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                if (week !== Constants.getWeekNumber2(currentDate)) {
                  week = Constants.getWeekNumber2(currentDate);
                  fullWeekHours = this.getWorkDaysPersonWeek(data.info_id) * workDayHours;
                }

                if (typeof absWeek[week] === 'undefined') {
                  absWeek[week] = 0;
                }

                fullWeekHours -= workDayHours;

                if (fullWeekHours < parseFloat(contract.contract_min)) {

                  if (Constants.personHasWorkDays(infoExtra[data.info_id]) === true) {

                    // person has working days, check work days personal and general
                    if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[data.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[data.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[data.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[data.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[data.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[data.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[data.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                      //vHours.total += workDayHours;
                      absWeek[week] += workDayHours;
                    }

                  } else { // just count the days
                    //vHours.total += workDayHours;
                    absWeek[week] += workDayHours;
                  }

                }

              } else { // just count all days
                //vHours.total += workDayHours;
                absWeek[week] += workDayHours;
              }

              if (absWeek[week] > parseFloat(contract.contract_min)) {
                //vHours.total = parseFloat(contract.contract_min);
                absWeek[week] = parseFloat(contract.contract_min);
              }
              
            }

            index++;

          }

          if (Object.keys(absWeek).length > 0) {
            for (let key in absWeek) {
              vHours.total += absWeek[key];
            }
          }

        }

      } else {

        if (typeof this.state.freeHours[data.vakantie_id] !== 'undefined') {
          vHours = this.state.freeHours[data.vakantie_id];
        }

        let found = false;

        if (typeof this.state.absAmount[data.info_id] !== 'undefined') {

          for (const abs of this.state.absAmount[data.info_id]) {
            if (data.vakantie_id === abs.vs_vakantie_id) {
              found = true;
              vHours.total = parseFloat(abs.vs_aantal);
              break;
            }
          }

        }

        if (found === false) {
          vHours.total = 0;
        }

      }

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

      if (data.vakantie_geaccepteerd === '0') { // open

        toRender.open.push(

          <div className='hr_timeRow'>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub1'>

              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()}-{(startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)}-{startDate.getFullYear()}{sameDay === false ? ' t/m ' : ' '}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate()}-{(endDate.getMonth() + 1) < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)}-{endDate.getFullYear()}</span> : <span>({typeof vHours.vu_begin === 'undefined' ? 'hele dag' : vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ? 'hele dag' : `${vHours.vu_begin.substr(0, 5)} - ${vHours.vu_eind.substr(0, 5)}`})</span>}</span>

              <Tooltip
                options
                html={(
                  <div className='appoptions_tooltipContainer'>
                    <span>Bekijk op de planner</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginLeft: '0.6vw'}}
              >
                <i className='far fa-external-link' style={{cursor: 'pointer', fontSize: '0.6vw'}} onClick={() => this.props.history.push({ pathname: `/planner`, selectedDate: startDate, highlight: data.info_id })} />
              </Tooltip>

            </div>

            {/* opmerking */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {Constants.isEmpty(data.vakantie_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{data.vakantie_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>
              :
                  <span>&nbsp;</span>
              }
            </div>

            {/* contracturen */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {contract !== null && contract.contract_type === '1' ?
                <i>{contract.contract_min !== null ? Constants.round(parseFloat(contract.contract_min), true) : 'n.v.t.'}</i>
              :
              contract !== null && contract.contract_type === '2' ?
                <i>{contract.contract_uren !== null ? Constants.round(parseFloat(contract.contract_uren), true) : 'n.v.t.'}</i>
              : null}
            </div>

            <div className='hr_hoursBar_sub3'>
              {vacationDays !== null && contract.contract_type !== '0' ?
                <input
                  ref={(ref) => this.hoursRef[data.vakantie_id]=ref}
                  className={'hr_inputBoxTime_small'}
                  defaultValue={vHours.total.toString()}
                  onChange={(event) => this.onChangeNumberDataFloat('overhours', 0, 999999999, event, data)}
                  //value={data.ov_aantal}
                  placeholder={'Verlofuren'}
                  type='number'
                  step={0.01}
                />
              :
                <span>n.v.t.</span>
              }
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{vacationSaldo !== null && contract.contract_type !== '0' ? Constants.round(vacationSaldo, true) : 'n.v.t.'}</b>
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.editHolidayAlert(contract, data, true)}>
                <i className='far fa-check' />
              </div>

              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.editHolidayAlert(contract, data, false)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='far fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1, cursor: 'pointer'}} onClick={() => this.setState({showAddHolidayModal: true, modalData: data})} />
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteHolidayAlert(data)} />
            </div>

          </div>

        );

      } else if (data.vakantie_geaccepteerd === '1') { // accepted

        toRender.accepted.push(

          <div className='hr_timeRow'>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()}-{(startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)}-{startDate.getFullYear()}{sameDay === false ? ' t/m ' : ' '}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate()}-{(endDate.getMonth() + 1) < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)}-{endDate.getFullYear()}</span> : <span>({typeof vHours.vu_begin === 'undefined' ? 'hele dag' : vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ? 'hele dag' : `${vHours.vu_begin.substr(0, 5)} - ${vHours.vu_eind.substr(0, 5)}`})</span>}</span>
            </div>

            {/* opmerking */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {Constants.isEmpty(data.vakantie_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{data.vakantie_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>
              :
                  <span>&nbsp;</span>
              }
            </div>

            {/* contracturen */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {contract !== null && contract.contract_type === '1' ?
                <i>{contract.contract_min !== null ? Constants.round(parseFloat(contract.contract_min), true) : 'n.v.t.'}</i>
              :
              contract !== null && contract.contract_type === '2' ?
                <i>{contract.contract_uren !== null ? Constants.round(parseFloat(contract.contract_uren), true) : 'n.v.t.'}</i>
              : null}
            </div>

            <div className='hr_hoursBar_sub3'>
              {vacationDays !== null && contract.contract_type !== '0' ?
                <b>{Constants.round(vHours.total, true)}</b>
              :
                <span>n.v.t.</span>
              }
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{vacationSaldo !== null && contract.contract_type !== '0' ? Constants.round(vacationSaldo, true) : 'n.v.t.'}</b>
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_declineButton' onClick={() => this.editHolidayAlert(contract, data, false)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='far fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1, cursor: 'pointer'}} onClick={() => this.setState({showAddHolidayModal: true, modalData: data})} />
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteHolidayAlert(data)} />
            </div>

          </div>

        );

      } else if (data.vakantie_geaccepteerd === '2') { // declined

        toRender.declined.push(

          <div className='hr_timeRow'>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub1'>
              <span>{Constants.getShortDayName(startDate).toLowerCase()} {startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()}-{(startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)}-{startDate.getFullYear()}{sameDay === false ? ' t/m ' : ' '}{sameDay === false ? <span>{Constants.getShortDayName(endDate).toLowerCase()} {endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate()}-{(endDate.getMonth() + 1) < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)}-{endDate.getFullYear()}</span> : <span>({typeof vHours.vu_begin === 'undefined' ? 'hele dag' : vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ? 'hele dag' : `${vHours.vu_begin.substr(0, 5)} - ${vHours.vu_eind.substr(0, 5)}`})</span>}</span>
            </div>

            {/* opmerking */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {Constants.isEmpty(data.vakantie_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{data.vakantie_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='fal fa-comment' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>
              :
                  <span>&nbsp;</span>
              }
            </div>

            {/* contracturen */}
            <div className='hr_hoursBar_sub3' style={{paddingRight: '0.5%'}}>
              {contract !== null && contract.contract_type === '1' ?
                <i>{contract.contract_min !== null ? Constants.round(parseFloat(contract.contract_min), true) : 'n.v.t.'}</i>
              :
              contract !== null && contract.contract_type === '2' ?
                <i>{contract.contract_uren !== null ? Constants.round(parseFloat(contract.contract_uren), true) : 'n.v.t.'}</i>
              : null}
            </div>

            <div className='hr_hoursBar_sub3'>
              {vacationDays !== null && contract.contract_type !== '0' ?
                <input
                  ref={(ref) => this.hoursRef[data.vakantie_id]=ref}
                  className={'hr_inputBoxTime_small'}
                  defaultValue={vHours.total.toString()}
                  onChange={(event) => this.onChangeNumberDataFloat('overhours', 0, 999999999, event, data)}
                  //value={data.ov_aantal}
                  placeholder={'Verlofuren'}
                  type='number'
                  step={0.01}
                />
              :
                <span>n.v.t.</span>
              }
            </div>

            <div className='hr_hoursBar_sub3'>
              <b>{vacationSaldo !== null && contract.contract_type !== '0' ? Constants.round(vacationSaldo, true) : 'n.v.t.'}</b>
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => this.editHolidayAlert(contract, data, true)}>
                <i className='far fa-check' />
              </div>

              <div className='hr_invisibleButton' style={{marginLeft: '6%'}}>
                &nbsp;
              </div>

            </div>

            <div className='hr_hoursBar_sub4'>
              <i className='far fa-edit' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1, cursor: 'pointer'}} onClick={() => this.setState({showAddHolidayModal: true, modalData: data})} />
              <i className='fas fa-minus-circle' style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '6%'}} onClick={() => this.deleteHolidayAlert(data)} />
            </div>

          </div>

        );

      }

    }

    return toRender;

  }

  editHolidayAlert(contract, data, accepted) {

    this.setState({
        alertTitle: accepted === true ? 'Goedkeuren' : 'Afwijzen',
        alertBody: `Weet je zeker dat je deze verlofaanvraag wilt ${accepted === true ? 'goedkeuren' : 'afwijzen'}?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', accepted === true ? 'Goedkeuren' : 'Afwijzen'],
        alertButtonAction: this.editHoliday.bind(this, contract, data, accepted),
        showAlert: true,
    });

  }

  async editHoliday(contract, data, accepted) {

    this.setState({reloading: true});

    // if(this.checkValidations() === false) {
    //   this.setState({loading: false});
    //   return;
    // }

    const startDate = Constants.stringToDate(data.vakantie_begin);
    const endDate = Constants.stringToDate(data.vakantie_eind);

    const sameDay = startDate.getTime() === endDate.getTime();

    const added = await APIEditHoliday.Request(
      data.vakantie_begin,
      data.vakantie_eind,
      data.vakantie_opmerking,
      accepted === true ? 1 : 2,
      data.vakantie_id
    );

    if(added === true) {

      await KodictNotifications.sendPushNotification(
        data.info_id,
        'holiday',
        'Vakantie',
        `Jouw ${sameDay === true ? 'verlofaanvraag voor' : 'vakantieverzoek van'} ${startDate.toLocaleDateString()}${sameDay === true ? `` : ` t/m ${endDate.toLocaleDateString()}`} is ${accepted === true ? 'goedgekeurd' : 'geweigerd'}`
      );

      if (contract.contract_type === '2' || contract.contract_type === '1') { // vast // min max

        let vacationDays = null;

        if (Constants.isEmpty(contract.contract_vakantie_dagen) === false || Constants.isEmpty(contract.contract_vakantie_bovenwettelijk) === false) {
          vacationDays = 0;
          vacationDays += Constants.isEmpty(contract.contract_vakantie_dagen) === false ? parseInt(contract.contract_vakantie_dagen) : 0;
          vacationDays += Constants.isEmpty(contract.contract_vakantie_bovenwettelijk) === false ? parseInt(contract.contract_vakantie_bovenwettelijk) : 0;
        }

        if (vacationDays === 0) { // 0 = nulurencontract
          vacationDays = null;
        }

        if (accepted === true && vacationDays !== null) {

          const absenceHours = parseFloat(this.hoursRef[data.vakantie_id].value);

          if (isNaN(absenceHours) === false && absenceHours > 0) {
            await APIADD.Request(`INSERT INTO VerlofSaldo`, `VALUES (NULL, ${data.info_id}, ${data.vakantie_id}, ${absenceHours})`);
          }

        } else if (data.vakantie_geaccepteerd === '1') {
          await APIDELETE.Request(`DELETE FROM VerlofSaldo`, `WHERE vs_vakantie_id = ${data.vakantie_id}`);
        }

      }

      this.setState({loading: true});

      await this.getRequestsData();

    } else {

      {/* console.log('error'); */}

      this.setState({loading: false, reloading: false});

    }

    this.setState({loading: false, reloading: false});

  }

  deleteHolidayAlert(data) {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: `Weet je zeker dat je dit verlof wilt verwijderen?`,
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteHoliday.bind(this, data),
        showAlert: true,
    });

  }

  async deleteHoliday(data) {

    this.setState({reloading: true});

    // if(this.checkValidations() === false) {
    //   this.setState({loading: false});
    //   return;
    // }

    const startDate = Constants.stringToDate(data.vakantie_begin);
    const endDate = Constants.stringToDate(data.vakantie_eind);

    const sameDay = startDate.getTime() === endDate.getTime();

    const added = await APIDELETE.Request(`DELETE FROM Vakantie`, `WHERE vakantie_id = ${data.vakantie_id}`);

    if(added === true) {

      await KodictNotifications.sendPushNotification(
        data.info_id,
        'holiday',
        'Vakantie',
        `Jouw ${sameDay === true ? 'verlofaanvraag voor' : 'vakantieverzoek van'} ${startDate.toLocaleDateString()}${sameDay === true ? `` : ` t/m ${endDate.toLocaleDateString()}`} is verwijderd`
      );

      await this.getRequestsData();

    } else {

      this.setState({reloading: false});

    }

    this.setState({reloading: false});

  }

  getEmployeeSelects() {

    let toRender = [];

    for (const acc of this.state.accounts) {
      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;
      toRender.push(<option value={acc.info_id} selected={this.state.reqEmployee === acc.info_id}>{userFullName}</option>)
    }

    return toRender;

  }

  changeReportEmployee(e) {

    this.setState({ reqEmployee: e.target.value });

  }

  changeReportAccepted(e) {

    this.setState({ reqAccepted: e.target.value });

  }

  changeReportSort(e) {

    this.setState({ reqSort: e.target.value });

  }

  async selectReportStartDate(date) {

    this.setState({ reqStart: date });

  }

  async selectReportEndDate(date) {

    this.setState({ reqEnd: date });

  }

  async changeDisplay() {

    this.setState({ loading: true });

    await this.getRequestsData();

    this.setState({ loading: false });

  }

  render() {

    const holidayRequestBars = this.getHolidayRequestBars();

    const workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

    if (this.props.individual !== true) {

      return (
        this.state.loading === false ?
          <div>

            {this.state.showAlert === true ?
          
              <BetterAlert 
                _closeAlert={this.closeAlert.bind(this)}
                _title={this.state.alertTitle}
                _body={this.state.alertBody}
                _buttons={this.state.alertButtons}
                _buttonText={this.state.alertButtonText}
                _buttonAction={this.state.alertButtonAction}
              />

            :

              null
        
            }

            {this.state.showAddHolidayModal === true ?
      
              <AddHolidayModal 
                _closeModal={this.closeModal.bind(this)}
                _modalData={this.state.modalData}
                _reloadScreen={this.reloadScreen.bind(this)}
              />

            :

              null

            }

            {this.state.reloading === true ?
                  
              <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

              null

            }


            <div className="employees_addButtons_container" style={{marginTop: '2vw'}}>

              <div className="planner_new_addButton" style={{marginLeft: 0, width: '8.5vw'}} onClick={() => this.setState({showAddHolidayModal: true})}>Verlof toevoegen</div>

            </div>

            <div className="employees_addButtons_container">

              <div style={{width: '10%'}}>
                <select
                  name="Selecteer een functie"
                  className={'hr_dropdown'}
                  onChange={(event) => this.nextYear(parseInt(event.target.value))}
                >
                  <option value={'null'} disabled>Jaar</option>
                  {this.getYearOptions()}
                </select>
              </div>

              <div style={{width: '12%', marginLeft: '0.6vw'}}>
                <select
                  name="Selecteer een functie"
                  className={'hr_dropdown'}
                  onChange={(event) => this.selectMonth(event.target.value === 'null' ? null : parseInt(event.target.value))}
                >
                  <option value={'null'} disabled>Maand</option>
                  <option value={'null'} selected={this.state.selectedMonth === 'null'}>Volledig jaar</option>
                  {this.getMonthOptions()}
                </select>
              </div>

            </div>
            
            {this.state.selectedMonth === null ?

              <div>

                <div className={'hr_hoursBar_title'}>
                  <div className={'hr_hoursBar_sub1'} style={{flex: 4}}>
                    <div>Naam</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Saldo ({this.state.year - 1})</div>
                    </div>
                  : null}

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Saldo ({this.state.year})</div>
                    </div>
                  : null}

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Verbruikt</div>
                    </div>
                  : null}

                  <div className={Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ? 'hr_hoursBar_sub4' : 'hr_hoursBar_sub2'}>
                    <div>Dagen</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Huidig saldo</div>
                    </div>
                  : null}

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>&nbsp;</div>
                    </div>
                  : null}

                </div>

                {this.getAbsenceBarsYear()}

              </div>

            : 

              <div>

                <div className={'hr_hoursBar_title'}>
                  <div className={'hr_hoursBar_sub1'}>
                    <div>Naam</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub3'}>
                      <div>Verbruikt</div>
                    </div>
                  : null}

                  <div className={'hr_hoursBar_sub3'}>
                    <div>Dagen</div>
                  </div>
                </div>

                {this.getAbsenceBars()}

              </div>

            }

          </div>
        :
                    <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
      );

    } else {

      return (
        this.state.loading === false ?
          <div>

            {this.state.showAlert === true ?
        
              <BetterAlert 
                _closeAlert={this.closeAlert.bind(this)}
                _title={this.state.alertTitle}
                _body={this.state.alertBody}
                _buttons={this.state.alertButtons}
                _buttonText={this.state.alertButtonText}
                _buttonAction={this.state.alertButtonAction}
              />

            :

              null
      
            }

            {this.state.showAddHolidayModal === true ?
    
              <AddHolidayModal 
                _closeModal={this.closeModal.bind(this)}
                _modalData={this.state.modalData}
                _reloadScreen={this.reloadScreen.bind(this)}
                _individual={this.props.individual}
                _individual_userID={this.props.individual_userID}
                />

            : null}

            {this.state.reloading === true ?
                
              <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

              null

            }


            <div className="employees_addButtons_container" style={{marginTop: '2vw'}}>

              <div className="planner_new_addButton" style={{marginLeft: 0, width: '8.5vw'}} onClick={() => this.setState({showAddHolidayModal: true})}>Verlof toevoegen</div>

            </div>

            <div className="employees_addButtons_container">

              <div style={{width: '15%'}}>
                <select
                  name="Selecteer een functie"
                  className={'hr_dropdown'}
                  onChange={(event) => this.nextYear(parseInt(event.target.value))}
                >
                  <option value={'null'} disabled>Jaar</option>
                  {this.getYearOptions()}
                </select>
              </div>

              <div style={{width: '18%', marginLeft: '0.6vw'}}>
                <select
                  name="Selecteer een functie"
                  className={'hr_dropdown'}
                  onChange={(event) => this.selectMonth(event.target.value === 'null' ? null : parseInt(event.target.value))}
                >
                  <option value={'null'} disabled>Maand</option>
                  <option value={'null'} selected={this.state.selectedMonth === 'null'}>Volledig jaar</option>
                  {this.getMonthOptions()}
                </select>
              </div>

            </div>
          
            {this.state.selectedMonth === null ?

              <div>

                <div className={'hr_hoursBar_title'}>
                  <div className={'hr_hoursBar_sub1'} style={{flex: 4}}>
                    <div>Naam</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Saldo ({this.state.year - 1})</div>
                    </div>
                  : null}

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Saldo ({this.state.year})</div>
                    </div>
                  : null}

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Verbruikt</div>
                    </div>
                  : null}

                  <div className={Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ? 'hr_hoursBar_sub4' : 'hr_hoursBar_sub2'}>
                    <div>Dagen</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub4'}>
                      <div>Huidig saldo</div>
                    </div>
                  : null}

                </div>

                {this.getAbsenceBarsYear()}

              </div>

            : 

              <div>

                <div className={'hr_hoursBar_title'}>
                  <div className={'hr_hoursBar_sub1'}>
                    <div>Naam</div>
                  </div>

                  {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                    <div className={'hr_hoursBar_sub3'}>
                      <div>Verbruikt</div>
                    </div>
                  : null}

                  <div className={'hr_hoursBar_sub3'}>
                    <div>Dagen</div>
                  </div>
                </div>

                {this.getAbsenceBars()}

              </div>

            }

          </div>
        :
          <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
      );

    }
  }
  
}

export default Absences;