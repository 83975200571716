


import CryptoJS from 'crypto-js';

import Data from '../../constants/Data';

class APIDELETE {

    async Request(del, where, storecode = Data.data.storeCode) {
        
        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        where = encodeURIComponent(where);

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/DELETE.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${storecode}&user=${username}&delete=${del}&where=${where}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === "1") {

            return true;

        } else if (sqlData === "0") {

            return false;

        } else {

            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIDELETE());