/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './holidays.css';
import '../tradeRequests/tradeRequests.css'
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';
import '../trade/trade.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import Colors from '../../../constants/Colors';

import APIGetOwnHolidays from '../../../classes/APIGetOwnHolidays';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import HolidaysModal from './holidaysModal';
import BetterAlert from '../../../components/alert/alert';
import APIGetDeadlinesAbsence from '../../../classes/APIGetDeadlinesAbsence';
import APIGET from '../../../classes/global/APIGET';

class Holidays extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      holidays: [],
      deadlinesAbsence: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      oneDay: false,

      vHours: {},
      infoExtra: {},

      yearData: [],
      yearDataOpen: [],

      yearHoursAccepted: 0,
      yearHoursOpen: 0,

      yearDaysAccepted: 0,
      yearDaysOpen: 0,

      workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
      workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
      workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
      workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
      workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
      workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
      workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    await this.getHolidays();
    await this.getDeadlinesAbsence();

    const holidayHours = await APIGET.Request(
      `SELECT *`,
      `FROM VerlofUren`,
      `LEFT JOIN Vakantie ON vakantie_id = vu_vakantie_id`,
      `WHERE vakantie_info_id = ${Data.data.accountData.info_id}`,
      null,
      null
    );
    const vHoursObj = Constants.convertToObject(holidayHours, 'vakantie_id');

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    await this.getHolidayData(vHoursObj, infoExtraObj);

    this.setState({vHours: vHoursObj, infoExtra: infoExtraObj, loading: false});

  }

  async getHolidayData(vHours, infoExtra) {

    const yearData = await APIGET.Request(
      `SELECT *`,
      `FROM Vakantie`,
      `LEFT JOIN VerlofUren ON vu_vakantie_id = vakantie_id`,
      `WHERE vakantie_info_id = ${Data.data.accountData.info_id} AND (YEAR(vakantie_begin) = ${Constants.getDateNowStatic().getFullYear()} OR YEAR(vakantie_eind) = ${Constants.getDateNowStatic().getFullYear()})`,
      null,
      null
    );

    const workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

    const holidayDays = yearData;

    const dateNow = Constants.getDateNowStatic();

    if(holidayDays != null && holidayDays.length > 0) {

      let allHolidayDays = [];
      let allHolidayDaysOpen = [];
      let yearHoursAccepted = 0;
      let yearDaysAccepted = 0;
      let yearHoursOpen = 0;
      let yearDaysOpen = 0;

      for(let key in holidayDays) { //iterate through holidays ranges from DB
          
        if(holidayDays[key].vakantie_geaccepteerd === '1') {
  
              let currentDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
              let endDate = Constants.stringToDate(holidayDays[key].vakantie_eind);

              if (holidayDays[key].vakantie_begin === holidayDays[key].vakantie_eind) {

                allHolidayDays.push({...holidayDays[key], date: currentDate});

                if (Constants.hasGeneralWorkDays() === true) {

                    if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                        // person has working days, check work days personal and general
                        if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                            yearDaysAccepted += 1;
                        }

                    } else { // just count the days
                        yearDaysAccepted += 1;
                    }

                } else { // just count all days
                    yearDaysAccepted += 1;
                }

                if (typeof vHours[holidayDays[key].vakantie_id] !== 'undefined') {
                  if (vHours[holidayDays[key].vakantie_id].vu_begin === '00:00:00' && vHours[holidayDays[key].vakantie_id].vu_eind === '00:00:00') {
                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                            // person has working days, check work days personal and general
                            if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearHoursAccepted += workDayHours;
                            }

                        } else { // just count the days
                            yearHoursAccepted += workDayHours;
                        }

                    } else { // just count all days
                        yearHoursAccepted += workDayHours;
                    }
                  } else {

                    const calcHours = parseInt(Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_eind) - Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_eind) - Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_begin));

                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                            // person has working days, check work days personal and general
                            if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearHoursAccepted += calcHours;
                            }

                        } else { // just count the days
                            yearHoursAccepted += calcHours;
                        }

                    } else { // just count all days
                        yearHoursAccepted += calcHours;
                    }
                  }
                } else {
                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                            // person has working days, check work days personal and general
                            if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearHoursAccepted += workDayHours;
                            }

                        } else { // just count the days
                            yearHoursAccepted += workDayHours;
                        }

                    } else { // just count all days
                        yearHoursAccepted += workDayHours;
                    }
                }

              } else {
  
                let index = 0;
                while(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index).getTime() <= endDate.getTime()) {

                  if (currentDate.getFullYear() !== dateNow.getFullYear()) {

                    index++;

                  } else {

                    const dateIndex = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index);

                    allHolidayDays.push({...holidayDays[key], date: dateIndex});

                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                            // person has working days, check work days personal and general
                            if (((dateIndex.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (dateIndex.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (dateIndex.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (dateIndex.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (dateIndex.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (dateIndex.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (dateIndex.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearDaysAccepted += 1;
                                yearHoursAccepted += workDayHours;
                            }

                        } else { // just count the days
                            yearDaysAccepted += 1;
                            yearHoursAccepted += workDayHours;
                        }

                    } else { // just count all days
                        yearDaysAccepted += 1;
                        yearHoursAccepted += workDayHours;
                    }

                    index++;

                  }
    
                }

              }
  
          }

          if(holidayDays[key].vakantie_geaccepteerd === '0') {
  
            let currentDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
            let endDate = Constants.stringToDate(holidayDays[key].vakantie_eind);

            if (holidayDays[key].vakantie_begin === holidayDays[key].vakantie_eind) {

              allHolidayDaysOpen.push({...holidayDays[key], date: currentDate});

                if (Constants.hasGeneralWorkDays() === true) {

                    if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                        // person has working days, check work days personal and general
                        if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                            yearDaysOpen += 1;
                        }

                    } else { // just count the days
                        yearDaysOpen += 1;
                    }

                } else { // just count all days
                    yearDaysOpen += 1;
                }

              if (typeof vHours[holidayDays[key].vakantie_id] !== 'undefined') {
                if (vHours[holidayDays[key].vakantie_id].vu_begin === '00:00:00' && vHours[holidayDays[key].vakantie_id].vu_eind === '00:00:00') {
                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {
    
                            // person has working days, check work days personal and general
                            if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearHoursOpen += workDayHours;
                            }
    
                        } else { // just count the days
                            yearHoursOpen += workDayHours;
                        }
    
                    } else { // just count all days
                        yearHoursOpen += workDayHours;
                    }
                } else {

                    const calcHours = parseInt(Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_eind) - Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_eind) - Constants.timeStringToFloat(vHours[holidayDays[key].vakantie_id].vu_begin));

                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {
        
                            // person has working days, check work days personal and general
                            if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearHoursOpen += calcHours;
                            }
        
                        } else { // just count the days
                            yearHoursOpen += calcHours;
                        }
        
                    } else { // just count all days
                        yearHoursOpen += calcHours;
                    }
                }
              }

            } else {
  
              let index = 0;
              while(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index).getTime() <= endDate.getTime()) {

                if (currentDate.getFullYear() !== dateNow.getFullYear()) {

                  index++;

                } else {

                    const dateIndex = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + index);

                    allHolidayDaysOpen.push({...holidayDays[key], date: dateIndex});

                    if (Constants.hasGeneralWorkDays() === true) {

                        if (Constants.personHasWorkDays(infoExtra[holidayDays[key].vakantie_info_id]) === true) {

                            // person has working days, check work days personal and general
                            if (((dateIndex.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_vrijdag === '1') || (dateIndex.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_donderdag === '1') || (dateIndex.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_woensdag === '1') || (dateIndex.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_dinsdag === '1') || (dateIndex.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_maandag === '1') || (dateIndex.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zaterdag === '1') || (dateIndex.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[holidayDays[key].vakantie_info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                                yearDaysOpen += 1;
                                yearHoursOpen += workDayHours;
                            }

                        } else { // just count the days
                            yearDaysOpen += 1;
                            yearHoursOpen += workDayHours;
                        }

                    } else { // just count all days
                        yearDaysOpen += 1;
                        yearHoursOpen += workDayHours;
                    }

                    index++;

                }
    
              }

            }
  
        }

      }

      this.setState({yearData: allHolidayDays, yearDataOpen: allHolidayDaysOpen, yearHoursAccepted: yearHoursAccepted, yearHoursOpen: yearHoursOpen, yearDaysAccepted: yearDaysAccepted, yearDaysOpen: yearDaysOpen});

    }

  }

  openHolidaysModal(mode, data, oneDay) {

    if (typeof data !== 'undefined' && data !== null) {
      data.hours = typeof this.state.vHours[data.vakantie_id] !== 'undefined' ? this.state.vHours[data.vakantie_id] : null;
    }

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
      oneDay: oneDay === true,
    });

  }

  async getDeadlinesAbsence() {

    let deadlines = await APIGetDeadlinesAbsence.Request();

    if(deadlines === 'error') {

        deadlines = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    this.setState({ deadlinesAbsence: deadlines });

  }

  async getHolidays() {

    let holidays = await APIGetOwnHolidays.Request();

    if(holidays === 'error') {

        holidays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(holidays === null) {

        holidays = [];

    }

    this.setState({ holidays: holidays });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getHolidays();
    await this.getDeadlinesAbsence();

    const holidayHours = await APIGET.Request(
      `SELECT *`,
      `FROM VerlofUren`,
      `LEFT JOIN Vakantie ON vakantie_id = vu_vakantie_id`,
      `WHERE vakantie_info_id = ${Data.data.accountData.info_id}`,
      null,
      null
    );
    const vHoursObj = Constants.convertToObject(holidayHours, 'vakantie_id');

    await this.getHolidayData(vHoursObj, this.state.infoExtra);

    this.setState({vHours: vHoursObj, reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getHolidayBarsOpen() {

    let holidays = [];

    for(let key in this.state.holidays) {

        const holiday = this.state.holidays[key];

        if(holiday.vakantie_geaccepteerd === '0') {

            const date = Constants.stringToDate(holiday.vakantie_begin);
            const endDate = Constants.stringToDate(holiday.vakantie_eind);

            const sameDay = date.getTime() === endDate.getTime();

            let vHours = null;
            if (sameDay === true) { // verlof
              if (typeof this.state.vHours[holiday.vakantie_id] !== 'undefined') {
                vHours = this.state.vHours[holiday.vakantie_id];
                vHours.total = parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
              }
            }

            holidays.push(
                // <div 
                //     className={'tradeRequests_tradeBar'} 
                //     style={{borderColor: Colors.color.greyNotFilled, height: '3vw', alignItems: 'center'}}
                //     onClick={() => this.openHolidaysModal(1, holiday, sameDay)}
                // >

                //     {date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()} {sameDay === false ? `t/m ${endDate.getDate()} ${Constants.getMonthName(endDate)} ${endDate.getFullYear()}` : null}

                // </div>

            <div 
                className={'trade_tradeBar'} 
                style={{borderColor: Colors.color.greyNotFilled, backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1)}}
            >   

                <div style={{backgroundColor: Colors.color.greyNotFilled, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                  <span style={{textAlign: 'center', color: Constants.getContrast(Colors.color.greyNotFilled)}}>In afwachting van een goedkeuring</span>
                </div>

                <div className='trade_tradeContainer'>

                  <div className={'trade_functionContainer'}>

                    <div>{date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()} {sameDay === false ? `t/m ${endDate.getDate()} ${Constants.getMonthName(endDate)} ${endDate.getFullYear()}` : null}</div>

                    {vHours !== null ?
                      vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ?
                        <div style={{marginTop: 3}}>Hele dag (8 uren)</div>
                      :
                        <div style={{marginTop: 3}}>{vHours.vu_begin.substr(0,5)} - {vHours.vu_eind.substr(0,5)} ({vHours.total} {vHours.total === 1 ? 'uur' : 'uren'})</div>
                    : null}

                    <div style={{marginTop: 3}}>{holiday.vakantie_opmerking === 'null' ? '' : holiday.vakantie_opmerking}</div>

                    <div className='trade_normalButton2' style={{marginTop: 8}} onClick={() => this.openHolidaysModal(1, holiday, sameDay)}>
                      Wijzigen
                    </div>

                  </div>

                </div>

            </div>
            );

        }

    }

    return holidays;

  }

  getHolidayBarsAccepted() {

    let holidays = [];

    for(let key in this.state.holidays) {

        const holiday = this.state.holidays[key];

        if(holiday.vakantie_geaccepteerd === '1') {

            const date = Constants.stringToDate(holiday.vakantie_begin);
            const endDate = Constants.stringToDate(holiday.vakantie_eind);

            const sameDay = date.getTime() === endDate.getTime();

            let vHours = null;
            if (sameDay === true) { // verlof
              if (typeof this.state.vHours[holiday.vakantie_id] !== 'undefined') {
                vHours = this.state.vHours[holiday.vakantie_id];
                vHours.total = parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
              }
            }

            holidays.push(
                // <div 
                //     className={'tradeRequests_tradeBar'} 
                //     style={{borderColor: Colors.color.greenFilledIn, height: '3vw', alignItems: 'center'}}
                //     onClick={() => this.openHolidaysModal(1, holiday, sameDay)}
                // >

                //     {date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()} {sameDay === false ? `t/m ${endDate.getDate()} ${Constants.getMonthName(endDate)} ${endDate.getFullYear()}` : null}

                // </div>

            <div 
                className={'trade_tradeBar'} 
                style={{borderColor: Colors.color.greenFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1)}}
            >   

                <div style={{backgroundColor: Colors.color.greenFilledIn, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                  <span style={{textAlign: 'center', color: Constants.getContrast(Colors.color.greenFilledIn)}}>Goedgekeurd</span>
                </div>

                <div className='trade_tradeContainer'>

                  <div className={'trade_functionContainer'}>

                    <div>{date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()} {sameDay === false ? `t/m ${endDate.getDate()} ${Constants.getMonthName(endDate)} ${endDate.getFullYear()}` : null}</div>

                    {vHours !== null ?
                      vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ?
                        <div style={{marginTop: 3}}>Hele dag (8 uren)</div>
                      :
                        <div style={{marginTop: 3}}>{vHours.vu_begin.substr(0,5)} - {vHours.vu_eind.substr(0,5)} ({vHours.total} {vHours.total === 1 ? 'uur' : 'uren'})</div>
                      : null}

                    <div style={{marginTop: 3}}>{holiday.vakantie_opmerking === 'null' ? '' : holiday.vakantie_opmerking}</div>

                  </div>

                </div>

            </div>
            );

        }

    }

    return holidays;

  }

  getHolidayBarsRefused() {

    let holidays = [];

    for(let key in this.state.holidays) {

        const holiday = this.state.holidays[key];

        if(holiday.vakantie_geaccepteerd === '2') {

            const date = Constants.stringToDate(holiday.vakantie_begin);
            const endDate = Constants.stringToDate(holiday.vakantie_eind);

            const sameDay = date.getTime() === endDate.getTime();

            let vHours = null;
            if (sameDay === true) { // verlof
              if (typeof this.state.vHours[holiday.vakantie_id] !== 'undefined') {
                vHours = this.state.vHours[holiday.vakantie_id];
                vHours.total = parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin)) > 8 ? 8 : parseInt(Constants.timeStringToFloat(vHours.vu_eind) - Constants.timeStringToFloat(vHours.vu_begin));
              }
            }

            holidays.push(
                // <div 
                //     className={'tradeRequests_tradeBar'} 
                //     style={{borderColor: Colors.color.redFilledIn, height: '3vw', alignItems: 'center'}}
                //     onClick={() => this.openHolidaysModal(1, holiday, sameDay)}
                // >

                //     {date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()} {sameDay === false ? `t/m ${endDate.getDate()} ${Constants.getMonthName(endDate)} ${endDate.getFullYear()}` : null}

                // </div>

            <div 
                className={'trade_tradeBar'} 
                style={{borderColor: Colors.color.redFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.1)}}
            >   

                <div style={{backgroundColor: Colors.color.redFilledIn, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: '1.5vw', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                  <span style={{textAlign: 'center', color: Constants.getContrast(Colors.color.redFilledIn)}}>Afgewezen</span>
                </div>

                <div className='trade_tradeContainer'>

                  <div className={'trade_functionContainer'}>

                    <div>{date.getDate()} {Constants.getMonthName(date)} {date.getFullYear()} {sameDay === false ? `t/m ${endDate.getDate()} ${Constants.getMonthName(endDate)} ${endDate.getFullYear()}` : null}</div>

                    {vHours !== null ?
                      vHours.vu_begin === '00:00:00' && vHours.vu_eind === '00:00:00' ?
                        <div style={{marginTop: 3}}>Hele dag (8 uren)</div>
                      :
                        <div style={{marginTop: 3}}>{vHours.vu_begin.substr(0,5)} - {vHours.vu_eind.substr(0,5)} ({vHours.total} {vHours.total === 1 ? 'uur' : 'uren'})</div>
                      : null}

                    <div style={{marginTop: 3}}>{holiday.vakantie_opmerking === 'null' ? '' : holiday.vakantie_opmerking}</div>

                  </div>

                </div>

            </div>
            );

        }

    }

    return holidays;

  }

  showAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Planner publiceren',
      alertBody: (
        <div>

          Wil je een dag vrij vragen of een vakantie aanvragen?

        </div>
      ),
      alertButtons: 3,
      alertButtonText: ['Annuleren', 'Verlof aanvragen', 'Vakantie aanvragen'],
      alertButtonAction: this.openHolidaysModal.bind(this, 0, null, true),
      alertButtonAction2: this.openHolidaysModal.bind(this, 0),
      showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  getWorkDays() {

    let days = 0;

    if (this.state.workWeekMonday === true) {
      days++;
    }
    if (this.state.workWeekTuesday === true) {
      days++;
    }
    if (this.state.workWeekWednesday === true) {
      days++;
    }
    if (this.state.workWeekThursday === true) {
      days++;
    }
    if (this.state.workWeekFriday === true) {
      days++;
    }
    if (this.state.workWeekSaturday === true) {
      days++;
    }
    if (this.state.workWeekSunday === true) {
      days++;
    }

    return days;

  }

  render() {

    const holidayBarsOpen = this.getHolidayBarsOpen();
    const holidayBarsAccepted = this.getHolidayBarsAccepted();
    const holidayBarsRefused = this.getHolidayBarsRefused();

    return (
        <div className='holidays'>

          <Navbar history={this.props.history} active={'holidays'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Verlofdagen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
                {/* <i className='fa fa-plus'></i> */}
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='holidays_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <HolidaysModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _oneDay={this.state.oneDay}
                  _deadlines={this.state.deadlinesAbsence}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              {this.state.holidays.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen aangevraagde verlofdagen op dit moment</div>
                </div>

              :

                null
              
              }

              <div className="holidays_addButtons_container">

                <div className="planner_new_addButton" onClick={() => this.showAlert()}>
                  {/* <i className={'fal fa-plus'} /> */}
                  Toevoegen
                </div>

              </div>

              <span style={{marginLeft: '3.6vw', fontSize: '0.8vw', marginTop: 10}}>Openstaande verlofdagen {Constants.getDateNowStatic().getFullYear()}: {this.state.yearDaysOpen}</span>
              <span style={{marginLeft: '3.6vw', fontSize: '0.8vw', marginTop: 3}}>Openstaande verlofuren {Constants.getDateNowStatic().getFullYear()}: <b>{this.state.yearHoursOpen}</b></span>
              <span style={{marginLeft: '3.6vw', fontSize: '0.8vw', marginTop: 10}}>Goedgekeurde verlofdagen {Constants.getDateNowStatic().getFullYear()}: {this.state.yearDaysAccepted}</span>
              <span style={{marginLeft: '3.6vw', fontSize: '0.8vw', marginTop: 3}}>Goedgekeurde verlofuren {Constants.getDateNowStatic().getFullYear()}: <b>{this.state.yearHoursAccepted}</b></span>
              <span style={{marginLeft: '3.6vw', fontSize: '0.8vw', marginTop: 10}}>Totaal verlofuren {Constants.getDateNowStatic().getFullYear()}: <b>{this.state.yearHoursAccepted + this.state.yearHoursOpen}</b></span>

              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>

                <div className={'tradeRequests_dataContainer'}>

                  <div className={'tradeRequests_dataContainerTitle'}>

                      Aangevraagde verlofdagen

                  </div>

                  {holidayBarsOpen}

                </div>

                <div className={'tradeRequests_dataContainer'}>

                  <div className={'tradeRequests_dataContainerTitle'}>

                      Goedgekeurde verlofdagen

                  </div>

                  {holidayBarsAccepted}

                </div>

                <div className={'tradeRequests_dataContainer'}>

                  <div className={'tradeRequests_dataContainerTitle'}>

                      Afgewezen verlofdagen

                  </div>

                  {holidayBarsRefused}

                </div>

              </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default Holidays;