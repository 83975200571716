/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetOwnHours from '../../../classes/APIGetOwnHours';
import APIGetOwnHoursClock from '../../../classes/APIGetOwnHoursClock';
import APIGetSchedule from '../../../classes/APIGetSchedule';

class MyHours extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    // compwillmount

    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();
    
    this.state = {

      loading: true,
      reloading: false,

      day: day,
      month: month,
      year: year,
      dateText: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,

      plannerData: [],
      clockData: [],

      selectedDate: date,

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

      schedule: [],

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    }

    // const options = await APIGetOptions.Request();
    // Data.data.appOptions = {
    //     id: parseInt(options[0].opties_id),
    //     clock: options[0].opties_inklok === '1' ? true : false,
    //     brk: options[0].opties_pauze === '0' ? 'PLANNER' : 'CLOCK',
    //     hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
    //     availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : 'NONE'
    // };

    if(Data.data.appOptions.hours === 'CLOCK') {
      await this.getOwnHoursClock(this.state.month, this.state.year);
    } else {
      await this.getPlannerData(this.state.month, this.state.year);
    }

    this.setState({loading: false});

  }

  async getSchedule(month, year) {

    const today = await Constants.getDateNow(); //new Date();

    let scheduleObject = [];

    for (let i = 1; i < 32; i++) {
      let s = null;
      if(typeof month === 'undefined' || month === null) {
        s = await APIGetSchedule.Request(`${today.getFullYear()}-${today.getMonth() + 1}-${i}`);
      } else {
        s = await APIGetSchedule.Request(`${year}-${month}-${i}`);
      }
      if(s !== null) {
        for(const _s of s) {
          scheduleObject.push({..._s});
        }
      }
    }

    if(scheduleObject === null) {
      this.setState({
        schedule: [],
        loading: false,
      });
      return;
    }

    if(scheduleObject === 'error') {
      this.setState({
          schedule: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({schedule: scheduleObject});

  }

  async getPlannerData(month, year) {
    
    const plannerData = await APIGetOwnHours.Request(month, year);

    if(plannerData === null) {
      this.setState({
        plannerData: [],
        loading: false,
      });
      return;
    }

    if(plannerData === 'error') {
      this.setState({
          plannerData: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({plannerData: plannerData});

  }

  async getOwnHoursClock(month, year) {

    if(Data.data.appOptions.brk === 'PLANNER') { //CLOCK
      await this.getSchedule(month, year);
    }
    
    const plannerData = await APIGetOwnHoursClock.Request(Data.data.accountData.account_id, month, year);

    if(plannerData === null) {
      this.setState({
        clockData: [],
        loading: false,
      });
      return;
    }

    if(plannerData === 'error') {
      this.setState({
          clockData: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({clockData: plannerData});

  }

  async selectDateCalendar(date) {

    if(this.state.loading === true) {
      return;
    }

    const today = new Date();
    const newDate = new Date(date);

    if(today < newDate) {
      return false;
    }

  this.setState({loading: true});

  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  this.setState({
      month: month,
      year: year,
      dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
  });

  await this.reloadData(month, year);

  this.setState({loading: false, selectedDate: date});

  }

  async nextMonth() {

    if(this.state.loading === true) {
        return;
    }

    let month = this.state.month + 1;
    let year = this.state.year;

    if(month >= 13) {

        month = 1;
        year += 1;
        
    }

    if(this.canGoNext() === false) {
      return;
    }

    this.setState({loading: true});

    this.setState({
        month: month,
        year: year,
        dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadData(month, year);

    this.setState({loading: false});

  }

  async previousMonth() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let month = this.state.month - 1;
    let year = this.state.year;

    if(month <= 0) {

      month = 12;
      year -= 1;
      
    }

    this.setState({
        month: month,
        year: year,
        dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadData(month, year);

    this.setState({loading: false});

  }

  async reloadData(month, year) {

    const date = new Date(year, month, 0);

    if(Data.data.appOptions.hours === 'CLOCK') {
      await this.getOwnHoursClock(month, year);
    } else {
      await this.getPlannerData(month, year);
    }

    this.setState({
      selectedDate: date,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  getWorkedDays() {

    return this.state.plannerData.length;

  }

  getSubHours() {

    let hours = 0;

    for(let key in this.state.plannerData) {

      const plannerData = this.state.plannerData;

      const startTime = Constants.getDateTZ(plannerData[key].rooster_datum, plannerData[key].rooster_begin).getTime();
      const endTime = Constants.getDateTZ(plannerData[key].rooster_datum, plannerData[key].rooster_eind).getTime();

      //const breakHours = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_pauze}`).getHours();
      //const breakMinutes = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_pauze}`).getMinutes() / 60;

      //const hourDiff = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_eind}`) - new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_begin}`);

      //const hourDiff = ((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes;

      //ROUND TO 2 DECIMAL AND - BREAK TIME
      //const hourDiff = Math.round(((((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes) + Number.EPSILON) * 100) / 100;

      //this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] = this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] + (hourDiff / 60 / 60 / 1000);
      
      const hourDiff = ((endTime - startTime) / 60 / 60 / 1000);
      
      hours += hourDiff;

    }

    return Math.round((hours + Number.EPSILON) * 100) / 100; //round to 2 decimals

  }

  getBreakTimeTotal() {

    let breakTime = 0;

    for(let key in this.state.plannerData) {

      const plannerData = this.state.plannerData;

      const breakHours = Constants.getHoursTZ(plannerData[key].rooster_datum, plannerData[key].rooster_pauze);
      const breakMinutes = Constants.getMinutesTZ(plannerData[key].rooster_datum, plannerData[key].rooster_pauze) / 60;

      const totalBreak = breakHours + breakMinutes;
      
      breakTime += totalBreak;

    }

    return Math.round((breakTime + Number.EPSILON) * 100) / 100;

  }

  getNetHours() {

    let hours = 0;

    for(let key in this.state.plannerData) {

      const plannerData = this.state.plannerData;

      const startTime = Constants.getDateTZ(plannerData[key].rooster_datum, plannerData[key].rooster_begin).getTime();
      const endTime = Constants.getDateTZ(plannerData[key].rooster_datum, plannerData[key].rooster_eind).getTime();
      const breakHours = Constants.getHoursTZ(plannerData[key].rooster_datum, plannerData[key].rooster_pauze);
      const breakMinutes = Constants.getMinutesTZ(plannerData[key].rooster_datum, plannerData[key].rooster_pauze) / 60;

      const hourDiff = ((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes;
      
      hours += hourDiff;

    }

    return Math.round((hours + Number.EPSILON) * 100) / 100;

  }

  getWorkedDaysClock() {

    return this.state.clockData.length;

  }

  getSubHoursClock() {

    let hours = 0;

    for(let key in this.state.clockData) {

      const clockData = this.state.clockData;

      const startTime = Constants.getDateTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_inklok_tijd.substring(0, 8)).getTime();
      const endTime = Constants.getDateTZ(clockData[key].kloktijd_uitklok_datum, clockData[key].kloktijd_uitklok_tijd.substring(0, 8)).getTime();

      //const breakHours = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_pauze}`).getHours();
      //const breakMinutes = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_pauze}`).getMinutes() / 60;

      //const hourDiff = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_eind}`) - new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_begin}`);

      //const hourDiff = ((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes;

      //ROUND TO 2 DECIMAL AND - BREAK TIME
      //const hourDiff = Math.round(((((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes) + Number.EPSILON) * 100) / 100;

      //this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] = this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] + (hourDiff / 60 / 60 / 1000);
      
      const hourDiff = ((endTime - startTime) / 60 / 60 / 1000);
      
      hours += hourDiff;

    }

    return Math.round((hours + Number.EPSILON) * 100) / 100; //round to 2 decimals

  }

  getBreakTimeTotalClock() {

    let breakTime = 0;

    for(let key in this.state.clockData) {

      const clockData = this.state.clockData;

      const breakHours = Constants.getHoursTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_pauze);
      const breakMinutes = Constants.getMinutesTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_pauze) / 60;
      const breakSeconds = Constants.getSecondsTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_pauze) / 60 / 60;

      const totalBreak = breakHours + breakMinutes + breakSeconds;
      
      breakTime += totalBreak;

    }

    return Math.round((breakTime + Number.EPSILON) * 100) / 100;;

  }

  getNetHoursClock() {

    let hours = 0;

    for(let key in this.state.clockData) {

      const clockData = this.state.clockData;

      const startTime = Constants.getDateTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_inklok_tijd.substring(0, 8)).getTime();
      const endTime = Constants.getDateTZ(clockData[key].kloktijd_uitklok_datum, clockData[key].kloktijd_uitklok_tijd.substring(0, 8)).getTime();

      let hourDiff = ((endTime - startTime) / 60 / 60 / 1000);

      if(Data.data.appOptions.brk !== 'PLANNER') {
        const breakHours = Constants.getHoursTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_pauze);
        const breakMinutes = Constants.getMinutesTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_pauze) / 60;
        const breakSeconds = Constants.getSecondsTZ(clockData[key].kloktijd_inklok_datum, clockData[key].kloktijd_pauze) / 60 / 60;

        hourDiff = ((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes - breakSeconds;
      }

      if(hourDiff < 0) {
          hourDiff = 0;
      }
      
      hours += hourDiff;

    }

    if(Data.data.appOptions.brk === 'PLANNER') {
      hours -= this.timeStringToFloat(this.getUserPlannerBreak());
    }

    return Math.round((hours + Number.EPSILON) * 100) / 100;

  }

  getHourPlannerBars() {

    let hourBars = [];

    for(let key in this.state.plannerData) {

      const plannerData = this.state.plannerData[key];
      const date = Constants.stringToDate(plannerData.rooster_datum);
      const startTime = Constants.getDateTZ(plannerData.rooster_datum, plannerData.rooster_begin).getTime();
      const endTime = Constants.getDateTZ(plannerData.rooster_datum, plannerData.rooster_eind).getTime();
      const breakHours = Constants.getHoursTZ(plannerData.rooster_datum, plannerData.rooster_pauze);
      const breakMinutes = Constants.getMinutesTZ(plannerData.rooster_datum, plannerData.rooster_pauze) / 60;
      const subHours = Math.round((((endTime - startTime) / 60 / 60 / 1000) + Number.EPSILON) * 100) / 100;
      const netHours = Math.round(((((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes) + Number.EPSILON) * 100) / 100;

      hourBars.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'}>
            {date.getDate()} {Constants.getShortMonthName(date)} {date.getFullYear()}
          </div>
          <div className={'myHours_barRow'}>
            {plannerData.rooster_begin.substring(0, 8)}
          </div>
          <div className={'myHours_barRow'}>
            {plannerData.rooster_eind.substring(0, 8)}
          </div>
          <div className={'myHours_barRow'}>
            {plannerData.rooster_pauze.substring(0, 5)}
          </div>
          <div className={'myHours_barRow'} style={{flex: 3}}>
            {plannerData.functie_naam}
          </div>
          <div className={'myHours_barRow'} style={{flex: 0.5}}>
            {subHours}
          </div>
          <div className={'myHours_barRow'} style={{flex: 0.5}}>
            {netHours}
          </div>

        </div>

      );

    }

    return hourBars;

  }

  getUserPlannerBreak() {

    let breakTime = '00:00';

    for(let key in this.state.schedule) {

      const schedule = this.state.schedule[key];

      // const today = new Date();
      // const scheduleDate = Constants.getDateTZ(schedule.rooster_datum, schedule.rooster_eind);

      if(schedule.info_id === Data.data.accountData.account_id) { // && today.getTime() >= scheduleDate.getTime()

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }

  getUserPlannerBreakDate(id, date) {

    let breakTime = '00:00';

    for(let key in this.state.schedule) {

      const schedule = this.state.schedule[key];

      // const today = new Date();
      // const scheduleDate = Constants.getDateTZ(schedule.rooster_datum, schedule.rooster_eind); // today >= scheduledate

      if(schedule.info_id === id && schedule.rooster_datum === date) {

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }

  getHourPlannerBarsClock() {

    let hourBars = [];

    for(let key in this.state.clockData) {

      const clockData = this.state.clockData[key];

      const clockInDate = Constants.stringToDate(clockData.kloktijd_inklok_datum);
      const clockOutDate = Constants.stringToDate(clockData.kloktijd_uitklok_datum);
      const startTime = Constants.getDateTZ(clockData.kloktijd_inklok_datum, clockData.kloktijd_inklok_tijd).getTime();
      const endTime = Constants.getDateTZ(clockData.kloktijd_uitklok_datum, clockData.kloktijd_uitklok_tijd).getTime();
      let breakHours = Constants.getHoursTZ(clockData.kloktijd_inklok_datum, clockData.kloktijd_pauze);
      let breakMinutes = Constants.getMinutesTZ(clockData.kloktijd_inklok_datum, clockData.kloktijd_pauze) / 60;
      let breakSeconds = Constants.getSecondsTZ(clockData.kloktijd_inklok_datum, clockData.kloktijd_pauze) / 60 / 60;

      if(Data.data.appOptions.brk === 'PLANNER') {
        breakHours = Constants.getHoursTZ(clockData.kloktijd_inklok_datum, this.getUserPlannerBreakDate(clockData.info_id, clockData.kloktijd_inklok_datum));
        breakMinutes = Constants.getMinutesTZ(clockData.kloktijd_inklok_datum, this.getUserPlannerBreakDate(clockData.info_id, clockData.kloktijd_inklok_datum)) / 60;
        breakSeconds = 0;
      }
      const subHours = Math.round((((endTime - startTime) / 60 / 60 / 1000) + Number.EPSILON) * 100) / 100;
      const netHours = Math.round(((((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes - breakSeconds) + Number.EPSILON) * 100) / 100;

      hourBars.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'}>
            {clockInDate.getDate()} {Constants.getShortMonthName(clockInDate)} {clockInDate.getFullYear()} {clockData.kloktijd_inklok_tijd.substring(0, 8)}
          </div>
          <div className={'myHours_barRow'}>
            {clockOutDate.getDate()} {Constants.getShortMonthName(clockOutDate)} {clockOutDate.getFullYear()} {clockData.kloktijd_uitklok_tijd.substring(0, 8)}
          </div>
          <div className={'myHours_barRow'}>
            {Data.data.appOptions.brk === 'PLANNER' ?
              this.getUserPlannerBreakDate(clockData.info_id, clockData.kloktijd_inklok_datum)
            :
              clockData.kloktijd_pauze.substring(0, 8)
            }
          </div>
          <div className={'myHours_barRow'} style={{flex: 0.5}}>
            {subHours}
          </div>
          <div className={'myHours_barRow'} style={{flex: 0.5}}>
            {netHours}
          </div>

        </div>

      );

    }

    return hourBars;

  }

  canGoNext() {

    let month = this.state.month + 1;
    let year = this.state.year;

    if(month >= 13) {

        month = 1;
        year += 1;
        
    }

    const today = new Date();
    const newDate = new Date(year, month-1, 1);

    if(today < newDate) {
      return false;
    }

    return true;

  }

  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    const hoursMins = hours + minutes / 60;
    return Math.round((hoursMins + Number.EPSILON) * 100) / 100;
  }

  render() {

    const hourPlannerBars = this.getHourPlannerBars();
    const hourPlannerBarsClock = this.getHourPlannerBarsClock();

    return (
        <div className='myHours'>

          <Navbar history={this.props.history} active={'my_hours'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='planner_nav2_sub1' onClick={() => this.previousMonth()}>
              <i className='fa fa-chevron-left'></i>
            </div> 
            <div className='planner_nav2_sub2'>
              <DatePicker 
                selected={this.state.selectedDate}
                onSelect={date => this.selectDateCalendar(date)}
                customInput={<i className='fa fa-calendar-day'></i>}
                popperPlacement="top-start"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "-40px, 0px"
                  },
                }}
                locale={nl}
                dropdownMode="select"
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div> 
            <div className='planner_nav2_sub3' style={{flex: 2}}>
                {Constants.getMonthName(this.state.dateText).charAt(0).toUpperCase() + Constants.getMonthName(this.state.dateText).slice(1)} {this.state.year}
            </div> 
            <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>
              
            </div> 
            <div className='planner_nav2_sub5' onClick={() => this.nextMonth()} style={{cursor: this.canGoNext() === true ? 'pointer' : 'default'}}>
              {this.canGoNext() === true ?
              
                <i className='fa fa-chevron-right'></i>

              :

                null
            
              }
            </div> 

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='myHours_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {Data.data.appOptions.hours === 'CLOCK' ?
              
                <div>

                  <div className={'avail_headerRow'}>
                    <div className="myHours_headerContainer">Gewerkte dagen: {this.getWorkedDaysClock()}</div>
                    <div className="myHours_headerContainer">Bruto uren: {this.getSubHoursClock()}</div>
                    <div className="myHours_headerContainer">Uren pauze: {Data.data.appOptions.brk === 'PLANNER' ? this.timeStringToFloat(this.getUserPlannerBreak()) : this.getBreakTimeTotalClock()}</div>
                    <div className="myHours_headerContainer" style={{fontWeight: 'bold'}}>Netto uren: {this.getNetHoursClock()}</div>
                  </div>

                  <div className={'myHours_barContainerTop stickyTopHours'}>
                    <div className={'myHours_barRowMain'}>
                      <div>Inklok tijd</div>
                    </div>
                    <div className={'myHours_barRowMain'}>
                      <div>Uitklok tijd</div>
                    </div>
                    <div className={'myHours_barRowMain'}>
                      <div>Pauze</div>
                    </div>
                    <div className={'myHours_barRowMain'} style={{flex: 0.5}}>
                      <div>Bruto uren</div>
                    </div>
                    <div className={'myHours_barRowMain'} style={{flex: 0.5}}>
                      <div>Netto uren</div>
                    </div>
                  </div>

                  {hourPlannerBarsClock}

                </div>

              :

                <div>

                  <div className={'avail_headerRow'}>
                    <div className="myHours_headerContainer">Gewerkte dagen: {this.getWorkedDays()}</div>
                    <div className="myHours_headerContainer">Bruto uren: {this.getSubHours()}</div>
                    <div className="myHours_headerContainer">Uren pauze: {this.getBreakTimeTotal()}</div>
                    <div className="myHours_headerContainer" style={{fontWeight: 'bold'}}>Netto uren: {this.getNetHours()}</div>
                  </div>

                  <div className={'myHours_barContainerTop stickyTopHours'}>
                    <div className={'myHours_barRowMain'}>
                      <div>Datum</div>
                    </div>
                    <div className={'myHours_barRowMain'}>
                      <div>Begintijd</div>
                    </div>
                    <div className={'myHours_barRowMain'}>
                      <div>Eindtijd</div>
                    </div>
                    <div className={'myHours_barRowMain'}>
                      <div>Pauze</div>
                    </div>
                    <div className={'myHours_barRowMain'} style={{flex: 3}}>
                      <div>Functie</div>
                    </div>
                    <div className={'myHours_barRowMain'} style={{flex: 0.5}}>
                      <div>Bruto uren</div>
                    </div>
                    <div className={'myHours_barRowMain'} style={{flex: 0.5}}>
                      <div>Netto uren</div>
                    </div>
                  </div>

                  {hourPlannerBars}

                </div>
            
              }
              
            </div>
        
          }

        </div>
    );
  }
  
}

export default MyHours;