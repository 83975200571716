


import CryptoJS from 'crypto-js';

import Data from '../../constants/Data';

class APIGETLOGIN {

    async Request(select, from, join, where, group, order) {
        
        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        where = encodeURIComponent(where);

        try {
            response = await fetch(`https://login-t01.tiemdo.com/phpcalls/GET.php/`, {
                method: 'post',
                body: new URLSearchParams(`user=${username}&select=${select}&from=${from}&join=${join}&where=${where}&group=${group}&order=${order}&hash=${hash}`)
            });
        } catch(err) {
            // console.log(err);
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
            // console.log(err);
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return []; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIGETLOGIN());