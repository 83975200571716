import React from 'react';
import './breadcrumb.scss';
import '../../constants/DefaultStyle.css';
import Constants from '../../constants/Constants';

class Breadcrumb extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    // props
    // this.pages = this.props.pages;
    // this.pageFunction = this.props.pageFunction;
    
    this.state = {

        // pages: this.pages,

    };

  }

//   componentDidUpdate(updatedState, currentState) {

//     if (currentState.pages !== updatedState.pages) {
//         this.setState({ pages: updatedState.pages });
//     }

//   }

  renderItems() {

    let toRender = [];
    let count = 0;

    if (Constants.isEmpty(this.props.items) === false) {

        for (const item of this.props.items) {

            if (toRender.length === 0) {
                toRender.push(<span className="breadcrumb_item" onClick={() => item.action()}>{item.name}</span>);
            } else {
                toRender.push(<span className={`breadcrumb_item_margin ${item.active ? 'breadcrumb_active' : ''}`}>{item.name}</span>);
            }

            if (count < this.props.items.length - 1) {
                toRender.push(<i className="far fa-angle-right" />);        
            }

            count++;

        }

    }

    return toRender;

  }

  render() {

    return (
        <div className='breadcrumb'>

            {this.renderItems()}

        </div>
    );
  }
  
}

export default Breadcrumb;