/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './registrationCodes.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import QRCode from 'qrcode.react';
import Constants from '../../../constants/Constants';

class RegistrationCodesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.closeModal = this.props._closeModal;
    
    this.state = {

    };

  }

  componentDidMount() {

  }

  render() {

    return (
        <div id="registrationCodeModal" className="modal" onClick={(e) => e.target === document.getElementById("zregistrationCodeModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.closeModal()}>&times;</span>
            <p>Registratiecode delen</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                <div className='plan_modal-form' style={{marginBottom: 40}}>

                    <div style={{textAlign: 'center', fontSize: '1vw'}}>Met deze QR-code kun je de registratiecode versturen via de mobiel.<br /><br />Open de Tiemdo app. Navigeer naar menu en vervolgens naar 'Medewerkers registreren'. Druk op 'QR-code scannen' en scan de onderstaande QR-code.</div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <QRCode 
                            value={`${this.modalData}`}
                            size={256}
                            style={{marginTop: 20}}
                        />
                    </div>

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default RegistrationCodesModal;