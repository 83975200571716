/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './shifts.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import APIAddDayShiftTemplate from '../../../classes/APIAddDayShiftTemplate';
import APIEditDayShiftTemplate from '../../../classes/APIEditDayShiftTemplate';
import APIDeleteDayShiftTemplate from '../../../classes/APIDeleteDayShiftTemplate';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';

class ShiftTempModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        name: '',

        team: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if (this.mode === 1) {

        this.setState({
            name: this.modalData.dagdienstsjabloon_naam
        });

    }

  }

  deleteAlert() {

    // if (this.modalData.dagdienstsjabloon_id === '1' || this.modalData.dagdienstsjabloon_id === 1) {
    //     this.setState({
    //         alertTitle: 'Verwijderen',
    //         alertBody: 'Je kunt dit sjabloon niet verwijderen',
    //         alertButtons: 1,
    //         alertButtonText: ['OK'],
    //         showAlert: true,
    //     });
    //     return;
    // }

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'LET OP: Als je deze sjabloon verwijdert, worden de daarbij gekoppelde diensten gewist. Weet je zeker dat je deze sjabloon wilt verwijderen? ',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteShift.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveShift() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    // const added = await APIAddDayShiftTemplate.Request(
    //   this.state.name,
    // );

    const added = await APIADD.Request(`INSERT INTO DagDienstSjabloon`, `VALUES (NULL, '${this.state.name}', ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : `NULL`})`);

    if(added === true) {

      this.closeModal();

      this.reloadScreen('ADD');

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editShift() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditDayShiftTemplate.Request(
      this.state.name,
      parseInt(this.modalData.dagdienstsjabloon_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteShift() {

    this.setState({loading: true});

    const added = await APIDeleteDayShiftTemplate.Request(
      parseInt(this.modalData.dagdienstsjabloon_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen('DEL');

      return;

    }

  }

  checkValidations() {

    if(this.state.name === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen naam ingevoerd',
      });
      return false;
    }

    return true;

  }

  async duplicateShift() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    await APIADD.Request(`INSERT INTO DagDienstSjabloon`, `VALUES (NULL, '${this.state.name}', ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? this.state.team : `NULL`})`);
    const ID = await APIGET.Request(`SELECT dagdienstsjabloon_id`, `FROM DagDienstSjabloon`, null, null, null, `ORDER BY dagdienstsjabloon_id DESC LIMIT 1`);

    if (ID.length > 0) {

      await this.props._duplicateTemplate(ID[0].dagdienstsjabloon_id);

    }

  }

  getTeams() {

    let functions = [];

    for(const person of Data.data.teams) {

        functions.push(
            <option value={person.team_id} selected={person.team_id === this.state.team}>{person.team_naam}</option>
        );

    }

    return functions;

  }

  selectTeam(event) {

    this.setState({
        team: event.target.value,
    });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="shiftTempModal" className="modal" onClick={(e) => e.target === document.getElementById("zshiftTempModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Sjabloon toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Sjabloon wijzigen</p>

            :
            this.mode === 2 ?

              <p>Sjabloon dupliceren</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                  <div className='modal-row'>

                      <div className='modal-rowSub1'>
                          <span><b>Naam</b></span>
                      </div>

                      <div className='modal-rowSub2'>

                        <input 
                            className={'inputField2'}
                            maxLength={25}
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                            placeholder={'Sjabloonnaam'}
                        />

                      </div>

                  </div>

                  {(Data.data.teams.length > 1 && Data.data.chosenTeam !== null) && this.mode === 2 ?
                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                          <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Naar team</span>
                        </div>

                        <div className='modal-rowSub2'>

                        <select 
                              name="Selecteer een team" 
                              className={'dropDownField'}
                              style={{marginTop: 0}} 
                              onChange={(event) => this.selectTeam(event)}
                          >
                              <option value="" selected disabled hidden>Selecteer een team</option>
                              {this.getTeams()}
                          </select>

                        </div>

                    </div>
                  : null}

                  <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editShift()}>
                  <span>Opslaan</span>
                </div>
              : 
              this.mode === 2 ?      
                <div className='modal-submitButton' onClick={() => this.duplicateShift()}>
                  <span>Dupliceren</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveShift()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default ShiftTempModal;