/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './appOptions.css';
import '../../../components/navbar/navbar.css';
import '../deadlines/deadlines.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../employees/employees.css';
import '../../../constants/DefaultStyle.css';
import '../salaries/salaries.css';
import '../../../components/smartPlanModal/smartPlanModal.css';
import '../workWeek/workWeek.css';
import '../hoursRegistration/hoursRegistration.css';
import '../reports/reports.scss';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIEditOptions from '../../../classes/APIEditOptions';
import APIGetOptions from '../../../classes/APIGetOptions';
import APIGetLocations from '../../../classes/APIGetLocations';
import Colors from '../../../constants/Colors';
import LocationsModal from './locationsModal';
import MissingModal from '../../../components/missingModal/missingModal';
import APIEditOptions2 from '../../../classes/APIEditOptions2';

import { Tooltip } from 'react-tippy';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAllContracts from '../../../classes/APIGetAllContracts';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIEDITLOGIN from '../../../classes/login/APIEDITLOGIN';
import APIGETLOGIN from '../../../classes/login/APIGETLOGIN';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import ParticularModal from '../particular/particularModal';
import AbsencetypesModal from '../absencetypes/absencetypesModal';
import SkillsModal from '../skills/skillsModal';
import APIGetParticular from '../../../classes/APIGetParticular';
import APIGetAbsencetypes from '../../../classes/APIGetAbsencetypes';
import APIGetSkills from '../../../classes/APIGetSkills';
import BonusModal from './bonusModal';
import LeaveBalanceModal from './leaveBalanceModal';
import LeaveTypeModal from './leaveTypeModal';
import APIGetHostedPage from '../../../classes/payment/APIGetHostedPage';
import PublicHolidayModal from './publicHolidayModal';
import { Prompt } from 'react-router';
import APIGetSubscription from '../../../classes/payment/APIGetSubscription';
import APIUpdateQuantity from '../../../classes/payment/APIUpdateQuantity';
import APIUpdateSubscriptionTrial from '../../../classes/payment/APIUpdateSubscriptionTrial';
import APICancelSubscription from '../../../classes/payment/APICancelSubscription';

import { toast } from 'react-toastify';

import CryptoJS from 'crypto-js';

class AppOptions extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.timeRef = {};
    this.lastDeleted = null;
    this.lastVal = '';

    this.paramMenu = this.props.location.menu !== undefined && this.props.location.menu !== null ? this.props.location.menu : 'general';
    this.paramSubMenu = this.props.location.subMenu !== undefined && this.props.location.subMenu !== null ? this.props.location.subMenu : 'general';

    // compwillmount
    
    this.state = {

      loading: true,
      buttonLoading: false,

      clockSystem: Data.data.appOptions.clock,
      breakOption: Data.data.appOptions.brk,
      hoursRegister: Data.data.appOptions.hours,
      availability: Data.data.appOptions.availability,
      open: Data.data.appOptions.open,
      iban: Data.data.appOptions.iban,
      bsn: Data.data.appOptions.bsn,

      opties_smartplan: Data.data.appOptions.opties_smartplan === '1',
      opties_financien: Data.data.appOptions.opties_financien === '1',
      opties_urenregistratie2: Data.data.appOptions.opties_urenregistratie2 === '1',
      opties_klokPlanning: Data.data.appOptions.opties_klokPlanning === '1',
      opties_klokAfronden: Data.data.appOptions.opties_klokAfronden,
      opties_pauzeAfronden: Data.data.appOptions.opties_pauzeAfronden,
      opties_urenEigen: Data.data.appOptions.opties_urenEigen === '1',
      opties_onbpd: Data.data.appOptions.opties_onbpd,
      opties_onbpw: Data.data.appOptions.opties_onbpw,
      opties_ruilenComp: Data.data.appOptions.opties_ruilenComp === '1',
      opties_ruilenGeenFunctie: Data.data.appOptions.opties_ruilenGeenFunctie === '1',
      opties_verlofDeadline: Data.data.appOptions.opties_verlofDeadline,
      opties_ruilenFunctie: Data.data.appOptions.opties_ruilenFunctie === '1',
      opties_planBeschikbaar: Data.data.appOptions.opties_planBeschikbaar === '1',
      opties_planFunctieSort: Data.data.appOptions.opties_planFunctieSort === '1',
      opties_contractVerlopen: Data.data.appOptions.opties_contractVerlopen === '1',
      opties_klokMobiel: Data.data.appOptions.opties_klokMobiel === '1',
      opties_fteUren: Data.data.appOptions.opties_fteUren,
      opties_loonfactor: Data.data.appOptions.opties_loonfactor,
      opties_BTW: Data.data.appOptions.opties_BTW,
      opties_omzetBTW: Data.data.appOptions.opties_omzetBTW === '1',
      opties_loonPercentage: Data.data.appOptions.opties_loonPercentage,
      opties_chat: Data.data.appOptions.opties_chat === '1',
      opties_ruilen_vantevoren: Data.data.appOptions.opties_ruilen_vantevoren,
      opties_feestdagen: Data.data.appOptions.opties_feestdagen === '1',
      opties_eindtijd_weergeven: Data.data.appOptions.opties_eindtijd_weergeven === '1',
      opties_opendienst_beschikbaar: Data.data.appOptions.opties_opendienst_beschikbaar === '1',
      opties_infoplanner_verborgen: Data.data.appOptions.opties_infoplanner_verborgen === '1',
      opties_uren_daadwerkelijk: Data.data.appOptions.opties_uren_daadwerkelijk === '1',
      opties_overuren: Data.data.appOptions.opties_overuren === '1',
      opties_verlof_limiet: Data.data.appOptions.opties_verlof_limiet,
      opties_planner_drukte: Data.data.appOptions.opties_planner_drukte === '1',
      opties_planner_loonkosten: Data.data.appOptions.opties_planner_loonkosten === '1',
      opties_planner_vorigOmzet: Data.data.appOptions.opties_planner_vorigOmzet === '1',
      opties_planner_verwachteOmzet: Data.data.appOptions.opties_planner_verwachteOmzet === '1',
      opties_planner_omzet: Data.data.appOptions.opties_planner_omzet === '1',
      opties_planner_productiviteit: Data.data.appOptions.opties_planner_productiviteit === '1',
      opties_planner_berekeningen: Data.data.appOptions.opties_planner_berekeningen === '1',
      opties_planner_extraDiensten: Data.data.appOptions.opties_planner_extraDiensten === '1',
      opties_planner_contracturen: Data.data.appOptions.opties_planner_contracturen === '1',
      opties_onbpm: Data.data.appOptions.opties_onbpm,
      opties_verlofsaldo: Data.data.appOptions.opties_verlofsaldo === '1',
      opties_versie: Data.data.appOptions.opties_versie,

      hoursRegisterDisabled: !Data.data.appOptions.clock,

      showModal: false,
      modalMode: 0,
      modalData: [],

      showPartModal: false,
      showAbsModal: false,
      showSkillsModal: false,
      showBonusModal: false,
      showLeaveBalanceModal: false,
      showLeaveTypeModal: false,
      showPublicHolidayModal: false,

      showAlert: false,

      hasLocation: true,

      missingModalVisible: false,

      // new
      newOptions: [],

      menu: this.paramMenu,
      subMenu: this.paramSubMenu,
      selectedTeam: null,

      workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
      workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
      workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
      workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
      workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
      workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
      workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

      dailyWorkHours: Data.data.appOptions.opties_dag_werkuren,

      accounts: [],
      infoExtra: {},

      salaries: {},
      salariesArray: [],
      emptySalaries: [],

      newSalaries: {},
      salaryModels: [],
      selectedSalaryModel: null,
      newSalaryModel: '',

      errorText: '',
      showError: false,

      chosenTeam: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeam : null,
      chosenTeamObj: Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj : 
      {
        bedrijf_naam: Data.data.storeName,
        bedrijf_adres: Data.data.storeAddress,
        bedrijf_postcode: Data.data.storeZip,
        bedrijf_plaats: Data.data.storeCity,
        bedrijf_telefoon: Data.data.storePhone,
        bedrijf_email: Data.data.storeMail,
        bedrijf_land: Data.data.storeCountry,
        bedrijf_kvk: Data.data.storeKvK,
      },

      breakRules: [],
      emptyBreakRules: [],

      holidayPeriods: [],
      emptyHolidayPeriods: [],

      particularities: [],
      absenceTypes: [],
      skills: [],

      exclusions: [],

      bonus: [],

      leaveBalances: [],
      leaveTypes: [],
      leaveTypeBalances: {},
      correctTypes: true,

      publicHolidays: [],
      publicHoliday_year: new Date().getFullYear(),
      minYear: new Date().getFullYear(),
      maxYear: new Date().getFullYear(),

      defaultWorkdays: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        0: '',
      },

      abs_clockIn: null,

      timezoneList: {
        "Etc/GMT+12": "(GMT-12:00) International Date Line West",
        "Pacific/Midway": "(GMT-11:00) Midway Island, Samoa",
        "Pacific/Honolulu": "(GMT-10:00) Hawaii",
        "US/Alaska": "(GMT-09:00) Alaska",
        "America/Los_Angeles": "(GMT-08:00) Pacific Time (US & Canada)",
        "US/Arizona": "(GMT-07:00) Arizona",
        "America/Managua": "(GMT-06:00) Central America",
        "US/Central": "(GMT-06:00) Central Time (US & Canada)",
        "America/Bogota": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
        "US/Eastern": "(GMT-05:00) Eastern Time (US & Canada)",
        "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
        "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires, Georgetown",
        "America/Noronha": "(GMT-02:00) Mid-Atlantic",
        "Atlantic/Azores": "(GMT-01:00) Azores",
        "Etc/Greenwich": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
        "Europe/Amsterdam": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "Europe/Helsinki": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "Europe/Moscow": "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
        "Asia/Tehran": "(GMT+03:30) Tehran",
        "Asia/Yerevan": "(GMT+04:00) Yerevan",
        "Asia/Kabul": "(GMT+04:30) Kabul",
        "Asia/Yekaterinburg": "(GMT+05:00) Yekaterinburg",
        "Asia/Karachi": "(GMT+05:00) Islamabad, Karachi, Tashkent",
        "Asia/Calcutta": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "Asia/Katmandu": "(GMT+05:45) Kathmandu",
        "Asia/Dhaka": "(GMT+06:00) Astana, Dhaka",
        "Asia/Rangoon": "(GMT+06:30) Yangon (Rangoon)",
        "Asia/Bangkok": "(GMT+07:00) Bangkok, Hanoi, Jakarta",
        "Asia/Hong_Kong": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "Asia/Seoul": "(GMT+09:00) Seoul",
        "Australia/Adelaide": "(GMT+09:30) Adelaide",
        "Australia/Canberra": "(GMT+10:00) Canberra, Melbourne, Sydney",
        "Asia/Magadan": "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
        "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
        "Pacific/Tongatapu": "(GMT+13:00) Nuku'alofa"
      },
      chosenTimeZone: Data.data.storeTimezone,

      currencies: {
        "EUR": "Euro",
        "USD": "United States Dollar",
        "GBP": "British Pound Sterling",
        "JPY": "Japanese Yen",
        "NONE": "-------------------------",
        "AED": "United Arab Emirates Dirham",
        "AFN": "Afghan Afghani",
        "ALL": "Albanian Lek",
        "AMD": "Armenian Dram",
        "ANG": "Netherlands Antillean Guilder",
        "AOA": "Angolan Kwanza",
        "ARS": "Argentine Peso",
        "AUD": "Australian Dollar",
        "AWG": "Aruban Florin",
        "AZN": "Azerbaijani Manat",
        "BAM": "Bosnia-Herzegovina Convertible Mark",
        "BBD": "Barbadian Dollar",
        "BDT": "Bangladeshi Taka",
        "BGN": "Bulgarian Lev",
        "BHD": "Bahraini Dinar",
        "BIF": "Burundian Franc",
        "BMD": "Bermudan Dollar",
        "BND": "Brunei Dollar",
        "BOB": "Bolivian Boliviano",
        "BRL": "Brazilian Real",
        "BSD": "Bahamian Dollar",
        "BTC": "Bitcoin",
        "BTN": "Bhutanese Ngultrum",
        "BWP": "Botswanan Pula",
        "BYN": "Belarusian Ruble",
        "BZD": "Belize Dollar",
        "CAD": "Canadian Dollar",
        "CDF": "Congolese Franc",
        "CHF": "Swiss Franc",
        "CLF": "Chilean Unit of Account (UF)",
        "CLP": "Chilean Peso",
        "CNH": "Chinese Yuan (Offshore)",
        "CNY": "Chinese Yuan",
        "COP": "Colombian Peso",
        "CRC": "Costa Rican Colón",
        "CUC": "Cuban Convertible Peso",
        "CUP": "Cuban Peso",
        "CVE": "Cape Verdean Escudo",
        "CZK": "Czech Republic Koruna",
        "DJF": "Djiboutian Franc",
        "DKK": "Danish Krone",
        "DOP": "Dominican Peso",
        "DZD": "Algerian Dinar",
        "EGP": "Egyptian Pound",
        "ERN": "Eritrean Nakfa",
        "ETB": "Ethiopian Birr",
        "FJD": "Fijian Dollar",
        "FKP": "Falkland Islands Pound",
        "GEL": "Georgian Lari",
        "GGP": "Guernsey Pound",
        "GHS": "Ghanaian Cedi",
        "GIP": "Gibraltar Pound",
        "GMD": "Gambian Dalasi",
        "GNF": "Guinean Franc",
        "GTQ": "Guatemalan Quetzal",
        "GYD": "Guyanaese Dollar",
        "HKD": "Hong Kong Dollar",
        "HNL": "Honduran Lempira",
        "HRK": "Croatian Kuna",
        "HTG": "Haitian Gourde",
        "HUF": "Hungarian Forint",
        "IDR": "Indonesian Rupiah",
        "ILS": "Israeli New Sheqel",
        "IMP": "Manx pound",
        "INR": "Indian Rupee",
        "IQD": "Iraqi Dinar",
        "IRR": "Iranian Rial",
        "ISK": "Icelandic Króna",
        "JEP": "Jersey Pound",
        "JMD": "Jamaican Dollar",
        "JOD": "Jordanian Dinar",
        "KES": "Kenyan Shilling",
        "KGS": "Kyrgystani Som",
        "KHR": "Cambodian Riel",
        "KMF": "Comorian Franc",
        "KPW": "North Korean Won",
        "KRW": "South Korean Won",
        "KWD": "Kuwaiti Dinar",
        "KYD": "Cayman Islands Dollar",
        "KZT": "Kazakhstani Tenge",
        "LAK": "Laotian Kip",
        "LBP": "Lebanese Pound",
        "LKR": "Sri Lankan Rupee",
        "LRD": "Liberian Dollar",
        "LSL": "Lesotho Loti",
        "LYD": "Libyan Dinar",
        "MAD": "Moroccan Dirham",
        "MDL": "Moldovan Leu",
        "MGA": "Malagasy Ariary",
        "MKD": "Macedonian Denar",
        "MMK": "Myanma Kyat",
        "MNT": "Mongolian Tugrik",
        "MOP": "Macanese Pataca",
        "MRO": "Mauritanian Ouguiya (pre-2018)",
        "MRU": "Mauritanian Ouguiya",
        "MUR": "Mauritian Rupee",
        "MVR": "Maldivian Rufiyaa",
        "MWK": "Malawian Kwacha",
        "MXN": "Mexican Peso",
        "MYR": "Malaysian Ringgit",
        "MZN": "Mozambican Metical",
        "NAD": "Namibian Dollar",
        "NGN": "Nigerian Naira",
        "NIO": "Nicaraguan Córdoba",
        "NOK": "Norwegian Krone",
        "NPR": "Nepalese Rupee",
        "NZD": "New Zealand Dollar",
        "OMR": "Omani Rial",
        "PAB": "Panamanian Balboa",
        "PEN": "Peruvian Nuevo Sol",
        "PGK": "Papua New Guinean Kina",
        "PHP": "Philippine Peso",
        "PKR": "Pakistani Rupee",
        "PLN": "Polish Zloty",
        "PYG": "Paraguayan Guarani",
        "QAR": "Qatari Rial",
        "RON": "Romanian Leu",
        "RSD": "Serbian Dinar",
        "RUB": "Russian Ruble",
        "RWF": "Rwandan Franc",
        "SAR": "Saudi Riyal",
        "SBD": "Solomon Islands Dollar",
        "SCR": "Seychellois Rupee",
        "SDG": "Sudanese Pound",
        "SEK": "Swedish Krona",
        "SGD": "Singapore Dollar",
        "SHP": "Saint Helena Pound",
        "SLL": "Sierra Leonean Leone",
        "SOS": "Somali Shilling",
        "SRD": "Surinamese Dollar",
        "SSP": "South Sudanese Pound",
        "STD": "São Tomé and Príncipe Dobra (pre-2018)",
        "STN": "São Tomé and Príncipe Dobra",
        "SVC": "Salvadoran Colón",
        "SYP": "Syrian Pound",
        "SZL": "Swazi Lilangeni",
        "THB": "Thai Baht",
        "TJS": "Tajikistani Somoni",
        "TMT": "Turkmenistani Manat",
        "TND": "Tunisian Dinar",
        "TOP": "Tongan Pa'anga",
        "TRY": "Turkish Lira",
        "TTD": "Trinidad and Tobago Dollar",
        "TWD": "New Taiwan Dollar",
        "TZS": "Tanzanian Shilling",
        "UAH": "Ukrainian Hryvnia",
        "UGX": "Ugandan Shilling",
        "UYU": "Uruguayan Peso",
        "UZS": "Uzbekistan Som",
        "VEF": "Venezuelan Bolívar Fuerte (Old)",
        "VES": "Venezuelan Bolívar Soberano",
        "VND": "Vietnamese Dong",
        "VUV": "Vanuatu Vatu",
        "WST": "Samoan Tala",
        "XAF": "CFA Franc BEAC",
        "XAG": "Silver Ounce",
        "XAU": "Gold Ounce",
        "XCD": "East Caribbean Dollar",
        "XDR": "Special Drawing Rights",
        "XOF": "CFA Franc BCEAO",
        "XPD": "Palladium Ounce",
        "XPF": "CFP Franc",
        "XPT": "Platinum Ounce",
        "YER": "Yemeni Rial",
        "ZAR": "South African Rand",
        "ZMW": "Zambian Kwacha",
        "ZWL": "Zimbabwean Dollar"
      },
      chosenCurrency: Data.data.storeCurrency,

      hasSubscription: false,
      hostedPage: null,
      chosenVersion: Data.data.appOptions.opties_versie,
      plannedShifts_fallback: [],

      specialChanges: false,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    // const location = await APIGetLocations.Request();

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    // fallback planned shifts active employees cheat
    const datePreviousWeek = new Date(new Date().setDate(new Date().getDate() - 7));
    const mondayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 1));
    const sundayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 0));

    const [
      location,
      accounts,
      infoExtra,
      salaries,
      salaryModels,
      breakRules,
      exclusions,
      leaveBalances,
      leaveTypes,
      leaveTypeBalances,
      defaultWorkdays,
      holidayPeriods,
      bonus,
      parts,
      absTypes,
      skills,
      publicHolidays,
      minmaxYear,
      hostedPages,
      plannedShifts_fallback,
    ] = await Promise.all([
      APIGetLocations.Request(),
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`),
      APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),
      APIGET.Request(`SELECT *`, `FROM Uitzondering`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`),
      APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, `ORDER BY vt_naam`),
      APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM Openingstijden`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VakantiePeriode`, null, `WHERE vp_begin >= '${Constants.dateToString(new Date())}'`, null, `ORDER BY vp_begin`),
      APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, `ORDER BY t_datum, t_factor DESC`),
      APIGetParticular.Request(),
      APIGetAbsencetypes.Request(),
      APIGetSkills.Request(),
      APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${new Date().getFullYear() - 1}`, null, `ORDER BY f_datum`),
      APIGET.Request(`SELECT MIN(f_datum) AS min_jaar, MAX(f_datum) AS max_jaar`, `FROM Feestdag`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM HostedPage`, null, null, null, null),
      APIGET.Request(`SELECT rooster_id, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${mondayPreviousWeek}' AND '${sundayPreviousWeek}'`, `GROUP BY rooster_info_id`, null),
    ]);

    // has subscription
    const hostedPage = Constants.isEmpty(hostedPages) === false && hostedPages.length > 0 ? hostedPages[0] : null;
    const subscriptionObj = Constants.isEmpty(hostedPage) === false ? await APIGetSubscription.Request(hostedPage.hp_subscription_id) : null;
    const hasSubscription = Constants.isEmpty(subscriptionObj) === false && (subscriptionObj.status === 'active' || subscriptionObj.status === 'in_trial');

    // delete hostedpage if not active
    // if (Constants.isEmpty(subscriptionObj) === false && subscriptionObj.status !== 'active' && subscriptionObj.status !== 'in_trial') {
    //   await APIDELETE.Request(`DELETE FROM HostedPage`, `WHERE hp_id IS NOT NULL`);
    // }

    // const accounts = await APIGetAllAccounts.Request();

    // const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    // const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);

    // const breakRules = await APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`);

    // const exclusions = await APIGET.Request(`SELECT *`, `FROM Uitzondering`, null, null, null, null);

    // const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    // const leaveTypes = await APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, `ORDER BY vt_naam`);
    // const leaveTypeBalances = await APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, null);
    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vt_id');

    let correctTypes = true;
    for (const lt of leaveTypes) {
      if (Constants.objectArrayContainsKey(leaveTypeBalances, 'vtb_vt_id', lt.vt_id) === false) {
        correctTypes = false;
        break;
      }
    }

    let _defaultWorkdays = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      0: '',
    };

    // const defaultWorkdays = await APIGET.Request(`SELECT *`, `FROM Openingstijden`, null, null, null, null);

    for (const _wd of defaultWorkdays) {
      _defaultWorkdays[parseInt(_wd.ot_dag)] = `${_wd.ot_begin.substr(0,5)} - ${_wd.ot_eind.substr(0,5)}`;
    }

    // const holidayPeriods = await APIGET.Request(`SELECT *`, `FROM VakantiePeriode`, null, `WHERE vp_begin >= '${Constants.dateToString(new Date())}'`, null, `ORDER BY vp_begin`);

    // const bonus = await APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, `ORDER BY t_factor DESC`);

    // const parts = await APIGetParticular.Request();
    // const absTypes = await APIGetAbsencetypes.Request();
    // const skills = await APIGetSkills.Request();

    // const publicHolidays = await APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${new Date().getFullYear() - 1}`, null, `ORDER BY f_datum`);
    // const minmaxYear = await APIGET.Request(`SELECT MIN(f_datum) AS min_jaar, MAX(f_datum) AS max_jaar`, `FROM Feestdag`, null, null, null, null);

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    // clockin absence
    let abs_clockIn = null;
    for (const abs of absTypes) {
      if (Constants.isTrue(abs.afw_inklok)) {
        abs_clockIn = abs.afw_id;
        break;
      }
    }

    this.setState({
      clockSystem: options[0].opties_inklok === '1' ? true : false,
      breakOption: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
      hoursRegister: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
      availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
      open: options[0].opties_opendienst === '1' ? true : false,
      iban: options[0].opties_iban === '1' ? true : false,
      bsn: options[0].opties_bsn === '1' ? true : false,

      opties_smartplan: options[0].opties_smartplan === '1',
      opties_financien: options[0].opties_financien === '1',
      opties_urenregistratie2: options[0].opties_urenregistratie2 === '1',
      opties_klokPlanning: options[0].opties_klokPlanning === '1',
      opties_klokAfronden: options[0].opties_klokAfronden,
      opties_pauzeAfronden: options[0].opties_pauzeAfronden,
      opties_urenEigen: options[0].opties_urenEigen === '1',
      opties_onbpd: options[0].opties_onbpd,
      opties_onbpw: options[0].opties_onbpw,
      opties_ruilenComp: options[0].opties_ruilenComp === '1',
      opties_ruilenGeenFunctie: options[0].opties_ruilenGeenFunctie === '1',
      opties_verlofDeadline: options[0].opties_verlofDeadline,
      opties_ruilenFunctie: options[0].opties_ruilenFunctie === '1',
      opties_planBeschikbaar: options[0].opties_planBeschikbaar === '1',
      opties_planFunctieSort: options[0].opties_planFunctieSort === '1',
      opties_contractVerlopen: options[0].opties_contractVerlopen === '1',
      opties_klokMobiel: options[0].opties_klokMobiel === '1',
      opties_fteUren: options[0].opties_fteUren,
      opties_loonfactor: options[0].opties_loonfactor,
      opties_BTW: options[0].opties_BTW,
      opties_omzetBTW: options[0].opties_omzetBTW === '1',
      opties_loonPercentage: options[0].opties_loonPercentage,
      opties_chat: options[0].opties_chat === '1',
      opties_ruilen_vantevoren: options[0].opties_ruilen_vantevoren,
      opties_feestdagen: options[0].opties_feestdagen === '1',
      opties_eindtijd_weergeven: options[0].opties_eindtijd_weergeven === '1',
      opties_opendienst_beschikbaar: options[0].opties_opendienst_beschikbaar === '1',
      opties_infoplanner_verborgen: options[0].opties_infoplanner_verborgen === '1',
      opties_uren_daadwerkelijk: options[0].opties_uren_daadwerkelijk === '1',
      opties_overuren: options[0].opties_overuren === '1',
      opties_verlof_limiet: options[0].opties_verlof_limiet,
      opties_planner_drukte: options[0].opties_planner_drukte === '1',
      opties_planner_loonkosten: options[0].opties_planner_loonkosten === '1',
      opties_planner_vorigOmzet: options[0].opties_planner_vorigOmzet === '1',
      opties_planner_verwachteOmzet: options[0].opties_planner_verwachteOmzet === '1',
      opties_planner_omzet: options[0].opties_planner_omzet === '1',
      opties_planner_productiviteit: options[0].opties_planner_productiviteit === '1',
      opties_planner_berekeningen: options[0].opties_planner_berekeningen === '1',
      opties_planner_extraDiensten: options[0].opties_planner_extraDiensten === '1',
      opties_planner_contracturen: options[0].opties_planner_contracturen === '1',
      opties_onbpm: options[0].opties_onbpm,
      opties_verlofsaldo: options[0].opties_verlofsaldo === '1',
      opties_versie: options[0].opties_versie,

      hasLocation: location !== null,

      accounts: accounts,
      infoExtra: infoExtraObj,

      salaries: salariesObj, 
      salariesArray: salaries,

      salaryModels: salaryModels,
      selectedSalaryModel: salaryModels.length > 0 ? salaryModels[0].ut_id : null,
      newSalaries: newSalariesObj,

      breakRules: breakRules,

      holidayPeriods: holidayPeriods,

      particularities: parts,
      absenceTypes: absTypes,
      skills: skills,

      exclusions: exclusions,

      bonus: bonus,

      leaveBalances: leaveBalances,
      leaveTypes: leaveTypes,
      leaveTypeBalances: leaveTypeBalancesObj,
      correctTypes: correctTypes,

      publicHolidays: publicHolidays,
      minYear: minmaxYear.length > 0 && typeof minmaxYear[0].min_jaar !== 'undefined' && minmaxYear[0].min_jaar !== null && typeof minmaxYear[0].max_jaar !== 'undefined' && minmaxYear[0].max_jaar !== null ? parseInt(Constants.stringToDate(minmaxYear[0].min_jaar).getFullYear()) : this.state.publicHoliday_year,
      maxYear: minmaxYear.length > 0 && typeof minmaxYear[0].min_jaar !== 'undefined' && minmaxYear[0].min_jaar !== null && typeof minmaxYear[0].max_jaar !== 'undefined' && minmaxYear[0].max_jaar !== null ? parseInt(Constants.stringToDate(minmaxYear[0].max_jaar).getFullYear()) : this.state.publicHoliday_year,

      defaultWorkdays: _defaultWorkdays,
      
      abs_clockIn: abs_clockIn,

      hostedPage: hostedPage,
      hasSubscription: hasSubscription,
      plannedShifts_fallback: plannedShifts_fallback,

    });

    this.setState({loading: false}, async () => {

      window.Chargebee.registerAgain();

      const parent = this;

      const cbInstance = window.Chargebee.getInstance();
      cbInstance.setCheckoutCallbacks(function(cart) {
        return {

          success: async function(hpid) {
            // console.log('success', hpid, cart);
            await parent.succeedCheckout(hpid);
          }

        }
      });
      // const cart = cbInstance.getCart();
      // const customer = {
      //   first_name: Data.data.accountData.info_voornaam, 
      //   last_name: Constants.isEmpty(Data.data.accountData.info_tussenvoegsel) ? Data.data.accountData.info_achternaam : Data.data.accountData.info_tussenvoegsel + ' ' + Data.data.accountData.info_achternaam, 
      //   email: Data.data.accountData.info_email,
      //   billing_address: {
      //     first_name: Data.data.accountData.info_voornaam, 
      //     last_name: Constants.isEmpty(Data.data.accountData.info_tussenvoegsel) ? Data.data.accountData.info_achternaam : Data.data.accountData.info_tussenvoegsel + ' ' + Data.data.accountData.info_achternaam, 
      //     company: Data.data.storeName, 
      //     email: Data.data.accountData.info_email,
      //   }
      // };
      // cart.setCustomer(customer);

      // GET DATA
      // const paymentData = await APIGET.Request(`SELECT *`, `FROM HostedPage`, null, null, null, null);
      // const result = await APIGetHostedPage.Request('MSEFWV5DZNbDsoanVi3KE5ji6435bitcu');
      // const result = await APIGetSubscription.Request('AzyXUdSwl1SCUv1h');

      // UPDATE DATA
      // await APIUpdateQuantity.Request('AzyXUdSwl1SCUv1h', 50);
      // await APIUpgradeSubscription.Request('AzyXUdSwl1SCUv1h', 'Medewerkers2-EUR-Monthly', 50);
      // await APIEDIT.Request(`UPDATE Opties`, `SET opties_versie = 2`, `WHERE opties_id >= 0`);

      // CANCEL
      // await APICancelSubscription.Request('AzyXUdSwl1SCUv1h');

      // CHECKOUT SUCCESS
      //await APIADD.Request(`INSERT INTO HostedPage`, `VALUES ('${result.id}', '${result.content.customer.id}', '${result.content.subscription.id}', NULL)`);
      //await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET pakket_id = 6`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

      // REMOVE DATA
      // if (result.status !== 'active') {
      //   await APIDELETE.Request(`DELETE FROM HostedPage`, `WHERE hp_id IS NOT NULL`);
      // }

      // console.log(result);
      // console.log(result.status);

    });

    if(this.props.location.fromTut === true) {
      this.openMissingModal();
    }

  }

  async succeedCheckout(hpid) {

    this.setState({ loading: true });

    const result = await APIGetHostedPage.Request(hpid);

    await APIADD.Request(`INSERT INTO HostedPage`, `VALUES ('${result.id}', '${result.content.customer.id}', '${result.content.subscription.id}', NULL)`);
    await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET pakket_id = 101`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

    if (Constants.isEmpty(Data.data.trial) === false) {

      let _trialEnd = new Date(Data.data.trial);
      _trialEnd = new Date(_trialEnd.setDate(_trialEnd.getDate() + 1));
      const _now = await Constants.getDateNow2();

      if (_trialEnd.getTime() > _now.getTime()) {

        const product = Data.data.appOptions.opties_versie === '2' ? Constants.isEmpty(Data.data.trial) === false ? "TIMETRACKING_TRIAL-EUR-MONTHLY" : "TIMETRACKING-EUR-MONTHLY" : Constants.isEmpty(Data.data.trial) === false ? "SCHEDULING_TRIAL-EUR-MONTHLY" : "SCHEDULING-EUR-MONTHLY";
        const activeEmployees = this.getActiveEmployees();

        let trialEnd = new Date(Data.data.trial);
        trialEnd = new Date(trialEnd.setDate(trialEnd.getDate() + 1));
        await APIUpdateSubscriptionTrial.Request(result.content.subscription.id, activeEmployees, (trialEnd.getTime() / 1000), product);

      }

    }

    await this.componentDidMount();

    this.setState({ loading: false });

  }

  async reloadScreen() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    this.setState({loading: true});

    const location = await APIGetLocations.Request();

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    this.setState({
      clockSystem: options[0].opties_inklok === '1' ? true : false,
      breakOption: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
      hoursRegister: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
      availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
      open: options[0].opties_opendienst === '1' ? true : false,
      iban: options[0].opties_iban === '1' ? true : false,
      bsn: options[0].opties_bsn === '1' ? true : false,
      hasLocation: location !== null,
    });

    this.setState({loading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      showPartModal: false,
      showAbsModal: false,
      showSkillsModal: false,
      showBonusModal: false,
      showLeaveBalanceModal: false,
      showLeaveTypeModal: false,
      showPublicHolidayModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  toggleSwitchClockFALSE() {

      this.setState({ 
          clockSystem: false,
          breakOption: 'PLANNER',
          hoursRegister: 'PLANNER',
          hoursRegisterDisabled: true,
      }, 
        () => this.editOptions(false)
      );

  }

  toggleSwitchClock() {

        this.setState({ 
            clockSystem: !this.state.clockSystem,
            breakOption: this.state.breakOption === 'CLOCK' ? 'PLANNER' : this.state.breakOption,
            hoursRegister: this.state.clockSystem === true ? 'PLANNER' : 'CLOCK',
            hoursRegisterDisabled: this.state.clockSystem,
        }, () => {

          this.editNewOptions('opties_uren_daadwerkelijk', this.state.clockSystem);
          this.editOptions(false);

        });

  }

  toggleSwitchBreakSchedule() {

          this.setState({ 
              breakOption: this.state.breakOption === 'PLANNER' ? 'PLANNER' : 'PLANNER',
          }, 
            () => this.editOptions(false)
          );

  }

  toggleSwitchBreakClock() {

          this.setState({ 
              breakOption: this.state.breakOption === 'CLOCK' ? 'PLANNER' : 'CLOCK',
          }, 
            () => this.editOptions(false)
          );

  }

  toggleSwitchBreakScheduleCalc() {

          this.setState({ 
              breakOption: this.state.breakOption === 'CALC' ? 'PLANNER' : 'CALC',
          }, 
            () => this.editOptions(false)
          );

  }

  toggleSwitchHours() {

          this.setState({ 
              hoursRegister: this.state.hoursRegister === 'PLANNER' ? 'CLOCK' : 'PLANNER',
              breakOption: this.state.hoursRegister === 'CLOCK' && this.state.breakOption === 'CLOCK' ? 'PLANNER' : this.state.breakOption,
          }, () => {
            this.editOptions(false);
            this.editNewOptions('opties_uren_daadwerkelijk', this.state.hoursRegister === 'CLOCK' ? true : this.state.opties_uren_daadwerkelijk);
          });

  }

  toggleSwitchAvailability() {

          this.setState({ 
              availability: this.state.availability === 'AVAILABILITY' ? 'NONE' : 'AVAILABILITY',
          }, 
            () => this.editOptions(false)
          );

  }

  toggleSwitchSchool() {

      this.setState({ 
          availability: this.state.availability === 'SCHOOL' ? 'NONE' : 'SCHOOL',
      }, 
        () => this.editOptions(false)
      );

  }

  toggleSwitchStandard() {

    this.setState({ 
        availability: this.state.availability === 'STANDARD' ? 'NONE' : 'STANDARD',
    }, 
      () => this.editOptions(false)
    );

}

  toggleSwitchUnavailability() {

    this.setState({ 
        availability: this.state.availability === 'UNAVAILABILITY' ? 'NONE' : 'UNAVAILABILITY',
    }, 
      () => this.editOptions(false)
    );

  }

  toggleSwitchOpen() {

    this.setState({ 
        open: !this.state.open,
    }, 
      () => this.editOptions(false)
    );

  }

  toggleSwitchIban() {

    this.setState({ 
        iban: !this.state.iban,
    }, 
      () => this.editOptions(false)
    );

  }

  toggleSwitchBsn() {

    this.setState({ 
        bsn: !this.state.bsn,
    }, 
      () => this.editOptions(false)
    );

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  toggleSwitchAvailabilityOff() {

          this.setState({ 
              availability: this.state.availability === 'NONE' ? 'AVAILABILITY' : 'NONE',
          }, 
            () => this.editOptions(false)
          );

  }

  // deleteAlert() {

  //   this.setState({
  //       alertTitle: 'Waarschuwing',
  //       alertBody: 'Wanneer je de beschikbaarheidsopties verandert, zullen medewerkers opnieuw hun beschikbaarheid moeten doorgeven. Wil je doorgaan?',
  //       alertButtons: 2,
  //       alertButtonText: ['Annuleren', 'Doorgaan'],
  //       alertButtonAction: this.editOptions.bind(this, true),
  //       showAlert: true,
  //   });

  // }
  
  async editOptions(reset) {

    // if((this.state.availability !== Data.data.appOptions.availability) && reset === false) {
    //   this.deleteAlert();
    //   return;
    // }

    this.setState({buttonLoading: true});

    const added = await APIEditOptions.Request(
      this.state.clockSystem === true ? 1 : 0,
      this.state.breakOption === 'PLANNER' ? 0 : this.state.breakOption === 'CLOCK' && this.state.clockSystem === true ? 1 : this.state.breakOption === 'CALC' ? 2 : 0,
      this.state.hoursRegister === 'PLANNER' ? 0 : 1,
      this.state.availability === 'AVAILABILITY' ? 0 : this.state.availability === 'SCHOOL' ? 1 : this.state.availability === 'UNAVAILABILITY' ? 2 : this.state.availability === 'STANDARD' ? 3 : 4,
      this.state.open === true ? 1 : 0,
      this.state.iban === true ? 1 : 0,
      this.state.bsn === true ? 1 : 0,
      Data.data.appOptions.id
    );

    // if(reset === true) {
    //   await APIDeleteAvailabilities.Request();
    // }

    this.setState({buttonLoading: false});

    if(added === true) {

      // Constants.defaultAlert(this, 'Melding', 'Opties zijn gewijzigd');

      // setTimeout(() => {
      //   this.props.history.push("/");
      // }, 1000);

      // return;

      Data.data.appOptions = {
        ...Data.data.appOptions,
        id: Data.data.appOptions.id,
        clock: this.state.clockSystem,
        brk: this.state.breakOption,
        hours: this.state.hoursRegister,
        availability: this.state.availability,
        open: this.state.open,
        iban: this.state.iban,
        bsn: this.state.bsn,
      };

    } else {

      this.setState({loading: false});

    }

  }

  openLocationsModal() {

    this.setState({
      modalData: null,
      modalMode: 0,
      showModal: true,
    });

  }

  openMissingModal() {
    this.setState({ missingModalVisible: true });
  }

  closeMissingModal() {
      this.setState({ missingModalVisible: false });
  }

  async editNewOptions(set, value, bool = true) {

    if (set === 'opties_versie' && value === '0') {
      const _continue = window.confirm('Weet je zeker dat je wilt overschakelen naar de simpele versie?');
      if (_continue === false) {
        return;
      }
    }

     await APIEditOptions2.Request(set, bool ? value === true ? '1' : '0' : value, Data.data.appOptions.id);

     Data.data.appOptions = {
      ...Data.data.appOptions,
      [set]: bool ? value === true ? '1' : '0' : value,
    };

    this.forceUpdate();

    this.setState({
      [set]: value,
    }, async () => {

      if (set === 'opties_versie' && value === '0') {

        await Promise.all([
          this.editNewOptions('opties_urenregistratie2', false),
          this.editNewOptions('opties_uren_daadwerkelijk', false),
          this.editNewOptions('opties_verlofsaldo', false),
          this.editNewOptions('opties_financien', false),
          this.toggleSwitchClockFALSE(),
          this.editNewOptions('opties_planner_loonkosten', false),
          this.editNewOptions('opties_planner_vorigOmzet', false),
          this.editNewOptions('opties_planner_verwachteOmzet', false),
          this.editNewOptions('opties_planner_omzet', false),
          this.editNewOptions('opties_planner_productiviteit', false),
          this.editNewOptions('opties_planner_berekeningen', false),
          this.editNewOptions('opties_overuren', false),
        ]);

      }

      if (set === 'opties_versie' && value === '1') {

        await Promise.all([
          //this.editNewOptions('opties_urenregistratie2', false),
          //this.editNewOptions('opties_uren_daadwerkelijk', false),
          // this.editNewOptions('opties_verlofsaldo', false),
          // this.editNewOptions('opties_financien', false),
          // this.toggleSwitchClockFALSE(),
          // this.editNewOptions('opties_planner_loonkosten', false),
          // this.editNewOptions('opties_planner_vorigOmzet', false),
          // this.editNewOptions('opties_planner_verwachteOmzet', false),
          // this.editNewOptions('opties_planner_omzet', false),
          // this.editNewOptions('opties_planner_productiviteit', false),
          // this.editNewOptions('opties_planner_berekeningen', false),
          // this.editNewOptions('opties_overuren', false),
        ]);

      }

      if (set === 'opties_urenregistratie2' && value === false) {

        await Promise.all([
          this.editNewOptions('opties_overuren', false),
          this.editNewOptions('opties_uren_daadwerkelijk', false),
          this.editNewOptions('opties_financien', false),
          this.toggleSwitchClockFALSE(),
        ]);

      }

      if (set === 'opties_financien' && value === false) {

        await Promise.all([
          this.editNewOptions('opties_planner_loonkosten', false),
          this.editNewOptions('opties_planner_vorigOmzet', false),
          this.editNewOptions('opties_planner_verwachteOmzet', false),
          this.editNewOptions('opties_planner_omzet', false),
          this.editNewOptions('opties_planner_productiviteit', false),
          this.editNewOptions('opties_planner_berekeningen', false),
        ]);

      }

      if (set === 'opties_uren_daadwerkelijk' && value === false) {

        await Promise.all([
          this.editNewOptions('opties_overuren', false),
        ]);

      }

    });

  }

  async onChangeNumberData(data, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (data === 'day') {
      if (nr === '') {
        this.editNewOptions('opties_onbpd', '0', false);
      } else {
        this.editNewOptions('opties_onbpd', parseInt(nr).toString(), false);
      }
    } else if (data === 'week') {
      if (nr === '') {
        this.editNewOptions('opties_onbpw', '0', false);
      } else {
        this.editNewOptions('opties_onbpw', parseInt(nr).toString(), false);
      }
    } else if (data === 'month') {
      if (nr === '') {
        this.editNewOptions('opties_onbpm', '0', false);
      } else {
        this.editNewOptions('opties_onbpm', parseInt(nr).toString(), false);
      }
    } else if (data === 'vacation') {
      if (nr === '') {
        this.editNewOptions('opties_verlofDeadline', '0', false);
      } else {
        this.editNewOptions('opties_verlofDeadline', parseInt(nr).toString(), false);
      }
    } else if (data === 'vacationLimit') {
      if (nr === '') {
        this.editNewOptions('opties_verlof_limiet', '0', false);
      } else {
        this.editNewOptions('opties_verlof_limiet', parseInt(nr).toString(), false);
      }
    } else if (data === 'tradeDays') {
      if (nr === '') {
        this.editNewOptions('opties_ruilen_vantevoren', '0', false);
      } else {
        this.editNewOptions('opties_ruilen_vantevoren', parseInt(nr).toString(), false);
      }
    } else if (data === 'roundClock') {
      if (nr === '') {
        this.editNewOptions('opties_klokAfronden', '0', false);
      } else {
        this.editNewOptions('opties_klokAfronden', parseInt(nr).toString(), false);
      }
    } else if (data === 'roundBreaks') {
      if (nr === '') {
        this.editNewOptions('opties_pauzeAfronden', '0', false);
      } else {
        this.editNewOptions('opties_pauzeAfronden', parseInt(nr).toString(), false);
      }
    } else if (data === 'dailyWorkHours') {
      if (nr === '') {
        this.setState({dailyWorkHours: '0'});
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_dag_werkuren = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_dag_werkuren = '0';
      } else {
        this.setState({dailyWorkHours: Constants.round(parseFloat(nr)).toString()});
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_dag_werkuren = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_dag_werkuren = Constants.round(parseFloat(nr)).toString();
      }
    } else if (data === 'fte') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_fteUren = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_fteUren = '0';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_fteUren = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_fteUren = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_fteUren: nr});
    } else if (data === 'btw') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_BTW = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_BTW = '0';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_BTW = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_BTW = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_BTW: nr});
    } else if (data === 'salaryFactor') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonfactor = 1`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonfactor = '1';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonfactor = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonfactor = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_loonfactor: nr});
    } else if (data === 'salaryPercent') {
      if (nr === '') {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonPercentage = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonPercentage = '0';
      } else {
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_loonPercentage = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_loonPercentage = Constants.round(parseFloat(nr)).toString();
      }
      this.setState({opties_loonPercentage: nr});
    }
  }

  onChangeNumberDataFloat(data, min, max, event, key, filled) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (data === 'salary') {

      if (filled === true) {

        let salaries = this.state.newSalaries;

        if (nr !== '') {
          salaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid = nr;
        } else {
          salaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid = '';
        }

        this.setState({ newSalaries: salaries, specialChanges: true });

      } else { // key = index of array here

        let salaries = this.state.emptySalaries;

        if (nr !== '') {
          salaries[key].amount = nr;
        } else {
          salaries[key].amount = '';
        }

        this.setState({ emptySalaries: salaries, specialChanges: true });

      }

    } else if (data === 'breakHours') {

      if (filled === true) {

        let breakRules = this.state.breakRules;

        if (nr !== '') {
          breakRules[key].pr_uren = nr;
        } else {
          breakRules[key].pr_uren = '';
        }

        this.setState({ breakRules: breakRules });

      } else { // key = index of array here

        let breakRules = this.state.emptyBreakRules;

        if (nr !== '') {
          breakRules[key].pr_uren = nr;
        } else {
          breakRules[key].pr_uren = '';
        }

        this.setState({ emptyBreakRules: breakRules });

      }

    }

  }

  onChangeNumberDataInt(data, min, max, event, key, filled) {

    this.setState({ showError: false });

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (data === 'age') {

      if (filled === true) {

        let salaries = this.state.newSalaries;

        if (nr !== '') {
          salaries[this.state.selectedSalaryModel][key].uurloon_leeftijd = parseInt(nr).toString();
        } else {
          salaries[this.state.selectedSalaryModel][key].uurloon_leeftijd = '';
        }

        this.setState({ newSalaries: salaries, specialChanges: true });

      } else { // key = index of array here

        let salaries = this.state.emptySalaries;

        if (nr !== '') {
          salaries[key].age = parseInt(nr).toString();
        } else {
          salaries[key].age = '';
        }

        this.setState({ emptySalaries: salaries, specialChanges: true });

      }

    } else if (data === 'breakMinutes') {

      if (filled === true) {

        let breakRules = this.state.breakRules;

        if (nr !== '') {
          breakRules[key].pr_pauze = parseInt(nr).toString();
        } else {
          breakRules[key].pr_pauze = '';
        }

        this.setState({ breakRules: breakRules });

      } else { // key = index of array here

        let breakRules = this.state.emptyBreakRules;

        if (nr !== '') {
          breakRules[key].pr_pauze = parseInt(nr).toString();
        } else {
          breakRules[key].pr_pauze = '';
        }

        this.setState({ emptyBreakRules: breakRules });

      }

    }

  }

  selectMenu(menu) {

    if (this.state.specialChanges === true) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ specialChanges: false });
      }
    }

    let subMenu = null;

    if (menu === 'general') {
      subMenu = 'general';
    } else if (menu === 'planner') {
      subMenu = 'planner';
    }

    this.setState({
      menu: menu,
      subMenu: subMenu,
    }, () => {

      if (menu === 'general') {
        window.Chargebee.registerAgain();
      }

    });

  }

  selectSubMenu(menu) {

    if (this.state.specialChanges === true) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ specialChanges: false });
      }
    }

    this.setState({
      subMenu: menu,
    }, () => {

      if (menu === 'general') {
        window.Chargebee.registerAgain();
      }

    });

  }

  async setWorkDay(day) {

    if (day === 'mon') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_maandag = ${this.state.workWeekMonday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_maandag = this.state.workWeekMonday === false ? '1' : '0';
      this.setState({ workWeekMonday: !this.state.workWeekMonday});
    } else if (day === 'tue') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_dinsdag = ${this.state.workWeekTuesday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_dinsdag = this.state.workWeekTuesday === false ? '1' : '0';
      this.setState({ workWeekTuesday: !this.state.workWeekTuesday});
    } else if (day === 'wed') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_woensdag = ${this.state.workWeekWednesday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_woensdag = this.state.workWeekWednesday === false ? '1' : '0';
      this.setState({ workWeekWednesday: !this.state.workWeekWednesday});
    } else if (day === 'thu') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_donderdag = ${this.state.workWeekThursday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_donderdag = this.state.workWeekThursday === false ? '1' : '0';
      this.setState({ workWeekThursday: !this.state.workWeekThursday});
    } else if (day === 'fri') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_vrijdag = ${this.state.workWeekFriday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_vrijdag = this.state.workWeekFriday === false ? '1' : '0';
      this.setState({ workWeekFriday: !this.state.workWeekFriday});
    } else if (day === 'sat') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_zaterdag = ${this.state.workWeekSaturday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_zaterdag = this.state.workWeekSaturday === false ? '1' : '0';
      this.setState({ workWeekSaturday: !this.state.workWeekSaturday});
    } else if (day === 'sun') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_zondag = ${this.state.workWeekSunday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_zondag = this.state.workWeekSunday === false ? '1' : '0';
      this.setState({ workWeekSunday: !this.state.workWeekSunday});
    }

  }

  async setEveryoneToWorkDays() {

    this.setState({
      reloading: true,
    });

    let promises = [];

    for (const acc of this.state.accounts) {

      if (typeof this.state.infoExtra[acc.info_id] === 'undefined') {
        promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag, info2_werkweek)`, `VALUES (NULL, ${acc.info_id}, ${this.state.workWeekMonday === true ? '1' : '0'}, ${this.state.workWeekTuesday === true ? '1' : '0'}, ${this.state.workWeekWednesday === true ? '1' : '0'}, ${this.state.workWeekThursday === true ? '1' : '0'}, ${this.state.workWeekFriday === true ? '1' : '0'}, ${this.state.workWeekSaturday === true ? '1' : '0'}, ${this.state.workWeekSunday === true ? '1' : '0'}, 1)`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${this.state.workWeekMonday === true ? '1' : '0'}, info2_werkdag_dinsdag = ${this.state.workWeekTuesday === true ? '1' : '0'}, info2_werkdag_woensdag = ${this.state.workWeekWednesday === true ? '1' : '0'}, info2_werkdag_donderdag = ${this.state.workWeekThursday === true ? '1' : '0'}, info2_werkdag_vrijdag = ${this.state.workWeekFriday === true ? '1' : '0'}, info2_werkdag_zaterdag = ${this.state.workWeekSaturday === true ? '1' : '0'}, info2_werkdag_zondag = ${this.state.workWeekSunday === true ? '1' : '0'}, info2_werkweek = 1`, `WHERE info2_info_id = ${acc.info_id}`));
      }

    }

    await Promise.all(promises);

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({
      infoExtra: infoExtraObj,
      reloading: false,
    });

  }

  renderSalaries() {

    let toRender = [];

    if (this.state.newSalaries[this.state.selectedSalaryModel].length > 0) {

      toRender.push(

        <div className='salaries_salaryContainer' style={{marginLeft: '1%', marginBottom: '0.3vw', marginTop: '1vw'}}>

          <span style={{fontsize: '0.8vw'}}>Leeftijd</span>

          <span style={{fontsize: '0.8vw'}}>Uurloon</span>

        </div>

      );

    }

    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {

      const s = this.state.newSalaries[this.state.selectedSalaryModel][key];

      toRender.push(

        <div key={s.uurloon_id} className='salaries_salaryContainer' style={{marginLeft: '1%'}}>

          <input 
            className={'inputField2'}
            min={1}
            max={200}
            value={s.uurloon_leeftijd}
            onChange={(event) => this.onChangeNumberDataInt('age', 1, 200, event, key, true)}
            placeholder={'Leeftijd'}
            type={'number'}
            step={1}
            style={{marginTop: 0, width: '15%', fontsize: '0.8vw'}}
          />

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

            <i className='salaries_delButton fas fa-minus-circle' style={{marginRight: '1vw', fontSize: '0.8vw'}} onClick={() => this.deleteAlertSalary(s)} />

            <input 
              className={'inputField2'}
              min={0}
              max={999999999}
              value={s.uurloon_hoeveelheid}
              onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event, key, true)}
              placeholder={'Uurloon'}
              type={'number'}
              step={0.01}
              style={{marginTop: 0, width: '100%', fontsize: '0.8vw'}}
            />
          
          </div>

        </div>

      );

    }

    return toRender;

  }

  renderEmptySalaries() {

    let toRender = [];

    if (this.state.emptySalaries.length > 0) {

      toRender.push(

        <div className='salaries_salaryContainer' style={{marginBottom: '0.3vw', marginTop: '1vw'}}>

          <span style={{fontsize: '0.8vw'}}>Leeftijd</span>

          <span style={{fontsize: '0.8vw'}}>Uurloon</span>

        </div>

      );

    }

    for (let index in this.state.emptySalaries) {

      const e = this.state.emptySalaries[index];

      toRender.push(

        <div key={index + 'e'} className='salaries_salaryContainer'>

          <input 
            className={'inputField2'}
            min={1}
            max={200}
            value={e.age}
            onChange={(event) => this.onChangeNumberDataInt('age', 1, 200, event, index, false)}
            placeholder={'Leeftijd'}
            type={'number'}
            step={1}
            style={{marginTop: 0, width: '15%', fontsize: '0.8vw'}}
          />

          <input 
            className={'inputField2'}
            min={0}
            max={999999999}
            value={e.amount}
            onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event, index, false)}
            placeholder={'Uurloon'}
            type={'number'}
            step={0.01}
            style={{marginTop: 0, width: '35%', fontsize: '0.8vw'}}
          />

        </div>

      );

    }

    return toRender;

  }

  addRow() {

    let emptySalaries = this.state.emptySalaries;
    emptySalaries.push({age: '', amount: ''});

    this.setState({ emptySalaries: emptySalaries });

  }

  async saveSalaries() {

    if (this.checkValidationsSalary() === false) {
      return;
    }

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {
      if ((isNaN(parseInt(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)}, ${this.state.selectedSalaryModel})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], newSalaries: newSalariesObj,  reloading: false, specialChanges: false });

  }

  async applySalary() {

    if (this.checkValidationsSalary() === false) {
      return;
    }

    // SAVE THE CURRENT VALUES

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {
      if ((isNaN(parseInt(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)}, ${this.state.selectedSalaryModel})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    // DONE RELOADING AND SAVING

    const [
      accounts, 
      contracts, 
      infoExtra,
      dateNow,
    ] = await Promise.all(
    [
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      Constants.getDateNow(),
    ]);

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    let contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    // promises
    let promisesSalary = [];

    for (const acc of accounts) {

      if (typeof infoExtraObj[acc.info_id] === 'undefined' || infoExtraObj[acc.info_id].info2_ut_id === this.state.selectedSalaryModel) {

        const age = Constants.getAge(dateNow, Constants.stringToDate(acc.info_geboorte));
        let salary = 0;
        let foundSalary = false;
        for (const s of newSalariesObj[this.state.selectedSalaryModel]) {
          if (parseInt(s.uurloon_leeftijd) === age) {
            salary = parseFloat(s.uurloon_hoeveelheid);
            foundSalary = true;
            break;
          }
        }

        if (foundSalary === false) {
          if (age < parseInt(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_leeftijd)) {
            salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_hoeveelheid);
          } else if (age > parseInt(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd)) {
            salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid);
          }
        }

        const contract = Constants.getCurrentContract(contractsObj[acc.info_id], dateNow);

        if (contract === null) {
          //promisesSalary.push(APIADD.Request(`INSERT INTO ContractN (contract_info_id, contract_uurloon)`, `VALUES (${acc.info_id}, ${salary})`));
        } else {
          promisesSalary.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${salary}`, `WHERE contract_id = ${contract.contract_id}`));
        }

        if (typeof infoExtraObj[acc.info_id] === 'undefined') {
          promisesSalary.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_ut_id)`, `VALUES (NULL, ${acc.info_id}, ${this.state.selectedSalaryModel})`));
        }

      } else {
        continue;
      }

    }

    await Promise.all(promisesSalary);

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], newSalaries: newSalariesObj, reloading: false, specialChanges: false });

  }

  async resetSalary() {

    if (this.checkValidationsSalary() === false) {
      return;
    }

    // SAVE THE CURRENT VALUES

    this.setState({ reloading: true, showError: false });

    let promises = [];

    // first save salaries
    for (let key in this.state.newSalaries[this.state.selectedSalaryModel]) {
      if ((isNaN(parseInt(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd === '') || (isNaN(parseFloat(this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid)) || this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid === '')) {
        promises.push(APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${this.state.salaries[key].uurloon_id}`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE Uurloon`, `SET uurloon_leeftijd = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_leeftijd}, uurloon_hoeveelheid = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_hoeveelheid}`, `WHERE uurloon_id = ${this.state.newSalaries[this.state.selectedSalaryModel][key].uurloon_id}`));
      }
    }

    // then save empty salaries
    for (const e of this.state.emptySalaries) {
      if ((isNaN(parseInt(e.age)) || e.age === '') || (isNaN(parseFloat(e.amount)) || e.amount === '')) {
        continue;
      } else {
        promises.push(APIADD.Request(`INSERT INTO Uurloon`, `VALUES (NULL, ${parseInt(e.age)}, ${parseFloat(e.amount)}, ${this.state.selectedSalaryModel})`));
      }
    }

    await Promise.all(promises);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    // DONE RELOADING AND SAVING

    const [
      accounts, 
      contracts, 
      infoExtra,
      dateNow,
    ] = await Promise.all(
    [
      APIGetAllAccounts.Request(),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),
      Constants.getDateNow(),
    ]);

    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');
    let contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    // promises
    let promisesSalary = [];

    for (const acc of accounts) {

      const age = Constants.getAge(dateNow, Constants.stringToDate(acc.info_geboorte));
      let salary = 0;
      let foundSalary = false;
      for (const s of newSalariesObj[this.state.selectedSalaryModel]) {
        if (parseInt(s.uurloon_leeftijd) === age) {
          salary = parseFloat(s.uurloon_hoeveelheid);
          foundSalary = true;
          break;
        }
      }

      if (foundSalary === false) {
        if (age < parseInt(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_leeftijd)) {
          salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][0].uurloon_hoeveelheid);
        } else if (age > parseInt(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd)) {
          salary = parseFloat(newSalariesObj[this.state.selectedSalaryModel][newSalariesObj[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid);
        }
      }

      const contract = Constants.getCurrentContract(contractsObj[acc.info_id], dateNow);

      if (contract === null) {
        //promisesSalary.push(APIADD.Request(`INSERT INTO ContractN (contract_info_id, contract_uurloon)`, `VALUES (${acc.info_id}, ${salary})`));
      } else {
        promisesSalary.push(APIEDIT.Request(`UPDATE ContractN`, `SET contract_uurloon = ${salary}`, `WHERE contract_id = ${contract.contract_id}`));
      }

      if (typeof infoExtraObj[acc.info_id] === 'undefined') {
        promisesSalary.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_ut_id)`, `VALUES (NULL, ${acc.info_id}, ${this.state.selectedSalaryModel})`));
      } else {
        promisesSalary.push(APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_ut_id = ${this.state.selectedSalaryModel}`, `WHERE info2_info_id = ${acc.info_id}`));
      }

    }

    await Promise.all(promisesSalary);

    this.setState({ salaries: salariesObj, salariesArray: salaries, emptySalaries: [], newSalaries: newSalariesObj, reloading: false });

  }

  async deleteSalary(data) {

    this.setState({reloading: true});

    await APIDELETE.Request(`DELETE FROM Uurloon`, `WHERE uurloon_id = ${data.uurloon_id}`);

    const salaries = await APIGET.Request(`SELECT *`, `FROM Uurloon`, null, null, null, `ORDER BY uurloon_leeftijd`);
    const salariesObj = Constants.convertToObject(salaries, 'uurloon_leeftijd');
    let newSalariesObj = Constants.convertToObjectWithArrays(salaries, 'uurloon_ut_id');

    // add model array which are not added from db
    for (const sm of this.state.salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({salaries: salariesObj, salariesArray: salaries, newSalaries: newSalariesObj, reloading: false, showError: false, specialChanges: false });

  }

  checkValidationsSalary() {

    let newSalaries2 = [];
    let newEmptySalaries = [];

    // first check salaries
    for (const s of this.state.newSalaries[this.state.selectedSalaryModel]) {

      if (Constants.objectArrayContainsKey(newSalaries2, 'uurloon_leeftijd', s.uurloon_leeftijd) === false) {
        newSalaries2.push(s);
      } else {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${s.uurloon_leeftijd} jaar is al toegevoegd`
        });
        return false;
      }

    }

    // then check emptysalaries duplications
    for (let key in this.state.emptySalaries) {

      if (Constants.objectArrayContainsKey(newEmptySalaries, 'age', this.state.emptySalaries[key].age) === false) {
        newEmptySalaries.push(this.state.emptySalaries[key]);
      } else {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.emptySalaries[key].age} jaar kan niet 2x toegevoegd worden`
        });
        return false;
      }

    }

    // lastly check salaries and empty
    for (let key in this.state.emptySalaries) {

      if (Constants.objectArrayContainsKey(newSalaries2, 'uurloon_leeftijd', this.state.emptySalaries[key].age) === true) {
        this.setState({
          showError: true,
          errorText: `Uurloon voor ${this.state.emptySalaries[key].age} jaar bestaat al`
        });
        return false;
      }

    }

    return true;

  }

  getSalaryModelBars() {

    let models = [];

    for(const model of this.state.salaryModels) {

        models.push(
            <option value={model.ut_id} selected={model.ut_id === this.state.selectedSalaryModel}>{model.ut_naam}</option>
        );

    }

    return models;

  }

  selectSalaryModel(e) {

    this.setState({
      selectedSalaryModel: e.target.value === 'null' ? null : e.target.value,
      showError: false,
    });

  }

  async saveSalaryModel() {

    if (this.state.newSalaryModel === '') {
      this.setState({
        errorText: 'Je hebt geen naam ingevoerd',
        showError: true,
      });
      return;
    }

    this.setState({ loading: true });

    await APIADD.Request(`INSERT INTO UurloonTabel`, `VALUES (NULL, '${this.state.newSalaryModel}')`);

    const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);
    const selectedModel = salaryModels.length > 0 ? salaryModels[salaryModels.length - 1].ut_id : null;

    let newSalariesObj = this.state.newSalaries;

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({
      salaryModels: salaryModels,
      selectedSalaryModel: selectedModel,
      newSalaries: newSalariesObj,
      loading: false
    });

  }

  applyWorkWeekAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je deze werkdagen wilt toepassen op alle medewerkers? Hiermee worden de huidige werkdagen van medewerkers overschreven door de werkdagen die hier zijn aangegeven

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.setEveryoneToWorkDays.bind(this),
      showAlert: true,
    });

  }

  applySalariesAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je de toegevoegde uurlonen wilt toepassen op medewerkers met dit loonmodel? Hiermee worden de huidige uurlonen van medewerkers overschreven door de bedragen in het loonmodel

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.applySalary.bind(this),
      showAlert: true,
    });

  }

  resetSalariesAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je dit loonmodel wilt toepassen op alle medewerkers? Hiermee worden de huidige uurlonen van medewerkers overschreven door de bedragen in het loonmodel

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.resetSalary.bind(this),
      showAlert: true,
    });

  }

  deleteAlertSalary(data) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je het uurloon van {data.uurloon_leeftijd} jaar wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteSalary.bind(this, data),
      showAlert: true,
    });

  }

  deleteSalaryModelAlert() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je dit loonmodel wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteSalaryModel.bind(this),
      showAlert: true,
    });

  }

  async deleteSalaryModel() {

    this.setState({ loading: true });

    await APIDELETE.Request(`DELETE FROM UurloonTabel`, `WHERE ut_id = ${this.state.selectedSalaryModel}`);

    const salaryModels = await APIGET.Request(`SELECT *`, `FROM UurloonTabel`, null, null, null, null);
    const selectedModel = salaryModels.length > 0 ? salaryModels[0].ut_id : null;

    let newSalariesObj = this.state.newSalaries;

    // add model array which are not added from db
    for (const sm of salaryModels) {

      let found = false;

      for (let key in newSalariesObj) {
        for (const s of newSalariesObj[key]) {
          if (s.uurloon_ut_id === sm.ut_id) {
            found = true;
          }
        }
      }

      if (found === false) {
        newSalariesObj[sm.ut_id] = [];
      }

    }

    this.setState({
      salaryModels: salaryModels,
      selectedSalaryModel: selectedModel,
      newSalaries: newSalariesObj,
      loading: false
    });

  }

  getTeamButtonsMenu() {

    let toReturn = [];

    for (const team of Data.data.teams) {

      toReturn.push(

        <div key={team.team_id} className={`employees_menuButton ${this.state.chosenTeam === parseInt(team.team_id) ? 'activeAv' : ''}`} onClick={() => this.selectTeam(team)}>
          <div>{team.team_naam}</div>
        </div>

      );

    }

    return toReturn;

  }

  selectTeam(team) {

    this.setState({
      chosenTeam: parseInt(team.team_id),
      chosenTeamObj: team,
    });

  }

  async changeTeamData(e, key) {

    let chosenTeamObj = this.state.chosenTeamObj;

    chosenTeamObj[key] = e.target.value;

    if (this.state.chosenTeam === parseInt(Data.data.chosenTeamObj.team_id)) {
      Data.data.chosenTeamObj[key] = e.target.value;
    }

    for (let team of Data.data.teams) {
      if (parseInt(team.team_id) === this.state.chosenTeam) {
        team[key] = e.target.value;
      }
    }

    for (let team of Data.data.ownTeams) {
      if (parseInt(team.team_id) === this.state.chosenTeam) {
        team[key] = e.target.value;
      }
    }

    APIEDIT.Request(`UPDATE Team`, `SET ${key} = '${e.target.value}'`, `WHERE team_id = ${this.state.chosenTeam}`);

    this.setState({ chosenTeamObj: chosenTeamObj });

  }

  async changeCompanyData(e, key) {
    
    let chosenTeamObj = this.state.chosenTeamObj;
    chosenTeamObj[key] = e.target.value;

    if (key === 'bedrijf_naam') {
      Data.data.storeName = e.target.value;
    } else if (key === 'bedrijf_adres') {
      Data.data.storeAddress = e.target.value;
    } else if (key === 'bedrijf_postcode') {
      Data.data.storeZip = e.target.value;
    } else if (key === 'bedrijf_plaats') {
      Data.data.storeCity = e.target.value;
    } else if (key === 'bedrijf_telefoon') {
      Data.data.storePhone = e.target.value;
    } else if (key === 'bedrijf_email') {
      Data.data.storeMail = e.target.value;
    } else if (key === 'bedrijf_kvk') {
      Data.data.storeKvK = e.target.value;
    } else if (key === 'bedrijf_land') {
      Data.data.storeCountry = e.target.value;
    }

    APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET ${key} = '${e.target.value}'`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

    this.setState({ chosenTeamObj: chosenTeamObj });

  }

  selectDate(date, array, key, key2) {

    let arr = this.state[array];

    if (typeof arr[key] !== 'undefined') {
      arr[key][key2] = Constants.dateToString(date);
    }

    this.setState({ [array]: arr });

  }

  getHolidayPeriodBars() {

    let toReturn = [];

    for (const key in this.state.holidayPeriods) {

      const br = this.state.holidayPeriods[key];

      toReturn.push(

      <div key={br.vp_id} className='appoptions_break_bar' style={{ width: '36.5%' }}>

        <span>Begin:</span>

        <ReactDatePicker
          selected={Constants.stringToDate(br.vp_begin)}
          onChange={(date) => this.selectDate(date, 'holidayPeriods', key, 'vp_begin')}
          dateFormat="dd-MM-yyyy"
          className={'appoption_inputField_holiday'}
          placeholderText={'Selecteer een datum'}
          showWeekNumbers
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={nl}
        />

        <span>Eind:</span>

        <ReactDatePicker
          selected={Constants.stringToDate(br.vp_eind)}
          onChange={(date) => this.selectDate(date, 'holidayPeriods', key, 'vp_eind')}
          dateFormat="dd-MM-yyyy"
          className={'appoption_inputField_holiday'}
          placeholderText={'Selecteer een datum'}
          showWeekNumbers
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={nl}
        />

        <i style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '2%'}} className='fas fa-minus-circle' onClick={() => this.deleteHolidayPeriodRow(key, 'holidayPeriods')} />
                        
      </div>

      );

    }

    return toReturn;

  }

  getEmptyHolidayPeriodBars() {

    let toReturn = [];

    for (const key in this.state.emptyHolidayPeriods) {

      const br = this.state.emptyHolidayPeriods[key];

      toReturn.push(

      <div key={key + br.vp_id} className='appoptions_break_bar' style={{ width: '36.5%' }}>

        <span>Begin:</span>

        <ReactDatePicker
          selected={br.vp_begin === null ? null : Constants.stringToDate(br.vp_begin)}
          onChange={(date) => this.selectDate(date, 'emptyHolidayPeriods', key, 'vp_begin')}
          dateFormat="dd-MM-yyyy"
          className={'appoption_inputField_holiday'}
          placeholderText={'Datum'}
          showWeekNumbers
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={nl}
        />

        <span>Eind:</span>

        <ReactDatePicker
          selected={br.vp_eind === null ? null : Constants.stringToDate(br.vp_eind)}
          onChange={(date) => this.selectDate(date, 'emptyHolidayPeriods', key, 'vp_eind')}
          dateFormat="dd-MM-yyyy"
          className={'appoption_inputField_holiday'}
          placeholderText={'Datum'}
          showWeekNumbers
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={nl}
        />

        <i style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '2%'}} className='fas fa-minus-circle' onClick={() => this.deleteHolidayPeriodRow(key, 'emptyHolidayPeriods')} />
                        
      </div>

      );

    }

    return toReturn;

  }

  addHolidayPeriodRow() {

    let emptyHolidayPeriods = this.state.emptyHolidayPeriods;

    emptyHolidayPeriods.push({
      vp_id: null,
      vp_begin: null,
      vp_eind: null,
    });

    this.setState({ emptyHolidayPeriods: emptyHolidayPeriods });

  }

  async saveHolidayPeriods() {

    this.setState({ reloading: true });

    await APIDELETE.Request(`DELETE FROM VakantiePeriode`, `WHERE vp_id >= 0`);

    let promises = [];

    for (const br of this.state.holidayPeriods) {
      if (Constants.isEmpty(br.vp_begin) === false && Constants.isEmpty(br.vp_eind) === false) {
        promises.push(APIADD.Request(`INSERT INTO VakantiePeriode`, `VALUES (NULL, '${br.vp_begin}', '${br.vp_eind}')`));
      }
    }

    for (const br of this.state.emptyHolidayPeriods) {
      if (Constants.isEmpty(br.vp_begin) === false && Constants.isEmpty(br.vp_eind) === false) {
        promises.push(APIADD.Request(`INSERT INTO VakantiePeriode`, `VALUES (NULL, '${br.vp_begin}', '${br.vp_eind}')`));
      }
    }

    await Promise.all(promises);

    const holidayPeriods = await APIGET.Request(`SELECT *`, `FROM VakantiePeriode`, null, `WHERE vp_begin >= '${Constants.dateToString(new Date())}'`, null, `ORDER BY vp_begin`);

    this.setState({ reloading: false, holidayPeriods: holidayPeriods, emptyHolidayPeriods: [], });

  }

  deleteHolidayPeriodRow(key, array) {

    let holidayPeriod = this.state[array];

    holidayPeriod.splice(key, 1);

    this.setState({ [array]: holidayPeriod });

  }

  getBreakRulesBars() {

    let toReturn = [];

    for (const key in this.state.breakRules) {

      const br = this.state.breakRules[key];

      toReturn.push(

      <div key={br.pr_id} className='appoptions_break_bar'>

        <span>Als medewerker</span>

        <input 
          className={'appoption_inputField_break'}
          min={0}
          max={24}
          value={br.pr_uren}
          onChange={(event) => this.onChangeNumberDataFloat('breakHours', 0, 24, event, key, true)}
          type={'number'}
          step={0.1}
          placeholder='2,5'
        />

        <span>uur werkt, dan is de pauze</span>

        <input 
          className={'appoption_inputField_break'}
          min={0}
          max={1440}
          value={br.pr_pauze}
          onChange={(event) => this.onChangeNumberDataInt('breakMinutes', 0, 1440, event, key, true)}
          type={'number'}
          step={1}
          placeholder='15'
        />

        <span>minuten</span>

        <i style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '2%'}} className='fas fa-minus-circle' onClick={() => this.deleteBreakRuleRow(key, 'breakRules')} />
                        
      </div>

      );

    }

    return toReturn;

  }

  getEmptyBreakRulesBars() {

    let toReturn = [];

    for (const key in this.state.emptyBreakRules) {

      const br = this.state.emptyBreakRules[key];

      toReturn.push(

      <div key={key + br.pr_id} className='appoptions_break_bar'>

        <span>Als medewerker</span>

        <input 
          className={'appoption_inputField_break'}
          min={0}
          max={24}
          value={br.pr_uren}
          onChange={(event) => this.onChangeNumberDataFloat('breakHours', 0, 24, event, key, false)}
          type={'number'}
          step={0.1}
          placeholder='2,5'
        />

        <span>uur werkt, dan is de pauze</span>

        <input 
          className={'appoption_inputField_break'}
          min={0}
          max={1440}
          value={br.pr_pauze}
          onChange={(event) => this.onChangeNumberDataInt('breakMinutes', 0, 1440, event, key, false)}
          type={'number'}
          step={1}
          placeholder='15'
        />

        <span>minuten</span>

        <i style={{color: Colors.color.redFilledIn, cursor: 'pointer', marginLeft: '2%'}} className='fas fa-minus-circle' onClick={() => this.deleteBreakRuleRow(key, 'emptyBreakRules')} />
                        
      </div>

      );

    }

    return toReturn;

  }

  addBreakRuleRow() {

    let emptyBreakRules = this.state.emptyBreakRules;

    emptyBreakRules.push({
      pr_id: null,
      pr_uren: '',
      pr_pauze: '',
    });

    this.setState({ emptyBreakRules: emptyBreakRules });

  }

  async saveBreakRules() {

    this.setState({ reloading: true });

    await APIDELETE.Request(`DELETE FROM PauzeRegel`, `WHERE pr_id >= 0`);

    let promises = [];

    for (const br of this.state.breakRules) {
      if (isNaN(parseFloat(br.pr_uren)) === false && isNaN(parseInt(br.pr_pauze)) === false) {
        promises.push(APIADD.Request(`INSERT INTO PauzeRegel`, `VALUES (NULL, ${br.pr_uren}, ${br.pr_pauze})`));
      }
    }

    for (const br of this.state.emptyBreakRules) {
      if (isNaN(parseFloat(br.pr_uren)) === false && isNaN(parseInt(br.pr_pauze)) === false) {
        promises.push(APIADD.Request(`INSERT INTO PauzeRegel`, `VALUES (NULL, ${br.pr_uren}, ${br.pr_pauze})`));
      }
    }

    await Promise.all(promises);

    const breakRules = await APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`);

    this.setState({ reloading: false, breakRules: breakRules, emptyBreakRules: [], });

  }

  deleteBreakRuleRow(key, array) {

    let breakRules = this.state[array];

    breakRules.splice(key, 1);

    this.setState({ [array]: breakRules });

  }

  openPublicHolidayModal(mode, data) {

    this.setState({
      showPublicHolidayModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  openBonusModal(data) {

    this.setState({
      showBonusModal: true, 
      modalData: data,
    });

  }

  openPartModal(mode, data) {

    this.setState({
      showPartModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  openAbsModal(mode, data) {

    this.setState({
      showAbsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  openSkillsModal(mode, data) {

    this.setState({
      showSkillsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  async reloadBonus() {

    this.setState({reloading: true});

    const bonus = await APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, `ORDER BY t_factor DESC`);

    this.setState({
      reloading: false, 
      bonus: bonus
    });

  }

  async reloadPart() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetParticular.Request();

    this.setState({
      reloading: false, 
      particularities: bijzonderheden
    });

  }

  async reloadAbs() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetAbsencetypes.Request();

    this.setState({
      reloading: false, 
      absenceTypes: bijzonderheden
    });

  }

  async reloadSkills() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetSkills.Request();

    this.setState({
      reloading: false, 
      skills: bijzonderheden
    });

  }

  async reloadLeave() {

    this.setState({reloading: true});

    const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, `ORDER BY vb_factor DESC`);
    const leaveTypes = await APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, `ORDER BY vt_naam`);
    const leaveTypeBalances = await APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, null);
    const leaveTypeBalancesObj = Constants.convertToObjectWithArrays(leaveTypeBalances, 'vtb_vt_id');

    // re-check contracts
    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractLeaveBalance = await APIGET.Request(`SELECT *`, `FROM ContractVerlofBalans`, `LEFT JOIN ContractN ON cvb_contract_id = contract_id LEFT JOIN VerlofBalans ON cvb_vb_id = vb_id`, null, null, null);
    const contractLeaveBalanceObj = Constants.convertToObjectWithArrays(contractLeaveBalance, 'cvb_contract_id');

    let promises = [];
    for (const contract of contracts) {

      if (leaveBalances.length > 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

        if (typeof contractLeaveBalanceObj[contract.contract_id] === 'undefined') {

          for (const leave of leaveBalances) {
            promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
          }

        } else {

          for (const leave of leaveBalances) {
            
            if (Constants.objectArrayContainsKey(contractLeaveBalanceObj[contract.contract_id], 'cvb_vb_id', leave.vb_id) === false) {
              promises.push(APIADD.Request(`INSERT INTO ContractVerlofBalans`, `VALUES (NULL, ${contract.contract_id}, ${leave.vb_id}, ${leave.vb_factor})`));
            }

          }

        }

      }

    }

    await Promise.all(promises);

    let correctTypes = true;
    for (const lt of leaveTypes) {
      if (Constants.objectArrayContainsKey(leaveTypeBalances, 'vtb_vt_id', lt.vt_id) === false) {
        correctTypes = false;
        break;
      }
    }

    this.setState({
      reloading: false, 
      leaveBalances: leaveBalances,
      leaveTypes: leaveTypes,
      leaveTypeBalances: leaveTypeBalancesObj,
      correctTypes: correctTypes,
    });

  }

  openLeaveBalanceModal(mode, data) {

    this.setState({
      showLeaveBalanceModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : null,
    });

  }

  openLeaveTypeModal(mode, data) {

    this.setState({
      showLeaveTypeModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : null,
    });

  }

  getBonusBars() {

    let toRender = [];
    let count = 0;

    for (const bonus of this.state.bonus) {

      const date = Constants.isEmpty(bonus.t_datum) === false ? Constants.stringToDate(bonus.t_datum) : null;
      const bonusFactor = Constants.round(parseFloat(bonus.t_factor) * 100);
      const bonusFactor2 = Constants.round(parseFloat(bonus.t_factor_tvt) * 100);

      let bonusTitle = ``;
      if (date !== null) {
        bonusTitle = `${date.getDate()} ${Constants.getMonthName(date)}`;
      } else if (Constants.isEmpty(bonus.t_dag) === false) {
        if (bonus.t_dag === '1') {
          bonusTitle = `Maandag`;
        } else if (bonus.t_dag === '2') {
          bonusTitle = `Dinsdag`;
        } else if (bonus.t_dag === '3') {
          bonusTitle = `Woensdag`;
        } else if (bonus.t_dag === '4') {
          bonusTitle = `Donderdag`;
        } else if (bonus.t_dag === '5') {
          bonusTitle = `Vrijdag`;
        } else if (bonus.t_dag === '6') {
          bonusTitle = `Zaterdag`;
        } else if (bonus.t_dag === '0') {
          bonusTitle = `Zondag`;
        }
      } else if (Constants.isEmpty(bonus.t_elkedag) === false) {
        if (bonus.t_elkedag === '1') {
          bonusTitle = `Elke dag`;
        }
      }

      toRender.push(

        <div key={bonus.t_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '40%', cursor: 'pointer'}} onClick={() => this.openBonusModal(bonus)}>

          <div className='hr_hoursBar_sub1'>
            <span>{bonusTitle}</span>
          </div>

          <div className='hr_hoursBar_sub2'>
            <span>{bonus.t_begin.substr(0, 5)} - {bonus.t_eind.substr(0, 5)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{bonusFactor}%</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <span>{bonusFactor2}%</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getPartBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.particularities) {

      toRender.push(

        <div key={part.bijz_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '37%', cursor: 'pointer'}} onClick={() => this.openPartModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.bijz_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3' style={{color: `#${part.bijz_kleur}`}}>
            {part.bijz_afkorting}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getAbsBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.absenceTypes) {

      toRender.push(

        <div key={part.afw_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '30%', cursor: 'pointer'}} onClick={() => this.openAbsModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.afw_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {part.afw_afkorting}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getSkillsBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.skills) {

      toRender.push(

        <div key={part.comp_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '30%', cursor: 'pointer'}} onClick={() => this.openSkillsModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.comp_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {part.comp_afkorting}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  async changeExclusion(checked, id) {

    if (checked === true) { // delete
      await APIDELETE.Request(`DELETE FROM Uitzondering`, `WHERE uit_afw_id = ${id}`);
    } else {
      await APIADD.Request(`INSERT INTO Uitzondering`, `VALUES (NULL, ${id})`);
    }

    const exclusions = await APIGET.Request(`SELECT *`, `FROM Uitzondering`, null, null, null, null);
    this.setState({ exclusions: exclusions });

  }

  getExclusionBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.absenceTypes) {

      const checked = Constants.objectArrayContainsKey(this.state.exclusions, 'uit_afw_id', part.afw_id);

      toRender.push(

        <div key={part.afw_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '30%', cursor: 'pointer'}} onClick={() => this.changeExclusion(checked, part.afw_id)}>

          <div className='hr_hoursBar_sub1'>

            {checked === true ?
              <i className='fas fa-check-square' style={{fontSize: '0.8vw', marginRight: '0.6vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
            :
              <i className='fal fa-square' style={{fontSize: '0.8vw', marginRight: '0.6vw'}} />
            }

            <span>{part.afw_naam}</span>

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getLeaveBalancesBars() {

    let toRender = [];
    let count = 0;

    for (const leave of this.state.leaveBalances) {

      toRender.push(

        <div key={leave.vb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '37%', cursor: 'pointer'}} onClick={() => this.openLeaveBalanceModal(1, leave)}>

          <div className='hr_hoursBar_sub1'>
            <span>{leave.vb_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {Constants.digitsToLocale(parseFloat(leave.vb_factor), 9)}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getLeaveTypesBars() {

    let toRender = [];
    let count = 0;

    for (const leave of this.state.leaveTypes) {

      let leaveTypeBalances = null;
      if (typeof this.state.leaveTypeBalances[leave.vt_id] !== 'undefined') {
        leaveTypeBalances = this.state.leaveTypeBalances[leave.vt_id].length;
      }

      toRender.push(

        <div key={leave.vb_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '37%', cursor: 'pointer'}} onClick={() => this.openLeaveTypeModal(1, leave)}>

          <div className='hr_hoursBar_sub1'>
            <span>{leave.vt_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {leaveTypeBalances !== null ?
              <span>{leaveTypeBalances}</span>
            : <span><i className='fas fa-exclamation-triangle' style={{color: Colors.color.redFilledIn}} /></span>}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getYearOptions() {

    let toReturn = [];

    for (let i = this.state.minYear; i <= this.state.maxYear; i++) {

      toReturn.push(
        <option value={i.toString()} selected={this.state.publicHoliday_year === i}>{i.toString()}</option>
      );

    }

    return toReturn;

  }

  async reloadPublicHolidays() {

    this.setState({ reloading: true });

    const publicHolidays = await APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${new Date().getFullYear() - 1}`, null, `ORDER BY f_datum`);
    const minmaxYear = await APIGET.Request(`SELECT MIN(f_datum) AS min_jaar, MAX(f_datum) AS max_jaar`, `FROM Feestdag`, null, null, null, null);

    this.setState({ 
      publicHolidays: publicHolidays,
      minYear: minmaxYear.length > 0 && typeof minmaxYear[0].min_jaar !== 'undefined' && minmaxYear[0].min_jaar !== null && typeof minmaxYear[0].max_jaar !== 'undefined' && minmaxYear[0].max_jaar !== null ? parseInt(Constants.stringToDate(minmaxYear[0].min_jaar).getFullYear()) : this.state.publicHoliday_year,
      maxYear: minmaxYear.length > 0 && typeof minmaxYear[0].min_jaar !== 'undefined' && minmaxYear[0].min_jaar !== null && typeof minmaxYear[0].max_jaar !== 'undefined' && minmaxYear[0].max_jaar !== null ? parseInt(Constants.stringToDate(minmaxYear[0].max_jaar).getFullYear()) : this.state.publicHoliday_year,
      reloading: false 
    });

  }

  getPublicHolidayBars() {

    let toRender = [];
    let count = 0;

    for (const part of this.state.publicHolidays) {

      const date = Constants.stringToDate(part.f_datum);

      if (date.getFullYear() !== this.state.publicHoliday_year) {
        continue;
      }

      toRender.push(

        <div key={part.f_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '40%', cursor: 'pointer'}} onClick={() => this.openPublicHolidayModal(1, part)}>

          <div className='hr_hoursBar_sub1'>
            <span>{part.f_naam}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {date.toLocaleDateString()}
          </div>

          <div className='hr_hoursBar_sub3'>
            <i className='far fa-edit' />
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 10) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  onChangeTime(e, i2, fromOther, option) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }
    let times = this.state.timesStandard;

    if (option === 'availability') {
      times = this.state.timesStandard;
    } else if (option === 'default_workdays') {
      times = this.state.defaultWorkdays;
    }

    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times });
      } else if (option === 'default_workdays') {
        this.setState({ defaultWorkdays: times });
      }

      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
          APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
        } else {
          APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
        }

        this.setState({ defaultWorkdays: times });

      }

      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false && i2 !== 'maxEnd') {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          times[i2] = e.target.value;

          if (option === 'availability') {
            this.setState({ timesStandard: times, specialChanges: true });
          } else if (option === 'default_workdays') {

            const start = e.target.value.substr(0, 5);
            const end = e.target.value.substr(8, 13);

            // check if start and end are correct
            if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
              APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
            } else {
              APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
            }

            this.setState({ defaultWorkdays: times });

          }

          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times, specialChanges: true });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
          APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
        } else {
          APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
        }

        this.setState({ defaultWorkdays: times });

      }

    } else {
      e.target.value = '';
      times[i2] = e.target.value;

      if (option === 'availability') {
        this.setState({ timesStandard: times, specialChanges: true });
      } else if (option === 'default_workdays') {

        const start = e.target.value.substr(0, 5);
        const end = e.target.value.substr(8, 13);

        // check if start and end are correct
        if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {
          APIDELETE.Request(`DELETE FROM Openingstijden`, `WHERE ot_dag = ${i2}`);
        } else {
          APIADD.Request(`INSERT INTO Openingstijden`, `VALUES (NULL, ${i2}, '${start}', '${end}')`);
        }

        this.setState({ defaultWorkdays: times });

      }

    }

  }

  handleKey(e, i2) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      if (this.state.menu === 'availability') {
        this.saveStandardAvailability(i2);
      }

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  getAbsSelects() {

    let toRender = [];

    for (const part of this.state.absenceTypes) {

      toRender.push(
        <option value={part.afw_id} selected={this.state.abs_clockIn === part.afw_id}>{part.afw_naam}</option>
      );

    }

    return toRender;

  }

  async selectAbsOption(e) {

    let value = e.target.value;

    if (value === 'null') {
      value = null;
    }

    this.setState({ abs_clockIn: value }, async () => {

      await APIEDIT.Request(`UPDATE Afwezigheid`, `SET afw_inklok = NULL`, `WHERE afw_id >= 0`);

      if (value !== null) {
        await APIEDIT.Request(`UPDATE Afwezigheid`, `SET afw_inklok = 1`, `WHERE afw_id = ${value}`);
      }

    });

  }

  getTimezones() {

    let toRender = [];

    for (const key in this.state.timezoneList) {
      toRender.push(<option key={key} value={key} selected={this.state.chosenTimeZone === key}>{this.state.timezoneList[key]}</option>);
    }

    return toRender;

  }

  selectTZ(e) {

    // UPDATE DB
    APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_tijdzone = '${e.target.value}'`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

    // UPDATE DATA
    Data.data.storeTimezone = e.target.value;

    // UPDATE THIS STATE
    this.setState({ chosenTimeZone: e.target.value });

  }

  getCurrencyList() {

    let toRender = [];

    for (const key in this.state.currencies) {
      toRender.push(<option key={key} value={key} disabled={key === 'NONE'} selected={this.state.chosenCurrency === key}>{this.state.currencies[key]}</option>);
    }

    return toRender;

  }

  selectCurrency(e) {

    // UPDATE DB
    APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_valuta = '${e.target.value}'`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);

    // UPDATE DATA
    Data.data.storeCurrency = e.target.value;

    // UPDATE THIS STATE
    this.setState({ chosenCurrency: e.target.value });

  }

  getActiveEmployees() {

    let employees = 0;
    let employeesSchedule = 0;

    for (const acc of this.state.accounts) {
      const infoExtra = Constants.isEmpty(this.state.infoExtra[acc.info_id]) === false ? this.state.infoExtra[acc.info_id] : null;
      if (Data.data.accountData.info_id === acc.info_id || (infoExtra !== null && infoExtra.info2_status === '2')) {
        continue;
      }
      employees += 1;
    }

    for (const acc of this.state.plannedShifts_fallback) {
      if (Data.data.accountData.info_id === acc.rooster_info_id) {
        continue;
      }
      employeesSchedule += 1;
    }

    const total = (employeesSchedule > employees ? employeesSchedule : employees);

    return total < 0 ? 0 : total;

  }

  cancelSubscriptionAlert() {

    this.setState({
        alertTitle: 'Waarschuwing',
        alertBody: `Weet je zeker dat je dit abonnement wilt opzeggen? Als je niet in een proefperiode zit, zal jouw toegang tot dit account gelijk worden geblokkeerd`,
        alertButtons: 2,
        alertButtonText: ['Terug', 'Opzeggen'],
        alertButtonAction: this.cancelSubscription.bind(this, true),
        showAlert: true,
    });

  }

  async cancelSubscription() {

    this.setState({ loading: true });

    await APICancelSubscription.Request(this.state.hostedPage.hp_subscription_id);
    await APIDELETE.Request(`DELETE FROM HostedPage`, `WHERE hp_id IS NOT NULL`);

    const trialEnd = Constants.isEmpty(Data.data.trial) === false ? new Date(Data.data.trial) : null;
    const now = await Constants.getDateNow2();

    if (trialEnd < now) {

      Data.data.accountData = [];
      Data.data.accountDataExtra = [];
      Data.data.loggedIn = false;
      Data.data.serverIP = '';
      Data.data.storeCode = '';
      Data.data.storeName = '';
      Data.data.storePhone = '';
      Data.data.storeMail = '';
      Data.data.storeCountry = '';
      Data.data.storeAddress = '';
      Data.data.storeZip = '';
      Data.data.storeCity = '';
      Data.data.storeKvK = '';
      Data.data.pakketID = 0;
      Data.data.storeTimezone = '';
      Data.data.storeCurrency = 'EUR';
      Data.data.trial = null;
      Data.data.teams = [];
      Data.data.ownTeams = [];
      Data.data.chosenTeam = null;
      Data.data.chosenTeamObj = null;
      Data.data.storeLogo = null;

      localStorage.setItem('@storecode', '');
      localStorage.setItem('@username', '');
      localStorage.setItem('@password', '');

      this.props.history.push("/");
      return;

    }

    await this.componentDidMount();

    this.setState({ loading: false });

  }

  copyToken(company) {

    if (company === 'TAURUS') {

      navigator.clipboard.writeText(Data.data.companyData.bedrijf_taurusToken);

      toast.success('Token gekopieerd!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });

    }

  }

  newTokenAlert(company) {

    this.setState({
        alertTitle: 'Waarschuwing',
        alertBody: `Weet je zeker dat je een nieuwe token wilt aanvragen? Deze moet je dan aangeven bij het systeem waarmee je wilt koppelen of gekoppeld bent`,
        alertButtons: 2,
        alertButtonText: ['Terug', 'Aanvragen'],
        alertButtonAction: this.newToken.bind(this, company),
        showAlert: true,
    });

  }

  async newToken(company) {

    if (company === 'TAURUS') {
      const newToken = Constants.generateString(20);
      const newTokenCrypted = CryptoJS.SHA512(newToken).toString();
      await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_taurusToken = '${newTokenCrypted}'`, `WHERE bedrijf_code = '${Data.data.storeCode}'`);
      const companyData = await APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, null, `WHERE bedrijf_code = '${Data.data.storeCode}'`, null, null);
      Data.data.companyData = companyData[0];
    }

    this.forceUpdate();

  }

  render() {

    let trialEnd = Constants.isEmpty(Data.data.trial) === false ? new Date(Data.data.trial) : null;
    if (trialEnd !== null) {
      trialEnd = new Date(trialEnd.setDate(trialEnd.getDate() + 1));
    }

    const activeEmployees = this.getActiveEmployees();

    return (
        <div className='appOptions'>

          {this.state.reloading === true ?       
            <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
          : null}

          <Prompt
            when={this.state.specialChanges}
            message='You have unsaved changes. Are you sure you want to leave?'
          />

          <Navbar history={this.props.history} fixed active={'app_options'} loading={this.state.loading} reloading={this.state.reloading} />

          {this.state.missingModalVisible === true ?
            
            <MissingModal 
              _closeModal={this.closeMissingModal.bind(this)}
              _history={this.props.history}
              _parent={this}
            />

          :

            null
          
          }

          {this.state.showModal === true ?
            
            <LocationsModal
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadScreen.bind(this)}
            />

          : null}

          {this.state.showBonusModal === true ?
            
            <BonusModal
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _reloadScreen={this.reloadBonus.bind(this)}
            />

          : null}

          {this.state.showPartModal === true ?
                
            <ParticularModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadPart.bind(this)}
            />

          : null}

          {this.state.showAbsModal === true ?
                
            <AbsencetypesModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadAbs.bind(this)}
            />

          : null}

          {this.state.showSkillsModal === true ?
                
            <SkillsModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadSkills.bind(this)}
            />

          : null}

          {this.state.showLeaveBalanceModal === true ?
                
            <LeaveBalanceModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadLeave.bind(this)}
            />

          : null}

          {this.state.showLeaveTypeModal === true ?
                
            <LeaveTypeModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadLeave.bind(this)}
              _leaveTypeBalances={this.state.leaveTypeBalances}
              _leaveBalances={this.state.leaveBalances}
              />

          : null}

           {this.state.showPublicHolidayModal === true ?
                
            <PublicHolidayModal 
              _closeModal={this.closeModal.bind(this)}
              _modalData={this.state.modalData}
              _modalMode={this.state.modalMode}
              _reloadScreen={this.reloadPublicHolidays.bind(this)}
            />

          : null}

          {this.state.showAlert === true ?
            
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null
          
          }

          {/* <div className='planner_nav2_fixed fixedNav2'>

            <div className='planner_nav2_sub1' style={{cursor: 'default'}}>
            </div> 
            <div className='planner_nav2_sub2'>

            </div> 
            <div className='planner_nav2_sub3' style={{flex: 8}}>

              Platform configuratie
                
            </div> 
            <div className='planner_nav2_sub4' style={{justifyContent: 'center'}}>
        
            </div> 
            <div className='planner_nav2_sub5' style={{cursor: 'default'}}>
            </div> 

          </div> */}

          <div className='employees_main2'>

            <div className='employees_menu2'>

              <div className='employees_menuTitle'>
                <div>Configuratie</div>
              </div>

              {/* <div className='employees_menuSubTitle' style={{paddingTop: '0.5vw'}}>
                <div>Mijn bedrijf</div>
              </div> */}

              <div className={`employees_menuButton ${this.state.menu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('general')}>
                <div>Algemeen</div>
              </div>

              {/* <div className={`employees_menuButton ${this.state.menu === 'teams' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('teams')}>
                <div>Vestigingen</div>
              </div> */}

              <div className={`employees_menuButton ${this.state.menu === 'availability' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('availability')}>
                <div>Beschikbaarheid</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'planner' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('planner')}>
                <div>Planner</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'breaks' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('breaks')}>
                <div>Pauzes</div>
              </div>

              {this.state.clockSystem === true ?
                <div className={`employees_menuButton ${this.state.menu === 'clock' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('clock')}>
                  <div>Inkloksysteem</div>
                  {this.state.hasLocation === false && Constants.isTrue(Data.data.appOptions.opties_klokMobiel) ?
                    <span className='fas fa-exclamation-triangle appoptions_badge_new' style={{color: Colors.color.redFilledIn}} />
                  : null}
                </div>
              : null}

              <div className={`employees_menuButton ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('absence')}>
                <div>Verlof</div>
                {this.state.loading === false && (this.state.leaveBalances.length === 0 || (this.state.leaveBalances.length > 0 && (this.state.leaveTypes.length === 0 || this.state.correctTypes === false))) && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                  <span className='fas fa-exclamation-triangle appoptions_badge_new' style={{color: Colors.color.redFilledIn}} />
                : null}
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'trade' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('trade')}>
                <div>{'Ruilen & vervanging'}</div>
              </div>

              {Data.data.appOptions.opties_versie !== '0' ?
                <div className={`employees_menuButton ${this.state.menu === 'abs' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('abs')}>
                  <div>Afwezigheid</div>
                </div>
              : null}

              {/* {Data.data.appOptions.opties_versie !== '0' ?
                <div className={`employees_menuButton ${this.state.menu === 'part' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('part')}>
                  <div>Bijzonderheden</div>
                </div>
              : null} */}

              {Data.data.appOptions.opties_versie !== '0' ?
                <div className={`employees_menuButton ${this.state.menu === 'skills' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('skills')}>
                  <div>Vaardigheden</div>
                </div>
              : null}

              {Data.data.appOptions.opties_versie === '2' ?
                <div className={`employees_menuButton ${this.state.menu === 'bonus' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('bonus')}>
                  <div>Toeslagen</div>
                </div>
              : null}

              <div className='employees_menuSubTitle'>
                <div>Connecties</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'connections' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('connections')}>
                <div>Koppelingen</div>
              </div>

              {/* {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className={`employees_menuButton ${this.state.menu === 'finances' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('finances')}>
                  <div>Financiën</div>
                </div>
              : null} */}

              {/* {this.state.opties_urenregistratie2 === true || this.state.clockSystem === true ?
                <div className='employees_menuSubTitle'>
                  <div>Tijdregistratie</div>
                </div>
              : null}

              {this.state.opties_urenregistratie2 === true ?
                <div className={`employees_menuButton ${this.state.menu === 'hours' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('hours')}>
                  <div>Tijdregistratie</div>
                </div>
              : null}

              {this.state.clockSystem === true ?
                <div className={`employees_menuButton ${this.state.menu === 'clock' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('clock')}>
                  <div>Inkloksysteem</div>
                  {this.state.hasLocation === false && Constants.isTrue(Data.data.appOptions.opties_klokMobiel) ?
                    <span className='fas fa-exclamation-triangle appoptions_badge_new' style={{color: Colors.color.redFilledIn}} />
                  : null}
                </div>
              : null} */}

              {/* {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true ?
                <div>
                  <div className='employees_menuSubTitle'>
                    <div>Verlof</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('absence')}>
                    <div>Verlof</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.menu === 'leavebalances' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('leavebalances')}>
                    <div>Verlofsaldo's</div>
                    {this.state.loading === false && this.state.leaveBalances.length === 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                      <span className='fas fa-exclamation-triangle appoptions_badge_new' style={{color: Colors.color.redFilledIn}} />
                    : null}
                  </div>

                  <div className={`employees_menuButton ${this.state.menu === 'leavetypes' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('leavetypes')}>
                    <div>Verloftypes</div>
                    {this.state.loading === false && this.state.leaveBalances.length > 0 && (this.state.leaveTypes.length === 0 || this.state.correctTypes === false) && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                      <span className='fas fa-exclamation-triangle appoptions_badge_new' style={{color: Colors.color.redFilledIn}} />
                    : null}
                  </div>
                </div>
              : null} */}

              {/* <div className='employees_menuSubTitle'>
                <div>Personeel</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'trade' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('trade')}>
                <div>{'Ruilen & vervanging'}</div>
              </div>

              {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === false ?
                <div className={`employees_menuButton ${this.state.menu === 'absence' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('absence')}>
                  <div>Verlof</div>
                </div>
              : null}

              <div className={`employees_menuButton ${this.state.menu === 'register' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('register')}>
                <div>Registreren</div>
              </div> */}

              {/* {Data.data.appOptions.opties_versie !== '0' ?
                <div className='employees_menuSubTitle'>
                  <div>Dienst notities</div>
                </div>
              : null}

              {Data.data.appOptions.opties_versie !== '0' ?
                <div className={`employees_menuButton ${this.state.menu === 'part' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('part')}>
                  <div>Bijzonderheden</div>
                </div>
              : null}

              {Data.data.appOptions.opties_versie !== '0' ?
                <div className={`employees_menuButton ${this.state.menu === 'abs' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('abs')}>
                  <div>Afwezigheden</div>
                </div>
              : null}

              {Data.data.appOptions.opties_versie !== '0' ?
                <div className={`employees_menuButton ${this.state.menu === 'skills' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('skills')}>
                  <div>Competenties</div>
                </div>
              : null} */}

              {/* {Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) ? */}
                {/* <div>
                  <div className='employees_menuSubTitle'>
                    <div>Regels {`&`} uitsluitingen</div>
                  </div>

                  {this.state.breakOption === 'CALC' ?
                    <div className={`employees_menuButton ${this.state.menu === 'breaks' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('breaks')}>
                      <div>Pauzeregels</div>
                    </div>
                  : null}

                  <div className={`employees_menuButton ${this.state.menu === 'exclusions' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('exclusions')}>
                    <div>Uitsluitingen</div>
                  </div> */}

                  {/* <div className={`employees_menuButton ${this.state.menu === 'bonus' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('bonus')}>
                    <div>Toeslagen</div>
                  </div> */}

                {/* </div> */}

              {/* : null} */}

            </div>

            {this.state.menu === 'general' ?

              <div className='employees_menu2'>

                <div className='employees_menuTitle'>
                  <div>Menu</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('general')}>
                  <div>Algemeen</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'days' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('days')}>
                  <div>Openingstijden</div>
                </div>

                {Data.data.appOptions.opties_versie !== '0' ?
                  <div className={`employees_menuButton ${this.state.subMenu === 'salaries' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('salaries')}>
                    <div>Uurlonen</div>
                  </div>
                : null}

                <div className={`employees_menuButton ${this.state.subMenu === 'public_holidays' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('public_holidays')}>
                  <div>Feestdagen</div>
                </div>

              </div>

            : null}

            {this.state.menu === 'planner' ?

              <div className='employees_menu2'>

                <div className='employees_menuTitle'>
                  <div>Menu</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'planner' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('planner')}>
                  <div>Planner</div>
                </div>

                <div className={`employees_menuButton ${this.state.subMenu === 'labels' ? 'activeAv' : ''}`} onClick={() => this.selectSubMenu('labels')}>
                  <div>Labels</div>
                </div>

              </div>

            : null}

            {this.state.menu === 'teams' ?

              Data.data.teams.length > 1 ?

                <div className='employees_menu2'>

                  <div className='employees_menuTitle'>
                    <div>Teams</div>
                  </div>

                  {this.getTeamButtonsMenu()}

                </div>

              : 

                <div className='employees_menu2'>

                  <div className='employees_menuTitle'>
                    <div>Teams</div>
                  </div>

                  <div className={`employees_menuButton activeAv`}>
                    <div>{Data.data.storeName}</div>
                  </div>

                </div>

            : null}

            <div className='employees_content2' style={this.state.menu !== 'general' && this.state.menu !== 'planner' && this.state.menu !== 'teams' ? {width: 'calc(87% - 0px)'} : null}>

              {this.state.loading === true ?
                
                          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                <div className='employees_container'>

                  {this.state.menu === 'general' ?

                    this.state.subMenu === 'general' ?

                      <div>

                        <div className='appoptions_submenu_title'>Algemeen</div>

                        {/* <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Jouw pakket</div>

                        <div className='appoptions_submenu_rowContainer'>

                          {Data.data.pakketID === 99 ?
                            <span style={{fontsize: '0.8vw'}}>Maximaal</span>
                          :
                          Data.data.pakketID === 101 ?
                            <span style={{fontsize: '0.8vw'}}>{this.formatter.format('0.50')} per medewerker</span>
                          :
                          Data.data.pakketID === 0 ?
                            <span style={{fontsize: '0.8vw'}}>Proefversie</span>
                          :
                            <span style={{fontsize: '0.8vw'}}>{Data.data.maxEployees - 9} - {Data.data.maxEployees} medewerkers</span>
                          }

                          <span 
                            className='defaultLinkO' 
                            onClick={() => window.Chargebee.registerAgain()} 
                            id="subscribe" 
                            data-cb-type="checkout" 
                            data-cb-item-0="Medewerkers-EUR-Monthly"  
                            data-cb-item-0-quantity="50"
                            data-cb-item-0-type="plan"
                            // data-cb-item-1="Clock-in-System-EUR-Monthly"
                            // data-cb-item-1-quantity="1"
                            // data-cb-item-1-type="addon"
                          >Upgrade</span>

                        </div> */}

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Facturatie</div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Versie</span>

                          {/* <select 
                              name="Selecteer een optie" 
                              className={'dropDownField'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                              onChange={(event) => this.setState({ chosenVersion: event.target.value })}
                          >
                            <option value={'1'} selected={Data.data.appOptions.opties_versie === '1'}>Personeelsplanning</option>
                            <option value={'2'} selected={Data.data.appOptions.opties_versie === '2'}>Personeelsplanning + inkloksysteem</option>
                          </select> */}

                          {Data.data.appOptions.opties_versie === '0' ?
                            <span><b>Starter</b></span>
                          : Data.data.appOptions.opties_versie === '1' ?
                            <span><b>Personeelsplanning</b></span>
                          : 
                            <span><b>Personeelsplanning + inkloksysteem</b></span>
                          }

                        </div>

                        <div className='appoptions_submenu_rowContainer_noHover'>

                          <span style={{fontsize: '0.8vw'}}>Beheer abonnement</span>

                          {Data.data.pakketID !== 101 && Data.data.pakketID !== '101' ?
                            <span><i>Binnenkort beschikbaar</i></span>
                          :
                            <span><b>{activeEmployees}</b> actieve medewerkers</span>
                          }

                        </div>

                        {Data.data.pakketID === 101 || Data.data.pakketID === '101' ?
                          <div>
                            {this.state.hasSubscription === false || (this.state.hasSubscription === true && Constants.isEmpty(Data.data.trial) === false) ?

                              <div className='appoptions_submenu_rowContainer_noHover'>

                                <span style={{fontsize: '0.8vw'}}>&nbsp;</span>

                                <span>Betaling zal ingaan per: <b>{Constants.isEmpty(trialEnd) || trialEnd.getTime() <= new Date() ? 'direct' : trialEnd.toLocaleDateString()}</b></span>

                              </div>

                            : null}

                            {this.state.hasSubscription === false ?

                              <div className='appoptions_submenu_rowContainer_noHover'>

                                <span style={{fontsize: '0.8vw'}}>&nbsp;</span>

                                <div 
                                  className='planner_new_availableButton2'
                                  onClick={() => window.Chargebee.registerAgain()} 
                                  id="subscribe" 
                                  data-cb-type="checkout" 
                                  data-cb-item-0={Data.data.appOptions.opties_versie === '2' ? Constants.isEmpty(Data.data.trial) === false ? "TIMETRACKING_TRIAL-EUR-MONTHLY" : "TIMETRACKING-EUR-MONTHLY" : Constants.isEmpty(Data.data.trial) === false ? "SCHEDULING_TRIAL-EUR-MONTHLY" : "SCHEDULING-EUR-MONTHLY"} // checkoutVersion === '1' ? 
                                  data-cb-item-0-quantity={activeEmployees}
                                  data-cb-item-0-type="plan"
                                  // data-cb-item-1="Clock-in-System-EUR-Monthly"
                                  // data-cb-item-1-quantity="1"
                                  // data-cb-item-1-type="addon"
                                >
                                  Betaling instellen
                                </div>

                              </div>

                            : 
                            
                              <div className='appoptions_submenu_rowContainer_noHover'>

                                <span style={{fontsize: '0.8vw'}}>&nbsp;</span>

                                <span className='defaultLinkO' onClick={() => this.cancelSubscriptionAlert()}>Abonnement opzeggen</span>

                              </div>
                            
                            }
                          </div>
                        : null}

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Algemeen</div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Smart Plan toestaan</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_smartplan} 
                                  onClick={() => this.editNewOptions('opties_smartplan', !this.state.opties_smartplan)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>De Smart Plan optie tonen in de planner. Hiermee worden jouw medewerkers snel ingepland op basis van de door hun aangegeven beschikbaarheid.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {/* {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Tijdregistratie weergeven</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_urenregistratie2} 
                                    onClick={() => this.editNewOptions('opties_urenregistratie2', !this.state.opties_urenregistratie2)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Uren bijhouden volgens planner of inkloksysteem.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie === '2' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Financiën weergeven</span>

                            <label className={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false ? 'switch_disabled' : "switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_financien} 
                                    onClick={() => this.editNewOptions('opties_financien', !this.state.opties_financien)}
                                    readOnly
                                    disabled={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false}
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Uitgebreid financiën bijhouden van medewerkers. De financiën rapportage is terug te zien onder het tabje 'Overzichten'.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null} */}

                        {/* {Data.data.appOptions.opties_versie === '2' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Inkloksysteem</span>

                            <label className={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false ? 'switch_disabled' : "switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.clockSystem} 
                                    onClick={() => this.toggleSwitchClock()}
                                    readOnly
                                    disabled={Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) === false}
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Gebruik maken van het inkloksysteem.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null} */}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Chatten toestaan</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_chat} 
                                    onClick={() => this.editNewOptions('opties_chat', !this.state.opties_chat)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>De chat-functie toestaan. Wanneer dit is toegestaan, kan er onderling gechat worden via de Tiemdo app.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1.5vw'}}>Algemeen medewerkers</div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Feestdagen weergeven</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_feestdagen} 
                                  onClick={() => this.editNewOptions('opties_feestdagen', !this.state.opties_feestdagen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Feestdagen weergeven voor medewerkers in de Tiemdo app.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Eindtijd weergeven</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_eindtijd_weergeven} 
                                  onClick={() => this.editNewOptions('opties_eindtijd_weergeven', !this.state.opties_eindtijd_weergeven)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Eindtijd van diensten weergeven voor medewerkers in de Tiemdo app.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Medewerkers mogen eigen uren inzien</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_urenEigen} 
                                  onClick={() => this.editNewOptions('opties_urenEigen', !this.state.opties_urenEigen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Medewerkers toestaan om eigen gewerkte uren in te zien.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1.5vw'}}>Algemeen overig</div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Tijdzone</span>

                            <select 
                                name="Selecteer een tijdzone" 
                                className={'dropDownField'}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                                onChange={(event) => this.selectTZ(event)}
                            >
                                {this.getTimezones()}
                            </select>

                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Valuta</span>

                            <select 
                                name="Selecteer een valuta" 
                                className={'dropDownField'}
                                style={{width: '60%', fontsize: '0.8vw'}} 
                                onChange={(event) => this.selectCurrency(event)}
                            >
                                {this.getCurrencyList()}
                            </select>

                          </div>
                        : null}

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1.5vw'}}>Factoren</div>

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Wekelijkse FTE-uren</span>

                            <input 
                              className={'inputField2Shadow'}
                              min={0}
                              max={999999999}
                              value={this.state.opties_fteUren}
                              onChange={(event) => this.onChangeNumberData('fte', 0, 999999999, event)}
                              type={'number'}
                              step={0.01}
                              style={{width: '15%'}}
                              />

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Geef hier het Fulltime-Equivalent aan. Dit zijn de uren die een full-time medewerker moet werken per week. (0 = geen)</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Arbeidsduur werkdag</span>

                            <input 
                              className={'inputField2Shadow'}
                              onChange={(event) => this.onChangeNumberData('dailyWorkHours', 0, 24, event)}
                              // onKeyDown={(event) => this.handleKey(event, 'maxDays')}
                              value={this.state.dailyWorkHours}
                              placeholder={'8'}
                              maxLength={5}
                              type={'number'}
                              style={{width: '15%'}}

                            />

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Geef hier het fulltime aantal contracturen per dag aan.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>BTW (%)</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            max={999999999}
                            value={this.state.opties_BTW}
                            onChange={(event) => this.onChangeNumberData('btw', 0, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Geef hier het BTW percentage aan.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Omzet inclusief BTW</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_omzetBTW} 
                                  onClick={() => this.editNewOptions('opties_omzetBTW', !this.state.opties_omzetBTW)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Is jouw aangegeven omzet inclusief of exclusief BTW?</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Loonfactor</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={1}
                            max={999999999}
                            value={this.state.opties_loonfactor}
                            onChange={(event) => this.onChangeNumberData('salaryFactor', 1, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>De loonkosten worden met deze factor vermenigvuldigd. Deze vermenigvuldiging kan worden gebruikt om diverse extra kosten mee te kunnen rekenen (zoals kosten die naast de directe loonkosten gemaakt worden).</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Loonpercentage van (verwachte) omzet</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            max={999999999}
                            value={this.state.opties_loonPercentage}
                            onChange={(event) => this.onChangeNumberData('salaryPercent', 0, 999999999, event)}
                            type={'number'}
                            step={0.01}
                            style={{width: '15%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Percentage loonkosten in verhouding tot omzet. Wanneer de verwachte loonkosten boven dit percentage komt, wordt dit rood aangegeven in de planner. (0 = uitgeschakeld)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                    :
                    this.state.subMenu === 'days' ?
                      
                      <div>

                        <div className='appoptions_submenu_title'>Openingstijden</div>

                        <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw', marginLeft: '1%'}}>

                          Openingstijden

                          {/* <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>De geselecteerde werkdagen zullen weergegeven worden in de planner en meegenomen worden in de berekening van verlofuren.</span><br /><br />
                                <span>Werkdagen kunnen ook per medewerker ingesteld worden bij {`Medewerkers -> Naam -> Werkdagen`}.</span>
                              </div>
                            )}
                            style={{marginLeft: '2%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip> */}
                        </div>

                        <div>

                          <div className='employees_content_container' style={{justifyContent: 'center', flexDirection: 'column', marginTop: '1vw', marginLeft: '1vw'}}>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekMonday === false ? 0.5 : 1}}>Maandag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw1']=ref}
                                  className={this.state.workWeekMonday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 1, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 1, 'default_workdays')}
                                  value={this.state.defaultWorkdays[1]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekMonday === false}
                                />

                              </div>

                            </div>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekTuesday === false ? 0.5 : 1}}>Dinsdag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw2']=ref}
                                  className={this.state.workWeekTuesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 2, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 2, 'default_workdays')}
                                  value={this.state.defaultWorkdays[2]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekTuesday === false}
                                />

                              </div>

                            </div>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekWednesday === false ? 0.5 : 1}}>Woensdag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw3']=ref}
                                  className={this.state.workWeekWednesday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 3, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 3, 'default_workdays')}
                                  value={this.state.defaultWorkdays[3]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekWednesday === false}
                                />

                              </div>

                            </div>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekThursday === false ? 0.5 : 1}}>Donderdag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw4']=ref}
                                  className={this.state.workWeekThursday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 4, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 4, 'default_workdays')}
                                  value={this.state.defaultWorkdays[4]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekThursday === false}
                                />

                              </div>

                            </div>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekFriday === false ? 0.5 : 1}}>Vrijdag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw5']=ref}
                                  className={this.state.workWeekFriday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 5, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 5, 'default_workdays')}
                                  value={this.state.defaultWorkdays[5]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekFriday === false}
                                />

                              </div>

                            </div>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekSaturday === false ? 0.5 : 1}}>Zaterdag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw6']=ref}
                                  className={this.state.workWeekSaturday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 6, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 6, 'default_workdays')}
                                  value={this.state.defaultWorkdays[6]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekSaturday === false}
                                />

                              </div>

                            </div>

                            <div className='smartPlanModal-settingRow' style={{marginTop: '1vw', width: '44%'}}>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 2}}>

                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', opacity: this.state.workWeekSunday === false ? 0.5 : 1}}>Zondag</span>

                              </div>

                              <div className='smartPlanModal-settingRowSub2' style={{flex: 1}}>

                                <input
                                  ref={(ref) => this.timeRef['dw0']=ref}
                                  className={this.state.workWeekSunday === true ? 'employees_inputBoxTime' : 'employees_inputBoxTimeDisabled'}
                                  onChange={(event) => this.onChangeTime(event, 0, true, 'default_workdays')}
                                  onKeyDown={(event) => this.handleKey(event, 0, 'default_workdays')}
                                  value={this.state.defaultWorkdays[0]}
                                  placeholder={'Begin- en eindtijd'}
                                  style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                                  disabled={this.state.workWeekSunday === false}
                                />

                              </div>

                            </div>

                          </div>

                        </div>

                      </div>

                    :
                    this.state.subMenu === 'public_holidays' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Feestdagen`}</div>

                        <div className="employees_addButtons_container" style={{marginTop: '2vw', marginLeft: '0.5%'}}>

                          <div style={{width: '10%'}}>
                            <select
                              name="Selecteer een functie"
                              className={'hr_dropdown'}
                              onChange={(event) => this.setState({ publicHoliday_year: parseInt(event.target.value) })}
                            >
                              <option value={'null'} disabled>Jaar</option>
                              {this.getYearOptions()}
                            </select>
                          </div>

                        </div>

                        <div className="employees_addButtons_container" style={{marginTop: '1vw', marginLeft: '0.5%'}}>

                          <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openPublicHolidayModal(0, null)}>Toevoegen</div>

                        </div>

                        {this.getPublicHolidayBars()}

                      </div>

                    :
                    this.state.subMenu === 'salaries' ?

                      <div style={{width: '45%'}}>

                        <div className='appoptions_submenu_title'>Uurlonen</div>

                        {this.state.showError === true ?
                          <div style={{marginLeft: '1%', fontSize: '0.8vw', color: Colors.color.redFilledIn, marginBottom: '2%'}}>{this.state.errorText}</div>
                        : null}

                        {this.state.selectedSalaryModel !== null ?
                          <div className='salaries_buttonContainer'>

                            <span className='planner_new_deleteButton2' style={{marginLeft: 0}} onClick={() => this.deleteSalaryModelAlert()}>Verwijderen</span>

                            <span className='planner_new_publishButton2' onClick={() => this.saveSalaries()}>Opslaan</span>

                          </div>
                        : null}

                        <div className='appoptions_salary_selectionContainer'>

                          <span style={{fontsize: '0.8vw'}}>Loonmodel</span>

                          <select 
                              name="Selecteer een loonmodel" 
                              className={'dropDownField'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                              onChange={(event) => this.selectSalaryModel(event)}
                          >
                              <option value={'null'} selected={this.state.selectedSalaryModel === null}>- Nieuw loonmodel toevoegen -</option>
                              {this.getSalaryModelBars()}
                          </select>

                        </div>

                        {this.state.selectedSalaryModel === null ?
                          <div className='salaries_buttonContainer' style={{marginTop: '2vw', marginBottom: 0}}>

                            <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.saveSalaryModel()}>Toevoegen</span>

                          </div>
                        : null}

                        {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length > 0 ?

                          <div className='salaries_buttonContainer_apply' style={{marginTop: '4%'}}>

                            <span style={{marginLeft: '1%', fontsize: '0.8vw'}}>Alle medewerkers resetten naar dit loonmodel</span>

                            <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.resetSalariesAlert()}>Resetten</span>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Dit loonmodel zal toegepast worden op alle medewerkers</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-6%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                        : null}

                        {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length > 0 ?

                          <div className='salaries_buttonContainer_apply' style={{marginTop: '4%', marginBottom: '2vw'}}>

                            <span style={{marginLeft: '1%', fontsize: '0.8vw'}}>De uurlonen toepassen op medewerkers met dit loonmodel</span>

                            <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.applySalariesAlert()}>Toepassen</span>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>De onderstaande uurlonen zullen worden toegepast op alle medewerkers die dit loonmodel gebruiken</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-6%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>

                        : null}

                        {this.state.selectedSalaryModel !== null ?
                          this.state.newSalaries[this.state.selectedSalaryModel].length > 0 ?
                            <span style={{marginLeft: '1%', fontSize: '1vw', fontWeight: 'bold'}}>Toegevoegde uurlonen</span>
                          : 
                            <div style={{marginLeft: '1%', fontsize: '0.8vw', marginTop: '2vw'}}>Je hebt nog geen uurlonen toegevoegd</div>
                        : null}

                        {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length > 1 ?
                          <div style={{marginTop: '0.5vw', marginLeft: '1%'}}>
                            <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.newSalaries[this.state.selectedSalaryModel][0].uurloon_leeftijd}</b> jaar of jonger krijgen een uurloon van {this.formatter.format(this.state.newSalaries[this.state.selectedSalaryModel][0].uurloon_hoeveelheid)}</div>
                            <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd}</b> jaar of ouder krijgen een uurloon van {this.formatter.format(this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid)}</div>
                          </div>
                        : null}

                        {this.state.selectedSalaryModel !== null && this.state.newSalaries[this.state.selectedSalaryModel].length === 1 ?
                          <div style={{marginTop: '0.5vw', marginLeft: '1%'}}>
                            <div style={{fontSize: '0.8vw'}}>Medewerkers van <b>{this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_leeftijd}</b> jaar en jonger of ouder krijgen een uurloon van {this.formatter.format(this.state.newSalaries[this.state.selectedSalaryModel][this.state.newSalaries[this.state.selectedSalaryModel].length - 1].uurloon_hoeveelheid)}</div>
                          </div>
                        : null}

                        {this.state.selectedSalaryModel !== null ?

                          this.renderSalaries()

                        : 

                           <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                            <span style={{fontsize: '0.8vw'}}>Naam</span>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.newSalaryModel}
                                onChange={(event) => this.setState({ newSalaryModel: event.target.value, showError: false })}
                                placeholder={'Naam loonmodel'}
                                style={{width: 'calc(60% - 34px)', fontsize: '0.8vw'}} 
                            />

                          </div>
                          
                        }

                        {this.state.selectedSalaryModel !== null ?
                          <div style={{marginLeft: '1%'}}>

                            <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '2vw'}}>Nieuw uurloon toevoegen</div>

                            <div className='salaries_buttonContainer_add'>

                              <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.addRow()}>Toevoegen</span>

                            </div>

                            {this.renderEmptySalaries()}

                          </div>
                        : null}

                      </div>

                    :

                      null

                  :
                  this.state.menu === 'teams' ?

                    Data.data.teams.length > 1 ?

                      <div style={{width: '45%'}}>
                        
                        <div className='appoptions_submenu_title'>{this.state.chosenTeamObj.team_naam}</div>

                        <div className='appoptions_salary_selectionContainer'>

                          <span style={{fontsize: '0.8vw'}}>Naam</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_naam) === false ? this.state.chosenTeamObj.team_naam : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_naam')}
                              placeholder={'Naam vestiging :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Adres</span>

                          <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_adres) === false ? this.state.chosenTeamObj.team_adres : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_adres')}
                              placeholder={'Adres :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Postcode</span>

                          <input 
                              className={'inputField2'}
                              maxLength={20}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_postcode) === false ? this.state.chosenTeamObj.team_postcode : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_postcode')}
                              placeholder={'Postcode :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Plaats</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_plaats) === false ? this.state.chosenTeamObj.team_plaats : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_plaats')}
                              placeholder={'Plaats :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Land</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_land) === false ? this.state.chosenTeamObj.team_land : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_land')}
                              placeholder={'Land :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Telefoon</span>

                          <input 
                              className={'inputField2'}
                              maxLength={20}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_telefoon) === false ? this.state.chosenTeamObj.team_telefoon : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_telefoon')}
                              placeholder={'Telefoonnummer :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>KvK</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(this.state.chosenTeamObj.team_kvk) === false ? this.state.chosenTeamObj.team_kvk : ''}
                              onChange={(event) => this.changeTeamData(event, 'team_kvk')}
                              placeholder={'KvK :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                      </div>

                    :

                      <div style={{width: '45%'}}>
                          
                        <div className='appoptions_submenu_title'>{Data.data.storeName}</div>

                        <div className='appoptions_salary_selectionContainer'>

                          <span style={{fontsize: '0.8vw'}}>Naam</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(Data.data.storeName) === false ? Data.data.storeName : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_naam')}
                              placeholder={'Naam vestiging :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Adres</span>

                          <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={Constants.isEmpty(Data.data.storeAddress) === false ? Data.data.storeAddress : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_adres')}
                              placeholder={'Adres :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Postcode</span>

                          <input 
                              className={'inputField2'}
                              maxLength={20}
                              value={Constants.isEmpty(Data.data.storeZip) === false ? Data.data.storeZip : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_postcode')}
                              placeholder={'Postcode :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Plaats</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(Data.data.storeCity) === false ? Data.data.storeCity : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_plaats')}
                              placeholder={'Plaats :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Land</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(Data.data.storeCountry) === false ? Data.data.storeCountry : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_land')}
                              placeholder={'Land :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>Telefoon</span>

                          <input 
                              className={'inputField2'}
                              maxLength={20}
                              value={Constants.isEmpty(Data.data.storePhone) === false ? Data.data.storePhone : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_telefoon')}
                              placeholder={'Telefoonnummer :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>E-mail</span>

                          <input 
                              className={'inputField2'}
                              maxLength={20}
                              value={Constants.isEmpty(Data.data.storeMail) === false ? Data.data.storeMail : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_email')}
                              placeholder={'E-mail :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                        <div className='appoptions_salary_selectionContainer' style={{marginTop: '2%'}}>

                          <span style={{fontsize: '0.8vw'}}>KvK</span>

                          <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(Data.data.storeKvK) === false ? Data.data.storeKvK : ''}
                              onChange={(event) => this.changeCompanyData(event, 'bedrijf_kvk')}
                              placeholder={'KvK :'}
                              style={{width: '60%', fontsize: '0.8vw'}} 
                          />

                        </div>

                      </div>

                  :
                  this.state.menu === 'availability' ?

                    <div>

                      <div className='appoptions_submenu_title'>Beschikbaarheid</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Dagelijkse beschikbaarheid</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                          <input 
                              type="checkbox" 
                              checked={this.state.availability === 'AVAILABILITY'} 
                              onClick={() => this.toggleSwitchAvailability()}
                              readOnly
                          />
                          <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers toestaan om zelf de keuze te laten maken wanneer zij beschikbaar of onbeschikbaar zijn per dag.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Standaard beschikbaarheid</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.availability === 'STANDARD'} 
                                onClick={() => this.toggleSwitchStandard()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Standaard beschikbaarheid voor medewerkers van maandag t/m zondag.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Schoolrooster</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.availability === 'SCHOOL'} 
                                onClick={() => this.toggleSwitchSchool()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers toestaan om hun schoolrooster en onbeschikbare dagen aan te geven.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Alleen onbeschikbaarheid</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.availability === 'UNAVAILABILITY'} 
                                onClick={() => this.toggleSwitchUnavailability()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers toestaan om alleen onbeschikbare dagen aan te geven.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Uitgeschakeld</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.availability === 'NONE'} 
                                onClick={() => this.toggleSwitchAvailabilityOff()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers hoeven geen beschikbaarheid aan te geven.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Extra instellingen</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Limiet onbeschikbare medewerkers per dag</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          value={this.state.opties_onbpd}
                          onChange={(event) => this.onChangeNumberData('day', 0, 999999999, event)}
                          type={'number'}
                          step={1}
                          style={{width: '10%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Wanneer op één dag deze limiet is bereikt, kan deze dag niet meer als onbeschikbaar worden aangegeven.</span><br/>
                              <span>Bijvoorbeeld 2 = op één dag maximaal 2 medewerkers onbeschikbaar (0 = geen limiet)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      {/* ONBPM EN ONBPW zijn omgedraaidd!!!!!!!!! */}

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Limiet onbeschikbaarheid per persoon per week</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          value={this.state.opties_onbpm}
                          onChange={(event) => this.onChangeNumberData('month', 0, 7, event)}
                          type={'number'}
                          step={1}
                          style={{width: '10%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Limiet voor het aangeven van aantal onbeschikbare dagen per persoon per week.</span><br/>
                              <span>Bijvoorbeeld 2 = medewerker mag maar 2 onbeschikbare dagen per week aangeven (0 = geen wekelijks limiet)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Limiet onbeschikbaarheid per persoon per maand</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          value={this.state.opties_onbpw}
                          onChange={(event) => this.onChangeNumberData('week', 0, 999999999, event)}
                          type={'number'}
                          step={1}
                          style={{width: '10%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Limiet voor het aangeven van aantal onbeschikbare dagen per persoon per maand.</span><br/>
                              <span>Bijvoorbeeld 2 = medewerker mag maar 2 onbeschikbare dagen per maand aangeven (0 = geen maandelijks limiet)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                    </div>

                  :
                  this.state.menu === 'clock' ?

                    <div>

                      <div className='appoptions_submenu_title'>Inkloksysteem</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Inklokken via mobiel toestaan</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_klokMobiel} 
                                  onClick={() => this.editNewOptions('opties_klokMobiel', !this.state.opties_klokMobiel)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Toestaan om medewerkers in te laten klokken in de Tiemdo app.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {this.state.opties_klokMobiel === true ?

                          this.state.hasLocation === true ?

                            <div className='appoptions_submenu_rowContainer2'>

                              <span style={{fontsize: '0.8vw'}}>Locatie(s) ingesteld</span>

                              <div className='planner_new_availableButton2' style={{marginLeft: 0}} onClick={this.openLocationsModal.bind(this)}>
                                Mijn locaties
                              </div>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Wanneer er locaties zijn ingesteld, kunnen medewerkers op deze locaties inklokken via hun mobiel.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>

                          :

                            <div className='appoptions_submenu_rowContainer2'>

                              <span style={{fontsize: '0.8vw', color: Colors.color.redFilledIn, fontWeight: 'bold'}}>Geen locatie ingesteld</span>

                              <div className='planner_new_availableButton2' onClick={this.openLocationsModal.bind(this)}>
                                Instellen
                              </div>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Wanneer er locaties zijn ingesteld, kunnen medewerkers op deze locaties inklokken via hun mobiel.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>

                        : null}

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Alleen ingeplande medewerkers</span>

                          <label className={this.state.opties_klokMobiel === false ? 'switch_disabled' : "switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_klokPlanning} 
                                  onClick={() => this.editNewOptions('opties_klokPlanning', !this.state.opties_klokPlanning)}
                                  readOnly
                                  disabled={this.state.opties_klokMobiel === false}
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Alleen ingeplande medewerkers mogen inklokken via hun mobiel. Vanaf het inklokscherm is het altijd mogelijk om in te klokken.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Afronden</div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>In- en uitklokken afronden</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_klokAfronden}
                            onChange={(event) => this.onChangeNumberData('roundClock', 0, 60, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Inklok- en uitkloktijden afronden op x minuten. Bijv. afronden op 5 minuten maakt 12:28 = 12:30. (0 = niet afronden)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Pauzes afronden</span>

                          <input 
                            className={'inputField2Shadow'}
                            min={0}
                            value={this.state.opties_pauzeAfronden}
                            onChange={(event) => this.onChangeNumberData('roundBreaks', 0, 60, event)}
                            type={'number'}
                            step={1}
                            style={{width: '10%'}}
                          />

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Pauzes afronden op x minuten. Bijv. afronden op 5 minuten maakt 12:28 = 12:30. (0 = niet afronden)</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Automatische afwezigheid</div>

                        <span className='defaultLinkO' style={{marginLeft: '0.5%'}} onClick={() => this.selectMenu('abs')}>Beheer afwezigheidstypes</span>

                        <div className='appoptions_submenu_rowContainer2'>

                          {/* <div style={{display: 'flex', flexDirection: 'column'}}> */}
                            <span style={{fontsize: '0.8vw'}}>Te laat inklokken koppelen aan</span>
                            {/* <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.selectMenu('absence')}>
                              Beheer afwezigheidstypes
                            </div>
                          </div> */}

                          <div style={{width: '30%'}}>
                            <select
                              name="Selecteer een afwezigheid"
                              className={'dropDownField'}
                              onChange={(event) => this.selectAbsOption(event)}
                            >
                              <option value={'null'} disabled>Afwezigheidstypes</option>
                              <option value={'null'} selected={this.state.abs_clockIn === null}>Geen afwezigheid koppelen</option>
                              {this.getAbsSelects()}
                            </select>
                          </div>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Als een medewerker te laat inklokt, dan wordt er automatisch een afwezigheid gekoppeld aan de medewerker's dienst.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>

                  :
                  this.state.menu === 'bonus' ?

                    <div>

                      <div className='appoptions_submenu_title'>

                        <span>Toeslagen</span>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Dit zijn de factoren waarmee de uren en financiën vermenigvuldigd worden. Dit kan toegepast worden op bijvoorbeeld feestdagen.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div style={{display: 'flex', flexDirection: 'row', marginTop: '2vw', marginBottom: '1%', marginLeft: '0.5%'}}>
                        <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openBonusModal(null)}>Toevoegen</span>
                      </div>

                      <div className='hr_hoursBar_title' style={{width: '40%'}}>

                        <div className='hr_hoursBar_sub1'>
                          <span>Datum</span>
                        </div>

                        <div className='hr_hoursBar_sub2'>
                          <span>Tijd</span>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <span>Uitbetaling</span>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <span>TvT</span>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <span>&nbsp;</span>
                        </div>

                      </div>

                      {this.getBonusBars()}

                    </div>

                  :
                  this.state.menu === 'connections' ?

                    <div>

                      <div className='appoptions_submenu_title'>

                        <span>Koppelingen</span>

                      </div>

                      <div style={{ paddingLeft: '0.5vw', marginTop: '1vw' }}>

                        <div className='appOptions_new_row'>
                          
                          <div className='appOptions_new_row_col1'>
                            <span style={{ fontWeight: 600 }}>Uw API token</span>
                          </div>

                          <div className='appOptions_new_row_col2'>

                            <span>{Data.data.companyData.bedrijf_taurusToken}</span>

                            <Tooltip
                              options
                              html={(
                                <div className='plannerN_tooltipContainer'>
                                  <span>Token kopiëren</span>
                                </div>
                              )}
                              position={'top'}
                              theme='kodict'
                              delay={300}
                              hideDelay={0}
                            >
                              <span><i className='far fa-copy appOptions_icon_button' onClick={() => this.copyToken('TAURUS')} /></span>
                            </Tooltip>

                            <Tooltip
                              options
                              html={(
                                <div className='plannerN_tooltipContainer'>
                                  <span>Nieuwe token aanvragen</span>
                                </div>
                              )}
                              position={'top'}
                              theme='kodict'
                              delay={300}
                              hideDelay={0}
                            >
                              <span><i className='far fa-sync appOptions_icon_button' onClick={() => this.newTokenAlert('TAURUS')} /></span>
                            </Tooltip>

                          </div>

                        </div>

                      </div>

                    </div>

                  :
                  this.state.menu === 'finances' ?

                    <div>

                      <div className='appoptions_submenu_title'>Financiën</div>

                      <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Factoren</div>

                      <div className='appoptions_submenu_rowContainer'>

                        <span style={{fontsize: '0.8vw'}}>BTW (%)</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          max={999999999}
                          value={this.state.opties_BTW}
                          onChange={(event) => this.onChangeNumberData('btw', 0, 999999999, event)}
                          type={'number'}
                          step={0.01}
                          style={{width: '15%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Geef hier het BTW percentage aan.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer'>

                        <span style={{fontsize: '0.8vw'}}>Omzet inclusief BTW</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_omzetBTW} 
                                onClick={() => this.editNewOptions('opties_omzetBTW', !this.state.opties_omzetBTW)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Is jouw aangegeven omzet inclusief of exclusief BTW?</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer'>

                        <span style={{fontsize: '0.8vw'}}>Loonfactor</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={1}
                          max={999999999}
                          value={this.state.opties_loonfactor}
                          onChange={(event) => this.onChangeNumberData('salaryFactor', 1, 999999999, event)}
                          type={'number'}
                          step={0.01}
                          style={{width: '15%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>De loonkosten worden met deze factor vermenigvuldigd. Deze vermenigvuldiging kan worden gebruikt om diverse extra kosten mee te kunnen rekenen (zoals kosten die naast de directe loonkosten gemaakt worden).</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer'>

                        <span style={{fontsize: '0.8vw'}}>Loonpercentage van (verwachte) omzet</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          max={999999999}
                          value={this.state.opties_loonPercentage}
                          onChange={(event) => this.onChangeNumberData('salaryPercent', 0, 999999999, event)}
                          type={'number'}
                          step={0.01}
                          style={{width: '15%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Percentage loonkosten in verhouding tot omzet. Wanneer de verwachte loonkosten boven dit percentage komt, wordt dit rood aangegeven in de planner. (0 = uitgeschakeld)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                    </div>

                  :
                  this.state.menu === 'planner' ?

                    this.state.subMenu === 'planner' ?

                      <div>

                        <div className='appoptions_submenu_title'>Planner</div>

                        <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Weergave</div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Beschikbaarheid verbergen</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_infoplanner_verborgen} 
                                  onClick={() => this.editNewOptions('opties_infoplanner_verborgen', !this.state.opties_infoplanner_verborgen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Beschikbaarheid op de planner verborgen houden. Beschikbaarheid kan alsnog worden gezien wanneer er op de invoerbalk geklikt wordt.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Verwachte drukte</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planner_drukte} 
                                  onClick={() => this.editNewOptions('opties_planner_drukte', !this.state.opties_planner_drukte)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Laat de verwachte drukte zien. De drukte wordt berekend met behulp van de afgelopen 3 weken.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                        {/* <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Extra open diensten</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planner_extraDiensten} 
                                  onClick={() => this.editNewOptions('opties_planner_extraDiensten', !this.state.opties_planner_extraDiensten)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Extra open diensten weergeven. Dit zijn diensten die extra zijn gemaakt of die (nog) niet gekoppeld zijn aan een medewerker.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div> */}

                        {/* <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Contracturen</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planner_contracturen} 
                                  onClick={() => this.editNewOptions('opties_planner_contracturen', !this.state.opties_planner_contracturen)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Contracturen en overuren weergeven achter de naam van een medewerker.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div> */}

                        {/* <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Sorteren</div>

                        <div className='appoptions_submenu_rowContainer4' onClick={() => this.props.history.push(`/planner_arrange`)}>

                          <span style={{fontsize: '0.8vw'}}>Medewerkers sorteren</span>

                          <i className='fas fa-arrow-right' style={{fontsize: '0.8vw'}} />

                        </div>

                        <div className='appoptions_submenu_rowContainer4' onClick={() => this.props.history.push(`/function_arrange`)}>

                          <span style={{fontsize: '0.8vw'}}>Functies sorteren</span>

                          <i className='fas fa-arrow-right' style={{fontsize: '0.8vw'}} />

                        </div> */}

                        {/* <div className='appoptions_submenu_rowContainer'>

                          <span style={{fontsize: '0.8vw'}}>Open diensten sorteren onder functie</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_planFunctieSort} 
                                  onClick={() => this.editNewOptions('opties_planFunctieSort', !this.state.opties_planFunctieSort)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Open diensten onder de functie weergeven of helemaal onderin de planner weergeven.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div> */}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>{`Financiële berekeningen`}</div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Loonkosten</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_loonkosten} 
                                    onClick={() => this.editNewOptions('opties_planner_loonkosten', !this.state.opties_planner_loonkosten)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Loonkosten weergeven van medewerkers met een uurloon (oproepkracht, nul-uren, min/max enzovoort).</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Omzet vorig jaar</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_vorigOmzet} 
                                    onClick={() => this.editNewOptions('opties_planner_vorigOmzet', !this.state.opties_planner_vorigOmzet)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Omzet van het jaar ervoor weergeven.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Verwachte omzet</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_verwachteOmzet} 
                                    onClick={() => this.editNewOptions('opties_planner_verwachteOmzet', !this.state.opties_planner_verwachteOmzet)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Verwachte omzet kunnen invullen per dag.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Werkelijke omzet</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_omzet} 
                                    onClick={() => this.editNewOptions('opties_planner_omzet', !this.state.opties_planner_omzet)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Werkelijke omzet kunnen invullen per dag.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Productiviteit</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_productiviteit} 
                                    onClick={() => this.editNewOptions('opties_planner_productiviteit', !this.state.opties_planner_productiviteit)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Productiviteit per dag berekenen met behulp van geplande uren en omzet. Als er nog geen werkelijke omzet is gemaakt, wordt de berekening gemaakt met behulp van verwachte omzet of omzet van het jaar ervoor.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Constants.isTrue(this.state.opties_financien) ?
                          <div className='appoptions_submenu_rowContainer'>

                            <span style={{fontsize: '0.8vw'}}>Berekening weergeven</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_planner_berekeningen} 
                                    onClick={() => this.editNewOptions('opties_planner_berekeningen', !this.state.opties_planner_berekeningen)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>De totale berekening weergeven.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div style={{marginTop: '1vw'}}>
                            <div className='appoptions_submenu_subtitle'>Open diensten</div>

                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Open diensten gelijk beschikbaar zetten</span>

                              <label className={"switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_opendienst_beschikbaar} 
                                      onClick={() => this.editNewOptions('opties_opendienst_beschikbaar', !this.state.opties_opendienst_beschikbaar)}
                                      readOnly
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Wanneer een open dienst wordt aangemaakt, zal deze gelijk beschikbaar worden gezet.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          </div>
                        : null}

                        {Data.data.appOptions.opties_versie !== '0' ?
                          <div style={{marginTop: '1vw'}}>
                            <div className='appoptions_submenu_subtitle'>Contract</div>

                            <div className='appoptions_submenu_rowContainer'>

                              <span style={{fontsize: '0.8vw'}}>Contract verlopen</span>

                              <label className={"switch"} style={{marginLeft: 25}}>
                                  <input 
                                      type="checkbox" 
                                      checked={this.state.opties_contractVerlopen} 
                                      onClick={() => this.editNewOptions('opties_contractVerlopen', !this.state.opties_contractVerlopen)}
                                      readOnly
                                  />
                                  <span className="slider round"></span>
                              </label>

                              <Tooltip
                                options
                                html={(
                                  <div className='appoptions_tooltipContainer'>
                                    <span>Wanneer een contract is verlopen van een medewerker houdt de planner hier rekening mee en kan deze medewerker niet meer worden ingepland.</span>
                                  </div>
                                )}
                                style={{position: 'absolute', right: '-5%'}}
                                position={'top'}
                                arrow
                                followCursor
                                theme='kodict'
                              >
                                <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                              </Tooltip>

                            </div>
                          </div>
                        : null}

                      </div>

                    : this.state.subMenu === 'labels' ?

                      <div>

                        <div className='appoptions_submenu_title'>{`Labels`}</div>

                        <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                          <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openPartModal(0)}>Toevoegen</div>

                        </div>

                        {this.getPartBars()}

                      </div>

                    : null

                  :
                  this.state.menu === 'trade' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Ruilen & vervanging`}</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Ruilen/overnemen dezelfde functie</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_ruilenFunctie} 
                                onClick={() => this.editNewOptions('opties_ruilenFunctie', !this.state.opties_ruilenFunctie)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers mogen alleen diensten ruilen die dezelfde functie vereisen als hun eigen functie. Wanneer dit is uitgeschakeld, kan elke dienst geruild worden ongeacht de functie.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Rekening houden met vaardigheden</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_ruilenComp} 
                                onClick={() => this.editNewOptions('opties_ruilenComp', !this.state.opties_ruilenComp)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers mogen alleen met elkaar ruilen of andermans diensten overnemen wanneer zij dezelfde vaardigheden hebben.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Overnemen zonder functie</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_ruilenGeenFunctie} 
                                onClick={() => this.editNewOptions('opties_ruilenGeenFunctie', !this.state.opties_ruilenGeenFunctie)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers - die geen functie toegewezen hebben gekregen - toestaan om diensten overnemen.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Dagen van te voren</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          value={this.state.opties_ruilen_vantevoren}
                          onChange={(event) => this.onChangeNumberData('tradeDays', 0, 999999999, event)}
                          type={'number'}
                          step={1}
                          style={{width: '10%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Aantal dagen van te voren dat geruild mag worden door medewerkers. (0 = op de dag zelf nog)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                    </div>

                  :
                  this.state.menu === 'hours' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Tijdregistratie`}</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Medewerkers mogen eigen uren inzien</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_urenEigen} 
                                onClick={() => this.editNewOptions('opties_urenEigen', !this.state.opties_urenEigen)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers toestaan om eigen gewerkte uren in te zien.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      {/* <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Uren bijhouden</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Tijdregistratie volgens rooster</span>

                        <label className={this.state.hoursRegisterDisabled ? "switch_disabled" : "switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.hoursRegister === 'PLANNER'} 
                                onClick={() => this.toggleSwitchHours()}
                                readOnly
                                disabled={this.state.hoursRegisterDisabled}
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Tijdregistratie wordt bijgehouden door het rooster.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Tijdregistratie volgens inkloksysteem</span>

                        <label className={this.state.hoursRegisterDisabled ? "switch_disabled" : "switch"}>
                            <input 
                                type="checkbox" 
                                checked={this.state.hoursRegister === 'CLOCK'} 
                                onClick={() => this.toggleSwitchHours()}
                                readOnly
                                disabled={this.state.hoursRegisterDisabled}
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Tijdregistratie wordt bijgehouden door het inkloksysteem.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div> */}

                      <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Rapportage</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Rooster</span>

                        <label className={this.state.hoursRegister === 'CLOCK' ? "switch_disabled" : "switch"}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_uren_daadwerkelijk === false} 
                                onClick={() => this.editNewOptions('opties_uren_daadwerkelijk', !this.state.opties_uren_daadwerkelijk)}
                                readOnly
                                disabled={this.state.hoursRegister === 'CLOCK'}
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Rapportage weergeven van de uren die door de planning zijn berekend.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Daadwerkelijk gewerkte uren</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_uren_daadwerkelijk === true} 
                                onClick={() => this.editNewOptions('opties_uren_daadwerkelijk', !this.state.opties_uren_daadwerkelijk)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Rapportage weergeven van de gewerkte uren die  door jou zijn goedgekeurd. Tijdregistratie kun je goedkeuren bij het tabje 'Tijdregistratie'.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_subtitle' style={{marginTop: '1vw'}}>Pauze</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Pauzes via rooster</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.breakOption === 'PLANNER'} 
                                onClick={() => this.toggleSwitchBreakSchedule()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Pauzes berekenen via het rooster.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <span style={{fontsize: '0.8vw'}}>Pauzes via gewerkte uren</span>
                          {this.state.breakOption === 'CALC' ?
                            <div className={'defaultLinkO'} style={{fontSize: '0.8vw'}} onClick={() => this.selectMenu('breaks')}>
                              Beheer pauzeregels
                            </div>
                          : null}
                        </div>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.breakOption === 'CALC'}
                                onClick={() => this.toggleSwitchBreakScheduleCalc()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Pauzes berekenen via het totaal aantal gewerkte uren. Deze berekeningen kun je zelf aanpassen.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Pauzes via inkloksysteem</span>

                        <label className={this.state.clockSystem === false || this.state.hoursRegister === 'PLANNER' ? "switch_disabled" : "switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.breakOption === 'CLOCK'}
                                onClick={() => this.toggleSwitchBreakClock()}
                                readOnly
                                disabled={this.state.clockSystem === false || this.state.hoursRegister === 'PLANNER'}
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Pauzes berekenen via het inkloksysteem. Medewerkers mogen dan zelf hun pauzes aangeven via het inkloksysteem.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      {Data.data.appOptions.opties_versie !== '0' ?
                        <div style={{marginTop: '1vw'}}>
                          <div className='appoptions_submenu_subtitle'>Overuren</div>

                          <div className='appoptions_submenu_rowContainer2'>

                            <span style={{fontsize: '0.8vw'}}>Overuren bijhouden</span>

                            <label className={this.state.opties_uren_daadwerkelijk === false ? 'switch_disabled' : "switch"}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_overuren} 
                                    onClick={() => this.editNewOptions('opties_overuren', !this.state.opties_overuren)}
                                    readOnly
                                    disabled={this.state.opties_uren_daadwerkelijk === false}
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Overuren worden middels de planning en de daadwerkelijke uren berekend. Overuren kun je bekijken en wijzigen bij het tabje 'Overzichten' of bij de medewerkersinstellingen.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: '-5%'}}
                              position={'top'}
                              arrow
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        </div>
                      : null}

                    </div>

                  :
                  this.state.menu === 'breaks' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Pauzes`}</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Pauzes via rooster</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.breakOption === 'PLANNER'} 
                                onClick={() => this.toggleSwitchBreakSchedule()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Pauzes berekenen via het rooster.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <span style={{fontsize: '0.8vw'}}>Pauzes via gewerkte uren</span>
                        </div>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.breakOption === 'CALC'}
                                onClick={() => this.toggleSwitchBreakScheduleCalc()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Pauzes berekenen via het totaal aantal gewerkte uren. Deze berekeningen kun je zelf aanpassen.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Pauzes via inkloksysteem</span>

                        <label className={this.state.clockSystem === false || this.state.hoursRegister === 'PLANNER' ? "switch_disabled" : "switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.breakOption === 'CLOCK'}
                                onClick={() => this.toggleSwitchBreakClock()}
                                readOnly
                                disabled={this.state.clockSystem === false || this.state.hoursRegister === 'PLANNER'}
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Pauzes berekenen via het inkloksysteem. Medewerkers mogen dan zelf hun pauzes aangeven via het inkloksysteem.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      {this.state.breakOption === 'CALC' ?
                       
                        <div>

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '1.5vw'}}>Pauzeregels</div>

                          <div style={{display: 'flex', flexDirection: 'row', marginTop: '1vw', marginBottom: '1%', marginLeft: '0.5%'}}>
                            <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.addBreakRuleRow()}>Toevoegen</span>
                            <span className='planner_new_publishButton2' style={{width: '4vw'}} onClick={() => this.saveBreakRules()}>Opslaan</span>
                          </div>

                          {this.getBreakRulesBars()}

                          {this.getEmptyBreakRulesBars()}

                        </div>

                      : null}

                    </div>

                  :
                  this.state.menu === 'absence' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Verlof`}</div>

                      {/* {Data.data.appOptions.opties_versie !== '0' ?
                        <div className='appoptions_submenu_rowContainer2'>

                          <span style={{fontsize: '0.8vw'}}>Verlofsaldo bijhouden</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_verlofsaldo} 
                                  onClick={() => this.editNewOptions('opties_verlofsaldo', !this.state.opties_verlofsaldo)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Verlofsaldo bijhouden bij medewerkers die een vaste uren contract hebben of een min-max contract. Bij deze medewerkers kan een verlofsaldo worden opgebouwd of een vast aantal dagen toegewezen worden.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: '-5%'}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>
                      : null} */}

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Dagen van te voren</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          value={this.state.opties_verlofDeadline}
                          onChange={(event) => this.onChangeNumberData('vacation', 0, 999999999, event)}
                          type={'number'}
                          step={1}
                          style={{width: '10%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Aantal dagen van te voren dat verlof kan worden aangevraagd door medewerkers. (0 = op de dag zelf nog)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>Limiet dagen achtereenvolgend</span>

                        <input 
                          className={'inputField2Shadow'}
                          min={0}
                          value={this.state.opties_verlof_limiet}
                          onChange={(event) => this.onChangeNumberData('vacationLimit', 0, 999999999, event)}
                          type={'number'}
                          step={1}
                          style={{width: '10%'}}
                        />

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Aantal dagen achtereenvolgend dat een medewerker in één verzoek vrij kan vragen. (0 = geen limiet)</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true ?

                        <div>

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '2vw', width: '8vw'}}>
                            <span>Verlofsaldo's</span>
                            {this.state.loading === false && this.state.leaveBalances.length === 0 && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                              <span className='fas fa-exclamation-triangle' style={{ color: Colors.color.redFilledIn, position: 'absolute', right: '-5%', top: '0.3vw', fontSize: '0.8vw' }} />
                            : null}
                          </div>

                          <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '1vw'}}>

                            <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openLeaveBalanceModal(0)}>Toevoegen</div>

                          </div>

                          {this.state.leaveBalances.length > 0 ?
                            <div className='hr_hoursBar' style={{width: '37%'}}>

                              <div className='hr_hoursBar_sub1'>
                                <b>Saldo</b>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <b>Opbouw per uur</b>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                &nbsp;
                              </div>

                            </div>
                          : null}

                          {this.getLeaveBalancesBars()}

                          <div className='appoptions_submenu_subtitle' style={{marginTop: '2vw', width: '8vw'}}>
                            <span>Verloftypes</span>
                            {this.state.loading === false && this.state.leaveBalances.length > 0 && (this.state.leaveTypes.length === 0 || this.state.correctTypes === false) && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?
                              <span className='fas fa-exclamation-triangle' style={{ color: Colors.color.redFilledIn, position: 'absolute', right: '-5%', top: '0.3vw', fontSize: '0.8vw' }} />
                            : null}
                          </div>

                          <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '1vw'}}>

                            <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openLeaveTypeModal(0)}>Toevoegen</div>

                          </div>

                          {this.state.leaveTypes.length > 0 ?
                            <div className='hr_hoursBar' style={{width: '37%'}}>

                              <div className='hr_hoursBar_sub1'>
                                <b>Type</b>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                <b>Saldo's</b>
                              </div>

                              <div className='hr_hoursBar_sub3'>
                                &nbsp;
                              </div>

                            </div>
                          : null}

                          {this.getLeaveTypesBars()}

                        </div>

                      : null}

                      {Data.data.appOptions.availability === 'AVAILABILITY' ?
                        <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <div className='appoptions_submenu_subtitle' style={{marginTop: '2vw'}}>Vakantieperiodes</div>
                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Tijdens het aanvragen van een vakantie in de app wordt de medewerker gevraagd om extra beschikbare dagen aan te geven wanneer hun vakantie in de vakantieperiode valt.</span>
                              </div>
                            )}
                            style={{position: 'absolute', left: '12%', bottom: 0}}
                            position={'top'}
                            arrow
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                          </Tooltip>
                        </div>
                      : null}

                      {Data.data.appOptions.availability === 'AVAILABILITY' ?
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '1%', marginBottom: '1%', marginLeft: '0.5%'}}>
                          <span className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.addHolidayPeriodRow()}>Toevoegen</span>
                          <span className='planner_new_publishButton2' style={{width: '4vw'}} onClick={() => this.saveHolidayPeriods()}>Opslaan</span>
                        </div>
                      : null}
                      
                      {Data.data.appOptions.availability === 'AVAILABILITY' ?
                        this.getHolidayPeriodBars()
                      : null}

                      {Data.data.appOptions.availability === 'AVAILABILITY' ?
                        this.getEmptyHolidayPeriodBars()
                      : null}

                    </div>

                  :
                  this.state.menu === 'leavebalances' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Verlofsaldo's`}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openLeaveBalanceModal(0)}>Toevoegen</div>

                      </div>

                      <div className='hr_hoursBar' style={{width: '30%'}}>

                        <div className='hr_hoursBar_sub1'>
                          <b>Saldo</b>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <b>Opbouw per uur</b>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          &nbsp;
                        </div>

                      </div>

                      {this.getLeaveBalancesBars()}

                    </div>

                  :
                  this.state.menu === 'leavetypes' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Verloftypes`}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openLeaveTypeModal(0)}>Toevoegen</div>

                      </div>

                      <div className='hr_hoursBar' style={{width: '30%'}}>

                        <div className='hr_hoursBar_sub1'>
                          <b>Type</b>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <b>Saldo's</b>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          &nbsp;
                        </div>

                      </div>

                      {this.getLeaveTypesBars()}

                    </div>

                  :
                  this.state.menu === 'register' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Registratie`}</div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>IBAN</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.iban} 
                                onClick={() => this.toggleSwitchIban()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers moeten hun IBAN invullen tijdens het registreren.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className='appoptions_submenu_rowContainer2'>

                        <span style={{fontsize: '0.8vw'}}>BSN-nummer</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.bsn} 
                                onClick={() => this.toggleSwitchBsn()}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Medewerkers moeten hun BSN-nummer invullen tijdens het registreren.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                    </div>

                  :
                  this.state.menu === 'part' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Labels`}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openPartModal(0)}>Toevoegen</div>

                      </div>

                      {this.getPartBars()}

                    </div>

                  :
                  this.state.menu === 'abs' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Afwezigheden`}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openAbsModal(0)}>Toevoegen</div>

                      </div>

                      {this.getAbsBars()}

                      <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div className='appoptions_submenu_subtitle' style={{marginTop: '2vw', marginBottom: '0.5vw'}}>Uitsluitingen</div>
                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Deze afwezigheidstypes worden uitgesloten tijdens de financiële berekeningen en tijdregistraties.</span>
                            </div>
                          )}
                          style={{position: 'absolute', left: '9%', bottom: '0.6vw'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>
                      </div>



                      {/* <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '0vw', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>

                        <span className='defaultLinkO' onClick={() => this.selectMenu('abs')}>Beheer afwezigheidstypes</span>

                      </div> */}

                      {this.getExclusionBars()}

                    </div>

                  :
                  this.state.menu === 'skills' ?

                    <div>

                      <div className='appoptions_submenu_title'>{`Vaardigheden`}</div>

                      <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '2vw'}}>

                        <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.openSkillsModal(0)}>Toevoegen</div>

                      </div>

                      {this.getSkillsBars()}

                    </div>

                  :
                  this.state.menu === 'exclusions' ?

                    <div>

                      <div className='appoptions_submenu_title'>

                        <span>Uitsluitingen</span>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Deze afwezigheidstypes worden uitgesloten tijdens de financiële berekeningen en tijdregistraties.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: '-5%'}}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: '0.8vw'}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>

                      <div className="planner_addButtons_container" style={{marginLeft: '0.5%', marginTop: '0vw', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>

                        <span className='defaultLinkO' onClick={() => this.selectMenu('abs')}>Beheer afwezigheidstypes</span>

                      </div>

                      {this.getExclusionBars()}

                    </div>

                  :

                    null

                  }

                </div>

              }

            </div>

          </div>

        </div>
    );
  }
  
}

export default AppOptions;