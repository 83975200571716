/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './deadlines.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import APIAddDeadline from '../../../classes/APIAddDeadline';
import APIEditDeadline from '../../../classes/APIEditDeadline';
import APIDeleteDeadline from '../../../classes/APIDeleteDeadline';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIDeleteDeadlineAbsence from '../../../classes/APIDeleteDeadlineAbsence';
import APIEditDeadlineAbsence from '../../../classes/APIEditDeadlineAbsence';
import APIAddDeadlineAbsence from '../../../classes/APIAddDeadlineAbsence';

class DeadlinesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    if (typeof this.modalData.deadlineverlof_id !== 'undefined') {
      this.modalData.deadline_id = this.modalData.deadlineverlof_id;
      this.modalData.deadline_begin = this.modalData.deadlineverlof_begin;
      this.modalData.deadline_eind = this.modalData.deadlineverlof_eind;
      this.modalData.deadline_datum = this.modalData.deadlineverlof_datum;
      this.modalData.deadline_tijd = this.modalData.deadlineverlof_tijd;
      this.modalData.deadline_aanpassen = this.modalData.deadlineverlof_aanpassen;
    }
    
    this.state = {

        loading: false,

        deadlineDate1: null,
        deadlineDateText1: '',

        deadlineDate2: null,
        deadlineDateText2: '',

        deadlineDate3: null,
        deadlineDateText3: '',

        deadlineTime: null,
        deadlineTimeText: '00:00',

        selected: 'availability',

        edit: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING DEADLINE

      const time = new Date();
      time.setHours(0, 0);
      this.setTime(time);
        
    }

    if(this.mode === 1) { //EDITING DEADLINE

      const hour = parseInt(this.modalData.deadline_tijd.substring(0, 2));
      const min = parseInt(this.modalData.deadline_tijd.substring(3, 5));

      const time = new Date();
      time.setHours(hour, min);

      this.setTime(time);

      const dateObject1 = Constants.stringToDate(this.modalData.deadline_datum);
      const dateObject2 = Constants.stringToDate(this.modalData.deadline_begin);
      const dateObject3 = Constants.stringToDate(this.modalData.deadline_eind);
      // dateObject1.setDate(dateObject1.getDate() + 1);
      // dateObject2.setDate(dateObject2.getDate() + 1);
      // dateObject3.setDate(dateObject3.getDate() + 1);

      const dateText1 = Constants.dateToString(dateObject1);
      const dateText2 = Constants.dateToString(dateObject2);
      const dateText3 = Constants.dateToString(dateObject3);

      this.setState({
        deadlineDate1: Constants.stringToDate(this.modalData.deadline_datum),
        deadlineDateText1: dateText1,
        deadlineDate2: Constants.stringToDate(this.modalData.deadline_begin),
        deadlineDateText2: dateText2,
        deadlineDate3: Constants.stringToDate(this.modalData.deadline_eind),
        deadlineDateText3: dateText3,
        edit: this.modalData.deadline_aanpassen === '1',
      });

    }

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        deadlineDate1: date,
        deadlineDateText1: dateText,
        showError: false,
    });

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        deadlineDate2: date,
        deadlineDateText2: dateText,
        showError: false,
    });

  }

  setDate3(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        deadlineDate3: date,
        deadlineDateText3: dateText,
        showError: false,
    });

  }

  setTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const mins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        deadlineTime: date,
        deadlineTimeText: `${hours}:${mins}`,
        showError: false,
    });

  }

  toggleSwitch() {

    this.setState({edit: !this.state.edit});

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze deadline wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteDeadline.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveDeadline() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    let added = false;

    // if (this.state.selected === 'absence') {

    //   added = await APIAddDeadlineAbsence.Request(
    //     this.state.deadlineDateText2,
    //     this.state.deadlineDateText3,
    //     this.state.deadlineDateText1,
    //     this.state.deadlineTimeText,
    //     this.state.edit,
    //   );

    // } else {

    added = await APIAddDeadline.Request(
      this.state.deadlineDateText2,
      this.state.deadlineDateText3,
      this.state.deadlineDateText1,
      this.state.deadlineTimeText,
      this.state.edit,
    );

    //}
    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editDeadline() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    let added = false;

    if (this.props._modalType === 'absence') {

      added = await APIEditDeadlineAbsence.Request(
        this.state.deadlineDateText2,
        this.state.deadlineDateText3,
        this.state.deadlineDateText1,
        this.state.deadlineTimeText,
        this.state.edit,
        parseInt(this.modalData.deadline_id)
      );

    } else {

      added = await APIEditDeadline.Request(
        this.state.deadlineDateText2,
        this.state.deadlineDateText3,
        this.state.deadlineDateText1,
        this.state.deadlineTimeText,
        this.state.edit,
        parseInt(this.modalData.deadline_id)
      );

    }

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteDeadline() {

    this.setState({loading: true});

    let added = false;

    if (this.props._modalType === 'absence') {

      added = await APIDeleteDeadlineAbsence.Request(
        parseInt(this.modalData.deadline_id),
      );

    } else {

      added = await APIDeleteDeadline.Request(
        parseInt(this.modalData.deadline_id),
      );

    }

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.deadlineDate1 === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen deadline datum ingevoerd',
      });
      return false;
    }
    if(this.state.deadlineDate2 === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen begindatum ingevoerd',
      });
      return false;
    }
    if(this.state.deadlineDate3 === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen einddatum ingevoerd',
      });
      return false;
    }
    if(this.state.deadlineDate3 < this.state.deadlineDate2) {
      this.setState({
        showError: true,
        errorText: 'Begindatum kan niet later zijn dan einddatum',
      });
      return false;
    }

    return true;

  }

  selectType(event) {

    this.setState({
        selected: event.target.value,
    });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="deadlinesModal" className="modal" onClick={(e) => e.target === document.getElementById("zdeadlinesModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Deadline toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Deadline wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Deadline datum</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <DatePicker
                            selected={this.state.deadlineDate1}
                            onChange={(date) => this.setDate1(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'inputFieldTime'}
                            placeholderText={'Selecteer een datum'}
                            showWeekNumbers
                            locale={nl}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Om</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <DatePicker
                              selected={this.state.deadlineTime}
                              onChange={(date) => this.setTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Van"
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              className={'inputFieldTime'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '2vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Periode van</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                        <DatePicker
                            selected={this.state.deadlineDate2}
                            startDate={this.state.deadlineDate2}
                            endDate={this.state.deadlineDate3}
                            onChange={(date) => this.setDate2(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'inputFieldTime'}
                            placeholderText={'Selecteer een datum'}
                            locale={nl}
                            showWeekNumbers
                            selectsStart
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>T/m</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <DatePicker
                            selected={this.state.deadlineDate3}
                            startDate={this.state.deadlineDate2}
                            endDate={this.state.deadlineDate3}
                            minDate={this.state.deadlineDate2}
                            onChange={(date) => this.setDate3(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'inputFieldTime'}
                            placeholderText={'Selecteer een datum'}
                            locale={nl}
                            showWeekNumbers
                            selectsEnd
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '2vw'}}>

                        <div className='modal-rowSub1'>
                          <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Beschikbaarheid aanpasbaar na deadline</span>
                        </div>

                        <div className='modal-rowSub2'>

                          <label className="switch">
                              <input 
                                type="checkbox" 
                                checked={this.state.edit} 
                                onClick={() => this.toggleSwitch()}
                                readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                        </div>

                    </div>

                  <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editDeadline()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveDeadline()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default DeadlinesModal;