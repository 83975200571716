/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './planner.scss';
import '../../constants/DefaultStyle.css';
import '../options/appOptions/appOptions.css';
import '../options/requests/requests.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import Constants from '../../constants/Constants';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

import { Tooltip } from 'react-tippy';

import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';
import APIDELETE from '../../classes/global/APIDELETE';
import APIEDIT from '../../classes/global/APIEDIT';
import { DragDropContext } from 'react-beautiful-dnd';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGetAllSkillSchedules from '../../classes/APIGetAllSkillSchedules';
import Colors from '../../constants/Colors';
import APIAddToTeamPlanner from '../../classes/APIAddToTeamPlanner';
import APIAddPartSchedule from '../../classes/APIAddPartSchedule';
import APIDeleteUserPlanner from '../../classes/APIDeleteUserPlanner';
import Plan from './plan.class';
import __ from '../../constants/__';
import APIAddSkillSchedule from '../../classes/APIAddSkillSchedule';
import Localization from '../../constants/Localization';
import APIEditOptions2 from '../../classes/APIEditOptions2';
import SortModal from '../../components/sortModal/sortModal';
import APIDeleteUsedDayShift from '../../classes/APIDeleteUsedDayShift';
import BetterAlert from '../../components/alert/alert';
import ScheduleDownloadName from '../../components/excel/scheduleDownloadName';
import ScheduleDownloadJob from '../../components/excel/scheduleDownloadJob';
import TemplateModal from '../../components/smartPlanModal/templateModal';
import DayNoteModal from './dayNoteModal';
import APICanReplaceShift from '../../classes/APICanReplaceShift';
import PublishModal from './publishModal';
import SmartPlanModal from '../../components/smartPlanModal/smartPlanModal';
import KodictNotifications from '../../constants/KodictNotifications';

class Planner extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    // from tutorial?
    this.param_fromTut = this.props.location.fromTut !== undefined && this.props.location.fromTut !== null ? this.props.location.fromTut : false;

    this.salaryFactor = parseFloat(Data.data.appOptions.opties_loonfactor);

    this.from = null;
    this.fromType = '';

    this.lastDeleted = null;
    this.lastVal = '';
    this.timeRef = {};
    this.initialTarget = null;
    this.isPlanning = false;
    this.isPlanning_excel = {};
    this.canOpenPlanMenu = true;

    this.debounceTimer = null;

    this.breakRules = [];
    this.planAccounts_backup = {};

    this.teamUsers = {};
    this.teamPlanData = [];

    this.WEEK = Constants.isEmpty(this.props.location.selectedDate) === false ? Constants.getWeekNumber2(this.props.location.selectedDate) : Constants.getWeekNumber();
    this.YEAR = Constants.isEmpty(this.props.location.selectedDate) === false ? Constants.getWeekYear2(this.props.location.selectedDate) : new Date().getFullYear();

    this.state = {

      loading: true,
      reloading: false,
      reloadingPublish: false,
      smartPlanning: false,

      display: Constants.isEmpty(localStorage.getItem('@plannerDisplay')) ? 'TABLE' : localStorage.getItem('@plannerDisplay'), // XCL, LIST, TABLE
      section: null,
      dayShiftsTemplate: null,
      
      week: this.WEEK,
      year: this.YEAR,
      selectedDate: Constants.isEmpty(this.props.location.selectedDate) === false ? Constants.getWeekNumber2(this.props.location.selectedDate) : new Date(),

      mondayDate: Constants.dateToString(Constants.getMonday(this.WEEK, this.YEAR)),
      tuesdayDate: Constants.dateToString(Constants.getTuesday(this.WEEK, this.YEAR)),
      wednesdayDate: Constants.dateToString(Constants.getWednesday(this.WEEK, this.YEAR)),
      thursdayDate: Constants.dateToString(Constants.getThursday(this.WEEK, this.YEAR)),
      fridayDate: Constants.dateToString(Constants.getFriday(this.WEEK, this.YEAR)),
      saturdayDate: Constants.dateToString(Constants.getSaturday(this.WEEK, this.YEAR)),
      sundayDate: Constants.dateToString(Constants.getSunday(this.WEEK, this.YEAR)),

      // MODALS
      showModal: false,
      showPublishModal: false,
      showSmartPlanModal: false,
      showSortModal: false,
      showAddTempModal: false,
      showDayNoteModal: false,

      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,

      // ALERT
      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      // SIDEMENU
      sidemenuActive: false,

      opties_smartplan: Data.data.appOptions.opties_smartplan === '1',
      opties_financien: Data.data.appOptions.opties_financien === '1',
      opties_planBeschikbaar: Data.data.appOptions.opties_planBeschikbaar === '1',
      opties_planFunctieSort: Data.data.appOptions.opties_planFunctieSort === '1',
      opties_contractVerlopen: Data.data.appOptions.opties_contractVerlopen === '1',
      opties_opendienst_beschikbaar: Data.data.appOptions.opties_opendienst_beschikbaar === '1',
      opties_infoplanner_verborgen: Data.data.appOptions.opties_infoplanner_verborgen === '1',

      opties_planner_drukte: Data.data.appOptions.opties_planner_drukte === '1',
      opties_planner_loonkosten: Data.data.appOptions.opties_planner_loonkosten === '1',
      opties_planner_vorigOmzet: Data.data.appOptions.opties_planner_vorigOmzet === '1',
      opties_planner_verwachteOmzet: Data.data.appOptions.opties_planner_verwachteOmzet === '1',
      opties_planner_omzet: Data.data.appOptions.opties_planner_omzet === '1',
      opties_planner_productiviteit: Data.data.appOptions.opties_planner_productiviteit === '1',
      opties_planner_berekeningen: Data.data.appOptions.opties_planner_berekeningen === '1',
      opties_planner_extraDiensten: Data.data.appOptions.opties_planner_extraDiensten === '1',
      opties_planner_contracturen: Data.data.appOptions.opties_planner_contracturen === '1',

      // DATA
      accounts: [],
      accountsObj: {},
      allAccounts: [],
      allAccountsObj: {},
      contracts: {},
      planAccounts: {},
      addEmptyRowsUser: {},
      addEmptyRowsJob: {},
      planList: {},
      allPlansUser: {},
      user_hoursData: {},

      skills: {},
      skillsAccounts: {},
      skillsDayShifts: {},

      plannerArrange: [],

      jobs: [],
      sections: [],
      dayShiftsTemplates: [],
      dayShifts: {},
      usedDayShifts: [],
      planTemplates: [],
      secondaryJobs: {},
      breaks: [],
      labels_data: [],
      skills_data: [],
      availableShifts: {},
      availableDayShifts: {},
      userDefaultWorkdays: [],
      absenceTypes: [],

      turnoverLastYear: [],
      turnoverActual: [],
      turnoverExpected: [],

      expectedLoad_last3Weeks: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },
      expectedLoadMinMax: {
        mon: { min: 0, max: 0 },
        tue: { min: 0, max: 0 },
        wed: { min: 0, max: 0 },
        thu: { min: 0, max: 0 },
        fri: { min: 0, max: 0 },
        sat: { min: 0, max: 0 },
        sun: { min: 0, max: 0 },
      },

      dayNotes: {},

      // OVERHOURS DATA
      schedule2: {}, 
      absAmount2: {}, 
      hoursWorked: [], 
      overhours_corrections: {},

      // INPUTS
      inputs_turnoverActual: {},
      inputs_turnoverExpected: {},
      // inputs_plans: {},
      // inputs_av: {},

      // MENUS
      menu_export_open: false,
      menu_template_open: false,
      menu_dayShift_open: {},
      menu_plan_open: {},
      menu_holiday_open: {},
      menu_employee_open: {},
      menu_plan_employees_open: false,
      menu_plan_teams_open: false,
      menu_plan_jobs_open: false,
      menu_plan_breaks_open: false,
      menu_plan_labels_open: false,
      menu_plan_skills_open: false,
      menu_plan_absence_open: false,

      // SELECTIONS MENU
      menu_selectedEmployee: null,
      menu_selectedTeam: null,
      menu_selectedTime: '',
      menu_selectedJob: null,
      menu_selectedBreak: '00:00',
      menu_selectedLabel: null,
      menu_selectedSkills: [],

      // SELECTIONS EXCEL
      excel_selectionsJob: [],

      excel_selectedJob: {},
      excel_selectedBreak: {},

      // MISC
      showHover_select: null,
      isDragging: false,
      copyShifts: false,
      selectShifts: Constants.isEmpty(localStorage.getItem('@planner_selectShifts')) ? false : Constants.isTrue(localStorage.getItem('@planner_selectShifts')),
      selected: null,
      showTeams: Constants.isEmpty(localStorage.getItem('@planner_showTeams')) ? true : Constants.isTrue(localStorage.getItem('@planner_showTeams')),
      isTwoMonths: false,
      monthPlanData: {},
      monthPlanData2: {},
      smartPlanning_day: '',
      smartPlanning_account: '',
      planAccounts_cumulative: {},
      planAccounts_maxDays: {},
      jobsCollapsed: {},

      highestPlanTempKey: 0,
      highestCumulative: 0,

      // RENDER OBJECTS
      expectedLoad_renderObj: [],
      sections_renderObj: [],
      defaultShifts_renderObj: [],

      // ERRORS
      error_employee: false,
      error_time: false,
      error_job: false,

    };

    this.navBar = React.createRef();

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    await this.loadGeneralData();

    if (this.param_fromTut === true) {
      this.navBar.current.openHelpdesk();
    }

  }

  async loadGeneralData() {

    if (this.state.loading === false && this.state.reloading === false && this.state.reloadingPublish === false) {
      this.setState({ loading: true });
    }

    this.from = null;
    this.fromType = '';

    this.setState({ menu_employee_open: {}, selected: null });

    // PREV YEAR TURNOVER
    let mondayP = Constants.getMonday(this.state.week, this.state.year);
    let sundayP = Constants.getSunday(this.state.week, this.state.year);

    if (Constants.isLeapYear(this.state.year) || Constants.isLeapYear(this.state.year - 1)) {
      mondayP.setDate(mondayP.getDate() - 365);
      sundayP.setDate(sundayP.getDate() - 365);
    } else {
      mondayP.setDate(mondayP.getDate() - 364);
      sundayP.setDate(sundayP.getDate() - 364);
    }

    const _month = Constants.getMonday(this.state.week, this.state.year).getMonth() + 1;
    const _month2 = Constants.getSunday(this.state.week, this.state.year).getMonth() + 1;
    const _year = Constants.getMonday(this.state.week, this.state.year).getFullYear();
    const _year2 = Constants.getSunday(this.state.week, this.state.year).getFullYear();

    // GET GENERAL DATA
    const [

      now,

      accounts,
      allAccounts,
      contracts,

      jobs,
      jobsArrange,

      plannerArrange,

      dailyAvailability,
      dailyUnavailability,
      standardAvailability,
      standardUnavailability,
      school,
      schoolHolidays,
      repeatingAvailability,

      holidays,
      holidayRequests,

      planData,
      allPlanData,
      dayShiftsData,
      usedDayShiftsData,
      planTemplates,
      sections,
      dayShiftsTemplates,

      skills,
      skillsAccounts,
      skillsDayShifts,
      breakRules,
      secondaryJobs,
      breaks,
      labels_data,
      skills_data,
      availableShifts,
      availableDayShifts,
      userDefaultWorkdays,
      absenceTypes,
      absence,

      absenceAll,
      hours,
      absAmount,
      scheduleAbsence,
      clocked,
      schedules,
      overhoursCorrectionsData,

      turnoverLastYear,
      turnoverActual,
      turnoverExpected,

      mondayExpectedLoad,
      tuesdayExpectedLoad,
      wednesdayExpectedLoad,
      thursdayExpectedLoad,
      fridayExpectedLoad,
      saturdayExpectedLoad,
      sundayExpectedLoad,

      dayNotes,

      teamUsers,
      teamPlanData,

      monthPlanData,
      monthPlanData2,

      user_hoursData,

    ] = await Promise.all([

      // now
      Constants.getDateNow2(),

      // accounts
      Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN TeamUsers ON teamu_info_id = info_id`, `WHERE teamu_team_id = ${Data.data.chosenTeamObj.team_id} AND (info2_status <> 2 OR info2_status IS NULL)`, `GROUP BY info_id`, null) : APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE info2_status <> 2 OR info2_status IS NULL`, `GROUP BY info_id`, `ORDER BY info_voornaam, info_achternaam`),
      APIGET.Request(`SELECT *`, `FROM Account`, `LEFT JOIN Info ON info_id = account_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, null, `GROUP BY info_id`, `ORDER BY info_voornaam, info_achternaam`),
      
      // contracts
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),

      // jobs and arrange
      APIGetFunctions.Request(),
      APIGET.Request(`SELECT fv_functie_id, fv_index`, `FROM FunctieVolgorde`, null, null, null, `ORDER BY fv_index`),

      // planner arrange
      APIGET.Request(`SELECT pv_info_id, pv_index`, `FROM PlannerVolgorde`, null, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY pv_index`),

      // availabilities
      APIGET.Request(`SELECT *`, `FROM Beschikbaar`, `LEFT JOIN Info ON info_id = beschikbaar_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND beschikbaar_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY beschikbaar_datum, beschikbaar_begin, beschikbaar_eind, beschikbaar_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Onbeschikbaar`, `LEFT JOIN Info ON info_id = onbeschikbaar_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND onbeschikbaar_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY onbeschikbaar_datum, onbeschikbaar_begin, onbeschikbaar_eind, onbeschikbaar_id DESC`),
      APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND sb_beschikbaar = 1`, null, `ORDER BY sb_dag, sb_begin, sb_eind, sb_id DESC`),
      APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND sb_beschikbaar = 2`, null, `ORDER BY sb_dag, sb_begin, sb_eind, sb_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Schoolrooster`, `LEFT JOIN Info ON info_id = schoolrooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)`, null, `ORDER BY schoolrooster_dag, schoolrooster_begin, schoolrooster_eind, schoolrooster_begin, schoolrooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND ((schoolvakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (schoolvakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind) OR ('${this.state.sundayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind))`, null, null),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM HerBeschikbaar`, `LEFT JOIN Info ON hb_info_id = info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN TeamUsers ON teamu_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamu_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM HerBeschikbaar`, `LEFT JOIN Info ON hb_info_id = info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)`, null, null),

      // holidays
      APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND vakantie_geaccepteerd = 1 AND ((vakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (vakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN vakantie_begin AND vakantie_eind) OR ('${this.state.sundayDate}' BETWEEN vakantie_begin AND vakantie_eind))`, null, `ORDER BY info_voornaam, info_achternaam`),

      // holiday requests
      APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND vakantie_geaccepteerd = 0 AND ((vakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (vakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN vakantie_begin AND vakantie_eind) OR ('${this.state.sundayDate}' BETWEEN vakantie_begin AND vakantie_eind))`, null, `ORDER BY info_voornaam, info_achternaam`),

      // plan data
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),

      // all plan data
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),

      // shifts data
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),

      // used day shifts
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),

      // plan templates
      APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, null, `GROUP BY sjabloonm_key`, null),

      // sections
      APIGET.Request(`SELECT *`, `FROM Afdeling`, null, null, null, `ORDER BY afdeling_naam`),

      // day shifts templates
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM DagDienstSjabloon`, null, `WHERE dagdienstsjabloon_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY dagdienstsjabloon_id`) : APIGET.Request(`SELECT *`, `FROM DagDienstSjabloon`, null, null, null, `ORDER BY dagdienstsjabloon_id`),

      // skills
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),

      // skills accounts
      APIGET.Request(`SELECT *`, `FROM CompInfo`, `LEFT JOIN Competentie ON comp_id = compi_comp_id`, null, null, null),

      // skills dayshifts
      APIGET.Request(`SELECT *`, `FROM CompDagDienst`, `LEFT JOIN Competentie ON comp_id = compd_comp_id`, null, null, null),

      // break rules
      APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),

      // secondary jobs
      APIGET.Request(`SELECT *`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, null, null, null),

      // breaks
      APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`),

      // labels data
      APIGET.Request(`SELECT *`, `FROM Bijzonderheid`, null, null, null, `ORDER BY bijz_naam`),

      // skills data
      APIGET.Request(`SELECT *`, `FROM Competentie`, null, null, null, `ORDER BY comp_naam`),

      // available shifts
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),

      // available day shifts
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),

      // user fixed times
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM VasteDagen`, `LEFT JOIN Functie ON vd_functie_id = functie_id`, `WHERE vd_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM VasteDagen`, `LEFT JOIN Functie ON vd_functie_id = functie_id`, null, null, null),

      // absence types
      APIGET.Request(`SELECT *`, `FROM Afwezigheid`, null, null, null, `ORDER BY afw_naam`),

      // absence
      APIGET.Request(`SELECT *`, `FROM AfwezigheidN`, `LEFT JOIN Afwezigheid ON afwn_afw_id = afw_id LEFT JOIN Uitzondering ON uit_afw_id = afwn_afw_id`, `WHERE afwn_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),

      // overhours st00ff ...
      APIGET.Request(`SELECT *`, `FROM AfwezigheidN`, `LEFT JOIN Afwezigheid ON afwn_afw_id = afw_id LEFT JOIN Info ON afwn_info_id = info_id LEFT JOIN Uitzondering ON uit_afw_id = afwn_afw_id`, `WHERE uit_id IS NOT NULL`, null, null), // absence_n
      APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id LEFT JOIN Info ON vs_info_id = info_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afwr_afw_id = afw_id LEFT JOIN Uitzondering ON uit_afw_id = afw_id`, `WHERE uit_id IS NOT NULL`, null, null),
      APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id LEFT JOIN Uren ON uren_kloktijd_id = kloktijd_id`, `WHERE uren_id IS NULL`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Uren ON uren_rooster_id = rooster_id`, `WHERE rooster_publiceren = 1 AND uren_id IS NULL`, null, null),
      APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, null, null, `ORDER BY co_datum DESC, co_id DESC`),

      // turnover last year
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE (omzetd_datum BETWEEN '${Constants.dateToString(mondayP)}' AND '${Constants.dateToString(sundayP)}') AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE omzetd_datum BETWEEN '${Constants.dateToString(mondayP)}' AND '${Constants.dateToString(sundayP)}'`, null, `ORDER BY omzetd_datum`),

      // actual turnover
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE (omzetd_datum BETWEEN '${Constants.dateToString(this.state.mondayDate)}' AND '${Constants.dateToString(this.state.sundayDate)}') AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE (omzetd_datum BETWEEN '${Constants.dateToString(this.state.mondayDate)}' AND '${Constants.dateToString(this.state.sundayDate)}')`, null, `ORDER BY omzetd_datum`),

      // expected turnover
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE (omzetv_datum BETWEEN '${Constants.dateToString(this.state.mondayDate)}' AND '${Constants.dateToString(this.state.sundayDate)}') AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetv_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE (omzetv_datum BETWEEN '${Constants.dateToString(this.state.mondayDate)}' AND '${Constants.dateToString(this.state.sundayDate)}')`, null, `ORDER BY omzetv_datum`),
      
      // expected load last 3 weeks plans
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.mondayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.mondayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 0 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.mondayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.mondayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 0`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.tuesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.tuesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 1 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.tuesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.tuesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 1`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.wednesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.wednesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 2 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.wednesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.wednesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 2`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.thursdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.thursdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 3 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.thursdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.thursdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 3`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.fridayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.fridayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 4 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.fridayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.fridayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 4`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.saturdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.saturdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 5 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.saturdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.saturdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 5`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.sundayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.sundayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 6 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(this.state.sundayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(this.state.sundayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 6`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),

      // day notes
      APIGET.Request(`SELECT *`, `FROM DagNotitie`, `LEFT JOIN Team ON dn_team_id = team_id`, `WHERE dn_datum BETWEEN '${Constants.dateToString(this.state.mondayDate)}' AND '${Constants.dateToString(this.state.sundayDate)}'`, null, null),

      // teamusers
      APIGET.Request(`SELECT *`, `FROM TeamUsers`, `LEFT JOIN Team ON teamu_team_id = team_id`, null, null, `ORDER BY team_naam`),

      // team plan data
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),

      // month plan data
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${_month} AND YEAR(rooster_datum) = ${_year} AND rooster_publiceren = 1`, null, null),

      // month plan data 2
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${_month2} AND YEAR(rooster_datum) = ${_year2} AND rooster_publiceren = 1`, null, null),

      // users all hours data
      Constants.calculateAllHours_users(undefined, undefined, undefined),

    ]);

    // get data
    let planAccounts = this.getPlanAccounts(accounts, dailyAvailability, dailyUnavailability, standardAvailability, standardUnavailability, school, schoolHolidays, holidays, holidayRequests, planData, repeatingAvailability, absence);
    const planList = this.getPlanList(dailyAvailability, dailyUnavailability, standardAvailability, standardUnavailability, school, schoolHolidays, holidays, planData, repeatingAvailability);
    const emptyRowsUser = this.getEmptyRowsUser(accounts);
    const emptyRowsJob = this.getEmptyRowsJob(jobs);
    const jobsReorder = this.getJobs(jobs, jobsArrange);
    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const overhoursData = this.getOverhoursData(absenceAll, hours, absAmount, scheduleAbsence, clocked, schedules, overhoursCorrectionsData, breakRules);
    const inputs_turnoverActual = this.getTurnOverActual_inputs(turnoverActual);
    const inputs_turnoverExpected = this.getTurnOverExpected_inputs(turnoverExpected);
    const allPlanDataObject = this.getAllPlansUser(allAccounts, allPlanData);

    // set constant data
    this.breakRules = breakRules;
    this.planAccounts_backup = this.getPlanAccounts(accounts, dailyAvailability, dailyUnavailability, standardAvailability, standardUnavailability, school, schoolHolidays, holidays, holidayRequests, planData, repeatingAvailability, absence);

    // teams
    this.teamUsers = Constants.convertToObjectWithArrays(teamUsers, 'teamu_info_id');
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    // objects
    const contractsObject = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const skillsAccountsObject = Constants.convertToObjectWithArrays(skillsAccounts, 'compi_info_id');
    const skillsDayShiftsObject = Constants.convertToObjectWithArrays(skillsDayShifts, 'compd_dagdienst_id');
    const accountsObject = Constants.convertToObject(accounts, 'info_id');
    const allAccountsObject = Constants.convertToObject(allAccounts, 'info_id');
    const dayNotesObj = Constants.convertToObjectWithArrays(dayNotes, 'dn_datum');
    const secondaryJobsObject = Constants.convertToObjectWithArrays(secondaryJobs, 'fe_info_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const monthPlanDataObject = Constants.convertToObjectWithArrays(monthPlanData, 'rooster_info_id');
    const monthPlanData2Object = Constants.convertToObjectWithArrays(monthPlanData2, 'rooster_info_id');

    // calculations
    // highest planner template key (for creating new planTemplates, deciding key)
    let tempKey = 0;
    for (const temp of planTemplates) {
      if (parseInt(temp.sjabloonm_key) > tempKey) {
        tempKey = parseInt(temp.sjabloonm_key);
      }
    }

    // expected load
    const expectedLoad_last3Weeks = {
      mon: mondayExpectedLoad,
      tue: tuesdayExpectedLoad,
      wed: wednesdayExpectedLoad,
      thu: thursdayExpectedLoad,
      fri: fridayExpectedLoad,
      sat: saturdayExpectedLoad,
      sun: sundayExpectedLoad,
    };
    const expectedLoadMinMax = this.getExpectedLoadMinMax(expectedLoad_last3Weeks);

    // CUMULATIVE DAYS
    // GET MAX WORKING DAYS CUMULATIVE
    let planAccounts_cumulative = {};
    let highest = 0;
    for (const usr of accounts) {
      const maxCumulative = isNaN(parseInt(usr.info2_opeenvolgend)) === true ? 0 : parseInt(usr.info2_opeenvolgend);
      if (maxCumulative > highest) {
        highest = maxCumulative;
      }
    }

    // GET ALL WORKING DAYS FROM HIGHEST CUMULATIVE DAYS SETTING 
    if (highest > 0) {
      highest += 7;
      const planAccounts_cumulativeData = await APIGET.Request(`SELECT rooster_datum, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_info_id IS NOT NULL AND rooster_datum BETWEEN (CAST('${this.state.mondayDate}' AS DATE) - INTERVAL ${highest} DAY) AND (CAST('${this.state.mondayDate}' AS DATE) + INTERVAL ${highest} DAY)`, null, `ORDER BY rooster_datum`);
      planAccounts_cumulative = Constants.convertToObjectWithArrays(planAccounts_cumulativeData, 'rooster_info_id');
    }

    // MAX DAYS
    let planAccounts_maxDays = {};
    for (const userKey in allPlanDataObject) {
      for (const dayKey in allPlanDataObject[userKey]) {
        if (allPlanDataObject[userKey][dayKey].length > 0) {
          if (Constants.isEmpty(planAccounts_maxDays[userKey]) === true) {
            planAccounts_maxDays[userKey] = 1;
          } else {
            planAccounts_maxDays[userKey] += 1;
          }
        }
      }
    }

    // console.log(planAccounts)
    // console.log(planList)
    // console.log(accountsObject)
    // console.log(planAccounts_cumulative)
    // console.log(planAccounts_maxDays)

    this.setState({ 

      accounts: accounts,
      accountsObj: accountsObject,
      allAccounts: allAccounts,
      allAccountsObj: allAccountsObject,
      contracts: contractsObject,
      planAccounts: planAccounts,
      planList: planList,
      planTemplates: planTemplates,
      dayShiftsTemplates: dayShiftsTemplates,
      allPlansUser: allPlanDataObject,

      dayShiftsTemplate: dayShiftsTemplates.length > 0 ? dayShiftsTemplates[0].dagdienstsjabloon_id : null,

      skills: skillsObject,
      skillsAccounts: skillsAccountsObject,
      skillsDayShifts: skillsDayShiftsObject,
      secondaryJobs: secondaryJobsObject,
      breaks: breaks,
      labels_data: labels_data,
      skills_data: skills_data,
      availableShifts: availableShiftsObject,
      availableDayShifts: availableDayShiftsObject,
      userDefaultWorkdays: userDefaultWorkdays,
      absenceTypes: absenceTypes,

      addEmptyRowsUser: emptyRowsUser,
      addEmptyRowsJob: emptyRowsJob,

      plannerArrange: plannerArrange,

      jobs: jobsReorder,
      sections: sections,
      dayShifts: dayShiftsObj,

      turnoverLastYear: turnoverLastYear,
      turnoverActual: turnoverActual,
      turnoverExpected: turnoverExpected,

      expectedLoadMinMax: expectedLoadMinMax,
      expectedLoad_last3Weeks: expectedLoad_last3Weeks,

      dayNotes: dayNotesObj,

      // overhours data
      schedule2: overhoursData.schedule2, 
      absAmount2: overhoursData.absAmount2, 
      hoursWorked: overhoursData.hoursWorked, 
      overhours_corrections: overhoursData.overhours_corrections,

      // inputs
      inputs_turnoverActual: inputs_turnoverActual,
      inputs_turnoverExpected: inputs_turnoverExpected,

      // misc
      highestPlanTempKey: tempKey,
      highestCumulative: (highest - 7),

      planAccounts_cumulative: planAccounts_cumulative,
      planAccounts_maxDays: planAccounts_maxDays,

      isTwoMonths: _month !== _month2,
      monthPlanData: monthPlanDataObject,
      monthPlanData2: monthPlanData2Object,

      // allHoursData
      user_hoursData: user_hoursData,

    }, async () => {

      // INCORRECT TIMES
      let promisesIncorrect = [];

      for (const plan of planData) {
        const user_to = accountsObject[plan.rooster_info_id];
        if (Constants.isEmpty(user_to) || Constants.isEmpty(user_to.info_id)) {
          continue;
        }
        const incorrectTimes = this.getIncorrectTimes(user_to, plan.rooster_datum, plan, 'PLAN');
        if (incorrectTimes === true && plan.rooster_tijd_incorrect === '0') {
          promisesIncorrect.push(APIEDIT.Request(`UPDATE Rooster`, `SET rooster_tijd_incorrect = 1`, `WHERE rooster_id = ${plan.rooster_id}`));
        } else if (incorrectTimes === false && plan.rooster_tijd_incorrect === '1') {
          promisesIncorrect.push(APIEDIT.Request(`UPDATE Rooster`, `SET rooster_tijd_incorrect = 0`, `WHERE rooster_id = ${plan.rooster_id}`));
        }
      }

      if (promisesIncorrect.length > 0) {
        await Promise.all(promisesIncorrect);
        const planData2 = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`);
        planAccounts = this.getPlanAccounts(accounts, dailyAvailability, dailyUnavailability, standardAvailability, standardUnavailability, school, schoolHolidays, holidays, holidayRequests, planData2, repeatingAvailability, absence);
        this.setState({ planAccounts: planAccounts });
      }

      // EXCEL
      // make sure the input boxes are reloaded
      if (this.state.display === 'XCL') {

        for (const userKey in planAccounts) {

          let highestRows = 1;
          for (const dayKey in planAccounts[userKey]) {
            for (const plan of planAccounts[userKey][dayKey].plans) {
              const planKey = `plan${plan.rooster_id}`;
              if (Constants.isEmpty(this.timeRef[planKey]) === false) {
                this.timeRef[planKey].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
              }
              highestRows++;
            }
          }

          for (const dayKey in planAccounts[userKey]) {

            for (let row = 0; row < highestRows; row++) {

              if (Constants.isEmpty(userKey) === false) {

                const user = this.state.accountsObj[userKey];
                const avKey = `av${user.info_id}${user.functie_id}${dayKey}${row}`;

                if (Constants.isEmpty(this.timeRef[avKey]) === false) {
                  this.timeRef[avKey].value = '';
                }

              } else {

                // OPEN SHIFTS
                for (const job of this.state.jobs) {

                  const user = { info_id: null, functie_id: job.functie_id, functie_naam: job.functie_naam, functie_kleur: job.functie_kleur };
                  const avKey = `av${user.info_id}${user.functie_id}${dayKey}${row}`;

                  if (Constants.isEmpty(this.timeRef[avKey]) === false) {
                    this.timeRef[avKey].value = '';
                  }
            
                }

              }

            }

            for (const plan of planAccounts[userKey][dayKey].plans) {

              const planKey = `plan${plan.rooster_id}`;

              if (Constants.isEmpty(this.timeRef[planKey]) === false) {
                this.timeRef[planKey].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
              }

            }

          }

        }

      }

      // constant data
      this.setState({

        expectedLoad_renderObj: this.getDaysData_expectedLoad_preload(),

        loading: false,
        reloading: false,
        reloadingPublish: false,

      });

    });

  }

  getPlanAccounts(accounts, dailyAvailability, dailyUnavailability, standardAvailability, standardUnavailability, school, schoolHolidays, holidays, holidayRequests, planData, repeatingAvailability, absence) {

    let planAccounts = {};

    // open shifts
    planAccounts['null'] = {
      mon: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
      tue: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
      wed: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
      thu: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
      fri: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
      sat: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
      sun: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] }
    };
    for (const plan of planData) {
      if (Constants.isEmpty(plan.rooster_info_id) === true) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts['null'][dayKey].plans.push(plan);
      }
    }

    // // status unavailable user
    // for(const acc of accounts) {

    //   if (acc.info2_status === '2') {
    //     Constants.removeFromArray2(accounts, 'info_id', acc.info_id);
    //   }

    // }

    for (const acc of accounts) {

      if (Constants.isEmpty(planAccounts[acc.info_id])) {

        planAccounts[acc.info_id] = {
          mon: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
          tue: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
          wed: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
          thu: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
          fri: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
          sat: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] },
          sun: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], holidayRequests: [], absence: [], plans: [] }
        };

      }

      for (const plan of planData) {
        if (plan.rooster_info_id === acc.info_id) {
          const date = Constants.stringToDate(plan.rooster_datum);
          const day = date.getDay();
          const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
          planAccounts[acc.info_id][dayKey].plans.push(plan);
        }
      }

      for (const av of holidays) {
        if (av.vakantie_info_id === acc.info_id) {

          const startDate = Constants.stringToDate(av.vakantie_begin);
          const endDate = Constants.stringToDate(av.vakantie_eind);

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
            // const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            // const day = currentDate.getDay();
            // const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            // planAccounts[acc.info_id][dayKey].holidays.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            // index++;

            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            const day = currentDate.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            if (dateString === Constants.dateToString(currentDate)) {
              planAccounts[acc.info_id][dayKey].holidays.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            }

            index++;

          }

        }
      }

      for (const av of holidayRequests) {
        if (av.vakantie_info_id === acc.info_id) {

          const startDate = Constants.stringToDate(av.vakantie_begin);
          const endDate = Constants.stringToDate(av.vakantie_eind);

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            const day = currentDate.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            if (dateString === Constants.dateToString(currentDate)) {
              planAccounts[acc.info_id][dayKey].holidayRequests.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            }

            index++;

          }

        }
      }

      for (const abs of absence) {
        if (abs.afwn_info_id === acc.info_id) {

          const date = Constants.stringToDate(abs.afwn_datum);
          const day = date.getDay();
          const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
          const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

          if (dateString === abs.afwn_datum) {
            planAccounts[acc.info_id][dayKey].absence.push(abs);
          }

        }
      }

      // DAILY
      if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        for (const av of dailyAvailability) {
          if (av.beschikbaar_info_id === acc.info_id) {
            const date = Constants.stringToDate(av.beschikbaar_datum);
            const day = date.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].av.push(av);
          }
        }

        for (const av of dailyUnavailability) {
          if (av.onbeschikbaar_info_id === acc.info_id) {
            const date = Constants.stringToDate(av.onbeschikbaar_datum);
            const day = date.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].unav.push(av);
          }
        }

      // STANDARD
      } else if (Data.data.appOptions.availability === 'STANDARD') {

        for (const av of standardAvailability) {
          if (av.sb_info_id === acc.info_id) {
            const day = parseInt(av.sb_dag);
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].sav.push(av);
          }
        }

        for (const av of standardUnavailability) {
          if (av.sb_info_id === acc.info_id) {
            const day = parseInt(av.sb_dag);
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].sunav.push(av);
          }
        }

      // SCHOOL
      } else if (Data.data.appOptions.availability === 'SCHOOL') {

        for (const av of dailyAvailability) {
          if (av.beschikbaar_info_id === acc.info_id) {
            const date = Constants.stringToDate(av.beschikbaar_datum);
            const day = date.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].av.push(av);
          }
        }

        for (const av of dailyUnavailability) {
          if (av.onbeschikbaar_info_id === acc.info_id) {
            const date = Constants.stringToDate(av.onbeschikbaar_datum);
            const day = date.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].unav.push(av);
          }
        }

        for (const av of school) {
          if (av.schoolrooster_info_id === acc.info_id) {
            const day = parseInt(av.schoolrooster_dag);
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[acc.info_id][dayKey].school.push(av);
          }
        }

        for (const av of schoolHolidays) {
          if (av.schoolvakantie_info_id === acc.info_id) {

            const startDate = Constants.stringToDate(av.schoolvakantie_begin);
            const endDate = Constants.stringToDate(av.schoolvakantie_eind);

            let index = 0;
            while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
              const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
              const day = currentDate.getDay();
              const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
              const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

              if (dateString === Constants.dateToString(currentDate)) {
                planAccounts[acc.info_id][dayKey].schoolHoliday.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
              }
              index++;
            }

          }
        }

      }

      for (const r of repeatingAvailability) {

        if (r.hb_info_id === acc.info_id) {

          for (const dayKey in planAccounts[r.hb_info_id]) {

            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            const date1 = Constants.stringToDate(dateString);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval) {

              if (r.hb_beschikbaar === '1' && planAccounts[acc.info_id][dayKey].av.length === 0) {

                const _av = {
                  beschikbaar_info_id: r.hb_info_id,
                  beschikbaar_datum: dateString,
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                };

                planAccounts[r.hb_info_id][dayKey].av.push(_av);

              } else if (r.hb_beschikbaar === '2' && planAccounts[acc.info_id][dayKey].unav.length === 0) {

                const _av = {
                  onbeschikbaar_info_id: r.hb_info_id,
                  onbeschikbaar_datum: dateString,
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                };

                planAccounts[r.hb_info_id][dayKey].unav.push(_av);

              }

            }

          }

        }

      }

    }

    return planAccounts;

  }

  getPlanList(dailyAvailability, dailyUnavailability, standardAvailability, standardUnavailability, school, schoolHolidays, holidays, planData, repeatingAvailability) {

    let planList = {
      mon: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] },
      tue: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] },
      wed: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] },
      thu: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] },
      fri: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] },
      sat: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] },
      sun: { isLoading: false, av: [], unav: [], sav: [], sunav: [], school: [], schoolHoliday: [], holidays: [], plans: [] }
    };

    for (const plan of planData) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    for (const av of holidays) {

      const startDate = Constants.stringToDate(av.vakantie_begin);
      const endDate = Constants.stringToDate(av.vakantie_eind);

      let index = 0;
      while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

        const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
        const day = currentDate.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

        if (dateString === Constants.dateToString(currentDate)) {
          planList[dayKey].holidays.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
        }

        index++;

      }

    }

     // DAILY
     if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      for (const av of dailyAvailability) {
        const date = Constants.stringToDate(av.beschikbaar_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].av.push(av);
      }

      for (const av of dailyUnavailability) {
        const date = Constants.stringToDate(av.onbeschikbaar_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].unav.push(av);
      }

    // STANDARD
    } else if (Data.data.appOptions.availability === 'STANDARD') {

      for (const av of standardAvailability) {
        const day = parseInt(av.sb_dag);
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].sav.push(av);
      }

      for (const av of standardUnavailability) {
        const day = parseInt(av.sb_dag);
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].sunav.push(av);
      }

    // SCHOOL
    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      for (const av of dailyAvailability) {
        const date = Constants.stringToDate(av.beschikbaar_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].av.push(av);
      }

      for (const av of dailyUnavailability) {
        const date = Constants.stringToDate(av.onbeschikbaar_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].unav.push(av);
      }

      for (const av of school) {
        const day = parseInt(av.schoolrooster_dag);
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].school.push(av);
      }

      for (const av of schoolHolidays) {

        const startDate = Constants.stringToDate(av.schoolvakantie_begin);
        const endDate = Constants.stringToDate(av.schoolvakantie_eind);

        let index = 0;
        while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
          const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
          const day = currentDate.getDay();
          const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
          const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

          if (dateString === Constants.dateToString(currentDate)) {
            planList[dayKey].schoolHoliday.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
          }

          index++;
        }

      }

    }

    for (const r of repeatingAvailability) {

      for (const dayKey in planList) {

        const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

        const date1 = Constants.stringToDate(dateString);
        const date2 = Constants.stringToDate(r.hb_datum);
        const interval = parseInt(r.hb_interval);
        const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
        const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
        const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
        const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

        if (isInInterval) {

          if (r.hb_beschikbaar === '1' && Constants.objectArrayContainsKey(planList[dayKey].av, 'beschikbaar_info_id', r.hb_info_id) === false) {

            const _av = {
              ...r,
              beschikbaar_info_id: r.hb_info_id,
              beschikbaar_datum: dateString,
              beschikbaar_begin: r.hb_begin,
              beschikbaar_eind: r.hb_eind,
              beschikbaar_opmerking: r.hb_opmerking,
            };

            planList[dayKey].av.push(_av);

          } else if (r.hb_beschikbaar === '2' && Constants.objectArrayContainsKey(planList[dayKey].unav, 'onbeschikbaar_info_id', r.hb_info_id) === false) {

            const _av = {
              ...r,
              onbeschikbaar_info_id: r.hb_info_id,
              onbeschikbaar_datum: dateString,
              onbeschikbaar_begin: r.hb_begin,
              onbeschikbaar_eind: r.hb_eind,
              onbeschikbaar_opmerking: r.hb_opmerking,
            };

            planList[dayKey].unav.push(_av);

          }

        }

        planList[dayKey].av.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind > b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.beschikbaar_id > b.beschikbaar_id)) ? 1 : -1);
        planList[dayKey].unav.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind > b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.onbeschikbaar_id > b.onbeschikbaar_id)) ? 1 : -1);

      }

    }

    return planList;

  }

  getAllPlansUser(accounts, planData) {

    let allPlansUser = {};

    allPlansUser['null'] = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: []
    };

    for (const acc of accounts) {

      allPlansUser[acc.info_id] = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
      };

    }

    for (const plan of planData) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      allPlansUser[plan.rooster_info_id][dayKey].push(plan);
    }

    return allPlansUser;

  }

  getJobs(jobs, jobsArrange) {

    let newJobs = [];

    // if(this.state.section !== null) {

    //   for(const _function of jobs) {

    //     if(parseInt(_function.functie_afdeling_id) === this.state.section) {
    //       newJobs.push(_function);
    //     }

    //   }

    // } else {

    for(const _function of jobs) {
      newJobs.push(_function);
    }

    // }

    // new sort
    if (jobsArrange.length > 0) {

      for (const arr of jobsArrange) {

        for (const func of newJobs) {

          if (func.functie_id === arr.fv_functie_id && Constants.objectArrayContainsKey(newJobs, 'functie_id', arr.fv_functie_id)) {
            let index = newJobs.indexOf(func);
            newJobs.splice(index, 1);
            newJobs.splice(parseInt(arr.fv_index), 0, func);
          }

        }

      }

    }

    return newJobs;

  }

  getEmptyRowsUser(accounts) {

    let rowsUsers = {};

    // open shifts
    rowsUsers['null'] = 0;

    for (const acc of accounts) {

      if (Constants.isEmpty(rowsUsers[acc.info_id])) {
        rowsUsers[acc.info_id] = 0;
      }

    }

    return rowsUsers;

  }

  getEmptyRowsJob(jobs) {

    let rowsUsers = {};

    for (const job of jobs) {

      if (Constants.isEmpty(rowsUsers[job.functie_id])) {
        rowsUsers[job.functie_id] = 0;
      }

    }

    return rowsUsers;

  }

  getDayShifts(dayShifts, usedDayShifts) {

    let dayShiftsObj = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: []
    };

    loop1:
    for (const shift of dayShifts) {

      const day = parseInt(shift.dagdienst_dag);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      // USED DAYSHIFTS
      for (const usedDayShift of usedDayShifts) {
        if (dateString === usedDayShift.gdm_datum && shift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
          if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
            if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
              continue loop1;
            }
          } else {
            continue loop1;
          }
        }
      }

      dayShiftsObj[dayKey].push(shift);

    }

    return dayShiftsObj;

  }

  getTurnOverActual_inputs(turnover) {

    let inputs = {
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '',
      sun: ''
    };

    for (const t of turnover) {

      const date = Constants.stringToDate(t.omzetd_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

      inputs[dayKey] = isNaN(parseFloat(t.omzetd_hoeveelheid)) ? 0 : parseFloat(t.omzetd_hoeveelheid);

    }

    return inputs;

  }

  getTurnOverExpected_inputs(turnover) {

    let inputs = {
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '',
      sun: ''
    };

    for (const t of turnover) {

      const date = Constants.stringToDate(t.omzetv_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

      inputs[dayKey] = isNaN(parseFloat(t.omzetv_hoeveelheid)) ? 0 : parseFloat(t.omzetv_hoeveelheid);

    }

    return inputs;

  }

  getExpectedLoadMinMax(plans_last3Weeks) {

    let expectedLoadMinMax = {
      mon: { min: 24, max: 0 },
      tue: { min: 24, max: 0 },
      wed: { min: 24, max: 0 },
      thu: { min: 24, max: 0 },
      fri: { min: 24, max: 0 },
      sat: { min: 24, max: 0 },
      sun: { min: 24, max: 0 },
    };

    for (const dayKey in plans_last3Weeks) {
      for (const plan of plans_last3Weeks[dayKey]) {

        const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
        const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
        if (planStart < expectedLoadMinMax[dayKey].min) {
          expectedLoadMinMax[dayKey].min = planStart;
        }
        if (planEnd > expectedLoadMinMax[dayKey].max) {
          expectedLoadMinMax[dayKey].max = planEnd;
        }

      }
    }

    // for (const userKey in planAccounts) {
    //   for (const dayKey in planAccounts[userKey]) {
    //     for (const plan of planAccounts[userKey][dayKey].plans) {

    //       const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
    //       const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
    //       if (planStart < expectedLoadMinMax[dayKey].min) {
    //         expectedLoadMinMax[dayKey].min = planStart;
    //       }
    //       if (planEnd > expectedLoadMinMax[dayKey].max) {
    //         expectedLoadMinMax[dayKey].max = planEnd;
    //       }

    //     }
    //   }
    // }

    return expectedLoadMinMax;

  }  

  getOverhoursData(absenceAll, hours, absAmount, scheduleAbsence, clocked, schedules, overhoursCorrectionsData, breakRules) {

    let newHours = {};
    let absAmountObj = {};
    let scheduleAbsenceObj = {};

    // OVERHOURS DATA
     // CLOCKED DATA INTO UREN DB
     if (Data.data.appOptions.hours === 'CLOCK') {
      for (const data of clocked) {

        let _break = data.kloktijd_pauze.substr(0, 8);
        if (Data.data.appOptions.brk === 'PLANNER') {
          _break = Constants.getUserPlannerBreak(data.info_id, data.kloktijd_inklok_datum, schedules);
        } else if (Data.data.appOptions.brk === 'CALC') {
          _break = Constants.getBreakRule(data.kloktijd_inklok_datum, data.kloktijd_inklok_tijd, data.kloktijd_uitklok_datum, data.kloktijd_uitklok_tijd, null, breakRules).str.substr(0, 5);
        }

        hours.push({
          ...data,
          uren_id: null,
          uren_begindatum: data.kloktijd_inklok_datum,
          uren_einddatum: data.kloktijd_uitklok_datum,
          uren_begin: data.kloktijd_inklok_tijd,
          uren_eind: data.kloktijd_uitklok_tijd,
          uren_pauze: _break,
        });

      }
    } else if (Data.data.appOptions.hours === 'PLANNER') {
      for (const data of schedules) {

        let _break = data.rooster_pauze;
        if (Data.data.appOptions.brk === 'CALC') {
          _break = Constants.getBreakRule(data.rooster_datum, data.rooster_begin, data.rooster_datum, data.rooster_eind, data, breakRules).str.substr(0, 5);
        }

        hours.push({
          ...data,
          uren_id: null,
          uren_begindatum: data.rooster_datum,
          uren_einddatum: Plan.isNightShift2(data),
          uren_begin: data.rooster_begin,
          uren_eind: data.rooster_eind,
          uren_pauze: _break,
        });

      }
    }

    // HOURS (UREN DB)
    for (const data of hours) {

      const hourDate = Constants.stringToDate(data.uren_begindatum);
      const week = Constants.getWeekNumber2(hourDate);
      const year = hourDate.getFullYear();
      const yearKey = year.toString();
      const userKey = data.info_id;

      // CREATE USER OBJECT
      if (Constants.isEmpty(newHours[userKey])) {
        newHours[userKey] = {
          user: data.info_id,
          userFullName: `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`,
          data: {},
        };
      }

      // CREATE YEAR OBJECT
      if (Constants.isEmpty(newHours[userKey].data[yearKey])) {
        newHours[userKey].data[yearKey] = [];
      }

      // CREATE WEEK ITEM
      if (Constants.objectArrayContainsKey(newHours[userKey].data[yearKey], 'week', week) === false) {
        newHours[userKey].data[yearKey].push({
          week: week,
          weekData: [],
        });
      }

      const weekItem = Constants.getArrayItem(newHours[userKey].data[yearKey], 'week', week);

      if (weekItem !== null) {
        weekItem.weekData.push(data);
      }

    }

    // ABS
    for (const abs of absAmount) {

      const date = Constants.stringToDate(abs.vakantie_begin);
      const week = Constants.getWeekNumber2(date);
      const weekKey = week.toString();
      const year = date.getFullYear();
      const yearKey = year.toString();
      const userKey = abs.vakantie_info_id;

      if (Constants.isEmpty(absAmountObj[userKey])) {
        absAmountObj[userKey] = {};
      }
      if (Constants.isEmpty(absAmountObj[userKey][yearKey])) {
        absAmountObj[userKey][yearKey] = {};
      }
      if (Constants.isEmpty(absAmountObj[userKey][yearKey][weekKey])) {
        absAmountObj[userKey][yearKey][weekKey] = [];
      }

      absAmountObj[userKey][yearKey][weekKey].push(abs);

    }

    // SCHEDULE ABSENTS
    for (const data of absenceAll) { // absence_n

      const date = Constants.stringToDate(data.afwn_datum);
      const week = Constants.getWeekNumber2(date);
      const weekKey = week.toString();
      const year = date.getFullYear();
      const yearKey = year.toString();
      const userKey = data.afwn_info_id;

      if (Constants.isEmpty(scheduleAbsenceObj[userKey])) {
        scheduleAbsenceObj[userKey] = {};
      }
      if (Constants.isEmpty(scheduleAbsenceObj[userKey][yearKey])) {
        scheduleAbsenceObj[userKey][yearKey] = {};
      }
      if (Constants.isEmpty(scheduleAbsenceObj[userKey][yearKey][weekKey])) {
        scheduleAbsenceObj[userKey][yearKey][weekKey] = [];
      }

      scheduleAbsenceObj[userKey][yearKey][weekKey].push(data);

    }
    // for (const data of scheduleAbsence) {

    //   const date = Constants.stringToDate(data.rooster_datum);
    //   const week = Constants.getWeekNumber2(date);
    //   const weekKey = week.toString();
    //   const year = date.getFullYear();
    //   const yearKey = year.toString();
    //   const userKey = data.rooster_info_id;

    //   if (Constants.isEmpty(scheduleAbsenceObj[userKey])) {
    //     scheduleAbsenceObj[userKey] = {};
    //   }
    //   if (Constants.isEmpty(scheduleAbsenceObj[userKey][yearKey])) {
    //     scheduleAbsenceObj[userKey][yearKey] = {};
    //   }
    //   if (Constants.isEmpty(scheduleAbsenceObj[userKey][yearKey][weekKey])) {
    //     scheduleAbsenceObj[userKey][yearKey][weekKey] = [];
    //   }

    //   scheduleAbsenceObj[userKey][yearKey][weekKey].push(data);

    // }

    // newHours.sort((a, b) => (a.userFullName > b.userFullName) ? 1 : -1);

    // SORT DAY DATA WEEKS (UREN)
    for (const key in newHours) {
      for (const yearKey in newHours[key].data) {
        newHours[key].data[yearKey].sort((a, b) => (a.week > b.week) ? -1 : 1);
        for (let _weekData of newHours[key].data[yearKey]) {
          _weekData.weekData.sort((a, b) => ((a.uren_begindatum > b.uren_begindatum) || (a.uren_begindatum === b.uren_begindatum && a.uren_begin > b.uren_begin)) ? 1 : -1);
        }
      }
    }

    // CORRECTIONS
    let overhoursCorrections = {};
    for (const correction of overhoursCorrectionsData) {

      const date = Constants.stringToDate(correction.co_datum);
      const year = date.getFullYear();
      const yearKey = year.toString();

      if (typeof overhoursCorrections[correction.co_info_id] === 'undefined') {
        overhoursCorrections[correction.co_info_id] = {};
        overhoursCorrections[correction.co_info_id][new Date().getFullYear().toString()] = {
          sep: [],
          total: 0,
          totalAT: 0,
        };
      }

      if (typeof overhoursCorrections[correction.co_info_id][yearKey] === 'undefined') {
        overhoursCorrections[correction.co_info_id][yearKey] = {
          sep: [],
          total: 0,
          totalAT: 0,
        };
      }

      overhoursCorrections[correction.co_info_id][yearKey].sep.push(correction);
      overhoursCorrections[correction.co_info_id][yearKey].total += parseFloat(correction.co_aantal);

    }

    for (const userKey in overhoursCorrections) {
      let yearCorrections = 0;
      for (const yearKey in overhoursCorrections[userKey]) {
        overhoursCorrections[userKey][yearKey].totalAT += (parseFloat(overhoursCorrections[userKey][yearKey].total) + yearCorrections);
        yearCorrections += overhoursCorrections[userKey][yearKey].total;
      }
    }

    return {
      schedule2: scheduleAbsenceObj, 
      absAmount2: absAmountObj, 
      hoursWorked: newHours, 
      overhours_corrections: overhoursCorrections,
    };

  }

  renderUserOverhours(userID) {

    const allHoursData = this.state.user_hoursData[userID];

    if (Constants.isEmpty(allHoursData) === true) {
      return 0;
    }

    let overhours = 0;

    if (Constants.isEmpty(allHoursData.totalOverhours) === false) {

      for (const yearKey in allHoursData.totalOverhours) {

        const year = parseInt(yearKey);

        // actual overhours year
        if (year <= this.state.year) {

          for (const weekKey in allHoursData.totalOverhours[yearKey]) {

            const week = parseInt(weekKey);

            // actual overhours wear
            if (year < this.state.year || (week < this.state.week && year === this.state.year)) {

              overhours += allHoursData.totalOverhours[yearKey][weekKey];

            }

          }

        }

      }

    }

    if (Constants.isEmpty(allHoursData.overhoursCorrections) === false) {

      for (const c of allHoursData.overhoursCorrections) {

        const date = Constants.stringToDate(c.co_datum);
        const year = date.getFullYear();
        const amount = parseFloat(c.co_aantal);

        // actual years
        if (year <= this.state.year) {
          overhours += (amount * 1000 * 60 * 60);
        }

      }

    }

    return overhours;

  }

  renderUsersTableDisplay() {

    let toRender = [];

    let fid = '';

    // USERS WITH FUNCTION
    for (const func of this.state.jobs) {

      if (this.state.section !== null && func.functie_afdeling_id !== this.state.section) {
        continue;
      }

      if (fid !== func.functie_id) {

        toRender.push(
          <div>
            {this.renderUsersTableDisplay_jobs(func)}
          </div>
        );

        fid = func.functie_id;

      }

    }

    let usersNoJob = false;
    for (const user of this.state.accounts) {
      if (Constants.isEmpty(user.functie_id) === true) {
        usersNoJob = true;
        break;
      }
    }

    if (this.state.section === null && usersNoJob === true) {
      toRender.push(
        <div>
          {this.renderUsersTableDisplay_noJobs()}
        </div>
      );
    }

    return toRender;

  }

  renderUsersTableDisplay_jobs(func) {

    let toRender = [];

    // toRender.push(
    //   <div className="plannerZ_jobContainer plannerZ_stickyDayShifts">

    //     {this.renderUsersTableDisplay_dayShifts(func)}

    //     <div className="plannerZ_hideBorderBottom" />

    //   </div>
    // );

    if (Constants.isEmpty(this.state.jobsCollapsed[func.functie_id]) === false && this.state.jobsCollapsed[func.functie_id] === true) {
      
      toRender.push(
        <div className="plannerZ_jobContainer">

          <div style={{ width: '100%', fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{func.functie_naam}</span>
            <i className='far fa-square-plus' style={{ cursor: 'pointer', fontSize: '1vw' }} onClick={() => this.collapseJob(func)} />
          </div>

          {/* {this.renderUsersTableDisplay_users(func)}

          {this.renderUsersTableDisplay_openShifts(func)}

          {this.renderUsersTableDisplay_dayShifts(func)}

          <div className="plannerZ_hideBorderBottom" /> */}

        </div>
      );

    } else {

      toRender.push(
        <div className="plannerZ_jobContainer">

          <div style={{ width: '100%', fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{func.functie_naam}</span>
            <i className='fal fa-square-minus' style={{ cursor: 'pointer', fontSize: '1vw' }} onClick={() => this.collapseJob(func)} />
          </div>

          {this.renderUsersTableDisplay_users(func)}

          {this.renderUsersTableDisplay_openShifts(func)}

          {this.renderUsersTableDisplay_dayShifts(func)}

          <div className="plannerZ_hideBorderBottom" />

        </div>
      );

    }

    return toRender;

  }

  renderUsersTableDisplay_noJobs() {

    let toRender = [];

    // toRender.push(
    //   <div className="plannerZ_jobContainer plannerZ_stickyDayShifts">

    //     {this.renderUsersTableDisplay_dayShifts(func)}

    //     <div className="plannerZ_hideBorderBottom" />

    //   </div>
    // );

    toRender.push(
      <div className="plannerZ_jobContainer">

        <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw' }}>{__('planner_web_screen_usersNoJob')}</div>

        {this.renderUsersTableDisplay_users(null)}

        <div className="plannerZ_hideBorderBottom" />

      </div>
    );

    return toRender;

  }

  renderUsersTableDisplay_users(func) {

    let toRender = [];

    let usersList = [];

    // FILL USERSLIST
    for (const acc of this.state.accounts) {
      usersList.push({ ...acc });
    }

    // NEW ARRANGEMENT
    if (this.state.plannerArrange.length > 0) {

      for (const arr of this.state.plannerArrange) {

        for (const user of usersList) {

          if (user.info_id === arr.pv_info_id && Constants.objectArrayContainsKey(usersList, 'info_id', arr.pv_info_id)) {
            let index = usersList.indexOf(user);
            usersList.splice(index, 1);
            usersList.splice(parseInt(arr.pv_index), 0, user);
          }

        }

      }

    }

    for (const user of usersList) {

      if (Constants.isEmpty(func) === false && user.functie_id !== func.functie_id) {
        continue;
      }

      if (Constants.isEmpty(func) === true && Constants.isEmpty(user.functie_id) === false) {
        continue;
      }

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
      const plannedHoursWeek = this.getPlannedHoursWeek(user);
      const currentContract = Constants.getCurrentContract(this.state.contracts[user.info_id], Constants.stringToDate(this.state.mondayDate));

      // const overhours = this.getOverhoursUser(user.info_id);

      // let overhoursText = null;
      // let negative = false;

      // if (Constants.isTrue(Data.data.appOptions.opties_overuren) && overhours !== null) {

      //   overhoursText = isNaN(overhours.balanceEnd) || Constants.msToTime2(overhours.balanceEnd) === '00:00' ? Constants.msToTime2(0) : overhours.balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(overhours.balanceEnd)) : overhours.balanceEnd !== 0 ? '+' + Constants.msToTime2(overhours.balanceEnd) : Constants.msToTime2(0);
      //   negative = overhours.balanceEnd < 0;

      //   if (overhoursText === '00:00') {
      //     overhoursText = null;
      //   }

      // }

      const overhours = this.renderUserOverhours(user.info_id);

      const menuOpen = Constants.isEmpty(this.state.menu_employee_open[user.info_id]) ? false : this.state.menu_employee_open[user.info_id];

      toRender.push(

        <div className="plannerZ_employeeContainer">

          <div className="plannerZ_employeeInfo">

            <div className="plannerZ_employeeInfo_sub3">

              {Constants.isEmpty(user.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${user.info2_profielfoto}`} style={{ width: '1.8vw', height: '1.8vw', borderRadius: '2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.8vw', height: '1.8vw', borderRadius: '2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.7vw' }} /></p>
                </div>
              }

            </div>

            <div className="plannerZ_employeeInfo_sub1">

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>

                <span className="plannerZ_employeeInfo_sub1_name" onClick={() => Constants.isEmpty(user) || Constants.isEmpty(user.info_id) ? null : this.openEmployeeMenu(user)}>{userFullName}</span>

                {currentContract !== null && overhours !== 0 ?
                  // <span className="plannerZ_employeeInfo_sub1_details" style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                  overhours < 0 ? <span className='plannerZ_employeeInfo_sub1_details' style={{ color: Colors.color.greenFilledIn }}>-{Constants.msToTime2(Math.abs(overhours))}</span> : overhours === 0 ? <span className='plannerZ_employeeInfo_sub1_details'>{Constants.msToTime2(Math.abs(overhours))}</span> : <span className='plannerZ_employeeInfo_sub1_details' style={{ color: Colors.color.redFilledIn }}>+{Constants.msToTime2(Math.abs(overhours))}</span>
                : null}

                {/* {currentContract !== null && currentContract.contract_type === '1' ? // min-max
                  <span className="plannerZ_employeeInfo_sub1_details"><i /> {}</span>
                :
                currentContract !== null && currentContract.contract_type === '2' ? // vast
                  <span className="plannerZ_employeeInfo_sub1_details"><i className='fal fa-file-contract' aria-hidden={true} /> {Constants.msToTime2(parseFloat(currentContract.contract_uren) * 60 * 60 * 1000)}</span>
                : null} */}

              </div>

              <p>
                {this.state.addEmptyRowsUser[user.info_id] > 0 ?
                  <div><i className='fas fa-minus-circle plannerZ_plusIcon' aria-hidden={true} onClick={() => this.removeEmptyRowUser(user)} /></div>
                : null}
                <div><i className='fas fa-plus-circle plannerZ_plusIcon' aria-hidden={true} onClick={() => this.addEmptyRowUser(user)} /></div>
              </p>

            </div>

            <div className="plannerZ_employeeInfo_sub2">

              <span>{Constants.msToTime2(plannedHoursWeek)}</span>

              {currentContract !== null && currentContract.contract_type === '1' ? // min-max
                <span className="plannerZ_employeeInfo_sub2_details"><span className="plannerZ_employeeInfo_sub2_details" style={{ color: plannedHoursWeek < (parseFloat(currentContract.contract_uren) * 60 * 60 * 1000) ? Colors.color.redFilledIn : null }}>{Constants.msToTime2(parseFloat(currentContract.contract_uren) * 60 * 60 * 1000)}</span> | <span className="plannerZ_employeeInfo_sub2_details" style={{ color: plannedHoursWeek > (parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000) ? Colors.color.redFilledIn : null }}>{Constants.msToTime2(parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000)}</span></span>
              :
              currentContract !== null && currentContract.contract_type === '2' ? // vast
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {/* <i className='fal fa-file-contract' aria-hidden={true} /> */}
                  <span className="plannerZ_employeeInfo_sub2_details" style={{ color: plannedHoursWeek > (parseFloat(currentContract.contract_uren) * 60 * 60 * 1000) ? Colors.color.redFilledIn : null }}>{Constants.msToTime2(parseFloat(currentContract.contract_uren) * 60 * 60 * 1000)}</span>
                </div>
              : null}

            </div>

          </div>

          {this.renderUsersTableDisplay_days(user)}

          {menuOpen === true ?
            this.renderEmployeeMenu(user, false, false)
          : null}

        </div>

      );

    }

    return toRender;

  }

  renderUsersTableDisplay_days(user) {

    let toRender = [];

    //////////////////////////////////////////
    // Get highest row count of column(day)
    let highest = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    const userData = this.state.planAccounts[user.info_id];

    if (Constants.isEmpty(userData) === false) {
      for (const dayKey in userData) {
        for (const plan of userData[dayKey].plans) {
          if (plan.rooster_info_id === user.info_id) {
            highest[dayKey] += 1;
          }
        }
      }
    }

    let highestLength = 0;
    for (const key in highest) {
      if (highest[key] > highestLength) {
        highestLength = highest[key];
      }
    }

    if (highestLength === 0) {
      highestLength = highestLength + 1;
    }

    highestLength = highestLength + this.state.addEmptyRowsUser[user.info_id];
    ////////////////////////////////////////////

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';

      const av = userData[dayKey];

      const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
      const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);
      const absence = av.absence.length > 0;

      toRender.push(
        <div 
          className="plannerZ_dayContainer"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          style={{ backgroundColor: absence ? Constants.hexToRgbA(Colors.color.redFilledIn, 0.15) : holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.8) : Constants.isTrue(user[info_werkdag]) === false ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.4) : null }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null}
          {this.renderTouchBox(day, user, highestLength)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersTableDisplay_openShifts(func) {

    let toRender = [];

    const user = {
      info_id: null,
      functie_id: func.functie_id,
      functie_naam: func.functie_naam,
      functie_kleur: func.functie_kleur,
    };

    toRender.push(

      <div className="plannerZ_employeeContainer">

        <div className="plannerZ_employeeInfo">

          <div className="plannerZ_employeeInfo_sub1" style={{ flex: 4 }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>

              <span className='plannerZ_employeeInfo_sub1_name' style={{ fontWeight: 700 }}>{__('options_web_screen_openShifts')}</span>

            </div>

            <p>
              {this.state.addEmptyRowsUser[user.info_id] > 0 ?
                <div><i className='fas fa-minus-circle plannerZ_plusIcon' aria-hidden={true} onClick={() => this.removeEmptyRowUser(user)} /></div>
              : null}
              <div><i className='fas fa-plus-circle plannerZ_plusIcon' aria-hidden={true} onClick={() => this.addEmptyRowUser(user)} /></div>
            </p>

          </div>

          <div className="plannerZ_employeeInfo_sub2">



          </div>

        </div>

        {this.renderUsersTableDisplay_openShifts_days(func)}

      </div>

    );

    return toRender;

  }

  renderUsersTableDisplay_openShifts_days(func) {

    let toRender = [];

    //////////////////////////////////////////
    // Get highest row count of column(day)
    let highest = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    const user = {
      info_id: null,
      functie_id: func.functie_id,
      functie_naam: func.functie_naam,
      functie_kleur: func.functie_kleur,
    };

    const userData = this.state.planAccounts['null'];

    if (Constants.isEmpty(userData) === false) {
      for (const dayKey in userData) {
        for (const plan of userData[dayKey].plans) {
          if (plan.rooster_info_id === user.info_id && plan.functie_id === func.functie_id) {
            highest[dayKey] += 1;
          }
        }
      }
    }

    let highestLength = 0;
    for (const key in highest) {
      if (highest[key] > highestLength) {
        highestLength = highest[key];
      }
    }

    if (highestLength === 0) {
      highestLength = highestLength + 1;
    }

    highestLength = highestLength + this.state.addEmptyRowsUser[user.info_id];
    ////////////////////////////////////////////

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      const av = userData[dayKey];

      toRender.push(
        <div 
          className="plannerZ_dayContainer"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          style={{  }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {this.state.planAccounts[user.info_id][dayKey].isLoading === func.functie_id ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null}
          {this.renderTouchBox(day, user, highestLength)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersTableDisplay_dayShifts(func) {

    let count = 0;

    for (const dayKey in this.state.dayShifts) {
      for (const shift of this.state.dayShifts[dayKey]) {
        if (shift.functie_id === func.functie_id && shift.dagdienst_sjabloon === this.state.dayShiftsTemplate) {
          count++;
        }
      }
    }
 
    if (count > 0) {

      return (

        <div className="plannerZ_employeeContainer">

          <div className="plannerZ_employeeInfo">

            <div className="plannerZ_employeeInfo_sub1">

              {/* {func.functie_naam} */}
              <span className='plannerZ_employeeInfo_sub1_name' style={{ fontWeight: 700 }}>{__('misc_defaultShifts')}</span>

            </div>

          </div>

          {this.renderUsersTableDisplay_dayShifts_days(func)}

        </div>

      );

    } else {
      return (null);
    }

  }

  renderUsersTableDisplay_dayShifts_days(func) {

    let toRender = [];

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      toRender.push(
        <div 
          className="plannerZ_dayContainer"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          // style={{ backgroundColor: holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(contract) ? Constants.hexToRgbA(Colors.color.pastBorderColor, 0.15) : null }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {/* {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null} */}
          {this.renderTouchBox_dayShift(day, func)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersExcelDisplay() {

    let toRender = [];

    let fid = '';

    // USERS WITH FUNCTION
    for (const func of this.state.jobs) {

      if (this.state.section !== null && func.functie_afdeling_id !== this.state.section) {
        continue;
      }

      if (fid !== func.functie_id) {

        toRender.push(
          <div>
            {this.renderUsersExcelDisplay_jobs(func)}
          </div>
        );

        fid = func.functie_id;

      }

    }

    let usersNoJob = false;
    for (const user of this.state.accounts) {
      if (Constants.isEmpty(user.functie_id) === true) {
        usersNoJob = true;
        break;
      }
    }

    if (this.state.section === null && usersNoJob === true) {
      toRender.push(
        <div>
          {this.renderUsersExcelDisplay_noJobs()}
        </div>
      );
    }

    return toRender;

  }

  renderUsersExcelDisplay_jobs(func) {

    let toRender = [];

    // toRender.push(
    //   <div className="plannerZ_jobContainer plannerZ_stickyDayShifts">

    //     {this.renderUsersTableDisplay_dayShifts(func)}

    //     <div className="plannerZ_hideBorderBottom" />

    //   </div>
    // );

    if (Constants.isEmpty(this.state.jobsCollapsed[func.functie_id]) === false && this.state.jobsCollapsed[func.functie_id] === true) {
      
      toRender.push(
        <div className="plannerZ_jobContainer">

          <div style={{ width: '100%', fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{func.functie_naam}</span>
            <i className='far fa-square-plus' style={{ cursor: 'pointer', fontSize: '1vw' }} onClick={() => this.collapseJob(func)} />
          </div>

          {/* {this.renderUsersExcelDisplay_users(func)}

          {this.renderUsersExcelDisplay_openShifts(func)}

          {this.renderUsersExcelDisplay_dayShifts(func)}

          <div className="plannerZ_hideBorderBottom" /> */}

        </div>
      );

    } else {

      toRender.push(
        <div className="plannerZ_jobContainer">

          <div style={{ width: '100%', fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{func.functie_naam}</span>
            <i className='fal fa-square-minus' style={{ cursor: 'pointer', fontSize: '1vw' }} onClick={() => this.collapseJob(func)} />
          </div>

          {this.renderUsersExcelDisplay_users(func)}

          {this.renderUsersExcelDisplay_openShifts(func)}

          {this.renderUsersExcelDisplay_dayShifts(func)}

          <div className="plannerZ_hideBorderBottom" />

        </div>
      );

    }

    return toRender;

  }

  renderUsersExcelDisplay_noJobs() {

    let toRender = [];

    // toRender.push(
    //   <div className="plannerZ_jobContainer plannerZ_stickyDayShifts">

    //     {this.renderUsersTableDisplay_dayShifts(func)}

    //     <div className="plannerZ_hideBorderBottom" />

    //   </div>
    // );

    toRender.push(
      <div className="plannerZ_jobContainer">

        <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw' }}>{__('planner_web_screen_usersNoJob')}</div>

        {this.renderUsersExcelDisplay_users(null)}

        <div className="plannerZ_hideBorderBottom" />

      </div>
    );

    return toRender;

  }

  renderUsersExcelDisplay_users(func) {

    let toRender = [];

    let usersList = [];

    // FILL USERSLIST
    for (const acc of this.state.accounts) {
      usersList.push({ ...acc });
    }

    // NEW ARRANGEMENT
    if (this.state.plannerArrange.length > 0) {

      for (const arr of this.state.plannerArrange) {

        for (const user of usersList) {

          if (user.info_id === arr.pv_info_id && Constants.objectArrayContainsKey(usersList, 'info_id', arr.pv_info_id)) {
            let index = usersList.indexOf(user);
            usersList.splice(index, 1);
            usersList.splice(parseInt(arr.pv_index), 0, user);
          }

        }

      }

    }

    for (const user of usersList) {

      if (Constants.isEmpty(func) === false && user.functie_id !== func.functie_id) {
        continue;
      }

      if (Constants.isEmpty(func) === true && Constants.isEmpty(user.functie_id) === false) {
        continue;
      }

      const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
      const plannedHoursWeek = this.getPlannedHoursWeek(user);
      const currentContract = Constants.getCurrentContract(this.state.contracts[user.info_id], Constants.stringToDate(this.state.mondayDate));

      // const overhours = this.getOverhoursUser(user.info_id);

      // let overhoursText = null;
      // let negative = false;

      // if (Constants.isTrue(Data.data.appOptions.opties_overuren) && overhours !== null) {

      //   overhoursText = isNaN(overhours.balanceEnd) || Constants.msToTime2(overhours.balanceEnd) === '00:00' ? Constants.msToTime2(0) : overhours.balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(overhours.balanceEnd)) : overhours.balanceEnd !== 0 ? '+' + Constants.msToTime2(overhours.balanceEnd) : Constants.msToTime2(0);
      //   negative = overhours.balanceEnd < 0;

      //   if (overhoursText === '00:00') {
      //     overhoursText = null;
      //   }

      // }

      const overhours = this.renderUserOverhours(user.info_id);

      const menuOpen = Constants.isEmpty(this.state.menu_employee_open[user.info_id]) ? false : this.state.menu_employee_open[user.info_id];

      toRender.push(

        <div className="plannerZ_employeeContainer">

          <div className="plannerZ_employeeInfo_excel">

            <div className="plannerZ_employeeInfo_excel_sub3">

              {Constants.isEmpty(user.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${user.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }

            </div>

            <div className="plannerZ_employeeInfo_excel_sub1">

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>

                <span className="plannerZ_employeeInfo_excel_sub1_name" onClick={() => Constants.isEmpty(user) || Constants.isEmpty(user.info_id) ? null : this.openEmployeeMenu(user)}>{userFullName}</span>

                {currentContract !== null && overhours !== 0 ?
                  overhours < 0 ? <span className='plannerZ_employeeInfo_sub1_details' style={{ color: Colors.color.greenFilledIn }}>-{Constants.msToTime2(Math.abs(overhours))}</span> : overhours === 0 ? <span className='plannerZ_employeeInfo_sub1_details'>{Constants.msToTime2(Math.abs(overhours))}</span> : <span className='plannerZ_employeeInfo_sub1_details' style={{ color: Colors.color.redFilledIn }}>+{Constants.msToTime2(Math.abs(overhours))}</span>
                  // <span className="plannerZ_employeeInfo_excel_sub1_details" style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                : null}

                {/* {currentContract !== null && currentContract.contract_type === '1' ? // min-max
                  <span className="plannerZ_employeeInfo_sub1_details"><i /> {}</span>
                :
                currentContract !== null && currentContract.contract_type === '2' ? // vast
                  <span className="plannerZ_employeeInfo_sub1_details"><i className='fal fa-file-contract' aria-hidden={true} /> {Constants.msToTime2(parseFloat(currentContract.contract_uren) * 60 * 60 * 1000)}</span>
                : null} */}

              </div>

              <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
                {this.state.addEmptyRowsUser[user.info_id] > 0 ?
                  <div><i className='fas fa-minus-circle plannerZ_plusIcon' style={{ fontSize: '0.6vw' }} aria-hidden={true} onClick={() => this.removeEmptyRowUser(user)} /></div>
                : null}
                <div><i className='fas fa-plus-circle plannerZ_plusIcon' style={{ fontSize: '0.6vw' }} aria-hidden={true} onClick={() => this.addEmptyRowUser(user)} /></div>
              </p>

            </div>

            <div className="plannerZ_employeeInfo_excel_sub2">

              <span>{Constants.msToTime2(plannedHoursWeek)}</span>

              {currentContract !== null && currentContract.contract_type === '1' ? // min-max
                <span className="plannerZ_employeeInfo_excel_sub2_details"><span className="plannerZ_employeeInfo_sub2_details" style={{ color: plannedHoursWeek < (parseFloat(currentContract.contract_uren) * 60 * 60 * 1000) ? Colors.color.redFilledIn : null }}>{Constants.msToTime2(parseFloat(currentContract.contract_uren) * 60 * 60 * 1000)}</span> | <span className="plannerZ_employeeInfo_sub2_details" style={{ color: plannedHoursWeek > (parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000) ? Colors.color.redFilledIn : null }}>{Constants.msToTime2(parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000)}</span></span>
              :
              currentContract !== null && currentContract.contract_type === '2' ? // vast
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {/* <i className='fal fa-file-contract' aria-hidden={true} /> */}
                  <span className="plannerZ_employeeInfo_excel_sub2_details" style={{ color: plannedHoursWeek > (parseFloat(currentContract.contract_uren) * 60 * 60 * 1000) ? Colors.color.redFilledIn : null }}>{Constants.msToTime2(parseFloat(currentContract.contract_uren) * 60 * 60 * 1000)}</span>
                </div>
              : null}

            </div>

          </div>

          {this.renderUsersExcelDisplay_days(user)}

          {menuOpen === true ?
            this.renderEmployeeMenu(user, false, false)
          : null}

        </div>

      );

    }

    return toRender;

  }

  renderUsersExcelDisplay_days(user) {

    let toRender = [];

    //////////////////////////////////////////
    // Get highest row count of column(day)
    let highest = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    const userData = this.state.planAccounts[user.info_id];

    if (Constants.isEmpty(userData) === false) {
      for (const dayKey in userData) {
        for (const plan of userData[dayKey].plans) {
          if (plan.rooster_info_id === user.info_id) {
            highest[dayKey] += 1;
          }
        }
      }
    }

    let highestLength = 0;
    for (const key in highest) {
      if (highest[key] > highestLength) {
        highestLength = highest[key];
      }
    }

    if (highestLength === 0) {
      highestLength = highestLength + 1;
    }

    highestLength = highestLength + this.state.addEmptyRowsUser[user.info_id];
    ////////////////////////////////////////////

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      const av = userData[dayKey];

      const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
      const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);
      const absence = av.absence.length > 0;

      toRender.push(
        <div 
          className="plannerZ_dayContainer_excel"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          // style={{ backgroundColor: absence ? Constants.hexToRgbA(Colors.color.redFilledIn, 0.15) : holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.8) : null }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
            <div className="loaderPlanner_excel lds-dual-ring-plan" />
          : null}
          {this.renderTouchBox_excel(day, user, highestLength)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersExcelDisplay_openShifts(func) {

    let toRender = [];

    const user = {
      info_id: null,
      functie_id: func.functie_id,
      functie_naam: func.functie_naam,
      functie_kleur: func.functie_kleur,
    };

    toRender.push(

      <div className="plannerZ_employeeContainer">

        <div className="plannerZ_employeeInfo_excel">

          <div className="plannerZ_employeeInfo_excel_sub1" style={{ flex: 4 }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>

              <span className='plannerZ_employeeInfo_excel_sub1_name' style={{ fontWeight: 700 }}>{__('options_web_screen_openShifts')}</span>

            </div>

            <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
              {this.state.addEmptyRowsUser[user.info_id] > 0 ?
                <div><i className='fas fa-minus-circle plannerZ_plusIcon' style={{ fontSize: '0.6vw' }} aria-hidden={true} onClick={() => this.removeEmptyRowUser(user)} /></div>
              : null}
              <div><i className='fas fa-plus-circle plannerZ_plusIcon' style={{ fontSize: '0.6vw' }} aria-hidden={true} onClick={() => this.addEmptyRowUser(user)} /></div>
            </p>

          </div>

          <div className="plannerZ_employeeInfo_excel_sub2">



          </div>

        </div>

        {this.renderUsersExcelDisplay_openShifts_days(func)}

      </div>

    );

    return toRender;

  }

  renderUsersExcelDisplay_openShifts_days(func) {

    let toRender = [];

    //////////////////////////////////////////
    // Get highest row count of column(day)
    let highest = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    const user = {
      info_id: null,
      functie_id: func.functie_id,
      functie_naam: func.functie_naam,
      functie_kleur: func.functie_kleur,
    };

    const userData = this.state.planAccounts['null'];

    if (Constants.isEmpty(userData) === false) {
      for (const dayKey in userData) {
        for (const plan of userData[dayKey].plans) {
          if (plan.rooster_info_id === user.info_id && plan.functie_id === func.functie_id) {
            highest[dayKey] += 1;
          }
        }
      }
    }

    let highestLength = 0;
    for (const key in highest) {
      if (highest[key] > highestLength) {
        highestLength = highest[key];
      }
    }

    if (highestLength === 0) {
      highestLength = highestLength + 1;
    }

    highestLength = highestLength + this.state.addEmptyRowsUser[user.info_id];
    ////////////////////////////////////////////

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      const av = userData[dayKey];

      toRender.push(
        <div 
          className="plannerZ_dayContainer_excel"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          style={{  }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {this.state.planAccounts[user.info_id][dayKey].isLoading === func.functie_id ?
            <div className="loaderPlanner_excel lds-dual-ring-plan" />
          : null}
          {this.renderTouchBox_excel(day, user, highestLength)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersExcelDisplay_dayShifts(func) {

    let count = 0;

    for (const dayKey in this.state.dayShifts) {
      for (const shift of this.state.dayShifts[dayKey]) {
        if (shift.functie_id === func.functie_id && shift.dagdienst_sjabloon === this.state.dayShiftsTemplate) {
          count++;
        }
      }
    }
 
    if (count > 0) {

      return (

        <div className="plannerZ_employeeContainer">

          <div className="plannerZ_employeeInfo_excel">

            <div className="plannerZ_employeeInfo_excel_sub1">

              {/* {func.functie_naam} */}
              <span className='plannerZ_employeeInfo_excel_sub1_name' style={{ fontWeight: 700 }}>{__('misc_defaultShifts')}</span>

            </div>

          </div>

          {this.renderUsersExcelDisplay_dayShifts_days(func)}

        </div>

      );

    } else {
      return (null);
    }

  }

  renderUsersExcelDisplay_dayShifts_days(func) {

    let toRender = [];

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      toRender.push(
        <div 
          className="plannerZ_dayContainer_excel"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          // style={{ backgroundColor: holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(contract) ? Constants.hexToRgbA(Colors.color.pastBorderColor, 0.15) : null }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {/* {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null} */}
          {this.renderTouchBox_excel_dayShift(day, func)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersListDisplay() {

    let toRender = [];

    let fid = '';

    // USERS WITH FUNCTION
    for (const func of this.state.jobs) {

      if (this.state.section !== null && func.functie_afdeling_id !== this.state.section) {
        continue;
      }

      if (fid !== func.functie_id) {

        toRender.push(
          <div>
            {this.renderUsersListDisplay_jobs(func)}
          </div>
        );

        fid = func.functie_id;

      }

    }

    toRender.push(
      <div>
        {this.renderUsersListDisplay_employeesContainer()}
      </div>
    );

    return toRender;

  }

  renderUsersListDisplay_jobs(func) {

    let toRender = [];

    // toRender.push(
    //   <div className="plannerZ_jobContainer plannerZ_stickyDayShifts">

    //     {this.renderUsersTableDisplay_dayShifts(func)}

    //     <div className="plannerZ_hideBorderBottom" />

    //   </div>
    // );

    toRender.push(
      <div className="plannerZ_jobContainer" style={{ position: 'relative' }}>

        {(Constants.isEmpty(this.state.jobsCollapsed[func.functie_id]) === false && this.state.jobsCollapsed[func.functie_id] === true) ?
          <i className='far fa-square-plus' style={{ cursor: 'pointer', fontSize: '1vw', display: 'flex', alignSelf: 'flex-end' }} onClick={() => this.collapseJob(func)} />
        :
          <i className='fal fa-square-minus' style={{ cursor: 'pointer', fontSize: '1vw', display: 'flex', alignSelf: 'flex-end' }} onClick={() => this.collapseJob(func)} />
        }
        {/* <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw' }}>{func.functie_naam}</div> */}

        {this.renderUsersListDisplay_users(func)}

        {/* {this.renderUsersExcelDisplay_users(func)}

        {this.renderUsersExcelDisplay_openShifts(func)}

        {this.renderUsersExcelDisplay_dayShifts(func)} */}

        <div className="plannerZ_hideBorderBottom" style={{ height: '2vw' }} />

      </div>
    );

    return toRender;

  }

  renderUsersListDisplay_employeesContainer() {

    let toRender = [];

    // toRender.push(
    //   <div className="plannerZ_jobContainer plannerZ_stickyDayShifts">

    //     {this.renderUsersTableDisplay_dayShifts(func)}

    //     <div className="plannerZ_hideBorderBottom" />

    //   </div>
    // );

    toRender.push(
      <div className="plannerZ_jobContainer" style={{ marginTop: '2vw' }}>

        {/* <div style={{fontSize: '1.2vw', fontWeight: 'bold', marginBottom: '1vw' }}>{func.functie_naam}</div> */}

        {this.renderUsersListDisplay_employees()}

        {/* {this.renderUsersExcelDisplay_users(func)}

        {this.renderUsersExcelDisplay_openShifts(func)}

        {this.renderUsersExcelDisplay_dayShifts(func)} */}

        <div className="plannerZ_hideBorderBottom" />

      </div>
    );

    return toRender;

  }

  renderUsersListDisplay_users(func) {

    let toRender = [];

    toRender.push(

      <div className="plannerZ_employeeContainer_list">

        <div className="plannerZ_employeeInfo">

          <div className="plannerZ_employeeInfo_sub1" style={{ flex: 4 }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>

              <span className='plannerZ_employeeInfo_sub1_name' style={{ fontWeight: 700, cursor: 'default' }}>{func.functie_naam}</span>

            </div>

            {(Constants.isEmpty(this.state.jobsCollapsed[func.functie_id]) === false && this.state.jobsCollapsed[func.functie_id] === true) ?
              null
            :
              <p><i className='fas fa-plus-circle plannerZ_plusIcon' aria-hidden={true} onClick={() => this.addEmptyRowJob(func)} /></p>
            }

          </div>

        </div>

        {this.renderUsersListDisplay_days(func)}

      </div>

    );

    return toRender;

  }

  renderUsersListDisplay_days(func) {

    let toRender = [];

    if (Constants.isEmpty(this.state.jobsCollapsed[func.functie_id]) === false && this.state.jobsCollapsed[func.functie_id] === true) {
      return (null);
    }

    //////////////////////////////////////////
    // Get highest row count of column(day)
    let highest = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    // const userData = this.state.planAccounts[user.info_id];

    for (const dayKey in this.state.planList) {
      for (const plan of this.state.planList[dayKey].plans) {
        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
          if (plan.functie_id === func.functie_id && plan.teamr_team_id === Data.data.chosenTeamObj.team_id) {
            highest[dayKey] += 1;
          }
        } else {
          if (plan.functie_id === func.functie_id) {
            highest[dayKey] += 1;
          }
        }
      }
    }

    for (const dayKey in this.state.dayShifts) {
      for (const shift of this.state.dayShifts[dayKey]) {
        if (this.state.dayShiftsTemplate === shift.dagdienst_sjabloon && shift.functie_id === func.functie_id) {
          highest[dayKey] += 1;
        }
      }
    }

    let highestLength = 0;
    for (const key in highest) {
      if (highest[key] > highestLength) {
        highestLength = highest[key];
      }
    }

    if (highestLength === 0) {
      highestLength = highestLength + 1;
    }

    highestLength = highestLength + this.state.addEmptyRowsJob[func.functie_id];
    ////////////////////////////////////////////

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      // const av = userData[dayKey];

      // const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
      // const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);

      toRender.push(
        <div 
          className="plannerZ_dayContainer"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          // style={{ backgroundColor: holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.8) : null }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {this.state.planList[dayKey].isLoading === func.functie_id ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null}
          {this.renderTouchBox_list(day, func, highestLength)}
        </div>
      );

    }

    return toRender;

  }

  renderUsersListDisplay_employees() {

    let toRender = [];

    toRender.push(

      <div className="plannerZ_employeeContainer_list">

        <div className="plannerZ_employeeInfo" style={{ alignItems: 'flex-start', marginTop: '0.5vw' }}>

          <div className="plannerZ_employeeInfo_sub1" style={{ flex: 4 }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>

              <span className='plannerZ_employeeInfo_sub1_name' style={{ fontWeight: 700, cursor: 'default' }}>{__('misc_employees')}</span>

            </div>

            {/* <p><i className='fas fa-plus-circle plannerZ_plusIcon' aria-hidden={true} onClick={() => this.addEmptyRowJob(func)} /></p> */}

          </div>

        </div>

        {this.renderUsersListDisplay_employees_days()}

      </div>

    );

    return toRender;

  }

  renderUsersListDisplay_employees_days() {

    let toRender = [];

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);

      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);

      // const av = userData[dayKey];

      // const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
      // const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);

      toRender.push(
        <div 
          className="plannerZ_dayContainer"
          onMouseEnter={() => document.getElementById(dayKey).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(dayKey).style.color = '#282828'} 
          // style={{ backgroundColor: holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.8) : null }}
          // onDrop={(e) => this.onDropAv(e, user, userData, 1)}
          // onDragOver={(e) => this.onDragOverOther(e)}
          // onDragLeave={(e) => this.onDragLeaveOther(e)}
        >
          {/* {this.state.planList[dayKey].isLoading === func.functie_id ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null} */}
          {this.renderTouchBox_employees_list(day)}
        </div>
      );

    }

    return toRender;

  }

  renderTouchBox(day, user, highestRowCountDay) {

    let toRender = [];

    const userData = this.state.planAccounts[user.info_id];

    if (Constants.isEmpty(userData) === false) {

      // GET DAY DATA
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dayData = userData[dayKey];

      // PLANS
      for (const plan of dayData.plans) {
        if (Constants.isEmpty(plan.rooster_info_id) === true) {
          if (plan.functie_id === user.functie_id) {
            toRender.push(this.renderTouchBox_plan(user, plan, day));
          }
        } else {
          toRender.push(this.renderTouchBox_plan(user, plan, day));
        }
      }

      // RENDER OTHERS (HOLIDAY, AVAILABILITY)
      if (toRender.length < highestRowCountDay) {

        for (let row = toRender.length; row < highestRowCountDay; row++) {

          toRender.push(this.renderTouchBox_av(user, dayData, day, row));

        }

      }

    }

    return toRender;

  }

  renderTouchBox_list(day, func, highestRowCountDay) {

    let toRender = [];
   
    // GET DAY DATA
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    // SHIFTS
    let dayShiftRow = 0;
    for (const shift of this.state.dayShifts[dayKey]) {

      if (shift.functie_id !== func.functie_id || shift.dagdienst_sjabloon !== this.state.dayShiftsTemplate) {
        continue;
      }

      //SHIFTS
      toRender.push(this.renderTouchBox_list_dayShift(shift, day, func, dayShiftRow));

      dayShiftRow++;
      
    }

    // PLANS
    for (const plan of this.state.planList[dayKey].plans) {
      if (plan.functie_id === func.functie_id) {

        // TEAMS
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(plan.info_id) === false && (Constants.isEmpty(this.teamUsers[plan.info_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[plan.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false)) {
            continue;
          }
        }

        // GET USER DATA
        const user = Constants.isEmpty(plan.info_id) ? 
          {
            info_id: null,
            functie_id: func.functie_id,
            functie_naam: func.functie_naam,
            functie_kleur: func.functie_kleur,
          }
        :
          this.state.accountsObj[plan.info_id];

        //PLANS
        toRender.push(this.renderTouchBox_list_plan(user, plan, day));

      }
      
    }

    // RENDER EMPTY
    if (toRender.length < highestRowCountDay) {

      for (let row = toRender.length; row < highestRowCountDay; row++) {

        toRender.push(this.renderTouchBox_list_av(func, day, row));

      }

    }

    return toRender;

  }

  renderTouchBox_employees_list(day) {

    let toRender = [];

    let newUsersList = [];
    let holidayInfoRender = [];

    // GET DAY DATA
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    // HOLIDAY
    for (const userKey in this.state.planAccounts) {
      if (this.state.planAccounts[userKey][dayKey].holidays.length > 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
        holidayInfoRender.push({ userID: userKey, color: Colors.color.orangeVacation });
      }
    }

    // DAILY
    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      // AVAILABLE
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].av.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey) === false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greenFilledIn });
        }
      }

      // NOT FILLED
      for (const user of this.state.accounts) {
        const userKey = user.info_id;
        if (this.state.planAccounts[userKey][dayKey].av.length === 0 && this.state.planAccounts[userKey][dayKey].unav.length === 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greyNotFilled });
        }
      }

      // UNAVAILABLE
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].unav.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.redFilledIn });
        }
      }

    // STANDARD
    } else if (Data.data.appOptions.availability === 'STANDARD') {

      // AVAILABLE
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].sav.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greenFilledIn });
        }
      }

      // NOT FILLED
      for (const user of this.state.accounts) {
        const userKey = user.info_id;
        if (this.state.planAccounts[userKey][dayKey].sav.length === 0 && this.state.planAccounts[userKey][dayKey].sunav.length === 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greyNotFilled });
        }
      }

      // UNAVAILABLE
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].sunav.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.redFilledIn });
        }
      }

    // SCHOOL
    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      // AVAILABLE
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].av.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greenFilledIn });
        }
      }

      // NO SCHOOL
      for (const user of this.state.accounts) {
        const userKey = user.info_id;
        if (this.state.planAccounts[userKey][dayKey].school.length === 0 && this.state.planAccounts[userKey][dayKey].schoolHoliday.length === 0 && this.state.planAccounts[userKey][dayKey].av.length === 0 && this.state.planAccounts[userKey][dayKey].unav.length === 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greenFilledIn });
        }
      }

      // SCHOOL HOLIDAY
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].schoolHoliday.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greenFilledIn });
        }
      }

      // SCHOOL
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].school.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greyNotFilled });
        }
      }

      // UNAVAILABLE
      for (const userKey in this.state.planAccounts) {
        if (this.state.planAccounts[userKey][dayKey].unav.length > 0 && Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.redFilledIn });
        }
      }

    // NONE
    } else if (Data.data.appOptions.availability === 'NONE') {

      // NOT FILLED
      for (const user of this.state.accounts) {
        const userKey = user.info_id;
        if (Constants.objectArrayContainsKey(newUsersList, 'userID', userKey)=== false && this.state.planAccounts[userKey][dayKey].plans.length === 0 && this.state.allPlansUser[userKey][dayKey].length === 0 && Constants.objectArrayContainsKey(holidayInfoRender, 'userID', userKey) === false) {
          newUsersList.push({ userID: userKey, color: Colors.color.greyNotFilled });
        }
      }

    }

    for (const _holiday of holidayInfoRender) {
      newUsersList.push(_holiday);
    }

    // RENDER
    for (const user of newUsersList) {

      if (Constants.isEmpty(user.userID) === true) {
        continue;
      }

      const userData = this.state.accountsObj[user.userID];
      const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';

      // WORKING DAY
      if (Constants.isTrue(userData[info_werkdag]) === false) {
        continue;
      }

      // TEAMS
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (Constants.isEmpty(userData.info_id) === false && (Constants.isEmpty(this.teamUsers[userData.info_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[userData.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false)) {
          continue;
        }
      }

      // JOBS
      if (this.state.section !== null) {

        let hasJob = false;

        for (const job of this.state.jobs) {
          if (job.functie_afdeling_id !== this.state.section) {
            continue;
          }
          if(userData.functie_id === job.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[userData.info_id], 'functie_id', job.functie_id) === true) {
            hasJob = true;
            break;
          }
        }

        if (hasJob === false) {
          continue;
        }

      }

      toRender.push(this.renderTouchBox_list_employee(user.userID, user.color, day));

    }

    return toRender;

  }

  renderTouchBox_plan(user, plan, day) {

    const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    const functionColor = plan.functie_kleur !== null ? plan.functie_kleur : 'EBEBEB';
    const functionName = plan.functie_naam !== null ? plan.functie_naam : 'Functie onbekend';

    const av = this.state.planAccounts[user.info_id][dayKey];
    const planKey = `plan${plan.rooster_id}`;

    const absence = av.absence.length > 0 ? av.absence[0] : null;

    const menuOpen = Constants.isEmpty(this.state.menu_plan_open[planKey]) ? false : this.state.menu_plan_open[planKey];

    let hasAllSkills = true;
    let skillsRender = [];

    // plan has skills
    if (Constants.isEmpty(this.state.skills[plan.rooster_id]) === false) {

      // has all skills?
      hasAllSkills = Constants.isEmpty(plan.rooster_info_id) || Plan.userHasAllSkills(this.state.skills[plan.rooster_id], this.state.skillsAccounts[user.info_id]);

      // iterate through plan skills to show which one is missing
      for (const skill of this.state.skills[plan.rooster_id]) {
        skillsRender.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(this.state.skillsAccounts[user.info_id], skill) || Constants.isEmpty(plan.rooster_info_id) ? null : Colors.color.redFilledIn}}>• {skill.comp_naam}</span>
          </div>
        );
      }

    }

    // teams
    let other_team = null;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      if (Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && this.teamPlanData[plan.rooster_id].team_id !== Data.data.chosenTeamObj.team_id) {
        other_team = this.teamPlanData[plan.rooster_id];
      }
    }

    // if (other_team !== null && this.state.showTeams === false) {
    //   return (null);
    // }

    return (

      // <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false && this.state.selected !== planKey ? this.onDropPlan(e, user, plan, day) : this.onClickTile(planKey, user, plan, null, day, 'PLAN') : this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}>
      <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false && this.state.selected !== planKey ? this.onDropPlan(e, user, plan, day) : Constants.isEmpty(this.state.selected) === false ? this.onClickTile(planKey, user, plan, null, day, 'PLAN') : null : this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}>

        <div 
          className={`plannerZ_touchbox_outer ${this.state.selected === planKey ? 'blink' : ''} planMenuAsset`}
          // draggable={Constants.isEmpty(other_team) === true}
          // onDragStart={Constants.isEmpty(other_team) === true ? () => { this.from = plan; this.fromType = 'PLAN'; this.closeAllPlanMenus(); } : null}
          // onDrop={(e) => Constants.isEmpty(other_team) === true ? this.onDropPlan(e, user, plan, day) : null}
          // onDragOver={(e) => Constants.isEmpty(other_team) === true ? this.onDragOver(e) : null}
          // onDragLeave={(e) => Constants.isEmpty(other_team) === true ? this.onDragLeave(e) : null}
          onMouseOver={() => this.state.selectShifts === true && Constants.isEmpty(this.state.selected) === true ? this.setState({ showHover_select: planKey }) : null}
          onMouseLeave={() => this.state.selectShifts === true ? this.setState({ showHover_select: null }) : null}
          draggable={true}
          onDragStart={() => { this.from = plan; this.fromType = 'PLAN'; this.closeAllPlanMenus(); }}
          onDrop={(e) => this.onDropPlan(e, user, plan, day)}
          onDragOver={(e) => this.onDragOver(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
        >          

          {this.state.display === 'LIST' && (Constants.isEmpty(this.state.planList[dayKey].isLoading) || this.state.planList[dayKey].isLoading === false) && (this.state.planAccounts[user.info_id][dayKey].isLoading === true || (Constants.isEmpty(user.info_id) === true && this.state.planAccounts[user.info_id][dayKey].isLoading === plan.functie_id)) ?
            <div className="loaderPlanner_list lds-dual-ring-plan" />
          : null}

          {this.state.selectShifts === true && this.state.showHover_select === planKey ?
            <div className='plannerZ_touchbox_hover planMenuAsset'>
              <div className='plannerZ_touchbox_hover_sub1 planMenuAsset' onClick={() => this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}>
                <p><i className='far fa-calendar-lines-pen planMenuAsset' /></p>
              </div>
              <div className='plannerZ_touchbox_hover_sub2 planMenuAsset' onClick={() => this.onClickTile(planKey, user, plan, null, day, 'PLAN')}>
                <p><i className='far fa-hand-pointer planMenuAsset' /></p>
              </div>
            </div>
          : null}

          <div 
            className="plannerZ_touchbox planMenuAsset" 
            style={{ 
              backgroundColor: Constants.isEmpty(other_team) === false ? Constants.hexToRgbA(Colors.color.greyNotFilled, 0.2) : Constants.hexToRgbA(`#${functionColor}`, 0.2),
              border: `1px solid ${Constants.isEmpty(other_team) === false ? Constants.hexToRgbA(Colors.color.greyNotFilled, 0.2) : Constants.isEmpty(plan.rooster_info_id) ? Constants.hexToRgbA(Colors.color.redFilledIn, 0.8) : Constants.hexToRgbA(`#${functionColor}`, 0.8)}`,
            }}         
          >

            <div className="plannerZ_touchbox_sub1 planMenuAsset" style={{ width: Constants.isEmpty(other_team) ? null : '61%' }}>

              <div className="plannerZ_touchbox_sub1_row planMenuAsset">

                {Constants.isEmpty(plan.rooster_info_id) ?
                  <span className='planMenuAsset' style={{ fontSize: '0.6vw', color: Colors.color.redFilledIn, fontWeight: 'bold' }}>{__('home_overview_openShift')}</span>
                :
                  <span className='planMenuAsset' style={{ fontSize: '0.6vw' }}>{functionName}</span>
                }

              </div>

              <div className="plannerZ_touchbox_sub1_row planMenuAsset" style={{ cursor: 'pointer' }}>

                <span className="plannerZ_touchbox_time planMenuAsset" style={{ color: plan.rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : null }}>{plan.rooster_begin.substr(0, 5)} - {plan.rooster_eind.substr(0, 5)}</span>

                {plan.rooster_pauze !== '00:00:00' ?
                  <span className='plannerZ_touchbox_break planMenuAsset'>{plan.rooster_pauze.substr(0, 5)}</span>
                : null}

              </div>

            </div>

            {Constants.isEmpty(other_team) === true ?

              <div className="plannerZ_touchbox_sub2 planMenuAsset">

                <div className="plannerZ_touchbox_sub2_row planMenuAsset">

                  {Constants.isEmpty(absence) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='far fa-user-minus'></i>
                          <span style={{marginLeft: '0.5vw'}}>{absence.afw_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text planMenuAsset" style={{ backgroundColor: Colors.color.redFilledIn }}>
                        {/* <i className='fas fa-tag' aria-hidden={true} style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`) }} /> */}
                        <p className='planMenuAsset' style={{ color: 'white', fontSize: '0.5vw' }}>{absence.afw_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {Constants.isEmpty(plan.bijz_id) === false && Constants.isEmpty(absence) === true ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <b style={{ color: `#${plan.bijz_kleur}` }}>{plan.bijz_afkorting}</b>
                          <span style={{marginLeft: '0.5vw'}}>{plan.bijz_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text planMenuAsset" style={{ backgroundColor: `#${plan.bijz_kleur}` }}>
                        {/* <i className='fas fa-tag' aria-hidden={true} style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`) }} /> */}
                        <p className='planMenuAsset' style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`), fontSize: '0.5vw' }}>{plan.bijz_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {hasAllSkills === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='far fa-exclamation-triangle'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_userNoSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.redFilledIn }}>
                        <p><i className='fas fa-exclamation-triangle planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : 
                  hasAllSkills === true && Constants.isEmpty(this.state.skills[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw', color: Constants.isEmpty(plan.rooster_info_id) ? null : Colors.color.greenFilledIn}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                        <p><i className='fas fa-graduation-cap planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

                <div className="plannerZ_touchbox_sub2_row planMenuAsset">

                  {Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ backgroundColor: Colors.color.orangeVacation }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {plan.rooster_publiceren === '0' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='fas fa-exclamation'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_notPublished')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.redFilledIn }}>
                        <p><i className='fas fa-exclamation planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

              </div>

            : 
            
              <div className="plannerZ_touchbox_sub2 planMenuAsset" style={{ width: Constants.isEmpty(other_team) ? null : '39%' }}>

                <div className="plannerZ_touchbox_sub2_row planMenuAsset">
                  {Constants.isEmpty(other_team) === false ?
                    <span className='plannerZ_touchbox_break planMenuAsset' style={{ color: '#282828', textAlign: 'right' }}>{other_team.team_naam}</span>
                  : null}
                </div>

                <div className="plannerZ_touchbox_sub2_row planMenuAsset">

                  {Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.6) }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {plan.rooster_publiceren === '0' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='fas fa-exclamation'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_notPublished')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.6) }}>
                        <p><i className='fas fa-exclamation planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

              </div>

            }

          </div>

        </div>

        {menuOpen === true ?
          this.renderPlanMenu(planKey, null, user, av, day, day === 0 || day === 5 || day === 6, plan, 'TABLE')
        : null}

      </div>

    );

  }

  renderTouchBox_av(user, av, day, row) {

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const date = Constants.stringToDate(dateString);

    const avKey = `av${user.info_id}${user.functie_id}${dayKey}${row}`;

    const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
    const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);
    const absence = av.absence.length > 0 ? av.absence[0] : null;

    const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';

    const blocked = (holiday || (Constants.isEmpty(contract) && Constants.isTrue(this.state.opties_contractVerlopen) === true) || Constants.isTrue(user[info_werkdag]) === false) && Constants.isEmpty(user.info_id) === false;

    const renderAvailLabels = this.state.opties_infoplanner_verborgen === true ? this.renderAvailLabels_av(user, date, av, false, avKey) : [];

    const menuOpen = Constants.isEmpty(this.state.menu_plan_open[avKey]) ? false : this.state.menu_plan_open[avKey];
    const menuHolidayOpen = Constants.isEmpty(this.state.menu_holiday_open[avKey]) ? false : this.state.menu_holiday_open[avKey];

    return (

      <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => e.target.className.includes('planHoliday') ? null : this.state.selectShifts && Constants.isEmpty(this.state.selected) === false && blocked === false ? this.onDropAv(e, user, av, day) : this.canOpenPlanMenu === true && blocked === false ? this.openPlanMenu(avKey, null, user, null) : null}>

        <div 
          className="plannerZ_touchbox_empty_outer planMenuAsset"
          onDrop={(e) => blocked ? null : this.onDropAv(e, user, av, day)}
          onDragOver={(e) => blocked ? null : this.onDragOverOther(e)}
          onDragLeave={(e) => blocked ? null : this.onDragLeaveOther(e)}
          style={{ cursor: blocked ? 'not-allowed' : null }}
        >

          <div className="plannerZ_touchbox_empty planMenuAsset">

            <div className="plannerZ_touchbox_empty_sub1 planMenuAsset">

              <div className="plannerZ_touchbox_empty_sub1_row planMenuAsset">

                {Constants.isEmpty(user.info_id) === false && holiday === true ?
                  <div className="plannerZ_touchbox_label" style={{ backgroundColor: Colors.color.orangeVacation }}>
                    <p aria-hidden={true} style={{ color: 'white' }}>{__('misc_leave')}</p>
                  </div>
                :
                Constants.isEmpty(user.info_id) === false && Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ?
                  <div className="plannerZ_touchbox_label" style={{ backgroundColor: Colors.color.pastBorderColor }}>
                    <p aria-hidden={true} style={{ color: 'white' }}>{__('misc_contractExpired')}</p>
                  </div>
                :
                Constants.isEmpty(user.info_id) === false && Constants.isEmpty(absence) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <i style={{ color: Colors.color.redFilledIn }} className='far fa-user-minus'></i>
                        <span style={{marginLeft: '0.5vw'}}>{absence.afw_naam}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    arrowSize='small'
                    theme='tiemdo'
                  >
                    <div className="plannerZ_touchbox_label" style={{ backgroundColor: Colors.color.redFilledIn }}>
                      <p aria-hidden={true} style={{ color: 'white' }}>{absence.afw_afkorting}</p>
                    </div>
                  </Tooltip>
                :
                renderAvailLabels.length > 0 ?
                  <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%' }}>
                    {renderAvailLabels}
                  </div>
                :
                  <span className='planMenuAsset'>&nbsp;</span>
                }

              </div>

              {/* <div className="plannerZ_touchbox_empty_sub1_row">

                <span>&nbsp;</span>

              </div> */}

            </div>

            {/* <div className="plannerZ_touchbox_empty_sub2">

              <div className="plannerZ_touchbox_empty_sub2_row">

                <span>&nbsp;</span>

              </div>

              <div className="plannerZ_touchbox_empty_sub2_row">

                <span>&nbsp;</span>

              </div>

            </div> */}

          </div>

        </div>

        {menuOpen === true ?
          this.renderPlanMenu(avKey, null, user, av, day, day === 0 || day === 5 || day === 6, null, 'TABLE')
        : null}

        {menuHolidayOpen === true ?
          this.renderHolidayMenu(user, av, day === 0 || day === 5 || day === 6)
        : null}

      </div>

    );

  }

  renderTouchBox_dayShift(day, func) {

    let toRender = [];

    let newDayShifts = [];
    let countDayShifts = {};

    // GET DAY DATA
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    for (const shift of this.state.dayShifts[dayKey]) {

      if (shift.functie_id === func.functie_id && shift.dagdienst_sjabloon === this.state.dayShiftsTemplate) {

        // SKILLS DAYSHIFT CHECK
        let skillsString = ``;
        const skills = this.state.skillsDayShifts[shift.dagdienst_id];
        if (Constants.isEmpty(skills) === false) {
          for (const skill of skills) {
            skillsString += `${skill.comp_id}`;
          }
        } else {
          skillsString = `null`;
        }

        const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;

        if (Constants.isEmpty(countDayShifts[shiftKey])) {
          newDayShifts.push(shift);
          countDayShifts[shiftKey] = 1;
        } else {
          countDayShifts[shiftKey] += 1;
        }

      }

    }

    for (const shift of newDayShifts) {

      // SKILLS DAYSHIFT CHECK
      let skillsString = ``;
      const skills = this.state.skillsDayShifts[shift.dagdienst_id];
      if (Constants.isEmpty(skills) === false) {
        for (const skill of skills) {
          skillsString += `${skill.comp_id}`;
        }
      } else {
        skillsString = `null`;
      }

      const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;

      // SKILL DATA
      let skillsRender = [];

      // shift has skills
      if (Constants.isEmpty(this.state.skillsDayShifts[shift.dagdienst_id]) === false) {
        // iterate through shift skills to show skills
        for (const skill of this.state.skillsDayShifts[shift.dagdienst_id]) {
          skillsRender.push(
            <div>
              <span style={{marginLeft: 0}}>• {skill.comp_naam}</span>
            </div>
          );
        }
      }

      const menuOpen = Constants.isEmpty(this.state.menu_dayShift_open[shiftKey]) ? false : this.state.menu_dayShift_open[shiftKey];

      toRender.push(

        <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false ? this.onClickTile(shiftKey, null, shift, null, day, 'SHIFT') : null : this.openDayShiftMenu(shiftKey, shift)}>
          {/* <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? this.onClickTile(shiftKey, null, shift, null, day, 'SHIFT') : this.openDayShiftMenu(shiftKey, shift)}> */}

          <div 
            className={`plannerZ_touchbox_outer ${this.state.selected === shiftKey ? 'blink' : ''} planMenuAsset`} 
            onMouseOver={() => this.state.selectShifts === true && Constants.isEmpty(this.state.selected) === true ? this.setState({ showHover_select: shiftKey }) : null}
            onMouseLeave={() => this.state.selectShifts === true ? this.setState({ showHover_select: null }) : null}
            draggable={true}
            onDragStart={() => { this.from = shift; this.fromType = 'SHIFT'; this.closeAllPlanMenus(); }}
          >

            {this.state.selectShifts === true && this.state.showHover_select === shiftKey ?
              <div className='plannerZ_touchbox_hover planMenuAsset'>
                <div className='plannerZ_touchbox_hover_sub1 planMenuAsset' onClick={() => this.canOpenPlanMenu === true ? this.openDayShiftMenu(shiftKey, shift) : null}>
                  <p><i className='far fa-calendar-lines-pen planMenuAsset' /></p>
                </div>
                <div className='plannerZ_touchbox_hover_sub2 planMenuAsset' onClick={() => this.onClickTile(shiftKey, null, shift, null, day, 'SHIFT')}>
                  <p><i className='far fa-hand-pointer planMenuAsset' /></p>
                </div>
              </div>
            : null}

            {/* {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
              <div className="loaderPlanner lds-dual-ring-plan" />
            : null} */}

            {/* <div className="plannerZ_touchbox" style={{  }}> */}
            <div 
              className="plannerZ_touchbox" 
              style={{ backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.15) }}         
            >

              <div className="plannerZ_touchbox_sub1">

                <div className="plannerZ_touchbox_sub1_row">

                  <span style={{ fontSize: '0.6vw' }}>{__('misc_defaultShift')}</span>

                </div>

                <div className="plannerZ_touchbox_sub1_row" style={{ cursor: 'pointer' }}>

                  <span className="plannerZ_touchbox_time" style={{  }}>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>

                  {shift.dagdienst_pauze !== '00:00:00' ?
                    <span className='plannerZ_touchbox_break'>{shift.dagdienst_pauze.substr(0, 5)}</span>
                  : null}

                </div>

              </div>

              <div className="plannerZ_touchbox_sub2">

                <div className="plannerZ_touchbox_sub2_row">

                  {Constants.isEmpty(shift.bijz_id) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <b style={{ color: `#${shift.bijz_kleur}` }}>{shift.bijz_afkorting}</b>
                          <span style={{marginLeft: '0.5vw'}}>{shift.bijz_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text" style={{ backgroundColor: `#${shift.bijz_kleur}` }}>
                        {/* <i className='fas fa-tag' aria-hidden={true} style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`) }} /> */}
                        <p style={{ color: Constants.getContrast(`#${shift.bijz_kleur}`), fontSize: '0.5vw' }}>{shift.bijz_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {skillsRender.length > 0 ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                        <p><i className='fas fa-graduation-cap' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

                <div className="plannerZ_touchbox_sub2_row">

                  {Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ backgroundColor: Colors.color.orangeVacation }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}
                
                  <div className="plannerZ_touchbox_iconContainer_text" style={{ marginLeft: '0.1vw', backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.7) }}>
                    <p aria-hidden={true} style={{ color: Constants.getContrast(`#${shift.functie_kleur}`) }}>{countDayShifts[shiftKey]}</p>
                  </div>

                </div>

              </div>

            </div>

          </div>

          {menuOpen === true ?
            this.renderDayShiftMenu(shiftKey, day, day === 0 || day === 5 || day === 6, shift)
          : null}

        </div>

      );

    }

    return toRender;

  }

  renderTouchBox_excel(day, user, highestRowCountDay) {

    let toRender = [];

    const userData = this.state.planAccounts[user.info_id];

    if (Constants.isEmpty(userData) === false) {

      // GET DAY DATA
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dayData = userData[dayKey];

      // PLANS
      for (const plan of dayData.plans) {

        if (Constants.isEmpty(plan.rooster_info_id) === true) {
          if (plan.functie_id === user.functie_id) {
            toRender.push(this.renderTouchBox_excel_plan(user, plan, day, toRender.length));
          }
        } else {
          toRender.push(this.renderTouchBox_excel_plan(user, plan, day, toRender.length));
        }

        // if (toRender.length > 0 && highestRowCountDay > 1 && toRender.length !== (dayData.plans.length + 1)) {
        //   toRender.push(<div className='plannerZ_renderBorderHorizontal' />);
        //   highestRowCountDay++;
        // }

      }

      // RENDER OTHERS (HOLIDAY, AVAILABILITY)
      if (toRender.length < highestRowCountDay) {

        for (let row = toRender.length; row < highestRowCountDay; row++) {

          toRender.push(this.renderTouchBox_excel_av(user, dayData, day, row));

          // if (toRender.length > 0 && highestRowCountDay > 1 && row !== (highestRowCountDay - 1)) {
          //   toRender.push(<div className='plannerZ_renderBorderHorizontal' />);
          // }

        }

      }

    }

    return toRender;

  }

  renderTouchBox_excel_plan(user, plan, day, row) {

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    const functionColor = plan.functie_kleur !== null ? plan.functie_kleur : 'EBEBEB';
    const functionName = plan.functie_naam !== null ? plan.functie_naam : 'Functie onbekend';

    const av = this.state.planAccounts[user.info_id][dayKey];
    const planKey = `plan${plan.rooster_id}`;

    const absence = av.absence.length > 0 ? av.absence[0] : null;

    const menuOpen = Constants.isEmpty(this.state.menu_plan_open[planKey]) ? false : this.state.menu_plan_open[planKey];

    let hasAllSkills = true;
    let skillsRender = [];

    // plan has skills
    if (Constants.isEmpty(this.state.skills[plan.rooster_id]) === false) {

      // has all skills?
      hasAllSkills = Constants.isEmpty(plan.rooster_info_id) || Plan.userHasAllSkills(this.state.skills[plan.rooster_id], this.state.skillsAccounts[user.info_id]);

      // iterate through plan skills to show which one is missing
      for (const skill of this.state.skills[plan.rooster_id]) {
        skillsRender.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(this.state.skillsAccounts[user.info_id], skill) || Constants.isEmpty(plan.rooster_info_id) ? null : Colors.color.redFilledIn}}>• {skill.comp_naam}</span>
          </div>
        );
      }

    }

    // teams
    let other_team = null;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      if (Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && this.teamPlanData[plan.rooster_id].team_id !== Data.data.chosenTeamObj.team_id) {
        other_team = this.teamPlanData[plan.rooster_id];
      }
    }

    // if (other_team !== null && this.state.showTeams === false) {
    //   return (null);
    // }

    return (

      <div className='planMenuAsset' style={{ position: 'relative' }}>

        <div 
          className={`plannerZ_touchbox_empty_outer_excel ${this.state.selected === planKey ? 'blink' : ''} planMenuAsset`}
          draggable={true}
          onDragStart={(e) => this.onDragStartPlan(e, planKey, plan)}
          onDrop={(e) => this.onDropPlan(e, user, plan, day)}
          onDragOver={(e) => this.onDragOver(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
          style={{ borderTop: row > 0 ? '1px solid #EEF0F1' : null, }} 
        >

          <div className="plannerZ_touchbox_empty_excel planMenuAsset" style={{ backgroundColor: Constants.isEmpty(other_team) === false ? Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1) : Constants.hexToRgbA(`#${functionColor}`, 0.2), }}>

            <div className="plannerZ_touchbox_empty_excel_sub1 planMenuAsset">

              <input
                ref={(ref) => this.timeRef[planKey]=ref}
                className={plan.rooster_publiceren === '3' ? 'plannerZ_input_time_excel_openShift planMenuAsset' : 'plannerZ_input_time_excel planMenuAsset'}
                defaultValue={`${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`}
                // placeholder='00:00 - 00:00'
                onChange={(event) => this.onChangeTime(event, plan, planKey, user, day, true)}
                onBlur={() => this.updateUserPlannerDirectExtraChecks(planKey, user, day, plan, 'SHIFT')}
                onFocus={() => this.shouldClosePlanMenu(planKey, 'PLAN')}
                type={'text'}
                disabled={this.state.planAccounts[user.info_id][dayKey].isLoading === true}
                style={
                  plan.rooster_tijd_incorrect === '1' ?
                    {color: Colors.color.redFilledIn} 
                  : null
                    // isAvailableShift ?
                    //   {color: '#E6A024'} 
                    // :
                    //   {color: '#04163b'} 
                }
              />

            </div>

            <div className="plannerZ_touchbox_empty_excel_sub2 planMenuAsset" style={{ cursor: 'pointer' }} onClick={(e) => this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false && this.state.selected !== planKey ? this.onDropPlan(e, user, plan, day) : this.onClickTile(planKey, user, plan, null, day, 'PLAN') : this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}>
              
              {plan.rooster_pauze !== '00:00:00' ?
                <span className='plannerZ_touchbox_empty_excel_sub2_break planMenuAsset' style={{ width: Constants.isEmpty(other_team) ? null : '30%' }}>{plan.rooster_pauze.substr(0, 5)}</span>
              : null}

              {Constants.isEmpty(other_team) === false ?
                <span className='plannerZ_touchbox_empty_excel_sub2_break planMenuAsset' style={{ width: Constants.isEmpty(other_team) ? null : '70%', textAlign: 'right', paddingRight: '0.3vw' }}>{other_team.team_naam}</span>
              : null}

              {Constants.isEmpty(other_team) === true ?

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '0.3vw', paddingTop: '0.2vw', paddingBottom: '0.2vw' }}>

                  {Constants.isEmpty(absence) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='far fa-user-minus'></i>
                          <span style={{marginLeft: '0.5vw'}}>{absence.afw_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text planMenuAsset" style={{ backgroundColor: Colors.color.redFilledIn }}>
                        <p className='planMenuAsset' style={{ color: 'white', fontSize: '0.5vw' }}>{absence.afw_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {Constants.isEmpty(plan.bijz_id) === false && Constants.isEmpty(absence) === true ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <b style={{ color: `#${plan.bijz_kleur}` }}>{plan.bijz_afkorting}</b>
                          <span style={{marginLeft: '0.5vw'}}>{plan.bijz_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text planMenuAsset" style={{ backgroundColor: `#${plan.bijz_kleur}` }}>
                        <p className='planMenuAsset' style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`), fontSize: '0.5vw' }}>{plan.bijz_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {hasAllSkills === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='far fa-exclamation-triangle'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_userNoSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.redFilledIn }}>
                        <p><i className='fas fa-exclamation-triangle planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : 
                  hasAllSkills === true && Constants.isEmpty(this.state.skills[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw', color: Constants.isEmpty(plan.rooster_info_id) ? null : Colors.color.greenFilledIn}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                        <p><i className='fas fa-graduation-cap planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.orangeVacation }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {plan.rooster_publiceren === '0' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='fas fa-exclamation'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_notPublished')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.redFilledIn }}>
                        <p><i className='fas fa-exclamation planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}
                </div>

              : null}

            </div>

          </div>

        </div>

        {menuOpen === true ?
          this.renderPlanMenu(planKey, null, user, av, day, day === 0 || day === 5 || day === 6, plan, 'XCL')
        : null}

      </div>

    );

  }

  renderTouchBox_excel_av(user, av, day, row) {

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const date = Constants.stringToDate(dateString);

    const avKey = `av${user.info_id}${user.functie_id}${dayKey}${row}`;

    const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
    const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);
    const absence = av.absence.length > 0 ? av.absence[0] : null;
    const holidayRequest = av.holidayRequests.length > 0;

    const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';

    const blocked = (holiday || (Constants.isEmpty(contract) && Constants.isTrue(this.state.opties_contractVerlopen) === true) || Constants.isTrue(user[info_werkdag]) === false) && Constants.isEmpty(user.info_id) === false;

    const renderAvailLabels = this.state.opties_infoplanner_verborgen === true ? this.renderAvailLabels_av_excel(user, date, av, true) : [];
    const isAvailable = (av.av.length > 0 || av.sav.length > 0) ? true : (av.unav.length > 0 || av.sunav.length > 0) ? false : null;

    // const menuOpen = Constants.isEmpty(this.state.menu_plan_open[avKey]) ? false : this.state.menu_plan_open[avKey];

    const menuHolidayOpen = Constants.isEmpty(this.state.menu_holiday_open[avKey]) ? false : this.state.menu_holiday_open[avKey];

    // onClick={() => this.canOpenPlanMenu === true ? this.openPlanMenu(avKey, user, null) : null}
    return (

      <div className='planMenuAsset' style={{ position: 'relative' }}>

        {/* {row > 0 ?
          <div className='plannerZ_renderBorderHorizontal' />
        : null} */}

        <div className='planMenuAsset' style={{ position: 'relative' }}>

        {/* absence ? Constants.hexToRgbA(Colors.color.redFilledIn, 0.15) :  */}
          <div 
            className="plannerZ_touchbox_empty_outer_excel planMenuAsset"
            onClick={(e) => this.state.selectShifts && Constants.isEmpty(this.state.selected) === false && blocked === false ? this.onDropAv(e, user, av, day) : null}
            onDrop={(e) => blocked ? null : this.onDropAv(e, user, av, day)}
            onDragOver={(e) => blocked ? null : this.onDragOverOther(e)}
            onDragLeave={(e) => blocked ? null : this.onDragLeaveOther(e)}
            style={{ 
              cursor: blocked ? 'not-allowed' : null, 
              backgroundColor: holiday ? Constants.hexToRgbA(Colors.color.orangeVacation, 0.15) : Constants.isEmpty(user.info_id) === false && Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.8) : Constants.isEmpty(user.info_id) === false && Constants.isTrue(user[info_werkdag]) === false ? Constants.hexToRgbA(Colors.color.pastBackgroundColor, 0.4) : this.timeRef[avKey] === document.activeElement && Constants.isEmpty(this.state.excel_selectedJob) === false && Constants.isEmpty(this.state.excel_selectedJob[avKey]) === false ? Constants.hexToRgbA(`#${this.state.excel_selectedJob[avKey].functie_kleur}`, 0.1) : null,
              borderTop: row > 0 ? '1px solid #EEF0F1' : null,
            }}
          >

            <div className="plannerZ_touchbox_empty_excel planMenuAsset">

              {this.state.opties_infoplanner_verborgen === true && renderAvailLabels.length > 0 && holidayRequest === false && blocked === false && this.timeRef[avKey] !== document.activeElement ?
                <div className='plannerZ_touchbox_empty_excel_availIcon plannerZ_touchbox_label_excel_availIcon' style={{ color: isAvailable ? Colors.color.greenFilledIn : Colors.color.redFilledIn }}>
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        {renderAvailLabels}
                        {/* <i style={{ color: Colors.color.redFilledIn }} className='fas fa-exclamation'></i>
                        <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_notPublished')}</span> */}
                      </div>
                    )}
                    position={'top'}
                    arrow
                    arrowSize='small'
                    theme='tiemdo'
                  >
                    {/* <div className='plannerZ_touchbox_label_excel_availIcon' style={{ color: isAvailable ? Colors.color.greenFilledIn : Colors.color.redFilledIn }}> */}
                      <p style={{ marginBlock: 0, padding: '0 0.3vw' }}><i className='far fa-user' /></p>
                    {/* </div> */}
                  </Tooltip>
                </div>
              :
              holidayRequest === true && blocked === false && this.timeRef[avKey] !== document.activeElement ?
                <div className='plannerZ_touchbox_empty_excel_availIcon plannerZ_touchbox_label_excel_availIcon' style={{ color: Colors.color.orangeVacation, cursor: 'pointer' }} onClick={() => this.openHolidayMenu(avKey)}>
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <i style={{ color: Colors.color.orangeVacation }} className='far fa-island-tropical'></i>
                        <span style={{marginLeft: '0.5vw'}}>{__('home_overview_leaveRequest_title')}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    arrowSize='small'
                    theme='tiemdo'
                  >
                    {/* <div className='plannerZ_touchbox_label_excel_availIcon' style={{ color: isAvailable ? Colors.color.greenFilledIn : Colors.color.redFilledIn }}> */}
                      <p style={{ marginBlock: 0, padding: '0 0.3vw' }}><i className='far fa-island-tropical' /></p>
                    {/* </div> */}
                  </Tooltip>
                </div>
              : null}

              <div className="plannerZ_touchbox_empty_excel_sub1 planMenuAsset" style={{ width: this.timeRef[avKey] === document.activeElement && this.state.excel_selectionsJob.length > 0 && Constants.isEmpty(this.state.excel_selectedJob) === false && Constants.isEmpty(this.state.excel_selectedJob[avKey]) === false ? '50%' : '100%' }}>

                {Constants.isEmpty(user.info_id) === false && holiday === true ?
                  <div className="plannerZ_touchbox_label_excel" style={{ backgroundColor: Colors.color.orangeVacation }}>
                    <p aria-hidden={true} style={{ color: 'white' }}>{__('misc_leave')}</p>
                  </div>
                :
                Constants.isEmpty(user.info_id) === false && Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ?
                  <div className="plannerZ_touchbox_label_excel" style={{ backgroundColor: Colors.color.pastBorderColor }}>
                    <p aria-hidden={true} style={{ color: 'white' }}>{__('misc_contractExpired')}</p>
                  </div>
                :
                Constants.isEmpty(user.info_id) === false && Constants.isTrue(user[info_werkdag]) === false ?
                  null
                :
                // Constants.isEmpty(user.info_id) === false && Constants.isEmpty(absence) === false ?
                //   <Tooltip
                //     options
                //     html={(
                //       <div className='plannerZ_tooltipContainer'>
                //         <i style={{ color: Colors.color.redFilledIn }} className='far fa-user-minus'></i>
                //         <span style={{marginLeft: '0.5vw'}}>{absence.afw_naam}</span>
                //       </div>
                //     )}
                //     position={'top'}
                //     arrow
                //     arrowSize='small'
                //     theme='tiemdo'
                //   >
                //     <div className="plannerZ_touchbox_label_excel" style={{ backgroundColor: Colors.color.redFilledIn }}>
                //       <p aria-hidden={true} style={{ color: 'white' }}>{absence.afw_afkorting}</p>
                //     </div>
                //   </Tooltip>
                // :
                // renderAvailLabels.length > 0 ?
                //   <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%' }}>
                //     {renderAvailLabels}
                //   </div>
                // :

                  <input
                    ref={(ref) => this.timeRef[avKey]=ref}
                    className='plannerZ_input_time_excel planMenuAsset'
                    onKeyDown={(e) => this.handleKey(e, avKey, user, dateString, day, null)}
                    defaultValue={''}
                    // placeholder='00:00 - 00:00'
                    onChange={(event) => this.onChangeTime(event, null, avKey, user, day, true)}
                    onBlur={() => setTimeout(() => { this.planUserDirect(avKey, user, dateString, day); }, 200)}
                    onFocus={() => { this.getSecondaryJobsSelections(user, avKey); this.shouldClosePlanMenu(avKey, 'AV'); }}
                    type={'text'}
                    disabled={this.state.planAccounts[user.info_id][dayKey].isLoading === true || Constants.isEmpty(this.state.selected) === false}
                    // style={
                    //   isPlan && plan.rooster_tijd_incorrect === '1' ?
                    //     {color: Colors.color.redFilledIn} 
                    //   : null
                    //     // isAvailableShift ?
                    //     //   {color: '#E6A024'} 
                    //     // :
                    //     //   {color: '#04163b'} 
                    // }
                  />

                }

              </div>

              {this.timeRef[avKey] === document.activeElement && this.state.excel_selectionsJob.length > 0 && Constants.isEmpty(this.state.excel_selectedJob) === false && Constants.isEmpty(this.state.excel_selectedJob[avKey]) === false ?
                <div className="plannerZ_touchbox_empty_excel_sub2 planMenuAsset" style={{ cursor: this.state.excel_selectionsJob.length > 1 ? 'pointer' : 'text' }} onMouseDown={(e) => e.preventDefault()} onClick={() => this.iterateJobSelections(avKey)}>
                  <div className='plannerZ_touchbox_empty_excel_sub2_row planMenuAsset'>
                    <span className='planMenuAsset'>{this.state.excel_selectedJob[avKey].functie_naam}</span>
                    {this.state.excel_selectionsJob.length > 1 ?
                      <p className='planMenuAsset'><i className='far fa-angle-right planMenuAsset' style={{ paddingLeft: '0.3vw' }} /></p>
                    : null}
                  </div>
                </div>
              : null}

            </div>

          </div>

          {/* {menuOpen === true ?
            this.renderPlanMenu(avKey, user, av, day, day === 0 || day === 5 || day === 6, null)
          : null} */}

          {menuHolidayOpen === true ?
            this.renderHolidayMenu(user, av, day === 0 || day === 5 || day === 6)
          : null}

        </div>

      </div>

    );

  }

  renderTouchBox_excel_dayShift(day, func) {

    let toRender = [];

    let newDayShifts = [];
    let countDayShifts = {};

    // GET DAY DATA
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    for (const shift of this.state.dayShifts[dayKey]) {

      if (shift.functie_id === func.functie_id && shift.dagdienst_sjabloon === this.state.dayShiftsTemplate) {

        // SKILLS DAYSHIFT CHECK
        let skillsString = ``;
        const skills = this.state.skillsDayShifts[shift.dagdienst_id];
        if (Constants.isEmpty(skills) === false) {
          for (const skill of skills) {
            skillsString += `${skill.comp_id}`;
          }
        } else {
          skillsString = `null`;
        }

        const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;

        if (Constants.isEmpty(countDayShifts[shiftKey])) {
          newDayShifts.push(shift);
          countDayShifts[shiftKey] = 1;
        } else {
          countDayShifts[shiftKey] += 1;
        }

      }

    }

    for (const shift of newDayShifts) {

      // SKILLS DAYSHIFT CHECK
      let skillsString = ``;
      const skills = this.state.skillsDayShifts[shift.dagdienst_id];
      if (Constants.isEmpty(skills) === false) {
        for (const skill of skills) {
          skillsString += `${skill.comp_id}`;
        }
      } else {
        skillsString = `null`;
      }

      const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;

      // SKILL DATA
      let skillsRender = [];

      // shift has skills
      if (Constants.isEmpty(this.state.skillsDayShifts[shift.dagdienst_id]) === false) {
        // iterate through shift skills to show skills
        for (const skill of this.state.skillsDayShifts[shift.dagdienst_id]) {
          skillsRender.push(
            <div>
              <span style={{marginLeft: 0}}>• {skill.comp_naam}</span>
            </div>
          );
        }
      }

      const menuOpen = Constants.isEmpty(this.state.menu_dayShift_open[shiftKey]) ? false : this.state.menu_dayShift_open[shiftKey];

      toRender.push(

        <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? this.onClickTile(shiftKey, null, shift, null, day, 'SHIFT') : this.openDayShiftMenu(shiftKey, shift)}>

          <div 
            className={`plannerZ_touchbox_empty_outer_excel ${this.state.selected === shiftKey ? 'blink' : ''}`}
            draggable={true}
            onDragStart={(e) => this.onDragStartDayShift(e, shiftKey, shift)}
            style={{ cursor: 'pointer' }}
          >

            <div className="plannerZ_touchbox_empty_excel planMenuAsset" style={{ backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.08), }}>

              <div className="plannerZ_touchbox_empty_excel_sub1 planMenuAsset" style={{ width: '54%' }}>

                <div className='plannerZ_touchbox_empty_excel_time'>

                  <div className="plannerZ_touchbox_iconContainer_text" style={{ marginRight: '0.2vw', backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.7) }}>
                    <p aria-hidden={true} style={{ color: Constants.getContrast(`#${shift.functie_kleur}`) }}>{countDayShifts[shiftKey]}</p>
                  </div>

                  <span>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>

                </div>

              </div>

              <div className="plannerZ_touchbox_empty_excel_sub2 planMenuAsset" style={{ width: '46%' }}>
              
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '0.3vw', paddingTop: '0.2vw', paddingBottom: '0.2vw' }}>
                  {shift.dagdienst_pauze !== '00:00:00' ?
                    <span className='plannerZ_touchbox_empty_excel_sub2_break planMenuAsset'>{shift.dagdienst_pauze.substr(0, 5)}</span>
                  : null}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '0.3vw', paddingTop: '0.2vw', paddingBottom: '0.2vw' }}>
                  {Constants.isEmpty(shift.bijz_id) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <b style={{ color: `#${shift.bijz_kleur}` }}>{shift.bijz_afkorting}</b>
                          <span style={{marginLeft: '0.5vw'}}>{shift.bijz_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text planMenuAsset" style={{ backgroundColor: `#${shift.bijz_kleur}` }}>
                        <p className='planMenuAsset' style={{ color: Constants.getContrast(`#${shift.bijz_kleur}`), fontSize: '0.5vw' }}>{shift.bijz_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {skillsRender.length > 0 ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                        <p><i className='fas fa-graduation-cap' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.orangeVacation }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}
                </div>

              </div>

            </div>

            {/* <div 
              className="plannerZ_touchbox" 
              style={{ backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.1) }}         
            >

              <div className="plannerZ_touchbox_sub1">

                <div className="plannerZ_touchbox_sub1_row">

                  <span style={{ fontSize: '0.6vw' }}>{__('misc_defaultShift')}</span>

                </div>

                <div className="plannerZ_touchbox_sub1_row" style={{ cursor: 'pointer' }}>

                  <span className="plannerZ_touchbox_time" style={{  }}>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>

                  {shift.dagdienst_pauze !== '00:00:00' ?
                    <span className='plannerZ_touchbox_break'>{shift.dagdienst_pauze.substr(0, 5)}</span>
                  : null}

                </div>

              </div>

              <div className="plannerZ_touchbox_sub2">

                <div className="plannerZ_touchbox_sub2_row">

                  {Constants.isEmpty(shift.bijz_id) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <b style={{ color: `#${shift.bijz_kleur}` }}>{shift.bijz_afkorting}</b>
                          <span style={{marginLeft: '0.5vw'}}>{shift.bijz_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text" style={{ backgroundColor: `#${shift.bijz_kleur}` }}>
                        <p style={{ color: Constants.getContrast(`#${shift.bijz_kleur}`), fontSize: '0.5vw' }}>{shift.bijz_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {skillsRender.length > 0 ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                        <p><i className='fas fa-graduation-cap' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

                <div className="plannerZ_touchbox_sub2_row">

                  {Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ backgroundColor: Colors.color.orangeVacation }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}
                
                  <div className="plannerZ_touchbox_iconContainer_text" style={{ marginLeft: '0.1vw', backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.7) }}>
                    <p aria-hidden={true} style={{ color: Constants.getContrast(`#${shift.functie_kleur}`) }}>{countDayShifts[shiftKey]}</p>
                  </div>

                </div>

              </div>

            </div> */}

          </div>

          {menuOpen === true ?
            this.renderDayShiftMenu(shiftKey, day, day === 0 || day === 5 || day === 6, shift)
          : null}

        </div>

      );

    }

    return toRender;

  }

  renderTouchBox_list_plan(user, plan, day) {

    const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    const functionColor = plan.functie_kleur !== null ? plan.functie_kleur : 'EBEBEB';
    const functionName = plan.functie_naam !== null ? plan.functie_naam : 'Functie onbekend';

    const av = this.state.planAccounts[user.info_id][dayKey];
    const planKey = `plan${plan.rooster_id}`;

    const absence = av.absence.length > 0 ? av.absence[0] : null;

    const menuOpen = Constants.isEmpty(this.state.menu_plan_open[planKey]) ? false : this.state.menu_plan_open[planKey];

    let hasAllSkills = true;
    let skillsRender = [];

    // plan has skills
    if (Constants.isEmpty(this.state.skills[plan.rooster_id]) === false) {

      // has all skills?
      hasAllSkills = Constants.isEmpty(plan.rooster_info_id) || Plan.userHasAllSkills(this.state.skills[plan.rooster_id], this.state.skillsAccounts[user.info_id]);

      // iterate through plan skills to show which one is missing
      for (const skill of this.state.skills[plan.rooster_id]) {
        skillsRender.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(this.state.skillsAccounts[user.info_id], skill) || Constants.isEmpty(plan.rooster_info_id) ? null : Colors.color.redFilledIn}}>• {skill.comp_naam}</span>
          </div>
        );
      }

    }

    // teams
    let other_team = null;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      if (Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && this.teamPlanData[plan.rooster_id].team_id !== Data.data.chosenTeamObj.team_id) {
        other_team = this.teamPlanData[plan.rooster_id];
      }
    }

    // if (other_team !== null && this.state.showTeams === false) {
    //   return (null);
    // }

    return (

      <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false && this.state.selected !== planKey ? this.onDropPlan(e, user, plan, day) : Constants.isEmpty(this.state.selected) === false ? this.onClickTile(planKey, user, plan, null, day, 'PLAN') : null : this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}>
        {/* <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false && this.state.selected !== planKey ? this.onDropPlan(e, user, plan, day) : this.onClickTile(planKey, user, plan, null, day, 'PLAN') : this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}> */}

        <div 
          className={`plannerZ_touchbox_list_outer ${this.state.selected === planKey ? 'blink' : ''} planMenuAsset`}
          onMouseOver={() => this.state.selectShifts === true && Constants.isEmpty(this.state.selected) === true ? this.setState({ showHover_select: planKey }) : null}
          onMouseLeave={() => this.state.selectShifts === true ? this.setState({ showHover_select: null }) : null}
          draggable={true}
          onDragStart={() => { this.from = plan; this.fromType = 'PLAN'; this.closeAllPlanMenus(); }}
          onDrop={(e) => this.onDropPlan(e, user, plan, day)}
          onDragOver={(e) => this.onDragOver(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
        >          

          {this.state.display === 'LIST' && (Constants.isEmpty(this.state.planList[dayKey].isLoading) || this.state.planList[dayKey].isLoading === false) && (this.state.planAccounts[user.info_id][dayKey].isLoading === true || (Constants.isEmpty(user.info_id) === true && this.state.planAccounts[user.info_id][dayKey].isLoading === plan.functie_id)) ?
            <div className="loaderPlanner_list lds-dual-ring-plan" />
          : null}

          {this.state.selectShifts === true && this.state.showHover_select === planKey ?
            <div className='plannerZ_touchbox_hover planMenuAsset'>
              <div className='plannerZ_touchbox_hover_sub1 planMenuAsset' onClick={() => this.canOpenPlanMenu === true ? this.openPlanMenu(planKey, null, user, plan) : null}>
                <p><i className='far fa-calendar-lines-pen planMenuAsset' /></p>
              </div>
              <div className='plannerZ_touchbox_hover_sub2 planMenuAsset' onClick={() => this.onClickTile(planKey, user, plan, null, day, 'PLAN')}>
                <p><i className='far fa-hand-pointer planMenuAsset' /></p>
              </div>
            </div>
          : null}

          {/* <div className='plannerZ_touchbox_list_hover'>
            <div className='plannerZ_touchbox_list_hover_sub1'>
              <p><i className='far fa-calendar-lines-pen' /></p>
            </div>
            <div className='plannerZ_touchbox_list_hover_sub2'>
              <p><i className='far fa-arrows-up-down-left-right' /></p>
            </div>
          </div> */}

          <div 
            className="plannerZ_touchbox_list planMenuAsset" 
            style={{ backgroundColor: Constants.isEmpty(other_team) === false ? Constants.hexToRgbA(Colors.color.greyNotFilled, 0.2) : Constants.hexToRgbA(`#${functionColor}`, 0.35) }}         
          >

            <div className="plannerZ_touchbox_list_sub3 planMenuAsset">
              {Constants.isEmpty(user.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${user.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
              Constants.isEmpty(plan.rooster_info_id) ?
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: Colors.color.redFilledIn }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: 'white', fontSize: '0.5vw' }} /></p>
                </div>
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>

            <div className="plannerZ_touchbox_list_sub1 planMenuAsset" style={{ width: Constants.isEmpty(other_team) ? null : '59%' }}>

              <div className="plannerZ_touchbox_list_sub1_row planMenuAsset">

                {Constants.isEmpty(plan.rooster_info_id) ?
                  <span className='planMenuAsset' style={{ fontSize: '0.6vw', color: Colors.color.redFilledIn, fontWeight: 'bold' }}>{__('home_overview_openShift')}</span>
                :
                  <span className='planMenuAsset' style={{ fontSize: '0.6vw' }}>{userFullName}</span>
                }

              </div>

              <div className="plannerZ_touchbox_list_sub1_row planMenuAsset" style={{ cursor: 'pointer' }}>

                <span className="plannerZ_touchbox_list_time planMenuAsset" style={{ color: plan.rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : null }}>{plan.rooster_begin.substr(0, 5)} - {plan.rooster_eind.substr(0, 5)}</span>

                {plan.rooster_pauze !== '00:00:00' ?
                  <span className='plannerZ_touchbox_list_break planMenuAsset'>{plan.rooster_pauze.substr(0, 5)}</span>
                : null}

              </div>

            </div>

            {Constants.isEmpty(other_team) === true ?

              <div className="plannerZ_touchbox_list_sub2 planMenuAsset">

                <div className="plannerZ_touchbox_list_sub2_row planMenuAsset">

                  {Constants.isEmpty(absence) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='far fa-user-minus'></i>
                          <span style={{marginLeft: '0.5vw'}}>{absence.afw_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer_text planMenuAsset" style={{ backgroundColor: Colors.color.redFilledIn }}>
                        <p className='planMenuAsset' style={{ color: 'white', fontSize: '0.5vw' }}>{absence.afw_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {Constants.isEmpty(plan.bijz_id) === false && Constants.isEmpty(absence) === true ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <b style={{ color: `#${plan.bijz_kleur}` }}>{plan.bijz_afkorting}</b>
                          <span style={{marginLeft: '0.5vw'}}>{plan.bijz_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_list_iconContainer_text planMenuAsset" style={{ backgroundColor: `#${plan.bijz_kleur}` }}>
                        {/* <i className='fas fa-tag' aria-hidden={true} style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`) }} /> */}
                        <p className='planMenuAsset' style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`), fontSize: '0.5vw' }}>{plan.bijz_afkorting}</p>
                      </div>
                    </Tooltip>
                  : null}

                  {hasAllSkills === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='far fa-exclamation-triangle'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_userNoSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_list_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.redFilledIn }}>
                        <p><i className='fas fa-exclamation-triangle planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : 
                  hasAllSkills === true && Constants.isEmpty(this.state.skills[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                          {skillsRender.length > 0 ?
                            <div style={{marginTop: '0.5vw', marginLeft: '1.2vw', color: Constants.isEmpty(plan.rooster_info_id) ? null : Colors.color.greenFilledIn}}>
                              {skillsRender}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_list_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                        <p><i className='fas fa-graduation-cap planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

                <div className="plannerZ_touchbox_list_sub2_row planMenuAsset">

                  {Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_list_iconContainer planMenuAsset" style={{ backgroundColor: Colors.color.orangeVacation }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {plan.rooster_publiceren === '0' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='fas fa-exclamation'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_notPublished')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_list_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Colors.color.redFilledIn }}>
                        <p><i className='fas fa-exclamation planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

              </div>

            : 
            
              <div className="plannerZ_touchbox_sub2 planMenuAsset" style={{ width: Constants.isEmpty(other_team) ? null : '29%' }}>

                <div className="plannerZ_touchbox_sub2_row planMenuAsset">
                  {Constants.isEmpty(other_team) === false ?
                    <span className='plannerZ_touchbox_break planMenuAsset' style={{ color: '#282828', textAlign: 'right' }}>{other_team.team_naam}</span>
                  : null}
                </div>

                <div className="plannerZ_touchbox_sub2_row planMenuAsset">

                  {Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === false ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.6) }}>
                        <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                  {plan.rooster_publiceren === '0' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerZ_tooltipContainer'>
                          <i style={{ color: Colors.color.redFilledIn }} className='fas fa-exclamation'></i>
                          <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_notPublished')}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      arrowSize='small'
                      theme='tiemdo'
                    >
                      <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ marginLeft: '0.1vw', backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.6) }}>
                        <p><i className='fas fa-exclamation planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                      </div>
                    </Tooltip>
                  : null}

                </div>

              </div>
            
            }

          </div>

        </div>

        {menuOpen === true ?
          this.renderPlanMenu(planKey, this.state.menu_selectedJob, user, av, day, day === 0 || day === 5 || day === 6, plan, 'LIST')
        : null}

      </div>

    );

  }

  renderTouchBox_list_av(func, day, row) {

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const date = Constants.stringToDate(dateString);

    const avKey = `av_list${func.functie_id}${dayKey}${row}`;

    let user = null;
    let av = null;

    if (Constants.isEmpty(this.state.menu_selectedEmployee) === false) {
      user = this.state.menu_selectedEmployee;
      av = this.state.planAccounts[user.info_id][dayKey];
    }

    // const holiday = Constants.objectArrayContainsKey(av.holidays, 'dateString', dateString);
    // const contract = Constants.getCurrentContract(this.state.contracts[user.info_id], date);

    // const blocked = (holiday || Constants.isEmpty(contract)) && Constants.isEmpty(user.info_id) === false;

    // const renderAvailLabels = this.state.opties_infoplanner_verborgen === true ? this.renderAvailLabels(user, date, av, false) : [];

    const menuOpen = Constants.isEmpty(this.state.menu_plan_open[avKey]) ? false : this.state.menu_plan_open[avKey];

    return (

      <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts && Constants.isEmpty(this.state.selected) === false ? this.onDropAv(e, null, null, day, func) : this.canOpenPlanMenu === true ? this.openPlanMenu(avKey, func, null, null) : null}>

        <div 
          className="plannerZ_touchbox_empty_outer planMenuAsset"
          onDrop={(e) => this.onDropAv(e, null, null, day, func)}
          onDragOver={(e) => this.onDragOverOther(e)}
          onDragLeave={(e) => this.onDragLeaveOther(e)}
          // style={{ cursor: blocked ? 'not-allowed' : null }}
        >

          <div className="plannerZ_touchbox_empty planMenuAsset">

            <div className="plannerZ_touchbox_empty_sub1 planMenuAsset">

              <div className="plannerZ_touchbox_empty_sub1_row planMenuAsset">

                {/* {Constants.isEmpty(user.info_id) === false && holiday === true ?
                  <div className="plannerZ_touchbox_label" style={{ backgroundColor: Colors.color.orangeVacation }}>
                    <p aria-hidden={true} style={{ color: 'white' }}>{__('misc_leave')}</p>
                  </div>
                :
                Constants.isEmpty(user.info_id) === false && Constants.isEmpty(contract) === true && this.state.opties_contractVerlopen === true ?
                  <div className="plannerZ_touchbox_label" style={{ backgroundColor: Colors.color.pastBorderColor }}>
                    <p aria-hidden={true} style={{ color: 'white' }}>{__('misc_contractExpired')}</p>
                  </div>
                :
                renderAvailLabels.length > 0 ?
                  <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%' }}>
                    {renderAvailLabels}
                  </div>
                : */}
                  <span className='planMenuAsset'>&nbsp;</span>
                {/* } */}

              </div>

              {/* <div className="plannerZ_touchbox_empty_sub1_row">

                <span>&nbsp;</span>

              </div> */}

            </div>

            {/* <div className="plannerZ_touchbox_empty_sub2">

              <div className="plannerZ_touchbox_empty_sub2_row">

                <span>&nbsp;</span>

              </div>

              <div className="plannerZ_touchbox_empty_sub2_row">

                <span>&nbsp;</span>

              </div>

            </div> */}

          </div>

        </div>

        {menuOpen === true ?
          this.renderPlanMenu(avKey, func, user, av, day, day === 0 || day === 5 || day === 6, null, 'LIST')
        : null}

      </div>

    );

  }

  renderTouchBox_list_dayShift(shift, day, func, row) {

    // GET DAY DATA
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    // SKILLS DAYSHIFT CHECK
    let skillsString = ``;
    const skills = this.state.skillsDayShifts[shift.dagdienst_id];
    if (Constants.isEmpty(skills) === false) {
      for (const skill of skills) {
        skillsString += `${skill.comp_id}`;
      }
    } else {
      skillsString = `null`;
    }

    const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}${row}`;

    // SKILL DATA
    let skillsRender = [];

    // shift has skills
    if (Constants.isEmpty(this.state.skillsDayShifts[shift.dagdienst_id]) === false) {
      // iterate through shift skills to show skills
      for (const skill of this.state.skillsDayShifts[shift.dagdienst_id]) {
        skillsRender.push(
          <div>
            <span style={{marginLeft: 0}}>• {skill.comp_naam}</span>
          </div>
        );
      }
    }

    const menuOpen = Constants.isEmpty(this.state.menu_dayShift_open[shiftKey]) ? false : this.state.menu_dayShift_open[shiftKey];

    return (
      <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts && Constants.isEmpty(this.state.selected) === false ? this.onDropShift(e, shift, day, func) : this.openDayShiftMenu(shiftKey, shift)}>

        <div 
          className="plannerZ_touchbox_outer" 
          onDrop={(e) => this.onDropShift(e, shift, day, func)}
          onDragOver={(e) => this.onDragOver(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
          // draggable={true}
          // onDragStart={() => { this.from = shift; this.fromType = 'SHIFT'; this.closeAllPlanMenus(); }}
        >

          {/* {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null} */}

          {/* <div className="plannerZ_touchbox" style={{  }}> */}
          <div 
            className="plannerZ_touchbox" 
            style={{ backgroundColor: Constants.hexToRgbA(`#${shift.functie_kleur}`, 0.15) }}         
          >

            <div className="plannerZ_touchbox_sub1">

              <div className="plannerZ_touchbox_sub1_row">

                <span style={{ fontSize: '0.6vw' }}>{__('misc_defaultShift')}</span>

              </div>

              <div className="plannerZ_touchbox_sub1_row" style={{ cursor: 'pointer' }}>

                <span className="plannerZ_touchbox_time" style={{  }}>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>

                {shift.dagdienst_pauze !== '00:00:00' ?
                  <span className='plannerZ_touchbox_break'>{shift.dagdienst_pauze.substr(0, 5)}</span>
                : null}

              </div>

            </div>

            <div className="plannerZ_touchbox_sub2">

              <div className="plannerZ_touchbox_sub2_row">

                {Constants.isEmpty(shift.bijz_id) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <b style={{ color: `#${shift.bijz_kleur}` }}>{shift.bijz_afkorting}</b>
                        <span style={{marginLeft: '0.5vw'}}>{shift.bijz_naam}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    arrowSize='small'
                    theme='tiemdo'
                  >
                    <div className="plannerZ_touchbox_iconContainer_text" style={{ backgroundColor: `#${shift.bijz_kleur}` }}>
                      {/* <i className='fas fa-tag' aria-hidden={true} style={{ color: Constants.getContrast(`#${plan.bijz_kleur}`) }} /> */}
                      <p style={{ color: Constants.getContrast(`#${shift.bijz_kleur}`), fontSize: '0.5vw' }}>{shift.bijz_afkorting}</p>
                    </div>
                  </Tooltip>
                : null}

                {skillsRender.length > 0 ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <i style={{ color: '#022F40' }} className='far fa-graduation-cap'></i>
                        <span style={{marginLeft: '0.5vw'}}>{__('planner_web_screen_shiftSkills')}</span>
                        {skillsRender.length > 0 ?
                          <div style={{marginTop: '0.5vw', marginLeft: '1.2vw'}}>
                            {skillsRender}
                          </div>
                        : null}
                      </div>
                    )}
                    position={'top'}
                    arrow
                    arrowSize='small'
                    theme='tiemdo'
                  >
                    <div className="plannerZ_touchbox_iconContainer" style={{ marginLeft: '0.1vw', backgroundColor: '#022F40' }}>
                      <p><i className='fas fa-graduation-cap' aria-hidden={true} style={{ color: 'white' }} /></p>
                    </div>
                  </Tooltip>
                : null}

              </div>

              <div className="plannerZ_touchbox_sub2_row">

                {Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <i style={{ color: Colors.color.orangeVacation }} className='far fa-magnifying-glass'></i>
                        <span style={{marginLeft: '0.5vw'}}>{__('misc_availableShift')}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    arrowSize='small'
                    theme='tiemdo'
                  >
                    <div className="plannerZ_touchbox_iconContainer planMenuAsset" style={{ backgroundColor: Colors.color.orangeVacation }}>
                      <p><i className='far fa-magnifying-glass planMenuAsset' aria-hidden={true} style={{ color: 'white' }} /></p>
                    </div>
                  </Tooltip>
                : null}

              </div>

            </div>

          </div>

        </div>

        {menuOpen === true ?
          this.renderDayShiftMenu(shiftKey, day, day === 0 || day === 5 || day === 6, shift, func)
        : null}

      </div>
    );

  }

  renderTouchBox_list_employee(userID, color = Colors.color.greyNotFilled, day) {

    const newKey = Constants.isEmpty(day) === false ? `${userID}${day}` : userID;

    // GET DAY DATA
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const date = Constants.stringToDate(dateString);

    const userData = this.state.planAccounts[userID][dayKey];
    let user = this.state.accountsObj[userID];

    const blocked = this.state.planAccounts[userID][dayKey].holidays.length > 0;

    const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;

    const renderAvailLabels = this.renderAvailLabels_list(user, date, userData, false, newKey);

    const menuOpen = Constants.isEmpty(this.state.menu_employee_open[newKey]) ? false : this.state.menu_employee_open[newKey];
    const menuHolidayOpen = Constants.isEmpty(this.state.menu_holiday_open[newKey]) ? false : this.state.menu_holiday_open[newKey];

    return (
      <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => blocked ? this.openEmployeeMenu(user, day) : e.target.className.includes('planHoliday') ? null : this.state.selectShifts ? Constants.isEmpty(this.state.selected) === false ? this.onClickTile(newKey, user, { ...user, dateFrom: dateString }, null, day, 'AV') : null : Constants.isEmpty(user) || Constants.isEmpty(user.info_id) ? null : this.openEmployeeMenu(user, day)}>
        {/* <div className='planMenuAsset' style={{ position: 'relative' }} onClick={(e) => this.state.selectShifts ? this.onClickTile(newKey, user, { ...user, dateFrom: dateString }, null, day, 'AV') : Constants.isEmpty(user) || Constants.isEmpty(user.info_id) ? null : this.openEmployeeMenu(user, day)}> */}

        <div 
          className={`plannerZ_touchbox_outer_employee ${this.state.selected === newKey ? 'blink' : ''}`}
          onMouseOver={() => blocked ? null : this.state.selectShifts === true && Constants.isEmpty(this.state.selected) === true ? this.setState({ showHover_select: newKey }) : null}
          onMouseLeave={() => blocked ? null : this.state.selectShifts === true ? this.setState({ showHover_select: null }) : null}
          draggable={blocked === false}
          onDragStart={blocked ? null : () => { this.from = { ...user, dateFrom: dateString }; this.fromType = 'AV'; this.closeAllPlanMenus(); this.setState({ menu_employee_open: {} }); }}
        >

          {this.state.selectShifts === true && this.state.showHover_select === newKey ?
            <div className='plannerZ_touchbox_hover planMenuAsset'>
              <div className='plannerZ_touchbox_hover_sub1 planMenuAsset' onClick={() => this.openEmployeeMenu(user, day)}>
                <p><i className='far fa-user-pen planMenuAsset' /></p>
              </div>
              <div className='plannerZ_touchbox_hover_sub2 planMenuAsset' onClick={() => this.onClickTile(newKey, user, { ...user, dateFrom: dateString }, null, day, 'AV')}>
                <p><i className='far fa-hand-pointer planMenuAsset' /></p>
              </div>
            </div>
          : null}

          {/* {this.state.planAccounts[user.info_id][dayKey].isLoading === true ?
            <div className="loaderPlanner lds-dual-ring-plan" />
          : null} */}

          {/* <div className="plannerZ_touchbox" style={{  }}> */}
          <div 
            className="plannerZ_touchbox_employee" 
            style={{ backgroundColor: Constants.hexToRgbA(`${color}`, 0.3) }}         
          >

            <div className="plannerZ_touchbox_employee_sub3">
              {Constants.isEmpty(user.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${user.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>

            <div className="plannerZ_touchbox_employee_sub1">

              <div className="plannerZ_touchbox_employee_sub1_row">

                <span className="plannerZ_touchbox_employee_time" style={{  }}>{userFullName}</span>

              </div>

              {Constants.isEmpty(user.functie_id) === false ?
                <div className="plannerZ_touchbox_employee_sub1_row" style={{ marginTop: '0vw' }}>
                  <span className='plannerZ_touchbox_break' style={{ color: '#282828', margin: 0 }}>{user.functie_naam}</span>
                </div>
              : null}

              {renderAvailLabels.length > 0 && blocked === false ?
                <div className="plannerZ_touchbox_employee_sub1_row" style={{ marginTop: '0.2vw' }}>
                  <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%' }}>
                    {renderAvailLabels}
                  </div>
                </div>
              : null}

              {color === Colors.color.orangeVacation ?
                <div className="plannerZ_touchbox_employee_sub1_row" style={{ marginTop: '0.2vw' }}>
                  <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%' }}>
                    <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.orangeVacation, color: 'white', marginTop: 0 }}>
                      <span className='planMenuAsset'>{__('misc_leave')}</span>
                    </div>
                  </div>
                </div>
              : null}

            </div>

            {/* <div className="plannerZ_touchbox_sub1">

              <div className="plannerZ_touchbox_sub1_row">

                <span style={{ fontSize: '0.6vw' }}>{__('misc_defaultShift')}</span>

              </div>

              <div className="plannerZ_touchbox_sub1_row" style={{ cursor: 'pointer' }}>

                <span className="plannerZ_touchbox_time" style={{  }}>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>

                {shift.dagdienst_pauze !== '00:00:00' ?
                  <span className='plannerZ_touchbox_break'>{shift.dagdienst_pauze.substr(0, 5)}</span>
                : null}

              </div>

            </div>

            <div className="plannerZ_touchbox_sub2">

              <div className="plannerZ_touchbox_sub2_row">

          

              </div>

            </div> */}

          </div>

        </div>

        {menuOpen === true ?
          this.renderEmployeeMenu(user, true, day === 0 || day === 6)
        : null}

        {menuHolidayOpen === true ?
          this.renderHolidayMenu(user, userData, day === 0 || day === 5 || day === 6)
        : null}

      </div>
    );

  }

  renderAvailLabels(user, date, userAvail, column) {

    let toReturn = [];
    let isAvailable = false;
    let isUnavailableAllDay = false;
    let isSchoolHoliday = false;

    // BIRTHDAY
    let birthday = false;
    if (Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false) {
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(date.getFullYear());
      if (Constants.dateToString(new Date(date)) === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }
    if (birthday === true) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: '#E362EE', color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          <span className='planMenuAsset'>{__('misc_birthday')}</span>
        </div>
      );
    }

    // AVAILABILITY
    for (const av of userAvail.av) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.beschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')} <i className='fas fa-comment' /></span>
              </Tooltip>
            :
              <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
          Constants.isEmpty(av.beschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)} <i className='fas fa-comment' /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      isAvailable = true;
    }

    // UNAVAILABILITY
    for (const av of userAvail.unav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')} <i className='fas fa-comment' /></span>
              </Tooltip>
            :
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
          Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)} <i className='fas fa-comment' /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      if (av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00') {
        isUnavailableAllDay = true;
      }
    }

    // STANDARD AVAILABILITY
    for (const av of userAvail.sav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // STANDARD UNAVAILABILITY
    for (const av of userAvail.sunav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // SCHOOL HOLIDAY
    if (isAvailable === false && isUnavailableAllDay === false) {
      for (const av of userAvail.schoolHoliday) {
        toReturn.push(
          <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{__('misc_schoolHoliday')}</span>
          </div>
        );
        isSchoolHoliday = true;
      }
    }

    // SCHOOL
    if (isAvailable === false && isUnavailableAllDay === false && isSchoolHoliday === false) {
      for (const av of userAvail.school) {
        toReturn.push(
          <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodSchool'), `${av.schoolrooster_begin.substr(0, 5)} - ${av.schoolrooster_eind.substr(0, 5)}`)}</span>
          </div>
        );
      }
    }

    return toReturn;

  } 

  renderAvailLabels_av(user, date, userAvail, column, key) {

    let toReturn = [];
    let isAvailable = false;
    let isUnavailableAllDay = false;
    let isSchoolHoliday = false;

    // HOLIDAYREQUEST
    let holidayRequests = false;
    for (const av of userAvail.holidayRequests) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset planHoliday' style={{ color: Colors.color.orangeVacation, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0, cursor: 'pointer' }} onClick={() => this.openHolidayMenu(key)}>
          <span className='planMenuAsset planHoliday'>{__('home_overview_leaveRequest_title')} <i className='far fa-arrow-up-right-from-square planHoliday' /></span>
        </div>
      );
      holidayRequests = true;
    }

    if (holidayRequests === true) {
      return toReturn;
    }

    // BIRTHDAY
    let birthday = false;
    if (Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false) {
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(date.getFullYear());
      if (Constants.dateToString(new Date(date)) === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }
    if (birthday === true) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ color: '#E362EE', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          <span className='planMenuAsset'>{__('misc_birthday')}</span>
        </div>
      );
    }

    // AVAILABILITY
    for (const av of userAvail.av) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ color: Colors.color.greenFilledIn, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.beschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')} <i className='far fa-comment' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /></span>
              </Tooltip>
            :
              <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
          Constants.isEmpty(av.beschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)} <i className='far fa-comment' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      isAvailable = true;
    }

    // UNAVAILABILITY
    for (const av of userAvail.unav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ color: Colors.color.redFilledIn, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')} <i className='far fa-comment' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /></span>
              </Tooltip>
            :
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
          Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)} <i className='far fa-comment' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      if (av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00') {
        isUnavailableAllDay = true;
      }
    }

    // STANDARD AVAILABILITY
    for (const av of userAvail.sav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ color: Colors.color.greenFilledIn, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // STANDARD UNAVAILABILITY
    for (const av of userAvail.sunav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ color: Colors.color.redFilledIn, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // SCHOOL HOLIDAY
    if (isAvailable === false && isUnavailableAllDay === false) {
      for (const av of userAvail.schoolHoliday) {
        toReturn.push(
          <div className='plannerZ_avail_label planMenuAsset' style={{ color: Colors.color.greenFilledIn, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{__('misc_schoolHoliday')}</span>
          </div>
        );
        isSchoolHoliday = true;
      }
    }

    // SCHOOL
    if (isAvailable === false && isUnavailableAllDay === false && isSchoolHoliday === false) {
      for (const av of userAvail.school) {
        toReturn.push(
          <div className='plannerZ_avail_label planMenuAsset' style={{ color: Colors.color.redFilledIn, marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodSchool'), `${av.schoolrooster_begin.substr(0, 5)} - ${av.schoolrooster_eind.substr(0, 5)}`)}</span>
          </div>
        );
      }
    }

    return toReturn;

  } 

  renderAvailLabels_excel(user, date, userAvail, column) {

    let toReturn = [];
    let isAvailable = false;
    let isUnavailableAllDay = false;
    let isSchoolHoliday = false;

    // BIRTHDAY
    let birthday = false;
    if (Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false) {
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(date.getFullYear());
      if (Constants.dateToString(new Date(date)) === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }
    if (birthday === true) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: '#E362EE', color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          <span className='planMenuAsset'>{__('misc_birthday')}</span>
        </div>
      );
    }

    // AVAILABILITY
    for (const av of userAvail.av) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.beschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')} <i className='fas fa-comment' /></span>
              </Tooltip>
            :
              <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
          Constants.isEmpty(av.beschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)} <i className='fas fa-comment' /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      isAvailable = true;
    }

    // UNAVAILABILITY
    for (const av of userAvail.unav) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')} <i className='fas fa-comment' /></span>
              </Tooltip>
            :
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
          Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)} <i className='fas fa-comment' /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      if (av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00') {
        isUnavailableAllDay = true;
      }
    }

    // STANDARD AVAILABILITY
    for (const av of userAvail.sav) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // STANDARD UNAVAILABILITY
    for (const av of userAvail.sunav) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // SCHOOL HOLIDAY
    if (isAvailable === false && isUnavailableAllDay === false) {
      for (const av of userAvail.schoolHoliday) {
        toReturn.push(
          <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{__('misc_schoolHoliday')}</span>
          </div>
        );
        isSchoolHoliday = true;
      }
    }

    // SCHOOL
    if (isAvailable === false && isUnavailableAllDay === false && isSchoolHoliday === false) {
      for (const av of userAvail.school) {
        toReturn.push(
          <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodSchool'), `${av.schoolrooster_begin.substr(0, 5)} - ${av.schoolrooster_eind.substr(0, 5)}`)}</span>
          </div>
        );
      }
    }

    return toReturn;

  } 

  renderAvailLabels_av_excel(user, date, userAvail, column) {

    let toReturn = [];
    let isAvailable = false;
    let isUnavailableAllDay = false;
    let isSchoolHoliday = false;

    // BIRTHDAY
    let birthday = false;
    if (Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false) {
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(date.getFullYear());
      if (Constants.dateToString(new Date(date)) === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }
    if (birthday === true) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: '#E362EE', color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
          <span className='planMenuAsset'>{__('misc_birthday')}</span>
        </div>
      );
    }

    // Constants.isEmpty(av.beschikbaar_opmerking) === false ?
    // <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
    // AVAILABILITY
    for (const av of userAvail.av) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
          {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      
      if (Constants.isEmpty(av.beschikbaar_opmerking) === false) {
        toReturn.push(<div style={{marginTop: '0.1vw', whiteSpace: 'pre-line', fontSize: '0.6vw'}}><i className='far fa-comment' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /> {av.beschikbaar_opmerking}</div>);
      }

      isAvailable = true;
    }

    // UNAVAILABILITY
    for (const av of userAvail.unav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
          {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );

      if (Constants.isEmpty(av.onbeschikbaar_opmerking) === false) {
        toReturn.push(<div style={{marginTop: '0.1vw', whiteSpace: 'pre-line', fontSize: '0.6vw'}}><i className='far fa-comment' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /> {av.onbeschikbaar_opmerking}</div>);
      }

      if (av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00') {
        isUnavailableAllDay = true;
      }
    }

    // STANDARD AVAILABILITY
    for (const av of userAvail.sav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // STANDARD UNAVAILABILITY
    for (const av of userAvail.sunav) {
      toReturn.push(
        <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // SCHOOL HOLIDAY
    if (isAvailable === false && isUnavailableAllDay === false) {
      for (const av of userAvail.schoolHoliday) {
        toReturn.push(
          <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
            <span className='planMenuAsset'>{__('misc_schoolHoliday')}</span>
          </div>
        );
        isSchoolHoliday = true;
      }
    }

    // SCHOOL
    if (isAvailable === false && isUnavailableAllDay === false && isSchoolHoliday === false) {
      for (const av of userAvail.school) {
        toReturn.push(
          <div className='plannerZ_avail_label planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.3vw' : 0 }}>
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodSchool'), `${av.schoolrooster_begin.substr(0, 5)} - ${av.schoolrooster_eind.substr(0, 5)}`)}</span>
          </div>
        );
      }
    }

    return toReturn;

  } 

  renderAvailLabels_list(user, date, userAvail, column, key) {

    let toReturn = [];
    let isAvailable = false;
    let isUnavailableAllDay = false;
    let isSchoolHoliday = false;

    if (Constants.isEmpty(key) === false) {
      for (const av of userAvail.holidayRequests) {
        toReturn.push(
          <div className='plannerZ_avail_label_excel planMenuAsset planHoliday' style={{ backgroundColor: Colors.color.orangeVacation, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0, cursor: 'pointer' }} onClick={() => this.openHolidayMenu(key)}>
            <span className='planMenuAsset planHoliday'>{__('home_overview_leaveRequest_title')} <i className='far fa-arrow-up-right-from-square planHoliday' /></span>
          </div>
        );
      }
    }

    // BIRTHDAY
    let birthday = false;
    if (Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false) {
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(date.getFullYear());
      if (Constants.dateToString(new Date(date)) === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }
    if (birthday === true) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: '#E362EE', color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          <span className='planMenuAsset'>{__('misc_birthday')}</span>
        </div>
      );
    }

    // AVAILABILITY
    for (const av of userAvail.av) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.beschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')} <i className='fas fa-comment' /></span>
              </Tooltip>
            :
              <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
          Constants.isEmpty(av.beschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.beschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)} <i className='fas fa-comment' /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.beschikbaar_begin.substr(0, 5)} - ${av.beschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      isAvailable = true;
    }

    // UNAVAILABILITY
    for (const av of userAvail.unav) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
            Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
              <Tooltip
                options
                html={(
                  <div className='plannerZ_tooltipContainer'>
                    <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                    <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                  </div>
                )}
                position={'top'}
                arrow
                arrowSize='small'
                theme='tiemdo'
              >
                <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')} <i className='fas fa-comment' /></span>
              </Tooltip>
            :
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
          Constants.isEmpty(av.onbeschikbaar_opmerking) === false ?
            <Tooltip
              options
              html={(
                <div className='plannerZ_tooltipContainer'>
                  <i style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} className='far fa-comment'></i>
                  <span style={{marginLeft: '0.5vw', whiteSpace: 'pre-line'}}>{av.onbeschikbaar_opmerking}</span>
                </div>
              )}
              position={'top'}
              arrow
              arrowSize='small'
              theme='tiemdo'
            >
              <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)} <i className='fas fa-comment' /></span>
            </Tooltip>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.onbeschikbaar_begin.substr(0, 5)} - ${av.onbeschikbaar_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
      if (av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00') {
        isUnavailableAllDay = true;
      }
    }

    // STANDARD AVAILABILITY
    for (const av of userAvail.sav) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayAvailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodAvailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    // STANDARD UNAVAILABILITY
    for (const av of userAvail.sunav) {
      toReturn.push(
        <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.redFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
          {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
            <span className='planMenuAsset'>{__('planner_screen_wholeDayUnavailable')}</span>
          :
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodUnavailable'), `${av.sb_begin.substr(0, 5)} - ${av.sb_eind.substr(0, 5)}`)}</span>
          }
        </div>
      );
    }

    if (Data.data.appOptions.availability === 'SCHOOL') {
      if (userAvail.school.length === 0 && userAvail.schoolHoliday.length === 0 && userAvail.av.length === 0 && userAvail.unav.length === 0) {
        toReturn.push(
          <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{__('misc_noSchool')}</span>
          </div>
        );
      }
    }

    // SCHOOL HOLIDAY
    if (isAvailable === false && isUnavailableAllDay === false) {
      for (const av of userAvail.schoolHoliday) {
        toReturn.push(
          <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greenFilledIn, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{__('misc_schoolHoliday')}</span>
          </div>
        );
        isSchoolHoliday = true;
      }
    }

    // SCHOOL
    if (isAvailable === false && isUnavailableAllDay === false && isSchoolHoliday === false) {
      for (const av of userAvail.school) {
        toReturn.push(
          <div className='plannerZ_avail_label_excel planMenuAsset' style={{ backgroundColor: Colors.color.greyNotFilled, color: 'white', marginTop: column && toReturn.length > 0 ? '0.2vw' : 0 }}>
            <span className='planMenuAsset'>{Localization.t.formatString(__('planner_screen_periodSchool'), `${av.schoolrooster_begin.substr(0, 5)} - ${av.schoolrooster_eind.substr(0, 5)}`)}</span>
          </div>
        );
      }
    }

    return toReturn;

  } 

  renderSkills() {

    let toReturn = ``;

    for (const skill of this.state.menu_selectedSkills) {

      let str = ``;

      if (toReturn.length > 0) {
        str = `, ${skill.comp_afkorting}`;
      } else {
        str = skill.comp_afkorting;
      }

      toReturn += str;

    }

    return toReturn;

  }

  renderSkillsDayShift() {

    let toReturn = [];

    for (const skill of this.state.menu_selectedSkills) {

      toReturn.push(
        <div><span>• {skill.comp_afkorting}</span></div>
      );

    }

    return toReturn;

  }

  renderPlanMenu(key, func, user, av, day, left, plan, type) {

    let userFullName = ``;
    let absence = null;

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const date = Constants.stringToDate(dateString);

    const renderAvailLabels = type === 'LIST' && Constants.isEmpty(user) && Constants.isEmpty(av) ? [] : this.renderAvailLabels(user, date, av, true);
    const renderSkills = this.renderSkills();

    const isPlan = Constants.isEmpty(plan) === false;

    if (type === 'LIST' && Constants.isEmpty(this.state.menu_selectedEmployee) === false) {

      user = this.state.menu_selectedEmployee;
      userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;

      if (Constants.isEmpty(user.info_id) === false) {
        av = this.state.planAccounts[user.info_id][dayKey];
        absence = av.absence.length > 0 ? av.absence[0] : null;
      }

    }

    // teams
    let team = null;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      team = this.state.menu_selectedTeam;
    }

    return (

      <div 
        className={`plannerZ_planMenu_container${left ? '_left' : ''} planMenuAsset`}
        onKeyDown={(e) => this.handleKey(e, key, user, dateString, day, plan, func)}
      >

        {/* {left ?
          <div className='plannerZ_planMenu_arrowRight' />
        :
          <div className='plannerZ_planMenu_arrowLeft' />
        } */}

        {isPlan || (isPlan === false && Data.data.appOptions.opties_infoplanner_verborgen === '0') || this.state.display === 'LIST' ?
          <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%', marginBottom: renderAvailLabels.length > 0 ? '1vw' : 0 }}>
            {renderAvailLabels}
          </div>
        : null}

        {type === 'LIST' ?
          <div className='planMenuAsset' style={{position: 'relative'}}>

            <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_employees_open: !this.state.menu_plan_employees_open, menu_plan_teams_open: false, menu_plan_jobs_open: false, menu_plan_breaks_open: false, menu_plan_labels_open: false, menu_plan_skills_open: false, })}>

              <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                <p><i className='far fa-user planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
                <span className='planMenuAsset' style={{ color: this.state.error_employee ? Colors.color.redFilledIn : null }}>{__('misc_employee')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_employees_open ? '#47B7EB' : null }}>
                {Constants.isEmpty(user) ?
                  <span className='plannerZ_hoverable planMenuAsset'>-</span>
                :
                Constants.isEmpty(user.info_id) ?
                  <span className='plannerZ_hoverable planMenuAsset'>{__('home_overview_openShift')}</span>
                : 
                  <span className='plannerZ_hoverable planMenuAsset'>{userFullName}</span>
                }
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_employees_open ? '#47B7EB' : null }}>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>

            {this.state.menu_plan_employees_open === true ?
              this.renderEmployeesMenu(func, left, plan, day, key)
            : null}  

          </div>
        : null}

        {type !== 'XCL' ?
          <div className='plannerZ_planMenu_row planMenuAsset'>

            <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
              <p><i className='far fa-clock planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
              <span className='planMenuAsset' style={{ color: this.state.error_time ? Colors.color.redFilledIn : null }}>{__('misc_time')}</span>
            </div>

            <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
              <input
                ref={(ref) => this.timeRef[key]=ref}
                className='plannerZ_input_time planMenuAsset'
                defaultValue={isPlan ? `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}` : ''}
                placeholder='00:00 - 00:00'
                onChange={(event) => this.onChangeTime(event, plan, key, user, day, false)}
                type={'text'}
                style={
                  isPlan && plan.rooster_tijd_incorrect === '1' ?
                    {color: Colors.color.redFilledIn} 
                  : null
                    // isAvailableShift ?
                    //   {color: '#E6A024'} 
                    // :
                    //   {color: '#04163b'} 
                }
              />
            </div>

          </div>
        : null}

        {team !== null ?
          <div className='planMenuAsset' style={{position: 'relative'}}>

            {Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false ?
              <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_teams_open: !this.state.menu_plan_teams_open, menu_plan_employees_open: false, menu_plan_jobs_open: false, menu_plan_breaks_open: false, menu_plan_labels_open: false, menu_plan_skills_open: false, menu_plan_absence_open: false, })}>

                <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                  <p><i className='far fa-location-dot planMenuAsset' /></p>
                </div>

                <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
                  <span className='planMenuAsset'>{__('misc_team')}</span>
                </div>

                <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_teams_open ? '#47B7EB' : null }}>
                  <span className='plannerZ_hoverable planMenuAsset'>{team.team_naam}</span>
                </div>

                <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_teams_open ? '#47B7EB' : null }}>
                  <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
                </div>

              </div>
            :
              <div className='plannerZ_planMenu_row planMenuAsset'>

                <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
                  <p><i className='far fa-briefcase planMenuAsset' /></p>
                </div>

                <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
                  <span className='planMenuAsset'>{__('misc_team')}</span>
                </div>

                <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
                  <span style={{ fontWeight: 500 }} className='planMenuAsset'>{team.team_naam}</span>
                </div> 

              </div>
            }

            {this.state.menu_plan_teams_open === true ?
              this.renderTeamsMenu(user, left, plan, day, key)
            : null}  

          </div>
        : null}

        {/* JOBS */}
        <div className='planMenuAsset' style={{position: 'relative'}}>

          {Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false ?
            <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_jobs_open: !this.state.menu_plan_jobs_open, menu_plan_teams_open: false, menu_plan_employees_open: false, menu_plan_breaks_open: false, menu_plan_labels_open: false, menu_plan_skills_open: false, menu_plan_absence_open: false, })}>

              <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                <p><i className='far fa-briefcase planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
                <span className='planMenuAsset' style={{ color: this.state.error_job ? Colors.color.redFilledIn : null }}>{__('misc_job')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_jobs_open ? '#47B7EB' : null }}>
                {Constants.isEmpty(this.state.menu_selectedJob.functie_id) === false ?
                  <span className='plannerZ_hoverable planMenuAsset'>{this.state.menu_selectedJob.functie_naam}</span>
                :
                  <span className='plannerZ_hoverable planMenuAsset'>-</span>
                }
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_jobs_open ? '#47B7EB' : null }}>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>
          :
            <div className='plannerZ_planMenu_row planMenuAsset'>

              <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
                <p><i className='far fa-briefcase planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
                <span className='planMenuAsset'>{__('misc_job')}</span>
              </div>

              <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
                <span style={{ fontWeight: 500 }} className='planMenuAsset'>{this.state.menu_selectedJob.functie_naam}</span>
              </div> 

            </div>
          }

          {this.state.menu_plan_jobs_open === true ?
            this.renderJobsMenu(user, left, plan, day, key)
          : null}  

        </div>

        {/* BREAKS */}
        <div className='planMenuAsset' style={{position: 'relative'}}>

          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_breaks_open: !this.state.menu_plan_breaks_open, menu_plan_teams_open: false, menu_plan_employees_open: false, menu_plan_jobs_open: false, menu_plan_labels_open: false, menu_plan_skills_open: false, menu_plan_absence_open: false, })}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-mug-hot planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
              <span className='planMenuAsset'>{__('misc_break')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_breaks_open ? '#47B7EB' : null }}>
              <span className='plannerZ_hoverable planMenuAsset'>{this.state.menu_selectedBreak}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_breaks_open ? '#47B7EB' : null }}>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>

          {this.state.menu_plan_breaks_open === true ?
            this.renderBreaksMenu(user, left, plan, day, key)
          : null}  

        </div>

        {/* LABELS */}
        <div className='planMenuAsset' style={{position: 'relative'}}>

          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_labels_open: !this.state.menu_plan_labels_open, menu_plan_teams_open: false, menu_plan_employees_open: false, menu_plan_jobs_open: false, menu_plan_breaks_open: false, menu_plan_skills_open: false, menu_plan_absence_open: false, })}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-tag planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
              <span className='planMenuAsset'>{__('misc_label')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_labels_open ? '#47B7EB' : null }}>
              {Constants.isEmpty(this.state.menu_selectedLabel) === false ?
                <span className='plannerZ_hoverable planMenuAsset'>{this.state.menu_selectedLabel.bijz_naam}</span>
              :
                <span className='plannerZ_hoverable planMenuAsset'>-</span>
              }
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_labels_open ? '#47B7EB' : null }}>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>

          {this.state.menu_plan_labels_open === true ?
            this.renderLabelsMenu(user, left, plan, day, key)
          : null}  

        </div>

        {/* SKILLS */}
        <div className='planMenuAsset' style={{position: 'relative'}}>

          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_skills_open: !this.state.menu_plan_skills_open, menu_plan_teams_open: false, menu_plan_employees_open: false, menu_plan_jobs_open: false, menu_plan_breaks_open: false, menu_plan_labels_open: false, menu_plan_absence_open: false, })}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-graduation-cap planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
              <span className='planMenuAsset'>{__('misc_skills')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_skills_open ? '#47B7EB' : null }}>
              {Constants.isEmpty(this.state.menu_selectedSkills) === false && this.state.menu_selectedSkills.length > 0 ?
                <span className='plannerZ_hoverable planMenuAsset'>{renderSkills}</span>
              :
                <span className='plannerZ_hoverable planMenuAsset'>-</span>
              }
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_skills_open ? '#47B7EB' : null }}>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>

          {this.state.menu_plan_skills_open === true ?
            this.renderSkillsMenu(user, left, plan, day, key)
          : null}  

        </div>

        {/* ABSENCE */}
        {Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false ?
          <div className='planMenuAsset' style={{position: 'relative'}}>

            <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_absence_open: !this.state.menu_plan_absence_open, menu_plan_teams_open: false, menu_plan_employees_open: false, menu_plan_jobs_open: false, menu_plan_breaks_open: false, menu_plan_labels_open: false, menu_plan_skills_open: false, })}>

              <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                <p><i className='far fa-user-minus planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
                <span className='planMenuAsset'>{__('misc_absence')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_absence_open ? '#47B7EB' : null }}>
                {Constants.isEmpty(av) === false && av.absence.length > 0 ?
                  <span className='plannerZ_hoverable planMenuAsset'>{av.absence[0].afw_naam}</span>
                :
                  <span className='plannerZ_hoverable planMenuAsset'>-</span>
                }
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_absence_open ? '#47B7EB' : null }}>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>

            {this.state.menu_plan_absence_open === true ?
              this.renderAbsenceMenu(user, av, left, plan, day, key)
            : null}  

          </div>
        : null}

        {Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false && Constants.isEmpty(av) === false && av.absence.length > 0 ?
          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset'>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-user-minus planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
              <span className='planMenuAsset'>{__('misc_absenceWaitDay')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              {Constants.isEmpty(av) === false && av.absence.length > 0 && Constants.isTrue(av.absence[0].afwn_ziekteWachtdag) === true ?
                <div className='smartPlanModal-checkbox planMenuAsset' onClick={() => this.selectMenuWaitingDay(user, av, day, false)}>
                  <i className='fas fa-check-square planMenuAsset' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </div>
              :
                <div className='smartPlanModal-checkbox planMenuAsset' onClick={() => this.selectMenuWaitingDay(user, av, day, true)}>
                  <i className='fal fa-square planMenuAsset' style={{fontSize: '0.8vw'}} />
                </div>
              }
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <span>&nbsp;</span>
            </div>

          </div>
        : null}

        {/* BUTTONS */}
        {isPlan ?
          <div>

            <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.planUserMenu(key, user, dateString, day, func)}>

              <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                <p><i className='far fa-clone plannerZ_hoverable planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
                <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_duplicateShift')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>

            {plan.rooster_publiceren === '1' || plan.rooster_publiceren === '3' ?
              Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === true ?
                <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.shiftAvailable(user, day, plan)}>

                  <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                    <p><i className='far fa-magnifying-glass plannerZ_hoverable planMenuAsset' /></p>
                  </div>

                  <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
                    <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_putAvailable')}</span>
                  </div>

                  <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
                    <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
                  </div>

                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.shiftAvailable(user, day, plan)}>

                  <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                    <p><i className='far fa-magnifying-glass-minus plannerZ_hoverable planMenuAsset' /></p>
                  </div>

                  <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
                    <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_putUnavailable')}</span>
                  </div>

                  <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
                    <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
                  </div>

                </div>
            : null}

            <div className='planMenuAsset' style={{ height: '0.75vw', width: '100%' }} />

            <div className='plannerZ_planMenu_clickAbleRow_delete planMenuAsset' onClick={() => this.deleteUserPlannerAlert(user, day, plan)}>

              <div className='plannerZ_planMenu_clickAbleRow_delete_sub4 planMenuAsset'>
                <p><i className='far fa-trash plannerZ_hoverable planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_delete_sub2 planMenuAsset' style={{ flex: 4.3 }}>
                <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('button_delete')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_delete_sub3 planMenuAsset'>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>

          </div>
        : null}

        {/* style={{ marginTop: isPlan ? '0.5vw' : null }} */}
        {isPlan === false ?
          <div className='plannerZ_planMenu_buttonRow planMenuAsset'>

            <div className='plannerZ_planMenu_buttonRow_sub1 planMenuAsset'>
              <div className='modal-cancelButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.closeAllPlanMenus()}>{__('button_cancel')}</div>
            </div>

            {/* {isPlan ?
              <div className='plannerZ_planMenu_buttonRow_sub2 planMenuAsset'>
                <div className='modal-submitButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.updateUserPlanner(key, user, day, plan)}>{__('button_edit')}</div>
              </div>
            : */}
              <div className='plannerZ_planMenu_buttonRow_sub2 planMenuAsset'>
                <div className='modal-submitButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.planUserMenu(key, user, dateString, day, func)}>{__('button_plan')}</div>
              </div>
            {/* } */}

          </div>
        : null}

      </div>

    );

  }

  renderHolidayMenu(user, av, left) {

    const holiday = av.holidayRequests[0];

    const startDate = Constants.stringToDate(holiday.vakantie_begin);
    const endDate = Constants.stringToDate(holiday.vakantie_eind);
    const sameDay = holiday.vakantie_begin === holiday.vakantie_eind;

    return (

      <div 
        className={`plannerZ_planMenu_container${left ? '_left' : ''} planMenuAsset planHoliday`}
        // onKeyDown={(e) => this.handleKey(e, key, user, dateString, day, plan, func)}
      >

        {/* DATE */}
        <div className='plannerZ_planMenu_row planMenuAsset planHoliday'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset planHoliday'>
            {sameDay ?
              <p><i className='far fa-plane planMenuAsset planHoliday' /></p>
            :
              <p><i className='far fa-plane-departure planMenuAsset planHoliday' /></p>
            }
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset planHoliday'>
            <span className='planMenuAsset planHoliday'>{sameDay ? __('misc_date') : __('misc_startDate')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset planHoliday'>
            <span className='planMenuAsset planHoliday'>{startDate.toLocaleDateString()}</span>
          </div>

        </div>

        {/* END DATE */}
        {sameDay === false ?
          <div className='plannerZ_planMenu_row planMenuAsset planHoliday'>

            <div className='plannerZ_planMenu_row_sub3 planMenuAsset planHoliday'>
              <p><i className='far fa-plane-arrival planMenuAsset planHoliday' /></p>
            </div>

            <div className='plannerZ_planMenu_row_sub1 planMenuAsset planHoliday'>
              <span className='planMenuAsset planHoliday'>{__('misc_endDate')}</span>
            </div>

            <div className='plannerZ_planMenu_row_sub2 planMenuAsset planHoliday'>
              <span className='planMenuAsset planHoliday'>{endDate.toLocaleDateString()}</span>
            </div>

          </div>
        : null}

        {/* COMMENT */}
        <div className='plannerZ_planMenu_row planMenuAsset planHoliday' style={{ alignItems: 'flex-start' }}>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset planHoliday' style={{ alignItems: 'flex-start' }}>
            <p><i className='far fa-comment planMenuAsset planHoliday' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset planHoliday' style={{ alignItems: 'flex-start' }}>
            <span className='planMenuAsset planHoliday'>{__('misc_comment')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset planHoliday' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            {Constants.isEmpty(holiday.vakantie_opmerking) === false ?
              <div style={{ whiteSpace: 'pre-line' }}>{holiday.vakantie_opmerking}</div>
            :
              <span className='plannerZ_hoverable planMenuAsset planHoliday'>-</span>
            }
          </div>

        </div>

        {/* GENERAL OVERVIEW */}
        <div className='plannerZ_planMenu_clickAbleRow planMenuAsset planHoliday' onClick={() => this.props.history.push({ pathname: `/reports`, menu: 'general', general_start: new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1), general_end: new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1), highlight: holiday.vakantie_id })}>

          <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset planHoliday'>
            <p><i className='far fa-user-chart plannerZ_hoverable planMenuAsset planHoliday' /></p>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset planHoliday' style={{ flex: 4.3 }}>
            <span className='plannerZ_hoverable planMenuAsset planHoliday' style={{ fontWeight: 600 }}>{__('planner_web_screen_checkGeneralOverview')}</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset planHoliday'>
            <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset planHoliday' /></p>
          </div>

        </div>

        {/* GO TO REQUEST */}
        <div className='plannerZ_planMenu_clickAbleRow planMenuAsset planHoliday' onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: holiday.vakantie_id })}>

          <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset planHoliday'>
            <p><i className='far fa-handshake-angle plannerZ_hoverable planMenuAsset planHoliday' /></p>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset planHoliday' style={{ flex: 4.3 }}>
            <span className='plannerZ_hoverable planMenuAsset planHoliday' style={{ fontWeight: 600 }}>{__('planner_web_screen_goToHolidayRequest')}</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset planHoliday'>
            <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset planHoliday' /></p>
          </div>

        </div>

        {/* BUTTONS */}
        <div className='plannerZ_planMenu_buttonRow planMenuAsset planHoliday'>

          <div className='plannerZ_planMenu_buttonRow_sub1 planMenuAsset planHoliday'>
            <div className='modal-deleteButton planMenuAsset planHoliday' style={{ marginLeft: 0 }} onClick={() => this.denyLeaveAlert(holiday)}>{__('button_deny')}</div>
          </div>

          <div className='plannerZ_planMenu_buttonRow_sub2 planMenuAsset planHoliday'>
            <div className='modal-submitButton planMenuAsset planHoliday' style={{ marginLeft: 0 }} onClick={() => this.approveLeaveAlert(holiday)}>{__('button_approve')}</div>
          </div>

        </div>

      </div>

    );

  }

  renderEmployeeMenu(user, side, left) {

    let renderAvailable = [];
    let renderSecondaryJobs = [];
    let renderSkills = [];

    const secondaryJobs = this.state.secondaryJobs[user.info_id];
    const skills = this.state.skillsAccounts[user.info_id];
    const plannedHoursWeek = this.getPlannedHoursWeek(user);
    const plannedDaysWeek = this.getPlannedDaysWeek(user);
    const plannedHoursMonth = this.getPlannedHoursMonths(user);
    const plannedDaysMonth = this.getPlannedDaysMonths(user);

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const date = Constants.stringToDate(dateString);
      const dayName = dateString === this.state.mondayDate ? __('misc_monday') : dateString === this.state.tuesdayDate ? __('misc_tuesday') : dateString === this.state.wednesdayDate ? __('misc_wednesday') : dateString === this.state.thursdayDate ? __('misc_thursday') : dateString === this.state.fridayDate ? __('misc_friday') : dateString === this.state.saturdayDate ? __('misc_saturday') : __('misc_sunday');

      const userData = this.state.planAccounts[user.info_id][dayKey];
      const renderAvailLabels = Constants.isEmpty(user) && Constants.isEmpty(userData) ? [] : this.renderAvailLabels_list(user, date, userData, true);

      renderAvailable.push(

        <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ alignItems: 'flex-start', paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

          <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset' style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{dayName}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset' style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: '0.15vw' }}>
            {renderAvailLabels}
          </div>

        </div>

      );

    }

    if (Constants.isEmpty(secondaryJobs) === false) {

      for (const job of secondaryJobs) {

        renderSecondaryJobs.push(

          <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

            <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
              <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>• {job.functie_naam}</span>
            </div>

          </div>

        );

      }

    }

    if (Constants.isEmpty(skills) === false) {

      for (const skill of skills) {

        renderSkills.push(

          <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

            <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
              <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>• {skill.comp_naam}</span>
            </div>

          </div>

        );

      }

    }

    // const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    // const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    // const date = Constants.stringToDate(dateString);

    // const renderSkills = this.renderSkillsDayShift();

    // let userFullName = ``;
    // let user = null;
    // let av = null

    // if (this.state.display === 'LIST' && Constants.isEmpty(this.state.menu_selectedEmployee) === false) {
    //   user = this.state.menu_selectedEmployee;
    //   av = this.state.planAccounts[user.info_id][dayKey];
    //   userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
    // }

    // const renderAvailLabels = Constants.isEmpty(user) && Constants.isEmpty(av) ? [] : this.renderAvailLabels(user, date, av, true);

    return (

      <div 
        className={`plannerZ_employeeMenu_container${side && !left ? '_right' : side && left ? '_left' : ''} employeeMenuAsset`}
      >

        {/* AVAILABILITY */}
        <div className='employeeMenuAsset' style={{ fontWeight: 700, marginBottom: '0.1vw' }}>{__('misc_availability')}</div>

        {renderAvailable}

        {/* JOBS */}
        <div className='employeeMenuAsset' style={{ fontWeight: 700, marginTop: '0.4vw', marginBottom: '0.1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {__('misc_job')}
          {Constants.isEmpty(user.functie_naam) === false ?
            <span className='defaultLinkO employeeMenuAsset' style={{ fontWeight: 500, fontSize: '0.6vw' }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id, menu: 'function' })}>{__('employees_button_manageJob')}</span>
          : null}
        </div>

        <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

          <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
            {Constants.isEmpty(user.functie_naam) === false ?
              <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{user.functie_naam}</span>
            :
              <div className='defaultLinkO employeeMenuAsset' style={{ fontWeight: 500 }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id, menu: 'function' })}>{__('employees_button_manageJob')}</div>
            }
          </div>

        </div>

        {/* SECONDARY JOBS */}
        {Constants.isEmpty(secondaryJobs) === false ?
          <div className='employeeMenuAsset' style={{ fontWeight: 700, marginTop: '0.4vw', marginBottom: '0.1vw' }}>{__('employees_secondJob')}</div>
        : null}

        {Constants.isEmpty(secondaryJobs) === false ?
          renderSecondaryJobs
        : null}

        {/* SKILLS DATA */}
        <div className='employeeMenuAsset' style={{ fontWeight: 700, marginTop: '0.4vw', marginBottom: '0.1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {__('misc_skills')}
          {renderSkills.length > 0 ?
            <span className='defaultLinkO employeeMenuAsset' style={{ fontWeight: 500, fontSize: '0.6vw' }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id, menu: 'skills' })}>{__('employees_button_manageSkills')}</span>
          : null}
        </div>

        {renderSkills.length === 0 ?
          <div className='defaultLinkO employeeMenuAsset' style={{ fontWeight: 500 }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id, menu: 'skills' })}>{__('employees_button_manageSkills')}</div>
        : null}

        {renderSkills}

        {/* WEEK DATA */}
        <div className='employeeMenuAsset' style={{ fontWeight: 700, marginTop: '0.4vw', marginBottom: '0.1vw' }}>{__('misc_week_c')} {this.state.week}</div>

        <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

          <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset'>
            <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{__('misc_days_c')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
            {plannedDaysWeek}
          </div>

        </div>

        <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

          <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset'>
            <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{__('misc_hours_c')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
            {Constants.msToTime2(plannedHoursWeek)}
          </div>

        </div>

        {/* MONTH 1 DATA */}
        <div className='employeeMenuAsset' style={{ fontWeight: 700, marginTop: '0.4vw', marginBottom: '0.1vw' }}>{Constants.getMonthName(Constants.stringToDate(this.state.mondayDate))}</div>

        <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

          <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset'>
            <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{__('misc_days_c')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
            {plannedDaysMonth.month}
          </div>

        </div>

        <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

          <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset'>
            <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{__('misc_hours_c')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
            {Constants.msToTime2(plannedHoursMonth.month)}
          </div>

        </div>

        {/* MONTH 2 DATA */}
        {this.state.isTwoMonths ?
          <div>
            <div className='employeeMenuAsset' style={{ fontWeight: 700, marginTop: '0.4vw', marginBottom: '0.1vw' }}>{Constants.getMonthName(Constants.stringToDate(this.state.sundayDate))}</div>

            <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

              <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset'>
                <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{__('misc_days_c')}</span>
              </div>

              <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
                {plannedDaysMonth.month2}
              </div>

            </div>

            <div className='plannerZ_planMenu_row employeeMenuAsset' style={{ paddingTop: '0.1vw', paddingBottom: '0.1vw' }}>

              <div className='plannerZ_planMenu_row_sub1 employeeMenuAsset'>
                <span className='employeeMenuAsset' style={{ fontWeight: 500 }}>{__('misc_hours_c')}</span>
              </div>

              <div className='plannerZ_planMenu_row_sub2 employeeMenuAsset'>
                {Constants.msToTime2(plannedHoursMonth.month2)}
              </div>

            </div>

          </div>
        : null}

        <div className='defaultLinkO employeeMenuAsset' style={{ fontWeight: 600, marginTop: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id })}>{__('misc_employeeSettings')}</div>

        {/* {this.state.display === 'LIST' ?
          <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%', marginBottom: renderAvailLabels.length > 0 ? '1vw' : 0 }}>
            {renderAvailLabels}
          </div>
        : null}

        {this.state.display === 'LIST' ?
          <div className='planMenuAsset' style={{position: 'relative'}}>

            <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_employees_open: true })}>

              <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                <p><i className='far fa-user planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
                <span className='planMenuAsset' style={{ color: this.state.error_employee ? Colors.color.redFilledIn : null }}>{__('misc_employee')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_employees_open ? '#47B7EB' : null }}>
                {Constants.isEmpty(user) ?
                  <span className='plannerZ_hoverable planMenuAsset'>-</span>
                :
                Constants.isEmpty(user.info_id) ?
                  <span className='plannerZ_hoverable planMenuAsset'>{__('home_overview_openShift')}</span>
                : 
                  <span className='plannerZ_hoverable planMenuAsset'>{userFullName}</span>
                }
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_employees_open ? '#47B7EB' : null }}>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>

            {this.state.menu_plan_employees_open === true ?
              this.renderEmployeesMenu(func, left, null, day, key)
            : null}  

          </div>
        : null}

        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-clock planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_time')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>
          </div>

        </div>

        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-briefcase planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_job')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{this.state.menu_selectedJob.functie_naam}</span>
          </div>

        </div>

        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-mug-hot planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_break')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{this.state.menu_selectedBreak}</span>
          </div>

        </div>

        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-tag planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_label')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            {Constants.isEmpty(this.state.menu_selectedLabel) === false ?
              <span className='plannerZ_hoverable planMenuAsset'>{this.state.menu_selectedLabel.bijz_naam}</span>
            :
              <span className='plannerZ_hoverable planMenuAsset'>-</span>
            }
          </div>

        </div>

        <div className='plannerZ_planMenu_row planMenuAsset' style={{ alignItems: 'flex-start' }}>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset' style={{ alignItems: 'flex-start' }}>
            <p><i className='far fa-graduation-cap planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset' style={{ alignItems: 'flex-start' }}>
            <span className='planMenuAsset'>{__('misc_skills')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            {Constants.isEmpty(this.state.menu_selectedSkills) === false && this.state.menu_selectedSkills.length > 0 ?
              renderSkills
            :
              <span className='plannerZ_hoverable planMenuAsset'>-</span>
            }
          </div>

        </div>

        {Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === true ?
          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' style={{ marginTop: '0.75vw' }} onClick={() => this.dayShiftAvailable(day, shift)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-magnifying-glass plannerZ_hoverable planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
              <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_putAvailable')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>
        :
          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' style={{ marginTop: '0.75vw' }} onClick={() => this.dayShiftAvailable(day, shift)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-magnifying-glass-minus plannerZ_hoverable planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
              <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_putUnavailable')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>
        }

        {this.state.display === 'LIST' ?
          <div className='plannerZ_planMenu_buttonRow planMenuAsset'>

            <div className='plannerZ_planMenu_buttonRow_sub1 planMenuAsset'>
              <div className='modal-cancelButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.closeAllPlanMenus()}>{__('button_cancel')}</div>
            </div>

            <div className='plannerZ_planMenu_buttonRow_sub2 planMenuAsset'>
              <div className='modal-submitButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.planUserDayShiftMenu(key, user, dateString, day, shift, func)}>{__('button_plan')}</div>
            </div>

          </div>
        : null} */}

      </div>

    );

  }

  renderDayShiftMenu(key, day, left, shift, func) {

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const date = Constants.stringToDate(dateString);

    const renderSkills = this.renderSkillsDayShift();

    let userFullName = ``;
    let user = null;
    let av = null

    if (this.state.display === 'LIST' && Constants.isEmpty(this.state.menu_selectedEmployee) === false) {
      user = this.state.menu_selectedEmployee;
      av = this.state.planAccounts[user.info_id][dayKey];
      userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
    }

    const renderAvailLabels = Constants.isEmpty(user) && Constants.isEmpty(av) ? [] : this.renderAvailLabels(user, date, av, true);

    return (

      <div 
        className={`plannerZ_planMenu_container${left ? '_left' : ''} planMenuAsset`}
      >

        {this.state.display === 'LIST' ?
          <div className='planMenuAsset' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative', width: '100%', marginBottom: renderAvailLabels.length > 0 ? '1vw' : 0 }}>
            {renderAvailLabels}
          </div>
        : null}

        {this.state.display === 'LIST' ?
          <div className='planMenuAsset' style={{position: 'relative'}}>

            <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' onClick={() => this.setState({ menu_plan_employees_open: true })}>

              <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
                <p><i className='far fa-user planMenuAsset' /></p>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub1 planMenuAsset'>
                <span className='planMenuAsset' style={{ color: this.state.error_employee ? Colors.color.redFilledIn : null }}>{__('misc_employee')}</span>
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ color: this.state.menu_plan_employees_open ? '#47B7EB' : null }}>
                {Constants.isEmpty(user) ?
                  <span className='plannerZ_hoverable planMenuAsset'>-</span>
                :
                Constants.isEmpty(user.info_id) ?
                  <span className='plannerZ_hoverable planMenuAsset'>{__('home_overview_openShift')}</span>
                : 
                  <span className='plannerZ_hoverable planMenuAsset'>{userFullName}</span>
                }
              </div>

              <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset' style={{ color: this.state.menu_plan_employees_open ? '#47B7EB' : null }}>
                <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
              </div>

            </div>

            {this.state.menu_plan_employees_open === true ?
              this.renderEmployeesMenu(func, left, null, day, key)
            : null}  

          </div>
        : null}

        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-clock planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_time')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{shift.dagdienst_begin.substr(0, 5)} - {shift.dagdienst_eind.substr(0, 5)}</span>
          </div>

        </div>

        {/* JOBS */}
        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-briefcase planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_job')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{this.state.menu_selectedJob.functie_naam}</span>
          </div>

        </div>

        {/* BREAKS */}
        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-mug-hot planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_break')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{this.state.menu_selectedBreak}</span>
          </div>

        </div>

        {/* LABELS */}
        <div className='plannerZ_planMenu_row planMenuAsset'>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset'>
            <p><i className='far fa-tag planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset'>
            <span className='planMenuAsset'>{__('misc_label')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset'>
            {Constants.isEmpty(this.state.menu_selectedLabel) === false ?
              <span className='plannerZ_hoverable planMenuAsset'>{this.state.menu_selectedLabel.bijz_naam}</span>
            :
              <span className='plannerZ_hoverable planMenuAsset'>-</span>
            }
          </div>

        </div>

        {/* SKILLS */}
        <div className='plannerZ_planMenu_row planMenuAsset' style={{ alignItems: 'flex-start' }}>

          <div className='plannerZ_planMenu_row_sub3 planMenuAsset' style={{ alignItems: 'flex-start' }}>
            <p><i className='far fa-graduation-cap planMenuAsset' /></p>
          </div>

          <div className='plannerZ_planMenu_row_sub1 planMenuAsset' style={{ alignItems: 'flex-start' }}>
            <span className='planMenuAsset'>{__('misc_skills')}</span>
          </div>

          <div className='plannerZ_planMenu_row_sub2 planMenuAsset' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            {Constants.isEmpty(this.state.menu_selectedSkills) === false && this.state.menu_selectedSkills.length > 0 ?
              renderSkills
            :
              <span className='plannerZ_hoverable planMenuAsset'>-</span>
            }
          </div>

        </div>

        {/* BUTTONS */}
        {Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === true ?
          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' style={{ marginTop: '0.75vw' }} onClick={() => this.dayShiftAvailable(day, shift)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-magnifying-glass plannerZ_hoverable planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
              <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_putAvailable')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>
        :
          <div className='plannerZ_planMenu_clickAbleRow planMenuAsset' style={{ marginTop: '0.75vw' }} onClick={() => this.dayShiftAvailable(day, shift)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub4 planMenuAsset'>
              <p><i className='far fa-magnifying-glass-minus plannerZ_hoverable planMenuAsset' /></p>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset' style={{ flex: 4.3 }}>
              <span className='plannerZ_hoverable planMenuAsset' style={{ fontWeight: 600 }}>{__('misc_putUnavailable')}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right plannerZ_hoverable planMenuAsset' /></p>
            </div>

          </div>
        }

        {this.state.display === 'LIST' ?
          <div className='plannerZ_planMenu_buttonRow planMenuAsset'>

            <div className='plannerZ_planMenu_buttonRow_sub1 planMenuAsset'>
              <div className='modal-cancelButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.closeAllPlanMenus()}>{__('button_cancel')}</div>
            </div>

            {/* {isPlan ?
              <div className='plannerZ_planMenu_buttonRow_sub2 planMenuAsset'>
                <div className='modal-submitButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.updateUserPlanner(key, user, day, plan)}>{__('button_edit')}</div>
              </div>
            : */}
              <div className='plannerZ_planMenu_buttonRow_sub2 planMenuAsset'>
                <div className='modal-submitButton planMenuAsset' style={{ marginLeft: 0 }} onClick={() => this.planUserDayShiftMenu(key, user, dateString, day, shift, func)}>{__('button_plan')}</div>
              </div>
            {/* } */}

          </div>
        : null}

      </div>

    );

  }

  renderEmployeesMenu(func, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    let toRender = [];
    let holidayInfoRender = [];

    // HOLIDAY
    for (const av of this.state.planList[dayKey].holidays) {
      if (Constants.arrayContains(holidayInfoRender, av.info_id) === false) {
        holidayInfoRender.push(av.info_id);
      }
    }

    // DAILY
    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      if (Data.data.appOptions.availability === 'AVAILABILITY') {
        
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontWeight: 'bold', color: Colors.color.greenFilledIn }}>{__('misc_available')}</span>
            </div>
          </div>
        );

        if (this.state.planList[dayKey].av.length === 0) {
          toRender.push(
            <div className='plannerZ_planMenu_row'>
              <div className='plannerZ_planMenu_row_sub2'>
                <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
              </div>
            </div>
          );
        }

        for (const av of this.state.planList[dayKey].av) {

          const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;

          if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
            continue;
          }

          // working days
          const userData = this.state.accountsObj[av.info_id];
          const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
          if (Constants.isEmpty(av.info_id) === false && Constants.isEmpty(userData) === false && Constants.isTrue(userData[info_werkdag]) === false) {
            continue;
          }

          // teams
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
              continue;
            }
          }

          // leave
          if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
            continue;
          }
    
          toRender.push(
    
            <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
                {Constants.isEmpty(av.info2_profielfoto) === false ?
                  <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
                :
                  <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                    <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                  </div>
                }
              </div>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset'>{userFullName}</span>
                </div>
                {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                  </div>
                : null}
                {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                  </div>
                :
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{av.beschikbaar_begin.substr(0, 5)} - {av.beschikbaar_eind.substr(0, 5)}</span>
                  </div>
                }
              </div>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
                <p><i className='far fa-angle-right planMenuAsset' /></p>
              </div>
    
            </div>
    
          );
    
        }

      }

      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.greyNotFilled }}>{__('misc_notFilled')}</span>
          </div>
        </div>
      );

      let notFilled = 0;

      for (const av of this.state.accounts) {

        if (this.state.planAccounts[av.info_id][dayKey].av.length === 0 && this.state.planAccounts[av.info_id][dayKey].unav.length === 0) {

          const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;

          if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
            continue;
          }

          // working days
          const userData = this.state.accountsObj[av.info_id];
          const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
          if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
            continue;
          }

          // teams
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
              continue;
            }
          }

          // leave
          if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
            continue;
          }

          toRender.push(
  
            <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
  
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
                {Constants.isEmpty(av.info2_profielfoto) === false ?
                  <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
                :
                  <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                    <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                  </div>
                }
              </div>
  
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset'>{userFullName}</span>
                </div>
                {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                  </div>
                : null}
                {/* {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                  </div>
                :
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.redFilledInredFilledIn, fontSize: '0.7vw' }}>{av.onbeschikbaar_begin.substr(0, 5)} - {av.onbeschikbaar_eind.substr(0, 5)}</span>
                  </div>
                } */}
              </div>
  
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
                <p><i className='far fa-angle-right planMenuAsset' /></p>
              </div>
  
            </div>
  
          );

          notFilled++;

        }

      }

      if (notFilled === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }

      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.redFilledIn }}>{__('misc_unavailable')}</span>
          </div>
        </div>
      );

      if (this.state.planList[dayKey].unav.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }

      for (const av of this.state.planList[dayKey].unav) {

        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }

        toRender.push(
  
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
  
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
  
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{av.onbeschikbaar_begin.substr(0, 5)} - {av.onbeschikbaar_eind.substr(0, 5)}</span>
                </div>
              }
            </div>
  
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
  
          </div>
  
        );
  
      }

    // STANDARD
    } else if (Data.data.appOptions.availability === 'STANDARD') {

      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ fontWeight: 'bold', color: Colors.color.greenFilledIn }}>{__('misc_available')}</span>
          </div>
        </div>
      );
  
      if (this.state.planList[dayKey].sav.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      for (const av of this.state.planList[dayKey].sav) {
  
        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }
    
        toRender.push(
    
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{av.sb_begin.substr(0, 5)} - {av.sb_eind.substr(0, 5)}</span>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
    
          </div>
    
        );
    
      }
  
      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.greyNotFilled }}>{__('misc_notFilled')}</span>
          </div>
        </div>
      );
  
      let notFilled = 0;
  
      for (const av of this.state.accounts) {
  
        if (this.state.planAccounts[av.info_id][dayKey].sav.length === 0 && this.state.planAccounts[av.info_id][dayKey].sunav.length === 0) {
  
          const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
          if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
            continue;
          }

          // working days
          const userData = this.state.accountsObj[av.info_id];
          const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
          if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
            continue;
          }

          // teams
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
              continue;
            }
          }

          // leave
          if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
            continue;
          }
  
          toRender.push(
    
            <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
                {Constants.isEmpty(av.info2_profielfoto) === false ?
                  <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
                :
                  <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                    <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                  </div>
                }
              </div>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset'>{userFullName}</span>
                </div>
                {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                  </div>
                : null}
                {/* {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                  </div>
                :
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.redFilledInredFilledIn, fontSize: '0.7vw' }}>{av.onbeschikbaar_begin.substr(0, 5)} - {av.onbeschikbaar_eind.substr(0, 5)}</span>
                  </div>
                } */}
              </div>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
                <p><i className='far fa-angle-right planMenuAsset' /></p>
              </div>
    
            </div>
    
          );
  
          notFilled++;
  
        }
  
      }
  
      if (notFilled === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.redFilledIn }}>{__('misc_unavailable')}</span>
          </div>
        </div>
      );
  
      if (this.state.planList[dayKey].sunav.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      for (const av of this.state.planList[dayKey].sunav) {
  
        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
    
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }
  
        toRender.push(
    
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              {av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{av.sb_begin.substr(0, 5)} - {av.sb_eind.substr(0, 5)}</span>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
    
          </div>
    
        );
    
      }

    // SCHOOL
    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ fontWeight: 'bold', color: Colors.color.greenFilledIn }}>{__('misc_available')}</span>
          </div>
        </div>
      );
  
      if (this.state.planList[dayKey].av.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      for (const av of this.state.planList[dayKey].av) {
  
        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }
    
        toRender.push(
    
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              {av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{av.beschikbaar_begin.substr(0, 5)} - {av.beschikbaar_eind.substr(0, 5)}</span>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
    
          </div>
    
        );
    
      }
  
      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.greenFilledIn }}>{__('misc_noSchool')}</span>
          </div>
        </div>
      );
  
      let notFilled = 0;
  
      for (const av of this.state.accounts) {
  
        if (this.state.planAccounts[av.info_id][dayKey].school.length === 0 && this.state.planAccounts[av.info_id][dayKey].av.length === 0 && this.state.planAccounts[av.info_id][dayKey].unav.length === 0) {
  
          const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
          if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
            continue;
          }

          // working days
          const userData = this.state.accountsObj[av.info_id];
          const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
          if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
            continue;
          }

          // teams
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
              continue;
            }
          }

          // leave
          if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
            continue;
          }
  
          toRender.push(
    
            <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
                {Constants.isEmpty(av.info2_profielfoto) === false ?
                  <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
                :
                  <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                    <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                  </div>
                }
              </div>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset'>{userFullName}</span>
                </div>
                {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                  </div>
                : null}
                {/* {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                  </div>
                :
                  <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                    <span className='planMenuAsset' style={{ color: Colors.color.redFilledInredFilledIn, fontSize: '0.7vw' }}>{av.onbeschikbaar_begin.substr(0, 5)} - {av.onbeschikbaar_eind.substr(0, 5)}</span>
                  </div>
                } */}
              </div>
    
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
                <p><i className='far fa-angle-right planMenuAsset' /></p>
              </div>
    
            </div>
    
          );
  
          notFilled++;
  
        }
  
      }

      if (notFilled === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }

      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.greenFilledIn }}>{__('misc_schoolHoliday')}</span>
          </div>
        </div>
      );
  
      if (this.state.planList[dayKey].schoolHoliday.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      for (const av of this.state.planList[dayKey].schoolHoliday) {
  
        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }
    
        toRender.push(
    
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset' style={{ color: Colors.color.greenFilledIn, fontSize: '0.7vw' }}>{__('misc_schoolHoliday')}</span>
              </div>
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
    
          </div>
    
        );
    
      }

      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.greyNotFilled }}>{__('misc_school')}</span>
          </div>
        </div>
      );
  
      if (this.state.planList[dayKey].school.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      for (const av of this.state.planList[dayKey].school) {
  
        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
  
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }
    
        toRender.push(
    
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              {av.schoolrooster_begin === '00:00:00' && av.schoolrooster_eind === '00:00:00' ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.greyNotFilled, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.greyNotFilled, fontSize: '0.7vw' }}>{av.schoolrooster_begin.substr(0, 5)} - {av.schoolrooster_eind.substr(0, 5)}</span>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
    
          </div>
    
        );
    
      }
  
      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.redFilledIn }}>{__('misc_unavailable')}</span>
          </div>
        </div>
      );
  
      if (this.state.planList[dayKey].unav.length === 0) {
        toRender.push(
          <div className='plannerZ_planMenu_row'>
            <div className='plannerZ_planMenu_row_sub2'>
              <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
            </div>
          </div>
        );
      }
  
      for (const av of this.state.planList[dayKey].unav) {
  
        const userFullName = `${av.info_voornaam} ${(av.info_tussenvoegsel !== null && av.info_tussenvoegsel !== '') ? av.info_tussenvoegsel + " " + av.info_achternaam : av.info_achternaam}`;
    
        if (av.functie_id !== func.functie_id || Constants.objectArrayContainsKey(this.state.secondaryJobs[av.info_id], 'functie_id', func.functie_id)) {
          continue;
        }

        // working days
        const userData = this.state.accountsObj[av.info_id];
        const info_werkdag = day === 1 ? 'info2_werkdag_maandag' : day === 2 ? 'info2_werkdag_dinsdag' : day === 3 ? 'info2_werkdag_woensdag' : day === 4 ? 'info2_werkdag_donderdag' : day === 5 ? 'info2_werkdag_vrijdag' : day === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
        if (Constants.isEmpty(av.info_id) === false && Constants.isTrue(userData[info_werkdag]) === false) {
          continue;
        }

        // teams
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamUsers[av.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[av.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // leave
        if (Constants.arrayContains(holidayInfoRender, av.info_id) === true) {
          continue;
        }
  
        toRender.push(
    
          <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuEmployee(plan, day, key, av)}>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
              {Constants.isEmpty(av.info2_profielfoto) === false ?
                <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${av.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
              :
                <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                  <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
              <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                <span className='planMenuAsset'>{userFullName}</span>
              </div>
              {this.state.allPlansUser[av.info_id][dayKey].length > 0 ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.pastBorderColor, fontSize: '0.7vw' }}>{Localization.t.formatString(__('misc_plannedTimes'), this.state.allPlansUser[av.info_id][dayKey].length)}</span>
                </div>
              : null}
              {av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00' ?
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{__('misc_wholeDay')}</span>
                </div>
              :
                <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
                  <span className='planMenuAsset' style={{ color: Colors.color.redFilledIn, fontSize: '0.7vw' }}>{av.onbeschikbaar_begin.substr(0, 5)} - {av.onbeschikbaar_eind.substr(0, 5)}</span>
                </div>
              }
            </div>
    
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>
    
          </div>
    
        );
    
      }

    }

    toRender.push(
      <div className='plannerZ_planMenu_row'>
        <div className='plannerZ_planMenu_row_sub2'>
          <span style={{ marginTop: '0.5vw', fontWeight: 'bold', color: Colors.color.orangeVacation }}>{__('misc_leave')}</span>
        </div>
      </div>
    );

    if (holidayInfoRender.length === 0) {
      toRender.push(
        <div className='plannerZ_planMenu_row'>
          <div className='plannerZ_planMenu_row_sub2'>
            <span style={{ fontStyle: 'italic' }}>{__('misc_noEmployees')}</span>
          </div>
        </div>
      );
    }

    for (const userID of holidayInfoRender) {

      // const holiday = this.state.planAccounts[userID][dayKey].holidays[0];
      const userData = this.state.accountsObj[userID];

       // teams
       if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (Constants.isEmpty(this.teamUsers[userData.info_id]) === true || (Constants.isEmpty(plan) === false && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && Constants.objectArrayContainsKey(this.teamUsers[userData.info_id], 'team_id', this.teamPlanData[plan.rooster_id].team_id) === false) || Constants.objectArrayContainsKey(this.teamUsers[userData.info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
          continue;
        }
      }

      const userFullName = `${userData.info_voornaam} ${(userData.info_tussenvoegsel !== null && userData.info_tussenvoegsel !== '') ? userData.info_tussenvoegsel + " " + userData.info_achternaam : userData.info_achternaam}`;

      toRender.push(
    
        <div className='plannerZ_planMenu_clickAbleRow_employee plannerZ_subMenu planMenuAsset' style={{ cursor: 'default', color: Colors.color.TEXT_COLOR }}>
    
          <div className='plannerZ_planMenu_clickAbleRow_employee_sub1 planMenuAsset'>
            {Constants.isEmpty(userData.info2_profielfoto) === false ?
              <img className='profilePic' src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${userData.info2_profielfoto}`} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw' }} />
            :
              <div className='profilePic' style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.2vw', backgroundColor: '#EBEBEB' }}>
                <p style={{ display: 'flex', marginBlock: 0 }}><i className='far fa-user' aria-hidden={true} style={{ color: '#282828', fontSize: '0.5vw' }} /></p>
              </div>
            }
          </div>
    
          <div className='plannerZ_planMenu_clickAbleRow_employee_sub2 planMenuAsset'>
            <div className='plannerZ_planMenu_clickAbleRow_employee_sub2_row planMenuAsset'>
              <span className='planMenuAsset'>{userFullName}</span>
            </div>
          </div>
    
          <div className='plannerZ_planMenu_clickAbleRow_employee_sub3 planMenuAsset'>
            <p>&nbsp;</p>
          </div>
    
        </div>
    
      );

    }

    return (

      <div className={`plannerZ_planMenu_container_employee_sub${left ? '_left' : ''} planMenuAsset`}>

        <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' style={{ paddingTop: 0, paddingBottom: '0.75vw', marginBottom: '0.5vw', borderBottom: '1px solid #e1e1e1' }} onClick={() => this.selectMenuEmployee(plan, day, key, { info_id: null, functie_id: func.functie_id, functie_naam: func.functie_naam, functie_kleur: func.functie_kleur })}>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{__('home_overview_openShift')}</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
            <p><i className='far fa-angle-right planMenuAsset' /></p>
          </div>

        </div>

        {/* <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuBreak('00:00', plan, day, key, user)}>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{__('home_overview_openShift')}</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
            <p><i className='far fa-angle-right planMenuAsset' /></p>
          </div>

        </div> */}

        {toRender}
      
      </div>

    );

  }

  renderTeamsMenu(user, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    const teams = this.teamUsers[user.info_id];

    let toRender = [];

    for (const team of teams) {

      toRender.push(

        <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuTeam(team, plan, day, key, user)}>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{team.team_naam}</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
            <p><i className='far fa-angle-right planMenuAsset' /></p>
          </div>

        </div>

      );

    }

    return (

      <div className={`plannerZ_planMenu_container_sub${left ? '_left' : ''} planMenuAsset`}>

        <span className='defaultLinkO planMenuAsset' style={{ marginBottom: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id, menu: 'teams' })}>{__('planner_web_screen_manageTeams')} {user.info_voornaam}</span>

        <div className='divider planMenuAsset' />

        {toRender}
      
      </div>

    );

  }

  renderJobsMenu(user, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    let toRender = [];

    const secondaryJobs = this.state.secondaryJobs[user.info_id];

    if (Constants.isEmpty(secondaryJobs) === false) {

      for (const job of secondaryJobs) {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' style={{ marginTop: toRender.length === 0 && Constants.isEmpty(user) === false && Constants.isEmpty(user.functie_id) === true ? 0 : null }} onClick={() => this.selectMenuJob(job, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span className='planMenuAsset'>{job.functie_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>

          </div>

        );

      }

    }

    if (Constants.isEmpty(user) === false && Constants.isEmpty(user.functie_id) === true) {

      for (const job of this.state.jobs) {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuJob(job, plan, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span className='planMenuAsset'>{job.functie_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>

          </div>

        );

      }

    }

    return (

      <div className={`plannerZ_planMenu_container_sub${left ? '_left' : ''} planMenuAsset`}>

        {Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false ?
            <div className='defaultLinkO planMenuAsset' style={{ marginBottom: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/employees/' + user.info_id, menu: 'function' })}>{__('employees_button_manageJob')} {user.info_voornaam}</div>
        : null}

        {Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === false ?
          <div className='divider planMenuAsset' />
        : null}


        {Constants.isEmpty(user) === false && Constants.isEmpty(user.functie_id) === false ?
          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' style={{ marginTop: Constants.isEmpty(user) === false && Constants.isEmpty(user.info_id) === true ? 0 : null}} onClick={() => this.selectMenuJob(user, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span className='planMenuAsset'>{user.functie_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>

          </div>
        : null}

        {toRender}
      
      </div>

    );

  }

  renderBreaksMenu(user, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    let toRender = [];

    for (const brk of this.state.breaks) {

      toRender.push(

        <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuBreak(brk.brk_tijd.substr(0, 5), plan, day, key, user)}>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
            <span className='planMenuAsset'>{brk.brk_tijd.substr(0, 5)}</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
            <p><i className='far fa-angle-right planMenuAsset' /></p>
          </div>

        </div>

      );

    }

    return (

      <div className={`plannerZ_planMenu_container_sub${left ? '_left' : ''} planMenuAsset`}>

        <span className='defaultLinkO planMenuAsset' style={{ marginBottom: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/manage/', menu: 'breaks' })}>{__('planner_web_screen_manageBreaks')}</span>

        <div className='divider planMenuAsset' />

        <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuBreak('00:00', plan, day, key, user)}>

          <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
            <span className='planMenuAsset'>00:00</span>
          </div>

          <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
            <p><i className='far fa-angle-right planMenuAsset' /></p>
          </div>

        </div>

        {toRender}
      
      </div>

    );

  }

  renderLabelsMenu(user, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    let toRender = [];

    for (const label of this.state.labels_data) {

      if (Constants.isEmpty(this.state.menu_selectedLabel) === true || this.state.menu_selectedLabel.bijz_id !== label.bijz_id) {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuLabel(label, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span className='planMenuAsset'>{label.bijz_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>

          </div>

        );

      } else {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuLabel(label, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span style={{ fontWeight: 600 }} className='planMenuAsset'>{label.bijz_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-xmark planMenuAsset' /></p>
            </div>

          </div>

        );

      }

    }

    return (

      <div className={`plannerZ_planMenu_container_sub${left ? '_left' : ''} planMenuAsset`}>

        <span className='defaultLinkO planMenuAsset' style={{ marginBottom: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/app_options/', menu: 'planner', subMenu: 'labels' })}>{__('misc_manageLabels')}</span>

        <div className='divider planMenuAsset' />

        {toRender}
      
      </div>

    );

  }

  renderSkillsMenu(user, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    let toRender = [];

    for (const skill of this.state.skills_data) {

      if (Constants.objectArrayContainsKey(this.state.menu_selectedSkills, 'comp_id', skill.comp_id) === false) {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuSkill(skill, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span className='planMenuAsset'>{skill.comp_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>

          </div>

        );

      } else {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuSkill(skill, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span style={{ fontWeight: 600 }} className='planMenuAsset'>{skill.comp_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-xmark planMenuAsset' /></p>
            </div>

          </div>

        );

      }

    }

    return (

      <div className={`plannerZ_planMenu_container_sub${left ? '_left' : ''} planMenuAsset`}>

        <span className='defaultLinkO planMenuAsset' style={{ marginBottom: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/app_options/', menu: 'skills' })}>{__('employees_button_manageSkills')}</span>

        <div className='divider planMenuAsset' />

        {toRender}
      
      </div>

    );

  }

  renderAbsenceMenu(user, av, left, plan, day, key) {

    if (Constants.isEmpty(this.timeRef[key]) === false) {
      this.timeRef[key].focus();
    }

    let toRender = [];

    for (const abs of this.state.absenceTypes) {

      if (Constants.objectArrayContainsKey(av.absence, 'afw_id', abs.afw_id) === false) {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuAbsence(abs, av, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span className='planMenuAsset'>{abs.afw_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-angle-right planMenuAsset' /></p>
            </div>

          </div>

        );

      } else {

        toRender.push(

          <div className='plannerZ_planMenu_clickAbleRow plannerZ_subMenu planMenuAsset' onClick={() => this.selectMenuAbsence(abs, av, plan, day, key, user)}>

            <div className='plannerZ_planMenu_clickAbleRow_sub2 planMenuAsset'>
              <span style={{ fontWeight: 600 }} className='planMenuAsset'>{abs.afw_naam}</span>
            </div>

            <div className='plannerZ_planMenu_clickAbleRow_sub3 planMenuAsset'>
              <p><i className='far fa-xmark planMenuAsset' /></p>
            </div>

          </div>

        );

      }

    }

    return (

      <div className={`plannerZ_planMenu_container_sub${left ? '_left' : ''} planMenuAsset`}>

        <span className='defaultLinkO planMenuAsset' style={{ marginBottom: '0.5vw' }} onClick={() => this.props.history.push({ pathname: '/app_options/', menu: 'abs' })}>{__('time_manageAbsenceTypes')}</span>

        <div className='divider planMenuAsset' />

        {toRender}
      
      </div>

    );

  }

  selectMenuEmployee(plan, day, key, user) {

    this.setState({
      menu_plan_employees_open: false,
      menu_selectedEmployee: user,
      error_employee: false,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'SHIFT');

      }

    });

  }

  selectMenuTeam(team, plan, day, key, user) {

    this.setState({
      menu_plan_teams_open: false,
      menu_selectedTeam: team,
      // error_job: false,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'TEAM');

      }

    });

  }  selectMenuJob(job, plan, day, key, user) {

    this.setState({
      menu_plan_jobs_open: false,
      menu_selectedJob: job,
      error_job: false,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'JOB');

      }

    });

  }

  selectMenuJob(job, plan, day, key, user) {

    this.setState({
      menu_plan_jobs_open: false,
      menu_selectedJob: job,
      error_job: false,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'JOB');

      }

    });

  }

  selectMenuBreak(brk, plan, day, key, user) {

    this.setState({
      menu_plan_breaks_open: false,
      menu_selectedBreak: brk,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'BREAK');

      }

    });

  }

  selectMenuLabel(label, plan, day, key, user) {

    let _label = this.state.menu_selectedLabel;

    if (Constants.isEmpty(this.state.menu_selectedLabel) === true || this.state.menu_selectedLabel.bijz_id !== label.bijz_id) {
      _label = label;
    } else {
      _label = null;
    }

    this.setState({
      menu_plan_labels_open: false,
      menu_selectedLabel: _label,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'LABEL');

      }

    });

  }

  selectMenuSkill(skill, plan, day, key, user) {

    let menu_selectedSkills = this.state.menu_selectedSkills;

    if (Constants.objectArrayContainsKey(menu_selectedSkills, 'comp_id', skill.comp_id) === false) {
      menu_selectedSkills.push(skill);
    } else {
      Constants.removeFromArray2(menu_selectedSkills, 'comp_id', skill.comp_id);
    }

    this.setState({
      // menu_plan_skills_open: false,
      menu_selectedSkills: menu_selectedSkills,
    }, () => {

      if (Constants.isEmpty(plan) === false) {

        this.updateUserPlanner(key, user, day, plan, 'SKILLS');

      }

    });

  }

  async selectMenuAbsence(abs, av, plan, day, key, user) {

    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    let planAccountsLoad = this.state.planAccounts;

    planAccountsLoad[user.info_id][dayKey].isLoading = Constants.isEmpty(user.info_id) ? user.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad });

    if (Constants.objectArrayContainsKey(av.absence, 'afw_id', abs.afw_id) === false) {

      if (av.absence.length > 0) {
        await APIDELETE.Request(`DELETE FROM AfwezigheidN`, `WHERE afwn_id > 0 AND afwn_info_id = ${user.info_id} AND afwn_datum = '${dateString}'`);
      }

      await APIADD.Request(`INSERT INTO AfwezigheidN`, `VALUES (NULL, '${dateString}', ${user.info_id}, ${abs.afw_id}, 0)`);
      
    } else {

      await APIDELETE.Request(`DELETE FROM AfwezigheidN`, `WHERE afwn_id > 0 AND afwn_info_id = ${user.info_id} AND afwn_datum = '${dateString}'`);

    }

    const absences = await APIGET.Request(`SELECT *`, `FROM AfwezigheidN`, `LEFT JOIN Afwezigheid ON afwn_afw_id = afw_id LEFT JOIN Uitzondering ON uit_afw_id = afwn_afw_id`, `WHERE afwn_info_id = ${user.info_id} AND afwn_datum = '${dateString}'`, null, null);

    planAccountsLoad[user.info_id][dayKey].absence = [];
    for (const _abs of absences) {
      planAccountsLoad[user.info_id][dayKey].absence.push(_abs);
    }

    planAccountsLoad[user.info_id][dayKey].isLoading = false;

    this.setState({
      // menu_plan_skills_open: false,
      menu_plan_absence_open: false,
      planAccounts: planAccountsLoad,
    });

  }

  async selectMenuWaitingDay(user, av, day, activate) {

    if (Constants.isEmpty(av) === true || av.absence.length === 0) {
      return;
    }

    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    let planAccountsLoad = this.state.planAccounts;

    planAccountsLoad[user.info_id][dayKey].isLoading = Constants.isEmpty(user.info_id) ? user.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad });

    await APIEDIT.Request(`UPDATE AfwezigheidN`, `SET afwn_ziekteWachtdag = ${activate === true ? 1 : 0}`, `WHERE afwn_id = ${av.absence[0].afwn_id}`);

    const absences = await APIGET.Request(`SELECT *`, `FROM AfwezigheidN`, `LEFT JOIN Afwezigheid ON afwn_afw_id = afw_id LEFT JOIN Uitzondering ON uit_afw_id = afwn_afw_id`, `WHERE afwn_info_id = ${user.info_id} AND afwn_datum = '${dateString}'`, null, null);

    planAccountsLoad[user.info_id][dayKey].absence = [];
    for (const _abs of absences) {
      planAccountsLoad[user.info_id][dayKey].absence.push(_abs);
    }

    planAccountsLoad[user.info_id][dayKey].isLoading = false;

    this.setState({
      // menu_plan_skills_open: false,
      // menu_plan_absence_open: false,
      planAccounts: planAccountsLoad,
    });

  }

  getDaysData() {

    let toRender = [];

    toRender.push(
      <div className='plannerZ_dayInfoContainer'>
        {this.getDaysData_dayNames()}
      </div>
    );

    toRender.push(
      <div className='plannerZ_dayInfoContainer'>
        {this.getDaysData_dayNotes()}
      </div>
    );

    if (this.state.opties_planner_drukte === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_expectedLoad()}
        </div>
      );
    }

    if (this.state.opties_planner_vorigOmzet === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_turnoverLastYear()}
        </div>
      );
    }

    if (this.state.opties_planner_verwachteOmzet === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_turnoverExpected()}
        </div>
      );
    }

    if (this.state.opties_planner_omzet === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_turnoverActual()}
        </div>
      );
    }

    if (this.state.opties_planner_loonkosten === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_wage()}
        </div>
      );
    }

    if (this.state.opties_planner_berekeningen === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_calculations()}
        </div>
      );
    }

    if (this.state.opties_planner_productiviteit === true) {
      toRender.push(
        <div className='plannerZ_dayInfoContainer'>
          {this.getDaysData_productivity()}
        </div>
      );
    }

    return toRender;

  }

  getDaysData_dayNames() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingBottom: '0.5vw', paddingTop: '0.5vw' }}>

        <div className="plannerZ_employeeInfo_sub1">

          <span className="plannerZ_employeeInfo_sub1_name" style={{ fontWeight: 'bold', fontSize: '1.2vw', cursor: 'default' }}>{__('misc_week').toUpperCase()} {this.state.week}, {this.state.year}</span>

          <div className='plannerZ_dateControls_container'>
            <div className='plannerZ_dateControls_container_sub1' onClick={() => this.getNextWeek(false)}>
              <p><i className='fas fa-angle-left' /></p>
            </div>
            <div className='plannerZ_dateControls_container_sub2'>
              <DatePicker 
                selected={this.state.selectedDate}
                onSelect={date => this.selectDateCalendar(date)}
                customInput={<p><i className='far fa-calendar-day' /></p>}
                popperPlacement="top-start"
                showPopperArrow={false}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "-2vw, -1vw"
                  },
                }}
                locale={nl}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showWeekNumbers
              />
            </div>
            <div className='plannerZ_dateControls_container_sub3' onClick={() => this.getNextWeek(true)}>
              <p><i className='fas fa-angle-right' /></p>
            </div>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      const dayNotes = this.state.dayNotes[Constants.dateToString(date)];
      let dayNote = null;
      if (Constants.isEmpty(dayNotes) === false) {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          dayNote = Constants.getArrayItem(dayNotes, 'dn_team_id', Data.data.chosenTeamObj.team_id);
        } else {
          dayNote = dayNotes[0];
        }
      }

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingBottom: '0.5vw', paddingTop: '0.5vw', backgroundColor: Constants.isEmpty(dayNote) === false ? Constants.hexToRgbA(`#${dayNote.dn_kleur}`, 0.2) : null }}>
          
          <div className="plannerZ_dayContainer_day">

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span id={dayKey} style={{ fontWeight: 'bold', fontSize: '0.8vw', textTransform: 'uppercase' }}>{Constants.getShortDayName(date)} {date.getDate()} {Constants.getMonthName(date)}</span>
              <p className='planner_dayNoteIcon' onClick={() => this.openDayNoteModal(dayKey)}><i className='fal fa-note-sticky' /></p>
            </div>

          </div>

        </div>
      );

    }

    return toRender;

  }

  getDaysData_dayNotes() {

    let toRender = [];
    let hasDayNotes = false;

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      const dayNote = this.state.dayNotes[Constants.dateToString(date)];

      if (Constants.isEmpty(dayNote) === false) {
        hasDayNotes = true;
        break;
      }

    }

    if (hasDayNotes === true) {

      toRender.push(
        <div className="plannerZ_employeeInfo" style={{ paddingBottom: 0, paddingTop: 0 }}>

          <div className="plannerZ_employeeInfo_sub1">

            <span>&nbsp;</span>

          </div>

        </div>
      );

      for (let i = 0; i < 7; i++) {

        const day = (i + 1) === 7 ? 0 : (i + 1);
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
        const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

        // const dayNote = this.state.dayNotes[Constants.dateToString(date)];

        const dayNotes = this.state.dayNotes[Constants.dateToString(date)];
        let dayNote = null;
        if (Constants.isEmpty(dayNotes) === false) {
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            dayNote = Constants.getArrayItem(dayNotes, 'dn_team_id', Data.data.chosenTeamObj.team_id);
          } else {
            dayNote = dayNotes[0];
          }
        }

        toRender.push(
          <div className="plannerZ_dayContainer" style={{ paddingBottom: Constants.isEmpty(dayNote) === false ? '0.5vw' : 0, paddingTop: 0, backgroundColor: Constants.isEmpty(dayNote) === false ? Constants.hexToRgbA(`#${dayNote.dn_kleur}`, 0.2) : null }}>
          
            <div className="plannerZ_dayContainer_day_note" style={{ paddingLeft: '2.5%', paddingRight: '2.5%', textAlign: 'center' }}>

              {/* {Constants.isEmpty(dayNote) === false ?
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?
                    <div style={{ fontWeight: 600, textAlign: 'left' }}>{Data.data.chosenTeamObj.team_naam}</div>
                  : null}
                  <div>
                    <span style={{ color: `#${dayNote.dn_kleur}`, textAlign: 'center' }}>{dayNote.dn_notitie}</span>
                  </div>
                </div>
              : null} */}

              {Constants.isEmpty(dayNote) === false ?
                <span style={{ color: `#${dayNote.dn_kleur}`, textAlign: 'center' }}>{dayNote.dn_notitie}</span>
              : null}

            </div>

          </div>
        );

      }

    }

    return toRender;

  }

  getDaysData_expectedLoad_preload() {

    let toRender = [];

    let lastPlans = 0;
    for (const dayKey in this.state.expectedLoad_last3Weeks) {
      for (const p of this.state.expectedLoad_last3Weeks[dayKey]) {
        lastPlans += 1;
      }
    }

    if (lastPlans === 0) {
      return [];
    }

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span>{__('planner_web_top_expectedLoad')}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      let toReturn = [];

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
    
      const expectedLoad_last3Weeks = this.state.expectedLoad_last3Weeks[dayKey];
      const expectedLoadMinMax = this.state.expectedLoadMinMax[dayKey];

      let maxAmount = 0;

      // calculate max blocks within timespan
      for (let j = expectedLoadMinMax.min; j < expectedLoadMinMax.max; j++) { // i === 12 ? nextHour = i + 1 = 13

        let amount = 0;
  
        for (const plan of expectedLoad_last3Weeks) {
          if (Constants.timeStringToFloat(plan.rooster_begin) >= j && Constants.timeStringToFloat(plan.rooster_begin) < (j + 1)) {
            amount++;
          } else if (Constants.timeStringToFloat(plan.rooster_eind) > j && Constants.timeStringToFloat(plan.rooster_eind) <= (j + 1)) {
            amount++;
          } else if (j > Constants.timeStringToFloat(plan.rooster_begin) && j < Constants.timeStringToFloat(plan.rooster_eind)) {
            amount++;
          }
        }
  
        if (amount > maxAmount) {
          maxAmount = amount;
        }

      }

      // calculate factor if amount is higher than 10
      let factor = maxAmount > 10 ? (maxAmount / 10) : 1;
      const max = maxAmount / factor;

      // max blocks = 10; so max employees > 10 ? max / 10 = factor divider
      for (let j = expectedLoadMinMax.min; j < expectedLoadMinMax.max; j++) { // i === 12 ? nextHour = i + 1 = 13

        let expectBlocks = [];
        let amount = 0;

        for (const plan of expectedLoad_last3Weeks) {
          if (Constants.timeStringToFloat(plan.rooster_begin) >= j && Constants.timeStringToFloat(plan.rooster_begin) < (j + 1)) {
            amount++;
          } else if (Constants.timeStringToFloat(plan.rooster_eind) > j && Constants.timeStringToFloat(plan.rooster_eind) <= (j + 1)) {
            amount++;
          } else if (j > Constants.timeStringToFloat(plan.rooster_begin) && j < Constants.timeStringToFloat(plan.rooster_eind)) {
            amount++;
          }
        }

        amount = amount / factor;

        for (let k = 0; k < amount; k++) {

          expectBlocks.push(

            <div className='planner_expectBlock' style={{backgroundColor: amount === max ? Colors.color.redFilledIn : null}}>
              &nbsp;
            </div>

          );

        }

        toReturn.push(

          <div className='planner_expectColumn'>

            <div className='planner_expectBlockColumn'>
              {expectBlocks}
            </div>

            <div className='planner_expectLine'>
              {j === (expectedLoadMinMax.max - 1) ?
                <div className='planner_expectLine_2'>{j > expectedLoadMinMax.min && j < expectedLoadMinMax.max && amount === max ? <span>{j}</span> : <span>&nbsp;</span>}</div>
              : 
                <div className='planner_expectLine_1'>{j > expectedLoadMinMax.min && j < expectedLoadMinMax.max && amount === max ? <span>{j}</span> : <span>&nbsp;</span>}</div>
              }
            </div>

          </div>

        );

      }

      toRender.push(

        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">

             <div className='planner_expectContainer'>

              {toReturn}

            </div>

            <div className='planner_expectStartEnd'>
              <span>{expectedLoadMinMax.min < 10 ? '0' + expectedLoadMinMax.min : expectedLoadMinMax.min}:00</span>
              <span>{expectedLoadMinMax.max < 10 ? '0' + expectedLoadMinMax.max : expectedLoadMinMax.max}:00</span>
            </div>

          </div>

        </div>

      );
      
    }

    return toRender;

  }

  getDaysData_expectedLoad() {

    return this.state.expectedLoad_renderObj;

  }

  getDaysData_turnoverLastYear() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span style={{ fontStyle: 'italic', width: '100%' }}>{Localization.t.formatString(__('planner_web_top_lastYear'), (this.state.year - 1))}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
      let date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
      
      if (Constants.isLeapYear(date.getFullYear()) || Constants.isLeapYear(date.getFullYear() - 1)) {
        date.setDate(date.getDate() - 365);
      } else {
        date.setDate(date.getDate() - 364);
      }

      const turnoverObject = Constants.getArrayItem(this.state.turnoverLastYear, 'omzetd_datum', Constants.dateToString(date));
      const turnover = Constants.isEmpty(turnoverObject) === true || isNaN(parseFloat(turnoverObject.omzetd_hoeveelheid)) ? 0 : parseFloat(turnoverObject.omzetd_hoeveelheid);

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">
            <div className='plannerZ_dayInfoBar_values'>
              <span style={{ fontStyle: 'italic', width: '100%' }}>{this.formatter.format(turnover)}</span>
            </div>
          </div>

        </div>
      );

    }

    return toRender;

  }

  getDaysData_turnoverExpected() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span>{__('planner_web_top_expected')}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
    
      const turnover = this.state.inputs_turnoverExpected[dayKey];

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">
            <div className='plannerZ_dayInfoBar_values'>
              <input 
                // value={turnover}
                className='plannerZ_input_turnover'
                defaultValue={turnover}
                onChange={(e) => this.onChangeTurnover(e, day, 'EXPECTED')}
                placeholder={0}
                type={'number'}
                step={0.01} 
                // dir={'rtl'}
              />
            </div>
          </div>

        </div>
      );

    }

    return toRender;

  }

  getDaysData_turnoverActual() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span>{__('planner_web_top_actual')}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
    
      const turnover = this.state.inputs_turnoverActual[dayKey];

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">
            <div className='plannerZ_dayInfoBar_values'>
              <input 
                // value={turnover}
                className='plannerZ_input_turnover'
                defaultValue={turnover}
                onChange={(e) => this.onChangeTurnover(e, day, 'ACTUAL')}
                placeholder={0}
                type={'number'}
                step={0.01} 
                // dir={'rtl'}
              />
            </div>
          </div>

        </div>
      );

    }

    return toRender;

  }

  getDaysData_wage() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span>{__('planner_web_top_wage')}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      const wageCosts = this.getCalculationsDay(day).wage;
      const turnover = (isNaN(parseFloat(this.state.inputs_turnoverActual[dayKey])) || parseFloat(this.state.inputs_turnoverActual[dayKey]) === 0) ? isNaN(parseFloat(this.state.inputs_turnoverExpected[dayKey])) ? 0 : parseFloat(this.state.inputs_turnoverExpected[dayKey]) : parseFloat(this.state.inputs_turnoverActual[dayKey]);

      const salaryPercent = isNaN(parseFloat(Data.data.appOptions.opties_loonPercentage)) ? 0 : parseFloat(Data.data.appOptions.opties_loonPercentage);
      const isAboveSalaryPercent = Constants.calcPercentInt(wageCosts, turnover) > salaryPercent;

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">
            <div className='plannerZ_dayInfoBar_values'>
              <span style={{ color: isAboveSalaryPercent && salaryPercent !== 0 ? Colors.color.redFilledIn : null }}>{this.formatter.format(wageCosts)}</span>
            </div>
          </div>

        </div>
      );

    }

    return toRender;

  }

  getDaysData_calculations() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span style={{ fontWeight: 700 }}>{__('planner_web_top_total')}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      const total = this.getCalculationsDay(day).total;

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">
            <div className='plannerZ_dayInfoBar_values'>
              <span style={{ fontWeight: 600 }}>{this.formatter.format(total)}</span>
            </div>
          </div>

        </div>
      );

    }

    return toRender;

  }

  getDaysData_productivity() {

    let toRender = [];

    toRender.push(
      <div className="plannerZ_employeeInfo" style={{ paddingTop: 0, paddingBottom: 0 }}>

        <div className="plannerZ_employeeInfo_sub1">

          <div className='plannerZ_dayInfoBar'>
            <span>{__('planner_web_top_productivity')}</span>
          </div>

        </div>

      </div>
    );

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      // const dayName = day === 1 ? 'Maandag' : day === 2 ? 'Dinsdag' : day === 3 ? 'Woensdag' : day === 4 ? 'Donderdag' : day === 5 ? 'Vrijdag' : day === 6 ? 'Zaterdag' : day === 0 ? 'Zondag' : 'Maandag';
      const date = day === 1 ? Constants.stringToDate(this.state.mondayDate) : day === 2 ? Constants.stringToDate(this.state.tuesdayDate) : day === 3 ? Constants.stringToDate(this.state.wednesdayDate) : day === 4 ? Constants.stringToDate(this.state.thursdayDate) : day === 5 ? Constants.stringToDate(this.state.fridayDate) : day === 6 ? Constants.stringToDate(this.state.saturdayDate) : day === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      const productivity = this.getCalculationsDay(day).productivity;
      const _productivity = isNaN(productivity) || isFinite(productivity) === false ? 0 : productivity;

      toRender.push(
        <div className="plannerZ_dayContainer" style={{ paddingTop: 0, paddingBottom: 0 }}>
          
          <div className="plannerZ_dayContainer_day">
            <div className='plannerZ_dayInfoBar_values'>
              <span>{this.formatter.format(_productivity)}</span>
            </div>
          </div>

        </div>
      );

    }

    return toRender;

  }

  iterateJobSelections(key) {

    if (Constants.isEmpty(this.state.excel_selectionsJob) === false && Constants.isEmpty(this.state.excel_selectedJob) === false && Constants.isEmpty(this.state.excel_selectedJob[key]) === false) {

      let excel_selectedJob = this.state.excel_selectedJob;

      const pos = this.state.excel_selectionsJob.findIndex(x => x.functie_id === this.state.excel_selectedJob[key].functie_id);
      const nextPos = pos + 1 === this.state.excel_selectionsJob.length ? 0 : pos + 1;

      if (Constants.isEmpty(excel_selectedJob[key]) === true) {
        excel_selectedJob[key] = null;
      }
      excel_selectedJob[key] = this.state.excel_selectionsJob[nextPos];

      this.setState({ excel_selectedJob: excel_selectedJob });

    }

  }

  getSecondaryJobsSelections(user, key) {

    let selections = [];
    let excel_selectedJob = this.state.excel_selectedJob;

    if (Constants.isEmpty(user) === false) {

      if (Constants.isEmpty(user.functie_id) === false) {

        selections.push(user);

      }

      const secondaryJobs = this.state.secondaryJobs[user.info_id];

      if (Constants.isEmpty(secondaryJobs) === false) {

        for (const job of secondaryJobs) {
          selections.push(job);
        }

      }

    // OPEN SHIFT
    } else {

      if (Constants.isEmpty(user.functie_id) === false) {

        selections.push(user);

      }

    }

    if (selections.length === 0) {

      for (const job of this.state.jobs) {
        selections.push(job);
      }

    }

    if (Constants.isEmpty(excel_selectedJob[key]) === true) {
      excel_selectedJob[key] = null;
    }
    excel_selectedJob[key] = selections[0];

    this.setState({ excel_selectionsJob: selections, excel_selectedJob: excel_selectedJob });

  }

  getSectionSelections() {

    let toRender = [];

    toRender.push(
      <option value={'null'} selected={this.state.section === null}>{__('misc_allSections')}</option>
    );

    for (const section of this.state.sections) {

      toRender.push(
        <option value={section.afdeling_id} selected={this.state.section === section.afdeling_id}>{section.afdeling_naam}</option>
      );

    }

    return toRender;

  }

  getDayShiftsSelections() {

    let toRender = [];

    for (const temp of this.state.dayShiftsTemplates) {

      toRender.push(
        <option value={temp.dagdienstsjabloon_id} selected={this.state.dayShiftsTemplate === temp.dagdienstsjabloon_id}>{temp.dagdienstsjabloon_naam}</option>
      );

    }

    return toRender;

  }

  onChangeTurnover(e, day, type) {

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const turnover = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
    const _NaN = isNaN(parseFloat(e.target.value));

    if (type === 'ACTUAL') {

      let inputs = this.state.inputs_turnoverActual;
      inputs[dayKey] = turnover;

      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        this.setState({ inputs_turnoverActual: inputs });
        // APIEDIT.Request(`UPDATE OmzetDagelijks`, `SET omzetd_hoeveelheid = ${turnover}`, `WHERE omzetd_datum = '${dateString}'`);
        // DELETE o FROM OmzetDagelijks o LEFT JOIN TeamOmzet ot ON ot.to_omzetd_id = o.omzetd_id WHERE o.omzetd_datum = '2022-04-04' AND ot.to_team_id = 3;
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          await APIDELETE.Request(`DELETE o FROM OmzetDagelijks o`, `LEFT JOIN TeamOmzet ot ON ot.to_omzetd_id = o.omzetd_id WHERE o.omzetd_datum = '${dateString}' AND ot.to_team_id = ${Data.data.chosenTeamObj.team_id}`);
        } else {
          await APIDELETE.Request(`DELETE FROM OmzetDagelijks`, `WHERE omzetd_datum = '${dateString}'`);
        }
        if (_NaN === false) {

          await APIADD.Request(`INSERT INTO OmzetDagelijks`, `VALUES (NULL, '${dateString}', ${turnover})`);

          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
            await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
          }

        }
      }, 200);

    } else if (type === 'EXPECTED') {

      let inputs = this.state.inputs_turnoverExpected;
      inputs[dayKey] = turnover;

      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        this.setState({ inputs_turnoverExpected: inputs });
        // APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = ${turnover}`, `WHERE omzetv_datum = '${dateString}'`);
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          await APIDELETE.Request(`DELETE o FROM OmzetVerwachting o`, `LEFT JOIN TeamOmzetV ot ON ot.tov_omzetv_id = o.omzetv_id WHERE o.omzetv_datum = '${dateString}' AND ot.tov_team_id = ${Data.data.chosenTeamObj.team_id}`);
        } else {
          await APIDELETE.Request(`DELETE FROM OmzetVerwachting`, `WHERE omzetv_datum = '${dateString}'`);
        }
        if (_NaN === false) {

          await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${dateString}', ${turnover})`);

          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
            await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
          }

        }
      }, 200);

    }

  }

  onClickTile(key, user, plan, av, day, fromType) {

    if (this.state.selected !== key) {

      this.from = plan; 
      this.fromType = fromType; 
      this.closeAllPlanMenus();

      this.setState({ selected: key, menu_employee_open: {} });

    } else {

      this.from = null; 
      this.fromType = ''; 
      this.closeAllPlanMenus();

      this.setState({ selected: null, menu_employee_open: {} });

    }

  }

  onDragStartPlan(e, key, plan) {

    if (this.timeRef[key] === document.activeElement) {
      e.preventDefault();
      return false;
    }

    this.from = plan; 
    this.fromType = 'PLAN'; 

    this.closeAllPlanMenus();

  }

  onDragStartDayShift(e, key, shift) {

    if (this.timeRef[key] === document.activeElement) {
      e.preventDefault();
      return false;
    }

    this.from = shift; 
    this.fromType = 'SHIFT'; 

    this.closeAllPlanMenus();

  }

  onDragOver(e) {
    e.preventDefault();
    // e.target.style.opacity = 0.2;
    e.currentTarget.style.opacity = 0.2;
    // e.currentTarget.parentNode.style.opacity = 0.2;
  }

  onDragLeave(e) {
    e.preventDefault();
    e.currentTarget.style.opacity = null;
    // e.target.style.opacity = null;
    // e.currentTarget.parentNode.style.opacity = null;
  }

  onDragOverOther(e) {
    e.preventDefault();
    //e.currentTarget.style.backgroundColor = '#EDF8FD';
    //e.target.style.backgroundColor = '#EDF8FD';
  }

  onDragLeaveOther(e) {
    e.preventDefault();
    //e.currentTarget.style.backgroundColor = 'white';
    //e.target.style.backgroundColor = 'white';
  }

  async onDropPlan(e, user_to, toData, dayTo) {

    e.target.style.opacity = null;
    e.currentTarget.style.opacity = null;

    const _from = this.from;
    const _fromType = this.fromType;

    this.from = null;
    this.fromType = '';
    this.setState({ selected: null });

    const dayKeyTo = dayTo === 0 ? 'sun' : dayTo === 1 ? 'mon' : dayTo === 2 ? 'tue' : dayTo === 3 ? 'wed' : dayTo === 4 ? 'thu' : dayTo === 5 ? 'fri' : dayTo === 6 ? 'sat' : 'mon';
    const dateTo = dayTo === 1 ? Constants.stringToDate(this.state.mondayDate) : dayTo === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayTo === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayTo === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayTo === 5 ? Constants.stringToDate(this.state.fridayDate) : dayTo === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayTo === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

    // FROM PLAN
    if (Constants.isEmpty(_from) === false && _fromType === 'PLAN') {

      if (toData.rooster_id === _from.rooster_id) {
        return;
      }

      const dayFrom = Constants.stringToDate(_from.rooster_datum).getDay();
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      // CHECK PLAN INFO SETTINGS
      if (user_to.info_id !== _from.info_id && Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], _from.rooster_begin.substr(0, 5), _from.rooster_eind.substr(0, 5), false, true) === false) {
        return;
      }
      if (user_to.info_id !== _from.info_id && Plan.checkInfoExtra_add(this.state.accountsObj[_from.info_id], _from.info_id, Constants.dateToString(dateFrom), this.state.planAccounts_maxDays[_from.info_id], this.state.planAccounts_cumulative[_from.info_id], toData.rooster_begin.substr(0, 5), toData.rooster_eind.substr(0, 5), false, true) === false) {
        return;
      }

      // CHECK TEAM INFO
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (user_to.info_id !== _from.info_id && Constants.isEmpty(user_to.info_id) === false && (Constants.isEmpty(this.teamUsers[user_to.info_id]) === true || Constants.isEmpty(this.teamPlanData[_from.rooster_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[user_to.info_id], 'team_id', this.teamPlanData[_from.rooster_id].team_id) === false)) {
          const userFullName = `${user_to.info_voornaam} ${(user_to.info_tussenvoegsel !== null && user_to.info_tussenvoegsel !== '') ? user_to.info_tussenvoegsel + " " + user_to.info_achternaam : user_to.info_achternaam}`;
          window.alert(`${userFullName} zit niet in het team van ${this.teamPlanData[_from.rooster_id].team_naam}`);
          return;
        }
        if (user_to.info_id !== _from.info_id && Constants.isEmpty(_from.info_id) === false && (Constants.isEmpty(this.teamUsers[_from.info_id]) === true || Constants.isEmpty(this.teamPlanData[toData.rooster_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[_from.info_id], 'team_id', this.teamPlanData[toData.rooster_id].team_id) === false)) {
          const userFullName = `${_from.info_voornaam} ${(_from.info_tussenvoegsel !== null && _from.info_tussenvoegsel !== '') ? _from.info_tussenvoegsel + " " + _from.info_achternaam : _from.info_achternaam}`;
          window.alert(`${userFullName} zit niet in het team van ${this.teamPlanData[toData.rooster_id].team_naam}`);
          return;
        }
      }

      // LOADING
      let planAccountsLoad = this.state.planAccounts;
      let planListLoad = this.state.planList;
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      planListLoad[dayKeyFrom].isLoading = _from.functie_id;
      planListLoad[dayKeyTo].isLoading = toData.functie_id;
      planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = Constants.isEmpty(_from.info_id) ? _from.functie_id : true;
      planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
      this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

      let promises = [];
      // let promises2 = [];

      // DELETE AND ADD
      promises.push(APIDeleteUserPlanner.Request(_from.rooster_id));
      promises.push(APIDeleteUserPlanner.Request(toData.rooster_id));

      await Promise.all(promises);

      // plan persons
      if (this.state.display === 'LIST') {
        await this.planUser(user_to, Constants.dateToString(dateFrom), _from, _fromType);
        await this.planUser(_from, Constants.dateToString(dateTo), toData, _fromType);
      } else {
        await this.planUser(user_to, Constants.dateToString(dateTo), _from, _fromType);
        await this.planUser(_from, Constants.dateToString(dateFrom), toData, _fromType);
      }

      // await Promise.all(promises2);

      // RELOAD
      if (user_to.info_id === _from.info_id) {
        
        if (dayFrom === dayTo) {
          await this.reload_one_user_one_day(user_to.info_id, dayFrom);
        } else {
          await this.reload_one_user_two_days(user_to.info_id, dayFrom, dayTo);
        }

      } else {

        if (dayFrom === dayTo) {
          await this.reload_two_users_one_day(_from.info_id, user_to.info_id, dayFrom);
        } else {
          await this.reload_two_users_two_days(_from.info_id, user_to.info_id, dayFrom, dayTo);
        }

      }

    // FROM SHIFT
    } else if (Constants.isEmpty(_from) === false && _fromType === 'SHIFT') {

      const dayFrom = parseInt(_from.dagdienst_dag);
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], _from.dagdienst_begin.substr(0, 5), _from.dagdienst_eind.substr(0, 5), false, true) === false) {
        return;
      }

      // LOADING
      let planAccountsLoad = this.state.planAccounts;
      let planListLoad = this.state.planList;
      // // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      planListLoad[dayKeyTo].isLoading = user_to.functie_id;
      planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
      this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

      // CONVERTION FROM SHIFTS TO PLAN (TO PLAN AN USER)
      const _fromShift = {
        ..._from,
        rooster_begin: _from.dagdienst_begin,
        rooster_eind: _from.dagdienst_eind,
        rooster_pauze: _from.dagdienst_pauze,
      };

      let promises = [];

      // DELETE AND ADD
      promises.push(APIDeleteUserPlanner.Request(toData.rooster_id));
      promises.push(this.planUser(user_to, Constants.dateToString(dateTo), _fromShift, _fromType));

      // DELETE FROM AVAILABLE DAYSHIFTS
      promises.push(APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${_from.dagdienst_id}`));

      await Promise.all(promises);

      // RELOAD
      // if (dayFrom === dayTo) {
        await this.reload_one_user_one_day_shifts(user_to.info_id, dayTo);
      // } else {
      //   await this.reload_one_user_two_days_shifts(user_to.info_id, dayFrom, dayTo);
      // }

    // FROM AV
    } else if (Constants.isEmpty(_from) === false && _fromType === 'AV') {

      const dayFrom = Constants.stringToDate(_from.dateFrom).getDay();
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      if (dayFrom !== dayTo) {
        window.alert(__('misc_dragToSameDay_alert'));
        return;
      }

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[_from.info_id], _from.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[_from.info_id], this.state.planAccounts_cumulative[_from.info_id], toData.rooster_begin.substr(0, 5), toData.rooster_eind.substr(0, 5), false, true) === false) {
        return;
      }

      // CHECK TEAMS
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (Constants.isEmpty(_from.info_id) === false && (Constants.isEmpty(this.teamUsers[_from.info_id]) === true || Constants.isEmpty(this.teamPlanData[toData.rooster_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[_from.info_id], 'team_id', this.teamPlanData[toData.rooster_id].team_id) === false)) {
          const userFullName = `${_from.info_voornaam} ${(_from.info_tussenvoegsel !== null && _from.info_tussenvoegsel !== '') ? _from.info_tussenvoegsel + " " + _from.info_achternaam : _from.info_achternaam}`;
          window.alert(`${userFullName} zit niet in het team van ${this.teamPlanData[toData.rooster_id].team_naam}`);
          return;
        }
       }

      // LOADING
      let planListLoad = this.state.planList;
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      // planListLoad[dayKeyFrom].isLoading = _from.functie_id;
      planListLoad[dayKeyTo].isLoading = toData.functie_id;
      this.setState({ planList: planListLoad });

      let promises = [];

      // DELETE AND ADD
      promises.push(APIDeleteUserPlanner.Request(toData.rooster_id));

      await Promise.all(promises);

      // plan persons
      await this.planUser(_from, Constants.dateToString(dateTo), toData, 'PLAN');

      // await Promise.all(promises2);

      // RELOAD
      if (user_to.info_id === _from.info_id) {
        
        if (dayFrom === dayTo) {
          await this.reload_one_user_one_day(user_to.info_id, dayFrom);
        } else {
          await this.reload_one_user_two_days(user_to.info_id, dayFrom, dayTo);
        }

      } else {

        if (dayFrom === dayTo) {
          await this.reload_two_users_one_day(_from.info_id, user_to.info_id, dayFrom);
        } else {
          await this.reload_two_users_two_days(_from.info_id, user_to.info_id, dayFrom, dayTo);
        }

      }

    }

  }

  async onDropAv(e, user_to, toData, dayTo, func) {

    if (e.target.className.includes('touchbox') && e.target.className.includes('touchbox_empty') === false) {
      return;
    }

    let _from = this.from;
    const _fromType = this.fromType;

    this.from = null;
    this.fromType = '';
    this.setState({ selected: null });

    const dayKeyTo = dayTo === 0 ? 'sun' : dayTo === 1 ? 'mon' : dayTo === 2 ? 'tue' : dayTo === 3 ? 'wed' : dayTo === 4 ? 'thu' : dayTo === 5 ? 'fri' : dayTo === 6 ? 'sat' : 'mon';
    const dateTo = dayTo === 1 ? Constants.stringToDate(this.state.mondayDate) : dayTo === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayTo === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayTo === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayTo === 5 ? Constants.stringToDate(this.state.fridayDate) : dayTo === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayTo === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

    // FROM PLAN
    if (Constants.isEmpty(_from) === false && _fromType === 'PLAN') {

      if (this.state.display === 'LIST') {
        user_to = _from;
      }

      const dayFrom = Constants.stringToDate(_from.rooster_datum).getDay();
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], _from.rooster_begin.substr(0, 5), _from.rooster_eind.substr(0, 5), true, true, user_to.info_id === _from.info_id ? Constants.dateToString(dateFrom) : null) === false) {
        return;
      }

      // CHECK TEAM INFO
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (user_to.info_id !== _from.info_id && Constants.isEmpty(user_to.info_id) === false && (Constants.isEmpty(this.teamUsers[user_to.info_id]) === true || Constants.isEmpty(this.teamPlanData[_from.rooster_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[user_to.info_id], 'team_id', this.teamPlanData[_from.rooster_id].team_id) === false)) {
          const userFullName = `${user_to.info_voornaam} ${(user_to.info_tussenvoegsel !== null && user_to.info_tussenvoegsel !== '') ? user_to.info_tussenvoegsel + " " + user_to.info_achternaam : user_to.info_achternaam}`;
          window.alert(`${userFullName} zit niet in het team van ${this.teamPlanData[_from.rooster_id].team_naam}`);
          return;
        }
      }

      // LOADING
      let planAccountsLoad = this.state.planAccounts;
      let planListLoad = this.state.planList;
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      if (this.state.display === 'LIST') {
        planListLoad[dayKeyFrom].isLoading = _from.functie_id;
        planListLoad[dayKeyTo].isLoading = func.functie_id;
      }
      planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = Constants.isEmpty(_from.info_id) ? _from.functie_id : true;
      planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
      this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

      //let promises = [];

      // DELETE AND ADD
      if (this.state.copyShifts === false) {
        await APIDeleteUserPlanner.Request(_from.rooster_id);
      }
      await this.planUser(user_to, Constants.dateToString(dateTo), _from, _fromType, null, func);

      //await Promise.all(promises);

      // RELOAD
      if (user_to.info_id === _from.info_id) {
        
        if (dayFrom === dayTo) {
          await this.reload_one_user_one_day(user_to.info_id, dayFrom);
        } else {
          await this.reload_one_user_two_days(user_to.info_id, dayFrom, dayTo);
        }

      } else {

        if (dayFrom === dayTo) {
          await this.reload_two_users_one_day(_from.info_id, user_to.info_id, dayFrom);
        } else {
          await this.reload_two_users_two_days(_from.info_id, user_to.info_id, dayFrom, dayTo);
        }

      }

      // NOTI
      if (_from.rooster_publiceren === '1') {
        KodictNotifications.sendPushNotification(_from.info_id, 'planner', __('misc_planner2'), Localization.t.formatString(__('planner_noti_deleted'), `${Constants.stringToDate(_from.rooster_datum).toLocaleDateString()} ${_from.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${_from.rooster_eind.substr(0, 5)}` : ``}`))
      }

    // FROM SHIFT
    } else if (Constants.isEmpty(_from) === false && _fromType === 'SHIFT') {

      const dayFrom = parseInt(_from.dagdienst_dag);
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], _from.dagdienst_begin.substr(0, 5), _from.dagdienst_eind.substr(0, 5), true, true) === false) {
        return;
      }

      // LOADING
      let planAccountsLoad = this.state.planAccounts;
      let planListLoad = this.state.planList;
      // // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      planListLoad[dayKeyTo].isLoading = user_to.functie_id;
      planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
      this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

      // CONVERTION FROM SHIFTS TO PLAN (TO PLAN AN USER)
      const _fromShift = {
        ..._from,
        rooster_begin: _from.dagdienst_begin,
        rooster_eind: _from.dagdienst_eind,
        rooster_pauze: _from.dagdienst_pauze,
      };

      let promises = [];

      // DELETE AND ADD
      //promises.push(APIDeleteUserPlanner.Request(_from.rooster_id));
      promises.push(this.planUser(user_to, Constants.dateToString(dateTo), _fromShift, _fromType));

      // DELETE FROM AVAILABLE DAYSHIFTS
      promises.push(APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${_from.dagdienst_id}`));

      await Promise.all(promises);

      // RELOAD
      // if (dayFrom === dayTo) {
        await this.reload_one_user_one_day_shifts(user_to.info_id, dayTo);
      // } else {
      //   await this.reload_one_user_two_days_shifts(user_to.info_id, dayFrom, dayTo);
      // }

    // FROM AV
    } else if (Constants.isEmpty(_from) === false && _fromType === 'AV') {

      if (this.state.display === 'LIST') {
        user_to = _from;
      }
  
      const dayFrom = Constants.stringToDate(_from.dateFrom).getDay();
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
  
      const newPlan = {
        rooster_datum: _from.dateFrom,
        rooster_begin: '00:00',
        rooster_eind: '00:00',
        rooster_pauze: '00:00',
        functie_id: func.functie_id,
      };

      if (dayFrom !== dayTo) {
        window.alert(__('misc_dragToSameDay_alert'));
        return;
      }

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[_from.info_id], _from.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[_from.info_id], this.state.planAccounts_cumulative[_from.info_id], newPlan.rooster_begin.substr(0, 5), newPlan.rooster_eind.substr(0, 5), true, true, Constants.dateToString(dateFrom)) === false) {
        return;
      }
  
      // // CHECK TEAM INFO
      // if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      //   if (Constants.isEmpty(_from.info_id) === false && (Constants.isEmpty(this.teamUsers[_from.info_id]) === true || Constants.isEmpty(this.teamPlanData[_from.rooster_id]) === true || Constants.objectArrayContainsKey(this.teamUsers[user_to.info_id], 'team_id', this.teamPlanData[_from.rooster_id].team_id) === false)) {
      //     const userFullName = `${user_to.info_voornaam} ${(user_to.info_tussenvoegsel !== null && user_to.info_tussenvoegsel !== '') ? user_to.info_tussenvoegsel + " " + user_to.info_achternaam : user_to.info_achternaam}`;
      //     window.alert(`${userFullName} zit niet in het team van ${this.teamPlanData[_from.rooster_id].team_naam}`);
      //     return;
      //   }
      // }
  
      // LOADING
      let planAccountsLoad = this.state.planAccounts;
      let planListLoad = this.state.planList;
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      if (this.state.display === 'LIST') {
        // planListLoad[dayKeyFrom].isLoading = _from.functie_id;
        planListLoad[dayKeyTo].isLoading = func.functie_id;
      }
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = Constants.isEmpty(_from.info_id) ? _from.functie_id : true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
      this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });
  
      //let promises = [];
  
      // DELETE AND ADD
      await this.planUser(user_to, Constants.dateToString(dateTo), newPlan, 'PLAN', null, func);
  
      //await Promise.all(promises);
  
      // RELOAD
      if (dayFrom === dayTo) {
        await this.reload_one_user_one_day(user_to.info_id, dayFrom);
      } else {
        await this.reload_one_user_two_days(user_to.info_id, dayFrom, dayTo);
      }

    }

  }

  async onDropShift(e, shift, dayTo, func) {

    e.target.style.opacity = null;
    e.currentTarget.style.opacity = null;

    const _from = this.from;
    const _fromType = this.fromType;

    this.from = null;
    this.fromType = '';
    this.setState({ selected: null });

    const dayKeyTo = dayTo === 0 ? 'sun' : dayTo === 1 ? 'mon' : dayTo === 2 ? 'tue' : dayTo === 3 ? 'wed' : dayTo === 4 ? 'thu' : dayTo === 5 ? 'fri' : dayTo === 6 ? 'sat' : 'mon';
    const dateTo = dayTo === 1 ? Constants.stringToDate(this.state.mondayDate) : dayTo === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayTo === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayTo === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayTo === 5 ? Constants.stringToDate(this.state.fridayDate) : dayTo === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayTo === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

    // FROM PLAN
    if (Constants.isEmpty(_from) === false && _fromType === 'PLAN') {

      const dayFrom = Constants.stringToDate(_from.rooster_datum).getDay();
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[_from.info_id], _from.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[_from.info_id], this.state.planAccounts_cumulative[_from.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), false, true) === false) {
        return;
      }

      // CHECK TEAMS
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (Constants.isEmpty(this.teamPlanData[_from.rooster_id]) === true || this.teamPlanData[_from.rooster_id].team_id !== Data.data.chosenTeamObj.team_id) {
          return;
        }
       }

      // LOADING
      let planListLoad = this.state.planList;
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      planListLoad[dayKeyFrom].isLoading = _from.functie_id;
      planListLoad[dayKeyTo].isLoading = func.functie_id;
      this.setState({ planList: planListLoad });

      let promises = [];
      // let promises2 = [];

      // DELETE AND ADD
      promises.push(APIDeleteUserPlanner.Request(_from.rooster_id));

      await Promise.all(promises);

      // plan persons
      await this.planUser(_from, Constants.dateToString(dateTo), shift, 'SHIFT', null, func);

      // await Promise.all(promises2);

      // RELOAD
      if (dayFrom === dayTo) {
        await this.reload_one_user_one_day_shifts(_from.info_id, dayTo);
      } else {
        await this.reload_one_user_two_days_shifts(_from.info_id, dayFrom, dayTo);
      }

    // FROM AV
    } else if (Constants.isEmpty(_from) === false && _fromType === 'AV') {

      const dayFrom = Constants.stringToDate(_from.dateFrom).getDay();
      const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
      const dateFrom = dayFrom === 1 ? Constants.stringToDate(this.state.mondayDate) : dayFrom === 2 ? Constants.stringToDate(this.state.tuesdayDate) : dayFrom === 3 ? Constants.stringToDate(this.state.wednesdayDate) : dayFrom === 4 ? Constants.stringToDate(this.state.thursdayDate) : dayFrom === 5 ? Constants.stringToDate(this.state.fridayDate) : dayFrom === 6 ? Constants.stringToDate(this.state.saturdayDate) : dayFrom === 0 ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);

      if (dayFrom !== dayTo) {
        window.alert(__('misc_dragToSameDay_alert'));
        return;
      }

      // CHECK PLAN INFO SETTINGS
      if (Plan.checkInfoExtra_add(this.state.accountsObj[_from.info_id], _from.info_id, Constants.dateToString(dateTo), this.state.planAccounts_maxDays[_from.info_id], this.state.planAccounts_cumulative[_from.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), false, true) === false) {
        return;
      }

      // // CHECK TEAMS
      // if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      //   if (Constants.isEmpty(this.teamPlanData[_from.rooster_id]) === true || this.teamPlanData[_from.rooster_id].team_id !== Data.data.chosenTeamObj.team_id) {
      //     return;
      //   }
      //  }

      // LOADING
      let planListLoad = this.state.planList;
      // planAccountsLoad[_from.info_id][dayKeyFrom].isLoading = true;
      // planAccountsLoad[user_to.info_id][dayKeyTo].isLoading = true;
      // planListLoad[dayKeyFrom].isLoading = _from.functie_id;
      planListLoad[dayKeyTo].isLoading = func.functie_id;
      this.setState({ planList: planListLoad });

      // plan persons
      await this.planUser(_from, Constants.dateToString(dateTo), shift, 'SHIFT', null, func);

      // await Promise.all(promises2);

      // RELOAD
      if (dayFrom === dayTo) {
        await this.reload_one_user_one_day_shifts(_from.info_id, dayTo);
      } else {
        await this.reload_one_user_two_days_shifts(_from.info_id, dayFrom, dayTo);
      }

    }

  }

  reloadWholePlanner() {

    this.setState({ reloading: true }, () => {

      this.loadGeneralData();

    });

  }

  reloadPlannerPublish() {

    this.setState({ reloadingPublish: true }, () => {

      this.loadGeneralData();

    });

  }

  async reloadPlanTemplates() {

    this.setState({ reloading: true });

    const planTemplates = await APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, null, `GROUP BY sjabloonm_key`, null);

    // calculations
    let tempKey = 0;
    for (const temp of planTemplates) {
      if (parseInt(temp.sjabloonm_key) > tempKey) {
        tempKey = parseInt(temp.sjabloonm_key);
      }
    }

    this.setState({
      planTemplates: planTemplates,
      highestPlanTempKey: tempKey,
      reloading: false,
    });

  }

  async reloadDayNotes() {

    this.setState({ reloading: true });

    const notes = await APIGET.Request(`SELECT *`, `FROM DagNotitie`, `LEFT JOIN Team ON dn_team_id = team_id`, `WHERE dn_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    const notesObj = Constants.convertToObjectWithArrays(notes, 'dn_datum');

    this.setState({ 
      dayNotes: notesObj, 
      reloading: false 
    });

  }

  async reloadDaySmartPlan(day) {

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    const date = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;

    const [
      planData,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      // allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      // APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    // const allPlanDataObject = Constants.convertToObjectWithArrays(allPlanData, 'rooster_info_id');
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;

    // first set length to 0
    for (const userKey in planAccounts) {
      planAccounts[userKey][dayKey].plans = [];
    }

    // add new data
    for (const userKey in planAccounts) {
      for (const plan of planData) {
        if (plan.rooster_info_id === userKey) {
          const date = Constants.stringToDate(plan.rooster_datum);
          const day = date.getDay();
          const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
          planAccounts[userKey][dayKey].plans.push(plan);
        }
      }
    }

    // SAVE STATES
    const planCalculations = await this.planCalculations(planAccounts);

    // this.setState({ 
    //   planAccounts: planAccounts,
    //   dayShifts: dayShiftsObj,
    //   skills: skillsObject,
    //   addEmptyRowsUser: addEmptyRowsUser,
    //   availableShifts: availableShiftsObject,
    //   availableDayShifts: availableDayShiftsObject,

    //   highestCumulative: (planCalculations.highest - 7),
    //   planAccounts_cumulative: planCalculations.planAccounts_cumulative,
    //   planAccounts_maxDays: planCalculations.planAccounts_maxDays,
    // }, () => {

    //   if (Constants.isEmpty(type) === false && Constants.isEmpty(key) === false && type !== 'ALL') {
    //     this.timeRef[key].focus();
    //   }

    // });

    return {
      planAccounts: planAccounts,
      planAccounts_cumulative: planCalculations.planAccounts_cumulative,
      planAccounts_maxDays: planCalculations.planAccounts_maxDays,
      dayShifts: dayShiftsObj,
    };

  }

  async reload_one_user_one_day(userID_to, day, key, type) {

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    const date = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;

    const toString = Constants.isEmpty(userID_to) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_to}`;

    // TODO RELOAD TEAMPLANDATA

    const [
      planData,
      planDataList,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND ${toString} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND ${toString} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND ${toString} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const allPlanDataObject = this.getAllPlansUser(this.state.allAccounts, allPlanData);
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;
    let planList = this.state.planList;

    // first set length to 0
    planAccounts[userID_to][dayKey].plans = [];
    planList[dayKey].plans = [];

    // add new data
    for (const plan of planData) {
      if (plan.rooster_info_id === userID_to) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_to][dayKey].plans.push(plan);
      }
    }
    for (const plan of planDataList) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    // LOADING
    planAccounts[userID_to][dayKey].isLoading = false;
    planList[dayKey].isLoading = false;

    this.changeReloadState(userID_to, null, planAccounts, allPlanDataObject, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject, key, type, true, dayKey);

  }

  async reload_one_user_two_days(userID_to, dayFrom, dayTo) {

    const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
    const dateFrom = dayFrom === 1 ? this.state.mondayDate : dayFrom === 2 ? this.state.tuesdayDate : dayFrom === 3 ? this.state.wednesdayDate : dayFrom === 4 ? this.state.thursdayDate : dayFrom === 5 ? this.state.fridayDate : dayFrom === 6 ? this.state.saturdayDate : dayFrom === 0 ? this.state.sundayDate : this.state.mondayDate;

    const dayKeyTo = dayTo === 0 ? 'sun' : dayTo === 1 ? 'mon' : dayTo === 2 ? 'tue' : dayTo === 3 ? 'wed' : dayTo === 4 ? 'thu' : dayTo === 5 ? 'fri' : dayTo === 6 ? 'sat' : 'mon';
    const dateTo = dayTo === 1 ? this.state.mondayDate : dayTo === 2 ? this.state.tuesdayDate : dayTo === 3 ? this.state.wednesdayDate : dayTo === 4 ? this.state.thursdayDate : dayTo === 5 ? this.state.fridayDate : dayTo === 6 ? this.state.saturdayDate : dayTo === 0 ? this.state.sundayDate : this.state.mondayDate;

    const toString = Constants.isEmpty(userID_to) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_to}`;

    const [
      planData,
      planDataList,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND ${toString} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND ${toString} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND ${toString} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const allPlanDataObject = this.getAllPlansUser(this.state.allAccounts, allPlanData);
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;
    let planList = this.state.planList;

    // first set length to 0
    planAccounts[userID_to][dayKeyFrom].plans = [];
    planAccounts[userID_to][dayKeyTo].plans = [];
    planList[dayKeyFrom].plans = [];
    planList[dayKeyTo].plans = [];


    // add new data
    for (const plan of planData) {
      if (plan.rooster_info_id === userID_to) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_to][dayKey].plans.push(plan);
      }
    }
    for (const plan of planDataList) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    // LOADING
    planAccounts[userID_to][dayKeyFrom].isLoading = false;
    planAccounts[userID_to][dayKeyTo].isLoading = false;
    planList[dayKeyFrom].isLoading = false;
    planList[dayKeyTo].isLoading = false;


    this.changeReloadState(userID_to, null, planAccounts, allPlanDataObject, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject);

  }

  async reload_two_users_one_day(userID_from, userID_to, day) {

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    const date = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;

    const toString = Constants.isEmpty(userID_to) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_to}`;
    const fromString = Constants.isEmpty(userID_from) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_from}`;

    const [
      planData,
      planDataList,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND (${toString} AND ((rooster_datum = '${date}')) OR ${fromString} AND ((rooster_datum = '${date}')))`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND (${toString} AND (rooster_datum = '${date}')) OR (${fromString} AND (rooster_datum = '${date}'))`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND (((rooster_datum = '${date}')) OR ((rooster_datum = '${date}')))`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND ((rooster_datum = '${date}')) OR ((rooster_datum = '${date}'))`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND ((${toString} AND (rooster_datum = '${date}')) OR (${fromString} AND (rooster_datum = '${date}')))`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND (((rooster_datum = '${date}')) OR ((rooster_datum = '${date}')))`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const allPlanDataObject = this.getAllPlansUser(this.state.allAccounts, allPlanData);
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;
    let planList = this.state.planList;

    // first set length to 0
    planAccounts[userID_to][dayKey].plans = [];
    planAccounts[userID_from][dayKey].plans = [];
    planList[dayKey].plans = [];

    // add new data
    for (const plan of planData) {
      if (plan.rooster_info_id === userID_to) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_to][dayKey].plans.push(plan);
      }
      if (plan.rooster_info_id === userID_from) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_from][dayKey].plans.push(plan);
      }
    }
    for (const plan of planDataList) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    // LOADING
    planAccounts[userID_to][dayKey].isLoading = false;
    planAccounts[userID_from][dayKey].isLoading = false;
    planList[dayKey].isLoading = false;

    this.changeReloadState(userID_to, userID_from, planAccounts, allPlanDataObject, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject);

  }

  async reload_two_users_two_days(userID_from, userID_to, dayFrom, dayTo) {

    const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
    const dateFrom = dayFrom === 1 ? this.state.mondayDate : dayFrom === 2 ? this.state.tuesdayDate : dayFrom === 3 ? this.state.wednesdayDate : dayFrom === 4 ? this.state.thursdayDate : dayFrom === 5 ? this.state.fridayDate : dayFrom === 6 ? this.state.saturdayDate : dayFrom === 0 ? this.state.sundayDate : this.state.mondayDate;

    const dayKeyTo = dayTo === 0 ? 'sun' : dayTo === 1 ? 'mon' : dayTo === 2 ? 'tue' : dayTo === 3 ? 'wed' : dayTo === 4 ? 'thu' : dayTo === 5 ? 'fri' : dayTo === 6 ? 'sat' : 'mon';
    const dateTo = dayTo === 1 ? this.state.mondayDate : dayTo === 2 ? this.state.tuesdayDate : dayTo === 3 ? this.state.wednesdayDate : dayTo === 4 ? this.state.thursdayDate : dayTo === 5 ? this.state.fridayDate : dayTo === 6 ? this.state.saturdayDate : dayTo === 0 ? this.state.sundayDate : this.state.mondayDate;

    const toString = Constants.isEmpty(userID_to) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_to}`;
    const fromString = Constants.isEmpty(userID_from) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_from}`;

    const [
      planData,
      planDataList,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND (${fromString} OR ${toString}) AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND (${fromString} OR ${toString}) AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND (${fromString} OR ${toString}) AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const allPlanDataObject = this.getAllPlansUser(this.state.allAccounts, allPlanData);
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;
    let planList = this.state.planList;

    // first set length to 0
    planAccounts[userID_to][dayKeyFrom].plans = [];
    planAccounts[userID_to][dayKeyTo].plans = [];
    planAccounts[userID_from][dayKeyFrom].plans = [];
    planAccounts[userID_from][dayKeyTo].plans = [];
    planList[dayKeyFrom].plans = [];
    planList[dayKeyTo].plans = [];


    // add new data
    for (const plan of planData) {
      if (plan.rooster_info_id === userID_to) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_to][dayKey].plans.push(plan);
      }
      if (plan.rooster_info_id === userID_from) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_from][dayKey].plans.push(plan);
      }
    }
    for (const plan of planDataList) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    // LOADING
    planAccounts[userID_to][dayKeyTo].isLoading = false;
    planAccounts[userID_to][dayKeyFrom].isLoading = false;
    planAccounts[userID_from][dayKeyTo].isLoading = false;
    planAccounts[userID_from][dayKeyFrom].isLoading = false;
    planList[dayKeyTo].isLoading = false;
    planList[dayKeyFrom].isLoading = false;


    this.changeReloadState(userID_to, userID_from, planAccounts, allPlanDataObject, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject);

  }

  async reload_one_user_one_day_shifts(userID_to, day) {

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    const date = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;

    const toString = Constants.isEmpty(userID_to) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_to}`;

    const [
      planData,
      planDataList,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND ${toString} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND ${toString} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND ${toString} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND rooster_datum = '${date}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const allPlanDataObject = this.getAllPlansUser(this.state.allAccounts, allPlanData);
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;
    let planList = this.state.planList;

    // first set length to 0
    planAccounts[userID_to][dayKey].plans = [];
    planList[dayKey].plans = [];

    // add new data
    for (const plan of planData) {
      if (plan.rooster_info_id === userID_to) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_to][dayKey].plans.push(plan);
      }
    }
    for (const plan of planDataList) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    // LOADING
    planAccounts[userID_to][dayKey].isLoading = false;
    planList[dayKey].isLoading = false;

    this.changeReloadState(userID_to, null, planAccounts, allPlanDataObject, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject);

  }

  async reload_one_user_two_days_shifts(userID_to, dayFrom, dayTo) {

    const dayKeyFrom = dayFrom === 0 ? 'sun' : dayFrom === 1 ? 'mon' : dayFrom === 2 ? 'tue' : dayFrom === 3 ? 'wed' : dayFrom === 4 ? 'thu' : dayFrom === 5 ? 'fri' : dayFrom === 6 ? 'sat' : 'mon';
    const dateFrom = dayFrom === 1 ? this.state.mondayDate : dayFrom === 2 ? this.state.tuesdayDate : dayFrom === 3 ? this.state.wednesdayDate : dayFrom === 4 ? this.state.thursdayDate : dayFrom === 5 ? this.state.fridayDate : dayFrom === 6 ? this.state.saturdayDate : dayFrom === 0 ? this.state.sundayDate : this.state.mondayDate;

    const dayKeyTo = dayTo === 0 ? 'sun' : dayTo === 1 ? 'mon' : dayTo === 2 ? 'tue' : dayTo === 3 ? 'wed' : dayTo === 4 ? 'thu' : dayTo === 5 ? 'fri' : dayTo === 6 ? 'sat' : 'mon';
    const dateTo = dayTo === 1 ? this.state.mondayDate : dayTo === 2 ? this.state.tuesdayDate : dayTo === 3 ? this.state.wednesdayDate : dayTo === 4 ? this.state.thursdayDate : dayTo === 5 ? this.state.fridayDate : dayTo === 6 ? this.state.saturdayDate : dayTo === 0 ? this.state.sundayDate : this.state.mondayDate;

    const toString = Constants.isEmpty(userID_to) ? `rooster_info_id IS NULL` : `rooster_info_id = ${userID_to}`;

    const [
      planData,
      planDataList,
      dayShiftsData,
      usedDayShiftsData,
      skills,
      availableShifts,
      availableDayShifts,
      teamPlanData,
      allPlanData,
    ] = await Promise.all([
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND ${toString} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND ${toString} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      // Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND teamr_team_id = ${Data.data.chosenTeamObj.team_id} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`) : APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND ${toString} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id LEFT JOIN InfoExtra ON info2_info_id = info_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND (rooster_datum = '${dateFrom}' OR rooster_datum = '${dateTo}')`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id LEFT JOIN CompDagDienst ON compd_dagdienst_id = dagdienst_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind, bijzd_dagdienst_id DESC, compd_dagdienst_id DESC`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
      APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
      APIGET.Request(`SELECT *`, `FROM TeamRooster`, `LEFT JOIN Team ON teamr_team_id = team_id LEFT JOIN Rooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, `ORDER BY team_naam`),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShiftsData, usedDayShiftsData);
    const skillsObject = Constants.convertToObjectWithArrays(skills, 'compr_rooster_id');
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');
    const availableDayShiftsObject = Constants.convertToObject(availableDayShifts, 'shb_dagdienst_id');
    const allPlanDataObject = this.getAllPlansUser(this.state.allAccounts, allPlanData);
    this.teamPlanData = Constants.convertToObject(teamPlanData, 'teamr_rooster_id');

    let planAccounts = this.state.planAccounts;
    let planList = this.state.planList;

    // first set length to 0
    planAccounts[userID_to][dayKeyFrom].plans = [];
    planAccounts[userID_to][dayKeyTo].plans = [];
    planList[dayKeyFrom].plans = [];
    planList[dayKeyTo].plans = [];


    // add new data
    for (const plan of planData) {

      if (plan.rooster_info_id === userID_to) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planAccounts[userID_to][dayKey].plans.push(plan);
      }

    }
    for (const plan of planDataList) {
      const date = Constants.stringToDate(plan.rooster_datum);
      const day = date.getDay();
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      planList[dayKey].plans.push(plan);
    }

    // LOADING
    //planAccounts[userID_to][dayKeyFrom].isLoading = false;
    planAccounts[userID_to][dayKeyTo].isLoading = false;
    planList[dayKeyFrom].isLoading = false;
    planList[dayKeyTo].isLoading = false;

    this.changeReloadState(userID_to, null, planAccounts, allPlanDataObject, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject);

  }

  async changeReloadState(userID_to, userID_from, planAccounts, allPlanData, dayShiftsObj, skillsObject, availableShiftsObject, availableDayShiftsObject, key, type, directly, dayKey_to) {

    // EXTRA ROWS
    let addEmptyRowsUser = this.state.addEmptyRowsUser;
    if (Constants.isEmpty(addEmptyRowsUser[userID_to]) === false) {
      addEmptyRowsUser[userID_to] = 0;
    }
    if (Constants.isEmpty(addEmptyRowsUser[userID_from]) === false) {
      addEmptyRowsUser[userID_from] = 0;
    }

    let addEmptyRowsJob = this.state.addEmptyRowsJob;
    for (const jobKey in addEmptyRowsJob) {
      addEmptyRowsJob[jobKey] = 0;
    }

    const planCalculations = await this.planCalculations(allPlanData);

    this.setState({ 
      planAccounts: planAccounts,
      dayShifts: dayShiftsObj,
      skills: skillsObject,
      addEmptyRowsUser: addEmptyRowsUser,
      addEmptyRowsJob: addEmptyRowsJob,
      availableShifts: availableShiftsObject,
      availableDayShifts: availableDayShiftsObject,
      allPlansUser: allPlanData,

      highestCumulative: (planCalculations.highest - 7),
      planAccounts_cumulative: planCalculations.planAccounts_cumulative,
      planAccounts_maxDays: planCalculations.planAccounts_maxDays,
    }, () => {

      // reloading inputs EXCEL display
      if (this.state.display === 'XCL' && directly !== true) {

        for (const userKey in planAccounts) {

          let highestRows = 1;
          for (const dayKey in planAccounts[userKey]) {
            for (const plan of planAccounts[userKey][dayKey].plans) {
              const planKey = `plan${plan.rooster_id}`;
              if (Constants.isEmpty(this.timeRef[planKey]) === false) {
                this.timeRef[planKey].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
              }
              highestRows++;
            }
          }

          for (const dayKey in planAccounts[userKey]) {

            for (let row = 0; row < highestRows; row++) {

              if (Constants.isEmpty(userKey) === false) {

                const user = this.state.accountsObj[userKey];
                const avKey = `av${user.info_id}${user.functie_id}${dayKey}${row}`;

                if (Constants.isEmpty(this.timeRef[avKey]) === false) {
                  this.timeRef[avKey].value = '';
                }

              } else {

                // OPEN SHIFTS
                for (const job of this.state.jobs) {

                  const user = { info_id: null, functie_id: job.functie_id, functie_naam: job.functie_naam, functie_kleur: job.functie_kleur };
                  const avKey = `av${user.info_id}${user.functie_id}${dayKey}${row}`;

                  if (Constants.isEmpty(this.timeRef[avKey]) === false) {
                    this.timeRef[avKey].value = '';
                  }
            
                }

              }

            }

            for (const plan of planAccounts[userKey][dayKey].plans) {

              const planKey = `plan${plan.rooster_id}`;

              if (Constants.isEmpty(this.timeRef[planKey]) === false) {
                this.timeRef[planKey].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
              }

            }

          }

        }

      } else if (this.state.display === 'XCL' && directly === true) {

        let highestRows = 1;
        for (const plan of planAccounts[userID_to][dayKey_to].plans) {
          const planKey = `plan${plan.rooster_id}`;
          if (Constants.isEmpty(this.timeRef[planKey]) === false) {
            this.timeRef[planKey].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
          }
          highestRows++;
        }

        for (let row = 0; row < highestRows; row++) {

          if (Constants.isEmpty(userID_to) === false) {

            const user = this.state.accountsObj[userID_to];
            const avKey = `av${user.info_id}${user.functie_id}${dayKey_to}${row}`;

            if (Constants.isEmpty(this.timeRef[avKey]) === false) {
              this.timeRef[avKey].value = '';
            }

          } else {

            // OPEN SHIFTS
            for (const job of this.state.jobs) {

              const user = { info_id: null, functie_id: job.functie_id, functie_naam: job.functie_naam, functie_kleur: job.functie_kleur };
              const avKey = `av${user.info_id}${user.functie_id}${dayKey_to}${row}`;

              if (Constants.isEmpty(this.timeRef[avKey]) === false) {
                this.timeRef[avKey].value = '';
              }
            
            }

          }

        }

        for (const plan of planAccounts[userID_to][dayKey_to].plans) {

          const planKey = `plan${plan.rooster_id}`;

          if (Constants.isEmpty(this.timeRef[planKey]) === false) {
            this.timeRef[planKey].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
          }

        }

      }

      if (Constants.isEmpty(type) === false && Constants.isEmpty(key) === false && Constants.isEmpty(this.state.menu_plan_open[key]) === false && this.state.menu_plan_open[key] === true && type !== 'ALL') {
        this.timeRef[key].focus();
      } else if (this.state.display === 'XCL' && Constants.isEmpty(this.state.menu_plan_open[key]) === false && this.state.menu_plan_open[key] === true && Constants.isEmpty(type) === false && Constants.isEmpty(key) === false && type === 'ALL') {
        this.timeRef[key].focus();
      } else if (this.state.display === 'XCL' && Constants.isEmpty(this.state.menu_plan_open[key]) === true && Constants.isEmpty(type) === false && Constants.isEmpty(key) === false && type === 'ALL') {
        this.timeRef[key].focus();
      }

    });

  }

  async planUser(user_to, date, from, fromType, key, func) {

    // from always contains an info_id
    // to can either be planObject, shiftObject, availabilityObject

    const dateObject = Constants.stringToDate(date);
    const dateString = Constants.dateToString(dateObject);
    const day = dateObject.getDay().toString();
    const dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';

    const incorrectTimes = this.getIncorrectTimes(user_to, date, from, fromType);

    if (Constants.isEmpty(from.rooster_pauze)) {
      from.rooster_pauze = '00:00:00';
    }

    if (this.state.display === 'LIST') {
      from.functie_id = Constants.isEmpty(func) ? from.functie_id : func.functie_id;
    }

    let _break = from.rooster_pauze.substring(0,5);
    // if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(from.rooster_datum) === false) {
    //   _break = this.getBreakRule(from.rooster_datum, from.rooster_begin, from.rooster_datum, from.rooster_eind, from);
    //   _break = _break.str.substr(0, 5);
    // }

    if (Constants.isEmpty(user_to.info_id) === true) {
      await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${from.rooster_begin.substring(0,5)}', '${from.rooster_eind.substring(0,5)}', ${user_to.info_id}, ${from.functie_id === null ? 'NULL' : user_to.functie_id}, 3, '${_break}', ${incorrectTimes === true ? 1 : 0})`);
    } else {
      await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${from.rooster_begin.substring(0,5)}', '${from.rooster_eind.substring(0,5)}', ${user_to.info_id}, ${from.functie_id === null ? 'NULL' : from.functie_id}, 0, '${_break}', ${incorrectTimes === true ? 1 : 0})`);
    }
    const [
      added, // ADDED ID
      dayShifts,
      usedDayShifts,
    ] = await Promise.all([
      APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`),
      APIGET.Request(`SELECT *`, `FROM DagDienst`, `LEFT JOIN Functie ON functie_id = dagdienst_functie_id LEFT JOIN BijzDagDienst ON bijzd_dagdienst_id = dagdienst_id LEFT JOIN Bijzonderheid ON bijz_id = bijzd_bijz_id`, null, `GROUP BY dagdienst_id`, `ORDER BY dagdienst_begin, dagdienst_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),
    ]);

    const dayShiftsObj = this.getDayShifts(dayShifts, usedDayShifts);

    let promises = [];

    // TEAMS
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      if (Constants.isEmpty(this.state.menu_selectedTeam) === false) {
        await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${this.state.menu_selectedTeam.team_id}, ${added[0].rooster_id})`);
        // promises.push(APIAddToTeamPlanner.Request(this.state.menu_selectedTeam.team_id, parseInt(added[0].rooster_id)));
      } else if (Constants.isEmpty(from.rooster_id) === false && Constants.isEmpty(this.teamPlanData[from.rooster_id]) === false && Constants.isEmpty(this.teamPlanData[from.rooster_id].team_id) === false) {
        await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${this.teamPlanData[from.rooster_id].team_id}, ${added[0].rooster_id})`);
        // promises.push(APIAddToTeamPlanner.Request(this.teamPlanData[from.rooster_id].team_id, parseInt(added[0].rooster_id)));
      } else {
        await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${added[0].rooster_id})`);
        // promises.push(APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, parseInt(added[0].rooster_id)));
      }
    }

    // OTHERS
    // PLAN
    if (Constants.isEmpty(from) === false && fromType === 'PLAN') {

      // LABEL
      if (Constants.isEmpty(from.bijz_id) === false) {
        promises.push(APIAddPartSchedule.Request(from.bijz_id, added[0].rooster_id));
      }

      // SKILLS
      let skillsStringPlan = ``;
      const skills = this.state.skills[from.rooster_id];
      if (Constants.isEmpty(skills) === false && skills.length > 0) {
        for (const skill of skills) {
          promises.push(APIAddSkillSchedule.Request(skill.comp_id, added[0].rooster_id));
          skillsStringPlan += `${skill.comp_id}`;
        }
      } else {
        skillsStringPlan = 'null';
      }

      // check if day shifts have same details so we use one of the dayshifts template
      const planKeyID = `${day}${from.rooster_begin}${from.rooster_eind}${from.rooster_pauze}${from.functie_id}${from.bijz_id}${skillsStringPlan}`;
      for (const shift of dayShiftsObj[dayKey]) {
        // SKILLS DAYSHIFT CHECK
        let skillsString = ``;
        const skillsDayShift = this.state.skillsDayShifts[shift.dagdienst_id];
        if (Constants.isEmpty(skillsDayShift) === false && skillsDayShift.length > 0) {
          for (const skill of skillsDayShift) {
            skillsString += `${skill.comp_id}`;
          }
        } else {
          skillsString = `null`;
        }
        const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;
        if (planKeyID === shiftKey) {
          promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added[0].rooster_id})`));
          break;
        }
      }

    // PLAN MENU
    } else if (Constants.isEmpty(from) === false && fromType === 'MENU') {

      // LABEL
      if (Constants.isEmpty(from.bijz_id) === false) {
        promises.push(APIAddPartSchedule.Request(from.bijz_id, added[0].rooster_id));
      }
  
      // SKILLS
      let skillsStringPlan = ``;
      let skills = this.state.menu_selectedSkills;
      if (Constants.isEmpty(skills) === false && skills.length > 0) {
        skills.sort((a, b) => (a.comp_id > b.comp_id) ? 1 : -1);
        for (const skill of skills) {
          promises.push(APIAddSkillSchedule.Request(skill.comp_id, added[0].rooster_id));
          skillsStringPlan += `${skill.comp_id}`;
        }
      } else {
        skillsStringPlan = 'null';
      }
  
      // check if day shifts have same details so we use one of the dayshifts template
      const planKeyID = `${day}${from.rooster_begin}:00${from.rooster_eind}:00${from.rooster_pauze}:00${from.functie_id}${from.bijz_id}${skillsStringPlan}`;
      for (const shift of dayShiftsObj[dayKey]) {
        // SKILLS DAYSHIFT CHECK
        let skillsString = ``;
        const skillsDayShift = this.state.skillsDayShifts[shift.dagdienst_id];
        if (Constants.isEmpty(skillsDayShift) === false && skillsDayShift.length > 0) {
          for (const skill of skillsDayShift) {
            skillsString += `${skill.comp_id}`;
          }
        } else {
          skillsString = `null`;
        }
        const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;
        if (planKeyID === shiftKey) {
          promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${dateString}', ${shift.dagdienst_id}, ${added[0].rooster_id})`));
          break;
        }
      }

      //this.closeAllPlanMenus();

    // SHIFT
    } else if (Constants.isEmpty(from) === false && fromType === 'SHIFT') {

      // LABEL
      if (Constants.isEmpty(from.bijz_id) === false) {
        promises.push(APIAddPartSchedule.Request(from.bijz_id, added[0].rooster_id));
      }

      // SKILLS
      const skills = this.state.skillsDayShifts[from.dagdienst_id];
      if (Constants.isEmpty(skills) === false) {
        for (const skill of skills) {
          promises.push(APIAddSkillSchedule.Request(skill.comp_id, added[0].rooster_id));
        }
      }

      promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${from.dagdienst_id}, ${added[0].rooster_id})`));

    }

    await Promise.all(promises);

    if (this.state.display === 'XCL' && Constants.isEmpty(key) === false) {
      this.isPlanning_excel[key] = false;
    }
    this.isPlanning = false;

    return parseInt(added[0].rooster_id);

  }

  async planUserMenu(key, user_to, date, day, func) {

    if (this.isPlanning || Constants.isEmpty(this.timeRef[key]) === true) {
      return;
    }

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    const start = this.timeRef[key].value.substr(0, 5);
    const end = this.timeRef[key].value.substr(8, 13);

    this.canOpenPlanMenu = true;

    const newPlan = {
      rooster_begin: start.length === 5 && this.isValidForSave(start) === true ? start : '00:00',
      rooster_eind: end.length === 5 && this.isValidForSave(end) === true ? end : '00:00',
      rooster_datum: date,
      rooster_pauze: this.state.menu_selectedBreak,
      functie_id: Constants.isEmpty(this.state.menu_selectedJob) === false ? this.state.menu_selectedJob.functie_id : null,
      bijz_id: Constants.isEmpty(this.state.menu_selectedLabel) === false ? this.state.menu_selectedLabel.bijz_id : null,
    };

    // VALIDATION
    let error_employee = false;
    let error_time = false;
    let error_job = false;

    if (start.length !== 5 || this.isValidForSave(start) === false || end.length !== 5 || this.isValidForSave(end) === false) {
      error_time = true;
    }
    if (Constants.isEmpty(newPlan.functie_id) === true) {
      error_job = true;
    }
    if (Constants.isEmpty(user_to) === true) {
      error_employee = true;
    }

    if (error_time || error_job || error_employee) {
      this.setState({ error_time: error_time, error_job: error_job, error_employee: error_employee });
      return;
    }

    // CHECK PLAN INFO SETTINGS
    if (Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, date, this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], start, end, true, true) === false) {
      return;
    }

    this.isPlanning = true;

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    planListLoad[dayKey].isLoading = this.state.display === 'LIST' ? func.functie_id : user_to.functie_id;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    await this.planUser(user_to, date, newPlan, 'MENU', null, func);
    await this.reload_one_user_one_day(user_to.info_id, day);

  }

  async planUserDayShiftMenu(key, user_to, date, day, shift, func) {

    if (this.isPlanning) {
      return;
    }

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    const start = shift.dagdienst_begin.substr(0, 5);
    const end = shift.dagdienst_eind.substr(0, 5);

    this.canOpenPlanMenu = true;

    const newPlan = {
      ...shift,
      rooster_begin: shift.dagdienst_begin,
      rooster_eind: shift.dagdienst_eind,
      rooster_datum: date,
      rooster_pauze: shift.dagdienst_pauze.substr(0, 5),
      functie_id: shift.dagdienst_functie_id,
      bijz_id: shift.bijz_id,
    };

    // VALIDATION
    let error_employee = false;
    let error_time = false;
    let error_job = false;

    // if (start.length !== 5 || this.isValidForSave(start) === false || end.length !== 5 || this.isValidForSave(end) === false) {
    //   error_time = true;
    // }
    // if (Constants.isEmpty(newPlan.functie_id) === true) {
    //   error_job = true;
    // }
    if (Constants.isEmpty(user_to) === true) {
      error_employee = true;
    }

    if (error_time || error_job || error_employee) {
      this.setState({ error_time: error_time, error_job: error_job, error_employee: error_employee });
      return;
    }

    // CHECK PLAN INFO SETTINGS
    if (Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, date, this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], start, end, true, true) === false) {
      return;
    }

    this.isPlanning = true;

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    planListLoad[dayKey].isLoading = this.state.display === 'LIST' ? func.functie_id : user_to.functie_id;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    await this.planUser(user_to, date, newPlan, 'SHIFT', null, func);
    await this.reload_one_user_one_day(user_to.info_id, day);

  }

  async planUserDirect(key, user_to, date, day) {

    if ((Constants.isEmpty(this.isPlanning_excel[key]) === false && this.isPlanning_excel[key]) || Constants.isEmpty(this.timeRef[key]) === true) {
      return;
    }

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    const start = this.timeRef[key].value.substr(0, 5);
    const end = this.timeRef[key].value.substr(8, 13);

    this.canOpenPlanMenu = true;

    const newPlan = {
      rooster_begin: start.length === 5 && this.isValidForSave(start) === true ? start : '00:00',
      rooster_eind: end.length === 5 && this.isValidForSave(end) === true ? end : '00:00',
      rooster_datum: date,
      rooster_pauze: Constants.isEmpty(this.state.excel_selectedBreak) === false && Constants.isEmpty(this.state.excel_selectedBreak[key]) === false ? this.state.excel_selectedBreak[key] : '00:00',
      functie_id: Constants.isEmpty(this.state.excel_selectedJob) === false && Constants.isEmpty(this.state.excel_selectedJob[key]) === false ? this.state.excel_selectedJob[key].functie_id : user_to.functie_id,
      bijz_id: Constants.isEmpty(this.state.menu_selectedLabel) === false ? this.state.menu_selectedLabel.bijz_id : null,
    };

    // VALIDATION
    let error_time = false;
    let error_job = false;

    if (start.length !== 5 || this.isValidForSave(start) === false || end.length !== 5 || this.isValidForSave(end) === false) {
      error_time = true;
    }
    if (Constants.isEmpty(newPlan.functie_id) === true) {
      error_job = true;
    }

    if (error_time || error_job) {
      this.timeRef[key].value = '';
      this.setState({ error_time: error_time, error_job: error_job });
      return;
    }

    // CHECK PLAN INFO SETTINGS
    if (Plan.checkInfoExtra_add(this.state.accountsObj[user_to.info_id], user_to.info_id, date, this.state.planAccounts_maxDays[user_to.info_id], this.state.planAccounts_cumulative[user_to.info_id], start, end, true, true) === false) {
      this.timeRef[key].value = '';
      return;
    }

    this.isPlanning_excel[key] = true;

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    planListLoad[dayKey].isLoading = user_to.functie_id;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    await this.planUser(user_to, date, newPlan, 'MENU', key);
    await this.reload_one_user_one_day(user_to.info_id, day);

  }

  getIncorrectTimes(user_to, date, from, fromType) {

    const dateObject = Constants.stringToDate(date);
    const dateString = Constants.dateToString(dateObject);
    const day = dateObject.getDay().toString();
    const dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';

    let planStart = null;
    let planEnd = null;

    const userAvail = this.state.planAccounts[user_to.info_id][dayKey];

    if (Constants.isEmpty(userAvail) === false) {

      if (fromType === 'PLAN' || fromType === 'MENU') {

        planStart = Constants.stringToDateTime(dateString, from.rooster_begin);
        planEnd = Constants.stringToDateTime(Plan.isNightShift2(from), from.rooster_eind);

      } else if (fromType === 'SHIFT') {

        from.rooster_datum = dateString;
        from.rooster_begin = from.dagdienst_begin;
        from.rooster_eind = from.dagdienst_eind;

        planStart = Constants.stringToDateTime(dateString, from.dagdienst_begin);
        planEnd = Constants.stringToDateTime(Plan.isNightShift2(from), from.dagdienst_eind);

      } else if (fromType === 'TEMPLATE') {

        planStart = Constants.stringToDateTime(dateString, from.rooster_begin);
        planEnd = Constants.stringToDateTime(Plan.isNightShift2(from), from.rooster_eind);

      }

      // AVAILABLE
      for (const av of userAvail.av) {

        const availStart = Constants.stringToDateTime(dateString, av.beschikbaar_begin);
        const availEnd = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'AV'), av.beschikbaar_eind);

        if((planStart >= availStart && planEnd <= availEnd) || (av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00')) {
          return false;
        } else {
          return true;
        }

      }

      // UNAVAILABLE
      for (const av of userAvail.unav) {

        const availStart = Constants.stringToDateTime(dateString, av.onbeschikbaar_begin);
        const availEnd = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'UNAV'), av.onbeschikbaar_eind);

        if(av.onbeschikbaar_begin === '00:00:00' && av.onbeschikbaar_eind === '00:00:00') {
          return true;
        }

        if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
          return true;
        }
        if(planStart >= availStart && planStart <= availEnd) {
          return true;
        }
        if(planEnd >= availStart && planEnd <= availEnd) {
          return true;
        }
        if(planStart <= availStart && planEnd >= availEnd) {
          return true;
        }

      }

      // STANDARD AVAILABLE
      for (const av of userAvail.sav) {

        const availStart = Constants.stringToDateTime(dateString, av.sb_begin);
        const availEnd = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'SAV'), av.sb_eind);

        if((planStart >= availStart && planEnd <= availEnd) || (av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00')) {
          return false;
        } else {
          return true;
        }

      }

      // STANDARD UNAVAILABLE
      for (const av of userAvail.sunav) {

        const availStart = Constants.stringToDateTime(dateString, av.sb_begin);
        const availEnd = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'SUNAV'), av.sb_eind);

        if(av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00') {
          return true;
        }

        if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
          return true;
        }
        if(planStart >= availStart && planStart <= availEnd) {
          return true;
        }
        if(planEnd >= availStart && planEnd <= availEnd) {
          return true;
        }
        if(planStart <= availStart && planEnd >= availEnd) {
          return true;
        }

      }

      // SCHOOL
      for (const av of userAvail.school) {

        if (userAvail.schoolHoliday.length > 0) {
          return false;
        }

        const availStart = Constants.stringToDateTime(dateString, av.schoolrooster_begin);
        const availEnd = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'SCHOOL'), av.schoolrooster_eind);

        if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
          return true;
        }
        if(planStart >= availStart && planStart <= availEnd) {
          return true;
        }
        if(planEnd >= availStart && planEnd <= availEnd) {
          return true;
        }
        if(planStart <= availStart && planEnd >= availEnd) {
          return true;
        }

      }

    }

    return false;

  }

  async updateUserPlanner(key, user_to, day, plan, type = 'ALL') {

    if (Constants.isEmpty(this.timeRef[key]) === true) {
      return;
    }

    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    const start = this.timeRef[key].value.substr(0, 5);
    const end = this.timeRef[key].value.substr(8, 13);

    this.canOpenPlanMenu = true;

    const newPlan = {
      rooster_begin: start.length === 5 && this.isValidForSave(start) === true ? start : '00:00',
      rooster_eind: end.length === 5 && this.isValidForSave(end) === true ? end : '00:00',
      rooster_datum: plan.rooster_datum,
      rooster_pauze: this.state.menu_selectedBreak,
      functie_id: Constants.isEmpty(this.state.menu_selectedJob) === false ? this.state.menu_selectedJob.functie_id : null,
      bijz_id: Constants.isEmpty(this.state.menu_selectedLabel) === false ? this.state.menu_selectedLabel.bijz_id : null,
    };

    // VALIDATION
    let error_employee = false;
    let error_time = false;
    let error_job = false;

    if (type === 'ALL' || type === 'SHIFT' || type === 'BREAK' || type === 'JOB') {
      if (start.length !== 5 || this.isValidForSave(start) === false || end.length !== 5 || this.isValidForSave(end) === false) {
        error_time = true;
      }
      if (Constants.isEmpty(newPlan.functie_id) === true) {
        error_job = true;
      }
      if (Constants.isEmpty(user_to) === true) {
        error_employee = true;
      }
    }

    if (error_time || error_job || error_employee) {
      this.state.planAccounts[user_to.info_id][dayKey].isLoading = false;
      this.setState({ error_time: error_time, error_job: error_job, error_employee: error_employee });
      return;
    }

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    // planListLoad[dayKey].isLoading = true;
    if (this.state.display === 'LIST') {
      planAccountsLoad[plan.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    } else {
      planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    }
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    const incorrectTimes = this.getIncorrectTimes(user_to, dateString, newPlan, 'MENU');

    const publishing = this.state.display === 'LIST' ? Constants.isEmpty(user_to.info_id) ? 3 : plan.rooster_publiceren === '3' ? 0 : plan.rooster_publiceren : plan.rooster_publiceren;

    await Promise.all([
      type === 'ALL' || type === 'SKILLS' ? APIDELETE.Request(`DELETE FROM CompRooster`, `WHERE compr_rooster_id = ${plan.rooster_id}`) : null,
      type === 'ALL' || type === 'LABEL' ? APIDELETE.Request(`DELETE FROM BijzRooster`, `WHERE bijzr_rooster_id = ${plan.rooster_id}`) : null,
      type === 'ALL' || type === 'SHIFT' || type === 'BREAK' || type === 'JOB' ? APIEDIT.Request(`UPDATE Rooster`, `SET rooster_info_id = ${user_to.info_id}, rooster_begin = '${newPlan.rooster_begin}', rooster_eind = '${newPlan.rooster_eind}', rooster_pauze = '${newPlan.rooster_pauze}', rooster_functie_id = ${newPlan.functie_id}, rooster_tijd_incorrect = ${incorrectTimes === true ? 1 : 0}, rooster_publiceren = ${publishing}`, `WHERE rooster_id = ${plan.rooster_id}`) : null,
      type === 'TEAM' ? APIEDIT.Request(`UPDATE TeamRooster`, `SET teamr_team_id = ${this.state.menu_selectedTeam.team_id}`, `WHERE teamr_rooster_id = ${plan.rooster_id}`) : null,
    ]);
    
    let promises = [];

    // LABEL
    if (type === 'ALL' || type === 'LABEL') {
      if (Constants.isEmpty(newPlan.bijz_id) === false) {
        promises.push(APIAddPartSchedule.Request(newPlan.bijz_id, plan.rooster_id));
      }
    }
  
    // SKILLS
    if (type === 'ALL' || type === 'SKILLS') {
      let skills = this.state.menu_selectedSkills;
      if (Constants.isEmpty(skills) === false && skills.length > 0) {
        for (const skill of skills) {
          promises.push(APIAddSkillSchedule.Request(skill.comp_id, plan.rooster_id));
        }
      }
    }

    await Promise.all(promises);

    if (type === 'ALL') {
      this.closeAllPlanMenus();
    }

    // RELOAD
    await this.reload_one_user_one_day(user_to.info_id, day, key, type);

    // NOTI
    if (plan.rooster_publiceren === '1' && (start !== plan.rooster_begin.substr(0, 5) || end !== plan.rooster_eind.substr(0, 5))) {
      KodictNotifications.sendPushNotification(user_to.info_id, 'planner', __('misc_planner2'), Localization.t.formatString(__('planner_noti_edited'), `${Constants.stringToDate(dateString).toLocaleDateString()} ${plan.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${plan.rooster_eind.substr(0, 5)}` : ``}`, `${Constants.stringToDate(dateString).toLocaleDateString()} ${start}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${end}` : ``}`))
    }

  }

  async updateUserPlannerDirect(key, user_to, day, plan, type = 'SHIFT') {

    if (Constants.isEmpty(this.timeRef[key]) === true) {
      return;
    }

    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    const start = this.timeRef[key].value.substr(0, 5);
    const end = this.timeRef[key].value.substr(8, 13);

    this.canOpenPlanMenu = true;

    const newPlan = {
      rooster_begin: start.length === 5 && this.isValidForSave(start) === true ? start : '00:00',
      rooster_eind: end.length === 5 && this.isValidForSave(end) === true ? end : '00:00',
      rooster_datum: plan.rooster_datum,
      rooster_pauze: this.state.menu_selectedBreak,
      functie_id: Constants.isEmpty(this.state.menu_selectedJob) === false ? this.state.menu_selectedJob.functie_id : plan.rooster_functie_id,
      bijz_id: Constants.isEmpty(this.state.menu_selectedLabel) === false ? this.state.menu_selectedLabel.bijz_id : plan.bijz_id,
    };

    // VALIDATION
    let error_time = false;
    let error_job = false;

    if (type === 'ALL' || type === 'SHIFT' || type === 'BREAK' || type === 'JOB') {
      if (start.length !== 5 || this.isValidForSave(start) === false || end.length !== 5 || this.isValidForSave(end) === false) {
        error_time = true;
      }
      if (Constants.isEmpty(newPlan.functie_id) === true) {
        error_job = true;
      }
    }

    if (error_time || error_job) {
      this.setState({ error_time: error_time, error_job: error_job });
      return;
    }

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    // planListLoad[dayKey].isLoading = true;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    const incorrectTimes = this.getIncorrectTimes(user_to, dateString, newPlan, 'MENU');

    await Promise.all([
      // type === 'ALL' || type === 'SKILLS' ? APIDELETE.Request(`DELETE FROM CompRooster`, `WHERE compr_rooster_id = ${plan.rooster_id}`) : null,
      // type === 'ALL' || type === 'LABEL' ? APIDELETE.Request(`DELETE FROM BijzRooster`, `WHERE bijzr_rooster_id = ${plan.rooster_id}`) : null,
      type === 'ALL' || type === 'SHIFT' || type === 'BREAK' || type === 'JOB' ? APIEDIT.Request(`UPDATE Rooster`, `SET rooster_begin = '${newPlan.rooster_begin}', rooster_eind = '${newPlan.rooster_eind}', rooster_pauze = '${newPlan.rooster_pauze}', rooster_functie_id = ${newPlan.functie_id}, rooster_tijd_incorrect = ${incorrectTimes === true ? 1 : 0}`, `WHERE rooster_id = ${plan.rooster_id}`) : null,
    ]);
    
    // let promises = [];

    // // LABEL
    // if (type === 'ALL' || type === 'LABEL') {
    //   if (Constants.isEmpty(newPlan.bijz_id) === false) {
    //     promises.push(APIAddPartSchedule.Request(newPlan.bijz_id, plan.rooster_id));
    //   }
    // }
  
    // // SKILLS
    // if (type === 'ALL' || type === 'SKILLS') {
    //   let skills = this.state.menu_selectedSkills;
    //   if (Constants.isEmpty(skills) === false && skills.length > 0) {
    //     for (const skill of skills) {
    //       promises.push(APIAddSkillSchedule.Request(skill.comp_id, plan.rooster_id));
    //     }
    //   }
    // }

    // await Promise.all(promises);

    // if (type === 'ALL') {
    //   this.closeAllPlanMenus();
    // }

    // RELOAD
    await this.reload_one_user_one_day(user_to.info_id, day, key, type);

    // NOTI
    if (plan.rooster_publiceren === '1' && (start !== plan.rooster_begin.substr(0, 5) || end !== plan.rooster_eind.substr(0, 5))) {
      KodictNotifications.sendPushNotification(user_to.info_id, 'planner', __('misc_planner2'), Localization.t.formatString(__('planner_noti_edited'), `${Constants.stringToDate(dateString).toLocaleDateString()} ${plan.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${plan.rooster_eind.substr(0, 5)}` : ``}`, `${Constants.stringToDate(dateString).toLocaleDateString()} ${start}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${end}` : ``}`))
    }

  }

  async updateUserPlannerDirectExtraChecks(key, user_to, day, plan, type = 'SHIFT') {

    if (Constants.isEmpty(this.timeRef[key]) === true) {
      return;
    }

    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    // deleting shift
    if (Constants.isEmpty(this.timeRef[key].value) === true) {
      await this.deleteUserPlanner(user_to, day, plan);
      return;
    }

    const start = this.timeRef[key].value.substr(0, 5);
    const end = this.timeRef[key].value.substr(8, 13);

    this.canOpenPlanMenu = true;

    const newPlan = {
      rooster_begin: start.length === 5 && this.isValidForSave(start) === true ? start : '00:00',
      rooster_eind: end.length === 5 && this.isValidForSave(end) === true ? end : '00:00',
      rooster_datum: plan.rooster_datum,
      rooster_pauze: this.state.menu_selectedBreak,
      functie_id: Constants.isEmpty(this.state.menu_selectedJob) === false ? this.state.menu_selectedJob.functie_id : plan.rooster_functie_id,
      bijz_id: Constants.isEmpty(this.state.menu_selectedLabel) === false ? this.state.menu_selectedLabel.bijz_id : plan.bijz_id,
    };

    // VALIDATION
    let error_time = false;
    let error_job = false;

    if (type === 'ALL' || type === 'SHIFT' || type === 'BREAK' || type === 'JOB') {
      if (start.length !== 5 || this.isValidForSave(start) === false || end.length !== 5 || this.isValidForSave(end) === false) {
        error_time = true;
      }
      if (Constants.isEmpty(newPlan.functie_id) === true) {
        error_job = true;
      }
    }

    if (error_time || error_job) {
      this.timeRef[key].value = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
      this.setState({ error_time: error_time, error_job: error_job });
      return;
    }

    if (this.timeRef[key].value === `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`) {
      return;
    }

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    // planListLoad[dayKey].isLoading = true;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    const incorrectTimes = this.getIncorrectTimes(user_to, dateString, newPlan, 'MENU');

    await Promise.all([
      type === 'ALL' || type === 'SKILLS' ? APIDELETE.Request(`DELETE FROM CompRooster`, `WHERE compr_rooster_id = ${plan.rooster_id}`) : null,
      type === 'ALL' || type === 'LABEL' ? APIDELETE.Request(`DELETE FROM BijzRooster`, `WHERE bijzr_rooster_id = ${plan.rooster_id}`) : null,
      type === 'ALL' || type === 'SHIFT' || type === 'BREAK' || type === 'JOB' ? APIEDIT.Request(`UPDATE Rooster`, `SET rooster_begin = '${newPlan.rooster_begin}', rooster_eind = '${newPlan.rooster_eind}', rooster_pauze = '${newPlan.rooster_pauze}', rooster_functie_id = ${newPlan.functie_id}, rooster_tijd_incorrect = ${incorrectTimes === true ? 1 : 0}`, `WHERE rooster_id = ${plan.rooster_id}`) : null,
    ]);
    
    let promises = [];

    // LABEL
    if (type === 'ALL' || type === 'LABEL') {
      if (Constants.isEmpty(newPlan.bijz_id) === false) {
        promises.push(APIAddPartSchedule.Request(newPlan.bijz_id, plan.rooster_id));
      }
    }
  
    // SKILLS
    if (type === 'ALL' || type === 'SKILLS') {
      let skills = this.state.menu_selectedSkills;
      if (Constants.isEmpty(skills) === false && skills.length > 0) {
        for (const skill of skills) {
          promises.push(APIAddSkillSchedule.Request(skill.comp_id, plan.rooster_id));
        }
      }
    }

    await Promise.all(promises);

    if (type === 'ALL') {
      this.closeAllPlanMenus();
    }

    // RELOAD
    await this.reload_one_user_one_day(user_to.info_id, day, key, type);

    // NOTI
    if (plan.rooster_publiceren === '1' && (start !== plan.rooster_begin.substr(0, 5) || end !== plan.rooster_eind.substr(0, 5))) {
      KodictNotifications.sendPushNotification(user_to.info_id, 'planner', __('misc_planner2'), Localization.t.formatString(__('planner_noti_edited'), `${Constants.stringToDate(dateString).toLocaleDateString()} ${plan.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${plan.rooster_eind.substr(0, 5)}` : ``}`, `${Constants.stringToDate(dateString).toLocaleDateString()} ${start}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${end}` : ``}`))
    }

  }

  async deleteUserPlanner(user_to, day, plan) {

    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    planListLoad[dayKey].isLoading = user_to.functie_id;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    await APIDELETE.Request(`DELETE FROM Rooster`, `WHERE rooster_id = ${plan.rooster_id}`);
    await this.reload_one_user_one_day(user_to.info_id, day);

    // NOTI
    if (plan.rooster_publiceren === '1') {
      KodictNotifications.sendPushNotification(user_to.info_id, 'planner', __('misc_planner2'), Localization.t.formatString(__('planner_noti_deleted'), `${Constants.stringToDate(dateString).toLocaleDateString()} ${plan.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${plan.rooster_eind.substr(0, 5)}` : ``}`))
    }

  }

  async shiftAvailable(user_to, day, plan) {

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    // loading day and user
    let planAccountsLoad = this.state.planAccounts;
    let planListLoad = this.state.planList;
    // planListLoad[dayKey].isLoading = true;
    planAccountsLoad[user_to.info_id][dayKey].isLoading = Constants.isEmpty(user_to.info_id) ? user_to.functie_id : true;
    this.setState({ planAccounts: planAccountsLoad, planList: planListLoad });

    if (Constants.isEmpty(this.state.availableShifts[plan.rooster_id]) === true) {

      const replace = await APICanReplaceShift.Request(plan.rooster_id);

      if (replace === false) {
        alert(__('misc_availableShiftError'));
        let planAccountsLoad = this.state.planAccounts;
        planAccountsLoad[user_to.info_id][dayKey].isLoading = false;
        this.setState({ planAccounts: planAccountsLoad });
        return;
      }

      await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${plan.rooster_id}, ${Constants.isEmpty(user_to.info_id) ? 'NULL' : user_to.info_id}, 0)`);

    } else {
      await APIDELETE.Request(`DELETE FROM BeschikbareDienst`, `WHERE bdienst_rooster_id = ${plan.rooster_id}`);
    }

    const availableShifts = await APIGET.Request(`SELECT *`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON rooster_id = bdienst_rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'bdienst_rooster_id');

    this.state.planAccounts[user_to.info_id][dayKey].isLoading = false;

    this.setState({ availableShifts: availableShiftsObject });

  }

  async dayShiftAvailable(day, shift) {

    const dayKey = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;

    // SKILLS DAYSHIFT CHECK CURRENT SHIFT
    let skillsString = ``;
    const skills = this.state.skillsDayShifts[shift.dagdienst_id];
    if (Constants.isEmpty(skills) === false) {
      for (const skill of skills) {
        skillsString += `${skill.comp_id}`;
      }
    } else {
      skillsString = `null`;
    }

    const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;

    let promises = [];

    if (this.state.display === 'LIST') {

      if (Constants.isEmpty(this.state.availableDayShifts[shift.dagdienst_id]) === true) {
        await APIADD.Request(`INSERT INTO ShiftBeschikbaar`, `VALUES (NULL, ${shift.dagdienst_id}, '${dateString}', ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`);
      } else {
        await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id}`);
      }

    } else {

      for (const _shift of this.state.dayShifts[dayKey]) {

        // SKILLS DAYSHIFT CHECK
        let skillsString2 = ``;
        const skills = this.state.skillsDayShifts[_shift.dagdienst_id];
        if (Constants.isEmpty(skills) === false) {
          for (const skill of skills) {
            skillsString2 += `${skill.comp_id}`;
          }
        } else {
          skillsString2 = `null`;
        }

        const shiftKey2 = `${_shift.dagdienst_dag}${_shift.dagdienst_begin}${_shift.dagdienst_eind}${_shift.dagdienst_pauze}${_shift.functie_id}${_shift.bijz_id}${skillsString2}`;

        if (shiftKey === shiftKey2) {

          if (Constants.isEmpty(this.state.availableDayShifts[_shift.dagdienst_id]) === true) {
            promises.push(APIADD.Request(`INSERT INTO ShiftBeschikbaar`, `VALUES (NULL, ${_shift.dagdienst_id}, '${dateString}', ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
          } else {
            promises.push(APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${_shift.dagdienst_id}`));
          }

        }

      }

      await Promise.all(promises);

    }

    const availableShifts = await APIGET.Request(`SELECT *`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    const availableShiftsObject = Constants.convertToObject(availableShifts, 'shb_dagdienst_id');

    this.setState({ availableDayShifts: availableShiftsObject });

  }

  async approveLeave(holiday) {

    this.setState({ reloading: true });

    await APIEDIT.Request(`UPDATE Vakantie`, `SET vakantie_geaccepteerd = 1`, `WHERE vakantie_id = ${holiday.vakantie_id}`);

    const [
      holidays,
      holidayRequests,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND vakantie_geaccepteerd = 1 AND ((vakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (vakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN vakantie_begin AND vakantie_eind) OR ('${this.state.sundayDate}' BETWEEN vakantie_begin AND vakantie_eind))`, null, `ORDER BY info_voornaam, info_achternaam`),
      APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND vakantie_geaccepteerd = 0 AND ((vakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (vakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN vakantie_begin AND vakantie_eind) OR ('${this.state.sundayDate}' BETWEEN vakantie_begin AND vakantie_eind))`, null, `ORDER BY info_voornaam, info_achternaam`),
    ]);

    let planAccounts = this.state.planAccounts;

    for (const userKey in planAccounts) {

      for (const dayKey in planAccounts[userKey]) {
        planAccounts[userKey][dayKey].holidays = [];
        planAccounts[userKey][dayKey].holidayRequests = [];
      }

      for (const av of holidays) {
        if (av.vakantie_info_id === userKey) {

          const startDate = Constants.stringToDate(av.vakantie_begin);
          const endDate = Constants.stringToDate(av.vakantie_eind);

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            const day = currentDate.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            if (dateString === Constants.dateToString(currentDate)) {
              planAccounts[userKey][dayKey].holidays.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            }

            index++;
          }

        }
      }

      for (const av of holidayRequests) {
        if (av.vakantie_info_id === userKey) {

          const startDate = Constants.stringToDate(av.vakantie_begin);
          const endDate = Constants.stringToDate(av.vakantie_eind);

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            const day = currentDate.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            if (dateString === Constants.dateToString(currentDate)) {
              planAccounts[userKey][dayKey].holidayRequests.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            }

            index++;
          }

        }
      }

    }

    const _startDate = Constants.stringToDate(holiday.vakantie_begin);
    const _endDate = Constants.stringToDate(holiday.vakantie_eind);

    if (holiday.vakantie_begin === holiday.vakantie_eind) {
      KodictNotifications.sendPushNotification(holiday.vakantie_info_id, 'holiday', __('noti_holiday_title'), Localization.t.formatString(__('options_holiday_request_employee_accepted'), `${_startDate.toLocaleDateString()}`));
    } else {
      KodictNotifications.sendPushNotification(holiday.vakantie_info_id, 'holiday', __('noti_holiday_title'), Localization.t.formatString(__('options_holiday_request_employee_accepted2'), `${_startDate.toLocaleDateString()} - ${_endDate.toLocaleDateString()}`));
    }

    this.setState({

      planAccounts: planAccounts,
      reloading: false,

    })

  }

  async denyLeave(holiday) {

    this.setState({ reloading: true });

    await APIEDIT.Request(`UPDATE Vakantie`, `SET vakantie_geaccepteerd = 2`, `WHERE vakantie_id = ${holiday.vakantie_id}`);

    const [
      holidays,
      holidayRequests,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND vakantie_geaccepteerd = 1 AND ((vakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (vakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN vakantie_begin AND vakantie_eind) OR ('${this.state.sundayDate}' BETWEEN vakantie_begin AND vakantie_eind))`, null, `ORDER BY info_voornaam, info_achternaam`),
      APIGET.Request(`SELECT *`, `FROM Vakantie`, `LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL) AND vakantie_geaccepteerd = 0 AND ((vakantie_begin BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR (vakantie_eind BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') OR ('${this.state.mondayDate}' BETWEEN vakantie_begin AND vakantie_eind) OR ('${this.state.sundayDate}' BETWEEN vakantie_begin AND vakantie_eind))`, null, `ORDER BY info_voornaam, info_achternaam`),
    ]);

    let planAccounts = this.state.planAccounts;

    for (const userKey in planAccounts) {

      for (const dayKey in planAccounts[userKey]) {
        planAccounts[userKey][dayKey].holidays = [];
        planAccounts[userKey][dayKey].holidayRequests = [];
      }

      for (const av of holidays) {
        if (av.vakantie_info_id === userKey) {

          const startDate = Constants.stringToDate(av.vakantie_begin);
          const endDate = Constants.stringToDate(av.vakantie_eind);

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            const day = currentDate.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            if (dateString === Constants.dateToString(currentDate)) {
              planAccounts[userKey][dayKey].holidays.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            }

            index++;
          }

        }
      }

      for (const av of holidayRequests) {
        if (av.vakantie_info_id === userKey) {

          const startDate = Constants.stringToDate(av.vakantie_begin);
          const endDate = Constants.stringToDate(av.vakantie_eind);

          let index = 0;
          while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {
            const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);
            const day = currentDate.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            const dateString = dayKey === 'mon' ? this.state.mondayDate : dayKey === 'tue' ? this.state.tuesdayDate : dayKey === 'wed' ? this.state.wednesdayDate : dayKey === 'thu' ? this.state.thursdayDate : dayKey === 'fri' ? this.state.fridayDate : dayKey === 'sat' ? this.state.saturdayDate : dayKey === 'sun' ? this.state.sundayDate : this.state.mondayDate;

            if (dateString === Constants.dateToString(currentDate)) {
              planAccounts[userKey][dayKey].holidayRequests.push({ ...av, date: currentDate, dateString: Constants.dateToString(currentDate) });
            }

            index++;
          }

        }
      }

    }

    const _startDate = Constants.stringToDate(holiday.vakantie_begin);
    const _endDate = Constants.stringToDate(holiday.vakantie_eind);

    if (holiday.vakantie_begin === holiday.vakantie_eind) {
      KodictNotifications.sendPushNotification(holiday.vakantie_info_id, 'holiday', __('noti_holiday_title'), Localization.t.formatString(__('options_holiday_request_employee_denied'), `${_startDate.toLocaleDateString()}`));
    } else {
      KodictNotifications.sendPushNotification(holiday.vakantie_info_id, 'holiday', __('noti_holiday_title'), Localization.t.formatString(__('options_holiday_request_employee_denied2'), `${_startDate.toLocaleDateString()} - ${_endDate.toLocaleDateString()}`));
    }

    this.setState({

      planAccounts: planAccounts,
      reloading: false,

    })

  }

  toggleTeams(show) {

    this.setState({ reloading: true, showTeams: show, selected: null }, async () => {

      let planAccounts = this.state.planAccounts;
      let planList = this.state.planList;

      this.from = null; 
      this.fromType = ''; 

      localStorage.setItem('@planner_showTeams', show ? '1' : '0');

      const planData = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id LEFT JOIN BijzRooster ON bijzr_rooster_id = rooster_id LEFT JOIN Bijzonderheid ON bijz_id = bijzr_bijz_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afw_id = afwr_afw_id LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN InfoExtra ON info2_info_id = info_id LEFT JOIN CompRooster ON compr_rooster_id = rooster_id`, `WHERE (info2_status <> 2 OR info2_status IS NULL)${Data.data.teams.length > 1 && Data.data.chosenTeam !== null && this.state.showTeams === false ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ''} AND rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, `GROUP BY rooster_id`, `ORDER BY rooster_datum, rooster_begin, rooster_eind, bijzr_rooster_id DESC, compr_rooster_id DESC, rooster_id DESC`);

      for (const userKey in planAccounts) {

        for (const dayKey in planAccounts[userKey]) {
          planAccounts[userKey][dayKey].plans = [];
        }

        for (const plan of planData) {
          if (plan.rooster_info_id === userKey) {
            const date = Constants.stringToDate(plan.rooster_datum);
            const day = date.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts[userKey][dayKey].plans.push(plan);
          } else if (Constants.isEmpty(plan.rooster_info_id) === true) {
            const date = Constants.stringToDate(plan.rooster_datum);
            const day = date.getDay();
            const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
            planAccounts['null'][dayKey].plans.push(plan);
          }
        }

      }

      for (const dayKey in planList) {
        planList[dayKey].plans = [];
      }

      for (const plan of planData) {
        const date = Constants.stringToDate(plan.rooster_datum);
        const day = date.getDay();
        const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
        planList[dayKey].plans.push(plan);
      }

      this.setState({ planAccounts: planAccounts, planList: planList, reloading: false });

    });

  }

  collapseJob(func) {

    let jobsCollapsed = this.state.jobsCollapsed;

    if (Constants.isEmpty(jobsCollapsed[func.functie_id]) === false && jobsCollapsed[func.functie_id] === true) {
      jobsCollapsed[func.functie_id] = false;
    } else {
      jobsCollapsed[func.functie_id] = true;
    }

    this.setState({ jobsCollapsed: jobsCollapsed });

  }

  /** MISC FUNCTIONS **/

  // HANDLE KEYS

  handleKey(e, key, user_to, date, day, plan, func) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      // if (Constants.isEmpty(plan) === false) {
      //   this.updateUserPlanner(key, user_to, day, plan, 'ALL');
      // } else {
      //   this.planUserMenu(key, user_to, date, day);
      // }

      if (this.state.display === 'XCL') {

        if (Constants.isEmpty(plan) === true && (Constants.isEmpty(this.isPlanning_excel[key]) === true || this.isPlanning_excel[key] === false)) {
          this.canOpenPlanMenu = false;
          setTimeout(() => { // timeout for break to update when typing
            this.planUserDirect(key, user_to, date, day);
          }, 200);
        } 

      } else {

        if (Constants.isEmpty(plan) === true && this.isPlanning === false) {
          this.canOpenPlanMenu = false;
          setTimeout(() => { // timeout for break to update when typing
            this.planUserMenu(key, user_to, date, day, func);
          }, 200);
        } 
        
      }
      // else {
      //   setTimeout(() => {
      //     this.closeAllPlanMenus();
      //   }, 250);
      // }

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    } else {

      e.preventDefault();

    }

  }

  // ONCHANGES

  onChangeTime(e, planData, key, user, day, directly) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }

    const fromOther = Constants.isEmpty(planData) === true;

    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal); //e.target.defaultvalue
    } 
    
    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false) {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;

        const _eventValue = e.target.value

        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {

          if (this.state.display === 'XCL' && Constants.isEmpty(planData) === true && (Constants.isEmpty(this.state.menu_plan_open[key]) || this.state.menu_plan_open[key] === false)) {

            let excel_selectedBreak = this.state.excel_selectedBreak;
            let _break = '00:00';

            // automatic breaks
            if (Data.data.appOptions.brk === 'CALC') {

              const start = _eventValue.substr(0, 5);
              const end = _eventValue.substr(8, 13);

              // check if start and end are correct
              if (start.length === 5 && end.length === 5 && this.isValidForSave(start) === true && this.isValidForSave(end) === true) {

                const from = {
                  rooster_datum: Constants.dateToString(new Date()),
                  rooster_begin: start,
                  rooster_eind: end,
                };

                _break = this.getBreakRule(from.rooster_datum, from.rooster_begin, from.rooster_datum, from.rooster_eind, from);
                _break = _break.str.substr(0, 5);

                if (Constants.isEmpty(excel_selectedBreak[key]) === true) {
                  excel_selectedBreak[key] = '00:00';
                }
                excel_selectedBreak[key] = _break;

              }

            }

            this.setState({ excel_selectedBreak: excel_selectedBreak, error_time: false, });

          } else {

            let _break = this.state.menu_selectedBreak;

            // automatic breaks
            if (Data.data.appOptions.brk === 'CALC') {

              const start = _eventValue.substr(0, 5);
              const end = _eventValue.substr(8, 13);

              // check if start and end are correct
              if (start.length === 5 && end.length === 5 && this.isValidForSave(start) === true && this.isValidForSave(end) === true) {

                const from = {
                  rooster_datum: Constants.dateToString(new Date()),
                  rooster_begin: start,
                  rooster_eind: end,
                };

                _break = this.getBreakRule(from.rooster_datum, from.rooster_begin, from.rooster_datum, from.rooster_eind, from);
                _break = _break.str.substr(0, 5);

              }

            }

            this.setState({ menu_selectedBreak: _break, error_time: false, });

          }

        }, 200);

        // saving plan
        if (Constants.isEmpty(planData) === false) {

          this.canOpenPlanMenu = false;

          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(async () => {

            if (directly === true) {
              this.updateUserPlannerDirect(key, user, day, planData, 'ALL');
            } else {
              this.updateUserPlanner(key, user, day, planData, 'SHIFT');
            }

          }, 200);

        }

    } else {

      e.target.value = '';

    }

  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 10) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  // NEW WEEK

  getNextWeek(next) {

    let week = next ? this.state.week + 1 : this.state.week - 1;
    let year = this.state.year;

    if (week > Constants.weeksInYear(year)) {
      week = 1;
      year = year + 1;
    } else if (week < 1) {
      week = Constants.weeksInYear(year);
      year = year - 1;
    }

    this.from = null;
    this.fromType = '';

    this.setState({

      loading: true,

      week: week,
      year: year,

      mondayDate: Constants.dateToString(Constants.getMonday(week, year)),
      tuesdayDate: Constants.dateToString(Constants.getTuesday(week, year)),
      wednesdayDate: Constants.dateToString(Constants.getWednesday(week, year)),
      thursdayDate: Constants.dateToString(Constants.getThursday(week, year)),
      fridayDate: Constants.dateToString(Constants.getFriday(week, year)),
      saturdayDate: Constants.dateToString(Constants.getSaturday(week, year)),
      sundayDate: Constants.dateToString(Constants.getSunday(week, year)),

      selectedDate: Constants.getMonday(week, year),

      selected: null,

    }, () => {

      this.loadGeneralData();

    });

  }

  selectDateCalendar(date) {

    const week = Constants.getWeekNumber2(date);
    const year = date.getFullYear();

    this.from = null;
    this.fromType = '';

    this.setState({

      loading: true,

      week: week,
      year: year,

      mondayDate: Constants.dateToString(Constants.getMonday(week, year)),
      tuesdayDate: Constants.dateToString(Constants.getTuesday(week, year)),
      wednesdayDate: Constants.dateToString(Constants.getWednesday(week, year)),
      thursdayDate: Constants.dateToString(Constants.getThursday(week, year)),
      fridayDate: Constants.dateToString(Constants.getFriday(week, year)),
      saturdayDate: Constants.dateToString(Constants.getSaturday(week, year)),
      sundayDate: Constants.dateToString(Constants.getSunday(week, year)),

      selectedDate: date,

      selected: null,

      showPublishModal: false,

    }, () => {

      this.loadGeneralData();

    });

  }

  selectDisplay(e) {

    let _display = e.target.value;

    this.from = null;
    this.fromType = '';

    this.setState({ loading: true }, () => {

      this.setState({ display: _display, selected: null, loading: false });

    });

    localStorage.setItem('@plannerDisplay', e.target.value); 

  }

  selectSection(e) {

    let _sectionID = e.target.value;

    this.from = null;
    this.fromType = '';

    if (Constants.isEmpty(_sectionID) === true) {
      _sectionID = null;
    }

    this.setState({ loading: true }, () => {

      this.setState({ section: _sectionID, selected: null, loading: false });

    });

  }

  selectDayShiftsTemplate(e) {

    let _tempID = e.target.value;

    this.from = null;
    this.fromType = '';

    if (Constants.isEmpty(_tempID) === true) {
      _tempID = null;
    }

    this.setState({ loading: true }, () => {

      this.setState({ dayShiftsTemplate: _tempID, selected: null, loading: false });

    });

  }

  // OVERHOURS

  getWorkDaysPersonWeek(id) {

    let count = 0;

    const user = this.state.accountsObj[id];

    if (typeof user === 'undefined') {
      return 0;
    }

    if (user.info2_werkdag_maandag === '1') {
      count++;
    }
    if (user.info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (user.info2_werkdag_woensdag === '1') {
      count++;
    }
    if (user.info2_werkdag_donderdag === '1') {
      count++;
    }
    if (user.info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (user.info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (user.info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  calcSickHours(userID, _week, year) {

    let absents = [];
    let totalAbsents = 0;

    let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 60 * 60 * 1000) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 60 * 60 * 1000) : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren) * 60 * 60 * 1000);

    // SICK HOURS
    if (Constants.isTrue(Data.data.appOptions.clock)) { // absence_n

      let foundSchedulesAbsence = [];
      let contract = null;

      if (Constants.isEmpty(this.state.schedule2[userID]) === false && Constants.isEmpty(this.state.schedule2[userID][year]) === false && Constants.isEmpty(this.state.schedule2[userID][year][_week.toString()]) === false) {

        for (const sch of this.state.schedule2[userID][year][_week.toString()]) {

          const date = Constants.stringToDate(sch.afwn_datum);
          contract = Constants.getCurrentContract(this.state.contracts[sch.info_id], date);

          if (contract !== null) {

            // SET NEW WORKDAYHOURS LIKE CONTRACT
            if (Constants.isTrue(contract.contract_vaste_dagen)) {

              workDayHours = date.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                          : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

            } else {

              workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren) * 60 * 60 * 1000) : workDayHours;

            }

          }

          // ZIEKTE WACHTDAG
          if (Constants.isTrue(sch.afwn_ziekteWachtdag) === true) {
            workDayHours = 0;
          }

          // absence_n
          // if (Constants.isEmpty(sch.afwr_id) === false && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
          if (Constants.objectArrayContainsKey(foundSchedulesAbsence, 'afwn_datum', sch.afwn_datum) === false) {  
            foundSchedulesAbsence.push(sch);
            absents.push({ date: date, ms: workDayHours, id: sch.afwn_id });
          }

        }

      }

      let _absenceHours = 0;

      for (const _absN of foundSchedulesAbsence) {

        if (Constants.isTrue(_absN.afwn_ziekteWachtdag) === true) {
          continue;
        }

        const absObject = Constants.getArrayItem(absents, 'id', _absN.afwn_id);

        let sickHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 60 * 60 * 1000) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 60 * 60 * 1000) : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren) * 60 * 60 * 1000);

        if (Constants.isEmpty(absObject) === false) {
          sickHours = absObject.ms;
        }

        if (contract.contract_type === '1' && _absenceHours < (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000)) {
          // _absenceHours += workDayHours > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : workDayHours;
          _absenceHours += sickHours;
        } else if (contract.contract_type === '2' && _absenceHours < (parseFloat(contract.contract_uren) * 60 * 60 * 1000)) {
          // _absenceHours += workDayHours > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : workDayHours;
          _absenceHours += sickHours;
        }

      }
        
      // if (Constants.isEmpty(contract) === false && contract.contract_type === '1') {
      //   _absenceHours = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : (workDayHours * foundSchedulesAbsence.length) : 0;
      // } else if (Constants.isEmpty(contract) === false && contract.contract_type === '2') {
      //   _absenceHours = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : (workDayHours * foundSchedulesAbsence.length) : 0;
      // }

      totalAbsents = _absenceHours;

      // totalAbsents = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) : 0;

    }

    return { absents: absents, totalAbsents: totalAbsents };

  }

  calcAbsAmount(userID, _week, year, totalWorkedWeek) {

    let absence = [];
    let totalAbsence = 0;
    let fullWeekHours = 0;
    const chosenWeek = parseInt(_week);

    // ABSAMOUNT
    if (Constants.isEmpty(this.state.absAmount2[userID]) === false && Constants.isEmpty(this.state.absAmount2[userID][year]) === false) {

      for (const weekKey in this.state.absAmount2[userID][year]) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        for (const abs of this.state.absAmount2[userID][year][weekKey]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          const contract = Constants.getCurrentContract(this.state.contracts[abs.info_id], absStart) === null ? Constants.getCurrentContract(this.state.contracts[abs.info_id], absEnd) : Constants.getCurrentContract(this.state.contracts[abs.info_id], absStart);

          if (Constants.isEmpty(contract) === true) {
            continue;
          }

          // SET NEW WORKDAYHOURS LIKE CONTRACT
          workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

          let fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;

          if (sameDay) {
            if (chosenWeek === parseInt(weekKey) && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
              // SET NEW WORKDAYHOURS LIKE CONTRACT
              if (Constants.isEmpty(contract) === false && Constants.isTrue(contract.contract_vaste_dagen)) {
                workDayHours = absStart.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                              : absStart.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                              : absStart.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                              : absStart.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                              : absStart.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                              : absStart.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                              : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
              }
              // NEW REAL CALC ABSENCE
              if (Constants.objectArrayContainsKey(absence, 'date', absStart) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60))) < parseFloat(contract.contract_uren)) {
                absence.push({ date: absStart, ms: workDayHours * 60 * 60 * 1000 });
                totalAbsence += workDayHours * 60 * 60 * 1000;
              }
              // absence.push({ date: absStart, ms: parseFloat(abs.vs_aantal) * 60 * 60 * 1000 });
              // totalAbsence += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
              // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            }
          } else {

            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
              
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[userID]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (Constants.personWorksDay(infoExtra[userID], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                } 
                // else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (absWeek[week] < parseFloat(contract.contract_uren) && Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[userID]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (Constants.personWorksDay(infoExtra[userID], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                } 
                // else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (absWeek[week] < parseFloat(contract.contract_max_uren) && Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              }

              index++;

            }

          }

        }

      }

    }

    return { absence: absence, totalAbsence: totalAbsence };

  }

  calcOverhoursN(hours, contract, hoursWorkedWeek, totalOverhoursLocal) {

    const _startDate = Constants.stringToDate(hours.uren_begindatum);

    let _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 1000 * 60 * 60) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);
    let _workDayHoursFloat = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : parseFloat(Data.data.appOptions.opties_dag_werkuren);

    let overhours = null;
    let overhoursCurrent = null;
    let overhoursCorrection = null;
    let overhoursContract = 0;
    let negative = false;
    let negativeCurrent = false;
    let contract_hours = null;
    let contract_max_hours = null;
    let foundUserHoursWeek = false;

    let realOverhoursCurrent = null;
    let realNegativeCurrent = null;

    let workedThisDay = 0;
    let workedThisDayAbsence = 0;
    // let absenceHoursThisDay = 0;

    if (Constants.isTrue(Data.data.appOptions.opties_overuren) === false) {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,
        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,
        overhoursCorrection: overhoursCorrection,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

    if (contract !== null) {

      // SET NEW WORKDAYHOURS LIKE CONTRACT
      _workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? (parseFloat(contract.contract_werkdag_uren) * 1000 * 60 * 60) : _workDayHours;
      _workDayHoursFloat = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? parseFloat(contract.contract_werkdag_uren) : _workDayHoursFloat;

      // CALC CURRENT OVERHOURS (THIS DAY)
      const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
      const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
      const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

      if (start !== null && end !== null && diff !== null) {

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        if (Constants.isTrue(contract.contract_vaste_dagen)) {

          _workDayHours = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

          _workDayHoursFloat = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);

        }

        overhoursCurrent = (diff - _workDayHours) < 0 ? (_workDayHours - diff) : (diff - _workDayHours);
        negativeCurrent = (diff - _workDayHours) < 0;

        workedThisDay = diff;
        workedThisDayAbsence = _workDayHours;

      }

      if (isNaN(overhoursCurrent) === true || overhoursCurrent === 0) {
        overhoursCurrent = null;
      }

      // get current chosen week
      const chosenWeek = Constants.getWeekNumber2(_startDate);

      // get total worktime this week
      let totalHoursWeek = 0;

      // found hours objects this week
      let foundDBHoursArray = [];
      let foundExtraHoursArray = [];

      // normals
      for (const hours2 of hoursWorkedWeek) {
        //if (hours2.info_id === hours.info_id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === hours.uren_begindatum)) {
          const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
          const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
          const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
          //if (startHours2 < _startDate || hours2.uren_begindatum === hours.uren_begindatum) { // <
            foundUserHoursWeek = true;
            foundDBHoursArray.push(hours2);
            totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2));
          //}
        //}
      }

      // SICK HOURS
      if (Constants.isTrue(Data.data.appOptions.clock)) { // absence_n

        let foundSchedulesAbsence = [];

        if (Constants.isEmpty(this.state.schedule2[hours.info_id]) === false && Constants.isEmpty(this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

          for (const sch of this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) {

            if (sch.afwn_info_id === (hours.info_id)) {

              if (Constants.objectArrayContainsKey(foundSchedulesAbsence, 'afwn_datum', sch.afwn_datum) === false) {
                foundSchedulesAbsence.push(sch);
              }

            }

          }

        }

        let _absenceHours = 0;

        for (const _absN of foundSchedulesAbsence) {

          if (Constants.isTrue(_absN.afwn_ziekteWachtdag) === true) {
            continue;
          }

          if (contract.contract_type === '1') {
            _absenceHours += _workDayHours > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : _workDayHours;
          } else if (contract.contract_type === '2') {
            _absenceHours += _workDayHours > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : _workDayHours;
          }

        }

        // if (contract.contract_type === '1') {
        //   _absenceHours = foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_max_uren) * 60 * 60 * 1000) : (_workDayHours * foundSchedulesAbsence.length) : 0;
        // } else if (contract.contract_type === '2') {
        //   _absenceHours = foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) > (parseFloat(contract.contract_uren) * 60 * 60 * 1000) ? (parseFloat(contract.contract_uren) * 60 * 60 * 1000) : (_workDayHours * foundSchedulesAbsence.length) : 0;
        // }

        totalHoursWeek += _absenceHours;

        // totalHoursWeek += foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) : 0;

      }

      // ABSAMOUNT
      if (Constants.isEmpty(this.state.absAmount2[hours.info_id]) === false && Constants.isEmpty(this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

        let fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;

        for (const abs of this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          if (sameDay) {
            totalHoursWeek += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
          } else {

            const infoExtra = this.state.accountsObj;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
            
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (Constants.personWorksDay(infoExtra[hours.info_id], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (Constants.personWorksDay(infoExtra[hours.info_id], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              }

              index++;

            }

            if (Object.keys(absWeek).length > 0) {
              for (let key in absWeek) {
                if (chosenWeek === parseInt(key)) {
                  totalHoursWeek += (absWeek[key] * 60 * 60 * 1000);
                }
              }
            }

          }

        }

      }

      realOverhoursCurrent = overhoursCurrent;
      realNegativeCurrent = negativeCurrent;

      // calc
      if (Constants.isTrue(contract.contract_vaste_dagen) === false) {
        
        if (contract.contract_type === '1') {

          contract_max_hours = parseFloat(contract.contract_max_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) > contract_max_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_max_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        } else if (contract.contract_type === '2') {

          contract_hours = parseFloat(contract.contract_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) >= contract_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        }

      }

    }

    if (Constants.isEmpty(contract) === true || Constants.isEmpty(contract.contract_type) === true || contract.contract_type === '0') {

      return {
        overhoursTotal: '00:00',
        isNegativeTotal: false,

        overhoursCurrent: null,
        isNegativeCurrent: false,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: 0,
        // overhours this week
      };

    } else {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,

        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

  }

  getOverhoursUser(userID) {

    let count = 0;

    const currentWeekCalc = parseInt(this.YEAR) + parseInt(this.state.week);

    if (Constants.isEmpty(this.state.hoursWorked[userID]) === false) {

      const user = this.state.hoursWorked[userID];

      let yearOverhoursData = {};

      for (const yearKey in user.data) {

        if (Constants.isEmpty(yearOverhoursData[yearKey])) {
          yearOverhoursData[yearKey] = 0;
        }

        const userOverhoursYear = user.data[yearKey];

        if (Constants.isEmpty(userOverhoursYear) === false) {

          const startWeek = userOverhoursYear[userOverhoursYear.length - 1].week;
          let endWeek = Constants.getWeekNumber2(new Date());

          if (new Date().getFullYear() > parseInt(yearKey)) {
            endWeek = userOverhoursYear[0].week;
          }

           // loop from start to current week
           for (let i = endWeek; i >= startWeek; i--) {

            const week = i;
            const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
            const monday = Constants.getMonday(i, yearKey);

            // should calc this week?
            const weekCalc = parseInt(yearKey) + parseInt(week);
            if ((weekCalc >= currentWeekCalc) && (parseInt(yearKey) >= parseInt(this.YEAR))) {
              continue;
            }

            let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
            let totalHours = 0;
            let realTotalHours = 0;
            let totalWorkedHours = 0;
            let totalWorkedHoursAbsence = 0;
            let currentContract = null;
            let correction = 0;

            // CONTRACT
            let contract = Constants.getCurrentContract(this.state.contracts[user.user], monday);
            contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

            if (contract !== null) {
              currentContract = contract;
            } else {
              continue;
            }

            //
            //
            // yearData
            const yearData = Constants.getArrayItem(userOverhoursYear, 'week', i);

            // calc hours and overhours and correction
            if (Constants.isEmpty(yearData) === false) {

              // ARRAY OF DAYS
              const weekData = yearData.weekData;

              // HOURS DAY DATA
              for (const dayData of weekData) { // days in week array

                const date = Constants.stringToDate(dayData.uren_begindatum);

                // if (date > _now) {
                //   continue;
                // }

                const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

                daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
                daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

                totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
                realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
                totalWorkedHours += calcOverhours.workedThisDay;
                totalWorkedHoursAbsence += calcOverhours.workedThisDayAbsence;

              }

            }

            // CALC ABSENT AND ABSENCES
            const calcAbsAmount = this.calcAbsAmount(user.user, i, yearKey, totalWorkedHoursAbsence);
            const calcSickHours = this.calcSickHours(user.user, i, yearKey);

            let totalAbsence = calcAbsAmount.totalAbsence;
            let totalAbsent = calcSickHours.totalAbsents;

            // ABS DAY DATA
            for (const abs of calcAbsAmount.absence) {
              daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
            } 

            // SICK DAY DATA
            for (const sick of calcSickHours.absents) {
              daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
            } 

            if (currentContract !== null) {

              // show the correction incase of confusion
              correction += (totalHours - realTotalHours);

              // fix minus hours
              if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
                if (currentContract.contract_type === '2') {
                  const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                  if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - realTotalHours;
                  }
                } else if (currentContract.contract_type === '1') {
                  const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                  const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
                  if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = 0;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_max_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_max_hours;
                    correction = totalHours - realTotalHours;
                  }
                }
              }

              // fix correction negative
              const correctionNegative = correction < 0;
              correction = Math.abs(correction);

              yearOverhoursData[yearKey] += totalHours;

            }

          }

        }

      }

      let startBalance = 0;
      let balanceNow = 0;
      let balanceEnd = 0;

      const _yearNow = new Date().getFullYear();

      for (const yearKey in yearOverhoursData) {
        const overhoursAmount = yearOverhoursData[yearKey];
        if (parseInt(yearKey) <= _yearNow) {
          balanceEnd += overhoursAmount;
        }
        if (parseInt(yearKey) === _yearNow) {
          balanceNow += overhoursAmount;
        }
        if (parseInt(yearKey) < _yearNow) {
          startBalance += overhoursAmount;
        }
      }

      // CORRECTIONS
      if (Constants.isEmpty(this.state.overhours_corrections[user.user]) === false) {
        for (const yearKey in this.state.overhours_corrections[user.user]) {
          const overhoursAmount = (this.state.overhours_corrections[user.user][yearKey].total * 60 * 60 * 1000);
          if (parseInt(yearKey) <= _yearNow) {
            balanceEnd += overhoursAmount;
          }
          if (parseInt(yearKey) === _yearNow) {
            balanceNow += overhoursAmount;
          }
          if (parseInt(yearKey) < _yearNow) {
            startBalance += overhoursAmount;
          }
        }
      }

      return {
        startBalance: startBalance,
        balanceNow: balanceNow,
        balanceEnd: balanceEnd,
      };

    }

    return null;

  }

  async deletePlanner(fromPlanTemplate = false) {

    if (fromPlanTemplate !== true) {
      this.setState({ reloading: true });
    }

    let promises = [];

    for (const accKey in this.state.planAccounts) {
      for (const dayKey in this.state.planAccounts[accKey]) {
        for (const plan of this.state.planAccounts[accKey][dayKey].plans) {
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && this.teamPlanData[plan.rooster_id].team_id === Data.data.chosenTeamObj.team_id) {
              promises.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
              promises.push(APIDeleteUserPlanner.Request(plan.rooster_id));
            }
          } else {
            promises.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
            promises.push(APIDeleteUserPlanner.Request(plan.rooster_id));
          }
        }
      }
    }

    await Promise.all(promises);

    if (fromPlanTemplate !== true) {
      await this.loadGeneralData();
    }

  }

  async undoPlanner() {

    this.setState({ reloading: true });

    let deletes = [];
    let adds = [];

    for (const accKey in this.state.planAccounts) {
      for (const dayKey in this.state.planAccounts[accKey]) {
        for (const plan of this.state.planAccounts[accKey][dayKey].plans) {
          deletes.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
          deletes.push(APIDeleteUserPlanner.Request(plan.rooster_id));
        }
      }
    }

    await Promise.all(deletes);

    for (const accKey in this.planAccounts_backup) {
      for (const dayKey in this.planAccounts_backup[accKey]) {
        for (const plan of this.planAccounts_backup[accKey][dayKey].plans) {
          adds.push(APIADD.Request(`INSERT INTO Rooster`, `VALUES (${plan.rooster_id}, '${plan.rooster_datum}', '${plan.rooster_begin.substring(0,5)}', '${plan.rooster_eind.substring(0,5)}', ${plan.info_id}, ${plan.functie_id === null ? 'NULL' : plan.functie_id}, ${plan.rooster_publiceren}, '${plan.rooster_pauze.substring(0,5)}', ${plan.rooster_tijd_incorrect})`));
        }
      }
    }

    await Promise.all(adds);

    await this.loadGeneralData();

  }

  async saveTemplate(key, name) {

    // user has a team
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      name = name + ` - ${Data.data.chosenTeamObj.team_naam}`;
    }

    let promisesSpecials = [];

    for (const accKey in this.state.planAccounts) {
      for (const dayKey in this.state.planAccounts[accKey]) {
        for (const plan of this.state.planAccounts[accKey][dayKey].plans) {

          // plan.rooster_publiceren === '3' || Constants.isEmpty(plan.info_id) || 
          if (Constants.isEmpty(plan.functie_id)) {
            continue;
          }

          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (plan.teamr_team_id !== Data.data.chosenTeamObj.team_id) {
              continue;
            }
          }

          const day = dayKey === 'mon' ? 1 : dayKey === 'tue' ? 2 : dayKey === 'wed' ? 3 : dayKey === 'thu' ? 4 : dayKey === 'fri' ? 5 : dayKey === 'sat' ? 6 : 0;

          const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', ${day}, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.rooster_publiceren === '3' || Constants.isEmpty(plan.info_id) ? '-1' : plan.info_id}, NULL, ${plan.rooster_id})`);
          const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

          if (added === true) {

            // save comp to sjabloon
            if (Constants.isEmpty(this.state.skills[plan.rooster_id]) === false) {
              for (const skill of this.state.skills[plan.rooster_id]) {
                promisesSpecials.push(APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`));
              }
            }

            // save bijz to sjabloon
            if (Constants.isEmpty(plan.bijz_id) === false) {
              promisesSpecials.push(APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${plan.bijz_id}, ${addedID[0].sjabloonm_id})`));
            }

          }

        }
      }
    }

    await Promise.all(promisesSpecials);

    let promisesShifts = [];

    for (const shift of this.state.usedDayShifts) {
      const date = Constants.stringToDate(shift.gdm_datum);
      promisesShifts.push(APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', ${date.getDay()}, '00:00:00', '00:00:00', '00:00:00', NULL, NULL, ${shift.gdm_dagdienst_id}, ${shift.gdm_rooster_id})`));
    }

    await Promise.all(promisesShifts);

    return true;

  }

  async applyPlanTemplate(key) {

    this.setState({ reloading: true });

    this.closeAllNormalMenus();

    const [
      templates,
      tempSkills,
      tempBijz,
    ] = await Promise.all([
      APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, `WHERE sjabloonm_key = ${key}`, null, null),
      APIGET.Request(`SELECT *`, `FROM SjabloonComp`, null, null, null, null),
      APIGET.Request(`SELECT *`, `FROM SjabloonBijz`, null, null, null, null),
    ]);

    let tempSkillsObj = {};

    for (const tempSkill of tempSkills) {
      if (typeof tempSkillsObj[tempSkill.sjc_sjabloon_id] === 'undefined') {
        tempSkillsObj[tempSkill.sjc_sjabloon_id] = [];
      }
      tempSkillsObj[tempSkill.sjc_sjabloon_id].push(tempSkill);
    }

    const tempBijzObj = Constants.convertToObject(tempBijz, 'sjb_sjabloon_id');

    if (templates.length === 0) {
      this.setState({ reloading: false });
      return;
    }

    // check if the template fits the team
    let teamIDTemplate = null;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      for (const temp of templates) {

        // is person
        if (Constants.isEmpty(temp.sjabloonm_info_id) === false) {

          const teamInfo = await APIGET.Request(`SELECT *`, `FROM TeamRooster`, null, `WHERE teamr_rooster_id = ${temp.sjabloonm_rooster_id}`, null, null);

          if (teamInfo.length > 0) {
            teamIDTemplate = teamInfo[0].teamr_team_id;
            // console.log(teamInfo[0])
            // console.log(Data.data.chosenTeamObj.team_id)
            break;
          }

        }

      }

    }

    for (const accKey in this.state.planAccounts) {
      for (const dayKey in this.state.planAccounts[accKey]) {
        for (const plan of this.state.planAccounts[accKey][dayKey].plans) {
            for (const temp of templates) {
                if (temp.sjabloonm_rooster_id === plan.rooster_id) {
                  this.setState({ reloading: false }, () => {
                    this.setState({
                      alertTitle: 'Fout',
                      alertBody: (
                        <div>
                          Je kunt een opgeslagen planning niet in dezelfde week toepassen
                        </div>
                      ),
                      alertButtons: 1,
                      alertButtonText: ['OK'],
                      alertButtonAction: null,
                      showAlert: true,
                    });
                  });
                  return;
                }
            }
        }
      }
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null && (teamIDTemplate === null || teamIDTemplate !== Data.data.chosenTeamObj.team_id)) {
      this.setState({ reloading: false }, () => {
        this.setState({
          alertTitle: 'Probleem met sjabloon',
          alertBody: (
            <div>
              Er lijkt een probleem te zijn met deze sjabloon. Het is voor vestiging {Data.data.chosenTeamObj.team_naam} onveilig om deze sjabloon toe te passen
            </div>
          ),
          alertButtons: 1,
          alertButtonText: ['OK'],
          alertButtonAction: null,
          showAlert: true,
        });
      });
      return;
    }

    // delete schedule first
    await Promise.all([
      this.deletePlanner(true),
      APIDELETE.Request(`DELETE FROM Rooster`, `WHERE (rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') AND rooster_info_id IS NULL`),
    ]);

    let templateUsedDayShifts = [];

    // default shifts
    for (const temp of templates) {
      // is used default shift
      if (Constants.isEmpty(temp.sjabloonm_info_id) === true) {
        // add to array
        templateUsedDayShifts.push(temp);
      }
    }

    // shifts
    for (const temp of templates) {

      // is person
      if (Constants.isEmpty(temp.sjabloonm_info_id) === false) {

        const day = parseInt(temp.sjabloonm_dag);
        const user = this.state.accountsObj[temp.sjabloonm_info_id];
        const userDayInfo = this.state.planAccounts[temp.sjabloonm_info_id];

        let dateString = '';
        let dayKey = '';
        let wdKey = 'info2_werkdag_maandag';

        if (day === 0) {
          dateString = this.state.sundayDate;
          dayKey = 'sun';
          wdKey = 'info2_werkdag_zondag';
        } else if (day === 1) {
          dateString = this.state.mondayDate;
          dayKey = 'mon';
          wdKey = 'info2_werkdag_maandag';
        } else if (day === 2) {
          dateString = this.state.tuesdayDate;
          dayKey = 'tue';
          wdKey = 'info2_werkdag_dinsdag';
        } else if (day === 3) {
          dateString = this.state.wednesdayDate;
          dayKey = 'wed';
          wdKey = 'info2_werkdag_woensdag';
        } else if (day === 4) {
          dateString = this.state.thursdayDate;
          dayKey = 'thu';
          wdKey = 'info2_werkdag_donderdag';
        } else if (day === 5) {
          dateString = this.state.fridayDate;
          dayKey = 'fri';
          wdKey = 'info2_werkdag_vrijdag';
        } else if (day === 6) {
          dateString = this.state.saturdayDate;
          dayKey = 'sat';
          wdKey = 'info2_werkdag_zaterdag';
        }

        // if (Constants.getUserIsInTeam(this.teamUsers, temp.sjabloonm_info_id, Data.data.chosenTeamObj.team_id) === false) {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.objectArrayContainsKey(this.teamUsers[temp.sjabloonm_info_id], 'team_id', Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // create toPlan object
        let toPlanObject = {
          rooster_datum: dateString,
          rooster_begin: temp.sjabloonm_begin,
          rooster_eind: temp.sjabloonm_eind,
          rooster_pauze: temp.sjabloonm_pauze,
          functie_id: parseInt(temp.sjabloonm_functie_id),
          bijz_id: null,
        };

        let added = null;
        let skipRest = false;
        let skipForHoliday = false;

        if (temp.sjabloonm_info_id === '-1') {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          added = parseInt(rid[0].rooster_id);
          skipRest = true;
        }

        if (added === null && (Constants.isEmpty(user) === true || Constants.isEmpty(userDayInfo) === true)) { // deleted user?
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          added = parseInt(rid[0].rooster_id);
          skipRest = true;
        }

        if (Constants.isEmpty(user) === false && Constants.isEmpty(userDayInfo) === false) {

          for (const holiday of userDayInfo[dayKey].holidays) {
            if (temp.sjabloonm_info_id === holiday.vakantie_info_id && dateString === holiday.dateString) {
              skipForHoliday = true;
              break;
            }
          }
          if (skipForHoliday === true && added === null) {
            await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
            const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
            added = parseInt(rid[0].rooster_id);
            skipRest = true;
            //continue;
          }

          if (user[wdKey] === '0' && added === null) {
            await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
            const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
            added = parseInt(rid[0].rooster_id);
            skipRest = true;
            //continue;
          }

          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen) && added === null) {

            const contract = Constants.getCurrentContract(this.state.contracts[temp.sjabloonm_info_id], Constants.stringToDate(dateString));
            if (contract === null) {
              await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
              const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
              added = parseInt(rid[0].rooster_id);
              skipRest = true;
            }

          }

        }

        if (skipRest === true || skipForHoliday === true) {

          // successfully added to planner OPEN SHIFTS
          if (added !== null) {

            let promisesOpenShifts = [];

            if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
              promisesOpenShifts.push(APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added));
            }

            // add comps from temp to shift
            let skillsStringPlan = '';
            if (typeof tempSkillsObj[temp.sjabloonm_id] !== 'undefined') {
              for (const skill of tempSkillsObj[temp.sjabloonm_id]) {
                promisesOpenShifts.push(APIAddSkillSchedule.Request(skill.sjc_comp_id, added));
                skillsStringPlan += `${skill.sjc_comp_id}`;
              }
            } else {
              skillsStringPlan = `null`;
            }

            // add bij from temp to shift
            if (typeof tempBijzObj[temp.sjabloonm_id] !== 'undefined') {
              promisesOpenShifts.push(APIAddPartSchedule.Request(tempBijzObj[temp.sjabloonm_id].sjb_bijz_id, added));
              toPlanObject.bijz_id = tempBijzObj[temp.sjabloonm_id].sjb_bijz_id;
            }

            // check if day shifts have same details so we use one of the dayshifts template
            const planKeyID = `${day}${toPlanObject.rooster_begin}${toPlanObject.rooster_eind}${toPlanObject.rooster_pauze}${toPlanObject.functie_id}${toPlanObject.bijz_id}${skillsStringPlan}`;
            for (const shift of this.state.dayShifts[dayKey]) {
              // SKILLS DAYSHIFT CHECK
              let skillsString = ``;
              const skillsDayShift = this.state.skillsDayShifts[shift.dagdienst_id];
              if (Constants.isEmpty(skillsDayShift) === false) {
                for (const skill of skillsDayShift) {
                  skillsString += `${skill.comp_id}`;
                }
              } else {
                skillsString = `null`;
              }
              const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;
              if (planKeyID === shiftKey) {
                promisesOpenShifts.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${dateString}', ${shift.dagdienst_id}, ${added})`));
                break;
              }
            }

            // // reload cumulatives
            // let cumulatives = this.state.planUsersMaxCumulative;
            // let userCumulative = cumulatives[temp.sjabloonm_info_id];
            // if (typeof userCumulative === 'undefined') {
            //   userCumulative = [];
            //   userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
            // } else {
            //   userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
            // }
            // this.setState({ planUsersMaxCumulative: cumulatives });

            await Promise.all(promisesOpenShifts);

          }

          continue;

        }

        // add to planner, added returns rooster_id
        if (added === null) {
          added = await this.planUser(user, dateString, toPlanObject, 'TEMPLATE');
        }

        // successfully added to planner
        if (added !== null) {

          let promisesPlans = [];

          // add comps from temp to shift
          let skillsStringPlan = '';
          if (typeof tempSkillsObj[temp.sjabloonm_id] !== 'undefined') {
            for (const skill of tempSkillsObj[temp.sjabloonm_id]) {
              promisesPlans.push(APIAddSkillSchedule.Request(skill.sjc_comp_id, added));
              skillsStringPlan += `${skill.sjc_comp_id}`;
            }
          } else {
            skillsStringPlan = `null`;
          }

          // add bij from temp to shift
          if (typeof tempBijzObj[temp.sjabloonm_id] !== 'undefined') {
            promisesPlans.push(APIAddPartSchedule.Request(tempBijzObj[temp.sjabloonm_id].sjb_bijz_id, added));
            toPlanObject.bijz_id = tempBijzObj[temp.sjabloonm_id].sjb_bijz_id;
          }

          // check if day shifts have same details so we use one of the dayshifts template
          const planKeyID = `${day}${toPlanObject.rooster_begin}${toPlanObject.rooster_eind}${toPlanObject.rooster_pauze}${toPlanObject.functie_id}${toPlanObject.bijz_id}${skillsStringPlan}`;
          for (const shift of this.state.dayShifts[dayKey]) {
            // SKILLS DAYSHIFT CHECK
            let skillsString = ``;
            const skillsDayShift = this.state.skillsDayShifts[shift.dagdienst_id];
            if (Constants.isEmpty(skillsDayShift) === false) {
              for (const skill of skillsDayShift) {
                skillsString += `${skill.comp_id}`;
              }
            } else {
              skillsString = `null`;
            }
            const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;
            if (planKeyID === shiftKey) {
              promisesPlans.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${dateString}', ${shift.dagdienst_id}, ${added})`));
              break;
            }
          }

          // // reload cumulatives
          // let cumulatives = this.state.planUsersMaxCumulative;
          // let userCumulative = cumulatives[temp.sjabloonm_info_id];
          // if (typeof userCumulative === 'undefined') {
          //   userCumulative = [];
          //   userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
          // } else {
          //   userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
          // }
          // this.setState({ planUsersMaxCumulative: cumulatives });

          await Promise.all(promisesPlans)
        
        }

      }
      
    }

    await this.loadGeneralData();

  }

  async deleteTemplate(key) {

    this.closeAllNormalMenus();

    await APIDELETE.Request(`DELETE FROM SjabloonModern`, `WHERE sjabloonm_key = ${key}`);

    const planTemplates = await APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, null, `GROUP BY sjabloonm_key`, null);

    let tempKey = 0;
    for (const temp of planTemplates) {
      if (parseInt(temp.sjabloonm_key) > tempKey) {
        tempKey = parseInt(temp.sjabloonm_key);
      }
    }

    this.setState({
      planTemplates: planTemplates,
      highestPlanTempKey: tempKey,
    });

  }

  async smartPlan(options) {

    this.closeModal();

    this.setState({loading: true, smartPlanning: true});

    let _planAccounts = this.state.planAccounts;
    let _planAccounts_cumulative = this.state.planAccounts_cumulative;
    let _planAccounts_maxDays = this.state.planAccounts_maxDays;
    let _dayShifts = this.state.dayShifts;

    let tempDayShiftData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    let tempAvailabilityData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    for (const userKey in this.state.planAccounts) {

      for (const dayKey in this.state.planAccounts[userKey]) {

        const day = dayKey === 'mon' ? 1 : dayKey === 'tue' ? 2 : dayKey === 'wed' ? 3 : dayKey === 'thu' ? 4 : dayKey === 'fri' ? 5 : dayKey === 'sat' ? 6 : 0;
        const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
        const data = this.state.planAccounts[userKey][dayKey];
        const user = this.state.accountsObj[userKey];

        if ((options.monday === false && dayKey === '1') || (options.tuesday === false && dayKey === '2') || (options.wednesday === false && dayKey === '3')
          || (options.thursday === false && dayKey === '4') || (options.friday === false && dayKey === '5') || (options.saturday === false && dayKey === '6')
          || (options.sunday === false && dayKey === '0')) {
          continue;
        }

        // already planned in another team
        if (Constants.isEmpty(user) === false && this.state.allPlansUser[user.info_id][dayKey].length > 0) {
          continue;
        }

        // GET AVAIL DATA

        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

          if (options.available === true) {

            for (const av of data.av) {
              const date1 = Constants.stringToDateTime(dateString, av.beschikbaar_begin);
              const date2 = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'AV'), av.beschikbaar_eind);
              tempAvailabilityData[dayKey].push({
                ...user,
                ...av,
                duration: av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
              });
            }

          }

          if (options.notFilled === true) {

            if (data.av.length === 0 && data.unav.length === 0) {
              tempAvailabilityData[dayKey].push({
                ...user,
                duration: 0,
              });
            }

          }

        } else if(Data.data.appOptions.availability === 'STANDARD') {

          if (options.available === true) {

            for (const av of data.sav) {
              const date1 = Constants.stringToDateTime(dateString, av.sb_begin);
              const date2 = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'SAV'), av.sb_eind);
              tempAvailabilityData[dayKey].push({
                ...user,
                ...av,
                duration: av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
              });
            }

          }

          if (options.notFilled === true) {

            if (data.sav.length === 0 && data.sunav.length === 0) {
              tempAvailabilityData[dayKey].push({
                ...user,
                duration: 0,
              });
            }

          }

        } else if(Data.data.appOptions.availability === 'SCHOOL') {

          if (options.noSchool === true) {

            if (data.school.length === 0 && data.schoolHoliday.length === 0) {
              tempAvailabilityData[dayKey].push({
                ...user,
                duration: 99999999999,
              });
            }

          }

          if (options.schoolHoliday === true) {

            if (data.schoolHoliday.length > 0) {
              tempAvailabilityData[dayKey].push({
                ...user,
                duration: 99999999999,
              });
            }

          }

          if (options.school === true) {

            for (const av of data.school) {
              const date1 = Constants.stringToDateTime(dateString, av.schoolrooster_begin);
              const date2 = Constants.stringToDateTime(Plan.availIsNightShift2(av, dateString, 'SCHOOL'), av.schoolrooster_eind);
              tempAvailabilityData[dayKey].push({
                ...user,
                ...av,
                duration: date2.getTime() - date1.getTime(),
              });
            }

          }

        } else if(Data.data.appOptions.availability === 'NONE') {

          tempAvailabilityData[dayKey].push({
            ...user,
            duration: 0,
          });

        }

        // SORT

        // daily avail
        tempAvailabilityData[dayKey].sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

        // cheapest
        if (options.cheapest === true) {

          for (let key in tempAvailabilityData[dayKey]) {

            const contract = Constants.getCurrentContract(this.state.contracts[tempAvailabilityData[dayKey][key].info_id], Constants.stringToDate(dateString));

            if (contract !== null) {

              const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
              tempAvailabilityData[dayKey][key] = {...tempAvailabilityData[dayKey][key], salary: salary};

            } else {

              tempAvailabilityData[dayKey][key] = {...tempAvailabilityData[dayKey][key], salary: 0};

            }

          }

          tempAvailabilityData[dayKey].sort((a, b) => a.salary < b.salary ? -1 : 1);

        }
      
      }

    }

    for (let i = 0; i < 7; i++) {

      const day = (i + 1) === 7 ? 0 : (i + 1);
      const dateString = day === 1 ? this.state.mondayDate : day === 2 ? this.state.tuesdayDate : day === 3 ? this.state.wednesdayDate : day === 4 ? this.state.thursdayDate : day === 5 ? this.state.fridayDate : day === 6 ? this.state.saturdayDate : day === 0 ? this.state.sundayDate : this.state.mondayDate;
      const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';
      const dayName = dateString === this.state.mondayDate ? __('misc_monday') : dateString === this.state.tuesdayDate ? __('misc_tuesday') : dateString === this.state.wednesdayDate ? __('misc_wednesday') : dateString === this.state.thursdayDate ? __('misc_thursday') : dateString === this.state.fridayDate ? __('misc_friday') : dateString === this.state.saturdayDate ? __('misc_saturday') : __('misc_sunday');

      this.setState({ smartPlanning_day: dayName });

      // DAY SHIFTS

      for (const shift of _dayShifts[dayKey]) {
        if (Constants.isEmpty(shift.dagdienst_sjabloon) === true || shift.dagdienst_sjabloon !== this.state.dayShiftsTemplate) {
          continue;
        }
        const date1 = Constants.stringToDateTime(dateString, shift.dagdienst_begin);
        const date2 = Constants.stringToDateTime(Plan.dayShiftIsNightShift2(shift, dateString), shift.dagdienst_eind);

        tempDayShiftData[dayKey].push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      // sort day shifts
      tempDayShiftData[dayKey].sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersFixedShifts_smartPlan(tempAvailabilityData[dayKey], tempDayShiftData[dayKey], dateString, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData[dayKey], tempDayShiftData[dayKey], dateString, options, _planAccounts, _planAccounts_cumulative, _planAccounts_maxDays, _dayShifts);
      const reloadedStates = await this.reloadDaySmartPlan(day);

      _planAccounts = reloadedStates.planAccounts;
      _planAccounts_cumulative = reloadedStates.planAccounts_cumulative;
      _planAccounts_maxDays = reloadedStates.planAccounts_maxDays;
      _dayShifts = reloadedStates.dayShifts;

    }

    this.setState({ smartPlanning: false, smartPlanning_day: '', smartPlanning_account: '' });

    await this.loadGeneralData();

  }

  async calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts) {

    let everythingFilled = true;
    let shiftsToDelete = [];

    loop1:
    for (const person of personList) {

      if (Constants.isEmpty(person.info_id) === true) {
        continue;
      }

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

      this.setState({ smartPlanning_account: userFullName });

      if (Constants.isEmpty(planAccounts_maxDays[person.info_id]) === false && planAccounts_maxDays[person.info_id] >= options.maxDays) { // add to options max days TODO
        continue;
      }

      const _dateObj = Constants.stringToDate(date);
      const _dayKey = _dateObj.getDay() === 0 ? 'sun' : _dateObj.getDay() === 1 ? 'mon' : _dateObj.getDay() === 2 ? 'tue' : _dateObj.getDay() === 3 ? 'wed' : _dateObj.getDay() === 4 ? 'thu' : _dateObj.getDay() === 5 ? 'fri' : _dateObj.getDay() === 6 ? 'sat' : 'sun';
      for (const _plan of planAccounts[person.info_id][_dayKey].plans) {
        if (_plan.rooster_datum === date && _plan.info_id === person.info_id) {
          continue loop1;
        }
      }

      const _wdKey = _dateObj.getDay() === 0 ? 'info2_werkdag_zondag' : _dateObj.getDay() === 1 ? 'info2_werkdag_maandag' : _dateObj.getDay() === 2 ? 'info2_werkdag_dinsdag' : _dateObj.getDay() === 3 ? 'info2_werkdag_woensdag' : _dateObj.getDay() === 4 ? 'info2_werkdag_donderdag' : _dateObj.getDay() === 5 ? 'info2_werkdag_vrijdag' : _dateObj.getDay() === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
      if (person[_wdKey] === '0') {
        continue;
      }
      
      if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        const contract = Constants.getCurrentContract(this.state.contracts[person.info_id], Constants.stringToDate(date));
        if (contract === null) {
          continue;
        }
      }

      let index = 0;
      for (let shift of shiftList) {

        if (Constants.isEmpty(options.function) === false) {
          if (shift.functie_id !== options.function) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
            return;
          }
        }

        if (Constants.isEmpty(options.section) === false) {
          if (shift.functie_afdeling_id !== options.section) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
            return;
          }
        }

        if (Constants.isEmpty(shift.bijz_id) === false) {
          Constants.removeFromArray(shiftList, shift);
          await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
          return;
        }

        if(Data.data.appOptions.availability === 'AVAILABILITY') {

          // person not filled
          if(typeof person.beschikbaar_begin === 'undefined' && typeof person.beschikbaar_eind === 'undefined' && person.duration === 0) {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          }

          // person is whole day available
          if(person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00') {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.beschikbaar_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.beschikbaar_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(person.functie_id === shift.functie_id) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop1;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'STANDARD') {

          // person not filled
          if(typeof person.sb_begin === 'undefined' && typeof person.sb_eind === 'undefined' && person.duration === 0) {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          }

          // person is whole day available
          if(person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00') {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.sb_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.sb_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(person.functie_id === shift.functie_id) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop1;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'SCHOOL') {

          // person is whole day available
          if(person.duration === 99999999999) {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.schoolrooster_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.schoolrooster_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(person.functie_id === shift.functie_id) {

              if((planStart < availStart && planEnd < availStart) || (planStart > availEnd && planEnd > availEnd)) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration < person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if (Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'NONE') {

          if(person.functie_id === shift.functie_id) {

            shift.rooster_begin = shift.dagdienst_begin;
            shift.rooster_eind = shift.dagdienst_eind;
            shift.rooster_pauze = shift.dagdienst_pauze;

            if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
              Constants.removeFromArray(personList, person);
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

            if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
              continue;
            }

            const added = await this.planUser(person, date, shift, 'SHIFT');
            if(added !== null) {
              const planCalculations = this.addPlanCalculations(person.info_id, date);
              this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
            }

            Constants.removeFromArray(personList, person);
            Constants.removeFromArray(shiftList, shift);
            
            await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
            return;
          }

        }

      }
    }

    // SECONDARY FUNCTIONS

    loop10:
    for (const person of personList) {

      if (Constants.isEmpty(person.info_id) === true) {
        continue;
      }

      const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam : person.info_achternaam}`;

      this.setState({ smartPlanning_account: userFullName });

      if (Constants.isEmpty(planAccounts_maxDays[person.info_id]) === false && planAccounts_maxDays[person.info_id] >= options.maxDays) { // add to options max days TODO
        continue;
      }

      const _dateObj = Constants.stringToDate(date);
      const _dayKey = _dateObj.getDay() === 0 ? 'sun' : _dateObj.getDay() === 1 ? 'mon' : _dateObj.getDay() === 2 ? 'tue' : _dateObj.getDay() === 3 ? 'wed' : _dateObj.getDay() === 4 ? 'thu' : _dateObj.getDay() === 5 ? 'fri' : _dateObj.getDay() === 6 ? 'sat' : 'sun';
      for (const _plan of planAccounts[person.info_id][_dayKey].plans) {
        if (_plan.rooster_datum === date && _plan.info_id === person.info_id) {
          continue loop10;
        }
      }

      const _wdKey = _dateObj.getDay() === 0 ? 'info2_werkdag_zondag' : _dateObj.getDay() === 1 ? 'info2_werkdag_maandag' : _dateObj.getDay() === 2 ? 'info2_werkdag_dinsdag' : _dateObj.getDay() === 3 ? 'info2_werkdag_woensdag' : _dateObj.getDay() === 4 ? 'info2_werkdag_donderdag' : _dateObj.getDay() === 5 ? 'info2_werkdag_vrijdag' : _dateObj.getDay() === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
      if (person[_wdKey] === '0') {
        continue;
      }
      
      if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        const contract = Constants.getCurrentContract(this.state.contracts[person.info_id], Constants.stringToDate(date));
        if (contract === null) {
          continue;
        }
      }

      let index = 0;
      for (let shift of shiftList) {

        if (Constants.isEmpty(options.function) === false) {
          if (shift.functie_id !== options.function) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
            return;
          }
        }

        if (Constants.isEmpty(options.section) === false) {
          if (shift.functie_afdeling_id !== options.section) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
            return;
          }
        }

        if (Constants.isEmpty(shift.bijz_id) === false) {
          Constants.removeFromArray(shiftList, shift);
          await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
          return;
        }

        if(Data.data.appOptions.availability === 'AVAILABILITY') {

          // person not filled
          if(typeof person.beschikbaar_begin === 'undefined' && typeof person.beschikbaar_eind === 'undefined' && person.duration === 0) {

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          }

          // person is whole day available
          if(person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00') {

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.beschikbaar_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.beschikbaar_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop10;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'STANDARD') {

          // person not filled
          if(typeof person.sb_begin === 'undefined' && typeof person.sb_eind === 'undefined' && person.duration === 0) {

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          }

          // person is whole day available
          if(person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00') {

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.sb_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.sb_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop10;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'SCHOOL') {

          // person is whole day available
          if(person.duration === 99999999999) {

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(person, date, shift, 'SHIFT');
              if(added !== null) {
                const planCalculations = this.addPlanCalculations(person.info_id, date);
                this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.schoolrooster_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.schoolrooster_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

              if((planStart < availStart && planEnd < availStart) || (planStart > availEnd && planEnd > availEnd)) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration < person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if (Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'NONE') {

          if(Constants.objectArrayContainsKey(this.state.secondaryJobs[person.info_id], 'functie_id', shift.functie_id)) {

            shift.rooster_begin = shift.dagdienst_begin;
            shift.rooster_eind = shift.dagdienst_eind;
            shift.rooster_pauze = shift.dagdienst_pauze;

            if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
              Constants.removeFromArray(personList, person);
              await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
              return;
            }

            if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
              continue;
            }

            const added = await this.planUser(person, date, shift, 'SHIFT');
            if(added !== null) {
              const planCalculations = this.addPlanCalculations(person.info_id, date);
              this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
            }

            Constants.removeFromArray(personList, person);
            Constants.removeFromArray(shiftList, shift);
            
            await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
            return;
          }

        }

      }
    }

    // loop10:
    // for (const person of personList) {

    //   if (this.userDaysPlanned[parseInt(person.info_id)] >= options.maxDays) { // add to options max days TODO
    //     continue;
    //   }

    //   const _dateObj = Constants.stringToDate(date);
    //   const _dayKey = _dateObj.getDay() === 0 ? 'sun' : _dateObj.getDay() === 1 ? 'mon' : _dateObj.getDay() === 2 ? 'tue' : _dateObj.getDay() === 3 ? 'wed' : _dateObj.getDay() === 4 ? 'thu' : _dateObj.getDay() === 5 ? 'fri' : _dateObj.getDay() === 6 ? 'sat' : 'sun';
    //   for (const _plan of this.allPlannerData[_dayKey]) {
    //     if (_plan.rooster_datum === date && _plan.info_id === person.info_id) {
    //       continue loop10;
    //     }
    //   }

    //   const _wdKey = _dateObj.getDay() === 0 ? 'info2_werkdag_zondag' : _dateObj.getDay() === 1 ? 'info2_werkdag_maandag' : _dateObj.getDay() === 2 ? 'info2_werkdag_dinsdag' : _dateObj.getDay() === 3 ? 'info2_werkdag_woensdag' : _dateObj.getDay() === 4 ? 'info2_werkdag_donderdag' : _dateObj.getDay() === 5 ? 'info2_werkdag_vrijdag' : _dateObj.getDay() === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
    //   if (this.state.infoExtra[person.info_id][_wdKey] === '0') {
    //     continue;
    //   }
      
    //   if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
    //     const contract = Constants.getCurrentContract(this.state.contractsObj[person.info_id], Constants.stringToDate(date));
    //     if (contract === null) {
    //       continue;
    //     }
    //     // // has contract
    //     // if (typeof this.state.contractsObj[person.info_id] !== 'undefined') {
    //     //   const contract = this.state.contractsObj[person.info_id];
    //     //   // has valid end date
    //     //   if (Constants.isValidDateString(contract.contract_eind)) {
    //     //     const endDate = Constants.stringToDate(contract.contract_eind);
  
    //     //     if (Constants.formatDate(endDate) <= date) {
    //     //       continue;
    //     //     } 

    //     //   }
    //     // }
    //   }

    //   let index = 0;
    //   for (let shift of shiftList) {

    //     if (Constants.isEmpty(options.function) === false) {
    //       if (shift.functie_id !== options.function) {
    //         Constants.removeFromArray(shiftList, shift);
    //         await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //         return;
    //       }
    //     }

    //     if (Constants.isEmpty(options.section) === false) {
    //       if (shift.functie_afdeling_id !== options.section) {
    //         Constants.removeFromArray(shiftList, shift);
    //         await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //         return;
    //       }
    //     }

    //     if (typeof this.state.partDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
    //       Constants.removeFromArray(shiftList, shift);
    //       await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //       return;
    //     }

    //     if(Data.data.appOptions.availability === 'AVAILABILITY') {

    //       // person not filled
    //       if(typeof person.beschikbaar_begin === 'undefined' && typeof person.beschikbaar_eind === 'undefined' && person.duration === 0) {

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           shift.rooster_begin = shift.dagdienst_begin;
    //           shift.rooster_eind = shift.dagdienst_eind;
    //           shift.rooster_pauze = shift.dagdienst_pauze;

    //           if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
    //             Constants.removeFromArray(personList, person);
    //             await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //             return;
    //           }

    //           if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
    //             continue;
    //           }

    //           const added = await this.planUser(person, date, shift, 'SHIFT');
    //           if(added !== null) {
    //             const planCalculations = this.addPlanCalculations(person.info_id, date);
    //             this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
    //           }

    //           Constants.removeFromArray(personList, person);
    //           Constants.removeFromArray(shiftList, shift);
              
    //           await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //           return;
    //         }

    //       }

    //       // person is whole day available
    //       if(person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00') {

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           shift.rooster_begin = shift.dagdienst_begin;
    //           shift.rooster_eind = shift.dagdienst_eind;
    //           shift.rooster_pauze = shift.dagdienst_pauze;

    //           if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
    //             Constants.removeFromArray(personList, person);
    //             await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //             return;
    //           }

    //           if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
    //             continue;
    //           }

    //           const added = await this.planUser(person, date, shift, 'SHIFT');
    //           if(added !== null) {
    //             const planCalculations = this.addPlanCalculations(person.info_id, date);
    //             this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
    //           }

    //           Constants.removeFromArray(personList, person);
    //           Constants.removeFromArray(shiftList, shift);
              
    //           await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //           return;
    //         }

    //       } else {

    //         const availStart = new Date(`${this.state.thursdayDate}T${person.beschikbaar_begin}`);
    //         const availEnd = new Date(`${this.state.thursdayDate}T${person.beschikbaar_eind}`);

    //         const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
    //         const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
    //             shift.filled = true;
    //             shift.person = person;
    //             everythingFilled = false;

    //             shiftsToDelete.push({...shift});
    //             break loop10;
    //           }

    //           if(availStart <= planStart && availEnd >= planEnd) {

    //             if(shift.filled !== true) {

    //               shift.filled = true;
    //               shift.person = person;
    //               everythingFilled = false;

    //               shiftsToDelete.push({...shift});
    //               break;

    //             } else {
                  
    //               if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
    //                 Constants.removeFromArray(shiftsToDelete, shift);
                    
    //                 shift.person = person;
    //                 everythingFilled = false;

    //                 shiftsToDelete.push({...shift});
    //                 break;

    //               }
                  
    //             }

    //           }

    //         }

    //         index++;

    //       }

    //     }

    //     if(Data.data.appOptions.availability === 'STANDARD') {

    //       // person not filled
    //       if(typeof person.sb_begin === 'undefined' && typeof person.sb_eind === 'undefined' && person.duration === 0) {

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           shift.rooster_begin = shift.dagdienst_begin;
    //           shift.rooster_eind = shift.dagdienst_eind;
    //           shift.rooster_pauze = shift.dagdienst_pauze;

    //           if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
    //             Constants.removeFromArray(personList, person);
    //             await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //             return;
    //           }

    //           if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
    //             continue;
    //           }

    //           const added = await this.planUser(person, date, shift, 'SHIFT');
    //           if(added !== null) {
    //             const planCalculations = this.addPlanCalculations(person.info_id, date);
    //             this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
    //           }

    //           Constants.removeFromArray(personList, person);
    //           Constants.removeFromArray(shiftList, shift);
              
    //           await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //           return;
    //         }

    //       }

    //       // person is whole day available
    //       if(person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00') {

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           shift.rooster_begin = shift.dagdienst_begin;
    //           shift.rooster_eind = shift.dagdienst_eind;
    //           shift.rooster_pauze = shift.dagdienst_pauze;

    //           if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
    //             Constants.removeFromArray(personList, person);
    //             await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //             return;
    //           }

    //           if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
    //             continue;
    //           }

    //           const added = await this.planUser(person, date, shift, 'SHIFT');
    //           if(added !== null) {
    //             const planCalculations = this.addPlanCalculations(person.info_id, date);
    //             this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
    //           }

    //           Constants.removeFromArray(personList, person);
    //           Constants.removeFromArray(shiftList, shift);
              
    //           await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //           return;
    //         }

    //       } else {

    //         const availStart = new Date(`${this.state.thursdayDate}T${person.sb_begin}`);
    //         const availEnd = new Date(`${this.state.thursdayDate}T${person.sb_eind}`);

    //         const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
    //         const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
    //             shift.filled = true;
    //             shift.person = person;
    //             everythingFilled = false;

    //             shiftsToDelete.push({...shift});
    //             break loop10;
    //           }

    //           if(availStart <= planStart && availEnd >= planEnd) {

    //             if(shift.filled !== true) {

    //               shift.filled = true;
    //               shift.person = person;
    //               everythingFilled = false;

    //               shiftsToDelete.push({...shift});
    //               break;

    //             } else {
                  
    //               if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
    //                 Constants.removeFromArray(shiftsToDelete, shift);
                    
    //                 shift.person = person;
    //                 everythingFilled = false;

    //                 shiftsToDelete.push({...shift});
    //                 break;

    //               }
                  
    //             }

    //           }

    //         }

    //         index++;

    //       }

    //     }

    //     if(Data.data.appOptions.availability === 'SCHOOL') {

    //       // person is whole day available
    //       if(person.duration === 99999999999) {

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           shift.rooster_begin = shift.dagdienst_begin;
    //           shift.rooster_eind = shift.dagdienst_eind;
    //           shift.rooster_pauze = shift.dagdienst_pauze;

    //           if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
    //             Constants.removeFromArray(personList, person);
    //             await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //             return;
    //           }

    //           if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
    //             continue;
    //           }

    //           const added = await this.planUser(person, date, shift, 'SHIFT');
    //           if(added !== null) {
    //             const planCalculations = this.addPlanCalculations(person.info_id, date);
    //             this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
    //           }

    //           Constants.removeFromArray(personList, person);
    //           Constants.removeFromArray(shiftList, shift);
              
    //           await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //           return;
    //         }

    //       } else {

    //         const availStart = new Date(`${this.state.thursdayDate}T${person.schoolrooster_begin}`);
    //         const availEnd = new Date(`${this.state.thursdayDate}T${person.schoolrooster_eind}`);

    //         const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
    //         const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

    //         if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //           if((planStart < availStart && planEnd < availStart) || (planStart > availEnd && planEnd > availEnd)) {

    //             if(shift.filled !== true) {

    //               shift.filled = true;
    //               shift.person = person;
    //               everythingFilled = false;

    //               shiftsToDelete.push({...shift});
    //               break;

    //             } else {
                  
    //               if(shift.person.duration < person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
    //                 Constants.removeFromArray(shiftsToDelete, shift);
                    
    //                 shift.person = person;
    //                 everythingFilled = false;

    //                 shiftsToDelete.push({...shift});
    //                 break;

    //               }
                  
    //             }

    //           }

    //         }

    //         index++;

    //       }

    //     }

    //     if (Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'NONE') {

    //       if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

    //         shift.rooster_begin = shift.dagdienst_begin;
    //         shift.rooster_eind = shift.dagdienst_eind;
    //         shift.rooster_pauze = shift.dagdienst_pauze;

    //         if (Plan.checkInfoExtra_add(this.state.accountsObj[person.info_id], person.info_id, date, planAccounts_maxDays[person.info_id], planAccounts_cumulative[person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
    //           Constants.removeFromArray(personList, person);
    //           await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //           return;
    //         }

    //         if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[person.info_id]) === false) {
    //           continue;
    //         }

    //         const added = await this.planUser(person, date, shift, 'SHIFT');
    //         if(added !== null) {
    //           const planCalculations = this.addPlanCalculations(person.info_id, date);
    //           this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
    //         }

    //         Constants.removeFromArray(personList, person);
    //         Constants.removeFromArray(shiftList, shift);
            
    //         await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
    //         return;
    //       }

    //     }

    //   }
    // }

    if(everythingFilled === false) {

      for(const shift of shiftsToDelete) {

        //  delete current added users and restart
        shift.rooster_begin = shift.dagdienst_begin;
        shift.rooster_eind = shift.dagdienst_eind;
        shift.rooster_pauze = shift.dagdienst_pauze;

        if (Plan.checkInfoExtra_add(this.state.accountsObj[shift.person.info_id], shift.person.info_id, date, planAccounts_maxDays[shift.person.info_id], planAccounts_cumulative[shift.person.info_id], shift.dagdienst_begin.substr(0, 5), shift.dagdienst_eind.substr(0, 5), true, false) === false) {
          Constants.removeFromArray(personList, shift.person);
          await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
          return;
        }

        if (Plan.userHasAllSkills(this.state.skillsDayShifts[shift.dagdienst_id], this.state.skillsAccounts[shift.person.info_id]) === false) {
          continue;
        }

        const added = await this.planUser(shift.person, date, shift, 'SHIFT');
        if(added !== null) {
          const planCalculations = this.addPlanCalculations(shift.person.info_id, date);
          this.setState({ planAccounts_cumulative: planCalculations.planAccounts_cumulative, planAccounts_maxDays: planCalculations.planAccounts_maxDays });
        }

        Constants.removeFromArray(personList, shift.person);
        Constants.removeFromArray(shiftList, shift);

      }

      await this.calculateBestPersonForShift(personList, shiftList, date, options, planAccounts, planAccounts_cumulative, planAccounts_maxDays, dayShifts);
      return;

    }

    return everythingFilled;

  }

  async planUsersFixedShifts() {

    this.setState({ reloading: true });

    const userDefaultWorkdays = this.state.userDefaultWorkdays;

    // DEL
    let promisesDelete = [];
    for (const workday of userDefaultWorkdays) {
      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      promisesDelete.push(APIDELETE.Request(`DELETE FROM Rooster`, `WHERE rooster_datum = '${dateString}' AND rooster_info_id = ${workday.vd_info_id}`));
    }
    await Promise.all(promisesDelete);

    // DEFAULT DAYSHIFTS
    let _newDayShifts = { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] };
    for (const key in this.state.dayShifts) {
      for (const shift of this.state.dayShifts[key]) {
        _newDayShifts[key].push(shift);
      }
    }

    // PLAN

    let promisesDayShifts = [];

    loop1:
    for (const workday of userDefaultWorkdays) {

      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      const dayKey = workday.vd_dag === '0' ? 'sun' : workday.vd_dag === '1' ? 'mon' : workday.vd_dag === '2' ? 'tue' : workday.vd_dag === '3' ? 'wed' : workday.vd_dag === '4' ? 'thu' : workday.vd_dag === '5' ? 'fri' : workday.vd_dag === '6' ? 'sat' : 'mon';

      const toPlan = {
        rooster_datum: dateString,
        rooster_begin: workday.vd_begin,
        rooster_eind: workday.vd_eind,
        rooster_pauze: Constants.isEmpty(workday.vd_pauze) === false ? workday.vd_pauze : '00:00:00',
        functie_id: workday.vd_functie_id,
        info_id: workday.vd_info_id,
      };

      let _break = `'00:00:00'`;
      if (Constants.isEmpty(workday.vd_pauze) === false) {
        _break = `'${workday.vd_pauze.substr(0,5)}'`;
      }

      if (this.state.planAccounts[workday.vd_info_id][dayKey].holidays.length > 0) {
        await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
        const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
        const added2 = parseInt(rid[0].rooster_id);
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
        }
        continue loop1;
      }

      // if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[workday.vd_info_id], workday.vd_info_id, dateString, toPlan, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
      //   continue;
      // }

      if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

        const contract = Constants.getCurrentContract(this.state.contracts[workday.vd_info_id], Constants.stringToDate(dateString));
        if (contract === null) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          const added2 = parseInt(rid[0].rooster_id);
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
          }
          continue;
        }

      }

      const added = await this.planUser({ info_id: workday.vd_info_id }, dateString, toPlan, 'PLAN');

      const planKeyID = `${workday.vd_dag}${toPlan.rooster_begin}${toPlan.rooster_eind}${toPlan.rooster_pauze}${toPlan.functie_id}nullnull`;

      for (const shift of _newDayShifts[dayKey]) {
        let skillsString = ``;
        const skillsDayShift = this.state.skillsDayShifts[shift.dagdienst_id];
        if (Constants.isEmpty(skillsDayShift) === false && skillsDayShift.length > 0) {
          for (const skill of skillsDayShift) {
            skillsString += `${skill.comp_id}`;
          }
        } else {
          skillsString = `null`;
        }
        const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;
        if (planKeyID === shiftKey) {
          Constants.removeFromArray2(_newDayShifts[dayKey], 'dagdienst_id', shift.dagdienst_id);
          break;
        }
      }

    }

    await Promise.all(promisesDayShifts);

    await this.loadGeneralData();

  }

  async planUsersFixedShifts_smartPlan(personList, shiftList, date, options) {

    const userDefaultWorkdays = this.state.userDefaultWorkdays;

    // DEL
    let promisesDelete = [];
    for (const workday of userDefaultWorkdays) {

      if (Constants.isEmpty(options.function) === false) {
        if (workday.vd_functie_id !== options.function) {
          continue;
        }
      }
      if (Constants.isEmpty(options.section) === false) {
        if (workday.functie_afdeling_id !== options.section) {
          continue;
        }
      }

      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      if (dateString === date) {
        promisesDelete.push(APIDELETE.Request(`DELETE FROM Rooster`, `WHERE rooster_datum = '${date}' AND rooster_info_id = ${workday.vd_info_id}`));
        Constants.removeFromArray2(personList, 'info_id', workday.vd_info_id);
      }
    }
    await Promise.all(promisesDelete);

    // PLAN

    let promisesDayShifts = [];

    loop1:
    for (let workday of userDefaultWorkdays) {

      if (Constants.isEmpty(options.function) === false) {
        if (workday.vd_functie_id !== options.function) {
          continue;
        }
      }
      if (Constants.isEmpty(options.section) === false) {
        if (workday.functie_afdeling_id !== options.section) {
          continue;
        }
      }

      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      const dayKey = workday.vd_dag === '0' ? 'sun' : workday.vd_dag === '1' ? 'mon' : workday.vd_dag === '2' ? 'tue' : workday.vd_dag === '3' ? 'wed' : workday.vd_dag === '4' ? 'thu' : workday.vd_dag === '5' ? 'fri' : workday.vd_dag === '6' ? 'sat' : 'mon';

      if (dateString === date) {

        const toPlan = {
          rooster_datum: date,
          rooster_begin: workday.vd_begin,
          rooster_eind: workday.vd_eind,
          rooster_pauze: Constants.isEmpty(workday.vd_pauze) === false ? workday.vd_pauze : '00:00:00',
          functie_id: workday.vd_functie_id,
          info_id: workday.vd_info_id,
        };

        let _break = `'00:00:00'`;
        if (Constants.isEmpty(workday.vd_pauze) === false) {
          _break = `'${workday.vd_pauze.substr(0,5)}'`;
        } else {
          workday.vd_pauze = '00:00:00';
        }

        if (this.state.planAccounts[workday.vd_info_id][dayKey].holidays.length > 0) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          const added2 = parseInt(rid[0].rooster_id);
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
          }
          continue loop1;
        }

        // if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[workday.vd_info_id], workday.vd_info_id, date, toPlan, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
        //   continue;
        // }

        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

          const contract = Constants.getCurrentContract(this.state.contracts[workday.vd_info_id], Constants.stringToDate(dateString));
          if (contract === null) {
            await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
            const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
            const added2 = parseInt(rid[0].rooster_id);
            if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
              await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
            }
            continue;
          }

        }

        const added = await this.planUser({ info_id: workday.vd_info_id }, dateString, toPlan, 'PLAN');

        const planKeyID = `${workday.vd_dag}${toPlan.rooster_begin}${toPlan.rooster_eind}${toPlan.rooster_pauze}${toPlan.functie_id}nullnull`;

        for (const shift of shiftList) {
          let skillsString = ``;
          const skillsDayShift = this.state.skillsDayShifts[shift.dagdienst_id];
          if (Constants.isEmpty(skillsDayShift) === false && skillsDayShift.length > 0) {
            for (const skill of skillsDayShift) {
              skillsString += `${skill.comp_id}`;
            }
          } else {
            skillsString = `null`;
          }
          const shiftKey = `${shift.dagdienst_dag}${shift.dagdienst_begin}${shift.dagdienst_eind}${shift.dagdienst_pauze}${shift.functie_id}${shift.bijz_id}${skillsString}`;
          if (planKeyID === shiftKey) {
            Constants.removeFromArray2(shiftList, 'dagdienst_id', shift.dagdienst_id);
            break;
          }
        }

      }

    }

    await Promise.all(promisesDayShifts);

  }

  plansToCsv() {

    let csvText = `Subject,Start Date,Start Time,End Date,End Time`;

    for (const accKey in this.state.planAccounts) {
      for (const dayKey in this.state.planAccounts[accKey]) {
        for (const plan of this.state.planAccounts[accKey][dayKey].plans) {
          const _endDate = Plan.isNightShift2(plan);
          const _start = Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin.substr(0, 5));
          const _end = Constants.stringToDateTime(_endDate, plan.rooster_eind.substr(0, 5));
          const userFullName = `${plan.info_voornaam} ${(plan.info_tussenvoegsel !== null && plan.info_tussenvoegsel !== '') ? plan.info_tussenvoegsel + " " + plan.info_achternaam : plan.info_achternaam}`;
          const jobName = Constants.isEmpty(plan.functie_naam) ? '' : plan.functie_naam;
          const subject = `${userFullName} - ${jobName}`;
          csvText += `\r\n${subject},${Constants.stringToDate(plan.rooster_datum).toLocaleDateString('en', { year: "numeric", month: "2-digit", day: "2-digit" })},${Constants.hourAmPm(_start)},${Constants.stringToDate(_endDate).toLocaleDateString('en', { year: "numeric", month: "2-digit", day: "2-digit" })},${Constants.hourAmPm(_end)}`;
        }
      }
    }

    return csvText;

  }

  downloadBlob(content, filename, contentType) {

    // close menus
    this.closeAllNormalMenus();  

    // Create a blob
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    let pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();

  }

  getPlannedHoursWeek(user) {

    const userPlans = this.state.allPlansUser[user.info_id];
    let time = 0;

    if (Constants.isEmpty(userPlans) === false) {

      // ITERATE THROUGH DAYS
      for (const dayKey in userPlans) {

        // ITERATE THROUGH ALL THE PLANS
        for (const plan of userPlans[dayKey]) {
          // for (const plan of userPlans[dayKey].plans) {
          const _start = Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin.substr(0,5));
          const _end = Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind.substr(0,5));
          const _break = Constants.timeStringToMS(plan.rooster_pauze.substr(0,5));
          time += ((_end - _start) - _break);
        }

      }

    }

    return time;

  }

  getPlannedDaysWeek(user) {

    const userPlans = this.state.allPlansUser[user.info_id];
    let days = [];

    if (Constants.isEmpty(userPlans) === false) {

      // ITERATE THROUGH DAYS
      for (const dayKey in userPlans) {

        // ITERATE THROUGH ALL THE PLANS
        for (const plan of userPlans[dayKey]) {
          if (Constants.arrayContains(days, plan.rooster_datum) === false) {
            days.push(plan.rooster_datum);
          }
        }

      }

    }

    return days.length;

  }

  getPlannedHoursMonths(user) {

    const userPlans = this.state.monthPlanData[user.info_id];
    const userPlans2 = this.state.monthPlanData2[user.info_id];

    let time = 0;
    let time2 = 0;

    if (Constants.isEmpty(userPlans) === false) {

      // ITERATE THROUGH ALL THE PLANS
      for (const plan of userPlans) {
        const _start = Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin.substr(0,5));
        const _end = Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind.substr(0,5));
        const _break = Constants.timeStringToMS(plan.rooster_pauze.substr(0,5));
        time += ((_end - _start) - _break);
      }

    }

    if (Constants.isEmpty(userPlans2) === false) {

      // ITERATE THROUGH ALL THE PLANS
      for (const plan of userPlans2) {
        const _start = Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin.substr(0,5));
        const _end = Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind.substr(0,5));
        const _break = Constants.timeStringToMS(plan.rooster_pauze.substr(0,5));
        time2 += ((_end - _start) - _break);
      }

    }

    return {
      month: time,
      month2: time2,
    };

  }

  getPlannedDaysMonths(user) {

    const userPlans = this.state.monthPlanData[user.info_id];
    const userPlans2 = this.state.monthPlanData2[user.info_id];

    let days = [];
    let days2 = [];

    if (Constants.isEmpty(userPlans) === false) {

      // ITERATE THROUGH ALL THE PLANS
      for (const plan of userPlans) {
        if (Constants.arrayContains(days, plan.rooster_datum) === false) {
          days.push(plan.rooster_datum);
        }
      }

    }

    if (Constants.isEmpty(userPlans2) === false) {

      // ITERATE THROUGH ALL THE PLANS
      for (const plan of userPlans2) {
        if (Constants.arrayContains(days2, plan.rooster_datum) === false) {
          days2.push(plan.rooster_datum);
        }
      }

    }

    return {
      month: days.length,
      month2: days2.length,
    };

  }

  editNewOptions(set, value, bool = true) {

    APIEditOptions2.Request(set, bool ? value === true ? '1' : '0' : value, Data.data.appOptions.id);

    Data.data.appOptions = {
     ...Data.data.appOptions,
     [set]: bool ? value === true ? '1' : '0' : value,
   };

   this.setState({
     [set]: value,
   });

  }

  addEmptyRowUser(user) {

    if (Constants.isEmpty(this.state.addEmptyRowsUser[user.info_id]) === false) {

      let addEmptyRowsUser = this.state.addEmptyRowsUser;
      addEmptyRowsUser[user.info_id] += 1;

      this.setState({ addEmptyRowsUser: addEmptyRowsUser });

    }

  }

  removeEmptyRowUser(user) {

    if (Constants.isEmpty(this.state.addEmptyRowsUser[user.info_id]) === false) {

      let addEmptyRowsUser = this.state.addEmptyRowsUser;
      addEmptyRowsUser[user.info_id] -= 1;

      if (addEmptyRowsUser[user.info_id] <= 0) {
        addEmptyRowsUser[user.info_id] = 0;
      }

      this.setState({ addEmptyRowsUser: addEmptyRowsUser });

    }

  }

  addEmptyRowJob(func) {

    if (Constants.isEmpty(this.state.addEmptyRowsJob[func.functie_id]) === false) {

      let addEmptyRowsJob = this.state.addEmptyRowsJob;
      addEmptyRowsJob[func.functie_id] += 1;

      this.setState({ addEmptyRowsJob: addEmptyRowsJob });

    }

  }

  // TODO: CALCULATEBEST... FOR SMARTPLAN

  async planCalculations(allPlanData) {

    // CUMULATIVE DAYS
    // GET MAX WORKING DAYS CUMULATIVE
    let planAccounts_cumulative = {};
    let highest = 0;
    for (const usr of this.state.accounts) {
      const maxCumulative = isNaN(parseInt(usr.info2_opeenvolgend)) === true ? 0 : parseInt(usr.info2_opeenvolgend);
      if (maxCumulative > highest) {
        highest = maxCumulative;
      }
    }

    // GET ALL WORKING DAYS FROM HIGHEST CUMULATIVE DAYS SETTING 
    if (highest > 0) {
      highest += 7;
      const planAccounts_cumulativeData = await APIGET.Request(`SELECT rooster_datum, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_info_id IS NOT NULL AND rooster_datum BETWEEN (CAST('${this.state.mondayDate}' AS DATE) - INTERVAL ${highest} DAY) AND (CAST('${this.state.mondayDate}' AS DATE) + INTERVAL ${highest} DAY)`, null, `ORDER BY rooster_datum`);
      planAccounts_cumulative = Constants.convertToObjectWithArrays(planAccounts_cumulativeData, 'rooster_info_id');
    }

    // MAX DAYS
    let planAccounts_maxDays = {};
    for (const userKey in allPlanData) {
      for (const dayKey in allPlanData[userKey]) {
        if (allPlanData[userKey][dayKey].length > 0) {
          if (Constants.isEmpty(planAccounts_maxDays[userKey]) === true) {
            planAccounts_maxDays[userKey] = 1;
          } else {
            planAccounts_maxDays[userKey] += 1;
          }
        }
      }
    }

    return {
      highest: highest,
      planAccounts_cumulative: planAccounts_cumulative,
      planAccounts_maxDays: planAccounts_maxDays,
    };

  }

  addPlanCalculations(userID, dateString) {

    const date = Constants.stringToDate(dateString);
    const day = date.getDay();
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    let planAccounts_cumulative = this.state.planAccounts_cumulative;
    let planAccounts_maxDays = this.state.planAccounts_maxDays;

    if (Constants.isEmpty(planAccounts_cumulative[userID]) === true) {
      planAccounts_cumulative[userID] = [];
    }

    if (Constants.isEmpty(planAccounts_maxDays[userID]) === true) {
      planAccounts_maxDays[userID] = 0;
    }

    planAccounts_cumulative[userID].push({ rooster_datum: dateString, rooster_info_id: userID });

    if (this.state.planAccounts[userID][dayKey].plans.length === 0) {
      planAccounts_maxDays[userID] += 1;
    }

    return {
      planAccounts_cumulative: planAccounts_cumulative,
      planAccounts_maxDays: planAccounts_maxDays,
    };

  }

  removePlanCalculations(userID, dateString) {

    const date = Constants.stringToDate(dateString);
    const day = date.getDay();
    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    let planAccounts_cumulative = this.state.planAccounts_cumulative;
    let planAccounts_maxDays = this.state.planAccounts_maxDays;

    if (Constants.isEmpty(planAccounts_cumulative[userID]) === true) {
      planAccounts_cumulative[userID] = [];
    }

    if (Constants.isEmpty(planAccounts_maxDays[userID]) === true) {
      planAccounts_maxDays[userID] = 0;
    }

    Constants.removeFromArray2(planAccounts_cumulative[userID], 'rooster_datum', dateString);

    const plansOnDate = (this.state.planAccounts[userID][dayKey].plans.length - 1);

    if (plansOnDate <= 0 && this.state.planAccounts[userID][dayKey].plans.length > 0) {
      planAccounts_maxDays[userID] -= 1;
    }

    if (planAccounts_maxDays[userID] < 0) {
      planAccounts_maxDays[userID] = 0;
    }

    return {
      planAccounts_cumulative: planAccounts_cumulative,
      planAccounts_maxDays: planAccounts_maxDays,
    };

  }

  getBreakRule(startDate, startTime, endDate, endTime, schedule = null) {

    const start = Constants.stringToDateTime(startDate, startTime);
    const end = Constants.stringToDateTime(schedule !== null ? Plan.isNightShift2(schedule) : endDate, endTime);

    const totalMS = end.getTime() - start.getTime();
    const totalHours = totalMS / 1000 / 60 / 60;

    let brk = 0;
    for (const br of this.breakRules) {

        if (totalHours >= parseFloat(br.pr_uren)) {
        brk = parseInt(br.pr_pauze);
        }

    }

    if (brk > 0) {
        brk = brk * 60 * 1000;
    }

    return {
        ms: brk,
        str: Constants.msToTime(brk),
    };

  }

  getCalculationsDay(day) {

    let wage = 0;
    let totalTimePlanned = 0;

    const dayKey = day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : day === 0 ? 'sun' : 'mon';

    loop1:
    for (const accKey in this.state.planAccounts) {
      for (const plan of this.state.planAccounts[accKey][dayKey].plans) {

        if (Constants.isEmpty(plan.rooster_info_id) === true) {
          continue;
        }

        for (const _abs of this.state.planAccounts[accKey][dayKey].absence) {
          if (Constants.isEmpty(_abs.uit_id) === false) {
            continue loop1;
          }
        }

        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false && this.teamPlanData[plan.rooster_id].team_id !== Data.data.chosenTeamObj.team_id) {
            continue loop1;
          }
        }

        const totalTime = ((Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind) / 1000 / 60 / 60) - (Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin) / 1000 / 60 / 60)) - Constants.timeStringToFloat(plan.rooster_pauze);
        const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.rooster_datum));

        if (Constants.isEmpty(contract) === false && contract.contract_type !== '2') {

          let hourSalary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);

          // if (contract.contract_type === '2') {

          //   const monthSalary = isNaN(parseFloat(contract.contract_maandloon)) ? 0 : parseFloat(contract.contract_maandloon);
          //   const contractHours = isNaN(parseFloat(contract.contract_uren)) ? 0 : parseFloat(contract.contract_uren);

          //   hourSalary = ((monthSalary * 12) / (contractHours * 52));

          // }

          wage += ((totalTime * hourSalary) * this.salaryFactor);

        }

        totalTimePlanned += totalTime;

      }
    }

    const turnover = (isNaN(parseFloat(this.state.inputs_turnoverActual[dayKey])) || parseFloat(this.state.inputs_turnoverActual[dayKey]) === 0) ? isNaN(parseFloat(this.state.inputs_turnoverExpected[dayKey])) ? 0 : parseFloat(this.state.inputs_turnoverExpected[dayKey]) : parseFloat(this.state.inputs_turnoverActual[dayKey]);
    const total = (turnover - wage);
    const productivity = (turnover / totalTimePlanned);

    return {
      wage: wage,
      total: total,
      productivity: productivity,
    };

  }

  getCautionsWeek() {

    let unpublishedWeek = 0;
    let incorrectWeek = 0;
    let openShiftsWeek = 0;
    let dayShiftsWeek = 0;
    let availableShiftsWeek = 0;

    for (const accKey in this.state.planAccounts) {
      for (const dayKey in this.state.planAccounts[accKey]) {
        for (const plan of this.state.planAccounts[accKey][dayKey].plans) {

          if (plan.rooster_publiceren === '0') {
            unpublishedWeek++;
          }

          if (plan.rooster_tijd_incorrect === '1') {
            incorrectWeek++;
          }

          if (Plan.userHasAllSkills(this.state.skills[plan.rooster_id], this.state.skillsAccounts[plan.info_id]) === false) {
            incorrectWeek++;
          }

          if (Constants.isEmpty(plan.rooster_info_id) === true) {
            openShiftsWeek++;
          }

        }
      }
    }

    for (const dayKey in this.state.dayShifts) {
      for (const shift of this.state.dayShifts[dayKey]) {
        if (shift.dagdienst_sjabloon === this.state.dayShiftsTemplate) {
          dayShiftsWeek++;
        }
      }
    }

    for (const key in this.state.availableShifts) {
      availableShiftsWeek++;
    }
    for (const key in this.state.availableDayShifts) {
      availableShiftsWeek++;
    }

    return {
      unpublishedWeek: unpublishedWeek,
      incorrectWeek: incorrectWeek,
      openShiftsWeek: openShiftsWeek,
      dayShiftsWeek: dayShiftsWeek,
      availableShiftsWeek: availableShiftsWeek,
    };

  }

  getPlanTemplateRows() {

    let toReturn = [];

    for (const temp of this.state.planTemplates) {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        if (temp.sjabloonm_naam.includes(Data.data.chosenTeamObj.team_naam)) {

          toReturn.push(
            <div key={temp.sjabloonm_id} className='plannerZ_menuRowButton settings_Asset2'>
              <div className='plannerZ_menuRowButton_sub1 settings_Asset2' onClick={() => this.deleteTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
                <p><i className='fas fa-minus-circle settings_Asset2' style={{ color: Colors.color.redFilledIn }} aria-hidden={true} /></p>
              </div>
              <div className='plannerZ_menuRowButton_sub2 settings_Asset2' onClick={() => this.applyPlanTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
                <span className='settings_Asset2'>{temp.sjabloonm_naam}</span>
              </div>
              <div className='plannerZ_menuRowButton_sub3 settings_Asset2' onClick={() => this.applyPlanTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
                <p><i className='far fa-chevron-right settings_Asset2' aria-hidden={true} /></p>
              </div>
            </div>
          );

          // toReturn.push(
          //   <div key={temp.sjabloonm_id} className='planner_settingsMenu_row_delete settings_Asset2'>
          //     <div className='planner_settingsMenu_row_delete_sub1 settings_Asset2' onClick={() => this.deleteTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
          //       <i className='fas fa-minus-circle settings_Asset2' />
          //     </div>
          //     <div className='planner_settingsMenu_row_delete_sub2 settings_Asset2' onClick={() => this.applyTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
          //       <span>{temp.sjabloonm_naam}</span>
          //     </div>
          //     <div className='planner_settingsMenu_row_delete_sub3 settings_Asset2' onClick={() => this.applyTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
          //       <i className='fal fa-arrow-right settings_Asset2' />
          //     </div>
          //   </div>
          // );

        }

      } else {

        toReturn.push(
          <div key={temp.sjabloonm_id} className='plannerZ_menuRowButton settings_Asset2'>
            <div className='plannerZ_menuRowButton_sub1 settings_Asset2' onClick={() => this.deleteTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
              <p><i className='fas fa-minus-circle settings_Asset2' style={{ color: Colors.color.redFilledIn }} aria-hidden={true} /></p>
            </div>
            <div className='plannerZ_menuRowButton_sub2 settings_Asset2' onClick={() => this.applyPlanTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
              <span className='settings_Asset2'>{temp.sjabloonm_naam}</span>
            </div>
            <div className='plannerZ_menuRowButton_sub3 settings_Asset2' onClick={() => this.applyPlanTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
              <p><i className='far fa-chevron-right settings_Asset2' aria-hidden={true} /></p>
            </div>
          </div>
        );

      }

    }

    return toReturn;

  }

  openPublishModal() {

    this.setState({ 
      showPublishModal: true,
      modalData: null,
      modalMode: 0,
    });

  }

  openSmartPlanModal() {

    this.setState({ showSmartPlanModal: true });

  }

  openSortModal(menu) {

    this.setState({ 
      sidemenuActive: false,
      showSortModal: true, 
      modalData: menu,
    });

  }

  openAddTempModal() {

    this.setState({
      showAddTempModal: true, 
      modalMode: 0,
      modalData: [],
    });
	
  }

  openDayNoteModal(day) {

    let date = this.state.mondayDate;
    let data = [];
    let mode = 0;

    if (day === 'mon') {
      date = this.state.mondayDate;
    } else if (day === 'tue') {
      date = this.state.tuesdayDate;
    } else if (day === 'wed') {
      date = this.state.wednesdayDate;
    } else if (day === 'thu') {
      date = this.state.thursdayDate;
    } else if (day === 'fri') {
      date = this.state.fridayDate;
    } else if (day === 'sat') {
      date = this.state.saturdayDate;
    } else if (day === 'sun') {
      date = this.state.sundayDate;
    }

    if (typeof this.state.dayNotes[date] !== 'undefined') {
      mode = 1;
      // data = this.state.dayNotes[date];
      const dayNotes = this.state.dayNotes[Constants.dateToString(date)];
      if (Constants.isEmpty(dayNotes) === false) {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          data = Constants.getArrayItem(dayNotes, 'dn_team_id', Data.data.chosenTeamObj.team_id);
        } else {
          data = dayNotes[0];
        }
        if (Constants.isEmpty(data)) {
          mode = 0;
          data = [];
        }
      }
    }

    this.setState({ 
      showDayNoteModal: true,
      modalData: data,
      modalMode: mode,
      modalDate: date,
    });

  }

  closeModal() {

    this.setState({
      showModal: false,
      showPublishModal: false,
      showSmartPlanModal: false,
      showSortModal: false,
      showAddTempModal: false,
      showDayNoteModal: false,

      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,
    });

  }

  closeSortModal() {

    this.closeModal();

    this.reloadWholePlanner();

  }

  approveLeaveAlert(holiday) {

    if(this.state.loading === true || this.state.reloading === true) { // || this.isPlanning
      return;
    }

    this.closeAllPlanMenus();

    this.setState({
      alertTitle: __('alert_approve_title'),
      alertBody: (
        <div>
          {__('alert_approve')}
        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_approve')],
      alertButtonAction: this.approveLeave.bind(this, holiday),
      showAlert: true,
    });

  }

  denyLeaveAlert(holiday) {

    if(this.state.loading === true || this.state.reloading === true) { // || this.isPlanning
      return;
    }

    this.closeAllPlanMenus();

    this.setState({
      alertTitle: __('alert_deny_title'),
      alertBody: (
        <div>
          {__('alert_deny')}
        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_deny')],
      alertButtonAction: this.denyLeave.bind(this, holiday),
      showAlert: true,
    });

  }

  deleteUserPlannerAlert(user_to, day, plan) {

    if(this.state.loading === true || this.state.reloading === true) { // || this.isPlanning
      return;
    }

    this.closeAllPlanMenus();

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: (
        <div>
          {__('alert_delete')}
        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_delete')],
      alertButtonAction: this.deleteUserPlanner.bind(this, user_to, day, plan),
      showAlert: true,
    });

  }

  deletePlannerAlert() {

    if(this.state.loading === true || this.state.reloading === true) { // || this.isPlanning
      return;
    }

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: (
        <div>
          {Localization.t.formatString(__('planner_web_alert_deletePlanner'), (<b>{this.state.week}, {this.state.year}</b>))}
        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_delete')],
      alertButtonAction: this.deletePlanner.bind(this),
      showAlert: true,
    });

  }

  undoPlannerAlert() {

    if(this.state.loading === true || this.state.reloading === true) { // || this.isPlanning
      return;
    }

    this.setState({
      alertTitle: __('button_undo'),
      alertBody: (
        <div>
          {Localization.t.formatString(__('planner_web_alert_undoPlanner'), (<b>{this.state.week}, {this.state.year}</b>))}
        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_undo')],
      alertButtonAction: this.undoPlanner.bind(this),
      showAlert: true,
    });

  }

  smartPlanAlert(options) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    let peopleHaveFunction = false;
    let defaultShiftsOpen = false;

    for (const userKey in this.state.planAccounts) {

      for (const dayKey in this.state.planAccounts[userKey]) {

        const data = this.state.planAccounts[userKey][dayKey];

        if ((options.monday === false && dayKey === '1') || (options.tuesday === false && dayKey === '2') || (options.wednesday === false && dayKey === '3')
          || (options.thursday === false && dayKey === '4') || (options.friday === false && dayKey === '5') || (options.saturday === false && dayKey === '6')
          || (options.sunday === false && dayKey === '0')) {
          continue;
        }

        if (((data.av.length > 0 || data.sav.length > 0) && options.available === true) || (options.notFilled === true || options.noSchool === true) || (data.schoolHoliday.length > 0 && options.schoolHoliday === true) || (data.school.length > 0 && options.school === true)) {
          const user = this.state.accountsObj[userKey];
          if (Constants.isEmpty(user) === false && Constants.isEmpty(user.functie_id) === false) {
            peopleHaveFunction = true;
            break;
          }
        }

      }

    }

    if(peopleHaveFunction === false) {
      this.setState({
        alertTitle: 'Oeps!',
        alertBody: (
          <div>

            Er zijn geen medewerkers beschikbaar die een primaire functie toegewezen hebben gekregen<br /><br />

          </div>
        ),
        alertButtons: 1,
        alertButtonText: ['OK'],
        alertButtonAction: null,
        showAlert: true,
      });
      return;
    }

    for (const dayKey in this.state.dayShifts) {
      for (const shift of this.state.dayShifts[dayKey]) {
        if (Constants.isEmpty(shift.dagdienst_sjabloon) === false && shift.dagdienst_sjabloon === this.state.dayShiftsTemplate) {
          defaultShiftsOpen = true;
          break;
        }
      }
    }

    if(defaultShiftsOpen === false) {
      this.setState({
        alertTitle: 'Oeps!',
        alertBody: (
          <div>

            Er zijn geen standaard diensten om medewerkers op in te plannen<br /><br />

          </div>
        ),
        alertButtons: 1,
        alertButtonText: ['OK'],
        alertButtonAction: null,
        showAlert: true,
      });
      return;
    }

    this.setState({
      alertTitle: 'Smart Plan',
      alertBody: (
        <div>

          Wil je de Smart Plan optie nu gebruiken?<br /><br />
          De Smart Plan optie roostert automatisch jouw medewerkers in op basis van jouw standaard diensten, de beschikbaarheid van jouw medewerkers en de functies van deze medewerkers

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Smart Plan'],
      alertButtonAction: this.smartPlan.bind(this, options),
      showAlert: true,
    });

  }

  fixedShiftsAlert() {

    this.setState({
      alertTitle: 'Vaste werktijden',
      alertBody: (
        <div>

          <b>Medewerkers met vaste werktijden inplannen</b><br/><br/>
    
          De medewerkers zullen worden ingepland aan de hand van hun vaste werktijden. Hun huidige diensten van deze week worden dan vervangen. Wil je doorgaan?
    
        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Inplannen'],
      alertButtonAction: this.planUsersFixedShifts.bind(this),
      showAlert: true,
    });

  }

  applyPlanTempAlert(key, name) {

    this.setState({
      alertTitle: __('planner_web_screen_applySavedSchedule'),
      alertBody: (
        <div>
          {Localization.t.formatString(__('planner_web_screen_applySavedSchedule_text'), <b>{name}</b>, <b>{this.state.week}, {this.state.year}</b>)}
        </div>
      ),
      alertButtons: 2,
      alertButtonText: [__('button_cancel'), __('button_apply')],
      alertButtonAction: this.applyPlanTemplate.bind(this, key),
      showAlert: true,
    });

  }

  deleteTempAlert(key, name) {

    this.setState({
      alertTitle: 'Planning sjabloon verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je het sjabloon <b>{name}</b> wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deleteTemplate.bind(this, key),
      showAlert: true,
    });

  }

  closeAlert() {

    this.setState({
      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,
    });

  }

  openHolidayMenu(key) {

    let menuObj = this.state.menu_holiday_open;

    // first close them all
    for (const key2 in menuObj) {
      menuObj[key2] = false;
    }

    // open new one
    menuObj[key] = true;

    this.setState({

      menu_holiday_open: menuObj, 
      menu_plan_open: {}, 
      menu_dayShift_open: {}, 

      menu_selectedEmployee: null,
      menu_selectedTeam: null,
      menu_selectedTime: '',
      menu_selectedJob: null,
      menu_selectedBreak: '00:00',
      menu_selectedLabel: null,
      menu_selectedSkills: [],

      menu_plan_employees_open: false,
      menu_plan_teams_open: false,
      menu_plan_jobs_open: false,
      menu_plan_breaks_open: false,
      menu_plan_labels_open: false,
      menu_plan_skills_open: false,
      menu_plan_absence_open: false,

    });

  }

  openPlanMenu(key, func, user, plan) {

    let menuObj = this.state.menu_plan_open;

    if (menuObj[key] === true) {
      this.timeRef[key].focus();
      return;
    }

    // first close them all
    for (const key2 in menuObj) {
      menuObj[key2] = false;
    }

    // open new one
    menuObj[key] = true;

    // set data
    let selectedTeam = Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj : null;
    let selectedJob = Constants.isEmpty(user) ? func : user;
    let selectedBreak = '00:00';
    let selectedLabel = null;
    let selectedSkills = [];

    // set plan data if it's plan object
    if (Constants.isEmpty(plan) === false) {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null && Constants.isEmpty(this.teamPlanData[plan.rooster_id]) === false) {
        selectedTeam = this.teamPlanData[plan.rooster_id];
      }

      if (Constants.isEmpty(plan.functie_id) === false) {
        selectedJob = plan;
      }

      if (Constants.isEmpty(plan.rooster_pauze) === false) {
        selectedBreak = plan.rooster_pauze.substr(0, 5);
      }

      if (Constants.isEmpty(plan.bijz_id) === false) {
        selectedLabel = plan;
      }

      if (Constants.isEmpty(this.state.skills[plan.rooster_id]) === false) {

        for (const skill of this.state.skills[plan.rooster_id]) {
          selectedSkills.push(skill);
        }

      }

    }

    this.setState({ 

      menu_plan_open: menuObj, 
      menu_holiday_open: {}, 
      menu_dayShift_open: {}, 

      menu_selectedEmployee: user,
      menu_selectedTeam: selectedTeam,
      menu_selectedJob: selectedJob,
      menu_selectedBreak: selectedBreak,
      menu_selectedLabel: selectedLabel,
      menu_selectedSkills: selectedSkills,

      menu_plan_employees_open: false,
      menu_plan_teams_open: false,
      menu_plan_jobs_open: false,
      menu_plan_breaks_open: false,
      menu_plan_labels_open: false,
      menu_plan_skills_open: false,
      menu_plan_absence_open: false,

    }, () => {

      // focus on input key
      if (Constants.isEmpty(this.timeRef[key]) === false) {
        this.timeRef[key].focus();
      }

    });

  }

  openEmployeeMenu(user, day) {

    const newKey = Constants.isEmpty(day) === false ? `${user.info_id}${day}` : user.info_id;

    let menuObj = this.state.menu_employee_open;

    if (menuObj[newKey] === true && this.state.display !== 'LIST') {
      menuObj[newKey] = false;
      this.setState({ 
        menu_employee_open: menuObj, 
      });
      return;
    } else if (menuObj[newKey] === true && this.state.display === 'LIST') {
      return;
    }

    // first close them all
    for (const key2 in menuObj) {
      menuObj[key2] = false;
    }

    // open new one
    menuObj[newKey] = true;

    this.setState({ 
      menu_employee_open: menuObj, 
    });

  }

  openDayShiftMenu(key, shift) {

    let menuObj = this.state.menu_dayShift_open;

    if (menuObj[key] === true) {
      return;
    }

    // first close them all
    for (const key2 in menuObj) {
      menuObj[key2] = false;
    }

    // open new one
    menuObj[key] = true;

    // set data
    let selectedTeam = Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj : null;
    let selectedJob = null;
    let selectedBreak = '00:00';
    let selectedLabel = null;
    let selectedSkills = [];

    // set plan data if it's plan object
    if (Constants.isEmpty(shift) === false) {

      if (Constants.isEmpty(shift.functie_id) === false) {
        selectedJob = shift;
      }

      if (Constants.isEmpty(shift.dagdienst_pauze) === false) {
        selectedBreak = shift.dagdienst_pauze.substr(0, 5);
      }

      if (Constants.isEmpty(shift.bijz_id) === false) {
        selectedLabel = shift;
      }

      if (Constants.isEmpty(this.state.skillsDayShifts[shift.dagdienst_id]) === false) {

        for (const skill of this.state.skillsDayShifts[shift.dagdienst_id]) {
          selectedSkills.push(skill);
        }

      }

    }

    this.setState({ 

      menu_dayShift_open: menuObj, 
      menu_plan_open: {}, 
      menu_holiday_open: {}, 

      menu_selectedEmployee: null,
      menu_selectedTeam: selectedTeam,
      menu_selectedJob: selectedJob,
      menu_selectedBreak: selectedBreak,
      menu_selectedLabel: selectedLabel,
      menu_selectedSkills: selectedSkills,

      menu_plan_employees_open: false,
      menu_plan_teams_open: false,
      menu_plan_jobs_open: false,
      menu_plan_breaks_open: false,
      menu_plan_labels_open: false,
      menu_plan_skills_open: false,
      menu_plan_absence_open: false,

    }, () => {

      // focus on input key
      if (Constants.isEmpty(this.timeRef[key]) === false) {
        this.timeRef[key].focus();
      }

    });

  }

  shouldClosePlanMenu(key, type = 'PLAN') {

    if (Constants.isEmpty(this.state.menu_plan_open[key]) === true || this.state.menu_plan_open[key] === false) {
      if (type === 'PLAN') {
        this.closeAllPlanMenus();
      } else if (type === 'AV') {
        this.setState({
          menu_plan_open: {},
          menu_dayShift_open: {},
          menu_holiday_open: {},
        });
      }
    }

  }

  closeAllPlanMenus() {

    this.setState({ 

      menu_plan_open: {},
      menu_holiday_open: {},
      menu_dayShift_open: {},

      // selections
      menu_selectedEmployee: null,
      menu_selectedTeam: null,
      menu_selectedTime: '',
      menu_selectedJob: null,
      menu_selectedBreak: '00:00',
      menu_selectedLabel: null,
      menu_selectedSkills: [],

      // sub menus
      menu_plan_employees_open: false,
      menu_plan_teams_open: false,
      menu_plan_jobs_open: false,
      menu_plan_breaks_open: false,
      menu_plan_labels_open: false,
      menu_plan_skills_open: false,
      menu_plan_absence_open: false,

      // errors
      error_employee: false,
      error_job: false,
      error_time: false,

    });

  }

  closeAllMenus() {

    this.setState({
      menu_export_open: false,
      menu_template_open: false,
    });

    this.closeAllPlanMenus();

  }

  closeAllNormalMenus() {

    this.setState({
      menu_export_open: false,
      menu_template_open: false,
    });

  }

  closeAllMenusOutside(e) {

    if (e.target !== this.initialTarget) {
      return;
    }

    if (Constants.isEmpty(e) || Constants.isEmpty(e.target) || Constants.isEmpty(e.target.className) || Constants.isEmpty(e.target.className.includes)) {
      this.closeAllMenus();
      if (this.state.display === 'LIST') {
        this.setState({ menu_employee_open: {} });
      }
      return;
    }

    if(e.target.className.includes('settings_Asset2') === false
      && e.target.className.includes('alertModal') === false) {
      this.setState({
        menu_export_open: false,
        menu_template_open: false,
      });
    }

    if(e.target.className.includes('planMenuAsset') === false) {
      this.closeAllPlanMenus();
    }

    if(e.target.className.includes('employeeMenuAsset') === false && this.state.display === 'LIST') {
      this.setState({ menu_employee_open: {} });
    }

  }

  startGrabbing() {
    if(this.state.isDragging === false) {
      this.setState({ isDragging: true });
    }
  }

  stopGrabbing() {
    if(this.state.isDragging === true) {
      this.setState({ isDragging: false });
    }
  }

  render() {

    const cautionsWeek = this.getCautionsWeek();

    const _unpublishedWeek = cautionsWeek.unpublishedWeek;
    const _incorrectWeek = cautionsWeek.incorrectWeek;
    const _openShiftsWeek = cautionsWeek.openShiftsWeek;
    const _dayShiftsWeek = cautionsWeek.dayShiftsWeek;
    const _availableShiftsWeek = cautionsWeek.availableShiftsWeek;

    return (

      // onDragStart={() => this.startGrabbing()} onDragEnd={() => this.stopGrabbing()} style={{ cursor: this.state.isDragging ? 'grabbing' : null }}
      <div className='plannerZ_container' onMouseDown={(e) => { this.initialTarget = e.target; }} onMouseUp={(e) => this.closeAllMenusOutside(e)}>

        <Navbar ref={this.navBar} history={this.props.history} active={'planner'} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'planner'} parent={this} />

        <div className={`planner_sidemenuBackground ${this.state.sidemenuActive === true ? 'active' : ''}`} id="sidemenuBackground" onClick={(e) => e.target === document.getElementById("sidemenuBackground") ? this.setState({sidemenuActive: false}) : null}>

          <div className={`${this.state.sidemenuActive === true ? 'planner_sidemenu active' : 'planner_sidemenu'}`}>

            <div className='planner_sidemenu_container'>

              <div className='planner_sidemenu_menuHeader'>
                <span style={{ fontSize: 24, fontWeight: 'bold' }}>{__('options_web_screen_settingsPlanner')}</span>
              </div>

              {Data.data.accountData.account_rights === '2' ?
                <div>

                  <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>{__('options_web_screen_general')}</span>

                  <div className='planner_sidemenu_rowContainer'>

                    <span>{__('options_web_screen_allowSmartPlan')}</span>

                    <label className={"switch"} style={{marginLeft: 25}}>
                        <input 
                            type="checkbox" 
                            checked={this.state.opties_smartplan} 
                            onClick={() => this.editNewOptions('opties_smartplan', !this.state.opties_smartplan)}
                            readOnly
                        />
                        <span className="slider round"></span>
                    </label>

                    <Tooltip
                      options
                      html={(
                        <div className='appoptions_tooltipContainer'>
                          <span>{__('options_web_tooltip_smartPlan')}</span>
                        </div>
                      )}
                      style={{position: 'absolute', right: -2}}
                      position={'top'}
                      followCursor
                      theme='kodict'
                    >
                      <i style={{fontSize: 10}} className='fal fa-question-circle' />
                    </Tooltip>

                  </div>

                </div>
              : null}

              <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>{__('options_web_screen_manage')}</span>

              {/* <div className='planner_sidemenu_rowContainer_button' onClick={() => { this.showAvailableShiftsModal(); this.setState({ sidemenuActive: false }); }}>

                <span>{__('options_web_screen_availableShifts')}</span>

                <i className='fas fa-arrow-right' />

              </div> */}

              {/* <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>Toevoegen</span> */}

              {Data.data.accountData.account_rights === '2' ?
                <div className='planner_sidemenu_rowContainer_button' onClick={() => { this.navBar.current.openRegistrationMenu(); this.setState({ sidemenuActive: false }); }}>

                  <span>{__('options_web_screen_addEmployee')}</span>

                  <i className='fas fa-arrow-right' />

                </div>
              : null}

              {Data.data.accountData.account_rights === '2' ?
                <div className='planner_sidemenu_rowContainer_button' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'functions' })}>

                  <span>{__('options_web_screen_addJob')}</span>

                  <i className='fas fa-arrow-right' />

                </div>
              : null}

              <div className='planner_sidemenu_rowContainer_button' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'shifts' })}>

                <span>{__('options_web_screen_manageDefaultShifts')}</span>

                <i className='fas fa-arrow-right' />

              </div>

              {Data.data.accountData.account_rights === '2' ?
                <div className='planner_sidemenu_rowContainer_button' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'sections' })}>

                  <span>{__('options_web_screen_addSection')}</span>

                  <i className='fas fa-arrow-right' />

                </div>
              : null}

              <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>{__('options_web_screen_display')}</span>

              <div className='planner_sidemenu_rowContainer'>

                <span>{__('options_web_screen_hideAvailability')}</span>

                <label className={"switch"} style={{marginLeft: 25}}>
                    <input 
                        type="checkbox" 
                        checked={this.state.opties_infoplanner_verborgen} 
                        onClick={() => this.editNewOptions('opties_infoplanner_verborgen', !this.state.opties_infoplanner_verborgen)}
                        readOnly
                    />
                    <span className="slider round"></span>
                </label>

                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('options_web_tooltip_showAvailability')}</span>
                    </div>
                  )}
                  style={{position: 'absolute', right: -2}}
                  position={'top'}
                  followCursor
                  theme='kodict'
                >
                  <i style={{fontSize: 10}} className='fal fa-question-circle' />
                </Tooltip>

              </div>

              <div className='planner_sidemenu_rowContainer'>

                <span>{__('options_web_screen_expectedLoad')}</span>

                <label className={"switch"} style={{marginLeft: 25}}>
                    <input 
                        type="checkbox" 
                        checked={this.state.opties_planner_drukte} 
                        onClick={() => this.editNewOptions('opties_planner_drukte', !this.state.opties_planner_drukte)}
                        readOnly
                    />
                    <span className="slider round"></span>
                </label>

                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('options_web_tooltip_expectedLoad')}</span>
                    </div>
                  )}
                  style={{position: 'absolute', right: -2}}
                  position={'top'}
                  followCursor
                  theme='kodict'
                >
                  <i style={{fontSize: 10}} className='fal fa-question-circle' />
                </Tooltip>

              </div>

              <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>{__('options_web_screen_sort')}</span>

              {this.state.display === 'TABLE' || this.state.display === 'XCL' ?
                <div className='planner_sidemenu_rowContainer_button' onClick={() => this.openSortModal('employees')}>

                  <span>{__('options_web_screen_sortEmployees')}</span>

                  <i className='fas fa-arrow-right' />

                </div>
              : null}

              <div className='planner_sidemenu_rowContainer_button' onClick={() => this.openSortModal('jobs')}>

                <span>{__('options_web_screen_sortJobs')}</span>

                <i className='fas fa-arrow-right' />

              </div>

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>{__('options_web_screen_finances')}</span>
              : null}

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className='planner_sidemenu_rowContainer'>

                  <span>{__('options_web_screen_salary')}</span>

                  <label className={"switch"} style={{marginLeft: 25}}>
                      <input 
                          type="checkbox" 
                          checked={this.state.opties_planner_loonkosten} 
                          onClick={() => this.editNewOptions('opties_planner_loonkosten', !this.state.opties_planner_loonkosten)}
                          readOnly
                      />
                      <span className="slider round"></span>
                  </label>

                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('options_web_tooltip_salary')}</span>
                      </div>
                    )}
                    style={{position: 'absolute', right: -2}}
                    position={'top'}
                    followCursor
                    theme='kodict'
                  >
                    <i style={{fontSize: 10}} className='fal fa-question-circle' />
                  </Tooltip>

                </div>
              : null}

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className='planner_sidemenu_rowContainer'>

                  <span>{__('options_web_screen_turnoverLastYear')}</span>

                  <label className={"switch"} style={{marginLeft: 25}}>
                      <input 
                          type="checkbox" 
                          checked={this.state.opties_planner_vorigOmzet} 
                          onClick={() => this.editNewOptions('opties_planner_vorigOmzet', !this.state.opties_planner_vorigOmzet)}
                          readOnly
                      />
                      <span className="slider round"></span>
                  </label>

                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('options_web_tooltip_turnoverLast')}</span>
                      </div>
                    )}
                    style={{position: 'absolute', right: -2}}
                    position={'top'}
                    followCursor
                    theme='kodict'
                  >
                    <i style={{fontSize: 10}} className='fal fa-question-circle' />
                  </Tooltip>

                </div>
              : null}

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className='planner_sidemenu_rowContainer'>

                  <span>{__('options_web_screen_expectedTurnover')}</span>

                  <label className={"switch"} style={{marginLeft: 25}}>
                      <input 
                          type="checkbox" 
                          checked={this.state.opties_planner_verwachteOmzet} 
                          onClick={() => this.editNewOptions('opties_planner_verwachteOmzet', !this.state.opties_planner_verwachteOmzet)}
                          readOnly
                      />
                      <span className="slider round"></span>
                  </label>

                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('options_web_tooltip_expectedTurnover')}</span>
                      </div>
                    )}
                    style={{position: 'absolute', right: -2}}
                    position={'top'}
                    followCursor
                    theme='kodict'
                  >
                    <i style={{fontSize: 10}} className='fal fa-question-circle' />
                  </Tooltip>

                </div>
              : null}

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className='planner_sidemenu_rowContainer'>

                  <span>{__('options_web_screen_actualTurnover')}</span>

                  <label className={"switch"} style={{marginLeft: 25}}>
                      <input 
                          type="checkbox" 
                          checked={this.state.opties_planner_omzet} 
                          onClick={() => this.editNewOptions('opties_planner_omzet', !this.state.opties_planner_omzet)}
                          readOnly
                      />
                      <span className="slider round"></span>
                  </label>

                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('options_web_tooltip_actualTurnover')}</span>
                      </div>
                    )}
                    style={{position: 'absolute', right: -2}}
                    position={'top'}
                    followCursor
                    theme='kodict'
                  >
                    <i style={{fontSize: 10}} className='fal fa-question-circle' />
                  </Tooltip>

                </div>
              : null}

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className='planner_sidemenu_rowContainer'>

                  <span>{__('options_web_screen_productivity')}</span>

                  <label className={"switch"} style={{marginLeft: 25}}>
                      <input 
                          type="checkbox" 
                          checked={this.state.opties_planner_productiviteit} 
                          onClick={() => this.editNewOptions('opties_planner_productiviteit', !this.state.opties_planner_productiviteit)}
                          readOnly
                      />
                      <span className="slider round"></span>
                  </label>

                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('options_web_tooltip_productivity')}</span>
                      </div>
                    )}
                    style={{position: 'absolute', right: -2}}
                    position={'top'}
                    followCursor
                    theme='kodict'
                  >
                    <i style={{fontSize: 10}} className='fal fa-question-circle' />
                  </Tooltip>

                </div>
              : null}

              {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                <div className='planner_sidemenu_rowContainer'>

                  <span>{__('options_web_screen_calculations')}</span>

                  <label className={"switch"} style={{marginLeft: 25}}>
                      <input 
                          type="checkbox" 
                          checked={this.state.opties_planner_berekeningen} 
                          onClick={() => this.editNewOptions('opties_planner_berekeningen', !this.state.opties_planner_berekeningen)}
                          readOnly
                      />
                      <span className="slider round"></span>
                  </label>

                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('options_web_tooltip_calculations')}</span>
                      </div>
                    )}
                    style={{position: 'absolute', right: -2}}
                    position={'top'}
                    followCursor
                    theme='kodict'
                  >
                    <i style={{fontSize: 10}} className='fal fa-question-circle' />
                  </Tooltip>

                </div>
              : null}

              {Data.data.appOptions.opties_versie !== '0' ?
                <div style={{marginTop: 20}}>
                  <span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 15 }}>{__('options_web_screen_openShifts')}</span>

                  <div className='planner_sidemenu_rowContainer'>

                    <span>{__('options_web_screen_openShiftsAvailable')}</span>

                    <label className={"switch"} style={{marginLeft: 25}}>
                        <input 
                            type="checkbox" 
                            checked={this.state.opties_opendienst_beschikbaar} 
                            onClick={() => this.editNewOptions('opties_opendienst_beschikbaar', !this.state.opties_opendienst_beschikbaar)}
                            readOnly
                        />
                        <span className="slider round"></span>
                    </label>

                    <Tooltip
                      options
                      html={(
                        <div className='appoptions_tooltipContainer'>
                          <span>{__('options_web_tooltip_openShifts')}</span>
                        </div>
                      )}
                      style={{position: 'absolute', right: -2}}
                      position={'top'}
                      followCursor
                      theme='kodict'
                    >
                      <i style={{fontSize: 10}} className='fal fa-question-circle' />
                    </Tooltip>

                  </div>
                </div>
              : null}

              {Data.data.appOptions.opties_versie !== '0' ?
                <div style={{marginTop: 20}}>
                  <span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 15 }}>{__('misc_contract')}</span>

                  <div className='planner_sidemenu_rowContainer'>

                    <span>{__('options_web_screen_contractExpired')}</span>

                    <label className={"switch"} style={{marginLeft: 25}}>
                        <input 
                            type="checkbox" 
                            checked={this.state.opties_contractVerlopen} 
                            onClick={() => this.editNewOptions('opties_contractVerlopen', !this.state.opties_contractVerlopen)}
                            readOnly
                        />
                        <span className="slider round"></span>
                    </label>

                    <Tooltip
                      options
                      html={(
                        <div className='appoptions_tooltipContainer'>
                          <span>{__('options_web_tooltip_contractExpired')}</span>
                        </div>
                      )}
                      style={{position: 'absolute', right: -2}}
                      position={'top'}
                      followCursor
                      theme='kodict'
                    >
                      <i style={{fontSize: 10}} className='fal fa-question-circle' />
                    </Tooltip>

                  </div>
                </div>
              : null}

              <div style={{width: '100%', height: 40}}> 
                    &nbsp;
              </div>

            </div>

          </div>

        </div>

        {this.state.showAlert === true ?
          <BetterAlert 
            _closeAlert={this.closeAlert.bind(this)}
            _title={this.state.alertTitle}
            _body={this.state.alertBody}
            _buttons={this.state.alertButtons}
            _buttonText={this.state.alertButtonText}
            _buttonAction={this.state.alertButtonAction}
          />
        : null}

        {this.state.showPublishModal === true ?
          <PublishModal 
            _closeModal={this.closeModal.bind(this)}
            _week={this.state.week}
            _year={this.state.year}
            _infoExtra={this.state.accountsObj}
            _contracts={this.state.contracts}
            _goTo={this.selectDateCalendar.bind(this)}
            _reloadScreen={this.reloadPlannerPublish.bind(this)}
          />
        : null}

        {this.state.showSmartPlanModal === true ?
          <SmartPlanModal 
            _closeModal={this.closeModal.bind(this)}
            _week={this.state.week}
            _smartPlanAlert={this.smartPlanAlert.bind(this)}
          />

        : null}

        {this.state.showSortModal === true ?  
          <SortModal 
            _closeModal={this.closeSortModal.bind(this)}
            _modalData={this.state.modalData}
          />
        : null}

        {this.state.showAddTempModal === true ?
          <TemplateModal 
            _closeModal={this.closeModal.bind(this)}
            _modalData={this.state.modalData}
            _modalMode={this.state.modalMode}
            _reloadScreen={this.reloadPlanTemplates.bind(this)}
            _saveTemplate={this.saveTemplate.bind(this)}
            _key={this.state.highestPlanTempKey}
          />
        : null}

        {this.state.showDayNoteModal === true ?
          <DayNoteModal 
            _closeModal={this.closeModal.bind(this)}
            _modalData={this.state.modalData}
            _modalMode={this.state.modalMode}
            _modalDate={this.state.modalDate}
            _reloadScreen={this.reloadDayNotes.bind(this)} //bind
          />
        : null}

        {this.state.reloading === true ?
                        
          <div className='loader2'>
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>

        : null}

        {this.state.loading === true ?
        
          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
            {this.state.smartPlanning === true ?
              <div className='smartPlan_text'>
                <span style={{ fontWeight: 700 }}>{__('misc_loadingSmartPlan')}</span>
                <span>{this.state.smartPlanning_day}: {Constants.isEmpty(this.state.smartPlanning_account) ? __('misc_loadingSmartPlan_account') : this.state.smartPlanning_account}</span>
              </div>
            : null}
          </div>

        :

          <div className='plannerZ_table'>

            <div className="plannerZ_daysContainerControls">

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, height: '100%' }}>

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{__('planner_web_screen_settings')}</span>
                    </div>
                  )}
                  position={'top'}
                  theme='tiemdo'
                  arrow
                  arrowSize='small'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_settings' style={{marginLeft: 0}} onClick={() => this.setState({ sidemenuActive: !this.state.sidemenuActive })}>
                    <i className={'far fa-cog'} />
                  </div>
                </Tooltip>

                {/* {this.state.userDefaultWorkdays.length > 0 ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <span>Medewerkers met vaste werktijden inplannen</span>
                      </div>
                    )}
                    position={'top'}
                    theme='tiemdo'
                    arrow
                    arrowSize='small'
                    delay={300}
                    hideDelay={0}
                  >
                    <div className='plannerN_settingsButton_save' onClick={this.planUsersDefaultWorkdaysAlert.bind(this)}>
                      <i className={'far fa-user-clock'} />
                    </div>
                  </Tooltip>
                : null} */}

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{__('planner_web_screen_allSavedTemplates')}</span>
                    </div>
                  )}
                  position={'top'}
                  theme='tiemdo'
                  arrow
                  arrowSize='small'
                  delay={300}
                  hideDelay={0}
                >
                  <div style={{ position: 'relative' }}>
                    <div className="plannerN_settingsButton_save settings_Asset2" onClick={() => this.setState({ menu_template_open: !this.state.menu_template_open, menu_export_open: false, })}>
                      <i className={'far fa-layer-group settings_Asset2'} />
                    </div>
                    {this.state.menu_template_open === true ?

                      <div className='plannerZ_excelMenu settings_Asset2'>

                        <div className='settings_Asset2' style={{ fontWeight: 700, marginBottom: '0.4vw' }}>{__('planner_web_screen_allSavedTemplates')}</div>

                        {this.getPlanTemplateRows()}

                      </div>

                    : null}
                  </div>
                </Tooltip>

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{Localization.t.formatString(__('planner_web_screen_savePlanner'), this.state.week)}</span>
                    </div>
                  )}
                  position={'top'}
                  theme='tiemdo'
                  arrow
                  arrowSize='small'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_save' onClick={() => this.openAddTempModal()}>
                    <i className={'far fa-save'} />
                  </div>
                </Tooltip>

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{__('planner_web_screen_exportPlanner')}</span>
                    </div>
                  )}
                  position={'top'}
                  theme='tiemdo'
                  arrow
                  arrowSize='small'
                  delay={300}
                  hideDelay={0}
                >
                  <div style={{ position: 'relative' }}>
                    <div className="plannerN_settingsButton_excel settings_Asset2" onClick={() => this.setState({ menu_export_open: !this.state.menu_export_open, menu_template_open: false, })}>
                      <i className={'far fa-file-download settings_Asset2'} />
                    </div>
                    {this.state.menu_export_open === true ?

                      <div className='plannerZ_excelMenu settings_Asset2'>

                        <div className='settings_Asset2' style={{ fontWeight: 700, marginBottom: '0.4vw' }}>Excel</div>

                        <ScheduleDownloadName dataSet={this.state.planAccounts} week={this.state.week} year={this.state.year} closeMenu={this.closeAllNormalMenus.bind(this)}/>
                        <ScheduleDownloadJob dataSet={this.state.planAccounts} week={this.state.week} year={this.state.year} closeMenu={this.closeAllNormalMenus.bind(this)}/>

                        <div className='settings_Asset2' style={{ fontWeight: 700, marginBottom: '0.4vw', marginTop: '0.5vw' }}>Google (csv)</div>

                        <div className='plannerZ_menuRowButton settings_Asset2' onClick={() => this.downloadBlob(this.plansToCsv(), 'export.csv', 'text/csv;charset=utf-8;')}>
                          <div className='plannerZ_menuRowButton_sub1 settings_Asset2'>
                            <p><i className='far fa-file-csv settings_Asset2' aria-hidden={true} /></p>
                          </div>
                          <div className='plannerZ_menuRowButton_sub2 settings_Asset2'>
                            <span className='settings_Asset2'>{__('planner_web_menu_exportCsv')}</span>
                          </div>
                          <div className='plannerZ_menuRowButton_sub3 settings_Asset2'>
                            <p><i className='far fa-chevron-right settings_Asset2' aria-hidden={true} /></p>
                          </div>
                        </div>

                      </div>

                    : null}
                  </div>
                </Tooltip>

                {/* <div className='plannerN_settingsButton_save' onClick={() => this.openAddTempModal()}>
                  <i className={'far fa-save'} />
                </div> */}

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{Localization.t.formatString(__('planner_web_screen_undoPlanner'), this.state.week)}</span>
                    </div>
                  )}
                  position={'top'}
                  theme='tiemdo'
                  arrow
                  arrowSize='small'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_redo' onClick={() => this.undoPlannerAlert()}>
                    <i className={'far fa-redo'} />
                  </div>
                </Tooltip>

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{Localization.t.formatString(__('planner_web_screen_deletePlanner'), this.state.week)}</span>
                    </div>
                  )}
                  position={'top'}
                  theme='tiemdo'
                  arrow
                  arrowSize='small'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_delete' onClick={() => this.deletePlannerAlert()}>
                    <i className={'far fa-trash'} />
                  </div>
                </Tooltip>

              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flex: 2, height: '100%' }}>

                {_unpublishedWeek !== 0 ?
                  <div className='plannerZ_caution_label' style={{ backgroundColor: Colors.color.redFilledIn }}>
                    <span style={{ color: 'white' }}><b>{_unpublishedWeek}</b> {__('planner_web_caution_notPublished')}</span>
                  </div>
                : null}

                {_incorrectWeek !== 0 ?
                  <div className='plannerZ_caution_label' style={{ backgroundColor: Colors.color.redFilledIn }}>
                    <span style={{ color: 'white' }}><b>{_incorrectWeek}</b> {__('planner_web_caution_conflicts')}</span>
                  </div>
                : null}

                {_openShiftsWeek !== 0 ?
                  <div className='plannerZ_caution_label' style={{ backgroundColor: Colors.color.redFilledIn }}>
                    <span style={{ color: 'white' }}><b>{_openShiftsWeek}</b> {__('options_web_screen_openShifts')}</span>
                  </div>
                : null}

                {_availableShiftsWeek !== 0 ?
                  <div className='plannerZ_caution_label' style={{ backgroundColor: Colors.color.orangeVacation }}>
                    <span style={{ color: 'white' }}><b>{_availableShiftsWeek}</b> {__('misc_availableShifts').toLowerCase()}</span>
                  </div>
                : null}

                {_dayShiftsWeek !== 0 ?
                  <div className='plannerZ_caution_label' style={{ backgroundColor: Colors.color.orangeVacation }}>
                    <span style={{ color: 'white' }}><b>{_dayShiftsWeek}</b> {__('misc_defaultShifts').toLowerCase()}</span>
                  </div>
                : null}

              </div>
              
            </div>

            <div className="plannerZ_daysContainerControls">

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flex: 2, height: '100%' }}>

                {/* <span style={{ fontWeight: 600, fontSize: '0.8vw', marginLeft: '0.6vw' }}>{__('options_web_screen_display')}</span> */}

                <div style={{width: '12vw', marginLeft: '0.6vw'}}>
                  <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.2vw' }}>{__('misc_display')}</div>
                  <select
                      name={__('select_option')}
                      className={'plannerZ_input_select'}
                      onChange={(event) => this.selectDisplay(event)}
                  >
                    <option value={'null'} disabled>{__('misc_display')}</option>
                    <option value={'TABLE'} selected={this.state.display === 'TABLE'}>{__('misc_display_normal')}</option>
                    <option value={'XCL'} selected={this.state.display === 'XCL'}>{__('misc_display_excel')}</option>
                    <option value={'LIST'} selected={this.state.display === 'LIST'}>{__('misc_display_list')}</option>                      
                  </select>
                </div>

                {/* <span style={{ fontWeight: 600, fontSize: '0.8vw', marginLeft: '1vw' }}>{__('misc_section')}</span> */}

                <div style={{width: '10vw', marginLeft: '0.6vw'}}>
                  <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.2vw' }}>{__('misc_section')}</div>
                  <select
                      name={__('select_option')}
                      className={'plannerZ_input_select'}
                      onChange={(event) => this.selectSection(event)}
                  >
                      <option value={'null'} disabled>{__('misc_section')}</option>
                      {this.getSectionSelections()}
                  </select>
                </div>

                {/* <span style={{ fontWeight: 600, fontSize: '0.8vw', marginLeft: '1vw' }}>{__('misc_defaultShifts')}</span> */}

                <div style={{width: '10vw', marginLeft: '0.6vw'}}>
                  <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.2vw' }}>{__('planner_screen_shiftTemplate')}</div>
                  <select
                      name={__('select_option')}
                      className={'plannerZ_input_select'}
                      onChange={(event) => this.selectDayShiftsTemplate(event)}
                  >
                      <option value={'null'} disabled>{__('misc_defaultShifts')}</option>
                      <option value={'null'} selected={this.state.dayShiftsTemplate === null}>{__('misc_none')}</option>
                      {this.getDayShiftsSelections()}
                  </select>
                </div>

                {/* <span style={{ fontWeight: 600, fontSize: '0.8vw', marginLeft: '1vw' }}>{__('misc_defaultShifts')}</span> */}

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{__('misc_copyMove')}</span>
                    </div>
                  )}
                  arrow
                  arrowSize='small'
                  position={'top'}
                  theme='tiemdo'
                  delay={300}
                  hideDelay={0}
                  style={{ marginLeft: '0.6vw' }}
                >
                  <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.2vw' }}>{__('button_copy')}</div>
                  {this.state.copyShifts === true ?
                    <div className='reports_toggleButton_active' onClick={() => this.setState({ copyShifts: false })}>
                      <i className='fas fa-check-circle' />
                      <span><i className={'far fa-copy'} /></span>
                    </div>
                  :
                    <div className='reports_toggleButton' onClick={() => this.setState({ copyShifts: true })}>
                      <i className='fal fa-circle' />
                      <span><i className={'fal fa-copy'} /></span>
                    </div>
                  }
                </Tooltip>

                <Tooltip
                  options
                  html={(
                    <div className='plannerZ_tooltipContainer'>
                      <span>{__('misc_selectShifts')}</span>
                    </div>
                  )}
                  arrow
                  arrowSize='small'
                  position={'top'}
                  theme='tiemdo'
                  delay={300}
                  hideDelay={0}
                  style={{ marginLeft: '0.6vw' }}
                >
                  <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.2vw' }}>{__('misc_select')}</div>
                  {this.state.selectShifts === true ?
                    <div className='reports_toggleButton_active' onClick={() => { this.setState({ selectShifts: false }); localStorage.setItem('@planner_selectShifts', '0'); }}>
                      <i className='fas fa-check-circle' />
                      <span><i className={'far fa-arrow-pointer'} /></span>
                    </div>
                  :
                    <div className='reports_toggleButton' onClick={() => { this.setState({ selectShifts: true }); localStorage.setItem('@planner_selectShifts', '1'); }}>
                      <i className='fal fa-circle' />
                      <span><i className={'fal fa-arrow-pointer'} /></span>
                    </div>
                  }
                </Tooltip>

                {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerZ_tooltipContainer'>
                        <span>{__('misc_showTeams')}</span>
                      </div>
                    )}
                    arrow
                    arrowSize='small'
                    position={'top'}
                    theme='tiemdo'
                    delay={300}
                    hideDelay={0}
                    style={{ marginLeft: '0.6vw' }}
                  >
                    <div style={{ fontSize: '0.7vw', fontWeight: 600, marginBottom: '0.2vw' }}>{__('misc_teams')}</div>
                    {this.state.showTeams === true ?
                      <div className='reports_toggleButton_active' onClick={() => this.toggleTeams(false)}>
                        <i className='fas fa-check-circle' />
                        <span><i className={'far fa-screen-users'} /></span>
                      </div>
                    :
                      <div className='reports_toggleButton' onClick={() => this.toggleTeams(true)}>
                        <i className='fal fa-circle' />
                        <span><i className={'fal fa-screen-users'} /></span>
                      </div>
                    }
                  </Tooltip>
                : null}

              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1, height: '100%' }}>

                {this.state.userDefaultWorkdays.length > 0 ?
                  <div className="planner_new_availableButton2" style={{ marginLeft: 0, marginRight: '0.6vw' }} onClick={() => this.fixedShiftsAlert()}>
                    <span>{__('misc_defaultWorkingTimes')}</span>
                  </div>
                : null}

                {Data.data.appOptions.opties_smartplan === '1' ?
                  <div className="planner_new_availableButton2" style={{ marginLeft: 0, marginRight: '0.6vw' }} onClick={() => this.openSmartPlanModal()}>
                    <span>Smart Plan</span>
                  </div>
                : null}

                <div className="planner_new_publishButton2" style={{ marginLeft: 0, marginRight: '0.6vw' }} onClick={() => this.openPublishModal()}>
                  <span style={{ fontWeight: 700 }}>{__('misc_publish')} ({parseInt(_unpublishedWeek)})</span>
                </div>

              </div>
              
            </div>

            <div className="plannerZ_daysContainer stickyPlannerZ_options">
              {this.getDaysData()}
            </div>

            {/* NORMAL DISPLAY */}
            {this.state.display === 'TABLE' ?

              this.renderUsersTableDisplay()

            /* EXCEL DISPLAY */  
            : this.state.display === 'XCL' ?

              this.renderUsersExcelDisplay()

            /* LIST DISPLAY */  
            : this.state.display === 'LIST' ?

              this.renderUsersListDisplay()

            : null}


          </div>
        
        }

      </div>

    );
    
  }
  
}

export default Planner;