import React from 'react';

//styles
import './registerUserCode.css';
import '../registerUser/registerUser.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import APICheckUsernameAvailibility from '../../../classes/APICheckUsernameAvailibility';
import APIRegisterDeleteCode from '../../../classes/APIRegisterDeleteCode';
import APIRegisterAccount from '../../../classes/APIRegisterAccount';
import APIRegisterGetAccountID from '../../../classes/APIRegisterGetAccountID';
import APIRegisterAccountInfo from '../../../classes/APIRegisterAccountInfo';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import CryptoJS from 'crypto-js';

import BetterAlert from '../../../components/alert/alert';
import KodictNotifications from '../../../constants/KodictNotifications';

class RegisterUserCode extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    this.regCode = this.props.location.regCode;
    this.registerObject = null;

    if(this.props.location.registerObject === null || this.props.location.registerObject === undefined || this.props.location.registerObject.length <= 0) {
        this.props.history.push('/');
    } else {
        this.registerObject = this.props.location.registerObject[0]; //
    }

    // compwillmount
    
    this.state = {

      loading: true,
      loading1: false,
      loading2: false,

      blockUser: false,
      blockInfo: true,

      username: '',
      password1: '',
      password2: '',

      showError1: false,
      errorText1: '',

      firstName: '',
      insertion: '',
      lastName: '',

      birthDate: null,
      birthText: '',
      birthplace: '',

      address: '',
      addressNumber: '',
      zipCode: '',
      city: '',

      email: '',
      phone: '',
      phone2: '',

      startDate: null,

      iban: '',
      bsn: '',

      functionID: null,
      rights: 0,

    //   username: this.registerObject.registratie_username === null ? '' : this.registerObject.registratie_username,
    //   password1: '',
    //   password2: '',

    //   showError1: false,
    //   errorText1: '',

    //   firstName: '',
    //   insertion: '',
    //   lastName: '',

    //   birthDate: null,
    //   birthText: '',

    //   address: '',
    //   addressNumber: '',
    //   zipCode: '',

    //   email: this.registerObject.registratie_email === null ? '' : this.registerObject.registratie_email,
    //   phone: this.registerObject.registratie_telefoon === null ? '' : this.registerObject.registratie_telefoon,

    //   functionID: this.registerObject.registratie_functie_id === null ? null : parseInt(this.registerObject.registratie_functie_id),
    //   rights: this.registerObject.registratie_rechten === null ? 0 : parseInt(this.registerObject.registratie_rechten),

      comment: '',

      showError2: false,
      errorText2: '',

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      privacyAgreed: false,

    };

  }

  async componentDidMount() {

    if(this.registerObject === null) {
        this.props.history.push('/');
        return;
    }

    this.setState({
        username: this.registerObject.registratie_username === null ? '' : this.registerObject.registratie_username,
  
        email: this.registerObject.registratie_email === null ? '' : this.registerObject.registratie_email,
        phone: this.registerObject.registratie_telefoon === null ? '' : this.registerObject.registratie_telefoon,

        startDate: this.registerObject.registratie_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.registerObject.registratie_indienst,
  
        functionID: this.registerObject.registratie_functie_id === null ? null : parseInt(this.registerObject.registratie_functie_id),
        rights: this.registerObject.registratie_rechten === null ? 0 : parseInt(this.registerObject.registratie_rechten),
        loading: false,
    });


  }

  addedAlert() {
    this.setState({
        alertTitle: 'Geregistreerd',
        alertBody: `Je bent succesvol geregistreerd!`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  setBirthDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }
  
      const dateObject = new Date(date);

      dateObject.setDate(dateObject.getDate() + 1);
  
      const dateText = dateObject.toISOString().substring(0, 10);
  
      this.setState({
          birthDate: date,
          birthText: dateText,
          showError2: false,
      });

  }

  async continue() {

    this.setState({loading1: true, showError1: false});

    if(await this.checkValidations1() === false) {
        this.setState({loading1: false});
        return;
    }

    this.setState({
        loading1: false,
        blockUser: true,
        blockInfo: false,
    });

    const smoothScroll = 'scrollBehavior' in document.documentElement.style;

    if(smoothScroll === true) {
        window.scrollTo({
            top: this.infoRef.offsetTop - 100,
            behavior: 'smooth',
        });
    } else {
        window.scrollTo(0, this.infoRef.offsetTop - 100);
    }

  }

  async previous() {

    this.setState({showError2: false});

    this.setState({
        blockUser: false,
        blockInfo: true,
    });

    const smoothScroll = 'scrollBehavior' in document.documentElement.style;

    if(smoothScroll === true) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    } else {
        window.scrollTo(0, 0);
    }

  }

  async saveAccount() {

    this.setState({loading2: true, showError2: false});

    if(await this.checkValidations1() === false || this.checkValidations2() === false) {
        this.setState({loading2: false});
        return;
    }

    const codeDeleted = await APIRegisterDeleteCode.Request(this.regCode);

    if(codeDeleted === false) {
        this.setState({
            loading2: false,
            showError2: true,
            errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
        });
        return;
    }

    const accountAdded = await APIRegisterAccount.Request(
        this.state.username,
        CryptoJS.SHA512(this.state.password2).toString(),
        this.state.rights
    );

    if(accountAdded === true) {

        const accountIDObject = await APIRegisterGetAccountID.Request(this.state.username);

        const accountInfoAdded = await APIRegisterAccountInfo.Request(
            this.state.username,
            parseInt(accountIDObject[0].account_id),
            this.state.firstName,
            this.state.insertion,
            this.state.lastName,
            this.state.phone,
            this.state.email,
            this.state.functionID,
            this.state.comment,
            this.state.address,
            this.state.addressNumber,
            this.state.zipCode,
            this.state.birthText,
            this.state.privacyAgreed === true ? 1 : 0,
            this.state.startDate === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.startDate,
            this.state.iban,
            this.state.bsn,
            this.state.city,
            this.state.phone2,
            this.state.birthplace,
        );

        if(accountInfoAdded === true) {

            this.addedAlert();

            const userFullName = `${this.state.firstName} ${(this.state.insertion !== null && this.state.insertion !== '') ? this.state.insertion + " " + this.state.lastName : this.state.lastName}`;

            await KodictNotifications.sendPushNotificationAdmin('news', 'Registratie', `${userFullName} heeft zich zojuist geregistreerd`);

            localStorage.setItem('@storecode', Data.data.storeCode.toLowerCase());
            localStorage.setItem('@username', this.state.username.toLowerCase());
            localStorage.setItem('@password', CryptoJS.SHA512(this.state.password2).toString());

            this.props.history.push('/');

            return;

        } else {

            this.setState({
                loading2: false,
                showError2: true,
                errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
            });

            return;

        }

    } else {

        this.setState({
            loading2: false,
            showError2: true,
            errorText2: 'Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw.'
        });

        return;

    }

  }

  showError1() {

    if(this.state.showError1 === true) {
      return <div className='errorText'>{this.state.errorText1}</div>;
    } else {
      return null;
    }

  }

  showError2() {

    if(this.state.showError2 === true) {
      return <div className='errorText'>{this.state.errorText2}</div>;
    } else {
      return null;
    }

  }

  async checkValidations1() {

    if(this.state.username === '') {
        this.setState({
            showError1: true,
            errorText1: 'Je hebt geen gebruikersnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9]*$/.test(this.state.username) === false) {
        this.setState({
            showError1: true,
            errorText1: 'Gebruikersnaam mag alleen letters en/of cijfers bevatten',
        });
        return false;
    }

    const userNameExists = await APICheckUsernameAvailibility.Request(this.state.username);

    if(userNameExists === true) {
        this.setState({
            showError1: true,
            errorText1: 'Deze gebruikersnaam bestaat al',
        });
        return false;
    }

    if(this.state.password1 === '') {
        this.setState({
            showError1: true,
            errorText1: 'Je hebt geen wachtwoord ingevoerd',
        });
        return false;
    }

    if(this.state.password2 === '') {
        this.setState({
            showError1: true,
            errorText1: 'Je moet het wachtwoord nog bevestigen',
        });
        return false;
    }

    if(this.state.password1 !== this.state.password2) {
        this.setState({
            showError1: true,
            errorText1: 'Wachtwoorden komen niet overeen',
        });
        return false;
    }

    return true;

  }

  checkValidations2() {

    if(this.state.firstName === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen voornaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.firstName) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Voornaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.insertion !== '' && /^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.insertion) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Tussenvoegsel mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.lastName === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen achternaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.lastName) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Achternaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.birthDate === null || this.state.birthText === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen geboortedatum geselecteerd',
        });
        return false;
    }
    if(this.state.birthplace === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen geboorteplaats ingevoerd',
        });
        return false;
    }
    if(this.state.address === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen straatnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.address) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Straatnaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.addressNumber === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen huisnummer ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-()]*$/.test(this.state.addressNumber) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Huisnummer mag alleen letters, cijfers en -() bevatten',
        });
        return false;
    }
    if(this.state.zipCode === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen postcode ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.zipCode) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Postcode mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.city === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen plaatsnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.city) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Plaatsnaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.email === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen e-mailadres ingevoerd',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.email) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Geen geldig e-mailadres',
        });
        return false;
    }
    if(this.state.phone === '') {
        this.setState({
            showError2: true,
            errorText2: 'Je hebt geen telefoonnummer ingevoerd',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phone) === false) {
        this.setState({
            showError2: true,
            errorText2: 'Geen geldig telefoonnummer',
        });
        return false;
    }
    if(this.state.privacyAgreed === false) {
        this.setState({
            showError2: true,
            errorText2: 'Je moet akkoord gaan met de privacyverklaring',
        });
        return false;
    }

    return true;

  }

  toggleSwitch() {

    this.setState({
        privacyAgreed: !this.state.privacyAgreed,
        showError2: false,
    });

  }

  render() {

    return (
        <div className='registerUserCode'>

          <div className='planner_nav2'>

            <div className='planner_nav2_sub1' onClick={() => this.props.history.goBack()}>
              <i className='fa fa-chevron-left'></i><div style={{fontSize: '1vw', marginLeft: 20}}>Login</div>
            </div> 
            <div className='planner_nav2_sub2'>
            </div> 
            <div className='planner_nav2_sub3'>
              Registratie
            </div> 
            <div className='planner_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='planner_nav2_sub5'>
            </div> 

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='registerUser_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }


                <div className={'registerUser_userContainer'}>

                    <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Inloggegevens</div>

                    <div>Gebruikersnaam: *</div>

                    <input 
                        className={this.state.blockUser === false && this.registerObject.registratie_username === null ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={30}
                        value={this.state.username}
                        onChange={(event) => this.setState({ username: event.target.value, showError1: false })}
                        placeholder={'Gebruikersnaam'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser || (this.registerObject.registratie_username !== null && this.registerObject.registratie_username !== '')}
                    />

                    <div style={{marginTop: 10}}>Wachtwoord: *</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        type={'password'}
                        value={this.state.password1}
                        onChange={(event) => this.setState({ password1: event.target.value, showError1: false })}
                        placeholder={'Wachtwoord'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    <div style={{marginTop: 10}}>Bevestig wachtwoord: *</div>

                    <input 
                        className={this.state.blockUser === false ? 'inputField2' : 'inputField2Disabled'}
                        type={'password'}
                        value={this.state.password2}
                        onChange={(event) => this.setState({ password2: event.target.value, showError1: false })}
                        placeholder={'Bevestig wachtwoord'}
                        autoComplete={'new-password'}
                        disabled={this.state.blockUser}
                    />

                    {this.state.blockUser === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}>Volgende</div>

                    :
                    this.state.loading1 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.continue()}>Volgende</div>

                    }

                    {this.showError1()}

                    <div style={{fontStyle: 'italic', marginTop: 10, fontSize: '1vw'}}>(*) = verplicht</div>

                </div>

                <div ref={(ref) => this.infoRef=ref} className={'registerUser_infoContainer'}>

                    {this.state.blockUser === true ?

                        <div className='submitButton' style={{marginBottom: 10}} onClick={() => this.previous()}>Terug</div>

                    :

                        null

                    }

                    {/* <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Persoonsgegevens</div>

                    <div>Voornaam: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={50}
                        value={this.state.firstName}
                        onChange={(event) => this.setState({ firstName: event.target.value, showError2: false })}
                        placeholder={'Voornaam'}
                        disabled={this.state.blockInfo}
                        style={{textTransform: 'capitalize'}}
                    />

                    <div style={{marginTop: 10}}>Tussenvoegsel:</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={50}
                        value={this.state.insertion}
                        onChange={(event) => this.setState({ insertion: event.target.value, showError2: false })}
                        placeholder={'Tussenvoegsel'}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Achternaam: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={50}
                        value={this.state.lastName}
                        onChange={(event) => this.setState({ lastName: event.target.value, showError2: false })}
                        placeholder={'Achternaam'}
                        disabled={this.state.blockInfo}
                        style={{textTransform: 'capitalize'}}
                    />

                    <div style={{marginTop: 10}}>Geboortedatum: *</div>

                    <DatePicker
                        selected={this.state.birthDate}
                        onChange={(date) => this.setBirthDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className={this.state.blockInfo === false ? 'inputFieldTime' : 'inputFieldTimeDisabled'}
                        placeholderText={'Selecteer jouw geboortedatum'}
                        showWeekNumbers
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        locale={nl}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Geboorteplaats: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={100}
                        value={this.state.birthplace}
                        onChange={(event) => this.setState({ birthplace: event.target.value, showError2: false })}
                        placeholder={'Geboorteplaats'}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Straatnaam: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={80}
                        value={this.state.address}
                        onChange={(event) => this.setState({ address: event.target.value, showError2: false })}
                        placeholder={'Straatnaam'}
                        disabled={this.state.blockInfo}
                        style={{textTransform: 'capitalize'}}
                    />

                    <div style={{marginTop: 10}}>Huisnummer: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={10}
                        value={this.state.addressNumber}
                        onChange={(event) => this.setState({ addressNumber: event.target.value, showError2: false })}
                        placeholder={'Huisnummer + toevoeging'}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>Postcode: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={20}
                        value={this.state.zipCode}
                        onChange={(event) => this.setState({ zipCode: event.target.value, showError2: false })}
                        placeholder={'Postcode'}
                        disabled={this.state.blockInfo}
                        style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                    />

                    <div style={{marginTop: 10}}>Plaatsnaam: *</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={50}
                        value={this.state.city}
                        onChange={(event) => this.setState({ city: event.target.value, showError2: false })}
                        placeholder={'Plaatsnaam'}
                        disabled={this.state.blockInfo}
                    />

                    <div style={{marginTop: 10}}>E-mail: *</div>

                    <input 
                        className={this.state.blockInfo === false && this.registerObject.registratie_email === null ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={50}
                        type={'email'}
                        value={this.state.email}
                        onChange={(event) => this.setState({ email: event.target.value, showError2: false })}
                        placeholder={'E-mail'}
                        disabled={this.state.blockInfo || (this.registerObject.registratie_email !== null && this.registerObject.registratie_email !== '')}
                    />

                    <div style={{marginTop: 10}}>Telefoon: *</div>

                    <input 
                        className={this.state.blockInfo === false && this.registerObject.registratie_telefoon === null ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={12}
                        type={'tel'}
                        value={this.state.phone}
                        onChange={(event) => this.setState({ phone: event.target.value, showError2: false })}
                        placeholder={'bv. 06 of 074'}
                        disabled={this.state.blockInfo || (this.registerObject.registratie_telefoon !== null && this.registerObject.registratie_telefoon !== '')}
                    />

                    <div style={{marginTop: 10}}>Tweede telefoon:</div>

                    <input 
                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                        maxLength={12}
                        type={'tel'}
                        value={this.state.phone2}
                        onChange={(event) => this.setState({ phone2: event.target.value })}
                        placeholder={'Optioneel'}
                        disabled={this.state.blockInfo}
                    />

                    {Data.data.appOptions.iban === true ?

                    <div>

                        <div style={{marginTop: 10}}>IBAN:</div>

                        <input 
                            className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                            maxLength={25}
                            value={this.state.iban}
                            onChange={(event) => this.setState({ iban: event.target.value })}
                            placeholder={'bv. NL85 ABNA #########'}
                            disabled={this.state.blockInfo}
                        />

                    </div>

                    : null}

                    {Data.data.appOptions.bsn === true ?

                    <div>

                        <div style={{marginTop: 10}}>BSN-nummer:</div>

                        <input 
                            className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                            maxLength={15}
                            value={this.state.bsn}
                            onChange={(event) => this.setState({ bsn: event.target.value })}
                            placeholder={'Persoonsnummer'}
                            disabled={this.state.blockInfo}
                        />

                    </div>

                    : null}

                    <div style={{marginTop: 10}}>Aanvullende informatie: (max. 250 tekens)</div>

                    <textarea  
                        className={this.state.blockInfo === false ? 'inputFieldBig' : 'inputFieldBigDisabled'}
                        maxLength={250}
                        value={this.state.comment}
                        onChange={(event) => this.setState({ comment: event.target.value })}
                        disabled={this.state.blockInfo}
                    /> */}

                    <div className='registerUser_infoContainerRow'>

                        <div className='registerUser_infoContainer_sub' style={{paddingRight: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Persoonsgegevens</div>

                            <div>Voornaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.firstName}
                                onChange={(event) => this.setState({ firstName: event.target.value, showError2: false })}
                                placeholder={'Voornaam'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Tussenvoegsel:</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.insertion}
                                onChange={(event) => this.setState({ insertion: event.target.value, showError2: false })}
                                placeholder={'Tussenvoegsel'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Achternaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.lastName}
                                onChange={(event) => this.setState({ lastName: event.target.value, showError2: false })}
                                placeholder={'Achternaam'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Geboortedatum: *</div>

                            <DatePicker
                                selected={this.state.birthDate}
                                onChange={(date) => this.setBirthDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={this.state.blockInfo === false ? 'inputFieldTime' : 'inputFieldTimeDisabled'}
                                placeholderText={'Selecteer jouw geboortedatum'}
                                showWeekNumbers
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={nl}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Geboorteplaats: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={100}
                                value={this.state.birthplace}
                                onChange={(event) => this.setState({ birthplace: event.target.value, showError2: false })}
                                placeholder={'Geboorteplaats'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>E-mail: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                type={'email'}
                                value={this.state.email}
                                onChange={(event) => this.setState({ email: event.target.value, showError2: false })}
                                placeholder={'E-mail'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Telefoon: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={12}
                                type={'tel'}
                                value={this.state.phone}
                                onChange={(event) => this.setState({ phone: event.target.value, showError2: false })}
                                placeholder={'bv. 06 of 074'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Tweede telefoon:</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={12}
                                type={'tel'}
                                value={this.state.phone2}
                                onChange={(event) => this.setState({ phone2: event.target.value })}
                                placeholder={'Optioneel'}
                                disabled={this.state.blockInfo}
                            />

                            {Data.data.appOptions.iban === true ?

                                <div>

                                    <div style={{marginTop: 10}}>IBAN:</div>

                                    <input 
                                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                        maxLength={25}
                                        value={this.state.iban}
                                        onChange={(event) => this.setState({ iban: event.target.value })}
                                        placeholder={'bv. NL85 ABNA #########'}
                                        disabled={this.state.blockInfo}
                                    />

                                </div>

                            : null}

                            {Data.data.appOptions.bsn === true ?

                                <div>

                                    <div style={{marginTop: 10}}>BSN-nummer:</div>

                                    <input 
                                        className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                        maxLength={15}
                                        value={this.state.bsn}
                                        onChange={(event) => this.setState({ bsn: event.target.value })}
                                        placeholder={'Persoonsnummer'}
                                        disabled={this.state.blockInfo}
                                    />

                                </div>

                            : null}



                            {/* <div style={{marginTop: 10}}>Datum in dienst:</div>

                            <DatePicker
                                selected={this.state.startDate}
                                onChange={(date) => this.setStartDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={this.state.blockInfo === false ? 'inputFieldTime' : 'inputFieldTimeDisabled'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={nl}
                                disabled={this.state.blockInfo}
                            /> */}

                            <div style={{marginTop: 10}}>Aanvullende informatie: (max. 250 tekens)</div>

                            <textarea  
                                className={this.state.blockInfo === false ? 'inputFieldBig' : 'inputFieldBigDisabled'}
                                maxLength={250}
                                value={this.state.comment}
                                onChange={(event) => this.setState({ comment: event.target.value })}
                                disabled={this.state.blockInfo}
                            />

                        </div>

                        <div className='registerUser_infoContainer_sub' style={{paddingLeft: '1vw', paddingRight: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Adresgegevens</div>

                            <div>Straatnaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={80}
                                value={this.state.address}
                                onChange={(event) => this.setState({ address: event.target.value, showError2: false })}
                                placeholder={'Straatnaam'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Huisnummer: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={10}
                                value={this.state.addressNumber}
                                onChange={(event) => this.setState({ addressNumber: event.target.value, showError2: false })}
                                placeholder={'Huisnummer + toevoeging'}
                                disabled={this.state.blockInfo}
                            />

                            <div style={{marginTop: 10}}>Postcode: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={20}
                                value={this.state.zipCode}
                                onChange={(event) => this.setState({ zipCode: event.target.value, showError2: false })}
                                placeholder={'Postcode'}
                                disabled={this.state.blockInfo}
                                style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                            />

                            <div style={{marginTop: 10}}>Plaatsnaam: *</div>

                            <input 
                                className={this.state.blockInfo === false ? 'inputField2' : 'inputField2Disabled'}
                                maxLength={50}
                                value={this.state.city}
                                onChange={(event) => this.setState({ city: event.target.value, showError2: false })}
                                placeholder={'Plaatsnaam'}
                                disabled={this.state.blockInfo}
                            />

                        </div>

                        {/* <div className='registerUser_infoContainer_sub' style={{paddingLeft: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Accountgegevens</div>

                            <div>Functie:</div>

                            <select 
                                name="Selecteer een functie" 
                                className={this.state.blockInfo === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                                disabled={this.state.blockInfo}
                                style={{marginTop: 5}} 
                                onChange={(event) => this.selectFunction(event)}
                            >
                                <option value={null} selected>Selecteer een functie</option>
                                {functions}
                            </select>

                            <div style={{marginTop: 10}}>Accountrechten: *</div>

                            <select 
                                name="Selecteer een accountrecht" 
                                className={this.state.blockInfo === true ? 'dropDownFieldDisabled' : 'dropDownField'}
                                disabled={this.state.blockInfo}
                                style={{marginTop: 5}} 
                                onChange={(event) => this.selectRight(event)}
                            >
                                <option value={'0'} selected>Medewerker</option>
                                <option value={'1'}>Planner</option>
                                <option value={'2'}>Administrator</option>
                            </select>

                        </div> */}

                    </div>

                    <div style={{marginTop: 10}}>

                        <div style={{display: 'inline'}}>
                            Ik ga akkoord met de <div style={{display: 'inline', color: '#47B7EB', cursor: 'pointer'}} onClick={() => window.open('https://tiemdo.nl/privacy.html', '_blank')}>privacyverklaring</div>
                        </div>

                        <label className="switch" style={{marginTop: 5, display: 'block'}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.privacyAgreed} 
                                onClick={() => this.toggleSwitch()}
                                disabled={this.state.blockInfo}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                    </div>

                    {this.state.blockInfo === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}>Registreren</div>

                    :
                    this.state.loading2 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.saveAccount()}>Registreren</div>

                    }

                    {this.showError2()}

                    <div style={{fontStyle: 'italic', marginTop: 10, marginBottom: 100, fontSize: '1vw'}}>(*) = verplicht</div>

                </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default RegisterUserCode;