/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './login.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../../components/alert/alert';
import __ from '../../constants/__';
import APICheckStoreCode from '../../classes/APICheckStoreCode';
import APIGetHostedPage from '../../classes/payment/APIGetHostedPage';
import APIADD from '../../classes/global/APIADD';
import APIEDITLOGIN from '../../classes/login/APIEDITLOGIN';
import APIUpdateSubscriptionTrial from '../../classes/payment/APIUpdateSubscriptionTrial';
import APIGET from '../../classes/global/APIGET';
import Colors from '../../constants/Colors';

class SubscriptionModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.errorText = this.props._errorText;
    
    this.state = {

        loading: false,

        functionName: '',
        selectedColor: '#00C0FF',

        showError: false,
        errorText: '',

        validCompanyCode: false,
        companyCode: '',
        codeData: null,
        checkoutTrial: false,
        checkoutVersion: '1',

        accounts: [],
        plannedShifts_fallback: [],

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

  }

  async componentDidUpdate() {
    
    if(this.state.validCompanyCode === true) {

        window.Chargebee.registerAgain();

        const parent = this;

        const cbInstance = window.Chargebee.getInstance();
        cbInstance.setCheckoutCallbacks(function(cart) {
            return {

                success: async function(hpid) {
                    // console.log('success', hpid, cart);
                    await parent.succeedCheckout(hpid);
                },
                close: function() {
                    parent.closeModal();
                }

            }
        });

    }

  }

  async succeedCheckout(hpid) {

    this.setState({ loading: true });

    const result = await APIGetHostedPage.Request(hpid);

    await APIADD.Request(`INSERT INTO HostedPage`, `VALUES ('${result.id}', '${result.content.customer.id}', '${result.content.subscription.id}', NULL)`, this.state.codeData.bedrijf_code);
    await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET pakket_id = 101`, `WHERE bedrijf_code = '${this.state.codeData.bedrijf_code}'`);

    if (Constants.isEmpty(this.state.codeData.bedrijf_proef_datum) === false) {

        let _trialEnd = new Date(this.state.codeData.bedrijf_proef_datum);
        _trialEnd = new Date(_trialEnd.setDate(_trialEnd.getDate() + 1));
        const _now = await Constants.getDateNow2(this.state.codeData.bedrijf_tijdzone);

        if (_trialEnd.getTime() > _now.getTime()) {

            const product = this.state.checkoutVersion === '2' ? this.state.checkoutTrial === true ? "TIMETRACKING_TRIAL-EUR-MONTHLY" : "TIMETRACKING-EUR-MONTHLY" : this.state.checkoutTrial === true ? "SCHEDULING_TRIAL-EUR-MONTHLY" : "SCHEDULING-EUR-MONTHLY";
            const activeEmployees = this.getActiveEmployees();

            let trialEnd = new Date(this.state.codeData.bedrijf_proef_datum);
            trialEnd = new Date(trialEnd.setDate(trialEnd.getDate() + 1));
            await APIUpdateSubscriptionTrial.Request(result.content.subscription.id, activeEmployees, (trialEnd.getTime() / 1000), product);

        }

    }

    this.setState({ loading: false });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze functie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteFunction.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveFunction() {

    this.setState({loading: true});

    if(await this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    this.setState({
        loading: false,
        companyCode: this.state.functionName.replaceAll(/\s/g, '').toLowerCase(),
        validCompanyCode: true,
    });

  }

  async checkValidations() {

    const datePreviousWeek = new Date(new Date().setDate(new Date().getDate() - 7));
    const mondayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 1));
    const sundayPreviousWeek = Constants.dateToString(Constants.getBeginOfWeek(datePreviousWeek, 0));

    if(this.state.functionName === '') {
      this.setState({
        showError: true,
        errorText: __('error_notFilled_companyCode'),
      });
      return false;
    }

    const companyCode = await APICheckStoreCode.Request(this.state.functionName.replaceAll(/\s/g, ''));

    if(companyCode === null) {
        this.setState({
            showError: true,
            errorText: __('error_companyCode'),
        });
      return false;
    }

    let checkoutTrial = false;

    if (Constants.isEmpty(companyCode.bedrijf_proef_datum) === false) {

        const trialEnd = Constants.stringToDate(companyCode.bedrijf_proef_datum);
        const now = await Constants.getDateNow2(companyCode.bedrijf_tijdzone);

        checkoutTrial = trialEnd > now;

    }

    const optionsObject = await APIGET.Request(`SELECT opties_versie`, `FROM Opties`, null, `WHERE opties_id >= 0`, null, null, companyCode.bedrijf_code);
    const checkoutVersion = optionsObject[0].opties_versie;

    const accounts = await APIGET.Request(`SELECT *`, `FROM Info`, `LEFT JOIN InfoExtra ON info2_info_id = info_id`, null, `GROUP BY info_id`, null, companyCode.bedrijf_code);
    const plannedShifts_fallback = await APIGET.Request(`SELECT rooster_id, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${mondayPreviousWeek}' AND '${sundayPreviousWeek}'`, `GROUP BY rooster_info_id`, null);

    this.setState({ 
        codeData: companyCode,
        checkoutTrial: checkoutTrial,
        checkoutVersion: checkoutVersion,

        accounts: accounts,
        plannedShifts_fallback: plannedShifts_fallback,
    });

    return true;

  }

  goBack() {

    if (this.props._fromTut === true) {

        this.props._reloadTutScreen();

    }

    this.props._closeModal();

  }

  getActiveEmployees() {

    let employees = 0;

    for (const acc of this.state.accounts) {
      if (acc.info2_status === '2') {
        continue;
      }
      employees += 1;
    }

    const total = ((this.state.plannedShifts_fallback.length > employees ? this.state.plannedShifts_fallback.length : employees) - 1);

    return total < 0 ? 0 : total;

  }

  render() {

    const activeEmployees = this.getActiveEmployees();

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="functionsModal" className="modal" onClick={(e) => e.target === document.getElementById("zfunctionsModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>            
            <p>{__('misc_setUpPayment')}</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span>&nbsp;</span>
                            {/* <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Hoofdlettergevoelig</span> */}
                        </div>

                        <div className='modal-rowSub2'>

                           <span style={{ color: Colors.color.redFilledIn }}>{this.errorText}</span>

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>{__('login_companycode')}</b></span>
                            {/* <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Hoofdlettergevoelig</span> */}
                        </div>

                        <div className='modal-rowSub2'>

                           <input 
                            disabled={this.state.validCompanyCode}
                            className={'inputField2'}
                            maxLength={100}
                            value={this.state.functionName}
                            onChange={(event) => this.setState({ functionName: event.target.value })}
                            placeholder={__('login_companycode')}
                          />

                        </div>

                    </div>

                    {/* <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Functiekleur</b></span>
                            <div style={{width: '4vw', height: '2vw', marginTop: 10, borderRadius: 5, backgroundColor: this.state.selectedColor}}></div>
                        </div>

                        <div className='modal-rowSub2'>

                           <ChromePicker 
                            //presetColors={[]}
                            width={'100%' - 32}
                            color={this.state.selectedColor}
                            onChange={(color) => this.setState({selectedColor: color.hex})}
                            disableAlpha={true}
                          />

                        </div>

                    </div> */}

                  <div style={{height: '1vw', width: '100%'}} />

                </div>
            
                {/* <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editFunction()}>Opslaan</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveFunction()}>Toevoegen</div>

                    }

                    {this.showError()}

                </div> */}

            </div>

            {this.state.loading === true ?

              <div className='modal-bottom'>

                <div className='modal-loadingButtonText'>
                  <div className="lds-dual-ring-button" />
                </div>

              </div>

            :

              <div className='modal-bottom'>

                {this.showError()}

                <div className='modal-cancelButton' style={{ marginRight: '1.2vw' }} onClick={() => this.closeModal()}>
                    <span>{__('button_back')}</span>
                </div>

                {this.state.validCompanyCode === false ?
                    <div className='modal-normalButton' onClick={() => this.saveFunction()}>
                        <span>{__('button_continue')}</span>
                    </div>
                :
                    <div className='modal-disabledButton'>
                        <span>{__('button_continue')}</span>
                    </div>
                }

                {this.state.validCompanyCode === false ?
                    <div className='modal-disabledButton' style={{ marginLeft: '0.6vw' }}>
                        <span>{__('button_setUp')}</span>
                    </div>
                : 
                    <div 
                        className='modal-submitButton' 
                        style={{ marginLeft: '0.6vw' }}
                        onClick={() => window.Chargebee.registerAgain()} 
                        id="subscribe" 
                        data-cb-type="checkout" 
                        data-cb-item-0={this.state.checkoutVersion === '2' ? this.state.checkoutTrial === true ? "TIMETRACKING_TRIAL-EUR-MONTHLY" : "TIMETRACKING-EUR-MONTHLY" : this.state.checkoutTrial === true ? "SCHEDULING_TRIAL-EUR-MONTHLY" : "SCHEDULING-EUR-MONTHLY"} // checkoutVersion === '1' ? 
                        data-cb-item-0-quantity={activeEmployees}
                        data-cb-item-0-type="plan"
                    >
                        <span>{__('button_setUp')}</span>
                    </div>
                }

              </div>

            }

        </div>

      </div>
    );
  }
  
}

export default SubscriptionModal;