import React from 'react';

import { useParams } from "react-router-dom";
import APIGET from '../../classes/global/APIGET';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

class InfoWebView extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    //const { storecode, serverip } = useParams();
    const { storecode, serverip, teamid } = this.props.match.params;

    this.storecode = storecode;
    this.serverip = serverip;
    this.teamid = teamid;
    
    this.state = {
      loading: true,
      editorValue: {},
    };

  }

  async componentDidMount() {

    let companyInfo = [];
    if (this.teamid === 'null') {
      companyInfo =  await APIGET.webView(`SELECT *`, `FROM BedrijfInformatie`, null, null, null, null, this.storecode, this.serverip);
    } else {
      companyInfo =  await APIGET.webView(`SELECT *`, `FROM BedrijfInformatie`, null, `WHERE bi_team_id = ${this.teamid}`, null, null, this.storecode, this.serverip);
    }

    // EDITOR VALUE
    let _companyInfo = {};
    if (companyInfo.length > 0) {
      _companyInfo = JSON.parse(companyInfo[0].bi_tekst);
    }

    this.setState({ 
      editorValue: _companyInfo,
      loading: false
    });

  }

  render() {

    let editorModules  = {
      toolbar: false,
    };

    return (
      <div style={{backgroundColor: 'white'}}>
        {this.state.loading ?
          <div>
            {/* <div className='loader lds-dual-ring' /> */}
          </div>
        :
          <ReactQuill 
            defaultValue={this.state.editorValue}
            modules={editorModules}
            readOnly={true}
          /> 
        }
      </div>        
    );
  }
  
}

export default InfoWebView;