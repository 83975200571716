import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIAddStoreCode {

    async Request(name, code, address, zip, city, phone, email, owner, sid, status, trial, pid, kvk) {
        
        const hash = CryptoJS.MD5(code + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://login-t01.tiemdo.com/phpcalls/addStoreCode.php/`, {
                method: 'post',
                body: new URLSearchParams(`name=${name}&storecode=${code}&address=${address}&zip=${zip}&city=${city}&phone=${phone}&email=${email}&owner=${owner}&sid=${sid}&status=${status}&trial=${trial}&pid=${pid}&kvk=${kvk}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === "1") {
            return true;

        } else if (sqlData === "0") {
            return false;

        } else {
            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIAddStoreCode());