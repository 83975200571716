/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../startupModal/startupModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import PlannerArrange from '../../pages/options/plannerArrange/plannerArrange';
import FunctionArrange from '../../pages/options/functionArrange/functionArrange';

class SortModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.closeModal = this.props._closeModal;
    this.modalData = this.props._modalData;
    
    this.state = {

        loading: true,
        reloading: false,

        menu: Constants.isEmpty(this.modalData) === false && typeof this.modalData === 'string' ? this.modalData : 'employees',

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    this.setState({loading: false});

  }

  selectMenu(menu) {

    this.setState({
      menu: menu,
    });

  }

  goBack() {

    this.props._closeModal();

  }

  closeModal2() {

    this.setState({
      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="sortModal" className="tutmodal" onClick={(e) => e.target === document.getElementById("zsortModal") ? this.goBack() : null}>

        <div className="tutmodal-content" style={{minHeight: this.state.loading === true ? '15vw' : null, height: '90vh'}}>

          <div className="tutmodal-header">
              <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
              <p>Planner sorteren</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="tutmodal-body2" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
          
              }

              <div className='tutmodal_menu'>

                <div className='employees_menuSubTitle'>
                  <div>Sorteren</div>
                </div>

                <div className={`employees_menuButton ${this.state.menu === 'employees' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('employees')}>
                  <div>Medewerkers</div>
                </div>

                <div className={`employees_menuButton ${this.state.menu === 'jobs' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('jobs')}>
                  <div>Functies</div>
                </div>

              </div>

              <div className='tutmodal_content' style={this.state.menu !== 'general' && this.state.menu !== 'teams' ? {width: 'calc(87% - 0px)'} : null}>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                  </div>

                :

                  <div className='tutmodal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='loader3'>
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                      </div>

                    :

                      null

                    }

                    {this.state.menu === 'employees' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Medewerkers sorteren</div>

                        <PlannerArrange />

                      </div>

                    :
                    this.state.menu === 'jobs' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Functies sorteren</div>

                        <FunctionArrange />
               
                      </div>

                    :

                      null

                    }

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {/* {this.state.loading === true ?

            <div className='tutmodal-bottom'>

              <div className='tutmodal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='tutmodal-bottom'>

              {this.showError()}

                <div className='modal-deleteButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                  <span>Annuleren</span>
                </div>

              {this.state.menu === 'qr' ?
                <div className='modal-normalButton' onClick={() => this.closeModal()}>
                  <span>Voltooien</span>
                </div>
              :
              this.state.generated === false && this.state.menu === 'open' ?
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>QR-code genereren</span>
                </div>
              :
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>Volgende</span>
                </div>
              }

            </div>

          } */}

        </div>

      </div>
    );
  }
  
}

export default SortModal;