import moment from 'moment-timezone';

import packageJson from '../../package.json';

class Data {
  constructor() {
    this.data = {
      logo_version: 2,
      whatsNewVersion: packageJson.version,

      teams: [],
      ownTeams: [],
      chosenTeam: null,
      chosenTeamObj: null,

      hostedPage: null,
      trialBanner: false,

      multiAccounts: [],

      development: true,

      loggedIn: false,

      cryptKey: 'j897gytHJGfr987y6tghjHJGHVYFCytytvcTtFVGyh',
      secretKey: 'juf87rheyuhdsJHGFTujoi876jhbGFvhHGHGFggfb',

      maxEployees: 0,

      accountData: [],
      accountDataExtra: [],

      appOptions: 
      {
        id: 1,
        clock: false,
        brk: 'PLANNER',
        hours: 'PLANNER',
        availability: 'AVAILABILITY',
        open: false,
        iban: false,
        bsn: false,
        opties_smartplan: '0',
        opties_financien: '0',
        opties_urenregistratie2: '0',
        opties_klokPlanning: '0',
        opties_klokAfronden: '0',
        opties_pauzeAfronden: '0',
        opties_urenEigen: '0',
        opties_onbpd: '0',
        opties_onbpw: '0',
        opties_ruilenComp: '0',
        opties_ruilenGeenFunctie: '0',
        opties_verlofDeadline: '0',
        opties_ruilenFunctie: '1',
        opties_pauzeAfronden: '0',
        opties_planBeschikbaar: '1',
        opties_planFunctieSort: '0',
        opties_contractVerlopen: '0',
      },

      activeScreenID: 'home',

      registerID: null,

      utcOffset: moment().utcOffset(),

      storeCode: '',
      serverIP: '',
      serverStatus: 0,
      storeName: '',
      trial: null,
      storeAddress: '',
      storeZip: '',
      storeCity: '',
      storeKvK: '',
      storePhone: '',
      storeMail: '',
      pakketID: 0,
      storeTimezone: '',
      storeCurrency: 'EUR',
      storeLang: '',
      storeLogo: null,
      storeCountry: '',

      companyData: null,

      theme: 0,
      themes: [
        {
          name: 'Standaard',
          color1: '4C4C4C',
          color1_text: 'ffffff',
          color2: '47B7EB', //blue 157dfa //orange FAAF32 //purple BD32FA //newblue 158EFA //neworange 47B7EB
          color3: '',
          color3_text: ''
        }
      ],

      l_i_a: false,

      inTutorial: false,
      tutorialStep: 0,

      canUseNav: true,










      timeData: [{
          value: '00:00',
        }, {
          value: '00:15',
        }, {
          value: '00:30',
        }, {
          value: '00:45',
        }, {
          value: '01:00',
        }, {
          value: '01:15',
        }, {
          value: '01:30',
        }, {
          value: '01:45',
        }, {
          value: '02:00',
        }, {
          value: '02:15',
        }, {
          value: '02:30',
        }, {
          value: '02:45',
        }, {
          value: '03:00',
        }, {
          value: '03:15',
        }, {
          value: '03:30',
        }, {
          value: '03:45',
        }, {
          value: '04:00',
        }, {
          value: '04:15',
        }, {
          value: '04:30',
        }, {
          value: '04:45',
        }, {
          value: '05:00',
        }, {
          value: '05:15',
        }, {
          value: '05:30',
        }, {
          value: '05:45',
        }, {
          value: '06:00',
        }, {
          value: '06:15',
        }, {
          value: '06:30',
        }, {
          value: '06:45',
        }, {
          value: '07:00',
        }, {
          value: '07:15',
        }, {
          value: '07:30',
        }, {
          value: '07:45',
        }, {
          value: '08:00',
        }, {
          value: '08:15',
        }, {
          value: '08:30',
        }, {
          value: '08:45',
        }, {
          value: '09:00',
        }, {
          value: '09:15',
        }, {
          value: '09:30',
        }, {
          value: '09:45',
        }, {
          value: '10:00',
        }, {
          value: '10:15',
        }, {
          value: '10:30',
        }, {
          value: '10:45',
        }, {
          value: '11:00',
        }, {
          value: '11:15',
        }, {
          value: '11:30',
        }, {
          value: '11:45',
        }, {
          value: '12:00',
        }, {
          value: '12:15',
        }, {
          value: '12:30',
        }, {
          value: '12:45',
        }, {
          value: '13:00',
        }, {
          value: '13:15',
        }, {
          value: '13:30',
        }, {
          value: '13:45',
        }, {
          value: '14:00',
        }, {
          value: '14:15',
        }, {
          value: '14:30',
        }, {
          value: '14:45',
        }, {
          value: '15:00',
        }, {
          value: '15:15',
        }, {
          value: '15:30',
        }, {
          value: '15:45',
        }, {
          value: '16:00',
        }, {
          value: '16:15',
        }, {
          value: '16:30',
        }, {
          value: '16:45',
        }, {
          value: '17:00',
        }, {
          value: '17:15',
        }, {
          value: '17:30',
        }, {
          value: '17:45',
        }, {
          value: '18:00',
        }, {
          value: '18:15',
        }, {
          value: '18:30',
        }, {
          value: '18:45',
        }, {
          value: '19:00',
        }, {
          value: '19:15',
        }, {
          value: '19:30',
        }, {
          value: '19:45',
        }, {
          value: '20:00',
        }, {
          value: '20:15',
        }, {
          value: '20:30',
        }, {
          value: '20:45',
        }, {
          value: '21:00',
        }, {
          value: '21:15',
        }, {
          value: '21:30',
        }, {
          value: '21:45',
        }, {
          value: '22:00',
        }, {
          value: '22:15',
        }, {
          value: '22:30',
        }, {
          value: '22:45',
        }, {
          value: '23:00',
        }, {
          value: '23:15',
        }, {
          value: '23:30',
        }, {
          value: '23:45',
      }],
    };
  }

  get(key) {
    return this.data[key];
  }

  set(key, value) {
    this.data[key] = value;
  }
}

// singleton
export default (new Data());