/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../planner/planner.scss';
import './schedule.scss';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGetSchedule from '../../classes/APIGetSchedule';
import Constants from '../../constants/Constants';
import Colors from '../../constants/Colors';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import APIGetSpecificDefaultDayShift from '../../classes/APIGetSpecificDefaultDayShift';
import APIGetUsedDayShifts from '../../classes/APIGetUsedDayShifts';
import ScheduleDownloadName from '../../components/excel/scheduleDownloadName';
import ScheduleDownloadJob from '../../components/excel/scheduleDownloadJob';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';

import { Tooltip } from 'react-tippy';
import APIGetSections from '../../classes/APIGetSections';
import APIGetAllPartSchedules from '../../classes/APIGetAllPartSchedules';
import APIGetAllAbsenceSchedules from '../../classes/APIGetAllAbsenceSchedules';
import APIGET from '../../classes/global/APIGET';

class Schedule extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    this.dayShiftsData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.plannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.availableData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.unavailableData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.notFilledData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.holidayDaysData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    this.sectionsData = [];
    this.holidayDays = [];
    this.functionsData = [];
    this.timesData = [];
    this.users = [];
    this.usedDayShifts = [];

    this.fromPlanner = [];
    this.fromPlannerDay = -1;
    this.toPlanner = [];

    this.teamPlanner = [];
    this.teamUsers = [];

    this.functionsArrange = [];
    
    this.scrollHeight = 0;
    
    this.state = {

      loading: true,
      reloading: false,

      week: 0,
      year: 0,

      mondayDate: '',
      tuesdayDate: '',
      wednesdayDate: '',
      thursdayDate: '',
      fridayDate: '',
      saturdayDate: '',
      sundayDate: '',

      selectedDate: new Date(),

      mode: 'MODAL',

      selectedBar: '',

      toPublish: 0,

      showModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      section: null,
      
      reloadScreenMode: 0,

      dayShiftsDataState: [],

      showModeMenu: false,

      team: Data.data.teams.length > 1 && Data.data.ownTeams.length > 0 ? Data.data.chosenTeamObj.team_id : null,

      partSchedules: {},
      absenceSchedules: {},

      showSettingsMenu: false,

    };

    //componentWillMount here:

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.compWillMount();

  }

  compWillMount() {

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    await this.getData();

    this.setState({loading: false});

  }

  async getNextData(next) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({loading: true});

    this.WEEK = next === true ? this.state.week  + 1 : this.state.week - 1;
    this.YEAR = this.state.year;

    if(this.WEEK > this.weeksInYear(this.YEAR)) {
      this.WEEK = 1;
      this.YEAR = this.YEAR + 1;
    }
    if(this.WEEK < 1) {
      this.YEAR = this.YEAR - 1;
      this.WEEK = this.weeksInYear(this.YEAR);
    }

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    this.setState({
      week: this.WEEK,
      year: this.YEAR,
      mondayDate: Constants.dateToString(mondayDate),
      tuesdayDate: Constants.dateToString(tuesdayDate),
      wednesdayDate: Constants.dateToString(wednesdayDate),
      thursdayDate: Constants.dateToString(thursdayDate),
      fridayDate: Constants.dateToString(fridayDate),
      saturdayDate: Constants.dateToString(saturdayDate),
      sundayDate: Constants.dateToString(sundayDate),
      selectedBar: '',
    });

    this.fromPlanner = [];
    this.fromPlannerDay = -1;
    this.toPlanner = [];

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    await this.getFunctions();

    this.setState({loading: false});

  }

  async getCalendarDateData(week, year) {

    if(this.state.loading === true) {
      return;
    }

    this.setState({loading: true});

    this.WEEK = week;
    this.YEAR = year;

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    this.setState({
      week: this.WEEK,
      year: this.YEAR,
      mondayDate: Constants.dateToString(mondayDate),
      tuesdayDate: Constants.dateToString(tuesdayDate),
      wednesdayDate: Constants.dateToString(wednesdayDate),
      thursdayDate: Constants.dateToString(thursdayDate),
      fridayDate: Constants.dateToString(fridayDate),
      saturdayDate: Constants.dateToString(saturdayDate),
      sundayDate: Constants.dateToString(sundayDate),
    });

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    this.setState({loading: false});

  }

  async getData() {

    this.WEEK = this.getWeekNumber();
    this.YEAR = this.getWeekYear();

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    this.setState({
      week: this.getWeekNumber(),
      year: this.getWeekYear(),
      mondayDate: Constants.dateToString(mondayDate),
      tuesdayDate: Constants.dateToString(tuesdayDate),
      wednesdayDate: Constants.dateToString(wednesdayDate),
      thursdayDate: Constants.dateToString(thursdayDate),
      fridayDate: Constants.dateToString(fridayDate),
      saturdayDate: Constants.dateToString(saturdayDate),
      sundayDate: Constants.dateToString(sundayDate),
    });

    this.functionsArrange = await APIGET.Request(`SELECT fv_functie_id, fv_index`, `FROM FunctieVolgorde`, null, null, null, `ORDER BY fv_index`);

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    await this.getSections();
    await this.getFunctions();

  }

  async getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate) {

    // if(Data.data.appOptions.open === true) {

    //   await this.getUsedDayShifts(Constants.dateToString(mondayDate), Constants.dateToString(sundayDate));

    //   await this.getDayShifts(1, Constants.dateToString(mondayDate));
    //   await this.getDayShifts(2, Constants.dateToString(tuesdayDate));
    //   await this.getDayShifts(3, Constants.dateToString(wednesdayDate));
    //   await this.getDayShifts(4, Constants.dateToString(thursdayDate));
    //   await this.getDayShifts(5, Constants.dateToString(fridayDate));
    //   await this.getDayShifts(6, Constants.dateToString(saturdayDate));
    //   await this.getDayShifts(0, Constants.dateToString(sundayDate));

    // }

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

      this.teamPlanner = [];

      const teamPlannerMonday = await APIGetTeamPlanner.Request(Constants.dateToString(mondayDate));
      const teamPlannerTuesday = await APIGetTeamPlanner.Request(Constants.dateToString(tuesdayDate));
      const teamPlannerWednesday = await APIGetTeamPlanner.Request(Constants.dateToString(wednesdayDate));
      const teamPlannerThursday = await APIGetTeamPlanner.Request(Constants.dateToString(thursdayDate));
      const teamPlannerFriday = await APIGetTeamPlanner.Request(Constants.dateToString(fridayDate));
      const teamPlannerSaturday = await APIGetTeamPlanner.Request(Constants.dateToString(saturdayDate));
      const teamPlannerSunday = await APIGetTeamPlanner.Request(Constants.dateToString(sundayDate));

      for (const tp of teamPlannerMonday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerTuesday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerWednesday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerThursday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerFriday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerSaturday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerSunday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }

      this.teamUsers = await APIGetTeamUsers.Request();

    }

    // GET BIJZONDERHEDEN
    const partSchedules = await APIGetAllPartSchedules.Request(Constants.dateToString(mondayDate), Constants.dateToString(sundayDate));
    const partSchedulesObj = Constants.convertToObject(partSchedules, 'bijzr_rooster_id');

    // GET ABSENCES FROM SCHEDULES
    const absenceSchedules = await APIGetAllAbsenceSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate));
    const absenceSchedulesObj = Constants.convertToObject(absenceSchedules, 'rooster_id');

    this.setState({ 
      partSchedules: partSchedulesObj,
      absenceSchedules: absenceSchedulesObj,
    });

    await this.getPlannerData(1, Constants.dateToString(mondayDate));
    await this.getPlannerData(2, Constants.dateToString(tuesdayDate));
    await this.getPlannerData(3, Constants.dateToString(wednesdayDate));
    await this.getPlannerData(4, Constants.dateToString(thursdayDate));
    await this.getPlannerData(5, Constants.dateToString(fridayDate));
    await this.getPlannerData(6, Constants.dateToString(saturdayDate));
    await this.getPlannerData(0, Constants.dateToString(sundayDate));

    // await this.getAvailableData(1, Constants.dateToString(mondayDate));
    // await this.getAvailableData(2, Constants.dateToString(tuesdayDate));
    // await this.getAvailableData(3, Constants.dateToString(wednesdayDate));
    // await this.getAvailableData(4, Constants.dateToString(thursdayDate));
    // await this.getAvailableData(5, Constants.dateToString(fridayDate));
    // await this.getAvailableData(6, Constants.dateToString(saturdayDate));
    // await this.getAvailableData(0, Constants.dateToString(sundayDate));

    // await this.getNotFilledData(1, Constants.dateToString(mondayDate));
    // await this.getNotFilledData(2, Constants.dateToString(tuesdayDate));
    // await this.getNotFilledData(3, Constants.dateToString(wednesdayDate));
    // await this.getNotFilledData(4, Constants.dateToString(thursdayDate));
    // await this.getNotFilledData(5, Constants.dateToString(fridayDate));
    // await this.getNotFilledData(6, Constants.dateToString(saturdayDate));
    // await this.getNotFilledData(0, Constants.dateToString(sundayDate));

    // await this.getUnavailableData(1, Constants.dateToString(mondayDate));
    // await this.getUnavailableData(2, Constants.dateToString(tuesdayDate));
    // await this.getUnavailableData(3, Constants.dateToString(wednesdayDate));
    // await this.getUnavailableData(4, Constants.dateToString(thursdayDate));
    // await this.getUnavailableData(5, Constants.dateToString(fridayDate));
    // await this.getUnavailableData(6, Constants.dateToString(saturdayDate));
    // await this.getUnavailableData(0, Constants.dateToString(sundayDate));

    //this.fixDayShiftData();

  }

  fixDayShiftData() {

    let tempData = [];
    let tempPlannerIDData = [];
    let tempShiftIDData = [];

    // MONDAY

    for(let key in this.plannerData.mon) {

      for(let key2 in this.dayShiftsData.mon) {

        if(this.plannerData.mon[key].rooster_begin === this.dayShiftsData.mon[key2].dagdienst_begin
          && this.plannerData.mon[key].rooster_eind === this.dayShiftsData.mon[key2].dagdienst_eind
          && this.plannerData.mon[key].functie_id === this.dayShiftsData.mon[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.mon[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.mon[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.mon[key2]);
              tempPlannerIDData.push(this.plannerData.mon[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.mon[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.mon, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // TUESDAY

    for(let key in this.plannerData.tue) {

      for(let key2 in this.dayShiftsData.tue) {

        if(this.plannerData.tue[key].rooster_begin === this.dayShiftsData.tue[key2].dagdienst_begin
          && this.plannerData.tue[key].rooster_eind === this.dayShiftsData.tue[key2].dagdienst_eind
          && this.plannerData.tue[key].functie_id === this.dayShiftsData.tue[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.tue[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.tue[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.tue[key2]);
              tempPlannerIDData.push(this.plannerData.tue[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.tue[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.tue, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // WEDNESDAY

    for(let key in this.plannerData.wed) {

      for(let key2 in this.dayShiftsData.wed) {

        if(this.plannerData.wed[key].rooster_begin === this.dayShiftsData.wed[key2].dagdienst_begin
          && this.plannerData.wed[key].rooster_eind === this.dayShiftsData.wed[key2].dagdienst_eind
          && this.plannerData.wed[key].functie_id === this.dayShiftsData.wed[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.wed[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.wed[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.wed[key2]);
              tempPlannerIDData.push(this.plannerData.wed[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.wed[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.wed, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // THURSDAY

    for(let key in this.plannerData.thu) {

      for(let key2 in this.dayShiftsData.thu) {

        if(this.plannerData.thu[key].rooster_begin === this.dayShiftsData.thu[key2].dagdienst_begin
          && this.plannerData.thu[key].rooster_eind === this.dayShiftsData.thu[key2].dagdienst_eind
          && this.plannerData.thu[key].functie_id === this.dayShiftsData.thu[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.thu[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.thu[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.thu[key2]);
              tempPlannerIDData.push(this.plannerData.thu[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.thu[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.thu, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // FRIDAY

    for(let key in this.plannerData.fri) {

      for(let key2 in this.dayShiftsData.fri) {

        if(this.plannerData.fri[key].rooster_begin === this.dayShiftsData.fri[key2].dagdienst_begin
          && this.plannerData.fri[key].rooster_eind === this.dayShiftsData.fri[key2].dagdienst_eind
          && this.plannerData.fri[key].functie_id === this.dayShiftsData.fri[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.fri[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.fri[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.fri[key2]);
              tempPlannerIDData.push(this.plannerData.fri[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.fri[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.fri, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // SATURDAY

    for(let key in this.plannerData.sat) {

      for(let key2 in this.dayShiftsData.sat) {

        if(this.plannerData.sat[key].rooster_begin === this.dayShiftsData.sat[key2].dagdienst_begin
          && this.plannerData.sat[key].rooster_eind === this.dayShiftsData.sat[key2].dagdienst_eind
          && this.plannerData.sat[key].functie_id === this.dayShiftsData.sat[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.sat[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.sat[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.sat[key2]);
              tempPlannerIDData.push(this.plannerData.sat[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.sat[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.sat, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // SUNDAY

    for(let key in this.plannerData.sun) {

      for(let key2 in this.dayShiftsData.sun) {

        if(this.plannerData.sun[key].rooster_begin === this.dayShiftsData.sun[key2].dagdienst_begin
          && this.plannerData.sun[key].rooster_eind === this.dayShiftsData.sun[key2].dagdienst_eind
          && this.plannerData.sun[key].functie_id === this.dayShiftsData.sun[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.sun[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.sun[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.sun[key2]);
              tempPlannerIDData.push(this.plannerData.sun[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.sun[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.sun, tempData[key]);

    }

  }

  async getSpecificDateDBData(date) {

    // if(Data.data.appOptions.open === true) {

    //   await this.getUsedDayShifts(this.state.mondayDate, this.state.sundayDate);

    //   await this.getDayShifts(date.getDay(), Constants.dateToString(date));

    // }

    await this.getPlannerData(date.getDay(), Constants.dateToString(date));

    // await this.getAvailableData(date.getDay(), Constants.dateToString(date));

    // await this.getNotFilledData(date.getDay(), Constants.dateToString(date));

    // await this.getUnavailableData(date.getDay(), Constants.dateToString(date));

    //this.fixDayShiftData();

  }

  getWeekNumber() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getWeekNumber2(today) {
    let date = today;
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getWeekYear() {
    let date = new Date();
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
  }

  weeksInYear(year) {
    let month = 11, day = 31, week = 0;
  
    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
      const d = new Date(year, month, day--);
      week = this.alt(d)[1];
    } while (week === 1);
  
    return week;
  }

  alt(d) {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0,0,0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(),0,1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
    // Return array of year and week number
    return [d.getFullYear(), weekNo];
}

  getMonday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
  }

  getTuesday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 1);
    return ISOweekStart;
  }

  getWednesday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 2);
    return ISOweekStart;
  }

  getThursday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 3);
    return ISOweekStart;
  }

  getFriday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 4);
    return ISOweekStart;
  }

  getSaturday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 5);
    return ISOweekStart;
  }

  getSunday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart.setDate(ISOweekStart.getDate() + 6);
    return ISOweekStart;
  }

  async getSections() {

    const sectionsData = await APIGetSections.Request();

    if(sectionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.sectionsData = sectionsData;

  }

  async getFunctions() {

    const functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.functionsData = functionsData;
    
    if(this.state.section !== null) {

      let functionDataSection = [];

      for(const _function of functionsData) {

        if(parseInt(_function.functie_afdeling_id) === this.state.section) {
          functionDataSection.push(_function);
        }

      }

      this.functionsData = functionDataSection;

    }

     // new sort
     if (this.functionsArrange.length > 0) {

      for (const arr of this.functionsArrange) {

        for (const func of this.functionsData) {

          if (func.functie_id === arr.fv_functie_id && Constants.objectArrayContainsKey(this.functionsData, 'functie_id', arr.fv_functie_id)) {
            let index = this.functionsData.indexOf(func);
            this.functionsData.splice(index, 1);
            this.functionsData.splice(parseInt(arr.fv_index), 0, func);
          }

        }

      }

    }

    //this.functionsData = JSON.parse(localStorage.getItem('@functionSort')) !== this.functionsData ? this.functionsData : JSON.parse(localStorage.getItem('@functionSort'));

    //Constants.swapInArray(this.functionsData, this.functionsData.indexOf(this.functionsData[3]), this.functionsData.indexOf(this.functionsData[3 - 1]));

    //localStorage.setItem('@functionSort', JSON.stringify(this.functionsData));

  }

  async getDayShifts(weekDay, date) {

    const dayNumber = Constants.stringToDate(date).getDay();

    if(weekDay === 0) {
      this.dayShiftsData.sun = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.sun) {
            if(this.dayShiftsData.sun[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.sun[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.sun[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.sun, this.dayShiftsData.sun[key]);
              break;
            }
          }
        }
      }

    }
    if(weekDay === 1) {
      this.dayShiftsData.mon = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.mon) {
            if(this.dayShiftsData.mon[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.mon[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.mon[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.mon, this.dayShiftsData.mon[key]);
              break;
            }
          }
        }
      }

    }
    if(weekDay === 2) {
      this.dayShiftsData.tue = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.tue) {
            if(this.dayShiftsData.tue[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.tue[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.tue[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.tue, this.dayShiftsData.tue[key]);
              break;
            }
          }
        }
      }

    }
    if(weekDay === 3) {
      this.dayShiftsData.wed = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.wed) {
            if(this.dayShiftsData.wed[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.wed[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.wed[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.wed, this.dayShiftsData.wed[key]);
              break;
            }
          }
        }
      }

    }
    if(weekDay === 4) {
      this.dayShiftsData.thu = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.thu) {
            if(this.dayShiftsData.thu[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.thu[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.thu[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.thu, this.dayShiftsData.thu[key]);
              break;
            }
          }
        }
      }

    }
    if(weekDay === 5) {
      this.dayShiftsData.fri = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.fri) {
            if(this.dayShiftsData.fri[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.fri[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.fri[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.fri, this.dayShiftsData.fri[key]);
              break;
            }
          }
        }
      }

    }
    if(weekDay === 6) {
      this.dayShiftsData.sat = await APIGetSpecificDefaultDayShift.Request(dayNumber, date);

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {
          for (let key in this.dayShiftsData.sat) {
            if(this.dayShiftsData.sat[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.sat[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.sat[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
              Constants.removeFromArray(this.dayShiftsData.sat, this.dayShiftsData.sat[key]);
              break;
            }
          }
        }
      }

    }

      if(this.dayShiftsData.sun === 'error') {

          this.dayShiftsData = [];
          
          this.setState({
              errorScreen: true,
              loading: false,
          });
          return;
      }
      
      this.setState({dayShiftsDataState: this.dayShiftsData});

  }

  async getUsedDayShifts(date1, date2) {

    const usedDayShifts = await APIGetUsedDayShifts.Request(date1, date2);

    if(usedDayShifts === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.usedDayShifts = usedDayShifts;

  }

  changeSectionTab(section) {

    if(this.state.section === section) {
      return;
    }

    this.setState({
      section: section,
    });

    setTimeout(async() => {

      await this.getFunctions();
      
      await this.reloadScreen2FullScreen();
      
    }, 100);

  }

  getSectionTabs() {

    let bars = [];

    bars.push(

      <div className='planner_sectionTab' style={{flex: 0.3, color: '#282828', cursor: 'default'}}>

        Afdeling:

      </div>

    );

    if(this.state.section === null) {

      bars.push(

        <div className='planner_sectionTab' style={{color: '#47B7EB'}}>

          Alle

        </div>

      );

    } else {

      bars.push(

        <div className='planner_sectionTab' onClick={() => this.changeSectionTab(null)}>

          Alle

        </div>

      );

    }

    for(const tab of this.sectionsData) {

      if(this.state.section === parseInt(tab.afdeling_id)) {

        bars.push(

          <div className='planner_sectionTab' style={{color: '#47B7EB'}}>
  
            {tab.afdeling_naam}
  
          </div>
  
        );

      } else {

        bars.push(

          <div className='planner_sectionTab' onClick={() => this.changeSectionTab(parseInt(tab.afdeling_id))}>
  
            {tab.afdeling_naam}
  
          </div>
  
        );

      }

    }

    return bars;

  }

  setTeam(team) {

    this.setState({ team: team.team_id });

  }

  async getPlannerData(weekDay, date) {

    if(weekDay === 0) {
      this.plannerData.sun = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.sun = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.sun, parseInt(this.state.team));
      }
    }
    if(weekDay === 1) {
      this.plannerData.mon = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.mon = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.mon, parseInt(this.state.team));
      }
    }
    if(weekDay === 2) {
      this.plannerData.tue = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.tue = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.tue, parseInt(this.state.team));
      }
    }
    if(weekDay === 3) {
      this.plannerData.wed = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.wed = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.wed, parseInt(this.state.team));
      }
    }
    if(weekDay === 4) {
      this.plannerData.thu = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.thu = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.thu, parseInt(this.state.team));
      }
    }
    if(weekDay === 5) {
      this.plannerData.fri = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.fri = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.fri, parseInt(this.state.team));
      }
    }
    if(weekDay === 6) {
      this.plannerData.sat = await APIGetSchedule.Request(date);

      if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
        this.plannerData.sat = Constants.getTeamPlannerT(this.teamPlanner, this.plannerData.sat, parseInt(this.state.team));
      }
    }

      if(this.plannerData === 'error') {

          this.plannerData = [];
          
          this.setState({
              errorScreen: true,
              loading: false,
          });
          return;
      }
      
      this.setState({plannerDataState: this.plannerData});

  }

//   async getAvailableData(weekDay, date) {

//     if(weekDay === 0) {
//       this.availableData.sun = await APIGetAllAvailabilities.Request(date);
//     }
//     if(weekDay === 1) {
//       this.availableData.mon = await APIGetAllAvailabilities.Request(date);
//     }
//     if(weekDay === 2) {
//       this.availableData.tue = await APIGetAllAvailabilities.Request(date);
//     }
//     if(weekDay === 3) {
//       this.availableData.wed = await APIGetAllAvailabilities.Request(date);
//     }
//     if(weekDay === 4) {
//       this.availableData.thu = await APIGetAllAvailabilities.Request(date);
//     }
//     if(weekDay === 5) {
//       this.availableData.fri = await APIGetAllAvailabilities.Request(date);
//     }
//     if(weekDay === 6) {
//       this.availableData.sat = await APIGetAllAvailabilities.Request(date);
//     }

//     if(this.availableData === 'error') {

//         this.availableData = [];
        
//         this.setState({
//             errorScreen: true,
//             loading: false,
//         });
//         return;
//     }
    
//     this.setState({availableDataState: this.availableData});

//   }

//   async getUnavailableData(weekDay, date) {

//       if(weekDay === 0) {
//         this.unavailableData.sun = await APIGetAllUnavailabilities.Request(date);
//       }
//       if(weekDay === 1) {
//         this.unavailableData.mon = await APIGetAllUnavailabilities.Request(date);
//       }
//       if(weekDay === 2) {
//         this.unavailableData.tue = await APIGetAllUnavailabilities.Request(date);
//       }
//       if(weekDay === 3) {
//         this.unavailableData.wed = await APIGetAllUnavailabilities.Request(date);
//       }
//       if(weekDay === 4) {
//         this.unavailableData.thu = await APIGetAllUnavailabilities.Request(date);
//       }
//       if(weekDay === 5) {
//         this.unavailableData.fri = await APIGetAllUnavailabilities.Request(date);
//       }
//       if(weekDay === 6) {
//         this.unavailableData.sat = await APIGetAllUnavailabilities.Request(date);
//       }

//       if(this.unavailableData === 'error') {

//           this.unavailableData = [];
          
//           this.setState({
//               errorScreen: true,
//               loading: false,
//           });
//           return;
//       }
      
//       this.setState({unavailableDataState: this.unavailableData});

//   }

//   async getNotFilledData(weekDay, date) {

//     if(weekDay === 0) {
//       this.notFilledData.sun = await APIGetAllNotFilled.Request(date);
//     }
//     if(weekDay === 1) {
//       this.notFilledData.mon = await APIGetAllNotFilled.Request(date);
//     }
//     if(weekDay === 2) {
//       this.notFilledData.tue = await APIGetAllNotFilled.Request(date);
//     }
//     if(weekDay === 3) {
//       this.notFilledData.wed = await APIGetAllNotFilled.Request(date);
//     }
//     if(weekDay === 4) {
//       this.notFilledData.thu = await APIGetAllNotFilled.Request(date);
//     }
//     if(weekDay === 5) {
//       this.notFilledData.fri = await APIGetAllNotFilled.Request(date);
//     }
//     if(weekDay === 6) {
//       this.notFilledData.sat = await APIGetAllNotFilled.Request(date);
//     }

//       if(this.notFilledData === 'error') {

//           this.notFilledData = [];
          
//           this.setState({
//               errorScreen: true,
//               loading: false,
//           });
//           return;
//       }
      
//       this.setState({notFilledDataState: this.notFilledData});

//   }

//   async getAllHolidays() {

//     let holidayDays = await APIGetAllHolidays.Request();

//     if(holidayDays === 'error') {

//         holidayDays = [];

//         this.setState({ 
//             errorScreen: true,
//             loading: false,
//         });

//         return;
//     }

//     if(holidayDays != null && holidayDays.length > 0) {

//         let allHolidayDays = [];
        
//         for(let key in holidayDays) { //iterate through holidays ranges from DB

//             if(holidayDays[key].vakantie_geaccepteerd === '1') {

//                 let currentDate = new Date(holidayDays[key].vakantie_begin);
//                 let endDate = new Date(holidayDays[key].vakantie_eind);

//                 currentDate.setHours(12,0,0,0);
//                 endDate.setHours(12,0,0,0);

//                 while(currentDate <= endDate) {

//                     const userFullName = `${holidayDays[key].info_voornaam} ${(holidayDays[key].info_tussenvoegsel !== null && holidayDays[key].info_tussenvoegsel !== '') ? holidayDays[key].info_tussenvoegsel + " " + holidayDays[key].info_achternaam.charAt(0) : holidayDays[key].info_achternaam.charAt(0)}.`;

//                     allHolidayDays.push({
//                         date: new Date(currentDate),
//                         name: userFullName,
//                         comment: holidayDays[key].vakantie_opmerking,
//                         end: holidayDays[key].vakantie_eind,
//                     });

//                     currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

//                 }

//             }

//         }

//         this.holidayDays = allHolidayDays;

//         for(let key in this.holidayDays) {

//           const weekDay = this.holidayDays[key].date.getDay();

//           if(weekDay === 0) {
//             this.holidayDaysData.sun.push(this.holidayDays[key]);
//           }
//           if(weekDay === 1) {
//             this.holidayDaysData.mon.push(this.holidayDays[key]);
//           }
//           if(weekDay === 2) {
//             this.holidayDaysData.tue.push(this.holidayDays[key]);
//           }
//           if(weekDay === 3) {
//             this.holidayDaysData.wed.push(this.holidayDays[key]);
//           }
//           if(weekDay === 4) {
//             this.holidayDaysData.thu.push(this.holidayDays[key]);
//           }
//           if(weekDay === 5) {
//             this.holidayDaysData.fri.push(this.holidayDays[key]);
//           }
//           if(weekDay === 6) {
//             this.holidayDaysData.sat.push(this.holidayDays[key]);
//           }

//         }

//     } else {

//         this.holidayDaysData = [];

//     }

//   }

  async reloadScreen(date) {

    //this.scrollHeight = window.scrollY;

    //this.setState({loading: true});

    this.setState({reloading: true});

    // this.WEEK = this.state.week;
    // this.YEAR = this.state.year;

    // const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    // const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    // const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    // const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    // const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    // const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    // const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    const dateObject = new Date(date);

    await this.getSpecificDateDBData(dateObject);

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];

    //this.setState({loading: false});

    this.setState({ reloading: false, selectedBar: '' });

    //window.scrollBy(0, this.scrollHeight);

  }

  async reloadScreen2() {

    this.setState({reloading: true});

    this.WEEK = this.state.week;
    this.YEAR = this.state.year;

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];

    this.setState({reloading: false, selectedBar: ''});

  }

  async reloadScreen2FullScreen() {

    this.setState({loading: true});

    this.WEEK = this.state.week;
    this.YEAR = this.state.year;

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];

    this.setState({loading: false, selectedBar: ''});

  }

//   getAvailableBars() {

//     let availableDataDay = null;
//     let availableDataDayBars = null;

//     let availableBars = {
//       mon: [],
//       tue: [],
//       wed: [],
//       thu: [],
//       fri: [],
//       sat: [],
//       sun: [],
//     };

//     let maxNotFilled = 0;

//     if(maxNotFilled < this.availableData.mon.length) {
//       maxNotFilled = this.availableData.mon.length;
//     }
//     if(maxNotFilled < this.availableData.tue.length) {
//       maxNotFilled = this.availableData.tue.length;
//     }
//     if(maxNotFilled < this.availableData.wed.length) {
//       maxNotFilled = this.availableData.wed.length;
//     }
//     if(maxNotFilled < this.availableData.thu.length) {
//       maxNotFilled = this.availableData.thu.length;
//     }
//     if(maxNotFilled < this.availableData.fri.length) {
//       maxNotFilled = this.availableData.fri.length;
//     }
//     if(maxNotFilled < this.availableData.sat.length) {
//       maxNotFilled = this.availableData.sat.length;
//     }
//     if(maxNotFilled < this.availableData.sun.length) {
//       maxNotFilled = this.availableData.sun.length;
//     }

//     for(let i = 0; i < 7; i++) {

//       if(i === 0) {
//         availableDataDay = this.availableData.mon;
//         availableDataDayBars = availableBars.mon;
//         availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.greenFilledIn, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>Beschikbaar</div>)
//       }
//       if(i === 1) {
//         availableDataDay = this.availableData.tue;
//         availableDataDayBars = availableBars.tue;
//       }
//       if(i === 2) {
//         availableDataDay = this.availableData.wed;
//         availableDataDayBars = availableBars.wed;
//       }
//       if(i === 3) {
//         availableDataDay = this.availableData.thu;
//         availableDataDayBars = availableBars.thu;
//       }
//       if(i === 4) {
//         availableDataDay = this.availableData.fri;
//         availableDataDayBars = availableBars.fri;
//       }
//       if(i === 5) {
//         availableDataDay = this.availableData.sat;
//         availableDataDayBars = availableBars.sat;
//       }
//       if(i === 6) {
//         availableDataDay = this.availableData.sun;
//         availableDataDayBars = availableBars.sun;
//         availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.greenFilledIn, borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
//       }

//       if(i !== 0 && i !== 6) {
//         availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.greenFilledIn}}></div>);
//       }

//       for(let key in availableDataDay) {

//         const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

//         availableDataDayBars.push(
  
//           <div key={i + 'a' + key} 
//             draggable='true' 
//             onDragStart={(event) => this.onDragStartAvailable(event, i === 6 ? 0 : i + 1, key)}
//             onClick={() => this.onClickAvailable(i, key)}
//             className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox2 blink' : 'planner_touchBox2'}
//             style={{borderColor: Colors.color.greenFilledIn}}
//           >
  
//             <div className='planner_timeContainer'>
  
//               {(availableDataDay[key].beschikbaar_begin === '00:00:00' && availableDataDay[key].beschikbaar_eind === '00:00:00') ?
                                                
//                 <div>Hele dag</div>

//               :

//                 <div>{availableDataDay[key].beschikbaar_begin.substring(0,5)} - {availableDataDay[key].beschikbaar_eind.substring(0,5)}</div>

//               }
  
//             </div>
  
//             <div className='planner_nameContainer'>
  
//               <div className='planner_nameText'>{userFullName}</div>
  
//             </div>
  
//           </div>
  
//         );
  
//       }

//       let j = availableDataDay.length;
//       while(j < maxNotFilled) {
//         availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
//         j++;
//       }

//     }

//     return availableBars;

//   }

//   getNotFilledBars() {

//     let notFilledDataDay = null;
//     let notFilledDataDayBars = null;

//     let notFilledBars = {
//       mon: [],
//       tue: [],
//       wed: [],
//       thu: [],
//       fri: [],
//       sat: [],
//       sun: [],
//     };

//     let maxNotFilled = 0;

//     if(maxNotFilled < this.notFilledData.mon.length) {
//       maxNotFilled = this.notFilledData.mon.length;
//     }
//     if(maxNotFilled < this.notFilledData.tue.length) {
//       maxNotFilled = this.notFilledData.tue.length;
//     }
//     if(maxNotFilled < this.notFilledData.wed.length) {
//       maxNotFilled = this.notFilledData.wed.length;
//     }
//     if(maxNotFilled < this.notFilledData.thu.length) {
//       maxNotFilled = this.notFilledData.thu.length;
//     }
//     if(maxNotFilled < this.notFilledData.fri.length) {
//       maxNotFilled = this.notFilledData.fri.length;
//     }
//     if(maxNotFilled < this.notFilledData.sat.length) {
//       maxNotFilled = this.notFilledData.sat.length;
//     }
//     if(maxNotFilled < this.notFilledData.sun.length) {
//       maxNotFilled = this.notFilledData.sun.length;
//     }

//     for(let i = 0; i < 7; i++) {

//       if(i === 0) {
//         notFilledDataDay = this.notFilledData.mon;
//         notFilledDataDayBars = notFilledBars.mon;
//         notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.greyNotFilled, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>Niet ingevuld</div>)
//       }
//       if(i === 1) {
//         notFilledDataDay = this.notFilledData.tue;
//         notFilledDataDayBars = notFilledBars.tue;
//       }
//       if(i === 2) {
//         notFilledDataDay = this.notFilledData.wed;
//         notFilledDataDayBars = notFilledBars.wed;
//       }
//       if(i === 3) {
//         notFilledDataDay = this.notFilledData.thu;
//         notFilledDataDayBars = notFilledBars.thu;
//       }
//       if(i === 4) {
//         notFilledDataDay = this.notFilledData.fri;
//         notFilledDataDayBars = notFilledBars.fri;
//       }
//       if(i === 5) {
//         notFilledDataDay = this.notFilledData.sat;
//         notFilledDataDayBars = notFilledBars.sat;
//       }
//       if(i === 6) {
//         notFilledDataDay = this.notFilledData.sun;
//         notFilledDataDayBars = notFilledBars.sun;
//         notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.greyNotFilled, borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
//       }

//       if(i !== 0 && i !== 6) {
//         notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.greyNotFilled}}></div>);
//       }

//       for(let key in notFilledDataDay) {

//         const userFullName = `${notFilledDataDay[key].info_voornaam} ${(notFilledDataDay[key].info_tussenvoegsel !== null && notFilledDataDay[key].info_tussenvoegsel !== '') ? notFilledDataDay[key].info_tussenvoegsel + " " + notFilledDataDay[key].info_achternaam.charAt(0) : notFilledDataDay[key].info_achternaam.charAt(0)}.`;
  
//         notFilledDataDayBars.push(
  
//           <div key={i + 'n' + key} 
//             draggable='true' 
//             onDragStart={(event) => this.onDragStartNotFilled(event, i === 6 ? 0 : i + 1, key)}
//             onClick={() => this.onClickNotFilled(i, key)}
//             className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'planner_touchBox2 blink' : 'planner_touchBox2'}
//             style={{borderColor: Colors.color.greyNotFilled}}
//           >
  
//             <div className='planner_timeContainer'>
  
//               <div>n.v.t.</div>
  
//             </div>
  
//             <div className='planner_nameContainer'>
  
//               <div className='planner_nameText'>{userFullName}</div>
  
//             </div>
  
//           </div>
  
//         );
  
//       }

//       let j = notFilledDataDay.length;
//       while(j < maxNotFilled) {
//         notFilledDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
//         j++;
//       }

//     }

//     return notFilledBars;

//   }

//   getUnavailableBars() {

//     let unavailableDataDay = null;
//     let unavailableDataDayBars = null;

//     let unavailableBars = {
//       mon: [],
//       tue: [],
//       wed: [],
//       thu: [],
//       fri: [],
//       sat: [],
//       sun: [],
//     };

//     let maxNotFilled = 0;

//     if(maxNotFilled < this.unavailableData.mon.length) {
//       maxNotFilled = this.unavailableData.mon.length;
//     }
//     if(maxNotFilled < this.unavailableData.tue.length) {
//       maxNotFilled = this.unavailableData.tue.length;
//     }
//     if(maxNotFilled < this.unavailableData.wed.length) {
//       maxNotFilled = this.unavailableData.wed.length;
//     }
//     if(maxNotFilled < this.unavailableData.thu.length) {
//       maxNotFilled = this.unavailableData.thu.length;
//     }
//     if(maxNotFilled < this.unavailableData.fri.length) {
//       maxNotFilled = this.unavailableData.fri.length;
//     }
//     if(maxNotFilled < this.unavailableData.sat.length) {
//       maxNotFilled = this.unavailableData.sat.length;
//     }
//     if(maxNotFilled < this.unavailableData.sun.length) {
//       maxNotFilled = this.unavailableData.sun.length;
//     }

//     for(let i = 0; i < 7; i++) {

//       if(i === 0) {
//         unavailableDataDay = this.unavailableData.mon;
//         unavailableDataDayBars = unavailableBars.mon;
//         unavailableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.redFilledIn, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>Onbeschikbaar</div>)
//       }
//       if(i === 1) {
//         unavailableDataDay = this.unavailableData.tue;
//         unavailableDataDayBars = unavailableBars.tue;
//       }
//       if(i === 2) {
//         unavailableDataDay = this.unavailableData.wed;
//         unavailableDataDayBars = unavailableBars.wed;
//       }
//       if(i === 3) {
//         unavailableDataDay = this.unavailableData.thu;
//         unavailableDataDayBars = unavailableBars.thu;
//       }
//       if(i === 4) {
//         unavailableDataDay = this.unavailableData.fri;
//         unavailableDataDayBars = unavailableBars.fri;
//       }
//       if(i === 5) {
//         unavailableDataDay = this.unavailableData.sat;
//         unavailableDataDayBars = unavailableBars.sat;
//       }
//       if(i === 6) {
//         unavailableDataDay = this.unavailableData.sun;
//         unavailableDataDayBars = unavailableBars.sun;
//         unavailableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.redFilledIn, borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
//       }

//       if(i !== 0 && i !== 6) {
//         unavailableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.redFilledIn}}></div>);
//       }

//       for(let key in unavailableDataDay) {

//         const userFullName = `${unavailableDataDay[key].info_voornaam} ${(unavailableDataDay[key].info_tussenvoegsel !== null && unavailableDataDay[key].info_tussenvoegsel !== '') ? unavailableDataDay[key].info_tussenvoegsel + " " + unavailableDataDay[key].info_achternaam.charAt(0) : unavailableDataDay[key].info_achternaam.charAt(0)}.`;
  
//         unavailableDataDayBars.push(
  
//           <div 
//             draggable='true' 
//             onDragStart={(event) => this.onDragStartUnavailable(event, i === 6 ? 0 : i + 1, key)}
//             onClick={() => this.onClickUnavailable(i, key)}
//             key={i + 'u' + key} 
//             className={this.state.selectedBar === unavailableDataDay[key].info_id + i ? 'planner_touchBox2 blink' : 'planner_touchBox2'}
//             style={{borderColor: Colors.color.redFilledIn}}
//           >
  
//             <div className='planner_timeContainer'>
  
//             {(unavailableDataDay[key].onbeschikbaar_begin === '00:00:00' && unavailableDataDay[key].onbeschikbaar_eind === '00:00:00') ?
                                                
//               <div>Hele dag</div>

//             :

//               <div>{unavailableDataDay[key].onbeschikbaar_begin.substring(0,5)} - {unavailableDataDay[key].onbeschikbaar_eind.substring(0,5)}</div>

//             }
  
//             </div>
  
//             <div className='planner_nameContainer'>
  
//               <div className='planner_nameText'>{userFullName}</div>
  
//             </div>
  
//           </div>
  
//         );
  
//       }

//       let j = unavailableDataDay.length;
//       while(j < maxNotFilled) {
//         unavailableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
//         j++;
//       }

//     }

//     return unavailableBars;

//   }

//   getHolidayBars() {

//     let holidayDataDay = null;
//     let holidayDataDayBars = null;

//     let holidayBars = {
//       mon: [],
//       tue: [],
//       wed: [],
//       thu: [],
//       fri: [],
//       sat: [],
//       sun: [],
//     };

//     let maxNotFilled = 0;

//     if(maxNotFilled < this.holidayDaysData.mon.length) {
//       maxNotFilled = this.holidayDaysData.mon.length;
//     }
//     if(maxNotFilled < this.holidayDaysData.tue.length) {
//       maxNotFilled = this.holidayDaysData.tue.length;
//     }
//     if(maxNotFilled < this.holidayDaysData.wed.length) {
//       maxNotFilled = this.holidayDaysData.wed.length;
//     }
//     if(maxNotFilled < this.holidayDaysData.thu.length) {
//       maxNotFilled = this.holidayDaysData.thu.length;
//     }
//     if(maxNotFilled < this.holidayDaysData.fri.length) {
//       maxNotFilled = this.holidayDaysData.fri.length;
//     }
//     if(maxNotFilled < this.holidayDaysData.sat.length) {
//       maxNotFilled = this.holidayDaysData.sat.length;
//     }
//     if(maxNotFilled < this.holidayDaysData.sun.length) {
//       maxNotFilled = this.holidayDaysData.sun.length;
//     }

//     for(let i = 0; i < 7; i++) {

//       if(i === 0) {
//         holidayDataDay = this.holidayDaysData.mon;
//         holidayDataDayBars = holidayBars.mon;
//         holidayDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.orangeVacation, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>Vakantie</div>)
//       }
//       if(i === 1) {
//         holidayDataDay = this.holidayDaysData.tue;
//         holidayDataDayBars = holidayBars.tue;
//       }
//       if(i === 2) {
//         holidayDataDay = this.holidayDaysData.wed;
//         holidayDataDayBars = holidayBars.wed;
//       }
//       if(i === 3) {
//         holidayDataDay = this.holidayDaysData.thu;
//         holidayDataDayBars = holidayBars.thu;
//       }
//       if(i === 4) {
//         holidayDataDay = this.holidayDaysData.fri;
//         holidayDataDayBars = holidayBars.fri;
//       }
//       if(i === 5) {
//         holidayDataDay = this.holidayDaysData.sat;
//         holidayDataDayBars = holidayBars.sat;
//       }
//       if(i === 6) {
//         holidayDataDay = this.holidayDaysData.sun;
//         holidayDataDayBars = holidayBars.sun;
//         holidayDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.orangeVacation, borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
//       }

//       if(i !== 0 && i !== 6) {
//         holidayDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Colors.color.orangeVacation}}></div>);
//       }

//       for(let key in holidayDataDay) {

//         const date = new Date(holidayDataDay[key].date);

//         const weekStart = new Date(this.state.mondayDate);
//         const weekEnd = new Date(this.state.sundayDate);

//         if((date.getDate() >= weekStart.getDate() && date.getMonth() >= weekStart.getMonth() && date.getFullYear() >= weekStart.getFullYear()) &&
//         (date.getDate() <= weekEnd.getDate() && date.getMonth() <= weekEnd.getMonth() && date.getFullYear() <= weekEnd.getFullYear())) {

//           holidayDataDayBars.push(
    
//             <div key={i + 'h' + key} className='planner_touchBox2' style={{borderColor: Colors.color.orangeVacation}}>
    
//               <div className='planner_timeContainer'>
                                                  
//                 <div>Vakantie</div>
    
//               </div>
    
//               <div className='planner_nameContainer'>
    
//                 <div className='planner_nameText'>{holidayDataDay[key].name}</div>
    
//               </div>
    
//             </div>
    
//           );

//         }
  
//       }

//       let j = holidayDataDay.length;
//       while(j < maxNotFilled) {
//         holidayDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
//         j++;
//       }

//     }

//     return holidayBars;

//   }

newPlannerBar(planData, i) {

  const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;

  const bijz = typeof this.state.partSchedules[planData.rooster_id] !== 'undefined' ? this.state.partSchedules[planData.rooster_id].bijz_afkorting : null;
  const bijzColor = typeof this.state.partSchedules[planData.rooster_id] !== 'undefined' ? this.state.partSchedules[planData.rooster_id].bijz_kleur : null;

  // check absences
  let absence = null;
  const absenceSchedule = this.state.absenceSchedules[planData.rooster_id];
  if (typeof absenceSchedule !== 'undefined') {
    absence = absenceSchedule;
  }

  return (

    <div key={planData.rooster_id} className='plannerN_shiftOuter'>

      <div 
        className={`plannerN_shiftContainer`} 
        style={{backgroundColor: Constants.hexToRgbA(`#${planData.functie_kleur}`, 0.20), cursor: 'default'}}
      >

        <div className='plannerN_shiftColumn1' style={{backgroundColor: Constants.hexToRgbA(`#${planData.functie_kleur}`, 0.5)}}>

          <span style={{color: Constants.getContrastPlan(`#${planData.functie_kleur}`)}}>{planData.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>

        </div>

        <div className='plannerN_shiftColumn2'>

          <div className='plannerN_shiftRow'>

            <span className='planTimeAsset' style={{color: '#04163b'}}>{planData.rooster_begin.substr(0,5)} - {planData.rooster_eind.substr(0,5)}</span>

            {planData.rooster_pauze !== '00:00:00' ?
              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer'>
                    <i className='fal fa-coffee'></i>
                    <span style={{marginLeft: '0.5vw'}}>{planData.rooster_pauze.substr(0,5)} pauze</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginLeft: 4}}
              >
                <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
              </Tooltip>
            : null}

            {bijz !== null && (absence === null || typeof absence === 'undefined') ?
              <span style={{color: `#${bijzColor}`, fontSize: '0.6vw', marginLeft: 4}}>{bijz}</span>
            : 
            absence !== null ?
                <span style={{color: Colors.color.redFilledIn, fontSize: '0.6vw', marginLeft: 4}}>{absence.afw_afkorting}</span>
            : null}

          </div>

          <div className='plannerN_shiftRow'>

            <span className='plannerN_nameText'>{userFullName}</span>

          </div>

        </div>

      </div>

    </div>

  );

}

getPlannerBars() {

  let dayShiftDataDay = null;
  let plannerDataDay = null;

  let plannerBars = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };

  let maxNotFilled = 0;

  if(maxNotFilled < (this.dayShiftsData.mon.length + this.plannerData.mon.length)) {
    maxNotFilled = this.dayShiftsData.mon.length + this.plannerData.mon.length;
  }
  if(maxNotFilled < (this.dayShiftsData.tue.length + this.plannerData.tue.length)) {
    maxNotFilled = this.dayShiftsData.tue.length + this.plannerData.tue.length;
  }
  if(maxNotFilled < (this.dayShiftsData.wed.length + this.plannerData.wed.length)) {
    maxNotFilled = this.dayShiftsData.wed.length + this.plannerData.wed.length;
  }
  if(maxNotFilled < (this.dayShiftsData.thu.length + this.plannerData.thu.length)) {
    maxNotFilled = this.dayShiftsData.thu.length + this.plannerData.thu.length;
  }
  if(maxNotFilled < (this.dayShiftsData.fri.length + this.plannerData.fri.length)) {
    maxNotFilled = this.dayShiftsData.fri.length + this.plannerData.fri.length;
  }
  if(maxNotFilled < (this.dayShiftsData.sat.length + this.plannerData.sat.length)) {
    maxNotFilled = this.dayShiftsData.sat.length + this.plannerData.sat.length;
  }
  if(maxNotFilled < (this.dayShiftsData.sun.length + this.plannerData.sun.length)) {
    maxNotFilled = this.dayShiftsData.sun.length + this.plannerData.sun.length;
  }

  let maxBars = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };

  for(let key in this.functionsData) {
    let count = 0;

    //monday
    for(let key2 in this.dayShiftsData.mon) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.mon[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.mon) {
      if(this.functionsData[key].functie_id === this.plannerData.mon[key2].functie_id) {
        count++;
      }
    }
    maxBars.mon.push({ functionID: this.functionsData[key].functie_id, max: count });

    //tuesday
    count = 0;
    for(let key2 in this.dayShiftsData.tue) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.tue[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.tue) {
      if(this.functionsData[key].functie_id === this.plannerData.tue[key2].functie_id) {
        count++;
      }
    }
    maxBars.tue.push({ functionID: this.functionsData[key].functie_id, max: count });

    //wednesday
    count = 0;
    for(let key2 in this.dayShiftsData.wed) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.wed[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.wed) {
      if(this.functionsData[key].functie_id === this.plannerData.wed[key2].functie_id) {
        count++;
      }
    }
    maxBars.wed.push({ functionID: this.functionsData[key].functie_id, max: count });

    //thursday
    count = 0;
    for(let key2 in this.dayShiftsData.thu) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.thu[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.thu) {
      if(this.functionsData[key].functie_id === this.plannerData.thu[key2].functie_id) {
        count++;
      }
    }
    maxBars.thu.push({ functionID: this.functionsData[key].functie_id, max: count });

    //friday
    count = 0;
    for(let key2 in this.dayShiftsData.fri) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.fri[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.fri) {
      if(this.functionsData[key].functie_id === this.plannerData.fri[key2].functie_id) {
        count++;
      }
    }
    maxBars.fri.push({ functionID: this.functionsData[key].functie_id, max: count });

    //saturday
    count = 0;
    for(let key2 in this.dayShiftsData.sat) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.sat[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.sat) {
      if(this.functionsData[key].functie_id === this.plannerData.sat[key2].functie_id) {
        count++;
      }
    }
    maxBars.sat.push({ functionID: this.functionsData[key].functie_id, max: count });

    //sunday
    count = 0;
    for(let key2 in this.dayShiftsData.sun) {
      if(this.functionsData[key].functie_id === this.dayShiftsData.sun[key2].functie_id) {
        count++;
      }
    }
    for(let key2 in this.plannerData.sun) {
      if(this.functionsData[key].functie_id === this.plannerData.sun[key2].functie_id) {
        count++;
      }
    }
    maxBars.sun.push({ functionID: this.functionsData[key].functie_id, max: count });
  }

  let maxBars2 = [];

  for(let key in this.functionsData) {
    let max = 0;
    for(let key2 in maxBars) {
      let tempMaxBars = maxBars[key2];
      for(let key3 in tempMaxBars) {
        if(this.functionsData[key].functie_id === tempMaxBars[key3].functionID) {
          if(max < tempMaxBars[key3].max) {
            max = tempMaxBars[key3].max;
          }
        }
      }
    }
    maxBars2.push({ 
      functionID: this.functionsData[key].functie_id, 
      max: max, 
      functionColor: this.functionsData[key].functie_kleur,
      functionName: this.functionsData[key].functie_naam
    });
  }

  for(let i = 0; i < 7; i++) {

    if(i === 0) {

      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.mon;
      plannerDataDay = this.plannerData.mon;

      for(let key in this.functionsData) {

        //if(this.state.showDefaultDayShifts === true) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        //}

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.mon.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
            >
              {maxBars2[key2].functionName}
            </div>
          );
          let j = 0;  
          while(j < maxBars2[key2].max) {
            plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.mon.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                  >
                    {maxBars2[key2].functionName}
                  </div>
                ); //MAXBARS.functionname added and rounded corners
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.mon.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
            >
              {tempPlannerData[key].functie_naam}
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.mon.push( 
  
          // <div 
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.mon.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                  > 
                    {maxBars2[key2].functionName}
                  </div>
                ); //MAXBARS2[Key2].functionname added and rounded borders
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }
    if(i === 1) {
      
      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.tue;
      plannerDataDay = this.plannerData.tue;

      for(let key in this.functionsData) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.tue.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
            >
              
            </div>
          );
          let j = 0;
          while(j < maxBars2[key2].max) {
            plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.tue.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                      
                    </div>
                  );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.tue.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5)}}
            >
              
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.tue.push( 
  
          // <div 
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.tue.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                    
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }
    if(i === 2) {

      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.wed;
      plannerDataDay = this.plannerData.wed;

      for(let key in this.functionsData) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.wed.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
            >
            </div>
          );
          let j = 0;
          while(j < maxBars2[key2].max) {
            plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.wed.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.wed.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5)}}
            >
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.wed.push( 
  
          // <div 
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.wed.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }
    if(i === 3) {
      
      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.thu;
      plannerDataDay = this.plannerData.thu;

      for(let key in this.functionsData) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.thu.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}} 
            >
            </div>
          );
          let j = 0;
          while(j < maxBars2[key2].max) {
            plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.thu.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}} 
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.thu.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5)}} 
            >
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.thu.push( 
  
          // <div  
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.thu.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }
    if(i === 4) {
      
      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.fri;
      plannerDataDay = this.plannerData.fri;

      for(let key in this.functionsData) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.fri.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
            >
            </div>
          );
          let j = 0;
          while(j < maxBars2[key2].max) {
            plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.fri.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.fri.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5)}}
            >
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.fri.push( 
  
          // <div 
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.fri.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }
    if(i === 5) {
      
      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.sat;
      plannerDataDay = this.plannerData.sat;

      for(let key in this.functionsData) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.sat.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
            >
            </div>
          );
          let j = 0;
          while(j < maxBars2[key2].max) {
            plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.sat.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.sat.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5)}}
            >
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.sat.push( 
  
          // <div 
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.sat.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5)}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }
    if(i === 6) {
      
      let tempPlannerData = [];
      let functionTitle = '';

      dayShiftDataDay = this.dayShiftsData.sun;
      plannerDataDay = this.plannerData.sun;

      for(let key in this.functionsData) {

          for(let key2 in dayShiftDataDay) {

            if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

              tempPlannerData.push(
                {
                    rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                    rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                    rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                    info_voornaam: '', //n.v.t.
                    info_achternaam: '',
                    info_tussenvoegsel: null,
                    rooster_publiceren: '2',
                    functie_id: dayShiftDataDay[key2].functie_id,
                    functie_naam: dayShiftDataDay[key2].functie_naam,
                    functie_kleur: dayShiftDataDay[key2].functie_kleur,
                }
              );

            }

          }

        for(let key2 in plannerDataDay) {

          if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

            tempPlannerData.push(
              plannerDataDay[key2]
            );

          }

        }

      }

      let count = 0;

      if(tempPlannerData.length === 0) {
        for(let key2 in maxBars2) {
          plannerBars.sun.push(
            <div className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}
            >
            </div>
          );
          let j = 0;
          while(j < maxBars2[key2].max) {
            plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
            j++;
          }
        }
      }

      let tempBarsArray = [];

      for(let key in tempPlannerData) {

        if(tempPlannerData[key].functie_naam !== functionTitle) {
          if(functionTitle !== '') {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                let j = count;
                while(j < maxBars2[key2].max) {
                  plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            }
            count = 0;
          }
          for(let key2 in maxBars2) {
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.sun.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { break; }
          }
          functionTitle = tempPlannerData[key].functie_naam;
          plannerBars.sun.push(
            <div 
              className='planner_headerText2' 
              style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}
            >
            </div>
          );
        }

        const userFullName = `${tempPlannerData[key].info_voornaam} ${(tempPlannerData[key].info_tussenvoegsel !== null && tempPlannerData[key].info_tussenvoegsel !== '') ? tempPlannerData[key].info_tussenvoegsel + " " + tempPlannerData[key].info_achternaam.charAt(0) : tempPlannerData[key].info_achternaam.charAt(0)}.`;
  
        plannerBars.sun.push( 
  
          // <div 
          //   key={key} 
          //   className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? 'planner_touchBox2 lastEdited' : 'planner_touchBox2'} 
          //   style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, width: '96%'}}
          // >
  
          //   <div className='planner_timeContainer'>

          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

          //     :

          //       <div>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
              
          //     }
      
          //   </div>
  
          //   <div className='planner_nameContainer'>
  
          //     {tempPlannerData[key].rooster_publiceren === '2' ?
                
          //       <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

          //     :

          //       <div className='planner_nameText'>{userFullName}</div>
              
          //     }
  
          //   </div>

          //   <div className='planner_iconContainer'>

          //   {

          //     tempPlannerData[key].rooster_publiceren === '0' ?

          //       <i className='fa fa-exclamation'></i>

          //   :

          //     null

          //   }

          //   </div>
  
          // </div>

          this.newPlannerBar(tempPlannerData[key], i)
  
        );

        if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
          tempBarsArray.push(tempPlannerData[key].functie_id);
        }

        count++;

        if(parseInt(key) === (tempPlannerData.length - 1)) {
          for(let key2 in maxBars2) {
            if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
              let j = count;
              while(j < maxBars2[key2].max) {
                plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
            if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
              if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                tempBarsArray.push(maxBars2[key2].functionID);
                plannerBars.sun.push(
                  <div 
                    className='planner_headerText2' 
                    style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}
                  >
                  </div>
                );
                let j = 0;
                while(j < maxBars2[key2].max) {
                  plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                  j++;
                }
              }
            } else { continue; }
          }
        }

      }

    }

    // let j = dayShiftDataDay.length + plannerDataDay.length;
    // while(j < maxNotFilled) {
    //   plannerDataBars.push(<div className='plannerN_touchBoxEmpty' />);
    //   j++;
    // }

  }

  return plannerBars;

}

  async selectDateCalendar(date) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({selectedDate: date});

    const dateSelected = new Date(date);

    await this.getCalendarDateData(this.getWeekNumber2(dateSelected), dateSelected.getFullYear());

  }

  openModeMenu() {

    this.setState({
      showSettingsMenu: !this.state.showSettingsMenu,
    });

  }

  changeTeamTab(id) {

    this.setState({ loading: true, team: typeof id === 'undefined' ? this.state.team : id }, 
    () => {
        this.reloadScreen2FullScreen();
    });

  }

  getTeamTabs() {

    let bars = [];

    for(const tab of Data.data.ownTeams) {

      if(this.state.team === tab.team_id) {

        bars.push(

          <div className='planner_sectionTab' style={{backgroundColor: '#47B7EB', color: 'white'}}>
  
            {tab.team_naam}
  
          </div>
  
        );

      } else {

        bars.push(

          <div className='planner_sectionTab' onClick={() => this.changeTeamTab(tab.team_id)}>
  
            {tab.team_naam}
  
          </div>
  
        );

      }

    }

    return bars;

  }

  render() {

    const plannerBars = this.getPlannerBars();
    //const availableBars = this.getAvailableBars();
    //const notFilledBars = this.getNotFilledBars();
    //const unavailableBars = this.getUnavailableBars();
    //const holidayBars = this.getHolidayBars();

    return (
        <div className='schedule'>

          <Navbar history={this.props.history} active={'schedule'} loading={this.state.loading} liveSwitch={'schedule'} parent={this} />

          <div className='planner_nav2'>

            <div className='planner_nav2_sub1' onClick={() => this.getNextData(false)}>
              <i className='fa fa-chevron-left'></i>
            </div> 
            <div className='planner_nav2_sub2'>
              <DatePicker 
                selected={this.state.selectedDate}
                onSelect={date => this.selectDateCalendar(date)}
                customInput={<i className='fa fa-calendar-day'></i>}
                popperPlacement="top-start"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "-40px, 0px"
                  },
                }}
                locale={nl}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showWeekNumbers
              />
            </div> 
            <div className='planner_nav2_sub3'>
              Week {this.state.week}, {this.state.year}
            </div> 
            <div className='schedule_nav2_sub4'>

            </div> 
            <div className='planner_nav2_sub5' onClick={() => this.getNextData(true)}>
              <i className='fa fa-chevron-right'></i>
            </div> 

          </div>

          {this.sectionsData.length > 0 ?
          
            <div className='planner_sectionBar'>

              {this.getSectionTabs()}

            </div>

          : null}

          {Data.data.teams.length > 1 && Data.data.ownTeams.length > 0 ?
          
            <div className='planner_sectionBar'>

              {this.getTeamTabs()}

            </div>

          : null}

          {/* <div className='schedule_optionsBar'>

            <div className='schedule_exportButton' onClick={() => this.openModeMenu()}>
              <i className={'far fa-file-excel'} />
              <span>Exporteren</span>
            </div>
            
            <div className={`schedule_exportButton_menu ${this.state.showModeMenu === true ? 'modeMenuActive' : ''}`}>
              <div className='schedule_exportButton_menu_title'>
                <div className='schedule_exportButton_menu_title_flex'>
                  <span>Sorteer op</span>
                </div>
              </div>
              <ScheduleDownloadName dataSet={this.plannerData} week={this.state.week} year={this.state.year} closeMenu={this.openModeMenu.bind(this)}/>
              <ScheduleDownloadJob dataSet={this.plannerData} week={this.state.week} year={this.state.year} closeMenu={this.openModeMenu.bind(this)}/>
            </div>

            <div className='planner_addButtonGreen' onClick={() => this.props.history.push('/planner')}>
              Planning maken
            </div>

          </div> */}

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='planner_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              <div className="planner_addButtons_container">

                <div style={{position: 'relative'}}>
                  <div className="planner_settingsButton settings_Asset2" onClick={() => this.setState({ showSettingsMenu: !this.state.showSettingsMenu })}>
                    {/* <i className={'fal fa-file-excel settings_Asset2'} /> */}
                    Exporteren
                  </div>

                  {this.state.showSettingsMenu === true ?

                    <div className='planner_settingsMenu' style={{width: '300%', left: '-8%', top: '3vw'}}>

                      <div className='arrow_box settingsAsset' />

                      <ScheduleDownloadName dataSet={this.plannerData} week={this.state.week} year={this.state.year} closeMenu={this.openModeMenu.bind(this)}/>
                      <ScheduleDownloadJob dataSet={this.plannerData} week={this.state.week} year={this.state.year} closeMenu={this.openModeMenu.bind(this)}/>

                    </div>

                  : null}
                </div>

                <div className="planner_new_addButton" onClick={() => this.props.history.push({ pathname: `/planner`, selectedDate: Constants.stringToDate(this.state.mondayDate) })} style={{width: '8.5vw'}}>
                  {/* <i className={'fal fa-calendar-alt'} /> */}
                  Planning maken
                </div>

              </div>

              <div className='planner_listDisplay'>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Ma {this.getMonday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getMonday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getMonday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.mon}</div>

                  {/* <div>{availableBars.mon}</div>

                  <div>{notFilledBars.mon}</div>

                  <div>{unavailableBars.mon}</div>

                  <div>{holidayBars.mon}</div> */}

                </div>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Di {this.getTuesday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getTuesday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getTuesday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.tue}</div>
                  
                  {/* <div>{availableBars.tue}</div>

                  <div>{notFilledBars.tue}</div>

                  <div>{unavailableBars.tue}</div>

                  <div>{holidayBars.tue}</div> */}

                </div>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Wo {this.getWednesday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getWednesday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getWednesday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.wed}</div>
                  
                  {/* <div>{availableBars.wed}</div>

                  <div>{notFilledBars.wed}</div>

                  <div>{unavailableBars.wed}</div>

                  <div>{holidayBars.wed}</div> */}

                </div>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Do {this.getThursday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getThursday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getThursday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.thu}</div>
                  
                  {/* <div>{availableBars.thu}</div>

                  <div>{notFilledBars.thu}</div>

                  <div>{unavailableBars.thu}</div>

                  <div>{holidayBars.thu}</div> */}

                </div>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Vr {this.getFriday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getFriday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getFriday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.fri}</div>
                  
                  {/* <div>{availableBars.fri}</div>

                  <div>{notFilledBars.fri}</div>

                  <div>{unavailableBars.fri}</div>

                  <div>{holidayBars.fri}</div> */}

                </div>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Za {this.getSaturday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getSaturday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getSaturday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.sat}</div>
                  
                  {/* <div>{availableBars.sat}</div>

                  <div>{notFilledBars.sat}</div>

                  <div>{unavailableBars.sat}</div>

                  <div>{holidayBars.sat}</div> */}

                </div>

                <div className='planner_dayContainer'>

                  <div className='planner_dateText stickyTopSchedule' style={{width: '103%'}}>Zo {this.getSunday(this.WEEK, this.YEAR).getDate()} {Constants.getShortMonthName(this.getSunday(this.WEEK, this.YEAR).toISOString()).replace('.', '')} {this.getSunday(this.WEEK, this.YEAR).getFullYear()}</div>

                  <div>{plannerBars.sun}</div>
                  
                  {/* <div>{availableBars.sun}</div>

                  <div>{notFilledBars.sun}</div>

                  <div>{unavailableBars.sun}</div>

                  <div>{holidayBars.sun}</div> */}

                </div>

              </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default Schedule;