const helpdeskItems = [

    {
        title: 'Instructie | Beginnen met Tiemdo',
        desc: 'Introductievideo waarin de basis functionaliteiten van Tiemdo uitgelegd worden',
        videoID: 'WazCFVBwUNs',
        duration: '58:16',
        tab: 'DEMO',
        text: null,
    },
    {
        title: 'Instructie | Quick start',
        desc: 'Introductievideo waarin de basis functionaliteiten van Tiemdo in vogelvlucht uitgelegd worden',
        videoID: 'qnq5_PlRxyw',
        duration: '15:33',
        tab: 'DEMO',
        text: null,
    },
    {
        title: 'Rooster | Functionaliteiten van het rooster',
        desc: 'Video waarin de basis functionaliteiten van het rooster worden uitgelegd',
        videoID: 'WqfY2RZmVuM',
        duration: '10:02',
        tab: 'PLANNER',
        text: null,
    },
    {
        title: 'Rooster | plus- en minuren',
        desc: 'Video waarin de betrokkenheid van de plus- en minuren bij het rooster wordt uitgelegd',
        videoID: 'QWjo0KjybiM',
        duration: '02:37',
        tab: 'PLANNER',
        text: null,
    },
    {
        title: 'Rooster | Handmatig plannen',
        desc: 'Video waarin wordt uitgelegd hoe handmatig gepland kan worden',
        videoID: 'AZYtEzig7OQ',
        duration: '01:46',
        tab: 'PLANNER',
        text: null,
    },
    {
        title: 'Rooster | Diensten kopiëren rooster',
        desc: 'Video waarin wordt uitgelegd hoe diensten snel gekopieerd en geplakt kunnen worden',
        videoID: 'rEAqu3MVz6I',
        duration: '02:29',
        tab: 'PLANNER',
        text: null,
    },
    {
        title: 'Medewerkers | Medewerkers zelf registreren',
        desc: 'Video waarin uitgelegd wordt hoe je medewerkers zelf kunt registreren',
        videoID: 'SDwfUwguUcw',
        duration: '06:19',
        tab: 'EMPLOYEES',
        text: null,
    },
    {
        title: 'Medewerkers | Medewerkers registreren met registratiecode',
        desc: 'Video waarin uitgelegd wordt hoe je medewerkers middels een registratiecode kunt registreren',
        videoID: 'o3oneyQw0u4',
        duration: '02:58',
        tab: 'EMPLOYEES',
        text: null,
    },
    {
        title: 'Inkloksysteem | Uitleg in- en uitklokken',
        desc: 'Video waarin het inkloksysteem samen met pauzes worden uitgelegd',
        videoID: '-QtX8XBljE0',
        duration: '07:25',
        tab: 'CLOCK',
        text: null,
    },
    {
        title: 'Beschikbaarheid | Beschikbaarheid en onbeschikbaarheid',
        desc: 'Video waarin de beschikbaarheid van medewerkers wordt uitgelegd',
        videoID: 'IhrNaSE7OXE',
        duration: '05:17',
        tab: 'AVAILABILITY',
        text: null,
    },
    {
        title: 'Ruilen | Diensten onderling ruilen',
        desc: 'Video waarin wordt uitgelegd hoe diensten geruild en goedgekeurd kunnen worden',
        videoID: '13qWe5QrcR8',
        duration: '02:50',
        tab: 'TRADE',
        text: null,
    },
    {
        title: 'Overzichten | Management overzicht',
        desc: 'Video waarin de overzichten van Tiemdo uitgelegd worden',
        videoID: 'VHGSdZzSc9U',
        duration: '04:05',
        tab: 'REPORTS',
        text: null,
    },
    {
        title: 'Instellingen | Mijn bedrijf',
        desc: 'Video waarin de functionaliteiten binnen "Mijn bedrijf" worden uitgelegd',
        videoID: 'w2UD8kPx1ko',
        duration: '05:05',
        tab: 'SETTINGS',
        text: null,
    },
    {
        title: 'Instellingen | Uitleg instellingen en configuratie',
        desc: 'Video waarin de instellingen van Tiemdo worden uitgelegd',
        videoID: '3822wLIrqgs',
        duration: '08:46',
        tab: 'SETTINGS',
        text: null,
    },


];

export default (helpdeskItems);