import React from 'react';

//styles
import './resetPassword2.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

import 'react-datepicker/dist/react-datepicker.css';

import BetterAlert from '../../../components/alert/alert';
import Data from '../../../constants/Data';
import APICheckStoreCode from '../../../classes/APICheckStoreCode';
import APIResetPasswordCheckToken from '../../../classes/APIResetPasswordCheckToken';

import CryptoJS from 'crypto-js';
import APIResetPasswordDeleteToken from '../../../classes/APIResetPasswordDeleteToken';
import APIResetPasswordEditPassword from '../../../classes/APIResetPasswordEditPassword';
import Constants from '../../../constants/Constants';

class ResetPassword2 extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    const url = new URL(window.location.href.replace('#', ''));

    this.token = url.searchParams.get('token');
    this.storeCode = url.searchParams.get('storecode');

    if(this.token === null || this.token.length < 64 || this.storeCode === null || this.storeCode.length < 3) {
      this.props.history.push("/");
    }

    this.accountID = null;
    this.tokenID = null;

    // compwillmount
    
    this.state = {

      loading: true,
      loading2: false,

      password1: '',
      password2: '',

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

    };

  }

  async componentDidMount() {

    // false token request
    if(this.token === null || this.token.length < 64 || this.storeCode === null || this.storeCode.length < 3) {
      return;
    }

    const codeValid = await APICheckStoreCode.Request(this.storeCode);

    // is storecode correct
    if(codeValid === null) {
      Data.data.serverIP = '';
      this.props.history.push("/");
      return;
    }

    Data.data.serverIP = codeValid.server_ip;

    // is token correct
    const tokenObject = await APIResetPasswordCheckToken.Request(this.token, this.storeCode);

    if(tokenObject === null) {
      Data.data.serverIP = '';
      this.props.history.push("/");
      return;
    }

    // is within 24 hours
    const timeNow = await Constants.getDateNow();

    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = timeNow;
    const secondDate = new Date(`${tokenObject.wachtwoordherstel_datum}T${tokenObject.wachtwoordherstel_tijd}`);

    const diffDays = Math.abs((firstDate - secondDate) / oneDay);

    if(diffDays > 1) {
      await APIResetPasswordDeleteToken.Request(tokenObject.wachtwoordherstel_id);
      Data.data.serverIP = '';
      this.props.history.push("/");
      return;
    }

    // everything is correct, set the data.
    Data.data.storeCode = this.storeCode;
    this.accountID = tokenObject.wachtwoordherstel_account_id;
    this.tokenID = tokenObject.wachtwoordherstel_id;

    this.setState({loading: false});

  }

  checkValidations() {

    if(this.state.password1 === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen nieuw wachtwoord ingevoerd',
        });
        return false;
    }

    if(this.state.password2 === '') {
        this.setState({
            showError: true,
            errorText: 'Je moet het nieuwe wachtwoord nog bevestigen',
        });
        return false;
    }

    if(this.state.password1 !== this.state.password2) {
        this.setState({
            showError: true,
            errorText: 'Wachtwoorden komen niet overeen',
        });
        return false;
    }

    return true;

  }

  changedAlert() {
    this.setState({
        alertTitle: 'Gelukt',
        alertBody: `Jouw wachtwoord is gewijzigd`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    Data.data.serverIP = '';
    Data.data.storeCode = '';
    this.props.history.push("/");

  }

  
  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async changePass() {

    this.setState({loading2: true});

    if(this.checkValidations() === false) {
      this.setState({loading2: false});
      return;
    }

    const changed = await APIResetPasswordEditPassword.Request(CryptoJS.SHA512(this.state.password2).toString(), this.accountID);

    if(changed === true) {

        await APIResetPasswordDeleteToken.Request(this.tokenID);

        this.changedAlert();

    } else {

        // console.log('error');

        this.setState({
            loading2: false,
        });

    }

  }

  render() {

    return (
        <div className='resetPassword'>

          <div className='planner_nav2'>

            <div className='functions_nav2_sub2'>
            </div>
            <div className='resetPass_nav2_sub3'>
              Wachtwoord wijzigen
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='resetPass_container'>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }


                <div className={'resetPass_userContainer'}>

                    <input 
                        className={'inputField2'}
                        type={'password'}
                        value={this.state.password1}
                        onChange={(event) => this.setState({ password1: event.target.value, showError: false })}
                        placeholder={'Nieuw wachtwoord'}
                        autoCapitalize={'none'}
                    />

                    <input 
                        style={{marginTop: 10}}
                        className={'inputField2'}
                        type={'password'}
                        value={this.state.password2}
                        onChange={(event) => this.setState({ password2: event.target.value, showError: false })}
                        placeholder={'Herhaal wachtwoord'}
                        autoComplete={'new-password'}
                        autoCapitalize={'none'}
                    />

                    {this.state.loading2 === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div className='submitButton' style={{marginTop: 40}} onClick={() => this.changePass()}>Wachtwoord wijzigen</div>

                    }

                    {this.showError()}

                </div>

            </div>
        
          }

        </div>
    );
  }
  
}

export default ResetPassword2;