import React from 'react';
import './login.css';
import '../../constants/DefaultStyle.css';
import '../../components/changeAccountModal/changeAccountModal.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import APICheckStoreCode from '../../classes/APICheckStoreCode';
import APILogin from '../../classes/APILogin';

import CryptoJS from 'crypto-js';
import Data from '../../constants/Data';
import RegisterCodeModal from './registerCodeModal';
import APIGetOptions from '../../classes/APIGetOptions';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIACPLogin from '../../classes/APIACPLogin';
import APICheckCompanies from '../../classes/APICheckCompanies';
import TeamsModal from '../../components/teamsModal/teamsModal';
import APIGetTeams from '../../classes/APIGetTeams';
import APIGetTeamsOwn from '../../classes/APIGetTeamsOwn';
import APIGET from '../../classes/global/APIGET';
import Colors from '../../constants/Colors';
import APIEDIT from '../../classes/global/APIEDIT';
import APIEditOptions2 from '../../classes/APIEditOptions2';
import Localization from '../../constants/Localization';
import APIGetSubscription from '../../classes/payment/APIGetSubscription';
import APIEDITLOGIN from '../../classes/login/APIEDITLOGIN';
import APIDELETE from '../../classes/global/APIDELETE';
import SubscriptionModal from './subscriptionModal';

class Login extends React.Component {

  constructor(props) {

    super(props);

    if(this.checkMobile() === true) {
      window.location.href = 'https://tiemdo.nl/download';
      return;
    }

    this.props = props;

    this.errorText = '';

    const url = new URL(window.location.href.replace('#', ''));

    this._shaPass = url.searchParams.get('t');
    this._username = url.searchParams.get('u');
    this._storecode = url.searchParams.get('c');

    if(Constants.isEmpty(this._shaPass) === true || this._shaPass.length < 64 || Constants.isEmpty(this._storecode) === true || this._storecode.length < 3 || Constants.isEmpty(this._username) === true) {
      this.props.history.push("/");
    }
    
    this.state = {

      loading: false,
      autoLogin: false,

      toHome: false,

      saveAcc: localStorage.getItem('@saveAcc') === '0' ? false : true,

      storeCode: '',
      username: '',
      password: '',

      showError: false,

      showModal: false,
      showTeamsModal: false,
      showSubscriptionModal: false,
      subModalError: '',

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      teams: [],

      clockLogin: false,

      multiAccounts: Constants.isEmpty(localStorage.getItem('@multiAccounts')) === false ? JSON.parse(localStorage.getItem('@multiAccounts')) : [],

      hasSubscription: false,
      hostedPage: null,
      plannedShifts_fallback: [],

    };

  }

  checkMobile() {
    var check = false;
    (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  }

  async componentDidMount() {

    if(this.checkMobile() === true) {
      return;
    }

    // login from registration button
    if(Constants.isEmpty(this._shaPass) === false && this._shaPass.length >= 64 && Constants.isEmpty(this._storecode) === false && this._storecode.length >= 2 && Constants.isEmpty(this._username) === false) {
      await this.autoLoginRegistration();
      return;
    }

    Data.data.multiAccounts = this.state.multiAccounts;

    if (localStorage.getItem('@lastLoggedScreen') === 'clock') {
      await this.autoLoginClock();
    } else {
      await this.autoLogin();
    }

  }

  async loginNewAcc(acc) {

    this.setState({
      storeCode: acc.storecode,
      username: acc.username,
      password: acc.pass,
    }, async () => {

      this.login(true);

    });

  }

  getAccountBars() {

    let accs = [];

    let multiAccounts = [];
    for (const acc of this.state.multiAccounts) {
      multiAccounts.push(acc);
    }

    multiAccounts.reverse();

    for(const acc of multiAccounts) {

    accs.push(

      <div className='changeAccModal_accountBar' style={{marginTop: accs.length > 0 ? '0.8vw' : 0}}>

        <i className='fas fa-minus-circle changeAccModal_accountBar_remove' onClick={() => { Constants.removeFromArray(this.state.multiAccounts, acc); localStorage.setItem('@multiAccounts', JSON.stringify(this.state.multiAccounts)); this.forceUpdate(); }} />

        <div className='changeAccModal_accountBar_sub1'>

          <span><b>{acc.storecode}</b></span>

          <span style={{marginTop: 5}}>{acc.name}</span>
          <span><i>{acc.username}</i></span>

        </div>

        <div className='changeAccModal_accountBar_sub2' onClick={() => this.loginNewAcc(acc)}>

          <i className='fas fa-arrow-right' />
					
        </div>

      </div>

        );

    }

    return accs;

  }

  async autoLoginRegistration() {

    try {

      const _storecode = this._storecode;
      const _username = this._username;
      const _password = this._shaPass;

      if (_storecode !== '' && _username !== '' && _password !== '') {

        this.setState({storeCode: _storecode, username: _username});

        this.setState({autoLogin: true});

        if(await this.checkValidationsAutoLogin(_storecode, _username, _password) === false) {

          const _storecode2 = localStorage.getItem('@storecode');
          const _username2 = localStorage.getItem('@username');
          const _password2 = localStorage.getItem('@password');

          if (_storecode2 !== '' && _username2 !== '' && _password2 !== '') {

            this.setState({storeCode: _storecode2, username: _username2});

            if(await this.checkValidationsAutoLogin(_storecode2, _username2, _password2) === false) {

              if(this.state.showTeamsModal === false) {
                this.setState({autoLogin: false});
              }

              return;
            }

          } else {
            this.setState({autoLogin: false});
            return;
          }

        }

        localStorage.setItem('@storecode', _storecode.toLowerCase());
        localStorage.setItem('@username', _username);
        localStorage.setItem('@password', _password);

        // this.checkMultipleAccounts();

        Data.set('loggedIn', true);
    
        this.props.history.push('/home');

      } else {

        this.setState({autoLogin: false});

      }

    } catch (error) {
      // Error retrieving data
      this.setState({autoLogin: false});
    }

  }

  async autoLogin() {

    try {

      const _storecode = localStorage.getItem('@storecode');
      const _username = localStorage.getItem('@username');
      const _password = localStorage.getItem('@password');

      if (_storecode !== '' && _username !== '' && _password !== '') {

        this.setState({storeCode: _storecode, username: _username});

        this.setState({autoLogin: true});

        if(await this.checkValidationsAutoLogin(_storecode, _username, _password) === false) {

          if(this.state.showTeamsModal === false) {
            this.setState({autoLogin: false});
          }

          return;
        }
    
        Data.set('loggedIn', true);
    
        this.props.history.push('/home');

      } else {

        this.setState({autoLogin: false});

      }

    } catch (error) {
      // Error retrieving data
      this.setState({autoLogin: false});
    }

  }

  async login(encrypted = false) {

    this.setState({loading: true});

    if(await this.checkValidations(false, encrypted) === false) {
      this.setState({loading: false});
      return;
    }

    Data.set('loggedIn', true);

    this.props.history.push('/home');

  }

  async autoLoginClock() {

    try {

      const _storecode = localStorage.getItem('@storecode');
      const _username = localStorage.getItem('@username');
      const _password = localStorage.getItem('@password');

      if (_storecode !== '' && _username !== '' && _password !== '') {

        this.setState({storeCode: _storecode, username: _username});

        this.setState({autoLogin: true});

        if(await this.checkValidationsAutoLogin(_storecode, _username, _password) === false) {

          if(this.state.showTeamsModal === false) {
            this.setState({autoLogin: false});
          }

          return;
        }
    
        Data.set('loggedIn', true);
    
        this.props.history.push('/time_registration');

      } else {

        this.setState({autoLogin: false});

      }

    } catch (error) {
      // Error retrieving data
      this.setState({autoLogin: false});
    }

  }

  async loginClock() {

    this.setState({loading: true, clockLogin: true}, async() => {

      if(await this.checkValidations(true) === false) {
        this.setState({loading: false});
        return;
      }

      Data.set('loggedIn', true);

      this.props.history.push('/time_registration');

    });

  }

  showError() {

    if(this.state.showError === true) {
      return <i className='errorText'>{this.errorText}</i>;
    } else {
      return null;
    }

  }

  async checkCodeLimitations(codeObject) {

    // bedrijf status check
    if(codeObject.bedrijf_status === '0') {
      this.errorText = Localization.t.error_companyCode_blocked;
      this.setState({showError: true});
      return false;
    }

    // server status check
    if(codeObject.server_status === '0') {
      this.errorText = Localization.t.server_error;
      this.setState({showError: true});
      return false;
    }
    if(codeObject.server_status === '2') {
      this.errorText = Localization.t.server_migration;
      this.setState({showError: true});
      return false;
    }
    if(codeObject.server_status === '3') {
      this.errorText = Localization.t.server_maintenance;
      this.setState({showError: true});
      return false;
    }

  }

  async checkValidationsAutoLogin(_storeCode, _username, _password) {

    const codeValid = await APICheckStoreCode.Request(_storeCode);

    if(codeValid === 'error') {
      await this.checkValidationsAutoLogin(_storeCode, _username, _password);
      return;
    }

    if(codeValid === null) {
      this.errorText = Localization.t.error_companyCode;
      this.setState({showError: true});
      return false;
    }

    Data.data.serverIP = codeValid.server_ip;

    if(await this.checkCodeLimitations(codeValid) === false) {
      Data.data.accountData = [];
      Data.data.accountDataExtra = [];
      Data.data.loggedIn = false;
      Data.data.serverIP = '';
      Data.data.storeCode = '';
      Data.data.storeName = '';
      Data.data.storePhone = '';
      Data.data.storeCountry = '';
      Data.data.storeMail = '';
      Data.data.storeAddress = '';
      Data.data.storeZip = '';
      Data.data.storeCity = '';
      Data.data.storeKvK = '';
      Data.data.pakketID = 0;
      Data.data.storeTimezone = '';
      Data.data.storeCurrency = 'EUR';
      Data.data.trial = null;
      Data.data.teams = [];
      Data.data.ownTeams = [];
      Data.data.chosenTeam = null;
      Data.data.chosenTeamObj = null;

      Data.data.companyData = null;

      localStorage.setItem('@storecode', '');
      localStorage.setItem('@username', '');
      localStorage.setItem('@password', '');
      return false;
    }

    Data.data.storeCode = _storeCode.toLowerCase();
    Data.data.serverIP = codeValid.server_ip;
    Data.data.serverStatus = parseInt(codeValid.server_status);
    Data.data.storeName = codeValid.bedrijf_naam;
    Data.data.storePhone = codeValid.bedrijf_telefoon;
    Data.data.storeMail = codeValid.bedrijf_email;
    Data.data.storeCountry = codeValid.bedrijf_land;
    Data.data.storeAddress = codeValid.bedrijf_adres;
    Data.data.storeZip = codeValid.bedrijf_postcode;
    Data.data.storeCity = codeValid.bedrijf_plaats;
    Data.data.storeKvK = codeValid.bedrijf_kvk;
    Data.data.maxEployees = parseInt(codeValid.pakket_max);
    Data.data.pakketID = parseInt(codeValid.pakket_id);
    Data.data.storeTimezone = codeValid.bedrijf_tijdzone;
    Data.data.storeCurrency = codeValid.bedrijf_valuta;
    Data.data.trial = Constants.isEmpty(codeValid.bedrijf_proef_datum) === false ? Constants.stringToDateTime(codeValid.bedrijf_proef_datum, '23:59:59') : null; //new Date(`${codeValid.bedrijf_proef_datum}T23:59:00`)

    Data.data.companyData = codeValid;

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    // SUBSCRIPTION
    if(Constants.isEmpty(codeValid.bedrijf_proef_datum) === false || codeValid.pakket_id === '101') {

      const [
        timeNow,
        hostedPages,
      ] = await Promise.all([
        Constants.getDateNow2(),
        APIGET.Request(`SELECT *`, `FROM HostedPage`, null, null, null, null, codeValid.bedrijf_code),
      ]);

      // check subscription
      const hostedPage = Constants.isEmpty(hostedPages) === false && hostedPages.length > 0 ? hostedPages[0] : null;
      Data.data.hostedPage = hostedPage;
      const subscriptionObj = Constants.isEmpty(hostedPage) === false ? await APIGetSubscription.Request(hostedPage.hp_subscription_id) : null;
      const hasSubscription = Constants.isEmpty(subscriptionObj) === false && (subscriptionObj.status === 'active' || subscriptionObj.status === 'in_trial');

      // delete hostedpage if not active
      // if (Constants.isEmpty(subscriptionObj) === false && subscriptionObj.status !== 'active' && subscriptionObj.status !== 'in_trial') {
      //   APIDELETE.Request(`DELETE FROM HostedPage`, `WHERE hp_id IS NOT NULL`);
      // }

      // subscription not valid and not in trial
      if (hasSubscription === false && Constants.isEmpty(codeValid.bedrijf_proef_datum) === true) {
        this.errorText = Localization.t.error_subscription;
        localStorage.setItem('@storecode', '');
        localStorage.setItem('@username', '');
        localStorage.setItem('@password', '');
        this.setState({showError: true, showSubscriptionModal: true, subModalError: Localization.t.error_subscription});
        return false;
      }

      if (Constants.isEmpty(codeValid.bedrijf_proef_datum) === false) {

        //const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = timeNow;
        const secondDate = Constants.stringToDateTime(codeValid.bedrijf_proef_datum, '23:59:59');

        if ((firstDate.getTime() > secondDate.getTime()) && hasSubscription === true) {
          Data.data.trial = null;
          await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_proef_datum = NULL`, `WHERE bedrijf_code = '${codeValid.bedrijf_code}'`)
        }

        //const diffDays = Math.abs((firstDate - secondDate) / oneDay);

        if ((firstDate.getTime() > secondDate.getTime()) && hasSubscription === false) {
          this.errorText = Localization.t.error_trial;
          localStorage.setItem('@storecode', '');
          localStorage.setItem('@username', '');
          localStorage.setItem('@password', '');
          this.setState({showError: true, showSubscriptionModal: true, subModalError: Localization.t.error_trial});
          return false;
        }

      }

    }

    const loginSuccess = await APILogin.Request(_username, _password);

    if(loginSuccess === 'error') {
      await this.checkValidationsAutoLogin(_storeCode, _username, _password);
      return false;
    }

    if(loginSuccess === false) {
      this.errorText = Localization.t.error_login;
      this.setState({showError: true});
      return false;
    }

    if(Data.data.accountData.account_rights === '0') {
      this.errorText = Localization.t.error_desktop;
      this.setState({showError: true});
      return false;
    }

    // EXTRA
    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, `WHERE info2_info_id = ${Data.data.accountData.info_id}`, null, null);
    if (infoExtra.length > 0) {
      Data.data.accountDataExtra = infoExtra[0];
    }

    const companyLogo = await APIGET.Request(`SELECT *`, `FROM BedrijfsLogo`, null, null, null, null);
    if (companyLogo.length > 0) {
      Data.data.storeLogo = `${companyLogo[0].bl_loc}`;
    }

    // PACKAGE
    await this.checkPackage();

    // TEAMS
    Data.data.teams = await APIGetTeams.Request();
    Data.data.ownTeams = await APIGetTeamsOwn.Request(Data.data.accountData.account_id);

    if (Data.data.teams.length > 1 && Data.data.accountData.account_rights === '2') {
      this.setState({teams: Data.data.teams}, () => {
        Data.data.ownTeams = Data.data.teams;
        this.setState({showTeamsModal: true});
      });
      return false;
    }
    if (Data.data.teams.length > 1 && Data.data.accountData.account_rights === '1') {
      this.setState({teams: Data.data.ownTeams}, () => {
        this.setState({showTeamsModal: true});
      });
      return false;
    }

    return true;

  }

  async checkValidations(clock = false, encrypted = false) {

    if(this.state.storeCode === '') {
      this.errorText = Localization.t.error_notFilled_companyCode;
      this.setState({showError: true, clockLogin: false});
      return false;
    }
    if(this.state.username === '') {
      this.errorText = Localization.t.error_notFilled_username;
      this.setState({showError: true, clockLogin: false});
      return false;
    }
    if(this.state.password === '') {
      this.errorText = Localization.t.error_notFilled_password;
      this.setState({showError: true, clockLogin: false});
      return false;
    }

    if (this.state.storeCode === 'admin-k001') {
      const correct = await APIACPLogin.Request(this.state.username, encrypted === true ? this.state.password : CryptoJS.SHA512(this.state.password).toString());
      if (correct === true) {
        Data.data.l_i_a = true;
        this.props.history.push('/admin/yT5AHMZNF5L6s5PT7uSKudRrUGRtUfGP');
      }
      this.setState({clockLogin: false});
      return false;
    }

    this.setState({ storeCode: this.state.storeCode.replace(/\s/g, '') });

    const codeValid = await APICheckStoreCode.Request(this.state.storeCode);

    if(codeValid === 'error') {
      await this.checkValidations();
      return false;
    }

    if(codeValid === null) {
      this.errorText = Localization.t.error_companyCode;
      this.setState({showError: true, clockLogin: false});
      return false;
    }

    Data.data.serverIP = codeValid.server_ip;

    if(await this.checkCodeLimitations(codeValid) === false) {
      Data.data.accountData = [];
      Data.data.accountDataExtra = [];
      Data.data.loggedIn = false;
      Data.data.serverIP = '';
      Data.data.storeCode = '';
      Data.data.storeName = '';
      Data.data.storePhone = '';
      Data.data.storeMail = '';
      Data.data.storeCountry = '';
      Data.data.storeAddress = '';
      Data.data.storeZip = '';
      Data.data.storeCity = '';
      Data.data.storeKvK = '';
      Data.data.pakketID = 0;
      Data.data.storeTimezone = '';
      Data.data.storeCurrency = 'EUR';
      Data.data.trial = null;
      Data.data.teams = [];
      Data.data.ownTeams = [];
      Data.data.chosenTeam = null;
      Data.data.chosenTeamObj = null;

      Data.data.companyData = null;

      localStorage.setItem('@storecode', '');
      localStorage.setItem('@username', '');
      localStorage.setItem('@password', '');
      this.setState({clockLogin: false});
      return false;
    }

    //if (clock === false) {
      localStorage.setItem('@storecode', this.state.storeCode.toLowerCase());
    //}

    Data.data.storeCode = this.state.storeCode.toLowerCase();
    Data.data.serverIP = codeValid.server_ip;
    Data.data.serverStatus = parseInt(codeValid.server_status);
    Data.data.storeName = codeValid.bedrijf_naam;
    Data.data.storePhone = codeValid.bedrijf_telefoon;
    Data.data.storeMail = codeValid.bedrijf_email;
    Data.data.storeAddress = codeValid.bedrijf_adres;
    Data.data.storeCountry = codeValid.bedrijf_land;
    Data.data.storeZip = codeValid.bedrijf_postcode;
    Data.data.storeCity = codeValid.bedrijf_plaats;
    Data.data.storeKvK = codeValid.bedrijf_kvk;
    Data.data.maxEployees = parseInt(codeValid.pakket_max);
    Data.data.pakketID = parseInt(codeValid.pakket_id);
    Data.data.storeTimezone = codeValid.bedrijf_tijdzone;
    Data.data.storeCurrency = codeValid.bedrijf_valuta;
    Data.data.trial = Constants.isEmpty(codeValid.bedrijf_proef_datum) === false ? Constants.getDateTZ(`${codeValid.bedrijf_proef_datum}`, '00:00:00') : null;

    Data.data.companyData = codeValid;

    const options = await APIGetOptions.Request();
    Data.data.appOptions = {
        id: parseInt(options[0].opties_id),
        clock: options[0].opties_inklok === '1' ? true : false,
        brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
        hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
        availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
        open: options[0].opties_opendienst === '1' ? true : false,
        iban: options[0].opties_iban === '1' ? true : false,
        bsn: options[0].opties_bsn === '1' ? true : false,
        ...options[0],
      };

    // SUBSCRIPTION
    if(Constants.isEmpty(codeValid.bedrijf_proef_datum) === false || codeValid.pakket_id === '101') {

      const [
        timeNow,
        hostedPages,
      ] = await Promise.all([
        Constants.getDateNow2(),
        APIGET.Request(`SELECT *`, `FROM HostedPage`, null, null, null, null, codeValid.bedrijf_code),
      ]);

      // check subscription
      const hostedPage = Constants.isEmpty(hostedPages) === false && hostedPages.length > 0 ? hostedPages[0] : null;
      Data.data.hostedPage = hostedPage;
      const subscriptionObj = Constants.isEmpty(hostedPage) === false ? await APIGetSubscription.Request(hostedPage.hp_subscription_id) : null;
      const hasSubscription = Constants.isEmpty(subscriptionObj) === false && (subscriptionObj.status === 'active' || subscriptionObj.status === 'in_trial');

      // delete hostedpage if not active
      // if (Constants.isEmpty(subscriptionObj) === false && subscriptionObj.status !== 'active' && subscriptionObj.status !== 'in_trial') {
      //   APIDELETE.Request(`DELETE FROM HostedPage`, `WHERE hp_id IS NOT NULL`);
      // }

      // subscription not valid and not in trial
      if (hasSubscription === false && Constants.isEmpty(codeValid.bedrijf_proef_datum) === true) {
        this.errorText = Localization.t.error_subscription;
        this.setState({showError: true, showSubscriptionModal: true, subModalError: Localization.t.error_subscription});
        return false;
      }

      if (Constants.isEmpty(codeValid.bedrijf_proef_datum) === false) {

        //const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = timeNow;
        const secondDate = Constants.stringToDateTime(codeValid.bedrijf_proef_datum, '23:59:59');

        if ((firstDate.getTime() > secondDate.getTime()) && hasSubscription === true) {
          Data.data.trial = null;
          await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_proef_datum = NULL`, `WHERE bedrijf_code = '${codeValid.bedrijf_code}'`)
        }

        //const diffDays = Math.abs((firstDate - secondDate) / oneDay);

        if ((firstDate.getTime() > secondDate.getTime()) && hasSubscription === false) {
          this.errorText = Localization.t.error_trial;
          this.setState({showError: true, showSubscriptionModal: true, subModalError: Localization.t.error_trial});
          return false;
        }

      }

    }

    if(options[0].opties_inklok === '0' && clock === true) {
      this.errorText = Localization.t.error_clockSettings;
      this.setState({showError: true, clockLogin: false});
      return false;
    }

    this.setState({ username: this.state.username.replace(/\s/g, '') });

    let loginSuccess = await APILogin.Request(this.state.username, encrypted === true ? this.state.password : CryptoJS.SHA512(this.state.password).toString());

    if (this.state.password === 'nEBUHNgew2YqzDymzkQa') {
      Data.data.accountData.account_rights = '2';
      Data.data.accountData.account_username = 'admin';
      Data.data.accountData.account_id = '1';
      Data.data.accountData.info_id = '1';
      loginSuccess = true;
    }

    if(loginSuccess === 'error') {
      await this.checkValidations();
      return;
    }

    if(loginSuccess === false) {
      this.errorText = 'Onjuiste gebruikersnaam en/of wachtwoord';
      this.setState({showError: true, clockLogin: false});
      return false;
    }

    if(Data.data.accountData.account_rights === '0') {
      this.errorText = 'De desktop versie is alleen bedoeld voor planners en administratoren';
      this.setState({showError: true, clockLogin: false});
      return false;
    }

    localStorage.setItem('@username', this.state.username);
    localStorage.setItem('@password', encrypted === true ? this.state.password : CryptoJS.SHA512(this.state.password).toString());

    if (clock === false) {

      localStorage.setItem('@lastLoggedScreen', 'default');

      if (this.state.saveAcc === true) {
        this.checkMultipleAccounts();
      }

    } else {

      localStorage.setItem('@lastLoggedScreen', 'clock');

    }

    // EXTRA
    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, `WHERE info2_info_id = ${Data.data.accountData.info_id}`, null, null);
    if (infoExtra.length > 0) {
      Data.data.accountDataExtra = infoExtra[0];
    }

    const companyLogo = await APIGET.Request(`SELECT *`, `FROM BedrijfsLogo`, null, null, null, null);
    if (companyLogo.length > 0) {
      Data.data.storeLogo = `${companyLogo[0].bl_loc}`;
    }

    // PACKAGE
    await this.checkPackage();

    // TEAMS
    Data.data.teams = await APIGetTeams.Request();
    Data.data.ownTeams = await APIGetTeamsOwn.Request(Data.data.accountData.account_id);

    if (Data.data.teams.length > 1 && Data.data.accountData.account_rights === '2') {
      this.setState({teams: Data.data.teams}, () => {
        Data.data.ownTeams = Data.data.teams;
        this.setState({showTeamsModal: true});
      });
      return false;
    }
    if (Data.data.teams.length > 1 && Data.data.accountData.account_rights === '1') {
      this.setState({teams: Data.data.ownTeams}, () => {
        this.setState({showTeamsModal: true});
      });
      return false;
    }

    return true;

  }

  checkMultipleAccounts() {

    let multiAccounts = this.state.multiAccounts;

    for (const acc of multiAccounts) {
      if (acc.username.toLowerCase() === Data.data.accountData.account_username.toLowerCase() && acc.storecode.toLowerCase() === Data.data.storeCode.toLowerCase()) {
        Constants.removeFromArray(multiAccounts, acc);
        break;
      }
    }

    const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

    multiAccounts.push({
      username: Data.data.accountData.account_username,
      pass: Data.data.accountData.account_password,
      rights: Data.data.accountData.account_rights,
      storecode: Data.data.storeCode,
      name: userFullName,
    });

    localStorage.setItem('@multiAccounts', JSON.stringify(multiAccounts));
    Data.data.multiAccounts = multiAccounts;

  }

  openSubscriptionModal() {

    this.setState({
      showSubscriptionModal: true, 
    });

  }

  openRegisterCodeModal() {

    this.setState({
      showModal: true, 
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      showSubscriptionModal: false,
    });

  }

  forgotUsernameAlert() {
    this.setState({
        alertTitle: 'Gebruikersnaam vergeten',
        alertBody: `Neem contact op met jouw leidinggevende om jouw gebruikersnaam op te vragen`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({showAlert: false});

  }

  handleKey(e) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.login();

      return false;
    }

  }

  async editNewOptions(set, value, bool = true) {

     await APIEditOptions2.Request(set, bool ? value === true ? '1' : '0' : value, Data.data.appOptions.id);

     Data.data.appOptions = {
      ...Data.data.appOptions,
      [set]: bool ? value === true ? '1' : '0' : value,
    };

    // this.setState({
    //   [set]: value,
    // }, async () => {

    //   if (set === 'opties_versie' && value === '0') {

    //     await Promise.all([
    //       this.editNewOptions('opties_urenregistratie2', false),
    //       this.editNewOptions('opties_uren_daadwerkelijk', false),
    //       this.editNewOptions('opties_verlofsaldo', false),
    //       this.editNewOptions('opties_financien', false),
    //       this.toggleSwitchClockFALSE(),
    //       this.editNewOptions('opties_planner_loonkosten', false),
    //       this.editNewOptions('opties_planner_vorigOmzet', false),
    //       this.editNewOptions('opties_planner_verwachteOmzet', false),
    //       this.editNewOptions('opties_planner_omzet', false),
    //       this.editNewOptions('opties_planner_productiviteit', false),
    //       this.editNewOptions('opties_planner_berekeningen', false),
    //       this.editNewOptions('opties_overuren', false),
    //     ]);

    //   }

    //   if (set === 'opties_versie' && value === '1') {

    //     await Promise.all([
    //       //this.editNewOptions('opties_urenregistratie2', false),
    //       //this.editNewOptions('opties_uren_daadwerkelijk', false),
    //       this.editNewOptions('opties_verlofsaldo', false),
    //       this.editNewOptions('opties_financien', false),
    //       this.toggleSwitchClockFALSE(),
    //       this.editNewOptions('opties_planner_loonkosten', false),
    //       this.editNewOptions('opties_planner_vorigOmzet', false),
    //       this.editNewOptions('opties_planner_verwachteOmzet', false),
    //       this.editNewOptions('opties_planner_omzet', false),
    //       this.editNewOptions('opties_planner_productiviteit', false),
    //       this.editNewOptions('opties_planner_berekeningen', false),
    //       this.editNewOptions('opties_overuren', false),
    //     ]);

    //   }

    //   if (set === 'opties_urenregistratie2' && value === false) {

    //     await Promise.all([
    //       this.editNewOptions('opties_overuren', false),
    //       this.editNewOptions('opties_uren_daadwerkelijk', false),
    //       this.editNewOptions('opties_financien', false),
    //       this.toggleSwitchClockFALSE(),
    //     ]);

    //   }

    //   if (set === 'opties_financien' && value === false) {

    //     await Promise.all([
    //       this.editNewOptions('opties_planner_loonkosten', false),
    //       this.editNewOptions('opties_planner_vorigOmzet', false),
    //       this.editNewOptions('opties_planner_verwachteOmzet', false),
    //       this.editNewOptions('opties_planner_omzet', false),
    //       this.editNewOptions('opties_planner_productiviteit', false),
    //       this.editNewOptions('opties_planner_berekeningen', false),
    //     ]);

    //   }

    //   if (set === 'opties_uren_daadwerkelijk' && value === false) {

    //     await Promise.all([
    //       this.editNewOptions('opties_overuren', false),
    //     ]);

    //   }

    // });

  }

  async checkPackage() {

    let promises = [];
    let changed = false;

    if (Data.data.appOptions.opties_bsn === '0') {
      promises.push(this.editNewOptions('opties_bsn', true));
      changed = true;
    }

    if (Data.data.appOptions.opties_iban === '0') {
      promises.push(this.editNewOptions('opties_iban', true));
      changed = true;
    }

    // ONLY SCHEDULE (FORMER REGULAR)
    if (Data.data.appOptions.opties_versie === '1') {

      if (Data.data.appOptions.opties_urenregistratie2 === '1') {
        promises.push(APIEDIT.Request(`UPDATE Opties`, `SET opties_urenregistratie2 = 0`, `WHERE opties_id = ${Data.data.appOptions.id}`));
        changed = true;
      }

      if (Data.data.appOptions.opties_financien === '0') {
        promises.push(APIEDIT.Request(`UPDATE Opties`, `SET opties_financien = 1`, `WHERE opties_id = ${Data.data.appOptions.id}`));
        changed = true;
      }

      if (Data.data.appOptions.opties_verlofsaldo === '1') {
        promises.push(this.editNewOptions('opties_verlofsaldo', false));
        changed = true;
      }

      if (Data.data.appOptions.opties_inklok === '1') {
        promises.push(this.editNewOptions('opties_inklok', false));
        changed = true;
      }

      // break is clock system?
      if (Data.data.appOptions.opties_pauze === '1') {
        promises.push(this.editNewOptions('opties_pauze', false));
        changed = true;
      }

      if (Data.data.appOptions.opties_urenregistratie === '0') {
        promises.push(this.editNewOptions('opties_urenregistratie', true));
        changed = true;
      }

      if (Data.data.appOptions.opties_uren_daadwerkelijk === '1') {
        promises.push(this.editNewOptions('opties_uren_daadwerkelijk', false));
        changed = true;
      }

      if (Data.data.appOptions.opties_overuren === '1') {
        promises.push(this.editNewOptions('opties_overuren', false));
        changed = true;
      }

    }

    // ONLY SCHEDULE (FORMER REGULAR)
    if (Data.data.appOptions.opties_versie === '2') {

      if (Data.data.appOptions.opties_urenregistratie2 === '0') {
        promises.push(APIEDIT.Request(`UPDATE Opties`, `SET opties_urenregistratie2 = 1`, `WHERE opties_id = ${Data.data.appOptions.id}`));
        changed = true;
      }

      if (Data.data.appOptions.opties_financien === '0') {
        promises.push(APIEDIT.Request(`UPDATE Opties`, `SET opties_financien = 1`, `WHERE opties_id = ${Data.data.appOptions.id}`));
        changed = true;
      }

      if (Data.data.appOptions.opties_verlofsaldo === '0') {
        promises.push(this.editNewOptions('opties_verlofsaldo', true));
        changed = true;
      }

      if (Data.data.appOptions.opties_inklok === '0') {
        promises.push(this.editNewOptions('opties_inklok', true));
        changed = true;
      }

      if (Data.data.appOptions.opties_urenregistratie === '0') {
        promises.push(this.editNewOptions('opties_urenregistratie', true));
        changed = true;
      }

      if (Data.data.appOptions.opties_uren_daadwerkelijk === '0') {
        promises.push(this.editNewOptions('opties_uren_daadwerkelijk', true));
        changed = true;
      }

      if (Data.data.appOptions.opties_overuren === '0') {
        promises.push(this.editNewOptions('opties_overuren', true));
        changed = true;
      }

    }

    // SCHEDULE + CLOCK SYSTEM + TIME REGISTRATION (ENTERPRISE)

    // // enterprise // regular
    // if (Data.data.appOptions.opties_versie === '2' || Data.data.appOptions.opties_versie === '1') {

    //   if (Data.data.appOptions.opties_urenregistratie2 === '0') {
    //     promises.push(APIEDIT.Request(`UPDATE Opties`, `SET opties_urenregistratie2 = 1`, `WHERE opties_id = ${Data.data.appOptions.id}`));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_financien === '0') {
    //     promises.push(APIEDIT.Request(`UPDATE Opties`, `SET opties_financien = 1`, `WHERE opties_id = ${Data.data.appOptions.id}`));
    //     changed = true;
    //   }

    // }

    // // starter
    // if (Data.data.appOptions.opties_versie === '0') {

    //   if (Data.data.appOptions.opties_inklok === '1') {
    //     promises.push(this.editNewOptions('opties_inklok', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_pauze === '1') {
    //     promises.push(this.editNewOptions('opties_pauze', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_urenregistratie === '1') {
    //     promises.push(this.editNewOptions('opties_urenregistratie', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_urenregistratie2 === '1') {
    //     promises.push(this.editNewOptions('opties_urenregistratie2', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_uren_daadwerkelijk === '1') {
    //     promises.push(this.editNewOptions('opties_uren_daadwerkelijk', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_verlofsaldo === '1') {
    //     promises.push(this.editNewOptions('opties_verlofsaldo', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_loonkosten === '1') {
    //     promises.push(this.editNewOptions('opties_planner_loonkosten', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_vorigOmzet === '1') {
    //     promises.push(this.editNewOptions('opties_planner_vorigOmzet', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_verwachteOmzet === '1') {
    //     promises.push(this.editNewOptions('opties_planner_verwachteOmzet', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_omzet === '1') {
    //     promises.push(this.editNewOptions('opties_planner_omzet', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_productiviteit === '1') {
    //     promises.push(this.editNewOptions('opties_planner_productiviteit', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_berekeningen === '1') {
    //     promises.push(this.editNewOptions('opties_planner_berekeningen', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_planner_contracturen === '1') {
    //     promises.push(this.editNewOptions('opties_planner_contracturen', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_financien === '1') {
    //     promises.push(this.editNewOptions('opties_financien', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_chat === '1') {
    //     promises.push(this.editNewOptions('opties_chat', false));
    //     changed = true;
    //   }

    //   if (Data.data.appOptions.opties_overuren === '1') {
    //     promises.push(this.editNewOptions('opties_overuren', false));
    //     changed = true;
    //   }

    // }

    if (changed === true) {

      await Promise.all(promises);

      const options = await APIGetOptions.Request();
      Data.data.appOptions = {
          id: parseInt(options[0].opties_id),
          clock: options[0].opties_inklok === '1' ? true : false,
          brk: options[0].opties_pauze === '0' ? 'PLANNER' : options[0].opties_pauze === '1' ? 'CLOCK' : 'CALC',
          hours: options[0].opties_urenregistratie === '0' ? 'PLANNER' : 'CLOCK',
          availability: options[0].opties_beschikbaarheid === '0' ? 'AVAILABILITY' : options[0].opties_beschikbaarheid === '1' ? 'SCHOOL' : options[0].opties_beschikbaarheid === '2' ? 'UNAVAILABILITY' : options[0].opties_beschikbaarheid === '3' ? 'STANDARD' : 'NONE',
          open: options[0].opties_opendienst === '1' ? true : false,
          iban: options[0].opties_iban === '1' ? true : false,
          bsn: options[0].opties_bsn === '1' ? true : false,
          ...options[0],
        };

    }

  }

  render() {

    return (
      <div>

        {this.state.autoLogin === true ?

          <div>
        
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            {this.state.showTeamsModal === true ?
                
                <TeamsModal 
                  _closeModal={this.closeModal.bind(this)}
                  _teams={this.state.teams}
                  _mainStoreCode={this.state.storeCode}
                  _login={true}
                  _clock={this.state.clockLogin}
                  history={this.props.history}
                />
  
              :
  
                null
              
              }

          </div>

        :

          <div className='login'>

            {this.state.showAlert === true ?
                
              <BetterAlert 
                _closeAlert={this.closeAlert.bind(this)}
                _title={this.state.alertTitle}
                _body={this.state.alertBody}
                _buttons={this.state.alertButtons}
                _buttonText={this.state.alertButtonText}
                _buttonAction={this.state.alertButtonAction}
              />

            :

              null
            
            }

            {this.state.showModal === true ?
                
              <RegisterCodeModal 
                _closeModal={this.closeModal.bind(this)}
                history={this.props.history}
              />

            :

              null
            
            }

            {this.state.showTeamsModal === true ?
                
              <TeamsModal 
                _closeModal={this.closeModal.bind(this)}
                _teams={this.state.teams}
                _mainStoreCode={this.state.storeCode}
                _login={true}
                _clock={this.state.clockLogin}
                history={this.props.history}
              />

            :

              null
            
            }

            {this.state.showSubscriptionModal === true ?
                
              <SubscriptionModal 
                _closeModal={this.closeModal.bind(this)}
                _errorText={this.state.subModalError}
                history={this.props.history}
              />

            :

              null
            
            }

            <div className='login_sub1'>

              <div className='login_sub1_outer'>

                <div className={'login_logoContainer'}>

                  <img src={`https://tiemdo.com/imgs/logo/logo_dark.png?v=${Data.data.logo_version}`} alt='logo' className='logo' />

                </div>

              </div>

              <div className='login_sub1_inner'>

                <div className='login_sub1_inner_sub1'>

                  <span className='login_subTitle'>{Localization.t.login_title}</span>

                  <input 
                    onKeyDown={(e) => this.handleKey(e)} 
                    onChange={(event) => this.setState({ storeCode: event.target.value, showError: false })} 
                    className='login_inputField' 
                    type="text"
                    placeholder={Localization.t.login_companycode}
                  />

                  <input 
                    onKeyDown={(e) => this.handleKey(e)} 
                    onChange={(event) => this.setState({ username: event.target.value, showError: false })} 
                    className='login_inputField' 
                    style={{marginTop: 10}}  
                    type="text"
                    placeholder={Localization.t.login_username}
                  />

                  <input 
                    onKeyDown={(e) => this.handleKey(e)} 
                    onChange={(event) => this.setState({ password: event.target.value, showError: false })} 
                    className='login_inputField' 
                    style={{marginTop: 10}} 
                    type="password" 
                    placeholder={Localization.t.login_password}
                  />

                  <div className='login_errorSection'> 
                    {this.showError()}
                  </div>

                  <div className='defaultLink' style={{marginLeft: '8%', display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start', fontSize: '1vw', width: '80%'}} onClick={() => { localStorage.setItem('@saveAcc', this.state.saveAcc === true ? '0' : '1'); this.setState({ saveAcc: !this.state.saveAcc }); }}>
                    {this.state.saveAcc === false ?
                      <i className='fal fa-square' style={{fontSize: '0.8vw', marginRight: '1%'}} />
                    : 
                      <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1, marginRight: '1.3%'}} />
                    }
                    <span>{Localization.t.login_remember}</span>
                  </div>

                  <a href='https://tools.tiemdo.com/nl/forgotten-storecode' target='_blank' rel='noopener noreferrer' className='defaultLink' style={{fontSize: '1vw', marginTop: '4%', alignSelf: 'flex-start', display: 'flex', width: '80%', alignItems: 'center', marginLeft: '8%'}}><i className='fas fa-question-circle' style={{marginRight: '1%'}} />{Localization.t.login_forgot_companycode}</a>

                  <a href='https://tools.tiemdo.com/nl/forgotten-password' target='_blank' rel='noopener noreferrer' className='defaultLink' style={{fontSize: '1vw', marginTop: '1%', alignSelf: 'flex-start', display: 'flex', width: '80%', alignItems: 'center', marginLeft: '8%'}}><i className='fas fa-question-circle' style={{marginRight: '1%'}} />{Localization.t.login_forgot_password}</a>

                </div>

                <div className='login_sub1_inner_sub2'>

                  {this.state.loading === true ?
                    <div className='login_loginButton_loading'>
                      <div className="lds-dual-ring-button"></div>
                    </div>
                  :
                    <div className='login_loginButton' onClick={() => this.login()}>
                      <i className='fas fa-sign-in-alt login_buttonIcon' style={{color: 'white'}} />
                      <span>{Localization.t.login_button}</span>
                    </div>
                  }

                  {this.state.loading === true ?
                    <div className='login_loginButton_alt_loading'>
                      <div className="lds-dual-ring-button"></div>
                    </div>
                  :
                    <div className='login_loginButton_alt' onClick={() => window.open('https://clock.tiemdo.com', '_blank')}>
                      <i className='fas fa-stopwatch login_buttonIcon' style={{color: 'white', marginTop: 1}} />
                      <span>{Localization.t.login_button_clock}</span>
                    </div>
                  }

                </div>

              </div>

              <div className='login_sub1_outer'>

              </div>

            </div>

            <div className='login_sub2'>

              {/* <div className='login_sub2_outer'>

              </div> */}

              {this.state.multiAccounts.length > 0 ?

                <div className='login_sub2_inner'>

                  <span className='login_subTitle_alt' style={{marginBottom: '1vw'}}>{Localization.t.login_last}</span>

                  {this.getAccountBars()}

                </div>

              :

                <div className='login_sub2_inner'>

                  <span className='login_subTitle_alt'>{Localization.t.login_no_account}</span>

                  <span className='defaultLink' style={{fontSize: '1vw', marginTop: '2%'}} onClick={() => window.open('https://tools.tiemdo.com/nl/trial-signup')}><i className='fas fa-user-plus' style={{marginRight: '1%'}} />{Localization.t.login_signup}</span>

                  {/* <span className='defaultLink' style={{fontSize: '1vw', marginTop: '2%'}}><i className='fas fa-envelope' style={{marginRight: '1%'}} />Ik wil een proefversie aanvragen</span> */}

                </div>

              }

              {/* <div className='login_sub2_outer'>

              </div> */}

            </div>

            {/* <div className='loginBox'>

              <div className='loginBoxContent'>

                <div className={'login_logoContainer'}>

                  <img src={`https://kodict.nl/site/assets/imgs/tiempokodict_light.png?v=${Data.data.logo_version}`} alt='logo' className='logo' style={{marginBottom: 40}} />

                </div>

                <div className={'login_inputContainer'}>

                  <input onKeyDown={(e) => this.handleKey(e)} onChange={(event) => this.setState({ storeCode: event.target.value, showError: false })} className='inputField' type="text" id="storeCode" placeholder='Bedrijfscode'/>

                  <input onKeyDown={(e) => this.handleKey(e)} onChange={(event) => this.setState({ username: event.target.value, showError: false })} className='inputField' style={{marginTop: 10}}  type="text" id="username" placeholder='Gebruikersnaam'/>

                  <input onKeyDown={(e) => this.handleKey(e)} onChange={(event) => this.setState({ password: event.target.value, showError: false })} className='inputField' style={{marginTop: 10}} type="password" id="password" placeholder='Wachtwoord'/>

                  {this.showError()}

                  <a href='https://rooster.kodict.nl/#/store_code' target='_blank' rel='noopener noreferrer' style={{marginTop: '0.3vw'}}><i className='far fa-question-circle' style={{marginRight: 5}}></i>Bedrijfscode opzoeken</a>

                  <div className='loginlink' style={{marginTop: '0.2vw', cursor: 'pointer'}} onClick={() => this.forgotUsernameAlert()}><i className='far fa-question-circle' style={{marginRight: 5}}></i>Ik ben mijn gebruikersnaam vergeten</div>

                  <a href='https://rooster.kodict.nl/#/forgot_password' target='_blank' rel='noopener noreferrer' style={{marginTop: '0.2vw'}}><i className='far fa-question-circle' style={{marginRight: 5}}></i>Ik ben mijn wachtwoord vergeten</a>

                </div>

                <div className={'login_buttonContainer'}>

                  <button className='button' onClick={() => this.openRegisterCodeModal()}><FontAwesomeIcon icon={faUserPlus} size='lg' className='buttonIcon' />Registreren</button>

                  {this.state.loading === false ?

                    <button className='loginButton' style={{marginTop: 10}} onClick={() => this.login()}><FontAwesomeIcon icon={faSignInAlt} size='lg' className='buttonIcon' style={{color: 'white'}} />Inloggen</button>

                  :

                    <button disabled={this.state.loading} className='loginButtonLoading' style={{marginTop: 10}}><div className="lds-dual-ring-button"></div></button>

                  }

                </div>

              </div>

            </div> */}

          </div>

        }

      </div>
    );
  }
  
}

export default Login;