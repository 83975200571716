/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/blocked/blocked.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import APIAddBlockedDay from '../../classes/APIAddBlockedDay';
import APIEditBlockedDay from '../../classes/APIEditBlockedDay';
import APIDeleteBlockedDay from '../../classes/APIDeleteBlockedDay';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';
import APIEDIT from '../../classes/global/APIEDIT';
import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';

class ContractEndModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        date: new Date(),
        dateText: Constants.dateToString(new Date()),

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        date: date,
        dateText: dateText,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze geblokkeerde dag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteBlocked.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveBlocked() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }
    let added = null;

    if(this.props._hasContract === true) {
      added = await APIEDIT.Request(`UPDATE Contract`, `SET contract_eind = '${this.state.dateText}'`, `WHERE contract_info_id = ${this.modalData.info_id}`);
    } else {
      added = await APIADD.Request(`INSERT INTO Contract (contract_id, contract_info_id, contract_eind)`, `VALUES (NULL, ${this.modalData.info_id}, '${this.state.dateText}')`);
    }

    if(added === true) {

        let date = this.state.dateText;
        if (this.state.date.getTime() < new Date().getTime()) {
            date = Constants.dateToString(new Date());
        }

        await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_info_id = NULL, rooster_publiceren = 3`, `WHERE rooster_info_id = ${this.modalData.info_id} AND rooster_datum >= '${date}'`);

        // const ownSchedule = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_info_id = ${this.modalData.info_id} AND rooster_datum >= '${date}'`, null, null);

        // const promises = ownSchedule.map(schedule => APIEDIT.Request(`UPDATE Rooster`, `SET rooster_info_id = NULL, rooster_publiceren = 3`, `WHERE rooster_id = ${schedule.rooster_id}`));

        // await Promise.all(promises);

        this.closeModal();

        await this.reloadScreen();

        return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.date === null || this.state.dateText === '') {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen einddatum geselecteerd',
        });
        return false;
      }

    return true;

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="contractEndModal" className="modal" onClick={(e) => e.target === document.getElementById("zcontractEndModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            <p>Contract beëindigen</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Einddatum:</div>

                    <DatePicker
                      selected={this.state.date}
                      onChange={(date) => this.setDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className={'inputFieldTime'}
                      placeholderText={'Selecteer een einddatum'}
                      locale={nl}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    <div className='submitButton' style={{color: Colors.color.redFilledIn}} onClick={() => this.saveBlocked()}>Contract beëindigen</div>

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default ContractEndModal;