


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIRegisterAccountInfo {

    async Request(username, id, first, insert, last, phone, email, fid, extra, address, number, zip, birth, privacy, startDate, ibnr, idnr, city, phone2, birthplace) { //id, first, insert, last, phone, email, fid, extra, address, number, zip, birth
        username = username.toLowerCase();

        first = first.charAt(0).toUpperCase() + first.substring(1);

        insert = (insert !== null && insert !== '') ? insert.toLowerCase() : '';

        last = last.charAt(0).toUpperCase() + last.substring(1);

        email = email.toLowerCase();

        //remove spaces at end
        first = first.replace(/\s*$/,"");
        insert = (insert !== null && insert !== '') ? insert.replace(/\s*$/,"") : insert;
        last = last.replace(/\s*$/,"");
        phone = phone.replace(/\s*$/,"");
        phone2 = phone2.replace(/\s*$/,"");
        email = email.replace(/\s*$/,"");
        address = address.replace(/\s*$/,"");
        number = number.replace(/\s*$/,"");
        zip = zip.replace(/\s*$/,"");
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/registerAccountInfo.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&id=${id}&first=${first}&insert=${insert}&last=${last}&phone=${phone}&email=${email}&fid=${fid}&extra=${extra}&address=${address}&number=${number}&zip=${zip}&birth=${birth}&privacy=${privacy}&startDate=${startDate}&ibnr=${ibnr}&idnr=${idnr}&city=${city}&phone2=${phone2}&birthplace=${birthplace}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }


        if (sqlData === "1") {

            return true;

        } else if (sqlData === "0") {

            return false;

        } else {

            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIRegisterAccountInfo());