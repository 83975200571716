/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './absencetypes.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APIGetDefaultShifts from '../../../classes/APIGetDefaultShifts';

import 'react-datepicker/dist/react-datepicker.css';
//import BetterAlert from '../../../components/alert/alert';
import AbsencetypesModal from './absencetypesModal';
import APIGetParticular from '../../../classes/APIGetParticular';
import APIGetAbsencetypes from '../../../classes/APIGetAbsencetypes';

class Absencetypes extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      times: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    const bijzonderheden = await APIGetAbsencetypes.Request();

    this.setState({
      loading: false, 
      times: bijzonderheden
    });

  }

  openTimesModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    const bijzonderheden = await APIGetAbsencetypes.Request();

    this.setState({
      reloading: false, 
      times: bijzonderheden
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getTimeBars() {

    let times = [];

    for(let key in this.state.times) {

      const bijz = this.state.times[key];

        times.push(
            <div key={key} 
              className={'absencetypes_timeContainer'} 
              onClick={() => this.openTimesModal(1, bijz)}
            >

              <div className='absencetypes_row'>
                <div className='absencetypes_sub1'>
                  Naam:
                </div>
                <div className='absencetypes_sub2'>
                  {bijz.afw_naam}
                </div>
              </div>

              <div className='absencetypes_row' style={{marginTop: 5}}>
                <div className='absencetypes_sub1'>
                  Afkorting:
                </div>
                <div className='absencetypes_sub2'>
                  {bijz.afw_afkorting}
                </div>
              </div>

            </div>
        );

    }

    return times;

  }

  render() {

    const timeBars = this.getTimeBars();

    return (
        <div className='times'>

          <Navbar history={this.props.history} active={'absencetypes'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Afwezigheidstypes
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
                {/* <i className='fa fa-plus'></i> */}
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='absencetypes_timesMainContainer'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <AbsencetypesModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              {/* {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              } */}

              <div className="planner_addButtons_container" style={{marginLeft: 0, marginBottom: '2vw'}}>

                <div className="planner_new_addButton" onClick={() => this.openTimesModal(0)}>
                  {/* <i className={'fal fa-plus'} /> */}
                  Toevoegen
                </div>

              </div>

              {this.state.times.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen afwezigheidstypes aangemaakt</div>
                </div>

              :

                null
              
              }

              {timeBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Absencetypes;