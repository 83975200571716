


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIGetAllUnpublished {

    async Request(team) {

        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        if(typeof team === 'undefined' || team === null) {

            try {
                response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/getAllUnpublished.php/`, {
                    method: 'post',
                    body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&hash=${hash}`)
                });
            } catch(err) {
                return 'error';
            }

        } else {

            try {
                response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/getAllUnpublishedTeam.php/`, {
                    method: 'post',
                    body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&team=${team}&hash=${hash}`)
                });
            } catch(err) {
                return 'error';
            }

        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }


        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return null;

        } else {

            return null; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIGetAllUnpublished());