/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './changeAccountModal.css';
import '../../constants/DefaultStyle.css';
import '../../pages/planner/planner.scss';

//js components & classes
import Colors from '../../constants/Colors';
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';
import BetterAlert from '../alert/alert';

class ChangeAccountModal extends React.Component {

  constructor(props) {

    super(props);

	this.props = props;
    
    this.state = {

		showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

    //componentWillMount here:

  }

  componentDidMount() {

  }

  loginNewAcc(acc) {

	let multiAccounts = Data.data.multiAccounts;

	Constants.removeFromArray(multiAccounts, acc);

	multiAccounts.push({
	  username: acc.username,
	  pass: acc.pass,
	  rights: acc.rights,
	  storecode: acc.storecode,
	  name: acc.name,
	});

	localStorage.setItem('@multiAccounts', JSON.stringify(multiAccounts));
	Data.data.multiAccounts = multiAccounts;

	Data.data.accountData = [];
	Data.data.accountDataExtra = [];
	Data.data.loggedIn = false;
	Data.data.serverIP = '';
	Data.data.storeCode = '';
	Data.data.storeName = '';
	Data.data.storePhone = '';
	Data.data.storeMail = '';
	Data.data.storeCountry = '';
	Data.data.storeAddress = '';
	Data.data.storeZip = '';
	Data.data.storeCity = '';
	Data.data.storeKvK = '';
	Data.data.pakketID = 0;
	Data.data.storeTimezone = '';
	Data.data.storeCurrency = 'EUR';
	Data.data.trial = null;
	Data.data.teams = [];
	Data.data.ownTeams = [];
	Data.data.chosenTeam = null;
	Data.data.chosenTeamObj = null;

	localStorage.setItem('@storecode', acc.storecode);
	localStorage.setItem('@username', acc.username);
	localStorage.setItem('@password', acc.pass);

	this.props.history.push("/");

  }

  getAccountBars() {

    let accs = [];

	let multiAccounts = [];
	for (const acc of Data.data.multiAccounts) {
	  multiAccounts.push(acc);
	}

	multiAccounts.reverse();

    for(const acc of multiAccounts) {

		accs.push(

			<div className='changeAccModal_accountBar' style={{marginTop: accs.length > 0 ? '0.8vw' : 0}}>

				<i className='fas fa-minus-circle changeAccModal_accountBar_remove' onClick={() => { Constants.removeFromArray(Data.data.multiAccounts, acc); localStorage.setItem('@multiAccounts', JSON.stringify(Data.data.multiAccounts)); this.forceUpdate(); }} />

				<div className='changeAccModal_accountBar_sub1'>

					<span><b>{acc.storecode}</b></span>

					<span style={{marginTop: 5}}>{acc.name}</span>
					<span><i>{acc.username}</i></span>

				</div>

				<div className='changeAccModal_accountBar_sub2' onClick={() => this.loginNewAcc(acc)}>

					<i className='fas fa-arrow-right' />
					
				</div>

			</div>

        );

    }

    return accs;

  }

	stopTutorialAlert() {

		this.setState({
			alertTitle: 'Web-uitleg afsluiten',
			alertBody: (
			  <div>
	
				Wil je stoppen met het volgen van de web-uitleg?
	
			  </div>
			),
			alertButtons: 2,
			alertButtonText: ['Doorgaan', 'Afsluiten'],
			alertButtonAction: this.stopTutorial.bind(this),
			showAlert: true,
		});
	
	}

	closeAlert() {

		this.setState({ showAlert: false });
	
	  }

  render() {

    return (
        <div id="changeAccModal" className="changeAccModal" onClick={(e) => e.target === document.getElementById("zchangeAccModal") ? this.props._closeModal() : null}>

			{this.state.showAlert === true ?
                
				<BetterAlert
					_closeAlert={this.closeAlert.bind(this)}
					_title={this.state.alertTitle}
					_body={this.state.alertBody}
					_buttons={this.state.alertButtons}
					_buttonText={this.state.alertButtonText}
					_buttonAction={this.state.alertButtonAction}
				/>

			:

			null
			
			}

        <div className="changeAccModal-content">

          <div className="changeAccModal-header">

			{this.props._login !== true ?
            	<span className="changeAccModal-close" onClick={() => this.props._closeModal()}>&times;</span>
			: null}

            <span>Selecteer een account</span>

          </div>

            <div className="changeAccModal-body">

                {this.getAccountBars()}

            </div>

        </div>

      </div>
    );
  }
  
}

export default ChangeAccountModal;