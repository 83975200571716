/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../planner/planner.scss';
import '../../constants/DefaultStyle.css';
import './clockSystem.css';

//js components & classes
import Data from '../../constants/Data';

import BetterAlert from '../../components/alert/alert';

class ClockAdminModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    this.state = {

        loading: true,

        showError: false,
        errorText: '',

        selected: '',
        selectedAcc: null,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

    this.setState({
        loading: false,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  selectAccount(e) {

    this.setState({ selectedAcc: e.target.value });

  }

  getAccountBars() {

    let toReturn = [];

    if (this.state.selected === 'in') {

        for (const acc of this.props._accounts) {

            const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

            toReturn.push(
            <option key={acc.info_id} value={acc.info_id} selected={this.state.selectedAcc === acc.info_id}>{userFullName}</option>
            );

        }

    } else if (this.state.selected === 'out') {

        for (const acc of this.props._accountsActive) {

            const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

            toReturn.push(
            <option key={acc.info_id} value={acc.info_id} selected={this.state.selectedAcc === acc.info_id}>{userFullName}</option>
            );

        }

    }

    return toReturn;

  }

  render() {

    return (
        <div id="clockAdminModal" className="modal" onClick={(e) => e.target === document.getElementById("zclockAdminModal") ? this.props._closeModal() : null}>

        <div className="modal-content" style={{width: '25%'}}>

            <div className="modal-header">
                <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
                <p>Managers menu</p>
            </div>

            <div className="modal-body" style={{paddingBottom: 0, marginBottom: '5%'}}>

                {this.state.loading === true ?
        
                    <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                : null}

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='cs_modal_bar' style={{color: this.state.selected === 'in' ? '#47B7EB' : null}} onClick={() => this.setState({ selected: 'in' })}>
                    <div className='cs_modal_bar_sub1'>
                        Een medewerker inklokken
                    </div>
                    <div className='cs_modal_bar_sub2'>
                        <i className='fas fa-chevron-right' />
                    </div>
                </div>

                <div className='cs_modal_bar' style={{color: this.state.selected === 'out' ? '#47B7EB' : null}} onClick={() => this.setState({ selected: 'out' })}>
                    <div className='cs_modal_bar_sub1'>
                        Een medewerker uitklokken
                    </div>
                    <div className='cs_modal_bar_sub2'>
                        <i className='fas fa-chevron-right' />
                    </div>
                </div>

                {this.state.selected !== '' ?

                    <div className='cs_modal_bottom'>

                        <div className='cs_modal_bottom_sub1'>

                            <select
                              name="Selecteer een medewerker"
                              className={'cs_dropdown'}
                              onChange={(event) => this.selectAccount(event)}
                            >
                              <option value={'null'} disabled selected>Selecteer een medewerker</option>
                              {this.getAccountBars()}
                            </select>

                        </div>

                        <div className='cs_modal_bottom_sub2'>

                            <div className='cs_modal_button' onClick={() => this.state.selected === 'in' ? this.props._clockInUser(this.state.selectedAcc, true, true) : this.state.selected === 'out' ? this.props._clockOutUser(this.state.selectedAcc, true) : null}>
                                {this.state.selected === 'in' ? 'Inklokken' : 'Uitklokken'}
                            </div>

                        </div>

                    </div>

                : null}

                <div className='cs_modal_bar_danger' onClick={() => this.props._clockAllOut()}>
                    <div className='cs_modal_bar_sub1'>
                        Alle medewerkers uitklokken
                    </div>
                    <div className='cs_modal_bar_sub2'>
                        <i className='fas fa-chevron-right' />
                    </div>
                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default ClockAdminModal;