/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/appOptions/appOptions.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';

import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIEDIT from '../../classes/global/APIEDIT';


class UserLeaveBalanceModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,

        name: '',
        factor: 0.00,
        days: 0,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    if (this.modalData !== null) {

        this.setState({
            factor: this.modalData.cvb_vb_factor,
        });

    }

    this.setState({ loading: false, });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkValidations() {

    if(Constants.isEmpty(this.state.factor) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen factor ingevuld`,
        });
        return false;
    }

    return true;

  }

reset() {

    for (const leave of this.props._leaveBalances) {

        if (leave.vb_id === this.modalData.cvb_vb_id) {
            this.onChangeNumberDataFloat('factor', 0, 1, { target: { value: leave.vb_factor } });
            break;
        }

    }

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async editBonus() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    const newLeave = {
      cvb_id: this.modalData.cvb_id,
      cvb_contract_id: this.modalData.cvb_contract_id,
      cvb_vb_id: this.modalData.cvb_vb_id,
      cvb_vb_factor: this.state.factor,
      vb_naam: this.modalData.vb_naam,
      vb_factor: this.modalData.vb_factor,
      vb_id: this.modalData.vb_id,
    };

    this.props._reloadScreen2(newLeave);
    this.closeModal();

    // if (this.modalData.cvb_id === -1 && this.modalData.cvb_contract_id === -1) {

    //   // method here with values

    //   const newLeave = {
    //     cvb_id: this.modalData.cvb_id,
    //     cvb_contract_id: this.modalData.cvb_contract_id,
    //     cvb_vb_id: this.modalData.cvb_vb_id,
    //     cvb_vb_factor: this.state.factor,
    //     vb_naam: this.modalData.vb_naam,
    //     vb_factor: this.modalData.vb_factor,
    //     vb_id: this.modalData.vb_id,
    //   };

    //   this.props._reloadScreen2(newLeave);
    //   this.closeModal();

    // } else {

    //   await APIEDIT.Request(`UPDATE ContractVerlofBalans`, `SET cvb_vb_factor = ${this.state.factor}`, `WHERE cvb_id = ${this.modalData.cvb_id}`);

    //   this.reloadScreen();
    //   this.closeModal();

    // }

  }

  onChangeNumberDataFloat(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'factor') {

      let absAmount = this.state.factor;

      if (nr !== '') {
        absAmount = parseFloat(nr);
      } else {
        absAmount = '';
      }

      this.setState({ factor: absAmount });

    }

  }

  onChangeNumberDataInt(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'days') {

      let absAmount = this.state.days;

      if (nr !== '') {

        absAmount = nr;

        const days = parseInt(absAmount);
        const contractHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === false && parseFloat(Data.data.appOptions.opties_dag_werkuren) !== 0 && isNaN(parseFloat(Data.data.appOptions.opties_dag_werkuren)) === false ? parseFloat(Data.data.appOptions.opties_dag_werkuren) : 8;
        const fte = Constants.isEmpty(Data.data.appOptions.opties_fteUren) === false && parseFloat(Data.data.appOptions.opties_fteUren) !== 0 && isNaN(parseFloat(Data.data.appOptions.opties_fteUren)) === false ? parseFloat(Data.data.appOptions.opties_fteUren) : 40;

        const maxHoursYear = fte * 52;
        const vacationMaxYear = contractHours * days;

        this.onChangeNumberDataFloat('factor', 0, 1, { target: { value: vacationMaxYear / maxHoursYear } });

      } else {
        absAmount = '';
      }

      this.setState({ days: absAmount });

    }

  }

  render() {

    return (
        <div id="leaveBalanceModal2" className="modal" onClick={(e) => e.target === document.getElementById("zleaveBalanceModal2") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            <p>Verlofopbouw wijzigen</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                          <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Dagen gebaseerd op fulltime contract</span>
                        </div>

                        <div className='modal-rowSub2'>

                        <input
                              className={'inputField2'}
                              onChange={(event) => this.onChangeNumberDataInt('days', 0, 366, event)}
                              value={this.state.days}
                              placeholder={'Aantal dagen'}
                              type='number'
                              step={1}
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Factor</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                           <input
                              className={this.state.days > 0 ? 'inputField2Disabled' : 'inputField2'}
                              onChange={(event) => this.onChangeNumberDataFloat('factor', 0, 1, event)}
                              value={this.state.factor}
                              placeholder={'Opbouwfactor'}
                              type='number'
                              step={0.000000001}
                              disabled={this.state.days > 0}
                          />

                          <span className='defaultLinkO' style={{marginTop: '0.5vw'}} onClick={() => this.reset()}>Terugzetten naar standaard</span>

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}
   
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                    <span>Annuleren</span>
                </div>

                <div className='modal-submitButton' onClick={() => this.editBonus()}>
                    <span>Wijzigen</span>
                </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default UserLeaveBalanceModal;