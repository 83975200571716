/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './uploadEmployeesModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';
import '../../pages/options/reports/reports.scss';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import Colors from '../../constants/Colors';

import XLSX from 'xlsx';
import APIADD from '../../classes/global/APIADD';

import CryptoJS from 'crypto-js';
import APIGET from '../../classes/global/APIGET';
import APIDELETE from '../../classes/global/APIDELETE';
import APISendNewAccountMail from '../../classes/APISendNewAccountMail';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGetAllRegistrationCodes from '../../classes/APIGetAllRegistrationCodes';
import APIAddToTeam from '../../classes/APIAddToTeam';
import APICheckUsernameAvailibility from '../../classes/APICheckUsernameAvailibility';
import { remove } from 'remove-accents';


class UploadEmployeesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,
        reloading: false,

        file: null,
        addedAccounts: [],
        failures: false,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        selectedTeams: [],

        sendLoginData: true,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    this.setState({loading: false});

  }

  goBack() {

    this.props._closeModal();

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  checkExcel(e) {

    this.setState({ showError: false, addedAccounts: [], failures: false, });  

    if (e.target.files.length === 0 || Constants.isEmpty(e.target.files[0])) {
      this.setState({ file: null });
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {

      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];

      const data2 = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false});

      this.setState({ file: data2 });

    }

    // const file = e.target.files[0];

    // const reader = new FileReader();
    // const rABS = !!reader.readAsBinaryString;
    // reader.onload = (e) => {
    //   /* Parse data */
    //   const bstr = e.target.result;
    //   const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
    //   /* Get first worksheet */
    //   const wsname = wb.SheetNames[0];
    //   const ws = wb.Sheets[wsname];
    //   /* Convert array of arrays */
    //   const data = XLSX.utils.sheet_to_json(ws, {header:1});

    //   console.log(data)

    // };

    // if(rABS) {
    //   reader.readAsBinaryString(file);
    // } else {
    //   reader.readAsArrayBuffer(file);
    // }

  }

  async importEmployees() {

    if (Constants.isEmpty(this.state.file)) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen bestand geselecteerd',
      });
      return;
    }  

    this.setState({ reloading: true });

    let addedAccounts = [];
    let failures = 0;
    let failuresEach = 0;
    let usernameExistsCount = 2; // so it starts with .....2 on the end like (jordyortega2)

    for (const key in this.state.file) {

      if (key === '0') { // skip headers
        continue;
      }

      if (failuresEach >= 5) {
        this.setState({
          showError: true,
          errorText: '5 achtereenvolgende rijen mislukt. Afgebroken',
        });
        break;
      }

      let count = 0;

      const employeesObject = await APIGetAllAccounts.Request();

      if(employeesObject !== null) {
          count += employeesObject.length;
      }

      const registrationCodesObject = await APIGetAllRegistrationCodes.Request();

      if(registrationCodesObject !== null) {
          count += registrationCodesObject.length;
      }

      if(count >= (Data.data.maxEployees + 1)) {
          this.setState({
            alertTitle: 'Mislukt',
            alertBody: (<span>Je hebt het maximale aantal registratiecodes en medewerkers van <b>{Data.data.maxEployees}</b> bereikt</span>),
            alertButtons: 10,
            alertButtonText: ['OK'],
            // alertButtonText: ['Terug', 'Upgrade'],
            //alertButtonAction: () => this.openUpgradeMenu(),
            showAlert: true,
          });
          failures++;
          break;
      } 

      const row = this.state.file[key];

      // 0 = info_voornaam
      // 1 = info_tussenvoegsel
      // 2 = info_achternaam
      // 3 = info_geboorte
      // 4 = info_geboorteplaats
      // 5 = info_email
      // 6 = info_telefoon
      // 7 = info_adres
      // 8 = info_huisnummer
      // 9 = info_postcode
      // 10 = info_plaats
      // 11 = info_indienst
      // 12 = info_ibnr
      // 13 = info_idnr

      //  || Constants.isEmpty(row[6]) === true
      if (Constants.isEmpty(row[0]) === true || Constants.isEmpty(row[2]) === true || Constants.isEmpty(row[5]) === true) {
        addedAccounts.push({ name: null, success: false, text: <span style={{color: Colors.color.redFilledIn}}><i className='far fa-times' style={{color: Colors.color.redFilledIn}} /> (Voornaam, achternaam, e-mail)</span>, row: parseInt(key) + 1 });
        failures++;
        failuresEach++;
        this.setState({ addedAccounts: addedAccounts });
        continue;
      }

      const userFullName = `${row[0]} ${(Constants.isEmpty(row[1]) === false) ? row[1] + " " + row[2] : row[2]}`;

      //const _user = `${(row[0].toString() + row[2].toString() + row[6].toString().slice(-2)).toLowerCase()}`;
      let _user = this.fixUsername(`${(row[0].toString() + row[2].toString()).toLowerCase()}`);

      const usernameExists = await APICheckUsernameAvailibility.Request(_user);

      if (usernameExists === true) {

        _user = this.fixUsername(`${(row[0].toString() + row[2].toString() + usernameExistsCount.toString()).toLowerCase()}`);
        usernameExistsCount++;

        while(true) {

          const usernameExists2 = await APICheckUsernameAvailibility.Request(_user);

          if (usernameExists2 === false) {
            break;
          }

          _user = this.fixUsername(`${(row[0].toString() + row[2].toString() + usernameExistsCount.toString()).toLowerCase()}`);
          usernameExistsCount++;

        }

      }

      const _pass = Constants.generateString();
      const _passEncrypted = CryptoJS.SHA512(_pass).toString();

      const added1 = await APIADD.Request(`INSERT INTO Account`, `VALUES (null, '${_user}', '${_passEncrypted}', 0)`);

      if (added1 === false) {
        addedAccounts.push({ name: userFullName, success: false, text: <span style={{color: Colors.color.redFilledIn}}><i className='far fa-times' style={{color: Colors.color.redFilledIn}} /> (Kijk alstublieft de gegevens na)</span>, row: parseInt(key) + 1 });
        failures++;
        failuresEach++;
        this.setState({ addedAccounts: addedAccounts });
        continue;
      }

      const addedInfoID = (await APIGET.Request(`SELECT account_id`, `FROM Account`, null, null, null, `ORDER BY account_id DESC LIMIT 1`))[0].account_id;

      const added2 = await APIADD.Request(`INSERT INTO Info`, `VALUES (` +
        `${addedInfoID}, ` +
        `'${Constants.isEmpty(row[0]) === false ? Constants.firstUppercase(row[0]) : ''}', ` +
        `'${Constants.isEmpty(row[1]) === false ? row[1] : ''}', ` +
        `'${Constants.isEmpty(row[2]) === false ? Constants.firstUppercase(row[2]) : ''}', ` +
        `'${Constants.isEmpty(row[6]) === false ? row[6] : ''}', ` +
        `'${Constants.isEmpty(row[5]) === false ? row[5] : ''}', ` +
        `NULL, ` +
        `'', ` +
        `'${Constants.isEmpty(row[7]) === false ? row[7] : ''}', ` +
        `'${Constants.isEmpty(row[8]) === false ? row[8] : ''}', ` +
        `'${Constants.isEmpty(row[9]) === false ? row[9] : ''}', ` +
        `'${Constants.isEmpty(row[3]) === false ? row[3] : '2000-01-01'}', ` +
        `0, ` +
        `${Constants.isEmpty(row[11]) === false ? `'${row[11]}'` : `'${Constants.dateToString(new Date())}'`}, ` +
        `'${Constants.isEmpty(row[12]) === false ? row[12] : ''}', ` +
        `'${Constants.isEmpty(row[13]) === false ? row[13] : ''}', ` +
        `'${Constants.isEmpty(row[10]) === false ? row[10] : ''}', ` +
        `'', ` +
        `'${Constants.isEmpty(row[4]) === false ? row[4] : ''}')`
      );

      if (added2 === false) {
        await APIDELETE.Request(`DELETE FROM Account`, `WHERE account_id = ${addedInfoID}`);
        addedAccounts.push({ name: userFullName, success: false, text: <span style={{color: Colors.color.redFilledIn}}><i className='far fa-times' style={{color: Colors.color.redFilledIn}} /> (Kijk alstublieft de gegevens na)</span>, row: parseInt(key) + 1 });
        failures++;
        failuresEach++;
        this.setState({ addedAccounts: addedAccounts });
        continue;
      }

      let promises = [];

      if (this.state.selectedTeams.length > 0) {

        for (const team of this.state.selectedTeams) {
          promises.push(APIAddToTeam.Request(team.team_id, addedInfoID));
        }

      }

      promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkweek, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${addedInfoID}, 1, 1, 1, 1, 1, 1, 1, 1)`));
      promises.push(APIADD.Request(`INSERT INTO ContractN (contract_id, contract_info_id)`, `VALUES (NULL, ${addedInfoID})`));

      await Promise.all(promises);

      if (this.state.sendLoginData === true) {
        await APISendNewAccountMail.Request(row[5], _user, _pass, Data.data.storeCode);
      }

      addedAccounts.push({ name: userFullName, success: true, row: parseInt(key) + 1 });
      this.setState({ addedAccounts: addedAccounts });
      failuresEach = 0;

    }

    addedAccounts.push({ name: <b>Klaar</b>, success: true, row: null });
    this.setState({ addedAccounts: addedAccounts });

    if (failures === 0) {

      setTimeout(() => {

        this.setState({ reloading: false });
        this.reloadScreen();
        this.closeModal();
        
      }, 5000);

    } else {

      this.setState({ reloading: false, failures: true });

    }

  }

  fixUsername(user) {

    let _user = '';

    // remove spaces
    _user = user.replaceAll(' ', '');

    // remove special characters
    _user.normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "");

    // last check special chars
    _user = remove(_user);

    return _user.toLowerCase();

  }

  renderImportedEmployees() {

    let toRender = [];

    for (const added of this.state.addedAccounts) {
      
      toRender.push(
        <div>
          <span>{added.row !== null ? `Rij ${added.row}: ` : ''}{added.name === null ? 'Naam onbekend' : added.name} ... {added.success === true ? <i className='far fa-check' style={{color: Colors.color.greenFilledIn}} /> : added.text}</span>
        </div>
      );

    }

    return toRender;

  }

  toggleTeamButton(team) {

    let selected = this.state.selectedTeams;

    if (Constants.objectArrayContainsKey(this.state.selectedTeams, 'team_id', team.team_id)) {
      Constants.removeFromArray2(selected, 'team_id', team.team_id);
    } else {
      selected.push(team);
    }

    this.setState({ selectedTeams: selected });

  }

  renderTeamButtons() {

    let toRender = [];

    for (const team of Data.data.teams) {

      toRender.push(

        Constants.objectArrayContainsKey(this.state.selectedTeams, 'team_id', team.team_id) ?
          <div className='reports_toggleButton_active' style={{marginLeft: toRender.length > 0 ? '0.6vw' : 0}} onClick={() => this.toggleTeamButton(team)}>
            <i className='fas fa-check-circle' />
            <span>{team.team_naam}</span>
          </div>
        :
          <div className='reports_toggleButton' style={{marginLeft: toRender.length > 0 ? '0.6vw' : 0}} onClick={() => this.toggleTeamButton(team)}>
            <i className='fal fa-circle' />
            <span>{team.team_naam}</span>
          </div>

      );

    }

    return toRender;

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="employeesmodal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.goBack() : null}>

        <div className="employeesmodal-content" style={{minHeight: this.state.loading === true ? '15vw' : null}}>

          <div className="employeesmodal-header">
              <p>Importeren</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="employeesmodal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  history={this.props.history}
                />

              :

                null
          
              }

              <div className='employeesmodal_content' style={{width: '100%'}}>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                  </div>

                :

                  <div className='employeesmodal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='loader3'>
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                      </div>

                    :

                      null

                    }

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Medewerkers importeren</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw', flexDirection: 'column', alignItems: 'flex-start'}}>

                        <div><span><b>LET OP: </b>Om medewerkers te kunnen importeren dien je gebruik te maken van deze sjabloon: <b><a className='defaultLinkO' href='https://tiemdo.com/downloads/import-employees.xlsx?v=3'>import-employees.xlsx</a></b></span></div>
                        <div><span>Verander de eerste rij <b>niet</b> en zet de gegevens precies in dezelfde volgorde als in de eerste rij</span></div>
                        <div><span>Voornaam, achternaam en e-mail zijn <b>verplicht</b></span></div>

                      </div>

                      {Data.data.teams.length > 1 && Data.data.chosenTeam !== null ?

                        <div>

                          <div style={{fontSize: '1vw', fontWeight: 'bold'}}>Selecteer teams</div>

                          <div><span><i>Wanneer je geen team(s) selecteert, dien je na het importeren handmatig de teams te selecteren pér medewerker</i></span></div>

                          <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                            {this.renderTeamButtons()}

                          </div>

                        </div>

                      : null}

                      <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1' style={{ paddingLeft: 0 }}>

                          {this.state.sendLoginData === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setState({ sendLoginData: true })}>
                              <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                            </div>

                            :

                            <div className='smartPlanModal-checkbox' onClick={() => this.setState({ sendLoginData: false })}>
                              <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 40}}>

                          <span style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.setState({ sendLoginData: !this.state.sendLoginData })}>E-mails versturen</span>

                        </div>

                      </div>

                      <div><span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Verstuur een e-mail met inloggegegevens zodat medewerkers gelijk kunnen inloggen. Indien je dit uitschakelt, kan dit later verzonden worden in Medewerkers {`>>`} -medewerker- {`>>`} Account</span></div>

                      {/* <div className='modal-row' style={{ marginTop: '2vw' }}>

                          <div className='modal-rowSub1'>
                              <span><b>E-mail versturen</b></span>
                              <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Verstuur een e-mail met inloggegegevens. Indien je dit uitschakelt, kan dit later verzonden worden in Medewerkers {`>>`} -medewerker- {`>>`} Account</span>
                          </div>

                          <div className='modal-rowSub2'>

                            {this.state.sendLoginData === false ?

                              <div className='smartPlanModal-checkbox' onClick={() => this.setState({ sendLoginData: true })}>
                                <i className='fal fa-square' style={{fontSize: '0.8vw'}} />
                              </div>

                              :

                              <div className='smartPlanModal-checkbox' onClick={() => this.setState({ sendLoginData: false })}>
                                <i className='fas fa-check-square' style={{fontSize: '0.8vw', color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                              </div>

                            }

                          </div>

                      </div> */}

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw' }}>

                        <input ref={(ref) => this.uploadInput=ref} type="file" onChange={(event) => this.checkExcel(event)} accept={'.xls,.xlsx'} />

                      </div>

                      {this.state.addedAccounts.length > 0 ?
                        <div style={{fontSize: '1vw', fontWeight: 'bold'}}>Medewerkers</div>
                      : null}

                      {this.renderImportedEmployees()}

                    </div>

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {this.state.loading === true || this.state.reloading === true ?

            <div className='employeesmodal-bottom'>

              <div className='employeesmodal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='employeesmodal-bottom'>

              {this.showError()}

              {this.state.failures === false ?
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                  <span>Annuleren</span>
                </div>
              : null}

              {this.state.failures === true ?
                <div className='modal-normalButton' onClick={() => { this.reloadScreen(); this.closeModal(); }}>
                  <span>Voltooien</span>
                </div>
              :
                <div className='modal-normalButton' onClick={() => this.importEmployees()}>
                  <span>Importeren</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default UploadEmployeesModal;