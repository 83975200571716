import React from 'react';
import './App.css';
import './App2.scss';

import Geocode from "react-geocode";

// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

import Login from './pages/login/login';
// import Home from './pages/home/home';
import Availability from './pages/availability/availability';
import Schedule from './pages/schedule/schedule';
import Planner from './pages/planner/planner';

import Functions from './pages/options/functions/functions';
import Deadlines from './pages/options/deadlines/deadlines';
import Shifts from './pages/options/shifts/shifts';
import Times from './pages/options/times/times';
import TradeRequests from './pages/options/tradeRequests/tradeRequests';
import HolidayRequests from './pages/options/holidayRequests/holidayRequests';
import Blocked from './pages/options/blocked/blocked';
import RegisterUser from './pages/options/registerUser/registerUser';
import RegistrationCode from './pages/options/registrationCode/registrationCode';
import Account from './pages/options/account/account';
import Holidays from './pages/options/holidays/holidays';
import Trade from './pages/options/trade/trade';
import Accounts from './pages/options/accounts/accounts';
import Password from './pages/options/password/password';
import RegisterUserCode from './pages/options/registerUserCode/registerUserCode';
import MyHours from './pages/options/myHours/myHours';
import HoursRegistration from './pages/options/hoursRegistration/hoursRegistration';
import UserHours from './pages/options/userHours/userHours';
import ActiveUsers from './pages/activeUsers/activeUsers';
import AppOptions from './pages/options/appOptions/appOptions';
import AvailabilitySchool from './pages/availabilitySchool/availabilitySchool';
import RegistrationCodes from './pages/options/registrationCodes/registrationCodes';
import Admin from './admin/admin';
import ResetPassword from './pages/options/resetPassword/resetPassword';
import ResetPassword2 from './pages/options/resetPassword2/resetPassword2';
import StoreCode from './storeCode/storeCode';
import Sections from './pages/options/sections/sections';
import History from './pages/history/history';
import DeadlinesView from './pages/options/deadlines/DeadlinesView';
import Availabilities from './pages/options/availabilities/availabilities';
import Employees from './pages/employees/employees';
import Finances from './pages/finances/finances';
import Absences from './pages/options/absences/absences';
import ViewContract from './components/contracts/viewContract';
import Particular from './pages/options/particular/particular';
import Absencetypes from './pages/options/absencetypes/absencetypes';
import Skills from './pages/options/skills/skills';
import Home2 from './pages/home2/home2';
import AvailableShiftsAdmin from './pages/options/availableShiftsAdmin/availableShiftsAdmin';
import Breaks from './pages/options/breaks/breaks';
import PlannerArrange from './pages/options/plannerArrange/plannerArrange';
import WhatsNew from './pages/options/whatsNew/whatsNew';
import StandardAvailabilityRequests from './pages/options/standardAvailabilityRequests/standardAvailabilityRequests';
import WorkWeek from './pages/options/workWeek/workWeek';
import Salaries from './pages/options/salaries/salaries';
import ClockSystem from './pages/clockSystem/clockSystem';
import Requests from './pages/options/requests/requests';
import MyCompany from './pages/options/mycompany/mycompany';
import Reports from './pages/options/reports/reports';
import FunctionArrange from './pages/options/functionArrange/functionArrange';

import { unregister } from './serviceWorker';
import ContractModal from './pages/employees/contractModal';
import InfoWebView from './pages/infoWebView/infoWebView';
import PlannerV2 from './pages/planner/plannerV2';
import PlannerTryout from './pages/planner/plannerTryout';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignContract from './pages/signContract/signContract';
import EmployeeDetails from './pages/employees/employeeDetails';
import EmployeeDetails2 from './pages/employees/employeeDetails2';

function App() {

  unregister();

  Geocode.setApiKey("AIzaSyB2h4xgVscXEqEmVdmXMMDiROJVVt-RZWc");

  const basename = document.getElementsByTagName('base')[0].getAttribute('href');

  return (
    <Router basename={basename}>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/home" component={Home} /> */}
        <Route path="/home" component={Home2} />
        <Route path="/availability" component={Availability} />
        <Route path="/availabilitySchool" component={AvailabilitySchool} />
        <Route path="/schedule" component={Schedule} />
        {/* <Route path="/planner" component={PlannerV2} /> */}
        <Route path="/planner" component={Planner} />
        <Route exact path="/employees" component={Employees} />
        <Route exact path="/employees/:employeeid" component={EmployeeDetails} />
        {/* <Route exact path="/employees/:employeeid" component={EmployeeDetails2} /> */}

        {/* <Route path="/sections" component={Sections} /> */}
        {/* <Route path="/functions" component={Functions} /> */}
        {/* <Route path="/deadlines" component={Deadlines} /> */}
        {/* <Route path="/shifts" component={Shifts} /> */}
        {/* <Route path="/times" component={Times} /> */}
        {/* <Route path="/breaks" component={Breaks} /> */}
        {/* <Route path="/trade_requests" component={TradeRequests} /> */}
        {/* <Route path="/available_shifts_requests" component={AvailableShiftsAdmin} /> */}
        <Route path="/holiday_requests" component={HolidayRequests} />
        {/* <Route path="/blocked_days" component={Blocked} /> */}
        <Route path="/hour_registration" component={HoursRegistration} />
        {/* <Route path="/user_hours_details" component={UserHours} /> */}
        <Route path="/register_user" component={RegisterUser} />
        <Route path="/registration_code" component={RegistrationCode} />
        <Route path="/registration_codes" component={RegistrationCodes} />
        <Route path="/account" component={Account} />
        <Route path="/my_hours" component={MyHours} />
        <Route path="/holidays" component={Holidays} />
        <Route path="/trade" component={Trade} />
        <Route path="/accounts" component={Accounts} />
        <Route path="/password" component={Password} />
        <Route path="/code_register_user" component={RegisterUserCode} />
        <Route path="/active_users" component={ActiveUsers} />
        <Route path="/app_options" component={AppOptions} />
        <Route path="/history" component={History} />
        <Route path="/all_deadlines" component={DeadlinesView} />
        {/* <Route path="/availabilities" component={Availabilities} /> */}
        {/* <Route path="/absences" component={Absences} /> */}
        <Route path="/employees" component={Employees} />
        {/* <Route path="/finances" component={Finances} /> */}
        <Route path="/contract" component={ViewContract} />
        {/* <Route path="/particularities" component={Particular} /> */}
        {/* <Route path="/absencetypes" component={Absencetypes} /> */}
        {/* <Route path="/skills" component={Skills} /> */}
        <Route path="/planner_arrange" component={PlannerArrange} />
        <Route path="/function_arrange" component={FunctionArrange} />
        {/* <Route path="/whats_new" component={WhatsNew} /> */}
        {/* <Route path="/standard_availability_requests" component={StandardAvailabilityRequests} /> */}
        <Route path="/salaries" component={Salaries} />
        <Route path="/requests" component={Requests} />
        <Route path="/manage" component={MyCompany} />
        <Route path="/reports" component={Reports} />

        <Route path="/forgot_password" component={ResetPassword} />
        <Route path="/reset" component={ResetPassword2} />

        <Route path="/store_code" component={StoreCode} />

        <Route path="/time_registration" component={ClockSystem} />

        <Route path="/try_our_awesome_planner" component={PlannerTryout} />

        <Route path="/company_info/:storecode/:serverip/:teamid" component={InfoWebView} />

        <Route path="/sign_contract/:storecode/:serverip/:userID/:contractFlatID/:contractID" component={SignContract} />

        <Route path="/admin/yT5AHMZNF5L6s5PT7uSKudRrUGRtUfGP" component={Admin} />
      </Switch>
    </Router>
  );
}

export default App;
