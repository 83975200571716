/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../../planner/planner.scss';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import BetterAlert from '../../../components/alert/alert';

import LocationPicker from 'react-location-picker';

import Geocode from "react-geocode";
import APIAddLocation from '../../../classes/APIAddLocation';

class AddLocationModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    this.state = {

        loading: false,

        name: '',
        address: '',
        lat: 52.1326,
        lng: 5.2913,
        zoom: 7,
        radius: 20,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async searchAddress() {

    const pos = await Geocode.fromAddress(this.state.address);

    this.setState({
      lat: pos.results[0].geometry.location.lat,
      lng: pos.results[0].geometry.location.lng,
      zoom: 17,
    });

  }

  chooseLatLng(pos) {

    this.setState({
      lat: pos.position.lat,
      lng: pos.position.lng,
    });

  }

  async addLocation() {

    if (this.checkValidations() === false) {
      return;
    }

    this.setState({ loading: true });

    const added = await APIAddLocation.Request(this.state.lat, this.state.lng, this.state.radius, this.state.name);

    if (added === false) {

      this.setState({
        loading: false,
        showError: true,
        errorText: 'Er is iets misgegaan tijdens het opslaan',
      });
      return;

    }

    this.props._reloadScreen();
    this.props._closeModal();

  }

  checkValidations() {

    if(this.state.name === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen naam van jouw inkloklocatie ingevoerd',
      });
      return false;
    }

    return true;

  }

  render() {

    return (
        <div id="addLocationModal" className="modal" onClick={(e) => e.target === document.getElementById("zaddLocationModal") ? this.props._closeModal() : null}>

        <div className="modal-content" style={{width: '50%'}}>
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
            <p>Locatie toevoegen</p>
          </div>

          {this.state.screenLoading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

                <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                    {this.state.showAlert === true ?
                        
                        <BetterAlert
                            _closeAlert={this.closeAlert.bind(this)}
                            _title={this.state.alertTitle}
                            _body={this.state.alertBody}
                            _buttons={this.state.alertButtons}
                            _buttonText={this.state.alertButtonText}
                            _buttonAction={this.state.alertButtonAction}
                        />

                    :

                        null
                    
                    }

                    <div className='plan_modal-form'>

                        <div className='modal-row'>

                            <div className='modal-rowSub1'>
                                <span><b>Naam inkloklocatie</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                              <input 
                                className={'inputField2'}
                                maxLength={100}
                                value={this.state.name}
                                onChange={(event) => this.setState({ name: event.target.value })}
                                placeholder={'Typ een naam'}
                              />

                            </div>

                        </div>

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Zoek locatie op adres</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                              <input 
                                className={'inputField2'}
                                maxLength={200}
                                value={this.state.address}
                                onChange={(event) => this.setState({ address: event.target.value })}
                                placeholder={'Typ een adres'}
                              />

                              <div className='submitButton' style={{marginTop: '10px'}} onClick={() => this.searchAddress()}>Zoeken</div>

                            </div>

                        </div>

                        <div className='modal-row2' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1_padding'>
                              <span style={{fontStyle: 'italic', fontSize: '0.6vw', marginTop: '1vw'}}>Of versleep de rode markeerpijl</span>
                            </div>

                            <div className='modal-rowSub2'>

                              <LocationPicker
                                  containerElement={ <div style={ {width: '100%', marginTop: '8px'} } /> }
                                  mapElement={ <div style={ {height: '300px'} } /> }
                                  defaultPosition={{lat: this.state.lat, lng: this.state.lng}}
                                  zoom={this.state.zoom}
                                  radius={this.state.radius}
                                  onChange={(pos) => this.chooseLatLng(pos)}
                              />

                            </div>

                        </div>

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Straal</b></span>
                            </div>

                            <div className='modal-rowSub2'>

                              <div style={{textAlign: 'center'}}>{this.state.radius} meter</div>

                              <input 
                                type="range" 
                                min="5" 
                                max="100" 
                                value={this.state.radius} 
                                className="inputSlider" 
                                style={{marginTop: '15px'}} 
                                onChange={(event) => this.setState({radius: parseInt(event.target.value), zoom: 17})} 
                              />

                            </div>

                        </div>

                        <div style={{height: '1vw', width: '100%'}} />

                    </div>

                </div>

            }

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              <div className='modal-submitButton' onClick={() => this.addLocation()}>
                <span>Toevoegen</span>
              </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default AddLocationModal;