/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './availabilitySchool.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';

import APIAddSchoolHoliday from '../../classes/APIAddSchoolHoliday';
import APIEditSchoolHoliday from '../../classes/APIEditSchoolHoliday';
import APIDeleteSchoolHoliday from '../../classes/APIDeleteSchoolHoliday';

import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Constants from '../../constants/Constants';

class SchoolHolidayModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.holiday = this.props._modalHoliday;
    this.holidayNumber = this.props._modalHolidayNumber;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        date: null,
        dateText: '',

        date2: null,
        dateText2: '',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING AVAILABILITY

        //this.setState({ date: this.props._modalDate });
        
    }

    if(this.mode === 1) { //EDITING

        const dateObject1 = Constants.stringToDate(this.modalData.schoolvakantie_begin);
        const dateObject2 = Constants.stringToDate(this.modalData.schoolvakantie_eind);
        // dateObject1.setDate(dateObject1.getDate() + 1);
        // dateObject2.setDate(dateObject2.getDate() + 1);
        // dateObject3.setDate(dateObject3.getDate() + 1);

        const dateText1 = dateObject1.toISOString().substring(0, 10);
        const dateText2 = dateObject2.toISOString().substring(0, 10);

        this.setState({
            date: Constants.stringToDate(this.modalData.schoolvakantie_begin),
            dateText: dateText1,
            date2: Constants.stringToDate(this.modalData.schoolvakantie_eind),
            dateText2: dateText2,
        });

    }

  }

  setDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        date: date,
        dateText: dateText,
        showError: false,
    });

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const dateObject = date;

    const dateText = Constants.dateToString(dateObject);

    this.setState({
        date2: date,
        dateText2: dateText,
        showError: false,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async saveAvailability() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    const added = await APIAddSchoolHoliday.Request(
          this.state.dateText,
          this.state.dateText2,
          this.holidayNumber,
        );

    if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

    } else {
        // console.log('error');
    }

    this.setState({loading: false});

  }

  async editAvailability() {

    this.setState({loading: true, showError: false});

    if(this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    const added = await APIEditSchoolHoliday.Request(
        this.state.dateText,
        this.state.dateText2,
        this.modalData.schoolvakantie_id,
      );

    if(added === true) {

        this.closeModal();

        this.reloadScreen();

        return;

    } else {
        // console.log('error');
    }

    this.setState({loading: false});

  }

  async deleteAvailability() {

    this.setState({loading: false, showError: false});

    const deleted = await APIDeleteSchoolHoliday.Request(this.modalData.schoolvakantie_id);

    if(deleted === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {
        // console.log('error');
    }

    this.setState({loading: false});

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je jouw schoolvakantie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteAvailability.bind(this),
        showAlert: true,
    });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {

    if(this.state.date === null) {
    this.setState({
        showError: true,
        errorText: 'Je hebt geen begindatum ingevoerd',
    });
    return false;
    }
    if(this.state.date2 === null) {
    this.setState({
        showError: true,
        errorText: 'Je hebt geen einddatum ingevoerd',
    });
    return false;
    }
    if(this.state.date2 < this.state.date) {
    this.setState({
        showError: true,
        errorText: 'Begindatum kan niet later zijn dan einddatum',
    });
    return false;
    }

    return true;

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="schoolHolidayModal" className="modal" onClick={(e) => e.target === document.getElementById("zschoolHolidayModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.props._closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Schoolvakantie toevoegen</p>

            :

              <p>Schoolvakantie wijzigen</p>
            
            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }
                
                <div className='plan_modal-account'>

                  {this.mode === 0 ?

                    <div>
                      {this.holiday} toevoegen
                    </div>

                  :
                  this.mode === 1 ?

                    <div>
                      {this.holiday} wijzigen
                    </div>

                  :

                    null
                  
                  }
                </div>

                <div className='plan_modal-form'>

                    <div style={{marginTop: 10}}>Van:</div>

                    <DatePicker
                        selected={this.state.date}
                        startDate={this.state.date}
                        endDate={this.state.date2}
                        onChange={(date) => this.setDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className={'inputFieldTime'}
                        placeholderText={'Selecteer een datum'}
                        locale={nl}
                        showWeekNumbers
                        selectsStart
                    />

                    <div style={{marginTop: 5}}>Tot en met:</div>

                    <DatePicker
                        selected={this.state.date2}
                        startDate={this.state.date}
                        endDate={this.state.date2}
                        minDate={this.state.date}
                        onChange={(date) => this.setDate2(date)}
                        dateFormat="dd-MM-yyyy"
                        className={'inputFieldTime'}
                        placeholderText={'Selecteer een datum'}
                        locale={nl}
                        showWeekNumbers
                        selectsEnd
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editAvailability()}>Wijzigen</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveAvailability()}>Opslaan</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default SchoolHolidayModal;