/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../startupModal/startupModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import QRCode from 'qrcode.react';
import APIRegisterAddRegistrationCode from '../../classes/APIRegisterAddRegistrationCode';

import { toast } from 'react-toastify';
import APISendRegistrationCodeMail from '../../classes/APISendRegistrationCodeMail';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGET from '../../classes/global/APIGET';

class RegistrationCodeSendModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.code = this.makeCode(4);
    
    this.state = {

        loading: true,
        reloading: false,

        steps: [],
        curStep: 'menu',

        menu: 'menu',
        selectedTeam: null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        generated: false,

        mail1: '',
        mail2: '',

        jobs: [],
        job: null,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    const jobs = await APIGetFunctions.Request();

    this.calcSteps();

    this.setState({ jobs: jobs, loading: false});

  }

  calcSteps() {

    let steps = {};
    
    steps['menu'] = typeof this.state.steps.menu !== 'undefined' ? this.state.steps.menu : false;
    steps['open'] = typeof this.state.steps.open !== 'undefined' ? this.state.steps.open : false;
    steps['qr'] = typeof this.state.steps.qr !== 'undefined' ? this.state.steps.qr : false;

    this.setState({ steps: steps });

  }

  async nextStep() {

    // if (await this.checkValidations() === false) {
    //   return;
    // }

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex += 1;
    if(nextIndex > keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    if (nextKey === 'qr' && this.state.generated === false) {
        await this.generateCode();
    } 

    steps[curStep] = true;

    this.selectMenu(nextKey);

    this.setState({ steps: steps, curStep: nextKey });

  }

  prevStep() {

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    this.selectMenu(nextKey);

    this.setState({ steps: steps, curStep: nextKey });

  }

  async generateCode() {

    this.setState({ loading: true });

    await APIRegisterAddRegistrationCode.Request(
        this.code,
        null,
        null,
        0,
        null,
        this.state.job,
        Constants.dateToString(new Date()),
    );

    this.setState({ generated: true, loading: false });

  }

  selectMenu(menu) {

    this.setState({
      menu: menu,
    });

  }

  selectMenuManual(menu) {

    if (this.wentBack === true) {
      this.setState({
        menu: menu,
      });
      return;
    }

    this.wentBack = true;

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    this.setState({
      menu: menu,
      curStep: nextKey,
      steps: steps,
    });

  }

  goBack() {

    this.props._closeModal();

  }

  closeModal2() {

    this.setState({
      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  hasDoneStep(key) {

    const done = this.state.steps[key];

    if (typeof done === 'undefined') {
      if (key === 'menu' || this.state.menu === key || this.state.subMenu === key) {
        return '';
      }
      return '_disabled';
    }

    // if (this.state.menu === 'general' && key === 'general') {
    //     return '';
    // }

    if (this.state.menu === 'menu' && this.state.subMenu === key) {
      return '';
    }

    if (this.state.menu === key && this.state.menu !== 'menu') {
      return '';
    }

    if (this.state.menu === key || this.state.subMenu === key) {
      if (done) {
        return '_done';
      } else {
        return '';
      }
    }

    if (done) {
      return '_done';
    }

    return '_disabled';

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  makeCode(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }

  registrationCodeString(code) {

      let count = 0;

      let extraString = ' ';

      if(code[0] === 'I' || code[0] === 'l') {
          count++;
      }
      if(code[1] === 'I' || code[1] === 'l') {
          count++;
      }
      if(code[2] === 'I' || code[2] === 'l') {
          count++;
      }
      if(code[3] === 'I' || code[3] === 'l') {
          count++;
      }

      if(count > 0) {

          extraString += '(';

          for(let i = 0; i < code.length; i++) {

              const comma = (count - i) > 1;

              if(code[i] === 'I') {
                  extraString += comma ? 'hoofdletter i, ' : 'hoofdletter i';
              }
              if(code[i] === 'l') {
                  extraString += comma ? 'kleine letter L, ' : 'kleine letter L';
              }
          }

          extraString += ')';

      }

      return code + extraString;

  }

  async checkEmailValid() {

    if (this.state.mail2 !== '') {

        const data = await APIGET.Request(`SELECT info_id`, `FROM Info`, null, `WHERE info_email = '${this.state.mail2}'`, null, null);

        if (data.length > 0) {
            return false;
        }

    }

    return true;

  }

  async sendRegistrationCodeMail() {

    if(this.state.loading || this.state.reloading) {
      return;
    }

    if(Constants.isEmpty(this.state.mail1) || Constants.isEmpty(this.state.mail2)) {
      this.setState({
          showError: true,
          errorText: 'Vul twee keer het e-mailadres in',
      });
      return;
    }

    if(this.state.mail2 !== '' && /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.mail2) === false) {
      this.setState({
          showError: true,
          errorText: 'Geen geldig e-mailadres',
      });
      return;
    }

    if(this.state.mail1 !== this.state.mail2) {
      this.setState({
          showError: true,
          errorText: 'E-mailadressen zijn niet hetzelfde',
      });
      return;
    }

    const emailValid = await this.checkEmailValid();
    if (emailValid === false) {
      this.setState({
          showError: true,
          errorText: 'Dit e-mailadres bestaat al',
      });
      return false;
    }

    this.setState({ loading: true });

    await APISendRegistrationCodeMail.Request(this.state.mail2, this.registrationCodeString(this.code), Data.data.storeCode);

    const added = await APIRegisterAddRegistrationCode.Request(
      this.code,
      null,
      null,
      0,
      null,
      this.state.job,
      Constants.dateToString(new Date()),
    );

    if (added) {

      toast.success('😄 Registratiecode is verstuurd!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });

      this.closeModal();

    } else {

      toast.error('😔 Er is iets misgegaan', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });

      this.setState({ loading: false });

    }

  }

  renderJobSelects() {

    let toRender = [];

    for (const job of this.state.jobs) {

      toRender.push(
        <option value={job.functie_id} selected={this.state.job === job.functie_id}>{job.functie_naam}</option>
      );

    }

    return toRender;

  }

  selectJob(e) {

    this.setState({ job: e.target.value === 'null' ? null : e.target.value });

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="tutmodal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.goBack() : null}>

        <div className="tutmodal-content" style={{minHeight: this.state.loading === true ? '15vw' : null}}>

          <div className="tutmodal-header">
              <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
              <p>Een registratiecode versturen</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="tutmodal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
          
              }

              <div className='tutmodal_menu'>

                <div className='employees_menuSubTitle'>
                  <div>Registratiecode</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('menu')} ${this.state.menu === 'menu' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('menu')}>
                  <div>Mail of app</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('open')} ${this.state.menu === 'open' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('open')}>
                  <div>Open de app</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('qr')} ${this.state.menu === 'qr' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('qr')}>
                  <div>Scan de QR-code</div>
                </div>

              </div>

              <div className='tutmodal_content' style={this.state.menu !== 'general' && this.state.menu !== 'teams' ? {width: 'calc(87% - 0px)'} : null}>

                {this.state.loading === true ?
                
                  <div className='loaderModal'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                  </div>

                :

                  <div className='tutmodal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='loader3'>
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                      </div>

                    :

                      null

                    }

                    {this.state.menu === 'menu' ?

                      <div>

                          <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Registratiecode versturen via de app</div>

                          <div style={{marginTop: '1vw'}}>Klik hieronder op <b>Volgende</b> om via de app een medewerker te registreren. Met de app krijg je de mogelijkheid om de registratiecode te versturen per SMS, WhatsApp, Mail enzovoort.</div>

                          <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '2vw'}}>Registratiecode versturen via e-mail</div>

                          <div className='modal-row_start' style={{marginTop: '2vw'}}>

                              <div className='modal-rowSub1_start'>
                                  <span><b>E-mailadres</b></span>
                              </div>

                              <div className='modal-rowSub2_start'>

                                <input 
                                  className={'inputField2'}
                                  maxLength={100}
                                  value={this.state.mail1}
                                  onChange={(event) => this.setState({ mail1: event.target.value })}
                                  placeholder={'E-mail medewerker :'}
                                />

                              </div>

                          </div>

                          <div className='modal-row_start' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1_start'>
                                  <span><b>E-mailadres herhalen</b></span>
                              </div>

                              <div className='modal-rowSub2_start'>

                                <input 
                                  className={'inputField2'}
                                  maxLength={100}
                                  value={this.state.mail2}
                                  onChange={(event) => this.setState({ mail2: event.target.value })}
                                  placeholder={'Herhaal e-mail :'}
                                />

                              </div>

                          </div>

                          <div className='modal-row_start' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1_start'>
                                  <span><b>Functie</b></span>
                                  <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Optioneel</span>
                              </div>

                              <div className='modal-rowSub2_start'>

                                <select 
                                    name="Selecteer een functie" 
                                    className={'dropDownField'}
                                    onChange={(event) => this.selectJob(event)}
                                >
                                    <option value="null" selected={this.state.job === 'null'}>Geen functie toewijzen</option>
                                    {this.renderJobSelects()}                       
                                </select>

                              </div>

                          </div>

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '1vw' }}>
                            <div className='planner_new_publishButton' style={{marginLeft: 0, width: '12vw'}} onClick={() => this.sendRegistrationCodeMail()}>Verstuur registratiecode</div>
                          </div>

                          {/* <div style={{marginTop: '1vw'}}><b>Stap 1:</b> Open de 'Tiemdo' app</div>
                          <div style={{marginTop: '0.5vw'}}><b>Stap 2:</b> Druk onderin op het tabblad 'Menu'</div>
                          <div style={{marginTop: '0.5vw'}}><b>Stap 3:</b> Scroll naar het kopje 'Registratie' en druk op 'Medewerker registreren'</div>
                          <div style={{marginTop: '0.5vw'}}><b>Stap 4:</b> Druk op 'QR-code scannen'</div>

                          <div style={{marginTop: '2vw'}}>Klik hieronder op {this.state.generated === false ? <b>QR-code genereren</b> : <b>volgende</b>} als je deze stappen hebt gevolgd</div> */}

                      </div>

                    :
                    this.state.menu === 'open' ?

                      <div>

                          <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>QR-functie openen in de app</div>

                          <div style={{marginTop: '1vw'}}><b>Stap 1:</b> Open de 'Tiemdo' app</div>
                          <div style={{marginTop: '0.5vw'}}><b>Stap 2:</b> Druk onderin op het tabblad 'Menu'</div>
                          <div style={{marginTop: '0.5vw'}}><b>Stap 3:</b> Scroll naar het kopje 'Registratie' en druk op 'Medewerker registreren'</div>
                          <div style={{marginTop: '0.5vw'}}><b>Stap 4:</b> Druk op 'QR-code scannen'</div>

                          <div style={{marginTop: '2vw'}}>Klik hieronder op {this.state.generated === false ? <b>QR-code genereren</b> : <b>volgende</b>} als je deze stappen hebt gevolgd</div>

                          <div style={{marginTop: '2vw'}}><b>Optioneel:</b> Selecteer een functie voor de medewerker</div>

                          <div className='modal-row_start' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1_start'>
                                  <span><b>Functie</b></span>
                                  <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Optioneel</span>
                              </div>

                              <div className='modal-rowSub2_start'>

                                <select 
                                    name="Selecteer een functie" 
                                    className={'dropDownField'}
                                    onChange={(event) => this.selectJob(event)}
                                >
                                    <option value="null" selected={this.state.job === 'null'}>Geen functie toewijzen</option>
                                    {this.renderJobSelects()}                       
                                </select>

                              </div>

                          </div>

                      </div>

                    :
                    this.state.menu === 'qr' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginBottom: '2vw'}}>Scan de QR-code</div>

                        <div style={{marginTop: '1vw', marginBottom: '1vw'}}><b>Stap 5:</b> Scan de QR-code hieronder</div>

                        <QRCode 
                            value={`${this.code}`}
                            size={256}
                        />

                        <div style={{marginTop: '1vw'}}><b>Stap 6:</b> Deel de 4-cijferige code met een medewerker</div>

                        <div style={{marginTop: '2vw'}}>Klik hieronder op <b>voltooien</b> als je klaar bent</div>

                      </div>

                    :

                      null

                    }

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {this.state.loading === true ?

            <div className='tutmodal-bottom'>

              <div className='tutmodal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='tutmodal-bottom'>

              {this.showError()}

                <div className='modal-deleteButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                  <span>Annuleren</span>
                </div>

              {this.state.menu === 'qr' ?
                <div className='modal-normalButton' onClick={() => this.closeModal()}>
                  <span>Voltooien</span>
                </div>
              :
              this.state.generated === false && this.state.menu === 'open' ?
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>QR-code genereren</span>
                </div>
              :
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>Volgende</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default RegistrationCodeSendModal;