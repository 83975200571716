/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './accounts.css';
import '../../planner/planner.scss';
import '../functions/functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetFunctions from '../../../classes/APIGetFunctions';

import 'react-datepicker/dist/react-datepicker.css';
import AccountsModal from './accountsModal';
import APIGetAdminAccountInfo from '../../../classes/APIGetAdminAccountInfo';

class Accounts extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      accounts: [],
      functions: [],
      secureData: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

    };

    this.navBar = React.createRef();

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    await this.getAccounts();
    await this.getFunctions();

    if(Data.data.accountData.account_rights === '2' && (Data.data.appOptions.bsn === true || Data.data.appOptions.iban === true)) {
      await this.getSecureData();
    }

    this.setState({loading: false});

  }

  openAccountModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  async getAccounts() {

    let accounts = await APIGetAllAccounts.Request();

    if(accounts === 'error') {

        accounts = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }
    if(accounts === null) {

        accounts = [];

    }

    this.setState({ accounts: accounts });

  }

  async getSecureData() {

    this.setState({ secureData: [] });

    let secureData = [];

    for(let key in this.state.accounts) {

      let accounts = await APIGetAdminAccountInfo.Request(this.state.accounts[key].account_id);

      if(accounts === 'error') {

          accounts = [];

          this.setState({ 
              errorScreen: true,
              loading: false,
          });

          return;
      }
      if(accounts === null) {

        continue;

      }

      secureData.push({id: this.state.accounts[key].account_id, bsn: accounts[0].info_idnr, iban: accounts[0].info_ibnr})

    }

    this.setState({secureData: secureData});

  }

  async getFunctions() {

    let functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(functionsData === null) {

        functionsData = [];

    }

    this.setState({functions: functionsData});

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getAccounts();
    if(Data.data.accountData.account_rights === '2' && (Data.data.appOptions.bsn === true || Data.data.appOptions.iban === true)) {
      await this.getSecureData();
    }

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  getIBAN(id) {

    for(let key in this.state.secureData) {

      if(this.state.secureData[key].id === id) {

        return this.state.secureData[key].iban === '' || this.state.secureData[key].iban === null || this.state.secureData[key].iban === 'null' ? 'n.v.t.' : this.state.secureData[key].iban;

      }

    }

    return 'n.v.t.';

  }

  getBSN(id) {

    for(let key in this.state.secureData) {

      if(this.state.secureData[key].id === id) {

        return this.state.secureData[key].bsn === '' || this.state.secureData[key].bsn === null || this.state.secureData[key].bsn === 'null' ? 'n.v.t.' : this.state.secureData[key].bsn;

      }

    }

    return 'n.v.t.';

  }

  getAccountBars() {

    let accounts = [];

    let char = '';

    for(let key in this.state.accounts) {

        const account = this.state.accounts[key];

        const userFullName = `${account.info_voornaam} ${(account.info_tussenvoegsel !== null && account.info_tussenvoegsel !== '') ? account.info_tussenvoegsel + " " + account.info_achternaam.charAt(0) : account.info_achternaam.charAt(0)}.`;

        if(Data.data.accountData.account_rights === '2') {

          accounts.push(
            <div>

                {char === account.info_voornaam.substring(0,1).toUpperCase() ?
                    
                    null

                :

                    <div style={{fontSize: '2vw', fontWeight: 'bold', marginBottom: 10, marginTop: 10}}>
                        {account.info_voornaam.substring(0,1).toUpperCase()}
                    </div>

                }

                <div className='accounts_adminBar'>

                  {/* Header */}
                  <div 
                    className='accounts_adminHeader'
                    style={{cursor: 'pointer'}}
                    onClick={() => this.openAccountModal(0, account)}
                  >

                    {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                      <div style={{fontSize: '1.2vw', fontWeight: '500'}}>{userFullName} ({account.account_username})</div>
                    :
                      <div style={{fontSize: '1.2vw', fontWeight: '500'}}>{userFullName}</div>
                    }

                    <div style={{fontStyle: 'italic', marginTop: 5}}>{account.account_rights === '2' ? 'Administrator' : account.account_rights === '1' ? 'Planner' : 'Medewerker'}</div>

                    <i className={'fa fa-pen'} style={{fontSize: '1vw', position: 'absolute', top: 15, right: 15, color: '#282828'}}/>

                  </div>

                  {/* Content */}
                  <div className='accounts_adminContent'>

                    {/* First column */}
                    <div className='accounts_adminColumn'>

                      <div className='accounts_adminColumnRow'>

                        {account.info_adres} {account.info_huisnummer}

                      </div>

                      <div className='accounts_adminColumnRow'>

                        {account.info_postcode}, {account.info_plaats !== null || account.info_plaats !== 'null' ? account.info_plaats : ''}

                      </div>

                      <div className='accounts_adminColumnRow'>

                        <a href={`tel:${account.info_telefoon}`}>{account.info_telefoon}</a>

                      </div>

                      <div className='accounts_adminColumnRow'>

                        <a href={`mailto:${account.info_email}`}>{account.info_email}</a>

                      </div>

                    </div>

                    {/* Second column */}
                    <div className='accounts_adminColumn2'>

                      {/* Column in second column */}

                      <div className='accounts_adminColumnColumn'>

                        <div className='accounts_adminColumnRow'>

                          Geboortedatum:

                        </div>

                        <div className='accounts_adminColumnRow'>

                          Standaard functie:

                        </div>

                        <div className='accounts_adminColumnRow'>

                          Datum in dienst:

                        </div>

                        {Data.data.appOptions.iban === true ?

                          <div className='accounts_adminColumnRow'>

                            IBAN:

                          </div>
                      
                        : null}

                        {Data.data.appOptions.bsn === true ?

                          <div className='accounts_adminColumnRow'>

                            BSN-nummer:

                          </div>

                        : null}

                      </div>

                      <div className='accounts_adminColumnColumn' style={{flex: 2}}>

                        <div className='accounts_adminColumnRow'>

                          {account.info_geboorte.substring(8) + '-' + account.info_geboorte.substring(5, 7) + '-' + account.info_geboorte.substring(0, 4)}

                        </div>

                        <div className='accounts_adminColumnRow'>

                          {account.functie_id === null ?
                          
                            'n.v.t.'

                          :

                            account.functie_naam
                        
                          }

                        </div>

                        <div className='accounts_adminColumnRow'>

                          {account.info_indienst === null || account.info_indienst === '0000-00-00' ? 'n.v.t.' : account.info_indienst.substring(8) + '-' + account.info_indienst.substring(5, 7) + '-' + account.info_indienst.substring(0, 4)}

                        </div>

                        {Data.data.appOptions.iban === true ?

                          <div className='accounts_adminColumnRow'>

                            {this.getIBAN(account.account_id)}

                          </div>

                        : null}

                        {Data.data.appOptions.bsn === true ?

                          <div className='accounts_adminColumnRow'>

                            {this.getBSN(account.account_id)}

                          </div>

                        : null}

                      </div>

                    </div>

                  </div>

                </div>
            </div>
          );

        } else {

          accounts.push(
              <div>

                  {char === account.info_voornaam.substring(0,1).toUpperCase() ?
                      
                      null

                  :

                      <div style={{fontSize: '2vw', fontWeight: 'bold', marginBottom: 10, marginTop: 10}}>
                          {account.info_voornaam.substring(0,1).toUpperCase()}
                      </div>

                  }

                  <div className='accounts_accountBar'>

                      <div 
                          className='accounts_firstRow'
                      >

                          <div style={{fontSize: '1.2vw', fontWeight: '500'}}>{userFullName}</div>

                          <div style={{fontStyle: 'italic', marginTop: 5}}>{account.account_rights === '2' ? 'Administrator' : account.account_rights === '1' ? 'Planner' : 'Medewerker'}</div>

                      </div>

                      <div className='accounts_secRow'>

                          <div className='accounts_secRow_sub1'>

                              <div style={{fontStyle: 'italic'}}>{account.info_email}</div>

                              <div style={{fontStyle: 'italic', marginTop: 15}}>{account.info_telefoon}</div>

                          </div>

                          <div className='accounts_secRow_sub2'>

                              <i 
                                  className={'fa fa-envelope'} 
                                  style={{fontSize: '1.5vw', color: '#47B7EB', cursor: 'pointer'}} 
                                  onClick={() => window.open(`mailto:${account.info_email}`)}
                              />

                              <i 
                                  className={'fa fa-phone'} 
                                  style={{fontSize: '1.5vw', marginTop: 15, color: '#47B7EB', cursor: 'pointer'}} 
                                  onClick={() => window.open(`tel:${account.info_telefoon}`)}
                              />

                          </div>

                      </div>

                  </div>
              </div>
          );

        }

        char = account.info_voornaam.substring(0,1).toUpperCase();

    }

    return accounts;

  }

  render() {

    const accountBars = this.getAccountBars();

    return (
        <div className='accounts'>

          <Navbar ref={this.navBar} history={this.props.history} active={'accounts'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Medewerkers {this.state.accounts.length > 0 ? '(' + (this.state.accounts.length - 1) + '/'+Data.data.maxEployees+')' : null}
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className={Data.data.accountData.account_rights === '2' ? 'accounts_containerBig' : 'accounts_container'}>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <AccountsModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                  _functions={this.state.functions}
                />

              :

                null
              
              }

              {/* {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              } */}

              {Data.data.accountData.account_rights === '2' ?
                
                <div className="planner_addButtons_container">

                  <div className="planner_addButton_management" style={{marginLeft: 0}} onClick={() => this.navBar.current.openRegistrationMenu()}>Medewerker toevoegen</div>

                </div>

              :

                null
              
              }

              {this.state.accounts.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen medewerkers op dit moment</div>
                </div>

              :

                null
              
              }

              {accountBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Accounts;