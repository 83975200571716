/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './hoursRegistration.css'
import '../myHours/myHours.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../../constants/DefaultStyle.css';
import '../../employees/employees.css';
import '../reports/reports.scss';
import '../appOptions/appOptions.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import ReactDatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import { Tooltip } from 'react-tippy';
import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import APIGetAllHoursMin from '../../../classes/APIGetAllHoursMin';
import APIGetAllHoursMinClock from '../../../classes/APIGetAllHoursMinClock';
import HoursDownload from '../../../components/excel/hoursDownload';
import APIGetAllHoursMinClockWeekly from '../../../classes/APIGetAllHoursMinClockWeekly';
import APIGetAllHoursMinWeekly from '../../../classes/APIGetAllHoursMinWeekly';
import APIGetSchedule from '../../../classes/APIGetSchedule';
import APIGetTeamUsers from '../../../classes/APIGetTeamUsers';
import APIGET from '../../../classes/global/APIGET';
import Colors from '../../../constants/Colors';
import APIDELETE from '../../../classes/global/APIDELETE';
import APIADD from '../../../classes/global/APIADD';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAllAbsenceSchedules from '../../../classes/APIGetAllAbsenceSchedules';
import APIGetAbsencetypes from '../../../classes/APIGetAbsencetypes';
import APIDeleteAbsenceSchedule from '../../../classes/APIDeleteAbsenceSchedule';
import APIAddAbsenceSchedule from '../../../classes/APIAddAbsenceSchedule';
import APIGetFunctions from '../../../classes/APIGetFunctions';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIGetAllContracts from '../../../classes/APIGetAllContracts';
import OverhoursModal from './overhoursModal';
import { Prompt } from 'react-router';
import Plan from '../../planner/plan.class';
import KodictNotifications from '../../../constants/KodictNotifications';
import Localization from '../../../constants/Localization';
import __ from '../../../constants/__';

class HoursRegistration extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    // compwillmount

    this.timeRef = {};
    this.lastDeleted = null;
    this.lastVal = '';

    this.onlyReport = typeof this.props.onlyReport !== 'undefined' && this.props.onlyReport !== null ? this.props.onlyReport : false;

    this.dateNow = new Date();

    const date = typeof this.props.location !== 'undefined' && typeof this.props.location.date !== 'undefined' && this.props.location.date !== null ? this.props.location.date : Constants.stringToDate(Constants.dateToString(this.dateNow));
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();

    this.teamUsers = [];
    this.plannerData = [];
    this.clockData = [];

    this.breakRules = [];

    this.reportDetailed = false;
    
    this.state = {

      loading: true,
      reloading: false,

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

      menu: Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? 'hours' : 'report',

      accounts: [],
      contracts: {},
      jobs: [],
      infoExtra: {},

      hoursDate: date,
      hoursDateString: Constants.dateToString(date),

      mondayDate: null,
      tuesdayDate: null,
      wednesdayDate: null,
      thursdayDate: null,
      fridayDate: null,
      saturdayDate: null,
      sundayDate: null,

      schedule: {},
      clock: {},
      hours: {},

      //iterations
      hoursDB: [],

      accepted: false,

      extraHours: {},

      showPlanner: true,

      absencetypes: [],
      absenceSchedules: [],

      showPlanMenuAbsence: {},
      showPlanMenu: {},


      reportData: [],
      scheduleData: [],
      scheduleDataObj: {},

      reportDisplay: 'period',
      reportSort: this.props.individual !== true ? Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC' : 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC' : Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC' : 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC', 
      reportDetailed: false,
      reportJob: 'all',
      reportEmployee: 'all',
      reportContract: null,
      reportBonus: true,

      reportStart: new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1),
      reportEnd: new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), Constants.getDaysInMonth(this.dateNow.getMonth() + 1, this.dateNow.getFullYear())),

      highlightDates: [],


      overhours: [],
      overhoursObj: {},
      overhoursDone: {},

      showOverhoursModal: false,
      overhoursModalData: {},
      overhoursCorrections: {},

      changes: false,

      bonus: [],

      absAmount: {},

      workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
      workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
      workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
      workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
      workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
      workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
      workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    let accounts = await APIGetAllAccounts.Request();

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      const teamUsers = await APIGetTeamUsers.Request();
      accounts = Constants.getTeamAccounts(teamUsers, accounts);
    }

    const jobs = await APIGetFunctions.Request();
    const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    // const overhoursCorrections = await APIGET.Request(`SELECT co_info_id, SUM(co_aantal) AS co_totaal`, `FROM CorrectieOveruren`, null, null, `GROUP BY co_info_id`, null);
    // const overhoursCorrectionsObj = Constants.convertToObject(overhoursCorrections, 'co_info_id');

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    const bonus = await APIGET.Request(`SELECT *`, `FROM Toeslag`, null, null, null, `ORDER BY t_factor DESC`);

    // const overhoursDone = await APIGET.Request(`SELECT *`, `FROM OverurenAfgerond`, null, null, null, `ORDER BY ova_id DESC LIMIT 53`);
    // const overhoursDoneObj = Constants.convertToObject(overhoursDone, 'ova_weekjaar');

    this.breakRules = await APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`);

    // setTimeout(async () => {

    //   await this.calcOverhours();
      
    // }, 2000);

    this.setState({
      // weeklyData: weeklyData,
      // plannerDataYear: hoursYear,
      // clockDataYear: clockYear,
      // overhoursDone: overhoursDoneObj,
      // overhoursObj: overhoursObj,
      contracts: contractsObj,
      // overhours: overhours,
      // overhoursCorrections: overhoursCorrectionsObj,
      jobs: jobs,
      accounts: accounts,
      bonus: bonus,
      infoExtra: infoExtraObj,
    }, async () => {

      await this.getWeekData(this.state.hoursDate);

      this.setState({ loading: false });

    });

  }

  // date = DATE OBJECT
  async getWeekData(date) {

    if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {

      const weekNumber = Constants.getWeekNumber2(date);
      const year = Constants.getBeginOfWeek(date).getFullYear();

      // const monday = Constants.getMonday(weekNumber, year);
      // const tuesday = Constants.getTuesday(weekNumber, year);
      // const wednesday = Constants.getWednesday(weekNumber, year);
      // const thursday = Constants.getThursday(weekNumber, year);
      // const friday = Constants.getFriday(weekNumber, year);
      // const saturday = Constants.getSaturday(weekNumber, year);
      // const sunday = Constants.getSunday(weekNumber, year);

      const monday = Constants.getBeginOfWeek(date, 1);
      const tuesday = Constants.getBeginOfWeek(date, 2);
      const wednesday = Constants.getBeginOfWeek(date, 3);
      const thursday = Constants.getBeginOfWeek(date, 4);
      const friday = Constants.getBeginOfWeek(date, 5);
      const saturday = Constants.getBeginOfWeek(date, 6);
      const sunday = Constants.getBeginOfWeek(date, 0);

      const extraHours = {
        [Constants.dateToString(monday)]: [],
        [Constants.dateToString(tuesday)]: [],
        [Constants.dateToString(wednesday)]: [],
        [Constants.dateToString(thursday)]: [],
        [Constants.dateToString(friday)]: [],
        [Constants.dateToString(saturday)]: [],
        [Constants.dateToString(sunday)]: [],
      };

      // get schedules
      let scheduleWeek = [];
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        scheduleWeek = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE (rooster_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND rooster_publiceren = 1 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY info_voornaam, info_achternaam, rooster_datum, rooster_begin, rooster_eind`);
      } else {
        scheduleWeek = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id`, `WHERE (rooster_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND rooster_publiceren = 1`, null, `ORDER BY info_voornaam, info_achternaam, rooster_datum, rooster_begin, rooster_eind`);
      }
      const scheduleWeekObj = Constants.convertToObjectWithArrays(scheduleWeek, 'rooster_datum');

      // get clocktimes
      let clockWeek = [];
      if (Data.data.appOptions.hours === 'CLOCK') {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          clockWeek = await APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id RIGHT JOIN TeamKloktijd ON teamk_kloktijd_id = kloktijd_id`, `WHERE (kloktijd_inklok_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND teamk_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY info_voornaam, info_achternaam`);
        } else {
          clockWeek = await APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id`, `WHERE (kloktijd_inklok_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}')`, null, `ORDER BY info_voornaam, info_achternaam`);
        }
      }
      const clockWeekObj = Constants.convertToObjectWithArrays(clockWeek, 'kloktijd_inklok_datum');

      // get hours worked FROM ROOSTER OR CLOCK
      let hours = [];
      if (Data.data.appOptions.hours === 'PLANNER') {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          hours = await APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id LEFT JOIN Rooster ON uren_rooster_id = rooster_id RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND uren_rooster_id IS NOT NULL AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY info_voornaam, info_achternaam`);
        } else {
          hours = await APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id LEFT JOIN Rooster ON uren_rooster_id = rooster_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND uren_rooster_id IS NOT NULL`, null, `ORDER BY info_voornaam, info_achternaam`);
        }
      } else if (Data.data.appOptions.hours === 'CLOCK') {
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          hours = await APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id LEFT JOIN Kloktijd ON uren_kloktijd_id = kloktijd_id RIGHT JOIN TeamKloktijd ON teamk_kloktijd_id = kloktijd_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND uren_kloktijd_id IS NOT NULL AND teamk_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY info_voornaam, info_achternaam`);
        } else {
          hours = await APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id LEFT JOIN Kloktijd ON uren_kloktijd_id = kloktijd_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND uren_kloktijd_id IS NOT NULL`, null, `ORDER BY info_voornaam, info_achternaam`);
        }
      }
      const hoursObj = Constants.convertToObject(hours, Data.data.appOptions.hours === 'CLOCK' ? 'uren_kloktijd_id' : 'uren_rooster_id');

      // get hours worked EXTRA HOURS
      let extraHoursDB = [];
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        extraHoursDB = await APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND uren_rooster_id IS NULL AND uren_kloktijd_id IS NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY info_voornaam, info_achternaam`);
      } else {
        extraHoursDB = await APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') AND uren_rooster_id IS NULL AND uren_kloktijd_id IS NULL`, null, `ORDER BY info_voornaam, info_achternaam`);
      }

      for (const eh of extraHoursDB) {
        extraHours[eh.uren_begindatum].push(eh);
      }

      let hoursObjForState = {}; // to use in state

      if (Data.data.appOptions.hours === 'PLANNER') {

        for (const key in scheduleWeekObj) { //iterate through planning monday - sunday

          const _date = scheduleWeekObj[key]; // monday - sunday

          // the monday or... sunday isnt defined, define it
          if (typeof hoursObjForState[key] === 'undefined') {
            hoursObjForState[key] = {};
          }

          // iterate through schedules of the cycled date
          for (const schedule of _date) {

            // this schedule has a CORRECTED time
            if (typeof hoursObj[schedule.rooster_id] !== 'undefined') {
              hoursObjForState[key][schedule.rooster_id] = hoursObj[schedule.rooster_id];
            } else {

              let _break = schedule.rooster_pauze;
              if (Data.data.appOptions.brk === 'CALC') {
                _break = this.getBreakRule(schedule.rooster_datum, schedule.rooster_begin, schedule.rooster_datum, schedule.rooster_eind, schedule).str.substr(0, 5);
              }

              hoursObjForState[key][schedule.rooster_id] = {
                ...schedule,
                uren_id: null,
                uren_begindatum: schedule.rooster_datum,
                uren_einddatum: Plan.isNightShift2(schedule),
                uren_begin: schedule.rooster_begin,
                uren_eind: schedule.rooster_eind,
                uren_pauze: _break,
              };
              
            }

          }

        }

      } else if (Data.data.appOptions.hours === 'CLOCK') {

        for (const key in clockWeekObj) { //iterate through planning monday - sunday

          const _date = clockWeekObj[key]; // monday - sunday

          // the monday or... sunday isnt defined, define it
          if (typeof hoursObjForState[key] === 'undefined') {
            hoursObjForState[key] = {};
          }

          // iterate through schedules of the cycled date
          for (const clock of _date) {

            // this schedule has a CORRECTED time
            if (typeof hoursObj[clock.kloktijd_id] !== 'undefined') {
              hoursObjForState[key][clock.kloktijd_id] = hoursObj[clock.kloktijd_id];
            } else {

              let _break = clock.kloktijd_pauze.substr(0, 8);
              if (Data.data.appOptions.brk === 'PLANNER') {
                _break = this.getUserPlannerBreak(clock.info_id, key, scheduleWeekObj);
              } else if (Data.data.appOptions.brk === 'CALC') {
                _break = this.getBreakRule(clock.kloktijd_inklok_datum, clock.kloktijd_inklok_tijd, clock.kloktijd_uitklok_datum, clock.kloktijd_uitklok_tijd).str.substr(0, 5);
              }

              hoursObjForState[key][clock.kloktijd_id] = {
                ...clock,
                uren_id: null,
                uren_begindatum: clock.kloktijd_inklok_datum,
                uren_einddatum: clock.kloktijd_uitklok_datum,
                uren_begin: clock.kloktijd_inklok_tijd,
                uren_eind: clock.kloktijd_uitklok_tijd,
                uren_pauze: _break,
              };

            }

          }

        }

      }
  
      // MENUS ABSENCE
      let showPlanMenu = this.state.showPlanMenu;
      let showPlanMenuAbsence = this.state.showPlanMenuAbsence;

      for (const key in scheduleWeekObj) {

        const _date = scheduleWeekObj[key]; // monday - sunday

        // the monday or... sunday isnt defined, define it
        if (typeof showPlanMenu[key] === 'undefined') {
          showPlanMenu[key] = {};
        }
        if (typeof showPlanMenuAbsence[key] === 'undefined') {
          showPlanMenuAbsence[key] = {};
        }

        for (const plan of _date) {
          showPlanMenu[key][plan.rooster_id] = false;
          showPlanMenuAbsence[key][plan.rooster_id] = false;
        }
      }

      const absencetypes = await APIGetAbsencetypes.Request();

      const absenceSchedules = await APIGetAllAbsenceSchedules.Request(Constants.formatDate(monday), Constants.formatDate(sunday));
      const absenceSchedulesObj = Constants.convertToObject(absenceSchedules, 'rooster_id');

      let pastTwoMonths = new Date();
      pastTwoMonths.setMonth(pastTwoMonths.getMonth() - 2);
      let allFilledDays = [];
      let allFilledDaysExtra = [];
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        allFilledDays = Data.data.appOptions.hours === 'CLOCK' ? await APIGET.Request(`SELECT uren_begindatum, uren_goedgekeurd`, `FROM Uren`, `RIGHT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(pastTwoMonths)}' AND '${Constants.dateToString(new Date())}') AND uren_kloktijd_id IS NOT NULL AND teamk_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : await APIGET.Request(`SELECT uren_begindatum, uren_goedgekeurd`, `FROM Uren`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(pastTwoMonths)}' AND '${Constants.dateToString(new Date())}') AND uren_rooster_id IS NOT NULL AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
      } else {
        allFilledDays = Data.data.appOptions.hours === 'CLOCK' ? await APIGET.Request(`SELECT uren_begindatum, uren_goedgekeurd`, `FROM Uren`, null, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(pastTwoMonths)}' AND '${Constants.dateToString(new Date())}') AND uren_kloktijd_id IS NOT NULL`, null, null) : await APIGET.Request(`SELECT uren_begindatum, uren_goedgekeurd`, `FROM Uren`, null, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(pastTwoMonths)}' AND '${Constants.dateToString(new Date())}') AND uren_rooster_id IS NOT NULL`, null, null);
      }
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        allFilledDaysExtra = await APIGET.Request(`SELECT uren_begindatum, uren_goedgekeurd`, `FROM Uren`, null, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(pastTwoMonths)}' AND '${Constants.dateToString(new Date())}') AND uren_kloktijd_id IS NULL AND uren_rooster_id IS NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
      } else {
        allFilledDaysExtra = await APIGET.Request(`SELECT uren_begindatum, uren_goedgekeurd`, `FROM Uren`, null, `WHERE (uren_begindatum BETWEEN '${Constants.dateToString(pastTwoMonths)}' AND '${Constants.dateToString(new Date())}') AND uren_kloktijd_id IS NULL AND uren_rooster_id IS NULL`, null, null);
      }
      allFilledDays = allFilledDaysExtra.concat(allFilledDays);
      const allFilledDaysObj = Constants.convertToObjectWithArrays(allFilledDays, 'uren_begindatum');

      let highlightDates = [
        {  "hr_highlight_date": [] }
      ];

      for (const key in allFilledDaysObj) {

        const _date = allFilledDaysObj[key];
        let accepted = false;

        for (const hours of _date) {

          if (hours.uren_goedgekeurd === '1') {
            accepted = true;
          } else {
            accepted = false;
            break;
          }

        }

        if (accepted === true) {
          highlightDates[0]['hr_highlight_date'].push(Constants.stringToDate(key));
        }

      }

      const overhours = await APIGET.Request(`SELECT *`, `FROM Overuren`, null, `WHERE ov_datum BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}'`, null, null);
      const overhoursObj = Constants.convertToObjectWithArrays(overhours, 'ov_info_id');

      const absAmount = await APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id`, `WHERE (vakantie_begin BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}') OR (vakantie_eind BETWEEN '${Constants.dateToString(monday)}' AND '${Constants.dateToString(sunday)}')`, null, null);
      const absAmountObj = Constants.convertToObjectWithArrays(absAmount, 'vs_info_id');

      this.setState({
        hoursDate: date,
        hoursDateString: Constants.dateToString(date),
        mondayDate: monday,
        tuesdayDate: tuesday,
        wednesdayDate: wednesday,
        thursdayDate: thursday,
        fridayDate: friday,
        saturdayDate: saturday,
        sundayDate: sunday,

        overhours: overhours,
        overhoursObj: overhoursObj,

        schedule: scheduleWeekObj,
        clock: clockWeekObj,
        hours: hoursObjForState,

        hoursDB: hours,

        accepted: this.hoursAccepted(hoursObjForState, extraHours, Constants.dateToString(date)),

        extraHours: extraHours,

        showPlanMenu: showPlanMenu,
        showPlanMenuAbsence: showPlanMenuAbsence,

        absenceSchedules: absenceSchedulesObj,
        absencetypes: absencetypes,

        highlightDates: highlightDates,

        absAmount: absAmountObj,

      });

    }

    await this.changeHoursDisplay(this.state.reportDisplay, this.state.reportDetailed, this.state.reportSort, Constants.dateToString(this.state.reportStart), Constants.dateToString(this.state.reportEnd));

  }

  hoursAccepted(hours, extraHours, dateString) {

    let accepted = true;

    if ((typeof hours[dateString] === 'undefined' || hours[dateString].length === 0) && (typeof extraHours[dateString] === 'undefined' || extraHours[dateString].length === 0)) {
      return false;
    }

    for (const key in hours[dateString]) {
      if (hours[dateString][key].uren_goedgekeurd !== '1') {
        accepted = false;
        break;
      }
    }

    for (const key in extraHours[dateString]) {
      if (extraHours[dateString][key].uren_goedgekeurd !== '1') {
        accepted = false;
        break;
      }
    }

    return accepted;

  }

  getBreakRule(startDate, startTime, endDate, endTime, schedule = null) {

    const start = Constants.getDateTZ(startDate, startTime);
    const end = Constants.getDateTZ(schedule !== null ? Plan.isNightShift2(schedule) : endDate, endTime);

    const totalMS = end.getTime() - start.getTime();
    const totalHours = totalMS / 1000 / 60 / 60;

    let brk = 0;
    for (const br of this.breakRules) {

      if (totalHours >= parseFloat(br.pr_uren)) {
        brk = parseInt(br.pr_pauze);
      }

    }

    if (brk > 0) {
      brk = brk * 60 * 1000;
    }

    return {
      ms: brk,
      str: Constants.msToTime(brk),
    };

  }

  async getSchedule(month, year) {

    const today = await Constants.getDateNow(); //new Date();

    let scheduleObject = [];

    for (let i = 1; i < 32; i++) {
      let s = null;
      if(typeof month === 'undefined' || month === null) {
        s = await APIGetSchedule.Request(`${today.getFullYear()}-${today.getMonth() + 1}-${i}`);
      } else {
        s = await APIGetSchedule.Request(`${year}-${month}-${i}`);
      }
      if(s !== null) {
        for(const _s of s) {
          scheduleObject.push({..._s});
        }
      }
    }

    if(scheduleObject === null) {
      this.setState({
        schedule: [],
        loading: false,
      });
      return;
    }

    if(scheduleObject === 'error') {
      this.setState({
          schedule: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    this.setState({schedule: scheduleObject});

  }

  endFirstWeek(firstDate, firstDay) {
    if (! firstDay) {
        return 7 - firstDate.getDay();
    }
    if (firstDate.getDay() < firstDay) {
        return firstDay - firstDate.getDay();
    } else {
        return 7 - firstDate.getDay() + firstDay;
    }
  }

  getWeekNumber2(today) {
    let date = today;
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getWeeksStartAndEndInMonth(month, year) {
    let weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();

    let start = 1;
    let end = this.endFirstWeek(firstDate, 2);
    while (start <= numDays) {
      if (start !== end) {
        const week = this.getWeekNumber2(new Date(year, month, start));
        weeks.push({week: week, start: start === 1 ? start : start - 1, end: end === Constants.getDaysInMonth(month + 1, year) ? end : end - 1});
      }
      start = end + 1;
      end = end + 7;
      end = start === 1 && end === 8 ? 1 : end;
      if (end > numDays) {
          end = numDays;
      }
    }
    return weeks;
  } 

  async getAllHoursMin(month, year) {
    
    let plannerData = await APIGetAllHoursMin.Request(month, year);

    if(plannerData === null) {
      this.setState({
        plannerData: [],
        loading: false,
      });
      return;
    }

    if(plannerData === 'error') {
      this.setState({
          plannerData: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      plannerData = Constants.getTeamAccounts(this.teamUsers, plannerData);
    }

    this.plannerData = plannerData;

    this.setState({plannerData: plannerData});

  }

  async getAllHoursClock(month, year) {

    if(Data.data.appOptions.brk === 'PLANNER') { //CLOCK
      await this.getSchedule(month, year);
    }
    
    let plannerData = await APIGetAllHoursMinClock.Request(month, year);

    if(plannerData === null) {
      this.setState({
        clockData: [],
        loading: false,
      });
      return;
    }

    if(plannerData === 'error') {
      this.setState({
          clockData: [],
          errorScreen: true,
          loading: false,
      });
      return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      plannerData = Constants.getTeamAccounts(this.teamUsers, plannerData);
    }

    if(Data.data.appOptions.brk === 'PLANNER') { //breaking changes 0, 5 to 0, 8
      for(const clockData of plannerData) {
          clockData.kloktijd_pauze_totaal = this.timeStringToFloat(this.getUserPlannerBreak(clockData.info_id).substring(0, 8));
          clockData.kloktijd_netto_uren = this.timeStringToFloat(clockData.kloktijd_bruto_uren.substring(0, 8)) - this.timeStringToFloat(this.getUserPlannerBreak(clockData.info_id).substring(0, 8));
      }
    }

    this.clockData = plannerData;

    this.setState({clockData: plannerData});

  }

  async selectDateCalendar(date) {

    if(this.state.loading === true) {
      return;
    }

    const today = new Date();
    const newDate = new Date(date);

    if(today < newDate) {
      return false;
    }

  this.setState({loading: true});

  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  this.setState({
      month: month,
      year: year,
      dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
  });

  await this.reloadData(month, year);

  this.setState({loading: false, selectedDate: date});

  }

  async nextMonth() {

    if(this.state.loading === true) {
        return;
    }

    let month = this.state.month + 1;
    let year = this.state.year;

    if(month >= 13) {

        month = 1;
        year += 1;
        
    }

    if(this.canGoNext() === false) {
      return;
    }

    this.setState({loading: true});

    this.setState({
        month: month,
        year: year,
        dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadData(month, year);

    this.setState({loading: false});

  }

  async previousMonth() {

    if(this.state.loading === true) {
        return;
    }

    this.setState({loading: true});

    let month = this.state.month - 1;
    let year = this.state.year;

    if(month <= 0) {

      month = 12;
      year -= 1;
      
    }

    this.setState({
        month: month,
        year: year,
        dateText: `${year}-${month < 10 ? '0' + month : month}-01`,
    });

    await this.reloadData(month, year);

    this.setState({loading: false});

  }

  async reloadData(month = this.state.month, year = this.state.year) {

    const date = new Date(year, month, 0);

    if(Data.data.appOptions.hours === 'CLOCK') {
      await this.getAllHoursClock(month, year);
    } else {
      await this.getAllHoursMin(month, year);
    }

    const weeks = this.getWeeksStartAndEndInMonth(month - 1, year);
    const weeklyData = [];

    for (let i = 0; i < weeks.length; i++) {

      let weekData = [];

      if(Data.data.appOptions.hours === 'CLOCK') {
        weekData = await APIGetAllHoursMinClockWeekly.Request(`${year}-${month}-${weeks[i].start}`, `${year}-${month}-${weeks[i].end}`);
      } else {
        weekData = await APIGetAllHoursMinWeekly.Request(`${year}-${month}-${weeks[i].start}`, `${year}-${month}-${weeks[i].end}`);
      }

      if(Data.data.appOptions.brk === 'PLANNER' && Data.data.appOptions.hours === 'CLOCK') {//breaking changes 0, 5 to 0, 8
        if(weekData !== null) {
          for(const clockData of weekData) {
              clockData.kloktijd_pauze_totaal = this.timeStringToFloat(this.getUserPlannerBreakWeekly(clockData.info_id, weeks[i].start, weeks[i].end).substring(0, 8));
              clockData.kloktijd_netto_uren = this.timeStringToFloat(clockData.kloktijd_bruto_uren.substring(0, 8)) - this.timeStringToFloat(this.getUserPlannerBreakWeekly(clockData.info_id, weeks[i].start, weeks[i].end).substring(0, 8));
          }
        }
      }

      weeklyData.push({
        week: weeks[i].week,
        ...weekData,
      })
      
    }

    this.setState({
      weeklyData: weeklyData,
      selectedDate: date,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  showOverhoursModal(absence, data) {

    this.setState({ 
      showOverhoursModal: true,
      overhoursModalData: {
        absence: absence,
        data: data,
      },
    });

  }

  closeOverhoursModal() {

    this.setState({ 
      showOverhoursModal: false,
      overhoursModalData: {},
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
    const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
    return Math.round((hoursMins + Number.EPSILON) * 100) / 100;
  }

  // getUserPlannerBreak(id) {

  //   let breakTime = '00:00';

  //   for(let key in this.state.schedule) {

  //     const schedule = this.state.schedule[key];

  //     // const today = new Date();
  //     // const scheduleDate = Constants.getDateTZ(schedule.rooster_datum, schedule.rooster_eind);

  //     if(schedule.info_id === id) { // && today.getTime() >= scheduleDate.getTime()

  //       if(breakTime !== '00:00') {

  //         const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
  //         const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
  //         const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
  //         const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

  //         const totalBreakHours = oldBreakHours + breakHours;
  //         const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

  //         const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
  //         newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

  //         const newBreakHours = newBreakDateAndTime.getUTCHours();
  //         const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();
  //         // const newBreakHours = Constants.getHoursTZD(newBreakDateAndTime);
  //         // const newBreakMinutes = Constants.getMinutesTZD(newBreakDateAndTime);

  //         breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

  //       } else {

  //         breakTime = schedule.rooster_pauze.substring(0, 5);

  //       }

  //     }

  //   }

  //   return breakTime;

  // }

  getUserPlannerBreakWeekly(id, start, end) {

    let breakTime = '00:00';

    for(let key in this.state.schedule) {

      const schedule = this.state.schedule[key];

      const year = parseInt(schedule.rooster_datum.substring(0, 4));
      const month = parseInt(schedule.rooster_datum.substring(5, 7));
      const day = parseInt(schedule.rooster_datum.substring(8, 10));

      // const today = new Date();
      // const scheduleDate = Constants.getDateTZ(schedule.rooster_datum, schedule.rooster_eind);

      if(schedule.info_id === id && year === this.state.year && month === this.state.month && (day >= start && day <= end)) { // && today.getTime() >= scheduleDate.getTime()

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }

  getHourPlannerBars() {

    let hourBars = [];

    for(let key in this.state.plannerData) {

      const plannerData = this.state.plannerData[key];

      const userFullName = `${plannerData.info_voornaam} ${(plannerData.info_tussenvoegsel !== null && plannerData.info_tussenvoegsel !== '') ? plannerData.info_tussenvoegsel + " " + plannerData.info_achternaam : plannerData.info_achternaam}`;

      hourBars.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'} style={{flex: 2}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow'}>
            {plannerData.rooster_werkdagen}
          </div>
          <div className={'myHours_barRow'}>
            {this.timeStringToFloat(plannerData.rooster_bruto_uren)}
          </div>
          <div className={'myHours_barRow'}>
            {this.timeStringToFloat(plannerData.rooster_pauze_totaal)}
          </div>
          <div className={'myHours_barRow'} style={{fontWeight: 'bold'}}>
            {this.timeStringToFloat(plannerData.rooster_netto_uren)}
          </div>
          <div 
            className={'myHours_barRow'} 
            style={{flex: 0.5, color: '#282828', cursor: 'pointer', justifyContent: 'center'}}
            onClick={() => this.props.history.push({
              pathname: `/user_hours_details`,
              date: new Date(this.state.year, this.state.selectedMonth, 1),
              userFullName: userFullName,
              userID: parseInt(plannerData.info_id)
            })}
          >
            <i className={'far fa-eye'}></i>
          </div>

        </div>

      );

    }

    return hourBars;

  }

  getHourPlannerBarsClock() {

    let hourBars = [];

    for(let key in this.state.clockData) {

      const clockData = this.state.clockData[key];

      const userFullName = `${clockData.info_voornaam} ${(clockData.info_tussenvoegsel !== null && clockData.info_tussenvoegsel !== '') ? clockData.info_tussenvoegsel + " " + clockData.info_achternaam : clockData.info_achternaam}`;

      let breakTime = 0;

      if(this.state.loading === false) {//breaking changes 0, 5 to 0, 8
          breakTime = this.timeStringToFloat(this.getUserPlannerBreak(clockData.info_id).substring(0, 8));
      }

      hourBars.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'} style={{flex: 2}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow'}>
            {clockData.kloktijd_werkdagen}x
          </div>
          <div className={'myHours_barRow'}>
            {this.timeStringToFloat(clockData.kloktijd_bruto_uren)}
          </div>
          <div className={'myHours_barRow'}>
            {Data.data.appOptions.brk === 'PLANNER' ?
              breakTime
            :
              this.timeStringToFloat(clockData.kloktijd_pauze_totaal.substring(0, 8)) //breaking changes 0, 5 to 0, 8
            }
          </div>
          <div className={'myHours_barRow'} style={{fontWeight: 'bold'}}>
            {Data.data.appOptions.brk === 'PLANNER' ?
              Constants.round(this.timeStringToFloat(clockData.kloktijd_bruto_uren) - breakTime)
            :
              Constants.round(this.timeStringToFloat(clockData.kloktijd_netto_uren))
            }
          </div>
          <div 
            className={'myHours_barRow'} 
            style={{flex: 0.5, color: '#282828', cursor: 'pointer', justifyContent: 'center'}}
            onClick={() => this.props.history.push({
              pathname: `/user_hours_details`,
              date: new Date(this.state.year, this.state.selectedMonth, 1),
              userFullName: userFullName,
              userID: parseInt(clockData.info_id),
              mode: 'CLOCK',
            })}
          >
            <i className={'far fa-eye'}></i>
          </div>

        </div>

      );

    }

    return hourBars;

  }

  getHourPlannerBarsYear() {

    let hourBars = [];

    for(let key in this.state.plannerDataYear) {

      const plannerData = this.state.plannerDataYear[key];

      const userFullName = `${plannerData.info_voornaam} ${(plannerData.info_tussenvoegsel !== null && plannerData.info_tussenvoegsel !== '') ? plannerData.info_tussenvoegsel + " " + plannerData.info_achternaam : plannerData.info_achternaam}`;

      hourBars.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'} style={{flex: 2}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow'}>
            {plannerData.rooster_werkdagen}
          </div>
          <div className={'myHours_barRow'}>
            {Constants.round(parseFloat(plannerData.rooster_bruto_uren) / 60 / 60)}
          </div>
          <div className={'myHours_barRow'}>
            {Constants.round(parseFloat(plannerData.rooster_pauze_totaal) / 60 / 60)}
          </div>
          <div className={'myHours_barRow'} style={{fontWeight: 'bold'}}>
            {Constants.round(parseFloat(plannerData.rooster_netto_uren) / 60 / 60)}
          </div>

        </div>

      );

    }

    return hourBars;

  }

  getHourPlannerBarsClockYear() {

    let hourBars = [];

    for(let key in this.state.clockDataYear) {

      const clockData = this.state.clockDataYear[key];

      const userFullName = `${clockData.info_voornaam} ${(clockData.info_tussenvoegsel !== null && clockData.info_tussenvoegsel !== '') ? clockData.info_tussenvoegsel + " " + clockData.info_achternaam : clockData.info_achternaam}`;

      let breakTime = 0;

      if(this.state.loading === false) {//breaking changes 0, 5 to 0, 8
          breakTime = this.timeStringToFloat(this.getUserPlannerBreak(clockData.info_id).substring(0, 8));
      }

      hourBars.push(

        <div key={key} className={'myHours_barContainer'}>

          <div className={'myHours_barRow'} style={{flex: 2}}>
            {userFullName}
          </div>
          <div className={'myHours_barRow'}>
            {clockData.kloktijd_werkdagen}x
          </div>
          <div className={'myHours_barRow'}>
            {Constants.round(parseFloat(clockData.kloktijd_bruto_uren) / 60 / 60)}
          </div>
          <div className={'myHours_barRow'}>
            {Data.data.appOptions.brk === 'PLANNER' ?
              breakTime
            :
            Constants.round(parseFloat(clockData.kloktijd_pauze_totaal) / 60 / 60) //breaking changes 0, 5 to 0, 8
            }
          </div>
          <div className={'myHours_barRow'} style={{fontWeight: 'bold'}}>
            {Data.data.appOptions.brk === 'PLANNER' ?
              Constants.round((parseFloat(clockData.kloktijd_bruto_uren) / 60 / 60) - breakTime)
            :
              Constants.round(parseFloat(clockData.kloktijd_netto_uren) / 60 / 60)
            }
          </div>

        </div>

      );

    }

    return hourBars;

  }

  canGoNext() {

    let month = this.state.month + 1;
    let year = this.state.year;

    if(month >= 13) {

        month = 1;
        year += 1;
        
    }

    const today = new Date();
    const newDate = new Date(year, month-1, 1);

    if(today < newDate) {
      return false;
    }

    return true;

  }

  async selectMonth(month) {

    if (this.state.selectedMonth === month) {
      return;
    }

    this.setState({ loading: true });

    if (month !== null) {
      await this.reloadData(month + 1, this.state.year);
    }
    
    this.setState({ selectedMonth: month, selectedWeek: null, dateText: `${this.state.year}-${(month + 1) < 10 ? '0' + (month + 1) : (month + 1)}-01`, loading: false });

  }

  async selectWeek(week) {

    if (this.state.selectedWeek === week) {
      return;
    }

    if (week !== null) {

      let weekData = [];

      for (const data of this.state.weeklyData) {
        if (data.week === week) {
          for (const key in data) {
            if (key !== 'week') {
              weekData.push(data[key]);
            }
          }
        }
      }
      
      if (Data.data.appOptions.hours === 'CLOCK') {
        this.setState({ selectedWeek: week, clockData: weekData }); //
      } else {
        this.setState({ selectedWeek: week, plannerData: weekData }); //
      }

    } else {

      this.setState({ selectedWeek: null, plannerData: this.plannerData, clockData: this.clockData });

    }

  }

  renderWeekNumbers() {

    let toReturn = [];

    for (const data of this.state.weeklyData) {

      toReturn.push(
        <div className={`employees_menuButton ${this.state.selectedWeek === data.week ? 'activeAv' : ''}`} onClick={() => this.selectWeek(data.week)}>
          <div>{__('misc_week_c')} {data.week}</div>
        </div>
      );

    }

    return toReturn;

  }

  async nextYear() {

    this.setState({ loading: true });

    let hoursYear = [];
    let clockYear = [];

    if (Data.data.appOptions.hours === 'CLOCK') {

      clockYear = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, ` +
        `COUNT(kloktijd_id) as kloktijd_werkdagen, ` +
        `SUM(TIME_TO_SEC(kloktijd_uitklok_tijd) - TIME_TO_SEC(kloktijd_inklok_tijd)) as kloktijd_bruto_uren, ` +
        `SUM(TIME_TO_SEC(kloktijd_uitklok_tijd) - TIME_TO_SEC(kloktijd_inklok_tijd) - TIME_TO_SEC(kloktijd_pauze)) as kloktijd_netto_uren, ` +
        `SUM(TIME_TO_SEC(kloktijd_pauze)) as kloktijd_pauze_totaal`, 
        `FROM Kloktijd`,
        `LEFT JOIN Info ON info_id = kloktijd_info_id`,
        `WHERE YEAR(kloktijd_inklok_datum) = ${this.state.year + 1}`,
        `GROUP BY info_id`,
        `ORDER BY info_voornaam, info_achternaam`
      );

    } else {

      hoursYear = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, ` +
        `COUNT(rooster_id) as rooster_werkdagen, ` +
        `SUM(TIME_TO_SEC(rooster_eind) - TIME_TO_SEC(rooster_begin)) as rooster_bruto_uren, ` +
        `SUM(TIME_TO_SEC(rooster_eind) - TIME_TO_SEC(rooster_begin) - TIME_TO_SEC(rooster_pauze)) as rooster_netto_uren, ` +
        `SUM(TIME_TO_SEC(rooster_pauze)) as rooster_pauze_totaal`, 
        `FROM Rooster`,
        `LEFT JOIN Info ON info_id = rooster_info_id`,
        `WHERE YEAR(rooster_datum) = ${this.state.year + 1} AND rooster_publiceren = 1`,
        `GROUP BY info_id`,
        `ORDER BY info_voornaam, info_achternaam`
      );

    }

    this.setState({
      plannerDataYear: hoursYear,
      clockDataYear: clockYear,
      year: this.state.year + 1,
      loading: false,
    });

  }

  async previousYear() {

    this.setState({ loading: true });

    let hoursYear = [];
    let clockYear = [];

    if (Data.data.appOptions.hours === 'CLOCK') {

      clockYear = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, ` +
        `COUNT(kloktijd_id) as kloktijd_werkdagen, ` +
        `SUM(TIME_TO_SEC(kloktijd_uitklok_tijd) - TIME_TO_SEC(kloktijd_inklok_tijd)) as kloktijd_bruto_uren, ` +
        `SUM(TIME_TO_SEC(kloktijd_uitklok_tijd) - TIME_TO_SEC(kloktijd_inklok_tijd) - TIME_TO_SEC(kloktijd_pauze)) as kloktijd_netto_uren, ` +
        `SUM(TIME_TO_SEC(kloktijd_pauze)) as kloktijd_pauze_totaal`, 
        `FROM Kloktijd`,
        `LEFT JOIN Info ON info_id = kloktijd_info_id`,
        `WHERE YEAR(kloktijd_inklok_datum) = ${this.state.year - 1}`,
        `GROUP BY info_id`,
        `ORDER BY info_voornaam, info_achternaam`
      );

    } else {

      hoursYear = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, ` +
        `COUNT(rooster_id) as rooster_werkdagen, ` +
        `SUM(TIME_TO_SEC(rooster_eind) - TIME_TO_SEC(rooster_begin)) as rooster_bruto_uren, ` +
        `SUM(TIME_TO_SEC(rooster_eind) - TIME_TO_SEC(rooster_begin) - TIME_TO_SEC(rooster_pauze)) as rooster_netto_uren, ` +
        `SUM(TIME_TO_SEC(rooster_pauze)) as rooster_pauze_totaal`, 
        `FROM Rooster`,
        `LEFT JOIN Info ON info_id = rooster_info_id`,
        `WHERE YEAR(rooster_datum) = ${this.state.year - 1} AND rooster_publiceren = 1`,
        `GROUP BY info_id`,
        `ORDER BY info_voornaam, info_achternaam`
      );

    }

    this.setState({
      plannerDataYear: hoursYear,
      clockDataYear: clockYear,
      year: this.state.year - 1,
      loading: false,
    });

  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 10) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  onChangeTime(e, _date, key, key2, hours, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }

    this.setState({ changes: true });

    let times = this.state[hours];
    let val = e.target.value.toString();
    // if (val == this.timeRef[planData.rooster_id].value) {
    //     return;
    // }

    // if(val.charAt(0) !== '' && val.charAt(0) !== '0' && val.charAt(0) !== '1' && val.charAt(0) !== '2') {
    //   
    //   e.target.value = this.lastVal;
    //   return;
    // }
    // if (val.length == 4) {
    //   if(val.charAt(3) !== '0' && val.charAt(3) !== '1' && val.charAt(3) !== '2' && val.charAt(3) !== '3' && val.charAt(3) !== '4' && val.charAt(3) !== '5') {
    //     
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // if (val.length > 4) {
    //   if(parseInt(val.substr(3, 4)) < 0 && parseInt(val.substr(3, 4)) > 59) {
    //     
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // // ERROR IN HERE (this.lastVal)
    // if (val.length > 8) {
    //   if(val.charAt(8) !== '0' && val.charAt(8) !== '1' && val.charAt(8) !== '2') {
    //     
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // if (val.length > 11) {

    //   if(val.charAt(11) !== '0' && val.charAt(11) !== '1' && val.charAt(11) !== '2' && val.charAt(11) !== '3' && val.charAt(11) !== '4' && val.charAt(11) !== '5') {
    //     
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    //   if(parseInt(val.substr(11, 12)) < 0 && parseInt(val.substr(11, 12)) > 59) {
    //     
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // ERROR IN HERE

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : ``.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    }

    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      times[_date][key][key2] = e.target.value;
      this.setState({ [hours]: times });
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
      times[_date][key][key2] = e.target.value;
      this.setState({ [hours]: times });
      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length > 5) {
          e.target.value = val;
          times[_date][key][key2] = e.target.value;
          this.setState({ [hours]: times });
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;
        times[_date][key][key2] = e.target.value;
        this.setState({ [hours]: times });

        if (Data.data.appOptions.brk === 'CALC') {
          if (key2 === 'end' && this.isValid(times[_date][key]['start'])) {

            const obj = {
              rooster_datum: _date,
              rooster_begin: times[_date][key]['start'],
              rooster_eind: val,
            };

            times[_date][key]['break'] = this.getBreakRule(_date, times[_date][key]['start'], _date, val, obj).str.substr(0, 5);

          } else if (key2 === 'start' && this.isValid(times[_date][key]['end'])) {

            const obj = {
              rooster_datum: _date,
              rooster_begin: val,
              rooster_eind: times[_date][key]['end'],
            };

            times[_date][key]['break'] = this.getBreakRule(_date, val, _date, times[_date][key]['end'], obj).str.substr(0, 5);

          } else if (key2 === 'uren_eind' && this.isValid(times[_date][key]['uren_begin'])) {

            const obj = {
              rooster_datum: _date,
              rooster_begin: times[_date][key]['uren_begin'],
              rooster_eind: val,
            };

            times[_date][key]['uren_pauze'] = this.getBreakRule(_date, times[_date][key]['uren_begin'], _date, val, obj).str.substr(0, 5);

          } else if (key2 === 'uren_begin' && this.isValid(times[_date][key]['uren_eind'])) {

            const obj = {
              rooster_datum: _date,
              rooster_begin: val,
              rooster_eind: times[_date][key]['uren_eind'],
            };

            times[_date][key]['uren_pauze'] = this.getBreakRule(_date, val, _date, times[_date][key]['uren_eind'], obj).str.substr(0, 5);

          }
        }

    } else {
      e.target.value = '';
      times[_date][key][key2] = e.target.value;
      this.setState({ [hours]: times });
    }

  }

  handleKey(e, i2) {

    // if(e.keyCode === 13){
    //   e.preventDefault(); // Ensure it is only this code that runs

    //   if (this.state.menu === 'availability') {
    //     this.saveStandardAvailability(i2);
    //   } else if (this.state.menu === 'settings') {
    //     this.saveExtraInfo();
    //   }

    //   return false;
    // }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {

    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  getUserPlannerEnd(id, date = this.state.hoursDateString, scheduleWeek = this.state.schedule) {

    let end = null;

    for(let key in scheduleWeek[date]) {

      const schedule = scheduleWeek[date][key];

      if(schedule.info_id === id) {

        end = {
          time: schedule.rooster_eind,
          date: schedule.rooster_datum,
        }

        break;
        // if(breakTime !== '00:00') {

        //   const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
        //   const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
        //   const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
        //   const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

        //   const totalBreakHours = oldBreakHours + breakHours;
        //   const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

        //   const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
        //   newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

        //   const newBreakHours = newBreakDateAndTime.getUTCHours();
        //   const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

        //   breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        // } else {

        //   breakTime = schedule.rooster_pauze.substring(0, 5);

        // }

      }

    }

    return end;

  }

  getUserPlannerStart(id, date = this.state.hoursDateString, scheduleWeek = this.state.schedule) {

    let start = null;

    for(let key in scheduleWeek[date]) {

      const schedule = scheduleWeek[date][key];

      if(schedule.info_id === id) {

        start = {
          time: schedule.rooster_begin,
          date: schedule.rooster_datum,
        }

        break;

        // if(breakTime !== '00:00') {

        //   const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
        //   const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
        //   const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
        //   const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

        //   const totalBreakHours = oldBreakHours + breakHours;
        //   const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

        //   const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
        //   newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

        //   const newBreakHours = newBreakDateAndTime.getUTCHours();
        //   const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

        //   breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        // } else {

        //   breakTime = schedule.rooster_pauze.substring(0, 5);

        // }

      }

    }

    return start;

  }

  getUserPlannerBreak(id, date = this.state.hoursDateString, scheduleWeek = this.state.schedule) {

    let breakTime = '00:00';

    for(let key in scheduleWeek[date]) {

      const schedule = scheduleWeek[date][key];

      if(schedule.info_id === id) {

        if(breakTime !== '00:00') {

          const oldBreakHours = Constants.getHoursTZ(schedule.rooster_datum, breakTime);
          const oldBreakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, breakTime);
          const breakHours = Constants.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
          const breakMinutes = Constants.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

          const totalBreakHours = oldBreakHours + breakHours;
          const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

          const newBreakDateAndTime = Constants.getDateTZ(schedule.rooster_datum, breakTime);
          newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

          const newBreakHours = newBreakDateAndTime.getUTCHours();
          const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

          breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

        } else {

          breakTime = schedule.rooster_pauze.substring(0, 5);

        }

      }

    }

    return breakTime;

  }

  selectMenu(menu) {

    this.setState({ menu: menu });

  }

  selectDateChangeHours(date, dateKey, key, key2, hours) {

    let times = this.state[hours];

    times[dateKey][key][key2] = Constants.dateToString(date);

    this.setState({ [hours]: times, changes: true });

  }

  selectHoursDateMenu(date) {

    this.setState({ 
      hoursDate: date,
      hoursDateString: Constants.dateToString(date),
      accepted: this.hoursAccepted(this.state.hours, this.state.extraHours, Constants.dateToString(date)),
    });

  }

  async selectHoursDate(date) {

    // if (Constants.getWeekNumber2(this.state.hoursDate) === Constants.getWeekNumber2(date) && this.state.hoursDate.getFullYear() === date.getFullYear()) {
    //   this.selectHoursDateMenu(date);
    //   return;
    // }

    if (this.state.changes === true) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      } else {
        this.setState({ changes: false });
      }
    }

    this.setState({reloading: true});

    await this.getWeekData(date);

    this.setState({reloading: false});

  }

  async selectReportStartDate(date) {

    this.setState({ reportStart: date });

  }

  async selectReportEndDate(date) {

    this.setState({ reportEnd: date });

  }

  // calcOverhoursClockN(hours, contract, _new = false) {

  //   let overhours = null;
  //   let overhoursContract = 0;
  //   let negative = false;
  //   let contract_hours = null;
  //   let contract_max_hours = null;
  //   let foundUserHoursWeek = false;

  //   if (contract !== null) {

  //     // get total worktime this week
  //     let totalHoursWeek = 0;

  //     // normal bars
  //     if (_new === false) {

  //       // normals
  //       for (const dateKey in this.state.schedule) {
  //         for (const sch of this.state.schedule[dateKey]) {
  //           if (sch.info_id === hours.info_id) {
  //             const startHours2 = Constants.stringToDateTime(sch.rooster_datum, sch.rooster_begin);
  //             const endHours2 = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);
  //             const breakHours2 = Constants.timeStringToMS(sch.rooster_pauze);
  //             foundUserHoursWeek = true;
  //             totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
  //           }
  //         }
  //       }

  //       // extra
  //       for (const dateKey in this.state.extraHours) {
  //         for (const key2 in this.state.extraHours[dateKey]) {
  //           const hours2 = this.state.extraHours[dateKey][key2];
  //           if (Constants.isEmpty(hours2.info_id) === false) { // not new
  //             if (hours2.info_id === hours.info_id) {
  //               const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
  //               const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
  //               const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
  //               foundUserHoursWeek = true;
  //               totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
  //             }
  //           } else { // new
  //             if (hours2.id === hours.info_id) {
  //               const startHours2 = Constants.stringToDateTime(hours2.startDate, hours2.start);
  //               const endHours2 = Constants.stringToDateTime(hours2.endDate, hours2.end);
  //               const breakHours2 = Constants.timeStringToMS(hours2.break);
  //               foundUserHoursWeek = true;
  //               totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
  //             }
  //           }
  //         }
  //       }

  //     // extra new bars
  //     } else {

  //       // normals
  //       for (const dateKey in this.state.schedule) {
  //         for (const sch of this.state.schedule[dateKey]) {
  //           if (sch.info_id === hours.id) {
  //             const startHours2 = Constants.stringToDateTime(sch.rooster_datum, sch.rooster_begin);
  //             const endHours2 = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);
  //             const breakHours2 = Constants.timeStringToMS(sch.rooster_pauze);
  //             foundUserHoursWeek = true;
  //             totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
  //           }
  //         }
  //       }

  //       // extra
  //       for (const dateKey in this.state.extraHours) {
  //         for (const key2 in this.state.extraHours[dateKey]) {
  //           const hours2 = this.state.extraHours[dateKey][key2];
  //           if (Constants.isEmpty(hours2.info_id) === false) { // not new
  //             if (hours2.info_id === hours.id) {
  //               const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
  //               const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
  //               const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
  //               foundUserHoursWeek = true;
  //               totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
  //             }
  //           } else { // new
  //             if (hours2.id === hours.id) {
  //               const startHours2 = Constants.stringToDateTime(hours2.startDate, hours2.start);
  //               const endHours2 = Constants.stringToDateTime(hours2.endDate, hours2.end);
  //               const breakHours2 = Constants.timeStringToMS(hours2.break);
  //               foundUserHoursWeek = true;
  //               totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
  //             }
  //           }
  //         }
  //       }

  //     }

  //     if (contract.contract_type === '1') {

  //       contract_hours = parseFloat(contract.contract_uren);
  //       contract_max_hours = parseFloat(contract.contract_max_uren);

  //       if (totalHoursWeek < contract_hours) {
  //         if (overhours === null) {
  //           overhours = 0;
  //         }
  //         overhoursContract += (totalHoursWeek - contract_hours);
  //       } else if (totalHoursWeek > contract_max_hours) {
  //         if (overhours === null) {
  //           overhours = 0;
  //         }
  //         overhoursContract += (totalHoursWeek - contract_max_hours);
  //       }

  //     } else if (contract.contract_type === '2') {

  //       contract_hours = parseFloat(contract.contract_uren);

  //       if (overhours === null) {
  //         overhours = 0;
  //       }
  //       overhoursContract += (totalHoursWeek - contract_hours);

  //     }

  //     // get all overhours
  //     const allOverhours = this.state.overhoursObj[_new ? hours.id : hours.info_id];
  //     if (typeof allOverhours !== 'undefined') {
  //       if (overhours === null) {
  //         overhours = 0;
  //       }
  //       for (const overhour of allOverhours) {
  //         overhours += parseFloat(overhour.ov_aantal);
  //       }
  //     }

  //     if (overhours !== null) {
  //       overhours = overhours + overhoursContract;
  //       negative = overhours < 0;
  //       overhours = Math.abs(overhours);
  //       overhours = Constants.msToTime(overhours * 60 * 60 * 1000);
  //     }

  //   }

  //   return {
  //     overhours: overhours,
  //     negative: negative,
  //     //overhoursContract: overhoursContract,
  //   };

  // }

  getWorkDays() {

    let days = 0;

    if (this.state.workWeekMonday === true) {
      days++;
    }
    if (this.state.workWeekTuesday === true) {
      days++;
    }
    if (this.state.workWeekWednesday === true) {
      days++;
    }
    if (this.state.workWeekThursday === true) {
      days++;
    }
    if (this.state.workWeekFriday === true) {
      days++;
    }
    if (this.state.workWeekSaturday === true) {
      days++;
    }
    if (this.state.workWeekSunday === true) {
      days++;
    }

    return days;

  }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (typeof this.state.infoExtra[id] === 'undefined') {
      return 0;
    }

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  calcOverhoursN(hours, contract, _new = false) {

    _new = Constants.isEmpty(hours.info_id);

    let _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 1000 * 60 * 60) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);
    let _workDayHoursFloat = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : parseFloat(Data.data.appOptions.opties_dag_werkuren);

    let overhours = null;
    let overhoursCurrent = null;
    let overhoursCorrection = null;
    let overhoursContract = 0;
    let negative = false;
    let negativeCurrent = false;
    let contract_hours = null;
    let contract_max_hours = null;
    let foundUserHoursWeek = false;

    let realOverhoursCurrent = null;
    let realNegativeCurrent = null;

    if (Constants.isTrue(Data.data.appOptions.opties_overuren) === false) {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,
        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,
        overhoursCorrection: overhoursCorrection,
        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,
        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

    if (contract !== null) {

      // SET NEW WORKDAYHOURS LIKE CONTRACT
      _workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? (parseFloat(contract.contract_werkdag_uren) * 1000 * 60 * 60) : _workDayHours;
      _workDayHoursFloat = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? parseFloat(contract.contract_werkdag_uren) : _workDayHoursFloat;

      // CALC CURRENT OVERHOURS (THIS DAY)
      let start = null;
      let end = null;
      let diff = null;

      if (Constants.isEmpty(hours.info_id) === false) {
        start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
        end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
        diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);
      } else {
        start = Constants.stringToDateTime(hours.startDate, hours.start);
        end = Constants.stringToDateTime(hours.endDate, hours.end);
        diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.break);
      }

      if (start !== null && end !== null && diff !== null) {

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        if (Constants.isTrue(contract.contract_vaste_dagen)) {

          _workDayHours = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

          _workDayHoursFloat = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);

        }

        overhoursCurrent = (diff - _workDayHours) < 0 ? Constants.msToTime2(_workDayHours - diff) : Constants.msToTime2(diff - _workDayHours);
        negativeCurrent = (diff - _workDayHours) < 0;

      }

      if (isNaN(Constants.timeStringToFloat(overhoursCurrent)) === true || Constants.timeStringToFloat(overhoursCurrent) === 0) {
        overhoursCurrent = null;
      }

      // get current chosen week
      const chosenWeek = Constants.getWeekNumber2(this.state.hoursDate);

      // get total worktime this week
      let totalHoursWeek = 0;

      // found hours objects this week
      let foundDBHoursArray = [];
      let foundExtraHoursArray = [];

      // normal bars
      if (_new === false) {

        // normals
        for (const dateKey in this.state.hours) {
          for (const key2 in this.state.hours[dateKey]) {
            const hours2 = this.state.hours[dateKey][key2];
            if (hours2.info_id === hours.info_id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === this.state.hoursDateString)) {
              const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
              const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
              const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
              if (startHours2 < this.state.hoursDate || hours2.uren_begindatum === this.state.hoursDateString) { // <
                foundUserHoursWeek = true;
                foundDBHoursArray.push(hours2);
                totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
              }
            }
          }
        }

        // extra
        for (const dateKey in this.state.extraHours) {
          for (const key2 in this.state.extraHours[dateKey]) {
            const hours2 = this.state.extraHours[dateKey][key2];
            if (Constants.isEmpty(hours2.info_id) === false) { // not new
              if (hours2.info_id === hours.info_id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === this.state.hoursDateString)) {
                const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
                const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
                const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
                if (startHours2 < this.state.hoursDate || hours2.uren_begindatum === this.state.hoursDateString) { // <
                  foundUserHoursWeek = true;
                  foundDBHoursArray.push(hours2);
                  totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
                }
              }
            } else { // new
              if (hours2.id === hours.info_id && hours2.startDate === this.state.hoursDateString) {
                const startHours2 = Constants.stringToDateTime(hours2.startDate, hours2.start);
                const endHours2 = Constants.stringToDateTime(hours2.endDate, hours2.end);
                const breakHours2 = Constants.timeStringToMS(hours2.break);
                if (startHours2 < this.state.hoursDate || hours2.startDate === this.state.hoursDateString) { // <
                  foundUserHoursWeek = true;
                  foundExtraHoursArray.push(hours2);
                  totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
                }
              }
            }
          }
        }

      // extra new bars
      } else {

        // normals
        for (const dateKey in this.state.hours) {
          for (const key2 in this.state.hours[dateKey]) {
            const hours2 = this.state.hours[dateKey][key2];
            if (hours2.info_id === hours.id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === this.state.hoursDateString)) {
              const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
              const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
              const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
              if (startHours2 < this.state.hoursDate || hours2.uren_begindatum === this.state.hoursDateString) {
                foundUserHoursWeek = true;
                foundDBHoursArray.push(hours2);
                totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
              }
            }
          }
        }

        // extra
        for (const dateKey in this.state.extraHours) {
          for (const key2 in this.state.extraHours[dateKey]) {
            const hours2 = this.state.extraHours[dateKey][key2];
            if (Constants.isEmpty(hours2.info_id) === false) { // not new
              if (hours2.info_id === hours.id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === this.state.hoursDateString)) {
                const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
                const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
                const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
                if (startHours2 < this.state.hoursDate || hours2.uren_begindatum === this.state.hoursDateString) {
                  foundUserHoursWeek = true;
                  foundDBHoursArray.push(hours2);
                  totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
                }
              }
            } else { // new
              if (hours2.id === hours.id && hours2.startDate === this.state.hoursDateString) {
                const startHours2 = Constants.stringToDateTime(hours2.startDate, hours2.start);
                const endHours2 = Constants.stringToDateTime(hours2.endDate, hours2.end);
                const breakHours2 = Constants.timeStringToMS(hours2.break);
                if (startHours2 < this.state.hoursDate || hours2.startDate === this.state.hoursDateString) {
                  foundUserHoursWeek = true;
                  foundExtraHoursArray.push(hours2);
                  totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2) / 1000 / 60 / 60);
                }
              }
            }
          }
        }

      }

      // SICK HOURS
      if (Constants.isTrue(Data.data.appOptions.clock)) {

        let foundSchedulesAbsence = [];

        for (const schKey in this.state.schedule) {
          for (const schKey2 in this.state.schedule[schKey]) {

            const sch = this.state.schedule[schKey][schKey2];

            if (sch.rooster_info_id === (_new ? hours.id : hours.info_id)) {

              const schDate = Constants.stringToDate(sch.rooster_datum);

              // if (schDate > this.state.hoursDate && Constants.objectArrayContainsKey(foundDBHoursArray, 'uren_begindatum', sch.rooster_datum) === false && Constants.objectArrayContainsKey(foundExtraHoursArray, 'startDate', sch.rooster_datum) === false) {
              //   foundSchedulesAbsence.push(sch);
              // } else 
              if (typeof this.state.absenceSchedules[sch.rooster_id] !== 'undefined' && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
                foundSchedulesAbsence.push(sch);
              }

            }

          }
        }

        // let sickHours = 0;

        // for (const sch of foundSchedulesAbsence) {
        //   // const start = Constants.stringToDateTime(sch.rooster_datum, sch.rooster_begin.substr(0, 5));
        //   // const end = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind.substr(0, 5));
        //   // const _schBreak = Constants.timeStringToMS(sch.rooster_pauze.substr(0, 5));
        //   // sickHours += Constants.round(((end - start) - _schBreak) / 1000 / 60 / 60);
        // }

        totalHoursWeek += foundSchedulesAbsence.length > 0 ? (_workDayHoursFloat * foundSchedulesAbsence.length) : 0;

        // if (hours.info_id === '18') {
        //   console.log('sick: ' + foundSchedulesAbsence.length > 0 ? (_workDayHoursFloat * foundSchedulesAbsence.length) : 0)
        // }

      }

      // ABSAMOUNT
      if (Constants.isEmpty(this.state.absAmount[_new ? hours.id : hours.info_id]) === false) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

        let fullWeekHours = this.getWorkDaysPersonWeek(_new ? hours.id : hours.info_id) * workDayHours;

        for (const abs of this.state.absAmount[_new ? hours.id : hours.info_id]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          if (sameDay) {
            totalHoursWeek += parseFloat(abs.vs_aantal);
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
            
              if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(_new ? hours.id : hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[_new ? hours.id : hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              } else if (contract !== null && Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) === true && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(_new ? hours.id : hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[_new ? hours.id : hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (((currentDate.getDay() === 5 && this.state.workWeekFriday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_vrijdag === '1') || (currentDate.getDay() === 4 && this.state.workWeekThursday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_donderdag === '1') || (currentDate.getDay() === 3 && this.state.workWeekWednesday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_woensdag === '1') || (currentDate.getDay() === 2 && this.state.workWeekTuesday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_dinsdag === '1') || (currentDate.getDay() === 1 && this.state.workWeekMonday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_maandag === '1') || (currentDate.getDay() === 6 && this.state.workWeekSaturday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_zaterdag === '1') || (currentDate.getDay() === 0 && this.state.workWeekSunday === true && infoExtra[_new ? hours.id : hours.info_id].info2_werkdag_zondag === '1')) || this.getWorkDays() === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              }

              index++;

            }

            if (Object.keys(absWeek).length > 0) {
              for (let key in absWeek) {
                if (chosenWeek === parseInt(key)) {
                  totalHoursWeek += absWeek[key];
                }
              }
            }

          }

        }

      }

      realOverhoursCurrent = overhoursCurrent;
      realNegativeCurrent = negativeCurrent;

      // calc
      if (Constants.isTrue(contract.contract_vaste_dagen) === false) {
        
        if (contract.contract_type === '1') {

          contract_max_hours = parseFloat(contract.contract_max_uren);

          if ((totalHoursWeek) > contract_max_hours) {

            const totalOverhoursweek = (totalHoursWeek - contract_max_hours) * 1000 * 60 * 60;
            const _totalHours = (totalHoursWeek * 1000 * 60 * 60) + diff;

            const _totalMinusTotalOverhours = _totalHours - (contract_max_hours * 1000 * 60 * 60) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Constants.msToTime2(Math.abs(overhoursCurrent));

          }

        } else if (contract.contract_type === '2') {

          contract_hours = parseFloat(contract.contract_uren);

          // esmee 12
          // regien 9
          // frank 18
          // damian 60

          // console.log(hours.info_voornaam, hours.info_id)

          // if (hours.info_id === '60' || hours.id === '60') {
          //   console.log('contract: ' + contract_hours)
          //   console.log('totalhours: ' + totalHoursWeek)
          // }

          if ((totalHoursWeek) >= contract_hours) {

            let overhoursWeek = 0;
            if (typeof this.state.overhoursObj[_new ? hours.id : hours.info_id] !== 'undefined') {
              for (const o of this.state.overhoursObj[_new ? hours.id : hours.info_id]) {
                if (o.ov_datum !== this.state.hoursDateString && Constants.stringToDate(o.ov_datum) < this.state.hoursDate) {
                  overhoursWeek += parseFloat(o.ov_aantal);
                }
              }
            }

            const _overhoursDay = overhoursCurrent;
            // if (hours.info_id === '60' || hours.id === '60') {
            //   console.log(_overhoursDay);
            // }

            const totalOverhoursweek = (overhoursWeek * 1000 * 60 * 60); // maybe the overhours which are saved?? // (totalHoursWeek - contract_hours) * 1000 * 60 * 60
            const _totalHours = (totalHoursWeek * 1000 * 60 * 60); // + diff

            const _totalMinusTotalOverhours = _totalHours - (contract_hours * 1000 * 60 * 60) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            // totalhours + diff is real total hours. minus contracthours = total overhours. the difference between these overhours and saved overhours this week is the correction. OR above is going wrong (total overhours)

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Constants.msToTime2(Math.abs(overhoursCurrent));

          }

        }

      }

      // if (hours.info_id === '2' || hours.id === '2') {

        // let overhoursWeek = 0;
        // if (typeof this.state.overhoursObj[_new ? hours.id : hours.info_id] !== 'undefined') {
        //   for (const o of this.state.overhoursObj[_new ? hours.id : hours.info_id]) {
        //     overhoursWeek += parseFloat(o.ov_aantal);
        //   }
        // }

      //   contract_hours = parseFloat(contract.contract_uren);
      //   const diffFloat = Constants.round(diff / 1000 / 60 / 60);
      //   const _workDayHoursFloat = Constants.round(_workDayHours / 1000 / 60 / 60);

      //   if ((totalHoursWeek) > contract_hours) {

      //     const totalOverhoursweek = (totalHoursWeek - contract_hours) * 1000 * 60 * 60;
      //     const _totalHours = (totalHoursWeek * 1000 * 60 * 60) + diff;

      //     //const diffWorkDay = _workDayHours - diff;

      //     const _totalMinusTotalOverhours = _totalHours - (contract_hours * 1000 * 60 * 60) - totalOverhoursweek;

      //     overhoursCurrent = (_totalMinusTotalOverhours);

      //     // const totalOverhoursweek = (totalHoursWeek - contract_hours) * 1000 * 60 * 60;
      //     // const _totalHours = (totalHoursWeek * 1000 * 60 * 60) + diff;

      //     // const _totalMinusTotalOverhours = _totalHours - totalOverhoursweek;
      //     // const _totalPlusCurrentDay = _totalMinusTotalOverhours + diff;
      //     // const _totalMinusWorkday = _totalPlusCurrentDay - _workDayHours;

      //     // overhoursCurrent = (_totalMinusWorkday - (contract_hours * 1000 * 60 * 60));

      //     negativeCurrent = overhoursCurrent < 0;
      //     overhoursCurrent = Constants.msToTime2(Math.abs(overhoursCurrent));






      //     // const overhoursDay = (diff - _workDayHours);

      //     // const toSubstract = Math.abs(overhoursWeek) * 1000 * 60 * 60;
      //     // const substractFrom = (totalHoursWeek - contract_hours) * 1000 * 60 * 60;

      //     // console.log('------>-------')
      //     // console.log('overhoursDay: ' + overhoursDay / 1000 / 60 / 60)
      //     // console.log('toSubstract: ' + toSubstract / 1000 / 60 / 60)
      //     // console.log('substractFrom: ' + substractFrom / 1000 / 60 / 60)
      //     // console.log('total: ' + overhoursWeek > 0 ? Constants.msToTime2((substractFrom - toSubstract)) : Constants.msToTime2((substractFrom + toSubstract)))
      //     // console.log('------>-------')

      //     // if (overhoursWeek > 0) {
      //     //   overhoursCurrent = (substractFrom - toSubstract);
      //     // } else {
      //     //   overhoursCurrent = (substractFrom + toSubstract);
      //     // }
      //     // if (overhoursDay > 0) {
      //     //   overhoursCurrent += Math.abs(overhoursDay);
      //     // } else {
      //     //   overhoursCurrent -= Math.abs(overhoursDay);
      //     // }

      //     // negativeCurrent = overhoursCurrent < 0;
      //     // overhoursCurrent = Math.abs(overhoursCurrent);

      //     // overhoursCurrent = Constants.msToTime2(overhoursCurrent);

      //   }

        // // if (contract_hours === totalHoursWeek && overhoursWeek !== 0) {
        // //   const toSubstract = (overhoursWeek > 0 ? -Math.abs(overhoursWeek) : Math.abs(overhoursWeek));
        // //   overhoursCorrection += toSubstract;
        // // }

      // }



    }

    //   if (contract.contract_type === '1') {

    //     contract_hours = parseFloat(contract.contract_uren);
    //     contract_max_hours = parseFloat(contract.contract_max_uren);

    //     if (totalHoursWeek < contract_hours) {
    //       if (overhours === null) {
    //         overhours = 0;
    //       }
    //       overhoursContract += (totalHoursWeek - contract_hours);
    //     } else if (totalHoursWeek > contract_max_hours) {
    //       if (overhours === null) {
    //         overhours = 0;
    //       }
    //       overhoursContract += (totalHoursWeek - contract_max_hours);
    //     }

    //   } else if (contract.contract_type === '2') {

    //     contract_hours = parseFloat(contract.contract_uren);

    //     if (overhours === null) {
    //       overhours = 0;
    //     }
    //     overhoursContract += (totalHoursWeek - contract_hours);

    //   }

    //   // get all overhours
    //   const allOverhours = this.state.overhoursObj[_new ? hours.id : hours.info_id];
    //   if (typeof allOverhours !== 'undefined') {
    //     if (overhours === null) {
    //       overhours = 0;
    //     }
    //     for (const overhour of allOverhours) {
    //       // overhours = this week? remove it
    //       if (chosenWeek !== Constants.getWeekNumber2(Constants.stringToDate(overhour.ov_datum))) {
    //         overhours += parseFloat(overhour.ov_aantal);
    //       }
    //     }
    //   }

    //   // 
    //   // 
    //   // 

    //   // CALC OVERHOURS
    //   if (overhours !== null) {
    //     const correction = typeof this.state.overhoursCorrections[_new ? hours.id : hours.info_id] !== 'undefined' ? parseFloat(this.state.overhoursCorrections[_new ? hours.id : hours.info_id].co_totaal) : 0;
    //     //const abs = Constants.isEmpty(absDeduct) === false ? absDeduct : 0;
    //     overhours = overhoursContract + overhours + correction;
    //     negative = overhours < 0;
    //     overhours = Math.abs(overhours);
    //     overhours = Constants.msToTime2(overhours * 60 * 60 * 1000);
    //   }

    // }

    if (Constants.isEmpty(contract) === true || Constants.isEmpty(contract.contract_type) === true || contract.contract_type === '0') {

      return {
        overhoursTotal: '00:00',
        isNegativeTotal: false,

        overhoursCurrent: null,
        isNegativeCurrent: false,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,
        // overhoursContract: 0,
        // overhours this week
      };

    } else {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,

        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,
        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

  }

  getHoursBars() {

    let toReturn = [];

    const hoursDay = this.state.hours[this.state.hoursDateString];

    if (typeof hoursDay !== 'undefined') {

      let hoursArray = [];
      for (const key in hoursDay) {
        hoursArray.push(hoursDay[key]);
      }

      hoursArray.sort((a, b) => (a.rooster_datum > b.rooster_datum || (a.rooster_datum === b.rooster_datum && a.rooster_begin > b.rooster_begin)) ? 1 : -1);

      for (const hours of hoursArray) { 

      //for (const key in hoursDay) {

        //const hours = hoursDay[key];

        // check absences
        let absence = null;
        const absenceSchedule = this.state.absenceSchedules[hours.rooster_id];
        if (typeof absenceSchedule !== 'undefined') {
          absence = absenceSchedule;
        }

        const userFullName = `${hours.info_voornaam} ${(hours.info_tussenvoegsel !== null && hours.info_tussenvoegsel !== '') ? hours.info_tussenvoegsel + " " + hours.info_achternaam : hours.info_achternaam}`;

        let _break = hours.rooster_pauze;
        let _breakTime = Constants.timeStringToMS(hours.rooster_pauze);
        if (Data.data.appOptions.brk === 'CALC') {
          _break = this.getBreakRule(hours.rooster_datum, hours.rooster_begin, hours.rooster_datum, hours.rooster_eind, hours).str.substr(0, 5);
          _breakTime = this.getBreakRule(hours.rooster_datum, hours.rooster_begin, hours.rooster_datum, hours.rooster_eind, hours).ms;
        }

        let alertMisleadingEnd = false;
        if (hours.uren_begindatum !== hours.uren_einddatum) {
          const _planEnd = Plan.isNightShift2(hours);
          if (hours.uren_einddatum !== _planEnd) {
            alertMisleadingEnd = true;
          }
        }

        // const startPlan = Constants.stringToDateTime(hours.rooster_datum, hours.rooster_begin);
        // const endPlan = Constants.stringToDateTime(Plan.isNightShift2(hours), hours.rooster_eind);
        // const diffPlan = endPlan.getTime() - startPlan.getTime() - _breakTime;

        // const _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);

        // const start = Constants.stringToDateTime(hours.rooster_datum, hours.uren_begin);
        // const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
        // const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

        // let overhours_current = (diff - _workDayHours) < 0 ? Constants.msToTime2(_workDayHours - diff) : Constants.msToTime2(diff - _workDayHours);
        // const negative_current = (diff - _workDayHours) < 0;

        // if (isNaN(Constants.timeStringToFloat(overhours_current)) === true || Constants.timeStringToFloat(overhours_current) === 0) {
        //   overhours_current = null;
        // }

        let contract = Constants.getCurrentContract(this.state.contracts[hours.info_id], this.state.hoursDate);
        contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

        let overhoursN = this.calcOverhoursN(hours, contract);
        let overhours = overhoursN.overhoursTotal;
        let negative = overhoursN.isNegativeTotal;
        let overhours_current = overhoursN.realOverhoursCurrent;
        let negative_current = overhoursN.realNegativeCurrent;

        if (isNaN(Constants.timeStringToFloat(overhours)) === true || overhoursN.overhours === '00:00') {
          overhours = null;
        }

        toReturn.push(
          <div className='hr_timeRow'>

            <div className='hr_timeRow_sub1_column'>
              <div>
                {userFullName}
                {absence !== null ?
                  <span style={{color: Colors.color.redFilledIn, marginLeft: '2%'}}>{absence.afw_afkorting}</span>
                : null}
              </div>
            </div>

            <div className='hr_timeRow_sub2'>
              {hours.rooster_begin.substr(0, 5)}
            </div>
            <div className='hr_timeRow_sub2'>
              {hours.rooster_eind.substr(0, 5)}
            </div>
            <div className='hr_timeRow_sub2'>
              {_break.substr(0, 5)}
            </div>

            <div className='hr_timeRow_sub2'>
              <ReactDatePicker
                selected={Constants.stringToDate(hours.rooster_datum)}
                //selected={Constants.stringToDate(hours.uren_begindatum)}
                //onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, hours.rooster_id, 'uren_begindatum', 'hours')}
                dateFormat="dd-MM-yyyy"
                className={'hr_inputBoxTime_date' + (` disabledInput`)}
                placeholderText={__('misc_startDate')}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale={nl}
                disabled
              />
            </div>
            <div className='hr_timeRow_sub2'>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime'}
                onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, hours.rooster_id, 'uren_begin', 'hours', true)}
                onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                // value={absence !== null ? hours.rooster_begin.substr(0, 5) : hours.uren_begin.substr(0, 5)}
                value={hours.uren_begin.substr(0, 5)}
                placeholder={__('misc_startTime')}
                maxLength={5}
                //disabled={absence !== null}
              />
            </div>

            <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
              &nbsp;
            </div>

            <div className='hr_timeRow_sub2'>
              <ReactDatePicker
                // selected={absence !== null ? Constants.stringToDate(hours.rooster_datum) : Constants.stringToDate(hours.uren_einddatum)}
                selected={Constants.stringToDate(hours.uren_einddatum)}
                onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, hours.rooster_id, 'uren_einddatum', 'hours')}
                dateFormat="dd-MM-yyyy"
                className={alertMisleadingEnd ? 'hr_inputBoxTime_date_alert' : 'hr_inputBoxTime_date'}
                placeholderText={__('misc_endDate')}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale={nl}
                //disabled={absence !== null}
              />
            </div>
            <div className='hr_timeRow_sub2'>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime'}
                onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, hours.rooster_id, 'uren_eind', 'hours', true)}
                onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                // value={absence !== null ? hours.rooster_eind.substr(0, 5) : hours.uren_eind.substr(0, 5)}
                value={hours.uren_eind.substr(0, 5)}
                placeholder={__('misc_endTime')}
                maxLength={5}
                //disabled={absence !== null}
              />
            </div>

            <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
              &nbsp;
            </div>

            <div className='hr_timeRow_sub2'>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime'}
                onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, hours.rooster_id, 'uren_pauze', 'hours', true)}
                onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                //value={absence !== null ? hours.rooster_pauze.substr(0, 5) : hours.uren_pauze.substr(0, 5)}
                value={hours.uren_pauze.substr(0, 5)}
                placeholder={__('misc_break')}
                maxLength={5}
                //disabled={absence !== null}
              />
            </div>

            <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.5, fontSize: '0.6vw'}}>
              {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours_current !== null ?
                <span style={{color: negative_current === true ? Colors.color.greenFilledIn : overhours_current === '00:00' ? null : Colors.color.redFilledIn, backgroundColor: contract !== null ? negative_current === true ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1) : overhours_current === '00:00' ? null : Constants.hexToRgbA(Colors.color.redFilledIn, 0.1) : null}}>
                  {negative_current === true ? '-' : '+'}{overhours_current}
                </span>
              :
                <span>&nbsp;</span>
              }
            </div>

            <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.2}}>
              {hours.uren_goedgekeurd === '1' ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('misc_approved')}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='far fa-check' style={{color: Colors.color.greenFilledIn}} />
                </Tooltip>
              :
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('misc_notApproved')}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='far fa-times' style={{color: Colors.color.redFilledIn}} />
                </Tooltip>
              }
            </div>

            <div className='hr_timeRow_sub3' style={{cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] ? this.closePlanMenu(hours.rooster_id) : this.openPlanMenu(hours.rooster_id)}>
              <i className='fas fa-caret-down planMenuAsset' />
            </div>

            {this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] === true ?
              this.renderPlanMenu(hours, absence)
            : null}

          </div>
        );

      }

    }

    return toReturn;

  }

  getScheduleClockBars() {

    let toReturn = [];

    const hoursDay = this.state.schedule[this.state.hoursDateString];

    if (typeof hoursDay !== 'undefined') {

      for (const key in hoursDay) {

        const hours = hoursDay[key];

        // check absences
        let absence = null;
        const absenceSchedule = this.state.absenceSchedules[hours.rooster_id];
        if (typeof absenceSchedule !== 'undefined') {
          absence = absenceSchedule;
        }

        let hasClocked = false;
        for (const key in this.state.hours[this.state.hoursDateString]) {
          const clock = this.state.hours[this.state.hoursDateString][key];
          if (clock.info_id === hours.info_id) {
            hasClocked = true;
            break;
          }
        }
        if (hasClocked === false) {
          for (const key in this.state.extraHours[this.state.hoursDateString]) {
            const time = this.state.extraHours[this.state.hoursDateString][key];
            if (time.uren_info_id === hours.info_id || time.id === hours.info_id) {
              hasClocked = true;
              break;
            }
          }
        }

        if (hasClocked === true) {
          continue;
        }

        let _break = hours.rooster_pauze.substr(0, 5);
        // let _breakTime = Constants.timeStringToFloat(hours.rooster_pauze) * 1000 * 60 * 60;
        if (Data.data.appOptions.brk === 'CALC') {
          _break = this.getBreakRule(hours.rooster_datum, hours.rooster_begin, hours.rooster_datum, hours.rooster_eind, hours).str.substr(0, 5);
          // _breakTime = this.getBreakRule(hours.rooster_datum, hours.rooster_begin, hours.rooster_datum, hours.rooster_eind, hours).ms;
        }

        // let contract = Constants.getCurrentContract(this.state.contracts[hours.info_id], this.state.hoursDate);
        // contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

        // let overhoursN = this.calcOverhoursN(hours, contract);
        // let overhours = overhoursN.overhoursTotal;
        // let negative = overhoursN.isNegativeTotal;
        // let overhours_current = overhoursN.overhoursCurrent;
        // let negative_current = overhoursN.isNegativeCurrent;

        // if (isNaN(Constants.timeStringToFloat(overhours)) === true || overhoursN.overhours === '00:00') {
        //   overhours = null;
        // }

        const userFullName = `${hours.info_voornaam} ${(hours.info_tussenvoegsel !== null && hours.info_tussenvoegsel !== '') ? hours.info_tussenvoegsel + " " + hours.info_achternaam : hours.info_achternaam}`;

        toReturn.push(
          <div className='hr_timeRow'>

            <div className='hr_timeRow_sub1_column'>

              <div className='hr_timeRow_sub1_row'>

                <span style={{ opacity: 0.5 }}>{userFullName}</span>
                {absence !== null ?
                  <span style={{color: Colors.color.redFilledIn, marginLeft: '2%'}}>{absence.afw_afkorting}</span>
                : null}

              </div>
        
              {/* {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours !== null ?
                <div>
                  <span style={{opacity: 0.5, color: negative === true ? Colors.color.greenFilledIn : overhours === '00:00' ? null : Colors.color.redFilledIn, fontSize: '0.5vw'}}>
                    {negative === true ? '-' : '+'}{overhours}
                  </span>
                </div>
              : null} */}

              {/* {absence === null ? */}
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <b>{__('misc_notClocked')}</b>
                    </div>
                  )}
                  style={{position: 'absolute', right: '5%'}}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='fas fa-stopwatch' />
                </Tooltip>
              {/* : null} */}

            </div>

            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              {hours.rooster_begin.substr(0, 5)}
            </div>
            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              {hours.rooster_eind.substr(0, 5)}
            </div>
            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              {_break}
            </div>

            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              <ReactDatePicker
                selected={Constants.stringToDate(hours.rooster_datum)}
                dateFormat="dd-MM-yyyy"
                className={'hr_inputBoxTime_date' + (` disabledInput`)}
                placeholderText={__('misc_startDate')}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale={nl}
                disabled={true}
              />
            </div>
            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime' + (` disabledInput`)}
                value={hours.rooster_begin.substr(0, 5)}
                placeholder={__('misc_startTime')}
                maxLength={5}
                disabled={true}
              />
            </div>

            <div className='hr_timeRow_sub2' style={{flex: 0.2, opacity: 0.5}}>
              &nbsp;
            </div>

            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              <ReactDatePicker
                selected={Constants.stringToDate(hours.rooster_datum)}
                dateFormat="dd-MM-yyyy"
                className={'hr_inputBoxTime_date' + (` disabledInput`)}
                placeholderText={__('misc_endDate')}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale={nl}
                disabled={true}
              />
            </div>
            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime' + (` disabledInput`)}
                value={hours.rooster_eind.substr(0, 5)}
                placeholder={__('misc_endTime')}
                maxLength={5}
                disabled={true}
              />
            </div>

            <div className='hr_timeRow_sub2' style={{flex: 0.2, opacity: 0.5}}>
              &nbsp;
            </div>

            <div className='hr_timeRow_sub2' style={{ opacity: 0.5 }}>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime' + (` disabledInput`)}
                value={hours.rooster_pauze.substr(0, 5)}
                placeholder={__('misc_break')}
                maxLength={5}
                disabled={true}
              />
            </div>

            <div className='hr_timeRow_sub3' style={{cursor: 'pointer', flex: 0.5}} onClick={() => this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] ? this.closePlanMenu(hours.rooster_id) : this.openPlanMenu(hours.rooster_id)}>
              <span>&nbsp;</span>
            </div>

            <div className='hr_timeRow_sub3' style={{cursor: 'pointer', flex: 0.2}} onClick={() => this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] ? this.closePlanMenu(hours.rooster_id) : this.openPlanMenu(hours.rooster_id)}>
              <span>&nbsp;</span>
            </div>

            <div className='hr_timeRow_sub3' style={{cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] ? this.closePlanMenu(hours.rooster_id) : this.openPlanMenu(hours.rooster_id)}>
              <i className='fas fa-caret-down planMenuAsset' />
            </div>

            {/* <div className='hr_timeRow_sub2'>
              {hasClocked === true ?
                <i className='far fa-check' style={{color: Colors.color.greenFilledIn}} />
              :
                <i className='far fa-times' style={{color: Colors.color.redFilledIn}} />
              }
            </div>

            <div className='hr_timeRow_sub3' style={{cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] ? this.closePlanMenu(hours.rooster_id) : this.openPlanMenu(hours.rooster_id)}>
              <i className='fas fa-caret-down planMenuAsset' />
            </div> */}

            {this.state.showPlanMenu[this.state.hoursDateString][hours.rooster_id] === true ?
              this.renderPlanMenu(hours, absence, hasClocked)
            : null}

          </div>
        );

      }

    }

    return toReturn;

  }

  getHoursBarsClock() {

    let toReturn = [];

    const hoursDay = this.state.hours[this.state.hoursDateString];

    if (typeof hoursDay !== 'undefined') {

      let hoursArray = [];
      for (const key in hoursDay) {
        hoursArray.push(hoursDay[key]);
      }

      hoursArray.sort((a, b) => (a.kloktijd_inklok_datum > b.kloktijd_inklok_datum || (a.kloktijd_inklok_datum === b.kloktijd_inklok_datum && a.kloktijd_inklok_tijd > b.kloktijd_inklok_tijd)) ? 1 : -1);

      for (const hours of hoursArray) { 

        //const hours = hoursDay[key];

        const userFullName = `${hours.info_voornaam} ${(hours.info_tussenvoegsel !== null && hours.info_tussenvoegsel !== '') ? hours.info_tussenvoegsel + " " + hours.info_achternaam : hours.info_achternaam}`;

        let _start = '00:00';//hours.kloktijd_inklok_tijd.substr(0, 8);
        let _end = '00:00';//hours.kloktijd_uitklok_tijd.substr(0, 8);

        let bijz = '';
        let bijzReason = '';
        let bijzType = 0;
        
        // const planStart = this.getUserPlannerStart(hours.info_id);
        // const planEnd = this.getUserPlannerEnd(hours.info_id);

        let _break = hours.kloktijd_pauze.substr(0, 5);
        if (Data.data.appOptions.brk === 'PLANNER') {

          _break = this.getUserPlannerBreak(hours.info_id);

        } else if (Data.data.appOptions.brk === 'CALC') {

          _break = this.getBreakRule(hours.kloktijd_inklok_datum, hours.kloktijd_inklok_tijd, hours.kloktijd_uitklok_datum, hours.kloktijd_uitklok_tijd).str.substr(0, 5);

        }

        // if (planStart !== null && planEnd !== null) {

        //   _start = planStart.time;
        //   _end = planEnd.time;

        //   const planStartDate = Constants.getDateTZ(planStart.date, planStart.time);
        //   const clockStartDate = Constants.getDateTZ(hours.kloktijd_inklok_datum, hours.kloktijd_inklok_tijd);

        //   if (clockStartDate < planStartDate) {
        //     bijz = 'Eerder begonnen';
        //   } else if (clockStartDate > planStartDate) {
        //     bijz = 'Te laat begonnen';
        //   }

        // } else {
        //   bijz = 'Staat niet op de planning';
        // }

        let totalMsPlan = 0;
        let breakMS = 0;
        let lowest = null;
        let highest = null;
        let planObject = null;

        if (typeof this.state.schedule[hours.kloktijd_inklok_datum] !== 'undefined') {

          for (const sch of this.state.schedule[hours.kloktijd_inklok_datum]) {

            if (sch.info_id === hours.info_id && sch.rooster_datum === hours.kloktijd_inklok_datum) {

              const startPlan = Constants.stringToDateTime(sch.rooster_datum, sch.rooster_begin);
              const endPlan = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);

              let _breakTime = Constants.timeStringToMS(sch.rooster_pauze);
              if (Data.data.appOptions.brk === 'CALC') {
                _breakTime = this.getBreakRule(sch.rooster_datum, sch.rooster_begin, sch.rooster_datum, sch.rooster_eind, sch).ms;
              }

              const diffPlan = endPlan.getTime() - startPlan.getTime() - _breakTime;

              breakMS += _breakTime;
              totalMsPlan += diffPlan;

              if (lowest === null || startPlan.getTime() < lowest) {
                lowest = startPlan;
                _start = sch.rooster_begin.substr(0, 5);
                planObject = sch;
              }

              if (highest === null || endPlan.getTime() > highest) {
                highest = endPlan;
                _end = sch.rooster_eind.substr(0, 5);
              }

            }

          }

          _break = Constants.msToTime(breakMS).substr(0, 5);

          const planStartDate = Constants.stringToDateTime(this.state.hoursDateString, _start);
          const clockStartDate = Constants.stringToDateTime(hours.kloktijd_inklok_datum, hours.kloktijd_inklok_tijd);

          if (clockStartDate < planStartDate) {
            bijz = __('misc_startedEarlier');
            bijzType = 1;
          } else if (clockStartDate > planStartDate) {
            bijz = __('misc_startedTooLate');
            bijzType = 2;
            if (Constants.isEmpty(hours.kloktijd_laat_reden) === false) {
              if (hours.kloktijd_laat_reden === '1') {
                bijzReason = __('misc_tooLate');
              } else if (hours.kloktijd_laat_reden === '2') {
                bijzReason = __('misc_forgotClockIn');
              }
            }
          }

        }

        if (_start === '00:00' && _end === '00:00') {
          bijz = __('misc_notOnPlanning');
          bijzType = 3;
        }

        // ABSENCE
        let absence = null;
        if (planObject !== null && Constants.isEmpty(planObject.rooster_id) === false) {
          const absenceSchedule = this.state.absenceSchedules[planObject.rooster_id];
          if (typeof absenceSchedule !== 'undefined') {
            absence = absenceSchedule;
          }
        }

        // const _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);

        // const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
        // const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
        // const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

        // let overhours_current = (diff - _workDayHours) < 0 ? Constants.msToTime2(_workDayHours - diff) : Constants.msToTime2(diff - _workDayHours);
        // const negative_current = (diff - _workDayHours) < 0;

        // if (isNaN(Constants.timeStringToFloat(overhours_current)) === true || Constants.timeStringToFloat(overhours_current) === 0) {
        //   overhours_current = null;
        // }

        let contract = Constants.getCurrentContract(this.state.contracts[hours.info_id], this.state.hoursDate);
        contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

        let overhoursN = this.calcOverhoursN(hours, contract);
        let overhours = overhoursN.overhoursTotal;
        let negative = overhoursN.isNegativeTotal;
        let overhours_current = overhoursN.realOverhoursCurrent;
        let negative_current = overhoursN.realNegativeCurrent;

        if (isNaN(Constants.timeStringToFloat(overhours)) === true || overhoursN.overhours === '00:00') {
          overhours = null;
        }

        let alertMisleadingEnd = false;
        if (hours.uren_begindatum !== hours.uren_einddatum) {
          const _planEnd = highest !== null ? Constants.dateToString(highest) : '0000-00-00';
          if (hours.uren_einddatum !== _planEnd) {
            alertMisleadingEnd = true;
          }
        }

        toReturn.push(
          <div className='hr_timeRow'>

            <div className='hr_timeRow_sub1_column'>
              <div>
                <span>{userFullName}</span>
                {absence !== null ?
                  <span style={{color: Colors.color.redFilledIn, marginLeft: '2%'}}>{absence.afw_afkorting}</span>
                : null}
              </div>

              {bijz !== '' ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <div><b>{bijz}</b></div>
                      {bijzReason !== '' ?
                        <div style={{marginTop: '0.5vw'}}>{__('misc_reason')}: {bijzReason}</div>
                      : null}
                    </div>
                  )}
                  style={{position: 'absolute', right: '5%'}}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  {bijzType === 3 ?
                    <i style={{fontSize: '0.7vw', color: Colors.color.orangeVacation}} className='fas fa-calendar-exclamation' />
                  :
                    <i style={{fontSize: '0.7vw', color: bijzType === 1 ? Colors.color.orangeVacation : Colors.color.redFilledIn }} className='fas fa-exclamation-triangle' />
                  }
                </Tooltip>
              : null}
            </div>

            {/* <div className='hr_timeRow_sub1'>
              {userFullName}
              {bijz !== '' ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{bijz}</span>
                    </div>
                  )}
                  style={{position: 'absolute', right: '5%'}}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i style={{fontSize: '0.8vw', color: Colors.color.redFilledIn}} className='fas fa-exclamation-triangle' />
                </Tooltip>
              : null}
            </div> */}

            <div className='hr_timeRow_sub2'>
              <span style={{color: _start === '00:00' ? Colors.color.redFilledIn : null}}>{_start}</span>
            </div>
            <div className='hr_timeRow_sub2'>
              <span style={{color: _end === '00:00' ? Colors.color.redFilledIn : null}}>{_end}</span>
            </div>
            <div className='hr_timeRow_sub2'>
              {_break}
            </div>

            <div className='hr_timeRow_sub2'>
              <ReactDatePicker
                selected={Constants.stringToDate(hours.uren_begindatum)}
                onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, hours.kloktijd_id, 'uren_begindatum', 'hours')}
                dateFormat="dd-MM-yyyy"
                className={'hr_inputBoxTime_date'}
                placeholderText={__('misc_startDate')}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale={nl}
                //disabled={absence !== null}
              />
            </div>
            <div className='hr_timeRow_sub2'>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime'}
                onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, hours.kloktijd_id, 'uren_begin', 'hours', true)}
                onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                // value={absence !== null ? _start : hours.uren_begin.substr(0, 5)}
                value={hours.uren_begin.substr(0, 5)}
                placeholder={__('misc_startTime')}
                maxLength={5}
                //disabled={absence !== null}
              />
            </div>

            <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
              &nbsp;
            </div>

            <div className='hr_timeRow_sub2'>
              <ReactDatePicker
                selected={Constants.stringToDate(hours.uren_einddatum)}
                onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, hours.kloktijd_id, 'uren_einddatum', 'hours')}
                dateFormat="dd-MM-yyyy"
                className={alertMisleadingEnd ? 'hr_inputBoxTime_date_alert' : 'hr_inputBoxTime_date'}
                placeholderText={__('misc_endDate')}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale={nl}
                //disabled={absence !== null}
              />
            </div>
            <div className='hr_timeRow_sub2'>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime'}
                onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, hours.kloktijd_id, 'uren_eind', 'hours', true)}
                onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                // value={absence !== null ? _end : hours.uren_eind.substr(0, 5)}
                value={hours.uren_eind.substr(0, 5)}
                placeholder={__('misc_endTime')}
                maxLength={5}
                //disabled={absence !== null}
              />
            </div>

            <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
              &nbsp;
            </div>

            <div className='hr_timeRow_sub2'>
              <input
                // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                className={'hr_inputBoxTime'}
                onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, hours.kloktijd_id, 'uren_pauze', 'hours', true)}
                onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                // value={absence !== null ? _break : hours.uren_pauze.substr(0, 5)}
                value={hours.uren_pauze.substr(0, 5)}
                placeholder={__('misc_break')}
                maxLength={5}
                //disabled={absence !== null}
              />
            </div>

            <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.5, fontSize: '0.6vw'}}>
              {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours_current !== null ?
                <span style={{color: negative_current === true ? Colors.color.greenFilledIn : overhours_current === '00:00' ? null : Colors.color.redFilledIn, backgroundColor: contract !== null ? negative_current === true ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1) : overhours_current === '00:00' ? null : Constants.hexToRgbA(Colors.color.redFilledIn, 0.1) : null}}>
                  {negative_current === true ? '-' : '+'}{overhours_current}
                </span>
              :
                <span>&nbsp;</span>
              }
            </div>

            <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.2}}>
              {hours.uren_goedgekeurd === '1' ?
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('misc_approved')}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='far fa-check' style={{color: Colors.color.greenFilledIn}} />
                </Tooltip>
              :
                <Tooltip
                  options
                  html={(
                    <div className='appoptions_tooltipContainer'>
                      <span>{__('misc_notApproved')}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <i className='far fa-times' style={{color: Colors.color.redFilledIn}} />
                </Tooltip>
              }
            </div>

            {planObject !== null ?
              <div className='hr_timeRow_sub3' style={{cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[planObject.rooster_datum][planObject.rooster_id] ? this.closePlanMenu(planObject.rooster_id) : this.openPlanMenu(planObject.rooster_id)}>
                <i className='fas fa-caret-down planMenuAsset' />
              </div>
            :
              <div className='hr_timeRow_sub3' onClick={() => this.deleteClockTimeAlert(hours)}>
                <i style={{color: Colors.color.redFilledIn, cursor: 'pointer'}} className='fas fa-minus-circle' />
              </div>
            }

            {planObject !== null && this.state.showPlanMenu[planObject.rooster_datum][planObject.rooster_id] === true ?
              this.renderPlanMenu(planObject, absence, true, hours)
            : null}

          </div>
        );

      }

    }

    return toReturn;

  }

  getExtraHoursBars() {

    let toReturn = [];

    const hoursDay = this.state.extraHours[this.state.hoursDateString];

    if (typeof hoursDay !== 'undefined') {

      for (const key in hoursDay) {

        const hours = hoursDay[key];

        // is saved in DB
        if (Constants.isEmpty(hours.uren_id) === false) {

          let _start = '00:00';//hours.kloktijd_inklok_tijd.substr(0, 8);
          let _end = '00:00';//hours.kloktijd_uitklok_tijd.substr(0, 8);
          let _break = '00:00';

          let totalMsPlan = 0;
          let breakMS = 0;
          let lowest = null;
          let highest = null;

          if (typeof this.state.schedule[hours.uren_begindatum] !== 'undefined') {

            for (const sch of this.state.schedule[hours.uren_begindatum]) {

              if (sch.info_id === hours.info_id && sch.rooster_datum === hours.uren_begindatum) {

                const startPlan = Constants.stringToDateTime(sch.rooster_datum, sch.rooster_begin);
                const endPlan = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);

                let _breakTime = Constants.timeStringToMS(sch.rooster_pauze);
                if (Data.data.appOptions.brk === 'CALC') {
                  _breakTime = this.getBreakRule(sch.rooster_datum, sch.rooster_begin, sch.rooster_datum, sch.rooster_eind, sch).ms;
                }

                const diffPlan = endPlan.getTime() - startPlan.getTime() - _breakTime;

                breakMS += _breakTime;
                totalMsPlan += diffPlan;

                if (lowest === null || startPlan.getTime() < lowest) {
                  lowest = startPlan;
                  _start = sch.rooster_begin;
                }

                if (highest === null || endPlan.getTime() > highest) {
                  highest = endPlan;
                  _end = sch.rooster_eind;
                }

              }

            }

            _break = Constants.msToTime(breakMS).substr(0, 5);

          }

          // const _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);

          // const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
          // const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
          // const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

          // let overhours_current = (diff - _workDayHours) < 0 ? Constants.msToTime2(_workDayHours - diff) : Constants.msToTime2(diff - _workDayHours);
          // const negative_current = (diff - _workDayHours) < 0;

          // if (isNaN(Constants.timeStringToFloat(overhours_current)) === true || Constants.timeStringToFloat(overhours_current) === 0) {
          //   overhours_current = null;
          // }

          let contract = Constants.getCurrentContract(this.state.contracts[hours.info_id], this.state.hoursDate);
          contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

          let overhoursN = this.calcOverhoursN(hours, contract);
          let overhours = overhoursN.overhoursTotal;
          let negative = overhoursN.isNegativeTotal;
          let overhours_current = overhoursN.realOverhoursCurrent;
          let negative_current = overhoursN.realNegativeCurrent;

          if (isNaN(Constants.timeStringToFloat(overhours)) === true || overhoursN.overhours === '00:00') {
            overhours = null;
          }

          // const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
          // const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
          // const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

          // let overhours = (diff) < 0 ? Constants.msToTime(diff) : Constants.msToTime(diff);
          // const negative = (diff) < 0;

          // const contract = typeof this.state.contracts[hours.info_id] !== 'undefined' && this.state.contracts[hours.info_id].contract_type !== null && this.state.contracts[hours.info_id].contract_type !== '0' ? this.state.contracts[hours.info_id] : null;

          // if (isNaN(Constants.timeStringToFloat(overhours)) === true) {
          //   overhours = null;
          // }

          toReturn.push(
            <div className='hr_timeRow'>

              <div className='hr_timeRow_sub1'>
                <select
                  name={__('select_employee')}
                  className={'hr_dropdown'}
                  onChange={(event) => this.selectAccount(event, key)}
                >
                  <option value={'null'} disabled selected>{__('select_employee')}</option>
                  {this.getAccountBars(key)}
                </select>
                <span style={{marginLeft: '2%', paddingRight: '2%'}}>
                  {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours !== null ?
                    <div>
                      <span style={{color: negative === true ? Colors.color.greenFilledIn : overhours === '00:00' ? null : Colors.color.redFilledIn, fontSize: '0.5vw'}}>
                        {negative === true ? '-' : '+'}{overhours}
                      </span>
                    </div>
                  : null}
                </span>
              </div>

              <div className='hr_timeRow_sub2'>
                {_start}
              </div>
              <div className='hr_timeRow_sub2'>
                {_end}
              </div>
              <div className='hr_timeRow_sub2'>
                {_break}
              </div>

              <div className='hr_timeRow_sub2'>
                <ReactDatePicker
                  selected={Constants.stringToDate(hours.uren_begindatum)}
                  onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, key, 'uren_begindatum', 'extraHours')}
                  dateFormat="dd-MM-yyyy"
                  className={'hr_inputBoxTime_date'}
                  placeholderText={__('misc_startDate')}
                  showWeekNumbers
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  locale={nl}
                />
              </div>
              <div className='hr_timeRow_sub2'>
                <input
                  // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                  className={'hr_inputBoxTime'}
                  onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, key, 'uren_begin', 'extraHours', true)}
                  onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                  value={hours.uren_begin.substr(0, 5)}
                  placeholder={__('misc_startTime')}
                  maxLength={5}
                />
              </div>

              <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
                &nbsp;
              </div>

              <div className='hr_timeRow_sub2'>
                <ReactDatePicker
                  selected={Constants.stringToDate(hours.uren_einddatum)}
                  onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, key, 'uren_einddatum', 'extraHours')}
                  dateFormat="dd-MM-yyyy"
                  className={'hr_inputBoxTime_date'}
                  placeholderText={__('misc_endDate')}
                  showWeekNumbers
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  locale={nl}
                />
              </div>
              <div className='hr_timeRow_sub2'>
                <input
                  // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                  className={'hr_inputBoxTime'}
                  onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, key, 'uren_eind', 'extraHours', true)}
                  onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                  value={hours.uren_eind.substr(0, 5)}
                  placeholder={__('misc_endTime')}
                  maxLength={5}
                />
              </div>

              <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
                &nbsp;
              </div>

              <div className='hr_timeRow_sub2'>
                <input
                  // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                  className={'hr_inputBoxTime'}
                  onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, key, 'uren_pauze', 'extraHours', true)}
                  onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                  value={hours.uren_pauze.substr(0, 5)}
                  placeholder={__('misc_break')}
                  maxLength={5}
                />
              </div>

              <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.5, fontSize: '0.6vw'}}>
                {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours_current !== null ?
                  <span style={{color: negative_current === true ? Colors.color.greenFilledIn : overhours_current === '00:00' ? null : Colors.color.redFilledIn, backgroundColor: contract !== null ? negative_current === true ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1) : overhours_current === '00:00' ? null : Constants.hexToRgbA(Colors.color.redFilledIn, 0.1) : null}}>
                    {negative_current === true ? '-' : '+'}{overhours_current}
                  </span>
                :
                  <span>&nbsp;</span>
                }
              </div>

              <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.2}}>
                {hours.uren_goedgekeurd === '1' ?
                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('misc_approved')}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <i className='far fa-check' style={{color: Colors.color.greenFilledIn}} />
                  </Tooltip>
                :
                  <Tooltip
                    options
                    html={(
                      <div className='appoptions_tooltipContainer'>
                        <span>{__('misc_notApproved')}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <i className='far fa-times' style={{color: Colors.color.redFilledIn}} />
                  </Tooltip>
                }
              </div>

              <div className='hr_timeRow_sub3' onClick={() => this.deleteClockTimeAlert(hours)}>
                <i style={{color: Colors.color.redFilledIn, cursor: 'pointer'}} className='fas fa-minus-circle' />
              </div>

            </div>
          );

          // is NEW
        } else {

          let _start = '00:00';//hours.kloktijd_inklok_tijd.substr(0, 8);
          let _end = '00:00';//hours.kloktijd_uitklok_tijd.substr(0, 8);
          let _break = '00:00';

          let totalMsPlan = 0;
          let breakMS = 0;
          let lowest = null;
          let highest = null;

          const _startDate = Constants.isEmpty(hours.startDate) ? this.state.hoursDateString : hours.startDate;
          const _endDate = Constants.isEmpty(hours.endDate) ? this.state.hoursDateString : hours.endDate;

          if (typeof this.state.schedule[_startDate] !== 'undefined') {

            for (const sch of this.state.schedule[_startDate]) {

              if (sch.info_id === hours.id && sch.rooster_datum === _startDate) {

                const startPlan = Constants.stringToDateTime(sch.rooster_datum, sch.rooster_begin);
                const endPlan = Constants.stringToDateTime(Plan.isNightShift2(sch), sch.rooster_eind);

                let _breakTime = Constants.timeStringToMS(sch.rooster_pauze);
                if (Data.data.appOptions.brk === 'CALC') {
                  _breakTime = this.getBreakRule(sch.rooster_datum, sch.rooster_begin, sch.rooster_datum, sch.rooster_eind, sch).ms;
                }

                const diffPlan = endPlan.getTime() - startPlan.getTime() - _breakTime;

                breakMS += _breakTime;
                totalMsPlan += diffPlan;

                if (lowest === null || startPlan.getTime() < lowest) {
                  lowest = startPlan;
                  _start = sch.rooster_begin;
                }

                if (highest === null || endPlan.getTime() > highest) {
                  highest = endPlan;
                  _end = sch.rooster_eind;
                }

              }

            }

            _break = Constants.msToTime(breakMS).substr(0, 5);

          }

          // const _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);

          // const start = Constants.stringToDateTime(_startDate, hours.start.substr(0, 5));
          // const end = Constants.stringToDateTime(_endDate, hours.end.substr(0, 5));
          // const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.break.substr(0, 5));

          // let overhours_current = (diff - _workDayHours) < 0 ? Constants.msToTime2(_workDayHours - diff) : Constants.msToTime2(diff - _workDayHours);
          // const negative_current = (diff - _workDayHours) < 0;

          // if (isNaN(Constants.timeStringToFloat(overhours_current)) === true || Constants.timeStringToFloat(overhours_current) === 0) {
          //   overhours_current = null;
          // }

          let contract = Constants.getCurrentContract(this.state.contracts[hours.id], this.state.hoursDate);
          contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

          let overhoursN = this.calcOverhoursN(hours, contract);
          let overhours = overhoursN.overhoursTotal;
          let negative = overhoursN.isNegativeTotal;
          let overhours_current = overhoursN.overhoursCurrent;
          let negative_current = overhoursN.isNegativeCurrent;

          if (isNaN(Constants.timeStringToFloat(overhours)) === true || overhoursN.overhours === '00:00') {
            overhours = null;
          }

          // const start = Constants.stringToDateTime(Constants.isEmpty(hours.startDate) ? this.state.hoursDateString : hours.startDate, hours.start.substr(0, 5));
          // const end = Constants.stringToDateTime(Constants.isEmpty(hours.endDate) ? this.state.hoursDateString : hours.endDate, hours.end.substr(0, 5));
          // const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.break.substr(0, 5));

          // let overhours = (diff) < 0 ? Constants.msToTime(diff) : Constants.msToTime(diff);
          // const negative = (diff) < 0;

          // const contract = typeof hours.id !== 'undefined' && hours.id !== null && typeof this.state.contracts[hours.id] !== 'undefined' && this.state.contracts[hours.id].contract_type !== '0' ? this.state.contracts[hours.id] : null;

          // if (isNaN(Constants.timeStringToFloat(overhours)) === true) {
          //   overhours = null;
          // }

          toReturn.push(
            <div className='hr_timeRow'>

              <div className='hr_timeRow_sub1'>
                <select
                  name={__('select_employee')}
                  className={'hr_dropdown'}
                  onChange={(event) => this.selectAccount(event, key)}
                >
                  <option value={'null'} disabled selected>{__('select_employee')}</option>
                  {this.getAccountBars(key)}
                </select>
                <span style={{marginLeft: '2%', paddingRight: '2%'}}>
                  {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours !== null ?
                    <div>
                      <span style={{color: negative === true ? Colors.color.greenFilledIn : overhours === '00:00' ? null : Colors.color.redFilledIn, fontSize: '0.5vw'}}>
                        {negative === true ? '-' : '+'}{overhours}
                      </span>
                    </div>
                  : null}
                </span>
              </div>

              <div className='hr_timeRow_sub2'>
                {_start}
              </div>
              <div className='hr_timeRow_sub2'>
                {_end}
              </div>
              <div className='hr_timeRow_sub2'>
                {_break}
              </div>

              <div className='hr_timeRow_sub2'>
                <ReactDatePicker
                  selected={Constants.stringToDate(hours.startDate)}
                  onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, key, 'startDate', 'extraHours')}
                  dateFormat="dd-MM-yyyy"
                  className={'hr_inputBoxTime_date'}
                  placeholderText={__('misc_startDate')}
                  showWeekNumbers
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  locale={nl}
                />
              </div>
              <div className='hr_timeRow_sub2'>
                <input
                  // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                  className={'hr_inputBoxTime'}
                  onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, key, 'start', 'extraHours', true)}
                  onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                  value={hours.start.substr(0, 5)}
                  placeholder={__('misc_startTime')}
                  maxLength={5}
                />
              </div>

              <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
                &nbsp;
              </div>

              <div className='hr_timeRow_sub2'>
                <ReactDatePicker
                  selected={Constants.stringToDate(hours.endDate)}
                  onChange={(date) => this.selectDateChangeHours(date, this.state.hoursDateString, key, 'endDate', 'extraHours')}
                  dateFormat="dd-MM-yyyy"
                  className={'hr_inputBoxTime_date'}
                  placeholderText={__('misc_endDate')}
                  showWeekNumbers
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  locale={nl}
                />
              </div>
              <div className='hr_timeRow_sub2'>
                <input
                  // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                  className={'hr_inputBoxTime'}
                  onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, key, 'end', 'extraHours', true)}
                  onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                  value={hours.end.substr(0, 5)}
                  placeholder={__('misc_endTime')}
                  maxLength={5}
                />
              </div>

              <div className='hr_timeRow_sub2' style={{flex: 0.2}}>
                &nbsp;
              </div>

              <div className='hr_timeRow_sub2'>
                <input
                  // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
                  className={'hr_inputBoxTime'}
                  onChange={(event) => this.onChangeTime(event, this.state.hoursDateString, key, 'break', 'extraHours', true)}
                  onKeyDown={(event) => this.handleKey(event, 'maxEnd')}
                  value={hours.break.substr(0, 5)}
                  placeholder={__('misc_break')}
                  maxLength={5}
                />
              </div>

              <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.5, fontSize: '0.6vw'}}>
                {Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null && overhours_current !== null ?
                  <span style={{color: negative_current === true ? Colors.color.greenFilledIn : overhours_current === '00:00' ? null : Colors.color.redFilledIn, backgroundColor: contract !== null ? negative_current === true ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1) : overhours_current === '00:00' ? null : Constants.hexToRgbA(Colors.color.redFilledIn, 0.1) : null}}>
                    {negative_current === true ? '-' : '+'}{overhours_current}
                  </span>
                :
                  <span>&nbsp;</span>
                }
              </div>

              <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.2}}>
                &nbsp;
              </div>

              <div className='hr_timeRow_sub3' onClick={() => this.deleteExtraRowAlert(key)}>
                <i style={{color: Colors.color.redFilledIn, cursor: 'pointer'}} className='fas fa-minus-circle' />
              </div>

            </div>
          );

        }

      }

    }

    return toReturn;

  }

  deleteExtraRowAlert(key) {

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: __('alert_delete'),
      alertButtons: 2,
      alertButtonAction: this.deleteExtraRow.bind(this, key),
      alertButtonText: [__('button_cancel'), __('button_delete')],
      showAlert: true,
    });

  }

  deleteExtraRow(key) {

    let extraHours = this.state.extraHours;

    Constants.removeFromArray(extraHours[this.state.hoursDateString], extraHours[this.state.hoursDateString][key]);

    this.setState({ extraHours: extraHours, changes: true });

  }

  selectAccount(e, key) {

    let extraHours = this.state.extraHours;
    const hoursObject = extraHours[this.state.hoursDateString][key];

    // is saved in db
    if (Constants.isEmpty(hoursObject.uren_id) === false) {
      extraHours[this.state.hoursDateString][key].uren_info_id = e.target.value;
    } else {
      extraHours[this.state.hoursDateString][key].id = e.target.value;
    }

    this.setState({ extraHours: extraHours, changes: true });

  }

  getAccountBars(key) {

    let toReturn = [];

    const hoursObject = this.state.extraHours[this.state.hoursDateString][key];

    for (const acc of this.state.accounts) {

      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;

      // is saved in db
      if (Constants.isEmpty(hoursObject.uren_id) === false) {

        toReturn.push(
          <option value={acc.info_id} selected={hoursObject.uren_info_id === acc.info_id}>{userFullName}</option>
        );

      } else {

        toReturn.push(
          <option value={acc.info_id} selected={hoursObject.id === acc.info_id}>{userFullName}</option>
        );

      }

    }

    return toReturn;

  }

  closePlanMenu(rid) {

    //this.closeAllPlanMenuSubs();

    let showPlanMenu = this.state.showPlanMenu;

    showPlanMenu[this.state.hoursDateString][rid] = false;

    this.setState({ showPlanMenu: showPlanMenu });

  }

  openPlanMenu(rid) {

    //this.closeAllPlanMenus();

    let showPlanMenu = this.state.showPlanMenu;

    showPlanMenu[this.state.hoursDateString][rid] = true;

    this.setState({ showPlanMenu: showPlanMenu });

  }

  renderPlanMenu(data, absence, hasClocked, hoursData = null) {

    let _break = data.rooster_pauze;
    if (Data.data.appOptions.brk === 'CALC') {
      _break = this.getBreakRule(data.rooster_datum, data.rooster_begin, data.rooster_datum, data.rooster_eind, data).str.substr(0, 5);
    }

    return (

      <div draggable='false' className={`planner_planMenu`} style={{width: '21%', right: 0, paddingTop: '0.8%', paddingBottom: '0.8%'}}>

        {Data.data.appOptions.hours === 'CLOCK' && hasClocked !== true ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => { this.changeAbsenceSchedule(null, data, false); this.addExtraHoursData(data.info_id, data.rooster_datum, Plan.isNightShift2(data), data.rooster_begin, data.rooster_eind, _break); }}
            >
              <div className='planner_planMenu_row_sub1' style={{paddingLeft: 0}}>
                <i className='fal fa-info-circle planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2' style={{flex: 5}}>
                {__('time_addToRegistration')}
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className='fal fa-arrow-right planMenuAsset' />
              </div>
            </div>

          </div>
        : null}

        <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuAbsence[this.state.hoursDateString][data.rooster_id] === true ? 
              this.closePlanMenuAbsence(data.rooster_id) 
            :
              this.openPlanMenuAbsence(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1' style={{paddingLeft: 0}}>
              <i className='fal fa-user-minus planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2' style={{flex: 5}}>
              {__('misc_absence')}
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className={`fa${absence !== null && typeof absence !== 'undefined' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuAbsence[this.state.hoursDateString][data.rooster_id] === true ? {color: '#47B7EB'} : null} />
            </div>
          </div>
          
          {this.state.showPlanMenuAbsence[this.state.hoursDateString][data.rooster_id] === true ?

            this.renderMenuAbsence(data, absence)

          : null}

        </div>

        {hoursData !== null ?
          <div 
            className='planner_planMenu_deleteRow'
            onClick={() => this.deleteClockTimeAlert(hoursData)}
          >
            <div className='planner_planMenu_deleteRow_sub1' style={{paddingLeft: 0}}>
              <i className='fal fa-trash-alt planMenuAsset' />
            </div>
            <div className='planner_planMenu_deleteRow_sub2' style={{flex: 5}}>
              {__('button_delete')}
            </div>
            <div className='planner_planMenu_row_sub3'>
              &nbsp;
            </div>
          </div>
        : null}

      </div>

    );
    
  }

  async changeAbsenceSchedule(absence, data, add) {

    if (this.state.loading || this.state.reloading) {
      return;
    } 

    // const partSchedule = this.state.partSchedules[data.rooster_id];

    if(add === true) {

      await APIDeleteAbsenceSchedule.Request(data.rooster_id);
      await APIAddAbsenceSchedule.Request(absence.afw_id, data.rooster_id);

      // if (data.rooster_publiceren === '1') {
      //   const dateObject = Constants.stringToDate(data.rooster_datum);
      //   KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.getDate()} ${Constants.getMonthNameM(dateObject.getMonth())}: ${absence.afw_naam}`);
      // }

    } else {

      await APIDeleteAbsenceSchedule.Request(data.rooster_id);

      // if (data.rooster_publiceren === '1') {

      //   const dateObject = Constants.stringToDate(data.rooster_datum);

      //   if (typeof partSchedule !== 'undefined') {
      //     KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${dateObject.toLocaleDateString()}: Geen absentie/${partSchedule.bijz_naam}`);
      //   } else {
      //     KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.getDate()} ${Constants.getMonthNameM(dateObject.getMonth())}: Geen absentie`);
      //   }

      // }

    }

    const absenceSchedules = await APIGetAllAbsenceSchedules.Request(Constants.dateToString(this.state.mondayDate), Constants.dateToString(this.state.sundayDate));
    const absenceSchedulesObj = Constants.convertToObject(absenceSchedules, 'rooster_id');

    this.setState({ absenceSchedules: absenceSchedulesObj }); 

    this.closeAllPlanMenus();

  }

  renderMenuAbsence(data, absence) { // data = plannerid data

    let toRender = [];

    if (absence === null || typeof absence === 'undefined') {
      absence = { afw_id: null, afw_naam: null, afw_afkorting: null };
    }

    for (const type of this.state.absencetypes) {

      if (type.afw_id === absence.afw_id) {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeAbsenceSchedule(type, data, false)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{type.afw_naam}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeAbsenceSchedule(type, data, true)}>
            <div className='planner_planMenuSub_row_sub1'>
              {type.afw_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }
      
    }

    return (
      <div className={`planner_planMenuSub`} style={{left: '-110%'}}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'abs' })} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            {__('time_manageAbsenceTypes')}
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' />

        {toRender}
      </div>
    );

  }

  async saveWorkedHours() {

    const hours = this.state.hours[this.state.hoursDateString];
    const extraHours = this.state.extraHours[this.state.hoursDateString];

    this.setState({ reloading: true, changes: false });

    // ROOSTER
    if (typeof hours !== 'undefined' && Object.keys(hours).length > 0) {

      let promises = [];


      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NOT NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`);
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_kloktijd_id IS NOT NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`);
      } else {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NOT NULL`);
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_kloktijd_id IS NOT NULL`);
      }

      for (const key in hours) {
        const info = hours[key];

        if (Constants.isEmpty(info.rooster_id) === false) {
          // console.log("save 1")
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${info.uren_begin}', '${info.uren_eind}', '${info.uren_pauze}', ${info.rooster_id}, NULL, ${info.uren_goedgekeurd === '1' ? '1' : '0'}, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        } else if (Constants.isEmpty(info.kloktijd_id) === false) {
          // console.log("save 2")
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${info.uren_begin.substr(0, 5)}', '${info.uren_eind.substr(0, 5)}', '${info.uren_pauze.substr(0, 5)}', NULL, ${info.kloktijd_id}, ${info.uren_goedgekeurd === '1' ? '1' : '0'}, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        } else {
          // console.log("save 3")
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${info.uren_begin}', '${info.uren_eind}', '${info.uren_pauze}', NULL, NULL, ${info.uren_goedgekeurd === '1' ? '1' : '0'}, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        }
      }

      await Promise.all(promises);

    }

    // EXTRA HOURS
    if (typeof extraHours !== 'undefined' &&  Object.keys(extraHours).length > 0) {

      let promises = [];

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NULL AND uren_kloktijd_id IS NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`);
      } else {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NULL AND uren_kloktijd_id IS NULL`);
      }

      for (const key in extraHours) {
        const info = extraHours[key];
        if (Constants.isEmpty(info.uren_id) === false) {
          if (Constants.isEmpty(info.uren_info_id) === true) {
            continue;
          }
          //promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.uren_info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${Constants.isEmpty(info.uren_begin) ? '00:00' : info.uren_begin}', '${Constants.isEmpty(info.uren_eind) ? '00:00' : info.uren_eind}', '${Constants.isEmpty(info.uren_pauze) ? '00:00' : info.uren_pauze}', NULL, NULL, ${info.uren_goedgekeurd === '1' ? '1' : '0'})`));
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.uren_info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${Constants.isEmpty(info.uren_begin) ? '00:00' : info.uren_begin}', '${Constants.isEmpty(info.uren_eind) ? '00:00' : info.uren_eind}', '${Constants.isEmpty(info.uren_pauze) ? '00:00' : info.uren_pauze}', NULL, NULL, ${info.uren_goedgekeurd === '1' ? '1' : '0'}, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));

        } else {
          if (Constants.isEmpty(info.id) === true) {
            continue;
          }
          //promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.id}, '${this.state.hoursDateString}', '${this.state.hoursDateString}', '${Constants.isEmpty(info.start) ? '00:00' : info.start}', '${Constants.isEmpty(info.end) ? '00:00' : info.end}', '${Constants.isEmpty(info.break) ? '00:00' : info.break}', NULL, NULL, ${info.uren_goedgekeurd === '1' ? '1' : '0'})`));
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.id}, '${info.startDate}', '${info.endDate}', '${Constants.isEmpty(info.start) ? '00:00' : info.start}', '${Constants.isEmpty(info.end) ? '00:00' : info.end}', '${Constants.isEmpty(info.break) ? '00:00' : info.break}', NULL, NULL, ${info.uren_goedgekeurd === '1' ? '1' : '0'}, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        }
      }

      await Promise.all(promises);

    }

    await this.getWeekData(this.state.hoursDate);

    this.setState({reloading: false});

  }

  async acceptAllHours() {

    const hours = this.state.hours[this.state.hoursDateString];
    const extraHours = this.state.extraHours[this.state.hoursDateString];

    this.setState({ reloading: true, changes: false });

    // overhours
    await APIDELETE.Request(`DELETE FROM Overuren`, `WHERE ov_datum = '${this.state.hoursDateString}'`);

    // overhoursN
    let addedAccsOverhoursN = [];

    // ROOSTER

    if (typeof hours !== 'undefined' && Object.keys(hours).length > 0) {

      let promises = [];

      // await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NOT NULL AND `);
      // await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_kloktijd_id IS NOT NULL`);

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NOT NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`);
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_kloktijd_id IS NOT NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`);
      } else {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NOT NULL`);
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_kloktijd_id IS NOT NULL`);
      }

      for (const key in hours) {
        const info = hours[key];
        
        if (Constants.isEmpty(info.rooster_id) === false) {
          // console.log("1")
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${info.uren_begin}', '${info.uren_eind}', '${info.uren_pauze}', ${info.rooster_id}, NULL, 1, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        } else if (Constants.isEmpty(info.kloktijd_id) === false) {
          // console.log("2")
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${info.uren_begin.substr(0, 5)}', '${info.uren_eind.substr(0, 5)}', '${info.uren_pauze.substr(0, 5)}', NULL, '${info.kloktijd_id}', 1, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        } else {
          // console.log("3")
          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${info.uren_begin}', '${info.uren_eind}', '${info.uren_pauze}', NULL, NULL, 1, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== 'NULL' ? Data.data.chosenTeamObj.team_id : 'NULL'})`));
        }

        // overhours
        let contract = Constants.getCurrentContract(this.state.contracts[info.info_id], this.state.hoursDate);
        contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

        if (Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null) {

          const overhoursToAdd = this.calcOverhoursN(info, contract);
          if (Constants.isEmpty(overhoursToAdd.overhoursCurrent) === false && Constants.timeStringToFloat(overhoursToAdd.overhoursCurrent) !== 0 && Constants.arrayContains(addedAccsOverhoursN, info.info_id) === false) {
            const _overhoursToAdd = Constants.timeStringToFloat(overhoursToAdd.overhoursCurrent);
            const __overhoursToAdd = overhoursToAdd.isNegativeCurrent ? -_overhoursToAdd : _overhoursToAdd;
            addedAccsOverhoursN.push(info.info_id);
            promises.push(APIADD.Request(`INSERT INTO Overuren`, `VALUES (NULL, ${info.info_id}, '${this.state.hoursDateString}', ${__overhoursToAdd})`));
          }

        }

      }

      await Promise.all(promises);
    }

    // EXTRA HOURS
    if (typeof extraHours !== 'undefined' &&  Object.keys(extraHours).length > 0) {
      let promises = [];

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NULL AND uren_kloktijd_id IS NULL AND uren_team_id = ${Data.data.chosenTeamObj.team_id}`);
      } else {
        await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_begindatum = '${this.state.hoursDateString}' AND uren_rooster_id IS NULL AND uren_kloktijd_id IS NULL`);
      }

      for (const key in extraHours) {
        const info = extraHours[key];
        if (Constants.isEmpty(info.uren_id) === false) {
          if (Constants.isEmpty(info.uren_info_id) === true) {
            continue;
          }

          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.uren_info_id}, '${info.uren_begindatum}', '${info.uren_einddatum}', '${Constants.isEmpty(info.uren_begin) ? '00:00' : info.uren_begin}', '${Constants.isEmpty(info.uren_eind) ? '00:00' : info.uren_eind}', '${Constants.isEmpty(info.uren_pauze) ? '00:00' : info.uren_pauze}', NULL, NULL, 1, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));

          // overhours
          let contract = Constants.getCurrentContract(this.state.contracts[info.info_id], this.state.hoursDate);
          contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

          if (Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null) {

            const overhoursToAdd = this.calcOverhoursN(info, contract);
            if (Constants.isEmpty(overhoursToAdd.overhoursCurrent) === false && Constants.timeStringToFloat(overhoursToAdd.overhoursCurrent) !== 0 && Constants.arrayContains(addedAccsOverhoursN, info.info_id) === false) {
              const _overhoursToAdd = Constants.timeStringToFloat(overhoursToAdd.overhoursCurrent);
              const __overhoursToAdd = overhoursToAdd.isNegativeCurrent ? -_overhoursToAdd : _overhoursToAdd;
              addedAccsOverhoursN.push(info.info_id);
              promises.push(APIADD.Request(`INSERT INTO Overuren`, `VALUES (NULL, ${info.info_id}, '${this.state.hoursDateString}', ${__overhoursToAdd})`));
            }

          }

        } else {
          if (Constants.isEmpty(info.id) === true) {
            continue;
          }

          promises.push(APIADD.Request(`INSERT INTO Uren`, `VALUES (NULL, ${info.id}, '${info.startDate}', '${info.endDate}', '${Constants.isEmpty(info.start) ? '00:00' : info.start}', '${Constants.isEmpty(info.end) ? '00:00' : info.end}', '${Constants.isEmpty(info.break) ? '00:00' : info.break}', NULL, NULL, 1, ${Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`));

          // overhours
          let contract = Constants.getCurrentContract(this.state.contracts[info.id], this.state.hoursDate);
          contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

          if (Constants.isTrue(Data.data.appOptions.opties_overuren) && contract !== null) {

            const overhoursToAdd = this.calcOverhoursN(info, contract);
            if (Constants.isEmpty(overhoursToAdd.overhoursCurrent) === false && Constants.timeStringToFloat(overhoursToAdd.overhoursCurrent) !== 0 && Constants.arrayContains(addedAccsOverhoursN, info.id) === false) {
              const _overhoursToAdd = Constants.timeStringToFloat(overhoursToAdd.overhoursCurrent);
              const __overhoursToAdd = overhoursToAdd.isNegativeCurrent ? -_overhoursToAdd : _overhoursToAdd;
              addedAccsOverhoursN.push(info.id);
              promises.push(APIADD.Request(`INSERT INTO Overuren`, `VALUES (NULL, ${info.id}, '${this.state.hoursDateString}', ${__overhoursToAdd})`));
            }

          }

        }
      }

      await Promise.all(promises);

    }

    await this.getWeekData(this.state.hoursDate);

    this.setState({reloading: false});

  }

  getOpenBusinessDaysWeek() {

    let count = 0;

    if (Data.data.appOptions.opties_verlof_maandag === '1') {
      count++;
    }
    if (Data.data.appOptions.opties_verlof_dinsdag === '1') {
      count++;
    }
    if (Data.data.appOptions.opties_verlof_woensdag === '1') {
      count++;
    }
    if (Data.data.appOptions.opties_verlof_donderdag === '1') {
      count++;
    }
    if (Data.data.appOptions.opties_verlof_vrijdag === '1') {
      count++;
    }
    if (Data.data.appOptions.opties_verlof_zaterdag === '1') {
      count++;
    }
    if (Data.data.appOptions.opties_verlof_zondag === '1') {
      count++;
    }

    return count;

  }

  async calcOverhours() {

    // const workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));
    // const fullWeekHours = this.getOpenBusinessDaysWeek() * workDayHours;

    // 

    let overhoursUsers = {};

    const hoursData = await APIGET.Request(
      `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, ` +
      `COUNT(DISTINCT uren_begindatum) as uren_werkdagen, ` +
      `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(uren_begindatum, uren_begin), TIMESTAMP(uren_einddatum, uren_eind) ) ) as uren_bruto, ` +
      `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(uren_begindatum, uren_begin), TIMESTAMP(uren_einddatum, uren_eind) ) - TIME_TO_SEC(uren_pauze) ) as uren_netto, ` +
      `SUM( TIME_TO_SEC(uren_pauze) ) as uren_pauze`,
      `FROM Uren`,
      (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id` : ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id` : ``),
      `WHERE uren_goedgekeurd = 1 AND uren_begindatum BETWEEN '${Constants.dateToString(this.state.mondayDate)}' AND '${Constants.dateToString(this.state.sundayDate)}'` + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})` : ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})` : ``),
      `GROUP BY info_id`,
      null
    );

    for (const hours of hoursData) {

      let contract = Constants.getCurrentContract(this.state.contracts[hours.info_id], this.state.hoursDate);
      contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

      if (contract !== null) {

        const hoursTotal = Constants.round(parseInt(hours.uren_netto) / 60 / 60);
        let overhours = 0;
        
        if (contract.contract_type === '2') { // vaste uren

          const contractHours = parseFloat(contract.contract_uren);
          overhours = Constants.round((hoursTotal - contractHours));

        } else if (contract.contract_type === '1') { // min max

          const contractMin = parseFloat(contract.contract_min);
          const contractMax = parseFloat(contract.contract_max);

          if (hoursTotal < contractMin) {
            overhours = Constants.round((hoursTotal - contractMin));
          } else if (hoursTotal > contractMax) {
            overhours = Constants.round((hoursTotal - contractMax));
          }

        }

        overhoursUsers[hours.info_id] = overhours;

      }

    }

    for (const key in overhoursUsers) {

      await APIDELETE.Request(`DELETE FROM Overuren`, `WHERE ov_week = ${Constants.getWeekNumber2(this.state.mondayDate)} AND ov_jaar = ${this.state.mondayDate.getFullYear()} AND ov_info_id = ${key}`);
      await APIADD.Request(`INSERT INTO Overuren`, `VALUES (NULL, ${key}, ${Constants.getWeekNumber2(this.state.mondayDate)}, ${this.state.mondayDate.getFullYear()}, ${overhoursUsers[key]})`);
      
    }

    const overhours = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Overuren.*`, `FROM Overuren`, `LEFT JOIN Info ON ov_info_id = info_id`, null, null, `ORDER BY info_voornaam, info_achternaam`);
    const overhoursObj = Constants.convertToObjectWithArrays(overhours, 'info_id');

    this.setState({ overhours: overhours, overhoursObj: overhoursObj });

  }

  addExtraHours() {

    let extraHours = this.state.extraHours;

    extraHours[this.state.hoursDateString].push({
      id: null,
      startDate: this.state.hoursDateString,
      start: '00:00',
      endDate: this.state.hoursDateString,
      end: '00:00',
      break: '00:00',
    });

    this.setState({ extraHours: extraHours, changes: true });

  }

  addExtraHoursData(id, startDate, endDate, start, end, _break) {

    let extraHours = this.state.extraHours;

    extraHours[this.state.hoursDateString].push({
      id: id,
      startDate: startDate,
      start: start,
      endDate: endDate,
      end: end,
      break: _break,
    });
    
    this.closeAllPlanMenus();

    this.setState({ extraHours: extraHours, changes: true });

  }

  deleteClockTimeAlert(data) {

    this.setState({
      alertTitle: __('alert_delete_title'),
      alertBody: __('alert_delete'),
      alertButtons: 2,
      alertButtonAction: this.deleteClockTime.bind(this, data),
      alertButtonText: [__('button_cancel'), __('button_delete')],
      showAlert: true,
    });

  }

  async deleteClockTime(data) {

    this.setState({ reloading: true });

    await APIDELETE.Request(`DELETE FROM Uren`, `WHERE uren_id = ${data.uren_id}`);
    await APIDELETE.Request(`DELETE FROM Overuren`, `WHERE ov_datum = '${this.state.hoursDateString}' AND ov_info_id = ${data.info_id}`);
    if (typeof data.kloktijd_id !== 'undefined' && data.kloktijd_id !== null) {
      await APIDELETE.Request(`DELETE FROM Kloktijd`, `WHERE kloktijd_id = ${data.kloktijd_id}`);
    }

    await this.getWeekData(this.state.hoursDate);

    this.setState({ reloading: false });

  }

  changeReportDisplay(e) {

    let sort = 'NULL';
    if (this.state.reportDetailed === true) {
      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC`;
      } else {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC`;
      }
    } else {
      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC`;
      } else {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC`;
      }
    }

    this.setState({ reportDisplay: e.target.value });

  }

  changeReportSort(e) {

    this.setState({ reportSort: e.target.value });

  }

  changeReportJob(e) {

    this.setState({ reportJob: e.target.value });

  }

  changeReportEmployee(e) {

    this.setState({ reportEmployee: e.target.value });

  }

  changeReportDetailed(e) {

    let sort = 'NULL';
    if (e.target.value === 'true') {
      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC`;
      } else {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC`;
      }
    } else {
      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC`;
      } else {
        sort = `ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC`;
      }
    }

    this.setState({ reportDetailed: e.target.value === 'true' ? true : false, reportSort: sort });

  }

  changeReportContract(e) {

    this.setState({ reportContract: e.target.value === 'null' ? null : e.target.value });

  }

  changeReportBonus(e) {

    this.setState({ reportBonus: e.target.value === '1' ? true : false });

  }

  closePlanMenuAbsence(rid) {
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    showPlanMenuAbsence[this.state.hoursDateString][rid] = false;
    this.setState({ showPlanMenuAbsence: showPlanMenuAbsence });
  }

  openPlanMenuAbsence(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    showPlanMenuAbsence[this.state.hoursDateString][rid] = true;
    this.setState({ showPlanMenuAbsence: showPlanMenuAbsence });
  }

  closeAllPlanMenus() {

    this.closeAllPlanMenuSubs();

    // add listener
    //window.removeEventListener("click", this.clickOutsideMenu);

    let showPlanMenu = this.state.showPlanMenu;

    for (const key in showPlanMenu) {
      for (const key2 in showPlanMenu[key]) {
        showPlanMenu[key][key2] = false;
      }
    }

    this.setState({showPlanMenu: showPlanMenu});

  }

  closeAllPlanMenuSubs() {

    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;

    for (const key in this.state.schedule) {
      for (const plan of this.state.schedule[key]) {
        showPlanMenuAbsence[key][plan.rooster_id] = false;
      }
    }
    
    this.setState({
      showPlanMenuAbsence: showPlanMenuAbsence,
    });

  }

  closeAllPlanMenusOutside(e) {

    if(e.target.className.includes('planner_planMenu') === false && e.target.className.includes('planner_arrowContainer') === false
      && e.target.className.includes('fas fa-caret-down') === false && e.target.className.includes('planMenuAsset') === false
      && e.target.className.includes('planner_table_nameContainer2_name_sub2') === false && e.target.className.includes('planner_userMenu') === false
      && e.target.className.includes('hr_timeRow_sub3') === false) {
      this.closeAllPlanMenus();
    }

  }

  async changeHoursDisplay(display, detailed, order, start, end) {

    if (this.state.loading === false && this.state.reloading === false) {
      this.setState({ reloading: true });
    }

    this.reportDetailed = detailed;

    let reportData = [];
    let scheduleData = [];

    let reportData2 = [];
    let scheduleData2 = [];

    let scheduleDataObj = {};

    let newReportData = [];

    // not detailed
    if (detailed === false && this.props.individual !== true) {

      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {

        reportData = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*, InfoExtra.*`,
          // `COUNT(DISTINCT uren_begindatum) as uren_werkdagen, ` +
          // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(uren_begindatum, uren_begin), TIMESTAMP(uren_einddatum, uren_eind) ) ) as uren_bruto, ` +
          // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(uren_begindatum, uren_begin), TIMESTAMP(uren_einddatum, uren_eind) ) - TIME_TO_SEC(uren_pauze) ) as uren_netto, ` +
          // `SUM( TIME_TO_SEC(uren_pauze) ) as uren_pauze`,
          `FROM Uren`,
          (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN InfoExtra ON info2_info_id = info_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id` : ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id` : ``) + (` LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`),
          (this.state.reportDisplay === 'period' ? `WHERE uren_goedgekeurd = 1 AND uren_begindatum BETWEEN '${start}' AND '${end}'` : `WHERE uren_goedgekeurd = 1 AND uren_begindatum = '${start}'`) + (this.state.reportEmployee !== 'null' && this.state.reportEmployee !== 'all' ? ` AND uren_info_id = ${this.state.reportEmployee}` : ``) + (this.state.reportJob !== 'null' && this.state.reportJob !== 'all' ? ` AND info_functie_id = ${this.state.reportJob}` : ``) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})` : ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})` : ``) + (` AND uit_id IS NULL`),
          null, // `GROUP BY info_id HAVING uren_netto > 0`,
          null, // order
        );

        let _reportData = [];

        if (this.state.reportContract !== null) {

          for (const plan of reportData) {

            const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.uren_begindatum));

            if (this.state.reportContract === '10' && (contract === null || contract.contract_type !== '2' || Constants.isEmpty(contract.contract_type))) {
              _reportData.push(plan);
            } else if (this.state.reportContract === '0' && (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type))) {
              _reportData.push(plan);
            } else if (contract !== null && contract.contract_type === this.state.reportContract) {
              _reportData.push(plan);
            } else {
              continue;
            }

            // if (contract === null && contract.contract_type !== this.state.reportContract) {
            //   continue;
            // } else if ((contract === null || contract.contract_type !== this.state.reportContract)) {
            //   continue;
            // } else {
            //   _reportData.push(plan);
            // }

          }

        } else {
          _reportData = reportData;
        }

        for (let plan of _reportData) {

          let bonusObj = {};
          for (const b of this.state.bonus) {
            const key = `${parseFloat(b.t_factor) * 100}`;
            if (typeof bonusObj[key] === 'undefined') {
                bonusObj[key] = 0;
            }
          }

          if (Constants.objectArrayContainsKey(reportData2, 'info_id', plan.info_id) === false) {
            plan = {
              ...plan,
              uren_bruto: 0,
              uren_netto: 0,
              uren_pauzes: 0,
              uren_werkdagen: 0,
              uren_bonus: bonusObj,
            };
            reportData2.push(plan);
          }

          let toUpdate = Constants.getArrayItem(reportData2, 'info_id', plan.info_id);

          if (toUpdate !== null) {

            const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.uren_begindatum));

            const start = Constants.stringToDateTime(plan.uren_begindatum, plan.uren_begin);
            const end = Constants.stringToDateTime(plan.uren_einddatum, plan.uren_eind);
            const brk = Constants.timeStringToFloat(plan.uren_pauze) * 60 * 60;

            const uren_netto = ((end.getTime() - start.getTime()) / 1000) - brk;

            toUpdate.uren_bruto += ((end.getTime() - start.getTime()) / 1000);
            toUpdate.uren_netto += uren_netto;
            toUpdate.uren_pauzes += brk; // math floor?
            toUpdate.uren_bonus = Constants.isEmpty(contract) === false && contract.contract_type === '2' ? {} : Plan.calcBonusActual2(plan, uren_netto, toUpdate.uren_bonus, this.state.bonus);

          }

        }

        for (let newPlan of reportData2) {
          let userWorkDays = [];
          for (const plan of reportData) {
            if (newPlan.info_id === plan.info_id) {
              if (Constants.getArrayItem(userWorkDays, 'uren_begindatum', plan.uren_begindatum) === null) {
                userWorkDays.push(plan);
              }
            }
          }
          newPlan.uren_werkdagen = userWorkDays.length;
        }

        if (order === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC') {

          const key = 'info_voornaam', key2 = 'info_achternaam', key3 = 'uren_netto';
          reportData2.sort((a, b) => (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? 1 : -1);

        } else if (order === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC') {

          const key = 'info_voornaam', key2 = 'info_achternaam', key3 = 'uren_netto';
          reportData2.sort((a, b) => (a[key] < b[key]) || ((a[key] === b[key]) && a[key2] < b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? 1 : -1);  

        } else if (order === 'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC') {

          const key = 'uren_netto', key2 = 'info_voornaam', key3 = 'info_achternaam';
          reportData2.sort((a, b) => (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1);

        } else if (order === 'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC') {

          const key = 'uren_netto', key2 = 'info_voornaam', key3 = 'info_achternaam';
          reportData2.sort((a, b) => (a[key] < b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1);   

        }

      }

      scheduleData = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*, InfoExtra.*`,
        // `COUNT(DISTINCT rooster_datum) as rooster_werkdagen, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) ) as rooster_bruto, ` +
        // `SUM( TIMESTAMPDIFF( SECOND, TIMESTAMP(rooster_datum, rooster_begin), TIMESTAMP(rooster_datum, rooster_eind) ) - TIME_TO_SEC(rooster_pauze) ) as rooster_netto, ` +
        // `SUM( TIME_TO_SEC(rooster_pauze) ) as rooster_pauze`,
        `FROM Rooster`,
        (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN InfoExtra ON info2_info_id = info_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id` : ``) + (` LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`),
        (this.state.reportDisplay === 'period' ? `WHERE rooster_publiceren = 1 AND rooster_datum BETWEEN '${start}' AND '${end}'` : `WHERE rooster_publiceren = 1 AND rooster_datum = '${start}'`) + (this.state.reportEmployee !== 'null' && this.state.reportEmployee !== 'all' ? ` AND rooster_info_id = ${this.state.reportEmployee}` : ``) + (this.state.reportJob !== 'null' && this.state.reportJob !== 'all' ? ` AND info_functie_id = ${this.state.reportJob}` : ``) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ``) + (` AND uit_id IS NULL`),
        null, // `GROUP BY info_id HAVING rooster_netto > 0`,
        null, // Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? null : order,
      );

      let _scheduleData = [];

      if (this.state.reportContract !== null) {

        for (const plan of scheduleData) {

          const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.rooster_datum));

          if (this.state.reportContract === '10' && (contract === null || contract.contract_type !== '2' || Constants.isEmpty(contract.contract_type))) {
            _scheduleData.push(plan);
          } else if (this.state.reportContract === '0' && (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type))) {
            _scheduleData.push(plan);
          } else if (contract !== null && contract.contract_type === this.state.reportContract) {
            _scheduleData.push(plan);
          } else {
            continue;
          }

        }

      } else {
        _scheduleData = scheduleData;
      }

      for (let plan of _scheduleData) {

        let bonusObj = {};
        for (const b of this.state.bonus) {
          const key = `${parseFloat(b.t_factor) * 100}`;
          if (typeof bonusObj[key] === 'undefined') {
              bonusObj[key] = 0;
          }
        }

        if (Constants.objectArrayContainsKey(scheduleData2, 'info_id', plan.info_id) === false) {
          plan = {
            ...plan,
            rooster_bruto: 0,
            rooster_netto: 0,
            rooster_pauzes: 0,
            rooster_werkdagen: 0,
            rooster_bonus: bonusObj,
          };
          scheduleData2.push(plan);
        }

        let toUpdate = Constants.getArrayItem(scheduleData2, 'info_id', plan.info_id);

        if (toUpdate !== null) {

          const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.rooster_datum));

          const start = Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin);
          const end = Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind);
          const brk = Constants.timeStringToFloat(plan.rooster_pauze) * 60 * 60;

          const rooster_netto = ((end.getTime() - start.getTime()) / 1000) - brk;

          toUpdate.rooster_bruto += ((end.getTime() - start.getTime()) / 1000);
          toUpdate.rooster_netto += rooster_netto;
          toUpdate.rooster_pauzes += brk; // math floor?
          toUpdate.rooster_bonus = Constants.isEmpty(contract) === false && contract.contract_type === '2' ? {} : Plan.calcBonus2(plan, rooster_netto, toUpdate.rooster_bonus, this.state.bonus);

        }

      }

      for (let newPlan of scheduleData2) {
        let userWorkDays = [];
        for (const plan of scheduleData) {
          if (newPlan.info_id === plan.info_id) {
            if (Constants.getArrayItem(userWorkDays, 'rooster_datum', plan.rooster_datum) === null) {
              userWorkDays.push(plan);
            }
          }
        }
        newPlan.rooster_werkdagen = userWorkDays.length;
      }

      scheduleDataObj = Constants.convertToObject(scheduleData2, 'info_id');

      if (order === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC') {

        const key = 'info_voornaam', key2 = 'info_achternaam', key3 = 'rooster_netto';
        scheduleData2.sort((a, b) => (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? 1 : -1);

      } else if (order === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC') {

        const key = 'info_voornaam', key2 = 'info_achternaam', key3 = 'rooster_netto';
        scheduleData2.sort((a, b) => (a[key] < b[key]) || ((a[key] === b[key]) && a[key2] < b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? 1 : -1);  

      } else if (order === 'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC') {

        const key = 'rooster_netto', key2 = 'info_voornaam', key3 = 'info_achternaam';
        scheduleData2.sort((a, b) => (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1);

      } else if (order === 'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC') {

        const key = 'rooster_netto', key2 = 'info_voornaam', key3 = 'info_achternaam';
        scheduleData2.sort((a, b) => (a[key] < b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1);   

      }

      this.setState({ reportData: reportData2, scheduleData: scheduleData2, scheduleDataObj: scheduleDataObj, reloading: false });

    // detailed
    } else {

      let _reportData = [];

      if (Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) {

        if (this.props.individual !== true) {

          reportData = await APIGET.Request(
            `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*, InfoExtra.*`,
            `FROM Uren`,
            (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN InfoExtra ON info2_info_id = info_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id` : ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id` : ``),
            (this.state.reportDisplay === 'period' ? `WHERE uren_goedgekeurd = 1 AND uren_begindatum BETWEEN '${start}' AND '${end}'` : `WHERE uren_goedgekeurd = 1 AND uren_begindatum = '${start}'`) + (this.state.reportEmployee !== 'null' && this.state.reportEmployee !== 'all' ? ` AND uren_info_id = ${this.state.reportEmployee}` : ``) + (this.state.reportJob !== 'null' && this.state.reportJob !== 'all' ? ` AND info_functie_id = ${this.state.reportJob}` : ``) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})` : ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})` : ``),
            null,
            order
          );

        } else {

          reportData = await APIGET.Request(
            `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Uren.*, InfoExtra.*`,
            `FROM Uren`,
            (`LEFT JOIN Info ON info_id = uren_info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN InfoExtra ON info2_info_id = info_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? Data.data.appOptions.hours === 'CLOCK' ? ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id` : ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id` : ``),
            (this.state.reportDisplay === 'period' ? `WHERE uren_goedgekeurd = 1 AND uren_begindatum BETWEEN '${start}' AND '${end}'` : `WHERE uren_goedgekeurd = 1 AND uren_begindatum = '${start}'`) + (` AND uren_info_id = ${this.props.individual_userID}`) + (this.state.reportJob !== 'null' && this.state.reportJob !== 'all' ? ` AND info_functie_id = ${this.state.reportJob}` : ``),
            null,
            order
          );

        }

        if (this.state.reportContract !== null) {

          for (const plan of reportData) {

            const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.uren_begindatum));

            if (this.state.reportContract === '10' && (contract === null || contract.contract_type !== '2' || Constants.isEmpty(contract.contract_type))) {
              _reportData.push(plan);
            } else if (this.state.reportContract === '0' && (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type))) {
              _reportData.push(plan);
            } else if (contract !== null && contract.contract_type === this.state.reportContract) {
              _reportData.push(plan);
            } else {
              continue;
            }

          }

        } else {
          _reportData = reportData;
        }

      }

      if (this.props.individual !== true) {

        scheduleData = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*, AfwRooster.*, Afwezigheid.*, InfoExtra.*`,
          `FROM Rooster`,
          (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afwr_afw_id = afw_id LEFT JOIN InfoExtra ON info2_info_id = info_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id` : ``),
          (this.state.reportDisplay === 'period' ? `WHERE rooster_publiceren = 1 AND rooster_datum BETWEEN '${start}' AND '${end}'` : `WHERE rooster_publiceren = 1 AND rooster_datum = '${start}'`) + (this.state.reportEmployee !== 'null' && this.state.reportEmployee !== 'all' ? ` AND rooster_info_id = ${this.state.reportEmployee}` : ``) + (this.state.reportJob !== 'null' && this.state.reportJob !== 'all' ? ` AND info_functie_id = ${this.state.reportJob}` : ``) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}` : ``),
          null,
          Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? null : order,
        );

      } else {

        scheduleData = await APIGET.Request(
          `SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, functie_naam, Rooster.*, AfwRooster.*, Afwezigheid.*, InfoExtra.*`,
          `FROM Rooster`,
          (`LEFT JOIN Info ON info_id = rooster_info_id LEFT JOIN Functie ON functie_id = rooster_functie_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afwr_afw_id = afw_id LEFT JOIN InfoExtra ON info2_info_id = info_id`) + (Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id` : ``),
          (this.state.reportDisplay === 'period' ? `WHERE rooster_publiceren = 1 AND rooster_datum BETWEEN '${start}' AND '${end}'` : `WHERE rooster_publiceren = 1 AND rooster_datum = '${start}'`) + (` AND rooster_info_id = ${this.props.individual_userID}`) + (this.state.reportJob !== 'null' && this.state.reportJob !== 'all' ? ` AND info_functie_id = ${this.state.reportJob}` : ``),
          null,
          Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? null : order,
        );

      }

      let _scheduleData = [];

      if (this.state.reportContract !== null) {

        for (const plan of scheduleData) {

          const contract = Constants.getCurrentContract(this.state.contracts[plan.info_id], Constants.stringToDate(plan.rooster_datum));

          if (this.state.reportContract === '10' && (contract === null || contract.contract_type !== '2' || Constants.isEmpty(contract.contract_type))) {
            _scheduleData.push(plan);
          } else if (this.state.reportContract === '0' && (contract === null || contract.contract_type === '0' || Constants.isEmpty(contract.contract_type))) {
            _scheduleData.push(plan);
          } else if (contract !== null && contract.contract_type === this.state.reportContract) {
            _scheduleData.push(plan);
          } else {
            continue;
          }

        }

      } else {
        _scheduleData = scheduleData;
      }

      scheduleDataObj = Constants.convertToObjectWithArrays(_scheduleData, 'rooster_datum');

      this.setState({ reportData: _reportData, scheduleData: _scheduleData, scheduleDataObj: scheduleDataObj, reloading: false });

    }

  }

  renderHourBars() {

    let toRender = [];
    let count = 0;

    if (this.reportDetailed === false && this.props.individual !== true) {

      let totalPlannedDays = 0;
      let totalPlannedHours = 0;
      let totalWorkDays = 0;
      let totalHours = 0;
      let totalBonus = 0;

      for (const data of this.state.reportData) {

        if (typeof data.uren_netto === 'undefined') {
          continue;
        }

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
        const schData = typeof this.state.scheduleDataObj[data.info_id] !== 'undefined' ? this.state.scheduleDataObj[data.info_id] : null;

        totalPlannedDays += schData !== null ? parseInt(schData.rooster_werkdagen) : 0;
        totalPlannedHours += schData !== null ? Constants.round(parseInt(schData.rooster_netto) / 60 / 60) : 0;
        totalWorkDays += parseInt(data.uren_werkdagen);
        totalHours += (parseInt(data.uren_netto) / 60 / 60);

        let bonusHours = 0;

        for (const key in data.uren_bonus) {
          totalBonus += (parseInt(data.uren_bonus[key]) / 60 / 60);
          bonusHours += parseInt(data.uren_bonus[key]);
        }

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span className='defaultLinkO' onClick={() => this.clickOnUserHours(data.info_id)}>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub2'>
              <span>{data.functie_naam}</span>
            </div>

            <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', paddingRight: '0.5%'}}>
              {schData !== null ? schData.rooster_werkdagen : __('misc_nvt')}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', paddingRight: '0.5%'}}>
              {schData !== null ? Constants.round(parseInt(schData.rooster_netto) / 60 / 60, true) : __('misc_nvt')}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
              {data.uren_werkdagen}
            </div>

            <div className='hr_hoursBar_sub3'>
              {Constants.round((bonusHours / 60 / 60), true)}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
              {Constants.round(((parseInt(data.uren_netto) + bonusHours) / 60 / 60), true)}
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

      toRender.push(

        <div className='hr_hoursBar' style={{borderTop: '1px solid #EBEBEB', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            {__('misc_total')}:
          </div>

          <div className='hr_hoursBar_sub2'>
            &nbsp;
          </div>

          <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', paddingRight: '0.5%'}}>
            {totalPlannedDays}
          </div>

          <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', paddingRight: '0.5%'}}>
            {Constants.round(totalPlannedHours, true)}
          </div>

          <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
            {totalWorkDays}
          </div>

          <div className='hr_hoursBar_sub3'>
            {Constants.round(totalBonus, true)}
          </div>

          <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
            {Constants.round(totalHours + totalBonus, true)}
          </div>

        </div>

      );

    } else {

      for (const data of this.state.reportData) {

        if (typeof data.uren_begin === 'undefined') {
          continue;
        }

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
        const schDataArray = typeof this.state.scheduleDataObj[data.uren_begindatum] !== 'undefined' ? this.state.scheduleDataObj[data.uren_begindatum] : null;

        let schData = null;
        if (schDataArray !== null && schDataArray.length > 0) {
          for (const sch of schDataArray) {
            if (sch.info_id === data.info_id) {
              schData = sch;
              break;
            }
          }
        }

        const planDate = schData !== null ? Constants.stringToDate(schData.rooster_datum) : null;
        const startDate = Constants.stringToDate(data.uren_begindatum);
        const endDate = Constants.stringToDate(data.uren_einddatum);

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub2'>
              <span>{schData !== null ? schData.functie_naam : ''}</span>
            </div>

            <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', justifyContent: 'flex-start'}}>
              {schData !== null ? `${planDate.getDate()} ${Constants.getShortMonthNameM(planDate.getMonth()).toLowerCase()}` : __('misc_nvt')}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', justifyContent: 'flex-start'}}>
              {schData !== null ? schData.rooster_begin.substr(0, 5) : __('misc_nvt')}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontStyle: 'italic', justifyContent: 'flex-start'}}>
              {schData !== null ? schData.rooster_eind.substr(0, 5) : __('misc_nvt')}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold', justifyContent: 'flex-start'}}>
              {`${startDate.getDate()} ${Constants.getShortMonthNameM(startDate.getMonth()).toLowerCase()} ${data.uren_begin.substr(0, 5)}`}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold', justifyContent: 'flex-start'}}>
              {`${endDate.getDate()} ${Constants.getShortMonthNameM(endDate.getMonth()).toLowerCase()} ${data.uren_eind.substr(0, 5)}`}
            </div>

            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
              {data.uren_pauze.substr(0, 5)}
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toRender;

  }

  renderHourBarsPlanner() {

    let toRender = [];
    let count = 0;

    if (this.reportDetailed === false && this.props.individual !== true) {

      let totalWorkDays = 0;
      let totalHours = 0;

      for (const data of this.state.scheduleData) {

        if (typeof data.rooster_netto === 'undefined') {
          continue;
        }

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

        const hours = Constants.round(parseInt(data.rooster_netto) / 60 / 60);
        totalHours += hours;
        totalWorkDays += parseInt(data.rooster_werkdagen);

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span className='defaultLinkO' onClick={() => this.clickOnUserHours(data.info_id)}>{userFullName}</span>
            </div>

            <div className='hr_hoursBar_sub2'>
              <span>{data.functie_naam}</span>
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
              {data.rooster_werkdagen}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
              {hours.toLocaleString(Constants.getLang(), { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

      toRender.push(

        <div className='hr_hoursBar' style={{borderTop: '1px solid #EBEBEB', backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            {__('misc_total')}:
          </div>

          <div className='hr_hoursBar_sub2'>
            &nbsp;
          </div>

          <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
            {totalWorkDays}
          </div>

          <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold'}}>
            {Constants.round(totalHours, true)}
          </div>

        </div>

      );

    } else {

      for (const data of this.state.scheduleData) {

        if (typeof data.rooster_begin === 'undefined') {
          continue;
        }

        const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

        const planDate = Constants.stringToDate(data.rooster_datum);

        const afw = Constants.isEmpty(data.afwr_id) === false;

        toRender.push(

          <div className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

            <div className='hr_hoursBar_sub1'>
              <span>{userFullName} {afw === true ? <span style={{color: Colors.color.redFilledIn}}>{data.afw_afkorting}</span> : null}</span>
            </div>

            <div className='hr_hoursBar_sub2'>
              <span>{data.functie_naam}</span>
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold', justifyContent: 'flex-start'}}>
              {`${planDate.getDate()} ${Constants.getShortMonthNameM(planDate.getMonth()).toLowerCase()}`}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold', justifyContent: 'flex-start'}}>
              {data.rooster_begin.substr(0, 5)}
            </div>

            <div className='hr_hoursBar_sub3' style={{fontWeight: 'bold', justifyContent: 'flex-start'}}>
              {data.rooster_eind.substr(0, 5)}
            </div>

            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
              {data.rooster_pauze.substr(0, 5)}
            </div>

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toRender;

  }

  async clickOnUserHours(userID) {

    this.setState({
      reportDetailed: true,
      reportEmployee: userID,
      reportSort: Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true ? 'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC' : 'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC',
    }, async() => {

      await this.changeHoursDisplay(this.state.reportDisplay, this.state.reportDetailed, this.state.reportSort, Constants.dateToString(this.state.reportStart), Constants.dateToString(this.state.reportEnd));

    });

  }

  getEmployeeSelects() {

    let toRender = [];

    for (const acc of this.state.accounts) {
      const userFullName = `${acc.info_voornaam} ${(acc.info_tussenvoegsel !== null && acc.info_tussenvoegsel !== '') ? acc.info_tussenvoegsel + " " + acc.info_achternaam : acc.info_achternaam}`;
      toRender.push(<option value={acc.info_id} selected={this.state.reportEmployee === acc.info_id}>{userFullName}</option>)
    }

    return toRender;

  }

  getJobSelects() {

    let toRender = [];

    for (const job of this.state.jobs) {
      toRender.push(<option value={job.functie_id} selected={this.state.reportJob === job.functie_id}>{job.functie_naam}</option>)
    }

    return toRender;

  }

  onChangeNumberDataFloat(data, min, max, event, key) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (data === 'overhours') {

      let overhours = this.state.overhours;

      if (nr !== '') {
        overhours[key].ov_aantal = nr;
      } else {
        overhours[key].ov_aantal = '';
      }

      this.setState({ overhours: overhours });

    }

  }

  getOverhoursBars() {

    let toReturn = [];
    let count = 0;

    for (const key in this.state.overhoursObj) {

      const data = this.state.overhoursObj[key][0];

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;
      const disabled = typeof this.state.contracts[data.info_id] === 'undefined' || (this.state.contracts[data.info_id].contract_type !== '1' && this.state.contracts[data.info_id].contract_type !== '2');

      if (disabled === true) {
        continue;
      }

      let totalHours = 0;
      for (const dt in this.state.overhoursObj[key]) {
        const amount = Constants.round(parseFloat(this.state.overhoursObj[key][dt].ov_aantal));
        totalHours += amount;
      }

      toReturn.push(

        <div key={data.ov_id} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, width: '45%', height: '1.8vw'}}>

          <div className='hr_hoursBar_sub1'>
            {userFullName}
          </div>

          <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-end', flex: 0.5}}>
            {/* <input
              // ref={(ref) => this.timeRef[hours.rooster_id]=ref}
              className={'hr_inputBoxTime'}
              onChange={(event) => this.onChangeNumberDataFloat('overhours', -999999999, 999999999, event, key)}
              value={data.ov_aantal}
              placeholder={'Overuren'}
              type='number'
              step={0.01}
              disabled={disabled}
            /> */}
            <span>{Constants.round(totalHours, true)}</span>
          </div>

          <div className='hr_hoursBar_sub3'>
            {/* <div className='planner_new_addButton' style={{width: '9.5vw', height: '1.4vw'}} onClick={() => this.showOverhoursModal(true, data)}>Omzetten verlofuren</div> */}
            {/* <div className='planner_new_addButton' style={{width: '5.5vw', height: '1.4vw', marginLeft: '1vw'}}>Uitbetalen</div> */}
            &nbsp;
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toReturn;

  }

  // correctOverhoursAlert() {

  //   this.setState({
  //     alertTitle: 'Waarschuwing',
  //     alertBody: <span>Weet je zeker dat je de overuren van deze week wilt berekenen?<br /><br />Dit betekent dat je deze week afrondt en dat de overuren niet meer terug te draaien zijn, tenzij je deze zelf handmatig aanpast bij het tabje 'Overuren'.<br /><br /><br /><span style={{color: Colors.color.redFilledIn}}>Tip: doe dit aan het eind van de werkweek. Deze berekening kan maar EENMALIG per week worden gedaan.</span></span>,
  //     alertButtons: 2,
  //     alertButtonAction: this.correctOverhours.bind(this),
  //     alertButtonText: ['Annuleren', 'Overuren berekenen'],
  //     showAlert: true,
  //   });

  // }

  // async correctOverhours() {

  //   this.setState({ reloading: true });

  //   let hoursWorked = {}; //ID: -hours-

  //   // date of week
  //   for (const dateKey in this.state.schedule) {

  //     const schDate = this.state.schedule[dateKey];

  //     // schedules of date
  //     schLoop:
  //     for (const sch of schDate) {

  //       let realHoursFound = false;

  //       if (typeof hoursWorked[sch.info_id] === 'undefined') {
  //         hoursWorked[sch.info_id] = 0;
  //       }

  //       // date of hoursweek
  //       if (typeof this.state.hours[dateKey] !== 'undefined') {

  //         // hours of date
  //         for (const hoursKey in this.state.hours[dateKey]) {

  //           const hours = this.state.hours[dateKey][hoursKey];

  //           if (hours.info_id === sch.info_id) {

  //             hoursWorked[hours.info_id] += Constants.timeStringToFloat(hours.uren_eind) - Constants.timeStringToFloat(hours.uren_begin) - Constants.timeStringToFloat(hours.uren_pauze);
  //             realHoursFound = true;

  //           }

  //         }

  //       }

  //       // date of extra hoursweek
  //       if (typeof this.state.extraHours[dateKey] !== 'undefined') {

  //         // hours of date
  //         for (const hoursKey in this.state.extraHours[dateKey]) {

  //           const hours = this.state.extraHours[dateKey][hoursKey];

  //           if (hours.info_id === sch.info_id) {

  //             if (typeof hoursWorked[hours.info_id] === 'undefined') {
  //               hoursWorked[hours.info_id] = 0;
  //             }

  //             hoursWorked[hours.info_id] += Constants.timeStringToFloat(hours.uren_eind) - Constants.timeStringToFloat(hours.uren_begin) - Constants.timeStringToFloat(hours.uren_pauze);
  //             realHoursFound = true;

  //           }

  //         }

  //       }

  //       if (realHoursFound === false) { // take schedule data

  //         hoursWorked[sch.info_id] += Constants.timeStringToFloat(sch.rooster_eind) - Constants.timeStringToFloat(sch.rooster_begin) - Constants.timeStringToFloat(sch.rooster_pauze);

  //       }

  //     }

  //   }

  //   //CHECK HOURS WHICH ARE NOT ON SCHEDULE (CLOCKTIMES EXTRA)
  //   for (const dateKey in this.state.hours) {

  //     hoursLoop:
  //     for (const hoursKey in this.state.hours[dateKey]) {

  //       const hours = this.state.hours[dateKey][hoursKey];
  //       const schDate = this.state.schedule[dateKey];

  //       if (typeof schDate !== 'undefined') {

  //         for (const sch of schDate) {

  //           if (hours.info_id === sch.info_id) {
  //             continue hoursLoop;
  //           }

  //           if (typeof hoursWorked[hours.info_id] === 'undefined') {
  //             hoursWorked[hours.info_id] = 0;
  //           }

  //           hoursWorked[hours.info_id] += Constants.timeStringToFloat(hours.uren_eind) - Constants.timeStringToFloat(hours.uren_begin) - Constants.timeStringToFloat(hours.uren_pauze);

  //         }

  //       } else {

  //         if (typeof hoursWorked[hours.info_id] === 'undefined') {
  //           hoursWorked[hours.info_id] = 0;
  //         }

  //         hoursWorked[hours.info_id] += Constants.timeStringToFloat(hours.uren_eind) - Constants.timeStringToFloat(hours.uren_begin) - Constants.timeStringToFloat(hours.uren_pauze);

  //       }

  //     }

  //   }

  //   //CHECK EXTRA HOURS WHICH ARE NOT ON SCHEDULE
  //   for (const dateKey in this.state.extraHours) {

  //     hoursLoop:
  //     for (const hoursKey in this.state.extraHours[dateKey]) {

  //       const hours = this.state.extraHours[dateKey][hoursKey];
  //       const schDate = this.state.schedule[dateKey];

  //       if (Constants.isEmpty(hours.info_id) || Constants.isEmpty(hours.uren_eind) || Constants.isEmpty(hours.uren_pauze) || Constants.isEmpty(hours.info_id)) {
  //         continue;
  //       }

  //       if (typeof schDate !== 'undefined') {

  //         for (const sch of schDate) {

  //           if (hours.info_id === sch.info_id) {
  //             continue hoursLoop;
  //           }

  //           if (typeof hoursWorked[hours.info_id] === 'undefined') {
  //             hoursWorked[hours.info_id] = 0;
  //           }

  //           hoursWorked[hours.info_id] += Constants.timeStringToFloat(hours.uren_eind) - Constants.timeStringToFloat(hours.uren_begin) - Constants.timeStringToFloat(hours.uren_pauze);

  //         }

  //       } else {

  //         if (typeof hoursWorked[hours.info_id] === 'undefined') {
  //           hoursWorked[hours.info_id] = 0;
  //         }

  //         hoursWorked[hours.info_id] += Constants.timeStringToFloat(hours.uren_eind) - Constants.timeStringToFloat(hours.uren_begin) - Constants.timeStringToFloat(hours.uren_pauze);

  //       }

  //     }

  //   }

  //   let promises = [];

  //   // for (const keyID in hoursWorked) {

  //   //   const userHours = hoursWorked[keyID];
  //   //   const userContract = this.state.contracts[keyID];

  //   //   if (userHours === 0 || typeof userContract === 'undefined' || (userContract.contract_type !== '1' && userContract.contract_type !== '2') || Constants.isEmpty(userContract.contract_uren) || parseInt(userContract.contract_uren) <= 0) {
  //   //     continue;
  //   //   }

  //   //   const newOverhours = parseFloat(this.state.overhoursObj[keyID].ov_aantal) + (userHours - parseFloat(userContract.contract_uren));

  //   //   promises.push(APIEDIT.Request(`UPDATE Overuren`, `SET ov_aantal = ${newOverhours}`, `WHERE ov_info_id = ${keyID}`));

  //   // }

  //   for (const keyID in hoursWorked) {

  //     const userHours = hoursWorked[keyID];
  //     const userContract = this.state.contracts[keyID];

  //     if (userHours === 0 || typeof userContract === 'undefined' || (userContract.contract_type !== '1' && userContract.contract_type !== '2') || Constants.isEmpty(userContract.contract_uren) || parseInt(userContract.contract_uren) <= 0) {
  //       continue;
  //     }

  //     if (userContract.contract_type === '1') { // minmax

  //       const min = parseFloat(userContract.contract_min);
  //       const max = parseFloat(userContract.contract_max);

  //       let newOverhours = parseFloat(this.state.overhoursObj[keyID].ov_aantal);

  //       if (userHours < min) {
  //         newOverhours = parseFloat(this.state.overhoursObj[keyID].ov_aantal) + (userHours - min);
  //       } else if (userHours > max) {
  //         newOverhours = parseFloat(this.state.overhoursObj[keyID].ov_aantal) + (userHours - max);
  //       }

  //       promises.push(APIEDIT.Request(`UPDATE Overuren`, `SET ov_aantal = ${newOverhours}`, `WHERE ov_info_id = ${keyID}`));

  //     } else if (userContract.contract_type === '2') { // vaste uren

  //       const newOverhours = parseFloat(this.state.overhoursObj[keyID].ov_aantal) + (userHours - parseFloat(userContract.contract_uren));
  //       promises.push(APIEDIT.Request(`UPDATE Overuren`, `SET ov_aantal = ${newOverhours}`, `WHERE ov_info_id = ${keyID}`));

  //     }

  //   }

  //   await Promise.all(promises);

  //   await APIADD.Request(`INSERT INTO OverurenAfgerond`, `VALUES (NULL, ${Constants.getWeekNumber2(this.state.hoursDate).toString() + this.state.hoursDate.getFullYear().toString()})`);

  //   const overhoursCall = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Overuren.*`, `FROM Overuren`, `LEFT JOIN Info ON ov_info_id = info_id`, null, null, `ORDER BY info_voornaam, info_achternaam`);
  //   const overhoursCallObj = Constants.convertToObject(overhoursCall, 'info_id');

  //   const overhoursDone = await APIGET.Request(`SELECT *`, `FROM OverurenAfgerond`, null, null, null, `ORDER BY ova_id DESC LIMIT 53`);
  //   const overhoursDoneObj = Constants.convertToObject(overhoursDone, 'ova_weekjaar');

  //   this.setState({ reloading: false, overhours: overhoursCall, overhoursObj: overhoursCallObj, overhoursDone: overhoursDoneObj });

  // }

  // async saveOverhours() {

  //   this.setState({ reloading: true });

  //   let promises = [];

  //   for (const overhours of this.state.overhours) {

  //     if (overhours.ov_aantal === '') {
  //       promises.push(APIEDIT.Request(`UPDATE Overuren`, `SET ov_aantal = 0`, `WHERE ov_id = ${overhours.ov_id}`));
  //     } else if (isNaN(parseFloat(overhours.ov_aantal)) === false) {
  //       promises.push(APIEDIT.Request(`UPDATE Overuren`, `SET ov_aantal = ${Constants.round(parseFloat(overhours.ov_aantal))}`, `WHERE ov_id = ${overhours.ov_id}`));
  //     }

  //   }

  //   await Promise.all(promises);

  //   const overhoursCall = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Overuren.*`, `FROM Overuren`, `LEFT JOIN Info ON ov_info_id = info_id`, null, null, `ORDER BY info_voornaam, info_achternaam`);
  //   const overhoursCallObj = Constants.convertToObject(overhoursCall, 'info_id');

  //   this.setState({ reloading: false, overhours: overhoursCall, overhoursObj: overhoursCallObj });

  // }

  render() {

    // const hourPlannerBars = this.getHourPlannerBars();
    // const hourPlannerBarsClock = this.getHourPlannerBarsClock();

    //const overhoursDoneDisabled = typeof this.state.overhoursDone[Constants.getWeekNumber2(this.state.hoursDate).toString() + this.state.hoursDate.getFullYear().toString()] !== 'undefined';

    if (this.onlyReport === false) {

      return (

        <div className='hoursRegistration' onClick={(e) => this.closeAllPlanMenusOutside(e)}>

        {this.state.reloading === true ?       
          <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
        : null}

        <Navbar ref={this.navBar} history={this.props.history} active={'hour_registration'} fixed loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'hours'} parent={this} />

        <Prompt
          when={this.state.changes}
          message='You have unsaved changes. Are you sure you want to leave?'
        />

        {this.state.showAlert === true ?
              
          <BetterAlert 
            _closeAlert={this.closeAlert.bind(this)}
            _title={this.state.alertTitle}
            _body={this.state.alertBody}
            _buttons={this.state.alertButtons}
            _buttonText={this.state.alertButtonText}
            _buttonAction={this.state.alertButtonAction}
          />

        :

          null
            
        }

        {this.state.showOverhoursModal === true ?
              
          <OverhoursModal 
            _closeModal={this.closeOverhoursModal.bind(this)}
            _modalData={this.state.overhoursModalData}
          />

        :

          null
            
        }

        {/* <div className='planner_nav2 fixedNav2'>

          <div className='planner_nav2_sub2'>
      
          </div> 
            <div className='planner_nav2_sub3' style={{flex: 2}}>
                Tijdregistratie
            </div>
          <div className='schedule_nav2_sub4'>

          </div>

        </div> */}

        <div className='employees_main2'>
          {/* stickyTopMenuPanel */}
          {/* <div className='employees_menu2'>

            <div className='employees_menuTitle'>
              <div>Menu</div>
            </div>

            {Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?
              <div className={`employees_menuButton ${this.state.menu === 'hours' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('hours')}>
                <div>Tijdregistratie</div>
              </div>
            : null}

            {Constants.isTrue(Data.data.appOptions.opties_overuren) ?
              <div className={`employees_menuButton ${this.state.menu === 'overhours' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('overhours')}> 
                <div>Overuren</div>
              </div>
            : null}

            <div className={`employees_menuButton ${this.state.menu === 'report' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('report')}>
              <div>Rapportage</div>
            </div>

          </div> */}

          {this.state.menu === 'hours' ?
            <div className='employees_menu2'>

              <div className='employees_menuTitle'>
                <div>{__('misc_week_c')} {Constants.getWeekNumber2(this.state.hoursDate)}</div>
              </div>

              {this.state.loading === false ?

                <div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.mondayDate.getDate() && this.state.hoursDate.getMonth() === this.state.mondayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.mondayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.mondayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_monday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.mondayDate.getDate() < 10 ? '0' + this.state.mondayDate.getDate() : this.state.mondayDate.getDate()}-{(this.state.mondayDate.getMonth() + 1) < 10 ? '0' + (this.state.mondayDate.getMonth() + 1) : (this.state.mondayDate.getMonth() + 1)}</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.tuesdayDate.getDate() && this.state.hoursDate.getMonth() === this.state.tuesdayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.tuesdayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.tuesdayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_tuesday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.tuesdayDate.getDate() < 10 ? '0' + this.state.tuesdayDate.getDate() : this.state.tuesdayDate.getDate()}-{(this.state.tuesdayDate.getMonth() + 1) < 10 ? '0' + (this.state.tuesdayDate.getMonth() + 1) : (this.state.tuesdayDate.getMonth() + 1)}</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.wednesdayDate.getDate() && this.state.hoursDate.getMonth() === this.state.wednesdayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.wednesdayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.wednesdayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_wednesday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.wednesdayDate.getDate() < 10 ? '0' + this.state.wednesdayDate.getDate() : this.state.wednesdayDate.getDate()}-{(this.state.wednesdayDate.getMonth() + 1) < 10 ? '0' + (this.state.wednesdayDate.getMonth() + 1) : (this.state.wednesdayDate.getMonth() + 1)}</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.thursdayDate.getDate() && this.state.hoursDate.getMonth() === this.state.thursdayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.thursdayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.thursdayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_thursday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.thursdayDate.getDate() < 10 ? '0' + this.state.thursdayDate.getDate() : this.state.thursdayDate.getDate()}-{(this.state.thursdayDate.getMonth() + 1) < 10 ? '0' + (this.state.thursdayDate.getMonth() + 1) : (this.state.thursdayDate.getMonth() + 1)}</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.fridayDate.getDate() && this.state.hoursDate.getMonth() === this.state.fridayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.fridayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.fridayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_friday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.fridayDate.getDate() < 10 ? '0' + this.state.fridayDate.getDate() : this.state.fridayDate.getDate()}-{(this.state.fridayDate.getMonth() + 1) < 10 ? '0' + (this.state.fridayDate.getMonth() + 1) : (this.state.fridayDate.getMonth() + 1)}</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.saturdayDate.getDate() && this.state.hoursDate.getMonth() === this.state.saturdayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.saturdayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.saturdayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_saturday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.saturdayDate.getDate() < 10 ? '0' + this.state.saturdayDate.getDate() : this.state.saturdayDate.getDate()}-{(this.state.saturdayDate.getMonth() + 1) < 10 ? '0' + (this.state.saturdayDate.getMonth() + 1) : (this.state.saturdayDate.getMonth() + 1)}</div>
                  </div>

                  <div className={`employees_menuButton ${this.state.hoursDate.getDate() === this.state.sundayDate.getDate() && this.state.hoursDate.getMonth() === this.state.sundayDate.getMonth() && this.state.hoursDate.getFullYear() === this.state.sundayDate.getFullYear() ? 'activeAv' : ''}`} onClick={() => this.selectHoursDateMenu(this.state.sundayDate)}>
                    <div className='employees_menuButton_sub1'>{__('misc_sunday')}</div> 
                    <div className='employees_menuButton_sub1'>{this.state.sundayDate.getDate() < 10 ? '0' + this.state.sundayDate.getDate() : this.state.sundayDate.getDate()}-{(this.state.sundayDate.getMonth() + 1) < 10 ? '0' + (this.state.sundayDate.getMonth() + 1) : (this.state.sundayDate.getMonth() + 1)}</div>
                  </div>

                </div>

              : null}

            </div>
          : null}

          {/* {this.state.selectedMonth !== null ?

            <div className='employees_menu'>

              <div className='employees_menuTitle'>
                <div>Week</div>
              </div>

              <div className={`employees_menuButton ${this.state.selectedWeek === null ? 'activeAv' : ''}`} onClick={() => this.selectWeek(null)}>
                <div>Hele maand</div>
              </div>
          
              {this.renderWeekNumbers()}

            </div>

          : null} */}

          {/* 'calc(74% - 0px)' : 'calc(87% - 0px)' */}
          <div className='employees_content2' style={{width: this.state.menu === 'hours' ? 'calc(87% - 0px)' : '100%', fontSize: '0.8vw'}}>

            {this.state.loading === true ?
        
                        <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

            :

              <div className='employees_container'>

                {this.state.menu === 'hours' ?

                  <div>
              
                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>{__('misc_timeRegistration')}</div>

                    {/* {Constants.isTrue(Data.data.appOptions.opties_overuren) ?
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '1vw'}}>
                        <span>Overuren week {Constants.getWeekNumber2(this.state.hoursDate)} berekenen</span>
                        <div className='planner_new_availableButton' style={{width: '7.5vw', opacity: overhoursDoneDisabled ? 0.5 : 1, pointerEvents: overhoursDoneDisabled ? 'none' : null}} onClick={overhoursDoneDisabled ? null : () => this.correctOverhoursAlert()}>Week afronden</div>
                      </div>
                    : null} */}

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw', height: '1.8vw'}}>

                      <div className='planner_new_publishButton2' style={{marginLeft: 0}} onClick={() => this.acceptAllHours()}>{this.state.accepted === true ? __('button_reapprove') : __('button_approve')}</div>

                      {this.state.accepted === false ?
                        <div className='planner_new_availableButton2' style={{marginLeft: '0.6vw'}} onClick={() => this.saveWorkedHours()}>{__('button_save')}</div>
                      : null}

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.hoursDate}
                          onChange={(date) => this.selectHoursDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={__('select_date')}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                          highlightDates={this.state.highlightDates}
                          show
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      <div style={{marginLeft: '0.1%'}}>
                        {this.state.accepted === true ?
                          <span style={{color: Colors.color.greenFilledIn, fontSize: '0.7vw'}}>{__('time_fullyApproved')}</span>
                        :
                          <span style={{color: Colors.color.redFilledIn, fontSize: '0.7vw'}}>{__('time_notFullyApproved')}</span>
                        }
                      </div>

                    </div>

                    <div className='hr_timeRow_title'>

                      <div className='hr_timeRow_sub1'>{__('misc_name')}</div>

                      <div className='hr_timeRow_sub2'>{__('misc_plannedStartTime')}</div>
                      <div className='hr_timeRow_sub2'>{__('misc_plannedEndTime')}</div>
                      <div className='hr_timeRow_sub2'>{__('misc_plannedBreak')}</div>

                      <div className='hr_timeRow_sub2' style={{flex: 2}}>{__('misc_actualStartTime')}</div>
                      <div className='hr_timeRow_sub2' style={{flex: 0.2}}>&nbsp;</div>
                      <div className='hr_timeRow_sub2' style={{flex: 2}}>{__('misc_actualEndTime')}</div>
                      <div className='hr_timeRow_sub2' style={{flex: 0.2}}>&nbsp;</div>
                      <div className='hr_timeRow_sub2'>{__('misc_actualBreak')}</div>

                      <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.5}}>&nbsp;</div>

                      <div className='hr_timeRow_sub3' style={{justifyContent: 'flex-start', flex: 0.2}}>&nbsp;</div>

                      <div className='hr_timeRow_sub3'>&nbsp;</div>

                    </div>

                    {Data.data.appOptions.hours === 'CLOCK' ?
                      this.getHoursBarsClock()
                    :
                    Data.data.appOptions.hours === 'PLANNER' ?
                      this.getHoursBars()
                    : 
                      null
                    }

                    {Data.data.appOptions.hours === 'CLOCK' ?
                      this.getScheduleClockBars()
                    : null}

                    {this.state.extraHours[this.state.hoursDateString].length > 0 ?

                      <div>

                        <div className='hr_title' style={{marginTop: '1%', marginBottom: '0.5%'}}>{__('time_extraTimeRegistrations')}</div>
                  
                        {this.getExtraHoursBars()}

                      </div>

                    : null}

                    <div className="planner_addButtons_container2" style={{ marginTop: '2%' }}>

                      <div className='planner_new_addButton2' style={{marginLeft: 0}} onClick={() => this.addExtraHours()}>{__('time_button_addTime')}</div>

                    </div>

                    {/* <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      {Data.data.appOptions.hours === 'CLOCK' ?
                        this.state.showPlanner === true ?
                          <div className='reports_toggleButton_active' onClick={() => this.setState({ showPlanner: !this.state.showPlanner })}>
                            <i className='far fa-check' />
                            <span>Dagplanning weergeven</span>
                          </div>
                        :
                          <div className='reports_toggleButton' onClick={() => this.setState({ showPlanner: !this.state.showPlanner })}>
                            <span>Dagplanning weergeven</span>
                          </div>
                      : null}

                    </div>

                    {Data.data.appOptions.hours === 'CLOCK' && this.state.showPlanner === true ?
                      <div style={{marginTop: '0'}}>

                        <div style={{fontSize: '1vw', fontWeight: 'bold'}}>Dagplanning</div>

                        <div className='hr_timeRow_title'>

                          <div className='hr_timeRow_sub1'>Naam</div>

                          <div className='hr_timeRow_sub2'>Geplande begintijd</div>
                          <div className='hr_timeRow_sub2'>Geplande eindtijd</div>
                          <div className='hr_timeRow_sub2'>Geplande pauze</div>

                          <div className='hr_timeRow_sub2'>Tijd geregistreerd</div>

                          <div className='hr_timeRow_sub3'>Acties</div>

                        </div>
                    
                        {this.getScheduleClockBars()}

                      </div>
                    : null} */}

                  </div>

                :
                this.state.menu === 'report' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Tijdregistratie rapportage</div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div style={{width: '8%'}}>
                        <select
                          name="Selecteer een optie"
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeReportDisplay(event)}
                        >
                          <option value={'null'} disabled>Weergave</option>
                          <option value={'period'} selected={this.state.reportDisplay === 'period'}>Periode</option>
                          <option value={'day'} selected={this.state.reportDisplay === 'day'}>Dag</option>
                        </select>
                      </div>

                      <div style={{width: '16%', marginLeft: '0.6vw'}}>
                        <select
                          name="Selecteer een medewerker"
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeReportEmployee(event)}
                        >
                          <option value={'null'} disabled>Medewerker</option>
                          <option value={'all'} selected={this.state.reportEmployee === 'all'}>Alle medewerkers</option>
                          <option value={'null'} disabled>&nbsp;</option>
                          {this.getEmployeeSelects()}
                        </select>
                      </div>

                      <div style={{width: '10%', marginLeft: '0.6vw'}}>
                        <select
                          name="Selecteer een functie"
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeReportJob(event)}
                        >
                          <option value={'null'} disabled>Functie</option>
                          <option value={'all'} selected={this.state.reportJob === 'all'}>Alle functies</option>
                          {this.getJobSelects()}
                        </select>
                      </div>

                      {this.state.reportDetailed === false ?

                        Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                          <div style={{width: '12%', marginLeft: '0.6vw'}}>
                            <select
                              name="Selecteer een optie"
                              className={'hr_dropdown'}
                              onChange={(event) => this.changeReportSort(event)}
                            >
                              <option value={'null'} disabled>Sorteren op</option>
                              <option 
                                value={'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC'}
                              >Naam oplopend</option>
                              <option 
                                value={'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC'}
                              >Naam aflopend</option>
                              <option 
                                value={'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC'}
                              >Uren oplopend</option>
                              <option 
                                value={'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC'}
                              >Uren aflopend</option>
                            </select>
                          </div>

                        :

                          <div style={{width: '12%', marginLeft: '0.6vw'}}>
                            <select
                              name="Selecteer een optie"
                              className={'hr_dropdown'}
                              onChange={(event) => this.changeReportSort(event)}
                            >
                              <option value={'null'} disabled>Sorteren op</option>
                              <option 
                                value={'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC'}
                              >Naam oplopend</option>
                              <option 
                                value={'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC'}
                              >Naam aflopend</option>
                              <option 
                                value={'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC'}
                              >Uren oplopend</option>
                              <option 
                                value={'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC'}
                              >Uren aflopend</option>
                            </select>
                          </div>

                      :
                      this.state.reportDetailed === true ?

                        Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                          <div style={{width: '12%', marginLeft: '0.6vw'}}>
                            <select
                              name="Selecteer een optie"
                              className={'hr_dropdown'}
                              onChange={(event) => this.changeReportSort(event)}
                            >
                              <option value={'null'} disabled>Sorteren op</option>
                              <option 
                                value={'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC'}
                              >Naam oplopend</option>
                              <option 
                                value={'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_begindatum ASC, uren_begin ASC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_begindatum ASC, uren_begin ASC'}
                              >Naam aflopend</option>
                              <option 
                                value={'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC'}
                              >Datum oplopend</option>
                              <option 
                                value={'ORDER BY uren_begindatum DESC, uren_begin DESC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY uren_begindatum DESC, uren_begin DESC, info_voornaam ASC, info_achternaam ASC'}
                              >Datum aflopend</option>
                            </select>
                          </div>

                        :

                          <div style={{width: '12%', marginLeft: '0.6vw'}}>
                            <select
                              name="Selecteer een optie"
                              className={'hr_dropdown'}
                              onChange={(event) => this.changeReportSort(event)}
                            >
                              <option value={'null'} disabled>Sorteren op</option>
                              <option 
                                value={'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC'}
                              >Naam oplopend</option>
                              <option 
                                value={'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_datum ASC, rooster_begin ASC'} 
                                selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_datum ASC, rooster_begin ASC'}
                              >Naam aflopend</option>
                              <option 
                                value={'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC'}
                              >Datum oplopend</option>
                              <option 
                                value={'ORDER BY rooster_datum DESC, rooster_begin DESC, info_voornaam ASC, info_achternaam ASC'} 
                                selected={this.state.reportSort === 'ORDER BY rooster_datum DESC, rooster_begin DESC, info_voornaam ASC, info_achternaam ASC'}
                              >Datum aflopend</option>
                            </select>
                          </div>

                      :

                        null

                      }

                      <div className='planner_new_availableButton' style={{width: '11vw', marginLeft: '0.6vw'}} onClick={() => this.changeHoursDisplay(this.state.reportDisplay, this.state.reportDetailed, this.state.reportSort, Constants.dateToString(this.state.reportStart), Constants.dateToString(this.state.reportEnd))}>Rapportage weergeven</div>

                      <HoursDownload dataSet={Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? this.state.reportData : this.state.scheduleData} detailed={this.reportDetailed} start={this.state.reportStart} end={this.state.reportDisplay === 'day' ? null : this.state.reportEnd} bonus={this.state.bonus} contracts={this.state.contracts} />

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                      <div style={{width: '12%'}}>
                        <select
                          name="Selecteer een optie"
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeReportDetailed(event)}
                        >
                          <option value={'null'} disabled>Gedetailleerd</option>
                          <option value={'false'} selected={this.state.reportDetailed === false}>Totaal</option>
                          <option value={'true'} selected={this.state.reportDetailed === true}>Per werkdag</option>
                        </select>
                      </div>

                      <div style={{width: '18%', marginLeft: '0.6vw'}}>
                        <select
                          name="Selecteer een optie"
                          className={'hr_dropdown'}
                          onChange={(event) => this.changeReportContract(event)}
                        >
                          <option value={'null'} disabled>Selecteer een contract</option>
                          <option 
                            value={'null'} 
                            selected={this.state.reportContract === null}
                          >Alle contracten</option>
                          <option 
                            value={'10'} 
                            selected={this.state.reportContract === '10'}
                          >Flex contract {`&`} min-max contract</option>
                          <option 
                            value={'0'} 
                            selected={this.state.reportContract === '0'}
                          >Flex contract (oproep, nuluren, flex)</option>
                          <option 
                            value={'1'} 
                            selected={this.state.reportContract === '1'}
                          >Min-max contract</option>
                          <option 
                            value={'2'} 
                            selected={this.state.reportContract === '2'}
                          >Vaste uren contract</option>
                        </select>
                      </div>

                      {this.state.bonus.length > 0 ?
                        <div style={{width: '14%', marginLeft: '0.6vw'}}>
                          <select
                            name="Selecteer een optie"
                            className={'hr_dropdown'}
                            onChange={(event) => this.changeReportBonus(event)}
                          >
                            <option value={'null'} disabled>Toeslagen</option>
                            <option 
                              value={'0'} 
                              selected={this.state.reportBonus === false}
                            >Exclusief toeslaguren</option>
                            <option 
                              value={'1'} 
                              selected={this.state.reportBonus === true}
                            >Inclusief toeslaguren</option>
                          </select>
                        </div>
                      : null}

                    </div>

                    <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                      <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                        <ReactDatePicker
                          selected={this.state.reportStart}
                          onChange={(date) => this.selectReportStartDate(date)}
                          dateFormat="dd-MM-yyyy"
                          className={'hr_inputBoxTime_big'}
                          placeholderText={'Startdatum'}
                          showWeekNumbers
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          locale={nl}
                    
                        />

                        {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                      </div>

                      {this.state.reportDisplay === 'period' ?
                        <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>
                      : null}

                      {this.state.reportDisplay === 'period' ?
                        <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                          <ReactDatePicker
                            selected={this.state.reportEnd}
                            onChange={(date) => this.selectReportEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={'hr_inputBoxTime_big'}
                            placeholderText={'Einddatum'}
                            showWeekNumbers
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale={nl}
                          />

                          {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                        </div>
                      : null}

                    </div>

                    <div className='hr_hoursContainer'>

                      {this.reportDetailed === false ?

                        Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                          <div className='hr_hoursBar_title'>

                            <div className='hr_hoursBar_sub1'>
                              Naam
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              Functie
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              Geplande werkdagen
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              Geplande uren
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              Daadwerkelijke werkdagen
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              Daadwerkelijke uren
                            </div>

                          </div>

                        :

                          <div className='hr_hoursBar_title'>

                            <div className='hr_hoursBar_sub1'>
                              Naam
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              Functie
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              Werkdagen
                            </div>

                            <div className='hr_hoursBar_sub3'>
                              Uren
                            </div>

                          </div>

                      :

                        Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                          <div className='hr_hoursBar_title'>

                            <div className='hr_hoursBar_sub1'>
                              Naam
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              Functie
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Geplande datum
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Geplande begintijd
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Geplande eindtijd
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Begin
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Eind
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Pauze
                            </div>

                          </div>

                        :

                          <div className='hr_hoursBar_title'>

                            <div className='hr_hoursBar_sub1'>
                              Naam
                            </div>

                            <div className='hr_hoursBar_sub2'>
                              Functie
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Roosterdatum
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Begintijd
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Eindtijd
                            </div>

                            <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                              Pauze
                            </div>

                          </div>

                      }

                      {Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?
                        this.renderHourBars()
                      :
                        this.renderHourBarsPlanner()
                      }

                    </div>

                  </div>

                :
                this.state.menu === 'overhours' ?

                  <div>

                    <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Plus- en minuren</div>
  {/* 
                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw', width: '61%'}}>

                      <div className='planner_new_publishButton' style={{width: '5vw', position: 'absolute', right: 0}} onClick={() => this.saveOverhours()}>Opslaan</div>

                    </div> */}

                    <div style={{marginTop: '1vw'}}>&nbsp;</div>

                    {this.getOverhoursBars()}

                  </div>

                :

                  null

                }

              </div>

            }

          </div>

        </div>

      </div>
      );

    } else {

      if (this.props.individual !== true) {

        return (

          this.state.loading === false ?

            <div>

              {this.state.reloading === true ?       
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
              : null}

              {this.state.showAlert === true ?
                  
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
                
              }

              <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                <div style={{width: '8%'}}>
                  <select
                    name={__('select_option')}
                    className={'hr_dropdown'}
                    onChange={(event) => this.changeReportDisplay(event)}
                  >
                    <option value={'null'} disabled>{__('misc_display')}</option>
                    <option value={'period'} selected={this.state.reportDisplay === 'period'}>{__('misc_period')}</option>
                    <option value={'day'} selected={this.state.reportDisplay === 'day'}>{__('misc_day_c')}</option>
                  </select>
                </div>

                <div style={{width: '16%', marginLeft: '0.6vw'}}>
                  <select
                    name={__('select_employee')}
                    className={'hr_dropdown'}
                    onChange={(event) => this.changeReportEmployee(event)}
                  >
                    <option value={'null'} disabled>{__('misc_employee')}</option>
                    <option value={'all'} selected={this.state.reportEmployee === 'all'}>{__('select_employee_all')}</option>
                    <option value={'null'} disabled>&nbsp;</option>
                    {this.getEmployeeSelects()}
                  </select>
                </div>

                <div style={{width: '10%', marginLeft: '0.6vw'}}>
                  <select
                    name={__('select_job')}
                    className={'hr_dropdown'}
                    onChange={(event) => this.changeReportJob(event)}
                  >
                    <option value={'null'} disabled>{__('misc_job')}</option>
                    <option value={'all'} selected={this.state.reportJob === 'all'}>{__('select_job_all')}</option>
                    {this.getJobSelects()}
                  </select>
                </div>

                {this.state.reportDetailed === false ?

                  Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                    <div style={{width: '12%', marginLeft: '0.6vw'}}>
                      <select
                        name={__('select_sort')}
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>{__('select_sort')}</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_asc')}</option>
                        <option 
                          value={'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_desc')}</option>
                      </select>
                    </div>

                  :

                    <div style={{width: '12%', marginLeft: '0.6vw'}}>
                      <select
                        name={__('select_sort')}
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>{__('select_sort')}</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_asc')}</option>
                        <option 
                          value={'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_desc')}</option>
                      </select>
                    </div>

                :
                this.state.reportDetailed === true ?

                  Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                    <div style={{width: '12%', marginLeft: '0.6vw'}}>
                      <select
                        name={__('select_sort')}
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>{__('select_sort')}</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_begindatum ASC, uren_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_begindatum ASC, uren_begin ASC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_asc')}</option>
                        <option 
                          value={'ORDER BY uren_begindatum DESC, uren_begin DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_begindatum DESC, uren_begin DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_desc')}</option>
                      </select>
                    </div>

                  :

                    <div style={{width: '12%', marginLeft: '0.6vw'}}>
                      <select
                        name={__('select_sort')}
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>{__('select_sort')}</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_datum ASC, rooster_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_datum ASC, rooster_begin ASC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_asc')}</option>
                        <option 
                          value={'ORDER BY rooster_datum DESC, rooster_begin DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_datum DESC, rooster_begin DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_desc')}</option>
                      </select>
                    </div>

                :

                  null

                }

                <div className='planner_new_availableButton2' style={{marginLeft: '0.6vw'}} onClick={() => this.changeHoursDisplay(this.state.reportDisplay, this.state.reportDetailed, this.state.reportSort, Constants.dateToString(this.state.reportStart), Constants.dateToString(this.state.reportEnd))}>{__('button_show')}</div>

                <HoursDownload dataSet={Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? this.state.reportData : this.state.scheduleData} detailed={this.reportDetailed} start={this.state.reportStart} end={this.state.reportDisplay === 'day' ? null : this.state.reportEnd} bonus={this.state.bonus} incBonus={this.state.reportBonus} contracts={this.state.contracts} />

              </div>

              <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                <div style={{width: '12%'}}>
                  <select
                    name={__('select_option')}
                    className={'hr_dropdown'}
                    onChange={(event) => this.changeReportDetailed(event)}
                  >
                    <option value={'null'} disabled>{__('misc_detailed')}</option>
                    <option value={'false'} selected={this.state.reportDetailed === false}>{__('misc_total')}</option>
                    <option value={'true'} selected={this.state.reportDetailed === true}>{__('misc_eachWorkingDay')}</option>
                  </select>
                </div>

                <div style={{width: '18%', marginLeft: '0.6vw'}}>
                  <select
                    name={__('select_contract')}
                    className={'hr_dropdown'}
                    onChange={(event) => this.changeReportContract(event)}
                  >
                    <option value={'null'} disabled>{__('select_contract')}</option>
                    <option 
                      value={'null'} 
                      selected={this.state.reportContract === null}
                    >{__('select_contract_all')}</option>
                    <option 
                      value={'10'} 
                      selected={this.state.reportContract === '10'}
                    >{__('select_contract_flexMinMax')}</option>
                    <option 
                      value={'0'} 
                      selected={this.state.reportContract === '0'}
                    >{__('select_contract_flex')}</option>
                    <option 
                      value={'1'} 
                      selected={this.state.reportContract === '1'}
                    >{__('select_contract_minMax')}</option>
                    <option 
                      value={'2'} 
                      selected={this.state.reportContract === '2'}
                    >{__('select_contract_fixed')}</option>
                  </select>
                </div>

                {this.state.bonus.length > 0 ?
                  <div style={{width: '14%', marginLeft: '0.6vw'}}>
                    <select
                      name={__('select_option')}
                      className={'hr_dropdown'}
                      onChange={(event) => this.changeReportBonus(event)}
                    >
                      <option value={'null'} disabled>{__('misc_surcharges')}</option>
                      <option 
                        value={'0'} 
                        selected={this.state.reportBonus === false}
                      >{__('select_surcharge_exclusive')}</option>
                      <option 
                        value={'1'} 
                        selected={this.state.reportBonus === true}
                      >{__('select_surcharge_inclusive')}</option>
                    </select>
                  </div>
                : null}

              </div>

              <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                  <ReactDatePicker
                    selected={this.state.reportStart}
                    onChange={(date) => this.selectReportStartDate(date)}
                    dateFormat="dd-MM-yyyy"
                    className={'hr_inputBoxTime_big'}
                    placeholderText={__('misc_startDate')}
                    showWeekNumbers
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    locale={nl}
                      
                  />

                  {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                </div>

                {this.state.reportDisplay === 'period' ?
                  <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>
                : null}

                {this.state.reportDisplay === 'period' ?
                  <div className='planner_date_container' style={{position: 'relative', width: '10%'}}>

                    <ReactDatePicker
                      selected={this.state.reportEnd}
                      onChange={(date) => this.selectReportEndDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className={'hr_inputBoxTime_big'}
                      placeholderText={__('misc_endDate')}
                      showWeekNumbers
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      locale={nl}
                    />

                    {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                  </div>
                : null}

              </div>

              <div className='hr_hoursContainer'>

                {this.reportDetailed === false ?

                  Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_job')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        {__('misc_plannedWorkingDays')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        {__('misc_plannedHours')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                      {__('misc_actualWorkingDays')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                      {__('misc_surcharge')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        {__('misc_actualHours')}
                      </div>

                    </div>

                  :

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_job')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        {__('misc_workingDays')}
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        {__('misc_hours_c')}
                      </div>

                    </div>

                :

                  Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_job')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_plannedDate')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_plannedStartTime')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_plannedEndTime')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_start')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_end')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_break')}
                      </div>

                    </div>

                  :

                    <div className='hr_hoursBar_title'>

                      <div className='hr_hoursBar_sub1'>
                        {__('misc_name')}
                      </div>

                      <div className='hr_hoursBar_sub2'>
                        {__('misc_job')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_scheduleDate')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_startTime')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_endTime')}
                      </div>

                      <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                        {__('misc_break')}
                      </div>

                    </div>

                }

                {Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?
                  this.renderHourBars()
                :
                  this.renderHourBarsPlanner()
                }

              </div>

            </div>

          :

                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

        );

      } else {

        return (

          this.state.loading === false ?

            <div>

              {this.state.reloading === true ?       
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>
              : null}

              {this.state.showAlert === true ?
                  
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
                
              }

              <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                {this.state.reportDetailed === false && this.props.individual !== true ?

                  Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                    <div style={{width: '20%'}}>
                      <select
                        name={__('select_sort')}
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>{__('select_sort')}</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_netto DESC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_netto DESC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_netto ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_asc')}</option>
                        <option 
                          value={'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_netto DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_desc')}</option>
                      </select>
                    </div>

                  :

                    <div style={{width: '20%'}}>
                      <select
                        name="Selecteer een optie"
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>Sorteren op</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_netto DESC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_netto DESC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_netto ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_asc')}</option>
                        <option 
                          value={'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_netto DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_hours_desc')}</option>
                      </select>
                    </div>

                :
                this.state.reportDetailed === true || this.props.individual === true ?

                  Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                    <div style={{width: '20%'}}>
                      <select
                        name="Selecteer een optie"
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>Sorteren op</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, uren_begindatum ASC, uren_begin ASC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_begindatum ASC, uren_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, uren_begindatum ASC, uren_begin ASC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_begindatum ASC, uren_begin ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_asc')}</option>
                        <option 
                          value={'ORDER BY uren_begindatum DESC, uren_begin DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY uren_begindatum DESC, uren_begin DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_desc')}</option>
                      </select>
                    </div>

                  :

                    <div style={{width: '20%'}}>
                      <select
                        name="Selecteer een optie"
                        className={'hr_dropdown'}
                        onChange={(event) => this.changeReportSort(event)}
                      >
                        <option value={'null'} disabled>Sorteren op</option>
                        <option 
                          value={'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam ASC, info_achternaam ASC, rooster_datum ASC, rooster_begin ASC'}
                        >{__('select_sort_name_asc')}</option>
                        <option 
                          value={'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_datum ASC, rooster_begin ASC'} 
                          selected={this.state.reportSort === 'ORDER BY info_voornaam DESC, info_achternaam DESC, rooster_datum ASC, rooster_begin ASC'}
                        >{__('select_sort_name_desc')}</option>
                        <option 
                          value={'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_datum ASC, rooster_begin ASC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_asc')}</option>
                        <option 
                          value={'ORDER BY rooster_datum DESC, rooster_begin DESC, info_voornaam ASC, info_achternaam ASC'} 
                          selected={this.state.reportSort === 'ORDER BY rooster_datum DESC, rooster_begin DESC, info_voornaam ASC, info_achternaam ASC'}
                        >{__('select_sort_date_desc')}</option>
                      </select>
                    </div>

                :

                  null

                }

                {this.state.bonus.length > 0 ?
                  <div style={{width: '18%', marginLeft: '0.6vw'}}>
                    <select
                      name={__('select_option')}
                      className={'hr_dropdown'}
                      onChange={(event) => this.changeReportBonus(event)}
                    >
                      <option value={'null'} disabled>{__('misc_surcharges')}</option>
                      <option 
                        value={'0'} 
                        selected={this.state.reportBonus === false}
                      >{__('select_surcharge_exclusive')}</option>
                      <option 
                        value={'1'} 
                        selected={this.state.reportBonus === true}
                      >{__('select_surcharge_inclusive')}</option>
                    </select>
                  </div>
                : null}

                <div className='planner_new_availableButton2' style={{marginLeft: '0.6vw'}} onClick={() => this.changeHoursDisplay(this.state.reportDisplay, this.state.reportDetailed, this.state.reportSort, Constants.dateToString(this.state.reportStart), Constants.dateToString(this.state.reportEnd))}>{__('button_show')}</div>

                <HoursDownload dataSet={Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ? this.state.reportData : this.state.scheduleData} detailed={this.reportDetailed || this.props.individual === true} start={this.state.reportStart} end={this.state.reportDisplay === 'day' ? null : this.state.reportEnd} bonus={this.state.bonus} incBonus={this.state.reportBonus} contracts={this.state.contracts} />

              </div>

              <div className="planner_addButtons_container" style={{marginLeft: 0}}>

                <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                  <ReactDatePicker
                    selected={this.state.reportStart}
                    onChange={(date) => this.selectReportStartDate(date)}
                    dateFormat="dd-MM-yyyy"
                    className={'hr_inputBoxTime_big'}
                    placeholderText={__('misc_startDate')}
                    showWeekNumbers
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    locale={nl}
                      
                  />

                  {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                </div>

                {this.state.reportDisplay === 'period' ?
                  <span style={{marginRight: 'calc(1.4vw - 2px)'}}>-</span>
                : null}

                {this.state.reportDisplay === 'period' ?
                  <div className='planner_date_container' style={{position: 'relative', width: '15%'}}>

                    <ReactDatePicker
                      selected={this.state.reportEnd}
                      onChange={(date) => this.selectReportEndDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className={'hr_inputBoxTime_big'}
                      placeholderText={__('misc_endDate')}
                      showWeekNumbers
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      locale={nl}
                    />

                    {/* <i className='fas fa-calendar-week hr_inputBox_icon' /> */}

                  </div>
                : null}

              </div>

              <div className='hr_hoursContainer'>

                {Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?

                  <div className='hr_hoursBar_title'>

                    <div className='hr_hoursBar_sub1'>
                      {__('misc_name')}
                    </div>

                    <div className='hr_hoursBar_sub2'>
                      {__('misc_job')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_plannedDate')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_plannedStartTime')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_plannedEndTime')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_start')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_end')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_break')}
                    </div>

                  </div>

                :

                  <div className='hr_hoursBar_title'>

                    <div className='hr_hoursBar_sub1'>
                      {__('misc_name')}
                    </div>

                    <div className='hr_hoursBar_sub2'>
                      {__('misc_job')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_scheduleDate')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_startTime')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_endTime')}
                    </div>

                    <div className='hr_hoursBar_sub3' style={{justifyContent: 'flex-start'}}>
                      {__('misc_break')}
                    </div>

                  </div>

                }

                {Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?
                  this.renderHourBars()
                :
                  this.renderHourBarsPlanner()
                }

              </div>

            </div>

          :

            <div className='loaderModal'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

        );

      }

    }

  }
  
}

export default HoursRegistration;