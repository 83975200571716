/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './workWeek.css';
import '../salaries/salaries.css';
import '../myHours/myHours.css';
import '../../../components/smartPlanModal/smartPlanModal.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../../schedule/schedule.scss';
import '../../employees/employees.css';
import '../hoursRegistration/hoursRegistration.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import Constants from '../../../constants/Constants';
import Colors from '../../../constants/Colors';
import APIEDIT from '../../../classes/global/APIEDIT';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGET from '../../../classes/global/APIGET';
import APIADD from '../../../classes/global/APIADD';

class WorkWeek extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

      workWeekMonday: Data.data.appOptions.opties_verlof_maandag === '1',
      workWeekTuesday: Data.data.appOptions.opties_verlof_dinsdag === '1',
      workWeekWednesday: Data.data.appOptions.opties_verlof_woensdag === '1',
      workWeekThursday: Data.data.appOptions.opties_verlof_donderdag === '1',
      workWeekFriday: Data.data.appOptions.opties_verlof_vrijdag === '1',
      workWeekSaturday: Data.data.appOptions.opties_verlof_zaterdag === '1',
      workWeekSunday: Data.data.appOptions.opties_verlof_zondag === '1',

      dailyWorkHours: Data.data.appOptions.opties_dag_werkuren,

      accounts: [],
      infoExtra: {},

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2')) {
      return;
    }

    const accounts = await APIGetAllAccounts.Request();

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({
      accounts: accounts,
      infoExtra: infoExtraObj,
      loading: false,
    });

  }

  async onChangeNumberData(data, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
      nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
      nr = max;
    }
    
    if (data === 'dailyWorkHours') {
      if (nr === '') {
        this.setState({dailyWorkHours: '0'});
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_dag_werkuren = 0`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_dag_werkuren = '0';
      } else {
        this.setState({dailyWorkHours: Constants.round(parseFloat(nr)).toString()});
        await APIEDIT.Request(`UPDATE Opties`, `SET opties_dag_werkuren = ${Constants.round(parseFloat(nr))}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
        Data.data.appOptions.opties_dag_werkuren = Constants.round(parseFloat(nr)).toString();
      }
    }
  }

  closeModal() {

    this.setState({ 
      showModal: false,
      showUnavailableModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async setWorkDay(day) {

    if (day === 'mon') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_maandag = ${this.state.workWeekMonday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_maandag = this.state.workWeekMonday === false ? '1' : '0';
      this.setState({ workWeekMonday: !this.state.workWeekMonday});
    } else if (day === 'tue') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_dinsdag = ${this.state.workWeekTuesday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_dinsdag = this.state.workWeekTuesday === false ? '1' : '0';
      this.setState({ workWeekTuesday: !this.state.workWeekTuesday});
    } else if (day === 'wed') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_woensdag = ${this.state.workWeekWednesday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_woensdag = this.state.workWeekWednesday === false ? '1' : '0';
      this.setState({ workWeekWednesday: !this.state.workWeekWednesday});
    } else if (day === 'thu') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_donderdag = ${this.state.workWeekThursday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_donderdag = this.state.workWeekThursday === false ? '1' : '0';
      this.setState({ workWeekThursday: !this.state.workWeekThursday});
    } else if (day === 'fri') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_vrijdag = ${this.state.workWeekFriday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_vrijdag = this.state.workWeekFriday === false ? '1' : '0';
      this.setState({ workWeekFriday: !this.state.workWeekFriday});
    } else if (day === 'sat') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_zaterdag = ${this.state.workWeekSaturday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_zaterdag = this.state.workWeekSaturday === false ? '1' : '0';
      this.setState({ workWeekSaturday: !this.state.workWeekSaturday});
    } else if (day === 'sun') {
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_verlof_zondag = ${this.state.workWeekSunday === false ? '1' : '0'}`, `WHERE opties_id = ${Data.data.appOptions.opties_id}`);
      Data.data.appOptions.opties_verlof_zondag = this.state.workWeekSunday === false ? '1' : '0';
      this.setState({ workWeekSunday: !this.state.workWeekSunday});
    }

  }

  async setEveryoneToWorkDays() {

    this.setState({
      reloading: true,
    });

    let promises = [];

    for (const acc of this.state.accounts) {

      if (typeof this.state.infoExtra[acc.info_id] === 'undefined') {
        promises.push(APIADD.Request(`INSERT INTO InfoExtra (info2_id, info2_info_id, info2_werkdag_maandag, info2_werkdag_dinsdag, info2_werkdag_woensdag, info2_werkdag_donderdag, info2_werkdag_vrijdag, info2_werkdag_zaterdag, info2_werkdag_zondag)`, `VALUES (NULL, ${acc.info_id}, ${this.state.workWeekMonday === true ? '1' : '0'}, ${this.state.workWeekTuesday === true ? '1' : '0'}, ${this.state.workWeekWednesday === true ? '1' : '0'}, ${this.state.workWeekThursday === true ? '1' : '0'}, ${this.state.workWeekFriday === true ? '1' : '0'}, ${this.state.workWeekSaturday === true ? '1' : '0'}, ${this.state.workWeekSunday === true ? '1' : '0'})`));
      } else {
        promises.push(APIEDIT.Request(`UPDATE InfoExtra`, `SET info2_werkdag_maandag = ${this.state.workWeekMonday === true ? '1' : '0'}, info2_werkdag_dinsdag = ${this.state.workWeekTuesday === true ? '1' : '0'}, info2_werkdag_woensdag = ${this.state.workWeekWednesday === true ? '1' : '0'}, info2_werkdag_donderdag = ${this.state.workWeekThursday === true ? '1' : '0'}, info2_werkdag_vrijdag = ${this.state.workWeekFriday === true ? '1' : '0'}, info2_werkdag_zaterdag = ${this.state.workWeekSaturday === true ? '1' : '0'}, info2_werkdag_zondag = ${this.state.workWeekSunday === true ? '1' : '0'}`, `WHERE info2_info_id = ${acc.info_id}`));
      }

    }

    await Promise.all(promises);

    const infoExtra = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null);
    const infoExtraObj = Constants.convertToObject(infoExtra, 'info2_info_id');

    this.setState({
      infoExtra: infoExtraObj,
      reloading: false,
    });

  }

  applyAlert() {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.setState({
      alertTitle: 'Toepassen',
      alertBody: (
        <div>

          Weet je zeker dat je deze werkdagen wilt toepassen op alle medewerkers? Hiermee worden de huidige werkdagen van medewerkers overschreven door de werkdagen die hier zijn aangegeven.<br /><br />

          Indien je de werkdagen van een medewerker zelf wijzigt, wordt dit niet meer bijgehouden door Tiemdo

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Toepassen'],
      alertButtonAction: this.setEveryoneToWorkDays.bind(this),
      showAlert: true,
    });

  }

  render() {

    return (
        <div className='absences'>

          <Navbar history={this.props.history} active={'work_week'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} parent={this} />

          {this.state.showAlert === true ?
                    
            <BetterAlert 
              _closeAlert={this.closeAlert.bind(this)}
              _title={this.state.alertTitle}
              _body={this.state.alertBody}
              _buttons={this.state.alertButtons}
              _buttonText={this.state.alertButtonText}
              _buttonAction={this.state.alertButtonAction}
            />

          :

            null
                  
          }

          <div className='planner_nav2'>

            <div className='planner_nav2_sub1' style={{cursor: 'default'}}>
            </div> 
            <div className='planner_nav2_sub2'>
            
            </div> 
            <div className='planner_nav2_sub3' style={{flex: 2}}>
              Werkdagen
            </div> 
            <div className='schedule_nav2_sub4'>

            </div>
            <div className='planner_nav2_sub5' style={{cursor: 'default'}}>

            </div> 

          </div>

          <div className='workweek_main'>
              
              {this.state.loading === true ?
              
                          <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                <div className='workweek_container'>

                  {this.state.reloading === true ?
                            
                    <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

                  :

                    null

                  }

                    <div>

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '2vw', paddingLeft: '1%'}}>Arbeidsduur werkdag</div>

                      <input 
                        className={'workweek_inputBoxNumber'}
                        onChange={(event) => this.onChangeNumberData('dailyWorkHours', 0, 24, event)}
                        // onKeyDown={(event) => this.handleKey(event, 'maxDays')}
                        value={this.state.dailyWorkHours}
                        placeholder={'8'}
                        maxLength={5}
                        type={'number'}
                        style={{paddingLeft: 3, paddingTop: 2, paddingBottom: 2}}
                      />

                      <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '2vw', paddingLeft: '1%'}}>Werkdagen</div>

                      <div className='salaries_buttonContainer_apply' style={{paddingLeft: '1%', marginTop: '2%', marginBottom: 0}}>

                        <span style={{fontSize: '1vw'}}>De werkdagen toepassen op alle medewerkers</span>

                        <span className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.applyAlert()}>Toepassen</span>

                      </div>

                      <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekMonday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('mon')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('mon')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('mon')}>Maandag</span>

                        </div>

                      </div>

                      <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekTuesday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('tue')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('tue')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('tue')}>Dinsdag</span>

                        </div>

                      </div>

                       <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekWednesday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('wed')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('wed')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('wed')}>Woensdag</span>

                        </div>

                      </div>

                       <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekThursday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('thu')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('thu')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('thu')}>Donderdag</span>

                        </div>

                      </div>

                       <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekFriday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('fri')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('fri')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('fri')}>Vrijdag</span>

                        </div>

                      </div>

                      <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekSaturday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sat')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sat')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('sat')}>Zaterdag</span>

                        </div>

                      </div>

                      <div className='smartPlanModal-settingRow' style={{marginTop: '1vw'}}>

                        <div className='smartPlanModal-settingRowSub1'>

                          {this.state.workWeekSunday === false ?

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sun')}>
                              <i className='fal fa-circle' style={{fontSize: '1vw'}} />
                            </div>

                            : 

                            <div className='smartPlanModal-checkbox' onClick={() => this.setWorkDay('sun')}>
                              <i className='fas fa-check-circle' style={{fontSize: '1vw', color: Colors.color.greenFilledIn}} />
                            </div>

                          }

                        </div>

                        <div className='smartPlanModal-settingRowSub2' style={{flex: 20}}>

                          <span style={{fontSize: '1.2vw', cursor: 'pointer'}} onClick={() => this.setWorkDay('sun')}>Zondag</span>

                        </div>

                      </div>

                    </div>
                  
                </div>
            
              }

          </div>

        </div>
    );
  }
  
}

export default WorkWeek;