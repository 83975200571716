/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './planner.scss';
import '../../constants/DefaultStyle.css';
import '../options/appOptions/appOptions.css';
import '../options/requests/requests.css';

//js components & classes
import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';
import APIGetAllSchoolSchedule from '../../classes/APIGetAllSchoolSchedule';
import APIGetAllNotFilledSchool from '../../classes/APIGetAllNotFilledSchool';
import APIGetAllAvailabilities from '../../classes/APIGetAllAvailabilities';
import APIGetAllUnavailabilities from '../../classes/APIGetAllUnavailabilities';
import APIGetAllNotFilled from '../../classes/APIGetAllNotFilled';
import APIGetAllHolidays from '../../classes/APIGetAllHolidays';
import APIGetPlanner from '../../classes/APIGetPlanner';
import APIGetSpecificDefaultDayShift from '../../classes/APIGetSpecificDefaultDayShift';
import APIGetFunctions from '../../classes/APIGetFunctions';
import APIGetPublishCount from '../../classes/APIGetPublishCount';
import APIGetDefaultShifts from '../../classes/APIGetDefaultShifts';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIPublishPlanner from '../../classes/APIPublishPlanner';
import APIGetAllContracts from '../../classes/APIGetAllContracts';
import Constants from '../../constants/Constants';
import Plan from './plan.class';
import Colors from '../../constants/Colors';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import PlanModal from './planModal';
import BetterAlert from '../../components/alert/alert';
import APIGetAllPeopleNoAvailability from '../../classes/APIGetAllPeopleNoAvailability';
import APIGetUsedDayShifts from '../../classes/APIGetUsedDayShifts';
import APIGetSections from '../../classes/APIGetSections';
import APIGetAllUnpublished from '../../classes/APIGetAllUnpublished';
import KodictNotifications from '../../constants/KodictNotifications';
import PublishModal from './publishModal';
import Logger from '../../constants/Logger';

import APIGetAllPlannedHours from '../../classes/APIGetAllPlannedHours';
import BetterModal from '../../components/modal/modal';
import SmartPlanModal from '../../components/smartPlanModal/smartPlanModal';
import APIDeleteUsedDayShift from '../../classes/APIDeleteUsedDayShift';
import APIDeleteUserPlanner from '../../classes/APIDeleteUserPlanner';

import { Tooltip } from 'react-tippy';
import APIGetAllPlannedHoursWeek from '../../classes/APIGetAllPlannedHoursWeek';

import APIGetDayShiftTemplates from '../../classes/APIGetDayShiftTemplates';
import APIEditDailyGains from '../../classes/APIEditDailyGains';
import APIAddDailyGains from '../../classes/APIAddDailyGains';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';

import APIGetAllSchoolSchedulesYear from '../../classes/APIGetAllSchoolSchedulesYear';

import APIGetAllAvailabilitiesDay from '../../classes/APIGetAllAvailabilitiesDay';
import APIGetAllUnavailabilitiesDay from '../../classes/APIGetAllUnavailabilitiesDay';
import APIEditAccountJob from '../../classes/APIEditAccountJob';
import APIGetSkills from '../../classes/APIGetSkills';
import APIGetParticular from '../../classes/APIGetParticular';
import APIGetAbsencetypes from '../../classes/APIGetAbsencetypes';
import APIGetAllSkillsUsers from '../../classes/APIGetAllSkillsUsers';
import APIGetAllPartSchedules from '../../classes/APIGetAllPartSchedules';
import APIAddPartSchedule from '../../classes/APIAddPartSchedule';
import APIDeletePartSchedule from '../../classes/APIDeletePartSchedule';
import APIGetAllAbsenceSchedules from '../../classes/APIGetAllAbsenceSchedules';
import APIDeleteAbsenceSchedule from '../../classes/APIDeleteAbsenceSchedule';
import APIAddAbsenceSchedule from '../../classes/APIAddAbsenceSchedule';
import APIGetAllSkillSchedules from '../../classes/APIGetAllSkillSchedules';
import APIDeleteSkillSchedule from '../../classes/APIDeleteSkillSchedule';
import APIAddSkillSchedule from '../../classes/APIAddSkillSchedule';
import APIGetAllPartDayShifts from '../../classes/APIGetAllPartDayShifts';
import APIGetAllSkillDayShifts from '../../classes/APIGetAllSkillDayShifts';
import TemplateModal from '../../components/smartPlanModal/templateModal';

import APICanReplaceShift from '../../classes/APICanReplaceShift';
import APIGetAllAccountsTrade from '../../classes/APIGetAllAccountsTrade';

import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';
import APIEditUserPlanner from '../../classes/APIEditUserPlanner';
import DayNoteModal from './dayNoteModal';
import APIAddToAvailableShifts from '../../classes/APIAddToAvailableShifts';
import APIDELETE from '../../classes/global/APIDELETE';
import AvailableShiftsModal from './availableShiftsModal';
import APIEDIT from '../../classes/global/APIEDIT';
import APIAddToTeamPlanner from '../../classes/APIAddToTeamPlanner';
import APIEditOptions2 from '../../classes/APIEditOptions2';
import ScheduleDownloadName from '../../components/excel/scheduleDownloadName';
import ScheduleDownloadJob from '../../components/excel/scheduleDownloadJob';
import RepeatShiftModal from './repeatShiftModal';
import { weekdays } from 'moment';
import SortModal from '../../components/sortModal/sortModal';

class PlannerV2 extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.lastDeleted = null;
    this.lastVal = '';
    this.timeRef = {};

    this.isPlanning = false;

    this.dayShiftsData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.plannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.availableData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.unavailableData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.notFilledData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.holidayDaysData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.notFilledSchool = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.schoolScheduleData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.schoolHolidayData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.usersNoAvailability = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.allPlannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.standardAvailableData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.standardUnavailableData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.standardNotFilledData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    // ALL DATAS
    this._allAvailableData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allUnavailableData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allNotFilledData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allNotFilledSchool = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allSchoolScheduleData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allSchoolHolidayData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allUsersNoAvailability = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allStandardAvailableData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allStandardUnavailableData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this._allStandardNotFilledData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };

    this.emptyPlannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    this.teamUsers = [];
    this.teamPlanner = [];

    this.availableDataInit = {};
    this.unavailableDataInit = {};
    this.notFilledDataInit = {};
    this.notFilledSchoolInit = {};
    this.schoolScheduleDataInit = {};
    this.schoolHolidayDataInit = {};
    this.usersNoAvailabilityInit = {};

    this.sectionsData = [];
    this.holidayDays = [];
    this.holidayDaysRequests = [];
    this.functionsData = [];
    this.allFunctionsData = [];
    this.timesData = [];
    this.users = [];
    this.userDaysPlanned = {};
    this.usedDayShifts = []; // legacy
    this.usedDayShiftsModern = [];

    this.breakRules = [];

    this.fromPlanner = [];
    this.fromPlannerDay = -1;
    this.toPlanner = [];
    this.lastEdited = '';
    this.dontBlur = false;
    this.isAdding = false;

    this.plannerDataBackup = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.emptyPlannerDataBackup = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.usedDayShiftsModernBackup = [];
    this.absenceBackup = {};
    this.skillsBackup = {};
    this.partBackup = {};

    this.repeating = {};
    this.repeatingShifts = {};
    this.unpublishedRepeatingShifts = {};

    this.skillsRepeatShifts = {};
    this.partRepeatShifts = {};

    this.inactiveUsers = {};

    this.functionsArrange = [];

    this.salaryFactor = parseFloat(Data.data.appOptions.opties_loonfactor);
  
    this.scrollHeight = 0;

    this.userHoursScheduleID = this.props.location.scheduleID === undefined || this.props.location.scheduleID === null ? null : this.props.location.scheduleID;

    this.paramHighlight = this.props.location.highlight === undefined || this.props.location.highlight === null ? null : this.props.location.highlight;

    this.state = {

      display: Constants.isEmpty(localStorage.getItem('@plannerDisplay')) === true ? 'TABLE' : localStorage.getItem('@plannerDisplay'),

      loading: true,
      reloading: false,
      showTooltips: false,

      showDefaultDayShifts: true,

      week: 0,
      year: 0,

      mondayDate: '',
      tuesdayDate: '',
      wednesdayDate: '',
      thursdayDate: '',
      fridayDate: '',
      saturdayDate: '',
      sundayDate: '',

      selectedDate: new Date(),

      mode: 'CLICK', // typeof localStorage.getItem('@planMode') === 'undefined' || localStorage.getItem('@planMode') === null ? 'CLICK' : localStorage.getItem('@planMode'),

      selectedBar: '',
      lastEdited: '',

      toPublish: 0,

      showModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,

      showPublishModal: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,
      
      reloadScreenMode: 0,

      userHoursScheduleID: null,

      sortFunctions: false,

      collapsedFunctions: [],

      section: null,

      showModeMenu: false,

      hoursMonth: [],
      hoursMonth2: [],
      hoursWeek: [],
      contracts: [],
      month: 0,
      month2: 0,
      year2: 0,

      showQuestionModal: false,
      tutorialModal: false,

      showAvailableShiftsModal: false,

      showSmartPlanner: false,

      usedDayShifts: [],

      productivityTotal: 0,
      productivityMonths: {},

      salaryMonth1: 0,
      salaryMonth2: 0,
      salaryWeek: 0,

      collapseSalary: true,
      collapseProductivity: true,

      shiftTemplates: [],
      shiftTemp: null,
      shiftTempName: 'Selecteer een sjabloon',

      showTempMenu: false,

      showProductivity: !Constants.isTrue(Data.data.appOptions.opties_financien) ? false : localStorage.getItem('@showProductivity') === '1' ? true : false,
      showGains: !Constants.isTrue(Data.data.appOptions.opties_financien) ? false : localStorage.getItem('@showGains') === '1' ? true : false,
      showGainsExpect: !Constants.isTrue(Data.data.appOptions.opties_financien) ? false : localStorage.getItem('@showGainsExpect') === '1' ? true : false,
      showPreviousGains: !Constants.isTrue(Data.data.appOptions.opties_financien) ? false : localStorage.getItem('@showPreviousGains') === '1' ? true : false,
      showSalary: !Constants.isTrue(Data.data.appOptions.opties_financien) ? false : localStorage.getItem('@showSalary') === '1' ? true : false,
      showDetails: !Constants.isTrue(Data.data.appOptions.opties_financien) ? false : localStorage.getItem('@showDetails') === '1' ? true : false,
      showCrowds: localStorage.getItem('@showCrowds') === '1' ? true : false,

      showExtraOpenShifts: localStorage.getItem('@showExtraOpenShifts') === '0' ? false : true,
      showContractHours: localStorage.getItem('@showContractHours') === '1' ? true : false,

      omzetDagen: [0, 0, 0, 0, 0, 0, 0],
      omzetDagenV: [0, 0, 0, 0, 0, 0, 0],
      salarisDagen: [0, 0, 0, 0, 0, 0, 0],
      salarisUren: [0, 0, 0, 0, 0, 0, 0],
      dailyGains: [],
      dailyGainsExpect: [],

      editOmzet: false,
      editOmzetV: false,

      showSortMenu: false,
      sortBy: typeof localStorage.getItem('@mwSortBy') === 'string' ? localStorage.getItem('@mwSortBy') : 'availability',

      availableDataState: {},
      unavailableDataState: {},
      notFilledDataState: {},

      standardAvailableDataState: {},
      standardUnavailableDataState: {},
      standardNotFilledDataState: {},

      schoolScheduleDataState: {},
      schoolHolidayDataState: {},
      notFilledSchoolState: {},

      usersNoAvailabilityState: {},

      planInputs: {},
      availableInputs: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
      unavailableInputs: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
      notFilledInputs: {},
      schoolInputs: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
      schoolHolidayInputs: {},
      schoolNotFilledInputs: {},
      noAvailableInputs: {},
      standardAvailableInputs: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
      standardUnavailableInputs: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
      //standardNotFilledInputs: {},
      standardNotFilledInputs: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
      holidayInputs: {},

      showPlanMenu: {},
      showPlanMenuInfo: {},
      showPlanMenuEdit: {},
      showPlanMenuSpecial: {},
      showPlanMenuAbsence: {},
      showPlanMenuComp: {},
      showPlanMenuBreaks: {},

      showDayShiftMenu: {},

      showUserMenu: {},
      showUserMenuJob: {},

      showUserMenuAv: {},

      showPlanMenuOuter: false,
      editPlan: false,

      allAvailabilities: {},
      allUnavailabilities: {},
      allSchoolSchedules: {},
      allStandardAvailabilities: {},
      allStandardUnavailabilities: {},

      skills: [],
      particularities: [],
      absencetypes: [],

      skillsUsers: [],
      partSchedules: [],
      absenceSchedules: [],
      skillSchedules: [],

      partDayShifts: [],
      skillDayShifts: [],

      showSettingsMenu: false,
      showSettingsMenu2: false,
      showSettingsMenuAdd: false,
      showSettingsMenuShifts: false,
      showSettingsMenuPlanning: false,
      showSettingsMenuEmployees: false,
      showSettingsMenuExport: false,

      plannerTemplates: [],
      highestTempKey: 0,
      showAddTempModal: false,

      infoExtra: {},

      planUsersMaxCumulative: {},
      highestCumulative: 0,

      breaks: [],

      secondaryFunctions: {},
      secondaryFunctionName: 'test',
      secondaryFunctionColor: 'red',
      secondaryFunctionID: null,
      functionsUser: [],
      chosenUserSecondaryFunction: null,

      showRepeatShiftModal: false,
      showDayNoteModal: false,

      dayNotes: {},

      userAvailableShifts: [],
      plannerAvailableShifts: [],

      contractsObj: {},

      plannerArrange: [],

      freeHours: {},

      addEmptyRows: {},
      addEmptyRowsUser: {},

      expectedLoad: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },

      expectedLoadMinMax: {
        mon: { min: 0, max: 0 },
        tue: { min: 0, max: 0 },
        wed: { min: 0, max: 0 },
        thu: { min: 0, max: 0 },
        fri: { min: 0, max: 0 },
        sat: { min: 0, max: 0 },
        sun: { min: 0, max: 0 },
      },

      previousGains: {
        mon: null,
        tue: null,
        wed: null,
        thu: null,
        fri: null,
        sat: null,
        sun: null,
      },

      exclusions: [],

      multipleAvail: {},

      showUndoButton: false,

      sidemenuActive: false,

      opties_smartplan: Data.data.appOptions.opties_smartplan === '1',
      opties_financien: Data.data.appOptions.opties_financien === '1',
      opties_planBeschikbaar: Data.data.appOptions.opties_planBeschikbaar === '1',
      opties_planFunctieSort: Data.data.appOptions.opties_planFunctieSort === '1',
      opties_contractVerlopen: Data.data.appOptions.opties_contractVerlopen === '1',
      opties_opendienst_beschikbaar: Data.data.appOptions.opties_opendienst_beschikbaar === '1',
      opties_infoplanner_verborgen: Data.data.appOptions.opties_infoplanner_verborgen === '1',

      opties_planner_drukte: Data.data.appOptions.opties_planner_drukte === '1',
      opties_planner_loonkosten: Data.data.appOptions.opties_planner_loonkosten === '1',
      opties_planner_vorigOmzet: Data.data.appOptions.opties_planner_vorigOmzet === '1',
      opties_planner_verwachteOmzet: Data.data.appOptions.opties_planner_verwachteOmzet === '1',
      opties_planner_omzet: Data.data.appOptions.opties_planner_omzet === '1',
      opties_planner_productiviteit: Data.data.appOptions.opties_planner_productiviteit === '1',
      opties_planner_berekeningen: Data.data.appOptions.opties_planner_berekeningen === '1',
      opties_planner_extraDiensten: Data.data.appOptions.opties_planner_extraDiensten === '1',
      opties_planner_contracturen: Data.data.appOptions.opties_planner_contracturen === '1',

      weeklyHours: {},
      monthlyHours: {},
      monthlyHours2: {},

      userDefaultWorkdays: [],

      publicHolidays: [],

      accountsObj: {},

      copyShifts: false,

      hoursWorked: [],
      showWeekDetails: [],
      absAmount2: {},
      schedule2: {},
      overhours_corrections: {},

      showSortModal: false,

    };

    this.navBar = React.createRef();

    //componentWillMount here: //untill

    this.compWillMount();

  }

  compWillMount() {

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && (Data.data.accountData.account_rights !== '2' && Data.data.accountData.account_rights !== '1')) {
      return;
    }

    await this.getData_new();

  }

  async getData_new() {

    this.setState({ loading: true });

    // GET WEEK AND YEAR
    if(this.props.location.selectedDate !== null && this.props.location.selectedDate !== undefined) {
      this.WEEK = Constants.getWeekNumber2(this.props.location.selectedDate);
      this.YEAR = Constants.getWeekYear2(this.props.location.selectedDate);
    } else {
      this.WEEK = Constants.getWeekNumber();
      this.YEAR = Constants.getWeekYear();
    }

    // GET DAYS DATES
    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    const sundayDate = this.getSunday(this.WEEK, this.YEAR);

    // SET SOME STUFF TO NULL
    this.sectionsData = [];
    this.holidayDaysData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.functionData = [];

    // GET USERS AND SHIFT TEMP
    const [
        shiftTemplates,
        functionsArrange,
    ] = await Promise.all([
        APIGetDayShiftTemplates.Request(),
        APIGET.Request(`SELECT fv_functie_id, fv_index`, `FROM FunctieVolgorde`, null, null, null, `ORDER BY fv_index`),
    ]);

    this.functionsArrange = functionsArrange;

    // DAY SHIFT TEMPLATES
    let newShiftTemplates = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const temp of shiftTemplates) {
        if (temp.dagdienstsjabloon_team_id === Data.data.chosenTeamObj.team_id) {
          newShiftTemplates.push(temp);
        }
      }
    } else {
      newShiftTemplates = shiftTemplates;
    }

    const chosenTemp = newShiftTemplates.length > 0 ? newShiftTemplates[0] : null;

    this.setState({
        week: this.props.location.selectedDate !== null && this.props.location.selectedDate !== undefined ? this.getWeekNumber2(this.props.location.selectedDate) : this.getWeekNumber(),
        year: this.props.location.selectedDate !== null && this.props.location.selectedDate !== undefined ? this.getWeekYear2(this.props.location.selectedDate) : this.getWeekYear(),
        mondayDate: Constants.formatDate(mondayDate),
        tuesdayDate: Constants.formatDate(tuesdayDate),
        wednesdayDate: Constants.formatDate(wednesdayDate),
        thursdayDate: Constants.formatDate(thursdayDate),
        fridayDate: Constants.formatDate(fridayDate),
        saturdayDate: Constants.formatDate(saturdayDate),
        sundayDate: Constants.formatDate(sundayDate),
  
        shiftTemplates: newShiftTemplates,
        shiftTemp: chosenTemp !== null ? parseInt(chosenTemp.dagdienstsjabloon_id) : null,
        shiftTempName: chosenTemp !== null ? chosenTemp.dagdienstsjabloon_naam : 'Selecteer een sjabloon',
  
    }, async() => {

        await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

        // GET FIRST LOADING STUFF
        let newHours = {};
        let absAmountObj = {};
        let scheduleAbsenceObj = {};

        let [
            _getSections,
            _getAllHolidays,
            _getFunctions,

            skills,
            particularities,
            absencetypes,
            skillsUsers,
            breaks,
            secondaryFunctions,
            publicHolidays,

            breakRules,

            hours,
            absAmount,
            scheduleAbsence,
            clocked,
            schedules,
            overhoursCorrectionsData,
        ] = await Promise.all([
            this.getSections(),
            this.getAllHolidays(),
            this.getFunctions(),

            APIGetSkills.Request(),
            APIGetParticular.Request(),
            APIGetAbsencetypes.Request(),
            APIGetAllSkillsUsers.Request(),
            APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`),
            APIGET.Request(`SELECT functie_id, functie_naam, functie_kleur, fe_info_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, null, null, null),
            APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${mondayDate.getFullYear()}`, null, `ORDER BY f_datum`),
        
            APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`),
            
            APIGET.Request(`SELECT *`, `FROM Uren`, `LEFT JOIN Info ON uren_info_id = info_id`, null, null, null),
            APIGET.Request(`SELECT *`, `FROM VerlofSaldo`, `LEFT JOIN Vakantie ON vs_vakantie_id = vakantie_id LEFT JOIN Info ON vs_info_id = info_id`, null, null, null),
            APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Afwezigheid ON afwr_afw_id = afw_id LEFT JOIN Uitzondering ON uit_afw_id = afw_id`, `WHERE uit_id IS NOT NULL`, null, null),
            APIGET.Request(`SELECT *`, `FROM Kloktijd`, `LEFT JOIN Info ON kloktijd_info_id = info_id LEFT JOIN Uren ON uren_kloktijd_id = kloktijd_id`, `WHERE uren_id IS NULL`, null, null),
            APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Info ON rooster_info_id = info_id LEFT JOIN Uren ON uren_rooster_id = rooster_id`, `WHERE rooster_publiceren = 1 AND uren_id IS NULL`, null, null),
            APIGET.Request(`SELECT CorrectieOveruren.*, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM CorrectieOveruren`, `LEFT JOIN Info ON co_info_id_door = info_id`, null, null, `ORDER BY co_datum DESC, co_id DESC`),
        ]);

        // SET SOME OBJECTS AND OTHER STUFF
        const skillsUsersObj = Constants.convertToObjectWithArrays(skillsUsers, 'info_id');
        const secondaryFunctionsObj = Constants.convertToObjectWithArrays(secondaryFunctions, 'fe_info_id');

        this.breakRules = breakRules;

        // OVERHOURS DATA
         // CLOCKED DATA INTO UREN DB
         if (Data.data.appOptions.hours === 'CLOCK') {
          for (const data of clocked) {

            let _break = data.kloktijd_pauze.substr(0, 8);
            if (Data.data.appOptions.brk === 'PLANNER') {
              _break = Constants.getUserPlannerBreak(data.info_id, data.kloktijd_inklok_datum, schedules);
            } else if (Data.data.appOptions.brk === 'CALC') {
              _break = Constants.getBreakRule(data.kloktijd_inklok_datum, data.kloktijd_inklok_tijd, data.kloktijd_uitklok_datum, data.kloktijd_uitklok_tijd, null, breakRules).str.substr(0, 5);
            }

            hours.push({
              ...data,
              uren_id: null,
              uren_begindatum: data.kloktijd_inklok_datum,
              uren_einddatum: data.kloktijd_uitklok_datum,
              uren_begin: data.kloktijd_inklok_tijd,
              uren_eind: data.kloktijd_uitklok_tijd,
              uren_pauze: _break,
            });

          }
        } else if (Data.data.appOptions.hours === 'PLANNER') {
          for (const data of schedules) {

            let _break = data.rooster_pauze;
            if (Data.data.appOptions.brk === 'CALC') {
              _break = Constants.getBreakRule(data.rooster_datum, data.rooster_begin, data.rooster_datum, data.rooster_eind, data, breakRules).str.substr(0, 5);
            }

            hours.push({
              ...data,
              uren_id: null,
              uren_begindatum: data.rooster_datum,
              uren_einddatum: Plan.isNightShift2(data),
              uren_begin: data.rooster_begin,
              uren_eind: data.rooster_eind,
              uren_pauze: _break,
            });

          }
        }

        // HOURS (UREN DB)
        for (const data of hours) {

          const hourDate = Constants.stringToDate(data.uren_begindatum);
          const week = Constants.getWeekNumber2(hourDate);
          const year = hourDate.getFullYear();
          const yearKey = year.toString();
          const userKey = data.info_id;

          // CREATE USER OBJECT
          if (Constants.isEmpty(newHours[userKey])) {
            newHours[userKey] = {
              user: data.info_id,
              userFullName: `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`,
              data: {},
            };
          }

          // CREATE YEAR OBJECT
          if (Constants.isEmpty(newHours[userKey].data[yearKey])) {
            newHours[userKey].data[yearKey] = [];
          }

          // CREATE WEEK ITEM
          if (Constants.objectArrayContainsKey(newHours[userKey].data[yearKey], 'week', week) === false) {
            newHours[userKey].data[yearKey].push({
              week: week,
              weekData: [],
            });
          }

          const weekItem = Constants.getArrayItem(newHours[userKey].data[yearKey], 'week', week);

          if (weekItem !== null) {
            weekItem.weekData.push(data);
          }

        }

        // ABS
        for (const abs of absAmount) {

          const date = Constants.stringToDate(abs.vakantie_begin);
          const week = Constants.getWeekNumber2(date);
          const weekKey = week.toString();
          const year = date.getFullYear();
          const yearKey = year.toString();
          const userKey = abs.vakantie_info_id;

          if (Constants.isEmpty(absAmountObj[userKey])) {
            absAmountObj[userKey] = {};
          }
          if (Constants.isEmpty(absAmountObj[userKey][yearKey])) {
            absAmountObj[userKey][yearKey] = {};
          }
          if (Constants.isEmpty(absAmountObj[userKey][yearKey][weekKey])) {
            absAmountObj[userKey][yearKey][weekKey] = [];
          }

          absAmountObj[userKey][yearKey][weekKey].push(abs);

        }

         // SCHEDULE ABSENTS
         for (const data of scheduleAbsence) {

          const date = Constants.stringToDate(data.rooster_datum);
          const week = Constants.getWeekNumber2(date);
          const weekKey = week.toString();
          const year = date.getFullYear();
          const yearKey = year.toString();
          const userKey = data.rooster_info_id;

          if (Constants.isEmpty(scheduleAbsenceObj[userKey])) {
            scheduleAbsenceObj[userKey] = {};
          }
          if (Constants.isEmpty(scheduleAbsenceObj[userKey][yearKey])) {
            scheduleAbsenceObj[userKey][yearKey] = {};
          }
          if (Constants.isEmpty(scheduleAbsenceObj[userKey][yearKey][weekKey])) {
            scheduleAbsenceObj[userKey][yearKey][weekKey] = [];
          }

          scheduleAbsenceObj[userKey][yearKey][weekKey].push(data);

        }

        // newHours.sort((a, b) => (a.userFullName > b.userFullName) ? 1 : -1);

        // SORT DAY DATA WEEKS (UREN)
        for (const key in newHours) {
          for (const yearKey in newHours[key].data) {
            newHours[key].data[yearKey].sort((a, b) => (a.week > b.week) ? -1 : 1);
            for (let _weekData of newHours[key].data[yearKey]) {
              _weekData.weekData.sort((a, b) => ((a.uren_begindatum > b.uren_begindatum) || (a.uren_begindatum === b.uren_begindatum && a.uren_begin > b.uren_begin)) ? 1 : -1);
            }
          }
        }

        // CORRECTIONS
        let overhoursCorrections = {};
        for (const correction of overhoursCorrectionsData) {

          const date = Constants.stringToDate(correction.co_datum);
          const year = date.getFullYear();
          const yearKey = year.toString();

          if (typeof overhoursCorrections[correction.co_info_id] === 'undefined') {
            overhoursCorrections[correction.co_info_id] = {};
            overhoursCorrections[correction.co_info_id][new Date().getFullYear().toString()] = {
              sep: [],
              total: 0,
              totalAT: 0,
            };
          }

          if (typeof overhoursCorrections[correction.co_info_id][yearKey] === 'undefined') {
            overhoursCorrections[correction.co_info_id][yearKey] = {
              sep: [],
              total: 0,
              totalAT: 0,
            };
          }

          overhoursCorrections[correction.co_info_id][yearKey].sep.push(correction);
          overhoursCorrections[correction.co_info_id][yearKey].total += parseFloat(correction.co_aantal);

        }

        for (const userKey in overhoursCorrections) {
          let yearCorrections = 0;
          for (const yearKey in overhoursCorrections[userKey]) {
            overhoursCorrections[userKey][yearKey].totalAT += (parseFloat(overhoursCorrections[userKey][yearKey].total) + yearCorrections);
            yearCorrections += overhoursCorrections[userKey][yearKey].total;
          }
        }

        // NO IDEA
        if(this.userHoursScheduleID !== null) {
          this.setState({
            userHoursScheduleID: parseInt(this.userHoursScheduleID),
          });
          setTimeout(() => { 
            this.setState({
              userHoursScheduleID: null,
            });
          }, 5000);
        }

        // SET plan input data
        let planInputs = this.state.planInputs;
        let showPlanMenu = this.state.showPlanMenu;
        let showPlanMenuInfo = this.state.showPlanMenuInfo;
        let showPlanMenuEdit = this.state.showPlanMenuEdit;
        let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
        let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
        let showPlanMenuComp = this.state.showPlanMenuComp;
        let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

        for (const key in this.plannerData) {
            for (const plan of this.plannerData[key]) {
                planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
                showPlanMenu[plan.rooster_id] = false;
                showPlanMenuInfo[plan.rooster_id] = false;
                showPlanMenuEdit[plan.rooster_id] = false;
                showPlanMenuSpecial[plan.rooster_id] = false;
                showPlanMenuAbsence[plan.rooster_id] = false;
                showPlanMenuComp[plan.rooster_id] = false;
                showPlanMenuBreaks[plan.rooster_id] = false;
            }
        }

        for (const key in this.emptyPlannerData) {
            for (const plan of this.emptyPlannerData[key]) {
                planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
                showPlanMenu[plan.rooster_id] = false;
                showPlanMenuInfo[plan.rooster_id] = false;
                showPlanMenuEdit[plan.rooster_id] = false;
                showPlanMenuSpecial[plan.rooster_id] = false;
                showPlanMenuAbsence[plan.rooster_id] = false;
                showPlanMenuComp[plan.rooster_id] = false;
                showPlanMenuBreaks[plan.rooster_id] = false;
            }
        }

        for (const key in this.repeatingShifts) {
          for (const r of this.repeatingShifts[key]) {
            for (let i = 0; i < 7; i++) {
              showPlanMenu['rs' + r.hr_id + i] = false;
            }
          }
        }

        this.setState({
            skills: skills,
            particularities: particularities,
            absencetypes: absencetypes,
            skillsUsers: skillsUsersObj,
            breaks: breaks,
            secondaryFunctions: secondaryFunctionsObj,
            publicHolidays: publicHolidays,

            planInputs: planInputs,
            showPlanMenu: showPlanMenu,
            showPlanMenuInfo: showPlanMenuInfo,
            showPlanMenuEdit: showPlanMenuEdit,
            showPlanMenuSpecial: showPlanMenuSpecial,
            showPlanMenuAbsence: showPlanMenuAbsence,
            showPlanMenuComp: showPlanMenuComp,
            showPlanMenuBreaks: showPlanMenuBreaks,

            schedule2: scheduleAbsenceObj, 
            absAmount2: absAmountObj, 
            hoursWorked: newHours, 
            overhours_corrections: overhoursCorrections,

            loading: false,
        });

    });

  }

  async getExpectedLoad(monday, tuesday, wednesday, thursday, friday, saturday, sunday) {

    // const [
    //   monday,
    //   tuesday,
    //   wednesday,
    //   thursday,
    //   friday,
    //   saturday,
    //   sunday
    // ] = await Promise.all([
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(mondayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(mondayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 0 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(mondayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(mondayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 0`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 1 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 1`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 2 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 2`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(thursdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(thursdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 3 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(thursdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(thursdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 3`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(fridayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(fridayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 4 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(fridayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(fridayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 4`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(saturdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(saturdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 5 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(saturdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(saturdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 5`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(sundayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(sundayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 6 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(sundayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(sundayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 6`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
    // ]);

    const expectedLoad = {
      mon: monday,
      tue: tuesday,
      wed: wednesday,
      thu: thursday,
      fri: friday,
      sat: saturday,
      sun: sunday,
    };

    let expectedLoadMinMax = {
      mon: { min: 24, max: 0 },
      tue: { min: 24, max: 0 },
      wed: { min: 24, max: 0 },
      thu: { min: 24, max: 0 },
      fri: { min: 24, max: 0 },
      sat: { min: 24, max: 0 },
      sun: { min: 24, max: 0 },
    };

    //
    for (const plan of monday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.mon.min) {
        expectedLoadMinMax.mon.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.mon.max) {
        expectedLoadMinMax.mon.max = planEnd;
      }
    }

    //
    for (const plan of tuesday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.tue.min) {
        expectedLoadMinMax.tue.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.tue.max) {
        expectedLoadMinMax.tue.max = planEnd;
      }
    }

    //
    for (const plan of wednesday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.wed.min) {
        expectedLoadMinMax.wed.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.wed.max) {
        expectedLoadMinMax.wed.max = planEnd;
      }
    }

    //
    for (const plan of thursday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.thu.min) {
        expectedLoadMinMax.thu.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.thu.max) {
        expectedLoadMinMax.thu.max = planEnd;
      }
    }

    //
    for (const plan of friday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.fri.min) {
        expectedLoadMinMax.fri.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.fri.max) {
        expectedLoadMinMax.fri.max = planEnd;
      }
    }

    //
    for (const plan of saturday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.sat.min) {
        expectedLoadMinMax.sat.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.sat.max) {
        expectedLoadMinMax.sat.max = planEnd;
      }
    }

    //
    for (const plan of sunday) {
      const planStart = Math.floor(Constants.timeStringToFloat(plan.rooster_begin));
      const planEnd = Math.ceil(Constants.timeStringToFloat(plan.rooster_eind));
      if (planStart < expectedLoadMinMax.sun.min) {
        expectedLoadMinMax.sun.min = planStart;
      }
      if (planEnd > expectedLoadMinMax.sun.max) {
        expectedLoadMinMax.sun.max = planEnd;
      }
    }

    this.setState({ expectedLoad: expectedLoad, expectedLoadMinMax: expectedLoadMinMax });

  }

  getShiftTempName(tempID) {

    for (const temp of this.state.shiftTemplates) {
      if (parseInt(temp.dagdienstsjabloon_id) === tempID) {
        return temp.dagdienstsjabloon_naam;
      }
    }

    return 'Standaard';

  }

  clickOutsideMenu(e) {

    if (typeof document.getElementsByClassName('planner_planMenu_left') !== 'undefined' && document.getElementsByClassName('planner_planMenu_left').length > 0) {

      if (document.getElementsByClassName('planner_planMenu_left')[0].contains(e.target)){
        // Clicked in box
      } else{
        this.closeAllPlanMenus();
      }

    }

  }

  sundays(year) {

    let day, counter, date, month;

    day = 1;
    month = 0;
    counter = 0;
    date = new Date(year, month, day);
    while (date.getFullYear() === year) {
        if (date.getDay() === 0) { // Sun=0, Mon=1, Tue=2, etc.
            counter += 1;
        }
        day += 1;
        if(day > Constants.getDaysInMonth(year, month)) {
          day = 0;
          month += 1;
        }
        if(month === 12) {
          break;
        }
        date = new Date(year, month, day);
    }
    return counter;
  }

  async getNextData(next) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({loading: true});

    this.WEEK = next === true ? this.state.week + 1 : this.state.week - 1;
    this.YEAR = this.state.year;

    if(this.WEEK > this.weeksInYear(this.YEAR)) {
      this.WEEK = 1;
      this.YEAR = this.YEAR + 1;
    }
    if(this.WEEK < 1) {
      this.YEAR = this.YEAR - 1;
      this.WEEK = this.weeksInYear(this.YEAR);
    }

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    this.setState({
      week: this.WEEK,
      year: this.YEAR,
      mondayDate: Constants.formatDate(mondayDate),
      tuesdayDate: Constants.formatDate(tuesdayDate),
      wednesdayDate: Constants.formatDate(wednesdayDate),
      thursdayDate: Constants.formatDate(thursdayDate),
      fridayDate: Constants.formatDate(fridayDate),
      saturdayDate: Constants.formatDate(saturdayDate),
      sundayDate: Constants.formatDate(sundayDate),
      selectedBar: '',
      selectedDate: this.getMonday(this.WEEK, this.YEAR),
      lastEdited: '',
      planInputs: {},
    }, async() => {

      this.fromPlanner = [];
      this.fromPlannerDay = -1;
      this.toPlanner = [];

      this.plannerDataBackup = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this.emptyPlannerDataBackup = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this.usedDayShiftsModernBackup = [];
      this.absenceBackup = {};
      this.skillsBackup = {};
      this.partBackup = {};

      await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

      // SET plan input data
      let planInputs = this.state.planInputs;
      let showPlanMenu = this.state.showPlanMenu;
      let showPlanMenuInfo = this.state.showPlanMenuInfo;
      let showPlanMenuEdit = this.state.showPlanMenuEdit;
      let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
      let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
      let showPlanMenuComp = this.state.showPlanMenuComp;
      let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

      for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
      }

      for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
      }

      for (const key in this.repeatingShifts) {
        for (const r of this.repeatingShifts[key]) {
          for (let i = 0; i < 7; i++) {
            showPlanMenu['rs' + r.hr_id + i] = false;
          }
        }
      }

      this.setState({
        planInputs: planInputs,
        showPlanMenu: showPlanMenu,
        showPlanMenuInfo: showPlanMenuInfo,
        showPlanMenuEdit: showPlanMenuEdit,
        showPlanMenuSpecial: showPlanMenuSpecial,
        showPlanMenuAbsence: showPlanMenuAbsence,
        showPlanMenuComp: showPlanMenuComp,
        showPlanMenuBreaks: showPlanMenuBreaks,
        loading: false,
        });

    });

  }

  async getNextDataWY(week, year) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({loading: true});

    this.WEEK = week;
    this.YEAR = year;

    if(this.WEEK > this.weeksInYear(this.YEAR)) {
      this.WEEK = 1;
      this.YEAR = this.YEAR + 1;
    }
    if(this.WEEK < 1) {
      this.YEAR = this.YEAR - 1;
      this.WEEK = this.weeksInYear(this.YEAR);
    }

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    this.setState({
      week: this.WEEK,
      year: this.YEAR,
      mondayDate: Constants.formatDate(mondayDate),
      tuesdayDate: Constants.formatDate(tuesdayDate),
      wednesdayDate: Constants.formatDate(wednesdayDate),
      thursdayDate: Constants.formatDate(thursdayDate),
      fridayDate: Constants.formatDate(fridayDate),
      saturdayDate: Constants.formatDate(saturdayDate),
      sundayDate: Constants.formatDate(sundayDate),
      selectedBar: '',
      selectedDate: this.getMonday(this.WEEK, this.YEAR),
      lastEdited: '',
      showPublishModal: false,
    }, async() => {

      this.fromPlanner = [];
      this.fromPlannerDay = -1;
      this.toPlanner = [];

      this.plannerDataBackup = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this.emptyPlannerDataBackup = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this.usedDayShiftsModernBackup = [];
      this.absenceBackup = {};
      this.skillsBackup = {};
      this.partBackup = {};

      await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

      this.setState({loading: false});

    });

  }

  showDayNote(day) {

    let date = this.state.mondayDate;

    if (day === 'mon') {
      date = this.state.mondayDate;
    } else if (day === 'tue') {
      date = this.state.tuesdayDate;
    } else if (day === 'wed') {
      date = this.state.wednesdayDate;
    } else if (day === 'thu') {
      date = this.state.thursdayDate;
    } else if (day === 'fri') {
      date = this.state.fridayDate;
    } else if (day === 'sat') {
      date = this.state.saturdayDate;
    } else if (day === 'sun') {
      date = this.state.sundayDate;
    }

    if (typeof this.state.dayNotes[date] !== 'undefined') {

      return (
        <span style={{color: `#${this.state.dayNotes[date].dn_kleur}`}}>
          {this.state.dayNotes[date].dn_notitie}
        </span>
      );

    } else {

      return null;

    }

  }

  getDayNoteData(day) {

    let date = this.state.mondayDate;

    if (day === 'mon') {
      date = this.state.mondayDate;
    } else if (day === 'tue') {
      date = this.state.tuesdayDate;
    } else if (day === 'wed') {
      date = this.state.wednesdayDate;
    } else if (day === 'thu') {
      date = this.state.thursdayDate;
    } else if (day === 'fri') {
      date = this.state.fridayDate;
    } else if (day === 'sat') {
      date = this.state.saturdayDate;
    } else if (day === 'sun') {
      date = this.state.sundayDate;
    }

    if (typeof this.state.dayNotes[date] !== 'undefined') {

      return this.state.dayNotes[date];

    } else {

      return null;

    }

  }

  async reloadDayNotes() {

    this.setState({ reloading: true });

    const notes = await APIGET.Request(`SELECT *`, `FROM DagNotitie`, null, `WHERE dn_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    const notesObj = Constants.convertToObject(notes, 'dn_datum');

    this.setState({ dayNotes: notesObj, reloading: false });


  }

  async getCalendarDateData(week, year) {

    if(this.state.loading === true) {
      return;
    }

    this.setState({loading: true});

    this.WEEK = week;
    this.YEAR = year;

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    this.setState({
      week: this.WEEK,
      year: this.YEAR,
      mondayDate: Constants.formatDate(mondayDate),
      tuesdayDate: Constants.formatDate(tuesdayDate),
      wednesdayDate: Constants.formatDate(wednesdayDate),
      thursdayDate: Constants.formatDate(thursdayDate),
      fridayDate: Constants.formatDate(fridayDate),
      saturdayDate: Constants.formatDate(saturdayDate),
      sundayDate: Constants.formatDate(sundayDate),
    }, async () => {
      await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);
      this.setState({loading: false});

    });


  }

  async getData() {

    if(this.props.location.selectedDate !== null && this.props.location.selectedDate !== undefined) {
      this.WEEK = this.getWeekNumber2(this.props.location.selectedDate);
      this.YEAR = this.getWeekYear2(this.props.location.selectedDate);
    } else {
      this.WEEK = this.getWeekNumber();
      this.YEAR = this.getWeekYear();
    }

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    const shiftTemplates = await APIGetDayShiftTemplates.Request();
    let newShiftTemplates = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const temp of shiftTemplates) {
        if (temp.dagdienstsjabloon_team_id === Data.data.chosenTeamObj.team_id) {
          newShiftTemplates.push(temp);
        }
      }
    } else {
      newShiftTemplates = shiftTemplates;
    }

    const chosenTemp = newShiftTemplates.length > 0 ? newShiftTemplates[0] : null;

    this.setState({
      week: this.props.location.selectedDate !== null && this.props.location.selectedDate !== undefined ? this.getWeekNumber2(this.props.location.selectedDate) : this.getWeekNumber(),
      year: this.props.location.selectedDate !== null && this.props.location.selectedDate !== undefined ? this.getWeekYear2(this.props.location.selectedDate) : this.getWeekYear(),
      mondayDate: Constants.formatDate(mondayDate),
      tuesdayDate: Constants.formatDate(tuesdayDate),
      wednesdayDate: Constants.formatDate(wednesdayDate),
      thursdayDate: Constants.formatDate(thursdayDate),
      fridayDate: Constants.formatDate(fridayDate),
      saturdayDate: Constants.formatDate(saturdayDate),
      sundayDate: Constants.formatDate(sundayDate),

      shiftTemplates: newShiftTemplates,
      shiftTemp: chosenTemp !== null ? parseInt(chosenTemp.dagdienstsjabloon_id) : null,
      shiftTempName: chosenTemp !== null ? chosenTemp.dagdienstsjabloon_naam : 'Selecteer een sjabloon',

    }, async() => {

      await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

      this.functionsArrange = await APIGET.Request(`SELECT fv_functie_id, fv_index`, `FROM FunctieVolgorde`, null, null, null, `ORDER BY fv_index`);
      this.sectionsData = [];
      this.holidayDaysData = {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      };
      this.functionData = [];

      await this.getSections();
      await this.getAllHolidays();
      await this.getFunctions();

      // skills, particu, absencetypes
      const skills = await APIGetSkills.Request();
      const particularities = await APIGetParticular.Request();
      const absencetypes = await APIGetAbsencetypes.Request();

      // skills users have
      const skillsUsers = await APIGetAllSkillsUsers.Request();
      const skillsUsersObj = Constants.convertToObjectWithArrays(skillsUsers, 'info_id');

      // BREAKS
      const breaks = await APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`);

      // SECONDARY FUNCTIONS
      const secondaryFunctions = await APIGET.Request(`SELECT functie_id, functie_naam, functie_kleur, fe_info_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, null, null, null);
      const secondaryFunctionsObj = Constants.convertToObjectWithArrays(secondaryFunctions, 'fe_info_id');

      const publicHolidays = await APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${mondayDate.getFullYear()}`, null, `ORDER BY f_datum`);

      this.setState({
        skills: skills,
        particularities: particularities,
        absencetypes: absencetypes,

        skillsUsers: skillsUsersObj,

        breaks: breaks,

        secondaryFunctions: secondaryFunctionsObj,

        publicHolidays: publicHolidays,

      });

      if(this.userHoursScheduleID !== null) {
        this.setState({
          userHoursScheduleID: parseInt(this.userHoursScheduleID),
        });
        setTimeout(() => { 
          this.setState({
            userHoursScheduleID: null,
          });
        }, 5000);
      }

      if(Data.data.inTutorial === true) {

        this.setState({ tutorialModal: true });

        this.openQuestionModal();

      }

      // SET plan input data
      let planInputs = this.state.planInputs;
      let showPlanMenu = this.state.showPlanMenu;
      let showPlanMenuInfo = this.state.showPlanMenuInfo;
      let showPlanMenuEdit = this.state.showPlanMenuEdit;
      let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
      let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
      let showPlanMenuComp = this.state.showPlanMenuComp;
      let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

      for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
      }

      for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
      }

      for (const key in this.repeatingShifts) {
        for (const r of this.repeatingShifts[key]) {
          for (let i = 0; i < 7; i++) {
            showPlanMenu['rs' + r.hr_id + i] = false;
          }
        }
      }
    
      this.setState({
        planInputs: planInputs,
        showPlanMenu: showPlanMenu,
        showPlanMenuInfo: showPlanMenuInfo,
        showPlanMenuEdit: showPlanMenuEdit,
        showPlanMenuSpecial: showPlanMenuSpecial,
        showPlanMenuAbsence: showPlanMenuAbsence,
        showPlanMenuComp: showPlanMenuComp,
        showPlanMenuBreaks: showPlanMenuBreaks,

        loading: false,
      });

    });

  }

  async getUnpublishedRepeatShiftsWeek(repeatShift, infoExtra, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {

    let unpublished = [];

    for (let i = 0; i < 7; i++) {

        // const array = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;

        let isWorkDay = true;
        if (Constants.isEmpty(infoExtra[repeatShift.hr_info_id]) === false) {
            isWorkDay = i === 1 ? infoExtra[repeatShift.hr_info_id].info2_werkdag_maandag !== '0' : i === 2 ? infoExtra[repeatShift.hr_info_id].info2_werkdag_dinsdag !== '0' : i === 3 ? infoExtra[repeatShift.hr_info_id].info2_werkdag_woensdag !== '0' : i === 4 ? infoExtra[repeatShift.hr_info_id].info2_werkdag_donderdag !== '0' : i === 5 ? infoExtra[repeatShift.hr_info_id].info2_werkdag_vrijdag !== '0' : i === 6 ? infoExtra[repeatShift.hr_info_id].info2_werkdag_zaterdag !== '0' : infoExtra[repeatShift.hr_info_id].info2_werkdag_zondag !== '0';
        }

        const date1 = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;
        const date2 = Constants.stringToDate(repeatShift.hr_startdatum);
        const date3 = Constants.isEmpty(repeatShift.hr_einddatum) === false ? Constants.stringToDate(repeatShift.hr_einddatum) : null;
        const interval = parseInt(repeatShift.hr_interval);
        const calc1 = repeatShift.hr_type === 'day' ? date1.getDate() : repeatShift.hr_type === 'week' ? Constants.getWeekNumber2(date1) : repeatShift.hr_type === 'month' ? date1.getMonth() : date1.getFullYear();
        const calc2 = repeatShift.hr_type === 'day' ? date2.getDate() : repeatShift.hr_type === 'week' ? Constants.getWeekNumber2(date2) : repeatShift.hr_type === 'month' ? date2.getMonth() : date2.getFullYear();
        const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
        const isInInterval = repeatShift.hr_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : repeatShift.hr_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : repeatShift.hr_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
                  
        if (isInInterval && isWorkDay && date1 >= date2 && ((date3 !== null && date1 <= date3) || date3 === null)) {

            const existsInDB = await APIGET.Request(
                `SELECT info_id`,
                `FROM Info`,
                `LEFT JOIN ContractN` +
                ` ON contract_info_id = info_id`,
                `WHERE (contract_eind IS NULL OR contract_eind >= '${Constants.dateToString(date1)}') AND info_id = ${repeatShift.hr_info_id}` +
                ` AND NOT EXISTS (SELECT 1 FROM Rooster WHERE rooster_info_id = info_id AND '${Constants.dateToString(date1)}' = rooster_datum)` +
                ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${Constants.dateToString(date1)}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
                null,
                null
            );

            if (existsInDB.length > 0) {

              unpublished.push({
                ...repeatShift,
                date: date1,
              });

            }

        }

    }

    return unpublished;

}

  async getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate) {

    // reset timeRef
    this.timeRef = {};

    console.time('test');

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.teamPlanner = [];
  
        const [
          teamPlannerMonday,
          teamPlannerTuesday,
          teamPlannerWednesday,
          teamPlannerThursday,
          teamPlannerFriday,
          teamPlannerSaturday,
          teamPlannerSunday,
          _teamUsers,
        ] = await Promise.all([
          APIGetTeamPlanner.Request(Constants.formatDate(mondayDate)),
          APIGetTeamPlanner.Request(Constants.formatDate(tuesdayDate)),
          APIGetTeamPlanner.Request(Constants.formatDate(wednesdayDate)),
          APIGetTeamPlanner.Request(Constants.formatDate(thursdayDate)),
          APIGetTeamPlanner.Request(Constants.formatDate(fridayDate)),
          APIGetTeamPlanner.Request(Constants.formatDate(saturdayDate)),
          APIGetTeamPlanner.Request(Constants.formatDate(sundayDate)),
          APIGetTeamUsers.Request(),
        ]);

        for (const tp of teamPlannerMonday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
        for (const tp of teamPlannerTuesday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
        for (const tp of teamPlannerWednesday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
        for (const tp of teamPlannerThursday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
        for (const tp of teamPlannerFriday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
        for (const tp of teamPlannerSaturday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
        for (const tp of teamPlannerSunday) {
          this.teamPlanner = [...this.teamPlanner, tp];
        }
  
        this.teamUsers = _teamUsers;
  
      }

    // GET PLANNED HOURS
    const month = Constants.stringToDate(Constants.formatDate(mondayDate)).getMonth() + 1;
    const year = Constants.stringToDate(Constants.formatDate(mondayDate)).getFullYear();
    const month2 = Constants.stringToDate(Constants.formatDate(sundayDate)).getMonth() + 1;
    const year2 = Constants.stringToDate(Constants.formatDate(sundayDate)).getFullYear();

    // PREV YEAR GAINS
    let mondayP = Constants.getMonday(this.state.week, this.state.year);
    let sundayP = Constants.getSunday(this.state.week, this.state.year);

    mondayP.setFullYear(this.state.year - 1);
    sundayP.setFullYear(this.state.year - 1);

     // GET PROMISES !!!
     let [

      _getAllUsers,

      hoursMonth,
      hoursMonth2,
      hoursWeek,
      contracts,
      partSchedules,
      absenceSchedules,
      skillSchedules,
      partDayShifts,
      skillDayShifts,
      userAvailableShifts,
      plannerArrange,
      freeHours,
      plannerTemplates,
      infoExtraData,

      dayNotes,
      mondayExpectedLoad,
      tuesdayExpectedLoad,
      wednesdayExpectedLoad,
      thursdayExpectedLoad,
      fridayExpectedLoad,
      saturdayExpectedLoad,
      sundayExpectedLoad,

      exclusions,

      getUsedDayShiftsModern,

      previousGainsData,
      gains,

      plannerAvailableShifts,

      dailyGains,
      dailyGainsExpect,

      publishCount,
    //   defaultTimes,
    //   getAllUsers,

      weeklyHours,
      monthlyHours,
      monthlyHours2,

      userDefaultWorkdays,

    //   teamPlannerMonday,
    //   teamPlannerTuesday,
    //   teamPlannerWednesday,
    //   teamPlannerThursday,
    //   teamPlannerFriday,
    //   teamPlannerSaturday,
    //   teamPlannerSunday,
    //   teamUsers,

      // info inputs
      schoolschedules,
      monAv, 
      tueAv, 
      wedAv, 
      thuAv, 
      friAv, 
      satAv, 
      sunAv, 
      monUnav, 
      tueUnav, 
      wedUnav, 
      thuUnav, 
      friUnav, 
      satUnav, 
      sunUnav,

      repeating,
      repeatingShifts,
      skillsRepeatShifts,
      partRepeatShifts,
      
    ] = await Promise.all([

      this.getAllUsers(),

      APIGetAllPlannedHours.Request(month, year),
      APIGetAllPlannedHours.Request(month2, year2),
      APIGetAllPlannedHoursWeek.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate)),
      APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`),

      APIGetAllPartSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate)),
      APIGetAllAbsenceSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate)),
      APIGetAllSkillSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate)),
      APIGetAllPartDayShifts.Request(),
      APIGetAllSkillDayShifts.Request(),
      APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}'`, null, null),
      APIGET.Request(`SELECT pv_info_id, pv_index`, `FROM PlannerVolgorde`, null, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY pv_index`),
      APIGET.Request(`SELECT VerlofUren.*, Vakantie.*, Functie.*, info_id, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM VerlofUren`, `LEFT JOIN Vakantie ON vakantie_id = vu_vakantie_id LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, null, null, null),
      APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, null, `GROUP BY sjabloonm_key`, null),
      APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null),

      APIGET.Request(`SELECT *`, `FROM DagNotitie`, null, `WHERE dn_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}'`, null, null),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(mondayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(mondayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 0 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(mondayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(mondayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 0`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 1 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(tuesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 1`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 2 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(wednesdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 2`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(thursdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(thursdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 3 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(thursdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(thursdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 3`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(fridayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(fridayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 4 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(fridayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(fridayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 4`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(saturdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(saturdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 5 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(saturdayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(saturdayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 5`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM Rooster`, `RIGHT JOIN TeamRooster ON teamr_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(sundayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(sundayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 6 AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`) : APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN ('${Constants.dateToString(sundayDate)}' - INTERVAL 3 WEEK) AND ('${Constants.dateToString(sundayDate)}' - INTERVAL 1 DAY) AND rooster_publiceren = 1 AND WEEKDAY(rooster_datum) = 6`, null, `ORDER BY rooster_datum, rooster_begin, rooster_eind`),

      APIGET.Request(`SELECT *`, `FROM Uitzondering`, null, null, null, null),

      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}'`, null, null),

      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE (omzetd_datum BETWEEN '${Constants.dateToString(mondayP)}' AND '${Constants.dateToString(sundayP)}') AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE omzetd_datum BETWEEN '${Constants.dateToString(mondayP)}' AND '${Constants.dateToString(sundayP)}'`, null, `ORDER BY omzetd_datum`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${year} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${year}`, null, `ORDER BY omzetd_datum`),

      Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}'`, null, null),

      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE (omzetd_datum BETWEEN '${Constants.dateToString(mondayDate)}' AND '${Constants.dateToString(sundayDate)}') AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE (omzetd_datum BETWEEN '${Constants.dateToString(mondayDate)}' AND '${Constants.dateToString(sundayDate)}')`, null, `ORDER BY omzetd_datum`),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE (omzetv_datum BETWEEN '${Constants.dateToString(mondayDate)}' AND '${Constants.dateToString(sundayDate)}') AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetv_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE (omzetv_datum BETWEEN '${Constants.dateToString(mondayDate)}' AND '${Constants.dateToString(sundayDate)}')`, null, `ORDER BY omzetv_datum`),

      APIGetPublishCount.Request(Data.data.chosenTeam),
      //this.getDefaultTimes(),
    //   this.getAllUsers(),

      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}' AND rooster_publiceren < 2`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${month} AND YEAR(rooster_datum) = ${year} AND rooster_publiceren < 2`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${month2} AND YEAR(rooster_datum) = ${year2} AND rooster_publiceren < 2`, null, null),

      APIGET.Request(`SELECT *`, `FROM VasteDagen`, `LEFT JOIN Functie ON vd_functie_id = functie_id`, null, null, null),

    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(mondayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(tuesdayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(wednesdayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(thursdayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(fridayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(saturdayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamPlanner.Request(Constants.formatDate(sundayDate)) : [],
    //   Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGetTeamUsers.Request() : [],

        // info inputs
        Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllSchoolSchedulesYear.Request() : [],
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(mondayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 1 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(tuesdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 2 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(wednesdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 3 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(thursdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 4 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(fridayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 5 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(saturdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 6 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllAvailabilitiesDay.Request(Constants.formatDate(sundayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 0 AND sb_beschikbaar = 1`, null, `ORDER BY info_voornaam, info_achternaam`),

        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(mondayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 1 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(tuesdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 2 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(wednesdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 3 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(thursdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 4 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(fridayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 5 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(saturdayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 6 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(sundayDate)) : APIGET.Request(`SELECT sb_id, sb_beschikbaar, sb_dag, sb_begin, sb_eind, info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_kleur, functie_naam, functie_id, functie_afdeling_id`, `FROM StandaardBeschikbaarheid`, `LEFT JOIN Info ON info_id = sb_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, `WHERE sb_dag = 0 AND sb_beschikbaar = 2`, null, `ORDER BY info_voornaam, info_achternaam`),

        Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM HerBeschikbaar`, `LEFT JOIN Info ON hb_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id LEFT JOIN TeamUsers ON teamu_info_id = info_id`, `WHERE teamu_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM HerBeschikbaar`, `LEFT JOIN Info ON hb_info_id = info_id LEFT JOIN Functie ON functie_id = info_functie_id`, null, null, null),
        APIGET.Request(`SELECT *`, `FROM HerRooster`, `LEFT JOIN Info ON hr_info_id = info_id LEFT JOIN Functie ON functie_id = hr_functie_id LEFT JOIN Team ON team_id = hr_team_id`, `WHERE hr_einddatum IS NULL OR hr_einddatum >= '${Constants.dateToString(mondayDate)}'`, null, null),
        APIGET.Request(`SELECT *`, `FROM CompHerRooster`, `LEFT JOIN Competentie ON comp_id = comphr_comp_id LEFT JOIN HerRooster ON hr_id = comphr_hr_id LEFT JOIN Team ON team_id = hr_team_id`, `WHERE hr_einddatum IS NULL OR hr_einddatum >= '${Constants.dateToString(mondayDate)}'`, null, null),
        APIGET.Request(`SELECT *`, `FROM BijzHerRooster`, `LEFT JOIN Bijzonderheid ON bijz_id = bijzhr_bijz_id LEFT JOIN HerRooster ON hr_id = bijzhr_hr_id LEFT JOIN Team ON team_id = hr_team_id`, `WHERE hr_einddatum IS NULL OR hr_einddatum >= '${Constants.dateToString(mondayDate)}'`, null, null),

    ]);

    // if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

    //     this.teamPlanner = [];
  
    //     for (const tp of teamPlannerMonday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
    //     for (const tp of teamPlannerTuesday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
    //     for (const tp of teamPlannerWednesday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
    //     for (const tp of teamPlannerThursday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
    //     for (const tp of teamPlannerFriday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
    //     for (const tp of teamPlannerSaturday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
    //     for (const tp of teamPlannerSunday) {
    //       this.teamPlanner = [...this.teamPlanner, tp];
    //     }
  
    //     this.teamUsers = teamUsers;
  
    // }

    // GET REST OF THE INFO
    this.getExpectedLoad(mondayExpectedLoad, tuesdayExpectedLoad, wednesdayExpectedLoad, thursdayExpectedLoad, fridayExpectedLoad, saturdayExpectedLoad, sundayExpectedLoad);
    this.getUsedDayShiftsModern(getUsedDayShiftsModern);

    const infoExtra = Constants.convertToObject(infoExtraData, 'info2_info_id');

    let unpublishedRepeatsWeek = [];
    for (const r of repeatingShifts) {
      if (Constants.isEmpty(r.hr_team_id) === false && Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        if (r.hr_team_id === Data.data.chosenTeamObj.team_id) {
          const _unpublishedRepeatsWeek = await this.getUnpublishedRepeatShiftsWeek(r, infoExtra, mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);
          for (const u of _unpublishedRepeatsWeek) {
            unpublishedRepeatsWeek.push(u);
          }
        }
      } else {
        const _unpublishedRepeatsWeek = await this.getUnpublishedRepeatShiftsWeek(r, infoExtra, mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);
        for (const u of _unpublishedRepeatsWeek) {
          unpublishedRepeatsWeek.push(u);
        }
      }
    }

    const notesObj = Constants.convertToObject(dayNotes, 'dn_datum');

    const weeklyHoursObj = Constants.convertToObjectWithArrays(weeklyHours, 'rooster_info_id');
    const monthlyHoursObj = Constants.convertToObjectWithArrays(monthlyHours, 'rooster_info_id');
    const monthlyHours2Obj = Constants.convertToObjectWithArrays(monthlyHours2, 'rooster_info_id');
    const repeatingObj = Constants.convertToObjectWithArrays(repeating, 'hb_info_id');
    const repeatingShiftsObj = Constants.convertToObjectWithArrays(repeatingShifts, 'hr_info_id');
    const skillsRepeatShiftsObj = Constants.convertToObjectWithArrays(skillsRepeatShifts, 'comphr_hr_id');
    const partRepeatShiftsObj = Constants.convertToObject(partRepeatShifts, 'bijzhr_hr_id');

    this.repeating = repeatingObj;
    this.repeatingShifts = repeatingShiftsObj;
    this.skillsRepeatShifts = skillsRepeatShiftsObj;
    this.partRepeatShifts = partRepeatShiftsObj;
    this.unpublishedRepeatingShifts = Constants.convertToObjectWithArrays(unpublishedRepeatsWeek, 'hr_info_id');

    hoursMonth = hoursMonth === null ? [] : hoursMonth;

    if (month !== month2) {
      hoursMonth2 = hoursMonth2 === null ? [] : hoursMonth2;
    }

    // GET CONTRACTS
    const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

    // PREVIOUS GAINS
    let previousGains = {
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
    };

    for (const pgain of previousGainsData) {

      const pgainDate = Constants.stringToDate(pgain.omzetd_datum);

      if (mondayDate.getMonth() === pgainDate.getMonth() && mondayDate.getDate() === pgainDate.getDate()) {

        previousGains.mon = parseFloat(pgain.omzetd_hoeveelheid);

      } else if (tuesdayDate.getMonth() === pgainDate.getMonth() && tuesdayDate.getDate() === pgainDate.getDate()) {

        previousGains.tue = parseFloat(pgain.omzetd_hoeveelheid);

      } else if (wednesdayDate.getMonth() === pgainDate.getMonth() && wednesdayDate.getDate() === pgainDate.getDate()) {

        previousGains.wed = parseFloat(pgain.omzetd_hoeveelheid);

      } else if (thursdayDate.getMonth() === pgainDate.getMonth() && thursdayDate.getDate() === pgainDate.getDate()) {

        previousGains.thu = parseFloat(pgain.omzetd_hoeveelheid);

      } else if (fridayDate.getMonth() === pgainDate.getMonth() && fridayDate.getDate() === pgainDate.getDate()) {

        previousGains.fri = parseFloat(pgain.omzetd_hoeveelheid);

      } else if (saturdayDate.getMonth() === pgainDate.getMonth() && saturdayDate.getDate() === pgainDate.getDate()) {

        previousGains.sat = parseFloat(pgain.omzetd_hoeveelheid);

      } else if (sundayDate.getMonth() === pgainDate.getMonth() && sundayDate.getDate() === pgainDate.getDate()) {

        previousGains.sun = parseFloat(pgain.omzetd_hoeveelheid);

      }

    }

    // PRODUCTIVITY
    let totalHoursYearPr = 0;
    let totalGainsYear = 0;
    let productivityMonths = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
      12: null,
    };

    // all availabilties
    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      let availabilities = [];
      let unavailabilities = [];

      // get info
    //   let [monAv, tueAv, wedAv, thuAv, friAv, satAv, sunAv, monUnav, tueUnav, wedUnav, thuUnav, friUnav, satUnav, sunUnav] = await Promise.all([
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(mondayDate)),
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(tuesdayDate)),
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(wednesdayDate)),
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(thursdayDate)),
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(fridayDate)),
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(saturdayDate)),
    //     APIGetAllAvailabilitiesDay.Request(Constants.formatDate(sundayDate)),

    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(mondayDate)),
    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(tuesdayDate)),
    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(wednesdayDate)),
    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(thursdayDate)),
    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(fridayDate)),
    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(saturdayDate)),
    //     APIGetAllUnavailabilitiesDay.Request(Constants.formatDate(sundayDate)),
    //   ]);

      // add to array
      for (const av of monAv) {
        availabilities.push(av);
      }
      for (const av of tueAv) {
        availabilities.push(av);
      }
      for (const av of wedAv) {
        availabilities.push(av);
      }
      for (const av of thuAv) {
        availabilities.push(av);
      }
      for (const av of friAv) {
        availabilities.push(av);
      }
      for (const av of satAv) {
        availabilities.push(av);
      }
      for (const av of sunAv) {
        availabilities.push(av);
      }

      // add to array
      for (const av of monUnav) {
        unavailabilities.push(av);
      }
      for (const av of tueUnav) {
        unavailabilities.push(av);
      }
      for (const av of wedUnav) {
        unavailabilities.push(av);
      }
      for (const av of thuUnav) {
        unavailabilities.push(av);
      }
      for (const av of friUnav) {
        unavailabilities.push(av);
      }
      for (const av of satUnav) {
        unavailabilities.push(av);
      }
      for (const av of sunUnav) {
        unavailabilities.push(av);
      }

      // REPEATING AVAILABILITY
      for (const data of availabilities) {

        if (typeof this.repeating[data.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[data.info_id]) {
 
          const date1 = Constants.stringToDate(data.beschikbaar_datum);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(availabilities, 'info_id', data.info_id);
          }

        }

      }
      for (const data of unavailabilities) {

        if (typeof this.repeating[data.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[data.info_id]) {
 
          const date1 = Constants.stringToDate(data.onbeschikbaar_datum);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(unavailabilities, 'info_id', data.info_id);
          }

        }

      }

      // ADD REPEATING AVAILABILITIES
      for (const userKey in this.repeating) {

        for (const r of this.repeating[userKey]) {

          for (let i = 0; i < 7; i++) {

            const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

            const date1 = i === 1 ? Constants.stringToDate(mondayDate) : i === 2 ? Constants.stringToDate(tuesdayDate) : i === 3 ? Constants.stringToDate(wednesdayDate) : i === 4 ? Constants.stringToDate(thursdayDate) : i === 5 ? Constants.stringToDate(fridayDate) : i === 6 ? Constants.stringToDate(saturdayDate) : Constants.stringToDate(sundayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval && date1 >= date2) {

              if (r.hb_beschikbaar === '1') {

                const objToAdd = {
                  beschikbaar_id: r.hb_id + key,
                  beschikbaar_datum: Constants.dateToString(date1),
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                availabilities.push(objToAdd);

              } else if (r.hb_beschikbaar === '2') {

                const objToAdd = {
                  onbeschikbaar_id: r.hb_id + key,
                  onbeschikbaar_datum: Constants.dateToString(date1),
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                unavailabilities.push(objToAdd);

              }

            }

          }

        }

      }

      // turn to object
      const favailabilties = Constants.convertToObjectWithArrays(availabilities, 'info_id');
      const funavailabilties = Constants.convertToObjectWithArrays(unavailabilities, 'info_id');

      // inputssssss
      let availableInputs = this.state.availableInputs;
      let unavailableInputs = this.state.unavailableInputs;

      // set state
      this.setState({ allAvailabilities: favailabilties, allUnavailabilities: funavailabilties, availableInputs: availableInputs, unavailableInputs: unavailableInputs });

    } else if (Data.data.appOptions.availability === 'STANDARD') {

      let savailabilities = [];
      let sunavailabilities = [];

      // add to array
      for (const av of monAv) {
        savailabilities.push(av);
      }
      for (const av of tueAv) {
        savailabilities.push(av);
      }
      for (const av of wedAv) {
        savailabilities.push(av);
      }
      for (const av of thuAv) {
        savailabilities.push(av);
      }
      for (const av of friAv) {
        savailabilities.push(av);
      }
      for (const av of satAv) {
        savailabilities.push(av);
      }
      for (const av of sunAv) {
        savailabilities.push(av);
      }

      // add to array
      for (const av of monUnav) {
        sunavailabilities.push(av);
      }
      for (const av of tueUnav) {
        sunavailabilities.push(av);
      }
      for (const av of wedUnav) {
        sunavailabilities.push(av);
      }
      for (const av of thuUnav) {
        sunavailabilities.push(av);
      }
      for (const av of friUnav) {
        sunavailabilities.push(av);
      }
      for (const av of satUnav) {
        sunavailabilities.push(av);
      }
      for (const av of sunUnav) {
        sunavailabilities.push(av);
      }

      // turn to object
      const sfavailabilties = Constants.convertToObjectWithArrays(savailabilities, 'info_id');
      const sfunavailabilties = Constants.convertToObjectWithArrays(sunavailabilities, 'info_id');

      // inputssssss
      let savailableInputs = this.state.standardAvailableInputs;
      let sunavailableInputs = this.state.standardUnavailableInputs;

      // set state
      this.setState({ allStandardAvailabilities: sfavailabilties, allStandardUnavailabilities: sfunavailabilties, standardAvailableInputs: savailableInputs, standardUnavailableInputs: sunavailableInputs });

    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      let multipleAvail = {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      };
      
      let availabilities = [];
      let unavailabilities = [];

      // add to array
      for (const av of monAv) {

        availabilities.push(av);

        if (typeof multipleAvail.mon[av.info_id] === 'undefined') {
          multipleAvail.mon[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.mon[av.info_id].av = av;

      }
      for (const av of tueAv) {

        availabilities.push(av);

        if (typeof multipleAvail.tue[av.info_id] === 'undefined') {
          multipleAvail.tue[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.tue[av.info_id].av = av;

      }
      for (const av of wedAv) {

        availabilities.push(av);

        if (typeof multipleAvail.wed[av.info_id] === 'undefined') {
          multipleAvail.wed[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.wed[av.info_id].av = av;

      }
      for (const av of thuAv) {

        availabilities.push(av);

        if (typeof multipleAvail.thu[av.info_id] === 'undefined') {
          multipleAvail.thu[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.thu[av.info_id].av = av;

      }
      for (const av of friAv) {

        availabilities.push(av);

        if (typeof multipleAvail.fri[av.info_id] === 'undefined') {
          multipleAvail.fri[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.fri[av.info_id].av = av;

      }
      for (const av of satAv) {

        availabilities.push(av);

        if (typeof multipleAvail.sat[av.info_id] === 'undefined') {
          multipleAvail.sat[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.sat[av.info_id].av = av;

      }
      for (const av of sunAv) {

        availabilities.push(av);

        if (typeof multipleAvail.sun[av.info_id] === 'undefined') {
          multipleAvail.sun[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.sun[av.info_id].av = av;

      }

      // unav
      for (const av of monUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.mon[av.info_id] === 'undefined') {
          multipleAvail.mon[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.mon[av.info_id].unav = av;

      }
      for (const av of tueUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.tue[av.info_id] === 'undefined') {
          multipleAvail.tue[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.tue[av.info_id].unav = av;

      }
      for (const av of wedUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.wed[av.info_id] === 'undefined') {
          multipleAvail.wed[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.wed[av.info_id].unav = av;

      }
      for (const av of thuUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.thu[av.info_id] === 'undefined') {
          multipleAvail.thu[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.thu[av.info_id].unav = av;

      }
      for (const av of friUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.fri[av.info_id] === 'undefined') {
          multipleAvail.fri[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.fri[av.info_id].unav = av;

      }
      for (const av of satUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.sat[av.info_id] === 'undefined') {
          multipleAvail.sat[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.sat[av.info_id].unav = av;

      }
      for (const av of sunUnav) {

        unavailabilities.push(av);

        if (typeof multipleAvail.sun[av.info_id] === 'undefined') {
          multipleAvail.sun[av.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail.sun[av.info_id].unav = av;

      }

      // REPEATING AVAILABILITY
      for (const data of availabilities) {

        if (typeof this.repeating[data.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[data.info_id]) {
 
          const date1 = Constants.stringToDate(data.beschikbaar_datum);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(availabilities, 'info_id', data.info_id);
          }

        }

      }
      for (const data of unavailabilities) {

        if (typeof this.repeating[data.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[data.info_id]) {
 
          const date1 = Constants.stringToDate(data.onbeschikbaar_datum);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(unavailabilities, 'info_id', data.info_id);
          }

        }

      }

      // ADD REPEATING AVAILABILITIES
      for (const userKey in this.repeating) {

        for (const r of this.repeating[userKey]) {

          for (let i = 0; i < 7; i++) {

            const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

            const date1 = i === 1 ? Constants.stringToDate(mondayDate) : i === 2 ? Constants.stringToDate(tuesdayDate) : i === 3 ? Constants.stringToDate(wednesdayDate) : i === 4 ? Constants.stringToDate(thursdayDate) : i === 5 ? Constants.stringToDate(fridayDate) : i === 6 ? Constants.stringToDate(saturdayDate) : Constants.stringToDate(sundayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval && date1 >= date2) {

              if (r.hb_beschikbaar === '1') {

                const objToAdd = {
                  beschikbaar_id: r.hb_id + key,
                  beschikbaar_datum: Constants.dateToString(date1),
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                if (typeof multipleAvail[key][r.hb_info_id] === 'undefined') {
                  multipleAvail[key][r.hb_info_id] = { school: null, unav: null, av: null };
                }

                availabilities.push(objToAdd);
                multipleAvail[key][r.hb_info_id].av = objToAdd;

              } else if (r.hb_beschikbaar === '2') {

                const objToAdd = {
                  onbeschikbaar_id: r.hb_id + key,
                  onbeschikbaar_datum: Constants.dateToString(date1),
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                if (typeof multipleAvail[key][r.hb_info_id] === 'undefined') {
                  multipleAvail[key][r.hb_info_id] = { school: null, unav: null, av: null };
                }

                unavailabilities.push(objToAdd);
                multipleAvail[key][r.hb_info_id].unav = objToAdd;

              }

            }

          }

        }

      }

      // turn to object
      const fschoolschedules = Constants.convertToObjectWithArrays(schoolschedules, 'info_id');
      const funavailabilties = Constants.convertToObjectWithArrays(unavailabilities, 'info_id');
      const favailabilities = Constants.convertToObjectWithArrays(availabilities, 'info_id');

      // inputsss
      let schoolInputs = this.state.schoolInputs;
      let unavailableInputs = this.state.unavailableInputs;
      let availableInputs = this.state.availableInputs;

      // multiple availabilities
      for (const school of schoolschedules) {

        const dayKey = school.schoolrooster_dag === '1' ? 'mon' : school.schoolrooster_dag === '2' ? 'tue' : school.schoolrooster_dag === '3' ? 'wed' : school.schoolrooster_dag === '4' ? 'thu' : school.schoolrooster_dag === '5' ? 'fri' : 'mon';

        if (typeof multipleAvail[dayKey][school.info_id] === 'undefined') {
          multipleAvail[dayKey][school.info_id] = { school: null, unav: null, av: null };
        }

        multipleAvail[dayKey][school.info_id].school = school;

      }

      // set state
      this.setState({ allSchoolSchedules: fschoolschedules, allUnavailabilities: funavailabilties, allAvailabilities: favailabilities, unavailableInputs: unavailableInputs, availableInputs: availableInputs, schoolInputs: schoolInputs, multipleAvail: multipleAvail });

    } 
    
    // else if (Data.data.appOptions.availability === 'UNAVAILABILITY') {

    //   let unavailabilities = [];

    //   // add to array
    //   for (const av of monUnav) {
    //     unavailabilities.push(av);
    //   }
    //   for (const av of tueUnav) {
    //     unavailabilities.push(av);
    //   }
    //   for (const av of wedUnav) {
    //     unavailabilities.push(av);
    //   }
    //   for (const av of thuUnav) {
    //     unavailabilities.push(av);
    //   }
    //   for (const av of friUnav) {
    //     unavailabilities.push(av);
    //   }
    //   for (const av of satUnav) {
    //     unavailabilities.push(av);
    //   }
    //   for (const av of sunUnav) {
    //     unavailabilities.push(av);
    //   }
      
    //   // turn to object
    //   const funavailabilties = Constants.convertToObjectWithArrays(unavailabilities, 'info_id');

    //   // inputs
    //   let unavailableInputs = this.state.unavailableInputs;

    //   // set state
    //   this.setState({ allUnavailabilities: funavailabilties, unavailableInputs: unavailableInputs });

    // }

    // GET PARTICULARITIES FROM SCHEDULES
    //const partSchedules = await APIGetAllPartSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate));
    const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
    if (Object.keys(this.partBackup).length === 0) {
      this.partBackup = partSchedulesObj;
    }

    // GET ABSENCES FROM SCHEDULES
    //const absenceSchedules = await APIGetAllAbsenceSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate));
    const absenceSchedulesObj = Constants.convertToObject(absenceSchedules, 'rooster_id');
    if (Object.keys(this.absenceBackup).length === 0) {
      this.absenceBackup = absenceSchedulesObj;
    }

    // GET SKILLS FROM SCHEDULES
    //const skillSchedules = await APIGetAllSkillSchedules.Request(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate));
    const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
    if (Object.keys(this.skillsBackup).length === 0) {
      this.skillsBackup = skillSchedulesObj;
    }

    // GET PARTICULARITIES FROM DAY SHIFTS
    //const partDayShifts = await APIGetAllPartDayShifts.Request();
    const partDayShiftsObj = Constants.convertToObjectPrefix(partDayShifts, 'dagdienst_id', 'd');

    // GET SKILLS FROM SCHEDULES
    //const skillDayShifts = await APIGetAllSkillDayShifts.Request();
    const skillDayShiftsObj = Constants.convertToObjectWithArraysPrefix(skillDayShifts, 'dagdienst_id', 'd');

    // GET DAY NOTES
    //await this.getDayNotes(Constants.formatDate(mondayDate), Constants.formatDate(sundayDate));

    // GET USER PUT AVAILABLESHIFTS
    // const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}'`, null, null);

    // GET PLANNER AVAILABLESHIFTS
    // let plannerAvailableShifts = [];
    // if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
    //   plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    // } else {
    //   plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${Constants.formatDate(mondayDate)}' AND '${Constants.formatDate(sundayDate)}'`, null, null);
    // }

    // GET PLANNER ARRANGEMENT
    // const plannerArrange = await APIGET.Request(`SELECT pv_info_id, pv_index`, `FROM PlannerVolgorde`, null, Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? `WHERE pv_team_id = ${Data.data.chosenTeamObj.team_id}` : null, null, `ORDER BY pv_index`);

    // GET VERLOFUREN
    // const freeHours = await APIGET.Request(`SELECT VerlofUren.*, Vakantie.*, Functie.*, info_id, info_voornaam, info_tussenvoegsel, info_achternaam`, `FROM VerlofUren`, `LEFT JOIN Vakantie ON vakantie_id = vu_vakantie_id LEFT JOIN Info ON info_id = vakantie_info_id LEFT JOIN Functie ON functie_id = info_functie_id`, null, null, null);
    const freeHoursObj = Constants.convertToObject(freeHours, 'vu_vakantie_id');

    // GET PLANNING TEMPLATES
    //const plannerTemplates = await APIGetAllTemplates.Request();
    // const plannerTemplates = await APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, null, `GROUP BY sjabloonm_key`, null);
    let tempKey = 0;
    for (const temp of plannerTemplates) {
      if (parseInt(temp.sjabloonm_key) > tempKey) {
        tempKey = parseInt(temp.sjabloonm_key);
      }
    }

    // GET MAX WORKING DAYS CUMULATIVE
    let highest = 0;
    for (const usr of infoExtraData) {
      const maxCumulative = isNaN(parseInt(usr.info2_opeenvolgend)) === true ? 0 : parseInt(usr.info2_opeenvolgend);
      if (maxCumulative > highest) {
        highest = maxCumulative;
      }
    }

    // GET ALL WORKING DAYS FROM HIGHEST CUMULATIVE DAYS SETTING 
    if (highest > 0) {

      highest += 7;

      const planUsersMaxData = await APIGET.Request(
        `SELECT rooster_datum, rooster_info_id`,
        `FROM Rooster`,
        null,
        `WHERE rooster_info_id IS NOT NULL AND rooster_datum BETWEEN (CAST('${mondayDate}' AS DATE) - INTERVAL ${highest} DAY) AND (CAST('${mondayDate}' AS DATE) + INTERVAL ${highest} DAY)`,
        null,
        `ORDER BY rooster_datum`
      );

      const planUsersMaxCumulative = Constants.convertToObjectWithArrays(planUsersMaxData, 'rooster_info_id');

      this.setState({ planUsersMaxCumulative: planUsersMaxCumulative, highestCumulative: (highest - 7) });

    }

    // get expectation load data
    // await this.getExpectedLoad(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    this.setState({
      weeklyHours: weeklyHoursObj,
      monthlyHours: monthlyHoursObj,
      monthlyHours2: monthlyHours2Obj,

      hoursMonth: hoursMonth,
      hoursMonth2: hoursMonth2,
      hoursWeek: hoursWeek,
      contracts: contracts,
      contractsObj: contractsObj,
      month: month,
      month2: month2,
      year2: year2,
      productivityTotal: Constants.round(totalGainsYear / totalHoursYearPr),
      productivityMonths: productivityMonths,

      partSchedules: partSchedulesObj,
      absenceSchedules: absenceSchedulesObj,
      skillSchedules: skillSchedulesObj,

      partDayShifts: partDayShiftsObj,
      skillDayShifts: skillDayShiftsObj,

      plannerTemplates: plannerTemplates,
      highestTempKey: tempKey,

      infoExtra: infoExtra,

      userAvailableShifts: userAvailableShifts,
      plannerAvailableShifts: plannerAvailableShifts,

      plannerArrange: plannerArrange,

      freeHours: freeHoursObj,

      previousGains: previousGains,

      exclusions: exclusions,
      //overhoursDone: overhoursDoneObj,

      userDefaultWorkdays: userDefaultWorkdays,

      dayNotes: notesObj,
      toPublish: publishCount !== null ? publishCount : 0,
      
    });
     
    // GET OTHER DATA
    const [
        _mondayPlan,
        _tuesdayPlan,
        _wednesdayPlan,
        _thursdayPlan,
        _fridayPlan,
        _saturdayPlan,
        _sundayPlan,

        _mondayPlanEmpty,
        _tuesdayPlanEmpty,
        _wednesdayPlanEmpty,
        _thursdayPlanEmpty,
        _fridayPlanEmpty,
        _saturdayPlanEmpty,
        _sundayPlanEmpty,

        _mondayDayShift,
        _tuesdayDayShift,
        _wednesdayDayShift,
        _thursdayDayShift,
        _fridayDayShift,
        _saturdayDayShift,
        _sundayDayShift,

        _availableData1,
        _availableData2,
        _availableData3,
        _availableData4,
        _availableData5,
        _availableData6,
        _availableData7,

        _unavailableData1,
        _unavailableData2,
        _unavailableData3,
        _unavailableData4,
        _unavailableData5,
        _unavailableData6,
        _unavailableData7,

        _notFilledData1,
        _notFilledData2,
        _notFilledData3,
        _notFilledData4,
        _notFilledData5,
        _notFilledData6,
        _notFilledData7,

        _nfSchoolData1,
        _nfSchoolData2,
        _nfSchoolData3,
        _nfSchoolData4,
        _nfSchoolData5,
        _nfSchoolData6,
        _nfSchoolData7,

        _schoolScheduleData1,
        _schoolScheduleData2,
        _schoolScheduleData3,
        _schoolScheduleData4,
        _schoolScheduleData5,
        _schoolScheduleData6,
        _schoolScheduleData7,

        _schoolHolidaysData1,

        _standardAvailableData1,
        _standardAvailableData2,
        _standardAvailableData3,
        _standardAvailableData4,
        _standardAvailableData5,
        _standardAvailableData6,
        _standardAvailableData7,

        _standardUnavailableData1,
        _standardUnavailableData2,
        _standardUnavailableData3,
        _standardUnavailableData4,
        _standardUnavailableData5,
        _standardUnavailableData6,
        _standardUnavailableData7,

        _standardNotFilledData1,
        _standardNotFilledData2,
        _standardNotFilledData3,
        _standardNotFilledData4,
        _standardNotFilledData5,
        _standardNotFilledData6,
        _standardNotFilledData7,

        _noAvailability1,
        _noAvailability2,
        _noAvailability3,
        _noAvailability4,
        _noAvailability5,
        _noAvailability6,
        _noAvailability7,
    ] = await Promise.all([
        APIGetPlanner.Request(Constants.dateToString(mondayDate)),
        APIGetPlanner.Request(Constants.dateToString(tuesdayDate)),
        APIGetPlanner.Request(Constants.dateToString(wednesdayDate)),
        APIGetPlanner.Request(Constants.dateToString(thursdayDate)),
        APIGetPlanner.Request(Constants.dateToString(fridayDate)),
        APIGetPlanner.Request(Constants.dateToString(saturdayDate)),
        APIGetPlanner.Request(Constants.dateToString(sundayDate)),

        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(mondayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(tuesdayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(wednesdayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(thursdayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(fridayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(saturdayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
        APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(sundayDate)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),

        APIGetSpecificDefaultDayShift.Request(1, Constants.dateToString(mondayDate), this.state.shiftTemp),
        APIGetSpecificDefaultDayShift.Request(2, Constants.dateToString(tuesdayDate), this.state.shiftTemp),
        APIGetSpecificDefaultDayShift.Request(3, Constants.dateToString(wednesdayDate), this.state.shiftTemp),
        APIGetSpecificDefaultDayShift.Request(4, Constants.dateToString(thursdayDate), this.state.shiftTemp),
        APIGetSpecificDefaultDayShift.Request(5, Constants.dateToString(fridayDate), this.state.shiftTemp),
        APIGetSpecificDefaultDayShift.Request(6, Constants.dateToString(saturdayDate), this.state.shiftTemp),
        APIGetSpecificDefaultDayShift.Request(0, Constants.dateToString(sundayDate), this.state.shiftTemp),

        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(1, Constants.formatDate(mondayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(2, Constants.formatDate(tuesdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(3, Constants.formatDate(wednesdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(4, Constants.formatDate(thursdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(5, Constants.formatDate(fridayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(6, Constants.formatDate(saturdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getAvailableData(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(1, Constants.formatDate(mondayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(2, Constants.formatDate(tuesdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(3, Constants.formatDate(wednesdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(4, Constants.formatDate(thursdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(5, Constants.formatDate(fridayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(6, Constants.formatDate(saturdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'SCHOOL' ? this.getUnavailableData(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(1, Constants.formatDate(mondayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(2, Constants.formatDate(tuesdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(3, Constants.formatDate(wednesdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(4, Constants.formatDate(thursdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(5, Constants.formatDate(fridayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(6, Constants.formatDate(saturdayDate)) : null,
        Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ? this.getNotFilledData(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(1, Constants.formatDate(mondayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(2, Constants.formatDate(tuesdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(3, Constants.formatDate(wednesdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(4, Constants.formatDate(thursdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(5, Constants.formatDate(fridayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(6, Constants.formatDate(saturdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getNotFilledSchool(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(1, Constants.formatDate(mondayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(2, Constants.formatDate(tuesdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(3, Constants.formatDate(wednesdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(4, Constants.formatDate(thursdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(5, Constants.formatDate(fridayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(6, Constants.formatDate(saturdayDate)) : null,
        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolSchedule(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'SCHOOL' ? this.getSchoolHolidays() : null,

        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(1, Constants.formatDate(mondayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(2, Constants.formatDate(tuesdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(3, Constants.formatDate(wednesdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(4, Constants.formatDate(thursdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(5, Constants.formatDate(fridayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(6, Constants.formatDate(saturdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardAvailability(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(1, Constants.formatDate(mondayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(2, Constants.formatDate(tuesdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(3, Constants.formatDate(wednesdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(4, Constants.formatDate(thursdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(5, Constants.formatDate(fridayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(6, Constants.formatDate(saturdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardUnavailability(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(1, Constants.formatDate(mondayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(2, Constants.formatDate(tuesdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(3, Constants.formatDate(wednesdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(4, Constants.formatDate(thursdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(5, Constants.formatDate(fridayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(6, Constants.formatDate(saturdayDate)) : null, 
        Data.data.appOptions.availability === 'STANDARD' ? this.getStandardNotFilled(0, Constants.formatDate(sundayDate)) : null,

        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(1, Constants.formatDate(mondayDate)) : null,
        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(2, Constants.formatDate(tuesdayDate)) : null,
        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(3, Constants.formatDate(wednesdayDate)) : null,
        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(4, Constants.formatDate(thursdayDate)) : null,
        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(5, Constants.formatDate(fridayDate)) : null,
        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(6, Constants.formatDate(saturdayDate)) : null,
        Data.data.appOptions.availability === 'NONE' ? this.getAllPeopleNoAvailabilityData(0, Constants.formatDate(sundayDate)) : null,
    ]);

    this.getPlannerData(1, _mondayPlan); 
    this.getPlannerData(2, _tuesdayPlan);
    this.getPlannerData(3, _wednesdayPlan); 
    this.getPlannerData(4, _thursdayPlan);
    this.getPlannerData(5, _fridayPlan); 
    this.getPlannerData(6, _saturdayPlan); 
    this.getPlannerData(0, _sundayPlan); 

    this.getEmptyPlannerData(1, _mondayPlanEmpty); 
    this.getEmptyPlannerData(2, _tuesdayPlanEmpty); 
    this.getEmptyPlannerData(3, _wednesdayPlanEmpty); 
    this.getEmptyPlannerData(4, _thursdayPlanEmpty); 
    this.getEmptyPlannerData(5, _fridayPlanEmpty); 
    this.getEmptyPlannerData(6, _saturdayPlanEmpty);
    this.getEmptyPlannerData(0, _sundayPlanEmpty);

    this.getDayShifts(1, mondayDate, _mondayDayShift);
    this.getDayShifts(2, tuesdayDate, _tuesdayDayShift);
    this.getDayShifts(3, wednesdayDate, _wednesdayDayShift);
    this.getDayShifts(4, thursdayDate, _thursdayDayShift);
    this.getDayShifts(5, fridayDate, _fridayDayShift);
    this.getDayShifts(6, saturdayDate, _saturdayDayShift);
    this.getDayShifts(0, sundayDate, _sundayDayShift);

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;
    let showDayShiftMenu = this.state.showDayShiftMenu;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
          if (typeof this.inactiveUsers[plan.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.plannerData[key], 'info_id', plan.info_id);
              continue;
          }
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.dayShiftsData) {
        for (const plan of this.dayShiftsData[key]) {
            planInputs['d' + plan.dagdienst_id] = `${plan.dagdienst_begin.substr(0, 5)} - ${plan.dagdienst_eind.substr(0, 5)}`;
            showDayShiftMenu['d' + plan.dagdienst_id] = false;
        }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    let availableInputs = this.state.availableInputs;
    let unavailableInputs = this.state.unavailableInputs;
    let notFilledInputs = this.state.notFilledInputs;
    let schoolNotFilledInputs = this.state.schoolNotFilledInputs;
    let schoolInputs = this.state.schoolInputs;
    let schoolHolidayInputs = this.state.schoolHolidayInputs;
    let standardAvailableInputs = this.state.standardAvailableInputs;
    let standardUnavailableInputs = this.state.standardUnavailableInputs;
    let standardNotFilledInputs = this.state.standardNotFilledInputs;
    let noAvailableInputs = this.state.noAvailableInputs;

    if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.availableData.mon = Constants.getTeamAccounts(this.teamUsers, this.availableData.mon);
        this.availableData.tue = Constants.getTeamAccounts(this.teamUsers, this.availableData.tue);
        this.availableData.wed = Constants.getTeamAccounts(this.teamUsers, this.availableData.wed);
        this.availableData.thu = Constants.getTeamAccounts(this.teamUsers, this.availableData.thu);
        this.availableData.fri = Constants.getTeamAccounts(this.teamUsers, this.availableData.fri);
        this.availableData.sat = Constants.getTeamAccounts(this.teamUsers, this.availableData.sat);
        this.availableData.sun = Constants.getTeamAccounts(this.teamUsers, this.availableData.sun);

        this.unavailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.mon);
        this.unavailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.tue);
        this.unavailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.wed);
        this.unavailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.thu);
        this.unavailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.fri);
        this.unavailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sat);
        this.unavailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sun);
  
        this.notFilledData.mon = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.mon);
        this.notFilledData.tue = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.tue);
        this.notFilledData.wed = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.wed);
        this.notFilledData.thu = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.thu);
        this.notFilledData.fri = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.fri);
        this.notFilledData.sat = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.sat);
        this.notFilledData.sun = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.sun);
  
      }

      for (const key in this.availableData) {
        for (const inp of this.availableData[key]) {
          // INACTIVE
          if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.availableData[key], 'info_id', inp.info_id);
              continue;
          }
          // REPEATING AVAILABILITY
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
 
            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this.availableData[key], 'info_id', inp.info_id);
            }

          }
          availableInputs[inp.info_id] = ``;
        }
      }
      for (const key in this.unavailableData) {
          for (const inp of this.unavailableData[key]) {
              // INACTIVE
              if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                  Constants.removeFromArray2(this.unavailableData[key], 'info_id', inp.info_id);
                  continue;
              }
              // REPEATING AVAILABILITY
              if (typeof this.repeating[inp.info_id] === 'undefined') {
                continue;
              }

              for (const r of this.repeating[inp.info_id]) {
 
                const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
                const date2 = Constants.stringToDate(r.hb_datum);
                const interval = parseInt(r.hb_interval);
                const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
                const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
                const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
                const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
                if (isInInterval && date1 >= date2) {
                  Constants.removeFromArray2(this.unavailableData[key], 'info_id', inp.info_id);
                }

              }
              unavailableInputs[inp.info_id] = ``;
          }
      }
      for (const key in this.notFilledData) {
          for (const inp of this.notFilledData[key]) {
            // INACTIVE
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.notFilledData[key], 'info_id', inp.info_id);
                continue;
            }
            // REPEATING AVAILABILITY
            if (typeof this.repeating[inp.info_id] === 'undefined') {
              continue;
            }

            for (const r of this.repeating[inp.info_id]) {
 
              const date1 = key === 'mon' ? mondayDate : key === 'tue' ? tuesdayDate : key === 'wed' ? wednesdayDate : key === 'thu' ? thursdayDate : key === 'fri' ? fridayDate : key === 'sat' ? saturdayDate : key === 'sun' ? sundayDate : mondayDate;
              const date2 = Constants.stringToDate(r.hb_datum);
              const interval = parseInt(r.hb_interval);
              const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
              const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
              const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
              const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
              if (isInInterval && date1 >= date2) {
                Constants.removeFromArray2(this.notFilledData[key], 'info_id', inp.info_id);
              }

            }
            notFilledInputs[inp.info_id] = ``;
          }
      }

      // REPEATING AVAILABILITIES
      for (const key in this._allAvailableData) {
        for (const inp of this._allAvailableData[key]) {
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
  
            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this._allAvailableData[key], 'info_id', inp.info_id);
            }

          }
        }
      }
      for (const key in this._allUnavailableData) {
          for (const inp of this._allUnavailableData[key]) {
            if (typeof this.repeating[inp.info_id] === 'undefined') {
              continue;
            }

            for (const r of this.repeating[inp.info_id]) {
 
                const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
                const date2 = Constants.stringToDate(r.hb_datum);
                const interval = parseInt(r.hb_interval);
                const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
                const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
                const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
                const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
                if (isInInterval && date1 >= date2) {
                  Constants.removeFromArray2(this._allUnavailableData[key], 'info_id', inp.info_id);
                }

              }
          }
      }

      // ADD REPEATING AVAILABILITIES
      for (const userKey in this.repeating) {

        for (const r of this.repeating[userKey]) {

          for (let i = 0; i < 7; i++) {

            const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

            const date1 = i === 1 ? Constants.stringToDate(mondayDate) : i === 2 ? Constants.stringToDate(tuesdayDate) : i === 3 ? Constants.stringToDate(wednesdayDate) : i === 4 ? Constants.stringToDate(thursdayDate) : i === 5 ? Constants.stringToDate(fridayDate) : i === 6 ? Constants.stringToDate(saturdayDate) : Constants.stringToDate(sundayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval && date1 >= date2) {

              if (r.hb_beschikbaar === '1') {

                const objToAdd = {
                  beschikbaar_id: r.hb_id + key,
                  beschikbaar_datum: Constants.dateToString(date1),
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allAvailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.availableData[key].push(objToAdd);
                }

              } else if (r.hb_beschikbaar === '2') {

                const objToAdd = {
                  onbeschikbaar_id: r.hb_id + key,
                  onbeschikbaar_datum: Constants.dateToString(date1),
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allUnavailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.unavailableData[key].push(objToAdd);
                }

              }

            }

          }

        }

      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.availableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.notFilledData[key], av);
              }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.availableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.notFilledData[key], av);
              }
            }
          }
        }
      }

    }

    if(Data.data.appOptions.availability === 'SCHOOL') {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.availableData.mon = Constants.getTeamAccounts(this.teamUsers, this.availableData.mon);
        this.availableData.tue = Constants.getTeamAccounts(this.teamUsers, this.availableData.tue);
        this.availableData.wed = Constants.getTeamAccounts(this.teamUsers, this.availableData.wed);
        this.availableData.thu = Constants.getTeamAccounts(this.teamUsers, this.availableData.thu);
        this.availableData.fri = Constants.getTeamAccounts(this.teamUsers, this.availableData.fri);
        this.availableData.sat = Constants.getTeamAccounts(this.teamUsers, this.availableData.sat);
        this.availableData.sun = Constants.getTeamAccounts(this.teamUsers, this.availableData.sun);

        this.unavailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.mon);
        this.unavailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.tue);
        this.unavailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.wed);
        this.unavailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.thu);
        this.unavailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.fri);
        this.unavailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sat);
        this.unavailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sun);

        this.notFilledSchool.mon = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.mon);
        this.notFilledSchool.tue = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.tue);
        this.notFilledSchool.wed = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.wed);
        this.notFilledSchool.thu = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.thu);
        this.notFilledSchool.fri = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.fri);
        this.notFilledSchool.sat = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.sat);
        this.notFilledSchool.sun = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.sun);

        this.schoolScheduleData.mon = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.mon);
        this.schoolScheduleData.tue = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.tue);
        this.schoolScheduleData.wed = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.wed);
        this.schoolScheduleData.thu = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.thu);
        this.schoolScheduleData.fri = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.fri);
        this.schoolScheduleData.sat = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.sat);
        this.schoolScheduleData.sun = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.sun);

        this.schoolHolidayData.mon = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.mon);
        this.schoolHolidayData.tue = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.tue);
        this.schoolHolidayData.wed = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.wed);
        this.schoolHolidayData.thu = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.thu);
        this.schoolHolidayData.fri = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.fri);
        this.schoolHolidayData.sat = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.sat);
        this.schoolHolidayData.sun = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.sun);

      }

      for (const key in this.availableData) {
        for (const inp of this.availableData[key]) {
          // INACTIVE
          if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.availableData[key], 'info_id', inp.info_id);
              continue;
          }
          // REPEATING AVAILABILITY
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
 
            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this.availableData[key], 'info_id', inp.info_id);
            }

          }
          availableInputs[inp.info_id] = ``;
        }
      }
      for (const key in this.unavailableData) {
          for (const inp of this.unavailableData[key]) {
              // INACTIVE
              if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                  Constants.removeFromArray2(this.unavailableData[key], 'info_id', inp.info_id);
                  continue;
              }
              // REPEATING AVAILABILITY
              if (typeof this.repeating[inp.info_id] === 'undefined') {
                continue;
              }

              for (const r of this.repeating[inp.info_id]) {
 
                const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
                const date2 = Constants.stringToDate(r.hb_datum);
                const interval = parseInt(r.hb_interval);
                const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
                const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
                const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
                const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
                if (isInInterval && date1 >= date2) {
                  Constants.removeFromArray2(this.unavailableData[key], 'info_id', inp.info_id);
                }

              }
              unavailableInputs[inp.info_id] = ``;
          }
      }
      for (const key in this.notFilledSchool) {
        for (const inp of this.notFilledSchool[key]) {
          if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.notFilledSchool[key], 'info_id', inp.info_id);
              continue;
          }
          // REPEATING AVAILABILITY
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
 
            const date1 = key === 'mon' ? mondayDate : key === 'tue' ? tuesdayDate : key === 'wed' ? wednesdayDate : key === 'thu' ? thursdayDate : key === 'fri' ? fridayDate : key === 'sat' ? saturdayDate : key === 'sun' ? sundayDate : mondayDate;
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this.notFilledSchool[key], 'info_id', inp.info_id);
            }

          }
          schoolNotFilledInputs[inp.info_id] = ``;
        }
      }
      for (const key in this.schoolScheduleData) {
          for (const inp of this.schoolScheduleData[key]) {
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.schoolScheduleData[key], 'info_id', inp.info_id);
                continue;
            }
            // REPEATING AVAILABILITY
            if (typeof this.repeating[inp.info_id] === 'undefined') {
              continue;
            }

            for (const r of this.repeating[inp.info_id]) {
 
              const date1 = key === 'mon' ? mondayDate : key === 'tue' ? tuesdayDate : key === 'wed' ? wednesdayDate : key === 'thu' ? thursdayDate : key === 'fri' ? fridayDate : key === 'sat' ? saturdayDate : key === 'sun' ? sundayDate : mondayDate;
              const date2 = Constants.stringToDate(r.hb_datum);
              const interval = parseInt(r.hb_interval);
              const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
              const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
              const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
              const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
              if (isInInterval && date1 >= date2) {
                Constants.removeFromArray2(this.schoolScheduleData[key], 'info_id', inp.info_id);
              }

            }
            schoolInputs[inp.info_id] = ``;
          }
      }
      for (const key in this.schoolHolidayData) {
          for (const inp of this.schoolHolidayData[key]) {
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.schoolHolidayData[key], 'info_id', inp.info_id);
                continue;
            }
            // REPEATING AVAILABILITY
            if (typeof this.repeating[inp.info_id] === 'undefined') {
              continue;
            }

            for (const r of this.repeating[inp.info_id]) {
 
              const date1 = key === 'mon' ? mondayDate : key === 'tue' ? tuesdayDate : key === 'wed' ? wednesdayDate : key === 'thu' ? thursdayDate : key === 'fri' ? fridayDate : key === 'sat' ? saturdayDate : key === 'sun' ? sundayDate : mondayDate;
              const date2 = Constants.stringToDate(r.hb_datum);
              const interval = parseInt(r.hb_interval);
              const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
              const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
              const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
              const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
              if (isInInterval && date1 >= date2) {
                Constants.removeFromArray2(this.schoolHolidayData[key], 'info_id', inp.info_id);
              }

            }
            schoolHolidayInputs[inp.info_id] = ``;
          }
      }

      // REPEATING AVAILABILITIES
      for (const key in this._allAvailableData) {
        for (const inp of this._allAvailableData[key]) {
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
  
            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this._allAvailableData[key], 'info_id', inp.info_id);
            }

          }
        }
      }
      for (const key in this._allUnavailableData) {
          for (const inp of this._allUnavailableData[key]) {
            if (typeof this.repeating[inp.info_id] === 'undefined') {
              continue;
            }

            for (const r of this.repeating[inp.info_id]) {
 
                const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
                const date2 = Constants.stringToDate(r.hb_datum);
                const interval = parseInt(r.hb_interval);
                const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
                const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
                const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
                const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
                if (isInInterval && date1 >= date2) {
                  Constants.removeFromArray2(this._allUnavailableData[key], 'info_id', inp.info_id);
                }

              }
          }
      }

      // ADD REPEATING AVAILABILITIES
      for (const userKey in this.repeating) {

        for (const r of this.repeating[userKey]) {

          for (let i = 0; i < 7; i++) {

            const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

            const date1 = i === 1 ? Constants.stringToDate(mondayDate) : i === 2 ? Constants.stringToDate(tuesdayDate) : i === 3 ? Constants.stringToDate(wednesdayDate) : i === 4 ? Constants.stringToDate(thursdayDate) : i === 5 ? Constants.stringToDate(fridayDate) : i === 6 ? Constants.stringToDate(saturdayDate) : Constants.stringToDate(sundayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval && date1 >= date2) {

              if (r.hb_beschikbaar === '1') {

                const objToAdd = {
                  beschikbaar_id: r.hb_id + key,
                  beschikbaar_datum: Constants.dateToString(date1),
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allAvailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.availableData[key].push(objToAdd);
                }

              } else if (r.hb_beschikbaar === '2') {

                const objToAdd = {
                  onbeschikbaar_id: r.hb_id + key,
                  onbeschikbaar_datum: Constants.dateToString(date1),
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allUnavailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.unavailableData[key].push(objToAdd);
                }

              }

            }

          }

        }

      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.availableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledSchool[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.notFilledSchool[key], av);
                }
            }
            for (const av of this.schoolScheduleData[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolScheduleData[key], av);
                }
            }
            for (const av of this.schoolHolidayData[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolHolidayData[key], av);
                }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.availableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledSchool[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.notFilledSchool[key], av);
                }
            }
            for (const av of this.schoolScheduleData[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolScheduleData[key], av);
                }
            }
            for (const av of this.schoolHolidayData[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolHolidayData[key], av);
                }
            }
          }
        }
      }

    }

    if(Data.data.appOptions.availability === 'STANDARD') {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.standardAvailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.mon);
        this.standardAvailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.tue);
        this.standardAvailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.wed);
        this.standardAvailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.thu);
        this.standardAvailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.fri);
        this.standardAvailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.sat);
        this.standardAvailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.sun);

        this.standardUnavailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.mon);
        this.standardUnavailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.tue);
        this.standardUnavailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.wed);
        this.standardUnavailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.thu);
        this.standardUnavailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.fri);
        this.standardUnavailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.sat);
        this.standardUnavailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.sun);

        this.standardNotFilledData.mon = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.mon);
        this.standardNotFilledData.tue = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.tue);
        this.standardNotFilledData.wed = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.wed);
        this.standardNotFilledData.thu = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.thu);
        this.standardNotFilledData.fri = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.fri);
        this.standardNotFilledData.sat = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.sat);
        this.standardNotFilledData.sun = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.sun);

      }

      for (const key in this.standardAvailableData) {
        for (const inp of this.standardAvailableData[key]) {
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.standardAvailableData[key], 'info_id', inp.info_id);
                continue;
            }
          standardAvailableInputs[inp.info_id] = ``;
        }
      }
      for (const key in this.standardUnavailableData) {
        for (const inp of this.standardUnavailableData[key]) {
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.standardUnavailableData[key], 'info_id', inp.info_id);
                continue;
            }
          standardUnavailableInputs[inp.info_id] = ``;
        }
      }
      for (const key in this.standardNotFilledData) {
        for (const inp of this.standardNotFilledData[key]) {
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.standardNotFilledData[key], 'info_id', inp.info_id);
                continue;
            }
          standardNotFilledInputs[inp.info_id] = ``;
        }
      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.standardAvailableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.standardAvailableData[key], av);
              }
            }
            for (const av of this.standardUnavailableData[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.standardUnavailableData[key], av);
                }
            }
            for (const av of this.standardNotFilledData[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.standardNotFilledData[key], av);
                }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.standardAvailableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.standardAvailableData[key], av);
              }
            }
            for (const av of this.standardUnavailableData[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.standardUnavailableData[key], av);
                }
            }
            for (const av of this.standardNotFilledData[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.standardNotFilledData[key], av);
                }
            }
          }
        }
      }

    }

    // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

    //   if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

    //     this.unavailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.mon);
    //     this.unavailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.tue);
    //     this.unavailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.wed);
    //     this.unavailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.thu);
    //     this.unavailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.fri);
    //     this.unavailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sat);
    //     this.unavailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sun);

    //     this.notFilledData.mon = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.mon);
    //     this.notFilledData.tue = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.tue);
    //     this.notFilledData.wed = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.wed);
    //     this.notFilledData.thu = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.thu);
    //     this.notFilledData.fri = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.fri);
    //     this.notFilledData.sat = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.sat);
    //     this.notFilledData.sun = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.sun);

    //   }

    //   if (this.state.display === 'LIST') {
    //     for (const key in this.allPlannerData) {
    //       for (const planAll of this.allPlannerData[key]) {
    //         for (const av of this.unavailableData[key]) {
    //             if (typeof this.inactiveUsers[av.info_id] !== 'undefined') {
    //                 Constants.removeFromArray2(this.unavailableData[key], 'info_id', av.info_id);
    //                 continue;
    //             }
    //           if (planAll.info_id === av.info_id) {
    //             Constants.removeFromArray(this.unavailableData[key], av);
    //           }
    //         }
    //         for (const av of this.notFilledData[key]) {
    //             if (typeof this.inactiveUsers[av.info_id] !== 'undefined') {
    //                 Constants.removeFromArray2(this.notFilledSchool[key], 'info_id', av.info_id);
    //                 continue;
    //             }
    //           if (planAll.info_id === av.info_id) {
    //             Constants.removeFromArray(this.notFilledData[key], av);
    //           }
    //         }
    //       }
    //     }
    //   }

    //     for (const key in this.unavailableData) {
    //         for (const inp of this.unavailableData[key]) {
    //             unavailableInputs[inp.info_id] = ``;
    //         }
    //     }
    //     for (const key in this.notFilledData) {
    //         for (const inp of this.notFilledData[key]) {
    //           notFilledInputs[inp.info_id] = ``;
    //         }
    //     }

    // }

    if(Data.data.appOptions.availability === 'NONE') {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.usersNoAvailability.mon = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.mon);
        this.usersNoAvailability.tue = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.tue);
        this.usersNoAvailability.wed = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.wed);
        this.usersNoAvailability.thu = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.thu);
        this.usersNoAvailability.fri = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.fri);
        this.usersNoAvailability.sat = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.sat);
        this.usersNoAvailability.sun = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.sun);

      }

      for (const key in this.usersNoAvailability) {
        for (const inp of this.usersNoAvailability[key]) {
            if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
                Constants.removeFromArray2(this.usersNoAvailability[key], 'info_id', inp.info_id);
                continue;
            }
          noAvailableInputs[inp.info_id] = ``;
        }
      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.usersNoAvailability[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.usersNoAvailability[key], av);
              }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.usersNoAvailability[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.usersNoAvailability[key], av);
              }
            }
          }
        }
      }

    }

    this.getWorkedDaysGenerated();
    this.getSalarisDagen(contractsObj, dailyGains, dailyGainsExpect, exclusions);
    this.sortBy(this.state.sortBy, true);

    this.setState({
        planInputs: planInputs,
        showPlanMenu: showPlanMenu,
        showPlanMenuInfo: showPlanMenuInfo,
        showPlanMenuEdit: showPlanMenuEdit,
        showPlanMenuSpecial: showPlanMenuSpecial,
        showPlanMenuAbsence: showPlanMenuAbsence,
        showPlanMenuComp: showPlanMenuComp,
        showPlanMenuBreaks: showPlanMenuBreaks,
        showDayShiftMenu: showDayShiftMenu,

        availableDataState: this.availableData, 
        availableInputs: availableInputs,
        unavailableDataState: this.unavailableData, 
        unavailableInputs: unavailableInputs,
        notFilledDataState: this.notFilledData, 
        notFilledInputs: notFilledInputs,

        notFilledSchoolState: this.notFilledSchool, 
        schoolNotFilledInputs: schoolNotFilledInputs,
        schoolScheduleDataState: this.schoolScheduleData, 
        schoolInputs: schoolInputs,
        schoolHolidayDataState: this.schoolHolidayData, 
        schoolHolidayInputs: schoolHolidayInputs,

        standardAvailableDataState: this.standardAvailableData, 
        standardAvailableInputs: standardAvailableInputs,
        standardUnavailableDataState: this.standardUnavailableData, 
        standardUnavailableInputs: standardUnavailableInputs,
        standardNotFilledDataState: this.standardNotFilledData, 
        standardNotFilledInputs: standardNotFilledInputs,

        usersNoAvailabilityState: this.usersNoAvailability, 
        noAvailableInputs: noAvailableInputs,

    });

    //this.fixDayShiftData();

    console.timeEnd('test');

  }

  async getSpecificDateDBData(date) {

    const month = Constants.stringToDate(Constants.formatDate(date)).getMonth() + 1;
    const year = Constants.stringToDate(Constants.formatDate(date)).getFullYear();

    //await this.getUsedDayShifts(this.state.mondayDate, this.state.sundayDate); // OLD
    // await this.getUsedDayShiftsModern(this.state.mondayDate, this.state.sundayDate);

    //await Promise.all(promises);

    let highest = this.state.highestCumulative;
    if (this.state.highestCumulative > 0) {
      highest += 7;
    }

    const [
      //teamPlannerDate,
      hoursMonthData,
      hoursMonth2Data,
      hoursWeek,
      gains,
      now,

      teamPlannerMonday,
      teamPlannerTuesday,
      teamPlannerWednesday,
      teamPlannerThursday,
      teamPlannerFriday,
      teamPlannerSaturday,
      teamPlannerSunday,

      planUsersMaxData,

      usedDayShifts,

      weeklyHours,
      monthlyHours,
      monthlyHours2,

    ] = await Promise.all([
      //APIGetTeamPlanner.Request(Constants.formatDate(date)),
      APIGetAllPlannedHours.Request(month, year),
      APIGetAllPlannedHours.Request(this.state.month2, this.state.year2),
      APIGetAllPlannedHoursWeek.Request(this.state.mondayDate, this.state.sundayDate),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${year} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${year}`, null, `ORDER BY omzetd_datum`),
      Constants.getDateNow(),

      APIGetTeamPlanner.Request(this.state.mondayDate),
      APIGetTeamPlanner.Request(this.state.tuesdayDate),
      APIGetTeamPlanner.Request(this.state.wednesdayDate),
      APIGetTeamPlanner.Request(this.state.thursdayDate),
      APIGetTeamPlanner.Request(this.state.fridayDate),
      APIGetTeamPlanner.Request(this.state.saturdayDate),
      APIGetTeamPlanner.Request(this.state.sundayDate),

      APIGET.Request(`SELECT rooster_datum, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_info_id IS NOT NULL AND rooster_datum BETWEEN (CAST('${this.state.mondayDate}' AS DATE) - INTERVAL ${highest} DAY) AND (CAST('${this.state.mondayDate}' AS DATE) + INTERVAL ${highest} DAY)`, null, `ORDER BY rooster_datum`),

      this.getUsedDayShiftsModern(this.state.mondayDate, this.state.sundayDate),

      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND rooster_publiceren < 2`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${month} AND YEAR(rooster_datum) = ${year} AND rooster_publiceren < 2`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${this.state.month2} AND YEAR(rooster_datum) = ${this.state.year2} AND rooster_publiceren < 2`, null, null),

    ]);

    const weeklyHoursObj = Constants.convertToObjectWithArrays(weeklyHours, 'rooster_info_id');
    const monthlyHoursObj = Constants.convertToObjectWithArrays(monthlyHours, 'rooster_info_id');
    const monthlyHours2Obj = Constants.convertToObjectWithArrays(monthlyHours2, 'rooster_info_id');

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamPlanner = [];

      // const teamPlannerDate = await APIGetTeamPlanner.Request(Constants.formatDate(date));

      // for (const tp of teamPlannerDate) {
      //   this.teamPlanner = [...this.teamPlanner, tp];
      // }

      for (const tp of teamPlannerMonday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerTuesday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerWednesday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerThursday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerFriday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerSaturday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerSunday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }

    }

    // GET PLANNED HOURS

    let hoursMonth = this.state.hoursMonth;
    let hoursMonth2 = this.state.hoursMonth2;

    if (month === this.state.month) {

      // hoursMonth = await APIGetAllPlannedHours.Request(month, year);
      hoursMonth = hoursMonthData;
      hoursMonth = hoursMonth === null ? [] : hoursMonth;

    } else {

      // hoursMonth2 = await APIGetAllPlannedHours.Request(this.state.month2, this.state.year2);
      hoursMonth2 = hoursMonth2Data;
      hoursMonth2 = hoursMonth2 === null ? [] : hoursMonth2;

    }

    // const hoursWeek = await APIGetAllPlannedHoursWeek.Request(this.state.mondayDate, this.state.sundayDate);

    // PRODUCTIVITY

    //const productivityHoursYear = await APIGetHoursProductivity.Request(year);
    //const gains = await APIGetGains.Request(year);
    //let gains = [];
    // if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
    //   gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${year} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`);
    // } else {
    //   gains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${year}`, null, `ORDER BY omzetd_datum`);
    // }
    // const now = await Constants.getDateNow();

    let totalHoursYearPr = 0;
    let totalGainsYear = 0;
    let productivityMonths = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
      12: null,
    };

    // GET ALL WORKING DAYS FROM HIGHEST CUMULATIVE DAYS SETTING 
    if (this.state.highestCumulative > 0) {

      // let highest = this.state.highestCumulative;

      // highest += 7;

      // const planUsersMaxData = await APIGET.Request(
      //   `SELECT rooster_datum, rooster_info_id`,
      //   `FROM Rooster`,
      //   null,
      //   `WHERE rooster_info_id IS NOT NULL AND rooster_datum BETWEEN (CAST('${this.state.mondayDate}' AS DATE) - INTERVAL ${highest} DAY) AND (CAST('${this.state.mondayDate}' AS DATE) + INTERVAL ${highest} DAY)`,
      //   null,
      //   `ORDER BY rooster_datum`
      // );

      const planUsersMaxCumulative = Constants.convertToObjectWithArrays(planUsersMaxData, 'rooster_info_id');

      this.setState({ planUsersMaxCumulative: planUsersMaxCumulative, highestCumulative: (highest - 7) });

    }

    this.setState({
      weeklyHours: weeklyHoursObj,
      monthlyHours: monthlyHoursObj,
      monthlyHours2: monthlyHours2Obj,

      hoursMonth: hoursMonth,
      hoursMonth2: hoursMonth2,
      hoursWeek: hoursWeek,
      productivityTotal: Constants.round(totalGainsYear / totalHoursYearPr),
      productivityMonths: productivityMonths,
    });
     
    // GET OTHER DATA

    //await this.getUsedDayShiftsModern(this.state.mondayDate, this.state.sundayDate);

    await Promise.all([
      this.getPlannerData(date.getDay(), Constants.formatDate(date)),
      this.getEmptyPlannerData(date.getDay(), Constants.formatDate(date)),
      this.getDayShifts(date.getDay(), date),
    ]);

    let promises = [];

    // promises.push(this.getUsedDayShiftsModern(this.state.mondayDate, this.state.sundayDate));

    // promises.push(this.getPlannerData(date.getDay(), Constants.formatDate(date)));

    // promises.push(this.getEmptyPlannerData(date.getDay(), Constants.formatDate(date)));

    // promises.push(this.getDayShifts(date.getDay(), date));

    if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      promises.push(this.getAvailableData(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getNotFilledData(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getUnavailableData(date.getDay(), Constants.formatDate(date)));

    }

    if(Data.data.appOptions.availability === 'STANDARD') {

      promises.push(this.getStandardAvailability(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getStandardUnavailability(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getStandardNotFilled(date.getDay(), Constants.formatDate(date)));

    }

    if(Data.data.appOptions.availability === 'SCHOOL') {

      promises.push(this.getAvailableData(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getNotFilledSchool(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getSchoolSchedule(date.getDay(), Constants.formatDate(date)));

      promises.push(this.getSchoolHolidays());

      promises.push(this.getUnavailableData(date.getDay(), Constants.formatDate(date)));

    }

    // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

    //   promises.push(this.getNotFilledData(date.getDay(), Constants.formatDate(date)));

    //   promises.push(this.getUnavailableData(date.getDay(), Constants.formatDate(date)));

    // }

    if(Data.data.appOptions.availability === 'NONE') {

      promises.push(this.getAllPeopleNoAvailabilityData(date.getDay(), Constants.formatDate(date)));

    }

    promises.push(this.getPublishCount());

    await Promise.all(promises);

    this.getWorkedDaysGenerated();
    this.getSalarisDagen(this.state.contractsObj, this.state.dailyGains, this.state.dailyGainsExpect, this.state.exclusions);

    this.sortBy(this.state.sortBy, true);

    this.setState({
      showUndoButton: JSON.stringify(this.plannerData) !== JSON.stringify(this.plannerDataBackup),
    });

    //this.fixDayShiftData();

  }

  async getSpecificDateDBData2(date) {

    const month = Constants.stringToDate(Constants.formatDate(date)).getMonth() + 1;
    const year = Constants.stringToDate(Constants.formatDate(date)).getFullYear();

    let highest = this.state.highestCumulative;
    if (this.state.highestCumulative > 0) {
      highest += 7;
    }

    const [
      hoursMonthData,
      hoursMonth2Data,
      hoursWeek,
      gains,
      now,

      teamPlannerMonday,
      teamPlannerTuesday,
      teamPlannerWednesday,
      teamPlannerThursday,
      teamPlannerFriday,
      teamPlannerSaturday,
      teamPlannerSunday,

      planUsersMaxData,

      usedDayShifts,

      weeklyHours,
      monthlyHours,
      monthlyHours2,

    ] = await Promise.all([
      APIGetAllPlannedHours.Request(month, year),
      APIGetAllPlannedHours.Request(this.state.month2, this.state.year2),
      APIGetAllPlannedHoursWeek.Request(this.state.mondayDate, this.state.sundayDate),
      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE YEAR(omzetd_datum) = ${year} AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`) : APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE YEAR(omzetd_datum) = ${year}`, null, `ORDER BY omzetd_datum`),
      Constants.getDateNow(),

      APIGetTeamPlanner.Request(this.state.mondayDate),
      APIGetTeamPlanner.Request(this.state.tuesdayDate),
      APIGetTeamPlanner.Request(this.state.wednesdayDate),
      APIGetTeamPlanner.Request(this.state.thursdayDate),
      APIGetTeamPlanner.Request(this.state.fridayDate),
      APIGetTeamPlanner.Request(this.state.saturdayDate),
      APIGetTeamPlanner.Request(this.state.sundayDate),

      APIGET.Request(`SELECT rooster_datum, rooster_info_id`, `FROM Rooster`, null, `WHERE rooster_info_id IS NOT NULL AND rooster_datum BETWEEN (CAST('${this.state.mondayDate}' AS DATE) - INTERVAL ${highest} DAY) AND (CAST('${this.state.mondayDate}' AS DATE) + INTERVAL ${highest} DAY)`, null, `ORDER BY rooster_datum`),

      Data.data.teams.length > 1 && Data.data.chosenTeam !== null ? APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null) : APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null),

      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND rooster_publiceren < 2`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${month} AND YEAR(rooster_datum) = ${year} AND rooster_publiceren < 2`, null, null),
      APIGET.Request(`SELECT *`, `FROM Rooster`, null, `WHERE MONTH(rooster_datum) = ${this.state.month2} AND YEAR(rooster_datum) = ${this.state.year2} AND rooster_publiceren < 2`, null, null),

    ]);

    this.getUsedDayShiftsModern(usedDayShifts);

    const weeklyHoursObj = Constants.convertToObjectWithArrays(weeklyHours, 'rooster_info_id');
    const monthlyHoursObj = Constants.convertToObjectWithArrays(monthlyHours, 'rooster_info_id');
    const monthlyHours2Obj = Constants.convertToObjectWithArrays(monthlyHours2, 'rooster_info_id');

    // REPEATING SHIFTS
    let unpublishedRepeatsWeek = [];
    for (const userKey in this.repeatingShifts) {
      for (const r of this.repeatingShifts[userKey]) {
        if (Constants.isEmpty(r.hr_team_id) === false && Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (r.hr_team_id === Data.data.chosenTeamObj.team_id) {
            const _unpublishedRepeatsWeek = await this.getUnpublishedRepeatShiftsWeek(r, this.state.infoExtra, Constants.stringToDate(this.state.mondayDate), Constants.stringToDate(this.state.tuesdayDate), Constants.stringToDate(this.state.wednesdayDate), Constants.stringToDate(this.state.thursdayDate), Constants.stringToDate(this.state.fridayDate), Constants.stringToDate(this.state.saturdayDate), Constants.stringToDate(this.state.sundayDate));
            for (const u of _unpublishedRepeatsWeek) {
              unpublishedRepeatsWeek.push(u);
            }
          }
        } else {
          const _unpublishedRepeatsWeek = await this.getUnpublishedRepeatShiftsWeek(r, this.state.infoExtra, Constants.stringToDate(this.state.mondayDate), Constants.stringToDate(this.state.tuesdayDate), Constants.stringToDate(this.state.wednesdayDate), Constants.stringToDate(this.state.thursdayDate), Constants.stringToDate(this.state.fridayDate), Constants.stringToDate(this.state.saturdayDate), Constants.stringToDate(this.state.sundayDate));
          for (const u of _unpublishedRepeatsWeek) {
            unpublishedRepeatsWeek.push(u);
          }
        }
      }
    }
    this.unpublishedRepeatingShifts = Constants.convertToObjectWithArrays(unpublishedRepeatsWeek, 'hr_info_id');

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.teamPlanner = [];

      for (const tp of teamPlannerMonday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerTuesday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerWednesday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerThursday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerFriday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerSaturday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }
      for (const tp of teamPlannerSunday) {
        this.teamPlanner = [...this.teamPlanner, tp];
      }

    }

    // GET PLANNED HOURS

    let hoursMonth = this.state.hoursMonth;
    let hoursMonth2 = this.state.hoursMonth2;

    if (month === this.state.month) {

      hoursMonth = hoursMonthData;
      hoursMonth = hoursMonth === null ? [] : hoursMonth;

    } else {

      hoursMonth2 = hoursMonth2Data;
      hoursMonth2 = hoursMonth2 === null ? [] : hoursMonth2;

    }

    let totalHoursYearPr = 0;
    let totalGainsYear = 0;
    let productivityMonths = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
      12: null,
    };

    // GET ALL WORKING DAYS FROM HIGHEST CUMULATIVE DAYS SETTING 
    let planUsersMaxCumulative = {};
    if (this.state.highestCumulative > 0) {
      planUsersMaxCumulative = Constants.convertToObjectWithArrays(planUsersMaxData, 'rooster_info_id');
    }
     
    // GET OTHER DATA

    // await Promise.all([
    //   this.getPlannerData(date.getDay(), Constants.formatDate(date)),
    //   this.getEmptyPlannerData(date.getDay(), Constants.formatDate(date)),
    //   this.getDayShifts(date.getDay(), date),
    // ]);

    const [

      _plannerData,
      _emptyPlannerData,
      _dayShiftsData,

    ] = await Promise.all([

      APIGetPlanner.Request(Constants.dateToString(date)),
      APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${Constants.dateToString(date)}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`),
      APIGetSpecificDefaultDayShift.Request(date.getDay(), Constants.dateToString(date), this.state.shiftTemp),

    ]);

    // const _getPlannerData = this.getPlannerData_new(date.getDay(), _plannerData);
    // const _getEmptyPlannerData = this.getEmptyPlannerData_new(date.getDay(), _emptyPlannerData, _getPlannerData);
    // const _getDayShifts = this.getDayShifts_new(date.getDay(), Constants.dateToString(date), _dayShiftsData, _getEmptyPlannerData);

    ////////////////////////////////////////////////////////////////////////////////////////////

    const weekDay = date.getDay();

    if(weekDay === 0) {
      this.plannerData.sun = [];
      this.plannerData.sun = _plannerData;
      this.allPlannerData.sun = this.plannerData.sun;
      
      // backup
      if (this.plannerDataBackup.sun.length === 0) {
        this.plannerDataBackup.sun = this.plannerData.sun;
      }
      
      // team
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.sun = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.sun);
      }
    }
    if(weekDay === 1) {
      this.plannerData.mon = [];
      this.plannerData.mon = _plannerData;
      this.allPlannerData.mon = this.plannerData.mon;

      // backup
      if (this.plannerDataBackup.mon.length === 0) {
        this.plannerDataBackup.mon = this.plannerData.mon;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.mon = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.mon);
      }
    }
    if(weekDay === 2) {
      this.plannerData.tue = [];
      this.plannerData.tue = _plannerData;
      this.allPlannerData.tue = this.plannerData.tue;

      // backup
      if (this.plannerDataBackup.tue.length === 0) {
        this.plannerDataBackup.tue = this.plannerData.tue;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.tue = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.tue);
      }
    }
    if(weekDay === 3) {
      this.plannerData.wed = [];
      this.plannerData.wed = _plannerData;
      this.allPlannerData.wed = this.plannerData.wed;

      // backup
      if (this.plannerDataBackup.wed.length === 0) {
        this.plannerDataBackup.wed = this.plannerData.wed;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.wed = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.wed);
      }
    }
    if(weekDay === 4) {
      this.plannerData.thu = [];
      this.plannerData.thu = _plannerData;
      this.allPlannerData.thu = this.plannerData.thu;

      // backup
      if (this.plannerDataBackup.thu.length === 0) {
        this.plannerDataBackup.thu = this.plannerData.thu;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.thu = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.thu);
      }
    }
    if(weekDay === 5) {
      this.plannerData.fri = [];
      this.plannerData.fri = _plannerData;
      this.allPlannerData.fri = this.plannerData.fri;

      // backup
      if (this.plannerDataBackup.fri.length === 0) {
        this.plannerDataBackup.fri = this.plannerData.fri;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.fri = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.fri);
      }
    }
    if(weekDay === 6) {
      this.plannerData.sat = [];
      this.plannerData.sat = _plannerData;
      this.allPlannerData.sat = this.plannerData.sat;

      // backup
      if (this.plannerDataBackup.sat.length === 0) {
        this.plannerDataBackup.sat = this.plannerData.sat;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.sat = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.sat);
      }
    }

    if(this.plannerData === 'error') {

        this.plannerData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        if (typeof this.inactiveUsers[plan.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.plannerData[key], 'info_id', plan.info_id);
            continue;
        }
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    // EMPTY PLANNERDATA

    if(weekDay === 0) {
      this.emptyPlannerData.sun = [];
      this.emptyPlannerData.sun = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.sun.length === 0) {
        this.emptyPlannerDataBackup.sun = this.emptyPlannerData.sun;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.sun = this.emptyPlannerData.sun;
        this.emptyPlannerData.sun = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.sun);
      }
    }

    if(weekDay === 1) {
      this.emptyPlannerData.mon = [];
      this.emptyPlannerData.mon = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.mon.length === 0) {
        this.emptyPlannerDataBackup.mon = this.emptyPlannerData.mon;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.mon = this.emptyPlannerData.mon;
        this.emptyPlannerData.mon = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.mon);
      }
    }

    if(weekDay === 2) {
      this.emptyPlannerData.tue = [];
      this.emptyPlannerData.tue = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.tue.length === 0) {
        this.emptyPlannerDataBackup.tue = this.emptyPlannerData.tue;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.tue = this.emptyPlannerData.tue;
        this.emptyPlannerData.tue = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.tue);
      }
    }

    if(weekDay === 3) {
      this.emptyPlannerData.wed = [];
      this.emptyPlannerData.wed = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.wed.length === 0) {
        this.emptyPlannerDataBackup.wed = this.emptyPlannerData.wed;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.wed = this.emptyPlannerData.wed;
        this.emptyPlannerData.wed = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.wed);
      }
    }

    if(weekDay === 4) {
      this.emptyPlannerData.thu = [];
      this.emptyPlannerData.thu = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.thu.length === 0) {
        this.emptyPlannerDataBackup.thu = this.emptyPlannerData.thu;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.thu = this.emptyPlannerData.thu;
        this.emptyPlannerData.thu = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.thu);
      }
    }

    if(weekDay === 5) {
      this.emptyPlannerData.fri = [];
      this.emptyPlannerData.fri = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.fri.length === 0) {
        this.emptyPlannerDataBackup.fri = this.emptyPlannerData.fri;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.fri = this.emptyPlannerData.fri;
        this.emptyPlannerData.fri = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.fri);
      }
    }

    if(weekDay === 6) {
      this.emptyPlannerData.sat = [];
      this.emptyPlannerData.sat = _emptyPlannerData;

      // backup
      if (this.emptyPlannerDataBackup.sat.length === 0) {
        this.emptyPlannerDataBackup.sat = this.emptyPlannerData.sat;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.sat = this.emptyPlannerData.sat;
        this.emptyPlannerData.sat = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.sat);
      }
    }

    // SET plan input data
    for (const key in this.emptyPlannerData) {
      for (const plan of this.emptyPlannerData[key]) {
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    // DAY SHIFTS

    if(weekDay === 0) {
      this.dayShiftsData.sun = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.sun) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.sun = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.sun) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.sun, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.sun, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 1) {
      this.dayShiftsData.mon = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.mon) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.mon = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.mon) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.mon, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.mon, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 2) {
      this.dayShiftsData.tue = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.tue) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.tue = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.tue) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.tue, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.tue, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 3) {
      this.dayShiftsData.wed = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.wed) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.wed = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.wed) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.wed, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.wed, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 4) {
      this.dayShiftsData.thu = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.thu) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.thu = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.thu) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.thu, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.thu, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 5) {
      this.dayShiftsData.fri = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.fri) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.fri = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.fri) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.fri, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.fri, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 6) {
      this.dayShiftsData.sat = _dayShiftsData;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.sat) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.sat = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (Constants.dateToString(date) === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.sat) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.sat, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.sat, dayShift);
              }
            }
          }
        }
      }

    }

    if(this.dayShiftsData.sun === 'error') {

        this.dayShiftsData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    // SET plan input data
    let showDayShiftMenu = this.state.showDayShiftMenu;

    for (const key in this.dayShiftsData) {
      for (const plan of this.dayShiftsData[key]) {
        planInputs['d' + plan.dagdienst_id] = `${plan.dagdienst_begin.substr(0, 5)} - ${plan.dagdienst_eind.substr(0, 5)}`;
        showDayShiftMenu['d' + plan.dagdienst_id] = false;
      }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////

    //let promises = [];

    let _availableData = [];
    let _notfFilledData = [];
    let _unavailableData = [];

    let _standardAvailableData = [];
    let _standardNotfFilledData = [];
    let _standardUnavailableData = [];

    let _schoolNotFilledData = [];
    let _schoolScheduleData = [];
    let _schoolHolidayData_sun = null;
    let _schoolHolidayData_mon = null;
    let _schoolHolidayData_tue = null;
    let _schoolHolidayData_wed = null;
    let _schoolHolidayData_thu = null;
    let _schoolHolidayData_fri = null;
    let _schoolHolidayData_sat = null;

    let _peopleNoAvailabilityData = null;

    let _publishCount = null;

    let _getAvailableData = {};
    let _getNotFilledData = {};
    let _getUnavailableData = {};

    let _getSchoolNotFilledData = {};
    let _getSchoolScheduleData = {};
    let _getSchoolHolidayData = {};

    let _getStandardAvailableData = {};
    let _getStandardNotFilledData = {};
    let _getStandardUnavailableData = {};

    let _getPeopleNoAvailabilityData = {};

    if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      [
        _availableData,
        _notfFilledData,
        _unavailableData,
        _publishCount,
      ] = await Promise.all([
        APIGetAllAvailabilities.Request(Constants.dateToString(date)),
        APIGetAllNotFilled.Request(Constants.dateToString(date)),
        APIGetAllUnavailabilities.Request(Constants.dateToString(date)),
        APIGetPublishCount.Request(Data.data.chosenTeam),
      ]);

      _getAvailableData = this.getAvailableData_new(date.getDay(), Constants.dateToString(date), _availableData);
      _getNotFilledData = this.getNotFilledData_new(date.getDay(), Constants.dateToString(date), _notfFilledData);
      _getUnavailableData = this.getUnavailableData_new(date.getDay(), Constants.dateToString(date), _unavailableData);

      // REPEATING AVAILABILITIES
      for (const key in this._allAvailableData) {
        for (const inp of this._allAvailableData[key]) {
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {

            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this._allAvailableData[key], 'info_id', inp.info_id);
            }

          }
        }
      }
      for (const key in this._allUnavailableData) {
        for (const inp of this._allUnavailableData[key]) {
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {

              const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
              const date2 = Constants.stringToDate(r.hb_datum);
              const interval = parseInt(r.hb_interval);
              const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
              const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
              const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
              const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
            
              if (isInInterval && date1 >= date2) {
                Constants.removeFromArray2(this._allUnavailableData[key], 'info_id', inp.info_id);
              }

            }
        }
      }

      // ADD REPEATING AVAILABILITIES
      for (const userKey in this.repeating) {

        for (const r of this.repeating[userKey]) {

          for (let i = 0; i < 7; i++) {

            const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

            const date1 = i === 1 ? Constants.stringToDate(this.state.mondayDate) : i === 2 ? Constants.stringToDate(this.state.tuesdayDate) : i === 3 ? Constants.stringToDate(this.state.wednesdayDate) : i === 4 ? Constants.stringToDate(this.state.thursdayDate) : i === 5 ? Constants.stringToDate(this.state.fridayDate) : i === 6 ? Constants.stringToDate(this.state.saturdayDate) : Constants.stringToDate(this.state.sundayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval && date1 >= date2) {

              if (r.hb_beschikbaar === '1') {

                const objToAdd = {
                  beschikbaar_id: r.hb_id + key,
                  beschikbaar_datum: Constants.dateToString(date1),
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allAvailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.availableData[key].push(objToAdd);
                }

              } else if (r.hb_beschikbaar === '2') {

                const objToAdd = {
                  onbeschikbaar_id: r.hb_id + key,
                  onbeschikbaar_datum: Constants.dateToString(date1),
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allUnavailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.unavailableData[key].push(objToAdd);
                }

              }

            }

          }

        }

      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.availableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.notFilledData[key], av);
              }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.availableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.notFilledData[key], av);
              }
            }
          }
        }
      }

    }

    if(Data.data.appOptions.availability === 'STANDARD') {

      [
        _standardAvailableData,
        _standardNotfFilledData,
        _standardUnavailableData,
        _publishCount,
      ] = await Promise.all([
        APIGET.Request(
          `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
          `FROM StandaardBeschikbaarheid`,
          `LEFT JOIN Info` +
          ` ON info_id = sb_info_id` +
          ` LEFT JOIN Functie` +
          ` ON functie_id = info_functie_id`,
          `WHERE sb_dag = ${date.getDay()}` +
          ` AND sb_beschikbaar = 1` +
          ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${Constants.dateToString(date)}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
          null,
          `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
        ),
        APIGET.Request(
          `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
          `FROM Info`,
          `LEFT JOIN Functie` +
          ` ON functie_id = info_functie_id`,
          `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${Constants.dateToString(date)}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
          ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${date.getDay()} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
          null,
          `ORDER BY info_voornaam, info_achternaam`
        ),
        APIGET.Request(
          `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
          `FROM StandaardBeschikbaarheid`,
          `LEFT JOIN Info` +
          ` ON info_id = sb_info_id` +
          ` LEFT JOIN Functie` +
          ` ON functie_id = info_functie_id`,
          `WHERE sb_dag = ${date.getDay()}` +
          ` AND sb_beschikbaar = 2` +
          ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${Constants.dateToString(date)}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
          null,
          `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
        ),
        APIGetPublishCount.Request(Data.data.chosenTeam),
      ]);

      _getStandardAvailableData = this.getStandardAvailability_new(date.getDay(), Constants.dateToString(date), _standardAvailableData);
      _getStandardNotFilledData = this.getStandardNotFilled_new(date.getDay(), Constants.dateToString(date), _standardNotfFilledData);
      _getStandardUnavailableData = this.getStandardUnavailability_new(date.getDay(), Constants.dateToString(date), _standardUnavailableData);

      // promises.push(this.getStandardAvailability(date.getDay(), Constants.formatDate(date)));

      // promises.push(this.getStandardUnavailability(date.getDay(), Constants.formatDate(date)));

      // promises.push(this.getStandardNotFilled(date.getDay(), Constants.formatDate(date)));

    }

    if(Data.data.appOptions.availability === 'SCHOOL') {

      [
        _availableData,
        _unavailableData,
        _schoolNotFilledData,
        _schoolScheduleData,
        _schoolHolidayData_sun,
        _schoolHolidayData_mon,
        _schoolHolidayData_tue,
        _schoolHolidayData_wed,
        _schoolHolidayData_thu,
        _schoolHolidayData_fri,
        _schoolHolidayData_sat,
        _publishCount,
      ] = await Promise.all([
        APIGetAllAvailabilities.Request(Constants.dateToString(date)),
        APIGetAllUnavailabilities.Request(Constants.dateToString(date)),
        APIGetAllNotFilledSchool.Request(weekDay, Constants.dateToString(date)),
        APIGetAllSchoolSchedule.Request(weekDay, Constants.dateToString(date)),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.sundayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.mondayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.tuesdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.wednesdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.thursdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.fridayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.saturdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGetPublishCount.Request(Data.data.chosenTeam),
      ]);

      _getAvailableData = this.getAvailableData_new(date.getDay(), Constants.dateToString(date), _availableData);
      _getUnavailableData = this.getUnavailableData_new(date.getDay(), Constants.dateToString(date), _unavailableData);
      _getSchoolNotFilledData = this.getNotFilledSchool_new(date.getDay(), Constants.dateToString(date), _schoolNotFilledData);
      _getSchoolScheduleData = this.getSchoolSchedule_new(date.getDay(), Constants.dateToString(date), _schoolScheduleData);
      _getSchoolHolidayData = this.getSchoolHolidays_new(_schoolHolidayData_sun, _schoolHolidayData_mon, _schoolHolidayData_tue, _schoolHolidayData_wed, _schoolHolidayData_thu, _schoolHolidayData_fri, _schoolHolidayData_sat);

      // REPEATING AVAILABILITIES
      for (const key in this._allAvailableData) {
        for (const inp of this._allAvailableData[key]) {
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {

            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this._allAvailableData[key], 'info_id', inp.info_id);
            }

          }
        }
      }
      for (const key in this._allUnavailableData) {
        for (const inp of this._allUnavailableData[key]) {
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {

              const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
              const date2 = Constants.stringToDate(r.hb_datum);
              const interval = parseInt(r.hb_interval);
              const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
              const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
              const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
              const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
            
              if (isInInterval && date1 >= date2) {
                Constants.removeFromArray2(this._allUnavailableData[key], 'info_id', inp.info_id);
              }

            }
        }
      }

      // ADD REPEATING AVAILABILITIES
      for (const userKey in this.repeating) {

        for (const r of this.repeating[userKey]) {

          for (let i = 0; i < 7; i++) {

            const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

            const date1 = i === 1 ? Constants.stringToDate(this.state.mondayDate) : i === 2 ? Constants.stringToDate(this.state.tuesdayDate) : i === 3 ? Constants.stringToDate(this.state.wednesdayDate) : i === 4 ? Constants.stringToDate(this.state.thursdayDate) : i === 5 ? Constants.stringToDate(this.state.fridayDate) : i === 6 ? Constants.stringToDate(this.state.saturdayDate) : Constants.stringToDate(this.state.sundayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;

            if (isInInterval && date1 >= date2) {

              if (r.hb_beschikbaar === '1') {

                const objToAdd = {
                  beschikbaar_id: r.hb_id + key,
                  beschikbaar_datum: Constants.dateToString(date1),
                  beschikbaar_begin: r.hb_begin,
                  beschikbaar_eind: r.hb_eind,
                  beschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allAvailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.availableData[key].push(objToAdd);
                }

              } else if (r.hb_beschikbaar === '2') {

                const objToAdd = {
                  onbeschikbaar_id: r.hb_id + key,
                  onbeschikbaar_datum: Constants.dateToString(date1),
                  onbeschikbaar_begin: r.hb_begin,
                  onbeschikbaar_eind: r.hb_eind,
                  onbeschikbaar_opmerking: r.hb_opmerking,
                  functie_afdeling_id: r.functie_afdeling_id,
                  functie_id: r.functie_id,
                  functie_kleur: r.functie_kleur,
                  functie_naam: r.functie_naam,
                  info_achternaam: r.info_achternaam,
                  info_id: r.info_id,
                  info_tussenvoegsel: r.info_tussenvoegsel,
                  info_voornaam: r.info_voornaam,
                };

                this._allUnavailableData[key].push(objToAdd);

                if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
                  this.unavailableData[key].push(objToAdd);
                }

              }

            }

          }

        }

      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.availableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledSchool[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.notFilledSchool[key], av);
                }
            }
            for (const av of this.schoolScheduleData[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolScheduleData[key], av);
                }
            }
            for (const av of this.schoolHolidayData[key]) {
                if (planAll.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolHolidayData[key], av);
                }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.availableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
            for (const av of this.unavailableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
            for (const av of this.notFilledSchool[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.notFilledSchool[key], av);
                }
            }
            for (const av of this.schoolScheduleData[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolScheduleData[key], av);
                }
            }
            for (const av of this.schoolHolidayData[key]) {
                if (r.info_id === av.info_id) {
                  Constants.removeFromArray(this.schoolHolidayData[key], av);
                }
            }
          }
        }
      }

    }

    // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

    //   [
    //     _notfFilledData,
    //     _unavailableData,
    //     _publishCount,
    //   ] = await Promise.all([
    //     APIGetAllNotFilled.Request(Constants.dateToString(date)),
    //     APIGetAllUnavailabilities.Request(Constants.dateToString(date)),
    //     APIGetPublishCount.Request(Data.data.chosenTeam),
    //   ]);

    //   _getNotFilledData = this.getNotFilledData_new(date.getDay(), Constants.dateToString(date), _notfFilledData);
    //   _getUnavailableData = this.getUnavailableData_new(date.getDay(), Constants.dateToString(date), _unavailableData);

    // }

    if(Data.data.appOptions.availability === 'NONE') {

      [
        _peopleNoAvailabilityData,
        _publishCount,
      ] = await Promise.all([
        APIGetAllPeopleNoAvailability.Request(Constants.dateToString(date)),
        APIGetPublishCount.Request(Data.data.chosenTeam),
      ]);

      _getPeopleNoAvailabilityData = this.getAllPeopleNoAvailabilityData_new(date.getDay(), Constants.dateToString(date), _peopleNoAvailabilityData);

      //promises.push(this.getAllPeopleNoAvailabilityData(date.getDay(), Constants.formatDate(date)));

    }

    //promises.push(this.getPublishCount());

    //await Promise.all(promises);

    this.getWorkedDaysGenerated();
    const _salarisDagen = this.getSalarisDagen_new(this.state.contractsObj, this.state.dailyGains, this.state.dailyGainsExpect, this.state.exclusions);

    const _sortBy = this.sortBy_new(this.state.sortBy, true);

    this.setState({

      weeklyHours: weeklyHoursObj,
      monthlyHours: monthlyHoursObj,
      monthlyHours2: monthlyHours2Obj,

      hoursMonth: hoursMonth,
      hoursMonth2: hoursMonth2,
      hoursWeek: hoursWeek,
      productivityTotal: Constants.round(totalGainsYear / totalHoursYearPr),
      productivityMonths: productivityMonths,

      planUsersMaxCumulative: planUsersMaxCumulative, highestCumulative: (highest - 7),

      showUndoButton: JSON.stringify(this.plannerData) !== JSON.stringify(this.plannerDataBackup),

      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,

      showDayShiftMenu: showDayShiftMenu,

      availableInputs: _getAvailableData.availableInputs,
      unavailableInputs: _getUnavailableData.unavailableInputs,
      notFilledInputs: _getNotFilledData.notFilledInputs,

      schoolNotFilledInputs: _getSchoolNotFilledData.schoolNotFilledInputs,
      schoolInputs: _getSchoolScheduleData.schoolInputs,
      schoolHolidayInputs: _getSchoolHolidayData.schoolHolidayInputs,

      standardAvailableInputs: _getStandardAvailableData.standardAvailableInputs,
      standardUnavailableInputs: _getStandardUnavailableData.standardUnavailableInputs,
      standardNotFilledInputs: _getStandardNotFilledData.standardNotFilledInputs,

      noAvailableInputs: _getPeopleNoAvailabilityData.noAvailableInputs,

      toPublish: _publishCount,

      salarisDagen: _salarisDagen.salarisDagen, 
      salarisUren: _salarisDagen.salarisUren, 
      omzetDagen: _salarisDagen.omzetDagen, 
      dailyGains: _salarisDagen.dailyGains, 
      dailyGainsExpect: _salarisDagen.dailyGainsExpect, 
      omzetDagenV: _salarisDagen.omzetDagenV,

      sortBy: _sortBy.sortBy,

    });

  }

  getWeekNumber() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getWeekNumber2(today) {
    let date = today;
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  getWeekYear() {
    let date = new Date();
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
  }

  getWeekYear2(today) {
    let date = today;
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
  }

  weeksInYear(year) {
    let month = 11, day = 31, week = 0;
  
    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
      const d = new Date(year, month, day--);
      week = this.alt(d)[1];
    } while (week === 1);
  
    return week;
  }

  alt(d) {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0,0,0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(),0,1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
    // Return array of year and week number
    return [d.getFullYear(), weekNo];
}

  getMonday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
  }

  getTuesday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 1);
    //ISOweekStart.setDate(ISOweekStart.getDate() + 1);
    return ISOweekStart;
  }

  getWednesday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 2);
    //ISOweekStart.setDate(ISOweekStart.getDate() + 2);
    return ISOweekStart;
  }

  getThursday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 3);
    //ISOweekStart.setDate(ISOweekStart.getDate() + 3);
    return ISOweekStart;
  }

  getFriday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 4);
    //ISOweekStart.setDate(ISOweekStart.getDate() + 4);
    return ISOweekStart;
  }

  getSaturday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 5);
    //ISOweekStart.setDate(ISOweekStart.getDate() + 5);
    return ISOweekStart;
  }

  getSunday(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
        //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
        //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 6);
    return ISOweekStart;
  }

  getWorkedDaysGenerated() {

    let userDaysPlanned = {};

    for (const key in this.plannerData) {

      for (const plan of this.plannerData[key]) {

        const key2 = parseInt(plan.info_id);

        if (typeof userDaysPlanned[key2] === 'undefined') {
          userDaysPlanned[key2] = 1;
        } else {
          userDaysPlanned[key2] = userDaysPlanned[key2] + 1;
        }

      }

    }

    this.userDaysPlanned = userDaysPlanned;

  }

  async getAllUsers() {

    let users = await APIGetAllAccounts.Request();

    if(users === 'error') {

        users = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    this.inactiveUsers = {};

    for(const _user of users) {

      if (_user.info2_status === '2') {
        this.inactiveUsers[_user.info_id] = true;
        Constants.removeFromArray2(users, 'info_id', _user.info_id);
      }

    }

    const usersObj = Constants.convertToObject(users, 'info_id');

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      users = Constants.getTeamAccounts(this.teamUsers, users);

    }

    if(this.state.section !== null && users != null && users.length > 0) {

      let usersDataSection = [];

      for(const _user of users) {

        if(parseInt(_user.functie_afdeling_id) === this.state.section) {
          usersDataSection.push(_user);
        }

      }

      users = usersDataSection;

    }

    if(users != null && users.length > 0) {

        this.users = users;

    } else {

        this.users = [];

    }

    this.users.sort((a, b) => a.functie_naam === null || (a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

    // SET user data
    let showUserMenu = this.state.showUserMenu;
    let showUserMenuJob = this.state.showUserMenuJob;

    // empty rows
    let addEmptyRows = {};

    for (const user of users) {

      showUserMenu[user.info_id] = false;
      showUserMenuJob[user.info_id] = false;

      addEmptyRows[user.info_id] = 0;

    }
    
    this.setState({
      showUserMenu: showUserMenu,
      showUserMenuJob: showUserMenuJob,
      addEmptyRowsUser: addEmptyRows,
      accountsObj: usersObj,
    });

  }

  async getSections() {

    const sectionsData = await APIGetSections.Request();

    if(sectionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.sectionsData = sectionsData;

  }

  async getDefaultTimes() {

    const timesData = await APIGetDefaultShifts.Request();

    if(timesData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(timesData === null) {
      this.timesData = [];
    }

    this.timesData = timesData;

  }

  async getFunctions() {

    const functionsData = await APIGetFunctions.Request();

    if(functionsData === 'error') {
        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.functionsData = functionsData;
    this.allFunctionsData = functionsData;
    
    if(this.state.section !== null) {

      let functionDataSection = [];

      for(const _function of functionsData) {

        if(parseInt(_function.functie_afdeling_id) === this.state.section) {
          functionDataSection.push(_function);
        }

      }

      this.functionsData = functionDataSection;

    }

    // new sort
    if (this.functionsArrange.length > 0) {

      for (const arr of this.functionsArrange) {

        for (const func of this.functionsData) {

          if (func.functie_id === arr.fv_functie_id && Constants.objectArrayContainsKey(this.functionsData, 'functie_id', arr.fv_functie_id)) {
            let index = this.functionsData.indexOf(func);
            this.functionsData.splice(index, 1);
            this.functionsData.splice(parseInt(arr.fv_index), 0, func);
          }

        }

      }

    }

    //   localFunctions = JSON.parse(localStorage.getItem(`@functionSort${this.state.section}${Data.data.storeCode}`));

    // } else {

    //   localFunctions = JSON.parse(localStorage.getItem(`@functionSort${Data.data.storeCode}`));

    // }

    // let useLocalData = true;

    // if(localFunctions !== null && localFunctions.length > 0) {

    //   loop1:
    //   for(const key in localFunctions) {
    //     for(const key2 in this.functionsData) {
    //       if(localFunctions[key].functie_id === this.functionsData[key2].functie_id
    //         && localFunctions[key].functie_kleur === this.functionsData[key2].functie_kleur
    //         && localFunctions[key].functie_naam === this.functionsData[key2].functie_naam) {
    //           useLocalData = true;
    //           break;
    //       } else {
    //         if(parseInt(key2) === this.functionsData.length - 1) {
    //           useLocalData = false;
    //           break loop1;
    //         }
    //       }
    //     }
    //   }

    //   if(useLocalData === true) {
    //     loop2:
    //     for(const key2 in this.functionsData) {
    //       for(const key in localFunctions) {
    //         if(localFunctions[key].functie_id === this.functionsData[key2].functie_id
    //           && localFunctions[key].functie_kleur === this.functionsData[key2].functie_kleur
    //           && localFunctions[key].functie_naam === this.functionsData[key2].functie_naam) {
    //             useLocalData = true;
    //             break;
    //         } else {
    //           if(parseInt(key) === localFunctions.length - 1) {
    //             useLocalData = false;
    //             break loop2;
    //           }
    //         }
    //       }
    //     }
    //   }

    // } else {

    //   useLocalData = false;

    // }

    // if(useLocalData === true) {

    //   this.functionsData = localFunctions;

    // } else {

    //   if(this.state.section !== null) {

    //     localStorage.setItem(`@functionSort${this.state.section}${Data.data.storeCode}`, JSON.stringify(this.functionsData));

    //   } else {

    //     localStorage.setItem(`@functionSort${Data.data.storeCode}`, JSON.stringify(this.functionsData));

    //   }

    // }

    // EMPTY ROWS INIT

    let addEmptyRows = {};
    for (const func of functionsData) {
      addEmptyRows[func.functie_id] = 0;
    }

    this.setState({addEmptyRows: addEmptyRows});

    //this.functionsData = JSON.parse(localStorage.getItem('@functionSort')) !== this.functionsData ? this.functionsData : JSON.parse(localStorage.getItem('@functionSort'));

    //Constants.swapInArray(this.functionsData, this.functionsData.indexOf(this.functionsData[3]), this.functionsData.indexOf(this.functionsData[3 - 1]));

    //localStorage.setItem('@functionSort', JSON.stringify(this.functionsData));

  }

  async getUsedDayShifts(date1, date2) {

    const usedDayShifts = await APIGetUsedDayShifts.Request(date1, date2);

    if(usedDayShifts === 'error') {
        // this.setState({ 
        //     errorScreen: true,
        //     loading: false,
        // });
        await this.getUsedDayShifts(date1, date2);
        return;
    }

    this.setState({usedDayShifts: usedDayShifts});

    this.usedDayShifts = usedDayShifts;

  }

    getUsedDayShiftsModern(usedDayShifts) {

    // let usedDayShifts = [];
    // if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
    //   usedDayShifts = await APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, `LEFT JOIN TeamRooster ON teamr_rooster_id = gdm_rooster_id`, `WHERE gdm_datum BETWEEN '${date1}' AND '${date2}' AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    // } else {
    //   usedDayShifts = await APIGET.Request(`SELECT *`, `FROM GebruikteDagDienstModern`, null, `WHERE gdm_datum BETWEEN '${date1}' AND '${date2}'`, null, null);
    // }

    if(usedDayShifts === 'error') {
        // this.setState({ 
        //     errorScreen: true,
        //     loading: false,
        // });
        //await this.getUsedDayShiftsModern(date1, date2);
        return;
    }

    this.usedDayShiftsModern = usedDayShifts;

    if (this.usedDayShiftsModernBackup.length === 0) {
      this.usedDayShiftsModernBackup = usedDayShifts;
    }

  }

  async getPublishCount() {

    const count = await APIGetPublishCount.Request(Data.data.chosenTeam);

    if(count != null) {

        this.setState({ toPublish: count });

    }

  }

  getSalarisDagen(contractsObj, gains, gainsExpect, exclusions) {

    let salarisDagen = [0, 0, 0, 0, 0, 0, 0];
    let salarisUren = [0, 0, 0, 0, 0, 0, 0];
    let omzetDagen = [
      this.state.omzetDagen[0],
      this.state.omzetDagen[1],
      this.state.omzetDagen[2],
      this.state.omzetDagen[3],
      this.state.omzetDagen[4],
      this.state.omzetDagen[5],
      this.state.omzetDagen[6],
    ];
    let omzetDagenV = [
      this.state.omzetDagenV[0],
      this.state.omzetDagenV[1],
      this.state.omzetDagenV[2],
      this.state.omzetDagenV[3],
      this.state.omzetDagenV[4],
      this.state.omzetDagenV[5],
      this.state.omzetDagenV[6],
    ];

    if (typeof gains !== 'undefined') {

      omzetDagen = ['', '', '', '', '', '', ''];

      for (const gain of gains) {
        if (gain.omzetd_datum === this.state.mondayDate) {
          omzetDagen[0] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.tuesdayDate) {
          omzetDagen[1] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.wednesdayDate) {
          omzetDagen[2] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.thursdayDate) {
          omzetDagen[3] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.fridayDate) {
          omzetDagen[4] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.saturdayDate) {
          omzetDagen[5] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.sundayDate) {
          omzetDagen[6] = parseFloat(gain.omzetd_hoeveelheid);
        }
      }
    }

    if (typeof gainsExpect !== 'undefined') {

      // omzetDagenV = [0, 0, 0, 0, 0, 0, 0];
      omzetDagenV = ['', '', '', '', '', '', ''];

      for (const gain of gainsExpect) {
        if (gain.omzetv_datum === this.state.mondayDate) {
          omzetDagenV[0] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.tuesdayDate) {
          omzetDagenV[1] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.wednesdayDate) {
          omzetDagenV[2] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.thursdayDate) {
          omzetDagenV[3] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.fridayDate) {
          omzetDagenV[4] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.saturdayDate) {
          omzetDagenV[5] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.sundayDate) {
          omzetDagenV[6] = parseFloat(gain.omzetv_hoeveelheid);
        }
      }
    }

    for (const key in this.plannerData) {

      const index = key === 'mon' ? 0 : key === 'tue' ? 1  : key === 'wed' ? 2 : key === 'thu' ? 3 : key === 'fri' ? 4 : key === 'sat' ? 5 : key === 'sun' ? 6 : 0

      for (const plan of this.plannerData[key]) {

        const excluded = typeof this.state.absenceSchedules[plan.rooster_id] !== 'undefined' && Constants.objectArrayContainsKey(exclusions, 'uit_afw_id', this.state.absenceSchedules[plan.rooster_id].afwr_afw_id);

        if (plan.rooster_publiceren !== '2' && excluded === false) {

          const totalTime = ((Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind) / 1000 / 60 / 60) - (Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin) / 1000 / 60 / 60)) - Constants.timeStringToFloat(plan.rooster_pauze);
          salarisUren[index] += totalTime;

          const contract = Constants.getCurrentContract(contractsObj[plan.info_id], Constants.stringToDate(plan.rooster_datum));

          if (contract !== null && contract.contract_type !== '2'){

            const hourSalary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            salarisDagen[index] += ((totalTime * hourSalary) * this.salaryFactor);

          }

        }

      }

    }

    for (const userKey in this.unpublishedRepeatingShifts) {

      for (const r of this.unpublishedRepeatingShifts[userKey]) {

        let _break = `00:00:00`;
        if (Constants.isEmpty(r.hr_pauze) === false) {
        _break = `${r.hr_pauze}`;
        }

        const plan = {
            hr_team_id: r.hr_team_id,
            info_id: r.hr_info_id,
            rooster_datum: Constants.dateToString(r.date),
            rooster_begin: r.hr_begin,
            rooster_eind: r.hr_eind,
            rooster_pauze: _break,
            functie_id: r.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
            functie_naam: r.functie_naam,
        }

        const index = r.date.getDay() === 0 ? 6 : (r.date.getDay() - 1);

        const totalTime = ((Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind) / 1000 / 60 / 60) - (Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin) / 1000 / 60 / 60)) - Constants.timeStringToFloat(plan.rooster_pauze);
        salarisUren[index] += totalTime;

        const contract = Constants.getCurrentContract(contractsObj[plan.info_id], Constants.stringToDate(plan.rooster_datum));

        if (contract !== null && contract.contract_type !== '2'){

          const hourSalary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
          salarisDagen[index] += ((totalTime * hourSalary) * this.salaryFactor);

        }

      }

    }

    this.setState({ salarisDagen: salarisDagen, salarisUren: salarisUren, omzetDagen: omzetDagen, dailyGains: gains, dailyGainsExpect: gainsExpect, omzetDagenV: omzetDagenV });

  }

  getSalarisDagen_new(contractsObj, gains, gainsExpect, exclusions) {

    let salarisDagen = [0, 0, 0, 0, 0, 0, 0];
    let salarisUren = [0, 0, 0, 0, 0, 0, 0];
    let omzetDagen = [
      this.state.omzetDagen[0],
      this.state.omzetDagen[1],
      this.state.omzetDagen[2],
      this.state.omzetDagen[3],
      this.state.omzetDagen[4],
      this.state.omzetDagen[5],
      this.state.omzetDagen[6],
    ];
    let omzetDagenV = [
      this.state.omzetDagenV[0],
      this.state.omzetDagenV[1],
      this.state.omzetDagenV[2],
      this.state.omzetDagenV[3],
      this.state.omzetDagenV[4],
      this.state.omzetDagenV[5],
      this.state.omzetDagenV[6],
    ];

    if (typeof gains !== 'undefined') {

      omzetDagen = [0, 0, 0, 0, 0, 0, 0];

      for (const gain of gains) {
        if (gain.omzetd_datum === this.state.mondayDate) {
          omzetDagen[0] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.tuesdayDate) {
          omzetDagen[1] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.wednesdayDate) {
          omzetDagen[2] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.thursdayDate) {
          omzetDagen[3] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.fridayDate) {
          omzetDagen[4] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.saturdayDate) {
          omzetDagen[5] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.sundayDate) {
          omzetDagen[6] = parseFloat(gain.omzetd_hoeveelheid);
        }
      }
    }

    if (typeof gainsExpect !== 'undefined') {

      omzetDagenV = [0, 0, 0, 0, 0, 0, 0];

      for (const gain of gainsExpect) {
        if (gain.omzetv_datum === this.state.mondayDate) {
          omzetDagenV[0] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.tuesdayDate) {
          omzetDagenV[1] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.wednesdayDate) {
          omzetDagenV[2] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.thursdayDate) {
          omzetDagenV[3] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.fridayDate) {
          omzetDagenV[4] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.saturdayDate) {
          omzetDagenV[5] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.sundayDate) {
          omzetDagenV[6] = parseFloat(gain.omzetv_hoeveelheid);
        }
      }
    }

    for (const key in this.plannerData) {

      const index = key === 'mon' ? 0 : key === 'tue' ? 1  : key === 'wed' ? 2 : key === 'thu' ? 3 : key === 'fri' ? 4 : key === 'sat' ? 5 : key === 'sun' ? 6 : 0

      for (const plan of this.plannerData[key]) {

        const excluded = typeof this.state.absenceSchedules[plan.rooster_id] !== 'undefined' && Constants.objectArrayContainsKey(exclusions, 'uit_afw_id', this.state.absenceSchedules[plan.rooster_id].afwr_afw_id);

        if (plan.rooster_publiceren !== '2' && excluded === false) {

          const totalTime = ((Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind) / 1000 / 60 / 60) - (Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin) / 1000 / 60 / 60)) - Constants.timeStringToFloat(plan.rooster_pauze);
          salarisUren[index] += totalTime;

          const contract = Constants.getCurrentContract(contractsObj[plan.info_id], Constants.stringToDate(plan.rooster_datum));

          if (contract !== null && contract.contract_type !== '2'){

            const hourSalary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            salarisDagen[index] += ((totalTime * hourSalary) * this.salaryFactor);

          }

        }

      }

    }

    for (const userKey in this.unpublishedRepeatingShifts) {

      for (const r of this.unpublishedRepeatingShifts[userKey]) {

        let _break = `00:00:00`;
        if (Constants.isEmpty(r.hr_pauze) === false) {
        _break = `${r.hr_pauze}`;
        }

        const plan = {
            hr_team_id: r.hr_team_id,
            info_id: r.hr_info_id,
            rooster_datum: Constants.dateToString(r.date),
            rooster_begin: r.hr_begin,
            rooster_eind: r.hr_eind,
            rooster_pauze: _break,
            functie_id: r.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
            functie_naam: r.functie_naam,
        }

        const index = r.date.getDay() === 0 ? 6 : (r.date.getDay() - 1);

        const totalTime = ((Constants.stringToDateTime(Plan.isNightShift2(plan), plan.rooster_eind) / 1000 / 60 / 60) - (Constants.stringToDateTime(plan.rooster_datum, plan.rooster_begin) / 1000 / 60 / 60)) - Constants.timeStringToFloat(plan.rooster_pauze);
        salarisUren[index] += totalTime;

        const contract = Constants.getCurrentContract(contractsObj[plan.info_id], Constants.stringToDate(plan.rooster_datum));

        if (contract !== null && contract.contract_type !== '2'){

          const hourSalary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
          salarisDagen[index] += ((totalTime * hourSalary) * this.salaryFactor);

        }

      }

    }

    return { salarisDagen: salarisDagen, salarisUren: salarisUren, omzetDagen: omzetDagen, dailyGains: gains, dailyGainsExpect: gainsExpect, omzetDagenV: omzetDagenV };

    // this.setState({ salarisDagen: salarisDagen, salarisUren: salarisUren, omzetDagen: omzetDagen, dailyGains: gains, dailyGainsExpect: gainsExpect, omzetDagenV: omzetDagenV });

  }

  async getDayShifts(weekDay, date, data) {

    const dayNumber = date.getDay();
    date = Constants.formatDate(date);

    if(weekDay === 0) {
      this.dayShiftsData.sun = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.sun) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.sun = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.sun) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.sun, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.sun, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 1) {
      this.dayShiftsData.mon = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.mon) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.mon = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.mon) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.mon, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.mon, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 2) {
      this.dayShiftsData.tue = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.tue) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.tue = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.tue) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.tue, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.tue, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 3) {
      this.dayShiftsData.wed = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.wed) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.wed = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.wed) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.wed, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.wed, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 4) {
      this.dayShiftsData.thu = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.thu) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.thu = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.thu) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.thu, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.thu, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 5) {
      this.dayShiftsData.fri = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.fri) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.fri = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.fri) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.fri, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.fri, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 6) {
      this.dayShiftsData.sat = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.sat) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.sat = dayShiftSection;
  
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.sat) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.sat, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.sat, dayShift);
              }
            }
          }
        }
      }

    }

    if(this.dayShiftsData.sun === 'error') {

        this.dayShiftsData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getDayShifts_new(weekDay, date, data, prevData) {

    if(weekDay === 0) {
      this.dayShiftsData.sun = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.sun) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.sun = dayShiftSection;
  
      }

      // DEPRECATED AS OF 5 MARCH 2021 - ENDS 1 MAY 2021
      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.sun) {
                if(this.dayShiftsData.sun[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.sun[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.sun[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.sun, this.dayShiftsData.sun[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.sun, this.dayShiftsData.sun[key]);
                  }
                  //break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.sun) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.sun, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.sun, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 1) {
      this.dayShiftsData.mon = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.mon) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.mon = dayShiftSection;
  
      }

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.mon) {
                if(this.dayShiftsData.mon[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.mon[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.mon[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.mon, this.dayShiftsData.mon[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.mon, this.dayShiftsData.mon[key]);
                  }
                  //break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.mon) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.mon, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.mon, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 2) {
      this.dayShiftsData.tue = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.tue) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.tue = dayShiftSection;
  
      }

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.tue) {
                if(this.dayShiftsData.tue[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.tue[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.tue[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.tue, this.dayShiftsData.tue[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.tue, this.dayShiftsData.tue[key]);
                  }
                  // break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.tue) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.tue, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.tue, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 3) {
      this.dayShiftsData.wed = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.wed) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.wed = dayShiftSection;
  
      }

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.wed) {
                if(this.dayShiftsData.wed[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.wed[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.wed[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.wed, this.dayShiftsData.wed[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.wed, this.dayShiftsData.wed[key]);
                  }
                  //break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.wed) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.wed, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.wed, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 4) {
      this.dayShiftsData.thu = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.thu) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.thu = dayShiftSection;
  
      }

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.thu) {
                if(this.dayShiftsData.thu[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.thu[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.thu[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.thu, this.dayShiftsData.thu[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.thu, this.dayShiftsData.thu[key]);
                  }
                  //break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.thu) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.thu, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.thu, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 5) {
      this.dayShiftsData.fri = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.fri) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.fri = dayShiftSection;
  
      }

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.fri) {
                if(this.dayShiftsData.fri[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.fri[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.fri[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.fri, this.dayShiftsData.fri[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.fri, this.dayShiftsData.fri[key]);
                  }
                  //break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.fri) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.fri, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.fri, dayShift);
              }
            }
          }
        }
      }

    }
    if(weekDay === 6) {
      this.dayShiftsData.sat = data;

      if(this.state.section !== null) {

        let dayShiftSection = [];
  
        for(const _dayShift of this.dayShiftsData.sat) {
  
          if(parseInt(_dayShift.functie_afdeling_id) === this.state.section) {
            dayShiftSection.push(_dayShift);
          }
  
        }
  
        this.dayShiftsData.sat = dayShiftSection;
  
      }

      for(let key2 in this.usedDayShifts) {
        if(date === this.usedDayShifts[key2].gebruiktedagdienst_datum) {

              for (let key in this.dayShiftsData.sat) {
                if(this.dayShiftsData.sat[key].dagdienst_begin === this.usedDayShifts[key2].gebruiktedagdienst_begin && this.dayShiftsData.sat[key].dagdienst_eind === this.usedDayShifts[key2].gebruiktedagdienst_eind && this.dayShiftsData.sat[key].functie_id === this.usedDayShifts[key2].gebruiktedagdienst_functie_id) {
                  if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                    if (parseInt(this.usedDayShifts[key2].teamr_team_id) === Data.data.chosenTeam) {
                      Constants.removeFromArray(this.dayShiftsData.sat, this.dayShiftsData.sat[key]);
                    }
                  } else {
                    Constants.removeFromArray(this.dayShiftsData.sat, this.dayShiftsData.sat[key]);
                  }
                  //break loopPlan;
                }
              }

        }
      }

      // MODERN AS OF 5 MARCH 2021 || THIS CAN GO BEFORE PUSH SO REMOVE FROM ARRAY AND EXTRA ITERATION WONT BE NEEDED WHEN LEGACY GETS REMOVED
      for (const usedDayShift of this.usedDayShiftsModern) {
        if (date === usedDayShift.gdm_datum) {
          for (const dayShift of this.dayShiftsData.sat) {
            if (dayShift.dagdienst_id === usedDayShift.gdm_dagdienst_id) {
              if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                if (parseInt(usedDayShift.teamr_team_id) === Data.data.chosenTeam) {
                  Constants.removeFromArray(this.dayShiftsData.sat, dayShift);
                }
              } else {
                Constants.removeFromArray(this.dayShiftsData.sat, dayShift);
              }
            }
          }
        }
      }

    }

    if(this.dayShiftsData.sun === 'error') {

        this.dayShiftsData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    // SET plan input data
    let planInputs = prevData.planInputs;
    let showDayShiftMenu = this.state.showDayShiftMenu;

    for (const key in this.dayShiftsData) {
      for (const plan of this.dayShiftsData[key]) {
        planInputs['d' + plan.dagdienst_id] = `${plan.dagdienst_begin.substr(0, 5)} - ${plan.dagdienst_eind.substr(0, 5)}`;
        showDayShiftMenu['d' + plan.dagdienst_id] = false;
      }
    }
    
    return {
      planInputs: planInputs,
      showDayShiftMenu: showDayShiftMenu,
    };

    // this.forceUpdate();

  }

  async getPlannerData(weekDay, data) {

    if(weekDay === 0) {
      this.plannerData.sun = [];
      this.plannerData.sun = data;
      this.allPlannerData.sun = this.plannerData.sun;
      
      // backup
      if (this.plannerDataBackup.sun.length === 0) {
        this.plannerDataBackup.sun = this.plannerData.sun;
      }
      
      // team
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.sun = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.sun);
      }
    }
    if(weekDay === 1) {
      this.plannerData.mon = [];
      this.plannerData.mon = data;
      this.allPlannerData.mon = this.plannerData.mon;

      // backup
      if (this.plannerDataBackup.mon.length === 0) {
        this.plannerDataBackup.mon = this.plannerData.mon;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.mon = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.mon);
      }
    }
    if(weekDay === 2) {
      this.plannerData.tue = [];
      this.plannerData.tue = data;
      this.allPlannerData.tue = this.plannerData.tue;

      // backup
      if (this.plannerDataBackup.tue.length === 0) {
        this.plannerDataBackup.tue = this.plannerData.tue;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.tue = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.tue);
      }
    }
    if(weekDay === 3) {
      this.plannerData.wed = [];
      this.plannerData.wed = data;
      this.allPlannerData.wed = this.plannerData.wed;

      // backup
      if (this.plannerDataBackup.wed.length === 0) {
        this.plannerDataBackup.wed = this.plannerData.wed;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.wed = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.wed);
      }
    }
    if(weekDay === 4) {
      this.plannerData.thu = [];
      this.plannerData.thu = data;
      this.allPlannerData.thu = this.plannerData.thu;

      // backup
      if (this.plannerDataBackup.thu.length === 0) {
        this.plannerDataBackup.thu = this.plannerData.thu;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.thu = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.thu);
      }
    }
    if(weekDay === 5) {
      this.plannerData.fri = [];
      this.plannerData.fri = data;
      this.allPlannerData.fri = this.plannerData.fri;

      // backup
      if (this.plannerDataBackup.fri.length === 0) {
        this.plannerDataBackup.fri = this.plannerData.fri;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.fri = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.fri);
      }
    }
    if(weekDay === 6) {
      this.plannerData.sat = [];
      this.plannerData.sat = data;
      this.allPlannerData.sat = this.plannerData.sat;

      // backup
      if (this.plannerDataBackup.sat.length === 0) {
        this.plannerDataBackup.sat = this.plannerData.sat;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.sat = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.sat);
      }
    }

    if(this.plannerData === 'error') {

        this.plannerData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    this.setState({plannerDataState: this.plannerData});

  }

  async getPlannerData_new(weekDay, data) {

    if(weekDay === 0) {
      this.plannerData.sun = [];
      this.plannerData.sun = data;
      this.allPlannerData.sun = this.plannerData.sun;
      
      // backup
      if (this.plannerDataBackup.sun.length === 0) {
        this.plannerDataBackup.sun = this.plannerData.sun;
      }
      
      // team
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.sun = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.sun);
      }
    }
    if(weekDay === 1) {
      this.plannerData.mon = [];
      this.plannerData.mon = data;
      this.allPlannerData.mon = this.plannerData.mon;

      // backup
      if (this.plannerDataBackup.mon.length === 0) {
        this.plannerDataBackup.mon = this.plannerData.mon;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.mon = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.mon);
      }
    }
    if(weekDay === 2) {
      this.plannerData.tue = [];
      this.plannerData.tue = data;
      this.allPlannerData.tue = this.plannerData.tue;

      // backup
      if (this.plannerDataBackup.tue.length === 0) {
        this.plannerDataBackup.tue = this.plannerData.tue;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.tue = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.tue);
      }
    }
    if(weekDay === 3) {
      this.plannerData.wed = [];
      this.plannerData.wed = data;
      this.allPlannerData.wed = this.plannerData.wed;

      // backup
      if (this.plannerDataBackup.wed.length === 0) {
        this.plannerDataBackup.wed = this.plannerData.wed;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.wed = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.wed);
      }
    }
    if(weekDay === 4) {
      this.plannerData.thu = [];
      this.plannerData.thu = data;
      this.allPlannerData.thu = this.plannerData.thu;

      // backup
      if (this.plannerDataBackup.thu.length === 0) {
        this.plannerDataBackup.thu = this.plannerData.thu;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.thu = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.thu);
      }
    }
    if(weekDay === 5) {
      this.plannerData.fri = [];
      this.plannerData.fri = data;
      this.allPlannerData.fri = this.plannerData.fri;

      // backup
      if (this.plannerDataBackup.fri.length === 0) {
        this.plannerDataBackup.fri = this.plannerData.fri;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.fri = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.fri);
      }
    }
    if(weekDay === 6) {
      this.plannerData.sat = [];
      this.plannerData.sat = data;
      this.allPlannerData.sat = this.plannerData.sat;

      // backup
      if (this.plannerDataBackup.sat.length === 0) {
        this.plannerDataBackup.sat = this.plannerData.sat;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        this.plannerData.sat = Constants.getTeamPlanner(this.teamPlanner, this.plannerData.sat);
      }
    }

    if(this.plannerData === 'error') {

        this.plannerData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    return {
      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    };

  }

  async getEmptyPlannerData(weekDay, data) {

    if(weekDay === 0) {
      this.emptyPlannerData.sun = [];
      this.emptyPlannerData.sun = data;

      // backup
      if (this.emptyPlannerDataBackup.sun.length === 0) {
        this.emptyPlannerDataBackup.sun = this.emptyPlannerData.sun;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.sun = this.emptyPlannerData.sun;
        this.emptyPlannerData.sun = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.sun);
      }
    }

    if(weekDay === 1) {
      this.emptyPlannerData.mon = [];
      this.emptyPlannerData.mon = data;

      // backup
      if (this.emptyPlannerDataBackup.mon.length === 0) {
        this.emptyPlannerDataBackup.mon = this.emptyPlannerData.mon;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.mon = this.emptyPlannerData.mon;
        this.emptyPlannerData.mon = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.mon);
      }
    }

    if(weekDay === 2) {
      this.emptyPlannerData.tue = [];
      this.emptyPlannerData.tue = data;

      // backup
      if (this.emptyPlannerDataBackup.tue.length === 0) {
        this.emptyPlannerDataBackup.tue = this.emptyPlannerData.tue;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.tue = this.emptyPlannerData.tue;
        this.emptyPlannerData.tue = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.tue);
      }
    }

    if(weekDay === 3) {
      this.emptyPlannerData.wed = [];
      this.emptyPlannerData.wed = data;

      // backup
      if (this.emptyPlannerDataBackup.wed.length === 0) {
        this.emptyPlannerDataBackup.wed = this.emptyPlannerData.wed;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.wed = this.emptyPlannerData.wed;
        this.emptyPlannerData.wed = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.wed);
      }
    }

    if(weekDay === 4) {
      this.emptyPlannerData.thu = [];
      this.emptyPlannerData.thu = data;

      // backup
      if (this.emptyPlannerDataBackup.thu.length === 0) {
        this.emptyPlannerDataBackup.thu = this.emptyPlannerData.thu;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.thu = this.emptyPlannerData.thu;
        this.emptyPlannerData.thu = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.thu);
      }
    }

    if(weekDay === 5) {
      this.emptyPlannerData.fri = [];
      this.emptyPlannerData.fri = data;

      // backup
      if (this.emptyPlannerDataBackup.fri.length === 0) {
        this.emptyPlannerDataBackup.fri = this.emptyPlannerData.fri;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.fri = this.emptyPlannerData.fri;
        this.emptyPlannerData.fri = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.fri);
      }
    }

    if(weekDay === 6) {
      this.emptyPlannerData.sat = [];
      this.emptyPlannerData.sat = data;

      // backup
      if (this.emptyPlannerDataBackup.sat.length === 0) {
        this.emptyPlannerDataBackup.sat = this.emptyPlannerData.sat;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.sat = this.emptyPlannerData.sat;
        this.emptyPlannerData.sat = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.sat);
      }
    }

  }

  async getEmptyPlannerData_new(weekDay, data, prevData) {

    if(weekDay === 0) {
      this.emptyPlannerData.sun = [];
      this.emptyPlannerData.sun = data;

      // backup
      if (this.emptyPlannerDataBackup.sun.length === 0) {
        this.emptyPlannerDataBackup.sun = this.emptyPlannerData.sun;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.sun = this.emptyPlannerData.sun;
        this.emptyPlannerData.sun = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.sun);
      }
    }

    if(weekDay === 1) {
      this.emptyPlannerData.mon = [];
      this.emptyPlannerData.mon = data;

      // backup
      if (this.emptyPlannerDataBackup.mon.length === 0) {
        this.emptyPlannerDataBackup.mon = this.emptyPlannerData.mon;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.mon = this.emptyPlannerData.mon;
        this.emptyPlannerData.mon = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.mon);
      }
    }

    if(weekDay === 2) {
      this.emptyPlannerData.tue = [];
      this.emptyPlannerData.tue = data;

      // backup
      if (this.emptyPlannerDataBackup.tue.length === 0) {
        this.emptyPlannerDataBackup.tue = this.emptyPlannerData.tue;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.tue = this.emptyPlannerData.tue;
        this.emptyPlannerData.tue = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.tue);
      }
    }

    if(weekDay === 3) {
      this.emptyPlannerData.wed = [];
      this.emptyPlannerData.wed = data;

      // backup
      if (this.emptyPlannerDataBackup.wed.length === 0) {
        this.emptyPlannerDataBackup.wed = this.emptyPlannerData.wed;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.wed = this.emptyPlannerData.wed;
        this.emptyPlannerData.wed = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.wed);
      }
    }

    if(weekDay === 4) {
      this.emptyPlannerData.thu = [];
      this.emptyPlannerData.thu = data;

      // backup
      if (this.emptyPlannerDataBackup.thu.length === 0) {
        this.emptyPlannerDataBackup.thu = this.emptyPlannerData.thu;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.thu = this.emptyPlannerData.thu;
        this.emptyPlannerData.thu = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.thu);
      }
    }

    if(weekDay === 5) {
      this.emptyPlannerData.fri = [];
      this.emptyPlannerData.fri = data;

      // backup
      if (this.emptyPlannerDataBackup.fri.length === 0) {
        this.emptyPlannerDataBackup.fri = this.emptyPlannerData.fri;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.fri = this.emptyPlannerData.fri;
        this.emptyPlannerData.fri = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.fri);
      }
    }

    if(weekDay === 6) {
      this.emptyPlannerData.sat = [];
      this.emptyPlannerData.sat = data;

      // backup
      if (this.emptyPlannerDataBackup.sat.length === 0) {
        this.emptyPlannerDataBackup.sat = this.emptyPlannerData.sat;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        // this.allPlannerData.sat = this.emptyPlannerData.sat;
        this.emptyPlannerData.sat = Constants.getTeamPlanner(this.teamPlanner, this.emptyPlannerData.sat);
      }
    }

    // SET plan input data
    let planInputs = prevData.planInputs;
    let showPlanMenu = prevData.showPlanMenu;
    let showPlanMenuInfo = prevData.showPlanMenuInfo;
    let showPlanMenuEdit = prevData.showPlanMenuEdit;
    let showPlanMenuSpecial = prevData.showPlanMenuSpecial;
    let showPlanMenuAbsence = prevData.showPlanMenuAbsence;
    let showPlanMenuComp = prevData.showPlanMenuComp;
    let showPlanMenuBreaks = prevData.showPlanMenuBreaks;

    for (const key in this.emptyPlannerData) {
      for (const plan of this.emptyPlannerData[key]) {
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }
    
    return {
      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    };

  }

  fixDayShiftData() {

    let tempData = [];
    let tempPlannerIDData = [];
    let tempShiftIDData = [];

    // MONDAY

    for(let key in this.plannerData.mon) {

      for(let key2 in this.dayShiftsData.mon) {

        if(this.plannerData.mon[key].rooster_begin === this.dayShiftsData.mon[key2].dagdienst_begin
          && this.plannerData.mon[key].rooster_eind === this.dayShiftsData.mon[key2].dagdienst_eind
          && this.plannerData.mon[key].functie_id === this.dayShiftsData.mon[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.mon[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.mon[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.mon[key2]);
              tempPlannerIDData.push(this.plannerData.mon[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.mon[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.mon, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // TUESDAY

    for(let key in this.plannerData.tue) {

      for(let key2 in this.dayShiftsData.tue) {

        if(this.plannerData.tue[key].rooster_begin === this.dayShiftsData.tue[key2].dagdienst_begin
          && this.plannerData.tue[key].rooster_eind === this.dayShiftsData.tue[key2].dagdienst_eind
          && this.plannerData.tue[key].functie_id === this.dayShiftsData.tue[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.tue[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.tue[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.tue[key2]);
              tempPlannerIDData.push(this.plannerData.tue[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.tue[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.tue, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // WEDNESDAY

    for(let key in this.plannerData.wed) {

      for(let key2 in this.dayShiftsData.wed) {

        if(this.plannerData.wed[key].rooster_begin === this.dayShiftsData.wed[key2].dagdienst_begin
          && this.plannerData.wed[key].rooster_eind === this.dayShiftsData.wed[key2].dagdienst_eind
          && this.plannerData.wed[key].functie_id === this.dayShiftsData.wed[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.wed[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.wed[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.wed[key2]);
              tempPlannerIDData.push(this.plannerData.wed[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.wed[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.wed, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // THURSDAY

    for(let key in this.plannerData.thu) {

      for(let key2 in this.dayShiftsData.thu) {

        if(this.plannerData.thu[key].rooster_begin === this.dayShiftsData.thu[key2].dagdienst_begin
          && this.plannerData.thu[key].rooster_eind === this.dayShiftsData.thu[key2].dagdienst_eind
          && this.plannerData.thu[key].functie_id === this.dayShiftsData.thu[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.thu[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.thu[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.thu[key2]);
              tempPlannerIDData.push(this.plannerData.thu[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.thu[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.thu, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // FRIDAY

    for(let key in this.plannerData.fri) {

      for(let key2 in this.dayShiftsData.fri) {

        if(this.plannerData.fri[key].rooster_begin === this.dayShiftsData.fri[key2].dagdienst_begin
          && this.plannerData.fri[key].rooster_eind === this.dayShiftsData.fri[key2].dagdienst_eind
          && this.plannerData.fri[key].functie_id === this.dayShiftsData.fri[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.fri[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.fri[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.fri[key2]);
              tempPlannerIDData.push(this.plannerData.fri[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.fri[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.fri, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // SATURDAY

    for(let key in this.plannerData.sat) {

      for(let key2 in this.dayShiftsData.sat) {

        if(this.plannerData.sat[key].rooster_begin === this.dayShiftsData.sat[key2].dagdienst_begin
          && this.plannerData.sat[key].rooster_eind === this.dayShiftsData.sat[key2].dagdienst_eind
          && this.plannerData.sat[key].functie_id === this.dayShiftsData.sat[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.sat[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.sat[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.sat[key2]);
              tempPlannerIDData.push(this.plannerData.sat[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.sat[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.sat, tempData[key]);

    }

    tempData = [];
    tempPlannerIDData = [];
    tempShiftIDData = [];

    // SUNDAY

    for(let key in this.plannerData.sun) {

      for(let key2 in this.dayShiftsData.sun) {

        if(this.plannerData.sun[key].rooster_begin === this.dayShiftsData.sun[key2].dagdienst_begin
          && this.plannerData.sun[key].rooster_eind === this.dayShiftsData.sun[key2].dagdienst_eind
          && this.plannerData.sun[key].functie_id === this.dayShiftsData.sun[key2].functie_id) {

            if(Constants.arrayContains(tempPlannerIDData, this.plannerData.sun[key].rooster_id) === false
              && Constants.arrayContains(tempShiftIDData, this.dayShiftsData.sun[key2].dagdienst_id) === false) {

              tempData.push(this.dayShiftsData.sun[key2]);
              tempPlannerIDData.push(this.plannerData.sun[key].rooster_id);
              tempShiftIDData.push(this.dayShiftsData.sun[key2].dagdienst_id);

            }

        }

      }

    }

    for(let key in tempData) {

       Constants.removeFromArray(this.dayShiftsData.sun, tempData[key]);

    }

  }

  async getAllPeopleNoAvailabilityData(weekDay, date) {

    if(weekDay === 0) {
      this.usersNoAvailability.sun = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.sun = this.usersNoAvailability.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.usersNoAvailability.mon = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.mon = this.usersNoAvailability.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.usersNoAvailability.tue = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.tue = this.usersNoAvailability.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.usersNoAvailability.wed = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.wed = this.usersNoAvailability.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.usersNoAvailability.thu = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.thu = this.usersNoAvailability.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.usersNoAvailability.fri = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.fri = this.usersNoAvailability.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.usersNoAvailability.sat = await APIGetAllPeopleNoAvailability.Request(date);
      this._allUsersNoAvailability.sat = this.usersNoAvailability.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.sat = notFilledSection;
  
      }
    }

    if(this.usersNoAvailability === 'error') {

        this.usersNoAvailability = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getAvailableData(weekDay, date) {

    if(weekDay === 0) {
      this.availableData.sun = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.sun = this.availableData.sun;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.sun) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.sun = availabilitySection;
  
      }
    }
    if(weekDay === 1) {
      this.availableData.mon = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.mon = this.availableData.mon;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.mon) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.mon = availabilitySection;
  
      }
    }
    if(weekDay === 2) {
      this.availableData.tue = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.tue = this.availableData.tue;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.tue) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.tue = availabilitySection;
  
      }
    }
    if(weekDay === 3) {
      this.availableData.wed = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.wed = this.availableData.wed;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.wed) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.wed = availabilitySection;
  
      }
    }
    if(weekDay === 4) {
      this.availableData.thu = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.thu = this.availableData.thu;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.thu) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.thu = availabilitySection;
  
      }
    }
    if(weekDay === 5) {
      this.availableData.fri = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.fri = this.availableData.fri;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.fri) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.fri = availabilitySection;
  
      }
    }
    if(weekDay === 6) {
      this.availableData.sat = await APIGetAllAvailabilities.Request(date);
      this._allAvailableData.sat = this.availableData.sat;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.sat) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.sat = availabilitySection;
  
      }
    }

    if(this.availableData === 'error') {

        this.availableData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getUnavailableData(weekDay, date) {

      if(weekDay === 0) {
        this.unavailableData.sun = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.sun = this.unavailableData.sun;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.sun) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.sun = unavailabilitySection;
    
        }
      }
      if(weekDay === 1) {
        this.unavailableData.mon = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.mon = this.unavailableData.mon;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.mon) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.mon = unavailabilitySection;
    
        }
      }
      if(weekDay === 2) {
        this.unavailableData.tue = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.tue = this.unavailableData.tue;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.tue) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.tue = unavailabilitySection;
    
        }
      }
      if(weekDay === 3) {
        this.unavailableData.wed = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.wed = this.unavailableData.wed;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.wed) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.wed = unavailabilitySection;
    
        }
      }
      if(weekDay === 4) {
        this.unavailableData.thu = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.thu = this.unavailableData.thu;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.thu) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.thu = unavailabilitySection;
    
        }
      }
      if(weekDay === 5) {
        this.unavailableData.fri = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.fri = this.unavailableData.fri;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.fri) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.fri = unavailabilitySection;
    
        }
      }
      if(weekDay === 6) {
        this.unavailableData.sat = await APIGetAllUnavailabilities.Request(date);
        this._allUnavailableData.sat = this.unavailableData.sat;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.sat) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.sat = unavailabilitySection;
    
        }
      }

      if(this.unavailableData === 'error') {

          this.unavailableData = [];
          
          this.setState({
              errorScreen: true,
              loading: false,
          });
          return;
      }

  }

  async getNotFilledData(weekDay, date) {

    if(weekDay === 0) {
      this.notFilledData.sun = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.sun = this.notFilledData.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.notFilledData.mon = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.mon = this.notFilledData.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.notFilledData.tue = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.tue = this.notFilledData.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.notFilledData.wed = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.wed = this.notFilledData.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.notFilledData.thu = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.thu = this.notFilledData.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.notFilledData.fri = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.fri = this.notFilledData.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.notFilledData.sat = await APIGetAllNotFilled.Request(date);
      this._allNotFilledData.sat = this.notFilledData.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.sat = notFilledSection;
  
      }
    }

      if(this.notFilledData === 'error') {

          this.notFilledData = [];
          
          this.setState({
              errorScreen: true,
              loading: false,
          });
          return;
      }

  }

  async getNotFilledSchool(weekDay, date) {

    if(weekDay === 0) {
      this.notFilledSchool.sun = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.sun = this.notFilledSchool.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.notFilledSchool.mon = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.mon = this.notFilledSchool.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.notFilledSchool.tue = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.tue = this.notFilledSchool.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.notFilledSchool.wed = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.wed = this.notFilledSchool.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.notFilledSchool.thu = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.thu = this.notFilledSchool.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.notFilledSchool.fri = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.fri = this.notFilledSchool.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.notFilledSchool.sat = await APIGetAllNotFilledSchool.Request(weekDay, date);
      this._allNotFilledSchool.sat = this.notFilledSchool.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.sat = notFilledSection;
  
      }
    }

    if(this.notFilledSchool === 'error') {

        this.notFilledSchool = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getSchoolSchedule(weekDay, date) {

    if(weekDay === 0) {
      this.schoolScheduleData.sun = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.sun = this.schoolScheduleData.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.schoolScheduleData.mon = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.mon = this.schoolScheduleData.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.schoolScheduleData.tue = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.tue = this.schoolScheduleData.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.schoolScheduleData.wed = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.wed = this.schoolScheduleData.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.schoolScheduleData.thu = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.thu = this.schoolScheduleData.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.schoolScheduleData.fri = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.fr = this.schoolScheduleData.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.schoolScheduleData.sat = await APIGetAllSchoolSchedule.Request(weekDay, date);
      this._allSchoolScheduleData.sat = this.schoolScheduleData.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.sat = notFilledSection;
  
      }
    }

    if(this.schoolScheduleData === 'error') {

        this.schoolScheduleData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getAllHolidays() {

    let holidayDays = await APIGetAllHolidays.Request();

    if(holidayDays === 'error') {

        holidayDays = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      holidayDays = Constants.getTeamAccounts(this.teamUsers, holidayDays);

    }

    if(holidayDays !== null && holidayDays.length > 0) {

        let allHolidayDays = [];
        let allHolidayDaysRequests = [];
        
        for(let key in holidayDays) { //iterate through holidays ranges from DB

            if(holidayDays[key].vakantie_geaccepteerd === '1') {

                //let currentDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
                let startDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
                let endDate = Constants.stringToDate(holidayDays[key].vakantie_eind);

                //loop2:
                if (startDate.getTime() < endDate.getTime()) {

                  let index = 0;
                  while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

                      const userFullName = `${holidayDays[key].info_voornaam} ${(holidayDays[key].info_tussenvoegsel !== null && holidayDays[key].info_tussenvoegsel !== '') ? holidayDays[key].info_tussenvoegsel + " " + holidayDays[key].info_achternaam.charAt(0) : holidayDays[key].info_achternaam.charAt(0)}.`;

                      allHolidayDays.push({
                          dateString: Constants.dateToString(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index)),
                          date: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index),
                          name: userFullName,
                          comment: holidayDays[key].vakantie_opmerking,
                          end: holidayDays[key].vakantie_eind,
                          userID: holidayDays[key].info_id,
                          id: holidayDays[key].vakantie_id,
                      });

                      index++;

                  } 

                }else {

                  const userFullName = `${holidayDays[key].info_voornaam} ${(holidayDays[key].info_tussenvoegsel !== null && holidayDays[key].info_tussenvoegsel !== '') ? holidayDays[key].info_tussenvoegsel + " " + holidayDays[key].info_achternaam.charAt(0) : holidayDays[key].info_achternaam.charAt(0)}.`;

                  allHolidayDays.push({
                      dateString: Constants.dateToString(startDate),
                      date: startDate,
                      name: userFullName,
                      comment: holidayDays[key].vakantie_opmerking,
                      end: holidayDays[key].vakantie_eind,
                      userID: holidayDays[key].info_id,
                      id: holidayDays[key].vakantie_id,
                  });

                }

            } else if(holidayDays[key].vakantie_geaccepteerd === '0') {

              //let currentDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
              let startDate = Constants.stringToDate(holidayDays[key].vakantie_begin);
              let endDate = Constants.stringToDate(holidayDays[key].vakantie_eind);

              //loop2:
              if (startDate.getTime() < endDate.getTime()) {

                let index = 0;
                while(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index).getTime() <= endDate.getTime()) {

                    const userFullName = `${holidayDays[key].info_voornaam} ${(holidayDays[key].info_tussenvoegsel !== null && holidayDays[key].info_tussenvoegsel !== '') ? holidayDays[key].info_tussenvoegsel + " " + holidayDays[key].info_achternaam.charAt(0) : holidayDays[key].info_achternaam.charAt(0)}.`;

                    allHolidayDaysRequests.push({
                      date: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index),
                      dateString: Constants.dateToString(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index)),
                      name: userFullName,
                      comment: holidayDays[key].vakantie_opmerking,
                      end: holidayDays[key].vakantie_eind,
                      userID: holidayDays[key].info_id,
                      id: holidayDays[key].vakantie_id,
                    });

                    index++;

                } 

              } else {

                const userFullName = `${holidayDays[key].info_voornaam} ${(holidayDays[key].info_tussenvoegsel !== null && holidayDays[key].info_tussenvoegsel !== '') ? holidayDays[key].info_tussenvoegsel + " " + holidayDays[key].info_achternaam.charAt(0) : holidayDays[key].info_achternaam.charAt(0)}.`;

                allHolidayDaysRequests.push({
                  date: startDate,
                  dateString: Constants.dateToString(startDate),
                  name: userFullName,
                  comment: holidayDays[key].vakantie_opmerking,
                  end: holidayDays[key].vakantie_eind,
                  userID: holidayDays[key].info_id,
                  id: holidayDays[key].vakantie_id,
                });

              }

          }

        }

        this.holidayDays = allHolidayDays;
        this.holidayDaysRequests = allHolidayDaysRequests;
        let holidayInputs = {};

        for(let key in this.holidayDays) {

          const weekDay = this.holidayDays[key].date.getDay();

          if(weekDay === 0) {
            this.holidayDaysData.sun.push(this.holidayDays[key]);
          }
          if(weekDay === 1) {
            this.holidayDaysData.mon.push(this.holidayDays[key]);
          }
          if(weekDay === 2) {
            this.holidayDaysData.tue.push(this.holidayDays[key]);
          }
          if(weekDay === 3) {
            this.holidayDaysData.wed.push(this.holidayDays[key]);
          }
          if(weekDay === 4) {
            this.holidayDaysData.thu.push(this.holidayDays[key]);
          }
          if(weekDay === 5) {
            this.holidayDaysData.fri.push(this.holidayDays[key]);
          }
          if(weekDay === 6) {
            this.holidayDaysData.sat.push(this.holidayDays[key]);
          }

          // inputs
          if (typeof holidayInputs[this.holidayDays[key].userID] === 'undefined') {
            holidayInputs[this.holidayDays[key].userID] = '';
          }

        }

        this.setState({ holidayInputs: holidayInputs });

    } else {

        this.holidayDaysData.mon = [];
        this.holidayDaysData.tue = [];
        this.holidayDaysData.wed = [];
        this.holidayDaysData.thu = [];
        this.holidayDaysData.fri = [];
        this.holidayDaysData.sat = [];
        this.holidayDaysData.sun = [];

    }

  }

  async getSchoolHolidays() {

    const [
        _sun,
        _mon,
        _tue,
        _wed,
        _thu,
        _fri,
        _sat,
    ] = await Promise.all([
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.sundayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.mondayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.tuesdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.wednesdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.thursdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.fridayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
        APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.saturdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
    ]);

    this.schoolHolidayData.sun = _sun;
    this.schoolHolidayData.mon = _mon;
    this.schoolHolidayData.tue = _tue;
    this.schoolHolidayData.wed = _wed;
    this.schoolHolidayData.thu = _thu;
    this.schoolHolidayData.fri = _fri;
    this.schoolHolidayData.sat = _sat;

    this._allSchoolHolidayData.sun = _sun;
    this._allSchoolHolidayData.mon = _mon;
    this._allSchoolHolidayData.tue = _tue;
    this._allSchoolHolidayData.wed = _wed;
    this._allSchoolHolidayData.thu = _thu;
    this._allSchoolHolidayData.fri = _fri;
    this._allSchoolHolidayData.sat = _sat;

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.sun) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.sun = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.mon) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.mon = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.tue) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.tue = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.wed) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.wed = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.thu) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.thu = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.fri) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.fri = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.sat) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.sat = notFilledSection;

    }

  }

  async getStandardAvailability(weekDay, date) {

    if(weekDay === 0) {
      this.standardAvailableData.sun = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` + //         ` AND NOT EXISTS (SELECT 1 FROM Rooster WHERE rooster_info_id = info_id AND rooster_datum = '${date}')` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );
      
      this._allStandardAvailableData.sun = this.standardAvailableData.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.standardAvailableData.mon = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardAvailableData.mon = this.standardAvailableData.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.standardAvailableData.tue = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardAvailableData.tue = this.standardAvailableData.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.standardAvailableData.wed = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardAvailableData.wed = this.standardAvailableData.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.standardAvailableData.thu = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardAvailableData.thu = this.standardAvailableData.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.standardAvailableData.fri = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardAvailableData.fri = this.standardAvailableData.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.standardAvailableData.sat = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 1` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardAvailableData.sat = this.standardAvailableData.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.sat = notFilledSection;
  
      }
    }

    if(this.standardAvailableData === 'error') {

        this.standardAvailableData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getStandardUnavailability(weekDay, date) {

    if(weekDay === 0) {
      this.standardUnavailableData.sun = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` + //         ` AND NOT EXISTS (SELECT 1 FROM Rooster WHERE rooster_info_id = info_id AND rooster_datum = '${date}')` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.sun = this.standardUnavailableData.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.standardUnavailableData.mon = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.mon = this.standardUnavailableData.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.standardUnavailableData.tue = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.tue = this.standardUnavailableData.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.standardUnavailableData.wed = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.wed = this.standardUnavailableData.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.standardUnavailableData.thu = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.thu = this.standardUnavailableData.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.standardUnavailableData.fri = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.fri = this.standardUnavailableData.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.standardUnavailableData.sat = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id, sb_id, sb_dag, sb_beschikbaar, sb_begin, sb_eind, sb_opmerking`,
        `FROM StandaardBeschikbaarheid`,
        `LEFT JOIN Info` +
        ` ON info_id = sb_info_id` +
        ` LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE sb_dag = ${weekDay}` +
        ` AND sb_beschikbaar = 2` +
        ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)`,
        null,
        `ORDER BY sb_dag, sb_begin, sb_eind, info_voornaam, info_achternaam`
      );

      this._allStandardUnavailableData.sat = this.standardUnavailableData.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.sat = notFilledSection;
  
      }
    }

    if(this.standardUnavailableData === 'error') {

        this.standardUnavailableData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  async getStandardNotFilled(weekDay, date) {

    if(weekDay === 0) {
      this.standardNotFilledData.sun = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        // `WHERE NOT EXISTS (SELECT 1 FROM Rooster WHERE rooster_info_id = info_id AND rooster_datum = '${date}')` +
        // ` AND NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        // ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.sun = this.standardNotFilledData.sun;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.standardNotFilledData.mon = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.mon = this.standardNotFilledData.mon;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.standardNotFilledData.tue = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.tue = this.standardNotFilledData.tue;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.standardNotFilledData.wed = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.wed = this.standardNotFilledData.wed;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.standardNotFilledData.thu = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.thu = this.standardNotFilledData.thu;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.standardNotFilledData.fri = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.fri = this.standardNotFilledData.fri;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.standardNotFilledData.sat = await APIGET.Request(
        `SELECT info_id, info_voornaam, info_tussenvoegsel, info_achternaam, functie_id, functie_naam, functie_kleur, functie_afdeling_id`,
        `FROM Info`,
        `LEFT JOIN Functie` +
        ` ON functie_id = info_functie_id`,
        `WHERE NOT EXISTS (SELECT 1 FROM Vakantie WHERE vakantie_info_id = info_id AND '${date}' BETWEEN vakantie_begin AND vakantie_eind AND vakantie_geaccepteerd = 1)` +
        ` AND NOT EXISTS (SELECT 1 FROM StandaardBeschikbaarheid WHERE sb_dag = ${weekDay} AND sb_info_id = info_id AND (sb_beschikbaar = 1 OR sb_beschikbaar = 2))`,
        null,
        `ORDER BY info_voornaam, info_achternaam`
      );

      this._allStandardNotFilledData.sat = this.standardNotFilledData.sat;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.sat = notFilledSection;
  
      }
    }

    if(this.standardNotFilledData === 'error') {

        this.standardNotFilledData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

  }

  getAllPeopleNoAvailabilityData_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.usersNoAvailability.sun = data;
      this._allUsersNoAvailability.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.usersNoAvailability.mon = data;
      this._allUsersNoAvailability.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.usersNoAvailability.tue = data;
      this._allUsersNoAvailability.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.usersNoAvailability.wed = data;
      this._allUsersNoAvailability.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.usersNoAvailability.thu = data;
      this._allUsersNoAvailability.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.usersNoAvailability.fri = data;
      this._allUsersNoAvailability.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.usersNoAvailability.sat = data;
      this._allUsersNoAvailability.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.usersNoAvailability.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.usersNoAvailability.sat = notFilledSection;
  
      }
    }

    if(this.usersNoAvailability === 'error') {

        this.usersNoAvailability = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.usersNoAvailability.mon = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.mon);
      this.usersNoAvailability.tue = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.tue);
      this.usersNoAvailability.wed = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.wed);
      this.usersNoAvailability.thu = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.thu);
      this.usersNoAvailability.fri = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.fri);
      this.usersNoAvailability.sat = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.sat);
      this.usersNoAvailability.sun = Constants.getTeamAccounts(this.teamUsers, this.usersNoAvailability.sun);

    }

    let noAvailableInputs = this.state.noAvailableInputs;

    for (const key in this.usersNoAvailability) {
      for (const inp of this.usersNoAvailability[key]) {
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
          Constants.removeFromArray2(this.usersNoAvailability[key], 'info_id', inp.info_id);
          continue;
        }
        noAvailableInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.usersNoAvailability[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.usersNoAvailability[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.usersNoAvailability[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.usersNoAvailability[key], av);
            }
          }
        }
      }
    }

    return { noAvailableInputs: noAvailableInputs };

    // this.setState({usersNoAvailabilityState: this.usersNoAvailability, noAvailableInputs: noAvailableInputs});

  }

  getAvailableData_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.availableData.sun = data;
      this._allAvailableData.sun = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.sun) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.sun = availabilitySection;
  
      }
    }
    if(weekDay === 1) {
      this.availableData.mon = data;
      this._allAvailableData.mon = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.mon) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.mon = availabilitySection;
  
      }
    }
    if(weekDay === 2) {
      this.availableData.tue = data;
      this._allAvailableData.tue = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.tue) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.tue = availabilitySection;
  
      }
    }
    if(weekDay === 3) {
      this.availableData.wed = data;
      this._allAvailableData.wed = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.wed) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.wed = availabilitySection;
  
      }
    }
    if(weekDay === 4) {
      this.availableData.thu = data;
      this._allAvailableData.thu = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.thu) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.thu = availabilitySection;
  
      }
    }
    if(weekDay === 5) {
      this.availableData.fri = data;
      this._allAvailableData.fri = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.fri) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.fri = availabilitySection;
  
      }
    }
    if(weekDay === 6) {
      this.availableData.sat = data;
      this._allAvailableData.sat = data;

      if(this.state.section !== null) {

        let availabilitySection = [];
  
        for(const _availability of this.availableData.sat) {
  
          if(parseInt(_availability.functie_id) >= 0) {
            if(parseInt(_availability.functie_afdeling_id) === this.state.section) {
              availabilitySection.push(_availability);
            }
          } //else {
            //availabilitySection.push(_availability);
          //}
  
        }
  
        this.availableData.sat = availabilitySection;
  
      }
    }

    if(this.availableData === 'error') {

        this.availableData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.availableData.mon = Constants.getTeamAccounts(this.teamUsers, this.availableData.mon);
      this.availableData.tue = Constants.getTeamAccounts(this.teamUsers, this.availableData.tue);
      this.availableData.wed = Constants.getTeamAccounts(this.teamUsers, this.availableData.wed);
      this.availableData.thu = Constants.getTeamAccounts(this.teamUsers, this.availableData.thu);
      this.availableData.fri = Constants.getTeamAccounts(this.teamUsers, this.availableData.fri);
      this.availableData.sat = Constants.getTeamAccounts(this.teamUsers, this.availableData.sat);
      this.availableData.sun = Constants.getTeamAccounts(this.teamUsers, this.availableData.sun);

    }

    let availableInputs = this.state.availableInputs;

      for (const key in this.availableData) {
        for (const inp of this.availableData[key]) {
          // INACTIVE
          if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.availableData[key], 'info_id', inp.info_id);
              continue;
          }
          // REPEATING AVAILABILITY
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
 
            const date1 = Constants.stringToDate(inp.beschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this.availableData[key], 'info_id', inp.info_id);
            }

          }
          availableInputs[inp.info_id] = ``;
        }
      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.availableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.availableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.availableData[key], av);
              }
            }
          }
        }
      }
    
    // this.setState({availableDataState: this.availableData, availableInputs: availableInputs });

    return { availableInputs: availableInputs };

  }

  getUnavailableData_new(weekDay, date, data) {

      if(weekDay === 0) {
        this.unavailableData.sun = data;
        this._allUnavailableData.sun = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.sun) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.sun = unavailabilitySection;
    
        }
      }
      if(weekDay === 1) {
        this.unavailableData.mon = data;
        this._allUnavailableData.mon = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.mon) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.mon = unavailabilitySection;
    
        }
      }
      if(weekDay === 2) {
        this.unavailableData.tue = data;
        this._allUnavailableData.tue = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.tue) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.tue = unavailabilitySection;
    
        }
      }
      if(weekDay === 3) {
        this.unavailableData.wed = data;
        this._allUnavailableData.wed = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.wed) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.wed = unavailabilitySection;
    
        }
      }
      if(weekDay === 4) {
        this.unavailableData.thu = data;
        this._allUnavailableData.thu = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.thu) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.thu = unavailabilitySection;
    
        }
      }
      if(weekDay === 5) {
        this.unavailableData.fri = data;
        this._allUnavailableData.fri = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.fri) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.fri = unavailabilitySection;
    
        }
      }
      if(weekDay === 6) {
        this.unavailableData.sat = data;
        this._allUnavailableData.sat = data;

        if(this.state.section !== null) {

          let unavailabilitySection = [];
    
          for(const _unavailability of this.unavailableData.sat) {
    
            if(parseInt(_unavailability.functie_id) >= 0) {
              if(parseInt(_unavailability.functie_afdeling_id) === this.state.section) {
                unavailabilitySection.push(_unavailability);
              }
            } //else {
              //unavailabilitySection.push(_unavailability);
            //}
    
          }
    
          this.unavailableData.sat = unavailabilitySection;
    
        }
      }

      if(this.unavailableData === 'error') {

          this.unavailableData = [];
          
          this.setState({
              errorScreen: true,
              loading: false,
          });
          return;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.unavailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.mon);
        this.unavailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.tue);
        this.unavailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.wed);
        this.unavailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.thu);
        this.unavailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.fri);
        this.unavailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sat);
        this.unavailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.unavailableData.sun);
  
      }

      let unavailableInputs = this.state.unavailableInputs;

      for (const key in this.unavailableData) {
        for (const inp of this.unavailableData[key]) {
          // INACTIVE
          if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.unavailableData[key], 'info_id', inp.info_id);
              continue;
          }
          // REPEATING AVAILABILITY
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
 
            const date1 = Constants.stringToDate(inp.onbeschikbaar_datum);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this.unavailableData[key], 'info_id', inp.info_id);
            }

          }
          unavailableInputs[inp.info_id] = ``;
        }
      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.unavailableData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.unavailableData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.unavailableData[key], av);
              }
            }
          }
        }
      }
      
      //this.setState({unavailableDataState: this.unavailableData, unavailableInputs: unavailableInputs });

      return{ unavailableInputs: unavailableInputs };

  }

  getNotFilledData_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.notFilledData.sun = data;
      this._allNotFilledData.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.notFilledData.mon = data;
      this._allNotFilledData.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.notFilledData.tue = data;
      this._allNotFilledData.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.notFilledData.wed = data;
      this._allNotFilledData.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.notFilledData.thu = data;
      this._allNotFilledData.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.notFilledData.fri = data;
      this._allNotFilledData.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.notFilledData.sat = data;
      this._allNotFilledData.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledData.sat = notFilledSection;
  
      }
    }

      if(this.notFilledData === 'error') {

          this.notFilledData = [];
          
          this.setState({
              errorScreen: true,
              loading: false,
          });
          return;
      }

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        this.notFilledData.mon = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.mon);
        this.notFilledData.tue = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.tue);
        this.notFilledData.wed = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.wed);
        this.notFilledData.thu = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.thu);
        this.notFilledData.fri = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.fri);
        this.notFilledData.sat = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.sat);
        this.notFilledData.sun = Constants.getTeamAccounts(this.teamUsers, this.notFilledData.sun);
  
      }

      let notFilledInputs = this.state.notFilledInputs;

      for (const key in this.notFilledData) {
        for (const inp of this.notFilledData[key]) {
          // INACTIVE
          if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
              Constants.removeFromArray2(this.notFilledData[key], 'info_id', inp.info_id);
              continue;
          }
          // REPEATING AVAILABILITY
          if (typeof this.repeating[inp.info_id] === 'undefined') {
            continue;
          }

          for (const r of this.repeating[inp.info_id]) {
 
            const date1 = key === 'mon' ? Constants.stringToDate(this.state.mondayDate) : key === 'tue' ? Constants.stringToDate(this.state.tuesdayDate) : key === 'wed' ? Constants.stringToDate(this.state.wednesdayDate) : key === 'thu' ? Constants.stringToDate(this.state.thursdayDate) : key === 'fri' ? Constants.stringToDate(this.state.fridayDate) : key === 'sat' ? Constants.stringToDate(this.state.saturdayDate) : key === 'sun' ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
            const date2 = Constants.stringToDate(r.hb_datum);
            const interval = parseInt(r.hb_interval);
            const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
            const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
            const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
            const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
            if (isInInterval && date1 >= date2) {
              Constants.removeFromArray2(this.notFilledData[key], 'info_id', inp.info_id);
            }

          }
          notFilledInputs[inp.info_id] = ``;
        }
      }

      if (this.state.display === 'LIST') {
        for (const key in this.allPlannerData) {
          for (const planAll of this.allPlannerData[key]) {
            for (const av of this.notFilledData[key]) {
              if (planAll.info_id === av.info_id) {
                Constants.removeFromArray(this.notFilledData[key], av);
              }
            }
          }
        }
        for (const userKey in this.unpublishedRepeatingShifts) {
          for (const r of this.unpublishedRepeatingShifts[userKey]) {
            const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
            for (const av of this.notFilledData[key]) {
              if (r.info_id === av.info_id) {
                Constants.removeFromArray(this.notFilledData[key], av);
              }
            }
          }
        }
      }
      
      // this.setState({notFilledDataState: this.notFilledData, notFilledInputs: notFilledInputs });

      return { notFilledInputs: notFilledInputs };

  }

  getNotFilledSchool_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.notFilledSchool.sun = data;
      this._allNotFilledSchool.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.notFilledSchool.mon = data;
      this._allNotFilledSchool.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.notFilledSchool.tue = data;
      this._allNotFilledSchool.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.notFilledSchool.wed = data;
      this._allNotFilledSchool.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.notFilledSchool.thu = data;
      this._allNotFilledSchool.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.notFilledSchool.fri = data;
      this._allNotFilledSchool.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.notFilledSchool.sat = data;
      this._allNotFilledSchool.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.notFilledSchool.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.notFilledSchool.sat = notFilledSection;
  
      }
    }

    if(this.notFilledSchool === 'error') {

        this.notFilledSchool = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.notFilledSchool.mon = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.mon);
      this.notFilledSchool.tue = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.tue);
      this.notFilledSchool.wed = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.wed);
      this.notFilledSchool.thu = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.thu);
      this.notFilledSchool.fri = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.fri);
      this.notFilledSchool.sat = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.sat);
      this.notFilledSchool.sun = Constants.getTeamAccounts(this.teamUsers, this.notFilledSchool.sun);

    }

    let schoolNotFilledInputs = this.state.schoolNotFilledInputs;

    for (const key in this.notFilledSchool) {
      for (const inp of this.notFilledSchool[key]) {
        // INACTIVE
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.notFilledSchool[key], 'info_id', inp.info_id);
            continue;
        }
        // REPEATING AVAILABILITY
        if (typeof this.repeating[inp.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[inp.info_id]) {
 
          const date1 = key === 'mon' ? Constants.stringToDate(this.state.mondayDate) : key === 'tue' ? Constants.stringToDate(this.state.tuesdayDate) : key === 'wed' ? Constants.stringToDate(this.state.wednesdayDate) : key === 'thu' ? Constants.stringToDate(this.state.thursdayDate) : key === 'fri' ? Constants.stringToDate(this.state.fridayDate) : key === 'sat' ? Constants.stringToDate(this.state.saturdayDate) : key === 'sun' ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(this.notFilledSchool[key], 'info_id', inp.info_id);
          }

        }
        schoolNotFilledInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.notFilledSchool[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.notFilledSchool[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.notFilledSchool[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.notFilledSchool[key], av);
            }
          }
        }
      }
    }

    return { schoolNotFilledInputs: schoolNotFilledInputs };
    
    // this.setState({notFilledSchoolState: this.notFilledSchool, schoolNotFilledInputs: schoolNotFilledInputs});

  }

  getSchoolSchedule_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.schoolScheduleData.sun = data;
      this._allSchoolScheduleData.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.schoolScheduleData.mon = data;
      this._allSchoolScheduleData.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.schoolScheduleData.tue = data;
      this._allSchoolScheduleData.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.schoolScheduleData.wed = data;
      this._allSchoolScheduleData.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.schoolScheduleData.thu = data;
      this._allSchoolScheduleData.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.schoolScheduleData.fri = data;
      this._allSchoolScheduleData.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.schoolScheduleData.sat = data;
      this._allSchoolScheduleData.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.schoolScheduleData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.schoolScheduleData.sat = notFilledSection;
  
      }
    }

    if(this.schoolScheduleData === 'error') {

        this.schoolScheduleData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.schoolScheduleData.mon = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.mon);
      this.schoolScheduleData.tue = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.tue);
      this.schoolScheduleData.wed = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.wed);
      this.schoolScheduleData.thu = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.thu);
      this.schoolScheduleData.fri = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.fri);
      this.schoolScheduleData.sat = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.sat);
      this.schoolScheduleData.sun = Constants.getTeamAccounts(this.teamUsers, this.schoolScheduleData.sun);

    }

    let schoolInputs = this.state.schoolInputs;

    for (const key in this.schoolScheduleData) {
      for (const inp of this.schoolScheduleData[key]) {
        // INACTIVE
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.schoolScheduleData[key], 'info_id', inp.info_id);
            continue;
        }
        // REPEATING AVAILABILITY
        if (typeof this.repeating[inp.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[inp.info_id]) {
 
          const date1 = key === 'mon' ? Constants.stringToDate(this.state.mondayDate) : key === 'tue' ? Constants.stringToDate(this.state.tuesdayDate) : key === 'wed' ? Constants.stringToDate(this.state.wednesdayDate) : key === 'thu' ? Constants.stringToDate(this.state.thursdayDate) : key === 'fri' ? Constants.stringToDate(this.state.fridayDate) : key === 'sat' ? Constants.stringToDate(this.state.saturdayDate) : key === 'sun' ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(this.schoolScheduleData[key], 'info_id', inp.info_id);
          }

        }
        schoolInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.schoolScheduleData[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.schoolScheduleData[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.schoolScheduleData[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.schoolScheduleData[key], av);
            }
          }
        }
      }
    }

    return { schoolInputs: schoolInputs };
    
    // this.setState({schoolScheduleDataState: this.schoolScheduleData, schoolInputs: schoolInputs});

  }

  getSchoolHolidays_new(dsun, dmon, dtue, dwed, dthu, dfri, dsat) {
    
    // this.schoolHolidayData.sun = await APIGetAllSchoolHolidays.Request(0, this.state.sundayDate);
    // this.schoolHolidayData.mon = await APIGetAllSchoolHolidays.Request(1, this.state.mondayDate);
    // // this.schoolHolidayData.tue = await APIGetAllSchoolHolidays.Request(2, this.state.tuesdayDate);
    // this.schoolHolidayData.tue = await APIGET.Request(`SELECT info_id, info_voornaam, info_achternaam, info_tussenvoegsel, Functie.*, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE '${this.state.tuesdayDate}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null);
    // this.schoolHolidayData.wed = await APIGetAllSchoolHolidays.Request(3, this.state.wednesdayDate);
    // this.schoolHolidayData.thu = await APIGetAllSchoolHolidays.Request(4, this.state.thursdayDate);
    // this.schoolHolidayData.fri = await APIGetAllSchoolHolidays.Request(5, this.state.fridayDate);
    // this.schoolHolidayData.sat = await APIGetAllSchoolHolidays.Request(6, this.state.saturdayDate);

    this.schoolHolidayData.sun = dsun;
    this.schoolHolidayData.mon = dmon;
    this.schoolHolidayData.tue = dtue;
    this.schoolHolidayData.wed = dwed;
    this.schoolHolidayData.thu = dthu;
    this.schoolHolidayData.fri = dfri;
    this.schoolHolidayData.sat = dsat;

    this._allSchoolHolidayData.sun = dsun;
    this._allSchoolHolidayData.mon = dmon;
    this._allSchoolHolidayData.tue = dtue;
    this._allSchoolHolidayData.wed = dwed;
    this._allSchoolHolidayData.thu = dthu;
    this._allSchoolHolidayData.fri = dfri;
    this._allSchoolHolidayData.sat = dsat;

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.sun) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.sun = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.mon) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.mon = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.tue) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.tue = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.wed) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.wed = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.thu) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.thu = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.fri) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.fri = notFilledSection;

    }

    if(this.state.section !== null) {

      let notFilledSection = [];

      for(const _notFilled of this.schoolHolidayData.sat) {

        if(parseInt(_notFilled.functie_id) >= 0) {
          if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
            notFilledSection.push(_notFilled);
          }
        } //else {
          //notFilledSection.push(_notFilled);
        //}

      }

      this.schoolHolidayData.sat = notFilledSection;

    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.schoolHolidayData.mon = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.mon);
      this.schoolHolidayData.tue = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.tue);
      this.schoolHolidayData.wed = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.wed);
      this.schoolHolidayData.thu = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.thu);
      this.schoolHolidayData.fri = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.fri);
      this.schoolHolidayData.sat = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.sat);
      this.schoolHolidayData.sun = Constants.getTeamAccounts(this.teamUsers, this.schoolHolidayData.sun);

    }

    let schoolHolidayInputs = this.state.schoolHolidayInputs;

    for (const key in this.schoolHolidayData) {
      for (const inp of this.schoolHolidayData[key]) {
        // INACTIVE
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.schoolHolidayData[key], 'info_id', inp.info_id);
            continue;
        }
        // REPEATING AVAILABILITY
        if (typeof this.repeating[inp.info_id] === 'undefined') {
          continue;
        }

        for (const r of this.repeating[inp.info_id]) {
 
          const date1 = key === 'mon' ? Constants.stringToDate(this.state.mondayDate) : key === 'tue' ? Constants.stringToDate(this.state.tuesdayDate) : key === 'wed' ? Constants.stringToDate(this.state.wednesdayDate) : key === 'thu' ? Constants.stringToDate(this.state.thursdayDate) : key === 'fri' ? Constants.stringToDate(this.state.fridayDate) : key === 'sat' ? Constants.stringToDate(this.state.saturdayDate) : key === 'sun' ? Constants.stringToDate(this.state.sundayDate) : Constants.stringToDate(this.state.mondayDate);
          const date2 = Constants.stringToDate(r.hb_datum);
          const interval = parseInt(r.hb_interval);
          const calc1 = r.hb_type === 'day' ? date1.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date1) : r.hb_type === 'month' ? date1.getMonth() : date1.getFullYear();
          const calc2 = r.hb_type === 'day' ? date2.getDate() : r.hb_type === 'week' ? Constants.getWeekNumber2(date2) : r.hb_type === 'month' ? date2.getMonth() : date2.getFullYear();
          const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
          const isInInterval = r.hb_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hb_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hb_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
              
          if (isInInterval && date1 >= date2) {
            Constants.removeFromArray2(this.schoolHolidayData[key], 'info_id', inp.info_id);
          }

        }
        schoolHolidayInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.schoolHolidayData[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.schoolHolidayData[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.schoolHolidayData[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.schoolHolidayData[key], av);
            }
          }
        }
      }
    }

    return { schoolHolidayInputs: schoolHolidayInputs };

    // this.setState({ schoolHolidayDataState: this.schoolHolidayData, schoolHolidayInputs: schoolHolidayInputs });

    // if(holidayDays === 'error') {

    //     holidayDays = [];

    //     this.setState({ 
    //         errorScreen: true,
    //         loading: false,
    //     });

    //     return;
    // }

    // if(holidayDays != null && holidayDays.length > 0) {

    //     let allHolidayDays = [];
        
    //     for(let key in holidayDays) { //iterate through holidays ranges from DB

    //         if(holidayDays[key].vakantie_geaccepteerd === '1') {

    //             let currentDate = new Date(holidayDays[key].vakantie_begin);
    //             let endDate = new Date(holidayDays[key].vakantie_eind);

    //             currentDate.setHours(12,0,0,0);
    //             endDate.setHours(12,0,0,0);

    //             while(currentDate <= endDate) {

    //                 const userFullName = `${holidayDays[key].info_voornaam} ${(holidayDays[key].info_tussenvoegsel !== null && holidayDays[key].info_tussenvoegsel !== '') ? holidayDays[key].info_tussenvoegsel + " " + holidayDays[key].info_achternaam.charAt(0) : holidayDays[key].info_achternaam.charAt(0)}.`;

    //                 allHolidayDays.push({
    //                     date: new Date(currentDate),
    //                     name: userFullName,
    //                     comment: holidayDays[key].vakantie_opmerking,
    //                     end: holidayDays[key].vakantie_eind,
    //                 });

    //                 currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

    //             }

    //         }

    //     }

    //     this.holidayDays = allHolidayDays;

    //     for(let key in this.holidayDays) {

    //       const weekDay = this.holidayDays[key].date.getDay();

    //       if(weekDay === 0) {
    //         this.holidayDaysData.sun.push(this.holidayDays[key]);
    //       }
    //       if(weekDay === 1) {
    //         this.holidayDaysData.mon.push(this.holidayDays[key]);
    //       }
    //       if(weekDay === 2) {
    //         this.holidayDaysData.tue.push(this.holidayDays[key]);
    //       }
    //       if(weekDay === 3) {
    //         this.holidayDaysData.wed.push(this.holidayDays[key]);
    //       }
    //       if(weekDay === 4) {
    //         this.holidayDaysData.thu.push(this.holidayDays[key]);
    //       }
    //       if(weekDay === 5) {
    //         this.holidayDaysData.fri.push(this.holidayDays[key]);
    //       }
    //       if(weekDay === 6) {
    //         this.holidayDaysData.sat.push(this.holidayDays[key]);
    //       }

    //     }

    // } else {

    //     this.holidayDaysData.mon = [];
    //     this.holidayDaysData.tue = [];
    //     this.holidayDaysData.wed = [];
    //     this.holidayDaysData.thu = [];
    //     this.holidayDaysData.fri = [];
    //     this.holidayDaysData.sat = [];
    //     this.holidayDaysData.sun = [];

    // }

  }

  getStandardAvailability_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.standardAvailableData.sun = data;
      this._allStandardAvailableData.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.standardAvailableData.mon = data;
      this._allStandardAvailableData.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.standardAvailableData.tue = data;
      this._allStandardAvailableData.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.standardAvailableData.wed = data;
      this._allStandardAvailableData.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.standardAvailableData.thu = data;
      this._allStandardAvailableData.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.standardAvailableData.fri = data;
      this._allStandardAvailableData.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.standardAvailableData.sat = data;
      this._allStandardAvailableData.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardAvailableData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardAvailableData.sat = notFilledSection;
  
      }
    }

    if(this.standardAvailableData === 'error') {

        this.standardAvailableData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.standardAvailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.mon);
      this.standardAvailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.tue);
      this.standardAvailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.wed);
      this.standardAvailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.thu);
      this.standardAvailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.fri);
      this.standardAvailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.sat);
      this.standardAvailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.standardAvailableData.sun);

    }

    let schoolNotFilledInputs = this.state.standardAvailableInputs; //INPUTS NEEDS TO BE DONE

    for (const key in this.standardAvailableData) {
      for (const inp of this.standardAvailableData[key]) {
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.standardAvailableData[key], 'info_id', inp.info_id);
            continue;
        }
        schoolNotFilledInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.standardAvailableData[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.standardAvailableData[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.standardAvailableData[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.standardAvailableData[key], av);
            }
          }
        }
      }
    }

    return { standardAvailableInputs: schoolNotFilledInputs };
    
    // this.setState({standardAvailableDataState: this.standardAvailableData, standardAvailableInputs: schoolNotFilledInputs});

  }

  getStandardUnavailability_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.standardUnavailableData.sun = data;
      this._allStandardUnavailableData.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.standardUnavailableData.mon = data;
      this._allStandardUnavailableData.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.standardUnavailableData.tue = data;
      this._allStandardUnavailableData.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.standardUnavailableData.wed = data;
      this._allStandardUnavailableData.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.standardUnavailableData.thu = data;
      this._allStandardUnavailableData.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.standardUnavailableData.fri = data;
      this._allStandardUnavailableData.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.standardUnavailableData.sat = data;
      this._allStandardUnavailableData.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardUnavailableData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardUnavailableData.sat = notFilledSection;
  
      }
    }

    if(this.standardUnavailableData === 'error') {

        this.standardUnavailableData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.standardUnavailableData.mon = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.mon);
      this.standardUnavailableData.tue = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.tue);
      this.standardUnavailableData.wed = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.wed);
      this.standardUnavailableData.thu = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.thu);
      this.standardUnavailableData.fri = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.fri);
      this.standardUnavailableData.sat = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.sat);
      this.standardUnavailableData.sun = Constants.getTeamAccounts(this.teamUsers, this.standardUnavailableData.sun);

    }

    let schoolNotFilledInputs = this.state.standardAvailableInputs; //INPUTS NEEDS TO BE DONE

    for (const key in this.standardUnavailableData) {
      for (const inp of this.standardUnavailableData[key]) {
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.standardUnavailableData[key], 'info_id', inp.info_id);
            continue;
        }
        schoolNotFilledInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.standardUnavailableData[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.standardUnavailableData[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.standardUnavailableData[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.standardUnavailableData[key], av);
            }
          }
        }
      }
    }

    return { standardUnavailableInputs: schoolNotFilledInputs };
    
    // this.setState({standardUnavailableDataState: this.standardUnavailableData, standardUnavailableInputs: schoolNotFilledInputs});

  }

  getStandardNotFilled_new(weekDay, date, data) {

    if(weekDay === 0) {
      this.standardNotFilledData.sun = data;
      this._allStandardNotFilledData.sun = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.sun) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.sun = notFilledSection;
  
      }
    }
    if(weekDay === 1) {
      this.standardNotFilledData.mon = data;
      this._allStandardNotFilledData.mon = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.mon) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.mon = notFilledSection;
  
      }
    }
    if(weekDay === 2) {
      this.standardNotFilledData.tue = data;
      this._allStandardNotFilledData.tue = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.tue) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.tue = notFilledSection;
  
      }
    }
    if(weekDay === 3) {
      this.standardNotFilledData.wed = data;
      this._allStandardNotFilledData.wed = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.wed) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.wed = notFilledSection;
  
      }
    }
    if(weekDay === 4) {
      this.standardNotFilledData.thu = data;
      this._allStandardNotFilledData.thu = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.thu) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.thu = notFilledSection;
  
      }
    }
    if(weekDay === 5) {
      this.standardNotFilledData.fri = data;
      this._allStandardNotFilledData.fri = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.fri) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.fri = notFilledSection;
  
      }
    }
    if(weekDay === 6) {
      this.standardNotFilledData.sat = data;
      this._allStandardNotFilledData.sat = data;

      if(this.state.section !== null) {

        let notFilledSection = [];
  
        for(const _notFilled of this.standardNotFilledData.sat) {
  
          if(parseInt(_notFilled.functie_id) >= 0) {
            if(parseInt(_notFilled.functie_afdeling_id) === this.state.section) {
              notFilledSection.push(_notFilled);
            }
          } //else {
            //notFilledSection.push(_notFilled);
          //}
  
        }
  
        this.standardNotFilledData.sat = notFilledSection;
  
      }
    }

    if(this.standardNotFilledData === 'error') {

        this.standardNotFilledData = [];
        
        this.setState({
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      this.standardNotFilledData.mon = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.mon);
      this.standardNotFilledData.tue = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.tue);
      this.standardNotFilledData.wed = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.wed);
      this.standardNotFilledData.thu = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.thu);
      this.standardNotFilledData.fri = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.fri);
      this.standardNotFilledData.sat = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.sat);
      this.standardNotFilledData.sun = Constants.getTeamAccounts(this.teamUsers, this.standardNotFilledData.sun);

    }

    let schoolNotFilledInputs = this.state.standardAvailableInputs; //INPUTS NEEDS TO BE DONE

    for (const key in this.standardNotFilledData) {
      for (const inp of this.standardNotFilledData[key]) {
        if (typeof this.inactiveUsers[inp.info_id] !== 'undefined') {
            Constants.removeFromArray2(this.standardNotFilledData[key], 'info_id', inp.info_id);
            continue;
        }
        schoolNotFilledInputs[inp.info_id] = ``;
      }
    }

    if (this.state.display === 'LIST') {
      for (const key in this.allPlannerData) {
        for (const planAll of this.allPlannerData[key]) {
          for (const av of this.standardNotFilledData[key]) {
            if (planAll.info_id === av.info_id) {
              Constants.removeFromArray(this.standardNotFilledData[key], av);
            }
          }
        }
      }
      for (const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          const key = r.date.getDay() === 1 ? 'mon' : r.date.getDay() === 2 ? 'tue' : r.date.getDay() === 3 ? 'wed' : r.date.getDay() === 4 ? 'thu' : r.date.getDay() === 5 ? 'fri' : r.date.getDay() === 6 ? 'sat' : 'sun';
          for (const av of this.standardNotFilledData[key]) {
            if (r.info_id === av.info_id) {
              Constants.removeFromArray(this.standardNotFilledData[key], av);
            }
          }
        }
      }
    }

    return { standardNotFilledInputs: schoolNotFilledInputs };
    
    // this.setState({standardNotFilledDataState: this.standardNotFilledData, standardNotFilledInputs: schoolNotFilledInputs});

  }

  size(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  async smartPlan(options) {

    this.closeSmartPlanner();

    this.setState({loading: true});

    let tempDayShiftData = [];
    let tempAvailabilityData = [];

    // MONDAY

    if (options.monday === true) {

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.mon) {
            const date1 = new Date(`${this.state.mondayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.mondayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.mon) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.mon) {
            const date1 = new Date(`${this.state.mondayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.mondayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.mon) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.mon) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.mon) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.mon) {
            const date1 = new Date(`${this.state.mondayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.mondayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.mon) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.mon) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.mondayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

          // let foundContract = false;

          // for (const person of this.state.contracts) {

          //   if (parseInt(person.contract_info_id) === parseInt(tempAvailabilityData[key].info_id)) {
      
          //     const salary = isNaN(parseFloat(person.contract_bruto_uurloon)) ? 999999999 : parseFloat(person.contract_bruto_uurloon);
          //     tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};
          //     foundContract = true;
          //     break;
      
          //   }
      
          // }

          // if (foundContract === false) {
          //   tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 999999999};
          // }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.mon) {
        const date1 = new Date(`${this.state.mondayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.mondayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.mondayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.mondayDate, options);

    }


    //TUESDAY

    if (options.tuesday === true) {

      tempDayShiftData = [];
      tempAvailabilityData = [];

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.tue) {
            const date1 = new Date(`${this.state.tuesdayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.tuesdayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.tue) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.tue) {
            const date1 = new Date(`${this.state.tuesdayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.tuesdayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.tue) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.tue) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.tue) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.tue) {
            const date1 = new Date(`${this.state.tuesdayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.tuesdayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.tue) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.tue) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.tuesdayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.tue) {
        const date1 = new Date(`${this.state.tuesdayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.tuesdayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.tuesdayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.tuesdayDate, options);

    }


    // WEDSNESDAY

    if (options.wednesday === true) {

      tempDayShiftData = [];
      tempAvailabilityData = [];

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.wed) {
            const date1 = new Date(`${this.state.wednesdayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.wednesdayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.wed) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.wed) {
            const date1 = new Date(`${this.state.wednesdayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.wednesdayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.wed) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.wed) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.wed) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.wed) {
            const date1 = new Date(`${this.state.wednesdayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.wednesdayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.wed) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.wed) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.wednesdayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.wed) {
        const date1 = new Date(`${this.state.wednesdayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.wednesdayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.wednesdayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.wednesdayDate, options);

    }

    // THURSDAY

    if (options.thurday === true) {

      tempDayShiftData = [];
      tempAvailabilityData = [];

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.thu) {
            const date1 = new Date(`${this.state.thursdayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.thursdayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.thu) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.thu) {
            const date1 = new Date(`${this.state.thursdayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.thursdayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.thu) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.thu) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.thu) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.thu) {
            const date1 = new Date(`${this.state.thursdayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.thursdayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.thu) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.thu) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.thursdayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.thu) {
        const date1 = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.thursdayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.thursdayDate, options);

    }

    // FRIDAY

    if (options.friday === true) {

      tempDayShiftData = [];
      tempAvailabilityData = [];

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.fri) {
            const date1 = new Date(`${this.state.fridayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.fridayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.fri) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.fri) {
            const date1 = new Date(`${this.state.fridayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.fridayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.fri) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.fri) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.fri) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.fri) {
            const date1 = new Date(`${this.state.fridayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.fridayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.fri) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.fri) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.fridayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.fri) {
        const date1 = new Date(`${this.state.fridayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.fridayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.fridayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.fridayDate, options);

    }

    // SATURDAY

    if (options.saturday === true) {

      tempDayShiftData = [];
      tempAvailabilityData = [];

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.sat) {
            const date1 = new Date(`${this.state.saturdayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.saturdayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.sat) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.sat) {
            const date1 = new Date(`${this.state.saturdayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.saturdayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.sat) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.sat) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.sat) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.sat) {
            const date1 = new Date(`${this.state.saturdayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.saturdayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.sat) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.sat) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.saturdayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.sat) {
        const date1 = new Date(`${this.state.saturdayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.saturdayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.saturdayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.saturdayDate, options);

    }

    // SUNDAY

    if (options.sunday === true) {

      tempDayShiftData = [];
      tempAvailabilityData = [];

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        if (options.available) {
          for (const person of this.availableData.sun) {
            const date1 = new Date(`${this.state.sundayDate}T${person.beschikbaar_begin}`);
            const date2 = new Date(`${this.state.sundayDate}T${person.beschikbaar_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.notFilledData.sun) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.beschikbaar_eind > b.beschikbaar_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'STANDARD') {

        if (options.available) {
          for (const person of this.standardAvailableData.sun) {
            const date1 = new Date(`${this.state.sundayDate}T${person.sb_begin}`);
            const date2 = new Date(`${this.state.sundayDate}T${person.sb_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00' ? 99999999999 : date2.getTime() - date1.getTime(),
            });
          }
        }

        if (options.notFilled) {
          for (const person of this.standardNotFilledData.sun) {
            tempAvailabilityData.push({
              ...person,
              duration: 0,
            });
          }
        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.sb_eind > b.sb_eind)) ? -1 : 1);

      }

      if(Data.data.appOptions.availability === 'SCHOOL') {

        if (options.noSchool === true) {

          for (const person of this.notFilledSchool.sun) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.schoolHoliday === true) {

          for (const person of this.schoolHolidayData.sun) {

            tempAvailabilityData.push({
              ...person,
              duration: 99999999999,
            });
          }

        }

        if (options.school === true) {

          for (const person of this.schoolScheduleData.sun) {
            const date1 = new Date(`${this.state.sundayDate}T${person.schoolrooster_begin}`);
            const date2 = new Date(`${this.state.sundayDate}T${person.schoolrooster_eind}`);

            tempAvailabilityData.push({
              ...person,
              duration: date2.getTime() - date1.getTime(),
            });
          }

        }

        tempAvailabilityData.sort((a, b) => (a.duration > b.duration || (typeof a.schoolrooster_eind !== 'undefined' && typeof b.schoolrooster_eind !== 'undefined' && a.duration === b.duration && a.schoolrooster_eind < b.schoolrooster_eind)) ? -1 : 1);

      }

      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

      //   for (const person of this.notFilledData.sun) {
      //     tempAvailabilityData.push({
      //       ...person,
      //       duration: 0,
      //     });
      //   }

      // }

      if(Data.data.appOptions.availability === 'NONE') {

        for (const person of this.usersNoAvailability.sun) {
          tempAvailabilityData.push({
            ...person,
            duration: 0,
          });
        }

      }

      if (options.cheapest === true) {

        for (let key in tempAvailabilityData) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[tempAvailabilityData[key].info_id], Constants.stringToDate(this.state.sundayDate));

          if (contract !== null) {

            const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: salary};

          } else {

            tempAvailabilityData[key] = {...tempAvailabilityData[key], salary: 0};

          }

        }

        tempAvailabilityData.sort((a, b) => a.salary < b.salary ? -1 : 1);

      }

      for (const shift of this.dayShiftsData.sun) {
        const date1 = new Date(`${this.state.sundayDate}T${shift.dagdienst_begin}`);
        const date2 = new Date(`${this.state.sundayDate}T${shift.dagdienst_eind}`);

        tempDayShiftData.push({
          ...shift,
          duration: date2.getTime() - date1.getTime(),
        });
      }

      tempDayShiftData.sort((a, b) => (a.duration > b.duration || (a.duration === b.duration && a.dagdienst_eind > b.dagdienst_eind)) ? -1 : 1);

      if (options.defaultWorkDays === true) {
        await this.planUsersDefaultWorkdays_smartPlan(tempAvailabilityData, tempDayShiftData, this.state.sundayDate, options);
      }
      await this.calculateBestPersonForShift(tempAvailabilityData, tempDayShiftData, this.state.sundayDate, options);

    }

    await this.reloadScreen2FullScreen();

    this.setState({loading: false, reloading: false});

  }

  addWorkedDays(id) {

    const userID = parseInt(id);

    if (typeof this.userDaysPlanned[userID] === 'undefined') {
      this.userDaysPlanned[userID] = 1;
    } else {
      this.userDaysPlanned[userID] = this.userDaysPlanned[userID] + 1;
    }

  }

  personHasSecundaryFunction(user, fid) {
    
    if (typeof user.info_id === 'undefined') {
      user.info_id = user.account_id;
    }

    if (typeof this.state.secondaryFunctions[user.info_id] !== 'undefined') {

        for (const func of this.state.secondaryFunctions[user.info_id]) {

            if (func.functie_id === fid) {

                return true;

            }

        }

        return false;

    } else {

        return false;

    }

  }

  async calculateBestPersonForShift(personList, shiftList, date, options) {

    let everythingFilled = true;
    let shiftsToDelete = [];

    loop1:
    for (const person of personList) {

      if (this.userDaysPlanned[parseInt(person.info_id)] >= options.maxDays) { // add to options max days TODO
        continue;
      }

      const _dateObj = Constants.stringToDate(date);
      const _dayKey = _dateObj.getDay() === 0 ? 'sun' : _dateObj.getDay() === 1 ? 'mon' : _dateObj.getDay() === 2 ? 'tue' : _dateObj.getDay() === 3 ? 'wed' : _dateObj.getDay() === 4 ? 'thu' : _dateObj.getDay() === 5 ? 'fri' : _dateObj.getDay() === 6 ? 'sat' : 'sun';
      for (const _plan of this.allPlannerData[_dayKey]) {
        if (_plan.rooster_datum === date && _plan.info_id === person.info_id) {
          continue loop1;
        }
      }

      const _wdKey = _dateObj.getDay() === 0 ? 'info2_werkdag_zondag' : _dateObj.getDay() === 1 ? 'info2_werkdag_maandag' : _dateObj.getDay() === 2 ? 'info2_werkdag_dinsdag' : _dateObj.getDay() === 3 ? 'info2_werkdag_woensdag' : _dateObj.getDay() === 4 ? 'info2_werkdag_donderdag' : _dateObj.getDay() === 5 ? 'info2_werkdag_vrijdag' : _dateObj.getDay() === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
      if (this.state.infoExtra[person.info_id][_wdKey] === '0') {
        continue;
      }
      
      if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        const contract = Constants.getCurrentContract(this.state.contractsObj[person.info_id], Constants.stringToDate(date));
        if (contract === null) {
          continue;
        }
        // // has contract
        // if (typeof this.state.contractsObj[person.info_id] !== 'undefined') {
        //   const contract = this.state.contractsObj[person.info_id];
        //   // has valid end date
        //   if (Constants.isValidDateString(contract.contract_eind)) {
        //     const endDate = Constants.stringToDate(contract.contract_eind);
  
        //     if (Constants.formatDate(endDate) <= date) {
        //       continue;
        //     } 

        //   }
        // }
      }

      let index = 0;
      for (let shift of shiftList) {

        if (Constants.isEmpty(options.function) === false) {
          if (shift.functie_id !== options.function) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options);
            return;
          }
        }

        if (Constants.isEmpty(options.section) === false) {
          if (shift.functie_afdeling_id !== options.section) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options);
            return;
          }
        }

        if (typeof this.state.partDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
          Constants.removeFromArray(shiftList, shift);
          await this.calculateBestPersonForShift(personList, shiftList, date, options);
          return;
        }

        if(Data.data.appOptions.availability === 'AVAILABILITY') {

          // person not filled
          if(typeof person.beschikbaar_begin === 'undefined' && typeof person.beschikbaar_eind === 'undefined' && person.duration === 0) {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          }

          // person is whole day available
          if(person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00') {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.beschikbaar_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.beschikbaar_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(person.functie_id === shift.functie_id) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop1;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'STANDARD') {

          // person not filled
          if(typeof person.sb_begin === 'undefined' && typeof person.sb_eind === 'undefined' && person.duration === 0) {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          }

          // person is whole day available
          if(person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00') {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.sb_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.sb_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(person.functie_id === shift.functie_id) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop1;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'SCHOOL') {

          // person is whole day available
          if(person.duration === 99999999999) {

            if(person.functie_id === shift.functie_id) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.schoolrooster_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.schoolrooster_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(person.functie_id === shift.functie_id) {

              if((planStart < availStart && planEnd < availStart) || (planStart > availEnd && planEnd > availEnd)) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration < person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if (Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'NONE') {

          if(person.functie_id === shift.functie_id) {

            shift.rooster_begin = shift.dagdienst_begin;
            shift.rooster_eind = shift.dagdienst_eind;
            shift.rooster_pauze = shift.dagdienst_pauze;

            if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
              Constants.removeFromArray(personList, person);
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

            if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
              continue;
            }

            const added = await this.planUser(date, person, shift);
            if(added !== null) {

              // add comps from dayshift
              if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                  await APIAddSkillSchedule.Request(skill.comp_id, added);
                }
              }

              // reload cumulatives
              let cumulatives = this.state.planUsersMaxCumulative;
              let userCumulative = cumulatives[person.info_id];
              if (typeof userCumulative === 'undefined') {
                userCumulative = [];
                userCumulative.push({rooster_datum: date, info_id: person.info_id});
              } else {
                userCumulative.push({rooster_datum: date, info_id: person.info_id});
              }
              this.setState({ planUsersMaxCumulative: cumulatives });
              this.addWorkedDays(person.info_id); // add to worked days
              //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
              await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
              await this.putOpenShiftOnUnavailable(shift);
            }

            Constants.removeFromArray(personList, person);
            Constants.removeFromArray(shiftList, shift);
            
            await this.calculateBestPersonForShift(personList, shiftList, date, options);
            return;
          }

        }

      }
    }

    // SECONDARY FUNCTIONS

    loop10:
    for (const person of personList) {

      if (this.userDaysPlanned[parseInt(person.info_id)] >= options.maxDays) { // add to options max days TODO
        continue;
      }

      const _dateObj = Constants.stringToDate(date);
      const _dayKey = _dateObj.getDay() === 0 ? 'sun' : _dateObj.getDay() === 1 ? 'mon' : _dateObj.getDay() === 2 ? 'tue' : _dateObj.getDay() === 3 ? 'wed' : _dateObj.getDay() === 4 ? 'thu' : _dateObj.getDay() === 5 ? 'fri' : _dateObj.getDay() === 6 ? 'sat' : 'sun';
      for (const _plan of this.allPlannerData[_dayKey]) {
        if (_plan.rooster_datum === date && _plan.info_id === person.info_id) {
          continue loop10;
        }
      }

      const _wdKey = _dateObj.getDay() === 0 ? 'info2_werkdag_zondag' : _dateObj.getDay() === 1 ? 'info2_werkdag_maandag' : _dateObj.getDay() === 2 ? 'info2_werkdag_dinsdag' : _dateObj.getDay() === 3 ? 'info2_werkdag_woensdag' : _dateObj.getDay() === 4 ? 'info2_werkdag_donderdag' : _dateObj.getDay() === 5 ? 'info2_werkdag_vrijdag' : _dateObj.getDay() === 6 ? 'info2_werkdag_zaterdag' : 'info2_werkdag_zondag';
      if (this.state.infoExtra[person.info_id][_wdKey] === '0') {
        continue;
      }
      
      if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        const contract = Constants.getCurrentContract(this.state.contractsObj[person.info_id], Constants.stringToDate(date));
        if (contract === null) {
          continue;
        }
        // // has contract
        // if (typeof this.state.contractsObj[person.info_id] !== 'undefined') {
        //   const contract = this.state.contractsObj[person.info_id];
        //   // has valid end date
        //   if (Constants.isValidDateString(contract.contract_eind)) {
        //     const endDate = Constants.stringToDate(contract.contract_eind);
  
        //     if (Constants.formatDate(endDate) <= date) {
        //       continue;
        //     } 

        //   }
        // }
      }

      let index = 0;
      for (let shift of shiftList) {

        if (Constants.isEmpty(options.function) === false) {
          if (shift.functie_id !== options.function) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options);
            return;
          }
        }

        if (Constants.isEmpty(options.section) === false) {
          if (shift.functie_afdeling_id !== options.section) {
            Constants.removeFromArray(shiftList, shift);
            await this.calculateBestPersonForShift(personList, shiftList, date, options);
            return;
          }
        }

        if (typeof this.state.partDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
          Constants.removeFromArray(shiftList, shift);
          await this.calculateBestPersonForShift(personList, shiftList, date, options);
          return;
        }

        if(Data.data.appOptions.availability === 'AVAILABILITY') {

          // person not filled
          if(typeof person.beschikbaar_begin === 'undefined' && typeof person.beschikbaar_eind === 'undefined' && person.duration === 0) {

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          }

          // person is whole day available
          if(person.beschikbaar_begin === '00:00:00' && person.beschikbaar_eind === '00:00:00') {

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.beschikbaar_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.beschikbaar_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop10;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'STANDARD') {

          // person not filled
          if(typeof person.sb_begin === 'undefined' && typeof person.sb_eind === 'undefined' && person.duration === 0) {

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          }

          // person is whole day available
          if(person.sb_begin === '00:00:00' && person.sb_eind === '00:00:00') {

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.sb_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.sb_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              if(availStart === planStart && availEnd === planEnd && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) {
                
                shift.filled = true;
                shift.person = person;
                everythingFilled = false;

                shiftsToDelete.push({...shift});
                break loop10;
              }

              if(availStart <= planStart && availEnd >= planEnd) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration > person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if(Data.data.appOptions.availability === 'SCHOOL') {

          // person is whole day available
          if(person.duration === 99999999999) {

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              shift.rooster_begin = shift.dagdienst_begin;
              shift.rooster_eind = shift.dagdienst_eind;
              shift.rooster_pauze = shift.dagdienst_pauze;

              if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
                Constants.removeFromArray(personList, person);
                await this.calculateBestPersonForShift(personList, shiftList, date, options);
                return;
              }

              if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
                continue;
              }

              const added = await this.planUser(date, person, shift);
              if(added !== null) {

                // add comps from dayshift
                if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                  for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                    await APIAddSkillSchedule.Request(skill.comp_id, added);
                  }
                }

                // reload cumulatives
                let cumulatives = this.state.planUsersMaxCumulative;
                let userCumulative = cumulatives[person.info_id];
                if (typeof userCumulative === 'undefined') {
                  userCumulative = [];
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                } else {
                  userCumulative.push({rooster_datum: date, info_id: person.info_id});
                }
                this.setState({ planUsersMaxCumulative: cumulatives });
                this.addWorkedDays(person.info_id); // add to worked days
                //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
                await this.putOpenShiftOnUnavailable(shift);
              }

              Constants.removeFromArray(personList, person);
              Constants.removeFromArray(shiftList, shift);
              
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

          } else {

            const availStart = new Date(`${this.state.thursdayDate}T${person.schoolrooster_begin}`);
            const availEnd = new Date(`${this.state.thursdayDate}T${person.schoolrooster_eind}`);

            const planStart = new Date(`${this.state.thursdayDate}T${shift.dagdienst_begin}`);
            const planEnd = new Date(`${this.state.thursdayDate}T${shift.dagdienst_eind}`);

            if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

              if((planStart < availStart && planEnd < availStart) || (planStart > availEnd && planEnd > availEnd)) {

                if(shift.filled !== true) {

                  shift.filled = true;
                  shift.person = person;
                  everythingFilled = false;

                  shiftsToDelete.push({...shift});
                  break;

                } else {
                  
                  if(shift.person.duration < person.duration && typeof shiftList[index + 1] !== 'undefined' && shiftList[index + 1].functie_id === shift.functie_id) { // && function has more shifts in current function
                    
                    Constants.removeFromArray(shiftsToDelete, shift);
                    
                    shift.person = person;
                    everythingFilled = false;

                    shiftsToDelete.push({...shift});
                    break;

                  }
                  
                }

              }

            }

            index++;

          }

        }

        if (Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'NONE') {

          if(this.personHasSecundaryFunction(person, shift.functie_id) === true) {

            shift.rooster_begin = shift.dagdienst_begin;
            shift.rooster_eind = shift.dagdienst_eind;
            shift.rooster_pauze = shift.dagdienst_pauze;

            if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[person.info_id], person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
              Constants.removeFromArray(personList, person);
              await this.calculateBestPersonForShift(personList, shiftList, date, options);
              return;
            }

            if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[person.info_id]) === false) {
              continue;
            }

            const added = await this.planUser(date, person, shift);
            if(added !== null) {

              // add comps from dayshift
              if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
                for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
                  await APIAddSkillSchedule.Request(skill.comp_id, added);
                }
              }

              // reload cumulatives
              let cumulatives = this.state.planUsersMaxCumulative;
              let userCumulative = cumulatives[person.info_id];
              if (typeof userCumulative === 'undefined') {
                userCumulative = [];
                userCumulative.push({rooster_datum: date, info_id: person.info_id});
              } else {
                userCumulative.push({rooster_datum: date, info_id: person.info_id});
              }
              this.setState({ planUsersMaxCumulative: cumulatives });
              this.addWorkedDays(person.info_id); // add to worked days
              //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
              await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
              await this.putOpenShiftOnUnavailable(shift);
            }

            Constants.removeFromArray(personList, person);
            Constants.removeFromArray(shiftList, shift);
            
            await this.calculateBestPersonForShift(personList, shiftList, date, options);
            return;
          }

        }

      }
    }

    if(everythingFilled === false) {

      for(const shift of shiftsToDelete) {

        //  delete current added users and restart
        shift.rooster_begin = shift.dagdienst_begin;
        shift.rooster_eind = shift.dagdienst_eind;
        shift.rooster_pauze = shift.dagdienst_pauze;

        if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[shift.person.info_id], shift.person.info_id, date, shift, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          Constants.removeFromArray(personList, shift.person);
          await this.calculateBestPersonForShift(personList, shiftList, date, options);
          return;
        }

        if (Plan.userHasAllSkills(this.state.skillDayShifts['d' + shift.dagdienst_id], this.state.skillsUsers[shift.person.info_id]) === false) {
          continue;
        }

        const added = await this.planUser(date, shift.person, shift);
        if(added !== null) {

          // add comps from dayshift
          if (typeof this.state.skillDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
            for (const skill of this.state.skillDayShifts['d' + shift.dagdienst_id]) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
          }

          // reload cumulatives
          let cumulatives = this.state.planUsersMaxCumulative;
          let userCumulative = cumulatives[shift.person.info_id];
          if (typeof userCumulative === 'undefined') {
            userCumulative = [];
            userCumulative.push({rooster_datum: date, info_id: shift.person.info_id});
          } else {
            userCumulative.push({rooster_datum: date, info_id: shift.person.info_id});
          }
          this.setState({ planUsersMaxCumulative: cumulatives });
          this.addWorkedDays(shift.person.info_id); // add to worked days
          //await APIAddUsedDayShift.Request(date, shift.dagdienst_begin, shift.dagdienst_eind, added, shift.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(shift);
        }

        Constants.removeFromArray(personList, shift.person);
        Constants.removeFromArray(shiftList, shift);

      }

      await this.calculateBestPersonForShift(personList, shiftList, date, options);
      return;

    }

  }

    getBreakRule(startDate, startTime, endDate, endTime, schedule = null) {

        const start = Constants.stringToDateTime(startDate, startTime);
        const end = Constants.stringToDateTime(schedule !== null ? Plan.isNightShift2(schedule) : endDate, endTime);

        const totalMS = end.getTime() - start.getTime();
        const totalHours = totalMS / 1000 / 60 / 60;

        let brk = 0;
        for (const br of this.breakRules) {

            if (totalHours >= parseFloat(br.pr_uren)) {
            brk = parseInt(br.pr_pauze);
            }

        }

        if (brk > 0) {
            brk = brk * 60 * 1000;
        }

        return {
            ms: brk,
            str: Constants.msToTime(brk),
        };

    }

    async getIncorrectCorrectTimesPublishModal(dateString, dayKey, planStart, planEnd, from) {

      if (dayKey === null) {
          const dateObject = Constants.stringToDate(dateString);
          const day = dateObject.getDay().toString();
          dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';
      }

      // AVAILABILITY
      if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

          // UNAVAILABLE
          const _unav = await APIGET.Request(`SELECT *`, `FROM Onbeschikbaar`, null, `WHERE onbeschikbaar_datum = '${dateString}' AND onbeschikbaar_info_id = ${from.info_id}`, null, null);
          const unav = _unav.length > 0 ? _unav[0] : null;
          if (unav !== null) {

              if(unav.onbeschikbaar_begin === '00:00:00' && unav.onbeschikbaar_eind === '00:00:00') {
                  return true;
              }

              const availStart = Constants.stringToDateTime(dateString, unav.onbeschikbaar_begin);
              const availEnd = Constants.stringToDateTime(dateString, unav.onbeschikbaar_eind);

              if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                  return true;
              }
              if(planStart >= availStart && planStart <= availEnd) {
                  return true;
              }
              if(planEnd >= availStart && planEnd <= availEnd) {
                  return true;
              }
              if(planStart <= availStart && planEnd >= availEnd) {
                  return true;
              }

          }

          // AVAILABLE
          const _av = await APIGET.Request(`SELECT *`, `FROM Beschikbaar`, null, `WHERE beschikbaar_datum = '${dateString}' AND beschikbaar_info_id = ${from.info_id}`, null, null);
          const av = _av.length > 0 ? _av[0] : null;
          if (av !== null) {

              if(av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00') {
                  return false;
              }

              const availStart = Constants.stringToDateTime(dateString, av.beschikbaar_begin);
              const availEnd = Constants.stringToDateTime(dateString, av.beschikbaar_eind);

              if(availStart > planStart) {
                  return true;
              }
              if(availEnd < planStart) {
                  return true;
              }
              if(availEnd < planEnd) {
                  return true;
              }
              if(availStart > planEnd) {
                  return true;
              }

              return false;

          }

      }

      // STANDARD AVAILABILITY
      else if (Data.data.appOptions.availability === 'STANDARD') {

          // AVAILABLE
          const av = Constants.getArrayItem(this._allStandardAvailableData[dayKey], 'info_id', from.info_id);
          if (av !== null && av.sb_beschikbaar === '1') {

              if(av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00') {
                  return false;
              }

              const availStart = Constants.stringToDateTime(dateString, av.sb_begin);
              const availEnd = Constants.stringToDateTime(dateString, av.sb_eind);

              if(availStart > planStart) {
                  return true;
              }
              if(availEnd < planStart) {
                  return true;
              }
              if(availEnd < planEnd) {
                  return true;
              }
              if(availStart > planEnd) {
                  return true;
              }

              return false;

          }

          // UNAVAILABLE
          const unav = Constants.getArrayItem(this._allStandardUnavailableData[dayKey], 'info_id', from.info_id);
          if (unav !== null && unav.sb_beschikbaar === '2') {

              if(unav.sb_begin === '00:00:00' && unav.sb_eind === '00:00:00') {
                  return true;
              }

              const availStart = Constants.stringToDateTime(dateString, unav.sb_begin);
              const availEnd = Constants.stringToDateTime(dateString, unav.sb_eind);

              if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                  return true;
              }
              if(planStart >= availStart && planStart <= availEnd) {
                  return true;
              }
              if(planEnd >= availStart && planEnd <= availEnd) {
                  return true;
              }
              if(planStart <= availStart && planEnd >= availEnd) {
                  return true;
              }

          }

      }

      // AVAILABILITY SCHOOL
      else if (Data.data.appOptions.availability === 'SCHOOL') {

          if (typeof this.state.multipleAvail[dayKey] !== 'undefined' && typeof this.state.multipleAvail[dayKey][from.info_id] !== 'undefined') {

              const multiAvail = this.state.multipleAvail[dayKey][from.info_id];

              const _unav = await APIGET.Request(`SELECT *`, `FROM Onbeschikbaar`, null, `WHERE onbeschikbaar_datum = '${dateString}' AND onbeschikbaar_info_id = ${from.info_id}`, null, null);
              const unav = _unav.length > 0 ? _unav[0] : null;

              const _av = await APIGET.Request(`SELECT *`, `FROM Beschikbaar`, null, `WHERE beschikbaar_datum = '${dateString}' AND beschikbaar_info_id = ${from.info_id}`, null, null);
              const av = _av.length > 0 ? _av[0] : null;

              // UNAVAILABLE
              if (unav !== null) {

                  if (unav.onbeschikbaar_begin === '00:00:00' && unav.onbeschikbaar_eind === '00:00:00') {

                      return true;
                    
                  } else {

                      const availStart = Constants.stringToDateTime(dateString, unav.onbeschikbaar_begin);
                      const availEnd = Constants.stringToDateTime(dateString, unav.onbeschikbaar_eind);

                      if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                          return true;
                      }
                      if(planStart >= availStart && planStart <= availEnd) {
                          return true;
                      }
                      if(planEnd >= availStart && planEnd <= availEnd) {
                          return true;
                      }
                      if(planStart <= availStart && planEnd >= availEnd) {
                          return true;
                      }

                  }

              }

              // AVAILABLE
              if (av !== null) {

                  if (av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00') {

                      return false;

                  } else {

                      const availStart = Constants.stringToDateTime(dateString, av.beschikbaar_begin);
                      const availEnd = Constants.stringToDateTime(dateString, av.beschikbaar_eind);

                      if(availStart > planStart) {
                          return true;
                      }
                      if(availEnd < planStart) {
                          return true;
                      }
                      if(availEnd < planEnd) {
                          return true;
                      }
                      if(availStart > planEnd) {
                          return true;
                      }

                      return false;

                  }

              }

              // SCHOOL
              if (multiAvail.school !== null) {

                  const availStart = Constants.stringToDateTime(dateString, multiAvail.school.schoolrooster_begin);
                  const availEnd = Constants.stringToDateTime(dateString, multiAvail.school.schoolrooster_eind);

                  if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                      return true;
                  } else if(planStart >= availStart && planStart <= availEnd) {
                      return true;
                  } else if(planEnd >= availStart && planEnd <= availEnd) {
                      return true;
                  } else if(planStart <= availStart && planEnd >= availEnd) {
                      return true;
                  }
              }

          }

      }

      return false;

    }

    getIncorrectCorrectTimesDirectly(dateString, dayKey, start, end, from) {

        if (dayKey === null) {
            const dateObject = Constants.stringToDate(dateString);
            const day = dateObject.getDay().toString();
            dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';
        }

        const planStart = Constants.stringToDateTime(dateString, start);
        const planEnd= Constants.stringToDateTime(dateString, end);
    
        // AVAILABILITY
        if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
    
            // UNAVAILABLE
            const unav = Constants.getArrayItem(this._allUnavailableData[dayKey], 'info_id', from.info_id);
            if (unav !== null) {
    
                if(unav.onbeschikbaar_begin === '00:00:00' && unav.onbeschikbaar_eind === '00:00:00') {
                    return true;
                }
    
                const availStart = Constants.stringToDateTime(dateString, unav.onbeschikbaar_begin);
                const availEnd = Constants.stringToDateTime(dateString, unav.onbeschikbaar_eind);
    
                if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                    return true;
                }
                if(planStart >= availStart && planStart <= availEnd) {
                    return true;
                }
                if(planEnd >= availStart && planEnd <= availEnd) {
                    return true;
                }
                if(planStart <= availStart && planEnd >= availEnd) {
                    return true;
                }
    
            }
    
            // AVAILABLE
            const av = Constants.getArrayItem(this._allAvailableData[dayKey], 'info_id', from.info_id);
            if (av !== null) {
    
                if(av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00') {
                    return false;
                }
    
                const availStart = Constants.stringToDateTime(dateString, av.beschikbaar_begin);
                const availEnd = Constants.stringToDateTime(dateString, av.beschikbaar_eind);
    
                if(availStart > planStart) {
                    return true;
                }
                if(availEnd < planStart) {
                    return true;
                }
                if(availEnd < planEnd) {
                    return true;
                }
                if(availStart > planEnd) {
                    return true;
                }
    
                return false;
    
            }
    
        }
    
        // AVAILABILITY
        else if (Data.data.appOptions.availability === 'STANDARD') {
    
            // AVAILABLE
            const av = Constants.getArrayItem(this._allStandardAvailableData[dayKey], 'info_id', from.info_id);
            if (av !== null && av.sb_beschikbaar === '1') {
    
                if(av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00') {
                    return false;
                }
    
                const availStart = Constants.stringToDateTime(dateString, av.sb_begin);
                const availEnd = Constants.stringToDateTime(dateString, av.sb_eind);
    
                if(availStart > planStart) {
                    return true;
                }
                if(availEnd < planStart) {
                    return true;
                }
                if(availEnd < planEnd) {
                    return true;
                }
                if(availStart > planEnd) {
                    return true;
                }
    
                return false;
    
            }
    
            // UNAVAILABLE
            const unav = Constants.getArrayItem(this._allStandardUnavailableData[dayKey], 'info_id', from.info_id);
            if (unav !== null && unav.sb_beschikbaar === '2') {
    
                if(unav.sb_begin === '00:00:00' && unav.sb_eind === '00:00:00') {
                    return true;
                }
    
                const availStart = Constants.stringToDateTime(dateString, unav.sb_begin);
                const availEnd = Constants.stringToDateTime(dateString, unav.sb_eind);
    
                if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                    return true;
                }
                if(planStart >= availStart && planStart <= availEnd) {
                    return true;
                }
                if(planEnd >= availStart && planEnd <= availEnd) {
                    return true;
                }
                if(planStart <= availStart && planEnd >= availEnd) {
                    return true;
                }
    
            }
    
        }
    
        // AVAILABILITY SCHOOL
        else if (Data.data.appOptions.availability === 'SCHOOL') {
    
            if (typeof this.state.multipleAvail[dayKey] !== 'undefined' && typeof this.state.multipleAvail[dayKey][from.info_id] !== 'undefined') {
    
                const multiAvail = this.state.multipleAvail[dayKey][from.info_id];

                // UNAVAILABLE
                if (multiAvail.unav !== null) {
    
                    if (multiAvail.unav.onbeschikbaar_begin === '00:00:00' && multiAvail.unav.onbeschikbaar_eind === '00:00:00') {
    
                        return true;
                        
                    } else {
    
                        const availStart = Constants.stringToDateTime(dateString, multiAvail.unav.onbeschikbaar_begin);
                        const availEnd = Constants.stringToDateTime(dateString, multiAvail.unav.onbeschikbaar_eind);
    
                        if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                            return true;
                        }
                        if(planStart >= availStart && planStart <= availEnd) {
                            return true;
                        }
                        if(planEnd >= availStart && planEnd <= availEnd) {
                            return true;
                        }
                        if(planStart <= availStart && planEnd >= availEnd) {
                            return true;
                        }
    
                    }
    
                }
    
                // AVAILABLE
                if (multiAvail.av !== null) {
    
                    if (multiAvail.av.beschikbaar_begin === '00:00:00' && multiAvail.av.beschikbaar_eind === '00:00:00') {
    
                        return false;
    
                    } else {
    
                        const availStart = Constants.stringToDateTime(dateString, multiAvail.av.beschikbaar_begin);
                        const availEnd = Constants.stringToDateTime(dateString, multiAvail.av.beschikbaar_eind);
    
                        if(availStart > planStart) {
                            return true;
                        }
                        if(availEnd < planStart) {
                            return true;
                        }
                        if(availEnd < planEnd) {
                            return true;
                        }
                        if(availStart > planEnd) {
                            return true;
                        }
    
                        return false;
    
                    }
    
                }
    
                // SCHOOL
                if (multiAvail.school !== null) {
    
                    const availStart = Constants.stringToDateTime(dateString, multiAvail.school.schoolrooster_begin);
                    const availEnd = Constants.stringToDateTime(dateString, multiAvail.school.schoolrooster_eind);
    
                    if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                        return true;
                    } else if(planStart >= availStart && planStart <= availEnd) {
                        return true;
                    } else if(planEnd >= availStart && planEnd <= availEnd) {
                        return true;
                    } else if(planStart <= availStart && planEnd >= availEnd) {
                        return true;
                    }
                }
    
            }
    
        }
    
        return false;
    
      }

  getIncorrectCorrectTimes(dateString, dayKey, planStart, planEnd, from) {

    if (dayKey === null) {
        const dateObject = Constants.stringToDate(dateString);
        const day = dateObject.getDay().toString();
        dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';
    }

    // AVAILABILITY
    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

        // UNAVAILABLE
        const unav = Constants.getArrayItem(this._allUnavailableData[dayKey], 'info_id', from.info_id);
        if (unav !== null) {

            if(unav.onbeschikbaar_begin === '00:00:00' && unav.onbeschikbaar_eind === '00:00:00') {
                return true;
            }

            const availStart = Constants.stringToDateTime(dateString, unav.onbeschikbaar_begin);
            const availEnd = Constants.stringToDateTime(dateString, unav.onbeschikbaar_eind);

            if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                return true;
            }
            if(planStart >= availStart && planStart <= availEnd) {
                return true;
            }
            if(planEnd >= availStart && planEnd <= availEnd) {
                return true;
            }
            if(planStart <= availStart && planEnd >= availEnd) {
                return true;
            }

        }

        // AVAILABLE
        const av = Constants.getArrayItem(this._allAvailableData[dayKey], 'info_id', from.info_id);
        if (av !== null) {

            if(av.beschikbaar_begin === '00:00:00' && av.beschikbaar_eind === '00:00:00') {
                return false;
            }

            const availStart = Constants.stringToDateTime(dateString, av.beschikbaar_begin);
            const availEnd = Constants.stringToDateTime(dateString, av.beschikbaar_eind);

            if(availStart > planStart) {
                return true;
            }
            if(availEnd < planStart) {
                return true;
            }
            if(availEnd < planEnd) {
                return true;
            }
            if(availStart > planEnd) {
                return true;
            }

            return false;

        }

    }

    // STANDARD AVAILABILITY
    else if (Data.data.appOptions.availability === 'STANDARD') {

        // AVAILABLE
        const av = Constants.getArrayItem(this._allStandardAvailableData[dayKey], 'info_id', from.info_id);
        if (av !== null && av.sb_beschikbaar === '1') {

            if(av.sb_begin === '00:00:00' && av.sb_eind === '00:00:00') {
                return false;
            }

            const availStart = Constants.stringToDateTime(dateString, av.sb_begin);
            const availEnd = Constants.stringToDateTime(dateString, av.sb_eind);

            if(availStart > planStart) {
                return true;
            }
            if(availEnd < planStart) {
                return true;
            }
            if(availEnd < planEnd) {
                return true;
            }
            if(availStart > planEnd) {
                return true;
            }

            return false;

        }

        // UNAVAILABLE
        const unav = Constants.getArrayItem(this._allStandardUnavailableData[dayKey], 'info_id', from.info_id);
        if (unav !== null && unav.sb_beschikbaar === '2') {

            if(unav.sb_begin === '00:00:00' && unav.sb_eind === '00:00:00') {
                return true;
            }

            const availStart = Constants.stringToDateTime(dateString, unav.sb_begin);
            const availEnd = Constants.stringToDateTime(dateString, unav.sb_eind);

            if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                return true;
            }
            if(planStart >= availStart && planStart <= availEnd) {
                return true;
            }
            if(planEnd >= availStart && planEnd <= availEnd) {
                return true;
            }
            if(planStart <= availStart && planEnd >= availEnd) {
                return true;
            }

        }

    }

    // AVAILABILITY SCHOOL
    else if (Data.data.appOptions.availability === 'SCHOOL') {

        if (typeof this.state.multipleAvail[dayKey] !== 'undefined' && typeof this.state.multipleAvail[dayKey][from.info_id] !== 'undefined') {

            const multiAvail = this.state.multipleAvail[dayKey][from.info_id];

            // UNAVAILABLE
            if (multiAvail.unav !== null) {

                if (multiAvail.unav.onbeschikbaar_begin === '00:00:00' && multiAvail.unav.onbeschikbaar_eind === '00:00:00') {

                    return true;
                    
                } else {

                    const availStart = Constants.stringToDateTime(dateString, multiAvail.unav.onbeschikbaar_begin);
                    const availEnd = Constants.stringToDateTime(dateString, multiAvail.unav.onbeschikbaar_eind);

                    if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                        return true;
                    }
                    if(planStart >= availStart && planStart <= availEnd) {
                        return true;
                    }
                    if(planEnd >= availStart && planEnd <= availEnd) {
                        return true;
                    }
                    if(planStart <= availStart && planEnd >= availEnd) {
                        return true;
                    }

                }

            }

            // AVAILABLE
            if (multiAvail.av !== null) {

                if (multiAvail.av.beschikbaar_begin === '00:00:00' && multiAvail.av.beschikbaar_eind === '00:00:00') {

                    return false;

                } else {

                    const availStart = Constants.stringToDateTime(dateString, multiAvail.av.beschikbaar_begin);
                    const availEnd = Constants.stringToDateTime(dateString, multiAvail.av.beschikbaar_eind);

                    if(availStart > planStart) {
                        return true;
                    }
                    if(availEnd < planStart) {
                        return true;
                    }
                    if(availEnd < planEnd) {
                        return true;
                    }
                    if(availStart > planEnd) {
                        return true;
                    }

                    return false;

                }

            }

            // SCHOOL
            if (multiAvail.school !== null) {

                const availStart = Constants.stringToDateTime(dateString, multiAvail.school.schoolrooster_begin);
                const availEnd = Constants.stringToDateTime(dateString, multiAvail.school.schoolrooster_eind);

                if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                    return true;
                } else if(planStart >= availStart && planStart <= availEnd) {
                    return true;
                } else if(planEnd >= availStart && planEnd <= availEnd) {
                    return true;
                } else if(planStart <= availStart && planEnd >= availEnd) {
                    return true;
                }
            }

        }

    }

    return false;

  }

  async planUser(date, from, to) {

    // from always contains an info_id
    // to can either be planObject, shiftObject, availabilityObject

    const dateObject = Constants.stringToDate(date);
    const dateString = Constants.dateToString(dateObject);
    const day = dateObject.getDay().toString();
    const dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';

    const planStart = Constants.stringToDateTime(dateString, typeof to.rooster_begin !== 'undefined' ? to.rooster_begin : to.dagdienst_begin);
    const planEnd = Constants.stringToDateTime(dateString, typeof to.rooster_eind !== 'undefined' ? to.rooster_eind : to.dagdienst_eind);

    const incorrectTimes = this.getIncorrectCorrectTimes(dateString, dayKey, planStart, planEnd, from);

    let _break = to.rooster_pauze.substring(0,5);
    if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(to.rooster_datum) === false) {
        _break = this.getBreakRule(to.rooster_datum, to.rooster_begin, to.rooster_datum, to.rooster_eind, to);
        _break = _break.str.substr(0, 5);
    }

    await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${to.rooster_begin.substring(0,5)}', '${to.rooster_eind.substring(0,5)}', ${from.info_id}, ${to.functie_id === null ? 'NULL' : to.functie_id}, 0, '${_break}', ${incorrectTimes === true ? 1 : 0})`);
    const added = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, parseInt(added[0].rooster_id));
    }

    return parseInt(added[0].rooster_id);

  }

  async planUserPublishModal(date, from, to) {

    // from always contains an info_id
    // to can either be planObject, shiftObject, availabilityObject

    const dateObject = Constants.stringToDate(date);
    const dateString = Constants.dateToString(dateObject);
    const day = dateObject.getDay().toString();
    const dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';

    const planStart = Constants.stringToDateTime(dateString, typeof to.rooster_begin !== 'undefined' ? to.rooster_begin : to.dagdienst_begin);
    const planEnd = Constants.stringToDateTime(dateString, typeof to.rooster_eind !== 'undefined' ? to.rooster_eind : to.dagdienst_eind);

    const incorrectTimes = await this.getIncorrectCorrectTimesPublishModal(dateString, dayKey, planStart, planEnd, from);

    let _break = to.rooster_pauze.substring(0,5);
    if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(to.rooster_datum) === false) {
        _break = this.getBreakRule(to.rooster_datum, to.rooster_begin, to.rooster_datum, to.rooster_eind, to);
        _break = _break.str.substr(0, 5);
    }

    await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${to.rooster_begin.substring(0,5)}', '${to.rooster_eind.substring(0,5)}', ${from.info_id}, ${to.functie_id === null ? 'NULL' : to.functie_id}, 1, '${_break}', ${incorrectTimes === true ? 1 : 0})`);
    const added = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

    if (Constants.isEmpty(to.hr_team_id) === false) {
      await APIAddToTeamPlanner.Request(to.hr_team_id, parseInt(added[0].rooster_id));
    }

    return parseInt(added[0].rooster_id);

  }

  async editUserDirectly(start, end, planData) {

    if (start === planData.rooster_begin.substring(0, 5) && end === planData.rooster_eind.substring(0, 5)) {
        return true;
    }

    const dateObject = Constants.stringToDate(planData.rooster_datum);
    const dateString = Constants.dateToString(dateObject);
    const day = dateObject.getDay().toString();
    const dayKey = day === '0' ? 'sun' : day === '1' ? 'mon' : day === '2' ? 'tue' : day === '3' ? 'wed' : day === '4' ? 'thu' : day === '5' ? 'fri' : day === '6' ? 'sat' : 'mon';

    let planStart = start;
    let planEnd = end;

    if (typeof start === 'string') {
        planStart = Constants.stringToDateTime(dateString, start);
        planEnd = Constants.stringToDateTime(dateString, end);
    }

    const incorrectTimes = this.getIncorrectCorrectTimes(dateString, dayKey, planStart, planEnd, planData);

    let _break = planData.rooster_pauze.substring(0,5);
    if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(planData.rooster_datum) === false) {
        _break = this.getBreakRule(planData.rooster_datum, start, Plan.isNightShift2(planData), end);
        _break = _break.str.substr(0, 5);
    }

    const added = await APIEditUserPlanner.Request(
        start,
        end,
        planData.functie_id,
        Constants.isEmpty(planData.info_id) ? 'NULL' : planData.info_id,
        planData.rooster_id,
        _break,
        incorrectTimes === true ? 1 : 0,
    );

    if (added === true) {

        if(planData.rooster_publiceren === '1') {

            const dateObj = Constants.stringToDate(planData.rooster_datum);

            KodictNotifications.sendPushNotification(
              planData.info_id,
              'planner',
              'Planner',
              `Jouw rooster is gewijzigd van ${dateObj.toLocaleDateString()} ${planData.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${planData.rooster_eind.substr(0, 5)}` : ``} naar ${dateObj.toLocaleDateString()} ${start}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${end}` : ``}. Tik hier om jouw roostermeldingen te updaten`
            );
        }

        Logger.editPlannerDirectly(planData, start, end);

    }

    return added;

  }

  onClickSchoolHoliday(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.schoolHolidayData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.schoolHolidayData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.schoolHolidayData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.schoolHolidayData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.schoolHolidayData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.schoolHolidayData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.schoolHolidayData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickSchoolSchedule(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.schoolScheduleData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.schoolScheduleData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.schoolScheduleData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.schoolScheduleData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.schoolScheduleData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.schoolScheduleData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.schoolScheduleData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickStandardAvailable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.standardAvailableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.standardAvailableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.standardAvailableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.standardAvailableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.standardAvailableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.standardAvailableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.standardAvailableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickStandardUnavailable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.standardUnavailableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.standardUnavailableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.standardUnavailableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.standardUnavailableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.standardUnavailableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.standardUnavailableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.standardUnavailableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickStandardNotFilled(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.standardNotFilledData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.standardNotFilledData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.standardNotFilledData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.standardNotFilledData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.standardNotFilledData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.standardNotFilledData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.standardNotFilledData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickAvailable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.availableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.availableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.availableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.availableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.availableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.availableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.availableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickNotFilledSchool(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.notFilledSchool.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.notFilledSchool.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.notFilledSchool.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.notFilledSchool.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.notFilledSchool.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.notFilledSchool.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.notFilledSchool.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickNotFilled(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.notFilledData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.notFilledData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.notFilledData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.notFilledData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.notFilledData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.notFilledData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.notFilledData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickUnavailable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.unavailableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.unavailableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.unavailableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.unavailableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.unavailableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.unavailableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.unavailableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickHoliday(i, data, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  onClickNoAvailability(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    const day = i === 6 ? 0 : i + 1;

    let data = [];

    let date = null;

    if(day === 0) {
      data = this.usersNoAvailability.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.usersNoAvailability.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.usersNoAvailability.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.usersNoAvailability.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.usersNoAvailability.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.usersNoAvailability.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.usersNoAvailability.sat[key];
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL' || this.state.display === 'TABLE') {

      this.setState({
        modalData: data,
        modalMode: 0,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //fullscreen //2
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === data.info_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          selectedBar: data.info_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  async onClickSchoolHolidayTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.schoolHolidayData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.schoolHolidayData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.schoolHolidayData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.schoolHolidayData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.schoolHolidayData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.schoolHolidayData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.schoolHolidayData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'sh'+data.info_id+i) {
        this.timeRef['sh'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'sh'+data.info_id+i,
        }, () => { this.timeRef['sh'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);

          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
        } else {
          //  //  //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        chosenUserSecondaryFunction: 'sh'+data.info_id+i,
        editPlan: true,
      }, () => { this.timeRef['sh'+data.info_id+i].focus(); });

    }

  }

  async onClickSchoolScheduleTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.schoolScheduleData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.schoolScheduleData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.schoolScheduleData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.schoolScheduleData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.schoolScheduleData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.schoolScheduleData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.schoolScheduleData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 's'+data.info_id+i) {
        this.timeRef['s'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 's'+data.info_id+i,
        }, () => { this.timeRef['s'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 's'+data.info_id+i,
      }, () => { this.timeRef['s'+data.info_id+i].focus(); });

    }

  }

  async onClickStandardAvailableTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.standardAvailableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.standardAvailableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.standardAvailableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.standardAvailableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.standardAvailableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.standardAvailableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.standardAvailableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'sta'+data.info_id+i) {
        this.timeRef['sta'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'sta'+data.info_id+i,
        }, () => { this.timeRef['sta'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'sta'+data.info_id+i,
      }, () => { 
        this.timeRef['sta'+data.info_id+i].focus(); 
      });

    }

  }

  async onClickStandardUnavailableTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.standardUnavailableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.standardUnavailableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.standardUnavailableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.standardUnavailableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.standardUnavailableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.standardUnavailableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.standardUnavailableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'stu'+data.info_id+i) {
        this.timeRef['stu'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'stu'+data.info_id+i,
        }, () => { this.timeRef['stu'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'stu'+data.info_id+i,
      }, () => { this.timeRef['stu'+data.info_id+i].focus(); });

    }

  }

  async onClickStandardNotFilledTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.standardNotFilledData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.standardNotFilledData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.standardNotFilledData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.standardNotFilledData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.standardNotFilledData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.standardNotFilledData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.standardNotFilledData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'stn'+data.info_id+i) {
        this.timeRef['stn'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'stn'+data.info_id+i,
        }, () => { this.timeRef['stn'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'stn'+data.info_id+i,
      }, () => { this.timeRef['stn'+data.info_id+i].focus(); });

    }

  }

  async onClickAvailableTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.availableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.availableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.availableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.availableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.availableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.availableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.availableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'a'+data.info_id+i) {
        this.timeRef['a'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'a'+data.info_id+i,
        }, () => { this.timeRef['a'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'a'+data.info_id+i,
      }, () => { this.timeRef['a'+data.info_id+i].focus(); });

    }

  }

  async onClickNotFilledSchoolTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.notFilledSchool.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.notFilledSchool.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.notFilledSchool.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.notFilledSchool.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.notFilledSchool.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.notFilledSchool.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.notFilledSchool.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'sn'+data.info_id+i) {
        this.timeRef['sn'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'sn'+data.info_id+i,
        }, () => { this.timeRef['sn'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'sn'+data.info_id+i,
      }, () => { this.timeRef['sn'+data.info_id+i].focus(); });

    }

  }

  async onClickNotFilledTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.notFilledData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.notFilledData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.notFilledData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.notFilledData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.notFilledData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.notFilledData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.notFilledData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'n'+data.info_id+i) {
        this.timeRef['n'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'n'+data.info_id+i,
        }, () => { this.timeRef['n'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'n'+data.info_id+i,
      }, () => { this.timeRef['n'+data.info_id+i].focus(); });

    }

  }

  async onClickUnavailableTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.unavailableData.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.unavailableData.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.unavailableData.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.unavailableData.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.unavailableData.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.unavailableData.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.unavailableData.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'u'+data.info_id+i) {
        this.timeRef['u'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'u'+data.info_id+i,
        }, () => { this.timeRef['u'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }
          
          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'u'+data.info_id+i,
      }, () => { this.timeRef['u'+data.info_id+i].focus(); });

    }

  }

  async onClickNoAvailabilityTable(i, key, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = [];

    if(day === 0) {
      data = this.usersNoAvailability.sun[key];
      date = this.state.sundayDate;
    }
    if(day === 1) {
      data = this.usersNoAvailability.mon[key];
      date = this.state.mondayDate;
    }
    if(day === 2) {
      data = this.usersNoAvailability.tue[key];
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      data = this.usersNoAvailability.wed[key];
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      data = this.usersNoAvailability.thu[key];
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      data = this.usersNoAvailability.fri[key];
      date = this.state.fridayDate;
    }
    if(day === 6) {
      data = this.usersNoAvailability.sat[key];
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'na'+data.info_id+i) {
        this.timeRef['na'+data.info_id+i].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'na'+data.info_id+i,
        }, () => { this.timeRef['na'+data.info_id+i].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }
          
          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'na'+data.info_id+i,
      }, () => { this.timeRef['na'+data.info_id+i].focus(); });

    }

  }

  async onClickHolidayTable(i, data, e, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.fromPlanner.length === 0 && this.state.selectedBar === '' && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      this.setState({editPlan: false});
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'fh'+data.info_id+i+key) {
        this.timeRef['fh'+data.info_id+i+key].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'fh'+data.info_id+i+key,
        }, () => { this.timeRef['fh'+data.info_id+i+key].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, data, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }
          
          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          Logger.addPlannerClick(data, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

      // editPlan directly
      const primaryFunction = {
        functie_id: data.functie_id,
        functie_kleur: data.functie_kleur,
        functie_naam: data.functie_naam,
      };

      const allFunctionsIter = typeof this.state.secondaryFunctions[data.info_id] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
      let allFunctions = [];
      for (const x of allFunctionsIter) { 
        allFunctions.push(x); 
      }
      if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
        allFunctions.push(primaryFunction);
      }
      allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

      this.setState({
        secondaryFunctionColor: `#${data.functie_kleur}`,
        secondaryFunctionID: data.functie_id,
        secondaryFunctionName: data.functie_naam,
        functionsUser: allFunctions,
        editPlan: true,
        chosenUserSecondaryFunction: 'fh'+data.info_id+i+key,
      }, () => { this.timeRef['fh'+data.info_id+i+key].focus(); });

    }

  }

  async onClickEmptyShiftTable(i, key, fid, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = {
      info_id: null,

    };

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      this.timeRef['fes'+key+i+fid].focus();
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {// to empty nulled touchbox

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);

        const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${this.fromPlanner.rooster_begin}', '${this.fromPlanner.rooster_eind}', NULL, ${fid}, 3, '${this.fromPlanner.rooster_pauze}', 0)`);

        const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
          await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
        }

        const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
        if (typeof skillSchedule !== 'undefined') {
          for (const skill of skillSchedule) {
            await APIAddSkillSchedule.Request(skill.comp_id, rid[0].rooster_id);
          }
        }

        if (typeof skillSchedule !== 'undefined') {
          const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          this.setState({ skillSchedules: skillSchedulesObj });
        }

        // remove and add part to new rooster_ID
        const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
        if (typeof partSchedule !== 'undefined') {
          await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          await APIAddPartSchedule.Request(partSchedule.bijz_id, rid[0].rooster_id);
          const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          this.setState({ partSchedules: partSchedulesObj });
        }

        if(added === true) {

          await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }
          if (dayShiftID !== null) {
            await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${rid[0].rooster_id})`);
          }

          //Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.fromPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
          
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        return;

      }

    } else { // nothing selected

      // editPlan directly
      this.setState({
        editPlan: true,
      }, () => {
        this.timeRef['fes'+key+i+fid].focus();
      });

    }

  }

  getAvailabilityData(day, userID, reverse) {

    let data = null;
    let dayAv = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';
    if (reverse === true) {
      dayAv = day === 6 ? 'sun' : day === 0 ? 'mon' : day === 1 ? 'tue' : day === 2 ? 'wed' : day === 3 ? 'thu' : day === 4 ? 'fri' : day === 5 ? 'sat' : 'mon';
    }

    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      let foundData = null;

      for (const srch of this.unavailableData[dayAv]) {
        if (srch.info_id === userID) {
          foundData = srch;
          break;
        }
      }

      if (foundData === null) {
        for (const srch of this.availableData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      if (foundData === null) {
        for (const srch of this.notFilledData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      data = foundData;

    } else if (Data.data.appOptions.availability === 'STANDARD') {

      let foundData = null;

      for (const srch of this.standardAvailableData[dayAv]) {
        if (srch.info_id === userID) {
          foundData = srch;
          break;
        }
      }

      if (foundData === null) {
        for (const srch of this.standardUnavailableData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      if (foundData === null) {
        for (const srch of this.standardNotFilledData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      data = foundData;

    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      let foundData = null;

      for (const srch of this.unavailableData[dayAv]) {
        if (srch.info_id === userID) {
          foundData = srch;
          break;
        }
      }

      if (foundData === null) {
        for (const srch of this.availableData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      if (foundData === null) {
        for (const srch of this.schoolHolidayData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      if (foundData === null) {
        for (const srch of this.schoolScheduleData[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      if (foundData === null) {
        for (const srch of this.notFilledSchool[dayAv]) {
          if (srch.info_id === userID) {
            foundData = srch;
            break;
          }
        }
      }

      data = foundData;

    // } else if (Data.data.appOptions.availability === 'UNAVAILABILITY') {

    //   let foundData = null;

    //   for (const srch of this.unavailableData[dayAv]) {
    //     if (srch.info_id === userID) {
    //       foundData = srch;
    //       break;
    //     }
    //   }

    //   if (foundData === null) {
    //     for (const srch of this.notFilledData[dayAv]) {
    //       if (srch.info_id === userID) {
    //         foundData = srch;
    //         break;
    //       }
    //     }
    //   }

    //   data = foundData;

    } else if (Data.data.appOptions.availability === 'NONE') {

      let foundData = null;

      for (const srch of this.usersNoAvailability[dayAv]) {
        if (srch.info_id === userID) {
          foundData = srch;
          break;
        }
      }

      data = foundData;

    }

    return data;

  }

  async onClickEmptyShiftUserTable(i, key, fid, e, userID) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    let data = this.getAvailabilityData(i, userID, true);

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    if (this.state.editPlan === true) { //  && this.fromPlanner.length === 0 && this.state.selectedBar === ''
      if ((typeof e !== 'undefined' && e.target.className.includes('planFunctionSelection') === true) || this.state.chosenUserSecondaryFunction === 'fes'+key+i+fid+userID) {
        this.timeRef['fes'+key+i+fid+userID].focus();
      } else {
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[userID] !== 'undefined' ? this.state.secondaryFunctions[data.info_id] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          chosenUserSecondaryFunction: 'fes'+key+i+fid+userID,
        }, () => { this.timeRef['fes'+key+i+fid+userID].focus(); });
      }
      return;
    }

    if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') { // has selected shift or dayshift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) {// to empty nulled touchbox

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);

        const added = await this.planUser(date, data, this.fromPlanner);

        if(added !== null) {

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
          }

          if (typeof skillSchedule !== 'undefined') {
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj });
          }

          // remove and add part to new rooster_ID
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          if (typeof partSchedule !== 'undefined') {
            await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
            await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj });
          }

          //await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }
          if (dayShiftID !== null) {
            await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          }

          //Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.fromPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
          
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

        //return;

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[data.info_id], data.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.functie_naam
        }

        const added = await this.planUser(date, data, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(data, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

    } else { // nothing selected

        // editPlan directly
        const primaryFunction = {
          functie_id: data.functie_id,
          functie_kleur: data.functie_kleur,
          functie_naam: data.functie_naam,
        };

        const allFunctionsIter = typeof this.state.secondaryFunctions[userID] !== 'undefined' ? this.state.secondaryFunctions[userID] : [];
        let allFunctions = [];
        for (const x of allFunctionsIter) { 
          allFunctions.push(x); 
        }
        if (Constants.objectArrayContainsKey(allFunctions, 'functie_id', primaryFunction.functie_id) === false) {
          allFunctions.push(primaryFunction);
        }
        allFunctions.sort((a, b) => (a.functie_naam > b.functie_naam) ? 1 : -1);

        this.setState({
          secondaryFunctionColor: `#${data.functie_kleur}`,
          secondaryFunctionID: data.functie_id,
          secondaryFunctionName: data.functie_naam,
          functionsUser: allFunctions,
          editPlan: true,
          chosenUserSecondaryFunction: 'fes'+key+i+fid+userID,
      }, () => {
        this.timeRef['fes'+key+i+fid+userID].focus();
      });

    }

  }

  async onClickDayShift(i, toPlanObject, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    let day = i === 6 ? 0 : i + 1;

    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    this.toPlanner = toPlanObject;

    if(this.state.mode === 'MODAL') {

      this.setState({
        modalData: toPlanObject,
        modalMode: 1,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //black opacity, with date passing, controlled reloading
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.fromPlannerDay !== day) {
        this.fromPlanner = [];
        this.setState({ selectedBar: '' });
      }
  
      if(this.fromPlanner.length === 0 || this.state.selectedBar === '') {
        this.fromPlanner = [];
        this.setState({ selectedBar: '' });
        Constants.defaultAlert(this, 'Melding', 'Selecteer of versleep eerst een medewerker');
        return;
      }
  
      this.setState({ selectedBar: '', editPlan: false });
      this.lastEdited = '';

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) { // FROM planned shift

        if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, this.fromPlanner, this.toPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, this.fromPlanner, this.toPlanner);

        if(added !== null) {

          let promises = [];

          const skillsDayShift = this.state.skillDayShifts[this.toPlanner.rooster_id];
          const partDayShift = this.state.partDayShifts[this.toPlanner.rooster_id];

          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partDayShift !== 'undefined') {
            promises.push(APIAddPartSchedule.Request(partDayShift.bijz_id, added));
          }

          promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.toPlanner.dagdienst_id}, ${added})`));
          promises.push(this.putOpenShiftOnUnavailable(this.toPlanner));

          await Promise.all(promises);

          if (typeof skillsDayShift !== 'undefined' || typeof partDayShift !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // copy bijzonderheden and competenties
          // const partDayShift = this.state.partDayShifts[this.toPlanner.rooster_id];
          // if (typeof partDayShift !== 'undefined') {
          //   await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj }); 
          // }
          // const skillsDayShift = this.state.skillDayShifts[this.toPlanner.rooster_id];
          // if (typeof skillsDayShift !== 'undefined') {
          //   for (const skill of skillsDayShift) {
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj }); 
          // }

          // //await APIAddUsedDayShift.Request(date, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, added, this.toPlanner.functie_id);
          // await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.toPlanner.dagdienst_id}, ${added})`);
          // await this.putOpenShiftOnUnavailable(this.toPlanner);
          Logger.addPlannerClick(this.fromPlanner, this.toPlanner);
          this.reloadScreen(date);
        } else {
          //  console.log('error');
        }

        //this.lastEdited = `${added}` + i;

      } else { // from av, notfilled, unav

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        this.isPlanning = true;

        const added = await this.planUser(date, this.fromPlanner, this.toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts[this.toPlanner.rooster_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts[this.toPlanner.rooster_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, added, this.toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.toPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.toPlanner);
          Logger.addPlannerClick(this.fromPlanner, this.toPlanner);
          this.reloadScreen(date);
        } else {
          //  console.log('error');
        }

        //this.lastEdited = toPlanObject.rooster_id + i;
    
      // }

      }

    }

  }

  async onClickDayShiftTable(i, data, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    let day = i === 6 ? 0 : i + 1;

    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'MODAL') {

      const toPlanObject = {
        ...data,
        rooster_datum: Constants.stringToDate(date),
        rooster_id: 'd' + data.dagdienst_id,
        rooster_begin: data.dagdienst_begin,
        rooster_eind: data.dagdienst_eind,
        rooster_pauze: data.dagdienst_pauze,
        info_voornaam: <span>&nbsp;</span>, //n.v.t.
        info_achternaam: '',
        info_tussenvoegsel: null,
        rooster_publiceren: '2',
        functie_id: data.functie_id,
        functie_naam: data.functie_naam,
        functie_kleur: data.functie_kleur,
      }

      this.setState({
        modalData: toPlanObject,
        modalMode: 1,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //black opacity, with date passing, controlled reloading
      });

      return;

    }

    if(this.state.mode === 'CLICK') {

      if(this.state.selectedBar === 'd' + data.dagdienst_id + i) {

        this.setState({
          selectedBar: '',
        });
    
        this.fromPlanner = [];
        this.fromPlannerDay = -1;

      } else {

        this.setState({
          editPlan: false,
          selectedBar: 'd' + data.dagdienst_id + i,
        });
    
        this.fromPlanner = data;
        this.fromPlannerDay = day;

      }

    }

  }

  async onClickHeader(i, functionID) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    let day = i === 6 ? 0 : i + 1;

    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'CLICK') {

      if(this.fromPlannerDay !== day) {
        this.fromPlanner = [];
        this.setState({ selectedBar: '' });
      }

      if(this.fromPlanner.length === 0 || this.state.selectedBar === '') {
        this.fromPlanner = [];
        this.setState({ selectedBar: '' });
        Constants.defaultAlert(this, 'Melding', 'Selecteer of versleep eerst een medewerker');
        return;
      }

      this.setState({ selectedBar: '', editPlan: false });
      this.lastEdited = '';

      if (functionID !== null && functionID >= 0) { //HEADER

        if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) { // from planned shift

          if(this.fromPlanner.functie_id === functionID) {
            this.fromPlanner = [];
            this.setState({ selectedBar: '' });
            return;
          }

          this.isPlanning = true;

          // if (this.state.copyShifts !== true) {
          //   await Plan.deleteUserPlanner(this.fromPlanner);
          // }

          const toPlanner = {
            rooster_datum: date,
            rooster_begin: this.fromPlanner.rooster_begin,
            rooster_eind: this.fromPlanner.rooster_eind,
            rooster_pauze: this.fromPlanner.rooster_pauze,
            functie_id: functionID,
          }

          const [
            deleted,
            added,
          ] = await Promise.all([
            this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
            this.planUser(date, this.fromPlanner, toPlanner),
          ]);

          // const added = await this.planUser(date, this.fromPlanner, toPlanner);

          if(added !== null) {

            let promises = [];

            const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
            const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

            if (typeof skillSchedule !== 'undefined') {
              for (const skill of skillSchedule) {
                if (this.state.copyShifts !== true) {
                  promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
                }
                promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
              }
            }

            if (typeof partSchedule !== 'undefined') {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
              }
              promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
            }

            // let dayShiftID = null;
            // for (const ds of this.usedDayShiftsModern) {
            //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
            //     dayShiftID = ds.gdm_dagdienst_id;
            //     break;
            //   }
            // }

            // if (dayShiftID !== null) {
            //   promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
            // }

            await Promise.all(promises);

            if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

              const [
                skillSchedules,
                partSchedules,
              ] = await Promise.all(
              [
                APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
                APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              ]);

              const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
              const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

              this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

            }

            this.reloadScreen(date);

          } else {
            //  console.log('error');
          }

        } else { // from av, not fileled, unav

          this.isPlanning = true;

          const toPlanner = {
            rooster_datum: date,
            rooster_begin: '00:00',
            rooster_eind: '00:00',
            rooster_pauze: '00:00',
            functie_id: functionID,
          }

          if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, toPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
            this.isPlanning = false;
            this.setState({ selectedBar: '', editPlan: false });
            this.lastEdited = '';
            return;
          }

          const added = await this.planUser(date, this.fromPlanner, toPlanner);

          if(added !== null) {

            this.reloadScreen(date);
          } else {
            //  console.log('error');
          }

        }

      }

    }

    if(this.state.mode === 'MODAL') {

      this.setState({
        modalData: functionID,
        modalMode: 2,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //1
      });

      return;

    }

    // if(this.state.mode === 'CLICK') {

    // this.setState({
    //   modalData: this.fromPlanner,
    //   modalMode: 3,
    //   showModal: true,
    //   modalDate: new Date(date),
    //   reloadScreenMode: 0, //1
    //   modalFunctionID: functionID,
    // });

   // }

  }

  async onClickUserShift(i, editUserObject, e) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;

    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(this.state.mode === 'CLICK') {

      if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') {

        if(this.fromPlannerDay === day) {

          this.setState({editPlan: false});

          if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof editUserObject.rooster_id !== 'undefined' && this.fromPlanner.info_id !== null && editUserObject.info_id !== null) { // switch shifts

            if (this.fromPlanner.info_id === editUserObject.info_id) {
              this.fromPlanner = [];
              this.setState({selectedBar: ''});
              return;
            }

            if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, editUserObject) === false
                || Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[editUserObject.info_id], editUserObject.info_id, date, this.fromPlanner) === false) {
              this.isPlanning = false;
              this.setState({ selectedBar: '', editPlan: false });
              this.lastEdited = '';
              return;
            }

            this.setState({ selectedBar: '' });
            this.lastEdited = '';
            this.isPlanning = true;

            // to
            const added = await Plan.editUserPlanner(
              editUserObject.rooster_begin.substring(0, 5),
              editUserObject.rooster_eind.substring(0, 5),
              isNaN(parseInt(editUserObject.functie_id)) === true ? null : parseInt(editUserObject.functie_id),
              parseInt(this.fromPlanner.info_id),
              parseInt(this.fromPlanner.rooster_id),
              editUserObject.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date, null, editUserObject.rooster_begin.substring(0, 5), editUserObject.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
            );

            // from
            const added2 = await Plan.editUserPlanner(
              this.fromPlanner.rooster_begin.substring(0, 5),
              this.fromPlanner.rooster_eind.substring(0, 5),
              isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
              parseInt(editUserObject.info_id),
              parseInt(editUserObject.rooster_id),
              this.fromPlanner.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), editUserObject) === true ? 1 : 0,
            );

            if(added === true && added2 === true) {

              Logger.editPlannerClick(editUserObject, this.fromPlanner);

              if(editUserObject.rooster_publiceren === '1') {
                KodictNotifications.sendPushNotification(
                  editUserObject.info_id,
                  'planner',
                  'Planner',
                  'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
                );
      
                KodictNotifications.sendPushNotification(
                  this.fromPlanner.info_id,
                  'planner',
                  'Planner',
                  'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
                );
              }

              this.reloadScreen(date);
              
            } else {
              //  console.log('error');
            }

          } else { //  from av, not filled, unav to planned shift

            if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, editUserObject, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
              this.isPlanning = false;
              this.setState({ selectedBar: '', editPlan: false });
              this.lastEdited = '';
              return;
            }

            this.setState({ selectedBar: '' });
            this.lastEdited = '';
            this.isPlanning = true;

            const added = await Plan.editUserPlanner(
              editUserObject.rooster_begin.substring(0, 5),
              editUserObject.rooster_eind.substring(0, 5),
              isNaN(parseInt(editUserObject.functie_id)) === true ? null : parseInt(editUserObject.functie_id),
              parseInt(this.fromPlanner.info_id),
              parseInt(editUserObject.rooster_id),
              editUserObject.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date, null, editUserObject.rooster_begin.substr(0, 5), editUserObject.rooster_eind.substr(0, 5), this.fromPlanner) === true ? 1 : 0,
            );

            if(added === true) {

              Logger.editPlannerClick(editUserObject, this.fromPlanner);

              if(editUserObject.rooster_publiceren === '1') {
                KodictNotifications.sendPushNotification(
                  editUserObject.info_id,
                  'planner',
                  'Planner',
                  'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
                );
      
                KodictNotifications.sendPushNotification(
                  this.fromPlanner.info_id,
                  'planner',
                  'Planner',
                  'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
                );
              }

              this.reloadScreen(date);
              
            } else {
              //  console.log('error');
            }

            //this.lastEdited = editUserObject.rooster_id + i;

          }

        }

      } else {

        if(this.state.selectedBar === 'r' + editUserObject.info_id + i) {

          this.setState({
            selectedBar: '',
          });
      
          this.fromPlanner = [];
          this.fromPlannerDay = -1;
    
        } else {
    
          this.setState({
            selectedBar: 'r' + editUserObject.rooster_id + i,
          });
      
          this.fromPlanner = editUserObject;
          this.fromPlannerDay = day;
    
        }

      }

    }

    if (this.state.mode === 'MODAL') {

      this.setState({
        modalData: editUserObject,
        modalMode: 4,
        showModal: true,
        modalDate: Constants.stringToDate(date),
        reloadScreenMode: 0, //1
      });

      return;

    }

  }

  async onClickUserShiftTable(i, editUserObject, e) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    if (typeof e !== 'undefined' && e.target.className.includes('planTimeAsset') === true) {
      return;
    }

    this.lastEdited = '';

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    this.setState({editPlan: false});

    if (this.state.mode === 'MODAL') {

      if (editUserObject.rooster_publiceren === '3') { // is empty shift

        this.setState({
          modalData: editUserObject,
          modalMode: 1,
          showModal: true,
          modalDate: Constants.stringToDate(date),
          reloadScreenMode: 0, //1
        });

        return;

      } else {

        this.setState({
          modalData: editUserObject,
          modalMode: 4,
          showModal: true,
          modalDate: Constants.stringToDate(date),
          reloadScreenMode: 0, //1
        });

        return;

      }

    }

    if (this.state.mode === 'CLICK') {

      if(this.fromPlanner.length !== 0 && this.state.selectedBar !== '') {

        if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof editUserObject.rooster_id !== 'undefined' && editUserObject.info_id !== null && this.fromPlanner.rooster_publiceren === '3') { // from empty shift

          if (typeof editUserObject.rooster_id !== 'undefined' && editUserObject.rooster_publiceren === '3') {
            this.isPlanning = false;
            this.setState({ selectedBar: '', editPlan: false });
            this.lastEdited = '';
            return;
          }

          if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[editUserObject.info_id], editUserObject.info_id, date, this.fromPlanner) === false) {
            this.isPlanning = false;
            this.setState({ selectedBar: '', editPlan: false });
            this.lastEdited = '';
            return;
          }

          this.setState({ selectedBar: '' });
          this.lastEdited = '';
          this.isPlanning = true;

          await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);

          const added = await Plan.editUserPlanner(
            this.fromPlanner.rooster_begin.substring(0, 5),
            this.fromPlanner.rooster_eind.substring(0, 5),
            isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
            parseInt(editUserObject.info_id),
            parseInt(editUserObject.rooster_id),
            this.fromPlanner.rooster_pauze.substring(0, 5),
            this.getIncorrectCorrectTimesDirectly(date2, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), editUserObject) === true ? 1 : 0,
          );

          const skillSchedule2 = this.state.skillSchedules[editUserObject.rooster_id];
          if (typeof skillSchedule2 !== 'undefined') {
            for (const skill of skillSchedule2) {
              await APIDeleteSkillSchedule.Request(skill.comp_id, editUserObject.rooster_id);
            }
          }

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
              await APIAddSkillSchedule.Request(skill.comp_id, editUserObject.rooster_id);
            }
          }

          if (typeof skillSchedule !== 'undefined' || typeof skillSchedule2 !== 'undefined') {
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj });
          }

          if(added === true) {

            // await APIDeleteUsedDayShift.Request(editUserObject.rooster_id);
            // //await APIAddUsedDayShift.Request(this.fromPlanner.rooster_datum, editUserObject.rooster_begin, editUserObject.rooster_eind, this.fromPlanner.rooster_id, editUserObject.functie_id);
            // const dayShiftID = this.usedDayShiftsModern.map((ds) => ds.gdm_rooster_id === this.fromPlanner.rooster_id ? ds.gdm_dagdienst_id : null);
            // if (dayShiftID !== null) {
            //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
            // }

            //Logger.editPlannerClick(editUserObject, this.fromPlanner);

            if(editUserObject.rooster_publiceren === '1') {
              KodictNotifications.sendPushNotification(
                editUserObject.info_id,
                'planner',
                'Planner',
                'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
              );
            }

          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
          } else {
            //  console.log('error');
          }

        } else if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof editUserObject.rooster_id !== 'undefined' && this.fromPlanner.info_id !== null && editUserObject.rooster_publiceren === '3') { // to empty shift

          this.setState({ selectedBar: '' });
          this.lastEdited = '';
          this.isPlanning = true;

          await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);
          await APIDeleteUserPlanner.Request(editUserObject.rooster_id);

          const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${this.fromPlanner.rooster_begin}', '${this.fromPlanner.rooster_eind}', NULL, ${this.fromPlanner.functie_id}, 3, '${this.fromPlanner.rooster_pauze}', 0)`);

          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

          if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
            await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
          }

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              await APIAddSkillSchedule.Request(skill.comp_id, rid[0].rooster_id);
            }
          }

          if (typeof skillSchedule !== 'undefined') {
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj });
          }

          if(added === true) {

            await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);

            //Logger.editPlannerClick(editUserObject, this.fromPlanner);

            if(this.fromPlanner.rooster_publiceren === '1') {
              KodictNotifications.sendPushNotification(
                this.fromPlanner.info_id,
                'planner',
                'Planner',
                'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
              );
            }

          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
          } else {
            //  console.log('error');
          }

        } else if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof editUserObject.rooster_id !== 'undefined' && this.fromPlanner.info_id !== null && editUserObject.info_id !== null) { // switch shifts

          // if (this.fromPlanner.info_id === editUserObject.info_id) {
          //   this.fromPlanner = [];
          //   this.setState({selectedBar: ''});
          //   return;
          // }

          let added = false;
          let added2 = false;

          if (this.fromPlanner.info_id === editUserObject.info_id) { //switch shifts same user

            if (this.fromPlanner.functie_id === editUserObject.functie_id && this.fromPlanner.rooster_begin === editUserObject.rooster_begin && this.fromPlanner.rooster_eind === editUserObject.rooster_eind) {
              this.fromPlanner = [];
              this.setState({selectedBar: ''});
              return;
            }

            if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date2, editUserObject) === false
                || Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[editUserObject.info_id], editUserObject.info_id, date, this.fromPlanner) === false) {
              this.isPlanning = false;
              this.setState({ selectedBar: '', editPlan: false });
              this.lastEdited = '';
              return;
            }

            this.setState({ selectedBar: '' });
            this.lastEdited = '';
            this.isPlanning = true;

            // to
            added = await Plan.editUserPlanner(
              editUserObject.rooster_begin.substring(0, 5),
              editUserObject.rooster_eind.substring(0, 5),
              isNaN(parseInt(editUserObject.functie_id)) === true ? null : parseInt(editUserObject.functie_id),
              parseInt(this.fromPlanner.info_id),
              parseInt(this.fromPlanner.rooster_id),
              editUserObject.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date2, null, editUserObject.rooster_begin.substring(0, 5), editUserObject.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
            );

            // from
            added2 = await Plan.editUserPlanner(
              this.fromPlanner.rooster_begin.substring(0, 5),
              this.fromPlanner.rooster_eind.substring(0, 5),
              isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
              parseInt(this.fromPlanner.info_id),
              parseInt(editUserObject.rooster_id),
              this.fromPlanner.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), editUserObject) === true ? 1 : 0,
            );

            // remove and add skills to new rooster_id
            const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
            if (typeof skillSchedule !== 'undefined') {
              for (const skill of skillSchedule) {
                await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
                await APIAddSkillSchedule.Request(skill.comp_id, editUserObject.rooster_id);
              }
            }
            const skillSchedule2 = this.state.skillSchedules[editUserObject.rooster_id];
            if (typeof skillSchedule2 !== 'undefined') {
              for (const skill of skillSchedule2) {
                await APIDeleteSkillSchedule.Request(skill.comp_id, editUserObject.rooster_id);
                await APIAddSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
              }
            }

            if (typeof skillSchedule !== 'undefined' || typeof skillSchedule2 !== 'undefined') {
              const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
              const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
              this.setState({ skillSchedules: skillSchedulesObj });
            }

          } else { //switch shifts to other user

            if (date === date2) { // same day other user

              if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, editUserObject) === false
                  || Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[editUserObject.info_id], editUserObject.info_id, date2, this.fromPlanner) === false) {
                this.isPlanning = false;
                this.setState({ selectedBar: '', editPlan: false });
                this.lastEdited = '';
                return;
              }

            } else { // other day other user

              if (Plan.checkInfoExtraEditOtherDayOtherUser(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, editUserObject, this.allPlannerData, this.state.planUsersMaxCumulative, date2) === false
                  || Plan.checkInfoExtraEditOtherDayOtherUser(this.state.infoExtra[editUserObject.info_id], editUserObject.info_id, date2, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative, date) === false) {
                this.isPlanning = false;
                this.setState({ selectedBar: '', editPlan: false });
                this.lastEdited = '';
                return;
              }

            }

            this.setState({ selectedBar: '' });
            this.lastEdited = '';
            this.isPlanning = true;

            // to
            added = await Plan.editUserPlanner(
              editUserObject.rooster_begin.substring(0, 5),
              editUserObject.rooster_eind.substring(0, 5),
              isNaN(parseInt(editUserObject.functie_id)) === true ? null : parseInt(editUserObject.functie_id),
              parseInt(this.fromPlanner.info_id),
              parseInt(this.fromPlanner.rooster_id),
              editUserObject.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date2, null, editUserObject.rooster_begin.substring(0, 5), editUserObject.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
            );

            // from
            added2 = await Plan.editUserPlanner(
              this.fromPlanner.rooster_begin.substring(0, 5),
              this.fromPlanner.rooster_eind.substring(0, 5),
              isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
              parseInt(editUserObject.info_id),
              parseInt(editUserObject.rooster_id),
              this.fromPlanner.rooster_pauze.substring(0, 5),
              this.getIncorrectCorrectTimesDirectly(date, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), editUserObject) === true ? 1 : 0,
            );

          }

          if(added === true && added2 === true) {

            // await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);
            // await APIDeleteUsedDayShift.Request(editUserObject.rooster_id);
            // await APIDELETE.Request(`DELETE FROM GebruikteDagDienstModern`, `WHERE gdm_rooster_id = ${this.fromPlanner.rooster_id}`);
            // await APIDELETE.Request(`DELETE FROM GebruikteDagDienstModern`, `WHERE gdm_rooster_id = ${editUserObject.rooster_id}`);
            // const dayShiftID = this.usedDayShiftsModern.map((ds) => ds.gdm_rooster_id === this.fromPlanner.rooster_id ? ds.gdm_dagdienst_id : null);
            // if (dayShiftID !== null) {
            //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${editUserObject.rooster_id})`);
            // }
            // const dayShiftID2 = this.usedDayShiftsModern.map((ds) => ds.gdm_rooster_id === editUserObject.rooster_id ? ds.gdm_dagdienst_id : null);
            // if (dayShiftID2 !== null) {
            //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID2}, ${this.fromPlanner.rooster_id})`);
            // }
            //await APIAddUsedDayShift.Request(this.fromPlanner.rooster_datum, editUserObject.rooster_begin, editUserObject.rooster_eind, this.fromPlanner.rooster_id, editUserObject.functie_id);
            //await APIAddUsedDayShift.Request(editUserObject.rooster_datum, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, editUserObject.rooster_id, this.fromPlanner.functie_id);

            Logger.editPlannerClick(editUserObject, this.fromPlanner);

            if(editUserObject.rooster_publiceren === '1') {
              KodictNotifications.sendPushNotification(
                editUserObject.info_id,
                'planner',
                'Planner',
                'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
              );
    
              KodictNotifications.sendPushNotification(
                this.fromPlanner.info_id,
                'planner',
                'Planner',
                'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
              );
            }

          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
          } else {
            //  console.log('error');
          }

        } else if (typeof this.fromPlanner.dagdienst_id !== 'undefined' && this.fromPlanner.dagdienst_id !== null) {

          if (typeof editUserObject.rooster_id !== 'undefined' && editUserObject.rooster_publiceren === '3') {
            this.isPlanning = false;
            this.fromPlanner = [];
            this.setState({ selectedBar: '', editPlan: false, reloading: false });
            this.lastEdited = '';
            return;
          }

          if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[editUserObject.info_id], editUserObject.info_id, date, this.fromPlanner) === false) {
            this.isPlanning = false;
            this.setState({ selectedBar: '', editPlan: false });
            this.lastEdited = '';
            return;
          }

          this.setState({ selectedBar: '' });
          this.lastEdited = '';
          this.isPlanning = true;

          const toPlanner = {
            rooster_datum: date,
            rooster_begin: this.fromPlanner.dagdienst_begin.substr(0, 5),
            rooster_eind: this.fromPlanner.dagdienst_eind.substr(0, 5),
            rooster_pauze: this.fromPlanner.dagdienst_pauze.substr(0, 5),
            functie_id: this.fromPlanner.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
            functie_naam: this.fromPlanner.functie_naam
          }

          await APIDeleteUserPlanner.Request(editUserObject.rooster_id);

          if (editUserObject.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(editUserObject.info_id, 'planner', 'Planner', 'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten');
          }

          const added = await this.planUser(date, editUserObject, toPlanner);

          if(added !== null) {

            // copy bijzonderheden and competenties
            const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.dagdienst_id];
            if (typeof partDayShift !== 'undefined') {
              await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
              const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
              const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
              this.setState({ partSchedules: partSchedulesObj }); 
            }
            const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.dagdienst_id];
            if (typeof skillsDayShift !== 'undefined') {
              for (const skill of skillsDayShift) {
                await APIAddSkillSchedule.Request(skill.comp_id, added);
              }
              const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
              const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
              this.setState({ skillSchedules: skillSchedulesObj }); 
            }

            //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
            await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.dagdienst_id}, ${added})`);
            await this.putOpenShiftOnUnavailable(this.fromPlanner);
            Logger.addPlannerClick(editUserObject, toPlanner);
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
          } else {
            //  console.log('error');
          }

        } else { //  from av, not filled, unav to planned shift

          if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, editUserObject, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
            this.isPlanning = false;
            this.setState({ selectedBar: '', editPlan: false });
            this.lastEdited = '';
            return;
          }

          this.setState({ selectedBar: '' });
          this.lastEdited = '';
          this.isPlanning = true;

          const added = await Plan.editUserPlanner(
            editUserObject.rooster_begin.substring(0, 5),
            editUserObject.rooster_eind.substring(0, 5),
            isNaN(parseInt(editUserObject.functie_id)) === true ? null : parseInt(editUserObject.functie_id),
            parseInt(this.fromPlanner.info_id),
            parseInt(editUserObject.rooster_id),
            editUserObject.rooster_pauze.substring(0, 5),
            this.getIncorrectCorrectTimesDirectly(date, null, editUserObject.rooster_begin.substr(0, 5), editUserObject.rooster_eind.substr(0, 5), this.fromPlanner) === true ? 1 : 0,
          );

          if(added === true) {

            Logger.editPlannerClick(editUserObject, this.fromPlanner);

            if(editUserObject.rooster_publiceren === '1') {
              KodictNotifications.sendPushNotification(
                editUserObject.info_id,
                'planner',
                'Planner',
                'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
              );
    
              KodictNotifications.sendPushNotification(
                this.fromPlanner.info_id,
                'planner',
                'Planner',
                'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
              );
            }

          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
          } else {
            //  console.log('error');
          }

          //this.lastEdited = editUserObject.rooster_id + i;

        }

      } else {

        if(this.state.selectedBar === 'r' + editUserObject.rooster_id + i) {

          this.setState({
            selectedBar: '',
          });
      
          this.fromPlanner = [];
          this.fromPlannerDay = -1;
    
        } else {
    
          this.setState({
            selectedBar: 'r' + editUserObject.rooster_id + i,
          });
      
          this.fromPlanner = editUserObject;
          this.fromPlannerDay = day;
    
        }

      }

    }

  }

  onDragStartNotFilledSchool(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.notFilledSchool.sun[key];
    }
    if(day === 1) {
      data = this.notFilledSchool.mon[key];
    }
    if(day === 2) {
      data = this.notFilledSchool.tue[key];
    }
    if(day === 3) {
      data = this.notFilledSchool.wed[key];
    }
    if(day === 4) {
      data = this.notFilledSchool.thu[key];
    }
    if(day === 5) {
      data = this.notFilledSchool.fri[key];
    }
    if(day === 6) {
      data = this.notFilledSchool.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartSchoolHoliday(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.schoolHolidayData.sun[key];
    }
    if(day === 1) {
      data = this.schoolHolidayData.mon[key];
    }
    if(day === 2) {
      data = this.schoolHolidayData.tue[key];
    }
    if(day === 3) {
      data = this.schoolHolidayData.wed[key];
    }
    if(day === 4) {
      data = this.schoolHolidayData.thu[key];
    }
    if(day === 5) {
      data = this.schoolHolidayData.fri[key];
    }
    if(day === 6) {
      data = this.schoolHolidayData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartSchoolSchedule(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.schoolScheduleData.sun[key];
    }
    if(day === 1) {
      data = this.schoolScheduleData.mon[key];
    }
    if(day === 2) {
      data = this.schoolScheduleData.tue[key];
    }
    if(day === 3) {
      data = this.schoolScheduleData.wed[key];
    }
    if(day === 4) {
      data = this.schoolScheduleData.thu[key];
    }
    if(day === 5) {
      data = this.schoolScheduleData.fri[key];
    }
    if(day === 6) {
      data = this.schoolScheduleData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartStandardAvailable(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.standardAvailableData.sun[key];
    }
    if(day === 1) {
      data = this.standardAvailableData.mon[key];
    }
    if(day === 2) {
      data = this.standardAvailableData.tue[key];
    }
    if(day === 3) {
      data = this.standardAvailableData.wed[key];
    }
    if(day === 4) {
      data = this.standardAvailableData.thu[key];
    }
    if(day === 5) {
      data = this.standardAvailableData.fri[key];
    }
    if(day === 6) {
      data = this.standardAvailableData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartStandardUnavailable(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.standardUnavailableData.sun[key];
    }
    if(day === 1) {
      data = this.standardUnavailableData.mon[key];
    }
    if(day === 2) {
      data = this.standardUnavailableData.tue[key];
    }
    if(day === 3) {
      data = this.standardUnavailableData.wed[key];
    }
    if(day === 4) {
      data = this.standardUnavailableData.thu[key];
    }
    if(day === 5) {
      data = this.standardUnavailableData.fri[key];
    }
    if(day === 6) {
      data = this.standardUnavailableData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartStandardNotFilled(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.standardNotFilledData.sun[key];
    }
    if(day === 1) {
      data = this.standardNotFilledData.mon[key];
    }
    if(day === 2) {
      data = this.standardNotFilledData.tue[key];
    }
    if(day === 3) {
      data = this.standardNotFilledData.wed[key];
    }
    if(day === 4) {
      data = this.standardNotFilledData.thu[key];
    }
    if(day === 5) {
      data = this.standardNotFilledData.fri[key];
    }
    if(day === 6) {
      data = this.standardNotFilledData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartNoAvailability(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.usersNoAvailability.sun[key];
    }
    if(day === 1) {
      data = this.usersNoAvailability.mon[key];
    }
    if(day === 2) {
      data = this.usersNoAvailability.tue[key];
    }
    if(day === 3) {
      data = this.usersNoAvailability.wed[key];
    }
    if(day === 4) {
      data = this.usersNoAvailability.thu[key];
    }
    if(day === 5) {
      data = this.usersNoAvailability.fri[key];
    }
    if(day === 6) {
      data = this.usersNoAvailability.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartAvailable(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.availableData.sun[key];
    }
    if(day === 1) {
      data = this.availableData.mon[key];
    }
    if(day === 2) {
      data = this.availableData.tue[key];
    }
    if(day === 3) {
      data = this.availableData.wed[key];
    }
    if(day === 4) {
      data = this.availableData.thu[key];
    }
    if(day === 5) {
      data = this.availableData.fri[key];
    }
    if(day === 6) {
      data = this.availableData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartNotFilled(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.notFilledData.sun[key];
    }
    if(day === 1) {
      data = this.notFilledData.mon[key];
    }
    if(day === 2) {
      data = this.notFilledData.tue[key];
    }
    if(day === 3) {
      data = this.notFilledData.wed[key];
    }
    if(day === 4) {
      data = this.notFilledData.thu[key];
    }
    if(day === 5) {
      data = this.notFilledData.fri[key];
    }
    if(day === 6) {
      data = this.notFilledData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartUnavailable(event, day, key) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    let data = [];

    if(day === 0) {
      data = this.unavailableData.sun[key];
    }
    if(day === 1) {
      data = this.unavailableData.mon[key];
    }
    if(day === 2) {
      data = this.unavailableData.tue[key];
    }
    if(day === 3) {
      data = this.unavailableData.wed[key];
    }
    if(day === 4) {
      data = this.unavailableData.thu[key];
    }
    if(day === 5) {
      data = this.unavailableData.fri[key];
    }
    if(day === 6) {
      data = this.unavailableData.sat[key];
    }

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartHoliday(event, day, data) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartUserShift(event, day, data) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    if (this.timeRef[data.rooster_id] === document.activeElement) {
      event.preventDefault();
      return false;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    day = day === 6 ? 0 : day + 1;

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  onDragStartDayShift(event, day, data) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      event.preventDefault();
      return;
    }

    // if (this.state.editPlan === true) {
    //   event.preventDefault();
    //   return;
    // }

    this.lastEdited = '';

    day = day === 6 ? 0 : day + 1;

    this.fromPlanner = data;
    this.fromPlannerDay = day;

  }

  async onDrop(event, i, toPlanObject, functionID) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    event.target.style.opacity = null;
    event.currentTarget.style.opacity = null;

    this.lastEdited = '';

    this.toPlanner = toPlanObject;

    let day = i === 6 ? 0 : i + 1;

    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    this.setState({editPlan: false});

    if(this.toPlanner === null && functionID >= 0 && functionID !== null) { //HEADER

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) { // from planned shift

        if(this.fromPlanner.functie_id === functionID) {
          this.fromPlanner = [];
          this.setState({ selectedBar: '' });
          return;
        }

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.rooster_begin,
          rooster_eind: this.fromPlanner.rooster_eind,
          rooster_pauze: this.fromPlanner.rooster_pauze,
          functie_id: functionID,
        }

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, this.fromPlanner, toPlanner),
        ]);

        // const added = await this.planUser(date, this.fromPlanner, toPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }

          // if (dayShiftID !== null) {
          //   promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          // }

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          this.reloadScreen(date);
          
        } else {
          //  console.log('error');
        }

      } else { // from av, not fileled, unav

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: '00:00',
          rooster_eind: '00:00',
          rooster_pauze: '00:00',
          functie_id: functionID,
        }

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, toPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        const added = await this.planUser(date, this.fromPlanner, toPlanner);

        if(added !== null) {
          this.reloadScreen(date);
        } else {
          //  console.log('error');
        }

      }

      //this.lastEdited = `${added}` + i;

      return;

    }

    if(this.toPlanner !== null && this.toPlanner.rooster_publiceren === '2') { //default day shift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null) { // FROM planned shift

        if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, this.fromPlanner, this.toPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }
  
        // const added = await this.planUser(date, this.fromPlanner, this.toPlanner);
  
        if(added !== null) {

          let promises = [];

          const skillsDayShift = this.state.skillDayShifts[this.toPlanner.rooster_id];
          const partDayShift = this.state.partDayShifts[this.toPlanner.rooster_id];

          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partDayShift !== 'undefined') {
            promises.push(APIAddPartSchedule.Request(partDayShift.bijz_id, added));
          }

          promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.toPlanner.dagdienst_id}, ${added})`));
          promises.push(this.putOpenShiftOnUnavailable(this.toPlanner));

          await Promise.all(promises);

          if (typeof skillsDayShift !== 'undefined' || typeof partDayShift !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // copy bijzonderheden and competenties
          // const partDayShift = this.state.partDayShifts[this.toPlanner.rooster_id];
          // if (typeof partDayShift !== 'undefined') {
          //   await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj }); 
          // }
          // const skillsDayShift = this.state.skillDayShifts[this.toPlanner.rooster_id];
          // if (typeof skillsDayShift !== 'undefined') {
          //   for (const skill of skillsDayShift) {
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj }); 
          // }

          // //await APIAddUsedDayShift.Request(date, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, added, this.toPlanner.functie_id);
          // await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.toPlanner.dagdienst_id}, ${added})`);
          // await this.putOpenShiftOnUnavailable(this.toPlanner);
          Logger.addPlannerClick(this.fromPlanner, this.toPlanner);
          this.reloadScreen(date);
        } else {
          //  console.log('error');
        }
  
        //this.lastEdited = `${added}` + i;
  
      } else { // from av, notfilled, unav

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        const added = await this.planUser(date, this.fromPlanner, this.toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts[this.toPlanner.rooster_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts[this.toPlanner.rooster_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, added, this.toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.toPlanner.dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.toPlanner);
          this.reloadScreen(date);
        } else {
          //  console.log('error');
        }

      }

      //this.lastEdited = toPlanObject.rooster_id + i;

      return;

    }

    if(this.toPlanner !== null) { // planned shift

      if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof this.toPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null && this.toPlanner.rooster_id !== null) { // switch shifts

        if (this.fromPlanner.info_id === this.toPlanner.info_id) {
          this.fromPlanner = [];
          return;
        }

        if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner) === false
            || Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date, this.fromPlanner) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        // to
        const added = await Plan.editUserPlanner(
          this.toPlanner.rooster_begin.substring(0, 5),
          this.toPlanner.rooster_eind.substring(0, 5),
          isNaN(parseInt(this.toPlanner.functie_id)) === true ? null : parseInt(this.toPlanner.functie_id),
          parseInt(this.fromPlanner.info_id),
          parseInt(this.fromPlanner.rooster_id),
          this.toPlanner.rooster_pauze.substring(0, 5),
          this.getIncorrectCorrectTimesDirectly(date, null, this.toPlanner.rooster_begin.substring(0, 5), this.toPlanner.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
        );

        // from
        const added2 = await Plan.editUserPlanner(
          this.fromPlanner.rooster_begin.substring(0, 5),
          this.fromPlanner.rooster_eind.substring(0, 5),
          isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
          parseInt(this.toPlanner.info_id),
          parseInt(this.toPlanner.rooster_id),
          this.fromPlanner.rooster_pauze.substring(0, 5),
          this.getIncorrectCorrectTimesDirectly(date, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), this.toPlanner) === true ? 1 : 0,
        );

        if(added === true && added2 === true) {

          Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.toPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.toPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
  
            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          this.reloadScreen(date);
          
        } else {
          //  console.log('error');
        }

      } else { //  from av, not filled, unav to planned shift

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false });
          this.lastEdited = '';
          return;
        }

        const added = await Plan.editUserPlanner(
          this.toPlanner.rooster_begin, 
          this.toPlanner.rooster_eind,
          this.toPlanner.functie_id,
          this.fromPlanner.info_id,
          this.toPlanner.rooster_id,
          this.toPlanner.rooster_pauze,
          this.getIncorrectCorrectTimesDirectly(date, null, this.toPlanner.rooster_begin.substring(0, 5), this.toPlanner.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
        );

        if(added !== null) {

          if(this.toPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.toPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );

            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          this.reloadScreen(date);
        } else {
          //  console.log('error');
        }

        //this.lastEdited = toPlanObject.rooster_id + i;

      }

      return;

    }

    // if(this.toPlanner === null && functionID >= 0 && functionID !== null) {

    //   this.setState({
    //     modalData: this.fromPlanner,
    //     modalMode: 3,
    //     showModal: true,
    //     modalDate: new Date(date),
    //     reloadScreenMode: 0, //1
    //     modalFunctionID: functionID,
    //   });

    //   return;

    // } else {

    //   const added = await this.planUser(date, this.fromPlanner, this.toPlanner);

    //   if(added !== null) {
    //     await APIAddUsedDayShift.Request(date, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, added, this.toPlanner.functie_id);
    //     this.reloadScreen(date);
    //   } else {
    //     //  console.log('error');
    //   }

    // }

  }

  async onDropTable(event, i, toPlanObject) {

    if(this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    }

    event.target.style.opacity = null;
    event.currentTarget.style.opacity = null;

    this.lastEdited = '';

    this.toPlanner = toPlanObject;

    let day = i === 6 ? 0 : i + 1;
    let day2 = this.fromPlannerDay;

    let date = null;
    let date2 = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    if(day2 === 0) {
      date2 = this.state.sundayDate;
    }
    if(day2 === 1) {
      date2 = this.state.mondayDate;
    }
    if(day2 === 2) {
      date2 = this.state.tuesdayDate;
    }
    if(day2 === 3) {
      date2 = this.state.wednesdayDate;
    }
    if(day2 === 4) {
      date2 = this.state.thursdayDate;
    }
    if(day2 === 5) {
      date2 = this.state.fridayDate;
    }
    if(day2 === 6) {
      date2 = this.state.saturdayDate;
    }

    this.setState({editPlan: false});

    if(this.toPlanner !== null) { // planned shift

      this.setState({reloading: true});

      if (this.toPlanner.info_id === null && typeof this.toPlanner.functie_id !== 'undefined' && typeof this.fromPlanner.count === 'undefined') { // to empty nulled touchbox

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);

        const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${this.fromPlanner.rooster_begin}', '${this.fromPlanner.rooster_eind}', NULL, ${this.toPlanner.functie_id}, 3, '${this.fromPlanner.rooster_pauze}', 0)`);

        const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
          await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
        }

        const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
        if (typeof skillSchedule !== 'undefined') {
          for (const skill of skillSchedule) {
            await APIAddSkillSchedule.Request(skill.comp_id, rid[0].rooster_id);
          }
        }

        if (typeof skillSchedule !== 'undefined') {
          const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          this.setState({ skillSchedules: skillSchedulesObj });
        }

        // remove and add part to new rooster_ID
        const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
        if (typeof partSchedule !== 'undefined') {
          await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          await APIAddPartSchedule.Request(partSchedule.bijz_id, rid[0].rooster_id);
          const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          this.setState({ partSchedules: partSchedulesObj });
        }

        if(added === true) {

          await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }
          if (dayShiftID !== null) {
            await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${rid[0].rooster_id})`);
          }

          //Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.fromPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof this.toPlanner.rooster_id !== 'undefined' && this.toPlanner.info_id !== null && this.fromPlanner.rooster_publiceren === '3') { // from empty shift

        if (typeof this.toPlanner.rooster_id !== 'undefined' && this.toPlanner.rooster_publiceren === '3') {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false, reloading: false });
          this.lastEdited = '';
          return;
        }

        if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date, this.fromPlanner) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false, reloading: false });
          this.lastEdited = '';
          return;
        }

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);

        const added = await Plan.editUserPlanner(
          this.fromPlanner.rooster_begin.substring(0, 5),
          this.fromPlanner.rooster_eind.substring(0, 5),
          isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
          parseInt(this.toPlanner.info_id),
          parseInt(this.toPlanner.rooster_id),
          this.fromPlanner.rooster_pauze.substring(0, 5),
          this.getIncorrectCorrectTimesDirectly(date2, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), this.toPlanner) === true ? 1 : 0,
        );

        const skillSchedule2 = this.state.skillSchedules[this.toPlanner.rooster_id];
        if (typeof skillSchedule2 !== 'undefined') {
          for (const skill of skillSchedule2) {
            await APIDeleteSkillSchedule.Request(skill.comp_id, this.toPlanner.rooster_id);
          }
        }

        const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
        if (typeof skillSchedule !== 'undefined') {
          for (const skill of skillSchedule) {
            await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
            await APIAddSkillSchedule.Request(skill.comp_id, this.toPlanner.rooster_id);
          }
        }

        if (typeof skillSchedule !== 'undefined' || typeof skillSchedule2 !== 'undefined') {
          const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          this.setState({ skillSchedules: skillSchedulesObj });
        }

        if(added === true) {

          // await APIDeleteUsedDayShift.Request(this.toPlanner.rooster_id);
          // await APIAddUsedDayShift.Request(this.fromPlanner.rooster_datum, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, this.fromPlanner.rooster_id, this.toPlanner.functie_id);

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }
          if (dayShiftID !== null) {
            await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${this.toPlanner.rooster_id})`);
          }

          //Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.toPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.toPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof this.toPlanner.rooster_id !== 'undefined' && this.fromPlanner.info_id !== null && this.toPlanner.rooster_publiceren === '3') { // to empty shift

        this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        await APIDeleteUserPlanner.Request(this.fromPlanner.rooster_id);
        await APIDeleteUserPlanner.Request(this.toPlanner.rooster_id);

        const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${this.fromPlanner.rooster_begin}', '${this.fromPlanner.rooster_eind}', NULL, ${this.fromPlanner.functie_id}, 3, '${this.fromPlanner.rooster_pauze}', 0)`);

        const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
          await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
        }

        const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
        if (typeof skillSchedule !== 'undefined') {
          for (const skill of skillSchedule) {
            await APIAddSkillSchedule.Request(skill.comp_id, rid[0].rooster_id);
          }
        }

        if (typeof skillSchedule !== 'undefined') {
          const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          this.setState({ skillSchedules: skillSchedulesObj });
        }

        if(added === true) {

          await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);

          //Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.fromPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
            
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.rooster_id !== 'undefined' && typeof this.toPlanner.rooster_id !== 'undefined' && this.fromPlanner.rooster_id !== null && this.toPlanner.rooster_id !== null) { // switch shifts

        // if (this.fromPlanner.info_id === this.toPlanner.info_id) {
        //   this.fromPlanner = [];
        //   return;
        // }

        let added = false;
        let added2 = false;

        if (this.fromPlanner.info_id === this.toPlanner.info_id) { //switch shifts same user

          if (this.fromPlanner.functie_id === this.toPlanner.functie_id && this.fromPlanner.rooster_begin === this.toPlanner.rooster_begin && this.fromPlanner.rooster_eind === this.toPlanner.rooster_eind) {
            this.fromPlanner = [];
            this.setState({selectedBar: ''});
            this.setState({reloading: false});
            return;
          }

          if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date2, this.toPlanner) === false
              || Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date, this.fromPlanner) === false) {
            this.isPlanning = false;
            this.fromPlanner = [];
            this.setState({ selectedBar: '', editPlan: false, reloading: false });
            this.lastEdited = '';
            return;
          }

          this.setState({ selectedBar: '' });
          this.lastEdited = '';
          this.isPlanning = true;

          // to
          added = await Plan.editUserPlanner(
            this.toPlanner.rooster_begin.substring(0, 5),
            this.toPlanner.rooster_eind.substring(0, 5),
            isNaN(parseInt(this.toPlanner.functie_id)) === true ? null : parseInt(this.toPlanner.functie_id),
            parseInt(this.fromPlanner.info_id),
            parseInt(this.fromPlanner.rooster_id),
            this.toPlanner.rooster_pauze.substring(0, 5),
            this.getIncorrectCorrectTimesDirectly(date2, null, this.toPlanner.rooster_begin.substring(0, 5), this.toPlanner.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
          );

          // from
          added2 = await Plan.editUserPlanner(
            this.fromPlanner.rooster_begin.substring(0, 5),
            this.fromPlanner.rooster_eind.substring(0, 5),
            isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
            parseInt(this.fromPlanner.info_id),
            parseInt(this.toPlanner.rooster_id),
            this.fromPlanner.rooster_pauze.substring(0, 5),
            this.getIncorrectCorrectTimesDirectly(date, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), this.toPlanner) === true ? 1 : 0,
          );

          // remove and add skills to new rooster_id
          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
              await APIAddSkillSchedule.Request(skill.comp_id, this.toPlanner.rooster_id);
            }
          }
          const skillSchedule2 = this.state.skillSchedules[this.toPlanner.rooster_id];
          if (typeof skillSchedule2 !== 'undefined') {
            for (const skill of skillSchedule2) {
              await APIDeleteSkillSchedule.Request(skill.comp_id, this.toPlanner.rooster_id);
              await APIAddSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
            }
          }

          if (typeof skillSchedule !== 'undefined' || typeof skillSchedule2 !== 'undefined') {
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj });
          }

        } else { //switch shifts to other user

          if (date === date2) { // same day other user

            if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner) === false
                || Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date2, this.fromPlanner) === false) {
              this.isPlanning = false;
              this.fromPlanner = [];
              this.setState({ selectedBar: '', editPlan: false, reloading: false });
              this.lastEdited = '';
              return;
            }

          } else { // other day other user

            if (Plan.checkInfoExtraEditOtherDayOtherUser(this.state.infoExtra[this.fromPlanner.info_id], this.fromPlanner.info_id, date, this.toPlanner, this.allPlannerData, this.state.planUsersMaxCumulative, date2) === false
                || Plan.checkInfoExtraEditOtherDayOtherUser(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date2, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative, date) === false) {
              this.isPlanning = false;
              this.setState({ selectedBar: '', editPlan: false, reloading: false });
              this.lastEdited = '';
              return;
            }

          }

          this.setState({ selectedBar: '' });
          this.lastEdited = '';
          this.isPlanning = true;

          // to
          added = await Plan.editUserPlanner(
            this.toPlanner.rooster_begin.substring(0, 5),
            this.toPlanner.rooster_eind.substring(0, 5),
            isNaN(parseInt(this.toPlanner.functie_id)) === true ? null : parseInt(this.toPlanner.functie_id),
            parseInt(this.fromPlanner.info_id),
            parseInt(this.fromPlanner.rooster_id),
            this.toPlanner.rooster_pauze.substring(0, 5),
            this.getIncorrectCorrectTimesDirectly(date2, null, this.toPlanner.rooster_begin.substring(0, 5), this.toPlanner.rooster_eind.substring(0, 5), this.fromPlanner) === true ? 1 : 0,
          );

          // from
          added2 = await Plan.editUserPlanner(
            this.fromPlanner.rooster_begin.substring(0, 5),
            this.fromPlanner.rooster_eind.substring(0, 5),
            isNaN(parseInt(this.fromPlanner.functie_id)) === true ? null : parseInt(this.fromPlanner.functie_id),
            parseInt(this.toPlanner.info_id),
            parseInt(this.toPlanner.rooster_id),
            this.fromPlanner.rooster_pauze.substring(0, 5),
            this.getIncorrectCorrectTimesDirectly(date, null, this.fromPlanner.rooster_begin.substring(0, 5), this.fromPlanner.rooster_eind.substring(0, 5), this.toPlanner) === true ? 1 : 0,
          );

        }

        if(added === true && added2 === true) {

          // await APIDeleteUsedDayShift.Request(this.fromPlanner.rooster_id);
          // await APIDeleteUsedDayShift.Request(this.toPlanner.rooster_id);
          // await APIDELETE.Request(`DELETE FROM GebruikteDagDienstModern`, `WHERE gdm_rooster_id = ${this.fromPlanner.rooster_id}`);
          // await APIDELETE.Request(`DELETE FROM GebruikteDagDienstModern`, `WHERE gdm_rooster_id = ${this.toPlanner.rooster_id}`);
          // await APIAddUsedDayShift.Request(this.fromPlanner.rooster_datum, this.toPlanner.rooster_begin, this.toPlanner.rooster_eind, this.fromPlanner.rooster_id, this.toPlanner.functie_id);
          // await APIAddUsedDayShift.Request(this.toPlanner.rooster_datum, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, this.toPlanner.rooster_id, this.fromPlanner.functie_id);
          // const dayShiftID = this.usedDayShiftsModern.map((ds) => ds.gdm_rooster_id === this.fromPlanner.rooster_id ? ds.gdm_dagdienst_id : null);
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${this.toPlanner.rooster_id})`);
          // }
          // const dayShiftID2 = this.usedDayShiftsModern.map((ds) => ds.gdm_rooster_id === this.toPlanner.rooster_id ? ds.gdm_dagdienst_id : null);
          // if (dayShiftID2 !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID2}, ${this.fromPlanner.rooster_id})`);
          // }

          Logger.editPlannerClick(this.toPlanner, this.fromPlanner);

          if(this.toPlanner.rooster_publiceren === '1') {
            KodictNotifications.sendPushNotification(
              this.toPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
  
            KodictNotifications.sendPushNotification(
              this.fromPlanner.info_id,
              'planner',
              'Planner',
              'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten'
            );
          }

          // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
          
        } else {
          //  console.log('error');
        }

      } else if (typeof this.fromPlanner.count !== 'undefined' && this.fromPlanner.count !== null) {

        if ((typeof this.toPlanner.rooster_id !== 'undefined' && this.toPlanner.rooster_publiceren === '3') || this.toPlanner.info_id === null) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false, reloading: false });
          this.lastEdited = '';
          return;
        }

        if (typeof this.toPlanner.rooster_begin !== 'undefined') {

          if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date, this.fromPlanner) === false) {
            this.isPlanning = false;
            this.fromPlanner = [];
            this.setState({ selectedBar: '', editPlan: false, reloading: false });
            this.lastEdited = '';
            return;
          }

        } else {

          if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
            this.isPlanning = false;
            this.fromPlanner = [];
            this.setState({ selectedBar: '', editPlan: false, reloading: false });
            this.lastEdited = '';
            return;
          }

        }

        //this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const toPlanner = {
          rooster_datum: date,
          rooster_begin: this.fromPlanner.shifts[0].dagdienst_begin.substr(0, 5),
          rooster_eind: this.fromPlanner.shifts[0].dagdienst_eind.substr(0, 5),
          rooster_pauze: this.fromPlanner.shifts[0].dagdienst_pauze.substr(0, 5),
          functie_id: this.fromPlanner.shifts[0].functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
          functie_naam: this.fromPlanner.shifts[0].functie_naam
        }

        if (typeof this.toPlanner.rooster_begin !== 'undefined') {
          await APIDeleteUserPlanner.Request(this.toPlanner.rooster_id);
        }

        if (this.toPlanner.rooster_publiceren === '1') {
          KodictNotifications.sendPushNotification(this.toPlanner.info_id, 'planner', 'Planner', 'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten');
        }

        const added = await this.planUser(date, this.toPlanner, toPlanner);

        if(added !== null) {

          // copy bijzonderheden and competenties
          const partDayShift = this.state.partDayShifts['d' + this.fromPlanner.shifts[0].dagdienst_id];
          if (typeof partDayShift !== 'undefined') {
            await APIAddPartSchedule.Request(partDayShift.bijz_id, added);
            const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
            this.setState({ partSchedules: partSchedulesObj }); 
          }
          const skillsDayShift = this.state.skillDayShifts['d' + this.fromPlanner.shifts[0].dagdienst_id];
          if (typeof skillsDayShift !== 'undefined') {
            for (const skill of skillsDayShift) {
              await APIAddSkillSchedule.Request(skill.comp_id, added);
            }
            const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            this.setState({ skillSchedules: skillSchedulesObj }); 
          }

          //await APIAddUsedDayShift.Request(date, toPlanner.rooster_begin, toPlanner.rooster_eind, added, toPlanner.functie_id);
          await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${this.fromPlanner.shifts[0].dagdienst_id}, ${added})`);
          await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(this.toPlanner, toPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      } else { //  to av, not filled, unav to planned shift

        if (Plan.checkInfoExtraAdd(this.state.infoExtra[this.toPlanner.info_id], this.toPlanner.info_id, date, this.fromPlanner, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
          this.isPlanning = false;
          this.fromPlanner = [];
          this.setState({ selectedBar: '', editPlan: false, reloading: false });
          this.lastEdited = '';
          return;
        }

        //this.setState({ selectedBar: '' });
        this.lastEdited = '';
        this.isPlanning = true;

        const [
          deleted,
          added,
        ] = await Promise.all([
          this.state.copyShifts !== true ? Plan.deleteUserPlanner(this.fromPlanner) : false,
          this.planUser(date, this.toPlanner, this.fromPlanner),
        ]);

        // if (this.state.copyShifts !== true) {
        //   await Plan.deleteUserPlanner(this.fromPlanner);
        // }

        // const added = await this.planUser(date, this.toPlanner, this.fromPlanner);

        if(added !== null) {

          let promises = [];

          const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];

          if (typeof skillSchedule !== 'undefined') {
            for (const skill of skillSchedule) {
              if (this.state.copyShifts !== true) {
                promises.push(APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id));
              }
              promises.push(APIAddSkillSchedule.Request(skill.comp_id, added));
            }
          }

          if (typeof partSchedule !== 'undefined') {
            if (this.state.copyShifts !== true) {
              promises.push(APIDeletePartSchedule.Request(this.fromPlanner.rooster_id));
            }
            promises.push(APIAddPartSchedule.Request(partSchedule.bijz_id, added));
          }

          let dayShiftID = null;
          for (const ds of this.usedDayShiftsModern) {
            if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
              dayShiftID = ds.gdm_dagdienst_id;
              break;
            }
          }

          if (dayShiftID !== null) {
            promises.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`));
          }

          promises.push(this.putOpenShiftOnUnavailable(this.fromPlanner));

          await Promise.all(promises);

          if (typeof skillSchedule !== 'undefined' || typeof partSchedule !== 'undefined') {

            const [
              skillSchedules,
              partSchedules,
            ] = await Promise.all(
            [
              APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate),
              APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate),
            ]);

            const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
            const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

            this.setState({ skillSchedules: skillSchedulesObj, partSchedules: partSchedulesObj });

          }

          // // remove and add skills to new rooster_id
          // const skillSchedule = this.state.skillSchedules[this.fromPlanner.rooster_id];
          // if (typeof skillSchedule !== 'undefined') {
          //   for (const skill of skillSchedule) {
          //     await APIDeleteSkillSchedule.Request(skill.comp_id, this.fromPlanner.rooster_id);
          //     await APIAddSkillSchedule.Request(skill.comp_id, added);
          //   }
          //   const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          //   this.setState({ skillSchedules: skillSchedulesObj });
          // }

          // // remove and add part to new rooster_ID
          // const partSchedule = this.state.partSchedules[this.fromPlanner.rooster_id];
          // if (typeof partSchedule !== 'undefined') {
          //   await APIDeletePartSchedule.Request(this.fromPlanner.rooster_id);
          //   await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          //   const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          //   const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          //   this.setState({ partSchedules: partSchedulesObj });
          // }

          // //await APIAddUsedDayShift.Request(date, this.fromPlanner.rooster_begin, this.fromPlanner.rooster_eind, added, this.fromPlanner.functie_id);
          // let dayShiftID = null;
          // for (const ds of this.usedDayShiftsModern) {
          //   if(ds.gdm_rooster_id === this.fromPlanner.rooster_id) {
          //     dayShiftID = ds.gdm_dagdienst_id;
          //     break;
          //   }
          // }
          // if (dayShiftID !== null) {
          //   await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${dayShiftID}, ${added})`);
          // }
          // await this.putOpenShiftOnUnavailable(this.fromPlanner);
          Logger.addPlannerClick(this.toPlanner, this.fromPlanner);
                    // await this.reloadScreen(date2);
          // if (date !== date2) {
          //   this.reloadScreen(date);
          // }
          if (date !== date2) {
            await this.reloadScreen_twoDates(date, date2);
          } else {
            await this.reloadScreen(date);
          }
        } else {
          //  console.log('error');
        }

      }

      return;

    }

  }

  onDragOver(e, i) {

    let day = i === 6 ? 0 : i + 1;

    if(this.fromPlannerDay === day) {
      e.preventDefault();
    }

    e.target.style.opacity = 0.2;
    e.currentTarget.style.opacity = 0.2;

  }

  onDragOverOther(e, i) {
    e.preventDefault();
    e.currentTarget.style.opacity = 0.2;
    e.target.style.opacity = 0.2;
  }

  onDragLeave(e) {
    e.preventDefault();
    e.currentTarget.style.opacity = null;
    e.target.style.opacity = null;
  }

  onFocusOther(e, data) {

    if (typeof data !== 'undefined' && data !== null && (typeof data.functie_naam === 'undefined' || data.functie_naam === null)) {
      e.target.blur();
      e.preventDefault();
      Constants.defaultAlert(this, 'Melding', 'Deze medewerker heeft geen functie toegewezen gekregen. Klik op het pijltje naast zijn/haar naam om een functie toe te wijzen. Probeer het vervolgens opnieuw')
      return false;
    }

    this.fromPlanner = [];
    this.setState({ selectedBar: '' }); 

  }

  async duplicateShift(data) {

    if (this.state.loading || this.state.reloading) {
      return;
    }

    this.closeAllPlanMenus();

    const dateObject = Constants.stringToDate(data.rooster_datum);

    if (data.rooster_publiceren === '3') { // is empty shift

      const added = await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${data.rooster_datum}', '${data.rooster_begin}', '${data.rooster_eind}', NULL, ${data.functie_id === null ? 'NULL' : data.functie_id}, 3, '${data.rooster_pauze}', 0)`);

      const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
        await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
      }

      const skillSchedule = this.state.skillSchedules[data.rooster_id];
      if (typeof skillSchedule !== 'undefined') {
        for (const skill of skillSchedule) {
          await APIAddSkillSchedule.Request(skill.comp_id, rid[0].rooster_id);
        }
      }

      if (typeof skillSchedule !== 'undefined') {
        const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
        const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
        this.setState({ skillSchedules: skillSchedulesObj });
      }

      if(added === true) {

        await this.reloadScreen(data.rooster_datum);
            
      } else {
        //  console.log('error');
      }

    } else {

      if (Plan.checkInfoExtraEditNoDayCheck(this.state.infoExtra[data.info_id], data.info_id, data.rooster_datum, data) === false) {
        return;
      }

      const added = await this.planUser(data.rooster_datum, data, data);

      if(added !== null) {
        this.setState({ editPlan: false });
        //await APIAddUsedDayShift.Request(data.rooster_datum, data.rooster_begin, data.rooster_eind, added, data.functie_id);

        // add skills to duplicated shift
        const skillSchedule = this.state.skillSchedules[data.rooster_id];
        if (typeof skillSchedule !== 'undefined') {
          for (const skill of skillSchedule) {
            await APIAddSkillSchedule.Request(skill.comp_id, added);
          }
          const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
          this.setState({ skillSchedules: skillSchedulesObj }); 
        }

        // add particularity to duplicated shift
        const partSchedule = this.state.partSchedules[data.rooster_id];
        if (typeof partSchedule !== 'undefined') {
          await APIAddPartSchedule.Request(partSchedule.bijz_id, added);
          const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
          const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');
          this.setState({ partSchedules: partSchedulesObj }); 
        }

        this.reloadScreen(data.rooster_datum);
      } else {
        //  console.log('error');
      }

    }

  }



  async reloadScreenDirectly(date) {

    this.setState({reloading: true});

    let dateObject = null;

    if (typeof date === 'object') {

      dateObject = date;

    } else {

      dateObject = Constants.stringToDate(date);

    }

    await this.getSpecificDateDBData2(dateObject);

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    for (const key in this.emptyPlannerData) {
      for (const plan of this.emptyPlannerData[key]) {
        planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
        showPlanMenu[plan.rooster_id] = false;
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];
    this.isPlanning = false;

    this.setAllAddEmptyRows(0);
    this.setAllAddEmptyRowsUser(0);

    this.setState({ 
      reloading: false, 
      selectedBar: '', 
      lastEdited: this.lastEdited,

      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  async reloadScreen(date) {

    this.setState({reloading: true});

    let dateObject = null;

    if (typeof date === 'object') {

      dateObject = date;

    } else {

      dateObject = Constants.stringToDate(date);

    }

    await this.getSpecificDateDBData2(dateObject);

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];
    this.isPlanning = false;

    this.setAllAddEmptyRows(0);
    this.setAllAddEmptyRowsUser(0);

    this.setState({ 
      reloading: false, selectedBar: '', lastEdited: this.lastEdited,

      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  async reloadScreen_twoDates(date, date2) {

    this.setState({reloading: true});

    let dateObject = null;
    let dateObject2 = null;

    if (typeof date === 'object') {
      dateObject = date;
    } else {
      dateObject = Constants.stringToDate(date);
    }

    if (typeof date2 === 'object') {
      dateObject2 = date2;
    } else {
      dateObject2 = Constants.stringToDate(date2);
    }

    await Promise.all([
      this.getSpecificDateDBData2(dateObject),
      this.getSpecificDateDBData2(dateObject2)
    ]);

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];
    this.isPlanning = false;

    this.setAllAddEmptyRows(0);
    this.setAllAddEmptyRowsUser(0);

    this.setState({ 
      reloading: false, selectedBar: '', lastEdited: this.lastEdited,
  
      planInputs: planInputs,
      showPlanMenu: showPlanMenu,
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  async reloadScreen2() {

    this.setState({reloading: true});

    this.WEEK = this.state.week;
    this.YEAR = this.state.year;

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];
    this.isPlanning = false;

    this.setAllAddEmptyRows(0);
    this.setAllAddEmptyRowsUser(0);

    this.setState({
        reloading: false, 
        selectedBar: '', 
        lastEdited: this.lastEdited, 
        showUndoButton: JSON.stringify(this.plannerData) !== JSON.stringify(this.plannerDataBackup), 

        planInputs: planInputs,
        showPlanMenu: showPlanMenu,
        showPlanMenuInfo: showPlanMenuInfo,
        showPlanMenuEdit: showPlanMenuEdit,
        showPlanMenuSpecial: showPlanMenuSpecial,
        showPlanMenuAbsence: showPlanMenuAbsence,
        showPlanMenuComp: showPlanMenuComp,
        showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  async reloadScreen2FullScreen() {

    this.setState({loading: true});

    this.WEEK = this.state.week;
    this.YEAR = this.state.year;

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    // SET plan input data
    let planInputs = this.state.planInputs;
    let showPlanMenu = this.state.showPlanMenu;
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
            planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
            showPlanMenu[plan.rooster_id] = false;
            showPlanMenuInfo[plan.rooster_id] = false;
            showPlanMenuEdit[plan.rooster_id] = false;
            showPlanMenuSpecial[plan.rooster_id] = false;
            showPlanMenuAbsence[plan.rooster_id] = false;
            showPlanMenuComp[plan.rooster_id] = false;
            showPlanMenuBreaks[plan.rooster_id] = false;
        }
    }

    for (const key in this.repeatingShifts) {
      for (const r of this.repeatingShifts[key]) {
        for (let i = 0; i < 7; i++) {
          showPlanMenu['rs' + r.hr_id + i] = false;
        }
      }
    }

    this.fromPlannerDay = -1;
    this.fromPlanner = [];
    this.toPlanner = [];
    this.isPlanning = false;

    this.setAllAddEmptyRows(0);
    this.setAllAddEmptyRowsUser(0);

    this.setState({
        loading: false, 
        selectedBar: '', 
        lastEdited: this.lastEdited,

        planInputs: planInputs,
        showPlanMenu: showPlanMenu,
        showPlanMenuInfo: showPlanMenuInfo,
        showPlanMenuEdit: showPlanMenuEdit,
        showPlanMenuSpecial: showPlanMenuSpecial,
        showPlanMenuAbsence: showPlanMenuAbsence,
        showPlanMenuComp: showPlanMenuComp,
        showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  async reloadScreen2FullScreenTeam() {

    this.setState({loading: true});

    this.WEEK = this.state.week;
    this.YEAR = this.state.year;

    // BREAKING CHANGES

    const mondayDate = this.getMonday(this.WEEK, this.YEAR);
    // mondayDate.setDate(this.getMonday(this.WEEK, this.YEAR).getDate() + 1);

    const tuesdayDate = this.getTuesday(this.WEEK, this.YEAR);
    // tuesdayDate.setDate(this.getTuesday(this.WEEK, this.YEAR).getDate() + 1);

    const wednesdayDate = this.getWednesday(this.WEEK, this.YEAR);
    // wednesdayDate.setDate(this.getWednesday(this.WEEK, this.YEAR).getDate() + 1);

    const thursdayDate = this.getThursday(this.WEEK, this.YEAR);
    // thursdayDate.setDate(this.getThursday(this.WEEK, this.YEAR).getDate() + 1);

    const fridayDate = this.getFriday(this.WEEK, this.YEAR);
    // fridayDate.setDate(this.getFriday(this.WEEK, this.YEAR).getDate() + 1);

    const saturdayDate = this.getSaturday(this.WEEK, this.YEAR);
    // saturdayDate.setDate(this.getSaturday(this.WEEK, this.YEAR).getDate() + 1);

    const sundayDate = this.getSunday(this.WEEK, this.YEAR);
    // sundayDate.setDate(this.getSunday(this.WEEK, this.YEAR).getDate() + 1);

    // SET SOME STUFF TO NULL
    this.sectionsData = [];
    this.holidayDaysData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };
    this.functionData = [];


    // GET USERS AND SHIFT TEMP
    const [
        shiftTemplates,
        functionsArrange,
    ] = await Promise.all([
        APIGetDayShiftTemplates.Request(),
        APIGET.Request(`SELECT fv_functie_id, fv_index`, `FROM FunctieVolgorde`, null, null, null, `ORDER BY fv_index`),
    ]);

    this.functionsArrange = functionsArrange;

    let newShiftTemplates = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const temp of shiftTemplates) {
        if (temp.dagdienstsjabloon_team_id === Data.data.chosenTeamObj.team_id) {
          newShiftTemplates.push(temp);
        }
      }
    } else {
      newShiftTemplates = shiftTemplates;
    }

    const chosenTemp = newShiftTemplates.length > 0 ? newShiftTemplates[0] : null;

    this.setState({

      shiftTemplates: newShiftTemplates,
      shiftTemp: chosenTemp !== null ? parseInt(chosenTemp.dagdienstsjabloon_id) : null,
      shiftTempName: chosenTemp !== null ? chosenTemp.dagdienstsjabloon_naam : 'Selecteer een sjabloon',

    }, async() => {

      await this.getDBData(mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate);

    // GET FIRST LOADING STUFF
    const [
        _getSections,
        _getAllHolidays,
        _getFunctions,

        skills,
        particularities,
        absencetypes,
        skillsUsers,
        breaks,
        secondaryFunctions,
        publicHolidays,
    ] = await Promise.all([
        this.getSections(),
        this.getAllHolidays(),
        this.getFunctions(),

        APIGetSkills.Request(),
        APIGetParticular.Request(),
        APIGetAbsencetypes.Request(),
        APIGetAllSkillsUsers.Request(),
        APIGET.Request(`SELECT *`, `FROM Breaks`, null, null, null, `ORDER BY brk_tijd`),
        APIGET.Request(`SELECT functie_id, functie_naam, functie_kleur, fe_info_id`, `FROM FunctieExtra`, `LEFT JOIN Functie ON functie_id = fe_functie_id`, null, null, null),
        APIGET.Request(`SELECT *`, `FROM Feestdag`, null, `WHERE YEAR(f_datum) >= ${mondayDate.getFullYear()}`, null, `ORDER BY f_datum`),
    ]);

    // SET SOME OBJECTS AND OTHER STUFF
    this.functionsArrange = functionsArrange;
    const skillsUsersObj = Constants.convertToObjectWithArrays(skillsUsers, 'info_id');
    const secondaryFunctionsObj = Constants.convertToObjectWithArrays(secondaryFunctions, 'fe_info_id');

    // NO IDEA
    if(this.userHoursScheduleID !== null) {
      this.setState({
        userHoursScheduleID: parseInt(this.userHoursScheduleID),
      });
      setTimeout(() => { 
        this.setState({
          userHoursScheduleID: null,
        });
      }, 5000);
    }

      // SET plan input data
      let planInputs = this.state.planInputs;
      let showPlanMenu = this.state.showPlanMenu;
      let showPlanMenuInfo = this.state.showPlanMenuInfo;
      let showPlanMenuEdit = this.state.showPlanMenuEdit;
      let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
      let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
      let showPlanMenuComp = this.state.showPlanMenuComp;
      let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

      for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;        
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
      }

      for (const key in this.emptyPlannerData) {
        for (const plan of this.emptyPlannerData[key]) {
          planInputs[plan.rooster_id] = `${plan.rooster_begin.substr(0, 5)} - ${plan.rooster_eind.substr(0, 5)}`;
          showPlanMenu[plan.rooster_id] = false;
          showPlanMenuInfo[plan.rooster_id] = false;
          showPlanMenuEdit[plan.rooster_id] = false;
          showPlanMenuSpecial[plan.rooster_id] = false;
          showPlanMenuAbsence[plan.rooster_id] = false;
          showPlanMenuComp[plan.rooster_id] = false;
          showPlanMenuBreaks[plan.rooster_id] = false;
        }
      }

      for (const key in this.repeatingShifts) {
        for (const r of this.repeatingShifts[key]) {
          for (let i = 0; i < 7; i++) {
            showPlanMenu['rs' + r.hr_id + i] = false;
          }
        }
      }
    
      this.setState({
        planInputs: planInputs,
        showPlanMenu: showPlanMenu,
        showPlanMenuInfo: showPlanMenuInfo,
        showPlanMenuEdit: showPlanMenuEdit,
        showPlanMenuSpecial: showPlanMenuSpecial,
        showPlanMenuAbsence: showPlanMenuAbsence,
        showPlanMenuComp: showPlanMenuComp,
        showPlanMenuBreaks: showPlanMenuBreaks,

        loading: false,
      });

    });

  }

  onClickArrowUserMenu(i, key, data) {

    const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

    this.fromPlanner = [];
    this.setState({ selectedBar: '' });

    if (this.state.showUserMenu[dayName + data[dayName][key].info_id] === true) {
      this.closeUserMenu(dayName + data[dayName][key].info_id);
    } else {
      this.openUserMenu(dayName + data[dayName][key].info_id);
    }

  } 

  collapseFunction(id) {

    let newCollapsedFunctions = this.state.collapsedFunctions;

    newCollapsedFunctions.push(id);

    this.setState({
      collapsedFunctions: newCollapsedFunctions,
    });

  }

  expandFunction(id) {

    let newCollapsedFunctions = this.state.collapsedFunctions;

    Constants.removeFromArray(newCollapsedFunctions, id);

    this.setState({
      collapsedFunctions: newCollapsedFunctions,
    });
    
  }

  getSchoolHolidayBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.schoolHolidayData.mon.length) {
      maxNotFilled = this.schoolHolidayData.mon.length;
    }
    if(maxNotFilled < this.schoolHolidayData.tue.length) {
      maxNotFilled = this.schoolHolidayData.tue.length;
    }
    if(maxNotFilled < this.schoolHolidayData.wed.length) {
      maxNotFilled = this.schoolHolidayData.wed.length;
    }
    if(maxNotFilled < this.schoolHolidayData.thu.length) {
      maxNotFilled = this.schoolHolidayData.thu.length;
    }
    if(maxNotFilled < this.schoolHolidayData.fri.length) {
      maxNotFilled = this.schoolHolidayData.fri.length;
    }
    if(maxNotFilled < this.schoolHolidayData.sat.length) {
      maxNotFilled = this.schoolHolidayData.sat.length;
    }
    if(maxNotFilled < this.schoolHolidayData.sun.length) {
      maxNotFilled = this.schoolHolidayData.sun.length;
    }

    if(this.schoolHolidayData.mon.length === 0 && this.schoolHolidayData.tue.length === 0 && this.schoolHolidayData.wed.length === 0
      && this.schoolHolidayData.thu.length === 0 && this.schoolHolidayData.fri.length === 0 && this.schoolHolidayData.sat.length === 0
      && this.schoolHolidayData.sun.length === 0) {
        return [];
      }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.schoolHolidayData.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greenFilledIn)}}>Schoolvakantie</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.schoolHolidayData.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.schoolHolidayData.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.schoolHolidayData.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.schoolHolidayData.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.schoolHolidayData.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.schoolHolidayData.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}></div>);
      }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

          // // has contract
          // if (typeof this.state.contractsObj[availableDataDay[key].info_id] !== 'undefined') {
          //   const contract = this.state.contractsObj[availableDataDay[key].info_id];
          //   // has valid end date
          //   if (Constants.isValidDateString(contract.contract_eind)) {
          //     const endDate = Constants.stringToDate(contract.contract_eind);
  
          //     if (i === 0 && Constants.formatDate(endDate) <= this.state.mondayDate) {
          //       contractExpired = true;
          //     } else if (i === 1 && Constants.formatDate(endDate) <= this.state.tuesdayDate) {
          //       contractExpired = true;
          //     } else if (i === 2 && Constants.formatDate(endDate) <= this.state.wednesdayDate) {
          //       contractExpired = true;
          //     } else if (i === 3 && Constants.formatDate(endDate) <= this.state.thursdayDate) {
          //       contractExpired = true;
          //     } else if (i === 4 && Constants.formatDate(endDate) <= this.state.fridayDate) {
          //       contractExpired = true;
          //     } else if (i === 5 && Constants.formatDate(endDate) <= this.state.saturdayDate) {
          //       contractExpired = true;
          //     } else if (i === 6 && Constants.formatDate(endDate) <= this.state.sundayDate) {
          //       contractExpired = true;
          //     }

          //   }
          // }
        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);
        const hoursWeek2 = Constants.round(hoursWeek / 1000 / 60 / 60);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'a' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartSchoolHoliday(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     //onClick={() => this.onClickSchoolHoliday(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greenFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+availableDataDay[key].info_id}`} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>

          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickSchoolHoliday(i, key)}>

          //         <div>Schoolvakantie</div>
    
          //     </div>

          //     <div className='planner_nameContainer' onClick={() => this.onClickSchoolHoliday(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickSchoolHoliday(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.schoolHolidayData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?
    
          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

          contractExpired === true ?

            <div key={i + 'a' + key} className='plannerN_shiftOuter'>

              <div 
                className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
                style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
              >

                <div className='plannerN_shiftColumn1Outer'>

                    <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                      <i className='fal fa-file-signature' />

                    </div>

                  </div>

                <div className='plannerN_shiftColumn2'>

                  <div className='plannerN_shiftRow'>
                                                      
                    <span style={{color: '#04163b'}}>Contract verlopen</span>

                  </div>

                  <div className='plannerN_shiftRow'>

                    <div style={{width: '95%'}}>
                      <span className='plannerN_nameText'>{userFullName}</span>
                      <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                    </div>

                  </div>

                </div>

                <div className='plannerN_shiftColumn3'>

                  <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.schoolHolidayData)} />

                </div>

              </div>

              {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

                this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

              : null}

            </div>

          :

          <div key={i + 'a' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.2)}}
              draggable={'true'}
              onDragStart={(event) => this.onDragStartSchoolHoliday(event, i === 6 ? 0 : i + 1, key)}
              onClick={(event) => this.onClickSchoolHoliday(i, key, event)}
            >

              {availableDataDay[key].functie_naam !== null ?

                <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                    </Tooltip>

                  </div>

                </div>

              :

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>-</span>
                  </Tooltip>

                </div>

              </div>

              }

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                    
                  <span style={{color: '#04163b'}}>Schoolvakantie</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>

                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.schoolHolidayData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getSchoolScheduleBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.schoolScheduleData.mon.length) {
      maxNotFilled = this.schoolScheduleData.mon.length;
    }
    if(maxNotFilled < this.schoolScheduleData.tue.length) {
      maxNotFilled = this.schoolScheduleData.tue.length;
    }
    if(maxNotFilled < this.schoolScheduleData.wed.length) {
      maxNotFilled = this.schoolScheduleData.wed.length;
    }
    if(maxNotFilled < this.schoolScheduleData.thu.length) {
      maxNotFilled = this.schoolScheduleData.thu.length;
    }
    if(maxNotFilled < this.schoolScheduleData.fri.length) {
      maxNotFilled = this.schoolScheduleData.fri.length;
    }
    if(maxNotFilled < this.schoolScheduleData.sat.length) {
      maxNotFilled = this.schoolScheduleData.sat.length;
    }
    if(maxNotFilled < this.schoolScheduleData.sun.length) {
      maxNotFilled = this.schoolScheduleData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.schoolScheduleData.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greyNotFilled)}}>School</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.schoolScheduleData.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.schoolScheduleData.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.schoolScheduleData.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.schoolScheduleData.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.schoolScheduleData.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.schoolScheduleData.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5)}}></div>);
      }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
          
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);
        const hoursWeek2 = Constants.round(hoursWeek / 1000 / 60 / 60);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'a' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartSchoolSchedule(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     //onClick={() => this.onClickSchoolSchedule(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greyNotFilled, backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+availableDataDay[key].info_id}`} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickSchoolSchedule(i, key)}>

          //         <div>{availableDataDay[key].schoolrooster_begin.substring(0,5)} - {availableDataDay[key].schoolrooster_eind.substring(0,5)}</div>
    
          //     </div>
              
          //     <div className='planner_nameContainer' onClick={() => this.onClickSchoolSchedule(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickSchoolSchedule(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.schoolScheduleData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?
    
          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'a' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>

                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.schoolScheduleData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'a' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.4)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartSchoolSchedule(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickSchoolSchedule(i, key, event)}
          >

            {availableDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                    </Tooltip>

                  </div>

                </div>

              :

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>-</span>
                  </Tooltip>

                </div>

              </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>

              <span style={{color: '#04163b'}}>{availableDataDay[key].schoolrooster_begin.substring(0,5)} - {availableDataDay[key].schoolrooster_eind.substring(0,5)}</span>

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>

              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.schoolScheduleData)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

            this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getStandardAvailableBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.standardAvailableData.mon.length) {
      maxNotFilled = this.standardAvailableData.mon.length;
    }
    if(maxNotFilled < this.standardAvailableData.tue.length) {
      maxNotFilled = this.standardAvailableData.tue.length;
    }
    if(maxNotFilled < this.standardAvailableData.wed.length) {
      maxNotFilled = this.standardAvailableData.wed.length;
    }
    if(maxNotFilled < this.standardAvailableData.thu.length) {
      maxNotFilled = this.standardAvailableData.thu.length;
    }
    if(maxNotFilled < this.standardAvailableData.fri.length) {
      maxNotFilled = this.standardAvailableData.fri.length;
    }
    if(maxNotFilled < this.standardAvailableData.sat.length) {
      maxNotFilled = this.standardAvailableData.sat.length;
    }
    if(maxNotFilled < this.standardAvailableData.sun.length) {
      maxNotFilled = this.standardAvailableData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.standardAvailableData.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greenFilledIn)}}>Beschikbaar</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.standardAvailableData.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.standardAvailableData.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.standardAvailableData.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.standardAvailableData.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.standardAvailableData.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.standardAvailableData.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}></div>);
      }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        // if (i === 0) {
        //   console.log(availableDataDay[key])
        // }

        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'sta' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartStandardAvailable(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     //onClick={() => this.onClickStandardAvailable(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greenFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickStandardAvailable(i, key)}>

          //         {availableDataDay[key].sb_begin === '00:00:00' && availableDataDay[key].sb_eind === '00:00:00' ?
          //           <div>Hele dag</div>
          //         :
          //           <div>{availableDataDay[key].sb_begin.substring(0,5)} - {availableDataDay[key].sb_eind.substring(0,5)}</div>
          //         }
    
          //     </div>
              
          //     <div className='planner_nameContainer' onClick={() => this.onClickStandardAvailable(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickStandardAvailable(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.standardAvailableData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?
    
          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}
                
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'sta' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                    
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.standardAvailableData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

          <div key={i + 'sta' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.2)}}
              draggable={'true'}
              onDragStart={(event) => this.onDragStartStandardAvailable(event, i === 6 ? 0 : i + 1, key)}
              onClick={(event) => this.onClickStandardAvailable(i, key, event)}
            >

              {availableDataDay[key].functie_naam !== null ?

                <div className='plannerN_shiftColumn1Outer'>

                    <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                      <Tooltip
                        options
                        html={(
                          <div className='plannerN_tooltipContainer'>
                            <i className='fal fa-briefcase'></i>
                            <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                          </div>
                        )}
                        position={'top'}
                        arrow
                        followCursor
                        theme='kodict'
                      >
                        <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                      </Tooltip>

                    </div>

                  </div>

                :

                <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>-</span>
                    </Tooltip>

                  </div>

                </div>

              }

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>

                {(availableDataDay[key].sb_begin === '00:00:00' && availableDataDay[key].sb_eind === '00:00:00') ?
                                                    
                  <span style={{color: '#04163b'}}>Hele dag</span>

                :

                  <span style={{color: '#04163b'}}>{availableDataDay[key].sb_begin.substring(0,5)} - {availableDataDay[key].sb_eind.substring(0,5)}</span>

                }

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>

                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.standardAvailableData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getStandardUnavailableBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.standardUnavailableData.mon.length) {
      maxNotFilled = this.standardUnavailableData.mon.length;
    }
    if(maxNotFilled < this.standardUnavailableData.tue.length) {
      maxNotFilled = this.standardUnavailableData.tue.length;
    }
    if(maxNotFilled < this.standardUnavailableData.wed.length) {
      maxNotFilled = this.standardUnavailableData.wed.length;
    }
    if(maxNotFilled < this.standardUnavailableData.thu.length) {
      maxNotFilled = this.standardUnavailableData.thu.length;
    }
    if(maxNotFilled < this.standardUnavailableData.fri.length) {
      maxNotFilled = this.standardUnavailableData.fri.length;
    }
    if(maxNotFilled < this.standardUnavailableData.sat.length) {
      maxNotFilled = this.standardUnavailableData.sat.length;
    }
    if(maxNotFilled < this.standardUnavailableData.sun.length) {
      maxNotFilled = this.standardUnavailableData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.standardUnavailableData.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.redFilledIn)}}>Onbeschikbaar</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.standardUnavailableData.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.standardUnavailableData.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.standardUnavailableData.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.standardUnavailableData.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.standardUnavailableData.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.standardUnavailableData.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5)}}></div>);
      }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';
        
        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'stu' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartStandardUnavailable(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickStandardUnavailable(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.redFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickStandardUnavailable(i, key)}>

          //         {availableDataDay[key].sb_begin === '00:00:00' && availableDataDay[key].sb_eind === '00:00:00' ?
          //           <div>Hele dag</div>
          //         :
          //           <div>{availableDataDay[key].sb_begin.substring(0,5)} - {availableDataDay[key].sb_eind.substring(0,5)}</div>
          //         }
    
          //     </div>
              
          //     <div className='planner_nameContainer' onClick={() => this.onClickStandardUnavailable(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickStandardUnavailable(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.standardUnavailableData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?
    
          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'stu' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.standardUnavailableData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'stu' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.2)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartStandardUnavailable(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickStandardUnavailable(i, key, event)}
          >

            {availableDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                    </Tooltip>

                  </div>

                </div>

              :

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>-</span>
                  </Tooltip>

                </div>

              </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>

              {(availableDataDay[key].sb_begin === '00:00:00' && availableDataDay[key].sb_eind === '00:00:00') ?
                                                  
                <span style={{color: '#04163b'}}>Hele dag</span>

              :

                <span style={{color: '#04163b'}}>{availableDataDay[key].sb_begin.substring(0,5)} - {availableDataDay[key].sb_eind.substring(0,5)}</span>

              }

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
                  
              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.standardUnavailableData)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

            this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getStandardNotFilledBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.standardNotFilledData.mon.length) {
      maxNotFilled = this.standardNotFilledData.mon.length;
    }
    if(maxNotFilled < this.standardNotFilledData.tue.length) {
      maxNotFilled = this.standardNotFilledData.tue.length;
    }
    if(maxNotFilled < this.standardNotFilledData.wed.length) {
      maxNotFilled = this.standardNotFilledData.wed.length;
    }
    if(maxNotFilled < this.standardNotFilledData.thu.length) {
      maxNotFilled = this.standardNotFilledData.thu.length;
    }
    if(maxNotFilled < this.standardNotFilledData.fri.length) {
      maxNotFilled = this.standardNotFilledData.fri.length;
    }
    if(maxNotFilled < this.standardNotFilledData.sat.length) {
      maxNotFilled = this.standardNotFilledData.sat.length;
    }
    if(maxNotFilled < this.standardNotFilledData.sun.length) {
      maxNotFilled = this.standardNotFilledData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.standardNotFilledData.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greyNotFilled)}}>Niks ingevuld</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.standardNotFilledData.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.standardNotFilledData.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.standardNotFilledData.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.standardNotFilledData.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.standardNotFilledData.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.standardNotFilledData.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5)}}></div>);
      }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
         
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'stn' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartStandardNotFilled(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickStandardNotFilled(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greyNotFilled, backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickStandardNotFilled(i, key)}>

          //       <div>n.v.t.</div>
    
          //     </div>
              
          //     <div className='planner_nameContainer' onClick={() => this.onClickStandardNotFilled(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickStandardNotFilled(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.standardNotFilledData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?
    
          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'stn' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.standardNotFilledData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'stn' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.4)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartStandardNotFilled(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickStandardNotFilled(i, key, event)}
          >

            {availableDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                    </Tooltip>

                  </div>

                </div>

              :

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>-</span>
                  </Tooltip>

                </div>

              </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>
                                                  
                <span style={{color: '#04163b'}}>n.v.t.</span>

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
                  
              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.standardNotFilledData)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

            this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getAvailableBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.availableData.mon.length) {
      maxNotFilled = this.availableData.mon.length;
    }
    if(maxNotFilled < this.availableData.tue.length) {
      maxNotFilled = this.availableData.tue.length;
    }
    if(maxNotFilled < this.availableData.wed.length) {
      maxNotFilled = this.availableData.wed.length;
    }
    if(maxNotFilled < this.availableData.thu.length) {
      maxNotFilled = this.availableData.thu.length;
    }
    if(maxNotFilled < this.availableData.fri.length) {
      maxNotFilled = this.availableData.fri.length;
    }
    if(maxNotFilled < this.availableData.sat.length) {
      maxNotFilled = this.availableData.sat.length;
    }
    if(maxNotFilled < this.availableData.sun.length) {
      maxNotFilled = this.availableData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.availableData.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greenFilledIn)}}>Beschikbaar</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.availableData.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.availableData.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.availableData.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.availableData.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.availableData.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.availableData.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}></div>);
      }

      // let fid = null;

      // if (availableDataDay.length === 0) {
      //   availableDataDayBars.push(
      //     <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '1vw', paddingLeft: 4, marginTop: 5, marginBottom: 5}}>
      //       <span>&nbsp;</span>
      //     </div>
      //   );
      // }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
       
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        // // function names
        // if (this.state.sortBy === 'job' && availableDataDay[key].functie_id !== fid) {

        //   if (i === 0) {

        //     if (availableDataDay[key].functie_id === null) {
    
        //       availableDataDayBars.push(
        //         <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '1vw', paddingLeft: 4, marginTop: 5, marginBottom: 5}}>
        //           <span style={{fontSize: '0.7vw'}}>Geen functie</span>
        //         </div>
        //       );
    
        //     } else {
    
        //       availableDataDayBars.push(
        //         <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '1vw', paddingLeft: 4, marginTop: 5, marginBottom: 5}}>
        //           <span style={{fontSize: '0.7vw'}}>{availableDataDay[key].functie_naam}</span>
        //         </div>
        //       );
    
        //     }

        //   } else {

        //     availableDataDayBars.push(
        //       <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '1vw', paddingLeft: 4, marginTop: 5, marginBottom: 5}}>
        //         <span>&nbsp;</span>
        //       </div>
        //     );

        //   }
  
        //   fid = availableDataDay[key].functie_id;
  
        // }

        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'a' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartAvailable(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickAvailable(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greenFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+availableDataDay[key].info_id}`} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>

          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickAvailable(i, key)}>
    
          //       {(availableDataDay[key].beschikbaar_begin === '00:00:00' && availableDataDay[key].beschikbaar_eind === '00:00:00') ?
                                                  
          //         <div>Hele dag</div>

          //       :

          //         <div>{availableDataDay[key].beschikbaar_begin.substring(0,5)} - {availableDataDay[key].beschikbaar_eind.substring(0,5)}</div>

          //       }
    
          //     </div>

          //     <div className='planner_nameContainer' onClick={() => this.onClickAvailable(i, key)}>
      
          //       <div className='planner_nameText'>{userFullName}</div>

          //     </div>

          //     </div>

          //     {availableDataDay[key].beschikbaar_opmerking !== '' && availableDataDay[key].beschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay[key].beschikbaar_opmerking) === false ?

          //       <Tooltip
          //         options
          //         html={(
          //           <div className='planner_commentContainer'>
          //             <i className='fas fa-comment'></i>
          //             <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].beschikbaar_opmerking}</span>
          //           </div>
          //         )}
          //         position={'top'}
          //         arrow
          //         followCursor
          //         theme='kodict'
          //         className='planner_iconContainer'
          //         onClick={() => this.onClickAvailable(i, key)}
          //       >
      
          //         {

          //         availableDataDay[key].beschikbaar_opmerking !== '' && availableDataDay[key].beschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay[key].beschikbaar_opmerking) === false ?

          //           <i className='far fa-comment' style={{color: '#47B7EB', marginTop: '1px'}} data-tip data-for={`commentTooltip${key+i+availableDataDay[key].info_id}available`}></i>

          //         :

          //           null

          //         }

          //       </Tooltip>

          //     : null}

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.availableData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}

          //   </div>

          // </div>

          contractExpired === true ?

            <div key={i + 'a' + key} className='plannerN_shiftOuter'>

              <div 
                className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
                style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
              >

                <div className='plannerN_shiftColumn1Outer'>

                    <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                      <i className='fal fa-file-signature' />

                    </div>

                  </div>

                <div className='plannerN_shiftColumn2'>

                  <div className='plannerN_shiftRow'>
                                                      
                    <span style={{color: '#04163b'}}>Contract verlopen</span>

                  </div>

                  <div className='plannerN_shiftRow'>

                    <div style={{width: '95%'}}>
                      <span className='plannerN_nameText'>{userFullName}</span>
                      <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                    </div>
                  
                  </div>

                </div>

                <div className='plannerN_shiftColumn3'>

                  <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.availableData)} />

                </div>

              </div>

              {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

                this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

              : null}

            </div>

          :

          <div key={i + 'a' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.2)}}
              draggable={'true'}
              onDragStart={(event) => this.onDragStartAvailable(event, i === 6 ? 0 : i + 1, key)}
              onClick={(event) => this.onClickAvailable(i, key, event)}
            >

              {availableDataDay[key].functie_naam !== null ?

                <div className='plannerN_shiftColumn1Outer'>

                    <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                      <Tooltip
                        options
                        html={(
                          <div className='plannerN_tooltipContainer'>
                            <i className='fal fa-briefcase'></i>
                            <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                          </div>
                        )}
                        position={'top'}
                        arrow
                        followCursor
                        theme='kodict'
                      >
                        <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                      </Tooltip>

                    </div>

                  </div>

                :

                <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>-</span>
                    </Tooltip>

                  </div>

                </div>

              }

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>

                {(availableDataDay[key].beschikbaar_begin === '00:00:00' && availableDataDay[key].beschikbaar_eind === '00:00:00') ?
                                                  
                  <span style={{color: '#04163b'}}>Hele dag</span>

                :

                  <span style={{color: '#04163b'}}>{availableDataDay[key].beschikbaar_begin.substring(0,5)} - {availableDataDay[key].beschikbaar_eind.substring(0,5)}</span>

                }

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                {availableDataDay[key].beschikbaar_opmerking !== '' && availableDataDay[key].beschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay[key].beschikbaar_opmerking) === false ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fas fa-comment'></i>
                        <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].beschikbaar_opmerking}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginRight: 5}}
                  >
                    <i className='far fa-comment' style={{color: '#47B7EB'}} />
                  </Tooltip>
                : null}

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.availableData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getNotFilledSchoolBars() {

    let availableDataDay = null;
    let availableDataDayBars = null;

    let availableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.notFilledSchool.mon.length) {
      maxNotFilled = this.notFilledSchool.mon.length;
    }
    if(maxNotFilled < this.notFilledSchool.tue.length) {
      maxNotFilled = this.notFilledSchool.tue.length;
    }
    if(maxNotFilled < this.notFilledSchool.wed.length) {
      maxNotFilled = this.notFilledSchool.wed.length;
    }
    if(maxNotFilled < this.notFilledSchool.thu.length) {
      maxNotFilled = this.notFilledSchool.thu.length;
    }
    if(maxNotFilled < this.notFilledSchool.fri.length) {
      maxNotFilled = this.notFilledSchool.fri.length;
    }
    if(maxNotFilled < this.notFilledSchool.sat.length) {
      maxNotFilled = this.notFilledSchool.sat.length;
    }
    if(maxNotFilled < this.notFilledSchool.sun.length) {
      maxNotFilled = this.notFilledSchool.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        availableDataDay = this.notFilledSchool.mon;
        availableDataDayBars = availableBars.mon;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greenFilledIn)}}>Geen school</span></div>)
      }
      if(i === 1) {
        availableDataDay = this.notFilledSchool.tue;
        availableDataDayBars = availableBars.tue;
      }
      if(i === 2) {
        availableDataDay = this.notFilledSchool.wed;
        availableDataDayBars = availableBars.wed;
      }
      if(i === 3) {
        availableDataDay = this.notFilledSchool.thu;
        availableDataDayBars = availableBars.thu;
      }
      if(i === 4) {
        availableDataDay = this.notFilledSchool.fri;
        availableDataDayBars = availableBars.fri;
      }
      if(i === 5) {
        availableDataDay = this.notFilledSchool.sat;
        availableDataDayBars = availableBars.sat;
      }
      if(i === 6) {
        availableDataDay = this.notFilledSchool.sun;
        availableDataDayBars = availableBars.sun;
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        availableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}></div>);
      }

      for(let key in availableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[availableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(availableDataDay[key].info_id);

        const userFullName = `${availableDataDay[key].info_voornaam} ${(availableDataDay[key].info_tussenvoegsel !== null && availableDataDay[key].info_tussenvoegsel !== '') ? availableDataDay[key].info_tussenvoegsel + " " + availableDataDay[key].info_achternaam.charAt(0) : availableDataDay[key].info_achternaam.charAt(0)}.`;

        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        availableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'a' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartNotFilledSchool(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickNotFilledSchool(i, key)}
          //     className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greenFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{availableDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+availableDataDay[key].info_id}`} style={parseInt(availableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${availableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>

          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickNotFilledSchool(i, key)}>

          //       <div>n.v.t.</div>
    
          //     </div>
    
          //     <div className='planner_nameContainer' onClick={() => this.onClickNotFilledSchool(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickNotFilledSchool(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.notFilledSchool)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?
    
          //       this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'sn' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.notFilledSchool)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

              this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'sn' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === availableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.2)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartNotFilledSchool(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickNotFilledSchool(i, key, event)}
          >

            {availableDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>{availableDataDay[key].functie_naam}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>{availableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                  </Tooltip>

                </div>

              </div>

            :

            <div className='plannerN_shiftColumn1Outer'>

              <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5)}}>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-briefcase'></i>
                      <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <span>-</span>
                </Tooltip>

              </div>

            </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>
                                                  
                <span style={{color: '#04163b'}}>n.v.t.</span>

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
                  
              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.notFilledSchool)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + availableDataDay[key].info_id] === true ?

            this.renderUserMenu(availableDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = availableDataDay.length;
      while(j < maxNotFilled) {
        availableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return availableBars;

  }

  getNotFilledBars() {

    let notFilledDataDay = null;
    let notFilledDataDayBars = null;

    let notFilledBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.notFilledData.mon.length) {
      maxNotFilled = this.notFilledData.mon.length;
    }
    if(maxNotFilled < this.notFilledData.tue.length) {
      maxNotFilled = this.notFilledData.tue.length;
    }
    if(maxNotFilled < this.notFilledData.wed.length) {
      maxNotFilled = this.notFilledData.wed.length;
    }
    if(maxNotFilled < this.notFilledData.thu.length) {
      maxNotFilled = this.notFilledData.thu.length;
    }
    if(maxNotFilled < this.notFilledData.fri.length) {
      maxNotFilled = this.notFilledData.fri.length;
    }
    if(maxNotFilled < this.notFilledData.sat.length) {
      maxNotFilled = this.notFilledData.sat.length;
    }
    if(maxNotFilled < this.notFilledData.sun.length) {
      maxNotFilled = this.notFilledData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        notFilledDataDay = this.notFilledData.mon;
        notFilledDataDayBars = notFilledBars.mon;
      notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Data.data.appOptions.availability === 'UNAVAILABILITY' ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greyNotFilled)}}>{Data.data.appOptions.availability === 'UNAVAILABILITY' ? 'Beschikbaar' : 'Niet ingevuld'}</span></div>)
      }
      if(i === 1) {
        notFilledDataDay = this.notFilledData.tue;
        notFilledDataDayBars = notFilledBars.tue;
      }
      if(i === 2) {
        notFilledDataDay = this.notFilledData.wed;
        notFilledDataDayBars = notFilledBars.wed;
      }
      if(i === 3) {
        notFilledDataDay = this.notFilledData.thu;
        notFilledDataDayBars = notFilledBars.thu;
      }
      if(i === 4) {
        notFilledDataDay = this.notFilledData.fri;
        notFilledDataDayBars = notFilledBars.fri;
      }
      if(i === 5) {
        notFilledDataDay = this.notFilledData.sat;
        notFilledDataDayBars = notFilledBars.sat;
      }
      if(i === 6) {
        notFilledDataDay = this.notFilledData.sun;
        notFilledDataDayBars = notFilledBars.sun;
        notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Data.data.appOptions.availability === 'UNAVAILABILITY' ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Data.data.appOptions.availability === 'UNAVAILABILITY' ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5)}}></div>);
      }

      for(let key in notFilledDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
     
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(notFilledDataDay[key].info_id);

        const userFullName = `${notFilledDataDay[key].info_voornaam} ${(notFilledDataDay[key].info_tussenvoegsel !== null && notFilledDataDay[key].info_tussenvoegsel !== '') ? notFilledDataDay[key].info_tussenvoegsel + " " + notFilledDataDay[key].info_achternaam.charAt(0) : notFilledDataDay[key].info_achternaam.charAt(0)}.`;
  
        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        notFilledDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'n' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartNotFilled(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickNotFilled(i, key)}
          //     className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greyNotFilled, backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{notFilledDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+notFilledDataDay[key].info_id}`} style={parseInt(notFilledDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${notFilledDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>
                  
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickNotFilled(i, key)}>
    
          //       <div>n.v.t.</div>
    
          //     </div>
              
          //     <div className='planner_nameContainer' onClick={() => this.onClickNotFilled(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickNotFilled(i, key)}>
    
          //       {null}
    
          //     </div>

          //     {/* <div className='planner_arrowContainer' onClick={() => this.state.showUserMenu[notFilledDataDay[key].info_id] ? this.closeUserMenu(notFilledDataDay[key].info_id) : this.openUserMenu(notFilledDataDay[key].info_id)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     {this.state.showUserMenu[notFilledDataDay[key].info_id] === true ?

          //       this.renderUserMenu2(notFilledDataDay[key])

          //     : null} */}

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.notFilledData)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + notFilledDataDay[key].info_id] === true ?

          //       this.renderUserMenu(notFilledDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'n' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.notFilledData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + notFilledDataDay[key].info_id] === true ?

              this.renderUserMenu(notFilledDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'n' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Data.data.appOptions.availability === 'UNAVAILABILITY' ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.2) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.4)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartNotFilled(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickNotFilled(i, key, event)}
          >

            {notFilledDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Data.data.appOptions.availability === 'UNAVAILABILITY' ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>{notFilledDataDay[key].functie_naam}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>{notFilledDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                  </Tooltip>

                </div>

              </div>

            :

            <div className='plannerN_shiftColumn1Outer'>

              <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Data.data.appOptions.availability === 'UNAVAILABILITY' ? Constants.hexToRgbA(Colors.color.greenFilledIn, 0.5) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-briefcase'></i>
                      <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <span>-</span>
                </Tooltip>

              </div>

            </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>

                {Data.data.appOptions.availability === 'UNAVAILABILITY' ?             
                  <span style={{color: '#04163b'}}>Hele dag</span>
                :
                  <span style={{color: '#04163b'}}>n.v.t.</span>
                }

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
                  
              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.notFilledData)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + notFilledDataDay[key].info_id] === true ?

            this.renderUserMenu(notFilledDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = notFilledDataDay.length;
      while(j < maxNotFilled) {
        notFilledDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return notFilledBars;

  }

  getAllPeopleBars() {

    let notFilledDataDay = null;
    let notFilledDataDayBars = null;

    let notFilledBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.usersNoAvailability.mon.length) {
      maxNotFilled = this.usersNoAvailability.mon.length;
    }
    if(maxNotFilled < this.usersNoAvailability.tue.length) {
      maxNotFilled = this.usersNoAvailability.tue.length;
    }
    if(maxNotFilled < this.usersNoAvailability.wed.length) {
      maxNotFilled = this.usersNoAvailability.wed.length;
    }
    if(maxNotFilled < this.usersNoAvailability.thu.length) {
      maxNotFilled = this.usersNoAvailability.thu.length;
    }
    if(maxNotFilled < this.usersNoAvailability.fri.length) {
      maxNotFilled = this.usersNoAvailability.fri.length;
    }
    if(maxNotFilled < this.usersNoAvailability.sat.length) {
      maxNotFilled = this.usersNoAvailability.sat.length;
    }
    if(maxNotFilled < this.usersNoAvailability.sun.length) {
      maxNotFilled = this.usersNoAvailability.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        notFilledDataDay = this.usersNoAvailability.mon;
        notFilledDataDayBars = notFilledBars.mon;
        notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.greyNotFilled)}}>Medewerkers</span></div>)
      }
      if(i === 1) {
        notFilledDataDay = this.usersNoAvailability.tue;
        notFilledDataDayBars = notFilledBars.tue;
      }
      if(i === 2) {
        notFilledDataDay = this.usersNoAvailability.wed;
        notFilledDataDayBars = notFilledBars.wed;
      }
      if(i === 3) {
        notFilledDataDay = this.usersNoAvailability.thu;
        notFilledDataDayBars = notFilledBars.thu;
      }
      if(i === 4) {
        notFilledDataDay = this.usersNoAvailability.fri;
        notFilledDataDayBars = notFilledBars.fri;
      }
      if(i === 5) {
        notFilledDataDay = this.usersNoAvailability.sat;
        notFilledDataDayBars = notFilledBars.sat;
      }
      if(i === 6) {
        notFilledDataDay = this.usersNoAvailability.sun;
        notFilledDataDayBars = notFilledBars.sun;
        notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        notFilledDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.5)}}></div>);
      }

      for(let key in notFilledDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
         
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[notFilledDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(notFilledDataDay[key].info_id);

        const userFullName = `${notFilledDataDay[key].info_voornaam} ${(notFilledDataDay[key].info_tussenvoegsel !== null && notFilledDataDay[key].info_tussenvoegsel !== '') ? notFilledDataDay[key].info_tussenvoegsel + " " + notFilledDataDay[key].info_achternaam.charAt(0) : notFilledDataDay[key].info_achternaam.charAt(0)}.`;
  
        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        notFilledDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div key={i + 'n' + key} 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartNoAvailability(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickNoAvailability(i, key)}
          //     className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.greyNotFilled, backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //     <Tooltip
          //       options
          //       html={(
          //         <div className='planner_commentContainer'>
          //           <i className='fas fa-briefcase'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{notFilledDataDay[key].functie_naam}</span>
          //         </div>
          //       )}
          //       position={'top'}
          //       arrow
          //       followCursor
          //       theme='kodict'
          //     >
          //       <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+notFilledDataDay[key].info_id}`} style={parseInt(notFilledDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${notFilledDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //     </Tooltip>
    
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickNoAvailability(i, key)}>
    
          //       <div>n.v.t.</div>
    
          //     </div>
    
          //     <div className='planner_nameContainer' onClick={() => this.onClickNoAvailability(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     <div className='planner_iconContainer' onClick={() => this.onClickNoAvailability(i, key)}>
    
          //       {null}
    
          //     </div>

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.usersNoAvailability)}>

          //       <i className='fas fa-caret-down' />

          //     </div>

          //     </div>

          //     {this.state.showUserMenu[dayName + notFilledDataDay[key].info_id] === true ?

          //       this.renderUserMenu(notFilledDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'na' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.usersNoAvailability)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + notFilledDataDay[key].info_id] === true ?

              this.renderUserMenu(notFilledDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'na' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === notFilledDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.4)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartNoAvailability(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickNoAvailability(i, key, event)}
          >

            {notFilledDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>{notFilledDataDay[key].functie_naam}</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>{notFilledDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                  </Tooltip>

                </div>

              </div>

            :

            <div className='plannerN_shiftColumn1Outer'>

              <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.8)}}>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-briefcase'></i>
                      <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                >
                  <span>-</span>
                </Tooltip>

              </div>

            </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>
                                                  
                <span style={{color: '#04163b'}}>n.v.t.</span>

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
                  
              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.usersNoAvailability)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + notFilledDataDay[key].info_id] === true ?

            this.renderUserMenu(notFilledDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = notFilledDataDay.length;
      while(j < maxNotFilled) {
        notFilledDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return notFilledBars;

  }

  getUnavailableBars() {

    let unavailableDataDay = null;
    let unavailableDataDayBars = null;

    let unavailableBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.unavailableData.mon.length) {
      maxNotFilled = this.unavailableData.mon.length;
    }
    if(maxNotFilled < this.unavailableData.tue.length) {
      maxNotFilled = this.unavailableData.tue.length;
    }
    if(maxNotFilled < this.unavailableData.wed.length) {
      maxNotFilled = this.unavailableData.wed.length;
    }
    if(maxNotFilled < this.unavailableData.thu.length) {
      maxNotFilled = this.unavailableData.thu.length;
    }
    if(maxNotFilled < this.unavailableData.fri.length) {
      maxNotFilled = this.unavailableData.fri.length;
    }
    if(maxNotFilled < this.unavailableData.sat.length) {
      maxNotFilled = this.unavailableData.sat.length;
    }
    if(maxNotFilled < this.unavailableData.sun.length) {
      maxNotFilled = this.unavailableData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        unavailableDataDay = this.unavailableData.mon;
        unavailableDataDayBars = unavailableBars.mon;
        unavailableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.redFilledIn)}}>Onbeschikbaar</span></div>)
      }
      if(i === 1) {
        unavailableDataDay = this.unavailableData.tue;
        unavailableDataDayBars = unavailableBars.tue;
      }
      if(i === 2) {
        unavailableDataDay = this.unavailableData.wed;
        unavailableDataDayBars = unavailableBars.wed;
      }
      if(i === 3) {
        unavailableDataDay = this.unavailableData.thu;
        unavailableDataDayBars = unavailableBars.thu;
      }
      if(i === 4) {
        unavailableDataDay = this.unavailableData.fri;
        unavailableDataDayBars = unavailableBars.fri;
      }
      if(i === 5) {
        unavailableDataDay = this.unavailableData.sat;
        unavailableDataDayBars = unavailableBars.sat;
      }
      if(i === 6) {
        unavailableDataDay = this.unavailableData.sun;
        unavailableDataDayBars = unavailableBars.sun;
        unavailableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        unavailableDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5)}}></div>);
      }

      for(let key in unavailableDataDay) {

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
         
          if (i === 0 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (i === 1 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 2 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (i === 3 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (i === 4 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (i === 5 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (i === 6 && Constants.getCurrentContract(this.state.contractsObj[unavailableDataDay[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        const hoursWeek = this.getUserHoursWeek(unavailableDataDay[key].info_id);

        const userFullName = `${unavailableDataDay[key].info_voornaam} ${(unavailableDataDay[key].info_tussenvoegsel !== null && unavailableDataDay[key].info_tussenvoegsel !== '') ? unavailableDataDay[key].info_tussenvoegsel + " " + unavailableDataDay[key].info_achternaam.charAt(0) : unavailableDataDay[key].info_achternaam.charAt(0)}.`;
  
        const dayName = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun';

        unavailableDataDayBars.push(

          // <div className='planner_touchBoxOuter'>
  
          //   <div 
          //     draggable='true' 
          //     onDragStart={(event) => this.onDragStartUnavailable(event, i === 6 ? 0 : i + 1, key)}
          //     //onDragEnd={() => this.fromPlanner = []}
          //     // onClick={() => this.onClickUnavailable(i, key)}
          //     key={i + 'u' + key} 
          //     className={this.state.selectedBar === unavailableDataDay[key].info_id + i ? 'planner_touchBox blink' : 'planner_touchBox'}
          //     style={{borderColor: Colors.color.redFilledIn, backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.1)}}
          //   >

          //     <div
          //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
          //     >

          //       <Tooltip
          //         options
          //         html={(
          //           <div className='planner_commentContainer'>
          //             <i className='fas fa-briefcase'></i>
          //             <span style={{marginLeft: '0.5vw'}}>{unavailableDataDay[key].functie_naam}</span>
          //           </div>
          //         )}
          //         position={'top'}
          //         arrow
          //         followCursor
          //         theme='kodict'
          //       >
          //         <div className={'planner_touchBox_functionCircle'} data-tip data-for={`functionTooltip${key+i+unavailableDataDay[key].info_id}`} style={parseInt(unavailableDataDay[key].functie_id) >= 0 ? {backgroundColor: `#${unavailableDataDay[key].functie_kleur}`} : {display: 'none'}} />
          //       </Tooltip>
    
          //     <div className='planner_timeContainer' style={{cursor: 'pointer'}} onClick={() => this.onClickUnavailable(i, key)}>
    
          //     {(unavailableDataDay[key].onbeschikbaar_begin === '00:00:00' && unavailableDataDay[key].onbeschikbaar_eind === '00:00:00') ?
                                                  
          //       <div>Hele dag</div>

          //     :

          //       <div>{unavailableDataDay[key].onbeschikbaar_begin.substring(0,5)} - {unavailableDataDay[key].onbeschikbaar_eind.substring(0,5)}</div>

          //     }
    
          //     </div>
    
          //     <div className='planner_nameContainer' onClick={() => this.onClickUnavailable(i, key)}>
    
          //       <div className='planner_nameText'>{userFullName}</div>
    
          //     </div>

          //     </div>

          //     {unavailableDataDay[key].onbeschikbaar_opmerking !== '' && unavailableDataDay[key].onbeschikbaar_opmerking !== null && /^[\r|\n]+$/.test(unavailableDataDay[key].onbeschikbaar_opmerking) === false  ?

          //       <Tooltip
          //         options
          //         html={(
          //           <div className='planner_commentContainer'>
          //             <i className='fas fa-comment'></i>
          //             <span style={{marginLeft: '0.5vw'}}>{unavailableDataDay[key].onbeschikbaar_opmerking}</span>
          //           </div>
          //         )}
          //         position={'top'}
          //         arrow
          //         followCursor
          //         theme='kodict'
          //         className='planner_iconContainer'
          //         onClick={() => this.onClickUnavailable(i, key)}
          //       >

          //         {/* <ReactTooltip id={`commentTooltip${key+i+unavailableDataDay[key].info_id}unavailable`} type='dark' className='planner_commentContainer'>
          //           <i className='fas fa-comment'></i>
          //           <span style={{marginLeft: '0.5vw'}}>{unavailableDataDay[key].onbeschikbaar_opmerking}</span>
          //         </ReactTooltip> */}
      
          //         {

          //         unavailableDataDay[key].onbeschikbaar_opmerking !== '' && unavailableDataDay[key].onbeschikbaar_opmerking !== null && /^[\r|\n]+$/.test(unavailableDataDay[key].onbeschikbaar_opmerking) === false  ?

          //             <i className='far fa-comment' style={{color: '#47B7EB', marginTop: '1px'}} data-tip data-for={`commentTooltip${key+i+unavailableDataDay[key].info_id}unavailable`}></i>

          //         :

          //           null

          //         }

          //       </Tooltip>

          //     : null}

          //     <div className='planner_arrowContainer' onClick={() => this.onClickArrowUserMenu(i, key, this.unavailableData)}>

          //     <i className='fas fa-caret-down' />

          //     </div>

          //     {this.state.showUserMenu[dayName + unavailableDataDay[key].info_id] === true ?

          //     this.renderUserMenu(unavailableDataDay[key], i === 5 || i === 6)

          //     : null}
    
          //   </div>

          // </div>

        contractExpired === true ?

          <div key={i + 'u' + key} className='plannerN_shiftOuter'>

            <div 
              className={this.state.selectedBar === unavailableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

                    <i className='fal fa-file-signature' />

                  </div>

                </div>

              <div className='plannerN_shiftColumn2'>

                <div className='plannerN_shiftRow'>
                                                      
                  <span style={{color: '#04163b'}}>Contract verlopen</span>

                </div>

                <div className='plannerN_shiftRow'>

                  <div style={{width: '95%'}}>
                    <span className='plannerN_nameText'>{userFullName}</span>
                    <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                  </div>
                  
                </div>

              </div>

              <div className='plannerN_shiftColumn3'>

                <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.unavailableData)} />

              </div>

            </div>

            {this.state.showUserMenu[dayName + unavailableDataDay[key].info_id] === true ?

              this.renderUserMenu(unavailableDataDay[key], i === 5 || i === 6)

            : null}

          </div>

        :

        <div key={i + 'u' + key} className='plannerN_shiftOuter'>

          <div 
            className={this.state.selectedBar === unavailableDataDay[key].info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.2)}}
            draggable={'true'}
            onDragStart={(event) => this.onDragStartUnavailable(event, i === 6 ? 0 : i + 1, key)}
            onClick={(event) => this.onClickUnavailable(i, key, event)}
          >

            {unavailableDataDay[key].functie_naam !== null ?

              <div className='plannerN_shiftColumn1Outer'>

                  <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5)}}>

                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer'>
                          <i className='fal fa-briefcase'></i>
                          <span style={{marginLeft: '0.3vw'}}>{unavailableDataDay[key].functie_naam}</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                    >
                      <span>{unavailableDataDay[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                    </Tooltip>

                  </div>

                </div>

              :

              <div className='plannerN_shiftColumn1Outer'>

                <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5)}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fal fa-briefcase'></i>
                        <span style={{marginLeft: '0.3vw'}}>Geen functie</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                  >
                    <span>-</span>
                  </Tooltip>

                </div>

              </div>

            }

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>

              {(unavailableDataDay[key].onbeschikbaar_begin === '00:00:00' && unavailableDataDay[key].onbeschikbaar_eind === '00:00:00') ?
                                                  
                <span style={{color: '#04163b'}}>Hele dag</span>

              :

                <span style={{color: '#04163b'}}>{unavailableDataDay[key].onbeschikbaar_begin.substring(0,5)} - {unavailableDataDay[key].onbeschikbaar_eind.substring(0,5)}</span>

              }

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
                  
              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              {unavailableDataDay[key].onbeschikbaar_opmerking !== '' && unavailableDataDay[key].onbeschikbaar_opmerking !== null && /^[\r|\n]+$/.test(unavailableDataDay[key].onbeschikbaar_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-comment'></i>
                      <span style={{marginLeft: '0.3vw'}}>{unavailableDataDay[key].onbeschikbaar_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-comment' style={{color: '#47B7EB'}} />
                </Tooltip>
              : null}

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.onClickArrowUserMenu(i, key, this.unavailableData)} />

            </div>

          </div>

          {this.state.showUserMenu[dayName + unavailableDataDay[key].info_id] === true ?

            this.renderUserMenu(unavailableDataDay[key], i === 5 || i === 6)

          : null}

        </div>
  
        );
  
      }

      let j = unavailableDataDay.length;
      while(j < maxNotFilled) {
        unavailableDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return unavailableBars;

  }

  getHolidayBars() {

    let holidayDataDay = null;
    let holidayDataDayBars = null;

    let holidayBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < this.holidayDaysData.mon.length) {
      maxNotFilled = this.holidayDaysData.mon.length;
    }
    if(maxNotFilled < this.holidayDaysData.tue.length) {
      maxNotFilled = this.holidayDaysData.tue.length;
    }
    if(maxNotFilled < this.holidayDaysData.wed.length) {
      maxNotFilled = this.holidayDaysData.wed.length;
    }
    if(maxNotFilled < this.holidayDaysData.thu.length) {
      maxNotFilled = this.holidayDaysData.thu.length;
    }
    if(maxNotFilled < this.holidayDaysData.fri.length) {
      maxNotFilled = this.holidayDaysData.fri.length;
    }
    if(maxNotFilled < this.holidayDaysData.sat.length) {
      maxNotFilled = this.holidayDaysData.sat.length;
    }
    if(maxNotFilled < this.holidayDaysData.sun.length) {
      maxNotFilled = this.holidayDaysData.sun.length;
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {
        holidayDataDay = this.holidayDaysData.mon;
        holidayDataDayBars = holidayBars.mon;
        holidayDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}><span style={{color: Constants.getContrast(Colors.color.orangeVacation)}}>Verlof</span></div>)
      }
      if(i === 1) {
        holidayDataDay = this.holidayDaysData.tue;
        holidayDataDayBars = holidayBars.tue;
      }
      if(i === 2) {
        holidayDataDay = this.holidayDaysData.wed;
        holidayDataDayBars = holidayBars.wed;
      }
      if(i === 3) {
        holidayDataDay = this.holidayDaysData.thu;
        holidayDataDayBars = holidayBars.thu;
      }
      if(i === 4) {
        holidayDataDay = this.holidayDaysData.fri;
        holidayDataDayBars = holidayBars.fri;
      }
      if(i === 5) {
        holidayDataDay = this.holidayDaysData.sat;
        holidayDataDayBars = holidayBars.sat;
      }
      if(i === 6) {
        holidayDataDay = this.holidayDaysData.sun;
        holidayDataDayBars = holidayBars.sun;
        holidayDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5}}></div>);
      }

      if(i !== 0 && i !== 6) {
        holidayDataDayBars.push(<div className='planner_headerText2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5)}}></div>);
      }

      for(let key in holidayDataDay) {

        // BREAKING CHANGES

        const date = holidayDataDay[key].date;

        const weekStart = Constants.stringToDate(this.state.mondayDate);
        const weekEnd = Constants.stringToDate(this.state.sundayDate);

        // date.setHours(12,0,0,0);
        // weekStart.setHours(12,0,0,0);
        // weekEnd.setHours(12,0,0,0);

        // if((date.getDate() >= weekStart.getDate() && date.getMonth() >= weekStart.getMonth() && date.getFullYear() >= weekStart.getFullYear()) &&
        // (date.getDate() <= weekEnd.getDate() && date.getMonth() <= weekEnd.getMonth() && date.getFullYear() <= weekEnd.getFullYear())) {
        if(date >= weekStart && date <= weekEnd) {

          let isFreeHours = typeof this.state.freeHours[holidayDataDay[key].id] !== 'undefined' && this.state.freeHours[holidayDataDay[key].id].vu_begin !== '00:00:00' && this.state.freeHours[holidayDataDay[key].id].vu_eind !== '00:00:00';
          let freeHoursData = {};
          if (isFreeHours === true) {
            freeHoursData = this.state.freeHours[holidayDataDay[key].id];
          }

          const hoursWeek = this.getUserHoursWeek(holidayDataDay[key].id);

          holidayDataDayBars.push(

            isFreeHours === true ?

              <div key={i + 'fh' + key} className='plannerN_shiftOuter'>

                <div 
                  className={this.state.selectedBar === freeHoursData.info_id + i ? 'plannerN_shiftContainer blink' : 'plannerN_shiftContainer'}
                  style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: 'pointer'}}
                  draggable={'true'}
                  onDragStart={(event) => this.onDragStartHoliday(event, i === 6 ? 0 : i + 1, freeHoursData)}
                  onClick={(event) => this.onClickHoliday(i, freeHoursData, event)}
                >

                  {freeHoursData.functie_naam !== null ?

                    <div className='plannerN_shiftColumn1Outer'>

                      <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5)}}>

                        <Tooltip
                          options
                          html={(
                            <div className='plannerN_tooltipContainer'>
                              <i className='fal fa-briefcase'></i>
                              <span style={{marginLeft: '0.3vw'}}>{freeHoursData.functie_naam}</span>
                            </div>
                          )}
                          position={'top'}
                          arrow
                          followCursor
                          theme='kodict'
                        >
                          <span>{freeHoursData.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                        </Tooltip>

                      </div>

                    </div>

                  :

                    <div className='plannerN_shiftColumn1Outer'>

                      <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5)}}>

                        <i className='fal fa-island-tropical' />

                      </div>

                    </div>

                  }

                  <div className='plannerN_shiftColumn2'>

                    <div className='plannerN_shiftRow'>
                                                      
                      <span style={{color: '#04163b'}}>Verlof ({freeHoursData.vu_begin.substr(0, 5)} - {freeHoursData.vu_eind.substr(0, 5)})</span>

                    </div>

                    <div className='plannerN_shiftRow'>

                      <div style={{width: '95%'}}>
                        <span className='plannerN_nameText'>{holidayDataDay[key].name}</span>
                        <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                      </div>
                  
                    </div>

                  </div>

                  <div className='plannerN_shiftColumn3'>

                    &nbsp;

                  </div>

                </div>

              </div>

            :

              <div key={i + 'h' + key} className='plannerN_shiftOuter'>

                <div 
                  className={'plannerN_shiftContainer'}
                  style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: 'default'}}
                >

                  <div className='plannerN_shiftColumn1Outer'>

                    <div className='plannerN_shiftColumn1_2' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5)}}>

                      <i className='fal fa-island-tropical' />

                    </div>

                  </div>

                  <div className='plannerN_shiftColumn2'>

                    <div className='plannerN_shiftRow'>
                                                        
                      <span style={{color: '#04163b'}}>Verlof</span>

                    </div>

                    <div className='plannerN_shiftRow'>

                      <div style={{width: '95%'}}>
                        <span className='plannerN_nameText'>{holidayDataDay[key].name}</span>
                        <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                      </div>
                  
                    </div>

                  </div>

                  <div className='plannerN_shiftColumn3'>

                    &nbsp;

                  </div>

                </div>

              </div>
    
          );

        }
  
      }

      let j = holidayDataDay.length;
      while(j < maxNotFilled) {
        holidayDataDayBars.push(<div className='plannerN_touchBoxEmpty' />);
        j++;
      }

    }

    return holidayBars;

  }

  async sortUp(currentIndex) {
    if(currentIndex !== 0) {

      Constants.swapInArray(this.functionsData, currentIndex, currentIndex - 1);

      if(this.state.section !== null) {

        localStorage.setItem(`@functionSort${this.state.section}${Data.data.storeCode}`, JSON.stringify(this.functionsData));

      } else {

        localStorage.setItem(`@functionSort${Data.data.storeCode}`, JSON.stringify(this.functionsData));

      }

      this.setState({reloading: true});

      await this.getFunctions();

      this.setState({reloading: false});

    }
  }

  async sortDown(currentIndex) {
    if(currentIndex !== this.functionsData.length - 1) {
      
      Constants.swapInArray(this.functionsData, currentIndex, currentIndex + 1);
      
      if(this.state.section !== null) {

        localStorage.setItem(`@functionSort${this.state.section}${Data.data.storeCode}`, JSON.stringify(this.functionsData));

      } else {

        localStorage.setItem(`@functionSort${Data.data.storeCode}`, JSON.stringify(this.functionsData));

      }

      this.setState({reloading: true});

      await this.getFunctions();

      this.setState({reloading: false});

    }
  }

  async saveTimeDirectly(e, planData, table) {

    if (this.timeRef[planData.rooster_id].value === `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`) { //this.state.planInputs[planData.rooster_id]
      //this.setState({editPlan: false});
      return;
    }

    let planInputs = this.state.planInputs;

    // deletion
    // if (this.timeRef[planData.rooster_id].value === '') {

    //   if (typeof table === 'undefined') {
    //     this.setState({editPlan: false});
    //   }

    //   planInputs[planData.rooster_id] = this.timeRef[planData.rooster_id].value;

    //   // remove focus
    //   this.timeRef[planData.rooster_id].blur();

    //   // reset lastVal
    //   this.lastVal = '';

    //   // reset timeRef
    //   this.timeRef = {};

    //   // change last edited color
    //   //this.lastEdited = planData.rooster_id + day;
    //   this.lastEdited = '';
    //   //this.setState({ lastEdited: this.lastEdited });
    //   this.setState({ lastEdited: this.lastEdited, planInputs: planInputs }); //editplan

    //   // save in planner
    //   await APIDeleteUserPlanner.Request(planData.rooster_id);

    //   // reload specific date
    //   await this.reloadScreenDirectly(planData.rooster_datum);

    //   if (table === true) {
    //     this.setState({editPlan: false});
    //   }

    //   return false;

    // }

    // declare start and end
    const start = this.timeRef[planData.rooster_id].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
    const end = this.timeRef[planData.rooster_id].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

    const startDate = Constants.getDateTZ(planData.rooster_datum, start);
    const endDate = Constants.getDateTZ(planData.rooster_datum, end);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

      // set initial value
      planInputs[planData.rooster_id] = `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`;
      this.timeRef[planData.rooster_id].value = `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`;
      this.setState({ planInputs: planInputs }); //editplan

      // remove focus
      //this.timeRef[planData.rooster_id].blur();

      // reset lastVal
      this.lastVal = '';

      alert("Je hebt geen correcte begin- of eindtijd ingevuld");
      return false;
    }

    // if (startDate.getTime() > endDate.getTime()) {

    //   // set initial value
    //   planInputs[planData.rooster_id] = `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`;
    //   this.timeRef[planData.rooster_id].value = `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`;
    //   this.setState({ planInputs: planInputs }); //editplan

    //   // reset lastVal
    //   this.lastVal = '';

    //   alert("Begintijd kan niet later zijn dan eindtijd");
    //   return false;
    // }

    if (Plan.checkInfoExtraEditDirectly(this.state.infoExtra[planData.info_id], planData.info_id, planData.rooster_datum, start, end) === false) {
      
      // set initial value
      planInputs[planData.rooster_id] = `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`;
      this.timeRef[planData.rooster_id].value = `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`;
      this.setState({ planInputs: planInputs }); //editplan

      // reset lastVal
      this.lastVal = '';

      return false;
    }
    
    if (typeof table === 'undefined') {
      this.setState({editPlan: false});
    }

    planInputs[planData.rooster_id] = this.timeRef[planData.rooster_id].value;

    // remove focus
    this.timeRef[planData.rooster_id].blur();

    // reset lastVal
    this.lastVal = '';

    // reset timeRef
    this.timeRef = {};

    // change last edited color
    //this.lastEdited = planData.rooster_id + day;
    this.lastEdited = '';
    //this.setState({ lastEdited: this.lastEdited });
    this.setState({ lastEdited: this.lastEdited, planInputs: planInputs }); //editplan

    // save in planner
    await this.editUserDirectly(start, end, planData);

    Logger.editPlannerDirectly(planData, start, end);

    // reload specific date
    await this.reloadScreenDirectly(planData.rooster_datum);

    if (table === true) {
      this.setState({editPlan: false});
    }

  }

  async saveTimeDirectlyOther(e, data, i, prefix) {

    setTimeout(async() => {

      if (this.dontBlur || this.isAdding || Constants.isEmpty(this.timeRef[prefix+data.info_id+i])) {
        return;
      }

      if (prefix === 'a' && this.timeRef[prefix+data.info_id+i].value === `${data.beschikbaar_begin.substr(0, 5)} - ${data.beschikbaar_eind.substr(0, 5)}`) { //available
        return;
      } else if (prefix === 'u' && this.timeRef[prefix+data.info_id+i].value === `${data.onbeschikbaar_begin.substr(0, 5)} - ${data.onbeschikbaar_eind.substr(0, 5)}`) { //available
        return;
      } else if (prefix === 's' && this.timeRef[prefix+data.info_id+i].value === `${data.schoolrooster_begin.substr(0, 5)} - ${data.schoolrooster_eind.substr(0, 5)}`) { //available
        return;
      } else if (prefix === 'sta' && this.timeRef[prefix+data.info_id+i].value === `${data.sb_begin.substr(0, 5)} - ${data.sb_eind.substr(0, 5)}`) { //available
        return;
      } else if (prefix === 'stu' && this.timeRef[prefix+data.info_id+i].value === `${data.sb_begin.substr(0, 5)} - ${data.sb_eind.substr(0, 5)}`) { //available
        return;
      }

      if (this.timeRef[prefix+data.info_id+i].value === ``) { //this.state.planInputs[planData.rooster_id]
        //this.setState({editPlan: false});
        return;
      }

      let day = i === 6 ? 0 : i + 1;
      let date = null;

      if(day === 0) {
        date = this.state.sundayDate;
      }
      if(day === 1) {
        date = this.state.mondayDate;
      }
      if(day === 2) {
        date = this.state.tuesdayDate;
      }
      if(day === 3) {
        date = this.state.wednesdayDate;
      }
      if(day === 4) {
        date = this.state.thursdayDate;
      }
      if(day === 5) {
        date = this.state.fridayDate;
      }
      if(day === 6) {
        date = this.state.saturdayDate;
      }

      let otherInput = prefix === 'n' ? this.state.notFilledInputs 
        : prefix === 'a' ? this.state.availableInputs 
        : prefix === 'u' ? this.state.unavailableInputs
        : prefix === 's' ? this.state.schoolInputs
        : prefix === 'sn' ? this.state.schoolNotFilledInputs
        : prefix === 'sh' ? this.state.schoolHolidayInputs
        : prefix === 'na' ? this.state.noAvailableInputs   
        : prefix === 'sta' ? this.state.standardAvailableInputs 
        : prefix === 'stu' ? this.state.standardUnavailableInputs 
        : prefix === 'stn' ? this.state.standardNotFilledInputs 
        : {};

      // declare start and end
      const start = this.timeRef[prefix+data.info_id+i].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
      const end = this.timeRef[prefix+data.info_id+i].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

      const startDate = Constants.getDateTZ(date, start);
      const endDate = Constants.getDateTZ(date, end);

      // check if start and end are correct
      if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

        // set initial value
        //otherInput[data.info_id] = ``;
        // if (prefix === 'a') { //available
        //   this.timeRef[prefix+data.info_id+i].value = `${data.beschikbaar_begin.substr(0, 5)} - ${data.beschikbaar_eind.substr(0, 5)}`;
        // } else if (prefix === 'u') { //unavailable
        //   this.timeRef[prefix+data.info_id+i].value = `${data.onbeschikbaar_begin.substr(0, 5)} - ${data.onbeschikbaar_eind.substr(0, 5)}`;
        // } else if (prefix === 's') { //school
        //   this.timeRef[prefix+data.info_id+i].value = `${data.schoolrooster_begin.substr(0, 5)} - ${data.schoolrooster_eind.substr(0, 5)}`;
        // } else {
          this.timeRef[prefix+data.info_id+i].value = ``;
        //}

        //this.setState({ notFilledInputs: notFilledInputs }); //editplan

        // remove focus
        //this.timeRef[planData.rooster_id].blur();

        // reset lastVal
        this.lastVal = '';

        alert("Je hebt geen correcte begin- of eindtijd ingevuld");
        return false;
      }

      // if (startDate.getTime() > endDate.getTime()) {

      //   // set initial value
      //   //notFilledInputs[data.info_id] = ``;
      //   // if (prefix === 'a') { //available
      //   //   this.timeRef[prefix+data.info_id+i].value = `${data.beschikbaar_begin.substr(0, 5)} - ${data.beschikbaar_eind.substr(0, 5)}`;
      //   // } else if (prefix === 'u') { //unavailable
      //   //   this.timeRef[prefix+data.info_id+i].value = `${data.onbeschikbaar_begin.substr(0, 5)} - ${data.onbeschikbaar_eind.substr(0, 5)}`;
      //   // } else if (prefix === 's') { //school
      //   //   this.timeRef[prefix+data.info_id+i].value = `${data.schoolrooster_begin.substr(0, 5)} - ${data.schoolrooster_eind.substr(0, 5)}`;
      //   // } else {
      //     this.timeRef[prefix+data.info_id+i].value = ``;
      //   //}
      //   //this.setState({ notFilledInputs: notFilledInputs }); //editplan

      //   // reset lastVal
      //   this.lastVal = '';

      //   alert("Begintijd kan niet later zijn dan eindtijd");
      //   return false;
      // }

      if (Plan.checkInfoExtraAddDirectly(this.state.infoExtra[data.info_id], data.info_id, date, this.allPlannerData, this.state.planUsersMaxCumulative, start, end) === false) {
      
        // set initial value
        this.timeRef[prefix+data.info_id+i].value = ``;

        // reset lastVal
        this.lastVal = '';

        return false;
      }

      this.isAdding = true;

      //notFilledInputs[data.info_id] = this.timeRef['n'+data.info_id+i].value;

      // remove focus
      this.timeRef[prefix+data.info_id+i].blur();

      // reset lastVal
      this.lastVal = '';

      // reset timeRef
      this.timeRef = {};

      // change last edited color
      //this.lastEdited = planData.rooster_id + day;
      this.lastEdited = '';
      //this.setState({ lastEdited: this.lastEdited });
      this.setState({ lastEdited: this.lastEdited }); //editplan

      // save in planner
      const toPlanner = {
        rooster_datum: date,
        rooster_begin: start,
        rooster_eind: end,
        rooster_pauze: '00:00',
        functie_id: this.state.functionsUser.length > 1 && data.functie_id !== this.state.secondaryFunctionID ? this.state.secondaryFunctionID : data.functie_id
      };

      await this.planUser(date, data, toPlanner);

      Logger.addPlannerDirectly(toPlanner, data, start, end);

      // reload specific date
      await this.reloadScreenDirectly(date);

      this.setState({editPlan: false});

      this.isAdding = false;
      
    }, 250);

  }

  async saveTimeDirectlyOtherForce(e, data, i, prefix) {

    if (this.timeRef[prefix+data.info_id+i].value === ``) { //this.state.planInputs[planData.rooster_id]
      //this.setState({editPlan: false});
      return;
    }

    let day = i === 6 ? 0 : i + 1;
    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    // declare start and end
    const start = this.timeRef[prefix+data.info_id+i].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
    const end = this.timeRef[prefix+data.info_id+i].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

    const startDate = Constants.getDateTZ(date, start);
    const endDate = Constants.getDateTZ(date, end);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

      // set initial value
      //otherInput[data.info_id] = ``;
      // if (prefix === 'a') { //available
      //   this.timeRef[prefix+data.info_id+i].value = `${data.beschikbaar_begin.substr(0, 5)} - ${data.beschikbaar_eind.substr(0, 5)}`;
      // } else if (prefix === 'u') { //unavailable
      //   this.timeRef[prefix+data.info_id+i].value = `${data.onbeschikbaar_begin.substr(0, 5)} - ${data.onbeschikbaar_eind.substr(0, 5)}`;
      // } else if (prefix === 's') { //school
      //   this.timeRef[prefix+data.info_id+i].value = `${data.schoolrooster_begin.substr(0, 5)} - ${data.schoolrooster_eind.substr(0, 5)}`;
      // } else {
        this.timeRef[prefix+data.info_id+i].value = ``;
     // }

      //this.setState({ notFilledInputs: notFilledInputs }); //editplan

      // remove focus
      //this.timeRef[planData.rooster_id].blur();

      // reset lastVal
      this.lastVal = '';

      alert("Je hebt geen correcte begin- of eindtijd ingevuld");
      return false;
    }

    // if (startDate.getTime() > endDate.getTime()) {

    //   // set initial value
    //   //notFilledInputs[data.info_id] = ``;
    //   // if (prefix === 'a') { //available
    //   //   this.timeRef[prefix+data.info_id+i].value = `${data.beschikbaar_begin.substr(0, 5)} - ${data.beschikbaar_eind.substr(0, 5)}`;
    //   // } else if (prefix === 'u') { //unavailable
    //   //   this.timeRef[prefix+data.info_id+i].value = `${data.onbeschikbaar_begin.substr(0, 5)} - ${data.onbeschikbaar_eind.substr(0, 5)}`;
    //   // } else if (prefix === 's') { //school
    //   //   this.timeRef[prefix+data.info_id+i].value = `${data.schoolrooster_begin.substr(0, 5)} - ${data.schoolrooster_eind.substr(0, 5)}`;
    //   // } else {
    //     this.timeRef[prefix+data.info_id+i].value = ``;
    //   //}
    //   //this.setState({ notFilledInputs: notFilledInputs }); //editplan

    //   // reset lastVal
    //   this.lastVal = '';

    //   alert("Begintijd kan niet later zijn dan eindtijd");
    //   return false;
    // }

    if (Plan.checkInfoExtraAddDirectly(this.state.infoExtra[data.info_id], data.info_id, date, this.allPlannerData, this.state.planUsersMaxCumulative, start, end) === false) {
      
      // set initial value
      this.timeRef[prefix+data.info_id+i].value = ``;

      // reset lastVal
      this.lastVal = '';

      return false;
    }

    //notFilledInputs[data.info_id] = this.timeRef['n'+data.info_id+i].value;

    // remove focus
    this.timeRef[prefix+data.info_id+i].blur();

    // reset lastVal
    this.lastVal = '';

    // reset timeRef
    this.timeRef = {};

    // change last edited color
    //this.lastEdited = planData.rooster_id + day;
    this.lastEdited = '';
    //this.setState({ lastEdited: this.lastEdited });
    this.setState({ lastEdited: this.lastEdited }); //editplan

    // save in planner
    const toPlanner = {
      rooster_datum: date,
      rooster_begin: start,
      rooster_eind: end,
      rooster_pauze: '00:00',
      functie_id: this.state.functionsUser.length > 1 && data.functie_id !== this.state.secondaryFunctionID ? this.state.secondaryFunctionID : data.functie_id
    };

    await this.planUser(date, data, toPlanner);

    Logger.addPlannerDirectly(toPlanner, data, start, end);

    // reload specific date
    await this.reloadScreenDirectly(date);

    this.setState({editPlan: false});

  }

  async saveTimeDirectlyOtherHoliday(e, data, i, prefix, key) {

    setTimeout(async() => {

      if (this.dontBlur || this.isAdding || Constants.isEmpty(this.timeRef['fh'+data.info_id+i+key])) {
        return;
      }

      if (this.timeRef['fh'+data.info_id+i+key].value === ``) { //this.state.planInputs[planData.rooster_id]
        //this.setState({editPlan: false});
        return;
      }

      let day = i === 6 ? 0 : i + 1;
      let date = null;

      if(day === 0) {
        date = this.state.sundayDate;
      }
      if(day === 1) {
        date = this.state.mondayDate;
      }
      if(day === 2) {
        date = this.state.tuesdayDate;
      }
      if(day === 3) {
        date = this.state.wednesdayDate;
      }
      if(day === 4) {
        date = this.state.thursdayDate;
      }
      if(day === 5) {
        date = this.state.fridayDate;
      }
      if(day === 6) {
        date = this.state.saturdayDate;
      }

      // declare start and end
      const start = this.timeRef['fh'+data.info_id+i+key].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
      const end = this.timeRef['fh'+data.info_id+i+key].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

      const startDate = Constants.getDateTZ(date, start);
      const endDate = Constants.getDateTZ(date, end);

      // check if start and end are correct
      if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

        this.timeRef['fh'+data.info_id+i+key].value = ``;

        // reset lastVal
        this.lastVal = '';

        alert("Je hebt geen correcte begin- of eindtijd ingevuld");
        return false;
      }

      // if (startDate.getTime() > endDate.getTime()) {

      //   this.timeRef['fh'+data.info_id+i+key].value = ``;

      //   // reset lastVal
      //   this.lastVal = '';

      //   alert("Begintijd kan niet later zijn dan eindtijd");
      //   return false;
      // }

      this.isAdding = true;

      // remove focus
      this.timeRef['fh'+data.info_id+i+key].blur();

      // reset lastVal
      this.lastVal = '';

      // reset timeRef
      this.timeRef = {};

      // change last edited color
      this.lastEdited = '';
      this.setState({ lastEdited: this.lastEdited }); //editplan

      // save in planner
      const toPlanner = {
        rooster_datum: date,
        rooster_begin: start,
        rooster_eind: end,
        rooster_pauze: '00:00',
        functie_id: this.state.functionsUser.length > 1 && data.functie_id !== this.state.secondaryFunctionID ? this.state.secondaryFunctionID : data.functie_id
      };

      await this.planUser(date, data, toPlanner);

      Logger.addPlannerDirectly(toPlanner, data, start, end);

      //await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${start}', '${end}', NULL, ${fid}, 3, '00:00:00', 0)`);

      // reload specific date
      await this.reloadScreenDirectly(date);

      this.setState({editPlan: false});

      this.isAdding = false;
      
    }, 250);

  }

  async saveTimeDirectlyOtherHolidayForce(e, key, i, data) {

    if (this.timeRef['fh'+data.info_id+i+key].value === ``) { //this.state.planInputs[planData.rooster_id]
      //this.setState({editPlan: false});
      return;
    }

    let day = i === 6 ? 0 : i + 1;
    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    // declare start and end
    const start = this.timeRef['fh'+data.info_id+i+key].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
    const end = this.timeRef['fh'+data.info_id+i+key].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

    const startDate = Constants.getDateTZ(date, start);
    const endDate = Constants.getDateTZ(date, end);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

      // set initial value
      this.timeRef['fh'+data.info_id+i+key].value = ``;

      // reset lastVal
      this.lastVal = '';

      alert("Je hebt geen correcte begin- of eindtijd ingevuld");
      return false;
    }

    // if (startDate.getTime() > endDate.getTime()) {

    //   // set initial value
    //   this.timeRef['fh'+data.info_id+i+key].value = ``;

    //   // reset lastVal
    //   this.lastVal = '';

    //   alert("Begintijd kan niet later zijn dan eindtijd");
    //   return false;
    // }

    // remove focus
    this.timeRef['fh'+data.info_id+i+key].blur();

    // reset lastVal
    this.lastVal = '';

    // reset timeRef
    this.timeRef = {};

    // change last edited color
    this.lastEdited = '';
    this.setState({ lastEdited: this.lastEdited }); //editplan

    // save in planner
    const toPlan = {
      rooster_datum: date,
      rooster_begin: start,
      rooster_eind: end,
      rooster_pauze: '00:00:00',
      functie_id: this.state.functionsUser.length > 1 && data.functie_id !== this.state.secondaryFunctionID ? this.state.secondaryFunctionID : data.functie_id
    };

    await this.planUser(date, data, toPlan);

    Logger.addPlannerDirectly(toPlan, data, start, end);

    // await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${start}', '${end}', NULL, ${fid}, 3, '00:00:00', 0)`);

    // const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

    // if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
    //   await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeam}, ${rid[0].rooster_id})`);
    // }

    // if (Constants.isTrue(Data.data.appOptions.opties_opendienst_beschikbaar)) {
    //   await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${rid[0].rooster_id}, NULL, 0)`);
    //   const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    //   this.setState({userAvailableShifts: userAvailableShifts});
    // }

    // reload specific date
    await this.reloadScreenDirectly(date);

    this.setState({editPlan: false});

  }

  async saveTimeDirectlyOtherEmptyShift(e, key, i, fid) {

    setTimeout(async() => {

      if (this.dontBlur || this.isAdding || Constants.isEmpty(this.timeRef['fes'+key+i+fid])) {
        return;
      }

      if (this.timeRef['fes'+key+i+fid].value === ``) { //this.state.planInputs[planData.rooster_id]
        //this.setState({editPlan: false});
        return;
      }

      let day = i === 6 ? 0 : i + 1;
      let date = null;

      if(day === 0) {
        date = this.state.sundayDate;
      }
      if(day === 1) {
        date = this.state.mondayDate;
      }
      if(day === 2) {
        date = this.state.tuesdayDate;
      }
      if(day === 3) {
        date = this.state.wednesdayDate;
      }
      if(day === 4) {
        date = this.state.thursdayDate;
      }
      if(day === 5) {
        date = this.state.fridayDate;
      }
      if(day === 6) {
        date = this.state.saturdayDate;
      }

      // declare start and end
      const start = this.timeRef['fes'+key+i+fid].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
      const end = this.timeRef['fes'+key+i+fid].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

      const startDate = Constants.getDateTZ(date, start);
      const endDate = Constants.getDateTZ(date, end);

      // check if start and end are correct
      if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

        this.timeRef['fes'+key+i+fid].value = ``;

        // reset lastVal
        this.lastVal = '';

        alert("Je hebt geen correcte begin- of eindtijd ingevuld");
        return false;
      }

      // if (startDate.getTime() > endDate.getTime()) {

      //   this.timeRef['fes'+key+i+fid].value = ``;

      //   // reset lastVal
      //   this.lastVal = '';

      //   alert("Begintijd kan niet later zijn dan eindtijd");
      //   return false;
      // }

      this.isAdding = true;

      // remove focus
      this.timeRef['fes'+key+i+fid].blur();

      // reset lastVal
      this.lastVal = '';

      // reset timeRef
      this.timeRef = {};

      // change last edited color
      this.lastEdited = '';
      this.setState({ lastEdited: this.lastEdited }); //editplan

      // save in planner
      await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${start}', '${end}', NULL, ${fid}, 3, '00:00:00', 0)`);

      const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
      
      if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
        await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
      }

      if (Constants.isTrue(Data.data.appOptions.opties_opendienst_beschikbaar)) {
        await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${rid[0].rooster_id}, NULL, 0)`);
        const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
        this.setState({userAvailableShifts: userAvailableShifts});
      }

      // reload specific date
      await this.reloadScreenDirectly(date);

      this.setState({editPlan: false});

      this.isAdding = false;
      
    }, 250);

  }

  async saveTimeDirectlyOtherEmptyShiftUser(e, key, i, fid, userID) {

    setTimeout(async() => {

      if (this.dontBlur || this.isAdding || Constants.isEmpty(this.timeRef['fes'+key+i+fid+userID])) {
        return;
      }

      if (this.timeRef['fes'+key+i+fid+userID].value === ``) { //this.state.planInputs[planData.rooster_id]
        //this.setState({editPlan: false});
        return;
      }

      let day = i === 6 ? 0 : i + 1;
      let date = null;
      let data = this.getAvailabilityData(i, userID, false);

      if(day === 0) {
        date = this.state.sundayDate;
      }
      if(day === 1) {
        date = this.state.mondayDate;
      }
      if(day === 2) {
        date = this.state.tuesdayDate;
      }
      if(day === 3) {
        date = this.state.wednesdayDate;
      }
      if(day === 4) {
        date = this.state.thursdayDate;
      }
      if(day === 5) {
        date = this.state.fridayDate;
      }
      if(day === 6) {
        date = this.state.saturdayDate;
      }

      // declare start and end
      const start = this.timeRef['fes'+key+i+fid+userID].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
      const end = this.timeRef['fes'+key+i+fid+userID].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

      const startDate = Constants.getDateTZ(date, start);
      const endDate = Constants.getDateTZ(date, end);

      // check if start and end are correct
      if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

        this.timeRef['fes'+key+i+fid+userID].value = ``;

        // reset lastVal
        this.lastVal = '';

        alert("Je hebt geen correcte begin- of eindtijd ingevuld");
        return false;
      }

      // if (startDate.getTime() > endDate.getTime()) {

      //   this.timeRef['fes'+key+i+fid+userID].value = ``;

      //   // reset lastVal
      //   this.lastVal = '';

      //   alert("Begintijd kan niet later zijn dan eindtijd");
      //   return false;
      // }

      this.isAdding = true;

      // remove focus
      this.timeRef['fes'+key+i+fid+userID].blur();

      // reset lastVal
      this.lastVal = '';

      // reset timeRef
      this.timeRef = {};

      // change last edited color
      this.lastEdited = '';
      this.setState({ lastEdited: this.lastEdited }); //editplan

      // save in planner
      const toPlan = {
        rooster_datum: date,
        rooster_begin: start,
        rooster_eind: end,
        rooster_pauze: '00:00:00',
        functie_id: this.state.functionsUser.length > 1 && fid !== this.state.secondaryFunctionID ? this.state.secondaryFunctionID : fid
      };

      await this.planUser(date, data, toPlan);

      Logger.addPlannerDirectly(toPlan, data, start, end);

      // await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${start}', '${end}', NULL, ${fid}, 3, '00:00:00', 0)`);

      // const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
      
      // if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      //   await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeam}, ${rid[0].rooster_id})`);
      // }

      // if (Constants.isTrue(Data.data.appOptions.opties_opendienst_beschikbaar)) {
      //   await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${rid[0].rooster_id}, NULL, 0)`);
      //   const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
      //   this.setState({userAvailableShifts: userAvailableShifts});
      // }

      // reload specific date
      await this.reloadScreenDirectly(date);

      this.setState({editPlan: false});

      this.isAdding = false;
      
    }, 250);

  }

  async saveTimeDirectlyOtherEmptyShiftUserForce(e, key, i, fid, userID) {

    if (this.timeRef['fes'+key+i+fid+userID].value === ``) { //this.state.planInputs[planData.rooster_id]
      //this.setState({editPlan: false});
      return;
    }

    let day = i === 6 ? 0 : i + 1;
    let date = null;
    let data = this.getAvailabilityData(i, userID, true);

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    // declare start and end
    const start = this.timeRef['fes'+key+i+fid+userID].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
    const end = this.timeRef['fes'+key+i+fid+userID].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

    const startDate = Constants.getDateTZ(date, start);
    const endDate = Constants.getDateTZ(date, end);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

      // set initial value
      this.timeRef['fes'+key+i+fid+userID].value = ``;

      // reset lastVal
      this.lastVal = '';

      alert("Je hebt geen correcte begin- of eindtijd ingevuld");
      return false;
    }

    // if (startDate.getTime() > endDate.getTime()) {

    //   // set initial value
    //   this.timeRef['fes'+key+i+fid+userID].value = ``;

    //   // reset lastVal
    //   this.lastVal = '';

    //   alert("Begintijd kan niet later zijn dan eindtijd");
    //   return false;
    // }

    // remove focus
    this.timeRef['fes'+key+i+fid+userID].blur();

    // reset lastVal
    this.lastVal = '';

    // reset timeRef
    this.timeRef = {};

    // change last edited color
    this.lastEdited = '';
    this.setState({ lastEdited: this.lastEdited }); //editplan

    // save in planner
    const toPlan = {
      rooster_datum: date,
      rooster_begin: start,
      rooster_eind: end,
      rooster_pauze: '00:00:00',
      functie_id: this.state.functionsUser.length > 1 && fid !== this.state.secondaryFunctionID ? this.state.secondaryFunctionID : fid
    };

    await this.planUser(date, data, toPlan);

    Logger.addPlannerDirectly(toPlan, data, start, end);

    // await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${start}', '${end}', NULL, ${fid}, 3, '00:00:00', 0)`);

    // const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

    // if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
    //   await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeam}, ${rid[0].rooster_id})`);
    // }

    // if (Constants.isTrue(Data.data.appOptions.opties_opendienst_beschikbaar)) {
    //   await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${rid[0].rooster_id}, NULL, 0)`);
    //   const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    //   this.setState({userAvailableShifts: userAvailableShifts});
    // }

    // reload specific date
    await this.reloadScreenDirectly(date);

    this.setState({editPlan: false});

  }

  async saveTimeDirectlyOtherEmptyShiftForce(e, key, i, fid) {

    if (this.timeRef['fes'+key+i+fid].value === ``) { //this.state.planInputs[planData.rooster_id]
      //this.setState({editPlan: false});
      return;
    }

    let day = i === 6 ? 0 : i + 1;
    let date = null;

    if(day === 0) {
      date = this.state.sundayDate;
    }
    if(day === 1) {
      date = this.state.mondayDate;
    }
    if(day === 2) {
      date = this.state.tuesdayDate;
    }
    if(day === 3) {
      date = this.state.wednesdayDate;
    }
    if(day === 4) {
      date = this.state.thursdayDate;
    }
    if(day === 5) {
      date = this.state.fridayDate;
    }
    if(day === 6) {
      date = this.state.saturdayDate;
    }

    // declare start and end
    const start = this.timeRef['fes'+key+i+fid].value.substr(0, 5); //this.state.planInputs[planData.rooster_id]
    const end = this.timeRef['fes'+key+i+fid].value.substr(8, 13); //this.state.planInputs[planData.rooster_id]

    const startDate = Constants.getDateTZ(date, start);
    const endDate = Constants.getDateTZ(date, end);

    // check if start and end are correct
    if (start.length !== 5 || end.length !== 5 || this.isValidForSave(start) === false || this.isValidForSave(end) === false) {

      // set initial value
      this.timeRef['fes'+key+i+fid].value = ``;

      // reset lastVal
      this.lastVal = '';

      alert("Je hebt geen correcte begin- of eindtijd ingevuld");
      return false;
    }

    // if (startDate.getTime() > endDate.getTime()) {

    //   // set initial value
    //   this.timeRef['fes'+key+i+fid].value = ``;

    //   // reset lastVal
    //   this.lastVal = '';

    //   alert("Begintijd kan niet later zijn dan eindtijd");
    //   return false;
    // }

    // remove focus
    this.timeRef['fes'+key+i+fid].blur();

    // reset lastVal
    this.lastVal = '';

    // reset timeRef
    this.timeRef = {};

    // change last edited color
    this.lastEdited = '';
    this.setState({ lastEdited: this.lastEdited }); //editplan

    // save in planner
    await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${date}', '${start}', '${end}', NULL, ${fid}, 3, '00:00:00', 0)`);

    const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, ${Data.data.chosenTeamObj.team_id}, ${rid[0].rooster_id})`);
    }

    if (Constants.isTrue(Data.data.appOptions.opties_opendienst_beschikbaar)) {
      await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${rid[0].rooster_id}, NULL, 0)`);
      const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
      this.setState({userAvailableShifts: userAvailableShifts});
    }

    // reload specific date
    await this.reloadScreenDirectly(date);

    this.setState({editPlan: false});

  }

  handleKey(e, planData) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.timeRef[planData.rooster_id].blur();

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async handleKeyOther(e, data, i, prefix) {

    if(e.keyCode === 13){
      this.dontBlur = true;
      e.preventDefault(); // Ensure it is only this code that runs

      //this.timeRef[prefix+data.info_id+i].blur();

      await this.saveTimeDirectlyOtherForce(e, data, i, prefix);

      this.dontBlur = false;

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async handleKeyOtherHoliday(e, data, i, prefix, key) {

    if(e.keyCode === 13){
      this.dontBlur = true;
      e.preventDefault(); // Ensure it is only this code that runs

      //this.timeRef[prefix+data.info_id+i].blur();

      await this.saveTimeDirectlyOtherHolidayForce(e, key, i, data);

      this.dontBlur = false;

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async handleKeyOtherEmptyShift(e, key, i, fid) {

    if(e.keyCode === 13){
      this.dontBlur = true;
      e.preventDefault(); // Ensure it is only this code that runs

      //this.timeRef[prefix+data.info_id+i].blur();

      await this.saveTimeDirectlyOtherEmptyShiftForce(e, key, i, fid);

      this.dontBlur = false;

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async handleKeyOtherEmptyShiftUser(e, key, i, fid, userID) {

    if(e.keyCode === 13){
      this.dontBlur = true;
      e.preventDefault(); // Ensure it is only this code that runs

      //this.timeRef[prefix+data.info_id+i].blur();

      await this.saveTimeDirectlyOtherEmptyShiftUserForce(e, key, i, fid, userID);

      this.dontBlur = false;

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 9 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 88 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  isValidForSave(val) {

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  isValid(val) {

    if (val.length >= 0 && val.length <= 10) {
      val = val.substr(0, 5);
    } else if (val.length >= 9) {
      val = val.substr(8, 13);
    }

    const regexp = /^\d{0,2}?\:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
  }

  onChangeTime(e, planData, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }

    let val = e.target.value.toString();
    // if (val == this.timeRef[planData.rooster_id].value) {
    //     return;
    // } 

    // if(val.charAt(0) !== '' && val.charAt(0) !== '0' && val.charAt(0) !== '1' && val.charAt(0) !== '2') {
    //   console.log('here10')
    //   e.target.value = this.lastVal;
    //   return;
    // }
    // if (val.length == 4) {
    //   if(val.charAt(3) !== '0' && val.charAt(3) !== '1' && val.charAt(3) !== '2' && val.charAt(3) !== '3' && val.charAt(3) !== '4' && val.charAt(3) !== '5') {
    //     console.log('here11')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // if (val.length > 4) {
    //   if(parseInt(val.substr(3, 4)) < 0 && parseInt(val.substr(3, 4)) > 59) {
    //     console.log('here12')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // // ERROR IN HERE (this.lastVal)
    // if (val.length > 8) {
    //   if(val.charAt(8) !== '0' && val.charAt(8) !== '1' && val.charAt(8) !== '2') {
    //     console.log('here13')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // if (val.length > 11) {
      
    //   if(val.charAt(11) !== '0' && val.charAt(11) !== '1' && val.charAt(11) !== '2' && val.charAt(11) !== '3' && val.charAt(11) !== '4' && val.charAt(11) !== '5') {
    //     console.log('here14')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    //   if(parseInt(val.substr(11, 12)) < 0 && parseInt(val.substr(11, 12)) > 59) {
    //     console.log('here15')
    //     e.target.value = this.lastVal;
    //     return;
    //   }
    // }
    // ERROR IN HERE

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}`.length; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal); //e.target.defaultvalue
    } 
    
    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      return false;
    }

    // if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
    //   e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
    //   return false;
    // }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `${planData.rooster_begin.substring(0,5)} - ${planData.rooster_eind.substring(0,5)}` : this.lastVal;
      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false) {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          return false;
        }

        // if (val.length === 20) {
        //   this.props.onTimeChange(val);
        // }

        this.lastDeleted = '';
        this.lastVal = val;

        // let planInputs = this.state.planInputs;
        // planInputs[planData.rooster_id] = val;

        // this.setState({
        //   planInputs: planInputs,
        // });

        e.target.value = val;

    } else {
      e.target.value = '';
    }

  }

  onChangeTimeEmptyShift(e, fromOther) {

    if(this.state.reloading || this.state.loading) {
      e.preventDefault();
      return false;
    }

    let val = e.target.value.toString();

    this.lastDeleted = '';

    const lastLength = this.lastVal.length !== 0 ? this.lastVal.length : fromOther ? '' : 0; //e.target.defaultValue.length;

    if(val.length < lastLength) {
      this.lastDeleted = Constants.stringDiffer(val, this.lastVal === '' ? fromOther ? '' : `` : this.lastVal); //e.target.defaultvalue
    } 
    
    if(val.length > 13) {
      e.target.value = this.lastVal === '' ? e.target.value.slice(0, -1) : this.lastVal;
      return false;
    }

    if (this.lastDeleted === ':' && (val.length !== 10 && val.length !== 2)) {
      const pos = e.target.selectionStart;
      e.target.value = this.lastVal === '' ? fromOther ? '' : `` : this.lastVal;
      e.target.selectionStart = pos;
      e.target.selectionEnd = pos;
      return false;
    }

    let val2 = val.substr(8, 13);

    if (this.isValid(val)) {

        if (val.length === 2 && this.lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && this.lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length === 5 && this.lastVal.length !== 8 && val.includes(' - ') === false) {
          val = val + ' - ';
        }

        if (val.length > 7 && val.includes(' - ') === false) {
          val = val.slice(0, 5);
          val = val + ' - ';
        }

        if (val.length === 7 && (this.lastVal.length === 8)) {
          val = val.slice(0, -3);
        }

        if (this.isValidForSave(val2)) {

          if (val.length === 10 && this.lastVal.length !== 11 && Constants.charCount(val, ':') < 2) {
            val = val + ':';
          }

          if (val.length === 10 && this.lastVal.length === 11 && this.lastDeleted === ':') {
            val = val.slice(0, -1);
          }

        }

        if (val.length > 13) {
          e.target.value = val;
          return false;
        }

        this.lastDeleted = '';
        this.lastVal = val;

        e.target.value = val;

    } else {
      e.target.value = '';
    }

  }

  onClickPlanTime(data, i, table) {

    if (data.rooster_publiceren === '2') {
      return;
    }

    if(this.state.selectedBar !== '') {
      if (table === true) {
        this.onClickUserShiftTable(i, data);
      } else {
        this.onClickUserShift(i, data);
      }
      return;
    }

    this.setState({editPlan: true},
      () => {
        this.timeRef[data.rooster_id].focus();
      }
    );

  }

  closeAllPlanMenus() {

    this.closeAllPlanMenuSubs();

    // add listener
    //window.removeEventListener("click", this.clickOutsideMenu);

    let showPlanMenu = this.state.showPlanMenu;
    let showDayShiftMenu = this.state.showDayShiftMenu;
    let changed = false;

    for (const key in showPlanMenu) {
      showPlanMenu[key] = false;
    }

    for (const key in showDayShiftMenu) {
      showDayShiftMenu[key] = false;
    }

    this.setState({showPlanMenu: showPlanMenu, showDayShiftMenu: showDayShiftMenu, showPlanMenuOuter: false});

  }

  closeAllPlanMenuSubs() {

    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    let showPlanMenuComp = this.state.showPlanMenuComp;
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }

    for (const key in this.emptyPlannerData) {
      for (const plan of this.emptyPlannerData[key]) {
        showPlanMenuInfo[plan.rooster_id] = false;
        showPlanMenuEdit[plan.rooster_id] = false;
        showPlanMenuSpecial[plan.rooster_id] = false;
        showPlanMenuAbsence[plan.rooster_id] = false;
        showPlanMenuComp[plan.rooster_id] = false;
        showPlanMenuBreaks[plan.rooster_id] = false;
      }
    }
    
    this.setState({
      showPlanMenuInfo: showPlanMenuInfo,
      showPlanMenuEdit: showPlanMenuEdit,
      showPlanMenuSpecial: showPlanMenuSpecial,
      showPlanMenuAbsence: showPlanMenuAbsence,
      showPlanMenuComp: showPlanMenuComp,
      showPlanMenuBreaks: showPlanMenuBreaks,
    });

  }

  closePlanMenuInfo(rid) {
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    showPlanMenuInfo[rid] = false;
    this.setState({ showPlanMenuInfo: showPlanMenuInfo });
  }

  openPlanMenuInfo(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuInfo = this.state.showPlanMenuInfo;
    showPlanMenuInfo[rid] = true;
    this.setState({ showPlanMenuInfo: showPlanMenuInfo });
  }

  closePlanMenuEdit(rid) {
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    showPlanMenuEdit[rid] = false;
    this.setState({ showPlanMenuEdit: showPlanMenuEdit });
  }

  openPlanMenuEdit(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuEdit = this.state.showPlanMenuEdit;
    showPlanMenuEdit[rid] = true;
    this.setState({ showPlanMenuEdit: showPlanMenuEdit });
  }

  closePlanMenuSpecial(rid) {
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    showPlanMenuSpecial[rid] = false;
    this.setState({ showPlanMenuSpecial: showPlanMenuSpecial });
  }

  openPlanMenuSpecial(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuSpecial = this.state.showPlanMenuSpecial;
    showPlanMenuSpecial[rid] = true;
    this.setState({ showPlanMenuSpecial: showPlanMenuSpecial });
  }

  closePlanMenuAbsence(rid) {
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    showPlanMenuAbsence[rid] = false;
    this.setState({ showPlanMenuAbsence: showPlanMenuAbsence });
  }

  openPlanMenuAbsence(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuAbsence = this.state.showPlanMenuAbsence;
    showPlanMenuAbsence[rid] = true;
    this.setState({ showPlanMenuAbsence: showPlanMenuAbsence });
  }

  closePlanMenuComp(rid) {
    let showPlanMenuComp = this.state.showPlanMenuComp;
    showPlanMenuComp[rid] = false;
    this.setState({ showPlanMenuComp: showPlanMenuComp });
  }

  openPlanMenuComp(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuComp = this.state.showPlanMenuComp;
    showPlanMenuComp[rid] = true;
    this.setState({ showPlanMenuComp: showPlanMenuComp });
  }

  closePlanMenuBreaks(rid) {
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;
    showPlanMenuBreaks[rid] = false;
    this.setState({ showPlanMenuBreaks: showPlanMenuBreaks });
  }

  openPlanMenuBreaks(rid) {
    this.closeAllPlanMenuSubs();
    let showPlanMenuBreaks = this.state.showPlanMenuBreaks;
    showPlanMenuBreaks[rid] = true;
    this.setState({ showPlanMenuBreaks: showPlanMenuBreaks });
  }

  closePlanMenu(rid) {

    this.closeAllPlanMenuSubs();

    let showPlanMenu = this.state.showPlanMenu;

    showPlanMenu[rid] = false;

    this.setState({ showPlanMenu: showPlanMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  openPlanMenu(rid) {

    this.closeAllPlanMenus();

    this.fromPlanner = [];
    this.setState({ selectedBar: '' });

    // add listener
    //window.addEventListener("click", this.clickOutsideMenu);

    let showPlanMenu = this.state.showPlanMenu;

    showPlanMenu[rid] = true;

    this.setState({ showPlanMenu: showPlanMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  closeDayShiftMenu(rid) {

    this.closeAllPlanMenuSubs();

    let showDayShiftMenu = this.state.showDayShiftMenu;

    showDayShiftMenu[rid] = false;

    this.setState({ showDayShiftMenu: showDayShiftMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  openDayShiftMenu(rid) {

    this.closeAllPlanMenus();

    this.fromPlanner = [];
    this.setState({ selectedBar: '' });

    // add listener
    //window.addEventListener("click", this.clickOutsideMenu);

    let showDayShiftMenu = this.state.showDayShiftMenu;

    showDayShiftMenu[rid] = true;

    this.setState({ showDayShiftMenu: showDayShiftMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  onClickTimeEdit(data) {

    this.closeAllPlanMenus();

    Constants.defaultAlert(
      this,
      'Melding',
      'Je kunt begin- en eindtijden snel wijzigen door erop te klikken en een nieuwe tijd in te voeren'
    );

    if(this.state.editPlan === false) {

      this.setState({editPlan: true},
        () => {
          this.timeRef[data.rooster_id].focus();
        }
      );

    } else {

      this.timeRef[data.rooster_id].focus();

    }

  }

  onClickEditAll(data) {

    this.closeAllPlanMenus();

    if (data.rooster_publiceren === '3') {

      this.setState({
        modalData: data,
        modalMode: 1,
        showModal: true,
        modalDate: Constants.stringToDate(data.rooster_datum),
        reloadScreenMode: 0, //black opacity, with date passing, controlled reloading
      });

    } else {

      this.setState({
        modalData: data,
        modalMode: 4,
        showModal: true,
        modalDate: Constants.stringToDate(data.rooster_datum),
        reloadScreenMode: 0, //black opacity, with date passing, controlled reloading
      });

    }

  }

  async changeFunctionSchedule(data, func) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    if (data.rooster_publiceren === '3') {

      await APIEditUserPlanner.Request(data.rooster_begin, data.rooster_eind, func.functie_id, data.info_id, data.rooster_id, data.rooster_pauze, data.rooster_tijd_incorrect);
      await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_functie_id = '${func.functie_id}'`, `WHERE rooster_id = ${data.rooster_id}`);

    } else {

      await Plan.editUserPlanner(data.rooster_begin.substr(0, 5), data.rooster_eind.substr(0, 5), func.functie_id, data.info_id, data.rooster_id, data.rooster_pauze.substr(0, 5), data.rooster_tijd_incorrect);

      //await APIEditUserPlanner.Request(data.rooster_begin, data.rooster_eind, func.functie_id, data.info_id, data.rooster_id, data.rooster_pauze, data.rooster_tijd_incorrect);

    }

    await this.reloadScreen(data.rooster_datum);

  }

  renderFunctionMenu(data, left) { // data = plannerid data

    let functions = [];

    for (const func of this.allFunctionsData) {

      if (data.functie_id === func.functie_id) {
        functions.push(
          <div className='planner_planMenuSub_row' style={{cursor: 'default'}}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset' style={{color: '#282828'}}>{func.functie_naam}</b>
            </div>
          </div>
        );
      } else {
        functions.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeFunctionSchedule(data, func)}>
            <div className='planner_planMenuSub_row_sub1'>
              {func.functie_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'functions' })} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer functies
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' />

        {functions}
      </div>
    );

  }

  async changeBreakSchedule(data, brk, add) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    if (data.rooster_publiceren === '3') {

      if(add === true) {
        await APIEditUserPlanner.Request(data.rooster_begin, data.rooster_eind, data.functie_id, data.info_id, data.rooster_id, brk.brk_tijd, data.rooster_tijd_incorrect);
        await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_pauze = '${brk.brk_tijd}'`, `WHERE rooster_id = ${data.rooster_id}`);
      } else {
        await APIEDIT.Request(`UPDATE Rooster`, `SET rooster_pauze = '00:00:00'`, `WHERE rooster_id = ${data.rooster_id}`);
      }

    } else {

      if(add === true) {
        await APIEditUserPlanner.Request(data.rooster_begin, data.rooster_eind, data.functie_id, data.info_id, data.rooster_id, brk.brk_tijd, data.rooster_tijd_incorrect);
      } else {
        await APIEditUserPlanner.Request(data.rooster_begin, data.rooster_eind, data.functie_id, data.info_id, data.rooster_id, '00:00:00', data.rooster_tijd_incorrect);
      }

    }

    await this.reloadScreen(data.rooster_datum);

  }

  renderBreaksMenu(data, left) { // data = plannerid data

    let particularities = [];

    for (const part of this.state.breaks) {

      if (part.brk_tijd === data.rooster_pauze) {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeBreakSchedule(data, false)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{part.brk_tijd.substr(0,5)}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeBreakSchedule(data, part, true)}>
            <div className='planner_planMenuSub_row_sub1'>
              {part.brk_tijd.substr(0,5)}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'breaks' })} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer pauzecodes
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        {data.rooster_pauze !== '00:00:00' ?
          <div className='planner_planMenuSubInfo_row' style={{marginBottom: '4%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Huidige pauze: {data.rooster_pauze.substr(0,5)}
            </div>
            <div className='planner_planMenuSubInfo_row_sub3' style={{flex: 0.2}}>
              <i className='fas fa-minus-circle planMenuAsset' style={{color: Colors.color.redFilledIn, cursor: 'pointer', fontSize: '0.8vw'}} onClick={() => this.changeBreakSchedule(data, null, false)} />
            </div>
          </div>
          : null}

        <div className='divider' />

        {particularities}
      </div>
    );

  }

  async changeParticularitySchedule(part, data, add) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    const absenceSchedule = this.state.absenceSchedules[data.rooster_id];

    if(add === true) {

      await APIDeletePartSchedule.Request(data.rooster_id);
      await APIAddPartSchedule.Request(part.bijz_id, data.rooster_id);

      if (data.rooster_publiceren === '1' && typeof absenceSchedule === 'undefined') {
        const dateObject = Constants.stringToDate(data.rooster_datum);
        KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.toLocaleDateString()}: ${part.bijz_naam}`);
      }

    } else {

      await APIDeletePartSchedule.Request(data.rooster_id);

      if (data.rooster_publiceren === '1' && typeof absenceSchedule === 'undefined') {
        const dateObject = Constants.stringToDate(data.rooster_datum);
        KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.toLocaleDateString()}: Geen bijzonderheden`);
      }

    }

    const partSchedules = await APIGetAllPartSchedules.Request(this.state.mondayDate, this.state.sundayDate);
    const partSchedulesObj = Constants.convertToObject(partSchedules, 'rooster_id');

    this.setState({ partSchedules: partSchedulesObj }); 

  }

  renderSpecialMenu(data, left, partID) { // data = plannerid data

    let particularities = [];

    for (const part of this.state.particularities) {

      if (part.bijz_id === partID) {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeParticularitySchedule(part, data, false)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{part.bijz_naam}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        particularities.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeParticularitySchedule(part, data, true)}>
            <div className='planner_planMenuSub_row_sub1'>
              {part.bijz_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'part' })} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer bijzonderheidstypes
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' />

        {particularities}
      </div>
    );

  }

  async changeAbsenceSchedule(absence, data, add) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    const partSchedule = this.state.partSchedules[data.rooster_id];

    if(add === true) {

      await APIDeleteAbsenceSchedule.Request(data.rooster_id);
      await APIAddAbsenceSchedule.Request(absence.afw_id, data.rooster_id);

      if (data.rooster_publiceren === '1') {
        const dateObject = Constants.stringToDate(data.rooster_datum);
        KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.toLocaleDateString()}: ${absence.afw_naam}`);
      }

    } else {

      await APIDeleteAbsenceSchedule.Request(data.rooster_id);

      if (data.rooster_publiceren === '1') {

        const dateObject = Constants.stringToDate(data.rooster_datum);

        if (typeof partSchedule !== 'undefined') {
          KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.toLocaleDateString()}: Geen absentie/${partSchedule.bijz_naam}`);
        } else {
          KodictNotifications.sendPushNotification(data.info_id, 'planner', `Statuswijziging rooster`, `Status ${Constants.getDayName(data.rooster_datum).toLowerCase()} ${dateObject.toLocaleDateString()}: Geen absentie`);
        }

      }

    }

    const absenceSchedules = await APIGetAllAbsenceSchedules.Request(this.state.mondayDate, this.state.sundayDate);
    const absenceSchedulesObj = Constants.convertToObject(absenceSchedules, 'rooster_id');

    this.setState({ absenceSchedules: absenceSchedulesObj }, () => {

      this.getSalarisDagen(this.state.contractsObj, this.state.dailyGains, this.state.dailyGainsExpect, this.state.exclusions);

    }); 

  }

  renderMenuAbsence(data, left, absence) { // data = plannerid data

    let toRender = [];

    if (absence === null || typeof absence === 'undefined') {
      absence = { afw_id: null, afw_naam: null, afw_afkorting: null };
    }

    for (const type of this.state.absencetypes) {

      if (type.afw_id === absence.afw_id) {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeAbsenceSchedule(type, data, false)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset'>{type.afw_naam}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeAbsenceSchedule(type, data, true)}>
            <div className='planner_planMenuSub_row_sub1'>
              {type.afw_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }
      
    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'abs' })} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer afwezigheidstypes
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' />

        {toRender}
      </div>
    );

  }

  async changeSkillsSchedule(cid, rid, add) {

    if (this.state.loading || this.state.reloading || this.isPlanning) {
      return;
    } 

    if(add === true) {
      await APIDeleteSkillSchedule.Request(cid, rid);
      await APIAddSkillSchedule.Request(cid, rid);
    } else {
      await APIDeleteSkillSchedule.Request(cid, rid);
    }

    const skillSchedules = await APIGetAllSkillSchedules.Request(this.state.mondayDate, this.state.sundayDate);
    const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');
    
    //reload planner data
    const dateObj = skillSchedules.length > 0 ? Constants.stringToDate(skillSchedules[0].rooster_datum) : null;
    if (dateObj !== null) {
      const day = dateObj.getDay();
      if (day === 0) {
        this.plannerData.sun = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.sun = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      } else if (day === 1) {
        this.plannerData.mon = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.mon = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      } else if (day === 2) {
        this.plannerData.tue = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.tue = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      } else if (day === 3) {
        this.plannerData.wed = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.wed = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      } else if (day === 4) {
        this.plannerData.thu = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.thu = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      } else if (day === 5) {
        this.plannerData.fri = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.fri = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      } else if (day === 6) {
        this.plannerData.sat = await APIGetPlanner.Request(skillSchedules[0].rooster_datum);
        this.emptyPlannerData.sat = await APIGET.Request(`SELECT *`, `FROM Rooster`, `LEFT JOIN Functie ON functie_id = rooster_functie_id`, `WHERE rooster_datum = '${skillSchedules[0].rooster_datum}' AND rooster_info_id IS NULL`, null, `ORDER BY functie_naam, rooster_begin, rooster_eind`);
      }
    }

    this.setState({ skillSchedules: skillSchedulesObj }); 

  }

  renderMenuSkills(data, left, skills) { // data = plannerid data

    let toRender = [];
    let skillsUser = null;

    const userSkills = this.state.skillsUsers[data.info_id];
    if (typeof userSkills !== 'undefined') {
      skillsUser = userSkills;
    }

    for (const type of this.state.skills) {

      if (skills !== null && Constants.objectArrayContainsKey(skills, 'comp_naam', type.comp_naam)) {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeSkillsSchedule(type.comp_id, data.rooster_id, false)}>
            <div className='planner_planMenuSub_row_sub1'>
              <b className='planMenuAsset' style={skillsUser === null || Constants.objectArrayContainsKey(skillsUser, 'comp_naam', type.comp_naam) === false ? {color: Colors.color.redFilledIn} : null}>{type.comp_naam}</b>
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-times planMenuAsset' />
            </div>
          </div>
        );
      } else {
        toRender.push(
          <div className='planner_planMenuSub_row' onClick={() => this.changeSkillsSchedule(type.comp_id, data.rooster_id, true)}>
            <div className='planner_planMenuSub_row_sub1'>
              {type.comp_naam}
            </div>
            <div className='planner_planMenuSub_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        );
      }

    }

    return (
      <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

        <div className='planner_planMenuSub_row' onClick={() => this.props.history.push({ pathname: `/app_options`, menu: 'skills' })} style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSub_row_sub1'>
            Beheer competenties
          </div>
          <div className='planner_planMenuSub_row_sub3'>
            <i className='fal fa-plus planMenuAsset' />
          </div>
        </div>

        <div className='divider' /> 

        {toRender}
      </div>
    );

  }

  // beschikbaar maken
  async putShiftOnAvailable(planData) {

    this.setState({reloading: true});

    this.closeAllPlanMenus();

    const replace = await APICanReplaceShift.Request(planData.rooster_id);

    if (replace === false) {
      alert("Deze dienst wordt geruild of is al beschikbaar gemaakt");
      this.setState({reloading: false});
      return;
    }

    // if (typeof this.state.partSchedules[planData.rooster_id] !== 'undefined') {
    //   alert("Dit is een bijzondere dienst en kan dus niet op beschikbaar worden gezet");
    //   this.setState({reloading: false});
    //   return;
    // }

    let users = await APIGetAllAccountsTrade.Request(planData.rooster_datum, Data.data.accountData.info_id);
    let usersToSearch = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      const teamID = Constants.getTeamIDFromScheduleID(this.teamPlanner, planData.rooster_id);
      users = Constants.getTeamAccountsT(this.teamUsers, users, parseInt(teamID));
    }

    for (const person of users) {

      // trade without function
      if (Constants.isTrue(Data.data.appOptions.opties_ruilenGeenFunctie)) {

        if (planData.functie_id === person.functie_id || person.functie_id === null) {

            // check comp setting
            if (Constants.isTrue(Data.data.appOptions.opties_ruilenComp)) {

              if (typeof this.state.skillSchedules[planData.rooster_id] === 'undefined') {
                  usersToSearch.push(person);
                  continue;
              }   

              // check if user has comp
              if (typeof this.state.skillsUsers[person.account_id] !== 'undefined') {

                  let userHasAllSkills = true;
                  for (const skill of this.state.skillSchedules[planData.rooster_id]) {
                      const userHasSkill = Constants.objectArrayContainsKey(this.state.skillsUsers[person.account_id], 'comp_naam', skill.comp_naam);
                      if (userHasSkill === false) {
                          userHasAllSkills = false;
                          break;
                      }
                  }

                  if (userHasAllSkills === true) {
                      usersToSearch.push(person);
                  }

              }

            } else {

              usersToSearch.push(person);

            }

        }

      } else {

        if (planData.functie_id === person.functie_id || this.personHasSecundaryFunction(person, planData.functie_id) === true) {

          // check comp setting
          if (Constants.isTrue(Data.data.appOptions.opties_ruilenComp)) {

            if (typeof this.state.skillSchedules[planData.rooster_id] === 'undefined') {
                usersToSearch.push(person);
                continue;
            }  

            // check if user has comp
            if (typeof this.state.skillsUsers[person.account_id] !== 'undefined') {

                let userHasAllSkills = true;
                for (const skill of this.state.skillSchedules[planData.rooster_id]) {
                    const userHasSkill = Constants.objectArrayContainsKey(this.state.skillsUsers[person.account_id], 'comp_naam', skill.comp_naam);
                    if (userHasSkill === false) {
                        userHasAllSkills = false;
                        break;
                    }
                }

                if (userHasAllSkills === true) {
                    usersToSearch.push(person);
                }

            }

          } else {

              usersToSearch.push(person);

          }

        }

      }

    }

    let added  = false;

    if (planData.rooster_publiceren === '3') { // empty shift
      added = await APIADD.Request(`INSERT INTO BeschikbareDienst`, `VALUES (NULL, ${planData.rooster_id}, NULL, 0)`);
    } else {
      added = await APIAddToAvailableShifts.Request(planData.info_id, planData.rooster_id);
    }

    if (added === true) {

      const dateObject = Constants.stringToDate(planData.rooster_datum);

      const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam : planData.info_achternaam}`; //.charAt(0)

      for (const user of usersToSearch) {
        if (planData.rooster_publiceren === '3') {
          KodictNotifications.sendPushNotification(user.account_id, 'trade', 'Vervanging', `Er is een open dienst beschikbaar van ${planData.rooster_begin.substr(0,5)} - ${planData.rooster_eind.substr(0,5)} op ${Constants.getDayName(planData.rooster_datum, true)} ${dateObject.toLocaleDateString()}. Kun jij werken?`);
        } else {
          KodictNotifications.sendPushNotification(user.account_id, 'trade', 'Vervanging', `${userFullName} zoekt vervanging voor de dienst van ${planData.rooster_begin.substr(0,5)} - ${planData.rooster_eind.substr(0,5)} op ${Constants.getDayName(planData.rooster_datum, true)} ${dateObject.toLocaleDateString()}. Kun jij hem/haar vervangen?`);
        }
      }

      const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
      
      this.setState({userAvailableShifts: userAvailableShifts});

    }

    this.setState({reloading: false});

  }

  async removeShiftAvailable(planData) {

    this.setState({reloading: true});

    this.closeAllPlanMenus();

    await APIDELETE.Request(`DELETE FROM BeschikbareDienst`, `WHERE bdienst_rooster_id = ${planData.rooster_id}`);

    const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
      
    this.setState({userAvailableShifts: userAvailableShifts, reloading: false});

  }

  renderRepeatingShiftMenu(data, left, date) {

    return (

      <div draggable='false' className={`planner_planMenu${left ? '_left' : ''}`}>

        <div className='planner_planMenu_row' onClick={() => this.openRepeatShiftModal(data, 1)}>
          <div className='planner_planMenu_row_sub1'>
            <i className='fal fa-history planMenuAsset' />
          </div>
          <div className='planner_planMenu_row_sub2'>
            Wijzigen
          </div>
          <div className='planner_planMenu_row_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

        <div className='planner_planMenu_deleteRow' onClick={() => this.endRepeatingShiftAlert(data, date)}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-calendar-times planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Herh. dienst beëindigen
          </div>
        </div>

        {/* <div className='planner_planMenu_deleteRow' onClick={() => this.deleteUserPlannerAlert(data)} style={{marginTop: 0}}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-trash-alt planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Herh. dienst verwijderen
          </div>
        </div> */}

      </div>

    );
    
  }

  renderPlanMenu(data, left, partID, absence, skills, hasAllSkills, isAvailableShift) {

    return (

      <div draggable='false' className={`planner_planMenu${left ? '_left' : ''}`}>

        {/* <div className='planner_planMenu_triangle' /> */}

        {/* <div className='planner_planMenu_closeRow' onClick={() => this.closePlanMenu(data.rooster_id)}>
          <div className='planner_planMenu_closeRow_sub1'>
            <i className='fal fa-times' />
          </div>
          <div className='planner_planMenu_closeRow_sub2'>
            Sluiten
          </div>
          <div className='planner_planMenu_closeRow_sub3'>
            &nbsp;
          </div>
        </div> */}

        {data.rooster_publiceren !== '3' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuInfo[data.rooster_id] === true ? 
                this.closePlanMenuInfo(data.rooster_id) 
              :
                this.openPlanMenuInfo(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-info-circle planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Informatie
              </div>
              <div className='planner_planMenu_row_sub3' style={this.state.showPlanMenuInfo[data.rooster_id] === true ? {color: '#47B7EB'} : null}>
                <i className='fal fa-arrow-right planMenuAsset' />
              </div>
            </div>

            {this.state.showPlanMenuInfo[data.rooster_id] === true ?

              this.getHoursTooltip(data, left)

            : null}

          </div>
        : null}

        {/* <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuEdit[data.rooster_id] === true ? 
              this.closePlanMenuEdit(data.rooster_id) 
            :
              this.openPlanMenuEdit(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-edit planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Aanpassen
            </div>
            <div className='planner_planMenu_row_sub3' style={this.state.showPlanMenuEdit[data.rooster_id] === true ? {color: '#47B7EB'} : null}>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>

          {this.state.showPlanMenuEdit[data.rooster_id] === true ?

            <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickTimeEdit(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Tijd
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickEditAll(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Medewerker / functie / pauze
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>
              
            </div>

          : null}

        </div> */}

        <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuEdit[data.rooster_id] === true ? 
              this.closePlanMenuEdit(data.rooster_id) 
            :
              this.openPlanMenuEdit(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-briefcase planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Functie
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className={`fal fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuEdit[data.rooster_id] === true ? {color: '#47B7EB'} : null}/>
            </div>
          </div>

          {this.state.showPlanMenuEdit[data.rooster_id] === true ?

            this.renderFunctionMenu(data, left)

          : null}

        </div>

        <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuBreaks[data.rooster_id] === true ? 
              this.closePlanMenuBreaks(data.rooster_id) 
            :
              this.openPlanMenuBreaks(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-coffee planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Pauzes
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className={`fa${data.rooster_pauze !== '00:00:00' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuBreaks[data.rooster_id] === true ? {color: '#47B7EB'} : null}/>
            </div>
          </div>

          {this.state.showPlanMenuBreaks[data.rooster_id] === true ?

            this.renderBreaksMenu(data, left)

          : null}

        </div>

        {data.rooster_publiceren !== '3' ?
          <div className='planner_planMenu_row' onClick={() => this.openRepeatShiftModal(data, 0)}>
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-history planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Dienst herhalen
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        : null}

        <div className='planner_planMenu_row' onClick={() => this.duplicateShift(data)}>
          <div className='planner_planMenu_row_sub1'>
            <i className='fal fa-clone planMenuAsset' />
          </div>
          <div className='planner_planMenu_row_sub2'>
            Dupliceren
          </div>
          <div className='planner_planMenu_row_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

        {Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuSpecial[data.rooster_id] === true ? 
                this.closePlanMenuSpecial(data.rooster_id) 
              :
                this.openPlanMenuSpecial(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-briefcase planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Bijzonderheden
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${partID !== null ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuSpecial[data.rooster_id] === true ? {color: '#47B7EB'} : null}/>
              </div>
            </div>

            {this.state.showPlanMenuSpecial[data.rooster_id] === true ?

              this.renderSpecialMenu(data, left, partID)

            : null}

          </div>
        : null}

        {data.rooster_publiceren !== '3' && Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuAbsence[data.rooster_id] === true ? 
                this.closePlanMenuAbsence(data.rooster_id) 
              :
                this.openPlanMenuAbsence(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-user-minus planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Afwezigheid
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${absence !== null && typeof absence !== 'undefined' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuAbsence[data.rooster_id] === true ? {color: '#47B7EB'} : null} />
              </div>
            </div>
            
            {this.state.showPlanMenuAbsence[data.rooster_id] === true ?

              this.renderMenuAbsence(data, left, absence)

            : null}

          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuComp[data.rooster_id] === true ? 
                this.closePlanMenuComp(data.rooster_id) 
              :
                this.openPlanMenuComp(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-graduation-cap planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Competenties
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${skills !== null && typeof skills !== 'undefined' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuComp[data.rooster_id] === true ? {color: '#47B7EB'} : hasAllSkills === false ? {color: Colors.color.redFilledIn} : null} />
              </div>
            </div>
            
            {this.state.showPlanMenuComp[data.rooster_id] === true ?

              this.renderMenuSkills(data, left, skills)

            : null}

          </div>
        : null}

        {(data.rooster_publiceren === '1' || data.rooster_publiceren === '3') && data.functie_id !== null ? //Constants.isTrue(Data.data.appOptions.opties_planBeschikbaar) && 
          isAvailableShift === true ?
            <div className='planner_planMenu_deleteRow' onClick={() => this.removeShiftAvailable(data)}>
              <div className='planner_planMenu_deleteRow_sub1'>
                <i className='fal fa-search-minus planMenuAsset' />
              </div>
              <div className='planner_planMenu_deleteRow_sub2'>
                Niet meer beschikbaar
              </div>
            </div>
          :
            <div className='planner_planMenu_availableRow' onClick={() => this.putShiftOnAvailable(data)}>
              <div className='planner_planMenu_availableRow_sub1'>
                <i className='fal fa-search planMenuAsset' />
              </div>
              <div className='planner_planMenu_availableRow_sub2'>
                Beschikbaar maken
              </div>
            </div>
        : null}

          {/* Constants.isTrue(Data.data.appOptions.opties_planBeschikbaar) &&  */}
        <div className='planner_planMenu_deleteRow' onClick={() => this.deleteUserPlannerAlert(data)} style={{marginTop: (data.rooster_publiceren === '1' || data.rooster_publiceren === '3') && data.functie_id !== null ? 0 : null}}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-trash-alt planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Verwijderen
          </div>
        </div>

      </div>

    );
    
  }

  async putOpenShiftOnAvailable(planGroup) {

    this.closeAllPlanMenus();

    // if (typeof this.state.partDayShifts['d' + shift.dagdienst_id] !== 'undefined') {
    //   alert("Dit is een bijzondere dienst en kan dus niet op beschikbaar worden gezet");
    //   return;
    // }

    this.setState({ reloading: true });

    if (Constants.isEmpty(planGroup.shifts)) {

      const shift = planGroup;

      // if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === false) {
      //   return;
      // }

      let date = this.state.mondayDate; //monday

      if (shift.dagdienst_dag === '2') { //tuesday
          date = this.state.tuesdayDate;
      } else if (shift.dagdienst_dag === '3') {
          date = this.state.wednesdayDate;
      } else if (shift.dagdienst_dag === '4') {
          date = this.state.thursdayDate;
      } else if (shift.dagdienst_dag === '5') {
          date = this.state.fridayDate;
      } else if (shift.dagdienst_dag === '6') {
          date = this.state.saturdayDate;
      } else if (shift.dagdienst_dag === '0') {
          date = this.state.sundayDate;
      }

      await APIADD.Request(`INSERT INTO ShiftBeschikbaar`, `VALUES (NULL, ${shift.dagdienst_id}, '${date}', ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`);

    } else {

      for (const shift of planGroup.shifts) {

        let date = this.state.mondayDate; //monday

        if (shift.dagdienst_dag === '2') { //tuesday
            date = this.state.tuesdayDate;
        } else if (shift.dagdienst_dag === '3') {
            date = this.state.wednesdayDate;
        } else if (shift.dagdienst_dag === '4') {
            date = this.state.thursdayDate;
        } else if (shift.dagdienst_dag === '5') {
            date = this.state.fridayDate;
        } else if (shift.dagdienst_dag === '6') {
            date = this.state.saturdayDate;
        } else if (shift.dagdienst_dag === '0') {
            date = this.state.sundayDate;
        }

        await APIADD.Request(`INSERT INTO ShiftBeschikbaar`, `VALUES (NULL, ${shift.dagdienst_id}, '${date}', ${Data.data.teams.length > 0 && Data.data.chosenTeam !== null ? Data.data.chosenTeamObj.team_id : 'NULL'})`);

      }

    }

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    }

    this.setState({plannerAvailableShifts: plannerAvailableShifts, reloading: false });

  }

  async putOpenShiftOnUnavailable(planGroup, all = false) {
 
    this.closeAllPlanMenus();

    this.setState({ reloading: true });

    if (Constants.isEmpty(planGroup.shifts)) {

      const shift = planGroup;

      if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === false) {
        return;
      }

      let date = this.state.mondayDate; //monday

      if (shift.dagdienst_dag === '2') { //tuesday
          date = this.state.tuesdayDate;
      } else if (shift.dagdienst_dag === '3') {
          date = this.state.wednesdayDate;
      } else if (shift.dagdienst_dag === '4') {
          date = this.state.thursdayDate;
      } else if (shift.dagdienst_dag === '5') {
          date = this.state.fridayDate;
      } else if (shift.dagdienst_dag === '6') {
          date = this.state.saturdayDate;
      } else if (shift.dagdienst_dag === '0') {
          date = this.state.sundayDate;
      }

      await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id} AND shb_datum = '${date}'`);

    } else {

      // if (all === true) {

        for (const shift of planGroup.shifts) {

          if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === false) {
            continue;
          }

          let date = this.state.mondayDate; //monday

          if (shift.dagdienst_dag === '2') { //tuesday
              date = this.state.tuesdayDate;
          } else if (shift.dagdienst_dag === '3') {
              date = this.state.wednesdayDate;
          } else if (shift.dagdienst_dag === '4') {
              date = this.state.thursdayDate;
          } else if (shift.dagdienst_dag === '5') {
              date = this.state.fridayDate;
          } else if (shift.dagdienst_dag === '6') {
              date = this.state.saturdayDate;
          } else if (shift.dagdienst_dag === '0') {
              date = this.state.sundayDate;
          }

          await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id} AND shb_datum = '${date}'`);

        }

      // } else {

      //   const shift = planGroup.shifts[0];

      //   if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === false) {
      //     return;
      //   }

      //   let date = this.state.mondayDate; //monday

      //   if (shift.dagdienst_dag === '2') { //tuesday
      //       date = this.state.tuesdayDate;
      //   } else if (shift.dagdienst_dag === '3') {
      //       date = this.state.wednesdayDate;
      //   } else if (shift.dagdienst_dag === '4') {
      //       date = this.state.thursdayDate;
      //   } else if (shift.dagdienst_dag === '5') {
      //       date = this.state.fridayDate;
      //   } else if (shift.dagdienst_dag === '6') {
      //       date = this.state.saturdayDate;
      //   } else if (shift.dagdienst_dag === '0') {
      //       date = this.state.sundayDate;
      //   }

      //   await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id} AND shb_datum = '${date}'`);


      // }

    }

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    }

    this.setState({plannerAvailableShifts: plannerAvailableShifts, reloading: false });

  }

  async putOpenShiftOnUnavailable_auto(planGroup) {

    if (Constants.isEmpty(planGroup.shifts)) {

      const shift = planGroup;

      if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === false) {
        return;
      }

      let date = this.state.mondayDate; //monday

      if (shift.dagdienst_dag === '2') { //tuesday
          date = this.state.tuesdayDate;
      } else if (shift.dagdienst_dag === '3') {
          date = this.state.wednesdayDate;
      } else if (shift.dagdienst_dag === '4') {
          date = this.state.thursdayDate;
      } else if (shift.dagdienst_dag === '5') {
          date = this.state.fridayDate;
      } else if (shift.dagdienst_dag === '6') {
          date = this.state.saturdayDate;
      } else if (shift.dagdienst_dag === '0') {
          date = this.state.sundayDate;
      }

      await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id} AND shb_datum = '${date}'`);

    } else {

      // if (all === true) {

        for (const shift of planGroup.shifts) {

          if (Constants.objectArrayContains(this.state.plannerAvailableShifts, shift.dagdienst_id) === false) {
            continue;
          }

          let date = this.state.mondayDate; //monday

          if (shift.dagdienst_dag === '2') { //tuesday
              date = this.state.tuesdayDate;
          } else if (shift.dagdienst_dag === '3') {
              date = this.state.wednesdayDate;
          } else if (shift.dagdienst_dag === '4') {
              date = this.state.thursdayDate;
          } else if (shift.dagdienst_dag === '5') {
              date = this.state.fridayDate;
          } else if (shift.dagdienst_dag === '6') {
              date = this.state.saturdayDate;
          } else if (shift.dagdienst_dag === '0') {
              date = this.state.sundayDate;
          }

          await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_dagdienst_id = ${shift.dagdienst_id} AND shb_datum = '${date}'`);

        }

    }

  }

  renderDayShiftMenu(data, left, partName, skills, isAvailableShift) {

    let bijzText = 'Geen bijzonderheden';
    let skillsText = 'Geen competenties';

    if (partName !== null) {
      bijzText = partName;
    }

    if (skills !== null) {
      skillsText = [];
      for (const skill of skills) {
        skillsText.push(
          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              - {skill.comp_naam}
            </div>
          </div>
        );
      }
    }

    return (

      <div draggable='false' className={`planner_planMenu${left ? '_left' : ''}`}>

        {/* <div className='planner_planMenu_triangle' /> */}

        {/* BIJZONDERHEID */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div>
            <div className='planner_planMenuSubInfo_row' style={{marginTop: '1%', marginBottom: '1%'}}>
              <div className='planner_planMenuSubInfo_row_sub1'>
                <b className='planMenuAsset'>Bijzonderheden</b>
              </div>
            </div>

            <div className='planner_planMenuSubInfo_row' style={{marginBottom: '3%'}}>
              <div className='planner_planMenuSubInfo_row_sub1'>
                {bijzText}
              </div>
            </div>

            <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '1%'}}>
              <div className='planner_planMenuSubInfo_row_sub1'>
                <b className='planMenuAsset'>Competenties</b>
              </div>
            </div>

            {typeof skillsText === 'string' ?

              <div className='planner_planMenuSubInfo_row' style={{marginBottom: '1%'}}>
                <div className='planner_planMenuSubInfo_row_sub1'>
                  {skillsText}
                </div>
              </div>

            : skillsText}
          </div>
        : null}

        {data.functie_id !== null ?
          isAvailableShift === true ?
            <div className='planner_planMenu_deleteRow' onClick={() => this.putOpenShiftOnUnavailable(data, true)} style={Data.data.appOptions.opties_versie !== '0' ? {} : {marginTop: 0}}>
              <div className='planner_planMenu_deleteRow_sub1'>
                <i className='fal fa-search-minus planMenuAsset' />
              </div>
              <div className='planner_planMenu_deleteRow_sub2'>
                Niet meer beschikbaar
              </div>
            </div>
          :
            <div className='planner_planMenu_availableRow' onClick={() => this.putOpenShiftOnAvailable(data)} style={Data.data.appOptions.opties_versie !== '0' ? {} : {marginTop: 0}}>
              <div className='planner_planMenu_availableRow_sub1'>
                <i className='fal fa-search planMenuAsset' />
              </div>
              <div className='planner_planMenu_availableRow_sub2'>
                Beschikbaar maken
              </div>
            </div>
        : null}

      </div>
    );
    
  }

  renderDayShiftMenuTable(data, left, partName, skills, isAvailableShift) {

    let bijzText = 'Geen bijzonderheden';
    let skillsText = 'Geen competenties';

    if (partName !== null) {
      bijzText = partName;
    }

    if (skills !== null) {
      skillsText = [];
      for (const skill of skills) {
        skillsText.push(
          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              - {skill.comp_naam}
            </div>
          </div>
        );
      }
    }

    return (

      <div draggable='false' className={`planner_planMenu_table${left ? '_left' : ''}`} style={{top: this.state.display === 'XCL' ? 'calc(1.1vw + 2px)' : null}}>

        {/* <div className='planner_planMenu_triangle' /> */}

        {/* BIJZONDERHEID */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div>
            <div className='planner_planMenuSubInfo_row' style={{marginTop: '1%', marginBottom: '1%'}}>
              <div className='planner_planMenuSubInfo_row_sub1'>
                <b className='planMenuAsset'>Bijzonderheden</b>
              </div>
            </div>

            <div className='planner_planMenuSubInfo_row' style={{marginBottom: '3%'}}>
              <div className='planner_planMenuSubInfo_row_sub1'>
                {bijzText}
              </div>
            </div>

            <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '1%'}}>
              <div className='planner_planMenuSubInfo_row_sub1'>
                <b className='planMenuAsset'>Competenties</b>
              </div>
            </div>

            {typeof skillsText === 'string' ?

              <div className='planner_planMenuSubInfo_row' style={{marginBottom: '1%'}}>
                <div className='planner_planMenuSubInfo_row_sub1'>
                  {skillsText}
                </div>
              </div>

            : skillsText}
          </div>
        : null}

        {data.shifts[0].functie_id !== null ?
          isAvailableShift === true ?
            <div className='planner_planMenu_deleteRow' onClick={() => this.putOpenShiftOnUnavailable(data, true)} style={Data.data.appOptions.opties_versie !== '0' ? {} : {marginTop: 0}}>
              <div className='planner_planMenu_deleteRow_sub1'>
                <i className='fal fa-search-minus planMenuAsset' />
              </div>
              <div className='planner_planMenu_deleteRow_sub2'>
                Niet meer beschikbaar
              </div>
            </div>
          :
            <div className='planner_planMenu_availableRow' onClick={() => this.putOpenShiftOnAvailable(data)} style={Data.data.appOptions.opties_versie !== '0' ? {} : {marginTop: 0}}>
              <div className='planner_planMenu_availableRow_sub1'>
                <i className='fal fa-search planMenuAsset' />
              </div>
              <div className='planner_planMenu_availableRow_sub2'>
                Beschikbaar maken
              </div>
            </div>
        : null}

      </div>
    );
    
  }

  newPlannerBar(planData, i, left) {

    // check particularities
    let partColor = null;
    let partAfk = null;
    let partID = null;
    let partName = null;
    const partSchedule = this.state.partSchedules[planData.rooster_id];
    if (typeof partSchedule !== 'undefined') {
      partColor = partSchedule.bijz_kleur;
      partAfk = partSchedule.bijz_afkorting;
      partID = partSchedule.bijz_id;
    }

    // check absences
    let absence = null;
    const absenceSchedule = this.state.absenceSchedules[planData.rooster_id];
    if (typeof absenceSchedule !== 'undefined') {
      absence = absenceSchedule;
    }

    // check comp
    let skills = null;
    let skillsRender = [];
    let userSkills = [];
    let userHasAllSkills = true;
    const skillSchedule = this.state.skillSchedules[planData.rooster_id];
    const skillUser = this.state.skillsUsers[planData.info_id];
    if (typeof skillSchedule !== 'undefined') {
      skills = skillSchedule;
    }
    if (typeof skillUser !== 'undefined') {
      userSkills = skillUser;
    }

    if (skills !== null) {
      for (const skill of skills) {
        const userHasSkill = Constants.objectArrayContainsKey(userSkills, 'comp_naam', skill.comp_naam);
        if (userHasSkill === false) {
          userHasAllSkills = false;
          break;
        }
      }
      for (const skill of skills) {
        skillsRender.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(skillUser, skill) ? null : Colors.color.redFilledIn}}>• {skill.comp_afkorting}</span>
          </div>
        );
      }
    }

    let isAvailableShift = false;
    if (Constants.objectArrayContains(this.state.userAvailableShifts, planData.rooster_id) === true) {
      isAvailableShift = true;
    }

    const hoursWeek = this.getUserHoursWeek(planData.info_id);

    if (planData.rooster_publiceren === '2') {

      // check particularities
      const partSchedule2 = this.state.partDayShifts[planData.rooster_id];
      if (typeof partSchedule2 !== 'undefined') {
        partColor = partSchedule2.bijz_kleur;
        partAfk = partSchedule2.bijz_afkorting;
        partID = partSchedule2.bijz_id;
        partName = partSchedule2.bijz_naam;
      }

      const skillSchedule2 = this.state.skillDayShifts[planData.rooster_id];
      if (typeof skillSchedule2 !== 'undefined') {
        skills = skillSchedule2;
      }

      if (Constants.objectArrayContains(this.state.plannerAvailableShifts, planData.rooster_id.replace('d', '')) === true) {
        isAvailableShift = true;
      }

    }

    const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;

    const infoExtra = typeof this.state.infoExtra[planData.info_id] !== 'undefined' ? this.state.infoExtra[planData.info_id] : null;

    return (

      <div key={planData.rooster_id} className='plannerN_shiftOuter'>

        <div 
          className={this.state.lastEdited === planData.rooster_id + i || this.state.userHoursScheduleID === parseInt(planData.rooster_id) ? `plannerN_shiftContainer lastEdited ${this.state.selectedBar === 'r' + planData.rooster_id + i ? "blink" : ""}` : `plannerN_shiftContainer ${this.state.selectedBar === 'r' + planData.rooster_id + i ? "blink" : ""}`} 
          style={{backgroundColor: Constants.hexToRgbA(`#${planData.functie_kleur}`, planData.rooster_publiceren === '2' ? 0.15 : 0.20)}}
          draggable={this.state.showPlanMenu[planData.rooster_id] || planData.rooster_publiceren === '2' ? 'false' : 'true'}
          onDragStart={(event) => this.onDragStartUserShift(event, i, planData)}
          onDrop={(event) => this.onDrop(event, i, planData)}
          onDragOver={(event) => this.onDragOver(event, i)}
          onDragLeave={(event) => this.onDragLeave(event)}
          onClick={planData.rooster_publiceren === '2' ? (event) => this.onClickDayShift(i, planData, event) : (event) => this.onClickUserShift(i, planData, event)}
        >

          <div className='plannerN_shiftColumn1' style={{backgroundColor: Constants.hexToRgbA(`#${planData.functie_kleur}`, planData.rooster_publiceren === '2' ? 0.35 : 0.5)}}>

            {Constants.isEmpty(infoExtra) === false && Constants.isEmpty(infoExtra.info2_profielfoto) === false ?
              <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${infoExtra.info2_profielfoto}`} draggable={false} style={{ width: '1.2vw', height: '1.2vw', borderRadius: '1.5vw' }} />
            :
              <span style={{color: Constants.getContrastPlan(`#${planData.functie_kleur}`)}}>{planData.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
            }

          </div>

          <div className='plannerN_shiftColumn2'>

            <div className='plannerN_shiftRow'>

              {planData.rooster_publiceren === '2' ?
                <span style={{color: isAvailableShift ? '#E6A024' : Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[planData.rooster_id]}</span>
              :
              this.state.editPlan === true ?
                <input
                  ref={(ref) => this.timeRef[planData.rooster_id]=ref}
                  className='plannerN_inputBoxTime planTimeAsset'
                  defaultValue={this.state.planInputs[planData.rooster_id]}
                  onKeyDown={(event) => this.handleKey(event, planData)}
                  onBlur={(e) => this.saveTimeDirectly(e, planData)}
                  onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
                  onChange={(event) => this.onChangeTime(event, planData)}
                  disabled={this.state.selectedBar === 'r' + planData.rooster_id + i}
                  type={'text'}
                  style={
                    planData.rooster_tijd_incorrect === '1' ?
                      {color: Colors.color.redFilledIn} 
                    :
                      isAvailableShift ?
                        {color: '#E6A024'} 
                      :
                        {color: '#04163b'} 
                  }
                />
              :
                <div className='plannerN_inputBoxTime planTimeAsset' onClick={() => this.onClickPlanTime(planData, i)}>
                  <span className='planTimeAsset' style={{color: planData.rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : isAvailableShift ? '#E6A024' : '#04163b'}}>{this.state.planInputs[planData.rooster_id]}</span>
                </div>
              }

              {planData.rooster_pauze !== '00:00:00' ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-coffee'></i>
                      <span style={{marginLeft: '0.5vw'}}>{planData.rooster_pauze.substr(0,5)} pauze</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginLeft: 4}}
                >
                  <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                </Tooltip>
              : null}

              {skills !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-graduation-cap'></i>
                      <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                      {skillsRender.length > 0 ?
                        <div style={{marginTop: '0.5vw'}}>
                          {skillsRender}
                        </div>
                      : null}
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginLeft: 4}}
                >
                  <i className='fal fa-graduation-cap' style={{color: userHasAllSkills === false ? Colors.color.redFilledIn : null, fontSize: '0.6vw'}} />
                </Tooltip>
              : null}

              {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
                  <span style={{color: `#${partColor}`, fontSize: '0.6vw', marginLeft: 4}}>{partAfk}</span>
              : 
              absence !== null ?
                  <span style={{color: Colors.color.redFilledIn, fontSize: '0.6vw', marginLeft: 4}}>{absence.afw_afkorting}</span>
              : null}

            </div>

            <div className='plannerN_shiftRow'>

              {planData.rooster_publiceren === '2' ?
                <span style={{fontStyle: 'italic', fontSize: '0.6vw', color: Colors.color.redFilledIn}}>Standaard dienst</span>
              :
                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>
              }

            </div>

          </div>

          <div className='plannerN_shiftColumn3'>

            {planData.rooster_publiceren === '0' ?
              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer'>
                    <i className='fas fa-exclamation'></i>
                    <span style={{marginLeft: '0.3vw'}}>Niet gepubliceerd</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginRight: 5}}
              >
                <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn}} />
              </Tooltip>
            :
            isAvailableShift ?
              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer'>
                    <i className='far fa-search'></i>
                    <span style={{marginLeft: '0.3vw'}}>Dienst is beschikbaar gemaakt</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginRight: 5}}
              >
                <i className='far fa-search' style={{color: '#E6A024'}} />
              </Tooltip>
            : null}

            {planData.rooster_publiceren !== '2' ?
              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[planData.rooster_id] ? this.closePlanMenu(planData.rooster_id) : this.openPlanMenu(planData.rooster_id)} />
            :
              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showDayShiftMenu[planData.rooster_id] ? this.closeDayShiftMenu(planData.rooster_id) : this.openDayShiftMenu(planData.rooster_id)} />
            }

          </div>

        </div>

        {this.state.showPlanMenu[planData.rooster_id] === true ?

          this.renderPlanMenu(planData, left, partID, absence, skills, userHasAllSkills, isAvailableShift)

        : null}

        {this.state.showDayShiftMenu[planData.rooster_id] === true ?

          this.renderDayShiftMenu(planData, left, partName, skills, isAvailableShift)

        : null}

      </div>

    );

  }

  newPlannerBarRepeatShift(planData, i, left) {

    const teamName = Constants.getTeamNameFromTeamID(planData.hr_team_id);

    const functionColor = planData.functie_kleur !== null ? planData.functie_kleur : 'EBEBEB';
    const functionName = planData.functie_naam !== null ? planData.functie_naam : 'Functie onbekend';

    const date = i === 1 ? this.state.mondayDate : i === 2 ? this.state.tuesdayDate : i === 3 ? this.state.wednesdayDate : i === 4 ? this.state.thursdayDate : i === 5 ? this.state.fridayDate : i === 6 ? this.state.saturdayDate : this.state.sundayDate;

    let skills = [];
    let part = null;
    if (Constants.isEmpty(this.skillsRepeatShifts[planData.hr_id]) === false) {
      for (const skill of this.skillsRepeatShifts[planData.hr_id]) {
        skills.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(this.state.skillsUsers[planData.hr_info_id], skill) ? null : Colors.color.redFilledIn}}>• {skill.comp_afkorting}</span>
          </div>
        );
      }
    }

    if (Constants.isEmpty(this.partRepeatShifts[planData.hr_id]) === false) {
      part = this.partRepeatShifts[planData.hr_id];
    }

    const userHasAllSkills = Plan.userHasAllSkills(this.skillsRepeatShifts[planData.hr_id], this.state.skillsUsers[planData.hr_info_id]);

    // incorrect times check here

    if (Constants.isEmpty(planData.hr_team_id) === true || (Data.data.teams.length > 1 && Data.data.chosenTeam !== null && planData.hr_team_id === Data.data.chosenTeamObj.team_id)) {

      const hoursWeek = this.getUserHoursWeek(planData.info_id);

      const userFullName = `${planData.info_voornaam} ${(planData.info_tussenvoegsel !== null && planData.info_tussenvoegsel !== '') ? planData.info_tussenvoegsel + " " + planData.info_achternaam.charAt(0) : planData.info_achternaam.charAt(0)}.`;

      return (

        <div key={'rs' + i + planData.hr_id} className='plannerN_shiftOuter'>

          <div 
            className={`plannerN_shiftContainer`} 
            style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.10)}}
          >

            <div className='plannerN_shiftColumn1' style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.25)}}>

              <span style={{color: Constants.getContrastPlan(`#${functionColor}`)}}>{functionName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>

            </div>

            <div className='plannerN_shiftColumn2'>

              <div className='plannerN_shiftRow'>

                <span className='planTimeAsset' style={{color: '#04163b'}}>{planData.hr_begin.substr(0, 5)} - {planData.hr_eind.substr(0, 5)}</span>

                {planData.hr_pauze !== '00:00:00' ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <i className='fas fa-coffee'></i>
                        <span style={{marginLeft: '0.5vw'}}>{planData.hr_pauze.substr(0,5)} pauze</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {skills.length > 0 ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-graduation-cap'></i>
                        <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                        {skills.length > 0 ?
                          <div style={{marginTop: '0.5vw'}}>
                            {skills}
                          </div>
                        : null}
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-graduation-cap' style={{color: userHasAllSkills === false ? Colors.color.redFilledIn : null, fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {part !== null ?
                  <span style={{color: `#${part.bijz_kleur}`, fontSize: '0.6vw', marginLeft: 4}}>{part.bijz_afkorting}</span>
                : null}

              </div>

              <div className='plannerN_shiftRow'>

                <div style={{width: '95%'}}>
                  <span className='plannerN_nameText'>{userFullName}</span>
                  <span style={{marginLeft: '5%', fontWeight: '500', fontSize: '0.5vw'}}>{Constants.msToTime2(hoursWeek)}</span>
                </div>

              </div>

            </div>

            <div className='plannerN_shiftColumn3'>

              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer_table'>
                    <i className='fas fa-history'></i>
                    <span style={{marginLeft: '0.3vw'}}>Herhalende dienst</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginRight: 5}}
              >
                <i className='fas fa-history' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
              </Tooltip>

              <Tooltip
                options
                html={(
                  <div className='plannerN_tooltipContainer_table'>
                    <i className='fas fa-exclamation'></i>
                    <span style={{marginLeft: '0.3vw'}}>Niet gepubliceerd</span>
                  </div>
                )}
                position={'top'}
                arrow
                followCursor
                theme='kodict'
                style={{marginRight: 5}}
              >
                <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn}} />
              </Tooltip>

              <i className='planner_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu['rs' + planData.hr_id + i] ? this.closePlanMenu('rs' + planData.hr_id + i) : this.openPlanMenu('rs' + planData.hr_id + i)} />

            </div>

          </div>

          {this.state.showPlanMenu['rs' + planData.hr_id + i] === true ?
            this.renderRepeatingShiftMenu(planData, left, date)
          : null}

        </div>

      );

    }

    return null;

  }
 
  getPlannerBars() {

    let dayShiftDataDay = null;
    let plannerDataDay = null;

    let plannerBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    let maxNotFilled = 0;

    if(maxNotFilled < (this.dayShiftsData.mon.length + this.plannerData.mon.length)) {
      maxNotFilled = this.dayShiftsData.mon.length + this.plannerData.mon.length;
    }
    if(maxNotFilled < (this.dayShiftsData.tue.length + this.plannerData.tue.length)) {
      maxNotFilled = this.dayShiftsData.tue.length + this.plannerData.tue.length;
    }
    if(maxNotFilled < (this.dayShiftsData.wed.length + this.plannerData.wed.length)) {
      maxNotFilled = this.dayShiftsData.wed.length + this.plannerData.wed.length;
    }
    if(maxNotFilled < (this.dayShiftsData.thu.length + this.plannerData.thu.length)) {
      maxNotFilled = this.dayShiftsData.thu.length + this.plannerData.thu.length;
    }
    if(maxNotFilled < (this.dayShiftsData.fri.length + this.plannerData.fri.length)) {
      maxNotFilled = this.dayShiftsData.fri.length + this.plannerData.fri.length;
    }
    if(maxNotFilled < (this.dayShiftsData.sat.length + this.plannerData.sat.length)) {
      maxNotFilled = this.dayShiftsData.sat.length + this.plannerData.sat.length;
    }
    if(maxNotFilled < (this.dayShiftsData.sun.length + this.plannerData.sun.length)) {
      maxNotFilled = this.dayShiftsData.sun.length + this.plannerData.sun.length;
    }

    let maxBars = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    for(let key in this.functionsData) {
      let count = 0;

      //monday
      for(let key2 in this.dayShiftsData.mon) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.mon[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.mon) {
        if(this.functionsData[key].functie_id === this.plannerData.mon[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 1 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.mon.push({ functionID: this.functionsData[key].functie_id, max: count });

      //tuesday
      count = 0;
      for(let key2 in this.dayShiftsData.tue) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.tue[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.tue) {
        if(this.functionsData[key].functie_id === this.plannerData.tue[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 2 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.tue.push({ functionID: this.functionsData[key].functie_id, max: count });

      //wednesday
      count = 0;
      for(let key2 in this.dayShiftsData.wed) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.wed[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.wed) {
        if(this.functionsData[key].functie_id === this.plannerData.wed[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 3 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.wed.push({ functionID: this.functionsData[key].functie_id, max: count });

      //thursday
      count = 0;
      for(let key2 in this.dayShiftsData.thu) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.thu[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.thu) {
        if(this.functionsData[key].functie_id === this.plannerData.thu[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 4 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.thu.push({ functionID: this.functionsData[key].functie_id, max: count });

      //friday
      count = 0;
      for(let key2 in this.dayShiftsData.fri) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.fri[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.fri) {
        if(this.functionsData[key].functie_id === this.plannerData.fri[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 5 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.fri.push({ functionID: this.functionsData[key].functie_id, max: count });

      //saturday
      count = 0;
      for(let key2 in this.dayShiftsData.sat) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.sat[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.sat) {
        if(this.functionsData[key].functie_id === this.plannerData.sat[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 6 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.sat.push({ functionID: this.functionsData[key].functie_id, max: count });

      //sunday
      count = 0;
      for(let key2 in this.dayShiftsData.sun) {
        if(this.functionsData[key].functie_id === this.dayShiftsData.sun[key2].functie_id) {
          count++;
        }
      }
      for(let key2 in this.plannerData.sun) {
        if(this.functionsData[key].functie_id === this.plannerData.sun[key2].functie_id) {
          count++;
        }
      }
      for(const userKey in this.unpublishedRepeatingShifts) {
        for (const r of this.unpublishedRepeatingShifts[userKey]) {
          if (r.date.getDay() === 0 && this.functionsData[key].functie_id === r.functie_id) {
            count++;
          }
        }
      }
      maxBars.sun.push({ functionID: this.functionsData[key].functie_id, max: count });
    }

    let maxBars2 = [];

    for(let key in this.functionsData) {
      let max = 0;

      for(let key2 in maxBars) {
        let tempMaxBars = maxBars[key2];
        for(let key3 in tempMaxBars) {
          if(this.functionsData[key].functie_id === tempMaxBars[key3].functionID) {
            if(max < tempMaxBars[key3].max) {
              max = tempMaxBars[key3].max;
            }
          }
        }
      }
      maxBars2.push({ 
        functionID: this.functionsData[key].functie_id, 
        max: max, 
        functionColor: this.functionsData[key].functie_kleur,
        functionName: this.functionsData[key].functie_naam,
        index: this.functionsData.indexOf(this.functionsData[key]),
      });
    }

    for(let i = 0; i < 7; i++) {

      if(i === 0) {

        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.mon;
        plannerDataDay = this.plannerData.mon;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                      index: this.functionsData.indexOf(this.functionsData[key]),
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push({
                ...plannerDataDay[key2],
                index: this.functionsData.indexOf(this.functionsData[key]),
              });

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 1) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.mon.push(
              <div className={'planner_sortIconContainer'}>
                {this.state.sortFunctions === true ?
                  <div>
                    <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(maxBars2[key2].index)} />
                    <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(maxBars2[key2].index)} />
                  </div>
                : null}
                <div 
                  className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                  onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                >
                  <span style={{color: Constants.getContrast(maxBars2[key2].functionColor)}}>{maxBars2[key2].functionName}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;  
              while(j < maxBars2[key2].max) {
                plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.mon.push(
                    <div className={'planner_sortIconContainer'}>
                      {this.state.sortFunctions === true ?
                        <div>
                          <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(maxBars2[key2].index)} />
                          <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(maxBars2[key2].index)} />
                        </div>
                      : null}
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      >
                        <span style={{color: Constants.getContrast(maxBars2[key2].functionColor)}}>{maxBars2[key2].functionName}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    </div>
                  ); //MAXBARS.functionname added and rounded corners
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.mon.push(
              <div className={'planner_sortIconContainer'}>
                {this.state.sortFunctions === true ?
                  <div>
                    <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(tempPlannerData[key].index)} />
                    <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(tempPlannerData[key].index)} />
                  </div>
                : null}
                <div 
                  className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
                  onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                >
                  <span style={{color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}>{tempPlannerData[key].functie_naam}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {

              plannerBars.mon.push( 

                // <div className='planner_touchBoxOuter'>
        
                //   <div 
                //     key={key} 
                //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
                //     style={{ 
                //       borderColor: `#${tempPlannerData[key].functie_kleur}`,
                //       backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),
                //     }}
                //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
                //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
                //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
                //     onDragOver={(event) => this.onDragOver(event, i)}
                //     onDragLeave={(event) => this.onDragLeave(event)}
                //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
                //   >

                //     <div
                //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
                //     >

                //       {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

                //         <div className='planner_timeContainer' 
                //           style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
                //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
                //         >

                //           {tempPlannerData[key].rooster_publiceren === '2' ?
                            
                //             <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

                //           :

                //             <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                          
                //           }
                  
                //         </div>

                //       :

                //         <input
                //           ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
                //           className='planner_timeContainerInput'
                //           //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
                //           //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
                //           defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
                //           onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
                //           onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
                //           onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
                //           onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
                //           disabled={this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i}
                //           type={'text'}
                //           style={
                //             tempPlannerData[key].rooster_publiceren === '2' ? 
                //               {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
                //             : 
                //             tempPlannerData[key].rooster_tijd_incorrect === '1' ?
                //               {color: Colors.color.redFilledIn} 
                //             :
                //               {color: '#04163b'} 
                //           }
                //         />

                //       }
            
                //       <div className='planner_nameContainer'
                //         style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
                //         onClick={
                //           tempPlannerData[key].rooster_publiceren === '2' ?
                //             () => this.onClickDayShift(i, tempPlannerData[key])
                //           : 
                //             () => this.onClickUserShift(i, tempPlannerData[key])
                //           }
                //       >
            
                //         {tempPlannerData[key].rooster_publiceren === '2' ?
                          
                //           <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

                //         :

                //           <div className='planner_nameText'>{userFullName}</div>
                        
                //         }
            
                //       </div>

                //       </div>

                //       {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
                //         <div className='planner_afkContainer' 
                //           style={{color: `#${partColor}`}}
                //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
                //         >
                //           <span>{partAfk}</span>
                //         </div>
                //       : 
                //       absence !== null ?
                //         <div className='planner_afkContainer' 
                //           style={{color: Colors.color.redFilledIn}}
                //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? null : () => this.onClickUserShift(i, tempPlannerData[key])}
                //         >
                //           <span>{absence.afw_afkorting}</span>
                //         </div>
                //       : null}

                //     {tempPlannerData[key].rooster_publiceren === '0' ?

                //       <Tooltip
                //         options
                //         html={(
                //           <div className='planner_commentContainer'>
                //             <i className='fas fa-exclamation'></i>
                //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
                //           </div>
                //         )}
                //         position={'top'}
                //         arrow
                //         followCursor
                //         theme='kodict'
                //         className='planner_iconContainer'
                //       >
          
                //       {

                //         tempPlannerData[key].rooster_publiceren === '0' ?

                //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

                //       :

                //         null

                //       }

                //       </Tooltip>

                //     : null}

                //     {tempPlannerData[key].rooster_publiceren !== '2' ?

                //       <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

                //         <i className='fas fa-caret-down' />

                //       </div>

                //       : 

                //       skills !== null || partName !== null ?

                //         <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

                //           <i className='fas fa-caret-down' />

                //         </div>

                //       : null}
          
                //   </div>

                //   {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

                //     this.renderPlanMenu(tempPlannerData[key], false, partID, absence, skills, userHasAllSkills)

                //   : null}

                //   {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

                //     this.renderDayShiftMenu(tempPlannerData[key], false, partName, skills)

                //   : null}

                // </div>

                this.newPlannerBar(tempPlannerData[key], i, false)
        
              );

            } else {

              plannerBars.mon.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, false));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.mon.push(
                    <div className={'planner_sortIconContainer'}>
                      {this.state.sortFunctions === true ?
                        <div>
                          <i className={'fas fa-angle-up planner_sortIcon'} onClick={() => this.sortUp(maxBars2[key2].index)} />
                          <i className={'fas fa-angle-down planner_sortIconDown'} onClick={() => this.sortDown(maxBars2[key2].index)} />
                        </div>
                      : null}
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      > 
                        <span style={{color: Constants.getContrast(maxBars2[key2].functionColor)}}>{maxBars2[key2].functionName}</span><i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i> 
                      </div>
                    </div>
                  ); //MAXBARS2[Key2].functionname added and rounded borders
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.mon.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 1) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.tue;
        plannerDataDay = this.plannerData.tue;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 2) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.tue.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.tue.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                    >
                        <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    );
                    if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                      let j = 0;
                      while(j < maxBars2[key2].max) {
                        plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                        j++;
                      }
                    }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.tue.push(
              <div 
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)} 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }

          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {

              plannerBars.tue.push( 

              //   <div className='planner_touchBoxOuter'>
        
              //   <div 
              //     key={key} 
              //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
              //     style={{borderColor: `#${tempPlannerData[key].functie_kleur}`,
              //     backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),}}
              //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
              //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
              //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
              //     onDragOver={(event) => this.onDragOver(event, i)}
              //     onDragLeave={(event) => this.onDragLeave(event)}
              //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
              //   >

              //     <div
              //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
              //     >

              //         {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

              //           <div className='planner_timeContainer' 
              //             style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
              //           >

              //             {tempPlannerData[key].rooster_publiceren === '2' ?
                            
              //               <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

              //             :

              //               <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                          
              //             }
                  
              //           </div>

              //         :

              //           <input
              //             ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
              //             className='planner_timeContainerInput'
              //             //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
              //             //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //             defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //             onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
              //             onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
              //             onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
              //             onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
              //             type={'text'}
              //             style={
              //               tempPlannerData[key].rooster_publiceren === '2' ? 
              //                 {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
              //               : 
              //               tempPlannerData[key].rooster_tijd_incorrect === '1' ?
              //                 {color: Colors.color.redFilledIn} 
              //               :
              //                 {color: '#04163b'} 
              //             }
              //           />
                        
              //         }
        
              //     <div className='planner_nameContainer'
              //       style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
              //       onClick={
              //         tempPlannerData[key].rooster_publiceren === '2' ?
              //         () => this.onClickDayShift(i, tempPlannerData[key])
              //         : 
              //           () => this.onClickUserShift(i, tempPlannerData[key])
              //       }
              //     >
        
              //       {tempPlannerData[key].rooster_publiceren === '2' ?
                      
              //         <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

              //       :

              //         <div className='planner_nameText'>{userFullName}</div>
                    
              //       }
        
              //     </div>

              //     </div>

              //     {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
              //         <div className='planner_afkContainer' 
              //           style={{color: `#${partColor}`}}
              //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //         >
              //           <span>{partAfk}</span>
              //         </div>
              //       : 
              //       absence !== null ?
              //         <div className='planner_afkContainer' 
              //           style={{color: Colors.color.redFilledIn}}
              //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //         >
              //           <span>{absence.afw_afkorting}</span>
              //         </div>
              //       : null}

              //       {tempPlannerData[key].rooster_publiceren === '0' ?
                  
              //       <Tooltip
              //         options
              //         html={(
              //           <div className='planner_commentContainer'>
              //             <i className='fas fa-exclamation'></i>
              //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
              //           </div>
              //         )}
              //         position={'top'}
              //         arrow
              //         followCursor
              //         theme='kodict'
              //         className='planner_iconContainer'
              //       >
        
              //       {

              //         tempPlannerData[key].rooster_publiceren === '0' ?

              //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

              //       :

              //         null

              //       }

              //       </Tooltip>

              //     : null}

              //     {tempPlannerData[key].rooster_publiceren !== '2' ?

              //     <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

              //       <i className='fas fa-caret-down' />

              //     </div>

              //     : 

              //     skills !== null || partName !== null ?

              //       <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

              //         <i className='fas fa-caret-down' />

              //       </div>

              //     : null}

              //     {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

              //       this.renderPlanMenu(tempPlannerData[key], false, partID, absence, skills, userHasAllSkills)

              //     : null}

              //     {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

              //     this.renderDayShiftMenu(tempPlannerData[key], false, partName, skills)

              //     : null}
        
              //   </div>

              // </div>

              this.newPlannerBar(tempPlannerData[key], i, false)
        
              );

            } else {

              plannerBars.tue.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, false));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.tue.push(
                    <div 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.tue.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 2) {

        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.wed;
        plannerDataDay = this.plannerData.wed;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 3) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.wed.push(
              <div 
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.wed.push(
                    <div 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.wed.push(
              <div 
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)} 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {

              plannerBars.wed.push( 

              //   <div className='planner_touchBoxOuter'>
        
              //   <div 
              //     key={key} 
              //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
              //     style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),}}
              //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
              //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
              //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
              //     onDragOver={(event) => this.onDragOver(event, i)}
              //     onDragLeave={(event) => this.onDragLeave(event)}
              //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
              //   >

              //     <div
              //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
              //     >

              //       {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

              //         <div className='planner_timeContainer'    
              //           style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
              //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
              //         >

              //           {tempPlannerData[key].rooster_publiceren === '2' ?
                          
              //             <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

              //           :

              //             <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                        
              //           }

              //         </div>

              //       :

              //         <input
              //           ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
              //           className='planner_timeContainerInput'
              //           //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
              //           //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //           defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //           onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
              //           onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
              //           onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
              //           onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
              //           type={'text'}
              //           style={
              //             tempPlannerData[key].rooster_publiceren === '2' ? 
              //               {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
              //             : 
              //             tempPlannerData[key].rooster_tijd_incorrect === '1' ?
              //               {color: Colors.color.redFilledIn} 
              //             :
              //               {color: '#04163b'} 
              //           }
              //         />

              //       }
        
              //     <div className='planner_nameContainer'
              //       style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
              //       onClick={
              //         tempPlannerData[key].rooster_publiceren === '2' ?
              //         () => this.onClickDayShift(i, tempPlannerData[key])
              //         : 
              //           () => this.onClickUserShift(i, tempPlannerData[key]) 
              //         }
              //     >
        
              //       {tempPlannerData[key].rooster_publiceren === '2' ?
                      
              //         <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

              //       :

              //         <div className='planner_nameText'>{userFullName}</div>
                    
              //       }
        
              //     </div>

              //     </div>

              //     {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
              //           <div className='planner_afkContainer' 
              //             style={{color: `#${partColor}`}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{partAfk}</span>
              //           </div>
              //         : 
              //         absence !== null ?
              //           <div className='planner_afkContainer' 
              //             style={{color: Colors.color.redFilledIn}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{absence.afw_afkorting}</span>
              //           </div>
              //         : null}

              //       {tempPlannerData[key].rooster_publiceren === '0' ?
                    
              //       <Tooltip
              //         options
              //         html={(
              //           <div className='planner_commentContainer'>
              //             <i className='fas fa-exclamation'></i>
              //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
              //           </div>
              //         )}
              //         position={'top'}
              //         arrow
              //         followCursor
              //         theme='kodict'
              //         className='planner_iconContainer'
              //       >
        
              //       {

              //         tempPlannerData[key].rooster_publiceren === '0' ?

              //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

              //       :

              //         null

              //       }

              //       </Tooltip>

              //     : null}

              //     {tempPlannerData[key].rooster_publiceren !== '2' ?

              //     <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

              //       <i className='fas fa-caret-down' />

              //     </div>

              //     : 

              //     skills !== null || partName !== null ?

              //       <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

              //         <i className='fas fa-caret-down' />

              //       </div>

              //     : null}

              //     {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

              //       this.renderPlanMenu(tempPlannerData[key], false, partID, absence, skills, userHasAllSkills)

              //     : null}

              //     {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

              //     this.renderDayShiftMenu(tempPlannerData[key], false, partName, skills)

              //     : null}
        
              //   </div>

              // </div>

              this.newPlannerBar(tempPlannerData[key], i, false)
        
              );

            } else {

              plannerBars.wed.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, false));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.wed.push(
                    <div 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)} 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.wed.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 3) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.thu;
        plannerDataDay = this.plannerData.thu;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 4) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.thu.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}} 
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.thu.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}} 
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.thu.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}} 
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }

          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {

              plannerBars.thu.push( 

              //   <div className='planner_touchBoxOuter'>
        
              //   <div  
              //     key={key} 
              //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
              //     style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),}}
              //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
              //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
              //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
              //     onDragOver={(event) => this.onDragOver(event, i)}
              //     onDragLeave={(event) => this.onDragLeave(event)}
              //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
              //   >

              //     <div
              //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
              //     >
    

              //       {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

              //         <div className='planner_timeContainer' 
              //           style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
              //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
              //         >

              //           {tempPlannerData[key].rooster_publiceren === '2' ?
                          
              //             <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

              //           :

              //             <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                        
              //           }

              //         </div>

              //       :

              //         <input
              //           ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
              //           className='planner_timeContainerInput'
              //           //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
              //           //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //           defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //           onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
              //           onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
              //           onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
              //           onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
              //           type={'text'}
              //           style={
              //             tempPlannerData[key].rooster_publiceren === '2' ? 
              //               {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
              //             : 
              //             tempPlannerData[key].rooster_tijd_incorrect === '1' ?
              //               {color: Colors.color.redFilledIn} 
              //             :
              //               {color: '#04163b'} 
              //           }
              //         />

              //       }

              //     {/* <ReactTooltip id={`hoursTooltip${key}thu`} type='dark' className='planner_hoursTooltip' delayShow={400} disable={tempPlannerData[key].rooster_publiceren === '2'}>
              //       {this.getHoursTooltip(tempPlannerData[key].info_id, this.state.thursdayDate)}
              //     </ReactTooltip> */}
        
              //     <div className='planner_nameContainer'
              //       style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
              //       onClick={
              //         tempPlannerData[key].rooster_publiceren === '2' ?
              //         () => this.onClickDayShift(i, tempPlannerData[key])
              //         : 
              //           () => this.onClickUserShift(i, tempPlannerData[key]) 
              //         }
              //     >
        
              //       {tempPlannerData[key].rooster_publiceren === '2' ?
                      
              //         <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

              //       :

              //         <div className='planner_nameText'>{userFullName}</div>
                    
              //       }
        
              //     </div>

              //     </div>

              //     {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
              //           <div className='planner_afkContainer' 
              //             style={{color: `#${partColor}`}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{partAfk}</span>
              //           </div>
              //         : 
              //         absence !== null ?
              //           <div className='planner_afkContainer' 
              //             style={{color: Colors.color.redFilledIn}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{absence.afw_afkorting}</span>
              //           </div>
              //         : null}

              //     {tempPlannerData[key].rooster_publiceren === '0' ?
                    
              //       <Tooltip
              //         options
              //         html={(
              //           <div className='planner_commentContainer'>
              //             <i className='fas fa-exclamation'></i>
              //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
              //           </div>
              //         )}
              //         position={'top'}
              //         arrow
              //         followCursor
              //         theme='kodict'
              //         className='planner_iconContainer'
              //       >
        
              //       {

              //         tempPlannerData[key].rooster_publiceren === '0' ?

              //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

              //       :

              //         null

              //       }

              //       </Tooltip>

              //     : null}

              //     {tempPlannerData[key].rooster_publiceren !== '2' ?

              //     <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

              //       <i className='fas fa-caret-down' />

              //     </div>

              //     : 

              //     skills !== null || partName !== null ?

              //       <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

              //         <i className='fas fa-caret-down' />

              //       </div>

              //     : null}

              //     {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

              //       this.renderPlanMenu(tempPlannerData[key], false, partID, absence, skills, userHasAllSkills)

              //     : null}

              //     {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

              //     this.renderDayShiftMenu(tempPlannerData[key], false, partName, skills)

              //     : null}
        
              //   </div>

              // </div>

              this.newPlannerBar(tempPlannerData[key], i, false)
        
              );

            } else {

              plannerBars.thu.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, false));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.thu.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.thu.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 4) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.fri;
        plannerDataDay = this.plannerData.fri;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 5) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.fri.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.fri.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.fri.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

           if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {

              plannerBars.fri.push( 

              //   <div className='planner_touchBoxOuter'>
        
              //   <div 
              //     key={key} 
              //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
              //     style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),}}
              //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
              //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
              //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
              //     onDragOver={(event) => this.onDragOver(event, i)}
              //     onDragLeave={(event) => this.onDragLeave(event)}
              //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
              //   >

              //     <div
              //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
              //     >

              //         {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

              //           <div className='planner_timeContainer' 
              //             style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
              //           >

              //             {tempPlannerData[key].rooster_publiceren === '2' ?
                            
              //               <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

              //             :

              //               <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                          
              //             }

              //           </div>

              //         :

              //           <input
              //             ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
              //             className='planner_timeContainerInput'
              //             //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
              //             //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //             defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //             onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
              //             onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
              //             onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
              //             onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
              //             type={'text'}
              //             style={
              //               tempPlannerData[key].rooster_publiceren === '2' ? 
              //                 {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
              //               : 
              //               tempPlannerData[key].rooster_tijd_incorrect === '1' ?
              //                 {color: Colors.color.redFilledIn} 
              //               :
              //                 {color: '#04163b'} 
              //             }
              //           />

              //         }

              //     {/* <ReactTooltip id={`hoursTooltip${key}fri`} type='dark' className='planner_hoursTooltip' delayShow={400} disable={tempPlannerData[key].rooster_publiceren === '2'}>
              //       {this.getHoursTooltip(tempPlannerData[key].info_id, this.state.fridayDate)}
              //     </ReactTooltip> */}
        
              //     <div className='planner_nameContainer'
              //       style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
              //       onClick={
              //         tempPlannerData[key].rooster_publiceren === '2' ?
              //         () => this.onClickDayShift(i, tempPlannerData[key])
              //         : 
              //           () => this.onClickUserShift(i, tempPlannerData[key]) 
              //         }
              //     >
        
              //       {tempPlannerData[key].rooster_publiceren === '2' ?
                      
              //         <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

              //       :

              //         <div className='planner_nameText'>{userFullName}</div>
                    
              //       }
        
              //     </div>

              //     </div>

              //     {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
              //           <div className='planner_afkContainer' 
              //             style={{color: `#${partColor}`}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{partAfk}</span>
              //           </div>
              //         : 
              //         absence !== null ?
              //           <div className='planner_afkContainer' 
              //             style={{color: Colors.color.redFilledIn}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{absence.afw_afkorting}</span>
              //           </div>
              //         : null}

              //     {tempPlannerData[key].rooster_publiceren === '0' ?
                    
              //       <Tooltip
              //         options
              //         html={(
              //           <div className='planner_commentContainer'>
              //             <i className='fas fa-exclamation'></i>
              //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
              //           </div>
              //         )}
              //         position={'top'}
              //         arrow
              //         followCursor
              //         theme='kodict'
              //         className='planner_iconContainer'
              //       >
        
              //       {

              //         tempPlannerData[key].rooster_publiceren === '0' ?

              //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

              //       :

              //         null

              //       }

              //       </Tooltip>

              //     : null}

              //     {tempPlannerData[key].rooster_publiceren !== '2' ?

              //     <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

              //       <i className='fas fa-caret-down' />

              //     </div>

              //     : 

              //     skills !== null || partName !== null ?

              //       <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

              //         <i className='fas fa-caret-down' />

              //       </div>

              //     : null}

              //     {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

              //       this.renderPlanMenu(tempPlannerData[key], false, partID, absence, skills, userHasAllSkills)

              //     : null}

              //     {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

              //     this.renderDayShiftMenu(tempPlannerData[key], false, partName, skills)

              //     : null}
        
              //   </div>

              // </div>

              this.newPlannerBar(tempPlannerData[key], i, false)
        
              );

            } else {

              plannerBars.fri.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, false));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.fri.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.fri.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 5) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.sat;
        plannerDataDay = this.plannerData.sat;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 6) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.sat.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sat.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.sat.push(
              <div 
                className='planner_headerText' 
                style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                onDragOver={(event) => this.onDragOver(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
              >
                <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {
            
              plannerBars.sat.push( 

              //   <div className='planner_touchBoxOuter'>
        
              //   <div 
              //     key={key} 
              //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
              //     style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),}}
              //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
              //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
              //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
              //     onDragOver={(event) => this.onDragOver(event, i)}
              //     onDragLeave={(event) => this.onDragLeave(event)}
              //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
              //   >

              //     <div
              //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
              //     >


              //       {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

              //         <div className='planner_timeContainer' 
              //           style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
              //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
              //         >

              //           {tempPlannerData[key].rooster_publiceren === '2' ?
                          
              //             <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

              //           :

              //             <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                        
              //           }

              //         </div>

              //       :

              //         <input
              //           ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
              //           className='planner_timeContainerInput'
              //           //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
              //           //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //           defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
              //           onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
              //           onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
              //           onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
              //           onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
              //           type={'text'}
              //           style={
              //             tempPlannerData[key].rooster_publiceren === '2' ? 
              //               {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
              //             : 
              //             tempPlannerData[key].rooster_tijd_incorrect === '1' ?
              //               {color: Colors.color.redFilledIn} 
              //             :
              //               {color: '#04163b'} 
              //           }
              //         />

              //       }

              //     {/* <ReactTooltip id={`hoursTooltip${key}sat`} type='dark' className='planner_hoursTooltip' delayShow={400} disable={tempPlannerData[key].rooster_publiceren === '2'}>
              //       {this.getHoursTooltip(tempPlannerData[key].info_id, this.state.saturdayDate)}
              //     </ReactTooltip> */}
        
              //     <div className='planner_nameContainer'
              //       style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
              //       onClick={
              //         tempPlannerData[key].rooster_publiceren === '2' ?
              //         () => this.onClickDayShift(i, tempPlannerData[key])
              //         : 
              //           () => this.onClickUserShift(i, tempPlannerData[key]) 
              //         }
              //     >
        
              //       {tempPlannerData[key].rooster_publiceren === '2' ?
                      
              //         <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

              //       :

              //         <div className='planner_nameText'>{userFullName}</div>
                    
              //       }
        
              //     </div>

              //     </div>

              //     {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
              //           <div className='planner_afkContainer' 
              //             style={{color: `#${partColor}`}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{partAfk}</span>
              //           </div>
              //         : 
              //         absence !== null ?
              //           <div className='planner_afkContainer' 
              //             style={{color: Colors.color.redFilledIn}}
              //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
              //           >
              //             <span>{absence.afw_afkorting}</span>
              //           </div>
              //         : null}

              //     {tempPlannerData[key].rooster_publiceren === '0' ?
                    
              //       <Tooltip
              //         options
              //         html={(
              //           <div className='planner_commentContainer'>
              //             <i className='fas fa-exclamation'></i>
              //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
              //           </div>
              //         )}
              //         position={'top'}
              //         arrow
              //         followCursor
              //         theme='kodict'
              //         className='planner_iconContainer'
              //       >
        
              //       {

              //         tempPlannerData[key].rooster_publiceren === '0' ?

              //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

              //       :

              //         null

              //       }

              //       </Tooltip>

              //     : null}

              //     {tempPlannerData[key].rooster_publiceren !== '2' ?

              //     <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

              //       <i className='fas fa-caret-down' />

              //     </div>

              //     : 

              //     skills !== null || partName !== null ?

              //       <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

              //         <i className='fas fa-caret-down' />

              //       </div>

              //     : null}

              //     {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

              //       this.renderPlanMenu(tempPlannerData[key], true, partID, absence, skills, userHasAllSkills)

              //     : null}

              //     {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

              //     this.renderDayShiftMenu(tempPlannerData[key], true, partName, skills)

              //     : null}
        
              //   </div>

              // </div>

              this.newPlannerBar(tempPlannerData[key], i, true)
        
              );

            } else {

              plannerBars.sat.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, true));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sat.push(
                    <div 
                      className='planner_headerText' 
                      style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), color: Constants.getContrast(maxBars2[key2].functionColor)}}
                      onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                      onDragOver={(event) => this.onDragOver(event, i)}
                      onDragLeave={(event) => this.onDragLeave(event)}
                      onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                    >
                      <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sat.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }
      if(i === 6) {
        
        let tempPlannerData = [];
        let functionTitle = '';

        dayShiftDataDay = this.dayShiftsData.sun;
        plannerDataDay = this.plannerData.sun;

        for(let key in this.functionsData) {

          if(this.state.showDefaultDayShifts === true) {

            for(let key2 in dayShiftDataDay) {

              if(this.functionsData[key].functie_id === dayShiftDataDay[key2].functie_id) {

                tempPlannerData.push(
                  {
                      ...dayShiftDataDay[key2],
                      rooster_id: 'd' + dayShiftDataDay[key2].dagdienst_id,
                      rooster_begin: dayShiftDataDay[key2].dagdienst_begin,
                      rooster_eind: dayShiftDataDay[key2].dagdienst_eind,
                      rooster_pauze: dayShiftDataDay[key2].dagdienst_pauze,
                      info_voornaam: <span>&nbsp;</span>, //n.v.t.
                      info_achternaam: '',
                      info_tussenvoegsel: null,
                      rooster_publiceren: '2',
                      functie_id: dayShiftDataDay[key2].functie_id,
                      functie_naam: dayShiftDataDay[key2].functie_naam,
                      functie_kleur: dayShiftDataDay[key2].functie_kleur,
                  }
                );

              }

            }

          }

          for(let key2 in plannerDataDay) {

            if(this.functionsData[key].functie_id === plannerDataDay[key2].functie_id) {

              tempPlannerData.push(
                plannerDataDay[key2]
              );

            }

          }

          for(const userKey in this.unpublishedRepeatingShifts) {
            for (const r of this.unpublishedRepeatingShifts[userKey]) {
              if (r.date.getDay() !== 0) {
                continue;
              }
              if(this.functionsData[key].functie_id === r.functie_id) {
                tempPlannerData.push({
                  ...r,
                  index: this.functionsData.indexOf(this.functionsData[key]),
                });
              }
            }
          }

        }

        let count = 0;

        if(tempPlannerData.length === 0) {
          for(let key2 in maxBars2) {
            plannerBars.sun.push(
              <div className={'planner_collapseIconContainer'}>
                {Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false ?
                  <i className={'far fa-caret-square-up planner_collapseIcon'} style={{color: `#${maxBars2[key2].functionColor}`}} onClick={() => this.collapseFunction(maxBars2[key2].functionID)} />
                : 
                  <i className={'fas fa-caret-square-down planner_collapseIcon'} style={{color: `#${maxBars2[key2].functionColor}`}} onClick={() => this.expandFunction(maxBars2[key2].functionID)} />
                }
                <div className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                  onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                >
                  <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
            if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
              let j = 0;
              while(j < maxBars2[key2].max) {
                plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                j++;
              }
            }
          }
        }

        let tempBarsArray = [];

        for(let key in tempPlannerData) {

          if(tempPlannerData[key].functie_naam !== functionTitle) {
            if(functionTitle !== '') {
              for(let key2 in maxBars2) {
                if(maxBars2[key2].functionID === tempPlannerData[key - 1].functie_id) {
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = count;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              }
              count = 0;
            }
            for(let key2 in maxBars2) {
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sun.push(
                    <div className={'planner_collapseIconContainer'}>
                      {Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false ?
                        <i className={'far fa-caret-square-up planner_collapseIcon'} style={{color: `#${maxBars2[key2].functionColor}`}} onClick={() => this.collapseFunction(maxBars2[key2].functionID)} />
                      : 
                        <i className={'fas fa-caret-square-down planner_collapseIcon'} style={{color: `#${maxBars2[key2].functionColor}`}} onClick={() => this.expandFunction(maxBars2[key2].functionID)} />
                      }
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                      >
                        <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { break; }
            }
            functionTitle = tempPlannerData[key].functie_naam;
            plannerBars.sun.push(
              <div className={'planner_collapseIconContainer'}>
                {Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false ?
                  <i className={'far fa-caret-square-up planner_collapseIcon'} style={{color: `#${tempPlannerData[key].functie_kleur}`}} onClick={() => this.collapseFunction(tempPlannerData[key].functie_id)} />
                : 
                  <i className={'fas fa-caret-square-down planner_collapseIcon'} style={{color: `#${tempPlannerData[key].functie_kleur}`}} onClick={() => this.expandFunction(tempPlannerData[key].functie_id)} />
                }
                <div 
                  className='planner_headerText' 
                  style={{backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(tempPlannerData[key].functie_kleur)}}
                  onClick={() => this.onClickHeader(i, tempPlannerData[key].functie_id)}
                  onDragOver={(event) => this.onDragOver(event, i)}
                  onDragLeave={(event) => this.onDragLeave(event)}
                  onDrop={(event) => this.onDrop(event, i, null, tempPlannerData[key].functie_id)}
                >
                  <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                </div>
              </div>
            );
          }
    
          if(Constants.arrayContains(this.state.collapsedFunctions, tempPlannerData[key].functie_id) === false) {

            if (Constants.isEmpty(tempPlannerData[key].hr_id) === true) {
            
              plannerBars.sun.push( 

                // <div className='planner_touchBoxOuter'>
        
                //   <div 
                //     key={key} 
                //     className={this.state.lastEdited === tempPlannerData[key].rooster_id + i || this.state.userHoursScheduleID === parseInt(tempPlannerData[key].rooster_id) ? `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} lastEdited ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}` : `planner_touchBox${tempPlannerData[key].rooster_publiceren === '2' ? '2' : ''} ${this.state.selectedBar === 'r' + tempPlannerData[key].rooster_id + i ? "blink" : ""}`} 
                //     style={{borderColor: `#${tempPlannerData[key].functie_kleur}`, backgroundColor: Constants.hexToRgbA(`#${tempPlannerData[key].functie_kleur}`, 0.1),}}
                //     draggable={this.state.showPlanMenu[tempPlannerData[key].rooster_id] || tempPlannerData[key].rooster_publiceren === '2' ? 'false' : 'true'}
                //     onDragStart={(event) => this.onDragStartUserShift(event, i, tempPlannerData[key])}
                //     onDrop={(event) => this.onDrop(event, i, tempPlannerData[key])}
                //     onDragOver={(event) => this.onDragOver(event, i)}
                //     onDragLeave={(event) => this.onDragLeave(event)}
                //     //onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : null}
                //   >

                //     <div
                //       style={{display: 'flex', flexDirection: 'row', flex: 14}}
                //     >
          
                //     {/* <div className='planner_timeContainer' data-tip data-for={`hoursTooltip${key}sun`}>

                //       {tempPlannerData[key].rooster_publiceren === '2' ?
                        
                //         <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>

                //       :

                //         <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{tempPlannerData[key].rooster_begin.substring(0,5)} - {tempPlannerData[key].rooster_eind.substring(0,5)}</div>
                      
                //       }
              
                //     </div> */}

                //         {this.state.editPlan === false || tempPlannerData[key].rooster_publiceren === '2' ?

                //           <div className='planner_timeContainer' 
                //             style={tempPlannerData[key].rooster_publiceren === '2' ? {cursor: 'pointer'} : null} 
                //             onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickPlanTime(tempPlannerData[key], i)}
                //           >

                //             {tempPlannerData[key].rooster_publiceren === '2' ?
                              
                //               <div style={{color: Colors.color.redFilledIn, fontWeight: 'bold'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>

                //             :

                //               <div style={{color: tempPlannerData[key].rooster_tijd_incorrect === '1' ? Colors.color.redFilledIn : '#04163b'}}>{this.state.planInputs[tempPlannerData[key].rooster_id]}</div>
                            
                //             }

                //           </div>

                //         :

                //           <input
                //             ref={(ref) => this.timeRef[tempPlannerData[key].rooster_id]=ref}
                //             className='planner_timeContainerInput'
                //             //defaultValue={`${tempPlannerData[key].rooster_begin.substring(0,5)} - ${tempPlannerData[key].rooster_eind.substring(0,5)}`}
                //             //value={this.state.planInputs[tempPlannerData[key].rooster_id]}
                //             defaultValue={this.state.planInputs[tempPlannerData[key].rooster_id]}
                //             onKeyDown={(event) => this.handleKey(event, tempPlannerData[key])}
                //             onBlur={(e) => this.saveTimeDirectly(e, tempPlannerData[key])}
                //             onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
                //             onChange={(event) => this.onChangeTime(event, tempPlannerData[key])}
                //             type={'text'}
                //             style={
                //               tempPlannerData[key].rooster_publiceren === '2' ? 
                //                 {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
                //               : 
                //               tempPlannerData[key].rooster_tijd_incorrect === '1' ?
                //                 {color: Colors.color.redFilledIn} 
                //               :
                //                 {color: '#04163b'} 
                //             }
                //           />

                //         }
          
                //     <div className='planner_nameContainer'
                //       style={userHasAllSkills === false ? {color: Colors.color.redFilledIn} : null}
                //       onClick={
                //         tempPlannerData[key].rooster_publiceren === '2' ?
                //         () => this.onClickDayShift(i, tempPlannerData[key])
                //         : 
                //           () => this.onClickUserShift(i, tempPlannerData[key]) 
                //         }
                //     >
          
                //       {tempPlannerData[key].rooster_publiceren === '2' ?
                        
                //         <div className='planner_nameText' style={{color: Colors.color.redFilledIn}}>{tempPlannerData[key].info_voornaam}</div>

                //       :

                //         <div className='planner_nameText'>{userFullName}</div>
                      
                //       }
          
                //     </div>

                //     </div>

                //     {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
                //         <div className='planner_afkContainer' 
                //           style={{color: `#${partColor}`}}
                //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
                //         >
                //           <span>{partAfk}</span>
                //         </div>
                //       : 
                //       absence !== null ?
                //         <div className='planner_afkContainer' 
                //           style={{color: Colors.color.redFilledIn}}
                //           onClick={tempPlannerData[key].rooster_publiceren === '2' ? () => this.onClickDayShift(i, tempPlannerData[key]) : () => this.onClickUserShift(i, tempPlannerData[key])}
                //         >
                //           <span>{absence.afw_afkorting}</span>
                //         </div>
                //       : null}

                //     {tempPlannerData[key].rooster_publiceren === '0' ?
                    
                //       <Tooltip
                //         options
                //         html={(
                //           <div className='planner_commentContainer'>
                //             <i className='fas fa-exclamation'></i>
                //             <span style={{marginLeft: '0.5vw'}}>Nog niet gepubliceerd</span>
                //           </div>
                //         )}
                //         position={'top'}
                //         arrow
                //         followCursor
                //         theme='kodict'
                //         className='planner_iconContainer'
                //       >
          
                //       {

                //         tempPlannerData[key].rooster_publiceren === '0' ?

                //           <i className='fa fa-exclamation' data-tip data-for={`publishedTooltip${key}mon`} style={{fontSize: '0.7vw'}}></i>

                //       :

                //         null

                //       }

                //       </Tooltip>

                //     : null}

                //     {tempPlannerData[key].rooster_publiceren !== '2' ?

                //     <div className='planner_arrowContainer' onClick={() => this.state.showPlanMenu[tempPlannerData[key].rooster_id] ? this.closePlanMenu(tempPlannerData[key].rooster_id) : this.openPlanMenu(tempPlannerData[key].rooster_id)}>

                //       <i className='fas fa-caret-down' />

                //     </div>

                //     : 

                //     skills !== null || partName !== null ?

                //       <div className='planner_arrowContainer' onClick={() => this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] ? this.closeDayShiftMenu(tempPlannerData[key].rooster_id) : this.openDayShiftMenu(tempPlannerData[key].rooster_id)}>

                //         <i className='fas fa-caret-down' />

                //       </div>

                //     : null}

                //     {this.state.showPlanMenu[tempPlannerData[key].rooster_id] === true ?

                //       this.renderPlanMenu(tempPlannerData[key], true, partID, absence, skills, userHasAllSkills)

                //     : null}

                //     {this.state.showDayShiftMenu[tempPlannerData[key].rooster_id] === true ?

                //     this.renderDayShiftMenu(tempPlannerData[key], true, partName, skills)

                //     : null}
          
                //   </div>

                // </div>

                this.newPlannerBar(tempPlannerData[key], i, true)
        
              );

            } else {

              plannerBars.sun.push(this.newPlannerBarRepeatShift(tempPlannerData[key], i, true));

            }

          }

          if(Constants.arrayContains(tempBarsArray, tempPlannerData[key].functie_id) === false) {
            tempBarsArray.push(tempPlannerData[key].functie_id);
          }

          count++;

          if(parseInt(key) === (tempPlannerData.length - 1)) {
            for(let key2 in maxBars2) {
              if(maxBars2[key2].functionID === tempPlannerData[key].functie_id) {
                if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                  let j = count;
                  while(j < maxBars2[key2].max) {
                    plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                    j++;
                  }
                }
              }
              if(tempPlannerData[key].functie_id !== maxBars2[key2].functionID) {
                if(Constants.arrayContains(tempBarsArray, maxBars2[key2].functionID) === false) {
                  tempBarsArray.push(maxBars2[key2].functionID);
                  plannerBars.sun.push(
                    <div className={'planner_collapseIconContainer'}>
                      {Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false ?
                        <i className={'far fa-caret-square-up planner_collapseIcon'} style={{color: `#${maxBars2[key2].functionColor}`}} onClick={() => this.collapseFunction(maxBars2[key2].functionID)} />
                      : 
                        <i className={'fas fa-caret-square-down planner_collapseIcon'} style={{color: `#${maxBars2[key2].functionColor}`}} onClick={() => this.expandFunction(maxBars2[key2].functionID)} />
                      }
                      <div 
                        className='planner_headerText' 
                        style={{backgroundColor: Constants.hexToRgbA(`#${maxBars2[key2].functionColor}`, 0.5), borderTopRightRadius: 5, borderBottomRightRadius: 5, color: Constants.getContrast(maxBars2[key2].functionColor)}}
                        onClick={() => this.onClickHeader(i, maxBars2[key2].functionID)}
                        onDragOver={(event) => this.onDragOver(event, i)}
                        onDragLeave={(event) => this.onDragLeave(event)}
                        onDrop={(event) => this.onDrop(event, i, null, maxBars2[key2].functionID)}
                      >
                        <i className='fa fa-plus planner_headerText-icon'></i><i className='fa fa-plus planner_headerText-iconSmall'></i>
                      </div>
                    </div>
                  );
                  if(Constants.arrayContains(this.state.collapsedFunctions, maxBars2[key2].functionID) === false) {
                    let j = 0;
                    while(j < maxBars2[key2].max) {
                      plannerBars.sun.push(<div className='plannerN_touchBoxEmpty' />);
                      j++;
                    }
                  }
                }
              } else { continue; }
            }
          }

        }

      }

      // let j = dayShiftDataDay.length + plannerDataDay.length;
      // while(j < maxNotFilled) {
      //   plannerDataBars.push(<div className='plannerN_touchBoxEmpty' />);
      //   j++;
      // }

    }

    return plannerBars;

  }

  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
    const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
    return Math.round((hoursMins + Number.EPSILON) * 100) / 100;
  }

  getHoursTooltip(data, left) {

    const userID = data.info_id;
    const dateString = data.rooster_datum;
    const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

    const dateObject = Constants.stringToDate(dateString);

    let foundContract = false;

    let nettoWeek = 0;
    let dagenWeek = 0;
    let kostenWeek = 0;
    let contractUrenWeek = 0;

    let bruto = 0;
    let pauze = 0;
    let netto = 0;
    let dagen = 0;
    let kosten = 0;

    let uurloon = 0;

    let availableText = 'Niks ingevuld';
    let availableFound = false;

    if (Data.data.appOptions.availability === 'SCHOOL') {
      availableText = 'Geen school';
    }

    const monthName = Constants.getMonthNameM(dateObject.getMonth());

    // const hoursMonth = dateObject.getMonth() + 1 === this.state.month ? this.state.hoursMonth : this.state.hoursMonth2;

    // for (const person of hoursMonth) {

    //   if (parseInt(person.info_id) === parseInt(userID)) {

    //     dagen = person.rooster_werkdagen;
    //     bruto = this.timeStringToFloat(person.rooster_bruto_uren);
    //     pauze = this.timeStringToFloat(person.rooster_pauze_totaal);
    //     netto = this.timeStringToFloat(person.rooster_netto_uren);

    //     break;

    //   }

    // }

    // for (const person of this.state.hoursWeek) {

    //   if (parseInt(person.info_id) === parseInt(userID)) {

    //     dagenWeek = person.rooster_werkdagen;
    //     nettoWeek = this.timeStringToFloat(person.rooster_netto_uren);

    //     break;

    //   }

    // }

    //nettoWeek = this.getUserHoursWeek(userID);

    const contract = Constants.getCurrentContract(this.state.contractsObj[userID]);
    if (contract !== null) {
      const salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
      uurloon = salary;
      foundContract = true;
    }

    // for (const person of this.state.contracts) {

    //   if (parseInt(person.contract_info_id) === parseInt(userID)) {

    //     const salary = isNaN(parseFloat(person.contract_bruto_uurloon)) ? 0 : parseFloat(person.contract_bruto_uurloon);

    //     //kostenWeek = (nettoWeek * salary) * this.salaryFactor;
    //     //kosten = (netto * salary) * this.salaryFactor;
    //     //contractUrenWeek = parseInt(person.contract_uren);
    //     uurloon = salary;

    //     foundContract = true;

    //     break;

    //   }

    // }

    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      if (typeof this.state.allAvailabilities[userID] !== 'undefined') {
        for (const schedule of this.state.allAvailabilities[userID]) {
          if(schedule.beschikbaar_datum === dateString) {
            if (schedule.beschikbaar_begin === '00:00:00' || schedule.beschikbaar_eind === '00:00:00') {
              availableText = <span className='planMenuAsset'>Hele dag beschikbaar</span>;
            } else {
              availableText = <span className='planMenuAsset' style={data.rooster_tijd_incorrect === '1' ? {color: Colors.color.redFilledIn} : null}>Beschikbaar van {schedule.beschikbaar_begin.substr(0, 5)} - {schedule.beschikbaar_eind.substr(0, 5)}</span>;
            }
            availableFound = true;
            break;
          }
        }
      }

      if(availableFound === false) {
        if (typeof this.state.allUnavailabilities[userID] !== 'undefined') {
          for (const schedule of this.state.allUnavailabilities[userID]) {
            if(schedule.onbeschikbaar_datum === dateString) {
              if (schedule.onbeschikbaar_begin === '00:00:00' || schedule.onbeschikbaar_eind === '00:00:00') {
                availableText = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</span>;
              } else {
                availableText = <span className='planMenuAsset' style={data.rooster_tijd_incorrect === '1' ? {color: Colors.color.redFilledIn} : null}>Onbeschikbaar van {schedule.onbeschikbaar_begin.substr(0, 5)} - {schedule.onbeschikbaar_eind.substr(0, 5)}</span>;
              }
            }
          }
        }
      }

    } else if (Data.data.appOptions.availability === 'STANDARD') {

      if (typeof this.state.allStandardAvailabilities[userID] !== 'undefined') {
        for (const schedule of this.state.allStandardAvailabilities[userID]) {
          if(parseInt(schedule.sb_dag) === dateObject.getDay()) {
            if (schedule.sb_begin === '00:00:00' || schedule.sb_eind === '00:00:00') {
              availableText = <span className='planMenuAsset'>Hele dag beschikbaar</span>;
            } else {
              availableText = <span className='planMenuAsset' style={data.rooster_tijd_incorrect === '1' ? {color: Colors.color.redFilledIn} : null}>Beschikbaar van {schedule.sb_begin.substr(0, 5)} - {schedule.sb_eind.substr(0, 5)}</span>;
            }
            availableFound = true;
            break;
          }
        }
      }

      if(availableFound === false) {
        if (typeof this.state.allStandardUnavailabilities[userID] !== 'undefined') {
          for (const schedule of this.state.allStandardUnavailabilities[userID]) {
            if(parseInt(schedule.sb_dag) === dateObject.getDay()) {
              if (schedule.sb_begin === '00:00:00' || schedule.sb_eind === '00:00:00') {
                availableText = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</span>;
              } else {
                availableText = <span className='planMenuAsset' style={data.rooster_tijd_incorrect === '1' ? {color: Colors.color.redFilledIn} : null}>Onbeschikbaar van {schedule.sb_begin.substr(0, 5)} - {schedule.sb_eind.substr(0, 5)}</span>;
              }
            }
          }
        }
      }

    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      const dateDay = dateObject.getDay();
      const dayKey = dateDay === 1 ? 'mon' : dateDay === 2 ? 'tue' : dateDay === 3 ? 'wed' : dateDay === 4 ? 'thu' : dateDay === 5 ? 'fri' : dateDay === 6 ? 'sat' : dateDay === 0 ? 'sun' : 'mon';

      if (typeof this.state.multipleAvail[dayKey][userID] !== 'undefined') {

        const multipleAvail = this.state.multipleAvail[dayKey][userID];

        let schooltext = multipleAvail.school !== null ? `${multipleAvail.school.schoolrooster_begin.substr(0, 5)} - ${multipleAvail.school.schoolrooster_eind.substr(0, 5)}` : null;
        if (typeof this.state.schoolHolidayDataState[dayKey] !== 'undefined') {
          for (const hol of this.state.schoolHolidayDataState[dayKey]) {
            if(hol.info_id === userID) {
              schooltext = 'schoolvakantie';
              break;
            }
          }
        }

        // if (multipleAvail.school !== null && multipleAvail.av !== null) {
        //   availableText = (
        //     <div>
        //       <div className='planMenuAsset'>School: {multipleAvail.school.schoolrooster_begin.substr(0, 5)} - {multipleAvail.school.schoolrooster_eind.substr(0, 5)}</div>
        //       {(multipleAvail.av.beschikbaar_begin === '00:00:00' || multipleAvail.av.beschikbaar_eind === '00:00:00') ?
        //         <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Beschikbaar: hele dag</div>
        //       :
        //         <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Beschikbaar: {multipleAvail.av.beschikbaar_begin.substr(0, 5)} - {multipleAvail.av.beschikbaar_eind.substr(0, 5)}</div>
        //       }
        //     </div>
        //   );
        // } else 

        if (multipleAvail.school !== null && multipleAvail.unav !== null) {
          availableText = (
            <div>
              {this.schoolhol}
              <div className='planMenuAsset'>School: {schooltext}</div>
              {(multipleAvail.unav.onbeschikbaar_begin === '00:00:00' || multipleAvail.unav.onbeschikbaar_eind === '00:00:00') ?
                <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: hele dag</div>
              :
                <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: {multipleAvail.unav.onbeschikbaar_begin.substr(0, 5)} - {multipleAvail.unav.onbeschikbaar_eind.substr(0, 5)}</div>
              }
            </div>
          );
        } else if (multipleAvail.av !== null) {
          availableText = (
            <div>
              {(multipleAvail.av.beschikbaar_begin === '00:00:00' || multipleAvail.av.beschikbaar_eind === '00:00:00') ?
                <div className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Beschikbaar: hele dag</div>
              :
                <div className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Beschikbaar: {multipleAvail.av.beschikbaar_begin.substr(0, 5)} - {multipleAvail.av.beschikbaar_eind.substr(0, 5)}</div>
              }
            </div>
          );
        } else if (multipleAvail.unav !== null) {
          availableText = (
            <div>
              {(multipleAvail.unav.onbeschikbaar_begin === '00:00:00' || multipleAvail.unav.onbeschikbaar_eind === '00:00:00') ?
                <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: hele dag</div>
              :
                <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: {multipleAvail.unav.onbeschikbaar_begin.substr(0, 5)} - {multipleAvail.unav.onbeschikbaar_eind.substr(0, 5)}</div>
              }
            </div>
          );
        } else if (multipleAvail.school !== null) {
          availableText = (
            <div>
              <div className='planMenuAsset'>School: {schooltext}</div>
            </div>
          );
        }

      }

      // if (typeof this.state.allSchoolSchedules[userID] !== 'undefined') {
      //   for (const schedule of this.state.allSchoolSchedules[userID]) {
      //     if(parseInt(schedule.schoolrooster_dag) === dateObject.getDay()) {
      //       availableText = <span className='planMenuAsset' style={data.rooster_tijd_incorrect === '1' ? {color: Colors.color.redFilledIn} : null}>School van {schedule.schoolrooster_begin.substr(0, 5)} - {schedule.schoolrooster_eind.substr(0, 5)}</span>;
      //       availableFound = true;
      //       break;
      //     }
      //   }
      // }

      // if(availableFound === false) {
      //   if (typeof this.state.allUnavailabilities[userID] !== 'undefined') {
      //     for (const schedule of this.state.allUnavailabilities[userID]) {
      //       if(schedule.onbeschikbaar_datum === dateString) {
      //         if (schedule.onbeschikbaar_begin === '00:00:00' || schedule.onbeschikbaar_eind === '00:00:00') {
      //           availableText = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</span>;
      //         } else {
      //           availableText = <span className='planMenuAsset' style={data.rooster_tijd_incorrect === '1' ? {color: Colors.color.redFilledIn} : null}>Onbeschikbaar van {schedule.onbeschikbaar_begin.substr(0, 5)} - {schedule.onbeschikbaar_eind.substr(0, 5)}</span>;
      //         }
      //       }
      //     }
      //   }
      // }

    }

    //SKILLS
    const employeeSkills = this.state.skillsUsers[userID];

    let skills = null;

    if (typeof employeeSkills !== 'undefined') {

      skills = [];

      for (const skill of employeeSkills) {
        skills.push(
          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              - {skill.comp_naam}
            </div>
          </div>
        );
      }

    }

    let _weeklyHours = 0;
    let _weeklyDays = [];
    if (Constants.isEmpty(this.state.weeklyHours[userID]) === false) {
      for (const _plan of this.state.weeklyHours[userID]) {
        const _start = Constants.stringToDateTime(_plan.rooster_datum, _plan.rooster_begin.substr(0,5));
        const _end = Constants.stringToDateTime(Plan.isNightShift2(_plan), _plan.rooster_eind.substr(0,5));
        const _break = Constants.timeStringToMS(_plan.rooster_pauze.substr(0,5));
        if (Constants.objectArrayContainsKey(_weeklyDays, 'rooster_datum', _plan.rooster_datum) === false) {
          _weeklyDays.push(_plan);
        }
        _weeklyHours += ((_end - _start) - _break);
      }
    }

    let _monthlyHours = 0;
    let _monthlyDays = [];
    if (Constants.isEmpty(this.state.monthlyHours[userID]) === false) {
      for (const _plan of this.state.monthlyHours[userID]) {
        const _start = Constants.stringToDateTime(_plan.rooster_datum, _plan.rooster_begin.substr(0,5));
        const _end = Constants.stringToDateTime(Plan.isNightShift2(_plan), _plan.rooster_eind.substr(0,5));
        const _break = Constants.timeStringToMS(_plan.rooster_pauze.substr(0,5));
        if (Constants.objectArrayContainsKey(_monthlyDays, 'rooster_datum', _plan.rooster_datum) === false) {
          _monthlyDays.push(_plan);
        }
        _monthlyHours += ((_end - _start) - _break);
      }
    }

    return(

      <div className={`planner_planMenuSub${left ? '_left' : ''}`} style={{paddingBottom: '6%'}}>

        {/* NAME */}
        <div className='planner_userMenu_row' style={{marginTop: '1%', paddingBottom: '4%', borderBottom: '1px solid #e1e1e1'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>{userFullName}</b>
          </div>
        </div>

        {/* BESCHIKBAARHEID */}
        <div className='planner_planMenuSubInfo_row' style={{marginTop: '3%', marginBottom: '0%'}}>
          <div className='planner_planMenuSubInfo_row_sub1'>
            <b className='planMenuAsset'>Beschikbaarheid</b>
          </div>
        </div>

        <div className='planner_planMenuSubInfo_row' style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSubInfo_row_sub1'>
            {availableText}
          </div>
        </div>

        {/* FUNCTIE */}
        <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '0%'}}>
          <div className='planner_planMenuSubInfo_row_sub1'>
            <b className='planMenuAsset'>Functie</b>
          </div>
        </div>

        <div className='planner_planMenuSubInfo_row' style={{marginBottom: '3%'}}>
          <div className='planner_planMenuSubInfo_row_sub1'>
            {data.functie_naam}
          </div>
        </div>

        {/* COMPETENTIES */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              <b className='planMenuAsset'>Competenties</b>
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'skills'})}>
              Beheer competenties
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          skills
        : null}

        {/* SALARY */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              <b className='planMenuAsset'>Uurloon</b>
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          foundContract === true ?
            <div className='planner_planMenuSubInfo_row'>
              <div className='planner_planMenuSubInfo_row_sub1'>
                {this.formatter.format(Math.round((uurloon + Number.EPSILON) * 100) / 100)}
              </div>
            </div>
          :
            <div className='planner_planMenuSubInfo_row'>
              <div className='planner_planMenuSubInfo_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'contract'})}>
                Uurloon toevoegen
              </div>
            </div>
        : null}

        {/* VANDAAG */}
        <div>
          <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              <b className='planMenuAsset'>Deze dag</b>
            </div>
          </div>

          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Uren:
            </div>
            <div className='planner_planMenuSubInfo_row_sub2'>
              {Constants.msToTime2((Constants.stringToDateTime(Plan.isNightShift2(data), data.rooster_eind.substr(0,5)) - Constants.stringToDateTime(data.rooster_datum, data.rooster_begin.substr(0,5))) - Constants.timeStringToMS(data.rooster_pauze.substr(0,5)))}
            </div>
          </div>

          {/* WEEK */}
          <div className='planner_planMenuSubInfo_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              <b className='planMenuAsset'>Week {this.state.week}</b>
            </div>
          </div>

          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Dagen:
            </div>
            <div className='planner_planMenuSubInfo_row_sub2'>
              {_weeklyDays.length}
            </div>
          </div>

          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Uren:
            </div>
            <div className='planner_planMenuSubInfo_row_sub2'>
              {Constants.msToTime2(_weeklyHours)}
            </div>
          </div>

          {/* MAAND */}
          <div className='planner_planMenuSubInfo_row' style={{marginBottom: '0%', marginTop: '2%'}}>
            <div className='planner_planMenuSubInfo_row_sub1'>
              <b className='planMenuAsset'>Maand {monthName}</b>
            </div>
          </div>

          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Dagen:
            </div>
            <div className='planner_planMenuSubInfo_row_sub2'>
              {_monthlyDays.length}
            </div>
          </div>

          <div className='planner_planMenuSubInfo_row'>
            <div className='planner_planMenuSubInfo_row_sub1'>
              Uren:
            </div>
            <div className='planner_planMenuSubInfo_row_sub2'>
              {Constants.msToTime2(_monthlyHours)}
            </div>
          </div>

        </div>

        {/* {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row_hover' style={{marginTop: '4%'}} onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'stats'})}>
            <div className='planner_userMenu_row_hover_sub1'>
              Medewerkeroverzicht
            </div>
            <div className='planner_userMenu_row_hover_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        : null} */}

        <div className='planner_userMenu_row_hover' style={{marginTop: '4%'}} onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'settings'})}>
          <div className='planner_userMenu_row_hover_sub1'>
            Medewerkerinstellingen
          </div>
          <div className='planner_userMenu_row_hover_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

      </div>

      // <div style={{display: 'flex', flexDirection: 'row'}}>

      //   <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, paddingRight: '0.5vw', borderRight: '1px solid #fff'}}>

      //     <div><b>Week {this.state.week}</b></div>

      //     <div style={{marginTop: '1vw'}}>Dagen: {dagenWeek}</div>

      //     {foundContract && !isNaN(contractUrenWeek) && contractUrenWeek !== 0 ?
      //       <div>{nettoWeek !== contractUrenWeek ? <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn, marginRight: 5}}/> : null}Netto uren: {nettoWeek} / {contractUrenWeek}</div>
      //     :
      //       <div>Netto uren: {nettoWeek}</div>
      //     }

      //     {foundContract === true ?
      //       <div><b>Kosten: {this.formatter.format(Math.round((kostenWeek + Number.EPSILON) * 100) / 100)}</b></div>
      //     :
      //       <div>Kosten: n.v.t.</div>
      //     }

      //   </div>

      //   <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, paddingLeft: '0.5vw'}}>

      //     <div><b>Maand {monthName}</b></div>

      //     <div style={{marginTop: '1vw'}}>Dagen: {dagen}</div>
      //     <div>Netto uren: {netto}</div>
      //     {foundContract === true ?
      //       <div><b>Kosten: {this.formatter.format(Math.round((kosten + Number.EPSILON) * 100) / 100)}</b></div>
      //     :
      //       <div>Kosten: n.v.t.</div>
      //     }

      //   </div>

      // </div>

    );

  }

  getHoursTooltipDay(userID, i) {

    let dateString = null;

    if (i === 0) {
      dateString = this.state.mondayDate;
    }
    if (i === 1) {
      dateString = this.state.tuesdayDate;
    }
    if (i === 2) {
      dateString = this.state.wednesdayDate;
    }
    if (i === 3) {
      dateString = this.state.thursdayDate;
    }
    if (i === 4) {
      dateString = this.state.fridayDate;
    }
    if (i === 5) {
      dateString = this.state.saturdayDate;
    }
    if (i === 6) {
      dateString = this.state.sundayDate;
    }

    const dateObject = Constants.stringToDate(dateString);

    let foundContract = false;

    let nettoWeek = 0;
    let dagenWeek = 0;
    let kostenWeek = 0;
    let contractUrenWeek = 0;

    let bruto = 0;
    let pauze = 0;
    let netto = 0;
    let dagen = 0;
    let kosten = 0;

    const monthName = Constants.getMonthNameM(dateObject.getMonth());

    const hoursMonth = dateObject.getMonth() + 1 === this.state.month ? this.state.hoursMonth : this.state.hoursMonth2;

    for (const person of hoursMonth) {

      if (parseInt(person.info_id) === parseInt(userID)) {

        dagen = person.rooster_werkdagen;
        bruto = this.timeStringToFloat(person.rooster_bruto_uren);
        pauze = this.timeStringToFloat(person.rooster_pauze_totaal);
        netto = this.timeStringToFloat(person.rooster_netto_uren);

        break;

      }

    }

    for (const person of this.state.hoursWeek) {

      if (parseInt(person.info_id) === parseInt(userID)) {

        dagenWeek = person.rooster_werkdagen;
        //nettoWeek = this.timeStringToFloat(person.rooster_netto_uren);

        break;

      }

    }

    nettoWeek = Constants.round(this.getUserHoursWeek(userID) / 60 / 60 / 1000);

    for (const person of this.state.contracts) {

      if (parseInt(person.contract_info_id) === parseInt(userID)) {

        const salary = isNaN(parseFloat(person.contract_bruto_uurloon)) ? 0 : parseFloat(person.contract_bruto_uurloon);

        kostenWeek = (nettoWeek * salary) * this.salaryFactor;
        kosten = (netto * salary) * this.salaryFactor;
        contractUrenWeek = parseInt(person.contract_uren);

        foundContract = true;

        break;

      }

    }

    return(

      <div style={{display: 'flex', flexDirection: 'row'}}>

        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, paddingRight: '0.5vw', borderRight: '1px solid #fff'}}>

          <div><b>Week {this.state.week}</b></div>

          <div style={{marginTop: '1vw'}}>Dagen: {dagenWeek}</div>

          {foundContract && !isNaN(contractUrenWeek) && contractUrenWeek !== 0 ?
            <div>{nettoWeek !== contractUrenWeek ? <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn, marginRight: 5}}/> : null}Netto uren: {nettoWeek} / {contractUrenWeek}</div>
          :
            <div>Netto uren: {nettoWeek}</div>
          }

          {foundContract === true ?
            <div><b>Kosten: {this.formatter.format(Math.round((kostenWeek + Number.EPSILON) * 100) / 100)}</b></div>
          :
            <div>Kosten: n.v.t.</div>
          }

        </div>

        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, paddingLeft: '0.5vw'}}>

          <div><b>Maand {monthName}</b></div>

          <div style={{marginTop: '1vw'}}>Dagen: {dagen}</div>
          <div>Netto uren: {netto}</div>
          {foundContract === true ?
            <div><b>Kosten: {this.formatter.format(Math.round((kosten + Number.EPSILON) * 100) / 100)}</b></div>
          :
            <div>Kosten: n.v.t.</div>
          }

        </div>

      </div>

    );

  }

  async selectDateCalendar(date) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    this.setState({selectedDate: date});

    const dateSelected = Constants.stringToDate(date);

    await this.getCalendarDateData(this.getWeekNumber2(dateSelected), dateSelected.getFullYear());

  }

  changeMode(mode) {

    if(this.state.mode === mode) {
      return;
    }

    this.fromPlannerDay = -1;
    this.fromPlanner= [];
    this.toPlanner = [];
    this.setState({ 
      mode: mode, 
      selectedBar: '',
      showModeMenu: false,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,
    });

  }

  openDayNoteModal(day) {

    let date = this.state.mondayDate;
    let data = [];
    let mode = 0;

    if (day === 'mon') {
      date = this.state.mondayDate;
    } else if (day === 'tue') {
      date = this.state.tuesdayDate;
    } else if (day === 'wed') {
      date = this.state.wednesdayDate;
    } else if (day === 'thu') {
      date = this.state.thursdayDate;
    } else if (day === 'fri') {
      date = this.state.fridayDate;
    } else if (day === 'sat') {
      date = this.state.saturdayDate;
    } else if (day === 'sun') {
      date = this.state.sundayDate;
    }

    if (typeof this.state.dayNotes[date] !== 'undefined') {
      data = this.state.dayNotes[date];
      mode = 1;
    }

    this.setState({ 
      showDayNoteModal: true,
      modalData: data,
      modalMode: mode,
      modalDate: date,
    });

  }

  closeDayNoteModal() {

    this.setState({ 
      showDayNoteModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,
    });

  }

  closePublishModal() {

    this.setState({ 
      showPublishModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,
    });

  }

  openRepeatShiftModal(data, mode) {

    this.closeAllPlanMenus();

    this.setState({ 
      showRepeatShiftModal: true,
      modalData: data,
      modalMode: mode,
    });

  }

  closeRepeatShiftModal() {

    this.setState({ 
      showRepeatShiftModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
    });

  }

  async closeAvailableShiftsModal() {

    // GET USER PUT AVAILABLESHIFTS
    const userAvailableShifts = await APIGET.Request(`SELECT bdienst_rooster_id`, `FROM BeschikbareDienst`, `LEFT JOIN Rooster ON bdienst_rooster_id = rooster_id`, `WHERE rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    }

    this.setState({ 
      showAvailableShiftsModal: false,
      modalData: [],
      modalMode: 0,
      modalDate: null,
      modalFunctionID: null,
      editMode: false,
      userAvailableShifts: userAvailableShifts,
      plannerAvailableShifts: plannerAvailableShifts,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  openQuestionModal() {

    this.setState({ showQuestionModal: true });

  }

  closeQuestionModal() {

    this.setState({ showQuestionModal: false });

  }

  openSmartPlanner() {

    this.setState({ showSmartPlanner: true });

  }

  closeSmartPlanner() {

    this.setState({ showSmartPlanner: false });

  }

  openSortModal(menu) {

    this.setState({ sidemenuActive: false });

    this.setState({ showSortModal: true, modalData: menu });

  }

  closeSortModal() {

    this.setState({ showSortModal: false, modalData: [] });

    this.reloadScreen2FullScreenTeam();

  }

  getAllPeopleAddUser(date, editMode) { //add holiday

    const weekDay = date.getDay();
    const dateOfDay = date;

    let plannerData = [];
    let availableData = [];
    let notFilledData = [];
    let unavailableData = [];
    let holidayData = [];
    let notFilledSchoolData = [];
    let schoolscheduleData = [];
    let schoolHolidayData = [];
    let usersNoAvailability = [];
    let standardAvailableData = [];
    let standardNotFilledData = [];
    let standardUnavailableData = [];
    let allPlannerData = [];

    if(weekDay === 0) {
      plannerData = this.plannerData.sun;
      availableData = this.availableData.sun;
      notFilledData = this.notFilledData.sun;
      unavailableData = this.unavailableData.sun;
      holidayData = this.holidayDaysData.sun;
      notFilledSchoolData = this.notFilledSchool.sun;
      schoolscheduleData = this.schoolScheduleData.sun;
      schoolHolidayData = this.schoolHolidayData.sun;
      usersNoAvailability = this.usersNoAvailability.sun;
      standardAvailableData = this.standardAvailableData.sun;
      standardUnavailableData = this.standardUnavailableData.sun;
      standardNotFilledData = this.standardNotFilledData.sun;
      allPlannerData = this.allPlannerData.sun;
    }
    if(weekDay === 1) {
      plannerData = this.plannerData.mon;
      availableData = this.availableData.mon;
      notFilledData = this.notFilledData.mon;
      unavailableData = this.unavailableData.mon;
      holidayData = this.holidayDaysData.mon;
      notFilledSchoolData = this.notFilledSchool.mon;
      schoolscheduleData = this.schoolScheduleData.mon;
      schoolHolidayData = this.schoolHolidayData.mon;
      usersNoAvailability = this.usersNoAvailability.mon;
      standardAvailableData = this.standardAvailableData.mon;
      standardUnavailableData = this.standardUnavailableData.mon;
      standardNotFilledData = this.standardNotFilledData.mon;
      allPlannerData = this.allPlannerData.mon;
    }
    if(weekDay === 2) {
      plannerData = this.plannerData.tue;
      availableData = this.availableData.tue;
      notFilledData = this.notFilledData.tue;
      unavailableData = this.unavailableData.tue;
      holidayData = this.holidayDaysData.tue;
      notFilledSchoolData = this.notFilledSchool.tue;
      schoolscheduleData = this.schoolScheduleData.tue;
      schoolHolidayData = this.schoolHolidayData.tue;
      usersNoAvailability = this.usersNoAvailability.tue;
      standardAvailableData = this.standardAvailableData.tue;
      standardUnavailableData = this.standardUnavailableData.tue;
      standardNotFilledData = this.standardNotFilledData.tue;
      allPlannerData = this.allPlannerData.tue;
    }
    if(weekDay === 3) {
      plannerData = this.plannerData.wed;
      availableData = this.availableData.wed;
      notFilledData = this.notFilledData.wed;
      unavailableData = this.unavailableData.wed;
      holidayData = this.holidayDaysData.wed;
      notFilledSchoolData = this.notFilledSchool.wed;
      schoolscheduleData = this.schoolScheduleData.wed;
      schoolHolidayData = this.schoolHolidayData.wed;
      usersNoAvailability = this.usersNoAvailability.wed;
      standardAvailableData = this.standardAvailableData.wed;
      standardUnavailableData = this.standardUnavailableData.wed;
      standardNotFilledData = this.standardNotFilledData.wed;
      allPlannerData = this.allPlannerData.wed;
    }
    if(weekDay === 4) {
      plannerData = this.plannerData.thu;
      availableData = this.availableData.thu;
      notFilledData = this.notFilledData.thu;
      unavailableData = this.unavailableData.thu;
      holidayData = this.holidayDaysData.thu;
      notFilledSchoolData = this.notFilledSchool.thu;
      schoolscheduleData = this.schoolScheduleData.thu;
      schoolHolidayData = this.schoolHolidayData.thu;
      usersNoAvailability = this.usersNoAvailability.thu;
      standardAvailableData = this.standardAvailableData.thu;
      standardUnavailableData = this.standardUnavailableData.thu;
      standardNotFilledData = this.standardNotFilledData.thu;
      allPlannerData = this.allPlannerData.thu;
    }
    if(weekDay === 5) {
      plannerData = this.plannerData.fri;
      availableData = this.availableData.fri;
      notFilledData = this.notFilledData.fri;
      unavailableData = this.unavailableData.fri;
      holidayData = this.holidayDaysData.fri;
      notFilledSchoolData = this.notFilledSchool.fri;
      schoolscheduleData = this.schoolScheduleData.fri;
      schoolHolidayData = this.schoolHolidayData.fri;
      usersNoAvailability = this.usersNoAvailability.fri;
      standardAvailableData = this.standardAvailableData.fri;
      standardUnavailableData = this.standardUnavailableData.fri;
      standardNotFilledData = this.standardNotFilledData.fri;
      allPlannerData = this.allPlannerData.fri;
    }
    if(weekDay === 6) {
      plannerData = this.plannerData.sat;
      availableData = this.availableData.sat;
      notFilledData = this.notFilledData.sat;
      unavailableData = this.unavailableData.sat;
      holidayData = this.holidayDaysData.sat;
      notFilledSchoolData = this.notFilledSchool.sat;
      schoolscheduleData = this.schoolScheduleData.sat;
      schoolHolidayData = this.schoolHolidayData.sat;
      usersNoAvailability = this.usersNoAvailability.sat;
      standardAvailableData = this.standardAvailableData.sat;
      standardUnavailableData = this.standardUnavailableData.sat;
      standardNotFilledData = this.standardNotFilledData.sat;
      allPlannerData = this.allPlannerData.sat;
    }

    let peopleInPlanner = [];
    let availablePeople = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      for(let key in allPlannerData) {

          for (const teamUser of this.teamUsers) {
              if (allPlannerData[key].info_id === teamUser.teamu_info_id && parseInt(teamUser.teamu_team_id) === Data.data.chosenTeam) {
                  peopleInPlanner.push(allPlannerData[key].info_voornaam + allPlannerData[key].info_id);
              }
          }

      }

    } else {

      for(let key in plannerData) {

        peopleInPlanner.push(plannerData[key].info_voornaam + plannerData[key].info_id);

      }

    }

    for(let key in holidayData) {

      const holidayDate = holidayData[key].date;

      if(dateOfDay.getDate() === holidayDate.getDate() && dateOfDay.getMonth() === holidayDate.getMonth() && dateOfDay.getFullYear() === holidayDate.getFullYear()) {

      //const userFullName = `${holidayData[key].info_voornaam} ${(holidayData[key].info_tussenvoegsel !== null && holidayData[key].info_tussenvoegsel !== '') ? holidayData[key].info_tussenvoegsel + " " + holidayData[key].info_achternaam.charAt(0) : holidayData[key].info_achternaam.charAt(0)}.`;

        availablePeople.push(
            {
                name: holidayData[key].name,
                value: null,
                color: Colors.color.orangeVacation,
                start: '99:99',
                end: '99:99',
                inPlanner: false,
            }
        );

      }

    }

    if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      for(let key in availableData) {

          // if(this.peopleInPlannerContains(this.availableData[key].info_voornaam + this.availableData[key].info_id, peopleInPlanner) === true) {
          //     continue;
          // }

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[availableData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

            // // has contract
            // if (typeof this.state.contractsObj[availableData[key].info_id] !== 'undefined') {
            //   const contract = this.state.contractsObj[availableData[key].info_id];
            //   // has valid end date
            //   if (Constants.isValidDateString(contract.contract_eind)) {
            //     const endDate = Constants.stringToDate(contract.contract_eind);
  
            //     if (weekDay === 0 && Constants.formatDate(endDate) <= this.state.sundayDate) {
            //       contractExpired = true;
            //     } else if (weekDay === 1 && Constants.formatDate(endDate) <= this.state.mondayDate) {
            //       contractExpired = true;
            //     } else if (weekDay === 2 && Constants.formatDate(endDate) <= this.state.tuesdayDate) {
            //       contractExpired = true;
            //     } else if (weekDay === 3 && Constants.formatDate(endDate) <= this.state.wednesdayDate) {
            //       contractExpired = true;
            //     } else if (weekDay === 4 && Constants.formatDate(endDate) <= this.state.thursdayDate) {
            //       contractExpired = true;
            //     } else if (weekDay === 5 && Constants.formatDate(endDate) <= this.state.fridayDate) {
            //       contractExpired = true;
            //     } else if (weekDay === 6 && Constants.formatDate(endDate) <= this.state.saturdayDate) {
            //       contractExpired = true;
            //     }

            //   }
            // }
          }

          const userFullName = `${availableData[key].info_voornaam} ${(availableData[key].info_tussenvoegsel !== null && availableData[key].info_tussenvoegsel !== '') ? availableData[key].info_tussenvoegsel + " " + availableData[key].info_achternaam.charAt(0) : availableData[key].info_achternaam.charAt(0)}.`;

          availablePeople.push(
              {
                  name: userFullName,
                  value: availableData[key].info_id,
                  color: Colors.color.greenFilledIn,
                  start: availableData[key].beschikbaar_begin.substring(0,5),
                  end: availableData[key].beschikbaar_eind.substring(0,5),
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

      for(let key in notFilledData) {

          const userFullName = `${notFilledData[key].info_voornaam} ${(notFilledData[key].info_tussenvoegsel !== null && notFilledData[key].info_tussenvoegsel !== '') ? notFilledData[key].info_tussenvoegsel + " " + notFilledData[key].info_achternaam.charAt(0) : notFilledData[key].info_achternaam.charAt(0)}.`;

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
            
            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

          }

          availablePeople.push(
              {
                  name: userFullName,
                  value: notFilledData[key].info_id,
                  color: 'black',
                  start: null,
                  end: null,
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

      for(let key in unavailableData) {

        const userFullName = `${unavailableData[key].info_voornaam} ${(unavailableData[key].info_tussenvoegsel !== null && unavailableData[key].info_tussenvoegsel !== '') ? unavailableData[key].info_tussenvoegsel + " " + unavailableData[key].info_achternaam.charAt(0) : unavailableData[key].info_achternaam.charAt(0)}.`;

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

          if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        availablePeople.push(
            {
                name: userFullName,
                value: unavailableData[key].info_id,
                color: Colors.color.redFilledIn,
                start: unavailableData[key].onbeschikbaar_begin.substring(0,5),
                end: unavailableData[key].onbeschikbaar_eind.substring(0,5),
                inPlanner: false,
                contractExpired: contractExpired,
            }
        );

      }

    }

    if(Data.data.appOptions.availability === 'STANDARD') {

      for(let key in standardAvailableData) {

          // if(this.peopleInPlannerContains(this.availableData[key].info_voornaam + this.availableData[key].info_id, peopleInPlanner) === true) {
          //     continue;
          // }

          const userFullName = `${standardAvailableData[key].info_voornaam} ${(standardAvailableData[key].info_tussenvoegsel !== null && standardAvailableData[key].info_tussenvoegsel !== '') ? standardAvailableData[key].info_tussenvoegsel + " " + standardAvailableData[key].info_achternaam.charAt(0) : standardAvailableData[key].info_achternaam.charAt(0)}.`;

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
           
            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[standardAvailableData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

          }

          availablePeople.push(
              {
                  name: userFullName,
                  value: standardAvailableData[key].info_id,
                  color: Colors.color.greenFilledIn,
                  start: standardAvailableData[key].sb_begin.substring(0,5),
                  end: standardAvailableData[key].sb_eind.substring(0,5),
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

      for(let key in standardNotFilledData) {

          const userFullName = `${standardNotFilledData[key].info_voornaam} ${(standardNotFilledData[key].info_tussenvoegsel !== null && standardNotFilledData[key].info_tussenvoegsel !== '') ? standardNotFilledData[key].info_tussenvoegsel + " " + standardNotFilledData[key].info_achternaam.charAt(0) : standardNotFilledData[key].info_achternaam.charAt(0)}.`;

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
           
            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[standardNotFilledData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

          }

          availablePeople.push(
              {
                  name: userFullName,
                  value: standardNotFilledData[key].info_id,
                  color: 'black',
                  start: null,
                  end: null,
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

      for(let key in standardUnavailableData) {

        const userFullName = `${standardUnavailableData[key].info_voornaam} ${(standardUnavailableData[key].info_tussenvoegsel !== null && standardUnavailableData[key].info_tussenvoegsel !== '') ? standardUnavailableData[key].info_tussenvoegsel + " " + standardUnavailableData[key].info_achternaam.charAt(0) : standardUnavailableData[key].info_achternaam.charAt(0)}.`;

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        
          if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[standardUnavailableData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        availablePeople.push(
            {
                name: userFullName,
                value: standardUnavailableData[key].info_id,
                color: Colors.color.redFilledIn,
                start: standardUnavailableData[key].sb_begin.substring(0,5),
                end: standardUnavailableData[key].sb_eind.substring(0,5),
                inPlanner: false,
                contractExpired: contractExpired,
            }
        );

      }

    }

    if(Data.data.appOptions.availability === 'SCHOOL') {

      for(let key in notFilledSchoolData) {

          const userFullName = `${notFilledSchoolData[key].info_voornaam} ${(notFilledSchoolData[key].info_tussenvoegsel !== null && notFilledSchoolData[key].info_tussenvoegsel !== '') ? notFilledSchoolData[key].info_tussenvoegsel + " " + notFilledSchoolData[key].info_achternaam.charAt(0) : notFilledSchoolData[key].info_achternaam.charAt(0)}.`;

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
           
            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[notFilledSchoolData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

          }

          availablePeople.push(
              {
                  name: userFullName,
                  value: notFilledSchoolData[key].info_id,
                  color: Colors.color.greenFilledIn,
                  start: '00:01',
                  end: '00:01',
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

      for(let key in schoolHolidayData) {

            const userFullName = `${schoolHolidayData[key].info_voornaam} ${(schoolHolidayData[key].info_tussenvoegsel !== null && schoolHolidayData[key].info_tussenvoegsel !== '') ? schoolHolidayData[key].info_tussenvoegsel + " " + schoolHolidayData[key].info_achternaam.charAt(0) : schoolHolidayData[key].info_achternaam.charAt(0)}.`;
  
            let contractExpired = false;
            if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
              
              if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
                contractExpired = true;
              } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
                contractExpired = true;
              } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
                contractExpired = true;
              } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
                contractExpired = true;
              } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
                contractExpired = true;
              } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
                contractExpired = true;
              } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[schoolHolidayData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
                contractExpired = true;
              }

            }

              availablePeople.push(
                  {
                      name: userFullName,
                      value: schoolHolidayData[key].info_id,
                      color: Colors.color.greenFilledIn,
                      start: '00:02',
                      end: '00:02',
                      inPlanner: false,
                      contractExpired: contractExpired,
                  }
              );

      }

      for(let key in schoolscheduleData) {

          const userFullName = `${schoolscheduleData[key].info_voornaam} ${(schoolscheduleData[key].info_tussenvoegsel !== null && schoolscheduleData[key].info_tussenvoegsel !== '') ? schoolscheduleData[key].info_tussenvoegsel + " " + schoolscheduleData[key].info_achternaam.charAt(0) : schoolscheduleData[key].info_achternaam.charAt(0)}.`;

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
           
            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[schoolscheduleData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

          }

          availablePeople.push(
              {
                  name: userFullName,
                  value: schoolscheduleData[key].info_id,
                  color: 'black',
                  start: schoolscheduleData[key].schoolrooster_begin.substring(0,5),
                  end: schoolscheduleData[key].schoolrooster_eind.substring(0,5),
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

      for(let key in unavailableData) {

        const userFullName = `${unavailableData[key].info_voornaam} ${(unavailableData[key].info_tussenvoegsel !== null && unavailableData[key].info_tussenvoegsel !== '') ? unavailableData[key].info_tussenvoegsel + " " + unavailableData[key].info_achternaam.charAt(0) : unavailableData[key].info_achternaam.charAt(0)}.`;

        let contractExpired = false;
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
         
          if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
            contractExpired = true;
          } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
            contractExpired = true;
          }

        }

        availablePeople.push(
            {
                name: userFullName,
                value: unavailableData[key].info_id,
                color: Colors.color.redFilledIn,
                start: unavailableData[key].onbeschikbaar_begin.substring(0,5),
                end: unavailableData[key].onbeschikbaar_eind.substring(0,5),
                inPlanner: false,
                contractExpired: contractExpired,
            }
        );

      }

    }

    // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {

    //   for(let key in notFilledData) {

    //       const userFullName = `${notFilledData[key].info_voornaam} ${(notFilledData[key].info_tussenvoegsel !== null && notFilledData[key].info_tussenvoegsel !== '') ? notFilledData[key].info_tussenvoegsel + " " + notFilledData[key].info_achternaam.charAt(0) : notFilledData[key].info_achternaam.charAt(0)}.`;

    //       let contractExpired = false;
    //       if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
           
    //         if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
    //           contractExpired = true;
    //         } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
    //           contractExpired = true;
    //         } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
    //           contractExpired = true;
    //         } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
    //           contractExpired = true;
    //         } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
    //           contractExpired = true;
    //         } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
    //           contractExpired = true;
    //         } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[notFilledData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
    //           contractExpired = true;
    //         }

    //       }

    //       availablePeople.push(
    //           {
    //               name: userFullName,
    //               value: notFilledData[key].info_id,
    //               color: 'black',
    //               start: null,
    //               end: null,
    //               inPlanner: false,
    //               contractExpired: contractExpired,
    //           }
    //       );

    //   }

    //   for(let key in unavailableData) {

    //     const userFullName = `${unavailableData[key].info_voornaam} ${(unavailableData[key].info_tussenvoegsel !== null && unavailableData[key].info_tussenvoegsel !== '') ? unavailableData[key].info_tussenvoegsel + " " + unavailableData[key].info_achternaam.charAt(0) : unavailableData[key].info_achternaam.charAt(0)}.`;

    //     let contractExpired = false;
    //     if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
        
    //       if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
    //         contractExpired = true;
    //       } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
    //         contractExpired = true;
    //       } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
    //         contractExpired = true;
    //       } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
    //         contractExpired = true;
    //       } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
    //         contractExpired = true;
    //       } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
    //         contractExpired = true;
    //       } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[unavailableData[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
    //         contractExpired = true;
    //       }

    //     }

    //     availablePeople.push(
    //         {
    //             name: userFullName,
    //             value: unavailableData[key].info_id,
    //             color: Colors.color.redFilledIn,
    //             start: unavailableData[key].onbeschikbaar_begin.substring(0,5),
    //             end: unavailableData[key].onbeschikbaar_eind.substring(0,5),
    //             inPlanner: false,
    //             contractExpired: contractExpired,
    //         }
    //     );

    //   }

    // }

    if(Data.data.appOptions.availability === 'NONE') {

      for(let key in usersNoAvailability) {

          const userFullName = `${usersNoAvailability[key].info_voornaam} ${(usersNoAvailability[key].info_tussenvoegsel !== null && usersNoAvailability[key].info_tussenvoegsel !== '') ? usersNoAvailability[key].info_tussenvoegsel + " " + usersNoAvailability[key].info_achternaam.charAt(0) : usersNoAvailability[key].info_achternaam.charAt(0)}.`;

          let contractExpired = false;
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {
           
            if (weekDay === 0 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.mondayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 1 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.tuesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 2 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.wednesdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 3 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.thursdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 4 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.fridayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 5 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.saturdayDate)) === null) {
              contractExpired = true;
            } else if (weekDay === 6 && Constants.getCurrentContract(this.state.contractsObj[usersNoAvailability[key].info_id], Constants.stringToDate(this.state.sundayDate)) === null) {
              contractExpired = true;
            }

          }

          availablePeople.push(
              {
                  name: userFullName,
                  value: usersNoAvailability[key].info_id,
                  color: 'black',
                  start: null,
                  end: null,
                  inPlanner: false,
                  contractExpired: contractExpired,
              }
          );

      }

    }

    for(let key in this.users) {

        if(Constants.arrayContains(peopleInPlanner, this.users[key].info_voornaam + this.users[key].info_id) === true) {

            const userFullName = `${this.users[key].info_voornaam} ${(this.users[key].info_tussenvoegsel !== null && this.users[key].info_tussenvoegsel !== '') ? this.users[key].info_tussenvoegsel + " " + this.users[key].info_achternaam.charAt(0) : this.users[key].info_achternaam.charAt(0)}.`;

            availablePeople.push(
                {
                    name: userFullName,
                    value: this.users[key].info_id,
                    color: Colors.color.greyNotFilled,
                    start: null,
                    end: null,
                    inPlanner: true,
                }
            );

        }

    }

    if(editMode === true) {

        const shift = this.state.modalData;

        const userFullName = `${shift.info_voornaam} ${(shift.info_tussenvoegsel !== null && shift.info_tussenvoegsel !== '') ? shift.info_tussenvoegsel + " " + shift.info_achternaam.charAt(0) : shift.info_achternaam.charAt(0)}.`;

        availablePeople = [
            {
                name: userFullName,
                value: shift.info_id,
                color: 'black',
                start: null,
                end: null,
                inPlanner: false,
            },
            ...availablePeople,
        ]

    }

    return availablePeople;

  }

  deletePlannerAlert() {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.setState({
      alertTitle: 'Verwijderen',
      alertBody: (
        <div>

          Weet je zeker dat je de gehele planning van week <b>{this.state.week}, {this.state.year}</b> wilt verwijderen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Verwijderen'],
      alertButtonAction: this.deletePlanner.bind(this),
      showAlert: true,
    });

  }

  async deletePlanner() {

    this.setState({ loading: true });

    let promises = [];

    for (const key in this.plannerData) {
      
      if (this.plannerData[key].length > 0) {

        for (const plan of this.plannerData[key]) {

          promises.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
          promises.push(APIDeleteUserPlanner.Request(plan.rooster_id));

        }

      }

      if (this.emptyPlannerData[key].length > 0) {

        for (const plan of this.emptyPlannerData[key]) {

          promises.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
          promises.push(APIDeleteUserPlanner.Request(plan.rooster_id));

        }

      }

    }

    if (promises.length > 0) {

      Logger.deleteWholePlanner(`gehele planning week ${this.WEEK}, ${this.YEAR}`);

      await Promise.all(promises);
        
    }

    this.reloadScreen2FullScreen();

  }

  endRepeatingShiftAlert(data, date) {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.closeAllPlanMenus();

    this.setState({
      alertTitle: 'Herhalende dienst beëindigen',
      alertBody: (
        <div>

          Weet je zeker dat je deze herhalende dienst vanaf <b>{Constants.stringToDate(date).toLocaleDateString()}</b> wilt beëindigen?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Beëindigen'],
      alertButtonAction: this.endRepeatingShift.bind(this, data, date),
      showAlert: true,
    });

  }

  async endRepeatingShift(data, date) {

    const dateObj = Constants.stringToDate(date);
    const newDate = new Date(dateObj.setDate(dateObj.getDate() - 1));

    await APIEDIT.Request(`UPDATE HerRooster`, `SET hr_einddatum = '${Constants.dateToString(newDate)}'`, `WHERE hr_id = ${data.hr_id}`);

    this.reloadScreen2();

  }

  deleteUserPlannerAlert(data) {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.closeAllPlanMenus();

    if (data.rooster_publiceren === '3') { // is empty shift

      this.setState({
        alertTitle: 'Verwijderen',
        alertBody: (
          <div>

            Weet je zeker dat je deze open dienst wilt verwijderen?

          </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteUserPlannerDirectly.bind(this, data),
        showAlert: true,
      });

    } else {

      const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

      this.setState({
        alertTitle: 'Verwijderen',
        alertBody: (
          <div>

            Weet je zeker dat je de dienst van <b>{userFullName}</b> wilt verwijderen?

          </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteUserPlannerDirectly.bind(this, data),
        showAlert: true,
      });

    }

  }

  async deleteUserPlannerDirectly(data) {

    await APIDeleteUserPlanner.Request(data.rooster_id);
    await APIDeleteUsedDayShift.Request(data.rooster_id);
    await Logger.deletePlanner(data);

    if (data.rooster_publiceren === '1') {
      KodictNotifications.sendPushNotification(data.info_id, 'planner', 'Planner', 'Jouw rooster is gewijzigd. Tik hier om jouw roostermeldingen te updaten');
    }

    this.reloadScreenDirectly(data.rooster_datum);

  }

  publishPlanner() {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    if(this.state.toPublish > 0) {

      this.setState({
        alertTitle: 'Planner publiceren',
        alertBody: (
          <div>

            Weet je zeker dat je deze planning wilt publiceren?<br /><br />
            De planning zal voor alle medewerkers zichtbaar worden

          </div>
        ),
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Publiceren'],
        alertButtonAction: this.publish.bind(this),
        showAlert: true,
      });

    }

  }

  smartPlanAlert(options) {

    if(this.state.loading === true || this.state.reloading === true) {
      return;
    }

    let peopleHaveFunction = false;

    // MONDAY

    if (options.monday === true) {

      if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
        if (options.available === true) {
          for(const person of this.availableData.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
        if(peopleHaveFunction === false && options.notFilled === true) {
          for(const person of this.notFilledData.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }
      if(Data.data.appOptions.availability === 'STANDARD') {
        if (options.available === true) {
          for(const person of this.standardAvailableData.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
        if(peopleHaveFunction === false && options.notFilled === true) {
          for(const person of this.standardNotFilledData.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }
      if(Data.data.appOptions.availability === 'SCHOOL') {
        if (options.noSchool === true) {
          for(const person of this.notFilledSchool.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
        if (options.schoolHoliday === true) {
          for(const person of this.schoolHolidayData.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
        if (options.school === true) {
          for(const person of this.schoolScheduleData.mon) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }
      // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
      //   for(const person of this.notFilledData.mon) {
      //     if(person.functie_id !== null && person.functie_id !== undefined) {
      //       peopleHaveFunction = true;
      //       break;
      //     }
      //   }
      // }
      if(Data.data.appOptions.availability === 'NONE') {
        for(const person of this.usersNoAvailability.mon) {
          if(person.functie_id !== null && person.functie_id !== undefined) {
            peopleHaveFunction = true;
            break;
          }
        }
      }

    }

    // TUESDAY

    if (options.tuesday === true) {

      if(peopleHaveFunction === false) {
        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
          if (options.available === true) {
            for(const person of this.availableData.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.notFilledData.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'STANDARD') {
          if (options.available === true) {
            for(const person of this.standardAvailableData.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.standardNotFilledData.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
          if (options.noSchool === true) {
            for(const person of this.notFilledSchool.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.schoolHoliday === true) {
            for(const person of this.schoolHolidayData.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.school === true) {
            for(const person of this.schoolScheduleData.tue) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
        //   for(const person of this.notFilledData.tue) {
        //     if(person.functie_id !== null && person.functie_id !== undefined) {
        //       peopleHaveFunction = true;
        //       break;
        //     }
        //   }
        // }
        if(Data.data.appOptions.availability === 'NONE') {
          for(const person of this.usersNoAvailability.tue) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }

    }

    // WEDNESDAY

    if (options.wednesday === true) {

      if(peopleHaveFunction === false) {
        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
          if (options.available === true) {
            for(const person of this.availableData.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.notFilledData.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'STANDARD') {
          if (options.available === true) {
            for(const person of this.standardAvailableData.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.standardNotFilledData.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
          if (options.noSchool === true) {
            for(const person of this.notFilledSchool.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.schoolHoliday === true) {
            for(const person of this.schoolHolidayData.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.school === true) {
            for(const person of this.schoolScheduleData.wed) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
        //   for(const person of this.notFilledData.wed) {
        //     if(person.functie_id !== null && person.functie_id !== undefined) {
        //       peopleHaveFunction = true;
        //       break;
        //     }
        //   }
        // }
        if(Data.data.appOptions.availability === 'NONE') {
          for(const person of this.usersNoAvailability.wed) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }

    }

    // THURSDAY

    if (options.thurday === true) {

      if(peopleHaveFunction === false) {
        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
          if (options.available === true) {
            for(const person of this.availableData.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.notFilledData.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'STANDARD') {
          if (options.available === true) {
            for(const person of this.standardAvailableData.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.standardNotFilledData.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
          if (options.noSchool === true) {
            for(const person of this.notFilledSchool.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.schoolHoliday === true) {
            for(const person of this.schoolHolidayData.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.school === true) {
            for(const person of this.schoolScheduleData.thu) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
        //   for(const person of this.notFilledData.thu) {
        //     if(person.functie_id !== null && person.functie_id !== undefined) {
        //       peopleHaveFunction = true;
        //       break;
        //     }
        //   }
        // }
        if(Data.data.appOptions.availability === 'NONE') {
          for(const person of this.usersNoAvailability.thu) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }

    }

    // FRIDAY

    if (options.friday === true) {

      if(peopleHaveFunction === false) {
        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
          if (options.available === true) {
            for(const person of this.availableData.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.notFilledData.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'STANDARD') {
          if (options.available === true) {
            for(const person of this.standardAvailableData.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.standardNotFilledData.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
          if (options.noSchool === true) {
            for(const person of this.notFilledSchool.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.schoolHoliday === true) {
            for(const person of this.schoolHolidayData.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.school === true) {
            for(const person of this.schoolScheduleData.fri) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
        //   for(const person of this.notFilledData.fri) {
        //     if(person.functie_id !== null && person.functie_id !== undefined) {
        //       peopleHaveFunction = true;
        //       break;
        //     }
        //   }
        // }
        if(Data.data.appOptions.availability === 'NONE') {
          for(const person of this.usersNoAvailability.fri) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }

    }

    // SATURDAY

    if (options.saturday === true) {

      if(peopleHaveFunction === false) {
        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
          if (options.available === true) {
            for(const person of this.availableData.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.notFilledData.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'STANDARD') {
          if (options.available === true) {
            for(const person of this.standardAvailableData.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.standardNotFilledData.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
          if (options.noSchool === true) {
            for(const person of this.notFilledSchool.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.schoolHoliday === true) {
            for(const person of this.schoolHolidayData.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.school === true) {
            for(const person of this.schoolScheduleData.sat) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
        //   for(const person of this.notFilledData.sat) {
        //     if(person.functie_id !== null && person.functie_id !== undefined) {
        //       peopleHaveFunction = true;
        //       break;
        //     }
        //   }
        // }
        if(Data.data.appOptions.availability === 'NONE') {
          for(const person of this.usersNoAvailability.sat) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }

    }

    // SUNDAY

    if (options.sunday === true) {

      if(peopleHaveFunction === false) {
        if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
          if (options.available === true) {
            for(const person of this.availableData.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.notFilledData.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'STANDARD') {
          if (options.available === true) {
            for(const person of this.standardAvailableData.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if(peopleHaveFunction === false && options.notFilled === true) {
            for(const person of this.standardNotFilledData.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        if(Data.data.appOptions.availability === 'SCHOOL') {
          if (options.noSchool === true) {
            for(const person of this.notFilledSchool.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.schoolHoliday === true) {
            for(const person of this.schoolHolidayData.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
          if (options.school === true) {
            for(const person of this.schoolScheduleData.sun) {
              if(person.functie_id !== null && person.functie_id !== undefined) {
                peopleHaveFunction = true;
                break;
              }
            }
          }
        }
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
        //   for(const person of this.notFilledData.sun) {
        //     if(person.functie_id !== null && person.functie_id !== undefined) {
        //       peopleHaveFunction = true;
        //       break;
        //     }
        //   }
        // }
        if(Data.data.appOptions.availability === 'NONE') {
          for(const person of this.usersNoAvailability.sun) {
            if(person.functie_id !== null && person.functie_id !== undefined) {
              peopleHaveFunction = true;
              break;
            }
          }
        }
      }

    }

    if(peopleHaveFunction === false) {
      this.setState({
        alertTitle: 'Oeps!',
        alertBody: (
          <div>

            Er zijn geen medewerkers beschikbaar die een standaard functie hebben toegewezen.<br /><br />

          </div>
        ),
        alertButtons: 1,
        alertButtonText: ['OK'],
        alertButtonAction: null,
        showAlert: true,
      });
      return;
    }

    this.setState({
      alertTitle: 'Smart Plan',
      alertBody: (
        <div>

          Wil je de Smart Plan optie nu gebruiken?<br /><br />
          De Smart Plan optie roostert automatisch jouw medewerkers in op basis van jouw standaard diensten, de beschikbaarheid van jouw medewerkers en de standaard functies van deze medewerkers

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Smart Plan'],
      alertButtonAction: this.smartPlan.bind(this, options),
      showAlert: true,
    });

  }

  async publish() {

    this.setState({loading: true});

    let peopleToNotify = [];

    const unpublished = await APIGetAllUnpublished.Request();

    if(unpublished !== null) {

      for(const user of unpublished) {

        if(Constants.arrayContains(peopleToNotify, user.rooster_info_id) === false) {
          peopleToNotify.push(user.rooster_info_id);
        }

      }

      for(let key in peopleToNotify) {

        KodictNotifications.sendPushNotification(
          peopleToNotify[key],
          'planner',
          'Planner',
          'Jouw nieuwe rooster is geüpload. Tik hier om jouw roostermeldingen te updaten'
        );

      }

      peopleToNotify = [];

    }

    const published = await APIPublishPlanner.Request();

    if(published === true) {

        this.reloadScreen2FullScreen();

    } else {

      this.setState({loading: false});

    }

}

  sortFunctions() {
    this.setState({sortFunctions: !this.state.sortFunctions });
    this.closeSettings();
  }

  changeSectionTab(e) {

    let section = null

    if (e.target.value !== 'null') {
      section = parseInt(e.target.value);
    }

    if(this.state.section === section) {
      return;
    }

    this.setState({
      section: section,
    }, async() => { 
      await this.getFunctions();
      await this.reloadScreen2FullScreen();
    });

  }

  async changeDisplay(e) {

    this.setState({ display: e.target.value });
    localStorage.setItem('@plannerDisplay', e.target.value); 

    await this.reloadScreen2FullScreen();

  }

  getSectionOptions() {

    let sections = [];

    for(const section of this.sectionsData) {

      sections.push(
        <option value={section.afdeling_id} selected={this.state.section === parseInt(section.afdeling_id)}>{section.afdeling_naam}</option>
      );

    }

    return sections;

  }

  getSectionTabs() {

    let bars = [];

    bars.push(

      <div className='planner_sectionTab' style={{flex: 0.3, color: '#282828', cursor: 'default'}}>

        Afdeling:

      </div>

    );

    if(this.state.section === null) {

      bars.push(

        <div className='planner_sectionTab' style={{color: '#47B7EB'}}>

          Alle

        </div>

      );

    } else {

      bars.push(

        <div className='planner_sectionTab' onClick={() => this.changeSectionTab(null)}>

          Alle

        </div>

      );

    }

    for(const tab of this.sectionsData) {

      if(this.state.section === parseInt(tab.afdeling_id)) {

        bars.push(

          <div className='planner_sectionTab' style={{color: '#47B7EB'}}>
  
            {tab.afdeling_naam}
  
          </div>
  
        );

      } else {

        bars.push(

          <div className='planner_sectionTab' onClick={() => this.changeSectionTab(parseInt(tab.afdeling_id))}>
  
            {tab.afdeling_naam}
  
          </div>
  
        );

      }

    }

    return bars;

  }

  openModeMenu() {

    this.setState({
      showModeMenu: !this.state.showModeMenu,
    });

  }

  openTempMenu() {

    this.setState({
      showTempMenu: !this.state.showTempMenu,
    });

  }

  openSortMenu() {

    this.setState({
      showSortMenu: !this.state.showSortMenu,
    });

  }

  showPublishModal() {

    this.setState({
      modalData: null,
      modalMode: 0,
      showPublishModal: true,
      reloadScreenMode: 1, //fullscreen //2
    });
  }

  showAvailableShiftsModal() {

    this.closeSettings();

    this.setState({
      modalData: null,
      modalMode: 0,
      showAvailableShiftsModal: true,
      reloadScreenMode: 1, //fullscreen //2
    });

  }

  findAvailabilityUserTemplate(day, id) {

    let key = day === 0 ? 'sun' : day === 1 ? 'mon' : day === 2 ? 'tue' : day === 3 ? 'wed' : day === 4 ? 'thu' : day === 5 ? 'fri' : day === 6 ? 'sat' : 'mon';

    for (const user of this.availableData[key]) {
      if (user.info_id === id) {
        return user;
      }
    }

    for (const user of this.notFilledData[key]) {
      if (user.info_id === id) {
        return user;
      }
    }

    for (const user of this.unavailableData[key]) {
      if (user.info_id === id) {
        return user;
      }
    }

    for (const user of this.standardAvailableData[key]) {
      if (user.info_id === id) {
        return user;
      }
    }

    for (const user of this.standardUnavailableData[key]) {
      if (user.info_id === id) {
        return user;
      }
    }

    return null;

  }

  async saveShiftTemplate(shiftTemp) {

    for (const plan of this.plannerData.mon) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 1, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.tue) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 2, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.wed) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 3, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.thu) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 4, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.fri) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 5, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.sat) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 6, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }
    for (const plan of this.plannerData.sun) {
      await APIADD.Request(`INSERT INTO DagDienst`, `VALUES (NULL, 0, '${plan.rooster_begin}', '${plan.rooster_eind}', ${plan.functie_id}, '${plan.rooster_pauze}', ${shiftTemp})`);
    }

    return true;

  }

  async saveTemplate(key, name) {

    // user has a team
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      name = name + ` - ${Data.data.chosenTeamObj.team_naam}`;
    }

    for (const plan of this.plannerData.mon) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }

      //const added = await APIAddTemplate.Request(key, name, 1, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 1, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      //const addedID = await APIGET.Request(`SELECT sjabloon_id`, `FROM Sjabloon`, null, null, null, `ORDER BY sjabloon_id DESC LIMIT 1`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }
    for (const plan of this.plannerData.tue) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }
      //const added = await APIAddTemplate.Request(key, name, 2, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 2, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }
    for (const plan of this.plannerData.wed) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }
      // const added = await APIAddTemplate.Request(key, name, 3, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 3, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }
    for (const plan of this.plannerData.thu) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }
      // const added = await APIAddTemplate.Request(key, name, 4, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 4, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }
    for (const plan of this.plannerData.fri) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }
      // const added = await APIAddTemplate.Request(key, name, 5, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 5, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }
    for (const plan of this.plannerData.sat) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }
      // const added = await APIAddTemplate.Request(key, name, 6, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 6, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }
    for (const plan of this.plannerData.sun) {
      if (plan.rooster_publiceren === '3') { // is extra shift
        continue;
      }
      // const added = await APIAddTemplate.Request(key, name, 0, plan.rooster_begin, plan.rooster_eind, plan.rooster_pauze, plan.functie_id, plan.info_id, '00:00:00', '00:00:00');
      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', 0, '${plan.rooster_begin}', '${plan.rooster_eind}', '${plan.rooster_pauze}', ${plan.functie_id}, ${plan.info_id}, NULL, ${plan.rooster_id})`);
      const addedID = await APIGET.Request(`SELECT sjabloonm_id`, `FROM SjabloonModern`, null, null, null, `ORDER BY sjabloonm_id DESC LIMIT 1`);

      if (added !== true) {
        return false;
      }

      // save comp to sjabloon
      if (typeof this.state.skillSchedules[plan.rooster_id] !== 'undefined') {
        for (const skill of this.state.skillSchedules[plan.rooster_id]) {
          await APIADD.Request(`INSERT INTO SjabloonComp`, `VALUES (NULL, ${skill.comp_id}, ${addedID[0].sjabloonm_id})`);
        }
      }

      // save bijz to sjabloon
      if (typeof this.state.partSchedules[plan.rooster_id] !== 'undefined') {
        const bijz = this.state.partSchedules[plan.rooster_id];
        await APIADD.Request(`INSERT INTO SjabloonBijz`, `VALUES (NULL, ${bijz.bijz_id}, ${addedID[0].sjabloonm_id})`);
      }

    }

    for (const shift of this.usedDayShiftsModern) {

      const date = Constants.stringToDate(shift.gdm_datum);

      const added = await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', ${date.getDay()}, '00:00:00', '00:00:00', '00:00:00', NULL, NULL, ${shift.gdm_dagdienst_id}, ${shift.gdm_rooster_id})`);

      if (added !== true) {
        return false;
      }
    }

    // get old used day shifts LEGACY so people can copy their old templates
    // let allDayShiftData = {
    //   mon: [],
    //   tue: [],
    //   wed: [],
    //   thu: [],
    //   fri: [],
    //   sat: [],
    //   sun: [],
    // };
    // allDayShiftData.mon = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 1 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // allDayShiftData.tue = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 2 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // allDayShiftData.wed = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 3 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // allDayShiftData.thu = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 4 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // allDayShiftData.fri = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 5 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // allDayShiftData.sat = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 6 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // allDayShiftData.sun = await APIGET.Request(`SELECT *`, `FROM DagDienst`, null, `WHERE dagdienst_dag = 0 AND dagdienst_sjabloon = ${this.state.shiftTemp}`, null, null);
    // for (const shift of this.usedDayShifts) {
      
    //   let dayShiftData = null;
    //   let planData = null;
    //   const shiftDate = Constants.stringToDate(shift.gebruiktedagdienst_datum);

    //   if (shiftDate.getDay() === 1) {
    //     dayShiftData = allDayShiftData.mon;
    //     planData = this.plannerData.mon;
    //   } else if (shiftDate.getDay() === 2) {
    //     dayShiftData = allDayShiftData.tue;
    //     planData = this.plannerData.tue;
    //   } else if (shiftDate.getDay() === 3) {
    //     dayShiftData = allDayShiftData.wed;
    //     planData = this.plannerData.wed;
    //   } else if (shiftDate.getDay() === 4) {
    //     dayShiftData = allDayShiftData.thu;
    //     planData = this.plannerData.thu;
    //   } else if (shiftDate.getDay() === 5) {
    //     dayShiftData = allDayShiftData.fri;
    //     planData = this.plannerData.fri;
    //   } else if (shiftDate.getDay() === 6) {
    //     dayShiftData = allDayShiftData.sat;
    //     planData = this.plannerData.sat;
    //   } else if (shiftDate.getDay() === 0) {
    //     dayShiftData = allDayShiftData.sun;
    //     planData = this.plannerData.sun;
    //   }

    //   for (const ds of dayShiftData) {
    //     if (ds.dagdienst_begin === shift.gebruiktedagdienst_begin && ds.dagdienst_eind === shift.gebruiktedagdienst_eind && ds.dagdienst_functie_id === shift.gebruiktedagdienst_functie_id) {
    //       for (const pd of planData) {
    //         if (pd.rooster_id === shift.gebruiktedagdienst_rooster_id) {

    //           await APIADD.Request(`INSERT INTO SjabloonModern`, `VALUES (NULL, ${key}, '${name}', ${shiftDate.getDay()}, '00:00:00', '00:00:00', '00:00:00', NULL, NULL, ${ds.dagdienst_id}, ${pd.rooster_id})`);

    //         }
    //       }
    //     }
    //   }

    // }

    return true;

  }

  async deleteTemplate(key) {

    //const deleted = await APIDeleteTemplate.Request(key);

    const deleted = await APIDELETE.Request(`DELETE FROM SjabloonModern`, `WHERE sjabloonm_key = ${key}`);

    await this.reloadScreenAddTemp();

    return deleted;

  }

  async applyTemplate(key) {

    this.setState({loading: true});

    //const templates = await APIGetFromTemplate.Request(key);
    const templates = await APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, `WHERE sjabloonm_key = ${key}`, null, null);
    const tempSkills = await APIGET.Request(`SELECT *`, `FROM SjabloonComp`, null, null, null, null);
    let tempSkillsObj = {};

    for (const tempSkill of tempSkills) {
      if (typeof tempSkillsObj[tempSkill.sjc_sjabloon_id] === 'undefined') {
        tempSkillsObj[tempSkill.sjc_sjabloon_id] = [];
      }
      tempSkillsObj[tempSkill.sjc_sjabloon_id].push(tempSkill);
    }

    const tempBijz = await APIGET.Request(`SELECT *`, `FROM SjabloonBijz`, null, null, null, null);
    const tempBijzObj = Constants.convertToObject(tempBijz, 'sjb_sjabloon_id');

    if (templates.length === 0) {
      this.setState({loading: false});
      return;
    }

    // check if the template fits the team
    let teamIDTemplate = null;
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

      for (const temp of templates) {

        // is person
        if (Constants.isEmpty(temp.sjabloonm_info_id) === false) {

          const teamInfo = await APIGET.Request(`SELECT *`, `FROM TeamRooster`, null, `WHERE teamr_rooster_id = ${temp.sjabloonm_rooster_id}`, null, null);

          if (teamInfo.length > 0) {
            teamIDTemplate = teamInfo[0].teamr_team_id;
            break;
          }

        }

      }

    }

    for (const key in this.plannerData) {
        for (const plan of this.plannerData[key]) {
            for (const temp of templates) {
                if (temp.sjabloonm_rooster_id === plan.rooster_id) {
                    this.setState({loading: false}, () => {
                        this.setState({
                          alertTitle: 'Fout',
                          alertBody: (
                            <div>
                              Je kunt een opgeslagen planning niet in dezelfde week toepassen
                            </div>
                          ),
                          alertButtons: 1,
                          alertButtonText: ['OK'],
                          alertButtonAction: null,
                          showAlert: true,
                        });
                    });
                    return;
                }
            }
        }
    }

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null && (teamIDTemplate === null || teamIDTemplate !== Data.data.chosenTeamObj.team_id)) {
      this.setState({loading: false}, () => {
        this.setState({
          alertTitle: 'Probleem met sjabloon',
          alertBody: (
            <div>
              Er lijkt een probleem te zijn met deze sjabloon. Het is voor vestiging {Data.data.chosenTeamObj.team_naam} onveilig om deze sjabloon toe te passen
            </div>
          ),
          alertButtons: 1,
          alertButtonText: ['OK'],
          alertButtonAction: null,
          showAlert: true,
        });
      });
      return;
    }

    let deletes = [];

    for (const key in this.plannerData) {
      
      if (this.plannerData[key].length > 0) {

        for (const plan of this.plannerData[key]) {

          deletes.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
          deletes.push(APIDeleteUserPlanner.Request(plan.rooster_id));

        }

      }

    }

    await Promise.all(deletes);

    await APIDELETE.Request(`DELETE FROM Rooster`, `WHERE (rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') AND rooster_info_id IS NULL`);

    await this.reloadScreen2FullScreen();

    this.setState({loading: true});

    let templateUsedDayShifts = [];

    // vaste diensten
    for (const temp of templates) {

      // is used default shift
      if (Constants.isEmpty(temp.sjabloonm_info_id) === true) {

        // add to array
        templateUsedDayShifts.push(temp);

      }
      
    }

    // diensten
    for (const temp of templates) {

      // is person
      if (Constants.isEmpty(temp.sjabloonm_info_id) === false) {

        const day = parseInt(temp.sjabloonm_dag);

        let dateString = '';
        let wdKey = 'info2_werkdag_maandag';

        if (day === 0) {
          dateString = this.state.sundayDate;
          wdKey = 'info2_werkdag_zondag';
        } else if (day === 1) {
          dateString = this.state.mondayDate;
          wdKey = 'info2_werkdag_maandag';
        } else if (day === 2) {
          dateString = this.state.tuesdayDate;
          wdKey = 'info2_werkdag_dinsdag';
        } else if (day === 3) {
          dateString = this.state.wednesdayDate;
          wdKey = 'info2_werkdag_woensdag';
        } else if (day === 4) {
          dateString = this.state.thursdayDate;
          wdKey = 'info2_werkdag_donderdag';
        } else if (day === 5) {
          dateString = this.state.fridayDate;
          wdKey = 'info2_werkdag_vrijdag';
        } else if (day === 6) {
          dateString = this.state.saturdayDate;
          wdKey = 'info2_werkdag_zaterdag';
        }

        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
          if (Constants.getUserIsInTeam(this.teamUsers, temp.sjabloonm_info_id, Data.data.chosenTeamObj.team_id) === false) {
            continue;
          }
        }

        // create toPlan object
        const toPlanObject = {
          rooster_begin: temp.sjabloonm_begin.substring(0, 5),
          rooster_eind: temp.sjabloonm_eind.substring(0, 5),
          rooster_pauze: temp.sjabloonm_pauze.substring(0, 5),
          functie_id: parseInt(temp.sjabloonm_functie_id),
        };

        let fromPlanObject = this.findAvailabilityUserTemplate(day, temp.sjabloonm_info_id);

        if (fromPlanObject === null) {
          fromPlanObject = {info_id: temp.sjabloonm_info_id};
        }

        let added = null;
        let skipRest = false;

        let skipForHoliday = false;
        for (const holiday of this.holidayDays) {
          if (temp.sjabloonm_info_id === holiday.userID && dateString === holiday.dateString) {
            skipForHoliday = true;
            break;
          }
        }
        if (skipForHoliday === true && added === null) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          added = parseInt(rid[0].rooster_id);
          skipRest = true;
          //continue;
        }

        if (Constants.isEmpty(this.state.infoExtra[temp.sjabloonm_info_id]) === true) { // deleted user?
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          added = parseInt(rid[0].rooster_id);
          skipRest = true;
        }

        if (Constants.isEmpty(this.state.infoExtra[temp.sjabloonm_info_id]) === false && this.state.infoExtra[temp.sjabloonm_info_id][wdKey] === '0' && added === null) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          added = parseInt(rid[0].rooster_id);
          skipRest = true;
          //continue;
        }

        if (Constants.isEmpty(this.state.infoExtra[temp.sjabloonm_info_id]) === false && Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[temp.sjabloonm_info_id], temp.sjabloonm_info_id, dateString, toPlanObject, this.allPlannerData, this.state.planUsersMaxCumulative) === false && added === null) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          added = parseInt(rid[0].rooster_id);
          skipRest = true;
          //continue;
        }

        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen) && added === null) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[temp.sjabloonm_info_id], Constants.stringToDate(dateString));
          if (contract === null) {
            await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
            const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
            added = parseInt(rid[0].rooster_id);
            skipRest = true;
          }

          // // has contract
          // if (typeof this.state.contractsObj[temp.sjabloonm_info_id] !== 'undefined') {
          //   const contract = this.state.contractsObj[temp.sjabloonm_info_id];
          //   // has valid end date
          //   if (Constants.isValidDateString(contract.contract_eind)) {
          //     const endDate = Constants.stringToDate(contract.contract_eind);
  
          //     if (Constants.formatDate(endDate) <= dateString) {
          //       await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin}', '${toPlanObject.rooster_eind}', NULL, ${toPlanObject.functie_id}, 3, '${toPlanObject.rooster_pauze}', 0)`);
          //       const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          //       added = parseInt(rid[0].rooster_id);
          //       skipRest = true;
          //       //continue;
          //     } 

          //   }
          // }
        }

        if (skipRest === true || skipForHoliday === true) {

          // successfully added to planner
          if (added !== null) {

            if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
              await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added);
            }

            // add comps from temp to shift
            if (typeof tempSkillsObj[temp.sjabloonm_id] !== 'undefined') {
              for (const skill of tempSkillsObj[temp.sjabloonm_id]) {
                await APIAddSkillSchedule.Request(skill.sjc_comp_id, added);
              }
            }

            // add bij from temp to shift
            if (typeof tempBijzObj[temp.sjabloonm_id] !== 'undefined') {
                await APIAddPartSchedule.Request(tempBijzObj[temp.sjabloonm_id].sjb_bijz_id, added);
            }

            // iterate through sjabloon used day shifts
            for (const temp2 of templateUsedDayShifts) {
              if (temp.sjabloonm_rooster_id === temp2.sjabloonm_rooster_id) {
                await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${dateString}', ${temp2.sjabloonm_dagdienst_id}, ${added})`);
                Constants.removeFromArray(templateUsedDayShifts, temp2); //remove from arrayfor faster iterate
              }
            }

            // reload cumulatives
            let cumulatives = this.state.planUsersMaxCumulative;
            let userCumulative = cumulatives[temp.sjabloonm_info_id];
            if (typeof userCumulative === 'undefined') {
              userCumulative = [];
              userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
            } else {
              userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
            }
            this.setState({ planUsersMaxCumulative: cumulatives });

            // for (const temp2 of templateUsedDayShifts) {

            //   const day2 = parseInt(temp2.sjabloonm_dag);

            //   // if used day shift
            //   if(day2 === day && temp.sjabloon_begin === temp2.sjabloon_eBegin && temp.sjabloon_eind === temp2.sjabloon_eEind && temp.sjabloon_functie_id === temp2.sjabloon_functie_id) {
            //     await APIAddUsedDayShift.Request(dateString, temp2.sjabloon_begin, temp2.sjabloon_eind, added, temp.sjabloon_functie_id);
            //     await this.removeAllOpenShiftsAvailable();
            //     Constants.removeFromArray(templateUsedDayShifts, temp2); //remove from arrayfor faster iterate
            //   }

            // }

          }

          continue;

        }

        // add to planner, added returns rooster_id
        if (added === null) {
          added = await this.planUser(dateString, fromPlanObject, toPlanObject);
        }

        // successfully added to planner
        if (added !== null) {

          // add comps from temp to shift
          if (typeof tempSkillsObj[temp.sjabloonm_id] !== 'undefined') {
            for (const skill of tempSkillsObj[temp.sjabloonm_id]) {
              await APIAddSkillSchedule.Request(skill.sjc_comp_id, added);
            }
          }

          // add bij from temp to shift
          if (typeof tempBijzObj[temp.sjabloonm_id] !== 'undefined') {
              await APIAddPartSchedule.Request(tempBijzObj[temp.sjabloonm_id].sjb_bijz_id, added);
          }

          // iterate through sjabloon used day shifts
          for (const temp2 of templateUsedDayShifts) {
            if (temp.sjabloonm_rooster_id === temp2.sjabloonm_rooster_id) {
              await APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${dateString}', ${temp2.sjabloonm_dagdienst_id}, ${added})`);
              Constants.removeFromArray(templateUsedDayShifts, temp2); //remove from arrayfor faster iterate
            }
          }

          // reload cumulatives
          let cumulatives = this.state.planUsersMaxCumulative;
          let userCumulative = cumulatives[temp.sjabloonm_info_id];
          if (typeof userCumulative === 'undefined') {
            userCumulative = [];
            userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
          } else {
            userCumulative.push({rooster_datum: dateString, info_id: temp.sjabloonm_info_id});
          }
          this.setState({ planUsersMaxCumulative: cumulatives });

          // for (const temp2 of templateUsedDayShifts) {

          //   const day2 = parseInt(temp2.sjabloonm_dag);

          //   // if used day shift
          //   if(day2 === day && temp.sjabloon_begin === temp2.sjabloon_eBegin && temp.sjabloon_eind === temp2.sjabloon_eEind && temp.sjabloon_functie_id === temp2.sjabloon_functie_id) {
          //     await APIAddUsedDayShift.Request(dateString, temp2.sjabloon_begin, temp2.sjabloon_eind, added, temp.sjabloon_functie_id);
          //     await this.removeAllOpenShiftsAvailable();
          //     Constants.removeFromArray(templateUsedDayShifts, temp2); //remove from arrayfor faster iterate
          //   }

          // }

        }

      }
      
    }

    await this.reloadScreen2FullScreen();

    this.setState({loading: false});

  }

  deleteTempAlert(key, name) {

        this.setState({
            alertTitle: 'Planning sjabloon verwijderen',
            alertBody: (
              <div>
	
                Weet je zeker dat je het sjabloon <b>{name}</b> wilt verwijderen?
	
              </div>
            ),
            alertButtons: 2,
            alertButtonText: ['Annuleren', 'Verwijderen'],
            alertButtonAction: this.deleteTemplate.bind(this, key),
            showAlert: true,
          });

      }

      applyTempAlert(key, name) {

        this.setState({
            alertTitle: 'Standaard planning toepassen',
            alertBody: (
              <div>
	
                Weet je zeker dat je het sjabloon <b>{name}</b> wilt toepassen op week <b>{this.state.week}, {this.state.year}</b>?
	
              </div>
            ),
            alertButtons: 2,
            alertButtonText: ['Annuleren', 'Toepassen'],
            alertButtonAction: this.applyTemplate.bind(this, key),
            showAlert: true,
          });

      }

  getPlanningTemplates() {

    let toReturn = [];

    for (const temp of this.state.plannerTemplates) {

      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

        if (temp.sjabloonm_naam.includes(Data.data.chosenTeamObj.team_naam)) {

          toReturn.push(
            <div key={temp.sjabloonm_id} className='planner_settingsMenu_row_delete settings_Asset2'>
              <div className='planner_settingsMenu_row_delete_sub1 settings_Asset2' onClick={() => this.deleteTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
                <i className='fas fa-minus-circle settings_Asset2' />
              </div>
              <div className='planner_settingsMenu_row_delete_sub2 settings_Asset2' onClick={() => this.applyTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
                <span>{temp.sjabloonm_naam}</span>
              </div>
              <div className='planner_settingsMenu_row_delete_sub3 settings_Asset2' onClick={() => this.applyTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
                <i className='fal fa-arrow-right settings_Asset2' />
              </div>
            </div>
          );

        }

      } else {

        toReturn.push(
          <div key={temp.sjabloonm_id} className='planner_settingsMenu_row_delete settings_Asset2'>
            <div className='planner_settingsMenu_row_delete_sub1 settings_Asset2' onClick={() => this.deleteTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
              <i className='fas fa-minus-circle settings_Asset2' />
            </div>
            <div className='planner_settingsMenu_row_delete_sub2 settings_Asset2' onClick={() => this.applyTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
              <span>{temp.sjabloonm_naam}</span>
            </div>
            <div className='planner_settingsMenu_row_delete_sub3 settings_Asset2' onClick={() => this.applyTempAlert(temp.sjabloonm_key, temp.sjabloonm_naam)}>
              <i className='fal fa-arrow-right settings_Asset2' />
            </div>
          </div>
        );

      }

    }

    return toReturn;

  }

  async reloadScreenAddTemp() {

        this.setState({reloading: true});
	
    // const templates = await APIGetAllTemplates.Request();
    const templates = await APIGET.Request(`SELECT *`, `FROM SjabloonModern`, null, null, `GROUP BY sjabloonm_key`, null);
    const shiftTemplates = await APIGetDayShiftTemplates.Request();
    let newShiftTemplates = [];

    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const temp of shiftTemplates) {
        if (temp.dagdienstsjabloon_team_id === Data.data.chosenTeamObj.team_id) {
          newShiftTemplates.push(temp);
        }
      }
    } else {
      newShiftTemplates = shiftTemplates;
    }
	
        this.setState({reloading: false, plannerTemplates: templates, shiftTemplates: newShiftTemplates, });
	
  }
  
  async removeAllOpenShiftsAvailable() {

    await APIDELETE.Request(`DELETE FROM ShiftBeschikbaar`, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`);

    // GET PLANNER AVAILABLESHIFTS
    let plannerAvailableShifts = [];
    if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}' AND shb_team_id = ${Data.data.chosenTeamObj.team_id}`, null, null);
    } else {
      plannerAvailableShifts = await APIGET.Request(`SELECT shb_dagdienst_id`, `FROM ShiftBeschikbaar`, null, `WHERE shb_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}'`, null, null);
    }

    this.setState({plannerAvailableShifts: plannerAvailableShifts});

  }

  openAddTempModal() {

        this.setState({
          showAddTempModal: true, 
          modalMode: 0,
          modalData: [],
        });
	
  }

  closeAddTempModal() {

    this.setState({ 
      showAddTempModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  planUsersDefaultWorkdaysAlert() {

    this.setState({
      alertTitle: 'Vaste werktijden',
      alertBody: (
        <div>

          <b>Medewerkers met vaste werktijden inplannen</b><br/><br/>
    
          De medewerkers zullen worden ingepland aan de hand van hun vaste werktijden. Hun huidige diensten van deze week worden dan vervangen. Wil je doorgaan?
    
        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Inplannen'],
      alertButtonAction: this.planUsersDefaultWorkdays.bind(this),
      showAlert: true,
    });

  }

  async planUsersDefaultWorkdays() {

    this.setState({ loading: true });

    const userDefaultWorkdays = this.state.userDefaultWorkdays;

    // DEL
    let promisesDelete = [];
    for (const workday of userDefaultWorkdays) {
      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      promisesDelete.push(APIDELETE.Request(`DELETE FROM Rooster`, `WHERE rooster_datum = '${dateString}' AND rooster_info_id = ${workday.vd_info_id}`));
    }
    await Promise.all(promisesDelete);

    // DEFAULT DAYSHIFTS
    let _newDayShifts = { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] };
    for (const key in this.dayShiftsData) {
      for (const shift of this.dayShiftsData[key]) {
        _newDayShifts[key].push(shift);
      }
    }

    // PLAN

    let promisesDayShifts = [];

    loop1:
    for (const workday of userDefaultWorkdays) {

      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      const dayKey = workday.vd_dag === '0' ? 'sun' : workday.vd_dag === '1' ? 'mon' : workday.vd_dag === '2' ? 'tue' : workday.vd_dag === '3' ? 'wed' : workday.vd_dag === '4' ? 'thu' : workday.vd_dag === '5' ? 'fri' : workday.vd_dag === '6' ? 'sat' : 'mon';

      const toPlan = {
        rooster_datum: dateString,
        rooster_begin: workday.vd_begin,
        rooster_eind: workday.vd_eind,
        rooster_pauze: Constants.isEmpty(workday.vd_pauze) === false ? workday.vd_pauze : '00:00:00',
        functie_id: workday.vd_functie_id,
        info_id: workday.vd_info_id,
      };

      let _break = `'00:00:00'`;
      if (Constants.isEmpty(workday.vd_pauze) === false) {
        _break = `'${workday.vd_pauze.substr(0,5)}'`;
      }

      for (const holiday of this.holidayDays) {
        if (workday.vd_info_id === holiday.userID && dateString === holiday.dateString) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          const added2 = parseInt(rid[0].rooster_id);
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
          }
          continue loop1;
        }
      }

      // if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[workday.vd_info_id], workday.vd_info_id, dateString, toPlan, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
      //   continue;
      // }

      if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

        const contract = Constants.getCurrentContract(this.state.contractsObj[workday.vd_info_id], Constants.stringToDate(dateString));
        if (contract === null) {
          await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
          const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
          const added2 = parseInt(rid[0].rooster_id);
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
          }
          continue;
        }

      }

      const added = await this.planUser(dateString, { info_id: workday.vd_info_id }, toPlan);

      for (const shift of _newDayShifts[dayKey]) {
        if (shift.dagdienst_dag === workday.vd_dag && shift.dagdienst_begin.substr(0,5) === workday.vd_begin.substr(0,5) && shift.dagdienst_eind.substr(0,5) === workday.vd_eind.substr(0,5) && shift.dagdienst_pauze.substr(0,5) === workday.vd_pauze.substr(0,5) && shift.functie_id === workday.vd_functie_id) {
          promisesDayShifts.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${dateString}', ${shift.dagdienst_id}, ${added})`));
          Constants.removeFromArray2(_newDayShifts[dayKey], 'dagdienst_id', shift.dagdienst_id);
          break;
        }
      }

    }

    await Promise.all(promisesDayShifts);

    await this.reloadScreen2();

    this.setState({ loading: false });

  }

  async planUsersDefaultWorkdays_smartPlan(personList, shiftList, date, options) {

    const userDefaultWorkdays = this.state.userDefaultWorkdays;

    // DEL
    let promisesDelete = [];
    for (const workday of userDefaultWorkdays) {

      if (Constants.isEmpty(options.function) === false) {
        if (workday.vd_functie_id !== options.function) {
          continue;
        }
      }
      if (Constants.isEmpty(options.section) === false) {
        if (workday.functie_afdeling_id !== options.section) {
          continue;
        }
      }

      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;
      if (dateString === date) {
        promisesDelete.push(APIDELETE.Request(`DELETE FROM Rooster`, `WHERE rooster_datum = '${date}' AND rooster_info_id = ${workday.vd_info_id}`));
        Constants.removeFromArray2(personList, 'info_id', workday.vd_info_id);
      }
    }
    await Promise.all(promisesDelete);

    // PLAN

    let promisesDayShifts = [];

    loop1:
    for (let workday of userDefaultWorkdays) {

      if (Constants.isEmpty(options.function) === false) {
        if (workday.vd_functie_id !== options.function) {
          continue;
        }
      }
      if (Constants.isEmpty(options.section) === false) {
        if (workday.functie_afdeling_id !== options.section) {
          continue;
        }
      }

      const dateString = workday.vd_dag === '0' ? this.state.sundayDate : workday.vd_dag === '1' ? this.state.mondayDate : workday.vd_dag === '2' ? this.state.tuesdayDate : workday.vd_dag === '3' ? this.state.wednesdayDate : workday.vd_dag === '4' ? this.state.thursdayDate : workday.vd_dag === '5' ? this.state.fridayDate : workday.vd_dag === '6' ? this.state.saturdayDate : this.state.mondayDate;

      if (dateString === date) {

        const toPlan = {
          rooster_datum: date,
          rooster_begin: workday.vd_begin,
          rooster_eind: workday.vd_eind,
          rooster_pauze: Constants.isEmpty(workday.vd_pauze) === false ? workday.vd_pauze : '00:00:00',
          functie_id: workday.vd_functie_id,
          info_id: workday.vd_info_id,
        };

        let _break = `'00:00:00'`;
        if (Constants.isEmpty(workday.vd_pauze) === false) {
          _break = `'${workday.vd_pauze.substr(0,5)}'`;
        } else {
          workday.vd_pauze = '00:00:00';
        }

        for (const holiday of this.holidayDays) {
          if (workday.vd_info_id === holiday.userID && dateString === holiday.dateString) {
            await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
            const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
            const added2 = parseInt(rid[0].rooster_id);
            if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
              await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
            }
            continue loop1;
          }
        }

        // if (Plan.checkInfoExtraAddNoAlert(this.state.infoExtra[workday.vd_info_id], workday.vd_info_id, date, toPlan, this.allPlannerData, this.state.planUsersMaxCumulative) === false) {
        //   continue;
        // }

        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

          const contract = Constants.getCurrentContract(this.state.contractsObj[workday.vd_info_id], Constants.stringToDate(dateString));
          if (contract === null) {
            await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlan.rooster_begin}', '${toPlan.rooster_eind}', NULL, ${toPlan.functie_id}, 3, ${_break}, 0)`);
            const rid = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);
            const added2 = parseInt(rid[0].rooster_id);
            if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
              await APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, added2);
            }
            continue;
          }

        }

        const added = await this.planUser(date, { info_id: workday.vd_info_id }, toPlan);

        for (const shift of shiftList) {
          if (shift.dagdienst_dag === workday.vd_dag && shift.dagdienst_begin.substr(0,5) === workday.vd_begin.substr(0,5) && shift.dagdienst_eind.substr(0,5) === workday.vd_eind.substr(0,5) && shift.dagdienst_pauze.substr(0,5) === workday.vd_pauze.substr(0,5) && shift.functie_id === workday.vd_functie_id) {
            promisesDayShifts.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (NULL, '${date}', ${shift.dagdienst_id}, ${added})`));
            Constants.removeFromArray2(shiftList, 'dagdienst_id', shift.dagdienst_id);
            break;
          }
        }

      }

    }

    await Promise.all(promisesDayShifts);

  }

  renderRepeatingShiftMenuTable(data, left, date) {

    return (

      <div draggable='false' className={`planner_planMenu_table${left ? '_left' : ''}`} style={{top: this.state.display === 'XCL' ? 'calc(1.1vw + 2px)' : null}}>

        <div className='planner_planMenu_row' onClick={() => this.openRepeatShiftModal(data, 1)}>
          <div className='planner_planMenu_row_sub1'>
            <i className='fal fa-history planMenuAsset' />
          </div>
          <div className='planner_planMenu_row_sub2'>
            Wijzigen
          </div>
          <div className='planner_planMenu_row_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

        <div className='planner_planMenu_deleteRow' onClick={() => this.endRepeatingShiftAlert(data, date)}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-calendar-times planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Herh. dienst beëindigen
          </div>
        </div>

        {/* <div className='planner_planMenu_deleteRow' onClick={() => this.deleteUserPlannerAlert(data)} style={{marginTop: 0}}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-trash-alt planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Herh. dienst verwijderen
          </div>
        </div> */}

      </div>

    );
    
  }

  renderPlanMenuTable(data, left, partID, absence, skills, hasAllSkills, isAvailableShift) {

    const repeatItem = Constants.getArrayItem2(this.repeatingShifts[data.info_id], 'hr_startdatum', data.rooster_datum, 'hr_functie_id', data.functie_id);

    return (

      <div draggable='false' className={`planner_planMenu_table${left ? '_left' : ''}`} style={{top: this.state.display === 'XCL' ? 'calc(1.1vw + 2px)' : null}}>

        {/* <div className='planner_planMenu_triangle' /> */}

        {/* <div className='planner_planMenu_closeRow' onClick={() => this.closePlanMenu(data.rooster_id)}>
          <div className='planner_planMenu_closeRow_sub1'>
            <i className='fal fa-times' />
          </div>
          <div className='planner_planMenu_closeRow_sub2'>
            Sluiten
          </div>
          <div className='planner_planMenu_closeRow_sub3'>
            &nbsp;
          </div>
        </div> */}

        {data.rooster_publiceren !== '3' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuInfo[data.rooster_id] === true ? 
                this.closePlanMenuInfo(data.rooster_id) 
              :
                this.openPlanMenuInfo(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-info-circle planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Informatie
              </div>
              <div className='planner_planMenu_row_sub3' style={this.state.showPlanMenuInfo[data.rooster_id] === true ? {color: '#47B7EB'} : null}>
                <i className='fal fa-arrow-right planMenuAsset' />
              </div>
            </div>

            {this.state.showPlanMenuInfo[data.rooster_id] === true ?

              this.getHoursTooltip(data, left)

            : null}

          </div>
        : null}

        {/* <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuEdit[data.rooster_id] === true ? 
              this.closePlanMenuEdit(data.rooster_id) 
            :
              this.openPlanMenuEdit(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-edit planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Aanpassen
            </div>
            <div className='planner_planMenu_row_sub3' style={this.state.showPlanMenuEdit[data.rooster_id] === true ? {color: '#47B7EB'} : null}>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>

          {this.state.showPlanMenuEdit[data.rooster_id] === true ?

            <div className={`planner_planMenuSub${left ? '_left' : ''}`}>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickTimeEdit(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Tijd
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickEditAll(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Medewerker / functie / pauze
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>
              
            </div>

          : null}

        </div> */}

        <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuEdit[data.rooster_id] === true ? 
              this.closePlanMenuEdit(data.rooster_id) 
            :
              this.openPlanMenuEdit(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-briefcase planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Functie
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className={`fa${data.rooster_pauze !== '00:00:00' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuEdit[data.rooster_id] === true ? {color: '#47B7EB'} : null}/>
            </div>
          </div>

          {this.state.showPlanMenuEdit[data.rooster_id] === true ?

            this.renderFunctionMenu(data, left)

          : null}

        </div>

        <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuBreaks[data.rooster_id] === true ? 
              this.closePlanMenuBreaks(data.rooster_id) 
            :
              this.openPlanMenuBreaks(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-coffee planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Pauzes
            </div>
            <div className='planner_planMenu_row_sub3'>
              <i className={`fal fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuBreaks[data.rooster_id] === true ? {color: '#47B7EB'} : null}/>
            </div>
          </div>

          {this.state.showPlanMenuBreaks[data.rooster_id] === true ?

            this.renderBreaksMenu(data, left)

          : null}

        </div>

        {data.rooster_publiceren !== '3' ?
          repeatItem !== null ?
            <div className='planner_planMenu_row' onClick={() => this.openRepeatShiftModal(repeatItem, 1)}>
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-history planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Herh. dienst wijzigen
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className='fal fa-arrow-right planMenuAsset' />
              </div>
            </div>
          :
            <div className='planner_planMenu_row' onClick={() => this.openRepeatShiftModal(data, 0)}>
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-history planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Dienst herhalen
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className='fal fa-arrow-right planMenuAsset' />
              </div>
            </div>
        : null}

        <div className='planner_planMenu_row' onClick={() => this.duplicateShift(data)}>
          <div className='planner_planMenu_row_sub1'>
            <i className='fal fa-clone planMenuAsset' />
          </div>
          <div className='planner_planMenu_row_sub2'>
            Dupliceren
          </div>
          <div className='planner_planMenu_row_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

        {Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuSpecial[data.rooster_id] === true ? 
                this.closePlanMenuSpecial(data.rooster_id) 
              :
                this.openPlanMenuSpecial(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-briefcase planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Bijzonderheden
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${partID !== null ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuSpecial[data.rooster_id] === true ? {color: '#47B7EB'} : null}/>
              </div>
            </div>

            {this.state.showPlanMenuSpecial[data.rooster_id] === true ?

              this.renderSpecialMenu(data, left, partID)

            : null}

          </div>
        : null}

        {data.rooster_publiceren !== '3' && Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuAbsence[data.rooster_id] === true ? 
                this.closePlanMenuAbsence(data.rooster_id) 
              :
                this.openPlanMenuAbsence(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-user-minus planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Afwezigheid
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${absence !== null && typeof absence !== 'undefined' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuAbsence[data.rooster_id] === true ? {color: '#47B7EB'} : null} />
              </div>
            </div>
            
            {this.state.showPlanMenuAbsence[data.rooster_id] === true ?

              this.renderMenuAbsence(data, left, absence)

            : null}

          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          <div style={{position: 'relative'}}>
            <div 
              className='planner_planMenu_row'
              onClick={() => this.state.showPlanMenuComp[data.rooster_id] === true ? 
                this.closePlanMenuComp(data.rooster_id) 
              :
                this.openPlanMenuComp(data.rooster_id)
              }
            >
              <div className='planner_planMenu_row_sub1'>
                <i className='fal fa-graduation-cap planMenuAsset' />
              </div>
              <div className='planner_planMenu_row_sub2'>
                Competenties
              </div>
              <div className='planner_planMenu_row_sub3'>
                <i className={`fa${skills !== null && typeof skills !== 'undefined' ? 'r' : 'l'} fa-arrow-right planMenuAsset`} style={this.state.showPlanMenuComp[data.rooster_id] === true ? {color: '#47B7EB'} : hasAllSkills === false ? {color: Colors.color.redFilledIn} : null} />
              </div>
            </div>
            
            {this.state.showPlanMenuComp[data.rooster_id] === true ?

              this.renderMenuSkills(data, left, skills)

            : null}

          </div>
        : null}

        {(data.rooster_publiceren === '1' || data.rooster_publiceren === '3') && data.functie_id !== null ? //Constants.isTrue(Data.data.appOptions.opties_planBeschikbaar) && 
          isAvailableShift === true ?
            <div className='planner_planMenu_deleteRow' onClick={() => this.removeShiftAvailable(data)}>
              <div className='planner_planMenu_deleteRow_sub1'>
                <i className='fal fa-search-minus planMenuAsset' />
              </div>
              <div className='planner_planMenu_deleteRow_sub2'>
                Niet meer beschikbaar
              </div>
            </div>
          :
            <div className='planner_planMenu_availableRow' onClick={() => this.putShiftOnAvailable(data)}>
              <div className='planner_planMenu_availableRow_sub1'>
                <i className='fal fa-search planMenuAsset' />
              </div>
              <div className='planner_planMenu_availableRow_sub2'>
                Beschikbaar maken
              </div>
            </div>
        : null}

            {/* Constants.isTrue(Data.data.appOptions.opties_planBeschikbaar) &&  */}
        <div className='planner_planMenu_deleteRow' onClick={() => this.deleteUserPlannerAlert(data)} style={{marginTop: (data.rooster_publiceren === '1' || data.rooster_publiceren === '3') && data.functie_id !== null ? 0 : null}}>
          <div className='planner_planMenu_deleteRow_sub1'>
            <i className='fal fa-trash-alt planMenuAsset' />
          </div>
          <div className='planner_planMenu_deleteRow_sub2'>
            Verwijderen
          </div>
        </div>

      </div>

    );
    
  }

  getPlannerTouchBoxTable(tempPlannerData, i, mgtop) {

    // check particularities
    let partColor = null;
    let partAfk = null;
    let partID = null;
    const partSchedule = this.state.partSchedules[tempPlannerData.rooster_id];
    if (typeof partSchedule !== 'undefined') {
      partColor = partSchedule.bijz_kleur;
      partAfk = partSchedule.bijz_afkorting;
      partID = partSchedule.bijz_id;
    }

    // check absences
    let absence = null;
    const absenceSchedule = this.state.absenceSchedules[tempPlannerData.rooster_id];
    if (typeof absenceSchedule !== 'undefined') {
      absence = absenceSchedule;
    }

    // check comp
    let skills = null;
    let skillsRender = [];
    let userSkills = [];
    let userHasAllSkills = true;
    const skillSchedule = this.state.skillSchedules[tempPlannerData.rooster_id];
    const skillUser = this.state.skillsUsers[tempPlannerData.info_id];
    if (typeof skillSchedule !== 'undefined') {
      skills = skillSchedule;
    }
    if (typeof skillUser !== 'undefined') {
      userSkills = skillUser;
    }

    if (skills !== null) {
      for (const skill of skills) {
        const userHasSkill = Constants.objectArrayContainsKey(userSkills, 'comp_naam', skill.comp_naam);
        if (userHasSkill === false) {
          userHasAllSkills = false;
          break;
        }
      }
      for (const skill of skills) {
        skillsRender.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(skillUser, skill) ? null : Colors.color.redFilledIn}}>• {skill.comp_afkorting}</span>
          </div>
        );
      }
    }

    let isAvailableShift = false;
    if (Constants.objectArrayContains(this.state.userAvailableShifts, tempPlannerData.rooster_id) === true) {
      isAvailableShift = true;
    }

    const planData = tempPlannerData;

    let functionColor = planData.functie_kleur !== null ? planData.functie_kleur : 'EBEBEB';
    let functionName = planData.functie_naam !== null ? planData.functie_naam : 'Functie onbekend';

    const isExtraShift = typeof planData.info_id === 'undefined' || planData.info_id === null;

    const infoExtra = typeof this.state.infoExtra[planData.info_id] !== 'undefined' ? this.state.infoExtra[planData.info_id] : null;

    return (

      this.state.display === 'TABLE' ?

        <div key={planData.rooster_id} className='plannerN_shiftOuter_table' style={{marginBottom: isExtraShift ? '1.5%' : null, marginTop: mgtop ? '1.5%' : null}}>

          <div 
            className={this.state.lastEdited === planData.rooster_id + i || this.state.userHoursScheduleID === parseInt(planData.rooster_id) ? `plannerN_shiftContainer_table lastEdited ${this.state.selectedBar === 'r' + planData.rooster_id + i ? "blink" : ""}` : `plannerN_shiftContainer_table ${this.state.selectedBar === 'r' + planData.rooster_id + i ? "blink" : ""}`} 
            style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.20)}}
            draggable={this.state.showPlanMenu[planData.rooster_id] ? 'false' : 'true'}
            onDragStart={(event) => this.onDragStartUserShift(event, i, planData)}
            onDrop={(event) => this.onDropTable(event, i, planData)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickUserShiftTable(i, planData, event)}
          >

            <div className='plannerN_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.5)}}>

              {Constants.isEmpty(infoExtra) === false && Constants.isEmpty(infoExtra.info2_profielfoto) === false ?
                <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${infoExtra.info2_profielfoto}`} draggable={false} style={{ width: '1vw', height: '1vw', borderRadius: '1vw' }} />
              :
                <span style={{color: Constants.getContrastPlan(`#${functionColor}`)}}>{functionName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
              }

            </div>

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {planData.rooster_publiceren === '3' ?
                  <span className='plannerN_nameText_table' style={{color: Colors.color.redFilledIn}}>Open dienst</span>
                :
                  <span className='plannerN_nameText_table'>{functionName}</span>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef[planData.rooster_id]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.planInputs[planData.rooster_id]}
                    onKeyDown={(event) => this.handleKey(event, planData)}
                    onBlur={(e) => this.saveTimeDirectly(e, planData)}
                    onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
                    onChange={(event) => this.onChangeTime(event, planData)}
                    disabled={this.state.selectedBar === 'r' + planData.rooster_id + i}
                    type={'text'}
                    style={
                      planData.rooster_tijd_incorrect === '1' && planData.rooster_publiceren !== '3' ?
                        {color: Colors.color.redFilledIn} 
                      : 
                        isAvailableShift ?
                          {color: '#E6A024'} 
                        :
                          {color: '#04163b'} 
                    }
                  />
                :
                  <div className='plannerN_inputBoxTime_table planTimeAsset' onClick={() => this.onClickPlanTime(planData, i, true)}>
                    <span className='planTimeAsset' style={{color: planData.rooster_tijd_incorrect === '1' && planData.rooster_publiceren !== '3' ? Colors.color.redFilledIn : isAvailableShift ? '#E6A024' : '#04163b'}}>{this.state.planInputs[planData.rooster_id]}</span>
                  </div>
                }

                {planData.rooster_pauze !== '00:00:00' ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-coffee'></i>
                        <span style={{marginLeft: '0.3vw'}}>{planData.rooster_pauze.substr(0,5)} pauze</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {skills !== null ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-graduation-cap'></i>
                        <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                        {skillsRender.length > 0 ?
                          <div style={{marginTop: '0.5vw'}}>
                            {skillsRender}
                          </div>
                        : null}
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-graduation-cap' style={{color: userHasAllSkills === false && planData.rooster_publiceren !== '3' ? Colors.color.redFilledIn : null, fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
                    <span style={{color: `#${partColor}`, fontSize: '0.6vw', marginLeft: 4}}>{partAfk}</span>
                : 
                absence !== null ?
                    <span style={{color: Colors.color.redFilledIn, fontSize: '0.6vw', marginLeft: 4}}>{absence.afw_afkorting}</span>
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table_row'>

              {planData.rooster_publiceren === '0' ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-exclamation'></i>
                      <span style={{marginLeft: '0.3vw'}}>Niet gepubliceerd</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn}} />
                </Tooltip>
              : 
              isAvailableShift ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='far fa-search'></i>
                      <span style={{marginLeft: '0.3vw'}}>Dienst is beschikbaar gemaakt</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-search' style={{color: '#E6A024'}} />
                </Tooltip>
              : null}

              <i className='planner_table_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[planData.rooster_id] ? this.closePlanMenu(planData.rooster_id) : this.openPlanMenu(planData.rooster_id)} />

            </div>

          </div>

          {this.state.showPlanMenu[planData.rooster_id] === true ?

            this.renderPlanMenuTable(planData, i === 5 || i === 6, partID, absence, skills, userHasAllSkills, isAvailableShift)

          : null}

        </div>

      :

        <div key={planData.rooster_id} className='plannerX_shiftOuter_table' style={{marginBottom: isExtraShift ? '1.5%' : null}}>

          <div 
            className={this.state.lastEdited === planData.rooster_id + i || this.state.userHoursScheduleID === parseInt(planData.rooster_id) ? `plannerX_shiftContainer_table lastEdited ${this.state.selectedBar === 'r' + planData.rooster_id + i ? "blink" : ""}` : `plannerX_shiftContainer_table ${this.state.selectedBar === 'r' + planData.rooster_id + i ? "blink" : ""}`} 
            style={{backgroundColor: absence !== null ? Constants.hexToRgbA(Colors.color.redFilledIn, 0.20) : partAfk !== null ? Constants.hexToRgbA(`#${partColor}`, 0.20) : null}}
            draggable={this.state.showPlanMenu[planData.rooster_id] ? 'false' : 'true'}
            onDragStart={(event) => this.onDragStartUserShift(event, i, planData)}
            onDrop={(event) => this.onDropTable(event, i, planData)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickUserShiftTable(i, planData, event)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef[planData.rooster_id]=ref}
                    className='plannerX_inputBoxTimePlan_table planTimeAsset'
                    defaultValue={this.state.planInputs[planData.rooster_id]}
                    onKeyDown={(event) => this.handleKey(event, planData)}
                    onBlur={(e) => this.saveTimeDirectly(e, planData)}
                    onFocus={() => { this.setState({selectedBar: ''}); this.fromPlanner = [];}}
                    onChange={(event) => this.onChangeTime(event, planData)}
                    disabled={this.state.selectedBar === 'r' + planData.rooster_id + i}
                    type={'text'}
                    style={
                      planData.rooster_tijd_incorrect === '1' && planData.rooster_publiceren !== '3' ?
                        {color: Colors.color.redFilledIn} 
                      : 
                      isAvailableShift ?
                        {color: '#E6A024'} 
                      :
                      planData.rooster_publiceren === '3' ?
                        {color: Colors.color.redFilledIn, fontWeight: 'bold'} 
                      :
                        {color: '#04163b'} 
                    }
                  />
                :
                  <div className='plannerX_inputBoxTimePlan_table planTimeAsset' onClick={() => this.onClickPlanTime(planData, i, true)}>
                    <span className='planTimeAsset' style={{color: planData.rooster_tijd_incorrect === '1' || planData.rooster_publiceren === '3' ? Colors.color.redFilledIn : isAvailableShift ? '#E6A024' : '#04163b', fontWeight: planData.rooster_publiceren === '3' ? 'bold' : '500'}}>{this.state.planInputs[planData.rooster_id]}</span>
                  </div>
                }

                {planData.rooster_pauze !== '00:00:00' ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-coffee'></i>
                        <span style={{marginLeft: '0.3vw'}}>{planData.rooster_pauze.substr(0,5)} pauze</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {skills !== null ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-graduation-cap'></i>
                        <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                        {skillsRender.length > 0 ?
                          <div style={{marginTop: '0.5vw'}}>
                            {skillsRender}
                          </div>
                        : null}
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-graduation-cap' style={{color: userHasAllSkills === false && planData.rooster_publiceren !== '3' ? Colors.color.redFilledIn : null, fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {partAfk !== null && (absence === null || typeof absence === 'undefined') ?
                    <span style={{color: `#${partColor}`, fontSize: '0.6vw', marginLeft: 4}}>{partAfk}</span>
                : 
                absence !== null ?
                    <span style={{color: Colors.color.redFilledIn, fontSize: '0.6vw', marginLeft: 4}}>{absence.afw_afkorting}</span>
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {planData.rooster_publiceren === '0' ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-exclamation'></i>
                      <span style={{marginLeft: '0.3vw'}}>Niet gepubliceerd</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn}} />
                </Tooltip>
              : 
              isAvailableShift ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='far fa-search'></i>
                      <span style={{marginLeft: '0.3vw'}}>Dienst is beschikbaar gemaakt</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-search' style={{color: '#E6A024'}} />
                </Tooltip>
              : null}

              <i className='planner_table_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu[planData.rooster_id] ? this.closePlanMenu(planData.rooster_id) : this.openPlanMenu(planData.rooster_id)} />

            </div>

          </div>

          {this.state.showPlanMenu[planData.rooster_id] === true ?

            this.renderPlanMenuTable(planData, i === 5 || i === 6, partID, absence, skills, userHasAllSkills, isAvailableShift)

          : null}

        </div>

    );

  }

  getAvailabilityTouchBoxTable(availableDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', availableDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[availableDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['a'+availableDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'a' + availableDataDay.beschikbaar_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickAvailableTable(i, key, event)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['a'+availableDataDay.info_id+i] === document.activeElement || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                
                  (availableDataDay.beschikbaar_begin === '00:00:00' && availableDataDay.beschikbaar_eind === '00:00:00') ?
                                              
                    <div className='plannerN_nameText' style={{fontWeight: '500'}}>Hele dag beschikbaar</div>

                  :
            
                    <div className='plannerN_nameText' style={{fontWeight: '500'}}>Beschikbaar van {availableDataDay.beschikbaar_begin.substring(0,5)} - {availableDataDay.beschikbaar_eind.substring(0,5)}</div>

                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['a'+availableDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.availableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'a')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'a')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['a'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['a'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5, marginBottom: availableDataDay.beschikbaar_opmerking !== '' && availableDataDay.beschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay.beschikbaar_opmerking) === false ? 2 : 0}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              : null}

              {availableDataDay.beschikbaar_opmerking !== '' && availableDataDay.beschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay.beschikbaar_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-comment'></i>
                      <span style={{marginLeft: '0.3vw'}}>{availableDataDay.beschikbaar_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-comment' style={{color: '#47B7EB'}} />
                </Tooltip>
              : 
                null
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'a' + availableDataDay.beschikbaar_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickAvailableTable(i, key, event)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['a'+availableDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.availableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'a')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'a')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['a'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['a'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              : null}

              {availableDataDay.beschikbaar_opmerking !== '' && availableDataDay.beschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay.beschikbaar_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-comment'></i>
                      <span style={{marginLeft: '0.3vw'}}>{availableDataDay.beschikbaar_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-comment' style={{color: '#47B7EB'}} />
                </Tooltip>
              : 
                null
              }

            </div>

          </div>

        </div>

    );

  }

  getUnavailabilityTouchBoxTable(availableDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', availableDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[availableDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    let multipleText = null;

    // multiple
    if (Data.data.appOptions.availability === 'SCHOOL') {

      const multipleAvail = this.state.multipleAvail[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id];

      let schooltext = typeof multipleAvail !== 'undefined' && multipleAvail.school !== null ? `${multipleAvail.school.schoolrooster_begin.substr(0, 5)} - ${multipleAvail.school.schoolrooster_eind.substr(0, 5)}` : null;
      if (typeof this.state.schoolHolidayDataState[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'] !== 'undefined') {
        for (const hol of this.state.schoolHolidayDataState[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun']) {
          if(hol.info_id === availableDataDay.info_id) {
            schooltext = 'schoolvakantie';
            break;
          }
        }
      }

      if (typeof multipleAvail !== 'undefined' && multipleAvail.school !== null && multipleAvail.unav !== null) {
       
        multipleText = (
          <div className='plannerN_tooltipContainer'>
            <div>School: {schooltext}</div>
            {(multipleAvail.unav.onbeschikbaar_begin === '00:00:00' && multipleAvail.unav.onbeschikbaar_eind === '00:00:00') ?
              <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: hele dag</div>
            :
              <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: {multipleAvail.unav.onbeschikbaar_begin.substr(0, 5)} - {multipleAvail.unav.onbeschikbaar_eind.substr(0, 5)}</div>
            }
          </div>
        );

      }

    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['u'+availableDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'u' + availableDataDay.onbeschikbaar_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickUnavailableTable(i, key, e)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['u'+availableDataDay.info_id+i] === document.activeElement || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?

                  multipleText !== null ?

                    <Tooltip
                      options
                      html={multipleText}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                      style={{marginRight: 5}}
                    >
                      <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Meerdere aantekeningen <i className='fal fa-info-circle' /></div>
                    </Tooltip>

                  :
                  (availableDataDay.onbeschikbaar_begin === '00:00:00' && availableDataDay.onbeschikbaar_eind === '00:00:00') ?
                                              
                    <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>

                  :
            
                    <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn, fontSize: '0.6vw'}}>Onbeschikbaar van {availableDataDay.onbeschikbaar_begin.substring(0,5)} - {availableDataDay.onbeschikbaar_eind.substring(0,5)}</div>

                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['u'+availableDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.unavailableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'u')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'u')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['u'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['u'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5, marginBottom: availableDataDay.onbeschikbaar_opmerking !== '' && availableDataDay.onbeschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay.onbeschikbaar_opmerking) === false ? 2 : 0}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              : null}

              {availableDataDay.onbeschikbaar_opmerking !== '' && availableDataDay.onbeschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay.onbeschikbaar_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-comment'></i>
                      <span style={{marginLeft: '0.3vw'}}>{availableDataDay.onbeschikbaar_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-comment' style={{color: '#47B7EB'}} />
                </Tooltip>
              : 
                null
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'u' + availableDataDay.onbeschikbaar_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickUnavailableTable(i, key, e)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['u'+availableDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.unavailableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'u')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'u')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['u'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['u'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              : null}

              {availableDataDay.onbeschikbaar_opmerking !== '' && availableDataDay.onbeschikbaar_opmerking !== null && /^[\r|\n]+$/.test(availableDataDay.onbeschikbaar_opmerking) === false ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-comment'></i>
                      <span style={{marginLeft: '0.3vw'}}>{availableDataDay.onbeschikbaar_opmerking}</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-comment' style={{color: '#47B7EB'}} />
                </Tooltip>
              : 
                null
              }

            </div>

          </div>

        </div>

    );

  }

  getNotFilledTouchBoxTable(notFilledDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', notFilledDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[notFilledDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['n'+notFilledDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'n' + notFilledDataDay.info_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickNotFilledTable(i, key, e)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['n'+notFilledDataDay.info_id+i] === document.activeElement ?
                  Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    <div className='plannerN_nameText' style={{fontWeight: '500'}}>Hele dag beschikbaar</div>
                  :
                    <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.greyNotFilled}}>&nbsp;</div>
                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['n'+notFilledDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.notFilledInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'n')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'n')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['n'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['n'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

      :

        <div key={i + 'n' + notFilledDataDay.info_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickNotFilledTable(i, key, e)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['n'+notFilledDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.notFilledInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'n')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'n')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['n'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['n'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getNotFilledSchoolTouchBoxTable(notFilledDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', notFilledDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[notFilledDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['sn'+notFilledDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'sn' + notFilledDataDay.info_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickNotFilledSchoolTable(i, key, event)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['sn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <div className='plannerN_nameText' style={{fontWeight: '500'}}>Geen school</div>
                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['sn'+notFilledDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.schoolNotFilledInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'sn')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'sn')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>
              
              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'sn' + notFilledDataDay.info_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickNotFilledSchoolTable(i, key, event)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['sn'+notFilledDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.schoolNotFilledInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'sn')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'sn')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getSchoolTouchBoxTable(availableDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', availableDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[availableDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['s'+availableDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 's' + key} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickSchoolScheduleTable(i, key, event)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['s'+availableDataDay.info_id+i] === document.activeElement || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                
                  <div className='planner_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>School van {availableDataDay.schoolrooster_begin.substring(0,5)} - {availableDataDay.schoolrooster_eind.substring(0,5)}</div>

                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['s'+availableDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.schoolInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 's')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 's')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['s'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['s'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 's' + key} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickSchoolScheduleTable(i, key, event)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['s'+availableDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.schoolInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 's')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 's')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['s'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['s'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getSchoolHolidayTouchBoxTable(notFilledDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', notFilledDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[notFilledDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['sh'+notFilledDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'sh' + notFilledDataDay.info_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickSchoolHolidayTable(i, key, event)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['sh'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <div className='plannerN_nameText' style={{fontWeight: '500'}}>Schoolvakantie</div>
                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['sh'+notFilledDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.schoolHolidayInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'sh')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'sh')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sh'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sh'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'sh' + notFilledDataDay.info_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickSchoolHolidayTable(i, key, event)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['sh'+notFilledDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.schoolHolidayInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'sh')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'sh')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sh'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['sh'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getStandardAvailableTouchBoxTable(availableDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', availableDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[availableDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['sta'+availableDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'sta' + key} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickStandardAvailableTable(i, key, event)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['sta'+availableDataDay.info_id+i] === document.activeElement || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                
                  (availableDataDay.sb_begin === '00:00:00' && availableDataDay.sb_eind === '00:00:00') ?
                                              
                    <div className='plannerN_nameText' style={{fontWeight: '500'}}>Hele dag beschikbaar</div>

                  :
            
                    <div className='plannerN_nameText' style={{fontWeight: '500'}}>Beschikbaar van {availableDataDay.sb_begin.substring(0,5)} - {availableDataDay.sb_eind.substring(0,5)}</div>

                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['sta'+availableDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.standardAvailableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'sta')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'sta')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['sta'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['sta'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'sta' + key} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickStandardAvailableTable(i, key, event)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['sta'+availableDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.standardAvailableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'sta')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'sta')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['sta'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['sta'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getStandardUnavailableTouchBoxTable(availableDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', availableDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[availableDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['stu'+availableDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'stu' + key} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickStandardUnavailableTable(i, key, e)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['stu'+availableDataDay.info_id+i] === document.activeElement || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                
                  (availableDataDay.sb_begin === '00:00:00' && availableDataDay.sb_eind === '00:00:00') ?
                                              
                    <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>

                  :
            
                    <div className='plannerN_nameText' style={{fontWeight: '500', fontSize: '0.6vw', color: Colors.color.redFilledIn}}>Onbeschikbaar van {availableDataDay.sb_begin.substring(0,5)} - {availableDataDay.sb_eind.substring(0,5)}</div>

                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['stu'+availableDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.standardUnavailableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'stu')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'stu')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['stu'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['stu'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'stu' + key} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === availableDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${availableDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, availableDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickStandardUnavailableTable(i, key, e)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['stu'+availableDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.standardUnavailableInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][availableDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, availableDataDay, i, 'stu')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, availableDataDay, i, 'stu')}
                    onFocus={(e) => this.onFocusOther(e, availableDataDay)}
                    onChange={(event) => this.onChangeTime(event, availableDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['stu'+availableDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[availableDataDay.info_id] !== 'undefined' && this.timeRef['stu'+availableDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getStandardNotFilledTouchBoxTable(notFilledDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', notFilledDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[notFilledDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['stn'+notFilledDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'stn' + notFilledDataDay.info_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickStandardNotFilledTable(i, key, e)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['stn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.greyNotFilled}}>&nbsp;</div>
                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['stn'+notFilledDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.standardNotFilledInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'stn')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'stn')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['stn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['stn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'stn' + notFilledDataDay.info_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickStandardNotFilledTable(i, key, e)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['stn'+notFilledDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.standardNotFilledInputs[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'stn')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'stn')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['stn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['stn'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getAllPeopleTouchBoxTable(notFilledDataDay, i, key) {

    const vacation = Constants.getArrayItem2(this.holidayDaysRequests, 'dateString', i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate, 'userID', notFilledDataDay.info_id);

    let birthday = false;
    const user = this.state.accountsObj[notFilledDataDay.info_id];
    if (Constants.isEmpty(user) === false) {
      const _dateNow = Constants.stringToDate(i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate);
      const _dateNowString = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : this.state.sundayDate;
      let _date = Constants.stringToDate(user.info_geboorte);
      _date = _date.setFullYear(_dateNow.getFullYear());
      if (_dateNowString === Constants.dateToString(new Date(_date))) {
        birthday = true;
      }
    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['na'+notFilledDataDay.info_id+i] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'na' + notFilledDataDay.info_id} className='plannerN_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickNoAvailabilityTable(i, key, e)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                {this.timeRef['na'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.greyNotFilled}}>&nbsp;</div>
                :
                  <div className='plannerN_nameText'>&nbsp;</div>
                }

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['na'+notFilledDataDay.info_id+i]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.noAvailableInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'na')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'na')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['na'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['na'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }

            </div>

          </div>

        </div>

      :

        <div key={i + 'na' + notFilledDataDay.info_id} className='plannerX_shiftOuter_table'>

          <div 
            className={this.state.selectedBar === notFilledDataDay.info_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${notFilledDataDay.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, notFilledDataDay)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(e) => this.onClickNoAvailabilityTable(i, key, e)}
          >

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['na'+notFilledDataDay.info_id+i]=ref}
                    className='plannerX_inputBoxTime_table planTimeAsset'
                    defaultValue={this.state.noAvailableInputs[notFilledDataDay.info_id]}
                    onKeyDown={(event) => this.handleKeyOther(event, notFilledDataDay, i, 'na')}
                    onBlur={(e) => this.saveTimeDirectlyOther(e, notFilledDataDay, i, 'na')}
                    onFocus={(e) => this.onFocusOther(e, notFilledDataDay)}
                    onChange={(event) => this.onChangeTime(event, notFilledDataDay, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerX_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['na'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[notFilledDataDay.info_id] !== 'undefined' && this.timeRef['na'+notFilledDataDay.info_id+i] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {vacation !== null ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fas fa-island-tropical'></i>
                      <span style={{marginLeft: '0.3vw'}}>Ga naar het verlofverzoek</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-island-tropical' style={{color: Colors.color.orangeVacation, cursor: 'pointer'}} onClick={() => this.props.history.push({ pathname: `/requests`, menu: 'absences', highlight: vacation.id })} />
                </Tooltip> 
              :
              birthday === true ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <i className='fal fa-birthday-cake'></i>
                      <span style={{marginLeft: '0.3vw'}}>Jarig</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-birthday-cake' style={{color: '#E362EE'}} />
                </Tooltip> 
              :
                <span>&nbsp;</span>
              }
              
            </div>

          </div>

        </div>

    );

  }

  getHolidayTouchBoxTable(holidayDataDay, i, key, mgtop) {

    // BREAKING CHANGES

    // new Date()
    const date = holidayDataDay.date;

    const weekStart = Constants.stringToDate(this.state.mondayDate);
    const weekEnd = Constants.stringToDate(this.state.sundayDate);

    // date.setHours(12,0,0,0);
    // weekStart.setHours(12,0,0,0);
    // weekEnd.setHours(12,0,0,0);

    if(date.getTime() >= weekStart.getTime() && date.getTime() <= weekEnd.getTime()) {

      let isFreeHours = typeof this.state.freeHours[holidayDataDay.id] !== 'undefined' && this.state.freeHours[holidayDataDay.id].vu_begin !== '00:00:00' && this.state.freeHours[holidayDataDay.id].vu_eind !== '00:00:00';
      let freeHoursData = {};
      if (isFreeHours === true) {
        freeHoursData = this.state.freeHours[holidayDataDay.id];
      }

      const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[holidayDataDay.userID] !== 'undefined' && this.state.secondaryFunctionID !== null;
      const isActive = this.timeRef['fh'+freeHoursData.info_id+i+key] === document.activeElement;

      return (

        this.state.display === 'TABLE' ?

          isFreeHours === true ?

            <div key={'fh' + holidayDataDay.userID + i + key} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

              <div 
                className={'plannerN_shiftContainer_table'}
                style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${freeHoursData.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: isFreeHours === true ? this.state.selectedBar !== '' ? 'pointer' : 'text' : 'default'}}
                // style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: isFreeHours === true ? this.state.selectedBar !== '' ? 'pointer' : 'text' : 'default'}}
                onDrop={(event) => this.onDropTable(event, i, freeHoursData)}
                onDragOver={(event) => this.onDragOverOther(event, i)}
                onDragLeave={(event) => this.onDragLeave(event)}
                onClick={(e) => this.onClickHolidayTable(i, freeHoursData, e, key)}
              >

                {/* <div className='plannerN_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5)}}>

                  <i className='fal fa-island-tropical' />

                </div> */}

                <div className='plannerN_shiftColumn2_table'>

                  <div className='plannerN_shiftRow_table'>

                    <div className='plannerN_nameText' style={{color: Colors.color.orangeVacation}}>Verlof ({this.state.freeHours[holidayDataDay.id].vu_begin.substr(0, 5)} - {this.state.freeHours[holidayDataDay.id].vu_eind.substr(0, 5)})</div>

                  </div>

                  <div className='plannerN_shiftRow_table'>

                    {this.state.editPlan === true ?
                      <input
                        ref={(ref) => this.timeRef['fh'+freeHoursData.info_id+i+key]=ref}
                        className='plannerN_inputBoxTime_table planTimeAsset'
                        defaultValue={this.state.holidayInputs[holidayDataDay.userID]}
                        onKeyDown={(event) => this.handleKeyOtherHoliday(event, freeHoursData, i, 'fh', key)}
                        onBlur={(e) => this.saveTimeDirectlyOtherHoliday(e, freeHoursData, i, 'fh', key)}
                        onFocus={(e) => this.onFocusOther(e, freeHoursData)}
                        onChange={(event) => this.onChangeTime(event, freeHoursData, true)}
                        type={'text'}
                      />
                    : 
                      <div className='plannerN_inputBoxTime_table' >
                        <span>&nbsp;</span>
                      </div>
                    }

                    {typeof this.state.secondaryFunctions[freeHoursData.info_id] !== 'undefined' && this.timeRef['fh'+freeHoursData.info_id+i+key] === document.activeElement ?
                      <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                        {this.state.secondaryFunctionName}
                      </span>
                    : null}

                    {typeof this.state.secondaryFunctions[freeHoursData.info_id] !== 'undefined' && this.timeRef['fh'+freeHoursData.info_id+i+key] === document.activeElement ?
                      <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                    : null}

                  </div>

                </div>

                <div className='plannerN_shiftColumn3_table'>
                  &nbsp;
                </div>

              </div>

            </div>

          :

            <div key={i + 'h' + key} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

              <div 
                className={'plannerN_shiftContainer_table'}
                style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: 'not-allowed'}}
              >

                <div className='plannerN_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.5)}}>

                  <i className='fal fa-island-tropical' />

                </div>

                <div className='plannerN_shiftColumn2_table'>

                  <div className='plannerN_shiftRow_table'>

                    <div className='plannerN_nameText' style={{color: Colors.color.orangeVacation}}>Verlof</div>

                  </div>

                  <div className='plannerN_shiftRow_table'>

                    <span>&nbsp;</span>

                  </div>

                </div>

                <div className='plannerN_shiftColumn3_table'>
                  &nbsp;
                </div>

              </div>

            </div>
            
        : // XCL

        isFreeHours === true ?

          <div key={'fh' + holidayDataDay.userID + i + key} className='plannerX_shiftOuter_table'>

            <div 
              className={'plannerX_shiftContainer_table'}
              style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${freeHoursData.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: isFreeHours === true ? this.state.selectedBar !== '' ? 'pointer' : 'text' : 'default'}}
              // style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: isFreeHours === true ? this.state.selectedBar !== '' ? 'pointer' : 'text' : 'default'}}
              onDrop={(event) => this.onDropTable(event, i, freeHoursData)}
              onDragOver={(event) => this.onDragOverOther(event, i)}
              onDragLeave={(event) => this.onDragLeave(event)}
              onClick={(e) => this.onClickHolidayTable(i, freeHoursData, e, key)}
            >

              <i className='fal fa-island-tropical' style={{color: Colors.color.orangeVacation, fontSize: '0.6vw', marginTop: '1%'}} />

              {this.state.editPlan === true ?
                <input
                  ref={(ref) => this.timeRef['fh'+freeHoursData.info_id+i+key]=ref}
                  className='plannerX_inputBoxTime_table planTimeAsset'
                  defaultValue={this.state.holidayInputs[holidayDataDay.userID]}
                  onKeyDown={(event) => this.handleKeyOtherHoliday(event, freeHoursData, i, 'fh', key)}
                  onBlur={(e) => this.saveTimeDirectlyOtherHoliday(e, freeHoursData, i, 'fh', key)}
                  onFocus={(e) => this.onFocusOther(e, freeHoursData)}
                  onChange={(event) => this.onChangeTime(event, freeHoursData, true)}
                  type={'text'}
                  style={{marginLeft: 5}}
                />
              : 
                
                <div style={{color: Colors.color.orangeVacation, marginLeft: 5}}>Verlof ({this.state.freeHours[holidayDataDay.id].vu_begin.substr(0, 5)} - {this.state.freeHours[holidayDataDay.id].vu_eind.substr(0, 5)})</div>
                
              }

              {typeof this.state.secondaryFunctions[freeHoursData.info_id] !== 'undefined' && this.timeRef['fh'+freeHoursData.info_id+i+key] === document.activeElement ?
                <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                  {this.state.secondaryFunctionName}
                </span>
              : null}

              {typeof this.state.secondaryFunctions[freeHoursData.info_id] !== 'undefined' && this.timeRef['fh'+freeHoursData.info_id+i+key] === document.activeElement ?
                <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
              : null}

            </div>

          </div>

        :

          <div key={i + 'h' + key} className='plannerX_shiftOuter_table'>

            <div 
              className={'plannerX_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.orangeVacation, 0.2), cursor: 'not-allowed'}}
            >

              <i className='fal fa-island-tropical' style={{color: Colors.color.orangeVacation, fontSize: '0.6vw', marginTop: '1%'}} />

              <div style={{color: Colors.color.orangeVacation, marginLeft: 5}}>Verlof</div>

            </div>

          </div>

      );

    } else {
      return(null);
    }

  }

  getContractTouchBoxTable(key, mgtop) {

    return (

      this.state.display === 'TABLE' ?

        <div key={'contract' + key} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

          <div 
            className={'plannerN_shiftContainer_table'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'not-allowed'}}
          >

            <div className='plannerN_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.7)}}>

              <i className='fal fa-file-signature' />

            </div>

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                <div className='plannerN_nameText' style={{color: '#04163b'}}>Contract</div>

              </div>

              <div className='plannerN_shiftRow_table'>

                <span style={{color: '#04163b', fontStyle: 'italic', fontWeight: '500'}}>Verlopen</span>

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table'>
              &nbsp;
            </div>

          </div>

        </div>

      :

        <div key={'contract' + key} className='plannerX_shiftOuter_table'>

          <div 
            className={'plannerX_shiftContainer_table'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.pastBorderColor, 0.4), cursor: 'not-allowed'}}
          >

            <i className='fal fa-file-signature' style={{fontSize: '0.6vw', marginTop: '1%'}} />

            <span style={{color: '#04163b', fontStyle: 'italic', marginLeft: 5}}>Contract verlopen</span>

          </div>

        </div>

    );

  }

  getShiftTouchBoxTable(data, i, planKey) {

    // check particularities
    let partColor = null;
    let partAfk = null;
    let partID = null;
    let partName = null;
    const partSchedule = this.state.partDayShifts['d' + data.shifts[0].dagdienst_id];
    if (typeof partSchedule !== 'undefined') {
      partColor = partSchedule.bijz_kleur;
      partAfk = partSchedule.bijz_afkorting;
      partID = partSchedule.bijz_id;
      partName = partSchedule.bijz_naam;
    }

    // check comp
    let skills = null;
    const skillSchedule = this.state.skillDayShifts['d' + data.shifts[0].dagdienst_id];
    if (typeof skillSchedule !== 'undefined') {
      skills = skillSchedule;
    }

    let isAvailableShift = false;

    if (Constants.objectArrayContains(this.state.plannerAvailableShifts, data.shifts[0].dagdienst_id) === true) {
      isAvailableShift = true;
    }

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'shift' + data.shifts[0].dagdienst_id} className='plannerN_shiftOuter_table' style={{marginBottom: '1.5%'}}>

          <div 
            onClick={(event) => this.onClickDayShiftTable(i, data.shifts[0], event)}
            draggable='true'
            onDragStart={(event) => this.onDragStartDayShift(event, i, data)}
            //onDrop={(event) => this.onDropTable(event, i, data)}
            //onDragOver={(event) => this.onDragOverOther(event, i)}
            //onDragLeave={(event) => this.onDragLeave(event)}
            className={this.state.selectedBar === 'd' + data.shifts[0].dagdienst_id + i ? 'plannerN_shiftContainer_table blink' : 'plannerN_shiftContainer_table'}
            style={{borderColor: '#' + data.shifts[0].functie_kleur, backgroundColor: Constants.hexToRgbA(`#${data.shifts[0].functie_kleur}`, 0.15) }}
          >

            <div className='plannerN_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(`#${data.shifts[0].functie_kleur}`, 0.35)}}>

              {/* <span style={{color: Constants.getContrastPlan(`#${data.shifts[0].functie_kleur}`)}}>{data.shifts[0].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span> */}
              <span style={{color: Constants.getContrastPlan(`#${data.shifts[0].functie_kleur}`), fontWeight: 'bold'}}>{data.count}</span>

            </div>

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                <span style={{fontWeight: 'bold', color: isAvailableShift ? '#E6A024' : Colors.color.redFilledIn}}>{data.shifts[0].dagdienst_begin.substr(0, 5)} - {data.shifts[0].dagdienst_eind.substr(0, 5)}</span>

              </div>

              <div className='plannerN_shiftRow_table'>

                <span className='plannerN_nameText_table' style={{color: Colors.color.redFilledIn, fontStyle: 'italic', fontWeight: '500'}}>Standaard dienst</span>

                {data.shifts[0].dagdienst_pauze !== '00:00:00' ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-coffee'></i>
                        <span style={{marginLeft: '0.5vw'}}>{data.shifts[0].dagdienst_pauze.substr(0,5)} pauze</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {skills !== null ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-graduation-cap'></i>
                        <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-graduation-cap' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {partAfk !== null ?
                  <span style={{color: `#${partColor}`, fontSize: '0.6vw', marginLeft: 4}}>{partAfk}</span>
                : null}

              </div>

            </div>

            <div className='plannerN_shiftColumn3_table_row'>

              {isAvailableShift ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='far fa-search'></i>
                      <span style={{marginLeft: '0.3vw'}}>Dienst is beschikbaar gemaakt</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-search' style={{color: '#E6A024'}} />
                </Tooltip>
              : null}

              <i className='planner_table_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showDayShiftMenu['d' + data.shifts[0].dagdienst_id] ? this.closeDayShiftMenu('d' + data.shifts[0].dagdienst_id) : this.openDayShiftMenu('d' + data.shifts[0].dagdienst_id)} />

            </div>

          </div>

          {this.state.showDayShiftMenu['d' + data.shifts[0].dagdienst_id] === true ?
            this.renderDayShiftMenuTable(data, i === 5 || i === 6, partName, skills, isAvailableShift)
          : null}

        </div>

      :

        <div key={i + 'shift' + data.shifts[0].dagdienst_id} className='plannerX_shiftOuter_table' style={{marginTop: '1%'}}>

          <div 
            onClick={(event) => this.onClickDayShiftTable(i, data.shifts[0], event)}
            draggable='true'
            onDragStart={(event) => this.onDragStartDayShift(event, i, data)}
            //onDrop={(event) => this.onDropTable(event, i, data)}
            //onDragOver={(event) => this.onDragOverOther(event, i)}
            //onDragLeave={(event) => this.onDragLeave(event)}
            className={this.state.selectedBar === 'd' + data.shifts[0].dagdienst_id + i ? 'plannerX_shiftContainer_table blink' : 'plannerX_shiftContainer_table'}
            style={{backgroundColor: Constants.hexToRgbA(`#${data.shifts[0].functie_kleur}`, 0.15)}} // backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.15)
          >

            <div className='plannerX_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(`#${data.shifts[0].functie_kleur}`, 0.35)}}>

              {/* <span style={{color: Constants.getContrastPlan(`#${data.shifts[0].functie_kleur}`)}}>{data.shifts[0].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span> */}
              <span style={{color: isAvailableShift ? '#E6A024' : Constants.getContrastPlan(`#${data.shifts[0].functie_kleur}`), fontWeight: 'bold'}}>{data.count}</span>

            </div>

            <div className='plannerX_shiftColumn2_table'>

              <div className='plannerX_shiftRow_table'>

                <span style={{fontWeight: 'bold', color: isAvailableShift ? '#E6A024' : '#04163b'}}>{data.shifts[0].dagdienst_begin.substr(0, 5)} - {data.shifts[0].dagdienst_eind.substr(0, 5)}</span>

                {data.shifts[0].dagdienst_pauze !== '00:00:00' ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-coffee'></i>
                        <span style={{marginLeft: '0.5vw'}}>{data.shifts[0].dagdienst_pauze.substr(0,5)} pauze</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {skills !== null ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer_table'>
                        <i className='fas fa-graduation-cap'></i>
                        <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                      </div>
                    )}
                    position={'top'}
                    arrow
                    followCursor
                    theme='kodict'
                    style={{marginLeft: 4}}
                  >
                    <i className='fal fa-graduation-cap' style={{fontSize: '0.6vw'}} />
                  </Tooltip>
                : null}

                {partAfk !== null ?
                  <span style={{color: `#${partColor}`, fontSize: '0.6vw', marginLeft: 4}}>{partAfk}</span>
                : null}

              </div>

            </div>

            <div className='plannerX_shiftColumn3_table'>

              {isAvailableShift ?
                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='far fa-search'></i>
                      <span style={{marginLeft: '0.3vw'}}>Dienst is beschikbaar gemaakt</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='far fa-search' style={{color: '#E6A024'}} />
                </Tooltip>
              : null}

              <i className='planner_table_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showDayShiftMenu['d' + data.shifts[0].dagdienst_id] ? this.closeDayShiftMenu('d' + data.shifts[0].dagdienst_id) : this.openDayShiftMenu('d' + data.shifts[0].dagdienst_id)} />

            </div>

          </div>

          {this.state.showDayShiftMenu['d' + data.shifts[0].dagdienst_id] === true ?
            this.renderDayShiftMenuTable(data, i === 5 || i === 6, partName, skills, isAvailableShift)
          : null}

        </div>

    );

  }

  getNonWorkdayTouchboxTable(key, mgtop) {

      return (

        this.state.display === 'TABLE' ?

          <div key={'nwd' + key} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

            <div 
              className={'plannerN_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: 'not-allowed'}}
            >

              <div className='plannerN_shiftColumn2_table'>

                <div className='plannerN_shiftRow_table' style={{maxWidth: '11vw'}}>

                  <span className='plannerN_nameText_table' style={{color: '#04163b'}}>&nbsp;</span>

                </div>

                <div className='plannerN_shiftRow_table'>

                  <span className='plannerN_nameText_table2'>&nbsp;</span>

                </div>

              </div>

            </div>

          </div>

        :

          <div key={'nwd' + key} className='plannerX_shiftOuter_table'>

            <div 
              className={'plannerX_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: 'not-allowed'}}
            >

              {/* <i className='fal fa-building' style={{fontSize: '0.6vw', marginTop: '1%'}} /> */}

              <div style={{color: '#04163b', marginLeft: 5}}>&nbsp;</div>

            </div>

          </div>

      );

  }

  getOtherTeamTouchBoxTable(key, mgtop, plan) {

      let functionName = 'Functie onbekend';
      if (Constants.isEmpty(plan) === false && Constants.isEmpty(plan.functie_naam) === false) {
        functionName = plan.functie_naam;
      }

      const teamName = Constants.getTeamNameFromScheduleID(this.teamPlanner, plan.rooster_id);

      return (

        this.state.display === 'TABLE' ?

          <div key={'o' + key} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

            <div 
              className={'plannerN_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn2_table'>

                <div className='plannerN_shiftRow_table' style={{maxWidth: '11vw'}}>

                  <span className='plannerN_nameText_table' style={{color: '#04163b'}}>{functionName}</span>

                </div>

                <div className='plannerN_shiftRow_table'>

                  <span className='plannerN_nameText_table2'>{plan.rooster_begin.substr(0,5)} - {plan.rooster_eind.substr(0,5)} <span style={{fontSize: '0.5vw'}}>{teamName}</span></span>

                </div>

              </div>

            </div>

          </div>

        :

          <div key={'o' + key} className='plannerX_shiftOuter_table'>

            <div 
              className={'plannerX_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: 'default'}}
            >

              <i className='fal fa-building' style={{fontSize: '0.6vw', marginTop: '1%'}} />

              <div style={{color: '#04163b', marginLeft: 5}}>{plan.rooster_begin.substr(0,5)} - {plan.rooster_eind.substr(0,5)} <span style={{fontSize: '0.5vw'}}>{teamName}</span></div>

            </div>

          </div>

      );

  }

  getRepeatShiftTouchBoxTable(key, mgtop, plan) {

    const teamName = Constants.getTeamNameFromTeamID(plan.hr_team_id);

    const functionColor = plan.functie_kleur !== null ? plan.functie_kleur : 'EBEBEB';
    const functionName = plan.functie_naam !== null ? plan.functie_naam : 'Functie onbekend';

    const date = key === 1 ? this.state.mondayDate : key === 2 ? this.state.tuesdayDate : key === 3 ? this.state.wednesdayDate : key === 4 ? this.state.thursdayDate : key === 5 ? this.state.fridayDate : key === 6 ? this.state.saturdayDate : this.state.sundayDate;

    let skills = [];
    let part = null;
    if (Constants.isEmpty(this.skillsRepeatShifts[plan.hr_id]) === false) {
      for (const skill of this.skillsRepeatShifts[plan.hr_id]) {
        skills.push(
          <div>
            <span style={{marginLeft: 0, color: Plan.userHasSkill(this.state.skillsUsers[plan.hr_info_id], skill) ? null : Colors.color.redFilledIn}}>• {skill.comp_afkorting}</span>
          </div>
        );
      }
    }

    if (Constants.isEmpty(this.partRepeatShifts[plan.hr_id]) === false) {
      part = this.partRepeatShifts[plan.hr_id];
    }

    const userHasAllSkills = Plan.userHasAllSkills(this.skillsRepeatShifts[plan.hr_id], this.state.skillsUsers[plan.hr_info_id]);

    const incorrectTime = this.getIncorrectCorrectTimesDirectly(date, null, plan.hr_begin, plan.hr_eind, { info_id: plan.info_id });

    if (Constants.isEmpty(plan.hr_team_id) === true || (Data.data.teams.length > 1 && Data.data.chosenTeam !== null && plan.hr_team_id === Data.data.chosenTeamObj.team_id)) {

      return (

        this.state.display === 'TABLE' ?

          <div key={'rs' + key + plan.hr_id} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

            <div 
              className={`plannerN_shiftContainer_table`} 
              style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.10), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn1_table' style={{backgroundColor: Constants.hexToRgbA(`#${functionColor}`, 0.25)}}>

                <span style={{color: Constants.getContrastPlan(`#${functionColor}`)}}>{functionName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>

              </div>

              <div className='plannerN_shiftColumn2_table'>

                <div className='plannerN_shiftRow_table'>

                  <span className='plannerN_nameText_table'>{functionName}</span>

                </div>

                <div className='plannerN_shiftRow_table'>

                  <span className='planTimeAsset' style={{color: incorrectTime ? Colors.color.redFilledIn : '#04163b', fontWeight: '500'}}>{plan.hr_begin.substr(0, 5)} - {plan.hr_eind.substr(0, 5)}</span>

                  {plan.hr_pauze !== '00:00:00' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer_table'>
                          <i className='fas fa-coffee'></i>
                          <span style={{marginLeft: '0.3vw'}}>{plan.hr_pauze.substr(0,5)} pauze</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                      style={{marginLeft: 4}}
                    >
                      <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                    </Tooltip>
                  : null}

                  {skills.length > 0 ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer_table'>
                          <i className='fas fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                          {skills.length > 0 ?
                            <div style={{marginTop: '0.5vw'}}>
                              {skills}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                      style={{marginLeft: 4}}
                    >
                      <i className='fal fa-graduation-cap' style={{color: userHasAllSkills === false ? Colors.color.redFilledIn : null, fontSize: '0.6vw'}} />
                    </Tooltip>
                  : null}

                  {part !== null ?
                    <span style={{color: `#${part.bijz_kleur}`, fontSize: '0.6vw', marginLeft: 4}}>{part.bijz_afkorting}</span>
                  : null}

                </div>

              </div>

              <div className='plannerN_shiftColumn3_table_row'>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-history'></i>
                      <span style={{marginLeft: '0.3vw'}}>Herhalende dienst</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-history' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-exclamation'></i>
                      <span style={{marginLeft: '0.3vw'}}>Niet gepubliceerd</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn}} />
                </Tooltip>

                <i className='planner_table_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu['rs' + plan.hr_id + key] ? this.closePlanMenu('rs' + plan.hr_id + key) : this.openPlanMenu('rs' + plan.hr_id + key)} />

              </div>

            </div>

            {this.state.showPlanMenu['rs' + plan.hr_id + key] === true ?
              this.renderRepeatingShiftMenuTable(plan, key === 0 || key === 6, date)
            : null}

          </div>

        :

          <div key={'rs' + key} className='plannerX_shiftOuter_table' style={{cursor: 'default'}}>

            <div 
              className={`plannerX_shiftContainer_table`} 
              // style={{backgroundColor: partAfk !== null ? Constants.hexToRgbA(`#${partColor}`, 0.20) : null}}
              style={{ cursor: 'default' }}
            >

              <div className='plannerX_shiftColumn2_table'>

                <div className='plannerX_shiftRow_table'>

                  <span className='planTimeAsset' style={{color: incorrectTime ? Colors.color.redFilledIn : '#04163b', fontWeight: '500'}}>{plan.hr_begin.substr(0, 5)} - {plan.hr_eind.substr(0, 5)}</span>

                  {plan.hr_pauze !== '00:00:00' ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer_table'>
                          <i className='fas fa-coffee'></i>
                          <span style={{marginLeft: '0.3vw'}}>{plan.hr_pauze.substr(0,5)} pauze</span>
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                      style={{marginLeft: 4}}
                    >
                      <i className='fal fa-coffee' style={{fontSize: '0.6vw'}} />
                    </Tooltip>
                  : null}

                  {skills.length > 0 ?
                    <Tooltip
                      options
                      html={(
                        <div className='plannerN_tooltipContainer_table'>
                          <i className='fas fa-graduation-cap'></i>
                          <span style={{marginLeft: '0.3vw'}}>Bevat competenties</span>
                          {skills.length > 0 ?
                            <div style={{marginTop: '0.5vw'}}>
                              {skills}
                            </div>
                          : null}
                        </div>
                      )}
                      position={'top'}
                      arrow
                      followCursor
                      theme='kodict'
                      style={{marginLeft: 4}}
                    >
                      <i className='fal fa-graduation-cap' style={{color: userHasAllSkills === false ? Colors.color.redFilledIn : null, fontSize: '0.6vw'}} />
                    </Tooltip>
                  : null}

                  {part !== null ?
                    <span style={{color: `#${part.bijz_kleur}`, fontSize: '0.6vw', marginLeft: 4}}>{part.bijz_afkorting}</span>
                  : null}

                </div>

              </div>

              <div className='plannerX_shiftColumn3_table'>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-history'></i>
                      <span style={{marginLeft: '0.3vw'}}>Herhalende dienst</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-history' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-exclamation'></i>
                      <span style={{marginLeft: '0.3vw'}}>Niet gepubliceerd</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-exclamation' style={{color: Colors.color.redFilledIn}} />
                </Tooltip>

                <i className='planner_table_hoverIcon fas fa-caret-down planTimeAsset' style={{fontSize: '0.8vw', cursor: 'pointer'}} onClick={() => this.state.showPlanMenu['rs' + plan.hr_id + key] ? this.closePlanMenu('rs' + plan.hr_id + key) : this.openPlanMenu('rs' + plan.hr_id + key)} />

              </div>

            </div>

            {this.state.showPlanMenu['rs' + plan.hr_id + key] === true ?
              this.renderRepeatingShiftMenuTable(plan, key === 0 || key === 6, date)
            : null}

          </div>

      );

    } else {

      return (

        this.state.display === 'TABLE' ?

          <div key={'rso' + key} className='plannerN_shiftOuter_table' style={{marginTop: mgtop ? '1.5%' : null}}>

            <div 
              className={'plannerN_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: 'default'}}
            >

              <div className='plannerN_shiftColumn2_table'>

                <div className='plannerN_shiftRow_table' style={{maxWidth: '11vw'}}>

                  <span className='plannerN_nameText_table' style={{color: '#04163b'}}>{functionName}</span>

                </div>

                <div className='plannerN_shiftRow_table'>

                  <span className='plannerN_nameText_table2'>{plan.hr_begin.substr(0,5)} - {plan.hr_eind.substr(0,5)} <span style={{fontSize: '0.5vw'}}>{teamName}</span></span>

                </div>

              </div>

              <div className='plannerN_shiftColumn3_table_row'>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer_table'>
                      <i className='fas fa-history'></i>
                      <span style={{marginLeft: '0.3vw'}}>Herhalende dienst</span>
                    </div>
                  )}
                  position={'top'}
                  arrow
                  followCursor
                  theme='kodict'
                  style={{marginRight: 5}}
                >
                  <i className='fas fa-history' style={{color: Colors.color.ORIGINAL_MAINCOLOR_1}} />
                </Tooltip>

              </div>

            </div>

          </div>

        :

          <div key={'rso' + key} className='plannerX_shiftOuter_table'>

            <div 
              className={'plannerX_shiftContainer_table'}
              style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.15), cursor: 'default'}}
            >

              <i className='fal fa-building' style={{fontSize: '0.6vw', marginTop: '1%'}} />

              <div style={{color: '#04163b', marginLeft: 5}}>{plan.hr_begin.substr(0,5)} - {plan.hr_eind.substr(0,5)} <span style={{fontSize: '0.5vw'}}>{teamName}</span></div>

            </div>

          </div>

      );

    }

}

  getFillEmptyShiftTouchBoxTable(i, key, fid) {

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'fes' + key + fid} className='plannerN_shiftOuter_table' style={{marginBottom: '1.5%'}}>

          <div 
            className={'plannerN_shiftContainer_table'}
            style={{backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, {info_id: null, functie_id: fid})}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickEmptyShiftTable(i, key, fid, event)}
          >

            <div className='plannerN_shiftColumn2_table'>

              <div className='plannerN_shiftRow_table'>

                <div className='plannerN_nameText'>&nbsp;</div>

              </div>

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['fes'+key+i+fid]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={''}
                    onKeyDown={(event) => this.handleKeyOtherEmptyShift(event, key, i, fid)}
                    onBlur={(e) => this.saveTimeDirectlyOtherEmptyShift(e, key, i, fid)}
                    onFocus={(e) => this.onFocusOther(e, null)}
                    onChange={(event) => this.onChangeTimeEmptyShift(event, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

              </div>

            </div>

          </div>

        </div>

      :

        <div key={i + 'fes' + key + fid} className='plannerX_shiftOuter_table' style={{marginBottom: '1.5%'}}>

          <div 
            className={'plannerX_shiftContainer_table'}
            style={{cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}} // backgroundColor: Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25),
            onDrop={(event) => this.onDropTable(event, i, {info_id: null, functie_id: fid})}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickEmptyShiftTable(i, key, fid, event)}
          >

            {this.state.editPlan === true ?
              <input
                ref={(ref) => this.timeRef['fes'+key+i+fid]=ref}
                className='plannerX_inputBoxTime_table planTimeAsset'
                defaultValue={''}
                onKeyDown={(event) => this.handleKeyOtherEmptyShift(event, key, i, fid)}
                onBlur={(e) => this.saveTimeDirectlyOtherEmptyShift(e, key, i, fid)}
                onFocus={(e) => this.onFocusOther(e, null)}
                onChange={(event) => this.onChangeTimeEmptyShift(event, true)}
                type={'text'}
              />
            : 
              <div className='plannerX_inputBoxTime_table' >
                <span>&nbsp;</span>
              </div>
            }

          </div>

        </div>

    );

  }

  getFillEmptyShiftUserTouchBoxTable(i, key, fid, userID) {

    let data = this.getAvailabilityData(i, userID, true);

    // multiple
    let multipleText = null;

    if (Data.data.appOptions.availability === 'SCHOOL') {

      const multipleAvail = this.state.multipleAvail[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'][userID];

      let schooltext = typeof multipleAvail !== 'undefined' && multipleAvail.school !== null ? `${multipleAvail.school.schoolrooster_begin.substr(0, 5)} - ${multipleAvail.school.schoolrooster_eind.substr(0, 5)}` : null;
      if (typeof this.state.schoolHolidayDataState[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun'] !== 'undefined') {
        for (const hol of this.state.schoolHolidayDataState[i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : 'sun']) {
          if(hol.info_id === userID) {
            schooltext = 'schoolvakantie';
            break;
          }
        }
      }

      if (typeof multipleAvail !== 'undefined' && multipleAvail.school !== null && multipleAvail.unav !== null) {
       
        multipleText = (
          <div className='plannerN_tooltipContainer'>
            <div>School: {schooltext}</div>
            {(multipleAvail.unav.onbeschikbaar_begin === '00:00:00' && multipleAvail.unav.onbeschikbaar_eind === '00:00:00') ?
              <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: hele dag</div>
            :
              <div style={{color: Colors.color.redFilledIn}}>Onbeschikbaar: {multipleAvail.unav.onbeschikbaar_begin.substr(0, 5)} - {multipleAvail.unav.onbeschikbaar_eind.substr(0, 5)}</div>
            }
          </div>
        );

      }

    }

    const isActiveSecondaryFunc = typeof this.state.secondaryFunctions[userID] !== 'undefined' && this.state.secondaryFunctionID !== null;
    const isActive = this.timeRef['fes'+key+i+fid+userID] === document.activeElement;

    return (

      this.state.display === 'TABLE' ?

        <div key={i + 'fes' + key + fid + userID} className='plannerN_shiftOuter_table' style={{marginTop: '1.5%'}}>

          <div 
            className={'plannerN_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${data.functie_kleur}`, 0.25) : Constants.hexToRgbA(Colors.color.greyNotFilled, 0.25), cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, data)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickEmptyShiftUserTable(i, key, fid, event, userID)}
          >

            <div className='plannerN_shiftColumn2_table'>

              {typeof data !== 'undefined' && data !== null ?
                Data.data.appOptions.availability === 'STANDARD' && (typeof data.sb_begin !== 'undefined') ?

                  data.sb_beschikbaar === '1' ?
                    <div className='plannerN_shiftRow_table'>
                      {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                        (data.sb_begin === '00:00:00' && data.sb_eind === '00:00:00') ?                      
                          <div className='plannerN_nameText' style={{fontWeight: '500'}}>Hele dag beschikbaar</div>
                        :
                          <div className='plannerN_nameText' style={{fontWeight: '500'}}>Beschikbaar van {data.sb_begin.substring(0,5)} - {data.sb_eind.substring(0,5)}</div>
                      :
                        <div className='plannerN_nameText'>&nbsp;</div>
                      }
                    </div>
                  :
                    <div className='plannerN_shiftRow_table'>
                      {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                        (data.sb_begin === '00:00:00' && data.sb_eind === '00:00:00') ?                      
                          <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>
                        :
                          <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Onbeschikbaar van {data.sb_begin.substring(0,5)} - {data.sb_eind.substring(0,5)}</div>
                      :
                        <div className='plannerN_nameText'>&nbsp;</div>
                      }
                    </div>

                :
                Data.data.appOptions.availability === 'AVAILABILITY' && typeof data !== 'undefined' && (typeof data.onbeschikbaar_id !== 'undefined' || typeof data.beschikbaar_id !== 'undefined') ?

                  typeof data.onbeschikbaar_id !== 'undefined' ?
                    <div className='plannerN_shiftRow_table'>
                      {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                        (data.onbeschikbaar_begin === '00:00:00' && data.onbeschikbaar_eind === '00:00:00') ?                      
                          <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>
                        :
                          <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Onbeschikbaar van {data.onbeschikbaar_begin.substring(0,5)} - {data.onbeschikbaar_eind.substring(0,5)}</div>
                      :
                        <div className='plannerN_nameText'>&nbsp;</div>
                      }
                    </div>
                  :
                    <div className='plannerN_shiftRow_table'>
                      {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                        (data.beschikbaar_begin === '00:00:00' && data.beschikbaar_eind === '00:00:00') ?                      
                          <div className='plannerN_nameText' style={{fontWeight: '500'}}>Hele dag beschikbaar</div>
                        :
                          <div className='plannerN_nameText' style={{fontWeight: '500'}}>Beschikbaar van {data.beschikbaar_begin.substring(0,5)} - {data.beschikbaar_eind.substring(0,5)}</div>
                      :
                        <div className='plannerN_nameText'>&nbsp;</div>
                      }
                    </div>

                :
                Data.data.appOptions.availability === 'SCHOOL' ?

                  multipleText !== null ?

                  isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                    <div className='plannerN_shiftRow_table'>
                      <Tooltip
                        options
                        html={multipleText}
                        position={'top'}
                        arrow
                        followCursor
                        theme='kodict'
                        style={{marginRight: 5}}
                      >
                        <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Meerdere aantekeningen <i className='fal fa-info-circle' /></div>
                      </Tooltip>
                    </div>
                  :
                    <div className='plannerN_nameText'>&nbsp;</div>

                  :
                  typeof data !== 'undefined' && (typeof data.schoolrooster_dag !== 'undefined' || typeof data.beschikbaar_id !== 'undefined' || typeof data.onbeschikbaar_id !== 'undefined' || typeof data.schoolvakantie_info_id !== 'undefined') ?

                    typeof data.onbeschikbaar_id !== 'undefined' ?
                      <div className='plannerN_shiftRow_table'>
                        {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                          (data.onbeschikbaar_begin === '00:00:00' && data.onbeschikbaar_eind === '00:00:00') ?                      
                            <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>
                          :
                            <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Onbeschikbaar van {data.onbeschikbaar_begin.substring(0,5)} - {data.onbeschikbaar_eind.substring(0,5)}</div>
                        :
                          <div className='plannerN_nameText'>&nbsp;</div>
                        }
                      </div>
                    :
                    typeof data.beschikbaar_id !== 'undefined' ?
                      <div className='plannerN_shiftRow_table'>
                        {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                          (data.beschikbaar_begin === '00:00:00' && data.beschikbaar_eind === '00:00:00') ?                      
                            <div className='plannerN_nameText' style={{fontWeight: '500'}}>Hele dag beschikbaar</div>
                          :
                            <div className='plannerN_nameText' style={{fontWeight: '500'}}>Beschikbaar van {data.beschikbaar_begin.substring(0,5)} - {data.beschikbaar_eind.substring(0,5)}</div>
                        :
                          <div className='plannerN_nameText'>&nbsp;</div>
                        }
                      </div>
                    :
                    typeof data.schoolrooster_dag !== 'undefined' ?
                      <div className='plannerN_shiftRow_table'>
                        {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                          <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>School van {data.schoolrooster_begin.substring(0,5)} - {data.schoolrooster_eind.substring(0,5)}</div>
                        :
                          <div className='plannerN_nameText'>&nbsp;</div>
                        }
                      </div>
                    :
                    typeof data.schoolvakantie_info_id !== 'undefined' ?
                      <div className='plannerN_shiftRow_table'>
                        {isActive ?
                          <div className='plannerN_nameText' style={{fontWeight: '500'}}>Schoolvakantie</div>
                        :
                          <div className='plannerN_nameText'>&nbsp;</div>
                        }
                      </div>
                    :
                      <div className='plannerN_shiftRow_table'>
                        {isActive ?
                          <div className='plannerN_nameText' style={{fontWeight: '500'}}>Geen school</div>
                        :
                          <div className='plannerN_nameText'>&nbsp;</div>
                        }
                      </div>

                  :
                    <div className='plannerN_shiftRow_table'>
                      {isActive ?
                        <div className='plannerN_nameText' style={{fontWeight: '500'}}>Geen school</div>
                      :
                        <div className='plannerN_nameText'>&nbsp;</div>
                      }
                    </div>

                :
                Data.data.appOptions.availability === 'UNAVAILABILITY' && typeof data !== 'undefined' && (typeof data.onbeschikbaar_id !== 'undefined') ?

                  <div className='plannerN_shiftRow_table'>
                    {isActive || Constants.isTrue(Data.data.appOptions.opties_infoplanner_verborgen) === false ?
                      (data.onbeschikbaar_begin === '00:00:00' && data.onbeschikbaar_eind === '00:00:00') ?                      
                        <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Hele dag onbeschikbaar</div>
                      :
                        <div className='plannerN_nameText' style={{fontWeight: '500', color: Colors.color.redFilledIn}}>Onbeschikbaar van {data.onbeschikbaar_begin.substring(0,5)} - {data.onbeschikbaar_eind.substring(0,5)}</div>
                    :
                      <div className='plannerN_nameText'>&nbsp;</div>
                    }
                  </div>

                :

                  <div className='plannerN_shiftRow_table'>
                    <div className='plannerN_nameText'>&nbsp;</div>
                  </div>

              :

                <div className='plannerN_shiftRow_table'>
                  <div className='plannerN_nameText'>&nbsp;</div>
                </div>

              }

              <div className='plannerN_shiftRow_table'>

                {this.state.editPlan === true ?
                  <input
                    ref={(ref) => this.timeRef['fes'+key+i+fid+userID]=ref}
                    className='plannerN_inputBoxTime_table planTimeAsset'
                    defaultValue={''}
                    onKeyDown={(event) => this.handleKeyOtherEmptyShiftUser(event, key, i, fid, userID)}
                    onBlur={(e) => this.saveTimeDirectlyOtherEmptyShiftUser(e, key, i, fid, userID)}
                    onFocus={(e) => this.onFocusOther(e, null)}
                    onChange={(event) => this.onChangeTimeEmptyShift(event, true)}
                    type={'text'}
                  />
                : 
                  <div className='plannerN_inputBoxTime_table' >
                    <span>&nbsp;</span>
                  </div>
                }

                {typeof this.state.secondaryFunctions[userID] !== 'undefined' && this.timeRef['fes'+key+i+fid+userID] === document.activeElement ?
                  <span className='plannerN_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                    {this.state.secondaryFunctionName}
                  </span>
                : null}

                {typeof this.state.secondaryFunctions[userID] !== 'undefined' && this.timeRef['fes'+key+i+fid+userID] === document.activeElement ?
                  <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
                : null}

              </div>

            </div>

          </div>

        </div>

      :

        <div key={i + 'fes' + key + fid + userID} className='plannerX_shiftOuter_table'>

          <div 
            className={'plannerX_shiftContainer_table'}
            style={{backgroundColor: isActive ? isActiveSecondaryFunc ? Constants.hexToRgbA(this.state.secondaryFunctionColor, 0.25) : Constants.hexToRgbA(`#${data.functie_kleur}`, 0.25) : null, cursor: this.state.selectedBar !== '' ? 'pointer' : 'text'}}
            onDrop={(event) => this.onDropTable(event, i, data)}
            onDragOver={(event) => this.onDragOverOther(event, i)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onClick={(event) => this.onClickEmptyShiftUserTable(i, key, fid, event, userID)}
          >

            {this.state.editPlan === true ?
              <input
                ref={(ref) => this.timeRef['fes'+key+i+fid+userID]=ref}
                className='plannerX_inputBoxTime_table planTimeAsset'
                defaultValue={''}
                onKeyDown={(event) => this.handleKeyOtherEmptyShiftUser(event, key, i, fid, userID)}
                onBlur={(e) => this.saveTimeDirectlyOtherEmptyShiftUser(e, key, i, fid, userID)}
                onFocus={(e) => this.onFocusOther(e, null)}
                onChange={(event) => this.onChangeTimeEmptyShift(event, true)}
                type={'text'}
              />
            : 
              <div className='plannerX_inputBoxTime_table' >
                <span>&nbsp;</span>
              </div>
            }

            {typeof this.state.secondaryFunctions[userID] !== 'undefined' && this.timeRef['fes'+key+i+fid+userID] === document.activeElement ?
              <span className='plannerX_functionChooseText planFunctionSelection' onClick={() => this.chooseFunctionIteration()}>
                {this.state.secondaryFunctionName}
              </span>
            : null}

            {typeof this.state.secondaryFunctions[userID] !== 'undefined' && this.timeRef['fes'+key+i+fid+userID] === document.activeElement ?
              <i className='far fa-angle-right planFunctionSelection' style={{fontSize: '0.7vw', cursor: 'pointer', marginTop: '1%'}} onClick={() => this.chooseFunctionIteration()} />
            : null}

          </div>

        </div>

    );

  }

  closeAllUserMenuSubs() {

    //this.closeAllPlanMenuSubs();

    // add listener
    //window.removeEventListener("click", this.clickOutsideMenu);

    let showUserMenuJob = this.state.showUserMenuJob;

    for (const key in showUserMenuJob) {
      showUserMenuJob[key] = false;
    }

    this.setState({showUserMenuJob: showUserMenuJob});

  }

  closeAllUserMenus() {

    this.closeAllUserMenuSubs();

    // add listener
    //window.removeEventListener("click", this.clickOutsideMenu);

    let showUserMenu = this.state.showUserMenu;

    for (const key in showUserMenu) {
      showUserMenu[key] = false;
    }

    this.setState({showUserMenu: showUserMenu, showPlanMenuOuter: false});

  }

  closeUserMenuJob(id) {
    let showUserMenuJob = this.state.showUserMenuJob;
    showUserMenuJob[id] = false;
    this.setState({ showUserMenuJob: showUserMenuJob });
  }

  openUserMenuJob(id) {
    this.closeAllPlanMenuSubs();
    let showUserMenuJob = this.state.showUserMenuJob;
    showUserMenuJob[id] = true;
    this.setState({ showUserMenuJob: showUserMenuJob });
  }

  closeUserMenu(id) {

    //this.closeAllPlanMenuSubs();

    let showUserMenu = this.state.showUserMenu;

    showUserMenu[id] = false;

    this.setState({ showUserMenu: showUserMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  openUserMenu(id) {

    this.closeAllUserMenus();

    // this.fromPlanner = [];
    // this.setState({ selectedBar: '' });

    // add listener
    //window.addEventListener("click", this.clickOutsideMenu);

    let showUserMenu = this.state.showUserMenu;

    showUserMenu[id] = true;

    this.setState({ showUserMenu: showUserMenu, showPlanMenuOuter: !this.state.showPlanMenuOuter });

  }

  getJobsUserMenu(userData) {

    let toReturn = [];

    for (const job of this.functionsData) {
      toReturn.push(
        <div className='planner_userMenuSub_row' onClick={() => this.editUserFunction(userData, job.functie_id)}>
          <div className='planner_userMenuSub_row_sub1'>
            {job.functie_naam}
          </div>
          <div className='planner_userMenuSub_row_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>
      );
    }

    return toReturn;

  }

  renderUserMenu(data, left) {

    const userID = data.info_id;
    const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

    const dateObject = Constants.stringToDate(this.state.mondayDate);
    const dateObject2 = Constants.stringToDate(this.state.sundayDate);

    let salary = 0;

    let nettoWeek = 0;
    let dagenWeek = 0;
    let kostenWeek = 0;
    let contractUrenWeek = 0;

    let netto = 0;
    let dagen = 0;
    let kosten = 0;
    let netto2 = 0;
    let dagen2 = 0;
    let kosten2 = 0;

    let foundContract = false;

    let availableText = ['Niks ingevuld','Niks ingevuld','Niks ingevuld','Niks ingevuld','Niks ingevuld','Niks ingevuld','Niks ingevuld'];
    let availableFound = [false, false, false, false, false, false, false];
    let dates = [this.state.mondayDate, this.state.tuesdayDate, this.state.wednesdayDate, this.state.thursdayDate, this.state.fridayDate, this.state.saturdayDate, this.state.sundayDate];

    if (Data.data.appOptions.availability === 'SCHOOL') {
      availableText = ['Geen school', 'Geen school', 'Geen school', 'Geen school', 'Geen school', 'Geen school', 'Geen school'];
    } else if (Data.data.appOptions.availability === 'UNAVAILABILITY') {
      availableText = [<span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>, <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>, <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>, <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>, <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>, <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>, <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>];
    }

    let day = 0;
    if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {

      for (const dateString of dates) {

        if (typeof this.state.allAvailabilities[userID] !== 'undefined') {
          for (const schedule of this.state.allAvailabilities[userID]) {
            if(schedule.beschikbaar_datum === dateString) {
              if (schedule.beschikbaar_begin === '00:00:00' || schedule.beschikbaar_eind === '00:00:00') {
                availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>;
              } else {
                availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>{schedule.beschikbaar_begin.substr(0, 5)} - {schedule.beschikbaar_eind.substr(0, 5)}</span>;
              }
              availableFound[day] = true;
              break;
            }
          }
        }

        if(availableFound[day] === false) {
          if (typeof this.state.allUnavailabilities[userID] !== 'undefined') {
            for (const schedule of this.state.allUnavailabilities[userID]) {
              if(schedule.onbeschikbaar_datum === dateString) {
                if (schedule.onbeschikbaar_begin === '00:00:00' || schedule.onbeschikbaar_eind === '00:00:00') {
                  availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag</span>;
                } else {
                  availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>{schedule.onbeschikbaar_begin.substr(0, 5)} - {schedule.onbeschikbaar_eind.substr(0, 5)}</span>;
                }
              }
            }
          }
        }

        day++;

      }

    } else if (Data.data.appOptions.availability === 'STANDARD') {

      for (const dateString of dates) {

        const dateObject = Constants.stringToDate(dateString);

        if (typeof this.state.allStandardAvailabilities[userID] !== 'undefined') {
          for (const schedule of this.state.allStandardAvailabilities[userID]) {
            if(parseInt(schedule.sb_dag) === dateObject.getDay()) {
              if (schedule.sb_begin === '00:00:00' || schedule.sb_eind === '00:00:00') {
                availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>Hele dag</span>;
              } else {
                availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.greenFilledIn}}>{schedule.sb_begin.substr(0, 5)} - {schedule.sb_eind.substr(0, 5)}</span>;
              }
              availableFound[day] = true;
              break;
            }
          }
        }

        if(availableFound[day] === false) {
          if (typeof this.state.allStandardUnavailabilities[userID] !== 'undefined') {
            for (const schedule of this.state.allStandardUnavailabilities[userID]) {
              if(parseInt(schedule.sb_dag) === dateObject.getDay()) {
                if (schedule.sb_begin === '00:00:00' || schedule.sb_eind === '00:00:00') {
                  availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag</span>;
                } else {
                  availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>{schedule.sb_begin.substr(0, 5)} - {schedule.sb_eind.substr(0, 5)}</span>;
                }
              }
            }
          }
        }

        day++;

      }

    } else if (Data.data.appOptions.availability === 'SCHOOL') {

      for (const dateString of dates) {

        const dateObject = Constants.stringToDate(dateString);
        const dateDay = dateObject.getDay();
        const dayKey = dateDay === 1 ? 'mon' : dateDay === 2 ? 'tue' : dateDay === 3 ? 'wed' : dateDay === 4 ? 'thu' : dateDay === 5 ? 'fri' : dateDay === 6 ? 'sat' : dateDay === 0 ? 'sun' : 'mon';

        if (typeof this.state.multipleAvail[dayKey][userID] !== 'undefined') {

          const multipleAvail = this.state.multipleAvail[dayKey][userID];

          let schooltext = multipleAvail.school !== null ? `${multipleAvail.school.schoolrooster_begin.substr(0, 5)} - ${multipleAvail.school.schoolrooster_eind.substr(0, 5)}` : null;
          if (typeof this.state.schoolHolidayDataState[dayKey] !== 'undefined') {
            for (const hol of this.state.schoolHolidayDataState[dayKey]) {
              if(hol.info_id === userID) {
                schooltext = 'Schoolvakantie';
                break;
              }
            }
          }

          if (multipleAvail.school !== null && multipleAvail.unav !== null) {
            availableText[day] = (
              <div>
                <div className='planMenuAsset'>{schooltext}</div>
                {(multipleAvail.unav.onbeschikbaar_begin === '00:00:00' || multipleAvail.unav.onbeschikbaar_eind === '00:00:00') ?
                  <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag</div>
                :
                  <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>{multipleAvail.unav.onbeschikbaar_begin.substr(0, 5)} - {multipleAvail.unav.onbeschikbaar_eind.substr(0, 5)}</div>
                }
              </div>
            );
          } else if (multipleAvail.unav !== null) {
            availableText[day] = (
              <div>
                {(multipleAvail.unav.onbeschikbaar_begin === '00:00:00' || multipleAvail.unav.onbeschikbaar_eind === '00:00:00') ?
                  <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag</div>
                :
                  <div className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>{multipleAvail.unav.onbeschikbaar_begin.substr(0, 5)} - {multipleAvail.unav.onbeschikbaar_eind.substr(0, 5)}</div>
                }
              </div>
            );
          } else if (multipleAvail.school !== null) {
            availableText[day] = (
              <div>
                <div className='planMenuAsset'>{schooltext}</div>
              </div>
            );
          }

        }

        // if (typeof this.state.allSchoolSchedules[userID] !== 'undefined') {
        //   for (const schedule of this.state.allSchoolSchedules[userID]) {
        //     if(parseInt(schedule.schoolrooster_dag) === dateObject.getDay()) {
        //       availableText[day] = <span className='planMenuAsset'>{schedule.schoolrooster_begin.substr(0, 5)} - {schedule.schoolrooster_eind.substr(0, 5)}</span>;
        //       availableFound[day] = true;
        //       break;
        //     }
        //   }
        // }

        // if(availableFound[day] === false) {
        //   if (typeof this.state.allUnavailabilities[userID] !== 'undefined') {
        //     for (const schedule of this.state.allUnavailabilities[userID]) {
        //       if(schedule.onbeschikbaar_datum === dateString) {
        //         if (schedule.onbeschikbaar_begin === '00:00:00' || schedule.onbeschikbaar_eind === '00:00:00') {
        //           availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Hele dag</span>;
        //         } else {
        //           availableText[day] = <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>{schedule.onbeschikbaar_begin.substr(0, 5)} - {schedule.onbeschikbaar_eind.substr(0, 5)}</span>;
        //         }
        //       }
        //     }
        //   }
        // }

        day++;

      }

    }

    //SKILLS
    const employeeSkills = this.state.skillsUsers[userID];

    let skills = null;

    if (typeof employeeSkills !== 'undefined') {

      skills = [];

      for (const skill of employeeSkills) {
        skills.push(
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              - {skill.comp_naam}
            </div>
          </div>
        );
      }

    }

    // SALARY
    const monthName = Constants.getMonthNameM(dateObject.getMonth());
    const monthName2 = Constants.getMonthNameM(dateObject2.getMonth());

    // const hoursMonth = dateObject.getMonth() + 1 === this.state.month ? this.state.hoursMonth : this.state.hoursMonth2;
    // const hoursMonth2 = dateObject2.getMonth() + 1 === this.state.month2 ? this.state.hoursMonth2 : this.state.hoursMonth;

    // for (const person of hoursMonth) {
    //   if (parseInt(person.info_id) === parseInt(userID)) {
    //     dagen = person.rooster_werkdagen;
    //     netto = this.timeStringToFloat(person.rooster_netto_uren);
    //     break;
    //   }
    // }

    // // month 2?
    // if (dateObject.getMonth() !== dateObject2.getMonth()) {
    //   for (const person of hoursMonth2) {
    //     if (parseInt(person.info_id) === parseInt(userID)) {
    //       dagen2 = person.rooster_werkdagen;
    //       netto2 = this.timeStringToFloat(person.rooster_netto_uren);
    //       break;
    //     }
    //   }
    // }

    // for (const person of this.state.hoursWeek) {
    //   if (parseInt(person.info_id) === parseInt(userID)) {
    //     dagenWeek = person.rooster_werkdagen;
    //     nettoWeek = this.timeStringToFloat(person.rooster_netto_uren);
    //     break;
    //   }
    // }

    // nettoWeek = this.getUserHoursWeek(userID);

    const contract = Constants.getCurrentContract(this.state.contractsObj[userID]);
    if (contract !== null) {
      salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
      foundContract = true;
    }

    // for (const person of this.state.contracts) {
    //   if (parseInt(person.contract_info_id) === parseInt(userID)) {
    //     salary = isNaN(parseFloat(person.contract_bruto_uurloon)) ? 0 : parseFloat(person.contract_bruto_uurloon);
    //     //kostenWeek = (nettoWeek * salary) * this.salaryFactor;
    //     //kosten = (netto * salary) * this.salaryFactor;
    //     //kosten2 = (netto2 * salary) * this.salaryFactor;
    //     //contractUrenWeek = parseInt(person.contract_uren);
    //     foundContract = true;
    //     break;
    //   }
    // }

    let _weeklyHours = 0;
    let _weeklyDays = [];
    if (Constants.isEmpty(this.state.weeklyHours[userID]) === false) {
      for (const _plan of this.state.weeklyHours[userID]) {
        const _start = Constants.stringToDateTime(_plan.rooster_datum, _plan.rooster_begin.substr(0,5));
        const _end = Constants.stringToDateTime(Plan.isNightShift2(_plan), _plan.rooster_eind.substr(0,5));
        const _break = Constants.timeStringToMS(_plan.rooster_pauze.substr(0,5));
        if (Constants.objectArrayContainsKey(_weeklyDays, 'rooster_datum', _plan.rooster_datum) === false) {
          _weeklyDays.push(_plan);
        }
        _weeklyHours += ((_end - _start) - _break);
      }
    }

    let _monthlyHours = 0;
    let _monthlyDays = [];
    if (Constants.isEmpty(this.state.monthlyHours[userID]) === false) {
      for (const _plan of this.state.monthlyHours[userID]) {
        const _start = Constants.stringToDateTime(_plan.rooster_datum, _plan.rooster_begin.substr(0,5));
        const _end = Constants.stringToDateTime(Plan.isNightShift2(_plan), _plan.rooster_eind.substr(0,5));
        const _break = Constants.timeStringToMS(_plan.rooster_pauze.substr(0,5));
        if (Constants.objectArrayContainsKey(_monthlyDays, 'rooster_datum', _plan.rooster_datum) === false) {
          _monthlyDays.push(_plan);
        }
        _monthlyHours += ((_end - _start) - _break);
      }
    }

    let _monthlyHours2 = 0;
    let _monthlyDays2 = [];
    if (dateObject.getMonth() !== dateObject2.getMonth()) {
      if (Constants.isEmpty(this.state.monthlyHours2[userID]) === false) {
        for (const _plan of this.state.monthlyHours2[userID]) {
          const _start = Constants.stringToDateTime(_plan.rooster_datum, _plan.rooster_begin.substr(0,5));
          const _end = Constants.stringToDateTime(Plan.isNightShift2(_plan), _plan.rooster_eind.substr(0,5));
          const _break = Constants.timeStringToMS(_plan.rooster_pauze.substr(0,5));
          if (Constants.objectArrayContainsKey(_monthlyDays2, 'rooster_datum', _plan.rooster_datum) === false) {
            _monthlyDays2.push(_plan);
          }
          _monthlyHours2 += ((_end - _start) - _break);
        }
      }
    }

    return (

      <div draggable='false' className={`planner_userMenu${left ? '_left' : ''}`}>

        {/* NAME */}
        <div className='planner_userMenu_row' style={{marginTop: '1%', paddingBottom: '4%', borderBottom: '1px solid #e1e1e1'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>{userFullName}</b>
          </div>
        </div>

        {/* BESCHIKBAARHEID */}
        {Data.data.appOptions.availability !== 'NONE' ?
          <div>
            <div className='planner_userMenu_row' style={{marginTop: '3%', marginBottom: '0%'}}>
              <div className='planner_userMenu_row_sub1'>
                {Data.data.appOptions.availability === 'SCHOOL' ?
                  <b className='planMenuAsset'>School</b>
                : 
                  <b className='planMenuAsset'>Beschikbaarheid</b>
                }
              </div>
            </div>

            {/* {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
              <div className='planner_userMenu_row'>
                <div className='planner_userMenu_row_sub1 defaultLinkO' onClick={() => this.props.history.push('/availabilities')}>
                  Beheer beschikbaarheid
                </div>
              </div>
            : null} */}

            {Data.data.appOptions.availability === 'STANDARD' ?
              <div className='planner_userMenu_row'>
                <div className='planner_userMenu_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'availability'})}>
                  Beheer beschikbaarheid
                </div>
              </div>
            : null}     

            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1'>
                Maandag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[0]}
              </div>
            </div>

            <div className='planner_userMenu_row' style={{marginTop: Data.data.appOptions.availability === 'SCHOOL' ? '2%' : null}}>
              <div className='planner_userMenu_row_sub1'>
                Dinsdag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[1]}
              </div>
            </div>

            <div className='planner_userMenu_row' style={{marginTop: Data.data.appOptions.availability === 'SCHOOL' ? '2%' : null}}>
              <div className='planner_userMenu_row_sub1'>
                Woensdag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[2]}
              </div>
            </div>

            <div className='planner_userMenu_row' style={{marginTop: Data.data.appOptions.availability === 'SCHOOL' ? '2%' : null}}>
              <div className='planner_userMenu_row_sub1'>
                Donderdag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[3]}
              </div>
            </div>

            <div className='planner_userMenu_row' style={{marginTop: Data.data.appOptions.availability === 'SCHOOL' ? '2%' : null}}>
              <div className='planner_userMenu_row_sub1'>
                Vrijdag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[4]}
              </div>
            </div>

            <div className='planner_userMenu_row' style={{marginTop: Data.data.appOptions.availability === 'SCHOOL' ? '2%' : null}}>
              <div className='planner_userMenu_row_sub1'>
                Zaterdag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[5]}
              </div>
            </div>

            <div className='planner_userMenu_row' style={{marginTop: Data.data.appOptions.availability === 'SCHOOL' ? '2%' : null}}>
              <div className='planner_userMenu_row_sub1'>
                Zondag:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {availableText[6]}
              </div>
            </div>
          </div>
        : null}

        {/* FUNCTIE */}
        <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>Functie</b>
          </div>
        </div>

        <div style={{position: 'relative'}}>
          <div 
            className='planner_userMenu_row_hover'
            onClick={() => this.state.showUserMenuJob[data.info_id] === true ? 
              this.closeUserMenuJob(data.info_id) 
            :
              this.openUserMenuJob(data.info_id)
            }
          >
            <div className='planner_userMenu_row_hover_sub1'>
              {data.functie_naam === null ? <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Geen functie</span> : data.functie_naam}
            </div>
            <div className='planner_userMenu_row_hover_sub3' style={this.state.showUserMenuJob[data.info_id] === true ? {color: '#47B7EB'} : null}>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>

          {this.state.showUserMenuJob[data.info_id] === true ?

            <div className={`planner_userMenuSub${left ? '_left' : ''}`}>

              <div className='planner_userMenuSub_row' onClick={() => this.editUserFunction(data, null)}>
                <div className='planner_userMenuSub_row_sub1'>
                  -
                </div>
                <div className='planner_userMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>

              {this.getJobsUserMenu(data)}
              
            </div>

          : null}
        </div>

        {/* COMPETENTIES */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_userMenu_row_sub1'>
              <b className='planMenuAsset'>Competenties</b>
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'skills'})}>
              Beheer competenties
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          skills
        : null}

        {/* UURLOON */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_userMenu_row_sub1'>
              <b className='planMenuAsset'>Uurloon</b>
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          foundContract === true ?
            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1'>
                {this.formatter.format(Math.round((salary + Number.EPSILON) * 100) / 100)}
              </div>
            </div>
          : 
            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'contract'})}>
                Uurloon toevoegen
              </div>
            </div>
        : null}

        {/* WEEK */}
        <div>
          <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_userMenu_row_sub1'>
              <b className='planMenuAsset'>Week {this.state.week}</b>
            </div>
          </div>

          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Dagen
            </div>
            <div className='planner_userMenu_row_sub2'>
              {_weeklyDays.length}
            </div>
          </div>

          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Uren:
            </div>
            <div className='planner_userMenu_row_sub2'>
              {Constants.msToTime2(_weeklyHours)}
            </div>
          </div>

          {/* MAAND */}
          <div className='planner_userMenu_row' style={{marginBottom: '0%', marginTop: '2%'}}>
            <div className='planner_userMenu_row_sub1'>
              <b className='planMenuAsset'>Maand {monthName}</b>
            </div>
          </div>

          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Dagen:
            </div>
            <div className='planner_userMenu_row_sub2'>
              {_monthlyDays.length}
            </div>
          </div>

          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Uren:
            </div>
            <div className='planner_userMenu_row_sub2'>
              {Constants.msToTime2(_monthlyHours)}
            </div>
          </div>

          {/* MAAND */}
          {dateObject.getMonth() !== dateObject2.getMonth() ?
            <div className='planner_userMenu_row' style={{marginBottom: '0%', marginTop: '2%'}}>
              <div className='planner_userMenu_row_sub1'>
                <b className='planMenuAsset'>Maand {monthName2}</b>
              </div>
            </div>
          : null}

          {dateObject.getMonth() !== dateObject2.getMonth() ?
            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1'>
                Dagen:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {_monthlyDays2.length}
              </div>
            </div>
          : null}

          {dateObject.getMonth() !== dateObject2.getMonth() ?
            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1'>
                Uren:
              </div>
              <div className='planner_userMenu_row_sub2'>
                {Constants.msToTime2(_monthlyHours2)}
              </div>
            </div>
          : null}

        </div>

        {/* {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row_hover' style={{marginTop: '4%'}} onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'stats'})}>
            <div className='planner_userMenu_row_hover_sub1'>
              Medewerkeroverzicht
            </div>
            <div className='planner_userMenu_row_hover_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        : null} */}

        <div className='planner_userMenu_row_hover' style={{marginTop: '4%'}} onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'settings'})}>
          <div className='planner_userMenu_row_hover_sub1'>
            Medewerkerinstellingen
          </div>
          <div className='planner_userMenu_row_hover_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

        {/* <div style={{position: 'relative'}}>
          <div 
            className='planner_planMenu_row'
            onClick={() => this.state.showPlanMenuEdit[data.rooster_id] === true ? 
              this.closePlanMenuEdit(data.rooster_id) 
            :
              this.openPlanMenuEdit(data.rooster_id)
            }
          >
            <div className='planner_planMenu_row_sub1'>
              <i className='fal fa-edit planMenuAsset' />
            </div>
            <div className='planner_planMenu_row_sub2'>
              Aanpassen
            </div>
            <div className='planner_planMenu_row_sub3' style={this.state.showPlanMenuEdit[data.rooster_id] === true ? {color: '#47B7EB'} : null}>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>

          {this.state.showPlanMenuEdit[data.rooster_id] === true ?

            <div className={`planner_planMenuSub`}>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickTimeEdit(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Tijd
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>

              <div className='planner_planMenuSub_row' onClick={() => this.onClickEditAll(data)}>
                <div className='planner_planMenuSub_row_sub1'>
                  Medewerker / functie / pauze
                </div>
                <div className='planner_planMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>
              
            </div>

          : null}

        </div> */}

      </div>

    );
    
  }

  renderUserMenu2(data, i) {

    const userID = data.info_id;
    const userFullName = `${data.info_voornaam} ${(data.info_tussenvoegsel !== null && data.info_tussenvoegsel !== '') ? data.info_tussenvoegsel + " " + data.info_achternaam : data.info_achternaam}`;

    const dateObject = Constants.stringToDate(this.state.mondayDate);

    let salary = 0;

    // let nettoWeek = 0;
    // let dagenWeek = 0;
    // let kostenWeek = 0;
    // let contractUrenWeek = 0;

    // let netto = 0;
    // let dagen = 0;
    // let kosten = 0;

    let foundContract = false;

    //SKILLS
    const employeeSkills = this.state.skillsUsers[userID];

    let skills = null;

    if (typeof employeeSkills !== 'undefined') {

      skills = [];

      for (const skill of employeeSkills) {
        skills.push(
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              - {skill.comp_naam}
            </div>
          </div>
        );
      }

    }

    // SALARY
    const monthName = Constants.getMonthNameM(dateObject.getMonth());

    // const hoursMonth = dateObject.getMonth() + 1 === this.state.month ? this.state.hoursMonth : this.state.hoursMonth2;

    // for (const person of hoursMonth) {
    //   if (parseInt(person.info_id) === parseInt(userID)) {
    //     dagen = person.rooster_werkdagen;
    //     netto = this.timeStringToFloat(person.rooster_netto_uren);
    //     break;
    //   }
    // }

    // for (const person of this.state.hoursWeek) {
    //   if (parseInt(person.info_id) === parseInt(userID)) {
    //     dagenWeek = person.rooster_werkdagen;
    //     //nettoWeek = this.timeStringToFloat(person.rooster_netto_uren);
    //     break;
    //   }
    // }

    // nettoWeek = this.getUserHoursWeek(userID);

    const contract = Constants.getCurrentContract(this.state.contractsObj[userID]);
    if (contract !== null) {
      salary = isNaN(parseFloat(contract.contract_uurloon)) ? 0 : parseFloat(contract.contract_uurloon);
      foundContract = true;
    }

    // for (const person of this.state.contracts) {
    //   if (parseInt(person.contract_info_id) === parseInt(userID)) {
    //     salary = isNaN(parseFloat(person.contract_bruto_uurloon)) ? 0 : parseFloat(person.contract_bruto_uurloon);
    //     //kostenWeek = (nettoWeek * salary) * this.salaryFactor;
    //     //kosten = (netto * salary) * this.salaryFactor;
    //     //contractUrenWeek = parseInt(person.contract_uren);
    //     foundContract = true;
    //     break;
    //   }
    // }

    return (

      <div draggable='false' className={`planner_userMenu`}>

        {/* NAME */}
        <div className='planner_userMenu_row' style={{marginTop: '1%', paddingBottom: '4%', borderBottom: '1px solid #e1e1e1'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>{userFullName}</b>
          </div>
        </div>

        {/* FUNCTIE */}
        <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>Functie</b>
          </div>
        </div>

        <div style={{position: 'relative'}}>
          <div 
            className='planner_userMenu_row_hover'
            onClick={() => this.state.showUserMenuJob[data.info_id] === true ? 
              this.closeUserMenuJob(data.info_id) 
            :
              this.openUserMenuJob(data.info_id)
            }
          >
            <div className='planner_userMenu_row_hover_sub1'>
              {data.functie_naam === null ? <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>Geen functie</span> : data.functie_naam}
            </div>
            <div className='planner_userMenu_row_hover_sub3' style={this.state.showUserMenuJob[data.info_id] === true ? {color: '#47B7EB'} : null}>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>

          {this.state.showUserMenuJob[data.info_id] === true ?

            <div className={`planner_userMenuSub`}>

              <div className='planner_userMenuSub_row' onClick={() => this.editUserFunction(data, null)}>
                <div className='planner_userMenuSub_row_sub1'>
                  -
                </div>
                <div className='planner_userMenuSub_row_sub3'>
                  <i className='fal fa-arrow-right planMenuAsset' />
                </div>
              </div>

              {this.getJobsUserMenu(data)}
              
            </div>

          : null}
        </div>

        {/* COMPETENTIES */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_userMenu_row_sub1'>
              <b className='planMenuAsset'>Competenties</b>
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'skills'})}>
              Beheer competenties
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          skills
        : null}

        {/* UURLOON */}
        {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
            <div className='planner_userMenu_row_sub1'>
              <b className='planMenuAsset'>Uurloon</b>
            </div>
          </div>
        : null}

        {Data.data.appOptions.opties_versie !== '0' ?
          foundContract === true ?
            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1'>
                {this.formatter.format(Math.round((salary + Number.EPSILON) * 100) / 100)}
              </div>
            </div>
          : 
            <div className='planner_userMenu_row'>
              <div className='planner_userMenu_row_sub1 defaultLinkO' onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'contract'})}>
                Uurloon toevoegen
              </div>
            </div>
        : null}

        {/* WEEK */}
        {/* <div className='planner_userMenu_row' style={{marginTop: '2%', marginBottom: '0%'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>Week {this.state.week}</b>
          </div>
        </div>

        <div className='planner_userMenu_row'>
          <div className='planner_userMenu_row_sub1'>
            Dagen
          </div>
          <div className='planner_userMenu_row_sub2'>
            {dagenWeek}
          </div>
        </div>

        {foundContract && !isNaN(contractUrenWeek) && contractUrenWeek !== 0 ?
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Uren:
            </div>
            <div className='planner_userMenu_row_sub2'>
              <span className='planMenuAsset' style={{color: Colors.color.redFilledIn}}>{nettoWeek}</span>&nbsp;/ {contractUrenWeek}
            </div>
          </div>
        :
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Uren:
            </div>
            <div className='planner_userMenu_row_sub2'>
              {nettoWeek}
            </div>
          </div>
        }

        {foundContract === true ?
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Kosten:
            </div>
            <div className='planner_userMenu_row_sub2'>
              <b className='planMenuAsset'>{this.formatter.format(Math.round((kostenWeek + Number.EPSILON) * 100) / 100)}</b>
            </div>
          </div>
        :
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Kosten:
            </div>
            <div className='planner_userMenu_row_sub2'>
              n.v.t.
            </div>
          </div>
        } */}

        {/* MAAND */}
        {/* <div className='planner_userMenu_row' style={{marginBottom: '0%', marginTop: '2%'}}>
          <div className='planner_userMenu_row_sub1'>
            <b className='planMenuAsset'>Maand {monthName}</b>
          </div>
        </div>

        <div className='planner_userMenu_row'>
          <div className='planner_userMenu_row_sub1'>
            Dagen:
          </div>
          <div className='planner_userMenu_row_sub2'>
            {dagen}
          </div>
        </div>

        <div className='planner_userMenu_row'>
          <div className='planner_userMenu_row_sub1'>
            Uren:
          </div>
          <div className='planner_userMenu_row_sub2'>
            {netto}
          </div>
        </div>

        {foundContract === true ?
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Kosten:
            </div>
            <div className='planner_userMenu_row_sub2'>
              <b className='planMenuAsset'>{this.formatter.format(Math.round((kosten + Number.EPSILON) * 100) / 100)}</b>
            </div>
          </div>
        :
          <div className='planner_userMenu_row'>
            <div className='planner_userMenu_row_sub1'>
              Kosten:
            </div>
            <div className='planner_userMenu_row_sub2'>
              n.v.t.
            </div>
          </div>
        } */}

        {/* {Data.data.appOptions.opties_versie !== '0' ?
          <div className='planner_userMenu_row_hover' style={{marginTop: '4%'}} onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'stats'})}>
            <div className='planner_userMenu_row_hover_sub1'>
              Medewerkeroverzicht
            </div>
            <div className='planner_userMenu_row_hover_sub3'>
              <i className='fal fa-arrow-right planMenuAsset' />
            </div>
          </div>
        : null} */}

        <div className='planner_userMenu_row_hover' style={{marginTop: '4%'}} onClick={() => this.props.history.push({pathname: '/employees', selected: userID, selectedName: userFullName, menu: 'settings'})}>
          <div className='planner_userMenu_row_hover_sub1'>
            Medewerkerinstellingen
          </div>
          <div className='planner_userMenu_row_hover_sub3'>
            <i className='fal fa-arrow-right planMenuAsset' />
          </div>
        </div>

      </div>

    );
    
  }

  getWorkDaysPersonWeek(id) {

    let count = 0;

    if (typeof this.state.infoExtra[id] === 'undefined') {
      return 0;
    }

    if (this.state.infoExtra[id].info2_werkdag_maandag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_dinsdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_woensdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_donderdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_vrijdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zaterdag === '1') {
      count++;
    }
    if (this.state.infoExtra[id].info2_werkdag_zondag === '1') {
      count++;
    }

    return count;

  }

  calcSickHours(userID, _week, year) {

    let absents = [];
    let totalAbsents = 0;

    let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 60 * 60 * 1000) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 60 * 60 * 1000) : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren) * 60 * 60 * 1000);

    // SICK HOURS
    if (Constants.isTrue(Data.data.appOptions.clock)) {

      let foundSchedulesAbsence = [];

      if (Constants.isEmpty(this.state.schedule2[userID]) === false && Constants.isEmpty(this.state.schedule2[userID][year]) === false && Constants.isEmpty(this.state.schedule2[userID][year][_week.toString()]) === false) {

        for (const sch of this.state.schedule2[userID][year][_week.toString()]) {

          const date = Constants.stringToDate(sch.rooster_datum);
          const contract = Constants.getCurrentContract(this.state.contractsObj[sch.info_id], date);

          if (contract !== null) {

            // SET NEW WORKDAYHOURS LIKE CONTRACT
            if (Constants.isTrue(contract.contract_vaste_dagen)) {

              workDayHours = date.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                          : date.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                          : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

            } else {

              workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren) * 60 * 60 * 1000) : workDayHours;

            }

          }

          if (Constants.isEmpty(sch.afwr_id) === false && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
            foundSchedulesAbsence.push(sch);
            absents.push({ date: date, ms: workDayHours });
          }

        }

      }

      totalAbsents = foundSchedulesAbsence.length > 0 ? (workDayHours * foundSchedulesAbsence.length) : 0;

    }

    return { absents: absents, totalAbsents: totalAbsents };

  }

  calcAbsAmount(userID, _week, year, totalWorkedWeek) {

    let absence = [];
    let totalAbsence = 0;
    let fullWeekHours = 0;
    const chosenWeek = parseInt(_week);

    // ABSAMOUNT
    if (Constants.isEmpty(this.state.absAmount2[userID]) === false && Constants.isEmpty(this.state.absAmount2[userID][year]) === false) {

      for (const weekKey in this.state.absAmount2[userID][year]) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        for (const abs of this.state.absAmount2[userID][year][weekKey]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          const contract = Constants.getCurrentContract(this.state.contractsObj[abs.info_id], absStart) === null ? Constants.getCurrentContract(this.state.contractsObj[abs.info_id], absEnd) : Constants.getCurrentContract(this.state.contractsObj[abs.info_id], absStart);

          // SET NEW WORKDAYHOURS LIKE CONTRACT
          workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

          let fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;

          if (sameDay) {
            if (chosenWeek === parseInt(weekKey) && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
              // SET NEW WORKDAYHOURS LIKE CONTRACT
              if (Constants.isEmpty(contract) === false && Constants.isTrue(contract.contract_vaste_dagen)) {
                workDayHours = absStart.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                              : absStart.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                              : absStart.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                              : absStart.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                              : absStart.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                              : absStart.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                              : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
              }
              // NEW REAL CALC ABSENCE
              if (Constants.objectArrayContainsKey(absence, 'date', absStart) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60))) < parseFloat(contract.contract_uren)) {
                absence.push({ date: absStart, ms: workDayHours * 60 * 60 * 1000 });
                totalAbsence += workDayHours * 60 * 60 * 1000;
              }
              // absence.push({ date: absStart, ms: parseFloat(abs.vs_aantal) * 60 * 60 * 1000 });
              // totalAbsence += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
              // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            }
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
              
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[userID]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (Constants.personWorksDay(infoExtra[userID], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                } 
                // else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (absWeek[week] < parseFloat(contract.contract_uren) && Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
              
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(abs.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  // fullWeekHours -= workDayHours;

                  // if (fullWeekHours < parseFloat(contract.contract_uren)) {

                  //   if (Constants.personHasWorkDays(infoExtra[userID]) === true) {

                  //     // person has working days, check work days personal and general
                  //     if (Constants.personWorksDay(infoExtra[userID], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                  //       absWeek[week] += workDayHours;
                  //       if (week === chosenWeek) {
                  //         absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //         totalAbsence += workDayHours * 60 * 60 * 1000;
                  //       }
                  //     }

                  //   } else { // just count the days
                  //     absWeek[week] += workDayHours;
                  //     if (week === chosenWeek) {
                  //       absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                  //       totalAbsence += workDayHours * 60 * 60 * 1000;
                  //     }
                  //   }

                  // }

                } 
                // else { // just count all days
                //   absWeek[week] += workDayHours;
                //   if (week === chosenWeek) {
                //     absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                //     totalAbsence += workDayHours * 60 * 60 * 1000;
                //   }
                // }

                // NEW REAL CALC ABSENCE
                if (absWeek[week] < parseFloat(contract.contract_max_uren) && Constants.objectArrayContainsKey(absence, 'date', currentDate) === false && (Constants.round((totalWorkedWeek / 1000 / 60 / 60)) + absWeek[week]) < parseFloat(contract.contract_uren)) {
                  absWeek[week] += workDayHours;
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    absence.push({ date: currentDate, ms: workDayHours * 60 * 60 * 1000 });
                    totalAbsence += workDayHours * 60 * 60 * 1000;
                  }
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                  if (week === chosenWeek && (parseInt(year) < new Date().getFullYear() || parseInt(year) === new Date().getFullYear() && parseInt(weekKey) < Constants.getWeekNumber())) {
                    totalAbsence = parseFloat(contract.contract_uren) * 1000 * 60 * 60;
                  }
                }
                
              }

              index++;

            }

          }

        }

      }

    }

    return { absence: absence, totalAbsence: totalAbsence };

  }

  calcOverhoursN(hours, contract, hoursWorkedWeek, totalOverhoursLocal) {

    const _startDate = Constants.stringToDate(hours.uren_begindatum);

    let _workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? (8 * 1000 * 60 * 60) : Data.data.appOptions.opties_dag_werkuren === '0' ? (8 * 1000 * 60 * 60) : (parseFloat(Data.data.appOptions.opties_dag_werkuren) * 1000 * 60 * 60);
    let _workDayHoursFloat = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : parseFloat(Data.data.appOptions.opties_dag_werkuren);

    let overhours = null;
    let overhoursCurrent = null;
    let overhoursCorrection = null;
    let overhoursContract = 0;
    let negative = false;
    let negativeCurrent = false;
    let contract_hours = null;
    let contract_max_hours = null;
    let foundUserHoursWeek = false;

    let realOverhoursCurrent = null;
    let realNegativeCurrent = null;

    let workedThisDay = 0;
    let workedThisDayAbsence = 0;
    // let absenceHoursThisDay = 0;

    if (Constants.isTrue(Data.data.appOptions.opties_overuren) === false) {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,
        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,
        overhoursCorrection: overhoursCorrection,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

    if (contract !== null) {

      // SET NEW WORKDAYHOURS LIKE CONTRACT
      _workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? (parseFloat(contract.contract_werkdag_uren) * 1000 * 60 * 60) : _workDayHours;
      _workDayHoursFloat = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? parseFloat(contract.contract_werkdag_uren) : _workDayHoursFloat;

      // CALC CURRENT OVERHOURS (THIS DAY)
      const start = Constants.stringToDateTime(hours.uren_begindatum, hours.uren_begin);
      const end = Constants.stringToDateTime(hours.uren_einddatum, hours.uren_eind);
      const diff = end.getTime() - start.getTime() - Constants.timeStringToMS(hours.uren_pauze);

      if (start !== null && end !== null && diff !== null) {

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        if (Constants.isTrue(contract.contract_vaste_dagen)) {

          _workDayHours = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? (parseFloat(contract.contract_maandag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? (parseFloat(contract.contract_dinsdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? (parseFloat(contract.contract_woensdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? (parseFloat(contract.contract_donderdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? (parseFloat(contract.contract_vrijdag) * 1000 * 60 * 60) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? (parseFloat(contract.contract_zaterdag) * 1000 * 60 * 60) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? (parseFloat(contract.contract_zondag) * 1000 * 60 * 60) : 0);

          _workDayHoursFloat = start.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                        : start.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                        : start.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                        : start.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                        : start.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                        : start.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                        : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);

        }

        overhoursCurrent = (diff - _workDayHours) < 0 ? (_workDayHours - diff) : (diff - _workDayHours);
        negativeCurrent = (diff - _workDayHours) < 0;

        workedThisDay = diff;
        workedThisDayAbsence = _workDayHours;

      }

      if (isNaN(overhoursCurrent) === true || overhoursCurrent === 0) {
        overhoursCurrent = null;
      }

      // get current chosen week
      const chosenWeek = Constants.getWeekNumber2(_startDate);

      // get total worktime this week
      let totalHoursWeek = 0;

      // found hours objects this week
      let foundDBHoursArray = [];
      let foundExtraHoursArray = [];

      // normals
      for (const hours2 of hoursWorkedWeek) {
        //if (hours2.info_id === hours.info_id && (hours2.uren_goedgekeurd === '1' || hours2.uren_begindatum === hours.uren_begindatum)) {
          const startHours2 = Constants.stringToDateTime(hours2.uren_begindatum, hours2.uren_begin);
          const endHours2 = Constants.stringToDateTime(hours2.uren_einddatum, hours2.uren_eind);
          const breakHours2 = Constants.timeStringToMS(hours2.uren_pauze);
          //if (startHours2 < _startDate || hours2.uren_begindatum === hours.uren_begindatum) { // <
            foundUserHoursWeek = true;
            foundDBHoursArray.push(hours2);
            totalHoursWeek += Constants.round(((endHours2 - startHours2) - breakHours2));
          //}
        //}
      }

      // SICK HOURS
      if (Constants.isTrue(Data.data.appOptions.clock)) {

        let foundSchedulesAbsence = [];

        if (Constants.isEmpty(this.state.schedule2[hours.info_id]) === false && Constants.isEmpty(this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

          for (const sch of this.state.schedule2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) {

            if (sch.rooster_info_id === (hours.info_id)) {

              if (Constants.isEmpty(sch.afwr_id) === false && Constants.objectArrayContainsKey(foundSchedulesAbsence, 'rooster_datum', sch.rooster_datum) === false) {
                foundSchedulesAbsence.push(sch);
              }

            }

          }

        }

        totalHoursWeek += foundSchedulesAbsence.length > 0 ? (_workDayHours * foundSchedulesAbsence.length) : 0;

      }

      // ABSAMOUNT
      if (Constants.isEmpty(this.state.absAmount2[hours.info_id]) === false && Constants.isEmpty(this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()]) === false && Constants.isEmpty(this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) === false) {

        let workDayHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === true ? 8 : Data.data.appOptions.opties_dag_werkuren === '0' ? 8 : Constants.round(parseFloat(Data.data.appOptions.opties_dag_werkuren));

        // SET NEW WORKDAYHOURS LIKE CONTRACT
        workDayHours = Constants.isEmpty(contract) === false && Constants.isEmpty(contract.contract_werkdag_uren) === false && contract.contract_werkdag_uren !== '0' ? Constants.round(parseFloat(contract.contract_werkdag_uren)) : workDayHours;

        let fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;

        for (const abs of this.state.absAmount2[hours.info_id][_startDate.getFullYear().toString()][chosenWeek.toString()]) {

          const absStart = Constants.stringToDate(abs.vakantie_begin);
          const absEnd = Constants.stringToDate(abs.vakantie_eind);
          const sameDay = abs.vakantie_begin === abs.vakantie_eind;

          if (sameDay) {
            totalHoursWeek += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
            // absenceHoursThisDay += parseFloat(abs.vs_aantal) * 60 * 60 * 1000;
          } else {

            const infoExtra = this.state.infoExtra;
            let week = Constants.getWeekNumber2(absStart);
            let absWeek = {};

            let index = 0;
            while(new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index).getTime() <= absEnd.getTime()) {

              const currentDate = new Date(absStart.getFullYear(), absStart.getMonth(), absStart.getDate() + index);
            
              if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '2') {

                // SET NEW WORKDAYHOURS LIKE CONTRACT
                if (Constants.isTrue(contract.contract_vaste_dagen)) {
                  workDayHours = currentDate.getDay() === 1 ? (Constants.isEmpty(contract.contract_maandag) === false && isNaN(parseFloat(contract.contract_maandag)) === false ? parseFloat(contract.contract_maandag) : 0)
                                : currentDate.getDay() === 2 ? (Constants.isEmpty(contract.contract_dinsdag) === false && isNaN(parseFloat(contract.contract_dinsdag)) === false ? parseFloat(contract.contract_dinsdag) : 0)
                                : currentDate.getDay() === 3 ? (Constants.isEmpty(contract.contract_woensdag) === false && isNaN(parseFloat(contract.contract_woensdag)) === false ? parseFloat(contract.contract_woensdag) : 0)
                                : currentDate.getDay() === 4 ? (Constants.isEmpty(contract.contract_donderdag) === false && isNaN(parseFloat(contract.contract_donderdag)) === false ? parseFloat(contract.contract_donderdag) : 0)
                                : currentDate.getDay() === 5 ? (Constants.isEmpty(contract.contract_vrijdag) === false && isNaN(parseFloat(contract.contract_vrijdag)) === false ? parseFloat(contract.contract_vrijdag) : 0)
                                : currentDate.getDay() === 6 ? (Constants.isEmpty(contract.contract_zaterdag) === false && isNaN(parseFloat(contract.contract_zaterdag)) === false ? parseFloat(contract.contract_zaterdag) : 0)
                                : (Constants.isEmpty(contract.contract_zondag) === false && isNaN(parseFloat(contract.contract_zondag)) === false ? parseFloat(contract.contract_zondag) : 0);
                }

                // if (Constants.dateToString(currentDate) === hours.uren_begindatum) {
                //   absenceHoursThisDay += workDayHours * 60 * 60 * 1000;
                // }
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (Constants.personWorksDay(infoExtra[hours.info_id], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              } else if (contract !== null && Constants.isTrue(contract.contract_opbouw_verlof) === true && contract.contract_type === '1') {
            
                if (Constants.hasGeneralWorkDays() === true) {

                  // get week at absStart, calc days by contract (contracthours / workdayhours), days++ if weeknumber is same, else days = 0 and count freehours

                  if (week !== Constants.getWeekNumber2(currentDate)) {
                    week = Constants.getWeekNumber2(currentDate);
                    fullWeekHours = this.getWorkDaysPersonWeek(hours.info_id) * workDayHours;
                  }

                  if (typeof absWeek[week] === 'undefined') {
                    absWeek[week] = 0;
                  }

                  fullWeekHours -= workDayHours;

                  if (fullWeekHours < parseFloat(contract.contract_uren)) {

                    if (Constants.personHasWorkDays(infoExtra[hours.info_id]) === true) {

                      // person has working days, check work days personal and general
                      if (Constants.personWorksDay(infoExtra[hours.info_id], currentDate.getDay()) || this.getWorkDaysPersonWeek(abs.info_id) === 0) {
                        absWeek[week] += workDayHours;
                      }

                    } else { // just count the days
                      absWeek[week] += workDayHours;
                    }

                  }

                } else { // just count all days
                  absWeek[week] += workDayHours;
                }

                if (absWeek[week] > parseFloat(contract.contract_uren)) {
                  absWeek[week] = parseFloat(contract.contract_uren);
                }
              
              }

              index++;

            }

            if (Object.keys(absWeek).length > 0) {
              for (let key in absWeek) {
                if (chosenWeek === parseInt(key)) {
                  totalHoursWeek += (absWeek[key] * 60 * 60 * 1000);
                }
              }
            }

          }

        }

      }

      realOverhoursCurrent = overhoursCurrent;
      realNegativeCurrent = negativeCurrent;

      // calc
      if (Constants.isTrue(contract.contract_vaste_dagen) === false) {
        
        if (contract.contract_type === '1') {

          contract_max_hours = parseFloat(contract.contract_max_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) > contract_max_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_max_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        } else if (contract.contract_type === '2') {

          contract_hours = parseFloat(contract.contract_uren) * 60 * 60 * 1000;

          if ((totalHoursWeek) >= contract_hours) {

            let overhoursWeek = totalOverhoursLocal;

            const totalOverhoursweek = (overhoursWeek);
            const _totalHours = (totalHoursWeek);

            const _totalMinusTotalOverhours = _totalHours - (contract_hours) - totalOverhoursweek;

            overhoursCurrent = (_totalMinusTotalOverhours);

            negativeCurrent = overhoursCurrent < 0;
            overhoursCurrent = Math.abs(overhoursCurrent);

          }

        }

      }

    }

    if (Constants.isEmpty(contract) === true || Constants.isEmpty(contract.contract_type) === true || contract.contract_type === '0') {

      return {
        overhoursTotal: '00:00',
        isNegativeTotal: false,

        overhoursCurrent: null,
        isNegativeCurrent: false,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: 0,
        // overhours this week
      };

    } else {

      return {
        overhoursTotal: overhours,
        isNegativeTotal: negative,

        overhoursCurrent: overhoursCurrent,
        isNegativeCurrent: negativeCurrent,

        overhoursCorrection: null,

        realOverhoursCurrent: realOverhoursCurrent,
        realNegativeCurrent: realNegativeCurrent,

        workedThisDay: workedThisDay,
        workedThisDayAbsence: workedThisDayAbsence,
        // absenceHoursThisDay: absenceHoursThisDay,

        // overhoursContract: overhoursContract,
        // overhours this week
      };

    }

  }

  getOverhoursUser(userID) {

    let count = 0;

    const currentWeekCalc = parseInt(this.YEAR) + parseInt(this.WEEK);

    if (Constants.isEmpty(this.state.hoursWorked[userID]) === false) {

      const user = this.state.hoursWorked[userID];

      let yearOverhoursData = {};

      for (const yearKey in user.data) {

        if (Constants.isEmpty(yearOverhoursData[yearKey])) {
          yearOverhoursData[yearKey] = 0;
        }

        const userOverhoursYear = user.data[yearKey];

        if (Constants.isEmpty(userOverhoursYear) === false) {

          const startWeek = userOverhoursYear[userOverhoursYear.length - 1].week;
          let endWeek = Constants.getWeekNumber2(new Date());

          if (new Date().getFullYear() > parseInt(yearKey)) {
            endWeek = userOverhoursYear[0].week;
          }

           // loop from start to current week
           for (let i = endWeek; i >= startWeek; i--) {

            const week = i;
            const _now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
            const monday = Constants.getMonday(i, yearKey);

            // should calc this week?
            const weekCalc = parseInt(yearKey) + parseInt(week);
            if ((weekCalc >= currentWeekCalc) && (parseInt(yearKey) >= parseInt(this.YEAR))) {
              continue;
            }

            const showDetails = Constants.arrayContains(this.state.showWeekDetails, i);

            let daysTotal = { '1': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '2': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '3': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '4': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '5': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '6': { realOverhours: 0, worked: 0, absence: 0, absent: 0 }, '7': { realOverhours: 0, worked: 0, absence: 0, absent: 0 } };
            let totalHours = 0;
            let realTotalHours = 0;
            let totalWorkedHours = 0;
            let totalWorkedHoursAbsence = 0;
            let currentContract = null;
            let correction = 0;

            // CONTRACT
            let contract = Constants.getCurrentContract(this.state.contractsObj[user.user], monday);
            contract = contract !== null && contract.contract_type !== '0' && Constants.isEmpty(contract.contract_type) === false ? contract : null;

            if (contract !== null) {
              currentContract = contract;
            } else {
              continue;
            }

            //
            //
            // yearData
            const yearData = Constants.getArrayItem(userOverhoursYear, 'week', i);

            // calc hours and overhours and correction
            if (Constants.isEmpty(yearData) === false) {

              // ARRAY OF DAYS
              const weekData = yearData.weekData;

              // HOURS DAY DATA
              for (const dayData of weekData) { // days in week array

                const date = Constants.stringToDate(dayData.uren_begindatum);

                // if (date > _now) {
                //   continue;
                // }

                const calcOverhours = this.calcOverhoursN(dayData, contract, weekData, totalHours);

                daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].realOverhours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
                daysTotal[date.getDay() === 0 ? '7' : date.getDay().toString()].worked += calcOverhours.workedThisDay;

                totalHours += isNaN(calcOverhours.overhoursCurrent) ? 0 : calcOverhours.isNegativeCurrent ? -calcOverhours.overhoursCurrent : calcOverhours.overhoursCurrent;
                realTotalHours += isNaN(calcOverhours.realOverhoursCurrent) ? 0 : calcOverhours.realNegativeCurrent ? -calcOverhours.realOverhoursCurrent : calcOverhours.realOverhoursCurrent;
                totalWorkedHours += calcOverhours.workedThisDay;
                totalWorkedHoursAbsence += calcOverhours.workedThisDayAbsence;

              }

            }

            // CALC ABSENT AND ABSENCES
            const calcAbsAmount = this.calcAbsAmount(user.user, i, yearKey, totalWorkedHoursAbsence);
            const calcSickHours = this.calcSickHours(user.user, i, yearKey);

            let totalAbsence = calcAbsAmount.totalAbsence;
            let totalAbsent = calcSickHours.totalAbsents;

            // ABS DAY DATA
            for (const abs of calcAbsAmount.absence) {
              daysTotal[abs.date.getDay() === 0 ? '7' : abs.date.getDay().toString()].absence += isNaN(abs.ms) ? 0 : abs.ms;
            } 

            // SICK DAY DATA
            for (const sick of calcSickHours.absents) {
              daysTotal[sick.date.getDay() === 0 ? '7' : sick.date.getDay().toString()].absent += isNaN(sick.ms) ? 0 : sick.ms;
            } 

            if (currentContract !== null) {

              // show the correction incase of confusion
              correction += (totalHours - realTotalHours);

              // fix minus hours
              if (currentContract !== null && (week !== Constants.getWeekNumber2(_now) || Constants.isTrue(Data.data.appOptions.clock) === false)) {
                if (currentContract.contract_type === '2') {
                  const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                  if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - realTotalHours;
                  }
                } else if (currentContract.contract_type === '1') {
                  const contract_hours = parseFloat(currentContract.contract_uren) * 60 * 60 * 1000;
                  const contract_max_hours = parseFloat(currentContract.contract_max_uren) * 60 * 60 * 1000;
                  if ((totalWorkedHours + totalAbsent + totalAbsence) < contract_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_hours;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) >= contract_hours && (totalWorkedHours + totalAbsent + totalAbsence) <= contract_max_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = 0;
                    correction = totalHours - oldTotalHours;
                  } else if ((totalWorkedHours + totalAbsent + totalAbsence) > contract_max_hours) {
                    const oldTotalHours = totalHours;
                    totalHours = (totalWorkedHours + totalAbsent + totalAbsence) - contract_max_hours;
                    correction = totalHours - realTotalHours;
                  }
                }
              }

              // fix correction negative
              const correctionNegative = correction < 0;
              correction = Math.abs(correction);

              yearOverhoursData[yearKey] += totalHours;

            }

          }

        }

      }

      let startBalance = 0;
      let balanceNow = 0;
      let balanceEnd = 0;

      const _yearNow = new Date().getFullYear();

      for (const yearKey in yearOverhoursData) {
        const overhoursAmount = yearOverhoursData[yearKey];
        if (parseInt(yearKey) <= _yearNow) {
          balanceEnd += overhoursAmount;
        }
        if (parseInt(yearKey) === _yearNow) {
          balanceNow += overhoursAmount;
        }
        if (parseInt(yearKey) < _yearNow) {
          startBalance += overhoursAmount;
        }
      }

      // CORRECTIONS
      if (Constants.isEmpty(this.state.overhours_corrections[user.user]) === false) {
        for (const yearKey in this.state.overhours_corrections[user.user]) {
          const overhoursAmount = (this.state.overhours_corrections[user.user][yearKey].total * 60 * 60 * 1000);
          if (parseInt(yearKey) <= _yearNow) {
            balanceEnd += overhoursAmount;
          }
          if (parseInt(yearKey) === _yearNow) {
            balanceNow += overhoursAmount;
          }
          if (parseInt(yearKey) < _yearNow) {
            startBalance += overhoursAmount;
          }
        }
      }

      return {
        startBalance: startBalance,
        balanceNow: balanceNow,
        balanceEnd: balanceEnd,
      };

    }

    return null;

  }

  getAllUsersTableDisplay() {

    let toReturn = [];

    let list = this.users;
    let fid = '';
    let usersWithoutFunction = false;

    // NEW ARRANGEMENT
    if (this.state.plannerArrange.length > 0) {

      for (const arr of this.state.plannerArrange) {

        for (const user of list) {

          if (user.info_id === arr.pv_info_id && Constants.objectArrayContainsKey(list, 'info_id', arr.pv_info_id)) {
            let index = list.indexOf(user);
            list.splice(index, 1);
            list.splice(parseInt(arr.pv_index), 0, user);
          }

        }

      }

    }

    // USERS WITH FUNCTION
    for (const func of this.functionsData) {

      let functionHasUsers = false;

      const funcIndex = this.functionsData.indexOf(func);

      if (fid !== func.functie_id) {

        if (this.state.display === 'TABLE') {

          toReturn.push(
            <div style={{position: 'relative',display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '1.5vw', backgroundColor: Constants.hexToRgbA(`#${func.functie_kleur}`, 0.5), borderRadius: 5, paddingLeft: 4, marginTop: 8, marginBottom: 5}}>
              {this.state.sortFunctions === true ?
                <div>
                  <i className={'fas fa-angle-up planner_sortIcon_table'} onClick={() => this.sortUp(funcIndex)} />
                  <i className={'fas fa-angle-down planner_sortIconDown_table'} onClick={() => this.sortDown(funcIndex)} />
                </div>
              : null}
              <span style={{color: Constants.getContrast(func.functie_kleur), fontSize: '0.7vw'}}>{func.functie_naam}</span>
            </div>
          );

        } else {

          toReturn.push(
            <div style={{position: 'relative',display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '1vw', backgroundColor: Constants.hexToRgbA(`#${func.functie_kleur}`, 0.5), borderRadius: 3, paddingLeft: 3, marginTop: 8, marginBottom: 5}}>
              {this.state.sortFunctions === true ?
                <div>
                  <i className={'fas fa-angle-up planner_sortIcon_table'} onClick={() => this.sortUp(funcIndex)} />
                  <i className={'fas fa-angle-down planner_sortIconDown_table'} onClick={() => this.sortDown(funcIndex)} />
                </div>
              : null}
              <span style={{color: Constants.getContrast(func.functie_kleur), fontSize: '0.7vw'}}>{func.functie_naam}</span>
            </div>
          );

        }

        fid = func.functie_id;

      }

      for (const user of list) {

        if (user.functie_id === null) {
          usersWithoutFunction = true;
          continue;
        }

        if (user.functie_id === func.functie_id) {

          functionHasUsers = true;
  
          const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
    
          let monday = [];
          let tuesday = [];
          let wednesday = [];
          let thursday = [];
          let friday = [];
          let saturday = [];
          let sunday = [];

          let planned = {
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false
          };

          let highest = {
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
            sun: 0,
          };

          // if has team, show shift it as other team
          if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
    
            let index = 0;

            // find all plans of user
            for (const key in this.allPlannerData) {

              const day = key === 'mon' ? monday : key === 'tue' ? tuesday : key === 'wed' ? wednesday : key === 'thu' ? thursday : key === 'fri' ? friday : key === 'sat' ? saturday : key === 'sun' ? sunday : monday;
              let foundPlans = [];
              let allPlans = [];

              for (const planAll of this.allPlannerData[key]) {
                if (planAll.info_id === user.info_id) {

                  allPlans.push(planAll);

                  for (const planTeam of this.plannerData[key]) {
                    if (planTeam.rooster_id === planAll.rooster_id) {
                      foundPlans.push(planTeam);
                    }
                  }
                }
              }

              // iterate through all found plans by user and check if they exist in team planner
              for (const allPlanUser of allPlans) {
                let found = false;
                for (const foundPlanUser of foundPlans) {
                  if (foundPlanUser.rooster_id === allPlanUser.rooster_id) {
                    found = true;
                    break;
                  }
                }

                if (found === false) {
                  day.push(this.getOtherTeamTouchBoxTable(index, day.length > 0, allPlanUser));
                  highest[key] += 1;
                  index++;
                }

              }

            }
    
          }
    
          // PLANNER
    
          this.plannerData.mon.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
          this.plannerData.tue.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
          this.plannerData.wed.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
          this.plannerData.thu.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
          this.plannerData.fri.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
          this.plannerData.sat.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
          this.plannerData.sun.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
    
          for (const planner of this.plannerData.mon) {
            if (planner.info_id === user.info_id) {
              monday.push(this.getPlannerTouchBoxTable(planner, 0, monday.length > 0));
              planned.mon = true;
            }
          }
          for (const planner of this.plannerData.tue) {
            if (planner.info_id === user.info_id) {
              tuesday.push(this.getPlannerTouchBoxTable(planner, 1, tuesday.length > 0));
              planned.tue = true;
            }
          }
          for (const planner of this.plannerData.wed) {
            if (planner.info_id === user.info_id) {
              wednesday.push(this.getPlannerTouchBoxTable(planner, 2, wednesday.length > 0));
              planned.wed = true;
            }
          }
          for (const planner of this.plannerData.thu) {
            if (planner.info_id === user.info_id) {
              thursday.push(this.getPlannerTouchBoxTable(planner, 3, thursday.length > 0));
              planned.thu = true;
            }
          }
          for (const planner of this.plannerData.fri) {
            if (planner.info_id === user.info_id) {
              friday.push(this.getPlannerTouchBoxTable(planner, 4, friday.length > 0));
              planned.fri = true;
            }
          }
          for (const planner of this.plannerData.sat) {
            if (planner.info_id === user.info_id) {
              saturday.push(this.getPlannerTouchBoxTable(planner, 5, saturday.length > 0));
              planned.sat = true;
            }
          }
          for (const planner of this.plannerData.sun) {
            if (planner.info_id === user.info_id) {
              sunday.push(this.getPlannerTouchBoxTable(planner, 6, sunday.length > 0));
              planned.sun = true;
            }
          }

          for (const key in this.plannerData) {
            for (const shift of this.plannerData[key]) {
              if (shift.info_id === user.info_id) {
                highest[key] += 1;
              }
            }
          }

          let highestLength = 0;
          for (const key in highest) {
            if (highest[key] > highestLength) {
              highestLength = highest[key];
            }
          }

          if (highestLength === 0) {
            highestLength = highestLength + 1;
          }

          highestLength = highestLength + this.state.addEmptyRowsUser[user.info_id];

          // HOLIDAYS
            //if (monday.length === 0) {
              for (const key in this.holidayDaysData.mon) {
      
                const planner = this.holidayDaysData.mon[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.mondayDate) {

                  for (let i = monday.length; i < highestLength; i++) {
                    monday.push(this.getHolidayTouchBoxTable(planner, 0, key + i, monday.length > 0));
                  }

                  if (monday.length === 0) {
                    monday.push(this.getHolidayTouchBoxTable(planner, 0, key));
                  }
      
                }
      
              }
            //}
            //if (tuesday.length === 0) {
              for (const key in this.holidayDaysData.tue) {
      
                const planner = this.holidayDaysData.tue[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.tuesdayDate) {
      
                  for (let i = tuesday.length; i < highestLength; i++) {
                    tuesday.push(this.getHolidayTouchBoxTable(planner, 1, key + i, tuesday.length > 0));
                  }

                  if (tuesday.length === 0) {
                    tuesday.push(this.getHolidayTouchBoxTable(planner, 1, key));
                  }
      
                }
      
              }
            //}
            //if (wednesday.length === 0) {
              for (const key in this.holidayDaysData.wed) {
      
                const planner = this.holidayDaysData.wed[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.wednesdayDate) {
      
                  for (let i = wednesday.length; i < highestLength; i++) {
                    wednesday.push(this.getHolidayTouchBoxTable(planner, 2, key + i, wednesday.length > 0));
                  }

                  if (wednesday.length === 0) {
                    wednesday.push(this.getHolidayTouchBoxTable(planner, 2, key));
                  }
      
                }
      
              }
           // }
            //if (thursday.length === 0) {
              for (const key in this.holidayDaysData.thu) {
      
                const planner = this.holidayDaysData.thu[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.thursdayDate) {
      
                  for (let i = thursday.length; i < highestLength; i++) {
                    thursday.push(this.getHolidayTouchBoxTable(planner, 3, key + i, thursday.length > 0));
                  }

                  if (thursday.length === 0) {
                    thursday.push(this.getHolidayTouchBoxTable(planner, 3, key));
                  }
      
                }
      
              }
            //}
            //if (friday.length === 0) {
              for (const key in this.holidayDaysData.fri) {
      
                const planner = this.holidayDaysData.fri[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.fridayDate) {
      
                  for (let i = friday.length; i < highestLength; i++) {
                    friday.push(this.getHolidayTouchBoxTable(planner, 4, key + i, friday.length > 0));
                  }

                  if (friday.length === 0) {
                    friday.push(this.getHolidayTouchBoxTable(planner, 4, key));
                  }
      
                }
      
              }
            //}
            //if (saturday.length === 0) {
              for (const key in this.holidayDaysData.sat) {
      
                const planner = this.holidayDaysData.sat[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.saturdayDate) {
      
                  for (let i = saturday.length; i < highestLength; i++) {
                    saturday.push(this.getHolidayTouchBoxTable(planner, 5, key + i, saturday.length > 0));
                  }

                  if (saturday.length === 0) {
                    saturday.push(this.getHolidayTouchBoxTable(planner, 5, key));
                  }
      
                }
      
              }
            //}
            //if (sunday.length === 0) {
              for (const key in this.holidayDaysData.sun) {
      
                const planner = this.holidayDaysData.sun[key];
      
                if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.sundayDate) {
      
                  for (let i = sunday.length; i < highestLength; i++) {
                    sunday.push(this.getHolidayTouchBoxTable(planner, 6, key + i, sunday.length > 0));
                  }

                  if (sunday.length === 0) {
                    sunday.push(this.getHolidayTouchBoxTable(planner, 6, key));
                  }
      
                }
      
              }
            //}

          // WORK DAYS
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_maandag === '0') {
            for (let i = monday.length; i < highestLength; i++) {
              monday.push(this.getNonWorkdayTouchboxTable(1 + i, monday.length > 0));
            }
            if (monday.length === 0) {
              monday.push(this.getNonWorkdayTouchboxTable(1, monday.length > 0));
            }
          }
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_dinsdag === '0') {
            for (let i = tuesday.length; i < highestLength; i++) {
              tuesday.push(this.getNonWorkdayTouchboxTable(2 + i, tuesday.length > 0));
            }
            if (tuesday.length === 0) {
              tuesday.push(this.getNonWorkdayTouchboxTable(2, tuesday.length > 0));
            }
          }
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_woensdag === '0') {
            for (let i = wednesday.length; i < highestLength; i++) {
              wednesday.push(this.getNonWorkdayTouchboxTable(3 + i, wednesday.length > 0));
            }
            if (wednesday.length === 0) {
              wednesday.push(this.getNonWorkdayTouchboxTable(3, wednesday.length > 0));
            }
          }
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_donderdag === '0') {
            for (let i = thursday.length; i < highestLength; i++) {
              thursday.push(this.getNonWorkdayTouchboxTable(4 + i, thursday.length > 0));
            }
            if (thursday.length === 0) {
              thursday.push(this.getNonWorkdayTouchboxTable(4, thursday.length > 0));
            }
          }
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_vrijdag === '0') {
            for (let i = friday.length; i < highestLength; i++) {
              friday.push(this.getNonWorkdayTouchboxTable(5 + i, friday.length > 0));
            }
            if (friday.length === 0) {
              friday.push(this.getNonWorkdayTouchboxTable(5, friday.length > 0));
            }
          }
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_zaterdag === '0') {
            for (let i = saturday.length; i < highestLength; i++) {
              saturday.push(this.getNonWorkdayTouchboxTable(6 + i, saturday.length > 0));
            }
            if (saturday.length === 0) {
              saturday.push(this.getNonWorkdayTouchboxTable(6, saturday.length > 0));
            }
          }
          if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_zondag === '0') {
            for (let i = sunday.length; i < highestLength; i++) {
              sunday.push(this.getNonWorkdayTouchboxTable(0 + i, sunday.length > 0));
            }
            if (sunday.length === 0) {
              sunday.push(this.getNonWorkdayTouchboxTable(0, sunday.length > 0));
            }
          }

          // REPEATING SHIFTS
          if (Constants.isEmpty(this.repeatingShifts[user.info_id]) === false) {
            for (const r of this.repeatingShifts[user.info_id]) {
              for (let i = 0; i < 7; i++) {

                const array = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;
                const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

                const date1 = i === 1 ? Constants.stringToDate(this.state.mondayDate) : i === 2 ? Constants.stringToDate(this.state.tuesdayDate) : i === 3 ? Constants.stringToDate(this.state.wednesdayDate) : i === 4 ? Constants.stringToDate(this.state.thursdayDate) : i === 5 ? Constants.stringToDate(this.state.fridayDate) : i === 6 ? Constants.stringToDate(this.state.saturdayDate) : Constants.stringToDate(this.state.sundayDate);
                const date2 = Constants.stringToDate(r.hr_startdatum);
                const date3 = Constants.isEmpty(r.hr_einddatum) === false ? Constants.stringToDate(r.hr_einddatum) : null;
                const interval = parseInt(r.hr_interval);
                const calc1 = r.hr_type === 'day' ? date1.getDate() : r.hr_type === 'week' ? Constants.getWeekNumber2(date1) : r.hr_type === 'month' ? date1.getMonth() : date1.getFullYear();
                const calc2 = r.hr_type === 'day' ? date2.getDate() : r.hr_type === 'week' ? Constants.getWeekNumber2(date2) : r.hr_type === 'month' ? date2.getMonth() : date2.getFullYear();
                const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
                const isInInterval = r.hr_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hr_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hr_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
                
                if (isInInterval && date1 >= date2 && ((date3 !== null && date1 <= date3) || date3 === null)) {

                  if (array.length === 0) {
                    array.push(this.getRepeatShiftTouchBoxTable(i, array.length > 0, r));
                  }

                }

              }
            }
          }
    
          if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
    
            // AVAILABILITY

            for (const dayKey in this.availableData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.availableData[dayKey]) {
                  const planner = this.availableData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getAvailabilityTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // UNAVAILABLE

            for (const dayKey in this.unavailableData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.unavailableData[dayKey]) {
                  const planner = this.unavailableData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getUnavailabilityTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // NOTFILLED

            for (const dayKey in this.notFilledData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.notFilledData[dayKey]) {
                  const planner = this.notFilledData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getNotFilledTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
          }

          if (Data.data.appOptions.availability === 'STANDARD') {
    
            // AVAILABILITY

            for (const dayKey in this.standardAvailableData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              //for (let i = day.length; i < highestLength; i++) {
              if (day.length === 0) {
                for (const planKey in this.standardAvailableData[dayKey]) {
                  const planner = this.standardAvailableData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getStandardAvailableTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // UNAVAILABLE

            for (const dayKey in this.standardUnavailableData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              // for (let i = day.length; i < highestLength; i++) {
              if (day.length === 0) {
                for (const planKey in this.standardUnavailableData[dayKey]) {
                  const planner = this.standardUnavailableData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getStandardUnavailableTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // NOTFILLED

            for (const dayKey in this.standardNotFilledData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              // for (let i = day.length; i < highestLength; i++) {
              if (day.length === 0) {
                for (const planKey in this.standardNotFilledData[dayKey]) {
                  const planner = this.standardNotFilledData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getStandardNotFilledTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
          }
    
          if(Data.data.appOptions.availability === 'SCHOOL') {

            // let useMultiple = {
            //   mon: false,
            //   tue: false,
            //   wed: false,
            //   thu: false,
            //   fri: false,
            //   sat: false,
            //   sun: false,
            // };

            // for (const dayKey in this.state.multipleAvail) {

            //   const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            //   const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;

            //   if (typeof this.state.multipleAvail[dayKey][user.info_id] !== 'undefined' && this.state.multipleAvail[dayKey][user.info_id].school !== null && this.state.multipleAvail[dayKey][user.info_id].unav !== null) {

            //     day.push(this.getSchoolTouchBoxTable(dayKey, index, planKey));

            //     useMultiple[dayKey] = true;
            //     break;

            //   }

            // }

            // UNAVAILABLE
            for (const dayKey in this.unavailableData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.unavailableData[dayKey]) {
                  const planner = this.unavailableData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getUnavailabilityTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }

            // AVAILABLE
            for (const dayKey in this.availableData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.availableData[dayKey]) {
                  const planner = this.availableData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getAvailabilityTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }

            // NOTFILLED
            for (const dayKey in this.notFilledSchool) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.notFilledSchool[dayKey]) {
                  const planner = this.notFilledSchool[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getNotFilledSchoolTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // SCHOOL
            for (const dayKey in this.schoolScheduleData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.schoolScheduleData[dayKey]) {
                  const planner = this.schoolScheduleData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getSchoolTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // SCHOOLHOLIDAY
            for (const dayKey in this.schoolHolidayData) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.schoolHolidayData[dayKey]) {
                  const planner = this.schoolHolidayData[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getSchoolHolidayTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }

          }
    
          // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
    
          //   // NOTFILLED

          //   for (const dayKey in this.notFilledData) {
          //     const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
          //     const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
          //     if (day.length === 0) {
          //       for (const planKey in this.notFilledData[dayKey]) {
          //         const planner = this.notFilledData[dayKey][planKey];
          //         if (planner.info_id === user.info_id) {
          //           day.push(this.getNotFilledTouchBoxTable(planner, index, planKey));
          //         }
          //       }
          //     }
          //   }
    
          //   // for (const key in this.notFilledData.mon) {
    
          //   //   const planner = this.notFilledData.mon[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     monday.push(this.getNotFilledTouchBoxTable(planner, 0, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.notFilledData.tue) {
    
          //   //   const planner = this.notFilledData.tue[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     tuesday.push(this.getNotFilledTouchBoxTable(planner, 1, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.notFilledData.wed) {
    
          //   //   const planner = this.notFilledData.wed[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     wednesday.push(this.getNotFilledTouchBoxTable(planner, 2, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.notFilledData.thu) {
    
          //   //   const planner = this.notFilledData.thu[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     thursday.push(this.getNotFilledTouchBoxTable(planner, 3, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.notFilledData.fri) {
    
          //   //   const planner = this.notFilledData.fri[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     friday.push(this.getNotFilledTouchBoxTable(planner, 4, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.notFilledData.sat) {
    
          //   //   const planner = this.notFilledData.sat[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     saturday.push(this.getNotFilledTouchBoxTable(planner, 5, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.notFilledData.sun) {
    
          //   //   const planner = this.notFilledData.sun[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     sunday.push(this.getNotFilledTouchBoxTable(planner, 6, key));
    
          //   //   }
    
          //   // }
            
          //   // UNAVAILABLE

          //   for (const dayKey in this.unavailableData) {
          //     const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
          //     const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
          //     if (day.length === 0) {
          //       for (const planKey in this.unavailableData[dayKey]) {
          //         const planner = this.unavailableData[dayKey][planKey];
          //         if (planner.info_id === user.info_id) {
          //           day.push(this.getUnavailabilityTouchBoxTable(planner, index, planKey));
          //         }
          //       }
          //     }
          //   }
    
          //   // for (const key in this.unavailableData.mon) {
    
          //   //   const planner = this.unavailableData.mon[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     monday.push(this.getUnavailabilityTouchBoxTable(planner, 0, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.unavailableData.tue) {
    
          //   //   const planner = this.unavailableData.tue[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     tuesday.push(this.getUnavailabilityTouchBoxTable(planner, 1, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.unavailableData.wed) {
    
          //   //   const planner = this.unavailableData.wed[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     wednesday.push(this.getUnavailabilityTouchBoxTable(planner, 2, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.unavailableData.thu) {
    
          //   //   const planner = this.unavailableData.thu[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     thursday.push(this.getUnavailabilityTouchBoxTable(planner, 3, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.unavailableData.fri) {
    
          //   //   const planner = this.unavailableData.fri[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     friday.push(this.getUnavailabilityTouchBoxTable(planner, 4, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.unavailableData.sat) {
    
          //   //   const planner = this.unavailableData.sat[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     saturday.push(this.getUnavailabilityTouchBoxTable(planner, 5, key));
    
          //   //   }
    
          //   // }
          //   // for (const key in this.unavailableData.sun) {
    
          //   //   const planner = this.unavailableData.sun[key];
    
          //   //   if (planner.info_id === user.info_id) {
    
          //   //     sunday.push(this.getUnavailabilityTouchBoxTable(planner, 6, key));
    
          //   //   }
    
          //   // }
    
          // }
    
          if(Data.data.appOptions.availability === 'NONE') {
            
            // NOTFILLED

            for (const dayKey in this.usersNoAvailability) {
              const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
              const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
              if (day.length === 0) {
                for (const planKey in this.usersNoAvailability[dayKey]) {
                  const planner = this.usersNoAvailability[dayKey][planKey];
                  if (planner.info_id === user.info_id) {
                    day.push(this.getAllPeopleTouchBoxTable(planner, index, planKey));
                  }
                }
              }
            }
    
            // for (const key in this.usersNoAvailability.mon) {
    
            //   const planner = this.usersNoAvailability.mon[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     monday.push(this.getAllPeopleTouchBoxTable(planner, 0, key));
    
            //   }
    
            // }
            // for (const key in this.usersNoAvailability.tue) {
    
            //   const planner = this.usersNoAvailability.tue[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     tuesday.push(this.getAllPeopleTouchBoxTable(planner, 1, key));
    
            //   }
    
            // }
            // for (const key in this.usersNoAvailability.wed) {
    
            //   const planner = this.usersNoAvailability.wed[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     wednesday.push(this.getAllPeopleTouchBoxTable(planner, 2, key));
    
            //   }
    
            // }
            // for (const key in this.usersNoAvailability.thu) {
    
            //   const planner = this.usersNoAvailability.thu[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     thursday.push(this.getAllPeopleTouchBoxTable(planner, 3, key));
    
            //   }
    
            // }
            // for (const key in this.usersNoAvailability.fri) {
    
            //   const planner = this.usersNoAvailability.fri[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     friday.push(this.getAllPeopleTouchBoxTable(planner, 4, key));
    
            //   }
    
            // }
            // for (const key in this.usersNoAvailability.sat) {
    
            //   const planner = this.usersNoAvailability.sat[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     saturday.push(this.getAllPeopleTouchBoxTable(planner, 5, key));
    
            //   }
    
            // }
            // for (const key in this.usersNoAvailability.sun) {
    
            //   const planner = this.usersNoAvailability.sun[key];
    
            //   if (planner.info_id === user.info_id) {
    
            //     sunday.push(this.getAllPeopleTouchBoxTable(planner, 6, key));
    
            //   }
    
            // }
    
          }
    
          // holidays

          // CONTRACTS
          if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.mondayDate)) === null && planned.mon === false) {
              monday = [];
              for (let i = monday.length; i < highestLength; i++) {
                monday.push(this.getContractTouchBoxTable(1 + i, monday.length > 0));
              }
              if(monday.length === 0) {
                monday.push(this.getContractTouchBoxTable(1));
              }
            }
            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.tuesdayDate)) === null && planned.tue === false) {
              tuesday = [];
              for (let i = tuesday.length; i < highestLength; i++) {
                tuesday.push(this.getContractTouchBoxTable(2 + i, tuesday.length > 0));
              }
              if(tuesday.length === 0) {
                tuesday.push(this.getContractTouchBoxTable(2));
              }
            }
            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.wednesdayDate)) === null && planned.wed === false) {
              wednesday = [];
              for (let i = wednesday.length; i < highestLength; i++) {
                wednesday.push(this.getContractTouchBoxTable(3 + i, wednesday.length > 0));
              }
              if(wednesday.length === 0) {
                wednesday.push(this.getContractTouchBoxTable(3));
              }
            }
            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.thursdayDate)) === null && planned.thu === false) {
              thursday = [];
              for (let i = thursday.length; i < highestLength; i++) {
                thursday.push(this.getContractTouchBoxTable(4 + i, thursday.length > 0));
              }
              if(thursday.length === 0) {
                thursday.push(this.getContractTouchBoxTable(4));
              }
            }
            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.fridayDate)) === null && planned.fri === false) {
              friday = [];
              for (let i = friday.length; i < highestLength; i++) {
                friday.push(this.getContractTouchBoxTable(5 + i, friday.length > 0));
              }
              if(friday.length === 0) {
                friday.push(this.getContractTouchBoxTable(5));
              }
            }
            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.saturdayDate)) === null && planned.sat === false) {
              saturday = [];
              for (let i = saturday.length; i < highestLength; i++) {
                saturday.push(this.getContractTouchBoxTable(6 + i, saturday.length > 0));
              }
              if(saturday.length === 0) {
                saturday.push(this.getContractTouchBoxTable(6));
              }
            }
            if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.sundayDate)) === null && planned.sun === false) {
              sunday = [];
              for (let i = sunday.length; i < highestLength; i++) {
                sunday.push(this.getContractTouchBoxTable(0 + i, sunday.length > 0));
              }
              if(sunday.length === 0) {
                sunday.push(this.getContractTouchBoxTable(0));
              }
            }

          }

          if (monday.length > 0 || tuesday.length > 0 || wednesday.length > 0 || thursday.length > 0 || friday.length > 0 || saturday.length > 0 || sunday.length > 0) {

            for (let i = monday.length; i < highestLength; i++) {
              monday.push(this.getFillEmptyShiftUserTouchBoxTable(0, 'ef' + i, fid, user.info_id));
            }
            for (let i = tuesday.length; i < highestLength; i++) {
              tuesday.push(this.getFillEmptyShiftUserTouchBoxTable(1, 'ef' + i, fid, user.info_id));
            }
            for (let i = wednesday.length; i < highestLength; i++) {
              wednesday.push(this.getFillEmptyShiftUserTouchBoxTable(2, 'ef' + i, fid, user.info_id));
            }
            for (let i = thursday.length; i < highestLength; i++) {
              thursday.push(this.getFillEmptyShiftUserTouchBoxTable(3, 'ef' + i, fid, user.info_id));
            }
            for (let i = friday.length; i < highestLength; i++) {
              friday.push(this.getFillEmptyShiftUserTouchBoxTable(4, 'ef' + i, fid, user.info_id));
            }
            for (let i = saturday.length; i < highestLength; i++) {
              saturday.push(this.getFillEmptyShiftUserTouchBoxTable(5, 'ef' + i, fid, user.info_id));
            }
            for (let i = sunday.length; i < highestLength; i++) {
              sunday.push(this.getFillEmptyShiftUserTouchBoxTable(6, 'ef' + i, fid, user.info_id));
            }

          }

          const hoursWeek = this.getUserHoursWeek(user.info_id);
          const hoursWeek2 = Constants.round(hoursWeek / 1000 / 60 / 60);

          let contractMinHours = null;
          let contractHours = null;
          let hasContract = false;

          const contract = Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.mondayDate));
          if (contract !== null) {

            if (contract.contract_type === '1') { // minmax
              const minHours = parseFloat(contract.contract_uren);
              const hours = parseFloat(contract.contract_max_uren);
              if (isNaN(hours) === false && hours > 0 && isNaN(minHours) === false && minHours > 0) {
                contractMinHours = minHours;
                contractHours = hours;
              }
              hasContract = true;
            } else if (contract.contract_type === '2') { // vaste uren
              const hours = parseFloat(contract.contract_uren);
              if (isNaN(hours) === false && hours > 0) {
                contractHours = hours;
              }
              hasContract = true;
            }

          }

          const overhours = this.getOverhoursUser(user.info_id);

          let overhoursText = null;
          let negative = false;

          if (Constants.isTrue(Data.data.appOptions.opties_overuren) && overhours !== null) {

            overhoursText = isNaN(overhours.balanceEnd) || Constants.msToTime2(overhours.balanceEnd) === '00:00' ? Constants.msToTime2(0) : overhours.balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(overhours.balanceEnd)) : overhours.balanceEnd !== 0 ? '+' + Constants.msToTime2(overhours.balanceEnd) : Constants.msToTime2(0);
            negative = overhours.balanceEnd < 0;

            if (overhoursText === '00:00') {
              overhoursText = null;
            }

          }
    
          toReturn.push(
    
            <div className='planner_table_nameContainer2' style={{marginBottom: this.state.display === 'XCL' ? '0.05%' : null}}>
    
              {/* NAME */}
              <div className='planner_table_nameContainer2_name' style={{backgroundColor: this.paramHighlight === user.info_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>
    
                <div className='planner_table_nameContainer2_name_sub1' style={this.state.showUserMenu[user.info_id] ? {color: '#47B7EB'} : null} onClick={() => this.state.showUserMenu[user.info_id] ? this.closeUserMenu(user.info_id) : this.openUserMenu(user.info_id)}>
                  <span>{userFullName}</span>
                </div>

                <div className='planner_table_nameContainer2_name_sub2' onClick={() => this.state.showUserMenu[user.info_id] ? this.closeUserMenu(user.info_id) : this.openUserMenu(user.info_id)}>
                  <i style={this.state.showUserMenu[user.info_id] ? {color: '#47B7EB'} : user.functie_naam === null ? {color: Colors.color.redFilledIn} : null} className={this.state.showUserMenu[user.info_id] ? 'fas fa-times' : 'planner_hoverIcon fas fa-caret-down'} />
                </div>

                <div className='planner_table_nameContainer2_name_sub2' onClick={() => this.setAddEmptyRowsUser(user.info_id)}>
                  <i className='fas fa-plus-circle planner_hoverIcon2' />
                </div>

                {contractHours !== null && contractMinHours !== null ?
                  <div className='planner_table_nameContainer2_name_sub3'>
                    <span><span style={{color: hoursWeek2 > contractHours ? Colors.color.redFilledIn : null}}>{Constants.msToTime2(hoursWeek)}</span>/{Constants.msToTime2(contractMinHours * 60 * 60 * 1000)}|{Constants.msToTime2(contractHours * 60 * 60 * 1000)}</span>
                    {hasContract === true && overhoursText !== null ?
                      <span style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                    : null}
                  </div>
                :
                contractHours !== null ?
                  <div className='planner_table_nameContainer2_name_sub3'>
                    <span><span style={{color: hoursWeek2 > contractHours ? Colors.color.redFilledIn : null}}>{Constants.msToTime2(hoursWeek)}</span>/{Constants.msToTime2(contractHours * 60 * 60 * 1000)}</span>
                    {hasContract === true && overhoursText !== null ?
                      <span style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                    : null}
                  </div>
                :
                  <div className='planner_table_nameContainer2_name_sub3'>
                    <span>{Constants.msToTime2(hoursWeek)}</span>
                    {hasContract === true && overhoursText !== null ?
                      <span style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                    : null}
                  </div>
                }
    
                {this.state.showUserMenu[user.info_id] === true ?
                  this.renderUserMenu(user)
                : null}

              </div>
    
              {/* MONDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.mondayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.mondayDate).style.color = '#282828'} 
              >
                {monday}
              </div>
    
              {/* TUESDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.tuesdayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.tuesdayDate).style.color = '#282828'} 
              >
                {tuesday}
              </div>
    
              {/* WEDNESDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.wednesdayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.wednesdayDate).style.color = '#282828'} 
              >
                {wednesday}
              </div>
    
              {/* THURSDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.thursdayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.thursdayDate).style.color = '#282828'} 
              >
                {thursday}
              </div>
    
              {/* FRIDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.fridayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.fridayDate).style.color = '#282828'} 
              >
                {friday}
              </div>
    
              {/* SATURDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.saturdayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.saturdayDate).style.color = '#282828'} 
              >
                {saturday}
              </div>
    
              {/* SUNDAY */}
              <div className='planner_table_nameContainer2_sub'
                onMouseEnter={() => document.getElementById(this.state.sundayDate).style.color = '#47B7EB'} 
                onMouseLeave={() => document.getElementById(this.state.sundayDate).style.color = '#282828'} 
              >
                {sunday}
              </div>
    
            </div>
    
          );
    
        }

      }

      if (functionHasUsers === true) {

        toReturn.push(this.renderEmptyShiftsTable(func.functie_id));

      }

      if (this.functionsData.length > 0) {

        toReturn.push(this.renderDayShiftsTable(func.functie_id));

      }

    }

    // USERS WITHOUT FUNCTION
    if (usersWithoutFunction === true && this.state.section === null) {

      if (this.state.display === 'TABLE') {

        toReturn.push(
          <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '1.5vw', backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderRadius: 5, paddingLeft: 4, marginTop: 8, marginBottom: 5}}>
            <span style={{color: Constants.getContrast(Colors.color.redFilledIn), fontSize: '0.7vw'}}>Geen functie</span>
          </div>
        );

      } else {

        toReturn.push(
          <div style={{position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '1vw', backgroundColor: Constants.hexToRgbA(Colors.color.redFilledIn, 0.5), borderRadius: 3, paddingLeft: 3, marginTop: 8, marginBottom: 5}}>
            <span style={{color: Constants.getContrast(Colors.color.redFilledIn), fontSize: '0.7vw'}}>Geen functie</span>
          </div>
        );

      }

      for (const user of list) {

        if (user.functie_id !== null) {
          continue;
        }
  
        const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;
  
        let monday = [];
        let tuesday = [];
        let wednesday = [];
        let thursday = [];
        let friday = [];
        let saturday = [];
        let sunday = [];

        let planned = {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false
        };

        let highest = {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0,
        };

        // if has team, show shift it as other team
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
    
          let index = 0;

          // find all plans of user
          for (const key in this.allPlannerData) {

            const day = key === 'mon' ? monday : key === 'tue' ? tuesday : key === 'wed' ? wednesday : key === 'thu' ? thursday : key === 'fri' ? friday : key === 'sat' ? saturday : key === 'sun' ? sunday : monday;
            let foundPlans = [];
            let allPlans = [];

            for (const planAll of this.allPlannerData[key]) {
              if (planAll.info_id === user.info_id) {

                allPlans.push(planAll);

                for (const planTeam of this.plannerData[key]) {
                  if (planTeam.rooster_id === planAll.rooster_id) {
                    foundPlans.push(planTeam);
                  }
                }
              }
            }

            // iterate through all found plans by user and check if they exist in team planner
            for (const allPlanUser of allPlans) {
              let found = false;
              for (const foundPlanUser of foundPlans) {
                if (foundPlanUser.rooster_id === allPlanUser.rooster_id) {
                  found = true;
                  break;
                }
              }

              if (found === false) {
                day.push(this.getOtherTeamTouchBoxTable(index, day.length > 0, allPlanUser));
                highest[key] += 1;
                index++;
              }

            }

          }
    
        }
  
        // PLANNER
  
        this.plannerData.mon.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
        this.plannerData.tue.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
        this.plannerData.wed.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
        this.plannerData.thu.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
        this.plannerData.fri.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
        this.plannerData.sat.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
        this.plannerData.sun.sort((a, b) => (a.rooster_begin > b.rooster_begin || (a.rooster_begin === b.rooster_begin && a.rooster_eind > b.rooster_eind) || (a.rooster_begin === b.rooster_begin && a.rooster_eind === b.rooster_eind && a.rooster_id > b.rooster_id)) ? 1 : -1);
  
        for (const planner of this.plannerData.mon) {
          if (planner.info_id === user.info_id) {
            monday.push(this.getPlannerTouchBoxTable(planner, 0, monday.length > 0));
            planned.mon = true;
          }
        }
        for (const planner of this.plannerData.tue) {
          if (planner.info_id === user.info_id) {
            tuesday.push(this.getPlannerTouchBoxTable(planner, 1, tuesday.length > 0));
            planned.tue = true;
          }
        }
        for (const planner of this.plannerData.wed) {
          if (planner.info_id === user.info_id) {
            wednesday.push(this.getPlannerTouchBoxTable(planner, 2, wednesday.length > 0));
            planned.wed = true;
          }
        }
        for (const planner of this.plannerData.thu) {
          if (planner.info_id === user.info_id) {
            thursday.push(this.getPlannerTouchBoxTable(planner, 3, thursday.length > 0));
            planned.thu = true;
          }
        }
        for (const planner of this.plannerData.fri) {
          if (planner.info_id === user.info_id) {
            friday.push(this.getPlannerTouchBoxTable(planner, 4, friday.length > 0));
            planned.fri = true;
          }
        }
        for (const planner of this.plannerData.sat) {
          if (planner.info_id === user.info_id) {
            saturday.push(this.getPlannerTouchBoxTable(planner, 5, saturday.length > 0));
            planned.sat = true;
          }
        }
        for (const planner of this.plannerData.sun) {
          if (planner.info_id === user.info_id) {
            sunday.push(this.getPlannerTouchBoxTable(planner, 6, sunday.length > 0));
            planned.sun = true;
          }
        }

        for (const key in this.plannerData) {
          for (const shift of this.plannerData[key]) {
            if (shift.info_id === user.info_id) {
              highest[key] += 1;
            }
          }
        }

        let highestLength = 0;
        for (const key in highest) {
          if (highest[key] > highestLength) {
            highestLength = highest[key];
          }
        }

        if (highestLength === 0) {
          highestLength = highestLength + 1;
        }

        highestLength = highestLength + this.state.addEmptyRowsUser[user.info_id];

        // HOLIDAYS
          //if (monday.length === 0) {
            for (const key in this.holidayDaysData.mon) {
      
              const planner = this.holidayDaysData.mon[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.mondayDate) {

                for (let i = monday.length; i < highestLength; i++) {
                  monday.push(this.getHolidayTouchBoxTable(planner, 0, key + i, monday.length > 0));
                }

                if (monday.length === 0) {
                  monday.push(this.getHolidayTouchBoxTable(planner, 0, key));
                }
      
              }
      
            }
          //}
          //if (tuesday.length === 0) {
            for (const key in this.holidayDaysData.tue) {
      
              const planner = this.holidayDaysData.tue[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.tuesdayDate) {
      
                for (let i = tuesday.length; i < highestLength; i++) {
                  tuesday.push(this.getHolidayTouchBoxTable(planner, 1, key + i, tuesday.length > 0));
                }

                if (tuesday.length === 0) {
                  tuesday.push(this.getHolidayTouchBoxTable(planner, 1, key));
                }
      
              }
      
            }
          //}
          //if (wednesday.length === 0) {
            for (const key in this.holidayDaysData.wed) {
      
              const planner = this.holidayDaysData.wed[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.wednesdayDate) {
      
                for (let i = wednesday.length; i < highestLength; i++) {
                  wednesday.push(this.getHolidayTouchBoxTable(planner, 2, key + i, wednesday.length > 0));
                }

                if (wednesday.length === 0) {
                  wednesday.push(this.getHolidayTouchBoxTable(planner, 2, key));
                }
      
              }
      
            }
         // }
          //if (thursday.length === 0) {
            for (const key in this.holidayDaysData.thu) {
      
              const planner = this.holidayDaysData.thu[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.thursdayDate) {
      
                for (let i = thursday.length; i < highestLength; i++) {
                  thursday.push(this.getHolidayTouchBoxTable(planner, 3, key + i, thursday.length > 0));
                }

                if (thursday.length === 0) {
                  thursday.push(this.getHolidayTouchBoxTable(planner, 3, key));
                }
      
              }
      
            }
          //}
          //if (friday.length === 0) {
            for (const key in this.holidayDaysData.fri) {
      
              const planner = this.holidayDaysData.fri[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.fridayDate) {
      
                for (let i = friday.length; i < highestLength; i++) {
                  friday.push(this.getHolidayTouchBoxTable(planner, 4, key + i, friday.length > 0));
                }

                if (friday.length === 0) {
                  friday.push(this.getHolidayTouchBoxTable(planner, 4, key));
                }
      
              }
      
            }
          //}
          //if (saturday.length === 0) {
            for (const key in this.holidayDaysData.sat) {
      
              const planner = this.holidayDaysData.sat[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.saturdayDate) {
      
                for (let i = saturday.length; i < highestLength; i++) {
                  saturday.push(this.getHolidayTouchBoxTable(planner, 5, key + i, saturday.length > 0));
                }

                if (saturday.length === 0) {
                  saturday.push(this.getHolidayTouchBoxTable(planner, 5, key));
                }
      
              }
      
            }
          //}
          //if (sunday.length === 0) {
            for (const key in this.holidayDaysData.sun) {
      
              const planner = this.holidayDaysData.sun[key];
      
              if (planner.userID === user.info_id && Constants.dateToString(planner.date) === this.state.sundayDate) {
      
                for (let i = sunday.length; i < highestLength; i++) {
                  sunday.push(this.getHolidayTouchBoxTable(planner, 6, key + i, sunday.length > 0));
                }

                if (sunday.length === 0) {
                  sunday.push(this.getHolidayTouchBoxTable(planner, 6, key));
                }
      
              }
      
            }
          //}

        // WORK DAYS
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_maandag === '0') {
          for (let i = monday.length; i < highestLength; i++) {
            monday.push(this.getNonWorkdayTouchboxTable(1 + i, monday.length > 0));
          }
          if (monday.length === 0) {
            monday.push(this.getNonWorkdayTouchboxTable(1, monday.length > 0));
          }
        }
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_dinsdag === '0') {
          for (let i = tuesday.length; i < highestLength; i++) {
            tuesday.push(this.getNonWorkdayTouchboxTable(2 + i, tuesday.length > 0));
          }
          if (tuesday.length === 0) {
            tuesday.push(this.getNonWorkdayTouchboxTable(2, tuesday.length > 0));
          }
        }
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_woensdag === '0') {
          for (let i = wednesday.length; i < highestLength; i++) {
            wednesday.push(this.getNonWorkdayTouchboxTable(3 + i, wednesday.length > 0));
          }
          if (wednesday.length === 0) {
            wednesday.push(this.getNonWorkdayTouchboxTable(3, wednesday.length > 0));
          }
        }
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_donderdag === '0') {
          for (let i = thursday.length; i < highestLength; i++) {
            thursday.push(this.getNonWorkdayTouchboxTable(4 + i, thursday.length > 0));
          }
          if (thursday.length === 0) {
            thursday.push(this.getNonWorkdayTouchboxTable(4, thursday.length > 0));
          }
        }
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_vrijdag === '0') {
          for (let i = friday.length; i < highestLength; i++) {
            friday.push(this.getNonWorkdayTouchboxTable(5 + i, friday.length > 0));
          }
          if (friday.length === 0) {
            friday.push(this.getNonWorkdayTouchboxTable(5, friday.length > 0));
          }
        }
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_zaterdag === '0') {
          for (let i = saturday.length; i < highestLength; i++) {
            saturday.push(this.getNonWorkdayTouchboxTable(6 + i, saturday.length > 0));
          }
          if (saturday.length === 0) {
            saturday.push(this.getNonWorkdayTouchboxTable(6, saturday.length > 0));
          }
        }
        if (Constants.isEmpty(this.state.infoExtra[user.info_id]) === false && this.state.infoExtra[user.info_id].info2_werkdag_zondag === '0') {
          for (let i = sunday.length; i < highestLength; i++) {
            sunday.push(this.getNonWorkdayTouchboxTable(0 + i, sunday.length > 0));
          }
          if (sunday.length === 0) {
            sunday.push(this.getNonWorkdayTouchboxTable(0, sunday.length > 0));
          }
        }

        // REPEATING SHIFTS
        if (Constants.isEmpty(this.repeatingShifts[user.info_id]) === false) {
          for (const r of this.repeatingShifts[user.info_id]) {
            for (let i = 0; i < 7; i++) {

              const array = i === 1 ? monday : i === 2 ? tuesday : i === 3 ? wednesday : i === 4 ? thursday : i === 5 ? friday : i === 6 ? saturday : sunday;
              const key = i === 1 ? 'mon' : i === 2 ? 'tue' : i === 3 ? 'wed' : i === 4 ? 'thu' : i === 5 ? 'fri' : i === 6 ? 'sat' : 'sun';

              const date1 = i === 1 ? Constants.stringToDate(this.state.mondayDate) : i === 2 ? Constants.stringToDate(this.state.tuesdayDate) : i === 3 ? Constants.stringToDate(this.state.wednesdayDate) : i === 4 ? Constants.stringToDate(this.state.thursdayDate) : i === 5 ? Constants.stringToDate(this.state.fridayDate) : i === 6 ? Constants.stringToDate(this.state.saturdayDate) : Constants.stringToDate(this.state.sundayDate);
              const date2 = Constants.stringToDate(r.hr_startdatum);
              const date3 = Constants.isEmpty(r.hr_einddatum) === false ? Constants.stringToDate(r.hr_einddatum) : null;
              const interval = parseInt(r.hr_interval);
              const calc1 = r.hr_type === 'day' ? date1.getDate() : r.hr_type === 'week' ? Constants.getWeekNumber2(date1) : r.hr_type === 'month' ? date1.getMonth() : date1.getFullYear();
              const calc2 = r.hr_type === 'day' ? date2.getDate() : r.hr_type === 'week' ? Constants.getWeekNumber2(date2) : r.hr_type === 'month' ? date2.getMonth() : date2.getFullYear();
              const result = calc2 >= calc1 ? (calc2 - calc1) % interval : (calc1 - calc2) % interval;
              const isInInterval = r.hr_type === 'week' ? result === 0 && date1.getDay() === date2.getDay() : r.hr_type === 'month' ? result === 0 && date1.getDate() === date2.getDate() : r.hr_type === 'year' ? result === 0 && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() : result === 0;
                
              if (isInInterval && date1 >= date2 && ((date3 !== null && date1 <= date3) || date3 === null)) {

                if (array.length === 0) {
                  array.push(this.getRepeatShiftTouchBoxTable(i, array.length > 0, r));
                }

              }

            }
          }
        }
    
        if (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
    
          // AVAILABILITY

          for (const dayKey in this.availableData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.availableData[dayKey]) {
                const planner = this.availableData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getAvailabilityTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
          // UNAVAILABLE

          for (const dayKey in this.unavailableData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.unavailableData[dayKey]) {
                const planner = this.unavailableData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getUnavailabilityTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }

    
          // NOTFILLED

          for (const dayKey in this.notFilledData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.notFilledData[dayKey]) {
                const planner = this.notFilledData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getNotFilledTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
        }

        if (Data.data.appOptions.availability === 'STANDARD') {
    
          // AVAILABILITY

          for (const dayKey in this.standardAvailableData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            //for (let i = day.length; i < highestLength; i++) {
            if (day.length === 0) {
              for (const planKey in this.standardAvailableData[dayKey]) {
                const planner = this.standardAvailableData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getStandardAvailableTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
          // UNAVAILABLE

          for (const dayKey in this.standardUnavailableData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            // for (let i = day.length; i < highestLength; i++) {
            if (day.length === 0) {
              for (const planKey in this.standardUnavailableData[dayKey]) {
                const planner = this.standardUnavailableData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getStandardUnavailableTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
          // NOTFILLED

          for (const dayKey in this.standardNotFilledData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            // for (let i = day.length; i < highestLength; i++) {
            if (day.length === 0) {
              for (const planKey in this.standardNotFilledData[dayKey]) {
                const planner = this.standardNotFilledData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getStandardNotFilledTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
        }
    
        if(Data.data.appOptions.availability === 'SCHOOL') {

          // AVAILABLE
          for (const dayKey in this.availableData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.availableData[dayKey]) {
                const planner = this.availableData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getAvailabilityTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }

          // UNAVAILABLE
          for (const dayKey in this.unavailableData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.unavailableData[dayKey]) {
                const planner = this.unavailableData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getUnavailabilityTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }

          // NOTFILLED
          for (const dayKey in this.notFilledSchool) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.notFilledSchool[dayKey]) {
                const planner = this.notFilledSchool[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getNotFilledSchoolTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
          // SCHOOL
          for (const dayKey in this.schoolScheduleData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.schoolScheduleData[dayKey]) {
                const planner = this.schoolScheduleData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getSchoolTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
          // SCHOOLHOLIDAY
          for (const dayKey in this.schoolHolidayData) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.schoolHolidayData[dayKey]) {
                const planner = this.schoolHolidayData[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getSchoolHolidayTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }

        }
    
        // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
    
        //   // NOTFILLED

        //   for (const dayKey in this.notFilledData) {
        //     const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
        //     const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
        //     if (day.length === 0) {
        //       for (const planKey in this.notFilledData[dayKey]) {
        //         const planner = this.notFilledData[dayKey][planKey];
        //         if (planner.info_id === user.info_id) {
        //           day.push(this.getNotFilledTouchBoxTable(planner, index, planKey));
        //         }
        //       }
        //     }
        //   }
            
        //   // UNAVAILABLE

        //   for (const dayKey in this.unavailableData) {
        //     const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
        //     const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
        //     if (day.length === 0) {
        //       for (const planKey in this.unavailableData[dayKey]) {
        //         const planner = this.unavailableData[dayKey][planKey];
        //         if (planner.info_id === user.info_id) {
        //           day.push(this.getUnavailabilityTouchBoxTable(planner, index, planKey));
        //         }
        //       }
        //     }
        //   }
    
        // }
    
        if(Data.data.appOptions.availability === 'NONE') {
            
          // NOTFILLED

          for (const dayKey in this.usersNoAvailability) {
            const day = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
            const index = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;
            if (day.length === 0) {
              for (const planKey in this.usersNoAvailability[dayKey]) {
                const planner = this.usersNoAvailability[dayKey][planKey];
                if (planner.info_id === user.info_id) {
                  day.push(this.getAllPeopleTouchBoxTable(planner, index, planKey));
                }
              }
            }
          }
    
        }
    
        // holidays

        // CONTRACTS
        if (Constants.isTrue(Data.data.appOptions.opties_contractVerlopen)) {

          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.mondayDate)) === null && planned.mon === false) {
            monday = [];
            for (let i = monday.length; i < highestLength; i++) {
              monday.push(this.getContractTouchBoxTable(1 + i, monday.length > 0));
            }
            if(monday.length === 0) {
              monday.push(this.getContractTouchBoxTable(1));
            }
          }
          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.tuesdayDate)) === null && planned.tue === false) {
            tuesday = [];
            for (let i = tuesday.length; i < highestLength; i++) {
              tuesday.push(this.getContractTouchBoxTable(2 + i, tuesday.length > 0));
            }
            if(tuesday.length === 0) {
              tuesday.push(this.getContractTouchBoxTable(2));
            }
          }
          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.wednesdayDate)) === null && planned.wed === false) {
            wednesday = [];
            for (let i = wednesday.length; i < highestLength; i++) {
              wednesday.push(this.getContractTouchBoxTable(3 + i, wednesday.length > 0));
            }
            if(wednesday.length === 0) {
              wednesday.push(this.getContractTouchBoxTable(3));
            }
          }
          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.thursdayDate)) === null && planned.thu === false) {
            thursday = [];
            for (let i = thursday.length; i < highestLength; i++) {
              thursday.push(this.getContractTouchBoxTable(4 + i, thursday.length > 0));
            }
            if(thursday.length === 0) {
              thursday.push(this.getContractTouchBoxTable(4));
            }
          }
          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.fridayDate)) === null && planned.fri === false) {
            friday = [];
            for (let i = friday.length; i < highestLength; i++) {
              friday.push(this.getContractTouchBoxTable(5 + i, friday.length > 0));
            }
            if(friday.length === 0) {
              friday.push(this.getContractTouchBoxTable(5));
            }
          }
          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.saturdayDate)) === null && planned.sat === false) {
            saturday = [];
            for (let i = saturday.length; i < highestLength; i++) {
              saturday.push(this.getContractTouchBoxTable(6 + i, saturday.length > 0));
            }
            if(saturday.length === 0) {
              saturday.push(this.getContractTouchBoxTable(6));
            }
          }
          if (Constants.getCurrentContract(this.state.contractsObj[user.info_id], Constants.stringToDate(this.state.sundayDate)) === null && planned.sun === false) {
            sunday = [];
            for (let i = sunday.length; i < highestLength; i++) {
              sunday.push(this.getContractTouchBoxTable(0 + i, sunday.length > 0));
            }
            if(sunday.length === 0) {
              sunday.push(this.getContractTouchBoxTable(0));
            }
          }

        }

        if (monday.length > 0 || tuesday.length > 0 || wednesday.length > 0 || thursday.length > 0 || friday.length > 0 || saturday.length > 0 || sunday.length > 0) {

          for (let i = monday.length; i < highestLength; i++) {
            monday.push(this.getFillEmptyShiftUserTouchBoxTable(0, 'ef' + i, fid, user.info_id));
          }
          for (let i = tuesday.length; i < highestLength; i++) {
            tuesday.push(this.getFillEmptyShiftUserTouchBoxTable(1, 'ef' + i, fid, user.info_id));
          }
          for (let i = wednesday.length; i < highestLength; i++) {
            wednesday.push(this.getFillEmptyShiftUserTouchBoxTable(2, 'ef' + i, fid, user.info_id));
          }
          for (let i = thursday.length; i < highestLength; i++) {
            thursday.push(this.getFillEmptyShiftUserTouchBoxTable(3, 'ef' + i, fid, user.info_id));
          }
          for (let i = friday.length; i < highestLength; i++) {
            friday.push(this.getFillEmptyShiftUserTouchBoxTable(4, 'ef' + i, fid, user.info_id));
          }
          for (let i = saturday.length; i < highestLength; i++) {
            saturday.push(this.getFillEmptyShiftUserTouchBoxTable(5, 'ef' + i, fid, user.info_id));
          }
          for (let i = sunday.length; i < highestLength; i++) {
            sunday.push(this.getFillEmptyShiftUserTouchBoxTable(6, 'ef' + i, fid, user.info_id));
          }

        }

        const hoursWeek = this.getUserHoursWeek(user.info_id);
        const hoursWeek2 = Constants.round(hoursWeek / 1000 / 60 / 60);

        let contractMinHours = null;
        let contractHours = null;
        let hasContract = false;

        const contract = Constants.getCurrentContract(this.state.contractsObj[user.info_id]);
        if (contract !== null) {

          if (contract.contract_type === '1') { // minmax
            const minHours = parseFloat(contract.contract_uren);
            const hours = parseFloat(contract.contract_max_uren);
            if (isNaN(hours) === false && hours > 0 && isNaN(minHours) === false && minHours > 0) {
              contractMinHours = minHours;
              contractHours = hours;
            }
            hasContract = true;
          } else if (contract.contract_type === '2') { // vaste uren
            const hours = parseFloat(contract.contract_uren);
            if (isNaN(hours) === false && hours > 0) {
              contractHours = hours;
            }
            hasContract = true;
          }

        }

        const overhours = this.getOverhoursUser(user.info_id);

        let overhoursText = null;
        let negative = false;

        if (Constants.isTrue(Data.data.appOptions.opties_overuren) && overhours !== null) {

          overhoursText = isNaN(overhours.balanceEnd) || Constants.msToTime2(overhours.balanceEnd) === '00:00' ? Constants.msToTime2(0) : overhours.balanceEnd < 0 ? '-' + Constants.msToTime2(Math.abs(overhours.balanceEnd)) : overhours.balanceEnd !== 0 ? '+' + Constants.msToTime2(overhours.balanceEnd) : Constants.msToTime2(0);
          negative = overhours.balanceEnd < 0;

          if (overhoursText === '00:00') {
            overhoursText = null;
          }

        }
  
        toReturn.push(
  
          <div className='planner_table_nameContainer2'>
  
            {/* NAME */}
            <div className='planner_table_nameContainer2_name' style={{backgroundColor: this.paramHighlight === user.info_id ? Constants.hexToRgbA(Colors.color.ORIGINAL_MAINCOLOR_1, 0.2) : null}}>
    
              <div className='planner_table_nameContainer2_name_sub1' style={this.state.showUserMenu[user.info_id] ? {color: '#47B7EB'} : null} onClick={() => this.state.showUserMenu[user.info_id] ? this.closeUserMenu(user.info_id) : this.openUserMenu(user.info_id)}>
                <span>{userFullName}</span>
              </div>

              <div className='planner_table_nameContainer2_name_sub2' onClick={() => this.state.showUserMenu[user.info_id] ? this.closeUserMenu(user.info_id) : this.openUserMenu(user.info_id)}>
                <i style={this.state.showUserMenu[user.info_id] ? {color: '#47B7EB'} : user.functie_naam === null ? {color: Colors.color.redFilledIn} : null} className={this.state.showUserMenu[user.info_id] ? 'fas fa-times' : 'planner_hoverIcon fas fa-caret-down'} />
              </div>

              {contractHours !== null && contractMinHours !== null ?
                <div className='planner_table_nameContainer2_name_sub3'>
                  <span><span style={{color: hoursWeek2 > contractHours ? Colors.color.redFilledIn : null}}>{Constants.msToTime2(hoursWeek)}</span>/{Constants.msToTime2(contractMinHours * 60 * 60 * 1000)}|{Constants.msToTime2(contractHours * 60 * 60 * 1000)}</span>
                  {hasContract === true && overhoursText !== null ?
                    <span style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                  : null}
                </div>
              :
              contractHours !== null ?
                <div className='planner_table_nameContainer2_name_sub3'>
                  <span><span style={{color: hoursWeek2 > contractHours ? Colors.color.redFilledIn : null}}>{Constants.msToTime2(hoursWeek)}</span>/{Constants.msToTime2(contractHours * 60 * 60 * 1000)}</span>
                  {hasContract === true && overhoursText !== null ?
                    <span style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                  : null}
                </div>
              :
                <div className='planner_table_nameContainer2_name_sub3'>
                  <span>{Constants.msToTime2(hoursWeek)}</span>
                  {hasContract === true && overhoursText !== null ?
                    <span style={{color: negative ? Colors.color.greenFilledIn : Colors.color.redFilledIn}}>{overhoursText}</span>
                  : null}
                </div>
              }
    
              {this.state.showUserMenu[user.info_id] === true ?
                this.renderUserMenu(user)
              : null}

            </div>
  
            {/* MONDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.mondayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.mondayDate).style.color = '#282828'} 
            >
              {monday}
            </div>
    
            {/* TUESDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.tuesdayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.tuesdayDate).style.color = '#282828'} 
            >
              {tuesday}
            </div>
    
            {/* WEDNESDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.wednesdayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.wednesdayDate).style.color = '#282828'} 
            >
              {wednesday}
            </div>
    
            {/* THURSDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.thursdayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.thursdayDate).style.color = '#282828'} 
            >
              {thursday}
            </div>
    
            {/* FRIDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.fridayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.fridayDate).style.color = '#282828'} 
            >
              {friday}
            </div>
    
            {/* SATURDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.saturdayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.saturdayDate).style.color = '#282828'} 
            >
              {saturday}
            </div>
    
            {/* SUNDAY */}
            <div className='planner_table_nameContainer2_sub'
              onMouseEnter={() => document.getElementById(this.state.sundayDate).style.color = '#47B7EB'} 
              onMouseLeave={() => document.getElementById(this.state.sundayDate).style.color = '#282828'} 
            >
              {sunday}
            </div>
  
          </div>
  
        );

      }

    }

    if (this.functionsData.length === 0) {
      toReturn.push(this.renderDayShiftsTable());
    }

    return toReturn;

  }

  renderDayShiftsTable(fid) {

    let monday = [];
    let tuesday = [];
    let wednesday = [];
    let thursday = [];
    let friday = [];
    let saturday = [];
    let sunday = [];

    let _newPlannerData = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    for (const dayKey in this.dayShiftsData) {

      for (const plan of this.dayShiftsData[dayKey]) {
        let skillsString = ``;
        const skillDayShifts = this.state.skillDayShifts['d' + plan.dagdienst_id];
        if (typeof skillDayShifts !== 'undefined') {
          for (const skill of skillDayShifts) {
            skillsString += `${skill.comp_id}`;
          }
        } else {
          skillsString = `null`;
        }
        const partDayShift = typeof this.state.partDayShifts['d' + plan.dagdienst_id] !== 'undefined' ? this.state.partDayShifts['d' + plan.dagdienst_id] : 'null';
        const planKey = `${plan.dagdienst_dag}${plan.dagdienst_begin}${plan.dagdienst_eind}${plan.dagdienst_pauze}${plan.functie_id}${partDayShift.bijz_id}${skillsString}`;
        if (typeof _newPlannerData[dayKey][planKey] === 'undefined') {
          _newPlannerData[dayKey][planKey] = {
            count: 1,
            fid: plan.functie_id,
            shifts: [plan],
          };
        } else {
          _newPlannerData[dayKey][planKey].count += 1;
          _newPlannerData[dayKey][planKey].shifts.push(plan);
        }
      }

    }

    for (const dayKey in _newPlannerData) {

      let dayArray = dayKey === 'mon' ? monday : dayKey === 'tue' ? tuesday : dayKey === 'wed' ? wednesday : dayKey === 'thu' ? thursday : dayKey === 'fri' ? friday : dayKey === 'sat' ? saturday : dayKey === 'sun' ? sunday : monday;
      const i = dayKey === 'mon' ? 0 : dayKey === 'tue' ? 1 : dayKey === 'wed' ? 2 : dayKey === 'thu' ? 3 : dayKey === 'fri' ? 4 : dayKey === 'sat' ? 5 : dayKey === 'sun' ? 6 : 0;

      for (const planKey in _newPlannerData[dayKey]) { // planner

        const planGroup = _newPlannerData[dayKey][planKey];

        if (planGroup.fid !== fid) {
          continue;
        }

        dayArray.push(this.getShiftTouchBoxTable(planGroup, i, planKey));

      }

    }

    // // DAY SHIFTS
    // for (const shift of this.dayShiftsData.mon) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     monday.push(this.getShiftTouchBoxTable(shift, 0));
    //   }
    //   if (typeof fid === 'undefined') {
    //     monday.push(this.getShiftTouchBoxTable(shift, 0));
    //   }
    // }
    // for (const shift of this.dayShiftsData.tue) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     tuesday.push(this.getShiftTouchBoxTable(shift, 1));
    //   }
    //   if (typeof fid === 'undefined') {
    //     tuesday.push(this.getShiftTouchBoxTable(shift, 1));
    //   }
    // }
    // for (const shift of this.dayShiftsData.wed) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     wednesday.push(this.getShiftTouchBoxTable(shift, 2));
    //   }
    //   if (typeof fid === 'undefined') {
    //     wednesday.push(this.getShiftTouchBoxTable(shift, 2));
    //   }
    // }
    // for (const shift of this.dayShiftsData.thu) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     thursday.push(this.getShiftTouchBoxTable(shift, 3));
    //   }
    //   if (typeof fid === 'undefined') {
    //     thursday.push(this.getShiftTouchBoxTable(shift, 3));
    //   }
    // }
    // for (const shift of this.dayShiftsData.fri) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     friday.push(this.getShiftTouchBoxTable(shift, 4));
    //   }
    //   if (typeof fid === 'undefined') {
    //     friday.push(this.getShiftTouchBoxTable(shift, 4));
    //   }
    // }
    // for (const shift of this.dayShiftsData.sat) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     saturday.push(this.getShiftTouchBoxTable(shift, 5));
    //   }
    //   if (typeof fid === 'undefined') {
    //     saturday.push(this.getShiftTouchBoxTable(shift, 5));
    //   }
    // }
    // for (const shift of this.dayShiftsData.sun) {
    //   if (typeof fid !== 'undefined' && shift.functie_id === fid) {
    //     sunday.push(this.getShiftTouchBoxTable(shift, 6));
    //   }
    //   if (typeof fid === 'undefined') {
    //     sunday.push(this.getShiftTouchBoxTable(shift, 6));
    //   }
    // }

    if (monday.length === 0 && tuesday.length === 0 && wednesday.length === 0 && thursday.length === 0 && friday.length === 0 && saturday.length === 0 && sunday.length === 0) {
      return (null);
    }

    if (monday.length === 0) {
      monday.push(null);
    }
    if (tuesday.length === 0) {
      tuesday.push(null);
    }
    if (wednesday.length === 0) {
      wednesday.push(null);
    }
    if (thursday.length === 0) {
      thursday.push(null);
    }
    if (friday.length === 0) {
      friday.push(null);
    }
    if (saturday.length === 0) {
      saturday.push(null);
    }
    if (sunday.length === 0) {
      sunday.push(null);
    }

    return(

      <div className='planner_table_nameContainer2' style={{borderTop: '1px solid #e1e1e1', marginTop: 3, paddingTop: 5}}>

        {/* NAME */}
        <div className='planner_table_nameContainer2_name'>
          <div className='planner_table_nameContainer2_name_sub1' style={{alignItems: 'flex-start', fontWeight: 'bold', fontSize: this.state.display === 'XCL' ? '0.6vw' : null}}>Standaard diensten</div>
        </div>

        {/* MONDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.mondayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.mondayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {monday}
        </div>
    
        {/* TUESDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.tuesdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.tuesdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {tuesday}
        </div>
    
        {/* WEDNESDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.wednesdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.wednesdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {wednesday}
        </div>
    
        {/* THURSDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.thursdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.thursdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {thursday}
        </div>
    
        {/* FRIDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.fridayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.fridayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {friday}
        </div>
    
        {/* SATURDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.saturdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.saturdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {saturday}
        </div>
    
        {/* SUNDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.sundayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.sundayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {sunday}
        </div>

      </div>

    );

  }

  renderEmptyShiftsTable(fid) {

    let monday = [];
    let tuesday = [];
    let wednesday = [];
    let thursday = [];
    let friday = [];
    let saturday = [];
    let sunday = [];

    // DAY SHIFTS
    for (const shift of this.emptyPlannerData.mon) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        monday.push(this.getPlannerTouchBoxTable(shift, 0));
      }
    }
    for (const shift of this.emptyPlannerData.tue) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        tuesday.push(this.getPlannerTouchBoxTable(shift, 1));
      }
    }
    for (const shift of this.emptyPlannerData.wed) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        wednesday.push(this.getPlannerTouchBoxTable(shift, 2));
      }
    }
    for (const shift of this.emptyPlannerData.thu) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        thursday.push(this.getPlannerTouchBoxTable(shift, 3));
      }
    }
    for (const shift of this.emptyPlannerData.fri) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        friday.push(this.getPlannerTouchBoxTable(shift, 4));
      }
    }
    for (const shift of this.emptyPlannerData.sat) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        saturday.push(this.getPlannerTouchBoxTable(shift, 5));
      }
    }
    for (const shift of this.emptyPlannerData.sun) {
      if (typeof fid !== 'undefined' && shift.functie_id === fid) {
        sunday.push(this.getPlannerTouchBoxTable(shift, 6));
      }
    }

    let highest = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    for (const key in this.emptyPlannerData) {
      for (const shift of this.emptyPlannerData[key]) {
        if (shift.functie_id === fid) {
          highest[key] += 1;
        }
      }
    }

    let highestLength = 0;

    for (const key in highest) {
      if (highest[key] > highestLength) {
        highestLength = highest[key];
      }
    }

    if (monday.length > 0 || tuesday.length > 0 || wednesday.length > 0 || thursday.length > 0 || friday.length > 0 || saturday.length > 0 || sunday.length > 0) {

      for (let i = monday.length; i < highestLength; i++) {
        monday.push(this.getFillEmptyShiftTouchBoxTable(0, 'ef' + i, fid));
      }
      for (let i = tuesday.length; i < highestLength; i++) {
        tuesday.push(this.getFillEmptyShiftTouchBoxTable(1, 'ef' + i, fid));
      }
      for (let i = wednesday.length; i < highestLength; i++) {
        wednesday.push(this.getFillEmptyShiftTouchBoxTable(2, 'ef' + i, fid));
      }
      for (let i = thursday.length; i < highestLength; i++) {
        thursday.push(this.getFillEmptyShiftTouchBoxTable(3, 'ef' + i, fid));
      }
      for (let i = friday.length; i < highestLength; i++) {
        friday.push(this.getFillEmptyShiftTouchBoxTable(4, 'ef' + i, fid));
      }
      for (let i = saturday.length; i < highestLength; i++) {
        saturday.push(this.getFillEmptyShiftTouchBoxTable(5, 'ef' + i, fid));
      }
      for (let i = sunday.length; i < highestLength; i++) {
        sunday.push(this.getFillEmptyShiftTouchBoxTable(6, 'ef' + i, fid));
      }

    }

    if (this.state.addEmptyRows[fid] > 0) {
      for (let i = 0; i < this.state.addEmptyRows[fid]; i++) {
        monday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e' + i, fid));
        tuesday.push(this.getFillEmptyShiftTouchBoxTable(1, 'e' + i, fid));
        wednesday.push(this.getFillEmptyShiftTouchBoxTable(2, 'e' + i, fid));
        thursday.push(this.getFillEmptyShiftTouchBoxTable(3, 'e' + i, fid));
        friday.push(this.getFillEmptyShiftTouchBoxTable(4, 'e' + i, fid));
        saturday.push(this.getFillEmptyShiftTouchBoxTable(5, 'e' + i, fid));
        sunday.push(this.getFillEmptyShiftTouchBoxTable(6, 'e' + i, fid));
      }
    }

    // if (monday.length > 0 || tuesday.length > 0 || wednesday.length > 0 || thursday.length > 0 || friday.length > 0 || saturday.length > 0 || sunday.length > 0) {
    //   monday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    //   tuesday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    //   wednesday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    //   thursday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    //   friday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    //   saturday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    //   sunday.push(this.getFillEmptyShiftTouchBoxTable(0, 'e1'));
    // }

    return(

      <div className='planner_table_nameContainer2' style={{borderTop: '1px solid #e1e1e1', marginTop: 3, paddingTop: 5}}>

        {/* NAME */}
        <div className='planner_table_nameContainer2_name'>

          <div className='planner_table_nameContainer2_name_sub1' style={{alignItems: 'flex-start', fontWeight: 'bold', fontSize: this.state.display === 'XCL' ? '0.6vw' : null}}>Open diensten</div>

          <div className='planner_table_nameContainer2_name_sub2' style={{alignItems: 'flex-start', fontSize: this.state.display === 'XCL' ? '0.6vw' : null}}>
            <i className='planner_hoverIcon2 fas fa-plus-circle' onClick={() => this.setAddEmptyRows(fid)} />
          </div>
          
        </div>

         {/* MONDAY */}
         <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.mondayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.mondayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {monday}
        </div>
    
        {/* TUESDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.tuesdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.tuesdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {tuesday}
        </div>
    
        {/* WEDNESDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.wednesdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.wednesdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {wednesday}
        </div>
    
        {/* THURSDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.thursdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.thursdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {thursday}
        </div>
    
        {/* FRIDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.fridayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.fridayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {friday}
        </div>
    
        {/* SATURDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.saturdayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.saturdayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {saturday}
        </div>
    
        {/* SUNDAY */}
        <div className='planner_table_nameContainer2_sub'
          onMouseEnter={() => document.getElementById(this.state.sundayDate).style.color = '#47B7EB'} 
          onMouseLeave={() => document.getElementById(this.state.sundayDate).style.color = '#282828'} 
          style={{justifyContent: 'flex-start'}}
        >
          {sunday}
        </div>

      </div>

    );

  }

  setAllAddEmptyRows(count) {

    let addEmptyRows = this.state.addEmptyRows;

    for (const key in addEmptyRows) {
      addEmptyRows[key] = count;
    }

    this.setState({ addEmptyRows: addEmptyRows });

  }

  setAddEmptyRows(fid) {

    let addEmptyRows = this.state.addEmptyRows;

    if (typeof addEmptyRows[fid] !== 'undefined') {
      addEmptyRows[fid] += 1;
    }

    this.setState({ addEmptyRows: addEmptyRows });

  }

  setAllAddEmptyRowsUser(count) {

    let addEmptyRowsUser = this.state.addEmptyRowsUser;

    for (const key in addEmptyRowsUser) {
      addEmptyRowsUser[key] = count;
    }

    this.setState({ addEmptyRowsUser: addEmptyRowsUser });

  }

  setAddEmptyRowsUser(userID) {

    let addEmptyRowsUser = this.state.addEmptyRowsUser;

    if (typeof addEmptyRowsUser[userID] !== 'undefined') {
      addEmptyRowsUser[userID] += 1;
    }

    this.setState({ addEmptyRowsUser: addEmptyRowsUser });

  }

  getExpectationDay(day) {

    let toReturn = [];
    let toRender = [];

    let lowest = this.state.expectedLoadMinMax[day].min;
    let highest = this.state.expectedLoadMinMax[day].max;

    let maxAmount = 0;

    for (let i = lowest; i < highest; i++) { // i === 12 ? nextHour = i + 1 = 13

      let amount = 0;
  
      for (const plan of this.state.expectedLoad[day]) {
        if (Constants.timeStringToFloat(plan.rooster_begin) >= i && Constants.timeStringToFloat(plan.rooster_begin) < (i + 1)) {
          amount++;
        } else if (Constants.timeStringToFloat(plan.rooster_eind) > i && Constants.timeStringToFloat(plan.rooster_eind) <= (i + 1)) {
          amount++;
        } else if (i > Constants.timeStringToFloat(plan.rooster_begin) && i < Constants.timeStringToFloat(plan.rooster_eind)) {
          amount++;
        }
      }
  
      if (amount > maxAmount) {
        maxAmount = amount;
      }

    }

    let factor = maxAmount > 10 ? (maxAmount / 10) : 1;
    const max = maxAmount / factor;

    // max blocks = 10; so max employees > 10 ? max / 10 = factor divider
    for (let i = lowest; i < highest; i++) { // i === 12 ? nextHour = i + 1 = 13

      let expectBlocks = [];
      let amount = 0;

      for (const plan of this.state.expectedLoad[day]) {
        if (Constants.timeStringToFloat(plan.rooster_begin) >= i && Constants.timeStringToFloat(plan.rooster_begin) < (i + 1)) {
          amount++;
        } else if (Constants.timeStringToFloat(plan.rooster_eind) > i && Constants.timeStringToFloat(plan.rooster_eind) <= (i + 1)) {
          amount++;
        } else if (i > Constants.timeStringToFloat(plan.rooster_begin) && i < Constants.timeStringToFloat(plan.rooster_eind)) {
          amount++;
        }
      }

      amount = amount / factor;

      for (let j = 0; j < amount; j++) {

        expectBlocks.push(

          <div className='planner_expectBlock' style={{backgroundColor: amount === max ? Colors.color.redFilledIn : null}}>
            &nbsp;
          </div>

        );

      }

      toReturn.push(

        <div className='planner_expectColumn'>

          <div className='planner_expectBlockColumn'>
            {expectBlocks}
          </div>

          <div className='planner_expectLine'>
            {i === (highest - 1) ?
              <div className='planner_expectLine_2'>{i > lowest && i < highest && amount === max ? i : null}&nbsp;</div>
            : 
              <div className='planner_expectLine_1'>{i > lowest && i < highest && amount === max ? i : null}&nbsp;</div>
            }
          </div>

        </div>

      );

    }

    // {lowest === 24 && max === 0 && maxAmount === 0 ?
    //   null
    // :

    toRender.push(

      <div className='planner_expectContainerOuter'>

        <div className='planner_expectContainer'>

          {toReturn}

        </div>

        <div className='planner_expectStartEnd'>
          <span>{lowest < 10 ? '0' + lowest : lowest}:00</span>
          <span>{highest < 10 ? '0' + highest : highest}:00</span>
        </div>

      </div>

    );

    return toRender;

  }

  async editUserFunction(user, fid) {

    if (user.functie_id === fid) {
      this.closeAllUserMenus();
      return;
    }

    this.setState({reloading: true});

    this.closeAllUserMenus();

    if (fid === null) {
      fid = 'null';
    }

    const edited = await APIEditAccountJob.Request(user.info_id, fid);

    if (edited === true) {
      await this.reloadScreen2(); //
    }

    this.setState({reloading: false});

  }

  getUserHoursWeek(userID) {

    let hours = 0.00;

    for (const key in this.allPlannerData) {
      for (const data of this.allPlannerData[key]) {
        if (data.info_id === userID) {
          // if (typeof this.state.absenceSchedules[data.rooster_id] !== 'undefined' && Constants.objectArrayContainsKey(this.state.exclusions, 'uit_afw_id', this.state.absenceSchedules[data.rooster_id].afw_id)) {
          //   continue;
          // }
          const startTime = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
          const endTime = Constants.stringToDateTime(Plan.isNightShift2(data), data.rooster_eind);
          const breakTime = Constants.timeStringToMS(data.rooster_pauze);
          hours += (endTime.getTime() - startTime.getTime()) - breakTime;
        }
      }
    }

    if (Constants.isEmpty(this.unpublishedRepeatingShifts[userID]) === false) {

      for (const r of this.unpublishedRepeatingShifts[userID]) {

        let _break = `00:00:00`;
        if (Constants.isEmpty(r.hr_pauze) === false) {
          _break = `${r.hr_pauze}`;
        }

        const data = {
            hr_team_id: r.hr_team_id,
            info_id: r.hr_info_id,
            rooster_datum: Constants.dateToString(r.date),
            rooster_begin: r.hr_begin,
            rooster_eind: r.hr_eind,
            rooster_pauze: _break,
            functie_id: r.functie_id, //typeof data.functie_id !== 'undefined' && data.functie_id !== null ? data.functie_id : 
            functie_naam: r.functie_naam,
        }

        const startTime = Constants.stringToDateTime(data.rooster_datum, data.rooster_begin);
        const endTime = Constants.stringToDateTime(Plan.isNightShift2(data), data.rooster_eind);
        const breakTime = Constants.timeStringToMS(data.rooster_pauze);
        hours += (endTime.getTime() - startTime.getTime()) - breakTime;

      }
      
    }

    return hours;

  }

  getShiftTemplates() {

    let toReturn = [];

    for (const temp of this.state.shiftTemplates) {

      toReturn.push(
        <option key={'shiftTemp' + temp.dagdienstsjabloon_id} value={temp.dagdienstsjabloon_id} selected={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id)}>{temp.dagdienstsjabloon_naam}</option>
        // <div key={temp.dagdienstsjabloon_id} className='planner_settingsMenu_row settings_Asset2' onClick={() => this.selectTemplate(temp.dagdienstsjabloon_id)}>
        //   <div className='planner_settingsMenu_row_sub1 settings_Asset2'>
        //     <i className='fal fa-copy settings_Asset2' style={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id) ? {color: '#47B7EB'} : null} />
        //   </div>
        //   <div className='planner_settingsMenu_row_sub2 settings_Asset2' style={this.state.shiftTemp === parseInt(temp.dagdienstsjabloon_id) ? {color: '#47B7EB'} : null}>
        //     {temp.dagdienstsjabloon_naam}
        //   </div>
        //   <div className='planner_settingsMenu_row_sub3 settings_Asset2'>
        //     {this.state.shiftTemp !== parseInt(temp.dagdienstsjabloon_id) ?
        //       <i className='fal fa-arrow-right settings_Asset2' />
        //     : null}
        //   </div>
        // </div>
      )

    }

    return toReturn;

  }

  async selectTemplate(event) {

    const id = event.target.value;

    if (this.state.shiftTemp === parseInt(id)) {
      return;
    }

    this.closeSettings();

    this.setState({loading: true, shiftTemp: parseInt(id), shiftTempName: this.getShiftTempName(parseInt(id)) }, async() => {

      await this.reloadScreen2FullScreen();

      this.setState({loading: false});

    });

  }

  toggleSalary() {

    localStorage.setItem('@showSalary', this.state.showSalary === true ? '0' : '1');

    this.setState({showSalary: !this.state.showSalary});

  }

  toggleProductivity() {

    localStorage.setItem('@showProductivity', this.state.showProductivity === true ? '0' : '1');

    this.setState({showProductivity: !this.state.showProductivity});

  }

  toggleGains() {

    localStorage.setItem('@showGains', this.state.showGains === true ? '0' : '1');

    this.setState({showGains: !this.state.showGains});

  }

  toggleGainsExpect() {

    localStorage.setItem('@showGainsExpect', this.state.showGainsExpect === true ? '0' : '1');

    this.setState({showGainsExpect: !this.state.showGainsExpect});

  }

  togglePreviousGains() {

    localStorage.setItem('@showPreviousGains', this.state.showPreviousGains === true ? '0' : '1');

    this.setState({showPreviousGains: !this.state.showPreviousGains});

  }

  toggleCrowds() {

    localStorage.setItem('@showCrowds', this.state.showCrowds === true ? '0' : '1');

    this.setState({showCrowds: !this.state.showCrowds});

  }

  toggleExtraOpenShifts() {

    localStorage.setItem('@showExtraOpenShifts', this.state.showExtraOpenShifts === true ? '0' : '1');

    this.setState({showExtraOpenShifts: !this.state.showExtraOpenShifts});

  }

  toggleDetails() {

    localStorage.setItem('@showDetails', this.state.showDetails === true ? '0' : '1');

    this.setState({showDetails: !this.state.showDetails});

  }

  toggleContractHours() {

    localStorage.setItem('@showContractHours', this.state.showContractHours === true ? '0' : '1');

    this.setState({showContractHours: !this.state.showContractHours});

  }

  handleKeyOmzet(e) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.editOmzetDagelijks();

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 188 || e.keyCode === 190 || e.keyCode === 189 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  handleKeyOmzetV(e) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.editOmzetDagelijksV();

      return false;
    }

    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 188 || e.keyCode === 190 || e.keyCode === 189 || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) { 
      
    } else {

      e.preventDefault();
      //e.target.value = e.target.value.toString().slice(0, -1);

    }

  }

  async editOmzetDagelijks() {

    let inDB = [false, false, false, false, false, false, false];

    let omzetDagen = [
      isNaN(parseFloat(this.inputMon.value)) ? 0 : parseFloat(this.inputMon.value), 
      isNaN(parseFloat(this.inputTue.value)) ? 0 : parseFloat(this.inputTue.value), 
      isNaN(parseFloat(this.inputWed.value)) ? 0 : parseFloat(this.inputWed.value), 
      isNaN(parseFloat(this.inputThu.value)) ? 0 : parseFloat(this.inputThu.value), 
      isNaN(parseFloat(this.inputFri.value)) ? 0 : parseFloat(this.inputFri.value), 
      isNaN(parseFloat(this.inputSat.value)) ? 0 : parseFloat(this.inputSat.value), 
      isNaN(parseFloat(this.inputSun.value)) ? 0 : parseFloat(this.inputSun.value), 
    ];

    for (const omzet of this.state.dailyGains) {

      if (this.state.mondayDate === omzet.omzetd_datum) {
        inDB[0] = true;
        const localVal = parseFloat(this.inputMon.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }
      else if (this.state.tuesdayDate === omzet.omzetd_datum) {
        inDB[1] = true;
        const localVal = parseFloat(this.inputTue.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }
      else if (this.state.wednesdayDate === omzet.omzetd_datum) {
        inDB[2] = true;
        const localVal = parseFloat(this.inputWed.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }
      else if (this.state.thursdayDate === omzet.omzetd_datum) {
        inDB[3] = true;
        const localVal = parseFloat(this.inputThu.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }
      else if (this.state.fridayDate === omzet.omzetd_datum) {
        inDB[4] = true;
        const localVal = parseFloat(this.inputFri.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }
      else if (this.state.saturdayDate === omzet.omzetd_datum) {
        inDB[5] = true;
        const localVal = parseFloat(this.inputSat.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }
      else if (this.state.sundayDate === omzet.omzetd_datum) {
        inDB[6] = true;
        const localVal = parseFloat(this.inputSun.value);
        if (localVal !== parseFloat(omzet.omzetd_hoeveelheid)) {
          await APIEditDailyGains.Request(omzet.omzetd_id, isNaN(localVal) ? 0 : localVal);
        }
      }

    }

    if (inDB[0] === false && parseFloat(this.inputMon.value) > 0) {
      const localVal = parseFloat(this.inputMon.value);
      await APIAddDailyGains.Request(this.state.mondayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[1] === false && parseFloat(this.inputTue.value) > 0) {
      const localVal = parseFloat(this.inputTue.value);
      await APIAddDailyGains.Request(this.state.tuesdayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[2] === false && parseFloat(this.inputWed.value) > 0) {
      const localVal = parseFloat(this.inputWed.value);
      await APIAddDailyGains.Request(this.state.wednesdayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[3] === false && parseFloat(this.inputThu.value) > 0) {
      const localVal = parseFloat(this.inputThu.value);
      await APIAddDailyGains.Request(this.state.thursdayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[4] === false && parseFloat(this.inputFri.value) > 0) {
      const localVal = parseFloat(this.inputFri.value);
      await APIAddDailyGains.Request(this.state.fridayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[5] === false && parseFloat(this.inputSat.value) > 0) {
      const localVal = parseFloat(this.inputSat.value);
      await APIAddDailyGains.Request(this.state.saturdayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[6] === false && parseFloat(this.inputSun.value) > 0) {
      const localVal = parseFloat(this.inputSun.value);
      await APIAddDailyGains.Request(this.state.sundayDate, isNaN(localVal) ? 0 : localVal);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetd_id`, `FROM OmzetDagelijks`, null, null, null, `ORDER BY omzetd_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${omzetID[0].omzetd_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }

    let dailyGains = [];
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      dailyGains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, `RIGHT JOIN TeamOmzet ON to_omzetd_id = omzetd_id`, `WHERE (omzetd_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') AND to_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetd_datum`);
    } else {
      dailyGains = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, `WHERE (omzetd_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}')`, null, `ORDER BY omzetd_datum`);
    }

    if (typeof dailyGains !== 'undefined') {

      for (const gain of dailyGains) {
        if (gain.omzetd_datum === this.state.mondayDate) {
          omzetDagen[0] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.tuesdayDate) {
          omzetDagen[1] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.wednesdayDate) {
          omzetDagen[2] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.thursdayDate) {
          omzetDagen[3] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.fridayDate) {
          omzetDagen[4] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.saturdayDate) {
          omzetDagen[5] = parseFloat(gain.omzetd_hoeveelheid);
        } else if (gain.omzetd_datum === this.state.sundayDate) {
          omzetDagen[6] = parseFloat(gain.omzetd_hoeveelheid);
        }
      }
    }

    this.setState({ omzetDagen: omzetDagen, editOmzet: false, dailyGains: dailyGains });

  }

  async editOmzetDagelijksV() {

    let inDB = [false, false, false, false, false, false, false];

    let omzetDagenV = [
      isNaN(parseFloat(this.inputMonV.value)) ? 0 : parseFloat(this.inputMonV.value),
      isNaN(parseFloat(this.inputTueV.value)) ? 0 : parseFloat(this.inputTueV.value), 
      isNaN(parseFloat(this.inputWedV.value)) ? 0 : parseFloat(this.inputWedV.value), 
      isNaN(parseFloat(this.inputThuV.value)) ? 0 : parseFloat(this.inputThuV.value), 
      isNaN(parseFloat(this.inputFriV.value)) ? 0 : parseFloat(this.inputFriV.value), 
      isNaN(parseFloat(this.inputSatV.value)) ? 0 : parseFloat(this.inputSatV.value), 
      isNaN(parseFloat(this.inputSunV.value)) ? 0 : parseFloat(this.inputSunV.value), 
    ];

    for (const omzet of this.state.dailyGainsExpect) {

      if (this.state.mondayDate === omzet.omzetv_datum) {
        inDB[0] = true;
        const localVal = parseFloat(this.inputMonV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }
      else if (this.state.tuesdayDate === omzet.omzetv_datum) {
        inDB[1] = true;
        const localVal = parseFloat(this.inputTueV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }
      else if (this.state.wednesdayDate === omzet.omzetv_datum) {
        inDB[2] = true;
        const localVal = parseFloat(this.inputWedV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }
      else if (this.state.thursdayDate === omzet.omzetv_datum) {
        inDB[3] = true;
        const localVal = parseFloat(this.inputThuV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }
      else if (this.state.fridayDate === omzet.omzetv_datum) {
        inDB[4] = true;
        const localVal = parseFloat(this.inputFriV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }
      else if (this.state.saturdayDate === omzet.omzetv_datum) {
        inDB[5] = true;
        const localVal = parseFloat(this.inputSatV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }
      else if (this.state.sundayDate === omzet.omzetv_datum) {
        inDB[6] = true;
        const localVal = parseFloat(this.inputSunV.value);
        if (localVal !== parseFloat(omzet.omzetv_hoeveelheid)) {
          await APIEDIT.Request(`UPDATE OmzetVerwachting`, `SET omzetv_hoeveelheid = '${isNaN(localVal) ? 0 : localVal}'`, `WHERE omzetv_id = ${omzet.omzetv_id}`);
        }
      }

    }

    if (inDB[0] === false && parseFloat(this.inputMonV.value) > 0) {
      const localVal = parseFloat(this.inputMonV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.mondayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[1] === false && parseFloat(this.inputTueV.value) > 0) {
      const localVal = parseFloat(this.inputTueV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.tuesdayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[2] === false && parseFloat(this.inputWedV.value) > 0) {
      const localVal = parseFloat(this.inputWedV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.wednesdayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[3] === false && parseFloat(this.inputThuV.value) > 0) {
      const localVal = parseFloat(this.inputThuV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.thursdayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[4] === false && parseFloat(this.inputFriV.value) > 0) {
      const localVal = parseFloat(this.inputFriV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.fridayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[5] === false && parseFloat(this.inputSatV.value) > 0) {
      const localVal = parseFloat(this.inputSatV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.saturdayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }
    if (inDB[6] === false && parseFloat(this.inputSunV.value) > 0) {
      const localVal = parseFloat(this.inputSunV.value);
      await APIADD.Request(`INSERT INTO OmzetVerwachting`, `VALUES (NULL, '${this.state.sundayDate}', ${isNaN(localVal) ? 0 : localVal})`);
      if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
        const omzetID = await APIGET.Request(`SELECT omzetv_id`, `FROM OmzetVerwachting`, null, null, null, `ORDER BY omzetv_id DESC LIMIT 1`);
        await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${omzetID[0].omzetv_id}, ${Data.data.chosenTeamObj.team_id})`);
      }
    }

    let dailyGainsExpect = [];
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      dailyGainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, `RIGHT JOIN TeamOmzetV ON tov_omzetv_id = omzetv_id`, `WHERE (omzetv_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') AND tov_team_id = ${Data.data.chosenTeamObj.team_id}`, null, `ORDER BY omzetv_datum`);
    } else {
      dailyGainsExpect = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, `WHERE (omzetv_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}')`, null, `ORDER BY omzetv_datum`);
    }

    if (typeof dailyGainsExpect !== 'undefined') {

      for (const gain of dailyGainsExpect) {
        if (gain.omzetv_datum === this.state.mondayDate) {
          omzetDagenV[0] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.tuesdayDate) {
          omzetDagenV[1] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.wednesdayDate) {
          omzetDagenV[2] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.thursdayDate) {
          omzetDagenV[3] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.fridayDate) {
          omzetDagenV[4] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.saturdayDate) {
          omzetDagenV[5] = parseFloat(gain.omzetv_hoeveelheid);
        } else if (gain.omzetv_datum === this.state.sundayDate) {
          omzetDagenV[6] = parseFloat(gain.omzetv_hoeveelheid);
        }
      }
    }

    this.setState({ omzetDagenV: omzetDagenV, editOmzetV: false, dailyGainsExpect: dailyGainsExpect });

  }

  sortBy(sort, force) {

    if (this.state.sortBy === sort && force !== true) {
      return;
    }

    this.closeSettings();

    let available = this.availableData;
    let notFilled = this.notFilledData;
    let unavailable = this.unavailableData;

    let notFilledSchool = this.notFilledSchool;
    let schoolHoliday = this.schoolHolidayData;
    let school = this.schoolScheduleData;

    let noAvailability = this.usersNoAvailability;

    let standardAvailable = this.standardAvailableData;
    let standardNotFilled = this.standardNotFilledData;
    let standardUnavailable = this.standardUnavailableData;

    if (sort === 'availability') {

      available.mon.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.tue.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.wed.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.thu.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.fri.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sat.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sun.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      unavailable.mon.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.tue.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.wed.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.thu.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.fri.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sat.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sun.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilledSchool.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      schoolHoliday.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      school.mon.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.tue.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.wed.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.thu.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.fri.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sat.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sun.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      noAvailability.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardAvailable.mon.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.tue.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.wed.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.thu.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.fri.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sat.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sun.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
      standardUnavailable.mon.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.tue.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.wed.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.thu.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.fri.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sat.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sun.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
      standardNotFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

    }

    if (sort === 'name') {

      available.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);

      unavailable.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);

      notFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilledSchool.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      schoolHoliday.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      school.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);

      noAvailability.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
      standardAvailable.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      
      standardUnavailable.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      
      standardNotFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
    }

    if (sort === 'job') {

      available.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      unavailable.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilled.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilledSchool.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      schoolHoliday.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      school.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      noAvailability.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardAvailable.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardUnavailable.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardNotFilled.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

    }

    this.availableData = available;
    this.unavailableData = unavailable;
    this.notFilledData = notFilled;

    this.schoolScheduleData = school;
    this.schoolHolidayData = schoolHoliday;
    this.notFilledSchool = notFilledSchool;

    this.usersNoAvailability = noAvailability;

    localStorage.setItem('@mwSortBy', sort);

    this.setState({ sortBy: sort });

  }

  sortBy_new(sort, force) {

    if (this.state.sortBy === sort && force !== true) {
      return;
    }

    this.closeSettings();

    let available = this.availableData;
    let notFilled = this.notFilledData;
    let unavailable = this.unavailableData;

    let notFilledSchool = this.notFilledSchool;
    let schoolHoliday = this.schoolHolidayData;
    let school = this.schoolScheduleData;

    let noAvailability = this.usersNoAvailability;

    let standardAvailable = this.standardAvailableData;
    let standardNotFilled = this.standardNotFilledData;
    let standardUnavailable = this.standardUnavailableData;

    if (sort === 'availability') {

      available.mon.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.tue.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.wed.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.thu.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.fri.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sat.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sun.sort((a, b) => (a.beschikbaar_begin > b.beschikbaar_begin || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      unavailable.mon.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.tue.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.wed.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.thu.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.fri.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sat.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sun.sort((a, b) => (a.onbeschikbaar_begin > b.onbeschikbaar_begin || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilledSchool.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      schoolHoliday.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      school.mon.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.tue.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.wed.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.thu.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.fri.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sat.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sun.sort((a, b) => (a.schoolrooster_eind > b.schoolrooster_eind || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      noAvailability.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardAvailable.mon.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.tue.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.wed.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.thu.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.fri.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sat.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sun.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
      standardUnavailable.mon.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.tue.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.wed.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.thu.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.fri.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sat.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sun.sort((a, b) => (a.sb_begin > b.sb_begin || (a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
      standardNotFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

    }

    if (sort === 'name') {

      available.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);
      available.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.beschikbaar_begin > b.beschikbaar_begin)) ? 1 : -1);

      unavailable.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);
      unavailable.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.onbeschikbaar_begin > b.onbeschikbaar_begin)) ? 1 : -1);

      notFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilledSchool.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      schoolHoliday.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      school.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);
      school.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.schoolrooster_eind > b.schoolrooster_eind)) ? 1 : -1);

      noAvailability.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
      standardAvailable.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardAvailable.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      
      standardUnavailable.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      standardUnavailable.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam) || (a.info_voornaam === b.info_voornaam && a.info_achternaam === b.info_achternaam && a.sb_begin > b.sb_begin)) ? 1 : -1);
      
      standardNotFilled.mon.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.tue.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.wed.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.thu.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.fri.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sat.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sun.sort((a, b) => (a.info_voornaam > b.info_voornaam || (a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      
    }

    if (sort === 'job') {

      available.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      available.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.beschikbaar_begin > b.beschikbaar_begin) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind < b.beschikbaar_eind) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.beschikbaar_begin === b.beschikbaar_begin && a.beschikbaar_eind === b.beschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      unavailable.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      unavailable.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin > b.onbeschikbaar_begin) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind < b.onbeschikbaar_eind) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.onbeschikbaar_begin === b.onbeschikbaar_begin && a.onbeschikbaar_eind === b.onbeschikbaar_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilled.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilled.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      notFilledSchool.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      notFilledSchool.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      schoolHoliday.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      schoolHoliday.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      school.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      school.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.schoolrooster_eind > b.schoolrooster_eind) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.schoolrooster_eind === b.schoolrooster_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      noAvailability.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      noAvailability.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardAvailable.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardAvailable.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardUnavailable.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardUnavailable.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.sb_begin > b.sb_begin) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind < b.sb_eind) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.sb_begin === b.sb_begin && a.sb_eind === b.sb_eind && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

      standardNotFilled.mon.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.tue.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.wed.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.thu.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.fri.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sat.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);
      standardNotFilled.sun.sort((a, b) => (a.functie_naam === null || a.functie_naam > b.functie_naam || (a.functie_naam === b.functie_naam && a.info_voornaam > b.info_voornaam) || (a.functie_naam === b.functie_naam && a.info_voornaam === b.info_voornaam && a.info_achternaam > b.info_achternaam)) ? 1 : -1);

    }

    this.availableData = available;
    this.unavailableData = unavailable;
    this.notFilledData = notFilled;

    this.schoolScheduleData = school;
    this.schoolHolidayData = schoolHoliday;
    this.notFilledSchool = notFilledSchool;

    this.usersNoAvailability = noAvailability;

    localStorage.setItem('@mwSortBy', sort);

    return { sortBy: sort  };

    // this.setState({ sortBy: sort });

  }

  chooseFunctionIteration() {

    if (this.dontBlur === true) {
      return;
    }

    if (this.state.functionsUser.length > 1) {

      this.dontBlur = true;

      setTimeout(() => {
        this.dontBlur = false;
      }, 250);

      const pos = this.state.functionsUser.findIndex(x => x.functie_id === this.state.secondaryFunctionID);
      const nextPos = pos + 1 === this.state.functionsUser.length ? 0 : pos + 1;

      this.setState({
        secondaryFunctionColor: `#${this.state.functionsUser[nextPos].functie_kleur}`,
        secondaryFunctionID: this.state.functionsUser[nextPos].functie_id,
        secondaryFunctionName: this.state.functionsUser[nextPos].functie_naam,
      });

    }

  }

  openEditOmzetDagelijks(day) {

    this.setState({ editOmzet: true }, () => {

      if (day === 'monday') {
        this.inputMon.focus();
      } else if (day === 'tuesday') {
        this.inputTue.focus();
      } else if (day === 'wednesday') {
        this.inputWed.focus();
      } else if (day === 'thursday') {
        this.inputThu.focus();
      } else if (day === 'friday') {
        this.inputFri.focus();
      } else if (day === 'saturday') {
        this.inputSat.focus();
      } else if (day === 'sunday') {
        this.inputSun.focus();
      }
      
    });

  }

  openEditOmzetDagelijksV(day) {

    this.setState({ editOmzetV: true }, () => {

      if (day === 'monday') {
        this.inputMonV.focus();
      } else if (day === 'tuesday') {
        this.inputTueV.focus();
      } else if (day === 'wednesday') {
        this.inputWedV.focus();
      } else if (day === 'thursday') {
        this.inputThuV.focus();
      } else if (day === 'friday') {
        this.inputFriV.focus();
      } else if (day === 'saturday') {
        this.inputSatV.focus();
      } else if (day === 'sunday') {
        this.inputSunV.focus();
      }
      
    });

  }

  getDayData(key, key2, index, table, _dateObj) {

    const special = key === 'mon' && table == false;
    const _dayName2 = index === 0 ? 'Maandag' : index === 1 ? 'Dinsdag' : index === 2 ? 'Woensdag' : index === 3 ? 'Donderdag' : index === 4 ? 'Vrijdag' : index === 5 ? 'Zaterdag' : index === 6 ? 'Zondag' : '`Maandag';

    const dayNote = this.showDayNote(key);
    const dayNoteData = this.getDayNoteData(key);
    const holidayPublic = Constants.getNationalHoliday(_dateObj, this.state.publicHolidays);

    return(

      <div className='plannerN_dateContainer_sub'>

        <div className='plannerN_dateContainer_sub_row_top' style={{paddingBottom: holidayPublic !== null || dayNote !== null ? 0 : '0.5vw', backgroundColor: dayNote !== null ? Constants.hexToRgbA(`#${dayNoteData.dn_kleur}`, 0.1) : null}}>
          <span id={`${Constants.dateToString(_dateObj)}`}><b>{_dayName2} {_dateObj.getDate()} {Constants.getMonthName(_dateObj).replace('.', '')}</b></span>
          <i className='fal fa-sticky-note planner_dayNoteIcon' onClick={() => this.openDayNoteModal(key)} />
        </div>

        {dayNote !== null ?
          <div className='plannerN_dateContainer_sub_row_wrap' style={{paddingBottom: holidayPublic !== null ? 0 : '0.5vw', backgroundColor: dayNote !== null ? Constants.hexToRgbA(`#${dayNoteData.dn_kleur}`, 0.1) : null}}>
            {dayNote}
          </div>
        : null}

        {holidayPublic !== null ?
          <div className='plannerN_dateContainer_sub_row_wrap' style={{paddingBottom: '0.5vw', backgroundColor: dayNote !== null ? Constants.hexToRgbA(`#${dayNoteData.dn_kleur}`, 0.1) : null}}>
            {holidayPublic}
          </div>
        : null}

        {this.state.opties_planner_drukte === true ?
          this.getExpectationDay(key)
        : null}

        {this.state.opties_planner_vorigOmzet === true ?
          <div className='plannerN_dateContainer_sub_row2'>
            {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`} style={{fontStyle: 'italic', paddingRight: '1%'}}>Omzet {this.state.year - 1}</span> : null}
            <span className={`planner_dateText_sub${special ? '2' : ''}`} style={{width: special ? '53%' : null, fontStyle: 'italic', textAlign: 'right', paddingRight: '1%'}}>{this.state.previousGains[key] === null ? this.formatter.format(0) : this.formatter.format(this.state.previousGains[key])}</span>
          </div>
        : null}

        {this.state.opties_planner_verwachteOmzet === true ?
          <div>
            {this.state.editOmzetV === true ?
              <div className='plannerN_dateContainer_sub_row2'>
                {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}>{this.state.editOmzetV ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn, float: 'none'}} onClick={() => this.editOmzetDagelijksV()} /> : <i className='fal fa-pen' style={{float: 'none'}} onClick={() => this.setState({ editOmzetV: true })} />} V. omzet</span> : null}
                <input 
                  ref={(ref) => index === 0 ? this.inputMonV=ref : index === 1 ? this.inputTueV=ref : index === 2 ? this.inputWedV=ref : index === 3 ? this.inputThuV=ref : index === 4 ? this.inputFriV=ref : index === 5 ? this.inputSatV=ref : index === 6 ? this.inputSunV=ref : this.inputMonV=ref}
                  className='planner_inputBoxOmzet'
                  defaultValue={this.state.omzetDagenV[index]}
                  onKeyDown={(event) => this.handleKeyOmzetV(event)}
                  placeholder={'Verwachte omzet'}
                  type={'number'}
                  step={0.01} 
                  style={special ? {width: '55%', marginLeft: 0} : {width: '100%'}}
                />
              </div>
            :
              <div className='plannerN_dateContainer_sub_row2'>
                {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}>{this.state.editOmzetV ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn, float: 'none'}} onClick={() => this.editOmzetDagelijksV()} /> : <i className='fal fa-pen' style={{float: 'none'}} onClick={() => this.setState({ editOmzetV: true })} />} V. omzet</span> : null}
                <span className={`planner_dateText_sub${special ? '2' : ''}`} onClick={() => this.openEditOmzetDagelijksV(key2)} style={{width: special ? '55%' : {width: '100%'}, cursor: 'text', border: '1px dashed #e1e1e1', textAlign: 'right'}}>{this.formatter.format(this.state.omzetDagenV[index])}</span>
              </div>
            // <span className='planner_dateText_sub' style={{paddingLeft: 0}}>{this.state.editOmzet ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn, float: 'none'}} onClick={() => this.editOmzetDagelijks()} /> : <i className='fal fa-pen' style={{float: 'none'}} onClick={() => this.setState({ editOmzet: true })} />} Omzet: {this.formatter.format(this.state.omzetDagen[0])}</span>
            }
          </div>
        : null}
                      
        {this.state.opties_planner_omzet === true ?
          <div>
            {this.state.editOmzet === true ?
              <div className='plannerN_dateContainer_sub_row2'>
                {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}>{this.state.editOmzet ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn, float: 'none'}} onClick={() => this.editOmzetDagelijks()} /> : <i className='fal fa-pen' style={{float: 'none'}} onClick={() => this.setState({ editOmzet: true })} />} Omzet</span> : null}
                <input 
                  ref={(ref) => index === 0 ? this.inputMon=ref : index === 1 ? this.inputTue=ref : index === 2 ? this.inputWed=ref : index === 3 ? this.inputThu=ref : index === 4 ? this.inputFri=ref : index === 5 ? this.inputSat=ref : index === 6 ? this.inputSun=ref : this.inputMon=ref}
                  className='planner_inputBoxOmzet'
                  defaultValue={this.state.omzetDagen[index]}
                  onKeyDown={(event) => this.handleKeyOmzet(event)}
                  placeholder={'Omzet'}
                  type={'number'}
                  step={0.01} 
                  style={special ? {width: '55%', marginLeft: 0} : {width: '100%'}}
                />
              </div>
            :
              <div className='plannerN_dateContainer_sub_row2'>
                {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}>{this.state.editOmzet ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn, float: 'none'}} onClick={() => this.editOmzetDagelijks()} /> : <i className='fal fa-pen' style={{float: 'none'}} onClick={() => this.setState({ editOmzet: true })} />} Omzet</span> : null}
                <span className={`planner_dateText_sub${special ? '2' : ''}`} onClick={() => this.openEditOmzetDagelijks(key2)} style={{width: special ? '55%' : {width: '100%'}, cursor: 'text', border: '1px dashed #e1e1e1', textAlign: 'right'}}>{this.formatter.format(this.state.omzetDagen[index])}</span>
              </div>
            // <span className='planner_dateText_sub' style={{paddingLeft: 0}}>{this.state.editOmzet ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn, float: 'none'}} onClick={() => this.editOmzetDagelijks()} /> : <i className='fal fa-pen' style={{float: 'none'}} onClick={() => this.setState({ editOmzet: true })} />} Omzet: {this.formatter.format(this.state.omzetDagen[0])}</span>
            }
          </div>
        : null}
                        
        {this.state.opties_planner_loonkosten === true ?
          <div className='plannerN_dateContainer_sub_row2'>
            {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}>Loonkosten</span> : null}
            <span className={`planner_dateText_sub${special ? '2' : ''}`} style={{width: special ? '55%' : null, color: parseFloat(Data.data.appOptions.opties_loonPercentage) !== 0 && Constants.calcPercentInt(this.state.salarisDagen[index], this.state.omzetDagen[index] > 0 ? this.state.omzetDagen[index] : this.state.omzetDagenV[index] > 0 ? this.state.omzetDagenV[index] : this.state.previousGains[key] > 0 ? this.state.previousGains[key] : 0) > parseFloat(Data.data.appOptions.opties_loonPercentage) ? Colors.color.redFilledIn : '#282828', textAlign: 'right'}}>{this.formatter.format(this.state.salarisDagen[index])}</span>
          </div>
        : null}

        {this.state.opties_planner_berekeningen === true ? // previous gains
          <div className='plannerN_dateContainer_sub_row2'>
            {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}><b>Totaal</b></span> : null}
            <span className={`planner_dateText_sub${special ? '2' : ''}`} style={{width: special ? '55%' : null, fontWeight: 'bold', textAlign: 'right'}}>{this.formatter.format(((this.state.omzetDagen[index] > 0 ? this.state.omzetDagen[index] : this.state.omzetDagenV[index] > 0 ? this.state.omzetDagenV[index] : this.state.previousGains[key]) - this.state.salarisDagen[index]))}</span>
          </div>
        : null}

        {this.state.opties_planner_productiviteit === true ?
          <div className='plannerN_dateContainer_sub_row2'>
            {special ? <span className={`planner_dateText_sub${special ? '2' : ''}`}>Productiviteit</span> : null}
            <span className={`planner_dateText_sub${special ? '2' : ''}`} style={{width: special ? '55%' : null, textAlign: 'right'}}>{this.formatter.format(isNaN((this.state.omzetDagen[index] > 0 ? this.state.omzetDagen[index] : this.state.omzetDagenV[index] > 0 ? this.state.omzetDagenV[index] : this.state.previousGains[key]) / this.state.salarisUren[index]) ? 0 : ((this.state.omzetDagen[index] > 0 ? this.state.omzetDagen[index] : this.state.omzetDagenV[index] > 0 ? this.state.omzetDagenV[index] : this.state.previousGains[key]) / this.state.salarisUren[index]))}</span>
          </div>
        : null}

      </div>

    );

  }

  resetToBackupAlert() {

    if(this.state.loading === true || this.state.reloading === true || this.isPlanning) {
      return;
    }

    this.setState({
      alertTitle: 'Ongedaan maken',
      alertBody: (
        <div>

          Weet je zeker dat je de wijzigingen van week <b>{this.state.week}, {this.state.year}</b> ongedaan wilt maken?

        </div>
      ),
      alertButtons: 2,
      alertButtonText: ['Annuleren', 'Ongedaan maken'],
      alertButtonAction: this.resetToBackup.bind(this),
      showAlert: true,
    });

  }

  async resetToBackup() {

    this.setState({ loading: true });

    // delete whole planner
    let deletes = [];

    for (const key in this.plannerData) {
      
      if (this.plannerData[key].length > 0) {

        for (const plan of this.plannerData[key]) {

          deletes.push(APIDeleteUsedDayShift.Request(plan.rooster_id));
          deletes.push(APIDeleteUserPlanner.Request(plan.rooster_id));

        }

      }

    }

    await Promise.all(deletes);

    await APIDELETE.Request(`DELETE FROM Rooster`, `WHERE (rooster_datum BETWEEN '${this.state.mondayDate}' AND '${this.state.sundayDate}') AND rooster_info_id IS NULL`);

    // add backup
    let adds = [];

    for (const key in this.plannerDataBackup) {
      if (this.plannerDataBackup[key].length > 0) {
        for (const plan of this.plannerDataBackup[key]) {
          // add to planner
          adds.push(APIADD.Request(`INSERT INTO Rooster`, `VALUES (${plan.rooster_id}, '${plan.rooster_datum}', '${plan.rooster_begin.substring(0,5)}', '${plan.rooster_eind.substring(0,5)}', ${plan.info_id}, ${plan.functie_id === null ? 'NULL' : plan.functie_id}, ${plan.rooster_publiceren}, '${plan.rooster_pauze.substring(0,5)}', ${plan.rooster_tijd_incorrect})`));
        }
      }
    }

    for (const key in this.emptyPlannerDataBackup) {
      if (this.emptyPlannerDataBackup[key].length > 0) {
        for (const plan of this.emptyPlannerDataBackup[key]) {
          // add to planner
          adds.push(APIADD.Request(`INSERT INTO Rooster`, `VALUES (${plan.rooster_id}, '${plan.rooster_datum}', '${plan.rooster_begin.substring(0,5)}', '${plan.rooster_eind.substring(0,5)}', NULL, ${plan.functie_id === null ? 'NULL' : plan.functie_id}, ${plan.rooster_publiceren}, '${plan.rooster_pauze.substring(0,5)}', ${plan.rooster_tijd_incorrect})`));
        }
      }
    }

    await Promise.all(adds);

    // add extra's
    let adds2 = [];

    for (const used of this.usedDayShiftsModernBackup) {
      adds2.push(APIADD.Request(`INSERT INTO GebruikteDagDienstModern`, `VALUES (${used.gdm_id}, '${used.gdm_datum}', ${used.gdm_dagdienst_id}, ${used.gdm_rooster_id})`));
    }

    for (const key in this.partBackup) {
      const part = this.partBackup[key];
      adds2.push(APIADD.Request(`INSERT INTO BijzRooster`, `VALUES (${part.bijzr_id}, ${part.bijzr_bijz_id}, ${part.bijzr_rooster_id})`));
    }

    for (const key in this.absenceBackup) {
      const abs = this.absenceBackup[key];
      adds2.push(APIADD.Request(`INSERT INTO AfwRooster`, `VALUES (${abs.afwr_id}, ${abs.afwr_afw_id}, ${abs.afwr_rooster_id})`));
    }

    for (const key in this.skillsBackup) {
      const skills = this.skillsBackup[key];
      for (const skill of skills) {
        adds2.push(APIADD.Request(`INSERT INTO CompRooster`, `VALUES (${skill.compr_id}, ${skill.compr_comp_id}, ${skill.compr_rooster_id})`));
      }
    }

    // teams
    if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
      for (const key in this.plannerDataBackup) {
        if (this.plannerDataBackup[key].length > 0) {
          for (const plan of this.plannerDataBackup[key]) {
            // add to teams
            adds2.push(APIAddToTeamPlanner.Request(Data.data.chosenTeamObj.team_id, parseInt(plan.rooster_id)));
          }
        }
      }
    }

    await Promise.all(adds2);

    await this.reloadScreen2FullScreen();

  }

  closeAllPlanMenusOutside(e) {

    if (Constants.isEmpty(e) || Constants.isEmpty(e.target) || Constants.isEmpty(e.target.className) || Constants.isEmpty(e.target.className.includes)) {
      return;
    }

    if(e.target.className.includes('planner_planMenu') === false && e.target.className.includes('planner_arrowContainer') === false
      && e.target.className.includes('fas fa-caret-down') === false && e.target.className.includes('planMenuAsset') === false
      && e.target.className.includes('planner_table_nameContainer2_name_sub2') === false && e.target.className.includes('planner_userMenu') === false) {
      this.closeAllPlanMenus();
      if (this.state.display === 'LIST') {
        this.closeAllUserMenus();
      }
    }
    
    if(e.target.className.includes('settingsAsset') === false && e.target.className.includes('planner_settingsButton') === false
      && e.target.className.includes('alertModal') === false) {
      this.setState({
        showSettingsMenu: false,
        showSettingsMenuAdd: false,
        showSettingsMenuEmployees: false,
      });
    }

    if(e.target.className.includes('settings_Asset2') === false && e.target.className.includes('planner_settingsButton') === false
      && e.target.className.includes('alertModal') === false) {
      this.setState({
        showSettingsMenu2: false,
        showSettingsMenuExport: false,
        showSettingsMenuShifts: false,
        showSettingsMenuPlanning: false,
      });
    }

  }

  handleKeyTab(e) {

    if (e.keyCode === 9) {
      this.setState({editPlan: true});

      // for (let i = 0; i < e.form.elements.length; i++){
      //   if (e.form.elements[i].tabIndex - e.tabIndex == 1){
      //       e.form.elements[i].focus();
      //       if (e.form.elements[i].type == "text"){
      //           e.form.elements[i].select();
      //       }
      //   }
      // }
    }

  }

  closeExport() {
    this.setState({ showSettingsMenuExport: false });
  }

  closeSettings() {
    this.setState({
      showSettingsMenu: false,
      showSettingsMenu2: false, 
      showSettingsMenuAdd: false,
      showSettingsMenuShifts: false,
      showSettingsMenuPlanning: false,
      showSettingsMenuEmployees: false,
      showSettingsMenuExport: false,
    });
  }

  closeSettingsSubs() {
    this.setState({
      showSettingsMenuAdd: false,
      showSettingsMenuShifts: false,
      showSettingsMenuPlanning: false,
      showSettingsMenuEmployees: false,
    });
  }

  async editNewOptions(set, value, bool = true) {

    await APIEditOptions2.Request(set, bool ? value === true ? '1' : '0' : value, Data.data.appOptions.id);

    Data.data.appOptions = {
     ...Data.data.appOptions,
     [set]: bool ? value === true ? '1' : '0' : value,
   };

   this.setState({
     [set]: value,
   }, async () => {

    if (set === 'opties_financien' && value === false) {

      await Promise.all([
        this.editNewOptions('opties_planner_loonkosten', false),
        this.editNewOptions('opties_planner_vorigOmzet', false),
        this.editNewOptions('opties_planner_verwachteOmzet', false),
        this.editNewOptions('opties_planner_omzet', false),
        this.editNewOptions('opties_planner_productiviteit', false),
        this.editNewOptions('opties_planner_berekeningen', false),
      ]);

    }

   });

  }

  renderDayData(table) {

    let toReturn = [];

    if (table === true) {

      toReturn.push(

        <div className='plannerN_dateContainer_sub' style={{maxWidth: '11%'}}>

          <div className='plannerN_dateContainer_sub_row_top' style={{justifyContent: 'flex-start'}}>
            <span style={{marginLeft: '1%'}}><b>Naam</b></span>
          </div>

          {this.state.opties_planner_vorigOmzet === true ?
            <div className='plannerN_dateContainer_sub_row2' style={{marginTop: '0.5vw'}}>
              <span className='planner_dateText_sub' style={{paddingLeft: 0, fontStyle: 'italic', marginLeft: '1%'}}>Omzet {this.state.year - 1}</span>
            </div>
          : null}

          {this.state.opties_planner_verwachteOmzet === true ?
            <div className='plannerN_dateContainer_sub_row2'>
              <span className='planner_dateText_sub' style={{paddingLeft: 0, marginLeft: '1%'}}>Verwachte omzet {this.state.editOmzetV ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn}} onClick={() => this.editOmzetDagelijksV()} /> : <i className='fal fa-pen' onClick={() => this.setState({ editOmzetV: true })} />}</span>
            </div>
          : null}
                          
          {this.state.opties_planner_omzet === true ?
            <div className='plannerN_dateContainer_sub_row2'>
              <span className='planner_dateText_sub' style={{paddingLeft: 0, marginLeft: '1%'}}>Omzet {this.state.editOmzet ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn}} onClick={() => this.editOmzetDagelijks()} /> : <i className='fal fa-pen' onClick={() => this.setState({ editOmzet: true })} />}</span>
            </div>
          : null}
                            
          {this.state.opties_planner_loonkosten === true ?
            <div className='plannerN_dateContainer_sub_row2'>
              <span className='planner_dateText_sub' style={{paddingLeft: 0, marginLeft: '1%'}}>Bruto loonkosten</span>
            </div>
          : null}

          {this.state.opties_planner_berekeningen === true ? // previous gains
            <div className='plannerN_dateContainer_sub_row2'>
              <span className='planner_dateText_sub' style={{paddingLeft: 0, marginLeft: '1%'}}><b>Totaal</b></span>
            </div>
          : null}

          {this.state.opties_planner_productiviteit === true ?
            <div className='plannerN_dateContainer_sub_row2'>
              <span className='planner_dateText_sub' style={{paddingLeft: 0, marginLeft: '1%'}}>Productiviteit</span>
            </div>
          : null}

        </div>

      );

    }

    for (let i = 0; i < 7; i++) {

      const _date = i === 0 ? this.state.mondayDate : i === 1 ? this.state.tuesdayDate : i === 2 ? this.state.wednesdayDate : i === 3 ? this.state.thursdayDate : i === 4 ? this.state.fridayDate : i === 5 ? this.state.saturdayDate : i === 6 ? this.state.sundayDate : this.state.mondayDate;
      const _dateObj = Constants.stringToDate(_date);
      const _dayName = i === 0 ? 'monday' : i === 1 ? 'tuesday' : i === 2 ? 'wednesday' : i === 3 ? 'thursday' : i === 4 ? 'friday' : i === 5 ? 'saturday' : i === 6 ? 'sunday' : 'monday';
      const _dayShort = i === 0 ? 'mon' : i === 1 ? 'tue' : i === 2 ? 'wed' : i === 3 ? 'thu' : i === 4 ? 'fri' : i === 5 ? 'sat' : i === 6 ? 'sun' : 'mon';

      toReturn.push(this.getDayData(_dayShort, _dayName, i, table, _dateObj));

    }

    return toReturn;

  }

  render() {

    const plannerBars = this.getPlannerBars();

    let availableBars = [];
    let notFilledBars = [];
    let schoolScheduleBars = [];
    let schoolHolidayBars = [];
    let notFilledSchoolBars = [];
    let unavailableBars = [];
    let allPeopleBars = [];
    
    if(Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY') {
      availableBars = this.getAvailableBars();
      notFilledBars = this.getNotFilledBars();
      unavailableBars = this.getUnavailableBars();
    }
    if(Data.data.appOptions.availability === 'SCHOOL') {
      notFilledSchoolBars = this.getNotFilledSchoolBars();
      schoolScheduleBars = this.getSchoolScheduleBars();
      schoolHolidayBars = this.getSchoolHolidayBars();
      unavailableBars = this.getUnavailableBars();
    }
    // if(Data.data.appOptions.availability === 'UNAVAILABILITY') {
    //   notFilledBars = this.getNotFilledBars();
    //   unavailableBars = this.getUnavailableBars();
    // }
    if(Data.data.appOptions.availability === 'NONE') {
      allPeopleBars = this.getAllPeopleBars();
    }
    const holidayBars = this.getHolidayBars();

    const top = (this.state.shiftTemplates.length * 2.2) + 2.2;

    // criticals
    let _unpublished = 0;
    let _incorrect = 0;
    let _open = 0;
    let _shifts = 0;
    let _unpublishedRepeats = 0;

    for (const key in this.plannerData) {
      for (const plan of this.plannerData[key]) {
        if (plan.rooster_publiceren === '0') {
          _unpublished++;
        }
        if (plan.rooster_tijd_incorrect === '1') {
          _incorrect++;
        }
        if (Plan.userHasAllSkills(this.state.skillSchedules[plan.rooster_id], this.state.skillsUsers[plan.info_id]) === false) {
          _incorrect++;
        }
      }
    }

    for (const key in this.emptyPlannerData) {
      _open += this.emptyPlannerData[key].length;
    }

    for (const key in this.dayShiftsData) {
      _shifts += this.dayShiftsData[key].length;
    }

    for (const userKey in this.unpublishedRepeatingShifts) {
      for (const u of this.unpublishedRepeatingShifts[userKey]) {
        _unpublishedRepeats++;
      }
    }

    return (
        <div className='planner' onClick={(e) => this.closeAllPlanMenusOutside(e)} onKeyDown={(e) => this.handleKeyTab(e)}>

          <Navbar ref={this.navBar} history={this.props.history} active={'planner'} loading={this.state.loading} reloading={this.state.reloading} liveSwitch={'planner'} parent={this} />

          {this.state.loading === false && (_unpublished !== 0 || _unpublishedRepeats !== 0 || _incorrect !== 0 || _open !== 0 || _shifts !== 0) ?
            <div className='planner_top_caution'>

              {_unpublished !== 0 ?
                <div>
                  {/* <i className='fas fa-exclamation-triangle' style={{color: Colors.color.redFilledIn}} /> */}
                  <span style={{fontWeight: 'bold'}}><span style={{color: Colors.color.redFilledIn}}>{_unpublished}</span> niet gepubliceerd</span>
                </div>
              : null}

              {_unpublishedRepeats !== 0 ?
                <div style={{marginLeft: _unpublished !== 0 ? '2vw' : 0}}>
                  <span style={{fontWeight: 'bold'}}><span style={{color: Colors.color.redFilledIn}}>{_unpublishedRepeats}</span> niet-gepubliceerde herh. {_unpublishedRepeats === 1 ? 'dienst' : 'diensten'}</span>
                </div>
              : null}

              {_incorrect !== 0 ?
                <div style={{marginLeft: _unpublished !== 0 || _unpublishedRepeats !== 0 ? '2vw' : 0}}>
                  {/* <i className='fas fa-alarm-exclamation' style={{color: Colors.color.redFilledIn}} /> */}
                  <span style={{fontWeight: 'bold'}}><span style={{color: Colors.color.redFilledIn}}>{_incorrect}</span> {_incorrect === 1 ? 'conflict' : 'conflicten'}</span>
                </div>
              : null}

              {_open !== 0 ?
                <div style={{marginLeft: _unpublished !== 0 || _unpublishedRepeats !== 0 || _incorrect !== 0 ? '2vw' : 0}}>
                  {/* <i className='fas fa-calendar-exclamation' style={{color: Colors.color.redFilledIn}} /> */}
                  <span style={{fontWeight: 'bold'}}><span style={{color: Colors.color.redFilledIn}}>{_open}</span> {_open === 1 ? 'open dienst' : 'open diensten'}</span>
                </div>
              : null}

              {_shifts !== 0 ?
                <div style={{marginLeft: _unpublished !== 0 || _unpublishedRepeats !== 0 || _incorrect !== 0 || _open !== 0 ? '2vw' : 0}}>
                  {/* <i className='fas fa-star-exclamation' style={{color: Colors.color.orangeVacation}} /> */}
                  <span style={{fontWeight: 'bold'}}><span style={{color: Colors.color.orangeVacation}}>{_shifts}</span> {_shifts === 1 ? 'standaard dienst' : 'standaard diensten'}</span>
                </div>
              : null}

              {/* <div style={{marginLeft: '4vw'}}>
                <i className='fas fa-people-arrows' style={{color: Colors.color.orangeVacation}} />
                <span style={{fontWeight: 'bold', marginLeft: '0.5vw'}}><span style={{color: Colors.color.orangeVacation}}>{_trades}</span> geruild</span>
              </div> */}

            </div>
          : null}

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className={`planner_container ${this.state.reloading === true ? 'planner_reloading' : ''}`}>

              {this.state.reloading === true ?
                        
                // lds-dual-ring 
                <div className='loader2' /> 

              :

                null

              }

              {/* {this.state.showPlanMenuOuter === true ?
                        
                // lds-dual-ring 
                <div className='planner_planMenu_outer' onClick={() => this.closeAllPlanMenus()} /> 

              :

                null

              } */}

              {this.state.showAddTempModal === true ?
                
                <TemplateModal 
                  _closeModal={this.closeAddTempModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreenAddTemp.bind(this)}
                  _saveTemplate={this.saveTemplate.bind(this)}
                  _saveShiftTemplate={this.saveShiftTemplate.bind(this)}
                  _key={this.state.highestTempKey}
                />
          
              :
          
                null
              
              }

              {this.state.showAvailableShiftsModal === true ?
                
                <AvailableShiftsModal 
                  _closeModal={this.closeAvailableShiftsModal.bind(this)}
                  _week={this.state.week}
                  _year={this.state.year}
                  _dayShifts={this.dayShiftsData}
                  _partDayShifts={this.state.partDayShifts}
                  _teamPlanner={this.teamPlanner}
                  _reloadScreen={
                    this.state.reloadScreenMode === 0 ? this.reloadScreen.bind(this) // refresh specific date and stay on place 
                    : this.state.reloadScreenMode === 1 ? this.reloadScreen2.bind(this) // refresh whole week and stay on place
                    : this.state.reloadScreenMode === 2 ? this.reloadScreen2FullScreen.bind(this) // refresh whole week and refresh screen
                    : null
                  }
                />

              :

                null
              
              }

              {this.state.showPublishModal === true ?
                
                <PublishModal 
                  _closeModal={this.closePublishModal.bind(this)}
                  _week={this.state.week}
                  _year={this.state.year}
                  _repeatingShifts={this.repeatingShifts}
                  _skillsRepeatShifts={this.skillsRepeatShifts}
                  _partRepeatShifts={this.partRepeatShifts}
                  _infoExtra={this.state.infoExtra}
                  _contracts={this.state.contractsObj}
                  _planUser={this.planUserPublishModal.bind(this)}
                  _getUserHoursWeek={this.getUserHoursWeek.bind(this)}
                  _goTo={this.getNextDataWY.bind(this)}
                  _reloadScreen={this.reloadScreen2.bind(this)}
                />

              : null}

              {this.state.showRepeatShiftModal === true ?
                
                <RepeatShiftModal 
                  _closeModal={this.closeRepeatShiftModal.bind(this)}
                  _modalData={this.state.modalData}
                  _skillsShifts={this.state.skillSchedules}
                  _partShifts={this.state.partSchedules}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen2.bind(this)}
                />

              : null}

              {this.state.showModal === true ?
                
                <PlanModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _modalDate={this.state.modalDate}
                  _modalFunctionID={this.state.modalFunctionID}
                  _times={this.timesData}
                  _functions={this.functionsData}
                  _users={this.getAllPeopleAddUser(this.state.modalDate, this.state.editMode)}
                  _hoursWeek={this.state.hoursWeek}
                  _infoExtra={this.state.infoExtra}
                  _planUsersMaxCumulative={this.state.planUsersMaxCumulative}
                  _putOpenShiftOnUnavailable={this.putOpenShiftOnUnavailable.bind(this)}
                  _reloadScreen={
                    this.state.reloadScreenMode === 0 ? this.reloadScreen.bind(this) // refresh specific date and stay on place 
                    : this.state.reloadScreenMode === 1 ? this.reloadScreen2.bind(this) // refresh whole week and stay on place
                    : this.state.reloadScreenMode === 2 ? this.reloadScreen2FullScreen.bind(this) // refresh whole week and refresh screen
                    : null
                  }
                />

              :

                null
              
              }

              {this.state.showDayNoteModal === true ?
                
                <DayNoteModal 
                  _closeModal={this.closeDayNoteModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _modalDate={this.state.modalDate}
                  _reloadScreen={this.reloadDayNotes.bind(this)} //bind
                />

              : null}

              {this.state.showSortModal === true ?
                
                <SortModal 
                  _closeModal={this.closeSortModal.bind(this)}
                  _modalData={this.state.modalData}
                />

              : null}

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {this.state.showSmartPlanner === true ?
                
                <SmartPlanModal 
                  _closeModal={this.closeSmartPlanner.bind(this)}
                  _week={this.state.week}
                  _smartPlanAlert={this.smartPlanAlert.bind(this)}
                  _applyTemplate={this.applyTemplate.bind(this)}
                  _saveTemplate={this.saveTemplate.bind(this)}
                  _deleteTemplate={this.deleteTemplate.bind(this)}
                />

              :

                null
              
              }

              <div className={`planner_sidemenuBackground ${this.state.sidemenuActive === true ? 'active' : ''}`} id="sidemenuBackground" onClick={(e) => e.target === document.getElementById("sidemenuBackground") ? this.setState({sidemenuActive: false}) : null}>

                <div className={`${this.state.sidemenuActive === true ? 'planner_sidemenu active' : 'planner_sidemenu'}`}>

                  <div className='planner_sidemenu_container'>

                    <div className='planner_sidemenu_menuHeader'>
                      <span style={{ fontSize: 24, fontWeight: 'bold' }}>Planner configuratie</span>
                    </div>

                    {Data.data.accountData.account_rights === '2' ?
                      <div>

                        <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>Algemeen</span>

                        {/* {Data.data.appOptions.opties_versie === '2' ?
                          <div className='planner_sidemenu_rowContainer'>

                            <span>Financiën weergeven</span>

                            <label className={"switch"} style={{marginLeft: 25}}>
                                <input 
                                    type="checkbox" 
                                    checked={this.state.opties_financien} 
                                    onClick={() => this.editNewOptions('opties_financien', !this.state.opties_financien)}
                                    readOnly
                                />
                                <span className="slider round"></span>
                            </label>

                            <Tooltip
                              options
                              html={(
                                <div className='appoptions_tooltipContainer'>
                                  <span>Uitgebreid financiën bijhouden van medewerkers. De financiën rapportage is terug te zien onder het tabje 'Overzichten'.</span>
                                </div>
                              )}
                              style={{position: 'absolute', right: -2}}
                              position={'top'}
                              followCursor
                              theme='kodict'
                            >
                              <i style={{fontSize: 10}} className='fal fa-question-circle' />
                            </Tooltip>

                          </div>
                        : null} */}

                        <div className='planner_sidemenu_rowContainer'>

                          <span>Smart Plan toestaan</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_smartplan} 
                                  onClick={() => this.editNewOptions('opties_smartplan', !this.state.opties_smartplan)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>De Smart Plan optie tonen in de planner. Hiermee worden jouw medewerkers snel ingepland op basis van de door hun aangegeven beschikbaarheid.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: -2}}
                            position={'top'}
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: 10}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>

                      </div>
                    : null}

                    <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>Beheren</span>

                    <div className='planner_sidemenu_rowContainer_button' onClick={() => { this.showAvailableShiftsModal(); this.setState({ sidemenuActive: false }); }}>

                      <span>Alle beschikbare diensten</span>

                      <i className='fas fa-arrow-right' />

                    </div>

                    {/* <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>Toevoegen</span> */}

                    {Data.data.accountData.account_rights === '2' ?
                      <div className='planner_sidemenu_rowContainer_button' onClick={() => { this.navBar.current.openRegistrationMenu(); this.setState({ sidemenuActive: false }); }}>

                        <span>Medewerker toevoegen</span>

                        <i className='fas fa-arrow-right' />

                      </div>
                    : null}

                    {Data.data.accountData.account_rights === '2' ?
                      <div className='planner_sidemenu_rowContainer_button' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'functions' })}>

                        <span>Functie toevoegen</span>

                        <i className='fas fa-arrow-right' />

                      </div>
                    : null}

                    <div className='planner_sidemenu_rowContainer_button' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'shifts' })}>

                      <span>Standaard diensten beheren</span>

                      <i className='fas fa-arrow-right' />

                    </div>

                    {Data.data.accountData.account_rights === '2' ?
                      <div className='planner_sidemenu_rowContainer_button' onClick={() => this.props.history.push({ pathname: `/manage`, menu: 'sections' })}>

                        <span>Afdeling toevoegen</span>

                        <i className='fas fa-arrow-right' />

                      </div>
                    : null}

                    <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>Weergave</span>

                    <div className='planner_sidemenu_rowContainer'>

                      <span>Beschikbaarheid verbergen</span>

                      <label className={"switch"} style={{marginLeft: 25}}>
                          <input 
                              type="checkbox" 
                              checked={this.state.opties_infoplanner_verborgen} 
                              onClick={() => this.editNewOptions('opties_infoplanner_verborgen', !this.state.opties_infoplanner_verborgen)}
                              readOnly
                          />
                          <span className="slider round"></span>
                      </label>

                      <Tooltip
                        options
                        html={(
                          <div className='appoptions_tooltipContainer'>
                            <span>Beschikbaarheid op de planner verborgen houden. Beschikbaarheid kan alsnog worden gezien wanneer er op de invoerbalk geklikt wordt.</span>
                          </div>
                        )}
                        style={{position: 'absolute', right: -2}}
                        position={'top'}
                        followCursor
                        theme='kodict'
                      >
                        <i style={{fontSize: 10}} className='fal fa-question-circle' />
                      </Tooltip>

                    </div>

                    <div className='planner_sidemenu_rowContainer'>

                      <span>Verwachte drukte</span>

                      <label className={"switch"} style={{marginLeft: 25}}>
                          <input 
                              type="checkbox" 
                              checked={this.state.opties_planner_drukte} 
                              onClick={() => this.editNewOptions('opties_planner_drukte', !this.state.opties_planner_drukte)}
                              readOnly
                          />
                          <span className="slider round"></span>
                      </label>

                      <Tooltip
                        options
                        html={(
                          <div className='appoptions_tooltipContainer'>
                            <span>Laat de verwachte drukte zien. De drukte wordt berekend met behulp van de afgelopen 3 weken.</span>
                          </div>
                        )}
                        style={{position: 'absolute', right: -2}}
                        position={'top'}
                        followCursor
                        theme='kodict'
                      >
                        <i style={{fontSize: 10}} className='fal fa-question-circle' />
                      </Tooltip>

                    </div>

                    {/* <div className='planner_sidemenu_rowContainer'>

                      <span>Extra open diensten</span>

                      <label className={"switch"} style={{marginLeft: 25}}>
                          <input 
                              type="checkbox" 
                              checked={this.state.opties_planner_extraDiensten} 
                              onClick={() => this.editNewOptions('opties_planner_extraDiensten', !this.state.opties_planner_extraDiensten)}
                              readOnly
                          />
                          <span className="slider round"></span>
                      </label>

                      <Tooltip
                        options
                        html={(
                          <div className='appoptions_tooltipContainer'>
                            <span>Extra open diensten weergeven. Dit zijn diensten die extra zijn gemaakt of die (nog) niet gekoppeld zijn aan een medewerker.</span>
                          </div>
                        )}
                        style={{position: 'absolute', right: -2}}
                        position={'top'}
                        followCursor
                        theme='kodict'
                      >
                        <i style={{fontSize: 10}} className='fal fa-question-circle' />
                      </Tooltip>

                    </div> */}

                    {/* <div className='planner_sidemenu_rowContainer'>

                      <span>Contracturen</span>

                      <label className={"switch"} style={{marginLeft: 25}}>
                          <input 
                              type="checkbox" 
                              checked={this.state.opties_planner_contracturen} 
                              onClick={() => this.editNewOptions('opties_planner_contracturen', !this.state.opties_planner_contracturen)}
                              readOnly
                          />
                          <span className="slider round"></span>
                      </label>

                      <Tooltip
                        options
                        html={(
                          <div className='appoptions_tooltipContainer'>
                            <span>Contracturen en overuren weergeven achter de naam van een medewerker.</span>
                          </div>
                        )}
                        style={{position: 'absolute', right: -2}}
                        position={'top'}
                        followCursor
                        theme='kodict'
                      >
                        <i style={{fontSize: 10}} className='fal fa-question-circle' />
                      </Tooltip>

                    </div> */}

                    <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>Sorteren</span>

                    {this.state.display === 'TABLE' || this.state.display === 'XCL' ?
                      <div className='planner_sidemenu_rowContainer_button' onClick={() => this.openSortModal('employees')}>

                        <span>Medewerkers sorteren</span>

                        <i className='fas fa-arrow-right' />

                      </div>
                    : null}

                    <div className='planner_sidemenu_rowContainer_button' onClick={() => this.openSortModal('jobs')}>

                      <span>Functies sorteren</span>

                      <i className='fas fa-arrow-right' />

                    </div>

                    {/* <div className='planner_sidemenu_rowContainer'>

                      <span>Open diensten sorteren onder functie</span>

                      <label className={"switch"} style={{marginLeft: 25}}>
                          <input 
                              type="checkbox" 
                              checked={this.state.opties_planFunctieSort} 
                              onClick={() => this.editNewOptions('opties_planFunctieSort', !this.state.opties_planFunctieSort)}
                              readOnly
                          />
                          <span className="slider round"></span>
                      </label>

                      <Tooltip
                        options
                        html={(
                          <div className='appoptions_tooltipContainer'>
                            <span>Open diensten onder de functie weergeven of helemaal onderin de planner weergeven.</span>
                          </div>
                        )}
                        style={{position: 'absolute', right: -2}}
                        position={'top'}
                        followCursor
                        theme='kodict'
                      >
                        <i style={{fontSize: 10}} className='fal fa-question-circle' />
                      </Tooltip>

                    </div> */}

                     {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20, marginLeft: 15 }}>{`Financiële berekeningen`}</span>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_sidemenu_rowContainer'>

                        <span>Loonkosten</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_planner_loonkosten} 
                                onClick={() => this.editNewOptions('opties_planner_loonkosten', !this.state.opties_planner_loonkosten)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Loonkosten weergeven van medewerkers met een uurloon (oproepkracht, nul-uren, min/max enzovoort).</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: -2}}
                          position={'top'}
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: 10}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_sidemenu_rowContainer'>

                        <span>Omzet vorig jaar</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_planner_vorigOmzet} 
                                onClick={() => this.editNewOptions('opties_planner_vorigOmzet', !this.state.opties_planner_vorigOmzet)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Omzet van het jaar ervoor weergeven.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: -2}}
                          position={'top'}
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: 10}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_sidemenu_rowContainer'>

                        <span>Verwachte omzet</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_planner_verwachteOmzet} 
                                onClick={() => this.editNewOptions('opties_planner_verwachteOmzet', !this.state.opties_planner_verwachteOmzet)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Verwachte omzet kunnen invullen per dag.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: -2}}
                          position={'top'}
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: 10}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_sidemenu_rowContainer'>

                        <span>Werkelijke omzet</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_planner_omzet} 
                                onClick={() => this.editNewOptions('opties_planner_omzet', !this.state.opties_planner_omzet)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Werkelijke omzet kunnen invullen per dag.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: -2}}
                          position={'top'}
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: 10}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_sidemenu_rowContainer'>

                        <span>Productiviteit</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_planner_productiviteit} 
                                onClick={() => this.editNewOptions('opties_planner_productiviteit', !this.state.opties_planner_productiviteit)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>Productiviteit per dag berekenen met behulp van geplande uren en omzet. Als er nog geen werkelijke omzet is gemaakt, wordt de berekening gemaakt met behulp van verwachte omzet of omzet van het jaar ervoor.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: -2}}
                          position={'top'}
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: 10}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_sidemenu_rowContainer'>

                        <span>Berekening weergeven</span>

                        <label className={"switch"} style={{marginLeft: 25}}>
                            <input 
                                type="checkbox" 
                                checked={this.state.opties_planner_berekeningen} 
                                onClick={() => this.editNewOptions('opties_planner_berekeningen', !this.state.opties_planner_berekeningen)}
                                readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                        <Tooltip
                          options
                          html={(
                            <div className='appoptions_tooltipContainer'>
                              <span>De totale berekening weergeven.</span>
                            </div>
                          )}
                          style={{position: 'absolute', right: -2}}
                          position={'top'}
                          followCursor
                          theme='kodict'
                        >
                          <i style={{fontSize: 10}} className='fal fa-question-circle' />
                        </Tooltip>

                      </div>
                    : null}

                    {Data.data.appOptions.opties_versie !== '0' ?
                      <div style={{marginTop: 20}}>
                        <span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 15 }}>Open diensten</span>

                        <div className='planner_sidemenu_rowContainer'>

                          <span>Open diensten gelijk beschikbaar zetten</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_opendienst_beschikbaar} 
                                  onClick={() => this.editNewOptions('opties_opendienst_beschikbaar', !this.state.opties_opendienst_beschikbaar)}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Wanneer een open dienst wordt aangemaakt, zal deze gelijk beschikbaar worden gezet.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: -2}}
                            position={'top'}
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: 10}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>
                      </div>
                    : null}

                    {Data.data.appOptions.opties_versie !== '0' ?
                      <div style={{marginTop: 20}}>
                        <span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 15 }}>Contract</span>

                        <div className='planner_sidemenu_rowContainer'>

                          <span>Contract verlopen</span>

                          <label className={"switch"} style={{marginLeft: 25}}>
                              <input 
                                  type="checkbox" 
                                  checked={this.state.opties_contractVerlopen} 
                                  onClick={async () => { await this.editNewOptions('opties_contractVerlopen', !this.state.opties_contractVerlopen); this.reloadScreen2FullScreen(); }}
                                  readOnly
                              />
                              <span className="slider round"></span>
                          </label>

                          <Tooltip
                            options
                            html={(
                              <div className='appoptions_tooltipContainer'>
                                <span>Wanneer een contract is verlopen van een medewerker houdt de planner hier rekening mee en kan deze medewerker niet meer worden ingepland.</span>
                              </div>
                            )}
                            style={{position: 'absolute', right: -2}}
                            position={'top'}
                            followCursor
                            theme='kodict'
                          >
                            <i style={{fontSize: 10}} className='fal fa-question-circle' />
                          </Tooltip>

                        </div>
                      </div>
                    : null}

                    <div style={{width: '100%', height: 40}}> 
                          &nbsp;
                    </div>

                  </div>

                </div>

              </div>

              <div className="planner_addButtons_container" style={{zIndex: 4}}>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <span>Instellingen planning</span>
                    </div>
                  )}
                  position={'top'}
                  theme='kodict'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_settings' style={{marginLeft: '-1vw'}} onClick={() => this.setState({ sidemenuActive: !this.state.sidemenuActive })}>
                    <i className={'far fa-cog'} />
                  </div>
                </Tooltip>

                {this.state.userDefaultWorkdays.length > 0 ?
                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <span>Medewerkers met vaste werktijden inplannen</span>
                      </div>
                    )}
                    position={'top'}
                    theme='kodict'
                    delay={300}
                    hideDelay={0}
                  >
                    <div className='plannerN_settingsButton_save' onClick={this.planUsersDefaultWorkdaysAlert.bind(this)}>
                      <i className={'far fa-user-clock'} />
                    </div>
                  </Tooltip>
                : null}

                <div style={{position: 'relative'}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <span>Alle opgeslagen planningen</span>
                      </div>
                    )}
                    offset={'-1.4vw, 0'}
                    position={'top'}
                    theme='kodict'
                    delay={300}
                    hideDelay={0}
                  >
                    <div className="plannerN_settingsButton_save settings_Asset2" onClick={() => this.setState({ showSettingsMenu2: !this.state.showSettingsMenu2 })}>
                      <i className={'far fa-layer-group settings_Asset2'} />
                    </div>
                  </Tooltip>

                  {this.state.showSettingsMenu2 === true ?

                    <div className='planner_settingsMenu settings_Asset2'>

                      <div className='arrow_box settings_Asset2' />

                      <div className='planner_planMenuSub_row settings_Asset2' onClick={() => this.openAddTempModal()} style={{cursor: 'default', paddingBottom: '2%', paddingTop: '2.5%'}}>
                        <div className='planner_planMenuSub_row_sub1 settings_Asset2' style={{color: '#282828', fontWeight: 'bold'}}>
                          Opgeslagen planningen
                        </div>
                      </div>

                      <div className='divider' />

                      {this.getPlanningTemplates()}

                    </div>

                  : null}
                  
                </div>

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <span>Week {this.state.week} opslaan als planningsjabloon</span>
                    </div>
                  )}
                  position={'top'}
                  theme='kodict'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_save' onClick={() => this.openAddTempModal()}>
                    <i className={'far fa-save'} />
                  </div>
                </Tooltip>

                <div style={{position: 'relative'}}>

                  <Tooltip
                    options
                    html={(
                      <div className='plannerN_tooltipContainer'>
                        <span>Rooster exporteren</span>
                      </div>
                    )}
                    offset={'-1.4vw, 0'}
                    position={'top'}
                    theme='kodict'
                    delay={300}
                    hideDelay={0}
                  >
                    <div className="plannerN_settingsButton_excel settings_Asset2" onClick={() => this.setState({ showSettingsMenuExport: !this.state.showSettingsMenuExport })}>
                      <i className={'far fa-file-excel settings_Asset2'} />
                    </div>
                  </Tooltip>

                  {this.state.showSettingsMenuExport === true ?

                    <div className='planner_settingsMenu settings_Asset2'>

                      <div className='arrow_box settings_Asset2' />

                      <ScheduleDownloadName dataSet={this.plannerData} week={this.state.week} year={this.state.year} closeMenu={this.closeExport.bind(this)}/>
                      <ScheduleDownloadJob dataSet={this.plannerData} week={this.state.week} year={this.state.year} closeMenu={this.closeExport.bind(this)}/>

                    </div>

                  : null}
                </div>

                {/* <div className='plannerN_settingsButton_save' onClick={() => this.openAddTempModal()}>
                  <i className={'far fa-save'} />
                </div> */}

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <span>Wijzigingen van week {this.state.week} ongedaan maken</span>
                    </div>
                  )}
                  position={'top'}
                  theme='kodict'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_redo' onClick={this.resetToBackupAlert.bind(this)}>
                    <i className={'far fa-redo'} />
                  </div>
                </Tooltip>

                {/* {this.state.showUndoButton === true ?  */}
                  {/* <div className='plannerN_settingsButton_redo' onClick={this.resetToBackupAlert.bind(this)}>
                    <i className={'far fa-redo'} />
                  </div> */}
                {/* : null} */}

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <span>Planning van week {this.state.week} verwijderen</span>
                    </div>
                  )}
                  position={'top'}
                  theme='kodict'
                  delay={300}
                  hideDelay={0}
                >
                  <div className='plannerN_settingsButton_delete'  onClick={this.deletePlannerAlert.bind(this)}>
                    <i className={'far fa-trash'} />
                  </div>
                </Tooltip>

                {/* <div className='plannerN_settingsButton_delete'  onClick={this.deletePlannerAlert.bind(this)}>
                  <i className={'far fa-trash'} />
                </div> */}

                {/* <div style={{position: 'relative'}}>
                  <div className="planner_settingsButton settingsAsset" onClick={() => this.setState({ sidemenuActive: !this.state.sidemenuActive })}>
                    Instellingen
                  </div>

                  {this.state.showSettingsMenu === true ?
                    <div className='planner_settingsMenu settingsAsset'>

                      <div className='arrow_box settingsAsset' />

                      <div className='planner_settingsMenu_row_title settingsAsset'>
                        <div className='planner_settingsMenu_row_title_sub1 settingsAsset'>
                          Algemeen
                        </div>
                      </div>

                      <div style={{position: 'relative'}}>
                        <div className='planner_settingsMenu_row settingsAsset' onClick={() => { this.closeSettingsSubs(); this.setState({showSettingsMenuAdd: !this.state.showSettingsMenuAdd}); }}>
                          <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                            <i className='fal fa-plus settingsAsset' />
                          </div>
                          <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                            Toevoegen
                          </div>
                          <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                            <i className='fal fa-arrow-right settingsAsset' />
                          </div>
                        </div>

                        {this.state.showSettingsMenuAdd === true ?
                          <div className='planner_settingsMenuSub settingsAsset'>

                            <div className='planner_settingsMenu_row settingsAsset' onClick={() => { this.navBar.current.openRegistrationMenu(); this.closeSettings(); }}>
                              <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                <i className='fal fa-user-plus settingsAsset' />
                              </div>
                              <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                                Medewerker toevoegen
                              </div>
                              <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                <i className='fal fa-arrow-right settingsAsset' />
                              </div>
                            </div>

                            <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.props.history.push(`/functions`)}>
                              <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                <i className='fal fa-briefcase settingsAsset' />
                              </div>
                              <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                                Functie toevoegen
                              </div>
                              <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                <i className='fal fa-arrow-right settingsAsset' />
                              </div>
                            </div>

                            <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.props.history.push(`/shifts`)}>
                              <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                <i className='fal fa-calendar-plus settingsAsset' />
                              </div>
                              <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                                Open dienst toevoegen
                              </div>
                              <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                <i className='fal fa-arrow-right settingsAsset' />
                              </div>
                            </div>

                            <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.props.history.push(`/sections`)}>
                              <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                <i className='fal fa-network-wired settingsAsset' />
                              </div>
                              <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                                Afdeling toevoegen
                              </div>
                              <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                <i className='fal fa-arrow-right settingsAsset' />
                              </div>
                            </div>

                          </div>
                        : null}
                      </div>

                      <div className='planner_settingsMenu_row settingsAsset' onClick={this.showAvailableShiftsModal.bind(this)}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-clipboard-list settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Alle beschikbare diensten
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          <i className='fal fa-arrow-right settingsAsset' /> 
                        </div>
                      </div>

                      <div className='planner_settingsMenu_row_title settingsAsset'>
                        <div className='planner_settingsMenu_row_title_sub1 settingsAsset'>
                          Sorteren
                        </div>
                      </div>

                      <div style={{position: 'relative'}}>
                        <div className='planner_settingsMenu_row settingsAsset' onClick={() => { this.closeSettingsSubs(); this.setState({showSettingsMenuEmployees: !this.state.showSettingsMenuEmployees}); }}>
                          <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                            <i className='fal fa-sort-alpha-down settingsAsset' />
                          </div>
                          <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                            Medewerkers sorteren
                          </div>
                          <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                            <i className='fal fa-arrow-right settingsAsset' />
                          </div>
                        </div>
                        
                        {this.state.showSettingsMenuEmployees === true ?
                          <div className='planner_settingsMenuSub settingsAsset'>

                            {this.state.display === 'TABLE' || this.state.display === 'XCL' ?
                              <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.props.history.push('planner_arrange')}>
                                <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                  <i className='fal fa-arrows settingsAsset' />
                                </div>
                                <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                                  Handmatig sorteren
                                </div>
                                <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                  <i className='fal fa-arrow-right settingsAsset' />
                                </div>
                              </div>
                            : null}

                            {this.state.display === 'LIST' ?
                              <div>

                                <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.sortBy('availability')}>
                                  <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                    <i className='fal fa-sort-numeric-down settingsAsset' style={this.state.sortBy === 'availability' ? {color: '#47B7EB'} : null} />
                                  </div>
                                  <div className='planner_settingsMenu_row_sub2 settingsAsset' style={this.state.sortBy === 'availability' ? {color: '#47B7EB'} : null}>
                                    Sorteren op beschikbaarheid
                                  </div>
                                  <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                    <i className='fal fa-arrow-right settingsAsset' style={this.state.sortBy === 'availability' ? {color: '#47B7EB'} : null} />
                                  </div>
                                </div>

                                <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.sortBy('name')}>
                                  <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                    <i className='fal fa-sort-alpha-down settingsAsset' style={this.state.sortBy === 'name' ? {color: '#47B7EB'} : null} />
                                  </div>
                                  <div className='planner_settingsMenu_row_sub2 settingsAsset' style={this.state.sortBy === 'name' ? {color: '#47B7EB'} : null} >
                                    Sorteren op naam
                                  </div>
                                  <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                    <i className='fal fa-arrow-right settingsAsset' style={this.state.sortBy === 'name' ? {color: '#47B7EB'} : null} />
                                  </div>
                                </div>

                                <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.sortBy('job')}>
                                  <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                                    <i className='fal fa-sort-shapes-down settingsAsset' style={this.state.sortBy === 'job' ? {color: '#47B7EB'} : null} />
                                  </div>
                                  <div className='planner_settingsMenu_row_sub2 settingsAsset' style={this.state.sortBy === 'job' ? {color: '#47B7EB'} : null}>
                                    Sorteren op functie
                                  </div>
                                  <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                                    <i className='fal fa-arrow-right settingsAsset' style={this.state.sortBy === 'job' ? {color: '#47B7EB'} : null} />
                                  </div>
                                </div>

                              </div>
                            : null}

                          </div>
                        : null}
                      </div>

                    <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.sortFunctions()}>
                      <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                        <i className='fal fa-sort-amount-down-alt settingsAsset' />
                      </div>
                      <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                        Functies sorteren
                      </div>
                      <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                        {this.state.sortFunctions === true ?
                          <i className='far fa-times settingsAsset' />
                        :
                          <i className='fal fa-arrow-right settingsAsset' /> 
                        }
                      </div>
                    </div>

                    <div className='planner_settingsMenu_row_title settingsAsset'>
                      <div className='planner_settingsMenu_row_title_sub1 settingsAsset'>
                        {`Weergeven`}
                      </div>
                    </div>

                    <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleExtraOpenShifts()}>
                      <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                        <i className='fal fa-folder-tree settingsAsset' />
                      </div>
                      <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                        Extra open diensten
                      </div>
                      <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                        {this.state.showExtraOpenShifts === true ?
                          <i className='far fa-times settingsAsset' />
                        :
                          <i className='fal fa-arrow-right settingsAsset' /> 
                        }
                      </div>
                    </div>

                    <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleContractHours()}>
                      <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                        <i className='fal fa-hourglass-half settingsAsset' />
                      </div>
                      <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                        Contracturen
                      </div>
                      <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                        {this.state.showContractHours === true ?
                          <i className='far fa-times settingsAsset' />
                        :
                          <i className='fal fa-arrow-right settingsAsset' /> 
                        }
                      </div>
                    </div>
                      
                    <div className='planner_settingsMenu_row_title settingsAsset'>
                      <div className='planner_settingsMenu_row_title_sub1 settingsAsset'>
                        {`Verwachtingen & berekeningen`}
                      </div>
                    </div>

                    <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleCrowds()}>
                      <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                        <i className='fal fa-users settingsAsset' />
                      </div>
                      <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                        Verwachte drukte
                      </div>
                      <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                        {this.state.showCrowds === true ?
                          <i className='far fa-times settingsAsset' />
                        :
                          <i className='fal fa-arrow-right settingsAsset' /> 
                        }
                      </div>
                    </div>

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleSalary()}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-euro-sign settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Loonkosten
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          {this.state.showSalary === true ?
                            <i className='far fa-times settingsAsset' />
                          :
                            <i className='fal fa-arrow-right settingsAsset' /> 
                          }
                        </div>
                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.togglePreviousGains()}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-coins settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Omzet vorig jaar
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          {this.state.showPreviousGains === true ?
                            <i className='far fa-times settingsAsset' />
                          :
                            <i className='fal fa-arrow-right settingsAsset' /> 
                          }
                        </div>
                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleGainsExpect()}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-analytics settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Verwachte omzet
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          {this.state.showGainsExpect === true ?
                            <i className='far fa-times settingsAsset' />
                          :
                            <i className='fal fa-arrow-right settingsAsset' /> 
                          }
                        </div>
                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleGains()}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-hand-holding-usd settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Werkelijke omzet
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          {this.state.showGains === true ?
                            <i className='far fa-times settingsAsset' />
                          :
                            <i className='fal fa-arrow-right settingsAsset' /> 
                          }
                        </div>
                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleProductivity()}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-user-chart settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Productiviteit
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          {this.state.showProductivity === true ?
                            <i className='far fa-times settingsAsset' />
                          :
                            <i className='fal fa-arrow-right settingsAsset' /> 
                          }
                        </div>
                      </div>
                    : null}

                    {Constants.isTrue(Data.data.appOptions.opties_financien) ?
                      <div className='planner_settingsMenu_row settingsAsset' onClick={() => this.toggleDetails()}>
                        <div className='planner_settingsMenu_row_sub1 settingsAsset'>
                          <i className='fal fa-calculator-alt settingsAsset' />
                        </div>
                        <div className='planner_settingsMenu_row_sub2 settingsAsset'>
                          Berekeningen weergeven
                        </div>
                        <div className='planner_settingsMenu_row_sub3 settingsAsset'>
                          {this.state.showDetails === true ?
                            <i className='far fa-times settingsAsset' />
                          :
                            <i className='fal fa-arrow-right settingsAsset' /> 
                          }
                        </div>
                      </div>
                    : null}

                  </div>
                : null}
                </div> */}

              </div>

              {/* <div className='planner_infoRow'>
                <div className='planner_infoRow_col1'>
                  <span>Planmodus:</span>
                </div>
                <div className='planner_infoRow_col2'>
                  <span><b>{this.state.mode === 'CLICK' ? 'Klik' : 'Pop-up'}</b></span>
                </div>
              </div> */}

              {/* {Data.data.teams.length > 0 && Data.data.chosenTeam !== null ?
                <div className='planner_infoRow'>
                  <div className='planner_infoRow_col1'>
                    <span>Vestiging:</span>
                  </div>
                  <div className='planner_infoRow_col2'>
                    <span><b>{Constants.getTeamDataChosen().name}</b></span>
                  </div>
                </div>
              : null}

              <div className='planner_infoRow'>
                <div className='planner_infoRow_col1'>
                  <span>Diensten sjabloon:</span>
                </div>
                <div className='planner_infoRow_col2'>
                  <span><b>{this.state.shiftTempName}</b></span>
                </div>
              </div>

              {this.state.display === 'LIST' ?
                <div className='planner_infoRow'>
                  <div className='planner_infoRow_col1'>
                    <span>Sorteren op:</span>
                  </div>
                  <div className='planner_infoRow_col2'>
                    <span><b>{this.state.sortBy === 'availability' ? 'Beschikbaarheid' : this.state.sortBy === 'name' ? 'Naam' : 'Functie'}</b></span>
                  </div>
                </div>
              : null}

              {this.state.opties_planner_loonkosten ?
                <div className='planner_infoRow'>
                  <div className='planner_infoRow_col1'>
                    <span>Kosten week {this.state.week}:</span>
                  </div>
                  <div className='planner_infoRow_col2'>
                    <span><b>{this.formatter.format(this.state.salaryWeek)}</b></span>
                  </div>
                </div>
              : null} */}

              <div className="planner_addButtons_container_sticky stickyTopPlannerControls">

                <div className='planner_date_container'>

                  <div className='planner_date_container_col1' onClick={() => this.getNextData(false)}>
                    <i className='fas fa-chevron-left'/>
                  </div>

                  <div className='planner_date_container_col2'>

                    <div style={{marginRight: '5%'}}><span style={{fontWeight: 600, fontSize: '1vw'}}>Week <b>{this.state.week}, {this.state.year}</b></span></div>

                    <DatePicker 
                      selected={this.state.selectedDate}
                      onSelect={date => this.selectDateCalendar(date)}
                      customInput={<i style={{cursor: 'pointer', fontSize: '0.9vw'}} className='far fa-calendar-day'></i>}
                      popperPlacement="top-start"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "calc(-1.8vw - 15px), 0px"
                        },
                      }}
                      locale={nl}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      showWeekNumbers
                    />

                  </div>

                  <div className='planner_date_container_col3' onClick={() => this.getNextData(true)}>
                    <i className='fas fa-chevron-right'/>
                  </div>

                </div>

                <div style={{width: '8%', marginLeft: '0.6vw'}}>
                  <select
                      name="Selecteer een afdeling"
                      className={'planner_dropdown'}
                      onChange={(event) => this.changeSectionTab(event)}
                  >
                      <option value={'null'} disabled>Afdeling</option>
                      <option value={'null'} selected={this.state.section === null}>Alle afdelingen</option>
                      {this.getSectionOptions()}
                  </select>
                </div>

                <div style={{width: '6%', marginLeft: '0.6vw'}}>
                  <select
                      name="Selecteer een weergave"
                      className={'planner_dropdown'}
                      onChange={(event) => this.changeDisplay(event)}
                  >
                      <option value={'null'} disabled>Weergave</option>
                      <option value={'TABLE'} selected={this.state.display === 'TABLE'}>Normaal</option>
                      <option value={'XCL'} selected={this.state.display === 'XCL'}>Tabel</option>
                      {Data.data.appOptions.availability !== 'SCHOOL' ?
                        <option value={'LIST'} selected={this.state.display === 'LIST'}>Lijst</option>
                      : null}
                  </select>
                </div>

                <div style={{width: '12%', marginLeft: '0.6vw'}}>
                  <select
                      name="Selecteer een sjabloon"
                      className={'planner_dropdown'}
                      onChange={(event) => this.selectTemplate(event)}
                  >
                      <option value={'null'} disabled>Standaard diensten</option>
                      {/* <option value={'TABLE'} selected={this.state.display === 'TABLE'}>Simpel</option>
                      <option value={'XCL'} selected={this.state.display === 'XCL'}>Tabel</option>
                      {Data.data.appOptions.availability !== 'SCHOOL' ?
                        <option value={'LIST'} selected={this.state.display === 'LIST'}>Lijst</option>
                      : null} */}
                      {this.getShiftTemplates()}
                  </select>
                </div>

                {/* <div className="planner_new_addButton" style={{width: '9vw'}} onClick={() => this.openAddTempModal()}>
                  Sjabloon opslaan
                </div> */}

                <Tooltip
                  options
                  html={(
                    <div className='plannerN_tooltipContainer'>
                      <span>Diensten kopiëren of verplaatsen</span>
                    </div>
                  )}
                  position={'top'}
                  theme='kodict'
                  delay={300}
                  hideDelay={0}
                >
                  {this.state.copyShifts === true ?
                    <div className='reports_toggleButton_active' style={{marginLeft: '0.6vw'}} onClick={() => this.setState({ copyShifts: false })}>
                      <i className='fas fa-check-circle' />
                      <span><i className={'far fa-copy'} /></span>
                    </div>
                  :
                    <div className='reports_toggleButton' style={{marginLeft: '0.6vw'}} onClick={() => this.setState({ copyShifts: true })}>
                      <i className='fal fa-circle' />
                      <span><i className={'fal fa-copy'} /></span>
                    </div>
                  }
                </Tooltip>

                {Constants.isTrue(Data.data.appOptions.opties_smartplan) ?
                  <div className="planner_new_availableButton2" onClick={() => this.openSmartPlanner()}>
                    {/* <i className={'fal fa-lightbulb'} /> */}
                    Smart plan
                  </div>
                : null}

                {/* {Constants.isTrue(Data.data.appOptions.opties_planBeschikbaar) ?
                  <div className="planner_new_availableButton" style={{width: '10.5vw'}} onClick={this.showAvailableShiftsModal.bind(this)}>
                    Beschikbare diensten
                  </div>
                : null} */}

                <div className="planner_new_publishButton2" onClick={this.showPublishModal.bind(this)}>
                  {/* <i className={'fal fa-share-square'} /> */}
                  Publiceren ({parseInt(this.state.toPublish) + _unpublishedRepeats})
                </div>

                {/* {this.state.showUndoButton === true ? 
                  <div className="planner_new_undoButton" onClick={this.resetToBackupAlert.bind(this)}>
                    <i className={'far fa-undo-alt'} />
                  </div>
                : null}

                <div className="planner_new_deleteButton" onClick={this.deletePlannerAlert.bind(this)}>
                  <i className={'fal fa-trash-alt'} />
                </div> */}

                {/* {Data.data.accountData.account_rights === '2' ?
                  <div className="planner_addButton_management" onClick={() => this.props.history.push(`/functions`)}>
                    <i className={'fal fa-briefcase'} />
                    Functie toevoegen
                  </div>
                : null}

                <div className="planner_addButton_management" onClick={() => this.props.history.push(`/shifts`)}>
                  <i className={'fal fa-calendar-week'} />
                  Standaard dienst toevoegen
                </div>

                {Data.data.accountData.account_rights === '2' ?
                  <div className="planner_addButton_management" onClick={() => this.navBar.current.openRegistrationMenu()}>
                    <i className={'fal fa-user-plus'} />
                    Medewerker toevoegen
                  </div>
                : null}

                {this.state.display !== 'TABLE' ?
                  <div className='planner_addButton_management' onClick={() => this.sortFunctions()} style={{marginLeft: '5vw'}}>
                    <i className={'fal fa-sort-amount-down-alt'} />
                    Functies sorteren
                  </div>
                : null}

                <div className={`planner_addButton_management_toggle${this.state.showSalary ? '_green' : '_red'}`} onClick={() => this.toggleSalary()} style={{marginLeft: this.state.display === 'TABLE' ? '5vw' : 5}}>
                  <i className={'fal fa-euro-sign'} />
                  Loonkosten
                </div>

                <div className={`planner_addButton_management_toggle${this.state.showProductivity ? '_green' : '_red'}`} onClick={() => this.toggleProductivity()}>
                  <i className={'fal fa-user-chart'} />
                  Productiviteit
                </div>

                <div className={`planner_addButton_management_toggle${this.state.showDetails ? '_green' : '_red'}`} onClick={() => this.toggleDetails()}>
                  <i className={'fal fa-chart-bar'} />
                  Details
                </div> */}

              </div>

{/* 
              <span style={{marginLeft: 10, fontSize: '0.8vw', marginBottom: 3}}>Weergave: <b>{this.state.display === 'TABLE' ? 'Simpel' : this.state.display === 'LIST' ? 'Uitgebreid' : 'Tabel'}</b></span>
              <span style={{marginLeft: 10, fontSize: '0.8vw', marginBottom: 3}}>Planmodus: <b>{this.state.mode === 'CLICK' ? 'Klik' : 'Pop-up'}</b></span>
              {this.state.display === 'LIST' ?
              <span style={{marginLeft: 10, fontSize: '0.8vw', marginBottom: 3}}>Medewerkers gesorteerd op: <b>{this.state.sortBy === 'availability' ? 'Beschikbaarheid' : this.state.sortBy === 'name' ? 'Naam' : 'Functie'}</b></span>
              : null}
              {Data.data.teams.length > 0 && Data.data.chosenTeam !== null ?
                <span style={{marginLeft: 10, fontSize: '0.8vw', marginBottom: 3}}>Planning vestiging: <b>{Constants.getTeamDataChosen().name}</b></span>
              : null}
              <span style={{marginLeft: 10, fontSize: '0.8vw', marginBottom: 10}}>Diensten sjabloon: <b>{this.state.shiftTempName}</b></span> */}

              {/* {this.state.showSalary === true ?

                <div className='planner_collapseInfoToggle' style={{marginLeft: 10, fontSize: '0.8vw'}}>
                  
                  {this.state.collapseSalary === true ?
                    <span style={{cursor: 'pointer'}} onClick={() => this.setState({collapseSalary: !this.state.collapseSalary})}>
                      <i className='far fa-chevron-square-down planner_collapseInfoIcon' /> 
                      <b>Bruto loonkosten</b>
                    </span>
                  :
                    <span style={{cursor: 'pointer'}} onClick={() => this.setState({collapseSalary: !this.state.collapseSalary})}>
                      <i className='fal fa-chevron-square-up planner_collapseInfoIcon' /> 
                      <b>Bruto loonkosten</b>
                    </span>
                  }

                  <div className={`planner_collapseInfoContainer${this.state.collapseSalary === false ? '-active' : ''}`} style={{marginBottom: '0.5vw', fontSize: '0.7vw'}}>

                    <span 
                      className='defaultLink' 
                      style={{marginBottom: '0.5%'}}
                      onClick={() => this.props.history.push({
                        pathname: `/finances`,
                        refer: 'salary',
                      })}
                    >
                      Beheer uurlonen
                    </span>

                    <div>Week ({this.state.week}): <i><b>{this.formatter.format(this.state.salaryWeek)}</b></i></div>
                    <div>Maand {Constants.getMonthNameM(this.state.month - 1)}: <i><b>{this.formatter.format(this.state.salaryMonth1)}</b></i></div>
                    {this.state.salaryMonth2 > 0 ?
                      <div>Maand {Constants.getMonthNameM(this.state.month2 - 1)}: <i><b>{this.formatter.format(this.state.salaryMonth2)}</b></i></div>
                    : null}

                  </div>

                </div>

              : null}

              {this.state.showProductivity === true ?

                <div>

                  <div style={{marginLeft: 10, fontSize: '0.8vw'}}>
                    
                    {this.state.collapseProductivity === true ?
                      <span style={{cursor: 'pointer'}} onClick={() => this.setState({collapseProductivity: !this.state.collapseProductivity})}>
                        <i className='far fa-chevron-square-down planner_collapseInfoIcon' /> 
                        <b>Productiviteit</b>
                      </span>
                    :
                      <span style={{cursor: 'pointer'}} onClick={() => this.setState({collapseProductivity: !this.state.collapseProductivity})}>
                        <i className='fal fa-chevron-square-up planner_collapseInfoIcon' /> 
                        <b>Productiviteit</b>
                      </span>                
                    }

                  </div>

                  <div className={`planner_collapseInfoContainer2${this.state.collapseProductivity === false ? '-active' : ''}`} style={{marginLeft: 10, marginBottom: '1vw', fontSize: '0.7vw'}}>

                      <span 
                        className='defaultLink' 
                        style={{marginBottom: '0.5%'}}
                        onClick={() => this.props.history.push({
                          pathname: `/finances`,
                          refer: 'gains',
                        })}
                      >
                        Beheer omzet
                      </span>

                      <div>Gemiddeld per gewerkt uur {this.state.year}: <b style={{fontSize: '0.7vw'}}><i>{this.state.productivityTotal === 0 || isNaN(this.state.productivityTotal) ? 'n.v.t.' : this.formatter.format(this.state.productivityTotal)}</i></b></div>

                      <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '0.5%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                          <div style={{textDecoration: this.state.month === 1 ? 'underline' : 'none'}}>Januari: {this.state.productivityMonths[1] !== null ? this.state.productivityMonths[1].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[1].pr)}</b> p/u &#9679; {this.state.productivityMonths[1].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[1].gains)} omzet</span> : <span><i>{this.state.productivityMonths[1].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[1].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 2 || this.state.month2 === 2 ? 'underline' : 'none'}}>Februari: {this.state.productivityMonths[2] !== null ? this.state.productivityMonths[2].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[2].pr)}</b> p/u &#9679; {this.state.productivityMonths[2].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[2].gains)} omzet</span> : <span><i>{this.state.productivityMonths[2].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[2].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 3 || this.state.month2 === 3 ? 'underline' : 'none'}}>Maart: {this.state.productivityMonths[3] !== null ? this.state.productivityMonths[3].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[3].pr)}</b> p/u &#9679; {this.state.productivityMonths[3].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[3].gains)} omzet</span> : <span><i>{this.state.productivityMonths[3].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[3].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 4 || this.state.month2 === 4 ? 'underline' : 'none'}}>April: {this.state.productivityMonths[4] !== null ? this.state.productivityMonths[4].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[4].pr)}</b> p/u &#9679; {this.state.productivityMonths[4].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[4].gains)} omzet</span> : <span><i>{this.state.productivityMonths[4].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[4].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 5 || this.state.month2 === 5 ? 'underline' : 'none'}}>Mei: {this.state.productivityMonths[5] !== null ? this.state.productivityMonths[5].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[5].pr)}</b> p/u &#9679; {this.state.productivityMonths[5].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[5].gains)} omzet</span> : <span><i>{this.state.productivityMonths[5].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[5].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 6 || this.state.month2 === 6 ? 'underline' : 'none'}}>Juni: {this.state.productivityMonths[6] !== null ? this.state.productivityMonths[6].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[6].pr)}</b> p/u &#9679; {this.state.productivityMonths[6].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[6].gains)} omzet</span> : <span><i>{this.state.productivityMonths[6].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[6].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                          <div style={{textDecoration: this.state.month === 7 || this.state.month2 === 7 ? 'underline' : 'none'}}>Juli: {this.state.productivityMonths[7] !== null ? this.state.productivityMonths[7].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[7].pr)}</b> p/u &#9679; {this.state.productivityMonths[7].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[7].gains)} omzet</span> : <span><i>{this.state.productivityMonths[7].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[7].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 8 || this.state.month2 === 8 ? 'underline' : 'none'}}>Augustus: {this.state.productivityMonths[8] !== null ? this.state.productivityMonths[8].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[8].pr)}</b> p/u &#9679; {this.state.productivityMonths[8].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[8].gains)} omzet</span> : <span><i>{this.state.productivityMonths[8].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[8].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 9 || this.state.month2 === 9 ? 'underline' : 'none'}}>September: {this.state.productivityMonths[9] !== null ? this.state.productivityMonths[9].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[9].pr)}</b> p/u &#9679; {this.state.productivityMonths[9].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[9].gains)} omzet</span> : <span><i>{this.state.productivityMonths[9].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[9].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 10 || this.state.month2 === 10 ? 'underline' : 'none'}}>Oktober: {this.state.productivityMonths[10] !== null ? this.state.productivityMonths[10].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[10].pr)}</b> p/u &#9679; {this.state.productivityMonths[10].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[10].gains)} omzet</span> : <span><i>{this.state.productivityMonths[10].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[10].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 11 || this.state.month2 === 11 ? 'underline' : 'none'}}>November: {this.state.productivityMonths[11] !== null ? this.state.productivityMonths[11].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[11].pr)}</b> p/u &#9679; {this.state.productivityMonths[11].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[11].gains)} omzet</span> : <span><i>{this.state.productivityMonths[11].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[11].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                          <div style={{textDecoration: this.state.month === 12 || this.state.month2 === 12 ? 'underline' : 'none'}}>December: {this.state.productivityMonths[12] !== null ? this.state.productivityMonths[12].expect === false ? <span><b>{this.formatter.format(this.state.productivityMonths[12].pr)}</b> p/u &#9679; {this.state.productivityMonths[12].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[12].gains)} omzet</span> : <span><i>{this.state.productivityMonths[12].hours} uren &#9679; {this.formatter.format(this.state.productivityMonths[12].gains)} verwachte omzet</i></span> : <span><i>n.v.t.</i></span>}</div>
                        </div>
                      </div>

                  </div>

                </div>

              : null} */}

              {this.state.display === 'LIST' ?

              <div>

                <div className='plannerN_dateContainer stickyTopPlanner'>

                  <div className='planner_dateText_fillSpace_left' />

                  {this.renderDayData(false)}

                  <div className='planner_dateText_fillSpace' />

                </div>

                <div className='planner_listDisplay'>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.mondayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.mondayDate).style.color = '#282828'} 
                    >
                      
                    {/* <div className='planner_dateText stickyTopPlanner'>Maandag {this.getMonday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getMonday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('mon')} /><br/>{this.showDayNote('mon')}<br/>{Constants.getNationalHoliday(this.getMonday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getMonday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}

                      {this.getDayData('mon', 'monday', 0, false)}
                    
                    </div> */}
                    
                    <div>{plannerBars.mon}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.mon}</div>
                        <div>{notFilledBars.mon}</div>
                        <div>{unavailableBars.mon}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().mon}</div>
                        <div>{this.getStandardNotFilledBars().mon}</div>
                        <div>{this.getStandardUnavailableBars().mon}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.mon}</div>
                        <div>{schoolHolidayBars.mon}</div>
                        <div>{schoolScheduleBars.mon}</div>
                        <div>{unavailableBars.mon}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.mon}</div>
                    //     <div>{unavailableBars.mon}</div>
                    //   </div>

                    // :
                    Data.data.appOptions.availability === 'NONE' ?
                    
                      <div>
                        <div>{allPeopleBars.mon}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.mon}</div>

                  </div>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.tuesdayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.tuesdayDate).style.color = '#282828'} 
                    >
                      
                    {/* <div className='planner_dateText stickyTopPlanner'>Dinsdag {this.getTuesday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getTuesday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('tue')} /><br/>{this.showDayNote('tue')}<br/>{Constants.getNationalHoliday(this.getTuesday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getTuesday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                      {this.getDayData('tue', 'tuesday', 1, false)}
                    
                    </div> */}

                    <div>{plannerBars.tue}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.tue}</div>
                        <div>{notFilledBars.tue}</div>
                        <div>{unavailableBars.tue}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().tue}</div>
                        <div>{this.getStandardNotFilledBars().tue}</div>
                        <div>{this.getStandardUnavailableBars().tue}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.tue}</div>
                        <div>{schoolHolidayBars.tue}</div>
                        <div>{schoolScheduleBars.tue}</div>
                        <div>{unavailableBars.tue}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.tue}</div>
                    //     <div>{unavailableBars.tue}</div>
                    //   </div>

                    // :
                    Data.data.appOptions.availability === 'NONE' ?
                    
                      <div>
                        <div>{allPeopleBars.tue}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.tue}</div>

                  </div>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.wednesdayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.wednesdayDate).style.color = '#282828'} 
                    >

                    {/* <div className='planner_dateText stickyTopPlanner'>Woensdag {this.getWednesday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getWednesday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('wed')} /><br/>{this.showDayNote('wed')}<br/>{Constants.getNationalHoliday(this.getWednesday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getWednesday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                    {this.getDayData('wed', 'wednesday', 2, false)}
                    
                    </div> */}

                    <div>{plannerBars.wed}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.wed}</div>
                        <div>{notFilledBars.wed}</div>
                        <div>{unavailableBars.wed}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().wed}</div>
                        <div>{this.getStandardNotFilledBars().wed}</div>
                        <div>{this.getStandardUnavailableBars().wed}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.wed}</div>
                        <div>{schoolHolidayBars.wed}</div>
                        <div>{schoolScheduleBars.wed}</div>
                        <div>{unavailableBars.wed}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.wed}</div>
                    //     <div>{unavailableBars.wed}</div>
                    //   </div>

                    // :
                    Data.data.appOptions.availability === 'NONE' ?
                    
                      <div>
                        <div>{allPeopleBars.wed}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.wed}</div>

                  </div>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.thursdayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.thursdayDate).style.color = '#282828'} 
                    >

                    {/* <div className='planner_dateText stickyTopPlanner'>Donderdag {this.getThursday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getThursday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('thu')} /><br/>{this.showDayNote('thu')}<br/>{Constants.getNationalHoliday(this.getThursday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getThursday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                    {this.getDayData('thu', 'thursday', 3, false)}
                    
                    </div> */}

                    <div>{plannerBars.thu}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.thu}</div>
                        <div>{notFilledBars.thu}</div>
                        <div>{unavailableBars.thu}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().thu}</div>
                        <div>{this.getStandardNotFilledBars().thu}</div>
                        <div>{this.getStandardUnavailableBars().thu}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.thu}</div>
                        <div>{schoolHolidayBars.thu}</div>
                        <div>{schoolScheduleBars.thu}</div>
                        <div>{unavailableBars.thu}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.thu}</div>
                    //     <div>{unavailableBars.thu}</div>
                    //   </div>

                    // :
                    Data.data.appOptions.availability === 'NONE' ?
                    
                      <div>
                        <div>{allPeopleBars.thu}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.thu}</div>

                  </div>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.fridayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.fridayDate).style.color = '#282828'} 
                    >

                    {/* <div className='planner_dateText stickyTopPlanner'>Vrijdag {this.getFriday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getFriday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('fri')} /><br/>{this.showDayNote('fri')}<br/>{Constants.getNationalHoliday(this.getFriday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getFriday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                    {this.getDayData('fri', 'friday', 4, false)}
                    
                    </div> */}

                    <div>{plannerBars.fri}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.fri}</div>
                        <div>{notFilledBars.fri}</div>
                        <div>{unavailableBars.fri}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().fri}</div>
                        <div>{this.getStandardNotFilledBars().fri}</div>
                        <div>{this.getStandardUnavailableBars().fri}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.fri}</div>
                        <div>{schoolHolidayBars.fri}</div>
                        <div>{schoolScheduleBars.fri}</div>
                        <div>{unavailableBars.fri}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.fri}</div>
                    //     <div>{unavailableBars.fri}</div>
                    //   </div>

                    // :
                      Data.data.appOptions.availability === 'NONE' ?
                      
                      <div>
                        <div>{allPeopleBars.fri}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.fri}</div>

                  </div>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.saturdayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.saturdayDate).style.color = '#282828'} 
                    >

                    {/* <div className='planner_dateText stickyTopPlanner'>Zaterdag {this.getSaturday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getSaturday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('sat')} /><br/>{this.showDayNote('sat')}<br/>{Constants.getNationalHoliday(this.getSaturday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getSaturday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                    {this.getDayData('sat', 'saturday', 5, false)}

                    </div> */}

                    <div>{plannerBars.sat}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.sat}</div>
                        <div>{notFilledBars.sat}</div>
                        <div>{unavailableBars.sat}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().sat}</div>
                        <div>{this.getStandardNotFilledBars().sat}</div>
                        <div>{this.getStandardUnavailableBars().sat}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.sat}</div>
                        <div>{schoolHolidayBars.sat}</div>
                        <div>{schoolScheduleBars.sat}</div>
                        <div>{unavailableBars.sat}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.sat}</div>
                    //     <div>{unavailableBars.sat}</div>
                    //   </div>

                    // :
                      Data.data.appOptions.availability === 'NONE' ?
                      
                      <div>
                        <div>{allPeopleBars.sat}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.sat}</div>

                  </div>

                    <div className='planner_dayContainer'
                      onMouseEnter={() => document.getElementById(this.state.sundayDate).style.color = '#47B7EB'} 
                      onMouseLeave={() => document.getElementById(this.state.sundayDate).style.color = '#282828'} 
                    >

                    {/* <div className='planner_dateText stickyTopPlanner'>Zondag {this.getSunday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getSunday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('sun')} /><br/>{this.showDayNote('sun')}<br/>{Constants.getNationalHoliday(this.getSunday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getSunday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                    {this.getDayData('sun', 'sunday', 6, false)}
                    
                    </div> */}

                    <div>{plannerBars.sun}</div>
                    
                    {Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                      <div>
                        <div>{availableBars.sun}</div>
                        <div>{notFilledBars.sun}</div>
                        <div>{unavailableBars.sun}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'STANDARD' ?

                      <div>
                        <div>{this.getStandardAvailableBars().sun}</div>
                        <div>{this.getStandardNotFilledBars().sun}</div>
                        <div>{this.getStandardUnavailableBars().sun}</div>
                      </div>

                    :
                    Data.data.appOptions.availability === 'SCHOOL' ?

                      <div>
                        <div>{notFilledSchoolBars.sun}</div>
                        <div>{schoolHolidayBars.sun}</div>
                        <div>{schoolScheduleBars.sun}</div>
                        <div>{unavailableBars.sun}</div>
                      </div>

                    :
                    // Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    
                    //   <div>
                    //     <div>{notFilledBars.sun}</div>
                    //     <div>{unavailableBars.sun}</div>
                    //   </div>

                    // :
                    Data.data.appOptions.availability === 'NONE' ?
                    
                      <div>
                        <div>{allPeopleBars.sun}</div>
                      </div>

                    :

                      null

                    }

                    <div>{holidayBars.sun}</div>

                  </div>

                </div>

              </div>

              :

                <div className='planner_table'>

                  {/* <div className='planner_table_nameContainer stickyTopPlanner'>

                    <div className='planner_table_nameContainer_name'>
                      <span className='planner_dateTextTable'>Naam<br/><span>&nbsp;</span><br/><div style={{height: '1vw'}}>&nbsp;</div>

                        {this.state.opties_planner_drukte === true ?
                          <div className='planner_expectContainerOuter'>&nbsp;</div>
                        : null}

                        {this.state.opties_planner_vorigOmzet === true ?
                          <div>
                            <br/>
                            <span className='planner_dateText_sub' style={{paddingLeft: 0, fontStyle: 'italic'}}>Omzet {this.state.year - 1}</span>
                          </div>
                        : null}

                        {this.state.opties_planner_verwachteOmzet === true ?
                          <div>
                            <br/>
                            <span className='planner_dateText_sub' style={{paddingLeft: 0}}>Verwachte omzet {this.state.editOmzetV ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn}} onClick={() => this.editOmzetDagelijksV()} /> : <i className='fal fa-pen' onClick={() => this.setState({ editOmzetV: true })} />}</span>
                          </div>
                        : null}

                        {this.state.opties_planner_omzet === true ?
                          <div>
                            <br/>
                            <span className='planner_dateText_sub' style={{paddingLeft: 0}}>Omzet {this.state.editOmzet ? <i className='fas fa-check' style={{color: Colors.color.greenFilledIn}} onClick={() => this.editOmzetDagelijks()} /> : <i className='fal fa-pen' onClick={() => this.setState({ editOmzet: true })} />}</span>
                          </div>
                        : null}

                        {this.state.opties_planner_loonkosten === true ?
                          <div>
                            <br/>
                            <span className='planner_dateText_sub' style={{paddingLeft: 0}}>Bruto loonkosten</span>
                          </div>
                        : null}

                        {this.state.opties_planner_berekeningen === true ?
                          <div>
                            <br/>
                            <span className='planner_dateText_sub' style={{paddingLeft: 0}}><b>Totaal</b></span>
                          </div>
                        : null}

                        {this.state.opties_planner_productiviteit === true ?
                          <div>
                            <br/>
                            <span className='planner_dateText_sub' style={{paddingLeft: 0}}>Productiviteit</span>
                          </div>
                        : null}
                      
                      </span>

                      <div className='planner_dateTextTable_fillSpace_left' />

                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Maandag {this.getMonday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getMonday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('mon')} /><br/>{this.showDayNote('mon')}<br/>{Constants.getNationalHoliday(this.getMonday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getMonday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                    
                          {this.getDayData('mon', 'monday', 0, true)}

                      </div>
                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Dinsdag {this.getTuesday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getTuesday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('tue')} /><br/>{this.showDayNote('tue')}<br/>{Constants.getNationalHoliday(this.getTuesday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getTuesday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                      
                      {this.getDayData('tue', 'tuesday', 1, true)}
                      
                      </div>
                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Woensdag {this.getWednesday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getWednesday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('wed')} /><br/>{this.showDayNote('wed')}<br/>{Constants.getNationalHoliday(this.getWednesday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getWednesday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                      
                      {this.getDayData('wed', 'wednesday', 2, true)}
                      
                      </div>
                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Donderdag {this.getThursday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getThursday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('thu')} /><br/>{this.showDayNote('thu')}<br/>{Constants.getNationalHoliday(this.getThursday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getThursday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                      
                      {this.getDayData('thu', 'thursday', 3, true)}
                      
                      </div>
                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Vrijdag {this.getFriday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getFriday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('fri')} /><br/>{this.showDayNote('fri')}<br/>{Constants.getNationalHoliday(this.getFriday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getFriday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                      
                      {this.getDayData('fri', 'friday', 4, true)}

                      </div>
                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Zaterdag {this.getSaturday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getSaturday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('sat')} /><br/>{this.showDayNote('sat')}<br/>{Constants.getNationalHoliday(this.getSaturday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getSaturday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                      
                      {this.getDayData('sat', 'saturday', 5, true)}

                      </div>
                    </div>

                    <div className='planner_table_nameContainer_sub'>
                      <div className='planner_dateTextTable'>Zondag {this.getSunday(this.WEEK, this.YEAR).getDate()} {Constants.getMonthName(Constants.formatDate(this.getSunday(this.WEEK, this.YEAR))).replace('.', '')} <i className='fal fa-sticky-note planner_dayNoteIcon' style={{fontSize: '0.6vw', cursor: 'pointer'}} onClick={() => this.openDayNoteModal('sun')} /><br/>{this.showDayNote('sun')}<br/>{Constants.getNationalHoliday(this.getSunday(this.WEEK, this.YEAR), this.state.publicHolidays) === null ? <div style={{height: '1vw'}}>&nbsp;</div> : <div style={{fontSize: '0.8vw', height: '1vw'}}>{Constants.getNationalHoliday(this.getSunday(this.WEEK, this.YEAR), this.state.publicHolidays)}</div>}
                      
                      {this.getDayData('sun', 'sunday', 6, true)}

                      </div>

                      <div className='planner_dateTextTable_fillSpace' />
                      
                    </div>

                  </div> */}

                  <div className='plannerN_dateContainer stickyTopPlanner' style={{width: '100%', paddingLeft: 0, paddingRight: 0}}>

                    <div className='planner_dateText_fillSpace_left' />

                    {this.renderDayData(true)}

                    <div className='planner_dateText_fillSpace' />

                  </div>

                  {this.getAllUsersTableDisplay()}

                </div>
                            
              }

            </div>
        
          }

        </div>
    );
  }
  
}

export default PlannerV2;