/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './registrationCodesModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';
import '../../pages/options/reports/reports.scss';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import RegistrationCodes from '../../pages/options/registrationCodes/registrationCodes';


class RegistrationCodesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat(Constants.getLang(), {
      style: 'currency',
      currency: Data.data.storeCurrency,
    });

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {



    };

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="registrationCodeModal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.closeModal() : null}>

        <div className="registrationCodeModal-content" style={{minHeight: '15vw'}}>

          <div className="registrationCodeModal-header">
              <span className="modal-close" onClick={() => this.closeModal()}>&times;</span>
              <p>Registratiecodes</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="registrationCodeModal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  history={this.props.history}
                />

              :

                null
          
              }

              <div className='registrationCodeModal_content' style={{width: '100%'}}>

                {this.state.loading === true ?
                
                  <div className='loader'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                  </div>

                :

                  <div className='registrationCodeModal_container'>

                    <RegistrationCodes />

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

        </div>

      </div>
    );
  }
  
}

export default RegistrationCodesModal;