/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './absencetypes.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddDefaultShift from '../../../classes/APIAddDefaultShift';
import APIEditDefaultShift from '../../../classes/APIEditDefaultShift';
import APIDeleteDefaultShift from '../../../classes/APIDeleteDefaultShift';

import { ChromePicker } from 'react-color';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import APIAddParticular from '../../../classes/APIAddParticular';
import APIEditParticular from '../../../classes/APIEditParticular';
import APIDeleteParticular from '../../../classes/APIDeleteParticular';
import APIAddAbsencetype from '../../../classes/APIAddAbsencetype';
import APIEditAbsencetype from '../../../classes/APIEditAbsencetype';
import APIDeleteAbsencetype from '../../../classes/APIDeleteAbsencetype';

class AbsencetypesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        name: '',
        afk: '',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDINg
        
    }

    if(this.mode === 1) { //EDITING

      this.setState({
        name: this.modalData.afw_naam,
        afk: this.modalData.afw_afkorting,
      });

    }

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je dit afwezigheidstype wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteTimes.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddAbsencetype.Request(
      this.state.name,
      this.state.afk === '' ? this.state.name.toLowerCase().substr(0, 3) : this.state.afk,
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditAbsencetype.Request(
      this.state.name,
      this.state.afk === '' ? this.state.name.toLowerCase().substr(0, 3) : this.state.afk,
      this.modalData.afw_id
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteTimes() {

    this.setState({loading: true});

    const added = await APIDeleteAbsencetype.Request(
      this.modalData.afw_id
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.name === '') {
        this.setState({
          showError: true,
          errorText: 'Je hebt geen naam ingevoerd',
        });
        return false;
      }

    return true;

  }

  goBack() {

    this.props._closeModal();

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="absencetypeModal" className="modal" onClick={(e) => e.target === document.getElementById("zabsencetypeModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Afwezigheid toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Afwezigheid wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                          <span><b>Naam afwezigheid</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={25}
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                            placeholder={'Bijv. "Ziek" / "Te laat" / "Niet op komen dagen"'}
                          />

                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Afkorting</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Maximaal 3 karakters</span>
                        </div>

                        <div className='modal-rowSub2'>

                          <input 
                            className={'inputField2'}
                            maxLength={3}
                            value={this.state.afk}
                            onChange={(event) => this.setState({ afk: event.target.value })}
                            placeholder={'Bijv. "zie" / "tl" / "nod"'}
                          />

                        </div>

                    </div>
                    
                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editTimes()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveTimes()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default AbsencetypesModal;