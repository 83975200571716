/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './registrationCodes.css';
import '../../availability/availability.css';
import '../../planner/planner.scss';
import '../myHours/myHours.css';
import '../../../constants/DefaultStyle.css';
import '../hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import APIGetAllRegistrationCodes from '../../../classes/APIGetAllRegistrationCodes';
import APIGetFunctions from '../../../classes/APIGetFunctions';
import Colors from '../../../constants/Colors';
import RegistrationCodesModal from './registrationCodesModal';
import APIRegisterDeleteCode from '../../../classes/APIRegisterDeleteCode';

class RegistrationCodes extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      codes: [],
      functions: [],

      showModal: false,
      modalMode: 0,
      modalData: [],
      modalDate: null,
      modalAvailable: null,
      modalMultipleDays: [],

      showAlert: false,

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    if(Data.data.loggedIn === false) {
      return;
    }

    const codesObject = await APIGetAllRegistrationCodes.Request();

    if(codesObject !== null) {
        this.setState({codes: codesObject});
    }

    const functionsData = await APIGetFunctions.Request();

    if(functionsData !== null) {
        this.setState({functions: functionsData});
    }

    this.setState({loading: false});

  }

  openRegistrationCodesModal(code) {
    this.setState({ 
      showModal: true,
      modalData: code,
    });
  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
    });

  }

  deleteAlert(code) {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze registratiecode wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteCode.bind(this, code),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async deleteCode(code) {

    this.setState({ 
        loading: true,
    });

    const deleted = await APIRegisterDeleteCode.Request(code);

    if (deleted === true) {

        const codesObject = await APIGetAllRegistrationCodes.Request();

        if(codesObject !== null) {
            this.setState({codes: codesObject});
        }

        this.setState({loading: false});

    } else {

        this.setState({ 
            loading: false,
        });

    }
  }

  getFunctionName(functionID) {

    for(let key in this.state.functions) {

        const _function = this.state.functions[key];

        if(parseInt(_function.functie_id) === functionID) {
            return _function.functie_naam;
        }

    }

    return null;

  } 

  getRegCodesBars() {

    let hourBars = [];
    let count = 0;

    for(let key in this.state.codes) {

      const code = this.state.codes[key];

      hourBars.push(

        <div key={key} className={'hr_hoursBar_noHeight'} style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

          <div className={'hr_hoursBar_sub5'} style={{fontWeight: 'bold'}}>
            {code.registratie_code}
          </div>
          <div className={'hr_hoursBar_sub2'}>
            {code.registratie_username}
          </div>
          <div className={'hr_hoursBar_sub2'}>
            {code.registratie_email}
          </div>
          <div className={'hr_hoursBar_sub2'}>
            {code.registratie_telefoon}
          </div>
          <div className={'hr_hoursBar_sub2'}>
            {this.getFunctionName(parseInt(code.registratie_functie_id))}
          </div>
          <div className={'hr_hoursBar_sub2'}>
          {code.registratie_rechten === '2' ?
                                
              'Administrator'

          :
          code.registratie_rechten === '1' ?

              'Planner'

          :

              'Medewerker'

          }
          </div>
          <div className={'hr_hoursBar_sub4'} style={{flex: 0.25}}>
            <i className='fa fa-share-alt' style={{cursor: 'pointer'}} onClick={() => this.openRegistrationCodesModal(code.registratie_code)}></i>
          </div>
          <div className={'hr_hoursBar_sub4'} style={{flex: 0.25}}>
            <i className='fas fa-minus-circle' style={{cursor: 'pointer', color: Colors.color.redFilledIn}} onClick={() => this.deleteAlert(code.registratie_code)}></i>
          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return hourBars;

  }

  render() {

    const regCodesBars = this.getRegCodesBars();

    return (
        <div className='registrationCodes'>

          {this.state.loading === true ?
          
                      <div className='loader'>
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                      </div>

          :

            <div className='myHours_container'>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
                  <div className="spinner">
                      <div className="double-bounce1"></div>
                      <div className="double-bounce2"></div>
                  </div>
                </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <RegistrationCodesModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              <div className={'hr_hoursBar_title'}>
                <div className={'hr_hoursBar_sub5'}>
                  <div>Code</div>
                </div>
                <div className={'hr_hoursBar_sub2'}>
                  <div>Gebruikersnaam</div>
                </div>
                <div className={'hr_hoursBar_sub2'}>
                  <div>E-mail</div>
                </div>
                <div className={'hr_hoursBar_sub2'}>
                  <div>Telefoon</div>
                </div>
                <div className={'hr_hoursBar_sub2'}>
                  <div>Functie</div>
                </div>
                <div className={'hr_hoursBar_sub2'}>
                  <div>Rechten</div>
                </div>
                <div className={'hr_hoursBar_sub4'} style={{flex: 0.25}}>
                  <div></div>
                </div>
                <div className={'hr_hoursBar_sub4'} style={{flex: 0.25}}>
                  <div></div>
                </div>
              </div>

              {regCodesBars}
              
            </div>
        
          }

        </div>
    );
  }
  
}

export default RegistrationCodes;