/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './functions.css';
import '../../planner/planner.scss';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Navbar from '../../../components/navbar/navbar';

import APIGetFunctions from '../../../classes/APIGetFunctions';

import 'react-datepicker/dist/react-datepicker.css';
import BetterAlert from '../../../components/alert/alert';
import FunctionsModal from './functionsModal';
import Constants from '../../../constants/Constants';

class Functions extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
        this.props.history.push("/");
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      this.props.history.push("/");
    }

    // compwillmount
    
    this.state = {

      loading: true,
      reloading: false,

      functions: [],

      showModal: false,
      modalMode: 0,
      modalData: [],

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    await this.getFunctions();

    this.setState({loading: false});

  }

  openFunctionModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  async getFunctions() {

    let functions = await APIGetFunctions.Request();

    if(functions === 'error') {

        functions = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });

        return;
    }

    this.setState({ functions: functions });

  }

  getFunctionBars() {

    let functionBars = [];

    for(let key in this.state.functions) {

        const functionObject = this.state.functions[key];

        functionBars.push(

            <div key={key} className='touchBoxBase' onClick={() => this.openFunctionModal(1, functionObject)}>

                <div className='touchContainer'
                  style={{ 
                            borderBottomColor: `#${functionObject.functie_kleur}`,
                            borderLeftColor: `#${functionObject.functie_kleur}`,
                            cursor: 'pointer',
                            backgroundColor: Constants.hexToRgbA(`#${functionObject.functie_kleur}`, 0.2),
                        }} 
                >

                  <div className='initContainer' style={{backgroundColor: Constants.hexToRgbA(`#${functionObject.functie_kleur}`, 0.6), flex: 0.2}}>

                    <span style={{color: Constants.getContrastPlan(`#${functionObject.functie_kleur}`)}}>{functionObject.functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                      
                  </div>

                  <div className='functionContainer' style={{paddingLeft: 10}}>

                    {functionObject.functie_naam}

                  </div>

                </div>

            </div>

        );

    }

    return functionBars;

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getFunctions();

    this.setState({reloading: false});

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  render() {

    const functionBars = this.getFunctionBars();

    return (
        <div className='functions'>

          <Navbar history={this.props.history} active={'functions'} menuActive={true} loading={this.state.loading} reloading={this.state.reloading} />

          <div className='planner_nav2'>

            <div className='functions_nav2_sub1'>
            </div> 
            <div className='functions_nav2_sub2'>
            </div>
            <div className='functions_nav2_sub3'>
              Functies
            </div> 
            <div className='functions_nav2_sub4' style={{cursor: 'default'}}>
                {/* <i className='fa fa-plus'></i> */}
            </div>
            <div className='functions_nav2_sub5'>
            </div>

          </div>

          {this.state.loading === true ?
          
                      <div className='loader'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

          :

            <div className='functions_container' style={{width: '40%', marginLeft: '30%'}}>

              {this.state.reloading === true ?
                        
                <div className='loader2'>
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
          </div>

              :

                null

              }

              {this.state.showModal === true ?
                
                <FunctionsModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                />

              :

                null
              
              }

              <div className="planner_addButtons_container" style={{marginLeft: 0, marginBottom: '2vw', marginTop: 0}}>

                <div className="planner_new_addButton" onClick={() => this.openFunctionModal(0)}>
                  {/* <i className={'fal fa-plus'} /> */}
                  Toevoegen
                </div>

              </div>

              {this.state.showAlert === true ?
                
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
              
              }

              {this.state.functions.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen functies aangemaakt</div>
                </div>

              :

                null
              
              }

              {functionBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Functions;