import LocalizedStrings from 'react-localization';

class Localization {

    constructor() {

    this.t = new LocalizedStrings({

        en: {
            // Buttons
            next_button: 'Next',
            continue_button: 'Continue',
            login_button: 'Login',

            // Days
            day_monday: 'Monday',
            day_tuesday: 'Tuesday',
            day_wednesday: 'Wednesday',
            day_thursday: 'Thursday',
            day_friday: 'Friday',
            day_saturday: 'Saturday',
            day_sunday: 'Sunday',

            // Days
            day_monday_shrt: 'Mon',
            day_tuesday_shrt: 'Tue',
            day_wednesday_shrt: 'Wed',
            day_thursday_shrt: 'Thu',
            day_friday_shrt: 'Fri',
            day_saturday_shrt: 'Sat',
            day_sunday_shrt: 'Sun',

            // Months
            month_jan: 'January',
            month_feb: 'February',
            month_mar: 'March',
            month_apr: 'April',
            month_may: 'May',
            month_jun: 'June',
            month_jul: 'July',
            month_aug: 'August',
            month_sep: 'September',
            month_oct: 'October',
            month_nov: 'November',
            month_dec: 'December',

            // Short Months
            month_jan_shrt: 'Jan',
            month_feb_shrt: 'Feb',
            month_mar_shrt: 'Mar',
            month_apr_shrt: 'Apr',
            month_may_shrt: 'May',
            month_jun_shrt: 'Jun',
            month_jul_shrt: 'Jul',
            month_aug_shrt: 'Aug',
            month_sep_shrt: 'Sep',
            month_oct_shrt: 'Oct',
            month_nov_shrt: 'Nov',
            month_dec_shrt: 'Dec',






            /* SERVER */
            server_error: "A server error has occurred. Try again later",
            server_migration: "The server is in migration. Try again later",
            server_maintenance: "The server is under maintenance. Try again later",

            /* ALERTS */
            alert_save_title: "Save",
            alert_save: "Are you sure you want to save this?",

            alert_delete_title: "Delete",
            alert_delete: "Are you sure you want to delete this?",

            alert_edit_title: "Edit",
            alert_edit: "Are you sure you want to edit this?",

            alert_add_title: "Add",
            alert_add: "Are you sure you want to add this?",

            alert_action_title: "Action",
            alert_action: "Are you sure you want to do this action?",

            alert_idle_title: "Inactive",
            alert_idle_minutes: "15 minutes",
            alert_idle: "You have been inactive for more than {0}. To make sure employee requests, leave and availability are up to date, please refresh this window",
            alert_idle_sub_refresh: "Refresh",
            alert_idle_sub: "Click the {0} button to reload Tiemdo",

            alert_success_title: "Success",
            alert_success_savedShifts: "Your shifts have been saved",
            alert_success_acceptedShift: "You have accepted to take over this shift",
            alert_success_copiedAvail: "Your availability has been copied",

            alert_warning_title: "Warning",

            alert_error_title: "Error",
            alert_error_manager: "Show this to your manager",

            alert_failed_title: "Failed",
            alert_failed_maxEmployees: "You have reached the maximum number of registration codes and employees of {0}",
            alert_failed_noTeam: "You are not yet linked to a team. Contact your manager",
            alert_failed_noShiftsAgenda: "You currently have no shifts to save to your calendar",
            alert_failed_media5MB: "Image upload failed. Make sure the image is not larger than 5mb",

            alert_failed_permission_agenda: "You must give Tiemdo access to your calendar. Go to your calendar settings",
            alert_failed_permission_location: `You have not yet given Tiemdo access to your location settings. In the next window you will be asked to grant access to your location data.\n\nNOTE: turn on precise location`,
            alert_failed_permission_location2: `You must give Tiemdo access to your location data in order to use the clock-in system.\n\nNOTE: turn on precise location`,
            alert_failed_permission_media: `Tiemdo needs access to your media files to upload a profile picture`,
            alert_failed_permission_noti: `You must give Tiemdo access to send notifications. Change this in your mobile's notification settings`,
            alert_failed_permission_camera_title: `Tiemdo has no access to your camera.`,
            alert_failed_permission_camera: `Try again or allow Tiemdo to access your camera in your settings.`,
            alert_failed_permission_camera2: `You have to give Tiemdo access to your camera to scan QR codes`,

            alert_approve_title: "Approve",
            alert_approve: "Are you sure you want to approve this request?",
            alert_approve2: "Are you sure you want to approve this action?",

            alert_deny_title: "Deny",
            alert_deny: "Are you sure you want to deny this request?",
            alert_deny2: "Are you sure you want to deny this action?",

            alert_accept_title: "Accept",
            alert_accept: "Are you sure you want to accept this request?",

            alert_logout_title: "Log out",
            alert_logout: "Are you sure you want to log out?",

            alert_welcome_title: "Set up account",
            alert_welcome: "Welcome at",
            alert_welcome_sub: "It looks like you are using Tiemdo for the first time. To make sure you have the correct settings, a window will open where you can set up your environment.",
            alert_welcomeSettings: "It seems that you are already using Tiemdo. Many new settings have been added since you started.",
            alert_welcomeSettings_sub: "Do you want to check if you still have the correct settings?",

            alert_network_title: "Network",
            alert_network: "Something seems to have gone wrong with your connection while logging in. Try again",

            alert_notification_title: "Notifications",
            alert_notification_question: "Would you like to receive notifications about deadlines, own shifts, trade requests and leave requests?",
            alert_notification_later: "You can change your notification settings later at Options",

            alert_agenda_title: "Calendar",
            alert_agenda_save: "Do you want to save your own shifts and deadlines in your calendar?",

            alert_availableShift_takeOver_title: "Take over shift",
            alert_availableShift_takeOver: "Are you sure you want to take over this shift?",

            alert_app_added_param: "{0} has been added",
            alert_app_edited_param: "{0} has been changed",
            alert_app_deleted_param: "{0} has been deleted",

            alert_app_calendar_added: "Your shifts have been saved to your calendar",
            alert_app_calendar_deleted: "Your shifts have been removed from your calendar",
            alert_app_calendar_error: "You currently have no shifts to save to your mobile calendar",

            alert_sentLogin: "New login data has been sent to {0}",

            /* NOTIFICATIONS */
            noti_holiday_title: "Leave",
            noti_holidayRequest_approved: "Your leave request from {0} has been approved",
            noti_holidayRequest_denied: "Your leave request from {0} has been denied",
            noti_holidayRequest_deleted: "Your leave request from {0} has been deleted",

            noti_trade_title: "Trade",
            noti_tradeRequest_staff_denied: "{0} has denied the trade request between you and {1}",
            noti_tradeRequest_staff_approved: "{0} has approved the trade request between you and {1}. Your new shift is on {2}",
            noti_tradeRequest_staff_deleted: "{0} has deleted the trade request between you and {1}",

            noti_availableShift_title: "Available shift",
            noti_availableShift_staff: "{0} would like to take over a shift on {1}",

            noti_takeover_title: "Shift takeover",
            noti_takeover_staff: "You have taken over the shift {0}",

            noti_substitution_title: "Substitution",
            noti_substitution_staff: "Your shift from {0} has been taken over",

            /* ERRORS */
            error_trial: "The trial period has expired. Set up your payment details to use Tiemdo again",
            error_subscription: "You do not have a valid subscription. Set up your payment details to use Tiemdo",

            error_forgot_username_title: "Forgot username",
            error_forgot_username: "Contact your manager to request your username",

            error_notFilled_companyCode: "You have not entered a company code",
            error_notFilled_username: "You have not entered a username",
            error_notFilled_password: "You have not entered a password",
            error_notFilled_firstName: "You have not entered a first name",
            error_notFilled_lastName: "You have not entered a lasst name",
            error_notFilled_birth: "You have not selected a birth date",
            error_notFilled_date: "You have not selected a date",
            error_notFilled_startDate: "You have not selected a start date",
            error_notFilled_endDate: "You have not selected an end date",
            error_notFilled_employee: "You have not selected an employee",
            error_notFilled_job: "You have not selected a job",
            error_notFilled_jobName: "You have not entered the name of the job",
            error_notFilled_sectionName: "You have not entered the name of the section",
            error_notFilled_birthPlace: "You have not entered a birth place",
            error_notFilled_email: "You have not entered an email address",
            error_notFilled_phone: "You have not entered a phone number",
            error_notFilled_address: "You have not entered an address",
            error_notFilled_addressHouse: "You have not entered a house number",
            error_notFilled_place: "You have not entered a city",
            error_notFilled_zip: "You have not entered a postal code",
            error_notFilled_clockCode: "You have not entered a clock-in code",
            error_notFilled_newsTitleMessage: "You have to enter both a title and a message",
            error_notFilled_privacy: "You must agree to the privacy statement",
            error_notFilled_option: "You have not selected an option",

            error_invalid_username: "Username may only contain letters and numbers",
            error_invalid_firstName: "First name may only contain letters, numbers and -",
            error_invalid_lastName: "Last name may only contain letters, numbers and -",
            error_invalid_insertion: "Middle name may only contain letters, numbers and -",
            error_invalid_birth: "You have not entered a valid birth date",
            error_invalid_address: "Address may only contain letters, numbers and -",
            error_invalid_addressHouse: "House number may only contain letters, numbers and -()",
            error_invalid_zip: "Postal code may only contain letters, numbers and -",
            error_invalid_place: "City may only contain letters, numbers and -",
            error_invalid_clockCode: "Clock-in code may only contain numbers",
            error_invalid_clockCode2: "Clock-in code must contain 4 numbers",
            error_invalid_email: "You have not entered a valid email address",
            error_invalid_phone: "You have not entered a valid phone number",
            error_invalid_phone2: "You have not entered a valid second phone number",
            error_invalid_startTime: "You have not entered a valid start time",
            error_invalid_endTime: "You have not entered a valid end time",
            error_invalid_timeIndication: "You have not entered a valid time indication",
            error_invalid_startHigherEnd: "Start time cannot be later than end time",
            error_invalid_endLowerStart: "End time cannot be sooner than start time",
            error_invalid_interval: "You have not entered a valid interval",
            error_invalid_startHigherEnd_date: "Start date cannot be later than end date",
            error_invalid_endLowerStart_date: "End date cannot be sooner than start date",

            error_tooLate_leave: "You are too late. You can request leave {0} days before the start date of your leave/vacation",
            error_consecutive_leave: "You cannot request a vacation that lasts longer than {0} consecutive days",
            error_years_leave: "You must request 2 vacations for a request with different years",

            error_companyCode: "Invalid company code",
            error_desktop: "The web version is only meant for administrators",
            error_clockSettings: "You need to setup your time tracking settings first",
            error_login: "Invalid username and/or password",
            error_login_password: "Invalid password",
            error_companyCode_blocked: "Company code can't be used at this time. Contact us via www.tiemdo.com",
            error_login_webFirst: "Please, complete the settings via desktop at https://app.tiemdo.com first",
            error_login_settings: "Your manager has not yet completed the settings. Try again later",

            error_file_empty: "You have not selected a file",
            error_file_max: "This file exceeds the 25MB limit",

            error_notAdded_functions: "You have to add at least 2 jobs",
            error_notAdded_employees: "You have to register at least 2 employees",
            error_notAdded_leaveBalances: "You have not added a leave balance yet",
            error_notAdded_leaveTypes: "You have not added a leave type yet",
            error_notAdded_password: "You need to set a new password",
            error_notAdded_passwordConfirm: "You still need to confirm the password",

            error_notSame_password: "Passwords do not match",
            error_notSame_email: "Emails do not match",

            error_duplicate_email: "This email address already exists",
            error_duplicate_username: "This username already exists",
            error_duplicate_clockCode: "This clock-in code already exists",
            error_duplicate_startEndTime: "Start time cannot be the same as end time",
            error_duplicate_endStartTime: "End time cannot be the same as start time",
            error_duplicate_startEndDate: "Start date cannot be the same as end date",
            error_duplicate_endStartDate: "End date cannot be the same as start date",

            error_general_text: "Something went wrong. Try again",
            error_general_register: "There was a problem while registering. Try again",
            error_general_save: "There was a problem while saving. Try again",
            error_general_jobDelete: "There was a problem while deleting. This job is linked to an employee or is used on the schedule",

            error_availableShift_ownShift: "This is your own shift",
            error_availableShift_onSchedule: "You are already on the schedule at that moment",
            error_availableShift_job: "You cannot take over this shift because of your job",
            error_availableShift_skills: "You cannot take over this shift due to the skills required",

            error_qr_fail: "This QR code is not recognized by Tiemdo",

            /* INPUTS */
            dropdown_title_team: "Teams",

            select_contract: "Select a contract type",
            select_contract_all: "All contract types",
            select_contract_flex: "Flex contract (on-call, zero-hour, flex)",
            select_contract_minMax: "Min-max contract",
            select_contract_fixed: "Fixed hours contract",
            select_contract_flexMinMax: "Flex contract & min-max contract",

            select_active_status: "Choose to show/hide inactive employees",

            select_team: "Show teams",
            select_team2: "Select a team",
            select_team_all: "All teams",

            select_employee: "Select an employee",
            select_employee_all: "All employees",

            select_rights: "Select a right",
            select_rights_employee: "Employee",
            select_rights_planner: "Planner",
            select_rights_admin: "Admin",

            select_wageModel: "Select a wage model",

            select_job: "Select a job",
            select_job_all: "All jobs",

            select_date: "Select a date",
            select_birthDate: "Select your birth date",
            select_birthYear: "Select your birth year",

            select_year: "Select a year",

            select_color: "Select a color",

            select_language: "Select language",

            select_absence: "Select an absence type",

            select_option: "Select an option",
            select_type: "Select a type",

            select_status: "Select a status",
            select_open: "Open",
            select_approved: "Approved",
            select_denied: "Denied",
            select_approvedDenied: "Approved & denied",

            select_sort: "Sort by",
            select_sort_date_asc: "Date ascending",
            select_sort_date_desc: "Date descending",
            select_sort_name_asc: "Name ascending",
            select_sort_name_desc: "Name descending",
            select_sort_hours_asc: "Hours ascending",
            select_sort_hours_desc: "Hours descending",
            select_sort_gains_asc: "Turnover ascending",
            select_sort_gains_desc: "Turnover descending",
            select_sort_wage_asc: "Wage ascending",
            select_sort_wage_desc: "Wage descending",
            select_sort_availability_asc: "Availability ascending",
            select_sort_availability_desc: "Availability descending",
            select_sort_unavailability_asc: "Unavailability ascending",
            select_sort_unavailability_desc: "Unavailability descending",
            select_sort_dateShift1_asc: "Date shift 1 ascending",
            select_sort_dateShift1_desc: "Date shift 1 descending",
            select_sort_dateShift2_asc: "Date shift 2 ascending",
            select_sort_dateShift2_desc: "Date shift 2 descending",

            select_surcharge_inclusive: "Surcharges inclusive",
            select_surcharge_exclusive: "Surcharges exclusive",

            select_kind: "Select a kind",
            select_kind_paid: "Paid",
            select_kind_correction: "Correction",

            /* BUTTONS */
            button_continue: "Continue",
            button_refresh: "Refresh",
            button_ok: "OK",
            button_cancel: "Cancel",
            button_logout: "Log out",
            button_setUp: "Set up",
            button_save: "Save",
            button_delete: "Delete",
            button_upload: "Upload",
            button_next: "Next",
            button_back: "Back",
            button_finish: "Finish",
            button_add: "Add",
            button_edit: "Edit",
            button_inactive: "Set inactive",
            button_active: "Set active",
            button_notification: "Send notification",
            button_undo: "Undo changes",
            button_approve: "Approve",
            button_deny: "Deny",
            button_show: "Show",
            button_reapprove: "Re-approve",
            button_start: "Start",
            button_export: "Export now",
            button_login: "Login",
            button_switchOn: "Switch on",
            button_switchOff: "Switch off",
            button_openSettings: "Open settings",
            button_takeOver: "Take over",
            button_open: "Open",
            button_create: "Create",
            button_copy: "Copy",
            button_paste: "Paste",
            button_sendMessage: "Send message",
            button_enableNoti: "Enable notifications",
            button_disableNoti: "Disable notifications",
            button_request: "Request",
            button_accept: "Accept",
            button_sendCode: "Send registration code",
            button_apply: "Apply",
            button_plan: "Schedule",
            button_resend_data: "Send new login data",

            /* MISC */
            misc_seconds: "seconds",
            misc_minutes: "minutes",
            misc_hours: "hours",
            misc_days: "days",
            misc_weeks: "weeks",
            misc_months: "months",
            misc_years: "years",

            misc_seconds_c: "Seconds",
            misc_minutes_c: "Minutes",
            misc_hours_c: "Hours",
            misc_days_c: "Days",
            misc_weeks_c: "Weeks",
            misc_months_c: "Months",
            misc_years_c: "Years",

            misc_second: "second",
            misc_minute: "minute",
            misc_hour: "hour",
            misc_day: "day",
            misc_week: "week",
            misc_month: "month",
            misc_year: "year",

            misc_second_c: "Second",
            misc_minute_c: "Minute",
            misc_hour_c: "Hour",
            misc_day_c: "Day",
            misc_week_c: "Week",
            misc_month_c: "Month",
            misc_year_c: "Year",

            misc_monday: "Monday",
            misc_tuesday: "Tuesday",
            misc_wednesday: "Wednesday",
            misc_thursday: "Thursday",
            misc_friday: "Friday",
            misc_saturday: "Saturday",
            misc_sunday: "Sunday",

            misc_monday_short: "Mon",
            misc_tuesday_short: "Tue",
            misc_wednesday_short: "Wed",
            misc_thursday_short: "Thu",
            misc_friday_short: "Fri",
            misc_saturday_short: "Sat",
            misc_sunday_short: "Sun",

            misc_january: "January",
            misc_february: "February",
            misc_march: "March",
            misc_april: "April",
            misc_may: "May",
            misc_june: "June",
            misc_july: "July",
            misc_august: "August",
            misc_september: "September",
            misc_october: "October",
            misc_november: "November",
            misc_december: "December",

            misc_january_short: "Jan",
            misc_february_short: "Feb",
            misc_march_short: "Mar",
            misc_april_short: "Apr",
            misc_may_short: "May",
            misc_june_short: "Jun",
            misc_july_short: "Jul",
            misc_august_short: "Aug",
            misc_september_short: "Sept",
            misc_october_short: "Oct",
            misc_november_short: "Nov",
            misc_december_short: "Dec",

            misc_goodDay: "Good day",
            misc_goodMorning: "Good morning",
            misc_goodAfternoon: "Good afternoon",
            misc_goodEvening: "Good evening",

            misc_by: "by",
            misc_by2: "By",
            misc_at: "at",
            misc_from: "From",
            misc_to: "To",
            misc_available: "Available",
            misc_unavailable: "Unavailable",
            misc_date: "Date",
            misc_time: "Time",
            misc_startEndTime: "Start time & end time",
            misc_startTime: "Start time",
            misc_endTime: "End time",
            misc_break: "Break",
            misc_breaks: "Breaks",
            misc_wholeDay: "All day",
            misc_wholeWeek: "Whole week",
            misc_showAll: "Show all",
            misc_start: "Start",
            misc_end: "End",
            misc_ntb: "TBD",  
            misc_tm: "until",  
            misc_nvt: "N/A",
            misc_menu: "Menu", 
            misc_employee: "Employee",
            misc_employees: "Employees",
            misc_account: "Account",
            misc_accounts: "Accounts",
            misc_teams: "Teams",
            misc_team: "Team",
            misc_availability: "Availability",
            misc_unavailability: "Unavailability",
            misc_data: "Data",
            misc_contract: "Contract",
            misc_contractData: "Contract data",
            misc_generate: "Generate",
            misc_job: "Job",
            misc_jobs: "Jobs",
            misc_section: "Section",
            misc_sections: "Sections",
            misc_balance: "Balance",
            misc_balances: "Balances",
            misc_leaveBalance: "Leave balance",
            misc_leaveBalances: "Leave balances",
            misc_plusMinus: "Plus minus",
            misc_workingDays: "Working days",
            misc_workingFiles: "File",
            misc_file: "File",
            misc_docs: "Documents",
            misc_fileName: "File name",
            misc_notes: "Notes & appointments",
            misc_notes2: "Notes",
            misc_appointments: "Appointments",
            misc_overviews: "Overviews",
            misc_settings: "Settings",
            misc_skills: "Skills",
            misc_defaultWorkingTimes: "Fixed shifts",
            misc_general: "General",
            misc_generalOverview: "General overview",
            misc_trade: "Trade",
            misc_trade2: "Trade",
            misc_absenceLeave: "Absence & leave",
            misc_absence: "Absence",
            misc_absence2: "Absence",
            misc_absenceWaitDay: "Wait day",
            misc_leave: "Leave",
            misc_finances: "Finances",
            misc_timeRegistration: "Time registration",
            misc_contracts: "Contracts",
            misc_flexHours: "Flex hours",
            misc_minMaxHours: "Min-max hours",
            misc_name: "Name",
            misc_salary: "Wage",
            misc_salaryModel: "Wage model",
            misc_brutoHour: "Hourly wage",
            misc_brutoMonth: "Monthly wage",
            misc_none: "None",
            misc_actions: "Actions",
            misc_used: "Used",
            misc_current: "Current",
            misc_currentBalance: "Current balance",
            misc_correction: "Correction",
            misc_corrections: "Corrections",
            misc_comment: "Comment",
            misc_payout: "Payout",
            misc_period: "Period",
            misc_openRequests: "Open requests",
            misc_approvedRequests: "Approved requests",
            misc_deniedRequests: "Denied requests",
            misc_gradedRequests: "Graded requests",
            misc_status: "Status",
            misc_accrual: "Accrual",
            misc_worked: "Worked",
            misc_total: "Total",
            misc_totalHours: "Total hours",
            misc_totalGains: "Total turnover",
            misc_totalSalary: "Total wage",
            misc_totalEnd: "Total final",
            misc_productivity: "Productivity",
            misc_title: "Title",
            misc_description: "Description",
            misc_startDate: "Start date",
            misc_endDate: "End date",
            misc_holiday: "Holiday",
            misc_holidays: "Holidays",
            misc_leaveDays: "Leave",
            misc_change: "Change",
            misc_changes: "Changes",
            misc_tradeRequest: "Trade request",
            misc_tradeRequests: "Trade requests",
            misc_leaveRequests: "Leave requests",
            misc_takeoverRequests: "Takeover requests",
            misc_availabilityRequests: "Availability",
            misc_all: "All",
            misc_minHours: "Minimum hours",
            misc_aboveMinHours: "Above minimum hours",
            misc_average: "Average",
            misc_averageWeek: "Average a week",
            misc_averageHoursWeek: "Average hours a week",
            misc_minContractHours: "Min. contract hours",
            misc_contractHours: "Contract hours",
            misc_leaveHoursToTake: "Leave to be taken",
            misc_approve: "Approve",
            misc_approved: "Approved",
            misc_notApproved: "Not approved",
            misc_deny: "Deny",
            misc_shift: "Shift",
            misc_response: "Response",
            misc_responses: "Responses",
            misc_responded: "Responded",
            misc_clockIn: "Clock in",
            misc_clockOut: "Clock out",
            misc_clocked: "Clocked in",
            misc_notClocked: "Not clocked in",
            misc_startedEarlier: "Started earlier",
            misc_startedTooLate: "Started too late",
            misc_tooLate: "Too late",
            misc_forgotClockIn: "Forgot to clock in",
            misc_notOnPlanning: "Is not on the schedule",
            misc_reason: "Reason",
            misc_plannedStartTime: "Scheduled start time",
            misc_plannedEndTime: "Scheduled end time",
            misc_plannedBreak: "Scheduled break",
            misc_actualStartTime: "Actual start time",
            misc_actualEndTime: "Actual end time",
            misc_actualBreak: "Actual break",
            misc_plannedWorkingDays: "Scheduled working days",
            misc_plannedHours: "Scheduled hours",
            misc_actualWorkingDays: "Actual working days",
            misc_actualHours: "Actual hours",
            misc_plannedDate: "Scheduled date",
            misc_display: "Display",
            misc_detailed: "Detailed",
            misc_eachWorkingDay: "Each working day",
            misc_surcharge: "Surcharge",
            misc_surcharges: "Surcharges",
            misc_scheduleDate: "Schedule date",
            misc_asc: "Ascending",
            misc_desc: "Descending",
            misc_absenceType: "Absence type",
            misc_achievedGains: "Achieved turnover",
            misc_actualGains: "Actual turnover",
            misc_expectedGains: "Expected turnover",
            misc_tradeReport: "Trade report",
            misc_availabilityReport: "Availability rapport",
            misc_financesReport: "Finances report",
            misc_absenceReport: "Absence report",
            misc_leaveReport: "Leave report",
            misc_timeReport: "Time registration report",
            misc_plusMinusReport: "Plus minus report",
            misc_plusHoursPaid: "Plus hours paid",
            misc_flexReport: "Flex hours report",
            misc_minMaxReport: "Min-max hours report",
            misc_notFilled: "Not filled",
            misc_periodic: "Periodic",
            misc_monthly: "Monthly",
            misc_register: "Register",
            misc_registration: "Registration",
            misc_registrationCode: "Registration code",
            misc_registrationCodes: "Registration codes",
            misc_privacyPolicy: "Privacy policy",
            misc_required: "Required",
            misc_read: "Read", // als in "Gelezen" (voltooid deelwoord-vorm)
            misc_read2: "Read", // als in "Gelezen" (voltooid deelwoord-vorm)
            misc_allRead: "Read all",
            misc_pending: "Pending",
            misc_takeOver: "Take over",
            misc_loading: "Loading",
            misc_clockInCode: "Clock-in code",
            misc_notification: "Notification",
            misc_notifications: "Notifications",
            misc_shiftToday: "Shift today",
            misc_ownTradeRequests: "Own trade requests",
            misc_fillAvailability: "Fill in availability",
            misc_requestLeave: "Request leave",
            misc_requestVacation: "Request vacation",
            misc_absenceEmployee: "Absence employee",
            misc_broadcastMessage: "Broadcast message",
            misc_noMessages: "No messages",
            misc_message: "Message",
            misc_messages: "Messages",
            misc_availableShifts: "Available shifts",
            misc_noAvailableShifts: "No available shifts",
            misc_information: "Information",
            misc_noInformation: "No information published",
            misc_contact: "Contact",
            misc_news: "News",
            misc_broadcast: "Broadcast",
            misc_deadline: "Deadline",
            misc_deadlines: "Deadlines",
            misc_blockedDate: "Blocked date",
            misc_blockedDates: "Blocked dates",
            misc_repeat: "Repeat",
            misc_repeating: "Repeating",
            misc_select: "Select",
            misc_selected: "Selected",
            misc_interval: "Interval",
            misc_type: "Type",
            misc_school: "School",
            misc_schoolHoliday: "School holiday",
            misc_schoolHolidays: "School holidays",
            misc_schoolSchedule: "School schedule",
            misc_contractExpired: "Contract expired",
            misc_chat: "Chat",
            misc_chats: "Chats",
            misc_role: "Role",
            misc_roles: "Roles",
            misc_call: "Call",
            misc_groupName: "Group name",
            misc_color: "Color",
            misc_colors: "Colors",
            misc_deselect: "Deselect",
            misc_planner: "Planner",
            misc_planner2: "Planner", // als in Rooster
            misc_planners: "Planners",
            misc_admin: "Admin",
            misc_admins: "Admins",
            misc_clockSystem: "Clock-in system",
            misc_defaultShift: "Default shift",
            misc_defaultShifts: "Default shifts",
            misc_schedule: "Schedule",
            misc_schedules: "Schedules",
            misc_sort: "Sort",
            misc_publish: "Publish",
            misc_option: "Option",
            misc_options: "Options",
            misc_noEmployees: "No employees",
            misc_noAppointments: "No appointments",
            misc_noAvailableShifts: "No available shifts",
            misc_noRequests: "No requests",
            misc_noBlockedDays: "No blocked days",
            misc_noDeadlines: "No deadlines",
            misc_noDocs: "No documents",
            misc_noJobs: "No jobs",
            misc_noRequestedLeave: "No requested vacations",
            misc_noApprovedLeave: "No approved vacations",
            misc_noDeniedLeave: "No denied vacations",
            misc_noRequestedTrade: "No trade requests",
            misc_noApprovedTrade: "No approved trade requests",
            misc_noDeniedTrade: "No denied trade requests",
            misc_noSentTrade: "No sent trade requests",
            misc_noReceivedTrade: "No received trade requests",
            misc_noSections: "No sections",
            misc_noFixedTimes: "No fixed times",
            misc_noSchool: "No school",
            misc_defaultTimes: "Fixed times",
            misc_particularities: "Particularities",
            misc_particularity: "Particularity",
            misc_default: "Default",
            misc_welcome: "Welcome",
            misc_employeesAccepted: "Employees accepted",
            misc_byEmployees: "By employees",
            misc_deadlineDate: "Deadline date",
            misc_periodFrom: "Period from",
            misc_periodTo: "Period to",
            misc_until: "To",
            misc_signDoc: "Sign document",
            misc_viewDoc: "View document",
            misc_myDocs: "My documents",
            misc_jobName: "Job name",
            misc_logs: "Logs",
            misc_details: "Details",
            misc_pendingApproval: "Pending approval",
            misc_pendingResponse: "Awaiting a response",
            misc_pendingResponse2: "Awaiting your response",
            misc_denied: "Denied",
            misc_deniedByYou: "Denied by you",
            misc_deniedByOther: "Denied by other",
            misc_deniedByManager: "Denied by manager",
            misc_request: "Request",
            misc_requests: "Requests",
            misc_requested: "Requested",      
            misc_attention: "Attention", 
            misc_theme: "Theme",     
            misc_themes: "Themes",     
            misc_sent: "Sent",     
            misc_received: "Received",     
            misc_management: "Management",  
            misc_capital: "Capital",   
            misc_lowercase: "Lowercase",   
            misc_rights: "Rights",   
            misc_setUpPayment: "Set up billing",   
            misc_birthday: "Birthday",   
            misc_label: "Label",   
            misc_labels: "Labels",   
            misc_manageLabels: "Manage labels",   
            misc_putAvailable: "Make shift available",   
            misc_putUnavailable: "Undo available",   
            misc_duplicateShift: "Duplicate shift",   
            misc_availableShift: "Shift is available",   
            misc_availableShiftError: "This shift is being traded or has already been made available",
            misc_allSections: "All sections",  
            misc_display_normal: "Normal display",  
            misc_display_excel: "Table display",  
            misc_display_list: "List display",  
            misc_copyMove: "Copy shifts or move shifts",
            misc_showTeams: "Show shifts from other teams",
            misc_loadingSmartPlan: "Working on Smart Plan...",
            misc_loadingSmartPlan_account: "Searching for employees...",
            misc_plannedTimes: "Planned {0}x",
            misc_dragToSameDay_alert: "You cannot drag an employee to another day",
            misc_employeeSettings: "Employee settings",
            misc_selectShifts: "Select tiles or show pop-up",
            misc_paymentLink: "Click here to add payment details",
            misc_timeForTime: "Time-for-time",

            misc_data_userData: "User data",
            misc_data_accountData: "Account data",
            misc_data_addressData: "Address data",
            misc_data_contactData: "Contact data",
            misc_data_title: "Data",
            misc_data_address_title: "Address data",
            misc_data_username: "Username",
            misc_data_password: "Password",
            misc_data_passwordConfirm: "Confirm password",
            misc_data_firstName: "First name",
            misc_data_lastName: "Last name",
            misc_data_insertion: "Middle name",
            misc_data_birth: "Birth date",
            misc_data_birthPlace: "Birth place",
            misc_data_email: "Email",
            misc_data_emailConfirm: "Confirm email",
            misc_data_phone: "Phone number",
            misc_data_phone2: "Second phone number",
            misc_data_startDate: "Start date",
            misc_data_address: "Address",
            misc_data_addressHouse: "House number",
            misc_data_place: "City",
            misc_data_zip: "Postal code",
            misc_data_bank: "Bank number/IBAN",
            misc_data_citizen: "National identification number",
            misc_data_extra: "Additional information",
            misc_data_accountRights: "Account rights",

            misc_data_privacy_agree: "I agree to the {0}",
            misc_data_privacy_agree2: "Employee agrees to the {0}",

            /* STARTUP MODAL */
            startup_addEmployee_link: "Add employee",
            startup_profile_restaurant: "Restaurant",
            startup_profile_bar: "Bar",
            startup_profile_hotel: "Hotel",
            startup_profile_delivery: "Take away",

            startup_title_profiles: "Profiles",
            startup_title_jobs: "Add jobs",
            startup_title_jobsAuto: "Automatically added jobs",

            startup_job_washingUp: "Washing Up",
            startup_job_service: "Service",
            startup_job_bar: "Bar",
            startup_job_cook: "Cook",
            startup_job_drinks: "Drinks",
            startup_job_food: "Food",
            startup_job_cleaning: "Cleaning",
            startup_job_reception: "Reception",
            startup_job_kitchen: "Kitchen",
            startup_job_cash: "Cash Register",
            startup_job_car: "Car",
            startup_job_scooter: "Motor",
            startup_job_bicycle: "Bicycle",
            startup_job_maintenance: "Maintenance",
            startup_job_housekeeping: "Housekeeping",

            startup_tut_company: "Enter your company details below as completely as possible.",
            startup_tut_data1: "Enter your personal details below. This is necessary to create an administrator account for you.",
            startup_tut_data2: "Enter a new password below to secure your administrator account.",
            startup_tut_jobs: "In order to be able to create a schedule and to be able to link jobs to shifts, a number of jobs must be added first. Select a profile below to give you suggestions for certain jobs and/or add jobs yourself.",
            startup_tut_jobs2: ": you must add at least 2 jobs to continue.",
            startup_tut_employees: "In order to create a schedule and to link employees to shifts, a number of employees must be registered first. Click on 'Add employee' below to quickly register a new employee. You do not have to fill in an e-mail or send login details immediately, this can also be done later in the settings of the employee.",
            startup_tut_employees2: ": you must register at least 2 employees to continue.",
            startup_tut_caution: "Caution",

            /* NAVBAR */
            navbar_trial_expired: "Trial has expired",
            navbar_trial: "Trial expires in: {0}",

            navbar_switchAccount: "Switch accounts",
            navbar_switchClock: "Go to time tracking screen",

            navbar_menu: "Menu",
            navbar_home: "Home",
            navbar_employees: "Employees",
            navbar_requests: "Requests",
            navbar_timeRegistration: "Time registration",
            navbar_overviews: "Reports",
            navbar_schedule: "Schedule",

            navbar_myCompany: "My company",
            navbar_settings: "Settings",
            navbar_logOut: "Log out",
            navbar_help: "Help",

            navbar_menu_importEmployees: "Import employees",
            navbar_menu_registerEmployees: "Register employees",
            navbar_menu_sendCode: "Send registration code",
            navbar_menu_allCodes: "All registration codes",
            navbar_menu_myAccount: "My account",
            navbar_menu_registration: "Registration",
            navbar_menu_log: "Log",
            navbar_menu_support: "Support",

            navbar_footer_version: "Version",
            navbar_footer_terms: "Terms and Conditions",
            navbar_footer_privacy: "Privacy Policy",
            
            /* MODALS */
            modal_teams_title: "Select a team",

            /* LOGIN */
            login_title: "Login to your account",
            login_button: "Login Tiemdo",
            login_button_clock: "Login time tracking",
            login_remember: "Remember me",
            login_forgot_companycode: "I forgot my company code",
            login_forgot_username: "I forgot my username",
            login_forgot_password: "I forgot my password",
            login_last: "Last used",
            login_no_account: "Don't have an account yet?",
            login_signup: "I want to signup for a trial",
            login_companycode: "Company code",
            login_username: "Username or e-mail",
            login_password: "Password",

            login_register_code_error: "You have not entered the full registration code",
            login_register_code_invalid: "Registration code is incorrect",
            login_register_registrationCode_text: "Enter the registration code below that you have received from your manager.",

            /* HOME */
            home_overview_tradeRequest: "Trade request",
            home_overview_openShift: "Open shift",
            home_overview_shiftTransfer_title: "Shift transfer",
            home_overview_shiftTransfer: "An employee wants to take over the job of {0} ({1} {2} - {3}, {4})",
            home_overview_leaveRequest_title: "Leave request",
            home_overview_leaveRequest_oneDay: "{0} on {1}",
            home_overview_leaveRequest: "{0} from {1} to {2}",
            home_overview_contract_title: "Contract",
            home_overview_contract: "Contract {0} expires on {1}",
            home_overview_birthday_title: "Birthday",
            home_overview_birthday: "{0} {1}",
            home_overview_deadline_title: "Deadline",
            home_overview_deadline: "{0} period from {1} to {2}",

            home_ownShifts: "My shifts",
            home_events: "Events",
            home_requestsContracts: "Requests & contracts",

            home_newsOverview: "News",
            home_latestNews: "Latest news",
            home_latestNews_send: "Broadcast news",

            home_actions: "Action center",
            home_actions_text: "Pending actions",
            home_actions_availability: "Availability changes",
            home_actions_trades: "Open trade requests",
            home_actions_holidays: "Open leave requests",
            home_actions_shifts: "Open shift takeovers",

            home_shortcuts: "Shortcuts",
            home_shortcuts_settings: "Settings",
            home_shortcuts_time: "Check time registration",
            home_shortcuts_gains: "Fill in turnover",
            home_shortcuts_deadlines: "Manage deadlines",
            home_shortcuts_leave: "Leave overview employees",

            home_editor_save: "Are you sure you want to save this information?",
            home_file_delete: "Are you sure you want to delete this file? This action cannot be undone",
            home_logo_delete: "Are you sure you want to delete your company logo?",

            home_menu_frontPage: "Front page",
            home_menu_companyInfo: "Company information",
            home_menu_companyFiles: "Company documents",
            home_menu_companyLogo: "Logo",

            home_companyInfo_title: "Company information",
            home_companyInfo_edit: "Edit information",

            home_documents_title: "Company documents",

            home_app_trialExpired: "Trial has been expired",
            home_app_trialExpires: "Trial expires in",
            home_app_noOpenActions: "You have no pending actions",
            home_app_noNewMessages: "You have no new messages",
            home_app_noAvailableShifts: "There are no available shifts",
            home_app_youHave: "You have",
            home_app_thereAre: "There are",
            home_app_thereIs: "There is",
            home_app_pendingAction: "pending action",
            home_app_pendingActions: "pending actions",
            home_app_newMessage: "new message",
            home_app_newMessages: "new messages",
            home_app_availableShift: "available shift",
            home_app_availableShifts: "available shifts",
            home_app_workingToday: "You are working today from",
            home_app_noWorkToday: "You do not have to work today",
            home_app_clockIn_create: "Create clock-in code",
            home_app_note_today: "Note today",
            home_app_shifts_noShifts: "No shifts",
            home_app_manageDeadlines: "Manage deadlines",
            home_app_noCompanyFiles: "No documents",
            home_app_editClockCode: "Edit clock-in code",

            home_app_alert_substitute: "Request for substitution has been sent to {0} employees",
            home_app_alert_substituteLate: "You are too late. You can search for a substitute {0} days in advance",
            home_app_alert_tradeLate: "You are too late. You can trade a shift {0} days in advance",
            home_app_alert_searchSubstitute: "Look for substitution",
            home_app_alert_searchSubstituteText: "The app searches for available and relevant employees for your shift. These employees will receive a notification. Do you want to continue?",
            home_app_alert_revokeSearchSubstitute: "Withdraw request substitution",
            home_app_alert_revokeSearchSubstituteText: "Are you sure you want to withdraw your request for a substitution?",
            
            home_app_alert_trade: "Would you like to trade with {0}?\n\nYour shift: {1}\n\nShift {2}: {3}.\n\nAfter this you will have to wait for an answer from {4} and after that you need an approval from one of your managers",
            
            home_app_noti_trade: "{0} would like to trade your shift {1} with his/her shift {2}",

            home_app_error_workToday: "You already have to work this day",

            home_app_button_revoke: "Withdraw",

            /* TUT MODAL */
            tutmodal_title: "First settings company",
            tutmodal_subTitle_myCompany: "My company",
            tutmodal_subTitle_myAccount: "My account",
            tutmodal_menu_jobs: "Jobs",
            tutmodal_menu_sections: "Sections",
            tutmodal_menu_breaks: "Breaks",
            tutmodal_menu_password: "Password",
            tutmodal_menu_data: "Data",
            tutmodal_page_password_title: "Edit password",
            tutmodal_page_data_title: "Edit user data",
            tutmodal_page_config_title: "Company succesfully set up",
            tutmodal_page_config_finished: "Finish",
            tutmodal_page_config_text: "The company is set up to your needs! Click {0} to close this window.",

            /* EMPLOYEES */
            employees_tooltip_contract: "Contract is about to expire",
            employees_tooltip_team: "Employee has not been assigned to a team yet",
            employees_tooltip_job: "Employee has not been assigned to a job yet",
            employees_tooltip_contractSign: "Sign contract",
            employees_tooltip_contractSignNotify: "Send notification to sign contract",
            employees_tooltip_changeContract: "Edit contract",
            employees_tooltip_extendContract: "Extend contract",

            employees_availability_invalid_startEndTime: "You have not entered a correct start time or end time on {0}",
            employees_availability_invalid_startEndTime2: "Start time cannot be later than end time on {0}",

            employees_misc_noTeam: "No team",

            employees_alert_changed_title: "Changed",
            employees_alert_changed: "{0} has been changed",
            employees_alert_failed_admin: "There is currently only 1 administrator. First make another employee an administrator and then delete this employee",
            employees_alert_failed_delete: "At least 1 employee must be registered to delete accounts",
            employees_alert_failed_inactive: "You cannot disable an administrator",
            employees_alert_delete_employee: "Are you sure you want to delete {0}? This action cannot be undone",
            employees_alert_inactive_employee: "Are you sure you want to set {0} to inactive?",
            employees_alert_active_employee: "Are you sure you want to set {0} to active?",
            employees_alert_documents_notify: "Would you like to send a notification to {0} to view this document or have it digitally signed?",
            employees_alert_notification: "Notification sent",
            employees_alert_generalDays: "You have not set any general working days yet. Do you want to set these working days now?",
            employees_alert_error_extendContract: "This contract cannot be extended as this is an open-ended contract",

            employees_alert_notifyUsers_title: "Send notification",

            employees_userSettings_employeeNumber: "Employee number",
            employees_userSettings_employeeNumber_sub: "Identify employee",
            employees_userSettings_register: "Can register new employees",
            employees_userSettings_register_sub: "(If this account does not have administrator rights)",
            employees_userSettings_maxDays: "Maximum working days per week",
            employees_userSettings_maxDays_sub: "(0 = none)",
            employees_userSettings_endTime: "Maximum end time",
            employees_userSettings_endTime_sub: "(empty = none)",
            employees_userSettings_maxCumulative: "Maximum number of working days in a row",
            employees_userSettings_maxCumulative_sub: "(0 = none)",

            employees_title: "Employees",
            employees_allEmployees: "All employees",
            employees_allEmployeesTeam: "All employees team",
            employees_active: "Active employees",
            employees_inactive: "Inactive employees",
            employees_export: "Export now",
            employees_button_register: "Register employee",
            employees_button_import: "Import employees",
            employees_button_changePass: "Change password",
            employees_button_manageWageModel: "Manage wage models",
            employees_button_manageSkills: "Manage skills",
            employees_button_manageWorkingDays: "Manage working days",
            employees_button_manageLeaveBalances: "Manage leave balances",
            employees_primeJob: "Primary job",
            employees_secondJob: "Secondary jobs",
            employees_button_manageJob: "Manage jobs",
            employees_put_inactive_title: "Put employee on inactive",
            employees_put_inactive: "Employee is no longer displayed in the planner and the employee can no longer enter leave or availability",
            employees_put_active_title: "Put employee on active",
            employees_put_active: "Employee is displayed again in the planner and the employee can enter leave or availability again",
            employees_delete_employee_title: "Delete employee",
            employees_delete_employee: "Employee is completely deleted from the system. This action cannot be undone",
            employees_docs_signedBy: "Signed by",
            employees_fixedShifts_sub: "Enter the fixed working hours below from Monday to Sunday. These working days can be scheduled in the planner with one click.",
            employees_generateContract: "Create a contract",
            employees_upload: "Upload",
            employees_selectTemplate: "Select a template",
            employees_contract_sign: "Sign",
            employees_contract_check: "View",
            employees_selectTemplate_delete: "Delete contract template",
            employees_foundTags: "Found tags",
            employees_contractMenu_tags: "Tags",
            employees_contractMenu_signing: "Signing",
            employees_contractMenu_employee: "Employee",
            employees_contractMenu_manager: "Manager",
            employees_contractMenu_company: "Company",
            employees_contractMenu_contract: "Contract",
            employees_contract_explainTitle: "Explaination",
            employees_contract_explain1: "1. Use a Word document (.docx) and fill in the contract with the tags shown on the right.",
            employees_contract_explain2: "2. Then upload your template below so that the data of the employees is automatically filled in on the placed tags.",
            employees_contract_explain3: "Is your tag not listed? Then create your own tag such as {my_tag} or {gender}. You can fill this in yourself below after selecting your template.",
            employees_contract_explain4: "Example file",

            employees_contractTag_signEmployee: "Signature of employee",
            employees_contractTag_signEmployer: "Signature of manager",
            employees_contractTag_signDate: "Date placed signature by employee",
            employees_contractTag_fullName: "Employee's full name",
            employees_contractTag_firstName: "Employee's first name",
            employees_contractTag_lastName: "Employee's last name",
            employees_contractTag_middleName: "Employee's middle name",
            employees_contractTag_job: "Employee's job",
            employees_contractTag_email: "Employee's email address",
            employees_contractTag_phone: "Employee's phone number",
            employees_contractTag_initials: "Employee's initials",
            employees_contractTag_birthDate: "Employee's birth date",
            employees_contractTag_birthPlace: "Employee's birth place",
            employees_contractTag_address: "Employee's address",
            employees_contractTag_city: "Employee's city",
            employees_contractTag_postalCode: "Employee's postal code",
            employees_contractTag_citizen: "Employee's Social Security Number",
            employees_contractTag_bank: "Employee's bank account",
            employees_contractTag_birthDateDay: "Employee's birth day separated",
            employees_contractTag_birthDateMonth: "Employee's birth month separated",
            employees_contractTag_birthDateYear: "Employee's birth year separated",

            employees_contractTag_contractStart: "Contract start date",
            employees_contractTag_contractEnd: "Contract end date",
            employees_contractTag_contractHours: "Contract fixed hours",
            employees_contractTag_contractMinHours: "Contract minimum hours",
            employees_contractTag_contractMaxHours: "Contract maximum hours",
            employees_contractTag_contractHourly: "Hourly wage",
            employees_contractTag_contractMonthly: "Monthly wage",

            employees_contractTag_fullName_m: "Manager's full name",
            employees_contractTag_firstName_m: "Manager's first name",
            employees_contractTag_lastName_m: "Manager's last name",
            employees_contractTag_middleName_m: "Manager's middle name",
            employees_contractTag_job_m: "Manager's job",
            employees_contractTag_email_m: "Manager's email address",
            employees_contractTag_phone_m: "Manager's phone number",
            employees_contractTag_initials_m: "Manager's initials",
            employees_contractTag_birthDate_m: "Manager's birth date",
            employees_contractTag_birthPlace_m: "Manager's birth place",
            employees_contractTag_address_m: "Manager's address",
            employees_contractTag_city_m: "Manager's city",
            employees_contractTag_postalCode_m: "Manager's postal code",

            employees_contractTag_companyName: "Company name",
            employees_contractTag_companyAddress: "Company address",
            employees_contractTag_companyPostalCode: "Company postal code",
            employees_contractTag_companyCity: "Company city",
            employees_contractTag_companyPhone: "Company phone number",
            employees_contractTag_companyEmail: "Company email address",
            employees_contractTag_companyCountry: "Company country",
            employees_contractTag_companyNumber: "Company registration number",

            employees_contractTag_companyName2: "Company name",
            employees_contractTag_companyAddress2: "Address",
            employees_contractTag_companyPostalCode2: "Postal code",
            employees_contractTag_companyCity2: "City",
            employees_contractTag_companyPhone2: "Phone number",
            employees_contractTag_companyEmail2: "Email address",
            employees_contractTag_companyCountry2: "Country",
            employees_contractTag_companyNumber2: "Registration number",

            /* REQUESTS */
            requests_tooltip_generalOverview: "View on the general overview",
            requests_tooltip_planner: "View on the planner",

            requests_workingHours: "Working hours a day",
            requests_manage_workingDays: "Manage working days",

            requests_availableShifts_employees: "Available shifts from employees",
            requests_availableShifts_planner: "Available shifts from planner",

            /* TIME REGISTRATION */
            time_addToRegistration: "Add to time registration",
            time_manageAbsenceTypes: "Manage absence types",
            time_fullyApproved: "Fully approved",
            time_notFullyApproved: "Not yet fully approved",
            time_extraTimeRegistrations: "Extra time registrations",
            time_button_addTime: "Add time registration",

            /* REPORTS */
            reports_tooltip_leave: "Go to leave request",

            reports_add_note: "Add note",
            reports_pinNotes: "Pin notes",
            reports_show_weekends: "Show weekends",
            reports_show_holidays: "Show public holidays",

            report_plusMinusCorrections: "Corrections plus hours & minus hours",
            report_manageExclusions: "Manage exclusions",


            /* APP */
            /* AVAILABILITY */
            avail_alert_copy_title: "Copy",
            avail_alert_copy: `Do you want to paste your copied availability into this month?\nNote: this will overwrite your current availability for this month`,

            avail_alert_limitMonth: `You have already reached the limit of unavailable days for this month`,
            avail_alert_limitWeek: `You have already reached the limit of unavailable days for this week`,
            avail_alert_limitDay: `The unavailability limit for this day has been reached`,
            avail_alert_planned: "You are already scheduled for this day and so it is too late to add your unavailability. Contact your manager",
            avail_alert_availAdded: "Availability has been added",
            avail_alert_unavailAdded: "Unavailability has been added",
            avail_alert_availEdited: "Availability has been changed",
            avail_alert_unavailEdited: "Unavailability has been changed",
            avail_alert_availDeleted: "Availability has been deleted",
            avail_alert_unavailDeleted: "Unavailability has been deleted",
            avail_alert_schoolAdded: "School hours have been added",
            avail_alert_schoolHolidayAdded: "School holiday has been added",
            avail_alert_schoolEdited: "School hours have been changed",
            avail_alert_schoolHolidayEdited: "School holiday has been changed",
            avail_alert_schoolDeleted: "School hours have been deleted",
            avail_alert_schoolHolidayDeleted: "School holiday has been deleted",
            avail_alert_standardAvail_requested: "Change of availability has been requested",
            avail_alert_standardUnavail_requested: "Change of unavailability has been requested",

            avail_button_paste: "Paste here",
            avail_button_schoolSchedule: "Switch to school schedule",
            avail_button_calendar: "Switch to calendar",
            avail_button_leave: "Tap here to request leave or vacation",

            avail_title_add: "Add availability",
            avail_title_add_sub: "Add availability on",
            avail_title_add2: "Add unavailability",
            avail_title_add_sub2: "Add unavailability on",
            avail_title_edit: "Edit availability",
            avail_title_edit_sub: "Edit availability on",
            avail_title_editRepeating_sub: "Edit repeating availability",
            avail_title_edit2: "Edit unavailability",
            avail_title_edit_sub2: "Edit unavailability on",
            avail_title_editRepeating_sub2: "Edit repeating unavailability",
            avail_title_add_school: "Add school hours",
            avail_title_add_school_sub: "Add school hours on",
            avail_title_edit_school: "Edit school hours",
            avail_title_edit_school_sub: "Edit school hours on",
            avail_title_add_schoolHoliday: "Add school holiday",
            avail_title_add_schoolHoliday_sub: "Add school holiday on",
            avail_title_edit_schoolHoliday: "Edit school holiday",
            avail_title_edit_schoolHoliday_sub: "Edit school holiday on",

            avail_add_IAm: "I am",
            avail_add_repeatAvail: "Repeat availability",
            avail_add_repeatUnavail: "Repeat unavailability",
            avail_add_repeatingAvail: "Repeating availability",
            avail_add_repeatingUnavail: "Repeating unavailability",

            avail_text_schoolHolidays: "Please note: these are the holidays you get according to the school schedule. This means that you are free from school and not from work.",
            avail_text_schoolHolidaysDelete: "School holidays are automatically removed when they are over.",

            avail_school_springbreak: "Spring Break",
            avail_school_mayHoliday: "Holidays in May",
            avail_school_summerbreak: "Summer Break",
            avail_school_autumnbreak: "Autumn Break",
            avail_school_christmasbreak: "Christmas Break",

            avail_standard_change: "{0} change is pending approval",
            avail_standard_changes: "{0} changes are pending approval",

            /* SCHEDULE */
            schedule_button_planning: "Create a schedule",

            schedule_error_empty: "No schedule published for this day",

            /* CHATS */
            chat_alert_created: "Group has been created",

            chat_error_enterGroupName: "Please enter a valid group name",
            chat_error_groupChatMin: "The group chat must contain more than 2 employees",

            chat_button_createGroup: "Create group",
            chat_button_editGroup: "Edit group",
            chat_button_editRights: "Edit rights",
            chat_button_deleteGroup: "Delete group",

            chat_screen_myChats: "My chats",
            chat_screen_groupChats: "Group chats",
            chat_screen_tapStartChat: "Tap here to start a chat",
            chat_screen_noGroupChats: "There are no group chats",
            chat_screen_createNewGroup: "Create new group",
            chat_screen_whoMayChat: "Who can chat",
            chat_screen_employeesGroupChat: "Employees group chat",
            chat_screen_deselect: "Deselect everyone",
            chat_screen_select: "Select everyone",
            chat_screen_loadMore: "Load more messages",
            chat_screen_typeHere: "Type your message here...",
            chat_screen_chatRights: "Chat rights",

            /* CLOCK */
            clock_alert_clockedOut_title: "Clocked out",
            clock_alert_clockedOut: "You are clocked out",
            clock_alert_break_title: "Break",
            clock_alert_break: "You are on a break",
            clock_alert_break2: "You are back from a break",
            clock_alert_failedDistance: `You are not near your work\n\nYour distance is {0} meters from the clock-in zone`,
            clock_alert_failedSchedule: "You are not on the schedule today",
            clock_alert_clockIn: "Do you want to clock in?",
            clock_alert_clockOut: "Do you want to clock out?",
            clock_alert_breakIn: "Do you want to go on a break?",
            clock_alert_breakOut: "Do you want to return from a break?",
            clock_alert_alreadyClockedIn: "You are already clocked in",
            clock_alert_alreadyClockedOut: "You are already clocked out",
            clock_alert_alreadyBreakIn: "You are already on a break",
            clock_alert_alreadyBreakOut: "You are already back from a break",
            clock_alert_breakError: "You cannot go on a break yet",

            clock_noti_dontForget: "Do not forget to clock out before {0}",
            clock_noti_clockedOut: "You are clocked out at {0}",

            clock_error_notAllowed: "Clocking in from a mobile is not allowed. It is only allowed to clock out from a mobile",

            clock_screen_title: "Clock-in & out",
            clock_screen_notSchedule: "You are not on the schedule today",
            clock_screen_onSchedule: "You are on the schedule today from",
            clock_screen_tooLate: "You are too late. Please select a reason below",

            clock_screen_reason_none: "None of the below",
            clock_screen_reason_tooLate: "Too late",
            clock_screen_reason_forgot: "Forgot to clock in",

            clock_screen_totalBreak: "Total break",
            clock_screen_breakTime: "total break according to the schedule",
            clock_screen_clockedInAt: "You are clocked in on",
            clock_screen_notClockedIn: "You are not clocked in",
            clock_screen_pause: "Break",
            clock_screen_pauseEnd: "End break",

            /* PLANNER */
            planner_alert_publish_title: "Publish schedule",
            planner_alert_publish: "Publish the schedule of {0}, unpublish or publish everything?\n\nIf you choose to publish, the schedule will be visible for all employees",
            
            planner_noti_published: "Your new schedule has been uploaded. Tap here to update your schedule notifications",
            planner_noti_deleted: "You have been removed from the schedule for {0}. Tap here to update your schedule notifications",
            planner_noti_added: "You have been added on the schedule for {0}. Tap here to update your schedule notifications",
            planner_noti_edited: "Your shift has been changed from {0} to {1}. Tap here to update your schedule notifications",
            planner_noti_statusTitle: "Schedule status change",
            planner_noti_statusNoAbsenceYesPart: "Status {0}: No absence/{1}",
            planner_noti_statusNoAbsence: "Status {0}: No absence",
            planner_noti_statusNoPart: "Status {0}: No particularity",
            planner_noti_statusYes: "Status {0}: {1}",

            planner_button_undo: "Undo publish",
            planner_button_day: "Publish this day",
            planner_button_all: "Publish all",

            planner_screen_shiftTemplate: "Shifts template",
            planner_screen_chooseSection: "Select section",
            planner_screen_chooseTemplate: "Select shift template",
            planner_screen_scheduleOn: "Schedule {0} on",
            planner_screen_wholeDayAvailable: "Available all day",
            planner_screen_wholeDayUnavailable: "Unavailable all day",
            planner_screen_periodAvailable: "Available {0}",
            planner_screen_periodUnavailable: "Unavailable {0}",
            planner_screen_periodSchool: "School {0}",
            planner_screen_noAvailability: "No availabiltiy given for this day",
            planner_screen_ignoreAvailability: "Ignore availability",

            planner_screen_personUnavailableWholeDay: "{0} is unavailable all day",
            planner_screen_personUnavailablePeriod: "{0} is unavailable {1}",
            planner_screen_personAvailableWholeDay: "{0} is available all day",
            planner_screen_personAvailablePeriod: "{0} is available {1}",
            planner_screen_personSchoolPeriod: "{0} has school {1}",

            planner_web_screen_usersNoJob: "Employees without a job",
            planner_web_screen_shiftSkills: "Shift requires the following skills",
            planner_web_screen_userNoSkills: "Employee does not have the required skills",
            planner_web_screen_notPublished: "Shift is not published yet",
            planner_web_screen_saved: "Saved",
            planner_web_screen_save: "Save",
            planner_web_screen_export: "Export",
            planner_web_screen_revert: "Revert",
            planner_web_screen_delete: "Delete",
            planner_web_screen_deletePlanner: "Delete schedule of week {0}",
            planner_web_screen_undoPlanner: "Undo changes of week {0}",
            planner_web_screen_exportPlanner: "Export schedule",
            planner_web_screen_savePlanner: "Save week {0} as a template",
            planner_web_screen_allSavedTemplates: "Saved schedules",
            planner_web_screen_settings: "Settings schedule",
            planner_web_screen_applySavedSchedule: "Apply saved schedule",
            planner_web_screen_applySavedSchedule_text: "Are you sure you want to apply the template {0} to week {1}?",
            planner_web_screen_manageBreaks: "Manage breaks",
            planner_web_screen_manageTeams: "Manage teams",
            planner_web_screen_goToHolidayRequest: "Go to leave request",
            planner_web_screen_checkGeneralOverview: "To general overview",

            planner_web_caution_notPublished: "not published",
            planner_web_caution_conflicts: "conflicts",

            planner_web_top_wage: "Gross wage costs",
            planner_web_top_expectedLoad: "Expected load",
            planner_web_top_expected: "Expected turnover",
            planner_web_top_actual: "Actual turnover",
            planner_web_top_productivity: "Productivity",
            planner_web_top_total: "Total",
            planner_web_top_lastYear: "Turnover {0}",

            planner_web_menu_sortName: "Sort by name",
            planner_web_menu_sortJob: "Sort by job",
            planner_web_menu_exportCsv: "Export .csv",

            planner_web_alert_deletePlanner: "Are you sure you want the delete the whole schedule of week {0}?",
            planner_web_alert_undoPlanner: "Are you sure you want to undo the changes in the schedule of week {0}?",

            /* OPTIONS */
            options_title_myData: "My data",
            options_title_myWorkedHours: "My worked hours",
            options_title_myPlusMinus: "My plus/minus hours",
            options_title_myAppointments: "My appointments",
            options_title_myDocuments: "My documents",
            options_title_teams: "Teams",
            options_title_switchTeam: "Switch to team",
            options_title_clockInSystem: "Clock-in system",
            options_title_clockIn: "Clock in",
            options_title_clockedEmployees: "Clocked employees",
            options_title_availableShifts: "Available shifts",
            options_title_viewShifts: "View available shifts",
            options_title_requests: "Requests",
            options_title_tradeRequests: "Trade requests",
            options_title_leaveRequests: "Leave requests",
            options_title_shiftTakeovers: "Takeover requests",
            options_title_registration: "Registration",
            options_title_registerEmployee: "Register employee",
            options_title_actions: "Actions",
            options_title_requestLeaveVacation: "Request leave/vacation",
            options_title_allDeadlines: "All deadlines",
            options_title_allEmployees: "All employees",
            options_title_support: "Support",
            options_title_mail: "Send mail to Tiemdo",
            options_title_switchAccount: "Switch to account",

            options_acc_multipleTeams: "Multiple teams",
            options_acc_fillInData: "Fill in data",
            options_acc_fill_text: "You still need to fill in a few details to complete your account. See below which information we still miss about you.",
            options_acc_fill_text2: "In addition, you must agree to our privacy statement in order to use the app.",
            options_acc_newPass: "New password",

            options_acc_alert_save_title: "Save {0}",
            options_acc_alert_save: "Are you sure you want to save the following changed data?{0}",
            options_acc_alert_saved: "{0} has been changed",
            options_acc_alert_deleted: "{0} has been deleted",
            options_acc_alert_savedOwn: "Your data has been changed",
            options_acc_alert_failedAdmin: "There is currently only 1 administrator. First make another employee an administrator and then delete this employee",
            options_acc_alert_failedEmployee: "At least 1 employee must be registered to delete accounts",

            options_blocked_add_title: "Add blocked day",
            options_blocked_edit_title: "Edit blocked day",
            options_pass_edit_title: "Edit password",
            options_deadline_add_title: "Add deadline",
            options_deadline_edit_title: "Edit deadline",
            options_job_add_title: "Add job",
            options_job_edit_title: "Edit job",
            options_holiday_edit_title: "Edit leave",
            options_holiday_approve_title: "Approve leave",
            options_section_add_title: "Add section",
            options_section_edit_title: "Edit section",
            options_fixedTimes_add_title: "Add fixed times",
            options_fixedTimes_edit_title: "Edit fixed times",

            options_noti_edited_blocked: "Blocked day has been changed",
            options_noti_edited_pass: "Your password has been changed",

            options_pass_error_current: "You have not entered your current password",
            options_pass_error_new: "You have not entered a new password",
            options_pass_error_confirm: "You need to confirm the new password",

            options_pass_screen_current: "Current password",
            options_pass_screen_new: "New password",

            options_clock_alert_clockOut: "Do you want to clock out this employee?",
            options_clock_error_clockOut: "This employee is already clocked out",
            options_clock_screen_title: "Clocked in employees",

            options_deadline_screen_edit: "Availability adjustable after deadline?",

            options_doc_noti_title: "Document upload",
            options_doc_noti: "{0} uploaded a new document under {1}",

            options_doc_uploadAlert: "Are you sure you want to upload this document?",

            options_holiday_screen_dayOffVacation: "Request a day off/vacation",
            options_holiday_screen_dayOff_title: "Request day off",
            options_holiday_screen_dayOff_sub: "Would you like to request a day off?",
            options_holiday_screen_dayOff: "Request day off",
            options_holiday_screen_vacation_title: "Request vacation",
            options_holiday_screen_vacation_sub: "Would you like to request a vacation?",
            options_holiday_screen_vacation: "Request vacation",
            options_holiday_screen_holidayPeriod: "You request a vacation during a busy holiday period. Select the days you can work during this busy holiday period.",
            options_holiday_screen_hours: "Leave hours to be taken", 

            options_holiday_request_staff: "{0} has requested leave for {1}",
            options_holiday_request_staff2: "{0} has requested a new vacation request from {1}",
            options_holiday_request_employee_accepted: "Your day off request for {0} has been approved",
            options_holiday_request_employee_denied: "Your day off request for {0} has been denied",
            options_holiday_request_employee_accepted2: "Your vacation request from {0} has been approved",
            options_holiday_request_employee_denied2: "Your vacation request from {0} has been denied",
            
            options_holiday_alert_added: "Leave has been requested",
            options_holiday_alert_edited: "Leave has been changed",
            options_holiday_alert_deleted: "Leave has been deleted",
            options_holiday_alert_approved: "Leave has been approved",
            options_holiday_alert_denied: "Leave has been denied",

            options_holiday_alert_acceptRequest: "Are you sure you want to approve this request?",            
            options_holiday_alert_denyRequest: "Are you sure you want to deny this request?", 
            
            options_myHours_screen_title: "Worked hours",
            options_myHours_screen_weekDisplay: "Week display",
            options_myHours_screen_monthDisplay: "Month display",
            options_myHours_screen_yearDisplay: "Year display",

            options_fixedTimes_alert_edited: "Fixed times have been changed",
            options_fixedTimes_alert_deleted: "Fixed times have been deleted",

            options_trade_alert_approved: "You have approved the trade request",
            options_trade_alert_denied: "You have denied the trade request",
            options_trade_alert_deleted: "You have deleted the trade request",
            options_trade_alert_accepted: "You have accepted the trade request",

            options_trade_noti_tradeRequestAccepted: "The trade request between you and {0} has been approved. You are taken off of {1} and your new shift is {2}",
            options_trade_noti_tradeRequestDenied: "The trade request between you and {0} has been denied",
            options_trade_noti_tradeRequest: "There is a new trade request available to approve",

            options_trade_noti_tradeRequestAccepted2: "{0} has accepted your trade request of {1}. Wait for approval from your manager",
            options_trade_noti_tradeRequestDenied2: "{0} has denied your trade request of {1}",

            options_userOptions_alert_error: "Token was not retrieved. Error message: ",
            options_userOptions_alert_mobile: "Tiemdo has noticed that you are using a different device. Your details for receiving notifications have been updated automatically",
            
            options_userOptions_screen_lang: "Language",
            options_userOptions_screen_calendar: "Calendar",
            options_userOptions_screen_calendarSync: "Synchronize calendar",
            options_userOptions_screen_calendarSync_sub: "Automatically synchronize your shifts to your calendar",
            options_userOptions_screen_calendarDelete: "Clear work calendar",
            options_userOptions_screen_noti: "Notifications",
            options_userOptions_screen_notiAllow: "Allow notifications",
            options_userOptions_screen_notiTime: "Notification time",
            options_userOptions_screen_notiTime_sub: "You can set up when you want to receive a notification. Please indicate below how many hours in advance you would like to receive a notification. This only applies to deadlines and own shifts",
            options_userOptions_screen_notiTime_sub2: "in advance",

            options_web_screen_settingsPlanner: "Settings schedule",
            options_web_screen_general: "General",
            options_web_screen_allowSmartPlan: "Allow Smart Plan",
            options_web_screen_manage: "Manage",
            options_web_screen_availableShifts: "All default shifts",
            options_web_screen_addEmployee: "Add employee",
            options_web_screen_addJob: "Add job",
            options_web_screen_manageDefaultShifts: "Manage default shifts",
            options_web_screen_addSection: "Add section",
            options_web_screen_display: "Display",
            options_web_screen_hideAvailability: "Show availability labels",
            options_web_screen_expectedLoad: "Show expected load",
            options_web_screen_sort: "Sort",
            options_web_screen_sortEmployees: "Sort employees",
            options_web_screen_sortJobs: "Sort jobs",
            options_web_screen_finances: "Financial calculations",
            options_web_screen_salary: "Show wage costs",
            options_web_screen_turnoverLastYear: "Show turnover last year",
            options_web_screen_expectedTurnover: "Show expected turnover",
            options_web_screen_actualTurnover: "Show actual turnover",
            options_web_screen_productivity: "Show productivity",
            options_web_screen_calculations: "Show calculation",
            options_web_screen_openShifts: "Open shifts",
            options_web_screen_openShiftsAvailable: "Open shifts immediate available",
            options_web_screen_contractExpired: "Contract expiration",

            options_web_tooltip_smartPlan: "Show the Smart Plan option on the schedule. This allows your employees to be quickly scheduled based on their availability",
            options_web_tooltip_showAvailability: "Show availability labels on the schedule",
            options_web_tooltip_expectedLoad: "Show the expected load. The load is calculated using the past 3 weeks",
            options_web_tooltip_salary: "Show wage costs of employees",
            options_web_tooltip_turnoverLast: "Show previous year's turnover",
            options_web_tooltip_expectedTurnover: "Be able to fill in expected turnover per day",
            options_web_tooltip_actualTurnover: "Be able to fill in actual turnover per day",
            options_web_tooltip_productivity: "Calculate productivity per day using scheduled hours and turnover. If no actual turnover has been made yet, the calculation is made using expected turnover or turnover from the previous year",
            options_web_tooltip_calculations: "Show the total calculation",
            options_web_tooltip_openShifts: "When an open shift is created, it will be put on available immediately",
            options_web_tooltip_contractExpired: "When an employee's contract has expired, the schedule takes this into account and this employee can no longer be scheduled",

            /* REGISTRATION */
            register_screen_title: "New employee",
            register_screen_self_title: "Register an employee yourself",
            register_screen_self_sub: "Do you have the details of the new employee?",

            register_screen_other_title: "Register an employee",
            register_screen_other_sub: "Do you not have the details of the new employee?",

            register_screen_qr_title: "Scan QR code",
            register_screen_qr_sub: "Have you created a registration code on the website? Share the code by pressing the button below",

            register_screen_rc_title: "Registration codes",
            register_screen_rc_sub: "All unused registration codes",

            register_button_self: "Register employee",
            register_button_other: "Send registration code",
            register_button_qr: "Scan QR code",

            register_message_register: `I would like you to register in the Tiemdo app. Use "{0}" as company code.\n\nYour registration code: (case sensitive)\n\n{1}\n\nDon't you have the Tiemdo app yet? Download it via:\n\nwww.tiemdo.com/download`,

            register_noti_sent: "Registration code has been sent",
            register_noti_deleted: "Registration code has been deleted",

            register_alert_created: "Registration code has been created",

            /* LOGGER */
            logger_published_whole: "whole schedule",

        },




































































































































































































    




































































































        nl: {
            // Buttons
            next_button: 'Volgende',
            continue_button: 'Doorgaan',
            login_button: 'Inloggen',

            // Days
            day_monday: 'Maandag',
            day_tuesday: 'Dinsdag',
            day_wednesday: 'Woensdag',
            day_thursday: 'Donderdag',
            day_friday: 'Vrijdag',
            day_saturday: 'Zaterdag',
            day_sunday: 'Zondag',

            // Days
            day_monday_shrt: 'Ma',
            day_tuesday_shrt: 'Di',
            day_wednesday_shrt: 'Wo',
            day_thursday_shrt: 'Do',
            day_friday_shrt: 'Vr',
            day_saturday_shrt: 'Za',
            day_sunday_shrt: 'Zo',

            // Months
            month_jan: 'januari',
            month_feb: 'februari',
            month_mar: 'maart',
            month_apr: 'april',
            month_may: 'mei',
            month_jun: 'juni',
            month_jul: 'juli',
            month_aug: 'augustus',
            month_sep: 'september',
            month_oct: 'oktober',
            month_nov: 'november',
            month_dec: 'december',

            // Short Months
            month_jan_shrt: 'jan',
            month_feb_shrt: 'feb',
            month_mar_shrt: 'mrt',
            month_apr_shrt: 'apr',
            month_may_shrt: 'mei',
            month_jun_shrt: 'jun',
            month_jul_shrt: 'jul',
            month_aug_shrt: 'aug',
            month_sep_shrt: 'sept',
            month_oct_shrt: 'okt',
            month_nov_shrt: 'nov',
            month_dec_shrt: 'dec',





            /* SERVER */
            server_error: "Er is een serverfout opgetreden. Probeer later opnieuw",
            server_migration: "De server is in migratie. Probeer later opnieuw",
            server_maintenance: "De server is in onderhoud. Probeer later opnieuw",

            /* ALERTS */
            alert_save_title: "Opslaan",
            alert_save: "Weet je zeker dat je dit wilt opslaan?",

            alert_delete_title: "Verwijderen",
            alert_delete: "Weet je zeker dat je dit wilt verwijderen?",

            alert_edit_title: "Wijziging",
            alert_edit: "Weet je zeker dat je dit wilt wijzigen?",

            alert_add_title: "Toevoegen",
            alert_add: "Weet je zeker dat je dit wilt toevoegen?",

            alert_action_title: "Actie",
            alert_action: "Weet je zeker dat je deze actie wilt uitvoeren?",

            alert_idle_title: "Inactief",
            alert_idle_minutes: "15 minuten",
            alert_idle: "Je bent langer dan {0} inactief geweest. Om ervoor te zorgen dat verzoeken, verlof en beschikbaarheid van medewerkers up-to-date zijn, dien je dit venster te verversen",
            alert_idle_sub_refresh: "Verversen",
            alert_idle_sub: "Klik op de knop {0} om Tiemdo opnieuw te laden",

            alert_success_title: "Gelukt",
            alert_success_savedShifts: "Jouw diensten zijn opgeslagen",
            alert_success_acceptedShift: "Je hebt de dienst geaccepteerd",
            alert_success_copiedAvail: "Jouw beschikbaarheid is gekopieerd",

            alert_warning_title: "Waarschuwing",

            alert_error_title: "Fout",
            alert_error_manager: "Geef dit door aan jouw leidinggevende",

            alert_failed_title: "Mislukt",
            alert_failed_maxEmployees: "Je hebt het maximale aantal registratiecodes en medewerkers van {0} bereikt",
            alert_failed_noTeam: "Je bent nog niet gekoppeld aan een team. neem contact op met jouw leidinggevende",
            alert_failed_noShiftsAgenda: "Je hebt op dit moment geen diensten die je in jouw mobiele agenda kunt opslaan",
            alert_failed_media5MB: "Afbeelding uploaden mislukt. Zorg ervoor dat de afbeelding niet groter is dan 5mb",

            alert_failed_permission_agenda: "Je dient Tiemdo toegang te geven tot jouw agenda. Ga naar jouw agenda-instellingen",
            alert_failed_permission_location: `Je hebt Tiemdo nog geen toegang gegeven tot jouw locatie-instellingen. In het volgende venster wordt gevraagd om toegang te verlenen tot jouw locatiegegevens.\n\nLET OP: zet exacte locatie aan`,
            alert_failed_permission_location2: `Je moet Tiemdo toegang geven tot jouw locatiegegevens om het inkloksysteem te kunnen gebruiken.\n\nLET OP: zet exacte locatie aan`,
            alert_failed_permission_media: `Tiemdo heeft toegang nodig tot jouw media bestanden om een profielfoto te kunnen uploaden`,
            alert_failed_permission_noti: `Je moet Tiemdo toegang geven voor het versturen van notificaties. Wijzig dit in de notificatie-instellingen van jouw mobiel`,
            alert_failed_permission_camera_title: `Tiemdo heeft geen toegang tot jouw camera.`,
            alert_failed_permission_camera: `Probeer opnieuw of geef Tiemdo toegang tot jouw camera in jouw instellingen.`,
            alert_failed_permission_camera2: `Je moet Tiemdo toegang geven tot jouw camera om QR-codes te scannen`,

            alert_approve_title: "Goedkeuren",
            alert_approve: "Weet je zeker dat je dit verzoek wilt goedkeuren?",
            alert_approve2: "Weet je zeker dat je deze actie wilt goedkeuren?",

            alert_deny_title: "Afwijzen",
            alert_deny: "Weet je zeker dat je dit verzoek wilt afwijzen?",
            alert_deny2: "Weet je zeker dat je deze actie wilt afwijzen?",

            alert_accept_title: "Accepteren",
            alert_accept: "Weet je zeker dat je dit verzoek wilt accepteren?",

            alert_logout_title: "Uitloggen",
            alert_logout: "Weet je zeker dat je wilt uitloggen?",

            alert_welcome_title: "Omgeving instellen",
            alert_welcome: "Welkom bij",
            alert_welcome_sub: "Het lijkt erop dat je voor het eerst gebruik maakt van Tiemdo. Om ervoor te zorgen dat je de juiste instellingen hebt, zal er een venster worden geopend waarin je jouw omgeving kunt instellen.",
            alert_welcomeSettings: "Het lijkt erop dat je al gebruik maakt van Tiemdo. Er zijn veel nieuwe instellingen bij gekomen vanaf het moment dat je begonnen bent.",
            alert_welcomeSettings_sub: "Wil je nakijken of je nog steeds de juiste instellingen hebt?",

            alert_network_title: "Netwerk",
            alert_network: "Something seems to have gone wrong with your connection while logging in. Try againEr lijkt iets mis te zijn gegaan met jouw verbinding tijdens het inloggen. Probeer opnieuw",

            alert_notification_title: "Notificaties",
            alert_notification_question: "Wil je notificaties ontvangen over deadlines, eigen diensten, ruil- en verlofverzoeken?",
            alert_notification_later: "Je kunt jouw notificatie-instellingen later wijzigen onder Opties in het menu",

            alert_agenda_title: "Agenda",
            alert_agenda_save: "Wil je jouw eigen diensten en deadlines opslaan in jouw mobiele agenda?",

            alert_availableShift_takeOver_title: "Dienst overnemen",
            alert_availableShift_takeOver: "Weet je zeker dat je deze dienst wilt overnemen?",

            alert_app_added_param: "{0} is toegevoegd",
            alert_app_edited_param: "{0} is gewijzigd",
            alert_app_deleted_param: "{0} is verwijderd",

            alert_app_calendar_added: "Jouw diensten zijn opgeslagen in jouw agenda",
            alert_app_calendar_deleted: "Jouw diensten zijn uit jouw agenda verwijderd",
            alert_app_calendar_error: "Je hebt op dit moment geen diensten die je in jouw mobiele agenda kunt opslaan",

            alert_sentLogin: "Nieuwe inloggegegevens zijn verstuurd naar {0}",

            /* NOTIFICATIONS */
            noti_holiday_title: "Vakantie",
            noti_holidayRequest_approved: "Jouw vakantieverzoek van {0} is goedgekeurd",
            noti_holidayRequest_denied: "Jouw vakantieverzoek van {0} is afgewezen",
            noti_holidayRequest_deleted: "Jouw vakantieverzoek van {0} is verwijderd",

            noti_availableShift_title: "Beschikbare dienst",
            noti_availableShift_staff: "{0} wilt graag een dienst overnemen op {1}",

            noti_trade_title: "Ruilen",
            noti_tradeRequest_staff_denied: "{0} heeft het ruilverzoek tussen jou en {1} afgewezen",
            noti_tradeRequest_staff_approved: "{0} heeft het ruilverzoek tussen jou en {1} goedgekeurd. Jouw nieuwe dienst is op {2}",
            noti_tradeRequest_staff_deleted: "{0} heeft het ruilverzoek tussen jou en {1} verwijderd",

            noti_takeover_title: "Dienstovername",
            noti_takeover_staff: "Je hebt de dienst {0} overgenomen",

            noti_substitution_title: "Vervanging",
            noti_substitution_staff: "Jouw dienst van {0} is overgenomen",

            /* ERRORS */
            error_trial: "De proeftijd is verstreken. Stel jouw betalingsgegevens in om weer gebruik te maken van Tiemdo",
            error_subscription: "Je hebt geen geldig abonnement. Stel jouw betalingsgegevens in om gebruik te maken van Tiemdo",

            error_forgot_username_title: "Gebruikersnaam vergeten",
            error_forgot_username: "Neem contact op met jouw leidinggevende om jouw gebruikersnaam op te vragen",

            error_notFilled_companyCode: "Je hebt geen bedrijfscode ingevoerd",
            error_notFilled_username: "Je hebt geen gebruikersnaam ingevoerd",
            error_notFilled_password: "Je hebt geen wachtwoord ingevoerd",
            error_notFilled_firstName: "Je hebt geen voornaam ingevoerd",
            error_notFilled_lastName: "Je hebt geen achternaam ingevoerd",
            error_notFilled_birth: "Je hebt geen geboortedatum geselecteerd",
            error_notFilled_employee: "Je hebt geen medewerker geselecteerd",
            error_notFilled_job: "Je hebt geen functie geselecteerd",
            error_notFilled_jobName: "Je hebt geen functienaam ingevoerd",
            error_notFilled_sectionName: "Je hebt geen afdelingsnaam ingevoerd",
            error_notFilled_date: "Je hebt geen datum geselecteerd",
            error_notFilled_startDate: "Je hebt geen begindatum geselecteerd",
            error_notFilled_endDate: "Je hebt geen einddatum geselecteerd",
            error_notFilled_birthPlace: "Je hebt geen geboorteplaats ingevoerd",
            error_notFilled_email: "Je hebt geen e-mailadres ingevoerd",
            error_notFilled_phone: "Je hebt geen telefoonnummer ingevoerd",
            error_notFilled_address: "Je hebt geen adres ingevoerd",
            error_notFilled_addressHouse: "Je hebt geen huisnummer ingevoerd",
            error_notFilled_place: "Je hebt geen plaatsnaam ingevoerd",
            error_notFilled_zip: "Je hebt geen postcode ingevoerd",
            error_notFilled_clockCode: "Je hebt geen inklokcode ingevoerd",
            error_notFilled_newsTitleMessage: "Titel en bericht moeten beide ingevuld zijn",
            error_notFilled_privacy: "Je moet akkoord gaan met de privacyverklaring",
            error_notFilled_option: "Je hebt geen optie geselecteerd",

            error_invalid_username: "Gebruikersnaam mag alleen letters en/of cijfers bevatten",
            error_invalid_firstName: "Voornaam mag alleen letters, cijfers en - bevatten",
            error_invalid_lastName: "Achternaam mag alleen letters, cijfers en - bevatten",
            error_invalid_insertion: "Tussenvoegsel mag alleen letters, cijfers en - bevatten",
            error_invalid_address: "Adres mag alleen letters, cijfers en - bevatten",
            error_invalid_addressHouse: "Huisnummer mag alleen letters, cijfers en -() bevatten",
            error_invalid_zip: "Postcode mag alleen letters, cijfers en - bevatten",
            error_invalid_place: "Plaatsnaam mag alleen letters, cijfers en - bevatten",
            error_invalid_clockCode: "Inklokcode mag alleen cijfers bevatten",
            error_invalid_clockCode2: "Inklokcode moet 4 cijfers bevatten",
            error_invalid_email: "Je hebt geen geldig e-mailadres ingevoerd",
            error_invalid_phone: "Je hebt geen geldig telefoonnummer ingevoerd",
            error_invalid_birth: "Je hebt geen geldige geboortedatum ingevoerd",
            error_invalid_phone2: "Je hebt geen geldig tweede telefoonnummer ingevoerd",
            error_invalid_startTime: "Je hebt geen correcte begintijd ingevuld",
            error_invalid_endTime: "Je hebt geen correcte eindtijd ingevuld",
            error_invalid_timeIndication: "Je hebt geen correcte tijdsaanduiding ingevuld",
            error_invalid_startHigherEnd: "Begintijd kan niet later zijn dan eindtijd",
            error_invalid_endLowerStart: "Eindtijd kan niet eerder zijn dan begintijd",
            error_invalid_interval: "Je hebt geen correcte interval ingevuld",
            error_invalid_startHigherEnd_date: "Begindatum kan niet later zijn dan einddatum",
            error_invalid_endLowerStart_date: "Einddatum kan niet eerder zijn dan begindatum",

            error_tooLate_leave: "Je bent te laat met het aanvragen van verlof. Verlof aanvragen kan {0} dagen voor de begindatum van jouw verlof/vakantie",
            error_consecutive_leave: "Je kunt geen vakantie aanvragen die langer duurt dan {0} dagen achtereenvolgend",
            error_years_leave: "Je moet 2 vakantieverzoeken aanvragen voor een verzoek met verschillende jaartallen",

            error_companyCode: "Bedrijfscode is onjuist",
            error_desktop: "De web-versie is alleen bedoeld voor administratoren",
            error_clockSettings: "Je moet eerst jouw inklok-instellingen configureren",
            error_login: "Onjuiste gebruikersnaam en/of wachtwoord",
            error_login_password: "Wachtwoord is onjuist",
            error_companyCode_blocked: "Bedrijfscode kan op dit moment niet gebruikt worden. Neem contact op via www.tiemdo.nl",
            error_login_webFirst: "Voltooi eerst de instellingen via een desktop op https://app.tiemdo.com",
            error_login_settings: "Jouw leidinggevende heeft de instellingen nog niet voltooid. Probeer later opnieuw",

            error_file_empty: "Je hebt geen bestand geselecteerd",
            error_file_max: "Dit bestand is groter dan 25MB",

            error_notAdded_functions: "Je moet minstens 2 functies toevoegen",
            error_notAdded_employees: "Je moet minstens 2 medewerkers registreren",
            error_notAdded_leaveBalances: "Je hebt nog geen verlofsaldo toegevoegd",
            error_notAdded_leaveTypes: "Je hebt nog geen verloftype toegevoegd",
            error_notAdded_password: "Je moet een nieuw wachtwoord instellen",
            error_notAdded_passwordConfirm: "Je moet het wachtwoord nog bevestigen",

            error_notSame_password: "Wachtwoorden komen niet overeen",
            error_notSame_email: "E-mails komen niet overeen",

            error_duplicate_email: "Dit e-mailadres bestaat al",
            error_duplicate_username: "Deze gebruikersnaam bestaat al",
            error_duplicate_clockCode: "Deze inklokcode bestaat al",
            error_duplicate_startEndTime: "Begintijd kan niet hetzelfde zijn als eindtijd",
            error_duplicate_endStartTime: "Eindtijd kan niet hetzelfde zijn als begintijd",
            error_duplicate_startEndDate: "Begindatum kan niet hetzelfde zijn als einddatum",
            error_duplicate_endStartDate: "Einddatum kan niet hetzelfde zijn als begindatum",

            error_general_text: "Er is iets misgegaan. Probeer opnieuw",
            error_general_register: "Er is een probleem opgetreden tijdens het registreren. Probeer opnieuw",
            error_general_save: "Er is een probleem opgetreden tijdens het opslaan. Probeer opnieuw",
            error_general_jobDelete: "Er is een probleem opgetreden tijdens het verwijderen. Deze functie is gekoppeld aan een medewerker of wordt gebruikt op de planner",

            error_availableShift_ownShift: "Dit is jouw eigen dienst",
            error_availableShift_onSchedule: "Je staat op dat moment al op het rooster",
            error_availableShift_job: "Je kunt deze dienst niet overnemen vanwege jouw functie",
            error_availableShift_skills: "Je kunt deze dienst niet overnemen vanwege de benodigde vaardigheden",

            error_qr_fail: "Deze QR-code wordt niet herkend door Tiemdo",

            /* INPUTS */
            dropdown_title_team: "Teams",

            select_contract: "Selecteer een contractvorm",
            select_contract_all: "Alle contractvormen",
            select_contract_flex: "Flex contract (oproep, nuluren, flex)",
            select_contract_minMax: "Min-max contract",
            select_contract_fixed: "Vaste uren contract",
            select_contract_flexMinMax: "Flex contract & min-max contract",

            select_active_status: "Kies om inactieve medewerkers te tonen/verbergen",

            select_team: "Vestigingen weergeven",
            select_team2: "Selecteer een vestiging",
            select_team_all: "Alle vestigingen",

            select_employee: "Selecteer een medewerker",
            select_employee_all: "Alle medewerkers",

            select_rights: "Selecteer een recht",
            select_rights_employee: "Medewerker",
            select_rights_planner: "Planner",
            select_rights_admin: "Administrator",

            select_wageModel: "Selecteer een loonmodel",

            select_job: "Selecteer een functie",
            select_job_all: "Alle functies",

            select_date: "Selecteer een datum",
            select_birthDate: "Selecteer jouw geboortedatum",
            select_birthYear: "Selecteer jouw geboortejaar",

            select_year: "Selecteer een jaar",

            select_color: "Selecteer een kleur",

            select_language: "Selecteer taal",

            select_absence: "Selecteer een afwezigheidstype",

            select_option: "Selecteer een optie",
            select_type: "Selecteer een type",

            select_status: "Selecteer een status",
            select_open: "Openstaand",
            select_approved: "Goedgekeurd",
            select_denied: "Afgewezen",
            select_approvedDenied: "Geaccepteerd & afgewezen",

            select_sort: "Sorteren op",
            select_sort_date_asc: "Datum oplopend",
            select_sort_date_desc: "Datum aflopend",
            select_sort_name_asc: "Naam oplopend",
            select_sort_name_desc: "Naam aflopend",
            select_sort_hours_asc: "Uren oplopend",
            select_sort_hours_desc: "Uren aflopend",
            select_sort_gains_asc: "Omzet oplopend",
            select_sort_gains_desc: "Omzet aflopend",
            select_sort_wage_asc: "Salaris oplopend",
            select_sort_wage_desc: "Salaris aflopend",
            select_sort_availability_asc: "Beschikbaarheid oplopend",
            select_sort_availability_desc: "Beschikbaarheid aflopend",
            select_sort_unavailability_asc: "Onbeschikbaarheid oplopend",
            select_sort_unavailability_desc: "Onbeschikbaarheid aflopend",
            select_sort_dateShift1_asc: "Datum dienst 1 oplopend",
            select_sort_dateShift1_desc: "Datum dienst 1 aflopend",
            select_sort_dateShift2_asc: "Datum dienst 2 oplopend",
            select_sort_dateShift2_desc: "Datum dienst 2 aflopend",

            select_surcharge_inclusive: "Inclusief toeslagen",
            select_surcharge_exclusive: "Exclusief toeslagen",

            select_kind: "Selecteer een soort",
            select_kind_paid: "Uitbetaald",
            select_kind_correction: "Correctie",

            /* BUTTONS */
            button_continue: "Verder",
            button_refresh: "Verversen",
            button_ok: "OK",
            button_cancel: "Annuleren",
            button_logout: "Uitloggen",
            button_setUp: "Instellen",
            button_save: "Opslaan",
            button_delete: "Verwijderen",
            button_upload: "Uploaden",
            button_next: "Volgende",
            button_back: "Terug",
            button_finish: "Voltooien",
            button_add: "Toevoegen",
            button_edit: "Wijzigen",
            button_inactive: "Inactief zetten",
            button_active: "Actief zetten",
            button_notification: "Melding sturen",
            button_undo: "Ongedaan maken",
            button_approve: "Goedkeuren",
            button_deny: "Afwijzen",
            button_show: "Weergeven",
            button_reapprove: "Opnieuw goedkeuren",
            button_start: "Starten",
            button_export: "Exporteer nu",
            button_login: "Inloggen",
            button_switchOn: "Inschakelen",
            button_switchOff: "Uitschakelen",
            button_openSettings: "Instellingen openen",
            button_takeOver: "Overnemen",
            button_open: "Openen",
            button_create: "Aanmaken",
            button_copy: "Kopiëren",
            button_paste: "Plakken",
            button_sendMessage: "Bericht versturen",
            button_enableNoti: "Notificaties inschakelen",
            button_disableNoti: "Notificaties uitschakelen",
            button_request: "Aanvragen",
            button_accept: "Accepteren",
            button_sendCode: "Verstuur registratiecode",
            button_apply: "Toepassen",
            button_plan: "Inplannen",
            button_resend_data: "Verstuur nieuwe inloggegevens",

            /* MISC */
            misc_seconds: "seconden",
            misc_minutes: "minuten",
            misc_hours: "uren",
            misc_days: "dagen",
            misc_weeks: "weken",
            misc_months: "maanden",
            misc_years: "jaren",

            misc_seconds_c: "Seconden",
            misc_minutes_c: "Minuten",
            misc_hours_c: "Uren",
            misc_days_c: "Dagen",
            misc_weeks_c: "Weken",
            misc_months_c: "Maanden",
            misc_years_c: "Jaren",

            misc_second: "seconde",
            misc_minute: "minuut",
            misc_hour: "uur",
            misc_day: "dag",
            misc_week: "week",
            misc_month: "maand",
            misc_year: "jaar",

            misc_second_c: "Seconde",
            misc_minute_c: "Minuut",
            misc_hour_c: "Uur",
            misc_day_c: "Dag",
            misc_week_c: "Week",
            misc_month_c: "Maand",
            misc_year_c: "Jaar",

            misc_monday: "Maandag",
            misc_tuesday: "Dinsdag",
            misc_wednesday: "Woensdag",
            misc_thursday: "Donderdag",
            misc_friday: "Vrijdag",
            misc_saturday: "Zaterdag",
            misc_sunday: "Zondag",

            misc_monday_short: "Ma",
            misc_tuesday_short: "Di",
            misc_wednesday_short: "Wo",
            misc_thursday_short: "Do",
            misc_friday_short: "Vr",
            misc_saturday_short: "Za",
            misc_sunday_short: "Zo",

            misc_january: "januari",
            misc_february: "februari",
            misc_march: "maart",
            misc_april: "april",
            misc_may: "mei",
            misc_june: "juni",
            misc_july: "juli",
            misc_august: "augustus",
            misc_september: "september",
            misc_october: "oktober",
            misc_november: "november",
            misc_december: "december",

            misc_january_short: "jan",
            misc_february_short: "feb",
            misc_march_short: "mrt",
            misc_april_short: "apr",
            misc_may_short: "mei",
            misc_june_short: "jun",
            misc_july_short: "jul",
            misc_august_short: "aug",
            misc_september_short: "sept",
            misc_october_short: "okt",
            misc_november_short: "nov",
            misc_december_short: "dec",

            misc_goodDay: "Goedendag",
            misc_goodMorning: "Goedemorgen",
            misc_goodAfternoon: "Goedemiddag",
            misc_goodEvening: "Goedenavond",

            misc_by: "door",
            misc_by2: "Door",
            misc_at: "om",
            misc_from: "Van",
            misc_to: "Naar",
            misc_available: "Beschikbaar",
            misc_unavailable: "Onbeschikbaar",
            misc_date: "Datum",
            misc_time: "Tijd",
            misc_startEndTime: "Begin- & eindtijd",
            misc_startTime: "Begintijd",
            misc_endTime: "Eindtijd",
            misc_break: "Pauze",
            misc_breaks: "Pauzes",
            misc_wholeDay: "Hele dag",
            misc_wholeWeek: "Hele week",
            misc_showAll: "Alles weergeven",
            misc_start: "Begin",
            misc_end: "Eind",
            misc_ntb: "n.t.b.",  
            misc_tm: "t/m",  
            misc_nvt: "n.v.t.",  
            misc_menu: "Menu", 
            misc_employee: "Medewerker",
            misc_employees: "Medewerkers",
            misc_account: "Account",
            misc_accounts: "Accounts",
            misc_teams: "Teams",
            misc_team: "Team",
            misc_availability: "Beschikbaarheid",
            misc_unavailability: "Onbeschikbaarheid",
            misc_data: "Gegevens",
            misc_contract: "Contract",
            misc_contractData: "Contractgegevens",
            misc_generate: "Genereren",
            misc_job: "Functie",
            misc_jobs: "Functies",
            misc_section: "Afdeling",
            misc_sections: "Afdelingen",
            misc_balance: "Saldo",
            misc_balances: "Saldo's",
            misc_leaveBalance: "Verlofsaldo",
            misc_leaveBalances: "Verlofsaldo's",
            misc_plusMinus: "Plus- & minuren",
            misc_workingDays: "Werkdagen",
            misc_workingFiles: "Dossier",
            misc_file: "Bestand",
            misc_docs: "Documenten",
            misc_fileName: "Bestandsnaam",
            misc_notes: "Notities & afspraken",
            misc_notes2: "Notities",
            misc_appointments: "Afspraken",
            misc_overviews: "Overzichten",
            misc_settings: "Instellingen",
            misc_skills: "Vaardigheden",
            misc_defaultWorkingTimes: "Vaste werktijden",
            misc_general: "Algemeen",
            misc_generalOverview: "Algemeen overzicht",
            misc_trade: "Ruil",
            misc_trade2: "Ruilen", // voltooid vorm ruilen
            misc_absenceLeave: "Afwezigheid & verlof",
            misc_absence: "Afwezigheid",
            misc_absence2: "Afwezig",
            misc_absenceWaitDay: "Wachtdag",
            misc_leave: "Verlof",
            misc_finances: "Financiën",
            misc_timeRegistration: "Tijdregistratie",
            misc_contracts: "Contracten",
            misc_flexHours: "Flex uren",
            misc_minMaxHours: "Min-max uren",
            misc_name: "Naam",
            misc_salary: "Salaris",
            misc_salaryModel: "Loonmodel",
            misc_brutoHour: "Bruto uurloon",
            misc_brutoMonth: "Bruto maandloon",
            misc_none: "Geen",
            misc_actions: "Acties",
            misc_used: "Verbruikt",
            misc_current: "Huidig",
            misc_currentBalance: "Huidig saldo",
            misc_correction: "Correctie",
            misc_corrections: "Correcties",
            misc_comment: "Opmerking",
            misc_payout: "Uitbetaling",
            misc_period: "Periode",
            misc_openRequests: "Openstaande verzoeken",
            misc_approvedRequests: "Goedgekeurde verzoeken",
            misc_deniedRequests: "Afgewezen verzoeken",
            misc_gradedRequests: "Beoordeelde verzoeken",
            misc_status: "Status",
            misc_accrual: "Opbouw",
            misc_worked: "Gewerkt",
            misc_total: "Totaal",
            misc_totalHours: "Totaal uren",
            misc_totalGains: "Totaal omzet",
            misc_totalSalary: "Totaal loon",
            misc_totalEnd: "Totale eindberekening",
            misc_productivity: "Productiviteit",
            misc_title: "Titel",
            misc_description: "Beschrijving",
            misc_startDate: "Startdatum",
            misc_endDate: "Einddatum",
            misc_holiday: "Vakantie",
            misc_holidays: "Vakanties",
            misc_leaveDays: "Vakantiedagen",
            misc_change: "Wijziging",
            misc_changes: "Wijzigingen",
            misc_tradeRequest: "Ruilverzoek",
            misc_tradeRequests: "Ruilverzoeken",
            misc_leaveRequests: "Verlofverzoeken",
            misc_takeoverRequests: "Dienstovernames",
            misc_availabilityRequests: "Beschikbaarheid",
            misc_all: "Alle",
            misc_minHours: "Minimale uren",
            misc_aboveMinHours: "Bovenminimale uren",
            misc_average: "Gemiddeld",
            misc_averageWeek: "Gemiddeld per week",
            misc_averageHoursWeek: "Gemiddelde uren per week",
            misc_minContractHours: "Min. contract uren",
            misc_contractHours: "Contract uren",
            misc_leaveHoursToTake: "Op te nemen verlofuren",
            misc_approve: "Goedkeuren",
            misc_approved: "Goedgekeurd",
            misc_notApproved: "Niet goedgekeurd",
            misc_deny: "Afwijzen",
            misc_shift: "Dienst",
            misc_response: "Reactie",
            misc_responses: "Reacties",
            misc_responded: "Gereageerd",
            misc_clockIn: "Inklokken",
            misc_clockOut: "Uitklokken",
            misc_clocked: "Ingeklokt",
            misc_notClocked: "Niet ingeklokt",
            misc_startedEarlier: "Eerder begonnen",
            misc_startedTooLate: "Later begonnen",
            misc_tooLate: "Te laat",
            misc_forgotClockIn: "Vergeten in te klokken",
            misc_notOnPlanning: "Staat niet op het rooster",
            misc_reason: "Reden",
            misc_plannedStartTime: "Geplande begintijd",
            misc_plannedEndTime: "Geplande eindtijd",
            misc_plannedBreak: "Geplande pauze",
            misc_actualStartTime: "Daadwerkelijke begintijd",
            misc_actualEndTime: "Daadwerkelijke eindtijd",
            misc_actualBreak: "Daadwerkelijke pauze",
            misc_plannedWorkingDays: "Geplande werkdagen",
            misc_plannedHours: "Geplande uren",
            misc_actualWorkingDays: "Daadwerkelijke werkdagen",
            misc_actualHours: "Daadwerkelijke uren",
            misc_plannedDate: "Geplande datum",
            misc_display: "Weergave",
            misc_detailed: "Gedetailleerd",
            misc_eachWorkingDay: "Per werkdag",
            misc_surcharge: "Toeslag",
            misc_surcharges: "Toeslagen",
            misc_scheduleDate: "Roosterdatum",
            misc_asc: "Oplopend",
            misc_desc: "Aflopend",
            misc_absenceType: "Afwezigheidstype",
            misc_achievedGains: "Behaalde omzet",
            misc_actualGains: "Werkelijke omzet",
            misc_expectedGains: "Verwachte omzet",
            misc_tradeReport: "Ruil rapport",
            misc_availabilityReport: "Beschikbaarheid rapport",
            misc_financesReport: "Financiën rapport",
            misc_absenceReport: "Afwezigheid rapport",
            misc_leaveReport: "Verlof rapport",
            misc_timeReport: "Tijdregistratie rapport",
            misc_plusMinusReport: "Plus- & minuren rapport",
            misc_plusHoursPaid: "Plusuren uitbetaald",
            misc_flexReport: "Flexuren rapport",
            misc_minMaxReport: "Min-maxuren rapport",
            misc_notFilled: "Niet ingevuld",
            misc_periodic: "Periodiek",
            misc_monthly: "Maandelijks",
            misc_register: "Registreren",
            misc_registration: "Registratie",
            misc_registrationCode: "Registratiecode",
            misc_registrationCodes: "Registratiecodes",
            misc_privacyPolicy: "Privacyverklaring",
            misc_required: "Verplicht",
            misc_read: "Lezen",
            misc_read2: "Gelezen",
            misc_allRead: "Alles gelezen",
            misc_pending: "In afwachting",
            misc_takeOver: "Overnemen",
            misc_loading: "Laden",
            misc_clockInCode: "Inklokcode",
            misc_notification: "Melding",
            misc_notifications: "Meldingen",
            misc_shiftToday: "Dienst vandaag",
            misc_ownTradeRequests: "Eigen ruilverzoeken",
            misc_fillAvailability: "Beschikbaarheid doorgeven",
            misc_requestLeave: "Vrij vragen",
            misc_requestVacation: "Vakantie doorgeven",
            misc_absenceEmployee: "Afwezigheid medewerker",
            misc_broadcastMessage: "Bericht uitzenden",
            misc_noMessages: "Geen berichten",
            misc_message: "Bericht",
            misc_messages: "Berichten",
            misc_availableShifts: "Beschikbare diensten",
            misc_noAvailableShifts: "Geen beschikbare diensten",
            misc_information: "Informatie",
            misc_noInformation: "Geen informatie gepubliceerd",
            misc_contact: "Contact",
            misc_news: "Nieuws",
            misc_broadcast: "Uitzenden",
            misc_deadline: "Deadline",
            misc_deadlines: "Deadlines",
            misc_blockedDate: "Geblokkeerde datum",
            misc_blockedDates: "Geblokkeerde datums",
            misc_repeat: "Herhalen",
            misc_repeating: "Herhalend",
            misc_select: "Selecteren",
            misc_selected: "Geselecteerd",
            misc_interval: "Interval",
            misc_type: "Type",
            misc_school: "School",
            misc_schoolHoliday: "Schoolvakantie",
            misc_schoolHolidays: "Schoolvakanties",
            misc_schoolSchedule: "Schoolrooster",
            misc_contractExpired: "Contract verlopen",
            misc_chat: "Chat",
            misc_chats: "Chats",
            misc_role: "Rol",
            misc_roles: "Rollen",
            misc_call: "Bellen",
            misc_groupName: "Groepsnaam",
            misc_color: "Kleur",
            misc_colors: "Kleuren",
            misc_deselect: "Deselecteren",
            misc_planner: "Planner",
            misc_planner2: "Planner", // als in Rooster
            misc_planners: "Planners",
            misc_admin: "Administrator",
            misc_admins: "Administrators",
            misc_clockSystem: "Inkloksysteem",
            misc_defaultShift: "Standaard dienst",
            misc_defaultShifts: "Standaard diensten",
            misc_schedule: "Rooster",
            misc_schedules: "Roosters",
            misc_sort: "Sorteren",
            misc_publish: "Publiceren",
            misc_option: "Optie",
            misc_options: "Opties",
            misc_noEmployees: "Geen medewerkers",
            misc_noAppointments: "Geen afspraken",
            misc_noAvailableShifts: "Geen beschikbare diensten",
            misc_noRequests: "Geen verzoeken",
            misc_noBlockedDays: "Geen geblokkeerde dagen",
            misc_noDeadlines: "Geen deadlines",
            misc_noDocs: "Geen documenten",
            misc_noJobs: "Geen functies",
            misc_noRequestedLeave: "Geen aangevraagde vakanties",
            misc_noApprovedLeave: "Geen goedgekeurde vakanties",
            misc_noDeniedLeave: "Geen afgewezen vakanties",
            misc_noRequestedTrade: "Geen ruilverzoeken",
            misc_noApprovedTrade: "Geen goedgekeurde ruilverzoeken",
            misc_noDeniedTrade: "Geen afgewezen ruilverzoeken",
            misc_noSentTrade: "Geen verzonden ruilverzoeken",
            misc_noReceivedTrade: "Geen ontvangen ruilverzoeken",
            misc_noSections: "Geen afdelingen",
            misc_noFixedTimes: "Geen standaard tijden",
            misc_noSchool: "Geen school",
            misc_defaultTimes: "Vaste tijden",
            misc_particularities: "Bijzonderheden",
            misc_particularity: "Bijzonderheid",
            misc_default: "Standaard",
            misc_welcome: "Welkom",
            misc_employeesAccepted: "Medewerkers geaccepteerd",
            misc_byEmployees: "Door medewerkers",
            misc_deadlineDate: "Deadline datum",
            misc_periodFrom: "Periode van",
            misc_periodTo: "Periode tot",
            misc_until: "Tot en met",
            misc_signDoc: "Onderteken document",
            misc_viewDoc: "Bekijk document",
            misc_myDocs: "Mijn documenten",
            misc_jobName: "Functienaam",
            misc_logs: "Logboek",
            misc_details: "Details",
            misc_pendingApproval: "In afwachting van een goedkeuring",
            misc_pendingResponse: "In afwachting van een reactie",
            misc_pendingResponse2: "In afwachting van jouw reactie",
            misc_denied: "Afgewezen",
            misc_deniedByYou: "Afgewezen door jou",
            misc_deniedByOther: "Afgewezen door ander",
            misc_deniedByManager: "Afgewezen door manager",
            misc_request: "Verzoek",
            misc_requests: "Verzoeken",
            misc_requested: "Aangevraagd",     
            misc_attention: "Let op",     
            misc_theme: "Thema",     
            misc_themes: "Thema's", 
            misc_sent: "Verzonden",     
            misc_received: "Ontvangen",   
            misc_management: "Management",     
            misc_capital: "Hoofdletter",   
            misc_lowercase: "Kleine letter",   
            misc_rights: "Rechten",   
            misc_setUpPayment: "Betalingsgegevens instellen",   
            misc_birthday: "Jarig",   
            misc_label: "Label",   
            misc_labels: "Labels",   
            misc_manageLabels: "Beheer labels",   
            misc_putAvailable: "Beschikbaar maken",   
            misc_putUnavailable: "Beschikbaar ongedaan maken",   
            misc_duplicateShift: "Dienst dupliceren",   
            misc_availableShift: "Dienst is beschikbaar",   
            misc_availableShiftError: "Deze dienst wordt geruild of is al beschikbaar gemaakt",
            misc_allSections: "Alle afdelingen",  
            misc_display_normal: "Normale weergave",  
            misc_display_excel: "Tabel weergave",  
            misc_display_list: "Lijst weergave",  
            misc_copyMove: "Diensten kopiëren of verplaatsen",
            misc_showTeams: "Laat diensten van andere teams zien",
            misc_loadingSmartPlan: "Bezig met Smart Plan...",
            misc_loadingSmartPlan_account: "Zoeken naar medewerkers...",
            misc_plannedTimes: "{0}x ingepland",
            misc_dragToSameDay_alert: "Je kunt een medewerker niet naar een andere dag verslepen",
            misc_employeeSettings: "Medewerker instellingen",
            misc_selectShifts: "Tegels selecteren of pop-up weergeven",
            misc_paymentLink: "Klik hier om betaalgegevens toe te voegen",
            misc_timeForTime: "Tijd-voor-tijd",

            misc_data_userData: "Persoonsgegevens",
            misc_data_accountData: "Accountgegevens",
            misc_data_addressData: "Adresgegevens",
            misc_data_contactData: "Contactgegevens",
            misc_data_title: "Gegevens",
            misc_data_address_title: "Adresgegevens",
            misc_data_username: "Gebruikersnaam",
            misc_data_password: "Wachtwoord",
            misc_data_passwordConfirm: "Bevestig wachtwoord",
            misc_data_firstName: "Voornaam",
            misc_data_lastName: "Achternaam",
            misc_data_insertion: "Tussenvoegsel",
            misc_data_birth: "Geboortedatum",
            misc_data_birthPlace: "Geboorteplaats",
            misc_data_email: "E-mail",
            misc_data_emailConfirm: "Bevestig e-mail",
            misc_data_phone: "Telefoonnummer",
            misc_data_phone2: "Tweede telefoonnummer",
            misc_data_startDate: "Datum in dienst",
            misc_data_address: "Adres",
            misc_data_addressHouse: "Huisnummer",
            misc_data_place: "Woonplaats",
            misc_data_zip: "Postcode",
            misc_data_bank: "IBAN",
            misc_data_citizen: "Burgerservicenummer",
            misc_data_extra: "Aanvullende informatie",
            misc_data_accountRights: "Accountrechten",

            misc_data_privacy_agree: "Ik ga akkoord met de {0}",
            misc_data_privacy_agree2: "Medewerker gaat akkoord met de {0}",

            /* STARTUP MODAL */
            startup_addEmployee_link: "Medewerker toevoegen",
            startup_profile_restaurant: "Restaurant",
            startup_profile_bar: "Bar",
            startup_profile_hotel: "Hotel",
            startup_profile_delivery: "Take-Away",

            startup_title_profiles: "Profielen",
            startup_title_jobs: "Functies toevoegen",
            startup_title_jobsAuto: "Automatisch toegevoegde functies",

            startup_job_washingUp: "Afwas",
            startup_job_service: "Bediening",
            startup_job_bar: "Bar",
            startup_job_cook: "Kok",
            startup_job_drinks: "Drink Runner",
            startup_job_food: "Food Runner",
            startup_job_cleaning: "Schoonmaak",
            startup_job_reception: "Receptie",
            startup_job_kitchen: "Keuken",
            startup_job_cash: "Kassa",
            startup_job_car: "Autokoerier",
            startup_job_scooter: "Scooterkoerier",
            startup_job_bicycle: "Fietskoerier",
            startup_job_maintenance: "Onderhoud",
            startup_job_housekeeping: "Housekeeping",

            startup_tut_company: "Vul hieronder zo volledig mogelijk jouw bedrijfsgegevens in.",
            startup_tut_data1: "Vul hieronder jouw persoonlijke gegevens in. Dit is nodig om een administrator account voor jou aan te maken.",
            startup_tut_data2: "Vul hieronder een nieuw wachtwoord in om jouw administrator account te beveiligen.",
            startup_tut_jobs: "Om een rooster te kunnen maken en om functies te kunnen koppelen aan diensten, moet eerst een aantal functies toegevoegd worden. Selecteer hieronder een profiel om je suggesties te geven voor bepaalde functies en/of voeg zelf functies toe.",
            startup_tut_jobs2: ": je moet minimaal 2 functies toevoegen om door te gaan.",
            startup_tut_employees: "Om een rooster te maken en om medewerkers te koppelen aan diensten, moet eerst een aantal medewerkers geregistreerd worden. Klik hieronder op 'Medewerker toevoegen' om snel een nieuwe medewerker te registreren. Je hoeft nog niet gelijk een e-mail in te vullen of inloggegevens te versturen, dit kan later ook bij de instellingen van de medewerker.",
            startup_tut_employees2: ": je moet minimaal 2 medewerkers registreren om door te gaan.",
            startup_tut_caution: "Let op",

            /* NAVBAR */
            navbar_trial_expired: "Proeftijd is verlopen",
            navbar_trial: "Proeftijd verloopt over: {0}",

            navbar_switchAccount: "Wissel van account",
            navbar_switchClock: "Naar het inkloksysteem",

            navbar_menu: "Menu",
            navbar_home: "Home",
            navbar_employees: "Medewerkers",
            navbar_requests: "Verzoeken",
            navbar_timeRegistration: "Tijdregistratie",
            navbar_overviews: "Overzichten",
            navbar_schedule: "Rooster",

            navbar_myCompany: "Mijn bedrijf",
            navbar_settings: "Instellingen",
            navbar_logOut: "Uitloggen",
            navbar_help: "Hulp",

            navbar_menu_importEmployees: "Medewerkers importeren",
            navbar_menu_registerEmployees: "Medewerkers registreren",
            navbar_menu_sendCode: "Registratiecode versturen",
            navbar_menu_allCodes: "Alle registratiecodes",
            navbar_menu_myAccount: "Mijn account",
            navbar_menu_registration: "Registratie",
            navbar_menu_log: "Logboek",
            navbar_menu_support: "Support",

            navbar_footer_version: "Versie",
            navbar_footer_terms: "Algemene voorwaarden",
            navbar_footer_privacy: "Privacy statement",

            /* MODALS */
            modal_teams_title: "Selecteer een team",

            /* LOGIN */
            login_title: "Log in op jouw account",
            login_button: "Inloggen Tiemdo",
            login_button_clock: "Inloggen inkloksysteem",
            login_remember: "Mijn gegevens onthouden",
            login_forgot_companycode: "Ik ben mijn bedrijfscode vergeten",
            login_forgot_username: "Ik ben mijn gebruikersnaam vergeten",
            login_forgot_password: "Ik ben mijn wachtwoord vergeten",
            login_last: "Laatst gebruikt",
            login_no_account: "Heb je nog geen account?",
            login_signup: "Ik wil me aanmelden voor een proefperiode",
            login_companycode: "Bedrijfscode",
            login_username: "Gebruikersnaam of e-mail",
            login_password: "Wachtwoord",

            login_register_code_error: "Je hebt niet de volledige registratiecode ingevoerd",
            login_register_code_invalid: "Registratiecode is onjuist",

            login_register_registrationCode_text: "Vul hieronder de registratiecode in die je hebt ontvangen van jouw leidinggevende.",

            /* HOME */
            home_overview_tradeRequest: "Ruilverzoek",
            home_overview_openShift: "Open dienst",
            home_overview_shiftTransfer_title: "Dienstovername",
            home_overview_shiftTransfer: "Een medewerker wilt de dienst van {0} ({1} {2} - {3}, {4}) overnemen",
            home_overview_leaveRequest_title: "Verlofverzoek",
            home_overview_leaveRequest_oneDay: "{0} op {1}",
            home_overview_leaveRequest: "{0} van {1} t/m {2}",
            home_overview_contract_title: "Contract",
            home_overview_contract: "Het contract van {0} loopt af op {1}",
            home_overview_birthday_title: "Verjaardag",
            home_overview_birthday: "{0} van {1}",
            home_overview_deadline_title: "Deadline",
            home_overview_deadline: "{0} periode van {1} t/m {2}",

            home_ownShifts: "Mijn diensten",
            home_events: "Gebeurtenissen",
            home_requestsContracts: "Verzoeken & contracten",

            home_newsOverview: "Nieuwsoverzicht",
            home_latestNews: "Laatste nieuws",
            home_latestNews_send: "Nieuws uitzenden",

            home_actions: "Actiecentrum",
            home_actions_text: "Openstaande acties",
            home_actions_availability: "Beschikbaarheid wijzigingen",
            home_actions_trades: "Openstaande ruilverzoeken",
            home_actions_holidays: "Openstaande verlofverzoeken",
            home_actions_shifts: "Openstaande dienstovernames",

            home_shortcuts: "Snelkoppelingen",
            home_shortcuts_settings: "Instellingen",
            home_shortcuts_time: "Tijdregistratie nakijken",
            home_shortcuts_gains: "Omzet invullen",
            home_shortcuts_deadlines: "Deadlines beheren",
            home_shortcuts_leave: "Vakantieoverzicht medewerkers",

            home_editor_save: "Weet je zeker dat je deze informatie wilt opslaan?",
            home_file_delete: "Weet je zeker dat je dit bestand wilt verwijderen? Deze actie kan niet meer ongedaan worden gemaakt",
            home_logo_delete: "Weet je zeker dat je jouw bedrijfslogo wilt verwijderen?",

            home_menu_frontPage: "Voorpagina",
            home_menu_companyInfo: "Bedrijfsinformatie",
            home_menu_companyFiles: "Bedrijfsdocumenten",
            home_menu_companyLogo: "Logo",

            home_companyInfo_title: "Bedrijfsinformatie",
            home_companyInfo_edit: "Informatie bewerken",

            home_documents_title: "Bedrijfsdocumenten",

            home_app_trialExpired: "Proefperiode is verlopen",
            home_app_trialExpires: "Proefperiode verloopt over",
            home_app_noOpenActions: "Je hebt geen openstaande acties",
            home_app_noNewMessages: "Je hebt geen nieuwe berichten",
            home_app_noAvailableShifts: "Er zijn geen beschikbare diensten",
            home_app_youHave: "Je hebt",
            home_app_thereAre: "Er zijn",
            home_app_thereIs: "Er is",
            home_app_pendingAction: "openstaande actie",
            home_app_pendingActions: "openstaande acties",
            home_app_newMessage: "nieuw bericht",
            home_app_newMessages: "nieuwe berichten",
            home_app_availableShift: "beschikbare dienst",
            home_app_availableShifts: "beschikbare diensten",
            home_app_workingToday: "Je werkt vandaag van",
            home_app_noWorkToday: "Je hoeft vandaag niet te werken",
            home_app_clockIn_create: "Inklokcode aanmaken",
            home_app_note_today: "Notitie vandaag",
            home_app_shifts_noShifts: "Geen diensten",
            home_app_manageDeadlines: "Beheer deadlines",
            home_app_noCompanyFiles: "Geen documenten",
            home_app_editClockCode: "Inklokcode wijzigen",

            home_app_alert_substitute: "Verzoek tot vervanging is verzonden naar {0} medewerkers",
            home_app_alert_substituteLate: "Je bent te laat met het zoeken naar vervanging. Vervanging zoeken kan {0} dagen van te voren",
            home_app_alert_tradeLate: "Je bent te laat met ruilen. Ruilen kan {0} dagen van te voren",
            home_app_alert_searchSubstitute: "Vervanging zoeken",
            home_app_alert_searchSubstituteText: "De app zoekt naar beschikbare en relevante medewerkers voor jouw dienst. Deze medewerkers zullen een melding ontvangen. Wil je doorgaan?",
            home_app_alert_revokeSearchSubstitute: "Vervanging zoeken intrekken",
            home_app_alert_revokeSearchSubstituteText: "Weet je zeker dat je jouw verzoek tot het vragen van vervanging wilt intrekken?",

            home_app_alert_trade: "Wil je ruilen met {0}?\n\nJouw dienst: {1}\n\nDienst van {2}: {3}.\n\nHierna zul je nog moeten wachten op een antwoord van {4} en vervolgens op een goedkeuring van één van jouw managers",

            home_app_noti_trade: "{0} wilt graag jouw dienst {1} ruilen met zijn/haar dienst {2}",

            home_app_error_workToday: "Je moet deze dag al werken",

            home_app_button_revoke: "Intrekken",

            /* TUT MODAL */
            tutmodal_title: "Eerste instellingen bedrijf",
            tutmodal_subTitle_myCompany: "Mijn bedrijf",
            tutmodal_subTitle_myAccount: "Mijn account",
            tutmodal_menu_jobs: "Functies",
            tutmodal_menu_sections: "Afdelingen",
            tutmodal_menu_breaks: "Pauzes",
            tutmodal_menu_password: "Wachtwoord",
            tutmodal_menu_data: "Gegevens",
            tutmodal_page_password_title: "Wachtwoord wijzigen",
            tutmodal_page_data_title: "Gegevens wijzigen",
            tutmodal_page_config_title: "Bedrijf ingesteld",
            tutmodal_page_config_finished: "Voltooien",
            tutmodal_page_config_text: "Het bedrijf is naar wens ingesteld! Klik op {0} om dit venster af te sluiten.",
            
            /* EMPLOYEES */
            employees_tooltip_contract: "Contract verloopt bijna",
            employees_tooltip_team: "Medewerker heeft nog geen team toegewezen gekregen",
            employees_tooltip_job: "Medewerker heeft nog geen functie toegewezen gekregen",
            employees_tooltip_contractSign: "Contract ondertekenen",
            employees_tooltip_contractSignNotify: "Melding sturen om contract te ondertekenen",
            employees_tooltip_changeContract: "Contract wijzigen",
            employees_tooltip_extendContract: "Contract verlengen",
            
            employees_availability_invalid_startEndTime: "Je hebt geen correcte begin- of eindtijd ingevuld op {0}",
            employees_availability_invalid_startEndTime2: "Begintijd kan niet later zijn dan eindtijd op {0}",

            employees_misc_noTeam: "Geen team",

            employees_alert_changed_title: "Gewijzigd",
            employees_alert_changed: "{0} is gewijzigd",

            employees_alert_failed_admin: "Er is op dit moment maar 1 administrator. Maak eerst een andere medewerker een administrator en verwijder dan deze medewerker",
            employees_alert_failed_delete: "Er moet tenminste 1 medewerker geregistreerd zijn om accounts te kunnen verwijderen",
            employees_alert_failed_inactive: "Je kunt geen beheerder inactief zetten",
            employees_alert_delete_employee: "Weet je zeker dat je {0} wilt verwijderen? Deze actie kan niet meer ongedaan worden gemaakt",
            employees_alert_inactive_employee: "Weet je zeker dat je {0} op inactief wilt zetten?",
            employees_alert_active_employee: "Weet je zeker dat je {0} op actief wilt zetten?",
            employees_alert_documents_notify: "Wil je een melding sturen naar {0} om dit document te bekijken of digitaal te laten ondertekenen?",
            employees_alert_notification: "Melding verstuurd",
            employees_alert_generalDays: "Je hebt nog geen algemene werkdagen ingesteld. Wil je deze werkdagen nu instellen?",
            employees_alert_error_extendContract: "Dit contract kan niet worden verlengd omdat dit een contract is van onbepaalde duur",

            employees_alert_notifyUsers_title: "Melding sturen",

            employees_userSettings_employeeNumber: "Personeelsnummer",
            employees_userSettings_employeeNumber_sub: "Identificeren medewerker",
            employees_userSettings_register: "Mag nieuwe medewerkers registreren",
            employees_userSettings_register_sub: "(Indien dit account geen administrator rechten heeft)",
            employees_userSettings_maxDays: "Maximale werkdagen per week",
            employees_userSettings_maxDays_sub: "(0 = geen)",
            employees_userSettings_endTime: "Maximale eindtijd",
            employees_userSettings_endTime_sub: "(leeg = geen)",
            employees_userSettings_maxCumulative: "Maximaal aantal werkdagen achter elkaar",
            employees_userSettings_maxCumulative_sub: "(0 = geen)",

            employees_title: "Medewerkers",
            employees_allEmployees: "Alle medewerkers",
            employees_allEmployeesTeam: "Alle medewerkers team",
            employees_active: "Actieve medewerkers",
            employees_inactive: "Inactieve medewerkers",
            employees_export: "Exporteer nu",
            employees_button_register: "Medewerker registreren",
            employees_button_import: "Medewerkers importeren",
            employees_button_changePass: "Wachtwoord wijzigen",
            employees_button_manageWageModel: "Beheer loonmodellen",
            employees_button_manageSkills: "Beheer vaardigheden",
            employees_button_manageWorkingDays: "Beheer werkdagen",
            employees_button_manageLeaveBalances: "Beheer verlofsaldo's",
            employees_primeJob: "Primaire functie",
            employees_secondJob: "Secundaire functies",
            employees_button_manageJob: "Beheer functies",
            employees_put_inactive_title: "Medewerker inactief zetten",
            employees_put_inactive: "Medewerker wordt niet meer weergegeven in de planner en de medewerker kan geen verlof of beschikbaarheid meer aangeven",
            employees_put_active_title: "Medewerker actief zetten",
            employees_put_active: "Medewerker wordt weer weergegeven in de planner en de medewerker kan weer verlof of beschikbaarheid aangeven",
            employees_delete_employee_title: "Medewerker verwijderen",
            employees_delete_employee: "Medewerker wordt volledig uit het systeem gewist. Deze actie kan niet meer ongedaan worden gemaakt",
            employees_docs_signedBy: "Ondertekend door",
            employees_fixedShifts_sub: "Vul hieronder de vaste werktijden in van maandag t/m zondag. Deze werkdagen kunnen met 1 klik in de planner worden ingepland.",
            employees_generateContract: "Maak een contract",
            employees_upload: "Uploaden",
            employees_selectTemplate: "Selecteer een template",
            employees_contract_sign: "Ondertekenen",
            employees_contract_check: "Bekijken",
            employees_selectTemplate_delete: "Contract template verwijderen",
            employees_foundTags: "Gevonden tags",
            employees_contractMenu_tags: "Tags",
            employees_contractMenu_signing: "Ondertekening",
            employees_contractMenu_employee: "Medewerker",
            employees_contractMenu_manager: "Manager",
            employees_contractMenu_company: "Bedrijf",
            employees_contractMenu_contract: "Contract",
            employees_contract_explainTitle: "Uitleg",
            employees_contract_explain1: "1. Gebruik een Word-document (.docx) en vul het contract in met de tags die hiernaast worden weergegeven.",
            employees_contract_explain2: "2. Upload vervolgens jouw gemaakte template hieronder zodat de gegevens van de medewerkers automatisch worden ingevuld op de geplaatste tags.",
            employees_contract_explain3: "Staat jouw tag er niet tussen? Maak dan een eigen tag zoals {mijn_tag} of {geslacht}. Deze kun je dan hieronder zelf invullen na het selecteren van jouw template.",
            employees_contract_explain4: "Voorbeeldbestand",

            employees_contractTag_signEmployee: "Handtekening van medewerker",
            employees_contractTag_signEmployer: "Handtekening van manager",
            employees_contractTag_signDate: "Datum van geplaatste handtekening medewerker",
            employees_contractTag_fullName: "Volledige naam medewerker",
            employees_contractTag_firstName: "Voornaam medewerker",
            employees_contractTag_lastName: "Achternaam medewerker",
            employees_contractTag_middleName: "Tussenvoegsel medewerker",
            employees_contractTag_job: "Functie medewerker",
            employees_contractTag_email: "E-mailadres medewerker",
            employees_contractTag_phone: "Telefoonnummer medewerker",
            employees_contractTag_initials: "Initialen medewerker",
            employees_contractTag_birthDate: "Geboortedatum medewerker",
            employees_contractTag_birthPlace: "Geboorteplaats medewerker",
            employees_contractTag_address: "Adres medewerker",
            employees_contractTag_city: "Plaatsnaam medewerker",
            employees_contractTag_postalCode: "Postcode medewerker",
            employees_contractTag_citizen: "Burgerservicenummer medewerker",
            employees_contractTag_bank: "Bank nummer/IBAN nummer medewerker",
            employees_contractTag_birthDateDay: "Geboortedag medewerker apart",
            employees_contractTag_birthDateMonth: "Geboortemaand medewerker apart",
            employees_contractTag_birthDateYear: "Geboortejaar medewerker apart",

            employees_contractTag_contractStart: "Contract startdatum",
            employees_contractTag_contractEnd: "Contract einddatum",
            employees_contractTag_contractHours: "Contract vaste uren",
            employees_contractTag_contractMinHours: "Contract minimum uren",
            employees_contractTag_contractMaxHours: "Contract maximum uren",
            employees_contractTag_contractHourly: "Uurloon",
            employees_contractTag_contractMonthly: "Maandloon",

            employees_contractTag_fullName_m: "Volledige naam manager",
            employees_contractTag_firstName_m: "Voornaam manager",
            employees_contractTag_lastName_m: "Achternaam manager",
            employees_contractTag_middleName_m: "Tussenvoegsel manager",
            employees_contractTag_job_m: "Functie manager",
            employees_contractTag_email_m: "E-mailadres manager",
            employees_contractTag_phone_m: "Telefoonnummer manager",
            employees_contractTag_initials_m: "Initialen manager",
            employees_contractTag_birthDate_m: "Geboortedatum manager",
            employees_contractTag_birthPlace_m: "Geboorteplaats manager",
            employees_contractTag_address_m: "Adres manager",
            employees_contractTag_city_m: "Plaatsnaam manager",
            employees_contractTag_postalCode_m: "Postcode manager",

            employees_contractTag_companyName: "Vestigingsnaam",
            employees_contractTag_companyAddress: "Adres vestiging",
            employees_contractTag_companyPostalCode: "Postcode vestiging",
            employees_contractTag_companyCity: "Plaats vestiging",
            employees_contractTag_companyPhone: "Telefoonnummer vestiging",
            employees_contractTag_companyEmail: "E-mailadres vestiging",
            employees_contractTag_companyCountry: "Land",
            employees_contractTag_companyNumber: "Kamer van Koophandel nummer",

            employees_contractTag_companyName2: "Vestigingsnaam",
            employees_contractTag_companyAddress2: "Adres",
            employees_contractTag_companyPostalCode2: "Postcode",
            employees_contractTag_companyCity2: "Plaats",
            employees_contractTag_companyPhone2: "Telefoonnummer",
            employees_contractTag_companyEmail2: "E-mailadres",
            employees_contractTag_companyCountry2: "Land",
            employees_contractTag_companyNumber2: "KvK",

            /* REQUESTS */
            requests_tooltip_generalOverview: "Bekijk op het algemeen overzicht",
            requests_tooltip_planner: "Bekijk op de planner",

            requests_workingHours: "Arbeidsduur werkdag",
            requests_manage_workingDays: "Beheer werkdagen",

            requests_availableShifts_employees: "Beschikbare diensten van medewerkers",
            requests_availableShifts_planner: "Beschikbare diensten van de planner",

            /* TIME REGISTRATION */
            time_addToRegistration: "Aan tijdregistratie toevoegen",
            time_manageAbsenceTypes: "Beheer afwezigheidstypes",
            time_fullyApproved: "Volledig goedgekeurd",
            time_notFullyApproved: "Nog niet volledig goedgekeurd",
            time_extraTimeRegistrations: "Extra tijdregistraties",
            time_button_addTime: "Tijdregistratie toevoegen",

            /* REPORTS */
            reports_tooltip_leave: "Ga naar het verlofverzoek",

            reports_add_note: "Notitie toevoegen",
            reports_pinNotes: "Notities vastzetten",
            reports_show_weekends: "Weekenden weergeven",
            reports_show_holidays: "Feestdagen weergeven",

            report_plusMinusCorrections: "Plus- & minuren correcties",
            report_manageExclusions: "Beheer uitsluitingen",


            /* APP */
            /* AVAILABILITY */
            avail_alert_copy_title: "Kopiëren",
            avail_alert_copy: `Wil je jouw gekopieerde beschikbaarheid plakken in deze maand?\nLet op: dit overschrijft wel jouw huidige beschikbaarheid van deze maand`,

            avail_alert_limitMonth: `Je hebt de limiet van aantal onbeschikbare dagen voor deze maand al bereikt`,
            avail_alert_limitWeek: `Je hebt de limiet van aantal onbeschikbare dagen voor deze week al bereikt`,
            avail_alert_limitDay: `De limiet voor het aantal onbeschikbaarheden voor deze dag is bereikt`,
            avail_alert_planned: "Je bent op deze dag al ingepland en dus is het te laat om jouw onbeschikbaarheid toe te voegen. Neem contact op met jouw manager",
            avail_alert_availAdded: "Beschikbaarheid is toegevoegd",
            avail_alert_unavailAdded: "Onbeschikbaarheid is toegevoegd",
            avail_alert_availEdited: "Beschikbaarheid is gewijzigd",
            avail_alert_unavailEdited: "Onbeschikbaarheid is gewijzigd",
            avail_alert_availDeleted: "Beschikbaarheid is verwijderd",
            avail_alert_unavailDeleted: "Onbeschikbaarheid is verwijderd",
            avail_alert_schoolAdded: "Schooltijden zijn toegevoegd",
            avail_alert_schoolHolidayAdded: "Schoolvakantie is toegevoegd",
            avail_alert_schoolEdited: "Schooltijden zijn gewijzigd",
            avail_alert_schoolHolidayEdited: "Schoolvakantie is gewijzigd",
            avail_alert_schoolDeleted: "Schooltijden zijn verwijderd",
            avail_alert_schoolHolidayDeleted: "Schoolvakantie is verwijderd",
            avail_alert_standardAvail_requested: "Wijziging van beschikbaarheid is doorgegeven",
            avail_alert_standardUnavail_requested: "Wijziging van onbeschikbaarheid is doorgegeven",

            avail_button_paste: "Hier plakken",
            avail_button_schoolSchedule: "Wisselen naar schoolrooster",
            avail_button_calendar: "Wisselen naar kalender",
            avail_button_leave: "Tik hier om vrij te vragen of om vakantie door te geven",

            avail_title_add: "Beschikbaarheid toevoegen",
            avail_title_add_sub: "Beschikbaarheid toevoegen op",
            avail_title_add2: "Onbeschikbaarheid toevoegen",
            avail_title_add_sub2: "Onbeschikbaarheid toevoegen op",
            avail_title_edit: "Beschikbaarheid wijzigen",
            avail_title_edit_sub: "Beschikbaarheid wijzigen op",
            avail_title_editRepeating_sub: "Herhalende beschikbaarheid wijzigen",
            avail_title_edit2: "Onbeschikbaarheid wijzigen",
            avail_title_edit_sub2: "Onbeschikbaarheid wijzigen op",
            avail_title_editRepeating_sub2: "Herhalende onbeschikbaarheid wijzigen",
            avail_title_add_school: "Schooltijden toevoegen",
            avail_title_add_school_sub: "Schooltijden toevoegen op",
            avail_title_edit_school: "Schooltijden wijzigen",
            avail_title_edit_school_sub: "Schooltijden wijzigen op",
            avail_title_add_schoolHoliday: "Schoolvakantie toevoegen",
            avail_title_add_schoolHoliday_sub: "Schoolvakantie toevoegen op",
            avail_title_edit_schoolHoliday: "Schoolvakantie wijzigen",
            avail_title_edit_schoolHoliday_sub: "Schoolvakantie wijzigen op",

            avail_add_IAm: "Ik ben",
            avail_add_repeatAvail: "Beschikbaarheid herhalen",
            avail_add_repeatUnavail: "Onbeschikbaarheid herhalen",
            avail_add_repeatingAvail: "Herhalende beschikbaarheid",
            avail_add_repeatingUnavail: "Herhalende onbeschikbaarheid",

            avail_text_schoolHolidays: "Let op: dit zijn de vakanties die je volgens het schoolrooster krijgt. Dit betekent dat je vrij bent van school en niet van werk.",
            avail_text_schoolHolidaysDelete: "Schoolvakanties worden automatisch verwijderd als deze voorbij zijn.",

            avail_school_springbreak: "Voorjaarsvakantie",
            avail_school_mayHoliday: "Meivakantie",
            avail_school_summerbreak: "Zomervakantie",
            avail_school_autumnbreak: "Herfstvakantie",
            avail_school_christmasbreak: "Kerstvakantie",

            avail_standard_change: "{0} wijziging is in afwachting van goedkeuring",
            avail_standard_changes: "{0} wijzigingen zijn in afwachting van goedkeuring",

            /* SCHEDULE */
            schedule_button_planning: "Planning maken",

            schedule_error_empty: "Geen rooster gepubliceerd voor deze dag",

            /* CHATS */
            chat_alert_created: "Groep is aangemaakt",

            chat_error_enterGroupName: "Voer een geldige groepsnaam in",
            chat_error_groupChatMin: "De groepchat moet meer dan 2 medewerkers bevatten",

            chat_button_createGroup: "Groep maken",
            chat_button_editGroup: "Groep wijzigen",
            chat_button_editRights: "Rechten wijzigen",
            chat_button_deleteGroup: "Groep verwijderen",

            chat_screen_myChats: "Mijn chats",
            chat_screen_groupChats: "Groepchats",
            chat_screen_tapStartChat: "Tik hier om te beginnen met een chat",
            chat_screen_noGroupChats: "Er zijn nog geen groepchats",
            chat_screen_createNewGroup: "Nieuwe groep maken",
            chat_screen_whoMayChat: "Wie mogen chatten",
            chat_screen_employeesGroupChat: "Medewerkers groepchat",
            chat_screen_deselect: "Iedereen deselecteren",
            chat_screen_select: "Iedereen selecteren",
            chat_screen_loadMore: "Meer berichten laden",
            chat_screen_typeHere: "Typ hier jouw bericht...",
            chat_screen_chatRights: "Chat-rechten",

            /* CLOCK */
            clock_alert_clockedOut_title: "Uitgeklokt",
            clock_alert_clockedOut: "Je bent uitgeklokt",
            clock_alert_break_title: "Pauze",
            clock_alert_break: "Je bent met pauze",
            clock_alert_break2: "Je bent terug van pauze",
            clock_alert_failedDistance: `Je bent niet in de buurt van jouw werk\n\nJouw afstand is {0} meter van de inklok-zone`,
            clock_alert_failedSchedule: "Je staat vandaag niet op het rooster",
            clock_alert_clockIn: "Wil je inklokken?",
            clock_alert_clockOut: "Wil je uitklokken",
            clock_alert_breakIn: "Wil je met pauze gaan?",
            clock_alert_breakOut: "Wil je terugkomen van pauze?",
            clock_alert_alreadyClockedIn: "Je bent al ingeklokt",
            clock_alert_alreadyClockedOut: "Je bent al uitgeklokt",
            clock_alert_alreadyBreakIn: "Je bent al met pauze",
            clock_alert_alreadyBreakOut: "Je bent al terug van pauze",
            clock_alert_breakError: "Je kunt nog niet met pauze gaan",

            clock_noti_dontForget: "Vergeet niet om voor {0} uit te klokken",
            clock_noti_clockedOut: "Je bent uitgeklokt om {0}",

            clock_error_notAllowed: "Inklokken vanaf een mobiel is niet toegestaan. Het is alleen toegestaan om uit te klokken vanaf een mobiel",

            clock_screen_title: "In- & uitklokken",
            clock_screen_notSchedule: "Je staat vandaag niet op het rooster",
            clock_screen_onSchedule: "Je staat vandaag op het rooster van",
            clock_screen_tooLate: "Je bent te laat. Selecteer een reden",

            clock_screen_reason_none: "Geen van onderstaande",
            clock_screen_reason_tooLate: "Te laat",
            clock_screen_reason_forgot: "Vergeten in te klokken",

            clock_screen_totalBreak: "Totale pauze",
            clock_screen_breakTime: "totale pauze volgens het rooster",
            clock_screen_clockedInAt: "Je bent ingeklokt op",
            clock_screen_notClockedIn: "Je bent niet ingeklokt",
            clock_screen_pause: "Pauzeren",
            clock_screen_pauseEnd: "Einde pauze",

            /* PLANNER */
            planner_alert_publish_title: "Rooster publiceren",
            planner_alert_publish: "Het rooster van {0} publiceren, publiceren ongedaan maken of alles publiceren?\n\nIndien je kiest voor publiceren zal het rooster voor alle medewerkers zichtbaar worden",

            planner_noti_published: "Jouw nieuwe rooster is geüpload. Tik hier om jouw roostermeldingen te updaten",
            planner_noti_deleted: "Je bent van het rooster verwijderd voor {0}. Tik hier om jouw roostermeldingen te updaten",
            planner_noti_added: "Je bent toegevoegd op het rooster voor {0}. Tik hier om jouw roostermeldingen te updaten",
            planner_noti_edited: "Jouw dienst is veranderd van {0} naar {1}. Tik hier om jouw roostermeldingen te updaten",
            planner_noti_statusTitle: "Statuswijziging rooster",
            planner_noti_statusNoAbsenceYesPart: "Status {0}: Geen absentie/{1}",
            planner_noti_statusNoAbsence: "Status {0}: Geen absentie",
            planner_noti_statusNoPart: "Status {0}: Geen bijzonderheid",
            planner_noti_statusYes: "Status {0}: {1}",

            planner_button_undo: "Publiceren ongedaan maken",
            planner_button_day: "Deze dag publiceren",
            planner_button_all: "Alles publiceren",

            planner_screen_shiftTemplate: "Dienstensjabloon",
            planner_screen_chooseSection: "Kies afdeling",
            planner_screen_chooseTemplate: "Kies dienstensjabloon",
            planner_screen_scheduleOn: "{0} inroosteren op",
            planner_screen_wholeDayAvailable: "Hele dag beschikbaar",
            planner_screen_wholeDayUnavailable: "Hele dag onbeschikbaar",
            planner_screen_periodAvailable: "Beschikbaar {0}",
            planner_screen_periodUnavailable: "Onbeschikbaar {0}",
            planner_screen_periodSchool: "School {0}",
            planner_screen_noAvailability: "Geen beschikbaarheid aangegeven voor deze dag",
            planner_screen_ignoreAvailability: "Negeer beschikbaarheid",

            planner_screen_personUnavailableWholeDay: "{0} is de hele dag beschikbaar",
            planner_screen_personUnavailablePeriod: "{0} is onbeschikbaar {1}",
            planner_screen_personAvailableWholeDay: "{0} is de hele dag beschikbaar",
            planner_screen_personAvailablePeriod: "{0} is beschikbaar {1}",
            planner_screen_personSchoolPeriod: "{0} heeft school {1}",

            planner_web_screen_usersNoJob: "Medewerkers zonder functie",
            planner_web_screen_shiftSkills: "Dienst vereist de volgende vaardigheden",
            planner_web_screen_userNoSkills: "Medewerker heeft niet de vereiste vaardigheden",
            planner_web_screen_notPublished: "Dienst is nog niet gepubliceerd",
            planner_web_screen_saved: "Opgeslagen",
            planner_web_screen_save: "Opslaan",
            planner_web_screen_export: "Exporteren",
            planner_web_screen_revert: "Terugdraaien",
            planner_web_screen_delete: "Verwijderen",
            planner_web_screen_deletePlanner: "Planning van week {0} verwijderen",
            planner_web_screen_undoPlanner: "Wijzigingen van week {0} ongedaan maken",
            planner_web_screen_exportPlanner: "Exporteer rooster",
            planner_web_screen_savePlanner: "Week {0} opslaan als planningsjabloon",
            planner_web_screen_allSavedTemplates: "Opgeslagen planningen",
            planner_web_screen_settings: "Instellingen planning",
            planner_web_screen_applySavedSchedule: "Opgeslagen planning toepassen",
            planner_web_screen_applySavedSchedule_text: "Weet je zeker dat je het sjabloon {0} wilt toepassen op week {1}?",
            planner_web_screen_manageBreaks: "Beheer pauzes",
            planner_web_screen_manageTeams: "Beheer teams",
            planner_web_screen_goToHolidayRequest: "Ga naar verlofverzoek",
            planner_web_screen_checkGeneralOverview: "Naar algemeen overzicht",

            planner_web_caution_notPublished: "niet gepubliceerd",
            planner_web_caution_conflicts: "conflicten",

            planner_web_top_wage: "Bruto loonkosten",
            planner_web_top_expectedLoad: "Verwachte drukte",
            planner_web_top_expected: "Verwachte omzet",
            planner_web_top_actual: "Daadwerkelijke omzet",
            planner_web_top_productivity: "Productiviteit",
            planner_web_top_total: "Totaal",
            planner_web_top_lastYear: "Omzet {0}",

            planner_web_menu_sortName: "Sorteer op naam",
            planner_web_menu_sortJob: "Sorteer op functie",
            planner_web_menu_exportCsv: "Exporteer .csv",

            planner_web_alert_deletePlanner: "Weet je zeker dat je de gehele planning van week {0} wilt verwijderen?",
            planner_web_alert_undoPlanner: "Weet je zeker dat je de wijzigingen van week {0} ongedaan wilt maken?",

            /* OPTIONS */
            options_title_myData: "Mijn gegevens",
            options_title_myWorkedHours: "Mijn gewerkte uren",
            options_title_myPlusMinus: "Mijn plus- & minuren",
            options_title_myAppointments: "Mijn afspraken",
            options_title_myDocuments: "Mijn documenten",
            options_title_teams: "Teams",
            options_title_switchTeam: "Wissel van team",
            options_title_clockInSystem: "Inkloksysteem",
            options_title_clockIn: "Inklokken",
            options_title_clockedEmployees: "Ingeklokte medewerkers",
            options_title_availableShifts: "Beschikbare diensten",
            options_title_viewShifts: "Bekijk beschikbare diensten",
            options_title_requests: "Verzoeken",
            options_title_tradeRequests: "Ruilverzoeken",
            options_title_leaveRequests: "Verlofverzoeken",
            options_title_shiftTakeovers: "Dienstovernames",
            options_title_registration: "Registratie",
            options_title_registerEmployee: "Medewerker registreren",
            options_title_actions: "Acties",
            options_title_requestLeaveVacation: "Vrij vragen/vakantie vragen",
            options_title_allDeadlines: "Alle deadlines",
            options_title_allEmployees: "Alle medewerkers",
            options_title_support: "Support",
            options_title_mail: "Mail Tiemdo",
            options_title_switchAccount: "Wissel van account",

            options_acc_multipleTeams: "Meerdere teams",
            options_acc_fillInData: "Gegevens aanvullen",
            options_acc_fill_text: "Je moet nog een aantal gegevens aanvullen om jouw account af te ronden. Bekijk hieronder welke gegevens wij nog van je missen.",
            options_acc_fill_text2: "Daarnaast dien je akkoord te gaan met onze privacyverklaring om de app te kunnen gebruiken.",
            options_acc_newPass: "Nieuw wachtwoord",

            options_acc_alert_save_title: "{0} opslaan",
            options_acc_alert_save: "Weet je zeker dat je de volgende gewijzigde gegevens wilt opslaan?{0}",
            options_acc_alert_saved: "{0} is gewijzigd",
            options_acc_alert_deleted: "{0} is verwijderd",
            options_acc_alert_savedOwn: "Jouw gegevens zijn gewijzigd",
            options_acc_alert_failedAdmin: "Er is op dit moment maar 1 administrator. Maak eerst een andere medewerker een administrator en verwijder dan deze medewerker",
            options_acc_alert_failedEmployee: "Er moet tenminste 1 medewerker geregistreerd zijn om accounts te kunnen verwijderen",

            options_blocked_add_title: "Geblokkeerde dag toevoegen",
            options_blocked_edit_title: "Geblokkeerde dag wijzigen",
            options_pass_edit_title: "Wachtwoord wijzigen",
            options_deadline_add_title: "Deadline toevoegen",
            options_deadline_edit_title: "Deadline wijzigen",
            options_job_add_title: "Functie toevoegen",
            options_job_edit_title: "Functie wijzigen",
            options_holiday_edit_title: "Verlof wijzigen",
            options_holiday_approve_title: "Verlof keuren",
            options_section_add_title: "Afdeling toevoegen",
            options_section_edit_title: "Afdeling wijzigen",
            options_fixedTimes_add_title: "Standaard tijden toevoegen",
            options_fixedTimes_edit_title: "Standaard tijden wijzigen",

            options_noti_edited_blocked: "Geblokkeerde dag is gewijzigd",
            options_noti_edited_pass: "Jouw wachtwoord is gewijzigd",

            options_pass_error_current: "Je hebt geen huidig wachtwoord ingevoerd",
            options_pass_error_new: "Je hebt geen nieuw wachtwoord ingevoerd",
            options_pass_error_confirm: "Je moet het nieuwe wachtwoord nog bevestigen",

            options_pass_screen_current: "Huidig wachtwoord",
            options_pass_screen_new: "Nieuw wachtwoord",

            options_clock_alert_clockOut: "Wil je deze medewerker uitklokken?",
            options_clock_error_clockOut: "Deze medewerker is al uitgeklokt",
            options_clock_screen_title: "Ingeklokte medewerkers",

            options_deadline_screen_edit: "Beschikbaarheid aanpasbaar na deadline?",

            options_doc_noti_title: "Document upload",
            options_doc_noti: "{0} heeft een nieuw document geüpload onder {1}",

            options_doc_uploadAlert: "Weet je zeker dat je dit document wilt uploaden?",

            options_holiday_screen_dayOffVacation: "Vrije dag/vakantie aanvragen",
            options_holiday_screen_dayOff_title: "Vrije dag aanvragen",
            options_holiday_screen_dayOff_sub: "Wil je graag een dag vrij vragen?",
            options_holiday_screen_dayOff: "Dag vrij vragen",
            options_holiday_screen_vacation_title: "Vakantie aanvragen",
            options_holiday_screen_vacation_sub: "Wil je graag een vakantie aanvragen?",
            options_holiday_screen_vacation: "Vakantie aanvragen",
            options_holiday_screen_holidayPeriod: "Je vraagt vakantie aan binnen een drukke vakantieperiode. Selecteer de dagen die je kunt werken tijdens deze drukke vakantieperiode.",
            options_holiday_screen_hours: "Op te nemen verlofuren", 

            options_holiday_request_staff: "{0} heeft verlof aangevraagd voor {1}",
            options_holiday_request_staff2: "{0} heeft een nieuw vakantieverzoek aangevraagd van {1}",
            options_holiday_request_employee_accepted: "Jouw verlofverzoek voor {0} is goedgekeurd",
            options_holiday_request_employee_denied: "Jouw verlofverzoek voor {0} is afgewezen",
            options_holiday_request_employee_accepted2: "Jouw vakantieverzoek van {0} is goedgekeurd",
            options_holiday_request_employee_denied2: "Jouw vakantieverzoek van {0} is afgewezen",

            options_holiday_alert_added: "Verlof is doorgegeven",
            options_holiday_alert_edited: "Verlof is gewijzigd",
            options_holiday_alert_deleted: "Verlof is verwijderd",
            options_holiday_alert_approved: "Verlof is goedgekeurd",
            options_holiday_alert_denied: "Verlof is afgewezen",

            options_holiday_alert_acceptRequest: "Weet je zeker dat je dit verzoek wilt goedkeuren?",            
            options_holiday_alert_denyRequest: "Weet je zeker dat je dit verzoek wilt afwijzen?",     

            options_myHours_screen_title: "Gewerkte uren",
            options_myHours_screen_weekDisplay: "Week weergave",
            options_myHours_screen_monthDisplay: "Maand weergave",
            options_myHours_screen_yearDisplay: "Jaar weergave",

            options_fixedTimes_alert_edited: "Standaard tijden zijn gewijzigd",
            options_fixedTimes_alert_deleted: "Standaard tijden zijn verwijderd",

            options_trade_alert_approved: "Je hebt het ruilverzoek goedgekeurd",
            options_trade_alert_denied: "Je hebt het ruilverzoek afgewezen",
            options_trade_alert_deleted: "Je hebt het ruilverzoek verwijderd",
            options_trade_alert_accepted: "Je hebt het ruilverzoek geaccepteerd",

            options_trade_noti_tradeRequestAccepted: "Het ruilverzoek tussen jou en {0} is goedgekeurd. Je bent van de dienst van {1} afgehaald en jouw nieuwe dienst is {2}",
            options_trade_noti_tradeRequestDenied: "Het ruilverzoek tussen jou en {0} is afgewezen",
            options_trade_noti_tradeRequest: "Er is een nieuw ruilverzoek gedaan om goed te keuren",

            options_trade_noti_tradeRequestAccepted2: "{0} heeft jouw ruilverzoek van {1} geaccepteerd. Wacht op een goedkeuring van jouw manager",
            options_trade_noti_tradeRequestDenied2: "{0} heeft jouw ruilverzoek van {1} afgewezen",

            options_userOptions_alert_error: "Token is niet opgehaald. Foutmelding: ",
            options_userOptions_alert_mobile: "Tiemdo heeft gemerkt dat je gebruik maakt van een ander toestel. Jouw gegevens voor het ontvangen van meldingen zijn automatisch bijgewerkt",

            options_userOptions_screen_lang: "Taal",
            options_userOptions_screen_calendar: "Agenda",
            options_userOptions_screen_calendarSync: "Agenda synchronizeren",
            options_userOptions_screen_calendarSync_sub: "Automatisch jouw diensten synchronizeren met jouw agenda",
            options_userOptions_screen_calendarDelete: "Werkagenda wissen",
            options_userOptions_screen_noti: "Notificaties",
            options_userOptions_screen_notiAllow: "Notificaties toestaan",
            options_userOptions_screen_notiTime: "Notificatietijd",
            options_userOptions_screen_notiTime_sub: "Je kunt zelf aangeven wanneer je een melding wilt ontvangen. Geef hieronder aan hoeveel uur van te voren je een notificatie wenst te ontvangen. Dit geldt alleen voor deadlines en eigen diensten",
            options_userOptions_screen_notiTime_sub2: "van te voren",

            options_web_screen_settingsPlanner: "Instellingen planning",
            options_web_screen_general: "Algemeen",
            options_web_screen_allowSmartPlan: "Smart Plan toestaan",
            options_web_screen_manage: "Beheren",
            options_web_screen_availableShifts: "Alle beschikbare diensten",
            options_web_screen_addEmployee: "Medewerker toevoegen",
            options_web_screen_addJob: "Functie toevoegen",
            options_web_screen_manageDefaultShifts: "Standaard diensten beheren",
            options_web_screen_addSection: "Afdeling toevoegen",
            options_web_screen_display: "Weergave",
            options_web_screen_hideAvailability: "Beschikbaarheid labels weergeven",
            options_web_screen_expectedLoad: "Verwachte drukte weergeven",
            options_web_screen_sort: "Sorteren",
            options_web_screen_sortEmployees: "Medewerkers sorteren",
            options_web_screen_sortJobs: "Functies sorteren",
            options_web_screen_finances: "Financiële berekeningen",
            options_web_screen_salary: "Loonkosten weergeven",
            options_web_screen_turnoverLastYear: "Omzet vorig jaar weergeven",
            options_web_screen_expectedTurnover: "Verwachte omzet weergeven",
            options_web_screen_actualTurnover: "Werkelijke omzet weergeven",
            options_web_screen_productivity: "Productiviteit weergeven",
            options_web_screen_calculations: "Berekening weergeven",
            options_web_screen_openShifts: "Open diensten",
            options_web_screen_openShiftsAvailable: "Open diensten gelijk beschikbaar zetten",
            options_web_screen_contractExpired: "Contract verlopen",

            options_web_tooltip_smartPlan: "De Smart Plan optie tonen in de planner. Hiermee worden jouw medewerkers snel ingepland op basis van de door hun aangegeven beschikbaarheid",
            options_web_tooltip_showAvailability: "Beschikbaarheid labels op de planning weergeven",
            options_web_tooltip_expectedLoad: "Laat de verwachte drukte zien. De drukte wordt berekend met behulp van de afgelopen 3 weken",
            options_web_tooltip_salary: "Loonkosten weergeven van medewerkers",
            options_web_tooltip_turnoverLast: "Omzet van het jaar ervoor weergeven.",
            options_web_tooltip_expectedTurnover: "Verwachte omzet kunnen invullen per dag",
            options_web_tooltip_actualTurnover: "Werkelijke omzet kunnen invullen per dag",
            options_web_tooltip_productivity: "Productiviteit per dag berekenen met behulp van geplande uren en omzet. Als er nog geen werkelijke omzet is gemaakt, wordt de berekening gemaakt met behulp van verwachte omzet of omzet van het jaar ervoor",
            options_web_tooltip_calculations: "De totale berekening weergeven.",
            options_web_tooltip_openShifts: "Wanneer een open dienst wordt aangemaakt, zal deze gelijk beschikbaar worden gezet",
            options_web_tooltip_contractExpired: "Wanneer een contract is verlopen van een medewerker houdt de planning hier rekening mee en kan deze medewerker niet meer worden ingepland",

            /* REGISTRATION */
            register_screen_title: "Nieuwe medewerker",
            register_screen_self_title: "Zelf een medewerker registreren",
            register_screen_self_sub: "Heb je de persoonsgegevens van de nieuwe medewerker?",

            register_screen_other_title: "Een medewerker laten registreren",
            register_screen_other_sub: "Heb je de persoonsgegevens van de nieuwe medewerker niet?",

            register_screen_qr_title: "QR-code scannen",
            register_screen_qr_sub: "Heb je een registratiecode op de website gemaakt? Deel de code door op de onderstaande knop te drukken",

            register_screen_rc_title: "Registratiecodes",
            register_screen_rc_sub: "Alle ongebruikte registratiecodes",

            register_button_self: "Zelf registreren",
            register_button_other: "Registratiecode versturen",
            register_button_qr: "QR-code scannen",

            register_message_register: `Ik wil graag dat je je registreert in de Tiemdo app. Gebruik "{0}" als bedrijfscode.\n\nJouw registratiecode: (hoofdletter gevoelig)\n\n{1}\n\nHeb je de Tiemdo app nog niet? Download het dan via:\n\nwww.tiemdo.nl/download`,

            register_noti_sent: "Registratiecode is verstuurd",
            register_noti_deleted: "Registratiecode is verwijderd",

            register_alert_created: "Registratiecode is aangemaakt",

            /* LOGGER */
            logger_published_whole: "gehele rooster",

        },

        de: {

        },

    });

}

}

export default (new Localization());